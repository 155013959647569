import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { UserTraining } from 'src/app/training/training-approve/interface';
import { TrainingArea } from '../../information-management/training-areas/interface/training-area';

@Injectable()
export class TrainingAreaService {

  constructor(private httpService: HttpClient) { }

  newTrainingArea() {
    const trainingObj: TrainingArea = {
      Id: 0,
      Description: '',
      Name: '',
      Ordinal: 0,
      Icon: '',
      TrainingTopics: [],
      TrainingTopicLevels: [],
      TotalLevels: 0,
      CompletedLevels: 0,
      TotalScore: 0,
      TrainingTopicsAndLevels: {}
      // DateAdded: null,
      // DateEdited: null
    };
    return trainingObj;
  }

  getTrainingAreas(): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}trainingarea`);
  }

  getTrainingArea(trainingAreaId: number): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}trainingarea/` + trainingAreaId
    );
  }

  getTrainingAreaImages(): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}trainingarea/images`
    );
  }

  saveTrainingArea(trainingArea: TrainingArea, areaIcon: File): Observable<any> {
    // return this.httpService.post(
    //   `${RuntimeConstants.API_PATH}trainingarea`,
    //   trainingArea
    // );
    const formData = this.prepareIconFormData(trainingArea, areaIcon);
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}trainingarea`,
      formData
    );
  }

  // updateTrainingArea(trainingArea: TrainingArea, areaIcon: File): Observable<any> {
  //   // return this.httpService.put(
  //   //   `${RuntimeConstants.API_PATH}trainingarea`,
  //   //   trainingArea
  //   // );
  //   let formData = this.prepareIconFormData(trainingArea, areaIcon);
  //   return this.httpService.put(
  //     `${RuntimeConstants.API_PATH}trainingarea`,
  //     formData
  //   );
  // }

  updateOrdinal(trainingArea: TrainingArea): Observable<any> {
    return this.httpService.put(
      `${RuntimeConstants.API_PATH}trainingarea/updateordinal`,
      trainingArea
    );
  }

  deleteTrainingArea(trainingAreaId: number): Observable<any> {
    return this.httpService.delete(
      `${RuntimeConstants.API_PATH}trainingarea/` + trainingAreaId
    );
  }

  private prepareIconFormData(param: TrainingArea, iconFile: File, isAvailableIcon: boolean = false): FormData {
    const formData = new FormData();
    if (iconFile) {
      formData.append('file', iconFile);
    }
    formData.append('isAvailableIcon', JSON.stringify({ isUseUploadedIcon: isAvailableIcon }));
    formData.append('model', JSON.stringify(param));
    return formData;
  }

  getUserTrainings(userId: number): Observable<Array<UserTraining>> {
    return this.httpService.get<Array<UserTraining>>(
      `${RuntimeConstants.API_PATH}usertraining/` + userId
    );
  }
  
}
