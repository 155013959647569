<pos-edit-wrapper (close)="onCancel()" [isPopup]="false" [headingIcon]="icons.tools"
  heading="Online Ordering - Configuration">
  <form #orderingConfigurationForm="ngForm" class="edit-form" autocomplete="off"
    (ngSubmit)="saveOrderingConfigurations(orderingConfigurationForm.valid)">
    <context-provider provide="props">
      <div class="col-12 container-fluid tab-parent border-top">
        <ul class="nav nav-tabs inner-tab pos-nav-tabs pt-pix-10">
          <li [ngClass]="{'active': selectedTab == tabList.General}">
            <a (click)="selectedTab = tabList.General" class="blue">
              <span class="pe-pix-5">
                <pos-icon class="theme-separator" [icon]="icons.cogs"></pos-icon>
              </span>
              <span>General</span>
            </a>
          </li>
          <li [ngClass]="{'active': selectedTab == tabList.Payment}">
            <a (click)="selectedTab = tabList.Payment" class="blue">
              <span class="pe-pix-5">
                <pos-icon class="theme-separator" [icon]="icons.paymentsMoneyBillAlt"></pos-icon>
              </span>
              <span>Payment</span>
            </a>
          </li>
        </ul>

        <div class="overflow-auto-tabs screen-scroll">
          <div [hidden]="selectedTab != tabList.General">
            <div class="pos-tab-body">
              <pos-row>
                <pos-column>
                  <pos-form-field label="Cart Terms" [validationMessages]="{required: 'Please enter cart terms.'}">
                    <input posInput class="form-control" name="cartTerms" #cartTerms="ngModel"
                      [(ngModel)]="orderingConfiguration.CartTerm" [required]="true">
                  </pos-form-field>
                </pos-column>
                <pos-column>
                  <pos-form-field label="Website Address"
                    [validationMessages]="{required: 'Please enter website address.'}">
                    <input posInput class="form-control" name="websiteAddress" #websiteAddress="ngModel"
                      [(ngModel)]="orderingConfiguration.WebsiteAddress" [required]="true">
                  </pos-form-field>
                </pos-column>
                <pos-column>
                  <pos-form-field>
                    <p-checkbox posInput name="vehicleDropOff" #vehicleDropOff="ngModel"
                      [(ngModel)]="orderingConfiguration.EnableVehicleDropOff" binary="true"
                      label=" Vehicle Drop-off Option">
                    </p-checkbox>
                  </pos-form-field>
                </pos-column>
                <pos-column>
                  <pos-form-field>
                    <p-checkbox name="isShowNutritionFactInOnlineOrder" #isShowNutritionFactInOnlineOrder="ngModel"
                      [(ngModel)]="orderingConfiguration.IsShowNutritionFactInOnlineOrder" binary="true"
                      label="Show nutrition facts and ingredients">
                    </p-checkbox>
                  </pos-form-field>
                </pos-column>
                <pos-column>
                  <pos-form-field>
                    <p-checkbox name="isShowOrderUpdatesNotificationOption"
                      #isShowOrderUpdatesNotificationOption="ngModel"
                      [(ngModel)]="orderingConfiguration.IsShowOrderUpdatesNotificationOption" binary="true"
                      label="Show order updates notification option">
                    </p-checkbox>
                  </pos-form-field>
                </pos-column>
                <pos-column>
                  <pos-form-field>
                    <p-checkbox name="isShowLoyaltyCardInOnlineOrder" #isShowLoyaltyCardInOnlineOrder="ngModel"
                      [(ngModel)]="orderingConfiguration.IsShowLoyaltyCardInOnlineOrder" binary="true"
                      label="Show loyalty card">
                    </p-checkbox>
                  </pos-form-field>
                </pos-column>
                <pos-column>
                  <pos-form-field label="Workflows allowed to order in offseason"
                    [validationMessages]="{required: 'Please enter payment type.'}">
                    <ng-select posInput appendTo="body" [items]="orderFulfillmentType" bindLabel="Label" bindValue="Value"
                      placeholder="Select workflow" [multiple]="true"
                      [(ngModel)]="offSeasonDeliveryWorkflow" id="selectedSalesUnit" name="orderFulfillments">
                    </ng-select>
                  </pos-form-field>
                </pos-column>

                <pos-column>
                  <h3 class="setting-title mt-pix-5 section-border">
                    <span>Email</span>
                  </h3>
                  <pos-row class="pos-title-content">
                    <pos-column>
                      <pos-form-field label="Order Confirmation - From Email"
                        [validationMessages]="{required: 'Please enter Order Confirmation - From Email.'}">
                        <input posInput class="form-control" name="OnlineOrderConfirmationFromEmail"
                          #OnlineOrderConfirmationFromEmail="ngModel"
                          [(ngModel)]="orderingConfiguration.OnlineOrderConfirmationFromEmail" [required]="true">
                      </pos-form-field>
                    </pos-column>
                    <pos-column>
                      <pos-form-field label="Order Confirmation - From Name"
                        [validationMessages]="{required: 'Please enter Order Confirmation - From Name.'}">
                        <input posInput class="form-control" name="OnlineOrderConfirmationEmailDisplayName"
                          #OnlineOrderConfirmationEmailDisplayName="ngModel"
                          [(ngModel)]="orderingConfiguration.OnlineOrderConfirmationEmailDisplayName" [required]="true">
                      </pos-form-field>
                    </pos-column>
                    <pos-column>
                      <pos-form-field label="Order Notification - To Email(s)" inputStyle="col-md-5 col-8 ">
                        <textarea posInput class="form-control" name="OnlineOrderNotificationToEmails"
                          #OnlineOrderNotificationToEmails="ngModel"
                          [(ngModel)]="orderingConfiguration.OnlineOrderNotificationToEmails"
                          placeholder="Enter emails separated by semicolon (;)" row="1"></textarea>
                      </pos-form-field>
                    </pos-column>
                    <pos-column>
                      <pos-form-field label="Order Confirmation - BCC Email(s)" inputStyle="col-md-5 col-8 ">
                        <textarea posInput class="form-control" name="OnlineOrderConfirmationBccEmails"
                          #OnlineOrderConfirmationBccEmails="ngModel"
                          [(ngModel)]="orderingConfiguration.OnlineOrderConfirmationBccEmails"
                          placeholder="Enter emails separated by semicolon (;)" row="1"></textarea>
                      </pos-form-field>
                    </pos-column>
                  </pos-row>
                </pos-column>
                
              </pos-row>
            </div>
          </div>

          <div [hidden]="selectedTab != tabList.Payment">
            <div class="pos-tab-body">
              <pos-row>

                <pos-column>
                  <pos-form-field label="Gateway" [validationMessages]="{required: 'Please enter payment type.'}">
                    <ng-select posInput #paymentTypes="ngModel" name="paymentTypes" appendTo="body"
                      [(ngModel)]="orderingConfiguration.PaymentTypes" [required]="true" bindValue="Value" bindLabel="Name"
                      [items]="orderPaymentType" (change)="changePaymentType()">
                    </ng-select>
                  </pos-form-field>

                  <pos-row class="mx-0" *ngIf="orderingConfiguration.PaymentTypes == 'Authorize.Net'">
                    <pos-column>
                        <pos-form-field label="API Login Id:" inputStyle="col-md-3 col-6" [validationMessages]="{required: 'Please enter login id.'}">
                            <input posInput class="form-control" name="AuthorizeNetApiLoginId"
                                [(ngModel)]="orderingConfiguration.AuthorizeNetApiLoginId" type="text" [required]="true"/>
                        </pos-form-field>
                    </pos-column>

                    <pos-column>
                        <pos-form-field label="Client Key:" inputStyle="col-6" [validationMessages]="{required: 'Please enter client key.'}">
                            <input posInput class="form-control" name="AuthorizeNetClientKey"
                                [(ngModel)]="orderingConfiguration.AuthorizeNetClientKey" type="text" [required]="true" />
                        </pos-form-field>
                    </pos-column>

                    <pos-column>
                        <pos-form-field label="Transaction Key:" inputStyle="col-md-3 col-6" [validationMessages]="{required: 'Please enter transaction key.'}">
                            <input posInput class="form-control" name="AuthorizeNetTransactionKey"
                                [(ngModel)]="orderingConfiguration.AuthorizeNetTransactionKey" type="text" [required]="true"/>
                        </pos-form-field>
                    </pos-column>
                  </pos-row>


                  <pos-row class="mx-0" *ngIf="orderingConfiguration.PaymentTypes == 'Paypal'">
                    <pos-column>
                        <pos-form-field label="Client Id" inputStyle="col-6" [validationMessages]="{required: 'Please enter client id.'}">
                            <input posInput class="form-control" name="PaypalClientId"
                                [(ngModel)]="orderingConfiguration.PaypalClientId" type="text" [required]="true"/>
                        </pos-form-field>
                    </pos-column>

                    <pos-column>
                        <pos-form-field label="Client Secret" inputStyle="col-6" [validationMessages]="{required: 'Please enter client secret.'}">
                            <input posInput class="form-control" name="PaypalClientSecret"
                                [(ngModel)]="orderingConfiguration.PaypalClientSecret" type="text" [required]="true" />
                        </pos-form-field>
                    </pos-column>
                  </pos-row>
                </pos-column>

                <pos-column>
                  <pos-form-field label="Options">
                    <p-checkbox posInput name="chkIsDebitCreditPaymentOption" #chkIsDebitCreditPaymentOption="ngModel"
                      class="ps-pix-15" label="Credit/Debit" [(ngModel)]="isDebitCreditPaymentOption" binary="true" disabled>
                    </p-checkbox>
                  </pos-form-field>
                </pos-column>
                <pos-column>
                  <pos-form-field>
                    <p-checkbox posInput name="isUseGiftcard" #isUseGiftcard="ngModel" class="ps-pix-15"
                      [(ngModel)]="orderingConfiguration.IsUseGiftcard" binary="true" label="Gift Card">
                    </p-checkbox>
                  </pos-form-field>
                </pos-column>

                <pos-column xs="12">
                  <pos-form-field inputStyle="col-md-4 col-12 display-flex">
                    <pos-row class="ps-pix-15">
                      <pos-column>
                        <p-checkbox posInput name="AllowGooglePayPayment" label="Google Pay"
                            [(ngModel)]="orderingConfiguration.AllowGooglePayPayment" binary="true" [disabled]="orderingConfiguration.PaymentTypes != 'Authorize.Net'">
                        </p-checkbox>
                      </pos-column>
                      <pos-column class="payment-configuration-button">
                        <button type="button" class="btn btn-primary white" [disabled]="orderingConfiguration.PaymentTypes != 'Authorize.Net'"
                          (click)="configureGooglePay()">
                          <pos-icon [icon]="icons.check" *ngIf="googlePayConfiguration.IsValid"></pos-icon>
                          Configure
                        </button>
                      </pos-column>
                    </pos-row>

                  </pos-form-field>
                </pos-column>
  
                <pos-column xs="12">
                  <pos-form-field inputStyle="col-md-4 col-12 display-flex">
                    <pos-row class="ps-pix-15">
                      <pos-column>
                        <p-checkbox posInput name="AllowApplePayPayment" label="Apple Pay"
                            [(ngModel)]="orderingConfiguration.AllowApplePayPayment" binary="true" [disabled]="orderingConfiguration.PaymentTypes != 'Authorize.Net'">
                        </p-checkbox>
                      </pos-column>
                      <pos-column class="payment-configuration-button">
                        <button type="button" class="btn btn-primary white" [disabled]="orderingConfiguration.PaymentTypes != 'Authorize.Net'"
                          (click)="configureApplePay()">
                          <pos-icon [icon]="icons.check" *ngIf="applePayConfiguration.IsValid"></pos-icon>
                          Configure
                        </button>
                      </pos-column>
                    </pos-row>
                  </pos-form-field>
                </pos-column>

                
                <pos-column>
                  <pos-form-field label="Settlement Mode" [validationMessages]="{required: 'Please enter payment mode.'}">
                    <ng-select posInput #paymentModes="ngModel" name="paymentModes" appendTo="body"
                      [(ngModel)]="orderingConfiguration.PaymentMode" [required]="true" bindValue="Value" bindLabel="Name"
                      [items]="orderPaymentModes" [disabled]="orderingConfiguration.PaymentTypes == 'Paypal'">
                    </ng-select>
                  </pos-form-field>
                </pos-column>
                <pos-column>
                  <pos-form-field>
                    <p-checkbox posInput name="chkTip" #chkTip="ngModel" label="Allow Tip"
                      [(ngModel)]="orderingConfiguration.IsAllowTip" binary="true">
                    </p-checkbox>
                  </pos-form-field>
                </pos-column>
                <pos-column xs="12" *ngIf="orderingConfiguration.IsAllowTip">
                  <pos-row>
                    <pos-column *ngIf="orderingConfiguration.MaxAllowTipType == tipType.Manual" class="col-110" xs="4">
                      <pos-form-field label="Maximum Allowed Tip" labelStyle="text-nowrap"
                        [validationMessages]="{required: 'Please enter tip amount.'}" inputStyle="col-lg-7 col-md-6 col-xs-9">
                        <input posInput name="maxTip" #maxTip="ngModel" type="number" posOnlyNumbers id="maxTip"
                          class="form-control col-100" [(ngModel)]="orderingConfiguration.MaxAllowTip"
                          [disabled]="!orderingConfiguration.IsAllowTip" [required]="true" />
                      </pos-form-field>
                    </pos-column>
                    <pos-column *ngIf="orderingConfiguration.MaxAllowTipType == tipType.Percent" class="col-110" xs="4">
                      <pos-form-field label="Maximum Allowed Tip" labelStyle="text-nowrap"
                        [validationMessages]="{range: 'Percent must be between 0-100', required: 'Please enter tip percent.'}"
                        inputStyle="col-lg-7 col-md-6 col-xs-9">
                        <input posInput name="maxTipPercent" #maxTipPercent="ngModel" type="number" posOnlyNumbers
                          id="maxTipPercent" class="form-control col-100" [range]="[0, 100]" step="1"
                          [(ngModel)]="orderingConfiguration.MaxAllowTip" [disabled]="!orderingConfiguration.IsAllowTip"
                          [required]="true" />
                      </pos-form-field>
                    </pos-column>
                    <pos-column class="col-150 pt-34" xs="4">
                      <div class="col-lg-9 col-md-8 col-sm-8 col-xs-12 btn-group">
                        <label class="btn btn-default btn-radio"
                          [ngClass]=" orderingConfiguration.MaxAllowTipType == tipType.Percent ? 'active btn-primary normal': 'btn-secondary normal'">
                          <input name="selectType" [(ngModel)]="orderingConfiguration.MaxAllowTipType" type="radio"
                            [value]="tipType.Percent" [disabled]="!orderingConfiguration.IsAllowTip" />
                          %
                        </label>
                        <label class="btn btn-default btn-radio"
                          [ngClass]="orderingConfiguration.MaxAllowTipType == tipType.Manual ? 'active btn-primary normal': 'btn-secondary normal'">
                          <input name="selectType" [(ngModel)]="orderingConfiguration.MaxAllowTipType" type="radio"
                            [value]="tipType.Manual" [disabled]="!orderingConfiguration.IsAllowTip" />
                          {{currencySymbol}}
                        </label>
                      </div>
                    </pos-column>
                  </pos-row>
                </pos-column>
              </pos-row>
            </div>
          </div>
        </div>
      </div>
    </context-provider>
    <div class="col-12 action-button-container page-footer">
      <button type="submit" *ngIf="permissionDenied" value="Save" class="btn-primary btn-lg btn btn-save pos-button"
        scrollup>Save</button>
      <button type="button" class="btn-secondary btn-lg btn btn-cancel pos-button" (click)="onCancel()" value="Cancel" scrollup>
        Cancel </button>
    </div>
  </form>
</pos-edit-wrapper>
