<div class="h-100 d-grid" [ngStyle]="{'align-items': buttonConfig.Alignment?.Vertical,
'justify-content': buttonConfig.Alignment?.Horizontal}">
    <div class="d-flex flex-column">
        <div class="grid-row" *ngFor="let row of rowsArray; let rowIndex = index;">
            <div class="grid-column" (click)="buttonClick(screenButton)"
                *ngFor="let screenButton of screenButtons?.slice(rowIndex * grid.NoOfCols, rowIndex * grid.NoOfCols + grid.NoOfCols);"
                [ngStyle]="{'height': buttonConfig.Height ? buttonConfig.Height + 'px' : (itemWidth + 'px'),
                'width': buttonConfig.Width ? buttonConfig.Width + 'px' : (itemWidth + 'px'), 'min-width': buttonConfig.Width ? buttonConfig.Width + 'px' : '100px',
                'max-width': itemMaxWidth ? itemMaxWidth + 'px' : '',
                'border-color': screenButton?.Button?.BorderColor, 'background-color': screenButton?.Button?.BackgroundColor,
                '--border-radius': buttonConfig.Radius + 'px', '--rotation-degree': buttonConfig.RotationDegree + 'deg',
                '--bg-opacity': buttonConfig.Opacity ?? 1 }"
                [class.kiosk-bg-light-grey]="isDesignMode || (screenButton?.Button && !screenButton.Button.BackgroundColor)"
                [ngClass]="{'active': screenButton?.Button?.IsSelected, 'searched': screenButton?.Button?.IsSearched,
                 'pointer': screenButton?.Button || (!screenButton?.Button && isDesignMode), 'filtered-product': screenButton?.Button?.IsFilteredByAllergens,
                 'out-of-stock': screenButton?.Button?.SalesProduct && !screenButton.Button.SalesProduct.IsInStock && !screenButton?.Button?.IsFilteredByAllergens,
                 'inactive-product': screenButton?.Button?.SalesProduct && !screenButton.Button.SalesProduct.IsActive }">
                <pos-icon class="plus-icon" *ngIf="!screenButton?.Button && isDesignMode"
                    [icon]="icons.plusMessage"></pos-icon>
                <div class="config-button">
                    <pos-kiosk-screen-setup [screenButton]="screenButton" [screenButtons]="screenButtons"
                        (screenButtonsChange)="emitScreenButtonsChange($event)" [isDesignMode]="isDesignMode"
                        [isAllowDelete]="isAllowDelete" (setGridRowsCols)="setGridRowsCols()"></pos-kiosk-screen-setup>
                </div>
                <div class="button-image" *ngIf="screenButton?.Button?.Image"
                    [ngStyle]="{'--max-button-width': buttonConfig.Width ? buttonConfig.Width + 'px' : (itemWidth + 'px')}">
                    <img alt="" [ngStyle]="{'border-radius': buttonConfig.ImageRadius}" src="{{buttonRootPath}}{{screenButton.Button.Image}}" [class.stretch-image]="grid.IsStretchImage" />
                </div>
                <div class="button-text"
                    *ngIf="screenButton?.Button?.ButtonText && ((templateType == templateTypes.FixedGridLayout || templateType == templateTypes.FluidGridLayout) ? buttonConfig?.IsShowButtonText : true)"
                    [class.round-bottom-radius]="!grid.IsShowTagsAndPrice"
                    [ngClass]="screenButton?.Button?.Image ? '' : 'height-fill-available'"
                    [ngStyle]="{'color': screenButton.Button.TextColor, 'font-size': screenButton.Button.FontSize, 'background-color': screenButton?.Button?.Image ? buttonConfig.TextBackground : '' }" [innerHTML]="screenButton.Button.ButtonText | uppercase">                  
                </div>
                <div class="price-section" *ngIf="screenButton?.Button && grid.IsShowTagsAndPrice">
                    <div class="tags" *ngIf="screenButton?.Button?.SalesProduct">
                        <div *ngFor="let tag of screenButton.Button.SalesProduct.SalesProductTags">
                            <img *ngIf="tag?.Tag?.Icon" class="tag-image" src="{{tagRootPath}}{{tag?.Tag?.Icon}}">
                        </div>
                    </div>
                    <div class="price" *ngIf="screenButton?.Button?.Price != null">
                        +{{ screenButton.Button.Price | tark_currency }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
