import { Component } from '@angular/core';
import { ModalComponent } from 'src/app/shared';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'pos-reorder-tags',
  templateUrl: './reorder-tags.component.html',
})
export class ReorderTagsComponent extends ModalComponent {

  tags: Array<String> = [];

  constructor(modalRef: BsModalRef) {
    super(modalRef);
  }

  closeReorder() {
    this.close.emit({tags: this.tags});
  }

  onSort(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.tags, event.previousIndex, event.currentIndex);
  }

}
