<pos-edit-wrapper [isPopup]="true" heading="Inventory Received: {{inventoryProductName}}" (close)="cancel()">
    <div class="col-12 pos-modal-body pt-pix-5">
        <div class="col-12 text-right" style="z-index: 999;">
            <button class="btn btn-secondary pull-right btn-lg normal pos-button" (click)="exportAction('exporInventoryReceived');">
                <pos-icon [icon]="icons.upload"></pos-icon>
                Export
            </button>
        </div>
        <div class="col-12" id="exporInventoryReceived">
            <ng-table [sort]="true" [columns]="inventoryReceivedSummaryColumns" [data]="inventoryReceivedSummary"
                [paginator]="false" [scrollable]="true" [scrollHeight]="'calc(100vh - 360px)'">
            </ng-table>
        </div>
    </div>
    <div class="col-12 action-button-container popup-footer">
        <button type="button" (click)="cancel()" class="btn-secondary btn-lg btn btn-cancel-modal pos-button"
            value="Cancel">Cancel</button>
    </div>
</pos-edit-wrapper>
