import { Component, OnInit, Input, OnChanges, ViewChild, TemplateRef } from '@angular/core';
import { GridColumn, EditColumn, DeleteColumn, LinkColumn, TemplateColumn } from '@tarktech/tark-ng-utils';
import { InventoryBinModel } from '../../../interface';
import { ConfirmDeleteComponent, Messages, SpinnerService, AlertsService, ModalService, PrintTableService, InventoryZoneService } from 'src/app/shared';
import { InventoryZoneBinsEditModelComponent } from './inventory-zone-bins-edit-model';
declare let $: any;

@Component({
  selector: 'pos-inventory-zone-edit-bins',
  templateUrl: './inventory-zone-edit-bins.component.html',
  styleUrls: ['./inventory-zone-edit-bins.component.scss']
})
export class InventoryZoneEditBinsComponent implements OnInit, OnChanges {

  @ViewChild('confirmDeleteOption') private confirmDeleteOption: ConfirmDeleteComponent;
  @ViewChild('popOverForInventoryBinName', { static: true }) popOverForInventoryBinName: TemplateRef<any>;
  @Input() inventoryBinList: Array<InventoryBinModel> = [];
  @Input() editInventoryzoneId = 0;
  inventoryZoneEditBinsListColumns: Array<GridColumn> = [];
  printInventoryBinListColumns: Array<GridColumn> = [];
  inventoryBinListData: Array<InventoryBinModel> = [];
  selectedInventoryBinId: number;
  selectedId: number;

  constructor(private spinnerService: SpinnerService,
    private inventoryZoneService: InventoryZoneService,
    private alertsService: AlertsService,
    private modalService: ModalService,
    private printService: PrintTableService) { }

  ngOnInit() {
    this.configureInventoryEditBinsGridColumns();
  }

  ngOnChanges() {
    this.inventoryBinListData = this.inventoryBinList;
  }

  configureInventoryEditBinsGridColumns() {
    const editColumn = new EditColumn({
      Clicked: (data) => {
        this.editInventoryBin(data.Id);
      },
      Width: '55px',
      AddIcon: new LinkColumn({
        Clicked: () => {
          this.editInventoryBin(0);
        },
        ToolTip: 'New'
      })
    });

    const deleteColumn = new DeleteColumn({
      Clicked: (data) => {
        this.deleteInventoryBin(data.Id);
      },
      Width: '83px',
      PrintIcon: new LinkColumn({
        Clicked: () => {
          this.printData();
        }
      })
    });

    this.inventoryZoneEditBinsListColumns = [
      new TemplateColumn({
        HeaderText: 'Name', Field: 'Bin', IsSortable: true, Width: '93%',
        itemTemplate: this.popOverForInventoryBinName
      })
    ];

    this.printInventoryBinListColumns = [...this.inventoryZoneEditBinsListColumns];

    this.inventoryZoneEditBinsListColumns.push(editColumn);
    this.inventoryZoneEditBinsListColumns.push(deleteColumn);
  }

  deleteInventoryBin(inventorybinId: number) {
    this.selectedInventoryBinId = inventorybinId;
    this.confirmDeleteOption.Show(Messages.ConfirmDeleteInventoryBin);
  }

  onConfirmAcknowledge() {
    this.spinnerService.show();
    this.inventoryZoneService.deleteInventoryBin(this.editInventoryzoneId, this.selectedInventoryBinId)
      .subscribe(response => {
        this.alertsService.renderSuccessMessage(Messages.InventoryBinDeleted);
        this.inventoryZoneService.getInventoryBins(this.editInventoryzoneId)
          .subscribe(res => {
            this.inventoryBinListData = res;
            this.inventoryBinList = res;
          },
            (err) => { this.alertsService.showApiError(err); },
            () => { this.spinnerService.hide(); });
      },
        (error) => { this.alertsService.showApiError(error); });
  }

  editInventoryBin(inventoryBinId: number) {
    this.selectedInventoryBinId = inventoryBinId;

    const modelRefTest = this.modalService.show(InventoryZoneBinsEditModelComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        editInventoryzoneId: this.editInventoryzoneId,
        inventoryBinEditId: this.selectedInventoryBinId
      }
    });
    modelRefTest.close.subscribe(res => {
      if (res && res.shouldReload) {
        this.onSaveBins();
      }
    });
  }

  getHeight(isOuterContainer: boolean) {
    if (isOuterContainer === true) {
      return $(window).height() - 290 + 'px';
    } else {
      return $(window).height() - 310 + 'px';
    }
  }

  printData() {
    this.printService.printEmitter.next({ gridColumns: this.printInventoryBinListColumns, gridData: this.inventoryBinListData });
  }

  onSaveBins() {
    this.spinnerService.show();
    this.inventoryZoneService.getInventoryBins(this.editInventoryzoneId)
      .subscribe(res => {
        this.inventoryBinListData = res;
        this.inventoryBinList = res;
      },
        (err) => { this.alertsService.showApiError(err); },
        () => {
          this.spinnerService.hide();
        });
  }

  openInventoryProductForBin(data: InventoryBinModel, popup: any) {
    this.selectedId = data.Id;
  }

}
