<pos-edit-wrapper heading="Report Group{{ reportGroup.Id > 0 ? ': '+ reportGroupName : ''}}"
    [headingIcon]="icons.layerGroup" (close)="cancel()" [isPopup]="false">
    <form #reportGroupForm="ngForm" (ngSubmit)="submit(reportGroupForm.valid)">
        <div class="col-xs-12 border-top pt-pix-5">
            <div class="col-12 pos-page-body">
                <context-provider provide="props">
                    <pos-row>
                        <pos-column>
                            <pos-form-field label="Name" [validationMessages]="{required: 'Please enter name.'}">
                                <input posInput name="groupName" #groupName="ngModel" type="text" class="form-control"
                                    [(ngModel)]="reportGroup.Name" [required]="true" />
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Category"
                                [validationMessages]="{required: 'Please select category.'}">
                                <ng-select posInput appendTo="body" [items]="reportCategory" bindLabel="Name"
                                    bindValue="Id" placeholder="Select category"
                                    [(ngModel)]="reportGroup.ReportCategoryId" id="reportCategory" name="reportCategory"
                                    [required]="true"></ng-select>
                            </pos-form-field>
                        </pos-column>

                        <pos-column xs="12" class="pt-pix-10 px-pix-5">
                            <div class="col-xs-12 ps-pix-6">
                                <label class="required">Icon</label>
                            </div>
                            <div class="col-lg-3 col-sm-5 col-xs-12 ps-pix-6">
                                <pos-icon-input [(value)]="reportGroup.ReportIcon" [required]="true"
                                    [submitted]="reportGroupForm.submitted" [validationMessage]="'Please enter icon.'">
                                </pos-icon-input>
                            </div>
                        </pos-column>

                        <pos-column *ngIf="reportGroup.PermissionType">
                            <label class="edit-form__section__label pl-5 mb-0">Permission Id: {{
                                reportGroup.PermissionType.PermissionId
                                }}</label>
                        </pos-column>
                    </pos-row>
                </context-provider>
            </div>
        </div>
        <div class="col-xs-12 action-button-container page-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" value="Save">
                Save
            </button>
            <button type="button" class="btn-secondary btn-lg btn btn-cancel pos-button" value="Cancel" (click)="cancel()">
                Cancel
            </button>
        </div>
    </form>
</pos-edit-wrapper>
