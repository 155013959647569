<pos-edit-wrapper (close)="cancelRecipeEditor()" heading="Recipe Editor: {{inventoryProductName}}">
    <form #recipeEditorForm="ngForm" autocomplete="off">
        <div class="col-12 px-4 pt-pix-10 recipe-editor">
            <p-table *ngIf="productInventoryConsumptions.length" [value]="productInventoryConsumptions" [scrollable]="true" [scrollHeight]="screenHeight.toString()">
                <ng-template pTemplate="header">
                    <tr>
                        <th class="w-40">Sales Product</th>
                        <th class="w-20">Size</th>
                        <th class="w-20">Qty</th>
                        <th class="w-40">Instructions</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-i="rowIndex">
                    <tr>
                        <td class="w-40">
                            {{rowData.Product}}
                        </td>
                        <td class="w-20">
                            {{rowData.Size}}
                        </td>
                        <td class="text-right" class="w-20">
                            <input posInput type="number" class="text-right form-control" name="qty{{i}}" #qty="ngModel" [ngClass]="{'error-back-color': (qty.invalid)}" 
                                step="0.0001" [(ngModel)]="rowData.qty" required>
                        </td>
                        <td class="w-75">
                            <textarea posInput rows="2" name="instruction{{i}}" class="form-control"
                                [(ngModel)]="rowData.instructions"></textarea>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <div *ngIf="!productInventoryConsumptions.length" class="h-200 align-center">
                No rows to show
            </div>
        </div>
        <div class="col-12 action-button-container popup-footer mti-15">
            <button type="submit" class="btn btn-lg btn-primary btn-save pos-button" [disabled]="!productInventoryConsumptions.length"
                (click)="editRecipes()">Apply Changes</button>
            <button class="btn btn-lg btn-secondary btn-cancel-modal pos-button" type="button" (click)="cancelRecipeEditor()">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
