<pos-keyboard-wedge [isEnabled]="isKeyboardWedgeEnabled" (onSwipeDetected)="onSwipeDetected($event)">
</pos-keyboard-wedge>
<pos-edit-wrapper [heading]="modalHeaderText" (close)="onClose()">
  <div class="col-xs-12 text-center pt-pix-10">
    <span>{{message}}</span>
  </div>
  <div class="col-12 action-button-container popup-footer">
    <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="onClose()" type="button">Close</button>
  </div>
</pos-edit-wrapper>
