import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import {
  BaseListComponent, AlertsService, SpinnerService, ModalService, PrintTableService,
  ApplicationStateService, Messages, Levels, Permissions, DomainConstants, ConfirmDeleteModalComponent, ExportService, AuthenticationService
} from 'src/app/shared';
import { HardwareInterface } from '../../interface/hardware-interface';
import { HardwareInterfaceService } from '../../services/hardware-interface.service';
import { GridColumn, TemplateColumn } from '@tarktech/tark-ng-utils';
import { finalize } from 'rxjs/operators';
import { HardwareInterfaceEditComponent } from '../hardware-interface-edit/hardware-interface-edit.component';
import { plusWhite, editWhite, deleteWhite, printWhite, hardwareInterfacePlug, externalLink } from 'src/app/shared/components/icon';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'pos-hardware-interface-list',
  templateUrl: './hardware-interface-list.component.html',
})
export class HardwareInterfaceListComponent extends BaseListComponent<HardwareInterface> implements OnInit {

  permission = {
    name: Permissions.SystemConfigurationHardwareInterfaces,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };
  icons = {
    plusWhite, editWhite, deleteWhite, printWhite, hardwareInterfacePlug, externalLink
  };
  hardwareInterfaceColumns: Array<GridColumn> = [];
  printHardwareInterfaceColumns: Array<GridColumn> = [];
  hardwareInterfaceList: Array<HardwareInterface> = [];
  @ViewChild('operationTemplate', { static: true }) operationTemplate: TemplateRef<any>;
  @ViewChild('operationHeaderTemplate', { static: true }) operationHeaderTemplate: TemplateRef<any>;

  constructor(protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected hardwareInterfaceService: HardwareInterfaceService,
    protected printService: PrintTableService,
    protected applicationStateService: ApplicationStateService,
    private authenticationService: AuthenticationService,
    protected route: ActivatedRoute,
    private exportService: ExportService,
    protected router : Router) {
    super(hardwareInterfaceService, alertService, spinnerService, modalService, applicationStateService, printService, route);
  }

  ngOnInit() {
    this.deleteSuccessMessage = Messages.HICDeleted;
    this.hardwareInterfaceColumns = this.configureGridColumns();
  }

  reload() {
    this.spinnerService.show();
    this.hardwareInterfaceService.getAll()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: response => {
          this.hardwareInterfaceList = response == null ? [] : response;
          this.gridData = response;
        }, error: this.alertService.showApiError
      });
  }

  getGridColumns(): Array<GridColumn> {
    return this.hardwareInterfaceColumns;
  }

  configureGridColumns(): Array<GridColumn> {
    const operationColumn = new TemplateColumn({
      itemTemplate: this.operationTemplate,
      Width: '147px',
      headerTemplate: this.operationHeaderTemplate,
      CellClass: 'cell-padding'
    });

    this.hardwareInterfaceColumns = [
      new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '27%' }),
      new GridColumn({ HeaderText: 'Unique Address', Field: 'Guid', IsSortable: true, Width: '65%' })
    ] as Array<GridColumn>;

    _.remove(this.hardwareInterfaceColumns, (column) => {
      return (column.Type === DomainConstants.GridColumnsTypes.EditColumn || column.Type === DomainConstants.GridColumnsTypes.DeleteColumn);
    });

    this.printHardwareInterfaceColumns = [...this.hardwareInterfaceColumns];

    if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      this.hardwareInterfaceColumns.push(operationColumn);
    }
    return this.hardwareInterfaceColumns;
  }

  editItem(id: number, data?: HardwareInterface): void {
    const modalRef = this.modalService.getModalWrapper(HardwareInterfaceEditComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      initialState: {
        id: data ? data.Id : 0
      }
    });

    modal.close.subscribe(res => {
      if (res && res.shouldReload) {
        this.reload();
      }
    });
  }

  protected printData() {
    this.printService.printEmitter.next({ gridColumns: this.printHardwareInterfaceColumns, gridData: this.gridData });
  }

  private exportCSV() {
    this.exportService.exportCSV('hardware-interface', this.hardwareInterfaceList, null, this.printHardwareInterfaceColumns);
  }

  deleteHardwareInterface(data: HardwareInterface): void {
    const modalRef = this.modalService.getModalWrapper(ConfirmDeleteModalComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      initialState: {
        message: Messages.ConfirmDeleteHIC
      }
    });
    modal.close.subscribe(res => {
      if (res && res.shouldDelete) {
        this.delete(data.Id);
      }
    });
  }

  getConfirmDeleteMessage(data: HardwareInterface): string {
    return Messages.ConfirmDeleteHIC;
  }

  viewHICDashboard(data: HardwareInterface) {
    let monitorUrl = new URL(data.MonitorUrl);
    const origin = monitorUrl.origin;
    window.open(origin, '_blank');
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
