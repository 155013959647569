<pos-edit-wrapper (close)="cancelEditing()" [isPopup]="false" [headingIcon]="icons.cashPayoutsSearchDollar"
    heading="{{cashPayout.Id ? 'Edit Cash Payout' : 'Add Cash Payout'}}">
    <form #cashPayoutForm="ngForm" class="edit-form" (ngSubmit)="submit(cashPayoutForm.valid)" autocomplete="off">
        <context-provider provide="props">
            <div class="col-12 pos-modal-body border-top pt-pix-5">
                <div class="col-12">
                    <pos-row>
                        <pos-column>
                            <pos-form-field label="Drawer" [validationMessages]="{required: 'Please select cash drawer.'}">
                                <ng-select posInput [items]="cashDrawerName" bindLabel="drawer_name" bindValue="id"
                                    [required]="true" placeholder="Select drawer" [(ngModel)]="cashPayout.CashDrawerId"
                                    name="cashDrawer">
                                </ng-select>
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="User" [validationMessages]="{required: 'Please select user.'}">
                                <ng-select posInput [items]="users" bindLabel="name" bindValue="id" [required]="true"
                                    placeholder="Select user" [(ngModel)]="cashPayout.UserId" name="user">
                                </ng-select>
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Amount"
                                [validationMessages]="{required: 'Please enter amount.', min: 'Please enter positive amount.'}">
                                <p class="input-group col-200">
                                    <span class="input-group-addon">{{ currencySymbol }}</span>
                                    <input posInput type="number" name="points" #amount="ngModel" class="form-control price-input"
                                        [(ngModel)]="cashPayout.Amount" [required]="true" min="0" posDecimal/>
                                </p>
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Memo" [validationMessages]="{required: 'Please enter cash payout memo.'}" inputStyle="col-lg-4 col-md-8">
                                <textarea posInput class="form-control" id="Memo" name="Memo" [(ngModel)]="cashPayout.Memo"
                                    type="text" rows="5" cols="20" [required]="true"></textarea>
                            </pos-form-field>
                        </pos-column>
                    </pos-row>
                </div>
            </div>
            <div class="col-12 action-button-container page-footer">
                <div class="form-horizontal">
                    <button type="submit" value="Save" class="btn-primary btn-lg btn btn-save pos-button"
                        data-dismiss="modal">Save</button>
                    <button type="button" class="btn-secondary btn-lg btn btn-cancel pos-button" (click)="cancelEditing()"
                        value="Cancel"> Cancel
                    </button>
                </div>
            </div>
        </context-provider>
    </form>
</pos-edit-wrapper>
