import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';

@Injectable()
export class HardwareService {

    hardwareApiUrl = '';
    constructor(private httpService: HttpClient) {
        this.hardwareApiUrl = RuntimeConstants.API_PATH + 'hardwares';
    }
    getHardwaresOfType(hardwareType: string) {
        return this.httpService.get(`${this.hardwareApiUrl}/` + hardwareType);
    }
}
