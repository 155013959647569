import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ConfiguratorService {
  autoCloseInterval: ReturnType<typeof setTimeout>;

  constructor(protected router: Router) { }

  startAutoCloseInterval = () => {
    this.autoCloseInterval = setTimeout(() => {
      if (this.router.url.includes('manage/system-configuration')) {
        this.router.navigate(['/manage/system-configuration']);
      }
    }, 10000);
  }

  clearAutoCloseInterval = () => {
    if (this.autoCloseInterval) {
      clearInterval(this.autoCloseInterval);
    }
  }

}
