import { Injectable, ViewContainerRef, Type } from '@angular/core';
import { DomainConstants } from 'src/app/shared/constants/domain-constants';
import { BannerWidgetComponent } from '../components/banner-widget/banner-widget.component';
import { MenuImageListWidgetComponent } from '../components/menu-image-list-widget/menu-image-list-widget.component';
import { MenuImageWidgetComponent } from '../components/menu-image-widget/menu-image-widget.component';
import { MenuListWidgetComponent } from '../components/menu-list-widget/menu-list-widget.component';
import { MenuTileListWidgetComponent } from '../components/menu-tile-list-widget/menu-tile-list-widget.component';
import { MenuTextWidgetComponent } from '../components/menu-text-widget/menu-text-widget.component';
import { WidgetComponentProperties } from '../interfaces/widget-component-properties';
import { MenuComboListWidgetComponent } from '../components';
import { MenuItemWidgetComponent } from '../components/menu-item-widget/menu-item-widget.component';
import { MenuGroupWidgetComponent } from '../components/menu-group-widget/menu-group-widget.component';
import { MenuVideoWidgetComponent } from '../components/menu-video-widget/menu-video-widget.component';

@Injectable({
  providedIn: 'root'
})
export class DigitalMenuComponentResolverFactoryService {

  constructor() { }

  loadComponent(vcr: ViewContainerRef, widgetType: string, widgetId: number, previewMode: boolean) {
    let component: Type<WidgetComponentProperties>;
    switch (widgetType) {
      case DomainConstants.DigitalMenuWidgetTypes.Image.Name:
        component = MenuImageWidgetComponent;
        break;
      case DomainConstants.DigitalMenuWidgetTypes.List.Name:
        component = MenuListWidgetComponent;
        break;
      case DomainConstants.DigitalMenuWidgetTypes.ImageList.Name:
        component = MenuImageListWidgetComponent;
        break;
      case DomainConstants.DigitalMenuWidgetTypes.Banner.Name:
        component = BannerWidgetComponent;
        break;
      case DomainConstants.DigitalMenuWidgetTypes.TileList.Name:
        component = MenuTileListWidgetComponent;
        break;
      case DomainConstants.DigitalMenuWidgetTypes.ComboItemList.Name:
        component = MenuComboListWidgetComponent;
        break;
      case DomainConstants.DigitalMenuWidgetTypes.Item.Name:
        component = MenuItemWidgetComponent;
        break;
      case DomainConstants.DigitalMenuWidgetTypes.Group.Name:
        component = MenuGroupWidgetComponent;
        break;
      case DomainConstants.DigitalMenuWidgetTypes.Video.Name:
        component = MenuVideoWidgetComponent;
        break; 
      default:
        component = MenuTextWidgetComponent;
        break;
    }
    if (component) {
      vcr.clear();
      const componentRef = vcr.createComponent<WidgetComponentProperties>(component);
      componentRef.instance.widgetId = widgetId;
      componentRef.instance.isPreviewMode = previewMode;
      return componentRef;
    }
  }
}
