<div id="LightingTest">
    <div>
        <h3 class="setting-title">Test Setup</h3>
    </div>
    <div class="col-xs-9">
        <pos-row>
            <pos-column class="pt-pix-10">
                <pos-row class="p-0">
                    <pos-column md="2" xs="12" class="pe-0">
                        <label class="edit-form__section__label">Light Address</label>
                    </pos-column>

                    <pos-column md="2" xs="3" [ngClass]="{'pe-0': true, 'has-error': (!lightAddress && isLightFlash)}">
                        <input type="text" class="form-control" [(ngModel)]="lightAddress" id="LightAddress" pattern="/^(\d|-{1})+$/" name="LightAddress" />
                        <span *ngIf="!lightAddress && isLightFlash">
                            <label class="control-label text-nowrap">Please enter light address.</label>
                        </span>
                    </pos-column>

                    <pos-column xs="2" class="text-right border-right col-100">
                        <button type="button" class="btn btn-warning normal white pos-button" id="LightOn" (click)="triggerFlash()">Flash</button>
                    </pos-column>

                    <pos-column md="5" xs="7">
                        <button type="button" class="btn btn-success white normal me-pix-5 pos-button" id="LightAll" (click)="triggerLightAll()">Light All</button>
                        <button type="button" class="btn btn-secondary normal pos-button" id="UnlightAll" (click)="triggerResetLightAll()">Reset All</button>
                    </pos-column>
                </pos-row>
            </pos-column>
        </pos-row>
    </div>
</div>
