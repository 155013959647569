<pos-edit-wrapper heading="Set Button" (close)="cancel()">
    <form #screenButtonForm="ngForm" (ngSubmit)="save(screenButtonForm.valid)" autocomplete="off">
        <div class="col-12 pos-modal-body">
            <pos-row>
                <pos-column>
                    <pos-form-field label="Button" inputStyle="col-12">
                        <div class="row row-cols-sm-2 row-cols-1 mx-0">
                            <div class="ps-0">
                                <ng-select posInput name="button" bindLabel="ButtonText" bindValue="Id"
                                    [items]="kioskButtons" [(ngModel)]="selectedScreenButtonId"
                                    [clearOnBackspace]="false" appendTo="body" placeholder="Select button">
                                    <ng-template ng-label-tmp let-item="item">
                                        <span class="ng-select-option"><img src="{{imageRootPath}}{{item.Image}}" height="20" *ngIf="item.Image" /></span>
                                            {{item.ButtonText}}
                                    </ng-template>
                                    <ng-template ng-option-tmp let-item="item" let-index="index">
                                        <span class="ng-select-option"><img src="{{imageRootPath}}{{item.Image}}" height="20" *ngIf="item.Image" /></span>
                                            {{item.ButtonText}}
                                    </ng-template>
                                </ng-select>
                            </div>
                            <div class="pt-pix-6 ps-0">
                                <span (click)="openKioskButtonModal(0)">
                                    <pos-action-icon [icon]="icons.plusWhite" iconText="Add">
                                    </pos-action-icon>
                                </span>
                                <span (click)="openKioskButtonModal(selectedScreenButtonId)">
                                    <pos-action-icon class="text-nowrap ms-pix-5" [icon]="icons.editWhiteWithText"
                                        iconText="Edit">
                                    </pos-action-icon>
                                </span>
                            </div>
                        </div>
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save</button>
            <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button"
                (click)="cancel()">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
