import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { AdfColumn, AdfModel } from '../../interfaces';
import { sync, times } from 'src/app/shared/components/icon';
import { DashboardBroadcastingService } from '../../services/dashboard-broadcasting.service';

@Component({
  selector: 'pos-adf-widget-fullscreen',
  templateUrl: './adf-widget-fullscreen.component.html',
  styleUrls: ['./adf-widget-fullscreen.component.scss']
})
export class AdfWidgetFullscreenComponent implements OnInit {

  @Input() adfModel: AdfModel;
  @Input() definition: any;
  @Input() isInteractive: boolean;
  @Input() column: AdfColumn;
  icons = {
    sync, times
  };

  close: EventEmitter<any> = new EventEmitter();

  constructor(private dashboardBroadcastService: DashboardBroadcastingService) { }

  ngOnInit(): void {
    if(this.definition && this.definition.isNewWidget) {
      this.definition.isNewWidget = false;
    }
  }

  reload() {
    this.dashboardBroadcastService.widgetReload(this.definition);
  }

  closeDialog() {
    this.close.emit();
  }

}
