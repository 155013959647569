import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants';
import { HttpClient } from '@angular/common/http';
import { SettingParam } from 'src/app/shared';

@Injectable({
  providedIn: 'root'
})
export class IconsService {

  iconsApiBasePath = RuntimeConstants.API_PATH + 'icons';

  constructor(private httpClient: HttpClient) { }

  getIconsDetail(terminalId) {
    return this.httpClient.get(`${this.iconsApiBasePath}/${terminalId}`);
  }

  saveIcons(params: SettingParam, iconsfile: File) {
    const formData = this.prepareIconsImageForm(params, iconsfile);
    return this.httpClient.post(`${this.iconsApiBasePath}`, formData);
  }

  prepareIconsImageForm(params, imageFile) {
    const formData = new FormData();
    if (imageFile) {
      formData.append('file', imageFile);
    }
    formData.append('model', JSON.stringify(params));
    return formData;
  }
}
