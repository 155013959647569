<pos-edit-wrapper heading="Payments" [headingIcon]="icons.paymentsMoneyBillAlt" (close)="cancelEditing()"
  [isPopup]="false">
  <form #configForm="ngForm" class="edit-form" (ngSubmit)="saveConfig(configForm.valid)">
    <div class="col-12 pos-page-body border-top">
      <div class="col-12">
        <pos-row>
          <pos-column>
            <pos-form-field label="Please Swipe Card Image" inputStyle="col-12">
              <pos-row>
                <pos-column md="5" sm="8" xs="12">
                  <input type="file" class="form-control" accept=".png,.jpg,.jpeg,.gif,.bmp" #pleaseSwipeCardImage
                    name="pleaseSwipeCardImage" id="pleaseSwipeCardImage"
                    (change)="parameters.OrderKioskPleaseSwipeCardImage = handleFileInput($event.target.files, 'swipeCard', 'pleaseSwipeCardImage')">
                </pos-column>
                <pos-column md="3" sm="4" xs="6">
                  <pos-row class="p-0">
                    <pos-column md="3" sm="5" xs="8" class="text-right pe-0"
                      [hidden]="!parameters?.OrderKioskPleaseSwipeCardImage">
                      <img
                        src="{{imageBasePath}}/order-kiosk/default-screens/{{parameters.OrderKioskPleaseSwipeCardImage}}"
                        height="50px" #swipeCard id="swipeCard" />
                    </pos-column>
                    <pos-column sm="4" xs="2" class="mt-pix-n5"
                      (click)="parameters.OrderKioskPleaseSwipeCardImage = removeIcon('swipeCard', 'pleaseSwipeCardImage', parameters.OrderKioskPleaseSwipeCardImage)"
                      [hidden]="!parameters?.OrderKioskPleaseSwipeCardImage">
                      <pos-icon [icon]="icons.times" class="pointer remove-icon-badge"></pos-icon>
                    </pos-column>
                  </pos-row>
                </pos-column>
              </pos-row>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Payment Failed Image" inputStyle="col-12">
              <pos-row>
                <pos-column md="5" sm="8" xs="12">
                  <input type="file" class="form-control" accept=".png,.jpg,.jpeg,.gif,.bmp" #paymentFailed
                    name="paymentFailed" id="paymentFailed"
                    (change)="parameters.OrderKioskPaymentFailedImage = handleFileInput($event.target.files, 'paymentFail', 'paymentFailed')">
                </pos-column>
                <pos-column md="3" sm="4" xs="6">
                  <pos-row class="p-0">
                    <pos-column md="3" sm="5" xs="8" class="text-right pe-0"
                      [hidden]="!parameters?.OrderKioskPaymentFailedImage">
                      <img
                        src="{{imageBasePath}}/order-kiosk/default-screens/{{parameters.OrderKioskPaymentFailedImage}}"
                        height="50px" #paymentFail id="paymentFail" />
                    </pos-column>
                    <pos-column sm="4" xs="2" class="mt-pix-n5"
                      (click)="parameters.OrderKioskPaymentFailedImage = removeIcon('paymentFail', 'paymentFailed', parameters.OrderKioskPaymentFailedImage)"
                      [hidden]="!parameters?.OrderKioskPaymentFailedImage">
                      <pos-icon [icon]="icons.times" class="pointer remove-icon-badge"></pos-icon>
                    </pos-column>
                  </pos-row>
                </pos-column>
              </pos-row>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Payment Terminal Busy Image" inputStyle="col-12">
              <pos-row>
                <pos-column md="5" sm="8" xs="12">
                  <input type="file" class="form-control" accept=".png,.jpg,.jpeg,.gif,.bmp" #terminalBusy
                    name="terminalBusy" id="terminalBusy"
                    (change)="parameters.OrderKioskPaymentTerminalBusyImage = handleFileInput($event.target.files, 'paymentFailByTerminalBusy', 'terminalBusy')">
                </pos-column>
                <pos-column md="3" sm="4" xs="6">
                  <pos-row class="p-0">
                    <pos-column md="3" sm="5" xs="8" class="text-right pe-0"
                      [hidden]="!parameters?.OrderKioskPaymentTerminalBusyImage">
                      <img
                        src="{{imageBasePath}}/order-kiosk/default-screens/{{parameters.OrderKioskPaymentTerminalBusyImage}}"
                        height="50px" #paymentFailByTerminalBusy id="paymentFailByTerminalBusy" />
                    </pos-column>
                    <pos-column sm="4" xs="2" class="mt-pix-n5"
                      (click)="parameters.OrderKioskPaymentTerminalBusyImage = removeIcon('paymentFailByTerminalBusy', 'terminalBusy', parameters.OrderKioskPaymentTerminalBusyImage)"
                      [hidden]="!parameters?.OrderKioskPaymentTerminalBusyImage">
                      <pos-icon [icon]="icons.times" class="pointer remove-icon-badge"></pos-icon>
                    </pos-column>
                  </pos-row>
                </pos-column>
              </pos-row>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field inputStyle="d-flex">
              <p-checkbox posInput name="chkAllowAllowPayAtCounter" #chkAllowPayAtCounter="ngModel"
                [(ngModel)]="parameters.OrderKioskAllowPayAtCounter" binary="true" label="Allow Pay at Counter">
              </p-checkbox>
            </pos-form-field>
          </pos-column>
        </pos-row>
        <div class="col-12 section-border">
          <div>
            <h3 class="setting-title mt-pix-5">Order Style Config</h3>
          </div>
          <pos-row class="pos-title-content" colLg="6" colXs="12">
            <pos-column>
              <pos-form-field label="Background Color">
                <pos-color-picker posInput name="backgroundColor"
                  [(ngModel)]="kioskPaymentStyle.OrderDetailBackgroundColor"></pos-color-picker>
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field label="Font Color">
                <pos-color-picker posInput name="fontColor"
                  [(ngModel)]="kioskPaymentStyle.OrderDetailFontColor"></pos-color-picker>
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field label="Vertical Position">
                <input posInput name="rightPanelWidth" type="text" class="form-control col-120"
                  [(ngModel)]="kioskPaymentStyle.OrderDetailVerticalPosition" />
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field label="Vertical Alignment" inputStyle="col-lg-6 col-12">
                <ng-select posInput name="rightPanelVertical" bindLabel="Name" bindValue="Value"
                  [items]="verticalAlignments" [(ngModel)]="kioskPaymentStyle.OrderDetailVerticalAlignment"
                  [clearOnBackspace]="false" appendTo="body" placeholder="Select vertical alignment"></ng-select>
              </pos-form-field>
            </pos-column>
          </pos-row>
        </div>
      </div>
    </div>
    <div class="col-xs-12 action-button-container page-footer">
      <div class="form-horizontal">
        <button class="btn btn-primary btn-lg btn-save pos-button" scrollup type="submit" value="Save">Save</button>
        <button class="btn btn-secondary btn-lg btn-cancel pos-button" routerLink="../" scrollup type="button"
          value="Cancel">Cancel</button>
      </div>
    </div>
  </form>
</pos-edit-wrapper>
