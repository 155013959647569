import { Component, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseFormComponent } from 'src/app/shared/components';
import { FormUtilityService } from 'src/app/shared/services/form-utility.service';

@Component({
  selector: 'pos-training-area-page',
  templateUrl: './training-area-page.component.html',
  styleUrls: ['./training-area-page.component.scss']
})
export class TrainingAreaPageComponent extends BaseFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.form
  }
  form: NgForm;
  trainingAreaTransParam: any;
  @Input() areaId: number;
  @Input() fromListPage = true;
  initialize = false;
  constructor(private route: ActivatedRoute, private router: Router, formUtilityService: FormUtilityService) {
    super(formUtilityService);
    this.areaId = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
    this.initialize = false;
   }

  ngOnInit() {
    this.initialize = true;
  }

  onClose($event: any) {
    this.router.navigate(['training-areas'], { relativeTo: this.route.parent });
  }

  onFormChange($event: NgForm) {
    this.form = $event;
    this.setInitialValue();
  }
}
