
import { Observable, of } from 'rxjs';
import { HttpParams, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class ReArrangeService {

  constructor(private httpService: HttpClient) { }

  reArrangeButtons(buttonModel: any): Observable<any> {
    return this.httpService.post(`${RuntimeConstants.API_PATH}Orders/RearrangeButtons`, buttonModel);
  }
}
