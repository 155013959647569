import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants';
import { EventSubscription } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class EventSubscriptionService {

  eventSubscriptionApiUrl = `${RuntimeConstants.API_PATH}event-subscription`;

  constructor(private httpService: HttpClient) {
  }

  saveEventSubscription(eventIdentifier: string, eventSubscription: EventSubscription) {
    return this.httpService.post(`${this.eventSubscriptionApiUrl}/${eventIdentifier}`, eventSubscription);
  }

  deleteEventSubscription(eventIdentifier: string, eventSubscriptionId: number) {
    return this.httpService.delete(`${this.eventSubscriptionApiUrl}/${eventIdentifier}/delete-subscription/${eventSubscriptionId}`);
  }

  changeSubscriptionStatus(eventIdentifier: string, subscriptionId: number, isActive: boolean) {
    return this.httpService.post(`${this.eventSubscriptionApiUrl}/${eventIdentifier}/subscription/${subscriptionId}/state/${isActive}`,
      null);
  }
}
