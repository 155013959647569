import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EndOfDayTriggerComponent } from './end-of-day-trigger/end-of-day-trigger.component';
import { StepsModule } from 'primeng/steps';
import { EndOfDayTriggerWrapperComponent } from './end-of-day-trigger-wrapper/end-of-day-trigger-wrapper.component';
import { ComponentsModule, TableConfiguration } from 'src/app/shared';
import { TableModule } from '@tarktech/tark-ng-utils';
@NgModule({
  declarations: [EndOfDayTriggerComponent, EndOfDayTriggerWrapperComponent],
  imports: [
    CommonModule,
    StepsModule,
    ComponentsModule,
    TableModule.forRoot(TableConfiguration),
  ]
})
export class EndOfDayModule { }
