<pos-edit-wrapper (close)="closeModal()" heading="{{selectedButtonBehaviorType.Text}}">
    <form #multiProductForm="ngForm" autocomplete="off" (submit)='saveBehavior(multiProductForm.valid)'>
        <div class="col-12 pos-modal-body screen-scroll">
            <pos-row class="g-0">
                <pos-column>
                    <pos-form-field label="Group Name"
                        [validationMessages]="{required: 'Please enter group name.'}">
                        <ng-select posInput [items]="multiProductGroups" placeholder="Select group"
                            name="groupList" bindLabel="Name" bindValue="Id" [(ngModel)]="groupId"
                            #groupList="ngModel" appendTo="body" [required]="true">
                        </ng-select>
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Promotion">
                        <ng-select posInput [items]="promotions" placeholder="Select promotion"
                            name="promotion" bindLabel="Name" bindValue="Id" [(ngModel)]="promotionId"
                            #promotionList="ngModel" appendTo="body">
                        </ng-select>
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save</button>
            <button type="button" (click)="closeModal()" class="btn-secondary btn-lg btn btn-cancel-modal pos-button">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
