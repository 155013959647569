import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { omit } from 'lodash';
import { finalize } from 'rxjs/operators';
import { cogs, RabbitMQService } from 'src/app/shared';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { Messages } from 'src/app/shared/constants';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { WidgetSaveModel } from '../../interfaces/widget-save-model';
import { MenuWidgetStyleService } from '../../services/menu-widget-style.service';
import { MenuWidgetService } from '../../services/menu-widget.service';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
declare let $: any;

@Component({
  selector: 'pos-menu-widget-config',
  templateUrl: './menu-widget-config.component.html',
  styleUrls: ['./menu-widget-config.component.scss']
})
export class MenuWidgetConfigComponent extends BaseWidgetComponent implements OnInit {
  menuId: number;
  codeMirrorOptions = {
    mode: 'application/ld+json',
    lineNumbers: true,
    lineWrapping: true,
    matchBrackets: true,
    styleActiveLine: true,
    foldGutter: true,
    autoCloseBrackets: true,
    indentWithTabs: true
  };
  widgetConfiguration: string;
  screenHeight: number = 10;
  id: number;
  icons = {
    cogs
  }

  constructor(protected route: ActivatedRoute,
    private router: Router,
    protected menuWidgetStyleService: MenuWidgetStyleService,
    protected menuWidgetService: MenuWidgetService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected applicationStateService: ApplicationStateService,
    protected modalService: ModalService,
    protected rabbitMQService: RabbitMQService) {
    super(menuWidgetService, alertService, spinnerService, applicationStateService, modalService, rabbitMQService);
    this.menuId = route.snapshot.params.menuId ? parseInt(route.snapshot.params.menuId, 10) : 0;
    this.widgetId = route.snapshot.params.widgetId ? parseInt(route.snapshot.params.widgetId, 10) : 0;
  }

  ngOnInit(): void {
    this.screenHeight = $(window).height();
    this.loadWidget(this.widgetId);
    this.loadSubscriptions();
    this.resizeWindow();
  }

  private loadSubscriptions(): void {
    if (this.widgetId) {
      this.spinnerService.show();
      this.menuWidgetService.getWidgetDetails(this.widgetId).pipe(finalize(() => {
        this.spinnerService.hide();
      })).subscribe({
        next: (response) => {
          this.widgetConfiguration = this.setData(response);
        }, error: this.alertService.showApiError
      });
    }
  }

  onCancel() {
    this.router.navigate(['manage/menu-display', this.menuId, 'widgets']);
  }

  editWidget() {

  }

  resizeWindow = () => {
    $(window).resize(() => {
      this.screenHeight = $(window).height();
    });
  }

  saveWidget(isValid: boolean) {
    const prepareData = this.prepareData(this.parseJson<WidgetSaveModel>(this.widgetConfiguration));
    if (!prepareData) {
      return;
    }
    this.spinnerService.show();
    this.menuWidgetService.insert(prepareData).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (response) => {
        this.alertService.renderSuccessMessage(Messages.DigitalMenuWidgetConfigSaveSuccess);
        this.router.navigate(['manage/menu-display', this.menuId, 'widgets']);
      }, error: this.alertService.showApiError
    });
  }

  setData(widget: WidgetSaveModel): string {
    widget.WidgetConfig = JSON.parse(widget.WidgetConfig);
    widget.WidgetDetails = JSON.parse(widget.WidgetDetails);
    widget.WidgetStyle = JSON.parse(widget.WidgetStyle);
    const widgetDisplay = omit(widget, ['Id', 'MenuDisplayId']);
    const widgetData = JSON.stringify(widgetDisplay, null, 2);
    return widgetData;
  }

  prepareData(widgetConfig: WidgetSaveModel) {
    if (!widgetConfig) {
      return;
    }
    widgetConfig.Id = this.widgetId;
    widgetConfig.MenuDisplayId = this.menuId;
    widgetConfig.WidgetConfig = JSON.stringify(widgetConfig?.WidgetConfig);
    widgetConfig.WidgetDetails = JSON.stringify(widgetConfig?.WidgetDetails);
    widgetConfig.WidgetStyle = JSON.stringify(widgetConfig?.WidgetStyle);
    widgetConfig.SalesTags = widgetConfig.SalesTags ? widgetConfig.SalesTags : [];
    widgetConfig.SalesGroups = widgetConfig.SalesGroups ? widgetConfig.SalesGroups : [];
    widgetConfig.SalesCategories = widgetConfig.SalesCategories ? widgetConfig.SalesCategories : [];
    widgetConfig.ComboItems = widgetConfig.ComboItems ? widgetConfig.ComboItems : [];
    return widgetConfig;
  }

}
