import { MessagePayload } from './message-payload';

export class OrderKioskStateUpdatedPayload extends MessagePayload {

    public TerminalId: number;
    public State: boolean;

    constructor(terminalId: number, state: boolean) {
        super();
        this.TerminalId = terminalId;
        this.State = state;
    }
}
