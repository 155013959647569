import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReadOnlyOrderSummaryComponent } from './read-only-order-summary.component';
import { OrderSummaryModule } from 'src/app/order-summary';
import { FormsModule } from '@angular/forms';
import { TableModule, TarkDatePipe, TarkDateTimePipe, TarkTimePipe } from '@tarktech/tark-ng-utils';
import { ComponentsModule } from 'src/app/shared';

@NgModule({
  declarations: [ReadOnlyOrderSummaryComponent],
  imports: [
    CommonModule,
    FormsModule,
    OrderSummaryModule,
    ComponentsModule,
    TableModule
  ],
  providers: [TarkDatePipe, TarkDateTimePipe, TarkTimePipe],
  exports: [ReadOnlyOrderSummaryComponent]
})
export class ReadOnlyOrderSummaryModule { }
