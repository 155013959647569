import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { DateColumn, GridColumn, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { CurrencyColumn } from '@tarktech/tark-ng-utils/table/currency-column';
import { finalize } from 'rxjs/operators';
import { VoidTransaction } from 'src/app/orders/interface/void-transaction';
import { AlertsService, ApplicationStateService, DomainConstants, Messages, ModalService, OrderService, SettingParam, SpinnerService } from 'src/app/shared';
import { ConfirmDeleteModalComponent } from 'src/app/shared/components/confirm-delete-modal/confirm-delete-modal.component';
import { CreditCardTransaction } from '../../interface/credit-card-transaction';

declare var $;
@Component({
  selector: 'pos-credit-card-transactions',
  templateUrl: './credit-card-transactions.component.html',
  styleUrls: ['./credit-card-transactions.component.scss']
})
export class CreditCardTransactionsComponent implements OnInit, AfterViewInit {

  @Input() transactions: Array<CreditCardTransaction> = [];
  @Input() total: number;
  @Input() orderTypeId?: number;
  creditCardTransactionColumns: Array<GridColumn> = [];
  @ViewChild('transactionDetailsTemplate', { static: true }) transactionDetailsTemplate: TemplateRef<any>;
  @ViewChild('voidButtonTemplate', { static: true }) voidButtonTemplate: TemplateRef<any>;
  @Output() closePopOver: EventEmitter<any> = new EventEmitter<any>();

  settingParams: SettingParam;
  transactionTypes = DomainConstants.TransactionTypes;
  paymentTypes = DomainConstants.PaymentModes;
  orderTypes = DomainConstants.ServeMethods;
  constructor(private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private modalService: ModalService,
    private applicationStateService: ApplicationStateService,
    private orderService: OrderService) {
  }

  ngOnInit(): void {
    this.settingParams = this.applicationStateService.settingParam;
    this.configureColumns();
  }

  ngAfterViewInit(): void {
  }

  configureColumns() {

    const transactionTemplate = new TemplateColumn({
      HeaderText: 'Transaction Details',
      itemTemplate: this.transactionDetailsTemplate,
      Width: '28%',
    });

    const voidButtonTemplate = new TemplateColumn({
      itemTemplate: this.voidButtonTemplate,
      Width: '8%',
      TextAlign: TextAlign.Center
    });

    this.creditCardTransactionColumns = [
      new GridColumn({ HeaderText: 'Transaction #', Field: 'TransactionId', Width: '12%' }),
      new CurrencyColumn({ HeaderText: 'Gratuity', Field: 'Gratuity', Width: '10%' }),
      new CurrencyColumn({ HeaderText: 'Amount', Field: 'Amount', Width: '10%' }),
      transactionTemplate,
      new DateColumn({ HeaderText: 'Transaction Date', Field: 'TransactionDate', Width: '15%' }),
      voidButtonTemplate
    ];
    if (this.total && this.transactions.length !== 0) {
      this.transactions.push({
        RegisterId: null, TransactionId: 'Total', Amount: this.total, PaymentType: null, OrderId: null,
        CCTxnRefNumber: null, AuthorizationCode: null, GiftCardNumber: null, LastFourDigits: null, TransactionType: null
      });
    }
  }

  confirmVoidTransaction(transaction: CreditCardTransaction) {
    const modalRef = this.modalService.getModalWrapper(ConfirmDeleteModalComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      initialState: {
        message: Messages.ConfirmVoidTransaction
      }
    });
    modal.close.subscribe(res => {
      if (res && res.shouldDelete) {
        this.voidTransaction(transaction);
      }
    });
  }

  voidTransaction(transaction: CreditCardTransaction) {
    const voidTransaction: VoidTransaction = {
      TerminalId: this.settingParams.TerminalId,
      TerminalName: this.settingParams.TerminalName,
      TransactionId: Number(transaction.TransactionId),
      OrderId: transaction.OrderId
    };
    this.spinnerService.show();
    this.orderService.voidTransaction(voidTransaction)
      .pipe(finalize(() => {
        if (transaction.PaymentType !== this.paymentTypes.CREDITCARD.toString()) {
          this.spinnerService.hide();
        }
      }))
      .subscribe({
        next: () => {
          transaction.TransactionType = this.transactionTypes.Void;
          if (transaction.PaymentType !== this.paymentTypes.CREDITCARD.toString()) {
            this.alertsService.renderSuccessMessage(Messages.TransactionVoidSuccess);
          }
        }, error: this.alertsService.showApiError
      });
  }

  close() {
    this.closePopOver.emit();
  }

}
