import { Component, OnInit, ViewChild } from '@angular/core';
import { EmailTemplate } from '../../interface';
import { SpinnerService, BaseCrudComponent, ModalService } from 'src/app/shared/components';
import { AlertsService, FormUtilityService, Messages } from 'src/app/shared';
import { finalize } from 'rxjs/operators';
import { EmailTemplateService } from '../../service/email-template.service';
import 'codemirror/mode/htmlmixed/htmlmixed';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailTemplatesPreviewComponent } from '../email-templates-preview/email-templates-preview.component';
import { messageCode } from 'src/app/shared/components/icon';
import { sortBy } from 'lodash';
import { emailTemplatesEnvelopeOpen } from 'src/app/shared/components/icon/icons';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'pos-email-templates-edit',
  templateUrl: './email-templates-edit.component.html',
  styleUrls: ['./email-templates-edit.component.scss']
})
export class EmailTemplatesEditComponent extends BaseCrudComponent<EmailTemplate> implements OnInit {
  @ViewChild('emailTemplateForm') emailTemplateForm: NgForm;
  get getForm(): NgForm {
    return this.emailTemplateForm
  }
  emailTemplate: EmailTemplate;
  variables = [];
  isOpenVariable: boolean;
  selectedParameter: string;
  codeMirrorOptions = {
    lineNumbers: true,
    name: 'htmlmixed'
  };
  icons = {
    messageCode,
    emailTemplatesEnvelopeOpen
  };
  ngxCodeMirrorDoc;

  constructor(protected spinnerService: SpinnerService,
    protected alertService: AlertsService,
    protected router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
    protected emailTemplateService: EmailTemplateService,
    formUtilityService: FormUtilityService) {
    super(emailTemplateService, alertService, spinnerService, formUtilityService);
    this.emailTemplate = emailTemplateService.newEmailTemplate();
    this.emailTemplate.Id = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
  }

  ngOnInit() {
    this.getEmailTemplateDetail();
    this.saveSuccessMessage = Messages.EmailTemplateSaveSuccess;
  }

  getEmailTemplateDetail() {
    this.spinnerService.show();
    this.emailTemplateService.get(this.emailTemplate.Id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.emailTemplate = res;
          this.variables = this.emailTemplate.Variables?.split(',');
          this.variables = sortBy(this.variables);

        }, error: (err) => this.alertService.showApiError(err)
      });
  }

  onSaveSuccess(model: EmailTemplate): void {
    this.router.navigate(['email-templates'], { relativeTo: this.route.parent });
  }

  saveEmailTemplate(isValid: boolean) {
    if (!isValid || !this.emailTemplate.Value) {
      return;
    }
    this.save(this.emailTemplate);
  }

  cancel() {
    this.router.navigate(['email-templates'], { relativeTo: this.route.parent });
  }

  preview() {
    const modalRef = this.modalService.getModalWrapper(EmailTemplatesPreviewComponent);
    modalRef.show({
      animated: false,
      class: 'vertical-center modal-lg',
      initialState: {
        emailContent: this.emailTemplate.Value
      }
    });
  }

  getCursorPosition(event) {
    this.ngxCodeMirrorDoc = event.curOp.cm.getDoc();
  }

  changeEmailParameter() {
    this.isOpenVariable = false;
    const cursor = this.ngxCodeMirrorDoc?.getCursor();
    if (cursor) {
      this.ngxCodeMirrorDoc.replaceRange('{{' + this.selectedParameter + '}}', cursor);
    } else {
      this.emailTemplate.Value = this.emailTemplate.Value + '{{' + this.selectedParameter + '}}';
    }
    this.selectedParameter = null;
  }

  loadDependencies(): void {
    // no dependencies to load
  }
}
