import { check } from 'src/app/shared/components/icon';
import { infoManageShoppingCart, ordinalBars, times } from './../../../../../shared/components/icon/icons';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AlertsService, BaseFormComponent, FormUtilityService, SpinnerService } from 'src/app/shared';
import { bookmark, layerGroup, boxes, editWhite, plusWhite, cogWhite, deleteWhite, clock } from '../../../../../shared/components/icon';
import { NgForm } from '@angular/forms';
import { OnlineOrderingCategoriesService } from '../../../service/online-ordering-categories.service';
import { OnlineOrderingCategory, OnlineOrderingCategorySection, OnlineOrderingCategoryProduct } from '../../../interface';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { zip } from 'rxjs';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { TemplateColumn, GridColumn } from '@tarktech/tark-ng-utils';
import * as _ from 'lodash';
import { OnlineOrderingCategoryProductComponent } from '../online-ordering-category-product/online-ordering-category-product.component';
import { OnlineOrderingCategoryProductService } from '../../../service/online-ordering-category-product.service';
import { OnlineOrderingOptionProductConfigurationComponent } from '../../online-ordering-options/online-ordering-option-product-configuration';
import { ConfirmDeleteComponent } from 'src/app/shared/components/confirm-delete/confirm-delete.component';
import { ActivatedRoute, Router } from '@angular/router';
// import { customCustomFilerComponet }
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { ExtensionValidationService } from 'src/app/shared/services/extension-validation.service';
declare let $: any;

@Component({
  selector: 'pos-online-ordering-category-edit',
  templateUrl: './online-ordering-category-edit.component.html',
  styleUrls: ['./online-ordering-category-edit.component.scss']
})
export class OnlineOrderingCategoryEditComponent extends BaseFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.form
  }
  onlineOrderingCategoryParam: any;
  public icons = {
    bookmark,
    layerGroup,
    boxes,
    editWhite,
    plusWhite,
    cogWhite,
    deleteWhite,
    ordinalBars,
    check,
    clock,
    infoManageShoppingCart,
    times
  };
  categoryIcon: File;
  sectionIcon: File;
  showCategoryIcon = false;
  orderingCategoryId: number;
  @ViewChild('orderingCategory', { static: true }) form: NgForm;
  @ViewChild('categoryImage') categoryImage: ElementRef;
  @ViewChild('nameTemplate', { static: true }) private nameTemplate: any;
  @ViewChild('iconTemplate', { static: true }) private iconTemplate: any;
  @ViewChild('iconTemplate1', { static: true }) private iconTemplate1: any;
  @ViewChild('descriptionTemplate', { static: true }) private descriptionTemplate: any;
  @ViewChild('confirmButtonDelete') private confirmButtonDelete: ConfirmDeleteComponent;
  @ViewChild('confirmSectionDelete') private confirmSectionDelete: ConfirmDeleteComponent;

  orderingCategorySectionsColumns: Array<GridColumn> = [];
  orderingCategoryData: OnlineOrderingCategory;
  orderingCategorySections: Array<OnlineOrderingCategorySection> = [];
  orderingCategoryProducts: Array<OnlineOrderingCategoryProduct> = [];
  rowGroupMetadata: any;
  orderingProductList: Array<any> = [];
  selectedProduct: any;
  selectedSection: any = null;
  productsWithSection: any = [];
  public message = Messages.ConfirmDeleteMenuButton;
  public sectionDeleteMessage = Messages.ConfirmDeleteMenuSection;
  resolutionWarningMessage = '';
  deleteProductId: any;
  deleteSectionId: any;
  uploadedImages: any = [];
  categoryIconName = '';
  currentCategoryName = '';
  productDetails: any;
  searchFilter = '';
  tabList = {
    CategorySetting: 'CategorySetting',
    SectionsSetting: 'SectionsSetting',
    ProductsSettings: 'ProductsSettings',
    ScheduleSettings: 'ScheduleSettings'
  };
  selectedTab = this.tabList.CategorySetting;

  constructor(
    private onlineOrderingCategoriesService: OnlineOrderingCategoriesService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
    private onlineOrderingCategoryProductService: OnlineOrderingCategoryProductService,
    private extensionValidation: ExtensionValidationService,
    formUtilityService: FormUtilityService) {
    super(formUtilityService);
    this.orderingCategoryData = onlineOrderingCategoriesService.newOrderingCategory();
    // this.orderingCategorySections.push(onlineOrderingCategoriesService.newOrderingCategorySection());
    this.orderingCategoryId = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
  }

  ngOnInit() {
    this.columnConfiguration();
    this.getOrderingCategoryDetails(this.orderingCategoryId);
    this.setInitialValue();
  }

  private columnConfiguration() {

    const iconColumn = new TemplateColumn({
      HeaderText: 'Icon',
      itemTemplate: this.iconTemplate,
      Width: '32%'
    });

    const nameColumn = new TemplateColumn({
      HeaderText: 'Name',
      itemTemplate: this.nameTemplate,
      Width: '32%'
    });

    const descriptionColumn = new TemplateColumn({
      HeaderText: 'Description',
      itemTemplate: this.descriptionTemplate,
      Width: '30%'
    });

    const deleteColumn = new TemplateColumn({
      // Clicked: (data) => {
      //   this.deleteSection(data);
      // },
      itemTemplate: this.iconTemplate1,
      Width: '40px'
    });

    this.orderingCategorySectionsColumns = [
      nameColumn,
      descriptionColumn,
      iconColumn,
      deleteColumn
    ] as Array<GridColumn>;

  }

  getHeight = function () {
    return $(window).height() - 310 + 'px';
  };

  removeTimeStamp(icon: string): string {
    if (icon) {
      const index = icon.lastIndexOf('.');
      const suffix = icon.substr(index);
      const timeIndex = icon.lastIndexOf('-');
      let iconName = icon.substr(0, index);
      if (icon.substr(timeIndex).length >= (suffix.length + 19)) {
        iconName = icon.substr(0, timeIndex);
      }
      return iconName + suffix;
    }
  }

  removeTimeStampFromCategoryIcon() {
    this.categoryIconName = this.removeTimeStamp(this.orderingCategoryData.Icon);
  }

  removeTimeStampFromSectionsIcon() {
    this.orderingCategorySections.forEach(section => {
      section.iconName = this.removeTimeStamp(section.Icon);
    });
  }

  getOrderingCategoryDetails(categoryId: number, isSilentCall: boolean = false) {
    if (categoryId > 0) {
      if (!isSilentCall) {
        this.spinnerService.show();
      }
      zip(
        this.onlineOrderingCategoriesService.getOnlineOrderingCategory(categoryId, false),
        this.onlineOrderingCategoryProductService.getOnlineOrderingCategoryProducts(),
        (
          categoryData: any,
          buttonList: any,
        ) => {
          this.orderingCategoryData = categoryData.OnlineOrderingCategory;
          this.orderingCategorySections = categoryData.OnlineOrderingCategorySections;
          this.addDefaultSection();
          this.addNewSection();
          this.currentCategoryName = this.orderingCategoryData.Name;
          this.orderingCategorySections = _.orderBy(this.orderingCategorySections, 'Ordinal');
          this.orderingCategoryProducts = categoryData.OnlineOrderingProducts;
          if (this.orderingCategoryProducts.length > 0) {
            this.prepareMetaData();
          } else {
            this.productsWithSection = [];
          }
          this.orderingProductList = _.filter(buttonList, function (product) {
            return !product.IsToggle;
          });
          this.removeTimeStampFromCategoryIcon();
          this.removeTimeStampFromSectionsIcon();
        }
      ).subscribe({
        next: (res) => {
        }, error: this.alertService.showApiError,
        complete: () => {
          if (!isSilentCall) {
            this.spinnerService.hide();
          }
        }
      });

    } else {
      this.orderingCategoryData = this.onlineOrderingCategoriesService.newOrderingCategory();
      this.currentCategoryName = this.orderingCategoryData.Name;
    }

  }

  createSectionRow(section) {
    if (section.Name == '' && section.Id == 0 && !section.IsChanged) {
      this.addNewSection();
    }
    section.IsChanged = true;
  }

  addNewSection() {
    const sections = _.filter(this.orderingCategorySections, (section) => {
      return !section.IsDefault;
    });
    const sectionList = _.orderBy(sections, ['Ordinal']);
    const lastOrdinal = sectionList.length > 0 ? sectionList[sectionList.length - 1].Ordinal + 1 : 1;
    const section = this.onlineOrderingCategoriesService.newOrderingCategorySection();
    section.Name = '';
    section.Ordinal = lastOrdinal;
    section.CategoryId = this.orderingCategoryData.Id;
    this.orderingCategorySections.push(section);
    this.orderingCategorySections = _.orderBy(this.orderingCategorySections, ['Ordinal']);
  }

  addDefaultSection() {
    const defaultSection = this.onlineOrderingCategoriesService.newOrderingCategorySection();
    defaultSection.Name = 'Default'
    defaultSection.Ordinal = 0;
    defaultSection.IsDefault = true;
    this.orderingCategorySections.push(defaultSection);
  }

  onCancel(reload: boolean) {
    this.router.navigate(['online-ordering-categories'], { relativeTo: this.route.parent });
  }

  saveOrderingCategory(isValid: boolean) {
    let sections = [];
    sections = _.filter(this.orderingCategorySections, (section) => {
      if (section.Id == 0) {
        section.Ordinal = 0;
      }
      return !section.IsDefault && section.Name != '';
    });
    // section.IsChanged == true &&
    const onlineOrderingCategoryData = {
      OnlineOrderingCategory: this.orderingCategoryData,
      OnlineOrderingCategorySections: sections,
    };
    const fileToUpload = this.categoryIcon ? this.categoryIcon.name : '';
    if (!isValid || (fileToUpload != '' && this.extensionValidation.isInvalidExtension(fileToUpload)) || this.orderingCategoryData.Icon == '') {
      return;
    }
    this.spinnerService.show();
    sections = _.orderBy(sections, ['IsChangedImage', 'Id', 'Ordinal', 'Icon']);
    this.uploadedImages = _.orderBy(this.uploadedImages, ['Id', 'Ordinal']);
    zip(
      this.onlineOrderingCategoriesService.saveOnlineOrderingCategory(this.orderingCategoryData, this.categoryIcon),
      this.onlineOrderingCategoriesService.saveOnlineOrderingCategorySection(sections, this.uploadedImages),
      (
        categoryResponse: any,
        sectionResponse: any
      ) => {
        this.alertService.renderSuccessMessage(Messages.OnlineOrderingCategorySaveSuccess);
        this.onCancel(true);
      }
    ).subscribe({
      next: (res) => {
      }, error: this.alertService.showApiError,
      complete: () => {
        this.spinnerService.hide();
      }
    });

  }

  handleFileInput(files: FileList) {
    this.categoryIcon = files.item(0);
    const fileToUpload = this.categoryIcon ? this.categoryIcon.name : '';
    if (this.extensionValidation.isInvalidExtension(fileToUpload)) {
      if (fileToUpload) {
        this.alertService.renderErrorMessage(Messages.InvalidReceiptLogoFileType);
      }
      this.removeIcon();
      return;
    }

    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        $('#categoryImage').attr('src', reader.result);
        setTimeout(() => { this.checkCategoryImageResolution(); });
      };
      reader.readAsDataURL(files[0]);
      this.showCategoryIcon = true;
      this.orderingCategoryData.Icon = this.categoryIcon.name;
    }
  }

  checkCategoryImageResolution() {
    if (this.categoryImage && (this.categoryImage.nativeElement.naturalWidth < 229 || this.categoryImage.nativeElement.naturalHeight < 139)) {
      this.resolutionWarningMessage = StringUtils.format(Messages.OnlineOrderingImageResolutionWarning, { 'resolution': '229X139' });
      // this.alertService.renderWarningMessage(message)

      this.orderingCategoryData.hasImageResolutionError = true;
    } else {
      this.orderingCategoryData.hasImageResolutionError = false;;
    }
  }

  handleSectionFileInput(files: FileList, index: string, section) {
    this.sectionIcon = files.item(0);
    const fileToUpload = this.sectionIcon ? this.sectionIcon.name : '';
    if (this.extensionValidation.isInvalidExtension(fileToUpload)) {
      if (fileToUpload) {
        this.alertService.renderErrorMessage(Messages.InvalidReceiptLogoFileType);
      }
      this.removeSectionIcon(index, section);
      return;
    }
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        $(('#sectionImage' + index) + section.Ordinal.toString()).attr('src', reader.result);
        setTimeout(() => { this.checkSectionImageResolution(('#sectionImage' + index) + section.Ordinal.toString(), section); });
      };
      reader.readAsDataURL(files[0]);
      section.Icon = this.sectionIcon ? this.sectionIcon.name : '';
    }
    this.createSectionRow(section);
    // section.IsChanged = true;
    section.IsChangedImage = true;
    const imageIndex = _.findIndex(this.uploadedImages, (image: any) => {
      return image.Id == section.Id.toString() + section.Ordinal.toString();
    });

    if (imageIndex == -1) {
      this.uploadedImages.push(this.sectionIcon);
      this.uploadedImages[this.uploadedImages.length - 1].Id = section.Id.toString() + section.Ordinal.toString();
      this.uploadedImages[this.uploadedImages.length - 1].Ordinal = section.Ordinal.toString();
    } else {
      this.uploadedImages[imageIndex] = this.sectionIcon;
      this.uploadedImages[imageIndex].Id = section.Id.toString() + section.Ordinal.toString();
      this.uploadedImages[imageIndex].Ordinal = section.Ordinal.toString();
    }

  }

  checkSectionImageResolution(id, section) {
    let image = new Image();
    image.src = $(id).attr('src');
    if (this.categoryImage && (image.naturalWidth < 229 || image.naturalHeight < 139)) {
      section.hasImageResolutionError = true;
      this.resolutionWarningMessage = StringUtils.format(Messages.OnlineOrderingImageResolutionWarning, { 'resolution': '229X139' });
      // let message = StringUtils.format(Messages.OnlineOrderingImageResolutionWarning, { 'preferableResolution': '229X139', 'enteredResolution':  (image.naturalWidth) + "X" + (image.naturalHeight)});
      // this.alertService.renderWarningMessage(message)
    } else {
      section.hasImageResolutionError = false;
    }
  }

  removeIcon() {
    this.orderingCategoryData.Icon = '';
    $('#categoryIcon').val('');
    this.showCategoryIcon = false;
    this.categoryIconName = '';
  }

  removeSectionIcon(index, section) {
    section.Icon = '';
    $(('#imageUpload' + index) + section.Ordinal).val('');
    section.IsChanged = true;
    section.iconName = '';
    this.uploadedImages = _.filter(this.uploadedImages, function (item) {
      return item.Id != index.toString() + section.Ordinal.toString();
    });
    const removedSections = _.remove(this.orderingCategorySections, function (section) {
      return section.Id == 0 && section.Name == '' && section.Description == '' && !section.IsDefault && section.IsChanged == true;
    });
  }

  prepareMetaData() {
    this.productsWithSection = _.groupBy(this.orderingCategoryProducts, function (product) { return product.SectionName; });

    _.forEach(this.productsWithSection, (param, index) => {
      this.productsWithSection[index] = _.orderBy(param, 'Ordinal');
    });
  }

  gridRowReorder(event) {
    const dragElementIndex = event.dragIndex;
    const dropElementIndex = event.dropIndex;
    dragElementIndex < dropElementIndex ?
      (this.orderingCategorySections[dropElementIndex - 1].Ordinal = dropElementIndex) :
      (this.orderingCategorySections[dropElementIndex].Ordinal = dropElementIndex + 1);
    const section = dragElementIndex < dropElementIndex ?
      this.orderingCategorySections[dropElementIndex - 1] : this.orderingCategorySections[dropElementIndex];
    if (!section.IsDefault && section.Id > 0) {
      this.onlineOrderingCategoriesService.updateOnlineOrderingCategorySection(section).subscribe(
        () => { },
        this.alertService.showApiError,
        () => { }
      );
    } else {
      section.Ordinal = Number.MAX_VALUE;
    }

  }

  ProductGridRecorder(event, products) {
    this.onlineOrderingCategoryProductService.updateOnlineOrderingProductOrdinal(products).subscribe(
      (res) => { products = res ? res : []; },
      this.alertService.showApiError,
      () => { }
    );
  }

  editProduct(product) {
    this.productDetails = product;
    const productModalRef = this.modalService.show(OnlineOrderingCategoryProductComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        buttonId: product ? product.Id : 0,
        categorySectionList: _.filter(this.orderingCategorySections, (section) => {
          return section.Name != '' && (section.Id > 0 || section.IsDefault);
        }),
        openFromCategory: true,
        selectedSection: product ? product.SectionId : null
      }
    });

    productModalRef.close.subscribe(res => {
      if (res && res.shouldReload) {
        if (res.buttonId) {
          this.saveButtonToSection(res.SectionId, res.buttonId, false);
        } else {
          if (res.SectionId != this.selectedSection || res.sectionId == null) {
            this.saveButtonToSection(res.SectionId, this.productDetails.Id, true, this.productDetails.OptionButtonId);
          } else {
            this.getOrderingCategoryDetails(this.orderingCategoryId, true);
          }
        }
      }
    });
  }

  showProductConfiguration(data: any) {
    this.modalService.show(OnlineOrderingOptionProductConfigurationComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        productName: data.ButtonText,
        productId: data.Id
      }
    });
  }

  addButtonToSection() {
    if (this.orderingCategoryProducts && this.orderingCategoryProducts.length > 0) {
      let index = _.findIndex(this.orderingCategoryProducts, (optionProduct) => {
        return optionProduct.Id == this.selectedProduct && optionProduct.SectionId == null;
      });

      if (index >= 0) {
        this.alertService.renderErrorMessage(Messages.ErrorWhileOnlineOrderingAddingDuplicateButton);
        return;
      }
    }

    if (this.selectedProduct) {
      this.saveButtonToSection(null, this.selectedProduct);
    }

  }

  deleteProduct(product): void {
    this.deleteProductId = product.OptionButtonId;
    this.message = StringUtils.format(Messages.ConfirmDeleteMenuButton, { 'buttonText': product.ButtonText, 'categoryName': this.currentCategoryName });
    this.confirmButtonDelete.Show(this.message);
  }


  deleteSection(section): void {
    if (section.Id > 0) {
      this.deleteSectionId = section.Id;
      this.confirmSectionDelete.Show(this.sectionDeleteMessage);
    }
  }


  onConfirmAcknowledge() {
    this.spinnerService.show();
    this.onlineOrderingCategoriesService.deleteButtonToOption(this.deleteProductId).subscribe(
      () => {
        this.alertService.renderSuccessMessage(
          Messages.MenuButtonDeleted
        );
        this.getOrderingCategoryDetails(this.orderingCategoryId);
      },
      this.alertService.showApiError,
      () => {
        this.spinnerService.hide();
      }
    );
  }

  onConfirmDeleteSection() {
    this.spinnerService.show();
    this.onlineOrderingCategoriesService.deleteSection(this.deleteSectionId).subscribe(
      () => {
        this.alertService.renderSuccessMessage(
          Messages.MenuSectionDeleted
        );
        this.getOrderingCategoryDetails(this.orderingCategoryId);
      },
      this.alertService.showApiError,
      () => {
        this.spinnerService.hide();
      }
    );
  }

  saveButtonToSection(sectionId: number, buttonId: number, isUpdate: boolean = false, optionId: number = 0) {
    const buttonDetails = {
      CategoryId: this.orderingCategoryId,
      ButtonId: buttonId,
      Ordinal: isUpdate && this.productDetails ? this.productDetails.Ordinal : 0,
      SectionId: sectionId > 0 ? sectionId : null,
      Id: 0
    };
    if (!isUpdate) {
      this.onlineOrderingCategoriesService.addButtonToOption(buttonDetails).subscribe(
        () => {
          this.alertService.renderSuccessMessage(Messages.OnlineOrderingButtonAddedSuccess);
          this.getOrderingCategoryDetails(this.orderingCategoryId);
          this.selectedProduct = null;
          this.selectedSection = null;
        },
        this.alertService.showApiError,
        () => { }
      );
    } else {
      buttonDetails.Id = optionId;
      this.onlineOrderingCategoriesService.updateButtonToOption(buttonDetails).subscribe(
        () => {

          this.getOrderingCategoryDetails(this.orderingCategoryId, true);
          this.selectedProduct = null;
          this.selectedSection = null;
        },
        this.alertService.showApiError,
        () => { }
      );
    }
  }
}
