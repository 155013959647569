import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalComponent, ColorUtilityService, AlertsService, SpinnerService, ApplicationStateService, WorkScheduleService } from 'src/app/shared';
import { BsModalRef } from 'ngx-bootstrap/modal';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { EventInput, ButtonTextCompoundInput, ToolbarInput, CalendarOptions, Calendar } from '@fullcalendar/core';
import { WorkScheduleTag, UserWorkSchedule, ParameterName, WeekDays, UserWorkScheduler, UserWorkScheduleState } from '../../interface';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { backward, forward } from 'src/app/shared/components/icon';
import { DatePipe } from '@angular/common';
import { ParameterService } from 'src/app/shared/services/parameter.service';
import { Router } from '@angular/router';
import { WorkScheduleTagService } from 'src/app/shared/services/work-schedule-tag.service';
import { FullCalendarComponent } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import momentPlugin from '@fullcalendar/moment';
declare let $: any;

@Component({
    selector: 'pos-user-work-schedule',
    templateUrl: './user-work-schedule.component.html',
    styleUrls: ['./user-work-schedule.component.scss']
})
export class UserWorkScheduleComponent extends ModalComponent implements OnInit {
    @ViewChild('calendar', { static: false }) calendarComponent: FullCalendarComponent;


    @ViewChild('currentUserCalendar', { static: false }) currentUserCalendar: FullCalendarComponent;
    calendarPlugins = [timeGridPlugin, listPlugin, dayGridPlugin, momentPlugin];
    headerOptions: ToolbarInput = {
        left: 'prev,next,today',
        center: 'title',
        right: 'timeGridWeek,listWeek'
    };

    icons = {
        forward,
        backward
    };
    headerButtonText: ButtonTextCompoundInput = {
        next: 'Next Week',
        prev: 'Previous Week',
        today: 'Today',
        week: 'Week',
        list: 'Agenda'
    };
    calendarEvents: EventInput[] = [];
    calendarOptions: CalendarOptions;
    isOnlyCurrentUserSchedules = false;
    removeHeader = false;
    startDate: Date = null;
    endDate: Date = null;
    tags: WorkScheduleTag[] = [];
    weekStartDay = 1;
    searchCalendarEvents: EventInput[] = [];
    dateFormat = 'mm-dd-yyyy';

    constructor(
        userWorkScheduleModalRef: BsModalRef,
        private colorUtilityService: ColorUtilityService,
        private workScheduleTagService: WorkScheduleTagService,
        private parameterService: ParameterService,
        private workScheduleService: WorkScheduleService,
        private alertService: AlertsService,
        private spinnerService: SpinnerService,
        private applicationStateService: ApplicationStateService,
        private datePipe: DatePipe,
        private router: Router
    ) {
        super(userWorkScheduleModalRef);
    }

    ngOnInit() {
        if (UserWorkScheduleState.State === this.router.url) {
            this.removeHeader = true;
        }
        this.calendarOptions = {
            initialView: 'timeGridWeek', headerToolbar: this.headerOptions,
            buttonText: this.headerButtonText,
            plugins: this.calendarPlugins,
            allDaySlot: false,
            weekends: true,
            slotEventOverlap: false,
            height: this.removeHeader ? ($(window).height() - 200).toString() + 'px' : ($(window).height() - 258).toString() + 'px',
            firstDay: Number(WeekDays[this.applicationStateService.settingParam.WeekStart]),
            eventMouseEnter: this.onEventHover,
            noEventsText: 'No schedules.',
            events: this.calendarEvents,
            dayHeaderFormat: `dddd ${this.applicationStateService.settingParam.DateFormat.toUpperCase()}`
        };
        this.dateFormat = this.applicationStateService.settingParam.PCalendarDateFormat;
        this.loadCalenderData();
        this.setCalendarOptions();
        this.loadData();


    }

    private setCalendarOptions(): void {
        this.headerOptions = {
            left: 'prev next today',
            center: 'title',
            right: 'timeGridWeek,listWeek'
        };
        this.calendarPlugins = [timeGridPlugin, listPlugin];
        this.headerButtonText = {
            next: 'Next Week',
            prev: 'Previous Week',
            week: 'Week',
            list: 'Agenda'
        };
    }

    getWorkSchedules() {
        this.calendarOptions.events = [];
        this.spinnerService.show();
        this.workScheduleService.getUserWorkSchedules(this.setUserWorkSchedule())
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (res) => {
                    this.loadCalenderEvents(res);
                }, error: this.alertService.showApiError
            });
    }

    private loadData(): void {
        this.spinnerService.show();
        this.workScheduleTagService.getWorkScheduleTags()
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (res) => {
                    this.tags = res?.length ? res : [];
                }, error: this.alertService.showApiError
            });
    }

    private loadCalenderData(): void {

        this.calendarOptions.datesSet = (generatorOrNext) => {
            this.startDate = new Date(generatorOrNext.view.activeStart);
            this.endDate = new Date(generatorOrNext.view.activeEnd.setDate(generatorOrNext.view.activeEnd.getDate() - 1));
            this.getWorkSchedules();
        }

    }

    private loadCalenderEvents(userWorkSchedules: UserWorkSchedule[]): void {
        if (userWorkSchedules) {
            const calendarEvents: EventInput[] = [];
            userWorkSchedules.forEach((userWorkSchedule: UserWorkSchedule) => {
                const calendarEvent: EventInput = {
                    color: userWorkSchedule.Color ? userWorkSchedule.Color : 'lightgray',
                    end: this.convertDateToStringFormat(userWorkSchedule.EndDate, 'yyyy-MM-dd HH:mm'),
                    start: this.convertDateToStringFormat(userWorkSchedule.StartDate, 'yyyy-MM-dd HH:mm'),
                    textColor: this.colorUtilityService.getContrastColor(
                        userWorkSchedule.Color ? userWorkSchedule.Color : 'lightgray'),
                    title: this.toCamelCase(userWorkSchedule.FirstName) + ' ' + this.toCamelCase(userWorkSchedule.LastName),
                    id: userWorkSchedule.UserId?.toString(),
                    extendedProps: userWorkSchedule,
                    className: 'right-auto'
                };
                calendarEvents.push(calendarEvent);
            });

            this.calendarOptions.events = calendarEvents;
            this.searchCalendarEvents = calendarEvents;
        }
    }

    private setUserWorkSchedule(): UserWorkScheduler {
        const userSchedule: UserWorkScheduler = {
            UserId: this.isOnlyCurrentUserSchedules ? this.applicationStateService.userId : null,
            StartDate: this.startDate,
            EndDate: new Date(this.endDate.setDate(this.endDate.getDate() + 1))
        };
        return userSchedule;
    }

    private convertDateToStringFormat(date: any, format: string) {
        return this.datePipe.transform(date, format);
    }

    public closeUserWorkSchedule(): void {
        this.hide({ shouldReload: false });
    }
    public reloadWorkSchedules(): void {
        this.calendarEvents = [];
        this.calendarOptions.initialDate = this.startDate;
        if (this.isOnlyCurrentUserSchedules) {
            const currentUserCalenderEvents = this.searchCalendarEvents.filter(ce => ce.id === this.applicationStateService.userId?.toString());
            this.calendarOptions.events = currentUserCalenderEvents;

        } else {
            this.calendarOptions.events = this.searchCalendarEvents;
        }
    }
    public searchWorkSchedules(): void {
        const calendarApi = this.isOnlyCurrentUserSchedules ? this.currentUserCalendar.getApi() : this.calendarComponent.getApi();
        calendarApi.gotoDate(this.startDate);
    }
    public getContrastColor(color: string) {
        return this.colorUtilityService.getContrastColor(color);
    }
    public toCamelCase(tagName: string): string {
        if (!tagName || tagName.length < 2) {
            return tagName;
        }
        return (tagName.charAt(0).toUpperCase() + tagName.slice(1));
    }
    public onEventHover(model): void {
        model.el.setAttribute('title', model.event.title);
        model.el.setAttribute('data-toggle', 'tooltip');
        model.el.setAttribute('data-placement', 'bottom');
    }
}
