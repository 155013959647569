<div class="tab-top-border border-top-0" [ngStyle]="{'height':getHeight(false)}">
  <div class="form-group col-md-9 col-12">
    <context-provider provide="props">
      <pos-row>
        <pos-column>
          <pos-form-field label="Name" [validationMessages]="{required: 'Please enter inventory zone name.'}">
            <input posInput class="form-control" name="inventoryZoneName" #inventoryZoneName="ngModel"
              [(ngModel)]="inventoryZone.Name" [required]="true" type="text" />
          </pos-form-field>
        </pos-column>
        <pos-column>
          <pos-form-field label="Code" [validationMessages]="{required: 'Please enter inventory zone code.'}"
            inputStyle="col-lg-2 col-md-3 col-sm-6 col-12">
            <input posInput class="form-control" name="inventoryZoneCode" #inventoryZoneCode="ngModel"
              [(ngModel)]="inventoryZone.Code" [required]="true" type="text" />
          </pos-form-field>
        </pos-column>
        <pos-column>
          <pos-form-field label="Label" inputStyle="col-lg-2 col-md-3 col-sm-6 col-12">
            <input posInput class="form-control" [(ngModel)]="inventoryZone.Label" type="text"
              #inventoryZoneDetailLabel="ngModel" name="inventoryZoneDetailLabel" />
          </pos-form-field>
        </pos-column>
        <pos-column>
          <pos-form-field label="Color" [validationMessages]="{required: 'Please enter inventory zone color.'}">
            <pos-color-picker posInput [(ngModel)]="inventoryZone.Color" name="inventoryZoneColor"
              #inventoryZoneColor="ngModel" [required]="true">
            </pos-color-picker>
          </pos-form-field>
        </pos-column>
        <pos-column>
          <pos-form-field label="Description">
            <input posInput class="form-control" [(ngModel)]="inventoryZone.Description" type="text"
              #inventoryZoneDetailDescription="ngModel" name="inventoryZoneDetailDescription" />
          </pos-form-field>
        </pos-column>
      </pos-row>
    </context-provider>
  </div>
</div>
