import { AlertsService } from './../../../../shared/services/alerts.service';
import { ApplicationStateService } from './../../../../shared/services/application-state.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { RabbitMQService, MathsUtilityService, SpinnerService, Messages, ModalBackdropService } from 'src/app/shared';
import * as _ from 'lodash';
import { InventoryLogService } from 'src/app/shared/services/inventory-log.service';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'pos-log-inventory-on-exhaustion',
  templateUrl: './log-inventory-on-exhaustion.component.html'
})
export class LogInventoryOnExhaustionComponent implements OnInit, OnDestroy {

  inventoryExhaustionEventSubscription: Subscription;
  modalRef: BsModalRef;
  confirmInventoryModalRef: BsModalRef;
  settingParam: any;
  inventoryProduct: any = {};
  eventMessage: any;
  salesInventoryProducts: any = [];
  inventoryProductList = [];
  exhaustionMessage = 'Inventory exhausted has been calculated for this product. Please check inventory and log current on-hand quantity.';

  @ViewChild('logInventoryProductModal') public logInventoryProductModal: any;
  constructor(private rabbitMQService: RabbitMQService,
    private modalService: BsModalService,
    private modalBackdropService: ModalBackdropService,
    private applicationStateService: ApplicationStateService,
    private alertService: AlertsService,
    private mathsUtilityService: MathsUtilityService,
    private inventoryLogService: InventoryLogService,
    private spinnerService: SpinnerService) {
  }

  ngOnDestroy() {
    if (this.inventoryExhaustionEventSubscription) {
      this.inventoryExhaustionEventSubscription.unsubscribe();
    }

  }

  ngOnInit() {
    // persistentRabbitMqSubscription
    this.inventoryExhaustionEventSubscription = this.rabbitMQService.subscribeToInventoryExhaustionEventExchange$()
      .subscribe({
        next: (message: any) => {
          this.eventMessage = message && message.Payload ? message.Payload.POSEvent : null;
          if (this.eventMessage && this.applicationStateService.terminalId == this.eventMessage.ExhaustionPromptToTerminalId) {
            const inventoryData = {
              Id: this.eventMessage.InventoryProductId,
              Type: 'InventoryProduct'
            };
            this.inventoryLogService.getInventoryLogging(inventoryData)
              .subscribe({
                next: (response: any) => {
                  if (response && response) {
                    this.inventoryProduct = _.find(response, (item) => {
                      return item.Id == inventoryData.Id;
                    });
                    this.modalRef = this.modalService.show(this.logInventoryProductModal, {
                      'backdrop': 'static',
                      'class': 'vertical-center',
                      keyboard: false
                    });
                    this.modalBackdropService.addBackDrop();
                  }
                }, error: this.alertService.showApiError
              });

          }
        }
      });
  }

  onCancel() {
    this.modalRef.hide();
    this.modalBackdropService.removeBackdrop();
  }

  onLog() {
    this.inventoryProductList = [];
    if (
      (this.inventoryProduct.IsUpdated && this.inventoryProduct.UnitQtyIncrement != null &&
        this.mathsUtilityService.floatSafeModulus(
          parseFloat(this.inventoryProduct.Qty),
          parseFloat(this.inventoryProduct.UnitQtyIncrement)
        ) != 0)) {
      return;
    } else {
      this.updateLogInventory();
    }
  }

  updateLogInventory() {
    this.onCancelConfirmLogInventory();
    this.inventoryProduct.IsUpdated = true;
    this.inventoryProductList.push(this.inventoryProduct);
    const inventoryLogData = {
      CategoryName: this.inventoryProduct.Category + ' - ' + this.inventoryProduct.SubCategory,
      CategoryList: this.inventoryProductList,
      IsChecked: true
    };
    const userDetails = this.applicationStateService.userDetails;
    let inventoryLogList = [];
    inventoryLogList.push(inventoryLogData);
    const objInventory = {
      InventoryList: inventoryLogList,
      UserName: userDetails.lastname + ' ' + userDetails.firstname,
      UserId: userDetails.id,
      FilterValue: '',
      FilterName: '',
    };
    this.spinnerService.show();
    this.inventoryLogService.updateInventoryLog(objInventory)
      .subscribe({
        next: (res) => {
          this.alertService.renderSuccessMessage(
            StringUtils.format(Messages.InventoryLogSuccess,
              { 'productName': this.inventoryProduct.Product })
          );
          this.modalRef.hide();
          this.modalBackdropService.removeBackdrop();
        }, error: this.alertService.showApiError,
        complete: () => {
          this.spinnerService.hide();
        }
      });
  }

  onCancelConfirmLogInventory() {
    if (this.confirmInventoryModalRef) {
      this.confirmInventoryModalRef.hide();
    }
  }
}
