import { Component, OnInit, EventEmitter } from '@angular/core';
import { ICloseable, SpinnerService, AlertsService, OrderService, ApplicationStateService, SettingParam, ModalService } from 'src/app/shared';
import { DriverService } from 'src/app/shared/services/driver.service';
import { Driver } from '../../interfaces/driver';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { DriverDeliveryDetail } from '../../interfaces/driver-delivery-detail';
import * as _ from 'lodash';
import { TerminalReportsComponent } from '../terminal-reports';

@Component({
  selector: 'pos-dispatch',
  templateUrl: './dispatch.component.html',
  styleUrls: ['./dispatch.component.scss']
})
export class DispatchComponent implements OnInit, ICloseable {
  close: EventEmitter<any> = new EventEmitter();

  selectedDriver: Driver;
  driverDetails: Array<Driver> = [];
  selectedOrders: Array<DriverDeliveryDetail> = [];
  deliveryTypeOrders: Array<DriverDeliveryDetail> = [];
  settingParam: SettingParam;
  terminalId: number;
  drawerId: number;
  constructor(private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private orderService: OrderService,
    private applicationStateService: ApplicationStateService,
    private driverService: DriverService,
    private modalService: ModalService) { }

  ngOnInit() {
    this.setDefaults();
    this.getDrivers();
  }

  setDefaults() {
    this.selectedOrders = [];
    this.selectedDriver = null;
    this.deliveryTypeOrders = [];
    this.settingParam = this.applicationStateService.settingParam;
    this.terminalId = this.applicationStateService.terminalId;
  }

  getDrivers(isSilentLoad = false) {
    if (!isSilentLoad) {
      this.spinnerService.show();
    }
    const driverObservables = [];
    this.selectedDriver = null;
    driverObservables.push(this.driverService.getDrivers());
    driverObservables.push(this.driverService.getUnservedOrderForDelivery());
    forkJoin(driverObservables)
      .pipe(finalize(() => {
        if (!isSilentLoad) {
          this.spinnerService.hide();
        }
      }))
      .subscribe({
        next: (responses: any) => {
          if (responses) {
            this.driverDetails = responses[0] ? responses[0] : [];
            this.unselectSelectedOrders();
            this.deliveryTypeOrders = responses[1] ? responses[1] : [];
          }
        }, error: this.alertService.showApiError
      });
  }

  unselectSelectedOrders() {
    this.selectedOrders = [];
  }

  driverSelection(driverDetail: any) {
    this.unselectSelectedOrders();
    this.selectedDriver = driverDetail;
    if (driverDetail.IsSelected) {
      _.forEach(this.deliveryTypeOrders, (item) => {
        item.IsSelected = false;
      });
      driverDetail.IsSelected = false;
      this.selectedDriver = null;
    } else {
      _.forEach(this.driverDetails, (item) => {
        item.IsSelected = false;
      });
      driverDetail.IsSelected = true;
    }
  }

  driverOut(update: boolean) {
    this.spinnerService.show();
    let deliverId = 0;
    if (update) {
      _.forEach(this.deliveryTypeOrders, (item: any) => {
        if (item.DeliveryUserID === this.selectedDriver.Id) {
          deliverId = item.deliverId;
        }
      });
    }
    const driverOutModel = {
      Orders: this.selectedOrders,
      UserId: this.selectedDriver.Id,
      DeliveryId: deliverId ? deliverId : 0
    };
    this.driverService.updateDriverAsDeliveryOut(driverOutModel)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          if (res) {
            this.selectedDriver.Id = res;
          }
          this.getDrivers();
        }, error: this.alertService.showApiError
      });
  }

  printDelivery() {
    this.spinnerService.show();
    let printList = [];
    this.driverService.getOpenDeliveryDetails(this.selectedDriver.Id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          if (res && this.settingParam.ReceiptPrinter.Id) {
            _.forEach(res, (item) => {
              const printOrder = {
                SurrogateOrderId: item.SurrogateOrderId,
                OrderId: item.OrderId,
                Parameter: this.settingParam,
                PaidAmount: item.PaidAmount,
                TerminalId: this.terminalId,
                ReceiptPrinterId: this.settingParam.ReceiptPrinter.Id
              };
              printList.push(printOrder);
            });
            this.printDriverReceipt(printList);
          }
        }, error: this.alertService.showApiError
      });
  }

  printDriverReceipt(printList: Array<any>) {
    this.spinnerService.show();
    this.driverService.printDriverReceipt(printList)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {

        }, error: this.alertService.showApiError
      });
  }

  driverIn() {
    this.spinnerService.show();
    this.driverService.getOpenDeliveryDetails(this.selectedDriver.Id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: Array<DriverDeliveryDetail>) => {
          if (res) {
            const deliveryPayments = [];
            _.forEach(res, (item) => {
              const paymentModel = {
                OrderId: item.OrderId,
                Amount: item.Total - item.PaidAmount,
                Gratuity: 0,
                UserId: this.selectedDriver.Id,
                DrawerId: this.drawerId,
                PaymentType: 1,
                TerminalId: this.applicationStateService.terminalId
              };
              deliveryPayments.push(paymentModel);
            });
            if (deliveryPayments.length) {
              this.applyPaymentForDelivery(deliveryPayments);

            }
            this.updateDriverBankDetails();
          }
        }, error: this.alertService.showApiError
      });
  }

  applyPaymentForDelivery(paymentModel) {
    this.spinnerService.show();
    this.driverService.applyPaymentForDriverDelivery(paymentModel)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          this.getDrivers(true);
        }, error: this.alertService.showApiError
      });
  }

  updateDriverBankDetails() {
    this.spinnerService.show();
    const driver = {
      IsActiveDelivery: true,
      IsPaid: false
    };
    this.driverService.makeDriverDeliveryPaid(this.selectedDriver.Id, driver)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          this.getDrivers();
        }, error: this.alertService.showApiError
      });
  }

  selectOrder(item) {
    if (item.IsSelected) {
      item.IsSelected = false;
      const index = this.selectedOrders.indexOf(item);
      this.selectedOrders.splice(index, 1);
    } else {
      item.IsSelected = true;
      item.id = item.OrderId;
      this.selectedOrders.push(item);
    }

  }

  terminalReport() {
    const modalRef = this.modalService.getModalWrapper(TerminalReportsComponent);
    modalRef.show({
      animated: false,
      class: 'vertical-center',
      initialState: {
        selectedDriver: this.selectedDriver
      }
    });
  }

  onCancel() {
    this.close.emit({});
  }
}
