<div class="dashboard-container mb-pix-10 mt-pix-10">
    <div *ngIf="adfModel.IsInteractive" class="col-12 mb-pix-2 display-flex p-0">
        <div class="col-12 pe-2 mb-pix-10 header-container">
            <div class="col-4 ps-pix-5 row">
                <h3 class="font-20">{{adfModel.Name}}</h3>
            </div>
            <div class="col-8 ps-0 pe-pix-15">
                <span class="float-right font-medium">
                    <a class="no-decoration me-pix-5" title="Add new widget" (click)="addWidgetDialog()">
                        <pos-action-icon [icon]="icons.dashboardPlus"></pos-action-icon>
                    </a>
                    <a class="no-decoration me-pix-5" title="Edit dashboard" (click)="editDashboardDialog()">
                        <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
                    </a>
                </span>
            </div>
            <div *ngIf="mode == 'interactive'" class="pe-pix-3 close-btn">
                <a class="float-right close close--small pointer" (click)="closeDashboard()">×</a>
            </div>
        </div>

    </div>
    <div class="dashboard mb-pix-60" [ngClass]="{'mb-pix-60': adfModel.IsInteractive, 'edit': isEditMode}">
        <pos-adf-dashboard-row *ngFor="let row of adfModel.rows" [row]="row" [adfModel]="adfModel" [options]="options"
            [isEditMode]="isEditMode" [isContinuousEditMode]="false" [isInteractive]="adfModel.IsInteractive">
        </pos-adf-dashboard-row>
    </div>
</div>
