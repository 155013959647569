import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { first, forEach } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OrderNavigationButton } from 'src/app/orders/interface/order-navigation-button';
import { ButtonBehavior, SpinnerService, AlertsService, ButtonFunctionService, ModalService, ModalFormComponent, FormUtilityService } from 'src/app/shared';

@Component({
  selector: 'pos-select-button',
  templateUrl: './select-button.component.html',
  styleUrls: ['./select-button.component.scss']
})
export class SelectButtonComponent extends ModalFormComponent implements OnInit {

  @Input() selectedButtonBehaviorType;
  @Input() buttonBehavior = null;
  @Input() screenToggleButtons: Array<OrderNavigationButton>;
  @Input() buttons = [];
  @ViewChild('uncheckButtonForm') uncheckButtonForm: NgForm;

  get getForm(): NgForm {
    return this.uncheckButtonForm;
  }

  buttonIds: Array<number> = [];

  constructor(private buttonFunctionService: ButtonFunctionService,
    protected spinnerService: SpinnerService,
    protected alertsService: AlertsService,
    modalRef: BsModalRef, modalService: ModalService, protected formUtilityService: FormUtilityService) {
      super(modalRef, modalService, formUtilityService);
    }

  ngOnInit(): void {
    if (this.buttonBehavior && this.buttonBehavior.Id) {
      try {
        if (this.buttonBehavior.BehaviorValue) {
          forEach(this.buttonBehavior.BehaviorValue, (b) => {
            this.buttonIds.push(b.ChoiceId);
          });
        } 
        else {
          const behavior = JSON.parse(this.buttonBehavior.TextValue);
          this.buttonIds = behavior ? behavior.buttonIds : null;
        }
        const notMappedButtonIds = this.buttonFunctionService.getAllSelectedButtonInList(this.screenToggleButtons, this.buttonIds);
        if (notMappedButtonIds)  {
          this.buttonFunctionService.addButtonInBindList(this.screenToggleButtons, this.buttons, notMappedButtonIds);
        }
        this.setInitialValue();
      } catch (ex) {
        console.error('Invalid behavior value: ' + this.buttonBehavior.TextValue);
      }
    }
  }

  saveBehavior = (isValid: boolean) => {
    if (isValid && this.buttonIds.length) {
      this.hide({ shouldReload: true, Value: { buttonIds: this.buttonIds } });
    }
  }

  closeModal = () => {
    this.hide({ shouldReload: false });
  }

}
