<pos-edit-wrapper (close)="cancel()"
    heading="{{ id > 0 || profileProduct.InventoryProductId ? 'Edit Profile Product' : 'Add Products to Profile'}}">
    <form #profileProductForm="ngForm" (ngSubmit)="saveProfileProduct(profileProductForm.valid)" autocomplete="off"
        class="edit-form">
        <div class="col-12 px-sm-4 px-2 pt-pix-10">
            <pos-row>
                <pos-column *ngIf="vendorName">
                    <pos-row>
                        <pos-column lg="2" xs="3">
                            <label class="ps-pix-5">Vendor</label>
                        </pos-column>
                        <pos-column xs="6">
                            <label>{{vendorName}}</label>
                        </pos-column>
                    </pos-row>
                </pos-column>
                <pos-column *ngIf="profileName">
                    <pos-row class="pt-pix-5">
                        <pos-column lg="2" xs="3" >
                            <label class="ps-pix-5">Profile</label>
                        </pos-column>
                        <pos-column xs="6">
                            <label>{{profileName}}</label>
                        </pos-column>
                    </pos-row>
                </pos-column>
                <pos-column *ngIf="id > 0 || profileProduct.InventoryProductId">
                    <pos-row class="pt-pix-5 mb-pix-5">
                        <pos-column lg="2" xs="3">
                            <label class="ps-pix-5">Product</label>
                        </pos-column>
                        <pos-column xs="6">
                            <label>{{ profileProduct.InventoryProduct ?
                                profileProduct.InventoryProduct.Name : ''}}</label>
                        </pos-column>
                    </pos-row>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Min" *ngIf="id > 0 || profileProduct.InventoryProductId"
                        inputStyle="row"
                        [validationMessages]="{required: 'Please enter min.', min: 'Please enter positive min value.'}">
                        <pos-row>
                            <pos-column xs="5" class="col-120">
                                <input posInput name="productMin" #productMin="ngModel" type="number"
                                    class="form-control" min="0" [(ngModel)]="profileProduct.MinLevel"
                                    [required]="true" posDecimal [validDecimal]="4"/>
                            </pos-column>
                            <pos-column xs="5" class="edit-form__section__label">
                                {{ profileProduct.InventoryProduct?.Unit ? profileProduct.InventoryProduct.Unit.Name :
                                '' }}
                            </pos-column>
                        </pos-row>
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Max" *ngIf="id > 0 || profileProduct.InventoryProductId"
                        inputStyle="row"
                        [validationMessages]="{required: 'Please enter max.', min: 'Please enter positive max value.'}">
                        <pos-row>
                            <pos-column xs="5" class="col-120">
                                <input posInput name="productMax" #productMax="ngModel" type="number"
                                    class="form-control" min="0" [(ngModel)]="profileProduct.MaxLevel"
                                    [required]="true" posDecimal [validDecimal]="4"/>
                            </pos-column>
                            <pos-column xs="5" class="edit-form__section__label">
                                {{ profileProduct.InventoryProduct?.Unit ? profileProduct.InventoryProduct.Unit.Name :
                                '' }}
                            </pos-column>
                        </pos-row>
                    </pos-form-field>
                </pos-column>
            </pos-row>

            <div *ngIf="!id && !profileProduct?.InventoryProductId">
                <div class="row pe-pix-20 pt-pix-5 justify-content-end ">
                    <input class="form-control pull-right col-200" name="searchProduct" placeholder="Filter..."
                        #searchProduct="ngModel" [(ngModel)]="searchText" (ngModelChange)="filterInventoryProducts()">
                </div>
                <div class="row pt-pix-10 pb-pix-5 display-flex" [ngStyle]="{'pe-pix-20': inventoryProducts.length > 11}">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-1">
                        <p-checkbox name="chkAll" [(ngModel)]="isCheckAllProfileProducts" binary="true"
                            (onChange)="checkAllProfileProducts()">
                        </p-checkbox>
                    </div>
                    <div class="col-lg-5 col-md-3 col-sm-3 col-5 theme-separator">
                        <strong>Product Name</strong>
                    </div>
                    <div class="col-lg-2 col-md-3 col-sm-3 col-2 ps-0 theme-separator">
                        <strong>Min</strong>
                    </div>
                    <div class="col-lg-2 col-md-3 col-sm-3 col-2 ps-0 theme-separator">
                        <strong>Max</strong>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2 col-2 ps-0 pe-0 theme-separator">
                        <strong>Unit</strong>
                    </div>
                </div>
                <div class="overflow-auto-tabs" [ngStyle]="{'height': 'calc(100vh - 350px)'}">
                    <div class="bold text-danger text-center"
                        *ngIf="!inventoryProducts.length && !profileProduct.InventoryProductId">No product(s)
                        available.</div>
                    <div *ngFor="let inventoryProduct of inventoryProducts; let index = index;"
                        class="row display-flex pb-pix-10">
                        <div class="col-lg-1 col-md-1 col-sm-1 col-1 pt-pix-5">
                            <p-checkbox name="chk{{index}}" [(ngModel)]="inventoryProduct.IsProfileProduct"
                                binary="true" (onChange)="isCheckAllProfileProducts = false">
                            </p-checkbox>
                        </div>
                        <div class="col-lg-5 col-md-3 col-sm-3 col-5 label-section">
                            {{ inventoryProduct.Name }}
                        </div>
                        <div class="col-lg-2 col-md-3 col-sm-3 col-2 ps-0"
                            [ngClass]="{'has-error': (min.invalid && profileProductForm.submitted)}">
                            <input name="min{{index}}" #min="ngModel" type="number" class="form-control" min="0"
                                [(ngModel)]="inventoryProduct.Min" required posDecimal [validDecimal]="4"
                                (ngModelChange)="inventoryProduct.IsProfileProduct = true" />
                            <span
                                *ngIf="!inventoryProduct.Min && inventoryProduct.Min != 0 && profileProductForm.submitted">
                                <span class="control-label">Please enter min.</span>
                            </span>
                            <span
                                *ngIf="inventoryProduct.Min && inventoryProduct.Min < 0 && profileProductForm.submitted">
                                <span class="control-label">Please enter positive min value.</span>
                            </span>
                        </div>
                        <div class="col-lg-2 col-md-3 col-sm-3 col-2 ps-0"
                            [ngClass]="{'has-error': (max.invalid && profileProductForm.submitted)}">
                            <input name="max{{index}}" #max="ngModel" type="number" class="form-control" min="0"
                                [(ngModel)]="inventoryProduct.Max" required posDecimal [validDecimal]="4"
                                (ngModelChange)="inventoryProduct.IsProfileProduct = true" />
                            <span
                                *ngIf="!inventoryProduct.Max && inventoryProduct.Max != 0 && profileProductForm.submitted">
                                <span class="control-label">Please enter max.</span>
                            </span>
                            <span
                                *ngIf="inventoryProduct.Max && inventoryProduct.Max < 0 && profileProductForm.submitted">
                                <span class="control-label">Please enter positive max value.</span>
                            </span>
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-2 col-2 ps-0 label-section">
                            {{ inventoryProduct.UnitName }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="clearfix"></div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save</button>
            <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" (click)="cancel()">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
