<pos-list-wrapper [headingIcon]="icons.iconsVectorSquare" heading="Training Areas" (close)="close()"
permissions='[{"Name":"{{permission.name}}", "Level":"{{permission.readOnlyLevel}}"}]'>
  <div class="row">
      <ng-table #trainingAreaGrid [customFilterColumns]="filterColumns" [exportFilename]="'training-areas'"
        [sort]="false" [paginator]="false" [columns]="trainingAreaColumns" [data]="trainingAreas"
        [isReorderableTable]="true" [ReorderableColumnRow]="'row'" (onRowReorder)="gridRowReorder($event)">
      </ng-table>
  </div>
  <ng-template let-data="rowData" #iconTemplate>
    <span *ngIf="(data.Icon !== null && data.Icon !== '')">
      <img class="image-size" src="{{imagePath}}/training-area-icon/{{data.Icon}}" height="50px" width="50px" alt="" />
    </span>
  </ng-template>
  <pos-confirm-delete [message]="confirmDeleteMessage" (onConfirmAcknowledge)="onConfirmAcknowledge()"
    #confirmTrainingAreaDelete>
  </pos-confirm-delete>
</pos-list-wrapper>
<pos-print-table #printTableReference [hidden]="true"></pos-print-table>
