import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BehaviorPromiseProviderService {

  constructor() { }

  resolvePromise: (value?: string | PromiseLike<string>) => void;
  rejectPromise: (reason?: any) => void;

  executionPromise = new Promise<any>((resolve, reject) => {
    this.resolvePromise = resolve;
    this.rejectPromise = reject;
  });

  resetPromise = () => {
    this.executionPromise = new Promise<any>((resolve, reject) => {
      this.resolvePromise = resolve;
      this.rejectPromise = reject;
    });
  }

  toPromise<T>(event: EventEmitter<T>): Promise<T> {
    return new Promise<T>((res, rej) => {
      event.subscribe(data => res(data), data => rej(data));
    });
  }
}
