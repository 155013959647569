<pos-edit-wrapper (close)="onCancel()" [isPopup]="false" [headingIcon]="icons.rabbitMqConfigurationCogs"
  heading="RabbitMQ Configuration">
  <form (ngSubmit)="saveRabbitMQConfiguration()" #rabbitMQConfigForm="ngForm" class="edit-form">
    <context-provider provide="props">
      <div class="col-12 pos-page-body pt-pix-5 border-top">
        <div class="col-12 pt-pix-15" *ngIf="showSSLWarning">
          <div class="alert condensed alert-warning mb-pix-5">
            {{useSSLConfigMessage}}
          </div>
        </div>
        <div class="col-12">
          <div>
            <h3 class="setting-title">Settings</h3>
          </div>
          <pos-row class="px-2">
            <pos-column>
              <pos-form-field label="Host" [validationMessages]="{required: 'Please enter host.'}">
                <input posInput type="text" class="form-control" name="host" [(ngModel)]="rabbitMQConfig.Host"
                  [required]="true" #host="ngModel" />
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field label="Port" [validationMessages]="{required: 'Please enter port.'}">
                <input posInput type="text" class="form-control" name="port" [(ngModel)]="rabbitMQConfig.Port"
                  #port="ngModel" [required]="true" />
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field label="Username" [validationMessages]="{required: 'Please enter username.'}">
                <input posInput type="text" class="form-control" name="username" [(ngModel)]="rabbitMQConfig.Username"
                  #username="ngModel" [required]="true" />
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field label="Password" [validationMessages]="{required: 'Please enter password.'}">
                <input posInput type="text" class="form-control" name="password" [(ngModel)]="rabbitMQConfig.Password"
                  #password="ngModel" [required]="true" />
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field label="Virtual Host" [validationMessages]="{required: 'Please enter virtual host.'}">
                <input posInput type="text" class="form-control" name="virtualHost"
                  [(ngModel)]="rabbitMQConfig.VirtualHost" #virtualHost="ngModel" [required]="true" />
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field>
                <p-checkbox posInput name="isUseSSL" #isUseSSL="ngModel" (onChange)="changeUseSSLStatus()"
                  [(ngModel)]="rabbitMQConfig.IsUseSSL" binary="true" label="Use SSL">
                </p-checkbox>
              </pos-form-field>
            </pos-column>
          </pos-row>
        </div>
        <div class="col-12 section-border">
          <div>
            <h3 class="setting-title">Stomp Host</h3>
          </div>
          <div class="row pt-pix-10 px-2">
            <div class="col-12">
              <ng-table #stompHostConfigGrid [columns]="stompHostConfigColumns" [data]="stompHostList" [sort]="false"
                [paginator]="false" [search]="false">
              </ng-table>
              <ng-template let-data="rowData" #useSSLTemplate>
                <span *ngIf="(data.IsUseSSL)" class="green text-center vertical-mid font-13">
                  <pos-icon [icon]="icons.check"></pos-icon>
                </span>
              </ng-template>
              <ng-template let-data="rowData" #operationHeaderTemplate>
                <div class="text-center" tooltip title="Add" (click)="this.editStompHostConfig(null)">
                  <pos-action-icon [icon]="icons.plusWhite" iconText="Add"></pos-action-icon>
                </div>
              </ng-template>
              <ng-template let-data="rowData" #operationTemplate>
                <div class="d-flex justify-content-center">
                  <span (click)="this.editStompHostConfig(data)">
                    <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
                  </span>
                  <span *ngIf="data.ApplicationHost !== 'Default'"
                    (click)="openConfirmDeleteModal(data.ApplicationHost)">
                    <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
                  </span>
                  <div class="col-30" *ngIf="data.ApplicationHost === 'Default'"></div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 action-button-container page-footer">
        <button class="btn btn-primary btn-lg btn-save pos-button" scrollup type="submit" value="Save">Save</button>
        <button class="btn btn-secondary btn-lg btn-cancel pos-button" routerLink="../" scrollup type="button"
          value="Cancel">Cancel</button>
      </div>
    </context-provider>
  </form>
</pos-edit-wrapper>
