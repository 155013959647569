import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../shared/components';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HeaderEntryModule } from '../header-entry/header-entry.module';
import { ManagementDashboardComponent } from './management-dashboard.component';
import { MANAGEMENT_DASHBOARD_STATES } from './management-dashboard-routes';
import { HighchartsChartModule } from 'highcharts-angular';
import { TableModule } from '@tarktech/tark-ng-utils';
import { DashboardListComponent } from './components/dashboard-list/dashboard-list.component';
import { DashboardEditComponent } from './components/dashboard-edit/dashboard-edit.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AdfModule } from '../adf/adf.module';
import { DashboardConfigurationComponent } from './components/dashboard-configuration';
import { DashboardConfigurationWrapperComponent } from './components/dashboard-configuration-wrapper';
import { RouterModule } from '@angular/router';

import { CheckboxModule } from 'primeng/checkbox';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ModalModule.forRoot(),
    ComponentsModule,
    RouterModule.forChild(MANAGEMENT_DASHBOARD_STATES),
    HeaderEntryModule,
    HighchartsChartModule,
    TableModule,
    NgSelectModule,
    AdfModule,
    CheckboxModule
  ],
  declarations: [ManagementDashboardComponent, DashboardListComponent, DashboardEditComponent, DashboardConfigurationComponent,
    DashboardConfigurationWrapperComponent],
  exports: [DashboardEditComponent, DashboardConfigurationComponent, DashboardConfigurationWrapperComponent]
})
export class ManagementDashboardModule { }
