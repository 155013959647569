import { Injectable } from '@angular/core';
import { forEach } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ExtensionValidationService {
  constructor() { }

  isInvalidExtension(fileToUpload: string, supportedExtension: Array<string> = ['.jpg', '.bmp', '.png']) {
    const fileExtension = fileToUpload.substring(fileToUpload.lastIndexOf('.'), fileToUpload.length);
    return !supportedExtension.includes(fileExtension?.toLowerCase());
  }
}
