import { checkCircleSolid, timesCircle, levelDown, circleRegular, reply, arrowAltCircleLeft, arrowLeft } from './../../shared/components/icon/icons';

import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnChanges, OnDestroy, AfterViewInit, ElementRef } from '@angular/core';
import { ModalDirective, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalComponent } from 'src/app/shared';
declare let $: any;

@Component({
    selector: 'pos-key-board',
    templateUrl: './keyboard.component.html'
})
export class KeyboardComponent extends ModalComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    @Input() keyboardId: string;
    @Input() options: any;
    @Input() isOpen: boolean;
    @Input() value: string;
    @Output() valueChanged = new EventEmitter<string>();
    @Output() submited = new EventEmitter<any>();
    @ViewChild('lgModal') public lgModal: ModalDirective;
    @Output() canceled = new EventEmitter<any>();
    @Output() isOpenChange = new EventEmitter<boolean>();
    @ViewChild('multiLineInput') private multiLineInput: ElementRef;
    @ViewChild('txtKeyboard') private txtKeyboard: ElementRef;
    @Input() required = true;

    isSubmitted: boolean = false;
    public icons = {
        checkCircleSolid,
        timesCircle,
        levelDown,
        circleRegular,
        reply,
        arrowAltCircleLeft,
        arrowLeft
    }
    defaultOptions = {
        deviceType: 'desktop',
        isOpen: false,
        capsLock: 'Off',
        title: 'Keyboard',
        inputMode: 'singleLine',
        dynamicMultilineInput: false
    };
    capsLock = '';
    initialValue = '';
    newLine = false;
    isDeviceMobile = false;
    width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    isFireFox = false;
    defaultMaxLength: number = Number.MAX_SAFE_INTEGER;
    constructor(bsModalRef: BsModalRef
    ) {
        super(bsModalRef);
        if (!this.value) {
            this.value = '';
            this.valueChanged.emit(this.value);
        }
        this.options = Object.assign({}, this.defaultOptions, this.options);
        this.keyboardId = this.keyboardId;
    }

    ngAfterViewInit() {
        if (this.options.inputMode === 'singleLine') {
            setTimeout(() => {
                this.txtKeyboard.nativeElement.focus();
            });
        } else {
            setTimeout(() => {
                this.multiLineInput.nativeElement.focus();
            });
        }
    }

    ngOnInit() {
        this.options = Object.assign({}, this.defaultOptions, this.options);
    }
    ngOnChanges() {
        const self = this;
        if (this.isOpen) {
            this.initialValue = this.value;
            this.options.inputMode === 'multiLine' ? this.newLine = true : this.newLine = false;
            this.options.capsLock === true ? this.capsLock = 'On' : this.capsLock = 'Off';
            if (this.isOpen) {
                if (this.options.deviceType === 'mobile') {
                    this.isDeviceMobile = false;
                } else if (this.options.deviceType === 'tablet') {
                    if (this.width > 750) {
                        this.isDeviceMobile = false;
                    } else {
                        this.isDeviceMobile = true;
                    }
                } else if (this.options.deviceType === 'desktop') {
                    if (this.width > 991) {
                        this.isDeviceMobile = false;
                    } else {
                        this.isDeviceMobile = true;
                    }
                }
                this.outsideClickEvent();
                this.lgModal.show();
            } else {
                this.lgModal.hide();
                this.hide({ value: this.value });
            }
            setTimeout(() => {
                this.options.inputMode === 'multiLine' ? $('.keyboard-modal__keyboard__comment--area').focus()
                    : $('.keyboard-modal__keyboard__comment').focus();
                $('#' + this.keyboardId).off('keydown');
                $('#' + this.keyboardId).on('keydown', function (event) {
                    self.keypressEventForKeyboard(event);
                });
            });
        }
    }
    ngOnDestroy() {
        this.isOpen = false;
        this.isOpenChange.emit(this.isOpen);
    }
    outsideClickEvent() {
        $('#' + this.keyboardId).modal({
            backdrop: 'static', // This disable for click outside event
            keyboard: false // This for keyboard event
        });
    }
    keypressEventForKeyboard(event) {
        if (this.isFireFox === true) {
            if (event.keyCode === 0) {
                this.value = this.value + (event.key);
            } else {
                this.value = this.value + (event.key);
            }
            this.valueChanged.emit(this.value);
        } else {
            if (event.keyCode === 8) {
                this.textEnteredForKeyboard('Back', 0);
            } else if (event.keyCode === 13) {
                this.submit();
            } else if (this.isValidKeyCode(event)) {
                this.textEnteredForKeyboard(event.key, 0);
            }
        }
        event.preventDefault();
    }

    isValidKeyCode(event) {
        return (event.keyCode >= 65 && event.keyCode <= 90) ||
            (event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105)
            || event.keyCode === 109
            || event.keyCode === 110 || event.keyCode === 32
            || (event.keyCode >= 186 && event.keyCode <= 190);
    }
    capsLockOnOff() {
        if (this.options.capsLock === true) {
            this.capsLock = 'Off';
            this.options.capsLock = false;
        } else {
            this.capsLock = 'On';
            this.options.capsLock = true;
        }
    }

    textEnteredForKeyboard(value, flag) {
        if (value === 'Back') {
            const text = this.value;
            this.value = text.slice(0, -1);
        } else if (value === 'Cancel') {
            this.canceled.emit();
            this.value = this.initialValue;
            this.options.isOpen = false;
            this.isOpen = false;
            this.isOpenChange.emit(this.isOpen);
            this.lgModal.hide();
            this.hide({ value: this.value });
        } else if (value === 'Clear') {
            this.value = '';
        } else {
            this.value = this.value + value;
        }
        this.valueChanged.emit(this.value);
        if (this.options.inputMode === 'multiLine' && value === '\n') {
            $('.keyboard-modal__keyboard__comment--area').focus();
        }
    }
    enterEventForKeyboard() {
        (this.options.inputMode === 'multiLine') ? this.textEnteredForKeyboard('\n', 0) : this.submit();
    }
    submit() {
        this.isSubmitted = true;
        if ((this.value && this.value.trim()) || !this.required) {
            $('#' + this.keyboardId).modal('hide');
            this.options.isOpen = false;
            this.isOpen = false;
            this.isOpenChange.emit(this.isOpen);
            this.valueChanged.emit(this.value);
            this.submited.emit(this.value);
            this.lgModal.hide();
            this.hide({ value: this.value.trim() , isSubmitted: this.isSubmitted});
        }
    }
    isFireFoxFunc() {
        if (navigator.userAgent.indexOf('Firefox') !== -1) {
            this.isFireFox = true;
        } else {
            this.isFireFox = false;
        }
    }
}
