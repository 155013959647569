import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import {  DashboardConfiguration, ModalService, ColorUtilityService, DomainConstants, ApplicationStateService } from 'src/app/shared';
import { TextWidgetConfiguration } from 'src/app/shared/interface';
import { WidgetEditComponent } from '../widget-edit/widget-edit.component';
import { forEach, orderBy, cloneDeep } from 'lodash';
import { BaseWidgetService } from 'src/app/adf/services/base-widget.service';
import { Observable, interval as observableInterval, Subscription } from 'rxjs';
import * as moment from 'moment';
import 'moment-duration-format';
import { DashboardBroadcastingService } from 'src/app/adf/services/dashboard-broadcasting.service';
import { AdfColumn, AdfModel } from 'src/app/adf/interfaces/adf-model';
import { DatePipe, CurrencyPipe } from '@angular/common';
@Component({
  selector: 'pos-text-widget',
  templateUrl: './text-widget.component.html',
  styleUrls: ['./text-widget.component.scss']
})
export class TextWidgetComponent implements OnInit, OnDestroy {

  @Input() widgetConfiguration: DashboardConfiguration;
  data: string = '';
  @Input() isInteractive = false;
  configData: TextWidgetConfiguration;
  @Input() config?: TextWidgetConfiguration;
  @Input() column: AdfColumn;
  timer: Observable<number>;
  timerSubscription: any;
  isLoading = true;
  @Input() adfModel: AdfModel;
  color: string;
  bgColor: string;
  borderColor: string;
  eventsSubscription: Array<Subscription> = [];
  copiedConfig: TextWidgetConfiguration;
  formattedData: string = '';
  constructor(
    private modalService: ModalService,
    private colorUtilityService: ColorUtilityService,
    private baseWidgetService: BaseWidgetService,
    private dashboardBroadcastingService: DashboardBroadcastingService,
    private datePipe: DatePipe,
    private applicationStateService: ApplicationStateService,
    private currencyPipe: CurrencyPipe) { }

  ngOnInit(): void {
    this.clearData();
    this.copiedConfig = cloneDeep(this.config);
    if (this.widgetConfiguration && !this.widgetConfiguration.isNewWidget) {
      this.baseWidgetService.loadBaseWidget(this.widgetConfiguration, this.config, this.getWidgetCompleted);
      if (this.widgetConfiguration.isSilentReload) {
        this.data = this.widgetConfiguration.widgetData;
        this.formattedData = this.format(this.data);
        this.showStatColor();
        setTimeout(() => {
          this.baseWidgetService.resizeText(this.widgetConfiguration.widgetId);
        });
      }
      this.startAutoIntervalTimer();
    } else {
      this.editTextWidget();
    }
    this.subscribeBroadcastEvents();
  }

  subscribeBroadcastEvents = () => {
    this.eventsSubscription.push(this.dashboardBroadcastingService.reloadWidget.subscribe((widget) => {
      if (widget && widget.widgetAssignmentId > 0) {
        this.reloadWidget(widget);
      }
    }));
  }

  reloadWidget = (widget) => {
    if (this.widgetConfiguration.widgetAssignmentId == widget.widgetAssignmentId) {
      this.isLoading = true;
      this.widgetConfiguration = cloneDeep(widget);
      this.config = widget.config;
      this.widgetConfiguration.isReloadWidget = true;
      this.baseWidgetService.loadBaseWidget(this.widgetConfiguration, this.config, this.getWidgetCompleted);
    }
  }

  ngOnDestroy(): void {
    this.clearData();
  }

  startAutoIntervalTimer = () => {
    if (this.widgetConfiguration && this.widgetConfiguration.AutoRefreshInterval) {
      this.timer = observableInterval(this.widgetConfiguration.AutoRefreshInterval * 60 * 1000);
      this.timerSubscription = this.timer.subscribe((t) => {
        this.widgetConfiguration.isReloadWidget = true;
        this.baseWidgetService.loadBaseWidget(this.widgetConfiguration, this.config, this.getWidgetCompleted);
      });
    }
  }

  getWidgetCompleted = (response) => {
    this.isLoading = false;
    this.data = response;
    this.formattedData = this.format(this.data);
    this.widgetConfiguration.isReloadWidget = false;
    this.widgetConfiguration.widgetData = response;
    this.showStatColor();
    setTimeout(() => {
      this.baseWidgetService.resizeText('w_' + this.column.rowIndex + '_' + this.column.columnIndex);
    });

    if (this.config && this.config.params && this.config.params.length) {
      this.config = this.copiedConfig;
    }
  }

  private clearData() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
    forEach(this.eventsSubscription, (subscribe) => {
      if (subscribe) {
        subscribe.unsubscribe();
      }
    });
  }

  editTextWidget() {
    const modalRef = this.modalService.getModalWrapper(WidgetEditComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center modal-lg',
      initialState: {
        widgetConfig: cloneDeep(this.widgetConfiguration),
      }
    });
    modal.close.subscribe((res) => {
      if (res && res.reload) {
        this.widgetConfiguration = res.widgetConfiguration;
        this.widgetConfiguration.isNewWidget = false;
        this.config = res.widgetConfiguration.config;
        this.updateAdfModel();
        this.baseWidgetService.loadBaseWidget(this.widgetConfiguration, this.config, this.getWidgetCompleted);
      } else {
        this.isLoading = false;
        this.deleteWidget()
      }
    });
  }

  updateAdfModel = () => {
    const rowIndex = this.widgetConfiguration.rowIndex > 0 ? this.widgetConfiguration.rowIndex - 1 : 0;
    const columnIndex = this.widgetConfiguration.columnIndex ? this.widgetConfiguration.columnIndex - 1 : 0;
    if (this.adfModel && this.adfModel.rows) {
      if (this.adfModel.rows[rowIndex] && this.adfModel.rows[rowIndex].columns) {
        if (this.adfModel.rows[rowIndex].columns[columnIndex].widgets)
          this.adfModel.rows[rowIndex].columns[columnIndex].widgets[0] = this.widgetConfiguration;
      }
    }
  }

  deleteWidget() {
    if (this.column) {
      const index = this.column.widgets.indexOf(this.widgetConfiguration);
      if (index >= 0) {
        this.column.widgets.splice(index, 1);
        this.dashboardBroadcastingService.widgetDelete(this.widgetConfiguration);
      }
    }
  }

  showStatColor = () => {
    let colors = this.config.colorConditions;
    this.color = this.config.color;
    this.bgColor = this.config.bgColor;
    this.borderColor = this.config.borderColor;
    if (colors && colors.length > 0) {
      colors = orderBy(colors, ['-value']);
      forEach(colors, (color) => {
        if (color && this.compare(this.data, color.operator, color.value)) {
          this.color = color.color;
          this.bgColor = color.bgColor;
          this.borderColor = color.borderColor;
          if (this.bgColor && !this.color) {
            this.color = this.colorUtilityService.getContrastColor(this.bgColor);
          }
          return false;
        }
      });
    }
  }

  compare = (text, operator, colorValue): boolean => {
    const objOperators = DomainConstants.DashboardWidgetSelectOptions['Operators'];
    const operators = Object.keys(objOperators).map(key => objOperators[key].value);
    if (operators.indexOf(operator) === -1) {
      return false;
    }
    return (text !== '' && colorValue !== '' && eval((text + operator + colorValue).toString()));

  }


  format = (stat) => {
    const outputType = this.config.outputType;
    const mask = this.config.mask;
    const currencySymbol = this.applicationStateService.settingParam.CurrencySymbol ?? '$';

    // format stat based on mask format
    if (mask?.length) {
      if (mask.indexOf('{value}') !== -1) {
        return mask.toString().replace('{value}', stat);
      }

      switch (true) {

        // format time duration
        case (mask.toLowerCase().indexOf('h:m') !== -1 || mask.toLowerCase().indexOf('m:s') !== -1):
          return (<any>moment.duration(parseInt(this.data, null), 'seconds')).format(mask.toLowerCase(), { trim: false });

        case mask.indexOf('#') !== -1:
          {
            stat = this.maskCurrency(mask, currencySymbol, stat);
          }
          return stat;
        case mask === currencySymbol:
          return this.currencyPipe.transform(stat);
        // format date
        case (mask.split('-').length === 3 || mask.split('/').length === 3):
          if (stat?.length >= 8) {
            return moment(stat).format(mask);
          }
          break;
        default:
          break;
      }

    }


    // format stat based on outputType
    if (outputType && outputType.length > 0) {
      switch (outputType.toLowerCase()) {
        case 'currency':
        case 'dollar':
        case currencySymbol:
          stat = this.currencyPipe.transform(stat, this.applicationStateService.settingParam.Currency);
          break;
        case 'date':
          stat = this.datePipe.transform(Date.now(), this.applicationStateService.settingParam.DateFormat ?? 'MM-dd-yy');
          break;
        case 'int':
          if (!stat) {
            stat = 0;
          }
          stat = parseInt(stat, 10);
          break;
        case 'time':
          stat = (<any>moment.duration(parseInt(this.data, null), 'seconds')).format('hh:mm:ss', { trim: false });
          break;
        default:
          break;
      }
      return stat;
    }

    return stat;
  }


  private maskCurrency(mask: string, currencySymbol: string, stat: any) {
    const isCurrency = mask.indexOf(currencySymbol) !== -1;
    const prefix = isCurrency ? currencySymbol : '';
    if (!stat) {
      stat = 0;
    }
    const decimalLength = mask?.indexOf('.') !== -1 ? mask?.substr(mask.indexOf('.') + 1, mask?.length)?.length : 2;
    stat = prefix + parseFloat(stat).toFixed(Number(decimalLength));
    return stat;
  }
}
