import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RuntimeConstants, SettingParam } from 'src/app/shared';
import { Observable } from 'rxjs';
import { InvoiceAreaButtonText } from 'src/app/shared/interface/InvoiceAreaButtonText';

@Injectable({
  providedIn: 'root'
})
export class TaxonomyService {

  taxonomyApiBasePath = `${RuntimeConstants.API_PATH}taxonomy`;

  newInvoiceAreaButtonText(): InvoiceAreaButtonText {
    return {
      SendOrder: 'SEND ORDER',
      CancelOrder: 'CANCEL ORDER',
      ReviewOrder: 'REVIEW ORDER',
      SettleOrder: 'RING UP',
    }
  }

  constructor(private httpClient: HttpClient) { }

  getTaxonomyDetail(terminalId: number): Observable<SettingParam> {
    return this.httpClient.get<SettingParam>(`${this.taxonomyApiBasePath}/${terminalId}`);
  }
  saveTaxonomy(params): Observable<SettingParam> {
    return this.httpClient.post<SettingParam>(`${this.taxonomyApiBasePath}`, params);
  }
}
