import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { WorkScheduleService } from '../../../../shared/services/work-schedule.service';
import { User } from 'src/app/information-management/users';
import { WorkScheduleTag, WorkSchedule, WorkScheduleBatchEntry, WorkScheduleBatchEntryControl } from '../../interface';
import { NgForm } from '@angular/forms';
import { backward, forward } from 'src/app/shared/components/icon';
import { WorkScheduleTagService } from 'src/app/shared/services/work-schedule-tag.service';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { UserService } from 'src/app/shared/services/user.service';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { DomainConstants } from 'src/app/shared/constants/domain-constants';
import { ConfirmDeleteModalComponent, ModalFormComponent, ModalService } from 'src/app/shared/components';
import { FormUtilityService } from 'src/app/shared/services/form-utility.service';

@Component({
    selector: 'pos-work-schedule-batch-entry',
    templateUrl: './work-schedule-batch-entry.component.html',
    styleUrls: ['./work-schedule-batch-entry.component.scss']
})
export class WorkScheduleBatchEntryComponent extends ModalFormComponent implements OnInit {
    get getForm(): NgForm {
        return this.form
    }
    users: User[] = [];
    userId: number;
    tags: WorkScheduleTag[] = [];
    conflictSchedules: WorkSchedule[] = [];
    checkAll = false;
    batchEntryData: WorkScheduleBatchEntry[] = [];
    weekStart: string;
    days = 0;
    isConflict = false;
    currentDate: Date;
    startDate = new Date();
    dateFormat = 'mm-dd-yyyy';
    @ViewChild('workScheduleBatchForm', { static: true }) form: NgForm;
    icons = {
        backward,
        forward
    };

    props = {
        labelClass: '',
        controlBoxClass: 'col-12'
    }
    constructor(
        private alertService: AlertsService,
        private spinnerService: SpinnerService,
        private workScheduleService: WorkScheduleService,
        workScheduleBatchEntryModalRef: BsModalRef,
        private userService: UserService,
        private workScheduleTagService: WorkScheduleTagService,
        private datePipe: DatePipe,
        private applicationStateService: ApplicationStateService,
        modalService: ModalService,
        formUtilityService: FormUtilityService
    ) {
        super(workScheduleBatchEntryModalRef, modalService, formUtilityService);
    }

    ngOnInit() {
        this.loadDependencies();
        this.dateFormat = this.applicationStateService.settingParam.PCalendarDateFormat;
        this.weekStart = this.applicationStateService.settingParam.WeekStart;
        this.setInitialValue();
    }

    private loadDependencies(): void {
        this.spinnerService.show();
        this.currentDate = new Date();
        const workScheduleObservable = [];
        workScheduleObservable.push(this.workScheduleTagService.getWorkScheduleTags());
        workScheduleObservable.push(this.userService.getAllActiveUser());

        forkJoin(workScheduleObservable)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (responses: any) => {
                    this.tags = responses && responses[0] ? responses[0] : [];
                    this.users = responses && responses[1] ? responses[1] : [];
                    this.resetBatchEntry();
                }, error: this.alertService.showApiError
            });
    }

    submit(isValid: boolean) {
        if (!isValid) {
            return;
        }
        let status = true;
        this.conflictSchedules = [];
        const updatedBatchEntryData = this.batchEntryData.filter(b => b.isActive);
        const workSchedules: WorkSchedule[] = [];
        if (updatedBatchEntryData && updatedBatchEntryData.length > 0) {

            updatedBatchEntryData.forEach((batchEntry: WorkScheduleBatchEntry) => {
                batchEntry.start_date = new Date(this.convertDateToStringFormat(batchEntry.start_date,
                    this.applicationStateService.settingParam.DateFormat) + ' ' + batchEntry.StartTime);
                batchEntry.end_date = new Date(this.convertDateToStringFormat(batchEntry.end_date,
                    this.applicationStateService.settingParam.DateFormat) + ' ' + batchEntry.EndTime);
                const startTime = batchEntry.StartTime.split(':');
                batchEntry.start_date.setHours(startTime[0], startTime[1], 0, 0);
                const endTime = batchEntry.EndTime.split(':');
                batchEntry.end_date.setHours(endTime[0], endTime[1], 0, 0);

                if (batchEntry.end_date.getTime() <= batchEntry.start_date.getTime()) {
                    this.alertService.renderErrorMessage(Messages.ErrorWhileStartTimeIsGreaterEndTime);
                    status = false;
                }
                workSchedules.push(this.addWorkSchedule(batchEntry));
            });

            if (status) {
                this.spinnerService.show();
                this.workScheduleService.addWorkScheduleBatch(workSchedules)
                    .pipe(finalize(() => {
                        this.spinnerService.hide();
                    }))
                    .subscribe({
                        next: (response) => {
                            this.isConflict = false;
                            this.findConflict(response);
                        }, error: this.alertService.showApiError
                    });
            }

        } else {
            this.alertService.renderErrorMessage(Messages.WorkScheduleRequiredMessage);
            return;
        }
    }

    private findConflict(workSchedules: WorkSchedule[]) {
        let index = 0;
        workSchedules.forEach((workSchedule: WorkSchedule) => {
            for (let i = index; i < this.batchEntryData.length; i++) {
                index++;
                if (workSchedule.Message === 'TRUE') {
                    if (this.batchEntryData[i].isActive) {
                        this.checkAll = false;
                        this.batchEntryData[i].isActive = false;
                        this.batchEntryData[i].TagId = null;
                        this.batchEntryData[i].StartTime = this.convertDateToStringFormat(this.startDate.getTime(), 'HH:mm');
                        this.batchEntryData[i].EndTime = this.convertDateToStringFormat(this.startDate.getTime(), 'HH:mm');
                        this.batchEntryData[i].optional = false;
                        this.batchEntryData[i].Id = workSchedule.Id;
                        this.batchEntryData[i].isConflict = false;
                        break;
                    }
                } else {
                    if (this.batchEntryData[i].isActive) {
                        this.batchEntryData[i].isConflict = true;
                        this.conflictSchedules.push(workSchedule);
                        this.isConflict = true;
                        break;
                    }
                }
            }
        });
        if (!this.isConflict) {
            this.alertService.renderSuccessMessage(Messages.WorkScheduleBatchSaveSuccess);
            this.closeBatchEntry(true);
        } else {
            setTimeout(() => {
                this.isConflict = false;
            }, 10000);
        }
    }

    private addWorkSchedule(batchEntry: WorkScheduleBatchEntry): WorkSchedule {
        return {
            Id: 0,
            user_id: this.userId,
            start_date: batchEntry.start_date,
            end_date: batchEntry.end_date,
            dateadded: new Date(),
            start_time: null,
            end_time: null,
            optional: batchEntry.optional,
            TagId: batchEntry.TagId,
            Message: '',
            user_name: ''
        };
    }

    public closeBatchEntry(isReload: boolean): void {
        this.days = 0;
        this.hide({ shouldReload: isReload });
    }

    public changeWeek(days: number): void {
        let activeData = [];
        if (days !== 0) {
            activeData = this.batchEntryData.filter(b => b.isActive);
        }
        this.days = days;
        if (activeData.length > 0) {
            const confirmDelete = this.modalService.show(ConfirmDeleteModalComponent, {
                animated: false,
                class: 'vertical-center',
                'backdrop': 'static',
                initialState: {
                  message: Messages.ConfirmUnSaveChangeToContinue
                }
              });
            confirmDelete.close.subscribe((res) => {
                    if(res.shouldDelete) {
                        this.onConfirmAcknowledge();
                    }
                }
            )
        } else {
            this.resetBatchEntry();
        }
    }

    private resetBatchEntry() {
        this.checkAll = false;
        this.currentDate.setDate(this.currentDate.getDate() + this.days);
        this.startDate = this.getDateOnDay(this.currentDate);
        this.batchEntryData = [];
        for (let i = 0; i < 7; i++) {
            const workFlowBatch: WorkScheduleBatchEntry = {
                start_date: i === 0 ? new Date(this.startDate) : new Date(this.startDate.setDate(this.startDate.getDate() + 1)),
                end_date: null,
                StartTime: this.convertDateToStringFormat(this.startDate.getTime(), 'HH:mm'),
                EndTime: this.convertDateToStringFormat(this.startDate.getTime(), 'HH:mm'),
                TagId: null,
                optional: false,
                isActive: false,
                isConflict: false,
                TagColor: '',
                Id: 0
            };
            workFlowBatch.end_date = workFlowBatch.start_date;
            this.batchEntryData.push(workFlowBatch);
        }
    }

    private convertDateToStringFormat(date: any, format: string) {
        return this.datePipe.transform(date, format)
    }

    private getDateOnDay(date) {
        date = new Date(date);
        const day = date.getDay();
        const diff = date.getDate() - day + Number(DomainConstants.WeekDay[this.weekStart]);
        // Commented by Rumit, as below formula wasn't working well with Sunday as start of week
        // diff = d.getDate() - day + (day == 0 ? -6 : parseInt(domainConstants.WeekDay[weekStart]));
        return new Date(date.setDate(diff));
    }

    public onConfirmAcknowledge() {
        this.resetBatchEntry();
    }

    public checkAllSchedule(isChecked: boolean): void {
        this.batchEntryData.forEach(b => b.isActive = isChecked);
    }

    public changeIsActive(): void {
        let isAllCheck = true;
        this.batchEntryData.forEach((batchEntry: WorkScheduleBatchEntry) => {
            if (!batchEntry.isActive) {
                this.checkAll = false;
                isAllCheck = false;
            }
            if (isAllCheck === true) {
                this.checkAll = true;
            }
        });
    }

    public changeScheduleData(data: any, control: string, index?: number): void {
        if (control === WorkScheduleBatchEntryControl.StartTime) {
            data.EndTime = data.StartTime;
        }
        if (control === WorkScheduleBatchEntryControl.StartDate) {
            const scheduleDate = data.start_date;
            data.end_date = new Date(data.start_date);
            if (index === 0) {
                this.batchEntryData.forEach((workScheduleBatchEntry: WorkScheduleBatchEntry, batchEntryIndex: number) => {
                    if (batchEntryIndex !== 0) {
                        workScheduleBatchEntry.start_date = new Date(scheduleDate.setDate(scheduleDate.getDate() + 1));
                        workScheduleBatchEntry.end_date = workScheduleBatchEntry.start_date;
                    }
                });
                data.start_date = new Date(data.end_date);
            }
        }
        data.isActive = true;
    }

    get workScheduleBatchEntryControls() {
        return WorkScheduleBatchEntryControl;
    }

    public getTagColor(workScheduleBatchEntry: WorkScheduleBatchEntry): void {
        workScheduleBatchEntry.isActive = true;
        if (this.tags) {
            const tag = this.tags.filter(t => t.Id === workScheduleBatchEntry.TagId);
            if (tag && tag.length > 0 && tag[0].Color) {
                workScheduleBatchEntry.TagColor = tag[0].Color;
            } else {
                workScheduleBatchEntry.TagColor = '';
            }
        }
    }

    public getFormHeight() {
        if (screen.height <= 812) {
            return 'screen-height-small';
        }
        return '';
    }

}
