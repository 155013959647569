import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AmountPaidDetail, CashDrawerDetail } from 'src/app/manage-console/load-drawer/cash-drawer-detail';
import { TerminalInfoComponent } from 'src/app/manage-console/terminal/components/terminal-info/terminal-info.component';
import {
    backgroundJobs, trainingModeOn, trainingModeOff, infoCircle, trainingApproveThumbsUp, designModeOn, designModeOff,
    unlinkedScreensDesktop, menuSetupOff, inactiveButtonsDiceOne, rssSquare, cog, checkCircleSolid, displayMenu, tabletAlt,
    shippingFast, upload, calendar, megaphone, menuSetupUnusedImage, piggyBankLight, bug, fileAudio, barcode, dollyFlatbedAlt, ringupMoneyBill
} from 'src/app/shared/components/icon';
import { ManagesService } from 'src/app/shared/services/manages.service';
import { finalize } from 'rxjs/internal/operators/finalize';
import { DrawerCheckpointComponent } from 'src/app/manage-console/drawer-checkpoint/drawer-checkpoint.component';
import { EndOfDayTriggerComponent } from 'src/app/manage-console/end-of-day/end-of-day-trigger/end-of-day-trigger.component';
import { TerminalReportsComponent } from 'src/app/dispatch/components/terminal-reports/terminal-reports.component';
import { DomainConstants, Levels, Messages, Permissions } from 'src/app/shared/constants';
import { Dashboard, SettingParam, UnservedOrdersData } from 'src/app/shared/interface';
import { InfoModalComponent, ModalService, SpinnerService } from 'src/app/shared/components';
import { AlertsService, ApplicationStateService, DashboardConfigurationService, OrderService, RabbitMQService } from 'src/app/shared/services';
import { TrainingAreaComponent } from 'src/app/information-management/training-areas/components/training-area/training-area.component';
import { OrderCancel } from '../../interface/order-cancel';
import { DispatchComponent } from 'src/app/dispatch/components/dispatch/dispatch.component';
import { QuickSearchComponent } from 'src/app/manage-console/quick-search/components/quick-search/quick-search.component';

import { OrderMaintenanceComponent } from 'src/app/order-maintenance/components/order-maintenance/order-maintenance.component';
import { TrainingApproveComponent } from 'src/app/training/training-approve/components/training-approve.component';
import { forEach } from 'lodash';
import { CashDrawerService } from 'src/app/configurator/cash-drawer/services/cash-drawer.service';
import { LoadDrawerComponent } from 'src/app/manage-console/load-drawer';
import { DriverComponent } from 'src/app/manage-console/driver/components/driver/driver.component';
import { Router } from '@angular/router';
import { GiftCardComponent } from 'src/app/manage-console/gift-card';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { GiftCardNumpadWrapperComponentComponent } from 'src/app/shared/components/numpad/gift-card-numpad-wrapper-component/gift-card-numpad-wrapper-component.component';
import { ConfirmBackupModalComponent } from 'src/app/manage-console/confirm-backup-modal/confirm-backup-modal.component';
import { QuickBooksComponent } from 'src/app/manage-console/quick-books';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'pos-manage-console',
    templateUrl: './manage-console.component.html',
    styleUrls: ['./manage-console.component.scss']
})
export class ManageConsoleComponent implements OnInit, OnDestroy {

    drawerAmountModel: CashDrawerDetail;
    @ViewChild('terminalInfo') terminalInfo: TerminalInfoComponent;
    permissions = Permissions;
    accessLevels = Levels;
    icons = {
        backgroundJobs,
        trainingModeOn,
        trainingModeOff,
        infoCircle,
        trainingApproveThumbsUp,
        unlinkedScreensDesktop,
        menuSetupOff,
        inactiveButtonsDiceOne,
        designModeOn,
        designModeOff,
        rssSquare,
        cog,
        checkCircleSolid,
        displayMenu,
        shippingFast,
        upload,
        calendar,
        megaphone,
        menuSetupUnusedImage,
        piggyBankLight,
        bug,
        fileAudio,
        barcode,
        dollyFlatbedAlt,
        ringupMoneyBill,
        tabletAlt
    };
    trainingModeText = 'Training Mode On';
    trainingIcon = this.icons.trainingModeOff;
    designModeIcon = this.icons.menuSetupOff;
    designModeText = 'Turn on screen designer';
    close: EventEmitter<any> = new EventEmitter();
    isBackOffice = false;
    settingParam: SettingParam;
    showInventoryPopover = false;
    showTrainingPopover = false;
    showDrawerPopover = false;
    showDeliveryPopover = false;
    showAutomatedPopover = false;
    showLogViewerPopover = false;
    showEventsPopover = false;
    showQuickBooksPopover = false;
    drawerButtonName = 'Close Drawer';
    registerAmountInDrawer = 0;
    isDrawerCheckPoint = false;
    isDrawerAmountLoaded = false;
    rabbitMqOrderServedSubscription: Subscription;
    rabbitMqCashDrawerOpenedSubscription: Subscription;
    rabbitMqCashDrawerClosedSubscription: Subscription;
    rabbitMqHICResponseSubscription: Subscription;
    constructor(private router: Router,
        private spinnerService: SpinnerService,
        private dashboardConfigurationService: DashboardConfigurationService,
        private modalService: ModalService,
        private applicationStateService: ApplicationStateService,
        private managesService: ManagesService,
        private orderService: OrderService,
        private cashDrawerService: CashDrawerService,
        private rabbitMQService: RabbitMQService,
        private alertService: AlertsService) { }

    ngOnInit(): void {
        this.setDefaults();
        this.checkIsBackOffice();
        this.checkTrainingMode();
        this.checkDesignMode();
        this.getDrawerStatus();
        this.subscribeRequiredExchanges();
    }

    ngOnDestroy() {
    }

    subscribeRequiredExchanges() {
        this.subscribeToCashDrawerClosedEventExchange();
        this.subscribeToCashDrawerOpenedEventExchange();
        this.subscribeToTerminalBroadcastQueue();
        this.subscribeToHICResponseToTerminal();
    }

    subscribeToCashDrawerClosedEventExchange() {
        this.rabbitMqCashDrawerClosedSubscription = this.rabbitMQService.subscribeToCashDrawerClosedEventExchange$()
            .subscribe({
                next: (message) => {
                    if (message.Payload.POSEvent) {
                        if (this.settingParam.CashDrawerPrinter.Id > 0 && message.Payload.POSEvent.DrawerId ===
                            this.settingParam.CashDrawerPrinter.Id) {
                            this.drawerButtonName = 'Load Drawer';
                            this.applicationStateService.statusCloseDrawer = true;

                        }
                    }
                }, error: this.alertService.showApiError
            });
    }

    subscribeToCashDrawerOpenedEventExchange() {
        this.rabbitMqCashDrawerOpenedSubscription = this.rabbitMQService.subscribeToCashDrawerOpenedEventExchange$()
            .subscribe({
                next: (message) => {
                    if (message.Payload.POSEvent) {
                        if (this.settingParam.CashDrawerPrinter.Id > 0 && message.Payload.POSEvent.DrawerId ===
                            this.settingParam.CashDrawerPrinter.Id) {
                            this.drawerButtonName = 'Close Drawer';
                            this.applicationStateService.statusCloseDrawer = false;
                            this.registerAmountInDrawer = message.Payload.POSEvent.OpeningAmount;
                        }
                    }
                }, error: this.alertService.showApiError
            });
    }

    subscribeToTerminalBroadcastQueue() {
        this.rabbitMqOrderServedSubscription = this.rabbitMQService.subscribeToOrderServedMessage$()
            .subscribe({
                next: (message) => {
                    this.getPaymentDetails(false, true);
                }, error: this.alertService.showApiError
            });
    }

    subscribeToHICResponseToTerminal() {
        this.rabbitMqHICResponseSubscription = this.rabbitMQService.subscribeToHICResponseToTerminal$(this.settingParam.TerminalId)
            .subscribe({
                next: (message) => {
                    const responseObject = message.Payload.HICReponse;
                    const messageBody = JSON.stringify(responseObject);

                    if (responseObject.HardwareType !== 'CreditCardTerminal') {
                        // Printer messages for receipt printer, cash drawer etc...
                        if (messageBody.indexOf(Messages.PrinterNotFound) !== -1 || messageBody.indexOf(Messages.ProblemWhilePrinting) !== -1) {
                            const initialState = {
                                message: Messages.PrinterNotFound,
                            };
                            this.openModal(InfoModalComponent, false, false, 'vertical-center', initialState);
                        } else if (messageBody.indexOf(Messages.CashDrawerNotConnected) !== -1 ||
                            messageBody.indexOf(Messages.ProblemWhileConnectingCashDrawer) !== -1) {
                            const initialState = {
                                message: Messages.ErrorCashDrawerConnection,
                            };
                            this.openModal(InfoModalComponent, false, false, 'vertical-center', initialState);
                        }
                    }
                }, error: this.alertService.showApiError
            });
    }

    getDrawerStatus() {
        this.spinnerService.show();
        this.managesService.getDrawerStatus(this.settingParam.CashDrawerPrinter.Id)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (response) => {
                    if ((response && response.action === 'Open') || this.isDrawerCheckPoint) {
                        this.drawerButtonName = 'Close Drawer';
                        this.registerAmountInDrawer = response.amount;
                        this.applicationStateService.statusCloseDrawer = false;
                    } else {
                        this.drawerButtonName = 'Load Drawer';
                        this.applicationStateService.statusCloseDrawer = true;
                    }
                    this.getPaymentDetails();
                }, error: this.alertService.showApiError
            });
    }

    getPaymentDetails(isFromCloseDrawer = false, isSilentLoad = false) {
        if (!isSilentLoad) {
            this.spinnerService.show();
        }
        this.managesService.getPaymentsDetails(this.settingParam.CashDrawerPrinter.Id)
            .pipe(finalize(() => {
                if (!isSilentLoad) {
                    this.spinnerService.hide();
                }
            }))
            .subscribe({
                next: (response: Array<AmountPaidDetail>) => {
                    this.drawerAmountModel.DrawerAmountModel = response;
                    forEach(response, (drawer) => {
                        if (drawer.PaymentType === 'Cash') {
                            this.drawerAmountModel.CashAmount = drawer.Amount;
                        } else if (drawer.PaymentType === 'Credit Card') {
                            this.drawerAmountModel.CreditAmount = drawer.Amount;
                        } else if (drawer.PaymentType === 'Total') {
                            this.drawerAmountModel.AllAmount = drawer.Amount;
                        } else if (drawer.PaymentType === 'Tips') {
                            this.drawerAmountModel.AllTips = drawer.Amount;
                        }
                    });
                    this.isDrawerAmountLoaded = true;
                    if (isFromCloseDrawer) {
                        this.openCashDrawerClicked();
                        this.openDrawerCheckpoint();
                    }
                }, error: this.alertService.showApiError
            });
    }

    setDefaults(): void {
        this.settingParam = this.applicationStateService.settingParam;
        this.drawerAmountModel = this.cashDrawerService.newDrawerDetail();
        this.drawerAmountModel.DrawerName = this.settingParam.CashDrawerPrinter.Name;
        this.drawerAmountModel.CurrentVersion = this.settingParam.CurrentVersion;
        this.drawerAmountModel.TerminalName = this.settingParam.TerminalName;
        this.drawerAmountModel.CashDrawerPrinterName = this.settingParam.CashDrawerPrinter.Name ? this.settingParam.CashDrawerPrinter.Name : 'None';
        this.drawerAmountModel.CardTerminalName = this.settingParam.CreditCardTerminal.Name ? this.settingParam.CreditCardTerminal.Name : 'None';
    }

    checkIsBackOffice(): void {
        this.isBackOffice = this.applicationStateService.terminalType === DomainConstants.TerminalTypes.BACK_OFFICE.Name;
    }
    checkDesignMode(): void {
        if (JSON.parse(this.applicationStateService.sessionDesignMode)) {
            this.designModeIcon = this.icons.designModeOn;
            this.designModeText = 'Turn off screen designer';
        } else {
            this.designModeIcon = this.icons.designModeOff;
            this.designModeText = 'Turn on screen designer';
        }
    }
    checkTrainingMode(): void {
        if (JSON.parse(this.applicationStateService.sessionTrainingMode)) {
            this.trainingIcon = this.icons.trainingModeOn;
            this.trainingModeText = 'Training Mode Off';
        } else {
            this.trainingIcon = this.icons.trainingModeOff;
            this.trainingModeText = 'Training Mode On';
        }
    }
    toggleInventoryPopover(): void {
        this.showInventoryPopover = true;
    }
    loadReports(): void {
        this.router.navigate(['manage/report']);
    }
    toggleTrainingPopover(): void {
        this.showTrainingPopover = true;
    }
    toggleDrawerPopover(): void {
        this.showDrawerPopover = true;
    }
    toggleDeliveryPopover(): void {
        this.showDeliveryPopover = true;
    }
    toggleAutomatedPopover(): void {
        this.showAutomatedPopover = true;
    }
    toggleQuickBooksPopover(): void {
        this.showQuickBooksPopover = true;
    }
    toggleLogViewerPopover(): void {
        this.showLogViewerPopover = true;
    }
    openModal<TComponent>(component: TComponent, requiredKeyboard: boolean, isAnimated: boolean, modalClass: string,
        initialState: object = {}, callBackOnSubmit?: any): void {
        const modal = this.modalService.show(component, {
            keyboard: requiredKeyboard,
            animated: isAnimated,
            class: modalClass,
            initialState: initialState
        });
        modal.close.subscribe(res => {
            if (callBackOnSubmit) {
                callBackOnSubmit(res);
            }
        });
    }
    loadQuickBooksCashSalesConfig(): void {
        this.showQuickBooksPopover = false;
        this.openModal(QuickBooksComponent, false, true, 'vertical-center');
    }
    loadTrainingTopics(): void {
        this.showTrainingPopover = false;
        this.openModal(TrainingAreaComponent, false, true, 'vertical-center modal-max-width-55');
    }
    loadQuickSearch(): void {
        this.showInventoryPopover = false;
        this.openModal(QuickSearchComponent, false, false, 'vertical-center modal-max-width-95');
    }
    loadDispatch(): void {
        this.showDeliveryPopover = false;
        this.openModal(DispatchComponent, false, false, 'vertical-center modal-max-width-70');
    }
    loadTerminalReports(): void {
        this.openModal(TerminalReportsComponent, false, false, 'vertical-center');
    }
    loadGiftCard(): void {
        const initialState = {
            numpadTitle: 'Gift Card Number Entry',
            numpadOption: {
                allowDecimal: false, allowLeadingZero: true, allowAlphabets: true, doubleZero: false
            }
        };
        this.openModal(GiftCardNumpadWrapperComponentComponent, false, false, 'vertical-center', initialState,
            this.openGiftCardManagementModal);
    }
    openGiftCardManagementModal = (res) => {
        if (res?.value) {
            const initialState = {
                giftCardNumber: res.value
            };
            this.openModal(GiftCardComponent, false, false, 'vertical-center modal-max-width-98', initialState);
        }
    }
    loadTrainingApprove(): void {
        this.showTrainingPopover = false;
        this.openModal(TrainingApproveComponent, false, false, 'vertical-center modal-max-width-55');
    }
    loadOrderMaintenance(): void {
        this.openModal(OrderMaintenanceComponent, false, false, 'vertical-center modal-max-width-98');
    }

    trainingMode(): void {
        if (!JSON.parse(this.applicationStateService.sessionTrainingMode)) {
            this.trainingIcon = this.icons.trainingModeOn;
            this.applicationStateService.sessionTrainingMode = true;
            this.trainingModeText = 'Training Mode Off';
            this.router.navigate(['order/order-entry']);

        } else {
            const cancelTrainingOrder: OrderCancel = {
                currentTerminalID: this.applicationStateService.terminalId,
                UserId: this.applicationStateService.userId,
            };
            this.orderService.deleteTrainingOrders(cancelTrainingOrder)
                .subscribe({
                    next: res => {
                        this.trainingIcon = this.icons.trainingModeOff;
                        this.applicationStateService.sessionTrainingMode = false;
                        this.trainingModeText = 'Training Mode On';
                        this.router.navigate(['order/order-entry']);

                    }, error: this.alertService.showApiError
                });
        }
    }
    designMode(): void {
        if (!JSON.parse(this.applicationStateService.sessionDesignMode)) {
            this.applicationStateService.sessionDesignMode = true;
            this.designModeText = 'End Design';
            this.router.navigate(['order/order-entry']);

        } else {
            this.applicationStateService.sessionDesignMode = false;
            this.designModeText = 'Design Screens';
            this.router.navigate(['order/order-entry']);

        }

    }
    drawerCheckPoint(): void {
        this.showDrawerPopover = false;
        this.isDrawerCheckPoint = true;
        this.getPaymentDetails(true);
    }

    closeDrawer(): void {
        this.showDrawerPopover = false;
        this.isDrawerCheckPoint = false;
        this.applicationStateService.statusCloseDrawer = true;

        if (!this.applicationStateService.unservedOrdersData) {
            this.spinnerService.show();
            this.orderService.getUnservedOrdersData(this.settingParam.CashDrawerPrinter.Id, this.settingParam.UserID)
                .pipe(finalize(() => {
                    this.spinnerService.hide();
                }))
                .subscribe({
                    next: (response) => {
                        this.applicationStateService.unservedOrdersData = response;
                        this.checkUnservedOrders(this.applicationStateService.unservedOrdersData);
                    }, error: this.alertService.showApiError
                });
        } else {
            this.checkUnservedOrders(this.applicationStateService.unservedOrdersData);
        }
    }

    checkUnservedOrders(unservedOrders: Array<UnservedOrdersData>) {
        if (unservedOrders.length > 0) {
            const initialState = {
                message: Messages.CloseDayUnsettledOrders,
                modalHeaderText: 'Close drawer?',
                confirmButtonText: 'Close'
            };
            this.openModal(InfoModalComponent, false, false, 'vertical-center', initialState);
        } else {
            this.getPaymentDetails(true);
        }
    }

    openDrawerCheckpoint() {
        const modalRef = this.modalService.getModalWrapper(DrawerCheckpointComponent);
        const modal = modalRef.show({
            keyboard: false,
            animated: false,
            class: 'vertical-center modal-max-width-95 modal-width',
            initialState: {
                drawerAmountModel: this.drawerAmountModel,
                isDrawerCheckPoint: this.isDrawerCheckPoint,
                registerAmountInDrawer: this.registerAmountInDrawer
            }
        });
        modal.close.subscribe((res) => {
            if (res?.shouldUpdateStatus) {
                this.getDrawerStatus();
            }
        });
    }

    loadProductDashboard(dashboardName: string): void {
        this.spinnerService.show();
        this.dashboardConfigurationService.getDashboardByName(dashboardName)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (res: Dashboard) => {
                    if (res) {
                        this.router.navigate(['dashboards/template', 'interactive', res.Id]);
                    } else {
                        this.router.navigate(['manage/dashboards/list']);
                    }
                }, error: this.alertService.showApiError
            });
    }

    closeDay(): void {
        this.openModal(EndOfDayTriggerComponent, false, false, 'vertical-center');
    }

    createOnlineBackup(): void {
        const modalRef = this.modalService.getModalWrapper(ConfirmBackupModalComponent);
        const modal = modalRef.show({
            keyboard: false,
            animated: false,
            class: 'vertical-center',
            initialState: {
                message: Messages.ConfirmSaveBackup,
                modalHeaderText: 'Confirm',
                rejectButtonText: 'No',
                confirmButtonText: 'Yes'
            }
        });
        modal.close.subscribe((res) => {
            if (res?.shouldConfirm) {
                this.spinnerService.show();
                this.managesService.createFullBackup(res.sendEmail).pipe(finalize(() => {
                    this.spinnerService.hide();
                })).subscribe({ next: response => { }, error: this.alertService.showApiError });
            }
        });
    }
    openCashDrawerClicked(): void {
        if (!this.settingParam) {
            this.settingParam = this.applicationStateService.settingParam;
        }
        if (!this.settingParam.CashDrawerPrinter.Id) {
            const initialState = {
                message: Messages.DrawerNotMapped,
                modalHeaderText: 'Message',
            };
            this.openModal(InfoModalComponent, false, false, 'vertical-center', initialState);
        } else {
            this.settingParam.TerminalName = this.applicationStateService.terminalName;
            this.managesService.openCashDrawerClicked(this.settingParam)
                .subscribe({ next: res => { }, error: this.alertService.showApiError });
        }
    }

    loadCashDrawer() {
        this.applicationStateService.statusCloseDrawer = false;

        if (!this.settingParam.CashDrawerPrinter.Id) {
            const initialState = {
                message: Messages.DrawerNotMapped,
                modalHeaderText: 'Message',
            };
            this.openModal(InfoModalComponent, false, false, 'vertical-center', initialState);
        } else {
            this.openCashDrawerClicked();
            this.openModal(LoadDrawerComponent, false, false, 'vertical-center');
        }
    }

    manageInactiveProducts(): void {
        this.router.navigate(['manage/menu-setup/inactive-products']);
    }
    manageUnlinkedScreen(): void {
        this.router.navigate(['manage/menu-setup/unlinked-screen']);
    }

    unusedImages(): void {
        this.router.navigate(['manage/menu-setup/unused-images']);
    }

    loadLogInventory(): void {
        this.router.navigate(['manage/log-inventory']);
    }
    loadInventoryBarcodeScanner(): void {
        this.router.navigate(['manage/inventory-barcode']);
    }
    loadInventoryReceiving(): void {
        this.router.navigate(['manage/inventory-receiving']);
    }
    loadInventoryProductLoss(): void {
        this.router.navigate(['manage/inventory-loss']);
    }
    loadInventoryProductPrep(): void {
        this.router.navigate(['manage/inventory-prep-recording']);
    }
    loadBatchMarkAsOrdered(): void {
        this.router.navigate(['manage/inventory-batch-mark-as-ordered']);
    }
    loadPrintoutDesigner(): void {
        this.router.navigate(['manage/receipt-script']);
    }
    loadLogViewer(): void {
        this.showLogViewerPopover = false;
        this.router.navigate(['manage/log-viewer']);
    }
    loadAudioRecordings(): void {
        this.showLogViewerPopover = false;
        this.router.navigate(['manage/captured-audio']);
    }
    loadHICDashboard(): void {
        this.router.navigate(['manage/dashboard/hic']);
    }
    loadLayoutDesigner(): void {
        this.router.navigate(['manage/layouts/list']);
    }
    loadHangFireJobs(): void {
        this.router.navigate(['manage/background-jobs']);
    }
    loadEventManager(): void {
        this.router.navigate(['manage/events']);
    }
    loadScheduledEventManager(): void {
        this.router.navigate(['manage/events/scheduled']);
    }
    loadAppConfiguration(): void {
        this.router.navigate(['manage/app-configuration']);
    }
    loadPromotion(): void {
        this.router.navigate(['manage/promotion']);
    }
    loadSystemConfiguration(): void {
        this.router.navigate(['manage/system-configuration'], { state: { isInitialize: true } });
    }
    loadDashboardList(): void {
        this.router.navigate(['manage/dashboards/list']);
    }
    loadSubscriptions(): void {
        this.showAutomatedPopover = false;
        this.router.navigate(['manage/automated-exports/subscriptions']);
    }
    loadManualSubscriptions(): void {
        this.showAutomatedPopover = false;
        this.router.navigate(['manage/automated-exports/subscriptions/manual']);
    }
    loadConfiguration(): void {
        this.showAutomatedPopover = false;
        this.router.navigate(['manage/automated-exports/configuration/data-source']);
    }
    loadDrivers(): void {
        this.showDeliveryPopover = false;
        this.openModal(DriverComponent, false, false, 'vertical-center');
    }
    loadMenuDisplay(): void {
        this.router.navigate(['manage/menu-display']);
    }
    loadOrders() {
        this.router.navigate(['manage/online-order']);
    }
    transferInventory() {
        this.router.navigate(['manage/inventory-transfer']);
    }

    loadOrderKiosk(): void {
        this.router.navigate(['manage/order-kiosk']);
    }
}
