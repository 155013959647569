import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
  AlertsService, ApplicationStateService, CreditCardPaymentStatus, DomainConstants, InfoModalComponent, Messages, ModalComponent,
  ModalService, ModalWrapperComponent, OrderService, RabbitMQService, SettingParam, SpinnerService
} from 'src/app/shared';
import { refundPaymentBan, share, angleRight, angleDoubleRight, angleLeft, angleDoubleLeft } from 'src/app/shared/components/icon/icons';
import { finalize } from 'rxjs/operators';
import { cloneDeep, forEach, remove, sumBy } from 'lodash';
import { QuantityChangePopupComponent } from 'src/app/shared/components/quantity-change-popup/quantity-change-popup.component';
import { RefundOrder, RefundOrderItem } from '../../interface';
import { forkJoin, Subscription } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OrderMaintenanceService } from '../../services';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { CurrencyPipe } from '@angular/common';
import { CreditCardResponseModalComponent } from 'src/app/orders/order-entry/settle/component/settle/credit-card-response-modal';
import { Id, OrderInvoice, OrderInvoiceOrderProduct } from 'src/app/orders/interface/order-invoice';
import { PeripheralModelComponent } from 'src/app/orders/order-entry/peripheral-model';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pos-refund-payment',
  templateUrl: './refund-payment.component.html',
  styleUrls: ['./refund-payment.component.scss']
})
export class RefundPaymentComponent extends ModalComponent implements OnInit, OnDestroy {

  @Input() orderId = 0;
  products: Array<OrderInvoiceOrderProduct> = [];
  productsRefund: Array<OrderInvoiceOrderProduct> = [];
  orderData: OrderInvoice;
  refundOrderData: OrderInvoice = null;
  totalDiscount: number;
  totalMarkup: number;
  orderItemTypes = DomainConstants.OrderItemTypes;
  refundSurrogateOrderId = 0;
  refundProduct: OrderInvoiceOrderProduct;
  refundQtyFlag: boolean;
  refundQty = 0;
  qtyToAdd = 0;
  checkCCPaymentStatusInterval;
  refundOrderId = 0;
  settingParam: SettingParam;
  refundTypeAmount = 0;
  icons = {
    refundPaymentBan, share, angleRight, angleDoubleRight, angleLeft, angleDoubleLeft
  };
  @ViewChild('readOnlyOrderSummary') public readOnlyOrderSummary: any;
  isDisableApprovedButton: boolean = true;
  peripheralModalRef: ModalWrapperComponent<any>;
  creditCardResponseRef: ModalWrapperComponent<any>;
  peripheralCreditCardMessage: string = '';
  rabbitMQRequestId: string = '';
  isEBTPayment: boolean;
  creditCardResponse = {
    header: '',
    message: '',
    buttonText: 'Cancel',
    isShowCCError: false ,
  }
  rabbitMqOrderEntryTerminalQueueSubscription: Subscription;
  rabbitMqHICResponseSubscription: Subscription;
  refundOrderSeats = [];
  refundedOrderAmount: number = 0;
  constructor(modalRef: BsModalRef,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private orderService: OrderService,
    private applicationStateService: ApplicationStateService,
    private modalService: ModalService,
    private orderMaintenanceService: OrderMaintenanceService,
    private currencyPipe: CurrencyPipe,
    private rabbitMQService: RabbitMQService) {
    super(modalRef);
  }

  ngOnInit(): void {
    this.getOrderDetails();
    this.refundOrderData = this.orderService.getNewOrderInvoiceDetails();
    this.subscribeToHICResponseToTerminal();
    this.subscribeToOrderEntryTerminalQueue();
    this.setDefaults();
  }

  setDefaults = () => {
    this.settingParam = this.applicationStateService.settingParam;
    this.peripheralCreditCardMessage = Messages.SwipeCardAndSelectResult;
  }

  ngOnDestroy(): void {
    this.clearCCPaymentStatusInterval();
  }

  subscribeToHICResponseToTerminal() {
    this.rabbitMqHICResponseSubscription = this.rabbitMQService.subscribeToHICResponseToTerminal$(this.applicationStateService.terminalId)
      .subscribe((message: any) => {
        const response = message.Payload.HICReponse;
        if (response.HardwareType == 'CreditCardTerminal') {
          this.creditCardResponse.header = 'Info';
          if (response.Status == DomainConstants.HICResponseStatus.Connected) {
            this.creditCardResponse.message = Messages.SwipeCardRequest;
          } else if (response.Status == DomainConstants.HICResponseStatus.Busy) {
            if (response.ConnectedToTerminalId == this.applicationStateService.terminalId) {
              this.creditCardResponse.message = Messages.CreditCardTerminalBusyWithPreviousRequest;
            } else {
              this.creditCardResponse.message = StringUtils.format(
                Messages.CreditCardTerminalBusy, {
                'terminalName': response.ConnectedToTerminalName,
                'creditCardTerminalName': response.DeviceName
              });
            }
            this.creditCardResponse.buttonText = 'Ok';
            this.rabbitMQRequestId = '';
          } else if (response.Status == DomainConstants.HICResponseStatus.Error) {
            this.creditCardResponse.message = response.Message;
            this.creditCardResponse.buttonText = 'Ok';
            this.rabbitMQRequestId = '';
          }
        }
      });
  }

  subscribeToOrderEntryTerminalQueue() {
    this.rabbitMqOrderEntryTerminalQueueSubscription = this.rabbitMQService.subscribeToOrderPaymentResponseTerminalQueue$(this.applicationStateService.terminalId)
      .subscribe((message: any) => {
        this.creditCardPaymentCompleted(message.Payload.PaymentResponse);
      });
  }

  onCCTransactionGetSuccessStatus = () => {
    this.clearCCPaymentStatusInterval();
    this.creditCardResponse.message = Messages.RefundSuccess;
    this.rabbitMQRequestId = '';
    this.creditCardResponse.buttonText = 'Ok';
    this.creditCardResponse.header = Messages.Success;
    this.hideCCPaymentModel();
  }

  onCCTransactionGetCanceledStatus = () => {
    this.clearCCPaymentStatusInterval();
    this.creditCardResponse.message = DomainConstants.TransactionStatus.Canceled;
    this.rabbitMQRequestId = '';
    this.creditCardResponse.header = Messages.Error;
    this.creditCardResponse.buttonText = 'Ok';
  }

  onCCTransactionGetFailedStatus = () => {
    this.clearCCPaymentStatusInterval();
    this.creditCardResponse.message = Messages.RefundRejected;
    this.rabbitMQRequestId = '';
    this.creditCardResponse.header = Messages.Error;
    this.creditCardResponse.buttonText = 'Ok';
  }

  checkCCPaymentStatus = () => {
    if (this.rabbitMQRequestId && this.orderId) {
      this.orderService.getTransactionStatus(this.rabbitMQRequestId)
        .subscribe({
          next: (response: CreditCardPaymentStatus) => {
            if (response.Status == DomainConstants.TransactionStatus.Success) {
              this.onCCTransactionGetSuccessStatus();
            } else if (response.Status == DomainConstants.TransactionStatus.Failed) {
              this.onCCTransactionGetFailedStatus();
            } else if (response.Status == DomainConstants.TransactionStatus.Canceled) {
              this.onCCTransactionGetCanceledStatus();
            }
            else if (this.creditCardResponse.message == Messages.ConnectingCreditCardTerminal && response.Status == DomainConstants.TransactionStatus.Pending) {
              this.creditCardResponse.message = Messages.StillConnectingCreditCardTerminal;
            }
          }, error: this.alertService.showApiError
        });
    } else {
      this.clearCCPaymentStatusInterval();
    }
  }

  clearCCPaymentStatusInterval() {
    if (this.checkCCPaymentStatusInterval) {
      clearInterval(this.checkCCPaymentStatusInterval);
    }
  }

  hideCCPaymentModel = () => {
    setTimeout(() => {
      this.creditCardResponseRef?.hide();
      this.close.emit({ shouldReload: true });
    }, 2500);
  }

  creditCardPaymentCompleted = (response) => {
    if (response && response.PaymentCompleted) {
      this.creditCardResponse.message = response.PaymentResponseFromWeb.Message == Messages.PaymentApproved ? Messages.RefundSuccess : response.PaymentResponseFromWeb.Message;
      this.rabbitMQRequestId = '';
      this.creditCardResponse.buttonText = 'Ok';
      if (response.PaymentSuccessfullyComplete) {
        this.creditCardResponse.header = Messages.Success;
        this.hideCCPaymentModel();
      } else {
        if (this.creditCardResponse.message == 'Canceled') {
          this.creditCardResponse.message = Messages.RefundPaymentCanceled;
          this.creditCardResponse.header = 'Result';
        } else {
          this.creditCardResponse.header = Messages.Error;
        }
        this.cancelRefundOrder();
      }
    }
  }

  getOrderDetails() {
    if (this.orderId > 0) {
      this.spinnerService.show();
      this.orderService.getOrderDetails(this.orderId)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (response: OrderInvoice) => {
            this.products = response.OrderItems;
            this.orderData = response;
          }, error: this.alertService.showApiError
        });
    }
  }

  precisionRound(number, precision) {
    const factor = Math.pow(10, precision);
    return Math.round(number * factor) / factor;
  }

  applyRefundViaCash() {
    if (this.refundOrderData) {
      const refundOrderModel = this.prepareModelForRefund(DomainConstants.PaymentTypes.CASH);
      this.spinnerService.show();
      this.orderMaintenanceService.applyRefundViaCash(refundOrderModel)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res: OrderInvoice) => {
            const successMessage = StringUtils.format(Messages.CashRefundSuccess, { 'amount': this.currencyPipe.transform(Math.abs(this.refundOrderData.GrandTotal ?? 0)) });
            this.orderRefundSuccess(successMessage);
          }, error: this.alertService.showApiError
        });
    }
  }

  applyRefundViaEBT() {
    if (this.refundOrderData) {
      const refundOrderModel = this.prepareModelForRefund(DomainConstants.PaymentTypes.EBT);
      this.spinnerService.show();
      this.orderMaintenanceService.applyRefundViaEBT(refundOrderModel)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res: OrderInvoice) => {
            const successMessage = StringUtils.format(Messages.CardRefundSuccess, { 'amount': this.currencyPipe.transform(Math.abs(this.refundOrderData.GrandTotal ?? 0)) });
            this.orderRefundSuccess(successMessage);
          }, error: this.alertService.showApiError
        });
    }
  }

  onApprovedCreditCardPayment = () => {
    if (this.isEBTPayment) {
      this.applyRefundViaEBT();
    } else {
      this.applyRefundViaPeripheralCreditCard();
    }
  }

  cancelCreditCardTransaction() {
    if (this.rabbitMQRequestId !== '') {
      const cancelRequestModel = {
        TerminalId: this.applicationStateService.terminalId,
        TerminalName: this.applicationStateService.terminalName,
        CreditCardTerminalId: this.settingParam.CreditCardTerminal.Id,
        OrderId: this.refundOrderId,
        RequestId: this.rabbitMQRequestId
      };
      this.spinnerService.show();
      this.orderService.cancelCreditCardPayment(cancelRequestModel)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (response: any) => {
            this.rabbitMQRequestId = '';
          }, error: this.alertService.showApiError
        });
      this.cancelRefundOrder();
    } else {
      this.close.emit({ shouldReload: true });
    }
  }

  applyRefundViaPeripheralCreditCard() {
    if (this.refundOrderData) {
      const refundOrderModel = this.prepareModelForRefund(DomainConstants.PaymentTypes.CREDITCARD);
      this.spinnerService.show();
      this.orderMaintenanceService.applyRefundViaPeripheralCreditCard(refundOrderModel)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res: OrderInvoice) => {
            const successMessage = StringUtils.format(Messages.CardRefundSuccess, { 'amount': this.currencyPipe.transform(Math.abs(this.refundOrderData.GrandTotal ?? 0)) });
            this.orderRefundSuccess(successMessage);
          }, error: this.alertService.showApiError
        });
    }
  }

  cancelRefundOrder = () => {
    this.orderService.deleteOrder(this.refundOrderId)
      .subscribe({
        next: () => {
        }, error: this.alertService.showApiError
      });
  }


  cancelCreditCardPayment() {
    this.creditCardResponseRef?.hide();
  }

  applyRefundViaIntegratedCreditCard = () => {
    this.spinnerService.show();
    this.setRabbitMQRequestId();
    const refundOrderModel = this.prepareModelForRefund(DomainConstants.PaymentTypes.CREDITCARD);
    refundOrderModel.CreditCardTerminalId = this.settingParam.CreditCardTerminal.Id;
    refundOrderModel.RabbitMQRequestId = this.rabbitMQRequestId;
    this.orderMaintenanceService.applyRefundViaIntegratedCreditCard(refundOrderModel)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: Id) => {
          this.checkCCPaymentStatusInterval = setInterval(() => {
            this.checkCCPaymentStatus();
          }, 5000);
          this.refundOrderId = res.Value;
          this.makeCreditCardPayment();
        }, error: this.alertService.showApiError
      });
  }

  refundByCreditCard = () => {
    if (this.settingParam.AllowCreditCards) {
      if (this.settingParam.CreditCardType === DomainConstants.CreditCardType.Peripheral) {
        this.openPeripheralModel();
      } else {
        this.applyRefundViaIntegratedCreditCard();
      }
    }
  }

  setRabbitMQRequestId() {
    this.rabbitMQRequestId = this.rabbitMQService.guidWithTimeStamp();
  }

  makeCreditCardPayment = () => {
    this.creditCardResponse.header = 'Info';
    this.creditCardResponse.message = Messages.ConnectingCreditCardTerminal;
    this.creditCardResponse.buttonText = 'Cancel';
    this.creditCardResponseRef = this.modalService.getModalWrapper(CreditCardResponseModalComponent);
    const modalRef = this.creditCardResponseRef.show({
      initialState:{
        creditCardResponse : this.creditCardResponse
      }
    });
    modalRef.close.subscribe((response)=>{
      if(response?.cancel){
        this.cancelCreditCardTransaction()
      }
    })
  }

  openPeripheralModel(isEBTPayment = false) {
    this.isEBTPayment = isEBTPayment;
    this.peripheralModalRef = this.modalService.getModalWrapper(PeripheralModelComponent);
    const modalRef = this.peripheralModalRef.show({
      initialState: {
        peripheralCreditCardMessage: this.peripheralCreditCardMessage
      }
    });
    modalRef.close.subscribe((res)=>{
      if(res.approve){
        this.onApprovedCreditCardPayment();
      }
    })
  }

  getOrderActual = () => {
    if (this.refundOrderData) {
      this.refundedOrderAmount = 0;
      const refundOrderModel = this.prepareModelForRefund(DomainConstants.PaymentTypes.CASH);
      this.spinnerService.show();
      const refundOrderObservables = [];
      refundOrderObservables.push(this.orderService.getOrderActual(refundOrderModel));
      refundOrderObservables.push(this.orderService.getSeatWiseRefundOrderDetails(refundOrderModel));
      refundOrderObservables.push(this.orderService.getOrderRefundedAmount(this.orderData.Id.Value));
      forkJoin(refundOrderObservables)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (responses: Array<any>) => {
            if (responses) {
              this.refundOrderData = responses[0] ? null : this.refundOrderData;
              this.refundOrderData = responses[0] && (responses[0] as OrderInvoice)?.OrderItems?.length ? responses[0] as OrderInvoice :
                this.orderService.getNewOrderInvoiceDetails();
              this.refundOrderSeats = responses[1] ? responses[1] as Array<any> : [];
              var refundedAmount = responses[2] ? responses[2] as number : 0;
              var totalRefundedAmount = this.refundOrderData.GrandTotal + refundedAmount;
              if (Math.abs(totalRefundedAmount) > this.orderData.PaidAmount) {
                this.refundedOrderAmount = (this.orderData.PaidAmount - Math.abs(refundedAmount)) * -1;
              }
            }
          }, error: this.alertService.showApiError
        });
    }
  }

  prepareModelForRefund = (paymentType: number): RefundOrder => {
    const refundOrderItems: Array<RefundOrderItem> = [];
    forEach(this.refundOrderData.OrderItems, (item) => {
      const refundOrderItem: RefundOrderItem = {
        Id: item.Id.Value,
        Qty: item.Qty,
      };
      refundOrderItems.push(refundOrderItem);
    });
    return {
      OrderId: this.orderData.Id.Value,
      RefundOrderItems: refundOrderItems,
      RefundPaymentType: paymentType,
      TerminalId: this.applicationStateService.terminalId,
      RefundAmount: this.refundedOrderAmount != 0 ? this.refundedOrderAmount : null
    }
  }

  cancelRefund() {
    this.close.emit();
  }

  orderRefundSuccess = (message: string) => {
    const modalRef = this.modalService.getModalWrapper(InfoModalComponent);
    const modal = modalRef.show({
      animated: false,
      class: "vertical-center",
      initialState: {
        message: message,
      },
    });
    modal.close.subscribe((res) => {
      this.close.emit({ shouldReload: true });
    });
  }

  getProductQtyFromRefundOrder = (productId: number): number => {
    return sumBy(this.refundOrderData.OrderItems, (refundItem) => {
      return refundItem.Id.Value === productId && refundItem.Qty;
    });
  }


  addToRefund = () => {
    forEach(this.orderData.OrderItems, (item) => {
      if (item.IsSelected) {
        const availableProductQty = this.getProductQtyFromRefundOrder(item.Id.Value);
        if (availableProductQty && availableProductQty >= item.Qty || (item.Qty - (item.RefundedQty ? item.RefundedQty : 0) -
          (item.TempRefundQty ? item.TempRefundQty : 0) == 0)
          || item.LinkedProductId) {
          this.alertService.renderErrorMessage(Messages.ProductAlreadyRefunded);
        } else {
          if (this.canPromptForOrderProductQty(item)) {
            this.promptForQty(cloneDeep(item), availableProductQty > 0);
          } else {
            this.addItemToRefund(cloneDeep(item), item.Qty - (item.RefundedQty ? item.RefundedQty : 0) -
              (item.TempRefundQty ? item.TempRefundQty : 0), availableProductQty > 0);
          }
        }
      }
    });
    const orderDetails = cloneDeep(this.orderData);
    this.orderData = null;
    this.orderData = orderDetails;
  }

  addItemToRefund = (item: OrderInvoiceOrderProduct, qty: number, isAvailableInRefund: boolean = false) => {
    item.IsSelected = false;
    const refundOrderData = cloneDeep(this.refundOrderData);
    item.Qty = qty;
    item.TempRefundQty = qty;
    this.affectRefundQty(item, qty);
    if (!isAvailableInRefund) {
      refundOrderData.OrderItems.push(item);
    } else {
      forEach(refundOrderData.OrderItems, (refundItem) => {
        if (refundItem.Id.Value === item.Id.Value) {
          refundItem.Qty += qty;
        }
      });
    }
    this.refundOrderData = refundOrderData;
    this.getOrderActual();
    if (this.readOnlyOrderSummary) {
      this.readOnlyOrderSummary.changeSelection(item);
    }
  }

  private canPromptForOrderProductQty(product: OrderInvoiceOrderProduct): boolean {
    const defaultQty = product.SalesProductBusinessEntity && product.SalesProductBusinessEntity.DefaultQty ?
      product.SalesProductBusinessEntity.DefaultQty : 0;
    return (this.settingParam.AllowOrderingFractionalQty
      && defaultQty !== 1 && product.Qty - (product.RefundedQty ? product.RefundedQty : 0) +
      (product.TempRefundQty ? product.TempRefundQty : 0) > 0)
      || (!this.settingParam.AllowOrderingFractionalQty && product.Qty - (product.RefundedQty ? product.RefundedQty : 0) +
        (product.TempRefundQty ? product.TempRefundQty : 0) > 1);
  }

  promptForQty(product: OrderInvoiceOrderProduct, isAvailableInRefund: boolean = false) {
    const maxQty = (product.Qty - (product.RefundedQty ? product.RefundedQty : 0) - (product.TempRefundQty ? product.TempRefundQty : 0));
    const numpadModalRef = this.modalService.show(QuantityChangePopupComponent, {
      animated: false,
      keyboard: false,
      class: 'vertical-center',
      initialState: {
        value: maxQty,
        salesProductUnit: product.UnitName,
        allowDecrement: true,
        maxQty: maxQty,
        header: 'Enter Refund Qty',
        maxValidationMessage: StringUtils.format(Messages.InvalidMaxRefundQty,
          { 'qty': maxQty })
      }
    });
    numpadModalRef.close.subscribe(res => {
      if (res.value) {
        this.addItemToRefund(product, res.value, isAvailableInRefund);
      }
    });
  }

  affectRefundQty = (product: OrderInvoiceOrderProduct, refundedQty: number) => {
    const orderDetails = cloneDeep(this.orderData);
    this.orderData = null;
    forEach(orderDetails.OrderItems, (item) => {
      if (item.Id.Value === product.Id.Value) {
        item.TempRefundQty = Number(item.TempRefundQty ? item.TempRefundQty : 0) + Number(refundedQty);
        item.IsSelected = false;
      }
    });
    this.orderData = orderDetails;
  }

  changeOrderProductSelectionForRefundOrder = (product: OrderInvoiceOrderProduct) => {
    if (product) {
      forEach(this.refundOrderData.OrderItems, (item) => {
        if (item.Id.Value === product.Id.Value) {
          item.IsSelected = product.IsSelected;
        } else {
          item.IsSelected = false;
        }
      });
    }
  }

  changeOrderProductSelection = (product: OrderInvoiceOrderProduct) => {
    if (product) {
      forEach(this.orderData.OrderItems, (item) => {
        if (item.Id.Value === product.Id.Value) {
          item.IsSelected = product.IsSelected;
        } else {
          item.IsSelected = false;
        }
      });
    }
  }

  addAllItemToRefund = () => {
    const orderDetails = cloneDeep(this.orderData);
    orderDetails.SurrogateOrderId = 0;
    orderDetails.Id.Value = 0;
    remove(orderDetails.OrderItems, (item) => {
      if ((item.Qty - (item.RefundedQty ? item.RefundedQty : 0) + (item.TempRefundQty ? item.TempRefundQty : 0) == 0)
        || item.LinkedProductId) {
        return item;
      }
    });
    forEach(orderDetails.OrderItems, (item) => {
      item.IsSelected = false;
      this.affectRefundQty(item, item.Qty - ((item.RefundedQty?? 0) - (item.RefundedQty ? (item.TempRefundQty??0) : 0)));
      item.Qty -= ((item.RefundedQty?? 0) - (item.RefundedQty ? (item.TempRefundQty??0) : 0));
    });
    if (orderDetails?.OrderItems?.length) {
      this.refundOrderData = orderDetails;
      this.getOrderActual();
    } else {
      this.alertService.renderErrorMessage(Messages.ProductAlreadyRefunded);
    }
  }

  removeItemFromRefund = () => {
    const refundOrderDetails = cloneDeep(this.refundOrderData);
    this.refundOrderData = null;
    const removedItem = remove(refundOrderDetails.OrderItems, (item) => {
      if (item.IsSelected) {
        this.affectRefundQty(item, (item.Qty * -1));
        return item;
      }
    });
    this.refundOrderData = refundOrderDetails;
    if (removedItem && removedItem.length) {
      this.getOrderActual();
    }
  }

  removeAllItemsFromRefund = () => {
    const orderDetails = cloneDeep(this.orderData);
    this.orderData = null;
    forEach(orderDetails.OrderItems, (item) => {
      item.TempRefundQty = 0;
      item.IsSelected = false;
    });
    this.orderData = orderDetails;
    this.refundOrderData = cloneDeep(this.orderService.getNewOrderInvoiceDetails());
    this.getOrderActual();
  }
}
