export const Messages = {
    // A //
    AccountCategoryDeleted: 'Account category deleted',
    AccountCategorySaveSuccess: 'Account category saved',
    AccountSaveSuccess: 'Account saved',
    AccountDeleted: 'Account deleted',
    AccountsandTabsSaveSuccess: 'Accounts and tabs configuration updated',
    AddNewSeatPrevent:
        'Pay only {typeAmount} of the {totalAmount} total due? This will prevent further payment by {subaccountTerm} for this order.',
    AudioNotificationFileSaveSuccess: 'Audio notification file saved',
    AudioNotificationFileDeleteSuccess: 'Audio notification file deleted',
    AccountAttributeTypeSaveSuccess: 'Account attribute type saved',
    AccountAttributeTypeUpdateSuccess: 'Account attribute type updated',
    AccountAttributeTypeDeleteSuccess: 'Account attribute type deleted',


    // B //
    BarcodeSaveSuccess: 'Barcode saved',
    BarcodeUpdatedSuccess: 'Barcode updated',
    ButtonMinimumMessage:
        'Please set the minimum to a number lower than the maximum.',
    BrandingSaveSuccess: 'Branding configuration updated',
    ButtonDeleted: 'Button deleted',
    ButtonSaveSuccess: 'Button saved',
    ButtonBehaviorSaveSuccess: 'Button behavior saved',
    ButtonBehaviorDeleted: 'Button behavior deleted',
    ButtonBehaviorExist: '{buttonBehavior} behavior already exist',
    BackupCreatedSuccess: 'Backup created',
    BackupRestoredSuccess: 'Backup restored',
    BaselineExpenseSaveSuccess: 'Baseline expense saved',
    BaselineExpenseDeletedSuccess: 'Baseline expense deleted',
    BackupDeletedSuccess: 'Backup deleted',
    ButtonHistoryDeleteConfirm: 'Are you sure you want to delete this button history?',
    ButtonHistoryDeletedSuccess: 'Button history deleted',
    ButtonHistorySavedSuccess: 'Button history saved',
    // C //
    CashDrawerNotConnected: 'Cash drawer not connected',
    CashRefundSuccess: 'Amount to refund in Cash: {amount}',
    CardRefundSuccess: 'Amount refunded to Card: {amount}',
    ClockIn: 'Clock In',
    ClockOut: 'Clock Out',
    CloseDayUnsettledOrders: 'There are unsettled orders. Please settle all orders before closing the drawer.',
    CloseDrawerBeforeSignOut: 'Please close the drawer before signing out.',
    CreditCardTerminalBusy: 'The {creditCardTerminalName} credit card terminal in use by {terminalName}',
    CreditCardTerminalBusyWithPreviousRequest:
        'Credit card terminal is busy with previous request. Please retry.',
    CreditCardPaymentSignatureRequired: 'Signature is required. Receipt printing.',
    CanNotFindResource: 'Cannot find the resource.',
    CodeNotMatch: 'Codes must match.',
    ColorsEffectWarning: 'Please log out and back in, or refresh your browser, for these changes to take effect.',
    ConfirmDeleteAudioNotificationFile: 'Are you sure you want to delete this audio notification file?',
    //ConfirmAddAccountOfLoyalty: 'Couldn\'t find a registered card ending with "{LoyaltyCardNumber}". Do you want to register it?',
    ConfirmDeleteCommentWarning: 'Are you sure you want to delete this comment warning?',
    ConfirmDeleteTaskWarning: 'Are you sure you want to delete this task warning?',
    ConfirmConfigurationChangedOfCategory: 'Please confirm the price configuration for this update category',
    ConfirmIsUseSSLConfig: 'A secured site (https) will not work with unsecured connections to RabbitMQ. Please configure RabbitMQ to use SSL.',
    ConfirmDeleteBaselineExpense:
        'Are you sure you want to delete the {ExpenseName} baseline expense?',
    ConfirmDeleteInactiveButton: 'Are you sure you want to delete the "{button}" button?',
    ConfirmDeleteUnlinkedScreen: 'Are you sure you want to delete the "{screen}" screen?',
    ConfirmDeleteUnusedImage: 'Are you sure you want to delete "{image}" <div class="text-align-center pt-10"><img src="{imagePath}/buttons/{image}" alt="" height="50" /><div>',
    ConfirmBulkDeleteUnusedImage: 'Are you sure you want to delete images?',
    ConfirmDeleteDJScheme: 'Are you sure you want to delete this DJ scheme?',
    ConfirmDeleteSlideshow: 'Are you sure you want to delete this slideshow?',
    ConfirmDeleteSlideshowImage: 'Are you sure you want to delete this image?',
    ConfirmDeletePrintScript: 'Are you sure you want to delete the <b>{script}</b> script?',
    ConfirmDeletePlaylist: 'Are you sure you want to delete this playlist?',
    ConfirmDeleteRevenueCenter: 'Are you sure you want to delete this revenue center?',
    ConfirmDeletePlaylistTrack:
        'Are you sure you want to remove track {trackName} from the {playlistName} playlist?',
    ConfirmDeleteTrack: 'Are you sure you want to delete this track?',
    ConfirmDeleteInventoryReceived:
        'Are you sure you want to delete this inventory receiving record?',
    ConfirmDeleteInventoryProductRecipe:
        'Are you sure you want to delete the <b>{prepRecipe}</b> inventory product recipe?',
    ConfirmDeleteMenuButton: 'Do you want to remove {buttonText} from the {categoryName} category?', //Are you sure you want to delete product?
    ConfirmDeleteMenuSection: 'Are you sure you want to delete this section?',
    ConfirmDeleteMenuButtonConfiguration: 'Are you sure you want to delete this mapped option?',
    ConfirmPrintClockOutReceipt: 'Do you want to print a time keeping receipt?',
    ConfirmDeleteSalesGroup: 'Are you sure you want to delete the <b>{salesGroup}</b> sales group?',
    ConfirmDeleteDigitalMenuWidget: 'Are you sure you want to delete this widget?',
    ConfirmDeleteMenuDisplayOverride: 'Are you sure you want to delete this menu override?',
    ConfirmDeleteSalesUnit: 'Are you sure you want to delete the <b>{salesUnit}</b> sales unit?',
    ConfirmDeleteTag: 'Are you sure you want to delete this tag?',
    ConfirmDeleteInventoryProductBarCode:
        'Are you sure you want to remove barcode <b>{barCode}</b>?',
    ConfirmDeleteSalesProductBarcode:
        'Are you sure you want to remove barcode <b>{barcode}</b>?',
    ConfirmDeleteSalesCategory:
        'Are you sure you want to delete the <b>{salesCategory}</b> sales category?',
    ConfirmDeleteDefaultButton:
        'Remove <b>{buttonName}</b> default button?',
    ConfirmDeleteSalesProduct:
        'Are you sure you want to delete this sales product?',
    ConfirmDeleteSchedule: 'Are you sure you want to delete this schedule?',
    // tslint:disable-next-line:max-line-length
    ConfirmDeleteVoidingOrder:
        'Voiding this order is irreversible.  Any previous reports created for this date will now be inaccurate.  Are you sure?',
    ConfirmDeleteVendor: 'Are you sure you want to delete the vendor <b>{vendor}</b>?',
    ConfirmDeleteInventoryCategory:
        'Are you sure you want to delete the <b>{inventoryCategory}</b> inventory category?',
    ConfirmDeleteInventorySubCategory:
        'Are you sure you want to delete the <b>{inventorySubCategory}</b> inventory subcategory?',
    ConfirmDeleteTerminal: 'Deleting this terminal will also clear its mapping with any hardware. Are you sure you want to proceed?',
    ConfirmDeleteDeviceMapping:
        'Are you sure you want to delete this terminal device mapping configuration?',
    ConfirmDeleteRabbitMQStompConfig:
        'Are you sure you want to delete the RabbitMQ STOMP configuration?',
    ConfirmDeleteHardwareDevice:
        'Are you sure you want to delete this hardware device?',
    ConfirmDeleteHIC: 'Are you sure you want to delete this HIC configuration?',
    CashDrawerSaveSuccess: 'Cash drawer configuration updated',
    CommentWarningSaveSuccess: 'Comment warning saved',
    CommentWarningDeleted: 'Comment warning deleted',
    ConfigureColorSaveSuccess: 'Color configuration updated',
    ConfirmDeleteInventoryZone:
        'Are you sure you want to delete this inventory zone?',
    ConfirmDeleteInventoryBin:
        'Are you sure you want to delete this inventory bin?',
    ConfirmDeleteSecurityRole:
        'Are you sure you want to delete the <b>{securityRole}</b> security role?',
    ConfirmDeleteUser: 'Are you sure you want to delete <b>{user}</b>?',
    ConfirmDeleteUserPayRate: 'Are you sure you want to delete the <b>{payRate}</b> pay rate?',
    ConfirmDeleteInfoLinkSection:
        'Are you sure you want to delete this information link section?',
    ConfirmDeleteInfoLink: 'Are you sure you want to delete this information link?',
    ConfirmDeleteJobCode: 'Are you sure you want to delete the <b>{jobCode}</b> job code?',
    ConfirmDeleteTask: 'Are you sure you want to delete this task?',
    ConfirmDeleteInfraction:
        'Are you sure you want to delete the <b>{infraction}</b> infraction?',
    ConfirmDeleteWorkSchedule:
        'Are you sure you want to delete this work schedule entry for <b>{user_name}</b>?',
    ConfirmDeleteWorkScheduleTag:
        'Are you sure you want to delete the <b>{tag}</b> tag?',
    ConfirmDeleteLoginMessage:
        'Are you sure you want to delete this login message?',
    ConfirmDeleteTimeClockEntry:
        'Are you sure you want to delete this time keeping entry?',
    ConfirmDeleteAccountCategory: 'Are you sure you want to delete the <b>{accountCategory}</b> account category?',
    ConfirmDeleteAccount: 'Are you sure you want to delete the <b>{account}</b> account?',
    ConfirmDeleteUnit: 'Are you sure you want to delete the <b>{unitName}</b> unit?',
    ConfirmDeleteSubAccount: 'Are you sure you want to delete this {subaccountTerm}?',
    ConfirmDeleteCashPayout: 'Are you sure you want to delete the <b>{cashPayout}</b> cash payout?',
    ConfirmWithAmountVariance:
        // tslint:disable-next-line:max-line-length
        'The amount you entered ({currencySymbol}{enteredAmount}) varies from the expected amount ({currencySymbol}{totalAmount}) by {currencySymbol}{varianceAmount} ({variance}%). Continue?', // 'You have entered a variance of . Continue?',
    ConfirmWithZeroAmount: 'The expected total is {currencySymbol}0.00.  Are you sure?',
    ConfirmCloseDrawer: 'Close the drawer with  {currencySymbol}{amount}. Continue?',
    ConfirmCheckpoint: 'Checkpoint the drawer with {currencySymbol}{amount}. Continue?',
    CashPayoutDeleted: 'Cash payout deleted',
    CashPayoutSaveSuccess: 'Cash payout saved',
    ConfirmDeleteScreen: 'Are you sure you want to delete the <b>{screenTitle}</b> screen?',
    ConfirmDeleteButton: 'Are you sure you want to delete the <b>{buttonName}</b> button?',
    ConfirmDeleteButtonBehavior: 'Are you sure you want to delete <b>{behaviorName}</b> behavior from this button?',
    CreateManagerLoginSaveSuccess: 'Manager login created.',
    ConfirmRestoreBackup: 'Are you sure you want to restore <b>{Name} ({Time}) </b> backup?',
    ConfirmOverWriteNewButton:
        'Are you sure you want to overwrite this screen with new buttons?',
    ConfirmCancelOrder: 'Are you sure you want to cancel this order?',
    ConfirmDeleteBackup: 'Are you sure you want to delete <b>{Name} ({Time})</b> backup?',
    ConfirmCancelPayment:
        'You have recorded a cash payment. Do you want to cancel this payment and go back to the order?',
    ConfirmAddNewButton: 'Set this button\'s next screen to ',
    ConfirmPrintOrderReceipt: 'Do you want to print receipt?',
    ConfirmCopyButton:
        'Are you sure you want to overwrite this screen with new buttons?',
    ConfiguratorAccessDenied:
        'You must be an Administrator to access system configuration.',
    ConfirmDeleteWhitelistedIp: 'Are you sure you want to delete this IP address?',
    ConfirmCopyToProduct:
        'Are you sure you want to copy the recipe from {FromProduct} to {TargetProduct}?',
    ConfirmCopyAreasToProduct:
        'Are you sure you want to copy selected area(s) from {fromProduct} to {toProduct}?',
    ConfirmCopyInstructionsToProductArea:
        'Are you sure you want to copy selected instruction(s) from {fromProduct} to {targetArea} of {toProduct}?',
    ConfirmDeleteInstructionArea:
        'Are you sure you want to delete {areaName} and all of its associated ingredient instructions?',
    ConfirmAbandonChanges:
        'There are some changes you made below that have not been updated. Do you still want to abandon the changes?',
    ConfirmSettlePartialPaymentPrevent:
        'Pay only {currencySymbol}{payableAmount} of the {currencySymbol}{payableSelectedSeats} total due? This will prevent further payment by {subaccountTerm} for this order.',
    ConfirmSaveBackup: 'Application performance may degrade while backup is being created. Do you want to create a backup now?',
    ConfirmDeletePrice:
        //'Are you sure you want to remove {size} configured price?',
        'The {size} size has a recipe associated with it. Removing this size will expire its recipe immediately. Continue?',
    ConfirmDeleteBarcodeOnDeletePrice: 'The {size} size has a barcode(s) associated with it. Removing this size will remove barcodes immediately. Continue?',
    ConfirmLinkMakeTableToAllOrderEntryTerminal: 'Are you sure you want to link all order entry terminals with the <b>{terminalName}</b> terminal?',
    CardbasedLoginSaveSuccess: 'Card-based login saved',
    CopiedRecipeSaveSussess: 'Recipe copied',
    CookingWarningTimerFinish:
        'Please wait for the timer to finish for this product.',
    CookingWarningAllComponentCooked:
        'This item has already been prepared via the timer.',
    ConfirmDeleteEquipmentType:
        'Are you sure you want to delete the <b>{equipmentType}</b> equipment type?',
    ConfirmDeleteEquipmentProperty:
        'Are you sure you want to delete the <b>{equipmentProperty}</b> equipment property?',
    ConfirmDeleteEquipment: 'Are you sure you want to remove <b>{equipment}</b>?',
    ConfirmDeleteInventoryBinLight: 'Are you sure you want to delete the light?',
    ConfirmDeleteInventoryVendorProfile: 'Are you sure you want to delete the <b>{profileName}</b> inventory vendor profile?',
    ConfirmApplyInventoryVendorProfile: 'Are you sure you want to apply the <b>{profileName}</b> inventory vendor profile?',
    ConfirmDeleteInventoryVendorProfileProduct: 'Remove <b>{productName}</b> from {vendorName}\'s <b>{profileName}</b> profile?',
    ConfirmDeleteTrainingArea: 'Are you sure you want to delete this training area?',
    ConfirmDeleteTrainingTopic: 'Are you sure you want to delete this training topic?',
    ConfirmDeleteTrainingBadge: 'Are you sure you want to delete the training badge?',
    ConfirmDeleteTrainingTopicLevel: 'Are you sure you want to delete this training topic level?',
    ConfirmUpdateBinColor: 'Apply color to all bins named {binName}?',
    ConfirmSaveRecipeIngredients:
        'Some ingredients for <b>{Name}</b> don\'t have sufficient calculated inventory on hand. Do you want to proceed anyway?',
    ConfirmUnSaveChangeToContinue:
        'There are some unsaved changes. Continuing will discard changes.',
    ConfirmPagerNumberUpdate: '<b>Pager {pagerNumber}</b> is already linked to active order <b>{surrogateOrderId}</b>, Are you sure you want to change it to <b>Pager {newPagerNumber}</b>?',
    ConfirmPagerNumberDeassign: 'Are you sure you want to remove <b>Pager {pagerNumber}</b> from the order <b>{surrogateOrderId}</b>?',
    CardbasedLoginOtherUserSignedin: 'You are now signed in as ',
    CardbasedLoginBadgeNumberErrorCode: 'Card read failure, please try again',
    CopyAreaInstructionSavedSuccess:
        'Sales product instruction area and associated instructions copied',
    CopySalesProductSavedSuccess: 'Sales product copied',
    ConnectingCreditCardTerminal: 'Connecting to credit card terminal...',
    ConfirmConflictDeleteSizes: 'A price has been configured for the following products. Removing a size from the category will remove all price configuration, for the selected size, from these products:',
    ConfirmDeleteProductLossReason: 'Are you sure you want to delete this product loss reason?',
    ConfirmDeleteExportScript: 'Are you sure you want to delete this export script?',
    ConfirmDeleteExternalApi: 'Are you sure you want to delete this configured external API?',
    ConfirmDeleteSalesSize: 'Are you sure you want to delete this sales size?',
    // ConfirmDeleteProductLossReason:'Are you sure you want to delete this product loss reason?',
    ConfirmDeleteRawIngredient: 'Are you sure you want to delete this raw ingredient?',
    ConfirmMergeOrder: 'Are you sure you want to merge the order {fromOrder} with order {toOrder}',
    ConfirmMarkUnservedOrder: 'Are you sure you want to mark order ({orderId}) as unserved?',
    ConfirmPaymentPriceRefund: 'You have recorded a cash payment. Do you want to cancel this payment and return to the order?',
    ConfirmPrintInventoryBarCodes: 'Do you want to print barcodes for the selected products? Will print {idsLength} labels.',
    ConfirmDeletePromotion: 'Are you sure you want to delete this promotion?',
    ConfirmApproveTraining:
        'You are about to confirm that <b>{LastName}, {FirstName}</b> has completed the following training: <br/><br/> <b>Area:</b> {Area} <br/> <b>Topic:</b> {Topic} <br/> <b>Level:</b> {Level}',
    CentralApiConfigSaveSuccess: 'Central API configuration saved',
    ConfirmDeleteOptionButton: 'Are you sure you want to remove the {buttonName} button?',
    ConfirmDeleteOption: 'Are you sure you want to delete the {optionName} option?',
    ConfirmDeleteCategory: 'Are you sure you want to delete the {categoryName} category?',
    ConfirmDeleteUserTrainingNote: 'Are you sure you want to delete this training note?',
    ConfirmDeleteDataSource: 'Are you sure you want to delete <b>{sourceName}</b> data source?',
    ConfirmDeleteWidgetGroup: 'Are you sure you want to delete <b>{widgetGroup}</b> widget group?',
    ConfirmDeleteExportConnection: 'Are you sure you want to delete <b>{connectionName}</b> export connection?',
    ChooseColorScreenSaveSuccess: 'Applied this color to buttons',
    ConfirmMoveTrainingTopic:
        'You are about to move the <b>{trainingTopic}</b> topic <br/> <br/> from <b>{trainingSourceArea}</b> <br/> to <b>{trainingDestArea}</b>',
    ConfirmMoveTrainingLevel:
        'You are about to move the <b>{trainingLevel}</b> level <br/> <br/> from <b>{trainingSourceArea}: {trainingSourceTopic}</b> <br/> to <b>{trainingDestArea}: {trainingDestTopic}</b>',
    ConfirmDeleteAccountAttributeType: 'Are you sure you want to delete <b>{accountAttributeType}</b> account attribute type?',
    ConfirmDeleteAllRecipeFromSalesProduct:
        'Direct linking to inventory will expire any detail product recipes for this size. Do you want to proceed?',
    ConfirmDeleteRecipeFromSalesProduct: 'Are you sure you want to remove this recipe from sales product?',
    ConfirmUnblockBlacklistedIpAddress: 'Are you sure you want to unblock this address: <b>{ipAddress}</b>?',
    ConfirmPrepLabelPrint: 'Confirm: print {printCount} stock labels?',
    // ConfirmLogInventory: 'Following sales products will go out of stock, due to inventory levels being logged. Are you sure you want to proceed?',
    ConfirmLogInventory: 'The following sales products will go out of stock using the quantities entered. Proceed?',
    ConfirmDeleteExcludedProduct: 'Are you sure you want to delete {productName} from the exclusion list?',
    CashDrawerAndCreditCardTerminalNotMapped: 'This terminal does not have a cash drawer or credit card terminal linked to it.',
    CreditCardTerminalNotMapped: 'This terminal does not have credit card terminal linked.',
    CashDrawerNotMapped: 'This terminal does not have a cash drawer linked.',
    ConfirmDeleteLayout: 'Deleting this layout will also delete the layout diagram. Do you want to continue?',
    ConnectingToSystem: 'Connecting to system...',
    Connecting: 'Connecting...',
    ConfirmDeleteGiftCardRecharge: 'Are you sure you want to delete this gift card recharge?',
    CacheRefreshSuccess: 'Cache refreshed',
    ConfirmUnchangedInventoryLog: 'You are about to record {productCount} items with the same inventory level as last time. Are you sure?',
    ConfirmCaptureAuthorizeAmount: 'Amount: <b>{currencySymbol}{amount}</b> </br> {tip} <div class="grey mt-10"> Authorized Amount: <b>{currencySymbol}{authorizedAmount}</b></div>',
    ConfirmCaptureAuthorizeAmountWithBalance: 'Amount: <b>{currencySymbol}{amount}</b> </br> {tip} </br> <div class="grey mt-10"> Authorized Amount: <b>{currencySymbol}{authorizedAmount}</b></div> </b> <div class="grey mt-10"> Balance: {currencySymbol}{balance}</br></div>',
    ConfirmDeleteWidget: 'Are you sure you want to delete this widget?',
    ConfirmDeleteEventConfiguration: 'Are you sure you want to delete <b>{configurationName}</b> action from this subscription?',
    ConfirmDeleteEventSubscription: 'Are you sure you want to delete this subscription?',
    ChooseQtyMove: 'Choose how many to move to {seatName}',
    CancelClosingDrawer: 'Cancel closing the drawer?',
    ConfirmRemovePrice: 'Removing this size will remove associated barcodes also. Are you sure you want to clear the price?',
    ConfirmDeleteReportSubscriptions: 'Are you sure you want to delete subscription(s) of <b>{reportName}</b> report?',
    ConfirmDeleteReportCategory: 'Are you sure you want to delete <b>{categoryName}</b> report category?',
    ConfirmDeleteReportGroup: 'Are you sure you want to delete <b>{reportGroup}</b> report group?',
    ConfirmDeleteReport: 'Are you sure you want to delete <b>{reportName}</b> report?',
    ConfirmDeleteReportRule: 'Are you sure you want to delete this report rule?',
    ConfirmDeleteReportSetting: 'Are you sure you want to delete this report setting?',
    ConfirmDeleteDashboardDataSource: 'Are you sure you want to delete <b>{dashboardDataSource}</b> data source?',
    ConfirmDeleteDashboardDataSourceParameter: 'Are you sure you want to delete this data source parameter?',
    ConfirmDeleteMakeTableFilter: 'Are you sure you want to delete filter?',
    ConfirmDeleteWidgetProductGroup: 'Are you sure you want to delete {groupName} group?',
    ConfirmDeleteWidgetComboItem: 'Are you sure you want to delete combo?',
    ConfirmDeleteWidgetComboProduct: 'Are you sure you want to delete product?',
    ConfigureAuthForLights: 'Please configure Nanoleaf authentication first.',
    ConfigureAuthForLightsFailed: 'Authentication Failed. Please verify the Base URL and connectivity and try again.',
    ConfirmSwapRecipe: 'This product swap will affect all <b>{productName}</b> sizes. Continue?',
    ConfirmVoidTransaction: 'Are you sure you want to void this transaction?',
    ConfirmationScreenWorkflowSaveSuccess: 'Confirmation screen configuration updated',
    CameraAnnotationSaveSuccess: 'Camera annotation saved',
    CameraAnnotationStatusUpdated: 'Camera annotation status saved',
    CopiedDataSourceResponseError: 'Error while copy response, Please try again.',
    ConfirmDeleteMappedOffsiteProducts: 'Deactivating {offsiteLocation} will remove all the products located at {offsiteLocation}. Are you sure you want to proceed?',
    ConfirmTransferInventory: 'You are about to transfer <b>{qty} {unit}</b> of <b>{product}</b> from <b>{fromLocation}</b> to <b>{toLocation}</b>. Proceed?',
    ConfirmDeleteGroupScreenMap: 'Are you sure you want to delete multiple product group mapping?',
    ConfirmDeleteMultipleProductGroupMap: 'Are you sure you want to delete <b>{name}</b> multiple product group?',
    ConfirmDeleteWaitListEntry: 'Remove {phoneNumber}?',
    // G //
    GiftCardBalanceAddedSuccess: '{currencySymbol}{balance} Balance added',
    GetAudioFilesResponseError: 'Error while retrieving audio file(s). Please try again.',
    GetNanoLeafLightInfoError: 'Error while reading light information. Please try again.',
    // D //
    DateNotSelected: 'Please select a date',
    DoNotInstruction:
        'This product is configured to not use an instruction terminal. Please enable this setting for proper instruction flow.',
    DrawerIsClosed: 'Drawer is closed. Please load the drawer.',
    DrawerNotMapped: 'A cash drawer is not mapped with this terminal.',
    DJSchemeDeleted: 'DJ scheme deleted',
    DJSchemeSaveSuccess: 'DJ scheme saved',
    DeviceMappingDeleted: 'Terminal device mapping configuration deleted',
    DeviceMappingSaveSuccess: 'Terminal device mapping configuration updated',
    DataSourceParameterSaveSuccess: 'Data source parameters saved',
    DataSourceParameterConfirmDelete: 'Are you sure you want to delete the <b>{paramName}</b> parameter?',
    DataSourceParameterDelete: 'Data source parameter deleted',
    DataSourceQueryDuplicateName: 'Name must be unique.',
    DataSourceParameterDuplicateName: 'Parameter must be unique.',
    DataSourceParameterDuplicateLabel: 'Field name must be unique.',
    DataSourceSubsciptionExported: 'Data source subscription exported successfully.',
    DatabaseSaveSuccess: 'Database configuration updated',
    DeliverySaveSuccess: 'Delivery configuration updated',
    DashboardSaveSuccess: 'Dashboard saved',
    DashboardConfigSaveSuccess: 'Dashboard configuration saved',
    DuplicateDeviceMapping: 'A {DeviceType} is already mapped with this terminal.',
    DuplicateProductError: 'Product already exists',
    DuplicateDefaultButtonError: 'Button already exists',
    DuplicateDashboardError: 'Dashboard already exists',
    DuplicateSalesTaxError: 'Sales tax name should be unique',
    DriverAccountSettleSuccess: 'Driver account settled',
    DriverUnavailable: 'Assign bank and mark driver as available for delivery',
    DriverBankAmountSaveSuccess: 'Bank amount saved',
    DriverAvailable: 'Cash-in and mark the driver unavailable for delivery',
    ReplicateButtonCreated: 'Button replicated. Refreshing Menu...',
    DeleteOptionButton: 'Button removed',
    DeleteHomeScreen: 'You cannot delete a screen marked as Home.',
    DaypartsSaveSuccess: 'Dayparts saved',
    DaypartConfirmDelete: 'Are you sure you want to delete the <b>{daypartName}</b> daypart?',
    DaypartDeleteSuccess: 'Daypart deleted',
    DataSourceSaveSuccess: 'Data source saved',
    DataSourceDeleted: 'Data source deleted',
    DataSourceSubscriptionDeleted: 'Subscription deleted',
    DataSourceSubscriptionConfirmDelete: 'Are you sure you want to delete this subscription?',
    DataSubscriptionSaveSuccess: 'Subscription saved',
    DataSourceEmptyParameter: 'No parameters specified',
    DashboardDataSourceSaveSuccess: 'Dashboard data source saved',
    DashboardDataSourceDeleted: 'Dashboard data source deleted',
    DashboardDataSourceParameterDeleted: 'Data source parameter deleted',
    DigitalMenuSaveSuccess: 'Digital menu saved',
    DigitalMenuWidgetConfigSaveSuccess: 'Menu widget configuration saved',
    DigitalMenuConfirmDelete: 'Are you sure you want to delete this menu?',
    DigitalMenuDeleted: 'Digital menu deleted',
    // E //
    EmailTemplateSaveSuccess: 'Email template saved',
    EnterButtonText: 'Enter',
    EndOfDayUnservedOrders: '<p class="text-danger">There are unserved orders. Please serve all orders and settle the drawer.</p>',
    EndOfDayCloseDrawers: '<p class="text-danger">Following drawers are not settled yet. Please settle all drawers to mark end of day: <ul>{drawers}</ul></p>',
    EndOfDayScheduledDailyClose: 'There are still {hours} hours until scheduled daily close.',
    EndOfDayTipsApplied: 'Please make sure that credit card tips are applied.',
    EndOfDayOperations: 'The following operations will be performed upon end of day: <br/><ul><li>Capture credit card transactions.</li><li>Adjust inventory for daily consumption.</li><li>Reset order number sequence.</li><li>Delete all training orders.</li></ul>',
    EndOfDayTriggerSuccess: 'End of day triggered',
    ErrorCashDrawerConnection: 'Unable to communicate with the cash drawer.',
    Error: 'Error',
    ErrorWhileSameExpenseAvailable: 'This expense already exists.',
    ErrorWhileRetrievingData: 'Error occurred while retrieving data.',
    ErrorWhileRetrievingReceiptSample: 'An error occurred rendering a printout sample',
    EventConfigurationSaved: 'Event configuration saved',
    ErrorWhileAddingMaketableThresholdColorForSameSecond:
        'Color already exists for this interval.',
    ErrorWhileRetrievingObject: 'Error occurred while retrieving {objectType}.',
    ErrorWhileScreenSaved:
        'Minimum and maximum must be specified together, or not at all.',
    ErrorScreenSelectionLimit: 'Min/Max selection set max 99 limit exceed.',
    ErrorScreenPriceAdjustLimit: 'Price Adjust set max 9999 limit exceed.',
    ErrorWhileMinTimeIsGreater:
        'The start time for this button must precede its end time.',
    ErrorWhileFromDateGreater: 'From date must precede the To Date',
    ErrorWhileStartTimeIsGreaterEndTime:
        'The start time must precede the end time.',
    ErrorWhileStartTimeIsLaterThanEndTime:
        'The start time must precede the end time.',
    ErrorWhileStartDateGreater:
        'Start date & time must match or precede the end date & time.',
    ErrorWhileInvalidEndDateForPromotion: 'End date should not precede the start date.',
    ErrorWhileClockInDateGreater: 'The Clock-in time must be earlier than the Clock-out time.',
    ErrorWhileUserNotAccessTerminal: 'You are not permitted to sign into this terminal.',
    ErrorWhileUserNotAccessBackOfficeTerminal: 'You are not permitted to sign into Back Office terminal.',
    ErrorWhileUserNotHavePermission: 'You are not permitted to access the page requested.',
    ErrorWhileUserNotHaveAnyTerminalAccessPermission: 'You do not have permission to sign in to any terminal.',
    ErrorWhileOnlineOrderingAddingButton: 'Can\'t add this button due to toggle configuration.',
    ErrorWhileOnlineOrderingAddingDuplicateButton: 'This button already exists.',
    ErrorWhileOnlineOrderingAddingDuplicateMenuButtonOption: 'This option already exists.',
    ErrorWhilePickupTimeNotBetweenDailyScheduleTime: 'The selected Pickup Time should be between the start time and end time of the daily schedule.',
    ErrorWhileClockOutUserWithUnsettledOrders: 'There are unsettled orders. Please settle all orders before clocking out.',
    ErrorWhileScheduleEndTimeIsLessThanStartTime: 'The selected end time should be greater then start time in daily schedule.',
    ErrorWhileTaxTypeNotSelected: 'Please select tax type.',
    ErrorWhileBarcodeSequenceSameAsRFID: 'Barcode sequence cannot be same as RFID sequence.',
    ErrorWhileRFIDSequenceSameAsBarcode: 'RFID sequence cannot be same as Barcode sequence.',
    EquipmentTypeSaveSuccess: 'Equipment type saved',
    EquipmentTypeDeleted: 'Equipment type deleted',
    EquipmentPropertiesSaveSuccess: 'Equipment properties saved',
    EquipmentPropertiesDeleted: 'Equipment properties deleted',
    EquipmentSaveSuccess: 'Equipment saved',
    EquipmentDeleted: 'Equipment deleted',
    EndOfDaySaveSuccess: 'End of day configuration updated',
    ErrorWhileOrderNotLoaded: 'Please load an order first.',
    ErrorWhileUnderpayment: 'Underpayment is not permitted.',
    ErrorWhileAddingProductPrice: 'The {size} size is already configured.',
    ErrorWhileAddingSalesProduct: 'Please enter price for {size} size.',
    ErrorWhileDrawerPriceEmpty: 'Please enter a number.',
    ErrorTimeEntry: 'Please enter a time in the future.',
    ErrorWhilePlayAudio:  'Error occurred while play audio',
    ErrorWhenAudioFileNotFound:  'Audio file not found',
    ExportScriptDeleted: 'Export script deleted',
    ExportScriptSaveSuccess: 'Export script saved',
    ExternalApiDeleted: 'External API deleted',
    ExternalApiSaveSuccess: 'External API saved',
    ExternalApiUpdatedSuccess: 'External API updated',
    ExternalApiCallSuccess: 'External API invoked',
    ExternalLoginsSaveSuccess: 'External login settings saved',
    ErrorWhileDefaultTaxNotSelected: 'Please select a default sales tax category.',
    EventActionSaveSuccess: 'Event action saved',
    EventActionDeleted: 'Event action deleted',
    EventSubscriptionDeleted: 'Event Subscription deleted.',
    ExportConnectionSaveSuccess: 'Export connection saved',
    ExportConnectionDeleted: 'Export connection deleted',
    ErrorWhileVoidTransaction: 'Error occurred while voiding the transaction: {errorMessage}',
    ExceedMaxAllowedTime: 'Maximum allowed time for scheduling order exceeded',
    EmbeddedScreenSaveSuccess: 'Embed screen saved',
    EmbeddedScreenDeleted: 'Embed screen deleted',
    EmbeddedScreenDeleteConfirm: 'Are you sure you want to delete this <b>{embeddedScreen}</b> embed screen?',
    // F //
    FormatParameterSaveSuccess: 'Format configuration saved',
    FeatureConfigurationSaveSuccess: 'Feature configuration saved',
    // G //
    GiftCardBalanceZero: 'Gift card balance is zero.',
    GiftCardNotFound: 'Gift card {giftCardNumber} not found.',
    GiftCardBalance: 'Balance remaining: {currencySymbol}{balance}',
    GeneralWorkflowSaveSuccess: 'General configuration updated',
    GiftCardWorkflowSaveSuccess: 'Gift Card configuration updated',
    // H //
    HardwareStatusUpdated: 'Hardware device status updated',
    HardwareDeviceDeleted: 'Hardware device deleted',
    HardwareSaveSuccess: 'Hardware saved',
    HICSaveSuccess: 'HIC configuration updated',
    HICDeleted: 'HIC deleted',

    // I //
    InactiveProductMessage: 'This product has been deactivated.',
    ImageUploadExpectedError: 'Couldn\'t upload this image due to unexpected error.',
    InactiveButtonDeleted: 'Inactive button deleted',
    InvalidReceiptLogoFileType: 'Only .jpg, .bmp and .png files are supported.',
    InvalidInventoryProductImageType: 'Only .jpg, .bmp, .gif, .webp and .png files are supported.',
    InvalidSalesProductImageFileType: 'Only .jpg, .bmp and .png files are supported.',
    InvalidOnlineOrderFaviconFileType: 'Only .ico files are supported.',
    InvalidAudioNotificationFileType: 'Only .wav or .mp3 files are supported.',
    InvalidDomainVerificationFileType: 'Only .txt file is supported',
    InvalidMerchantIdentityCertificateFileType: 'Only .pfx or .p12 file is supported',
    InvalidQty: 'Please enter a quantity greater than zero.',
    InvalidRecipeQty: 'Recipe quantity should be numeric.',
    InvalidLogoFileType: 'Only png files are supported.',
    InvalidRequest: 'Invalid request.',
    InvalidAmountInGiftCard:
        'Please enter the amount to add to the gift card, then press Credit Card.',
    InvalidAmountInGiftCardCC: 'Negative amounts are not allowed for a credit card.',
    InventoryReceivedDeleted: 'Inventory receive record deleted',
    InventoryCategoryDeleted: 'Inventory category deleted',
    InventoryCategorySaveSuccess: 'Inventory category saved',
    InventorySubCategoryDeleted: 'Inventory subcategory deleted',
    InventorySubCategorySaveSuccess: 'Inventory subcategory saved',
    InventoryProductSaveSuccess: 'Inventory product saved',
    InventoryProductsUpdateSuccess: 'Inventory products updated',
    InventoryProductDeleted: 'Inventory product deleted',
    InventoryProductBarcodeSaveSuccess: 'Barcode saved',
    InventoryProductBarcodeEditSuccess: 'Barcode edited',
    InventoryProductBarcodeDeleted: 'Barcode deleted',
    InventoryProductRecipeDeleted: 'Recipe deleted',
    InventoryProductBarcodeAlreadyExist: 'Barcode already exists.',
    InventoryZoneDeleted: 'Inventory zone deleted',
    InventoryBinDeleted: 'Inventory bin deleted',
    InventoryZoneSaveSuccess: 'Inventory zone saved',
    InventoryVendorProfileDeleted: 'Inventory vendor profile deleted',
    InventoryVendorProfileSaveSuccess: 'Inventory vendor profile saved',
    InventoryVendorProfileApplied: 'Inventory vendor profile applied',
    InventoryVendorProfileProductDeleted: 'Inventory vendor profile product deleted',
    InventoryVendorProfileProductSaveSuccess: 'Inventory vendor profile product saved',
    InfoLinkSectionSaveSuccess: 'Info link section saved',
    InfoLinkSectionDeleted: 'Info link section deleted',
    InfoLinkDeleted: 'Info link deleted',
    InfoLinkSaveSuccess: 'Info link saved',
    InfractionSaveSuccess: 'Infraction saved',
    InfractionDeleted: 'Infraction section deleted',
    IconsSaveSuccess: 'Icons configuration updated',
    InventoryLogSuccess: '{productName} Logged',
    InventoryBinLightSaveSuccess: 'Light saved',
    InventoryBinLightDeleted: 'Light deleted',
    InventoryBinLightAlreadyExist: 'Light already exists.',
    InvalidContexPrice1: 'Context price1 is not valid.',
    InvalidContexPrice2: 'Context price2 is not valid.',
    InvalidContexPrice3: 'Context price3 is not valid.',
    InvalidContexPrice4: 'Context price4 is not valid.',
    InvalidContexPrice5: 'Context price5 is not valid.',
    InvalidContexPrice6: 'Context price6 is not valid.',
    InvalidContexPrice7: 'Context price7 is not valid.',
    InvalidContexPrice8: 'Context price8 is not valid.',
    InvalidContexPrice9: 'Context price9 is not valid.',
    InvalidInventoryValue: 'Inventory Return Value is not valid',
    InvalidDocumentInventoryText: 'Decrement Inventory text is not valid',
    InvalidMinimumValue: 'Minimum value is invalid',
    InvalidMaximumValue: 'Maximum value is invalid',
    InvalidNotifyConfiguration: 'Please select at least one notification method',
    InfoMakeTableCookingState:
        'Currently, the \'cooking state\' is not enabled for Make Tables.  This option will not be utilized.',
    // tslint:disable-next-line:max-line-length
    InfoMakeTableRetrievingIngredientsState:
        'Currently, the \'retrieving ingredients\' state is not enabled for Make Tables.  This option will not be utilized.',
    InfoMakeTableCraftingState:
        'Currently, the \'crafting\' state is not enabled for Make Tables.  This option will not be utilized.',
    InfoMakeTableReadyState:
        'Currently, the \'ready\' state is not enabled for Make Tables.  This option will not be utilized.',
    InventoryProductLossSaveSuccess: 'Inventory loss recorded',
    InstructionsAreaAlreadyExists:
        'An instructions area already exists in {productName} with this name',
    InstructionsExcludedSaveSuccess:
        // 'Sales product instructions excluded saved', 
        'Inventory product excluded',
    InstructionsExcludedDeleteSuccess:
        'Removed {productName} from the exclusion list.',
    ImportRecipeSuccess: 'Recipe(s) imported',
    InventoryProductAliasSaved: 'Inventory product alias saved',
    InventoryProductAliasDeleted: 'Inventory product alias removed',
    InventoryProductIngredientDeleted: 'Inventory product ingredient(s) deleted',
    InventoryProductDietaryWarningDeleted: 'Inventory product dietary warning deleted',
    InventoryProductNutritionDeleted: 'Inventory product nutrition deleted',
    InventoryProductMeasuringInstructionsWarning: 'Long text may be truncated in label print.',
    InventoryMarkAsOrderedSuccess: '{productName} marked as ordered',
    InventoryRawInfoCopiedSuccess: 'Product ingredients configuration copied from {sourceProductName} to {destinationProductName}',
    IPUnblockSuccess: 'IP address unblocked',
    InsufficientGiftCardBalance: 'Insufficient balance. Current balance: {currencySymbol}{balance}. Do you want to pay {currencySymbol}{balance}?',
    InvalidParentFound: 'Invalid parent ingredient found',
    InvalidParentOrChildIngredient: '{childIngredient} is child of {parentIngredient}, and can not be set as a parent.',
    InvalidIngredientMove: 'Child ingredients must be grouped within their parent ingredients',
    InvalidMaxRefundQty: 'The refund quantity can\'t exceed the available quantity ({qty}).',
    InventoryProductDeleteConfirm: 'Are you sure you want to delete this archived record of inventory product?',
    InventoryProductHistoryDeleteConfirm: 'Are you sure you want to delete this inventory product history?',
    InventoryProductHistoryDeleted: 'Inventory product history deleted',
    InventoryProductHistorySavedSuccess: 'Product history saved',
    InvalidJsonFormat: 'Invalid json format.',
    InvalidScheduleOrderTime: 'Schedule time is invalid, schedule time should be between {startTime} and {endTime}.',
    InventoryConfigurationSaveSuccess: 'Inventory configuration updated',
    // J//
    JobCodeDeleted: 'Job code section deleted',
    JobCodeSaveSuccess: 'Job code saved',

    // K //
    KioskScreenDeleteConfirm: 'Are you sure you want to delete this screen?',
    KioskScreenDeleteSuccess: 'Kiosk screen deleted',
    KioskScreenSaveSuccess: 'Kiosk screen saved',
    KioskScreenConfigSaveSuccess: 'Kiosk screen configuration saved',
    KioskChoiceDeleteConfirm: 'Are you sure you want to delete this button?',
    KioskChoiceDeleteSuccess: 'Kiosk button deleted',
    KioskChoiceSaveSuccess: 'Kiosk button saved',
    KioskScreenChoiceSaveSuccess: 'Kiosk screen button saved',
    KioskScreenChoiceDeleted: 'Screen button deleted',
    KioskScreenChoiceRemoved: 'Screen button removed.',
    KioskScreenConfigChangeWarning: 'Screen buttons might be affected or removed if {type} is changed. Are you sure to continue?',
    KioskCancelOrderConfigSave: 'Kiosk cancel order configuration saved',
    KioskIdleTimeoutConfigSave: 'Kiosk idle timeout configuration saved',
    KioskOrderConfirmConfigSave: 'Kiosk order confirm configuration saved',
    KioskAIShakeConfigSave: 'Kiosk AI shake configuration saved',
    KioskPaymentConfigSave: 'Kiosk payment configuration saved',
    KioskCheckoutConfigSave: 'Kiosk checkout configuration saved',
    KioskGeneralConfigSave: 'Kiosk General configuration saved',

    // L//
    LoginMessageDeleted: 'Login message deleted',
    LoginMessageSaveSuccess: 'Login message saved',
    LicenseSaveSuccess: 'License configuration updated.',
    LicenseLimitError: 'License limit reached. Please contact support to purchase additional licenses.',
    LayoutDesignSaveSuccess: 'Layout saved',
    LayoutDesignDeleteSuccess: 'Layout deleted',
    LayoutSaveSuccess: 'Layout configuration updated.',
    LoadDestinationMessage:
        'Recipe copied. Do you want to open the destination product?',
    LoadDrawerForGiftCardRecharge: 'Please load the drawer to accept cash for gift cards.',
    LoginCodeMaxLengthError: 'Login code should not be longer than 8 digits',
    LoginCodeMinLengthError: 'Login code must utilize at least 4 digits',
    LabelPrintRequestSendSuccess: 'Label print request sent',
    LabelPrintRequestValidate: 'Max Portions and Measuring Instructions are required for label printing.',
    LabelPrinterNotConfigured: 'A label printer is not configured.',
    LoyaltyCardNumberNotFound: 'Couldn\'t find an account with card ending "{LoyaltyCardNumber}".',
    LayoutDesignerRequireAccountName: 'Each {accountTerm} requires a name.',
    LogoutRequestSent: 'Please wait.... logout request issued.',
    // M//
    MaximumSelectionGroupButton: 'Maximum selections within {groupName} is {buttonGroupMax}',
    MinimumSelectionGroupButton: 'Please make a selection in the {groupName} group.',
    MinimumSelectionGroupButtons: 'You must select at least {buttonGroupMin} items in the {groupName} group.',
    MinimumSelectionGroupScreen: 'You must make at least {minSelections} selection(s)',
    MaximumSelectionGroupScreen: 'You cannot make more than {maxSelections} selection(s)',
    MenuButtonConfigurationDeleted: 'Mapped option deleted',
    MenuButtonConfigurationAdded: 'Option added',
    MenuButtonDeleted: 'Product deleted',
    MenuSectionDeleted: 'Section deleted',
    Message: 'Message',
    MediaAddedToSlideshow: 'Media added to slideshow.',
    MinSelectionsMustLessThanMaxSelections: 'Min selections must be less than max selections.',
    MinLevelMustLessThanMaxLevel: 'Min level must be less than max level.',
    MergeInventoryProduct:
        '{oldProductName} has been merged into {masterProductName}',
    MergeSalesProduct:
        '{oldProductName} has been merged into {masterProductName}',
    MarkUndoneTask: 'Are you sure you want mark this as not completed?',
    MarkupDiscountRestrict: 'The order has {priceModifier} applied to it. An order cannot utilize both markup and discount.',
    MenuDisplaySettingDeleted: 'Menu display setting deleted',
    MenuDisplayReload: 'Menu display refresh triggered.',
    MaketableConfigrationSaveSuccess: 'Make table configuration updated',
    MacroScriptSaveSuccess: 'Macro script saved',
    MacroScriptDeleted: 'Macro script deleted',
    MacroScriptDeleteConfirm: 'Are you sure you want to delete <b>{macroScript}</b> script?',
    MarketingPromotionSaveSuccess: 'Promotion saved',
    MarketingPromotionDeleted: 'Promotion deleted',
    MacroScriptNameChangeWarning: 'Changing name of a macro could break scripts using the macro. Make sure to update scripts to use updated name.',
    MultipleProductGroupMappingDeleted: 'Multiple product group mapping deleted',
    MultipleProductGroupMappingUpdated: 'Multiple product group mapping saved.',
    MultipleProductGroupMappingExist: 'Multiple product group mapping already exist',
    MultiProductGroupSaved: 'Multiple product group saved.',
    MultiProductGroupDeleted: 'Multiple product group deleted.',
    MenuConfigurationSaved: 'Menu configuration saved',
    // N //
    NextInventoryDueDateOverrideEditSuccess:
        'Next Inventory Log Date updated',
    NoInventoryBinMapped: 'No bin is mapped with {inventoryProduct}',
    NonTaxable: 'Non Taxable',
    NoMakeTableMappedWithOrderEntry:
        'This terminal <b>({terminalName})</b> cannot accept orders without a mapped Make Table.',
    NotImplemented: 'Not Implemented',
    NoLayoutMappedWithOrderEntry: 'No layout is mapped with this terminal',
    NumpadDecrementValidationError: 'Value cannot be reduced.',
    noInventoryRecord: 'No inventory is recorded {message}',
    NotificationSaveSuccess: 'Notification configuration updated',
    // O //
    ObjectSaved: '{objectType} saved',
    OnlineOrderingCategorySaveSuccess: 'Category saved',
    OnlineOrderingSettingsSaveSuccess: 'Settings saved',
    OnlineOrderingButtonAddedSuccess: 'Button added',
    OpenDeliveries:
        'This driver has open deliveries that must be closed out first. Go to the Dispatch module to continue.',
    OrderAlreadyPaid: 'This order is already paid. Mark as served?',
    OrderPaidFull :"The order is paid in full.",
    OrderAlreadyOpen: 'Order {surrogateOrderId} is currently open on the {terminalName} terminal.',
    OrderMaxAllowedDiscount: 'The total discount on this order ({totalDiscount}%) exceeds the maximum allowed discount ({maxDiscount}%).',
    OutOfStockProductMessage: 'Inventory indicates that this product is out of stock.',
    OutOfStockProductMessageForMultipleSizes: 'Inventory indicates that this product is out of stock for the size selected.',
    OrderRefundSuccess: 'Order refunded',
    OrderMergeSuccess: 'Order merged',
    OrderMethodSaved: 'Order method saved',
    OrderClosurePrintAction: 'This order has items that have not been printed to the kitchen. Print now?',
    OnlineOrderingButtonSaveSuccess: 'Button saved',
    OnlineOrderingConfigurationSaveSuccess: 'Configuration saved',
    OnlineOrderingOptionDeleteSuccess: 'Option {optionName} deleted',
    OnlineOrderingCategoryDeleteSuccess: 'Category {categoryName} deleted',
    OnlineOrderingImageResolutionWarning: 'The selected image is low resolution, and may look grainy on high resolution screens. Recommended resolution is ({resolution})',
    OnlineOrderingMenuSyncSuccess: 'Online ordering menu synced',
    OrderQuantityDecreaseValidationError: 'Ordered quantity cannot be reduced after the order has been placed.',
    OrderItemMoveDecreaseValidationError: 'Please select a valid quantity to move',
    OrderItemQuantityDecrementValidationErrorAfterPayment: 'Please enter a quantity of {qty}​​​​​​​​​​​​​​​{productUnit}​​​​​​​​​​​​​​​(s) or more​​​​​​​​​​​​​​​.',
    OrderManagementOrdersAppearMessage: 'New orders may take 1-2 minutes to appear here.',
    OrderNumberMessage: 'Your order number is {surrogateOrderId}',
    OrderUpdateInSettleMessage: 'There have been modifications made to the order. The order and amounts have been reloaded.',
    OnlineOrderScheduleOverrideSaveSuccess: 'Online order schedule saved',
    OnlineOrderAnnouncementScheduleSaveSuccess: 'Online order announcement schedule saved',
    OnlineOrderScheduleOverrideConfirmDelete: 'Are you sure you want to delete this schedule?',
    OnlineOrderAnnouncementScheduleConfirmDelete: 'Are you sure you want to delete this schedule?',
    OnlineOrderScheduleOverrideDeleteSuccess: 'Online order schedule override deleted',
    OnlineOrderAnnouncementScheduleDeleteSuccess: 'Online order announcement schedule deleted',
    OrderRefundNotAllowed: 'Order is already refunded.',
    OrderProductWorkflowUpdatedSaveSuccess: 'Status updated',
    OrderWorkflowSaveSuccess: 'Order workflow configuration updated',
    OrderHasScheduledProductsMessage: 'The order cannot be served at this time. There are scheduled item(s) in the order.',
    OrderIsScheduled: 'This order is scheduled, cannot be served at this time.',
    OrderOutsideOperatingHours: 'The order can not be scheduled outside operating hours ({startTime} - {endTime}).',
    OrderScheduleFarInAdvance: 'Order can not be scheduled farther than {maxHours} hours in advance.',
    ScheduleOnClosedDay: 'Cannot schedule order as we are closed on {date}.',
    OffSiteLocationSaveSuccess: 'Off-site location saved',
    OffSiteProductSettingSaveSuccess: 'Off-site product settings saved',
    OffSiteProductsCleaned: 'Off-site products cleaned',
    OffSiteProductsConfirmCleanup: 'Are you sure you want to cleanup products?',
    OffSiteLocationStatusChange: 'Off-site location {activeMessage}',
    OrderFulfillmentValidation: 'A product cannot use multiple workflows of same delivery method.',
    // P //
    PagerNumberAssignedSuccess: 'Pager assigned',
    PagerNumberRemovedSuccess: 'Pager removed',
    PasswordIncorrect: 'Password incorrect, please try again.',
    PasswordBlankError: 'Password may not be blank',
    PasswordNotMatch: 'New password and confirmation password must match.',
    PlaylistDeleted: 'Playlist deleted',
    PlaylistTrackDeleted: 'Track removed from the playlist',
    PlaylistSaveSuccess: 'Playlist saved',
    PriceNotAvailable: 'Price not available for this product.',
    PrinterNotFound: 'Printer not Found',
    ProblemWhilePrinting: 'An error occurred while printing',
    ProblemWhileConnectingCashDrawer:
        'An error occurred while connecting to the cash drawer',
    ProductRecevingUpdatedSuccess: 'Product receiving record(s) updated',
    PrintingSaveSuccess: 'Printing configuration updated.',
    PayRateSaveSuccess: 'Pay rate added',
    PayRateUpdateSuccess: 'Pay rate updated',
    PayRateDeleteSuccess: 'Pay rate deleted',
    PaymentsSaveSuccess: 'Payments configuration updated.',
    PrepareQtySaveSuccess: 'Prep recorded',
    PriceSaveSuccess: 'Price saved',
    PriceDeleteSuccess: 'Price removed',
    PrepRecordAdded: 'Inventory prep record added',
    PrepRecipeAdded: 'Recipe saved',
    PrintRequestSendSuccess: 'Print request sent',
    ProductLossReasonSave: 'Product loss reason saved',
    ProductLossReasonUpdate: 'Product loss reason updated',
    ProductLossReasonDelete: 'Product loss reason deleted',
    PromotionSaveSuccess: 'Promotion saved',
    PromotionActiveStatusChange: 'Promotion {activeMessage}',
    ProductPrintStatus: 'Label Printing is {currentStatus}. Click to turn {nextStatus}.',
    PaymentApproved: 'Payment approved',
    PaymentRejected: 'Payment rejected.',
    ProductAlreadyRefunded: 'Item already refunded',
    PressApplyButtonToProceed: 'Please press Apply to proceed with this tab.',
    PaymentRecording: 'Payment approved. Applying payment to order, please wait...',
    PanelsUnavailableMessage: 'No light(s) configured',
    ProductsMarkedAsOrdered: 'Inventory products marked as ordered.',
    PrepQtyRequired: 'Please enter prep qty.',
    PickScreenConfigurationSaveSuccess: 'Pick screen configuration updated',
    PermissionTypeSaveSuccess: 'Permission saved',
    PermissionDelete: 'Permission deleted',
    PermissionDeleteConfirm: 'Are you sure you want to delete <b>{name}</b> permission?',
    PrintBarcodeRequestSent: 'Print barcode request sent.',

    // Q //
    QuickBookConfigurationSaveSuccess: 'QuickBooks configuration updated',

    // R //
    RecipesSavedSucess: 'Recipe(s) updated',
    SalesProductRecipeSaveSuccess: 'Recipe saved',
    RequestProcessingError: 'Error occurred while processing the request',
    ReceivingInstructionsPrinted: 'Receiving instruction(s) have been printed for items received.',
    ReceivingInstructionsAndLabelPrinted:
        'Receiving instruction(s) and label(s) were printed for items received. Please apply labels to the packaging of these items.',
    ReceivingLabelPrinted: 'Label(s) were printed for these items received. Please apply labels to the packaging of these items.',
    RabbitMQStompConfigDeleted: 'RabbitMQ STOMP configuration deleted',
    RabbitMQStompConfigSaveSuccess:
        'RabbitMQ Configuration updated.',
    ReceiptPrinterNotMapped: 'There is no receipt printer mapped to this terminal.',
    RequiredButtonType: 'Please select a button type.',
    RequiredInputProduct: 'Please select a product.',
    RequiredMakeTable: 'Please select a Make Table',
    RequiredDashboard: 'Please select a dashboard',
    RequiredSalesProduct: 'Please select a sales product',
    RequiredInventoryProduct: 'Please select an inventory product.',
    RequiredProductLossReason: 'Please select a loss reason.',
    RequiredTableLayout: 'Please select a table layout',
    ReceivingProductSaveSuccess: 'Inventory receipt recorded',
    RequiredCashDrawerMapped: 'To refund a cash drawer, a cash drawer must be mapped to this terminal (<b>{terminalName}</b>).',
    RequiredCreditCardTerminalMapped: 'To refund a credit card order, a credit card terminal must be mapped to this terminal (<b>{terminalName}</b>).',
    RequiredCreditCardTerminalMappedToAddBalance: 'To add balance, a credit card terminal must be mapped to this terminal (<b>{terminalName}</b>)',
    RequiredExternalApi: 'Please select an external API.',
    RequestSendSuccess: 'Request sent',
    RevenueCenterSaveSuccess: 'Revenue center saved',
    RevenueCenterStatusChange: 'Revenue center {activeMessage}',

    ReportParameterInvalidInput: '{parameterName} must be numeric.',
    ReportParameterRequired: '{parameterName} is required.',
    ReportParameterInvalidRange: '{parameterName} value between 0 to 100',
    RequiredInputUser: 'Please select a User.',
    RequiredInputTerminal: 'Please select a terminal.',
    RawIngredientsSave: 'Raw ingredient saved',
    RawIngredientsUpdate: 'Raw ingredient updated',
    RawIngredientsDelete: 'Raw ingredient deleted',
    RecipeDragDisable: 'Recipes cannot be sorted when "Show Previous Inventory Values" checkbox is checked',
    RecipeAreaMissing: 'Please add at least one instruction area.',
    RequiredProductAndQtyForFixedPrepRecipe: 'Please select a product and enter a quantity to print the recipe.',
    RequiredProductAndQtyForVariableRecipe: 'Please select a product and enter quantity of ingredient(s) to print the recipe.',
    ReceiptPrintSaveSuccess: 'Your changes have been saved.',
    ReceiptPrintHaveNoChanges: 'No changes have been made.',
    ReceiptPrintDeleteSuccess: 'Receipt script deleted',
    RefundSuccess: 'Refund completed',
    RefundPaymentCanceled: 'Payment cancelled',
    ReportCategorySaveSuccess: 'Report category saved',
    ReportCategoryDeleted: 'Report category deleted',
    ReportGroupSaveSuccess: 'Report group saved',
    ReportSaveSuccess: 'Report saved',
    ReportGroupDeleted: 'Report group deleted',
    ReportDeleted: 'Report deleted',
    ReportRuleDeleted: 'Report rule deleted',
    ReportSettingDeleted: 'Report setting deleted',
    ReportParameterError: 'Enough parameters are not available',
    ReportTwoParameterError: 'Report should have at least two parameter to define rules.',
    ReadLights: 'Please read lights',
    RefundRejected: 'Refund request rejected.',
    RefundDisableDueToUnserved: 'Order must be marked as complete/served before refunds can be issued',
    // S //
    SelectAtLeastOne: 'Select at least one track to save',
    SelectAtLeastOneFile: 'Select at least one file to save',
    SelectAtLeastOneProduct: 'Select at least one product to mark as ordered.',
    ScheduledOrder: 'Scheduled Order',
    ScheduledOrderNoProducts:
        'There are no pending products on this order to delay.',
    ScheduledOrderInstructions: 'Select a time to activate this order.',
    Success: 'Success',
    SwipeCardRequest: 'Please present card.',
    SalesProductMessage:
        'Please save the product details before entering recipe details.',
    SalesInventoryProductDeactivate: 'The following inventory products are utilized by <b>{salesProduct}</b>. Please check any product that you want to deactivate now.',
    SalesInventoryProductActivate: 'The following inventory products are utilized by <b>{salesProduct}</b> and are currently deactivated. Please check any that you would like to activate now.',
    SlideshowDeleted: 'Slideshow deleted',
    SlideshowSaveSuccess: 'Slideshow saved',
    SlideshowImageDeletedSuccessMessage: 'Slideshow image deleted',
    SlideshowImageParameterUpdateSuccessMessage:
        'Slide parameter updated',
    SalesGroupDeleted: 'Sales group deleted',
    SalesGroupSaveSuccess: 'Sales group saved',
    SalesCategoryDeleted: 'Sales category deleted',
    SalesCategorySaveSuccess: 'Sales category saved',
    SalesProductDeleted: 'Sales product deleted',
    SalesProductLossSaveSuccess: 'Sales product loss recorded',
    SalesProductSaveSuccess: 'Sales product saved',
    SalesProductUpdateSuccess: 'Sales products updated',
    SalesProductBarcodeSaveSuccess: 'Barcode saved',
    SalesProductBarcodeDeleted: 'Barcode deleted',
    SalesSizesDeleted: 'Sales size deleted',
    SalesSizesSaveSuccess: 'Sales size saved',
    SalesUnitDeleted: 'Sales unit deleted',
    SalesUnitSaveSuccess: 'Sales unit saved',
    TagSaveSuccess: 'Tag saved',
    TagDeleted: 'Tag deleted',
    TagIconRecommendedSize: 'Recommended size is 25x25 px',
    SecurityRoleSaveSuccess: 'Security role saved',
    SecurityRoleDeleted: 'Security role deleted',
    SubAccountDeleted: '{subaccountTerm} deleted',
    SubAccountSaveSuccess: '{subaccountTerm} saved',
    SubscriptionSaveSuccess: 'The subscription saved',
    SubscriptionCopySuccess: 'Subscription copied',
    SubscriptionCopySaveWarning: 'Select at least one subscription',
    ScheduleDeleted: 'Schedule deleted',
    ScreenDeleted: 'Screen deleted',
    ScreenSaveSuccess: 'Screen saved',
    ScreenHistorySaveSuccess: 'Screen history saved',
    SalesProductValidationError:
        'Cannot save sales product. Please find and resolve all validation errors first.',
    SelectAtLeastOneCheckbox:
        'Please select at least one checkbox before printing.',
    SwipeCardAndSelectResult: 'Please present card and indicate the result:',
    SeatSelectionPrevent:
        '{subaccountTerm} wise payment not allowed, as the order is partially paid.',
    SeatPaymentCompleted: 'Paid',
    SalesProductInstructionSaved: 'Sales product instruction saved',
    SalesProductAliasSaved: 'Sales product alias saved',
    SalesProductAliasDeleted: 'Sales product alias removed',
    SalesProductAreaSaved: 'Sales product area saved',
    ScheduleUpdate: 'Schedule updated',
    SalesSizeMappedError: 'There are no sizes mapped with this category.',
    SecurityRoleCopiedSuccess: 'Security role copied',
    PrepLabelPrintRequestSendSuccess: 'Stock label print request sent',
    SalesProductNotExists: 'Sales product doesn\'t exist.',
    ScreenHistoryDeleteConfirm: 'Are you sure you want to delete this screen history?',
    ScreenHistoryDeleted: 'Screen history deleted',
    ScreenHistorySavedSuccess: 'Screen history saved',
    SalesProductHistoryDeleteConfirm: 'Are you sure you want to delete this sales product history?',
    SalesProductPriceHistoryDeleteConfirm: 'Are you sure you want to delete this sales product price history?',
    SalesProductHistoryDeletedSuccess: 'Sales product history deleted',
    SalesProductPriceHistoryDeleted: 'Sales product price history deleted',
    SalesProductHistorySaveSuccess: 'Sales product history saved',
    SalesProductSizeSaveSuccess: 'Sales product size saved',
    ScheduleOrderActiveHourOverrideSaveSuccess: 'Schedule active hour override saved',
    ScheduleOrderActiveHourOverrideDeleted: 'Schedule active hour override deleted',
    StillConnectingCreditCardTerminal: 'Still trying to connect credit card terminal. Please wait...',
    StillConnectingForTip: 'Still trying to connect. Please wait...',
    SingularFeeNotApplicable: 'Singular Fee will not be applied with function type: {functionType}.',
    ServeScreenConfigurationSaveSuccess: 'Serve Screen configuration updated',

    // T //
    TagSavedSuccess: 'Tag saved',
    TagDeletedSuccess: 'Tag deleted',
    TaxableItem: 'Taxable Item',
    TerminalConfigAddSuccess: 'Terminal configuration saved',
    TimedProduct: 'Timed Product',
    TimedProductInstructions: 'Set the number of minutes to delay this product.',
    TimedOrderInstructions: 'Set the number of minutes to delay this order.',
    TrackAdded: 'Track added',
    TrackDeleted: 'Track deleted',
    TrackUpdated: 'Track updated',
    TrackSaveSuccess: 'Track saved',
    TerminalDeleted: 'Terminal configuration deleted',
    TerminalMappedWithAllOrderEntrySuccess: 'All order entry terminals mapped with <b>{terminalName}</b> terminal',
    TimeClockEntryDeleted: 'Time clock entry deleted',
    TimeClockEntrySaveSuccess: 'Time clock entry saved',
    TaxonomySaveSuccess: 'Taxonomy configuration updated',
    TaxSaveSuccess: 'Sales tax configuration updated.',
    TaxDeleteSuccess: 'Sales tax deleted',
    TaskSaveSuccess: 'Task saved',
    TaskUpdateSuccess: 'Task updated',
    TaskDeleteSuccess: 'Task deleted',
    TrainingAreaDeleteSuccess: 'Training area deleted',
    TrainingAreaSavedSuccess: 'Training area saved',
    TrainingTopicDeleteSuccess: 'Training topic deleted',
    TrainingTopicSavedSuccess: 'Training topic saved',
    TrainingBadgeDeleteSuccess: 'Training badge deleted',
    TrainingBadgeSavedSuccess: 'Training badge saved',
    TrainingTopicLevelDeleteSuccess: 'Training topic level deleted',
    TrainingTopicLevelSavedSuccess: 'Training topic level saved',
    TrainingTopicApprovedSuccess: 'Training topic approved',
    TrainingNoteSavedSuccess: 'Training note saved',
    TrainingNoteDeletedSuccess: 'Training note deleted',
    TrainingTopicMoveSuccess: 'Training topic <b>{trainingTopic}</b> moved',
    TrainingLevelMoveSuccess: 'Training level <b>{trainingLevel}</b> moved',
    TransactionDeleteInfo: 'Can\'t delete this transaction.',
    TerminalExternalAuthRequired: 'External authentication required. Please login with password',
    TipAddedSuccess: 'Adding Tip...',
    TipAddFailed: 'Could not add Tip, due to unexpected error.',
    TransferInventorySaveSuccess: 'Inventory transferred.',
    TerminalRefreshRequest: 'Terminal refresh request sent.',
    // U //
    UnexpectedErrorOccurred:
        'An unexpected error occurred while processing the request. We have been notified of this issue.',
    UnlinkedScreenDeleted: 'Unlinked screen deleted',
    UnusedImageDeleted: 'Button image deleted',
    UnusedImageBulkDeleted: 'Button images deleted',
    UnlinkedLoyalAccountTermSuccess: 'Unlinked {loyalAccountTerm}',
    ConfirmUnlinkedLoyalAccount: 'Are you sure you want to unlink this {loyalAccountTerm}?',
    UserDeleted: 'User deleted',
    UserSaveSuccess: 'User saved',
    UserCodeResetSuccess: 'User Code reset',
    PasswordResetSuccess: 'Password reset',
    UserCodeSaveSuccess: 'Code changed',
    UserPasswordSaveSuccess: 'Password changed',
    UnitDeleted: 'Unit deleted',
    UnitSaveSuccess: 'Unit saved',
    UserSettingSaveSuccess: 'User setting configuration updated',
    UpdateInventoryNextScheduleDate: 'Inventory product(s) schedule updated',
    UserTimeKeepingError: 'The user is not set for time keeping.',
    UserInactiveError: 'The user is not active.',
    UserHaveActiveDelivery: 'User have active delivery.',
    UserCurrentlyClockOut: 'You are currently Clocked Out',
    UserCurrentlyClockIn: 'You are currently Clocked In',
    UserSuccessfullyClockOut: 'You have successfully clock out.',
    UserSuccessfullyClockIn: 'You have successfully clock in.',
    UserUnblockSuccess: 'User unblocked',
    UnsavedChanges: 'You have unsaved changes. Are you sure you want to leave?',
    // V //
    VendorDeleted: 'Vendor deleted',
    VendorSaveSuccess: 'Vendor saved',
    VersionMisMatchInfo: 'Please refresh the browser to load the updated application. The application will be automatically refreshed in {interval} seconds.',
    VendorOrderScheduleConfirmDelete: 'Are you sure you want to delete this order schedule?',
    TransactionVoidSuccess: 'Transaction is marked as void.',
    // W //
    WaitTimeRuleActiveStatusChange: 'Wait time rule {activeMessage}',
    WaitTimeRuleSaveSuccess: 'Wait time rule saved',
    WaitTimeRuleDeleteSuccess: 'Wait time rule deleted',
    WaitTimeRuleConfirmDelete: 'Are you sure you want to delete this wait time rule?',
    WorkScheduleSaveSuccess: 'Work schedule saved',
    WorkScheduleDeleted: 'Work schedule deleted',
    WorkflowSaveSuccess: 'Workflow configuration updated',
    WhitelistedIpDeleted: 'IP address deleted',
    WhitelistedIpSaveSuccess: 'Whitelisted IP saved',
    WorkScheduleBatchSaveSuccess: 'Work schedule batch saved',
    WorkScheduleRequiredMessage: 'Please select and update at least one record.',
    WorkScheduleImportSuccess: 'Work schedule imported',
    WorkScheduleImportConfirmation: 'Are you sure you want to import <b>{fileName}</b>?',
    WorkScheduleImportFormatsMessage: 'Supports 7Shifts and Deputy files.',
    WorkScheduleSkipOverwrite: 'Are you sure you want to {action} conflicts?',
    WidgetDeleted: 'Widget deleted',
    WidgetGroupSaveSuccess: 'Widget group saved',
    WidgetGroupDeleteSuccess: 'Widget group deleted',
    WarningWhileAddSubscriptionWithoutAction: 'Please add an action to the subscription.',
    WaitListSuccessfullyAdded: 'Waitlist Added',
    WaitListEntryNotified: '{phoneNumber} is notified.',
    WaitListDeleteSuccessfully: 'Entry deleted successfully',
    // ORDER ENTRY //
    GetOrderDetailsFailed: 'Error occurred while retrieving order {orderId}.',
    CancelOrderFailed: 'An error occurred while cancelling order {orderId}.',
    DeleteDiscountFromOrderFailed:
        'An error occurred while removing the discount from order {orderId}.',
    DeleteItemFromOrderFailed:
        'An error occurred while deleting {productName} from order {orderId}.',
    DeleteComponentFromProductFailed:
        'An error occurred while deleting {componentName} from {productName}.',
    GetBalancePriceOfOrderFailed:
        'An error occurred retrieving balance of order {orderId}.',
    ChangeItemQtyFailed:
        'An error occurred while changing quantity for {productName}.',
    PaymentPriceOfOrderFailed:
        'An error occurred while recording payment for order {orderId}.',
    MakePaymentFailed: 'An error occurred while recording payment.',
    SavePeripheralCreditCardPaymentFailed:
        'An error occurred while recording credit card payment.',
    ChargeGiftCardAmountFailed: 'An error occurred while charging the gift card.',
    LoadInitialScreenFailed: 'An error occurred while loading the initial screen.',
    RemoveButtonFromScreenFailed:
        'An error occurred while removing {buttonName} from this screen.',
    DuplicateButtonOnScreenFailed:
        'An error occurred while duplicating {buttonName}.',
    FindButtonOnScreenFailed: 'An error occurred while finding a button.',
    ConfirmRemoveProductFromButton: 'Are you sure you want to remove the product from {buttonName}?',
    RemoveProductFromButtonSuccess: 'Removed product from the button',
    RemoveProductFailed:
        'Error occurred while removing product from {buttonName}.',
    DeleteScreenFailed: 'An error occurred while deleting the screen.',
    CheckscreenreferencesFailed:
        'An error occurred while retrieving screen references.',
    CheckForMostCommonNextScreenFailed:
        'An error occurred while retrieving most the common next screen.',
    GetSalesProductsInfoFailed:
        'An error occurred while retrieving sales product information.',
    ConfirmOrderFailed: 'An error occurred while confirming order {orderId}.',
    AddItemToOrderFailed: 'An error occurred while adding an item to this order.',
    UpdateDelayTimeFailed: 'An error occurred while updating the delay time.',
    GetDrawerStatusFailed: 'An error occurred while retrieving drawer status.',
    GetUnservedOrdersFailed: 'An error occurred while retrieving unserved orders.',
    UpdateScheduleDateFailed: 'An error occurred while updating the schedule.',
    GetButtonForSetNameFailed:
        'An error occurred while retrieving \'Set Name\' button.',
    SaveCommentForItemFailed:
        'An error occurred while adding a comment to {productName}.',
    PrintCheckOfOrderFailed: 'An error occurred while printing the check.',
    NotificationButtonFailed:
        'An error occurred while notification button {buttonName}.',
    SetNameForOrderFailed:
        'An error occurred while assigning a name for the order {orderId}.',
    MakeServeTheOrderFailed: 'An error occurred while marking order {orderId} as served',
    OverridePriceFailed:
        'An error occurred while performing a price override on  {productName}.',
    AddSpecialCommentFailed: 'An error occurred while adding comment.',
    DeleteTrainingModeFailed: 'An error occurred while ending training mode',
};
