import { GridColumn } from './grid-column';
import { GridColumnTypes } from './grid-column-types';
import { TextAlign } from './text-align';


export class DecimalColumn extends GridColumn {

    get Type(): string {
        return GridColumnTypes.DecimalColumn;
    }

     constructor(init?: Partial<DecimalColumn>) {
        super();
        this.TextAlign = TextAlign.Right;

        Object.assign(this, init);
    }
}
