<div class="row mx-0 kiosk-screen kiosk-background" [style.height]="'100vh'" [style.background-color]="backgroundColor" [style.background-image]="backgroundImage ? 'url(\'' + imagePath + backgroundImage + '\')' : null">
    <div class="col-xl-8 col-7 d-flex flex-direction-column justify-content-center align-items-center">
        <div class="col-12 d-flex flex-direction-column align-items-center">
            <button class="btn-lg kiosk-checkout-buttons add-button" type="button" (click)="addNewItem()">
                Add additional items
            </button>
            <button class="btn-lg kiosk-checkout-buttons mt-5 complete-order-button" type="button" (click)="onOrderConfirmed()">
                Complete order
            </button>
        </div>
        <div class="mt-5">
            <button class="cancel-order-button" (click)="confirmCancelOrder()">CANCEL ORDER</button>
        </div>
    </div>
    <div class="col-xl-4 col-5 p-3" [ngStyle]="{'height': 'calc(100vh)'}">
        <pos-kiosk-invoice #kioskInvoiceComponent></pos-kiosk-invoice>
    </div>
</div>
