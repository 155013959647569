import { Component, OnInit, ViewChild } from '@angular/core';
import { infoCircle, th, box, inventoryZonesWarehouse } from 'src/app/shared/components/icon';
import { SpinnerService, AlertsService, BaseFormComponent, FormUtilityService } from 'src/app/shared';
import { InventoryZoneService } from 'src/app/shared/services/inventory-zone.service';
import { zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { InventoryInfoModel } from '../../interface';
import { ActivatedRoute, Router } from '@angular/router';
declare let $: any;

@Component({
  selector: 'pos-inventory-zone-edit',
  templateUrl: './inventory-zone-edit.component.html'
})
export class InventoryZoneEditComponent extends BaseFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.form
  }
  inventoryZoneId: number;
  @ViewChild('formInventoryZone') form: NgForm;
  editInventoryzoneId: number;
  inventoryZone: any = {};
  inventoryZoneName: string;
  inventoryBinList: Array<any> = [];
  productList: Array<InventoryInfoModel> = [];
  inventoryBin: any;
  isFormSubmitted = false;
  childFormValidCheck = true;
  tabList = {
    ZoneDetailsSettings: 'ZoneDetailsSettings',
    BinsSettings: 'BinsSettings',
    ProductsSettings: 'ProductsSettings'
  };
  selectedTab = this.tabList.ZoneDetailsSettings;

  constructor(private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private inventoryZoneService: InventoryZoneService,
    private route: ActivatedRoute,
    private router: Router,
    formUtilityService: FormUtilityService
  ) {
    super(formUtilityService);
    this.inventoryZoneId = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
    this.scrollToTop();
  }

  icons = {
    infoCircle,
    th,
    box,
    inventoryZonesWarehouse
  };

  ngOnInit() {
    this.editInventoryzoneId = this.inventoryZoneId;
    this.getInventoryZoneDetails();
    this.setInitialValue();
  }

  getInventoryZoneDetails() {
    this.spinnerService.show();
    if (this.inventoryZoneId > 0) {
      zip(
        this.inventoryZoneService.getInventoryZone(this.inventoryZoneId),
        this.inventoryZoneService.getInventoryBins(this.inventoryZoneId),
        this.inventoryZoneService.getInventoryProductsForZone(this.inventoryZoneId)
      ).pipe(
        map(([inventoryZoneResponse,
          inventoryBinsResponse,
          inventorProForZoneResponse]) => {
          this.inventoryZone = inventoryZoneResponse;
          this.inventoryZoneName = inventoryZoneResponse.Name;

          this.inventoryBinList = inventoryBinsResponse == null ? [] : inventoryBinsResponse;
          this.productList = inventorProForZoneResponse == null ? [] : inventorProForZoneResponse;
        })
      ).subscribe({
        next: (res) => {
        },
        error: this.alertsService.showApiError,
        complete: () => {
          this.spinnerService.hide();
        }
      });
    } else {
      this.inventoryZone = this.inventoryZoneService.newInventoryZone();
      this.inventoryBin = {};
      this.inventoryBinList = [];
      this.productList = [];
      this.spinnerService.hide();
    }
  }

  saveInventoryZone(isValid) {
    this.isFormSubmitted = true;

    if (!isValid || !this.childFormValidCheck) {
      return;
    }
    this.spinnerService.show();
    if (this.editInventoryzoneId > 0) {
      this.inventoryZoneService.updateInventoryZone(this.editInventoryzoneId, this.inventoryZone)
        .subscribe({
          next: response => {
            this.resetForm();
            this.scrollToTop();
            this.router.navigate(['inventory-zones'], { relativeTo: this.route.parent });
          }, error: this.alertsService.showApiError,
          complete: () => { this.spinnerService.hide(); }
        });

    } else {
      this.inventoryZoneService.addInventoryZone(this.inventoryZone)
        .subscribe({
          next: response => {
            this.resetForm();
            this.scrollToTop();
            this.router.navigate(['inventory-zones'], { relativeTo: this.route.parent });
          }, error: this.alertsService.showApiError,
          complete: () => { this.spinnerService.hide(); }
        });
    }
  }

  resetForm() {
    this.editInventoryzoneId = 0;
    this.inventoryZoneName = '';
    // $scope.InventoryZone = salesGroupService.newSalesGroup();
  }

  getHeight(isOuterContainer: boolean) {
    if (isOuterContainer) {
      return $(window).height() - 300 + 'px';
    } else {
      return $(window).height() - 310 + 'px';
    }
  }

  cancelEditing() {
    this.router.navigate(['inventory-zones'], { relativeTo: this.route.parent });
  }

  private scrollToTop(): void {
    window.scrollTo(0, 0);
  }

}
