<pos-list-wrapper [headingIcon]="icons.cashierReport" heading="Revenue Centers" (close)="close()">
  <div class="row">
    <div class="col-md-12 pb-pix-5">
      <p-checkbox name="chkActive" #chkActive="ngModel" (onChange)="changeRevenueCenterList()"
        [(ngModel)]="isInactive" binary="true" label="Show Inactive">
      </p-checkbox>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <ng-table [columns]="revenueCenterColumns" [data]="filteredRevenueCenters" [paginator]="false" [search]="false">
      </ng-table>
    </div>
  </div>
</pos-list-wrapper>
<ng-template let-data="rowData" #activeTemplate>
  <span *ngIf="(data.IsActive)" class="green text-center vertical-mid font-13">
    <pos-icon [icon]="icons.check"></pos-icon>
  </span>
</ng-template>
<ng-template let-data="rowData" #operationTemplate>
  <span (click)="editItem(data.Id, data)" class="pe-pix-3" scrollup tooltip title="Edit">
    <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
  </span>
  <span *ngIf="data.IsActive" class="pt-pix-3" (click)="changeActiveStatus(data.Id, false)" tooltip
    title="Deactivate revenue center">
    <pos-action-icon [icon]="icons.ban"></pos-action-icon>
  </span>
  <span *ngIf="!data.IsActive" class="pt-pix-3" (click)="changeActiveStatus(data.Id, true)" tooltip
    title="Activate revenue center">
    <pos-action-icon [icon]="icons.checkCircleWhite"></pos-action-icon>
  </span>
</ng-template>
<ng-template #headerTemplate>
  <div class="text-center">
    <pos-action-icon (click)="editItem(0)" class="inline-block" [icon]="icons.plusWhite" iconText="Add">
    </pos-action-icon>
    <pos-export-dropdown class="inline-block ps-pix-5" (onPrint)="printData()"
      (onCSV)="exportCSV()"></pos-export-dropdown>
  </div>
</ng-template>
<pos-print-table #printTableReference [hidden]="true"></pos-print-table>
