import { Injectable } from '@angular/core';
import { cloneDeep, filter, find, forEach, remove, some, sortBy, uniqBy } from 'lodash';
import { OrderNavigationButton, ScreenButtons } from 'src/app/orders';
import { DomainConstants } from '../constants';
import { Screen as ScreenModel } from '../../orders/interface/screen';

@Injectable()
export class ButtonNavigationService {

  constructor() { }

  searchFromArray(allButtons: Array<OrderNavigationButton>, searchValue: string, isDeepFilter = false) {
    let filteredButtons: OrderNavigationButton[] = [], filteredTemp: OrderNavigationButton[] = [];
    const buttonsHavingSearchValue: Array<OrderNavigationButton> = [], buttons = [];
    let continueLoop = false;
    let isDuplicate = false;
    let prevScreenId = 0;
    forEach(allButtons, button => {
      if (searchValue && ((button.ButtonText && button.ButtonText.toLowerCase().includes(searchValue.toLowerCase()))
        || (button.SearchableTags && button.SearchableTags.toLowerCase().includes(searchValue.toLowerCase())))) {
        filteredTemp.push(button);
        buttonsHavingSearchValue.push(button);
        buttons.push(button);
        continueLoop = true;
      }
    });

    while (continueLoop) {
      continueLoop = false;
      filteredButtons = cloneDeep(filteredTemp);
      filteredTemp = [];
      isDuplicate = false;
      filteredButtons = sortBy(filteredButtons, 'ScreenId');
      prevScreenId = 0;

      forEach(filteredButtons, filteredButton => {
        if (!filteredButton.StartForm) {
          if (prevScreenId === filteredButton.ScreenId) {
            isDuplicate = true;
          } else {
            isDuplicate = filteredButton.ScreenId === filteredButton.NextScreenId;
            prevScreenId = filteredButton.ScreenId;
          }

          if (!isDuplicate) {
            const nextScreenButtons = filter(allButtons, (item) => item.StackScreenId === filteredButton.ScreenId ||
              (!item.StackScreenId && item.NextScreenId === filteredButton.ScreenId));
            if (isDeepFilter) {
              remove(nextScreenButtons, x => x.SalesProductId
                && !x.ButtonText.toLowerCase().includes(searchValue?.toLowerCase()));
            }
            // push buttons if search value is found in item's next screen
            if (nextScreenButtons.length) {
              buttonsHavingSearchValue.push(...nextScreenButtons);
              filteredTemp.push(...nextScreenButtons);
              continueLoop = true;
            }
          }
        }
      });
    }
    return { buttons: uniqBy(buttons, 'ButtonText'), buttonsHavingSearchValue: buttonsHavingSearchValue };
  }

  setIsSearch(orderNavigationButtons: Array<OrderNavigationButton>, filteredButtons: Array<OrderNavigationButton>) {
    forEach(orderNavigationButtons, (button) => {
      button.IsSearch = false;
      const filterButton = find(filteredButtons, (searchButton) => searchButton.Id === button.Id
        && searchButton.ScreenId === button.ScreenId);
      if (filterButton) { button.IsSearch = true; }
    });
  }

  setButtonSize = (screenButtons: Array<ScreenButtons>, areaWidth: number, maxHeight: number = null, fromMenuExplorer: boolean = false) => {
    forEach(screenButtons, (screenButton: ScreenButtons) => {
      const width = this.getButtonWidth(screenButton.Screen, areaWidth);
      const heightConfig = this.getButtonHeight(width, areaWidth, fromMenuExplorer);
      screenButton.UiConfiguration = {
        width: width + '%',
        height: maxHeight && heightConfig.buttonHeight > maxHeight ? maxHeight : heightConfig.buttonHeight,
        imageSize: heightConfig.imageSize
      };
    });
    return screenButtons;
  }

  getButtonWidth(button: ScreenModel, areaWidth: number) {
    let buttonSize, buttonWidth;
    if (button.Layout === DomainConstants.ScreenButtonLayouts.Fixed && button.ColumnCount && (window.outerWidth > 790 || button.ColumnCount < 4)) {
      buttonWidth = this.calcPercentageOfWidth(button.ColumnCount, null);
    } else if (button.Layout !== DomainConstants.ScreenButtonLayouts.Fixed || (window.outerWidth < 790)) {
      if ((window.outerWidth < 768) && button.Layout !== DomainConstants.ScreenButtonLayouts.Fixed) {
        buttonSize = null;
      } else {
        buttonSize = button.ButtonSize;
      }
      // In mobile, minimum button size is set to small because tiny buttons are not displaying properly
      // (DomainConstants.ButtonSize.Tiny ? DomainConstants.ButtonSize.Small : buttonSize)
      buttonWidth = areaWidth < 440 ?
        this.calcPercentageOfWidth(2, button.Layout !== DomainConstants.ScreenButtonLayouts.Fixed
          && button.Layout === DomainConstants.ButtonSize.Tiny ? DomainConstants.ButtonSize.Small : buttonSize)
        : areaWidth < 600 ? this.calcPercentageOfWidth(buttonSize === DomainConstants.ButtonSize.Large ? 4 : 3, buttonSize)
          : areaWidth < 790 ? this.calcPercentageOfWidth(4, buttonSize)
            : areaWidth < 991 ? this.calcPercentageOfWidth(5, buttonSize)
              : areaWidth < 1300 ? this.calcPercentageOfWidth(6, buttonSize)
                : this.calcPercentageOfWidth(10, buttonSize);
    } else {
      buttonWidth = this.calcPercentageOfWidth(10, buttonSize);
    }
    return buttonWidth;
  }

  getButtonHeight(buttonWidth, areaWidth, fromMenuExplorer) {
    let buttonHeight, width = 0, imageSize;
    const numberOfButtonToDisplay = Math.ceil(100 / parseFloat(buttonWidth));
    width = Math.floor(areaWidth / numberOfButtonToDisplay);
    const heightDiff = fromMenuExplorer ? 45 : 30;
    buttonHeight = Math.max(Math.floor(width - heightDiff), 60);
    imageSize = numberOfButtonToDisplay <= 4 && width > 300 ? (width - 200 + 'px') : width - Math.floor(width / 1.5) + 'px';
    return { buttonHeight: buttonHeight, imageSize: imageSize };
  }

  /** Calculate button's width based on device and screen's configurations. */
  calcPercentageOfWidth(numberOfButtons: number, size: string): number {
    if (size === DomainConstants.ButtonSize.Small) {
      if (window.innerWidth < 991) {
        return (100 / (numberOfButtons + 1));
      }
      return (100 / (numberOfButtons + 2));
    } else if (size === DomainConstants.ButtonSize.Large) {
      // When numberOfButtons's value 2 then display 2 buttons display in mobile screen
      if ((numberOfButtons - 2) === 0) {
        return (100 / (numberOfButtons));
      }
      return (100 / (numberOfButtons - 2));
    } else if (size === DomainConstants.ButtonSize.Tiny) {
      if (window.innerWidth < 768) {
        return (100 / numberOfButtons);
      } else if (window.innerWidth < 1300) {
        return (100 / (numberOfButtons + 2));
      }
      return (100 / (numberOfButtons + 4));
    }
    return (100 / numberOfButtons);
  }

  validateButtonVisibility(navigationButtons: Array<OrderNavigationButton>, isDesignMode: boolean) {
    forEach(navigationButtons, (button) => {
      let dateRange: Array<string> = [];
      button.IsVisible = true;
      if (!isDesignMode) {
        if (button.ScheduleStartDate || button.ScheduleEndDate) {
          if (Date.parse(button.ScheduleStartDate) > Date.now()
            || Date.parse(button.ScheduleEndDate) < Date.now()) {
            button.IsVisible = false;
          }
        }
        if (button.ScheduleStartTime || button.ScheduleEndTime) {
          if (this.timeToDateTime(button.ScheduleStartTime).getTime() > Date.parse((new Date()).toString())
            || this.timeToDateTime(button.ScheduleEndTime).getTime() < Date.parse((new Date()).toString())) {
            button.IsVisible = false;
          }
        }
        if (button.DateRange) {
          dateRange = button.DateRange.split('');
          const isInRange = dateRange.find(day => (parseInt(day, 10) - 1) === (new Date().getDay()));
          if (!isInRange) {
            button.IsVisible = false;
          }
        }
      }
    });
  }

  timeToDateTime(timeString): Date {
    if (timeString) {
      const timeTokens = timeString.split(':');
      if (timeTokens && timeTokens.length > 0) {
        const currentDate = new Date();
        return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), timeTokens[0], timeTokens[1]);
      }
    }
  }

  setButtonInStock(currentButton: OrderNavigationButton, sizeId: number = null, isCheckDefaultSize = true) {
    // Set SizeId = defaultSize In case passed size is not mapped with product
    if (sizeId && !currentButton.SalesProductSizes?.some(s => s.SizeId === sizeId)) {
      sizeId = currentButton.DefaultSizeId;
    }
    if (!isCheckDefaultSize) {
      return some(currentButton.SalesProductSizes, x => x.IsInStock);
    }
    return find(currentButton.SalesProductSizes,
      (salesProductSize) => salesProductSize.SizeId === (sizeId ? sizeId : currentButton.DefaultSizeId))?.IsInStock;
  }

  setSalesProductSizeInStock = (button: OrderNavigationButton, status: boolean, sizeIds: Array<number>) => {
    forEach(button.SalesProductSizes, (size) => {
      if (sizeIds.some((s) => s === size.SizeId)) {
        size.IsInStock = status;
      }
    });
  }

  setEmbeddedScreenOrdinal(buttons: Array<OrderNavigationButton>) {
    let embeddedScreenNumber = 0, lastEmbeddedScreenId = null;
    if (buttons.some(x => x.EmbeddedScreenChoiceId)) {
      buttons.forEach(x => {
        if (x.EmbeddedScreenChoiceId) {
          if (lastEmbeddedScreenId != x.EmbeddedScreenId) {
            embeddedScreenNumber++;
          }
          x.EmbeddedScreenNumber = embeddedScreenNumber;
          lastEmbeddedScreenId = x.EmbeddedScreenId;
        }
      });
    }
  }
}
