import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { ApplicationFeaturesService } from '../../services';
declare let $: any;

@Directive({
  selector: '[feature]'
})
export class FeatureDirective implements OnInit {

  @Input() feature: any;
  currentElement: ElementRef;

  constructor(el: ElementRef,
    private applicationFeatureService: ApplicationFeaturesService) {
    this.currentElement = el;
  }

  ngOnInit(): void {
    if (this.feature && !this.applicationFeatureService.isEnableFeature(this.feature)) {
      $(this.currentElement.nativeElement).hide();
    }
  }

}
