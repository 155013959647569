import { Component, OnInit, ViewChild, ElementRef, QueryList, Input } from '@angular/core';

@Component({
  selector: 'pos-media-rotator',
  templateUrl: './media-rotator.component.html',
  styleUrls: ['./media-rotator.component.scss']
})

export class MediaRotatorComponent implements OnInit {
  @Input() media: any;
  private currentSlide = 0;
  image = ['png', 'jpg', 'jpeg', 'gif', 'bmp'];
  video = ['mp4', 'ogg', 'webm', 'm4v', 'mpg', 'avi'];

  currentDisplayMedia: any = {}

  ngOnInit() {
    this.startSlideshow();
  }

  startSlideshow() {
    this.currentSlide = 0;
    this.showSlide(this.currentSlide);
  }

  nextSlide() {
    this.currentSlide = (this.currentSlide + 1) % this.media.length;
    this.showSlide(this.currentSlide);
  }

  showSlide(index: number) {
    const slideMedia = this.media[index];
    if (slideMedia) {
      const isImage = this.isImage(slideMedia.source);

      this.currentDisplayMedia = {
        type: isImage ? 'image' : 'video',
        source: slideMedia.source,
        duration: slideMedia.duration ? slideMedia.duration : 2000,
        id: slideMedia.id
      };
      setTimeout(() => {
        this.nextSlide();
      }, this.currentDisplayMedia.duration);
    }
  }

  isImage(url: string) {
    if(url) {
      let index = url.lastIndexOf(".");
      let suffix = url.substr(index + 1);
      return (this.image.indexOf(suffix) >= 0);
    }
  }

}
