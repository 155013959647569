import { MessagePayload } from './message-payload';

export class StringMessagePayload extends MessagePayload {

    public Message: any;

    constructor(message: any) {
        super();
        this.Message = message;
    }
}
