import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  BaseListComponent, AlertsService, SpinnerService, ModalService, PrintTableService,
  ApplicationStateService, Messages, Levels, Permissions, ConfirmDeleteModalComponent, DomainConstants, ExportService, RabbitMQService
} from 'src/app/shared';
import { Terminal } from '../../interface/terminal';
import { GridColumn, TableComponent, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import * as _ from 'lodash';
import { GlobalLogoffComponent } from '../global-logoff/global-logoff.component';
import { TerminalsService } from '../../services/terminals.service';
import {
  plusWhite, editWhite, deleteWhite, cogWhite, printWhite, terminalTv, undoWhite, circleRed, circleGreen
} from 'src/app/shared/components/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep, filter, find, forEach } from 'lodash';

@Component({
  selector: 'pos-terminals-list',
  templateUrl: './terminals-list.component.html',
  styleUrls: ['./terminals-list.component.scss']
})
export class TerminalsListComponent extends BaseListComponent<Terminal> implements OnInit {

  terminalColumns: Array<GridColumn> = [];
  printTerminalColumns: Array<GridColumn> = [];
  terminals: Array<Terminal> = [];
  filteredTerminals: Array<Terminal> = [];
  permission = {
    name: Permissions.SystemConfigurationTerminals,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };
  terminalTypes = DomainConstants.TerminalTypes;
  icons = {
    plusWhite, editWhite, deleteWhite, cogWhite, printWhite, terminalTv, undoWhite, circleGreen, circleRed
  };
  isLicenseAvailable = true;
  statusRefreshIntervalValue = 0;
  statusRefreshIntervalList = [
    { key: 'Never', value: 0 },
    { key: '30 Seconds', value: 30 * 1000, },
    { key: '1 Minute', value: 60 * 1000, },
    { key: '5 Minute', value: 300 * 1000, },
    { key: '10 Minute', value: 600 * 1000, },
  ]
  statusRefreshInterval: ReturnType<typeof setTimeout>;
  terminalStatus = DomainConstants.TerminalStatus;
  filterStatus = null;
  @ViewChild('iconTemplate', { static: true }) iconTemplate: TemplateRef<any>;
  @ViewChild('operationTemplate', { static: true }) operationTemplate: TemplateRef<any>;
  @ViewChild('operationHeaderTemplate', { static: true }) operationHeaderTemplate: TemplateRef<any>;
  @ViewChild('terminalTable', { static: true }) terminalTable: TableComponent;
  @ViewChild('terminalStatusTemplate', { static: true }) terminalStatusTemplate: TemplateRef<any>;

  constructor(protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected terminalService: TerminalsService,
    protected printService: PrintTableService,
    protected applicationStateService: ApplicationStateService,
    protected router: Router,
    protected route: ActivatedRoute,
    private exportService: ExportService,
    private rabbitmqService: RabbitMQService) {
    super(terminalService, alertService, spinnerService, modalService, applicationStateService, printService, route);
  }

  ngOnInit() {
    this.deleteSuccessMessage = Messages.TerminalDeleted;
    this.loadSubscriptions();
    this.statusRefreshIntervalChanged();
    this.terminalColumns = this.configureGridColumns();
    this.terminalTable.context = this.gridContext;
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.clearStatusRefreshInterval();
  }

  private loadSubscriptions(): void {
    this.data$.subscribe({
      next: (res) => {
        this.terminals = res;
        this.prepareTerminal();
        this.terminals = this.terminals.sort((a, b) => a.TerminalName.toLowerCase().localeCompare(b.TerminalName.toLowerCase()));
        this.getTerminalsByStatus();
        this.checkTerminalLicenseAvailable();
      }
    });
  }

  prepareTerminal() {
    forEach(this.terminals, (terminal) => {
      forEach(DomainConstants.TerminalTypes, (type) => {
        if (type.Name === terminal.TerminalType) {
          terminal.Image = type.Image;
          terminal.Icon = type.Icon;
        }
      });
    });
  }

  getGridColumns(): Array<GridColumn> {
    return this.terminalColumns;
  }

  configureGridColumns(): Array<GridColumn> {
    const operationColumn = new TemplateColumn({
      itemTemplate: this.operationTemplate,
      Width: '170px',
      headerTemplate: this.operationHeaderTemplate,
      CellClass: 'cell-padding',
      TextAlign: TextAlign.Center
    });

    const iconTemplate = new TemplateColumn({
      itemTemplate: this.iconTemplate,
      Width: '25px',
      CellClass: 'cell-padding no-right-border',
      TextAlign: TextAlign.Center,
    });

    const terminalStatusTemplate = new TemplateColumn({
      itemTemplate: this.terminalStatusTemplate,
      Width: '25px',
      CellClass: 'cell-padding no-right-border',
    })

    this.terminalColumns = [

      terminalStatusTemplate,
      new GridColumn({
        HeaderText: 'Terminal Name', Field: 'TerminalName', IsSortable: true, Width: '44%', CellClass: 'no-left-border pli-4'
      }),
      iconTemplate,
      new GridColumn({
        HeaderText: 'Terminal Type', Field: 'TerminalType', CellClass: 'no-left-border pli-4',
        IsSortable: true, Width: '44%'
      })
    ];
    this.printTerminalColumns = [...this.terminalColumns];
    this.terminalColumns.push(operationColumn);

    return this.terminalColumns;
  }

  editItem(id: number, data?: Terminal): void {

    this.router.navigate([id], { relativeTo: this.route });
  }

  printData() {
    this.printService.printEmitter.next({ gridColumns: this.printTerminalColumns, gridData: this.filteredTerminals });
  }

  exportCSV() {
    this.exportService.exportCSV('terminal', this.filteredTerminals, null, this.printTerminalColumns);
  }

  deleteTerminal(data: Terminal): void {
    const modalRef = this.modalService.getModalWrapper(ConfirmDeleteModalComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      initialState: {
        message: Messages.ConfirmDeleteTerminal
      }
    });
    modal.close.subscribe(res => {
      if (res && res.shouldDelete) {
        this.delete(data.TerminalId);
      }
    });
  }

  getConfirmDeleteMessage(data: Terminal): string {
    return Messages.ConfirmDeleteTerminal;
  }

  openGlobalLogoffModal() {
    const modalRef = this.modalService.getModalWrapper(GlobalLogoffComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center'
    });

    modal.close.subscribe(res => {
      if (res && res.shouldReload) {
        this.reload();
      }
    });
  }

  checkTerminalLicenseAvailable() {
    const licenseInfo = cloneDeep(this.applicationStateService.licenseInfo);
    if (licenseInfo.IsActive) {
      _.forEach(this.terminals, (terminal) => {
        _.forEach(licenseInfo.CustomerTerminalLicenses, (licenseTerminal) => {
          if (terminal.TerminalType === licenseTerminal.TerminalTypeName && licenseTerminal.TerminalCount > 0) {
            licenseTerminal.TerminalCount = licenseTerminal.TerminalCount - 1;
          }
        });
      });
      const selectedTerminalLicense = _.filter(licenseInfo.CustomerTerminalLicenses,
        (licenseTerminal) => licenseTerminal.TerminalCount > 0);
      this.isLicenseAvailable = (selectedTerminalLicense && selectedTerminalLicense.length > 0) ? true : false;
    }
  }

  terminalConfig(data: Terminal) {
    if (data.HasMappedHardwareTypes) {
      this.openDeviceMapping(data);
    } else if (data.TerminalType === this.terminalTypes.PRODUCT_BUILD_QUEUE.Name) {
      this.openBuildQueueConfiguration(data);
    } else if (data.TerminalType === this.terminalTypes.MENU_EXPLORER.Name) {
      this.openMenuExplorerConfiguration(data);
    }
  }

  openMenuExplorerConfiguration(data: Terminal) {
    this.router.navigate(['menu-explorer', data.TerminalId]);
  }

  openDeviceMapping(data: Terminal) {
    this.router.navigate(['device-mapping', data.TerminalId], { state: { name: data.TerminalName, terminalTypeId: data.TerminalTypeId }, relativeTo: this.route });
  }

  openBuildQueueConfiguration(data: Terminal) {
    this.router.navigate(['product-build-queue', data.TerminalId], { state: { name: data.TerminalName }, relativeTo: this.route });
  }

  refreshMenuDisplay(data: Terminal) {
    this.rabbitmqService.sendRefreshTerminalMessage(data.TerminalId);
    this.alertService.renderSuccessMessage(Messages.TerminalRefreshRequest);
  }

  statusRefreshIntervalChanged() {
    this.clearStatusRefreshInterval();
    if (this.statusRefreshIntervalValue) {
      this.statusRefreshInterval = setInterval(() => {
        this.reload(true);
      }, this.statusRefreshIntervalValue);
    }
  }

  clearStatusRefreshInterval() {
    if (this.statusRefreshInterval) {
      clearInterval(this.statusRefreshInterval)
    }
  }

  getTerminalStatusIcon(terminalStatus: string) {
    const icon = find(this.terminalStatus, ts => ts.Value == terminalStatus)?.Icon;
    return this.icons[icon];

  }

  getTerminalsByStatus() {
    this.filteredTerminals = this.filterStatus ? filter(this.terminals, t => t.TerminalStatus == this.filterStatus) :  this.terminals;
    this.gridData = this.filteredTerminals;
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
