<pos-edit-wrapper heading="External Logins" [headingIcon]="icons.externalLoginsSigninAlt" (close)="onCancel()" [isPopup]="false">
  <form #externalLoginForm="ngForm" (ngSubmit)="saveExternalLogins(externalLoginForm.valid)" autocomplete="off">
    <div class="col-xs-12 border-top">
      <div class="col-xs-12 pos-page-body">
        <div>
          <h3 class="setting-title mt-pix-5">Settings </h3>
        </div>
        <pos-row class="pos-title-content">
          <pos-column>
            <pos-form-field [validationMessages]="{required: 'Please enter user failed login attempts', min: 'Please enter positive user failed login attempts'}">
              <pos-row class="p-0">
                <pos-column xs="4" class="col-120 pe-0">
                  <label class="edit-form__section__label">Block user after</label>
                </pos-column>

                <pos-column sm="3" xs="4" class="pe-0">
                  <input posInput class="form-control text-right" name="userFailedLoginAttemptsLimit"
                    #userFailedLoginAttemptsLimit="ngModel" [(ngModel)]="paramUserFailedLoginAttemptsLimit"
                    type="number" [required]="true" min="0" posOnlyNumbers/>
                </pos-column>

                <pos-column xs="4" class="col-120 pe-0">
                  <label class="edit-form__section__label">failed attempts</label>
                </pos-column>
              </pos-row>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field [validationMessages]="{required: 'Please enter IP failed login attempts', min: 'Please enter positive IP failed login attempts'}">
              <pos-row class="p-0">
                <pos-column xs="4" class="col-120 pe-0">
                  <label class="edit-form__section__label">Block IP after</label>
                </pos-column>

                <pos-column sm="3" xs="4" class="pe-0">
                  <input posInput class="form-control text-right" name="ipFailedLoginAttemptsLimit"
                    #ipFailedLoginAttemptsLimit="ngModel" [(ngModel)]="paramIpFailedLoginAttemptsLimit"
                    type="number" required min="0" posOnlyNumbers />
                </pos-column>

                <pos-column xs="4" class="col-120 pe-0">
                  <label class="edit-form__section__label">failed attempts</label>
                </pos-column>
              </pos-row>
            </pos-form-field>
          </pos-column>
        </pos-row>
      </div>
    </div>
    <div class="col-xs-12 action-button-container page-footer">
      <button class="btn btn-primary btn-lg btn-save pos-button" scrollup type="submit" value="Save"> Save </button>
      <button class="btn btn-secondary btn-lg btn-cancel pos-button" (click)="onCancel()" scrollup type="button" value="Cancel"> Cancel </button>
    </div>
  </form>
</pos-edit-wrapper>
