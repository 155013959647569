<div class="col-12 p-0 full-height table-widget" *ngIf="!isLoading" [ngClass]="isInteractive? '' : 'radius-25'"
    [ngStyle]="(config.color || config.borderColor) && {'color': config.color,'border': config.borderColor ? 'solid 2px ' + config.borderColor:''}">
    <div class="col-12 ps-pix-15 pe-pix-15" [ngClass]="{'py-2': !isInteractive}">
        <div class="col-12 p-0">
            <div *ngIf="data.length">
                <ng-table [columns]="columns" [data]="data"  [paginator]="false" [tableStyle]="{'font-size': config.fontSize ? config.fontSize + 'px' : '11px'}"
                    [scrollable]="true" [scrollHeight]="config.height ? config.height : '430px'"
                    [rowBgColor]="'Meta_Color'" [rowFontColor]="'FontColor'"></ng-table>
            </div>
            <div class="grey text-center pt-pix-10" *ngIf="!data.length">
                No records found
            </div>
        </div>
        <div class="col-12 p-0" *ngIf="config.metricsName">
            <div class="col-12 text-center font-20" [ngStyle]="{'color': color}">
                {{ config.metricsName }}
            </div>
        </div>
    </div>
</div>
<div *ngIf="isLoading" class="col-12 p-0 full-height align-vertical-center"
    [ngClass]="isInteractive? '' : 'radius-25'"
    [ngStyle]="{'color':config.color,'background-color':config.bgColor,'border':'solid 2px '+(config.borderColor || config.bgColor || '#fff')}">
    <div class="col-12 ps-pix-15 pe-pix-15 pt-pix-5 pb-pix-5">
        <pos-widget-loading></pos-widget-loading>
    </div>
</div>
