import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { RuntimeConstants } from 'src/app/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class BaseWidgetSearchService {
  protected apiEndpoint: string;
  constructor(protected httpService: HttpClient) {
    this.apiEndpoint = `${RuntimeConstants.API_PATH}menu-widget`;
  }

  getMenuWidgets(menuId: number): Observable<Array<any>> {
    return this.httpService.get<Array<any>>(
      `${this.apiEndpoint}/${menuId}`
    );
  }

  getWidget(menuId: number, widgetId: number): Observable<any> {
    return this.httpService.get<any>(
      `${this.apiEndpoint}/${menuId}/widget/${widgetId}`
    );
  }
}
