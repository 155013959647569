<div *ngIf="isPopup">
  <button class="close" (click)="onCancel()">&times;</button>
  <h4 class="modal-content__heading">Product Ingredients: {{productName}}
  </h4>
</div>
<div class="overflow-hidden">
  <context-provider provide="props">
    <form #salesProductRecipe="ngForm">
      <div class="row"
        [ngClass]="{'screen-scroll px-4 pt-pix-5': isPopup, 'p-0': !isPopup}">

        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <pos-form-field inputStyle="pt-pix-7">
              <p-checkbox posInput name="directLink" #directLink="ngModel" [(ngModel)]="isDirectLinkToInventoryProduct"
                binary="true" (onChange)="onChangeDirectLinkState()" [disabled]="isDefaultQtyFraction"
                label="Direct Link to Inventory" class="ps-pix-2 text-nowrap">
              </p-checkbox>
            </pos-form-field>
          </div>
          
          <div class="col-lg-9 col-12">
            <div class="row justify-content-end mx-0">
              <div class="col-lg-4 col-sm-6 col-12">
                <pos-form-field label="Size" labelStyle="col-1 pt-pix-7 px-0" inputStyle="col-sm-10 col-7 ms-2">
                  <ng-select posInput [items]="salesSizes" bindLabel="Name" (change)="selectProductSizeForAddingIngredient()"
                    bindValue="SizeId" [clearable]="false" placeholder="Select size" appendTo="body" [(ngModel)]="selectedSizeId"
                    name="productSize" required>
                  </ng-select>
                </pos-form-field>
              </div>
              <div class="col-sm-3 col-12 float-right col-255">
                <pos-form-field inputStyle="pt-pix-7">
                  <p-checkbox posInput name="history" #history="ngModel" [(ngModel)]="isShowHistory" binary="true"
                    (onChange)="showHistoricalIngredientsData()" label="Show previous inventory values">
                  </p-checkbox>
                </pos-form-field>
              </div>
            </div>
          </div>

        </div>
        
        <div class="col-sm-6 col-12" *ngIf="isDirectLinkToInventoryProduct">
          <pos-form-field label="Product">
            <ng-select posInput [items]="ingredientList" bindLabel="Name" bindValue="Id" placeholder="Select product to add"
              appendTo="body" (change)="inventoryProductDirectLink()" [(ngModel)]="linkedProductId" name="salesGroup"
              (clear)="deleteInventoryProduct()">
            </ng-select>
          </pos-form-field>
        </div>

        <div class="col-12" *ngIf="!isDirectLinkToInventoryProduct || tempShowRecipe">
          <pos-form-field label="Product" inputStyle="col-12">

            <div class="row">
              <div class="col-lg-3 col-sm-5 col-xs-8">
                <ng-select posInput [items]="ingredientList" bindLabel="Name" bindValue="Id"
                  placeholder="Select product" appendTo="body" [(ngModel)]="ingredientId" name="salesGroup">
                </ng-select>
              </div>

              <div class="col-lg-2 col-sm-5 col-xs-4">
                <div class="btn-group pt-pix-6 pt-pix-sm-0" ngbDropdown>
                  <button type="button" class="btn btn-secondary pos-button" [disabled]="!(!isAtLeastOnePrice || ingredientId > 0)"
                    (click)="addIngredientToProduct()">Add</button>
                    <button ngbDropdownToggle type="button" class="btn btn-secondary dropdown-toggle pos-button" data-toggle="dropdown"
                      [disabled]="!(!isAtLeastOnePrice || ingredientId > 0)">
                      <span class="caret"></span>
                      <span class="sr-only">Recipe Types</span>
                    </button>
                    <ul ngbDropdownMenu class="dropdown-menu" role="menu">
                      <a ngbDropdownItem (click)="addIngredientToProduct()">Recipe</a>
                      <a ngbDropdownItem (click)="addIngredientToProduct(true)">Add to Base Recipe</a>
                    </ul>
                </div>
              </div>
            </div>
          </pos-form-field>
        </div>
        <div class="col-12 pt-pix-10">
          <div id="divReceipeContent" class="col-12"
            *ngIf="!productBaseIngredientConsumption?.length">
            <p-table [value]="productBaseIngredientConsumptions" [tableStyle]="'min-width: 1100px'" [sort]="false" [search]="false" [paginator]="false">
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 25px"></th>
                  <th style="width: 15%">Product</th>
                  <th style="width: 6%">Quantity</th>
                  <th style="width: 8%">Unit</th>
                  <th style="width: 13%">Notes</th>
                  <th style="width: 13%">Instructions</th>
                  <th style="width: 8%">Custom Color</th>
                  <th style="width: 12%">Effective Date</th>
                  <th style="width: 12%">Expired Date</th>
                  <th style="min-width: 113px"></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                  <td colspan="10">No records found</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <div id="divReceipeContent" class="col-12"
            *ngFor="let section of productBaseIngredientConsumption; let sectionIndex = index">
            <p-table [value]="section.Recipes"
              (onRowReorder)="productIngredientsGridRowReorder($event, section.Recipes)" [sort]="false"
              [search]="false" [paginator]="false">
              <ng-template pTemplate="header">
                <tr>
                  <th [ngClass]="{'hidden-header': sectionIndex != 0 }" style="width: 25px">
                  </th>
                  <th [ngClass]="{'hidden-header': sectionIndex != 0 }" style="width: 15%">{{sectionIndex == 0 ?
                    'Product': ''}}</th>
                  <th [ngClass]="{'hidden-header': sectionIndex != 0 }" style="width: 6%">{{sectionIndex == 0 ?
                    'Quantity': ''}}</th>
                  <th [ngClass]="{'hidden-header': sectionIndex != 0 }" style="width: 8%">{{sectionIndex == 0 ?
                    'Unit': ''}}</th>
                  <th [ngClass]="{'hidden-header': sectionIndex != 0 }" style="width: 13%">{{sectionIndex == 0 ?
                    'Notes': ''}}</th>
                  <th [ngClass]="{'hidden-header': sectionIndex != 0 }" style="width: 13%">{{sectionIndex == 0 ?
                    'Instructions': ''}}</th>
                  <th [ngClass]="{'hidden-header': sectionIndex != 0 }" style="width: 8%">{{sectionIndex == 0 ?
                    'Custom Color': ''}}</th>
                  <th [ngClass]="{'hidden-header': sectionIndex != 0 }" style="width: 12%">{{sectionIndex == 0 ?
                    'Effective Date': ''}}</th>
                  <th [ngClass]="{'hidden-header': sectionIndex != 0 }" style="width: 12%">{{sectionIndex == 0 ?
                    'Expired Date': ''}}</th>
                  <th [ngClass]="{'hidden-header': sectionIndex != 0 }" style="min-width: 113px;text-align: center;"></th>
                </tr>

              </ng-template>
              <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                <tr *ngIf="rowIndex == 0">
                  <td colspan="10" class="bold">
                    <pos-icon class="ps-pix-2 pe-pix-10" *ngIf="section.SizeId != 0" [icon]="icons.salesProductRecipe">
                    </pos-icon>
                    <pos-icon class="ps-pix-2 pe-pix-10" *ngIf="section.SizeId == 0" [icon]="icons.column">
                    </pos-icon>
                    {{section.SizeId == 0 ? 'Base Recipe' : 'Recipe (' + currentSizeName+ ')'}}
                  </td>
                </tr>
                <tr class="p-widget-header" [pReorderableRow]="rowIndex"
                  [ngClass]="{'row-grouping-reorder': rowIndex == 0 && sectionIndex > 0}">
                  <td style="width: 3%">
                    <i [ngClass]="icons.ordinalBars.iconClasses" pReorderableRowHandle></i>
                  </td>
                  <td style="width: 15%">
                    <span class="me-pix-2">
                      <pos-icon *ngIf="(rowData.IsCommon)" [icon]="icons.squareO" tooltip
                        tooltip="Not shown on pick screen">
                      </pos-icon>
                      <pos-icon *ngIf="(!rowData.IsCommon)" [icon]="icons.square" tooltip
                        tooltip="Shown on pick screen"></pos-icon>
                    </span>
                    <span>{{rowData.Product}}</span>
                  </td>
                  <td style="width: 6%">{{rowData.qty}}</td>
                  <td style="width: 8%">{{rowData.Unit}}</td>
                  <td style="width: 13%">{{rowData.Notes}}</td>
                  <td style="width: 13%">
                    <div *ngIf="rowData.instructions" class="me-pix-2">{{rowData.instructions}}</div>
                    <div *ngIf="rowData.instructions==''">
                      {{rowData.Product}} {{rowData.qty}} {{rowData.Unit}}
                    </div>
                  </td>
                  <td style="width: 8%">
                    <div class="hg-20 col-20 div-center"
                      [ngStyle]="{'background-color': rowData && rowData.CustomColor ? rowData.CustomColor : ''}">
                    </div>
                  </td>
                  <td style="width: 12%">{{rowData.DateEffective | tark_date_time}}</td>
                  <td style="width: 12%">{{rowData.DateExpired | tark_date_time}}</td>
                  <td style="min-width: 113px;text-align: center;">
                    <span (click)="editRecipe(rowData)" class="ps-pix-3 col-30">
                      <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
                    </span>
                    <span (click)="deleteRecipe(rowData)" class="pe-pix-3 ps-pix-3  col-30">
                      <pos-action-icon [icon]="icons.deleteWhite">
                      </pos-action-icon>
                    </span>
                    <span class="greyicon" class="pe-pix-3 col-30">
                      <ng-container>
                        <a class="action-btn grey pointer" #pop="bs-popover" placement="left"
                          (click)="openSalesProductPopover(pop, rowData)" [outsideClick]="true"
                          tooltip-append-to-body="true" [popover]="salesProductPopoverTemplate" triggers="click">
                          <pos-action-icon [icon]="icons.barsWhite"></pos-action-icon>
                        </a>
                      </ng-container>
                    </span>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
        <div class="col-12 text-align-right mt-pix-10" *ngIf="!isPopup">
          <button type="button" class="btn btn-lg btn-secondary normal pos-button" (click)="copyProductToIngredient()">
            Copy to Product
          </button>
          <button type="button" class="btn btn-lg btn-secondary ms-pix-5 normal pos-button" (click)="copyIngredientFromProduct()">
            Copy from Product
          </button>
        </div>
      </div>
    </form>
  </context-provider>
  <div class="clearfix"></div>
  <div class="col-12 action-button-container popup-footer pb-pix-10" *ngIf="isPopup">
    <button type="button" class="btn btn-lg btn-secondary me-pix-5 normal pos-button" (click)="copyProductToIngredient()">
      Copy to Product
    </button>
    <button type="button" class="btn btn-lg btn-secondary me-pix-5 normal pos-button" (click)="copyIngredientFromProduct()">
      Copy from Product
    </button>
    <button type="button" class="btn-lg btn-secondary btn btn-cancel-modal pos-button" (click)="onCancel()">
      Close
    </button>
  </div>
</div>

<ng-template let-data="rowData" #salesProductPopoverTemplate>
  <div class="col-170">
    <ul>
      <li class="listbullet pointer">
        <a class="grey ps-pix-2 display-flex align-item-center" (click)="onSwapRecipeItem()">
          <pos-icon class="pe-pix-10" [icon]="icons.exchangeAltAction">
          </pos-icon>
          Swap recipe
        </a>
      </li>
      <li class="listbullet pointer" *ngIf="recipeDetails?.SizeId">
        <a class="grey ps-pix-2 display-flex align-item-center" (click)="onMoveRecipe()">
          <pos-icon class="ps-pix-3 pe-pix-10" [icon]="icons.arrowsAlt">
          </pos-icon>
          Move to Base Recipe
        </a>
      </li>
    </ul>
  </div>
</ng-template>
