import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { GridContextInfo, Messages } from 'src/app/shared';
import { BaseCrudComponent, SpinnerService, sortNumericUp } from 'src/app/shared/components';
import { AlertsService, FormUtilityService } from 'src/app/shared/services';
import { InventoryVendorProfile } from '../../interface';
import { InventoryVendorProfileProduct } from '../../interface/inventory-vendor-profile-product';
import { InventoryVendorProfileService } from '../../service/inventory-vendor-profile.service';

@Component({
  selector: 'pos-inventory-vendor-profile-edit',
  templateUrl: './inventory-vendor-profile-edit.component.html',
  styleUrls: ['./inventory-vendor-profile-edit.component.scss']
})
export class InventoryVendorProfileEditComponent extends BaseCrudComponent<InventoryVendorProfile> implements OnInit {

  id: number = null;
  vendorId: number = null;
  vendorName = null;
  inventoryVendorProfile: InventoryVendorProfile = null;
  profileProducts: Array<InventoryVendorProfileProduct> = [];
  vendorsGridContext: GridContextInfo = null;
  profileGridContext: GridContextInfo = null;
  vendorsActive: boolean = true;
  @ViewChild('vendorProfileForm') vendorProfileForm: NgForm;
  get getForm(): NgForm {
    return this.vendorProfileForm
  }
  icons = {
    sortNumericUp
  }

  constructor(protected spinnerService: SpinnerService,
    protected inventoryVendorProfileService: InventoryVendorProfileService,
    protected alertService: AlertsService,
    private router: Router,
    protected route: ActivatedRoute,
    formUtilityService: FormUtilityService) {
    super(inventoryVendorProfileService, alertService, spinnerService, formUtilityService);
    this.vendorId = parseInt(route.snapshot.params.id, 10) ?? 0;
    this.id = parseInt(route.snapshot.params.profileId, 10) ?? 0;
    const navigation = router.getCurrentNavigation();
    this.vendorName = navigation?.extras?.state?.vendorName ?? '';
    this.vendorsGridContext = navigation?.extras?.state?.GridContext ?? null;
    this.profileGridContext = navigation?.extras?.state?.ProfileGridContext ?? null;
    this.vendorsActive = navigation?.extras?.state?.Active ?? null;
  }

  ngOnInit(): void {
    this.inventoryVendorProfile = this.inventoryVendorProfileService.newVendorProfile();
    this.saveSuccessMessage = Messages.InventoryVendorProfileSaveSuccess;
    this.dataSubscription();
  }

  private dataSubscription(): void {
    if (this.id > 0) {
      this.spinnerService.show();
      this.loadData().pipe(finalize(() => {
        this.spinnerService.hide();
      }))
        .subscribe({
          next: (res) => {
            this.inventoryVendorProfile = res;
            this.vendorName = this.inventoryVendorProfile.InventoryVendor.Name;
          }, error: this.alertService.showApiError
        });
    }
  }

  submit(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.inventoryVendorProfile.VendorId = this.vendorId;
    if (this.id > 0) {
      this.save(this.inventoryVendorProfile);
    } else {
      this.saveProfileWithProducts();
    }
  }

  cancel() {
    this.router.navigate(['vendors', this.vendorId, 'profiles'], { relativeTo: this.route.parent, state: { GridContext: this.vendorsGridContext, ProfileGridContext: this.profileGridContext, Active: this.vendorsActive } });
  }

  setProfileProducts(res) {
    this.profileProducts = res.profileProducts;
  }

  onSaveSuccess(model: InventoryVendorProfile): void {
    this.cancel();
  }

  saveProfileWithProducts() {
    this.profileProducts.forEach(x => x.InventoryProduct = null);
    this.spinnerService.show();
    this.inventoryVendorProfileService.saveProfileWithProducts(this.inventoryVendorProfile, this.profileProducts)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.alertService.renderSuccessMessage(Messages.InventoryVendorProfileSaveSuccess);
          this.router.navigate(['vendors', this.vendorId, 'profiles'], { relativeTo: this.route.parent });
        }, error: this.alertService.showApiError
      });
  }

  loadDependencies(): void { }

}
