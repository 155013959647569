import { Injectable } from '@angular/core';
import { RuntimeConstants, BaseCrudService } from 'src/app/shared';
import { HttpClient } from '@angular/common/http';
import { TimeClockEntry } from '../interface';
import { Observable } from 'rxjs';

@Injectable()
export class TimeClockEntriesService extends BaseCrudService<TimeClockEntry> {
    timeclockentriesApiBasePath = '';

    constructor(httpService: HttpClient) {
        super('timeclockentries', httpService);
        this.timeclockentriesApiBasePath = RuntimeConstants.API_PATH + 'timeclockentries';
    }

    newTimeClockEntry() {
        const timeClockEntryModel: TimeClockEntry = {
            Id: 0,
            EmpName: '',
            UserId: null,
            ClockIn: new Date(),
            ClockOut: new Date(),
            Name: '',
            StillClockedIn: false,
            ClockOutTime: new Date(),
            ClockInTime: new Date(),
            JobCodeId: 1
        };
        return timeClockEntryModel;
    }

    getTimeClockEntries(fromDate, toDate): Observable<TimeClockEntry[]> {
        const timeClockEntryDTO =  {
            FromDateTime: fromDate,
            ToDateTime: toDate
        };
        return this.httpService.post<TimeClockEntry[]>(this.timeclockentriesApiBasePath + '/getTimeClockEntries', timeClockEntryDTO);
    }
}
