<pos-edit-wrapper (close)="onCancel(false)" heading="Merge Sales Product">
    <form #mergeProductForm="ngForm" (submit)='submit(mergeProductForm.valid)' autocomplete="off">
        <div class="col-12 pos-modal-body form-horizontal screen-scroll">
            <pos-row>
                <pos-column>
                    <pos-form-field label="Sales Product" [validationMessages]="{required: 'Please select product.'}"
                        inputStyle="col-xs-12 pb-pix-2">
                        <ng-select posInput [items]="salesProducts" bindLabel="Name" placeholder="Select product"
                            appendTo="body" [(ngModel)]="selectedProduct" name="product" [required]="true">
                        </ng-select>
                    </pos-form-field>
                </pos-column>
            </pos-row>
            <pos-row>
                <pos-column>
                    <div class="mt-pix-5 mx-pix-5">Merging to <b>{{productName}} ({{dateAdded| tark_date }})</b></div>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" value="Merge">Merge</button>
            <button type="button" data-dismiss="modal" class="btn-secondary btn-lg btn btn-cancel-modal pos-button"
                (click)="onCancel(false)" value="Cancel">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
