import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GridColumn, TableComponent, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';

import { ActivatedRoute, Router } from '@angular/router';
import { BaseListComponent } from 'src/app/shared/components/base-list.component';
import { KioskChoice } from '../../interfaces/kiosk-choice';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { OrderKioskChoiceService } from '../../services/order-kiosk-choice.service';
import { AlertsService, ApplicationStateService, ExportService, PrintTableService } from 'src/app/shared/services';
import { ModalService, SpinnerService } from 'src/app/shared/components';
import { buttonsDiceOne, cogWhite, deleteWhite, editWhite, plusWhite, printWhite, tabletAlt } from 'src/app/shared/components/icon';
import { AuthenticationService } from 'src/app/shared/auth/services';
import { Levels, Permissions, RuntimeConstants } from 'src/app/shared/constants';

@Component({
  selector: 'pos-kiosk-choices',
  templateUrl: './kiosk-choices.component.html',
  styleUrls: ['./kiosk-choices.component.scss']
})
export class KioskChoicesComponent extends BaseListComponent<KioskChoice> implements OnInit {

  buttons: Array<KioskChoice> = [];
  gridColumns: Array<GridColumn> = [];
  printScreenColumns: Array<GridColumn> = [];
  icons = {
    cogWhite, deleteWhite, editWhite, plusWhite, printWhite, tabletAlt, buttonsDiceOne
  }
  imageRootPath = RuntimeConstants.IMAGE_BASE_PATH + "/order-kiosk/buttons/";
  
  @ViewChild('nameTemplate', { static: true }) nameTemplate: TemplateRef<any>;
  @ViewChild('operationTemplate', { static: true }) operationTemplate: TemplateRef<any>;
  @ViewChild('operationHeaderTemplate', { static: true }) operationHeaderTemplate: TemplateRef<any>;
  @ViewChild('kioskChoices', { static: true }) kioskChoices: TableComponent;

  constructor(private orderKioskChoiceService: OrderKioskChoiceService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected applicationStateService: ApplicationStateService,
    protected printService: PrintTableService,
    protected route: ActivatedRoute,
    private exportService: ExportService,
    private authenticationService: AuthenticationService,
    private router: Router) {
    super(orderKioskChoiceService, alertService, spinnerService, modalService, applicationStateService, printService, route);
  }

  ngOnInit() {
    this.loadData();
    this.gridColumns = this.configureGridColumns();
    this.deleteSuccessMessage = Messages.KioskChoiceDeleteSuccess;
    this.kioskChoices.context = this.gridContext;
  }

  loadData() {
    this.data$.subscribe({
      next: (response) => {
        this.buttons = response;
      }
    });
  }

  getGridColumns(): GridColumn[] {
    
    const nameTemplate = new TemplateColumn({
      HeaderText: 'Button Text', Field: 'ButtonText', Width: '60%',
      itemTemplate: this.nameTemplate, IsSortable: true
    })

    const columns = [
      nameTemplate,
      new GridColumn({ HeaderText: 'Type', Field: 'ButtonType', Width: '30%', IsSortable: true }),
    ];

    this.printScreenColumns = [...columns];
    this.checkPermission(columns);
    return [
      ...columns
    ] as GridColumn[];
  }


  private checkPermission(columns): void {
    if (this.authenticationService.userHasPermission([{ Name: Permissions.OrderKioskButtons, Level: Levels.Edit }], 'any')) {
      const operationColumn = new TemplateColumn({
        itemTemplate: this.operationTemplate,
        headerTemplate: this.operationHeaderTemplate,
        Width: '160px',
        TextAlign: TextAlign.Center,
        CellClass: 'cell-padding text-center'
      });
  
      columns.push(operationColumn);
    }
  }

  configureGridColumns() {
    return this.getGridColumns();
  }

  editItem(id: number, data?: KioskChoice): void {
    this.router.navigate([id], { relativeTo: this.route });
  }

  getConfirmDeleteMessage(data: KioskChoice): string {
    return Messages.KioskChoiceDeleteConfirm;
  }

  exportCSV() {
    this.exportService.exportCSV('kiosk-buttons', this.buttons, null, this.printScreenColumns);
  }
  
  protected printData(): void {
    this.printService.printEmitter.next({ gridColumns: this.printScreenColumns, gridData: this.buttons });
  }

  cancel() {
    this.router.navigate(['/manage/order-kiosk']);
  }

}
