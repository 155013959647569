import { DeleteColumn, GridColumn, LinkColumn, EditColumn } from '@tarktech/tark-ng-utils';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AlertsService, SpinnerService, ConfirmDeleteComponent, PrintTableService,
  BaseGridComponent, ApplicationStateService, Levels, Permissions, AuthenticationService
} from 'src/app/shared';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { TaskService } from 'src/app/shared/services/task.service';
import { Task } from '../../interface/task';
import { TaskEditComponent } from '../task-edit/task-edit.component';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { ActivatedRoute, Router } from '@angular/router';
import { checkDouble } from 'src/app/shared/components/icon';

@Component({
  selector: 'pos-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss']
})
export class TaskListComponent extends BaseGridComponent implements OnInit {
  @ViewChild('confirmButtonDelete') confirmButtonDelete: ConfirmDeleteComponent;
  public taskList: Task[] = [];
  taskId: number;
  public message = Messages.ConfirmDeleteTask;
  orderByField = 'taskname';
  reverse = false;
  taskColumns: Array<GridColumn> = [];
  printTaskColumns: Array<GridColumn> = [];
  public modalRef: any;
  icons = { checkDouble };
  permission = {
    name: Permissions.OperationsTasks,
    editLevel: Levels.Edit,
  };
  constructor(
    private alertService: AlertsService,
    private taskService: TaskService,
    private spinnerService: SpinnerService,
    private modalService: ModalService,
    protected applicationStateService: ApplicationStateService,
    protected route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private printService: PrintTableService,
    private router: Router,
  ) {
    super(applicationStateService, route);
    this.loadTaskList();
  }

  public ngOnInit() {
    this.configureGridColumns();
  }

  public loadTaskList() {
    this.spinnerService.show();
    this.taskService.getTaskList().subscribe(
      response => {
        if (response) {
          this.taskList = response;
        } else {
          this.taskList = [];
        }
      },
      this.alertService.showApiError,
      () => {
        this.spinnerService.hide();
      }
    );
  }

  public editTask(id: number) {
    const modalRef = this.modalService.show(TaskEditComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        taskId: id
      }
    });

    modalRef.close.subscribe(res => {
      this.loadTaskList();
    });
  }

  deleteTask(id: number) {
    this.taskId = id;
    this.confirmButtonDelete.Show(Messages.ConfirmDeleteTask);
  }

  onConfirmAcknowledge() {
    this.spinnerService.show();
    this.taskService.deleteTask(this.taskId).subscribe(
      () => {

        this.alertService.renderSuccessMessage(Messages.TaskDeleteSuccess);
        this.loadTaskList();
        this.spinnerService.hide();
      },
      this.alertService.showApiError,
      () => {
        this.spinnerService.hide();
      }
    );
  }

  private configureGridColumns() {
    const deleteColumn = new DeleteColumn({
      Clicked: (data) => {
        this.deleteTask(data.id);
      },
      Width: '83px',
      PrintIcon: new LinkColumn({
        Clicked: () => {
          this.printData();
        }
      })
    });

    const editColumn = new EditColumn({
      Clicked: (data) => {
        this.editTask(data.id);
      },
      Width: '57px',
      AddIcon: new LinkColumn({
        Clicked: () => {
          this.editTask(0);
        },
        ToolTip: 'New'
      })
    });

    this.taskColumns = [
      new GridColumn({ HeaderText: 'Message', Field: 'taskname', Width: '95%' })
    ] as Array<GridColumn>;

    this.printTaskColumns = [...this.taskColumns];

    if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      this.taskColumns.push(editColumn);
      this.taskColumns.push(deleteColumn);
    }
  }

  setOrder(value: string) {
    if (this.orderByField === value) {
      this.reverse = !this.reverse;
    }
    this.orderByField = value;
  }

  printData() {
    this.printService.printEmitter.next({ gridColumns: this.printTaskColumns, gridData: this.taskList });
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
