import { HttpClient } from '@angular/common/http';
import { TrainingTopicLevel } from '../../information-management/training-topic-levels/interface/training-topic-level';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { TrainingTopicService } from './training-topic.service';

@Injectable()
export class TrainingTopicLevelService {

    constructor(private httpService: HttpClient, private trainingTopicService: TrainingTopicService) { }

    newTrainingTopicLevel() {
        const trainingTopicLevelObj: TrainingTopicLevel = {
            Id: 0,
            LevelNumber: 0,
            TrainingTopicId: null,
            Description: '',
            PrerequisiteText: '',
            VideoUrl: '',
            LevelScore: null,
            TrainingTopic: this.trainingTopicService.newTrainingTopic(),
            DateAdded: new Date(),
            DateEdited: null,
            SuccessionLimitDelayMinutes: null
        };
        return trainingTopicLevelObj;
    }

    getTrainingTopicLevelsByTopicId(topicId): Observable<TrainingTopicLevel[]> {
        return this.httpService.get<TrainingTopicLevel[]>(
            `${RuntimeConstants.API_PATH}trainingtopiclevel/` + topicId + `/levels`
        );
    }
    getTrainingTopicLevel(trainingTopicLevelId: number): Observable<any> {
        return this.httpService.get(
            `${RuntimeConstants.API_PATH}trainingtopiclevel/` + trainingTopicLevelId
        );
    }

    saveTrainingTopicLevel(trainingTopicLevel: TrainingTopicLevel, userId: number): Observable<any> {
        return this.httpService.post(
            `${RuntimeConstants.API_PATH}trainingtopiclevel/` + userId,
            trainingTopicLevel
        );
    }

    updateTrainingTopicLevel(trainingTopicLevel: TrainingTopicLevel, userId: number): Observable<any> {
        return this.httpService.put(
            `${RuntimeConstants.API_PATH}trainingtopiclevel/` + userId,
            trainingTopicLevel
        );
    }

    deleteTrainingTopicLevel(trainingTopicLevelId: number, userId: number): Observable<any> {
        return this.httpService.delete(
            `${RuntimeConstants.API_PATH}trainingtopiclevel/` + trainingTopicLevelId + '/' + userId
        );
    }

    updateLevelNumber(trainingTopicLevels: Array<TrainingTopicLevel>): Observable<any> {
        return this.httpService.put(
            `${RuntimeConstants.API_PATH}trainingtopiclevel/updatelevelnumber`,
            trainingTopicLevels
        );
    }

    moveTrainingLevel(trainingTopicId: number, trainingLevelId: number): Observable<any> {
        return this.httpService.put(
          `${RuntimeConstants.API_PATH}trainingtopiclevel/movelevel/` + trainingTopicId + `/` + trainingLevelId, null
        );
      }
}
