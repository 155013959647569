import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrintCheckComponent } from './components';
import { FormsModule } from '@angular/forms';
import { TableModule } from '@tarktech/tark-ng-utils';
import { ComponentsModule } from '../shared/components/components.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TableModule,
    ComponentsModule
  ],
  declarations: [ PrintCheckComponent ],
})
export class PrintCheckModule { }
