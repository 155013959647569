import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pos-column',
  templateUrl: './column.component.html',
})
export class ColumnComponent implements OnInit {
  @Input() xxl: number;
  @Input() xl: number;
  @Input() lg: number;
  @Input() md: number;
  @Input() sm: number;
  @Input() xs: number;
  colClass = '';
  colStyleClass = '';
  @HostBinding('class') get columnClass() {
    return this.colClass + ' ' + this.colStyleClass;
  };

  ngOnInit(): void {
    this.colClass = this.prepareGridClass(this.xxl, this.xl, this.lg, this.md, this.sm, this.xs);
  }

  setClass(colClass: string, styleClass: string) {
    this.colClass = this.colClass.trim() ? this.colClass : colClass;
    this.colStyleClass = styleClass;
  }

  prepareGridClass(xxl: number, xl: number, lg: number, md: number, sm: number, xs: number) {
    return [xxl ? `col-xxl-${xxl}` : '',
    xl ? `col-xl-${xl}` : '',
    lg ? `col-lg-${lg}` : '',
    md ? `col-md-${md}` : '',
    sm ? `col-sm-${sm}` : '',
    xs ? `col-${xs}` : ''].filter(x => x).join(' ');
  }
}
