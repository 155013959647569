import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts/highcharts.src';
import { DashboardWidgetsService } from '../adf/services/dashboard-widgets.service';
import * as _ from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import * as moment from 'moment';
import { ApplicationStateService, ColorUtilityService, DomainConstants } from 'src/app/shared';

import HighchartsMore from 'highcharts/highcharts-more.src';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import { GridColumn } from '@tarktech/tark-ng-utils';
import { DashboardConfigurationService } from '../shared/services/dashboard-configuration.service';
import { ChartWidgetConfiguration, GaugeWidgetConfiguration, TableWidgetConfiguration, TextWidgetConfiguration } from '../shared/interface/widget-configuration';
import { DashboardConfiguration } from '../shared/interface/dashboard-configuration';
import { ActivatedRoute } from '@angular/router';

HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);

@Component({
  selector: 'pos-management-dashboard',
  templateUrl: './management-dashboard.component.html',
  styleUrls: ['./management-dashboard.component.scss']
})
export class ManagementDashboardComponent implements OnInit {

  highcharts = Highcharts;
  chartData;
  gaugeData;
  tableData;
  textData;
  chartConfig: ChartWidgetConfiguration;
  gaugeConfig: GaugeWidgetConfiguration;
  tableConfig: TableWidgetConfiguration;
  textConfig: TextWidgetConfiguration;
  tableColumns: Array<GridColumn> = [];

  transParam: any;
  terminalId: number;
  zoneData = [];

  chartOptions: Highcharts.Options;
  isSplineChart: boolean;
  gaugeChartOptions: Highcharts.Options;

  color = '';
  bgColor = '';
  borderColor = '';

  constructor(private dashboardWidgetsService: DashboardWidgetsService,
    private dashboardService: DashboardConfigurationService,
    private colorConversionService: ColorUtilityService,
    private applicationStateService: ApplicationStateService,
    private route: ActivatedRoute) {
    this.transParam = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
    this.terminalId = this.transParam.terminalId;
  }

  ngOnInit(): void {
    const observable: Array<Observable<any>> = [];
    observable.push(this.dashboardWidgetsService.getChartData([{ Name: 'sproc', Value: 'usp_GetStatHourlyOrderCount' }], 'GetTabularValue'));
    observable.push(this.dashboardWidgetsService.getChartData([{ Name: 'sproc', Value: 'usp_GetStatDueTasks' }], 'GetScalarValue'));
    observable.push(this.dashboardWidgetsService.getChartData([{ Name: 'sproc', Value: 'usp_GetStatEquipmentTemperatures' }], 'GetTabularValue'));
    observable.push(this.dashboardWidgetsService.getChartData([{ Name: 'sproc', Value: 'usp_GetStatDueTasks' }], 'GetScalarValue'));
    observable.push(this.dashboardService.getDashboardConfigurationsByTerminal(this.terminalId));

    forkJoin(observable)
      .subscribe({
        next: ([chartData, gaugeData, tableData, textData, config]: [any, any, any, any, DashboardConfiguration]) => {
          this.chartData = chartData;
          this.gaugeData = gaugeData;
          this.tableData = tableData;
          this.textData = textData;
  
          const chartConfig: any = _.find(config, (x: any) => x.WidgetKey === 'chart_widget');
          this.chartConfig = this.dashboardService.jsonData(chartConfig.Configuration);
  
          const gaugeConfig: any = _.find(config, (x: any) => x.WidgetKey === 'gauge_widget');
          this.gaugeConfig = this.dashboardService.jsonData(gaugeConfig.Configuration);
  
          const tableConfig: any = _.find(config, (x: any) => x.WidgetKey === 'table_widget');
          this.tableConfig = this.dashboardService.jsonData(tableConfig.Configuration);
  
          const textConfig: any = _.find(config, (x: any) => x.WidgetKey === 'text_widget');
          this.textConfig = this.dashboardService.jsonData(textConfig.Configuration);
  
          if (this.chartData && this.chartData.length > 0) {
            this.isSplineChart = this.chartConfig.chartType === 'line' && this.isDateFormat(Object.values(this.chartData[0])[1]);
            this.populateChart();
          }
          if (this.gaugeData) {
            this.populateGauge();
          }
          if (this.tableData && this.tableData.length > 0) {
            this.populateTable();
          }
  
          if (this.textConfig) {
            let colors = this.textConfig.colorConditions;
            if (colors && colors.length > 0) {
              colors = _.orderBy(colors, '-value');
  
              _.forEach(colors, (color) => {
                if (color && this.compare(this.textData, color.operator, color.value)) {
                  this.color = color.color;
                  this.bgColor = color.bgColor;
                  this.borderColor = color.borderColor;
                  if (this.bgColor && !this.color) {
                    this.color = this.colorConversionService.getContrastColor(this.bgColor);
                  }
                  return false;
                } else {
                  this.color = this.textConfig.color;
                  this.bgColor = this.textConfig.bgColor;
                  this.borderColor = this.textConfig.borderColor;
                }
              });
            }
  
          }
        }
      });
  }

  populateChart() {
    this.color = this.chartConfig.color;
    this.bgColor = this.chartConfig.bgColor;
    this.borderColor = this.chartConfig.borderColor;

    if (this.bgColor && !this.color) {
      this.color = this.colorConversionService.getContrastColor(this.bgColor);
    }

    const xTitle = Object.keys(this.chartData[0])[1];
    const yTitle = Object.keys(this.chartData[0])[0];
    const values = [];
    _.forEach(this.chartData, (item) => {
      if (!(parseFloat(item[yTitle]) < 0)) {
        values.push([item[xTitle], item[yTitle]]);
      }
    });
    this.chartOptions = {
      chart: {
        type: this.isSplineChart ? 'spline' : this.chartConfig.chartType,
        backgroundColor: this.bgColor,
        style: {
          color: this.bgColor ? this.colorConversionService.getContrastColor(this.bgColor) : ''
        }
      },
      title: {
        text: this.chartConfig.metricsName,
        style: {
          color: this.bgColor ? this.colorConversionService.getContrastColor(this.bgColor) : ''
        }
      },
      series: [
        {
          type: this.isSplineChart ? 'spline' : this.chartConfig.chartType,
          data: values,
          showInLegend: false,
          name: this.dashboardService.splitCamelCaseToString(yTitle),
          zoneAxis: 'x',
          zones: this.zoneData
        }
      ],
      xAxis: {
        type: this.isSplineChart ? 'datetime' : 'category',
        title: {
          text: this.dashboardService.splitCamelCaseToString(xTitle),
          style: {
            color: this.color
          }
        },
        labels: {
          style: {
            color: this.color
          },
          // formatter: () => {
          //   return this.isSplineChart ? moment(this.value).format("MM/DD/YYYY") : this.value;
          // }
        },
      },
      yAxis: {
        title: {
          text: this.dashboardService.splitCamelCaseToString(yTitle),
          style: {
            color: this.color
          }
        },
        allowDecimals: false,
        labels: {
          style: {
            color: this.color
          }
        }
      }
    };

    if (this.isSplineChart) {
      this.chartConfig.chart['plotOptions'] = {
        spline: {
          marker: {
            enabled: true
          }
        }
      };

      this.chartConfig.chart['tooltip'] = {
        xDateFormat: '%m/%d/%y'
      };
    }
  }

  populateGauge() {
    const currencySymbol = this.applicationStateService.settingParam.CurrencySymbol ?? '$';
    this.color = this.gaugeConfig.color;
    this.bgColor = this.gaugeConfig.bgColor;
    this.borderColor = this.gaugeConfig.borderColor;
    if (this.bgColor) {
      this.color = this.colorConversionService.getContrastColor(this.bgColor);
    }
    this.gaugeChartOptions = {
      chart: {
        type: this.gaugeConfig.gaugeType,
        backgroundColor: this.bgColor,
        height: '300'
      },
      title: null,
      series: [
        {
          type: this.gaugeConfig.gaugeType,
          data: [this.dashboardService.parseByDataType(this.gaugeData, this.gaugeConfig.outputType)],
          dataLabels: {
            format: '<div style="text-align:center"><span style="font-size:25px;color:' +
              ((this.color || Highcharts.theme && Highcharts.theme.colors) || 'black') + '">' +
              ((this.gaugeConfig.unit) === currencySymbol ? currencySymbol : '')
              + '{y} ' + (this.gaugeConfig.unit && this.gaugeConfig.unit !== currencySymbol ? this.gaugeConfig.unit : '') + '</span></div>'
          }
        }
      ],
      pane: {
        startAngle: -90,
        endAngle: 90,
        background: [{
          backgroundColor: '#EEE',
          innerRadius: '60%',
          outerRadius: '100%',
          shape: 'arc'
        }]
      },
      yAxis: {
        // tslint:disable-next-line: radix
        min: isNaN(parseInt(this.gaugeConfig.minValue)) ? 0 : parseInt(this.gaugeConfig.minValue),
        // tslint:disable-next-line: radix
        max: isNaN(parseInt(this.gaugeConfig.maxValue)) ? 100 : parseInt(this.gaugeConfig.maxValue),
        stops: [
          [0.1, '#DF5353'], // red 
          [0.5, '#DDDF0D'], // yellow
          [0.9, '#55BF3B'] // green
        ],
        lineWidth: 0,
        minorTickInterval: null,
        tickAmount: 2,
        labels: {
          y: 16,
          style: {
            color: this.color
          }
        }
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: 5,
            borderWidth: 0,
            useHTML: true
          }
        }
      },
    };
  }

  populateTable() {
    const columns = [];
    _.forEach(Object.keys(this.tableData[0]), (item) => {
      if (!item.toLowerCase().startsWith('meta_')) {
        columns.push(item);
      }
    });
    _.forEach(columns, (column) => {
      this.tableColumns.push(new GridColumn({ HeaderText: column, Field: column, IsSortable: true }));
    });
    _.forEach(this.tableData, (item) => {
      const metaColor = _.find(Object.keys(item), x => x.toLowerCase().startsWith('meta_'));
      if (item[metaColor]) {
        item.FontColor = this.colorConversionService.getContrastColor(item[metaColor]);
      }
    });
  }

  prepareZones(chartData) {
    chartData.forEach(element => {
      this.zoneData.push({ value: Date.parse(element.Date), color: element.Color });
    });
  }

  isDateFormat(str) {

    const date = moment(str, 'M/D/YYYY');

    if (date == null || !date.isValid()) {
      return false;
    }

    return date != null && date.isValid() && (str.indexOf(date.format('M/D/YYYY')) >= 0
      || str.indexOf(date.format('MM/DD/YYYY')) >= 0
      || str.indexOf(date.format('M/D/YY')) >= 0
      || str.indexOf(date.format('MM/DD/YY')) >= 0);
  }

  compare(a, op, b) {
    const objOperators = DomainConstants.DashboardWidgetSelectOptions['Operators'];
    const operators = Object.keys(objOperators).map(key => objOperators[key].value);
    if (operators.indexOf(op) === -1) {
      return false;
    }

    // tslint:disable-next-line: no-eval
    if (a !== '' && eval(a + op + b)) {
      return true;
    } else {
      return false;
    }

  }

}
