import { AlertsService, ApplicationStateService, FormUtilityService } from 'src/app/shared/services';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SpinnerService, Messages, Permissions, Levels, AuthenticationService, DomainConstants, BaseFormComponent, ModalService } from 'src/app/shared';
import { OnlineOrderingConfigurationsService } from '../../service/online-ordering-configurations.service';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { check, cogWhite, cogs, paymentsMoneyBillAlt, tools } from 'src/app/shared/components/icon';
import { OrderFulfillmentModel } from 'src/app/manage-console/automated-exports/interface/parameter-model';
import { OrderProductWorkflowService } from 'src/app/manage-console/online-order/service/order-product-workflow.service';
import { Observable, finalize, forkJoin } from 'rxjs';
import { GooglePayConfigurationComponent } from '../google-pay-configuration/google-pay-configuration.component';
import { ApplePayConfigurationComponent } from '../apple-pay-configuration/apple-pay-configuration.component';
import { OnlineOrderConfiguration } from '../../interface/online-ordering-configuration';
import { GooglePayConfiguration } from '../../interface/google-pay-configuration';
import { ApplePayConfiguration } from '../../interface/apple-pay-configuration';

@Component({
  selector: 'pos-online-ordering-configurations',
  templateUrl: './online-ordering-configurations.component.html',
  styleUrls: ['./online-ordering-configurations.component.scss']
})
export class OnlineOrderingConfigurationsComponent extends BaseFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.orderingConfigurationForm;
  }
  orderFulfillmentType: Array<OrderFulfillmentModel> = [];
  offSeasonDeliveryWorkflow = [];
  @ViewChild('orderingConfigurationForm') orderingConfigurationForm: NgForm;
  orderingConfiguration: any;
  tipType = DomainConstants.TipType;
  permission = {
    name: Permissions.OnlineOrderingOptionConfiguration,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };
  permissionDenied = false;
  orderPaymentType: Array<any> = [];
  currencySymbol = null;

  orderPaymentModes: Array<any> = [];
  OrderFulfillments = []
  icons = { tools, paymentsMoneyBillAlt, cogs, cogWhite, check }
  isDebitCreditPaymentOption = true;
  isAllowTip = false;
  props = {
    controlBoxClass: 'col-lg-3 col-md-4 col-12'
  }

  tabList = {
    General: 'General',
    Payment: 'Payment',
  }

  selectedTab = this.tabList.General;
  googlePayConfiguration: GooglePayConfiguration;
  applePayConfiguration: ApplePayConfiguration;
  merchantIdentityCertificate: File;

  constructor(private alertService: AlertsService,
    private orderProductWorkflowService: OrderProductWorkflowService,
    private spinnerService: SpinnerService,
    protected applicationStateService: ApplicationStateService,
    protected route: ActivatedRoute,
    private router: Router,
    private onlineOrderingConfigurationsService: OnlineOrderingConfigurationsService,
    private authenticationService: AuthenticationService,
    protected modalService: ModalService,
    formUtilityService: FormUtilityService
  ) { super(formUtilityService); }

  ngOnInit() {
    this.getConfigurationDetails();
    this.orderingConfiguration = {};
    if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      this.permissionDenied = true;
    }
    this.googlePayConfiguration = this.onlineOrderingConfigurationsService.newGooglePayConfiguration();
    this.applePayConfiguration = this.onlineOrderingConfigurationsService.newApplePayConfiguration()
    this.orderPaymentType = DomainConstants.OnlineOrderPaymentTypes;
    this.orderPaymentModes = DomainConstants.OnlineOrderPaymentModes;
    this.currencySymbol = this.applicationStateService.settingParam.CurrencySymbol;

  }

  onCancel() {
    this.router.navigate(['manage/app-configuration']);
  }

  getConfigurationDetails() {
    const observables: Array<Observable<object>> = [];
    this.spinnerService.show();
    observables.push(this.onlineOrderingConfigurationsService.getOnlineOrderingConfigurations())
    observables.push(this.orderProductWorkflowService.getWorkflowIdentifiers())
    forkJoin(observables)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: ([config, workflow]: [OnlineOrderConfiguration, Array<OrderFulfillmentModel>]) => {
          this.orderFulfillmentType = workflow;
          this.orderFulfillmentType.forEach(x => {
            x.Label = x.Name + ' (' + x.DeliveryType + ')'
          });
          this.orderFulfillmentType.push({ Name: 'Pickup', Value: 'Pickup', DeliveryType: 'Pickup', Label: 'Pickup'})
          this.googlePayConfiguration = config.GooglePayConfiguration ? JSON.parse(config.GooglePayConfiguration) :  this.onlineOrderingConfigurationsService.newGooglePayConfiguration();
          this.applePayConfiguration = config.ApplePayConfiguration ? JSON.parse(config.ApplePayConfiguration) :  this.onlineOrderingConfigurationsService.newApplePayConfiguration();
          this.orderingConfiguration = config;
          if (this.orderingConfiguration.OnlineOrderOffSeasonDeliveryWorkflow) {
            this.offSeasonDeliveryWorkflow = this.orderingConfiguration.OnlineOrderOffSeasonDeliveryWorkflow.split(",");
          }
        }, error: this.alertService.showApiError
      });
  }

  saveOrderingConfigurations(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.spinnerService.show();
    this.orderingConfiguration.GooglePayConfiguration = JSON.stringify(this.googlePayConfiguration);
    this.orderingConfiguration.ApplePayConfiguration = JSON.stringify(this.applePayConfiguration);
    this.orderingConfiguration.AllowGooglePayPayment = this.orderingConfiguration.AllowGooglePayPayment && this.googlePayConfiguration.IsValid;
    this.orderingConfiguration.AllowApplePayPayment = this.orderingConfiguration.AllowApplePayPayment && this.applePayConfiguration.IsValid;
    this.orderingConfiguration.OnlineOrderOffSeasonDeliveryWorkflow = '';
    this.orderingConfiguration.OnlineOrderOffSeasonDeliveryWorkflow += this.offSeasonDeliveryWorkflow.join(",");
    this.onlineOrderingConfigurationsService.saveOnlineOrderingConfigurations(this.orderingConfiguration, this.merchantIdentityCertificate).subscribe(
      () => {
        this.alertService.renderSuccessMessage(Messages.OnlineOrderingConfigurationSaveSuccess);
        this.router.navigate(['/manage/app-configuration']);
      },
      this.alertService.showApiError,
      () => {
        this.spinnerService.hide();
      }
    );

  }

  changePaymentType = () => {
    if (this.orderingConfiguration.PaymentTypes == 'Paypal') {
      this.orderingConfiguration.PaymentMode = 'AuthorizeWithCapture'
    }
  }

  configureGooglePay() {
    const modalRef = this.modalService.show(GooglePayConfigurationComponent, {
      class: 'vertical-center modal-lg',
      initialState: {
        configuration: {...this.googlePayConfiguration}
      }
    });

    modalRef.close.subscribe(res => {
      if (res?.IsValid) {
        this.googlePayConfiguration = res;
      }
    });
  }

  configureApplePay() {
    const modalRef = this.modalService.show(ApplePayConfigurationComponent, {
      class: 'vertical-center modal-max-width-70',
      initialState: {
        configuration: {...this.applePayConfiguration}
      }
    });

    modalRef.close.subscribe(res => {
      if (res?.isValid) {
        this.applePayConfiguration = res.configuration;
        this.merchantIdentityCertificate = res.merchantIdentityCertificate;
      }
    });
  }

}
