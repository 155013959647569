import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HangfireJobsComponent } from './components/hangfire-jobs.component';
import { FormsModule } from '@angular/forms';
import { TableModule } from '@tarktech/tark-ng-utils';
import { TableConfiguration } from '../shared/table/table-configuration';
import { HangfireJobsService } from './services';
import { ComponentsModule } from '../shared/components/components.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { Route, RouterModule } from '@angular/router';

const routes: Array<Route> = [{
  path: '',
  component: HangfireJobsComponent,
  data: {
    // permissions: [{ 'Name': Permissions.ManageHangFireJobs, 'Level': Levels.Access }]
  }
}];

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    NgSelectModule,
    TableModule.forRoot(TableConfiguration),
    RouterModule.forChild(routes)
  ],
  providers: [HangfireJobsService],
  declarations: [HangfireJobsComponent]

})
export class HangfireJobsModule { }
