<div class="row mx-0">
    <div class="col-4 p-pix-2">
        <div class="btn-cal" (click)="calculatePriceClosingDrawer('1')">1
        </div>
    </div>
    <div class="col-4 p-pix-2">
        <div class="btn-cal" (click)="calculatePriceClosingDrawer('2')">2
        </div>
    </div>
    <div class="col-4 p-pix-2">
        <div class="btn-cal" (click)="calculatePriceClosingDrawer('3')">3
        </div>
    </div>
</div>
<div class="row mx-0">
    <div class="col-4 p-pix-2">
        <div class="btn-cal" (click)="calculatePriceClosingDrawer('4')">4
        </div>
    </div>
    <div class="col-4 p-pix-2">
        <div class="btn-cal" (click)="calculatePriceClosingDrawer('5')">5
        </div>
    </div>
    <div class="col-4 p-pix-2">
        <div class="btn-cal" (click)="calculatePriceClosingDrawer('6')">6
        </div>
    </div>
</div>
<div class="row mx-0">
    <div class="col-4 p-pix-2">
        <div class="btn-cal" (click)="calculatePriceClosingDrawer('7')">7
        </div>
    </div>
    <div class="col-4 p-pix-2">
        <div class="btn-cal" (click)="calculatePriceClosingDrawer('8')">8
        </div>
    </div>
    <div class="col-4 p-pix-2">
        <div class="btn-cal" (click)="calculatePriceClosingDrawer('9')">9
        </div>
    </div>
</div>
<div class="row mx-0">
    <div class="col-4 p-pix-2">
        <div class="btn-cal" (click)="clearPrice()">Del</div>
    </div>
    <div class="col-4 p-pix-2">
        <div class="btn-cal" (click)="calculatePriceClosingDrawer('0')">0
        </div>
    </div>
    <div class="col-4 p-pix-2" *ngIf="isShowSubmitButton">
        <div class="btn-cal text-ellipsis" (click)="submit()">
            {{ enterButtonText }}</div>
    </div>
</div>
