import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Terminal } from 'src/app/configurator/terminals';
import { DomainConstants } from 'src/app/shared/constants';
import { EventSubscriptionActionAttribute } from '../../interfaces/event-subscription-action-attribute';
import { messageCode } from '../../../../shared/components/icon';
import { filter, find } from 'lodash';
import { Options } from '@angular-slider/ngx-slider';
import { ModalFormComponent, ModalService } from 'src/app/shared/components';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormUtilityService } from 'src/app/shared';
import { RepeatActionConfigurationComponent } from '../repeat-action-configuration';

@Component({
  selector: 'pos-dj-configuration',
  templateUrl: './dj-configuration.component.html',
  styleUrls: ['./dj-configuration.component.scss']
})
export class DjConfigurationComponent extends ModalFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.djActivityEditForm;
  }
  @ViewChild('RepeatActionConfiguration') repeatActionConfiguration: RepeatActionConfigurationComponent;
  @Input() actionId = 0;
  @Input() eventSubscriptionActionAttributes: Array<EventSubscriptionActionAttribute> = [];
  @Input() deliveryTypes = [];
  @Input() eventAttributes: Array<string> = [];
  @Input() eventIdentifier: string;
  @Input() audioText: string;
  @Input() audioVoices: Array<string> = [];
  @Input() terminals: Array<Terminal> = [];
  @Input() selectedDeliveryType;
  @Input() isRecoverableEvent;
  @ViewChild('djActivityEditForm') djActivityEditForm: NgForm;
  public icons = {
    messageCode
  };

  selectedTerminals: Array<number> = [];
  audioTextString = '';
  selectedAudioVoice: string = null;
  volume = 1;

  cursorPosition = 0;
  isOpenVariable = false;
  selectedEventAttribute: any;
  deliveryAttributes = DomainConstants.EventDeliveryAttributes;
  options: Options = {
    floor: 1,
    ceil: 100,
    translate: (value: number): string => {
      return '';
    }
  };

  constructor(modalRef: BsModalRef, modalService: ModalService, protected formUtilityService: FormUtilityService) {
    super(modalRef, modalService, formUtilityService);
  }
  ngOnInit(): void {
    this.terminals = filter(this.terminals, (terminal) => {
      return terminal.TerminalType === DomainConstants.TerminalTypes.DJ.Name || !terminal.TerminalId;
    });
    const selectedTerminals = find(this.eventSubscriptionActionAttributes,
      x => x.AttributeKey === this.deliveryAttributes.DJ.Terminals)?.AttributeValue.split(',').map(x => parseInt(x, 10));
    this.selectedTerminals = selectedTerminals ?? null;
    this.selectedAudioVoice = find(this.eventSubscriptionActionAttributes,
      x => x.AttributeKey === this.deliveryAttributes.DJ.SpeakVoiceId)?.AttributeValue;
    this.audioTextString = find(this.eventSubscriptionActionAttributes,
      x => x.AttributeKey === this.deliveryAttributes.DJ.SpeakText)?.AttributeValue ?? '';
    const volume = parseInt(find(this.eventSubscriptionActionAttributes,
      x => x.AttributeKey === this.deliveryAttributes.DJ.AudioVolume)?.AttributeValue, 10);
    this.volume = isNaN(volume) ? 1 : volume;
  }

  saveActivity = (isValid: boolean) => {
    if (isValid) {
      const actionAttributes = this.prepareEventActionAttributes();
      this.hide({ shouldReload: true, actionAttributes: actionAttributes });
    }
  }

  getCaretPos(speakText) {
    if (speakText.selectionStart || speakText.selectionStart === '0') {
      this.cursorPosition = speakText.selectionStart;
    }
  }

  changeEventAttribute() {
    this.isOpenVariable = false;
    this.audioTextString = this.audioTextString.substring(0, this.cursorPosition) + ' {{' + this.selectedEventAttribute + '}} '
      + this.audioTextString.substring(this.cursorPosition);
    this.selectedEventAttribute = null;
  }

  changeSelectedTerminals() {
    if (this.terminals.length > 0) {
      const lastSelectedTerminal = this.selectedTerminals[this.selectedTerminals.length - 1];
      if (lastSelectedTerminal === 0) {
        this.selectedTerminals = this.selectedTerminals.filter(x => x === 0);
      } else {
        this.selectedTerminals = this.selectedTerminals.filter(x => x !== 0);
      }
    }
  }

  prepareEventActionAttributes() {
    const actionDeliveryAttributes: Array<EventSubscriptionActionAttribute> = [];
    this.addAttribute(this.deliveryAttributes.DJ.Terminals, this.selectedTerminals.join(), actionDeliveryAttributes);
    this.addAttribute(this.deliveryAttributes.DJ.SpeakText, this.audioTextString.toString(),
      actionDeliveryAttributes);
    this.addAttribute(this.deliveryAttributes.DJ.SpeakVoiceId, this.selectedAudioVoice.toString(),
      actionDeliveryAttributes);
    this.addAttribute(this.deliveryAttributes.DJ.AudioVolume, this.volume.toString(),
      actionDeliveryAttributes);
      if(this?.repeatActionConfiguration?.repeatConfig) { 
        this.addAttribute(this.deliveryAttributes.DJ.Repeat, this?.repeatActionConfiguration?.isRepeat ? JSON.stringify(this?.repeatActionConfiguration?.repeatConfig) : "", actionDeliveryAttributes)
      }
    return actionDeliveryAttributes;
  }

  addAttribute = (key, value, array) => {
    const actionDeliveryAttribute: EventSubscriptionActionAttribute = {
      Id: this.getActionAttributeId(key),
      AttributeKey: key,
      AttributeValue: value,
      EventSubscriptionActionId: this.actionId
    };
    array.push(actionDeliveryAttribute);
  }

  getActionAttributeId(key) {
    const attribute = find(this.eventSubscriptionActionAttributes, x => x.AttributeKey === key);
    return attribute ? attribute.Id : 0;
  }

  onClose = (shouldReload: boolean) => {
    this.hide({ shouldReload: shouldReload });
  }
}
