import { Injectable } from "@angular/core";
import { IKioskNavigation } from "../interfaces/ikiosk-navigation";
import { ChoiceBehavior } from "./choices-behavior";
import { AlertsService } from "src/app/shared";
import { BehaviorPromiseProviderService, KioskNavigationService, OrderKioskChoiceBehaviorService } from "../services";
import { KioskBehaviorExecution } from "./kiosk-behavior-execution";

@Injectable({
    providedIn: 'root'
})
export class AddCommentToAllProductBehavior extends ChoiceBehavior {

    constructor(private kioskNavigationService: KioskNavigationService,
        private orderKioskChoiceBehaviorService: OrderKioskChoiceBehaviorService,
        private alertsService: AlertsService,
        private behaviorPromiseProviderService: BehaviorPromiseProviderService
    ) {
        super();
    }

    execute(navObj: IKioskNavigation, orderNavigation: any): Promise<void> {
        navObj.button.IsSelected = false;
        this.addCommentToAllProduct();
        return this.behaviorPromiseProviderService.executionPromise;
    }

    addCommentToAllProduct() {
        const model: KioskBehaviorExecution = {
            OrderId: this.kioskNavigationService.orderDetails?.Id?.Value,
            BehaviorId: this.behaviorId
        };
        this.kioskNavigationService.versionedVal.Obj.Order.Product.Comment = this.behaviorValues.comment;
        if (model.OrderId) {
            this.orderKioskChoiceBehaviorService.executeBehavior(model)
                .subscribe({
                    next: (order: any) => {
                        this.kioskNavigationService.versionedVal.Obj.Order.Id = order.Id.Value;
                        this.kioskNavigationService.orderDetails = order;
                        this.behaviorPromiseProviderService.resolvePromise(order);
                    },
                    error: (e) => {
                        this.behaviorPromiseProviderService.rejectPromise();
                        this.alertsService.showApiError(e);
                    }
                });
        } else {
            this.behaviorPromiseProviderService.resolvePromise();
        }
    }
}

