<pos-edit-wrapper (close)="closePopup()" heading="Prep Recipe: {{inventoryProductName}}">
    <form #productForm="ngForm" (ngSubmit)="submit(productForm.valid)" autocomplete="off">
        <div class="col-12 px-sm-4 px-2 screen-scroll pb-pix-5">
            <pos-row>
                <pos-column>
                    <pos-form-field label="Makes"
                        [validationMessages]="{required: 'Please enter prepare qty.', pattern: 'Only 2 decimal places allow.'}"
                        labelStyle="col-md-2 col-3 pt-pix-7 pe-0" inputStyle="col-9">
                        <pos-row>
                            <pos-column xs="5" class="ps-0">
                                <input posInput class="form-control" name="PrepareQty"
                                    [(ngModel)]="inventoryProductRecipe.PrepareQty" pattern="[0-9]+(.[0-9]{0,2})?$"
                                    #PrepareQty="ngModel" type="number" (keypress)="preventMinusSign($event)"
                                    [required]="true" />
                            </pos-column>
                            <pos-column xs="3" class="p-0">
                                <label class="edit-form__section__label">{{ inventoryProductRecipe.PrepareQty ?
                                    Pluralize(unitName, inventoryProductRecipe.PrepareQty) : unitName }}</label>
                            </pos-column>
                        </pos-row>
                    </pos-form-field>
                </pos-column>
            </pos-row>

            <div class="col-12 col-sm-12">
                <ng-table [sort]="true" [columns]="prepRecipeColumns" [exportFilename]="'prep-recipe'"
                    [data]="inventoryProductRecipes" [paginator]="false">
                </ng-table>
                <ng-template let-data="rowData" #unitTemplate>
                    {{data.UnitName}}(s)
                </ng-template>
            </div>
        </div>
        <div class="col-12 action-button-container popup-footer mt-pix-15">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" value="Save">Save</button>
            <button type="button" data-dismiss="modal" (click)="closePopup()"
                class="btn-secondary btn-lg btn btn-cancel-modal pos-button" value="Close">Close</button>
        </div>
    </form>
</pos-edit-wrapper>
