<div>
    <div>
        <h4 class="modal-content__heading">{{creditCardResponse.header}}</h4>
    </div>
    <div class="">
        <div class="col-12 pt-pix-15 text-center">
            {{creditCardResponse.message}}
        </div>
    </div>
    <div class="col-12 action-button-container popup-footer pb-pix-10">
        <button type="button" *ngIf="!creditCardResponse.isShowCCError" (click)="cancelCreditCardTransaction()"
            class="btn btn-secondary btn-lg btn-cancel-modal pos-button">
            {{creditCardResponse.buttonText}}
        </button>
        <button type="button" *ngIf="creditCardResponse.isShowCCError" (click)="hideOverPaymentModal()"
            class="btn btn-primary btn-lg btn-cancel-modal pos-button">
            Ok
        </button>
    </div>
    <div class="clearfix"></div>
</div>
