import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableComponent, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { GridColumn } from '@tarktech/tark-ng-utils/table/grid-column';
import { filter, remove } from 'lodash';
import { AuthenticationService } from 'src/app/shared/auth/services';
import { BaseListComponent, ModalService, SpinnerService } from 'src/app/shared/components';
import { plusWhite, printWhite, editWhite, deleteWhite, database, squareLightOrangeStack, fileSearch, folderMagnifyingGlass } from 'src/app/shared/components/icon';
import { Permissions, Levels, Messages, DomainConstants } from 'src/app/shared/constants';
import { AlertsService, ApplicationStateService, PrintTableService } from 'src/app/shared/services';
import { StringUtils } from 'src/app/shared/string-utils';
import { DataSource } from '../../interface/data-source';
import { DataSourceService } from '../../service/data-source.service';
import { DataSourcePreviewComponent } from '../data-source-preview/data-source-preview.component';

@Component({
  selector: 'pos-data-source',
  templateUrl: './data-source.component.html',
  styleUrls: ['./data-source.component.scss'],
})
export class DataSourceComponent extends BaseListComponent<DataSource> implements OnInit {
  dataSourceList: Array<DataSource> = [];
  dataSourceColumns: Array<GridColumn> = [];
  printDataSourceColumns: Array<GridColumn> = [];
  private _icons = {
    plusWhite,
    printWhite,
    editWhite,
    deleteWhite,
    database,
    squareLightOrangeStack,
    fileSearch,
    folderMagnifyingGlass
  };
  public get icons() {
    return this._icons;
  }
  public set icons(value) {
    this._icons = value;
  }
  permission = {
    name: Permissions.AutomatedExportsConfiguration,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };

  @ViewChild('operationTemplate', { static: true }) operationTemplate: TemplateRef<any>;
  @ViewChild('operationHeaderTemplate', { static: true }) operationHeaderTemplate: TemplateRef<any>;
  @ViewChild('dataSourceTable', { static: true }) dataSourceTable: TableComponent;

  constructor(private authenticationService: AuthenticationService,
    protected dataSourceService: DataSourceService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    private router: Router,
    protected applicationStateService: ApplicationStateService,
    protected printService: PrintTableService,
    protected route: ActivatedRoute
  ) {
    super(
      dataSourceService,
      alertService,
      spinnerService,
      modalService,
      applicationStateService,
      printService,
      route
    );
  }

  ngOnInit(): void {
    this.dataSourceColumns = this.configureGridColumns();
    this.deleteSuccessMessage = Messages.DataSourceDeleted;
    this.loadSubscriptions();
    this.checkPermission();
    this.dataSourceTable.context = this.gridContext;
  }

  private loadSubscriptions(): void {
    this.data$.subscribe({ next: (res) => {
      this.dataSourceList = filter(res, (dataSource) => !dataSource.IsPrivate);
    }});
  }

  private checkPermission(): void {
    if (!this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      remove(this.dataSourceColumns, (column) => {
        return (column.Type === 'TemplateColumn');
      });
    }
  }

  getGridColumns(): GridColumn[] {
    const operationColumn = new TemplateColumn({
      itemTemplate: this.operationTemplate,
      headerTemplate: this.operationHeaderTemplate,
      Width: '165px',
      TextAlign: TextAlign.Center,
      CellClass: 'cell-padding text-center'
    });
    const columns = [
      new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '60%' }),
      new GridColumn({
        HeaderText: 'Query Type',
        Field: 'QueryType',
        Width: '40%',
        IsSortable: true,
      }),
      operationColumn
    ];
    this.printDataSourceColumns = [...columns];
    return columns;
  }

  editItem(id: number, data?: DataSource): void {
    this.router.navigate([id], { relativeTo: this.route });
  }

  getConfirmDeleteMessage(data: DataSource): string {
    return StringUtils.format(Messages.ConfirmDeleteDataSource, { 'sourceName': data.Name });
  }

  printData() {
    this.printService.printEmitter.next({
      gridColumns: this.printDataSourceColumns,
      gridData: this.dataSourceList,
    });
  }

  preview(data: DataSource) {
        const modelRefUnit = this.modalService.show(DataSourcePreviewComponent, {
            animated: false,
            class: 'vertical-center modal-lg',
            keyboard: false,
            initialState: {
              dataSourceId: data.Id,
              dataSourceName: data.Name
            }
        });
        modelRefUnit.close.subscribe((response) => {
        });
  }

  close() {
    this.router.navigate(['manage/automated-exports/configuration']);
  }

  configureGridColumns() {
    return this.getGridColumns();
  }
}
