import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertsService, FormUtilityService } from 'src/app/shared/services';
import { ExportConnection } from '../../interface';
import { ExportConnectionService } from '../../service';
import { cogs, hardwareInterfacePlug, rssSquare } from 'src/app/shared/components/icon';
import { BaseCrudComponent, SpinnerService } from 'src/app/shared/components';
import { cloneDeep, filter, find, forEach, remove } from 'lodash';
import { DomainConstants, Messages } from 'src/app/shared';
import { finalize } from 'rxjs/operators';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'pos-export-connection-edit',
  templateUrl: './export-connection-edit.component.html',
  styleUrls: ['./export-connection-edit.component.scss'],
})
export class ExportConnectionEditComponent extends BaseCrudComponent<ExportConnection> implements OnInit {

  id = 0;
  exportConnection: ExportConnection;

  tabList = {
    Configuration: 'Configuration',
  };

  icons = {
    cogs,
    rssSquare,
    hardwareInterfacePlug
  };
  selectedTab = this.tabList.Configuration;
  height = window.innerHeight - 420;
  
  connectionTypes: Array<string> = [];
  exportConnectionParameters = DomainConstants.ExportConnectionParameters;
  connectionTypeParameters = [];
  get getForm(): NgForm {
    return this.exportConnectionForm
  }
  @ViewChild('exportConnectionForm') exportConnectionForm: NgForm;

  constructor(
    protected exportConnectionService: ExportConnectionService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    formUtilityService: FormUtilityService
  ) {
    super(exportConnectionService, alertService, spinnerService, formUtilityService);
    this.exportConnection = this.exportConnectionService.newExportConnection();
    this.id = parseInt(route.snapshot.params.id, 10) ?? 0;
  }

  ngOnInit(): void {
    this.saveSuccessMessage = Messages.ExportConnectionSaveSuccess;
    this.dataSubscription();
  }

  private dataSubscription(): void {
    if (this.id > 0) {
      this.spinnerService.show();
      this.loadData()
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({ next: (response) => {
          this.exportConnection = response;
          this.changeConnectionTypeParameters();
          const connectionJson = JSON.parse(this.exportConnection.Configuration);
          forEach(connectionJson, (value, key) => {
            const param = find(this.connectionTypeParameters, x => x.Key === key);
            if (param) {
              param.Value = value;
            }
          });
        }, error: this.alertService.showApiError });
    }
  }


  loadDependencies(): void {
    this.exportConnectionService.getExportTypes().subscribe({ next: (response) => {
      this.connectionTypes = response;
    }});
  }

  submit(isValid: boolean) {
    if (!isValid) {
      return;
    }
    remove(this.connectionTypeParameters, (x) => !x.Value);
    let connectionJson = {};
    forEach(this.connectionTypeParameters, (data) => {
      connectionJson = { ...connectionJson, [data['Key']]: data['Value'] };
    });
    this.exportConnection.Configuration = JSON.stringify(connectionJson);
    this.save(this.exportConnection);
  }

  onSaveSuccess() {
    this.cancel();
  }

  cancel() {
    this.router.navigate(['export-connection'], { relativeTo: this.route.parent });
  }

  changeConnectionTypeParameters() {
    this.connectionTypeParameters = cloneDeep(this.exportConnectionParameters)[this.exportConnection.ConnectionType];
  }
}
