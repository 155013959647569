<div class="h-80">
	<pos-header-entry-main [links]="headerLinks" [homeState]="homeState" [isShowBarIcon]="false"></pos-header-entry-main>
</div>
<div class="edit-form__section col-md-6 col-sm-8 offset-sm-2 offset-md-3 mt-pix-10">
	<div class="container dj-body">
		<div class="row pt-pix-10 pb-pix-10">
			<div class="alert alert-danger condensed inherit-position mb-pix-10 alertClass" *ngIf="showAlert">Song not found
			</div>
		</div>
		<div class="row">
			<div class="col-12 text-center">
				<pos-sound-player *ngIf="!isPlayDjSpeakText" [(djScheme)]="DjScheme" 
				[(schemes)]="schemes" 
				[(djTitle)]="djTitle"
				[(playBackTitle)]="playBackTitle"
				[(isLoadPlaylist)]="isLoadPlaylist"
				[(isPlaying)]="isPlaying"
				[(volume)]="volume"
				[(currentProgress)]="currentProgress"
				[(duration)]="duration"
				[(counter)]="counter"
				[(playlistName)]="playlistName"
				[(playlistTracks)]="playlistTracks"
				[(selectedPlaylist)]="selectedPlaylist"
				[(isStopped)]="isStopped"
				(startClick)="start($event)"
				(pauseClick)="pause()"
				(stopClick)="stop()"
				(playNextClick)="playNext()"
				(playPreviousClick)="playPrevious()"
				(nextSchemeClick)="nextScheme($event)"
				(setVolumeClick)="setVolume($event)"
				(progressBarClick)="progressBarClicked($event)"
				(loadTrackClick)="selectSong($event)"></pos-sound-player>

				<pos-sound-player *ngIf="isPlayDjSpeakText" [djScheme]="'Dj Notifications'" 
				[(djTitle)]="djSpeakTextFileName"
				[playBackTitle]="''"
				[isLoadPlaylist]="false"
				[(isPlaying)]="isPlaying"
				[(isStopped)]="isStopped"
				[(volume)]="djSpeakTextVolume"
				[currentProgress]="djSpeakTextProgress"
				[(duration)]="djSpeakTextDuration"
				[(counter)]="djSpeakTextCounter"
				[playlistName]=""
				[(playlistTracks)]="djSpeakTextList"
				[(selectedPlaylist)]="selectedPlaylist"
				(startClick)="start($event)"
				(pauseClick)="pause()"
				(stopClick)="stop()"
				(setVolumeClick)="setVolume($event)"></pos-sound-player>
			</div>
		</div>
	</div>
</div>
