import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { OrderWorkflowConfigurationParameter } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class OrderWorkflowService {

  orderWorkflowApiBasePath = `${RuntimeConstants.API_PATH}order-workflow-configuration`;
  constructor(private httpService: HttpClient) { }

  newOrderWorkflowConfigurationParameter(): OrderWorkflowConfigurationParameter {
    return {
      ScheduleOrderActiveHours: '',
      ShowAllOrders: false,
      AllowOverridesOnDietaryRestrictions: false,
      ShowTipsInTerminalInfo: false,
      IsSendOrderToKitchenImmediately: false,
      IsShowReviewOrder: false,
      AutoServeOrder: false,
      RecallEntireOrder: false,
      ShowOwnOrdersOnly: false,
      MaxScheduledOrderHours: 0,
      CloseSettleAfterFullPayment: false,
      IsUsePager: false,
      MaximumAllowedDiscount: 0,
      AllowOrderingFractionalQty: false,
      QuantityChange: '',
      OnlineOrderTerminalId: 0,
      OrderPrompts: '',
      RequireNameOnOrder: '',
      OrderNumberIncrement: 0,
      ShowOrderNumberAfterPayment: false,
      SettlePersonalOrdersOnly: false,
      IsShowCheckmarkOnToggleButton: false,
      ChangeBackgroundColorOnToggleButton: false,
      AllowModifyingSubaccountNameAtOrderInvoice: false,
      PagerRange: '',
      AutoSignOutOnOrderSubmitted: false,
      AutoSignOutOnPaymentCompleted: false,
      AutoSignOutOnUserIdleSeconds: 0,
      IsAutoServeNonPrintingItems: false,
      BarcodeScanningInitiatorSequence: null,
      BarcodeScanningEndingSequence: null,
      VerifyOrderBeforeSettle: false,
    }
  }

  getOrderWorkflowParameters(): Observable<OrderWorkflowConfigurationParameter> {
    return this.httpService.get<OrderWorkflowConfigurationParameter>(`${this.orderWorkflowApiBasePath}`);
  }

  updateOrderWorkflowParameters(orderWorkflowParameter: OrderWorkflowConfigurationParameter) {
    return this.httpService.post<OrderWorkflowConfigurationParameter>(`${this.orderWorkflowApiBasePath}`, orderWorkflowParameter);
  }
}
