import { Component, OnInit } from '@angular/core';
import { FieldInfoMessages, SpinnerService, AlertsService, ConfirmDeleteModalComponent, Messages, ModalService } from 'src/app/shared';
import { PlaylistsService } from '../../services';
import { Track } from 'src/app/configurator';
import { finalize } from 'rxjs/operators';
import { deleteWhite } from 'src/app/shared/components/icon';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import * as _ from 'lodash';
import { PlaylistTrack } from '../../interface/playlist-track';
import { TracksService } from 'src/app/configurator/tracks';
import { ActivatedRoute, Router } from '@angular/router';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { playListThList } from 'src/app/shared/components/icon/icons';

@Component({
  selector: 'pos-playlist-track',
  templateUrl: './playlist-track.component.html',
  styleUrls: ['./playlist-track.component.scss']
})
export class PlaylistTrackComponent implements OnInit {

  playlistName: string;
  playlistId = 0;
  fieldInfoMessages = FieldInfoMessages;
  templatesTransParam: any;
  playlistTracks: Array<PlaylistTrack>;
  icons = {
    deleteWhite,
    playListThList
  };

  constructor(protected spinnerService: SpinnerService,
    protected alertService: AlertsService,
    protected router: Router,
    private route: ActivatedRoute,
    private playlistsService: PlaylistsService,
    private modalService: ModalService,
    private tracksService: TracksService) {
    const navigation = router.getCurrentNavigation();
    this.playlistId = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
    this.playlistName = (navigation?.extras?.state && navigation?.extras?.state.playlistName) ? navigation?.extras?.state.playlistName : '';
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.spinnerService.show();
    this.playlistsService.getPlaylistTracks(this.playlistId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: Array<PlaylistTrack>) => {
          this.playlistTracks = response == null ? [] : response;
          _.forEach(this.playlistTracks, (track) => {
            track.Filename = track.Filename.substring(19, track.Filename.length);
          });
        }, error: this.alertService.showApiError
      });
  }

  mapTrack(id: number, playlistName) {
    this.router.navigate(['playlist', id, 'tracks'], {
      state: { isEditTrack: true, playlistName: playlistName },
      relativeTo: this.route.parent
    });
  }

  onSortTrack(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.playlistTracks, event.previousIndex, event.currentIndex);
    this.tracksService.changePlaylistTrackOrdinal(this.playlistTracks)
      .subscribe({
        next: () => {
        }, error: this.alertService.showApiError
      });
  }

  deletePlaylistTrack(playlistId, trackId, trackName) {
    const modalRef = this.modalService.getModalWrapper(ConfirmDeleteModalComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      initialState: {
        message: StringUtils.format(Messages.ConfirmDeletePlaylistTrack,
          { 'trackName': trackName, 'playlistName': this.playlistName })
      }
    });
    modal.close.subscribe(res => {
      if (res && res.shouldDelete) {
        this.delete(playlistId, trackId);
      }
    });
  }

  delete(playlistId: number, trackId: number) {
    this.spinnerService.show();
    this.playlistsService.deletePlaylistTrack(playlistId, trackId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: Array<Track>) => {
          this.alertService.renderSuccessMessage(Messages.PlaylistTrackDeleted);
          this.loadData();
        }, error: this.alertService.showApiError
      });
  }

}
