import { Component, OnInit } from '@angular/core';
import { Permissions, Levels, ApplicationStateService, EventBroadcastingService, AuthenticationService } from 'src/app/shared';
import {
  angleDoubleRight,
  angleDoubleLeft,
  licenseIdCard,
  terminalTv,
  rabbitMqConfigurationCogs,
  centralApiConfigurationCogs,
  database,
  hardwareToolbox,
  hardwareInterfacePlug,
  cashDrawerMoneyBillWave,
  printing,
  branding,
  digitalSignageBullHorn,
  colorsPaletter,
  layoutBoxes,
  iconsVectorSquare,
  music,
  playListThList,
  djSchemesRoad,
  taxonomyFileWord,
  workflowArrowCircleRight,
  deliveryTruckMoving,
  taxesMoneyBillAlt,
  usersCogs,
  endOfDayMoon,
  baselineExpenseMoneyBill,
  accountsAndTabsUsersTag,
  paymentsMoneyBillAlt,
  whiteListedAddressBook,
  blackListedAddressBook,
  cardBasedLoginsIdCard,
  externalLoginsSigninAlt,
  externalApiLink,
  flushCache,
  emailTemplatesEnvelopeOpen,
  dayparts,
  cashierReport,
  editSolid,
  layerGroup,
  times,
  search,
  bell,
  cogs,
  orderBuilding,
  makeTable,
  pickUpScreen,
  confirmationScreenSolid,
  rawIngredientInventoryProductStore,
  giftCard,
  camera,
  sms,
  star,
  language,
  windowCancel,
  timeUserClock,
  checkDouble,
  shoppingBag,
  rocket
} from 'src/app/shared/components/icon';
import { ConfiguratorService } from '../configurator.service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { BaseMenuSearchService } from 'src/app/shared/services/base-menu-search.service';
declare let $: any;
@Component({
  selector: 'pos-configurator-base',
  templateUrl: './configurator-base.component.html',
  styleUrls: ['./configurator-base.component.scss']
})
export class ConfiguratorBaseComponent implements OnInit {

  isFullScreen = false;
  permissions: any = Permissions;
  accessLevels: any = Levels;
  isInitialize = true;
  systemConfiguration = '';
  icons = {
    angleDoubleRight,
    angleDoubleLeft,
    times,
    search
  };
  filterMenuList = [];
  menuList = [
    {
      GroupName: 'Terminal and Licensing',
      Permission: this.permissions.TerminalsandLicensing,
      PermissionLevel: this.accessLevels.Access,
      List: [
        {
          Name: 'License', Icon: licenseIdCard, Permission: this.permissions.SystemConfigurationLicensing,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'license'
        },
        {
          Name: 'Terminals', Icon: terminalTv, Permission: this.permissions.SystemConfigurationTerminals,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'terminal'
        },
        {
          Name: 'RabbitMQ Configuration', Icon: rabbitMqConfigurationCogs, Permission: this.permissions.SystemConfigurationRabbitMQConfiguration,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'rabbitMQ-configuration'
        },
        {
          Name: 'Central API Configuration', Icon: centralApiConfigurationCogs, Permission: this.permissions.SystemConfigurationCentralAPIConfiguration,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'central-api-configuration'
        },
        {
          Name: 'Database', Icon: database, Permission: this.permissions.SystemConfigurationDatabase,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'database'
        }
      ]
    },
    {
      GroupName: 'Hardware and Peripherals',
      Permission: this.permissions.HardwareandPeripherals,
      PermissionLevel: this.accessLevels.Access,
      List: [
        {
          Name: 'Hardware', Icon: hardwareToolbox, Permission: this.permissions.SystemConfigurationHardware,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'hardwares'
        },
        {
          Name: 'Hardware Interface', Icon: hardwareInterfacePlug, Permission: this.permissions.SystemConfigurationHardwareInterfaces,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'hardware-interfaces'
        },
        {
          Name: 'Cash Drawer', Icon: cashDrawerMoneyBillWave, Permission: this.permissions.SystemConfigurationCashDrawer,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'cash-drawer'
        },
        {
          Name: 'Printing', Icon: printing, Permission: this.permissions.SystemConfigurationPrinting,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'printing'
        },
        {
          Name: 'Camera Annotations', Icon: camera, Permission: this.permissions.SystemConfigurationCameraAnnotation,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'camera-annotation'
        }
      ]
    },
    {
      GroupName: 'UI and Styling',
      Permission: this.permissions.UIandStyling,
      PermissionLevel: this.accessLevels.Access,
      List: [
        {
          Name: 'Branding', Icon: branding, Permission: this.permissions.SystemConfigurationBranding,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'branding'
        },
        {
          Name: 'Digital Signage', Icon: digitalSignageBullHorn, Permission: this.permissions.SystemConfigurationDigitalSignage,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'digital-signage'
        },
        {
          Name: 'Colors', Icon: colorsPaletter, Permission: this.permissions.SystemConfigurationColors,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'colors'
        },
        {
          Name: 'Layout', Icon: layoutBoxes, Permission: this.permissions.SystemConfigurationLayout,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'layout'
        },
        {
          Name: 'Icons', Icon: iconsVectorSquare, Permission: this.permissions.SystemConfigurationIcons,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'icons'
        },
      ]
    },
    {
      GroupName: 'Localization',
      Permission: this.permissions.SystemConfigurationLocalization,
      PermissionLevel: this.accessLevels.Access,
      List: [
        {
          Name: 'Formats', Icon: language, Permission: this.permissions.SystemConfigurationFormats,
          PermissionLevel: this.accessLevels.Access,  RoutLink: 'formats'
        }
      ]
    },
    {
      GroupName: 'DJ',
      Permission: this.permissions.DJ,
      PermissionLevel: this.accessLevels.Access,
      List: [
        {
          Name: 'Tracks', Icon: music, Permission: this.permissions.SystemConfigurationPlaylists,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'tracks'
        },
        {
          Name: 'Playlists', Icon: playListThList, Permission: this.permissions.SystemConfigurationPlaylists,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'playlists'
        },
        {
          Name: 'Schemes', Icon: djSchemesRoad, Permission: this.permissions.SystemConfigurationDJSchemes,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'dj-scheme'
        }
      ]
    },
    {
      GroupName: 'Operations',
      Permission: this.permissions.Operations,
      PermissionLevel: this.accessLevels.Access,
      List: [
        {
          Name: 'Taxonomy', Icon: taxonomyFileWord, Permission: this.permissions.SystemConfigurationTaxonomy,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'taxonomy'
        },
        {
          Name: 'Workflow', Icon: workflowArrowCircleRight, Permission: this.permissions.SystemConfigurationWorkflow,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'workflow'
        },
        {
          Name: 'Delivery', Icon: deliveryTruckMoving, Permission: this.permissions.SystemConfigurationDelivery,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'delivery'
        },
        {
          Name: 'Taxes', Icon: taxesMoneyBillAlt, Permission: this.permissions.SystemConfigurationTaxes,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'sales-taxes'
        },
        {
          Name: 'User Settings', Icon: usersCogs, Permission: this.permissions.SystemConfigurationUserSettings,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'user-settings'
        },
        {
          Name: 'End of day', Icon: endOfDayMoon, Permission: this.permissions.SystemConfigurationEndOfDay,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'end-of-day'
        },
        {
          Name: 'Baseline Expenses', Icon: baselineExpenseMoneyBill, Permission: this.permissions.SystemConfigurationBaselineExpenses,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'baseline-expenses'
        },
        {
          Name: 'Dayparts', Icon: dayparts, Permission: this.permissions.SystemConfigurationDayparts,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'dayparts'
        },
      ]
    },
    {
      GroupName: 'Workflow',
      Permission: this.permissions.Workflow,
      PermissionLevel: this.accessLevels.Access,
      List: [
        {
          Name: 'General', Icon: cogs, Permission: this.permissions.SystemConfigurationGeneralWorkflow,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'general-workflow'
        },
        {
          Name: 'Ordering', Icon: orderBuilding, Permission: this.permissions.SystemConfigurationOrderWorkflow,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'order-workflow'
        },
        {
          Name: 'Make Table', Icon: makeTable, Permission: this.permissions.SystemConfigurationMakeTableWorkflow,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'maketable-workflow'
        },
        {
          Name: 'Pick Screen', Icon: pickUpScreen, Permission: this.permissions.SystemConfigurationPickScreenWorkflow,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'pickscreen-workflow'
        },
        {
          Name: 'Serve Screen', Icon: rocket, Permission: this.permissions.SystemConfigurationServeScreenWorkflow,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'servescreen-workflow'
        },
        {
          Name: 'Confirmation Screen', Icon: confirmationScreenSolid, Permission: this.permissions.SystemConfigurationConfirmationScreenWorkflow,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'confirmation-screen-workflow'
        },
        {
          Name: 'Inventory', Icon: rawIngredientInventoryProductStore, Permission: this.permissions.SystemConfigurationInventoryWorkflow,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'inventory-workflow'
        },
        {
          Name: 'Gift Card', Icon: giftCard, Permission: this.permissions.SystemConfigurationGiftCardWorkflow,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'gift-card-workflow'
        },
      ] 
    },
    {
      GroupName: 'Customers',
      Permission: this.permissions.Customers,
      PermissionLevel: this.accessLevels.Access,
      List: [
        {
          Name: 'Accounts & Tabs', Icon: accountsAndTabsUsersTag, Permission: this.permissions.SystemConfigurationAccountsAndTab,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'accounts-and-tabs'
        },
        {
          Name: 'Payments', Icon: paymentsMoneyBillAlt, Permission: this.permissions.SystemConfigurationPayments,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'payments'
        },
        {
          Name: 'Revenue Centers', Icon: cashierReport, Permission: this.permissions.SystemConfigurationRevenueCenters,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'revenue-centers'
        }
      ]
    },
    {
      GroupName: 'Security',
      Permission: this.permissions.Security,
      PermissionLevel: this.accessLevels.Access,
      List: [
        {
          Name: 'Whitelisted Addresses', Icon: whiteListedAddressBook, Permission: this.permissions.SecurityWhitelistedAddresses,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'whitelisted-ip-addresses'
        },
        {
          Name: 'Blacklisted Addresses', Icon: blackListedAddressBook, Permission: this.permissions.SecurityBlacklistedAddresses,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'blacklisted-ip-addresses'
        },
        {
          Name: 'Card-based Logins', Icon: cardBasedLoginsIdCard, Permission: this.permissions.SecurityCardBasedLogins,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'card-based-login'
        },
        {
          Name: 'External Logins', Icon: externalLoginsSigninAlt, Permission: this.permissions.SecurityExternalLogins,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'external-logins'
        }
      ]
    },
    {
      GroupName: 'Reports',
      Permission: this.permissions.ReportsConfiguration,
      PermissionLevel: this.accessLevels.Access,
      List: [
        {
          Name: 'Report Groups', Icon: layerGroup, Permission: this.permissions.SystemConfigurationReportGroups,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'report-groups'
        },
        {
          Name: 'Reports', Icon: editSolid, Permission: this.permissions.SystemConfigurationReports,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'reports'
        }
      ]
    },
    {
      GroupName: 'API Manager',
      Permission: this.permissions.ApiManager,
      PermissionLevel: this.accessLevels.Access,
      List: [
        {
          Name: 'External API', Icon: externalApiLink, Permission: this.permissions.SystemConfigurationApiManager,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'external-api'
        }
      ]
    },
    {
      GroupName: 'Custom Data Source',
      Permission: this.permissions.CustomDataSource,
      PermissionLevel: this.accessLevels.Access,
      List: [
        {
          Name: 'Dashboard Data Source', Icon: database, Permission: Permissions.DashboardDataSource,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'data-source'
        }
      ]
    },
    {
      GroupName: 'Caching',
      Permission: this.permissions.FlushCache,
      PermissionLevel: this.accessLevels.Access,
      List: [
        {
          Name: 'Flush Cache', Icon: flushCache, Permission: this.permissions.SystemConfigurationCaching,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'flush-cache'
        }
      ]
    },
    {
      GroupName: 'Templates',
      Permission: this.permissions.Email,
      PermissionLevel: this.accessLevels.Access,
      List: [
        {
          Name: 'Email', Icon: emailTemplatesEnvelopeOpen, Permission: this.permissions.SystemConfigurationEmailTemplates,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'email-templates'
        },
        {
          Name: 'SMS', Icon: sms, Permission: this.permissions.SystemConfigurationSMSTemplates,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'sms-templates'
        }
      ]
    },
    {
      GroupName: 'Setup',
      Permission: this.permissions.Setup,
      PermissionLevel: this.accessLevels.Access,
      List: [
        {
          Name: 'Notification', Icon: bell, Permission: this.permissions.SystemConfigurationNotification,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'notification'
        },
        {
          Name: 'Features', Icon: star, Permission: this.permissions.SystemConfigurationFeatures,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'features'
        }
      ]
    }
  ];

  constructor(private applicationStateService: ApplicationStateService,
    private route: ActivatedRoute,
    private eventBroadcastingService: EventBroadcastingService,
    protected router: Router,
    private configuratorService: ConfiguratorService,
    private authenticationService: AuthenticationService,
    private baseMenuSearchService: BaseMenuSearchService) {
    const navigation = router.getCurrentNavigation();
    this.isInitialize = (navigation?.extras?.state && navigation?.extras?.state.isInitialize) ?? false;
    this.filterMenuList = [...this.menuList];
  }

  ngOnInit(): void {
    this.applicationStateService.gridRouteContext = null;
    if (this.isInitialize && this.authenticationService.userHasPermission([{ Name: this.permissions.SystemConfigurationLicensing, Level: this.accessLevels.Access }], 'any')) {
      this.router.navigate(['license'], { relativeTo: this.route });
    }
  }

  resetAutoCloseInterval = () => {
    this.configuratorService.clearAutoCloseInterval();
  }

  toggleFullScreen() {
    this.isFullScreen = !this.isFullScreen;
    if (this.isFullScreen) {
      $('.tab-content').css('width', '100%');
    } else {
      $('.tab-content').css('width', '80%');
    }
    this.eventBroadcastingService.onConfiguratorFullScreen(this.isFullScreen);
  }

  search() {
    this.filterMenuList = this.baseMenuSearchService.search(this.menuList, this.systemConfiguration);
  }

}
