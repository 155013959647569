import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DateColumn, GridColumn, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { BaseListComponent } from 'src/app/shared/components/base-list.component';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import { OrderProductWorkflow } from '../../interfaces/order-product-workflow';
import { OrderProductWorkflowService } from '../../service/order-product-workflow.service';
import { OnlineOrderWorkflowComponent } from '../online-order-workflow/online-order-workflow.component';
import { historyStack, squareGreenStack, chevronCircleRight, shippingFast } from 'src/app/shared/components/icon';
import { finalize } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { ManualValuesModel } from 'src/app/manage-console/automated-exports/interface/parameter-model';

@Component({
  selector: 'pos-online-order',
  templateUrl: './online-order.component.html',
  styleUrls: ['./online-order.component.scss']
})
export class OnlineOrderComponent extends BaseListComponent<OrderProductWorkflow> implements OnInit {

  onlineOrderColumns: Array<GridColumn> = [];
  onlineOrder: Array<OrderProductWorkflow> = [];
  onlineOrderType: Array<ManualValuesModel> = [];
  onlineOrderFiltered: Array<OrderProductWorkflow> = [];
  selectedType: string;
  selectedStatus: string;
  endDate: Date;
  startDate: Date;
  isCompleted = false;
  dateFormat = 'mm-dd-yy';
  icons = {
    historyStack,
    squareGreenStack,
    chevronCircleRight, shippingFast
  };

  @ViewChild('nextProcess', { static: true }) nextProcess: TemplateRef<any>;
  constructor(protected orderProductWorkflowService: OrderProductWorkflowService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected applicationStateService: ApplicationStateService,
    protected route: ActivatedRoute,
    private router: Router,
    protected printService: PrintTableService) {
    super(orderProductWorkflowService, alertService, spinnerService, modalService, applicationStateService, printService, route);
    this.dateFormat = this.applicationStateService.settingParam.PCalendarDateFormat;
  }

  ngOnInit(): void {
    this.onlineOrderColumns = this.configureGridColumns();
    this.endDate = new Date();
    this.startDate = new Date(new Date().setMonth(this.endDate.getMonth() - 1));
  }

  reload() {
    const observable: Array<Observable<any>> = [];
    this.spinnerService.show();
    observable.push(this.orderProductWorkflowService.getAll());
    observable.push(this.orderProductWorkflowService.getWorkflowIdentifiers());
    forkJoin(observable).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (response: [Array<OrderProductWorkflow>, Array<ManualValuesModel>]) => {
        this.onlineOrderType = [...response[1]];
        this.setOrderWorkflow(response[0]);
      },
      error: this.alertService.showApiError
    });

  }

  setOrderWorkflow(onlineOrders: Array<OrderProductWorkflow>) {
    this.onlineOrder = onlineOrders;
    this.onlineOrder.map((data) => {
      data.SalesProductName = data.OrderProduct?.SalesProduct.Name;
      data.OrderFulfillment = this.onlineOrderType.find(x => x.Value === data.WorkflowIdentifier).Name;
    });
    this.onlineOrderFiltered = [...this.onlineOrder];
  }

  editItem(data) {
    const modalRef = this.modalService.show(OnlineOrderWorkflowComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        orderProductWorkflow: data
      }
    });

    modalRef.close.subscribe(res => {
      if (res && res.shouldReload) {
        this.reload();
      }
    });
  }

  configureGridColumns(): GridColumn[] {
    const nextProcess = new TemplateColumn({
      itemTemplate: this.nextProcess,
      Width: '134px',
      CellClass: 'cell-padding',
      TextAlign: TextAlign.Center
    });
    const gridColumn = [
      new GridColumn({ Field: 'OrderProduct.OrderId', HeaderText: 'Order#', Width: '10%' }),
      new GridColumn({ Field: 'SalesProductName', HeaderText: 'Name', Width: '21%' }),
      new GridColumn({ Field: 'OrderFulfillment', HeaderText: 'Type', Width: '15%' }),
      new GridColumn({ Field: 'CurrentState', HeaderText: 'Current Status', Width: '20%' }),
      new GridColumn({ Field: 'Name', HeaderText: 'Ordered By', Width: '10%' }),
      new DateColumn({ Field: 'DateAdded', HeaderText: 'Ordered On', Width: '12%' }),
      nextProcess
    ];
    return gridColumn;
  }

  getGridColumns(): GridColumn[] {
    return [];
  }
  getConfirmDeleteMessage(data: OrderProductWorkflow): string {
    return null;
  }

  getOnlineOrderByType() {
    if (!this.selectedType) {
      this.onlineOrderFiltered = this.onlineOrder;
      return;
    }
    this.onlineOrderFiltered = this.onlineOrder.filter(x => x.WorkflowIdentifier === this.selectedType);
  }

  getAllOrdersBetweenDateAndCompletion() {
    this.spinnerService.show();
    const orderProductObservable = this.isCompleted ? this.orderProductWorkflowService.getAllByDate(this.isCompleted, this.startDate, this.endDate)
      : this.orderProductWorkflowService.getAll();
    orderProductObservable.pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (response: Array<OrderProductWorkflow>) => {
        this.setOrderWorkflow(response);
      }, error: this.alertService.showApiError
    });
  }


  close() {
    this.router.navigate(['manage/console']);
  }

}
