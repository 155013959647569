import { Component, Input, OnInit } from '@angular/core';
import { filter, forEach, groupBy, keys, map, reduce, sum, uniqBy } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalComponent } from 'src/app/shared';
import { OrderProductPromotion, PromotionGroup } from '../../interfaces';

@Component({
  selector: 'pos-promotion-summary',
  templateUrl: './promotion-summary.component.html',
  styleUrls: ['./promotion-summary.component.scss']
})
export class PromotionSummaryComponent extends ModalComponent implements OnInit {

  @Input() orderProductPromotions: Array<OrderProductPromotion> = [];
  // groupedPromotions: Dictionary<Array<OrderProductPromotion>>;
  promotionGroup: Array<PromotionGroup> = [];
  totalSaved = 0;
  constructor(modalRef: BsModalRef) { super(modalRef); }

  ngOnInit(): void {
    const groupedPromotions = groupBy(this.orderProductPromotions.filter(x => x.IsRewardedItem), 'PromotionId');
    forEach(groupedPromotions, (value, key) => {

      const group = groupBy(value, 'ProductName');     
     const products = map(keys(group), (e) => {
       return reduce(group[e], (r, o) => {
         return r.Amount += +o.RewardAmount, r.ProductName = o.ProductName, 
         r.OrderId = o.OrderId, r.Qty += +o.Qty, r.SourceOrderId = o.SourceOrderId, r.PromotionName = o.PromotionName, 
         r.PromotionId = o.PromotionId, r
       }, {Amount: 0, IsRewardedItem: true, PromotionId: 0, ProductName: '', RewardAmount: 0, OrderId: 0, Qty: 0, SourceOrderId: 0, PromotionName: ''})
     }) as Array<OrderProductPromotion>

      const promotion = {
        OrderProducts: products,
        TotalRewardAmount: 0
      };
      forEach(value, (item) => {        
        promotion.TotalRewardAmount += item.RewardAmount;
      });
      this.totalSaved += promotion.TotalRewardAmount;
      this.promotionGroup.push(promotion);
    });

    this.totalSaved = Math.abs(this.totalSaved);
    if (!this.totalSaved) {
      this.hideModal();
    }
  }

  hideModal() {
    this.hide({});
  }

}
