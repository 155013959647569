import { Component, OnDestroy, OnInit } from '@angular/core';
import { find, forEach, remove, sortBy } from 'lodash';
import { AlertsService, ApplicationStateService, ColorUtilityService } from 'src/app/shared/services';
import { TerminalsService } from 'src/app/configurator/terminals';
import { DomainConstants } from 'src/app/shared/constants';
import { SettingParam } from 'src/app/shared/interface';
import { RabbitMQService } from 'src/app/shared/services';
import { PickScreenOrder } from '../../interfaces/pick-screen-product';

import { PickScreenService } from '../../services/pick-screen.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
declare let $: any;
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pos-pick-screen',
  templateUrl: './pick-screen.component.html',
  styleUrls: ['./pick-screen.component.scss']
})
export class PickScreenComponent implements OnInit, OnDestroy {
  headerLinks = {
    changeTerminal: false,
    mappedTerminals: false,
    time: false,
    signIn: true,
    tasks: this.applicationStateService.applicationInitializationConfigurations.HasTaskSetup,
    home: false,
    admin: false,
    activeOrders: false
  };
  isRetrievingIngredients: boolean;
  private _noOfColumns: number;
  columns: Array<number>;
  settingParam: SettingParam;
  highlightTimeouts = new Map<number, Date>();

  trackByOrderProductId(index: number, item: PickScreenOrder): number {
    return item.OrderProductId;
  }

  pickScreenProducts: Array<PickScreenOrder> = [];
  get noOfColumns(): number {
    return this._noOfColumns;
  }

  // rabbitMq subscriptions
  rabbitMqOrderUpdateSubscription: Subscription;
  rabbitMqOrderDeleteSubscription: Subscription;
  autoRefreshMakeTableOrdersInterval: any;
  set noOfColumns(value: number) {
    this._noOfColumns = value;
    this.columns = Array(this._noOfColumns).fill(1).map((x, i) => i + 1);
  }

  constructor(private pickScreenService: PickScreenService,
    private colorUtilityService: ColorUtilityService,
    private applicationStateService: ApplicationStateService,
    private rabbitMQService: RabbitMQService,
    private terminalService: TerminalsService,
    private alertService: AlertsService) {
    this.settingParam = this.applicationStateService.settingParam;
  }

  autoRefreshMakeTableOrders() {
    if(!this.autoRefreshMakeTableOrdersInterval) {
      clearInterval(this.autoRefreshMakeTableOrdersInterval);
    }
    this.autoRefreshMakeTableOrdersInterval = setInterval(() => {
      this.getPickScreenProducts();
    }, this.settingParam.PickScreenRefreshInterval * 60 * 1000);
  }

  ngOnInit(): void {
    forEach(this.settingParam.MakeTableWorkflowStates, (workFlow) => {
      if (workFlow.Code === DomainConstants.MakeTableStates.RETRIEVING_INGREDIENTS && workFlow.IsSelected) {
        this.isRetrievingIngredients = true;
      }
    });
    if (this.isRetrievingIngredients) {
      this.getPickScreenProducts();
      this.setNoOfColumns();
      this.subscribeRabbitMQ();
      this.getMappedTerminals();
      this.autoRefreshMakeTableOrders();
    }
  }
  ngOnDestroy() {
    this.highlightTimeouts.clear();
    if(!this.autoRefreshMakeTableOrdersInterval) {
      clearInterval(this.autoRefreshMakeTableOrdersInterval);
    }
  }
  subscribeRabbitMQ() {
    this.subscribeToMakeTableExchangeForAllOrderEntryTerminals();
    this.subscribeToTerminalBroadcastQueue();
  }


  subscribeToMakeTableExchangeForAllOrderEntryTerminals() {
    this.rabbitMqOrderDeleteSubscription = this.rabbitMQService.subscribeToOrderDeletedMessageForAllOrderEntry$()
      .subscribe((message) => {
        remove(this.pickScreenProducts, (product) => {
          return product.OrderId === message.Payload.OrderId;
        });
      });
  }

  getMappedTerminals() {
    this.terminalService.getMappedTerminals(this.applicationStateService.terminalId, DomainConstants.TerminalTypes.MAKE_TABLE.Name)
      .subscribe({
        next: (mappedMakeTables) => {
          if (mappedMakeTables.length > 0) {
            forEach(mappedMakeTables, (mappedMakeTable) => {
              this.rabbitMQService.subscribeToOrderUpdatesFromMakeTable$(mappedMakeTable.id)
                .pipe(untilDestroyed(this))
                .subscribe((res) => {
                  const orderProductDetails = res.Payload.OrderProductDetails[0];
                  this.rabbitMQOrderUpdate(orderProductDetails);
                });
            });
          } else {
            this.rabbitMqOrderUpdateSubscription = this.rabbitMQService.subscribeToOrderUpdatesFromAllMakeTables$()
              .subscribe((message) => {
                const orderProductDetails = message.Payload.OrderProductDetails[0];
                this.rabbitMQOrderUpdate(orderProductDetails);
              });
          }
        }
      });
  }

  rabbitMQOrderUpdate(orderProductDetails) {
    if (orderProductDetails.IsUsePickScreen) {
      if (!orderProductDetails.Remove) {
        this.getDetailsByProductId(orderProductDetails.Id);
        return;
      } else {
        this.removeItems(orderProductDetails.Id);
      }
    }
  }

  getDetailsByProductId(orderProductId) {
    this.pickScreenService.getPickScreenDetails(orderProductId)
      .subscribe({
        next: (response) => {
          if (response.OrderProductId) {
            const hasProduct = find(this.pickScreenProducts, (product) => {
              return response.OrderProductId === product.OrderProductId;
            });
            if (!hasProduct) {
              this.setComponentFontColor(response);
              this.pickScreenProducts.push(response);
              this.pickScreenProducts = sortBy(this.pickScreenProducts, ['OrderId', 'OrderProductId']);

              // Add newly added product to highlight set
              if (this.highlightTimeouts.has(response.OrderProductId)) {
                this.highlightTimeouts.delete(response.OrderProductId);
              }

              const now = new Date();
              now.setSeconds(now.getSeconds() + this.settingParam.PickScreenHighlightItemDuration);

              this.highlightTimeouts.set(response.OrderProductId, now );

            } else {
              forEach(this.pickScreenProducts, (product) => {
                if (product.OrderProductId === response.OrderProductId) {
                  product.Qty = response.Qty;
                }
              });
            }
          }
        }, error: this.alertService.showApiError
      });
  }

  removeItems(productItemId) {
    remove(this.pickScreenProducts, (product) => {
      return product.OrderProductId === productItemId;
    });
  }

  removeOrder(orderId) {
    remove(this.pickScreenProducts, (product) => {
      return product.OrderId === orderId;
    });
  }

  setFontColor() {
    forEach(this.pickScreenProducts, (product) => {
      this.setComponentFontColor(product);
    });
  }

  setComponentFontColor(product) {
    forEach(product.Components, (component) => {
      component.PrimaryFontColor = this.getFontColor(component.PrimaryColor);
      component.SecondaryFontColor = this.getFontColor(component.SecondaryColor);
      component.CustomFontColor = this.getFontColor(component.CustomColor);
    });
  }
  setNoOfColumns() {
    if ($(window).width() > 1199) {
      this.noOfColumns = 3;
    } else if ($(window).width() > 791) {
      this.noOfColumns = 2;
    } else {
      this.noOfColumns = 1;
    }
  }


  subscribeToTerminalBroadcastQueue() {
    this.rabbitMQService.subscribeToOrderServedMessage$()
      .subscribe((message) => {
        this.removeOrder(message.Payload.OrderId);
      });
    this.rabbitMQService.subscribeToOrderItemDeletedMessage$()
      .subscribe((message) => {
        this.removeItems(message.Payload.OrderId);
      });
    this.rabbitMQService.subscribeToOrderItemQtyChangedMessage$()
      .subscribe((message) => {
        this.getDetailsByProductId(message.Payload.OrderId);
      });
  }
  getPickScreenProducts() {
    this.pickScreenService.getAllPickScreenDetails(this.applicationStateService.terminalId)
      .subscribe({
        next: (res: Array<PickScreenOrder>) => {
          this.pickScreenProducts = res;
          this.setFontColor();

          const currentProductIds = new Set(this.pickScreenProducts.map(product => product.OrderProductId));

          this.highlightTimeouts.forEach((_, key) => {
            if (!currentProductIds.has(key)) {
              this.highlightTimeouts.delete(key);
            }
          })
        }, error: this.alertService.showApiError
      });
  }

  getFontColor(color) {
    return this.colorUtilityService.getContrastColor(color);
  }

  getDataForColumn(column: number) {
    let pickIndex = column - 1;
    const columnData = [];
    while (pickIndex < this.pickScreenProducts.length) {
      columnData.push(this.pickScreenProducts[pickIndex]);
      pickIndex += this.noOfColumns;
    }
    return columnData;
  }

}
