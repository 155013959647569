import { Route } from '@angular/router';
import { Levels, Permissions } from '../shared/constants';
import { LayoutDesignerEditComponent, LayoutDesignerListComponent } from './components';

export const LAYOUT_DESIGNER_ROUTES: Array<Route> = [
    {
        path: 'list',
        component: LayoutDesignerListComponent,
        data: {
            group: 'layout',
            permissions: [{ 'Name': Permissions.ManageLayoutDesigner, 'Level': Levels.Access },
            { 'Name': Permissions.ManageConsole, 'Level': Levels.Access }]
        },
    },
    {
        path: 'edit/:id',
        component: LayoutDesignerEditComponent,
        data: {
            group: 'layout',
            permissions: [{ 'Name': Permissions.ManageLayoutDesigner, 'Level': Levels.Access },
            { 'Name': Permissions.ManageConsole, 'Level': Levels.Access }],
        }
    },
];
