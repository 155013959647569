import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComboListDescriptionStyleConfig, ComboListWidgetStyleConfig, ImageListWidgetConfig, ImageWidgetConfig, ItemWidgetConfig, ListWidgetConfig, ListWidgetStyleConfig, OutOfStockBehavior, PriceTableConfig, ProductSize, TagConfig, TilesWidgetStyleConfig, WidgetProductGroups } from '../interfaces';
import { BorderStyleConfig, FontStyleConfig } from 'src/app/shared/interface';
import { GroupedItemStyle } from '../interfaces/grouped-item-style';
import { ImageWidgetStyleConfig } from '../interfaces/image-widget-style-config';
import { MenuDisplayItem } from '../interfaces/menu-display-item';
import { ProductTag } from '../interfaces/product-tag';
import { ItemWidgetStyleConfig } from '../interfaces/item-widget-style-config';
import { WidgetBaseConfig } from '../interfaces/widget-base-config';
import { WidgetDetails } from '../interfaces/widget-details';
import { WidgetDisplayCombineItem } from '../interfaces/widget-display-combine-item';
import { WidgetHeaderConfig } from '../interfaces/widget-header-config';
import { WidgetHeaderStyleConfig } from '../interfaces/widget-header-style-config';
import { WidgetSaveModel } from '../interfaces/widget-save-model';
import { WidgetStyleConfig } from '../interfaces/widget-style-config';
import { BaseWidgetCrudService } from './base-widget-crud-service.service';
import { GridsterItem } from 'angular-gridster2';
import { TextWidgetConfig } from '../interfaces/text-widget-config';
import { DomainConstants } from 'src/app/shared/constants/domain-constants';
import { ComboSalesProductModel, WidgetComboItemModel } from '../interfaces/widget-combo-item-model';
import { SeparatorStyle } from '../interfaces/separator-style';
import { GroupWidgetStyleConfig } from '../interfaces/group-widget-style-config';

@Injectable({
    providedIn: 'root'
})
export class MenuWidgetService extends BaseWidgetCrudService {
    constructor(private httpClient: HttpClient) {
        super(httpClient);
    }

    getNewImageWidgetStyle(): ImageWidgetStyleConfig {
        const imageWidgetStyle: ImageWidgetStyleConfig = {
            Height: 100,
            Width: 100,
            ImagePosition: 'Center',
            BorderStyle: this.getNewBorderStyleConfig(),
            TitleFontStyle: this.getNewFontStyleConfig(),
            FooterFontStyle: this.getNewFontStyleConfig(),
            ItemFontStyle: this.getNewFontStyleConfig(),
            WidgetBackgroundColor: '',
            WidgetHeaderStyleConfig: this.getNewWidgetHeaderStyleConfig(),
            ListItemStyle: {},
            Horizontal: 'center',
            Vertical: 'center',
            OffsetX: '0px',
            OffsetY: '0px',
            BlurRadius: '0px',
            ShadowColor: '',
        };
        return imageWidgetStyle;
    }

    getNewBorderStyleConfig(): BorderStyleConfig {
        return {
            borderStyle: 'none',
            borderColor: 'black',
            borderRadius: ''
        };
    }
    getNewFontStyleConfig(): FontStyleConfig {
        return {
            fontSize: '',
            color: '',
            fontStyle: '',
            fontFamily: '',
            fontWeight: ''
        };
    }

    getNewWidgetHeaderStyleConfig(): WidgetHeaderStyleConfig {
        return {
            BackgroundColor: '',
            TextFontStyle: this.getNewFontStyleConfig(),
            PriceInfoStyle: this.getNewFontStyleConfig(),
            HeaderHeight: null,
            textAlign: 'left',
            ShowHeaderBorder: false
        };
    }

    getNewWidgetDetails(): WidgetDetails {
        return {
            Id: 0,
            MenuDisplayItems: [],
            WidgetStyleConfig: null,
            WidgetConfig: null,
            WidgetComboItems: [],
            WidgetType: '',
            MenuDisplayId: null,
            Name: '',
        };
    }

    getNewWidgetDisplayCombineItem(): WidgetDisplayCombineItem {
        return {
            IsInStock: true,
            Items: [],
            SalesProductId: null,
            SubItems: [],
            Id: 0,
            IsShowPrice: null,
            Image: null,
            Video: null,
            WidgetProductGroupId: null,
            Text: null,
            Tags: null,
            DietaryWarnings: null,
            DefaultSizeId: null,
            Description: null,
            Prices: null,
            Color: null,
            IsInteractive: false,
            InteractiveStyleClass: ''
        };
    }

    getNewWidgetStyleConfig(): WidgetStyleConfig {
        return {
            BorderStyle: this.getNewBorderStyleConfig(),
            TitleFontStyle: this.getNewFontStyleConfig(),
            FooterFontStyle: this.getNewFontStyleConfig(),
            ItemFontStyle: this.getNewFontStyleConfig(),
            WidgetBackgroundColor: '',
            WidgetHeaderStyleConfig: this.getNewWidgetHeaderStyleConfig(),
            Horizontal: 'center',
            Vertical: 'center'
        };
    }

    getGroupWidgetStyleConfig(): GroupWidgetStyleConfig {
        return {
            BorderStyle: {
                borderTop: '5px',
                borderBottom: '5px',
                borderLeft: '5px',
                borderRight: '5px',
                borderStyle: 'solid',
                borderColor: '#78ea30',
                borderRadius: ''
            }
        };
    }

    getNewBaseConfig(): WidgetBaseConfig {
        return {
            WidgetHeaderConfig: this.getNewWidgetHeaderConfig(),
            TitleText: '',
            FooterText: '',
            ProductDisplayOrdinal: []
        };
    }

    getNewWidgetHeaderConfig(): WidgetHeaderConfig {
        return {
            Image: '',
            Text: '',
            PriceInfo: '',
            Badge: '',
        };
    }

    getNewWidgetProduct(): WidgetProductGroups {
        return {
            Id: 0,
            MenuWidgetId: 0,
            Name: '',
            GroupConfig: '',
            Price: '',
            Ordinal: 0
        };
    }

    getNewMenuDisplayItem(): MenuDisplayItem {
        return {
            Id: 0,
            Text: '',
            Image: '',
            Video: '',
            Tags: [],
            DietaryWarnings: [],
            Description: '',
            Prices: [],
            Color: null,
            DefaultSizeId: null,
            IsShowPrice: false,
            Ordinal: null,
            IsInteractive: false,
            InteractiveStyleClass: ''
        };
    }

    getNewListWidgetStyle(): ListWidgetStyleConfig {
        return {
            DisplayListStyle: 'none',
            DescriptionStyle: this.getNewFontStyleConfig(),
            PriceStyle: this.getNewFontStyleConfig(),
            BorderStyle: this.getNewBorderStyleConfig(),
            TitleFontStyle: this.getNewFontStyleConfig(),
            FooterFontStyle: this.getNewFontStyleConfig(),
            ItemFontStyle: this.getNewFontStyleConfig(),
            WidgetBackgroundColor: '',
            WidgetHeaderStyleConfig: this.getNewWidgetHeaderStyleConfig(),
            GroupedItemStyle: this.getNewGroupedItemStyle(),
            ListItemStyle: {},
            Vertical: 'center',
            Horizontal: 'center',
            Width: '',
            GroupListBehavior: '',
            SeparatorStyle: this.getNewSeparatorStyle(),
            PriceTableConfig: this.getNewPriceTableConfig(),
            TagConfig: this.getNewTagConfig(),
        };
    }

    getNewPriceTableConfig(): PriceTableConfig {
        return {
            ShowPriceAsTable: false,
            Width: '20%',
            SalesSizes: [],
            CellPadding: '',
        }
    }

    getNewSeparatorStyle(): SeparatorStyle {
        return {
            BorderStyle: 'solid',
            BackgroundColor: 'transparent',
            Margin: '',
            Direction: 'column',
            Padding: '',
        }
    }

    getNewProductWidgetStyle(): ItemWidgetStyleConfig {
        return {
            DescriptionStyle: this.getNewFontStyleConfig(),
            PriceStyle: this.getNewFontStyleConfig(),
            BorderStyle: this.getNewBorderStyleConfig(),
            TitleFontStyle: this.getNewFontStyleConfig(),
            FooterFontStyle: this.getNewFontStyleConfig(),
            ItemFontStyle: this.getNewFontStyleConfig(),
            WidgetBackgroundColor: '',
            WidgetHeaderStyleConfig: this.getNewWidgetHeaderStyleConfig(),
            Vertical: 'center',
            Horizontal: 'center',
            TagConfig: this.getNewTagConfig(),
        };
    }

    getNewTilesWidgetStyle(): TilesWidgetStyleConfig {
        return {
            ...this.getNewWidgetStyleConfig(),
            TileBorder: this.getNewBorderStyleConfig(),
            Title: this.getNewFontStyleConfig(),
            Description: this.getNewFontStyleConfig(),
            Price: this.getNewFontStyleConfig(),
            TileHeight: 0,
            TilesPerRow: 0,
            TileBackgroundColor: '',
        }
    }

    getNewComboListWidgetStyleConfig(): ComboListWidgetStyleConfig {
        return {
            ...this.getNewWidgetStyleConfig(),
            ItemWidth: '',
            ItemHeight: '',
            DescriptionStyle: this.getNewComboListDescriptionStyleConfig()
        }
    }

    getNewComboListDescriptionStyleConfig(): ComboListDescriptionStyleConfig {
        return {
            ...this.getNewFontStyleConfig(),
            backgroundColor: ''
        }
    }

    getNewGroupedItemStyle(): GroupedItemStyle {
        return {
            GroupedItemPosition: null,
            ...this.getNewFontStyleConfig()
        };
    }

    getNewProduct(): ProductTag {
        return {
            Id: 0,
            Name: '',
            Color: '',
            Icon: '',
        };
    }


    getNewProductPrice(): ProductSize {
        return {
            SizeId: 0,
            Name: '',
            IsInStock: false,
            MakeTableSize: '',
        };
    }

    newWidgetComboItemModel(): WidgetComboItemModel {
        return {
            Description: '',
            Ordinal: 1,
            SalesProductId: null,
            ComboSalesProduct: []
        };
    }

    newWidgetComboSalesProduct(): ComboSalesProductModel {
        return {
            Ordinal: 1,
            BackgroundColor: '',
            DisplayText: '',
            FontColor: '',
            BorderStyle: '',
        }
    }

    getNewGridsterItem(): GridsterItem {
        return {
            x: 0,
            y: 0,
            rows: 1,
            cols: 1,
        }
    }

    getNewOutOfStockBehavior(): OutOfStockBehavior {
        return {
            Text: '',
            BehaviorType: '',
            TextStyleConfig: this.getNewFontStyleConfig(),
            ItemColor: '',
            TextDecoration: '',
        }
    }

    getNewImageListWidgetConfig(): ImageListWidgetConfig {
        return {
            WidgetHeaderConfig: this.getNewWidgetHeaderConfig(),
            ProductDisplayOrdinal: [],
            IsShowImageText: false,
            OutOfStockBehavior: this.getNewOutOfStockBehavior(),
        }
    }

    getNewListWidgetConfig(): ListWidgetConfig {
        return {
            Separator: '',
            ShowInlinePrices: [],
            IsShowProductPrice: false,
            IsShowPriceSize: false,
            IsShowProductImage: false,
            IsShowItemDescription: false,
            IsUseMakeTableColor: false,
            OutOfStockBehavior: this.getNewOutOfStockBehavior(),
            TitleText: '',
            FooterText: '',
            HideTags: false,
            WidgetHeaderConfig: this.getNewWidgetHeaderConfig(),
            ProductDisplayOrdinal: [],
            ShowIndentedText: true,
        }
    }

    getNewItemWidgetConfig(): ItemWidgetConfig {
        return {
            IsShowProductPrice: false,
            IsShowPriceSize: false,
            IsShowProductImage: false,
            IsShowItemDescription: false,
            IsUseMakeTableColor: false,
            OutOfStockBehavior: this.getNewOutOfStockBehavior(),
            HideTags: false,
            WidgetHeaderConfig: this.getNewWidgetHeaderConfig(),
            DisplayText: '',
            Image: '',
            Price: 0,
        }
    }

    getNewTextWidgetConfig(): TextWidgetConfig {
        return { Text: '', Orientation: DomainConstants.Orientation.Horizontal }
    }

    getNewImageWidgetConfig(): ImageWidgetConfig {
        return { Image: '' }
    }

    getNewWidgetSaveModel(): WidgetSaveModel {
        return {
            Id: 0,
            MenuDisplayId: 0,
            Name: '',
            WidgetType: '',
            WidgetDetails: '',
            WidgetStyle: '',
            WidgetConfig: '',
            SalesCategories: [],
            SalesGroups: [],
            SalesTags: [],
            SalesProductId: 0,
            ComboItems: [],
            WidgetProductGroups: [],
        }
    }
    getWidgetDetails(id) {
        return this.httpClient.get<WidgetSaveModel>(`${this.apiEndpoint}/${id}/details`);
    }

    getNewTagConfig(): TagConfig {
        return {
            verticalAlign: 'top',
        }
    }
}
