import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GridColumn } from '@tarktech/tark-ng-utils';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { finalize } from 'rxjs/operators';
import { upload } from 'src/app/shared/components/icon';
import { saveAs } from 'file-saver';
import { InventoryProductService } from '../../../../shared/services/inventory-product.service';
import { InventoryReceived } from '../../../../shared/interface/inventory-received';
import { ModalComponent } from './../../../../shared/components/modal/modal-component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'pos-recipes-included',
    templateUrl: './recipes-included.component.html',
    styleUrls: ['./recipes-included.component.scss']
})
export class RecipesIncludedComponent extends ModalComponent implements OnInit {

    @Input('inventoryProductName') inventoryProductName: string;
    @Input('inventoryProductId') inventoryProductId: number;
    recipeIncluded: Array<InventoryReceived> = [];
    @Output() closePopup = new EventEmitter();
    recipeIncludedColumns: Array<GridColumn> = [];
    isMobileMode = false;

    public icons = { upload };

    constructor(
        modalRef: BsModalRef,
        protected alertService: AlertsService,
        protected spinnerService: SpinnerService,
        private deviceService: DeviceDetectorService,
        private inventoryProductService: InventoryProductService
    ) {
        super(modalRef);
    }

    ngOnInit() {
        this.configureGridColumns();
        this.loadData();
        this.isMobileMode = this.deviceService.isMobile();
    }

    private configureGridColumns() {

        this.recipeIncludedColumns = [
            new GridColumn({ HeaderText: 'Sales Product', Field: 'ProductName', IsSortable: true, Width: '25%' }),
            new GridColumn({ HeaderText: 'Size', Field: 'SizeName', IsSortable: true, Width: '25%' }),
            new GridColumn({ HeaderText: 'Qty.', Field: 'Qty', IsSortable: true, Width: '25%' }),
            new GridColumn({ HeaderText: 'Unit', Field: 'UnitName', IsSortable: true, Width: '25%' })
        ] as Array<GridColumn>;
    }

    private loadData(): void {
        this.spinnerService.show();
        this.inventoryProductService.getIncludedRecipe(this.inventoryProductId)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (response) => {
                    this.recipeIncluded = response ? response : [];
                }, error: this.alertService.showApiError
            });
    }

    public cancel() {
        this.hide({});
        this.closePopup.emit(true);
    }

    public exportAction(tableId: string) {
        const fileName = this.inventoryProductName.replace(' ', '-') + '-RecipeIncluded.xls';
        const blob = new Blob([document.getElementById(tableId).outerHTML], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
        });
        saveAs(blob, fileName);
    }
}
