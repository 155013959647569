import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AccountDetailViewComponent } from 'src/app/information-management/account/component/account-detail-view/account-detail-view.component';
import { AccountEditModelComponent } from 'src/app/information-management/account/component/account-edit-model';
import {
  AccountTypeService, AlertsService, ApplicationStateService, AuthenticationService, DomainConstants, Levels, Messages, ModalComponent, ModalService,
  OrderEventBroadcastingService, OrderService, Permissions, SettingParam, SpinnerService, SwipeCardComponent
} from 'src/app/shared';
import { accountAddressCard, accountPrint, accountTelephone, loyaltyCreditCard, memberMoreDetails, mapMarkerAlt, plusWhite } from 'src/app/shared/components/icon';
import { Account, AccountType } from '../information-management/account/interface';
import { AccountService } from '../shared/services/account.service';
import { forEach } from 'lodash';
declare let $: any;
@Component({
  selector: 'pos-set-account',
  templateUrl: './set-account.component.html',
  styleUrls: ['./set-account.component.scss']
})
export class SetAccountComponent extends ModalComponent implements OnInit {
  searchAccount = '';
  accounts: Array<Account> = [];
  accountMaxHeight = 0;
  accountType: string;
  @Input() orderId: number;
  selectedAccountId: any;
  @Input() isTypeAccount = false;
  @Input() fromOrderManagement = false;
  @Output() onSave = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @Input() isSelectMember = false
  accountTypeId: number;
  accountTypeNames = DomainConstants.AccountTypes;
  settingParam: SettingParam;
  accountTypes: Array<AccountType> = [];
  isKeyboardWedgeEnabled = true;
  swipeModal: any;
  public icons = {
    accountTelephone,
    accountAddressCard,
    accountPrint,
    memberMoreDetails,
    mapMarkerAlt,
    plusWhite,
    loyaltyCreditCard
  };
  accountTerm: string = '';
  permission = {
    Name: Permissions.CustomersAccounts,
    EditLevel: Levels.Edit
  }

  constructor(modalRef: BsModalRef,
    private accountService: AccountService,
    private orderService: OrderService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private applicationStateService: ApplicationStateService,
    private modalService: ModalService,
    private authenticationService: AuthenticationService,
    private accountTypeService: AccountTypeService,
    private eventBroadcastingService: OrderEventBroadcastingService) {
    super(modalRef);
  }


  ngOnInit() {
    this.getAccounts();
    this.settingParam = this.applicationStateService.settingParam;
    this.accountTerm = this.settingParam.AccountTerm ? this.settingParam.AccountTerm : 'Tab';
  }

  getAccounts() {
    this.accountMaxHeight = 150;
    const accountObservable = [];
    this.spinnerService.show();
    accountObservable.push(this.accountService.getAccountByDetails());
    accountObservable.push(this.accountTypeService.getAccountTypes());
    forkJoin(accountObservable)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (responses: any) => {
          this.accounts = responses?.[0] ? responses[0] : [];
          this.accountTypes = responses?.[1] ? responses[1] : [];
          this.filterAccount();
          this.setSearchableAccountAttributes();
          const setMaxHeight = setTimeout(() => {
            this.accountMaxHeight = Math.max.apply(null, $('.cust-account').map(function () {
              return $(this).height();
            }).get());
            this.accountMaxHeight = this.accountMaxHeight - 5;
            clearTimeout(setMaxHeight);
          });
        }, error: this.alertService.showApiError
      });
  }

  filterAccount() {
    const accountType = _.find(this.accountTypes, (item) => {
      return item.AccountType === (this.isTypeAccount ? this.accountTypeNames.Account : this.accountTypeNames.Tab);
    });
    this.accountTypeId = accountType ? accountType.Id : 0;
    this.accounts = _.filter(this.accounts, (acc) => {
      return acc.AccountTypeId === this.accountTypeId;
    });
  }

  setSearchableAccountAttributes() {
    forEach(this.accounts, account => {
      account.SearchableAccountAttributes = account.AccountAttributes?.filter(x => x.IsSearchable && x.AttributeValue);
    });
  }

  accountSelect = (account: Account) => {
    if (this.orderId > 0) {
      this.spinnerService.show();
      const updatedAccount = { OrderId: this.orderId, AccountId: account.Id };
      this.orderService.updateAccountId(updatedAccount)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (response) => {
            this.eventBroadcastingService.onSetAccountCompleted(account);
            if (this.fromOrderManagement) {
              this.onSave.emit({ reload: true });
              if (this.isSelectMember) {
                this.hide({ shouldReload: false });
              }
            } else {
              this.hide({ shouldReload: false, account: account });
            }
          }, error: this.alertService.showApiError
        });
    } else {
      const accountType = _.find(this.accountTypes, (item) => {
        return item.Id === account.AccountTypeId;
      });
      if (accountType) {
        account.AccountType = accountType.AccountType;
      }
      this.hide({ shouldReload: true, account: account });
      this.eventBroadcastingService.onSetAccountCompleted(account);
    }

  }

  closeModal() {
    this.hide({ shouldReload: false });
    if (this.fromOrderManagement) {
      this.onClose.emit({ reload: false });
    }
  }

  addNewAccount() {
    const modalRef = this.modalService.getModalWrapper(AccountEditModelComponent);
    const modal = modalRef.show({
      animated: false,
      backdrop: 'static',
      keyboard: false,
      class: 'vertical-center modal-lg modal-max-width-65',
      initialState: {
        accountId: 0,
        accountTypeId: this.accountTypeId
      }
    });
    modal.close.subscribe(res => {
      if (res?.event?.shouldReload) {
        this.accountSelect(res.event.account);
      }
    });
  }

  onPrintAccountDetails(accountId: number, event: Event) {
    event.stopPropagation();
    if (this.settingParam.ReceiptPrinter?.Id) {
      this.spinnerService.show();
      const printObj = {
        AccountId: accountId,
        TerminalId: this.applicationStateService.terminalId,
        TerminalName: this.applicationStateService.terminalName
      };
      this.accountService.printAccountDetails(printObj)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res) => {
          }, error: this.alertService.showApiError
        });
    } else {
      this.alertService.renderErrorMessage(Messages.ReceiptPrinterNotMapped);
    }
  }

  addAccountForLoyaltyCard = () => {
    const loyaltyCardModalRef = this.modalService.getModalWrapper(SwipeCardComponent);
    this.swipeModal = loyaltyCardModalRef.show({
      animated: false,
      class: 'vertical-center',
      backdrop: 'static',
      keyboard: false,
      initialState: {
        modalHeaderText: 'Loyalty Card',
        message: Messages.SwipeCardRequest
      }
    });
    this.swipeModal.close.subscribe(res => {
      if (res?.cardNumber) {
        this.onSwipeDetected(res.cardNumber);
      }
    });
  }

  onSwipeDetected(loyaltyCardNumber, name: string = null) {
    const currentAccount = _.find(this.accountTypes, (type) => {
      return type.AccountType === this.accountTypeNames.Account;
    });
    const currentAccountTypeId = currentAccount ? currentAccount.Id : 0;
    const accountForLoyaltyInfo = _.find(this.accounts, (account) => {
      return account.AccountTypeId === currentAccountTypeId && account.LoyaltyCardNumber === loyaltyCardNumber;
    });
    if (!accountForLoyaltyInfo) {
      this.spinnerService.show();
      let account: Account = this.accountService.newAccount();
      account.Name = name ?? 'Member ' + loyaltyCardNumber.toString();
      account.LoyaltyCardNumber = loyaltyCardNumber.toString();
      account.AccountTypeId = 1;
      this.accountService.saveAccountDetails(account)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res) => {
            account = res;
            this.accountSelect(account);
          }, error: this.alertService.showApiError
        });

    } else {
      this.accountSelect(accountForLoyaltyInfo);
    }
    this.applicationStateService.enableAttendanceCardSwipe = true;
    // }
  }

  openAccountDetails(account, event: Event) {
    event.stopPropagation();
    let modalRef;
    const userHasPermission = this.authenticationService.userHasPermission([
      //   { Name: Permissions.ManageInformationManagement, Level: Levels.Access },
      // { Name: Permissions.InfoMgmtCustomers, Level: Levels.Access },
      { Name: Permissions.CustomersAccounts, Level: Levels.Edit }],
      'all');
    if (userHasPermission) {
      modalRef = this.modalService.getModalWrapper(AccountEditModelComponent);
    } else {
      modalRef = this.modalService.getModalWrapper(AccountDetailViewComponent);
    }
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center modal-lg modal-max-width-65',
      backdrop: 'static',
      keyboard: false,
      initialState: {
        accountId: account.Id,
        accountDetails: account
      }
    });
    modal.close.subscribe(() => {
      if (userHasPermission) {
        this.getAccounts();
      }
    });
  }
}
