<div class="width-80 m-0-auto">
    <div class="page-content">
        <div class="tab-content">
            <div class="tab-pane fade in active show">
                <div class="col-12 px-3 pb-pix-5">
                    <div class="row">
                        <div class="col-lg-10 col-sm-8 col-6 pe-0 align-items-center display-flex">
                            <h3 class="page-content__header pi-0">
                                <pos-icon class="me-pix-5 black font-17"
                                    [icon]="icons.inactiveButtonsDiceOne"></pos-icon>Inactive Buttons
                            </h3>
                        </div>
                        <div class="col-lg-2 col-sm-4 col-6 ps-0 text-align-right">
                            <button class="btn btn-secondary" style="font-weight: bold pos-button"
                                (click)="reloadInactiveProducts()">
                                <pos-icon [icon]="icons.sync"></pos-icon> Refresh
                            </button>
                            <span (click)="cancel()" class="pointer ps-pix-20 pe-pix-8 font-20 grey vertical-mid">
                                <pos-icon [icon]="icons.times"></pos-icon>
                            </span>
                        </div>
                    </div>
                    <div>
                        <ng-table [scrollable]="true" [scrollHeight]="screenHeight.toString()" #gridComponent
                            [columns]="inActiveButtonColumns" [data]="inActiveButtonList">
                        </ng-table>
                        <ng-template let-data="rowData" #iconTemplate>
                            <span *ngIf="(data.Image !== null && data.Image !== '')">
                                <img alt="" class="image-size" src="{{imagePath}}/buttons/{{data.Image}}" height="50px"
                                    width="50px" />
                            </span>
                        </ng-template>
                        <ng-template let-data="rowData" #toggleTemplate>
                            <span *ngIf="data.Toggle" class="badge badge-success param-badge">
                                <pos-icon [icon]="icons.check"></pos-icon>
                            </span>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<pos-confirm-delete [message]="message" (onConfirmAcknowledge)="onConfirmAcknowledge()" #confirmButtonDelete>
</pos-confirm-delete>
