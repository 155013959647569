import { OnChanges, ViewChild, Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { newAudioFile, messageCode } from '../../../../shared/components/icon';
import { ModalService, DomainConstants, HardwareModel, AudioNotificationService, SpinnerService, AlertsService, ModalFormComponent, FormUtilityService } from 'src/app/shared';
import * as _ from 'lodash';
import { AudioNotificationEditComponent } from '../../../../information-management/audio-notification-files/components/audio-notification-edit/audio-notification-edit.component';
import { Permissions, Levels } from '../../../../shared/constants';
import { AuthenticationService } from '../../../../shared/auth/services/authentication.service';
import { Options } from '@angular-slider/ngx-slider/options';
import { NgForm } from '@angular/forms';
import { EventSubscriptionActionAttribute } from '../../interfaces/event-subscription-action-attribute';
import { find, isNull } from 'lodash';
import { AudioNotificationFile } from '../../../../information-management/audio-notification-files/interfaces/audio-notification-file';
import { finalize } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RepeatActionConfigurationComponent } from '../repeat-action-configuration';
@Component({
  selector: 'pos-select-audio-notification',
  templateUrl: './select-audio-notification.component.html',
  styleUrls: ['./select-audio-notification.component.scss']
})
export class SelectAudioNotificationComponent extends ModalFormComponent implements OnInit, OnChanges {
  get getForm(): NgForm {
    return this.audioNotificationActivityEditForm;
  }
  public icons = {
    newAudioFile,
    messageCode
  };
  @ViewChild('RepeatActionConfiguration') repeatActionConfiguration: RepeatActionConfigurationComponent;
  @ViewChild('audioNotificationActivityEditForm', { static: true }) audioNotificationActivityEditForm: NgForm;
  delaySeconds: number;
  @Input() actionId = 0;
  @Input() eventSubscriptionActionAttributes: Array<EventSubscriptionActionAttribute> = [];
  @Input() deliveryTypes = [];
  selectedDeliveryType;
  @Input() eventIdentifier: string;
  deliveryAttributes = DomainConstants.EventDeliveryAttributes;

  @Input()
  eventAttributes: Array<string> = [];
  @Input()
  audioNotificationEvent: any;
  @Input()
  isDisabled = false;
  @Input()
  audioDevices: Array<HardwareModel> = [];
  @Input()
  audioFiles: Array<AudioNotificationFile> = [];
  @Input()
  audioText: string;
  @Input()
  audioVoices: Array<string> = [];
  @Input() isRecoverableEvent;
  selectedAudioDevices: Array<number> = [];
  selectedAudioFile: number = null;
  audioTextString = '';
  selectedAudioVoice: string = null;
  cursorPosition = 0;
  selectedEventAttribute: any;
  audioMode = true;
  userHasPermission = false;
  volumeValue = 1;
  isOpenVariable = false;
  options: Options = {
    floor: 1,
    ceil: 10,
    translate: (value: number): string => {
      return '';
    }
  };

  permission = {
    name: Permissions.AudioClips,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };

  constructor(modalService: ModalService, private authenticationService: AuthenticationService,
    private audioNotificationService: AudioNotificationService,
    private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    modalRef: BsModalRef, protected formUtilityService: FormUtilityService) {
    super(modalRef, modalService, formUtilityService);
  }

  ngOnChanges(event) {
    this.cursorPosition = this.audioTextString.length;
    if (this.selectedAudioFile) {
      this.audioMode = true;
    } else if (this.selectedAudioVoice && this.audioTextString) {
      this.audioMode = false;
    }
    if (this.eventAttributes) {
      this.eventAttributes = _.sortBy(this.eventAttributes);
    }
  }

  ngOnInit() {
    this.selectedAudioDevices = find(this.eventSubscriptionActionAttributes,
      x => x.AttributeKey === this.deliveryAttributes.AudioNotification.AudioDevices)
      ?.AttributeValue.split(',').map(x => parseInt(x, 10));
    const selectedAudioFile = parseInt(find(this.eventSubscriptionActionAttributes,
      x => x.AttributeKey === this.deliveryAttributes.AudioNotification.AudioFile)?.AttributeValue, 10);
    this.selectedAudioFile = isNaN(selectedAudioFile) ? null : selectedAudioFile;
    this.selectedAudioVoice = find(this.eventSubscriptionActionAttributes,
      x => x.AttributeKey === this.deliveryAttributes.AudioNotification.SpeakVoiceId)?.AttributeValue;
    this.audioTextString = find(this.eventSubscriptionActionAttributes,
      x => x.AttributeKey === this.deliveryAttributes.AudioNotification.SpeakText)?.AttributeValue ?? '';
    const volume = parseInt(find(this.eventSubscriptionActionAttributes,
      x => x.AttributeKey === this.deliveryAttributes.AudioNotification.AudioVolume)?.AttributeValue, 10);
    this.volumeValue = isNaN(volume) ? 0 : volume;
    if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      this.userHasPermission = true;
    }
    this.audioMode = this.selectedAudioFile ? true : false;
  }

  changeAudioDevice() {
    if (this.selectedAudioDevices.length > 0) {
      const lastSelectedAudioDevice = this.selectedAudioDevices[this.selectedAudioDevices.length - 1];
      if (lastSelectedAudioDevice !== 0) {
        if (this.selectedAudioDevices.includes(0)) {
          const selectedAudioList = [...this.selectedAudioDevices];
          for (let i = 0; i <= selectedAudioList.length - 1; i++) {
            if (this.selectedAudioDevices[i] === 0) {
              this.selectedAudioDevices.splice(i, 1);
            }
          }
        }
      } else {
        this.selectedAudioDevices = [0];
      }
    }
    this.selectedAudioDevices = [...this.selectedAudioDevices];
  }

  onChangeAudioMode() {
    if (this.audioMode) {
      this.audioTextString = '';
      this.selectedAudioVoice = null;
    } else {
      this.selectedAudioFile = null;
    }
  }

  addNewAudioFile() {
    const audioNotificationFileModalRef = this.modalService.show(AudioNotificationEditComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        audioNotifierId: 0,
        audioNotifierName: ''
      }
    });

    audioNotificationFileModalRef.close.subscribe(res => {
      if (res && res.shouldReload && res.Id) {
        this.selectedAudioFile = res.Id;
        this.reloadAudioFiles();
      }
    });
  }

  changeEventAttribute() {
    this.isOpenVariable = false;
    this.audioTextString = this.audioTextString.substring(0, this.cursorPosition) + ' {{' + this.selectedEventAttribute + '}} '
      + this.audioTextString.substring(this.cursorPosition);
    this.selectedEventAttribute = null;
  }

  saveActivity = (isValid: boolean) => {
    if (isValid) {
      const actionAttributes = this.prepareEventActionAttributes();
      this.hide({ shouldReload: true, actionAttributes: actionAttributes, audioFiles: this.audioFiles });
    }
  }

  onClose = (shouldReload: boolean) => {
    this.hide({ shouldReload: shouldReload, audioFiles: this.audioFiles });
  }

  prepareEventActionAttributes() {
    const actionDeliveryAttributes: Array<EventSubscriptionActionAttribute> = [];
    this.addAttribute(this.deliveryAttributes.AudioNotification.AudioDevices, this.selectedAudioDevices.join(),
      actionDeliveryAttributes);
    this.addAttribute(this.deliveryAttributes.AudioNotification.AudioVolume, this.volumeValue.toString(),
      actionDeliveryAttributes);
    this.addAttribute(this.deliveryAttributes.AudioNotification.AudioFile, this.selectedAudioFile?.toString(),
      actionDeliveryAttributes);
    this.addAttribute(this.deliveryAttributes.AudioNotification.SpeakText, this.audioTextString?.toString(),
      actionDeliveryAttributes);
    this.addAttribute(this.deliveryAttributes.AudioNotification.SpeakVoiceId, this.selectedAudioVoice?.toString(),
      actionDeliveryAttributes);
    if (this?.repeatActionConfiguration?.repeatConfig) {
      this.addAttribute(this.deliveryAttributes.AudioNotification.Repeat, this?.repeatActionConfiguration?.isRepeat ? JSON.stringify(this?.repeatActionConfiguration?.repeatConfig) : "", actionDeliveryAttributes)
    }
    return actionDeliveryAttributes;
  }

  getActionAttributeId(key) {
    const attribute = find(this.eventSubscriptionActionAttributes, x => x.AttributeKey === key);
    return attribute ? attribute.Id : 0;
  }

  getCaretPos(text) {
    this.cursorPosition = this.audioTextString.length;
    if (text.selectionStart || text.selectionStart === '0') {
      this.cursorPosition = text.selectionStart;
    }
  }

  addAttribute = (key, value, array) => {
    const actionDeliveryAttribute: EventSubscriptionActionAttribute = {
      Id: this.getActionAttributeId(key),
      AttributeKey: key,
      AttributeValue: value,
      EventSubscriptionActionId: this.actionId
    };
    if ((actionDeliveryAttribute.Id > 0 && !isNull(actionDeliveryAttribute.AttributeValue)) ||
      (actionDeliveryAttribute.Id === 0 && actionDeliveryAttribute.AttributeValue)) {
      array.push(actionDeliveryAttribute);
    }
  }

  reloadAudioFiles() {
    this.spinnerService.show();
    this.audioNotificationService.getAudioNotificationFiles()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (audioFiles: Array<AudioNotificationFile>) => {
          this.audioFiles = audioFiles;
        }, error: this.alertsService.showApiError
      });
  }
}
