<pos-edit-wrapper heading="Print Labels: {{inventoryProduct.name}}" (close)="cancel()">
  <form #printLabelForm="ngForm" (ngSubmit)="printLabel(printLabelForm.valid)" autocomplete="off">
    <div class="pos-modal-body">
      <pos-row>
        <pos-column>
          <pos-form-field label="Product Name" [validationMessages]="{required: 'Please enter product name.'}">
            <input posInput name="productName" #productName="ngModel" type="text" class="form-control"
              id="productName" [(ngModel)]="inventoryProductLabelPrint.ProductName" [required]="true" />
          </pos-form-field>
        </pos-column>

        <pos-column>
          <pos-form-field label="Unit Qty" [validationMessages]="{range: 'Unit quantity must be between 0 to 9999.'}" inputStyle="col-12">
            <div class="row row-cols-lg-4 row-cols-md-3 row-cols-2">
              <div>
                <input posInput name="productUnitQty" #productUnitQty="ngModel" type="number" class="form-control text-right"
                  min="0" max="9999" [range]="[0,9999]" [(ngModel)]="inventoryProductLabelPrint.UnitQty"
                  id="productUnitQty"/>
              </div>
              <div class="col-100">
                <label class="label-section">{{ inventoryProductLabelPrint.UnitQty ? Pluralize(inventoryProductLabelPrint.UnitName, inventoryProductLabelPrint.UnitQty) : inventoryProductLabelPrint.UnitName }}</label>
              </div>
            </div>
          </pos-form-field>
        </pos-column>

        <pos-column>
          <pos-form-field label="Max Portions">
            <input posInput name="productMaxPortionsText" #productMaxPortionsText="ngModel" type="text"
              class="form-control" [(ngModel)]="inventoryProductLabelPrint.MaxPortionsText" />
          </pos-form-field>
        </pos-column>

        <pos-column>
          <pos-form-field label="Measuring Instructions" [warning]="measuringInstructionsWarning" [hasWarning]="inventoryProductLabelPrint.MeasureByInstructions && inventoryProductLabelPrint.MeasureByInstructions.length > 20">
            <input posInput name="productMeasureByInstructions" #productMeasureByInstructions="ngModel" max="100"
              type="text" class="form-control" [(ngModel)]="inventoryProductLabelPrint.MeasureByInstructions" />
          </pos-form-field>
        </pos-column>

        <pos-column>
          <pos-form-field label="Number of Labels" [validationMessages]="{required: 'Please enter print count.', min: 'Please enter print count greater than zero.'}" inputStyle="col-12">
            <pos-row colLg="3" colMd="4" colXs="6">
              <pos-column>
                <input posInput type="number" min="1" class="form-control text-right" name="printLabelCount" #printLabelCount="ngModel"
                  [(ngModel)]="inventoryProductLabelPrint.NumberOfLabels" [required]="true">
              </pos-column>
            </pos-row>
          </pos-form-field>
        </pos-column>
      </pos-row>
    </div>
    <div class="col-12 action-button-container popup-footer">
      <button class="btn btn-primary btn-lg btn-save pos-button" type="submit">Print</button>
      <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="cancel()" type="button">Close</button>
    </div>
  </form>
</pos-edit-wrapper>
