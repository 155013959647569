import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AlertsService, ApplicationStateService, BaseFormComponent, FormUtilityService, Messages, ParameterService, SettingParam, SpinnerService } from 'src/app/shared';
import { bell } from 'src/app/shared/components/icon';
@Component({
  selector: 'pos-notification',
  templateUrl: './notification.component.html',
})
export class NotificationComponent extends BaseFormComponent implements OnInit {
  @ViewChild('notificationForm') notificationForm: NgForm;
  get getForm(): NgForm {
    return this.notificationForm
  }
  parameters: SettingParam;
  icons = {
    bell
  };

  constructor(private applicationStateService: ApplicationStateService,
    private parameterService: ParameterService,
    private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private router: Router,
    formUtilityService: FormUtilityService) {
    super(formUtilityService);
    this.parameters = applicationStateService.newSettingParams();
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.spinnerService.show();
    this.parameterService.getParameterForTerminal(this.applicationStateService.terminalId)
      .pipe(
        finalize(() => {
          this.spinnerService.hide();
        })
      )
      .subscribe({
        next: (res: SettingParam) => {
          this.parameters = res ? res : this.applicationStateService.newSettingParams();
        }, error: this.alertsService.showApiError
      });
  }

  saveNotification = (isValid: boolean) => {
    if(!isValid) {
      return;
    }
    this.spinnerService.show();
    this.parameterService.saveNotification(this.parameters)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.alertsService.renderSuccessMessage(Messages.NotificationSaveSuccess);
          this.router.navigate(['/manage/system-configuration']);
        }, error: this.alertsService.showApiError
      });
  }

  cancel() {
    this.router.navigate(['/manage/system-configuration']);
  }
}
