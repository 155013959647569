import { Directive, ElementRef, Renderer2, Input, OnInit, HostListener } from '@angular/core';

@Directive({
  selector: '[posDecimal]'
})
export class PosDecimalDirective implements OnInit {


  @Input('validDecimal') validDecimal?: number = 2;
  @Input('autoFixed') autoFixed?: boolean = true;
  @Input('isAllowNegative') isAllowNegative?: boolean = false;
  @Input() ngModel: any;

  currentElement: ElementRef;
  elementRenderer: Renderer2;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.currentElement = el;
    this.elementRenderer = renderer;
  }

  ngOnInit(): void {
    if (!this.ngModel) {
      return;
    }
  }

  @HostListener('blur', ['$event.target.value']) onBlur(value) {
    if(value) {
      if (this.autoFixed) {
        if (value || value == 0) {
          value = parseFloat(value).toFixed(this.validDecimal);
        }
      } else {
        if (value[0] === '.') {
          value = parseFloat(value);
        }
      }
      if (isNaN(value)) {
        value = '0';
      }
      this.currentElement.nativeElement.value = parseFloat(value).toFixed(this.validDecimal);
    }
  }

  @HostListener('keypress', ['$event']) onKeypress(event) {
    const keyCode = event.keyCode;
    const enterKeyCode = 13;

    // Allow Enter key
    if (keyCode === enterKeyCode) {
      return;
    }
    
    const currentDecimalCount = this.countDecimalPlaces(this.currentElement.nativeElement.value);
    if (keyCode === 32 || (keyCode === 45 && !this.isAllowNegative)) {
      event.preventDefault();
    }
  }

  countDecimalPlaces(number) {
    if (isNaN(number) || Number.isInteger(number)) {
      return 0;
    } else {
      const decimalPart = String(number).split('.')[1];
      return decimalPart ? decimalPart.length : 0;
    }
  }

}
