import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderEntryModule } from '../header-entry';
import { PICK_SCREEN_STATES } from './pick-screen.route';
import { PickScreenService } from './services/pick-screen.service';
import { PickScreenComponent } from './components/pick-screen/pick-screen.component';
import { PickScreenItemComponent } from './components';
import { ComponentsModule, FormsModule } from '../shared/components';
import { OrderCardSharedModule } from '../order-shared/order-card-shared.module';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(PICK_SCREEN_STATES),
        HeaderEntryModule,
        ComponentsModule,
        FormsModule,
        OrderCardSharedModule
    ],
    declarations: [PickScreenComponent, PickScreenItemComponent],
    providers: [PickScreenService],
    bootstrap: [PickScreenComponent]
})
export class PickScreenModule { }
