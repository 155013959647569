import { iconsVectorSquare, trainingCog } from './../../../../shared/components/icon/icons';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TrainingAreaService } from '../../../../shared/services/training-area.service';
import {
  AlertsService, Messages, SpinnerService, ConfirmDeleteComponent, AuthenticationService,
  Permissions, Levels, PrintTableService, ApplicationStateService
} from 'src/app/shared';
import { GridColumn, EditColumn, DeleteColumn, LinkColumn, TemplateColumn, TableComponent, TextAlign } from '@tarktech/tark-ng-utils';
import { TrainingArea } from '../../interface/training-area';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import * as _ from 'lodash';
import { BaseGridComponent } from 'src/app/shared/components/base-grid.component';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
@Component({
  selector: 'pos-training-areas-list',
  templateUrl: './training-areas-list.component.html',
  styleUrls: ['./training-areas-list.component.scss']
})
export class TrainingAreasListComponent extends BaseGridComponent implements OnInit {
  @ViewChild('confirmTrainingAreaDelete') private confirmTrainingAreaDelete: ConfirmDeleteComponent;
  @ViewChild('iconTemplate', { static: true }) private iconTemplate: any;
  trainingAreas: Array<TrainingArea> = [];
  trainingAreaColumns: Array<GridColumn> = [];
  printTrainingAreaColumns: Array<GridColumn> = [];
  trainingArea: TrainingArea;
  filterColumns: Array<string>;
  public imagePath = RuntimeConstants.IMAGE_BASE_PATH;

  @ViewChild('trainingAreaGrid', { static: true }) trainingAreaGrid: TableComponent;
  public confirmDeleteMessage = Messages.ConfirmDeleteTrainingArea;
  permission = {
    name: Permissions.TrainingAreas,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };

  topicPermission = {
    name: Permissions.TrainingTopics,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };

  icons = { iconsVectorSquare };

  constructor(private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private authenticationService: AuthenticationService,
    private trainingAreaService: TrainingAreaService,
    protected route: ActivatedRoute,
    private router: Router,
    protected applicationStateService: ApplicationStateService,
    private printService: PrintTableService) {
    super(applicationStateService, route);
    this.reloadTrainingAreaList();
    const navigation = router.getCurrentNavigation();
    this.gridContext = navigation?.extras?.state?.GridContext ?? this.gridContext;
  }

  ngOnInit() {
    this.columnConfiguration();
    this.trainingAreaGrid.context = this.gridContext;
  }

  private columnConfiguration() {
    const editColumn = new EditColumn({
      Clicked: (data) => {
        this.editTrainingArea(data.Id);
      },
      Width: '57px',
      AddIcon: new LinkColumn({
        Clicked: () => {
          this.editTrainingArea(0);
        },
        ToolTip: 'New'
      })
    });

    const deleteColumn = new DeleteColumn({
      Clicked: (data) => {
        this.deleteAreaButton(data);
      },
      Width: '68px',
      PrintIcon: new LinkColumn({
        Clicked: () => {
          this.printData();
        }
      })
    });

    const iconColumn = new TemplateColumn({
      HeaderText: 'Icon',
      itemTemplate: this.iconTemplate,
      Width: '10%',
      TextAlign: TextAlign.Center
    });

    const topicIconColumn = new LinkColumn({
      Clicked: (data) => {
        this.onTopicIcon(data);
      },
      CellClass: 'cell-link training-gear-icon',
      BackgroundColor: 'grey',
      Icon: trainingCog.iconClasses + '',
      ToolTip: 'Training Topics',
      Width: '0'
    });

    this.trainingAreaColumns = [
      new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '30%' }),
      new GridColumn({ HeaderText: 'Description', Field: 'Description', IsSortable: true, Width: '40%' }),
      new GridColumn({ HeaderText: 'Total Points', Field: 'TotalScore', IsSortable: true, Width: '11%' }),

    ] as Array<GridColumn>;

    this.printTrainingAreaColumns = [...this.trainingAreaColumns];

    this.filterColumns = ['TrainingTopicsAndLevels.Topics', 'TrainingTopicsAndLevels.Levels'];
    this.trainingAreaColumns.push(iconColumn);

    if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      this.trainingAreaColumns.push(editColumn);
      this.trainingAreaColumns.push(deleteColumn);
    }
    if (this.authenticationService.userHasPermission([{
      Name: this.topicPermission.name,
      Level: this.topicPermission.readOnlyLevel
    }], 'any')) {
      this.trainingAreaColumns.push(topicIconColumn);
    }

  }

  reloadTrainingAreaList() {
    this.spinnerService.show();
    this.trainingAreaService.getTrainingAreas()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
      next: (res) => {
        _.forEach(res, (area) => {
          area.TrainingTopicsAndLevels = { Topics: [], Levels: [] };
          _.forEach(area.trainingTopics, (topic) => {
            area.TrainingTopicsAndLevels.Topics.push(topic.Name);
            _.forEach(topic.TrainingTopicLevels, (level) => {
              area.TrainingTopicsAndLevels.Levels.push(level.Description);
            });
          });
        });
        if (res) {
          this.trainingAreas = res;
        } else {
          this.trainingAreas = [];
        }
      },
      error: this.alertService.showApiError
    });
  }

  gridRowReorder(event) {
    const dragElementIndex = event.dragIndex;
    const dropElementIndex = event.dropIndex;
    if (dragElementIndex < dropElementIndex) {
      this.trainingAreas[dropElementIndex - 1].Ordinal = dropElementIndex;
    } else {
      this.trainingAreas[dropElementIndex].Ordinal = dropElementIndex + 1;
    }
    this.trainingAreaService.updateOrdinal(dragElementIndex < dropElementIndex ?
      this.trainingAreas[dropElementIndex - 1] : this.trainingAreas[dropElementIndex])
      .subscribe({
        next: () => { },
        error: this.alertService.showApiError
      });
  }

  deleteAreaButton($event: TrainingArea) {
    this.trainingArea = $event;
    this.confirmTrainingAreaDelete.Show(this.confirmDeleteMessage);
  }

  editTrainingArea(areaId: number) {
    this.router.navigate([areaId], { relativeTo: this.route });
  }
  onTopicIcon(data: any) {
    this.router.navigate([data.Id, 'topics'], { state: { areaId: data.Id, areaName: data.Name, GridContext: this.gridContext }, relativeTo: this.route });
  }
  onConfirmAcknowledge() {
    this.spinnerService.show();
    this.trainingAreaService.deleteTrainingArea(this.trainingArea.Id)
    .pipe(finalize(() => {
      this.spinnerService.hide();
    }))
    .subscribe({
      next: () => {
        this.alertService.renderSuccessMessage(
          Messages.TrainingAreaDeleteSuccess
        );
        this.reloadTrainingAreaList();
      },
      error: this.alertService.showApiError
    });
  }

  printData() {
    this.printService.printEmitter.next({ gridColumns: this.printTrainingAreaColumns, gridData: this.trainingAreas });
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
