<pos-edit-wrapper heading="{{ trackId > 0 ? 'Edit' : 'Add' }} Track" (close)="cancel()" [headingIcon]="icons.music"
  [isPopup]="false">
  <form #formTrack="ngForm" class="edit-form" (ngSubmit)="saveTrack(formTrack.valid)">
    <context-provider provide="props">
      <div class="col-12 pos-page-body border-top pt-pix-5">
        <div class="col-12">
          <pos-row>
            <pos-column *ngIf="displayMode==displayModes.Add">
              <pos-form-field label="File" [fieldInfo]="fieldInfoMessages.TracksFileName">
                <input posInput #fileInput accept=".mp3" class="form-control trackFiles" name="trackFiles"
                  (change)="filesSelected($event.target.files)" type="file" [required]="true" />
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field label="Track Name" [validationMessages]="{required: 'Please enter name.'}">
                <input posInput class="form-control" name="Filename" [(ngModel)]="fileName" type="text"
                  [required]="true" #name="ngModel" />
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field label="Category" [fieldInfo]="fieldInfoMessages.TracksCategoryName">
                <input posInput class="form-control" name="Category" [(ngModel)]="track.Category" type="text" />
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field>
                <p-checkbox posInput name="chkUseDefaultVolume" [(ngModel)]="isUseDefaultVolume" binary="true"
                  (onChange)="setDefaultVolume()" label="Use default volume">
                </p-checkbox>
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field label="Volume" *ngIf="!isUseDefaultVolume">
                <pos-row>
                  <pos-column xs="11">
                    <ngx-slider [(value)]="track.Volume" [options]="options" class="m-0"></ngx-slider>
                  </pos-column>
                  <pos-column xs="1" class="p-0">
                    <span class="badge mt-pix-n2 volume-circle">{{track.Volume ? track.Volume : 0}}</span>
                  </pos-column>
                </pos-row>
              </pos-form-field>
            </pos-column>
          </pos-row>
        </div>
      </div>
      <div class="col-12 action-button-container page-footer">
        <button class="btn-lg btn-primary btn btn-save pos-button" type="submit">
          Save
        </button>
        <button class="btn btn-secondary btn-lg btn-cancel pos-button" type="button" (click)="redirectToTracks()">
          Cancel
        </button>
      </div>
    </context-provider>
  </form>
</pos-edit-wrapper>
