import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImageInterceptorService {

  private renderer: Renderer2;
  private observer: MutationObserver;
  private cachedImages: Map<string, HTMLImageElement> = new Map<string, HTMLImageElement>();

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  public setupImageInterception() {
    try {
      // Setup MutationObserver to dynamically observe changes in the DOM
      this.observer = new MutationObserver(mutationsList => {
        mutationsList.forEach(mutation => {
          // Handle added nodes for images or elements with background images
          if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
            mutation.addedNodes.forEach(node => {
              if (node instanceof HTMLElement) {
                if (node.tagName === 'IMG') {
                  this.handleImage(node as HTMLImageElement);
                } else {
                  this.checkBackgroundImage(node as HTMLElement);
                }
              }
            });
          }
          // Handle attribute changes for dynamically updated images or background images
          else if (mutation.type === 'attributes' && mutation.target instanceof HTMLElement) {
            const target = mutation.target as HTMLElement;
            if (mutation.attributeName === 'src' && target.tagName === 'IMG') {
              this.handleImage(target as HTMLImageElement);
            } else if (mutation.attributeName === 'style') {
              this.checkBackgroundImage(target);
            }
          }
        });
      });

      // Start observing mutations in the document body and its subtree
      this.connectObserver();
      // Initial setup to observe existing img tags and elements with background images
      this.observeExistingImages();
    } catch (error) {
      console.error('Error setting up image interception:', error);
    }
  }

  connectObserver() {
    try {
        this.observer.observe(document.body, { childList: true, subtree: true, attributes: true, attributeFilter: ['src', 'style'] });
    } catch (error) {
        console.error('Error reconnecting observer:', error);
    }
}

  private observeExistingImages() {
    try {
      // Function to observe existing img tags on page load
      const images = document.querySelectorAll('img');
      images.forEach((img: HTMLImageElement) => {
        this.handleImage(img);
      });

      // Check existing elements for background images
      const elements = document.querySelectorAll('*');
      elements.forEach((element: HTMLElement) => {
        this.checkBackgroundImage(element);
      });
    } catch (error) {
      console.error('Error observing existing images:', error);
    }
  }

  private handleImage(img: HTMLImageElement) {
    const src = img.src;
    if (src && (src.endsWith('.jpg') || src.endsWith('.png'))) {
      if (this.cachedImages.has(src)) {
        const cachedImg = this.cachedImages.get(src);
        this.displayImage(cachedImg, img);
      } else {
        this.cacheAndLoadImage(img).then(() => {
          this.displayImage(img, img);
        }).catch((error) => {
          console.error(`Failed to load image: ${src}`, error);
        });
      }
    }
  }

  private checkBackgroundImage(element: HTMLElement) {
    const computedStyle = getComputedStyle(element);
    const backgroundImage = computedStyle.backgroundImage;
    if (backgroundImage && (backgroundImage.includes('.jpg') || backgroundImage.includes('.png'))) {
      const imageUrl = this.extractImageUrl(backgroundImage);
      if (imageUrl) {
        if (this.cachedImages.has(imageUrl)) {
          const cachedImg = this.cachedImages.get(imageUrl);
          this.displayBackgroundImage(element, cachedImg);
        } else {
          const tempImg = new Image();
          tempImg.onload = () => {
            this.cacheImage(imageUrl, tempImg);
            this.displayBackgroundImage(element, tempImg);
          };
          tempImg.onerror = (error) => {
            console.error(`Failed to load background image: ${imageUrl}`, error);
          };
          tempImg.src = imageUrl;
        }
      }
    }
  }

  private cacheImage(imageUrl: string, img: HTMLImageElement) {
    this.cachedImages.set(imageUrl, img);
  }

  private extractImageUrl(backgroundImage: string): string | null {
    const match = backgroundImage.match(/url\(['"]?(.*?)['"]?\)/);
    return match ? match[1] : null;
  }

  private async cacheAndLoadImage(img: HTMLImageElement): Promise<void> {
    const src = img.src;
    try {
      await this.loadImage(img);
      this.cacheImage(src, img);
    } catch (error) {
      throw error;
    }
  }

  private loadImage(img: HTMLImageElement): Promise<void> {
    return new Promise((resolve, reject) => {
      img.onload = () => resolve();
      img.onerror = (error) => reject(error);
    });
  }

  private displayImage(cachedImg: HTMLImageElement, originalImg: HTMLImageElement) {
    if (cachedImg && cachedImg.complete) {
        this.disconnectObserver();
        originalImg.src = cachedImg.src;
        this.connectObserver();
    }
}

private displayBackgroundImage(element: HTMLElement, img: HTMLImageElement) {
    if (img && img.complete) {
        this.disconnectObserver();
        this.renderer.setStyle(element, 'backgroundImage', `url(${img.src})`);
        this.connectObserver();
    }
}


disconnectObserver() {
  try {
      this.observer.disconnect();
  } catch (error) {
      console.error('Error disconnecting observer:', error);
  }
}

  ngOnDestroy(): void {
    try {
      this.disconnectObserver();
    } catch (error) {
      console.error('Error cleaning up image interceptor:', error);
    }
  }
}
