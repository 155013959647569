import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertsService, ModalComponent, SpinnerService, Messages, ModalService, ConfirmDeleteModalComponent } from 'src/app/shared';
import { DeleteColumn, GridColumn } from '@tarktech/tark-ng-utils';
import { OnlineOrderingCategoriesService, OnlineOrderingCategoryProductService } from '../../../service';
import { ConfirmDeleteComponent } from 'src/app/shared/components/confirm-delete/confirm-delete.component';
import { zip } from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'pos-online-ordering-option-product-configuration',
  templateUrl: './online-ordering-option-product-configuration.component.html',
  styleUrls: ['./online-ordering-option-product-configuration.component.scss']
})
export class OnlineOrderingOptionProductConfigurationComponent extends ModalComponent implements OnInit {

  optionList: Array<any> = [];
  mappedOptionList: Array<any> = [];
  optionProductList: Array<any> = [];
  mappedOptionListColumns: Array<GridColumn> = [];
  selectedOption: any;
  screenHeight = 150 + 'px';
  productId: number;
  productName: string;
  showSelectionOption: boolean = false;
  deleteMappedOptionId: number = 0;
  public message = Messages.ConfirmDeleteMenuButtonConfiguration;
  constructor(commentModalRef: BsModalRef,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private onlineOrderingCategoriesService: OnlineOrderingCategoriesService,
    private onlineOrderingCategoryProductService: OnlineOrderingCategoryProductService,
    private modalService: ModalService) {
    super(commentModalRef);
  }

  ngOnInit() {
    this.columnConfiguration();
    this.getConfigurationDetails()
  }

  getConfigurationDetails() {
    if (this.productId > 0) {
      this.spinnerService.show();
      zip(
        this.onlineOrderingCategoriesService.getOnlineOrderingCategories(),
        this.onlineOrderingCategoryProductService.getOnlineOrderingProductConfiguration(this.productId),
        (
          categoryData: any,
          configurationData: any
        ) => {
          this.optionList = _.filter(categoryData, (orderingCategories) => {
            return orderingCategories.IsOption == true;
          });
          this.optionList = _.orderBy(this.optionList, ['Name']);
          this.mappedOptionList = configurationData;
        }
      ).subscribe({
        next: (res) => {
        }, error: this.alertService.showApiError,
        complete: () => {
          this.spinnerService.hide();
        }
      });

    }
  }



  private columnConfiguration() {
    const deleteColumn = new DeleteColumn({
      Clicked: (data) => {
        this.deleteMappedConfiguration(data);
      },
      Width: '40px'
    });


    this.mappedOptionListColumns = [
      new GridColumn({ HeaderText: 'Options', Field: 'MenuCategory.Name', IsSortable: true, Width: '88%' })
    ] as Array<GridColumn>;
    this.mappedOptionListColumns.push(deleteColumn);
  }

  onShowSelectionOption(data: any) {
    if (this.selectedOption == data.Option) {
      this.showSelectionOption = !this.showSelectionOption;
    }
    else {
      this.showSelectionOption = true;
    }
    this.selectedOption = data.Option;
  }

  onCancel(reload: boolean) {
    this.hide({ shouldReload: reload });
  }

  addOption() {
    if (this.mappedOptionList && this.mappedOptionList.length > 0) {
      var index = _.findIndex(this.mappedOptionList, (optionProduct) => {
        return optionProduct.OptionId == this.selectedOption;
      });

      if (index >= 0) {
        this.alertService.renderErrorMessage(Messages.ErrorWhileOnlineOrderingAddingDuplicateMenuButtonOption);
        return;
      }
    }

    if (this.selectedOption) {
      let menuButtonConfiguration = {
        ButtonId: this.productId,
        OptionId: this.selectedOption,
        Ordinal: 0
      }
      this.spinnerService.show();
      this.onlineOrderingCategoryProductService.addMenuButtonConfiguration(menuButtonConfiguration).subscribe(
        () => {
          this.alertService.renderSuccessMessage(Messages.MenuButtonConfigurationAdded);
          this.getConfigurationDetails();
          this.selectedOption = null;
        }, this.alertService.showApiError,
        () => {
          this.spinnerService.hide();
        }
      )
    }
  }

  deleteMappedConfiguration(data) {
    this.deleteMappedOptionId = data.Id;
    const modelRef = this.modalService.show(ConfirmDeleteModalComponent ,{
      animated: false,
      initialState: {
        message: this.message
      }
    });
    modelRef.close.subscribe((res)=>{
      if(res.shouldDelete){
        this.onConfirmAcknowledge();
      }
    })
  }

  onConfirmAcknowledge() {
    this.spinnerService.show();
    this.onlineOrderingCategoryProductService.deleteMenuButtonConfiguration(this.deleteMappedOptionId).subscribe(
      () => {
        this.alertService.renderSuccessMessage(
          Messages.MenuButtonConfigurationDeleted
        );
        this.deleteMappedOptionId = 0;
        this.getConfigurationDetails();
      },
      this.alertService.showApiError,
      () => {
        this.spinnerService.hide();
      }
    );
  }

  gridRowReorder(event) {
    this.onlineOrderingCategoryProductService.updateOnlineOrderingMenuButtonConfigurationOrdinal(this.mappedOptionList).subscribe({
      next: (res) => { this.mappedOptionList = res ? res : this.mappedOptionList; },
      error: this.alertService.showApiError
    });
  }
}
