<div>
	<button class="close" (click)="onCancel()">&times;</button>
	<h4 class="modal-content__heading">Training Approval
	</h4>
</div>
<div class="col-md-12 pos-modal-body mt-3">
	<ng-table [scrollable]="true" [scrollHeight]="'600px'" #gridComponent [sort]="false" [columns]="trainingInfoColumns"
		[data]="trainingInfo">
	</ng-table>
	<ng-template let-data="rowData" #approveTemplate>
		<div class="pt-pix-4 float-start pointer p-pix-7 font-18" (click)="onReview(data)">
			<pos-icon [icon]="icons.thList"></pos-icon>
		</div>

		<div class="pt-pix-3 pe-0 float-start pointer p-pix-5 font-18-5 position-relative" (click)="addNotes(data)">
			<pos-icon [icon]="icons.trainingApproveNote"></pos-icon>
			<span class="badge badge-sm bg-theme-separator ms-pix-n7 bold position-absolute mt-pix-n9"
				*ngIf="data.UserTrainingNotesCount > 0">{{data.UserTrainingNotesCount}}</span>
		</div>
		<span class="pt-pix-7 float-end white" *ngIf="!data.ShowApproveButton" style="font-size: 14px;">
			<b>{{data.ShowTimeForApprove}}</b>
		</span>

		<button *ngIf="data.ShowApproveButton" type="button" class="btn btn-primary btn-sm normal white float-end pos-button mt-pix-3"
			value="Approve" (click)="onApprove(data)" scrollup>Approve</button>

	</ng-template>
</div>
<div class="col-12 action-button-container popup-footer pb-pix-10">
	<button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" type="button" (click)="onCancel()">Close</button>
</div>
<div class="clearfix"></div>
