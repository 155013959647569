<div class="tab-top-border border-top-0" [ngStyle]="{'height':getHeight(false)}">
  <div class="col-md-8 col-sm-12 col-xs-12 mt-pix-15">
    <ng-table [columns]="inventoryZoneEditBinsListColumns" [exportFilename]="'inventory-zone-bins'"
      [data]="inventoryBinListData" [paginator]="false" [search]="false"></ng-table>
  </div>
</div>
<pos-confirm-delete (onConfirmAcknowledge)="onConfirmAcknowledge()" #confirmDeleteOption>
</pos-confirm-delete>
<pos-print-table #printTableReference [hidden]="true"></pos-print-table>

<ng-template #popOverForInventoryBinName let-data="rowData">
  <span #pop="bs-popover" class="grey popover-zone pointerStyle" placement="right" container="body"
    (click)="openInventoryProductForBin(data,pop)  " [outsideClick]="true" [popover]="inventoryBinNamePopoverTemplate"
    triggers="click" #popoverTooltip><span container="body" tooltip="Click to see products"
      placement="top">{{data.Bin}}</span></span>
</ng-template>

<ng-template #inventoryBinNamePopoverTemplate>
  <pos-inventory-products-for-bin [inventoryZoneBinId]="selectedId"></pos-inventory-products-for-bin>
</ng-template>
