<pos-list-wrapper [headingIcon]="promotionType.Icon" [heading]="promotionType.DisplayName" (close)="close()">
    <div class="row">
        <div class="col-12 relative">
            <div class="label-section active-label">
                <p-checkbox name="chkActive" #chkActive="ngModel" (onChange)="changePromotionList()"
                    [(ngModel)]="showInactivePromotions" binary="true" label="Show Inactive" >
                </p-checkbox>
            </div>
            <ng-table #promotionGrid [columns]="promotionColumns" [exportFilename]="promotionType.DisplayName"
                [isReorderableTable]="true" [ReorderableColumnRow]="'row'"
                (onRowReorder)="promotionGridRowReorder($event, filteredPromotions)" [data]="filteredPromotions">
            </ng-table>
            <ng-template let-data="rowData" #activeTemplate>
                <span *ngIf="(data.IsActive)" class="green vertical-mid font-13">
                    <pos-icon [icon]="icons.check"></pos-icon>
                </span>
            </ng-template>
            <ng-template let-data="rowData" #operationTemplate>
                <span class="pe-pix-3" (click)="editItem(data.Id)" scrollup tooltip title="Edit">
                    <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
                </span>
                <span *ngIf="(data.IsActive)" class="pt-pix-3" (click)="updatePromotionActiveStatus(data, false)"
                    tooltip title="Click here to deactivate this promotion">
                    <pos-action-icon [icon]="icons.ban"></pos-action-icon>
                </span>
                <span *ngIf="!(data.IsActive)" class="pt-pix-3" (click)="updatePromotionActiveStatus(data, true)"
                    tooltip title="Click here to activate this promotion">
                    <pos-action-icon [icon]="icons.checkCircleWhite"></pos-action-icon>
                </span>
            </ng-template>
            <ng-template #headerTemplate>
                <div class="text-center">
                    <pos-action-icon (click)="editItem(0)" [icon]="icons.plusWhite" iconText="Add">
                    </pos-action-icon>
                    <pos-export-dropdown class="inline-block ps-pix-5" (onPrint)="printData()"
                        (onCSV)="exportCSV()"></pos-export-dropdown>
                </div>
            </ng-template>
        </div>
    </div>
</pos-list-wrapper>
<pos-print-table [hidden]="true"></pos-print-table>
