import { Component, OnInit, ViewChild } from '@angular/core';
import { FieldInfoMessages, SpinnerService, AlertsService, Messages, ModalService, FormUtilityService, BaseCrudComponent } from 'src/app/shared';
import { DjSchemeService } from '../../services';
import { DjScheme } from '../../interface';
import { finalize } from 'rxjs/operators';
import { PlaylistsService, Playlist, PlaylistTrack } from 'src/app/configurator/playlists';
import { forkJoin, Observable } from 'rxjs';
import { ReorderTagsComponent } from '../reorder-tags/reorder-tags.component';
import * as _ from 'lodash';
import { Options } from '@angular-slider/ngx-slider/options';
import { ActivatedRoute, Router } from '@angular/router';
import { remove } from 'lodash';
import { djSchemesRoad } from 'src/app/shared/components/icon/icons';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'pos-dj-scheme-edit',
  templateUrl: './dj-scheme-edit.component.html',
  styleUrls: ['./dj-scheme-edit.component.scss']
})
export class DjSchemeEditComponent extends BaseCrudComponent<DjScheme> implements OnInit {
  get getForm(): NgForm {
    return this.formDJScheme
  }
  @ViewChild('formDJScheme') formDJScheme: NgForm;
  djScheme: DjScheme;
  id = 0;
  fieldInfoMessages = FieldInfoMessages;
  templatesTransParam: any;
  playlists: Array<Playlist>;
  djSchemes: Array<DjScheme>;
  playlistTracks: Array<PlaylistTrack> = [];
  pattern = [];
  volumeOptions: Options = {
    floor: 0,
    ceil: 100,
    translate: (value: number): string => {
      return '';
    }
  };
  options: Options = {
    floor: 0,
    ceil: 100,
    translate: (value: number): string => {
      return '';
    }
  };
  endAction = 'None';
  isUseDefaultVolume = false;
  icons = {
    djSchemesRoad
  }
  props = {
    controlBoxClass: 'col-lg-3 col-md-5 col-sm-8 col-xs-12'
  }

  constructor(protected spinnerService: SpinnerService,
    protected alertService: AlertsService,
    protected router: Router,
    private route: ActivatedRoute,
    private djSchemeService: DjSchemeService,
    private playlistService: PlaylistsService,
    private modalService: ModalService,
    formUtilityService: FormUtilityService) {
    super(djSchemeService, alertService, spinnerService, formUtilityService);
    this.id = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
    this.djScheme = djSchemeService.newDJScheme();
  }

  ngOnInit() {
    this.saveSuccessMessage = Messages.DJSchemeSaveSuccess;
  }

  loadDependencies(): void {
    const djSchemeObservable: Array<Observable<any>> = [];
    djSchemeObservable.push(this.playlistService.getAll());
    djSchemeObservable.push(this.djSchemeService.getAll());
    if (this.id > 0) {
      djSchemeObservable.push(this.djSchemeService.get(this.id));
    }
    this.spinnerService.show();
    forkJoin(djSchemeObservable)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: ([playlists, djSchemes, djScheme]: [Array<Playlist>, Array<DjScheme>, DjScheme]) => {
          this.playlists = playlists ? playlists : [];
          this.djSchemes = djSchemes ? djSchemes : [];
          this.djSchemes = this.djSchemes.filter(x => x.Id !== this.id);
          if (this.id > 0) {
            this.djScheme = djScheme;
            this.isUseDefaultVolume = !this.djScheme.Volume;
            this.reloadPlaylistTracks();
            this.loadDataCompleted();
          }
        }
      });
  }

  loadDataCompleted() {
    const volume = this.djScheme.Volume;
    if (this.djScheme.EndAction === this.id) {
      this.djScheme.OnComplete = null;
    }
    if (this.djScheme.EndAction == null) {
      this.djScheme.OnComplete = null;
      this.endAction = 'None';
    } else {
      if (this.djScheme.EndAction === this.id) {
        if (this.djScheme.OnComplete != null) {
          this.djScheme.OnComplete = this.id;
        }
        this.endAction = 'Repeat';
      } else {
        if (this.djScheme.OnComplete != null || this.djScheme.OnComplete === undefined) {
          this.djScheme.OnComplete = this.djScheme.EndAction;
        }
        this.endAction = 'Play';
      }
    }
    if (this.djScheme.StartTime != null || this.djScheme.EndTime != null) {
      this.djScheme.Scheduled = true;
    }
    this.djScheme.Pattern = (this.djScheme.PlayPattern !== '' && this.djScheme.PlayPattern !== null) ? true : false;
    if (this.djScheme.Pattern) {
      this.pattern = this.djScheme.PlayPattern.split(',');
    }
    setTimeout(() => {
      this.djScheme.Volume = volume;
    }, 100);
  }

  reloadPlaylistTracks() {
    if (this.djScheme.PlaylistId) {
      this.spinnerService.show();
      this.playlistService.getPlaylistTrackCategory(this.djScheme.PlaylistId)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res: Array<PlaylistTrack>) => {
            this.playlistTracks = res;
            remove(this.playlistTracks, x => !x.Category);
          }
        });
    }
  }

  timeToDateTime(timeString) {
    const timeTokens = timeString.split(':');
    if (timeTokens && timeTokens.length > 0) {
      return new Date(1970, 0, 1, timeTokens[0], timeTokens[1]).getTime();
    }
  }

  saveDjScheme(isValid: boolean) {
    if (!isValid) {
      return;
    }
    if (this.djScheme.Scheduled && this.djScheme.StartTime && this.djScheme.EndTime) {
      if (this.timeToDateTime(this.djScheme.StartTime) > this.timeToDateTime(this.djScheme.EndTime)) {
        this.alertService.renderErrorMessage(Messages.ErrorWhileStartTimeIsGreaterEndTime);
        return;
      }
    }
    this.djScheme.PlayPattern = this.djScheme.Pattern ? this.pattern.join(',') : '';
    if (!this.djScheme.StartFade) {
      this.djScheme.StartFadeSeconds = null;
      this.djScheme.FadeInVolumeIncrement = 0;
    }
    if (!this.djScheme.Scheduled) {
      this.djScheme.StartTime = null;
      this.djScheme.EndTime = null;
    }
    if (this.endAction != null) {
      if (this.endAction === 'Play') {
        this.djScheme.EndAction = this.djScheme.OnComplete;
      } else if (this.endAction === 'Repeat') {
        if (this.djScheme.Id === 0) {
          this.djScheme.EndAction = 0;
        } else {
          this.djScheme.EndAction = this.djScheme.Id;
        }
      } else if (this.endAction === 'None') {
        this.djScheme.EndAction = null;
      }
    }
    this.save(this.djScheme);
  }

  onSaveSuccess(model: DjScheme): void {
    this.router.navigate(['dj-scheme'], { relativeTo: this.route.parent });
  }

  reorderTags() {
    const modalRef = this.modalService.getModalWrapper(ReorderTagsComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      initialState: {
        tags: this.pattern
      }
    });
    modal.close.subscribe(res => {
      if (res && res.tags) {
        this.pattern = _.cloneDeep(res.tags);
      }
    });
  }

  onChangeIsUseDefaultVolume() {
    if (this.isUseDefaultVolume) {
      this.djScheme.Volume = 0;
    }
  }

  cancel() {
    this.router.navigate(['dj-scheme'], { relativeTo: this.route.parent });
  }
}
