<pos-edit-wrapper (close)="cancelCopy()" heading="Copy From Product: {{productName}}">
  <form #copyProductIngredientsInfo="ngForm" (ngSubmit)="copyProductIngredients(copyProductIngredientsInfo.valid)"
    autocomplete="off">
    <div class="col-md-12 px-4 form-horizontal screen-scroll">

      <pos-row colXs="12" class="p-0">
        <pos-column>
          <pos-form-field label="Target Product" [validationMessages]="{required: 'Please select target product'}"
            inputStyle="col-lg-8 col-md-8 col-sm-8 col-xs-12">
            <ng-select posInput #inventoryProduct="ngModel" name="inventoryProduct" appendTo="body" [items]="inventoryProducts"
              bindLabel="Name" [(ngModel)]="destinationProduct" placeholder="Select target product" [required]="true">
            </ng-select>
          </pos-form-field>
        </pos-column>

        <pos-column>
          <pos-form-field>
            <p-checkbox posInput name="productIngredients" #productIngredients="ngModel" (onChange)="onChangeSelection()"
              [(ngModel)]="ingredients" binary="true" label="Ingredients">
            </p-checkbox>
          </pos-form-field>
        </pos-column>

        <pos-column>
          <pos-form-field>
            <p-checkbox posInput name="productServings" #productServings="ngModel" (onChange)="onChangeSelection()"
              [(ngModel)]="servings" binary="true" label="Servings">
            </p-checkbox>
          </pos-form-field>
        </pos-column>

        <pos-column>
          <pos-form-field>
            <p-checkbox posInput name="productNutrition" #productNutrition="ngModel" (onChange)="onChangeSelection()"
              [(ngModel)]="nutrition" binary="true" label="Nutrition">
            </p-checkbox>
          </pos-form-field>
        </pos-column>

        <pos-column>
          <pos-form-field>
            <p-checkbox posInput name="productDietary" #productDietary="ngModel" (onChange)="onChangeSelection()"
              [(ngModel)]="dietaryWarnings" binary="true" label="Dietary Warnings">
            </p-checkbox>
          </pos-form-field>
        </pos-column>
      </pos-row>

    </div>
    <div class="clearfix"></div>

    <div class="col-12 action-button-container popup-footer">
      <button class="btn-primary btn-lg btn btn-save pos-button" type="submit" value="Save">Copy</button>
      <button class="btn-secondary btn-lg btn btn-cancel-modal pos-button" (click)="cancelCopy()" type="button" value="Cancel">Cancel
      </button>
    </div>

  </form>
</pos-edit-wrapper>
