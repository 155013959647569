<div class="width-98 m-auto">
    <div class="page-content">
        <div class="tab-content">
            <div class="tab-pane fade in active show">
                <div class="col-12 p-0">
                    <button class="col-2 btn-secondary btn-lg btn btn-cancel pos-button float-end mb-pix-10 col-70"
                        (click)="cancel()" type="button" value="Cancel">Back</button>
                    <div class="col-9 p-0 display-flex ps-pix-20">
                        <pos-icon [icon]="icons.barcodeScan"
                            class="pe-pix-10 vertical-center-align page-content__header-icon"></pos-icon>
                        <h3 class="page-content__header">Inventory Barcode Batch</h3>
                    </div>
                    <div class="pos-page-body col-12 border-top pt-pix-10 inventory-batch-list-scroll">
                        <div class="col-12 p-0 batch-item" *ngFor="let batch of inventoryScannerBatch; let i = index">
                            <pos-row colXs="12" colStyleClass="row mx-0" class="mx-0">
                                <pos-column>
                                    <div class="col-8 p-0 pt-pix-15 display-flex flex-auto">
                                        <pos-icon [icon]="icons.barcode"
                                            class="pe-pix-10 font-16"></pos-icon>
                                        <label class="bold-700 mb-0 font-16">{{batch.InventoryName}}&nbsp;</label>
                                    </div>
                                    <div class="col-1 col-35 float-end me-pix-25 pt-pix-10">
                                        <button (click)="editBatch(batch.InventoryId)" type="button"
                                            class="btn btn-secondary normal barcode-batch-edit">
                                            <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
                                        </button>
                                    </div>
                                    <div class="col-1 p-0 col-35 float-end pt-pix-10">
                                        <button (click)="deleteBatch(i)" type="button"
                                            class="btn btn-secondary normal barcode-batch-delete">
                                            <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
                                        </button>
                                    </div>
                                </pos-column>
                                <pos-column class="pt-pix-5" *ngIf="batch.PrimaryZoneName && batch.PrimaryQty">
                                    <label class="col-md-10 col-8 p-0 bold-700 mb-0">{{batch.PrimaryZoneName}}</label>
                                    <div class="col-md-2 col-4 p-0 text-align-right">
                                        {{batch.PrimaryQty | tark_number }} <span
                                            class="ms-pix-2">{{batch.Unit}}(s)</span>
                                    </div>
                                </pos-column>
                                <pos-column class="pt-pix-5" *ngIf="batch.SecondaryZoneName && batch.SecondaryQty">
                                    <label class="col-md-10 col-8 p-0 bold-700 mb-0">{{batch.SecondaryZoneName}}</label>
                                    <div class="col-md-2 col-4 p-0 text-align-right">
                                        {{batch.SecondaryQty | tark_number }} <span
                                            class="ms-pix-2">{{batch.Unit}}(s)</span>
                                    </div>
                                </pos-column>
                                <pos-column class="pt-pix-5" *ngIf="batch.OtherQty">
                                    <label class="col-md-10 col-8 p-0 bold-700 mb-0">Other</label>
                                    <div class="col-md-2 col-4 p-0 text-align-right">
                                        {{batch.OtherQty | tark_number }} <span
                                            class="ms-pix-2">{{batch.Unit}}(s)</span>
                                    </div>
                                </pos-column>
                                <pos-column class="pt-pix-5" *ngIf="batch.Memo">
                                    <label class="col-md-10 col-8 p-0 bold-700 mb-0">Memo</label>
                                    <div class="col-md-2 col-4 p-0">
                                        {{batch.Memo}}
                                    </div>
                                </pos-column>
                                <pos-column class="pt-pix-5 section-border">
                                    <label class="col-md-10 col-8 p-0 bold-700 mb-0">Total</label>
                                    <div class="col-md-2 col-4 p-0 text-align-right bold-700">
                                        {{batch.TotalQty | tark_number }} <span
                                            class="ms-pix-2">{{batch.Unit}}(s)</span>
                                    </div>
                                </pos-column>
                            </pos-row>
                        </div>
                        <div *ngIf="!inventoryScannerBatch.length">
                            <label>No Batch Jobs.</label>
                        </div>
                    </div>
                    <div class="col-12 action-button-container page-footer">
                        <button class="btn-primary btn-lg btn btn-save pos-button" type="button"
                            (click)="updateInventoryQty()" [disabled]="inventoryScannerBatch?.length == 0"
                            value="Save">Confirm
                            <span class="count-circle">{{inventoryScannerBatch?.length ?? 0}}</span></button>
                        <button class="btn-secondary btn-lg btn normal pos-button btn-cancel btn-danger"
                            (click)="deleteAllBatch()" type="button" value="delete"
                            [disabled]="inventoryScannerBatch?.length == 0">Cancel Batch</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
