import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridColumn } from '@tarktech/tark-ng-utils/table/grid-column';
import { TableComponent } from '@tarktech/tark-ng-utils/table/table.component';
import { remove } from 'lodash';
import { AuthenticationService } from 'src/app/shared/auth/services/authentication.service';
import { BaseListComponent } from 'src/app/shared/components/base-list.component';
import { deleteWhite, editWhite, plusWhite, printWhite, scroll } from 'src/app/shared/components/icon/icons';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import { Permissions, Levels, Messages, DomainConstants } from 'src/app/shared/constants';
import { MacroScript } from '../../interface/macro-script';
import { MacroScriptService } from '../../service/macro-script-service';
import { StringUtils } from 'src/app/shared';

@Component({
  selector: 'pos-macro-scripts',
  templateUrl: './macro-scripts.component.html',
  styleUrls: ['./macro-scripts.component.scss']
})
export class MacroScriptsComponent extends BaseListComponent<MacroScript> implements OnInit {
  macroScriptList: Array<MacroScript> = [];
  macroScriptColumns: Array<GridColumn> = [];
  printMacroScriptColumns: Array<GridColumn> = [];
  icons = {
    plusWhite,
    printWhite,
    editWhite,
    deleteWhite,
    scroll
  };
  permission = {
    name: Permissions.AutomatedExportsConfiguration,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };

  @ViewChild('operationTemplate', { static: true }) operationTemplate: TemplateRef<any>;
  @ViewChild('operationHeaderTemplate', { static: true }) operationHeaderTemplate: TemplateRef<any>;
  @ViewChild('macroScripts', { static: true }) dataSourceTable: TableComponent;

  constructor(private authenticationService: AuthenticationService,
    protected macroScripService : MacroScriptService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    private router: Router,
    protected applicationStateService: ApplicationStateService,
    protected printService: PrintTableService,
    protected route: ActivatedRoute
  ) {
    super(
      macroScripService,
      alertService,
      spinnerService,
      modalService,
      applicationStateService,
      printService,
      route
    );
  }

  ngOnInit(): void {
    this.macroScriptColumns = this.configureGridColumns();
    this.deleteSuccessMessage = Messages.MacroScriptDeleted;
    this.loadSubscriptions();
    this.checkPermission();
    this.dataSourceTable.context = this.gridContext;
  }

  private loadSubscriptions(): void {
    this.data$.subscribe({ next: (res) => {
      this.macroScriptList = res;
    }});
  }

  private checkPermission(): void {
    if (!this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      remove(this.macroScriptColumns, (column) => {
        return (column.Type === DomainConstants.GridColumnsTypes.EditColumn
          || column.Type === DomainConstants.GridColumnsTypes.DeleteColumn);
      });
    }
  }

  getGridColumns(): GridColumn[] {
    const columns = [
      new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '90%' })
    ];
    this.printMacroScriptColumns = [...columns];
    return columns;
  }

  editItem(id: number): void {
    this.router.navigate([id], { relativeTo: this.route });
  }

  getConfirmDeleteMessage(macroScript: MacroScript): string {
    return StringUtils.format(Messages.MacroScriptDeleteConfirm, {macroScript: macroScript.Name});
  }

  printData() {
    this.printService.printEmitter.next({
      gridColumns: this.printMacroScriptColumns,
      gridData: this.macroScriptList,
    });
  }

  close() {
    this.router.navigate(['manage/automated-exports/configuration']);
  }
}
