import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GridColumn } from '@tarktech/tark-ng-utils';
import { InventoryInfoModel } from '../../../interface';
import { filter } from 'lodash';
declare let $: any;

@Component({
  selector: 'pos-inventory-zone-edit-products',
  templateUrl: './inventory-zone-edit-products.component.html',
  styleUrls: ['./inventory-zone-edit-products.component.scss']
})
export class InventoryZoneEditProductsComponent implements OnInit, OnChanges {

  @Input() productList: Array<InventoryInfoModel>;
  isActive:boolean = true;
  height: number;
  inventoryZoneEditProductsListColumns: Array<GridColumn> = [];
  productListData: Array<InventoryInfoModel> = [];
  filteredProducts: Array<InventoryInfoModel> = [];
  constructor() { }

  ngOnInit() {
    this.configureGridColumnsInventoryProducts();
    this.height = $(window).height() - 370;
  }

  ngOnChanges(change: SimpleChanges) {
    this.productListData = this.productList;
    this.filterActiveProduct();
  }
  filterActiveProduct() {
    this.filteredProducts = filter(this.productListData, (product)=>{
      return product.IsActive == this.isActive;
    })
  }
  configureGridColumnsInventoryProducts() {
    this.inventoryZoneEditProductsListColumns = [
      new GridColumn({ HeaderText: 'Inventory Product', Field: 'Name', IsSortable: true, Width: '30%' }),
      new GridColumn({ HeaderText: 'Bin', Field: 'PrimaryBin', IsSortable: true, Width: '30%' }),
      new GridColumn({ HeaderText: 'Type', Field: 'ZoneType', IsSortable: true, Width: '30%' }),
    ];
  }

  getHeight(isOuterContainer: boolean) {
    if (isOuterContainer === true) {
      return $(window).height() - 290 + 'px';
    } else {
      return $(window).height() - 310 + 'px';
    }
  }

}
