<pos-edit-wrapper heading="Order Confirm" [headingIcon]="icons.checkDouble" (close)="cancelEditing()" [isPopup]="false">
  <form #configForm="ngForm" class="edit-form" (ngSubmit)="saveConfig(configForm.valid)">
    <div class="col-12 pos-page-body border-top">
      <div class="col-12">
        <pos-row>
          <pos-column>
            <pos-form-field label="Order Appreciation Text"
              [validationMessages]="{required: 'Please Enter Order Appreciation Text.'}">
              <textarea posInput class="form-control" name="orderAppreciationText"
                [(ngModel)]="parameters.OrderKioskOrderConfirmAppreciationText" [required]="true"
                #orderAppreciationText="ngModel"> </textarea>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Image" inputStyle="col-12">
              <pos-row>
                <pos-column md="5" sm="8" xs="12">
                  <input type="file" class="form-control" accept=".png,.jpg,.jpeg,.gif,.bmp" #image name="image"
                    id="image" (change)="handleFileInput($event.target.files)">
                </pos-column>
                <pos-column md="3" sm="4" xs="6">
                  <pos-row class="p-0">
                    <pos-column md="3" sm="5" xs="8" class="text-right pe-0" [hidden]="!showIcon">
                      <img
                        src="{{imageBasePath}}/order-kiosk/default-screens/{{parameters.OrderKioskOrderConfirmImage}}"
                        height="50px" #orderConfirmImage id="orderConfirmImage" />
                    </pos-column>
                    <pos-column sm="4" xs="2" class="mt-pix-n5" (click)="removeIcon()" [hidden]="!showIcon">
                      <pos-icon [icon]="icons.times" class="pointer remove-icon-badge"></pos-icon>
                    </pos-column>
                  </pos-row>
                </pos-column>
              </pos-row>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Screen Idle Timeout">
              <input posInput posOnlyNumbers name="userIdleTimeout" class="form-control col-120"
                [(ngModel)]="parameters.OrderKioskOrderConfirmScreenIdleTimeout" />
            </pos-form-field>
          </pos-column>
        </pos-row>
        <div class="col-12 section-border">
          <div>
            <h3 class="setting-title">Order Style Settings</h3>
          </div>
          <pos-row colXs="12" class="pos-title-content">

            <pos-column sm="6" xs="12">
              <pos-form-field label="Vertical" inputStyle="col-6">
                <ng-select posInput name="buttonVertical" bindLabel="Name" bindValue="Value"
                  [items]="alignItemsVertical" [(ngModel)]="style.VerticalAlignment" [clearOnBackspace]="false"
                  appendTo="body" placeholder="Select vertical alignment"></ng-select>
              </pos-form-field>
            </pos-column>

            <pos-column sm="6" xs="12">
              <pos-form-field label="Vertical Position" inputStyle="col-6 row row-cols-2 mx-0">
                <input posInput name="verticalPosition" type="number" class="form-control col-120"
                  [(ngModel)]="style.VerticalPosition" />
                <label class="col-30 edit-form__section__label"> px </label>
              </pos-form-field>
            </pos-column>

            <pos-column sm="6" xs="12">
              <pos-form-field label="Text Color">
                <pos-color-picker posInput name="textColor" [(ngModel)]="style.FontColor"></pos-color-picker>
              </pos-form-field>
            </pos-column>

            <pos-column sm="6" xs="12">
              <pos-form-field label="Background Color">
                <pos-color-picker posInput name="bgColor" [(ngModel)]="style.BackgroundColor"></pos-color-picker>
              </pos-form-field>
            </pos-column>

          </pos-row>
        </div>
      </div>
    </div>
    <div class="col-xs-12 action-button-container page-footer">
      <div class="form-horizontal">
        <button class="btn btn-primary btn-lg btn-save pos-button" scrollup type="submit" value="Save">Save</button>
        <button class="btn btn-secondary btn-lg btn-cancel pos-button" routerLink="../" scrollup type="button"
          value="Cancel">Cancel</button>
      </div>
    </div>
  </form>
</pos-edit-wrapper>
