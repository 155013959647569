import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertsService, FormUtilityService, ModalFormComponent, ModalService, SalesSizeService, SpinnerService } from 'src/app/shared';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SalesSize } from '../../interface';
import { Messages } from 'src/app/shared/constants/ui-messages';

@Component({
  selector: 'pos-sales-size-edit',
  templateUrl: './sales-size-edit.component.html',
  styleUrls: ['./sales-size-edit.component.scss']
})
export class SalesSizeEditComponent extends ModalFormComponent implements OnInit {
  @ViewChild('salesSizeForm', { static: true }) form: NgForm;
  salesSizeId: number;
  salesSizeData: SalesSize;
  tagsList: string[];
  get getForm(): NgForm {
    return this.form
  }
  props = {
    labelClass: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
    controlBoxClass: 'col-lg-8 col-md-8 col-sm-8 col-xs-12'
}
  constructor(
    modalRef: BsModalRef,
    private salesSizeService: SalesSizeService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    modalService: ModalService,
    formUtilityService: FormUtilityService
  ) {
    super(modalRef, modalService, formUtilityService);
    this.salesSizeData = this.salesSizeService.newSalesSize();
    //  {
    //   Id: null,
    //   Name: '',
    //   MakeTableSize: '',
    //   CliCodes: '',
    //   IsDirectLinkToInventoryProduct: false,
    //   SalesProductId: null,
    //   SizeId: null,
    // };
    this.tagsList = [];
  }

  ngOnInit(): void {
    this.getSalesSizeDetails(this.salesSizeId);
    this.setInitialValue();
  }

  getSalesSizeDetails(id: number) {
    if (id > 0) {
      this.spinnerService.show();
      this.salesSizeService.getSalesSize(id).subscribe(
        response => {
          this.salesSizeData = response;
          this.tagsList = response.CliCodes ? response.CliCodes.split(',') : [];
        },
        this.alertService.showApiError,
        () => {
          this.spinnerService.hide();
        }
      );
    } else {
      this.salesSizeData = this.salesSizeService.newSalesSize();
    }
  }

  saveSalesSize(isValid: boolean) {
    if (!isValid) {
      return;
    }

    this.spinnerService.show();
    this.salesSizeData.CliCodes = this.tagsList.join(',');

    if (this.salesSizeId > 0) {
      this.salesSizeService.updateSalesSize(this.salesSizeData).subscribe(
        () => {
          this.alertService.renderSuccessMessage(Messages.SalesSizesSaveSuccess);
          this.onCancel(true);
        },
        this.alertService.showApiError,
        () => {
          this.spinnerService.hide();
        }
      );
    } else {
      this.salesSizeService.addSalesSize(this.salesSizeData).subscribe(
        () => {
          this.alertService.renderSuccessMessage(Messages.SalesSizesSaveSuccess);
          this.onCancel(true);
        },
        this.alertService.showApiError,
        () => {
          this.spinnerService.hide();
        }
      );
    }
  }

  onCancel(reload: boolean) {
    this.hide({ shouldReload: reload });
  }
}
