import {
  onlineOrderingCommentAlt, onlineOrderingImages, twitterSquare, onlineOrderingSchedule,
  dicountMoneyBill, times, onlineOrderingColor, undo, exclamationCircle, calenderEdit, infoManageCogs, megaphone
} from './../../../../shared/components/icon/icons';
import { AlertsService, ApplicationStateService, FormUtilityService } from 'src/app/shared/services';
import { OnlineOrderingSettingsService } from './../../service/online-ordering-settings.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SpinnerService, Messages, Permissions, Levels, AuthenticationService, DomainConstants, FieldInfoMessages, BaseFormComponent } from 'src/app/shared';
import { OnlineOrderingSettings, OnlineOrderingDiscountSetting } from '../../interface/online-ordering';
import * as _ from 'lodash';
import { TemplateColumn, GridColumn } from '@tarktech/tark-ng-utils';
import { NgForm } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Dayparts } from 'src/app/configurator/dayparts/interface/daypart';
import { DaypartsService } from 'src/app/configurator/dayparts/services/dayparts.service';
import { find, orderBy, remove } from 'lodash';
import { forkJoin } from 'rxjs';
import { formatDate } from '@angular/common';
import { ExtensionValidationService } from 'src/app/shared/services/extension-validation.service';
import { LocationAddress } from '../../interface/location-address';

declare let $: any;
@Component({
  selector: 'pos-online-ordering-settings',
  templateUrl: './online-ordering-settings.component.html',
  styleUrls: ['./online-ordering-settings.component.scss']
})
export class OnlineOrderingSettingsComponent extends BaseFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.onlineOrderingSettingForm;
  }

  public icons = {
    onlineOrderingCommentAlt,
    onlineOrderingImages,
    twitterSquare,
    onlineOrderingSchedule,
    dicountMoneyBill,
    times,
    onlineOrderingColor, undo,
    exclamationCircle,
    calenderEdit,
    infoManageCogs,
    megaphone,
  };
  socialMediaClass = '';
  image: File;
  permissionDenied = false;
  uploadedImages: any = [];
  permission = {
    name: Permissions.OnlineOrderingSettings,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };
  isInvalid = true;
  onlineOrderingSettings: Array<OnlineOrderingSettings> = [];
  textSettings: Array<OnlineOrderingSettings> = [];
  imageSettings: Array<OnlineOrderingSettings> = [];
  socialMediaSettings: Array<OnlineOrderingSettings> = [];
  scheduleSettings: Array<OnlineOrderingSettings> = [];
  discountSettings: Array<OnlineOrderingSettings> = [];
  announcementSettings: Array<OnlineOrderingSettings> = [];
  discountPercentSettings: Array<OnlineOrderingDiscountSetting> = [];
  discountManualSettings: Array<OnlineOrderingDiscountSetting> = [];
  colorSettings: Array<OnlineOrderingSettings> = [];
  manualDiscount = 'discountCodeManual';
  percentDiscount = 'discountCodePercent';
  isAllowAdvanceOrder = false;
  timezoneList = [];
  @ViewChild('orderingSetting') orderingSetting: NgForm;
  percentDiscountColumns: Array<GridColumn> = [];
  manualDiscountColumns: Array<GridColumn> = [];
  isShowAcknowledgmentPageParam = false;
  tabList = {
    TextSetting: 'TextSetting',
    ImageSetting: 'ImageSetting',
    SocialMediaSetting: 'SocialMediaSetting',
    ScheduleSetting: 'ScheduleSetting',
    DiscountsSetting: 'DiscountsSetting',
    ColorSetting: 'ColorSetting',
    AnnouncementSetting: 'AnnouncementSetting',
  };
  selectedTab = this.tabList.TextSetting;
  @ViewChild('percentNameTemplate') private percentNameTemplate: any;
  @ViewChild('percentValueTemplate') private percentValueTemplate: any;
  @ViewChild('percentDescriptionTemplate') private percentDescriptionTemplate: any;
  @ViewChild('manualNameTemplate') private manualNameTemplate: any;
  @ViewChild('manualValueTemplate') private manualValueTemplate: any;
  @ViewChild('manualDescriptionTemplate') private manualDescriptionTemplate: any;
  @ViewChild('onlineOrderingSettingForm') onlineOrderingSettingForm: NgForm;
  scheduleDaysSettings: Array<any> = [];
  pickupTimeSettings: Array<any> = [];
  screenWidth = $(window).width();
  dayparts: Array<Dayparts> = [];
  daypartsConfig = [];
  syncOrderOn = DomainConstants.SyncOrderOn;
  activateFutureOrders: { day: null, withinHours: 0 };
  radioWithinHours = false;
  isValidHours = true;
  fieldInfoMessages = FieldInfoMessages;
  currencySymbol = null;
  locationAddresses: LocationAddress[] = [];

  constructor(private alertService: AlertsService,
    private spinnerService: SpinnerService,
    protected applicationStateService: ApplicationStateService,
    protected route: ActivatedRoute,
    private router: Router,
    private onlineOrderingSettingsService: OnlineOrderingSettingsService,
    private authenticationService: AuthenticationService,
    private daypartService: DaypartsService,
    private extensionValidation: ExtensionValidationService,
    formUtilityService: FormUtilityService) {
    super(formUtilityService);
    this.currencySymbol = applicationStateService.settingParam.CurrencySymbol;
  }

  ngOnInit() {
    this.socialMediaClass = twitterSquare.iconClasses + '';
    this.uploadedImages = [];
    this.getOnlineOrderingSettings();
    if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      this.permissionDenied = true;
    }
    this.getTimeZoneList();
    this.columnConfigurationForPercentDiscount();
    this.columnConfigurationForManualDiscount();
  }

  getTimeZoneList() {
    this.spinnerService.show();
    this.onlineOrderingSettingsService.getTimeZones()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.timezoneList = res;
        }
      });
  }

  getOnlineOrderingSettings() {
    this.uploadedImages = [];
    const onlineOrderSettingObservable = [];
    this.spinnerService.show();
    onlineOrderSettingObservable.push(this.onlineOrderingSettingsService.getOnlineOrderingSettings())
    onlineOrderSettingObservable.push(this.daypartService.getAll())
    forkJoin(onlineOrderSettingObservable)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (responses: any) => {
          if (responses) {
            this.onlineOrderingSettings = responses[0];
            this.textSettings = _.filter(this.onlineOrderingSettings, (textSetting) => {
              return textSetting.SettingType == 'Text';
            });
            let isShowAcknowledgmentPageParam = _.find(this.textSettings, x => x.KeyName == 'isShowAcknowledgmentPage');
            this.isShowAcknowledgmentPageParam = isShowAcknowledgmentPageParam?.ParamValue === 'true';
            this.imageSettings = _.filter(this.onlineOrderingSettings, (imageSetting) => {
              return imageSetting.SettingType == 'Images';
            });
            this.imageSettings = _.orderBy(this.imageSettings, 'Id');
            this.socialMediaSettings = _.filter(this.onlineOrderingSettings, (socialSetting) => {
              return socialSetting.SettingType == 'Social';
            });
            this.scheduleSettings = _.filter(this.onlineOrderingSettings, (scheduleSetting) => {
              return scheduleSetting.SettingType == 'Schedule';
            });
            this.announcementSettings = _.filter(this.onlineOrderingSettings, (announcementSetting) => {
              return announcementSetting.SettingType == 'Announcement';
            });
            this.discountSettings = _.filter(this.onlineOrderingSettings, (scheduleSetting) => {
              return scheduleSetting.SettingType == 'Discount';
            });
            this.colorSettings = _.filter(this.onlineOrderingSettings, (scheduleSetting) => {
              return scheduleSetting.SettingType === 'Theme';
            });
            const scheduleByDay = _.find(this.scheduleSettings, (scheduleSetting) => {
              return scheduleSetting.KeyName === 'schedules';
            });
            this.scheduleDaysSettings = JSON.parse(scheduleByDay.ParamValue);
            const pickupTimeSchedule = _.find(this.scheduleSettings, (scheduleSetting) => {
              return scheduleSetting.KeyName === 'pickupTimeSchedule';
            });
            this.pickupTimeSettings = JSON.parse(pickupTimeSchedule.ParamValue);
            const advanceOrderMaxDays = _.find(this.scheduleSettings, (scheduleSetting) => {
              return scheduleSetting.KeyName === 'advanceOrderMaxDays';
            });
            const daypartsConfiguration = _.find(this.scheduleSettings, (scheduleSetting) => {
              return scheduleSetting.KeyName === 'dayparts';
            });
            const futureOrders = _.find(this.scheduleSettings, (scheduleSetting) => {
              return scheduleSetting.KeyName === 'syncOrderOn';
            });
            const addresses = _.find(this.onlineOrderingSettings, (x) => x.KeyName === 'locationAddress');
            this.locationAddresses = JSON.parse(addresses?.ParamValue ?? '[]')
            this.activateFutureOrders = JSON.parse(futureOrders.ParamValue);
            this.radioWithinHours = this.activateFutureOrders.withinHours > 0 ? true : false;
            this.isAllowAdvanceOrder = advanceOrderMaxDays.ParamValue !== '0';
            this.discountPercentSettings = [];
            this.discountManualSettings = [];
            this.setDiscountByTypes();
            this.removeTimeStampFromSettingIcon();
            this.dayparts = responses[1] ? responses[1] : [];
            this.prepareDayParts(JSON.parse(daypartsConfiguration && daypartsConfiguration.ParamValue ? daypartsConfiguration.ParamValue : null));
          }
        }, error: this.alertService.showApiError
      });
  }

  removeDaypartOverrides = (partConfig) => {
    partConfig.MaxOrder = null;
    partConfig.MinWaitTime = null;
  }

  prepareDayParts = (res) => {
    this.daypartsConfig = [];
    this.dayparts = orderBy(this.dayparts, 'StartTime');
    _.forEach(this.dayparts, (part) => {
      if (part) {
        const config = find(res, (item) => {
          return item.Id == part.Id;
        });
        const configuration = {
          Id: part.Id,
          Name: part.Name,
          StartTime: this.parseTime(part.StartTime),
          EndTime: this.parseTime(part.EndTime),
          MaxOrder: config ? config.MaxOrder : null,
          MinWaitTime: config ? config.MinWaitTime : null
        };
        this.daypartsConfig.push(configuration);
      }
    });
  }

  parseTime(timeString) {
    const time = timeString.split(':');
    const date = new Date();
    date.setHours(time[0]);
    date.setMinutes(parseInt(time[1], 10));
    return formatDate(date, 'hh:mm a', 'en');
  }

  changeAllowAdvanceOrder(scheduleSetting: OnlineOrderingSettings) {
    if (!this.isAllowAdvanceOrder) {
      $('#advanceOrderMaxDays').hide();
    } else {
      $('#advanceOrderMaxDays').show();
    }
    scheduleSetting.IsChanged = true;
    scheduleSetting.ParamValue = '0';
  }

  private columnConfigurationForPercentDiscount() {

    const percentNameColumn = new TemplateColumn({
      HeaderText: 'Name',
      itemTemplate: this.percentNameTemplate,
      Width: '30%'
    });

    const percentValueColumn = new TemplateColumn({
      HeaderText: 'Value',
      itemTemplate: this.percentValueTemplate,
      Width: '150px'
    });

    const percentDescriptionColumn = new TemplateColumn({
      HeaderText: 'Description',
      itemTemplate: this.percentDescriptionTemplate,
      Width: '50%'
    });

    this.percentDiscountColumns = [
      percentNameColumn,
      percentValueColumn,
      percentDescriptionColumn
    ] as Array<GridColumn>;

  }

  private columnConfigurationForManualDiscount() {

    const manualNameColumn = new TemplateColumn({
      HeaderText: 'Name',
      itemTemplate: this.manualNameTemplate,
      Width: '30%'
    });

    const manualValueColumn = new TemplateColumn({
      HeaderText: 'Value',
      itemTemplate: this.manualValueTemplate,
      Width: '150px'
    });

    const manualDescriptionColumn = new TemplateColumn({
      HeaderText: 'Description',
      itemTemplate: this.manualDescriptionTemplate,
      Width: '50%'
    });

    this.manualDiscountColumns = [
      manualNameColumn,
      manualValueColumn,
      manualDescriptionColumn
    ] as Array<GridColumn>;

  }

  setDiscountByTypes() {
    const percentDiscount = [];
    const manualDiscount = [];
    this.discountSettings.forEach(discount => {
      if (discount.KeyName.indexOf(this.percentDiscount) != -1) {
        const discountObj = this.prepareDiscountObj(discount);
        if (discountObj) {
          percentDiscount.push(discountObj);
        }
      } else if (discount.KeyName.indexOf(this.manualDiscount) != -1) {
        if (this.discountManualSettings.length < 3) {
          const discountObj = this.prepareDiscountObj(discount);
          if (discountObj) {
            manualDiscount.push(discountObj);
          }
        }
      }
    });
    this.addDefaultDiscountRows(percentDiscount, this.percentDiscount, this.discountPercentSettings);
    this.addDefaultDiscountRows(manualDiscount, this.manualDiscount, this.discountManualSettings);
  }

  addDefaultDiscountRows(percentDiscount, keyNamePrefix, discountArray) {
    for (let i = 1; i <= 3; i++) {
      const indexOfAvailableDiscount = _.findIndex(percentDiscount, (discount: any) => {
        return discount.KeyName == keyNamePrefix + (i);
      });
      if (indexOfAvailableDiscount >= 0) {
        discountArray.push(percentDiscount[indexOfAvailableDiscount]);
      } else {
        const newDiscount = this.onlineOrderingSettingsService.newOnlineOrderingDiscount();
        newDiscount.Type = keyNamePrefix;
        newDiscount.KeyName = keyNamePrefix + (i);
        discountArray.push(newDiscount);
      }
    }
  }

  prepareDiscountObj(discount): OnlineOrderingDiscountSetting {
    const discountPercentObj = this.onlineOrderingSettingsService.newOnlineOrderingDiscount();
    const paramValue = discount.ParamValue.split('|');
    if (paramValue) {
      discountPercentObj.Code = paramValue[0];
      discountPercentObj.Value = paramValue[1];
      discountPercentObj.Description = discount.Description;
      discountPercentObj.Id = discount.Id;
      discountPercentObj.KeyName = discount.KeyName;
    }
    return discountPercentObj;
  }

  removeTimeStamp(icon: string): string {
    if (icon) {
      const index = icon.lastIndexOf('.');
      const suffix = icon.substr(index);
      const timeIndex = icon.lastIndexOf('-');
      let iconName = icon.substr(0, index);
      if (icon.substr(timeIndex).length >= (suffix.length + 19)) {
        iconName = icon.substr(0, timeIndex);
      }
      return iconName + suffix;
    }
    return icon;
  }

  handleDiscount(settings): any {
    let discountSetting = [];
    discountSetting = _.filter(this.discountManualSettings, (discount) => {
      return discount.IsChanged == true;
    });
    discountSetting = discountSetting.concat(_.filter(this.discountPercentSettings, (discount) => {
      return discount.IsChanged == true;
    }));
    discountSetting.forEach((discount, index) => {
      if (discount.Id != 0) {
        const disc = _.filter(this.onlineOrderingSettings, (settings) => {
          return discount.Id == settings.Id;
        });
        if (disc) {
          if (discount.Code && discount.Value) {
            disc[0].Description = discount.Description;
            disc[0].ParamValue = discount.Code + '|' + discount.Value;
          } else {
            disc[0].ParamValue = '';
          }
          settings.push(disc[0]);
        }
      } else {
        const setting = this.onlineOrderingSettingsService.newOnlineOrderingSetting();
        if (discount.Code && discount.Value) {
          setting.KeyName = discount.KeyName;
          setting.ParamValue = discount.Code + '|' + discount.Value;
          setting.Description = discount.Description;
          setting.SettingType = 'Discount';
          setting.DateAdded = new Date();
          settings.push(setting);
        }
      }

    });
    return settings;
  }

  removeTimeStampFromSettingIcon() {
    this.imageSettings.forEach(setting => {
      setting.iconName = this.removeTimeStamp(setting.ParamValue);
    });
  }

  handleFileInput(files: FileList, index: string, imageSetting) {
    this.image = files.item(0);
    const fileToUpload = this.image ? this.image.name : '';
    if (this.extensionValidation.isInvalidExtension(fileToUpload, ['.jpg', '.bmp', '.png', '.ico'])
      || this.isInValidFavicon(fileToUpload, imageSetting)) {
      if (fileToUpload) {
        imageSetting.SettingName !== 'Favicon' ? this.alertService.renderErrorMessage(Messages.InvalidReceiptLogoFileType) :
          this.alertService.renderErrorMessage(Messages.InvalidOnlineOrderFaviconFileType);
      }
      this.removeIcon(index, imageSetting);
      return;
    }
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = function (e) {
        $('#image' + index.toString()).attr('src', reader.result);
      };
      reader.readAsDataURL(files[0]);
      imageSetting.ParamValue = this.image ? this.image.name : '';
    }
    imageSetting.IsChanged = true;
    const imageIndex = _.findIndex(this.uploadedImages, (image: any) => {
      return image.Id == imageSetting.Id;
    });

    if (imageIndex == -1) {
      this.uploadedImages.push(this.image);
      this.uploadedImages[this.uploadedImages.length - 1].Id = imageSetting.Id;
    } else {
      this.uploadedImages[imageIndex] = this.image;
      this.uploadedImages[imageIndex].Id = imageSetting.Id;
    }

  }

  isInValidFavicon(fileToUpload, imageSetting) {
    return (
      imageSetting &&
      imageSetting.SettingName === 'Favicon' &&
      this.extensionValidation.isInvalidExtension(fileToUpload, ['.ico'])
    );
  }

  getHeight = () => {
    this.screenWidth = $(window).width();
    return $(window).height() - 310 + 'px';
  }

  removeIcon(index, imageSetting) {
    imageSetting.ParamValue = '';
    $('#imageUpload' + index).val('');
    imageSetting.IsChanged = true;
    imageSetting.iconName = '';
  }

  removeTime(startTime: boolean, index: number, isDaySchedule: boolean) {
    const settings = isDaySchedule ? this.scheduleDaysSettings : this.pickupTimeSettings;
    if (startTime) {
      settings[index].startTime = '';
    } else {
      settings[index].endTime = '';
    }
  }

  timeToDateTime(timeString) {
    const timeTokens = timeString.split(':');
    if (timeTokens && timeTokens.length > 0) {
      return new Date(1970, 0, 1, timeTokens[0], timeTokens[1]).getTime();
    }
  }

  saveOnlineOrderingSettings(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.isInvalid = true;
    let isValidSchedules = true;
    this.isValidHours = false;

    this.activateFutureOrders.withinHours = this.activateFutureOrders.day === this.syncOrderOn.OnPickupDate.Value && this.radioWithinHours
      ? this.activateFutureOrders.withinHours : 0;
    if (this.activateFutureOrders.withinHours < 0 || this.activateFutureOrders.withinHours > 24) {
      return;
    } else {
      this.isValidHours = true;
    }
    _.forEach(this.scheduleDaysSettings, (setting) => {
      if (setting.startTime && setting.endTime && setting.startTime > setting.endTime) {
        isValidSchedules = false;
      }
    });
    if (!isValidSchedules) {
      this.alertService.renderErrorMessage(Messages.ErrorWhileStartTimeIsGreaterEndTime);
      return;
    }

    if (!this.isInvalid) {
      return;
    }
    isValidSchedules = this.validateSchedules(isValidSchedules);
    if (!isValidSchedules) {
      this.alertService.renderErrorMessage(Messages.ErrorWhilePickupTimeNotBetweenDailyScheduleTime);
      return;
    }
    remove(this.daypartsConfig, (config) => {
      return !config.MaxOrder && !config.MinWaitTime;
    });
    this.setJsonParamValues();
    this.spinnerService.show();
    let settings = [];
    settings = _.filter(this.onlineOrderingSettings, (imageSetting) => {
      return imageSetting.IsChanged === true;
    });
    settings = this.handleDiscount(settings);
    this.uploadedImages = _.orderBy(this.uploadedImages, 'Id');
    this.onlineOrderingSettingsService.saveOnlineOrderingSettings(settings, this.uploadedImages)
      .subscribe({
        next: () => {
          this.onCancel();
          this.alertService.renderSuccessMessage(Messages.OnlineOrderingSettingsSaveSuccess);
        }, error: this.alertService.showApiError,
        complete: () => {
          this.spinnerService.hide();
        }
      });
  }

  private setJsonParamValues() {
    _.forEach(this.onlineOrderingSettings, (setting) => {
      if (setting.KeyName === 'schedules') {
        setting.ParamValue = JSON.stringify(this.scheduleDaysSettings);
        setting.IsChanged = true;
      } else if (setting.KeyName === 'pickupTimeSchedule') {
        setting.ParamValue = JSON.stringify(this.pickupTimeSettings);
        setting.IsChanged = true;
      } else if (setting.KeyName === 'dayparts') {
        setting.ParamValue = JSON.stringify(this.daypartsConfig);
        setting.IsChanged = true;
      } else if (setting.KeyName === 'syncOrderOn') {
        setting.ParamValue = JSON.stringify(this.activateFutureOrders);
        setting.IsChanged = true;
      } else if (setting.KeyName === 'locationAddress') {
        setting.ParamValue = JSON.stringify(this.locationAddresses);
        setting.IsChanged = true;
      }
    });
  }

  private validateSchedules(isValidSchedules: boolean) {
    _.forEach(this.scheduleDaysSettings, (scheduleDay) => {
      const pickupSetting = this.pickupTimeSettings.find(x => x.day === scheduleDay.day);

      if (!scheduleDay.startTime) { scheduleDay.startTime = '00:00'; }
      if (!scheduleDay.endTime) { scheduleDay.endTime = '23:59'; }
      if (!pickupSetting.startTime) { pickupSetting.startTime = scheduleDay.startTime; }
      if (!pickupSetting.endTime) { pickupSetting.endTime = scheduleDay.endTime; }

      if (this.timeToDateTime(scheduleDay.startTime) > this.timeToDateTime(pickupSetting.startTime)
        || this.timeToDateTime(scheduleDay.startTime) > this.timeToDateTime(pickupSetting.endTime)
        || this.timeToDateTime(pickupSetting.startTime) > this.timeToDateTime(scheduleDay.endTime)
        || this.timeToDateTime(pickupSetting.endTime) > this.timeToDateTime(scheduleDay.endTime)) {
        isValidSchedules = false;
      }
    });
    return isValidSchedules;
  }

  onCancel() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
