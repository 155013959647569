<pos-edit-wrapper heading="Preview" (close)="onClose()">
    <div class="modal-body pos-modal-body pt-0">
        <div>
            <div class="col-4 col-170 p-0"><label class="edit-form__section__label">Select terminal to
                Preview</label>
            </div>
            <div class="col-xl-4 col-sm-6 col-8 pe-0">
                <pos-form-field inputStyle="col-12" [appendPaddingTop]="false">
                    <ng-select posInput [items]="terminals" bindLabel="TerminalName"
                        bindValue="TerminalId" (change)="setResolutionByTerminal(terminalId)"
                        placeholder="Select terminal" appendTo="body" [(ngModel)]="terminalId"
                        name="buttonType" id="buttonType" #buttonType="ngModel" [required]="true">
                    </ng-select>
                </pos-form-field>
            </div>
        </div>
        
        <div class="kiosk-screen-preview pt-3">
            <div class="kiosk-screen" *ngIf="kioskScreen">
                <pos-kiosk-screen-template *ngIf="kioskScreen.Id" [screenId]="kioskScreen.Id"
                [templateType]="kioskScreen.TemplateType"
                [screenConfigs]="kioskScreen.KioskScreenConfigs"
                [screenButtons]="kioskScreen.KioskScreenChoices"></pos-kiosk-screen-template>
            </div>
        </div>
    </div>
    <div class="col-12 action-button-container popup-footer">
        <button class="btn btn-primary btn-lg btn-cancel-modal pos-button white" (click)="onClose()"
            type="button">Ok</button>
    </div>
</pos-edit-wrapper>
