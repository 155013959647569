import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalComponent } from 'src/app/shared/components/modal/modal-component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TrainingAreaService } from 'src/app/shared/services/training-area.service';
import { AlertsService, SpinnerService, AuthenticationService, ConfirmDeleteComponent, Messages } from 'src/app/shared';
import * as _ from 'lodash';
import { TrainingArea } from 'src/app/information-management/training-areas';
import { NgForm } from '@angular/forms';
import { TrainingTopic } from 'src/app/information-management/training-topics';
import { zip } from 'rxjs';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { TrainingTopicLevelService } from 'src/app/shared/services/training-topic-level.service';
import { TrainingTopicService } from 'src/app/shared/services/training-topic.service';

@Component({
  selector: 'pos-training-level-move',
  templateUrl: './training-level-move.component.html',
  styleUrls: ['./training-level-move.component.scss']
})
export class TrainingLevelMoveComponent extends ModalComponent implements OnInit {

  trainingAreas: Array<TrainingArea> = [];
  trainingTopics: Array<TrainingTopic> = [];
  trainingTopicId: number;
  trainingTopicName: string;
  trainingAreaId: number;
  trainingAreaName: number;
  trainingLevelId: number;
  trainingLevelName: string;
  selectedArea: any;
  selectedTopic: any;

  @ViewChild('trainingLevelMoveForm') trainingLevelMoveForm: NgForm;
  @ViewChild('confirmMoveTrainingLevel') private confirmMoveTrainingLevel: ConfirmDeleteComponent;
  props = {
    labelClass: '',
    controlBoxClass: 'col-lg-9 col-xs-8'
  }
  constructor(private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private authenticationService: AuthenticationService,
    private trainingAreaService: TrainingAreaService,
    private trainingTopicService: TrainingTopicService,
    private trainingTopicLevelService: TrainingTopicLevelService,
    modalRef: BsModalRef) {
    super(modalRef);
  }

  ngOnInit() {
    this.getTrainingAreas();
  }

  getTrainingAreas() {
    this.spinnerService.show();
    this.trainingAreaService.getTrainingAreas().subscribe({
      next: (res) => {
        this.trainingAreas = res;
      }, error: this.alertService.showApiError,
      complete: () => {
        this.spinnerService.hide();
      }
    });
  }

  getTrainingTopics() {
    if (this.selectedArea) {
      this.spinnerService.show();
      this.trainingTopicService.getTrainingTopicsByAreaId(this.selectedArea.Id, 0).subscribe({
        next: (res) => {
          this.trainingTopics = _.filter(res, (topic) => {
            return topic.Id != this.trainingTopicId;
          });
        }, error: this.alertService.showApiError,
        complete: () => {
          this.spinnerService.hide();
        }
      });
    } else {
      this.trainingTopics = [];
      this.selectedTopic = null;
    }
  }

  onConfirmAcknowledge() {
    this.spinnerService.show();
    this.trainingTopicLevelService.moveTrainingLevel(this.selectedTopic.Id, this.trainingLevelId).subscribe({
      next: (res) => {
        this.alertService.renderSuccessMessage(StringUtils.format(Messages.TrainingLevelMoveSuccess,
          { 'trainingLevel': this.trainingLevelName }
        ));
        this.onCancel(true);
      }, error: this.alertService.showApiError,
      complete: () => {
        this.spinnerService.hide();
      }
    });
  }

  moveTrainingLevel(isValid) {
    if (!isValid || !this.selectedArea || !this.selectedTopic) {
      return;
    }
    this.confirmMoveTrainingLevel.Show(
      StringUtils.format(Messages.ConfirmMoveTrainingLevel,
        {
          'trainingLevel': this.trainingLevelName,
          'trainingSourceTopic': this.trainingTopicName,
          'trainingSourceArea': this.trainingAreaName,
          'trainingDestTopic': this.selectedTopic.Name,
          'trainingDestArea': this.selectedArea.Name,
        }
      ));
  }

  onCancel(reload: boolean) {
    this.hide({ shouldReload: reload });
  }

}
