import { Component, OnInit } from '@angular/core';
import {
  ModalComponent, SpinnerService, ApplicationStateService, AlertsService, RuntimeConstants, OrderService, ColorUtilityService,
  RabbitMQService,
  DomainConstants,
  Messages
} from 'src/app/shared';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SalesProductsService } from 'src/app/information-management/sales-products/services';
import { finalize } from 'rxjs/operators';
import { SalesSizeWithPrice } from 'src/app/information-management/sales-sizes/interface/sales-size-with-price';
import { infoCircle, exclamationTriangle, boxes, listAlt, orderInfoInventoryCheckDouble, nutritionPrint, tag } from 'src/app/shared/components/icon';
import { orderBy } from 'lodash';
import { Tag } from 'src/app/information-management/tags/interface/tags';
import { OrderNavigationButton } from 'src/app/orders/interface';
declare let $: any;
@Component({
  selector: 'pos-order-entry-info-mode',
  templateUrl: './order-entry-info-mode.component.html',
  styleUrls: ['./order-entry-info-mode.component.scss']
})
export class OrderEntryInfoModeComponent extends ModalComponent implements OnInit {

  productInfo: OrderNavigationButton;
  manualPriceAdjust: number;
  public imagePath = RuntimeConstants.IMAGE_BASE_PATH;
  pageHeight;
  public icons = {
    infoCircle,
    exclamationTriangle,
    boxes,
    listAlt,
    orderInfoInventoryCheckDouble,
    nutritionPrint,
    tag
  };
  tabList = {
    ProductInformation: 'ProductInformation',
    ProductRecipe: 'ProductRecipe',
    ProductIngredients: 'ProductIngredients',
    ProductDietaryWarnings: 'ProductDietaryWarnings',
    ProductInventory: 'ProductInventory',
    Tags: 'Tags',
  };
  tags: Array<Tag> = [];
  selectedTab = this.tabList.ProductInformation;
  menuInteractivityActionType = DomainConstants.MenuInteractivityActionType;
  salesSizesWithPrices: Array<SalesSizeWithPrice> = [];
  isFilterAppliedOnMenu = false;
  constructor(modalRef: BsModalRef,
    private orderService: OrderService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private applicationStateService: ApplicationStateService,
    private colorUtilityService: ColorUtilityService,
    private rabbitMQService: RabbitMQService,
    private salesProductService: SalesProductsService) {
    super(modalRef);
  }

  ngOnInit() {
    this.pageHeight = $(window).height() - 500 < 150 ? 150 : 340;
    this.getProductSizesWithPrice();
    this.resizeWindow();
    if (this.productInfo.SalesProductTags?.length) {
      this.tags = orderBy(this.productInfo.SalesProductTags.map(x => x.Tag), 'Name');
    }
  }

  getProductSizesWithPrice() {
    this.spinnerService.show();
    this.salesProductService.getProductSizesWithPrice(this.productInfo.SalesProductId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (sizes: Array<SalesSizeWithPrice>) => {
          this.salesSizesWithPrices = sizes;
        }, error: this.alertService.showApiError
      });
  }

  printNutritionFactInfo(sizeId) {
    if (this.applicationStateService.settingParam.ReceiptPrinter?.Id) {
    this.spinnerService.show();
    var printObj = {
      SalesProductId: this.productInfo.SalesProductId,
      SizeId: sizeId,
      TerminalId: this.applicationStateService.terminalId,
      TerminalName: this.applicationStateService.terminalName
    }
    this.orderService.printNutritionFacts(printObj)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {

        }, error: this.alertService.showApiError
      });
    } else {
      this.alertService.renderErrorMessage(Messages.ReceiptPrinterNotMapped);
    }
  }

  closeModal() {
    this.resetMenuDisplayInteractivity();
    this.hide({ shouldReload: false });
  }

  resizeWindow = () => {
    $(window).resize(() => {
      this.pageHeight = $(window).height() - 500 < 150 ? 150 : 340;
    });
  }

  getContrastColor(color: string) {
    return this.colorUtilityService.getContrastColor(color);
  }

  sendHighlightProductRequest(actionType: string) {
    this.isFilterAppliedOnMenu = true;
    this.rabbitMQService.sendHighlightMenuItemsMessage(this.applicationStateService.terminalId, this.productInfo.SalesProductId,
      actionType);
  }

  resetMenuDisplayInteractivity() {
    if (this.isFilterAppliedOnMenu && !this.applicationStateService.settingParam.ResetMenuDisplayFilterSeconds) {
      this.isFilterAppliedOnMenu = false;
      this.rabbitMQService.sendHighlightMenuItemsMessage(this.applicationStateService.terminalId, null);
    }
  }

}
