import { Component, OnInit } from '@angular/core';
import { DomainConstants } from 'src/app/shared/constants';
import { InventoryScannerBatchModel } from '../../interfaces/inventory-scanner-batch-model';
import { AlertsService, ApplicationStateService, InventoryLogService, SpinnerService, UserDetails } from 'src/app/shared';
import { barcode, barcodeScan, editWhite, deleteWhite } from 'src/app/shared/components/icon'
import { finalize } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'pos-inventory-barcode-batch-list',
  templateUrl: './inventory-barcode-batch-list.component.html',
  styleUrls: ['./inventory-barcode-batch-list.component.scss']
})
export class InventoryBarcodeBatchListComponent implements OnInit {

  sessionKey = DomainConstants.SessionStorageKeys.InventoryScannerBatch;
  userDetails: UserDetails;
  inventoryScannerBatch: InventoryScannerBatchModel[] = [];
  icons = {
    barcode,
    barcodeScan,
    editWhite,
    deleteWhite
  }

  constructor(public spinnerService: SpinnerService,
    public alertService: AlertsService,
    private inventoryLogService: InventoryLogService,
    private router: Router,
    private route: ActivatedRoute,
    public applicationStateService: ApplicationStateService) {
    const batchData = sessionStorage.getItem(this.sessionKey);
    if (batchData) {
      this.inventoryScannerBatch = JSON.parse(batchData) as Array<InventoryScannerBatchModel>
    }
  }

  ngOnInit(): void {
    this.userDetails = this.applicationStateService.userDetails;
  }

  deleteBatch(i: number) {
    this.inventoryScannerBatch.splice(i, 1);
    sessionStorage.setItem(this.sessionKey, JSON.stringify(this.inventoryScannerBatch));
  }

  deleteAllBatch() {
    this.inventoryScannerBatch = [];
    sessionStorage.removeItem(this.sessionKey);
    this.cancel();
  }

  editBatch(inventoryId: number) {
    const barcodeValue = String(inventoryId).padStart(5, "0") + "000" + "0000";
    this.router.navigate(['inventory-barcode', barcodeValue], { relativeTo: this.route.parent });
  }

  updateInventoryQty = () => {
    const inventoryLogModel = this.prepareInventoryLogModel();
    sessionStorage.removeItem(this.sessionKey);
    this.spinnerService.show();
    this.inventoryLogService.updateInventoryLog(inventoryLogModel)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe({
        next: (res) => {
          this.alertService.renderSuccessMessage("Inventory logged.");
          this.cancel();
        }, error: this.alertService.showApiError
      });
  }

  prepareInventoryLogModel() {
    return {
      InventoryList: this.inventoryScannerBatch.map(x => x.InventoryFinalLog),
      UserName: this.userDetails.username,
      UserId: this.userDetails.id,
      FilterName: "Scanned",
      FilterValue: "",
      VendorName: 'true'
    };
  }

  cancel() {
    this.router.navigate(['inventory-barcode'], { relativeTo: this.route.parent });
  }

}
