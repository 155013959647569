import { Route } from '@angular/router';
import { DomainConstants, Levels } from '../shared/constants';
import { BackOfficeComponent } from './back-office.component';

const BACK_OFFICE: Route = {
    path: 'console',
    component: BackOfficeComponent,
    data: {
        permissions: [{ 'Name': DomainConstants.TerminalTypes.BACK_OFFICE.Permission, 'Level': Levels.Access }]
    }
};
export const BACK_OFFICE_STATES = [BACK_OFFICE];
