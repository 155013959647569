<div>
  <div class="clearfix"></div>
  <div class="col-md-12 p-0">
    <div class="alert condensed inherit-position alert-warning mb-pix-10" [ngClass]="{'mt-pix-10': !salesCategoryId}"
      id="divSalesSizeMsg" [hidden]="salesProductPrices.length || salesCategoryId">{{salesSizeMappedError}}</div>
  </div>

  <div class="form-group pt-pix-7 col-lg-7 col-md-9 col-sm-12 col-xs-12">

    <div class="">
      <ng-table #gridComponent [sort]="false" [search]="false" [paginator]="false" [columns]="salesProductPricesColumns"
        [data]="salesProductPrices" [styleClass]="'no-border no-alternative-background no-header-border'">
      </ng-table>

      <ng-template let-data="rowData" #nameTemplate>
        <label class="label-section">{{data.Name}}
          <span style="vertical-align: text-bottom;">
            <span *ngIf="defaultPriceId == data.SizeId" class="label label-default font-12">Default</span>          
          </span>
        </label>
      </ng-template>

      <ng-template let-data="rowData" #priceTemplate>
        <input class="form-control col-120" type="number" posDecimal [isAllowNegative]="true" name="Name{{data.Id}}{{data.Name}}" #priceNameName="ngModel"
          (input)="onChangePrice(data)" [(ngModel)]="data.Price" />
      </ng-template>

      <ng-template let-data="rowData" #priceMarginTemplate>
        <label class="label-section">{{(data.Price - data.Cost)  | tark_currency}} {{' (' + (data.MarginPct ? (data.MarginPct).toFixed(2) : 0) + '%)'}}</label>
      </ng-template>

      <ng-template let-data="rowData" #deleteTemplate>
        <a class="pointer pe-pix-1-5" (click)="confirmDeletePrice(data)" title="Delete"
          [hidden]="!data.Price || defaultPriceId == data.SizeId">
          <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
        </a>
      </ng-template>
    </div>
  </div>
</div>
<pos-confirm-delete (onConfirmAcknowledge)="removeSalesPrice()" #confirmPriceDelete>
</pos-confirm-delete>
