import { DomainConstants } from "src/app/shared/constants";
import { IKioskNavigation } from "../interfaces/ikiosk-navigation";
import { KioskChoiceBehavior } from "../interfaces/kiosk-choice-behavior";
import { ChoiceBehavior } from "./choices-behavior";
import { DisplayMessageBehavior } from "./display-message-behavior";
import { Injectable, Injector } from "@angular/core";
import { InventoryDecrementBehavior } from "./inventory-decrement-behavior";
import { InventoryIncrementBehavior } from "./inventory-increment-behavior";
import { AddMainProductBehavior } from "./add-main-product-behavior";
import { SelectButtonsBehavior } from "./select-buttons-behavior";
import { UncheckAllButtonsBehavior } from "./uncheck-all-buttons-behavior";
import { UncheckButtonsBehavior } from "./uncheck-buttons-behavior";
import { SetSizeBehavior } from "./set-size-behavior";
import { AddCommentToOrderBehavior } from "./add-comment-to-order";
import { AddCommentToAllProductBehavior } from "./add-comment-to-all-product";
import { TemporaryNavigationBehavior } from "./temporary-navigation-behavior";
import { MultipleProductsBehavior } from "./multiple-products-behavior";
import { AddDetailEntryBehavior } from "./add-detail-entry";
import { AddAutoDiscountBehavior } from "./add-auto-discount";
import { AddAutoMarkupBehavior } from "./add-auto-markup-behavior";
import { SingularFeeBehavior } from "./singular-fee-behavior";
import { ApplicationStateService } from "src/app/shared";
import { FilterProductsBehavior } from "./filter-products-behavior";
import { CallShakeGenerateAPIBehavior } from "./call-shake-generate-api";

@Injectable({
    providedIn: 'root'
})
export class ButtonBehaviorExecutor {
    behaviorMap: Record<string, new (...args: any[]) => ChoiceBehavior>;
    constructor(private injector: Injector,
        private appStateService: ApplicationStateService
    ) {
        this.behaviorMap = {
            [DomainConstants.KioskButtonBehaviors.DisplayMessage.Value]: DisplayMessageBehavior,
            [DomainConstants.KioskButtonBehaviors.DecrementInventory.Value]: InventoryDecrementBehavior,
            [DomainConstants.KioskButtonBehaviors.IncrementInventory.Value]: InventoryIncrementBehavior,
            [DomainConstants.KioskButtonBehaviors.AddMainProduct.Value]: AddMainProductBehavior,
            [DomainConstants.KioskButtonBehaviors.AddDetailEntry.Value]: AddDetailEntryBehavior,
            [DomainConstants.KioskButtonBehaviors.SelectButtons.Value]: SelectButtonsBehavior,
            [DomainConstants.KioskButtonBehaviors.UncheckAllButtons.Value]: UncheckAllButtonsBehavior,
            [DomainConstants.KioskButtonBehaviors.UncheckButtons.Value]: UncheckButtonsBehavior,
            [DomainConstants.KioskButtonBehaviors.SetSize.Value]: SetSizeBehavior,
            [DomainConstants.KioskButtonBehaviors.AddComment.Value]: AddCommentToOrderBehavior,
            [DomainConstants.KioskButtonBehaviors.AddCommentToAllProducts.Value]: AddCommentToAllProductBehavior,
            [DomainConstants.KioskButtonBehaviors.TemporaryNav.Value]: TemporaryNavigationBehavior,
            [DomainConstants.KioskButtonBehaviors.MultipleProduct.Value]: MultipleProductsBehavior,
            [DomainConstants.KioskButtonBehaviors.AutoDiscount.Value]: AddAutoDiscountBehavior,
            [DomainConstants.KioskButtonBehaviors.AutoMarkup.Value]: AddAutoMarkupBehavior,
            [DomainConstants.KioskButtonBehaviors.SingularFee.Value]: SingularFeeBehavior,
            [DomainConstants.KioskButtonBehaviors.FilterProducts.Value]: FilterProductsBehavior,
            [DomainConstants.KioskButtonBehaviors.CallShakeGenerateAPI.Value]: CallShakeGenerateAPIBehavior,
        }
    }

    executeButtonBehavior = (
        behavior: KioskChoiceBehavior,
        navObj: IKioskNavigation,
        orderNavigation
    ) => {
        try {
            const choiceBehavior = this.behaviorMap[behavior.Name];
            if (choiceBehavior) {
                const behaviorInstance = this.injector.get(choiceBehavior);
                behaviorInstance.behaviorValues = behavior.Value ? JSON.parse(behavior.Value) : null;
                behaviorInstance.behaviorId = behavior.Id;
                behaviorInstance.terminalType = this.appStateService.terminalType;
                return behaviorInstance.execute(navObj, orderNavigation);
            } else {
                console.error('Behavior not found:', behavior.Name);
            }
        } catch (ex) {
            console.error('Invalid ' + behavior.Name + ' behavior value: ' + behavior.Value);
        }
    }

}
