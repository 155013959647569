<pos-edit-wrapper heading="Combo Products" (close)="onCancel()">
    <form #comboProductConfigForm="ngForm" class="row mx-0" (ngSubmit)="saveWidget(comboProductConfigForm.valid)"
        autocomplete="off">
        <div class="col-12 pt-pix-5" id="ComboProductConfig">
            <div class="float-end mb-pix-10">
                <button type="button" class="btn btn-secondary normal pos-button" (click)="addProduct()">
                    <pos-icon [icon]="icons.plus" class="pe-pix-5 font-12"></pos-icon> Item
                </button>
            </div>
        </div>
        <div class="col-12 overflow-auto-tabs" [ngStyle]="{'height': containerHeight}" cdkDropList
            (cdkDropListDropped)="drop($event)">
            <div class="col-12 item-box" cdkDrag
                *ngFor="let product of comboSalesProducts; let i = index; trackBy:trackByIdx">
                <pos-row colXs="12" class="mx-0 p-0">
                    <pos-column>
                        <div class="row mx-0">
                            <pos-form-field class="col-6 p-0" label="DisplayText" inputStyle="col-12">
                                <input posInput type="text" class="form-control" name="DisplayText_{{i}}"
                                    [(ngModel)]="product.DisplayText" />
                            </pos-form-field>
                            <div class="col-6 float-end">
                                <div class="col-1 p-0 col-35 float-end pt-pix-10">
                                    <button cdkDragHandle type="button"
                                        class="btn btn-secondary normal widget-combo-move">
                                        <pos-action-icon [icon]="icons.arrowsAlt"
                                            [additionalClasses]="['font-16', 'white']"
                                            [backgroundClasses]="['green']"></pos-action-icon>
                                    </button>
                                </div>
                                <div class="col-1 p-0 col-35 float-end pt-pix-10 me-pix-10">
                                    <button (click)="onDeleteItem(i)" type="button"
                                        class="btn btn-secondary normal widget-combo-delete">
                                        <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </pos-column>

                    <pos-column>
                        <pos-form-field label="Background Color">
                            <pos-color-picker posInput [(ngModel)]="product.BackgroundColor"
                                name="BackgroundColor_{{i}}"></pos-color-picker>
                        </pos-form-field>
                    </pos-column>

                    <pos-column>
                        <pos-form-field label="Color">
                            <pos-color-picker posInput [(ngModel)]="product.FontColor"
                                name="FontColor_{{i}}"></pos-color-picker>
                        </pos-form-field>
                    </pos-column>

                    <pos-column>
                        <div>
                            <h3 class="setting-title">Item Border</h3>
                        </div>
                        <pos-border-style [(borderStyle)]="product.BorderStyleConfig"
                            prefix="combo-product_{{i}}"></pos-border-style>
                    </pos-column>
                </pos-row>
            </div>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" value="Save" class="btn btn-primary btn-lg white btn-save pos-button"
                scrollup>Save</button>
            <button type="button" class="btn btn-secondary btn-lg btn-cancel-modal" value="Cancel pos-button"
                (click)="onCancel()" scrollup>Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
