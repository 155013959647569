import { getCurrencySymbol } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { SpinnerService } from 'src/app/shared';
import { ApplicationStateService, ReloadSettingsService } from 'src/app/shared/services';

@Component({
  selector: 'pos-dashboard-configuration-wrapper',
  templateUrl: './dashboard-configuration-wrapper.component.html',
  styleUrls: ['./dashboard-configuration-wrapper.component.scss']
})
export class DashboardConfigurationWrapperComponent implements OnInit {

  terminalId = 0;
  transParam: Params;
  headerLinks: any;
  isEditMode = true;
  redirectRoute: string = null;
  homeState = 'order/order-entry';
  @ViewChild('dashboardConfiguration') dashboardConfiguration;
  constructor(private route: ActivatedRoute,
    private applicationStateService: ApplicationStateService,
    private reloadSettingsService: ReloadSettingsService,
    private spinnerService: SpinnerService,
    private router: Router) {
    this.transParam = route.snapshot.params;
    this.terminalId = route.snapshot.params.terminalId ? parseInt(route.snapshot.params.terminalId, 10) : 0;
    const navigation = router.getCurrentNavigation();
    this.redirectRoute = (navigation?.extras?.state && navigation?.extras?.state.redirectRoute) ?
      navigation?.extras?.state.redirectRoute : this.redirectRoute;
  }

  ngOnInit(): void {
    if (!this.transParam.dashboardId || this.transParam.mode === 'interactive') {
      this.headerLinks = {
        tasks: true,
        time: true,
        admin: true,
        activeOrders: true,
        warning: true,
        home: true,
        mySubscriptions: true,
        customLinks: true,
        reload: true,
        signIn: true,
      };
    }
    if (this.terminalId) {
      this.headerLinks = {
        home: false,
        admin: false,
        mySubscriptions: false,
        customLinks: false
      };
    }
    this.reloadSettings();
  }

  reloadSettings() {
    this.spinnerService.show();
    this.reloadSettingsService.reloadSettings(this.applicationStateService.terminalId ?? 0)
    .pipe(finalize(() => {
      this.spinnerService.hide();
    }))
    .subscribe(res => {
      this.applicationStateService.settingParam.CurrencySymbol = this.applicationStateService.settingParam.Currency ?
        getCurrencySymbol(this.applicationStateService.settingParam.Currency, 'narrow') : '$';
    });
  }
  toggleEditMode(isEditMode: boolean) {
    this.isEditMode = true;
  }

  saveDashboard() {
    this.dashboardConfiguration.posAdfDashboard.toggleEditMode();
  }

  closeDashboard() {
    if (this.redirectRoute) {
      this.router.navigate([this.redirectRoute]);
    } else {
      this.router.navigate(['manage/console']);
    }
  }

}
