import { Injectable } from '@angular/core';
import { RuntimeConstants, SettingParam } from 'src/app/shared';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {

  userSettingsApiBasePath = `${RuntimeConstants.API_PATH}usersettings`;

  constructor(private httpClient: HttpClient) { }

  getUserSettingsDetail(terminalId: number): Observable<SettingParam> {
    return this.httpClient.get<SettingParam>(`${this.userSettingsApiBasePath}/${terminalId}`);
  }

  saveUserSetting(params: SettingParam): Observable<SettingParam> {
    return this.httpClient.post<SettingParam>(`${this.userSettingsApiBasePath}`, params);
  }

  createManagerLogin() {
    return this.httpClient.post(`${this.userSettingsApiBasePath}/createManagerLogin`, null);
  }
}
