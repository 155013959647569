<pos-edit-wrapper heading="Please choose product" (close)="closeModal()">
    <div class="pt-pix-15 pos-modal-body">
        <ul>
            <li class="list-item pointer shadow display-flex" *ngFor="let product of barcodeProducts; let last = last;"
                (click)="selectProduct(product)" [ngClass]="{'mb-pix-10': !last}">
                <span>
                    <b> {{product.SalesProduct.Name}} </b><span class="badge" *ngIf="product.Qty"> {{ product.Qty }}
                    </span>
                    <p class="italic">{{ product.SalesProduct.SalesCategories.Name }}</p>
                </span>
                <span class="float-right badge bg-theme-separator"> {{product.SalesSize.Name}} </span>
            </li>
        </ul>
    </div>
    <div class="col-12 action-button-container popup-footer">
        <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="closeModal()"
            type="button">Close</button>
    </div>
    <div class="clearfix"></div>
</pos-edit-wrapper>
