import { Component, OnInit, ViewChild } from '@angular/core';
import { HangfireJobsService } from '../services/hangfire-jobs.service';
import { HangfireJobs } from '../interface/hangfire-jobs';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import { GridColumn, DateColumn, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { BaseListComponent } from 'src/app/shared/components/base-list.component';
import { environment } from 'src/environments/environment';
import { backgroundJobsSearch , backgroundJobs  } from 'src/app/shared/components/icon';
import { ApplicationStateService } from 'src/app/shared';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Format } from '@tarktech/tark-ng-utils/table/format-type';
@Component({
  selector: 'pos-hangfire-jobs',
  templateUrl: './hangfire-jobs.component.html',
  styleUrls: ['./hangfire-jobs.component.scss']
})
export class HangfireJobsComponent extends BaseListComponent<HangfireJobs> implements OnInit {

  hangfireJobs: HangfireJobs[] = [];
  hangfireJobsColumns: Array<GridColumn> = [];
  entityTypes = [];
  entityType: string = null;
  entityId = '';
  apiUrl: string;
  screenHeight = (window.innerHeight - 300) + 'px';
  @ViewChild('jobIdColumn', {static: true}) jobIdColumn: any;
  public icons = {
    backgroundJobsSearch,
    backgroundJobs
  };

  constructor(protected hangfireJobsService: HangfireJobsService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected applicationStateService: ApplicationStateService,
    protected route: ActivatedRoute,
    private router: Router,
    protected printService: PrintTableService) {
    super(hangfireJobsService, alertService, spinnerService, modalService, applicationStateService, printService, route);
    this.apiUrl = environment.api.basePath;
  }

  ngOnInit() {
    this.loadSubscriptions();
    this.hangfireJobsColumns = this.configureGridColumns();
  }

  loadSubscriptions() {
    this.spinnerService.show();
    this.hangfireJobsService.getEntityTypes()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      })).subscribe(res => {
        this.entityTypes = (res === null ? [] : res);
      });
  }

  getEntityJobDetails() {
    this.hangfireJobs = [];
    if (this.entityType || this.entityId !== '') {
      this.spinnerService.show();
      this.hangfireJobsService.getJobDetails(this.entityType, this.entityId)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        })).subscribe(res => {
          this.hangfireJobs = (res === null ? [] : res);
        });
    }
  }

  configureGridColumns() {
    const jobIdColumn = new TemplateColumn({
      HeaderText: 'Job',
      itemTemplate: this.jobIdColumn,
      Width: '11%',
      Field: 'JobId',
      IsSortable: true,
      TextAlign: TextAlign.Right
    });

    return [
      jobIdColumn,
      new GridColumn({ HeaderText: 'Operation', Field: 'Operation', IsSortable: true, Width: '14%' }),
      new DateColumn({
        HeaderText: 'Created On', FormatString: 'MM-dd-yyyy hh:mm:ss.SSS a', Field: 'CreatedAt',
        IsSortable: true, Width: '20%', Format: Format.Custom
      }),
      new DateColumn({
        HeaderText: 'Executed On', FormatString: 'MM-dd-yyyy hh:mm:ss.SSS a', Field: 'ExecutedOn',
        IsSortable: true, Width: '20%', Format: Format.Custom
      }),
      new GridColumn({ HeaderText: 'Execution Time (ms)', Field: 'ExecutionTime',
        IsSortable: true, Width: '15%', TextAlign: TextAlign.Right }),
      new GridColumn({ HeaderText: 'Retry Count', Field: 'RetryCount', IsSortable: true, Width: '10%', TextAlign: TextAlign.Right })
    ] as Array<GridColumn>;
  }

  getGridColumns(): GridColumn[] {
    return [];
  }
  editItem(id: number, data?: HangfireJobs): void {

  }
  getConfirmDeleteMessage(data: HangfireJobs): string {
    throw new Error('Method not implemented.');
  }
  close() {
    this.router.navigate(['manage/console']);
  }
}
