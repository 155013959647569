import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { newProduct, deleteWhite, minus, trashAltWhite } from 'src/app/shared/components/icon';
import { cloneDeep } from 'lodash';
import { NgForm } from '@angular/forms';
import { PromotionConfig, PromotionRule } from 'src/app/shared/interface';

@Component({
  selector: 'pos-criteria',
  templateUrl: './criteria.component.html',
  styleUrls: ['./criteria.component.scss'],
})
export class CriteriaComponent implements OnInit {
  @Input() level: number = 1;
  @Input() operators: Array<PromotionConfig> = [];
  @Input() criteries: Array<PromotionConfig> = [];
  @Input() ruleIndex = 0;
  @Input() styleClass = "";
  @Input() newRuleModel: PromotionRule = null;
  @Input() criteriaCaption = 'Criteria'
  @Output() delete: EventEmitter<number> = new EventEmitter<number>();
  @Output() newRuleModelChange: EventEmitter<any> = new EventEmitter<any>();

  public icons = {
    newProduct,
    deleteWhite,
    minus,
    trashAltWhite
  };
  constructor(protected form: NgForm) {
  }
  
  ngOnInit(): void {
    if (!this.newRuleModel?.Rules?.length) {
      this.createNewRule();
    }
  }

  createNewRule() {
    this.newRuleModel = { Name: null, Condition: 'And', Rules: [], Config: null, Description: null };
    this.addNewRule(null, false);
  }

  addNewRule(rule: PromotionRule = null, emitChanges = true) {
    if (rule) {
      rule.Rules.push({ Name: null, Description: null, Condition: null, Rules: [], Config: null });
    } else {
      this.newRuleModel.Rules.push({ Name: null, Condition: null, Rules: [], Config: null, Description: null });
    }
    this.onNewRuleModelChange();
  }

  addNewRuleSet(rule: PromotionRule = null) {
    if (rule) {
      rule.Rules.push({ Name: null, Description: null, Condition: 'And', Rules: [], Config: null });
    } else {
      this.newRuleModel.Rules.push({ Name: null, Condition: 'And', Rules: [], Config: null, Description: null });
    }
    this.onNewRuleModelChange();
  }
  criteriaDropdownChange(event, rule: PromotionRule) {
    if (event) {
      rule.Config = cloneDeep(event.Config);
      rule.Description = cloneDeep(event.Description);
    } else {
      rule.Config = null;
      rule.Description = null;
    }
    this.onNewRuleModelChange();
  }

  onDeleteRuleGroup() {
    this.delete.emit(this.ruleIndex);
  }

  onDelete(index) {
    if (this.newRuleModel?.Rules?.length) {
      this.newRuleModel.Rules.splice(index, 1);
    }
    this.onNewRuleModelChange();
  }
  onNewRuleModelChange() {
    this.newRuleModelChange.emit(this.newRuleModel);
  }

}
