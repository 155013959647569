import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UserPayRate } from '../../interface';
import { GridColumn, EditColumn, DeleteColumn, DateColumn, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { UserService } from '../../../../shared/services/user.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { finalize } from 'rxjs/operators';
import { Messages } from '../../../../shared/constants/';
import { ConfirmDeleteComponent } from 'src/app/shared/components/confirm-delete/confirm-delete.component';
import { JobCodeService } from 'src/app/information-management/job-code/service/job-code.service';
declare let $: any;
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { Format } from '@tarktech/tark-ng-utils/table/format-type';
import { ApplicationStateService } from 'src/app/shared/services';
import { NgForm } from '@angular/forms';
@Component({
    selector: 'pos-user-pay-rates-add-edit',
    templateUrl: './user-pay-rates-add-edit.component.html',
    styleUrls: ['./user-pay-rates-add-edit.component.scss']
})
export class UserPayRatesAddEditComponent implements OnInit {

    @Input() userId: number;
    payRateList: UserPayRate[] = [];
    jobCodes: any = [];
    payRate: UserPayRate;
    payRateId: number;
    payRatColumns: Array<GridColumn> = [];
    dateFormat = 'mm-dd-yyyy';
    currencySymbol = null;
    @ViewChild('amountTemplate', { static: true }) private amountTemplate: any;
    @ViewChild('confirmUserPayRate') private confirmUserPayRate: ConfirmDeleteComponent;
    @ViewChild('userPayRateForm') userPayRateForm: NgForm;
    constructor(
        private userService: UserService,
        private alertService: AlertsService,
        private spinnerService: SpinnerService,
        private applicationStateService: ApplicationStateService,
        private jobCodeService: JobCodeService
    ) { }

    ngOnInit() {
        this.payRate = this.userService.newUserPayRate();
        this.dateFormat = this.applicationStateService.settingParam.PCalendarDateFormat;
        this.configureGridColumns();
        this.loadJobCodeList();
        this.resizeWindow();
        if (this.userId > 0) {
            this.reloadPayRates();
        }
        this.currencySymbol = this.applicationStateService.settingParam.CurrencySymbol;
    }

    resizeWindow() {
        $(window).resize(() => {
            this.setTableHeaderStyle();
        });
    }

    setTableHeaderStyle = () => {
        const tableWidth = $('.p-datatable-scrollable-body > table').width();
        $('.p-datatable-scrollable-header-box').width(tableWidth);
    }

    private configureGridColumns() {
        const editColumn = new EditColumn({
            Clicked: (payRate) => {
                this.editUserPayRate(payRate);
            },
            Width: '35px'
        });

        const deleteColumn = new DeleteColumn({
            Clicked: (payRate) => {
                this.deleteUserPayRate(payRate);
            },
            Width: '35px'
        });

        const amountColumn = new TemplateColumn({
            HeaderText: 'Hourly Rate',
            itemTemplate: this.amountTemplate,
            Field: 'amount',
            Width: '28%',
            TextAlign: TextAlign.Right,
            IsSortable: true
        });

        this.payRatColumns = [
            new GridColumn({ HeaderText: 'Job Code', Field: 'name', IsSortable: true, Width: '29%' }),
            new DateColumn({
                HeaderText: 'Effective Date',
                Format: Format.Date,
                Field: 'effective_date',
                IsSortable: true,
                Width: '29%',
                TextAlign: TextAlign.Left
            })
        ] as Array<GridColumn>;

        this.payRatColumns.splice(0, 0, amountColumn);

        this.payRatColumns.push(editColumn);
        this.payRatColumns.push(deleteColumn);
    }
    private reloadPayRates() {
        this.spinnerService.show();
        this.userService.getUserPayRate(this.userId)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (payRates: UserPayRate[]) => {
                    this.payRateList = [];
                    if (payRates && payRates.length > 0) {
                        this.payRateList = payRates;
                    }
                }, error: this.alertService.showApiError
            });
    }
    private loadJobCodeList() {
        this.spinnerService.show();
        this.jobCodeService.getJobCodeList()
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (jobCodes: any) => {
                    if (jobCodes && jobCodes.length > 0) {
                        this.jobCodes = jobCodes;
                    }
                }, error: this.alertService.showApiError
            });
    }
    private editUserPayRate(payRate: UserPayRate) {
        this.payRate.effective_date = new Date(payRate.effective_date);
        this.payRate.amount = payRate.amount;
        this.payRate.job_code_id = payRate.job_code_id;
        this.payRate.id = payRate.id;
        this.payRate.user_id = payRate.user_id;
    }
    private deleteUserPayRate(payRate: UserPayRate) {
        this.payRateId = payRate.id;
        this.confirmUserPayRate.Show(StringUtils.format(Messages.ConfirmDeleteUserPayRate, { 'payRate': payRate.name }));
    }
    onConfirmAcknowledge() {
        this.spinnerService.show();
        this.userService.deleteUserPayRate(this.userId, this.payRateId)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: () => {
                    this.payRateId = 0;
                    this.reloadPayRates();
                }, error: this.alertService.showApiError
            });
    }
    saveUserPayRate(isValid: boolean) {
        if (!isValid) {
            return;
        }
        if (this.payRate.id > 0) {
            this.updateUserPayRate();
        } else {
            this.addUserPayRate();
        }
    }
    private addUserPayRate() {
        this.payRate.user_id = this.userId;
        this.spinnerService.show();
        this.userService.addUserPayRate(this.userId, this.payRate)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: () => {
                    this.alertService.renderSuccessMessage(Messages.PayRateSaveSuccess);
                    this.reset();
                    this.reloadPayRates();
                }, error: this.alertService.showApiError
            });
    }
    private updateUserPayRate() {
        this.spinnerService.show();
        this.userService.updateUserPayRate(this.userId, this.payRate)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: () => {
                    this.alertService.renderSuccessMessage(Messages.PayRateUpdateSuccess);
                    this.reset();
                    this.reloadPayRates();
                }, error: this.alertService.showApiError
            });
    }
    reset() {
        this.userPayRateForm.resetForm();
        this.payRate = this.userService.newUserPayRate();
    }
}
