import { cog } from './../../../shared/components/icon/icons';
import * as _ from 'lodash';
import { MakeTableColorConfig, MakeTableOrderModel, MakeTableUIState, MakeTableOrderItem } from 'src/app/make-table/interfaces';
import { OnInit, Component, OnDestroy } from '@angular/core';
import { AlertsService, ApplicationStateService, ColorUtilityService, DomainConstants, MessageTypes, OrderService, RabbitMQService, SettingParam, SpinnerService } from 'src/app/shared';
import { filter, find, findIndex, forEach, map, some, sortBy, sum } from 'lodash';
import { OrderCardService } from 'src/app/shared/services/order-card.service';
import { ServeScreenService } from '../../services/serve-screen.service';
declare let $: any;
import { filter as rxJsFilter, finalize } from 'rxjs/operators';
import { TerminalProperty } from 'src/app/configurator/terminals/interface/terminal-property';
import { TerminalsService } from 'src/app/configurator/terminals/services/terminals.service';
import { MakeTableService } from 'src/app/make-table/services/make-table.service';
import { Subscription } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pos-serve-screen',
  templateUrl: './serve-screen.component.html',
  styleUrls: ['./serve-screen.component.scss']
})
export class ServeScreenComponent implements OnInit, OnDestroy {

  public icons = {
    cog
  };
  currentTime = new Date().getTime();
  private _noOfColumns: number;
  serveScreenView = DomainConstants.MaketableView;
  serveScreenColorConfig: MakeTableColorConfig = {};
  serveScreenUIStates: Array<MakeTableUIState> = [];
  showProductsWithState: Array<string> = [];
  currentLayout = DomainConstants.MaketableView.Grid;
  columns: Array<number>;
  serveScreenOrders: Array<MakeTableOrderModel> = [];
  serveScreenLayoutViews = DomainConstants.MaketableView;
  isMobileMode: boolean;
  settingParam: SettingParam;
  serveButtonColor: string;
  serveButtonFontColor: string;
  serveScreenLayout: TerminalProperty;
  isShowNotifyButtonOnEachItem = false;
  headerLinks = {
    changeTerminal: false,
    mappedTerminals: false,
    time: false,
    signIn: false,
    tasks: false,
    activeOrders: false,
    admin: false,
    home: false
  };

  setNoOfColumns() {
    if ($(window).width() > 1199) {
      this.noOfColumns = 5;
    } else if ($(window).width() > 650) {
      this.noOfColumns = 2;
    } else {
      this.noOfColumns = 1;
    }
  }

  get noOfColumns(): number {
    return this._noOfColumns;
  }

  set noOfColumns(value: number) {
    this._noOfColumns = value;
    this.columns = Array(this._noOfColumns).fill(1).map((x, i) => i + 1);
  }

  // rabbitMq subscriptions
  rabbitMqOrderDeleteSubscription: Subscription;
  rabbitMqOrderProductStateChangeSubscription: Subscription;
  rabbitMqOrderUpdateSubscription: Subscription;
  constructor(private applicationStateService: ApplicationStateService,
    private colorUtilityService: ColorUtilityService,
    private orderCardService: OrderCardService,
    private rabbitMQService: RabbitMQService,
    private makeTableService: MakeTableService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private orderService: OrderService,
    private terminalService: TerminalsService,
    private serveScreenService: ServeScreenService) {
  }



  ngOnInit() {
    this.settingParam = this.applicationStateService.settingParam;
    this.isShowNotifyButtonOnEachItem = this.settingParam.ItemReadyShowNotifyButtonOnServeScreen
    this.getTerminalProperty();
    this.getServeScreenOrders();
    this.setServeScreenColorConfig();
    this.setNoOfColumns();
    this.subscribeRabbitMq();
    this.setOrderTimerInterval();
    this.showProductsWithState.push(DomainConstants.MakeTableStates.READY);
    this.isMobileMode = $(window).width() < 1151 ? true : false;
  }
  ngOnDestroy() {
  }
  autoRefreshMakeTableOrders() {
    setInterval(() => {
      this.getServeScreenOrders(true);
    }, 60000);
  }

  getTerminalProperty() {
    this.terminalService.getTerminalProperties(this.applicationStateService.terminalId)
      .subscribe({
        next: (response: Array<TerminalProperty>) => {
          if (response && response.length > 0) {
            this.serveScreenLayout = find(response, (item) => {
              return item.PropertyKey === DomainConstants.TerminalProperties.LAYOUT.Key;
            });
            this.currentLayout = this.serveScreenLayout && this.serveScreenLayout.PropertyValue ? this.serveScreenLayout.PropertyValue
              : DomainConstants.MaketableView.Grid;
          } else {
            this.currentLayout = DomainConstants.MaketableView.Grid;
            this.serveScreenLayout = {
              Id: 0,
              TerminalId: this.applicationStateService.terminalId,
              PropertyKey: DomainConstants.TerminalProperties.LAYOUT.Key,
              PropertyValue: DomainConstants.MaketableView.Grid
            };
          }
        }
      });
  }

  subscribeRabbitMq() {
    this.subscribeToOrderServedQueue();
    this.subscribeToOrderItemDeletedQueue();
    this.subscribeToMakeTableExchangeForAllOrderEntryTerminals();
  }

  subscribeToOrderServedQueue() {
    this.rabbitMQService.subscribeToOrderServedMessage$()
      .subscribe((message) => {
        this.removeOrder(message.Payload.OrderId);
      });
  }

  subscribeToOrderItemDeletedQueue() {
    this.rabbitMQService.subscribeToOrderItemDeletedMessage$()
      .subscribe((message) => {
        this.removeItems(message.Payload.OrderId);
      });
  }

  subscribeToMakeTableExchangeForAllOrderEntryTerminals() {
    this.rabbitMqOrderDeleteSubscription = this.rabbitMQService.subscribeToOrderDeletedMessageForAllOrderEntry$()
      .subscribe((message) => {
        this.removeOrder(message.Payload.OrderId);
      });

    this.rabbitMqOrderProductStateChangeSubscription = this.rabbitMQService.subscribeToOrderItemStateChangedForAllOrderEntryMessage$()
      .subscribe((message) => {
        if (message.Source.Id !== this.applicationStateService.terminalId) {
          this.rabbitMQProductStateChange(message);
        }
      });

    this.rabbitMqOrderUpdateSubscription = this.rabbitMQService.subscribeToOrderUpdatedMessageForAllOrderEntry$()
      .subscribe((message) => {
        if (message.Source.Id !== this.applicationStateService.terminalId) {
          this.rabbitMqOrderUpdate(message);
        }
      });

  }

  rabbitMqOrderUpdate = (message) => {
    let orderIndex = null;
    orderIndex = findIndex(this.serveScreenOrders, (order) => {
      if (order) {
        return order.Id === message.Payload.OrderDetails.Id;
      }
    });
    if (orderIndex >= 0) {
      this.serveScreenOrders[orderIndex] = message.Payload.OrderDetails;
    } else {
      this.serveScreenOrders = [...this.serveScreenOrders, message.Payload.OrderDetails];
      this.serveScreenOrders = sortBy(this.serveScreenOrders, 'Id');
    }
    this.setOrderProperties();
  }

  rabbitMQProductStateChange(message) {
    const orderAvailable = find(this.serveScreenOrders, (order) => {
      if (order) {
        return order.Id === message.Payload.OrderDetails.Id;
      }
    });
    if (orderAvailable) {
      forEach(this.serveScreenOrders, (order) => {
        if (order && order.Id === message.Payload.OrderDetails.Id) {
          let orderItemAvailable = false;
          forEach(order.OrderItems, (item) => {
            const rabbitMQOrderProduct = message.Payload.OrderDetails.OrderItems[0];

            if (rabbitMQOrderProduct && item.Id === rabbitMQOrderProduct.Id) {
              item.MakeTableState = rabbitMQOrderProduct.MakeTableState;
              item.MakeTableBgColor = this.orderCardService.getItemMakeTableColor(item, this.serveScreenColorConfig);
              item.FontColor = this.colorUtilityService.getContrastColor(item.MakeTableBgColor);
              item.OrderUpdate = false;
              item.MakeTableStateByUserFirstName = message.Payload.OrderDetails.OrderItems[0]?.MakeTableStateByUserFirstName;
              item.MakeTableStateByUserLastName = message.Payload.OrderDetails.OrderItems[0]?.MakeTableStateByUserLastName;
              item.MakeTableStateByUserRole = message.Payload.OrderDetails.OrderItems[0]?.MakeTableStateByUserRole;
              item.IsMakeTableStateChangeByGenericUser = this.checkIsMakeTableStateChangeByGenericUser(item);
              orderItemAvailable = true;
            }
          });
          this.serveScreenUIStates[order.Id].IsReadyProductAvailable = this.checkIsReadyProductAvailable(order);
          this.serveScreenUIStates[order.Id].IsReadyToServe = this.orderHasServeButton(order);
        }
      });
    } else {
      this.getServeScreenOrder(message.Payload.OrderDetails.Id);
    }
  }

  getServeScreenOrder(orderId) {
    this.serveScreenService.getServeScreenOrder(orderId)
      .subscribe({
        next: (res) => {
          const orderAvailable = find(this.serveScreenOrders, (order) => {
            if (order) {
              return order.Id === orderId;
            }
          });
          if (!orderAvailable) {
            this.serveScreenOrders = [...this.serveScreenOrders, res];
            this.serveScreenOrders = sortBy(this.serveScreenOrders, 'Id');
            this.setOrderProperties();
          }
        }
      });
  }

  removeOrder(orderId) {
    this.serveScreenOrders = filter(this.serveScreenOrders, (order) => {
      return order.Id !== orderId;
    });
  }

  removeItems(orderItemId) {
    forEach(this.serveScreenOrders, (order) => {
      if (order) {
        order.OrderItems = filter(order.OrderItems, (orderItem: MakeTableOrderItem) => {
          return orderItem.Id !== orderItemId;
        });
      }
    });
  }

  setServeScreenColorConfig() {
    this.serveScreenColorConfig.MakeTableCompleteColor = this.settingParam.MakeTableCompleteColor;
    this.serveScreenColorConfig.MakeTableReadyColor = this.settingParam.MakeTableReadyColor;
    this.serveScreenColorConfig.MakeTableAccountColor = this.settingParam.MakeTableAccountColor;
    this.serveButtonColor = this.settingParam.MakeTableServeColor ? this.settingParam.MakeTableServeColor : 'linear-gradient(to bottom,#d9534f 0,#c12e2a 100%)';
    this.serveButtonFontColor = this.settingParam.MakeTableServeFontColor ? this.settingParam.MakeTableServeFontColor :
      this.colorUtilityService.getContrastColor(this.serveButtonColor);
  }

  getServeScreenOrders(isFromAutoRefresh = false) {
    if (!isFromAutoRefresh) {
      this.spinnerService.show();
    }
    this.serveScreenService.getServeScreenOrders()
      .pipe(finalize(() => {
        if (!isFromAutoRefresh) {
          this.spinnerService.hide();
        }
      }))
      .subscribe({
        next: (res) => {
          this.serveScreenOrders = res;
          this.setOrderProperties();
        }
      });
  }

  getDataForColumn(column: number) {
    let pickIndex = column - 1;
    const columnData = [];
    const filteredOrder = filter(this.serveScreenOrders, (order) => {
      if (order) {
        this.serveScreenUIStates[order.Id].IsReadyToServe = this.orderHasServeButton(order);
        return this.serveScreenUIStates[order.Id]?.IsReadyProductAvailable;

      }
    });
    if (filteredOrder) {
      while (pickIndex < filteredOrder.length) {
        columnData.push(filteredOrder[pickIndex]);

        pickIndex += this.noOfColumns;
      }
    }
    return columnData;
  }

  setOrderProperties() {
    forEach(this.serveScreenOrders, (order) => {
      if (order) {
        forEach(order.OrderItems, (orderItem, index) => {
          if (orderItem) {
            this.setMakeTableColors(orderItem);
          }
          orderItem.IsMakeTableStateChangeByGenericUser = this.checkIsMakeTableStateChangeByGenericUser(orderItem);
        });
        this.addMissingServeScreenUIState(order);
      }
    });
    this.serveScreenOrders = filter(this.serveScreenOrders, (makeTableOrder) => {
      return makeTableOrder && !!this.serveScreenUIStates[makeTableOrder.Id]?.TotalOrderProducts;
    });
  }

  setMakeTableColors(orderItem) {
    orderItem.MakeTableBgColor = this.orderCardService.getItemMakeTableColor(orderItem, this.serveScreenColorConfig);
    orderItem.FontColor = this.colorUtilityService.getContrastColor(orderItem.MakeTableBgColor);
    orderItem.QtyBgColor = this.orderCardService.getBackGroundColor(orderItem.Qty, this.settingParam.MakeTableQuantityColors);
    orderItem.QtyFontColor = this.colorUtilityService.getContrastColor(orderItem.QtyBgColor);
  }

  calculateOrderTime(order) {
    return this.orderCardService.calculateOrderTime(order.MakeTableDateTime);
  }

  setOrderTimerInterval() {
    const timerRefreshInterval = 5;
    setInterval((e) => {
      if (this.serveScreenUIStates) {
        forEach(this.serveScreenOrders, (order) => {
          if (order) {
            this.serveScreenUIStates[order.Id].FormattedTime = this.calculateOrderTime(order);
          }
        });
      }
      this.currentTime = new Date().getTime();
    }, timerRefreshInterval * 1000);
  }

  addMissingServeScreenUIState(order) {
    let productCount: string | number = sum(map(filter(order.OrderItems, (orderItem) => {
      return orderItem.OrderItemType === DomainConstants.OrderItemTypes.Product
        && orderItem.OrderProductComponents[0].IsShowOnMakeTable;
    }), 'Qty'));
    if (productCount && productCount % 1 !== 0) {
      productCount = productCount.toFixed(2);
    }
    const uiState: MakeTableUIState = {
      FormattedTime: this.calculateOrderTime(order),
      IsReadyToServe: this.orderHasServeButton(order),
      TotalOrderProducts: productCount,
      IsCollapsed: false,
      IsCollapsible: false,
      IsReadyProductAvailable: this.checkIsReadyProductAvailable(order),
      SurrogateOrderId: order.SurrogateOrderId
    };
    this.serveScreenUIStates[order.Id] = uiState;
  }

  orderHasServeButton(order): boolean {
    return order.OrderItems?.filter(x => x.MakeTableState == DomainConstants.MakeTableStates.READY)?.length > 1;
  }

  serveAll(order) {
    let orderProducts = [];
    order.OrderItems.forEach(item => {
      if (item.MakeTableState === DomainConstants.MakeTableStates.READY) {
        orderProducts.push(this.prepareOrderProductObject(item, order.Id));
      }
    });
    if (orderProducts?.length > 0) {
      this.makeTableService.updateAllProductState(this.applicationStateService.terminalId, order.Id, orderProducts)
        .subscribe({
          next: () => {
            if (this.checkIsReadyToServe(order)) {
              this.serveThisOrder(order.Id);
            }
          }, error: (err) => {
            this.alertService.showApiError(err);
          }
        });
    }
  }

  notifyItemReady(event) {
    const orderId = event.Order.Id;
    const itemId = event.Product.Id;
    this.orderService.notifyItemReady(orderId, itemId)
      .subscribe({
        error: this.alertService.showApiError
      });
  }

  prepareOrderProductObject(orderProduct, orderId) {
    orderProduct.MakeTableState = DomainConstants.MakeTableStates.DONE;
    orderProduct.MakeTableStateByUserFirstName = this.applicationStateService.userDetails?.firstname;
    orderProduct.MakeTableStateByUserLastName = this.applicationStateService.userDetails?.lastname;
    if (this.applicationStateService.userDetails?.Roles) {
      orderProduct.MakeTableStateByUserRole = [];
      forEach(this.applicationStateService?.userDetails?.Roles, (role) => {
        orderProduct.MakeTableStateByUserRole.push(role.RoleName);
      });
    }
    orderProduct.IsMakeTableStateChangeByGenericUser = this.checkIsMakeTableStateChangeByGenericUser(orderProduct);

    return this.orderCardService.getProductStatusUpdateModel(orderProduct, orderId,
      DomainConstants.MakeTableStates.READY, 0);
  }

  orderItemStateChange(event) {
    const orderProduct: MakeTableOrderItem = event.Product;
    const order: MakeTableOrderModel = event.Order;
    if (orderProduct.MakeTableState === DomainConstants.MakeTableStates.READY) {
      const productStatusUpdateModel = this.prepareOrderProductObject(orderProduct, order.Id);
      this.makeTableService.updateProductState(this.applicationStateService.terminalId, order.Id, productStatusUpdateModel)
        .subscribe({
          next: () => {
            if (this.checkIsReadyToServe(order)) {
              this.serveThisOrder(order.Id);
            }
          }, error: (err) => {
            this.alertService.showApiError(err);
          }
        });
      this.serveScreenUIStates[order.Id].IsReadyProductAvailable = this.checkIsReadyProductAvailable(order);
      this.serveScreenUIStates[order.Id].IsReadyToServe = this.orderHasServeButton(order);
    }
  }

  checkIsMakeTableStateChangeByGenericUser = (product) => {
    return some(product.MakeTableStateByUserRole, (role) => {
      return role === DomainConstants.GENERIC_USER;
    });
  }

  serveThisOrder(orderId) {
    if (orderId) {
      this.orderService.serveOrder(orderId, this.applicationStateService.userDetails.id)
        .subscribe({
          next: () => {
          }, error: this.alertService.showApiError
        });
      this.serveScreenOrders = filter(this.serveScreenOrders, (order) => {
        return order.Id !== orderId;
      });
    }
  }

  checkIsReadyToServe(order) {
    const products = some(order.OrderItems, product => product.MakeTableState !== DomainConstants.MakeTableStates.DONE
      && product.OrderItemType === DomainConstants.OrderItemTypes.Product
      && product.OrderProductComponents[0].IsShowOnMakeTable);
    if (order.GrandTotal <= order.PaidAmount && !products) {
      return true;
    } else {
      return false;
    }
  }

  checkIsReadyProductAvailable(order) {
    return some(order.OrderItems, product => product.OrderItemType === DomainConstants.OrderItemTypes.Product
      && (product.MakeTableState === DomainConstants.MakeTableStates.READY));
  }

  changeLayout(layout) {
    if (layout !== this.currentLayout) {
      this.serveScreenLayout.PropertyValue = layout;
      this.terminalService.saveMakeTableTerminalProperty(this.serveScreenLayout)
        .subscribe({ next: () => { } });
      this.currentLayout = layout;
      this.setNoOfColumns();
    }
  }

}

