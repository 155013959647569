import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Permissions, Levels } from 'src/app/shared';
import {
  hardwareInterfacePlug,
  database,
  infoManageAngleDoubleRight,
  infoManageAngleDoubleLeft,
  scroll
} from 'src/app/shared/components/icon';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
declare let $: any;

@Component({
  selector: 'pos-automated-export-base',
  templateUrl: './automated-export-base.component.html',
  styleUrls: ['./automated-export-base.component.scss']
})
export class AutomatedExportBaseComponent implements OnInit {

  isFullScreen = false;
  public icons = {
    hardwareInterfacePlug,
    database,
    infoManageAngleDoubleRight,
    infoManageAngleDoubleLeft,
    scroll
  };
  permissions = Permissions;
  accessLevels = Levels;

  constructor(private applicationStateService: ApplicationStateService,
              private router: Router) { }

  ngOnInit(): void {
    if (this.router.url === 'manage/app-configuration') {
      this.applicationStateService.gridRouteContext = null;
    }
  }

  toggleFullScreen() {
    this.isFullScreen = !this.isFullScreen;
    if (this.isFullScreen) {
      $('.tab-content').css('width', '100%');
    } else {
      $('.tab-content').css('width', '80%');
    }
  }

}
