import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { InfractionService } from '../../service/infraction.service';
import { Infraction } from '../../interface';
import { finalize } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { User } from 'src/app/information-management/users';
import { infoManageInfractionThumbsDown, infractionThumbsDown } from 'src/app/shared/components/icon';
import { BaseCrudComponent } from 'src/app/shared/components/base-crud.component';
import { Messages } from 'src/app/shared/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/user.service';
import { ApplicationStateService, FormUtilityService } from 'src/app/shared/services';

@Component({
    selector: 'pos-infraction-edit',
    templateUrl: './infraction-edit.component.html',
})
export class InfractionEditComponent extends BaseCrudComponent<Infraction> implements OnInit {

    infraction: Infraction;
    users: User[] = [];
    public icons = {
        infractionThumbsDown,
        infoManageInfractionThumbsDown
    };
    @ViewChild('infractionForm') infractionForm: NgForm;
    dateFormat = 'mm-dd-yyyy';
    get getForm(): NgForm {
        return this.infractionForm
    }
    props = {
        controlBoxClass: 'col-lg-4 col-md-4 col-sm-6 col-xs-12'
    }

    constructor(
        protected alertService: AlertsService,
        protected spinnerService: SpinnerService,
        protected infractionService: InfractionService,
        private router: Router,
        private userService: UserService,
        private applicationStateService: ApplicationStateService,
        private route: ActivatedRoute,
        formUtilityService: FormUtilityService
    ) {
        super(infractionService, alertService, spinnerService, formUtilityService);
        this.id = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
    }

    ngOnInit() {
        this.infraction = this.infractionService.newInfraction();
        this.saveSuccessMessage = Messages.InfractionSaveSuccess;
        this.dateFormat = this.applicationStateService.settingParam.PCalendarDateFormat;
        this.dataSubscription();
        this.scrollToTop();
    }
    private dataSubscription(): void {
        if (this.id > 0) {
            this.spinnerService.show();
            this.loadData().pipe(finalize(() => {
                this.spinnerService.hide();
            }))
                .subscribe({
                    next: (res) => {
                        this.infraction = res;
                        this.infraction.InfractionDate = new Date(this.infraction.InfractionDate);
                    }, error: this.alertService.showApiError
                });
        }
    }
    public loadDependencies() {
        this.spinnerService.show();
        this.userService.getAllActiveUser()
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (users: User[]) => {
                    this.users = users;
                }, error: this.alertService.showApiError
            });
    }
    public submit(isValid: boolean) {
        if (!isValid) {
            return;
        }
        this.save(this.infraction);
    }
    public onSaveSuccess(model: Infraction): void {
        this.cancelEditing();
    }
    public cancelEditing(): void {
        this.router.navigate(['infractions'], { relativeTo: this.route.parent });
    }
    private scrollToTop(): void {
        window.scrollTo(0, 0);
    }
}
