import { Component, OnInit, ViewChild, Output, EventEmitter, Input, HostListener } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AlertsService, SpinnerService, ModalService, ModalComponent, spinner, DomainConstants, ApplicationStateService } from 'src/app/shared';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { PrepLabelPrintService } from '../service/prep-label-print.service';

@Component({
  selector: 'pos-prep-label-print',
  templateUrl: './prep-label-print.component.html',
  styleUrls: ['./prep-label-print.component.scss']
})
export class PrepLabelPrintComponent extends ModalComponent implements OnInit {
  // @HostListener('window:keydown', ['$event'])
  // handleKeyboardEvent(event: KeyboardEvent) {
  //   this.onKeyPress(event);
  // }
  @ViewChild('prepLabelPrintForm') prepLabelPrintForm: NgForm;
  @Input('productId') productId: number;
  @Input('productName') productName: string;
  @Output('closeModal') closeModal: EventEmitter<any> = new EventEmitter();
  labelCount: number = 1;
  maxAllowedPrintLabelCount: number = 0;
  constructor(
    modalRef: BsModalRef,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private modalService: ModalService,
    private applicationStateService: ApplicationStateService,
    private prepLabelPrintService: PrepLabelPrintService
  ) { super(modalRef); }

  ngOnInit() {
    const settingParam = this.applicationStateService.settingParam;
    if (settingParam.MaxPrepLabels) {
      this.maxAllowedPrintLabelCount = settingParam.MaxPrepLabels;
    } else {
      this.maxAllowedPrintLabelCount = 15;
    }
  }

  cancel() {
    this.hide({});
    this.closeModal.emit();
  }

  prepLabelPrint(isValid) {
    if (!isValid || this.labelCount > this.maxAllowedPrintLabelCount || this.labelCount == 0) {
      return;
    } else {
      this.spinnerService.show();
      const prepLabelPrintObj = {
        InventoryProductId: this.productId,
        PrintCount: this.labelCount,
        TerminalId: this.applicationStateService.terminalId
      };
      this.prepLabelPrintService.prepLabelPrint(prepLabelPrintObj).subscribe(
        res => {
          this.alertService.renderSuccessMessage(Messages.PrepLabelPrintRequestSendSuccess);
          this.cancel();
        }, this.alertService.showApiError, () => {
          this.spinnerService.hide();
        });
    }
  }

  onKeyPress(event) {
    return event.charCode >= 48 && event.charCode <= 57;
  }

}
