import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { forEach } from 'lodash';
import { ApplicationStateService, DomainConstants, NumpadOptions, SettingParam } from 'src/app/shared';

@Component({
  selector: 'pos-ringup-numpad',
  templateUrl: './ringup-numpad.component.html',
  styleUrls: ['./ringup-numpad.component.scss']
})
export class RingupNumpadComponent implements OnInit {

  @Input() cashTendered?: number;
  @Input() isShowCashTender?: boolean;
  @Input() enteredAmount: number;
  @Input() numpadOptions: NumpadOptions;
  @Input() paymentButtonText: string;
  @Input() numpadOptionChange = new EventEmitter();
  @Input() isSubmitPayment: boolean;
  @Input() isDisablePaymentButton: boolean;
  @Input() isShowSubmitButton: boolean;
  @Output() cancelPayment = new EventEmitter();
  @Output() submitPayment = new EventEmitter();
  @Output() cashPayment = new EventEmitter();
  @Output() creditCardPayment = new EventEmitter();
  @Output() giftCardPayment = new EventEmitter();
  @Output() EBTPayment = new EventEmitter();
  settingParam: SettingParam;
  tenderTypeColors: any;
  numpadValue: number;
  isAllowCreditCard: boolean;
  @ViewChild('numpad') numpad: any;
  constructor(private applicationStateService: ApplicationStateService) { }

  ngOnInit(): void {
    this.settingParam = this.applicationStateService.settingParam;
    if (this.settingParam.AllowCreditCards && (this.settingParam.CreditCardTerminal.Id != 0 ||
      this.settingParam.CreditCardType === DomainConstants.CreditCardType.Peripheral)) {
      this.isAllowCreditCard = true;
    }
    this.getTenderTypesColor();
    this.numpadValue = this.enteredAmount;
  }

  cancelPaymentPrice = () => {
    this.cancelPayment.emit();
  }

  makeCashPayment = (res) => {
    this.cashPayment.emit(res);
  }

  makeCreditCardPayment = () => {
    this.creditCardPayment.emit();
  }

  makeGiftCardPayment = () => {
    this.giftCardPayment.emit();
  }

  makeEBTPayment = () => {
    this.EBTPayment.emit();
  }


  submitCashPayment = (res) => {
    if (this.numpad && this.numpad.value > 0) {
      this.submitPayment.emit({value: this.numpad.value});
    }
  }

  getTenderTypesColor() {
    this.tenderTypeColors = {};
    const tenderTypesColors = this.settingParam.TenderTypesColors && this.settingParam.TenderTypesColors.length > 0 ?
      this.settingParam.TenderTypesColors.split(',') : [];
    forEach(tenderTypesColors, (tenderColor) => {
      const color = tenderColor.split(':');
      if (color && color[0] === DomainConstants.TenderTypes.CASH.Code) {
        this.tenderTypeColors.Cash = color[1];
      }
      else if (color && color[0] === DomainConstants.TenderTypes.CREDIT_CARD.Code) {
        this.tenderTypeColors.CreditCard = color[1];
      }
      else if (color && color[0] === DomainConstants.TenderTypes.GIFT_CARD.Code) {
        this.tenderTypeColors.GiftCard = color[1];
      }
      else if (color && color[0] === DomainConstants.TenderTypes.EBT.Code) {
        this.tenderTypeColors.EBT = color[1];
      }
    });
  }

}
