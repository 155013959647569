import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RuntimeConstants } from 'src/app/shared/constants';
import { BaseCrudService } from 'src/app/shared/services';
import { InventoryVendorProfile } from '../interface';
import { InventoryVendorProfileProduct } from '../interface/inventory-vendor-profile-product';

@Injectable({
  providedIn: 'root'
})
export class InventoryVendorProfileService extends BaseCrudService<InventoryVendorProfile> {

  apiUrl = `${RuntimeConstants.API_PATH}inventory-vendor-profile`;

  constructor(httpService: HttpClient) {
    super('inventory-vendor-profile', httpService);
  }

  newVendorProfile(): InventoryVendorProfile {
    return {
      Id: 0,
      Name: null,
      VendorId: null,
      InventoryVendor: null
    };
  }

  getAllProfiles(vendorId: number): Observable<Array<InventoryVendorProfile>> {
    return this.httpService.get<Array<InventoryVendorProfile>>(`${this.apiUrl}/profiles/${vendorId}`);
  }

  saveProfileWithProducts(inventoryVendorProfile: InventoryVendorProfile, profileProducts: Array<InventoryVendorProfileProduct>) {
    return this.httpService.post(`${this.apiUrl}/profile-products`,
      { InventoryVendorProfile: inventoryVendorProfile, ProfileProducts: profileProducts});
  }
}
