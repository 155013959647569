import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { debounce, find, flatMap, forEach, orderBy } from 'lodash';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { TerminalProperty, TerminalsService } from 'src/app/configurator/terminals';
import { OrderNavigationButton, ScreenButtons } from 'src/app/orders';
import { DomainConstants } from 'src/app/shared/constants';
import { home, search, cog, undo } from 'src/app/shared/components/icon';
import { MenuExplorerProductInfoComponent } from '../menu-explorer-product-info/menu-explorer-product-info.component';
import { ButtonNavigationService } from 'src/app/shared/services/button-navigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Configurations } from 'src/app/shared/interface/configurations';
import { ICloseable } from 'src/app/shared/components/modal/ICloseable';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { OrderService } from 'src/app/shared/services/order.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { OrderEventBroadcastingService } from 'src/app/shared/services/order-event-broadcasting.service';
import { ButtonBehavior } from 'src/app/shared/interface/button-behavior';
import { RabbitMQService } from 'src/app/shared/services/rabbitmq/services/rabbitmq.service';
declare let $: any;

@Component({
  selector: 'pos-menu-explorer',
  templateUrl: './menu-explorer.component.html',
  styleUrls: ['./menu-explorer.component.scss']
})
export class MenuExplorerComponent implements OnInit, OnDestroy {

  screenId: number = null;
  screenButtons: Array<ScreenButtons> = null;
  searchButtons: Array<OrderNavigationButton> = [];
  currentScreenButton: ScreenButtons = null;
  headingFontColor: string;
  searchText: string;
  filteredButtons: Array<OrderNavigationButton> = [];
  headerLinks = {
    tasks: false,
    home: false,
    warning: false,
    admin: false,
    time: false,
    activeOrders: false,
    signIn: false,
    customLinks: false,
    userMenu: false
  };
  icons = {
    home, search, cog, undo
  };
  oldMenuIndex: number = null;
  terminalId: number;
  designMode = false;
  screenWidth;
  appStateConfigurations: Configurations;
  private currentNavigation: Subscription;
  private navigationSubject: Subject<OrderNavigationButton> = new Subject<OrderNavigationButton>();
  inactiveTimeout: ReturnType<typeof setTimeout>;
  modal: ICloseable;
  timeoutSeconds = 30;
  terminalProperties = DomainConstants.TerminalProperties;

  search = debounce(() => {
    this.filterButtonsBySearchValue();
  }, 500, {
    'maxWait': 1000
  });

  private get navigation$(): Observable<OrderNavigationButton> {
    return this.navigationSubject.asObservable();
  }
  @HostListener('window:keydown')
  @HostListener('window:mousedown')
  @HostListener('window:touchmove')
  @HostListener('window:mousemove')
  @HostListener('window:wheel')
  @HostListener('window:tap')
  checkUserActivity() {
    clearTimeout(this.inactiveTimeout);
    this.checkTimeOut();
  }

  @HostListener('document:click', ['$event'])
  closeDropdown() {
    if (this.designMode) {
      $('.custom-order').css('display', 'none');
      this.oldMenuIndex = null;
    }
  }

  @HostListener('window:resize', ['$event'])
  resizeWindow() {
    this.screenWidth = window.innerWidth;
    this.setButtonSize();
  }

  constructor(private terminalService: TerminalsService,
    private applicationStateService: ApplicationStateService,
    private spinnerService: SpinnerService,
    private orderService: OrderService,
    private modalService: ModalService,
    private buttonNavigationService: ButtonNavigationService,
    private alertService: AlertsService,
    protected route: ActivatedRoute,
    protected router: Router,
    private rabbitMQService: RabbitMQService,
    protected orderEventBroadcastingService: OrderEventBroadcastingService) {
    const terminalId = route?.snapshot?.params?.id ? parseInt(route.snapshot.params.id, 10) : 0;
    this.terminalId = this.applicationStateService.terminalId;
    if (terminalId) {
      this.designMode = true;
      this.terminalId = terminalId;
    }
  }

  ngOnInit(): void {
    this.getRequiredData();
    this.headingFontColor = this.applicationStateService.settingParam.ColorPreference.HeadingFontColor;
    this.checkTimeOut();
    this.appStateConfigurations = this.applicationStateService.configurations;
    this.screenWidth = window.innerWidth;
    this.subscribeRequiredExchanges();
  }

  ngOnDestroy(): void {
    clearTimeout(this.inactiveTimeout);
  }

  getRequiredData() {
    this.subscribeToNavigation();
    this.spinnerService.show();
    const observables: Array<Observable<any>> = [];
    observables.push(this.terminalService.getTerminalProperties(this.terminalId));
    observables.push(this.orderService.getOrderNavigationDetails());
    forkJoin(observables)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: ([res, screenButtons]: [Array<TerminalProperty>, Array<ScreenButtons>]) => {
          if (res) {
            forEach(res, (terminalProperty) => {
              if (terminalProperty.PropertyKey === this.terminalProperties.HOME_SCREEN_ID.Key) {
                this.screenId = parseInt(terminalProperty.PropertyValue, 0);
              } else if (terminalProperty.PropertyKey === this.terminalProperties.IDLE_TIMEOUT.Key) {
                this.timeoutSeconds = terminalProperty.PropertyValue ? parseInt(terminalProperty.PropertyValue, 0) : 30;
              }
            });
            this.screenButtons = screenButtons ?? [];
            this.setButtonSize();
            this.currentScreenButton = screenButtons ? screenButtons[this.screenId] : null;
            this.validateButtonVisibility();
            this.buttonNavigationService.setEmbeddedScreenOrdinal(this.currentScreenButton.Buttons);
          }
        }, error: this.alertService.showApiError
      });
  }

  setButtonSize = () => {
    const areaWidth = $('#buttons-div').width();
    this.screenButtons = this.buttonNavigationService.setButtonSize(this.screenButtons, areaWidth, 250, true);
    this.currentScreenButton = this.currentScreenButton ?
      this.screenButtons[this.currentScreenButton.Screen.Id] : (this.screenButtons ? this.screenButtons[this.screenId] : null);
    this.setButtonInStock();
  }

  setButtonInStock() {
    forEach(this.screenButtons, (screenButton) => {
      forEach(screenButton.Buttons, (button) => {
        if (button.SalesProductId) {
          button.IsInStock = this.buttonNavigationService.setButtonInStock(button, null, false);
        }
      });
    });
  }

  validateButtonVisibility() {
    this.buttonNavigationService.validateButtonVisibility(this.currentScreenButton.Buttons, this.designMode);
  }
  subscribeRequiredExchanges() {
    this.subscribeToOrderEntryTerminalBroadcastQueue();
    // for out of stock product
    // this.subscribeToSalesProductOutOfStockEventExchange();
    // this.subscribeToSalesProductReplenishedEventExchange();
  }

  private subscribeToNavigation() {
    this.currentNavigation = this.navigation$
      .subscribe({
        next: (button: OrderNavigationButton) => {
          this.setNavigationFromBehaviors(button);
          const nextScreenId = button.StackScreenId ?? button.NextScreenId;
          if (button.SalesProductId) {
            this.openSalesProductInfoModal(button);
          } else if (nextScreenId) {
            this.loadNextScreen(nextScreenId);
          }
        }
      });
  }
  openSalesProductInfoModal(button: OrderNavigationButton) {
    if (!this.modal) {
      // for out of stock product
      // && (button.IsInStock || button.IsAllowOutOfStockOrder)
      const modalRef = this.modalService.getModalWrapper(MenuExplorerProductInfoComponent);
      this.modal = modalRef.show({
        animated: false,
        class: 'vertical-center modal-max-width-80 menu-explorer-product-info',
        initialState: {
          productInfo: button
        }
      });

      this.modal.close.subscribe(() => {
        this.modal = null;
      });
    }
  }

  buttonClick(button) {
    this.checkUserActivity();
    this.navigationSubject.next(button);
  }

  loadNextScreen(nextScreenId: number) {
    this.currentScreenButton = this.screenButtons[nextScreenId];
    this.validateButtonVisibility();
    this.buttonNavigationService.setEmbeddedScreenOrdinal(this.currentScreenButton.Buttons);
    this.scrollToTop();
  }

  setNavigationFromBehaviors(currentButton: OrderNavigationButton) {
    if (currentButton.Behaviors && currentButton.Behaviors.length) {
      const tempNavigationBehavior = find(currentButton.Behaviors, (behavior: ButtonBehavior) => {
        return behavior.BehaviorName === DomainConstants.ButtonBehaviors.TemporaryNav.Value;
      });

      if (tempNavigationBehavior) {
        const behaviorValues = JSON.parse(tempNavigationBehavior.TextValue);
        currentButton.StackScreenId = behaviorValues.screenId;
      }
    }
  }

  redirectToHomeScreen() {
    this.currentScreenButton = this.screenButtons ? this.screenButtons[this.screenId] : null;
    this.scrollToTop();
    this.validateButtonVisibility();
  }

  scrollToTop(): void {
    $('#buttons-div').animate({ scrollTop: 0 }, 'medium');
  }

  checkTimeOut() {
    if (!this.designMode) {
      this.inactiveTimeout = setTimeout(() => {
        this.resetToHome();
      }, this.timeoutSeconds * 1000);
    }
  }

  resetToHome() {
    this.searchText = null;
    this.search();
    if (this.modal) { this.modal.close.emit(); }
    if (this.screenId && this.currentScreenButton?.Screen?.Id !== this.screenId) {
      this.loadNextScreen(this.screenId);
    }
  }

  subscribeToOrderEntryTerminalBroadcastQueue() {
    this.orderEventBroadcastingService.terminalWarning.subscribe((data) => {
      if (data) {
        this.getOrderNavigationDetails(true);
      }
    });

    this.rabbitMQService.subscribeToTerminalWarningMessage$()
      .subscribe(() => {
        this.getOrderNavigationDetails(true);
      }, () => {
        console.log('error while connecting to RabbitMQ.');
      });
  }

  // subscribeToSalesProductOutOfStockEventExchange() {
  //   this.rabbitMQService.subscribeToSalesProductOutOfStockEventExchange$()
  //     .subscribe({ next: (message: any) => {
  //       if (message.Payload.POSEvent) {
  //         this.setSalesProductStockStatus(message.Payload.POSEvent.SalesProductId, message.Payload.POSEvent.SizeIds, false);
  //       }
  //     });
  // }

  // subscribeToSalesProductReplenishedEventExchange() {
  //   this.rabbitMQService.subscribeToSalesProductReplenishedEventExchange$()
  //     .subscribe({ next: (message: any) => {
  //       if (message.Payload.POSEvent) {
  //         this.setSalesProductStockStatus(message.Payload.POSEvent.SalesProductId, message.Payload.POSEvent.SizeIds, true);
  //       }
  //     });
  // }

  getOrderNavigationDetails(reload: boolean) {
    if (reload) {
      this.getRequiredData();
    }
  }

  closeDesignMode() {
    this.router.navigate(['manage/system-configuration/terminal']);
  }

  selectSearch(event) {
    this.filterButtonsBySearchValue(event.ButtonText);
  }

  filterButtonsBySearchValue(searchText = null) {
    this.filteredButtons = [];
    this.searchButtons = [];
    const screenButtons = flatMap(Object.values(this.screenButtons).map(x => x.Buttons));
    if (this.searchText?.length > 2 || searchText) {
      const searchData = this.buttonNavigationService.searchFromArray(screenButtons, searchText ?? this.searchText, true);
      this.filteredButtons = searchData.buttonsHavingSearchValue;
      this.searchButtons = orderBy(searchData.buttons, 'ButtonText');
    }
    this.buttonNavigationService.setIsSearch(screenButtons, this.filteredButtons);
  }

  // for out of stock product
  // setSalesProductStockStatus = (productId: number, sizeIds: Array<number>, status: boolean) => {
  //   // Update IsInStock flag in main object
  //   forEach(this.screenButtons, (screenButton) => {
  //     forEach(screenButton.Buttons, (button) => {
  //       this.setInStockForDefaultSize(button, productId, sizeIds, status);
  //     });
  //   });

  //   // Update IsInStock flag in visible screen's button list object
  //   forEach(this.currentScreenButton.Buttons, (button) => {
  //     this.setInStockForDefaultSize(button, productId, sizeIds, status);
  //   });
  // }

  // for out of stock product
  // setInStockForDefaultSize(button: OrderNavigationButton, productId: number, sizeIds: Array<number>, status: boolean) {
  //   if (button.SalesProductId === productId) {
  //     button.IsInStock = status;
  //     this.buttonNavigationService.setSalesProductSizeInStock(button, status, sizeIds);
  //   }
  // }
}
