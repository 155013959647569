import { Injectable } from '@angular/core';
import { RuntimeConstants } from '../constants/runtime-constants';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DashboardLayoutSpecification } from '../interface/dashboard-layout-specification';
import { DashboardLayout } from '../interface/dashboard-layout';
import { DashboardWidget } from '../interface/dashboard-widget';
import { DashboardWidgetAssignment } from '../interface/dashboard-widget-assignment';
import { DashboardConfiguration } from '../interface/dashboard-configuration';
import { Dashboard } from '../interface/dashboard';
import { AdfModel } from 'src/app/adf/interfaces';
import { DashboardDataSourceModel } from 'src/app/adf/interfaces/dashboard-data-source-model';

@Injectable({
  providedIn: 'root'
})
export class DashboardConfigurationService {

  dashboardApi = `${RuntimeConstants.API_PATH}DashboardConfiguration`;
  dashboardStatesParams = {};

  constructor(private httpClient: HttpClient) { }

  newDashboard() {
    const dashboard: AdfModel = {
      Id: 0,
      Name: '',
      AutoRefreshInterval: null,
      LayoutId: null,
      LayoutName: '',
      IsSystemDefined: false,
      IsShowClock: false,
      IsEditMode: false,
      layouts: null,
      rows: null,
      structure: '',
      IsInteractive: false,
      widgets : []
    };
    return dashboard;
  }

  // Call the ajax service  and send the model or data  with the path for the webApi location and the name of method that should be invoke.
  getDashboardConfigurations(dashboardId: number): Observable<Array<DashboardConfiguration>> {
    return this.httpClient.get<Array<DashboardConfiguration>>(`${this.dashboardApi}/GetDashboardConfigurations/${dashboardId}`);
  }

  getDashboard(dashboardId): Observable<Dashboard> {
    return this.httpClient.get<Dashboard>(`${this.dashboardApi}/GetDashboard/${dashboardId}`);
  }

  getDashboardByName(name): Observable<Dashboard> {
    return this.httpClient.get<Dashboard>(`${this.dashboardApi}/GetDashboardByName/${name}`);
  }

  getDashboards(): Observable<Array<Dashboard>> {
    return this.httpClient.get<Array<Dashboard>>(`${this.dashboardApi}/GetDashboards`);
  }

  getDashboardLayouts(): Observable<Array<DashboardLayout>> {
    return this.httpClient.get<Array<DashboardLayout>>(`${this.dashboardApi}/GetDashboardLayouts`);
  }

  getDashboardLayoutSpecifications(): Observable<Array<DashboardLayoutSpecification>> {
    return this.httpClient.get<Array<DashboardLayoutSpecification>>(`${this.dashboardApi}/GetDashboardLayoutSpecifications`);
  }

  getDashboardWidgets(): Observable<Array<DashboardWidget>> {
    return this.httpClient.get<Array<DashboardWidget>>(`${this.dashboardApi}/GetDashboardWidgets`);
  }

  saveDashboard(model): Observable<number> {
    return this.httpClient.post<number>(`${this.dashboardApi}/saveDashboard`, model);
  }

  saveDashboardWidgetAssignment(dashboardId: number, widget) {

    const model: DashboardWidgetAssignment = {
      DashboardId: dashboardId,
      Id: widget.widgetAssignmentId,
      widgetId: widget.widgetId,
      RowIndex: widget.rowIndex,
      ColumnIndex: widget.columnIndex,
      WidgetTitle: widget.title,
      AutoRefreshInterval: widget.AutoRefreshInterval,
      Configuration: JSON.stringify(widget.config),
      DatasourceId: widget.DatasourceId
    };

    return this.httpClient.post(`${this.dashboardApi}/saveDashboardWidgetAssignment`, model);
  }

  saveDashboardWidget(model: DashboardWidget) {
    return this.httpClient.post(`${this.dashboardApi}/saveDashboardWidget`, model);
  }

  deleteDashboardWidgetAssignments(widgetAssignmentIds: string) {
    return this.httpClient.delete(`${this.dashboardApi}/DeleteDashboardWidgetAssignments/${widgetAssignmentIds}`);
  }

  //Other useful functions
  jsonData(data) {
    try {
      data = JSON.parse(data);
    } catch (e) {
    }
    return data;
  }

  splitCamelCaseToString(s: string) {
    return (s.charAt(0).toUpperCase() + s.slice(1)).replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  filterDatasources(datasources: Array<DashboardDataSourceModel>, allowedTypes) {
    if (datasources && datasources.length > 0 && allowedTypes && allowedTypes.length > 0) {
      datasources = datasources.filter((datasource) => {
        return allowedTypes.split(',').indexOf(datasource.OutputType) !== -1;
      });
    }
    return datasources;
  }

  parseByDataType(data, outputType) {
    switch (outputType) {
      case 'Int':
      case 'int':
        data = parseInt(data, 10);
        break;

      case 'Currency':
      case 'currency':
      case 'Decimal':
      case 'decimal':
        data = parseFloat(data);
        break;

      default:
        break;
    }
    return data;
  }

  setDashboardStatesParams(params) {
    this.dashboardStatesParams = params;
  }

  getDashboardStatesParams() {
    return this.dashboardStatesParams;
  }

  getDashboardConfigurationsByTerminal(terminalId): Observable<Array<DashboardConfiguration>> {
    return this.httpClient.get<Array<DashboardConfiguration>>(`${this.dashboardApi}/dashboard-configurations/${terminalId}`);
  }
}
