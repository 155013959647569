<div>
  <h4 class="modal-content__heading" name="numpadHeader">{{numpadTitle}}</h4>
</div>

<div class="modal-body pt-0">
  <div class="row" *ngIf="isDisplayTaxable">
    <div class="col-3 pe-0">
      <div class="col-12 pt-pix-15">
        <div class="numpad__input"> &nbsp;</div>
      </div>
      <div class="col-12 pt-pix-15">
        <div class="btn-cal">#</div>
      </div>
      <div class="col-12 pt-pix-15" *ngIf="button.SpecialFunction == markupManual">
        <div class="btn-cal" (click)="taxableSelection()">{{taxableItem}}</div>
      </div>
    </div>
    <div class="col-9">
      <pos-numpad numpadId="manualDiscountModal" [value]="discountValue" [options]="numpadOptions"
        [onCancel]="onAddDiscountCancel" [onSubmit]="onAddDiscountSubmit">
      </pos-numpad>
    </div>
  </div>
  <div class="row" *ngIf="!isDisplayTaxable">
    <div class="col-12">
      <pos-numpad numpadId="manualDiscountModal" [value]="discountValue" [options]="numpadOptions"
        [onCancel]="onAddDiscountCancel" [onSubmit]="onAddDiscountSubmit">
      </pos-numpad>
    </div>
  </div>
</div>
