import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackOfficeComponent } from './back-office.component';
import { BACK_OFFICE_STATES } from './back-office.routes';
import { HeaderEntryModule } from '../header-entry/header-entry.module';
import { ManageConsoleModule } from '../manage-console/manage-console.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [BackOfficeComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(BACK_OFFICE_STATES),
    HeaderEntryModule,
    ManageConsoleModule
  ]
})
export class BackOfficeModule { }
