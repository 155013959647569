import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { WorkScheduleTag } from '../../information-management/work-schedule/interface/work-schedule';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';


@Injectable()
export class WorkScheduleTagService extends BaseCrudService<WorkScheduleTag> {

    constructor(httpService: HttpClient) {
        super('workScheduleTags', httpService);
    }
    workScheduleTagApiBasePath = `${RuntimeConstants.API_PATH}workScheduleTags`;

    newWorkScheduleTag() {
        return {
            Id: 0,
            Name: '',
            Color: ''
        };
    }
    getWorkScheduleTags(): Observable<any> {
        return this.httpService.get(`${this.workScheduleTagApiBasePath}`);
    }
}
