<pos-edit-wrapper heading="{{id > 0 ? 'Edit Group' : 'Add Group'}}" (close)="cancel()">
    <form #multiProductGroupForm="ngForm" (ngSubmit)="saveMultiProductGroup(multiProductGroupForm.valid)"
        autocomplete="off">
        <div class="col-12 pos-modal-body form-horizontal screen-scroll">
            <pos-row colXs="12" styleClass="p-0">
                <pos-column>
                    <pos-form-field label="Name" [validationMessages]="{required: 'Please enter group name.'}">
                        <input posInput name="groupName" #groupName="ngModel" type="text" class="form-control"
                            [(ngModel)]="name" [required]="true" />
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button class="btn-primary btn-lg btn btn-save pos-button" type="submit" value="Save">Save</button>
            <button class="btn-secondary btn-lg btn btn-cancel-modal pos-button" (click)="cancel()" type="button"
                value="Cancel">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
