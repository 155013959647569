<pos-edit-wrapper [isPopup]="!fromListPage" heading="{{id > 0 ? 'Edit Sales Group': 'Add Sales Group'}}"
    (close)="cancelEditing()" [headingIcon]="icons.salesGroupCommentsDollar">
    <form #salesGroupForm="ngForm" class="edit-form" (ngSubmit)="submit(salesGroupForm.valid)" autocomplete="off">
        <context-provider provide="props">
            <div class="col-12"
                [ngClass]="{'border-top pos-page-body': fromListPage, 'pos-modal-body screen-scroll': !fromListPage}">
                <pos-row>
                    <pos-column sm="6" xs="12">
                        <h3 class="setting-title mt-pix-5">
                            <span><i [ngClass]="icons.salesGroupThLarge.iconClasses" class="pe-pix-5"></i>Sales
                                Group</span>
                        </h3>
                        <pos-row class="pos-title-content">
                            <pos-column>
                                <pos-form-field label="Group Name"
                                    [validationMessages]="{required: 'Please enter group name.'}">
                                    <input posInput type="text" name="salesGroupName" #salesGroupName="ngModel"
                                        [required]="true" class="form-control" [(ngModel)]="salesGroup.name" />
                                </pos-form-field>
                            </pos-column>
                            <pos-column>
                                <pos-form-field label="Make Table Color">
                                    <pos-color-picker posInput [(ngModel)]="salesGroup.make_table_color"
                                        name="makeTableColor">
                                    </pos-color-picker>
                                </pos-form-field>
                            </pos-column>
                            <pos-column>
                                <pos-form-field label="Kitchen Printer" [fieldInfo]="kitchenPrinterInfo"
                                    inputStyle="col-12">
                                    <ng-select posInput [items]="kitchenPrinters" [multiple]="true" appendTo="body"
                                        [hideSelected]="true" bindLabel="Name" placeholder="Select kitchen printer(s)"
                                        [(ngModel)]="selectedSalesGroupKitchenPrinters"
                                        #selectedSalesGroupKitchenPrinter="ngModel"
                                        name="selectedSalesGroupKitchenPrinter">
                                    </ng-select>
                                </pos-form-field>
                            </pos-column>
                        </pos-row>
                    </pos-column>
                    <pos-column sm="6" xs="12">
                        <h3 class="setting-title mt-pix-5">
                            <span><i [ngClass]="icons.salesGroupClock.iconClasses" class="pe-pix-5"></i>Timer
                                Setting
                            </span>
                            <span class="pull-right" title="{{infoMakeTableCookingState}}" tooltip
                                *ngIf="!isMakeTableCookingState"><i [ngClass]="icons.infoCircle.iconClasses"
                                    aria-hidden="true"></i></span>
                        </h3>
                        <pos-row class="pos-title-content">
                            <pos-column>
                                <pos-form-field label="Prep Group">
                                    <input posInput type="text" class="form-control"
                                        [(ngModel)]="salesGroup.PrepGroupName" name="prepGroupName" />
                                </pos-form-field>
                            </pos-column>
                            <pos-column>
                                <pos-form-field>
                                    <p-checkbox posInput name="isTimedPrep" [(ngModel)]="salesGroup.IsTimedPrep"
                                        binary="true" [disabled]="!isMakeTableCookingState" label="Timed Prep">
                                    </p-checkbox>
                                </pos-form-field>
                            </pos-column>
                            <pos-column>
                                <pos-form-field>
                                    <p-checkbox posInput name="isUseWorkflowTimer"
                                        [(ngModel)]="salesGroup.IsUseWorkflowTimer" binary="true"
                                        [disabled]="!salesGroup.IsTimedPrep || !isMakeTableCookingState"
                                        label="Use Workflow Timer">
                                    </p-checkbox>
                                </pos-form-field>
                            </pos-column>
                            <pos-column>
                                <pos-form-field label="Prep Time"
                                    [validationMessages]="{min: 'Prep time must be greater than zero.'}">
                                    <pos-row>
                                        <pos-column xs="6" class="col-120">
                                            <input posInput type="number" id="prepTime" name="prepTime" min="1"
                                                class="form-control col-120"
                                                [disabled]="!salesGroup.IsTimedPrep || !isMakeTableCookingState"
                                                [(ngModel)]="salesGroup.PrepTime"
                                                (ngModelChange)="secondsConvertToTime(salesGroup.PrepTime)"
                                                #prepTime="ngModel" />
                                        </pos-column>
                                        <pos-column xs="6">
                                            <label class="edit-form__section__label ps-pix-10">{{prepSecondsConvertToTime |
                                                date:'HH:mm:ss'}}</label>
                                        </pos-column>
                                    </pos-row>
                                </pos-form-field>
                            </pos-column>
                            <pos-column>
                                <pos-form-field label="Prep Button Color">
                                    <pos-color-picker posInput [(ngModel)]="salesGroup.PrepButtonColor"
                                        [disabled]="!salesGroup.IsTimedPrep || !isMakeTableCookingState"
                                        name="prepButtonColor">
                                    </pos-color-picker>
                                </pos-form-field>
                            </pos-column>
                        </pos-row>
                    </pos-column>
                </pos-row>
            </div>
            <div class="col-12 action-button-container"
                [ngClass]="fromListPage ? 'page-footer' : 'popup-footer'">
                <button type="submit" value="Save" class="btn-primary btn-lg white btn btn-save pos-button">Save</button>
                <button type="button" class="btn-secondary  btn-lg btn btn-cancel pos-button"
                    [ngClass]="{'btn-cancel-modal': !fromListPage}" (click)="cancelEditing()" value="Cancel">Cancel
                </button>
            </div>
        </context-provider>
    </form>
</pos-edit-wrapper>
