import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { EventEmitter, Injectable, TemplateRef } from '@angular/core';
import { ICloseable } from './ICloseable';
declare let $: any
export class ModalComponent {
  public close: EventEmitter<any> = new EventEmitter();


  protected initialValues;
  constructor(public modalRef: BsModalRef) {

  }

  /**
   * hide
   */
  public hide(reason: any) {
    this.close.emit(reason);
  }
}

export class ModalWrapperComponent<TContentComponent> {
  bsModalRef: BsModalRef;
  constructor(private component: TContentComponent, private modalService: BsModalService, private backDropService: ModalBackdropService) {
  }

  public show(config?: ModalOptions) {
    config.keyboard = false;
    this.bsModalRef = this.modalService.show(this.component as TemplateRef<any>, config);
    this.backDropService.addBackDrop(this.modalService.getModalsCount());
    (this.bsModalRef.content as ICloseable)?.close?.subscribe((x) => {
      const hideTimeout = setTimeout(() => {
        this.bsModalRef.hide();
        this.backDropService.removeBackdrop();
        clearInterval(hideTimeout);
      });
    });
    return (this.bsModalRef.content as ICloseable);
  }

  hide(reason?: any) {
    if(this.bsModalRef.content) {
      (this.bsModalRef.content as ICloseable).close.emit(reason);
    } else {
      this.bsModalRef.hide();
    }
  }
}


@Injectable()
export class ModalBackdropService {
  public _modalRequestCount = 1049;

  addBackDrop = (modalCount = 1) => {
    this._modalRequestCount = 1049 + modalCount;
    this.changeBackDropZIndex();
  }

  removeBackdrop = () => {
    this._modalRequestCount--;
    this._modalRequestCount = this._modalRequestCount < 0 ? 0 : this._modalRequestCount;
    this.changeBackDropZIndex();
    if(this._modalRequestCount == 1049 && (!$('.modal:visible').length || $('.modal:visible').length == 1)) {
      $('.modal-backdrop').remove();
    }
  }

  private changeBackDropZIndex = () => {
    if ($('.modal:visible').length) {
      setTimeout(() => {
        $('.modal-backdrop').last().css('z-index', this._modalRequestCount);
        $('.modal').last().css('z-index', this._modalRequestCount);
      });
    }
  }
}
