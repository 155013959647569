import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { FileWithTimeStamp } from 'src/app/shared/interface/file-with-time-stamp';
import { BaseWidgetSearchService } from './base-widget-search.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { WidgetDetails } from '../interfaces';


@Injectable({
  providedIn: 'root'
})
export class BaseWidgetCrudService extends BaseWidgetSearchService {

  menuItemChangeSubject: Subject<any> = new Subject<any>();
    get menuItemChange$(): Observable<any> {
        return this.menuItemChangeSubject.asObservable();
    }

  showGroupWidgetsSubject: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  get showGroupWidgetsChange$(): Observable<any> {
    return this.showGroupWidgetsSubject.asObservable();
  }
  
  public widgetDataSubject: BehaviorSubject<Array<WidgetDetails>> = new BehaviorSubject([]);

    
  constructor(httpService: HttpClient) {
    super(httpService);
  }

  insert(model: any): Observable<any> {
    return this.httpService.post(this.apiEndpoint, model);
  }

  insertAndGet(model: any): Observable<any> {
    return this.httpService.post<any>(this.apiEndpoint, model);
  }

  update(model: any): Observable<any> {
    return this.httpService.put(`${this.apiEndpoint}/${model.Id}`, model);
  }

  insertOrUpdate(model: any, files: FileWithTimeStamp[], isMultipartRequest: boolean = false): Observable<any> {
    if (!files && isMultipartRequest) {
      if (model.Id) {
        return this.update(model);
      } else {
        return this.insert(model);
      }
    } else {
      return this.saveWithFile(model, files);
    }
  }

  saveWithFile(model: any, files: FileWithTimeStamp[]): Observable<any> {
    const formData = this.prepareIconFormData(model, files);
    return this.httpService.post<any>(`${this.apiEndpoint}/file`, formData);
  }

  delete(id: number): Observable<any> {
    return this.httpService.delete(`${this.apiEndpoint}/${id}`);
  }

  private prepareIconFormData(param: any, files: FileWithTimeStamp[]): FormData {
    const formData = new FormData();
    if (files) {
      files.forEach((file) => {
        formData.append('files', file.file, file.fileNameWithTimeStamp);
      })
    }

    formData.append('model', JSON.stringify(param));
    return formData;
  }
}
