import { Component, OnInit, ViewChild } from '@angular/core';
import { BrandingService } from '../../services';
import {
  ApplicationStateService, SpinnerService, AlertsService, SettingParam, FieldInfoMessages,
  RuntimeConstants, Messages, BaseFormComponent, FormUtilityService
} from 'src/app/shared';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { branding } from 'src/app/shared/components/icon/icons';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'pos-branding',
  templateUrl: './branding.component.html',
})
export class BrandingComponent extends BaseFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.form
  }
  @ViewChild('brandingForm') form: NgForm;
  parameters: SettingParam;
  fieldInfoMessages = FieldInfoMessages;
  imageBasePath = RuntimeConstants.IMAGE_BASE_PATH;
  updatedLogoFileName = '';
  updatedFileName = '';
  logo = null;
  selectedFiles: File;
  logoPath = '';
  receiptLogoPath = '';
  icons = {
    branding
  };

  constructor(private brandingService: BrandingService,
    private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private applicationStateService: ApplicationStateService,
    private router: Router,
    formUtilityService: FormUtilityService) {
    super(formUtilityService);
    this.parameters = applicationStateService.newSettingParams();
  }

  ngOnInit() {
    this.load();
  }

  load() {
    this.spinnerService.show();
    this.brandingService.getBrandingDetail(this.applicationStateService.terminalId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          this.parameters = res;
          this.parameters.Logo = 'logo.png';
          this.logoPath = `${this.imageBasePath}/logo/${this.parameters.Logo}`;
          this.receiptLogoPath = `${this.imageBasePath}/receipt/${this.parameters.ReceiptLogoPath}`;
          this.onFormChange(this.form);
        }, error: this.alertsService.showApiError
      });
  }

  saveBranding(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.parameters.ReceiptLogoPath = (this.updatedFileName && this.updatedFileName !== '') ? this.updatedFileName :
      this.parameters.ReceiptLogoPath;
    this.spinnerService.show();
    this.brandingService.saveBranding(this.parameters, this.selectedFiles)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          this.saveLogoFile();
        }, error: this.alertsService.showApiError
      });
  }

  saveLogoFile() {
    if (this.logo !== null) {
      if (this.updatedLogoFileName.substring(this.updatedLogoFileName.indexOf('.'), this.updatedLogoFileName.length) === '.png') {
        this.brandingService.uploadLogo(this.logo)
          .pipe(finalize(() => {
            this.spinnerService.hide();
          }))
          .subscribe({
            next: (res) => {
              if (this.updatedLogoFileName && this.updatedLogoFileName !== '') {
                this.parameters.Logo = this.updatedLogoFileName;
              }
              this.alertsService.renderSuccessMessage(Messages.BrandingSaveSuccess);
              this.router.navigate(['/manage/system-configuration']);
            }, error: this.alertsService.showApiError
          });
      } else {
        this.alertsService.renderErrorMessage(Messages.InvalidLogoFileType);
      }
    } else {
      this.alertsService.renderSuccessMessage(Messages.BrandingSaveSuccess);
      this.router.navigate(['/manage/system-configuration']);
    }
  }

  uploadLogoFile(files) {
    if (files && files[0]) {
      this.logo = files.item(0);
      this.updatedLogoFileName = files.item(0).name;
      if (this.updatedLogoFileName.substring(this.updatedLogoFileName.indexOf('.'), this.updatedLogoFileName.length) !== '.png') {
        this.updatedLogoFileName = 'logo.png';
        this.alertsService.renderErrorMessage(Messages.InvalidLogoFileType);
        return;
      }
    }
    // Read file from input
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.logoPath = reader.result.toString();
      };
      reader.readAsDataURL(files[0]);
    }
  }

  // Choose file ReceiptLogo
  uploadFile(files) {
    if (files && files[0]) {
      this.selectedFiles = files.item(0);
      this.updatedFileName = files.item(0).name;
      const extension = this.updatedFileName.substring(this.updatedFileName.indexOf('.'), this.updatedFileName.length);
      if (extension !== '.jpg' && extension !== '.bmp' && extension !== '.png') {
        this.updatedFileName = this.parameters.ReceiptLogoPath;
        this.alertsService.renderErrorMessage(Messages.InvalidReceiptLogoFileType);
        return;
      }
    }
    // Read file from input
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.receiptLogoPath = reader.result.toString();
      };
      reader.readAsDataURL(files[0]);
    }
  }

  onFormChange($event: NgForm) {
    this.form = $event;
    this.setInitialValue();
  }

  cancelEditing() {
    this.router.navigate(['manage/system-configuration']);
  }
}
