import { Injectable, TemplateRef } from "@angular/core";
import { DateColumn, GridColumn, TemplateColumn, TextAlign } from "@tarktech/tark-ng-utils";
import { AudioRecording } from "src/app/manage-console/audio-recordings";
import { AudioRecorderService } from "../auth";
import { SpinnerService } from "../components/spinner/spinner.service";
import { DomainConstants, Messages } from "../constants";
import { AlertsService } from "./alerts.service";
import { ApplicationStateService } from "./application-state.service";

@Injectable({
  providedIn: 'root'
})
export class AudioOperationsService {
  loaderTimeout: ReturnType<typeof setTimeout>;
  constructor(
    public applicationStateService: ApplicationStateService,
    private audioRecorderService: AudioRecorderService,
    protected spinnerService: SpinnerService,
    protected alertsService: AlertsService
  ) {
  }

  tagAudioRecord(audioInteractionMode: string, tagName: string) {
    if (!this.applicationStateService.settingParam.EnableAudioRecording) {
      return;
    }

    const terminalProperties = this.applicationStateService.terminalProperties;
    const terminalInteractionMode = terminalProperties.find(p => p.PropertyKey == DomainConstants.TerminalProperties.AUDIO_INTERACTION_MODE.Key)
    const interactionModes = terminalInteractionMode?.PropertyValue ? terminalInteractionMode.PropertyValue.split(',') : [];
    if (interactionModes.includes(audioInteractionMode)) {
      this.audioRecorderService.tagAudioRecord(tagName);
    }
  }

  untagAudioRecord() {
    if (this.applicationStateService.settingParam.EnableAudioRecording) {
      this.audioRecorderService.untagAudioRecord();
    }
  }

  getAudioRecordingsByHardware = (hardwareId: number, callBackOnClear) => {
    this.showSpinnerForRestResponse(callBackOnClear);
    this.audioRecorderService.getAllRecordingsByHardware(hardwareId)
      .subscribe({
        next: () => { },
        error: this.alertsService.showApiError
      });
  }

  getAudioRecordings = (callBackOnClear) => {
    this.showSpinnerForRestResponse(callBackOnClear);
    this.audioRecorderService.getAllRecordings()
      .subscribe({
        next: () => { },
        error: this.alertsService.showApiError
      });
  }

  getAudioRecordingsForOrder = (orderId: number, rabbitMQRequestId: string) => {
    return this.audioRecorderService.getRecordingsForOrder(orderId, rabbitMQRequestId);
  }

  showSpinnerForRestResponse = (callBackOnClear) => {
    clearTimeout(this.loaderTimeout);
    this.spinnerService.show();
    this.loaderTimeout = setTimeout(() => {
      this.spinnerService.hide();
      this.alertsService.renderErrorMessage(Messages.GetAudioFilesResponseError);
      clearTimeout(this.loaderTimeout);
      callBackOnClear();
    }, 10000);
  }

  getAudioBytes = (data: AudioRecording, rabbitMQRequestId: string, callBackOnClear) => {
    this.showSpinnerForRestResponse(callBackOnClear);
    this.audioRecorderService.getRecording(data, rabbitMQRequestId)
      .subscribe({ next: () => { }, error: this.alertsService.showApiError });
  }

  prepareAudioRecordingColumns(audioRecordingsOperationTemplate: TemplateRef<any>, isAddCreatedDate = false) {
    const operationColumn = new TemplateColumn({
      itemTemplate: audioRecordingsOperationTemplate,
      Width: '85px',
      TextAlign: TextAlign.Center,
      CellClass: 'cell-padding text-center'
    });

    const columns: Array<GridColumn> = [new GridColumn({ HeaderText: 'File', Field: 'Name', IsSortable: true, Width: isAddCreatedDate ? '55%' : '93%' })];
    if (isAddCreatedDate) {
      columns.push(new DateColumn({ HeaderText: 'Created Date', Field: 'CreatedDate', IsSortable: true, Width: '40%' }));
    }
    columns.push(operationColumn);
    return columns;
  }

}
