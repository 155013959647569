import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseFormComponent, SpinnerService, paymentsMoneyBillAlt, times } from 'src/app/shared/components';
import { DomainConstants, Messages, RuntimeConstants } from 'src/app/shared/constants';
import { SettingParam } from 'src/app/shared/interface';
import { AlertsService, ApplicationStateService, ExtensionValidationService, FormUtilityService } from 'src/app/shared/services';
import { finalize } from 'rxjs/internal/operators/finalize';
import moment from 'moment';
import { KioskScreenImage } from 'src/app/order-kiosk/interfaces';
import { filter } from 'lodash';
import { PaymentConfigService } from '../../../services/payment-config.service';
import { KioskPaymentStyle } from '../../../interfaces/styling/kiosk-payment-style';
declare let $: any;

@Component({
  selector: 'pos-payment-config',
  templateUrl: './payment-config.component.html',
  styleUrls: ['./payment-config.component.scss']
})
export class PaymentConfigComponent extends BaseFormComponent implements OnInit {


  get getForm(): NgForm {
    return this.form
  }
  @ViewChild('configForm') form: NgForm;

  parameters: SettingParam;
  kioskPaymentStyle: KioskPaymentStyle;
  imageBasePath = RuntimeConstants.IMAGE_BASE_PATH;
  updatedLogoFileName = '';
  updatedFileName = '';
  paymentConfigImages: Array<KioskScreenImage> = [];
  selectedFiles: File;
  showIcon = false;
  receiptLogoPath = '';
  verticalAlignments = DomainConstants.VerticalAlignments;
  icons = {
    paymentsMoneyBillAlt,
    times
  };


  constructor(
    private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private applicationStateService: ApplicationStateService,
    private router: Router,
    formUtilityService: FormUtilityService,
    private extensionValidation: ExtensionValidationService,
    private paymentConfigService: PaymentConfigService) {
    super(formUtilityService);
    this.parameters = applicationStateService.newSettingParams();
    this.kioskPaymentStyle = {
      OrderDetailFontColor: null,
      OrderDetailBackgroundColor: null,
      OrderDetailVerticalAlignment: null,
      OrderDetailVerticalPosition: null
    }
  }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.spinnerService.show();
    this.paymentConfigService.getPaymentConfig()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          this.parameters = res;      
          this.kioskPaymentStyle = this.parameters.OrderKioskPaymentStyle ? JSON.parse(this.parameters.OrderKioskPaymentStyle) : this.kioskPaymentStyle;
        }, error: this.alertsService.showApiError
      });
  }

  saveConfig(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.spinnerService.show();
    this.parameters.OrderKioskPaymentStyle = JSON.stringify(this.kioskPaymentStyle);
    this.paymentConfigService.save(this.parameters, this.paymentConfigImages)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          this.alertsService.renderSuccessMessage(Messages.KioskPaymentConfigSave);
        }, error: this.alertsService.showApiError
      });
  }

  handleFileInput(files: FileList, previewName: string, inputName: string) {
    const image = files[0];
    const timestamp = moment(Date.now()).format('yyyyMMDDHHmmssSSSS');
    const imageName = `${timestamp}-${image.name}`;

    const fileToUpload = image ? imageName : '';
    if (this.extensionValidation.isInvalidExtension(fileToUpload)) {
      if (fileToUpload) {
        this.alertsService.renderErrorMessage(Messages.InvalidReceiptLogoFileType);
      }
      this.removeIcon(previewName, inputName);
      return '';
    }
    if (files?.[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        $('#' + previewName).attr('src', reader.result);
      };
      reader.readAsDataURL(files[0]);
      this.paymentConfigImages.push({
        ImageName: imageName,
        ImageFile: image
      })
      return image ? imageName : '';
    }
  }

  public removeIcon(previewName, inputName, modal = null) {
    if (modal) {
      this.paymentConfigImages = filter(this.paymentConfigImages, (config: KioskScreenImage) => {
        return config.ImageName != modal;
      });
    }
    $('#' + previewName).val('');
    $('#' + inputName).val(null);
    return '';
  }

  cancelEditing() {
    this.router.navigate(['manage/order-kiosk']);
  }
}
