import { Component, OnInit, EventEmitter } from "@angular/core";
import { Messages } from "src/app/shared/constants";
import { NumpadOptions } from "src/app/shared/interface";
import { ApplicationStateService } from "src/app/shared/services";
import { InfoModalComponent } from "../../info-modal/info-modal.component";
import { ICloseable, ModalService } from "../../modal";

@Component({
  selector: 'pos-gift-card-numpad-wrapper-component',
  templateUrl: './gift-card-numpad-wrapper-component.component.html',
  styleUrls: ['./gift-card-numpad-wrapper-component.component.scss']
})
export class GiftCardNumpadWrapperComponentComponent implements OnInit, ICloseable {

  numpadOption: NumpadOptions;
  close: EventEmitter<any> = new EventEmitter();
  value: any;
  numpadId: string;
  numpadTitle: string;
  constructor(private applicationStateService: ApplicationStateService,
    private modalService: ModalService) { }
  ngOnInit() {

  }

  onSubmit = (res) => {
    if (res.value === this.applicationStateService.settingParam.RFIDErrorCode) {
      this.value = 0;
      const modalRef = this.modalService.show(InfoModalComponent, {
        animated: false,
        keyboard: false,
        class: 'vertical-center',
        initialState: {
          message: Messages.CardbasedLoginBadgeNumberErrorCode
        }
      });
      return;
    }
    this.close.emit(res);
  }

  onCancel = (res) => {
    this.close.emit({});
  }
}
