import { Injectable } from '@angular/core';
import { CronOptions } from '@tarktech/ngx-cron-editor';
import { find } from 'lodash';
import { DomainConstants } from '../constants/domain-constants';
import { ApplicationStateService } from './application-state.service';

@Injectable({
  providedIn: 'root'
})
export class CronEditorService {

  constructor(private appState: ApplicationStateService) {}
  newCronSettings(standardCron = true) {
    const cronSettings: CronOptions = {
      hideMinutesTab: false,
      hideHourlyTab: false,
      hideDailyTab: false,
      hideWeeklyTab: false,
      hideMonthlyTab: false,
      hideYearlyTab: false,
      hideAdvancedTab: false,
      use24HourTime: true,
      hideSeconds: true,
      hideSpecificWeekDayTab: false,
      hideSpecificMonthWeekTab: false,
      cronFlavor: standardCron ? 'standard' : 'quartz',
      weekStart: this.getWeekStart()
    };
    return cronSettings;
  }

  getWeekStart() {
    return parseInt(find(DomainConstants.WeekDay, (dayId, day) => {
      return day === this.appState.settingParam.WeekStart;
    }), 10);
  }

  static defaultQuartz = "0 0/1 * * * ? *";
  static defaultStandard = "0/1 * * * *";

  public static cronIsValid(cronFlavor: string, cron: string): boolean {
    if (cron) {
      const cronParts = cron.split(' ');
      return (
        (cronFlavor == 'quartz' && (cronParts.length === 6 || cronParts.length === 7)) ||
        (cronFlavor == 'standard' && cronParts.length === 5)
      );
    }
    return false;
  }
}
