<div class="m-0-auto">
    <div class="page-content">
        <div class="tab-content">
            <div class="tab-pane fade in active show px-3">
                <div class="col-12">
                    <a class="close" (click)="onCancel()">×</a>
                    <h3 class="page-content__header">
                        Screen History: {{screenName}}
                    </h3>
                </div>
                <div class="col-12 pb-pix-5">
                    <div class="table-responsive">
                        <ng-table [columns]="screenHistoryColumns" [exportFilename]="'screen-history'"
                            [data]="screenHistory"  (lazyLoad)="lazyLoad($event)" [lazy]="true" [totalRecords]="totalRecords">
                        </ng-table>

                        <ng-template let-data="rowData" #printReceiptIconTemplate>
                            <span *ngIf="data.PrintOnOrderReceipt" class="green text-center vertical-mid font-13">
                                <pos-icon [icon]="icons.check"></pos-icon>
                            </span>
                        </ng-template>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
