<pos-list-wrapper [headingIcon]="icons.editSolid" heading="Reports" (close)="close()">
  <div class="row">
    <div class="col-md-12 col-sm-12">
      <ng-table [search]="true" [sort]="false" #report [paginator]="false" [columns]="reportConfigurationColumns"
        [data]="reportConfiguration" [isReorderableTable]="true" [ReorderableColumnRow]="'row'"
        [exportFilename]="'reports'" (onRowReorder)="gridRowReorder($event)">
      </ng-table>
      <ng-template let-data="rowData" #IconTemplate>
        <fa-icon class="{{data.ReportIcon}} black"></fa-icon>
      </ng-template>
    </div>
  </div>
</pos-list-wrapper>
<pos-print-table [hidden]="true"></pos-print-table>
