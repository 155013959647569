import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'pos-icon-input',
  templateUrl: './icon-input.component.html',
  styleUrls: ['./icon-input.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class IconInputComponent implements OnInit {

  @Input() value: string;
  @Output() valueChange = new EventEmitter<string>();
  @Input('placeholder') placeholder: string;
  @Input() submitted: string;
  @Input() validationMessage: string;
  @Input() required: boolean;
  constructor() { }

  ngOnInit(): void {
  }

  onInputData() {
    this.valueChange.emit(this.value);
  }

}
