<pos-row class="mx-0">
    <pos-column class="p-0">
        <pos-form-field>
            <p-checkbox posInput name="isSkipReadyState" [(ngModel)]="isRepeat" binary="true"
                (change)="resetRepeatConfig()" label="Repeat">
            </p-checkbox>
        </pos-form-field>
    </pos-column>

    <pos-column sm="9" xs="12" class="p-0 pt-pix-10">
        <div class="col-12 p-0">
            <label class="required">Every</label>
        </div>
        <pos-column class="row mx-0">
            <pos-form-field class="col-sm-3 col-4 col-120 p-0" inputStyle="col-120 col-12"
                [validationMessages]="{required: 'Please enter time.', min: 'Please enter time.'}"
                [appendPaddingTop]="false">
                <input posInput posOnlyNumbers min="1" class="form-control" id="repeatPeriodTime"
                    name="repeatPeriodTime" [(ngModel)]="repeatConfig.Period.Time" [disabled]="!isRepeat"
                    [required]="true" type="number" #periodTime="ngModel" />
            </pos-form-field>
            <div class="col-md-5 col-6 ps-0">
                <ng-select [items]="durationUnits" appendTo="body" placeholder="Select Unit"
                    [(ngModel)]="repeatConfig.Period.Unit" name="unit" required [clearable]="false"
                    [disabled]="!isRepeat">
                </ng-select>
            </div>
        </pos-column>
    </pos-column>

    <pos-column sm="9" xs="12" class="p-0 pt-pix-10">
        <div class="col-12 p-0">
            <label class="required">Until</label>
        </div>
        <div class="row mx-0">
            <div class="col-1 col-w-10 p-0 mt-pix-7">
                <input posInput type="radio" name="chkTimes" [value]="untilDurationType.Times"
                    [(ngModel)]="repeatConfig.Until.Type" [required]="true" [disabled]="!isRepeat"
                    (change)="untilXTime.Until.Type = repeatConfig.Until.Type; repeatConfig.Until.Unit = null" />
            </div>
            <pos-form-field class="col-4 col-120" inputStyle="col-12"
                [validationMessages]="{required: 'Please enter until time.', min: 'Please enter until time.'}"
                [appendPaddingTop]="false">
                <input posInput posOnlyNumbers min="1" class="form-control" id="untilTimes" name="untilTimes"
                    (change)="repeatConfig.Until.Time = untilXTime.Until.Time" [(ngModel)]="untilXTime.Until.Time"
                    [disabled]="repeatConfig.Until.Type != untilDurationType.Times || !isRepeat" [required]="true"
                    type="number" #untilTimes="ngModel" />
            </pos-form-field>
            <div class="col-lg-5 col-md-5 col-sm-6 col-4 p-0 ps-0 mt-pix-7">
                Times
            </div>
        </div>
        <div class="row mx-0 mt-pix-10">
            <div class="col-1 col-w-10 p-0 mt-pix-7">
                <input type="radio" name="chkDuration" [value]="untilDurationType.Duration"
                    [(ngModel)]="repeatConfig.Until.Type" required [disabled]="!isRepeat"
                    (change)="untilXDuration.Until.Type = repeatConfig.Until.Type" />
            </div>
            <pos-form-field class="col-4 col-120" inputStyle="col-120 col-12"
                [validationMessages]="{required: 'Please enter until time.', min: 'Please enter until time'}"
                [appendPaddingTop]="false">
                <input posInput posOnlyNumbers min="1" class="form-control" id="untilDuration" name="untilDuration"
                    (change)="repeatConfig.Until.Time = untilXDuration.Until.Time"
                    [(ngModel)]="untilXDuration.Until.Time"
                    [disabled]="repeatConfig.Until.Type != untilDurationType.Duration || !isRepeat" [required]="true"
                    type="number" #untilDuration="ngModel" />
            </pos-form-field>
            <div class="col-lg-5 col-md-5 col-sm-6 col-6 ps-0">
                <ng-select [items]="durationUnits" appendTo="body" placeholder="Select Unit"
                    (change)="repeatConfig.Until.Unit = untilXDuration.Until.Unit"
                    [(ngModel)]="untilXDuration.Until.Unit" name="untilUnit" [required]="true" [clearable]="false"
                    #untilUnits="ngModel"
                    [disabled]="repeatConfig.Until.Type != untilDurationType.Duration || !isRepeat" required="">
                </ng-select>
            </div>
        </div>
        <div class="row mx-0 mt-pix-10 ps-0" *ngIf="isRecoverableEvent">
            <div class="col-lg-1 col-md-1 col-sm-1 col-1 col-w-10 ps-0">
                <input type="radio" name="chkDuration" [value]="untilDurationType.Recovery"
                    [(ngModel)]="repeatConfig.Until.Type" required [disabled]="!isRepeat"
                    (change)="clearUntilConfig()" />
            </div>
            <div class="col-md-5 col-6">
                Recovery
            </div>
        </div>
    </pos-column>
</pos-row>
