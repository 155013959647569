<div class="m-0-auto px-pix-10">
  <pos-list-wrapper [headingIcon]="icons.displayMenu" heading="Menu Display" (close)="cancel()">
    <div class="col-12 pb-pix-5">
      <div class="table-responsive">
        <ng-table [columns]="digitalMenuColumns" [data]="digitalMenu" #menuList>
        </ng-table>
      </div>
    </div>

    <ng-template let-data="rowData" #resolutionTemplate>
      {{data.Width}} × {{data.Height}}
    </ng-template>

    <ng-template let-data="rowData" #orientationTemplate>
      <div class="position-relative">
        {{data.Orientation ? 'Portrait':'Landscape'}} <pos-icon
        [icon]="data.Orientation ? icons.rectanglePortrait : icons.rectangleLandscape"
        class="font-17 ps-pix-5 absolute orientation"></pos-icon>
      </div>
    </ng-template>

    <ng-template let-data="rowData" #operationHeaderTemplate>
      <span>
        <span (click)="editItem(0)" tooltip title="Add">
          <pos-action-icon [icon]="icons.plusWhite" iconText="Add"></pos-action-icon>
        </span>
      </span>
      <span (click)="printData()" tooltip title="Print">
        <pos-action-icon [icon]="icons.printWhite" iconText="Print" class="ms-pix-5"></pos-action-icon>
      </span>
    </ng-template>

    <ng-template let-data="rowData" #operationTemplate>
      <div>
        <span class="pe-pix-3" (click)="editItem(data.Id)" tooltip title="Edit">
          <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
        </span>
        <span class="pe-pix-3" (click)="confirmDelete(data)" tooltip title="Delete">
          <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
        </span>
        <span class="pointer pe-pix-3" (click)="preview(data)" tooltip title="Preview">
          <pos-icon [icons]="[icons.squareLightOrangeStack, icons.fileSearch]"></pos-icon>
        </span>
        <span class="pe-pix-3" (click)="refreshMenuDisplay(data)" tooltip title="Trigger Refresh">
          <pos-action-icon [icon]="icons.redoWhite"></pos-action-icon>
        </span>
        <span tooltip>
          <span class="text-center mt-pix-n4" style="overflow-wrap: break-word;">
            <a class="action-btn grey pointer" #pop="bs-popover" placement="left" (click)="onLinkPopOver(data,pop)"
              [outsideClick]="true" [popover]="configurationTemplate" triggers="click">
              <pos-action-icon [icon]="icons.barsWhite"></pos-action-icon>
            </a>
          </span>
        </span>
      </div>
    </ng-template>
  </pos-list-wrapper>
</div>
<pos-print-table [hidden]="true"></pos-print-table>


<ng-template #configurationTemplate>
  <div class="">
    <ul>
      <li class="listbullet">
        <a class="grey pointer" (click)="configWidget(selectedMenu)">
          <pos-icon [icon]="icons.cog" class="pe-pix-3"></pos-icon>
          Menu Configuration
        </a>
      </li>
      <li class="listbullet pointer">
        <a class="grey" (click)="designMenu(selectedMenu)">
          <pos-icon [icon]="icons.objectGroup" class="pe-pix-8"></pos-icon>Menu Design
        </a>
      </li>
      <li class="listbullet pointer">
        <a class="grey" (click)="menuBackup(selectedMenu)">
          <pos-icon [icon]="icons.save" class="pe-pix-8"></pos-icon>Backups
        </a>
      </li>
    </ul>
  </div>
</ng-template>
