import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogInventoryOnExhaustionComponent } from '../log-inventory-on-exhaustion';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ComponentsModule, TableConfiguration } from 'src/app/shared';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { InventoryLogStockSalesProductWarningComponent } from '../inventory-log-stock-sales-product-warning';
import { TableModule } from '@tarktech/tark-ng-utils';
import { CheckboxModule } from 'primeng/checkbox';
import { InventoryProductLogComponent } from '../inventory-log/inventory-product-log.component';

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    ModalModule.forRoot(),
    ComponentsModule,
    TooltipModule.forRoot(),
    CheckboxModule,
    TableModule.forRoot(TableConfiguration)
  ],
  declarations: [LogInventoryOnExhaustionComponent, InventoryProductLogComponent, InventoryLogStockSalesProductWarningComponent],
  exports: [LogInventoryOnExhaustionComponent, InventoryProductLogComponent, InventoryLogStockSalesProductWarningComponent]
})
export class InventoryExhaustionModule { }
