import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ReportModel } from '../../interface/report-model';
import { ReportGroupService, ReportCategoryService } from '../../service';
import { deleteWhite, listWhite, databaseWhite, database, list, cogs, taskToDo, editSolid, layerGroup } from 'src/app/shared/components/icon';
import { ReportConfigurationService } from '../../service/report-configuration.service';
import { DataSource, DataSourceParameter, ManualValuesModel } from 'src/app/manage-console/automated-exports/interface';
import { AlertsService, FormUtilityService } from 'src/app/shared/services';
import { BaseCrudComponent, ConfirmDeleteModalComponent, ModalService, SpinnerService } from 'src/app/shared/components';
import { ReportGroup } from '../../interface/report-group';
import { ReportCategory } from '../../interface/report-category';
import { DataSourceService } from 'src/app/manage-console/automated-exports/service/data-source.service';
import { DataSourceParameterService } from 'src/app/manage-console/automated-exports/service';
import { filter, forEach, orderBy, remove } from 'lodash';
import { Messages } from 'src/app/shared/constants';
import { GridColumn, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { Rule } from '../../interface/report-rules';
import { DomainConstants } from 'src/app/shared';
import { ReportParameterRuleService } from '../../service/report-parameter-rule';
import { NgForm } from '@angular/forms';
import { ReportColumnSettingsService } from '../../service/report-column-settings.service';
import { ReportSetting } from '../../interface/report-settings';
import { ReportSettingModel } from '../../interface/report-settings-model';
import { PostSavedConfigurationService } from 'src/app/configurator/post-saved-configuration.service';

@Component({
  selector: 'pos-report-edit',
  templateUrl: './report-edit.component.html',
  styleUrls: ['./report-edit.component.scss']
})
export class ReportEditComponent extends BaseCrudComponent<ReportModel> implements OnInit {
  get getForm(): NgForm {
    return this.reportForm
  }
  id = 0;
  reportGroups: Array<ReportGroup> = [];
  reportGroupsFiltered: Array<ReportGroup> = [];
  reportCategories: Array<ReportCategory> = [];
  dataSources: Array<DataSource> = [];
  filteredDataSources: Array<DataSource> = [];
  queryTypes: Array<ManualValuesModel> = [];
  dataSource: DataSource = null;
  report: ReportModel;
  isExisting = false;
  reportName = '';
  reportId = 0;
  isSaveAndContinue = false;

  // report settings parameter
  reportSettings: Array<ReportSettingModel> = [];
  reportSettingGroups: Array<{ GroupField: string, Color: string }> = [];
  GroupFieldId = 0;
  reportSettingsColumns: Array<GridColumn>;
  reportSettingsAlign = DomainConstants.Align;
  SETTINGS_COLUMNS = DomainConstants.ReportColumnSettingName;

  // report rule parameter
  rules: Array<Rule> = [];
  rulesColumns: Array<GridColumn> = [];
  reportEditGroupColumns: Array<GridColumn> = [];
  reportParameters: Array<DataSourceParameter> = [];
  comparator = orderBy(DomainConstants.Comparator);
  rulesParameter: Array<DataSourceParameter> = [];
  firstParameter: Array<DataSourceParameter> = [];
  secondParameter: Array<DataSourceParameter> = [];

  // Report rule template
  @ViewChild('MessageTemplate', { static: true }) private messageTemplate: TemplateRef<any>;
  @ViewChild('FirstParameterTemplate', { static: true }) private firstParameterTemplate: TemplateRef<any>;
  @ViewChild('SecondParameterTemplate', { static: true }) private secondParameterTemplate: TemplateRef<any>;
  @ViewChild('ComparatorTemplate', { static: true }) private comparatorTemplate: TemplateRef<any>;
  @ViewChild('operationTemplate', { static: true }) private operationTemplate: TemplateRef<any>;
  @ViewChild('reportForm', { static: true }) private reportForm: NgForm;

  // Report settings template
  @ViewChild('ColumnNameTemplate', { static: true }) private columnNameTemplate: TemplateRef<any>;
  @ViewChild('FormatTemplate', { static: true }) private formatTemplate: TemplateRef<any>;
  @ViewChild('AlignTemplate', { static: true }) private alignTemplate: TemplateRef<any>;
  @ViewChild('TotalTemplate', { static: true }) private totalTemplate: TemplateRef<any>;
  @ViewChild('GroupByColumnTemplate', { static: true }) private groupByColumnTemplate: TemplateRef<any>; 
  @ViewChild('GroupByColumnColorTemplate', { static: true }) private groupByColumnColorTemplate: TemplateRef<any>;
  @ViewChild('SettingsOperationTemplate', { static: true }) private settingsOperationTemplate: TemplateRef<any>;
  @ViewChild('GroupOperationTemplate', { static: true }) private groupOperationTemplate: TemplateRef<any>;
  @ViewChild('TotalHeaderTemplate', { static: true }) private totalHeaderTemplate: TemplateRef<any>;

  codeMirrorOptions = {
    lineNumbers: true,
    name: 'sql',
    lineWrapping: true
  };
  tabList = {
    Query: 'Query',
    Parameters: 'Parameters',
    Settings: 'Settings',
    Rules: 'Rules',
    Group: 'Group',
  };
  formGroups = {
    Query: { $valid: true },
    Parameters: { $valid: true },
    Settings: { $valid: true },
    Rules: { $valid: true },
  };
  selectedTab = this.tabList.Query;
  height = window.innerHeight - 520;
  icons = {
    deleteWhite, listWhite, databaseWhite, database, list, cogs, taskToDo, editSolid, layerGroup
  };
  parameters: Array<any> = [];
  isParametersRetrieved = false;
  isPrivate: boolean;
  reportFormat = [];
  ReportTwoParameterError = Messages.ReportTwoParameterError;
  props = {
    labelClass: '',
    controlBoxClass: 'col-lg-3 col-sm-5 col-xs-12'
  }

  constructor(protected reportConfigurationService: ReportConfigurationService,
    protected reportParameterRuleService: ReportParameterRuleService,
    protected reportColumnSettingsService: ReportColumnSettingsService,
    protected reportGroupService: ReportGroupService,
    protected dataSourceService: DataSourceService,
    protected dataSourceParameterService: DataSourceParameterService,
    protected reportCategoryService: ReportCategoryService,
    protected modalService: ModalService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    private postSavedConfigurationService: PostSavedConfigurationService,
    private router: Router,
    private route: ActivatedRoute,
    formUtilityService: FormUtilityService) {
    super(reportConfigurationService, alertService, spinnerService, formUtilityService);
    this.report = reportConfigurationService.newReport();
    this.dataSource = dataSourceService.newDataSource();
    this.dataSource.IsPrivate = true;
    this.id = parseInt(route.snapshot.params.id, 10) ?? 0;
    this.isExisting = this.id ? true : false;
    this.reportId = this.id;
  }

  ngOnInit(): void {
    forEach(DomainConstants.ReportColumnFormat, (key, value: string) => {
      this.reportFormat.push({ Text: key, Value: value });
    });
    this.saveSuccessMessage = Messages.ReportSaveSuccess;
    this.rulesColumns = this.configureRulesGridColumns();
    this.reportEditGroupColumns = this.configureReportEditGroupColumns();
    this.reportSettingsColumns = this.configureReportSettingsColumns();
  }

  private dataSubscription(): void {
    if (this.id > 0) {
      const reportObservable = [];
      reportObservable.push(this.loadData());
      reportObservable.push(this.reportColumnSettingsService.getReportSettings(this.id));
      this.spinnerService.show();
      forkJoin(reportObservable).pipe(finalize(() => {
        this.spinnerService.hide();
      }))
        .subscribe({
          next: (response: Array<any>) => {
            this.report = response[0];
            this.reportName = response[0].Name;
            this.reportId = response[0].Id;
            this.dataSourceService.get(this.report.DataSourceId).subscribe({
              next: (response) => {
                this.dataSource = response;
              }
            });
            this.filterReportGroup();
            this.getParameters();
            this.getReportRules();
            if (response[1].length) {
              this.formatSettings(response[1]);
            } else {
              this.createSettingRow(0, true);
              this.createGroupRow(0, true);
            }
            this.isPrivate = this.dataSources.find(x => x.Id === this.report.DataSourceId)?.IsPrivate;
          }, error: this.alertService.showApiError
        });
    } else {
      const newSettings = this.reportColumnSettingsService.newReportSettings();
      this.reportSettings.push(newSettings);
    }
  }

  onSaveSuccess() {
    return;
  }

  loadDependencies(): void {
    const reportObservable: Array<Observable<any>> = [];
    reportObservable.push(this.reportCategoryService.getAll());
    reportObservable.push(this.reportGroupService.getAll());
    reportObservable.push(this.dataSourceService.getAll());
    reportObservable.push(this.dataSourceService.getDataSourceType());
    this.spinnerService.show();
    forkJoin(reportObservable).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: ([categories, groups, dataSources, queryTypes]:
        [Array<ReportCategory>, Array<ReportGroup>, Array<DataSource>, Array<Object>]) => {
        this.reportCategories = categories;
        this.reportGroups = groups;
        this.dataSources = dataSources;
        this.filteredDataSources = dataSources.filter(x => !x.IsPrivate);
        forEach(queryTypes, (key: string, value: string) => {
          if (value !== "POSAPI") {
            this.queryTypes.push({ Name: key, Value: value });
          }
        });
        this.dataSubscription();
      }
    });
  }

  configureRulesGridColumns() {
    const columns = [
      new TemplateColumn({ HeaderText: 'First Parameter', Field: 'parameter1', itemTemplate: this.firstParameterTemplate, Width: '20%', CellClass: 'parameter-width' }),
      new TemplateColumn({ HeaderText: 'Comparator', Field: 'Comparator', itemTemplate: this.comparatorTemplate, Width: '25%', CellClass: 'comparator-width' }),
      new TemplateColumn({ HeaderText: 'Second Parameter', Field: 'parameter2', itemTemplate: this.secondParameterTemplate, Width: '21%', CellClass: 'parameter-width' }),
      new TemplateColumn({ HeaderText: 'Message', Field: 'Message', itemTemplate: this.messageTemplate, Width: '36%', CellClass: 'message-width' }),
      new TemplateColumn({ itemTemplate: this.operationTemplate, Width: '45px' , CellClass: 'vertical-align-center' })
    ];
    return columns;
  }

  configureReportSettingsColumns() {
    const columns = [
      new TemplateColumn({ HeaderText: 'Column Name', Field: 'ColumnName', itemTemplate: this.columnNameTemplate, Width: '30%' }),
      new TemplateColumn({ HeaderText: 'Format', Field: 'Format', itemTemplate: this.formatTemplate, Width: '25%' }),
      new TemplateColumn({ HeaderText: 'Align', Field: 'Align', itemTemplate: this.alignTemplate, Width: '25%' }),
      new TemplateColumn({ headerTemplate: this.totalHeaderTemplate, Field: 'Total', TextAlign: TextAlign.Center, itemTemplate: this.totalTemplate, Width: '10%' }),
      new TemplateColumn({ itemTemplate: this.settingsOperationTemplate, Width: '20px', TextAlign: TextAlign.Center })
    ];
    return columns;
  }

  configureReportEditGroupColumns() {
    const columns = [
      new TemplateColumn({ HeaderText: 'Column', itemTemplate: this.groupByColumnTemplate, Width: '50%' }),
      new TemplateColumn({ HeaderText: 'Color', itemTemplate: this.groupByColumnColorTemplate, Width: '30%' }),
      new TemplateColumn({ itemTemplate: this.groupOperationTemplate, Width: '20px', TextAlign: TextAlign.Center }),
    ]
    return columns;
  }

  filterReportGroup() {
    if (this.report.ReportCategoryId) {
      this.reportGroupsFiltered = this.reportGroups.filter((data) => {
        return data.ReportCategoryId === this.report.ReportCategoryId;
      });
    } else {
      this.report.ReportGroupId = null;
      this.reportGroupsFiltered = [];
    }
  }

  onQueryTypeChange() {
    this.dataSource.DataSource = '';
  }

  dataSourceChanged() {
    if (this.isExisting) {
      this.dataSource = null;
    } else {
      this.dataSource = this.dataSourceService.newDataSource();
      this.dataSource.IsPrivate = true;
      this.report.DataSourceId = null;
      this.reportParameters = [];
      this.rules = [];
      this.createRulesRow(0, true);
      this.createParameterRow(0, true);
    }
  }

  onChangeDataSource() {
    this.rules = [];
    this.getParameters();
    this.getReportRules();
    this.isPrivate = this.dataSources.find(x => x.Id === this.report.DataSourceId)?.IsPrivate;
  }

  getParameters() {
    if (this.report.DataSourceId) {
      this.isParametersRetrieved = false;
      this.spinnerService.show();
      this.dataSourceParameterService.getDataSourceParameters(this.report.DataSourceId)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: res => {
            this.reportParameters = res;
            this.rulesParameter = [...res];
            this.firstParameter = this.rulesParameter;
            this.secondParameter = this.rulesParameter;
            forEach(this.reportParameters, param => {
              if (param.DataType === 'BIT') {
                const initialValue = param.InitialValue === '0' ? false : null;
                param.InitialValue = param.InitialValue === '1' ? true : initialValue;
              }
            });
            this.isParametersRetrieved = true;
            this.createParameterRow(this.reportParameters.length - 1);
          }, error: this.alertService.showApiError
        });
    } else {
      this.createParameterRow(0, true);
    }
  }

  createParameterRow(index: number, firstRow = false) {
    if (this.reportParameters.length !== (index + 1) && !firstRow) {
      return;
    }
    const newParameter = this.dataSourceParameterService.newDataSourceParameter();
    newParameter.DataSourceId = this.id;
    newParameter.Ordinal = index + 1;
    this.reportParameters.push(newParameter);
  }

  // Rules operation Function starts

  getReportRules() {
    if (this.report.DataSourceId) {
      this.spinnerService.hide();
      this.reportParameterRuleService.getParameterRule(this.report.DataSourceId).subscribe({
        next: (res) => {
          this.rules = res;
          this.createRulesRow(0, true);
        }
      });
    } else {
      this.createRulesRow(0, true);
    }
  }

  createRulesRow(index: number, firstRow = false) {
    if (this.rules.length !== (index + 1) && !firstRow) {
      return;
    }
    const newRule = this.reportConfigurationService.newRule();
    this.rules.push(newRule);
  }

  firstParameterChanged(index: number) {
    this.firstParameter = this.rulesParameter.filter(x => x.Id !== this.rules[index].Parameter2Id);
  }

  secondParameterChanged(index: number) {
    this.secondParameter = this.rulesParameter.filter(x => x.Id !== this.rules[index].Parameter1Id);
  }

  removeRule(rule: Rule, index) {
    if (rule.Id) {
      const modal = this.modalService.getModalWrapper(ConfirmDeleteModalComponent);
      const modalRef = modal.show({
        animated: false,
        class: 'vertical-center',
        keyboard: false,
        initialState: {
          message: Messages.ConfirmDeleteReportRule
        }
      });
      modalRef.close.subscribe(res => {
        if (res && res.shouldDelete) {
          this.deleteRule(rule, index);
        }
      });
    } else {
      this.deleteRule(rule, index);
    }
  }

  deleteRule(rule: Rule, index) {
    if (rule.Id > 0) {
      this.spinnerService.show();
      this.reportParameterRuleService.delete(rule.Id)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res) => {
            this.getReportRules();
            this.alertService.renderSuccessMessage(Messages.ReportRuleDeleted);
            this.rules.splice(index, 1);
          }, error: this.alertService.showApiError
        });
    } else {
      this.rules.splice(index, 1);
    }
  }
  // Rules operation Function ends

  // Report settings function starts
  createSettingRow(index: number, firstRow = false) {
    if (this.reportSettings.length !== (index + 1) && !firstRow) {
      return;
    }
    const newSettings = this.reportColumnSettingsService.newReportSettings();
    this.reportSettings.push(newSettings);
  }

  removeSettings(data: ReportSettingModel, index: number) {
    if (data.AlignId || data.FormatId || data.TotalId) {
      const modal = this.modalService.getModalWrapper(ConfirmDeleteModalComponent);
      const modalRef = modal.show({
        animated: false,
        class: 'vertical-center',
        keyboard: false,
        initialState: {
          message: Messages.ConfirmDeleteReportSetting
        }
      });
      modalRef.close.subscribe(res => {
        if (res && res.shouldDelete) {
          this.deleteSetting(data, index);
        }
      });
    } else {
      this.deleteSetting(data, index);
    }
  }

  deleteSetting(data: ReportSettingModel, index: number) {
    if (data.AlignId || data.FormatId || data.TotalId) {
      const deleteObservable: Array<Observable<any>> = [];
      if (data.AlignId) {
        deleteObservable.push(this.reportColumnSettingsService.delete(data.AlignId));
      }
      if (data.FormatId) {
        deleteObservable.push(this.reportColumnSettingsService.delete(data.FormatId));
      }
      if (data.TotalId) {
        deleteObservable.push(this.reportColumnSettingsService.delete(data.TotalId));
      }
      this.spinnerService.show();
      forkJoin(deleteObservable).pipe(finalize(() => {
        this.spinnerService.hide();
      }))
        .subscribe({
          next: (res) => {
            this.getReportRules();
            this.alertService.renderSuccessMessage(Messages.ReportSettingDeleted);
            this.reportSettings.splice(index, 1);
          }, error: this.alertService.showApiError
        });
    } else {
      this.reportSettings.splice(index, 1);
    }
  }

  formatSettings(response: Array<ReportSetting>) {
    response.forEach(reportSetting => {
      let existingData = this.reportSettings.find(data => data.ColumnName === reportSetting.ColumnName);
      if (!existingData) {
        this.reportSettings.push({ ColumnName: reportSetting.ColumnName, Format: null, Align: null, Total: null });
        existingData = this.reportSettings.find(data => data.ColumnName === reportSetting.ColumnName);
      }
      if (existingData) {
        existingData[reportSetting.SettingName] = reportSetting.SettingName === this.SETTINGS_COLUMNS.Total ? true : reportSetting.SettingValue;
        if (reportSetting.SettingName === this.SETTINGS_COLUMNS.Total) {
          existingData.TotalId = reportSetting.Id;
        }
        if (reportSetting.SettingName === this.SETTINGS_COLUMNS.Format) {
          existingData.FormatId = reportSetting.Id;
        }
        if (reportSetting.SettingName === this.SETTINGS_COLUMNS.Alignment) {
          existingData.AlignId = reportSetting.Id;
        }
        if (reportSetting.SettingName === this.SETTINGS_COLUMNS.GroupField) {
          this.GroupFieldId = reportSetting.Id;
          this.reportSettingGroups = JSON.parse(reportSetting.SettingValue);
        }
      }
    });
    const newReportSettings = this.reportColumnSettingsService.newReportSettings();
    this.reportSettings.push(newReportSettings);
    remove(this.reportSettings, x => x.ColumnName == this.SETTINGS_COLUMNS.GroupField);
    this.reportSettingGroups.push(this.reportColumnSettingsService.newReportGroup());
  }

  setSettings() {
    remove(this.reportSettings, x => !x.ColumnName);
    const reportSetting: Array<ReportSetting> = [];
    this.reportSettings.forEach((data: ReportSettingModel) => {
      if (data.ColumnName) {
        forEach(data, (value: string, key) => {
          if (key === this.SETTINGS_COLUMNS.Alignment && (data.Align || data.AlignId)) {
            reportSetting.push({
              Id: data.AlignId, ReportId: this.report.Id, ColumnName: data.ColumnName,
              SettingName: key, SettingValue: value
            });
          }
          if (key === this.SETTINGS_COLUMNS.Format && (data.Format || data.FormatId)) {
            reportSetting.push({
              Id: data.FormatId, ReportId: this.report.Id, ColumnName: data.ColumnName,
              SettingName: key, SettingValue: value
            });
          }
          if (key === this.SETTINGS_COLUMNS.Total && (data.Total || data.TotalId)) {
            reportSetting.push({
              Id: data.TotalId, ReportId: this.report.Id, ColumnName: data.ColumnName,
              SettingName: key, SettingValue: data.Total ? 'GrandTotal' : null
            });
          }
        });
      }
    });
    remove(this.reportSettingGroups, x => !x.GroupField);
    if (this.reportSettingGroups.length || this.GroupFieldId) {
      reportSetting.push({
        Id: this.GroupFieldId ?? 0, ReportId: this.reportId, ColumnName: 'GroupField',
        SettingName: 'GroupField', SettingValue: this.reportSettingGroups.length ? JSON.stringify(this.reportSettingGroups) : null
      });
    }
    return reportSetting;
  }
  // Report settings function ends

  createGroupRow(index: number, firstRow = false) {
    if (this.reportSettingGroups.length !== (index + 1) && !firstRow) {
      return;
    }
    const newGroup = this.reportColumnSettingsService.newReportGroup();
    this.reportSettingGroups.push(newGroup);
  }

  removeGroup(data: any, index: number) {
    this.reportSettingGroups.splice(index, 1);
  }


  submit(isValid: boolean) {
    let invalidParameterNames = false;
    let invalidFieldNames = false;
    if (!isValid) {
      return;
    }
    forEach(this.reportParameters, x => {
      x.DataSourceId = this.isExisting ? this.report?.DataSourceId : this.dataSource?.Id;
      const existingLabel = filter(this.reportParameters, value => x.Label === value.Label && x.Label);
      if (existingLabel.length > 1) {
        invalidParameterNames = true;
      }
      const existingName = filter(this.reportParameters, value => x.Name === value.Name && x.Name);
      if (existingName.length > 1) {
        invalidFieldNames = true;
      }
    });
    if (invalidParameterNames) {
      this.alertService.renderErrorMessage(Messages.DataSourceParameterDuplicateName);
      return;
    }
    if (invalidFieldNames) {
      this.alertService.renderErrorMessage(Messages.DataSourceParameterDuplicateLabel);
      return;
    }
    if (!this.isExisting) {
      this.dataSource.Name = this.report.Name;
    } else {
      this.dataSource = this.isPrivate && this.report.Id ? this.dataSource : null;
    }
    remove(this.reportParameters, x => !x.Label);
    remove(this.rules, x => !x.Parameter1Id);
    forEach(this.reportParameters, param => {
      if (param.DataType === 'BIT') {
        const initialValue = param.InitialValue !== null && Boolean(param.InitialValue) === false ? '0' : param.InitialValue;
        param.InitialValue = param.InitialValue ? '1' : initialValue;
      }
    });
    this.spinnerService.show();
    const reportSettings: Array<ReportSetting> = this.setSettings();
    this.reportConfigurationService.saveReport({
      Report: this.report,
      DataSource: this.dataSource,
      DataSourceParameters: this.reportParameters,
      ReportParameterRules: this.rules,
      ReportColumnSettings: reportSettings
    })
      .pipe(finalize(() => {
        this.spinnerService.hide();
      })).subscribe({
        next: (response) => {
          if (!this.isSaveAndContinue) {
            this.alertService.renderSuccessMessage(Messages.ReportSaveSuccess);
            this.postSavedConfigurationService.showMessage();
            this.cancel();
          } else {
            this.isSaveAndContinue = false;
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';           
            this.router.navigate(['reports', response.Report.Id], {
              state: {
                reportId: response.Report.Id,
                isScroll: false,
                reportName: response.Report.Name ?? ''
              },
              relativeTo: this.route.parent
            });
            this.router.onSameUrlNavigation = 'ignore';
          }
        }, error: this.alertService.showApiError
      });
  }

  cancel() {
    this.router.navigate(['reports'], { relativeTo: this.route.parent });
  }
}
