<ng-container *ngIf="controlType === getEditorType.Text">
    <div [ngClass]="{'has-error': (!propertyValue && submit)}">
        <input type="text" class="form-control" name="propertyName" [required]="required" [(ngModel)]="propertyValue"
            (input)="changeProperty()" />
        <span *ngIf="((!propertyValue) && (submit))">
            <label class="control-label">{{requiredMessage}}</label>
        </span>
    </div>
</ng-container>
<ng-container *ngIf="controlType === getEditorType.List">
    <div [ngClass]="{'has-error': (!propertyValue && submit)}">
        <ng-select [placeholder]="propertyPlaceHolder" [(ngModel)]="propertyValue" [name]="propertyName" appendTo="body"
            [required]="required" [items]="propertyValues" [bindLabel]="bindLabel" [bindValue]="bindValue" (change)="changeProperty()">
        </ng-select>
        <span *ngIf="((!propertyValue) && (submit))">
            <label class="control-label text-nowrap">{{requiredMessage}}</label>
        </span>
    </div>
</ng-container>
