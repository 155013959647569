import { Directive, OnInit, HostListener, Renderer2, ElementRef } from '@angular/core';
import { ColorPickerDirective } from 'ngx-color-picker';

@Directive({
    selector: '[colorPicker]'
})
export class NgxColorPickerDirective implements OnInit {
    constructor(private colorPickerService: ColorPickerDirective) { }
    ngOnInit() {
    }
    @HostListener("keydown.Tab", ["$event"])
    onKeyDown(e) {
        this.colorPickerService.closeDialog();
    }
}
