import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { RouterModule } from '@angular/router';
import { WAITLIST_STATES } from './wait-list.routes';
import { WaitListTerminalComponent } from './components';
import { HeaderEntryModule } from '../header-entry';
import { ComponentsModule } from '../shared/components';



@NgModule({
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    RouterModule.forChild(WAITLIST_STATES),
    HeaderEntryModule,
    ComponentsModule,
  ],
  declarations: [
    WaitListTerminalComponent,
  ],
})
export class WaitListModule { }
