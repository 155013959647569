import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalFormComponent } from 'src/app/shared/components';
import { ModalService } from 'src/app/shared/components/modal';
import { DomainConstants } from 'src/app/shared/constants';
import { ButtonBehavior } from 'src/app/shared/interface/button-behavior';
import { FormUtilityService } from 'src/app/shared/services';

@Component({
  selector: 'pos-auto-markup',
  templateUrl: './auto-markup.component.html',
  styleUrls: ['./auto-markup.component.scss']
})
export class AutoMarkupComponent extends ModalFormComponent implements OnInit {
  @Input() selectedButtonBehaviorType;
  @Input() buttonBehavior: ButtonBehavior = null;
  @ViewChild('displayMessageForm') displayMessageForm: NgForm;

  get getForm(): NgForm {
    return this.displayMessageForm;
  }

  markupPercent: number = 0;

  displayMessage: string = null;

  constructor(modalRef: BsModalRef, modalService: ModalService, protected formUtilityService: FormUtilityService
  ) {
    super(modalRef, modalService, formUtilityService);
  }

  ngOnInit(): void {
    if (this.buttonBehavior && this.buttonBehavior.Id) {
      try {
        const behavior = JSON.parse(this.buttonBehavior.TextValue);
        this.markupPercent = behavior ? behavior.markupPercent : 0;
        this.setInitialValue();
      } catch (ex) {
        console.error('Invalid behavior value: ' + this.buttonBehavior.TextValue);
      }
    }
  }

  saveBehavior = (isValid: boolean) => {
    if (isValid) {
      this.hide({ shouldReload: true, Value: { markupPercent: this.markupPercent } });
    }
  }

  closeModal = () => {
    this.hide({ shouldReload: false });
  }
}
