<div>
    <button data-dismiss="modal" aria-label="Close" class="close" type="button" (click)="cancelEditing()">
        &times;
    </button>
    <h4 class="modal-content__heading">Authenticate</h4>
</div>
<div class="overflow-hidden pt-pix-20">
    <div class="bold pos-modal-body">
        Hold the on-off button for 5-7 seconds until the LED starts flashing in a pattern. Then click "Authenticate"
        button.
    </div>
    <div class="col-12 action-button-container popup-footer mb-pix-10">
        <button class="btn btn-primary normal btn-lg me-pix-5 pos-button" (click)="authorize()">Authenticate</button>
        <button class="btn btn-secondary btn-lg normal pos-button btn-cancel-modal"
            (click)="cancelEditing()">Close</button>
    </div>
</div>
