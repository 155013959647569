<div class="row mx-0">
<div class="bottom-grey-border" [ngStyle]="{'height':designMode? '45px':'35px'}">
    <h1 class="page__title text-center screen-title" [ngStyle]="{'margin-top':designMode? '15px':'5px'}">
        <span *ngIf="!designMode || infoMode">{{orderNavigation?.Screen?.Name}}</span>
        <span *ngIf="designMode && !infoMode" class="order-navigation-dropdown">
            <ng-select [items]="screens" [clearable]="false" [hideSelected]="true"
                (change)="getOrderNavigationDetails($event.id)" bindValue="id" [ngModel]="orderNavigation?.Screen?.Id"
                [clearOnBackspace]="false" (close)="screenHeader.searchInput.nativeElement.blur()" bindLabel="Screen"
                #screenHeader name="screen">
            </ng-select>
        </span>
    </h1>
    <span class="float-right screen-setup"
        *ngIf="designMode && !infoMode">
        <pos-screen-setup [screenId]="orderNavigation?.Screen?.Id" [homeScreenId]="homeScreenId"
            [screenButtons]="screenButtons" [screenName]="orderNavigation?.Screen?.Name" [navigation]="orderNavigation"
            [oldMenuIndex]="oldMenuIndex" (reloadNavigation)="getOrderNavigationDetails($event)"
            (reloadScreens)="getScreenData(true, $event)">
        </pos-screen-setup>
    </span>
</div>
<div class="tab-content thick-scrollbar overflow-auto-tabs px-0" [ngStyle]="{
    height: orderNavigationHeight,
    background: orderNavigation?.Screen?.BackgroundImage
        ? 'center / cover no-repeat url(\'' + imageRootPath + '/' + orderNavigation.Screen.BackgroundImage + '\')'
        : orderNavigation?.Screen?.ColorName }" style="width: 100%">
    <div class="order-section__products__block" id="ButtonView" *ngIf="orderNavigation && orderNavigation.Buttons">
        <!--/OrderNavigation repeat - start *ProductShow : Info mode, DesignMode : Design mode, !DesignMode : Order entry mode  /-->
        <div id="divRepeatGroup" class="product-group p-0"
            *ngFor="let od of orderNavigation.Buttons; let buttonIndex = index"
            [ngClass]="{'col-md-3 col-4':orderNavigation.Buttons.length>4 || orderNavigation.Buttons.length<=12, 
                                    'col-lg-6 col-md-2 col-4 col-4':orderNavigation.Buttons.length<5,
                                    'col-lg-2 col-md-2 col-4':orderNavigation.Buttons.length>12, 'disabled-button': od.IsDisabled }"
            [ngStyle]="{'width':orderNavigation.UiConfiguration.width ,'height' : orderNavigation.UiConfiguration.height+ 'px'}"
            [hidden]="!od.IsVisible || (od.ManagerOnly && (!userDetails.manager  && !userDetails.administrator)) 
            || (isMobile && od.SpecialFunction === buttonFunctions.PLACEHOLDER.Name && !designMode)">
            <div class="ps-pix-5 pe-pix-5 py-1" [ngStyle]="{'background-color':od.BackgroundColor}">
                <div class="relative" *ngIf="infoMode">
                    <div class="product-group__qty" [hidden]="!od.SalesProductId">
                        {{(salesProductInfo[od.SalesProductId] ? salesProductInfo[od.SalesProductId].AvailableQty :
                        '')|floor}}
                    </div>
                    <div class="product-group__price" *ngIf="od.Price && od.SalesProductId">
                        <span *ngIf="!od.IsDefault">
                            {{((od.Price + priceAdjust > 0) ? od.Price + priceAdjust : 0)| tark_currency}}
                        </span>
                        <span *ngIf="od.IsDefault">
                            {{od.Price| tark_currency}}
                        </span>
                    </div>
                </div>


                <div
                    [ngClass]="{'product-block':((od.SpecialFunction !==buttonFunctions.PLACEHOLDER.Name) || designMode)}">
                    <div class="text-center" [hidden]="od.IsHide"
                        [ngClass]="{'product-block__detail':((od.SpecialFunction !==buttonFunctions.PLACEHOLDER.Name) || designMode), 'disabled-button': od.IsDisabled}"
                        [ngStyle]="{'border': (od.IsSearch?('4px solid ' + settingParam.SearchButtonNavigationBorderColor):(od.BorderColor?('4px solid ' + od.BorderColor): '')) || 
                        ((!od.Color && !designMode && (od.SpecialFunction == buttonFunctions.PLACEHOLDER.Name))?'2px solid transparent':((od.SpecialFunction == buttonFunctions.PLACEHOLDER.Name) && !designMode ) ? '2px solid ' + od.Color : '')}">
                        <div class="product-block__detail__block" [ngClass]="{'enabled-addproduct-button': od.IsFlash}"
                            (click)="buttonPressed(od)" [ngStyle]="{'background-color': od.Color}">
                            <div class="product-block__detail__info"
                                [ngClass]="{'stripe-even': designMode && !infoMode && od.EmbeddedScreenChoiceId &&(od.EmbeddedScreenNumber % 2 == 0), 
                                'stripe-odd': designMode && !infoMode && od.EmbeddedScreenChoiceId && (od.EmbeddedScreenNumber % 2 != 0)}"
                                [ngStyle]="{'height' : orderNavigation.UiConfiguration.height - (od.BorderColor ? 4 : 0) + 'px'}">
                                <pos-icon class="selected-product-check"
                                    *ngIf="od.IsSelected && od.Toggle && isShowCheckmarkOnToggleButton" [style]="{'color': od.IsDefault ? settingParam.ToggleButtonDefaultActiveStateCheckmarkColor :settingParam.ToggleButtonActiveStateCheckmarkColor,
                                'font-size': (orderNavigation.UiConfiguration.height * 0.15) + 'px'}"
                                    [icons]="[icons.orderProductCircle,icons.orderProductCheckMark]"></pos-icon>
                                <div *ngIf="od.Toggle && designMode && !infoMode && !od.IsSelected">
                                    <pos-icon [icons]="[icons.circleRegular]" class="selected-product-check" [style]="{'color': (od.IsDefault?settingParam.ToggleButtonDefaultActiveStateCheckmarkColor:settingParam.ToggleButtonActiveStateCheckmarkColor),
                                    'font-size': (orderNavigation.UiConfiguration.height * 0.15) + 'px'}"></pos-icon>
                                </div>
                                <div *ngIf="od.Image" class="product-block__detail__info__img-section">
                                    <img class="product-block__detail__info__img-section__img"
                                        [ngStyle]="{'height': orderNavigation.UiConfiguration.imageSize }"
                                        src="{{imageRootPath}}/{{od.Image}}" />
                                </div>
                                <div class="product-block__detail__info__name"
                                    [ngClass]="{'product-block__detail__info__name--no-img':!od.Image}"
                                    [ngStyle]="{'color':od.TextColor}" [hidden]="!od.ButtonText">
                                    {{((!od.IsSelected && (od.IsDefault && !od.NextScreenId && !od.StackScreenId)) ?
                                    'without ' : '') + od.ButtonText}}
                                </div>
                            </div>
                        </div>
                        <div *ngIf="designMode && !infoMode">
                            <div class="product-block__menu design-cog-icon display-flex">
                                <div class="product-block__menu__options" id="button_{{buttonIndex}}"
                                    (click)="buttonMenu($event, buttonIndex)">
                                    <pos-icon [icon]="icons.cog"></pos-icon>
                                    <ul class="custom-order" id="menu_{{buttonIndex}}"
                                        [ngStyle]="{'top': topMenuPosition,'position':'fixed'}">
                                        <div *ngFor="let menuOption of buttonMenuOptions"
                                            [ngClass]="{'text-center': menuOption.Action === menuActions.Heading }"
                                            [ngStyle]="{'cursor': menuOption.Action === menuActions.Heading ?'auto':'pointer'}">
                                            <span class="font-13 grey bold ps-pix-5"
                                                *ngIf="menuOption.Action === menuActions.Heading && od.EmbeddedScreenId">{{menuOption.Name}}</span>
                                            <li *ngIf="menuOption.Action != menuActions.Heading && ((menuOption.Menu !== buttonMenuOptionsConstants.RemoveEmbeddedScreen && menuOption.Menu !== buttonMenuOptionsConstants.EmbeddedScreenHeading) || od.EmbeddedScreenId)"
                                                (click)="openMenuConfig(menuOption, od)"
                                                [permissions]="menuOption.Permission" [checkPast]="false"
                                                [ngClass]="{'custom-orderUserPref': appStateConfigurations.UserPreferenceInvoiceLocation == 'left'}">
                                                <a href="">
                                                    <div class="order-menu-action-icon" *ngIf="menuOption.Icon">
                                                        <pos-icon class="black" [icon]="menuOption.Icon"></pos-icon>
                                                    </div>
                                                    {{menuOption.Name}}
                                                </a>
                                            </li>
                                        </div>
                                    </ul>
                                </div>
                                <div class="product-block__menu__options ms-pix-4"
                                    *ngIf="od.ScheduleStartDate || od.ScheduleStartTime || od.DateRange">
                                    <pos-icon [icon]="icons.clock"></pos-icon>
                                </div>
                            </div>
                        </div>
                        <div class="product-block__menu bl-10"
                            [hidden]="(od.IsActive || od.SalesProductId == null || !designMode || infoMode)">
                            <div class="product-block__menu__options">
                                <pos-icon [icon]="icons.orderButtonBan" class="font-1em black"></pos-icon>
                            </div>

                        </div>
                        <div class="product-block__menu br-10"
                            *ngIf="od.SalesProductId && ((!od.IsInStock && !od.IsAllowOutOfStockOrder))">
                            <div class="product-block__menu__options">
                                <pos-icon [icon]="icons.minusSquare"></pos-icon>
                            </div>
                        </div>

                    </div>
                    <div class="product-block__menu filter-button br-10" *ngIf="od.IsAppliedFilter"
                        [ngClass]="{'r-40':od.SalesProductId && ((!od.IsInStock && !od.IsAllowOutOfStockOrder))}">
                        <div class="product-block__menu__options">
                            <pos-icon [icon]="icons.exclamationTriangleRed"></pos-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="order-section__bottom px-0">
    <div class="order-section__bottom__block" [ngClass]="{'pull-right': configurations.UserPreferenceInvoiceLocation=='left', 'pull-left': configurations.UserPreferenceInvoiceLocation=='right'}">
        <div class="bg-light-gray pull-left display-flex ps-pix-10 navigationBar overflow-auto col-lg-8 col-md-8 col-sm-8 ">
            <!-- *ng-if="!IsShowCliTerminal && ScreenWidth > 767" -->
            <span id="spanWidthGetter" class="bold pe-pix-5 font-18 display-none"></span>
            <span class="breadcrumbs-navigation display-flex">
                <span class="order-section__bottom__block__arrow arrow-left text-center display-flex"
                    *ngIf="screenNavigationTracker.length >= (screenTrackLimit) + 1">
                    <a class="pointer" (click)="navigateToHomeScreen()">
                        <div class="bold pe-pix-5 font-18 text-ellipsis" title="{{screenNavigationTracker[0].Screen.Name}}">
                            {{screenNavigationTracker[0].Screen?.Name}} </div>
                    </a>
                    <span class="ps-pix-10 pe-pix-15 extreme-orange"><i class="fas fa-angle-right fa-2x"></i></span>
                    <a class="">
                        <div class="bold pe-pix-5 font-18 text-ellipsis"
                            *ngIf="screenNavigationTracker.length >= ((screenTrackLimit) + 1)">
                            .....</div>
                    </a>
                    <span class="ps-pix-10 pe-pix-15 extreme-orange"
                        *ngIf="screenNavigationTracker.length >= ((screenTrackLimit) + 1)"><i
                            class="fas fa-angle-right fa-2x"></i></span>
                </span>
                <span id="screenTrack-{{i}}"
                    *ngFor="let screenNavigationTrack of screenNavigationTracker; let i = index">
                    <span class="order-section__bottom__block__arrow arrow-left text-center display-flex"
                        *ngIf="(i >= screenNavigationTracker.length - screenTrackLimit) || ((i + 1) == screenNavigationTracker.length)">
                        <a class="pointer" (click)="navigateLinkScreen(i)">
                            <div class="bold pe-pix-5 font-18 text-ellipsis" title="{{screenNavigationTrack.Screen?.Name}}">
                                {{screenNavigationTrack.Screen?.Name}}
                            </div>
                        </a>
                        <span class="ps-pix-10 pe-pix-15 extreme-orange"><i class="fas fa-angle-right fa-2x"></i></span>
                        <div *ngIf="(i + 1) == screenNavigationTracker.length" class="bold pe-pix-5 font-18 ">
                            {{orderNavigation?.Screen?.Name}}</div>
                    </span>
                </span>
                <div class="bold pe-pix-5 font-18" *ngIf="!screenNavigationTracker || !screenNavigationTracker.length">
                    <span
                        class="order-section__bottom__block__arrow arrow-left text-center display-flex">{{orderNavigation?.Screen?.Name}}</span>
                </div>
            </span>
        </div>

        <div class="order-section__bottom__block--small">
            <div class="col-sm-12 p-0">
                <div class="bg-theme-separator pull-left width-full row mx-0">
                    <a class="order-section__bottom__block__arrow arrow-left col-3 text-center pointer bg-theme-separator vertical-center-align"
                        (click)="navigateBackward()">
                        <pos-icon [icon]="icons.navigateBackward"
                            [hidden]="orderNavigation?.Screen?.Id == homeScreenId || !screenNavigationTracker.length">
                        </pos-icon>
                    </a>
                    <div
                        class="order-section__bottom__block__arrow arrow-left border-right-theme-primary border-left-theme-primary col-6 row">
                        <div class="col-md-1">
                        </div>
                        <div class="col-md-10">
                            <div class="order-section__bottom__block__search">
                                <i aria-hidden="true"
                                    class="order-section__bottom__block__search__clear fas fa-times uib-modal-zindex"
                                    (click)="clearButtonSearch()"></i>
                                <p-autoComplete [(ngModel)]="searchValue" [suggestions]="searchButtons"
                                    inputStyleClass="form-control order-section__bottom__block__search__input"
                                    styleClass="auto-complete-width-100" (completeMethod)="search($event)"
                                    (input)="search($event)" field="ButtonText" [size]="20" placeholder="Find..."
                                    [minLength]="3" (onSelect)="selectSearch($event)"></p-autoComplete>
                            </div>
                        </div>
                        <div class="col-md-1">
                        </div>

                    </div>
                    <div class="order-section__bottom__block__arrow arrow-right col-3 text-center">
                    </div>
                </div>
            </div>
            <pos-function-buttons [(infoMode)]='infoMode' [orderDetail]="versionedVal.Obj.Order"
                (filteredData)="getFilteredData($event)"
                [buttons]="(orderNavigation?.Buttons?orderNavigation?.Buttons:[])" [serveMethods]="serveMethods"
                (addItem)='addItemToOrder()'>
            </pos-function-buttons>
        </div>
    </div>
</div>
</div>
