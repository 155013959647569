import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { InventoryProductService } from '../../../../shared/services/inventory-product.service';
import { finalize } from 'rxjs/operators';
import { InventoryReceived } from '../../../../shared/interface/inventory-received';
import { GridColumn, TextAlign, DateColumn } from '@tarktech/tark-ng-utils';
import { upload } from 'src/app/shared/components/icon';
import { saveAs } from 'file-saver';
import { ModalComponent } from 'src/app/shared';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'pos-inventory-received',
    templateUrl: './inventory-received.component.html',
    styleUrls: ['./inventory-received.component.scss']
})
export class InventoryReceivedComponent extends ModalComponent implements OnInit {

    @Input('inventoryProductName') inventoryProductName: string;
    @Input('inventoryProductId') inventoryProductId: number;
    inventoryReceivedSummary: Array<InventoryReceived> = [];
    @Output() closePopup = new EventEmitter();
    inventoryReceivedSummaryColumns: Array<GridColumn> = [];
    public icons = { upload };

    constructor(
        modalRef: BsModalRef,
        protected alertService: AlertsService,
        protected spinnerService: SpinnerService,
        private inventoryProductService: InventoryProductService
    ) {
        super(modalRef);
    }

    ngOnInit() {
        this.configureGridColumns();
        this.loadData();
    }

    private configureGridColumns() {

        this.inventoryReceivedSummaryColumns = [
            new DateColumn({
                HeaderText: 'Date',
                Field: 'ReceivedDate',
                TextAlign: TextAlign.Left,
                IsSortable: true,
                Width: '25%'
            }),
            new GridColumn({ HeaderText: 'Qty.', Field: 'Qty', IsSortable: true, Width: '10%' }),
            new GridColumn({ HeaderText: 'Unit Qty.', Field: 'UnitQty', IsSortable: true, Width: '12%' }),
            new GridColumn({ HeaderText: 'Received By', Field: 'ReceivedBy', IsSortable: true, Width: '22%' }),
            new GridColumn({ HeaderText: 'Barcode', Field: 'Barcode', IsSortable: true, Width: '18%' }),
            new GridColumn({ HeaderText: 'Location', Field: 'InventoryOffsiteLocation', IsSortable: true, Width: '20%' })
        ] as Array<GridColumn>;
    }

    private loadData(): void {
        this.spinnerService.show();
        this.inventoryProductService.getInventoryReceived(this.inventoryProductId)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (response) => {
                    this.inventoryReceivedSummary = response ? response : [];
                }, error: this.alertService.showApiError
            });
    }

    public cancel() {
        this.hide({});
        this.closePopup.emit(true);
    }

    public exportAction(tableId: string) {
        const fileName = this.inventoryProductName.replace(' ', '-') + '-InventoryReceived.xls';
        const blob = new Blob([document.getElementById(tableId).outerHTML], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
        });
        saveAs(blob, fileName);
    }
}
