<pos-list-wrapper [headingIcon]="icons.cashDrawerEntriesMoneyBillWave" heading="Cash Drawer Entries" (close)="close()">
    <div class="row">
        <div class="text-right col-12 cash-drawer-entries">
            <pos-export-dropdown class="inline-block float-end pb-pix-3 font-weight-600 pt-pix-8" (onPrint)="onPrint()"
                (onCSV)="exportCSV()"></pos-export-dropdown>
        </div>
        <div class="col-12">
            <ng-table id="cashDrawerEntries" [sort]="true" [columns]="cashDrawerEntryColumns"
                [data]="cashDrawerEntries">
            </ng-table>
        </div>
    </div>
</pos-list-wrapper>
<pos-print-table [hidden]="true"></pos-print-table>
