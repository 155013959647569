<pos-edit-wrapper (close)="onCancel(false)" heading="Product Loss Reason">
  <form #productLossReasonForm="ngForm" (ngSubmit)="saveProductLossReason(productLossReasonForm.valid)"
    autocomplete="off">
    <div class="col-12 pos-modal-body screen-scroll pt-pix-5">
      <pos-row>
        <pos-column>
          <pos-form-field label="Name" [validationMessages]="{required: 'Please enter product loss reason name.'}">
            <input posInput class="form-control" name="name" #name="ngModel" [(ngModel)]=productLossReasonData.Name
              [required]="true">
          </pos-form-field>
        </pos-column>
      </pos-row>
    </div>
    <div class="col-12 action-button-container popup-footer">
      <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" scrollup>Save </button>
      <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" (click)="onCancel(false)">Cancel</button>
    </div>
  </form>
</pos-edit-wrapper>
