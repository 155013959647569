import { Injectable } from '@angular/core';
import { OffsiteInventoryProductSetting } from '../interfaces';
import { BaseCrudService } from 'src/app/shared/services';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OffsiteInventoryProductSettingService extends BaseCrudService<OffsiteInventoryProductSetting> {

  constructor(private httpClient: HttpClient) { 
    super('offsite-inventory-product-settings', httpClient);
  }

  getProductsByLocation(locationId: number) {
    return this.httpClient.get(`${this.apiEndpoint}/location/${locationId}`);
  }

  saveProductSettings(productSettings: Array<OffsiteInventoryProductSetting>) {
    return this.httpClient.post(`${this.apiEndpoint}`, productSettings);
  }

  updateProductStatus(locationId: number, isActive = false) {
    return this.httpClient.post(`${this.apiEndpoint}/location/${locationId}/update-status`, isActive);
  }
}
