import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TrainingBadge } from '../../interface/training-badge';
import { RuntimeConstants, AlertsService, SpinnerService, ModalService, ImageGalleryComponent, Messages, ApplicationStateService, BaseFormComponent, FormUtilityService } from 'src/app/shared';
import { TrainingBadgeService } from '../../services';
import { zip } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ExtensionValidationService } from 'src/app/shared/services/extension-validation.service';
import { trainingBadge, times } from 'src/app/shared/components/icon';
declare let $: any;

@Component({
    selector: 'pos-training-badge-edit',
    templateUrl: './training-badge-edit.component.html',
})
export class TrainingBadgeEditComponent extends BaseFormComponent implements OnInit {
    get getForm(): NgForm {
        return this.form
    }
    @ViewChild('formTrainingBadge', { static: true }) form: NgForm;
    badge: TrainingBadge;
    iconList: Array<any> = [];
    badgeIcon: File;
    showBadgeIcon = false;
    icons = { trainingBadge, times }
    public imageRootPath = RuntimeConstants.IMAGE_BASE_PATH + '/training-badge-icon';
    constructor(private route: ActivatedRoute,
        private alertService: AlertsService,
        private spinnerService: SpinnerService,
        private router: Router,
        private modalService: ModalService,
        private applicationStateService: ApplicationStateService,
        private trainingBadgeService: TrainingBadgeService,
        private extensionValidation: ExtensionValidationService,
        formUtilityService: FormUtilityService) {
        super(formUtilityService);
        this.badge = this.trainingBadgeService.newTrainingBadge();
        this.badge.Id = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
    }

    ngOnInit() {
        this.getTrainingBadge(this.badge.Id);
        this.setInitialValue();
    }

    getTrainingBadge(badgeId: number) {
        if (badgeId > 0) {
            this.spinnerService.show();
            zip(
                this.trainingBadgeService.getTrainingBadge(badgeId),
                this.trainingBadgeService.getTrainingBadgeImages(),
                (
                    badge: any,
                    uploadedIcons: any
                ) => {
                    this.badge = badge;
                    this.iconList = uploadedIcons;
                    if (badge.Icon !== '') {
                        this.showBadgeIcon = true;
                    }
                }
            ).subscribe({
                next: (res) => {
                }, error: this.alertService.showApiError,
                complete: () => {
                    this.spinnerService.hide();
                }
            });
        } else {
            this.spinnerService.show();
            this.trainingBadgeService.getTrainingBadgeImages().subscribe(
                res => {
                    this.iconList = res;
                }, this.alertService.showApiError,
                () => {
                    this.spinnerService.hide();
                });
            this.badge = this.trainingBadgeService.newTrainingBadge();
        }

    }

    selectIcon() {
        const imageGalleryModalRef = this.modalService.show(ImageGalleryComponent, {
            animated: false,
            class: 'vertical-center modal-lg',
            initialState: {
                imageList: this.iconList,
                imageRootPath: this.imageRootPath,
                headerName: 'Select Badge Icon'
            }
        });

        imageGalleryModalRef.close.subscribe(res => {
            if (res && res.selectedImage) {
                this.badge.Icon = res.selectedImage;
                this.showBadgeIcon = true;
                this.badgeIcon = null;
            }
        });
    }

    saveTrainingBadge(isValid: boolean) {
        const fileToUpload = this.badgeIcon ? this.badgeIcon.name : '';
        if ((!isValid || (this.badge.ScoreThreshold != null && (this.badge.ScoreThreshold <= 0 || this.badge.ScoreThreshold % 10 > 0))
            || !this.showBadgeIcon
            || (this.extensionValidation.isInvalidExtension(fileToUpload)) && !this.badge.Icon)) {
            return;
        }
        this.spinnerService.show();
        this.trainingBadgeService.saveTrainingBadge(this.badge, this.badgeIcon, this.applicationStateService.userDetails.id).subscribe(
            res => {
                this.alertService.renderSuccessMessage(Messages.TrainingBadgeSavedSuccess);
                this.onCancel();
                this.router.navigate(['training-badges'], { relativeTo: this.route.parent });
            }, this.alertService.showApiError,
            () => {
                this.spinnerService.hide();
            }
        );
    }

    handleFileInput(files: FileList) {
        this.badgeIcon = files.item(0);
        this.badge.Icon = this.badgeIcon.name;
        const fileToUpload = this.badgeIcon ? this.badgeIcon.name : '';
        if (this.extensionValidation.isInvalidExtension(fileToUpload)) {
            if (fileToUpload) {
                this.alertService.renderErrorMessage(Messages.InvalidReceiptLogoFileType);
            }
            this.removeIcon();
            return;
        }
        if (files && files[0]) {
            const reader = new FileReader();
            reader.onload = function (e) {
                $('#badgeImage')
                    .attr('src', reader.result);
            };
            reader.readAsDataURL(files[0]);
            this.showBadgeIcon = true;
        }
    }

    removeIcon() {
        this.badge.Icon = '';
        $('#badgeIcon').val('');
        this.showBadgeIcon = false;
    }

    onCancel() {
        this.router.navigate(['training-badges'], { relativeTo: this.route.parent });
    }
}
