import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Account } from '../../interface/account';
import { BaseListComponent } from 'src/app/shared/components/base-list.component';
import { Permissions, Levels, Messages, DomainConstants } from '../../../../shared/constants/';
import { GridColumn, TemplateColumn, TextAlign, TableComponent } from '@tarktech/tark-ng-utils';
import { AccountService } from '../../../../shared/services/account.service';
import { AuthenticationService } from 'src/app/shared/auth/services/authentication.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import { check, infoManageTablesSolarPanel } from 'src/app/shared/components/icon';
import * as _ from 'lodash';
import { finalize } from 'rxjs/operators';
import { AccountTypeService, ApplicationStateService, SettingParam } from 'src/app/shared';
import { ActivatedRoute, Router } from '@angular/router';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { forkJoin } from 'rxjs';
import { AccountAttributeTypeService } from 'src/app/information-management/accounts';
import { AccountAttributeTypes } from 'src/app/information-management/customers';
import { cloneDeep, forEach, orderBy } from 'lodash';
import { AccountType } from '../../interface';

@Component({
    selector: 'pos-accounts-list',
    templateUrl: './accounts-list.component.html',
})
export class AccountsListComponent extends BaseListComponent<Account> implements OnInit {

    permission = {
        name: Permissions.CustomersAccounts,
        readOnlyLevel: Levels.ReadOnly,
        editLevel: Levels.Edit
    };
    icons = {
        check,
        infoManageTablesSolarPanel
    };
    accountColumns: Array<GridColumn> = [];
    printAccountColumns: Array<GridColumn> = [];
    accounts: Account[] = [];
    filteredAccounts: Account[] = [];
    accountAttributeTypes: AccountAttributeTypes[] = [];
    accountTypes: AccountType[] = [];
    accountsSearchResult: Account[] = [];
    @ViewChild('iconTemplate', { static: true }) private iconTemplate: any;
    isActiveAccounts = true;
    @ViewChild('accountGrid', { static: true }) accountGrid: TableComponent;
    @ViewChild('phoneTemplate', { static: true }) phoneTemplate: TemplateRef<any>;
    settingParam: SettingParam;
    accountTypeId: number = null;

    constructor(
        private authenticationService: AuthenticationService,
        protected alertService: AlertsService,
        protected spinnerService: SpinnerService,
        protected accountService: AccountService,
        protected accountTypeService: AccountTypeService,
        protected accountAttributeTypeService: AccountAttributeTypeService,
        protected modalService: ModalService,
        protected route: ActivatedRoute,
        private router: Router,
        protected printService: PrintTableService,
        protected applicationStateService: ApplicationStateService
    ) {
        super(accountService, alertService, spinnerService, modalService, applicationStateService, printService, route);
        this.settingParam = applicationStateService.settingParam;
    }

    ngOnInit() {
        if (this.othersProperties.isActiveAccounts === false) {
            this.isActiveAccounts = false;
        }
        if (this.othersProperties.accountTypeId) {
            this.accountTypeId = this.othersProperties.accountTypeId;
        }
        this.deleteSuccessMessage = Messages.AccountDeleted;
        this.accountColumns = this.configureGridColumns();
        this.printAccountColumns = [...this.getGridColumns()];
        this.checkPermission();
        this.scrollToTop();
        this.accountGrid.context = this.gridContext;
    }

    getOtherProperties() {
        this.othersProperties = { isActiveAccounts: this.isActiveAccounts, accountTypeId: this.accountTypeId };
    }

    public getGridColumns(): Array<GridColumn> {
        const iconColumn = new TemplateColumn({
            itemTemplate: this.iconTemplate,
            TextAlign: TextAlign.Center,
            Width: '6%',
            Field: 'IsActive',
            IsSortable: true,
            HeaderText: 'Active'
        });
        const phoneColumn = new TemplateColumn({
            itemTemplate: this.phoneTemplate,
            Width: '10%',
            Field: 'Phone',
            IsSortable: true,
            HeaderText: 'Phone'
        });

        let gridColumns = [
            new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '20%' }),
            new GridColumn({ HeaderText: 'Address', Field: 'Address', IsSortable: true, Width: '29%' }),
            new GridColumn({ HeaderText: 'City', Field: 'City', IsSortable: true, Width: '10%' }),
            new GridColumn({ HeaderText: 'State', Field: 'State', IsSortable: true, Width: '10%' }),
            phoneColumn,
            new GridColumn({ HeaderText: 'Account Type', Field: 'AccountType', IsSortable: true, Width: '10%' }),
            iconColumn
        ];

        return gridColumns;
    }
    private checkPermission(): void {
        if (!this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
            _.remove(this.accountColumns, (column) => {
                return (column.Type === DomainConstants.GridColumnsTypes.EditColumn
                    || column.Type === DomainConstants.GridColumnsTypes.DeleteColumn);
            });
        }
    }
    public reload() {
        this.spinnerService.show();
        forkJoin([
            this.accountService.getAccounts(),
            this.accountTypeService.getAccountTypes(),
            this.accountAttributeTypeService.getAccountAttributeTypes()
        ])
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: ([accounts, accountTypes, attributeTypes]: [Account[], AccountType[], AccountAttributeTypes[]]) => {
                    this.accounts = accounts?.length ? accounts : [];
                    this.setAccountTerms();
                    this.filteredAccounts = cloneDeep(this.accounts);
                    this.accountAttributeTypes = attributeTypes?.length ? attributeTypes.filter(x => x.IsSearchable) : [];
                    this.accountTypes = accountTypes;
                    this.accountTypes.forEach(x => {
                        x.AccountTerm = x.AccountType == DomainConstants.AccountTypes.Account ? this.settingParam.LoyalAccountTerm : (x.AccountType == DomainConstants.AccountTypes.Tab ? this.settingParam.AccountTerm : x.AccountType);
                    });
                    this.accountTypes = orderBy(this.accountTypes, 'AccountTerm');
                    this.addAccountAttributesColumn();
                    this.filterAccounts();
                }, error: this.alertService.showApiError
            });
    }

    private addAccountAttributesColumn() {
        forEach(this.accountAttributeTypes, attribute => {
            const attributeColumn = new GridColumn({ HeaderText: attribute.Name, Field: attribute.Name, IsSortable: true, Width: '10%' });
            if (!this.accountColumns.map(x => x.Field).includes(attributeColumn.Field)) {
                this.accountColumns.push(attributeColumn);
                this.printAccountColumns.push(attributeColumn);
            }
        });
    }

    filterAccounts() {
        if (this.accountTypeId) {
            this.filteredAccounts = [...this.accounts.filter(x => x.AccountTypeId == this.accountTypeId && x.IsActive == this.isActiveAccounts)];
        } else {
            this.filteredAccounts = [...this.accounts.filter(x => x.IsActive == this.isActiveAccounts)];
        }
    }

    setAccountTerms() {
        forEach(this.accounts, account => {
            account.AccountType = account.AccountType == DomainConstants.AccountTypes.Account ? this.settingParam.LoyalAccountTerm : (account.AccountType == DomainConstants.AccountTypes.Tab ? this.settingParam.AccountTerm : account.AccountType);
            forEach(account.AccountAttributeValues, (value, key) => {
                account[key] = value;
            });
        });
    }
    public editItem(id: number, data?: Account): void {
        this.router.navigate([id], { relativeTo: this.route });
    }
    public getConfirmDeleteMessage(data: Account): string {
        return StringUtils.format(Messages.ConfirmDeleteAccount,
            { 'account': data ? data.Name : '' }
        );
    }
    private scrollToTop(): void {
        window.scrollTo(0, 0);
    }
    public printData() {
        this.printService.printEmitter.next({ gridColumns: this.printAccountColumns, gridData: this.accounts });
    }

    close() {
        this.router.navigate(['../'], { relativeTo: this.route });
    }
}
