import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import {
  ChartWidgetConfiguration, ColorUtilityService, DashboardConfiguration, DashboardConfigurationService,
  ModalService
} from 'src/app/shared';
import * as Highcharts from 'highcharts';
import { WidgetEditComponent } from '../widget-edit';
import { BaseWidgetService } from 'src/app/adf/services/base-widget.service';
import { forEach, cloneDeep } from 'lodash';
import { Observable, interval as observableInterval, Subscription } from 'rxjs';
import { DashboardBroadcastingService } from 'src/app/adf/services/dashboard-broadcasting.service';
import { AdfModel } from 'src/app/adf/interfaces/adf-model';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'pos-chart-widget',
  templateUrl: './chart-widget.component.html',
  styleUrls: ['./chart-widget.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ChartWidgetComponent implements OnInit, OnDestroy {

  @Input() widgetConfiguration: DashboardConfiguration;
  data: any;
  @Input() isInteractive = false;

  isLoading = true;
  @Input() config: ChartWidgetConfiguration;
  highcharts = Highcharts;
  chartOptions: Highcharts.Options;
  isSplineChart: boolean;
  zoneData = [];
  timer: Observable<number>;
  timerSubscription: any;
  eventsSubscription: Array<Subscription> = [];
  @Input() column: any;
  @Input() adfModel: AdfModel;
  copiedConfig: ChartWidgetConfiguration;
  constructor(private dashboardService: DashboardConfigurationService,
    private colorConversionService: ColorUtilityService,
    private modalService: ModalService,
    private baseWidgetService: BaseWidgetService,
    private dashboardBroadcastingService: DashboardBroadcastingService) { }

  ngOnInit(): void {
    this.copiedConfig = cloneDeep(this.config);
    if (this.widgetConfiguration && !this.widgetConfiguration.isNewWidget) {
      this.isSplineChart = this.config.chartType === 'line';
      if (this.widgetConfiguration) {
        this.baseWidgetService.loadBaseWidget(this.widgetConfiguration, this.config, this.getWidgetCompleted);
        this.startAutoIntervalTimer();
      }
    } else {
      this.editTextWidget();
    }
    this.subscribeBroadcastEvents();
  }

  subscribeBroadcastEvents = () => {
    this.eventsSubscription.push(this.dashboardBroadcastingService.reloadWidget.subscribe((widget) => {
      if (widget && widget.widgetAssignmentId > 0) {
        this.reloadWidget(widget);
      }
    }));
  }

  reloadWidget = (widget) => {
    if (this.widgetConfiguration.widgetAssignmentId == widget.widgetAssignmentId) {
      this.isLoading = true;
      this.widgetConfiguration = cloneDeep(widget);
      this.config = widget.config;
      this.widgetConfiguration.isReloadWidget = true;
      this.baseWidgetService.loadBaseWidget(this.widgetConfiguration, this.config, this.getWidgetCompleted);
    }
  }

  ngOnDestroy(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
    forEach(this.eventsSubscription, (subscribe) => {
      if (subscribe) {
        subscribe.unsubscribe();
      }
    });
  }


  startAutoIntervalTimer = () => {
    if (this.widgetConfiguration && this.widgetConfiguration.AutoRefreshInterval) {
      this.timer = observableInterval(this.widgetConfiguration.AutoRefreshInterval * 60 * 1000);
      this.timerSubscription = this.timer.subscribe((t) => {
        this.widgetConfiguration.isReloadWidget = true;
        this.baseWidgetService.loadBaseWidget(this.widgetConfiguration, this.config, this.getWidgetCompleted);
      });
    }
  }

  getWidgetCompleted = (response) => {
    this.isLoading = false;
    this.widgetConfiguration.isReloadWidget = false;
    this.data = response ? response : [];
    this.prepareZones();
    switch (this.config.chartType) {
      case 'pie':
        this.populatePieChart();
        break;
      default:
        this.populateDefaultChart();
        break;
    }
    this.baseWidgetService.setWidgetHeights(this.adfModel);
    if (this.config && this.config.params && this.config.params.length) {
      this.config = this.copiedConfig;
    }
  }

  populateDefaultChart() {
    if (this.config.bgColor && !this.config.color) {
      this.config.color = this.colorConversionService.getContrastColor(this.config.bgColor);
    }
    if (this.data.length) {
      const xTitle = Object.keys(this.data[0])[1];
      const yTitle = Object.keys(this.data[0])[0];
      const values = [];
      forEach(this.data, (item) => {
        if (!(parseFloat(item[yTitle]) < 0)) {
          values.push([this.isSplineChart && xTitle.toLowerCase() === 'date' ? Date.parse(item[xTitle]) : item[xTitle], item[yTitle]]);
        }
      });
      this.setChartOptions(values, yTitle, xTitle);

      if (this.isSplineChart) {
        this.chartOptions.plotOptions = {
          spline: {
            marker: {
              enabled: true
            }
          }
        };

        this.chartOptions.tooltip = {
          xDateFormat: '%m/%d/%y'
        };

        this.chartOptions.xAxis['labels'].format = '{value:%m-%d-%Y}';
      }
    }
  }

  private setChartOptions(values: any[], yTitle: string, xTitle: string) {
    this.chartOptions = {
      chart: {
        type: this.isSplineChart ? 'spline' : this.config.chartType,
        backgroundColor: this.config.bgColor,
        style: {
          color: this.config.bgColor ? this.colorConversionService.getContrastColor(this.config.bgColor) : '',
          maxHeight: 400
        },
      },
      title: {
        text: null
      },
      series: [
        {
          type: this.isSplineChart ? 'spline' : this.config.chartType,
          data: values,
          showInLegend: false,
          name: this.dashboardService.splitCamelCaseToString(yTitle),
          zoneAxis: 'x',
          zones: this.zoneData
        }
      ],
      xAxis: {
        type: this.isSplineChart ? 'datetime' : 'category',
        title: {
          text: this.dashboardService.splitCamelCaseToString(xTitle),
          style: {
            color: this.config.color
          }
        },
        labels: {
          style: {
            color: this.config.color
          }
        },
      },
      yAxis: {
        title: {
          text: this.dashboardService.splitCamelCaseToString(yTitle),
          style: {
            color: this.config.color
          }
        },
        allowDecimals: false,
        labels: {
          style: {
            color: this.config.color
          }
        }
      }
    };
  }

  populatePieChart() {
    if (this.data.length) {
      const name = Object.keys(this.data[0])[0];
      const data = [];
      forEach(this.data, (item) => {
        data.push({
          name: item[Object.keys(this.data[0])[1]],
          y: item[Object.keys(this.data[0])[0]]
        });
      });

      if (this.config.bgColor) {
        this.config.color = this.colorConversionService.getContrastColor(this.config.bgColor);
      }

      this.chartOptions = {
        chart: {
          backgroundColor: this.config.bgColor,
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              style: {
                color: this.config.color || 'black'
                // || (Highcharts.theme && Highcharts.theme.contrastTextColor)
              }
            }
          }
        },
        series: [{
          type: 'pie',
          name: this.dashboardService.splitCamelCaseToString(name),
          // colorByPoint: true,
          data: data,
          color: this.config.color
        }]
      };
    }
  }

  prepareZones() {
    forEach(this.data, element => {
      this.zoneData.push({ value: Date.parse(element.Date), color: element.Color });
    });
  }

  editTextWidget() {
    const modalRef = this.modalService.getModalWrapper(WidgetEditComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center modal-lg',
      initialState: {
        widgetConfig: cloneDeep(this.widgetConfiguration),
      }
    });
    modal.close.subscribe((res) => {
      if (res && res.reload) {
        this.widgetConfiguration = res.widgetConfiguration;
        this.widgetConfiguration.isNewWidget = false;
        this.config = res.widgetConfiguration.config;
        this.updateAdfModel();
        this.baseWidgetService.loadBaseWidget(this.widgetConfiguration, this.config, this.getWidgetCompleted);
      } else {
        this.isLoading = false;
        this.deleteWidget()
      }
    });
  }

  updateAdfModel = () => {
    const rowIndex = this.widgetConfiguration.rowIndex > 0 ? this.widgetConfiguration.rowIndex - 1 : 0;
    const columnIndex = this.widgetConfiguration.columnIndex ? this.widgetConfiguration.columnIndex - 1 : 0;
    if (this.adfModel && this.adfModel.rows) {
      if (this.adfModel.rows[rowIndex] && this.adfModel.rows[rowIndex].columns) {
        if (this.adfModel.rows[rowIndex].columns[columnIndex].widgets)
          this.adfModel.rows[rowIndex].columns[columnIndex].widgets[0] = this.widgetConfiguration;
      }
    }
  }

  deleteWidget() {
    if (this.column) {
      const index = this.column.widgets.indexOf(this.widgetConfiguration);
      if (index >= 0) {
        this.column.widgets.splice(index, 1);
        this.dashboardBroadcastingService.widgetDelete(this.widgetConfiguration);
      }
    }
  }
}
