import { Component, OnInit } from '@angular/core';
import { AlertsService, DomainConstants, ICloseable, ModalService, OrderService, SpinnerService, chevronDown, chevronRight, times } from 'src/app/shared';
import { KioskNumpadComponent } from '../kiosk-numpad/kiosk-numpad.component';
import { OrderInvoice } from 'src/app/orders';
import { KioskNavigationService } from '../../services';
import { find, forEach, orderBy } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'pos-kiosk-invoice',
  templateUrl: './kiosk-invoice.component.html',
  styleUrls: ['./kiosk-invoice.component.scss']
})
export class KioskInvoiceComponent implements OnInit {

  order: OrderInvoice;
  orderItemTypes = DomainConstants.OrderItemTypes;
  icons = {
    chevronRight, chevronDown, times
  }
  numpadRef: ICloseable;

  constructor(private kioskNavigationService: KioskNavigationService,
    private orderService: OrderService,
    private alertService: AlertsService,
    private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute,
    private spinnerService: SpinnerService
  ) { }

  ngOnInit(): void {
    this.order = this.kioskNavigationService.orderDetails ?? this.orderService.getNewOrderInvoiceDetails();
    this.order.OrderItems = orderBy(this.order.OrderItems, x => x.SubAccountOrdinal);
    this.displaySizeWithMainProduct();
  }

  displaySizeWithMainProduct = () => {
    forEach(this.order?.OrderItems, (item: any) => {
      const sizeComponent = find(item.OrderProductComponents, (component: any) => component.IsSize);
      if(sizeComponent) {
        item.DisplayText = `${item.ButtonText} (${sizeComponent.ButtonText})`;
      }
    })
  }

  deleteProduct(product) {
    if(this.getItemsCount(product.Id.Value)) {
    this.orderService.deleteOrderProduct(this.order.Id.Value, product.Id.Value)
      .subscribe({
        next: () => {
          if (this.order.Id && this.order.Id.Value > 0 && this.getItemsCount(product.Id.Value)) {
            this.getOrderDetails();
          } else {
            this.startNewOrder();
          }
        }, error: (err) => {
          this.alertService.showApiError(err);
        }
      });
    } else {
      this.cancelOrder();
    }
  }

  cancelOrder() {
    this.spinnerService.show();
    this.orderService.deleteOrder(this.kioskNavigationService.orderDetails.Id.Value)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe({
        next: () => {
          this.startNewOrder();
        }, error: this.alertService.showApiError
      });
  }

  deleteComponent(product, component) {
    if (component.IsSize) {
      this.deleteOrderProductSize(product, component);
    } else {
      this.deleteOrderProductComponent(product, component);
    }
  }

  deleteOrderProductSize(product, component) {
    this.orderService.deleteOrderProductSize(this.order.Id.Value, product.Id.Value, component.Id.Value, component.SizeId)
      .subscribe({
        next: () => {
          if (this.order.Id && this.order.Id.Value > 0) {
            this.getOrderDetails();
          }
        }, error: (err) => {
          this.alertService.showApiError(err);
        }
      });
  }

  getItemsCount(productId: number = null) {
    let orderItemsExcludingFees = this.order.OrderItems.filter(x => x.OrderItemType !== this.orderItemTypes.SingularFee);
    if (productId) {
      orderItemsExcludingFees = orderItemsExcludingFees.filter(x => x.Id.Value !== productId);
    }
    return orderItemsExcludingFees?.length;
  }
  private startNewOrder() {
    this.kioskNavigationService.resetProps();
    this.router.navigate(['../home'], { relativeTo: this.route, state: { startOrderingDirectly: true } });
  }

  getOrderDetails() {
    if (this.order.Id) {
      this.orderService.getOrderDetails(this.order.Id.Value)
        .subscribe({
          next: (response: OrderInvoice) => {
            this.order = response;
            this.order.OrderItems = orderBy(this.order.OrderItems, x => x.SubAccountOrdinal);
            this.kioskNavigationService.orderDetails = this.order;
            this.displaySizeWithMainProduct();
          }, error: this.alertService.showApiError
        });
    }
  }

  deleteOrderProductComponent(product, component) {
    this.orderService.deleteOrderProductComponent(this.order.Id.Value, product.Id.Value, component.Id.Value)
      .subscribe({
        next: () => {
          if (this.order.Id && this.order.Id.Value > 0) {
            this.getOrderDetails();
          }
        }, error: (err) => {
          this.alertService.showApiError(err);
        }
      });
  }

  openNumpad(product) {
    this.numpadRef = this.modalService.show(KioskNumpadComponent, {
      'backdrop': 'static',
      keyboard: false,
      class: 'vertical-center kiosk-numpad',
      initialState: {
        qty: product.Qty,
        heading: `How many ${product.ButtonText}?`
      }
    });
    this.numpadRef.close.subscribe(res => {
      if (res?.qty) {
        product.Qty = res.qty;
        this.updateQty(product);
      }
    });
  }

  updateQty(product) {
    this.orderService.updateQty(this.order.Id.Value, product.Id.Value, product.Qty)
      .subscribe({
        next: () => {
          if (this.order.Id && this.order.Id.Value > 0) {
            this.getOrderDetails();
          }
        }, error: this.alertService.showApiError
      });
  }

}
