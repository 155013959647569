<pos-edit-wrapper [headingIcon]="icons.language" heading="Formats" (close)="cancelEditing()" [isPopup]="false">
    <form (ngSubmit)="saveFormats(formatsForm.valid)" #formatsForm="ngForm" class="edit-form">
        <div class="col-12 pos-page-body border-top">
            <div class="col-12">
                <div>
                    <h3 class="setting-title">Settings</h3>
                </div>
                <pos-row class="px-2">
                    <pos-column>
                        <pos-form-field label="Locale" [validationMessages]="{required: 'Please select locale.'}" inputStyle="col-sm-3 col-12">
                            <ng-select [items]="locales" appendTo="body" bindLabel="name" bindValue="code" posInput
                                placeholder="Select locale" [(ngModel)]="parameters.Locale" name="txtLocale"
                                #txtLocale="ngModel" [required]="true">
                            </ng-select>
                        </pos-form-field>
                    </pos-column>

                    <pos-column>
                        <pos-form-field label="Currency" inputStyle="col-sm-5 col-12">
                            <ng-select [items]="currencies" appendTo="body" bindLabel="currency" bindValue="code"
                                [(ngModel)]="parameters.Currency" name="txtCurrency" #txtCurrency="ngModel" id="txtCurrency" posInput
                                placeholder="Select currency" >
                            </ng-select>
                        </pos-form-field>
                    </pos-column>

                    <pos-column>
                        <pos-form-field label="Date" inputStyle="col-sm-3 col-12">
                            <input class="form-control" name="txtDateFormat" [(ngModel)]="parameters.DateFormat" posInput
                                type="text" #txtDateFormat="ngModel"/>
                        </pos-form-field>
                    </pos-column>
                    <pos-column>
                        <pos-form-field label="Time" inputStyle="col-sm-3 col-6">
                            <ng-select [items]="timeFormats" appendTo="body" bindLabel="Name" bindValue="Value" posInput
                                placeholder="Select time format" [(ngModel)]="parameters.TimeFormat"
                                name="txtTimeFormat" #txtTimeFormat="ngModel" [clearable]="false"
                                [clearOnBackspace]="false">
                            </ng-select>
                        </pos-form-field>
                    </pos-column>
                </pos-row>
            </div>
        </div>
        <div class="col-12 action-button-container page-footer">
            <button class="btn btn-primary btn-lg normal btn-save pos-button" scrollup type="submit"
                value="Save">Save</button>
            <button class="btn btn-secondary btn-lg normal btn-cancel pos-button" routerLink="../" scrollup type="button"
                value="Cancel">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>

