import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Instruction } from '../../../../instruction-terminal/interface/instruction';
import { RuntimeConstants, ModalComponent, SettingParam, ApplicationStateService, ColorUtilityService } from '../../../../shared';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OrderCardService } from 'src/app/shared/services/order-card.service';
declare let $: any;
@Component({
  selector: 'pos-instruction',
  templateUrl: './instruction.component.html',
  styleUrls: ['./instruction.component.scss']
})
export class InstructionComponent extends ModalComponent implements OnInit {

  constructor(
    private applicationStateService: ApplicationStateService,
    private colorUtilityService: ColorUtilityService,
    private orderCardService: OrderCardService,
    modalRef: BsModalRef
  ) {
    super(modalRef);
  }

  @ViewChild('instructionTerminal', { static: true }) myDiv: ElementRef;
  public imagePath = RuntimeConstants.IMAGE_BASE_PATH;
  @Input()
  public instruction: Instruction;

  @Input()
  public countInstructions: number;

  @Input()
  public isPopup: boolean = false;

  @Output()
  public removeProduct: EventEmitter<number> = new EventEmitter<number>();
  settingParam: SettingParam;
  qtyFontColor: string;
  qtyBgColor: string;

  ngOnInit() {
    this.settingParam = this.applicationStateService.settingParam;
    this.calculateInstructionHeight(this.instruction);
    this.resizeInstructions(this.instruction);
    this.setQuantityColor();
  }
  previousInstructionsCount: number;

  ngDoCheck() {
    this.setResize();
  }

  ngAfterViewInit() {
    const resizeTimeout = setTimeout(() => {
      this.resizeInstructions(this.instruction);
      clearTimeout(resizeTimeout);
    });
  }

  setResize() {
    let currentInstructionsCount = this.countInstructions;
    if (this.previousInstructionsCount !== currentInstructionsCount) {
      if (this.countInstructions > 0) {
        this.resizeInstructions(this.instruction);
      }
      this.previousInstructionsCount = currentInstructionsCount;
    }
  }

  removeInstruction(orderProductId) {
    this.removeProduct.emit(orderProductId);
  }

  resizeInstructions(instruction) {
    //resize instruction header
    this.resizeInstructionHeader(instruction.OrderProductId);

    //resize instruction texts
    this.resizeInstruction(instruction.OrderId, instruction.InstructionAreas);

    //resize exceptional instruction texts
    this.resizeExceptionalInstruction(instruction.OrderId, instruction.ExceptionalinstructionArea.SalesProductIngredients);
  }

  calculateInstructionHeight(response) {
    let instHeight = 0;
    let excHeight = 0;

    let countInsIngredients = response.InstructionAreas.reduce(function (total, area) {
      return total + area.SalesProductIngredients.length;
    }, 0);

    let countExceInsIngredients = response.ExceptionalinstructionArea.SalesProductIngredients.length;

    if (countInsIngredients > 0 && countExceInsIngredients > 0) {
      instHeight = 100 * countInsIngredients / (countInsIngredients + countExceInsIngredients);
      excHeight = 100 * countExceInsIngredients / (countInsIngredients + countExceInsIngredients);
    }
    if (countExceInsIngredients === 0)
      instHeight = 100;
    if (countInsIngredients === 0)
      excHeight = 100;

    response.InstructionAreas.height = (instHeight - 6) + '%';
    response.ExceptionalinstructionArea.height = excHeight + '%';
    response.InstructionAreas.totalIngredients = countInsIngredients;
    response.ExceptionalinstructionArea.totalExecIngredients = countExceInsIngredients;
  }

  resizeInstructionHeader(orderProductId) {
    //ingredient Text and outer container div Ids
    let outerContainer = $("#" + orderProductId);

    let textContainer = $("#" + orderProductId + "div");

    //set ingredient text again in case of lower font-size
    //as there might be more free space this time (ie. any instruction removed)
    //so we might get bigger font-size this time by resizeToFit
    if (parseFloat(textContainer.css("font-size")) < parseFloat(outerContainer.css("font-size"))) {
      textContainer.css("fontSize", parseFloat(outerContainer.css("font-size")));
    }

    //resize text to fit in the div
    this.resizeToFit(orderProductId);
  }

  resizeInstruction(orderId, areas) {
    areas.forEach(area => {
      for (let i = 0; i < area.SalesProductIngredients.length; i++) {

        //ingredient Text and outer container div Ids
        let id = 'i_' + orderId + '_' + area.AreaId + '_' + i;
        let outerContainer = $('#' + id);
        // let outerContainer =this.myDiv.nativeElement(id)
        let textContainer = $('#' + id + ' div');

        //set ingredient text again in case of lower font-size 
        //as there might be more free space this time (ie. any instruction removed)
        //so we might get bigger font-size this time by resizeToFit
        if (parseFloat(textContainer.css('font-size')) < parseFloat(outerContainer.css('font-size'))) {
          textContainer.text(area.SalesProductIngredients[i].Text);
          textContainer.css('fontSize', parseFloat(outerContainer.css('font-size')));
        }

        //resize text to fit in the div
        this.resizeToFit(id);
      }
    });
  }

  resizeExceptionalInstruction(orderId, ingredients) {
    for (let i = 0; i < ingredients.length; i++) {

      //ingredient Text and outer container div Ids
      let id = 'e_' + orderId + '_' + i;
      let outerContainer = $('#' + id);
      let textContainer = $('#' + id + ' div');

      //set ingredient text again in case of lower font-size 
      //as there might be more free space this time (ie. any instruction removed)
      //so we might get bigger font-size this time by resizeToFit
      if (parseFloat(textContainer.css('font-size')) < parseFloat(outerContainer.css('font-size'))) {
        textContainer.text(ingredients[i].Text);
        textContainer.css('fontSize', parseFloat(outerContainer.css('font-size')));
      }

      //resize text to fit in the div
      this.resizeToFit(id);
    }
  }

  onCancel() {
    this.hide({});
  }

  resizeToFit(id) {
    let minFontSize = 8;
    let outerContainer = $('#' + id);
    let textContainer = $('#' + id + ' div');

    let fontsize = textContainer.css('font-size');

    //decrease font size
    if (textContainer.height() >= outerContainer.height() && parseFloat(fontsize) > minFontSize) {
      textContainer.css('fontSize', parseFloat(fontsize) - 1);
      this.resizeToFit(id);
    }
    //trim text after min font size
    else if (textContainer.height() >= outerContainer.height() && parseFloat(fontsize) <= minFontSize) {
      let temp = textContainer.text();

      while (outerContainer.height() < textContainer.height() && temp.length > 3) {
        textContainer.text(temp = temp.substr(0, temp.length - 1));
      }
      if (temp.length > 6) {
        textContainer.text(temp.substr(0, temp.length - 3));
      }
      textContainer.append('...');
    }
  }

  setQuantityColor() {
    this.qtyBgColor = this.orderCardService.getBackGroundColor(this.instruction.Quantity,this.settingParam.MakeTableQuantityColors);
    this.qtyFontColor = this.colorUtilityService.getContrastColor(this.qtyBgColor);
  }
}
