import { Component, OnInit, EventEmitter } from '@angular/core';
import { ICloseable, ApplicationStateService } from 'src/app/shared';
import { OrderDietaryWarningModel } from '../../interface';
import * as _ from 'lodash';
import { checkCircleSolid } from 'src/app/shared/components/icon';

@Component({
  selector: 'pos-order-dietary-restriction',
  templateUrl: './order-dietary-restriction.component.html',
  styleUrls: ['./order-dietary-restriction.component.scss']
})
export class OrderDietaryRestrictionComponent implements OnInit, ICloseable {

  close: EventEmitter<any> = new EventEmitter();
  orderDietaryRestrictions: Array<OrderDietaryWarningModel> = [];
  originalOrderDietaryRestrictions: Array<OrderDietaryWarningModel> = [];
  allowOverridesOnDietaryRestrictions: boolean;
  isAllWarningOverrided = false;
  constructor(private applicationStateService: ApplicationStateService) { }
  public icons = {
    checkCircleSolid
  }
  ngOnInit() {
    this.originalOrderDietaryRestrictions = _.cloneDeep(this.orderDietaryRestrictions);
    this.allowOverridesOnDietaryRestrictions = this.applicationStateService.settingParam.AllowOverridesOnDietaryRestrictions;
    this.prepareDietaryRestrictions();
  }

  prepareDietaryRestrictions() {
    this.orderDietaryRestrictions.forEach(restriction => {
      _.orderBy(restriction.Components, x => x.InventoryProduct);
      restriction.GroupedComponents = _.groupBy(restriction.Components, c => c.SalesProduct);
      const [mainProduct, products] = _.partition(restriction.GroupedComponents, ([key, value]) => key.SalesProduct.startsWith(restriction.Product));
      restriction.GroupedComponents = [...mainProduct, ...products];
    });
  }

  onSubmit() {
    var filterOrderDietaryRestrictions = _.flatMap(this.orderDietaryRestrictions.map(x => x.Components)).filter((orderDietaryRestriction) => {
      return !orderDietaryRestriction.IsAdded;
    });
    this.close.emit({ shouldReload: true, orderDietaryRestrictions: filterOrderDietaryRestrictions });

  }

  checkIfAllWarningsAreOverridden() {
    this.isAllWarningOverrided = _.flatMap(this.orderDietaryRestrictions.map(x => x.Components)).every(x => (x.IsOverridable || x.DietaryWarningTypeId == 2) && x.IsAdded);
  }


  onClose() {
    this.close.emit({ shouldReload: false, orderDietaryRestrictions: this.originalOrderDietaryRestrictions });
  }
}
