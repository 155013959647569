import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { InputAttributes } from '../models/InputAttributes';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit, AfterViewInit {

  private customAttributes = [];
  public knownAttributes = {
    name: null,
    caption: null
  } as InputAttributes;

  @ViewChild('inputElement') private inputElement;


  constructor(private el: ElementRef) {}

  ngOnInit() {
    const attrs = this.el.nativeElement.attributes;

    if (attrs && attrs.length) {
      for (const attr of attrs) {
        if (Object.prototype.hasOwnProperty.call(this.knownAttributes, attr.name)) {
          this.knownAttributes[attr.name] = attr.value;
        } else {
          this.customAttributes.push({name: attr.name, value: attr.value});
        }
      }
    }

    console.dir(this.knownAttributes);
  }

  ngAfterViewInit() {
    this.customAttributes.forEach(attr => {
      this.inputElement.nativeElement.setAttribute(attr.name, attr.value);
    });
  }
}
