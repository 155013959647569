import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ComponentsModule, TableConfiguration } from 'src/app/shared';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TableModule as PrimengTableModule } from 'primeng/table';
import { TableModule } from '@tarktech/tark-ng-utils';
import { CheckboxModule } from 'primeng/checkbox';
import { InventoryProductEditModelComponent } from './inventory-product-edit-model.component';
import { InventoryProductEditComponent } from '../inventory-product-edit/inventory-product-edit.component';
import { CalendarModule } from 'primeng/calendar';
import { InventoryProductRawIngredientsComponent } from 'src/app/information-management/inventory-product-ingredients';
import { InventoryProductNutritionComponent, InventoryProductServingEditComponent } from 'src/app/information-management/nutrition-facts';
import { InventoryProductDietaryWarningComponent } from 'src/app/information-management/dietary-warnings';
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ManageBarcodeComponent } from '../manage-barcode';
import { InventoryProductAliasComponent } from '../inventory-product-alias';

@NgModule({
  imports: [
    CommonModule,
    
    NgSelectModule,
    FormsModule,
    ModalModule.forRoot(),
    ComponentsModule,
    TooltipModule.forRoot(),
    CheckboxModule,
    TableModule.forRoot(TableConfiguration),
    CalendarModule,
    DropdownModule,
    AutoCompleteModule,
    PrimengTableModule
  ],
  declarations: [InventoryProductEditModelComponent, InventoryProductEditComponent, InventoryProductRawIngredientsComponent,
    InventoryProductServingEditComponent, InventoryProductNutritionComponent, InventoryProductDietaryWarningComponent,
    ManageBarcodeComponent, InventoryProductAliasComponent],
  exports: [InventoryProductEditModelComponent, InventoryProductEditComponent, InventoryProductRawIngredientsComponent,
    InventoryProductServingEditComponent, InventoryProductNutritionComponent, InventoryProductDietaryWarningComponent]
})
export class InventoryProductEditModelModule { }
