import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dataFilter' })
export class DataFilterComponent implements PipeTransform {
    transform(value: any[], filter: Object): any[] {
        if (!value || value.length === 0) {
            return [];
        }
        if (!filter) {
            return value;
        }
        const keys = Object.keys(value[0]);
        const filterKey = Object.keys(filter);
        if (!filterKey) {
            return value;
        }
        return value.filter(val => keys.some(key => {
            const currentValue = val[filterKey[0]];
            if (currentValue) {
                return String(currentValue).toLowerCase().includes(String(filter[filterKey[0]]).toLowerCase());
            }
            return false;
        }));
    }

}
