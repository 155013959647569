import { Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GridColumn, TableComponent, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { forEach } from 'lodash';
import { InventoryOrderReceivingProductList } from 'src/app/shared/interface';
import Pluralize from 'pluralize';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { ICloseable } from 'src/app/shared/components/modal/ICloseable';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { InventoryReceivingWarningComponent } from '../inventory-receiving-warning/inventory-receiving-warning.component';

@Component({
  selector: 'pos-confirm-inventory-receiving',
  templateUrl: './confirm-inventory-receiving.component.html',
  styleUrls: ['./confirm-inventory-receiving.component.scss']
})
export class ConfirmInventoryReceivingComponent implements OnInit, ICloseable, OnDestroy {

  @Input() productList: Array<InventoryOrderReceivingProductList> = [];
  @Input() location: string;
  @ViewChild('confirmReceiving', { static: true }) confirmReceiving: TableComponent;
  confirmReceivingColumns: Array<GridColumn> = [];
  @ViewChild('qtyReceivedTemplate', { static: true }) private qtyReceivedTemplate: any;
  close: EventEmitter<any> = new EventEmitter();
  isConfirmDisable = true;
  isTimeOutOver = false;
  warningMessage: string;
  confirmDelay: number;
  confirmDelayTimeout: ReturnType<typeof setTimeout>;

  constructor(private applicationStateService: ApplicationStateService, private alertService: AlertsService,
    private modalService: ModalService) { }
  ngOnDestroy(): void {
    if (this.confirmDelayTimeout) {
      clearTimeout(this.confirmDelayTimeout);
    }
  }

  ngOnInit(): void {
    forEach(this.productList, (product) => {
      product.Qty = product.QtyReceived * (product.UnitQtyOverride ? product.UnitQtyOverride : product.UnitQty);
      product.inventoryUnit = product.Qty ? Pluralize(product.inventoryUnit, product.Qty) : product.inventoryUnit;
    });
    this.confirmDelay = this.applicationStateService.settingParam.InventoryReceivingConfirmationWaitTime;
    this.warningMessage = this.applicationStateService.settingParam.InventoryReceivingConfirmationWarningText;
    this.configureGridColumns();
    this.confirmationDelay();
  }

  confirmationDelay() {
    if (this.confirmDelay) {
      if (this.warningMessage && this.warningMessage.length) {
        this.isConfirmDisable = false;
        this.confirmTimeOut();
      } else {
        this.confirmTimeOut();
      }
    } else {
      this.isConfirmDisable = false;
    }
  }

  confirmTimeOut() {
    if (this.confirmDelayTimeout) {
      clearTimeout(this.confirmDelayTimeout);
    }
    this.confirmDelayTimeout = setTimeout(() => {
      this.isConfirmDisable = false;
      this.isTimeOutOver = true;
    }, (this.confirmDelay * 1000));
  }

  configureGridColumns() {
    const qtyReceivedTemp = new TemplateColumn({
      HeaderText: 'Qty',
      Field: 'qty',
      IsSortable: false,
      Width: '100px',
      itemTemplate: this.qtyReceivedTemplate,
      TextAlign: TextAlign.Right
    });

    this.confirmReceivingColumns = [
      new GridColumn({ HeaderText: 'Product', Field: 'ProductName', IsSortable: false }),
      // new GridColumn({ HeaderText: 'Qty', Field: 'qty', IsSortable: false, Width: '100px' }),
      qtyReceivedTemp,
      new GridColumn({ HeaderText: 'Unit(s)', Field: 'inventoryUnit', IsSortable: false, Width: '150px' })
    ];
  }

  onConfirm = () => {
    if (!this.isTimeOutOver && this.confirmDelay) {
      this.warningMessageModel();
    } else {
      this.close.emit({ shouldConfirm: true });
    }
  }

  warningMessageModel() {
    const modalRef = this.modalService.getModalWrapper(InventoryReceivingWarningComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      keyboard: false,
      initialState: {
        message: this.warningMessage
      }
    });
    modal.close.subscribe(() => {
      this.isTimeOutOver = false;
      this.confirmTimeOut();
    });
  }

  onCancel = () => {
    this.close.emit();
  }

}
