import { Component, OnInit, ViewChild } from '@angular/core';
import { User, ResetPasswordType } from '../../interface/user';
import { informationManagementUser, userMoneyCheckAlt, users } from 'src/app/shared/components/icon';
import { AbstractControl, NgForm, ValidationErrors } from '@angular/forms';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Messages, ConfirmDeleteComponent, EventBroadcastingService, UserService, ModalBackdropService, BaseFormComponent, FormUtilityService } from 'src/app/shared';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PermissionRoles } from 'src/app/information-management/security-roles/interface/permission-roles';
import { SecurityRoleService } from 'src/app/information-management/security-roles/service/security-role.service';
import { ActivatedRoute, Router } from '@angular/router';
declare let $: any;

@Component({
    selector: 'pos-user-edit',
    templateUrl: './user-edit.component.html',
    styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent extends BaseFormComponent implements OnInit {
    get getForm(): NgForm {
        return this.form
    }
    userData: User;
    user: User;
    userName: string;
    assignedUserWithSameBadgeNumber: User;
    userRoleList: PermissionRoles[] = [];
    selectedUserRole: Array<number> = [];
    icons = {
        informationManagementUser,
        userMoneyCheckAlt,
        users
    };
    @ViewChild('userForm', { static: true }) form: NgForm;
    @ViewChild('confirmBadge') private confirmBadge: ConfirmDeleteComponent;
    @ViewChild('resetPasswordPopup') public resetPasswordPopup: any;
    isResetPasswordDisplay = false;
    userId: number;
    atEditChkSystemUser: boolean;
    resetPasswordType = '';
    confirmPassword = '';
    userCodePassword = '';
    modalRef: BsModalRef;
    tabList = {
        Account: 'Account',
        PayRates: 'PayRates'
    };
    selectedTab = this.tabList.Account;
    props = {
        controlBoxClass: 'col-lg-5 col-md-6 col-sm-10 col-xs-12'
    }
    passwordType = ResetPasswordType;
    isSaveAndContinue = false;
    constructor(
        private userService: UserService,
        private securityRoleService: SecurityRoleService,
        private alertService: AlertsService,
        private spinnerService: SpinnerService,
        private route: ActivatedRoute,
        private router: Router,
        private modalService: BsModalService,
        private modalBackdropService: ModalBackdropService,
        private eventBroadcastingService: EventBroadcastingService,
        formUtilityService: FormUtilityService
    ) {
        super(formUtilityService);
        this.userId = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
        const navigation = this.router.getCurrentNavigation();
        this.userName = (navigation?.extras?.state && navigation?.extras?.state.userName) ?? '';
    }
    ngOnInit() {
        this.userData = this.userService.newUser();
        this.loadData();
        this.setInitialValue();
        this.scrollToTop();
    }
    private loadData() {
        this.spinnerService.show();
        const userObservable = [];
        userObservable.push(this.securityRoleService.getSecurityRoles(true));
        if (this.userId > 0) {
            this.atEditChkSystemUser = true;
            userObservable.push(this.userService.getUserData(this.userId));
        } else {
            this.atEditChkSystemUser = false;
        }
        forkJoin(userObservable)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (responses: any) => {
                    this.userRoleList = responses[0];
                    if (responses.length > 1) {
                        this.userData = responses[1];
                        this.userName = this.userData.username;
                        if (this.userData.IsAllowExternalLogin === true && this.userData.Password !== '' &&
                            this.userData.Password !== null && this.userData.id > 0) {
                            this.isResetPasswordDisplay = true;
                        }
                        if (this.userData.Roles && this.userData.Roles.length) {
                            const userRole = [];
                            this.userData.Roles.forEach((role) => {
                                userRole.push(role.RoleId);
                            });
                            this.selectedUserRole = [...userRole];
                        }
                    }
                }, error: this.alertService.showApiError
            });
    }
    submit(isValid: boolean) {
        if (!isValid) {
            return;
        }
        if (this.userId > 0) {
            if (!this.userData.IsAllowExternalLogin) {
                this.userData.Password = '';
            }
        }
        this.setUserDetails();
        if (this.user.BadgeNumber != null) {
            this.spinnerService.show();
            this.userService.getUsersList(true)
                .pipe(finalize(() => {
                    this.spinnerService.hide();
                }))
                .subscribe({
                    next: (userList: User[]) => {
                        this.badgeNumberCheckPoint(userList);
                    }, error: this.alertService.showApiError
                });
        } else {
            this.onSaveUser();
        }
    }
    private badgeNumberCheckPoint(userList: User[]) {
        let sameUser = false;
        let confirmMessage = '';
        this.assignedUserWithSameBadgeNumber = userList.find(u => u.BadgeNumber === this.user.BadgeNumber);
        if (this.assignedUserWithSameBadgeNumber) {
            if (this.assignedUserWithSameBadgeNumber.active) {
                confirmMessage = 'This badge is assigned to <span class=\"label label-success\">' +
                    this.assignedUserWithSameBadgeNumber.firstname + ',' + this.assignedUserWithSameBadgeNumber.lastname + '</span>.' +
                    ' move to <span class=\"label label-info\">' + this.user.firstname + ',' + this.user.lastname + '</span>?';
            } else {
                confirmMessage = 'This badge is assigned to <span class=\"label label-danger\">' +
                    this.assignedUserWithSameBadgeNumber.firstname + ',' + this.assignedUserWithSameBadgeNumber.lastname + '</span>.' +
                    ' move to <span class=\"label label-info\">' + this.user.firstname + ',' + this.user.lastname + '</span>?';
            }
            sameUser = this.user.id === this.assignedUserWithSameBadgeNumber.id ? true : false;
            this.assignedUserWithSameBadgeNumber.BadgeNumber = null;
        }
        if (this.assignedUserWithSameBadgeNumber && !sameUser) {
            this.confirmBadge.Show(confirmMessage);
        } else {
            this.onSaveUser();
        }
    }
    onConfirmAcknowledge() {
        if (this.user.IsAllowExternalLogin && !this.userData.Password) {
            return;
        }
        this.spinnerService.show();
        this.userService.updateUser(this.assignedUserWithSameBadgeNumber)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: () => {
                    this.onSaveUser();
                }, error: this.alertService.showApiError
            });
    }
    onCancelAcknowledge() {
        this.userData.BadgeNumber = '';
    }
    private onSaveUser() {
        if (this.user.IsAllowExternalLogin && !this.userData.Password) {
            return;
        }
        this.spinnerService.show();
        this.setRoles();
        if (this.userId > 0) {
            this.updateUser();
        } else {
            this.saveUser();
        }
    }
    private saveUser() {
        this.userService.addUser(this.user)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (res) => {
                    this.user.id = res.Id;
                    if (this.isSaveAndContinue) {
                        this.alertService.renderSuccessMessage(Messages.UserSaveSuccess);
                        this.redirectToUserEdit();
                    } else this.reset();
                }, error: this.alertService.showApiError
            });
    }
    private updateUser() {
        this.userService.updateUser(this.user)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: () => {
                    this.reset();
                }, error: this.alertService.showApiError
            });
    }

    setRoles() {
        this.user.Roles = [];
        this.selectedUserRole.forEach((roleId) => {
            this.user.Roles.push({
                UserId: this.userId,
                RoleId: roleId,
                UserName: null,
                RoleName: null
            });
        });
    }

    private reset() {
        this.userId = 0;
        this.resetPasswordType = '';
        this.alertService.renderSuccessMessage(Messages.UserSaveSuccess);
        this.cancel();
    }
    private setUserDetails() {
        this.user = this.userData;
        this.user.id = this.userId;
        this.user.BadgeNumber = this.userData.BadgeNumber === '' ? null : this.userData.BadgeNumber;
    }
    savePassword(savePasswordForm: NgForm) {
        if (!savePasswordForm.valid) {
            return;
        }
        if (this.resetPasswordType === ResetPasswordType.ExternalPassword) {
            this.updateExternalPassword(savePasswordForm);
        } else if (this.resetPasswordType === ResetPasswordType.UserCode) {
            this.updateUserCode(savePasswordForm);
        }
    }
    private updateUserCode(savePasswordForm: NgForm) {
        this.spinnerService.show();
        const user = {
            id: this.userId,
            code: this.userCodePassword
        };
        this.userService.updateUserCode(user)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: () => {
                    this.alertService.renderSuccessMessage(Messages.UserCodeResetSuccess);
                    this.resetPasswordType = '';
                    this.userData.code = this.userCodePassword;
                    this.closePasswordDialog(savePasswordForm);
                }, error: this.alertService.showApiError
            });
    }
    private updateExternalPassword(savePasswordForm: NgForm) {
        this.spinnerService.show();
        const user = {
            id: this.userId,
            IsAllowExternalLogin: this.userData.IsAllowExternalLogin,
            Password: this.userCodePassword
        };
        this.userData.Password = this.userCodePassword;
        this.userService.updateUserPassword(user)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: () => {
                    this.alertService.renderSuccessMessage(Messages.PasswordResetSuccess);
                    this.resetPasswordType = '';
                    this.userData.Password = this.userCodePassword;
                    this.closePasswordDialog(savePasswordForm);
                }, error: this.alertService.showApiError
            });
    }
    getHeight(isOuterContainer: boolean) {
        if (isOuterContainer) {
            return $(window).height() - 300 + 'px';
        } else {
            return $(window).height() - 310 + 'px';
        }
    }
    checkEnter(event: any) {
        if (event.keyCode === 13) {
            event.preventDefault();
        } else {
            return ((event.charCode > 64 && event.charCode < 91) ||
                (event.charCode > 96 && event.charCode < 123) ||
                event.charCode === 8 ||
                (event.charCode >= 48 && event.charCode <= 57));
        }
    }
    userSwipeBroadCast(enabled: boolean) {
        this.eventBroadcastingService.changeCardBasedSignIn(enabled);
    }
    allowExternalLoginChanged() {
        if (!this.userId && !this.userData.IsAllowExternalLogin) {
            this.userData.Password = '';
        }
    }
    resetPassword(resetPasswordType: string) {
        this.modalRef = this.modalService.show(this.resetPasswordPopup, {
            'backdrop': 'static',
            'class': 'vertical-center',
            keyboard: false
        });
        this.modalBackdropService.addBackDrop();
        this.confirmPassword = '';
        this.userCodePassword = '';
        this.resetPasswordType = resetPasswordType;
    }
    closePasswordDialog(savePasswordForm: NgForm) {
        this.modalRef.hide();
        this.modalBackdropService.removeBackdrop();
    }
    cancel() {
        this.router.navigate(['users'], { relativeTo: this.route.parent });
    }
    preventSpecialCharacter(event) {
        return ((event.charCode > 64 && event.charCode < 91) ||
            (event.charCode > 96 && event.charCode < 123) ||
            event.charCode === 8 ||
            (event.charCode >= 48 && event.charCode <= 57));
    }
    private scrollToTop(): void {
        window.scrollTo(0, 0);
    }

    cancelEditing() {
        this.router.navigate(['../'], { relativeTo: this.route });
    }

    validateUserEdit = (control: AbstractControl): ValidationErrors | null => {
        const loginCode = control.get('loginCode');
        if (loginCode?.value) {
            const checkPass = loginCode.value?.length < 4;
            if (checkPass) {
                loginCode.setErrors({ invalidLoginCode: true })
            } else {
                loginCode.setErrors(null);
            }
        }
        return null;
    }

    validateUserPassword = (control: AbstractControl): ValidationErrors | null => {
        const password = control.get('password');
        const confirmPassword = control.get('confirmPasswordInput');

        if (password?.value) {
            const checkPass = password.value?.length < 4;
            if (checkPass) {
                password.setErrors({ invalidPassword: true })
            } else {
                password.setErrors(null);
            }
        }
        if (confirmPassword?.value && password?.value) {
            const checkPass = password.value != confirmPassword.value;
            if (checkPass) {
                confirmPassword.setErrors({ invalidConfirmPassword: true })
            } else {
                confirmPassword.setErrors(null);
            }
        }
        return null;
    }

    redirectToUserEdit() {
        this.isSaveAndContinue = false;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['users', this.user.id], {
            state: {
                userName: this.user.username
            },
            relativeTo: this.route.parent
        });
        this.router.onSameUrlNavigation = 'ignore';
    }
}
