import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IconSpecification } from '../icon';

@Component({
  selector: 'pos-list-wrapper',
  templateUrl: './list-wrapper.component.html'
})
export class ListWrapperComponent {
  @Input() headingIcon: IconSpecification;
  @Input() heading: string;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  closeList() {
    this.close.next({shouldReload: false});
  }
}
