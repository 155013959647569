<!-- <pos-kiosk-message-strip [messageText]="displayText"></pos-kiosk-message-strip> -->
<div class=" row mx-0 d-flex" (click)="redirectToHome({ startOrderingDirectly: true })"
    [style.align-items]="style.VerticalAlignment">
    <div class="col-md-12 col-12 kiosk-background d-flex"
        [style.background-image]="image ? 'url(\'' + baseImagePath + image + '\')' : null"></div>
    <div class="col-md-12 col-12 order-details d-flex"
        [ngStyle]="{'top': style.VerticalPosition + 'px',
            'color': style.FontColor ? style.FontColor : '',
            'background-color': style.BackgroundColor ? style.BackgroundColor : ''
        }">
        <div class="col-lg-12 col-12">
            <div class="col-lg-12 col-12 primary-info">
                Order #{{orderNumber}} Confirmed
            </div>
            <div class="col-lg-12 col-12 secondary-info" [innerHTML]="orderAppreciationText"></div>
        </div>
    </div>
</div>
