<pos-edit-wrapper heading="Design Button" (close)="cancel()">
    <form #designButtonForm="ngForm" (ngSubmit)="save()" autocomplete="off">
        <div class="col-12 pos-modal-body">
            <pos-row>
                <pos-column>
                    <pos-form-field label="Icon" inputStyle="col-12">
                        <pos-row>
                            <pos-column md="8" xs="9">
                                <input posInput type="file" id="iconInput" name="iconInput"
                                    class="form-control" accept=".png,.jpg,.jpeg,.gif,.bmp" ngModel
                                    (change)="buttonDesignConfig.Icon = handleFileInput($event.target.files, 'iconPreview', 'iconInput')" />
                            </pos-column>
                            <pos-column md="1" xs="3" class=" p-0 col-50 mt-pix-n1" [hidden]="!buttonDesignConfig.Icon">
                                <img #iconPreview id="iconPreview" alt="" class="col-40" height="40"
                                    src="{{imageRootPath}}{{buttonDesignConfig.Icon}}" />
                                <div class="relative mt-pix-n7 float-right"
                                    (click)="removeIcon('iconInput'); buttonDesignConfig.Icon = null;">
                                    <pos-icon [icon]="icons.times"
                                        class="pointer ms-pix-n7 remove-icon-badge"></pos-icon>
                                </div>
                            </pos-column>
                        </pos-row>
                    </pos-form-field>
                </pos-column>
                <pos-column sm="6" xs="12">
                    <pos-form-field label="Icon Background">
                        <pos-color-picker posInput name="iconBackground"
                            [(ngModel)]="buttonDesignConfig.IconBackground"></pos-color-picker>
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save</button>
            <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button"
                (click)="cancel()">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
