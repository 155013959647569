<div class="col-12 p-0" [ngStyle]="{'border': config.borderColor ? 'solid 2px ' + config.borderColor:''}"
    [ngClass]="isInteractive ? '' : 'radius-25'">
    <div *ngIf=!config.url class="col-md-12 p-pix-10">
        <div class="alert alert-info mb-0">Please insert a url in the widget configuration</div>
    </div>
    <div class="col-md-12 p-0" [ngClass]="isInteractive ? '' : 'radius-25'">
        <iframe *ngIf="config.url" [ngStyle]="{'height': config.height, 'width': 'inherit'}"
            [ngClass]="isInteractive ? '' : 'radius-25'" [src]="url" title="iframe-widget"></iframe>
    </div>
</div>
