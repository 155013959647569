import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  Input
} from '@angular/core';
import { NgForm } from '@angular/forms';

import { ModalFormComponent } from 'src/app/shared/components';
import { TaskService } from 'src/app/shared/services/task.service';
import { AlertsService, SpinnerService, ModalService, FormUtilityService } from 'src/app/shared';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { Task } from '../../interface/task';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TaskScheduleComponent } from '../task-schedule/task-schedule.component';

@Component({
  selector: 'pos-task-edit',
  templateUrl: './task-edit.component.html',
  styleUrls: ['./task-edit.component.scss']
})
export class TaskEditComponent extends ModalFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.form
  }
  @ViewChild('taskForm', {static: true}) form: NgForm;
  @Output('uploadTask') uploadTask: EventEmitter<any> = new EventEmitter();

  @Input('data') data = false;

  public isScheduleAdd: boolean = false;
  public taskData: Task;

  toDo = true;
  completed = true;

  @Input()
  public taskId = 0;

  constructor(
    modalRef: BsModalRef,
    private taskService: TaskService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    modalService: ModalService,
    formUtilityService: FormUtilityService
  ) {
    super(modalRef, modalService,formUtilityService);
    this.getTaskDetails(this.taskId);
  }
  public ngOnInit() {
    this.isScheduleAdd = false;
    this.getTaskDetails(this.taskId);
    this.setInitialValue();
  }

  public getTaskDetails(id: number) {
    if (id > 0) {
      this.spinnerService.show();
      this.taskService.getTask(id).subscribe(
        response => {
          this.taskData = response;
        },
        this.alertService.showApiError,
        () => {
          this.spinnerService.hide();
        }
      );
    } else {
      this.taskData = this.taskService.newTask();
    }
  }

  public saveTask(isValid: boolean) {
    if (!isValid) {
      return;
    }
    if (this.taskId > 0) {
      this.spinnerService.show();
      this.taskService.updateTaskDetail(this.taskId, this.taskData).subscribe(
        () => {
          this.alertService.renderSuccessMessage(Messages.TaskUpdateSuccess);
          this.uploadTask.emit({ id: this.taskId, taskname: this.taskData.taskname, shouldReload: true });
          this.cancelEditing({ id: this.taskId, taskname: this.taskData.taskname, shouldReload: true });
        },
        this.alertService.showApiError,
        () => {
          this.spinnerService.hide();
        }
      );
    } else {
      this.addTask(false);
    }
  }

  public addTask(isShowSchedule) {
    this.spinnerService.show();
    this.taskService.addTaskDetail(this.taskData).subscribe(
      (response) => {
        this.uploadTask.emit({ id: response.id, taskname: this.taskData.taskname, shouldReload: true });
        this.alertService.renderSuccessMessage(Messages.TaskSaveSuccess);
        if(isShowSchedule) {
          this.taskData = response;
          this.showScheduleModel(response.id);
        }
        else {
          this.cancelEditing({ id: response.id, taskname: this.taskData.taskname, shouldReload: true });
        }
        
      },
      this.alertService.showApiError,
      () => {
        this.spinnerService.hide();
      }
    );

  }
  public showScheduleModel(id) {
    if (id > 0) {
      this.spinnerService.show();
      const modal = this.modalService.getModalWrapper(TaskScheduleComponent);
      const modalReference  = modal.show({
        animated: false,
        class: 'modal-lg vertical-center task-schedule-modal',
        initialState: {
          editTaskId: id
        }
      });
    } else if(this.taskData && this.taskData.taskname.trim()) {
      this.addTask(true);
      this.spinnerService.hide();
    }
    else {
      this.isScheduleAdd = true;
    }
  }

  public cancelEditing(closeModal: any = {}) {
    this.hide(closeModal);
  }
}
