import { Component, OnInit, EventEmitter, Input, ViewChild, OnDestroy } from '@angular/core';
import { ICloseable, ModalBackdropService, ModalService, ModalWrapperComponent } from 'src/app/shared/components/modal';
import { AlertsService, SpinnerService, ApplicationStateService, SettingParam, UserDetails, OrderService, NumpadOptions, DomainConstants, RuntimeConstants, InfoModalComponent, Messages, RabbitMQService, HttpStatusCodes, CameraAnnotationService, CameraAnnotationObject, OrderEventBroadcastingService } from 'src/app/shared';
import { filter, finalize } from 'rxjs/operators';
import * as _ from 'lodash';
import { OrderInvoice, Transactions } from 'src/app/orders/interface';
import { GiftCardPaymentComponent } from '../../../gift-card-payment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TerminalsService } from 'src/app/configurator';
import { forkJoin, Subscription } from 'rxjs';
import { TableSelectionComponent } from 'src/app/orders/table-selection/table-selection.component';
import { cloneDeep, find, forEach } from 'lodash';
import { OrderPaymentService } from 'src/app/orders/services';
import { UntilDestroy } from '@ngneat/until-destroy';
declare let $: any;
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { LayoutDesignersService } from 'src/app/shared/services/layout-designers.service';
import { PeripheralModelComponent } from '../../../peripheral-model';
import { CreditCardPaymentStatus } from 'src/app/shared/interface/credit-card-payment-status';
import { OrderProductPromotion } from 'src/app/manage-console/promotion/interfaces/order-product-promotion';
import { PromotionSummaryComponent } from 'src/app/manage-console/promotion/components/promotion-summary/promotion-summary.component';
import { CreditCardResponseModalComponent } from './credit-card-response-modal/credit-card-response-modal.component';


@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pos-settle',
  templateUrl: './settle.component.html',
  styleUrls: ['./settle.component.scss', '../../../../../../styles/pages/design/settle.scss']
})
export class SettleComponent implements OnInit, ICloseable, OnDestroy {
  close: EventEmitter<any> = new EventEmitter();

  @Input() orderId: number;
  @Input() serveOrderAfterPayment: boolean = false;
  @Input() promotionSummary: Array<OrderProductPromotion> = [];
  @ViewChild('ringupNumpad') ringupNumpad: any;
  accounts: Array<any> = [];
  settingParam: SettingParam;
  userDetails: UserDetails;
  drawerId: number;
  userId: number;
  isPaymentByEBT: boolean;
  paymentButtonText: string = Messages.EnterButtonText;
  ringupSuccessMessage: string = '';
  accountsList: Array<any> = [];
  minHeightOfOrderBox: number = 0;
  paymentLoader: boolean;
  selectedAccountDetails: any = {};
  enteredAmount: number = 0;
  dueAmountForSelectedSeats: number = 0;
  totalOrderDueAmount: number = 0;
  seats: any = [];
  orderData: OrderInvoice;
  tenderTypeColors: any = {};
  isPartialPayment: boolean = false;
  numpadPaymentOptions: NumpadOptions = { prefix: '$', disable: true, allowAlphabets: false, disableCancel: true, allowDecimal: true, max: 9999.99, enterButton: false };
  numpadOptionChange = new EventEmitter<any>();
  paymentsOnOrder: any;
  orderProductStatus = RuntimeConstants;
  totalAmountOfPayed: number = 0;
  isSubmitPayment: boolean = false;
  selectedSeatOrdinals: string = '';
  isAllowCreditCard: boolean = false;
  allOrdinals: string = '';
  cashTendered: number = 0;
  dueAmount: number = 0;
  canModifyAmount: boolean = true;
  rabbitMQRequestId: string = '';
  mappedLayout: Array<any> = [];
  creditCardResponseRef: ICloseable;
  peripheralCreditCardMessage: string = '';
  creditCardResponse = {
    header: '',
    message: '',
    buttonText: 'Cancel',
    isShowCCError: false,
  }
  subAccountTerm: string;
  paymentType = '';
  @ViewChild('peripheralModel') peripheralModel: any;
  @ViewChild('creditCardResponseModel') creditCardResponseModel: any;
  settleRabbitMQSubscriptions: Array<any> = [];
  // rabbitMq subscriptions
  rabbitMqOrderEntryTerminalQueueSubscription: Subscription;
  rabbitMqHICResponseSubscription: Subscription;
  rabbitMqOrderUpdateSubscription: Subscription;
  rabbitMqHICResponseToServerSubscription: Subscription;
  orderUpdateModalRef = null;
  // isShowCCError: boolean = false;
  tableTerm: string = '';
  closeSettleAfterFullPayment: boolean = false;
  cameraAnnotations: CameraAnnotationObject;
  change = 0;
  checkCCPaymentStatusInterval;
  autoSignOutUserSubscription: Subscription;
  creditCardResponseRefTimer;
  constructor(private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private applicationStateService: ApplicationStateService,
    private orderService: OrderService,
    private modalService: ModalService,
    private modalBackdropService: ModalBackdropService,
    private bsModalService: BsModalService,
    private rabbitMQService: RabbitMQService,
    private terminalService: TerminalsService,
    private layoutDesignerService: LayoutDesignersService,
    private orderPaymentService: OrderPaymentService,
    private cameraAnnotationService: CameraAnnotationService,
    private orderEventBroadcastingService: OrderEventBroadcastingService) { }

  ngOnInit() {
    this.setDefaults();
    this.getAccounts();
    this.getMappedLayoutWithTerminal();
    this.getAccountVisualizationData();
    this.subscribeToHICResponseToTerminal();
    this.subscribeToOrderPaymentResponseTerminalQueue();
    this.subscribeToHICResponseToServerQueue();
    this.subscribeToMakeTableExchangeForAllOrderEntryTerminals();
    if (this.promotionSummary?.length) {
      this.openPromotionSummary();
    }
    this.cameraAnnotations = this.applicationStateService.cameraAnnotations;
    this.subscribeToAutoSignOutUser();
  }

  ngOnDestroy(): void {
    this.autoSignOutUserSubscription?.unsubscribe();
    this.clearCCPaymentStatusInterval();
  }
  checkEnteredAmountIsBigger() {
    this.enteredAmount = this.ringupNumpad.numpad.value;
    if (this.enteredAmount > this.dueAmountForSelectedSeats) {
      const self = this;
      setTimeout(() => {
        self.enteredAmount = self.dueAmountForSelectedSeats;
        this.numpadOptionChange.next({ value: this.enteredAmount, options: this.numpadPaymentOptions });
        this.ringupNumpad.numpad.validateValue(self.enteredAmount);
      });
      return true;
    }
  }
  makePaymentEBT(e) {
    this.paymentType = DomainConstants.PaymentModes.EBT;
    if (!this.canModifyAmount) {
      return;
    }
    if (this.isSubmitPayment || this.numpadPaymentOptions.disable || this.numpadPaymentOptions.disableCancel) {
      return;
    }
    if (this.checkEnteredAmountIsBigger()) {
      return;
    }

    this.checkIsPartialPayment(this.submitEBTPayment);
  }
  submitEBTPayment = () => {
    this.addCameraAnnotationForPaymentType(DomainConstants.PaymentModes.EBT);
    if (this.settingParam.EBTType === DomainConstants.EBTTypes.Peripheral) {
      this.isPaymentByEBT = true;
      this.openPeripheralModel();
    }
  }
  setDefaults() {
    this.settingParam = this.applicationStateService.settingParam;
    this.numpadPaymentOptions.prefix = this.settingParam.CurrencySymbol;
    this.userDetails = this.applicationStateService.userDetails;
    this.drawerId = this.settingParam && this.settingParam.CashDrawerPrinter ? this.settingParam.CashDrawerPrinter.Id : 0;
    this.userId = this.userDetails ? this.userDetails.id : 0;
    if (this.settingParam.AllowCreditCards && this.settingParam.CreditCardTerminal.Id != 0) {
      this.isAllowCreditCard = true;
    } else {
      this.isAllowCreditCard = (this.settingParam.CreditCardType == 'Peripheral' && this.settingParam.AllowCreditCards) ? true : false;
    }
    this.peripheralCreditCardMessage = Messages.SwipeCardAndSelectResult;
    this.subAccountTerm = this.settingParam.SubaccountTerm ? this.settingParam.SubaccountTerm : 'Seat';
    this.tableTerm = this.settingParam.TableTerm ? this.settingParam.TableTerm : 'Table';
    this.closeSettleAfterFullPayment = this.settingParam.CloseSettleAfterFullPayment;
  }

  resetValues() {
    this.orderData = this.orderService.getNewOrderInvoiceDetails();
    this.paymentLoader = false;
    this.seats = [];
    this.paymentsOnOrder = [];
    this.selectedAccountDetails = {};
    this.enteredAmount = 0;
    this.dueAmountForSelectedSeats = 0;
    this.totalOrderDueAmount = 0;
    this.isPartialPayment = false;
    this.rabbitMQRequestId = '';
    this.dueAmount = 0;
    this.totalAmountOfPayed = 0;
    this.isSubmitPayment = false;
    this.selectedSeatOrdinals = '';
    this.allOrdinals = '';
    this.cashTendered = 0;
    this.numpadPaymentOptions.disable = true;
    this.numpadPaymentOptions.disableCancel = true;
    this.numpadPaymentOptions.prefix = this.settingParam.CurrencySymbol ?? '$';
    this.numpadOptionChange.next({ options: this.numpadPaymentOptions });
    this.ringupNumpad.numpad.formateValue(0, null);
    this.paymentButtonText = Messages.EnterButtonText;
  }

  getAccounts() {
    this.spinnerService.show();
    this.orderService.getUnservedOrdersDataForSettle(this.drawerId, this.userId)
      .pipe(finalize(() => {
        setTimeout(() => {
          this.spinnerService.hide();
        });
      }))
      .subscribe({
        next: (res) => {
          this.getAccountCompleted(res);
          this.getMinHeightOfOrderBox();
        }, error: this.alertService.showApiError
      });
  }

  getAccountCompleted = (res) => {
    if (res) {
      this.accountsList = _.filter(res, (account) => {
        return account.GrandTotal > account.PaidAmount;
      });
    } else {
      this.accountsList = [];
    }
  }

  getMinHeightOfOrderBox() {
    if (($(window).width()) > 780) {
      this.minHeightOfOrderBox = $('#NumpadFieldSet').height() - $('#AccountFieldSet').height() - 5;
      $('#OrderFieldSet').css('min-height', this.minHeightOfOrderBox);
      $('#OrderFieldSet').css('max-height', this.minHeightOfOrderBox);
    } else {
      this.minHeightOfOrderBox = 0;
      $('#OrderFieldSet').css('min-height', 'unset');
      $('#OrderFieldSet').css('max-height', 'unset');
    }
  }

  precisionRound(number, precision) {
    const factor = Math.pow(10, precision);
    return Math.round(number * factor) / factor;
  }

  accountSelection = (event) => {
    const isAvailableAccount = _.find(this.accountsList, (acc) => {
      return acc.id == event.accountDetails.id;
    });
    if (isAvailableAccount && this.selectedAccountDetails.id !== event.accountDetails.id && !this.paymentLoader) {
      // reset();
      this.selectedAccountDetails = event.accountDetails;
      this.orderId = event.accountDetails.id;
      this.enteredAmount = this.precisionRound(event.accountDetails.GrandTotal - event.accountDetails.PaidAmount, 2);
      this.dueAmountForSelectedSeats = this.enteredAmount;
      this.totalOrderDueAmount = this.enteredAmount;
      this.numpadPaymentOptions.disable = false;
      this.numpadPaymentOptions.disableCancel = false;
      this.numpadOptionChange.next({ value: this.enteredAmount, options: this.numpadPaymentOptions });
      this.seats = [];

      this.getOrderDetails(false, true);
      this.getTransactions();
      this.scrollToAccount(event.accountDetails.id);
    }
  }

  scrollToAccount(accountId: number) {
    // setTimeout(() => {
    const container = $('#settleAccount');
    const scrollTo = $('#account_' + accountId);
    container.animate({ scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop() });
    // });
  }

  getTransactions() {
    if (this.orderId) {
      const transactions = this.orderService.getTransactions(this.orderId)
        .subscribe({
          next: (res: Array<Transactions>) => {
            this.preparePaymentsOnOrder(res.filter(x => x.TransactionType !== DomainConstants.TransactionTypes.Void));
          }
        });
    }
  }

  getOrderDetailsAfterPayment() {
    if (this.orderId > 0) {
      this.spinnerService.show();
      this.orderService.getOrderDetails(this.orderId)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res: OrderInvoice) => {
            if (res) {
              this.cameraAnnotationService.addAnnotationToCamera(this.cameraAnnotations.RingupPaymentCompleted, {
                OrderId: res.SurrogateOrderId,
                PaymentType: this.paymentType,
                DueAmount: res.DueAmount?.toFixed(2),
                Change: this.change?.toFixed(2)
              });
              this.getOrderDetailsCompleted(res, true);
              if (!res.DueAmount) {
                this.printPendingItemsToKitchen();
              }
              this.paymentType = "";
              this.change = 0;
            }
          }, error: this.alertService.showApiError
        });
    }
  }

  getOrderDetails(serveOrder = false, isFromAccount = false) {
    if (this.orderId > 0) {
      this.spinnerService.show();
      this.orderService.getOrderDetails(this.orderId)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res: OrderInvoice) => {
            if (res) {
              if (isFromAccount && res.GrandTotal != this.selectedAccountDetails?.GrandTotal && res?.Id?.Value) {
                this.reloadOrderAndAccount(res?.Id?.Value);
              } else {
                this.getOrderDetailsCompleted(res, serveOrder);
              }
            }
          }, error: this.alertService.showApiError
        });
    }
  }

  getOrderDetailsCompleted = (res, serveOrder) => {
    this.orderData = res;
    if (this.orderData.DueAmount > 0 || !serveOrder) {
      const settleSeats = _.groupBy(this.orderData.OrderItems, 'SubAccountOrdinal');
      const ordinals = Object.keys(settleSeats);
      this.dueAmount = cloneDeep(this.orderData.DueAmount);
      this.allOrdinals = '';
      _.forEach(ordinals, (ordinal) => {
        this.allOrdinals += ',' + ordinal;
      });
      this.prepareSeatObj();
    } else {
      this.autoServeOrder();
    }
    this.orderId = this.orderData.Id.Value;
  }

  reloadOrderAndAccount = (orderId) => {
    this.resetValues();
    this.accountsList = [];
    this.orderId = orderId;
    this.spinnerService.show();
    const reloadOrderAndAccountObservable = [];
    reloadOrderAndAccountObservable.push(this.orderService.getOrderDetails(this.orderId));
    reloadOrderAndAccountObservable.push(this.orderService.getUnservedOrdersDataForSettle(this.drawerId, this.userId))
    forkJoin(reloadOrderAndAccountObservable)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (responses: any) => {
          if (responses && responses.length) {
            if (responses[0]) {
              this.getOrderDetailsCompleted(responses[0], false);
            }
            if (responses[1]) {
              this.getAccountCompleted(responses[1]);
            }
          }
        }, error: this.alertService.showApiError
      });
  }

  preparePaymentsOnOrder(transactions: Array<Transactions>) {
    this.paymentsOnOrder = [];

    _.forEach(transactions, (transaction) => {
      let paymentType;
      if (transaction.PaymentTypeId == DomainConstants.PaymentTypes.CASH) {
        paymentType = DomainConstants.PaymentModes.CASH;
      } else if (transaction.PaymentTypeId == DomainConstants.PaymentTypes.CREDITCARD) {
        paymentType = DomainConstants.PaymentModes.CREDITCARD;
      } else if (transaction.PaymentTypeId == DomainConstants.PaymentTypes.GIFTCARD) {
        paymentType = DomainConstants.PaymentModes.GIFTCARD;
      } else if (transaction.PaymentTypeId == DomainConstants.PaymentTypes.EBT) {
        paymentType = DomainConstants.PaymentModes.EBT;
      }
      const payment = {
        Amount: transaction.Amount,
        PaymentType: paymentType
      }
      this.paymentsOnOrder.push(payment);
    })
  }

  prepareSeatObj() {
    const self = this;
    this.spinnerService.show();
    this.orderService.getSeatWiseOrderDetails(this.orderData.Id.Value)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.seats = res ? res : [];
          this.prepareSeat();
        }, error: this.alertService.showApiError
      });
  }

  prepareSeat() {
    this.totalAmountOfPayed = this.orderData.PaidAmount;
    this.totalAmountOfPayed = this.precisionRound(this.totalAmountOfPayed, 2);
    let payedSeatsTotalAmount = 0;
    const payedSeats = _.filter(this.seats, function (item) {
      // if (item.Status) {
      payedSeatsTotalAmount += item.PaidAmount;
      // }
      return item.PaidAmount > 0;
    });
    payedSeatsTotalAmount = this.precisionRound(payedSeatsTotalAmount, 2);
    if (payedSeats && payedSeats.length > 0) {
      if (payedSeatsTotalAmount != this.totalAmountOfPayed) {
        this.isPartialPayment = true;
      } else {
        this.isPartialPayment = false;
      }
    } else {
      if (this.totalAmountOfPayed > 0) {
        this.isPartialPayment = true;
      } else {
        this.isPartialPayment = false;
      }
    }
    this.enteredAmount = this.precisionRound(this.selectedAccountDetails.GrandTotal - this.totalAmountOfPayed, 2);
    this.totalAmountOfPayed = this.enteredAmount;

  }

  onClose() {
    this.close.emit({ shouldReload: false });
  }

  cancelPaymentPrice = () => {
    this.paymentButtonText = Messages.EnterButtonText;
    this.numpadPaymentOptions.prefix = this.settingParam.CurrencySymbol ?? '$';
    if (this.selectedAccountDetails) {
      this.isSubmitPayment = false;
      this.cashTendered = 0;
      this.numpadPaymentOptions.disable = false;
      this.numpadPaymentOptions.disableCancel = false;
      this.numpadOptionChange.next({ value: this.enteredAmount, options: this.numpadPaymentOptions });
      if (this.dueAmountForSelectedSeats != 0) {
        this.enteredAmount = this.dueAmountForSelectedSeats;
      } else {
        this.enteredAmount = this.totalOrderDueAmount;
      }
    }
  }

  seatSelection(event) {
    if (!this.isSubmitPayment) {
      this.selectedSeatOrdinals = '';
      this.numpadPaymentOptions.disable = false;
      this.numpadPaymentOptions.disableCancel = false;
      this.numpadPaymentOptions.disableCancel = false;
      let selectedSubaccount = [];
      if (event.SelectedSeat.length > 0) {
        this.enteredAmount = 0;
        // const seatKeys = Object.keys(event);
        _.forEach(event.SelectedSeat, (seat, index) => {
          if (seat.Status) {
            this.numpadPaymentOptions.disable = true;
            this.numpadPaymentOptions.disableCancel = true;
          }
          this.selectedSeatOrdinals += ',' + seat.SubAccountOrdinal;
          selectedSubaccount.push(seat.SubaccountName ? seat.SubaccountName : this.subAccountTerm + ' ' + seat.SubAccountOrdinal);
          const seatDueAmount = parseFloat(seat.SeatTotal) - parseFloat(seat.PaidAmount);
          this.enteredAmount += (seatDueAmount > 0 ? seatDueAmount : 0);
          // + parseFloat(seat.Tax); // parseFloat(seat.RemainingPaidAmount);
        });
        this.enteredAmount = this.precisionRound(this.enteredAmount, 2);
        if (this.enteredAmount > this.totalOrderDueAmount) {
          this.enteredAmount = this.totalOrderDueAmount;
        }
      } else {
        this.enteredAmount = this.precisionRound(this.selectedAccountDetails.GrandTotal - this.selectedAccountDetails.PaidAmount, 2);
        this.totalOrderDueAmount = this.enteredAmount;
      }
      if (event.IsSubaccountSelected) {
        this.cameraAnnotationService.addAnnotationToCamera(this.cameraAnnotations.RingupSubaccountSelect, {
          SubaccountAmount: this.enteredAmount?.toFixed(2),
          OrderId: this.orderData.SurrogateOrderId,
          SubaccountName: selectedSubaccount.join()
        });
      }
      this.numpadOptionChange.next({ value: this.enteredAmount, options: this.numpadPaymentOptions });
      this.dueAmountForSelectedSeats = this.enteredAmount;
    }
  }

  splitPrintRequestHandler(obj) {
    if (this.orderId) {
      let seats = null;
      if (this.selectedSeatOrdinals !== '') {
        seats = this.selectedSeatOrdinals;
      }
      const printObj = {
        OrderId: this.orderId,
        PaidAmount: this.orderData.PaidAmount,
        SplitCount: obj.SplitCount,
        SplitAmount: obj.SplitAmount,
        SelectedSeats: seats,
        SurrogateOrderId: this.selectedAccountDetails.SurrogateOrderId
      };
      this.printReceipt(printObj);
    }
  }

  printSeat() {
    if (this.orderId) {
      let seats = null; // this.allOrdinals;
      if (this.selectedSeatOrdinals !== '') {
        seats = this.selectedSeatOrdinals;
      }
      const printObj = {
        OrderId: this.orderId,
        PaidAmount: this.orderData.PaidAmount,
        SelectedSeats: seats,
        SurrogateOrderId: this.selectedAccountDetails.SurrogateOrderId
      };
      this.printReceipt(printObj);
    }
  }

  printReceipt(printModel) {
    const seatDetails = [];
    forEach(this.seats, (seat, index) => {
      // forEach(seat, (item) => {
      let details = {
        SubAccountOrdinal: seat.SubAccountOrdinal,
        PctDiscount: seat.PctDiscount,
        PctMarkup: seat.PctMarkup,
        FlatDiscount: seat.FlatDiscount,
        FlatMarkup: seat.FlatMarkup
      }
      seatDetails.push(details);
      // });
    });
    this.orderId = printModel.OrderId;
    const paidAmount = printModel.PaidAmount;
    if (this.orderId) {
      this.settingParam.UserID = this.applicationStateService.userId;
      this.settingParam.CurrentUser = this.applicationStateService.username;
      this.settingParam.TerminalName = this.applicationStateService.terminalName;
      const printOrderModel = {
        OrderId: this.orderId,
        TerminalId: this.applicationStateService.terminalId,
        Parameter: this.settingParam,
        TransRefNum: '', // transRefNum,
        BankAuthCode: '', // bankAuthCode
        CardNumber: '', // cardNumber
        CardSchemeName: '', // cardSchemeName
        IsCreditPrint: false, // isCreditPrint,
        PaidAmount: paidAmount ? paidAmount : 0,
        SubAccountOrdinals: printModel.SelectedSeats,
        SurrogateOrderId: printModel.SurrogateOrderId,
        PrintCount: printModel.SplitCount,
        SplitAmount: printModel.SplitAmount,
        ReceiptPrinterId: this.settingParam.ReceiptPrinter.Id,
        PrintSeatDetails: seatDetails
      };
      if (!this.settingParam.ReceiptPrinter.Id) {
        const modalRef = this.modalService.show(InfoModalComponent, {
          animated: false,
          class: 'vertical-center',
          initialState: {
            message: Messages.ReceiptPrinterNotMapped
          }
        });
        return;
      }

      this.spinnerService.show();
      this.orderService.printCheck(printOrderModel)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res) => {
            if (res) {
            }
          }, error: this.alertService.showApiError
        });
    }
  }

  isProcessPayment() {
    if (this.enteredAmount > (this.selectedAccountDetails.GrandTotal - this.selectedAccountDetails.PaidAmount)) {
      this.enteredAmount = this.selectedAccountDetails.GrandTotal - this.selectedAccountDetails.PaidAmount;
      return false;
    }
    return true;
  }

  makeCashPayment = (res) => {
    this.enteredAmount = Number(res.value);
    if (this.enteredAmount) {
      const enteredAmount = this.enteredAmount;
      if (this.dueAmountForSelectedSeats < this.enteredAmount) {
        this.numpadPaymentOptions.prefix = `C ${this.settingParam.CurrencySymbol}`;
        this.paymentButtonText = "Submit";
        this.enteredAmount = this.precisionRound(this.enteredAmount - this.dueAmountForSelectedSeats, 2);
        this.change = this.enteredAmount;
      }
      this.addCameraAnnotationForCashPayment(enteredAmount, this.change);
      this.cashTendered = Number(res.value);
      const subAccountOrdinal = this.removePaidSeats(this.selectedSeatOrdinals ? this.selectedSeatOrdinals.split(',') : []);
      const amount = Number(res.value) > this.dueAmountForSelectedSeats ?
        this.dueAmountForSelectedSeats : this.enteredAmount;
      const paymentModel = this.orderPaymentService.preparePaymentDetails(this.orderId, this.orderData.SurrogateOrderId, amount, this.cashTendered, DomainConstants.PaymentTypes.CASH, subAccountOrdinal, this.rabbitMQRequestId, this.isPartialPayment);
      this.orderService.openCashDrawer(this.settingParam.CashDrawerPrinter.Id, paymentModel).pipe(finalize(() => {
      }))
        .subscribe({
          next: (response: any) => {
          }, error: this.alertService.showApiError
        });
      this.numpadPaymentOptions.disable = true;
      this.numpadOptionChange.next({ value: this.enteredAmount, options: this.numpadPaymentOptions });
      if ((this.selectedSeatOrdinals &&
        (Number(res.value)) < this.dueAmountForSelectedSeats) || this.precisionRound(res.value, 2) < this.dueAmount) {
        this.checkIsPartialPayment(this.cashPayment);
      } else {
        if (this.cashTendered == this.dueAmountForSelectedSeats) {
          this.cashPayment();
        }
      }
    }
  }

  removePaidSeats = (subAccountOrdinals) => {
    const ordinals = [];
    if (subAccountOrdinals) {
      forEach(subAccountOrdinals, item => {
        const seat = find(this.seats, seatData => {
          return seatData.SubAccountOrdinal == item && seatData.PaidAmount !== seatData.SeatTotal;
        });
        if (seat) {
          ordinals.push(seat.SubAccountOrdinal.toString());
        }
      });
    }
    return ordinals;
  }

  cashPayment = () => {
    this.isSubmitPayment = true;
    const subAccountOrdinal = this.removePaidSeats(this.selectedSeatOrdinals ? this.selectedSeatOrdinals.split(',') : []);
    const amount = this.cashTendered > this.dueAmountForSelectedSeats ? this.dueAmountForSelectedSeats : Number(this.enteredAmount)
    const paymentModel = this.orderPaymentService.preparePaymentDetails(this.orderId, this.orderData.SurrogateOrderId, amount, this.cashTendered, DomainConstants.PaymentTypes.CASH, subAccountOrdinal, this.rabbitMQRequestId, this.isPartialPayment);
    this.orderService.cashPayment(this.orderId, paymentModel)
      .pipe(finalize(() => {
        this.paymentType = DomainConstants.PaymentModes.CASH;
        this.paymentCompleted();
      }))
      .subscribe({
        next: (response: any) => {
        }, error: this.alertService.showApiError
      });
  }

  // preparePaymentDetails(amount: number, paymentTypeId: number) {
  //   const paymentDetails: any = {
  //     Id: 0,
  //     OrderId: this.orderId,
  //     Amount: amount,
  //     PaymentTypeId: paymentTypeId,
  //     IsCompleted: false,
  //     UserId: this.applicationStateService.userDetails.id,
  //     IsCaptured: false,
  //     CashDrawerId: this.settingParam.CashDrawerPrinter.Id,
  //     CashTendered: this.cashTendered,
  //     SubAccountOrdinals: this.selectedSeatOrdinals ? this.selectedSeatOrdinals.split(',') : this.allOrdinals.split(','),
  //     TerminalId: this.applicationStateService.terminalId,
  //     TerminalName: this.applicationStateService.terminalName,
  //     SurrogateOrderId: this.orderData.SurrogateOrderId,
  //     RabbitMQRequestId: this.rabbitMQRequestId,
  //     IsPartialPayment: this.isPartialPayment
  //   };
  //   return paymentDetails;
  // }

  paymentCompleted() {
    this.cancelPaymentPrice();
    this.getOrderDetailsAfterPayment();
    this.resetValues();
    this.getAccounts();
    this.accountsList = _.filter(this.accountsList, (account) => {
      return account.id != this.orderId;
    });
  }

  removeSubAccountPayments = () => {
    this.orderService.removeSubAccountPayment(this.orderId)
      .pipe(finalize(() => {
      }))
      .subscribe({
        next: (response: any) => {
        }, error: this.alertService.showApiError
      });
  }

  openPartialPaymentConfirmationModal = (callbackFunction) => {
    const preventMsg = StringUtils.format(Messages.ConfirmSettlePartialPaymentPrevent,
      {
        'payableAmount': this.precisionRound(this.enteredAmount, 2).toFixed(2),
        'payableSelectedSeats': this.dueAmountForSelectedSeats,
        'subaccountTerm': !this.settingParam.SubaccountTerm ? 'seat' : this.settingParam.SubaccountTerm,
        'currencySymbol': this.settingParam.CurrencySymbol
      });
    const modalRef = this.modalService.show(InfoModalComponent, {
      keyboard: false,
      animated: false,
      class: 'vertical-center',
      initialState: {
        message: preventMsg,
        confirmButtonText: 'Yes',
        rejectButtonText: 'No',
        modalHeaderText: 'Confirm'
      }
    });
    modalRef.close.subscribe((res) => {
      if (res && (res.shouldConfirm)) {
        this.isPartialPayment = true;
        this.removeSubAccountPayments();
        callbackFunction();
      } else {
        this.enteredAmount = this.dueAmountForSelectedSeats;
        this.cashTendered = 0;
        this.numpadPaymentOptions.prefix = this.settingParam.CurrencySymbol ?? '$';
        this.numpadPaymentOptions.disable = false;
        this.numpadPaymentOptions.disableCancel = false;
        this.numpadOptionChange.next({ value: this.enteredAmount, options: this.numpadPaymentOptions });
      }
    });
  }

  checkIsPartialPayment(callbackFunction) {
    if (this.enteredAmount > 0) {
      if (!this.cashTendered || this.cashTendered <= this.dueAmountForSelectedSeats) {
        callbackFunction();
      }
      // if (!this.isPartialPayment &&
      //   (this.cashTendered ? this.cashTendered : this.enteredAmount) < this.dueAmountForSelectedSeats) {
      //   this.openPartialPaymentConfirmationModal(callbackFunction);
      // } else {
      //   if (!this.cashTendered || this.cashTendered <= this.dueAmountForSelectedSeats) {
      //     callbackFunction();
      //   }
      // }
    }
  }

  giftCardPayment = () => {
    // this.enteredAmount = this.numpad.value;
    // if (this.enteredAmount > this.dueAmountForSelectedSeats) {
    //   const self = this;
    //   setTimeout(() => {
    //     self.enteredAmount = self.dueAmountForSelectedSeats;
    //     this.numpadOptionChange.next({ value: this.enteredAmount, options: this.numpadPaymentOptions });
    //     this.numpad.validateValue(self.enteredAmount);
    //   });
    //   return;
    // }
    if (this.isSubmitPayment || this.numpadPaymentOptions.disable || this.numpadPaymentOptions.disableCancel) {
      return;
    }
    if (this.checkEnteredAmountIsBigger()) {
      return;
    }
    this.checkIsPartialPayment(this.openGiftCardPaymentModal);
  }

  openGiftCardPaymentModal = () => {
    this.addCameraAnnotationForPaymentType(DomainConstants.PaymentModes.GIFTCARD);
    this.numpadPaymentOptions.disable = true;
    this.numpadPaymentOptions.disableCancel = true;
    this.numpadOptionChange.next({ value: this.enteredAmount, options: this.numpadPaymentOptions });
    const modalRef = this.modalService.getModalWrapper(GiftCardPaymentComponent);
    const modal = modalRef.show({
      keyboard: false,
      animated: false,
      class: 'vertical-center',
      initialState: {
        payableAmount: this.enteredAmount,
        orderId: this.orderId,
        subAccountOrdinal: this.selectedSeatOrdinals ? this.selectedSeatOrdinals.split(',') : this.allOrdinals.split(','),
        isPartialPayment: this.isPartialPayment
      }
    });
    modal.close.subscribe((response) => {
      if (response?.shouldReload) {
        this.paymentType = DomainConstants.PaymentModes.GIFTCARD;
        this.paymentCompleted();
      }
      this.numpadPaymentOptions.disable = false;
      this.numpadPaymentOptions.disableCancel = false;
      this.numpadOptionChange.next({ value: this.enteredAmount, options: this.numpadPaymentOptions });
    });
  }

  creditCardPayment = () => {
    this.paymentType = DomainConstants.PaymentModes.CREDITCARD;
    if (this.isSubmitPayment || this.numpadPaymentOptions.disable || this.numpadPaymentOptions.disableCancel) {
      return;
    }
    if (this.checkEnteredAmountIsBigger()) {
      return;
    }
    this.checkIsPartialPayment(this.submitCreditCardPayment);
  }

  submitCreditCardPayment = () => {
    this.addCameraAnnotationForPaymentType(DomainConstants.PaymentModes.CREDITCARD);
    if (this.settingParam.AllowCreditCards) {
      if (this.settingParam.CreditCardType === DomainConstants.CreditCardType.Peripheral) {
        this.isPaymentByEBT = false;
        this.openPeripheralModel();

      } else {
        this.makeCreditCardPayment();
      }
    }
  }
  setRabbitMQRequestId(orderId: number) {
    this.rabbitMQRequestId = this.rabbitMQService.guidWithTimeStamp();
  }
  openPeripheralModel() {
    const modalRef = this.modalService.getModalWrapper(PeripheralModelComponent);
    const modal = modalRef.show({
      keyboard: false,
      animated: false,
      class: 'vertical-center',
      initialState: {
        peripheralCreditCardMessage: this.peripheralCreditCardMessage
      }
    });
    modal.close.subscribe((response) => {
      if (response?.approve) {
        this.onPeripheralApproved();
      } else {
        this.onPeripheralCreditCardCancel();
      }
    });
  }
  makeCreditCardPayment = () => {
    this.creditCardResponse.header = 'Info';
    this.creditCardResponse.message = Messages.ConnectingCreditCardTerminal;
    this.creditCardResponse.buttonText = 'Cancel';
    this.numpadPaymentOptions.disable = true;
    this.numpadPaymentOptions.disableCancel = true;
    this.numpadOptionChange.next({ options: this.numpadPaymentOptions });
    this.setRabbitMQRequestId(this.orderId);
    const subAccountOrdinal = this.removePaidSeats(this.selectedSeatOrdinals ? this.selectedSeatOrdinals.split(',') : []);
    const payment = this.orderPaymentService.preparePaymentDetails(this.orderId, this.orderData.SurrogateOrderId, this.enteredAmount, this.cashTendered, DomainConstants.PaymentTypes.CREDITCARD, subAccountOrdinal, this.rabbitMQRequestId, this.isPartialPayment);
    payment.CreditCardTerminalId = this.settingParam.CreditCardTerminal.Id;

    this.creditCardResponseRef = this.modalService.show(CreditCardResponseModalComponent, {
      initialState: {
        creditCardResponse: this.creditCardResponse
      }
    });
    this.creditCardResponseRef.close.subscribe((res) => {
      this.creditCardResponseRef = null;
      if(this.creditCardResponseRefTimer) {
        clearTimeout(this.creditCardResponseRefTimer);
      }
      if (res?.cancel) {
        this.cancelCreditCardTransaction();
      } else {
        this.hideOverPaymentModal();
      }
    });
    this.orderService.integratedCreditCardPayment(this.orderId, payment)
      .pipe(finalize(() => {
      }))
      .subscribe({
        next: (response: any) => {
          this.checkCCPaymentStatusInterval = setInterval(() => {
            this.checkCCPaymentStatus();
          }, 5000);

        }, error: (error) => {
          if (error?.error && error.status === HttpStatusCodes.BadRequest) {
            this.orderAmountMisMatchError(error);
          } else {
            this.alertService.showApiError(error);
          }
        }
      });
  }

  onCCTransactionGetSuccessStatus = () => {
    this.clearCCPaymentStatusInterval();
    this.creditCardResponse.message = Messages.PaymentApproved;
    this.rabbitMQRequestId = '';
    this.creditCardResponse.buttonText = 'Ok'
    this.creditCardResponse.header = Messages.Success;
    this.paymentCompleted();
    const hideModalTimeout = setTimeout(() => {
      if (this.creditCardResponseRef) {
        this.creditCardResponseRef?.close?.emit();
      }
      clearTimeout(hideModalTimeout);
    }, 2500);
  }

  onCCTransactionGetCanceledStatus = () => {
    this.clearCCPaymentStatusInterval();
    this.creditCardResponse.message = DomainConstants.TransactionStatus.Canceled;
    this.rabbitMQRequestId = '';
    this.creditCardResponse.header = Messages.Error;
    this.creditCardResponse.buttonText = 'Ok';
  }

  onCCTransactionGetFailedStatus = () => {
    this.clearCCPaymentStatusInterval();
    this.creditCardResponse.message = Messages.PaymentRejected;
    this.rabbitMQRequestId = '';
    this.creditCardResponse.header = Messages.Error;
    this.creditCardResponse.buttonText = 'Ok';
  }

  checkCCPaymentStatus = () => {
    if (this.rabbitMQRequestId && this.orderId) {
      this.orderService.getTransactionStatus(this.rabbitMQRequestId)
        .subscribe({
          next: (response: CreditCardPaymentStatus) => {
            if (response.Status == DomainConstants.TransactionStatus.Success) {
              this.onCCTransactionGetSuccessStatus();
            } else if (response.Status == DomainConstants.TransactionStatus.Failed) {
              this.onCCTransactionGetFailedStatus();
            } else if (response.Status == DomainConstants.TransactionStatus.Canceled) {
              this.onCCTransactionGetCanceledStatus();
            }
            else if (this.creditCardResponse.message == Messages.ConnectingCreditCardTerminal && response.Status == DomainConstants.TransactionStatus.Pending) {
              this.creditCardResponse.message = Messages.StillConnectingCreditCardTerminal;
            }
          }, error: this.alertService.showApiError
        });
    } else {
      this.clearCCPaymentStatusInterval();
    }
  }

  clearCCPaymentStatusInterval() {
    if (this.checkCCPaymentStatusInterval) {
      clearInterval(this.checkCCPaymentStatusInterval);
    }
  }

  private orderAmountMisMatchError(error: any) {
    this.creditCardResponse.header = 'Info';
    this.creditCardResponse.message = error.error;
    this.creditCardResponse.buttonText = 'Cancel';
    this.creditCardResponse.isShowCCError = true;
    this.clearCCPaymentStatusInterval();
  }

  hideOverPaymentModal = () => {
    if (this.creditCardResponse.isShowCCError) {
      this.canModifyAmount = true;
      this.creditCardResponse.isShowCCError = false;
    }
    this.numpadPaymentOptions.disable = false;
    this.numpadPaymentOptions.disableCancel = false;
    this.paymentButtonText = 'Enter';
    this.numpadPaymentOptions.prefix = this.settingParam.CurrencySymbol ?? '$';
    this.numpadOptionChange.next({ options: this.numpadPaymentOptions });
  }

  onPeripheralApproved() {
    this.numpadPaymentOptions.disable = true;
    this.numpadPaymentOptions.disableCancel = true;
    this.numpadOptionChange.next({ options: this.numpadPaymentOptions });
    const subAccountOrdinal = this.removePaidSeats(this.selectedSeatOrdinals ? this.selectedSeatOrdinals.split(',') : []);
    const payment = this.orderPaymentService.preparePaymentDetails(this.orderId, this.orderData.SurrogateOrderId, this.enteredAmount, this.cashTendered, DomainConstants.PaymentTypes.CREDITCARD, subAccountOrdinal, this.rabbitMQRequestId, this.isPartialPayment);
    payment.CreditCardTerminalId = this.settingParam.CreditCardTerminal.Id;
    this.spinnerService.show();
    if (this.isPaymentByEBT) {
      this.orderService.peripheralEBTPayment(this.orderId, payment)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (response: any) => {
            this.onPeripheralCompleted();
          }, error: this.alertService.showApiError
        });
    }
    else {
      this.orderService.peripheralCreditCardPayment(this.orderId, payment)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (response: any) => {
            this.onPeripheralCompleted();
          }, error: this.alertService.showApiError
        });
    }
  }

  onPeripheralCompleted() {
    this.paymentCompleted();
  }

  onPeripheralCreditCardCancel() {
    this.numpadPaymentOptions.disable = false;
    this.numpadPaymentOptions.disableCancel = false;
    this.numpadOptionChange.next({ options: this.numpadPaymentOptions });
  }

  subscribeToOrderPaymentResponseTerminalQueue() {
    this.rabbitMqOrderEntryTerminalQueueSubscription = this.rabbitMQService.subscribeToOrderPaymentResponseTerminalQueue$(this.applicationStateService.terminalId)
      .subscribe((message: any) => {
        this.creditCardPaymentCompleted(message.Payload.PaymentResponse);
      });
  }
  subscribeToMakeTableExchangeForAllOrderEntryTerminals = () => {
    this.rabbitMqOrderUpdateSubscription = this.rabbitMQService.subscribeToOrderUpdatedMessageForAllOrderEntry$()
      .pipe(filter(message => message.Source.Id !== this.applicationStateService.terminalId))
      .subscribe((message) => {
        if (message && message.Payload && message.Payload.OrderDetails && message.Payload.OrderDetails.Id == this.orderId) {
          this.openOrderUpdateInfoModal(message.Payload.OrderDetails)
        } else {
          this.getAccounts();
        }
      });
  }

  openOrderUpdateInfoModal = (orderDetails) => {
    if (!this.orderUpdateModalRef) {
      this.orderUpdateModalRef = this.modalService.show(InfoModalComponent, {
        keyboard: false,
        animated: false,
        class: 'vertical-center',
        initialState: {
          message: Messages.OrderUpdateInSettleMessage
        }
      });
      this.orderUpdateModalRef.close.subscribe((res) => {
        if (res) {
          this.reloadOrderAndAccount(orderDetails.Id);
        }
        this.orderUpdateModalRef = null;
      });
    }
  }
  subscribeToHICResponseToServerQueue() {
    this.rabbitMqHICResponseToServerSubscription = this.rabbitMQService.subscribeToHICCreditCardTransactionResponseMessage$()
      .pipe(filter(message => message?.Payload?.CreditCardTransactionRequest?.TerminalId === this.applicationStateService.terminalId &&
        message?.Payload?.CreditCardTransactionRequest?.CreditCardPaymentRequest?.OrderID === this.orderId))
      .subscribe({
        next: (message: any) => {
          if (message?.Payload?.CreditCardTransactionResult?.Result === 'Ok') {
            this.creditCardResponse.message = Messages.PaymentRecording;
          }
        }
      });
  }

  subscribeToHICResponseToTerminal() {
    this.rabbitMqHICResponseSubscription = this.rabbitMQService.subscribeToHICResponseToTerminal$(this.applicationStateService.terminalId)
      .subscribe((message: any) => {
        const response = message.Payload.HICReponse;
        if (response.HardwareType == 'CreditCardTerminal') {
          this.creditCardResponse.header = 'Info';
          if (response.Status == DomainConstants.HICResponseStatus.Connected) {
            this.creditCardResponse.message = Messages.SwipeCardRequest;
          } else if (response.Status == DomainConstants.HICResponseStatus.Busy) {
            if (response.ConnectedToTerminalId == this.applicationStateService.terminalId) {
              this.creditCardResponse.message = Messages.CreditCardTerminalBusyWithPreviousRequest;
            } else {
              this.creditCardResponse.message = StringUtils.format(
                Messages.CreditCardTerminalBusy, {
                'terminalName': response.ConnectedToTerminalName,
                'creditCardTerminalName': response.DeviceName
              });
            }
            this.creditCardResponse.buttonText = 'Ok';
            this.rabbitMQRequestId = '';
            this.clearCCPaymentStatusInterval();
          } else if (response.Status == DomainConstants.HICResponseStatus.Error) {
            this.creditCardResponse.message = response.Message;
            this.creditCardResponse.buttonText = 'Ok';
            this.rabbitMQRequestId = '';
            this.clearCCPaymentStatusInterval();
          }
        }
      });
  }

  creditCardPaymentCompleted(response) {
    if (response?.PaymentCompleted) {
      this.clearCCPaymentStatusInterval();
      this.creditCardResponse.message = response.PaymentResponseFromWeb.Message;
      this.rabbitMQRequestId = '';
      this.creditCardResponse.buttonText = 'Ok';
      if (response.PaymentSuccessfullyComplete) {
        this.creditCardResponse.header = Messages.Success;
        this.creditCardResponseRefTimer = setTimeout(() => {
            this.creditCardResponseRef?.close?.emit();
          clearTimeout(this.creditCardResponseRefTimer);
        }, 2500);
        if (!this.settingParam.CardSignaturePrintReceipts) {
          this.paymentCompleted();
        }
      } else {
        this.creditCardResponse.header = Messages.Error;
      }
    }
  }

  cancelCreditCardPayment() {
    if (this.creditCardResponse.isShowCCError) {
      this.canModifyAmount = true;
      this.creditCardResponse.isShowCCError = false;
    }
    this.numpadPaymentOptions.disable = false;
    this.numpadPaymentOptions.disableCancel = false;
    this.numpadPaymentOptions.prefix = this.settingParam.CurrencySymbol ?? '$';
    this.numpadOptionChange.next({ options: this.numpadPaymentOptions });
    this.getAccounts();
  }

  cancelCreditCardTransaction() {
    this.canModifyAmount = true;
    if (this.rabbitMQRequestId !== '') {
      const cancelRequestModel = {
        TerminalId: this.applicationStateService.terminalId,
        TerminalName: this.applicationStateService.terminalName,
        CreditCardTerminalId: this.settingParam.CreditCardTerminal.Id,
        OrderId: this.orderId,
        RequestId: this.rabbitMQRequestId
      };
      this.clearCCPaymentStatusInterval();
      this.spinnerService.show();
      this.orderService.cancelCreditCardPayment(cancelRequestModel)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (response: any) => {
            this.rabbitMQRequestId = '';
          }, error: (e) => {
            if (e && e.error && e.status == HttpStatusCodes.BadRequest) {
              const modalRef = this.modalService.show(InfoModalComponent, {
                animated: false,
                class: 'vertical-center',
                keyboard: false,
                initialState: {
                  message: e.error,
                }
              });
            } else {
              this.alertService.showApiError(e);
            }
          }
        });
    }
    this.cancelCreditCardPayment();
  }

  getAccountVisualizationData() {
    this.layoutDesignerService.getAccountVisualizations()
      .subscribe({
        next: response => {
          this.accounts = response ? response : [];
        }, error: this.alertService.showApiError
      });
  }

  getMappedLayoutWithTerminal() {
    const layoutObservable = [];
    let terminalConfig;
    layoutObservable.push(this.terminalService.getTerminalProperties(this.applicationStateService.terminalId));
    layoutObservable.push(this.layoutDesignerService.getAll());
    this.spinnerService.show();
    forkJoin(layoutObservable)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (responses: Array<any>) => {
          if (responses) {
            terminalConfig = responses[0] ? responses[0] : [];
            const layouts = [];
            if (responses[1]) {
              _.forEach(responses[1], (layout: any) => {
                if (layout.IsActive) {
                  const selectedLayout = {
                    id: layout.Id,
                    label: layout.Name
                  };
                  layouts.push(selectedLayout);
                }
              });
            }
            let availableLayouts;
            _.forEach(terminalConfig, (terminalProperty) => {
              if (DomainConstants.TerminalProperties.TABLE_LAYOUT.Key === terminalProperty.PropertyKey) {
                availableLayouts = terminalProperty.PropertyValue.split(',').map((item) => {
                  return parseInt(item, 10);
                });
              }
            });
            // let mappedLayout = [];
            _.forEach(availableLayouts, (item) => {
              const currentLayout = _.find(layouts, (layout) => {
                return layout.id == item;
              });
              if (currentLayout) {
                this.mappedLayout.push(item);
              }
            });
          }
        }, error: this.alertService.showApiError
      });
  }

  tableSelection() {
    const modalRef = this.modalService.getModalWrapper(TableSelectionComponent);
    const modal = modalRef.show({
      keyboard: false,
      animated: false,
      class: 'vertical-center modal-max-width-95',
      initialState: {
        orders: this.accountsList,
        mappedLayout: this.mappedLayout,
        isShowOccupiedTable: true,
        accounts: this.accounts
      }
    });
    modal.close.subscribe((response) => {
      if (response?.orderId) {
        this.selectTable(response.orderId);
      }
    });
  }

  selectTable(accountId) {
    const selectedAccount = _.find(this.accountsList, (acc) => {
      return acc.id == accountId;
    });
    if (selectedAccount) {
      const account = {
        accountDetails: selectedAccount
      }
      this.accountSelection(account);
    }
  }

  updateIsSendToKitchen(orderData) {
    if (orderData && orderData.Id.Value) {
      this.spinnerService.show();
      this.orderService.updateIsSentToKitchen(orderData.Id.Value)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res: OrderInvoice) => {
            this.autoServeOrder(true);
          }
        });
    }
  }

  serveOrder(orderData) {
    if (orderData?.Id?.Value && this.checkIfOrderOrProductIsScheduled()) {
      this.orderService.serveOrder(orderData.Id.Value, this.userDetails.id)
        .pipe(finalize(() => {
        }))
        .subscribe({
          next: (res: OrderInvoice) => {
          }
        });
    }
  }

  checkIfOrderOrProductIsScheduled() {
    const isOrderScheduled = this.orderData.ScheduleDate ? new Date(this.orderData.ScheduleDate) > new Date() : false;
    const hasScheduledProducts = find(this.orderData.OrderItems,
      orderItem => orderItem.ScheduleTime && new Date(orderItem.ScheduleTime) > new Date());
    if (isOrderScheduled || hasScheduledProducts) {
      const modalRef = this.modalService.getModalWrapper(InfoModalComponent);
      const modal = modalRef.show({
        animated: false,
        class: 'vertical-center',
        initialState: {
          message: isOrderScheduled ? Messages.OrderIsScheduled : Messages.OrderHasScheduledProductsMessage
        }
      });
      return false;
    }
    return true;
  }

  autoServeOrder(isPrintAlwaysOrPromptClosureAction = false) {
    if (this.orderData.Id.Value > 0 && (this.settingParam.OrderClosurePrintAction === DomainConstants.OrderClosurePrintAction.DO_NOT_PRINT || isPrintAlwaysOrPromptClosureAction)) {
      const orderData = cloneDeep(this.orderData);
      let isDoneAllOrderProducts = true;
      _.forEach(this.orderData.OrderItems, (product) => {
        isDoneAllOrderProducts = product.StatusId !== 0 && isDoneAllOrderProducts ? true : false;
      });
      if (isDoneAllOrderProducts && (this.settingParam.AutoServeOrder || this.serveOrderAfterPayment)) {
        this.serveOrder(orderData);
      }
      if (this.closeSettleAfterFullPayment && !this.settingParam.AutoSignOutOnPaymentCompleted) {
        this.onClose();
      }
      this.resetValues();
    }
  }

  addCameraAnnotationForPaymentType(paymentType: string) {
    this.cameraAnnotationService.addAnnotationToCamera(this.cameraAnnotations.RingupPaymentType, {
      PaymentType: paymentType,
      OrderId: this.orderData.SurrogateOrderId,
      EnteredAmount: Number(this.enteredAmount)?.toFixed(2),
      DueAmount: this.dueAmountForSelectedSeats?.toFixed(2)
    });
  }

  addCameraAnnotationForCashPayment(enteredAmount, change) {
    this.cameraAnnotationService.addAnnotationToCamera(this.cameraAnnotations.RingupPaymentType, {
      PaymentType: DomainConstants.PaymentModes.CASH,
      OrderId: this.orderData.SurrogateOrderId,
      EnteredAmount: enteredAmount?.toFixed(2),
      DueAmount: this.dueAmountForSelectedSeats?.toFixed(2),
      Change: change
    });
  }

  printPendingItemsToKitchen() {
    const orderData = cloneDeep(this.orderData);
    if (this.settingParam.OrderClosurePrintAction === DomainConstants.OrderClosurePrintAction.PRINT_ALWAYS) {
      this.updateIsSendToKitchen(orderData);
    } else if (this.settingParam.OrderClosurePrintAction === DomainConstants.OrderClosurePrintAction.PROMPT_USER) {
      if (orderData.OrderItems.some(x => (x.OrderItemType == DomainConstants.OrderItemTypes.Product || x.OrderItemType == DomainConstants.OrderItemTypes.Comment)
        && (!x.IsSentToKitchen || x.IsDirty))) {
        this.promptForOrderClosurePrintAction(orderData);
      } else {
        this.autoServeOrder(true);
        this.shouldEmitUserAutoSignOutEvent();
      }
    }
  }

  private promptForOrderClosurePrintAction(orderData: OrderInvoice) {
    const modal = this.modalService.getModalWrapper(InfoModalComponent);
    const modalRef = modal.show({
      keyboard: false,
      animated: false,
      class: 'vertical-center',
      initialState: {
        message: Messages.OrderClosurePrintAction,
        confirmButtonText: 'Yes',
        rejectButtonText: 'No',
        modalHeaderText: 'Confirm'
      }
    });

    modalRef.close.subscribe((res) => {
      if (res?.shouldConfirm) {
        this.updateIsSendToKitchen(orderData);
      } else {
        this.autoServeOrder(true);
      }
      this.shouldEmitUserAutoSignOutEvent();
    });
  }

  openPromotionSummary() {
    if (this.promotionSummary?.find(x => x.IsRewardedItem && x.Amount > 0) != null) {
      const modalRef = this.modalService.getModalWrapper(PromotionSummaryComponent);
      modalRef.show({
        animated: false,
        class: 'vertical-center',
        initialState: {
          orderProductPromotions: this.promotionSummary
        }
      });
    }
  }

  subscribeToAutoSignOutUser() {
    this.autoSignOutUserSubscription = this.rabbitMQService.subscribeToAutoSignOutUserMessage$()
      .subscribe({
        next: (message: any) => {
          if (
            (message?.Payload?.EventName == "PaymentCompletedEvent") &&
            message?.Payload?.TerminalId == this.applicationStateService.terminalId &&
            message?.Payload?.UserId == this.applicationStateService.userId
          ) {
            this.shouldEmitUserAutoSignOutEvent();
          }
        },
        error: () => {
          console.log("error while connecting to RabbitMQ.");
        },
      });
  }

  shouldEmitUserAutoSignOutEvent() {
    if (this.applicationStateService.settingParam.AutoSignOutOnPaymentCompleted) {
      this.onClose();
      this.orderEventBroadcastingService.onAutoSignOutUser();
    }
  }
}
