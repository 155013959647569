import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants';
import { TerminalHardwareDevice } from '../../configurator/device-mapping/interface/terminal-hardware-device';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PosHardwareType } from '../../configurator/device-mapping/interface/pos-hardware-type';
import { HardwareModel } from '../interface/hardware-model';
import { TerminalSummary } from 'src/app/application-login/interface/terminal-summary';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';

@Injectable()
export class DeviceMappingService extends BaseCrudService<TerminalHardwareDevice> {

    devicemappingsApiBasePath = '';

    constructor(httpService: HttpClient) {
        super('devicemappings', httpService);
    }

    newDeviceMapping() {
        const deviceMapping: TerminalHardwareDevice = {
            Id: 0,
            DeviceType: null,
            DeviceId: 0,
            Device: '',
            TerminalId: null,
            IsActive: false,
            TypeIdentifier: '',
            TerminalName: '',
            TerminalType: ''
        };
        return deviceMapping;
    }

    getTerminalHardwareDevices(TerminalId: number): Observable<TerminalHardwareDevice[]> {
        return this.httpService.get<TerminalHardwareDevice[]>(`${RuntimeConstants.API_PATH}devicemappings/${TerminalId}`);
    }

    getHardwareDeviceDetails(id: number): Observable<TerminalHardwareDevice> {
        return this.httpService.get<TerminalHardwareDevice>(`${RuntimeConstants.API_PATH}devicemappings/details/${id}`);
    }

    getTerminals(): Observable<TerminalSummary[]> {
        return this.httpService.get<TerminalSummary[]>(`${RuntimeConstants.API_PATH}devicemappings/terminals`);
    }

    getAvailablePosHardwareTypesForTerminalType(terminalTypeId: number): Observable<PosHardwareType[]> {
        return this.httpService.get<PosHardwareType[]>(`${RuntimeConstants.API_PATH}devicemappings/pos-hardware-types/${terminalTypeId}`);
    }

    getHardwares(): Observable<HardwareModel[]> {
        return this.httpService.get<HardwareModel[]>(`${RuntimeConstants.API_PATH}devicemappings`);
    }

    getAudioDevices(): Observable<HardwareModel[]>  {
        return this.httpService.get<HardwareModel[]>(`${RuntimeConstants.API_PATH}devicemappings/audiodevices`);
    }

    saveHardwareDevicesMapping(hardwareDevices) {
        return this.httpService.post(`${RuntimeConstants.API_PATH}devicemappings/device-mapping`, hardwareDevices);
    }

    getHardwareTerminalMappingByTerminalId(terminalId: number, hardwareType: string): Observable<TerminalHardwareDevice> {
        return this.httpService.get<TerminalHardwareDevice>(`${RuntimeConstants.API_PATH}devicemappings/device-mapping/${terminalId}/hardware-type/${hardwareType}`);
    }
}

