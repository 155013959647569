import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Permissions, Levels, Messages } from '../../../../shared/constants/';
import { SalesGroupService } from 'src/app/shared/services/sales-group.service';
import { GridColumn, TemplateColumn, TextAlign, TableComponent } from '@tarktech/tark-ng-utils';
import { AuthenticationService } from 'src/app/shared/auth/services/authentication.service';
import { SalesGroup } from '../../interface';
import { BaseListComponent } from 'src/app/shared/components/base-list.component';
import { SpinnerService, AlertsService, ModalService, DomainConstants, PrintTableService, ApplicationStateService, salesGroupCommentsDollar } from 'src/app/shared';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';

@Component({
    selector: 'pos-sales-groups',
    templateUrl: './sales-groups.component.html',
    styleUrls: ['./sales-groups.component.scss']
})
export class SalesGroupsComponent extends BaseListComponent<SalesGroup> implements OnInit {
    permission = {
        name: Permissions.SalesGroups,
        readOnlyLevel: Levels.ReadOnly,
        editLevel: Levels.Edit
    };
    public icons = {
        salesGroupCommentsDollar
      };
    salesGroupsColumns: Array<GridColumn> = [];
    salesGroups: SalesGroup[] = [];
    @ViewChild('makeTableColorColumn', { static: true }) private makeTableColorColumn: any;
    @ViewChild('salesGroupGrid', { static: true }) salesGroupGrid: TableComponent;
    constructor(protected salesGroupService: SalesGroupService,
        private authenticationService: AuthenticationService,
        protected alertService: AlertsService,
        protected spinnerService: SpinnerService,
        protected modalService: ModalService,
        protected applicationStateService: ApplicationStateService,
        protected printService: PrintTableService,
        protected route: ActivatedRoute,
        private router: Router
    ) {
        super(salesGroupService, alertService, spinnerService, modalService, applicationStateService, printService, route);
        this.scrollToTop();
    }

    ngOnInit() {
        this.deleteSuccessMessage = Messages.SalesGroupDeleted;
        this.salesGroupsColumns = this.configureGridColumns();
        this.checkPermission();
        this.loadSubscriptions();
        this.salesGroupGrid.context = this.gridContext;
    }

    public getGridColumns(): Array<GridColumn> {

        const makeTableColorColumn = new TemplateColumn({
            HeaderText: 'Make Table Color',
            itemTemplate: this.makeTableColorColumn,
            TextAlign: TextAlign.Center,
            Width: '10%',
            Field: 'make_table_color',
            IsSortable: false
        });

        return [
            new GridColumn({ HeaderText: 'Name', Field: 'name', IsSortable: true, Width: '82%' }),
            makeTableColorColumn
        ] as Array<GridColumn>;
    }

    private loadSubscriptions(): void {
        this.data$.subscribe({ next: (res) => {
            this.salesGroups = res;
        }});
    }

    public editItem(id: number, data?: SalesGroup): void {
        this.router.navigate([id], { state: { salesGroupId: id, fromListPage: true }, relativeTo: this.route });
    }

    public getConfirmDeleteMessage(data: SalesGroup): string {
        return StringUtils.format(Messages.ConfirmDeleteSalesGroup,
            { 'salesGroup': data ? data.name : '' }
        );
    }

    private checkPermission(): void {
        if (!this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
            _.remove(this.salesGroupsColumns, (column) => {
                return (column.Type === DomainConstants.GridColumnsTypes.EditColumn
                    || column.Type === DomainConstants.GridColumnsTypes.DeleteColumn);
            });
        }
    }

    private scrollToTop(): void {
        window.scrollTo(0, 0);
    }

    close() {
        this.router.navigate(['../'], { relativeTo: this.route });
    }
}
