import { Component, Input, OnInit } from '@angular/core';
import { PickScreenOrder } from 'src/app/pick-screen';
import { ApplicationStateService, ColorUtilityService, OrderCardService, SettingParam } from 'src/app/shared';
import { lightBulbPickScreen } from 'src/app/shared/components/icon';
@Component({
  selector: 'pos-pick-screen-item',
  templateUrl: './pick-screen-item.component.html',
  styleUrls: ['./pick-screen-item.component.scss']
})
export class PickScreenItemComponent implements OnInit {

  @Input() pickScreenProduct: PickScreenOrder;
  @Input() highlightTimeouts = new Map<number, Date>();

  icons = { lightBulbPickScreen }
  settingParam: SettingParam;
  highlightDuration = 0;

  constructor(private orderCardService: OrderCardService, 
    private colorUtilityService: ColorUtilityService,
    private applicationStateService: ApplicationStateService) { }
  
  ngOnInit(): void {
    this.setDefaults();
    this.pickScreenProduct.QtyBgColor = this.orderCardService.getBackGroundColor(this.pickScreenProduct.Qty, this.settingParam.MakeTableQuantityColors);
    this.pickScreenProduct.QtyFontColor = this.colorUtilityService.getContrastColor(this.pickScreenProduct.QtyBgColor);
    this.highlightItem(this.pickScreenProduct.OrderProductId);
  }

  highlightItem(orderProductId: number) {
    if (this.highlightTimeouts.has(orderProductId)) {
      const expirationDate = this.highlightTimeouts.get(orderProductId);

      const now = new Date();
      const timeRemaining = (expirationDate.getTime() - now.getTime()) / 1000;

      if (timeRemaining > 0) {
        const animationDuration = 1; // Duration of a single animation loop in seconds (defined in CSS)
        this.highlightDuration = Math.ceil(timeRemaining / animationDuration);
      }
    }
  }

  setDefaults = () => {
    this.settingParam = this.applicationStateService.settingParam;
    document.documentElement.style.setProperty('--highlight-color', this.settingParam.PickScreenHighlightItemColor);
  }
}
