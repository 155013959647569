import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RuntimeConstants } from 'src/app/shared';
import { PrinterScript } from '../interface';

@Injectable({
  providedIn: 'root'
})
export class ReceiptDesignerService {

  receiptDesignerApiUrl = `${RuntimeConstants.API_PATH}receipt-designer`;

  constructor(private httpService: HttpClient) { }

  getAllPrinterScripts(): Observable<Array<PrinterScript>> {
    return this.httpService.get<Array<PrinterScript>>(`${this.receiptDesignerApiUrl}/printer-scripts`);
  }

  showReceiptSample(receiptScript: PrinterScript) {
    return this.httpService.post(`${this.receiptDesignerApiUrl}/receipt-sample`, receiptScript);
  }

  deletePrinterScript(scriptId: number) {
    return this.httpService.delete(`${this.receiptDesignerApiUrl}/delete-printer-script/${scriptId}`);
  }

  savePrinterScript(receiptScript: PrinterScript) {
    return this.httpService.post(`${this.receiptDesignerApiUrl}/save-printer-script`, receiptScript);
  }
}
