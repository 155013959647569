import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';
import { AccountCategory } from '../../information-management/account-categories/interface/account-category';
import { Observable } from 'rxjs';

@Injectable()
export class AccountCategoryService extends BaseCrudService<AccountCategory> {

    accountCategoryApiUrl = '';
    constructor(httpService: HttpClient) {
        super('accountcategories', httpService);
        this.accountCategoryApiUrl = RuntimeConstants.API_PATH + 'accountcategories';
    }
    newAccountCategory() {
        return {
            Id: 0,
            Category: ''
        };
    }
    getAccountCategories(): Observable<AccountCategory[]> {
        return this.httpService.get<AccountCategory[]>(this.accountCategoryApiUrl);
    }
}
