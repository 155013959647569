import { Component, OnInit, ViewChild } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { DomainConstants } from 'src/app/shared/constants/domain-constants';
import { FieldInfoMessages } from 'src/app/shared/constants/field-info-messages';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { MakeTableWorkflowParameter } from '../../interfaces/make-table-workflow-parameter';
import { MakeTableWorkflowService } from '../../services/make-table-workflow.service';
import { star, checkCircleMakeTable, clock, cogs, code, rocket, magic, makeTable, tasks } from '../../../../shared/components/icon';
import { Options } from '@angular-slider/ngx-slider';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { Terminal, TerminalsService } from 'src/app/configurator/terminals';
import { forkJoin, Observable } from 'rxjs';
import { filter, forEach, isEmpty } from 'lodash';
import { HardwareModel } from 'src/app/shared/interface/hardware-model';
import { Features } from 'src/app/shared/constants/features';
import { AudioNotificationService } from 'src/app/shared/services/audio-notification-file.service';
import { DeviceMappingService } from 'src/app/shared/services/device-mapping.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderVariable, PostSavedConfigurationService } from 'src/app/configurator/';
import { FormUtilityService } from 'src/app/shared/services/form-utility.service';
import { BaseFormComponent } from 'src/app/shared/components';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'pos-make-table-workflow',
  templateUrl: './make-table-workflow.component.html',
  styleUrls: ['./make-table-workflow.component.scss']
})
export class MakeTableWorkflowComponent extends BaseFormComponent implements OnInit {

  @ViewChild('maketableWorkflow') makeTableWorkflow: NgForm;
  get getForm(): NgForm {
    return this.makeTableWorkflow
  }
  maketableWorkflowParameters: MakeTableWorkflowParameter;
  fieldInfoMessages = FieldInfoMessages;
  showSubaccountInMakeTableMessage: string;
  showWhenMoreThanOneSubaccountMessage: string;
  subAccountTerm: string;
  makeTableStates = DomainConstants.MakeTableStates;
  chkAutoExpandMinutesOnlineOrder = false;
  chkSendViaSMS = false;
  chkSendViaDJ = false;
  chkSendViaAudioNotifier = false;
  isOpenVariableForAudioNotifier = false;
  isOpenVariableForSMS = false;
  isOpenVariableForDJTerminal = false;
  djTerminals: Array<Terminal> = [];
  cursorPosition = 0;
  audioVoices: Array<string> = [];
  audioDevices: Array<HardwareModel> = [];
  selectedOrderVariable = null;
  features = Features;
  orderVariables: Array<OrderVariable> = [];
  props = {
    controlBoxClass: 'col-md-6 col-sm-8 col-xs-12'
  }
  options: Options = {
    floor: 0,
    ceil: 100,
    translate: (value: number): string => {
      return '';
    }
  };
  icons = {
    star, checkCircleMakeTable, clock, cogs, rocket, magic, code, makeTable, tasks
  };
  constructor(
    private maketableWorkflowService: MakeTableWorkflowService,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private applicationStateService: ApplicationStateService,
    private terminalsService: TerminalsService,
    private audioNotificationService: AudioNotificationService,
    private deviceMappingService: DeviceMappingService,
    private postSavedConfigurationService: PostSavedConfigurationService,
    private router: Router,
    private route: ActivatedRoute,
    formUtilityService: FormUtilityService
  ) {
    super(formUtilityService);
    this.subAccountTerm = this.applicationStateService.settingParam.SubaccountTerm;
    this.showSubaccountInMakeTableMessage = StringUtils.format(FieldInfoMessages.ShowSubaccountInMakeTable,
      { 'subAccountTerm': this.subAccountTerm });
      this.showWhenMoreThanOneSubaccountMessage =StringUtils.format(FieldInfoMessages.ShowWhenMoreThanOneSubaccountInMakeTable,
        { 'subAccountTerm': this.subAccountTerm });
  }

  ngOnInit(): void {
    this.maketableWorkflowParameters = this.maketableWorkflowService.newMakeTableWorkflowConfigurationParameter();
    this.loadData();
  }

  loadData() {
    this.spinnerService.show();
    const observables: Array<Observable<any>> = [];
    observables.push(this.terminalsService.getTerminals());
    observables.push(this.maketableWorkflowService.getMakeTableWorkflowParameters());
    observables.push(this.audioNotificationService.getAudioVoiceId());
    observables.push(this.deviceMappingService.getAudioDevices());
    observables.push(this.maketableWorkflowService.getOrderVariables());
    forkJoin(observables).pipe(finalize(() => {
      this.spinnerService.hide()
    })).subscribe({
      next: ([terminals, maketableParameters, audioVoices, audioDevices, orderVariables]: [Terminal[], MakeTableWorkflowParameter, Array<string>, Array<HardwareModel>, Array<OrderVariable>]) => {
        this.maketableWorkflowParameters = maketableParameters;
        this.prepareIconAndFieldInfo();
        this.djTerminals = filter(terminals, (terminal) =>
          terminal.TerminalType === DomainConstants.TerminalTypes.DJ.Name);
        this.audioVoices = audioVoices;
        this.audioDevices = audioDevices;
        this.orderVariables = orderVariables;
        this.chkAutoExpandMinutesOnlineOrder = this.maketableWorkflowParameters.AutoExpandMinutesOnlineOrder > 0 ? true : false;
        this.chkSendViaSMS = this.maketableWorkflowParameters.OrderReadyNotifyViaSMSText !== '' ? true : false;
        this.chkSendViaDJ = this.maketableWorkflowParameters.OrderReadyNotifyViaDJTerminal && this.maketableWorkflowParameters.OrderReadyNotifyViaDJText &&
          this.maketableWorkflowParameters.OrderReadyNotifyViaDJVoice ? true : false;
        this.chkSendViaAudioNotifier = this.maketableWorkflowParameters.OrderReadyNotifyViaAudioNotifier &&
          this.maketableWorkflowParameters.OrderReadyNotifyViaAudioNotifierText && this.maketableWorkflowParameters.OrderReadyNotifyViaAudioNotifierVoice ? true : false;
        this.maketableWorkflowParameters.OrderReadyNotifyViaDJTerminal = this.maketableWorkflowParameters.OrderReadyNotifyViaDJTerminal > 0 ? this.maketableWorkflowParameters.OrderReadyNotifyViaDJTerminal : null;
        this.maketableWorkflowParameters.OrderReadyNotifyViaDJVoice = isEmpty(this.maketableWorkflowParameters.OrderReadyNotifyViaDJVoice) ? null : this.maketableWorkflowParameters.OrderReadyNotifyViaDJVoice;
        this.maketableWorkflowParameters.OrderReadyNotifyViaAudioNotifier = this.maketableWorkflowParameters.OrderReadyNotifyViaAudioNotifier > 0 ? this.maketableWorkflowParameters.OrderReadyNotifyViaAudioNotifier : null;
        this.maketableWorkflowParameters.OrderReadyNotifyViaAudioNotifierVoice = isEmpty(this.maketableWorkflowParameters.OrderReadyNotifyViaAudioNotifierVoice) ? null : this.maketableWorkflowParameters.OrderReadyNotifyViaAudioNotifierVoice;
      },
      error: this.alertService.showApiError
    });
  }

  setMakeTableNotifications() {
    if (!this.chkSendViaSMS || !this.maketableWorkflowParameters.OrderReadyShowNotifyButtonOnMaketable) {
      this.maketableWorkflowParameters.OrderReadyNotifyViaSMSText = '';
    }
    if (!this.chkSendViaDJ || !this.maketableWorkflowParameters.OrderReadyShowNotifyButtonOnMaketable) {
      this.maketableWorkflowParameters.OrderReadyNotifyViaDJTerminal = 0;
      this.maketableWorkflowParameters.OrderReadyNotifyViaDJText = '';
      this.maketableWorkflowParameters.OrderReadyNotifyViaDJVoice = '';
      this.maketableWorkflowParameters.OrderReadyNotifyViaDJVolume = 0;
    }
    if (!this.chkSendViaAudioNotifier || !this.maketableWorkflowParameters.OrderReadyShowNotifyButtonOnMaketable) {
      this.maketableWorkflowParameters.OrderReadyNotifyViaAudioNotifier = 0;
      this.maketableWorkflowParameters.OrderReadyNotifyViaAudioNotifierText = '';
      this.maketableWorkflowParameters.OrderReadyNotifyViaAudioNotifierVoice = '';
      this.maketableWorkflowParameters.OrderReadyNotifyViaAudioNotifierVolume = 0;
    }
  }

  changeOrderVariable(selectedVariable, messageText, inputId) {
    this.isOpenVariableForSMS = false;
    this.isOpenVariableForDJTerminal = false;
    this.isOpenVariableForAudioNotifier = false;
    messageText = messageText.substring(0, this.cursorPosition) + ' {{' + selectedVariable + '}} '
      + messageText.substring(this.cursorPosition);
    if (inputId === 'smsText') {
      this.maketableWorkflowParameters.OrderReadyNotifyViaSMSText = messageText;
    } else if (inputId === 'djTerminalText') {
      this.maketableWorkflowParameters.OrderReadyNotifyViaDJText = messageText;
    } else if (inputId === 'audioText') {
      this.maketableWorkflowParameters.OrderReadyNotifyViaAudioNotifierText = messageText;
    }
    this.setCaretPosition(inputId);
    this.selectedOrderVariable = null;
  }

  setCaretPosition(inputId) {
    const input: HTMLInputElement = <HTMLInputElement>document.getElementById(inputId);
    this.cursorPosition = input ? input.selectionStart : 0;
  }

  saveMaketableWorkflow(isValid) {
    if (!isValid) {
      return;
    }
    if (this.maketableWorkflowParameters.OrderReadyShowNotifyButtonOnMaketable && !(this.chkSendViaSMS || this.chkSendViaDJ ||
      this.maketableWorkflowParameters)) {
      this.alertService.renderErrorMessage(Messages.InvalidNotifyConfiguration);
      return;
    }
    if (!this.checkValidationForNotify()) {
      return;
    }
    this.maketableWorkflowParameters.OrderReadyNotifyViaDJVoice = this.maketableWorkflowParameters.OrderReadyNotifyViaDJVoice ?? '';
    this.maketableWorkflowParameters.OrderReadyNotifyViaAudioNotifierVoice = this.maketableWorkflowParameters.OrderReadyNotifyViaAudioNotifierVoice ?? '';
    this.setMakeTableNotifications();
    this.spinnerService.show();
    this.maketableWorkflowService.updateMakeTableWorkflowParameters(this.maketableWorkflowParameters).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (response) => {
        this.alertService.renderSuccessMessage(Messages.MaketableConfigrationSaveSuccess);
        this.postSavedConfigurationService.showMessage();
      }, error: this.alertService.showApiError
    });
  }

  checkValidationForNotify = () => {
    if (this.maketableWorkflowParameters.OrderReadyShowNotifyButtonOnMaketable) {
      if (this.chkSendViaDJ && (!this.maketableWorkflowParameters.OrderReadyNotifyViaDJTerminal || !this.maketableWorkflowParameters.OrderReadyNotifyViaDJVoice)) {
        return;
      }
      if (this.chkSendViaAudioNotifier && (!this.maketableWorkflowParameters.OrderReadyNotifyViaAudioNotifier ||
        !this.maketableWorkflowParameters.OrderReadyNotifyViaAudioNotifierVoice)) {
        return;
      }
    }
    return true;
  }

  changeAutoExpandMinutesOnlineOrder() {
    if (!this.chkAutoExpandMinutesOnlineOrder) {
      this.maketableWorkflowParameters.AutoExpandMinutesOnlineOrder = 0;
    }
  }

  prepareIconAndFieldInfo() {
    forEach(this.maketableWorkflowParameters.MakeTableWorkflowStates, state => {
      switch (state.Code) {
        case this.makeTableStates.PENDING:
          state.FieldInfo = this.fieldInfoMessages.OrderStatusPending;
          state.Icon = this.icons.star;
          break;
        case this.makeTableStates.DONE:
          state.FieldInfo = this.fieldInfoMessages.OrderStatusDone;
          state.Icon = this.icons.checkCircleMakeTable;
          break;
        case this.makeTableStates.COOKING:
          state.FieldInfo = this.fieldInfoMessages.OrderStatusCooking;
          state.Icon = this.icons.clock;
          break;
        case this.makeTableStates.RETRIEVING_INGREDIENTS:
          state.FieldInfo = this.fieldInfoMessages.OrderStatusRetrievingIngredients;
          state.Icon = this.icons.cogs;
          break;
        case this.makeTableStates.READY:
          state.FieldInfo = this.fieldInfoMessages.OrderStatusReady;
          state.Icon = this.icons.rocket;
          break;
        case this.makeTableStates.CRAFTING:
          state.FieldInfo = this.fieldInfoMessages.OrderStatusCrafting;
          state.Icon = this.icons.magic;
          break;
        case this.makeTableStates.PARTIALLY_IN_PROGRESS:
          state.FieldInfo = this.fieldInfoMessages.OrderStatusPartiallyInProgress;
          state.Icon = this.icons.tasks;
          break;
      }
    });
  }

  cancel() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

}
