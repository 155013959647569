import { GridColumnTypes } from './grid-column-types';
import { TextAlign } from './text-align';
import { OverlayPanelComponent } from './overlay-panel/overlay-panel.component';


export class GridColumn {
    HeaderText?: string;
    Field?: string;
    IsSortable?: boolean;

    Width?: string;

    TextAlign: TextAlign = TextAlign.Left;
    AllowExport = true;
    CellClass?: string;
    BackgroundColor?: string;
    CellClassProp?: string;
    IsPopOver?: boolean;
    PopOverColumnClicked: (event: any, data: any) => void;

    get Type(): string {
        return GridColumnTypes.GridColumn;
    }
    constructor(init?: Partial<GridColumn>) {
        Object.assign(this, init);
    }
   private get field(): string {
        return this.Field;
    }

   private get header(): string {
        return this.HeaderText;
    }
    private get exportable(): boolean {
        return this.AllowExport;
    }
}
