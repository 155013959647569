import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GridColumn, TableComponent } from '@tarktech/tark-ng-utils';
import { remove } from 'lodash';
import {
  AlertsService, ApplicationStateService, AuthenticationService, BaseListComponent, database, DomainConstants, Levels, Messages,
  ModalService, Permissions, PrintTableService, SpinnerService, StringUtils
} from 'src/app/shared';
import { DashboardDataSource } from '../../interface/dashboard-data-source';
import { DashboardDataSourceService } from '../../service/dashboard-data-source.service';

@Component({
  selector: 'pos-data-source',
  templateUrl: './dashboard-data-source.component.html',
})
export class DashboardDataSourceComponent extends BaseListComponent<DashboardDataSource> implements OnInit {

  dataSource: Array<DashboardDataSource> = [];
  dataSourceColumns: Array<GridColumn> = [];
  icons = { database };
  permission = {
    Name: Permissions.DashboardDataSource,
    EditLevel: Levels.Edit
  }
  @ViewChild('dashboardDataSourceTable', { static: true }) dashboardDataSourceTable: TableComponent;

  constructor(
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected printService: PrintTableService,
    protected applicationStateService: ApplicationStateService,
    protected dataSourceService: DashboardDataSourceService,
    private authenticationService: AuthenticationService,
    protected router: Router,
    protected route: ActivatedRoute
  ) {
    super(dataSourceService, alertService, spinnerService, modalService, applicationStateService, printService, route);
    this.deleteSuccessMessage = Messages.DashboardDataSourceDeleted;
  }

  ngOnInit(): void {
    this.dataSourceColumns = this.configureGridColumns();
    this.data$.subscribe((response) => {
      this.dataSource = response;
    });
    this.checkPermission();
    this.dashboardDataSourceTable.context = this.gridContext;
  }

  checkPermission() {
    if (!this.authenticationService.userHasPermission([{ Name: this.permission.Name, Level: this.permission.EditLevel }], 'any')) {
      remove(this.dataSourceColumns, (column) => {
        return (column.Type === DomainConstants.GridColumnsTypes.EditColumn
          || column.Type === DomainConstants.GridColumnsTypes.DeleteColumn);
      });
    }
  }

  getGridColumns(): Array<GridColumn> {
    const columns = [
      new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '98%' }),
    ];
    return columns;
  }

  getConfirmDeleteMessage(dashBoardDataSource) {
    return StringUtils.format(Messages.ConfirmDeleteDashboardDataSource, { 'dashboardDataSource': dashBoardDataSource.Name });
  }

  editItem(id: number, data: DashboardDataSource) {
    if (id) {
      this.router.navigate([id], { relativeTo: this.route });
    } else {
      this.router.navigate([0], { relativeTo: this.route });
    }
  }

  close() {
    this.router.navigate(['/manage/system-configuration']);
  }

}
