<pos-list-wrapper [headingIcon]="icons.inventoryCategoriesUnitList" heading="Inventory Categories" (close)="close()">
    <div class="row">
        <div class="col-12">
            <ng-table id="inventoryCategories" [sort]="true" [exportFilename]="'inventory-categories'"
                [columns]="inventoryCategoriesColumns" [data]="inventoryCategories">
            </ng-table>
        </div>
    </div>
</pos-list-wrapper>
<pos-confirm-delete (onConfirmAcknowledge)="onConfirmAcknowledge()" #confirmInventoryCategory>
</pos-confirm-delete>
<pos-print-table [hidden]="true"></pos-print-table>
