import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseCrudService } from 'src/app/shared/services';
import { SMSTemplate } from '../interfaces/sms-template';

@Injectable({
  providedIn: 'root'
})
export class SmsTemplateService extends BaseCrudService<SMSTemplate> {

  constructor(private httpClient: HttpClient) {
    super('sms-templates', httpClient);
  }

  newSmsTemplate(): SMSTemplate {
    return {
      Id: 0,
      Name: '',
      KeyName: '',
      Value: '',
      Variables: null,
    };
  }
}
