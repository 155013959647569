<div class="left-nav-toggle" id="menu-toggle-div" (click)="toggleFullScreen()">
    <a class="left-nav-toggle__icon">
        <pos-icon class="black" [icon]="isFullScreen ? icons.angleDoubleRight : icons.angleDoubleLeft">
        </pos-icon>
    </a>
</div>
<div class="report-page col-12 p-0">
    <div class="vertical-tabs report-navs">
        <ul class="nav nav-pills nav-stacked flex-column" [hidden]="isFullScreen">
            <div class="ps-pix-5">
                <div class="report-page-search ms-pix-10">
                    <span class="search-icons">
                        <pos-icon [icon]="icons.times" *ngIf="searchReports"
                            (click)="reportsByGroupCopy = reportsByGroup;searchReports = ''">
                        </pos-icon>
                    </span>
                    <pos-icon [icon]="icons.search" *ngIf="!searchReports"></pos-icon>
                    <input class="form-control" [(ngModel)]="searchReports" (input)="search()"
                        placeholder="Report Filter...">
                </div>
            </div>
            <div class="overflow-auto-tabs mt-pix-5" *ngIf="reportsByGroupCopy && reportsByGroupCopy.length"
                [ngStyle]="{'height': 'calc(100vh - 150px)'}">
                <div *ngFor="let groupReport of reportsByGroupCopy" [hidden]="!groupReport.ReportsGroupList.length">
                    <div class="p-pix-10 pb-0 pe-pix-10"
                        permissions='[{"Name":"{{groupReport.ReportCategoryPermissionId}}", "Level":"{{accessLevels.Access}}"}]'>
                        <li class="tab-head">
                            <fa-icon class="{{groupReport.ReportCategoryIcon}} col-18 text-center"></fa-icon>
                            {{ groupReport.Category }}
                        </li>
                        <p-accordion>
                            <div *ngFor="let report of groupReport.ReportsGroupList" class="pt-pix-5">
                                <p-accordionTab class="pointer"
                                    permissions='[{"Name":"{{report.PermissionId}}", "Level":"{{accessLevels.Access}}"}]'>
                                    <p-header class="width-full normal">
                                        <fa-icon class="{{report.ReportGroupIcon}} col-20 text-center"></fa-icon>
                                        {{ report.ReportGroup }}
                                    </p-header>
                                    <div>
                                        <div class="nav flex" *ngFor="let item of report.ReportsList" scrollup
                                            (click)="getReportParameterDetails(item)">
                                            <li class="report-navs__nav flex-grow-1"
                                                [ngClass]="{active : activeReport.Name === item.ReportName && activeReport.Id === item.Id}">
                                                <a class="report-navs__nav__link link">
                                                    <section class="float-start text-center col-33">
                                                        <fa-icon class="{{item.ReportIcon}} black"></fa-icon>
                                                    </section>
                                                    <div class="display-flex text-start">
                                                        {{ item.ReportName }}
                                                    </div>
                                                </a>
                                            </li>
                                        </div>
                                    </div>
                                </p-accordionTab>
                            </div>
                        </p-accordion>
                    </div>
                </div>
            </div>
        </ul>
        <div *ngIf="!isReportParameter">
            <pos-report-details [reportData]="reportData" [reportParam]="reportParam" [reportId]="activeReport.Id"
                (reRunReport)="runReport()" (close)="reportDetailsClosed()"></pos-report-details>
        </div>
    </div>
</div>
