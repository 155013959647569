import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountAttributeTypes } from 'src/app/information-management/customers/account-attribute-types/interface/account-attribute-types';

@Injectable({
    providedIn: 'root'
})
export class AccountAttributeTypeService {

    constructor(private httpService: HttpClient) { }
    newAccountAttributeType() {
        return {
            Id: 0,
            Name: '',
            DateAdded: null,
            DateEdited: null,
            QueryValue: '',
            ValueType: null,
            Ordinal: 1
        };
    }

    getAccountAttributeTypes(): Observable<any[]> {
        return this.httpService.get<any[]>(
            `${RuntimeConstants.API_PATH}AccountAttributeType`
        );
    }

    addAccountAttributeType(accountAttributeTypes: AccountAttributeTypes): Observable<any> {
        return this.httpService.post(`${RuntimeConstants.API_PATH}AccountAttributeType/`, accountAttributeTypes);
    }

    updateAccountAttributeType(accountAttributeTypes: AccountAttributeTypes): Observable<any> {
        return this.httpService.put(`${RuntimeConstants.API_PATH}AccountAttributeType/`, accountAttributeTypes);
    }

    deleteAccountAttributeType(accountAttributeTypeId: number): Observable<any> {
        return this.httpService.delete(`${RuntimeConstants.API_PATH}AccountAttributeType/` + accountAttributeTypeId);
    }
    updateAttributeTypeOrdinal(accountAttributeTypes: AccountAttributeTypes): Observable<any> {
        return this.httpService.put(
            `${RuntimeConstants.API_PATH}AccountAttributeType/update-ordinal`,
            accountAttributeTypes
        );
    }

}
