<pos-edit-wrapper heading="General" (close)="cancel()" [headingIcon]="icons.cogs" [isPopup]="false">
    <form (ngSubmit)="saveGeneralWorkflow(generalWorkflowForm.valid)" #generalWorkflowForm="ngForm" class="edit-form">
        <context-provider provide="props">
            <div class="col-12 pos-page-body border-top pt-pix-5">
                <div class="col-12">
                    <div>
                        <h3 class="setting-title">Regional Settings</h3>
                    </div>
                    <pos-row class="pos-title-content">
                        <pos-column>
                            <pos-form-field label="Week Start" [fieldInfo]="fieldInfoMessages.WorkflowWeekStart">
                                <ng-select posInput [items]="daysOfWeek" appendTo="body" bindLabel="Name"
                                    bindValue="Name" name="weekDay" [(ngModel)]="parameters.WeekStart"
                                    [clearable]="false" [clearOnBackspace]="false" class="col-200">
                                </ng-select>
                            </pos-form-field>
                        </pos-column>
                        <pos-column>
                            <pos-form-field label="Phone Mask"
                                [validationMessages]="{pattern: 'Only 0, hyphen(-), dot(.), space and round brackets are allowed.'}">
                                <input posInput type="text" class="form-control col-200" bindLabel="Name" bindValue="Name"
                                    name="phoneNumberMask" [(ngModel)]="parameters.PhoneNumberMask"
                                    pattern="^\+?[\(\)0\s.-]*$" #phoneNumberMask="ngModel">
                            </pos-form-field>
                        </pos-column>
                    </pos-row>
                </div>
                <div class="col-12 section-border">
                    <div>
                        <h3 class="setting-title">Operational Settings</h3>
                    </div>
                    <pos-row class="pos-title-content">
                        <pos-column>
                            <pos-form-field label="Operations">
                                <div class="btn-group">
                                    <label class="btn btn-radio col-75 text-shadow-none"
                                        [ngClass]="!parameters.IsGlobalShutoff ? 'active btn-primary normal' : 'btn-secondary'">
                                        <input [(ngModel)]="parameters.IsGlobalShutoff" [value]="false" type="radio"
                                            name="globalShutOff">
                                        Active
                                    </label>
                                    <label class="btn btn-radio col-75 text-shadow-none"
                                        [ngClass]="parameters.IsGlobalShutoff ? 'active btn-danger normal':'btn-secondary'">
                                        <input [(ngModel)]="parameters.IsGlobalShutoff" [value]="true" type="radio"
                                            name="globalShut">
                                        Idle
                                    </label>
                                </div>
                            </pos-form-field>
                        </pos-column>
                    </pos-row>
                </div>
                <div class="col-12 section-border">
                    <div>
                        <h3 class="setting-title">Menu Display Settings</h3>
                    </div>
                    <pos-row class="pos-title-content">
                        <pos-column xs="12">
                            <pos-form-field inputStyle="col-xs-12">
                                <pos-row colXs="3">
                                    <pos-column class="col-275">
                                        <label class="edit-form__section__label">Reset filters on menu display after</label>
                                    </pos-column>

                                    <pos-column xs="3" class="col-120">
                                        <input posInput type="number" min="0" name="ResetMenuDisplayFilterSeconds"
                                            step="1" posOnlyNumbers
                                            class="text-right chk-section__input config-printing-page__num-input form-control col-100"
                                            [(ngModel)]="parameters.ResetMenuDisplayFilterSeconds"
                                            #resetMenuDisplayFilterSeconds="ngModel" />
                                    </pos-column>

                                    <pos-column class="no-wrap">
                                        <label class="edit-form__section__label">seconds</label>
                                    </pos-column>
                                </pos-row>
                            </pos-form-field>
                        </pos-column>
                    </pos-row>
                </div>
                <div class="col-12 section-border">
                    <div>
                        <h3 class="setting-title">Data Export</h3>
                    </div>
                    <pos-row class="pos-title-content">
                        <pos-column xs="12">
                            <pos-form-field inputStyle="col-xs-12">
                                <pos-row colXs="3">
                                    <pos-column class="col-240 ">
                                        <label class="edit-form__section__label">Manual release queue length</label>
                                    </pos-column>

                                    <pos-column xs="3" class="col-120">
                                        <input posInput type="number" min="0" name="ManualReleaseMaxFileCount"
                                            step="1" posOnlyNumbers
                                            class="text-right chk-section__input config-printing-page__num-input form-control col-100"
                                            [(ngModel)]="parameters.ManualReleaseMaxFileCount"
                                            #ManualReleaseMaxFileCount="ngModel" />
                                    </pos-column>
                                </pos-row>
                            </pos-form-field>
                        </pos-column>
                    </pos-row>
                </div>
            </div>
            <div class="col-12 action-button-container page-footer">
                <button class="btn-primary btn-lg btn btn-save pos-button" scrollup type="submit" value="Save">Save</button>
                <button class="btn-secondary btn-lg btn btn-cancel pos-button" routerLink="../" scrollup type="button"
                    value="Cancel">Cancel</button>
            </div>
        </context-provider>
    </form>
</pos-edit-wrapper>
