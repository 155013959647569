import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { ApplicationStateService, DomainConstants, AlertsService, RuntimeConstants } from 'src/app/shared';
import { TimerOption } from '../../interfaces/timer-option';
import { Observable, interval as observableInterval } from 'rxjs';
import { UpdateTimerStatusModel } from '../../interfaces/update-timer-status-model';
import { TimerService } from '../../services/timer.service';
import { QueuedProduct } from '../../interfaces/queued-product';
import { TimerBroadcastingService } from '../../services/timer-broadcasting.service';
declare let $: any;
import * as _ from 'lodash';
@Component({
  selector: 'pos-timer-control',
  templateUrl: './timer-control.component.html',
  styleUrls: ['./timer-control.component.scss']
})
export class TimerControlComponent implements OnInit, OnDestroy {

  isActiveUpDown: boolean;
  @Input() option: TimerOption;
  counter = '00:00';
  subElapsedTime = 0;
  incrementTime = 0;
  startTime: moment.Moment;
  isTimerStop = true;
  isActiveTimer: boolean;
  timer: Observable<number>;
  timerSubscription: any;
  isTimeHit: boolean;
  isReadyProduct: boolean;
  isManualWithWorkFlowTimer: boolean;
  timeDiff = 0;
  continueTime = 0;
  timeCount = 0;
  timerData;
  productName = '';
  isResetProduct = false;
  @Input() timerProducts: Array<Array<QueuedProduct>> = [];
  audio: HTMLAudioElement = new Audio();
  timerTerminalOptions = DomainConstants.TimerTerminalOptions;
  eventSubscriptions: Array<any> = [];
  constructor(private applicationStateService: ApplicationStateService,
    private timerService: TimerService,
    private alertService: AlertsService,
    private timerBroadcastingService: TimerBroadcastingService) { }

  ngOnInit(): void {
    this.isActiveUpDown = false;
    this.audio.src = this.option.Sound ? `${RuntimeConstants.MEDIA_BASE_PATH}/timer-audio/${this.option.Sound}` : '../../../../assets/sounds/smoke-alarm.mp3';
    this.audio.load();
    this.audio.loop = true;
    this.subscribeBroadcast();
    this.setCounter();
    this.option.reset = (timerData) => {
      this.resetTimer(timerData);
    }
  }

  setCounter() {
    if (this.option.Counter) {
      let minutes: string | number = Math.floor(this.option.Counter / 60);
      let seconds: string | number = Math.floor(this.option.Counter % 60);
      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;
      this.counter = minutes + ':' + seconds;
      this.subElapsedTime = this.option.Counter;
      this.continueTime = this.subElapsedTime;
      this.isActiveTimer = false;
    }
  }

  ngOnDestroy(): void {
    if (this.eventSubscriptions) {
      _.forEach(this.eventSubscriptions, (subscription) => {
        subscription.unsubscribe();
      });
    }
    $(this.audio).finish();
    this.audio = new Audio();
    this.option.reset(this.timerData);
  }

  subscribeBroadcast = () => {
    this.eventSubscriptions.push(this.timerBroadcastingService.productRequest.subscribe(
      (data) => {
        if (data && this.option.Id === data.option.Id) {
          this.productRequested(data.product);
        }
      }
    ));
  }

  startSequence = (foodTime: number, timeDifference: number) => {
    this.startTime = moment();
    this.timer = observableInterval(1000);
    const self = this;
    this.timerSubscription = this.timer.subscribe((t) => {
      self.timerStart(Math.max(foodTime, 0), timeDifference, self.startTime);
    });
  }

  timerStart = (foodTime: number, timeDiffer: number, startTime: moment.Moment) => {
    const currentTime = moment();
    let diffStartOfTime = moment.duration(currentTime.diff(startTime)).asSeconds(); // time difference since start of time;
    diffStartOfTime = Math.floor(diffStartOfTime + timeDiffer);
    this.subElapsedTime = (foodTime - diffStartOfTime) + this.incrementTime;

    if (this.subElapsedTime < 0) {
      if (!this.isTimeHit) {
        this.isReadyProduct = true;
        this.audio.play();
        this.isActiveUpDown = true;
      }
      this.isTimeHit = true;
      if (this.subElapsedTime > -60) {
        this.counter = '00:' + (-1 * this.subElapsedTime);
        this.counter = this.timeFormat(this.counter);
      } else {
        this.counter = Math.floor((-1 * this.subElapsedTime) / 60) + ':' + Math.abs(this.subElapsedTime) % 60;
        this.counter = this.timeFormat(this.counter);
      }
    } else {
      this.isTimeHit = false;
      this.isReadyProduct = false;
      this.audio.pause();
      if (this.subElapsedTime < 60) {
        this.counter = '00:' + this.subElapsedTime;
        this.counter = this.timeFormat(this.counter);
      } else {
        this.counter = Math.floor(this.subElapsedTime / 60) + ':' + this.subElapsedTime % 60;
        this.counter = this.timeFormat(this.counter);
      }
    }
  }

  timeFormat = (time) => {
    const arr = time.split(':');
    if (arr[0].length === 1) {
      arr[0] = 0 + arr[0];
    }
    if (arr[1].length === 1) {
      arr[1] = 0 + arr[1];
    }
    return (arr[0] + ':' + arr[1]);
  }

  startTimer = (timerData) => {
    if (!this.option.IsManual
      && !this.isManualWithWorkFlowTimer) {
      this.timerData = timerData ? timerData : {};
      this.option.TimerData = timerData ? timerData : {};
      if (this.continueTime > 0) {
        this.timeCount = this.continueTime;
      } else {
        this.timeCount = timerData ? timerData.PrepTime : 0;
      }
      this.continueTime = 0;
      if (timerData && timerData.MakeTableText !== '') {
        this.productName = timerData ? timerData.MakeTableText : '';
      }
      this.isActiveUpDown = false;
      this.isActiveTimer = true;
      this.isTimerStop = false;
      this.startSequence(this.timeCount, this.timeDiff);
    } else {
      this.isActiveTimer = true;
      this.incrementTime = 0;
      this.isTimerStop = false;
      this.startSequence(this.continueTime, 0);
      // $scope.LoadFirstTime = false;
    }
  }

  stopTimer = (timerData) => {
    this.audio.pause();
    if (!this.option.IsManual &&
      !this.isManualWithWorkFlowTimer) {
      this.counter = '00:00';
      this.isActiveUpDown = true;
      this.timerData = timerData ? timerData : {};
      this.isResetProduct = false;
      this.isActiveTimer = true;
      this.isTimerStop = true;
      if (timerData && timerData.IsUseWorkflowTimer) {
        this.sendTimerStateChangeMessageToMakeTable(timerData, DomainConstants.MakeTableStates.COOKING + ',' +
          DomainConstants.MakeTableStates.CRAFTING, DomainConstants.TimerStates.DONE);
      }
    } else {
      this.isActiveTimer = false;
      this.incrementTime = 0;
      this.isTimerStop = true;
      this.continueTime = this.subElapsedTime;
    }
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
    const currentTime = moment();
    this.timeDiff = moment.duration(currentTime.diff(this.startTime)).asSeconds(); // time difference since start of time
  }

  resetTimer = (timerData) => {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
    this.audio.pause();
    this.isActiveTimer = true;
    this.counter = '00:00';
    this.isTimerStop = true;
    this.productName = '';
    this.isResetProduct = false;
    this.subElapsedTime = 0;
    this.isReadyProduct = false;
    this.timeDiff = 0;
    this.incrementTime = 0;
    this.timeCount = 0;
    if (!this.option.IsManual &&
      !this.isManualWithWorkFlowTimer) {
      if (timerData && timerData.IsUseWorkflowTimer) {
        // When Cooking is start at that time timerState will update with 'Queued'
        if (timerData.TimerState === DomainConstants.TimerStates.COOKING) {
          this.sendTimerStateChangeMessageToMakeTable(timerData, DomainConstants.MakeTableStates.COOKING + ',' +
            DomainConstants.MakeTableStates.CRAFTING, DomainConstants.TimerStates.QUEUED);
        }
      }
      if (this.option.TimerData) {
        this.option.TimerData.IsUpdate = false;
        this.option.TimerData = null;
      }

      this.option.OrderId = null;
    }
    this.isManualWithWorkFlowTimer = false;
    this.isActiveUpDown = false;
    this.setCounter();
  }

  productRequested = (timerData: QueuedProduct) => {
    this.isManualWithWorkFlowTimer = false;
    this.isResetProduct = true;
    this.isActiveTimer = false;
    this.isTimerStop = true;
    this.timeDiff = 0;
    this.continueTime = 0;
    this.option.OrderId = timerData ? timerData.OrderId : null;
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
    this.startTimer(timerData);
    if (timerData && timerData.IsUseWorkflowTimer) {
      this.option.productRequest(timerData);
      this.sendTimerStateChangeMessageToMakeTable(timerData, DomainConstants.MakeTableStates.COOKING + ',' +
        DomainConstants.MakeTableStates.CRAFTING, DomainConstants.TimerStates.COOKING);
    }
  }


  sendTimerStateChangeMessageToMakeTable = (timerData: QueuedProduct, makeTableState, timerState) => {
    const product: UpdateTimerStatusModel = {
      TerminalId: this.applicationStateService.terminalId,
      TimerTerminalId: timerState == DomainConstants.TimerStates.COOKING ||
        timerState == DomainConstants.TimerStates.DONE ? this.applicationStateService.terminalId : null,
      OrderId: timerData.OrderId,
      OrderProductComponentId: timerData.ComponentId,
      // ToSelect: false,
      MakeTableState: makeTableState,
      TimerState: timerState
    };
    if (timerState == DomainConstants.TimerStates.DONE) {
      this.timerData.TimerState = DomainConstants.TimerStates.DONE;
    } else if (timerState == DomainConstants.TimerStates.COOKING) {
      this.timerData.TimerState = DomainConstants.TimerStates.COOKING;
    }
    this.timerService.updateProductState(timerData.OrderId, timerData.ProductId, timerData.ComponentId, product)
      .subscribe({
        next: (res: void) => {
        }, error: this.alertService.showApiError
      });
  }

  timerUp = () => {
    if (this.option.IsManual && this.isTimerStop) {
      this.isActiveTimer = false;
    } else if (!this.option.OrderId
      && !this.option.IsManual
      && this.isTimerStop) { // NOTE: Use when timer stop is disable and slot use as a timerOnly
      this.isManualWithWorkFlowTimer = true;
      this.isActiveTimer = false;
    }
    this.timeCount = Number(this.timeCount);
    if (this.isTimerStop) {
      this.incrementTime = 0;
      this.timerStart(this.subElapsedTime + 30, 0, moment());
      this.timeDiff = 0;
      this.continueTime = this.subElapsedTime;
    } else {
      this.incrementTime = this.incrementTime + 30;
    }
  }

  timerDown = () => {
    if (this.subElapsedTime > 0) {
      this.timeCount = Number(this.timeCount);
      this.timeCount = Math.max(this.timeCount - 30, 0);
      if (this.isTimerStop) {
        this.incrementTime = 0;
        this.timerStart(this.subElapsedTime - 30, 0, moment());
        this.timeDiff = 0;
        this.continueTime = this.subElapsedTime;
      } else {
        if (!this.isTimeHit) {
          this.incrementTime = this.incrementTime - 30;
        }
      }
    }
  }

  productClick = () => {
    this.option.onComplete(this.option);
  }

}
