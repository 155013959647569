import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AdfModel } from '../../interfaces';
import { AdfDashboardService } from '../../services/adf-dashboard.service';
import { AdfUtilsService } from '../../services/adf-utils.service';

@Component({
  selector: 'pos-adf-dashboard-edit',
  templateUrl: './adf-dashboard-edit.component.html',
})
export class AdfDashboardEditComponent implements OnInit {

  @Input() adfModel: AdfModel;
  @Output() close: EventEmitter<AdfModel> = new EventEmitter();
  layouts: Array<{ key, value }> = [];

  constructor(private adfUtilsService: AdfUtilsService,
    private adfDashboardService: AdfDashboardService) { }

  ngOnInit(): void {
    this.layouts = this.splitValues(this.adfModel.layouts, 3);
  }

  splitValues = (model, size) => this.adfUtilsService.split(model, size);

  cancelEditing = () => {
    this.close.emit(this.adfModel);
  }

  changeStructure = (name, structure) => {
    console.log('change structure to ' + name);
    this.adfModel.LayoutName = name;
    this.adfDashboardService.changeStructure(this.adfModel, structure);
    this.adfModel.LayoutId = structure.value.layoutId;
    if (this.adfModel.structure !== name) {
      this.adfModel.structure = name;
    }
  }
}
