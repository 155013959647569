<div class="h-80">
  <pos-header-entry-main [links]="headerLinks"></pos-header-entry-main>
</div>
<div class="page order-page">
  <section class="order-section">
    <div class="col-md-8"
      [ngClass]="{'float-end':configurations.UserPreferenceInvoiceLocation=='left' && windowHeight >= 765,'float-start':configurations.UserPreferenceInvoiceLocation=='right' && windowHeight >= 765}">
      <div class="row mx-0">
        <div class="order-section__products p-0">
          <pos-order-navigation [(infoMode)]="productShow" [hidden]="isShowCliTerminal" #orderNavigation
            [orderId]="orderId"></pos-order-navigation>
          <div class="tab-content thick-scrollbar overflow-auto-tabs" style="width:100%">
            <div class="col-12 p-0 tab-pane fade tab-pane-m-p"
              *ngIf="selectedTab == tabList.CliTerminal">
            </div>
          </div>
        </div>
      </div>
    </div>
    <pos-order-invoice [loadOrderId]="orderId"></pos-order-invoice>
  </section>
</div>
<pos-scan-barcode (barcodeScanned)="scanBarcode($event)"></pos-scan-barcode>
