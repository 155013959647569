<div class="page log-viewer-page">
	<div class="col-md-12 px-3 tab-section">
		<div id="LogList">
			<div class="left-nav-toggle" id="menu-toggle-div" (click)="toggleFullScreen()">
				<a class="left-nav-toggle__icon">
					<pos-icon class="black"
						[icon]="isFullScreen ? icons.angleDoubleRight : icons.angleDoubleLeft"></pos-icon>
				</a>
			</div>
			<div>
				<div class="mt-pix-10 vertical-tabs">
					<ul class="nav nav-pills nav-stacked flex-column" [hidden]="isFullScreen">
						<li class="tab-head">Logs</li>
						<li class="pointer" role="presentation" [ngClass]="{'active': selectedFileName == fileName}"
							*ngFor="let fileName of logFileNames" scrollup
							(click)="selectedFileName = fileName; displayContent(fileName)">
							<a>
								<div class="display-flex">
									{{fileName}}
								</div>
							</a>
						</li>
					</ul>
					<div *ngIf="selectedFileName != '' && logs.length!==0">
						<pos-list-wrapper  [heading]="selectedFileName" (close)="close()">
							<div class="log-details__level">
								<div class="{{log.cssClass}} mb-pix-10 mt-pix-10"
									*ngFor="let log of logs | orderBy : 'time'">
									<b>{{log.level}} ({{log.time | tark_date_time: true }}):</b> {{log.message}}
									<hr class="mb-pix-10 mt-pix-10" />
								</div>
							</div>
						</pos-list-wrapper>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
