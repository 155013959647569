import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { find } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ButtonFunctionType, FormUtilityService, Messages, StringUtils } from 'src/app/shared';
import { InfoModalComponent, ModalFormComponent, ModalService } from 'src/app/shared/components';
import { DomainConstants } from 'src/app/shared/constants';
import { ButtonBehavior } from 'src/app/shared/interface/button-behavior';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';

@Component({
  selector: 'pos-singular-fee',
  templateUrl: './singular-fee.component.html',
})
export class SingularFeeComponent extends ModalFormComponent implements OnInit {
  @ViewChild('singularFeeForm') singularFeeForm: NgForm;
  @Input() selectedButtonBehaviorType;
  @Input() buttonBehavior: ButtonBehavior = null;
  @Input() buttonType: string;
  @Input() selectedFunction: string;
  name = '';
  isTaxable = true;
  amount = null;
  currencySymbol = null;
  singularFeeTypes = DomainConstants.SingularFeeTypes;
  feeType = this.singularFeeTypes.PerProduct;
  isShowFeeType = true;

  get getForm(): NgForm {
    return this.singularFeeForm;
  }

  constructor(
    private applicationStateService: ApplicationStateService,
    modalRef: BsModalRef, modalService: ModalService, protected formUtilityService: FormUtilityService) {
      super(modalRef, modalService, formUtilityService);
    }

  ngOnInit(): void {
    if (this.buttonBehavior && this.buttonBehavior.Id) {
      try {
        const behavior = JSON.parse(this.buttonBehavior.TextValue);
        this.name = behavior && behavior.name ? behavior.name : '';
        this.isTaxable = behavior && behavior.isTaxable;
        this.amount = behavior && behavior.amount ? behavior.amount : null;
        this.feeType = behavior && behavior.feeType ? behavior.feeType : DomainConstants.SingularFeeTypes.PerProduct;
        this.setInitialValue();
      } catch (ex) {
        console.error('Invalid behavior value: ' + this.buttonBehavior.TextValue);
      }
    }
    if (this.buttonType === DomainConstants.ButtonTypes.Functional &&
        this.selectedFunction !== DomainConstants.ButtonFunctionTypes.SET_SIZE.Code) {
      this.isShowFeeType = false;
      this.feeType = DomainConstants.SingularFeeTypes.PerOrder;
    }
    this.currencySymbol = this.applicationStateService.settingParam.CurrencySymbol;
  }

  saveBehavior = (isValid: boolean) => {
    let functionType: ButtonFunctionType = find(DomainConstants.ButtonFunctionTypes, x => x.Code == this.selectedFunction);
    const isSingularFeeApplicable = functionType?.IsSingularFeeApplicable ?? true;
    if (!isSingularFeeApplicable) {
      const modalRef = this.modalService.getModalWrapper(InfoModalComponent);
      modalRef.show({
        animated: false,
        class: 'vertical-center',
        initialState: {
          message: StringUtils.format(Messages.SingularFeeNotApplicable, { functionType: functionType.Name })
        }
      });
    }
    if (isValid) {
      this.hide({
        shouldReload: true,
        Value: {
          name: this.name.trim(),
          isTaxable: this.isTaxable,
          amount: this.amount,
          feeType: this.feeType,
        },
      });
    }
  }

  closeModal = () => {
    this.hide({ shouldReload: false });
  }
}
