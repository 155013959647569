import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IngredientsDietaryWarning } from '../interface/ingredients-dietary-warning';
import { RuntimeConstants } from 'src/app/shared';

@Injectable()
export class IngredientsDietaryWarningService {
  constructor(private httpService: HttpClient) { }

  getIngredientsDietaryWarningInfo(productId: number): Observable<IngredientsDietaryWarning[]> {
    return this.httpService.get<IngredientsDietaryWarning[]>
      (`${RuntimeConstants.API_PATH}ingredientsdietarywarning/` + productId);
  }
}
