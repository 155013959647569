import { GridColumn } from '@tarktech/tark-ng-utils';
import { ModalComponent } from './../../../../shared/components/modal/modal-component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';
import { RawIngredientsService } from '../../service';
import { AlertsService, SpinnerService } from 'src/app/shared';

@Component({
  selector: 'pos-sales-products-ingredient',
  templateUrl: './sales-products-ingredient.component.html',
  styleUrls: ['./sales-products-ingredient.component.scss']
})
export class SalesProductsIngredientComponent extends ModalComponent implements OnInit {
  rawIngredientId: number;
  salesProducts: any = [];
  salesProductColumns: Array<GridColumn> = [];
  constructor(
    salesIngredientModalRef: BsModalRef,
    private rawIngredientService: RawIngredientsService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService
  ) {
    super(salesIngredientModalRef);
  }

  ngOnInit() {
    this.getInventoryProducts(this.rawIngredientId);
    this.configureGridColumns();
  }

  onCancel(reload: boolean) {
    this.hide({ shouldReload: reload });
  }

  getInventoryProducts(rawIngredientId) {
    this.spinnerService.show();
    this.rawIngredientService.getSalesProduct(rawIngredientId)
      .subscribe({
        next: (response) => {
          if (response) {
            this.salesProducts = response;
          } else {
            this.salesProducts = [];
          }
        }, error: this.alertService.showApiError,
        complete: () => {
          this.spinnerService.hide();
        }
      });
  }

  private configureGridColumns() {
    this.salesProductColumns = [
      new GridColumn({ HeaderText: 'Product Name', Field: 'SalesProduct', Width: '30%' }),
      new GridColumn({ HeaderText: 'Size', Field: 'Size', Width: '10%' })
    ] as Array<GridColumn>;
  }
}
