<div class="h-80">
    <pos-header-entry-main [links]="headerLinks"></pos-header-entry-main>
</div>
<div class="col-md-12 p-0">
    <div style="padding-top: 0.2%" *ngIf="pickScreenProducts && pickScreenProducts.length > 0">
        <div class="grid white" class="best-fit">
            <div class="container best-fit px-pix-10">
                <div *ngFor="let column of columns" [ngStyle]="{'width': 100/noOfColumns + '%'}"
                    class="column {{'col-'+ column}}">
                    <ng-template ngFor let-item [ngForOf]="getDataForColumn(column)" let-i="index" [ngForTrackBy]="trackByOrderProductId">
                        <ng-container
                            *ngTemplateOutlet="itemTemplate;context:{pickScreenProducts:item, index:i*noOfColumns+column-1}">
                        </ng-container>
                    </ng-template>
                </div>
            </div>
            <ng-template #itemTemplate let-item="pickScreenProducts" let-i="index">
                <div>
                    <div class='pull-left p-1' style="width:100%">
                        <pos-pick-screen-item [pickScreenProduct]="item" [(highlightTimeouts)]="highlightTimeouts"></pos-pick-screen-item>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>
<div class="bottom-time-block text-nowrap">
    <pos-display-time></pos-display-time>
</div>
