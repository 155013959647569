import { Component, OnInit, EventEmitter, Input, ViewChild } from '@angular/core';
import { ICloseable, ModalService } from 'src/app/shared/components/modal';
import { TableOrderDetailsComponent } from 'src/app/layout-designer';
import { AlertsService, SpinnerService, ApplicationStateService, SettingParam, DomainConstants } from 'src/app/shared';
import { fieldInfoCircle } from 'src/app/shared/components/icon/icons'
import { finalize } from 'rxjs/operators';
import * as _ from 'lodash';
import { LayoutDesignersService } from 'src/app/shared/services/layout-designers.service';
declare let mxUtils: any;
declare let $: any;
@Component({
  selector: 'pos-table-selection',
  templateUrl: './table-selection.component.html',
  styleUrls: ['./table-selection.component.scss']
})
export class TableSelectionComponent implements OnInit, ICloseable {

  close: EventEmitter<any> = new EventEmitter();
  public icons = {
    fieldInfoCircle
  };
  @Input('mappedLayout') mappedLayout = [];
  @Input('orders') orders: Array<any> = [];
  @Input('accounts') accounts: Array<any> = [];
  @ViewChild('layoutDesign') layoutDesign: any;
  @Input('isShowOccupiedTable') isShowOccupiedTable: boolean = false;
  layouts: Array<any> = [];
  height: number = 0;
  width: number = 0;
  settingParam: SettingParam;
  oneActiveOrderPerAccount = false;
  selectedAccount: any;
  activeLayoutIndex = 0;
  tableTerm: string = '';
  constructor(private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private layoutService: LayoutDesignersService,
    private applicationStateService: ApplicationStateService,
    private modalService: ModalService) { }

  ngOnInit() {
    this.getAccountVisualizationData();
    this.height = $(window).height() - 275 > 630 ? 650 : $(window).height() - 265;
    this.width = $('#mainContainer').width() - 70;
    this.settingParam = this.applicationStateService.settingParam;
    this.oneActiveOrderPerAccount = this.settingParam.OneActiveOrderPerAccount;
    this.setDefault();
  }

  setDefault() {
    this.settingParam = this.applicationStateService.settingParam;
    this.tableTerm = this.settingParam.TableTerm ? this.settingParam.TableTerm : 'Table';
  }

  onClose() {
    this.close.emit({ shouldReload: false });
  }

  getHeight() {
    if ($(window).height() - 275 > 630) {
      return 650;
    }
    return ($(window).height() - 275 > 305 ? $(window).height() - 275 : 305);
  }

  getAccountVisualizationData() {
    this.spinnerService.show();
    this.layoutService.getAll()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          if (res) {
            _.forEach(this.mappedLayout, (item) => {
              const currentLayout = _.find(res, (layout) => {
                return layout.Id == item;
              });
              if (currentLayout) {
                if (this.isShowOccupiedTable) {
                  this.removeUnOccupiedShapes(currentLayout);
                }
                this.changeOccupiedTableColor(currentLayout);
                this.changeGridView(currentLayout);
                this.layouts.push(currentLayout);
              }
            })
          } else {
            this.layouts = [];
          }
        }, error: this.alertService.showApiError
      });
  }

  changeGridView = (layout) => {
    // grid="1" gridSize="10" guides="1"
    const doc = mxUtils.parseXml(layout.LayoutDefinition);
    const mxGraph = doc.getElementsByTagName('mxGraphModel');
    if (mxGraph && mxGraph.length) {
      mxGraph[0].setAttribute('grid', 0);
      mxGraph[0].setAttribute('gridSize', 0);
      mxGraph[0].setAttribute('guides', 0);
    } else {
      layout.isBlank = true;
    }
    layout.LayoutDefinition = (new XMLSerializer()).serializeToString(doc);
  }

  removeUnOccupiedShapes = (layout) => {
    const doc = mxUtils.parseXml(layout.LayoutDefinition);
    const mxCells = doc.getElementsByTagName('mxCell');
    const root = mxUtils.parseXml(layout.LayoutDefinition).documentElement;
    const currNode = root.childNodes[0];
    _.forEach(mxCells, (mxCell, index) => {
      const isAccount = this.getAttribute(mxCells[index], 'IsAccount') == 'true';
      const accountId = this.getAttribute(mxCells[index], 'AccountId');
      let orderDetails = [];
      if (isAccount) {
        orderDetails = this.getOrderDetailsByAccountId(accountId);
      }
      if (isAccount && (!orderDetails || !orderDetails.length)) {
        const currentCellId = this.getAttribute(mxCells[index], 'id');
        const nodeIndex = _.findIndex(currNode.childNodes, (e: any) => {
          const ind = e.nodeName == 'mxCell' ? this.getAttribute(e, 'id') : null;
          return ind == currentCellId;
        });
        currNode.removeChild(currNode.childNodes[nodeIndex]);
      }
    });
    layout.LayoutDefinition = (new XMLSerializer()).serializeToString(root);
  }

  setIsAccountAttributes(layout) {
    const doc = mxUtils.parseXml(layout.LayoutDefinition);
    const mxCells = doc.getElementsByTagName('mxCell');
    _.forEach(this.accounts, (account) => {
      if (layout.Id == account.LayoutId) {
        for (let cell of mxCells) {
          const currentTag = this.getAttribute(cell, 'id');
          if (currentTag == account.ShapeId) {
            cell.setAttribute('IsAccount', true);
            cell.setAttribute('AccountId', account.AccountId);
          }
        }
      }
    });
    layout.LayoutDefinition = (new XMLSerializer()).serializeToString(doc);
  }

  getAccountAttributes = (shape, layoutDetails) => {
    const doc = mxUtils.parseXml(layoutDetails.LayoutDefinition);
    const mxCells = doc.getElementsByTagName('mxCell');
    for (let cell of mxCells) {
      const currentTag = this.getAttribute(cell, 'id');
      if (currentTag == shape.id) {
        shape.IsAccount = this.getAttribute(cell, 'IsAccount') == 'true';
        shape.AccountId = this.getAttribute(cell, 'AccountId');
      }
    }
  }

  getOrderDetailsByAccountId = (accountId): Array<any> => {
    const orderDetails = [];
    _.forEach(this.orders, (order) => {
      if (order.AccountId == accountId) {
        const orderDetail = {
          OrderId: order.id,
          SurrogateOrderId: order.SurrogateOrderId,
          Total: order.GrandTotal,
          Amount: order.GrandTotal,
        };
        orderDetails.push(orderDetail);
      }
    });
    return orderDetails;
  }

  changeOccupiedTableColor = (layout) => {
    const doc = mxUtils.parseXml(layout.LayoutDefinition);
    const mxCells = doc.getElementsByTagName('mxCell');
    _.forEach(this.accounts, (account) => {
      if (layout.Id == account.LayoutId) {
        for (let cell of mxCells) {
          const currentTag = this.getAttribute(cell, 'id')
          if (currentTag == account.ShapeId) {
            const orderDetails = this.getOrderDetailsByAccountId(account.AccountId);
            if (orderDetails && orderDetails.length) {
              const currentStyle = this.getAttribute(cell, 'style');
              let style = '';
              if (currentStyle) {
                const styleArray = currentStyle.split(';');
                _.forEach(styleArray, (key) => { style += !key || key.includes('fillColor') ? '' : (key + ';'); });
                style += 'fillColor=lightsalmon;strokeWidth=3;dashed=1';
                cell.setAttribute('style', style);
              }
            }
          }
        }
      }
    });
    layout.LayoutDefinition = (new XMLSerializer()).serializeToString(doc);
  }

  getAttribute = (cell, attributeName) => {
    return cell ? cell.getAttribute(attributeName) : null;
  }

  selectTable(data) {
    if (data) {
      const table = data.shape;
      const layoutDetails = data.layout;
      this.getAccountAttributes(table, layoutDetails);
      if (table && table.IsAccount && table.AccountId) {
        table.OrderDetails = this.getOrderDetailsByAccountId(table.AccountId);

        if (table.OrderDetails && table.OrderDetails.length) {
          if (table.OrderDetails.length == 1 && this.isShowOccupiedTable) {
            table.OrderId = table.OrderDetails[0].OrderId;
            const details = {
              accountId: table.AccountId, orderId: table.OrderId, AccountType: DomainConstants.AccountTypes.Table, Name: table.value,
              Id: table.AccountId
            }
            this.close.emit(details);
          } else {
            if (!this.selectedAccount) {
              this.openOrderDetailsModel(table);
            }
          }
        } else {
          const details = {
            accountId: table.AccountId, orderId: table.OrderId, AccountType: DomainConstants.AccountTypes.Table, Name: table.value,
            Id: table.AccountId
          }
          this.close.emit(details);
        }
      }
    }
  }

  openOrderDetailsModel = (accountVisualization) => {
    this.selectedAccount = accountVisualization;
    const modalRef = this.modalService.getModalWrapper(TableOrderDetailsComponent);
    const TableOrderDetailsComponentModalRef = modalRef.show({
      animated: false,
      keyboard: false,
      class: 'vertical-center modal-md',
      initialState: {
        orders: accountVisualization.OrderDetails,
        tableName: accountVisualization.IsAccount && accountVisualization.value ? accountVisualization.value : '',
        isShowOccupiedTable: this.isShowOccupiedTable
      }
    });
    TableOrderDetailsComponentModalRef.close.subscribe(res => {
      this.selectedAccount = null;
      if (res && res.shouldSelectTable) {
        if (res.orderData) {
          accountVisualization.OrderId = res.orderData.OrderId;
          const details = {
            accountId: accountVisualization.AccountId, orderId: accountVisualization.OrderId,
            AccountType: DomainConstants.AccountTypes.Table, Name: accountVisualization.value,
            Id: accountVisualization.AccountId
          }
          this.close.emit(details);
        } else {
          const details = {
            accountId: accountVisualization.AccountId, orderId: accountVisualization.OrderId,
            AccountType: DomainConstants.AccountTypes.Table, Name: accountVisualization.value,
            Id: accountVisualization.AccountId
          }
          this.close.emit(details);
        }
      }
      if (res?.shouldOpenOrder) {
        this.onClose();
      }
    });
  }

}
