import { Injectable } from '@angular/core';
import { cloneDeep, filter, find, forEach, groupBy, orderBy, remove, sum } from 'lodash';
import { DomainConstants } from 'src/app/shared/constants/domain-constants';

@Injectable({
  providedIn: 'root'
})
export class SettleSeatService {
  totalFlatDiscount: number = 0;
  dividedDiscount: number = 0;
  confirmedSeat = 0;
  constructor() {
    this.totalFlatDiscount = 0;
    this.dividedDiscount = 0;
  }




  getSeatCount(orderData) {
    const order = cloneDeep(orderData);
    remove(order.OrderItems, (item: any) => {
      return !item.SubAccountOrdinal;
    });
    const availableSeats = groupBy(order.OrderItems, 'SubAccountOrdinal');
    const seats = Object.keys(availableSeats);
    remove(seats, (seat) => {
      return !seat;
    });
    return Object.keys(seats).length;
  }

  setFlatMarkupAndMarkupTax = (orderData, seats) => {
    const totalFlatMarkup = this.getTotalFlatMarkup(seats);
    const totalFlatMarkupTax = this.getFlatMarkupTaxForSeat(orderData);
    const availableSeatCount = this.getSeatCount(orderData);
    if (totalFlatMarkup) {
      const dividedMarkup = totalFlatMarkup / availableSeatCount;
      let dividedMarkupTax = 0;
      if (totalFlatMarkupTax) {
        dividedMarkupTax = totalFlatMarkupTax / availableSeatCount;
      }
      forEach(seats, (seat, index) => {
        seat.FlatMarkup = dividedMarkup;
        seat.FlatMarkupTax = dividedMarkupTax;
      });
    }
  }

  getTotalFlatDiscount = (seats): number => {
    let totalFlatDiscount = 0;
    forEach(seats, (seat) => {
      forEach(seat, (item) => {
        if (item.OrderItemType === DomainConstants.OrderItemTypes.Discount) {
          totalFlatDiscount += item.ActualPrice * -1;
        }
      });
      totalFlatDiscount -= seat.FlatDiscount ? seat.FlatDiscount : 0;
    });
    return totalFlatDiscount;
  }

  getTotalFlatMarkup = (seats) => {
    let totalFlatMarkup = 0;
    forEach(seats, (seat) => {
      forEach(seat, (item) => {
        if (item.OrderItemType === DomainConstants.OrderItemTypes.Markup) {
          totalFlatMarkup += item.ActualPrice;
        }
      });
    });
    return totalFlatMarkup;
  }

  precisionRound(number, precision) {
    const factor = Math.pow(10, precision);
    return Math.round(number * factor) / factor;
  }

  getItemTotalForSeat = (seat): number => {
    let itemTotal = 0;
    forEach(seat, (item) => {
      itemTotal += item.ActualPrice;
    });
    return itemTotal;
  }


  getFlatMarkupTaxForSeat = (orderData): number => {
    let markupTax = 0;
    forEach(orderData.OrderItems, (item) => {
      if (item.OrderItemType === DomainConstants.OrderItemTypes.Markup) {
        markupTax += item.TaxAmount;
      }
    });
    return markupTax ? markupTax : 0;
  }

  getPercentDiscountForSeat = (seatTotal: number, discountPct: number): number => {
    return seatTotal * discountPct / 100;
  }

  getPercentMarkupForSeat = (seatTotal: number, markupPct: number): number => {
    return seatTotal * markupPct / 100;
  }

  remainingSeats = (seats): number => {
    const test = filter(seats, (seat) => {
      const functionalItems = find(seat, (item) => {
        return item.SubAccountOrdinal === 0;
      });
      return !functionalItems && !seat.FlatDiscount;
    });
    return test ? test.length : 1;
  }

  setFlatDiscount = (orderData, seats) => {
    this.totalFlatDiscount = this.getTotalFlatDiscount(seats);
    const availableSeatCount = this.getSeatCount(orderData);

    if (this.totalFlatDiscount) {
      this.dividedDiscount = this.totalFlatDiscount / (this.remainingSeats(seats));
      const seatCount = availableSeatCount - (this.confirmedSeat);
      forEach(orderBy(seats, 'Total'), (seat, index) => {
        const functionalItems = find(seat, (item) => {
          return item.SubAccountOrdinal === 0;
        });
        if (!functionalItems) {
          if (seat.Total - this.dividedDiscount >= 0) {
            const discount = seat.FlatDiscount;
            seat.FlatDiscount = !seat.FlatDiscount ? this.dividedDiscount : seat.FlatDiscount;
            this.totalFlatDiscount -= discount ? 0 : this.dividedDiscount;
            this.dividedDiscount = this.totalFlatDiscount ? (this.totalFlatDiscount / (this.remainingSeats(seats))) : 0;
          } else if (seat.Total > 0) {
            this.totalFlatDiscount -= seat.FlatDiscount ? 0 : seat.Total;
            seat.FlatDiscount = !seat.FlatDiscount ? seat.Total : seat.FlatDiscount;
            this.dividedDiscount = this.totalFlatDiscount ? (this.totalFlatDiscount / (this.remainingSeats(seats))) : 0;
          }
        }
      });
    }
  }

  calculateAndSetSeatAmounts = (orderData, seats, seat) => {
    const itemTotal = this.getItemTotalForSeat(seat);
    this.setFlatDiscount(orderData, seats);
    this.setFlatMarkupAndMarkupTax(orderData, seats);
    seat.Total = itemTotal + (seat.FlatMarkup ? seat.FlatMarkup : 0) - (seat.FlatDiscount ? seat.FlatDiscount : 0);
    seat.PctMarkup = this.getPercentMarkupForSeat(seat.Total, orderData.MarkupPct);
    seat.Total = seat.Total + (seat.PctMarkup ? seat.PctMarkup : 0);
    seat.PctDiscount = this.getPercentDiscountForSeat(seat.Total, orderData.DiscountPct);
    seat.Discount = (seat.FlatDiscount ? seat.FlatDiscount : 0) + (seat.PctDiscount ? seat.PctDiscount : 0);
    seat.Markup = (seat.FlatMarkup ? seat.FlatMarkup : 0) + (seat.PctMarkup ? seat.PctMarkup : 0);
    seat.MarkupTax = (seat.FlatMarkupTax ? seat.FlatMarkupTax : 0);
    seat.Total = seat.Total - (seat.PctDiscount ? seat.PctDiscount : 0);
    seat.Tax = this.precisionRound(this.precisionRound(orderData.TaxTotal * seat.Total, 2) /
      this.precisionRound(orderData.GrandTotal - orderData.TaxTotal, 2), 2);
  }
}
