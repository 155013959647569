<pos-edit-wrapper heading="Find Next Screen for {{buttonInfo.ButtonText}}" (close)="cancel()">
  <div class="pt-pix-10 overflow-hidden">
    <form #chooseNextScreenForm="ngForm" autocomplete="off" (ngSubmit)="saveNextScreen()">
      <div class="col-12 pos-modal-body">
        <div class="col-12 mb-pix-10 row mx-0">
          <div class="col-md-2 col-12 pt-pix-10 px-pix-5 text-align-center">
            <img class="product-block__detail__info__img-section__img" [hidden]="!buttonInfo.Image"
              alt="{{buttonInfo.Image}}" src="{{imageBaseFile}}/buttons/{{buttonInfo.Image}}"
              style="max-height: 60px;" />
            <div class="text-ellipsis bold">{{buttonInfo.ButtonText}}</div>
          </div>
          <div class="col-md-10 col-12 pt-pix-3 row mx-0 align-items-center">
            <div class="col-lg-9 col-md-8 col-8 pe-0">
              <ng-select #screen="ngModel" name="screen" [items]="screens" bindLabel="Screen" bindValue="id"
                (change)="onScreenSelectionChange(selectedScreen)" [(ngModel)]="selectedScreen"
                placeholder="Select next screen">
              </ng-select>
            </div>
            <div class="col-lg-2 col-4 pe-0"
              permissions='[{"Name":"{{permission.name}}", "Level":"{{permission.editLevel}}"}]'>
              <button type="button" class="btn btn-primary btn normal me-pix-15 pos-button white" (click)="addScreen()">
                <pos-icon [icon]="icons.plusMessage" class="pointer"></pos-icon> New
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-sm-12 col-12">
          <legend class="mb-pix-10 font-14 border-bottom">
            <span class="bold p-pix-5"> Preview </span>
          </legend>
          <div class="col-12 p-0 overflow-auto pos-title-content" *ngIf="selectedScreen"
            [ngStyle]="{'height' : getHeight()}">
            <pos-screen-preview #screenPreviewComponent [screenId]="selectedScreen" [allButtons]="allButtons">
            </pos-screen-preview>
          </div>
          <div class="col-12 ps-pix-5" *ngIf="!selectedScreen"
            [ngStyle]="{'height' : getHeight()}">
            No button(s) found.
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="col-12 action-button-container popup-footer">
        <button class="btn-primary btn-lg btn btn-save pos-button" type="submit" value="Save">Save</button>
        <button class="btn-secondary btn-lg btn btn-cancel-modal pos-button" (click)="cancel()" type="button"
          value="Cancel">Cancel</button>
      </div>
    </form>
  </div>
</pos-edit-wrapper>
