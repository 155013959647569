import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ComponentsModule } from '../shared';
import { HeaderEntryModule } from '../header-entry';
import { TimerComponent } from './components/timer/timer.component';
import { TIMER_ROUTES } from './timer-routes';
import { TimerControlComponent } from './components/timer-control/timer-control.component';
import { TimerService } from './services/timer.service';
import { TimerBroadcastingService } from './services/timer-broadcasting.service';
import { NgPipesModule } from 'ngx-pipes';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ModalModule.forRoot(),
    ComponentsModule,
    RouterModule.forChild(TIMER_ROUTES),
    HeaderEntryModule,
    NgPipesModule
  ],
  providers: [TimerService, TimerBroadcastingService],
  declarations: [TimerComponent, TimerControlComponent]
})
export class TimerModule { }
