<pos-edit-wrapper [headingIcon]="icons.colorsPaletter" heading="Colors" (close)="cancelEditing()" [isPopup]="false">
  <form (ngSubmit)="saveColors(colorsForm.valid)" #colorsForm="ngForm" class="edit-form">
    <div class="col-xs-12 px-4 border-top configure-colors-page content-scroll">
      <div class="col-xs-12">
        <div>
          <h3 class="setting-title">Theme Colors</h3>
        </div>
        <pos-row colXs="12" class="pos-title-content">
          <pos-column>
            <pos-row colSm="6" colXs="12">
              <pos-column class="col-255">
                <pos-form-field label="Heading Color" [fieldInfo]="fieldInfoMessages.ColorsHeading">
                  <pos-color-picker posInput [(ngModel)]="parameters.ColorPreference.HeadingColor"
                    name="headingColor"></pos-color-picker>
                </pos-form-field>
              </pos-column>

              <pos-column>
                <pos-form-field label="Font Color">
                  <pos-color-picker posInput [(ngModel)]="parameters.ColorPreference.HeadingFontColor"
                    name="headingFontColor"></pos-color-picker>
                </pos-form-field>
              </pos-column>
            </pos-row>
          </pos-column>

          <pos-column>
            <pos-row colSm="6" colXs="12">
              <pos-column class="col-255">
                <pos-form-field label="Trim Color" [fieldInfo]="fieldInfoMessages.ColorsTrim">
                  <pos-color-picker posInput [(ngModel)]="parameters.ColorPreference.TrimColor"
                    name="trimColor"></pos-color-picker>
                </pos-form-field>
              </pos-column>

              <pos-column>
                <pos-form-field label="Font Color">
                  <pos-color-picker posInput [(ngModel)]="parameters.ColorPreference.TrimFontColor"
                    name="trimFontColor"></pos-color-picker>
                </pos-form-field>
              </pos-column>
            </pos-row>
          </pos-column>


        </pos-row>
      </div>

      <div class="col-xs-12 section-border">
        <div>
          <h3 class="setting-title">Button Colors</h3>
        </div>
        <pos-row colSm="6" colXs="12" class="pos-title-content">
          <pos-column>
            <pos-form-field label="Main Function Buttons">
              <pos-color-picker posInput [(ngModel)]="parameters.FunctionButtonBackgroundColor"
                name="functionButtonBackgroundColor"></pos-color-picker>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Order Function Buttons">
              <pos-color-picker posInput [(ngModel)]="parameters.OrderFunctionButtonBackgroundColor"
                name="orderFunctionButtonBackgroundColor"></pos-color-picker>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Default Button Color">
              <pos-color-picker posInput [(ngModel)]="parameters.DefaultButtonActiveStateBackgroundColor"
                name="defaultButtonActiveStateBackgroundColor"></pos-color-picker>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Toggle Button Color">
              <pos-color-picker posInput [(ngModel)]="parameters.ToggleButtonActiveStateBackgroundColor"
                name="toggleButtonActiveStateBackgroundColor"></pos-color-picker>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Default Button Checkmark Color">
              <pos-color-picker posInput [(ngModel)]="parameters.ToggleButtonDefaultActiveStateCheckmarkColor"
                name="toggleButtonDefaultActiveStateCheckmarkColor"></pos-color-picker>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Toggle Button Checkmark Color">
              <pos-color-picker posInput [(ngModel)]="parameters.ToggleButtonActiveStateCheckmarkColor"
                name="toggleButtonActiveStateCheckmarkColor"></pos-color-picker>
            </pos-form-field>
          </pos-column>
        </pos-row>
      </div>

      <div class="col-xs-12 section-border">
        <h3 class="setting-title">Make Table Colors</h3>
        <pos-row colSm="6" colXs="12" class="pos-title-content">
          <pos-column>
            <pos-form-field label="Make Table Custom Color" [fieldInfo]="fieldInfoMessages.ColorsMakeTableCustomColor">
              <pos-color-picker posInput [(ngModel)]="parameters.MakeTableColor"
                name="makeTableColor"></pos-color-picker>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Make Table In-Process Color"
              [fieldInfo]="fieldInfoMessages.ColorsMakeTableInProgress">
              <pos-color-picker posInput [(ngModel)]="parameters.MakeTableProcessColor"
                name="makeTableProcessColor"></pos-color-picker>
            </pos-form-field>
          </pos-column>
          <pos-column>
            <pos-form-field label="Make Table Completed Color" [fieldInfo]="fieldInfoMessages.ColorsMakeTableCompleted">
              <pos-color-picker posInput [(ngModel)]="parameters.MakeTableCompleteColor"
                name="makeTableCompleteColor"></pos-color-picker>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Make Table Account Color" [fieldInfo]="fieldInfoMessages.ColorsMakeTableAccount">
              <pos-color-picker posInput [(ngModel)]="parameters.MakeTableAccountColor"
                name="makeTableAccountColor"></pos-color-picker>
            </pos-form-field>
          </pos-column>
          <pos-column>
            <pos-form-field label="Make Table Ready Color" [fieldInfo]="fieldInfoMessages.ColorsMakeTableReady">
              <pos-color-picker posInput [(ngModel)]="parameters.MakeTableReadyColor"
                name="makeTableReadyColor"></pos-color-picker>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Make Table Call-In Color" [fieldInfo]="fieldInfoMessages.ColorsMakeTableCallIn">
              <pos-color-picker posInput [(ngModel)]="parameters.MakeTableCallInColor"
                name="MakeTableCallInColor"></pos-color-picker>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Make Table Pager Color" [fieldInfo]="fieldInfoMessages.ColorsMakeTablePager">
              <pos-color-picker posInput [(ngModel)]="parameters.MakeTablePagerColor"
                name="MakeTablePagerColor"></pos-color-picker>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Make Table Partially In Progress Color" [fieldInfo]="fieldInfoMessages.ColorsMakeTablePager">
              <pos-color-picker posInput [(ngModel)]="parameters.MakeTablePartiallyInProgressColor"
                name="MakeTablePartiallyInProgressColor"></pos-color-picker>
            </pos-form-field>
          </pos-column>

          <pos-column xs="12">
            <pos-row class="p-0">
              <pos-column sm="4" xs="12" class="col-255">
                <pos-form-field label="Make Table Notify Button Color">
                  <pos-color-picker posInput [(ngModel)]="parameters.MakeTableNotifyColor"
                    name="makeTableNotifyColor"></pos-color-picker>
                </pos-form-field>
              </pos-column>

              <pos-column md="5" sm="6" xs="12">
                <pos-form-field label="Font Color">
                  <pos-color-picker posInput [(ngModel)]="parameters.MakeTableNotifyFontColor"
                    name="makeTableNotifyFontColor"></pos-color-picker>
                </pos-form-field>
              </pos-column>
            </pos-row>
          </pos-column>

          <pos-column xs="12">
            <pos-row>
              <pos-column sm="4" xs="12" class="col-255">
                <pos-form-field label="Make Table Serve Button Color">
                  <pos-color-picker posInput [(ngModel)]="parameters.MakeTableServeColor"
                    name="makeTableServeColor"></pos-color-picker>
                </pos-form-field>
              </pos-column>

              <pos-column md="5" sm="6" xs="12">
                <pos-form-field label="Font Color">
                  <pos-color-picker posInput [(ngModel)]="parameters.MakeTableServeFontColor"
                    name="makeTableServeFontColor"></pos-color-picker>
                </pos-form-field>
              </pos-column>
            </pos-row>
          </pos-column>

          <pos-column xs="12">
            <pos-form-field label="Make Table Quantity Color" [fieldInfo]="fieldInfoMessages.ColorsMakeTableQuantity"
              inputStyle="col-xs-12">
              <pos-row class="p-0">
                <pos-column sm="3" xs="6" class="col-200 mb-1">
                  <pos-color-picker posInput [(ngModel)]="makeTableQuantityColor"
                    name="makeTableQuantityColor"></pos-color-picker>
                </pos-column>

                <pos-column xs="1" class="pt-pix-8 pe-0 col-70 mb-1">
                  <a (click)="updateMakeTableQuantityColor(makeTableQuantityColor)"
                    *ngIf="makeTableColorsArray.length < 10" title="Add">
                    <pos-action-icon [icon]="icons.plusWhite" iconText="Add"></pos-action-icon>
                  </a>
                </pos-column>

                <pos-column xs="1" class="col-52"></pos-column>

                <pos-column md="2" sm="6" xs="12">
                  <div class="row g-0">
                    <div class="col-md-2 col-xs-2 col-sm-2 col-2 color-qty-list" [ngStyle]="{'background-color':colorQty}"
                      *ngFor="let colorQty of makeTableColorsArray; let i = index">
                      <a (click)="removeColorQuantity(colorQty)" title="Delete">
                        <pos-icon [icon]="icons.timesCircle" class="pointer color-qty-list__img me-pix-5">
                        </pos-icon>
                      </a>
                      <span class="color-qty-list__qty">{{i+1}}</span>
                    </div>
                  </div>
                </pos-column>
              </pos-row>
            </pos-form-field>
          </pos-column>

          <pos-column xs="12">
            <pos-form-field label="Make Table Color Thresholds" [fieldInfo]="fieldInfoMessages.ColorsMakeTableQuantity"
              inputStyle="col-xs-12">
              <pos-row>
                <pos-column sm="3" xs="6" class="col-200 mb-1">
                  <pos-color-picker posInput [(ngModel)]="makeTableThresholdColor.Color"
                    name="makeTableThresholdColors"></pos-color-picker>
                </pos-column>

                <pos-column xs="3" class="col-140 mb-1">
                  <input class="form-control" placeholder="Seconds" [disabled]="!isAllowEditSeconds"
                    name="MakeTableThresholdColorsSecond" [(ngModel)]="makeTableThresholdColor.Second" type="number" />
                </pos-column>

                <pos-column xs="1" class="pt-pix-8 pe-0 col-70 mb-1">
                  <a (click)="updateMakeTableThresholdColor(makeTableThresholdColor)" title="Add">
                    <pos-action-icon [icon]="icons.plusWhite" iconText="Add"></pos-action-icon>
                  </a>
                </pos-column>


                <pos-column lg="2" md="3" sm="8" xs="12">
                  <div class="row g-0">
                    <div class="col-md-2 col-xs-2 col-sm-2 col-2 p-0 color-qty-list"
                      (click)="editMakeTableThresholdColor(makeTableThresholdColor)"
                      *ngFor="let makeTableThresholdColor of makeTableThresholdColors; let i=index;"
                      [ngStyle]="{'background-color':makeTableThresholdColor.Color}">
                      <a (click)="removeMakeTableThresholdColor(makeTableThresholdColor)" title="Delete">
                        <pos-icon [icon]="icons.timesCircle" *ngIf="i > 0" class="color-qty-list__img me-pix-5 pointer">
                        </pos-icon>
                      </a>
                      <span class="color-qty-list__qty">
                        {{makeTableThresholdColor.Second}}
                      </span>
                    </div>
                  </div>
                </pos-column>
              </pos-row>
            </pos-form-field>
          </pos-column>
        </pos-row>
      </div>

      <div class="col-xs-12 section-border">
        <div>
          <h3 class="setting-title">Highlight Colors</h3>
        </div>
        <pos-row class="pos-title-content">
          <pos-column>
            <pos-form-field label="Account Selection Background Color">
              <pos-color-picker posInput [(ngModel)]="parameters.AccountSelectionBackgroundColor"
                name="accountSelectionBackgroundColor"></pos-color-picker>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Driver Selection Background Color">
              <pos-color-picker posInput [(ngModel)]="parameters.DriverSelectionBackgroundColor"
                name="driverSelectionBackgroundColor"></pos-color-picker>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Search Button Navigation Border Color">
              <pos-color-picker posInput [(ngModel)]="parameters.SearchButtonNavigationBorderColor"
                name="searchButtonNavigationBorderColor"></pos-color-picker>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Menu Item Highlight Color">
              <pos-color-picker posInput [(ngModel)]="parameters.MenuItemHighlightColor"
                name="menuItemHighlightColor"></pos-color-picker>
            </pos-form-field>
          </pos-column>
        </pos-row>
      </div>

      <div class="col-xs-12 section-border">
        <div>
          <h3 class="setting-title">Invoice Styling</h3>
        </div>
        <pos-row class="pos-title-content">
          <pos-column>
            <pos-form-field label="Subaccount Font Size" [fieldInfo]="fieldInfoMessages.ColorsSubaccountFontSize"
              inputStyle="col-xs-6 col-170">
              <input posInput class="form-control" maxlength="3" [(ngModel)]="parameters.SubaccountFontSize"
                type="number" name="subaccountFontSize">
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Subaccount Main Color" [fieldInfo]="fieldInfoMessages.ColorsSubaccountMain">
              <pos-color-picker posInput [(ngModel)]="parameters.SubaccountMainColor"
                name="subaccountMainColor"></pos-color-picker>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Product Font Size" [fieldInfo]="fieldInfoMessages.ColorsProductFontSize"
              inputStyle="col-xs-6 col-170">
              <input posInput class="form-control" maxlength="2" [(ngModel)]="parameters.ProductFontSize" type="number"
                name="productFontSize">
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Subaccount Highlight Color"
              [fieldInfo]="fieldInfoMessages.ColorsSubaccountHighlight">
              <pos-color-picker posInput [(ngModel)]="parameters.SubaccountHighlightColor"
                name="subaccountHighlightColor"></pos-color-picker>
            </pos-form-field>
          </pos-column>
        </pos-row>
      </div>

      <div class="col-xs-12 section-border">
        <div>
          <h3 class="setting-title">Tender Type Colors</h3>
        </div>
        <pos-row colSm="6" colXs="12" class="pos-title-content mb-2">
          <pos-column *ngFor="let tenderType of tenderTypesColors; let i = index;"  sm="6" xs="12">
            <pos-form-field [label]="tenderType.Name">
              <pos-color-picker posInput [(ngModel)]="tenderType.Color" name="tenderColor{{i}}"
                [cpPosition]="'top'"></pos-color-picker>
            </pos-form-field>
          </pos-column>
        </pos-row>
      </div>
    </div>
    <div class="col-12 action-button-container page-footer">
      <button class="btn btn-primary btn-lg btn-save pos-button" scrollup type="submit">
        Save
      </button>
      <button class="btn btn-secondary btn-lg btn-cancel pos-button" routerLink="../" scrollup type="button"
        value="Cancel">Cancel</button>
    </div>
  </form>
</pos-edit-wrapper>
