import { Component, Input, OnInit } from '@angular/core';
import { forEach } from 'lodash';
import { ColorUtilityService } from 'src/app/shared';
import { ComboListWidgetStyleConfig } from '../../interfaces/combo-list-widget-style-config';
import { MenuDisplayComboItem } from '../../interfaces/menu-display-combo-item';
import { MenuWidgetStyleService } from '../../services';

@Component({
  selector: 'pos-combine-items',
  templateUrl: './combine-items.component.html',
  styleUrls: ['./combine-items.component.scss']
})
export class CombineItemsComponent implements OnInit {

  @Input() combinedProduct: Array<MenuDisplayComboItem> = [];
  @Input() description: string = null;
  @Input() widgetStyleConfig: ComboListWidgetStyleConfig = null;
  @Input() interactiveStyleClass: string;

  constructor(private colorUtilityService: ColorUtilityService,
    private menuWidgetStyleService: MenuWidgetStyleService) { }

  ngOnInit(): void {
    this.setContrastColor();
    forEach(this.combinedProduct, (product) => {
      product.ProductStyle = {
        ...this.menuWidgetStyleService.getBorderStyle(JSON.parse(product.BorderStyle)),
        'background-color': product?.BackgroundColor,
        'color': this.widgetStyleConfig?.ItemFontStyle?.color ? this.widgetStyleConfig?.ItemFontStyle?.color : product.FontColor
      };
    })
  }

  setContrastColor() {
    forEach(this.combinedProduct, product => {
      product.FontColor = product.FontColor ?? this.colorUtilityService.getContrastColor(product?.BackgroundColor);
    });
  }

}
