import { Component, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Vendor } from '../../interface/vendor';
import { AbstractControl, NgForm, ValidatorFn } from '@angular/forms';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { VendorService } from '../../service/vendor.service';
import { finalize } from 'rxjs/operators';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { ActivatedRoute, Router } from '@angular/router';
import { DomainConstants } from 'src/app/shared/constants/domain-constants';
import { deleteWhite, vendorStruck } from './../../../../shared/components/icon/icons';
import { ConfirmDeleteModalComponent } from 'src/app/shared/components/confirm-delete-modal/confirm-delete-modal.component';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { ApplicationStateService, BaseFormComponent, FormUtilityService, SettingParam } from 'src/app/shared';
import { forEach } from 'lodash';
declare var $;
@Component({
    selector: 'pos-vendor-edit',
    templateUrl: './vendor-edit.component.html',
    styleUrls: ['./vendor-edit.component.scss']
})
export class VendorEditComponent extends BaseFormComponent implements OnInit {

    @ViewChild('vendorForm', { static: true }) vendorForm: NgForm;
    props = {
        labelClass: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
        controlBoxClass: 'col-lg-3 col-md-4 col-sm-6 col-xs-12'
    }
    vendorId: number;
    vendor: Vendor;
    vendorName = '';
    vendorOrderDays = [];
    icons = {
        deleteWhite,
        vendorStruck
    };
    screenHeight: number;
    updatedIndex = 0;
    settingParam: SettingParam;
    defaultMask = DomainConstants.DefaultPhoneMask;
    vendorsActive: boolean = true;

    get getForm(): NgForm {
        return this.vendorForm;
    }
    constructor(
        protected alertService: AlertsService,
        protected spinnerService: SpinnerService,
        protected vendorService: VendorService,
        private router: Router,
        private route: ActivatedRoute,
        private modalService: ModalService,
        private applicationStateService: ApplicationStateService,
        protected formUtilityService: FormUtilityService
    ) {
        super(formUtilityService);
        this.vendorId = parseInt(this.route.snapshot.params.id, 10) ?? 0;
        const navigation = router.getCurrentNavigation();
        this.vendorsActive = navigation?.extras?.state?.Active ?? true;
    }
    ngOnChanges(changes: SimpleChanges): void {
        throw new Error('Method not implemented.');
    }

    ngOnInit() {
        this.vendorForm.reset();
        this.vendor = this.vendorService.newVendor();
        this.getVendorDetails();
        this.loadData();
        this.screenHeight = $(window).height() - 648;
        this.resizeWindow();
        this.settingParam = this.applicationStateService.settingParam;
        this.setInitialValue();
    }

    resizeWindow = () => {
        $(window).resize(() => {
            this.screenHeight = ($(window).height() - 648) < 156 ? 156 : ($(window).height() - 648);
        });
    }

    private getVendorDetails() {
        if (this.vendorId > 0) {
            this.spinnerService.show();
            this.vendorService.getVendorDetails(this.vendorId)
                .pipe(finalize(() => {
                    this.spinnerService.hide();
                }))
                .subscribe({
                    next: (vendor: Vendor) => {
                        this.vendor = vendor;
                        if (this.vendor.OrderSchedule.length >= 1) {
                            this.vendor.OrderSchedule.map((data) => {
                                data.OrderPlaceDayId = data.OrderPlaceDayId === 8 ? null : data.OrderPlaceDayId;
                                data.OrderReceiveDayId = data.OrderReceiveDayId === 8 ? null : data.OrderReceiveDayId;
                            });
                        }
                        if ((this.vendor.OrderSchedule.length >= 1 || this.vendor.OrderSchedule.length === 0) &&
                            (this.vendor.OrderSchedule[0]?.OrderPlaceDayId !== null ||
                                this.vendor.OrderSchedule[0]?.OrderReceiveDayId !== null)) {
                            this.vendor.OrderSchedule.push(this.vendorService.newOrderSchedule(this.vendorId));
                        }
                        this.vendor.Name = vendor.name;
                        this.vendorName = vendor.Name;
                    }, error: this.alertService.showApiError
                });
        }
    }

    loadData() {
        this.spinnerService.show();
        this.vendorService.getInventoryOrderDays().pipe(finalize(() => {
            this.spinnerService.hide();
        })).subscribe({
            next: (response) => {
                this.vendorOrderDays = response.filter(x => x.Id !== 8);
            }
        });
    }

    saveVendor(isValid: boolean) {
        if (!isValid || (this.vendor.TypicalLeadTime < 0 || this.vendor.TypicalLeadTime > 365)) {
            return;
        }
        this.spinnerService.show();
        if (this.vendor.OrderSchedule.length > 1) {
            this.vendor.OrderSchedule.splice(this.vendor.OrderSchedule.length - 1, 1);
        }
        this.vendorService.saveVendorDetails(this.vendor)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (vendor: Vendor) => {
                    this.alertService.renderSuccessMessage(Messages.VendorSaveSuccess);
                    this.cancel();
                }, error: this.alertService.showApiError
            });
    }

    createScheduleRow(lastIndex, scheduleIndex) {
        this.scheduleValidation(scheduleIndex);
        if (!lastIndex && this.vendor.OrderSchedule.length > 1) {
            return;
        }
        this.vendor.OrderSchedule.push(this.vendorService.newOrderSchedule(this.vendorId));
    }

    scheduleValidation(scheduleIndex) {
        forEach(this.vendor.OrderSchedule, (item) => { item.Invalid = false });
        const duplicateSchedule = this.vendor.OrderSchedule.filter((data, index) => {
            if (scheduleIndex !== index && index !== this.vendor.OrderSchedule.length - 1) {
                return (data.OrderPlaceDayId === this.vendor.OrderSchedule[scheduleIndex].OrderPlaceDayId)
                    && (data.OrderReceiveDayId === this.vendor.OrderSchedule[scheduleIndex].OrderReceiveDayId);
            }
        });
        if (duplicateSchedule.length) {
            this.vendor.OrderSchedule[scheduleIndex].Invalid = true;
            return;
        } else {
            this.vendor.OrderSchedule[scheduleIndex].Invalid = false;
        }
    }

    deleteScheduleRow(index) {
        if (!this.vendor.OrderSchedule[index].Id) {
            this.vendor.OrderSchedule.splice(index, 1);
            this.updatedIndex++;
        } else if (this.vendor.OrderSchedule.filter(x => x.Id).length >= 1) {
            const modal = this.modalService.getModalWrapper(ConfirmDeleteModalComponent);
            const modalRef = modal.show({
                animated: false,
                class: 'vertical-center',
                keyboard: false,
                initialState: {
                    message: Messages.VendorOrderScheduleConfirmDelete
                }
            });
            modalRef.close.subscribe(res => {
                if (res && res.shouldDelete) {
                    this.deleteVendorSchedule(this.vendor.OrderSchedule[index].Id);
                }
            });
        }
    }

    deleteVendorSchedule(id: number) {
        this.spinnerService.show();
        this.vendorService.deleteInventoryVendorSchedule(id).pipe(finalize(() => {
            this.spinnerService.hide();
        })).subscribe({
            next: (response) => {
                this.getVendorDetails();
            }, error: this.alertService.showApiError
        });
    }

    cancel() {
        this.router.navigate(['manage/app-configuration/vendors'], { state: { Active: this.vendorsActive } });
    }

    validateSchedule = (index: number, isOrderOn: boolean = false): ValidatorFn => {
        return (control: AbstractControl): { [key: string]: any } => {
            forEach(Object.keys(control.parent.controls), (name) => {
                if (name.includes('orderOn') || name.includes('receiveOn')) {
                    control.parent.controls[name].setErrors(null);
                }
            });
            let currentSchedule = this.vendor.OrderSchedule[index];
            if (currentSchedule) {
                if (isOrderOn) {
                    currentSchedule.OrderPlaceDayId = control.value;
                    currentSchedule.OrderReceiveDayId = this.vendor.OrderSchedule[index].OrderReceiveDayId;
                } else {
                    currentSchedule.OrderReceiveDayId = control.value;
                    currentSchedule.OrderPlaceDayId = this.vendor.OrderSchedule[index].OrderPlaceDayId;
                }
                this.scheduleValidation(index);
                currentSchedule = this.vendor.OrderSchedule[index];
                if (currentSchedule.Invalid) {
                    return { invalidSchedule: true }
                } else {
                    return null;
                }
            }
            return null;
        }
    }

}
