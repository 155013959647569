import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forEach } from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/internal/operators/finalize';
import { BaseCrudComponent } from 'src/app/shared/components/base-crud.component';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { DomainConstants } from 'src/app/shared/constants/domain-constants';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { FormUtilityService } from 'src/app/shared/services/form-utility.service';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import { ScreenService } from 'src/app/shared/services/screen.service';
import { ScreenDetails } from '../../interface/screen-details';
import { ScreenHistory } from '../../interface/screen-history';
import { ScreenHistoryService } from '../../service/screen-history.service';

@Component({
  selector: 'pos-screen-history-edit',
  templateUrl: './screen-history-edit.component.html',
  styleUrls: ['./screen-history-edit.component.scss']
})
export class ScreenHistoryEditComponent extends BaseCrudComponent<ScreenHistory> implements OnInit {
  dateFormat = 'mm-dd-yy';
  hourFormat = '12';
  id: number;
  screenId: number;
  screenHistory: ScreenHistory;
  screenName = '';
  screenList: Array<any> = [];
  screenLayouts = DomainConstants.ScreenButtonLayouts;
  buttonSizes = [];
  today = new Date();
  @ViewChild('screenHistoryForm') screenHistoryForm: NgForm;
  get getForm(): NgForm {
    return this.screenHistoryForm
  }

  constructor(protected screenHistoryService: ScreenHistoryService,
    protected screenService: ScreenService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected applicationStateService: ApplicationStateService,
    protected route: ActivatedRoute,
    private router: Router,
    protected printService: PrintTableService,
    formUtilityService: FormUtilityService) {
    super(screenHistoryService, alertService, spinnerService, formUtilityService);
    this.screenId = parseInt(route.snapshot.params.screenId, 10) ?? 0;
    this.id = parseInt(route.snapshot.params.id, 10) ?? 0;
    this.dateFormat = this.applicationStateService.settingParam.PCalendarDateFormat;
    this.saveSuccessMessage = Messages.ScreenHistorySaveSuccess;
    this.screenHistory = this.screenHistoryService.getNewScreenHistory();
    forEach(DomainConstants.ButtonSize, (key, value) => {
      this.buttonSizes.push({ Text: key, Value: value });
    });
  }

  ngOnInit(): void {
    this.laodScreenHistory();
  }

  laodScreenHistory() {
    if (this.id) {
      this.spinnerService.show();
      this.loadData().pipe(finalize(() => {
        this.spinnerService.hide();
      })).subscribe({
        next: (response) => {
          this.screenHistory = response;
          this.screenHistory.DateAdded = new Date(response.DateAdded);
          if (!this.screenHistory.ButtonSize) {
            this.screenHistory.ButtonSize = DomainConstants.ButtonSize.Normal;
          }
        }, error: this.alertService.showApiError
      });
    }
  }

  onCancel() {
    this.router.navigate([`manage/app-configuration/screens/${this.screenId}/screen-history`]);
  }

  loadDependencies() {
    this.spinnerService.show();
    const observable: Array<Observable<any>> = [];
    observable.push(this.screenService.getScreensForTemporaryNavigation(this.screenId));
    observable.push(this.screenService.get(this.screenId));
    forkJoin(observable).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (response: [Array<ScreenDetails>, ScreenDetails]) => {
        this.screenList = response[0];
        this.screenName = response[1].Title;
      },
      error: this.alertService.showApiError
    });
  }

  saveScreenHistory(isValid: boolean) {
    if (!isValid) {
      return;
    }
    if (!this.id) {
      this.screenHistory.ScreenId = this.screenId;
    }
    if (this.screenHistory.ButtonSize === DomainConstants.ButtonSize.Normal) {
      this.screenHistory.ButtonSize = null;
    }
    this.spinnerService.show();
    this.screenHistoryService.insert(this.screenHistory).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (response) => {
        this.onSaveSuccess();
      }, error: this.alertService.showApiError
    });
  }

  setNoOfColumns() {
    if (this.screenHistory.Layout == this.screenLayouts.Fixed) {
      this.screenHistory.ColumnCount = this.screenService.newScreen().ColumnCount;
    } else {
      this.screenHistory.ColumnCount = null;
      this.screenHistory.ButtonSize = this.screenService.newScreen().ButtonSize;
    }
  }

  onSaveSuccess() {
    this.onCancel();
    this.alertService.renderSuccessMessage(this.saveSuccessMessage);
  }

}
