<div class="col-12 pick-screen-page__section__container__content__detail row mx-0 breathing-highlight"
    *ngIf="pickScreenProduct" [ngStyle]="{ 'animation-iteration-count': highlightDuration }">
    <div class="col-12 pick-screen-page__section__container__content__detail--main">
        <span class="bg-green float-start me-pix-5">{{pickScreenProduct.ComponentName}} </span>
        <span *ngIf="settingParam.ShowNonDefaultQuantityOnPickScreen && pickScreenProduct.Qty != pickScreenProduct.DefaultQty"
                                    style="vertical-align: middle;"
                                    class="badge qty-circle make-table-page__content__block__qty"
                                    [ngStyle]="{'background-color': pickScreenProduct.QtyBgColor,'color': pickScreenProduct.QtyFontColor, 'border-radius': settingParam.AllowOrderingFractionalQty ? '5px':''? '5px':''}">
                                    {{pickScreenProduct.Qty | productQty}}
                                </span>
        <span class="bg-green float-end">{{pickScreenProduct.SurrogateOrderId}}</span>
    </div>
    <div class="col-12 row mx-0 pick-screen-page__section__container__content__detail--each"
        *ngFor="let product of pickScreenProduct.Components"
        [ngStyle]="{'background-color':product.CustomColor,'color': product.CustomFontColor}">
        <div class="col-7 py-pix-2">
            {{product.Instructions}}
        </div>
        <div class="col-5 p-0 row mx-0">
            <div class="col-3 no-pad vertical-center-align">
                <span class="badge pick-screen-page__section__container__content__detail--each__label"
                    *ngIf="product.PrimaryLabel"
                    [ngStyle]="{'background-color':product.PrimaryColor,'color':product.PrimaryFontColor}">
                    {{product.PrimaryLabel}}
                </span>
            </div>
            <div class="col-2 red no-pad vertical-center-align" [ngStyle]="{ 'color':product.PrimaryBinColor  }">
                {{product.PrimaryBin}}
            </div>
            <div class="col-3 no-pad vertical-center-align">
                <span class="badge normal" *ngIf="product.SecondaryLabel"
                    [ngStyle]="{'background-color':product.SecondaryColor, 'color': product.SecondaryFontColor}">
                    {{product.SecondaryLabel}}
                </span>
            </div>
            <div class="col-2 no-pad vertical-center-align" [ngStyle]="{ 'color':product.SecondaryBinColor }">
                {{product.SecondaryBin}}
            </div>
            <div class="col-md-1 col-2 no-pad vertical-center-align" *ngIf="product.IsBinLightMapped">
                <pos-icon [icon]="icons.lightBulbPickScreen"></pos-icon>
            </div>
        </div>
    </div>
</div>
