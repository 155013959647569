import { Component, OnInit, ViewChild } from '@angular/core';
import { cashPayoutMoneyBillWave, cashPayoutsSearchDollar } from 'src/app/shared/components/icon';
import { CashPayOut } from '../../interface';
import { User } from 'src/app/information-management/users/interface/user';
import { NgForm } from '@angular/forms';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { CashPayoutService } from '../../service';
import { BaseCrudComponent } from 'src/app/shared/components/base-crud.component';
import { UserService } from 'src/app/shared/services/user.service';
import { Messages } from 'src/app/shared/constants';
import { finalize } from 'rxjs/operators';
import { CashDrawerName } from '../../interface/cash-drawer-name';
import { forkJoin } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FormUtilityService } from 'src/app/shared/services/form-utility.service';
import { ApplicationStateService } from 'src/app/shared';

@Component({
    selector: 'pos-edit-cash-payout',
    templateUrl: './edit-cash-payout.component.html',
})
export class EditCashPayoutComponent extends BaseCrudComponent<CashPayOut> implements OnInit {

    cashPayout: CashPayOut;
    users: User[] = [];
    cashDrawerName: CashDrawerName[] = [];
    icons = {
        cashPayoutMoneyBillWave,
        cashPayoutsSearchDollar
    };
    props = {
        controlBoxClass: 'col-lg-3 col-md-5 col-sm-8 col-xs-12'
    }
    @ViewChild('cashPayoutForm') cashPayoutForm: NgForm;
    get getForm(): NgForm {
        return this.cashPayoutForm
    }
    currencySymbol = null;
    constructor(
        private applicationStateService: ApplicationStateService,
        protected alertService: AlertsService,
        protected spinnerService: SpinnerService,
        protected cashPayoutService: CashPayoutService,
        private router: Router,
        private userService: UserService,
        private route: ActivatedRoute,
        formUtilityService: FormUtilityService
    ) {
        super(cashPayoutService, alertService, spinnerService, formUtilityService);
        this.id = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
    }

    ngOnInit() {
        this.cashPayout = this.cashPayoutService.newCashPayout();
        this.saveSuccessMessage = Messages.CashPayoutSaveSuccess;
        this.dataSubscription();
        this.scrollToTop();
        this.currencySymbol = this.applicationStateService.settingParam.CurrencySymbol;
    }
    private dataSubscription(): void {
        if (this.id > 0) {
            this.spinnerService.show();
            this.loadData().pipe(finalize(() => {
                this.spinnerService.hide();
            }))
                .subscribe({
                    next: (res) => {
                        this.cashPayout = res;
                    }, error: this.alertService.showApiError
                });
        }
    }
    public loadDependencies(): void {
        const cashPayoutObservables = [];
        this.spinnerService.show();
        cashPayoutObservables.push(this.userService.getAllActiveUser());
        cashPayoutObservables.push(this.cashPayoutService.getCashDrawers());
        forkJoin(cashPayoutObservables)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (responses: any) => {
                    this.users = responses && responses[0].length > 0 ? responses[0] : [];
                    this.cashDrawerName = responses && responses[1] && responses[1].length > 0 ? responses[1] : [];
                }, error: this.alertService.showApiError
            });
    }
    public submit(isValid: boolean): void {
        if (!isValid || !this.cashPayout.UserId || !this.cashPayout.CashDrawerId || this.cashPayout.Amount < 0) {
            return;
        }
        this.save(this.cashPayout);
    }
    public onSaveSuccess(model: CashPayOut): void {
        this.cancelEditing();
    }
    public cancelEditing(): void {
        this.router.navigate(['cash-payouts'], { relativeTo: this.route.parent });
    }
    private scrollToTop(): void {
        window.scrollTo(0, 0);
    }
}
