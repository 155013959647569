import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MainService } from 'src/app/header-entry/services';
import { Messages, RuntimeConstants } from '../../constants';
import { ApplicationStateService } from '../../services';
import { StringUtils } from '../../string-utils';
import { ModalBackdropService } from '../modal';
@Component({
  selector: 'pos-app-version-check',
  templateUrl: './app-version-check.component.html',
  styleUrls: ['./app-version-check.component.scss']
})
export class AppVersionCheckComponent implements OnInit, OnDestroy {

  versionUpdateTimeout: any;
  refreshInterval: number = 30;
  autoRefreshPromptMessage = '';
  @ViewChild('versionInfoModal', { static: true }) public versionInfoModal: any;
  versionInfoModalRef: BsModalRef;
  autoCheckVersionInterval: any;
  restartVersionCheckInterval: any;
  constructor(private modalService: BsModalService,
    private modalBackDropService: ModalBackdropService,
    private applicationStateService: ApplicationStateService,
    private mainService: MainService) { }

  ngOnDestroy(): void {
    if (this.autoCheckVersionInterval) {
      clearInterval(this.autoCheckVersionInterval);
    }
    if (this.versionUpdateTimeout) {
      clearTimeout(this.versionUpdateTimeout);
    }
    if (this.restartVersionCheckInterval) {
      clearTimeout(this.restartVersionCheckInterval);
    }
  }

  ngOnInit(): void {
    this.autoRefreshPromptMessage = StringUtils.format(Messages.VersionMisMatchInfo, { 'interval': this.refreshInterval });
    if (RuntimeConstants.enableVersionCheck) {
      this.autoCheckAppVersion();
    }
  }

  autoCheckAppVersion() {
    this.autoCheckVersionInterval = setInterval(() => {
      this.getAppVersion();
    }, 1000 * 60 * 2);
  }

  clearAutoRefreshTimeout = () => {
    if (this.versionUpdateTimeout) {
      clearTimeout(this.versionUpdateTimeout);
    }
  }

  getAppVersion() {
    this.mainService.getApplicationVersion()
      .subscribe({
        next: (res) => {
          if (this.applicationStateService.applicationInitializationConfigurations) {
            if (!this.applicationStateService.applicationInitializationConfigurations?.CurrentVersion) {
              this.applicationStateService.applicationInitializationConfigurations.CurrentVersion = res.CurrentVersion;
            } else if (this.applicationStateService.applicationInitializationConfigurations.CurrentVersion !== res.CurrentVersion) {
              clearInterval(this.autoCheckVersionInterval);
              this.promptUserForDifferentVersion();
            }
          }
        }
      });
  }

  promptUserForDifferentVersion = () => {
    this.versionInfoModalRef = this.modalService.show(this.versionInfoModal, {
      'backdrop': 'static',
      'class': 'vertical-center',
      'keyboard': false,
      animated: false
    });
    this.modalBackDropService.addBackDrop();
    this.startAutoRefreshTimer();
  }

  startAutoRefreshTimer = () => {
    this.clearAutoRefreshTimeout();
    this.versionUpdateTimeout = setTimeout(() => {
      this.reloadNow();
    }, 1000 * this.refreshInterval);
    // Restart version check interval if reload now work somehow
    this.restartVersionCheck();
  }

  reloadNow = () => {
    window.location.reload();
  }

  dismissModal = () => {
    if (this.versionInfoModalRef) {
      this.versionInfoModalRef.hide();
      this.modalBackDropService.removeBackdrop();
    }
    this.startAutoRefreshTimer();
  }

  private restartVersionCheck() {
    if (!this.restartVersionCheckInterval) {
      this.restartVersionCheckInterval = setTimeout(() => {
        this.autoCheckAppVersion();
      }, 1000 * 60 * 5);
    }
  }
}
