<fieldset class="ingredient-recipe-height ps-1">
    <pos-row>
        <pos-column xl="3" lg="6" sm="6" xs="12">
            <pos-form-field label="I'm making" inputStyle="col-12">
                <ng-select posInput [items]="prepProducts" bindLabel="Name" bindValue="Id" placeholder="Select product"
                    [(ngModel)]="prepProduct" (change)="getIngredients()" name="product">
                </ng-select>
            </pos-form-field>
        </pos-column>
        <pos-column lg="3" sm="6" xs="12" class="pt-pix-10 pt-pix-sm-30">
            <button class="btn btn-lg btn-primary white pos-button normal" type="button"
                (click)="printInventory()">Print
                Recipe</button>
        </pos-column>
    </pos-row>
    <div class="row mx-0 bg-white">
        <div class="col-12 p-0 mt-pix-10" *ngIf="ingredients?.length">
            <fieldset class="fieldset mb-pix-10 pl-075em">
                <legend class="reset-style bold"><span class="bold">Ingredients</span></legend>
                <div class="mb-pix-5 pe-pix-15 overflow-auto" [ngStyle]="{'max-height': getRecipeHeight()}">
                    <h4>Products used to make it:</h4>
                </div>
                <div class="col-xl-5 col-lg-6 col-12 p-0 overflow-auto">
                    <ng-table [data]="ingredients" [columns]="ingredientColumns" [paginator]="false" [search]="false"
                        [scrollable]="true" [scrollHeight]="getIngredientHeight()"></ng-table>
                </div>
                <div class="col-12 my-pix-10 p-0">
                    <h4>Expected<span class="font-weight-600"> {{prepareQty}} {{
                            selectedUnit
                            }}</span> to be made, based on recipe.
                    </h4>
                </div>
            </fieldset>
        </div>
        <div class="col-12 mt-pix-5 ps-0" *ngIf="ingredients?.length">
            <div class="row mx-0">
                <div class="col-1 col-50">
                    <label class="edit-form__section__label"> Total </label>
                </div>
                <div class="col-1 col-120">
                    <input [(ngModel)]="preparedQty" class="form-control text-right" type="number" />
                </div>
                <div class="col-2 col-120 pe-0"><label class="edit-form__section__label"> {{
                        selectedUnit
                        }} made. </label></div>
            </div>
        </div>
        <ng-template let-data="rowData" #ingredientTemplate>
            <div><b> {{data.ProductName}}</b></div>
            <div>{{data.Instructions}}</div>
        </ng-template>
        <ng-template let-data="rowData" #qtyTemplate>
            <span [ngClass]="{'has-error': (qty.invalid && isFormSubmitted)}">
                <input type="number" class="form-control text-right" min="0" (input)="calculatePrepQty()"
                    [(ngModel)]="data.UsedPrepQty" #qty="ngModel" required />
                <span *ngIf="qty.invalid && isFormSubmitted">
                    <label class="control-label" *ngIf="qty.errors?.required">Please
                        enter quantity.</label>
                    <label class="control-label" *ngIf="data.UsedPrepQty < 0">Quantity should be
                        greater than 0.</label>
                </span>
            </span>
        </ng-template>
        <ng-template let-data="rowData" #newQtyTemplate>
            <span tooltipPosition="top"
                pTooltip="Only {{data.CalculatedQty}} {{data.UnitName}} are available in inventory">
                <pos-icon [icon]="icons.exclamationTriangle" class="red"
                    *ngIf="(data.CalculatedQty - (data.UsedPrepQty ? data.UsedPrepQty :0)) < 0">
                </pos-icon>
            </span>
            {{ ((data.CalculatedQty - (data.UsedPrepQty ? data.UsedPrepQty :0)) >= 0 ?
            data.CalculatedQty - (data.UsedPrepQty ? data.UsedPrepQty :0) : 0) | tark_number }}
        </ng-template>
        <div class="col-12 my-pix-5 p-0" *ngIf="recipeInformation">
            <fieldset class="fieldset pl-075em" title="Recipe">
                <legend class="reset-style bold"><span class="bold">Recipe</span></legend>
                <div class="mb-pix-5 pe-pix-15 overflow-auto" [ngStyle]="{'max-height': getRecipeHeight()}">
                    <h4>{{recipeInformation}}.</h4>
                </div>
            </fieldset>
        </div>
    </div>
</fieldset>
<div class="row mx-pix-n25">
    <div class="col-12 action-button-container page-footer">
        <button class="btn btn-lg btn-primary btn-save pos-button" (click)="updatePrepQty(); isFormSubmitted = true;"
            type="button">Record
            Prep</button>
        <button class="btn btn-lg btn-secondary btn-cancel pos-button" (click)="showCancelButton ? resetForm(): close()"
            type="button">{{ showCancelButton ? "Cancel" : "Close" }}</button>
    </div>
