import { Component } from '@angular/core';
import { WaitListService } from '../../services/wait-list.service';
import { ActivatedRoute } from '@angular/router';
import { finalize, interval, map, Observable, startWith } from 'rxjs';
import { AlertsService, ApplicationStateService, ConfirmDeleteModalComponent, Messages, ModalService, NumpadWrapperComponent, PhoneMaskPipe, SettingParam, SpinnerService, StringUtils } from 'src/app/shared';
import { DomainConstants } from 'src/app/shared/constants';
import { CustomerWaitListEntry } from '../../interfaces/customer-wait-list-entry';
import { UntilDestroy } from '@ngneat/until-destroy';
import { watch } from 'src/app/shared/components/icon';
import * as $ from 'jquery';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pos-wait-list-terminal',
  templateUrl: './wait-list-terminal.component.html',
  styleUrls: ['./wait-list-terminal.component.scss']
})
export class WaitListTerminalComponent {

  terminalId = 0;
  waitListEntries: CustomerWaitListEntry[] = [];
  headerLinks = {
    changeTerminal: false,
    mappedTerminals: false,
    time: false,
    signIn: false,
    home: false,
    admin: false,
    activeOrders: false,
    tasks: false,
    filterProducts: false,
    customLinks: false,
  };
  settingParam: SettingParam;
  currentTime$: Observable<Date>;
  scrollHeight: number = $(window).height();
  icons = { watch }
  showCode: boolean[] = [];

  constructor(
    route: ActivatedRoute,
    private waitListService: WaitListService,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private modalService: ModalService,
    private phoneMaskPipe: PhoneMaskPipe,
    private applicationStateService: ApplicationStateService,
  ) {
    this.terminalId = route.snapshot.params.terminalId ? parseInt(route.snapshot.params.terminalId, 10) : 0;
  }

  ngOnInit() {
    this.settingParam = this.applicationStateService.settingParam;
    if (this.terminalId)
      this.loadData();

    this.currentTime$ = interval(1000).pipe(map(() => new Date()));
    this.resizeWindow();
  }

  resizeWindow = () => {
    this.scrollHeight = $(window).height() - 220;
    $(window).resize(() => {
      this.scrollHeight = $(window).height() - 220;
    });
  }

  loadData() {
    this.spinnerService.hide();
    this.waitListService.getWaitListEntriesByTerminalId(this.terminalId)
    .pipe(finalize(() => this.spinnerService.hide()))
    .subscribe({
      next: (res: CustomerWaitListEntry[]) => {
        this.waitListEntries = res;
        this.waitListEntries.forEach((entry) => {
          entry.waitingTime$ = this.getWaitingTimeObservable(entry.WaitingSince);
        });
        this.showCode = this.waitListEntries.map((_curr, index) => this.showCode[index] || false);
      },
      error: this.alertService.showApiError,
    })
  }

  getWaitingTimeObservable(waitingSince: Date): Observable<string> {
    return interval(1000).pipe(
      startWith(0),
      map(() => this.calculateWaitTime(waitingSince))
    );
  }

  calculateWaitTime(waitingSince: Date): string {
    const now = new Date().getTime();
    const waitingTimeMs = (now - new Date(waitingSince).getTime() < 0) ? 0 : (now - new Date(waitingSince).getTime());
    const hours = Math.floor(waitingTimeMs / 3600000); // 1 hour = 3600000 ms
    const minutes = Math.floor((waitingTimeMs % 3600000) / 60000); // Remaining minutes
    const seconds = Math.floor((waitingTimeMs % 60000) / 1000); // Remaining seconds
    return `${this.padNumber(hours)}:${this.padNumber(minutes)}:${this.padNumber(seconds)}`;
  }

  padNumber(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }

  OpenAddNewEntryModal() {
    if (!this.terminalId) return;
    const modalRef = this.modalService.getModalWrapper(NumpadWrapperComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      initialState: {
        numpadTitle: 'Set Phone Number',
        numpadOption: {
          allowDecimal: false, allowLeadingZero: true, allowAlphabets: false, doubleZero: false, isMaskInput: true,
          mask: this.settingParam?.PhoneNumberMask?.trim() ? this.settingParam.PhoneNumberMask : DomainConstants.DefaultPhoneMask,
          maxLength: 10,
          minLength: 10,
          minValidationMessage: 'Please enter a valid phone number'
        },
        value: ' '
      }
    });
    modal.close.subscribe({
      next: (res) => {
        if (res?.value > 0) {
          this.AddNewEntryModal(res.value);
        }
      }
    })
  }

  AddNewEntryModal(phoneNumber: string) {
    const newWaitEntryModal: CustomerWaitListEntry = {
      Code: 0,
      PhoneNumber: phoneNumber,
      WaitingSince: new Date(),
      NotifyCount: 0,
      TerminalId: this.terminalId,
    }

    this.spinnerService.hide();
    this.waitListService.addWaitListEntry(newWaitEntryModal)
    .pipe(finalize(() => this.spinnerService.hide()))
    .subscribe({
      next: () => {
        this.alertService.renderSuccessMessage(Messages.WaitListSuccessfullyAdded);
        this.loadData();
      },
      error: this.alertService.showApiError,
    })
  }

  notify(entry: CustomerWaitListEntry): void {
    // Handle notify action
    this.spinnerService.show();
    this.waitListService.notifyWaitListEntry(entry)
    .pipe(finalize(() => this.spinnerService.hide()))
    .subscribe({
      next: () => {
        const message = StringUtils.format(Messages.WaitListEntryNotified, { 'phoneNumber': this.phoneMaskPipe.transform("" + entry.PhoneNumber) })
        this.alertService.renderSuccessMessage(message);
        this.loadData();
      },
      error: this.alertService.showApiError,
    })
  }

  openDeleteEntryModal(entry: CustomerWaitListEntry): void {
    const modalRef = this.modalService.getModalWrapper(ConfirmDeleteModalComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      initialState: {
        message: StringUtils.format(Messages.ConfirmDeleteWaitListEntry, { 'phoneNumber': this.phoneMaskPipe.transform(entry.PhoneNumber) })
      }
    })
    modal.close.subscribe({
      next: (res) => {
        if (res?.shouldDelete)
          this.deleteEntry(entry);
      }
    })
  }

  deleteEntry(entry: CustomerWaitListEntry): void {
    this.spinnerService.show();
    this.waitListService.deleteWaitListEntry(entry.Code)
    .pipe(finalize(() => this.spinnerService.hide()))
    .subscribe({
      next: () => {
        this.alertService.renderSuccessMessage(Messages.WaitListDeleteSuccessfully);
        this.loadData();
      },
      error: this.alertService.showApiError,
    })
  }

  getMaskedCode(code: string): string {
    return '*'.repeat(code.toString().length);
  }
}
