import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared';
import { OnlineOrderConfiguration } from '../interface/online-ordering-configuration';
import { GooglePayConfiguration } from '../interface/google-pay-configuration';
import { ApplePayConfiguration } from '../interface/apple-pay-configuration';


@Injectable({
  providedIn: 'root'
})
export class OnlineOrderingConfigurationsService {

  onlineOrderConfigurationApiPath = `${RuntimeConstants.API_PATH}onlineorderingconfiguration/`
  constructor(private httpService: HttpClient) { }

  getOnlineOrderingConfigurations(): Observable<OnlineOrderConfiguration> {
    return this.httpService.get<OnlineOrderConfiguration>(
      `${this.onlineOrderConfigurationApiPath}`
    );
  }

  saveOnlineOrderingConfigurations(configuration, file: File = null): Observable<any> {
    const formData = new FormData();
    if (file) {
      formData.append('file', file);
    }
    formData.append('model', JSON.stringify(configuration));
    return this.httpService.post(
      `${this.onlineOrderConfigurationApiPath}`, formData
    );
  }

  newGooglePayConfiguration(): GooglePayConfiguration {
    return {
      MerchantId: "",
      MerchantName: "",
      PaymentGatewayId: "",
      IsValid: false,
    }
  }

  newApplePayConfiguration(): ApplePayConfiguration {
    return {
      WebsiteDomain: "",
      MerchantName: "",
      MerchantId: "",
      MerchantIdentityCertificateName: "",
      CertificatePassword: "",
      IsValid: false,
    }
  }

  uploadDomainVerificationFile(domainFile: File): Observable<any> {
    const formData = new FormData();
    if (domainFile) {
      formData.append('file', domainFile);
    }
    return this.httpService.post(`${this.onlineOrderConfigurationApiPath}upload-domain-verification-file`, formData)
  }
}
