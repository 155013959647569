import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class BackupService {
  backupApiUrl = '';
  constructor(private httpService: HttpClient) {
    this.backupApiUrl = RuntimeConstants.API_PATH + 'Backup';
  }

  getScreenBackupsList(): Observable<any> {
    return this.httpService.get(`${this.backupApiUrl}/GetScreenBackupsList`);
  }

  deleteBackup(backupId: number): Observable<any> {
    return this.httpService.delete(`${this.backupApiUrl}/DeleteScreenBackup/` + backupId);
  }

  restoreScreenFromBackup(backupId: number): Observable<any> {
    return this.httpService.post(`${this.backupApiUrl}/RestoreScreenFromBackup/` + backupId, null);
  }

  createBackup(backupName: string): Observable<any> {
    return this.httpService.post(`${this.backupApiUrl}/CreateScreenBackup/` + backupName, null);
  }
}
