<div>
  <button class="close" (click)="onCancel()">&times;</button>
  <h4 class="modal-content__heading">Screen Backups</h4>
</div>
<div class="pt-pix-5 pb-pix-10 overflow-hidden">
  <div class="col-12 pos-modal-body modal-content__list screen-scroll">
    <ng-table #gridComponent [columns]="backupColumns" [sort]="false" [paginator]="false" [data]="backups">
    </ng-table>
    <ng-template let-data="rowData" #operationTemplate>
      <span class="vertical-mid pointer font-1-17em" (click)="confirmRestoreBackup(data)" #backupToolTip="bs-tooltip"
        tooltip="Restore">
        <pos-icon [icon]="icons.database"></pos-icon>
      </span>
      <span (click)="confirmDelete(data)" class="vertical-mid ps-pix-20 pointer">
        <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
      </span>
    </ng-template>
    <ng-template #headerTemplate>
      <div class="text-center">
        <button type="button" class="btn btn-sm btn-orange normal pos-button" (click)="createBackup()">
          <pos-icon [icon]="icons.screenBackupPlus" class="vertical-mid"></pos-icon> Create Backup
        </button>
      </div>
    </ng-template>
  </div>
  <div class="clearfix"></div>
  <div class="col-12 action-button-container popup-footer">
    <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button"
      (click)="onCancel()">Close</button>
  </div>
</div>
