import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RuntimeConstants, SettingParam } from 'src/app/shared';

@Injectable({
  providedIn: 'root'
})
export class AccountsAndTabsService {

  accountsandTabsApiBasePath = `${RuntimeConstants.API_PATH}accountsandtabs`;

  constructor(private httpClient: HttpClient) { }

  getAccountsandTabsDetail(terminalId: number): Observable<SettingParam> {
    return this.httpClient.get<SettingParam>(`${this.accountsandTabsApiBasePath}/${terminalId}`);
  }
  saveAccountAndTabs(params: SettingParam): Observable<SettingParam> {
    return this.httpClient.post<SettingParam>(`${this.accountsandTabsApiBasePath}`, params);
  }

  getAccountType(): Observable<Array<string>> {
    return this.httpClient.get<Array<string>>(`${this.accountsandTabsApiBasePath}`);
  }
}
