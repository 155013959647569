import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Messages } from 'src/app/shared';
import { BaseCrudModelComponent, SpinnerService } from 'src/app/shared/components';
import { AlertsService, FormUtilityService } from 'src/app/shared/services';
import { InventoryOffSiteLocation } from '../../interfaces/inventory-offsite-location';
import { InventoryOffSiteLocationService } from '../../services/inventory-offsite-location.service';

@Component({
  selector: 'pos-inventory-off-site-location-edit',
  templateUrl: './inventory-off-site-location-edit.component.html'
})
export class InventoryOffSiteLocationEditComponent extends BaseCrudModelComponent<InventoryOffSiteLocation> implements OnInit {

  @Input() inventoryOffSiteLocation: InventoryOffSiteLocation;
  @ViewChild('inventoryOffSiteLocationForm') form: NgForm;
  constructor(spinnerService: SpinnerService,
    private inventoryOffSiteLocationService: InventoryOffSiteLocationService,
    alertService: AlertsService,
    formUtilityService: FormUtilityService) {
    super(inventoryOffSiteLocationService, alertService, spinnerService, formUtilityService);
  }
  
  ngOnInit(): void {
    this.inventoryOffSiteLocation = this.inventoryOffSiteLocation ?? this.inventoryOffSiteLocationService.newOffSiteLocation();
    this.saveSuccessMessage = Messages.OffSiteLocationSaveSuccess;
  }

  saveOffSiteLocation(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.save(this.inventoryOffSiteLocation);
  }

  cancel() {
    this.hide({ shouldReload: false });
  }

  onSaveSuccess(model: InventoryOffSiteLocation): void {
    this.hide({ shouldReload: true });
  }

  get getForm(): NgForm {
    return this.form;
  }

  loadDependencies(): void {
    // No implementation
  }

}
