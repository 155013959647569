import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { FilterConfiguration } from 'src/app/shared/interface';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';
import { SalesProductHistory } from '../interface/sales-product-history';

@Injectable({
    providedIn: 'root'
})
export class SalesProductHistoryService extends BaseCrudService<SalesProductHistory> {

    constructor(httpService: HttpClient) {
        super('sales-product-history', httpService);
    }

    getNewSalesProduct(): SalesProductHistory {
        return {
            Id: 0,
            SalesProductId: null,
            Name: null,
            Description: null,
            SalesCategoryId: null,
            SalesCategory: null,
            IsActive: false,
            IsTaxable: false,
            IsRevenue: false,
            SalesTaxType: '',
            PrinterName: null,
            IsUsePickScreen: false,
            MakeTableColor: null,
            IsInStock: false,
            IsTimedPrep: false,
            PrepGroupName: null,
            PrepTime: null,
            PrepButtonColor: null,
            IsUseWorkflowTimer: false,
            IsShowInstructions: false,
            UseParallelPickScreen: false,
            IsSkipReadyState: false,
            IsAllowOutOfStockOrder: false,
            InventoryQty: null,
            Increment: null,
            DefaultQty: null,
            UnitId: null,
            SalesTaxId: 0,
            NoTags: false,
            Image: null,
            MenuDisplayText: null,
            DateAdded: new Date(Date.now()),
            IsNotified: true,
            SalesProductDateAdded: new Date(Date.now())
        };
    }

    getSalesProductsHistoryBySalesProductId(salesProductId: number): Observable<Array<SalesProductHistory>> {
        return this.httpService.get<Array<SalesProductHistory>>(`${this.apiEndpoint}/sales-product/${salesProductId}`);
    }

    getProductHistories(productId: number, filterConfiguration: FilterConfiguration): Observable<Array<SalesProductHistory>> {
        return this.httpService.post<Array<SalesProductHistory>>(`${this.apiEndpoint}/all/${productId}`,
            filterConfiguration);
    }

    getTotalRecordsCount(productId: number): Observable<number> {
        return this.httpService.get<number>(`${this.apiEndpoint}/count/${productId}`);
    }

}
