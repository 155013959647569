import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Account, AccountOrder } from '../../interface';
import { BaseCrudComponent } from 'src/app/shared/components/base-crud.component';
import { NgForm } from '@angular/forms';
import { tablesSolarPanel, accountOrdersShoppingCart, loyaltyCreditCard, infoManageTablesSolarPanel } from 'src/app/shared/components/icon';
import { AccountService, FormUtilityService } from 'src/app/shared/services';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { Messages, DomainConstants } from 'src/app/shared/constants';
import { finalize } from 'rxjs/operators';
import { AccountCategory } from 'src/app/information-management/account-categories/interface/account-category';
import { AccountCategoryService } from 'src/app/shared/services/account-category.service';
import { AccountTypeService } from '../../../../shared/services/account-type.service';
import { Observable, forkJoin } from 'rxjs';
import { AccountAttributeTypes } from 'src/app/information-management/customers';
import { AccountType } from '../../interface/account-type';
import { AccountDetailsPrint } from '../../interface/account-details-print';
import { ApplicationStateService, EventBroadcastingService, SettingParam, ModalService } from 'src/app/shared';
import { AccountAttribute } from 'src/app/information-management/accounts/account-attributes/interface/account-attribute';
import { SwipeCardComponent } from 'src/app/shared/components/swipe-card/swipe-card.component';
import { orderBy } from 'lodash';
declare let $: any;

@Component({
    selector: 'pos-account-edit',
    templateUrl: './account-edit.component.html',
    styleUrls: ['./account-edit.component.scss']
})
export class AccountEditComponent extends BaseCrudComponent<Account> implements OnInit {
    get getForm(): NgForm {
        return this.accountForm;
    }
    @Input() accountId = 0;
    @Input() accountTypeId = 0;
    @Output() saveAccount = new EventEmitter();
    @Output() cancelAccount = new EventEmitter();
    @Output() formChange = new EventEmitter<NgForm>();
    @Input() fromListPage: boolean;
    account: Account;
    settingParam: SettingParam;
    loyaltyCardInfoModelRef: any;
    accountCategories: AccountCategory[] = [];
    accountTypes: AccountType[] = [];
    isAccountType = false;
    accountOrderDetails: AccountOrder[] = [];
    accountAttributeTypes: AccountAttributeTypes[] = [];
    @ViewChild('ZipCode') ZipCode: any;
    public icons = {
        tablesSolarPanel,
        accountOrdersShoppingCart,
        loyaltyCreditCard,
        infoManageTablesSolarPanel
    };
    formGroups = {
        'Account': { '$valid': true }
    };
    @ViewChild('accountForm') accountForm: NgForm;
    tabList = {
        Account: 'Account',
        Orders: 'Orders'
    };
    selectedTab = this.tabList.Account;
    defaultMask = DomainConstants.DefaultPhoneMask;
    props = {
        labelClass: '',
        controlBoxClass: 'col-lg-8 col-md-8 col-sm-12 col-xs-12'
    }

    constructor(
        protected alertService: AlertsService,
        protected spinnerService: SpinnerService,
        protected accountService: AccountService,
        private accountCategoryService: AccountCategoryService,
        private accountTypeService: AccountTypeService,
        private applicationStateService: ApplicationStateService,
        private eventBroadcastingService: EventBroadcastingService,
        private modalService: ModalService,
        formUtilityService: FormUtilityService
    ) {
        super(accountService, alertService, spinnerService, formUtilityService);
    }

    ngOnInit() {
        this.id = this.accountId;
        this.account = this.accountService.newAccount();
        this.saveSuccessMessage = Messages.AccountSaveSuccess;
        this.dataSubscription();
        this.scrollToTop();
        this.settingParam = this.applicationStateService.settingParam;
    }
    private dataSubscription(): void {
        if (this.id > 0) {
            this.spinnerService.show();
            this.loadData().pipe(finalize(() => {
                this.spinnerService.hide();
            }))
                .subscribe({
                    next: (res) => {
                        this.account = res;
                        this.checkIsTypeAccount(this.account.AccountTypeId);
                    }, error: this.alertService.showApiError
                });
        } else {
            if (this.accountTypeId > 0) {
                this.account.AccountTypeId = this.accountTypeId;
            }
        }
    }
    public loadDependencies() {
        const accountObservables: Array<Observable<any>> = [];
        this.spinnerService.show();
        accountObservables.push(this.accountCategoryService.getAccountCategories());
        accountObservables.push(this.accountTypeService.getAccountTypes());
        accountObservables.push(this.accountService.getCustomerAccountOrders(this.id));
        forkJoin(accountObservables)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (responses: any) => {
                    this.formChange.emit(this.accountForm);
                    this.accountCategories = responses?.[0]?.length ? responses[0] : [];
                    this.accountTypes = responses?.[1]?.length ? responses[1] : [];
                    this.accountTypes.forEach(x => {
                        x.AccountTerm = x.AccountType == DomainConstants.AccountTypes.Account ? this.settingParam.LoyalAccountTerm : (x.AccountType == DomainConstants.AccountTypes.Tab ? this.settingParam.AccountTerm : x.AccountType);
                    });
                    this.accountTypes = orderBy(this.accountTypes, 'AccountTerm');
                    this.checkIsTypeAccount(this.account.AccountTypeId);
                    this.accountOrderDetails = responses?.[2]?.length ? responses[2] : [];
                }, error: this.alertService.showApiError
            });
    }
    public submit(isValid: boolean) {
        const pattern = /^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] *\d[A-Z]\d)$/;
        const isValidPostalCode = this.account.PostalCode ? pattern.test(this.account.PostalCode.toString()) : true;
        if (!isValid || !this.account.AccountTypeId || !isValidPostalCode) {
            return;
        }
        if (!this.isAccountType) {
            this.account.LoyaltyCardNumber = null;
        }
        this.prepareAccountAttributes();
        this.save(this.account);
    }
    public onSaveSuccess(model: Account): void {
        this.cancelAccount.emit({ shouldReload: true, account: model });
    }
    public cancelEditing(reload: boolean = false): void {
        this.cancelAccount.emit({ shouldReload: reload });
    }
    private scrollToTop(): void {
        window.scrollTo(0, 0);
    }
    public getHeight(isOuterContainer: boolean): string {
        if (isOuterContainer) {
            return $(window).height() - 300 + 'px';
        } else {
            return $(window).height() - 310 + 'px';
        }
    }
    public checkIsTypeAccount(accountTypeId: number) {
        if (accountTypeId) {
            const account = this.accountTypes.find(a => a.AccountType === DomainConstants.AccountTypes.Account);
            if (account && account.Id === accountTypeId) {
                this.isAccountType = true;
            } else {
                this.isAccountType = false;
            }
        } else {
            this.isAccountType = false;
        }
    }
    swipeCard() {
        if (this.settingParam.IsEnableLoyaltyCardProgram) {
            const loyaltyCardModalRef = this.modalService.getModalWrapper(SwipeCardComponent);
            const modal = loyaltyCardModalRef.show({
                animated: false,
                backdrop: 'static',
                keyboard: false,
                class: 'vertical-center',
                initialState: {
                    modalHeaderText: 'Loyalty Card',
                    message: Messages.SwipeCardRequest
                }
            });
            modal.close.subscribe(res => {
                if (res?.cardNumber) {
                    this.account.LoyaltyCardNumber = res.cardNumber;
                }
            });
        }

    }

    accountSwipeBroadCast(enabled: boolean) {
        this.eventBroadcastingService.changeCardBasedSignIn(enabled);
    }
    public onAttributeTypeChange(attributeType: AccountAttributeTypes[]) {
        this.accountAttributeTypes = attributeType;
    }
    public onPrintAccountDetails() {
        const accountDetailPrint: AccountDetailsPrint = {
            AccountId: this.account.Id,
            TerminalId: this.applicationStateService.terminalId,
            TerminalName: this.applicationStateService.terminalName
        };
        this.spinnerService.show();
        this.accountService.printAccountDetails(accountDetailPrint)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: () => {
                }, error: this.alertService.showApiError
            });
    }
    public prepareAccountAttributes() {
        if (this.account && !this.account.AccountAttributes) {
            this.account.AccountAttributes = [];
        }
        if (this.isAccountType) {
            this.accountAttributeTypes.forEach((attributeType: AccountAttributeTypes) => {
                if (attributeType?.Value?.toString().trim() || attributeType.Id) {
                    const attribute = this.account.AccountAttributes.find(a => a.AccountAttributeTypeId === attributeType.Id);

                    if (attribute) {
                        attribute.AttributeValue = attributeType.Value?.toString();
                    } else {
                        const accountAttribute: AccountAttribute = {
                            id: 0,
                            AccountId: this.account.Id,
                            AttributeValue: attributeType.Value?.toString().trim(),
                            AccountAttributeTypeId: attributeType.Id
                        };
                        this.account.AccountAttributes.push(accountAttribute);
                    }
                }
            });
        } else {
            this.account.AccountAttributes.forEach((accountAttribute: AccountAttribute) => {
                accountAttribute.AttributeValue = '';
            });
        }
    }
}
