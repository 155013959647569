import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'fixedLengthNumber' })
export class FixedLengthNumberComponent implements PipeTransform {
    transform(value, len) {
        if ((!value || !len) && value != 0) {
            return value;
        }
        const num = parseInt(value, 10);
        len = parseInt(len, 10);
        if (isNaN(num) || isNaN(len)) {
            return value;
        }
        let stringNum = '' + num;
        while (stringNum.length < len) {
            stringNum = '0' + num;
        }
        return stringNum;
    }

}
