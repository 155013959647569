import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseFormComponent, FormUtilityService } from 'src/app/shared';

@Component({
  selector: 'pos-account-edit-wrapper',
  templateUrl: './account-edit-wrapper.component.html',
})
export class AccountEditWrapperComponent extends BaseFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.accountForm;
  }

  @Input() accountId: number = null;
  initialize = false;
  @Input() accountTypeId: number = null;
  @Input() fromListPage = true;
  @Output() saveAccount = new EventEmitter();
  @Output() cancelAccount = new EventEmitter();
  accountForm: NgForm;
  constructor(private route: ActivatedRoute,
    private router: Router,
    formUtilityService: FormUtilityService) {
    super(formUtilityService);
    const navigation = router.getCurrentNavigation();
    this.accountId = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
    this.accountTypeId = (navigation?.extras?.state && navigation?.extras?.state.accountTypeId) ?? 0;
    this.initialize = false;
  }

  ngOnInit() {
    this.initialize = true;
  }

  onClose($event: any) {
    this.router.navigate(['accounts'], { relativeTo: this.route.parent });
  }

  onFormChange($event: NgForm) {
    this.accountForm = $event;
    this.setInitialValue();
  }
}
