import { Component, OnInit, OnChanges, Input, Output, EventEmitter, } from '@angular/core';
import { settleSplitPrint } from 'src/app/shared/components/icon';

@Component({
  selector: 'pos-settle-seat-splits',
  templateUrl: './settle-seat-splits.component.html',
  styleUrls: ['./settle-seat-splits.component.scss', '../../../../../../styles/pages/design/settle.scss']
})
export class SettleSeatSplitsComponent implements OnInit, OnChanges {

  @Input() currentBalance: number = 0;
  @Output() printSplits = new EventEmitter<any>();
  hasFraction: boolean = false;
  currentSplitBalance: number = 0;
  noOfSplits: number = 1;
  icons = {
    settleSplitPrint
  };
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.currentSplitBalance = this.currentBalance / this.noOfSplits;
  }


  precisionRound(number, precision) {
    const factor = Math.pow(10, precision);
    return Math.round(number * factor) / factor;
  }

  resetForm() {
    this.hasFraction = false;
    this.currentSplitBalance = this.currentBalance;
  }

  printRequest() {
    const printObj = {
      SplitCount: this.noOfSplits,
      SplitAmount: this.precisionRound(this.currentSplitBalance, 2)
    };
    this.printSplits.emit(printObj);
  }

  splitBalance() {
    this.hasFraction = false;
    if (this.noOfSplits && this.noOfSplits % 1 === 0) {
      this.currentSplitBalance = this.currentBalance / this.noOfSplits;
      this.currentSplitBalance = this.precisionRound(this.currentSplitBalance, 15);
    } else {
      this.hasFraction = true;
    }
  }

}
