import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { InventoryProductNutritionService } from 'src/app/information-management/nutrition-facts';
import { SalesProduct, SalesProductsService } from 'src/app/information-management/sales-products';
import { OrderNavigationButton } from 'src/app/orders/interface';
import { IngredientsDietaryWarning, IngredientsDietaryWarningService, IngredientsInfoService } from 'src/app/orders/order-entry';
import { AlertsService, RuntimeConstants, SpinnerService } from 'src/app/shared';
import { inventoryProductIngredientStore, exclamationTriangle, nutritionFacts, times } from 'src/app/shared/components/icon';
import { SalesProductNutrition } from '../../interfaces/sales-product-nutrition';

@Component({
  selector: 'pos-menu-explorer-product-info',
  templateUrl: './menu-explorer-product-info.component.html',
  styleUrls: ['./menu-explorer-product-info.component.scss']
})
export class MenuExplorerProductInfoComponent implements OnInit {
  productInfo: OrderNavigationButton;
  public imagePath = RuntimeConstants.IMAGE_BASE_PATH;
  tabList = {
    ProductIngredients: 'ProductIngredients',
    ProductDietaryWarnings: 'ProductDietaryWarnings',
    NutritionFacts: 'NutritionFacts'
  };
  activeTab = this.tabList.ProductIngredients;

  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  icons = {
    inventoryProductIngredientStore,
    exclamationTriangle,
    nutritionFacts,
    times
  };
  ingredientsInfo: string;
  dietaryWarnings: Array<IngredientsDietaryWarning> = [];
  nutritionFacts: SalesProductNutrition;
  salesProduct: SalesProduct;
  constructor(private ingredientsInfoService: IngredientsInfoService,
    private ingredientsDietaryWarningService: IngredientsDietaryWarningService,
    private inventoryProductNutritionService: InventoryProductNutritionService,
    private salesProductService: SalesProductsService,
    private spinnerService: SpinnerService,
    private alertService: AlertsService) { }

  ngOnInit(): void {
    this.getRequiredData();
  }

  getRequiredData() {
    this.spinnerService.show();
    const observables: Array<Observable<any>> = [];
    observables.push(this.salesProductService.getSalesProduct(this.productInfo.SalesProductId));
    observables.push(this.ingredientsDietaryWarningService.getIngredientsDietaryWarningInfo(this.productInfo.SalesProductId));
    observables.push(this.inventoryProductNutritionService.getNutritionsBySalesProductId(this.productInfo.SalesProductId,
      this.productInfo.DefaultSizeId));
    forkJoin(observables)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: ([salesProduct, dietaryWarnings, salesProductNutritionFacts]:
          [SalesProduct, Array<IngredientsDietaryWarning>, SalesProductNutrition]) => {
          this.salesProduct = salesProduct;
          this.ingredientsInfo = salesProductNutritionFacts.Ingredients;
          if (this.ingredientsInfo) {
            this.ingredientsInfo = this.ingredientsInfo.replace('Ingredients:', '');
          }
          this.dietaryWarnings = dietaryWarnings;
          this.nutritionFacts = salesProductNutritionFacts;
        },
        error: this.alertService.showApiError});
  }

  closeModal() {
    this.close.emit();
  }

}
