import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';
import { InventoryOrderReceivingNewBarcodeList } from 'src/app/shared/interface/inventory-order-receiving-new-barcode-list';
import { Observable } from 'rxjs';

@Injectable()
export class InventoryProductBarCodeService extends BaseCrudService<InventoryOrderReceivingNewBarcodeList> {

    inventoryProductBarCodeApiUrl = '';
    constructor(httpService: HttpClient) {
        super('inventory-product-barcode', httpService);
        this.inventoryProductBarCodeApiUrl = RuntimeConstants.API_PATH + 'inventory-product-barcode';
    }
    newInventoryProductBarCode() {
        return {
            Id: 0,
            Barcode: '',
            InventoryProductId: null,
            UnitQtyOverride: null,
            UnitName: ''
        };
    }

    getInventoryProductBarCodes(inventoryProductId: number): Observable<any> {
        return this.httpService.get(this.inventoryProductBarCodeApiUrl + '/' + inventoryProductId);
    }
    saveInventoryProductBarcode(inventoryProductBarcode: InventoryOrderReceivingNewBarcodeList) {
        return this.httpService.post(this.inventoryProductBarCodeApiUrl, inventoryProductBarcode);
    }
    updateInventoryProductBarcode(inventoryProductBarcode: InventoryOrderReceivingNewBarcodeList) {
        return this.httpService.put(this.inventoryProductBarCodeApiUrl + '/' + inventoryProductBarcode.Id, inventoryProductBarcode);
    }

    saveInventoryProductBarcodes(inventoryProductBarcodes: Array<InventoryOrderReceivingNewBarcodeList>):
        Observable<Array<InventoryOrderReceivingNewBarcodeList>> {
        return this.httpService.post<Array<InventoryOrderReceivingNewBarcodeList>>
            (this.inventoryProductBarCodeApiUrl + `/save-barcodes`, inventoryProductBarcodes);
    }

    printInventoryProductBarCodes(terminalId, selectedProducts: number[]) {
        return this.httpService.post(`${this.inventoryProductBarCodeApiUrl}/print-barcode/${terminalId}`, selectedProducts);
    }
}
