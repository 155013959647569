<pos-edit-wrapper (close)="onCancel(false)" heading="Option List: {{productName}}">
  <form #optionConfiguration="ngForm" autocomplete="off">
    <div class="col-12 pos-modal-body">
      <div class="col-12 p-0">
        <pos-row>
          <pos-column>
            <pos-form-field label="Select Option" inputStyle="col-12">
              <pos-row colSm="6">
                <pos-column>
                  <ng-select posInput #option name="option" [(ngModel)]="selectedOption" [items]="optionList"
                    bindLabel="Name" bindValue="Id" placeholder="Select option">
                  </ng-select>
                </pos-column>
                <pos-column>
                  <button type="button" class="btn btn-primary white btn normal pos-button" (click)="addOption()" [disabled]="!selectedOption">
                    Add
                  </button>
                </pos-column>
              </pos-row>
            </pos-form-field>
          </pos-column>
        </pos-row>
      </div>
      <div class="col-12 px-pix-5 mt-pix-10">
        <ng-table [scrollable]="true" [scrollHeight]="screenHeight.toString()" #optioGridComponent [sort]="false"
          [paginator]="false" [columns]="mappedOptionListColumns" [data]="mappedOptionList" [isReorderableTable]="true"
          [ReorderableColumnRow]="'row'" (onRowReorder)="gridRowReorder($event)">
        </ng-table>
      </div>
    </div>
    <div class="col-12 action-button-container popup-footer">
      <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" scrollup
        (click)="onCancel(false)">Close</button>
    </div>
  </form>
</pos-edit-wrapper>
