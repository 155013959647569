<pos-kiosk-header *ngIf="screenConfig.Header.IsShow" [header]="screenConfig.Header" />
<div class="row mx-0 designer-area" [style.background-color]="screenConfig.BackgroundColor"
    [style.height]="sectionHeight + 'px'"
    [style.background-image]="screenConfig.BackgroundImage ? 'url(\''+ imageRootPath + screenConfig.BackgroundImage + '\')' : null">
    <div class="p-0 relative" [ngClass]="screenConfig.ShowInvoice ? 'col-xl-9 col-8' : 'col-12'">
        <div class="footer-buttons" [ngStyle]="{'justify-content': screenConfig.VerticalAlignment}">
            <button type="button" class="btn btn-lg confirm-button" (click)="buttonClick(screenButtons[0], false)"
                [ngStyle]="{'background-color': screenConfig.ConfirmButtonBackgroundColor ? screenConfig.ConfirmButtonBackgroundColor : 'rgb(217, 55, 62, '+screenConfig.ConfirmButtonOpacity+')',
                  'color': screenConfig.ConfirmButtonFontColor ? screenConfig.ConfirmButtonFontColor : 'white',
                    'width': screenConfig.ConfirmButtonWidth ? screenConfig.ConfirmButtonWidth + 'px' : ''
                }">
                <div class="config-button">
                    <pos-kiosk-screen-setup [screenButton]="screenButtons[0]" [screenButtons]="screenButtons"
                        [isDesignMode]="isDesignMode" [setupItems]="setupItems"></pos-kiosk-screen-setup>
                </div>
                <span [innerHTML]="screenConfig.ConfirmButtonText"></span>
            </button>
            <button type="button" class="btn btn-lg reject-button" (click)="buttonClick(screenButtons[1])" [ngStyle]="{'background-color': screenConfig.RejectButtonBackgroundColor ? screenConfig.RejectButtonBackgroundColor : 'rgb(0, 0, 0, '+screenConfig.RejectButtonOpacity+')',
                 'color': screenConfig.RejectButtonFontColor ? screenConfig.RejectButtonFontColor : 'white',
                'width': screenConfig.RejectButtonWidth ? screenConfig.RejectButtonWidth + 'px' : ''
            }">
                <div class="config-button">
                    <pos-kiosk-screen-setup [screenButton]="screenButtons[1]" [screenButtons]="screenButtons"
                        [isDesignMode]="isDesignMode" [setupItems]="setupItems"></pos-kiosk-screen-setup>
                </div>
                <span [innerHTML]="screenConfig.RejectButtonText"></span>
            </button>
        </div>
    </div>
    <div class="col-xl-3 col-4 p-3" *ngIf="screenConfig.ShowInvoice" [ngStyle]="{'height': invoiceHeight - 3 + 'px'}">
        <pos-kiosk-invoice></pos-kiosk-invoice>
    </div>
</div>
<pos-kiosk-footer *ngIf="screenConfig.Footer.IsShow" [buttons]="screenButtons"
    [footer]="screenConfig.Footer"></pos-kiosk-footer>
