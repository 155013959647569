import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseCrudService } from 'src/app/shared/services';
import { ReportCategory } from '../interface/report-category';
@Injectable({
    providedIn: 'root'
})
export class ReportCategoryService extends BaseCrudService<ReportCategory> {
    constructor(private httpClient: HttpClient) {
        super('report-categories', httpClient);
    }

    newReportCategory(): ReportCategory {
        return {
            Id: 0,
            Name: '',
            ReportIcon: '',
            Ordinal: null,
        };
    }

    updateReportCategoryOrdinal(reportCategory: Array<ReportCategory>){
        return this.httpService.put<Array<ReportCategory>>(`${this.apiEndpoint}/ordinal`, reportCategory);
    }
}
