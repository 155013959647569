import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseCrudService } from 'src/app/shared/services';
import { DataSourceParameter } from '../interface';
import { DataSource } from '../interface/data-source';

@Injectable({
  providedIn: 'root'
})
export class DataSourceService extends BaseCrudService<DataSource> {
  constructor(private httpClient: HttpClient) {
    super('data-source', httpClient);
  }

  newDataSource() {
    const dataSource: DataSource = {
      Id: 0,
      Name: '',
      QueryType: null,
      DataSource: '',
      IsPrivate: false
    };
    return dataSource;
  }

  get(id: number) {
    return this.httpClient.get<DataSource>(this.apiEndpoint + `/${id}/data-source`);
  }

  getDataSourceType(): Observable<Array<string>> {
    return this.httpClient.get<Array<string>>(this.apiEndpoint + '/data-source-types');
  }

  saveDataSourceWithParameters(dataSourceSaveModel: { DataSource: DataSource, DataSourceParameters: Array<DataSourceParameter> }) {
    return this.httpClient.post(`${this.apiEndpoint}`, dataSourceSaveModel);
  }

  getDataFromDataSource(id: number): Observable<any> {
    return this.httpClient.get<any>(this.apiEndpoint + `/${id}`);
  }
}
