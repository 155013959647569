<div>
	<button class="close" (click)="onCancel()">&times;</button>
	<h4 class="modal-content__heading">Training Areas
	</h4>
</div>
<div class="pt-pix-5">
	<div class="pos-modal-body">
		<div *ngIf="trainingAreas?.length" class="row">
			<ng-container *ngFor="let trainingArea of trainingAreas">
				<div class="col-md-2 col-sm-4 col-6 pt-pix-15 pointer text-center" (click)="selectArea(trainingArea)" style="height:121px">
					<div><img src="{{imageRootPath}}/{{trainingArea.Icon}}" alt="" height="50px" width="50px" /></div>
					<div class="bold">{{trainingArea.Name}}</div>
					<div class="gray"> {{trainingArea.CompletedLevels}}/{{trainingArea.TotalLevels}}</div>
				</div>
			</ng-container>
		</div>
		<div class="alert condensed inherit-position alert-warning mb-pix-10 mt-pix-10" *ngIf="trainingAreas?.length == 0">
			There are no training(s) assigned to you.
		</div>
	</div>
	<div class="col-12 action-button-container popup-footer pb-pix-10">
		<button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" type="button" (click)="onCancel()">Close</button>
	</div>
	<div class="clearfix"></div>
</div>
