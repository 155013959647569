import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseCrudService } from 'src/app/shared/services';
import { ReportGroup } from '../interface/report-group';

@Injectable({
    providedIn: 'root'
})
export class ReportGroupService extends BaseCrudService<ReportGroup> {
    constructor(private httpClient: HttpClient) {
        super('report-group', httpClient);
    }

    newReportGroup(): ReportGroup {
        return {
            Id: 0,
            Name: '',
            ReportCategoryId: null,
            ReportIcon: null,
            Category: null,
            PermissionId: null
        };
    }
}
