import { Component, OnInit, OnDestroy } from '@angular/core';
import { GridColumn, TarkDateTimePipe } from '@tarktech/tark-ng-utils';
import { SpinnerService } from '../spinner';
import { PrintTableService } from '../../services/print-table.service';
import { Subscription } from 'rxjs';
import { Format } from '@tarktech/tark-ng-utils/table/format-type';

declare let $: any;

@Component({
  selector: 'pos-print-table',
  templateUrl: './print-table.component.html',
  styleUrls: ['./print-table.component.scss']
})
export class PrintTableComponent implements OnInit, OnDestroy {

  gridListColumns: Array<GridColumn> = [];
  gridDataList: any = [];
  printSubscription: Subscription;
  headerContent: string = null;
  format = Format;

  constructor(private spinnerService: SpinnerService,
    private dateTimePipe: TarkDateTimePipe,
    private printTableService: PrintTableService) {

  }

  ngOnInit() {
    this.printSubscription = this.printTableService.printEmitter.subscribe(response => {
      this.printTable(response.gridColumns, response.gridData, response.headerContent, response.isMentionReportCreatedDate);
    });
  }

  printTable(gridColumns: Array<GridColumn>, gridData: any, headerContent = null, isMentionReportCreatedDate = false) {
    this.spinnerService.show();
    this.gridListColumns = gridColumns;
    this.gridDataList = gridData;
    this.headerContent = headerContent;

    setTimeout(() => {
      const printContents = $('#print-section').clone();
      let data = printContents.html();
      data += `<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
            <style>
            .print-report-btn {
              position: fixed;
              top: 10px;
              right: 10px;
              background-color: orange;
              border: 1px solid grey;
              border-radius: 4px;
              color: white;
              font-size: 14px;
              padding: 5px 8px;
              cursor: pointer;
            }
            </style>`;
      data += '<button onclick="window.print()" class="no-print print-report-btn"><i class="fa fa-print"></i> Print Report</button>';
      data += '<style type="text/css" media="print"> .no-print {visibility: hidden;} </style>';
      if (isMentionReportCreatedDate) {
        data += `<b>Report Created: ${this.dateTimePipe.transform(Date.now())}</b>`;
      }
      const myWindow = window.open('', '', 'width=800,height=600');
      myWindow.document.body.innerHTML = data;
      myWindow.focus();
    });
    this.spinnerService.hide();
  }

  ngOnDestroy(): void {
    this.printSubscription.unsubscribe();
  }

}
