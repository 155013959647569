<div class="row mx-0 ">
    <div class="col-sm-6 col-xs-12">
        <pos-form-field label="Metrics Name" inputStyle="col-md-8 col-xs-12">
            <input posInput class="form-control" name="metricsName" [(ngModel)]="widgetConfig.config.metricsName"
                type="text" />
        </pos-form-field>
    </div>

    <div class="col-sm-6 col-xs-12">
        <pos-form-field label="Datasource" inputStyle="col-md-9 col-xs-12">
            <ng-select posInput [items]="datasources" appendTo="body" bindLabel="Name" bindValue="Id"
                (change)="populateDatasourceParams()" [(ngModel)]="widgetConfig.DatasourceId" name="datasource"
                [clearable]="false" [clearOnBackspace]="false">
            </ng-select>
        </pos-form-field>
    </div>

    <div class="col-sm-6 col-xs-12">
        <pos-form-field label="Mask" inputStyle="col-md-8 col-xs-12">
            <input posInput class="form-control" name="mask" [(ngModel)]="widgetConfig.config.mask"
                placeholder="ie. {value}% / ${value} / #.## / $##.##" type="text" />
        </pos-form-field>
    </div>

    <div *ngFor="let param of widgetConfig.config.params | dataFilter:{'IsUserDefined': true}"
        class="col-sm-6 col-xs-12">
        <pos-form-field [label]="param.ParameterLabel">
            <input posInput
                *ngIf="param.DataType === 'String'|| param.DataType === 'DateTime' || param.DataType === 'Int' || !param.DataType"
                class="form-control" name="param.ParameterName" [(ngModel)]="param.ParameterValue"
                placeholder="{{param.DataType === 'DateTime' ? dateFormat : ''}}" type="text" />
            <ng-select posInput [items]="options" appendTo="body" bindLabel="label" bindValue="value"
                [(ngModel)]="param.ParameterValue" name="options"
                *ngIf="param.DataType === 'DayRange' || param.DataType === 'PeriodRange' || param.DataType === 'ExchangeNames' || param.DataType === 'ExchangeTypes' || param.DataType === 'ExchangeRoutes'">
            </ng-select>
            <p-checkbox posInput *ngIf="param.DataType === 'Bool'" name="param.ParameterName"
                #param.ParameterName="ngModel" [(ngModel)]="param.ParameterValue" binary="true">
            </p-checkbox>
        </pos-form-field>
    </div>

    <div class="col-sm-6 col-xs-12">
        <pos-form-field label="Default Color">
            <pos-color-picker posInput name="defaultColor" [(ngModel)]="widgetConfig.config.color">
            </pos-color-picker>
        </pos-form-field>
    </div>

    <div class="col-sm-6 col-xs-12">
        <pos-form-field label="Default Background">
            <pos-color-picker posInput name="defaultBackground" [(ngModel)]="widgetConfig.config.bgColor">
            </pos-color-picker>
        </pos-form-field>
    </div>

    <div class="col-sm-6 col-xs-12">
        <pos-form-field label="Default Border">
            <pos-color-picker posInput name="defaultBorderColor" [(ngModel)]="widgetConfig.config.borderColor">
            </pos-color-picker>
        </pos-form-field>
    </div>
</div>

<div class="row mx-0" *ngFor="let row of widgetConfig.config.colorConditions; let i = index;">
    <div class="col-12">
        <div class="row align-items-center">
            <div class="col-6">
                <label class="pt-pix-6">Color Condition {{i+1}}</label>
            </div>
            <div class="col-6 d-flex justify-content-end align-items-center">
                <span (click)="addColorCondition()" *ngIf="widgetConfig.config.colorConditions.length === i+1" title="Add">
                    <pos-action-icon [icon]="icons.plusWhite" iconText="Add"></pos-action-icon>
                </span>
                <span (click)="removeColorCondition(i)" *ngIf="i!==0">
                    <pos-icon [icon]="icons.times" class="pointer ban-color font-20 ms-pix-5"></pos-icon>
                </span>
            </div>
        </div>

        <div class="row mx-0">
            <div class="col-4">
                <pos-form-field label="Operator" [appendPaddingTop]="false" inputStyle="col-12">
                    <ng-select posInput [items]="operators" bindLabel="label" bindValue="value" appendTo="body"
                        [(ngModel)]="row.operator" name="operators_{{i}}" [clearable]="false"
                        [clearOnBackspace]="false"></ng-select>
                </pos-form-field>
            </div>

            <div class="col-4">
                <pos-form-field label="Value" [appendPaddingTop]="false" inputStyle="col-12">
                    <input posInput [(ngModel)]="row.value" type="text" name="value_{{i}}" class="form-control"
                        placeholder="number" />
                </pos-form-field>
            </div>

            <div class="col-sm-4 col-12 mt-pix-10 mt-pix-sm-0">
                <pos-form-field label="Color" [appendPaddingTop]="false" inputStyle="col-12">
                    <pos-color-picker posInput styleClass="min-w-pix-130 max-w-pix-200" name="color_{{i}}"
                        [(ngModel)]="row.color" cpPosition="top">
                    </pos-color-picker>
                </pos-form-field>
            </div>

            <div class="col-sm-4 col-12">
                <pos-form-field label="Background" inputStyle="col-12">
                    <pos-color-picker posInput styleClass="min-w-pix-130 max-w-pix-200" name="background_{{i}}"
                        [(ngModel)]="row.bgColor" cpPosition="top">
                    </pos-color-picker>
                </pos-form-field>
            </div>

            <div class="col-sm-4 col-12">
                <pos-form-field label="Border" inputStyle="col-12">
                    <pos-color-picker posInput styleClass="min-w-pix-130 max-w-pix-200" name="borderColor_{{i}}"
                        [(ngModel)]="row.borderColor" cpPosition="top">
                    </pos-color-picker>
                </pos-form-field>
            </div>
        </div>
    </div>

</div>
