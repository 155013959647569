<div class="m-0-auto list-width-70">
    <pos-list-wrapper [headingIcon]="icons.shippingFast" heading="Order Delivery Workflow" (close)="close()">
        <div class="row">
            <div class="col-12">
                <div class="col-md-12 col-sm-12 pb-pix-5">
                    <div class="row pb-pix-5">
                        <div class="col-md-12 text-right">
                            <div class="inline-block ps-pix-10 float-end">
                                <label *ngIf="isCompleted">
                                    From
                                </label>
                                <p-calendar [required]="true" class="ps-pix-5 pe-pix-10" [dateFormat]="dateFormat"
                                    *ngIf="isCompleted" [readonlyInput]="true"
                                    (onSelect)="getAllOrdersBetweenDateAndCompletion()" appendTo="body"
                                    [inputStyle]="{'width':'unset','display':'unset'}" inputStyleClass="form-control"
                                    [maxDate]="endDate" [monthNavigator]="true" [showIcon]="true" name="startDate"
                                    [(ngModel)]="startDate" yearNavigator="true" yearRange="2000:2100"></p-calendar>

                                <label *ngIf="isCompleted">
                                    To
                                </label>
                                <p-calendar [required]="true" class="ps-pix-5 pe-pix-10" [dateFormat]="dateFormat"
                                    *ngIf="isCompleted" [readonlyInput]="true"
                                    (onSelect)="getAllOrdersBetweenDateAndCompletion()" appendTo="body"
                                    [minDate]="startDate" [inputStyle]="{'width':'unset','display':'unset'}"
                                    inputStyleClass="form-control" [monthNavigator]="true" [showIcon]="true"
                                    name="endDate" [(ngModel)]="endDate" yearNavigator="true" yearRange="2000:2100">
                                </p-calendar>
                                <div class="col-200 inline-block pe-pix-5 mt-pix-2">
                                    <ng-select [items]="onlineOrderType" placeholder="Type" [(ngModel)]="selectedType"
                                        appendTo="body" name="type" bindLabel="Name" bindValue="Value"
                                        (change)="getOnlineOrderByType()">
                                    </ng-select>
                                </div>
                                <span>
                                    <p-checkbox (onChange)="getAllOrdersBetweenDateAndCompletion()" class="mb-pix-10"
                                    name="completed" #Active="ngModel" [(ngModel)]="isCompleted" binary="true" label="Completed" />
                                </span>
                            </div>
                        </div>

                    </div>
                    <div class="table-responsive">
                        <ng-table [columns]="onlineOrderColumns" [data]="onlineOrderFiltered">
                        </ng-table>

                        <ng-template let-data="rowData" #activeTemplate>
                            <span *ngIf="data.IsActive" class="green">
                                <pos-icon [icon]="icons.check"></pos-icon>
                            </span>
                        </ng-template>
                        <ng-template let-data="rowData" #operationHeaderTemplate>
                            <span (click)="printData()" tooltip title="Print">
                                <pos-action-icon [icon]="icons.printWhite" iconText="Print" class="ms-pix-5">
                                </pos-action-icon>
                            </span>
                        </ng-template>
                        <ng-template let-data="rowData" #nextProcess>
                            <div class="text-center align-vertical-center">
                                <div class="display-flex ps-pix-5 pt-pix-1 col-131 next-step pointer"
                                    (click)="editItem(data)" *ngIf="!isCompleted">
                                    <pos-icon [icon]="icons.chevronCircleRight" class="pe-pix-5"></pos-icon>
                                    {{data.NextStep}}
                                </div>
                                <pos-icon [icons]="[icons.squareGreenStack,icons.historyStack]" class="pointer"
                                    (click)="editItem(data)" *ngIf="isCompleted">
                                </pos-icon>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </pos-list-wrapper>
</div>
<pos-print-table [hidden]="true"></pos-print-table>
