import { Component, OnInit, ViewChild } from '@angular/core';
import { InfoLinkModel } from '../../interface';
import { ModalFormComponent, ModalService } from 'src/app/shared/components';
import { SpinnerService, AlertsService, FormUtilityService } from 'src/app/shared';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { InfoLinkService } from '../../service';
import { zip } from 'rxjs';
import { InfoLinkSectionsService, InfoLinkSectionsModel } from 'src/app/information-management/info-link-section';
import { map } from 'rxjs/operators';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'pos-info-links-edit',
  templateUrl: './info-links-edit.component.html',
})
export class InfoLinksEditComponent extends ModalFormComponent implements OnInit {

  get getForm(): NgForm {
    return this.form
  }

  @ViewChild('formInfoLinks', { static: true }) form: NgForm;
  infoLinkEditId: number;
  infoLinkDetail: InfoLinkModel;
  infoLinkSectionList: Array<InfoLinkSectionsModel> = [];
  props = {
    controlBoxClass: 'col-lg-9 col-md-9 col-sm-10 col-xs-12'
  }

  constructor(private spinnerService: SpinnerService,
    private alertService: AlertsService,
    infoLinkModalRef: BsModalRef,
    private infoLinkService: InfoLinkService,
    private infoLinkSectionsService: InfoLinkSectionsService,
    modalService: ModalService,
    formUtilityService: FormUtilityService
  ) {
    super(infoLinkModalRef, modalService, formUtilityService);

    this.infoLinkDetail = this.infoLinkService.newInfoLink();
  }

  ngOnInit() {
    this.getInfoLinkDetails(this.infoLinkEditId);
    this.setInitialValue();
  }

  getInfoLinkDetails(infolinkId: number) {
    if (infolinkId > 0) {
      this.spinnerService.show();
      zip(this.infoLinkSectionsService.getInfoLinkSections(),
        this.infoLinkService.getInfoLink(infolinkId)
      ).pipe(
        map(([infoLinkSectionListResponse, infoLinkDetailResponse]) => {
          this.infoLinkSectionList = infoLinkSectionListResponse;
          this.infoLinkDetail = infoLinkDetailResponse;
        })
      ).subscribe({
        next: (res) => {
        }, error: this.alertService.showApiError,
        complete: () => {
          this.spinnerService.hide();
        }
      });
    } else {
      this.form.resetForm();
      this.infoLinkDetail = this.infoLinkService.newInfoLink();
      this.infoLinkSectionsService.getInfoLinkSections().subscribe(response => {
        this.infoLinkSectionList = response;
      },
        (error) => { this.alertService.showApiError(error); },
        () => {
          this.spinnerService.hide();
        });
    }
  }

  saveInfoLinks(isValid) {
    if (!isValid) {
      return;
    }

    this.spinnerService.show();
    if (this.infoLinkEditId > 0) {
      this.infoLinkService.updateInfoLink(this.infoLinkEditId, this.infoLinkDetail).subscribe(response => {
        this.resetForm();
        this.hide({ shouldReload: true });
      },
        (error) => { this.alertService.showApiError(error); },
        () => {
          this.spinnerService.hide();
        });
    } else {
      this.infoLinkService.addInfoLink(this.infoLinkDetail).subscribe(response => {
        this.resetForm();
        this.hide({ shouldReload: true });
      },
        (error) => { this.alertService.showApiError(error); },
        () => {
          this.spinnerService.hide();
        });
    }
  }

  resetForm() {
    this.infoLinkEditId = 0;
  }

  cancelEditing() {
    this.hide({ shouldReload: false });
  }

}
