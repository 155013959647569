import { Directive, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[conditionallyValidate][ngModel]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => ConditionallyValidateDirective),
    multi: true
  }]
})
export class ConditionallyValidateDirective implements Validator, OnInit, OnChanges {
  @Input() conditionallyValidate: boolean;
  private condition: boolean;
  ngOnInit(): void {
    this.condition = this.conditionallyValidate;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['conditionallyValidate']) {
      this.condition = changes['conditionallyValidate'].currentValue;
      if (this.onChange) this.onChange();
    }
  }
  private onChange: () => void;

  validate(control: AbstractControl): ValidationErrors {
    return this.condition ? null : {condition: {condition: false}};
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onChange = fn;
  }
}
