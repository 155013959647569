import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RuntimeConstants } from 'src/app/shared';
import { DashboardDataSourceModel } from '../interfaces/dashboard-data-source-model';
import { DashboardDatasourceParameterModel } from '../interfaces/dashboard-datasource-parameter-model';
import { DashboardWidgetDatasourceParameterModel } from '../interfaces/dashboard-widget-datasource-parameter-model';
import { SaveDashboardWidgetDatasourceParameterModel } from '../interfaces/save-dashboard-widget-datasource-parameter-model';
import { Widget } from '../interfaces/widget';
import { textWidget, iframWidget, chartWidget, gaugeWidget, tableWidget } from 'src/app/shared/components/icon';

@Injectable({
  providedIn: 'root'
})
export class WidgetService {

  widgetApiPath = `${RuntimeConstants.API_PATH}DashboardWidgets`;

  constructor(private httpClient: HttpClient) { }

  widgetTypes = {
    'iframe': {
      reload: true,
      frameless: false,
      editReload: true,
      editImmediate: true,
      config: {
        height: '420px'
      },
      Icon: iframWidget
    },
    'text_widget': {
      reload: true,
      frameless: false,
      editReload: true,
      editImmediate: true,
      Icon: textWidget
    },
    'chart_widget': {
      reload: true,
      frameless: false,
      editReload: true,
      editImmediate: true,
      Icon: chartWidget
    },
    'gauge_widget': {
      reload: true,
      frameless: false,
      editReload: true,
      editImmediate: true,
      Icon: gaugeWidget
    },
    'table_widget': {
      reload: true,
      frameless: false,
      editReload: true,
      editImmediate: true,
      Icon: tableWidget
    }
  };

  getWidgetDetails = (widgetKey: string): Widget => {
    return this.widgetTypes[widgetKey];
  }

  getDashboardDatasources(datasourceType = ''): Observable<Array<DashboardDataSourceModel>> {
    let url = `${this.widgetApiPath}/GetDashboardDatasources/`;
    if (datasourceType) {
      url += datasourceType;
    }
    return this.httpClient.get<Array<DashboardDataSourceModel>>(url);
  }

  getDashboardDatasourceParameters(datasourceId: number): Observable<Array<DashboardDatasourceParameterModel>> {
    return this.httpClient.get<Array<DashboardDatasourceParameterModel>>(`${this.widgetApiPath}GetDashboardDatasourceParameters/${datasourceId}`);
  }

  getDashboardWidgetDatasourceParameters(dashboardWidgetAssignmentId: number, datasourceId: number):
    Observable<Array<DashboardWidgetDatasourceParameterModel>> {
    // tslint:disable-next-line: max-line-length
    return this.httpClient.get<Array<DashboardWidgetDatasourceParameterModel>>(`${this.widgetApiPath}/GetDashboardWidgetDatasourceParameters/${dashboardWidgetAssignmentId}/${datasourceId}`);
  }

  saveDashboardWidgetDatasourceParameters(model: SaveDashboardWidgetDatasourceParameterModel):
    Observable<SaveDashboardWidgetDatasourceParameterModel> {
    return this.httpClient.post<SaveDashboardWidgetDatasourceParameterModel>
      (`${this.widgetApiPath}/SaveDashboardWidgetDatasourceParameters`, model);
  }
}
