import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { GridColumn } from '@tarktech/tark-ng-utils';
import { Subscription } from 'rxjs/internal/Subscription';
import { AudioRecorderService, AudioRecordingsPayload, DomainConstants, RuntimeConstants } from 'src/app/shared';
import { ModalService, SpinnerService } from 'src/app/shared/components';
import { sync, times, fileAudio, playGreen, downloadWhite } from 'src/app/shared/components/icon';
import { AlertsService, ApplicationStateService, AudioOperationsService, DeviceMappingService, HardwareService, RabbitMQMessage, RabbitMQService } from 'src/app/shared/services';
import { AudioRecording } from '../../interfaces/audio-recording';
import { forEach } from 'lodash';
import { AudioComponent } from 'src/app/shared/components/audio/audio.component';
import { finalize, forkJoin, Observable } from 'rxjs';
import { TerminalHardwareDevice } from 'src/app/configurator';
import { HardwareDevice } from 'src/app/information-management';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pos-audio-recording-list',
  templateUrl: './audio-recording-list.component.html',
  styleUrls: ['./audio-recording-list.component.scss']
})
export class AudioRecordingListComponent implements OnInit, OnDestroy {

  terminalAudioRecordingsSubscription: Subscription;
  terminalAudioRecordFileSubscription: Subscription;
  recordings: Array<AudioRecording> = [];
  screenHeight = (window.innerHeight - 245) + 'px';
  public icons = {
    sync,
    times,
    fileAudio,
    playGreen,
    downloadWhite
  };
  audioRecordingColumns: Array<GridColumn> = [];
  tagName: string;
  isDownloadFile = false;
  rabbitMQRequestId = null;
  audioRecorderHardwareType = DomainConstants.HardwareTypes.AudioRecorder;
  audioRecorders: Array<HardwareDevice> = [];
  mappedAudioRecorder = null;
  audioFilePath = `${RuntimeConstants.MEDIA_BASE_PATH}/captured-audio`;

  @ViewChild('operationTemplate', { static: true }) operationTemplate: TemplateRef<any>;
  deleteAudioTimeout: ReturnType<typeof setTimeout>;
  constructor(private spinnerService: SpinnerService,
    private router: Router,
    private rabbitMQService: RabbitMQService,
    private modalService: ModalService,
    private audioOperationsService: AudioOperationsService,
    private audioRecorderService: AudioRecorderService,
    private deviceMappingService: DeviceMappingService,
    private hardwareService: HardwareService,
    private alertsService: AlertsService,
    private applicationStateService: ApplicationStateService) { }

  ngOnInit(): void {
    this.audioRecordingColumns = this.audioOperationsService.prepareAudioRecordingColumns(this.operationTemplate);
    if (this.applicationStateService.settingParam.EnableAudioRecording) {
      this.subscribeRabbitMQForAudioOperations();
      this.loadAudioRecordings();
    }
  }

  ngOnDestroy(): void {
    this.terminalAudioRecordFileSubscription.unsubscribe();
    this.terminalAudioRecordingsSubscription.unsubscribe();
    clearTimeout(this.deleteAudioTimeout);
  }

  loadAudioRecordings() {
    this.spinnerService.show();
    const observable: Array<Observable<any>> = [];
    observable.push(this.deviceMappingService.getHardwareTerminalMappingByTerminalId(this.applicationStateService.terminalId, this.audioRecorderHardwareType));
    observable.push(this.hardwareService.getHardwaresOfType(this.audioRecorderHardwareType));
    forkJoin(observable)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: ([mapping, audioRecorders]: [TerminalHardwareDevice, Array<HardwareDevice>]) => {
          if (mapping) {
            this.mappedAudioRecorder = mapping.DeviceId;
            this.getAudioRecordings();
          }
          this.audioRecorders = audioRecorders;
        },
        error: this.alertsService.showApiError
      });
  }

  getAudioRecordings() {
    this.recordings = [];
    if (this.mappedAudioRecorder) {
      this.audioOperationsService.getAudioRecordingsByHardware(this.mappedAudioRecorder, () => {});
    }
  }

  downloadAudio = (data: AudioRecording) => {
    console.log(`clicked download audio  for ${this.tagName} at ${new Date(Date.now())}`);
    this.isDownloadFile = true;
    this.tagName = data.Name;
    this.rabbitMQRequestId = this.rabbitMQService.guidWithTimeStamp();
    this.audioOperationsService.getAudioBytes(data, this.rabbitMQRequestId, () => {
      this.rabbitMQRequestId = null;
    });
  }

  subscribeRabbitMQForAudioOperations = () => {
    this.terminalAudioRecordingsSubscription = this.rabbitMQService.subscribeToTerminalAudioRecordings$(this.applicationStateService.terminalId)
      .subscribe({
        next: (message: RabbitMQMessage<AudioRecordingsPayload>) => {
          this.spinnerService.hide();
          const recordings = [];
          forEach(message.Payload.AudioRecords, (audio) => {
            recordings.push({ Name: audio });
          });
          this.recordings = recordings;
          this.recordings.sort((a, b) => a.Name.localeCompare(b.Name));
          clearTimeout(this.audioOperationsService.loaderTimeout);
        }, error: () => {
          console.log('error while connecting to RabbitMQ.');
        }
      });

    this.terminalAudioRecordFileSubscription = this.rabbitMQService.subscribeToTerminalAudioFile$(this.applicationStateService.terminalId)
      .subscribe({
        next: (message: any) => {
          console.log(`AudioFile received for ${this.tagName} at ${new Date(Date.now())}`);
          clearTimeout(this.audioOperationsService.loaderTimeout);
          
          if (message.Payload.RabbitMQRequestId == this.rabbitMQRequestId) {
            this.spinnerService.hide();
            const fileUrl: string = `${this.audioFilePath}/${message.Payload.FileName}`;

            if (this.isDownloadFile) {
              this.isDownloadFile = false;
              this.audioRecorderService.download(fileUrl, this.tagName);
            } else {
              this.playAudio(fileUrl);
            }
            
            this.rabbitMQRequestId = null;
          }
        }, error: () => {
          console.log('error while connecting to RabbitMQ.');
        }
      });
  }

  sendPlayAudioRequest(data: AudioRecording) {
    this.tagName = data.Name;
    this.rabbitMQRequestId = this.rabbitMQService.guidWithTimeStamp();
    console.log(`clicked play audio  for ${this.tagName} at` + new Date(Date.now()));
    this.audioOperationsService.getAudioBytes(data, this.rabbitMQRequestId, () => {
      this.rabbitMQRequestId = null;
    });
  }

  playAudio(fileUrl: string) {
    const modal = this.modalService.getModalWrapper(AudioComponent);
    modal.show({
      animated: false,
      class: 'vertical-center fit-content',
      'backdrop': 'static',
      initialState: {
        audioUrl: fileUrl,
        fileName: this.tagName
      }
    });
  }

  close() {
    this.router.navigate(['manage/console']);
  }

}

