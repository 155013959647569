<pos-edit-wrapper (close)="cancelEditing()" heading="{{id ? 'Edit Tag' : 'Add Tag'}}">
    <form #tagForm="ngForm" (submit)="submit(tagForm.valid)" autocomplete="off">
        <div class="col-12 pos-modal-body form-horizontal screen-scroll">
            <pos-row>
                <pos-column>
                    <pos-form-field label="Name" [validationMessages]="{required: 'Please enter tag name.'}">
                        <input posInput type="text" name="name" class="form-control" [(ngModel)]="workScheduleTag.Name"
                            [required]="true" #name="ngModel" />
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Color">
                        <pos-color-picker posInput [(ngModel)]="workScheduleTag.Color" name="color">
                        </pos-color-picker>
                    </pos-form-field>
                </pos-column>
            </pos-row>


        </div>
        <div class="clearfix"></div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" value="Save">Save </button>
            <button type="button" data-dismiss="modal" class="btn-secondary btn-lg btn btn-cancel-modal pos-button"
                (click)="cancelEditing()" value="Cancel">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
