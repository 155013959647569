import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class BaseMenuSearchService {
    search(menuList, filterContext) {
        const filteredMenu = cloneDeep(menuList.filter((data) => {
            return (data.GroupName.toLocaleLowerCase().includes(filterContext.toLocaleLowerCase())
                || (data.List.filter((x) => {
                    return x.Name.toLocaleLowerCase().includes(filterContext.toLocaleLowerCase());
                })).length);
        }));

        filteredMenu.forEach((data) => {
            if (!data.GroupName.toLocaleLowerCase().includes(filterContext.toLocaleLowerCase())) {
                data.List = menuList.find(x => x.GroupName === data.GroupName).List.filter((x) => {
                    return x.Name.toLocaleLowerCase().includes(filterContext.toLocaleLowerCase());
                });
            }
        });
        return filteredMenu;
    }
}
