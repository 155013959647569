import { Injectable } from '@angular/core';
import { isEqual } from 'lodash';
import { ModalService } from '../components/modal/modal.service';
import { UnsavedFormDataComponent } from '../components/unsaved-form-data-modal/unsaved-form-data.component';
import { ApplicationStateService } from './application-state.service';

@Injectable({
    providedIn: 'root'
})
export class FormUtilityService {
    formValueChangeSubscription;
    constructor(private modalService: ModalService,
        private applicationStateService: ApplicationStateService) { }

    openConfirmModal() {
        return this.modalService.show(UnsavedFormDataComponent ,{
            animated: false,
            class: 'vertical-center',
            'backdrop': 'static'
        });
    }

    /**
     * hide
     */
    hasUnsavedChanges = (form, initialValues) => {
        return this.applicationStateService.settingParam.EnableFormDirtyBitsWarning && !isEqual(form.value, initialValues);
    }

}
