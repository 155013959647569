import { Messages } from 'src/app/shared/constants/ui-messages';
import { AudioNotificationService } from '../../../../shared/services/audio-notification-file.service';
import { AlertsService, FormUtilityService } from 'src/app/shared/services';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalFormComponent, ModalService } from 'src/app/shared/components';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SpinnerService } from 'src/app/shared';
import { ExtensionValidationService } from 'src/app/shared/services/extension-validation.service';
declare let $: any;
@Component({
  selector: 'pos-audio-notification-edit',
  templateUrl: './audio-notification-edit.component.html',
})
export class AudioNotificationEditComponent extends ModalFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.form;
  }

  @ViewChild('audioNotifierForm', {static: true}) form: NgForm;
  isChooseFile: boolean = false;
  audioNotifierFile: File;

  audioNotifierId: number;
  audioNotifierName: string;
  audioNotificationFile: any = {};
  props = {
    labelClass: '',
    controlBoxClass: 'col-md-10 col-xs-9'
  }
  constructor(audioNotifierModalRef: BsModalRef,
    modalService: ModalService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private extensionValidation: ExtensionValidationService,
    private audioNotificationService: AudioNotificationService,
    formUtilityService: FormUtilityService
    ) {
    super(audioNotifierModalRef, modalService, formUtilityService);
  }

  ngOnInit() {
    this.getAudioNotifierDetails(this.audioNotifierId);
    this.setInitialValue();
  }

  getAudioNotifierDetails(id: number) {
    if (id > 0) {
      this.spinnerService.show();
      this.audioNotificationService.getAudioNotificationFile(id).subscribe(
        response => {
          this.audioNotificationFile = response;
        },
        this.alertService.showApiError,
        () => {
          this.spinnerService.hide();
        }
      );
    } else {
      this.audioNotificationFile = this.audioNotificationService.newAudioNotifier();
    }
  }

  saveAudioNotificationFile(isValid) {
    if (!isValid || !this.audioNotificationFile.Name || !this.audioNotificationFile.FileName) {
      return;
    }
    this.spinnerService.show();

    if (this.audioNotifierId > 0) {
      this.audioNotificationService.updateAudioNotificationFile(this.audioNotificationFile, this.audioNotifierFile).subscribe(
        () => {
          this.alertService.renderSuccessMessage(Messages.AudioNotificationFileSaveSuccess);
          this.onCancel(true);
        },
        this.alertService.showApiError,
        () => {
          this.spinnerService.hide();
        }
      );
    } else {
      this.audioNotificationService.saveAudioNotificationFile(this.audioNotificationFile, this.audioNotifierFile).subscribe(
        (response) => {
          this.alertService.renderSuccessMessage(Messages.AudioNotificationFileSaveSuccess);
          this.onCancel(true, response.Id);
        },
        this.alertService.showApiError,
        () => {
          this.spinnerService.hide();
        }
      );
    }
  }

  onCancel(reload: boolean, audioNotificationFileId: number = 0) {
    this.hide({ shouldReload: reload, Id: audioNotificationFileId });
  }

  handleFileInput(files: FileList) {
    this.isChooseFile = true;
    this.audioNotifierFile = files.item(0);
    const fileToUpload = this.audioNotifierFile ? this.audioNotifierFile.name : '';
    if (this.extensionValidation.isInvalidExtension(fileToUpload, ['.wav', '.mp3'])) {
      if (fileToUpload) {
        this.alertService.renderErrorMessage(Messages.InvalidAudioNotificationFileType);
      }
      $("#audioNotifierFile").val('');
      this.audioNotificationFile.FileName = '';
      return;
    }

    if (files && files[0]) {
      this.audioNotificationFile.FileName = this.audioNotifierFile.name;
    }
  }
}
