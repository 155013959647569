import { Component, Input } from '@angular/core';
import { KioskHeader } from '../../interfaces';
import { RuntimeConstants } from 'src/app/shared';

@Component({
  selector: 'pos-kiosk-header',
  templateUrl: './kiosk-header.component.html',
  styleUrls: ['./kiosk-header.component.scss']
})
export class KioskHeaderComponent {
  @Input() header: KioskHeader;
  imageRootPath = `${RuntimeConstants.IMAGE_BASE_PATH}/order-kiosk/`;
}
