import { Component, OnInit, Input } from '@angular/core';
import { FormUtilityService, ModalFormComponent, ModalService } from 'src/app/shared';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'pos-sales-group-edit-model',
    templateUrl: './sales-group-edit-model.component.html',
    styleUrls: ['./sales-group-edit-model.component.scss']
})
export class SalesGroupEditModelComponent extends ModalFormComponent implements OnInit {
    form: NgForm;
    @Input('salesGroupId') salesGroupId: number = null;
    initialize = false;
    @Input('fromListPage') fromListPage = false;
    get getForm(): NgForm {
        return this.form
    }
    constructor(salesGroupModalRef: BsModalRef, modalService: ModalService, formUtilityService: FormUtilityService) {
        super(salesGroupModalRef, modalService, formUtilityService);
        this.initialize = false;
        
    }

    ngOnInit() {
        this.initialize = true;       
    }

    onClose($event: any) {
        this.hide($event);
    }

    onFormChange($event: NgForm) {
        this.form = $event;
        this.setInitialValue();
    }
}
