import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ProductLossReason } from '../../interface';
import { ProductLossReasonService } from '../../service';
import { AlertsService, SpinnerService, ModalFormComponent, ModalService, FormUtilityService } from 'src/app/shared';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Messages } from 'src/app/shared/constants/ui-messages';

@Component({
  selector: 'pos-product-loss-reason-edit',
  templateUrl: './product-loss-reason-edit.component.html',
  styleUrls: ['./product-loss-reason-edit.component.scss']
})
export class ProductLossReasonEditComponent extends ModalFormComponent implements OnInit {

  @ViewChild('productLossReasonForm') productLossReasonForm: NgForm;
  public productLossReasonData: ProductLossReason;
  productId: number = 0;

  get getForm(): NgForm {
    return this.productLossReasonForm;
  }
  constructor(
     modalRef: BsModalRef,
    private productLossService: ProductLossReasonService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    modalService: ModalService,
    formUtilityService: FormUtilityService
  ) {
    super(modalRef, modalService, formUtilityService);
    this.productLossReasonData=this.productLossService.newProductLossReason();

  }

  ngOnInit(): void {

    this.getProductLossReasonDetails(this.productId);
    this.setInitialValue();

  }

  getProductLossReasonDetails(id: number) {

    if (id > 0) {
      this.spinnerService.show();
      this.productLossService.getProductLossResasonById(id).subscribe(
        response => {
          this.productLossReasonData = response;
        },
        this.alertService.showApiError,
        () => {
          this.spinnerService.hide();
        }
      );
    } else {
      this.productLossReasonData=this.productLossService.newProductLossReason();
    }
  }

  saveProductLossReason(isValid: boolean) {
    if (!isValid) {
      return;
    }

    this.spinnerService.show();
    

    if (this.productId > 0) {
      this.productLossService.updateProductLossResason(this.productLossReasonData).subscribe(
        () => {
          this.alertService.renderSuccessMessage(Messages.ProductLossReasonUpdate);
          this.onCancel(true);
        },
        this.alertService.showApiError,
        () => {
          this.spinnerService.hide();
        }
      );
    } else {
      this.productLossService.addProductLossResason(this.productLossReasonData).subscribe(
        () => {
          this.alertService.renderSuccessMessage(Messages.ProductLossReasonSave);
          this.onCancel(true);
        },
        this.alertService.showApiError,
        () => {
          this.spinnerService.hide();
        }
      );
    }
  }

  onCancel(reload: boolean) {
    this.hide({ shouldReload: reload });
  }

  
}
