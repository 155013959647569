import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ceil, find, forEach, orderBy, remove } from 'lodash';
import { DomainConstants, RuntimeConstants } from 'src/app/shared/constants';
import { ListWidgetConfig, ListWidgetStyleConfig, MenuDisplayItem } from '../../interfaces';
import { MenuWidgetService } from '../../services';
import { MenuItemsSortService } from '../../services/menu-items-sort.service';
import { MenuWidgetStyleService } from '../../services/menu-widget-style.service';

@Component({
  selector: 'pos-menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls: ['./menu-list.component.scss']
})
export class MenuListComponent implements OnInit, OnChanges {

  @Input() listWidgetConfig: ListWidgetConfig = null;
  @Input() listWidgetStyleConfig: ListWidgetStyleConfig = null;
  @Input() menuListItems: Array<MenuDisplayItem> = [];
  imagePath = RuntimeConstants.IMAGE_BASE_PATH + '/menu-display/sales-products';
  tagIconPath = RuntimeConstants.IMAGE_BASE_PATH + '/tags';
  displayListStyle: string = null;
  outOfStockBehaviorTypes = DomainConstants.OutOfStockBehaviorTypes;
  preparedMenuDisplayItems: Array<Array<Array<MenuDisplayItem>>> = [];
  tagIconSize = 14;
  productImageSize = 30;
  bulletTypes = DomainConstants.BulletTypes;
  separatorLoopCount = 0;
  lastItemIndex = 0;

  salesSizes: number[] = [];

  constructor(private menuWidgetService: MenuWidgetService,
    private menuWidgetStyleService: MenuWidgetStyleService,
    private menuItemSortService: MenuItemsSortService) {
    this.listWidgetStyleConfig = this.menuWidgetService.getNewListWidgetStyle();
  }

  ngOnInit(): void {

  }

  ngOnChanges() {
    this.prepareMenuListItems();
    this.prepareStyleConfig();
  }

  sortPrices() {
    forEach(this.menuListItems, item => {
      item.Prices = orderBy(item.Prices, x => x.Price);

      // logic to size for tabled prices
      if(this.listWidgetStyleConfig?.PriceTableConfig?.ShowPriceAsTable) {
        item.SizePrice = this.salesSizes.map(size => {
          const price = find(item.Prices, (price => price.SizeId == size))
          return price;
        });
      }
    });
  }

  prepareMenuListItems = () => {
    this.salesSizes = this.listWidgetStyleConfig?.PriceTableConfig?.SalesSizes ?? [];
    this.sortPrices();
    const priorityList = this.listWidgetConfig?.ProductDisplayOrdinal?.find(pdo => pdo.GroupId == null);
    this.menuListItems = this.menuItemSortService.sortItems(this.menuListItems, priorityList?.Ordinal ?? []);
    this.createMenuItemsIndex();
    this.preparedMenuDisplayItems = this.generateMenuListItems([...this.menuListItems]);
  }

  createMenuItemsIndex = () => {
    if (this.listWidgetStyleConfig?.DisplayListStyle?.toLowerCase() === DomainConstants.BulletTypes.Numeric) {
      let index = 1;
      forEach(this.menuListItems, (item) => {
        if (this.listWidgetConfig?.OutOfStockBehavior?.BehaviorType === DomainConstants.OutOfStockBehaviorTypes.Hide) {
          item.Index = item?.IsInStock ? index++ : index;
        } else {
          item.Index = index++;
        }
      });
    }
  }

  generateMenuListItems(menuListItems: Array<MenuDisplayItem>): Array<Array<Array<MenuDisplayItem>>> {
    if (!menuListItems.length) {
      return [];
    }
    const menuItems = [];
    let itemsCount = 0, noOfItems = 0;
    if (this.listWidgetConfig?.OutOfStockBehavior?.BehaviorType === DomainConstants.OutOfStockBehaviorTypes.Hide) {
      remove(menuListItems, item => !item?.IsInStock);
    }
    this.separatorLoopCount = 0;
    this.lastItemIndex = 0;
    const priorityItemList = this.removePriorityListItems(menuListItems);

    while (itemsCount < menuListItems.length) {
      let menuSubItems = [];
      noOfItems = this.calculateNoOfItems(menuListItems);
      let subItems = menuListItems.slice(itemsCount, itemsCount += noOfItems);
      if (!menuItems.length && subItems.length) {
        subItems = [...priorityItemList, ...subItems];
        noOfItems = noOfItems + priorityItemList.length;
      }
      menuSubItems = this.generateMenuListSubItems(subItems, noOfItems);
      if (menuSubItems.length) {
        menuItems.push(menuSubItems);
      }
    }
    return menuItems;
  }

  calculateNoOfItems(menuListItems: Array<MenuDisplayItem>) {
    let noOfItems = 0;
    if (this.listWidgetConfig?.Separator && !Number(this.listWidgetConfig.Separator)) {
      const separators = this.listWidgetConfig.Separator.split(',').map(x => x.toLowerCase().trim());
      const itemNames = menuListItems.map(x => x.Text.toLowerCase());
      if (this.separatorLoopCount < separators.length) {
        const itemIndex = itemNames.indexOf(itemNames.find(x => x.startsWith(separators[this.separatorLoopCount])));
        if (itemIndex !== -1) {
          noOfItems = itemNames.slice(this.lastItemIndex, itemIndex).length;
          this.lastItemIndex = itemIndex;
        }
        this.separatorLoopCount++;
      } else {
        noOfItems = itemNames.slice(this.lastItemIndex).length;
      }
    } else {
      noOfItems = this.listWidgetConfig?.Separator ? parseInt(this.listWidgetConfig.Separator, 10) : menuListItems.length;
    }
    return noOfItems;
  }

  removePriorityListItems(menuListItems: Array<MenuDisplayItem>) {
    const priorityList = this.listWidgetConfig?.ProductDisplayOrdinal?.find(pdo => pdo.GroupId == null)?.Ordinal ?? [];
    const priorityItemList: Array<MenuDisplayItem> = [];
    for (const _element of priorityList) {
      if (menuListItems?.length && priorityList.includes(menuListItems[0]?.Id)) {
        const item = menuListItems.splice(0, 1)[0];
        priorityItemList.push(item);
      }
    }
    if (!menuListItems.length) {
      const item = priorityItemList.splice(priorityItemList.length - 1, 1)[0];
      menuListItems.push(item);
    }
    return priorityItemList;
  }

  generateMenuListSubItems(subItems, noOfItems) {
    const menuSubItems = [];
    let subItemsCount = 0;
    let columns = this.listWidgetConfig?.NoOfColumns ?? 1;
    let itemsPerColumn = ceil(subItems.length / (this.listWidgetConfig?.NoOfColumns ?? 1));

    while (subItemsCount < noOfItems) {
      const slicedSubItems = subItems.slice(subItemsCount, subItemsCount += itemsPerColumn);
      if (slicedSubItems.length) {
        menuSubItems.push(slicedSubItems);
      }
      itemsPerColumn = ceil((subItems.length - subItemsCount) / --columns);
    }
    return menuSubItems;
  }

  prepareStyleConfig = () => {
    let fontSize = 0;
    if (this.listWidgetStyleConfig?.ItemFontStyle?.fontSize) {
      fontSize = parseInt((this.listWidgetStyleConfig?.ItemFontStyle?.fontSize)?.replace('px', ''), 10);
    }
    this.tagIconSize = fontSize ? fontSize : this.tagIconSize;
    this.productImageSize = fontSize ? fontSize * 1.5 : this.productImageSize;

    if (this.listWidgetConfig?.OutOfStockBehavior?.TextStyleConfig) {
      this.listWidgetConfig.OutOfStockBehavior.TextStyleConfig.verticalAlign = this.listWidgetConfig?.OutOfStockBehavior?.TextStyleConfig?.verticalAlign??'middle';
    }
    if (this.listWidgetStyleConfig?.DescriptionStyle) {
      this.listWidgetStyleConfig.DescriptionStyle.lineHeight =
        (this.listWidgetStyleConfig?.DescriptionStyle?.fontSize ? this.listWidgetStyleConfig?.DescriptionStyle?.fontSize : '');
    }

    this.displayListStyle = this.listWidgetStyleConfig?.DisplayListStyle?.toLowerCase() !== DomainConstants.BulletTypes.Numeric ?
      this.menuWidgetStyleService.getBulletStyle(this.listWidgetStyleConfig?.DisplayListStyle,
        this.listWidgetStyleConfig?.ItemFontStyle?.fontSize)
      : this.menuWidgetStyleService.getLineHeight(this.listWidgetStyleConfig?.ItemFontStyle?.fontSize);

    this.listWidgetStyleConfig.TagConfig = this.listWidgetStyleConfig.TagConfig ?? this.menuWidgetService.getNewTagConfig();
  }
}
