<div>
    <div>
        <h4 class="modal-content__heading">Confirm Training Approval</h4>
    </div>
    <div class="pos-modal-body pt-3 pb-0">
        <span innerHTML="{{confirmMessage}}"></span>
    </div>
    <div class="col-12 action-button-container popup-footer pb-pix-10">
        <button class="btn btn-primary btn-lg btn-save pos-button" (click)="onConfirm()" type="button">Training
            Completed</button>
        <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="hideChildModal()"
            type="button">Cancel</button>
    </div>
    <div class="clearfix"></div>
</div>
