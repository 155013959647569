import { MessagePayload } from './message-payload';

export class OrderItemListPayload extends MessagePayload {

    public orderItemList: any;

    constructor(orderItemList: any) {
        super();
        this.orderItemList = orderItemList;
    }
}
