import { Component } from '@angular/core';
import { ImageInterceptorService, KioskNavigationBroadcastingService, KioskNavigationService, OrderKioskStorageService } from '../../services';
import { AlertsService, ApplicationStateService, OrderKioskScreenService, OrderService, SlideShowData, UserIdleService } from 'src/app/shared';
import { Subscription } from 'rxjs';
import { KioskUserIdleComponent } from '../kiosk-user-idle-component';
import { KioskScreen } from '../../interfaces';
import { Router } from '@angular/router';
import { MessageStripConfig } from '../../interfaces/styling/message-strip-style';
import { cloneDeep, find } from 'lodash';

@Component({
  selector: 'pos-order-kiosk-initial-screen',
  templateUrl: './order-kiosk-initial-screen.component.html',
  styleUrls: ['./order-kiosk-initial-screen.component.scss']
})
export class OrderKioskInitialScreenComponent {
  isSlideShow = true;
  userIdleTimeout = 30;
  userIdleSubscription: Subscription;
  slideShowData: Array<SlideShowData> = [];
  kioskScreen: KioskScreen;
  startOrderingDirectly = false;
  continueOrderScreenId: number;
  messageStripConfig: MessageStripConfig;
  constructor(private kioskNavigationService: KioskNavigationService,
    private orderKioskStorageService: OrderKioskStorageService,
    private userIdleService: UserIdleService,
    private orderService: OrderService,
    private kioskScreenService: OrderKioskScreenService,
    private alertsService: AlertsService,
    private router: Router,
    private imageInterceptorService: ImageInterceptorService,
    private applicationStateService: ApplicationStateService,
    private kioskNavigationBroadcastingService: KioskNavigationBroadcastingService
  ) {
    const currentNavigation = this.router.getCurrentNavigation();
    this.startOrderingDirectly = currentNavigation?.extras?.state?.startOrderingDirectly ?? false;
    this.continueOrderScreenId = currentNavigation?.extras?.state?.continueOrderScreenId ?? null;
    this.kioskScreen = this.kioskScreenService.newScreen();
    this.messageStripConfig = {
      FontColor: null,
      BackgroundColor: null,
      Text: null,
      FontSize: null
    }
  }

  ngOnInit() {
    if (this.applicationStateService.settingParam.OrderKioskTitleMessageConfig)
      this.messageStripConfig = JSON.parse(this.applicationStateService.settingParam.OrderKioskTitleMessageConfig);
    if (this.orderKioskStorageService.kioskScreens?.length) {
      this.changeSlideShowStatus(!this.kioskNavigationService.orderDetails);
      this.userIdleTimeout = this.kioskNavigationService.userIdleTimeout;
      this.slideShowData = this.orderKioskStorageService.slideShowData;
      const continueOrderScreen = find(this.orderKioskStorageService.kioskScreens, (x) => {
        return x.Id == this.continueOrderScreenId;
      });
      this.kioskScreen = continueOrderScreen ? cloneDeep(continueOrderScreen) : this.orderKioskStorageService.kioskScreen;
      this.kioskNavigationService.initializeNavigationService(this.orderKioskStorageService.kioskScreens ?? [],
        this.continueOrderScreenId ? this.continueOrderScreenId : this.orderKioskStorageService.homeScreenId
      );
      if (!this.startOrderingDirectly) {
        this.changeSlideShowStatus(true);
      } else {
        this.changeSlideShowStatus(false);
        this.subscribeToUserIdleSubscription();
      }
    } else {
      this.router.navigate(['order-kiosk/terminal']);
    }
  }


  onSlideShowTouch() {
    this.kioskNavigationService.homeScreenId = this.orderKioskStorageService.homeScreenId;
    this.kioskScreen = cloneDeep(this.orderKioskStorageService.kioskScreen);
    this.changeSlideShowStatus(false);
    this.kioskNavigationService.resetProps(false);
    this.subscribeToUserIdleSubscription();
  }

  subscribeToUserIdleSubscription() {
    if (this.userIdleSubscription) {
      this.userIdleSubscription.unsubscribe();
    }
    this.userIdleSubscription = this.userIdleService.initialize(this.userIdleTimeout, () => this.userGoneIdle());
  }

  userGoneIdle = () => {
    this.stopUserIdleService();
    const currentScreenId = this.kioskNavigationService.orderNavigation ? this.kioskNavigationService.orderNavigation.Id : this.kioskNavigationService.currentScreenConfig.ScreenId;
    if (currentScreenId == this.orderKioskStorageService.homeScreenId && !this.kioskNavigationService.orderDetails?.OrderItems.length) {
      this.changeSlideShowStatus(true);      
    } else {
      const callBack = (res) => {
        if (res?.continueOrder) {
          this.subscribeToUserIdleSubscription();
        } else {
          this.changeSlideShowStatus(true);
          this.deleteOrder();
        }
      }
      this.kioskNavigationService.openOverlayModal(KioskUserIdleComponent, callBack)
    }
  }

  deleteOrder() {
    if (this.kioskNavigationService.orderDetails?.Id?.Value) {
      this.orderService.deleteOrder(this.kioskNavigationService.orderDetails.Id.Value)
        .subscribe({
          next: () => {
            this.changeSlideShowStatus(true);
            this.kioskNavigationService.resetProps();
            this.kioskNavigationService.completeNavigation();
          }, error: this.alertsService.showApiError
        });
    } else {
      this.kioskNavigationService.resetProps();
      this.kioskNavigationService.completeNavigation();
    }
  }

  changeSlideShowStatus = (isShow: boolean) => {
    isShow ? this.imageInterceptorService.disconnectObserver() : this.imageInterceptorService.connectObserver();
    this.isSlideShow = isShow;
    if(isShow) {
      this.kioskNavigationBroadcastingService.changeKioskTerminalState(); 
    }
  }

  stopUserIdleService() {
    this.userIdleService.stop();
    this.userIdleSubscription?.unsubscribe();
  }

  ngOnDestroy() {
    this.userIdleService.stop();
    this.userIdleSubscription?.unsubscribe();
  }
}
