import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Messages, AlertsService, SpinnerService, FieldInfoMessages, BaseFormComponent, FormUtilityService } from 'src/app/shared';
import { finalize } from 'rxjs/operators';
import * as _ from 'lodash';
import { forkJoin } from 'rxjs';
import { Track } from '../../interface/track';
import { TracksService } from '../../services';
import { Options } from '@angular-slider/ngx-slider/options';
import { ActivatedRoute, Router } from '@angular/router';
import { music } from 'src/app/shared/components/icon/icons';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'pos-track-edit',
  templateUrl: './track-edit.component.html',
  styleUrls: ['./track-edit.component.scss']
})
export class TrackEditComponent extends BaseFormComponent implements OnInit {
  @ViewChild('formTrack') form: NgForm;
  get getForm(): NgForm {
    return this.form
  }
  track: Track;
  trackId = 0;
  isEditTrack = false;
  playlistId = 0;
  playlistName = null;
  fileName = '';
  extension = '';
  displayModes = {
    'Add': 'Add',
    'Edit': 'Edit'
  };
  displayMode = this.displayModes.Add;
  selectedFiles: FileList;
  fieldInfoMessages = FieldInfoMessages;
  options: Options = {
    floor: 1,
    ceil: 100,
    translate: (value: number): string => {
      return '';
    }
  };
  @ViewChild('fileInput') fileInput: ElementRef;
  tracks: Array<Track> = [];
  isUseDefaultVolume = false;
  icons = {
    music
  }
  props = {
    controlBoxClass: 'col-lg-3 col-md-5 col-sm-8 col-xs-12'
  }

  constructor(protected spinnerService: SpinnerService,
    protected alertService: AlertsService,
    protected router: Router,
    private route: ActivatedRoute,
    private tracksService: TracksService,
    formUtilityService: FormUtilityService) {
    super(formUtilityService);
    const navigation = router.getCurrentNavigation();
    this.trackId = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
    this.track = this.tracksService.newTrack();
    this.playlistId = route.snapshot.params.playlistId ? parseInt(route.snapshot.params.playlistId, 10) : 0;
    this.playlistName = (navigation?.extras?.state && navigation?.extras?.state.playlistName) ? navigation?.extras?.state.playlistName : '';
    this.isEditTrack = (navigation?.extras?.state && navigation?.extras?.state.isEditTrack) ? navigation?.extras?.state.isEditTrack : false;
  }

  ngOnInit() {
    this.loadData();
  }

  loadData(): void {
    if (this.trackId > 0) {
      this.spinnerService.show();
      this.tracksService.get(this.trackId)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res) => {
            this.track = res;
            const volume = this.track.Volume;
            this.extension = this.track.Filename.substring(this.track.Filename.lastIndexOf('.'));
            this.fileName = this.track.Filename.substring(19, this.track.Filename.lastIndexOf('.'));
            this.displayMode = this.displayModes.Edit;
            this.isUseDefaultVolume = !(this.track && this.track.Volume);
            this.onFormChange(this.form);
            setTimeout(() => {
              this.track.Volume = volume;
            }, 100);
          }, error: this.alertService.showApiError
        });
    } else {
      this.track = this.tracksService.newTrack();
    }
  }

  saveTrack(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.track.Filename = this.track.Filename.substring(0, 19) + this.fileName + this.extension;
    if (this.track.Id > 0) {// update track category
      this.spinnerService.show();
      this.tracksService.updateTrack(this.track)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: () => {
            this.alertService.renderSuccessMessage(Messages.TrackUpdated);
            this.resetTrack();
            this.redirectToTracks();
          }, error: this.alertService.showApiError
        });
    } else {
      if (this.selectedFiles) {
        this.spinnerService.show();
        const addObservables = [];
        this.track.Filename = this.fileName + this.extension;
        _.forEach(this.selectedFiles, (file) => {
          addObservables.push(this.tracksService.addTrack(this.track, file));
        });
        forkJoin(addObservables)
          .pipe(finalize(() => {
            this.spinnerService.hide();
            this.resetTrack();
          }))
          .subscribe({
            next: (response: Array<Track>) => {
              this.tracks = response;
              this.tracks.forEach(track => track.IsChecked = true);
              this.alertService.renderSuccessMessage(Messages.TrackAdded);
              this.redirectToTracks();
            }, error: this.alertService.showApiError
          });
      } else {
        this.alertService.renderErrorMessage(Messages.SelectAtLeastOneFile);
      }
    }
  }

  filesSelected(files) {
    this.selectedFiles = files;
    this.fileName = this.selectedFiles[0].name.substring(0, this.selectedFiles[0].name.lastIndexOf('.'));
    this.extension = this.selectedFiles[0].name.substring(this.selectedFiles[0].name.lastIndexOf('.'));
  }

  resetTrack() {
    if (this.track.Id === 0) {
      this.fileInput.nativeElement.value = null;
    }
    this.displayMode = this.displayModes.Add;
    this.selectedFiles = null;
    this.track = this.tracksService.newTrack();
  }

  redirectToTracks() {
    if (this.playlistId) {
      this.router.navigate(['playlist', this.playlistId, 'tracks'], {
        state: {
          isEditTrack: this.isEditTrack, playlistName:
            this.playlistName, tracks: this.tracks
        }, relativeTo: this.route.parent
      });
    } else {
      this.router.navigate(['tracks'], { relativeTo: this.route.parent });
    }
  }

  setDefaultVolume() {
    if (this.isUseDefaultVolume) {
      this.track.Volume = 0;
    }
  }

  onFormChange($event: NgForm) {
    this.form = $event;
    this.setInitialValue();
  }

  cancel() {
    this.router.navigate(['tracks'], { relativeTo: this.route.parent });
  }
}
