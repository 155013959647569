import { Component, OnInit, ViewChild, Input, ElementRef, Renderer2 } from '@angular/core';
import { RuntimeConstants } from '../../constants';
import { fieldInfoCircle } from '../icon';

declare let $: any;

@Component({
  selector: 'field-info',
  templateUrl: './field-info.component.html',
  styleUrls: ['./field-info.component.scss']
})
export class FieldInfoComponent implements OnInit {

  @ViewChild("popoverTooltip") popoverTooltip: ElementRef;
  @Input() info: string = '';
  @Input() image: string = '';
  @Input() seconds: number = 4;
  @Input() placement: string = 'top';
  @Input('styleClass') styleClass: string = '';
  infoTimeout: any;
  screenWidth: any;
  imageTemplate: string = '';
  public icons = {
    fieldInfoCircle
  };
  constructor(private renderer: Renderer2) {
    this.seconds = this.seconds ? this.seconds : 4;
    this.placement = this.placement ? this.placement : 'top';
  }

  ngOnInit() {
    if(this.image) {
      this.imageTemplate = `
        <div>
          <img src='${RuntimeConstants.IMAGE_BASE_PATH}/info-images/${this.image}' style='height: 100%; width: 100%; object-fit: contain'/>
        </div>
        <span>${this.info}</span>
      `
    }
  }

  onClickIcon(event) {
    event.preventDefault();
  }

  onClickFieldInfoIcon(event) {
    this.handleMouseOver(event);
  }

  handleMouseOver(event) {
    this.popoverTooltip.nativeElement.focus();
    this.infoTimeout = setTimeout(() => {
      $('.ui-tooltip').hide();
      if (this.infoTimeout) {
        clearTimeout(this.infoTimeout);
      }
    }, this.seconds * 1000);
  }

  handleMouseOut(event) {
    this.popoverTooltip.nativeElement.blur();
    if (this.infoTimeout) {
      clearTimeout(this.infoTimeout);
    }
  }

}


