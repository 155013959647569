import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { forEach } from 'lodash';
import { MenuItem } from 'primeng/api';
import { finalize } from 'rxjs/operators';
import { EndOfDayService } from 'src/app/configurator/end-of-day/services/end-of-day.service';
import { AlertsService, ApplicationStateService, ICloseable, Messages, OrderService, SettingParam, SpinnerService } from 'src/app/shared';
import { CashDrawerHistory } from '../interfaces/cash-drawer-history';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
declare let $: any;

@Component({
  selector: 'pos-end-of-day-trigger',
  templateUrl: './end-of-day-trigger.component.html',
  styleUrls: ['./end-of-day-trigger.component.scss']
})
export class EndOfDayTriggerComponent implements OnInit, ICloseable {

  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  settingParam: SettingParam;
  cashDrawerList: Array<CashDrawerHistory> = [];
  items: Array<MenuItem>;
  activeIndex = 0;
  showModal = false;
  isEndOfDayAlreadyTriggered = false;
  time: Date;
  endOfDayScheduledDailyCloseMessage = '';
  isShowWarning = true;
  messages = [
    Messages.EndOfDayUnservedOrders,
    Messages.EndOfDayCloseDrawers,
    Messages.EndOfDayTipsApplied,
    Messages.EndOfDayOperations
  ];

  constructor(private spinnerService: SpinnerService,
    private applicationStateService: ApplicationStateService,
    private alertsService: AlertsService,
    private endOfDayService: EndOfDayService,
    private orderService: OrderService) { }

  ngOnInit(): void {
    this.items = [{ label: 'Serve Orders' }, { label: 'Close Drawer' }, { label: 'Apply Tips' }, { label: 'Confirm' }];
    this.setDefaults();
    this.getLastCloseDate();
  }

  setStepIcon() {
    const steps = $('.p-steps-number');
    $('.p-steps-number').text('');
    forEach(steps, (item, index) => {
      if (parseInt(index, 10) < this.activeIndex) {
        item.setAttribute('class', 'p-steps-number fas fa-check bg-green white');
      }
    });
  }

  getScheduledClose() {
    const endOfDay = this.settingParam.EndOfDay.toString().split(':');
    const endOfDayDate = new Date();
    endOfDayDate.setHours(parseInt(endOfDay[0], 10), parseInt(endOfDay[1], 10));
    const now = new Date();
    const ms = endOfDayDate.getTime() - now.getTime();
    let mins: number | string = Math.floor(ms / (60 * 1000));
    let hours: number | string = Math.floor(mins / 60);
    if (mins < 0 || hours < 0) {
      this.isShowWarning = false;
      return;
    }
    mins = mins - (hours * 60);
    mins = mins < 10 ? '0' + mins.toString() : mins.toString();
    hours = hours < 10 ? '0' + hours.toString() : hours.toString();
    this.endOfDayScheduledDailyCloseMessage = StringUtils.format(Messages.EndOfDayScheduledDailyClose, { 'hours': hours + ':' + mins });
  }

  setDefaults() {
    this.settingParam = this.applicationStateService.settingParam;
  }

  getLastCloseDate() {
    this.spinnerService.show();
    this.endOfDayService.getLastDailyClose()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (lastCloseDay) => {
          const today = new Date();
          this.time = new Date(lastCloseDay);
          if (today.getDate() === this.time.getDate() && today.getMonth() === this.time.getMonth() &&
            today.getFullYear() === this.time.getFullYear()) {
            this.isEndOfDayAlreadyTriggered = true;
          } else {
            this.checkUnsettledOrders();
            this.getScheduledClose();
          }
          this.setStepIcon();
        }, error: this.alertsService.showApiError
      });
  }

  checkUnsettledOrders() {
    this.spinnerService.show();
    this.orderService.getUnservedOrdersData(this.settingParam.CashDrawerPrinter.Id, this.applicationStateService.userId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          if (!res || !res.length) {
            this.activeIndex = 1;
            this.isCashDrawerOpen();
            this.setStepIcon();
          }
        }, error: this.alertsService.showApiError
      });
  }

  isCashDrawerOpen() {
    this.spinnerService.show();
    this.endOfDayService.checkCashDrawerStatus()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: Array<CashDrawerHistory>) => {
          if (response.length > 0) {
            let drawers = '';
            forEach(response, (drawer) => {
              drawers += '<li class="text-danger">' + drawer.DrawerName + '</li>';
            });
            this.messages[this.activeIndex] = StringUtils.format(Messages.EndOfDayCloseDrawers, { 'drawers': drawers });
          } else {
            this.activeIndex = 2;
            this.setStepIcon();
          }
        }, error: this.alertsService.showApiError
      });
  }

  next() {
    if (this.activeIndex < 3) {
      this.activeIndex++;
      this.setStepIcon();
    } else {
      this.spinnerService.show();
      this.endOfDayService.triggerEndOfDay()
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: () => {
            this.alertsService.renderSuccessMessage(Messages.EndOfDayTriggerSuccess);
            this.close.emit();
          }, error: this.alertsService.showApiError
        });
    }
  }

  closeEndOfDay() {
    this.close.emit();
  }

}
