import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { from } from 'rxjs/internal/observable/from';
import {
    ApplicationStateService,
    AuthenticationService,
    InfoModalComponent,
    Messages,
    ModalService,
} from './shared';
import { UserLogInService } from './shared/services/user-log-in.service';
import { Location } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard  {

    promise: Promise<boolean>;
    resolvePromise: (value?: boolean | PromiseLike<boolean>) => void;
    rejectPromise: (reason?: any) => void;
    constructor(private authenticationService: AuthenticationService,
        private router: Router,
        private applicationStateService: ApplicationStateService,
        private userLoginService: UserLogInService,
        private modalService: ModalService,
        private location: Location) {
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.canActivate(childRoute, state);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let isLoggedIn = this.authenticationService.isLoggedIn();
        let isHavePermissionForView = !(route && route.data && route.data.permissions) ||
            this.authenticationService.userHasPermission(route.data.permissions, 'all');
        const userCode = this.applicationStateService.userCode;
        if (!isLoggedIn && userCode) {
            return from(this.login()
                .then(() => {
                    isLoggedIn = this.authenticationService.isLoggedIn();
                    isHavePermissionForView = !(route && route.data && route.data.permissions) ||
                        this.authenticationService.userHasPermission(route.data.permissions, 'all');
                    this.checkForPermission(isLoggedIn, isHavePermissionForView);
                    return Promise.resolve(isLoggedIn && isHavePermissionForView);
                }, () => {
                    return Promise.resolve(false);
                })

            );
        } else if (!isLoggedIn && (!isHavePermissionForView || !userCode)) {
            const redirect = route.queryParams['redirect'];
            if (redirect) {
                sessionStorage.setItem('redirectRoute', state.url?.split('?')[0]);
            }
            this.router.navigate(['login']);
        } else {
            this.checkForPermission(isLoggedIn, isHavePermissionForView);
            return isLoggedIn && isHavePermissionForView;
        }
    }

    login = (): Promise<boolean> => {
        this.userLoginService.data$
            .subscribe({
                next: (res) => {
                    return this.resolvePromise(res);
                }, error: () => {
                    this.rejectPromise();
                }
            });
        this.promise = new Promise<boolean>((resolve, reject) => {
            this.resolvePromise = resolve;
            this.rejectPromise = reject;
        });
        return this.promise;
    }

    checkForPermission = (isLoggedIn: boolean, isUserHavePermission: boolean) => {
        if (isLoggedIn && !isUserHavePermission) {
            this.openInfoModel();
        }
    }

    openInfoModel = () => {
        const modalRef = this.modalService.getModalWrapper(InfoModalComponent);
        const modal = modalRef.show({
            animated: false,
            class: 'vertical-center',
            initialState: {
                message: Messages.ErrorWhileUserNotHavePermission
            }
        });
        modal.close.subscribe(res => {
            this.location.back();
        });
    }
}
