<pos-edit-wrapper heading="Accounts & Tabs" [headingIcon]="icons.accountsAndTabsUsersTag" (close)="cancelEdit()"
    [isPopup]="false">
    <form (ngSubmit)="saveAccountsAndTabs()" #accountsandTabsform="ngForm" class="edit-form">
        <context-provider provide="props">
            <div class="col-12 pos-page-body border-top pt-pix-5">
                <pos-row>
                    <pos-column xs="12">
                        <h3 class="setting-title"> Settings </h3>
                        <pos-row colXs="12" class="pos-title-content">
                            <pos-column>
                                <pos-row>
                                    <pos-column class="col-220">
                                            <pos-form-field>
                                                <p-checkbox posInput name="accountOrders" #accountOrders="ngModel"
                                                    (onChange)="accountToOrders()" [(ngModel)]="chkAccountOrders"
                                                    binary="true" label="Assign account to orders">
                                                </p-checkbox>
                                            </pos-form-field>
                                    </pos-column>
                                    <pos-column class="col-sm-6 col-9 pt-1">
                                        <pos-form-field [appendPaddingTop]="false">
                                            <span class="display-flex">
                                                <ng-select posInput [items]="accountTypes" appendTo="body"
                                                    [disabled]="!chkAccountOrders"
                                                    [(ngModel)]="parameters.ChooseAccountOptional" class="col-150"
                                                    name="accountTypes" [clearable]="false" [clearOnBackspace]="false">
                                                </ng-select>
                                                <field-info
                                                    [info]="fieldInfoMessages.AccountsAndTabsAssignAccountToOrders"
                                                    class="pt-pix-7 ms-pix-15"></field-info>
                                            </span>
                                        </pos-form-field>
                                    </pos-column>
                                </pos-row>
                            </pos-column>

                            <pos-column class="ps-5">
                                <pos-row class="p-0">
                                    <pos-column>
                                        <pos-row>
                                            <pos-column class="col-150">
                                                <pos-form-field>
                                                    <p-checkbox posInput name="accountOrders_Account"
                                                        [disabled]="!chkAccountOrders" #accountOrders_Account="ngModel"
                                                        (onChange)="generateAccountToOrdersString('Account,');"
                                                        [(ngModel)]="chkAccountOrders_Account" binary="true"
                                                        [label]="loyaltyAccountTerm">
                                                    </p-checkbox>
                                                </pos-form-field>
                                            </pos-column>

                                            <pos-column class="col-150">
                                                <pos-form-field>
                                                    <p-checkbox posInput name="accountOrders_AccountDefault"
                                                        [disabled]="!chkAccountOrders_Account"
                                                        #accountOrders_AccountDefault="ngModel"
                                                        (onChange)="setDefaultAccountToOrdersString('Account');"
                                                        [(ngModel)]="chkAccountOrders_AccountDefault" binary="true"
                                                        label="Default">
                                                    </p-checkbox>
                                                </pos-form-field>
                                            </pos-column>
                                        </pos-row>
                                    </pos-column>

                                    <pos-column>
                                        <pos-row >
                                            <pos-column class="col-150">
                                                <pos-form-field>
                                                    <p-checkbox posInput name="accountOrders_Table"
                                                        [disabled]="!chkAccountOrders" #accountOrders_Table="ngModel"
                                                        (onChange)="generateAccountToOrdersString('Table,');"
                                                        [(ngModel)]="chkAccountOrders_Table" binary="true"
                                                        [label]="tableTerm">
                                                    </p-checkbox>
                                                </pos-form-field>
                                            </pos-column>

                                            <pos-column class="col-150">
                                                <pos-form-field>
                                                    <p-checkbox posInput name="accountOrders_TableDefault"
                                                        [disabled]="!chkAccountOrders_Table"
                                                        #accountOrders_TableDefault="ngModel"
                                                        (onChange)="setDefaultAccountToOrdersString('Table');"
                                                        [(ngModel)]="chkAccountOrders_TableDefault" binary="true"
                                                        label="Default">
                                                    </p-checkbox>
                                                </pos-form-field>
                                            </pos-column>
                                        </pos-row>
                                    </pos-column>

                                    <pos-column>
                                        <pos-row>
                                            <pos-column class="col-150">
                                                <pos-form-field>
                                                    <p-checkbox name="accountOrders_Tab" [disabled]="!chkAccountOrders"
                                                        #accountOrders_Tab="ngModel"
                                                        (onChange)="generateAccountToOrdersString('Tab,');"
                                                        [(ngModel)]="chkAccountOrders_Tab" binary="true"
                                                        [label]="accountTerm">
                                                    </p-checkbox>
                                                </pos-form-field>
                                            </pos-column>

                                            <pos-column class="col-150">
                                                <pos-form-field>
                                                    <p-checkbox name="accountOrders_TabDefault"
                                                        #accountOrders_TabDefault="ngModel"
                                                        [disabled]="!chkAccountOrders_Tab"
                                                        (onChange)="setDefaultAccountToOrdersString('Tab');"
                                                        [(ngModel)]="chkAccountOrders_TabDefault" binary="true"
                                                        label="Default">
                                                    </p-checkbox>
                                                </pos-form-field>
                                            </pos-column>
                                        </pos-row>
                                    </pos-column>
                                </pos-row>
                            </pos-column>

                            <pos-column>
                                <pos-form-field>
                                    <div class="display-flex">
                                        <p-checkbox posInput name="displayAccountLocationOnOrderReceipt"
                                            #displayAccountLocationOnOrderReceipt="ngModel"
                                            [(ngModel)]="parameters.DisplayAccountLocationOnOrderReceipt" binary="true"
                                            label="Display subaccount on order receipts">
                                        </p-checkbox>
                                        <field-info [info]="fieldInfoMessages.AccountsAndTabsDisplaySubAccount"
                                            placement='right' styleClass="float-end pt-0"></field-info>
                                    </div>
                                </pos-form-field>
                            </pos-column>

                            <pos-column>
                                <pos-form-field>
                                    <div class="display-flex">
                                        <p-checkbox posInput name="autoCloseAccount" #autoCloseAccount="ngModel"
                                            [(ngModel)]="parameters.AutoCloseAccount" binary="true"
                                            label="Auto close accounts">
                                        </p-checkbox>
                                        <field-info [info]="fieldInfoMessages.AccountsAndTabsAutoCloseAccount"
                                            class="float-end pt-0 ms-pix-15"></field-info>
                                    </div>
                                </pos-form-field>
                            </pos-column>

                            <pos-column>
                                <pos-form-field>
                                    <div class="display-flex">
                                        <p-checkbox posInput name="autoCloseTab" #autoCloseTab="ngModel"
                                            [(ngModel)]="parameters.AutoCloseTab" binary="true" label="Auto close tabs">
                                        </p-checkbox>
                                        <field-info [info]="fieldInfoMessages.AccountAndTabsAutoCloseTabs"
                                            class="float-end pt-0 ms-pix-15"></field-info>
                                    </div>
                                </pos-form-field>
                            </pos-column>

                            <pos-column>
                                <pos-form-field>
                                    <div class="display-flex">
                                        <p-checkbox posInput name="oneActiveOrderPerAccount"
                                            #oneActiveOrderPerAccount="ngModel"
                                            [(ngModel)]="parameters.OneActiveOrderPerAccount"
                                            label="One active order per account" binary="true">
                                        </p-checkbox>
                                        <field-info [info]="fieldInfoMessages.AccountsAndTabsOneActiveAccountPerAccount"
                                            class="float-end pt-0 ms-pix-15"></field-info>
                                    </div>
                                </pos-form-field>
                            </pos-column>

                            <pos-column>
                                <pos-form-field>
                                    <div class="display-flex">
                                        <p-checkbox posInput name="oneActiveOrderPerTab" #oneActiveOrderPerTab="ngModel"
                                            [(ngModel)]="parameters.OneActiveOrderPerTab" binary="true"
                                            label="One active order per tab">
                                        </p-checkbox>
                                        <field-info [info]="fieldInfoMessages.AccountsAndTabsOneActiveAccountPerTab"
                                            class="float-end pt-0 ms-pix-15"></field-info>
                                    </div>
                                </pos-form-field>
                            </pos-column>

                            <pos-column>
                                <pos-form-field>
                                    <div class="display-flex">
                                        <p-checkbox posInput name="isEnableLoyaltyCardProgram"
                                            #isEnableLoyaltyCardProgram="ngModel"
                                            [(ngModel)]="parameters.IsEnableLoyaltyCardProgram" binary="true"
                                            label="Enable loyalty card program">
                                        </p-checkbox>
                                        <field-info [info]="fieldInfoMessages.AccountsAndTabsEnableLoyaltyCard"
                                            class="float-end pt-0 ms-pix-15"></field-info>
                                    </div>
                                </pos-form-field>
                            </pos-column>

                            <pos-column class="ps-5">
                                <pos-form-field>
                                    <p-checkbox name="loyaltyProgramRequiresMemberInformation"
                                        #loyaltyProgramRequiresMemberInformation="ngModel"
                                        [(ngModel)]="parameters.LoyaltyProgramRequiresMemberInformation" binary="true"
                                        [disabled]="!parameters.IsEnableLoyaltyCardProgram"
                                        label="Allow Member Details">
                                    </p-checkbox>
                                </pos-form-field>
                            </pos-column>
                        </pos-row>
                    </pos-column>
                </pos-row>

                <div class="col-12 section-border">
                    <div>
                        <h3 class="setting-title">Account Switching</h3>
                    </div>
                    <pos-row class="pos-title-content">
                        <pos-column class="col-300">
                            <pos-form-field>
                                <ng-select [items]="accountSwitches" appendTo="body" bindLabel="Name" bindValue="Name"
                                    [(ngModel)]="parameters.AccountSwitching" name="accountSwitches"
                                    placeholder="Select">
                                </ng-select>
                            </pos-form-field>
                        </pos-column>
                        <pos-column>
                            <pos-form-field>
                                <p-checkbox posInput name="allowNonpaymentNonAccount"
                                    #allowNonpaymentNonAccount="ngModel"
                                    [(ngModel)]="parameters.AllowNonpaymentNonAccount" binary="true"
                                    label="Permit non-account unpaid entry">
                                </p-checkbox>
                            </pos-form-field>
                        </pos-column>
                    </pos-row>
                </div>
            </div>
            <div class="col-12 action-button-container page-footer">
                <div class="form-horizontal">
                    <button class="btn btn-primary btn-lg btn-save pos-button" scrollup type="submit">Save</button>
                    <button class="btn btn-secondary btn-lg btn-cancel pos-button" routerLink="../" scrollup
                        type="button">Cancel</button>
                </div>
            </div>
        </context-provider>
    </form>
</pos-edit-wrapper>
