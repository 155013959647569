import { Component, Input, OnInit } from '@angular/core';
import { forEach } from 'lodash';
import { AlertsService } from 'src/app/shared';
import { Service } from '../../interfaces/service';
import { HicDashboardService } from '../../services';
declare let $: any;

@Component({
  selector: 'pos-service-status',
  templateUrl: './service-status.component.html',
  styleUrls: ['./service-status.component.scss']
})
export class ServiceStatusComponent implements OnInit {

  @Input() services: Array<Service> = [];
  height = $(window).height() - 250;

  constructor(private hicDashboardService: HicDashboardService,
    private alertsService: AlertsService) { }

  ngOnInit(): void {
    this.checkServiceStatus();
  }

  checkServiceStatus() {
    forEach(this.services, (service: Service) => {
      this.hicDashboardService.checkServiceStatus(service.HealthUrl)
        .subscribe({
          next: () => {
            service.Status = 'Up';
          }, error: (error) => {
            service.Status = 'Down';
          }
        });
    });
  }
}
