<div class="col-12 p-0 ps-3">
    <form #userPayRateForm="ngForm" (submit)="saveUserPayRate(userPayRateForm.valid)">
        <pos-row colXxl="2" colLg="3" colSm="5" colXs="12" colStyleClass="p-0">
            <pos-column class="me-3 col-170">
                <pos-form-field label="Hourly Rate"
                [validationMessages]="{required: 'Please enter rate.', range: 'Please enter value between 0 to 100.'}"
                inputStyle="col-12">
                <p class="input-group">
                    <span class="input-group-addon">{{currencySymbol}}</span>
                    <input posInput type="number" name="payAmount" #payAmount='ngModel' class="form-control price-input"
                        [(ngModel)]="payRate.amount" [required]="true" posDecimal min="0"
                        step="0.01" max="100" [range]="[0,100]" />
                    </p>
            </pos-form-field>
            </pos-column>
            <pos-column lg="2" md="3" sm="4" class="me-2">
                <pos-form-field label="Job Code" [validationMessages]="{required: 'Please select job code rate.'}"
                inputStyle="col-12">
                <ng-select posInput [items]="jobCodes" bindLabel="name" bindValue="Id" placeholder="Select job code"
                    [(ngModel)]="payRate.job_code_id" name="jobCodeId" [required]="true">
                </ng-select>
            </pos-form-field>
            </pos-column>
            <pos-column lg="3" md="4" sm="5" class="p-0 col-255">
                <pos-form-field label="Effective Date" inputStyle="col-12" [validationMessages]="{required: 'Please select effective date.'}">
                    <p-calendar posInput [readonlyInput]="true" [required]="true" [dateFormat]="dateFormat"
                        [inputStyle]="{'width':'unset','display':'unset'}" inputStyleClass="form-control"
                        [monthNavigator]="true" [showIcon]="true" name="effectiveDate" [(ngModel)]="payRate.effective_date"
                        appendTo="body">
                    </p-calendar>
                </pos-form-field>
            </pos-column>
            <pos-column class="col-120">
                <pos-row colXs="12">
                    <pos-column class="d-none d-sm-block">
                        <label></label>
                    </pos-column>
                    <pos-column class="no-wrap text-left pt-pix-15 px-pix-10">
                        <button class="btn btn-primary text-white fw-bold normal me-pix-5 pos-button" type="submit">
                            {{payRate.id > 0 ? 'Update' : 'Add'}}
                        </button>
                        <button class="btn btn-secondary normal pos-button" (click)="reset()" type="button">
                            Cancel
                        </button>
                    </pos-column>
                </pos-row>
            </pos-column>
        </pos-row>
    </form>
    <div class="clearfix"></div>
    <div class="row">
        <div class="col-xl-6 col-lg-9 col-sm-12 p-0 overflow-auto pt-pix-10">
            <div class="table-responsive overflow-x-hidden">
                <ng-table id="payRates" [sort]="true" [columns]="payRatColumns" [data]="payRateList" [paginator]="false"
                    [scrollable]="true" [scrollHeight]="'calc(100vh - 395px)'" [search]="false">
                </ng-table>
                <ng-template let-data="rowData" #amountTemplate>
                    <span>
                        {{data.amount | tark_currency}}
                    </span>
                </ng-template>
            </div>
        </div>
    </div>
</div>
<pos-confirm-delete (onConfirmAcknowledge)="onConfirmAcknowledge()" #confirmUserPayRate>
</pos-confirm-delete>
