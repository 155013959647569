import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { turnDebugMode } from 'src/app/shared/components/icon';
import { DomainConstants } from 'src/app/shared/constants';
import { KeyValue } from '@angular/common';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AlertsService, SpinnerService, ApplicationStateService, ModalBackdropService } from 'src/app/shared';
import { LogService } from '../../services/log.service';
import { finalize } from 'rxjs/operators';
import { FeatureDetectionService } from '../../services/feature-detection.service';
import { NGXLogger } from 'ngx-logger';
import * as stackTrace from 'stacktrace-js';

import * as _ from 'lodash';

@Component({
  selector: 'pos-log-viewer',
  templateUrl: './log-viewer.component.html',
  styleUrls: ['./log-viewer.component.scss']
})
export class LogViewerComponent implements OnInit {

  @Input('isDebug') isDebug: boolean = false;
  logs: Array<any> = [];
  logLevels: any;
  logLevel;
  icons = {
    turnDebugMode
  };
  debuggerModalRef: BsModalRef;
  terminalName: string = '';
  browser: string = '';
  @ViewChild('debuggerModal') public debuggerModal: any;
  constructor(private bsModalService: BsModalService,
    private modalBackdropService: ModalBackdropService,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private applicationStateService: ApplicationStateService,
    private logService: LogService,
    private featureDetectionService: FeatureDetectionService,
    private logger: NGXLogger) {
    this.logLevels = DomainConstants.LOG_LEVEL;
    this.logLevel = this.logLevels.Debug;
  }

  ngOnInit() {
    const browserInfo = this.featureDetectionService.getBrowserInfo();
    this.terminalName = this.applicationStateService.terminalName;
    this.browser = browserInfo.browserName + ' (' + browserInfo.majorVersion + ')';
    const self = this;
    ['error', 'warn', 'info', 'log', 'debug'].forEach(function (verb) {
      console[verb] = (function (method, v) {
        return function (message) {
          // Log to browser console
          Function.prototype.bind.call(method, console, message);
          if (self.isDebug) {
            const logMessage = self.logEntry(v, new Date(), message);
            self.logs.push(logMessage);
          }
        };
      })(console[verb], verb);
    });

    window.onerror = function (message, source, lineno, colno, error) {
      // Log error to app insights
      stackTrace.fromError(error).then(this.logger.error);
      console.error('JS error on line ' + lineno + ': ' + colno + ' at ' + source + '. Error: ' + error);
    };
  }

  getLevelValue(level) {
    let logLevel;
    _.forEach(this.logLevels, (value, key) => {
      if (key.toLowerCase() === level) {
        logLevel = this.logLevels[key];
      }
    });
    return logLevel;
  }

  logEntry(level, time, message) {
    const logLevel = {
      'warn': 'text-warning',
      'error': 'text-danger',
      'log': 'text-success',
      'info': 'text-info'
    }
    const logMessage = {
      level: level,
      time: time,
      message: message,
      levelValue: this.getLevelValue(level),
      cssClass: logLevel[level] ? logLevel[level] : 'text-primary'
    };
    return logMessage;
  }


  openDebuggerModal() {
    if (!this.debuggerModalRef) {
      this.debuggerModalRef = this.bsModalService.show(this.debuggerModal, {
        'backdrop': 'static',
        'class': 'vertical-center',
        'keyboard': false
      });
      this.modalBackdropService.addBackDrop();
    }
  }

  closeDebuggerModal() {
    this.debuggerModalRef.hide();
    this.modalBackdropService.removeBackdrop();
    this.debuggerModalRef = null;
  }

  originalOrder = (firstKey: KeyValue<number, string>, secondKey: KeyValue<number, string>): number => {
    return 0;
  }

  clearLog() {
    this.logs = [];
  }

  uploadLog() {
    this.spinnerService.show();
    const uploadLogContain = JSON.stringify(this.logs);
    const logModel = {
      TerminalId: this.applicationStateService.terminalId,
      TerminalName: this.applicationStateService.terminalName,
      TerminalType: this.applicationStateService.terminalType,
      LogText: uploadLogContain
    };
    this.logService.uploadLog(logModel)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          if (res) {
            console.log(res);
          }
        }, error: this.alertService.showApiError
      });
  }
}
