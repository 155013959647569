import { Component, OnInit, Input } from '@angular/core';
import { AdfColumn, AdfModel, DashboardOptions } from '../../interfaces';
import { sync, arrowsAlt, minus, dashboardPlus, cog, times, clone, pencil, editWhite, expandArrows } from 'src/app/shared/components/icon';
import { ModalService, ConfirmDeleteModalComponent, Messages } from 'src/app/shared';
import { AdfWidgetFullscreenComponent } from '../adf-widget-fullscreen/adf-widget-fullscreen.component';
import { WidgetEditComponent } from '../widgets';
import { DashboardBroadcastingService } from '../../services/dashboard-broadcasting.service';
import { cloneDeep } from 'lodash';


@Component({
  selector: 'pos-adf-widget',
  templateUrl: './adf-widget.component.html',
  styleUrls: ['./adf-widget.component.scss']
})
export class AdfWidgetComponent implements OnInit {

  @Input() adfModel: AdfModel;
  @Input() definition: any;
  @Input() isEditMode: boolean;
  @Input() options: DashboardOptions;
  @Input() isInteractive: boolean;
  @Input() column: AdfColumn;

  icons = {
    sync, arrowsAlt, minus, dashboardPlus, cog, times, clone, pencil, editWhite, expandArrows
  };

  isCollapsed = false;

  constructor(private modalService: ModalService,
    private dashboardBroadcastService: DashboardBroadcastingService
  ) { }

  ngOnInit(): void {
  }

  reload() {
    this.dashboardBroadcastService.widgetReload(this.definition);
  }

  edit() {
    const modalRef = this.modalService.getModalWrapper(WidgetEditComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center modal-lg',
      initialState: {
        widgetConfig: cloneDeep(this.definition),
      }
    });
    modal.close.subscribe((res) => {
      if (res && res.reload) {
        this.definition = res.widgetConfiguration;
        if (this.definition && this.definition.config) {
          this.definition.config.params = res.widgetConfiguration.params;
        }
        this.reload();
      }
    });
  }

  openFullScreen() {
    const modalRef = this.modalService.getModalWrapper(AdfWidgetFullscreenComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center modal-lg',
      initialState: {
        adfModel: cloneDeep(this.adfModel),
        definition: cloneDeep(this.definition),
        isInteractive: cloneDeep(this.isInteractive),
        column: cloneDeep(this.column)
      }
    });
    modal.close.subscribe((res) => {
    });
  }

  deleteWidget() {
    if (this.column) {
      const index = this.column.widgets.indexOf(this.definition);
      if (index >= 0) {
        this.column.widgets.splice(index, 1);
        this.dashboardBroadcastService.widgetDelete(this.definition);
      }
    }
  }

  remove = () => {
    if (this.options.EnableConfirmDelete) {
      const confirmDelete = this.modalService.show(ConfirmDeleteModalComponent, {
        animated: false,
        class: 'vertical-center',
        'backdrop': 'static',
        initialState: {
          message: Messages.ConfirmDeleteWidget
        }
      });

      confirmDelete.close.subscribe(res => {
        if (res && res.shouldDelete) {
          this.deleteWidget();
        }
      });
    } else {
      this.deleteWidget();
    }
  };


}

