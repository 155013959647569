import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RuntimeConstants } from 'src/app/shared';

@Injectable({
  providedIn: 'root'
})
export class MakeTableService {

  constructor(private httpService: HttpClient) { }


  getActiveOrdersData(terminalId) {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}make-table/` + terminalId
    );
  }

  updateProductState(terminalId, orderId, makeTableProductStatusModel) {
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}make-table/${terminalId}/order/${orderId}/update-product-state`,
      makeTableProductStatusModel
    );
  }

  updateAllProductState(terminalId, orderId, makeTableProducts) {
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}make-table/${terminalId}/order/${orderId}/update-all-product-state`,
      makeTableProducts
    );
  }
}
