<pos-edit-wrapper (close)="cancelEditing()" heading="{{inventoryCategoryId > 0 ? 'Edit Category' : 'Add Category'}}">
    <form #form="ngForm" (submit)='saveInventoryCategory(form.valid)'>
        <div class="col-12 pos-modal-body screen-scroll">
            <pos-row>
                <pos-column>
                    <pos-form-field label="Category Name"
                        [validationMessages]="{required: 'Please enter category name.'}">
                        <input posInput type="text" name="invCategoryName" class="form-control"
                            #inventoryCategoryName="ngModel" [(ngModel)]="inventoryCategory.name" [required]="true" />
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Maximum Days between Inventory"
                        [validationMessages]="{required: 'Please enter maximum days between inventory.', range: 'Days must be in range of 0-99.'}">
                        <input posInput type="number" id="invCatMaxDay" name="invCatMaxDay"
                            class="form-control col-120" [(ngModel)]="inventoryCategory.days_between_inventory"
                            [required]="true" #inventoryCategoryDaysBetweenInventory="ngModel" min="0" max="99"
                            [range]="[0, 99]" posOnlyNumbers />
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="clearfix"></div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" value="Save" scrollup>Save</button>
            <button type="button" data-dismiss="modal" class="btn-secondary  btn-lg btn btn-cancel-modal pos-button"
                (click)="cancelEditing()" value="Cancel">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
