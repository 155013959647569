<pos-edit-wrapper heading="{{id > 0 ? 'Edit' : 'Add'}} Report{{id > 0 ? ': '+ reportName: ''}}"
    [headingIcon]="icons.editSolid" (close)="cancel()" [isPopup]="false">
    <form #reportForm="ngForm" (ngSubmit)="submit(reportForm.valid)">
        <div class="col-12 border-top pt-pix-5 report-configuration-parameters">
            <div class="col-12 pos-page-body content-scroll">
                <context-provider provide="props">
                    <pos-row>
                        <pos-column>
                            <pos-form-field label="Name" [validationMessages]="{required: 'Please enter name.'}">
                                <input posInput name="name" #name="ngModel" type="text" class="form-control"
                                    [(ngModel)]="report.Name" [required]="true" />
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Category"
                                [validationMessages]="{required: 'Please select category.'}">
                                <ng-select posInput appendTo="body" [items]="reportCategories" bindLabel="Name"
                                    bindValue="Id" placeholder="Select category"
                                    (change)="filterReportGroup(); report.ReportGroupId = null;"
                                    [(ngModel)]="report.ReportCategoryId" id="reportCategory" name="reportCategory"
                                    [required]="true">
                                </ng-select>
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Group" [validationMessages]="{required: 'Please select group.'}">
                                <ng-select posInput appendTo="body" [items]="reportGroupsFiltered" bindLabel="Name"
                                    bindValue="Id" placeholder="Select group" [(ngModel)]="report.ReportGroupId"
                                    id="reportGroup" name="reportGroup" [required]="true">
                                </ng-select>
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-row xs="12" class="pt-pix-10 mx-pix-2">
                                <pos-column xs="12" class="p-0">
                                    <label class="required">Icon</label>
                                </pos-column>
                                <pos-column lg="3" sm="5" xs="12" class="ps-0">
                                    <pos-icon-input [(value)]="report.ReportIcon" [required]="true"
                                        [submitted]="reportForm.submitted" [validationMessage]="'Please enter icon.'">
                                    </pos-icon-input>
                                </pos-column>
                            </pos-row>
                        </pos-column>

                        <pos-column xs="12">
                            <pos-form-field>
                                <p-checkbox posInput name="chkPaged" #chkPaged="ngModel" [(ngModel)]="report.IsPaged"
                                    binary="true" label="Use Pagination">
                                </p-checkbox>
                            </pos-form-field>
                        </pos-column>
                    </pos-row>

                    <div class="col-12 pt-pix-15 p-0 parameter-parent">
                        <ul class="nav nav-tabs inner-tab pos-nav-tabs">
                            <li [ngClass]="{'active': selectedTab == tabList.Query }">
                                <a (click)="selectedTab = tabList.Query" title="Query" class="blue"
                                    [ngClass]="{'required':!formGroups.Query.$valid}">
                                    <pos-icon [icon]="icons.database" class="theme-separator pe-pix-4">
                                    </pos-icon>
                                    <span>Query</span>
                                </a>
                            </li>
                            <li [ngClass]="{'active': selectedTab == tabList.Parameters }"
                                *ngIf="!isExisting && !report.Id || this.isPrivate && report.Id">
                                <a (click)="selectedTab = tabList.Parameters" class="blue">
                                    <pos-icon [icon]="icons.list" class="theme-separator pe-pix-4">
                                    </pos-icon>
                                    <span>Parameters</span>
                                </a>
                            </li>
                            <li [ngClass]="{'active': selectedTab == tabList.Rules }" *ngIf="report.Id">
                                <a (click)="selectedTab = tabList.Rules" class="blue">
                                    <pos-icon [icon]="icons.taskToDo" class="theme-separator pe-pix-4">
                                    </pos-icon>
                                    <span>Rules</span>
                                </a>
                            </li>
                            <li [ngClass]="{'active': selectedTab == tabList.Settings }">
                                <a (click)="selectedTab = tabList.Settings" class="blue">
                                    <pos-icon [icon]="icons.cogs" class="theme-separator pe-pix-4">
                                    </pos-icon>
                                    <span>Settings</span>
                                </a>
                            </li>
                            <li [ngClass]="{'active': selectedTab == tabList.Group}" *ngIf="report.Id">
                                <a (click)="selectedTab = tabList.Group" class="blue">
                                    <pos-icon [icon]="icons.layerGroup" class="theme-separator pe-pix-4"></pos-icon>
                                    <span>Group</span>
                                </a>
                            </li>
                        </ul>
                        <div class="col-12" [hidden]="selectedTab != tabList.Query">
                            <pos-row>
                                <pos-column *ngIf="!reportId">
                                    <pos-form-field>
                                        <div class="btn-group">
                                            <label class="btn btn-default btn-radio col-75"
                                                [ngClass]="isExisting ? 'active btn-primary normal': 'btn-secondary normal'">
                                                <input posInput [(ngModel)]="isExisting" [value]="true" type="radio"
                                                    name="existing" (change)="dataSourceChanged()">
                                                Existing
                                            </label>
                                            <label class="btn btn-default btn-radio col-75"
                                                [ngClass]=" !isExisting ?'active btn-primary normal': 'btn-secondary normal' ">
                                                <input [(ngModel)]="isExisting" [value]="false" type="radio" name="new"
                                                    (change)="dataSourceChanged()">
                                                New
                                            </label>
                                        </div>
                                    </pos-form-field>
                                </pos-column>

                                <pos-column *ngIf="isPrivate && reportId ? false : isExisting">
                                    <pos-form-field label="Data Source"
                                        [validationMessages]="{required: 'Please select data source.'}">
                                        <ng-select posInput appendTo="body" [items]="filteredDataSources"
                                            bindLabel="Name" bindValue="Id"
                                            (change)="!isExisting ? getParameters() : onChangeDataSource()"
                                            placeholder="Select data source" #reportDataSourceName="ngModel"
                                            [(ngModel)]="report.DataSourceId" id="reportDataSourceName"
                                            name="reportDataSourceName" [required]="true"
                                            [posValidationGroup]="formGroups.Query">
                                        </ng-select>
                                    </pos-form-field>
                                </pos-column>

                                <pos-column *ngIf="isPrivate && reportId ? true : !isExisting">
                                    <pos-form-field label="Type"
                                        [validationMessages]="{required: 'Please select query type.'}">
                                        <ng-select posInput appendTo="body" [items]="queryTypes" bindLabel="Name"
                                            bindValue="Value" placeholder="Select query type"
                                            (change)="onQueryTypeChange()" *ngIf="queryTypes?.length"
                                            [posValidationGroup]="formGroups.Query" [(ngModel)]="dataSource.QueryType"
                                            [clearable]="false" id="dataSourceQueryType" name="dataSourceQueryType"
                                            [required]="true">
                                        </ng-select>
                                    </pos-form-field>
                                </pos-column>

                                <pos-column *ngIf="isPrivate && reportId ? true : !isExisting">
                                    <pos-form-field label="Data Source"
                                        [validationMessages]="{required: 'Please enter data source.'}"
                                        inputStyle="col-12">
                                        <div class="col-sm-4 col-9 p-0"
                                            *ngIf="!dataSource.QueryType || dataSource.QueryType == 'Sproc'">
                                            <input posInput type="text" name="dataSourceSproc" #dataSourceName="ngModel"
                                                [posValidationGroup]="formGroups.Query" class="form-control"
                                                [(ngModel)]="dataSource.DataSource" [required]="true" />
                                        </div>
                                        <div class="col-12 p-0" *ngIf="dataSource.QueryType == 'Query'"
                                            [ngClass]="{'has-error': dataSourceQuery.invalid && reportForm.submitted}">
                                            <ngx-codemirror posInput id="dataSourceQuery" name="dataSourceQuery"
                                                class="data-source-query" #dataSourceQuery="ngModel"
                                                [posValidationGroup]="formGroups.Query"
                                                [(ngModel)]="dataSource.DataSource" [options]="codeMirrorOptions"
                                                [required]="true"></ngx-codemirror>
                                        </div>
                                    </pos-form-field>
                                </pos-column>
                            </pos-row>
                        </div>

                        <div class="col-12 px-pix-20" [hidden]="selectedTab != tabList.Parameters">
                            <div class="pt-pix-10" *ngIf="reportId || !isExisting">
                                <pos-report-configuration-parameters [parameters]="reportParameters"
                                    [dataSourceId]="report.DataSourceId" *ngIf="reportParameters?.length"
                                    (reloadParameters)="getParameters()" [isFormSubmitted]="reportForm.submitted">
                                </pos-report-configuration-parameters>
                            </div>
                        </div>

                        <div class="col-12" [hidden]="selectedTab != tabList.Rules">
                            <div class="report-edit">
                                <div *ngIf="rulesParameter.length >= 2">
                                    <ng-table #rulesGridComponent [search]="false" [sort]="false" [paginator]="false"
                                        [columns]="rulesColumns" [data]="rules"
                                        [styleClass]="'no-border no-alternative-background no-header-border'">
                                    </ng-table>
                                </div>
                                <div *ngIf="rulesParameter.length < 2">
                                    <div class="alert condensed inherit-position alert-warning mb-0 pt-pix-15 mt-pix-10"
                                        id="divDatasourceParameter">
                                        {{ReportTwoParameterError}}</div>
                                </div>
                            </div>
                            <ng-template let-data="rowData" let-rowIndex="rowIndex" #MessageTemplate>
                                <span
                                    [ngClass]="{'has-error': (Message.invalid && reportForm.submitted && rowIndex !== (rules.length - 1))}">
                                    <input type="text" name="Message{{rowIndex}}" #Message="ngModel"
                                        (ngModelChange)="createRulesRow(rowIndex)" class="form-control "
                                        [(ngModel)]="data.Message" (keyup)="createRulesRow(rowIndex)"
                                        [required]="rowIndex !== (rules.length - 1)" />
                                    <span
                                        *ngIf="Message.invalid && reportForm.submitted && rowIndex !== (rules.length - 1)">
                                        <label class="control-label" *ngIf="Message.errors?.required">Please enter
                                            message.</label>
                                    </span>
                                </span>
                            </ng-template>
                            <ng-template let-data="rowData" let-rowIndex="rowIndex" #FirstParameterTemplate>
                                <span class="font-size"
                                    [ngClass]="{'has-error': (FirstParameter.invalid && reportForm.submitted && rowIndex !== (rules.length - 1))}">
                                    <ng-select [items]="firstParameter" bindLabel="Label" bindValue="Id"
                                        placeholder="Select first parameter" appendTo="body"
                                        [(ngModel)]="data.Parameter1Id" name="firstParam{{rowIndex}}"
                                        [required]="rowIndex !== (rules.length - 1)" #FirstParameter="ngModel"
                                        (change)="createRulesRow(rowIndex)" (open)="firstParameterChanged(rowIndex)">
                                    </ng-select>
                                    <span
                                        *ngIf="FirstParameter.invalid && reportForm.submitted && rowIndex !== (rules.length - 1)">
                                        <label class="control-label" *ngIf="FirstParameter.errors?.required">Please
                                            select
                                            parameter.</label>
                                    </span>
                                </span>
                            </ng-template>
                            <ng-template let-data="rowData" let-rowIndex="rowIndex" #SecondParameterTemplate>
                                <span class="font-size"
                                    [ngClass]="{'has-error': (SecondParameter.invalid && reportForm.submitted && rowIndex !== (rules.length - 1))}">
                                    <ng-select [items]="secondParameter" bindLabel="Label" bindValue="Id"
                                        placeholder="Select second parameter" appendTo="body"
                                        [(ngModel)]="data.Parameter2Id" name="secondParam{{rowIndex}}"
                                        [required]="rowIndex !== (rules.length - 1)" #SecondParameter="ngModel"
                                        (change)="createRulesRow(rowIndex)" (open)="secondParameterChanged(rowIndex)">
                                    </ng-select>
                                    <span
                                        *ngIf="SecondParameter.invalid && reportForm.submitted && rowIndex !== (rules.length - 1)">
                                        <label class="control-label" *ngIf="SecondParameter.errors?.required">Please
                                            select
                                            parameter.</label>
                                    </span>
                                </span>
                            </ng-template>
                            <ng-template let-data="rowData" let-rowIndex="rowIndex" #ComparatorTemplate>
                                <span class="font-size"
                                    [ngClass]="{'has-error': (Comparator.invalid && reportForm.submitted && rowIndex !== (rules.length - 1))}">
                                    <ng-select [items]="comparator" bindLabel="Text" bindValue="Value"
                                        placeholder="Select comparator" appendTo="body" [(ngModel)]="data.Comparator"
                                        name="comparator{{rowIndex}}" [required]="rowIndex !== (rules.length - 1)"
                                        #Comparator="ngModel"
                                        (change)="comparatorChanged(data); createRulesRow(rowIndex)">
                                    </ng-select>
                                    <span
                                        *ngIf="Comparator.invalid && reportForm.submitted && rowIndex !== (rules.length - 1)">
                                        <label class="control-label" *ngIf="Comparator.errors?.required">Please select
                                            comparator.</label>
                                    </span>
                                </span>
                            </ng-template>
                            <ng-template let-data="rowData" let-rowIndex="rowIndex" #operationTemplate>
                                <div class="d-flex pt-pix-2">
                                    <span (click)="removeRule(data, rowIndex)" class="pr-2" tooltip title="Delete"
                                        *ngIf="rowIndex !== (rules.length - 1)">
                                        <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
                                    </span>
                                </div>
                            </ng-template>
                        </div>

                        <div class="col-12" [hidden]="selectedTab != tabList.Settings">
                            <div>
                                <ng-table #rulesGridComponent [search]="false" [sort]="false" [paginator]="false"
                                    [columns]="reportSettingsColumns" [data]="reportSettings"
                                    [styleClass]="'no-border no-alternative-background no-header-border'">
                                </ng-table>
                            </div>
                            <ng-template #TotalHeaderTemplate>
                                <div class="text-center">Show Total</div>
                            </ng-template>
                            <ng-template #GroupByHeaderTemplate>
                                <div class="text-center">Group By</div>
                            </ng-template>
                            <ng-template let-data="rowData" let-rowIndex="rowIndex" #ColumnNameTemplate>
                                <input type="text" name="ColumnName{{rowIndex}}" #ColumnName="ngModel"
                                    (ngModelChange)="createSettingRow(rowIndex)" class="form-control"
                                    [(ngModel)]="data.ColumnName" (keyup)="createSettingRow(rowIndex)" />
                            </ng-template>
                            <ng-template let-data="rowData" let-rowIndex="rowIndex" #FormatTemplate>
                                <ng-select [items]="reportFormat" bindLabel="Value" bindValue="Text"
                                    placeholder="Select format" appendTo="body" [(ngModel)]="data.Format"
                                    name="Format{{rowIndex}}" #ReportFormat="ngModel"
                                    (change)="createSettingRow(rowIndex)">
                                </ng-select>
                            </ng-template>
                            <ng-template let-data="rowData" let-rowIndex="rowIndex" #AlignTemplate>
                                <ng-select [items]="reportSettingsAlign" bindLabel="Text" bindValue="Value"
                                    placeholder="Select alignment" appendTo="body" [(ngModel)]="data.Align"
                                    name="ReportSettingAlign{{rowIndex}}" #ReportSettingAlign="ngModel"
                                    dropdownPosition="auto" (change)="createSettingRow(rowIndex)">
                                </ng-select>
                            </ng-template>
                            <ng-template let-data="rowData" let-rowIndex="rowIndex" #TotalTemplate>
                                <p-checkbox name="Total{{rowIndex}}" #Total="ngModel" [(ngModel)]="data.Total"
                                    binary="true" class="pt-pix-7">
                                </p-checkbox>
                            </ng-template>
                            <ng-template let-data="rowData" let-rowIndex="rowIndex" #SettingsOperationTemplate>
                                <span (click)="removeSettings(data, rowIndex)" class="pr-2" tooltip title="Delete"
                                    *ngIf="rowIndex !== (reportSettings.length - 1)">
                                    <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
                                </span>
                            </ng-template>
                        </div>
                        <div class="col-12 px-pix-20" [hidden]="selectedTab != tabList.Group">
                            <div class="col-md-7 col-lg-5 col-12 p-0 pt-pix-10">
                                <ng-table #reportEditGroupGrid [sort]="false" [paginator]="false"
                                    [columns]="reportEditGroupColumns" [data]="reportSettingGroups" [search]="false"
                                    [scrollable]="false" class="report-group-table">
                                </ng-table>

                                <ng-template let-data="rowData" let-rowIndex="rowIndex" #GroupByColumnTemplate>
                                    <input type="text" class="form-control" name="groupColumn{{rowIndex}}"
                                        [(ngModel)]="data.GroupField" (ngModelChange)="createGroupRow(rowIndex)"
                                        (keyup)="createGroupRow(rowIndex)" />
                                </ng-template>
                                <ng-template let-data="rowData" let-rowIndex="rowIndex" #GroupByColumnColorTemplate>
                                    <pos-color-picker name="groupColor{{rowIndex}}" cpPosition="top"
                                        [styleClass]="'col-12'" [(ngModel)]="data.Color"></pos-color-picker>
                                </ng-template>
                                <ng-template let-data="rowData" let-rowIndex="rowIndex" #GroupOperationTemplate>
                                    <span (click)="removeGroup(data, rowIndex)" class="pr-2" tooltip title="Delete"
                                        *ngIf="rowIndex !== (reportSettingGroups.length - 1)">
                                        <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
                                    </span>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </context-provider>
            </div>
        </div>
        <div class="col-12 action-button-container page-footer">
            <button class="btn-lg btn-success btn white btn-save pos-button" type="submit"
                (click)="isSaveAndContinue = true" *ngIf="!report.Id && !isExisting"> Apply
            </button>
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" value="Save">
                Save
            </button>
            <button type="button" class="btn-secondary btn-lg btn btn-cancel pos-button" value="Cancel"
                (click)="cancel()"> Cancel
            </button>
        </div>
    </form>
</pos-edit-wrapper>
