<pos-edit-wrapper heading="Add Backup" (close)="onCancel()">
  <form #formScreenBackup="ngForm" (ngSubmit)='saveScreenBackup(formScreenBackup.valid)'>
    <div class="col-12 pos-modal-body screen-scroll">
      <pos-row>
        <pos-column>
          <pos-form-field label="Name" [validationMessages]="{required: 'Please enter backup name.'}">
            <input posInput name="backupNameText" #backupNameText="ngModel" type="text" class="form-control"
              [(ngModel)]="backupName" [required]="true" />
          </pos-form-field>
        </pos-column>
      </pos-row>
    </div>
    <div class="col-12 action-button-container popup-footer">
      <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" value="Save">Save</button>
      <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" value="Cancel"
        (click)="onCancel()">Cancel</button>
    </div>
  </form>
</pos-edit-wrapper>
