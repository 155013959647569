import { Component, Input, ViewChild } from '@angular/core';
import { AlertsService, SpinnerService, Messages, FieldInfoMessages, FormUtilityService, BaseCrudModelComponent } from 'src/app/shared';
import { HardwareInterface } from '../../interface/hardware-interface';
import { HardwareInterfaceService } from '../../services/hardware-interface.service';
import { finalize } from 'rxjs/operators';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'pos-hardware-interface-edit',
  templateUrl: './hardware-interface-edit.component.html',
})
export class HardwareInterfaceEditComponent extends BaseCrudModelComponent<HardwareInterface> {
  @ViewChild('hardwareInterfaceForm') hardwareInterfaceForm: NgForm;
  get getForm(): NgForm {
    return this.hardwareInterfaceForm
  }
  @Input('id') id: number;
  HIC: HardwareInterface;
  fieldInfoMessages = FieldInfoMessages;

  constructor(protected hardwareInterfaceService: HardwareInterfaceService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    formUtilityService: FormUtilityService) {
    super(hardwareInterfaceService, alertService, spinnerService, formUtilityService);
    this.HIC = hardwareInterfaceService.newHardwareInterface();
  }

  onSaveSuccess(model: HardwareInterface): void {
    this.hide({ shouldReload: true });
  }

  loadDependencies(): void {
    this.spinnerService.show();
    this.hardwareInterfaceService.get(this.id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          this.HIC = response[0] ? response[0] : this.hardwareInterfaceService.newHardwareInterface();
        }
      });
  }

  saveHardwareInterface(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.spinnerService.show();
    this.hardwareInterfaceService.saveHIC(this.HIC)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          this.alertService.renderSuccessMessage(Messages.HICSaveSuccess);
          this.onSaveSuccess(this.HIC);
        }, error: this.alertService.showApiError
      });
  }

  cancelHardwareInterface() {
    this.hide({ shouldReload: false });
  }

}
