import { Component, Input, OnInit } from '@angular/core';
import { SalesProductNutrition } from '../../../menu-explorer/interfaces/sales-product-nutrition';

@Component({
  selector: 'pos-nutrition-facts',
  templateUrl: './nutrition-facts.component.html',
  styleUrls: ['./nutrition-facts.component.scss']
})
export class NutritionFactsComponent implements OnInit {

  @Input() nutritionFacts: SalesProductNutrition;
  
  ngOnInit(): void {  }

}
