import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';
import { OrderProductTransition } from '../interfaces/order-product-transition';
import { OrderProductWorkflow } from '../interfaces/order-product-workflow';

@Injectable({
    providedIn: 'root'
})
export class OrderProductWorkflowService extends BaseCrudService<OrderProductWorkflow> {
    constructor(protected httpService: HttpClient, private datePipe: DatePipe) {
        super('order-product-delivery-workflow', httpService);
    }

    getNewOrderProductTransition(orderProductId: number, state: string = null): OrderProductTransition {
        return {
            Id: null,
            OrderProductId: null,
            UserId: null,
            State: state,
            DateTime: null
        };
    }

    getAllByDate(complete: boolean, startDate: Date, endDate) {
        return this.httpService.get(this.apiEndpoint + `/startDate/${this.datePipe.transform(startDate, 'MM-dd-yyyy')}/endDate/${this.datePipe.transform(endDate, 'MM-dd-yyyy')}/completed/${complete}`);
    }

    getOrderProductTransitionHistory(orderProductId: number) {
        return this.httpService.get(this.apiEndpoint + `/${orderProductId}/history`);
    }

    getOrderShippingAddress(orderId: number) {
        return this.httpService.get(`${this.apiEndpoint}/${orderId}/shipping-address`);
    }

    updateOrderProductToNextStage(orderProductWorkflow: OrderProductWorkflow) {
        return this.httpService.post(this.apiEndpoint + `/${orderProductWorkflow.OrderProductId}`, orderProductWorkflow);
    }

    getOrderProductTransitionState(identifier: string) {
        return this.httpService.get(this.apiEndpoint + `/${identifier}/states`);
    }

    getWorkflowIdentifiers() {
        return this.httpService.get(this.apiEndpoint + '/identifiers');
    }
}
