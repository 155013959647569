import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { BaseFormComponent } from 'src/app/shared/components/base-form.component';
import { pickUpScreen } from 'src/app/shared/components/icon/icons';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { FormUtilityService } from 'src/app/shared/services/form-utility.service';
import { PickScreenWorkflowParameter } from '../../interfaces/pick-screen-workflow-parameter';
import { PickScreenWorkflowService } from '../../services/pick-screen-workflow.service';
import { PostSavedConfigurationService } from 'src/app/configurator/post-saved-configuration.service';

@Component({
  selector: 'pos-pick-screen-workflow',
  templateUrl: './pick-screen-workflow.component.html',
})
export class PickScreenWorkflowComponent extends BaseFormComponent implements OnInit {
  @ViewChild('pickScreenWorkflow') pickScreenWorkflow: NgForm;
  get getForm(): NgForm {
    return this.pickScreenWorkflow
  }
  pickScreenWorkflowParameters: PickScreenWorkflowParameter;
  icons = {
    pickUpScreen
  };
  constructor(
    private pickScreenWorkflowService: PickScreenWorkflowService,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private route: ActivatedRoute,
    private postSavedConfigurationService: PostSavedConfigurationService,
    private router: Router,
    formUtilityService: FormUtilityService) {
    super(formUtilityService);
  }

  ngOnInit(): void {
    this.pickScreenWorkflowParameters = this.pickScreenWorkflowService.newPickScreenWorkflowConfigurationParameter();
    this.loadData();
  }

  loadData() {
    this.spinnerService.show();
    this.pickScreenWorkflowService.getPickScreenWorkflowParameters().pipe(finalize(() => {
      this.spinnerService.hide()
    })).subscribe({
      next: (response) => {
        this.pickScreenWorkflowParameters = response;
      }, error: this.alertService.showApiError
    });
  }

  savePickScreenWorkflow(isValid) {
    if (!isValid) {
      return;
    }
    this.spinnerService.show();
    this.pickScreenWorkflowService.updatePickScreenWorkflowParameters(this.pickScreenWorkflowParameters).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (response) => {
        this.alertService.renderSuccessMessage(Messages.PickScreenConfigurationSaveSuccess);
        this.postSavedConfigurationService.showMessage();
      }, error: this.alertService.showApiError
    });
  }

  cancel() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

}
