import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'customDeepFilter' })
export class CustomDeepFilterComponent implements PipeTransform {
  transform(value: any[], searchText: string, propertyName?: string, propertyKey?: string): any[] {
    if (!value || !value.length) return [];
    if (!searchText) return value;
    var keys = Object.keys(value[0]);
    return value.filter(val => keys.some(key => {
      const currentValue = val[key];
      
      if (key === propertyName) {

        var x = [];
        currentValue?.forEach(element => {
          var arrayKeys = Object.keys(element);
          x = currentValue.filter(arrayVal => arrayKeys.some(arrayKey => {
            if (arrayKey === propertyKey) {
              const arrayValue = arrayVal[arrayKey];
              if (arrayValue) {
                return String(arrayValue).toLowerCase().includes(searchText.toLowerCase());
              }
            }
          }));
        });
         
        return x?.length;
      }
       return String(currentValue).toLowerCase().includes(searchText.toLowerCase());
    }));
  }

}
