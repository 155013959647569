import { Injectable } from '@angular/core';
import { BaseCrudService, DomainConstants } from 'src/app/shared';
import { WaitTimeAdjustmentRule } from '../interfaces';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { find } from 'lodash';
import { PromotionConfig } from 'src/app/shared/interface';

@Injectable({
  providedIn: 'root'
})
export class WaitTimeRuleService extends BaseCrudService<WaitTimeAdjustmentRule> {

  constructor(private httpClient: HttpClient) {
    super('min-wait-time', httpClient);
  }

  newWaitTimeRule(): WaitTimeAdjustmentRule {
    return {
      Id: 0,
      Name: '',
      RuleConfig: null,
      AdjustmentConfig: null,
      IsActive: true,
      Type: DomainConstants.WaitTimeRuleTypes.Global.Name
    };
  }

  getWaitTimeRuleTypeByRoute(route: string) {
    return find(DomainConstants.WaitTimeRuleTypes, x => x.RoutLink == route) ?? DomainConstants.WaitTimeRuleTypes.Global;
  }

  getRules(): Observable<Array<PromotionConfig>> {
    return this.httpClient.get<Array<PromotionConfig>>(`${this.apiEndpoint}/rules`);
  }

  getAdjustments(): Observable<Array<PromotionConfig>> {
    return this.httpClient.get<Array<PromotionConfig>>(`${this.apiEndpoint}/adjustments`);
  }
}
