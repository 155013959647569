import { Component, OnInit, ViewChild, Input } from '@angular/core';
import {
    AlertsService, Messages, SpinnerService, ConfirmDeleteComponent, AuthenticationService,
    Permissions, Levels, ApplicationStateService, ModalService, BaseGridComponent, GridContextInfo
} from 'src/app/shared';
import { GridColumn, EditColumn, DeleteColumn, LinkColumn, TableComponent } from '@tarktech/tark-ng-utils';
import { TrainingTopicLevel } from '../../interface/training-topic-level';
import { TrainingTopicLevelService } from '../../../../shared/services/training-topic-level.service';
import * as _ from 'lodash';
import { TrainingAreaService } from 'src/app/shared/services/training-area.service';
import { trainingMove } from './../../../../shared/components/icon/icons';
import { TrainingLevelMoveComponent } from '../training-level-move/training-level-move.component';
import { ActivatedRoute, Router } from '@angular/router';
declare let $: any;
@Component({
    selector: 'pos-training-topic-level-list',
    templateUrl: './training-topic-level-list.component.html',
    styleUrls: ['./training-topic-level-list.component.scss']
})
export class TrainingTopicLevelListComponent extends BaseGridComponent implements OnInit {
    trainingTopicTransParam: any;
    @ViewChild('confirmTrainingTopicLevelDelete') private confirmTrainingTopicLevelDelete: ConfirmDeleteComponent;
    @ViewChild('gridComponent', { static: true }) gridComponent: TableComponent;
    // @ViewChild('prerequisiteTextTemplate') private prerequisiteTextTemplate: any;
    trainingTopicLevels: Array<TrainingTopicLevel> = [];
    trainingTopicLevelColumns: Array<GridColumn> = [];
    trainingTopicLevel: TrainingTopicLevel;
    inFlightRequest = 0;
    @Input() topicId: number;
    @Input() areaId: number;
    @Input() areaName: string;
    @Input() topicName: string;
    public confirmDeleteMessage = Messages.ConfirmDeleteTrainingTopicLevel;

    permission = {
        name: Permissions.TrainingTopicLevels,
        readOnlyLevel: Levels.ReadOnly,
        editLevel: Levels.Edit
    };
    areaGridContext: GridContextInfo = null;
    topicGridContext: GridContextInfo = null;

    constructor(private alertService: AlertsService,
        private spinnerService: SpinnerService,
        private authenticationService: AuthenticationService,
        private trainingTopicLevelService: TrainingTopicLevelService,
        protected applicationStateService: ApplicationStateService,
        private router: Router,
        private trainingAreaService: TrainingAreaService,
        private modalService: ModalService,
        protected route: ActivatedRoute) {
        super(applicationStateService, route);
        const navigation = router.getCurrentNavigation();
        this.topicId = route.snapshot.params.topicId ? parseInt(route.snapshot.params.topicId, 10) : 0;
        this.areaId = route.snapshot.params.areaId ? parseInt(route.snapshot.params.areaId, 10) : 0;
        this.areaName = navigation?.extras?.state?.areaName ?? '';
        this.topicName = navigation?.extras?.state?.topicName ?? '';
        this.areaGridContext = navigation?.extras?.state?.AreaGridContext ?? this.areaGridContext;
        this.topicGridContext = navigation?.extras?.state?.TopicGridContext ?? this.topicGridContext;
        this.reloadTrainingTopicLevelList();
    }

    ngOnInit() {
        this.columnConfiguration();
        this.gridComponent.context = this.gridContext;
    }

    private columnConfiguration() {
        const editColumn = new EditColumn({
            Clicked: (data) => {
                $('html, body').animate({ scrollTop: 0 }, 'medium');
                this.editTrainingTopicLevel(data.Id);
            },
            Width: '102px',
            AddIcon: new LinkColumn({
                Clicked: () => {
                    $('html, body').animate({ scrollTop: 0 }, 'medium');
                    this.editTrainingTopicLevel(0);
                },
                ToolTip: 'New'
            })
        });

        const deleteColumn = new DeleteColumn({
            Clicked: (data) => {
                this.deleteTopicLevelButton(data);
            },
            Width: '0',
            CellClass: 'cell-delete pi-0'
        });

        const moveColumn = new LinkColumn({
            Clicked: (data) => {
                this.moveTrainingTopicLevel(data);
            },
            CellClass: 'cell-link training-gear-icon greyicon pi-0',
            Icon: trainingMove.iconClasses + ' font-17em',
            ToolTip: 'Move',
            Width: '0'
        });

        // const prerequisiteText = new TemplateColumn({
        //     HeaderText: 'Instructional Text',
        //     itemTemplate: this.prerequisiteTextTemplate,
        //     Width: '20%'
        //   });

        this.trainingTopicLevelColumns = [
            new GridColumn({ HeaderText: 'Description', Field: 'Description', IsSortable: true, Width: '25%' }),
            // new GridColumn({ HeaderText: 'Instructional Text', Field: 'PrerequisiteText', IsSortable: true, Width: '20%' }),
            // prerequisiteText,
            new GridColumn({ HeaderText: 'Video URL', Field: 'VideoUrl', IsSortable: true, Width: '25%' }),
            new GridColumn({ HeaderText: 'Level Score', Field: 'LevelScore', IsSortable: true, Width: '10%' }),
            new GridColumn({ HeaderText: 'Succession Limit Delay Minutes', Field: 'SuccessionLimitDelayMinutes', IsSortable: true, Width: '20%' }),
            new GridColumn({ HeaderText: 'Level Number', Field: 'LevelNumber', IsSortable: true, Width: '10%' })
        ] as Array<GridColumn>;

        if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
            this.trainingTopicLevelColumns.push(editColumn);
            this.trainingTopicLevelColumns.push(deleteColumn);
        }

        this.trainingTopicLevelColumns.push(moveColumn);

    }

    reloadTrainingTopicLevelList() {
        this.spinnerService.show();
        this.trainingTopicLevelService.getTrainingTopicLevelsByTopicId(this.topicId).subscribe(
            response => {
                if (!this.topicName && response && response.length && response[0].TrainingTopic) {
                    this.topicName = response[0].TrainingTopic.Name;
                    if (!this.topicId) {
                        this.topicId = response[0].TrainingTopic.Id;
                    }
                    if (!this.areaId) {
                        this.areaId = response[0].TrainingTopic.TrainingAreaId;
                    }
                }

                if (!this.areaName && this.areaId) {
                    this.trainingAreaService.getTrainingArea(this.areaId)
                        .subscribe({
                            next: (area) => {
                                this.areaName = area.Name;
                            }, error: this.alertService.showApiError
                        });
                }
                if (response) {
                    this.trainingTopicLevels = response;
                } else {
                    this.trainingTopicLevels = [];
                }
            }, this.alertService.showApiError,
            () => {
                this.spinnerService.hide();
            }
        );
    }

    gridRowReorder(event) {
        this.inFlightRequest++;
        this.trainingTopicLevelService.updateLevelNumber(this.trainingTopicLevels).subscribe(
            (response) => {
                this.inFlightRequest = this.inFlightRequest > 0 ? this.inFlightRequest - 1 : 0;
                if (this.inFlightRequest === 0) {
                    this.trainingTopicLevels = response;
                }
            },
            (err) => {
                this.inFlightRequest = this.inFlightRequest > 0 ? this.inFlightRequest - 1 : 0;
                this.alertService.showApiError(err);
            },
            () => {
            }
        );
    }

    deleteTopicLevelButton($event: TrainingTopicLevel) {
        this.trainingTopicLevel = $event;
        this.confirmTrainingTopicLevelDelete.Show(this.confirmDeleteMessage);
    }

    onConfirmAcknowledge() {
        this.spinnerService.show();
        this.trainingTopicLevelService.deleteTrainingTopicLevel(
            this.trainingTopicLevel.Id, this.applicationStateService.userDetails.id).subscribe(
                () => {
                    this.alertService.renderSuccessMessage(
                        Messages.TrainingTopicLevelDeleteSuccess
                    );
                    this.reloadTrainingTopicLevelList();
                },
                this.alertService.showApiError,
                () => {
                    this.spinnerService.hide();
                }
            );
    }

    editTrainingTopicLevel(topicLevelId: number) {
        this.router.navigate([topicLevelId],
            {
                state: {
                    levelId: topicLevelId, topicId: this.topicId, areaId: this.areaId, areaName: this.areaName,
                    topicName: this.topicName, AreaGridContext: this.areaGridContext, TopicGridContext: this.topicGridContext
                }, relativeTo: this.route
            });
    }
    onCancel() {
        this.router.navigate(['training-areas', this.areaId, 'topics'],
            {
                state: { areaId: this.areaId, areaName: this.areaName, topicName: this.topicName, GridContext: this.areaGridContext, TopicGridContext: this.topicGridContext },
                relativeTo: this.route.parent
            });
    }

    moveTrainingTopicLevel(level) {
        const modalRef = this.modalService.show(TrainingLevelMoveComponent, {
            animated: false,
            class: 'vertical-center',
            initialState: {
                trainingTopicId: this.topicId,
                trainingAreaId: this.areaId,
                trainingLevelId: level.Id,
                trainingTopicName: this.topicName,
                trainingAreaName: this.areaName,
                trainingLevelName: level.Description
            }
        });

        modalRef.close.subscribe(res => {
            if (res?.shouldReload) {
                this.reloadTrainingTopicLevelList();
            }
        });
    }
}
