<div class="page-content">
  <div class="tab-content">
    <div class="tab-pane fade in active show">
      <div class="col-12 p-0">
        <a class="close pe-pix-20" (click)="onCancel()">×</a>
        <div class="col-10 p-0 display-flex ps-pix-20">
          <h2 class="page-content__header">
            Training Topic
            <h3><b><span class="header-lightgrey pointer" (click)="onCancel()">Area:
                  {{areaName}}</span></b></h3>
          </h2>
        </div>
        <form #formTrainingTopic="ngForm" (ngSubmit)="saveTrainingTopic(formTrainingTopic.valid)" autocomplete="off">
          <div class="col-12 pos-page-body border-top">
            <div class="col-12">
              <context-provider provide="props">
              <pos-row>
                <pos-column>
                  <pos-form-field label="Name" [validationMessages]="{required: 'Please enter topic name.'}">
                    <input posInput type="text" id="topicName" name="topicName" #topicName="ngModel" class="form-control"
                      [(ngModel)]="topic.Name" [required]="true" />
                  </pos-form-field>
                </pos-column>

                <pos-column>
                  <pos-form-field label="Description" [validationMessages]="{required: 'Please enter topic description.'}">
                    <input posInput type="text" id="topicDescription" name="topicDescription" #topicDescription="ngModel"
                      class="form-control" [(ngModel)]="topic.Description" [required]="true" />
                  </pos-form-field>
                </pos-column>

                <pos-column>
                  <pos-form-field label="Role" [validationMessages]="{required: 'Please enter role.'}">
                    <ng-select posInput name="role" (remove)="onRoleRemove($event)" [items]="roles" [multiple]="true" [hideSelected]="true"
                      bindLabel="Name" bindValue="Id" placeholder="Select role" [(ngModel)]="topic.TopicsRoles" [required]="true">
                    </ng-select>
                  </pos-form-field>
                </pos-column>
              </pos-row>
              </context-provider>
            </div>
          </div>
          <div class="col-12 action-button-container page-footer">
            <div class="form-horizontal">
              <button type="submit" value="Save" class="btn btn-primary btn-lg white btn-save pos-button" scrollup>Save</button>
              <button type="button" class="btn btn-secondary btn-lg btn-cancel" value="Cancel pos-button" (click)="onCancel()"
                scrollup>Cancel</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

