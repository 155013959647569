<pos-list-wrapper [headingIcon]="icons.music"
  [heading]=" !isEditTrack ? 'Tracks':'Add tracks to '+playlistName +' playlist'" (close)="close()">
  <div class="row pt-pix-5">
    <div class="col-12 ">
      <ng-table #tracks [columns]="trackListColumns" [data]="tracksList">
      </ng-table>
      <ng-template let-data="rowData" #checkboxTemplate>
        <p-checkbox name="addTrack" #addTrack="ngModel" (onChange)="getSelectedState()" [(ngModel)]="data.IsChecked"
          binary="true">
        </p-checkbox>
      </ng-template>
      <ng-template let-data="rowData" #operationHeaderTemplate>
        <div class="text-center" tooltip title="Add">
          <pos-action-icon (click)="editItem(0)" [icon]="icons.plusWhite" iconText="Add" scrollup></pos-action-icon>
        </div>
      </ng-template>
      <ng-template let-data="rowData" #operationTemplate>
        <div class="text-center">
          <span (click)="editItem(data.Id, data)" tooltip title="Edit" scrollup>
            <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
          </span>
          <span class="ps-pix-3" (click)="deleteTrack(data)" tooltip title="Delete">
            <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
          </span>
          <div class="ps-pix-3 inline-block" (click)="playAudio(data)">
            <pos-action-icon [icon]="icons.playGreenNormalFont" *ngIf="!data.IsPlaying"></pos-action-icon>
            <pos-action-icon [icon]="icons.pauseGreen" *ngIf="data.IsPlaying"></pos-action-icon>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <div *ngIf="isEditTrack">
    <div class="col-12 action-button-container mt-pix-10 border-top-0-important">
      <div class="form-horizontal">
        <button class="btn-lg btn-primary white btn normal me-pix-5 pos-button" scrollup (click)="addTracks()"
          type="submit">
          Add Track(s)
        </button>
        <button class="btn-secondary btn-lg btn normal pos-button" (click)="close()" scrollup type="button">
          Cancel
        </button>
      </div>
    </div>
  </div>
</pos-list-wrapper>
