<pos-list-wrapper [headingIcon]="icons.camera" heading="Camera Annotations" (close)="close()">
    <div class="col-12 position-relative">
        <div class="label-section active-label">
            <div>
                <p-checkbox name="chkActive" #chkActive="ngModel" (onChange)="changeCameraAnnotationList()"
                    [(ngModel)]="showInactive" binary="true" label="Show Inactive" labelStyleClass="font-weight-600" />
            </div>
        </div>
    </div>
    <div class="col-12">
        <ng-table #cameraGrid [columns]="cameraAnnotationColumns" [data]="cameraAnnotations" sortField="Description">
        </ng-table>

        <ng-template let-data="rowData" #operationTemplate>
            <div class="d-flex justify-content-center">
                <span  (click)="editItem(data)" scrollup tooltip title="Edit">
                    <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
                </span>
                <span *ngIf="(data.IsActive)"  (click)="updateCameraAnnotationStatus(data, false)" tooltip
                    title="Click here to deactivate this annotation">
                    <pos-action-icon [icon]="icons.ban"></pos-action-icon>
                </span>
                <span *ngIf="!(data.IsActive)"  (click)="updateCameraAnnotationStatus(data, true)" tooltip
                    title="Click here to activate this annotation">
                    <pos-action-icon [icon]="icons.checkCircleWhite"></pos-action-icon>
                </span>
            </div>
        </ng-template>

        <ng-template #headerTemplate>
            <div class="d-flex justify-content-center">
                <pos-export-dropdown class="inline-block" (onPrint)="printData()"
                    (onCSV)="exportCSV()">
                </pos-export-dropdown>
            </div>
        </ng-template>

        <ng-template let-data="rowData" #activeTemplate>
            <span *ngIf="(data.IsActive)" class="green text-center vertical-mid font-13">
                <pos-icon [icon]="icons.check"></pos-icon>
            </span>
        </ng-template>
    </div>
</pos-list-wrapper>
<pos-print-table #printTableReference [hidden]="true"></pos-print-table>
