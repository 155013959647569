import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { DietaryWarning, DietaryWarningType, InventoryProductDietaryWarning } from '../../information-management/dietary-warnings/interface/inventory-product-dietary-warnings';

@Injectable()

export class InventoryProductDietaryWarningService {

    constructor(private httpService: HttpClient) {
    }

    newInventoryProductDietaryWarning(inventoryProductId) {
        const inventoryProductDietaryWarningObj = {
            Id: 0,
            InventoryProductId: inventoryProductId,
            DietaryWarningId: null,
            DietaryWarningTypeId: null,
            DietaryWarning: {
                Id: 0, Name: null, DateAdded: null,
                DateEdited: null
            },
            DietaryWarningType: {
                Id: 0, Name: null, DateAdded: null,
                DateEdited: null
            },
            DateAdded: null,
            DateEdited: null
        };
        return inventoryProductDietaryWarningObj;
    }

    getDietaryWarnings(): Observable<DietaryWarning[]> {
        return this.httpService.get<DietaryWarning[]>(`${RuntimeConstants.API_PATH}dietarywarning`);
    }
    getDietaryWarningTypes(): Observable<DietaryWarningType[]> {
        return this.httpService.get<DietaryWarningType[]>(`${RuntimeConstants.API_PATH}dietarywarningtype`);
    }
    getDietaryWarningsByInventoryProductId(inventoryProductId: number): Observable<InventoryProductDietaryWarning[]> {
        return this.httpService.get<InventoryProductDietaryWarning[]>
            (`${RuntimeConstants.API_PATH}ingredientsdietarywarning/dietarywarnings/` + inventoryProductId);
    }
    deleteDietaryWarning(inventoryProductDietaryWarningId: number): Observable<any> {
        return this.httpService.delete(
            `${RuntimeConstants.API_PATH}ingredientsdietarywarning/` + inventoryProductDietaryWarningId
        );
    }
}
