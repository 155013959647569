import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseCrudService } from 'src/app/shared/services';
import { Rule } from '../interface/report-rules';
import { ReportSettingModel } from '../interface/report-settings-model';

@Injectable({
    providedIn: 'root'
})
export class ReportColumnSettingsService extends BaseCrudService<Rule> {
    constructor(private httpClient: HttpClient) {
        super('report-column-settings', httpClient);
    }

    newReportSettings() {
        const reportSettings: ReportSettingModel = {
            ColumnName: null,
            Align: null,
            AlignId: 0,
            Format: null,
            FormatId: 0,
            Total: false,
            TotalId: 0,
        };
        return reportSettings;
    }

    getReportSettings(reportId: number) {
        return this.httpClient.get(`${this.apiEndpoint}/${reportId}`);
    }

    newReportGroup() {
        return {
            GroupField: '',
            Color: '',
          }
    }
}
