<div class="overflow-auto-tabs" style="width:100%">
  <!-- form-group  -->
  <form (ngSubmit)="addAlias(salesProductAlias)" autocomplete="off">
    <div class="row">
      <div class="col-12 ms-pix-3">
        <pos-form-field label="Alias" inputStyle="col-md-7 col-12">
          <div class="row row-cols-2">
            <div>
              <input posInput type="text" id="salesProductAlias" name="salesProductAlias" class="form-control"
              [(ngModel)]="salesProductAlias.AliasName" />
            </div>
            <div>
              <button class="btn btn-secondary normal pos-button vertical-center-align" type="submit"
                [disabled]="!(salesProductAlias.AliasName != null && salesProductAlias.AliasName != '')">
                <pos-icon [icon]="icons.normalPlus"></pos-icon> <span class="ps-1">Add</span>
              </button>
            </div>
          </div>
        </pos-form-field>
      </div>
    </div>
  </form>
  <div class="col-xl-5 col-md-7 col-12 pt-pix-10 ms-pix-3">
    <ng-table #gridComponent [search]="false" [sort]="false" [paginator]="false" [columns]="salesProductAliasesColumn"
      [data]="salesProductAliases">
    </ng-table>
  </div>
</div>
