import { NgModule } from '@angular/core';
import { VillainsComponent } from './villains/villains.component';
import { ComponentsModule } from '../shared/components/components.module';
import { FormsModule } from '../shared/components/forms/forms.module';
import { RouterModule, Routes } from '@angular/router';
import { HerosComponent } from './heros/heros.component';
import { CommonModule } from '@angular/common';

const routes: Routes = [
    {
        path: '',
        component: HerosComponent
    },
    {
        path: '1',
        component: VillainsComponent
    }
];

@NgModule({
    imports: [
        CommonModule,
        ComponentsModule,
        FormsModule,
        RouterModule.forChild(routes)
    ],
    declarations: [VillainsComponent, HerosComponent],
    exports: [
        // HerosComponent,
        VillainsComponent
    ]
})
export class ViewsModule { }
