<pos-edit-wrapper heading="{{ playlist.Id > 0 ? 'Edit' : 'Add' }} Playlist" (close)="cancel()"
  [headingIcon]="icons.playListThList" [isPopup]="false">
  <form (ngSubmit)="savePlaylist(playlistForm.valid)" #playlistForm="ngForm" class="edit-form">
    <context-provider provide="props">
      <div class="col-12 pos-page-body border-top pt-pix-5">
        <div class="col-12">
          <pos-row>
            <pos-column>
              <pos-form-field label="Name" [fieldInfo]="fieldInfoMessages.PlayListName"
                [validationMessages]="{required: 'Please enter name.'}">
                <input posInput class="form-control" name="Name" [(ngModel)]="playlist.Name" type="text" name="name"
                  [required]="true" #name="ngModel" />
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field>
                <p-checkbox posInput name="chkIsUserSchemeVolume" #chkIsUserSchemeVolume="ngModel"
                  label="Use scheme volume" [(ngModel)]="isUserSchemeVolume" binary="true"
                  (onChange)="onChangeIsUserSchemeVolume()">
                </p-checkbox>
              </pos-form-field>
            </pos-column>
            <pos-column *ngIf="!isUserSchemeVolume">
              <pos-form-field label="Volume">
                <pos-row>
                  <pos-column xs="11" >
                    <ngx-slider [(value)]="playlist.Volume" [options]="options" class="m-0"></ngx-slider>
                  </pos-column>
                  <pos-column xs="1" class="p-0">
                    <span class="badge mt-pix-n2 volume-circle">{{playlist.Volume ? playlist.Volume : 0}}</span>
                  </pos-column>
                </pos-row>
              </pos-form-field>
            </pos-column>
          </pos-row>
        </div>
      </div>
      <div class="col-12 action-button-container page-footer">
        <div class="form-horizontal">
          <button class="btn btn-primary btn-lg btn-save pos-button" scrollup type="submit" value="Save">Save</button>
          <button class="btn btn-secondary btn-lg btn-cancel pos-button" routerLink="../" scrollup type="button">
            Cancel
          </button>
        </div>
      </div>
    </context-provider>
  </form>
</pos-edit-wrapper>
