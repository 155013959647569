import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { AuthenticationResponse } from '../models/authentication-response.model';
import { RuntimeConstants } from '../../constants';
import { ApplicationStateService } from '../../services/application-state.service';
import { DomainConstants } from '../../constants/domain-constants';
import { tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { ChildStatePermissionConstants } from '../../constants/child-state=permission-constants';


@Injectable()
export class AuthenticationService {
  constructor(
    private http: HttpClient,
    private applicationStateService: ApplicationStateService
  ) { }

  public authenticate(password: string, UserName: string, externalPassword: string): Observable<AuthenticationResponse> {
    return this.http
      .post<AuthenticationResponse>(`${RuntimeConstants.API_PATH}auth`, {
        Code: password,
        Username: UserName,
        ExternalPassword: externalPassword
      });
  }

  public getLocalIpAddress(): Observable<Array<string>> {
    return this.http.get<Array<string>>(`${RuntimeConstants.API_PATH}auth/ip`);
  }

  getAuthenticationToken(userCode: any, externalPassword: any, userName: string): Observable<AuthenticationResponse> {
    if (externalPassword) {
      this.applicationStateService.externalPassword = externalPassword;
    }
    if (userName) {
      this.applicationStateService.username = userName;
    }
    if (userCode) {
      this.applicationStateService.userCode = userCode;
    } else {
      userCode = this.applicationStateService.userCode;
      if (!userCode) {
        return throwError('no-cached-credentials');
      }
    }

    return this.authenticate(userCode, userName, externalPassword)
      .pipe(tap(response => {
        this.applicationStateService.authTokenInfo = response;
        this.applicationStateService.isAuthenticated = true;
      }));
  }


  userHasPermission(permissions, grantIf): boolean {
    if (!grantIf) {
      grantIf = "any";
    }
    if (!this.isLoggedIn()) {
      return false;
    }

    var found = grantIf === "all";

    if (!permissions.length) {
      return true;
    }
    permissions.forEach(permission => {
      let existingPermission = _.find(this.applicationStateService.userPermissions.Permissions, (userPermission) => {
        return userPermission.Permission.Id == permission.Name && userPermission.PermissionLevel >= this.getPermissionLevel(permission.Level);
      });
      if (grantIf === "all" && (!existingPermission)) {
        found = false;
        return;
      }
      else if (grantIf === "any" && existingPermission) {
        found = true;
        return;
      }
    });
    return found;
  }


  getPermissionLevel(permissionLevel): number {
    let currentPermission = _.find(DomainConstants.PermissionLevels, (level) => {
      return level.key == permissionLevel;
    });
    if (currentPermission) {
      return currentPermission.value;
    }
    else {
      return DomainConstants.PermissionLevels.LEVEL.value;
    }
  }

  isLoggedIn(): boolean {
    let user = this.applicationStateService.userDetails;
    return user != null;
  }

  userHasPermissionForView(view: any, toParams: any): boolean {
    if (!this.isLoggedIn()) {
      return false;
    }

    let permissions
    if(view.isDynamicChild && ChildStatePermissionConstants[toParams.childPage])
    {
      permissions = ChildStatePermissionConstants[toParams.childPage].permissions
    }
    else {
      if(view.permissions) 
      {
        permissions = view.permissions
      } 
      else if(view.params && view.params.permissions)
      { 
        permissions = view.params.permissions;
      }
    }

    if (!permissions || !permissions.length) {
      return true;
    }

    return this.userHasPermission(permissions, null);
  }

  checkPermissionForView = function (view: any, toParams: any): boolean {
    if (view.allowAnonymous) {
      return true;
    }
    return this.userHasPermissionForView(view, toParams);
  }

  GetAuthenticationTokenForCardBasedLogin (userCode: any, userName: string, externalPassword: any, isSwitchUser: boolean = true): Observable<AuthenticationResponse> {
    let authModel = {
      Code: userCode,
      Username: userName,
      ExternalPassword: externalPassword
    }
    let cardBasedAuth = this.http.post<any>(`${RuntimeConstants.API_PATH}auth/by-card`, authModel);

     cardBasedAuth.subscribe(
      (res) => {
        if (isSwitchUser) {
          if (res && res.Password) {
            this.applicationStateService.userCode = res.Password;
          }
          this.applicationStateService.authTokenInfo = res;
          this.applicationStateService.isAuthenticated = true;
        }
      }, (error) => {
      });

      return cardBasedAuth;
  }
}
