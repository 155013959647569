import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { cloneDeep, filter, find, forEach, remove } from 'lodash';
import { finalize } from 'rxjs/operators';
import { AlertsService, BaseCrudModelComponent, FormUtilityService, InventoryProductDetails, InventoryProductService, Messages, SpinnerService } from 'src/app/shared';
import { InventoryVendorProfileProduct } from '../../interface';
import { InventoryVendorProfileProductService } from '../../service/inventory-vendor-profile-product.service';

@Component({
  selector: 'pos-inventory-vendor-profile-product-edit',
  templateUrl: './inventory-vendor-profile-product-edit.component.html',
  styleUrls: ['./inventory-vendor-profile-product-edit.component.scss']
})
export class InventoryVendorProfileProductEditComponent extends BaseCrudModelComponent<InventoryVendorProfileProduct> implements OnInit {

  id = 0;
  vendorId = 0;
  profileId = 0;
  vendorName = null;
  profileName = null;
  vendorProfileProducts: Array<InventoryVendorProfileProduct> = [];
  profileProduct: InventoryVendorProfileProduct;
  searchText = null;
  isCheckAllProfileProducts = false;
  inventoryProducts: Array<InventoryProductDetails> = [];
  inventoryProductsCopy: Array<InventoryProductDetails> = [];
  @ViewChild('profileProductForm') profileProductForm: NgForm;
  get getForm(): NgForm {
    return this.profileProductForm
  }

  constructor(protected spinnerService: SpinnerService,
    protected inventoryVendorProfileProductService: InventoryVendorProfileProductService,
    protected inventoryProductService: InventoryProductService,
    protected alertService: AlertsService,
    formUtilityService: FormUtilityService) {
    super(inventoryVendorProfileProductService, alertService, spinnerService, formUtilityService);
    this.profileProduct = inventoryVendorProfileProductService.newVendorProfileProduct();
  }

  ngOnInit(): void {
    this.dataSubscription();
    this.saveSuccessMessage = Messages.InventoryVendorProfileProductSaveSuccess;
  }

  private dataSubscription(): void {
    if (this.id > 0) {
      this.spinnerService.show();
      this.loadData().pipe(finalize(() => {
        this.spinnerService.hide();
      }))
        .subscribe({
          next: (res) => {
            this.profileProduct = res;
          }, error: this.alertService.showApiError
        });
    } else if (!this.profileProduct.InventoryProductId) {
      this.spinnerService.show();
      this.inventoryProductService.getActiveInventoryProducts(true)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res: Array<InventoryProductDetails>) => {
            this.inventoryProducts = filter(res, x => x.VendorId === this.vendorId);
            this.vendorName = !this.vendorName && this.vendorProfileProducts.length ?
              this.vendorProfileProducts[0].InventoryVendorProfile?.InventoryVendor?.Name : this.vendorName;
            this.profileName = !this.profileName && this.vendorProfileProducts.length ?
              this.vendorProfileProducts[0].InventoryVendorProfile?.Name : this.profileName;
            forEach(this.vendorProfileProducts, existingProduct => {
              remove(this.inventoryProducts, product => product.Id === existingProduct.InventoryProductId);
            });
            this.inventoryProductsCopy = cloneDeep(this.inventoryProducts);
          }, error: this.alertService.showApiError
        });
    }
  }

  saveProfileProduct(isValid: boolean) {
    if (!isValid) {
      return;
    }
    if (this.profileProduct && this.profileProduct.MinLevel !== this.profileProduct.MaxLevel
      && this.profileProduct.MinLevel > this.profileProduct.MaxLevel) {
      this.alertService.renderErrorMessage(Messages.MinLevelMustLessThanMaxLevel);
      return;
    }
    if (this.id > 0) {
      this.profileProduct.InventoryProduct = null;
      this.save(this.profileProduct);
    } else {
      this.saveMultipleProfileProducts();
    }
  }

  saveMultipleProfileProducts() {
    const profileProducts: Array<InventoryVendorProfileProduct> = [];
    let productInvalid = false;
    let isValid = true;
    forEach(this.inventoryProducts, product => {
      if (product.IsProfileProduct) {
        const newProduct: InventoryVendorProfileProduct = {
          Id: 0,
          InventoryVendorProfileId: this.profileId,
          InventoryProduct: find(this.inventoryProducts, x => x.Id === product.Id),
          InventoryProductId: product.Id,
          MinLevel: product.Min,
          MaxLevel: product.Max,
          InventoryVendorProfile: null
        };
        if (newProduct.MinLevel !== newProduct.MaxLevel && (newProduct.MinLevel == null || newProduct.MaxLevel == null)) {
          isValid = false;
        }
        if (newProduct.MinLevel > newProduct.MaxLevel) {
          productInvalid = true;
        }
        profileProducts.push(newProduct);
      }
    });
    if (!isValid) {
      return;
    }
    if (productInvalid) {
      this.alertService.renderErrorMessage(Messages.MinLevelMustLessThanMaxLevel);
      return;
    }
    if (this.profileId) {
      profileProducts.forEach(x => x.InventoryProduct = null);
      this.spinnerService.show();
      this.inventoryVendorProfileProductService.updateMultipleProfileProducts(profileProducts)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res) => {
            this.alertService.renderSuccessMessage(Messages.InventoryVendorProfileProductSaveSuccess);
            this.hide({ shouldReload: true });
          }, error: this.alertService.showApiError
        });
    } else {
      this.hide({ profileProducts: profileProducts, profileProduct: this.profileProduct });
    }
  }

  cancel() {
    this.hide();
  }

  checkAllProfileProducts() {
    if (this.isCheckAllProfileProducts) {
      this.inventoryProducts.forEach(ip => ip.IsProfileProduct = true);
    } else {
      this.inventoryProducts.forEach(ip => ip.IsProfileProduct = false);
    }
  }

  filterInventoryProducts() {
    this.isCheckAllProfileProducts = false;
    if (this.searchText) {
      this.inventoryProductsCopy.forEach(x => x.IsProfileProduct = find(this.inventoryProducts, p => p.Id === x.Id) ?
        find(this.inventoryProducts, p => p.Id === x.Id).IsProfileProduct : x.IsProfileProduct);
      this.inventoryProducts = this.inventoryProductsCopy.filter(x => String(x.Name).toLowerCase().includes(this.searchText.toLowerCase())
        || String(x.UnitName).toLowerCase().includes(this.searchText.toLowerCase())
        || String(x.Min).toLowerCase().includes(this.searchText.toLowerCase())
        || String(x.Max).toLowerCase().includes(this.searchText.toLowerCase()));
    } else {
      this.inventoryProductsCopy.forEach(x => x.IsProfileProduct = find(this.inventoryProducts, p => p.Id === x.Id) ?
        find(this.inventoryProducts, p => p.Id === x.Id).IsProfileProduct : x.IsProfileProduct);
      this.inventoryProducts = cloneDeep(this.inventoryProductsCopy);
    }
  }

  onSaveSuccess(model: InventoryVendorProfileProduct): void {
    this.hide({ shouldReload: true });
  }

  loadDependencies(): void { }

}
