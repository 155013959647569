<pos-list-wrapper [headingIcon]="icons.layerGroup" heading="Report Groups" (close)="close()">
    <div class="row">
        <div class="col-md-12 col-sm-12">
            <ng-table #reportGroups [search]="true" [sort]="true" [paginator]="true"
                [exportFilename]="'report-groups'" [columns]="reportGroupColumns" [data]="reportGroup">
            </ng-table>
            <ng-template let-data="rowData" #IconTemplate>
                <fa-icon class="{{data.ReportIcon}} black"></fa-icon>
            </ng-template>
        </div>
    </div>
</pos-list-wrapper>
<pos-print-table [hidden]="true"></pos-print-table>
