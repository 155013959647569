import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { Observable } from 'rxjs';
import { SalesSize } from '../../information-management/sales-sizes/interface/sales-size';
import { SalesProductSize } from 'src/app/information-management/sales-products/interface/sales-product-size';

@Injectable()
export class SalesSizeService {
  constructor(private httpService: HttpClient) { }

  newSalesSize() {
    const salesSizeObj: SalesSize = {
      Id: 0,
      Name: '',
      CliCodes: '',
      MakeTableSize: '',
      Ordinal: null,
      IsDirectLinkToInventoryProduct: false,
      SalesProductId: 0,
      SizeId: 0
    };
    return salesSizeObj;
  }

  getSalesSizes(): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}salessizes`
    );
  }

  getSalesSize(sizeId: number): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}salessizes/` + sizeId
    );
  }

  addSalesSize(salesSize: SalesSize): Observable<any> {
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}salessizes`,
      salesSize
    );
  }

  updateSalesSize(salesSize: SalesSize): Observable<any> {
    return this.httpService.put(
      `${RuntimeConstants.API_PATH}salessizes/` + salesSize.Id,
      salesSize
    );
  }

  deleteSalesSize(sizeId: number): Observable<any> {
    return this.httpService.delete(
      `${RuntimeConstants.API_PATH}salessizes/` + sizeId
    );
  }
  updateSalesSizeOrdinal(salesSize: SalesSize): Observable<any> {
    return this.httpService.put(
      `${RuntimeConstants.API_PATH}salessizes/ordinal`,
      salesSize
    );
  }

  getSalesProductSizes(salesCategoryId): Observable<Array<SalesProductSize>> {
    return this.httpService.get<Array<SalesProductSize>>(
      `${RuntimeConstants.API_PATH}salesproducts/sizes/` + salesCategoryId
    );
  }
}
