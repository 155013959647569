import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApplicationStateService, CameraAnnotationService, DomainConstants } from 'src/app/shared';
import { StringUtils } from 'src/app/shared/string-utils';

@Component({
  selector: 'pos-clock-in-status',
  templateUrl: './clock-in-status.component.html',
})
export class ClockInStatusComponent implements OnInit {

  constructor(
    private applicationStateService: ApplicationStateService,
    private cameraAnnotationService: CameraAnnotationService
  ) { }
  isSwipedCard: boolean;
  userClockInStatusMessage: string;
  clockInButtonText: string;
  clockedInUsername: string;
  @Output() close: EventEmitter<any> = new EventEmitter();
  ngOnInit(): void {
  }

  clockedInOutToSystem() {
    let cameraAnnotationsText = this.applicationStateService.cameraAnnotations.ClockIn;
    if (this.clockInButtonText === DomainConstants.ClockInOut.CLOCK_OUT) {
      cameraAnnotationsText = this.applicationStateService.cameraAnnotations.ClockOut;
    }
    this.cameraAnnotationService.addAnnotationToCamera(
      cameraAnnotationsText,
      {
        TimekeepingUser: this.clockedInUsername
      }
    );
    this.close.emit({isChangeClockInState: true, isSwipedCard: this.isSwipedCard});
  }

  hide() {
    this.close.emit();
  }
}
