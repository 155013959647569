<pos-edit-wrapper heading="General" [headingIcon]="icons.cogs" (close)="cancelEditing()" [isPopup]="false">
  <form #configForm="ngForm" class="edit-form" (ngSubmit)="saveConfig(configForm.valid)">
    <div class="col-12 pos-page-body border-top">
      <div class="col-12">
        <pos-row>
          <pos-column>
            <pos-form-field inputStyle="col-12"
              [validationMessages]="{required: 'Please enter minutes for removing unpaid kiosk orders.'}">
              <pos-row colXs="3">
                <pos-column class="col-250">
                  <label class="edit-form__section__label">Remove unpaid kiosk orders after</label>
                </pos-column>
                <pos-column xs="3" class="col-130">
                  <input posInput class="form-control col-120" name="intervalMinutes"
                    [(ngModel)]="parameters.OrderKioskRemovePendingOrderIntervalMinutes" [required]="true" type="number"
                    #intervalMinutes="ngModel" />
                </pos-column>
                <pos-column class="no-wrap">
                  <label class="edit-form__section__label">mins</label>
                </pos-column>
              </pos-row>
            </pos-form-field>
          </pos-column>
          <pos-column>
            <pos-form-field label="Out of service image">
              <div class="row">
                <div class="col-5">
                  <input posInput type="file" class="form-control" accept=".png,.jpg,.jpeg,.bmp"
                    name="orderKioskIsDisabledImage" id="image" (change)="handleFileInput($event.target.files)"
                    ngModel />
                </div>
                <div class="col-md-1 col-3 p-0 col-50 mt-pix-n1" [hidden]="!this.parameters.OrderKioskIsDisabledImage">
                  <img class="icon" src="{{ImagePath}}/{{this.parameters.OrderKioskIsDisabledImage}}"
                    #imagePreview id="imagePreview" alt=""/>
                  <div class="relative float-right mt-pix-n5" (click)="removeIcon()">
                    <pos-icon [icon]="icons.times" class="pointer ms-pix-n7 remove-icon-badge"></pos-icon>
                  </div>
                </div>
              </div>
            </pos-form-field>
          </pos-column>
        </pos-row>
        <div class="col-12 section-border">
          <div>
            <h3 class="setting-title mt-pix-5">Title Message Config</h3>
          </div>
          <pos-row class="pos-title-content" colLg="6" colXs="12">
            <pos-column>
              <pos-form-field label="Text">
                <input posInput name="text" #text="ngModel" class="form-control"
                  [(ngModel)]="messageStripConfig.Text" />
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field label="Font size">
                <input posInput name="fontSize" #fontSize="ngModel" class="form-control col-120"
                  [(ngModel)]="messageStripConfig.FontSize" />
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field label="Background Color">
                <pos-color-picker posInput name="backgroundColor"
                  [(ngModel)]="messageStripConfig.BackgroundColor"></pos-color-picker>
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field label="Font Color">
                <pos-color-picker posInput name="fontColor"
                  [(ngModel)]="messageStripConfig.FontColor"></pos-color-picker>
              </pos-form-field>
            </pos-column>
          </pos-row>
        </div>
      </div>
    </div>
    <div class="col-xs-12 action-button-container page-footer">
      <div class="form-horizontal">
        <button class="btn btn-primary btn-lg btn-save pos-button" scrollup type="submit" value="Save">Save</button>
        <button class="btn btn-secondary btn-lg btn-cancel pos-button" routerLink="../" scrollup type="button"
          value="Cancel">Cancel</button>
      </div>
    </div>
  </form>
</pos-edit-wrapper>
