<div class="row mx-0 p-0">
  <form #formSeatSplit="ngForm" class="p-0">
    <div class="row mx-0">
      <div class="col-md-3 col-2 ps-pix-5 mt-1">
        <label class="edit-form__section__label">{{currentBalance | tark_currency}}</label>
      </div>
      <div class="col-52 pull-left p-0 mt-1">
        <label class="edit-form__section__label">Split by </label>
      </div>
      <div class="col-5 mt-1"
        [ngClass]="{'has-error': (seatSplit.invalid || hasFraction || noOfSplits < 1)}">
        <p class="input-group flex-nowrap">
          <span class="input-group-addon pointer" (click)="noOfSplits = noOfSplits + 1;splitBalance()">▲</span>
          <input name="seatSplit" #seatSplit="ngModel" type="text" (input)="splitBalance()" type="number" min="1"
            step="1" class="form-control text-right seat-split" [(ngModel)]="noOfSplits"
            style="min-width: 50px;margin-bottom: -0.5px;" required />
          <span class="input-group-addon pointer"
            (click)="noOfSplits > 1 ? noOfSplits = noOfSplits - 1 : {}; splitBalance()">▼</span>
          <span *ngIf="seatSplit.invalid">
            <label class="control-label" *ngIf="seatSplit.errors?.required">Please enter No of Split.</label>
          </span>
        </p>
        <div class="col-12 p-0">
          <label class="edit-form__section__label text-nowrap"><span *ngIf="currentSplitBalance > 0">{{ currentSplitBalance| tark_currency }} /
              party</span></label>
        </div>
      </div>
      <div class="col-12 pe-pix-5 pt-pix-10 text-center">
        <button class="btn btn-primary pos-button white" type="button" (click)="printRequest()">
          <!-- <i ng-class="{{$root.icons.settlesplitprint}}" aria-hidden="true"></i>  -->
          <pos-icon [icon]="icons.settleSplitPrint"></pos-icon>
          Print Split Check
        </button>
      </div>
    </div>

  </form>
</div>
