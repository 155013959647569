import {
  Component,
  HostListener,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef
} from '@angular/core';
import {
  Diagram,
  AccountVisualization
} from '../../interfaces/layout-designer';
/**
 * externally (in mxClient) defined vars
 */
declare let mxCodec: any;
declare let mxUtils: any;
declare let mxResources: any;
declare let mxLanguage: any;

declare let EditorUi: any;
declare let Editor: any;
declare let Graph: any;

@Component({
  selector: 'pos-layout-designer',
  templateUrl: './layout-designer.component.html',
  styleUrls: ['./layout-designer.component.scss']
})
export class LayoutDesignerComponent implements AfterViewInit {

  @Input() data: Diagram = null;
  @Input() canvasHeight: number;
  @Input() canvasWidth: number;
  @Input() container: HTMLElement;
  @Input() readOnly: boolean;
  @Input() graphXML: any = '';

  @Output() shapeClick = new EventEmitter<any>();
  @Output() saveDiagram = new EventEmitter<any>();

  @ViewChild('actionBtns') actionBtns: ElementRef;

  editorUi;
  deletedShapes: any[] = [];
  graph;
  previewMode: boolean;

  STYLE_PATH = '/assets/styles';
  // URLs for save and export
  OPEN_URL = '/open';
  RESOURCE_BASE = 'assets/resources/grapheditor';

  mxLanguage = 'en-us';    // will remove later or move to config.ts
  mxLanguages = ['en-us']; // will remove later or move to config.ts

  @Input() oneActiveOrderPerAccount: boolean;
  @Input() isShowOccupiedTable: boolean;

  accountIds = [];
  deletedAccounts: AccountVisualization[] = [];
  selectedAccount: AccountVisualization;
  // shape was deleted
  @HostListener('window:shapeDeleted', ['$event,target'])
  onShapeDelete(e) {
    e.detail.forEach(shape => this.deletedShapes.push(shape));
  }

  @HostListener('window:shapeClicked', ['$event,target'])
  onShapeClick(e) {
    this.shapeClick.emit({shape: e.detail, layout: this.data});
  }


  @HostListener('window:shapeHover', ['$event,target'])
  onShapeHover(e) {
    this.graph.tooltipHandler.delay = 250;
    let div = this.graph.tooltipHandler.div;

    if (!div) {
      this.graph.tooltipHandler.init();
      div = this.graph.tooltipHandler.div;
    }

    // set tooltip css
    if (!e.detail) {
      div.style.cssText =
      'background: #fff; padding: 10px; visibility: hidden; border-color: #ababab; line-height: 1.5; border-radius: 5px; font-size: 12px;';
    } else {
      div.style.cssText = e.detail;
    }

    this.graph.tooltipHandler.div = div;
  }

  closePreview() {
    this.previewMode = false;
    this.graph.setEnabled(true);
  }

  ngAfterViewInit() {
      // Extends EditorUi to update I/O action states based on availability of backend
      const editorUiInit = EditorUi.prototype.init;

      EditorUi.prototype.init = function () {
        editorUiInit.apply(this, arguments);
        this.actions.get('export').setEnabled(false);
      };

      // Adds required resources (disables loading of fallback properties, this can only
      // be used if we know that all keys are defined in the language specific file)
      mxResources.loadDefaultBundle = true;
      const bundle = mxResources.getDefaultBundle(this.RESOURCE_BASE, mxLanguage) ||
        mxResources.getSpecialBundle(this.RESOURCE_BASE, mxLanguage);

      // Fixes possible asynchronous requests
      mxUtils.getAll([bundle, this.STYLE_PATH + '/default.xml'], (xhr) => {
        // Adds bundle text to resources
        mxResources.parse(xhr[0].getText());

        // Configures the default graph theme
        const themes = new Object();
        themes[Graph.prototype.defaultThemeName] = xhr[1].getDocumentElement();

        const editor = new Editor(false, themes, null, null, !this.readOnly);

        // Main
        if (this.canvasWidth) {
          this.container.style.width = this.canvasWidth + 'px';
        }

        if (this.canvasHeight) {
          this.container.style.height = this.canvasHeight + 'px';
        }

        this.editorUi = new EditorUi(editor, this.container, null, !this.readOnly, this.actionBtns);
        this._setGraphXML(this.graphXML);
        this.editorUi.refresh();
        this.graph = this.editorUi.editor.graph;
      }, function () {});
  }
  previewReadOnly() {
    this.graphXML = mxUtils.getPrettyXml(this.editorUi.editor.getGraphXml());
    this.previewMode = true;
    this.graph.setEnabled(false);
  }

  refresh(graph) {
    this.graphXML = graph;
    this.editorUi.refresh();
  }

  save() {
    this.graphXML = mxUtils.getPrettyXml(this.editorUi.editor.getGraphXml());
    this.saveDiagram.emit(this.graphXML);
  }

  // #region PRIVATE FUNCTIONS

  private _buildReadOnlyGraph() {
    const graphWrapper = document.createElement('div');

    if (this.canvasWidth) {
      graphWrapper.style.width = this.canvasWidth + 'px';
    }

    if (this.canvasHeight) {
      graphWrapper.style.height = this.canvasHeight + 'px';
    }

    graphWrapper.style.backgroundColor = '#fff';
    graphWrapper.style.overflow = 'auto';
    graphWrapper.style.padding = '10px';
    this.container.appendChild(graphWrapper);
    this.graph = new Graph(graphWrapper, null, null, null);

    const xml = this.graphXML;
    const doc = mxUtils.parseXml(xml);
    const codec = new mxCodec(doc);
    const root = doc.getElementsByTagName('root')[0];
    let elt = root ? root.firstChild : null;
    const cells = [];
    while (elt != null) {
      const cell = codec.decodeCell(elt);

      // NOTE: for some shapes like ellipse and rhombus, the editor adds them as style="rhombus;"
      // this rendering mode needs the more specific declaration of style="shape=rhombus;"
      ['ellipse', 'rhombus'].forEach(shape => {
        if (cell
          && cell.style
          && cell.style.indexOf(shape) > -1
          && cell.style.indexOf(`shape=${shape}`) === -1) {
          cell.style += `shape=${shape};`;
        }
      });

      // set default shape styles
      if (cell && cell.style) {
        if (cell.style.indexOf('fillColor') === -1) {
          cell.style += 'fillColor=#FFF;';
        }

        if (cell.style.indexOf('fontColor') === -1) {
          cell.style += 'fontColor=#000;';
        }

        if (cell.style.indexOf('strokeColor') === -1) {
          cell.style += 'strokeColor=#000;';
        }
      }

      if (cell) {
        cells.push(cell);
      }

      this.graph.refresh();
      elt = elt.nextSibling;
    }

    this.graph.addCells(cells);
    this.graph.enabled = false;
  }

  public _convertGraphToJSON(): Diagram {
    const currentData = this.data ? this.data : new Diagram();
    currentData.LayoutDefinition = this.data ? mxUtils.getPrettyXml(this.editorUi.editor.getGraphXml()) : '';
    return currentData;
  }

  private _setGraphXML(graphXml: string) {
    this.editorUi.editor.setGraphXml(mxUtils.parseXml(graphXml).documentElement);
  }

  parseXML(graphXML) {
    const xml = graphXML;
    return mxUtils.parseXml(xml);
  }
  // #endregion
}
