<div *ngIf="isShowHeaderFooter">
    <div *ngIf="isLoadOnPopup">
        <h4 class="modal-content__heading">Choose Sales Groups</h4>
    </div>
    <div *ngIf="!isLoadOnPopup">
        <h3 class="page-content__header ps-pix-10 pt-pix-15">Choose Sales Groups</h3>
    </div>
</div>
<div [ngClass]="isLoadOnPopup ? 'modal-body pb-0' : (isShowHeaderFooter?'p-pix-15 pt-pix-5':'')">
    <div class="row screen-scroll m-0" [ngStyle]="{'max-height': height}">
        <div class="px-0" *ngIf="isShowFilterSwitch">
            <div class="filter-switch pt-1">
                <span class="me-pix-10">
                    Filter Products
                </span>
                <span>
                    <p-inputSwitch (onChange)="filterProduct()" [(ngModel)]="isFilteredProduct"></p-inputSwitch>
                </span>
            </div>
        </div>

        <div class="px-0 pt-pix-10 pb-pix-5">
            <button type="button" class="btn btn-primary normal me-pix-5 pos-button white"
                (click)="checkAllGroups = true; checkAllSalesGroup(true)" [disabled]="!isFilteredProduct">
                <pos-icon [icon]="icons.checkCircleSimple">
                </pos-icon> Check All
            </button>
            <button type="button" class="btn btn-primary normal pos-button white"
                (click)="checkAllGroups = false; checkAllSalesGroup(false)" [disabled]="!isFilteredProduct">
                <pos-icon [icon]="icons.circleRegular">
                </pos-icon> Uncheck All
            </button>
        </div>


        <div class="col-12 p-0" [ngClass]="{'mb-pix-10': !isLoadOnPopup}"
            *ngIf="salesGroups">
            <span *ngFor="let group of salesGroups" class="pt-pix-7">
                <p-chip [label]="group.name"
                    [ngStyle]="{'pointer-events': (!isFilteredProduct ? 'none':''),'opacity':(!isFilteredProduct ? '0.5':'1')}"
                    class="pe-pix-5 pointer green-light-x white" (click)="checkAllGroups = false; salesGroupChanged(group)"
                    [style]="{'background-color':(group.IsSelected ? group.make_table_color: getLightenDarkenColor(group.make_table_color)), 'color': getContrastColor(group.make_table_color)}">
                    <span>
                        <pos-icon [icon]="icons.checkCircleSimple" class="font-16 pe-pix-5" *ngIf="group.IsSelected">
                        </pos-icon>
                        <pos-icon [icon]="icons.circleRegular" class="font-16 pe-pix-5" *ngIf="!group.IsSelected">
                        </pos-icon>
                    </span>
                </p-chip>
            </span>
        </div>
    </div>
</div>
<div id="filterProductFooter" *ngIf="isShowHeaderFooter" [ngClass]="{'fixed-bottom-section': !isLoadOnPopup}">
    <div class="col-12 action-button-container popup-footer pb-pix-10">
        <label class="float-start ps-pix-10 pt-pix-7 bold text-primary font-18">{{filteredProducts.SalesGroups.length > 0 ?
            filteredProducts.SalesGroups.length : 'No ' }}
            group(s) selected </label>
        <button type="button" class="btn-primary btn-lg btn white btn-cancel-modal pos-button" (click)="addFilteredProductsProperty()">Go
        </button>
    </div>
</div>
<div class="clearfix"></div>
