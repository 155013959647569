export const MessageTypes = {
    HIC_REQUEST: 'HIC Request',
    HIC_RESPONSE: 'HIC Response',
    TERMINAL_USE_RESPONSE: 'Terminal Use Response',
    PRODUCT_UPDATE_FOR_PICKSCREEN: 'Product Update for Pickscreen',
    ORDER_UPDATE: 'Order Update',
    ORDER_SERVED: 'Order Served',
    SHIFT_DUTIES: 'Shift Duties',
    TERMINAL_MESSAGE: 'Terminal Message',
    MESSAGE_MARK_AS_READ: "Mark as Read",
    REFRESH_ACTIVE_ORDER: 'Refresh Active order',
    ORDER_PRODUCT_STATE_CHANGE: 'Order Product State Change',
    PAYMENT_RESPONSE: 'Payment Response',
    TERMINAL_WARNING: 'Terminal Warning',
    TERMINAL_WARNING_CLOSURE: 'Terminal Warning Closure',
    ORDER_FULLY_PAID: 'Order Fully Paid',
    ORDER_PAYMENT_OUTSTANDING: 'Order Payment Outstanding',
    ORDER_QUANTITY_CHANGED: 'Order Quantity Changed',
    ORDER_ITEM_DELETE: 'Order Item Delete',
    CHECK_ORDER_IS_OPEN: 'Check Order Is Open',
    ORDER_ALREADY_OPEN: 'Order Already Open',
    ORDER_CLOSE_REQUEST: 'Order Close Request',
    COMMAND: 'Command',
    GLOBAL_LOGOFF: 'Global Logoff',
    ORDER_PRODUCT_COMPONENT_STATE_CHANGE: 'Order Product Component State Change',
    ORDER_PRODUCT_IS_CRAFTING: 'Order Product Is Crafting',
    ORDER_PRODUCT_IS_ALREADY_SELECTED_FOR_CRAFTING: 'Order Product Is Already Selected For Crafting',
    PRODUCT_LABEL_PRINTING_PARAMETER_UPDATE: 'Product Label Printing Parameter Update',
    DJ_SPEAK_TEXT: 'DJ Speak Text',
    HIC_CREDIT_CARD_TRANSACTION_RESPONSE: 'Credit Card Transaction Response',
    HIC_CAMERA_REQUEST: 'Add Annotation',
    ORDER_REVIEWED: 'Order Reviewed',
    REVIEW_ORDER: 'Review Order',
    CLOSE_REVIEW_ORDER_MODAL: 'Close Review Order Modal',
    LIGHT_AUTH_TOKEN: 'AuthToken',
    LIGHT_INFO: 'LightInfo',
    FILTER_MENU_ITEMS: 'FilterMenuItems',
    HIGHLIGHT_MENU_ITEMS: 'HighlightMenuItems',
    REFRESH_MENU_DISPLAY: 'REFRESH_MENU_DISPLAY',
    REFRESH_DASHBOARD: 'Refresh Dashboard',
    REFRESH_TERMINAL: 'Refresh Terminal',
    AUTO_SIGN_OUT_USER: 'Auto Sign Out User',
    ORDER_KIOSK_STATE_UPDATED: 'Order Kiosk State Updated'
};

export const WarningTypes = {
    MENU_CHANGED: 'MENU_CHANGED',
    HARDWARE_DISCONNECTED: 'HARDWARE_DISCONNECTED',
    EXTERNAL_WARNING: 'EXTERNAL_WARNING'
};

export const HICRequestTypes = {
    Print: 'Print',
    CashDrawer: 'CashDrawer',
    ReceiptPrint: 'ReceiptPrint',
    CreditCard: 'CreditCard'
};

export const CommandTypes = {
    LogOff: 'LogOff'
};
