import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AlertsService, SpinnerService, ModalService, ApplicationStateService } from 'src/app/shared';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TaskService } from 'src/app/shared/services/task.service';
import { ModalComponent } from 'src/app/shared/components/modal/modal-component';
import { Task, SingleTask } from 'src/app/information-management/tasks/interface/task';

@Component({
  selector: 'pos-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss']
})
export class TaskComponent extends ModalComponent implements OnInit {
  @ViewChild('taskForm') taskForm: NgForm;
  @Output('closeModal') closeModal: EventEmitter<any> = new EventEmitter();
  task: SingleTask;
  isValidDateTime: boolean = true;
  hourFormat = '12';

  constructor(modalRef: BsModalRef,
    private taskService: TaskService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private applicationStateService: ApplicationStateService,
    private modalService: ModalService) {
    super(modalRef);
  }

  ngOnInit() {
    this.task = this.taskService.newSingleTask();
    this.task.DateDue.setMinutes(this.task.DateDue.getMinutes() + 5);
    this.hourFormat = this.applicationStateService.settingParam.PCalendarHourFormat;
  }

  validateDate($event: any) {
    this.isValidDateTime = this.task.DateDue >= new Date() ? true : false;
  }

  addTask(isValid: boolean) {
    if (this.task.DateDue < new Date()) {
      this.isValidDateTime = false;
      return;
    }
    if (!isValid) {
      return;
    }
   
    this.spinnerService.show();
    this.taskService.addTask(this.task).subscribe(
      () => {
        this.alertService.renderSuccessMessage(Messages.TaskSaveSuccess);
        this.cancelEditing();
      }, this.alertService.showApiError,
      () => {
        this.spinnerService.hide();
      }
    );
  }

  cancelEditing(reload = true) {
    this.closeModal.emit();
    this.taskForm.resetForm();
    this.hide({ shouldReload: reload });
  }

}
