import { Injectable } from '@angular/core';
import { GridColumn } from '@tarktech/tark-ng-utils';
import { saveAs } from 'file-saver';
import { find, forEach } from 'lodash';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor() { }

  exportCSV(fileName: string, data, parameters = null, columns = null) {
    let dataCopy = [];
    forEach(data, (json) => {
      let obj = {};

      if (columns && columns?.length) {
        forEach(columns, (column) => {
          obj[(column?.HeaderText ?? column?.Field)?.replace(/([A-Z])/g, ' $1').trim()] = json[column?.Field];
        });

      } else {
        forEach(json, (value, property) => {
          obj[property.replace(/([A-Z])/g, ' $1').trim()] = value;
        });
      }

      dataCopy.push(obj);
    });
    const worksheet = XLSX.utils.json_to_sheet(dataCopy);

    let csvOutput: string = parameters ? parameters + '\n\n' : '';
    csvOutput += XLSX.utils.sheet_to_csv(worksheet);

    const workbook = { Sheets: { 'Sheet-1': worksheet }, SheetNames: ['Sheet-1'] };
    const blob = new Blob([csvOutput], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
    });
    saveAs(blob, fileName + '.csv');
  }

  exportColumnCSV(fileName: string, data, exportColumns: Array<GridColumn> = [], parameters = null) {
    let dataCopy = [];
    forEach(data, (json) => {
      let obj = {};
      forEach(json, (value, property) => {
        if (!exportColumns.find(x => x.Field === property) && exportColumns) {
          return;
        }
        obj[property.replace(/([A-Z])/g, ' $1').trim()] = value;
      });
      dataCopy.push(obj);
    });

    const worksheet = XLSX.utils.json_to_sheet(dataCopy);
    let csvOutput: string = parameters ? parameters + '\n\n' : '';
    csvOutput += XLSX.utils.sheet_to_csv(worksheet);

    const workbook = { Sheets: { 'Sheet-1': worksheet }, SheetNames: ['Sheet-1'] };
    const blob = new Blob([csvOutput], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
    });
    saveAs(blob, fileName + '.csv');
  }

}
