import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MakeTableOrderComponent } from './components/order-card';
import { ComponentsModule, FormsModule } from '../shared/components';
import { MakeTableBottomClockComponent } from './components/order-bottom-clock-and-layout/order-bottom-clock-and-layout.component';
import { TooltipModule } from 'primeng/tooltip';
import { DisplayTimeComponent } from './components/display-time/display-time.component';
import { TableConfiguration } from '../shared/table/table-configuration';
import { TableModule } from '@tarktech/tark-ng-utils';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [MakeTableOrderComponent, MakeTableBottomClockComponent, DisplayTimeComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    TooltipModule,
    FormsModule,
    TableModule.forRoot(TableConfiguration),
    NgbDropdownModule,
  ],
  exports: [MakeTableOrderComponent, MakeTableBottomClockComponent, DisplayTimeComponent]
})
export class OrderCardSharedModule { }
