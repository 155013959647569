<pos-edit-wrapper heading="Pick Screen" (close)="cancel()" [headingIcon]="icons.pickUpScreen" [isPopup]="false">
    <form (ngSubmit)="savePickScreenWorkflow(pickScreenWorkflow.valid)" #pickScreenWorkflow="ngForm" class="edit-form">
        <div class="col-12 border-top pt-pix-5">
            <div class="col-12 pos-page-body">
                <pos-row>
                    <pos-column>
                        <pos-form-field inputStyle="col-12">
                            <p-checkbox posInput name="chkShowNonDefaultQuantityOnPickScreen"
                                #chkShowNonDefaultQuantityOnPickScreen="ngModel"
                                [(ngModel)]="pickScreenWorkflowParameters.ShowNonDefaultQuantityOnPickScreen"
                                binary="true" label="Show quantity on pick screen when ordered in non-default quantity">
                            </p-checkbox>
                        </pos-form-field>
                    </pos-column>
                    <pos-column>
                        <pos-form-field inputStyle="col-12" label="Item Highlight Duration" [validationMessages]="{'required': 'Please enter highlight duration.'}">
                            <input posInput type="number" class="form-control col-120" posOnlyNumbers name="PickScreenHighlightItemDuration"
                            [(ngModel)]="pickScreenWorkflowParameters.PickScreenHighlightItemDuration" [required]="true" [min]="0"/>
                        </pos-form-field>
                    </pos-column>
                    <pos-column>
                        <pos-form-field inputStyle="col-12" label="Item Highlight Color">
                            <pos-color-picker posInput cpPosition="bottom" name="PickScreenHighlightItemColor" [(ngModel)]="pickScreenWorkflowParameters.PickScreenHighlightItemColor">
                            </pos-color-picker>
                        </pos-form-field>
                    </pos-column>
                </pos-row>
            </div>
        </div>
        <div class="col-12 action-button-container page-footer">
            <button class="btn-primary btn-lg btn btn-save pos-button" scrollup type="submit" value="Save">Save</button>
            <button class="btn-secondary btn-lg btn btn-cancel pos-button" routerLink="../" scrollup type="button"
                value="Cancel">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
