import { MessagePayload } from './message-payload';

export class FilterMenuItemsPayload extends MessagePayload {

    public DietaryWarnings?: Array<number>;
    public Tags?: Array<number>;
    public Widgets?: Array<number>;
    public ActionType: string;

    constructor(dietaryWarnings?: Array<number>, tags?: Array<number>, widgets?: Array<number>, actionType?: string) {
        super();
        this.DietaryWarnings = dietaryWarnings;
        this.Tags = tags;
        this.Widgets = widgets;
        this.ActionType = actionType;
    }
}
