export const ExchangeTypes = {
    FANOUT: 'FANOUT',
    DIRECT: 'DIRECT',
    TOPIC: 'TOPIC'
};

export const ExchangeNames = {
    TERMINALS: 'terminals',
    TERMINALS_MAKETABLEACTIVITY: 'terminals.maketableactivity',
    TERMINALS_INVOICE: 'terminals.invoice',
    TERMINALS_ORDERENTRY: 'terminals.orderentry',
    ORDER_PAYMENT_RESPONSE: 'order.payment.response',
    TERMINALS_MAKETABLE: 'terminals.maketable',
    TERMINALS_TIMER: 'terminals.timer',
    HIC_RESPONSE: 'hic.response',
    ACTIVITY_REPORTING: 'activity.reporting',
    TERMINALS_INSTRUCTION: 'terminals.instruction',
    ORDER_UPDATES: 'OrderUpdates',
    EVENT: 'event',
    USER_TRAINING_BADGE: 'UserTrainingBadge',
    TERMINAL_MENU_DISPLAY_ACTIVITY: 'terminals.menudisplayactivity',
    HIC_REQUEST: 'hic.request',
    RPC: 'rpc',
    SALES_PRODUCT: 'salesproduct',
    TERMINAL_ORDER_KIOSK: 'terminal.orderkiosk'
};

export const RoutingKeys = {
    ALL: 'all',
    SalesProductOutOfStockEvent: 'SalesProductOutOfStockEvent',
    SalesProductReplenishedEvent: 'SalesProductReplenishedEvent',
    CashDrawerClosedEvent: 'CashDrawerClosedEvent',
    CashDrawerOpenedEvent: 'CashDrawerOpenedEvent',
    InventoryExhaustionEvent: 'InventoryExhaustionEvent',
    OrderDelete: 'OrderDelete',
    OrderServed: 'OrderServed',
    OrderUpdate: 'OrderUpdate',
    OrderItemDelete: 'OrderItemDelete',
    OrderItemStateChange: 'OrderItemStateChange',
    OrderItemQtyChange: 'OrderItemQtyChange',
    TerminalUseResponse: 'TerminalUseResponse',
    TerminalUse: 'TerminalUse',
    TerminalLogout: 'TerminalLogout',
    TerminalStatusCheck: 'TerminalStatusCheck',
    LabelPrintingParameterChange: 'LabelPrintingParameterChange',
    RefreshActiveOrder: 'RefreshActiveOrder',
    GlobalLogoff: 'GlobalLogoff',
    ClockOut: 'ClockOut',
    TerminalMessage: 'TerminalMessage',
    TerminalMessageMarkAsRead: 'TerminalMessageMarkAsRead',
    RequireNameOnOrderTemporaryDisableParameter: 'RequireNameOnOrderTemporaryDisableParameter',
    DJSpeakText: 'DJSpeakText',
    ScheduleOrderUpdate: 'ScheduleOrderUpdate',
    OrderAlreadyOpen: 'OrderAlreadyOpen',
    OrderCloseRequest: 'OrderCloseRequest',
    OrderIsOpen: 'OrderIsOpen',
    HICCreditCardTransactionResponse: 'HICCreditCardTransactionResponse',
    TerminalWarning: 'TerminalWarning',
    OrderReviewed: 'OrderReviewed',
    ReviewOrder: 'ReviewOrder',
    CloseReviewOrderModal: 'CloseReviewOrderModal',
    RefreshMenuDisplay: 'RefreshMenuDisplay',
    MenuDisplayHighlightItem: 'MenuDisplayHighlightItem',
    MenuDisplayFilter: 'MenuDisplayFilter',
    AudioRecordings: 'AudioRecordings',
    AudioRecordingsForOrder: 'AudioRecordingsForOrder',
    AudioRecordFile: 'AudioRecordFile',
    RefreshDashboard: 'RefreshDashboard',
    RefreshTerminal: 'RefreshTerminal',    
    AutoSignOutUser: 'AutoSignOutUser',
    SalesProductUpdated: 'SalesProductUpdated',
    OrderKioskStateUpdated: 'OrderKioskStateUpdated',    
    UserClockInEvent: 'UserClockInEvent'
};
