import { Component, OnInit, ViewChild } from '@angular/core';
import { GridColumn, EditColumn, DeleteColumn, LinkColumn, TemplateColumn, TableComponent, TextAlign } from '@tarktech/tark-ng-utils';
import { TrainingBadge } from '../../interface/training-badge';
import {
    AlertsService, Messages, SpinnerService, ConfirmDeleteComponent, ModalService,
    AuthenticationService, Permissions, Levels, RuntimeConstants, PrintTableService, BaseGridComponent, ApplicationStateService
} from 'src/app/shared';
import { TrainingBadgeService } from '../../services/training-badge.service';
import { ActivatedRoute, Router } from '@angular/router';
import { trainingBadge } from 'src/app/shared/components/icon';
@Component({
    selector: 'pos-training-badge-list',
    templateUrl: './training-badge-list.component.html'
})
export class TrainingBadgeListComponent extends BaseGridComponent implements OnInit {
    @ViewChild('confirmTrainingBadgeDelete') private confirmTrainingBadgeDelete: ConfirmDeleteComponent;
    @ViewChild('iconTemplate', {static: true}) private iconTemplate: any;
    trainingBadges: Array<TrainingBadge> = [];
    trainingBadgeColumns: Array<GridColumn> = [];
    printTrainingBadgeColumns: Array<GridColumn> = [];
    trainingBadge: TrainingBadge;
    @ViewChild('trainingBadgeGrid', {static: true}) trainingBadgeGrid: TableComponent;
    public imagePath = RuntimeConstants.IMAGE_BASE_PATH;
    public confirmDeleteMessage = Messages.ConfirmDeleteTrainingBadge;
    permission = {
        name: Permissions.TrainingBadges,
        readOnlyLevel: Levels.ReadOnly,
        editLevel: Levels.Edit
    };
    icons = { trainingBadge }
    constructor(
        private alertService: AlertsService,
        private spinnerService: SpinnerService,
        private modalService: ModalService,
        private authenticationService: AuthenticationService,
        private trainingBadgeService: TrainingBadgeService,
        protected applicationStateService: ApplicationStateService,
        protected route: ActivatedRoute,
        private router: Router,
        private printService: PrintTableService) {
        super(applicationStateService, route);
        this.reloadTrainingBadgeList();
    }

    ngOnInit() {
        this.columnConfiguration();
        this.trainingBadgeGrid.context = this.gridContext;
    }

    private columnConfiguration() {
        const editColumn = new EditColumn({
            Clicked: (data) => {
                this.editTrainingBadge(data.Id);
            },
            Width: '57px',
            AddIcon: new LinkColumn({
                Clicked: () => {
                    this.editTrainingBadge(0);
                },
                ToolTip: 'New'
            })
        });

        const deleteColumn = new DeleteColumn({
            Clicked: (data) => {
                this.deleteBadgeButton(data);
            },
            Width: '83px',
            PrintIcon: new LinkColumn({
                Clicked: () => {
                    this.printData();
                }
            })
        });

        const iconColumn = new TemplateColumn({
            HeaderText: 'Icon',
            itemTemplate: this.iconTemplate,
            Width: '20%',
            TextAlign: TextAlign.Center
        });

        this.trainingBadgeColumns = [
            new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '55%' }),
            iconColumn,
            new GridColumn({ HeaderText: 'Score Threshold', Field: 'ScoreThreshold', IsSortable: true, Width: '20%' })
        ] as Array<GridColumn>;

        this.printTrainingBadgeColumns = [
            new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '54%' }),
            new GridColumn({ HeaderText: 'Score Threshold', Field: 'ScoreThreshold', IsSortable: true, Width: '20%' })
        ] as Array<GridColumn>;

        if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
            this.trainingBadgeColumns.push(editColumn);
            this.trainingBadgeColumns.push(deleteColumn);
        }
    }

    reloadTrainingBadgeList() {
        this.spinnerService.show();
        this.trainingBadgeService.getTrainingBadges().subscribe(
            response => {
                this.trainingBadges = (response === null ? [] : response);
            }, this.alertService.showApiError,
            () => {
                this.spinnerService.hide();
            }
        );
    }

    deleteBadgeButton($event: TrainingBadge) {
        this.trainingBadge = $event;
        this.confirmTrainingBadgeDelete.Show(this.confirmDeleteMessage);
    }

    onConfirmAcknowledge() {
        this.spinnerService.show();
        this.trainingBadgeService.deleteTrainingBadge(this.trainingBadge.Id).subscribe(
            () => {
                this.alertService.renderSuccessMessage(
                    Messages.TrainingBadgeDeleteSuccess
                );
                this.reloadTrainingBadgeList();
            },
            this.alertService.showApiError,
            () => {
                this.spinnerService.hide();
            }
        );
    }

    editTrainingBadge(badgeId: number) {
        this.router.navigate([badgeId ], { relativeTo: this.route });
    }

    printData() {
        this.printService.printEmitter.next({ gridColumns: this.printTrainingBadgeColumns, gridData: this.trainingBadges });
    }

    close() {
        this.router.navigate(['../'], { relativeTo: this.route });
    }
}
