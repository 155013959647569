<pos-edit-wrapper (close)="cancelMemo()" heading="Add Memo">
    <form #productMemoForm="ngForm" autocomplete="off" (submit)="saveMemo()">
        <div class="col-12 pos-modal-body">
            <pos-row class="g-0">
                <pos-column>
                    <pos-form-field label="Memo" inputStyle="col-12">
                        <input posInput type="text" name="inventoryMemo" class="form-control" [(ngModel)]="productMemo" />
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button class="btn btn-primary btn-lg btn-save pos-button" type="submit">Save</button>
            <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="cancelMemo()" type="button">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
