import { Component, OnInit, TemplateRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { User } from '../../interface';
import { GridColumn, TemplateColumn, EditColumn, DeleteColumn, LinkColumn, TextAlign, TableComponent } from '@tarktech/tark-ng-utils';
import { Permissions, Levels, Messages } from '../../../../shared/constants/';
import { barsWhite, check, userImpersonate, users, userUnblock } from 'src/app/shared/components/icon';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { AuthenticationService } from 'src/app/shared/auth/services/authentication.service';
import { ConfirmDeleteComponent } from 'src/app/shared/components/confirm-delete/confirm-delete.component';
import { finalize } from 'rxjs/operators';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import { BaseGridComponent, UserService } from 'src/app/shared';
import { ActivatedRoute, Router } from '@angular/router';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { LinkTemplateColumn } from '@tarktech/tark-ng-utils/table/link-template-column';

@Component({
    selector: 'pos-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss']
})
export class UserListComponent extends BaseGridComponent implements OnInit {

    permission = {
        name: Permissions.ManageEmployees,
        impersonate: Permissions.UserImpersonateUser,
        unblockUser: Permissions.UserUnblockUser,
        readOnlyLevel: Levels.ReadOnly,
        editLevel: Levels.Edit,
        accessLevel: Levels.Access
    };
    icons = {
        barsWhite,
        check,
        userImpersonate,
        userUnblock,
        users
    };
    user: User;
    users: User[] = [];
    usersSearchResult: User[] = [];
    userType = { showSystemUsers: false, showAllUsers: false };
    userId: number;
    @ViewChild('userGrid', { static: true }) userGrid: TableComponent;

    popup: any;
    userColumns: Array<GridColumn> = [];
    printUserColumns: Array<GridColumn> = [];
    @ViewChild('popOverForUser', { static: true }) popOverForUser: TemplateRef<any>;
    @ViewChild('confirmUser') private confirmUser: ConfirmDeleteComponent;
    @ViewChild('iconTemplate', { static: true }) private iconTemplate: any;
    @ViewChild('iconHeaderTemplate') private iconHeaderTemplate: any;
    constructor(
        private userService: UserService,
        private alertService: AlertsService,
        private spinnerService: SpinnerService,
        private authenticationService: AuthenticationService,
        protected route: ActivatedRoute,
        protected applicationStateService: ApplicationStateService,
        private printService: PrintTableService,
        private router: Router
    ) {
        super(applicationStateService, route);
    }

    ngOnInit() {
        this.userType.showAllUsers = this.othersProperties.showAllUsers ? true : false;
        this.userType.showSystemUsers = this.othersProperties.showSystemUsers ? true : false;
        this.configureGridColumns();
        this.reloadUsers();
        this.scrollToTop();
        this.userGrid.context = this.gridContext;
    }

    getOtherProperties() {
        this.othersProperties = { showAllUsers: this.userType.showAllUsers, showSystemUsers: this.userType.showSystemUsers };
    }

    private configureGridColumns() {
        const editColumn = new EditColumn({
            Clicked: (user) => {
                this.editUser(user.id, user.username);
            },
            Width: '57px',
            AddIcon: new LinkColumn({
                Clicked: () => {
                    this.editUser(0);
                },
                ToolTip: 'New'
            })
        });

        const deleteColumn = new DeleteColumn({
            Clicked: (user) => {
                this.deleteUser(user);
            },
            Width: '68px',
            PrintIcon: new LinkColumn({
                Clicked: () => {
                    this.printDiv();
                },
                ToolTip: 'Print'
            })
        });
        const popoverIcon = new LinkTemplateColumn({
            CellClass: 'cell-link greyicon', itemTemplate: this.popOverForUser, Width: '5px'
        });
        const iconColumn = new TemplateColumn({
            headerTemplate: this.iconHeaderTemplate,
            itemTemplate: this.iconTemplate,
            TextAlign: TextAlign.Center,
            Width: '6%',
            Field: 'active',
            IsSortable: true,
            HeaderText: 'Active'
        });

        this.userColumns = [
            new GridColumn({ HeaderText: 'First Name', Field: 'firstname', IsSortable: true, Width: '16%' }),
            new GridColumn({ HeaderText: 'Last Name', Field: 'lastname', IsSortable: true, Width: '16%' }),
            new GridColumn({ HeaderText: 'Username', Field: 'username', IsSortable: true, Width: '16%' }),
            new GridColumn({ HeaderText: 'Role', Field: 'RoleName', IsSortable: true, Width: '20%' }),
            new GridColumn({ HeaderText: 'Email', Field: 'EmailAddress', IsSortable: true, Width: '22%' }),
        ] as Array<GridColumn>;

        this.userColumns.push(iconColumn);
        this.printUserColumns = [...this.userColumns];

        if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
            this.userColumns.push(editColumn);
            this.userColumns.push(deleteColumn);
        }
        if (this.authenticationService.userHasPermission(
            [{ Name: this.permission.impersonate, Level: this.permission.accessLevel }], 'any')) {
            this.userColumns.push(popoverIcon);
        }
    }
    private reloadUsers() {
        this.spinnerService.show();
        this.userService.getUsersList()
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (users: User[]) => {
                    this.usersSearchResult = [...users];
                    this.users = users;
                    this.reload();
                }, error: this.alertService.showApiError
            });
    }

    reload() {
        this.users = this.usersSearchResult.filter(
            u => (u.active || this.userType.showAllUsers) && (!u.IsSystemUser || this.userType.showSystemUsers));
    }

    private editUser(id: number, name?: string) {
        this.router.navigate([id], {
            state: {
                userName: name
            }, relativeTo: this.route
        });
    }
    private deleteUser(user: User) {
        this.userId = user.id;
        this.confirmUser.Show(StringUtils.format(Messages.ConfirmDeleteUser, { 'user': user.lastname + ' ' + user.firstname }));
    }
    onConfirmAcknowledge() {
        this.spinnerService.show();
        this.userService.deleteUser(this.userId)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: () => {
                    this.alertService.renderSuccessMessage(Messages.UserDeleted);
                    this.reloadUsers();
                    this.userId = 0;
                }, error: this.alertService.showApiError
            });
    }

    onUnblockUser() {
        this.popup.hide();
        this.spinnerService.show();
        this.userService.unblockUser(this.user.id)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: () => {
                    this.alertService.renderSuccessMessage(Messages.UserUnblockSuccess);
                }, error: this.alertService.showApiError
            });
    }

    private printDiv() {
        this.printService.printEmitter.next({ gridColumns: this.printUserColumns, gridData: this.users });
    }

    onLinkPopOver(user: User, popup: any) {
        this.user = user;
        this.popup = popup;
    }
    onImpersonateUser() {
        this.popup.hide();
        this.spinnerService.show();
        this.userService.getUserData(this.user.id)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (response) => {
                    this.applicationStateService.isImpersonateUser = true;
                    this.applicationStateService.impersonateUserDetails = response;
                    this.applicationStateService.gotoLoginScreen = false;
                    this.router.navigate(['terminals']);
                }, error: this.alertService.showApiError
            });
    }
    private scrollToTop(): void {
        window.scrollTo(0, 0);
    }

    close() {
        this.router.navigate(['../'], { relativeTo: this.route });
    }
}
