import { Component, OnInit } from '@angular/core';
import { ModalComponent, ApplicationStateService, SettingParam, ServeMethod } from 'src/app/shared';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { taxi, utensils, truck, car, ship } from 'src/app/shared/components/icon';
import * as _ from 'lodash';

@Component({
  selector: 'pos-set-order-type',
  templateUrl: './set-order-type.component.html',
  styleUrls: ['./set-order-type.component.scss']
})
export class SetOrderTypeComponent extends ModalComponent implements OnInit {

  settingParam: SettingParam;
  serveMethods: Array<ServeMethod> = [];
  constructor(
    modalRef: BsModalRef,
    private applicationStateService: ApplicationStateService
  ) {
    super(modalRef);
  }

  public icons = {
    taxi,
    utensils,
    truck,
    car,
    ship
  };

  ngOnInit() {
    this.settingParam = this.applicationStateService.settingParam;
  }

  setServeMethod(serveMethodId) {
    this.hide({ shouldReload: false, serveMethodId: serveMethodId });
  }

  closeModal() {
    this.hide({ shouldReload: false, serveMethodId: null });
  }

  getServeMethodClass = function (key) {
    switch (key) {
      case '1':
        return this.icons.taxi.iconClasses;
      case '2':
        return this.icons.utensils.iconClasses;
      case '3':
        return this.icons.truck.iconClasses;
      case '5':
        return this.icons.car.iconClasses;
    }
  };

}
