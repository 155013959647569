import { Component, Input, OnInit } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget';
import { GroupWidgetStyleConfig } from '../../interfaces';
import { MenuWidgetService, MenuWidgetStyleService } from '../../services';
import { AlertsService, ApplicationStateService, ModalService, RabbitMQService, SpinnerService } from 'src/app/shared';
declare let $;

@Component({
  selector: 'pos-menu-group-widget',
  templateUrl: './menu-group-widget.component.html',
  styleUrls: ['./menu-group-widget.component.scss']
})
export class MenuGroupWidgetComponent extends BaseWidgetComponent implements OnInit {

  @Input() widgetId: number;
  @Input() isPreviewMode = false;
  widgetName = '';
  widgetStyleConfig: GroupWidgetStyleConfig = null;
  widgetStyle = null;
  constructor(
    protected menuWidgetService: MenuWidgetService,
    private menuWidgetStyleService: MenuWidgetStyleService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected applicationStateService: ApplicationStateService,
    protected modalService: ModalService,
    protected rabbitMQService: RabbitMQService) {
    super(menuWidgetService, alertService, spinnerService, applicationStateService, modalService, rabbitMQService);
  }

  ngOnInit(): void {
    this.loadSubscriptions();
    this.loadWidget(this.widgetId);
  }

  loadSubscriptions() {
    this.widgetData$.subscribe({
      next: (res) => {
        this.widgetStyleConfig = res.WidgetStyleConfig ? this.parseJson<GroupWidgetStyleConfig>(res.WidgetStyleConfig) : null;
        this.widgetName = res.Name;
        $('.widget-background-' + this.widgetId)?.css({'background-color': 'transparent'});
        const highlightColor = !this.applicationStateService.settingParam.MenuItemHighlightColor ? '#78ea30' : this.applicationStateService.settingParam.MenuItemHighlightColor;
        if (this.widgetStyleConfig?.BorderStyle) {
          this.widgetStyleConfig.BorderStyle.borderColor = !this.widgetStyleConfig?.BorderStyle.borderColor ? highlightColor : this.widgetStyleConfig?.BorderStyle.borderColor;
        }
        this.widgetStyle = this.menuWidgetStyleService.getGroupWidgetStyle(this.widgetStyleConfig, this.isPreviewMode);
      }
    });

    this.baseWidgetCrudService.showGroupWidgetsChange$.subscribe({
      next: (response) => {
        this.setBackgroundAndBorder(response?.showGroupWidgets ?? false);
      }
    });
  }

  private setBackgroundAndBorder(isShowWidget: boolean) {
    if (isShowWidget) {
      $('.widget-background-' + this.widgetId)?.removeClass('display-none-imp');
    } else {
      $('.widget-background-' + this.widgetId)?.addClass('display-none-imp');
    }
  }

  editWidget(id: number, data?: any): void {
    // no need to implement
  }
}
