import { Component, OnInit, Input, ViewChild, EventEmitter } from '@angular/core';
import { Messages } from '../../../../shared/constants/';
import { BaseListComponent } from 'src/app/shared/components/base-list.component';
import { InventoryOrderReceivingNewBarcodeList } from 'src/app/shared/interface/inventory-order-receiving-new-barcode-list';
import { GridColumn, TemplateColumn } from '@tarktech/tark-ng-utils';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { InventoryProductBarCodeService } from '../../../../shared/services/inventory-product-barcode.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import * as _ from 'lodash';
import { finalize } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { ICloseable, ApplicationStateService } from 'src/app/shared';
import { ActivatedRoute } from '@angular/router';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
declare let $: any;
@Component({
    selector: 'pos-manage-barcode',
    templateUrl: './manage-barcode.component.html',
    styleUrls: ['./manage-barcode.component.scss']
})
export class ManageBarcodeComponent extends BaseListComponent<InventoryOrderReceivingNewBarcodeList> implements OnInit, ICloseable {

    barcodeColumns: Array<GridColumn> = [];
    barcodes: Array<InventoryOrderReceivingNewBarcodeList> = [];
    barcode: InventoryOrderReceivingNewBarcodeList;
    close: EventEmitter<any> = new EventEmitter();
    printBarcodeColumns: Array<GridColumn> = [];

    @Input() isPopup: boolean;
    @Input() inventoryProductId: number;
    @Input() unitName: string;
    productName: string;
    @ViewChild('barCodeForm') barCodeForm: NgForm;
    @ViewChild('unitTemplate', { static: true }) private unitTemplate: any;
    constructor(
        protected alertService: AlertsService,
        protected spinnerService: SpinnerService,
        protected inventoryProductBarCodeService: InventoryProductBarCodeService,
        protected modalService: ModalService,
        protected printService: PrintTableService,
        protected applicationStateService: ApplicationStateService,
        protected route: ActivatedRoute
    ) {
        super(inventoryProductBarCodeService, alertService, spinnerService, modalService, applicationStateService, printService,
            route);
    }

    ngOnInit() {
        this.deleteSuccessMessage = Messages.InventoryProductBarcodeDeleted;
        this.barcode = this.inventoryProductBarCodeService.newInventoryProductBarCode();
        this.barcode.InventoryProductId = this.inventoryProductId;
        this.barcodeColumns = this.configureGridColumns();
        this.printBarcodeColumns = [...this.getGridColumns()];
        $('#barcodeName').focus();
    }
    public getGridColumns(): Array<GridColumn> {

        const unitColumn = new TemplateColumn({
            HeaderText: 'Unit',
            itemTemplate: this.unitTemplate,
            Field: 'UnitName',
            IsSortable: true,
            Width: '30%'
        });

        return [
            new GridColumn({ HeaderText: 'Barcode', Field: 'Barcode', IsSortable: true, Width: '35%' }),
            new GridColumn({ HeaderText: 'Unit Qty', Field: 'UnitQtyOverride', IsSortable: true, Width: '30%' }),
            unitColumn
        ] as Array<GridColumn>;
    }

    protected printData() {
        this.printService.printEmitter.next({ gridColumns: this.printBarcodeColumns, gridData: this.barcodes });
    }

    public reload() {
        if (this.inventoryProductId) {
            this.spinnerService.show();
            this.inventoryProductBarCodeService.getInventoryProductBarCodes(this.inventoryProductId)
                .pipe(finalize(() => {
                    this.spinnerService.hide();
                }))
                .subscribe({
                    next: (response) => {
                        this.barcodes = response ? response : [];
                        this.barcodes.forEach(code => {
                            code.UnitName = this.unitName;
                        });
                        $('#barcodeName').focus();
                    }, error: this.alertService.showApiError
                });
        }
    }

    public editItem(id: number, data?: InventoryOrderReceivingNewBarcodeList): void {
        $('#barcodeName').focus();
        this.barcode = { ...data };
    }
    public getConfirmDeleteMessage(data: InventoryOrderReceivingNewBarcodeList): string {
        return StringUtils.format(Messages.ConfirmDeleteInventoryProductBarCode,
            { 'barCode': data ? data.Barcode : '' }
        );
    }

    public submit(isValid: boolean) {
        if (!isValid) {
            return;
        }
        this.barcode.InventoryProductId = this.inventoryProductId;
        if (this.barcode.Id) {
            this.updateBarcode();
        } else {
            this.saveBarcode();
        }
    }

    public updateBarcode() {
        this.spinnerService.show();
        this.inventoryProductBarCodeService.updateInventoryProductBarcode(this.barcode)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: () => {
                    this.alertService.renderSuccessMessage(Messages.BarcodeSaveSuccess);
                    this.reload();
                    this.CancelEdit();
                }, error: this.alertService.showApiError
            });
    }

    public saveBarcode() {
        this.spinnerService.show();
        this.inventoryProductBarCodeService.saveInventoryProductBarcode(this.barcode)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: () => {
                    this.alertService.renderSuccessMessage(Messages.BarcodeSaveSuccess);
                    this.reload();
                    this.CancelEdit();
                }, error: this.alertService.showApiError
            });
    }

    public CancelEdit() {
        this.barCodeForm.onReset();
        this.barcode = this.inventoryProductBarCodeService.newInventoryProductBarCode();
        $('#barcodeName').focus();
    }

    public closeBarcodeModal() {
        this.CancelEdit();
        this.close.emit({ reload: false });
    }
    public saveBarcodeToAvoidFormSubmit() {

    }
    public preventMinusSign(event) {
        if (event.key === '-') {
            return false;
        }
        return true;
    }
}
