<div id="print-section">
  <div *ngIf="headerContent" [innerHTML]="headerContent"></div>
  <table border="1" style="border-collapse: collapse; margin: 10px 0;" [ngStyle]="{'margin-top': !headerContent ? '45px' : '10px'}">
    <thead>
      <tr>
        <th style="text-align: left; padding: 4px;" *ngFor="let column of gridListColumns">{{column.HeaderText}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of gridDataList">
        <td *ngFor="let column of gridListColumns" [ngStyle]="{'width':column.Width}" style="padding: 4px;">
          <span *ngIf="!column.Format">
            {{data[column.Field]}}
          </span>
          <span *ngIf="column.Format">
            <span *ngIf="column.Format === format.Date">
              {{ data[column.Field] | tark_date}}
            </span>
            <span *ngIf="column.Format === format.Time">
              {{ data[column.Field] | tark_time}}
            </span>
            <span *ngIf="column.Format === format.DateTime">
              {{ data[column.Field] | tark_date_time}}
            </span>
            <span *ngIf="column.Format === format.Custom">
              {{data[column.Field] | date: column.Format }}
            </span>
          </span>
        </td>
      </tr>
    </tbody>
  </table>

</div>
