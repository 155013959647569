<div class="page-content">
  <div class="tab-content">
    <div class="tab-pane fade in active show">
      <div class="col-12" permissions='[{"Name":"{{permission.name}}", "Level":"{{permission.readOnlyLevel}}"}]'>
        <div class="d-flex flex-wrap pb-2">
          <div class="col-5 display-flex ps-pix-20">
            <pos-icon [icon]="icons.licenseIdCard"
              class="pe-pix-10 vertical-center-align page-content__header-icon"></pos-icon>
            <h3 class="page-content__header">License</h3>
          </div>
          <div class="flex-grow-1">
            <a class="close pe-pix-20 pointer float-end" routerLink="../">×</a>
            <button class="btn btn-lg bg-theme-separator white float-end normal me-pix-15 pos-button"
              (click)="updateLicenseInfo()" type="submit" value="Update License">Update License</button>
          </div>
        </div>
        <form #formLicense="ngForm" class="edit-form" (ngSubmit)="saveLicense(formLicense.valid)" scrollup>
          <div class="col-12 pos-page-body border-top">
            <div class="col-12 pt-pix-5">
              <div>
                <h3 class="setting-title">Settings</h3>
              </div>
              <pos-row class="pos-title-content">
                <pos-column>
                  <pos-form-field label="Account ID" [fieldInfo]="fieldInfoMessages.LicenseAccountId"
                    [validationMessages]="{required: 'Account ID is required.'}"
                    inputStyle="col-md-4 col-sm-8 col-12">
                    <input posInput class="form-control" name="txtcustomerid" #txtcustomerid="ngModel"
                      [(ngModel)]="parameters.CustomerID" [required]="true" type="text" />
                  </pos-form-field>
                </pos-column>
              </pos-row>
            </div>
          </div>
          <div class="col-12 action-button-container page-footer">
            <button class="btn btn-primary btn-lg btn-save pos-button" scrollup type="submit" value="Save">Save</button>
            <button class="btn btn-secondary btn-lg btn-cancel pos-button" (click)="cancelEditing()" scrollup
              type="button" value="Cancel">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
