import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InventoryLossComponent } from './inventory-loss/components/inventory-loss.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { InventoryPrepComponent } from './inventory-prep/components/inventory-prep/inventory-prep.component';
import { FixedIngredientRecipeComponent } from './inventory-prep/components/fixed-ingredient-recipe/fixed-ingredient-recipe.component';
import { TooltipModule } from 'primeng/tooltip';
import { VariableIngredientRecipeComponent } from './inventory-prep/components/variable-ingredient-recipe/variable-ingredient-recipe.component';
import { ComponentsModule } from 'src/app/shared/components';

import { ModalModule } from 'ngx-bootstrap/modal';
import { TableModule } from '@tarktech/tark-ng-utils';
import { TableConfiguration } from 'src/app/shared';
import { InventoryLogComponent } from './inventory-log/components/inventory-log.component';
import { CheckboxModule } from 'primeng/checkbox';
import { InventoryExhaustionModule } from 'src/app/information-management/inventory-products/components/inventory-exhaustion';
import { InventoryProductEditModelModule } from 'src/app/information-management/inventory-products/components/inventory-product-edit-model/inventory-product-edit-model.module';
import { InventoryReceivingComponent } from './inventory-receiving/components/inventory-receiving/inventory-receiving.component';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmInventoryReceivingComponent } from './inventory-receiving/components/confirm-inventory-receiving/confirm-inventory-receiving.component';
import { InventoryReceivingEditComponent } from './inventory-receiving/components/inventory-receiving-edit/inventory-receiving-edit.component';
import { ServicingInformationService } from 'src/app/information-management/nutrition-facts/serving-information/service/inventory-serving-information.service';
import { InventoryProductNutritionService } from 'src/app/information-management/nutrition-facts';
import { InventorySubCategoryService } from 'src/app/information-management/inventory-subcategories/service';
import { VendorService } from 'src/app/information-management/vendors';
import { UnitService } from 'src/app/information-management/units';
import { InventoryProductIngredientsService } from 'src/app/information-management/inventory-product-ingredients/service';
import { RawIngredientsService } from 'src/app/information-management';
import { InventoryBatchMarkAsOrderedComponent } from './inventory-batch-mark-as-ordered/inventory-batch-mark-as-ordered.component';
import { InventoryDuplicateEntryComponent } from './inventory-receiving/components/inventory-duplicate-entry/inventory-duplicate-entry.component';
import { InventoryReceivingWarningComponent } from './inventory-receiving/components/inventory-receiving-warning/inventory-receiving-warning.component';
import { InventoryTransferComponent } from './inventory-transfer';
import { TooltipModule as bsTooltipModule } from 'ngx-bootstrap/tooltip';


@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    NgSelectModule,
    FormsModule,
    TooltipModule,
    ModalModule.forRoot(),
    CheckboxModule,
    InventoryExhaustionModule,
    InventoryProductEditModelModule,
    TableModule.forRoot(TableConfiguration),
    CalendarModule,
    bsTooltipModule.forRoot(),
  ],
  declarations: [InventoryLossComponent, InventoryPrepComponent, FixedIngredientRecipeComponent, VariableIngredientRecipeComponent,
    InventoryLogComponent, InventoryReceivingComponent, ConfirmInventoryReceivingComponent,
    InventoryReceivingEditComponent, InventoryBatchMarkAsOrderedComponent, InventoryDuplicateEntryComponent, InventoryReceivingWarningComponent, InventoryTransferComponent],
  exports: [FixedIngredientRecipeComponent],
  providers: [ServicingInformationService, InventoryProductNutritionService, InventorySubCategoryService, VendorService, UnitService,
    InventoryProductIngredientsService, RawIngredientsService]
})
export class InventoryModule { }
