import { Component, OnDestroy, OnInit } from '@angular/core';
import { RabbitMQService } from '../../../../shared/services/rabbitmq';
import { SpinnerService, ApplicationStateService, DomainConstants, AlertsService, SalesSizeService } from '../../../../shared';
import { TerminalsService } from '../../../../configurator/terminals/services/terminals.service';
import * as _ from 'lodash';
import { Instruction } from 'src/app/instruction-terminal/interface/instruction';
import { Queue } from 'src/app/shared/components/queue/queue';
import { Subject, Subscription } from 'rxjs';
import { InstructionsService } from 'src/app/information-management/sales-products/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
declare let $: any;
@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'pos-instruction-terminal',
    templateUrl: './instruction-terminal.component.html'
})
export class InstructionTerminalComponent implements OnInit, OnDestroy {

    instructionsBuffer: Array<Instruction> = [];
    private messageQueue: Queue<Number> = new Queue<Number>();
    private acceptNewItem$: Subject<void> = new Subject<void>();
    private awaitingItem = false;

    salesSizes: any = [];
    currentTerminalId: number;
    currentTerminalName: string;
    mappedMakeTables: any = [];
    MaxItemsInScreen = 3;

    // rabbitMq subscriptions
    rabbitMqOrderUpdatesFromAllMakeTableSubscription: Subscription;
    rabbitMqOrderServedSubscription: Subscription;
    rabbitMqOrderItemDeleteSubscription: Subscription;
    rabbitMqInstructionsTerminalSubscription: Subscription;
    headerLinks = {
        tasks: false,
        home: false,
        warning: false,
        admin: false,
        time: false,
        signIn: false,
        activeOrders: false,
        reload: false,
        customLinks: false
    };
    homeState = '/instruction-terminal';
    constructor(
        private instructionService: InstructionsService,
        private salesSizesService: SalesSizeService,
        private terminalService: TerminalsService,
        private rabbitMQService: RabbitMQService,
        private spinnerService: SpinnerService,
        private applicationStateService: ApplicationStateService,
        private alertService: AlertsService
    ) {

    }

    private startRetrieving(): void {
        this.acceptNewItem$.subscribe({
            next: this.retrieveFromQueue
        });
    }

    private retrieveFromQueue = async (): Promise<void> => {
        console.log('Call retrieveFromQueue orderProductId');
        this.awaitingItem = true;
        const orderProductId = await this.messageQueue.dequeue();
        this.awaitingItem = false;
        console.log(orderProductId);
        if (orderProductId) {
            this.getInstructions(orderProductId);
        }

    }
    ngOnDestroy() {
    }
    private addToBuffer(instruction: Instruction): void {
        if (this.instructionsBuffer.length >= this.MaxItemsInScreen) {
            throw new Error(`Buffer overflow: Only ${this.instructionsBuffer} or less items can be shown to the instruction terminal.`);
        }
        if (instruction.SalesProductId) {
            this.instructionsBuffer.push(instruction);
        }
        this.tryGetNewItem();
    }

    private removeFromBuffer(indexOfItemInBuffer: any) {
        this.instructionsBuffer.splice(indexOfItemInBuffer, 1);
        this.tryGetNewItem();
    }

    addProductToQueue(orderId, orderProductId, maketableState) {
        // Find Item in Buffer
        const existingItemIndex = this.getIndexOfOrderProductInBuffer(orderProductId);
        console.log('addProductToQueue existingItemIndex');
        console.log(existingItemIndex);
        // Add only if item doesn't exist already
        if (existingItemIndex === -1) {
            const orderProduct = { OrderProductId: 0, OrderId: 0, CurrentState: '' };
            orderProduct.OrderProductId = orderProductId;
            orderProduct.OrderId = orderId;
            orderProduct.CurrentState = maketableState;
            console.log('this.messageQueue.enqueue OrderproductId');
            console.log(orderProductId);
            if (orderProductId) {
                this.messageQueue.enqueue(orderProductId);
                // this.tryGetNewItem();
            }

        }
    }

    removeProductFromQueue(orderProductId) {
        if (orderProductId) {
            console.debug('Removed Item From Queue. Item: (' + orderProductId + ')');
            this.messageQueue.remove(orderProductId);
            return true;
        }
        return false;
    }

    SetInstructionItemStyle() {
        if (this.instructionsBuffer.length == 1) {
            return {
                'width': '50%',
                'padding': '5px',
                'min-width' : '300px'
            };
        }
        return {
            'width': 100 / this.instructionsBuffer.length + '%',
            'float': 'left',
            'padding': '5px',
            'min-width' : '300px'
        };
    }

    ngOnInit() {
        this.initializeController();
        this.startRetrieving();

    }

    private tryGetNewItem = () => {
        if (!this.awaitingItem && this.instructionsBuffer.length < this.MaxItemsInScreen) {
            this.acceptNewItem$.next();
        }
    }

    initializeController() {
        this.currentTerminalId = this.applicationStateService.terminalId;
        this.currentTerminalName = this.applicationStateService.terminalName;
        this.spinnerService.show();
        this.getSalesSizes();
        this.getTerminalProperies();
        this.getMakeTableMappedTerminals();
        setTimeout(() => {
            this.subscribeRabbitMQ();
            this.getMappedOrderEntryTerminals();
        });
    }

    subscribeRabbitMQ() {
        const self = this;
        this.rabbitMqOrderServedSubscription = this.rabbitMQService.subscribeToOrderServedMessage$()
            .subscribe({
                next: (message: any) => {
                    const servedOrderId = parseInt(message.Payload.OrderId);
                    self.removeItemsOfOrder(servedOrderId);
                }
            });

        this.rabbitMqOrderItemDeleteSubscription = this.rabbitMQService.subscribeToOrderItemDeletedMessage$()
            .subscribe({
                next: (message: any) => {
                    this.removeOrderProduct(message.Payload.OrderId);
                }
            });
    }

    private removeOrderProduct(orderProductId: number) {
        const indexOfItemInBuffer = this.getIndexOfOrderProductInBuffer(orderProductId);

        if (indexOfItemInBuffer >= 0) {
            this.removeFromBuffer(indexOfItemInBuffer);
            this.removeProductFromQueue(orderProductId);
        } else {
            console.error('Can\'t find the item being removed in buffer.');
        }
    }

    private getSalesSizes() {
        this.salesSizesService.getSalesProductSizes(0)
            .subscribe(
                (response) => {
                    this.salesSizes = response;
                },
                this.alertService.showApiError,
                () => {
                    this.spinnerService.hide();
                });

    }

    subscribeOrderDeletedMessage(orderEntryTerminalId) {
        this.rabbitMQService.subscribeToOrderDeletedMessage$(orderEntryTerminalId)
            .pipe(untilDestroyed(this))
            .subscribe({
                next: (message) => {
                    if (message.Source.Id !== this.applicationStateService.terminalId) {
                        this.removeItemsOfOrder(message.Payload.OrderId);
                    }
                }
            });
    }

    subscribeOrderItemStateChangedMessage(orderEntryTerminalId) {
        this.rabbitMQService.subscribeToOrderItemStateChangedMessage$(orderEntryTerminalId)
            .pipe(untilDestroyed(this))
            .subscribe({
                next: (message: any) => {
                    const mappedTerminal = $.grep(this.mappedMakeTables, (terminal) => {
                        return terminal.id == message.Source.Id;
                    });
                    const orderDetails = message.Payload.OrderDetails;
                    if (orderDetails.OrderItems[0]?.MakeTableState === DomainConstants.MakeTableStates.CRAFTING) {
                        /**
                         * Order product configuration issue: Screen is not marked as main product,
                         *  where actual product button is placed.
                         * So at the time of order place order_products.SalesProductId set -1
                         *  and further process of OrderProductGetInstructions is based on order_products.SalesProductId,
                         *  so it will not return proper data, and js will break.
                        */

                        if (orderDetails.OrderItems[0]?.SalesProductId > 0) {
                            if (mappedTerminal.length) {
                                console.log('addProductToQueue called');
                                this.addProductToQueue(orderDetails.Id, orderDetails.OrderItems[0].Id,
                                    orderDetails.OrderItems[0].MakeTableState);
                            }
                        } else {
                            // $log.debug("Order Product Configuration Issue.
                            // actual product screen is not marked as main product in order: " + orderProduct.order_id);
                        }
                    } else {
                        const orderProductId = orderDetails.OrderItems[0]?.Id;
                        // Remove the product if it's state is changed
                        this.removeProduct(orderProductId);
                        this.removeProductFromQueue(orderProductId);
                    }

                }, error: () => {
                    console.log('error while connecting to RabbitMQ.');
                }
            });
    }

    private getMappedOrderEntryTerminals() {
        this.instructionService.getMappedOrderEntryTerminals(this.currentTerminalId)
            .subscribe(
                (mappedOrderEntryTerminals) => {
                    mappedOrderEntryTerminals.forEach((orderEntryTerminal) => {
                        this.subscribeOrderDeletedMessage(orderEntryTerminal.Id);
                        this.subscribeOrderItemStateChangedMessage(orderEntryTerminal.Id);
                    });
                },
                this.alertService.showApiError,
                () => {
                    this.spinnerService.hide();
                });
    }

    private getMakeTableMappedTerminals() {
        this.terminalService.getMappedTerminals(this.currentTerminalId, DomainConstants.TerminalTypes.MAKE_TABLE.Name)
            .subscribe(
                (response) => {
                    this.getMappedMakeTablesCompleted(response);
                },
                this.alertService.showApiError
                ,
                () => {
                    this.spinnerService.hide();
                });
    }

    private getTerminalProperies() {
        this.terminalService.getTerminalProperties(this.currentTerminalId)
            .subscribe(
                (properties) => {
                    if (properties && properties.length > 0) {
                        const maxProductProperty = _.find(properties, (property) => {
                            return property.PropertyKey === DomainConstants.TerminalProperties.INSTRUCTION_MAX_PRODUCTS.Key;
                        });

                        if (maxProductProperty) {
                            this.MaxItemsInScreen = parseInt(maxProductProperty.PropertyValue);
                            this.tryGetNewItem();
                        }
                    }
                },
                this.alertService.showApiError,
                () => {
                    this.spinnerService.hide();
                });
    }

    removeProduct(orderProductId) {
        const indexToRemove = this.getIndexOfOrderProductInBuffer(orderProductId);
        if (indexToRemove >= 0) {
            this.removeFromBuffer(indexToRemove);
            console.debug('Remove Item From Instruction List. Item: (' + orderProductId + ')');
        }
        this.removeProductFromQueue(orderProductId);
    }

    productUpdateCallBack = (message) => {
        const orderDetailsForInstruction = message.Payload.OrderProductDetails;
        if (orderDetailsForInstruction.length > 0
            && orderDetailsForInstruction[0]?.MakeTableState === DomainConstants.MakeTableStates.CRAFTING) {
            console.debug('RabbitMQ Message Add Item In Queue. Order: '
                + orderDetailsForInstruction[0].OrderId + ', Item: '
                + orderDetailsForInstruction[0].MakeTableText + ' (' + orderDetailsForInstruction[0].Id + '), Sent On: '
                + message.Payload.SentOn, 'primary');
            this.addProductToQueue(orderDetailsForInstruction[0].OrderId, orderDetailsForInstruction[0].Id,
                orderDetailsForInstruction[0].MakeTableState);

        } else if (orderDetailsForInstruction[0]?.MakeTableState === DomainConstants.MakeTableStates.READY ||
            orderDetailsForInstruction[0].MakeTableState === DomainConstants.MakeTableStates.DONE) {
            this.removeProduct(orderDetailsForInstruction[0].Id);
            this.removeProductFromQueue(orderDetailsForInstruction[0].Id);
        }
    }

    getMappedMakeTablesCompleted(response) {
        this.mappedMakeTables = response;
        if (this.mappedMakeTables.length == 0) {
            this.rabbitMqOrderUpdatesFromAllMakeTableSubscription = this.rabbitMQService.subscribeToOrderUpdatesFromAllMakeTables$()
                .subscribe({
                    next: (message: any) => {
                        this.productUpdateCallBack(message);
                    }, error: () => {
                        console.log('error while connecting to RabbitMQ.');
                    }
                });
        }
    }

    removeItemsOfOrder(orderId) {
        const orderProducts = this.getOrderProductsForOrder(orderId);
        orderProducts.forEach((instructionOrderProduct) => {
            this.removeProduct(instructionOrderProduct.OrderProductId);
        });
        const orderProductIdsFromQueue = this.getOrderProductsForOrderFromQueue();
        orderProductIdsFromQueue.forEach((orderProductId) => {
            this.removeProductFromQueue(orderProductId);
        });

    }

    getOrderProductsForOrderFromQueue() {
        let orderProductIds = [];
        orderProductIds = this.messageQueue.getValues();
        return orderProductIds;
    }

    getOrderProductsForOrder(orderId) {
        let orderProducts = [];
        orderProducts = _.filter(this.instructionsBuffer, (instruction) => {
            return instruction.OrderId == orderId;
        });
        return orderProducts;
    }

    getIndexOfOrderProductInBuffer(orderProductId) {
        console.log('getIndexOfOrderProductInBuffer instructionsBuffer');
        console.log(this.instructionsBuffer);
        return _.findIndex(this.instructionsBuffer, (item) => {
            return item.OrderProductId === orderProductId;
        });
    }

    getInstructions(orderProductId) {
        console.log('Retrieve Item From Queue.Item: (' + orderProductId + ')');

        const bufferIndex = this.getIndexOfOrderProductInBuffer(orderProductId);
        // Ignore if item is already there in the buffer
        if (bufferIndex == -1 && orderProductId) {
            this.instructionService.getInstructions(orderProductId)
                .subscribe(
                    (response) => {
                        console.log('getInstructions reponse');
                        console.log(response);
                        response.ItemSelectedOn = new Date();
                        this.getInstructionsCompleted(response);
                        this.rabbitMQService.sendOrderProductIsCraftingMessage(this.currentTerminalId, response.OrderId, orderProductId, response.ItemSelectedOn);
                    },
                    (errorResponse) => {
                        console.debug('Display Item For Instruction[error]. OrderProductId: ' + orderProductId + ')', 'danger');
                        this.alertService.showApiError(errorResponse);
                    },
                    () => {

                    });
        }
    }

    getInstructionsCompleted(response) {
        $.each(this.salesSizes, function (value, key) {
            if (key.Id == response.SizeId) {
                response.Size = key.MakeTableSize;
                return false;
            }
        });
        this.addToBuffer(response);
        console.debug('Display Item For Instruction[success]. Order: ' + response.OrderId + ', Item: ' + response.SalesProductName + ' (' + response.OrderProductId + ')', 'primary');
    }
}
