import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';
import { Equipment } from '../interface/equipment';

@Injectable({
    providedIn: 'root'
})
export class EquipmentService extends BaseCrudService<Equipment> {

    equipmentApiUrl = '';
    constructor(httpService: HttpClient) {
        super('equipments', httpService);
        this.equipmentApiUrl = RuntimeConstants.API_PATH + 'equipments';
    }
    newEquipment(): Equipment {
        return {
            Id: 0,
            EquipmentTypeId: null,
            InventoryZoneId: null,
            Name: '',
            Description: '',
            EquipmentType: null,
            InventoryZone: '',
            Logs: [],
            DeviceAddress: null,
        };
    }

    getEquipmentList() {
        return this.httpService.get(`${this.equipmentApiUrl}/all`);
    }
}
