<pos-edit-wrapper [headingIcon]="icons.layoutBoxes" heading="Layout" (close)="cancelEditing()" [isPopup]="false">
  <form (ngSubmit)="saveLayout(formLayout.valid)" #formLayout="ngForm" class="edit-form">
    <div class="col-12 pos-modal-body border-top content-scroll">
      <div class="form-horizontal">
        <div class="col-12">
          <div>
            <h3 class="setting-title">Settings</h3>
          </div>
          <pos-row class="pos-title-content">
           <pos-column>
              <pos-form-field inputStyle="d-flex">
                <p-checkbox posInput name="quantityKeypad" #quantityKeypad="ngModel"
                  [(ngModel)]="parameters.QuantityKeypad" binary="true" label="Select quantity as keypad">
                </p-checkbox>
              </pos-form-field>
            </pos-column>

            <pos-column>
              <pos-form-field inputStyle="d-flex">
                <p-checkbox posInput name="showDisabledButtons" #showDisabledButtons="ngModel"
                  [(ngModel)]="parameters.ShowDisabledButtons" binary="true" label="Show inactive buttons as grayed out">
                </p-checkbox>
                <field-info [info]="fieldInfoMessages.LayoutShowInactiveButton" placement="right"
                  styleClass="float-end pt-0"></field-info>
              </pos-form-field>
            </pos-column>

            <pos-column>
              <pos-form-field inputStyle="d-flex">
                <p-checkbox posInput name="returnToHomeAfterEachProduct" #returnToHomeAfterEachProduct="ngModel"
                  [(ngModel)]="parameters.ReturnToHomeAfterEachProduct" binary="true" label="Return to home after product selection">
                </p-checkbox>
                <field-info [info]="fieldInfoMessages.LayoutReturnHomeAfterProductSelection" placement="right"
                  styleClass="float-end pt-0"></field-info>
              </pos-form-field>
            </pos-column>

            <pos-column>
              <pos-form-field inputStyle="d-flex">
                <p-checkbox posInput name="sortProductComponentsAlphabetically"
                  #sortProductComponentsAlphabetically="ngModel"
                  [(ngModel)]="parameters.SortProductComponentsAlphabetically" binary="true" label="Sort product components alphabetically">
                </p-checkbox>
                <field-info [info]="fieldInfoMessages.LayoutSortProduct"
                  styleClass="float-end pt-0" placement="right">
                </field-info>
              </pos-form-field>
            </pos-column>

            <pos-column>
              <pos-form-field inputStyle="d-flex">
                <p-checkbox posInput name="showMenuSearchBox" #showMenuSearchBox="ngModel"
                  [(ngModel)]="parameters.ShowMenuSearchBox" binary="true" label="Show Menu Search">
                </p-checkbox>
                <field-info [info]="fieldInfoMessages.LayoutShowMenuSearch" placement="right"
                  styleClass="float-end pt-0"></field-info>
              </pos-form-field>
            </pos-column>
          </pos-row>
        </div>
        <div class="col-12 section-border">
          <div>
            <h3 class="setting-title">Display Items</h3>
          </div>
          <pos-row class="pos-title-content">
            <pos-column>
              <pos-form-field inputStyle="d-flex">
                <p-checkbox posInput name="breadCrumbs" #breadCrumbs="ngModel" [(ngModel)]="parameters.BreadCrumbs"
                  binary="true" label="Show Breadcrumbs">
                </p-checkbox>
                <field-info [info]="fieldInfoMessages.LayoutShowBreadcrumbs"
                  styleClass="pull-right pt-0"></field-info>
              </pos-form-field>
            </pos-column>

            <pos-column>
              <pos-form-field>
                <p-checkbox posInput name="showButtonBar" #showButtonBar="ngModel"
                  [(ngModel)]="parameters.ShowButtonBar" binary="true" label="Use Button Bar">
                </p-checkbox>
              </pos-form-field>
            </pos-column>

            <pos-column>
              <pos-form-field>
                <p-checkbox posInput name="showActiveOrderPanel" #showActiveOrderPanel="ngModel"
                  [(ngModel)]="parameters.ShowActiveOrderPanel" binary="true" label="Show Active Order Panel">
                </p-checkbox>
              </pos-form-field>
            </pos-column>

            <pos-column>
              <pos-form-field>
                <p-checkbox posInput name="reportDatesEditable" #reportDatesEditable="ngModel"
                  [(ngModel)]="parameters.ReportDatesEditable" binary="true" label="Report Dates Edit: Clicks">
                </p-checkbox>
              </pos-form-field>
            </pos-column>
          </pos-row>
        </div>
        <div class="col-12 section-border">
          <div>
            <h3 class="setting-title">Heading Buttons</h3>
          </div>
          <pos-row class="pos-title-content">
            <pos-column>
              <pos-form-field inputStyle="d-flex">
                <p-checkbox posInput name="headingButtonTasks" #headingButtonTasks="ngModel"
                  [(ngModel)]="parameters.HeadingButtonTasks" binary="true" label="Show Shift Tasks">
                </p-checkbox>
                <field-info [info]="fieldInfoMessages.LayoutShowShiftTask"
                  styleClass="pull-right pt-0">
                </field-info>
              </pos-form-field>
            </pos-column>

            <pos-column>
              <pos-form-field>
                <p-checkbox posInput name="headingButtonAccounts" #headingButtonAccounts="ngModel"
                  [(ngModel)]="parameters.HeadingButtonAccounts" binary="true" label="Show Accounts">
                </p-checkbox>
              </pos-form-field>
            </pos-column>
          </pos-row>
        </div>
        <div class="col-12 section-border">
          <div>
            <h3 class="setting-title">
              <span class="display-flex">
                Function Buttons
                <field-info [info]="fieldInfoMessages.LayoutFunctionButtons" styleClass="pt-0">
                </field-info>
              </span>
            </h3>
          </div>
          <pos-row class="pos-title-content">
            <pos-column sm="2" xs="6" *ngFor="let functionDetails of functionLayout; let i=index;">
              <pos-form-field inputStyle="col-12">
                <ng-select posInput [items]="functionButtons" appendTo="body" bindLabel="Name" bindValue="Selection"
                  [(ngModel)]="functionDetails.Selection" name="functionButton{{i}}" [clearable]="false"
                  [clearOnBackspace]="false"></ng-select>
              </pos-form-field>
            </pos-column>
          </pos-row>
        </div>
        <div class="col-12 section-border">
          <div>
            <h3 class="setting-title">Toaster Messages</h3>
          </div>
          <pos-row class="pos-title-content">
            <pos-column>
              <pos-form-field label="Position" [fieldInfo]="fieldInfoMessages.LayoutToasterPosition" inputStyle="col-sm-3 col-xs-9">
                <ng-select posInput [items]="toasterPositions" appendTo="body" bindLabel="Name" bindValue="Value"
                  [(ngModel)]="parameters.ToasterPosition" name="toasterPosition" [clearable]="false"
                  [clearOnBackspace]="false"></ng-select>
              </pos-form-field>

              <pos-form-field label="Timeout" [fieldInfo]="fieldInfoMessages.LayoutToasterPosition" inputStyle="col-sm-3 col-xs-9">
                <input posInput type="number" class="form-control" [(ngModel)]="parameters.ToasterTimeout"
                  name="toasterTimeout" />
              </pos-form-field>

              <pos-form-field inputStyle="d-flex">
                <p-checkbox posInput name="toasterCloseButton" #toasterCloseButton="ngModel"
                  [(ngModel)]="parameters.ToasterCloseButton" binary="true" label="Show Close Button">
                </p-checkbox>
                <field-info [info]="fieldInfoMessages.LayoutToasterShowClose"
                  styleClass="pull-right pt-0"></field-info>
              </pos-form-field>
            </pos-column>
          </pos-row>
        </div>
      </div>
    </div>
    <div class="col-12 action-button-container page-footer">
      <button class="btn btn-primary btn-lg btn-save pos-button" scrollup type="submit">
        Save
      </button>
      <button class="btn btn-secondary btn-lg btn-cancel pos-button" routerLink="../" scrollup type="button"
        value="Cancel">Cancel</button>
    </div>
  </form>
</pos-edit-wrapper>
