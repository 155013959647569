import { Component, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseFormComponent } from 'src/app/shared/components';
import { FormUtilityService } from 'src/app/shared/services/form-utility.service';

@Component({
    selector: 'pos-sales-group-edit-wrap',
    templateUrl: './sales-group-edit-wrap.component.html',
    styleUrls: ['./sales-group-edit-wrap.component.scss']
})
export class SalesGroupEditWrapComponent extends BaseFormComponent implements OnInit {
    form: NgForm;
    salesGroupTransParam: any;
    @Input() salesGroupId: number = null;
    initialize = false;
    @Input() fromListPage = true;
    get getForm(): NgForm {
        return this.form
    }
    constructor(private router: Router,
        private route: ActivatedRoute,
        formUtilityService: FormUtilityService) {
        super(formUtilityService);
        const navigation = this.router.getCurrentNavigation();
        this.salesGroupId = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
        this.fromListPage = (navigation?.extras?.state && navigation?.extras?.state.fromListPage) ?? true;
        this.initialize = false;
    }

    ngOnInit() {
        this.initialize = true;
    }

    onClose($event: any) {
        this.router.navigate(['sales-group'], { relativeTo: this.route.parent});
    }

    onFormChange($event: NgForm) {
        this.form = $event;
        this.setInitialValue();
    }
}
