<pos-edit-wrapper heading="{{ editIndex > 0 ? 'Edit' : 'Add' }} Baseline Expense" (close)="cancelBaselineExpense()">
    <form (ngSubmit)="saveBaselineExpense(baselineExpensesForm.valid)" #baselineExpensesForm="ngForm" class="edit-form">
        <div class="col-xs-12 pos-page-body">
            <pos-row>
                <pos-column>
                    <pos-form-field label="Expense" [fieldInfo]="fieldInfoMessages.BaselineExpenses"
                        [validationMessages]="{ required: 'Please enter expense.'}">
                        <input posInput class="form-control" name="textExpense" [(ngModel)]="baselineExpense.Expense"
                            type="text" [required]="true" #txtExpense="ngModel" />
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Amount" [fieldInfo]="fieldInfoMessages.BaselineExpensesAmount"
                        [validationMessages]="{ required: 'Please enter amount.', min: 'Amount must be greater than zero.'}">
                        <p class="input-group col-170">
                            <span class="input-group-addon">{{currencySymbol}}</span>
                            <input posInput class="form-control price-input" name="textAmount"
                                [(ngModel)]="baselineExpense.Amount" type="number" step="0.1" min="0.1"
                                [required]="true" #txtAmount="ngModel" posDecimal/>
                        </p>
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button class="btn btn-primary btn-lg btn-save pos-button" type="submit">Save</button>
            <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="cancelBaselineExpense()"
                type="button">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
