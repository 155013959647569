import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ComponentsModule } from '../shared';
import { HeaderEntryModule } from '../header-entry';
import { ORDER_STATUS_STATES } from './order-status-routes';
import { OrderStatusService } from './services/order-status.service';
import { OrderStatusComponent } from './order-status/order-status.component';
import { RouterModule } from '@angular/router';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ModalModule.forRoot(),
    ComponentsModule,
    RouterModule.forChild(ORDER_STATUS_STATES),
    HeaderEntryModule
  ],
  providers: [OrderStatusService],
  declarations: [OrderStatusComponent]
})
export class OrderStatusModule { }
