<pos-edit-wrapper heading="Choose color for screen" (close)="onCancel()">
  <form #formColor="ngForm" (ngSubmit)='updateButtonColor(formColor.valid)'>
    <div class="col-12 pos-modal-body overflow-hidden">
      <pos-row>
        <pos-column>
          <pos-form-field label="Color" [validationMessages]="{required: 'Please select color.'}">
            <pos-color-picker posInput name="buttonBackgroundColor" [(ngModel)]="color" [required]="true">
            </pos-color-picker>
          </pos-form-field>
        </pos-column>
      </pos-row>
    </div>
    <div class="clearfix"></div>
    <div class="col-12 action-button-container popup-footer">
      <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" value="Save">Ok</button>
      <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" value="Cancel"
        (click)="onCancel()">Cancel</button>
    </div>
  </form>
</pos-edit-wrapper>
