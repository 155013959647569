<pos-edit-wrapper heading="{{ id > 0 ? 'Edit' : 'Add' }} HIC" (close)="cancelHardwareInterface()">
  <form (ngSubmit)="saveHardwareInterface(hardwareInterfaceForm.valid)" #hardwareInterfaceForm="ngForm"
    class="edit-form">
    <div class="col-12 pos-modal-body form-horizontal screen-scroll">
      <pos-row>
        <pos-column>
          <pos-form-field label="Name" [fieldInfo]="fieldInfoMessages.HardwareInterfaceName" [validationMessages]="{required: 'Please enter name.'}">
            <input posInput type="text" class="form-control" name="HICName" [(ngModel)]="HIC.Name" [required]="true" #HICName="ngModel" />
          </pos-form-field>
        </pos-column>

        <pos-column>
          <pos-form-field label="Monitor URL">
            <input posInput class="form-control" name="HICMonitorUrl" [(ngModel)]="HIC.MonitorUrl" type="text" />
          </pos-form-field>
        </pos-column>
      </pos-row>
    </div>
    <div class="clearfix"></div>
    <div class="col-12 action-button-container popup-footer">
      <button class="btn btn-primary btn-lg normal btn-save pos-button" scrollup type="submit" value="Save">Save</button>
      <button class="btn btn-secondary btn-lg normal btn-cancel-modal pos-button" (click)="cancelHardwareInterface()" type="button"
        value="Cancel">Cancel</button>
    </div>
  </form>
</pos-edit-wrapper>
