<div class="order bottom-time-block text-nowrap order-bottom-clock" ngbDropdown>
    <pos-display-time></pos-display-time>
    <span ngbDropdownToggle aria-expanded="true" data-toggle="dropdown" id="dropdownMenu1"
        class="bottom-time-block__time pointer setting-icon-size">
        <pos-icon [icon]="icons.cog"></pos-icon>
    </span>
    <ul ngbDropdownMenu aria-labelledby="dropdownMenu1" class="dropdown-menu maketable-view-dropdown pointer" role="menu">
        <li ngbDropdownItem role="presentation" class="ps-pix-5" *ngFor="let view of views | keyvalue"
            (click)="changeLayoutClicked(view.value)" [hidden]="view.value == currentLayout">
            Change to {{view.value}} layout
        </li>
    </ul>
</div>
