import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { downloadWhite } from '../icon';
import { ModalComponent } from '../modal';
import { saveAs } from 'file-saver';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AlertsService } from '../../services';
@UntilDestroy({checkProperties: true})
@Component({
  selector: 'pos-audio',
  templateUrl: './audio.component.html',
})
export class AudioComponent extends ModalComponent implements OnInit {
  @Input() audioUrl: string = null;
  @Input() fileName: string = null;
  public icons = { downloadWhite };
  constructor(modalRef: BsModalRef, private renderer: Renderer2, private el: ElementRef, private alertService: AlertsService) {
    super(modalRef);
  }
  ngOnInit(): void {
    const container = this.el.nativeElement.querySelector('#audio-div');
    const audioElement = this.renderer.createElement('audio');
    audioElement.src = this.audioUrl;
    audioElement.controls = true;
    audioElement.setAttribute('controlsList', 'nodownload');
    this.renderer.appendChild(container, audioElement);
    audioElement.addEventListener('error', (err) => {
      if (audioElement.error.code == 4) {
        this.alertService.renderErrorMessage("Track not found.");
      } else {
        this.alertService.renderErrorMessage("Error occurred while playing the audio.");
      }
    });
    audioElement.play();
  }

  downloadAudio = () => {
    saveAs(this.audioUrl, this.fileName.replace('.wav', ""));
  }

  cancel() {
    this.close.emit();
  }
}
