<h3 class="setting-title mb-pix-10">Attributes</h3>
<div class="tab-top-border border-top-0 overflow-auto-tabs m-0">
  <div class="col-12">
    <ng-table #gridComponent [sort]="false" [search]="false" [paginator]="false" [columns]="accountAttributeColumns"
      [data]="accountAttributes">
    </ng-table>
    <ng-template let-data="rowData" #nameTemplate>
      <span>
        <span>{{data.AttributeValue}}</span>
      </span>
    </ng-template>
  </div>
</div>
