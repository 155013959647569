import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared';
import { CreatedShake, ShakeCreatorRequest } from '../../interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShakeCreatorService {

  apiUrl = `${RuntimeConstants.API_PATH}AIShake`;

  constructor(private httpClient: HttpClient) {
  }

  createShake(shakeCreatorRequest: ShakeCreatorRequest): Observable<CreatedShake> { 
    return this.httpClient.post<CreatedShake>(`${this.apiUrl}/create`, shakeCreatorRequest);
  }
}
