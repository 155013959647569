import { Injectable, EventEmitter } from '@angular/core';
import { KioskScreen } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class KioskNavigationBroadcastingService {
  navigationChange = new EventEmitter<KioskScreen>();
  newAiShakeCreated = new EventEmitter<any>();
  kioskTerminalStateChange = new EventEmitter<any>();
  constructor() { }
  changeNavigation = (screen: KioskScreen) => {
    if (screen)
      this.navigationChange.emit(screen);
  }

  changeKioskTerminalState = () => {
    this.kioskTerminalStateChange.emit();
  }

}
