<ng-template #versionInfoModal>
    <div>
        <div>
            <h4 class="modal-content__heading">An update is available</h4>
        </div>
        <div class="">
            <div class="col-md-12 col-sm-12 pt-pix-10 pb-pix-10 text-center">
                {{autoRefreshPromptMessage}}
            </div>
        </div>
        <div class="col-xs-12 action-button-container popup-footer pb-pix-10">
            <button type="button" (click)="reloadNow()" class="btn btn-success btn-lg btn-save pos-button">Refresh Now
            </button>
            <button type="button" (click)="dismissModal()" class="btn btn-danger btn-lg btn-cancel-modal pos-button">Dismiss
            </button>
        </div>
        <div class="clearfix"></div>
    </div>
</ng-template>
