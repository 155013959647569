import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ApplicationStateService, RuntimeConstants, UserIdleService } from 'src/app/shared';
import { ModalComponent } from 'src/app/shared/components';
import { KioskNavigationService } from '../..';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pos-kiosk-cancel-order-screen',
  templateUrl: './kiosk-cancel-order-screen.component.html',
  styleUrls: ['./kiosk-cancel-order-screen.component.scss']
})
export class KioskCancelOrderScreenComponent extends ModalComponent {
  @Input() intervalSeconds: number = 0;
  baseImagePath = `${RuntimeConstants.IMAGE_BASE_PATH}/`;
  buttonText = 'Start a new order';
  backgroundImagePath = 'order-kiosk/default-screens/';
  backgroundImage = '';
  userIdleSubscription: Subscription;

  constructor(public bsModalRef: BsModalRef,
    private idleService: UserIdleService,
    private kioskNavigationService: KioskNavigationService,
    private applicationStateService: ApplicationStateService
  ) {
    super(bsModalRef);
  }

  ngOnInit() {
    const settingParams = this.applicationStateService?.settingParam;
    this.buttonText = settingParams?.OrderKioskStartOverButtonText;
    this.backgroundImage = this.backgroundImagePath + settingParams?.OrderKioskOrderCancelledImage;
    this.subscribeToUserIdleSubscription();
  }

  startOver() {
    this.stopUserIdleService();
    this.hide({
      newOrder: true
    })
  }

  subscribeToUserIdleSubscription() {
    if (this.userIdleSubscription) {
      this.userIdleSubscription.unsubscribe();
    }
    this.userIdleSubscription = this.idleService.initialize(this.kioskNavigationService.userIdleTimeout < 30 ? this.kioskNavigationService.userIdleTimeout : 30, () => this.startOver());
  }

  stopUserIdleService() {
    this.idleService.stop();
    this.userIdleSubscription?.unsubscribe();
  }
}
