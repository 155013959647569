import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalComponent, NumpadOptions } from 'src/app/shared';

@Component({
  selector: 'pos-kiosk-numpad',
  templateUrl: './kiosk-numpad.component.html',
  styleUrls: ['./kiosk-numpad.component.scss']
})
export class KioskNumpadComponent extends ModalComponent {
  @Input() qty = 0;
  @Input() heading = '';
  numpadOptions: NumpadOptions;
  
  constructor(bsModalRef: BsModalRef) {
    super(bsModalRef);
  }

  ngOnInit() {
    this.numpadOptions = {
      enterButton: true,
      enterButtonText: 'OK',
      doubleZero: false,
      startWithDecimal: false,
      allowDecimal: false,
      isReadOnly: true,
      min: 1
    };
  }

  onCancel = () => {
    this.hide({});
  }

  onSubmit = (res) => {
    this.hide({ qty: res.value });
  }
  
}
