<div>
    <button aria-label="Close" class="close" type="button" (click)="cancel()">&times;</button>
    <h4 class="modal-content__heading">Warnings and Missing Tags</h4>
</div>
<div class="pt-pix-15 px-pix-20 overflow-auto-tabs" [ngStyle]="{'max-height': height + 'px'}">
    <div class="loading-block" [hidden]="!showSpinner">
        <pos-spinner-icon></pos-spinner-icon>
    </div>
    <div [hidden]="showSpinner">
        <h3 class="page-content__header">{{button.ButtonText}}</h3>
        <div class="ps-pix-10 pt-pix-10">
            <div *ngIf="tags.length > 0" class="display-flex flex-flow-wrap">
                <div class="col-12 p-0 pb-pix-10 title">
                    Missing Tag(s)
                </div>
                <div class="ps-pix-5">
                    <span *ngFor="let tag of tags" [ngClass]="{'relative top-n8': !tag.Icon}">
                        <p-chip [label]="tag.Name" class="pe-pix-5" image="{{imagePath}}/{{tag.Icon}}" *ngIf="tag.Icon"
                            [style]="{'background-color': tag.Color, 'color': getContrastColor(tag.Color)}">
                        </p-chip>
                        <p-chip [label]="tag.Name" class="pe-pix-5" *ngIf="!tag.Icon"
                            [style]="{'background-color': tag.Color, 'color': getContrastColor(tag.Color)}">
                        </p-chip>
                    </span>
                </div>
            </div>
            <div *ngIf="dietaryWarnings" [ngClass]="{'pt-pix-10': tags.length > 0}">
                <div class="col-12 p-0 title">
                    Dietary Warning(s)
                </div>
                <div class="col-12 p-0 pt-pix-5 pb-pix-5"
                    *ngFor="let warning of dietaryWarnings | keyvalue; let last = last;"
                    [ngClass]="{'border-bottom': !last}">
                    <div class="col-12 ps-pix-7">
                        <b>{{ warning.key }}</b>
                    </div>
                    <div class="col-12 ps-pix-20 pe-0">
                        <span *ngFor="let val of warning.value; let last = last;">
                            {{ val.ProductName }}<span *ngIf="!last">, </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-12 action-button-container popup-footer pb-pix-10">
    <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" (click)="cancel()">Close</button>
</div>
<div class="clearfix"></div>
