import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CacheFlushService {

  cacheFlushApiBasePath = `${RuntimeConstants.API_PATH}cache-flush`;

  constructor(private httpService: HttpClient) { }

  refreshCache() {
    return this.httpService.post(this.cacheFlushApiBasePath + '/refresh', null);
  }
}
