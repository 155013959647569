type ItemEnqueuedHandler<T> = {
    callback: (val: T) => void,
    identifier: string
  };
  
  export class Queue<T> {
    private _values: Array<T>;
    private _onItemEnqueued: Array<ItemEnqueuedHandler<T>>;
  
    constructor() {
      this._values = [];
      this._onItemEnqueued = [];
    }  
  
    enqueue(value: T) {
      this._values.push(value);
  
      this.raiseItemEnqueued(value);
    }
  
    async dequeue(): Promise<T> {
      const dequeueValue = ((resolve) => {
        const value = this._values.splice(0, 1)[0];
        resolve(value);
      })
      return new Promise<T>(resolve => {
        if (!this.isEmpty()) {
          dequeueValue(resolve);
        } else {
          this.registerItemEnqueued(() => {
            dequeueValue(resolve);
          });
        }
      });
    }
  
    peek(): T {
      if (!this.isEmpty()) {
        return this._values[this._values.length-1];
      }
      return null;
    }
  
    isEmpty(): boolean {
      return this._values.length == 0;
    }
  
    removeAt(index: number): T {
      if (index >= this._values.length) {
        throw new Error("Index out of bounds of the queue.");
      }
  
      return this._values.splice(index, 1)[0];
    }
  
    remove(item: T): T {
      const index = this._values.indexOf(item);
  
      if (index < 0) {
        //throw new Error("Can't find item in the queue");
        console.log("Can't find item in the queue");
        return;
      }
  
      return this.removeAt(index);
    }

    getValues() {
      return this._values;
    }
  
    private raiseItemEnqueued(value: T) {
      for(const eventHandler of this._onItemEnqueued) {
        eventHandler.callback(value);
        
        this.removeHandler(eventHandler);
      }
    }
  
    private registerItemEnqueued(callback: (value: T) => void): number {
      const handlerWrapper = {
        callback,
        identifier: new Date().getTime().toString()
      } as ItemEnqueuedHandler<T>;
  
      this._onItemEnqueued.push(handlerWrapper);
  
      return this._onItemEnqueued.length - 1;
    }
  
    private removeHandler(handler: ItemEnqueuedHandler<T>): void {
      const index = this._onItemEnqueued.indexOf(handler);
      if (index < 0) {
        throw new Error("Can't find the handler to remove.");
      }
  
      this._onItemEnqueued.splice(index, 1);
    }
  }
