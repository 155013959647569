import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { EquipmentProperty } from '../../interface/equipment-property';
import { GridColumn } from '@tarktech/tark-ng-utils';
import { EquipmentPropertyService } from '../../service/equipment-property.service';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { AuthenticationService } from 'src/app/shared/auth/services/authentication.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Permissions, Levels, Messages, DomainConstants } from '../../../../shared/constants/';
import { BaseListComponent } from 'src/app/shared/components/base-list.component';
import * as _ from 'lodash';
import { finalize } from 'rxjs/operators';
import { EquipmentPropertyEditComponent } from '../equipment-property-edit';
import { ApplicationStateService } from 'src/app/shared';
import { ActivatedRoute, Router } from '@angular/router';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { equipmentProperties } from 'src/app/shared/components/icon';

@Component({
    selector: 'pos-equipment-property-list',
    templateUrl: './equipment-property-list.component.html',
})
export class EquipmentPropertyListComponent extends BaseListComponent<EquipmentProperty> implements OnInit {

    permission = {
        name: Permissions.EquipmentsProperties,
        readOnlyLevel: Levels.ReadOnly,
        editLevel: Levels.Edit
    };
    equipmentPropertyColumns: Array<GridColumn> = [];
    equipmentProperties: Array<EquipmentProperty> = [];
    id: number;
    equipmentTypeId: number;
    icons = { equipmentProperties };

    constructor(
        private authenticationService: AuthenticationService,
        protected alertService: AlertsService,
        protected spinnerService: SpinnerService,
        protected equipmentPropertyService: EquipmentPropertyService,
        protected modalService: ModalService,
        protected route: ActivatedRoute,
        protected printService: PrintTableService,
        protected applicationStateService: ApplicationStateService,
        protected router: Router,
    ) {
        super(equipmentPropertyService, alertService, spinnerService, modalService, applicationStateService, printService, route);
    }

    ngOnInit() {
        this.deleteSuccessMessage = Messages.EquipmentPropertiesDeleted;
        this.equipmentPropertyColumns = this.configureGridColumns();
        this.checkPermission();
        this.loadSubscriptions();
    }
    public getGridColumns(): Array<GridColumn> {
        return [
            new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '90%' }),
        ] as Array<GridColumn>;
    }
    private checkPermission(): void {
        if (!this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
            _.remove(this.equipmentPropertyColumns, (column) => {
                return (column.Type === DomainConstants.GridColumnsTypes.EditColumn
                    || column.Type === DomainConstants.GridColumnsTypes.DeleteColumn);
            });
        }
    }
    private loadSubscriptions(): void {
        this.data$.subscribe({
            next: (res) => {
                this.equipmentProperties = res;
            }
        });
    }
    public editItem(id: number, data?: EquipmentProperty): void {
        this.id = id;
        const modalRef = this.modalService.getModalWrapper(EquipmentPropertyEditComponent);
        const modal = modalRef.show({
            animated: false,
            backdrop: 'static',
            class: 'vertical-center',
            initialState: {
                id: this.id
            }
        });

        modal.close.subscribe(res => {
            if (res && res.reload) {
                this.reload();
            }
        });
    }
    public getConfirmDeleteMessage(data: EquipmentProperty): string {
        this.id = data.Id;
        return StringUtils.format(Messages.ConfirmDeleteEquipmentProperty,
            { 'equipmentProperty': data ? data.Name : '' }
        );
    }

    public delete() {
        this.spinnerService.show();
        this.equipmentPropertyService.deleteEquipmentProperty(this.id)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (res) => {
                    this.alertService.renderSuccessMessage(this.deleteSuccessMessage);
                    this.reset();
                    this.reload();
                }, error: this.alertService.showApiError
            });
    }

    public reset(): void {
        this.id = 0;
        this.equipmentTypeId = 0;
    }

    close() {
        this.router.navigate(['../'], { relativeTo: this.route });
    }
}
