import { TerminalEndpoint } from './terminal-endpoint';

export class RabbitMQMessage<TPayload = any> {

    public Type: string;
    public Source: any;
    public Destination: any;
    public Payload: TPayload;

    constructor(messageType: string, source: any, destination: any, payload: TPayload) {
        this.Type = messageType;
        this.Source = source;
        this.Destination = destination;
        this.Payload = payload;
    }
}
