<div class="page-content">
  <div class="tab-content">
    <div class="tab-pane fade in active show">
      <div class="col-12 row g-0">
        <div class="row align-items-center g-0">
          <div class="col-8 display-flex align-items-center ps-pix-20">
            <pos-icon [icon]="icons.playListThList"
              class="pe-pix-10 vertical-center-align page-content__header-icon"></pos-icon>
            <h3 class="page-content__header">
              Playlist: {{playlistName}}
            </h3>
          </div>
          <div class="col-4 text-end pb-pix-5">
            <a class="close pe-pix-20" routerLink="/manage/system-configuration/playlists">×</a>
          </div>
        </div>
        <div class="edit-form">
          <div class="pos-page-body col-12 px-0 border-top">
            <div class="d-flex position-relative justify-content-end">
              <button
                class="btn btn-warning white normal mt-pix-3 pos-button float-end text-nowrap add-track-button"
                tooltip [title]="fieldInfoMessages.PlaylistAddTrack" (click)="mapTrack(playlistId, playlistName)"
                type="button">
                Add Track(s)
              </button>
            </div>
            <div>
              <h3 class="setting-title mt-pix-5 ms-pix-10">Tracks</h3>
            </div>
            <div class="col-12 px-2">
              <div cdkDropList (cdkDropListDropped)="onSortTrack($event)" class="row g-0 pt-0">
                <ng-container *ngFor="let track of playlistTracks">
                  <div class="row g-0 well p-pix-10 mt-pix-10 mb-0 h-42 drag-item move" cdkDrag>
                    <div class="col-7">{{track.Filename}}</div>
                    <div class="col-4">{{track.Category}}</div>
                    <div class="col-1 ps-0 mt-pix-n4 text-end">
                      <span (click)="deletePlaylistTrack(track.PlaylistId, track.TrackId, track.Filename)">
                        <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
                      </span>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 action-button-container page-footer">
        <button class="btn-secondary btn-lg btn btn-cancel pos-button"
          routerLink="/manage/system-configuration/playlists" scrollup type="button">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
