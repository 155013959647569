<pos-edit-wrapper heading="Terminal: {{terminalName}} ({{gridColumns}} X {{gridRows}})" (close)="cancelTerminalEdit()" [isPopup]="false">
    <div class="col-xs-12 p-0 border-top">
        <pos-product-build-queue [terminalId]="terminalId" [terminalDesignMode]="true"
        [isRequiredSyncData]="false" [utilizeWidthPct]="'78'">
        </pos-product-build-queue>
    </div>
    <div class="col-12 action-button-container page-footer">
        <button class="btn btn-secondary btn-lg btn-cancel pos-button" (click)="cancelTerminalEdit()" scrollup type="button"
            value="Cancel">Close</button>
    </div>
</pos-edit-wrapper>
