import { Component, OnInit, ViewChild } from '@angular/core';
import { SpinnerService } from 'src/app/shared/components/spinner';
import { AlertsService, FormUtilityService } from 'src/app/shared/services';
import { GeneralWorkflowParameter } from '../../interfaces/general-workflow-parameter';
import { GeneralWorkflowService } from '../../services/general-workflow.service';
import { finalize } from 'rxjs/operators';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { forEach } from 'lodash';
import { DomainConstants, FieldInfoMessages } from 'src/app/shared/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { cogs } from 'src/app/shared/components/icon/icons';
import { BaseFormComponent } from 'src/app/shared/components/base-form.component';
import { NgForm } from '@angular/forms';
import { PostSavedConfigurationService } from 'src/app/configurator/post-saved-configuration.service';
@Component({
  selector: 'pos-general-workflow',
  templateUrl: './general-workflow.component.html',
})
export class GeneralWorkflowComponent extends BaseFormComponent implements OnInit {

  parameters: GeneralWorkflowParameter;
  daysOfWeek = [];
  fieldInfoMessages = FieldInfoMessages;
  icons = {
    cogs
  };
  props = {
    controlBoxClass: 'col-lg-3 col-md-5 col-sm-8 col-xs-12'
  }
  @ViewChild('generalWorkflowForm') generalWorkflowForm: NgForm;
  get getForm(): NgForm {
    return this.generalWorkflowForm
  }
  constructor(
    private generalWorkflowService: GeneralWorkflowService,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private router: Router,
    private route: ActivatedRoute,
    private postSavedConfigurationService: PostSavedConfigurationService,
    formUtilityService: FormUtilityService
  ) {
    super(formUtilityService);
  }

  ngOnInit(): void {
    this.parameters = this.generalWorkflowService.newGeneralWorkflowConfigurationParameter();
    this.loadData();
    forEach(DomainConstants.WeekDay, (key, value) => {
      this.daysOfWeek.push({ Name: value });
    });
  }

  private loadData() {
    this.spinnerService.show();
    this.generalWorkflowService.getGeneralWorkflowParameters()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (params: GeneralWorkflowParameter) => {
          this.parameters = params;
        }, error: this.alertService.showApiError
      });
  }

  saveGeneralWorkflow(isValid: boolean) {
    if (isValid) {
      this.parameters.ResetMenuDisplayFilterSeconds = this.parameters.ResetMenuDisplayFilterSeconds ?? 0;
      this.parameters.ManualReleaseMaxFileCount = this.parameters.ManualReleaseMaxFileCount ?? 0;
      this.spinnerService.show();
      this.generalWorkflowService.updateGeneralWorkflowParameters(this.parameters)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: () => {
            this.alertService.renderSuccessMessage(Messages.GeneralWorkflowSaveSuccess);
            this.postSavedConfigurationService.showMessage();
          }, error: this.alertService.showApiError
        });
    }
  }

  cancel() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

}
