<div class="m-0-auto width-95"
	permissions='[{"Name":"{{permissions.LinkReportSubscriptions}}", "Level":"{{accessLevels.Access}}"}]'>
	<div class="page-content">
		<div class="tab-content">
			<div class="tab-pane fade in active show">
				<div class="col-12 px-3 pb-pix-5">
					<div class="row pb-pix-5">
						<div class="col-md-9 col-9">
							<h3 class="page-content__header">
								<pos-icon [icon]="icons.subscriptionBookmark" class="black pe-pix-5"></pos-icon> My Subscriptions
							</h3>
						</div>
						<div class="col-md-3 col-3"
							permissions='[{"Name":"{{permissions.CopyReportSubscriptions}}", "Level":"{{accessLevels.Access}}"}]'>
							<button class="btn btn-primary float-end normal pos-button white"
								(click)="showCopySubscriptionModel()">
								<pos-icon [icon]="icons.copy"></pos-icon> Copy
								Subscription
							</button>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<div class="table-responsive" *ngIf="hasSubscriptions">
								<ng-table #gridComponent [search]="false" [sort]="false" [paginator]="false"
									[columns]="inventoryProductColumns" [data]="reportSubscriptions" [scrollable]="true"
									[scrollHeight]="screenHeight.toString()">
								</ng-table>
							</div>
							<div class="alert alert-warning mbi-0 mt-pix-10 mt-pix-5" *ngIf="!hasSubscriptions">
								{{ noSubscriptionMessage }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<ng-template let-data="rowData" #infoTemplate>
	<div class="col-12 p-0" *ngFor="let schedule of data| keyvalue; let i = index;">
		<div class="col-12 p-0" *ngIf="i == 0">
			<label class="extreme-orange">
				<strong>{{schedule.value[0].ReportName}}</strong>
			</label>
		</div>
		<div class="col-12 pb-pix-10 p-0">
			<div class="col-12">
				<strong class="dark-violet">{{ getCronStrue(schedule.value[0].Schedule)}}</strong>
			</div>
			<div class="col-12" *ngIf="schedule.value[0].ParameterLabel">
				<span class="pe-pix-10" *ngFor="let params of schedule.value;">
					<b>{{params.ParameterLabel}}</b>{{params.ParameterLabel? ':' : ''}}
					{{params.ParameterLabel ?
					convertParamValue(params.ParameterDataType,params.ParameterValue,params.ParameterQuery)
					: ''}}
				</span>
			</div>
		</div>
	</div>
</ng-template>

<ng-template let-data="rowData" #operationTemplate>
	<span *ngFor="let schedule of data| keyvalue; let i = index;">
		<span *ngIf="i == 0" class="item-vertical-center">
			<span class="flex-grow-1" tooltip title="Edit subscriptions"
				(click)="showSubscribeToReportModal(schedule.value[0].ReportId,schedule.value[0].ReportName)">
				<pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
			</span>
			<span class="flex-grow-1" tooltip title="Delete subscriptions"
				(click)="confirmDeleteReportSubscription(schedule.value[0].ReportSubscriptionId, schedule.value[0].ReportName)">
				<pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
			</span>
			<span *ngIf="!(schedule.value[0].IsActive)" class="pointer flex-grow-1" tooltip
				title="Click here to activate subscriptions"
				(click)="updateActivation(schedule.value[0].ReportSubscriptionId, true)">
				<pos-action-icon [icon]="icons.ban"></pos-action-icon>
			</span>
			<span *ngIf="(schedule.value[0].IsActive)" class="pointer flex-grow-1" tooltip
				title="Click here to deactivate subscriptions"
				(click)="updateActivation(schedule.value[0].ReportSubscriptionId, false)">
				<pos-action-icon [icon]="icons.checkCircleWhite"></pos-action-icon>
			</span>
		</span>
	</span>
</ng-template>
