<pos-edit-wrapper (close)="onCancel(false)" heading="Move Level: {{trainingLevelName}}">
  <form #trainingLevelMoveForm="ngForm" (ngSubmit)="moveTrainingLevel(trainingLevelMoveForm.valid)" autocomplete="off">
    <div class="col-12 pos-modal-body form-horizontal">
      <context-provider provide="props">
      <pos-row>
        <pos-column>
          <pos-form-field label="Area" [validationMessages]="{required: 'Please select training area.'}">
            <ng-select posInput name="trainingArea" appendTo="body" (change)="getTrainingTopics()" [items]="trainingAreas"
              bindLabel="Name" placeholder="Select training area" [(ngModel)]="selectedArea" [required]="true">
            </ng-select>
          </pos-form-field>
        </pos-column>

        <pos-column>
          <pos-form-field label="Topic" [validationMessages]="{required: 'Please select training topic.'}">
            <ng-select posInput [required]="true" name="trainingTopic" appendTo="body" [items]="trainingTopics" bindLabel="Name"
              placeholder="Select training topic" [(ngModel)]="selectedTopic">
            </ng-select>
          </pos-form-field>
        </pos-column>
      </pos-row>
      </context-provider>
    </div>
    <div class="col-12 action-button-container popup-footer">
      <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" scrollup>Move</button>
      <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" (click)="onCancel(false)">Cancel</button>
    </div>
  </form>
</pos-edit-wrapper>

<pos-confirm-delete (onConfirmAcknowledge)="onConfirmAcknowledge()" [confirmButtonText]="'Continue'"
  [cancelButtonText]="'Cancel'" #confirmMoveTrainingLevel>
</pos-confirm-delete>
