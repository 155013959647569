import { MessagePayload } from './message-payload';

export class AutoSignOutUserPayload extends MessagePayload {

    public UserId: number;
    public TerminalId: number;
    public EventName: string;

    constructor(userId: number, terminalId: number, eventName: string) {
        super();
        this.UserId = userId;
        this.TerminalId = terminalId;
        this.EventName = eventName;
    }
}
