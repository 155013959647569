import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  AlertsService, Messages, SpinnerService, ModalService, ImageGalleryComponent, RuntimeConstants
} from 'src/app/shared';
import { TrainingAreaService } from 'src/app/shared/services/training-area.service';
import { TrainingArea } from '../../interface/training-area';
import { zip } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ExtensionValidationService } from 'src/app/shared/services/extension-validation.service';
import { iconsVectorSquare, times } from 'src/app/shared/components/icon';
declare let $: any;


@Component({
  selector: 'pos-training-areas-edit',
  templateUrl: './training-areas-edit.component.html',
  styleUrls: ['./training-areas-edit.component.scss']
})
export class TrainingAreasEditComponent implements OnInit {
  @ViewChild('formTrainingArea', { static: true }) formTrainingArea: NgForm;
  @Output() onClose = new EventEmitter<any>();
  area: TrainingArea;
  iconList: Array<any> = [];
  areaIcon: File;
  showAreaIcon = false;
  saveTrainingAreaId = 0;
  buttonText = 'Close';
  @Input('areaId') areaId: number;
  @Input('fromListPage') fromListPage: number = null;
  @Output() formChange = new EventEmitter<NgForm>();
  icons = { iconsVectorSquare, times }
  public imageRootPath = RuntimeConstants.IMAGE_BASE_PATH + '/training-area-icon';
  constructor(
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private trainingAreaService: TrainingAreaService,
    private extensionValidation: ExtensionValidationService) {
    this.area = this.trainingAreaService.newTrainingArea();
    this.areaId = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
  }

  ngOnInit() {
    this.areaId = this.areaId ? this.areaId : 0;
    this.buttonText = this.fromListPage ? 'Cancel' : 'Close';
    this.formChange.emit(this.formTrainingArea);
    this.getTrainingArea(this.areaId);
  }

  getTrainingArea(areaId: number) {
    if (areaId > 0) {
      this.spinnerService.show();
      zip(
        this.trainingAreaService.getTrainingArea(areaId),
        this.trainingAreaService.getTrainingAreaImages(),
        (
          area: any,
          uploadedIcons: any
        ) => {
          this.area = area;
          this.iconList = uploadedIcons;
          if (area.Icon !== '') {
            this.showAreaIcon = true;
          }
        }
      ).subscribe({
        next: (res) => {
          this.formChange.emit(this.formTrainingArea);
        }, error: this.alertService.showApiError,
        complete: () => {
          this.spinnerService.hide();
        }
      });
    } else {
      this.spinnerService.show();
      this.trainingAreaService.getTrainingAreaImages().subscribe(
        res => {
          this.iconList = res;
        }, this.alertService.showApiError,
        () => {
          this.spinnerService.hide();
        });
      this.area = this.trainingAreaService.newTrainingArea();
    }

  }

  selectIcon() {
    const imageGalleryModalRef = this.modalService.show(ImageGalleryComponent, {
      animated: false,
      class: 'vertical-center modal-lg',
      initialState: {
        imageList: this.iconList,
        imageRootPath: this.imageRootPath,
        headerName: 'Select Area Icon'
      }
    });

    imageGalleryModalRef.close.subscribe(res => {
      if (res && res.selectedImage) {
        this.area.Icon = res.selectedImage;
        this.showAreaIcon = true;
        this.areaIcon = null;
      }
    });
  }

  saveTrainingArea(isValid: boolean) {
    const fileToUpload = this.areaIcon ? this.areaIcon.name : '';
    if (!isValid || ((!this.showAreaIcon || this.extensionValidation.isInvalidExtension(fileToUpload)) && !this.area.Icon)) {
      return;
    }
    this.spinnerService.show();
    this.trainingAreaService.saveTrainingArea(this.area, this.areaIcon).subscribe(
      res => {
        this.alertService.renderSuccessMessage(Messages.TrainingAreaSavedSuccess);
        this.saveTrainingAreaId = res.Id;
        this.onCancel(true);
      }, this.alertService.showApiError,
      () => {
        this.spinnerService.hide();
      }
    );
  }

  handleFileInput(files: FileList) {
    this.areaIcon = files.item(0);
    const fileToUpload = this.areaIcon ? this.areaIcon.name : '';
    if (this.extensionValidation.isInvalidExtension(fileToUpload)) {
      if (fileToUpload) {
        this.alertService.renderErrorMessage(Messages.InvalidReceiptLogoFileType);
      }
      this.removeIcon();
      return;
    }
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = function (e) {
        $('#areaImage')
          .attr('src', reader.result);
      };
      reader.readAsDataURL(files[0]);
      this.showAreaIcon = true;
    }
  }

  removeIcon() {
    this.area.Icon = '';
    $('#areaIcon').val('');
    this.showAreaIcon = false;
  }

  onCancel(reload: boolean) {
    this.onClose.next({ shouldReload: reload, trainingAreaId: this.saveTrainingAreaId });
  }
}
