import { Component, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormUtilityService, ModalFormComponent, ModalService } from 'src/app/shared';

@Component({
  selector: 'pos-account-edit-model',
  templateUrl: './account-edit-model.component.html',
  styleUrls: ['./account-edit-model.component.scss']
})
export class AccountEditModelComponent extends ModalFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.accountForm;
  }

  @Input('accountId') accountId: number = null;
  initialize = false;
  @Input('accountTypeId') accountTypeId: number = null;
  @Input('fromListPage') fromListPage = false;
  accountForm: NgForm;
  constructor(modalService: ModalService,
    modalRef: BsModalRef,
    formUtilityService: FormUtilityService) {
      super(modalRef, modalService, formUtilityService)
    this.initialize = false;
  }

  ngOnInit() {
    this.initialize = true;
  }

  onClose(event: any) {
    this.hide({ event });
  }

  onFormChange($event: NgForm) {
    this.accountForm = $event;
    this.setInitialValue();
  }
}
