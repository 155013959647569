import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { filter, find } from 'lodash';
import {
  exclamationTriangle
} from 'src/app/shared/components/icon';
import { Messages } from 'src/app/shared/constants';
import { ModalService, InfoModalComponent, SpinnerService } from 'src/app/shared/components';
import { AlertsService, ApplicationStateService } from 'src/app/shared/services';
import { VariableIngredientRecipeService } from '../../services/variable-ingredient-recipe.service';
import { finalize } from 'rxjs/operators';
import { GridColumn, TemplateColumn } from '@tarktech/tark-ng-utils';
import Pluralize from 'pluralize';
import { SettingParam } from 'src/app/shared/interface/setting-param';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { Router } from '@angular/router';
declare let $: any;

@Component({
  selector: 'pos-variable-ingredient-recipe',
  templateUrl: './variable-ingredient-recipe.component.html',
  styleUrls: ['./variable-ingredient-recipe.component.scss']
})
export class VariableIngredientRecipeComponent implements OnInit {
  prepProducts: any;
  prepProduct: number;
  ingredients: any;
  recipeInformation: string;
  prepareQty: number;
  preparedQty: number;
  selectedUnit: string;
  height: string;
  settingParams: SettingParam;
  public icons = {
    exclamationTriangle
  };
  ingredientColumns: Array<GridColumn> = [];
  @ViewChild('ingredientTemplate', { static: true }) ingredientTemplate: TemplateRef<any>;
  @ViewChild('qtyTemplate', { static: true }) qtyTemplate: TemplateRef<any>;
  @ViewChild('newQtyTemplate', { static: true }) newQtyTemplate: TemplateRef<any>;
  isFormSubmitted = false;
  showCancelButton = false;
  constructor(
    private variableIngredientRecipeService: VariableIngredientRecipeService,
    private applicationStateService: ApplicationStateService,
    private modalService: ModalService,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private router: Router
  ) {
    this.resetForm();
  }

  resetForm() {
    this.prepProduct = null;
    this.recipeInformation = '';
    this.prepareQty = 0;
    this.preparedQty = 0;
    this.selectedUnit = '';
    this.ingredients = [];
    this.showCancelButton = false;
  }

  public ngOnInit() {
    this.settingParams = this.applicationStateService.settingParam;
    this.height = ($(window).height() - 270) < 480 ? 480 + this.getIngredientHeight() + 'px' : ($(window).height() - 300) + 'px';
    this.spinnerService.show();
    this.prepareIngredientColumns();
    this.variableIngredientRecipeService.getPrepProducts()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          this.prepProducts = response;
        }, error: this.alertService.showApiError
      });
  }

  prepareIngredientColumns() {
    const ingredientColumn = new TemplateColumn({
      HeaderText: 'Ingredient', IsSortable: false, Width: '30%',
      itemTemplate: this.ingredientTemplate
    });
    const qtyColumn = new TemplateColumn({
      HeaderText: 'Qty', IsSortable: false, Width: '20%',
      itemTemplate: this.qtyTemplate
    });
    const newQtyColumn = new TemplateColumn({
      HeaderText: 'Available Qty', IsSortable: false, Width: '20%',
      itemTemplate: this.newQtyTemplate
    });
    this.ingredientColumns = [
      ingredientColumn,
      qtyColumn,
      new GridColumn({ HeaderText: 'Unit', Field: 'UnitName', IsSortable: false, Width: '20%' }),
      newQtyColumn
    ];
  }

  getPrepareQty(ingredient) {
    return Math.floor(parseFloat((ingredient.UsedPrepQty / (parseFloat((ingredient.Qty / ingredient.PrepareQty).toFixed(2)) > 0 ?
      parseFloat((parseFloat(ingredient.Qty) / ingredient.PrepareQty).toFixed(2)) : 1)).toFixed(2)));
  }

  getIngredientHeight() {
    return ($(window).height() >= 969 ? $(window).height() - 650 : 235) + 'px';
  }

  getRecipeHeight() {
    return ($(window).height() >= 969 ? $(window).height() - 730 : 130) + 'px';
  }

  updatePrepQty() {
    if (!this.preparedQty) {
      this.alertService.renderErrorMessage(Messages.PrepQtyRequired)
      return;
    }
    if (this.prepProduct) {
      const selectedProduct = find(this.prepProducts, (item) => (item.Id === this.prepProduct));
      this.isProductQtyInsufficient(selectedProduct);
    }
  }

  updateInventoryQty(isPrintPrepLabels) {
    this.spinnerService.show();
    const variableIngredientRecipeObj = {
      Qty: this.preparedQty,
      RecipeList: this.ingredients,
      TerminalId: this.applicationStateService.terminalId,
      IsPrintPrepLabels: isPrintPrepLabels
    };
    this.variableIngredientRecipeService.prepareVariableInventory(variableIngredientRecipeObj)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (inventoryProduct) => {
          this.alertService.renderSuccessMessage(Messages.PrepRecordAdded);
          this.resetForm();
        }, error: this.alertService.showApiError
      });
  }

  isProductQtyInsufficient(selectedProduct) {
    if (this.ingredients.some(x => (!x.UsedPrepQty && x.UsedPrepQty !== 0) || x.UsedPrepQty < 0)) {
      return;
    }
    const insufficientQtyProducts = filter(this.ingredients, (item) => (item.CalculatedQty - item.UsedPrepQty) < 0);
    if (insufficientQtyProducts.length > 0) {
      const modal = this.modalService.getModalWrapper(InfoModalComponent);
      const modalRef = modal.show({
        animated: false,
        class: 'vertical-center',
        initialState: {
          message: StringUtils.format(Messages.ConfirmSaveRecipeIngredients, { 'Name': selectedProduct.Name }),
          confirmButtonText: 'Yes',
          rejectButtonText: 'No',
          modalHeaderText: 'Confirm'
        }
      });
      modalRef.close.subscribe((response) => {
        if (response && response.shouldConfirm) {
          this.confirmPrepLabelPrint(selectedProduct);
        }
      });
    } else {
      this.confirmPrepLabelPrint(selectedProduct);
    }
  }

  confirmPrepLabelPrint(selectedProduct) {
    if (selectedProduct && selectedProduct.IsPrintPrepLabel && this.prepareQty > 0) {
      if (!this.settingParams.MaxPrepLabels) {
        this.settingParams.MaxPrepLabels = 15;
      }
      const prepLabelPrintCount = this.prepareQty > this.settingParams.MaxPrepLabels ? this.settingParams.MaxPrepLabels : this.prepareQty;
      const modal = this.modalService.getModalWrapper(InfoModalComponent);
      const modalRef = modal.show({
        animated: false,
        class: 'vertical-center',
        initialState: {
          message: StringUtils.format(Messages.ConfirmPrepLabelPrint, { 'printCount': prepLabelPrintCount }),
          confirmButtonText: 'Yes',
          rejectButtonText: 'No',
          modalHeaderText: 'Confirm'
        }
      });
      modalRef.close.subscribe((response) => {
        this.updateInventoryQty(response.shouldConfirm);
      });
    } else {
      this.updateInventoryQty(false);
    }
  }

  calculatePrepQty() {
    this.prepareQty = 0;
    let isPositiveQty = false;
    this.ingredients.forEach((ingredient) => {
      if (!isPositiveQty) {
        if (parseFloat((ingredient.Qty / ingredient.PrepareQty).toFixed(2)) > 0) {
          this.prepareQty = this.getPrepareQty(ingredient);
          isPositiveQty = true;
        }
      } else if (ingredient.UsedPrepQty > 0) {
        if (parseFloat((ingredient.Qty / ingredient.PrepareQty).toFixed(2)) > 0) {
          this.prepareQty = (this.prepareQty > this.getPrepareQty(ingredient)) ? this.getPrepareQty(ingredient) : this.prepareQty;
        }
      } else {
        this.prepareQty = 0;
      }
    });
    const selectedProduct = find(this.prepProducts, (item) => (item.Id === this.prepProduct));
    if (selectedProduct) {
      this.selectedUnit = this.prepareQty ? Pluralize(selectedProduct.UnitName, this.prepareQty) : selectedProduct.UnitName;
    }
    this.preparedQty = this.prepareQty;
  }

  getProduct() {
    this.variableIngredientRecipeService.getInventoryProduct(this.prepProduct)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (inventoryProduct) => {
          if (inventoryProduct.Recipe != null) {
            this.recipeInformation = inventoryProduct.Recipe;
          }
        }, error: this.alertService.showApiError
      });
  }

  getIngredients() {
    this.isFormSubmitted = false;
    this.prepareQty = 0;
    this.recipeInformation = '';
    this.setShowCancelButton();
    if (this.prepProduct == null) {
      this.resetForm();
      return;
    }
    const selectedProduct = find(this.prepProducts, (item) => (item.Id === this.prepProduct));
    if (selectedProduct) {
      this.selectedUnit = this.prepareQty ? Pluralize(selectedProduct.UnitName, this.prepareQty) : selectedProduct.UnitName;
    }
    this.spinnerService.show();
    this.getProduct();
    this.variableIngredientRecipeService.getIngredients(this.prepProduct)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          this.ingredients = response;
        }, error: this.alertService.showApiError
      });
  }

  printInventory() {
    if (this.prepProduct > 0 && this.prepareQty > 0) {
      const recordInventoryProduct = { InventoryProductId: 0, TerminalId: 0, Qty: 0, InventoryProductRecipes: [], TerminalName: '' };
      recordInventoryProduct.InventoryProductId = this.prepProduct;
      recordInventoryProduct.Qty = this.prepareQty;
      recordInventoryProduct.TerminalId = this.applicationStateService.terminalId;
      recordInventoryProduct.TerminalName = this.applicationStateService.terminalName;
      recordInventoryProduct.InventoryProductRecipes = this.ingredients;
      this.spinnerService.show();
      this.variableIngredientRecipeService.printInventoryPrep(this.prepProduct, recordInventoryProduct)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: () => {
            this.alertService.renderSuccessMessage(Messages.PrintRequestSendSuccess);
          }, error: this.alertService.showApiError
        });
    } else {
      const modal = this.modalService.getModalWrapper(InfoModalComponent);
      const modalRef = modal.show({
        animated: false,
        class: 'vertical-center',
        initialState: {
          message: Messages.RequiredProductAndQtyForVariableRecipe
        }
      });
    }
  }

  setShowCancelButton() {
    this.showCancelButton = (this.prepProduct) ? true : false;
  }

  close() {
    this.router.navigate(['manage/console']);
  }
}
