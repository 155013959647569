export const InventoryProductFieldInfoMessages = {
    Details: "General information about this product, usually helpful information about the product. Ingredient Instructions: The default instructions for this product when they are included on a product on the Instruction Screen terminal.",
    ShowonPickScreen: "If this is checked, then this ingredient will appear on the Pick Screen for any product that includes it. If not checked, it will not be listed in the Pick Screen items.",
    UnitQtyasOrdered: "For each item that you purchase from your Vendor, this is the number of Units that comes with it. Think of it as the number of Units that you receive, per barcode scanned in Receiving.",
    Min: "The minimum number of this product to have on hand. If the calculated on-hand quantity falls to or below this number, any users set up for the Inventory Minimum Reached event will be notified.",
    Max: "The minimum number of units to replenish to. The Restock Report uses this to determine how many to purchase.",
    WarnonConsumption: "Any users subscribed to this event will be notified when the product's calculated on-hand quantity reaches the Min.",
    WarnUnexpectedInventoryQty: "Users subscribed to this event will be notified any time that an Inventory Log does not match the current calculated on-hand quantity. It is subject to the Variance amounts.",
    VarianceThreshold: "Unexpected inventory events are not generated if variance between expected and actual is greater than this percentage.",
    VarianceThresholdAmount: "If the variance on an Unexpected Inventory Qty is higher than the Variance Threshold %, it is checked against this amount. It also must be higher than this amount to qualify. for the Unexpected Inventory Qty event.",
    AutoDecrement: "If set, then this product's calculated on-hand inventory is deducted by this amount every day, or order, as specified.",
    DoNotInventory: "If checked, this product will not appear on the Inventory Log screen.",
    ReceivingInstructions: "If specified, these instructions will print when this product is recorded in Inventory Receiving. Usually these will be portioning or storage instructions.",
    Recipe: "In Inventory Prep, any recipe specified here will print to give the user instructions on preparation of the product.",
    MaxPortions: "You can enter a number here to indicate the maximum quantity to portion, which will appear on the inventory label.",
    MeasuringInstructions: "Provide brief instructions on how measure the product's inventory units, while inside its packaging.",
    AllowOutOfStockSales: 'If checked, will allow selling sales products even though current inventory product is part of the recipe, and is calculated out of stock'
}
