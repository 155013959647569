import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GridColumn, TableComponent } from '@tarktech/tark-ng-utils';
import { filter, find, forEach, remove } from 'lodash';
import { UnitService } from 'src/app/information-management/units';
import { StringUtils } from 'src/app/shared';
import { BaseListComponent, ModalService, SpinnerService } from 'src/app/shared/components';
import { Messages } from 'src/app/shared/constants';
import { AlertsService, ApplicationStateService, PrintTableService } from 'src/app/shared/services';
import { InventoryVendorProfileProduct } from '../../interface/inventory-vendor-profile-product';
import { InventoryVendorProfileProductService } from '../../service/inventory-vendor-profile-product.service';
import { InventoryVendorProfileProductEditComponent } from '../inventory-vendor-profile-product-edit';

@Component({
  selector: 'pos-inventory-vendor-profile-products',
  templateUrl: './inventory-vendor-profile-products.component.html',
  styleUrls: ['./inventory-vendor-profile-products.component.scss']
})
export class InventoryVendorProfileProductsComponent extends BaseListComponent<InventoryVendorProfileProduct> implements OnInit {

  @Input() vendorName = null;
  @Input() profileName = null;
  vendorId = 0;
  profileId = 0;
  profileProductsColumns: Array<GridColumn> = [];
  vendorProfileProducts: Array<InventoryVendorProfileProduct> = [];
  height = window.innerHeight - 470 + 'px';
  deleteInventoryProductId: number = null;
  isMobileMode = window.innerWidth < 1151;

  @Output() profileProducts = new EventEmitter<any>();
  @ViewChild('profileProductsGrid', { static: true }) profileProductsGrid: TableComponent;

  constructor(protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected inventoryVendorProfileProductService: InventoryVendorProfileProductService,
    protected modalService: ModalService,
    protected applicationStateService: ApplicationStateService,
    protected printService: PrintTableService,
    protected route: ActivatedRoute,
    private unitService: UnitService
  ) {
    super(inventoryVendorProfileProductService, alertService, spinnerService, modalService, applicationStateService, printService, route);
    this.vendorId = parseInt(route.snapshot.params.id, 10) ?? 0;
    this.profileId = parseInt(route.snapshot.params.profileId, 10) ?? 0;
  }

  ngOnInit(): void {
    this.isPrintIconVisible = false;
    this.loadSubscriptions();
    this.deleteSuccessMessage = Messages.InventoryVendorProfileProductDeleted;
    this.profileProductsColumns = this.configureGridColumns();
    this.profileProductsGrid.context = this.gridContext;
  }

  private loadSubscriptions(): void {
    this.data$.subscribe({
      next: (res) => {
        this.vendorProfileProducts = res;
        this.vendorProfileProducts = filter(this.vendorProfileProducts, x => x.InventoryVendorProfileId === this.profileId
          && x.InventoryProduct.IsActive);
        this.vendorProfileProducts.sort((a, b) => a.InventoryProduct.Name.localeCompare(b.InventoryProduct.Name));
      }
    });
  }

  getGridColumns(): Array<GridColumn> {
    return [
      new GridColumn({ HeaderText: 'Inventory Product', Field: 'InventoryProduct.Name', IsSortable: true, Width: '45%' }),
      new GridColumn({ HeaderText: 'Min', Field: 'MinLevel', IsSortable: true, Width: '15%' }),
      new GridColumn({ HeaderText: 'Max', Field: 'MaxLevel', IsSortable: true, Width: '15%' }),
      new GridColumn({ HeaderText: 'Unit', Field: 'InventoryProduct.Unit.Name', IsSortable: true, Width: '17%' }),
    ];
  }

  editItem(id: number, data?: InventoryVendorProfileProduct): void {
    const modalRef = this.modalService.getModalWrapper(InventoryVendorProfileProductEditComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center ' + (id > 0 || data?.InventoryProduct ? '' : 'modal-max-width-40'),
      initialState: {
        id: id,
        profileId: this.profileId,
        vendorId: this.vendorId,
        vendorName: this.vendorName,
        profileName: this.profileName,
        vendorProfileProducts: this.vendorProfileProducts,
        profileProduct: data ?? this.inventoryVendorProfileProductService.newVendorProfileProduct()
      }
    });
    modal.close.subscribe({
      next: (res) => {
        if (res?.shouldReload) {
          this.reload();
        } else if (res?.profileProducts.length) {
          const profileProducts: Array<InventoryVendorProfileProduct> = res.profileProducts;
          this.vendorProfileProducts.push(...profileProducts);
          this.vendorProfileProducts.sort((a, b) => a.InventoryProduct.Name.localeCompare(b.InventoryProduct.Name));
          forEach(this.vendorProfileProducts, product => {
            const profileProduct = find(profileProducts, x => x.InventoryProduct.Id === product.InventoryProduct.Id);
            if (profileProduct) {
              product.InventoryProduct.Unit = this.unitService.newUnit();
              product.InventoryProduct.Unit.Name = profileProduct.InventoryProduct.UnitName;
              product.MinLevel = profileProduct.MinLevel;
              product.MaxLevel = profileProduct.MaxLevel;
            }
          });
          this.profileProducts.emit({ profileProducts: this.vendorProfileProducts });
        } else if (res?.profileProduct) {
          const profileProduct = find(this.vendorProfileProducts, x => x.InventoryProduct.Id === res.profileProduct.InventoryProductId);
          if (profileProduct) {
            profileProduct.MinLevel = res.profileProduct.MinLevel;
            profileProduct.MaxLevel = res.profileProduct.MaxLevel;
          }
        }
      }
    });
  }

  delete(id: number): void {
    if (id > 0) {
      super.delete(id);
    } else {
      remove(this.vendorProfileProducts, x => x.InventoryProductId === this.deleteInventoryProductId);
    }
  }

  getConfirmDeleteMessage(data: InventoryVendorProfileProduct): string {
    this.deleteInventoryProductId = data.InventoryProductId;
    return StringUtils.format(Messages.ConfirmDeleteInventoryVendorProfileProduct,
      {
        'productName': data && data.InventoryProduct ? data.InventoryProduct.Name : '',
        'profileName': data && data.InventoryVendorProfile ? data.InventoryVendorProfile.Name : this.profileName,
        'vendorName': this.vendorName
      }
    );
  }

}
