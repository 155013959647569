import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuDisplayConfigComponent } from './components/menu-display-config/menu-display-config.component';
import { GridsterModule } from 'angular-gridster2';
import { RouterModule } from '@angular/router';
import { MENU_DISPLAY_CONFIG, MENU_DISPLAY_STATE } from './menu-display-routes';
import { MenuImageWidgetComponent } from './components/menu-image-widget/menu-image-widget.component';
import { MenuWidgetDirective } from './directives/menu-widget.directive';
import { MenuWidgetComponent } from './components/menu-widget/menu-widget.component';
import { MenuListWidgetComponent } from './components/menu-list-widget/menu-list-widget.component';
import { ComponentsModule } from '../shared/components/components.module';
import { TableConfiguration } from '../shared/table/table-configuration';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TableModule } from '@tarktech/tark-ng-utils';
import { BannerComponent } from './components/banner/banner.component';
import { MenuDisplayComponent } from './components/menu-display/menu-display.component';
import { MenuDisplayEditComponent } from './components/menu-display-edit/menu-display-edit.component';
import { BannerWidgetComponent } from './components/banner-widget/banner-widget.component';
import { MenuImageListWidgetComponent } from './components/menu-image-list-widget/menu-image-list-widget.component';
import { MenuListComponent } from './components/menu-list/menu-list.component';
import { MenuImageComponent } from './components/menu-image/menu-image.component';
import { TileComponent } from './components/tile/tile.component';
import { MenuTileListWidgetComponent } from './components/menu-tile-list-widget/menu-tile-list-widget.component';
import { MenuTextWidgetComponent } from './components/menu-text-widget/menu-text-widget.component';
import { MenuDisplayPreviewComponent } from './components/menu-display-preview/menu-display-preview.component';
import { MenuComboListWidgetComponent } from './components/menu-combo-list-widget/menu-combo-list-widget.component';
import { CombineItemsComponent } from './components/combine-items/combine-items.component';
import { MenuWidgetConfigComponent } from './components/menu-widget-config/menu-widget-config.component';
import { MenuWidgetListComponent } from './components/menu-widget-list/menu-widget-list.component';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { WidgetGroupConfigComponent } from './components/widget-group-config/widget-group-config.component';
import { WidgetGroupComponent } from './components/widget-group/widget-group.component';
import { RouteConfigService } from '../shared/services/route-config.service';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MenuItemWidgetComponent } from './components/menu-item-widget/menu-item-widget.component';
import { CheckboxModule } from 'primeng/checkbox';
import { MenuWidgetEditComponent, WidgetHeaderConfigComponent, OutOfStockBehaviorComponent, FontStyleConfigComponent, BorderStyleComponent } from './components/menu-widget-edit';
import { GeneralStyleConfigComponent } from './components/menu-widget-edit/general-style-config/general-style-config.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxContextModule } from '@tarktech/ngx-context';
import { ComboItemsConfigComponent } from './components/menu-widget-edit/combo-items-config/combo-items-config.component';
import { ComboProductsConfigComponent } from './components/menu-widget-edit/combo-items-config/combo-products-config/combo-products-config.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ProductOrdinalConfigComponent } from './components/menu-widget-edit/product-ordinal-config/product-ordinal-config.component';
import { MenuGroupWidgetComponent } from './components/menu-group-widget/menu-group-widget.component';
import { TooltipModule } from 'primeng/tooltip';
import { MenuDisplayBackupComponent } from './components/menu-display-backup/menu-display-backup.component';
import { MenuDisplayBackupEditComponent } from './components/menu-display-backup-edit/menu-display-backup-edit.component';
import { MenuVideoWidgetComponent } from './components/menu-video-widget/menu-video-widget.component';

RouteConfigService.addGuardsToRoutes(MENU_DISPLAY_CONFIG.children);

@NgModule({
  declarations: [
    MenuDisplayConfigComponent,
    MenuImageWidgetComponent,
    MenuWidgetDirective,
    MenuListWidgetComponent,
    MenuDisplayComponent,
    MenuDisplayEditComponent,
    BannerComponent,
    BannerWidgetComponent,
    MenuImageListWidgetComponent,
    MenuListComponent,
    MenuImageComponent,
    TileComponent,
    MenuTileListWidgetComponent,
    MenuWidgetComponent,
    MenuTextWidgetComponent,
    MenuDisplayPreviewComponent,
    MenuComboListWidgetComponent,
    CombineItemsComponent,
    MenuWidgetConfigComponent,
    MenuWidgetListComponent,
    WidgetGroupConfigComponent,
    WidgetGroupComponent,
    MenuItemWidgetComponent,
    MenuWidgetEditComponent,
    WidgetHeaderConfigComponent,
    OutOfStockBehaviorComponent,
    FontStyleConfigComponent,
    BorderStyleComponent,
    GeneralStyleConfigComponent,
    ComboItemsConfigComponent,
    ComboProductsConfigComponent,
    ProductOrdinalConfigComponent,
    MenuGroupWidgetComponent,
    MenuDisplayBackupComponent,
    MenuDisplayBackupEditComponent,
    MenuVideoWidgetComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ComponentsModule,
    NgSelectModule,
    TableModule.forRoot(TableConfiguration),
    GridsterModule,
    MatButtonModule,
    MatMenuModule,
    CodemirrorModule,
    RouterModule.forChild(MENU_DISPLAY_STATE),
    PopoverModule.forRoot(),
    CheckboxModule,
    ModalModule.forRoot(),
    NgxContextModule,
    DragDropModule,
    TooltipModule
  ],
  exports: [MenuDisplayConfigComponent,
    MenuImageWidgetComponent,
    MenuWidgetDirective,
    MenuListWidgetComponent,
    MenuDisplayComponent,
    MenuDisplayEditComponent,
    BannerComponent,
    BannerWidgetComponent,
    MenuImageListWidgetComponent,
    MenuListComponent,
    MenuImageComponent,
    TileComponent,
    MenuTileListWidgetComponent,
    MenuTextWidgetComponent,
    MenuWidgetComponent,
    MenuDisplayPreviewComponent,
    MenuWidgetEditComponent],
})
export class MenuDisplayModule { }
