<pos-edit-wrapper heading="Printing" [headingIcon]="icons.printing" (close)="cancelEditing()" [isPopup]="false">
  <form #printingForm="ngForm" class="edit-form" (ngSubmit)="save(printingForm.valid)">
    <div class="col-12 pos-page-body border-top content-scroll">
      <div class="col-12">
        <div>
          <h3 class="setting-title">Receipt</h3>
        </div>
        <pos-row colXs="12" class="pos-title-content">
          <pos-column>
            <pos-form-field [validationMessages]="{range: 'Receipt must be in range of 0-100 seconds.'}" inputStyle="col-12">
              <pos-row colXs="3" class="p-0">
                <pos-column class="col-100 pt-pix-7">
                  <p-checkbox name="receiptAutoHide" #receiptAutoHide="ngModel" (onChange)="receiptSetting()"
                    [(ngModel)]="parameters.ReceiptAutoHide" binary="true" label="Receipt"></p-checkbox>
                </pos-column>

                <pos-column xs="3" class="col-100" *ngIf="parameters.ReceiptAutoHide">
                  <input posInput class="form-control me-pix-5 text-right" id="receiptAutoHideSec" [range]="[0, 100]"
                    name="receiptAutoHideSec" [(ngModel)]="parameters.ReceiptAutoHideSec" type="number" />
                </pos-column>

                <pos-column class="d-flex no-wrap pt-pix-7">
                  <label *ngIf="parameters.ReceiptAutoHide">
                    <span class="edit-form__section__label">seconds</span>
                  </label>
                  <field-info [info]="fieldInfoMessages.PrintingReceiptSeconds"></field-info>
                </pos-column>
              </pos-row>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field inputStyle="col-12 pe-0 row">
              <span class="col-3 col-300 d-flex flex-nowrap">
                <p-checkbox posInput name="printCreditCustomerReceipt" #printCreditCustomerReceipt="ngModel"
                  [(ngModel)]="parameters.PrintCreditCustomerReceipt" binary="true"
                  (onChange)="customerReceiptSetting()" label="Print Customer Receipt"></p-checkbox>
                <field-info [info]="fieldInfoMessages.PrintingCustomerReceipt"></field-info>
              </span>
              <span class="col-2  col-100">
                <p-checkbox name="PromptCustomerReceipt" #PromptCustomerReceipt="ngModel"
                  [disabled]="!parameters.PrintCreditCustomerReceipt" [(ngModel)]="parameters.PromptCustomerReceipt"
                  binary="true" label="Prompt"></p-checkbox>
              </span>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field inputStyle="col-12 pe-0 row">
              <span class="col-3 col-300 d-flex flex-nowrap">
                  <p-checkbox posInput name="printCreditMerchantReceipt" #printCreditMerchantReceipt="ngModel"
                    [(ngModel)]="parameters.PrintCreditMerchantReceipt" binary="true"
                    (onChange)="merchantReceiptSetting()" label="Print Merchant Receipt"></p-checkbox>
                  <field-info [info]="fieldInfoMessages.PrintingMerchantReceipt"></field-info>
              </span>
              <span class="col-2 col-100">
                  <p-checkbox name="promptMerchantReceipt" #promptMerchantReceipt="ngModel"
                    [(ngModel)]="parameters.PromptMerchantReceipt" binary="true"
                    [disabled]="!parameters.PrintCreditMerchantReceipt" label="Prompt"></p-checkbox>
              </span>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Card Signature Required Amount" [fieldInfo]="fieldInfoMessages.PrintingCardSignature"
              inputStyle="col-2 col-100 pe-0">
              <input posInput class="form-control text-right" name="CardSignatureReqAmount"
                [(ngModel)]="parameters.CardSignatureReqAmount" type="number" posDecimal />
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Print Check Default" inputStyle="col-sm-3 col-6">
              <ng-select [items]="printCheckOrderStatus" appendTo="body" bindLabel="Value" bindValue="Value"
                [(ngModel)]="parameters.PrintCheckDefaultOrderStatus" name="PrintCheckDefaultOrderStatus" placeholder="Select print check default">
              </ng-select>
            </pos-form-field>
          </pos-column>
        </pos-row>
      </div>

      <div class="col-12 section-border">
        <div>
          <h3 class="setting-title">Printing Settings</h3>
        </div>
        <pos-row colXs="12" class="pos-title-content">
          <pos-column>
            <pos-form-field inputStyle="d-flex">
              <p-checkbox posInput name="printPerOrder" #printPerOrder="ngModel" [(ngModel)]="parameters.PrintPerOrder"
                binary="true" (onChange)="printingSetting()" label="Print orders to Order Printer">
              </p-checkbox>
              <field-info [info]="fieldInfoMessages.PrintingEachOrderToPrinter" [placement]="windowRef.innerWidth > 370 ? 'top' :'left'">
              </field-info>
            </pos-form-field>
          </pos-column>

          <pos-column class="pe-0">
            <pos-row>
              <pos-column>
                <pos-form-field inputStyle="d-flex">
                  <p-checkbox name="printAccountAddressOnOrderReceipt" #printAccountAddressOnOrderReceipt="ngModel"
                    [(ngModel)]="parameters.PrintAccountAddressOnOrderReceipt" binary="true"
                    [disabled]="!parameters.PrintPerOrder" label="Print Customer address on Order Receipt"></p-checkbox>
                  <field-info [info]="fieldInfoMessages.PrintingCustomerAddressOnOrderReceipt" [placement]="windowRef.innerWidth > 370 ? 'top' :'left'"></field-info>
                </pos-form-field>
              </pos-column>

              <pos-column>
                <pos-form-field inputStyle="d-flex">
                  <p-checkbox name="printOnlySelectedItems" #printOnlySelectedItems="ngModel"
                    [(ngModel)]="parameters.PrintOnlySelectedItems" binary="true" [disabled]="!parameters.PrintPerOrder"
                    label="Print only Selected Items"></p-checkbox>
                  <field-info [info]="fieldInfoMessages.PrintingOnlySelectedItems" [placement]="windowRef.innerWidth > 370 ? 'top' :'left'">
                  </field-info>
                </pos-form-field>
              </pos-column>

              <pos-column>
                <pos-form-field inputStyle="d-flex">
                  <p-checkbox name="printOrderRecalls" #printOrderRecalls="ngModel"
                    [(ngModel)]="parameters.PrintOrderRecalls" binary="true" [disabled]="!parameters.PrintPerOrder"
                    label="Perform Recalls on Modified Orders"></p-checkbox>
                  <field-info [info]="fieldInfoMessages.PrintingPerformRecallsOnModifiedOrders" [placement]="windowRef.innerWidth > 370 ? 'top' :'left'"></field-info>
                </pos-form-field>
              </pos-column>

              <pos-column>
                <pos-form-field inputStyle="col-12"
                  [validationMessages]="{required: 'Please enter print delivery receipt copies.', range: 'Print delivery receipt must be in range of 0-100 copies.'}">
                  <pos-row colXs="3" class="p-0">
                    <pos-column class="col-200 pt-pix-7">
                      <p-checkbox name="printDeliveryReceiptToKitchen" #printDeliveryReceiptToKitchen="ngModel"
                        [(ngModel)]="parameters.PrintDeliveryReceiptToKitchen" binary="true"
                        [disabled]="!parameters.PrintPerOrder" label="Print Delivery Receipt"></p-checkbox>
                    </pos-column>

                    <pos-column xs="3" class="col-120">
                      <input posInput class="form-control me-pix-5 text-right"
                        [disabled]="!parameters.PrintDeliveryReceiptToKitchen" max="100" min="0" [range]="[0,100]"
                        name="DeliveryReceiptCopies" type="number"
                        [ngClass]="{'disabled': !parameters.PrintDeliveryReceiptToKitchen}"
                        [(ngModel)]="parameters.DeliveryReceiptCopies" #DeliveryReceiptCopies="ngModel"
                        [required]="parameters.PrintPerOrder && parameters.PrintDeliveryReceiptToKitchen" />
                    </pos-column>

                    <pos-column class="no-wrap d-flex pt-pix-7">
                      <span class="pt-0">Copies</span>
                      <field-info [info]="fieldInfoMessages.PrintingDeliveryReceiptsXCopies" [placement]="windowRef.innerWidth > 370 ? 'top' :'left'">
                      </field-info>
                    </pos-column>
                  </pos-row>
                </pos-form-field>
              </pos-column>

              <pos-column>
                <pos-form-field label="Order Closure Behavior" inputStyle="col-lg-2 col-md-4 col-12">
                  <ng-select posInput [items]="orderClosurePrintActions" appendTo="body" placeholder="Select order closure behavior"
                    [ngClass]="{'select-disabled': !parameters.PrintPerOrder}" [disabled]="!parameters.PrintPerOrder"
                    bindLabel="Value" bindValue="Value" [(ngModel)]="parameters.OrderClosurePrintAction"
                    name="OrderClosurePrintAction" #OrderClosurePrintAction="ngModel">
                  </ng-select>
                </pos-form-field>
              </pos-column>

              <pos-column>
                <pos-form-field label="Serve Methods:" inputStyle="col-12">
                  <div class="ps-pix-15" [ngClass]="{'pt-pix-10': !isFirst}"
                    *ngFor="let orderPrompt of serveMethods; let i=index; first as isFirst">
                    <p-checkbox name="promptOrder_type{{i}}" #promptOrder_type{{i}}="ngModel"
                      [(ngModel)]="orderPrompt.isSelected" binary="true" [disabled]="!parameters.PrintPerOrder"
                      [label]="orderPrompt.Name"></p-checkbox>
                  </div>
                </pos-form-field>
              </pos-column>
            </pos-row>
          </pos-column>

          <pos-column>
            <pos-form-field inputStyle="d-flex">
              <p-checkbox name="printZoneBinOnReceiving" #printZoneBinOnReceiving="ngModel"
                [(ngModel)]="parameters.PrintZoneBinOnReceiving" binary="true" label="Print Zone/Bin on receiving">
              </p-checkbox>
              <field-info [info]="fieldInfoMessages.PrintingZoneBinOnReceiving">
              </field-info>
            </pos-form-field>
          </pos-column>
        </pos-row>
      </div>

      <div class="col-12 section-border">
        <div>
          <h3 class="setting-title">Kitchen Printer</h3>
        </div>
        <pos-row class="pos-title-content">
          <pos-column>
            <pos-form-field label="Kitchen Printer" inputStyle="col-sm-4 col-12">
              <ng-select posInput [items]="kitchenPrinters" appendTo="body" placeholder="Select kitchen printer"
                [(ngModel)]="parameters.DefaultKitchenPrinterId" name="DefaultKitchenPrinterId"
                #DefaultKitchenPrinterId="ngModel" bindLabel="Name" bindValue="Id"></ng-select>
            </pos-form-field>
          </pos-column>
        </pos-row>
      </div>

      <div class="col-12 section-border">
        <div>
          <h3 class="setting-title">Label Printer</h3>
        </div>
        <pos-row class="pos-title-content">
          <pos-column>
            <pos-form-field label="Default Inventory Label Printer" inputStyle="col-sm-4 col-12">
              <ng-select posInput [items]="labelPrinters" appendTo="body" bindLabel="Name" bindValue="Id"
                [(ngModel)]="parameters.DefaultInventoryLabelPrinterId" name="DefaultInventoryLabelPrinterId"
                #DefaultInventoryLabelPrinterId="ngModel" placeholder="Select default inventory label printer">
              </ng-select>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field>
              <p-checkbox posInput name="chkproductLabelPrinting" #chkproductLabelPrinting="ngModel"
                label="Product Label Printing" [(ngModel)]="productLabelPrintingValue" binary="true"
                (onChange)="productLabelPrinting()">
              </p-checkbox>
            </pos-form-field>
          </pos-column>

          <pos-column class="ps-5">
            <pos-form-field label="Default Product Label Printer" inputStyle="col-sm-4 col-12">
              <ng-select posInput [items]="labelPrinters" appendTo="body" bindLabel="Name" bindValue="Id"
                [(ngModel)]="parameters.DefaultProductLabelPrinterId" placeholder="Select default product label printer"
                [ngClass]="{'select-disabled': !productLabelPrintingValue}" [disabled]="!productLabelPrintingValue"
                name="DefaultProductLabelPrinterId" #DefaultProductLabelPrinterId="ngModel"></ng-select>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Maximum Prep Label Count" inputStyle="col-4">
              <input posInput class="form-control mcol-100" posOnlyNumbers type="number" min="0" [(ngModel)]="parameters.MaxPrepLabels"
                name="MaxPrepLabels" />
            </pos-form-field>
          </pos-column>
        </pos-row>
      </div>
    </div>
    <div class="col-12 action-button-container page-footer">
      <button class="btn btn-primary btn-lg normal btn-save pos-button" scrollup type="submit" value="Save">Save</button>
      <button class="btn btn-secondary btn-lg normal btn-cancel pos-button" (click)="cancelEditing()" scrollup type="button"
        value="Cancel">Cancel</button>
    </div>
  </form>
</pos-edit-wrapper>
