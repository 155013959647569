import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { GridColumn, EditColumn, DeleteColumn, LinkColumn, TextAlign, DateColumn, TemplateColumn } from '@tarktech/tark-ng-utils';
import { SalesProductQuickReport } from '../../interface/sales-product-quick-report';
import { SpinnerService, AlertsService, ModalComponent } from 'src/app/shared';
import { SalesProductsService } from '../../services';
import { finalize } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Format } from '@tarktech/tark-ng-utils/table/format-type';

@Component({
  selector: 'pos-sales-product-quick-report',
  templateUrl: './sales-product-quick-report.component.html',
  styleUrls: ['./sales-product-quick-report.component.scss']
})
export class SalesProductQuickReportComponent extends ModalComponent implements OnInit {

  @Input('isPopup') isPopup: boolean = false;
  @Input('productId') productId: number = 0;
  @Input('productName') productName: string = '';
  @ViewChild('priceTemplate', {static: true}) private priceTemplate: any;
  @ViewChild('taxTemplate', {static: true}) private taxTemplate: any;
  @ViewChild('totalTemplate', {static: true}) private totalTemplate: any;
  reportColumns: Array<GridColumn> = [];
  reportData: Array<SalesProductQuickReport> = [];
  constructor(private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private salesProductService: SalesProductsService,
    public modalRef: BsModalRef
  ) {
    super(modalRef);
  }

  ngOnInit() {
    this.configureGridColumns();
    this.getReportsData();
  }

  getReportsData() {
    this.spinnerService.show();
    this.salesProductService.productQuickReport(this.productId ? this.productId : -1)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({ next: (res: Array<SalesProductQuickReport>) => {
        if (res && res.length > 0) {
          this.reportData = res;
        }
      }, error: this.alertService.showApiError });
  }

  configureGridColumns() {
    const priceColumn = new TemplateColumn({
      HeaderText: 'Price',
      itemTemplate: this.priceTemplate,
      Field: 'Price',
      TextAlign: TextAlign.Right,
      IsSortable: false
    });

    const taxColumn = new TemplateColumn({
      HeaderText: 'Tax',
      itemTemplate: this.taxTemplate,
      Field: 'Tax',
      TextAlign: TextAlign.Right,
      IsSortable: false
    });

    const totalColumn = new TemplateColumn({
      HeaderText: 'Total',
      itemTemplate: this.totalTemplate,
      Field: 'Total',
      TextAlign: TextAlign.Right,
      IsSortable: false
    });

    this.reportColumns = [
      new DateColumn({ HeaderText: 'Date', Format: Format.Date, Field: 'Date', IsSortable: false }),
      priceColumn,
      taxColumn,
      totalColumn
    ] as Array<GridColumn>;
  }

  onCancel() {
    this.hide({});
  }

}
