<div [ngClass]="styleClass" class="pos-color-picker">
    <span (click)="remove()" class="input-color-close"
        *ngIf="value"></span>
    <div class="input-color"
        [ngStyle]="{'background-color': value ? value : ''}">
    </div>
    <input  type="text" class="form-control" [ngClass]="inputStyleClass"
        [cpPosition]="cpPosition" [cpWidth]="cpWidth" [cpAlphaChannel]="cpAlphaChannel"
        [size]="size" (cpSliderChange)="colorChange($event.color)" (cpInputChange)="colorChange($event.color)"
        [colorPicker]="colorPicker ? colorPicker: value" [(ngModel)]="value"
        [name]="name" [disabled]="disabled" [required]="required" (ngModelChange)="onChange($event)"
    />
</div>
