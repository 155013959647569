import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomainConstants } from '../../constants';
import { ApplicationStateService } from '../../services';

@Component({
  selector: 'pos-account-attribute',
  templateUrl: './account-attribute.component.html'
})
export class AccountAttributeComponent implements OnInit, OnChanges {
  @Input() attributeType: any;
  @Output() changeAttribute = new EventEmitter<any>();
  dateFormat = 'mm-dd-yyyy';
  accountAttributeValueType = DomainConstants.AccountAttributeValueTypes;
  constructor(private applicationStateService: ApplicationStateService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.attributeType.DropdownValues = this.attributeType?.QueryValue ? this.attributeType.QueryValue.split(",") : [];
  }
  
  ngOnInit(): void {
    this.dateFormat = this.applicationStateService.settingParam.PCalendarDateFormat;
  }

  changeCurrentAttribute() {
    this.changeAttribute.emit(this.attributeType);
  }

}
