<pos-edit-wrapper (close)="onClose(false)"
  heading="{{ isImport ? ('Import Recipe for: ' + salesProductName + ', Size: ' + sizeName) :  ('Exclusions for: ' + salesProductName) }}">
  <form #importRecipeForm="ngForm" autocomplete="off">
    <div class="col-12" [ngClass]="{'container-fluid': !isImport}">
      <ul class="nav nav-tabs pos-nav-tabs" *ngIf="!isImport">
        <li [ngClass]="{'active': selectedTab == tabList.Recipes }">
          <a (click)="selectedTab = tabList.Recipes" class="blue" (click)="activeRecipeTab(true)">
            <span class="pe-pix-5">
              <pos-icon class="theme-separator" [icon]="icons.salesProductRecipe"></pos-icon>
            </span> <span>Recipe</span> </a>
        </li>

        <li [ngClass]="{'active': selectedTab == tabList.InventoryProducts }">
          <a (click)="selectedTab = tabList.InventoryProducts" class="blue" (click)="activeRecipeTab(false)">
            <span class="pe-pix-5">
              <pos-icon class="theme-separator" [icon]="icons.inventoryProductsStore
              "></pos-icon>
            </span> <span>Inventory Products</span> </a>
        </li>
      </ul>
      <div class="row mx-0 tab-content overflow-auto-tabs">
        <div class="col-12 pt-pix-10 screen-scroll pos-tab-body"
          [hidden]="selectedTab != tabList.Recipes" [ngClass]="{'border-top-0-important pos-tab-body': !isImport, 'pos-modal-body': isImport}"
          [ngStyle]="{'height': isImport ? height + 'px' : (height - 40) + 'px'}">
          <ng-table #gridComponent [columns]="recipeColumns" [data]="recipes">
          </ng-table>
        </div>
        
        <div class="col-12 active border-top-0-important screen-scroll pos-tab-body"
          [hidden]="isImport || selectedTab != tabList.InventoryProducts"
          [ngStyle]="{'height': isImport ? height + 'px' : (height - 40) + 'px'}">
          <div class="row mx-0"></div>
          <pos-row class="pt-pix-5">
            <pos-column>
              <pos-form-field label="Inventory Product">
                <pos-row class="mx-0">
                  <pos-column sm="10" xs="12" class="ps-0 mb-1">
                    <ng-select posInput appendTo="body" [items]="inventoryProducts" bindLabel="Name" bindValue="id"
                      placeholder="Select product to exclude" [(ngModel)]="selectedInventoryProductId"
                      name="inventoryProduct">
                    </ng-select>
                  </pos-column>
                  <pos-column sm="2" xs="12" class="ps-0 mb-1">
                    <button class="btn btn-primary normal pos-button"
                      (click)="addSalesProductInstructionExclusion(selectedInventoryProductId)"
                      [disabled]="!selectedInventoryProductId">
                      Add
                    </button>
                  </pos-column>
                </pos-row>
              </pos-form-field>
            </pos-column>
          </pos-row>
          <div class="col-12 p-0 pt-pix-10 overflow-auto">
            <ng-table #gridProductComponent [columns]="productsColumn" [data]="exclusionInventoryProducts">
            </ng-table>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
      <ng-template let-data="rowData" let-i="rowIndex" #chkTemplate>
        <span>
          <label>
            <p-checkbox name="directLink_{{i}}" #directLink="ngModel" [(ngModel)]="data.isChecked" binary="true"
              (onChange)="onRecipeChecked()">
            </p-checkbox>
          </label>
        </span>
      </ng-template>
      <ng-template let-data="rowData" #qtyTemplate>
        <span>
          {{data.qty}} {{data.Unit}}
        </span>
      </ng-template>
      <ng-template #headerTemplate>
        <div *ngIf="isImport" class="vertical-center-align">
          <label>
            <p-checkbox name="checkAll" #checkAll="ngModel" [(ngModel)]="isCheckedAll" binary="true"
              (onChange)="selectAll(isCheckedAll)">
            </p-checkbox>
          </label>
        </div>
        <div *ngIf="!isImport">
          <a class="text-nowrap">Exclude?</a>
        </div>
      </ng-template>
    </div>
    <div class="col-12 action-button-container popup-footer">
      <button *ngIf="isActiveRecipeTab" class="btn btn-primary btn-lg btn-save pos-button" type="submit" (click)="save()">
        {{isImport?'Import':'Save'}}
      </button>
      <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="onClose(false)" type="button">
        Cancel
      </button>
    </div>
  </form>
</pos-edit-wrapper>
