import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { MenuWidgetService } from 'src/app/menu-display/services';
import { BorderStyleConfig, DomainConstants } from 'src/app/shared'

@Component({
  selector: 'pos-border-style',
  templateUrl: './border-style.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class BorderStyleComponent implements OnChanges {
  @Input() borderStyle: BorderStyleConfig;
  @Output() borderStyleChange = new EventEmitter<BorderStyleConfig>();
  @Input() prefix: string;
  BorderStyleList = DomainConstants.BorderStyles;

  constructor(protected menuWidgetService: MenuWidgetService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('borderStyle') && !this.borderStyle) {
      this.borderStyle = this.menuWidgetService.getNewBorderStyleConfig();
    }
    this.borderStyleChange.emit(this.borderStyle);
  }

}
