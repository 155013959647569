<div class="table-responsive order-page__message-modal__inbox pt-pix-5 mb-pix-10">
    <ng-table [search]="true" [sort]="true" [paginator]="true" [columns]="sentMessageColumns" [data]="sentMessages"
        [totalRecords]="totalRecords" [rows]="filterConfiguration.PageSize" class="sent-message" [scrollable]="true"
        [scrollHeight]="height-90+'px'" [styleClass]="'no-border filter-left'" [lazy]="true"
        (lazyLoad)="lazyLoad($event)" #sentMessageTable>
    </ng-table>
    <ng-template let-data="rowData" #sendToTemplate>
        <div class="pointer" (click)="showMessage(data)">
            <span *ngIf="(data.Receiver !== null && data.Receiver !== '')">
                {{data.Receiver}}
            </span>
        </div>
    </ng-template>
    <ng-template let-data="rowData" #messageTextTemplate>
        <div class="pointer" (click)="showMessage(data)">
            <span innerHTML="{{sliceMessage(data.MessageText)}}"
                *ngIf="(data.MessageText !== null && data.MessageText !== '')">
            </span>
        </div>
    </ng-template>
    <ng-template let-data="rowData" #dateTemplate>
        <div class="pointer" (click)="showMessage(data)">
            <span *ngIf="(data.DateAdded !== null && data.DateAdded !== '')">
                {{data.DateAdded | tark_date_time}}
            </span>
        </div>
    </ng-template>
</div>
