<pos-edit-wrapper (close)="closeModal()" heading="Choose Product">
    <form #copyProductRecipeForm="ngForm" (submit)="selectActiveSalesProduct()">
        <div class="form-horizontal pos-modal-body">
            <div class="row mx-0">
                <pos-row>
                    <pos-column>
                        <pos-form-field inputStyle="col-12">
                            <ng-select posInput [items]="activeSalesProduct" bindLabel="Name" placeholder="Select product" appendTo="body"
                                [(ngModel)]="selectedProduct" name="selectProduct">
                            </ng-select>
                        </pos-form-field>
                    </pos-column>
                </pos-row>
            </div>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button class="btn btn-primary btn-lg btn-save pos-button" type="submit">Select</button>
            <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" type="button" (click)="closeModal()">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
