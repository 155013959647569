<pos-list-wrapper [headingIcon]="icons.equipmentListGripVertical" heading="Equipment List" (close)="close()">
    <div class="row">
        <div class="col-12">
            <ng-table id="equipment" [sort]="true" [columns]="equipmentsColumns"
                [exportFilename]="'equipment-list'" [data]="equipments" #equipmentGrid>
            </ng-table>
        </div>
    </div>
</pos-list-wrapper>
<pos-print-table [hidden]="true"></pos-print-table>
