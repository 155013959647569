<pos-edit-wrapper [headingIcon]="icons.iconsVectorSquare" heading="Icons" (close)="cancelEditing()" [isPopup]="false">
  <form (ngSubmit)="saveIcons(iconsForm.valid)" #iconsForm="ngForm" class="edit-form">
    <div class="col-12 pos-page-body border-top">
      <div class="col-12">
        <div>
          <h3 class="setting-title">Settings</h3>
        </div>
        <pos-row class="px-2">
          <pos-column>
            <pos-form-field label="Auto Next Button Color"
              [validationMessages]="{required: 'Please enter Auto Next Button Color.'}">
              <input posInput class="form-control" name="txtAutoNextButtonColor"
                [(ngModel)]="parameters.AutoNextButtonColor" [required]="true" type="text"
                #txtAutoNextButtonColor="ngModel" />
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Auto Next Button Icon">
              <div class="form-control height-auto">
                <input posInput (change)="uploadIconsFile($event.target.files)" type="file"
                  class="edit-form__section__file-input form-control">
                <img *ngIf="parameters.AutoNextButtonIcon" class="edit-form__section__file-img" [src]="imagePath" />
              </div>
            </pos-form-field>
          </pos-column>
        </pos-row>
      </div>
    </div>
    <div class="col-12 action-button-container page-footer">
      <button class="btn btn-primary btn-lg normal btn-save pos-button" scrollup type="submit" value="Save">Save</button>
      <button class="btn btn-secondary btn-lg normal btn-cancel pos-button" routerLink="../" scrollup type="button"
        value="Cancel">Cancel</button>
    </div>
  </form>
</pos-edit-wrapper>
