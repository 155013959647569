<pos-edit-wrapper [heading]="'Swap Recipe Item: ' + recipeItem?.Product" (close)="onCancel()">
    <form #recipeSwapForm="ngForm" (ngSubmit)='swapRecipe(recipeSwapForm.valid)'>
        <div class="overflow-hidden pos-modal-body">
            <pos-row>
                <pos-column>
                    <pos-form-field label="Product" [validationMessages]="{required: 'Please select product.'}">
                        <ng-select posInput [items]="ingredientList" bindLabel="Name" bindValue="Id" #swapItem="ngModel"
                            placeholder="Select product to swap" appendTo="body" [(ngModel)]="swapRecipeItem"
                            name="recipeItem" [required]="true">
                        </ng-select>
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <div class="form-horizontal">
                <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" scrollup
                    value="Save">Swap</button>
                <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" value="Cancel"
                    (click)="onCancel()">Cancel</button>
            </div>
        </div>
    </form>
</pos-edit-wrapper>
