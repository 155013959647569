<div class="col-12 form-group">
    <div class="row mx-0">
        <div class="col-12 p-0">
            <pos-row *ngFor="let property of hardwareProperties" [hidden]="property.Name != 'BaseUrl'"
                class="pt-pix-10">
                <pos-column>
                    <label class="required">
                        {{property.Description}}
                    </label>
                </pos-column>
                <pos-column>
                    <pos-form-field [appendPaddingTop]="false">
                        <pos-editor posInput [propertyValues]="property.AvailableValues"
                            [(propertyValue)]="property.ValueObject.Value" [required]="true"
                            [propertyName]="property.ValueObject.Name" [submit]="isSubmitted"
                            [controlType]="property.AvailableValueType" [bindLabel]="'Value'" [bindValue]="'Key'"
                            [propertyPlaceHolder]="'Select ' + property.Description"
                            (changeValue)="property.ValueObject.Value = $event; propertyValueChanged()"
                            [requiredMessage]="'Please specify value for ' + property.Description + '.'">
                        </pos-editor>
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 p-0 pt-pix-10">
            <button type="button" class="btn btn-primary pos-button" [disabled]="!isValidForm || !baseUrl"
                (click)="configureAuth()">
                <pos-icon [icon]="icons.check" *ngIf="authToken"></pos-icon>
                Configure Auth
            </button>
            <label class="grey ps-pix-10 pt-pix-7" *ngIf="authToken"><b>Token:</b> {{authToken}}</label>
        </div>

        <div class="col-12 p-0 pt-pix-10" *ngIf="authToken">
            <div class="col-12 p-0">
                <button type="button" class="btn btn-primary pos-button" (click)="readLights()">Read Lights</button>
            </div>
            <div class="row mx-0 pt-pix-10">
                <div class="col-xl-2 col-md-4 col-sm-3 col-xs-6 ps-0 me-pix-10">
                    <label class="label-section ban-color bold">Light</label>
                </div>
                <div class="col-xl-4 col-md-5 col-sm-5 col-xs-6 ps-0">
                    <label class="label-section ban-color bold">Name</label>
                </div>
            </div>
            <div class="col-12 p-0">
                <div *ngIf="lightsInfo.length" class="mt-pix-10">
                    <div class="row pb-pix-5" *ngFor="let light of lightsInfo; let i = index;">
                        <div class="col-xl-2 col-md-4 col-sm-3 col-xs-6">
                            <label class="label-section pb-pix-7">{{light.PanelId}}</label>
                        </div>
                        <div class="col-xl-4 col-md-5 col-sm-5 col-xs-6">
                            <input type="text" name="panelName{{i}}" [(ngModel)]="light.Name" class="form-control"
                                (blur)="lightInfoChanged()">
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-3 col-xs-6">
                            <button type="button" class="btn btn-warning normal white pos-button"
                                (click)="flashLight(light.PanelId)">
                                <pos-icon [icon]="icons.lightBulb" class="font-14"></pos-icon> Flash
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
