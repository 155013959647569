import { Route } from '@angular/router';
import { ApplicationLoginComponent } from './component/application-login/application-login.component';

const LOGIN: Route = {
    path: '',
    component: ApplicationLoginComponent
};

const HERO: Route = {
    path: 'views',
    loadChildren: () => import('../views/views.module').then(m => m.ViewsModule)
};

export const APPLICATION_LOGIN = [LOGIN, HERO];
