import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { RuntimeConstants } from 'src/app/shared';
import { DigitalSignage } from '../interface/digital-signage';

@Injectable({
  providedIn: 'root'
})
export class DigitalSignageService {

  slideshowApiBasePath = `${RuntimeConstants.API_PATH}slideshows`;

  constructor(private httpService: HttpClient) { }

  getTerminalSlideshowDetails(terminalId): Observable<Array<DigitalSignage>> {
    return this.httpService.get<Array<DigitalSignage>>(`${this.slideshowApiBasePath}/${terminalId}/slideshowdetails`);
  }
}
