import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridColumn, TableComponent, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { finalize } from 'rxjs/operators';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { BaseListComponent } from 'src/app/shared/components/base-list.component';
import { ModalService } from 'src/app/shared/components/modal';
import { SpinnerService } from 'src/app/shared/components/spinner';
import { AlertsService, ApplicationStateService, PrintTableService, RabbitMQService } from 'src/app/shared/services';
import {
  cogWhite, deleteWhite, editWhite, plusWhite, printWhite, rectanglePortrait, rectangleLandscape, squareLightOrangeStack,
  fileSearch, redoWhite, cog, objectGroup, barsWhite, displayMenu, save
} from 'src/app/shared/components/icon';
import { MenuDisplayService } from '../../services/menu-display.service';
import { MenuDisplay } from '../../interfaces/menu-display';
import { map } from 'lodash';
import { MenuDisplayBackupComponent } from '../menu-display-backup/menu-display-backup.component';
import { AuthenticationService, Levels, Permissions } from 'src/app/shared';

@Component({
  selector: 'pos-menu-display',
  templateUrl: './menu-display.component.html',
  styleUrls: ['./menu-display.component.scss']
})
export class MenuDisplayComponent extends BaseListComponent<MenuDisplay> implements OnInit {

  digitalMenu: Array<MenuDisplay> = [];
  digitalMenuColumns: Array<GridColumn> = [];
  icons = {
    cogWhite, deleteWhite, editWhite, plusWhite, printWhite, rectanglePortrait, rectangleLandscape, squareLightOrangeStack,
    fileSearch, redoWhite, cog, objectGroup, barsWhite, displayMenu, save
  };
  selectedMenu; popup;
  @ViewChild('operationTemplate', { static: true }) operationTemplate: TemplateRef<any>;
  @ViewChild('operationHeaderTemplate', { static: true }) operationHeaderTemplate: TemplateRef<any>;
  @ViewChild('resolutionTemplate', { static: true }) resolutionTemplate: TemplateRef<any>;
  @ViewChild('orientationTemplate', { static: true }) orientationTemplate: TemplateRef<any>;
  @ViewChild('menuList', { static: true }) menuList: TableComponent;

  permission = {
    name: Permissions.MenuDisplayList,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
 };

  constructor(protected menuDisplayService: MenuDisplayService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected applicationStateService: ApplicationStateService,
    protected route: ActivatedRoute,
    private router: Router,
    private rabbitMQService: RabbitMQService,
    protected printService: PrintTableService,
    private authenticationService: AuthenticationService) {
    super(menuDisplayService, alertService, spinnerService, modalService, applicationStateService, printService, route);
    this.deleteSuccessMessage = Messages.DigitalMenuDeleted;
  }

  ngOnInit(): void {
    this.digitalMenuColumns = this.configureGridColumns();
    this.loadData();
    this.menuList.context = this.gridContext;
  }

  loadData() {
    this.data$.pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (response) => {
        this.digitalMenu = response;
      }
    });
  }

  onLinkPopOver(data, popup) {
    this.selectedMenu = data;
    this.popup = popup;
  }

  getGridColumns(): GridColumn[] {
    let columns = []
    const operationColumn = new TemplateColumn({
      itemTemplate: this.operationTemplate,
      headerTemplate: this.operationHeaderTemplate,
      Width: '165px',
      TextAlign: TextAlign.Center,
      CellClass: 'cell-padding text-center'
    });
    const resolutionColumn = new TemplateColumn({
      itemTemplate: this.resolutionTemplate,
      HeaderText: 'Resolution (px)',
      Width: '20%',
    });
    const orientationColumn = new TemplateColumn({
      itemTemplate: this.orientationTemplate,
      HeaderText: 'Orientation',
      Width: '200px',
    });
    columns.push(new GridColumn({ HeaderText: 'Name', Field: 'Name', Width: '60%' }));
    columns.push(resolutionColumn);
    columns.push(orientationColumn);


    if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      columns.push(operationColumn);
  }

    return columns;
  }


  configureGridColumns() {
    return this.getGridColumns();
  }

  editItem(id: number): void {
    this.router.navigate([id], { relativeTo: this.route });
  }

  getConfirmDeleteMessage(data: MenuDisplay): string {
    return Messages.DigitalMenuConfirmDelete;
  }

  cancel() {
    this.router.navigate(['/manage/console']);
  }

  configWidget(data) {
    this.router.navigate([data.Id, 'widgets'], { relativeTo: this.route });
  }

  designMenu(data) {
    this.router.navigate(['config', data.Id], { relativeTo: this.route });
  }

  refreshMenuDisplay(data: MenuDisplay) {
    this.rabbitMQService.sendRefreshMenuDisplayMessage(this.applicationStateService.terminalId, data.Id);
    this.alertService.renderSuccessMessage(Messages.MenuDisplayReload);
  }

  preview(data) {
    this.router.navigate([data.Id, 'preview'], { relativeTo: this.route });
  }

  printData() {
    const printColumns = [
      new GridColumn({ HeaderText: 'Name', Field: 'Name', Width: '60%' }),
      new GridColumn({ HeaderText: 'Resolution (px)', Field: 'Resolution', Width: '20%' }),
      new GridColumn({ HeaderText: 'Orientation', Field: 'Orientation', Width: '20%' }),
    ]
    const printMenuData = map(this.digitalMenu, (menu) => {
      return {
        Name: menu.Name,
        Resolution: `${menu.Width} × ${menu.Height}`,
        Orientation: menu.Orientation ? 'Portrait' : 'Landscape'
      }
    })
    this.printService.printEmitter.next({ gridColumns: printColumns, gridData: printMenuData });
  }

  menuBackup(data) {
    this.popup.hide();
    const modalRef = this.modalService.getModalWrapper(MenuDisplayBackupComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center modal-lg',
      initialState: {
        menuId: data.Id,
        menuDisplayName: data.Name
      }
    });
    modal.close.subscribe(res => {
      if (res && res.shouldReload) {
        this.rabbitMQService.sendRefreshMenuDisplayMessage(this.applicationStateService.terminalId, data.Id);
        // this.reloadNavigation.emit(this.screenId);
      }
    });
  }

}
