import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { Observable } from 'rxjs';
import { DefaultSalesProduct } from '../interface/default-sales-product';
import { SalesProductDefaultButton } from '../interface/sales-product-default-button';
import { ActiveProductsWithSize } from '../interface/active-products-with-size';
import { SalesProduct } from '../interface/sales-product';
import { SalesSize } from '../../sales-sizes/interface/sales-size';
import { CopySalesProduct } from '../interface/copy-sales-product';
import { SalesKitchenPrinter } from '../interface/sales-kitchen-printer';
import { NavigationInventoriesBasedOnDietary } from 'src/app/shared/interface/navigation-inventory-based-on-dietary';
import { SalesProductBulkUpdate } from '../interface';

@Injectable({
    providedIn: 'root'
})
export class SalesProductsService {

    constructor(private httpService: HttpClient) { }

    newSalesProduct(): SalesProduct {
        return {
            id: 0,
            Name: '',
            CategoryId: null,
            Active: true,
            Taxable: true,
            DateAdded: new Date(),
            DateEdited: new Date(),
            revenue: true,
            InventoryQty: null,
            Information: '',
            NoTags: false,
            SalesTaxType: 'Default',
            CategoryName: '',
            UsePickScreen: false,
            MakeTableColor: '',
            RowNumber: null,
            PageSize: null,
            CurrentPage: 0,
            KitchenPrinterList: [],
            Message: '',
            IsTimedPrep: false,
            PrepTime: 0,
            PrepGroupName: '',
            PrepButtonColor: '',
            IsUseWorkflowTimer: false,
            IsShowInstructions: false,
            UseParallelPickScreen: null,
            IsSkipReadyState: null,
            IsAllowOutOfStockOrder: false,
            ProductSizes: [],
            DefaultSizeId: 0,
            UnitId: null,
            Increment: null,
            DefaultQty: null,
            SalesProductPrices: [],
            SalesTaxId: null,
            OrderFulfillments: [],
            UnitName: '',
            MenuDisplayText: '',
            Image: '',
            ProductImage: '',
            MenuDisplayDescription: null
        }
    }

    getSalesSizes(salesCategoryId): Observable<Array<SalesSize>> {
        return this.httpService.get<Array<SalesSize>>(
            `${RuntimeConstants.API_PATH}salesproducts/sizes/` + salesCategoryId
        );
    }

    getSalesProducts(): Observable<any> {
        return this.httpService.get(
            `${RuntimeConstants.API_PATH}salesproducts/` + true
        );
    }

    getSalesProductsWithTags(): Observable<any> {
        return this.httpService.get(
            `${RuntimeConstants.API_PATH}salesproducts/withTags`
        );
    }

    getProducts(isActive: boolean, categoryId: number): Observable<Array<SalesProduct>> {
        return this.httpService.get<Array<SalesProduct>>(`${RuntimeConstants.API_PATH}salesproducts/` + isActive + `/` + categoryId);
    }

    getSalesProductIngredient(salesProductId: number): Observable<Array<any>> {
        return this.httpService.get<Array<any>>(`${RuntimeConstants.API_PATH}salesproducts/` + salesProductId + `/ingredient`);
    }

    getSalesProduct(salesProductId: number): Observable<SalesProduct> {
        return this.httpService.get<SalesProduct>(`${RuntimeConstants.API_PATH}salesproducts/` + salesProductId);
    }

    getSalesInventoryProducts(salesProductId: number, isActive: boolean): Observable<any> {
        return this.httpService.get(`${RuntimeConstants.API_PATH}salesproducts/` + salesProductId + `/sales-inventory/active/` + isActive);
    }

    activateSalesProduct(salesProductId: number): Observable<any> {
        return this.httpService.post(
            `${RuntimeConstants.API_PATH}salesproducts/activate/` + salesProductId, null
        );
    }

    getAllSalesProducts(): Observable<any> {
        return this.httpService.get(
            `${RuntimeConstants.API_PATH}salesproducts/salesproducts`
        );
    }

    getProductInventory(productId): Observable<any> {
        return this.httpService.get(
            `${RuntimeConstants.API_PATH}salesproducts/` + productId + `/inventory`
        );
    };


    productQuickReport(salesProductId: number): Observable<any> {
        return this.httpService.get(
            `${RuntimeConstants.API_PATH}salesproducts/` + salesProductId + `/quickreport`
        );
    }

    getProductList(): Observable<Array<DefaultSalesProduct>> {
        return this.httpService.get<Array<DefaultSalesProduct>>(
            `${RuntimeConstants.API_PATH}salesproducts`
        );
    }

    getDefaultButtons(salesProductId: number): Observable<Array<SalesProductDefaultButton>> {
        return this.httpService.get<Array<SalesProductDefaultButton>>(
            `${RuntimeConstants.API_PATH}salesproducts/` + salesProductId + `/defaultproducts`
        );
    }

    addToDefaultProduct(salesProductId: number, defaultProduct) {
        return this.httpService.post(`${RuntimeConstants.API_PATH}salesproducts/` + salesProductId + `/defaultproducts`, defaultProduct);
    }

    addIngredientToProduct(salesProductId: number, sizeId: number, consumption: any): Observable<any> {
        return this.httpService.post(`${RuntimeConstants.API_PATH}salesproducts/` + salesProductId +
            `/size/` + sizeId + `/recipe`, consumption);
    }

    swapIngredientToProduct(salesProductId: number, inventoryProductId: number, newInventoryProduct): Observable<any> {
        return this.httpService.post(`${RuntimeConstants.API_PATH}salesproducts/` + salesProductId + `/recipe/ingredient/` + inventoryProductId, newInventoryProduct);
    }

    moveToBaseRecipe(salesProductId: number, inventoryProductId: number, sizeId: number): Observable<any> {
        return this.httpService.post(`${RuntimeConstants.API_PATH}salesproducts/` + salesProductId + `/recipe/` + inventoryProductId + `/size/` + sizeId + '/move-to-base', null);
    }

    removeFromDefaultProduct(salesProductId: number, defaultProductId: number): Observable<any> {
        return this.httpService.delete(`${RuntimeConstants.API_PATH}salesproducts/` + salesProductId + `/defaultproducts/` + defaultProductId);
    }

    getProductSizesWithPrice(salesProductId: number): Observable<Array<SalesSize>> {
        return this.httpService.get<Array<SalesSize>>(`${RuntimeConstants.API_PATH}salesproducts/sizes/` + salesProductId + `/prices`);
    }

    getSalesProductSizes(salesProductId: number): Observable<Array<any>> {
        return this.httpService.get<Array<any>>(`${RuntimeConstants.API_PATH}salesproducts/` + salesProductId + `/prices`);
    }

    getProductsInventoryConsumption(consumption: any): Observable<Array<any>> {
        return this.httpService.get<Array<any>>(`${RuntimeConstants.API_PATH}salesproducts/` +
            consumption.SalesProductId + `/inventoryProductId/` + consumption.InventoryProductId + `/size/` + consumption.SizeId
            + `/recipe/` + consumption.IsHistory);
    }

    getProductsInventoryConsumptionWithAllSizes(salesProductId: any): Observable<Array<any>> {
        return this.httpService.get<Array<any>>(`${RuntimeConstants.API_PATH}salesproducts/${salesProductId}/recipes`);
    }

    removeProductIngredient(salesProductId?: number, sizeId?: number, recipeId?: number): Observable<any> {
        return this.httpService.put(`${RuntimeConstants.API_PATH}salesproducts/` + salesProductId + `/size/` + sizeId + `/recipe/`
            + recipeId, null);
    }

    changeSalesInventoryProductOrdinal(salesProductId: number, sizeId: number, consumption: any): Observable<any> {
        return this.httpService.put(`${RuntimeConstants.API_PATH}salesproducts/` + salesProductId + `/size/` + sizeId + `/recipe/sort/`
            , consumption);
    }

    getSalesProductMappedButtonDetails(salesProductId: number): Observable<any> {
        return this.httpService.get(`${RuntimeConstants.API_PATH}salesproducts/` + salesProductId + `/mappedButtonDetails`);
    }

    getActiveSalesProductWithSizes(): Observable<Array<ActiveProductsWithSize>> {
        return this.httpService.get<Array<ActiveProductsWithSize>>(`${RuntimeConstants.API_PATH}salesproducts/withsizes`);
    }

    directLinkInventoryProduct(consumption: any): Observable<any> {
        return this.httpService.post(`${RuntimeConstants.API_PATH}salesproducts/link-product`, consumption);
    }

    updateDirectLinkProductStatus(salesProductId: number, sizeId: number, isDirectLink: boolean): Observable<any> {
        return this.httpService.put(`${RuntimeConstants.API_PATH}salesproducts/` + salesProductId + `/sizes/` + sizeId
            + `/update-direct-link/` + isDirectLink, null);
    }

    updateDirectLinkProductStatusAllSizes(salesProductId: number): Observable<any> {
        return this.httpService.put(`${RuntimeConstants.API_PATH}salesproducts/${salesProductId}/update-direct-link`, null);
    }

    copyProductToIngredient(salesProductId: number, sizeId: number, targetProductId: number,
        targetSizeId: number, recipeModel: any): Observable<any> {
        return this.httpService.post(`${RuntimeConstants.API_PATH}salesproducts/` + salesProductId + `/size/` + sizeId + `/recipe/copy/` +
            targetProductId + `/size/` + targetSizeId, recipeModel);
    }

    updateInventoryConsumption(salesProductId: number, sizeId: number, salesInventoryProductId: number, productModel: any)
        : Observable<any> {
        return this.httpService.post(`${RuntimeConstants.API_PATH}salesproducts/` + salesProductId + `/size/` + sizeId +
            `/recipe/` + salesInventoryProductId, productModel);
    }

    deletePrice(salesProductId: number, id: number): Observable<any> {
        return this.httpService.delete(`${RuntimeConstants.API_PATH}salesproducts/${salesProductId}/price/` + id);
    }

    getKitchenPrinter(salesProductId: number): Observable<any> {
        return this.httpService.get(`${RuntimeConstants.API_PATH}salesproducts/` + salesProductId + `/KichenPrinters`);
    }

    addSalesProduct(salesProduct: SalesProduct, imageFile: File, productImage: File): Observable<SalesProduct> {
        const formData = this.prepareImageForm(salesProduct, imageFile, productImage);
        return this.httpService.post<SalesProduct>(`${RuntimeConstants.API_PATH}salesproducts`, formData);
    }

    updateSaleProduct(salesProduct: SalesProduct, imageFile: File, productImage: File): Observable<SalesProduct> {
        const formData = this.prepareImageForm(salesProduct, imageFile, productImage);
        return this.httpService.put<SalesProduct>(`${RuntimeConstants.API_PATH}salesproducts/` + salesProduct.id, formData);
    }

    prepareImageForm(salesProduct: SalesProduct, imageFile: File, productImage: File): FormData {
        const formData = new FormData();
        if (imageFile) {
            formData.append('image', imageFile);
        }
        if (productImage) {
            formData.append('productImage', productImage);
        }
        formData.append('salesProduct', JSON.stringify(salesProduct));

        return formData;
    }

    getProductsInventoryForConsumption(inventoryProductId: number): Observable<any> {
        return this.httpService.get(`${RuntimeConstants.API_PATH}salesproducts/inventoryProductId/` + inventoryProductId + '/size/recipe');
    }

    mergeProduct(sourceProductId: number, destinationProductId: number): Observable<any> {
        return this.httpService.post(`${RuntimeConstants.API_PATH}salesproducts/merge/` + sourceProductId + '/' + destinationProductId,
            null);
    }

    CopySalesProduct(copySalesProduct: CopySalesProduct): Observable<any> {
        return this.httpService.post(`${RuntimeConstants.API_PATH}salesproducts/copySalesProduct`, copySalesProduct);
    }

    getAllActiveInventoryProducts(filter): Observable<any> {
        return this.httpService.get(`${RuntimeConstants.API_PATH}salesproducts/` + filter + `/GetAllActiveInventoryProducts`);
    }

    getInventoryLogStockSalesProducts(salesInventoryProducts) {
        return this.httpService.post(`${RuntimeConstants.API_PATH}salesproducts/instock/sales-inventory`, salesInventoryProducts);
    }

    getKitchenPrintersInfo(salesCategoryId: number): Observable<SalesKitchenPrinter> {
        return this.httpService.get<SalesKitchenPrinter>(`${RuntimeConstants.API_PATH}salesproducts/kitchen-printers-info/` + salesCategoryId);
    }

    getInventoryProductsBasedOnDietaryWarnings(salesProductId: number, dietaryWarningIds: Array<number>): Observable<Array<NavigationInventoriesBasedOnDietary>> {
        return this.httpService.post<Array<NavigationInventoriesBasedOnDietary>>(`${RuntimeConstants.API_PATH}salesproducts/${salesProductId}/dietary-warnings`, dietaryWarningIds);
    }

    getProductsForBulkUpdate(salesProductIds: Array<number>): Observable<Array<SalesProductBulkUpdate>> {
        return this.httpService.post<Array<SalesProductBulkUpdate>>(`${RuntimeConstants.API_PATH}salesproducts/products`, salesProductIds);
    }
}
