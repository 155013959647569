export class ExchangeInfo {

    public ExchangeName: string;
    public ExchangeType: any;
    public RoutingKey: any;
    public Queue: any;

    constructor(exchangeName: string, exchangeType: any, routingKey: any, queue?: any) {
        this.ExchangeName = exchangeName;
        this.ExchangeType = exchangeType;
        this.RoutingKey = routingKey;
        this.Queue = queue;
    }

    public getUri() {
        return '/exchange/' + this.ExchangeName + ((this.RoutingKey && this.RoutingKey !== '') ? ('/' + this.RoutingKey) : '');
    }
}
