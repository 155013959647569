import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KioskScreenConfig, KioskScreenChoice, CreatedShake, ScreenConfig, KioskConfigSetupItem } from 'src/app/order-kiosk/interfaces';
import { CreatedShakeScreenConfig } from 'src/app/order-kiosk/interfaces/config/created-shake-screen-config';
import { KioskNavigationBroadcastingService, KioskNavigationService, OrderKioskConfigService, OrderKioskStorageService } from 'src/app/order-kiosk/services';
import { DomainConstants, RuntimeConstants } from 'src/app/shared';
declare let $: any;

@Component({
  selector: 'pos-ai-shake-created',
  templateUrl: './ai-shake-created.component.html',
  styleUrls: ['./ai-shake-created.component.scss']
})
export class AIShakeCreatedComponent {
  @Input() screenConfigs: Array<KioskScreenConfig> = [];
  @Input() screenId: number;
  @Input() screenButtons: Array<KioskScreenChoice> = [];
  screenConfig: ScreenConfig;
  layoutConfig: CreatedShakeScreenConfig;
  imageRootPath = RuntimeConstants.IMAGE_BASE_PATH + "/order-kiosk/";
  isDesignMode = false;
  sectionHeight: number;
  createdShake: CreatedShake;
  setupItems: KioskConfigSetupItem;

  constructor(private orderKioskConfigService: OrderKioskConfigService,
    private orderKioskStorageService: OrderKioskStorageService,
    private kioskNavigationBroadcastingService: KioskNavigationBroadcastingService,
    private kioskNavigationService: KioskNavigationService,
    private route: ActivatedRoute
  ) {
    this.isDesignMode = route.snapshot.params.id;
    this.screenConfig = orderKioskConfigService.newScreenConfig();
    this.layoutConfig = orderKioskConfigService.newCreatedShakeScreenConfig();
    this.createdShake = this.newCreatedShake();
  }

  ngOnInit() {
    this.prepareConfigs();
    this.resizeWindow();
    this.createdShake = this.orderKioskStorageService.createdShake ?? this.newCreatedShake();
    this.setupItems = { IsEditable: true, SetButton: false, RemoveButton: false };
    this.kioskNavigationBroadcastingService.newAiShakeCreated.subscribe(() => {
      this.createdShake = this.orderKioskStorageService.createdShake;
    });
  }

  newCreatedShake(): CreatedShake {
    return {
      Name: 'AI Shake',
      PrimaryFlavor: { Name: 'Primary Flavor', InventoryProductId: 0 },
      SecondaryFlavors: [
        { Name: 'Secondary Flavor 1', InventoryProductId: 0 },
        { Name: 'Secondary Flavor 2', InventoryProductId: 0 }
      ]
    };
  }

  prepareConfigs() {
    const screenConfig = this.orderKioskConfigService.parseConfigProperties(DomainConstants.KioskScreenConfigProperties.ScreenConfig, this.screenConfigs);
    if (screenConfig) this.screenConfig = screenConfig;

    const layoutConfig = this.orderKioskConfigService.parseConfigProperties(DomainConstants.KioskScreenConfigProperties.CreatedShakeScreenConfig, this.screenConfigs);
    if (layoutConfig) this.layoutConfig = layoutConfig;
  }

  resizeWindow() {
    $(window).resize(() => {
      this.calculateSectionHeight();
    });
  }

  ngAfterViewInit(): void {
    this.calculateSectionHeight();
  }

  calculateSectionHeight() {
    this.sectionHeight = this.orderKioskConfigService.calculateInvoiceHeight(this.screenConfig);
  }

  buttonClick(kioskScreenChoice: KioskScreenChoice, isTryAgain = false) {
    if (!this.isDesignMode) {
      if (isTryAgain) {
        this.kioskNavigationService.navigateToScreen(kioskScreenChoice.NextScreenId);
      } else this.addToCart(kioskScreenChoice);
    } 
  }

  addToCart(kioskScreenChoice: KioskScreenChoice) {
    const screen = this.orderKioskStorageService.kioskScreens.find(x => x.Id == this.screenId);
    this.kioskNavigationService.addAIShakeToOrder(kioskScreenChoice.NextScreenId ?? screen?.DefaultNextScreenId);
  }

}
