import { Component, OnInit } from '@angular/core';
import { ApplicationStateService } from '../shared';

@Component({
  selector: 'pos-back-office',
  templateUrl: './back-office.component.html',
})
export class BackOfficeComponent implements OnInit {

  headerLinks = {
    tasks: this.applicationStateService.applicationInitializationConfigurations.HasTaskSetup,
    home: false,
    warning: false,
    changeTerminal: false,
    mappedTerminals: false,
    admin: false,
    time: false,
    signIn: true,
    activeOrders: false,
    userMenu: true
  };
  homeState = '/back-office/console';

  constructor(private applicationStateService: ApplicationStateService) { }

  ngOnInit(): void {
    this.applicationStateService.settingParam.BackofficeOnlyMode = true;
  }

}
