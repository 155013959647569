<div [ngStyle]="{'height' :height + 'px' ,'background-color': backgroundColor}" class="p-pix-2 overflow-auto" [ngClass]="data?.InteractiveStyleClass">
    <div class="col-12 p-0 display-flex tile-title">
        <div [ngStyle]="textStyle" class="width-85">
            <div class="text-ellipsis" innerHTML="{{data.Text }}"></div>
        </div>
        <div class="width-15 text-right">
            <div *ngFor="let price of data.Prices; let last = last;" [ngStyle]="priceStyle">
                <div [innerHTML]="price.Price + (last ? '' : '/')"> </div>
            </div>
        </div>
    </div>
    <div [ngStyle]="descriptionStyle" class="col-12 p-0 ps-pix-4">
        <div innerHTML="{{data.Description }}"></div>
    </div>
</div>
