import { Component, OnInit, ViewChild } from '@angular/core';
import { Permissions, Levels, Messages } from '../../../../shared/constants/';
import { GridColumn, EditColumn, DeleteColumn, LinkColumn, TemplateColumn, TextAlign, TableComponent } from '@tarktech/tark-ng-utils';
import { SalesCategory } from '../../interface/sales-category';
import { ConfirmDeleteComponent } from 'src/app/shared/components/confirm-delete/confirm-delete.component';
import { AuthenticationService } from 'src/app/shared/auth/services/authentication.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { SalesCategoryService } from 'src/app/shared/services/sales-category.service';
import { finalize } from 'rxjs/operators';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import { BaseGridComponent, ApplicationStateService, salesCategoriesCommentDollar } from 'src/app/shared';
import { ActivatedRoute, Router } from '@angular/router';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';

@Component({
    selector: 'pos-sales-categories',
    templateUrl: './sales-categories.component.html',
    styleUrls: ['./sales-categories.component.scss']
})
export class SalesCategoriesComponent extends BaseGridComponent implements OnInit {

    permission = {
        name: Permissions.SalesCategories,
        readOnlyLevel: Levels.ReadOnly,
        editLevel: Levels.Edit
    };
    public icons = {
        salesCategoriesCommentDollar
    };
    salesCategoriesColumns: Array<GridColumn> = [];
    printSalesCategoriesColumns: Array<GridColumn> = [];
    salesCategories: SalesCategory[] = [];
    salesCategoryId = 0;
    @ViewChild('confirmSalesCategoryDelete') private confirmSalesCategoryDelete: ConfirmDeleteComponent;
    isActiveSalesCategories = true;
    @ViewChild('makeTableColorColumn', {static: true}) private makeTableColorColumn: any;
    @ViewChild('salesCategoryGrid', {static: true}) salesCategoryGrid: TableComponent;
    constructor(private authenticationService: AuthenticationService,
        private alertService: AlertsService,
        private spinnerService: SpinnerService,
        private salesCategoryService: SalesCategoryService,
        protected route: ActivatedRoute,
        private router: Router,
        protected applicationStateService: ApplicationStateService,
        private printService: PrintTableService) {
        super(applicationStateService, route);
    }

    getOtherProperties() {
        this.othersProperties = { isActiveSalesCategories: this.isActiveSalesCategories };
    }

    ngOnInit() {
        if (this.othersProperties.isActiveSalesCategories === false) {
            this.isActiveSalesCategories =  false;
        }
        this.configureGridColumns();
        this.reloadSalesCategories();
        this.scrollToTop();
        this.salesCategoryGrid.context = this.gridContext;
    }
    private configureGridColumns() {

        const editColumn = new EditColumn({
            Clicked: (salesCategories) => {
                this.editSalesCategories(salesCategories.id);
            },
            Width: '57px',
            AddIcon: new LinkColumn({
                Clicked: () => {
                    this.editSalesCategories(0);
                },
                ToolTip: 'New'
            })
        });

        const deleteColumn = new DeleteColumn({
            Clicked: (salesCategory) => {
                this.deleteSalesCategories(salesCategory);
            },
            Width: '83px',
            PrintIcon: new LinkColumn({
                Clicked: () => {
                    this.printDiv();
                },
                ToolTip: 'Print'
            })
        });

        const makeTableColorColumn = new TemplateColumn({
            HeaderText: 'Make Table Color',
            itemTemplate: this.makeTableColorColumn,
            TextAlign: TextAlign.Center,
            Width: '10%',
            Field: 'make_table_color',
            IsSortable: false
        });

        this.salesCategoriesColumns = [
            new GridColumn({ HeaderText: 'Category', Field: 'name', IsSortable: true, Width: '82%' }),
            makeTableColorColumn
        ] as Array<GridColumn>;

        this.printSalesCategoriesColumns = [...this.salesCategoriesColumns];

        if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
            this.salesCategoriesColumns.push(editColumn);
            this.salesCategoriesColumns.push(deleteColumn);
        }
    }
    private reloadSalesCategories() {
        this.spinnerService.show();
        this.salesCategoryService.getSalesCategories(this.isActiveSalesCategories)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({ next: (salesCategories: SalesCategory[]) => {
                this.salesCategories = (salesCategories === null ? [] : salesCategories);
            }, error: this.alertService.showApiError });
    }
    private editSalesCategories(id: number) {
        this.router.navigate([id], { relativeTo: this.route, state: { salesCategoryId: id }});
    }
    private deleteSalesCategories(salesCategory: SalesCategory) {
        this.salesCategoryId = salesCategory.id;
        this.confirmSalesCategoryDelete.Show(StringUtils.format(Messages.ConfirmDeleteSalesCategory, { 'salesCategory': salesCategory.name }));
    }
    onConfirmAcknowledge() {
        this.spinnerService.show();
        this.salesCategoryService.deleteSalesCategory(this.salesCategoryId)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({ next: () => {
                this.alertService.renderSuccessMessage(Messages.SalesCategoryDeleted);
                this.reloadSalesCategories();
                this.salesCategoryId = 0;
            }, error: this.alertService.showApiError });
    }
    printDiv() {
        this.printService.printEmitter.next({ gridColumns: this.printSalesCategoriesColumns, gridData: this.salesCategories });
    }
    reload() {
        this.reloadSalesCategories();
    }
    private scrollToTop(): void {
        window.scrollTo(0, 0);
    }

    close() {
        this.router.navigate(['../'], { relativeTo: this.route });
    }
}
