<div class="col-md-3 pt-pix-10">
    <div class="left-nav-toggle" id="menu-toggle-div" (click)="toggleFullScreen()">
      <a class="left-nav-toggle__icon">
        <pos-icon class="black"
          [icon]="isFullScreen ? icons.infoManageAngleDoubleRight : icons.infoManageAngleDoubleLeft"></pos-icon>
      </a>
    </div>
  </div>
  <div class="col-md-12 tab-section px-3" id="automatedExportDiv">
    <div class="vertical-tabs">
      <ul class="nav nav-pills nav-stacked flex-column" [hidden]="isFullScreen">
        <li class="tab-head" permissions='[{"Name":"{{permissions.ManageAutomatedExports}}", "Level":"{{accessLevels.Access}}"}]'>
          Configuration
        </li>
        <li role="presentation" grantif="all" routerLink="data-source" routerLinkActive="active"
          scrollup permissions='[{"Name":"{{permissions.ManageAutomatedExports}}", "Level":"{{accessLevels.Access}}"}, {"Name":"{{permissions.AutomatedExportsConfiguration}}", "Level":"{{accessLevels.Access}}"}]'>
          <a>
            <div class="display-contents">
              <section class="float-start text-center col-33">
                <pos-icon class="black font-x-normal" [icon]="icons.database"></pos-icon>
              </section>
            </div>
            <div class="display-flex">
              Data Sources
            </div>
          </a>
        </li>
        <li role="presentation" grantif="all" routerLink="export-connection" routerLinkActive="active"
          scrollup permissions='[{"Name":"{{permissions.ManageAutomatedExports}}", "Level":"{{accessLevels.Access}}"}, {"Name":"{{permissions.AutomatedExportsConfiguration}}", "Level":"{{accessLevels.Access}}"}]'>
          <a>
            <div class="display-contents">
              <section class="float-start text-center col-33">
                <pos-icon class="black font-x-normal" [icon]="icons.hardwareInterfacePlug"></pos-icon>
              </section>
            </div>
            <div class="display-flex">
              Connections
            </div>
          </a>
        </li>
        <li role="presentation" grantif="all" routerLink="macro-scripts" routerLinkActive="active"
          scrollup permissions='[{"Name":"{{permissions.ManageAutomatedExports}}", "Level":"{{accessLevels.Access}}"}, {"Name":"{{permissions.AutomatedExportsConfiguration}}", "Level":"{{accessLevels.Access}}"}]'>
          <a>
            <div class="display-contents">
              <section class="float-start text-center col-33">
                <pos-icon class="black font-x-normal" [icon]="icons.scroll"></pos-icon>
              </section>
            </div>
            <div class="display-flex">
              Macro Scripts
            </div>
          </a>
        </li>
      </ul>
      <div class="p-0">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  
