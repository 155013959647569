<div>
  <h3 class="setting-title"> Attributes </h3>
</div>

<pos-row class="p-0">
  <pos-column md="6" xs="12" class="pt-pix-10" *ngFor="let accountAttributeType of accountAttributeTypes">
    <pos-row>
      <pos-column xs="12">
        <label>{{accountAttributeType.Name}}</label>
      </pos-column>
      <pos-column sm="8" xs="12">
        <pos-account-attribute [attributeType]="accountAttributeType" (changeAttribute)="changeAttribute()"> </pos-account-attribute>
      </pos-column>
    </pos-row>
  </pos-column>
</pos-row>
