export interface WorkSchedule {
    Id: number;
    user_id: number;
    user_name: string;
    start_date: Date;
    start_time: any;
    dateadded: Date;
    end_date: Date;
    end_time: any;
    optional: boolean;
    TagId?: number;
    Message: string;
}
export interface UserWorkSchedule {
    WorkScheduleId: number;
    StartDate: Date;
    EndDate: Date;
    IsOptional: boolean;
    Color: string;
    LastName: string;
    FirstName: string;
    UserId?: number;
    TagName: string;
}

export interface WorkScheduleTag {
    Id: number;
    Name: string;
    Color: string;
}

export interface WorkScheduleBatchEntry {
    Id: number;
    start_date: Date;
    end_date: Date;
    StartTime: any;
    EndTime: any;
    TagId: number;
    optional: boolean;
    isActive: boolean;
    isConflict: boolean;
    TagColor: string;
}

export interface UserWorkScheduler {
    StartDate?: Date;
    EndDate?: Date;
    UserId?: number;
}

export enum FilterBy {
    TwoWeeks = '2Weeks',
    Last7Days = 'Last7Days',
    Next7Days = 'Next7Days',
    Today = 'Today',
}

export enum ParameterName {
    WeekStart = 'WeekStart'
}

export enum WeekDays {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6
}

export enum WorkScheduleBatchEntryControl {
    StartDate = 'StartDate',
    StartTime = 'StartTime'
}

export enum UserWorkScheduleState {
    State = '/manage/work-schedule'
}
