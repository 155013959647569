import { Injectable } from '@angular/core';
import { BaseCrudService } from './base-crud.service';
import { HttpClient } from '@angular/common/http';
import { RuntimeConstants } from '../constants/runtime-constants';
import { MultiProductGroup } from 'src/app/information-management/screen/components/multi-product-group/multi-product-group';

@Injectable({
  providedIn: 'root'
})
export class MultiProductGroupService extends BaseCrudService<MultiProductGroup> {

  multiProductGroupApiUrl: string = '';
  constructor(httpService: HttpClient) {
    super('MultiProductGroup', httpService);
    this.multiProductGroupApiUrl = `${RuntimeConstants.API_PATH}MultiProductGroup`;
  }

}

