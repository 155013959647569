import { Route } from '@angular/router';
import { AuthGuard } from '../app-guard';
import { Levels, DomainConstants } from '../shared/constants';
import { DigitalSignageComponent } from './digital-signage/digital-signage.component';

const DIGITAL_SIGNAGE: Route = {
    path: '',
    canActivate: [AuthGuard],
    component: DigitalSignageComponent,
    data: {
        permissions: [{ 'Name': DomainConstants.TerminalTypes.DIGITAL_SIGNAGE.Permission, 'Level': Levels.Access }]
    }
};

export const DIGITAL_SIGNAGE_STATES = [DIGITAL_SIGNAGE];
