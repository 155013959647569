<pos-list-wrapper [headingIcon]="icons.infoManageInfractionThumbsDown" heading="Infractions" (close)="close()">
    <div class="row">
        <div class="col-12">
            <ng-table id="infraction" [sort]="true" [exportFilename]="'infraction'"
                [columns]="infractionColumns" #infractionGrid [data]="infractions">
            </ng-table>
        </div>
    </div>
</pos-list-wrapper>
<pos-print-table [hidden]="true"></pos-print-table>
