import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'pos-sales-product-dashboard',
  templateUrl: './sales-product-dashboard.component.html',
  styleUrls: ['./sales-product-dashboard.component.scss']
})
export class SalesProductDashboardComponent implements OnInit {

  @Input() salesProductName: string;
  @Input() salesProductId: number;
  @Input() dashboardId: number;
  @Output() close: EventEmitter<any> = new EventEmitter();

  height = window.innerHeight - 150;

  constructor() { }

  ngOnInit(): void {
  }

  onCancel() {
    this.close.emit();
  }
}
