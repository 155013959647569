<div *ngIf="isPopup">
  <button class="close" (click)="onCancel()">&times;</button>
  <h4 class="modal-content__heading">Product: {{productName}}
  </h4>
</div>
<div class="clearfix"></div>
<div class="overflow-hidden pb-pix-10">
  <context-provider provide="props">
    <div class="col-12 " [ngClass]="{'screen-scroll pos-modal-body': isPopup}">
      <div class="col-12"
        [ngClass]="isPopup ? 'col-12': 'col-11 p-0'">
        <pos-row>
          <pos-column>
            <pos-form-field label="Select Product">
              <div class="row">
                <div class="col-9" [ngClass]="{'col-lg-5 col-md-6': !isPopup}">
                  <ng-select posInput appendTo="body" [items]="salesProductList" bindLabel="Name" bindValue="ID"
                    placeholder="Select product to add" [(ngModel)]="defaultProductId" name="selectedFunction">
                  </ng-select>
                </div>
                <div class="col-3" [ngClass]="{'col-md-1 ps-0': !isPopup}">
                  <button type="button" class="btn btn-secondary btn normal pos-button vertical-center-align" [ngClass]="{'pull-right': isPopup}"
                    (click)="addToDefaultProduct()" [disabled]="!(defaultProductId > 0)">
                    <pos-icon [icon]="icons.normalPlus"></pos-icon>
                    <span class="ps-1">Add</span>
                  </button>
                </div>
              </div>
            </pos-form-field>
          </pos-column>
        </pos-row>
      </div>
      <div class="pt-pix-15"
        [ngClass]="isPopup ? 'col-12': 'col-md-6 col-12'">
        <ng-table #gridComponent [columns]="defaultButtonColumn" [search]="false" [paginator]="false"
          [data]="defaultSelectedButtons">
        </ng-table>
      </div>
      <div class="clearfix"></div>
      <div class="col-12 mt-pix-10">
        Note: Default buttons are performed by linking to a product; therefore, only buttons which are linked to a
        product can be made a default button selection.
      </div>
      <div class="clearfix"></div>
    </div>
  </context-provider>
  <div class="clearfix"></div>
  <div class="col-12 action-button-container popup-footer" *ngIf="isPopup">
    <button type="button" class="btn-lg btn-secondary btn btn-cancel-modal pos-button" (click)="onCancel()">
      Close
    </button>
  </div>
</div>
