import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';
import { Account } from '../../information-management/account/interface/account';
import { Observable } from 'rxjs';
import { AccountDetailsPrint } from '../../information-management/account/interface/account-details-print';

@Injectable()
export class AccountService extends BaseCrudService<Account> {

    accountApiUrl = '';
    constructor(httpService: HttpClient) {
        super('accounts', httpService);
        this.accountApiUrl = RuntimeConstants.API_PATH + 'accounts';
    }
    newAccount() {
        return {
            Id: 0,
            Name: '',
            IsActive: true,
            Address: '',
            City: '',
            State: '',
            Phone: '',
            Notes: '',
            AccountTypeId: null,
            AccountCategoryID: null,
            AccountType: '',
            LoyaltyCardNumber: null,
            PostalCode: null,
            AccountAttributes: [],
            AccountCategoryName: ''
        };
    }
    printAccountDetails(accountDetailPrint: AccountDetailsPrint): Observable<any> {
        return this.httpService.post(this.accountApiUrl + '/print', accountDetailPrint);
    }
    getCustomerAccountOrders(accountId: number): Observable<any> {
        return this.httpService.get(this.accountApiUrl + '/orders/' + accountId);
    }
    getAccounts(isActive: boolean = null): Observable<any> {
        return this.httpService.get(this.accountApiUrl + (isActive ? '/?isActive=' + isActive : ''));
    }

    getAccountByDetails(): Observable<any> {
        return this.httpService.get(`${this.accountApiUrl}/getAccountByDetails`);
    }

    saveAccountDetails(account: Account): Observable<any> {
        return this.httpService.post(this.accountApiUrl, account);
    }
}
