<pos-list-wrapper [headingIcon]="icons.inventoryZonesWarehouse" heading="Inventory Zones" (close)="close()">
  <ng-table #inventoryZoneGrid [columns]="inventoryZoneListColumns" [exportFilename]="'inventory-zones'"
    [data]="inventoryZoneListData">
  </ng-table>
  <pos-confirm-delete (onConfirmAcknowledge)="onConfirmAcknowledge()" #confirmDeleteOption>
  </pos-confirm-delete>
</pos-list-wrapper>


<ng-template #popOverForInventoryZone let-data="rowData">
  <span class="text-center mt-pix-n4" style="overflow-wrap: break-word;">
    <a class="action-btn grey pointer" #pop="bs-popover" placement="left" (click)="onLinkPopOver(data,pop)"
      [outsideClick]="true" [popover]="inventoryZonePopoverTemplate" triggers="click">
      <pos-action-icon [icon]="icons.barsWhite"></pos-action-icon>
    </a>
  </span>
</ng-template>

<ng-template #inventoryZonePopoverTemplate>
  <div class="">
    <ul>
      <li class="listbullet">
        <a class="grey" (click)="openNextInventoryLogDateModal(inventoryZoneData)">
          <pos-icon [icon]="icons.listAlt" class="pe-pix-3" style="margin-left: -5px;">
          </pos-icon>
          Next Inventory Log Date
        </a>
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #popOverForInventoryZoneName let-data="rowData">
  <span #pop="bs-popover" class="grey popover-zone pointerStyle" placement="right"
    (click)="openInventoryProductForZone(data,pop)  " [outsideClick]="true" [popover]="inventoryZoneNamePopoverTemplate"
    triggers="click" #popoverTooltip><span tooltip="Click to see products" placement="top">{{data.Name}}</span></span>
</ng-template>
<ng-template #inventoryZoneNamePopoverTemplate>
  <pos-inventory-products-for-zone [inventoryZoneId]="selectedId"></pos-inventory-products-for-zone>
</ng-template>

<pos-print-table #printTableReference [hidden]="true"></pos-print-table>
