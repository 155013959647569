import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import * as _ from 'lodash';
import { SettingParam, ApplicationStateService, DomainConstants } from 'src/app/shared';

@Component({
  selector: 'pos-settle-accounts',
  templateUrl: './settle-accounts.component.html',
  styleUrls: ['./settle-accounts.component.scss', '../../../../../../styles/pages/design/settle.scss']
})
export class SettleAccountsComponent implements OnInit, OnChanges {

  @Input() orderId: number;
  @Input() paymentLoader: boolean;
  @Input() accountsList: Array<any> = [];
  @Output() accountSelection: EventEmitter<any> = new EventEmitter();
  selectedAccountOrderId: number;
  settingParam: SettingParam;
  subAccountTerm: string;
  loyaltyAccountTerm: string = '';
  accountTypes = DomainConstants.AccountTypes;
  accountTerm: string = '';
  tableTerm: string = '';
  constructor(private applicationStateService:  ApplicationStateService) { }

  ngOnInit() {
    this.setDefault();
  }

  setDefault() {
    this.settingParam = this.applicationStateService.settingParam;
    this.subAccountTerm = this.settingParam.SubaccountTerm ? this.settingParam.SubaccountTerm : 'Seat';
    this.loyaltyAccountTerm = this.settingParam.LoyalAccountTerm ? this.settingParam.LoyalAccountTerm : 'Account';
    this.tableTerm = this.settingParam.TableTerm ? this.settingParam.TableTerm : 'Table';
    this.accountTerm = this.settingParam.AccountTerm ? this.settingParam.AccountTerm : 'Tab';
  }

  ngOnChanges() {
    if (this.accountsList && this.accountsList.length > 0) {
      const account = _.find(this.accountsList, (item) => {
        return !!item.IsSelectedAccount;
      });
      if (account && !!account.IsSelectedAccount) {
        this.selectedAccountOrderId = account.id;
      } else {
        this.selectedAccountOrderId = 0;
      }
      if (this.orderId) {
        const self = this;
        _.forEach(this.accountsList, (acc) => {
          if (acc.id == this.orderId) {
            setTimeout(() => {
              self.onAccountSelection(acc);
            });
          }
        });
      }
    }
  }

  onAccountSelection(accountDetails) {
    if (!this.paymentLoader) {
      this.selectedAccountOrderId = accountDetails.id;
      accountDetails.IsSelectedAccount = true;
      this.accountSelection.emit({ 'accountDetails': accountDetails });
    }
  }

}
