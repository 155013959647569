import { IHaveId } from '../models';
import { BaseCrudService, AlertsService } from '../services';
import { SpinnerService } from './spinner/spinner.service';
import { BaseCrudComponent } from './base-crud.component';
import { NgForm } from '@angular/forms';
import { EventEmitter } from '@angular/core';
import { FormUtilityService } from '../services/form-utility.service';
export abstract class BaseCrudModelComponent<TModel extends IHaveId> extends BaseCrudComponent<TModel> {
    private close: EventEmitter<any> = new EventEmitter<any>();
    abstract get getForm(): NgForm;
    isDetectUnsavedChanges = true;
    formValueChangeSubscription;
    protected initialValues;
    formTimeout;
    constructor(protected crudService: BaseCrudService<TModel>,
        protected alertService: AlertsService,
        protected spinnerService: SpinnerService,
        protected formUtilityService: FormUtilityService) {
        super(crudService, alertService, spinnerService, formUtilityService);
        this.setInitialValue();
    }

    setInitialValue = (): void => {
        this.formTimeout = setTimeout(() => {
            if (this.getForm) {
                const form = this.getForm;
                if (!this.initialValues) {
                    this.initialValues = form.value;
                }
                this.formValueChangeSubscription = form.valueChanges.subscribe({
                    next: () => {
                        if (!form.dirty) {
                            this.initialValues = form.value;
                        } else {
                            this.unsubscribeFormValueChangeSubscription();
                        }
                    }
                });
                this.clearFormTimeout();
            }
        });
    }

    clearFormTimeout() {
        if (this.formTimeout) {
            clearTimeout(this.formTimeout);
        }
    }

    openConfirmModal(reason?): Promise<boolean> {
        return new Promise<boolean>((resolve, _reject) => {
            const modal = this.formUtilityService.openConfirmModal();
            modal.close.subscribe(res => {
                if (res.shouldHide) {
                    this.close.next(reason);
                }
                return resolve(res.shouldHide);
            });

        });
    }

    public hide(reason?) {
        if (this.isDetectUnsavedChanges && this.getForm && this.hasUnsavedChanges() && !(this.getForm.submitted && !this.getForm.invalid)) {
            return this.openConfirmModal(reason);
        }
        this.close.next(reason);
    }

    hasUnsavedChanges() {
        return this.formUtilityService.hasUnsavedChanges(this.getForm, this.initialValues);
    }

}
