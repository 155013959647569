import { Directive, Input, ElementRef, Renderer2, OnInit, OnDestroy, DoCheck } from '@angular/core';
// import { isObject, isUndefined } from 'util';

@Directive({
  selector: '[posValidationGroup]'
})
export class ValidationGroupDirective implements OnInit, OnDestroy, DoCheck {

  @Input() posValidationGroup: any;
  @Input() ngModel: any;
  @Input() exValidation?: boolean = null;
  @Input() isIgnoreModel?: boolean = false;
  currentElement: ElementRef;
  elementRenderer: Renderer2;
  constructor(el: ElementRef, private renderer: Renderer2) {
    this.currentElement = el;
    this.elementRenderer = renderer;
  }

  ngOnInit() {
    this.posValidationGroup = typeof this.posValidationGroup === 'undefined' ? { // isUndefined(this.posValidationGroup)
      '$valid': true
    } : this.posValidationGroup;

    this.posValidationGroup.$valid = !!this.posValidationGroup.$valid;
    if (typeof this.posValidationGroup.controls === 'undefined'
      // isUndefined(this.posValidationGroup.controls)
      ) {
      this.posValidationGroup.controls = {};
    }

    if (typeof this.posValidationGroup.controls[this.currentElement.nativeElement.id] === 'undefined') {
      // isUndefined(this.posValidationGroup.controls[this.currentElement.nativeElement.id])
      this.posValidationGroup.controls[this.currentElement.nativeElement.id] = {
        'id': this.currentElement.nativeElement.id,
      };
    }
    this.exValidation = this.exValidation !== null ? this.exValidation : true;
    this.setValidationGroupControl(this.currentElement.nativeElement.id, this.ngModel, this.exValidation, this.isIgnoreModel);
  }

  setValidationGroupControl(id, model, exValidation, isIgnoreModel) {
    this.posValidationGroup.controls[id] = {
      '$valid': model ? true : false,
      'modal': model,
      'externalValidation': exValidation,
      'ignoreModel': isIgnoreModel
    };
  }

  ngOnDestroy(): void { }

  updateGroupValidity() {
    this.posValidationGroup.$valid = Object.keys(this.posValidationGroup.controls).reduce(($valid, ctrl) => {
      return $valid && (!!this.posValidationGroup.controls[ctrl].modal || !!this.posValidationGroup.controls[ctrl].ignoreModel)
        && !!this.posValidationGroup.controls[ctrl].externalValidation;
    }, true);
  }

  ngDoCheck() {
    this.setValidationGroupControl(this.currentElement.nativeElement.id, this.ngModel, this.exValidation, this.isIgnoreModel);
    this.updateGroupValidity();
  }
}
