import { Injectable } from "@angular/core";
import { RuntimeConstants } from "../constants/runtime-constants";

@Injectable({
    providedIn: 'root'
})
export class FontService {
    constructor() {
    }

    loadMenuDisplayFonts() {
        this.loadFonts('ThirstyRoughRegularOne.otf', 'Thirsty Rough');
        this.loadFonts('RobotoSlab-Bold.ttf', 'Roboto Slab');
        this.loadFonts('NexaRustSans-Black.otf', 'Nexa Rust Sans');
        this.loadFonts('Cafeteria Bold.otf', 'Cafeteria');
        this.loadFonts('Lato-Regular.ttf', 'Lato');
        this.loadFonts('amoreiza-regular.ttf', 'amorezia');
        this.loadFonts('AntrySans-Regular.ttf', 'Antry Sans')
    }

    loadOrderConfirmationFonts() {
        this.loadFonts('BRUSHSCI.TTF', 'Brush Script MT');
        this.loadFonts('EBGaramond-Regular.ttf', 'Garamond')
    }

    loadOrderKioskFonts() {
        this.loadFonts('Montserrat-Bold.ttf', 'Montserrat Bold');
        this.loadFonts('Montserrat-Black.ttf', 'Montserrat Black');
        this.loadFonts('Montserrat-ExtraBold.ttf', 'Montserrat ExtraBold');
        this.loadFonts('Montserrat-Medium.ttf', 'Montserrat Medium');
        this.loadFonts('Montserrat-Regular.otf', 'Montserrat Regular'), 
        this.loadFonts('jacksbv.ttf', 'jacksbv');
        this.loadFonts('Basyar Jalai.ttf', 'Basyar Jalai');
        this.loadFonts('CharlevoixPro-Black.otf', 'CharlevoixPro Black');
        this.loadFonts('CharlevoixPro-Bold.otf', 'CharlevoixPro Bold');
        this.loadFonts('CharlevoixPro-Regular.otf', 'CharlevoixPro Regular');
        this.loadFonts('CharlevoixPro-ExtraBold.otf', 'CharlevoixPro ExtraBold');
    }

    private loadFonts(fontFileName: string, fontName: string) {
        const existingStyle = document.getElementById(fontName);
        if (!existingStyle) {
            const newStyle = document.createElement('style');
            newStyle.setAttribute('id', fontName);
            const font = RuntimeConstants.FONT_BASE_PATH + '/' + fontFileName;
            newStyle.appendChild(document.createTextNode('@font-face{font-family: ' + fontName + '; src: url("' + font + '");}'));
            document.head.appendChild(newStyle);
        }
    }
}
