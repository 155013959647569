import { EventEmitter, Injectable } from '@angular/core';
import { Observable, Subject, Subscription, filter, finalize, from, mergeMap, of } from 'rxjs';
import { IKioskNavigation } from '../interfaces/ikiosk-navigation';
import { DomainConstants } from 'src/app/shared/constants';
import { cloneDeep, find, forEach, orderBy, remove, filter as lodashFilter, intersection } from 'lodash';
import { KioskChoice } from '../interfaces/kiosk-choice';
import { KioskScreen } from '../interfaces/kiosk-screen';
import { KioskNavigationBroadcastingService } from './kiosk-navigation-broadcasting.service';
import { ButtonBehaviorExecutor } from '../kiosk-behaviors';
import { OrderInvoice, TerminalDetails, VersionedObject } from 'src/app/orders';
import { KioskOrderVersionVal } from '../interfaces/kiosk-order-version-val';
import { KioskOrderService } from './kiosk-order.service';
import { ModalService, OrderKioskScreenService, RabbitMQService, InfoModalComponent, AlertsService, ApplicationStateService, UserIdleService, HttpStatusCodes, SettingParam, Messages, StringUtils, ICloseable } from 'src/app/shared';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CreatedShake, KioskOrder, KioskScreenChoice, ShakeCreatorRequest } from '../interfaces';
import { Router } from '@angular/router';
import { BehaviorPromiseProviderService } from './behavior-promise-provider.service';
import { SalesProductTag } from 'src/app/information-management';
import { KioskOverlayComponent } from '../components';
import { OrderKioskStorageService } from './order-kiosk-storage.service';
import { ShakeCreatorService } from '..';

@UntilDestroy({ checkProperties: true })
@Injectable({
  providedIn: 'root'
})
export class KioskNavigationService {
  reloadNavigationButtons = new EventEmitter<boolean>();
  private currentNavigationSubscription: Subscription;
  private navigationSubject: Subject<IKioskNavigation> = new Subject<IKioskNavigation>();
  dietaryWarnings = [];
  allDietaryWarnings = [];
  mappedTerminals: TerminalDetails[] = [];
  rabbitMqSalesProductUpdatedEventSubscription: Subscription;
  private get navigation$(): Observable<IKioskNavigation> {
    return this.navigationSubject.asObservable();
  }
  public orderNavigation: KioskScreen;
  private screenNavigation: KioskScreen;
  private screensWithButtons: Array<KioskScreen> = [];
  versionedVal = new VersionedObject<KioskOrderVersionVal>(this.kioskOrderService.newVersionValObject());
  currentScreenConfig: { ScreenId: number, ConfigId: number };
  homeScreenId: number;
  searchText: string = null;
  orderDetails: OrderInvoice;
  rabbitMqSalesProductOutOfStockEventSubscription: Subscription;
  rabbitMqSalesProductReplenishedEventSubscription: Subscription;
  userIdleTimeout = 0;
  settingParam: SettingParam;

  constructor(private buttonBehaviorExecutor: ButtonBehaviorExecutor,
    private kioskNavigationBroadcastingService: KioskNavigationBroadcastingService,
    private kioskOrderService: KioskOrderService,
    private kioskScreenService: OrderKioskScreenService,
    private modalService: ModalService,
    private alertService: AlertsService,
    private rabbitMQService: RabbitMQService,
    private applicationStateService: ApplicationStateService,
    private kioskStorageService: OrderKioskStorageService,
    private shakeCreatorService: ShakeCreatorService,
    private userIdleService: UserIdleService,
    private router: Router,
    private behaviorPromiseProviderService: BehaviorPromiseProviderService
  ) {
    this.screenNavigation = kioskScreenService.newScreen();
    this.settingParam = applicationStateService.settingParam;
  }

  public initializeNavigationService = (screens: Array<KioskScreen>, homeScreenId: number) => {
    this.resetNavigation();
    this.homeScreenId = homeScreenId;
    this.currentScreenConfig = { ScreenId: homeScreenId, ConfigId: null };
    this.screensWithButtons = screens;
    this.setButtonProperties();
    this.screenNavigation = screens.reduce((acc, screen) => {
      acc[screen.Id] = screen;
      return acc;
    }, this.kioskScreenService.newScreen());
    this.orderNavigation = this.screenNavigation[homeScreenId];
    if (!this.currentNavigationSubscription) {
      this.subscribeToNavigation();
    }
    this.subscribeToSalesProductOutOfStockEventExchange();
    this.subscribeToSalesProductReplenishedEventExchange();
    this.subscribeToSalesProductUpdatedEventExchange();
  }

  resetNavigation() {
    this.versionedVal = new VersionedObject<KioskOrderVersionVal>(this.kioskOrderService.newVersionValObject(this.orderDetails?.Id?.Value));
    this.orderNavigation = null;
  }

  resetProps = (isResetCurrentScreenConfig = true) => {
    this.resetNavigation();
    this.userIdleService.stop();
    this.currentScreenConfig = isResetCurrentScreenConfig ? { ScreenId: this.homeScreenId, ConfigId: null } : this.currentScreenConfig;
    this.dietaryWarnings = [];
    this.searchText = null;
    this.orderDetails = null;
    this.modalService.hideAllModal();
    this.applyDietaryWarnings();
    this.kioskNavigationBroadcastingService.changeKioskTerminalState();
  }

  setButtonProperties() {
    this.screensWithButtons.forEach(x => {
      x.KioskScreenChoices.forEach(btn => { if (btn.Button) btn.Button.IsSelected = false; })
    });
  }

  private isValidNavigation = (action: IKioskNavigation) => Boolean(action) && !action.isCancelled;

  private orderCommandSideEffect = (action: (navigationAction: IKioskNavigation, index?) => Observable<IKioskNavigation>) =>
    (source: Observable<IKioskNavigation>) =>
      source.pipe(
        this.navigationSideEffect(x => action(x))
      );

  private navigationSideEffect = (action: (navigationAction: IKioskNavigation, index?) => Observable<IKioskNavigation>) =>
    (source: Observable<IKioskNavigation>) =>
      source.pipe(
        mergeMap(action), // flatMap
        filter(this.isValidNavigation)
      );

  buttonClick = (button: KioskChoice, screenId: number = null, configId: number = null) => {
    if (!button.IsFilteredByAllergens
      && (!button.SalesProduct || button.SalesProduct?.IsInStock || button.SalesProduct?.IsAllowOutOfStockOrder)
      && (!button.SalesProduct || button.SalesProduct?.IsActive)) {
      if (screenId && screenId != this.currentScreenConfig?.ScreenId) {
        this.currentScreenConfig = { ConfigId: configId, ScreenId: screenId };
        this.changeOrderNavigation(this.screenNavigation[screenId]);
      }
      if (button.ButtonType != DomainConstants.KioskChoiceType.Placeholder) {
        this.ensureOrderPropertiesAreSet(cloneDeep({ button: button, isCancelled: false, orderCommand: {} }))
          .subscribe({
            next: () => {
              this.navigationSubject.next({ button: button, isCancelled: false, orderCommand: { configId: configId } });
            }
          });
      }
    }
  }

  private ensureOrderPropertiesAreSet = (orderNavigation: IKioskNavigation): Observable<IKioskNavigation> => {
    if (this.settingParam.TerminalType == DomainConstants.TerminalTypes.ORDER_KIOSK.Name) {
      return this.checkIsMappedMakeTableTerminal(orderNavigation)
        .pipe(
          this.orderCommandSideEffect(x => this.checkIsCreditCardTerminalMapped(x)),
          this.orderCommandSideEffect(x => this.checkIsReceiptPrinterMapped(x))
        );
    }
    return of(orderNavigation);
  }

  private checkIsMappedMakeTableTerminal(x: IKioskNavigation): Observable<IKioskNavigation> {
    if (!this.mappedTerminals?.length && this.settingParam.IsUseMakeTable) {
      this.showInfoMessage(StringUtils.format(Messages.NoMakeTableMappedWithOrderEntry,
        { 'terminalName': this.settingParam.TerminalName }));
      x.isCancelled = true;
      return of(x);
    }
    return of(x);
  }

  private checkIsCreditCardTerminalMapped = (x: IKioskNavigation): Observable<IKioskNavigation> => {
    if (!this.settingParam.CreditCardTerminal?.Id) {
      this.showInfoMessage(Messages.CreditCardTerminalNotMapped);
      x.isCancelled = true;
      return of(x);
    }
    return of(x);
  }

  private checkIsReceiptPrinterMapped = (x: IKioskNavigation): Observable<IKioskNavigation> => {
    if (!this.settingParam.ReceiptPrinter?.Id) {
      this.showInfoMessage(Messages.ReceiptPrinterNotMapped);
      x.isCancelled = true;
      return of(x);
    }
    return of(x);
  }

  private subscribeToNavigation() {
    this.currentNavigationSubscription = this.navigation$
      .pipe(
        this.navigationSideEffect(x => this.handleToggle(x)),
        this.navigationSideEffect(x => this.checkIsInStock(x)),
        this.navigationSideEffect(x => this.buttonBehavior(x)),
      )
      .subscribe({
        next: (response: IKioskNavigation) => {
          if (response) {
            let navButton = this.orderNavigation?.KioskScreenChoices.find(x => x.ButtonId == response.button?.Id);
            if (navButton) {
              navButton.Button = response.button;
            }
            const nextScreenId = response.button.NextScreenId ?? (response.button.ButtonType == DomainConstants.KioskChoiceType.Standard && this.orderNavigation.DefaultNextScreenId ? this.orderNavigation.DefaultNextScreenId : null);
            if (nextScreenId) {
              this.setNextScreen(nextScreenId, response);
            } else if (this.checkIsNavigationEnd(response)) {
              this.addItemToOrder();
            }
            this.filterProductsByTags(this.orderNavigation);
            this.kioskNavigationBroadcastingService.changeNavigation(this.orderNavigation);
          }
        }
      });
  }

  private handleToggle = (x: IKioskNavigation): Observable<IKioskNavigation> => {
    if (x.button.ButtonType == DomainConstants.KioskChoiceType.Toggle) {
      x.button.IsSelected = !x.button.IsSelected;
    }
    return of(x);
  }

  private checkIsInStock = (x: IKioskNavigation): Observable<IKioskNavigation> => {
    if (x.button.SalesProductId) {
      if (!x.button.SalesProduct?.IsInStock && !x.button.SalesProduct?.IsAllowOutOfStockOrder) {
        x.isCancelled = true;
      } else if (!x.button.SalesProduct?.IsActive) {
        x.isCancelled = true;
      }
    }
    return of(x);
  }

  private buttonBehavior = (x: IKioskNavigation): Observable<IKioskNavigation> => {
    return from(this.executeButtonBehaviors(x)
      .then((res) => {
        this.behaviorPromiseProviderService.resetPromise();
        return x;
      }, () => {
        x.isCancelled = true;
        return x;
      }
      ));
  }

  private executeButtonBehaviors = (x: IKioskNavigation): Promise<any> => {
    const buttonBehaviors = orderBy(x.button.ChoiceBehaviors, 'Ordinal');
    return buttonBehaviors.reduce(
      (promiseChain, behavior) => {
        return promiseChain
          .then(() => {
            console.log(`Executing behavior ${behavior.Ordinal}`);
            return this.buttonBehaviorExecutor.executeButtonBehavior(behavior, x, this.orderNavigation);
          })
          .catch(error => {
            console.error(`Behavior ${behavior.Name} set in ${x.button.ButtonText} button failed with error: ${error}`);
            return Promise.reject(error); // Propagate the rejection
          });
      },
      Promise.resolve()
    );
  };


  private setNextScreen(nextScreenId: number, response: IKioskNavigation) {
    this.changeOrderNavigation(this.screenNavigation[nextScreenId]);
    this.versionedVal.Obj.ScreenButtons = { Buttons: this.orderNavigation.KioskScreenChoices, Screen: this.orderNavigation };
    this.versionedVal.commit(`v${this.versionedVal.LatestVersion}_${this.currentScreenConfig.ScreenId}_${response.button.Id}`);
  }

  updateProductsStockAndPrice() {
    const sizeId = this.versionedVal?.Obj?.Order?.Product?.SizeId;
    forEach(this.orderNavigation.KioskScreenChoices, (button: KioskScreenChoice) => {
      if (button?.Button?.SalesProductId) {
        let salesSize = button.Button.SalesProduct.SalesProductSizes?.find(x => x.SizeId == sizeId);
        if (!salesSize) {
          salesSize = button.Button.SalesProduct.SalesProductSizes?.find(x => x.SizeId == button.Button.SalesProduct?.SalesCategories?.DefaultSizeId);
        }
        const isInStock = (salesSize?.IsInStock || button.Button.SalesProduct.IsAllowOutOfStockOrder);
        button.Button.SalesProduct.IsInStock = isInStock;
        button.Button.Price = salesSize.Price;
        if (!isInStock && button?.Button?.IsSelected) {
          this.removeInactiveOrOutOfStockComponentFromVersion(button.Button);
        }
      }
    });
  }

  private removeInactiveOrOutOfStockComponentFromVersion(button: KioskChoice) {
    button.IsSelected = false;
    this.versionedVal.Obj.Order.Product.Components = this.versionedVal.Obj.Order.Product.Components.filter(x => x.ButtonId != button.Id);
  }

  changeButtonSelectionState(buttonIds: Array<number>, status: boolean) {
    this.orderNavigation.KioskScreenChoices.forEach(btn => {
      if (btn?.Button && buttonIds.includes(btn.ButtonId)
        && btn.Button?.ButtonType == DomainConstants.KioskChoiceType.Toggle
        && btn.ConfigId == this.currentScreenConfig.ConfigId
        && status != btn.Button?.IsSelected) {
        this.buttonClick(btn.Button);
      }
    });
  }

  changeOrderNavigation = (screen: KioskScreen) => {
    this.orderNavigation = cloneDeep(screen);
    this.updateProductsStockAndPrice();
  }

  navigateBackward() {
    const currentSizeId = this.versionedVal.Obj.Order.Product.SizeId;
    this.versionedVal.revert(1);
    let lastCommit = this.versionedVal.getLastCommittedVersion();
    if (lastCommit.ScreenButtons.Screen.Id) {
      const lastCommittedScreen = this.screensWithButtons.find(x => x.Id == lastCommit.ScreenButtons.Screen.Id);
      this.changeOrderNavigation(lastCommittedScreen)
      this.filterProductsByTags(this.orderNavigation);
      this.kioskNavigationBroadcastingService.changeNavigation(this.orderNavigation);
    } else {
      this.navigateToHomeScreen();
    }

    if (!this.versionedVal.Obj.Order) {
      this.versionedVal.Obj.Order = this.kioskOrderService.newKioskOrder();
    } else {
      this.setScreenButtonsToVersion(lastCommit.Order);
    }

    if (currentSizeId != this.versionedVal.Obj.Order.Product.SizeId) {
      // this.setIsInStockInButtons();
    }
  }

  setScreenButtonsToVersion(order: KioskOrder) {
    forEach(this.orderNavigation.KioskScreenChoices, (screenChoice) => {
      if (screenChoice.Button?.IsSelected) {
        remove(order.Product.Components, (component) => {
          return component.ButtonId == screenChoice.Button.Id && screenChoice.Button.ButtonType != DomainConstants.KioskChoiceType.Toggle;
        });
        if (order.Product.SalesProductId == screenChoice.Button?.SalesProductId) {
          this.setMainProduct(screenChoice, order);
        }
      }
    });
  }

  private setMainProduct(screenChoice: KioskScreenChoice, order: KioskOrder) {
    if (screenChoice.Button?.ButtonType == DomainConstants.KioskChoiceType.Toggle) {
      const selectedMainProduct = find(this.orderNavigation.KioskScreenChoices, (navigateButton) => {
        return navigateButton.Button?.IsSelected && navigateButton.Button?.ChoiceBehaviors?.some(x => x.Name == DomainConstants.KioskButtonBehaviors.AddMainProduct.Value);
      });
      if (selectedMainProduct) {
        order.Product.SalesProductId = selectedMainProduct.Button.SalesProductId;
        this.versionedVal.Obj.Order.Product.Qty = selectedMainProduct.Button.SalesProduct?.DefaultQty;
      }
    } else {
      order.Product.SalesProductId = null;
      if (screenChoice.Button) screenChoice.Button.IsSelected = false;
    }
  }

  navigateToHomeScreen() {
    if (this.homeScreenId) {
      this.changeOrderNavigation(this.screenNavigation[this.homeScreenId] ? this.screenNavigation[this.homeScreenId] : this.kioskScreenService.newScreen());
    } else {
      this.changeOrderNavigation(this.kioskScreenService.newScreen());
    }
    this.kioskNavigationBroadcastingService.changeNavigation(this.orderNavigation);
  }

  private checkIsNavigationEnd(kioskNavigation: IKioskNavigation) {
    return kioskNavigation.button.ButtonType != DomainConstants.KioskChoiceType.Toggle && !kioskNavigation.button.NextScreenId;
  }

  completeNavigation() {
    this.navigationSubject.complete();
    this.currentNavigationSubscription.unsubscribe();
    this.navigationSubject = new Subject<IKioskNavigation>();
    this.subscribeToNavigation();
    this.versionedVal = new VersionedObject<KioskOrderVersionVal>(this.kioskOrderService.newVersionValObject());
    if (this.orderDetails) {
      this.navigateToCheckout();
    }
  }

  setIsSearched() {
    forEach(this.screensWithButtons, (screen: KioskScreen) => {
      this.setIsSearchedInButtons(screen);
    });
    this.refreshOrderNavigation();
  }

  refreshOrderNavigation() {
    const screenId = (this.versionedVal.Obj.ScreenButtons.Screen.Id ? this.versionedVal.Obj.ScreenButtons.Screen.Id : this.homeScreenId);
    const currentScreen = this.screensWithButtons.find(x => x.Id == screenId)
    this.changeOrderNavigation(currentScreen);
    this.screenNavigation[screenId] = this.orderNavigation;
    this.kioskNavigationBroadcastingService.changeNavigation(this.orderNavigation);
  }

  private setIsSearchedInButtons(screen: KioskScreen) {
    forEach(screen.KioskScreenChoices, (screenChoice: KioskScreenChoice) => {
      if (screenChoice.Button) {
        const isSearched = this.searchText ? screenChoice.Button.ButtonText?.toLowerCase().includes(this.searchText.toLowerCase()) : false;
        let childScreen: KioskScreen = null;
        if (!isSearched && screenChoice.NextScreenId) {
          childScreen = this.screensWithButtons.find(x => x.Id == screenChoice.NextScreenId);
          if (childScreen) this.setIsSearchedInButtons(childScreen);
        }
        screenChoice.Button.IsSearched = isSearched || childScreen?.KioskScreenChoices.some(x => x.Button?.IsSearched);
      }
    });
  }

  openOverlayModal(component: any, callBackFunction: Function, initialState = {}) {
    const modalRef = this.modalService.show(component, {
      animated: false,
      backdrop: 'static',
      keyboard: false,
      class: 'vertical-center modal-max-width-100 height-100vh border-radius',
      initialState: initialState,
    })

    modalRef.close.subscribe(callBackFunction);

    return modalRef;
  }

  subscribeToSalesProductReplenishedEventExchange() {
    this.rabbitMqSalesProductReplenishedEventSubscription = this.rabbitMQService.subscribeToSalesProductReplenishedEventExchange$()
      .subscribe((message: any) => {
        if (message.Payload.POSEvent) {
          this.setSalesProductStockStatus(message.Payload.POSEvent.SalesProductId, message.Payload.POSEvent.SizeIds, true);
          this.kioskNavigationBroadcastingService.changeNavigation(this.orderNavigation);
        }
      });
  }

  subscribeToSalesProductOutOfStockEventExchange() {
    this.rabbitMqSalesProductOutOfStockEventSubscription = this.rabbitMQService.subscribeToSalesProductOutOfStockEventExchange$()
      .subscribe((message: any) => {
        if (message.Payload.POSEvent) {
          this.setSalesProductStockStatus(message.Payload.POSEvent.SalesProductId, message.Payload.POSEvent.SizeIds, false);
          this.kioskNavigationBroadcastingService.changeNavigation(this.orderNavigation);
        }
      });
  }

  setSalesProductStockStatus = (productId: number, sizeIds: Array<number>, status: boolean) => {
    // Update IsInStock flag in main (all buttons list) object
    forEach(this.screensWithButtons, (screen: KioskScreen) => {
      forEach(screen.KioskScreenChoices, (choice: KioskScreenChoice) => {
        this.setInStockForDefaultSize(choice.Button, productId, sizeIds, status);
      })
    });

    // Update IsInStock flag in visible screen's button list object
    if (this.orderNavigation) {
      forEach(this.orderNavigation.KioskScreenChoices, (choice) => {
        this.setInStockForDefaultSize(choice.Button, productId, sizeIds, status);
      });
    }
  }

  setInStockForDefaultSize(button: KioskChoice, productId: number, sizeIds: Array<number>, status: boolean) {
    if (button != null && button.SalesProductId === productId && sizeIds?.some((size) => size === this.versionedVal?.Obj?.Order?.Product?.SizeId ?
      this.versionedVal.Obj.Order.Product.SizeId : button.SalesProduct?.SalesCategories?.DefaultSizeId)) {
      const isInStock = status || button.SalesProduct.IsAllowOutOfStockOrder;
      button.SalesProduct.IsInStock = isInStock;
      if (!isInStock && button.IsSelected) {
        this.removeInactiveOrOutOfStockComponentFromVersion(button);
      }
      forEach(button.SalesProduct.SalesProductSizes, (size) => {
        if (sizeIds.some((s) => s === size.SizeId)) {
          size.IsInStock = isInStock;
        }
      });
    }
  }

  subscribeToSalesProductUpdatedEventExchange() {
    this.rabbitMqSalesProductUpdatedEventSubscription = this.rabbitMQService.subscribeToSalesProductUpdatedMessage$()
      .subscribe((message: any) => {
        if (message.Payload.SalesProductId) {
          this.setSalesProductActiveStatus(message.Payload.SalesProductId, message.Payload.IsActive);
          this.kioskNavigationBroadcastingService.changeNavigation(this.orderNavigation);
        }
      });
  }

  setSalesProductActiveStatus(salesProductId: number, isActive: boolean) {
    forEach(this.screensWithButtons, (screen: KioskScreen) => {
      forEach(screen.KioskScreenChoices, (choice: KioskScreenChoice) => {
        if (choice?.Button?.SalesProductId == salesProductId) {
          choice.Button.SalesProduct.IsActive = isActive;
        }
      });
    });

    if (this.orderNavigation) {
      forEach(this.orderNavigation.KioskScreenChoices, (choice) => {
        if (choice?.Button?.SalesProductId == salesProductId) {
          choice.Button.SalesProduct.IsActive = isActive;
          if (!isActive && choice?.Button?.IsSelected) {
            this.removeInactiveOrOutOfStockComponentFromVersion(choice.Button);
          }
        }
      });
    }
  }

  showInfoMessage(message: string) {
    const modalRef = this.modalService.show(InfoModalComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        message: message
      }
    });
  }

  onContinuePress = () => {
    if (this.orderNavigation.DefaultNextScreenId && this.applicationStateService.terminalType == DomainConstants.TerminalTypes.ORDER_KIOSK.Name) {
      this.changeOrderNavigation(this.screenNavigation[this.orderNavigation.DefaultNextScreenId]);
      this.versionedVal.Obj.ScreenButtons = { Buttons: this.orderNavigation.KioskScreenChoices, Screen: this.orderNavigation };
      this.versionedVal.commit(`v${this.versionedVal.LatestVersion}_${this.currentScreenConfig.ScreenId}_${this.orderNavigation.Id}`);
      this.filterProductsByTags(this.orderNavigation);
      this.kioskNavigationBroadcastingService.changeNavigation(this.orderNavigation);
    } else {
      this.addItemToOrder();
    }
  }

  addItemToOrder() {
    if ((this.versionedVal?.Obj?.Order?.Product?.Components?.length > 0 || this.versionedVal?.Obj?.Order?.Product?.KioskAiShake) && this.applicationStateService.terminalType == DomainConstants.TerminalTypes.ORDER_KIOSK.Name) {
      if (!this.versionedVal.Obj.Order.Product.SalesProductId) {
        this.versionedVal.Obj.Order.Product.SalesProductId = -3;
      }
      if (this.versionedVal.Obj.Order.Id > 0) {
        this.addProductToOrder();
      } else {
        this.createOrder();
      }
    }
  }

  createOrder() {
    this.kioskOrderService.createOrder(this.versionedVal.Obj.Order)
      .subscribe({
        next: (res: OrderInvoice) => {
          this.versionedVal.Obj.Order.Id = res.Id.Value;
          this.orderDetails = res;
          this.completeNavigation();
        }, error: (e) => {
          this.showErrorMessage(e);
        }
      });
  }

  private showErrorMessage(e: any) {
    let message = "";
    if (e.status == HttpStatusCodes.Conflicted) {
      message = e.data ? e.data : e.error;
      this.showInfoMessage(message);
    } else if (e.status == HttpStatusCodes.BadRequest) {
      message = e.data ? e.data : e.error;
      this.alertService.showApiError(e);
    } else {
      this.alertService.showApiError(e);
    }
  }

  addProductToOrder() {
    this.kioskOrderService.addItemToOrder(this.versionedVal.Obj.Order.Id, this.versionedVal.Obj.Order.Product)
      .subscribe({
        next: (res: OrderInvoice) => {
          this.orderDetails = res;
          this.versionedVal.Obj.Order.Id = res.Id.Value;
          this.completeNavigation();
        }, error: (e) => {
          this.showErrorMessage(e);
        }
      });
  }

  navigateToCheckout() {
    this.router.navigate(['/order-kiosk/terminal/checkout']);
  }

  applyDietaryWarnings = () => {
    forEach(this.screensWithButtons, (screen: KioskScreen) => {
      this.filterProductByDietaryWarning(screen);
    });
    this.refreshOrderNavigation();
  }

  private filterProductByDietaryWarning(screen: KioskScreen) {
    const dietaryWarningIds = this.dietaryWarnings.map(x => x.Id);
    forEach(screen.KioskScreenChoices, (screenChoice: KioskScreenChoice) => {
      if (screenChoice.DietaryWarningIds?.length) {
        const isFiltered = dietaryWarningIds.some(x => screenChoice.DietaryWarningIds.includes(x));
        screenChoice.Button.IsFilteredByAllergens = isFiltered;
      } else if (screenChoice.Button)
        screenChoice.Button.IsFilteredByAllergens = false;
    });
  }

  private filterProductsByTags(screen: KioskScreen) {
    if (screen.IsFilteredProductsByTags && this.versionedVal?.Obj?.filterProductTags?.length) {
      const filteredChoices = lodashFilter(screen.KioskScreenChoices, (ksc: KioskScreenChoice) => {
        const mappedTags = ksc?.Button?.SalesProduct?.SalesProductTags?.map((x: SalesProductTag) => x.TagId);
        if (mappedTags) {
          const commonTags = intersection(mappedTags, this.versionedVal?.Obj?.filterProductTags);
          return commonTags?.length == this.versionedVal?.Obj?.filterProductTags?.length;
        }
      });
      screen.KioskScreenChoices = filteredChoices;
    }
  }

  createAIShake(groupId: number, screenId: number = null) {
    if (this.applicationStateService.terminalType == DomainConstants.TerminalTypes.ORDER_KIOSK.Name) {
      const shakeCreatorRequest: ShakeCreatorRequest = {
        GroupId: groupId,
        DietaryRestrictionIds: this.dietaryWarnings.map(x => x.Id)
      }
      const modalRef = this.openOverlayModal(KioskOverlayComponent, () => { },
        { intervalSeconds: this.userIdleTimeout, isShowButtons: false, backgroundImage: `order-kiosk/default-screens/${this.settingParam.OrderKioskAIShakeProcessingImage}` }
      );
      this.shakeCreatorService.createShake(shakeCreatorRequest)
        .pipe(finalize(() => {
          if (this.settingParam.OrderKioskAIShakeProcessingImageSeconds > 0) {
            const timeout = setTimeout(() => {
              modalRef.close.emit();
              clearTimeout(timeout);
            }, this.settingParam.OrderKioskAIShakeProcessingImageSeconds * 1000);
          } else modalRef.close.emit();
        }))
        .subscribe({
          next: (response: CreatedShake) => {
            this.kioskStorageService.createdShake = response;
            this.kioskNavigationBroadcastingService.newAiShakeCreated.emit();
            if (screenId) {
              const nextScreenId = this.screenNavigation[screenId]?.DefaultNextScreenId;
              this.navigateToScreen(nextScreenId);
            }
          }, error: this.alertService.showApiError
        });
    }
  }

  navigateToScreen(screenId: number) {
    this.changeOrderNavigation(this.screenNavigation[screenId] ? this.screenNavigation[screenId] : this.kioskScreenService.newScreen());
    this.versionedVal.Obj.ScreenButtons = { Buttons: this.orderNavigation.KioskScreenChoices, Screen: this.orderNavigation };
    this.versionedVal.commit(`v${this.versionedVal.LatestVersion}_${this.currentScreenConfig.ScreenId}`);
    this.kioskNavigationBroadcastingService.changeNavigation(this.orderNavigation);
  }

  addAIShakeToOrder(nextScreenId?: number) {
    if (this.applicationStateService.terminalType == DomainConstants.TerminalTypes.ORDER_KIOSK.Name) {
      const createdShake = this.kioskStorageService.createdShake;
      this.versionedVal.Obj.Order.Product.KioskAiShake = {
        Name: createdShake.Name,
        PrimaryFlavor: createdShake.PrimaryFlavor,
        SecondaryFlavors: createdShake.SecondaryFlavors
      }
      if (nextScreenId)
        this.navigateToScreen(nextScreenId);
      else
        this.addItemToOrder();
    }
  }
}
