<div class="col-12 pt-pix-5">
  <form #binLightForm="ngForm" (ngSubmit)="saveInventoryBinLight()">
    <pos-row>
      <pos-column>
        <pos-form-field label="Address" [validationMessages]="{required: 'Please enter light address.'}">
          <input posInput class="form-control" #InventoryZoneAddress="ngModel" name="InventoryZoneAddress"
            [(ngModel)]="currentBinLight.LightAddress" type="text" [required]="true" />
        </pos-form-field>
      </pos-column>
      <pos-column>
        <pos-form-field label="Controller" [validationMessages]="{required: 'Please select light device.'}"
          inputStyle="col-12">
          <pos-row>
            <pos-column sm="6" xs="12" class="mb-xs-10">
              <ng-select posInput [items]="lightingDevicesList" [hideSelected]="true" appendTo="body"
                bindLabel="DeviceName" bindValue="DeviceId" placeholder="Select controller"
                [(ngModel)]="currentBinLight.DeviceId" name="drpLightController" #drpLightController="ngModel"
                [required]="true">
              </ng-select>
            </pos-column>
            <pos-column sm="6" xs="12">
              <button class="btn btn-primary white normal me-pix-5 pos-button" type="submit">
                {{currentBinLight.Id ? "Update" : "Add" }}
              </button>
              <button class="btn btn-secondary  normal pos-button" (click)="resetBinLight()" type="button">
                Reset
              </button>
            </pos-column>
          </pos-row>
        </pos-form-field>
      </pos-column>
    </pos-row>
  </form>
</div>
<div class="col-12 pt-pix-12">
  <ng-table [columns]="inventoryZoneBinsLightColumns" [data]="inventoryBinLightsList" [paginator]="false"
    [search]="false" scrollHeight="160px"></ng-table>
</div>
