<div *ngIf="configs.length">
    <pos-row *ngFor="let config of configs; let index = index;">
        <pos-column *ngIf="config.Type == promotionConfigPropertyType.Text">
            <pos-form-field [label]="config.DisplayName"
                [validationMessages]="{required: 'Please enter ' + (config.DisplayName | lowercase) + '.'}">
                <input posInput type="text" class="form-control" [(ngModel)]="config.Value"
                    name="{{inputName+config.Name}}" #configRef="ngModel" (ngModelChange)="prepareDescription();"
                    [required]="config.IsRequired || config.IsInvalid">
            </pos-form-field>
        </pos-column>

        <pos-column *ngIf="config.Type == promotionConfigPropertyType.Numeric">
            <pos-form-field [label]="config.DisplayName"
                [validationMessages]="{required: 'Please enter ' + (config.DisplayName | lowercase) + '.'}">
                <input posInput type="number" class="form-control col-120" [(ngModel)]="config.Value"
                    name="{{inputName+config.Name}}" #configRef="ngModel" posDecimal *ngIf="config.IsAllowDecimal"
                    [required]="config.IsRequired || config.IsInvalid" min="0" (ngModelChange)="prepareDescription();">

                <input posInput type="number" class="form-control col-120" [(ngModel)]="config.Value"
                    name="{{inputName+config.Name}}" #configRef="ngModel" posOnlyNumbers *ngIf="!config.IsAllowDecimal"
                    [required]="config.IsRequired || config.IsInvalid" min="0" (ngModelChange)="prepareDescription();">
            </pos-form-field>
        </pos-column>

        <pos-column *ngIf="config.Type == promotionConfigPropertyType.SalesGroup">
            <pos-form-field [label]="config.DisplayName"
                [validationMessages]="{required: 'Please select ' + (config.DisplayName | lowercase) + '.'}">
                <ng-select posInput [appendTo]="parentElement" [items]="promotionData.salesGroups" bindLabel="name"
                    bindValue="Id" [multiple]="config.HasMultipleValues"
                    placeholder="Any group" [(ngModel)]="config.Value"
                    name="{{inputName+config.Name}}" #configRef="ngModel"
                    [required]="config.IsRequired || config.IsInvalid" (change)="clearDependent(config); prepareDescription();"
                    (clear)="clearDependent(config)">
                </ng-select>
            </pos-form-field>
        </pos-column>

        <pos-column *ngIf="config.Type == promotionConfigPropertyType.SalesCategory">
            <pos-form-field [label]="config.DisplayName"
                [validationMessages]="{required: 'Please select ' + (config.DisplayName | lowercase) + '.'}">
                <ng-select posInput [appendTo]="parentElement" [items]="salesCategories" bindLabel="name"
                    [multiple]="config.HasMultipleValues" bindValue="id"
                    placeholder="Any category" [(ngModel)]="config.Value"
                    name="{{inputName+config.Name}}" #configRef="ngModel"
                    [required]="config.IsRequired || config.IsInvalid" (change)="clearDependent(config); prepareDescription();"
                    (clear)="clearDependent(config)">
                </ng-select>
            </pos-form-field>
        </pos-column>

        <pos-column *ngIf="config.Type == promotionConfigPropertyType.SalesProduct">
            <pos-form-field [label]="config.DisplayName"
                [validationMessages]="{required: 'Please select ' + (config.DisplayName | lowercase) + '.'}">
                <ng-select posInput [appendTo]="parentElement" [items]="salesProducts" bindLabel="Name" bindValue="id"
                    [multiple]="config.HasMultipleValues" placeholder="Any product"
                    [(ngModel)]="config.Value" name="{{inputName+config.Name}}" #configRef="ngModel"
                    [required]="config.IsRequired || config.IsInvalid" (change)="prepareDescription();">
                </ng-select>
            </pos-form-field>
        </pos-column>

        <pos-column *ngIf="config.Type == promotionConfigPropertyType.PromotionPeriods">
            <pos-form-field [label]="config.DisplayName"
                [validationMessages]="{required: 'Please select ' + (config.DisplayName | lowercase) + '.'}">
                <ng-select posInput [appendTo]="parentElement" [items]="promotionData.promotionPeriods" bindLabel="Name"
                    [multiple]="config.HasMultipleValues" bindValue="Value" placeholder="Select {{config.DisplayName}}"
                    [(ngModel)]="config.Value" name="{{inputName+config.Name}}" #configRef="ngModel" [clearable]="false"
                    [required]="config.IsRequired || config.IsInvalid" class="col-250" (change)="prepareDescription();">
                </ng-select>
            </pos-form-field>
        </pos-column>

        <pos-column *ngIf="config.Type == promotionConfigPropertyType.MembershipTier">
            <pos-form-field [label]="config.DisplayName" inputStyle="col-12 row mx-0"
                [validationMessages]="{required: 'Please select ' + (config.DisplayName | lowercase) + '.'}">
                <ng-select posInput [appendTo]="parentElement" [items]="promotionData.accountAttributeTypes"
                    bindLabel="Name" [multiple]="config.HasMultipleValues" bindValue="Name"
                    placeholder="Select {{config.DisplayName}}" [(ngModel)]="config.Value.Name"
                    name="{{inputName+config.Name}}" #configRef="ngModel"
                    [required]="config.IsRequired || config.IsInvalid" class="col-250 col-3 p-0"
                    (change)="config.Value.Value = null; onChangeMemberShip(config); prepareDescription();">
                </ng-select>
                <span [ngClass]="{'has-error': config.Value?.Name && !config.Value?.Value && form.submitted}"
                    *ngIf="config.Value.Name && accountAttribute" class="col-3 col-300 pe-0">
                    <pos-account-attribute [attributeType]="accountAttribute"
                        (changeAttribute)="onChangeAccountAttribute($event, config); prepareDescription();"></pos-account-attribute>
                    <span *ngIf="config.Value?.Name && !config.Value?.Value && form.submitted">
                        <label class="control-label">Please enter {{ config.Value.Name | lowercase }}.</label>
                    </span>
                </span>
            </pos-form-field>
        </pos-column>

        <pos-column *ngIf="config.Type == promotionConfigPropertyType.Time">
            <pos-form-field [label]="config.DisplayName"
                [validationMessages]="{required: 'Please select ' + (config.DisplayName | lowercase) + '.'}">
                <input posInput type="time" class="form-control col-150" [(ngModel)]="config.Value"
                    name="{{inputName+config.Name}}" #configRef="ngModel" (change)="prepareDescription();"
                    [required]="config.IsRequired || config.IsInvalid">
            </pos-form-field>
        </pos-column>

        <pos-column *ngIf="config.Type == promotionConfigPropertyType.WeekDays">
            <pos-form-field [label]="config.DisplayName" inputStyle="col-xl-9 col-12">
                <pos-row colLg="1" colSm="2" colXs="3" class="ps-0">
                    <pos-column *ngFor="let day of daysOfWeek">
                        <p-checkbox name="chk_{{inputName+'_'+day.Name}}" #chk_{{day.Name}}="ngModel" [(ngModel)]="day.IsChecked"
                            binary="true" [label]="day.Name" (ngModelChange)="onWeekDaysChange(config);prepareDescription();">
                        </p-checkbox>
                    </pos-column>
                </pos-row>
            </pos-form-field>
        </pos-column>
    </pos-row>
</div>
<div class="col-12 ps-pix-5 pt-pix-5" *ngIf="description" [innerHTML]="description"></div>
