import { Directive, ElementRef, InjectionToken, Input, Optional, Self } from '@angular/core';
import { NgControl, NgForm, ValidationErrors, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

export const POS_INPUT_VALUE_ACCESSOR = new InjectionToken<{value: any}>(
  'POS_INPUT_VALUE_ACCESSOR',
);

@Directive({
  selector: '[posInput]',
})
export class InputControlDirective {

  protected _required: boolean | undefined;
  @Input()
  get required(): boolean {
    /* hasValidator property seems to work only for reactive forms. will come back to this later.
       for now have to pass [required]="true"
    */
    return this._required || this.ngControl?.control?.hasValidator(Validators.required);
  }
  set required(value: boolean) {
    this._required = value;
  }

  protected _disabled = false;
  @Input()
  get disabled(): boolean {
    return this._disabled ?? this.ngControl?.control?.disabled ?? false;
  }
  set disabled(value: boolean) {
    this._disabled = value
  }

  get errorState(): boolean {
    return this.ngControl?.control?.invalid && this.parentForm?.submitted;
  }

  get errors(): ValidationErrors {
    return this.ngControl?.errors;
  }

  readonly stateChanges: Subject<void> = new Subject<void>();
  ngControl: NgControl | null;
  readonly parentForm: NgForm | null;

  constructor(
    protected _elementRef: ElementRef<any>,
    @Optional() @Self() _ngControl: NgControl,
    @Optional() _parentForm: NgForm,
  ) {
    this.ngControl = _ngControl;
    this.parentForm = _parentForm;
  }
}
