import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { Messages, SpinnerService, AlertsService, ModalComponent, ApplicationStateService, SettingParam, ConfirmDeleteModalComponent, ConfirmDeleteComponent, OrderService, ModalService, InfoModalComponent } from 'src/app/shared';
import { finalize } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PagerDetails } from 'src/app/orders/interface/pager-details';
import { OrderCommandType } from 'src/app/orders/interface/order-command-type';
declare let $: any;
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
@Component({
  selector: 'pos-assign-pager',
  templateUrl: './assign-pager.component.html',
  styleUrls: ['./assign-pager.component.scss']
})
export class AssignPagerComponent extends ModalComponent implements OnInit {

  pagerRange: Array<PagerDetails> = [];
  pagerDetail: PagerDetails;
  orderId: number;
  isRemovePager: boolean;
  @ViewChild('confirmRemovePager') private confirmRemovePager: ConfirmDeleteComponent;
  settingParam: SettingParam;
  pagerHeight: number = 0;
  constructor(modalRef: BsModalRef,
    private orderService: OrderService,
    private spinnerService: SpinnerService,
    private applicationStateService: ApplicationStateService,
    private alertService: AlertsService,
    private modalService: ModalService) {
    super(modalRef);
    this.settingParam = this.applicationStateService.settingParam;
  }

  ngOnInit() {
    this.pagerHeight = $(window).height() < 800 ?  ($(window).height() - 220) : ($(window).height() - 350);
    this.configurePager();
    this.getUnservedOrders();
  }

  configurePager() {
    this.pagerRange = [];
    let range = this.settingParam.PagerRange;
    let pagerStartNumber = 1, pagerEndNumber = 20;
    if (range) {
      pagerStartNumber = parseInt(range.substring(0, range.indexOf('-')));
      pagerEndNumber = parseInt(range.substring(range.indexOf('-') + 1));
    }
    range = _.range(pagerStartNumber, pagerEndNumber + 1);
    _.forEach(range, (number) => {
      let pagerObj = { PagerNumber: number, OrderId: null, SurrogateOrderId: null };
      this.pagerRange.push(pagerObj);
    });

  }

  getUnservedOrders() {

    this.spinnerService.show();
    this.orderService.getUnservedOrdersData(0, 0)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: any) => {
          if (response) {
            _.forEach(response, (order) => {
              if (order.OrderAttributes.PagerNumber) {
                _.forEach(this.pagerRange, (pager) => {
                  if (pager.PagerNumber == order.OrderAttributes.PagerNumber) {
                    pager.OrderId = order.id;
                    pager.SurrogateOrderId = order.SurrogateOrderId;
                  }
                });
              }
            });
          }
        }, error: this.alertService.showApiError
      });
  }

  mapPager(pager) {
    this.pagerDetail = pager;
    var index = _.findIndex(this.pagerRange, (pagerData) => {
      return this.orderId == pagerData.OrderId;
    });

    if (index >= 0 && this.orderId && !pager.OrderId) {
      const message = StringUtils.format(Messages.ConfirmPagerNumberUpdate,
        { "pagerNumber": this.pagerRange[index].PagerNumber, 'surrogateOrderId': this.pagerRange[index].SurrogateOrderId, 'newPagerNumber': pager.PagerNumber });
      this.isRemovePager = false;

      const modalRef = this.modalService.show(InfoModalComponent, {
        animated: false,
        class: 'vertical-center',
        initialState: {
          message: message,
          confirmButtonText: 'Yes',
          rejectButtonText: 'No',
          modalHeaderText: 'Confirm'
        }
      });
      modalRef.close.subscribe((res) => {
        if (res && (res.shouldConfirm)) {
          this.onConfirmAcknowledge();
        }
        // else {
        //   this.resolvePromptPromise(commentWarningMatch.CommentTextMatch);
        // }
      });

      // this.confirmRemovePager.Show(message);

    } else {
      const pagerNumber = pager.OrderId ? null : pager.PagerNumber;

      if (!pagerNumber) {
        this.isRemovePager = true;
        // this.confirmRemovePager.Show();

        const modalRef = this.modalService.show(InfoModalComponent, {
          animated: false,
          class: 'vertical-center',
          initialState: {
            message: StringUtils.format(Messages.ConfirmPagerNumberDeassign,
              { 'pagerNumber': pager.PagerNumber, 'surrogateOrderId': pager.SurrogateOrderId }),
            confirmButtonText: 'Yes',
            rejectButtonText: 'No',
            modalHeaderText: 'Confirm'
          }
        });
        modalRef.close.subscribe((res) => {
          if (res && (res.shouldConfirm)) {
            this.onConfirmAcknowledge();
          }
          // else {
          //   this.resolvePromptPromise(commentWarningMatch.CommentTextMatch);
          // }
        });


      } else {
        this.hide({ Pager: pager, ConfirmationMessage: null, Command: OrderCommandType.PAGER_ASSIGN });
      }
    }
  }

  onConfirmAcknowledge() {
    if (this.isRemovePager) {
      this.pagerDetail.PagerNumber = null;
    }
    this.hide({ Pager: this.pagerDetail, ConfirmationMessage: null });
  }

  closePagerSelection() {
    this.hide({});
  }
}
