import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AlertsService, FormUtilityService, ICloseable, Messages, SpinnerService } from 'src/app/shared';
import { MenuBackupService } from '../../services/menu-backup.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'pos-menu-display-backup-edit',
  templateUrl: './menu-display-backup-edit.component.html',
  styleUrls: ['./menu-display-backup-edit.component.scss']
})
export class MenuDisplayBackupEditComponent  implements OnInit {

  get getForm(): NgForm {
    return this.formMenuDisplayBackup;
  }
  backupName: string = '';
  @ViewChild('formMenuDisplayBackup') formMenuDisplayBackup: NgForm;
  @Input('menuId') menuId: number;
  @Output() reload: EventEmitter<boolean> = new EventEmitter<true>();
  constructor(private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private backupService: MenuBackupService    ) {
    
  }

  ngOnInit(): void {
  }

  createBackup(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.spinnerService.show();
    this.backupService.createBackup(this.menuId, this.backupName)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: any) => {
          this.alertService.renderSuccessMessage(Messages.BackupCreatedSuccess);
          this.reload.emit(true);
        }, error: this.alertService.showApiError
      });
  }

  onCancel() {
    this.backupName = '';
  }

}
