<div>
  <button data-dismiss="modal" aria-label="Close" class="close" type="button" (click)="onCancel()">
    &times;
  </button>
  <h4 class="modal-content__heading">{{ isPreviewMode ? 'Preview' : 'Rearrange'}} Buttons</h4>
</div>  
<div class="pt-pix-10 pb-pix-10 overflow-hidden">
  <div class="col-12 pos-modal-body overflow-auto display-flex flex-wrap mb-pix-5 pb-pix-10" cdkDropListGroup [ngStyle]="{'max-height': 'calc(100vh - 270px)'}">
    <div id="divRepeatGroup" class="product-group p-0" cdkDropList
      *ngFor="let od of orderNavigation?.Buttons; let buttonIndex = index" [ngClass]="{'col-lg-3 col-md-3 col-sm-4 col-4':orderNavigation.Buttons.length>4 || orderNavigation.Buttons.length<=12, 
                                  'col-lg-6 col-md-2 col-sm-4 col-4':orderNavigation.Buttons.length<5,
                                  'col-lg-2 col-md-2 col-sm-4 col-4':orderNavigation.Buttons.length>12}"
      [ngStyle]="{'width':this.orderNavigation.UiConfiguration.width ,'height' : this.orderNavigation.UiConfiguration.height+ 'px'}"
      [cdkDropListData]="buttonIndex">
      <div class="ps-pix-5 pe-pix-5 pt-pix-4 pb-pix-4" [ngStyle]="{'background-color':od?.BackgroundColor}" cdkDrag
        [cdkDragData]="buttonIndex" (cdkDragEntered)="dragEntered($event)"
        [cdkDragDisabled]="isPreviewMode && previewButtons.length">
        <div class="product-block">
          <div class="text-align-center product-block__detail"
            [ngStyle]="{'border': (!isPreviewMode && od?.EmbeddedScreenId) ? '4px dashed blue' : od?.BorderColor ? ('4px solid '+od?.BorderColor):'default'}">
            <div class="product-block__detail__block" [ngStyle]="{'background-color': od.Color}">
              <div class="product-block__detail__info" [ngClass]="{'stripe-even': isPreviewMode && od.EmbeddedScreenChoiceId && (od.EmbeddedScreenNumber % 2 == 0), 
                'stripe-odd': isPreviewMode && od.EmbeddedScreenChoiceId && (od.EmbeddedScreenNumber % 2 != 0)}"
                [ngStyle]="{'height' : this.orderNavigation.UiConfiguration.height+ 'px'}">
                <div *ngIf="od.Image" class="product-block__detail__info__img-section">
                  <img alt="" class="product-block__detail__info__img-section__img"
                    [ngStyle]="{'height': this.orderNavigation.UiConfiguration.imageSize }"
                    src="{{imageRootPath}}/{{od.Image}}" />
                </div>

                <div class="product-block__detail__info__name"
                  [ngClass]="{'product-block__detail__info__name--no-img':!od.Image}" [ngStyle]="{'color':od.TextColor}"
                  [innerHTML]="od.ButtonText">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="clearfix"></div>
  <div class="col-12 action-button-container popup-footer">
    <button class="btn normal btn-warning btn-lg white me-pix-5 pos-button" *ngIf="!isPreviewMode && previewButtons.length"
      (click)="preview()">
      <div class="display-flex">
        <pos-icon class="white pe-pix-5 pt-pix-1" [icon]="icons.fileSearchPreview"></pos-icon>Preview
      </div>
    </button>
    <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" (click)="saveReArrangeButtons()" value="Save"
      *ngIf="!isPreviewMode">Save</button>
    <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" value="Cancel"
      (click)="onCancel()">Cancel</button>
  </div>
</div>
