import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ScannedProduct } from 'src/app/orders/interface';
import { ModalComponent } from 'src/app/shared/components';

@Component({
  selector: 'pos-barcode-product-selection',
  templateUrl: './barcode-product-selection.component.html',
  styleUrls: ['./barcode-product-selection.component.scss']
})
export class BarcodeProductSelectionComponent extends ModalComponent {

  @Input() barcodeProducts: Array<ScannedProduct> = [];

  constructor(private bsModalRef: BsModalRef) {
    super(bsModalRef);
  }

  selectProduct(product: ScannedProduct) {
    this.hide({product: product});
  }

  closeModal() {
    this.hide({});
  }

}
