<pos-edit-wrapper heading="Sales Size" (close)="onCancel(false)">
    <form #salesSizeForm="ngForm" (ngSubmit)="saveSalesSize(salesSizeForm.valid)" autocomplete="off">
        <context-provider provide="props">
            <div class="col-12 pos-modal-body form-horizontal screen-scroll">
                <pos-row>
                    <pos-column>
                        <pos-form-field label="Name" [validationMessages]="{required: 'Please enter size.'}">
                            <input posInput class="form-control" name="name" #name="ngModel" [(ngModel)]="salesSizeData.Name"
                                [required]="true" />
                        </pos-form-field>
                    </pos-column>
                    <pos-column>
                        <pos-form-field label="Make Table Size"
                            [validationMessages]="{required: 'Please enter make table size.'}">
                            <input posInput class="form-control" type="text" name="makeTableName" #makeTableName="ngModel"
                                [(ngModel)]="salesSizeData.MakeTableSize" [required]="true">
                        </pos-form-field>
                    </pos-column>
                    <pos-column>
                        <pos-form-field label="CLI Codes">
                            <ng-select posInput class="custom-theme" [items]="[]" [addTag]="true" name="tags" [(ngModel)]='tagsList'
                                #tags="ngModel" [multiple]="true" [hideSelected]="true" [isOpen]="false">
                            </ng-select>
                        </pos-form-field>
                    </pos-column>
                </pos-row>
            </div>
            <div class="clearfix"></div>
            <div class="col-12 action-button-container popup-footer">
                <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" scrollup>Save </button>
                <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button"
                    (click)="onCancel(false)">Cancel</button>
            </div>
        </context-provider>
    </form>
</pos-edit-wrapper>
