import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseCrudService } from 'src/app/shared';
import { RevenueCenter } from '../interface/revenue-center';
@Injectable({
    providedIn: 'root'
})
export class RevenueCenterService extends BaseCrudService<RevenueCenter> {

    constructor(protected httpService: HttpClient) {
        super('revenue-centers', httpService);
    }
    newRevenueCenter(): RevenueCenter {
        return {
            Id: 0,
            Name: '',
            IsActive: true,
            ServeMethodIds: [],
            OrderMethodIds: [],
            PaymentTypeIds: []
        };
    }
    changeActiveStatus(revenueCenterId: number, isActive: boolean) {
        return this.httpService.put(`${this.apiEndpoint}/${revenueCenterId}/status/${isActive}`, isActive);
    }
}
