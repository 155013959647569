import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';
import { InventoryOffSiteInventoryProduct } from '../interfaces/inventory-offsite-inventory-product';

@Injectable({
  providedIn: 'root'
})
export class InventoryOffsiteInventoryProductService extends BaseCrudService<InventoryOffSiteInventoryProduct> {

  constructor(protected httpService: HttpClient) {
    super('inventory-offsite-inventory-product', httpService);
  }

  getProductsByLocation(locationId: number) {
    return this.httpService.get(`${this.apiEndpoint}/location/${locationId}`);
  }

  getOffsiteInventoryByInventoryProductId(inventoryProductId: number) {
    return this.httpService.get(`${this.apiEndpoint}/inventory-product/${inventoryProductId}`);
  }
  
}
