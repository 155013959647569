import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalComponent } from 'src/app/shared/components/modal';
import { InventoryOrderReceivingProductList } from 'src/app/shared/interface/inventory-order-receiving-product-list';

@Component({
  selector: 'pos-inventory-duplicate-entry',
  templateUrl: './inventory-duplicate-entry.component.html',
  styleUrls: ['./inventory-duplicate-entry.component.scss']
})
export class InventoryDuplicateEntryComponent extends ModalComponent implements OnInit {


  @Input() productReceived: InventoryOrderReceivingProductList;
  @Input() productList: Array<InventoryOrderReceivingProductList> = [];
  @Input() date: string;
  close: EventEmitter<any> = new EventEmitter();

  constructor(duplicatePromptModalRef: BsModalRef) {
    super(duplicatePromptModalRef)
   }

  ngOnInit(): void {
  }

  onConfirm = () => {
    this.hide({});
  }

  onCancel = () => {
    this.hide({});
  }

}
