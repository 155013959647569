<div class="p-pix-10 banner" [ngStyle]="bannerStyle">
    <div class="image-icon" *ngIf="data?.Image">
        <pos-menu-image [imagePath]="imagePath" [image]="data.Image" [height]="imageSize" [width]="imageSize">
        </pos-menu-image>
    </div>
    <div class="banner-text" *ngIf="data?.Text"
        [ngStyle]="{'line-height': styleData?.TextFontStyle?.fontSize , 'justify-content': styleData?.textAlign}">
        <span [ngStyle]="textStyle" [innerHTML]="text"></span>
        <div *ngIf="data?.Badge" class="image-icon ms-pix-2">
            <pos-menu-image [imagePath]="imagePath" [image]="data.Badge" [height]="bannerSize" [width]="bannerSize">
            </pos-menu-image>
        </div>
    </div>

    <div class=" price-text text-nowrap" *ngIf="data?.PriceInfo" [ngStyle]="priceInfoStyle">
        <span innerHTML="{{data?.PriceInfo}}"></span>
    </div>
</div>
