<pos-edit-wrapper heading="Delivery" (close)="cancel()" [headingIcon]="icons.deliveryTruckMoving" [isPopup]="false">
  <form (ngSubmit)="saveDelivery(deliveryForm.valid)" #deliveryForm="ngForm" class="edit-form">
    <div class="col-12 pos-page-body border-top pt-pix-5">
      <div class="col-12">
        <div>
          <h3 class="setting-title">Settings</h3>
        </div>
        <pos-row class="px-2">
          <pos-column>
            <pos-form-field label="Delivery Fee" [fieldInfo]="fieldInfoMessages.DeliveryFee"
              [validationMessages]="{required: 'Please enter delivery fee.'}">
              <input posInput class="form-control col-120" min="0" name="txtDeliveryFee" #deliveryFee="ngModel"
                [(ngModel)]="parameters.DeliveryFee" posDecimal type="number" [required]="true" />
            </pos-form-field>
          </pos-column>
          <pos-column>
            <pos-form-field inputStyle="">
              <span class="col-xs-3 p-0 col-300 d-flex">
                <p-checkbox posInput name="txtDeliveryFeeCountsInDrawer" #txtDeliveryFeeCountsInDrawer="ngModel"
                  [(ngModel)]="parameters.DeliveryFeeCountsInDrawer" binary="true" label="Count in Drawer">
                </p-checkbox>
                <field-info [info]="fieldInfoMessages.DeliveryCountInDrawer" class="pt-0 pull-right"></field-info>
              </span>
            </pos-form-field>
          </pos-column>
          <pos-column>
            <pos-form-field>
              <p-checkbox posInput name="chkShowDispatchButton" #chkShowDispatchButton="ngModel"
                [(ngModel)]="parameters.ShowDispatchButton" binary="true" label="Show Dispatch Button">
              </p-checkbox>
            </pos-form-field>
          </pos-column>
        </pos-row>
      </div>
    </div>
    <div class="col-12 action-button-container page-footer">
      <button class="btn btn-primary btn-lg btn-save pos-button" scrollup type="submit" value="Save">
        Save
      </button>
      <button class="btn btn-secondary btn-lg btn-cancel pos-button" routerLink="../" scrollup type="button" value="Cancel">
        Cancel
      </button>
    </div>
  </form>
</pos-edit-wrapper>
