import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
  Permissions,
  Levels,
  AuthenticationService,
  ModalService,
  SpinnerService,
  AlertsService,
  ConfirmDeleteComponent,
  Messages,
  PrintTableService,
  BaseGridComponent,
  ApplicationStateService
} from 'src/app/shared';
import { GridColumn, EditColumn, DeleteColumn, LinkColumn } from '@tarktech/tark-ng-utils';
import { InfoLinkService } from '../../service/info-links.service';
import { InfoLinkModel } from '../../interface/info-link-model';
import { InfoLinksEditComponent } from '../info-links-edit/info-links-edit.component';
import { ActivatedRoute, Router } from '@angular/router';
import { infolinksExternalLinkAlt } from 'src/app/shared/components/icon';

@Component({
  selector: 'pos-info-links',
  templateUrl: './info-links.component.html',
})
export class InfoLinksComponent extends BaseGridComponent implements OnInit {

  @ViewChild('confirmDeleteOption') private confirmDeleteOption: ConfirmDeleteComponent;
  infoLinksListColumns: Array<GridColumn> = [];
  printInfoLinksListColumns: Array<GridColumn> = [];
  modalRefInfoLink: BsModalRef;
  infoLinkDetailsList: Array<InfoLinkModel> = [];
  editInfoLinkId: number;

  constructor(private authenticationService: AuthenticationService,
    private modalService: ModalService,
    private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private infoLinkService: InfoLinkService,
    protected applicationStateService: ApplicationStateService,
    protected route: ActivatedRoute,
    protected router: Router,
    private printService: PrintTableService) {
    super(applicationStateService, route);
  }

  permission = {
    ManageInfoLinks: Permissions.ManageInfoLinks
  };

  accessLevels = {
    ReadOnly: Levels.ReadOnly,
    Edit: Levels.Edit
  };

  icons = { infolinksExternalLinkAlt };
  ngOnInit() {
    this.configurInfoLinksGridColumns();
    this.reloadInfoLinksList();
  }

  configurInfoLinksGridColumns() {

    const editColumn = new EditColumn({
      Clicked: (data) => {
        this.editInfoLinks(data.Id);
      },
      Width: '57px',
      AddIcon: new LinkColumn({
        Clicked: () => {
          this.editInfoLinks(0);
        },
        ToolTip: 'New'
      })
    });

    const deleteColumn = new DeleteColumn({
      Clicked: (data) => {
        this.deleteInfoLinks(data.Id);
      },
      Width: '83px',
      PrintIcon: new LinkColumn({
        Clicked: () => {
          this.printData();
        }
      })
    });

    this.infoLinksListColumns = [
      new GridColumn({ HeaderText: 'Section', Field: 'SectionName', IsSortable: true, Width: '20%' }),
      new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '25%' }),
      new GridColumn({ HeaderText: 'Link', Field: 'Link', IsSortable: true, Width: '50%' })
    ] as Array<GridColumn>;

    this.printInfoLinksListColumns = [...this.infoLinksListColumns]
    if (this.authenticationService.userHasPermission([{ Name: this.permission.ManageInfoLinks, Level: this.accessLevels.Edit }],
      'any')) {
      this.infoLinksListColumns.push(editColumn);
      this.infoLinksListColumns.push(deleteColumn);
    }
  }

  reloadInfoLinksList() {
    this.spinnerService.show();
    return this.infoLinkService.getInfoLinks().subscribe(response => {
      this.infoLinkDetailsList = (response === null ? [] : response);
    },
      (error) => { this.alertsService.showApiError(error); },
      () => { this.spinnerService.hide(); });
  }

  editInfoLinks(infoLinkId: number) {
    const modelRefInfoLink = this.modalService.show(InfoLinksEditComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        infoLinkEditId: infoLinkId
      }
    });

    modelRefInfoLink.close.subscribe(res => {
      if (res && res.shouldReload) {
        this.onSave();
      } else {
        this.onSaveCancel();
      }
    });
  }

  deleteInfoLinks(infoLinkId: number) {
    this.editInfoLinkId = infoLinkId;
    this.confirmDeleteOption.Show(Messages.ConfirmDeleteInfoLink);
  }

  onConfirmAcknowledge() {
    this.spinnerService.show();
    this.infoLinkService.deleteInfoLink(this.editInfoLinkId).subscribe(response => {
      this.alertsService.renderSuccessMessage(Messages.InfoLinkDeleted);
      this.reloadInfoLinksList();
    },
      (error) => { this.alertsService.showApiError(error); },
      () => { this.spinnerService.hide(); });
  }

  onSave() {
    this.alertsService.renderSuccessMessage(Messages.InfoLinkSaveSuccess);
    this.reloadInfoLinksList();
  }

  onSaveCancel() {
  }

  printData() {
    this.printService.printEmitter.next({ gridColumns: this.printInfoLinksListColumns, gridData: this.infoLinkDetailsList });
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
