import { Injectable } from "@angular/core";
import { IKioskNavigation } from "../interfaces/ikiosk-navigation";
import { ChoiceBehavior } from "./choices-behavior";
import { AlertsService, DomainConstants, Messages, ModalService, StringUtils } from "src/app/shared";
import { KioskNavigationService } from "../services/kiosk-navigation.service";
import { KioskBehaviorExecution } from "./kiosk-behavior-execution";
import { OrderKioskChoiceBehaviorService } from "../services";
import { KioskScreen } from "../interfaces/kiosk-screen";
import { BehaviorPromiseProviderService } from "../services/behavior-promise-provider.service";

@Injectable({
    providedIn: 'root'
})
export class AddAutoMarkupBehavior extends ChoiceBehavior {

    constructor(private kioskNavigationService: KioskNavigationService,
        private orderKioskChoiceBehaviorService: OrderKioskChoiceBehaviorService,
        private modalService: ModalService,
        private alertsService: AlertsService,
        private behaviorPromiseProviderService: BehaviorPromiseProviderService) {
        super();
    }

    execute(navObj: IKioskNavigation, orderNavigation: KioskScreen): Promise<void> {
        if (this.kioskNavigationService.orderDetails?.DiscountPct) {
            this.kioskNavigationService.showInfoMessage(StringUtils.format(Messages.MarkupDiscountRestrict, { 'priceModifier': 'discount' }));
            return Promise.reject("Discount is already applied.");
        }
        if (this.behaviorValues.markupPercent && this.terminalType == DomainConstants.TerminalTypes.ORDER_KIOSK.Name) {
            return this.addMarkup();
        }
        return Promise.resolve();
    }

    addMarkup() {
        const model: KioskBehaviorExecution = {
            OrderId: this.kioskNavigationService.orderDetails?.Id?.Value,
            BehaviorId: this.behaviorId
        };
        this.orderKioskChoiceBehaviorService.executeBehavior(model)
            .subscribe({
                next: (order: any) => {
                    this.kioskNavigationService.versionedVal.Obj.Order.Id = order.Id.Value;
                    this.kioskNavigationService.versionedVal.Obj.Order.MarkupPct = order.MarkupPct;
                    this.kioskNavigationService.orderDetails = order;
                    this.behaviorPromiseProviderService.resolvePromise();
                },
                error: () => {
                    this.behaviorPromiseProviderService.rejectPromise();
                    this.alertsService.showApiError
                }
            });
        return this.behaviorPromiseProviderService.executionPromise;
    }
}

