import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { find, forEach, orderBy, remove } from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FilteredProducts } from 'src/app/make-table/filtered-product';
import { TerminalProperty } from '../../interface/terminal-property';
import { TerminalsService } from '../../services/terminals.service';
import { deleteWhite, circleRegular, checkCircleSimple } from 'src/app/shared/components/icon';
import { SalesGroup } from 'src/app/information-management/sales-groups/interface';
import { AlertsService, ColorUtilityService, EventBroadcastingService, OrderCardService, SalesGroupService } from 'src/app/shared/services';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { DomainConstants } from 'src/app/shared/constants/domain-constants';
@Component({
  selector: 'pos-filtered-products',
  templateUrl: './filtered-products.component.html',
  styleUrls: ['./filtered-products.component.scss']
})
export class FilteredProductsComponent implements OnInit {

  @Input() terminalId: number;
  @Input() filteredProducts: FilteredProducts = { SalesCategories: [], SalesGroups: [] };
  @Input() isShowHeaderFooter = true;
  @Input() isLoadOnPopup = true;
  @Input() isDataLoaded = false;
  @Input() height: number = null;
  @Input() isShowFilterSwitch = true;
  isFilteredProduct = true;
  isMobileView = false;
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() productsFiltered: EventEmitter<any> = new EventEmitter();
  checkAllGroups = false;
  salesGroup: number = null;
  salesGroups: Array<SalesGroup> = [];
  icons = {
    deleteWhite,
    checkCircleSimple,
    circleRegular
  };


  constructor(protected terminalService: TerminalsService,
    protected alertsService: AlertsService,
    protected spinnerService: SpinnerService,
    protected salesGroupService: SalesGroupService,
    protected modalService: ModalService,
    private colorUtilityService: ColorUtilityService,
    private eventBroadcastingService: EventBroadcastingService,
    private orderCardService: OrderCardService
  ) {

  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.spinnerService.show();
    const observables: Array<Observable<any>> = [];
    observables.push(this.salesGroupService.getAll());
    if (!this.isDataLoaded) {
      observables.push(this.terminalService.getTerminalProperties(this.terminalId));
    } else if (this.isShowFilterSwitch) {
      this.checkFilteredProduct();
    }
    forkJoin(observables)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: ([salesGroups, terminalProperties]: [Array<SalesGroup>, Array<TerminalProperty>]) => {
          this.salesGroups = orderBy(salesGroups, 'name');
          if (terminalProperties?.length) {
            const property = find(terminalProperties, x => x.PropertyKey === DomainConstants.TerminalProperties.FILTERED_PRODUCTS.Key);
            this.filteredProducts = property ? JSON.parse(property.PropertyValue) : { SalesCategories: [], SalesGroups: [] };
          }
          forEach(this.salesGroups, x => {
            const salesGroup = find(this.filteredProducts.SalesGroups, group => group === x.Id);
            if (salesGroup) {
              x.IsSelected = true;
            }
          });
          if (this.isShowFilterSwitch) {
            this.checkFilteredProduct();
          }
        },
        error: this.alertsService.showApiError
      });
  }

  getContrastColor(color) {
    return this.colorUtilityService.getContrastColor(color);
  }

  getLightenDarkenColor(color: string) {
    return this.colorUtilityService.lightenDarkenColor(color, 80);
  }

  salesGroupChanged(group: SalesGroup) {
    this.salesGroup = group.Id;
    if (group.IsSelected) {
      group.IsSelected = false;
      remove(this.filteredProducts.SalesGroups, x => x === this.salesGroup);
    } else {
      group.IsSelected = true;
      this.filteredProducts.SalesGroups.push(this.salesGroup);
    }
  }

  addFilteredProductsProperty() {
    const terminalProperty: TerminalProperty = {
      Id: 0,
      TerminalId: this.terminalId,
      PropertyKey: DomainConstants.TerminalProperties.FILTERED_PRODUCTS.Key,
      PropertyValue: JSON.stringify(this.filteredProducts)
    };
    this.spinnerService.show();
    this.terminalService.saveMakeTableTerminalProperty(terminalProperty)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: TerminalProperty) => {
          this.filteredProducts = JSON.parse(res.PropertyValue);
          this.productsFiltered.emit({ products: this.filteredProducts });
          this.eventBroadcastingService.filterProducts();
          this.close.emit();
        }
      });
  }

  filterProduct() {
    if (!this.isFilteredProduct) {
      this.checkAllGroups = false;
      this.salesGroups.forEach(group => group.IsSelected = false);
      this.filteredProducts.SalesGroups = [];
    }
  }

  checkFilteredProduct() {
    if (this.filteredProducts) {
      this.isFilteredProduct = this.orderCardService.checkIsFilteredApplied(this.filteredProducts);
      return;
    }
    this.isFilteredProduct = false;
  }

  checkAllSalesGroup(isCheckAllGroups: boolean) {
    if (isCheckAllGroups) {
      this.salesGroups.forEach(group => !group.IsSelected ? this.salesGroupChanged(group) : '');
    } else {
      this.salesGroups.forEach(group => group.IsSelected ? this.salesGroupChanged(group) : '');
    }
  }

  cancel() {
    this.close.emit();
  }

}
