import { NgModule } from '@angular/core';
import { ComponentsModule } from './components/components.module';
import { ApplicationStateService } from './services/application-state.service';
import { AlertsService } from './services/alerts.service';
import { LoggerService } from './services/logger.service';
// import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { ToastNoAnimationModule } from 'ngx-toastr';
import { HttpResponseUtilsService } from './services/http-response-utils.service';
import { AuthModule } from './auth';
import { MathsUtilityService } from './services/maths-utility.service';
import { PrintTableService } from './services/print-table.service';
import { PermissionService } from './services/permission.service';
import { DriverService, HardwareService, InventoryBinLightService, InventoryProductBarCodeService, InventoryProductRecipeService,
  ManagesService, OrderService, ParameterService, SalesCategoryService, SalesGroupService, WorkScheduleTagService, UserIdleService, PromotionDataService } from './services';
import { JL } from 'jsnlog';
import { OrderCardService } from './services/order-card.service';
import { ApplicationService } from './services/application.service';
import { ExternalApiService } from './services/external-api.service';
import { CommentWarningService } from './services/comment-warning-service';
import { InventoryProductDietaryWarningService } from './services/inventory-product-dietary-warnings.service';
import { AccountTypeService } from './services/account-type.service';
import { ApplicationLoginService } from './services/application-login.service';
import { SalesSizeService } from './services/sales-size.service';
import { DashboardTerminalPropertiesService } from './services/dashboard-terminal-properties.service';
import { UserService } from './services/user.service';
import { AudioNotificationService } from './services/audio-notification-file.service';
import { TaskService } from './services/task.service';
import { DeviceMappingService } from './services/device-mapping.service';
import { AccountService } from './services/account.service';
import { TrainingAreaService } from './services/training-area.service';
import { TrainingTopicService } from './services/training-topic.service';
import { TrainingTopicLevelService } from './services/training-topic-level.service';
import { CommonModule } from '@angular/common';
import { ScreenService } from './services/screen.service';
import { ButtonService } from './services/button.service';
import { ButtonFunctionService } from './services/button-function.service';
import { AccountCategoryService } from './services/account-category.service';
import { InventoryZoneService } from './services/inventory-zone.service';
import { SalesTaxService } from './services/sales-tax.service';
import { WorkScheduleService } from './services/work-schedule.service';
import { MarketingPromotionTypesService } from './services/marketing-promotion-types.service';
import { LayoutDesignersService } from './services/layout-designers.service';
import { TagService } from './services/tag.service';
import { ButtonNavigationService } from './services/button-navigation.service';
import { PreventBackGuardService } from './guards/prevent-back-guard.service';
import { CameraAnnotationService } from './services/camera-annotation.service';
import { AudioRecorderService } from './auth/services/audio-recorder.service';
import { AudioOperationsService } from './services/audio-operations.service';

@NgModule({
  imports: [
    // HttpModule,
    CommonModule,
    HttpClientModule,
    ComponentsModule,
    AuthModule,
    ToastNoAnimationModule.forRoot({
      closeButton: true,
      timeOut: 10000
    }),
  ],
  declarations: [],
  exports: [],
  providers: [
    { provide: 'JSNLOG', useValue: JL},
    CookieService,
    ApplicationStateService,
    HttpResponseUtilsService,
    AlertsService,
    MathsUtilityService,
    PermissionService,
    OrderService,
    PrintTableService,
    LoggerService,
    OrderCardService,
    ManagesService,
    ApplicationService, ExternalApiService, ParameterService, CommentWarningService, InventoryProductDietaryWarningService,
    AccountTypeService, ApplicationLoginService, SalesSizeService, DashboardTerminalPropertiesService, UserService,
    DeviceMappingService, AudioNotificationService, TaskService, DriverService, AccountService, TrainingAreaService, TrainingTopicService,
    TrainingTopicLevelService, ScreenService, ButtonService, ButtonFunctionService, AccountCategoryService, InventoryZoneService,
    HardwareService, InventoryProductBarCodeService, InventoryProductRecipeService, InventoryBinLightService, SalesCategoryService,
    SalesTaxService, WorkScheduleService, WorkScheduleTagService, MarketingPromotionTypesService, SalesGroupService, LayoutDesignersService , TagService,
    ButtonNavigationService, PreventBackGuardService, CameraAnnotationService, AudioRecorderService, AudioOperationsService, UserIdleService, PromotionDataService
  ]
})
export class SharedModule { }
