import { CurrencyPipe } from '@angular/common';
import { Inject, Pipe, PipeTransform } from '@angular/core';
import { TarkLocalProvider, LOCAL_PROVIDER } from '../service/tark-local-provider.service';

@Pipe({
  name: 'tark_currency'
})
export class TarkCurrencyPipe implements PipeTransform {

  constructor(@Inject(LOCAL_PROVIDER) private tarkLocalProvider: TarkLocalProvider,
    private currencyPipe: CurrencyPipe) {  }

  transform(value: number): string | null {
    return this.currencyPipe.transform(value, this.tarkLocalProvider.getCurrencyCode(), 'symbol', null, this.tarkLocalProvider.getLocale());
  }

}
