import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { OrderMethod, OrderMethodService } from 'src/app/configurator';
import { AlertsService, Messages, ModalComponent, OrderService, SpinnerService } from 'src/app/shared';
import { OrderSummary } from '../../interface';

@Component({
  selector: 'pos-set-order-method',
  templateUrl: './set-order-method.component.html'
})
export class SetOrderMethodComponent extends ModalComponent implements OnInit {

  @Input() order: OrderSummary = null;
  orderMethods: Array<OrderMethod> = [];
  orderMethodId: number = null;

  constructor(modalRef: BsModalRef,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private orderMethodService: OrderMethodService,
    private orderService: OrderService) {
    super(modalRef);
  }

  ngOnInit(): void {
    this.getRequiredData();
  }

  getRequiredData() {
    this.spinnerService.show();
    this.orderMethodService.getAll()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (orderMethods: Array<OrderMethod>) => {
          this.orderMethods = orderMethods;
          this.orderMethodId = orderMethods.find(x => x.Name == this.order.OrderMethod)?.Id;
        }, error: this.alertService.showApiError
      });
  }

  saveOrderMethod(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.spinnerService.show();
    this.orderService.updateOrderMethod(this.order.Order, this.orderMethodId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.alertService.renderSuccessMessage(Messages.OrderMethodSaved);
          this.hide({ shouldReload: true });
        }, error: this.alertService.showApiError
      });
  }

  closeModal() {
    this.hide({});
  }
}
