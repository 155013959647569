import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AlertsService, SpinnerService, DashboardConfigurationService, DashboardWidget, ModalService } from 'src/app/shared';
import { finalize } from 'rxjs/operators';
import { WidgetService } from '../../services/widget.service';
import { forEach, cloneDeep } from 'lodash';
import { Widget } from '../../interfaces/widget';
import { DashboardOptions } from '../../interfaces/dashboard-options';
import { AdfModel } from '../../interfaces/adf-model';
import { DashboardBroadcastingService } from '../../services/dashboard-broadcasting.service';
import { dashboardPlus, cog, dashboardSave, undo, editWhite } from 'src/app/shared/components/icon';
import { AdfDashboardEditComponent } from '../adf-dashboard-edit/adf-dashboard-edit.component';
import { AddWidgetComponent } from '../add-widget/add-widget.component';
import { Router } from '@angular/router';

@Component({
  selector: 'pos-adf-dashboard',
  templateUrl: './adf-dashboard.component.html',
  styleUrls: ['./adf-dashboard.component.scss']
})
export class AdfDashboardComponent implements OnInit {
  @Input() name: string;
  @Input() structure: string;
  @Input() isCollapsible: boolean;
  @Input() isEditable: boolean = true;
  isEditMode: boolean;
  @Input() isMaximizable: boolean;
  @Input() categories: boolean;
  @Input() adfModel: AdfModel;
  @Input() mode?: string;
  @Input() redirectRoute: string = null;
  @Output() toggleMode = new EventEmitter();
  widgets = {};
  dashboard = { widgetsList: {} };
  copiedDashboard: AdfModel;
  options: DashboardOptions;
  icons = {
    dashboardPlus,
    cog,
    editWhite,
    dashboardSave,
    undo
  };
  constructor(private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private dashboardConfigurationService: DashboardConfigurationService,
    private widgetService: WidgetService,
    private dashboardBroadcastingService: DashboardBroadcastingService,
    protected modalService: ModalService,
    private router: Router) { }

  ngOnInit(): void {
    this.isEditMode = this.adfModel.IsEditMode;
    this.prepareOptions();
    this.getDashboardWidgets();
  }

  getDashboardWidgets = (): void => {
    this.spinnerService.show();
    this.dashboardConfigurationService.getDashboardWidgets()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: Array<DashboardWidget>) => {
          if (res) {
            forEach(res, (widget: DashboardWidget) => {
              const widgetDetails: Widget = this.widgetService.getWidgetDetails(widget.WidgetKey);
              widget.Reload = true;
              widget.Icon = widgetDetails.Icon;
              this.widgets[widget.WidgetKey] = widget;
            });
            this.dashboard.widgetsList = this.widgets;
          }
        }, error: this.alertService.showApiError
      });
  }

  prepareOptions = () => {
    this.options = {
      Name: this.name,
      Categories: this.categories,
      Collapsible: this.isCollapsible,
      Editable: true,
      EnableConfirmDelete: false,
      Maximizable: this.isMaximizable
    };
  }

  toggleEditMode = () => {
    this.dashboardBroadcastingService.changeAdfDashboard(this.adfModel);
  }

  collapseAll = (isCollapse: boolean) => {
    this.dashboardBroadcastingService.changeAdfDashboardCollapseExpand(isCollapse);
  }

  cancelEditMode = () => {
    this.isEditMode = false;
    this.adfModel.IsEditMode = false;
    this.adfModel = cloneDeep(this.copiedDashboard);
    this.copiedDashboard = null;
    this.toggleMode.emit(this.isEditMode);
  }

  addWidgetDialog = () => {
    this.modalService.show(AddWidgetComponent, {
      animated: false,
      class: 'vertical-center modal-md',
      initialState: {
        adfModel: this.adfModel,
        widgets: this.widgets,
        dashboard: this.dashboard
      }
    })
  }

  editDashboardDialog = () => {
    this.modalService.show(AdfDashboardEditComponent, {
      animated: false,
      class: 'vertical-center modal-lg',
      initialState: {
        adfModel: this.adfModel
      }
    });
  }

  closeDashboard() {
    if (this.redirectRoute) {
      this.router.navigate([this.redirectRoute]);
    } else {
      this.router.navigate(['manage/console']);
    }
  }
}
