import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InventoryProductIngredientModel } from '..';
import { RuntimeConstants } from 'src/app/shared';
import { SalesProductNutritionFact } from '../interface/sales-product-nutrition-fact';

@Injectable()
export class IngredientsInfoService {
    ingredientsInfoApiUrl = `${RuntimeConstants.API_PATH}inventoryproductingredients`;
    constructor(private httpService: HttpClient) { }

    getIngredientsInfo(productId: number): Observable<InventoryProductIngredientModel[]> {
        return this.httpService.get<InventoryProductIngredientModel[]>
            (`${RuntimeConstants.API_PATH}inventoryproductingredients/` + productId);
    }

    getNutritionFactForSalesProducts(): Observable<SalesProductNutritionFact[]> {
        return this.httpService.get<SalesProductNutritionFact[]>
            (`${this.ingredientsInfoApiUrl}/ingredients`);
    }
}
