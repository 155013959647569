import { TerminalInfoComponent } from './terminal/components/terminal-info/terminal-info.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { ComponentsModule, TableConfiguration } from '../shared';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgPipesModule } from 'ngx-pipes';
import { TableModule } from '@tarktech/tark-ng-utils';
import { CalendarModule } from 'primeng/calendar';
import { TableModule as PrimengTableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { TableModule as DataTableModule } from 'primeng/table';
import { ColorPickerModule } from 'ngx-color-picker';
import { CKEditorModule } from 'ckeditor4-angular';
import { PopoverModule } from 'ngx-bootstrap/popover';

import { GiftCardComponent } from './gift-card/components/gift-card.component';
import { GiftCardService } from './gift-card/services/gift-card.service';
import { AddToBalanceComponent } from './gift-card/components/add-to-balance/add-to-balance.component';
import { OrderSummaryComponent } from './order-summary';
import { OrderTransactionsComponent } from './order-transactions/order-transactions.component';
import { QuickSearchComponent } from './quick-search/components/quick-search/quick-search.component';
import { QuickSerarchWrapperComponent } from './quick-search/components/quick-serarch-wrapper/quick-serarch-wrapper.component';
import { InventoryExhaustionModule } from '../information-management/inventory-products/components/inventory-exhaustion';
import { DrawerCheckpointComponent } from './drawer-checkpoint/drawer-checkpoint.component';
import { DrawerVarianceComponent } from './drawer-variance/drawer-variance.component';
import { MatButtonModule } from '@angular/material/button';
import { DrawerCheckpointWrapperComponent } from './drawer-checkpoint-wrapper/drawer-checkpoint-wrapper.component';
import { LoadDrawerComponent } from './load-drawer/load-drawer.component';
import { ReadOnlyOrderSummaryModule } from '../order-maintenance/components/read-only-order-summary';
import { InventoryModule } from './inventory/inventory.module';
import { EndOfDayModule } from './end-of-day/end-of-day.module';
import { SystemLogViewerComponent } from './system-log-viewer/components/system-log-viewer/system-log-viewer.component';
import { MANAGE_CONSOLE_STATES } from './manage-console.routes';
import { SystemLogViewerService } from './system-log-viewer';
import { CustomLinksComponent } from './customlinks/components/custom-links/custom-links.component';
import { InfoLinkService } from '../information-management/info-link/service/info-links.service';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { NewTemplateComponent } from './printout-designer/component/new-template/new-template.component';
import { ManageConsoleComponent } from './manage-console/component/manage-console/manage-console.component';
import { DriverComponent } from './driver/components/driver/driver.component';
import { TooltipModule } from 'primeng/tooltip';
import { ReceiptDesignerComponent } from './printout-designer/component/receipt-designer/receipt-designer.component';
import { RouterModule } from '@angular/router';
import { HeaderEntryModule } from 'src/app/header-entry/header-entry.module';
import { SharedHeaderComponent } from './shared-header/shared-header.component';
import { TrainingModule } from '../training/training-approve/training-module';
import { GiftCardNumpadWrapperComponentComponent } from '../shared/components/numpad/gift-card-numpad-wrapper-component/gift-card-numpad-wrapper-component.component';
import { OnlineOrderComponent } from './online-order/components/online-order/online-order.component';
import { OnlineOrderWorkflowComponent } from './online-order/components/online-order-workflow/online-order-workflow.component';
import { StepsModule } from 'primeng/steps';
import { ConfirmBackupModalComponent } from './confirm-backup-modal/confirm-backup-modal.component';
import { RouteConfigService } from '../shared/services/route-config.service';
import { INVENTORY_STATES } from './inventory/inventory.routes';
import { AudioRecordingListComponent } from './audio-recordings/component/audio-recording-list/audio-recording-list.component';
import { BarcodeScannerLivestreamModule } from "ngx-barcode-scanner";
import { TooltipModule as BsTooltipModule } from 'ngx-bootstrap/tooltip';
import { InventoryBarcodeScannerComponent } from './inventory-barcode/components/inventory-barcode-scanner';
import { InventoryBarcodeLogComponent } from './inventory-barcode/components/inventory-barcode-log';
import { InventoryBarcodeBatchListComponent } from './inventory-barcode/components/inventory-barcode-batch-list/inventory-barcode-batch-list.component';
RouteConfigService.addGuardsToRoutes(INVENTORY_STATES);

@NgModule({
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    ModalModule.forRoot(),
    ComponentsModule,
    NgPipesModule,
    StepsModule,
    TableModule.forRoot(TableConfiguration),
    CalendarModule,
    ColorPickerModule,
    PrimengTableModule,
    DropdownModule,
    AutoCompleteModule,
    CheckboxModule,
    DataTableModule,
    PopoverModule.forRoot(),
    CKEditorModule,
    MatButtonModule,
    ReadOnlyOrderSummaryModule,
    // ordersModule
    InventoryExhaustionModule,
    InventoryModule,
    EndOfDayModule,
    RouterModule.forChild(MANAGE_CONSOLE_STATES),
    TooltipModule,
    CodemirrorModule,
    HeaderEntryModule,
    TrainingModule,
    BarcodeScannerLivestreamModule,
    BsTooltipModule,
  ],
  providers: [GiftCardService, SystemLogViewerService, InfoLinkService, CurrencyPipe],
  declarations: [GiftCardComponent, AddToBalanceComponent, TerminalInfoComponent, OrderSummaryComponent, OrderTransactionsComponent,
    QuickSearchComponent, QuickSerarchWrapperComponent, DrawerCheckpointComponent, DrawerVarianceComponent,
    DrawerCheckpointWrapperComponent, LoadDrawerComponent, SystemLogViewerComponent, CustomLinksComponent, ManageConsoleComponent,
    DriverComponent, NewTemplateComponent, ReceiptDesignerComponent, SharedHeaderComponent, GiftCardNumpadWrapperComponentComponent, OnlineOrderComponent, OnlineOrderWorkflowComponent, ConfirmBackupModalComponent, AudioRecordingListComponent,
    InventoryBarcodeScannerComponent, InventoryBarcodeLogComponent, InventoryBarcodeBatchListComponent
  ],
  exports: [OrderSummaryComponent, OrderTransactionsComponent, TerminalInfoComponent, ManageConsoleComponent]
})
export class ManageConsoleModule { }
