import { Component, OnInit, EventEmitter, ViewChild, Input } from '@angular/core';
import { GridColumn, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { SettingParam, ApplicationStateService, OrderEventBroadcastingService } from 'src/app/shared';

@Component({
  selector: 'pos-table-order-details',
  templateUrl: './table-order-details.component.html',
  styleUrls: ['./table-order-details.component.scss']
})
export class TableOrderDetailsComponent implements OnInit {
  close: EventEmitter<any> = new EventEmitter();
  @Input() orders: Array<any> = [];
  @Input() tableName: string = '';
  @Input() isShowOccupiedTable: boolean = false;
  orderColumns: Array<GridColumn> = [];
  screenHeight = (window.innerHeight - 500) + 'px';
  settingParam: SettingParam;
  oneActiveOrderPerAccount = false;
  constructor(private applicationStateService: ApplicationStateService, private orderEventBroadcastingService: OrderEventBroadcastingService) { }

  ngOnInit() {
    this.settingParam = this.applicationStateService.settingParam;
    this.oneActiveOrderPerAccount = this.settingParam.OneActiveOrderPerAccount;
  }

  selectOrder(orderData) {
    if (this.isShowOccupiedTable) {
      const response = {
        shouldSelectTable: true,
        orderData: orderData
      };
      this.close.emit(response);
    } 
  }

  selectTable() {
    const response = {
      shouldSelectTable: true,
      orderData: null
    };
    this.close.emit(response);
  }

  openOrder = (orderId) => {
    this.orderEventBroadcastingService.activeOrderClicked.emit(orderId);
    this.close.emit({shouldOpenOrder: true});
  }
  
  closeDialog() {
    this.close.emit();
  }
}
