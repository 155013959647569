<div class="">
    <div class="activity-stream-header">
        <div class="row mx-0 col-12 p-0 justify-content-end pb-pix-10">
            <div class="col-xl-2 col-sm-4 col-12 p-0 pt-pix-10">
                <div class="inner-addon right-addon">
                    <pos-icon [icon]="icons.search"></pos-icon>
                    <input type="text" class="form-control" placeholder="Search" [(ngModel)]="searchActivity">
                </div>
            </div>
        </div>
        <div class="track-header text-bold">
            <div class="col-lg-9 col-9">
                <a (click)="sortActivities('Type')">
                    Activity Type
                    <span *ngIf="sortBy ==='Type'">
                        <span *ngIf="!reverseSort"><pos-icon [icon]="icons.sortasc"></pos-icon></span>
                        <span *ngIf="reverseSort"><pos-icon [icon]="icons.sortdesc"></pos-icon></span>
                    </span>
                </a>
            </div>
            <div class="col-lg-3 col-3">
                <a (click)="sortActivities('ActivityTime')">Time 
                    <span *ngIf="sortBy ==='ActivityTime'">
                        <span *ngIf="!reverseSort"><pos-icon [icon]="icons.sortasc"></pos-icon></span>
                        <span *ngIf="reverseSort"><pos-icon [icon]="icons.sortdesc"></pos-icon></span>
                    </span> 
                </a>
            </div>
        </div>
    </div>
    <div class="overflow-auto" [ngStyle]="{'height': height + 'px'}">
        <div class="panel" *ngFor="let activity of activities | customFilter :searchActivity | orderBy: (this.reverseSort ? '-' : '') + this.sortBy">
            <div class="panel-heading mb-pix-10">
                <div class="col-9">
                    <div class="italic">{{activity.ServiceName}}</div>
                    <div class="bold font-16" [ngStyle]="{'color': activity.Color}">{{activity.Type}}</div>
                    <div>{{activity.Activity}}</div>
                </div>
                <div class="col-3">
                    <div *ngIf="activity.ActivityTime">{{activity.ActivityTime | tark_date_time}}</div>
                </div>
            </div>
        </div>
    </div>
</div>
