import { Component, Input, OnInit } from '@angular/core';
import { cloneDeep, forEach, orderBy, some } from 'lodash';
import { Subscription } from 'rxjs/internal/Subscription';
import { RuntimeConstants } from 'src/app/shared';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { RabbitMQService } from 'src/app/shared/services/rabbitmq/services/rabbitmq.service';
import { ComboListWidgetStyleConfig } from '../../interfaces/combo-list-widget-style-config';
import { MenuDisplayComboItem } from '../../interfaces/menu-display-combo-item';
import { MenuDisplayItem } from '../../interfaces/menu-display-item';
import { WidgetBaseConfig } from '../../interfaces/widget-base-config';
import { WidgetComboItem } from '../../interfaces/widget-combo-item';
import { WidgetComponentProperties } from '../../interfaces/widget-component-properties';
import { WidgetDetails } from '../../interfaces/widget-details';
import { WidgetDisplayCombineItem } from '../../interfaces/widget-display-combine-item';
import { MenuWidgetStyleService } from '../../services/menu-widget-style.service';
import { MenuWidgetService } from '../../services/menu-widget.service';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
declare let $: any;
@Component({
  selector: 'pos-menu-combo-list-widget',
  templateUrl: './menu-combo-list-widget.component.html',
  styleUrls: ['./menu-combo-list-widget.component.scss']
})
export class MenuComboListWidgetComponent extends BaseWidgetComponent implements OnInit, WidgetComponentProperties {

  @Input() widgetId: number;
  widgetData: WidgetDetails;
  combinedItems: Array<WidgetDisplayCombineItem>;
  lodashFn: Function = some;
  comboListWidgetStyleConfig: ComboListWidgetStyleConfig;
  widgetConfig: WidgetBaseConfig;
  imagePath = RuntimeConstants.IMAGE_BASE_PATH + '/menu-display';
  rabbitMqSalesProductOutOfStockEventSubscription: Subscription;
  rabbitMqSalesProductReplenishedEventSubscription: Subscription;
  widgetContentStyle = {};
  constructor(
    protected menuWidgetService: MenuWidgetService,
    protected spinnerService: SpinnerService,
    protected alertService: AlertsService,
    protected rabbitMQService: RabbitMQService,
    protected applicationStateService: ApplicationStateService,
    protected modalService: ModalService,
    protected menuWidgetStyleService: MenuWidgetStyleService) {
    super(menuWidgetService, alertService, spinnerService, applicationStateService, modalService, rabbitMQService);
    this.deleteSuccessMessage = Messages.WidgetDeleted;
  }

  ngOnInit(): void {
    this.combinedItems = [];
    this.loadSubscriptions();
    this.loadWidget(this.widgetId);
    this.subscribeToSalesProductOutOfStockEventExchange();
    this.subscribeToSalesProductReplenishedEventExchange();
  }

  loadSubscriptions() {
    this.widgetData$.subscribe({ next: (res) => {
      this.widgetData = res ? res : null;
      this.prepareCombo();
      this.comboListWidgetStyleConfig = this.parseJson<ComboListWidgetStyleConfig>(res.WidgetStyleConfig);
      this.widgetConfig = this.parseJson<WidgetBaseConfig>(res.WidgetConfig);
      $('.widget-background-' + this.widgetId)?.css({'background': this.comboListWidgetStyleConfig?.WidgetBackgroundColor});
      this.comboListWidgetStyleConfig?.BorderStyle ?
        $('.widget-border-' + this.widgetId)?.css(this.menuWidgetStyleService.getBorderStyle(this.comboListWidgetStyleConfig.BorderStyle)) : null;
      this.widgetContentStyle = this.menuWidgetStyleService.getWidgetContentStyle(this.comboListWidgetStyleConfig);
    }, error: this.alertService.showApiError });
  }

  subscribeToSalesProductOutOfStockEventExchange() {
    this.rabbitMqSalesProductOutOfStockEventSubscription = this.rabbitMQService.subscribeToSalesProductOutOfStockEventExchange$()
      .subscribe((message) => {
        if (message.Payload.POSEvent) {
          this.setSalesProductStockStatus(message.Payload.POSEvent.SalesProductId, message.Payload.POSEvent.SizeIds, false);
        }
      });
  }

  subscribeToSalesProductReplenishedEventExchange() {
    this.rabbitMqSalesProductReplenishedEventSubscription = this.rabbitMQService.subscribeToSalesProductReplenishedEventExchange$()
      .subscribe((message) => {
        if (message.Payload.POSEvent) {
          this.setSalesProductStockStatus(message.Payload.POSEvent.SalesProductId, message.Payload.POSEvent.SizeIds, true);
        }
      });
  }

  setSalesProductStockStatus = (productId, sizeIds, status) => {
    const menuDisplayItems = cloneDeep(this.widgetData?.MenuDisplayItems);
    const combinedItems = cloneDeep(this.combinedItems);
    forEach(menuDisplayItems, (item) => {
      this.setIsInStockBySizes(item, productId, sizeIds, status);
    });
    forEach(combinedItems, (combinedItem) => {
      forEach(combinedItem.Items, (item) => {
        this.setIsInStockBySizes(item, productId, sizeIds, status);
      });
      combinedItem.IsInStock = combinedItem.IsInStock;
    });
    if(this.widgetData) {
      this.widgetData.MenuDisplayItems = menuDisplayItems;
    }
    this.combinedItems = combinedItems;
  }

  setIsInStockBySizes(item, productId, sizeIds, status) {
    if (item.Id == productId && item?.Prices) {
      forEach(item.Prices, (p) => {
        if (sizeIds?.some((size) => { return size == p?.SizeId })) {
          p.IsInStock = status;
        }
      });
    }
    item.IsInStock = some(item.Prices, (price) => price.IsInStock);
  }

  prepareCombo = () => {
    this.combinedItems = [];
    forEach(this.widgetData?.WidgetComboItems, (comboItem) => {
      let combineProducts: Array<MenuDisplayComboItem> = [];
      const MatchingDisplayProduct = this.widgetData?.MenuDisplayItems?.find(x => x?.Id == comboItem?.SalesProductId);
      const combinedItem = this.prepareComboWidgetDisplay(MatchingDisplayProduct, comboItem);
      this.combinedItems.push(cloneDeep(combinedItem));
    });
  }

  prepareComboWidgetDisplay(displayItem: MenuDisplayItem, comboItem: WidgetComboItem): WidgetDisplayCombineItem {
    return {
      IsInStock: displayItem.IsInStock,
      Items: orderBy(comboItem.WidgetComboSalesProducts, 'Ordinal'),
      SalesProductId: displayItem.Id,
      SubItems: [],
      Id: displayItem.Id,
      IsShowPrice: displayItem.IsShowPrice,
      Image: displayItem.Image,
      Video: displayItem.Video,
      WidgetProductGroupId: displayItem.WidgetProductGroupId,
      Text: displayItem.Text,
      Tags: displayItem.Tags,
      DietaryWarnings: displayItem.DietaryWarnings,
      DefaultSizeId: displayItem.DefaultSizeId,
      Description: comboItem.Description,
      Prices: displayItem.Prices,
      Color: displayItem.Color,
      IsInteractive: displayItem.IsInteractive,
      InteractiveStyleClass: displayItem.InteractiveStyleClass
    };

  }

  editWidget(id: number, data?: any): void {
  }


}
