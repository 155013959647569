<pos-edit-wrapper heading="{{widgetGroup.Id ? 'Edit' : 'Add'}} Widget Group" (close)="onCancel()">
    <form #widgetProductGroup="ngForm" (ngSubmit)="save(widgetProductGroup.valid)" autocomplete="off">
        <div class="col-12 pos-modal-body">
            <pos-row>
                <pos-column>
                    <pos-form-field label="Name" [validationMessages]="{required: 'Please enter group name.'}">
                        <input posInput class="form-control" name="name" #name="ngModel" [(ngModel)]="widgetGroup.Name"
                            type="text" [required]="true" />
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Price">
                        <input posInput type="text" class="form-control" name="price"
                            #price="ngModel" [(ngModel)]="widgetGroup.Price" />
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Description">
                        <input posInput type="text" class="form-control" name="groupConfigDescription" [(ngModel)]="groupConfig.Description" />
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="clearfix"></div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" scrollup>Save </button>
            <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" (click)="onCancel()">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
