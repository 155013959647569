import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { OffsiteInventoryProductSettingService } from '../../services/offsite-inventory-product-setting.service';
import { InventoryOffSiteLocationService } from '../../services';
import { finalize } from 'rxjs';
import { AlertsService, InfoModalComponent, Messages, ModalService, SpinnerService, cogs } from 'src/app/shared';
import { InventoryOffSiteInventoryProduct, InventoryOffSiteLocation, OffsiteInventoryProductSetting } from '../../interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { GridColumn, TemplateColumn } from '@tarktech/tark-ng-utils';
import { DecimalPipe } from '@angular/common';
import Pluralize from 'pluralize';

@Component({
  selector: 'pos-offsite-inventory-product-settings',
  templateUrl: './offsite-inventory-product-settings.component.html',
  styleUrls: ['./offsite-inventory-product-settings.component.scss']
})
export class OffsiteInventoryProductSettingsComponent implements OnInit {

  offsiteLocationId: number;
  offsiteLocation: InventoryOffSiteLocation;
  offsiteLocationProducts: Array<InventoryOffSiteInventoryProduct> = [];
  offsiteLocationProductSettings: Array<OffsiteInventoryProductSetting> = [];
  columns: Array<GridColumn> = [];
  icons = {
    cogs
  }
  @ViewChild('minTemplate', { static: true }) private minTemplate: TemplateRef<any>;
  @ViewChild('minHeaderTemplate', { static: true }) private minHeaderTemplate: TemplateRef<any>;
  @ViewChild('maxTemplate', { static: true }) private maxTemplate: TemplateRef<any>;
  @ViewChild('maxHeaderTemplate', { static: true }) private maxHeaderTemplate: TemplateRef<any>;
  pluralize = Pluralize
  constructor(private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private offsiteInventoryProductSettingService: OffsiteInventoryProductSettingService,
    private route: ActivatedRoute,
    private router: Router,
    private decimalPipe: DecimalPipe,
    private modalService: ModalService,
    private inventoryOffSiteLocationService: InventoryOffSiteLocationService) {
    this.offsiteLocationId = parseInt(route.snapshot.params.id, 10) ?? 0;
    this.offsiteLocation = inventoryOffSiteLocationService.newOffSiteLocation();
  }

  ngOnInit(): void {
    this.prepareColumns();
    this.loadDependencies();
    this.getActiveProductSettings();
  }

  loadDependencies() {
    this.spinnerService.show();
    this.inventoryOffSiteLocationService.get(this.offsiteLocationId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (inventoryOffsiteLocation: InventoryOffSiteLocation) => {
          this.offsiteLocation = inventoryOffsiteLocation;
        }, error: this.alertService.showApiError
      });
  }

  getActiveProductSettings() {
    this.spinnerService.show();
    this.offsiteInventoryProductSettingService.getProductsByLocation(this.offsiteLocationId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (offsiteLocationProductSettings: Array<OffsiteInventoryProductSetting>) => {
          this.offsiteLocationProductSettings = offsiteLocationProductSettings;
          this.offsiteLocationProductSettings.forEach(x => {
            x.InventoryProduct.UnitName = x.InventoryProduct.Unit.Name;
            x.MinPurchaseUnit = x.Min ? this.decimalPipe.transform(x.Min / x.InventoryProduct.UnitQty, '0.0-4').replace(/,/g, '') : null;
            x.MaxPurchaseUnit = x.Max ? this.decimalPipe.transform(x.Max / x.InventoryProduct.UnitQty, '0.0-4').replace(/,/g, '') : null;
          });
        }, error: this.alertService.showApiError
      });
  }

  prepareColumns() {
    this.columns = [
      new GridColumn({ HeaderText: 'Name', Field: 'InventoryProduct.Name', Width: '25%', IsSortable: true, CellClass: 'label-padding' }),
      new TemplateColumn({ Width: '25%', IsSortable: false, itemTemplate: this.minTemplate, headerTemplate: this.minHeaderTemplate }),
      new TemplateColumn({ Width: '25%', IsSortable: false, itemTemplate: this.maxTemplate, headerTemplate: this.maxHeaderTemplate }),
    ];
  }

  updateInventoryProductSettings(isValid: boolean) {
    if (!isValid || this.offsiteLocationProductSettings.some(x => x.Min < 0 || x.Max < 0)) {
      return;
    }
    this.spinnerService.show();
    this.offsiteLocationProductSettings.forEach(x => { x.Min = x.Min ?? 0; x.Max = x.Max ?? 0 });
    this.offsiteInventoryProductSettingService.saveProductSettings(this.offsiteLocationProductSettings.filter(x => x.Id > 0 || (x.Min > 0 && x.Max > 0)))
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.alertService.renderSuccessMessage(Messages.OffSiteProductSettingSaveSuccess);
          this.cancel();
        }, error: this.alertService.showApiError
      });
  }

  cancel() {
    this.router.navigate(['off-site-locations'], { relativeTo: this.route.parent });
  }

  setDecimalValue(value: number) {
    return parseFloat(this.decimalPipe.transform(value, '0.2-4')?.replace(/,/g, '') || '0.00');
  }

  confirmCleanupProducts() {
    const modal = this.modalService.getModalWrapper(InfoModalComponent);
    const modalRef = modal.show({
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      initialState: {
        modalHeaderText: 'Confirm Cleanup',
        confirmButtonText: 'Yes',
        rejectButtonText: 'No',
        message: Messages.OffSiteProductsConfirmCleanup
      }
    });
    modalRef.close.subscribe((res) => {
      if (res?.shouldConfirm) this.cleanupProducts();
    });
  }

  cleanupProducts() {
    this.spinnerService.show();
    this.offsiteInventoryProductSettingService.updateProductStatus(this.offsiteLocationId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.getActiveProductSettings();
          this.alertService.renderSuccessMessage(Messages.OffSiteProductsCleaned);
        }, error: this.alertService.showApiError
      });
  }

}
