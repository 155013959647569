<pos-edit-wrapper (close)="cancelEditing()" heading="{{id ? 'Edit Equipment Property' : 'Add Equipment Property'}}">
    <form #equipmentPropertyForm="ngForm" (submit)="submit(equipmentPropertyForm.valid)" autocomplete="off">
        <div class="col-12 pos-modal-body screen-scroll">
            <pos-row>
                <pos-column>
                    <pos-form-field label="Name" [validationMessages]="{required: 'Please enter name.'}">
                        <input posInput type="text" name="Name" class="form-control"
                            [(ngModel)]="equipmentProperty.Name" [required]="true" />
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" value="Save">Save </button>
            <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" (click)="cancelEditing()"
                value="Cancel">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
