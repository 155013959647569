import { Input, Output } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { EventEmitter } from "events";
import { RuntimeConstants } from "../../constants";
import { ColorUtilityService } from "../../services";

@Component({
  selector: "pos-ng-select-tag",
  templateUrl: "./ng-select-tag.component.html",
  styleUrls: ["./ng-select-tag.component.scss"],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm}]
})
export class NgSelectTagComponent implements OnInit {
  @Input("items") items: any[]= [];
  @Input("selectedTags") selectedTags: any[];
  @Input("multiple") multiple: boolean = false;
  @Input("label") label: string;
  @Input("disable") disable: boolean;
  @Input() dropdownPosition = 'auto';
  @Output("selectedTags")onselectedTags = new EventEmitter();
  @Input() controlLabel: string = '';
  imagePath = RuntimeConstants.IMAGE_BASE_PATH + '/tags';

  constructor(private colorUtilityService: ColorUtilityService) {}

  ngOnInit(): void {
  }

  removeTagSelection = (event) => {
    this.selectedTags = this.selectedTags.filter((data) => {
      return data.Id != event.Id;
    });
  }

  public getContrastColor(color: string) {
    return this.colorUtilityService.getContrastColor(color);
  }
  
  getSelectedTags = () => {
    return this.selectedTags;
  }
}
