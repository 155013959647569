<pos-edit-wrapper (close)="closeModal()" heading="{{selectedButtonBehaviorType.Text}}">
    <form #incrementInventoryForm="ngForm" autocomplete="off" (submit)='saveBehavior(incrementInventoryForm.valid)'>
        <div class="col-12 pos-modal-body screen-scroll">
            <pos-row>
                <pos-column>
                    <pos-form-field label="Inventory Product"
                        [validationMessages]="{required: 'Please select inventory product.'}">
                        <ng-select posInput [items]="inventoryProducts" placeholder="Select inventory product"
                            name="products" bindLabel="Name" bindValue="Id" [(ngModel)]="inventoryProductId"
                            #inventoryProductsList="ngModel" appendTo="body"
                            (change)="selectInventoryProduct(inventoryProductId)" [required]="true">
                        </ng-select>
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Qty" [validationMessages]="{required: 'Please enter qty.'}">
                        <pos-row colXs="6" class="p-0">
                            <pos-column>
                                <input posInput type="number" class="form-control col-120" [(ngModel)]="qty"
                                    name="productQty" #qtyInput="ngModel" [required]="true">
                            </pos-column>
                            <pos-column>
                                <label class="label-section">{{ qty && unit ? Pluralize(unit, qty) : unit }}</label>
                            </pos-column>
                        </pos-row>
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save</button>
            <button type="button" (click)="closeModal()" class="btn-secondary btn-lg btn btn-cancel-modal pos-button">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
