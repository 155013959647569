<pos-list-wrapper [headingIcon]="icons.users" heading="Users" (close)="close()">
    <div class="row justify-content-end">
        <div class="col-180 ps-0 text-end">
            <p-checkbox name="showSystemUsers" #showSystemUsers="ngModel"
                [(ngModel)]="userType.showSystemUsers" binary="true" (onChange)="reload()"
                label="Show System Users">
            </p-checkbox>
            <!-- <label class="edit-form__section__label">
                <strong> </strong>
            </label> -->
        </div>
        <div class="col-150 ps-pix-10 text-end">
            <p-checkbox name="showAllUsers" #showAllUsers="ngModel" [(ngModel)]="userType.showAllUsers"
                binary="true" (onChange)="reload()" label="Show Inactive">
            </p-checkbox>
            <!-- <label class="edit-form__section__label">
                <strong> </strong>
            </label> -->
        </div>
    </div>
    <div class="row">
        <div class="col-12 pt-pix-3">
            <ng-table #userGrid id="users" [sort]="true" [exportFilename]="'user'" [columns]="userColumns"
                [data]="users">
            </ng-table>
            <ng-template let-data="rowData" #iconTemplate>
                <span *ngIf="data.active" class="green text-center vertical-mid f-s-13">
                    <pos-icon [icon]="icons.check"></pos-icon>
                </span>
            </ng-template>
            <ng-template #iconHeaderTemplate>
            </ng-template>
        </div>
    </div>
</pos-list-wrapper>
<pos-confirm-delete (onConfirmAcknowledge)="onConfirmAcknowledge()" #confirmUser>
</pos-confirm-delete>
<ng-template #popOverForUser let-data="rowData">
    <span class="text-center mt-pix-n4 overflow-wrap">
        <a class="action-btn grey pointer" #pop="bs-popover" placement="left" (click)="onLinkPopOver(data,pop)"
            [outsideClick]="true" [popover]="userPopoverTemplate" triggers="click">
            <pos-action-icon [icon]="icons.barsWhite"></pos-action-icon>
        </a>
    </span>
</ng-template>
<ng-template #userPopoverTemplate>
    <div class="">
        <ul>
            <li class="listbullet"
                permissions='[{"Name":"{{permission.impersonate}}", "Level":"{{permission.accessLevel}}"}]'>
                <a class="grey pointer" (click)="onImpersonateUser()">
                    <pos-icon [icon]="icons.userImpersonate">
                    </pos-icon>
                    Impersonate User
                </a>
            </li>
            <li class="listbullet"
                permissions='[{"Name":"{{permission.unblockUser}}", "Level":"{{permission.accessLevel}}"}]'>
                <a class="grey pointer" (click)="onUnblockUser()">
                    <pos-icon [icon]="icons.userUnblock" class="ps-pix-2 pe-pix-4">
                    </pos-icon>
                    Unblock User
                </a>
            </li>
        </ul>
    </div>
</ng-template>

<pos-print-table [hidden]="true"></pos-print-table>
