<pos-banner *ngIf="widgetConfig" [data]="widgetConfig?.WidgetHeaderConfig" [imagePath]="imagePath"
    [styleData]="comboListWidgetStyleConfig?.WidgetHeaderStyleConfig"
    [borderStyle]="comboListWidgetStyleConfig?.BorderStyle"></pos-banner>
<div *ngIf="widgetConfig?.TitleText" class="p-pix-3 pb-0 text-center" [innerHTML]="widgetConfig?.TitleText"
    [ngStyle]="comboListWidgetStyleConfig?.TitleFontStyle"></div>
<div class="vertical-center-align flex-grow" [ngStyle]="{'background': comboListWidgetStyleConfig?.WidgetBackgroundColor}">
    <div class="flex-wrap vertical-center-align" [ngStyle]="widgetContentStyle">
        <div class="display-flex" *ngFor="let combinedItem of combinedItems">
            <pos-combine-items [combinedProduct]="combinedItem.Items" [description]="combinedItem?.Description" class="p-pix-3" 
                [widgetStyleConfig]="comboListWidgetStyleConfig" *ngIf="combinedItem.Items?.length && combinedItem.Items?.length > 1 && combinedItem.IsInStock"
                [interactiveStyleClass]="combinedItem.InteractiveStyleClass"></pos-combine-items>
        </div>
    </div>
</div>
<div *ngIf="widgetConfig?.FooterText" class="p-pix-3 text-center" [innerHTML]="widgetConfig?.FooterText"
    [ngStyle]="comboListWidgetStyleConfig?.FooterFontStyle"></div>
