import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RuntimeConstants } from 'src/app/shared';
import { InventoryReceived } from 'src/app/shared/interface/inventory-received';

@Injectable({
  providedIn: 'root'
})
export class InventoryReceivingService {

  constructor(private httpService: HttpClient) { }

  recordInventoryReceiving(terminalId: number, products: Array<InventoryReceived>): Observable<any> {
    return this.httpService.post(`${RuntimeConstants.API_PATH}inventory-products-receiving/record/${terminalId}`, products);
  }
  
  deleteInventoryReceived(InventoryReceivingId: number) {
    return this.httpService.delete(`${RuntimeConstants.API_PATH}inventory-products-receiving/delete/${InventoryReceivingId}`);
  }

  getInventoryReceivingPresentDay(id: number,date: string): Observable<any> {
    return this.httpService.get(`${RuntimeConstants.API_PATH}inventory-products-receiving/${id}/date/${date}`);
  }

  getInventoryReceivingProducts(vendorId: number) {
    return this.httpService.get(`${RuntimeConstants.API_PATH}inventory-products-receiving/vendor/${vendorId}`);
  }
}
