export interface PermissionRoles {
    Id: number;
    Name: string;
    DateAdded: Date;
    DateEdited?: Date;
    IsSystemDefined: boolean;
}

export enum SecurityRoleEdit {
    EnterOrder = 'Enter Order',
    ButtonExclusions = 'Button Exclusions'
}
