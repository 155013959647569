import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridColumn, TableComponent, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { AlertsService, ApplicationStateService, AuthenticationService, BaseGridComponent, Levels, Permissions, SpinnerService } from 'src/app/shared';
import { SMSTemplate } from '../../interfaces/sms-template';
import { SmsTemplateService } from '../../services/sms-template.service';
import { editWhite, sms } from 'src/app/shared/components/icon';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'pos-sms-templates-list',
  templateUrl: './sms-templates-list.component.html',
})
export class SmsTemplatesListComponent extends BaseGridComponent implements OnInit {
  smsTemplates: Array<SMSTemplate> = [];
  smsTemplateColumns: Array<GridColumn> = [];
  @ViewChild('operationTemplate', { static: true }) operationTemplate: TemplateRef<any>;
  @ViewChild('templatesGrid', { static: true }) templatesGrid: TableComponent;
  icons = {
    editWhite,
    sms
  };
  permission = {
    name: Permissions.SystemConfigurationSMSTemplates,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };
  constructor(protected smsTemplateService: SmsTemplateService,
    protected alertsService: AlertsService,
    protected spinnerService: SpinnerService,
    protected applicationStateService: ApplicationStateService,
    protected route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService) {
    super(applicationStateService, route);
  }

  ngOnInit(): void {
    this.loadData();
    this.smsTemplateColumns = this.configureGridColumns();
    this.templatesGrid.context = this.gridContext;
  }

  loadData() {
    this.spinnerService.show();
    this.smsTemplateService.getAll()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.smsTemplates = res;
        }, error: this.alertsService.showApiError
      });
  }

  protected configureGridColumns(): GridColumn[] {
    const editColumn = new TemplateColumn({
      itemTemplate: this.operationTemplate,
      Width: '45px',
      TextAlign: TextAlign.Center,
      CellClass: 'cell-padding'
    });
    this.smsTemplateColumns = [
      new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '97%' })
    ] as Array<GridColumn>;

    if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }],
      'any')) {
      this.smsTemplateColumns.push(editColumn);
    }
    return this.smsTemplateColumns;
  }

  editItem(id: number, _data?: SMSTemplate): void {
    this.router.navigate([id], { relativeTo: this.route });
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
