<div class="loading-block" [hidden]="!showLoader">
    <pos-spinner-icon></pos-spinner-icon>
</div>
<div>
    <h4 class="modal-content__heading order-wise-refund-header">Refund Orders</h4>
</div>
<div class="page-content clearfix">
    <div class="tab-content">
         <div class="row g-0 py-2">
                <div class="order-wise-refund-table col-12 px-2">
                    <ng-table [data]="orderList" [columns]="orderColumns" [paginator]="false" [search]="false" [scrollable]="true">
                    </ng-table>
                    <ng-template let-data="rowData" #nameTemplate>
                        <div class="name-ellipsis">{{data.Name}}</div>
                    </ng-template>

                    <ng-template let-data="rowData" #paidAmountTemplate>
                        <span>{{ data.PaidAmount * -1 | tark_currency}}</span>
                    </ng-template>
                </div>
                <div class="col-12 action-button-container popup-footer">
                    <button type="button" class="btn-secondary btn normal pos-button btn-cancel-modal" (click)="close()"
                        value="Cancel"> Close
                    </button>
                </div>
            </div>
    </div>
</div>
