import { Injectable } from '@angular/core';
import { BaseCrudService, RuntimeConstants } from 'src/app/shared';
import { HttpClient } from '@angular/common/http';
import { Track } from '../interface';
import { PlaylistTrack } from '../../playlists';

@Injectable({
  providedIn: 'root'
})
export class TracksService extends BaseCrudService<Track> {

  tracksApiPath = `${RuntimeConstants.API_PATH}tracks`;

  constructor(private httpClient: HttpClient) {
    super('tracks', httpClient);
  }

  newTrack() {
    const track: Track = {
      Id: 0,
      Filename: '',
      Category: '',
      Volume: 1
    };
    return track;
  }

  addTrack(track: Track, file: File) {
    const formData = this.prepareTrackFile(track, file);
    return this.httpClient.post(`${this.tracksApiPath}`, formData);
  }

  updateTrack(track: Track) {
    return this.httpClient.post(`${this.tracksApiPath}/${track.Id}`, track);
  }

  prepareTrackFile(track, file: File): FormData {
    const formData = new FormData();
    if (file) {
      formData.append('file', file);
    }
    formData.append('model', JSON.stringify(track));
    return formData;
  }

  changePlaylistTrackOrdinal(playlistTracks: Array<PlaylistTrack>) {
    return this.httpClient.put(`${this.tracksApiPath}/ordinal`, playlistTracks);
  }
}
