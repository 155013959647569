import { Component, OnInit, Input } from '@angular/core';
import { SalesProductsService } from '../../services';
import { finalize } from 'rxjs/operators';
import { AlertsService } from 'src/app/shared/services/alerts.service';

@Component({
  selector: 'pos-sales-product-show-recipe',
  templateUrl: './sales-product-show-recipe.component.html',
  styleUrls: ['./sales-product-show-recipe.component.scss']
})
export class SalesProductShowRecipeComponent implements OnInit {

  @Input() salesProductId: number;
  recipes: Array<any> = [];
  isLoad: boolean = false;
  constructor(private alertService: AlertsService,
    private salesProductService: SalesProductsService) { }

  ngOnInit() {
    this.getDetails();
  }

  getDetails() {
    this.isLoad = true;
    this.recipes = [];
    this.salesProductService.getSalesProductIngredient(this.salesProductId)
      .pipe(finalize(() => {
        this.isLoad = false;
      }))
      .subscribe({
        next: (res) => {
          if (res) {
            this.recipes = res;
          }
        }, error: this.alertService.showApiError
      });
  }
}
