import { Component, EventEmitter, OnInit } from '@angular/core';
import { forEach } from 'lodash';
import { finalize } from 'rxjs/operators';
import { DeliveryPayment, Driver, DriverBank } from 'src/app/dispatch';
import { DriverService } from 'src/app/shared/services/driver.service';
import { ICloseable, ModalService } from 'src/app/shared/components';
import { NumpadWrapperComponent } from 'src/app/shared/components/numpad/numpad-wrapper/numpad-wrapper.component';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { NumpadOptions, SettingParam } from 'src/app/shared/interface';
import { AlertsService, ApplicationStateService, OrderService } from 'src/app/shared/services';

@Component({
  selector: 'pos-driver',
  templateUrl: './driver.component.html',
  styleUrls: ['./driver.component.scss']
})
export class DriverComponent implements OnInit, ICloseable {

  close: EventEmitter<any> = new EventEmitter<any>();
  drivers: Array<Driver> = [];
  activeDriverTooltip = Messages.DriverAvailable;
  inactiveDriverTooltip = Messages.DriverUnavailable;
  numpadOptions: NumpadOptions;

  selectedDriver: Driver;
  driverBankDetails: DriverBank;
  driverBankTotalAmount = 0;
  driverAmount = 0;

  isFinalSetup = false;
  settingParam: SettingParam;

  constructor(private driverService: DriverService,
    private spinnerService: SpinnerService,
    private modalService: ModalService,
    private applicationStateService: ApplicationStateService,
    private orderService: OrderService,
    private alertsService: AlertsService) { }

  ngOnInit(): void {
    this.reload();
    this.setDefaults();
    this.numpadOptions = {
      allowDecimal: true,
      prefix: this.settingParam?.CurrencySymbol ?? '$'
    };
  }

  setDefaults() {
    this.settingParam = this.applicationStateService.settingParam;
  }

  reload(isSilentLoad = false) {
    if (!isSilentLoad) {
      this.spinnerService.show();
    }
    this.driverService.getDrivers()
      .pipe(finalize(() => {
        if (!isSilentLoad) {
          this.spinnerService.hide();
        }
      }))
      .subscribe({
        next: (response) => {
          this.drivers = response;
        }, error: this.alertsService.showApiError
      });
  }

  closeDriver() {
    this.close.emit();
  }

  updateDriver(driver: Driver) {
    this.selectedDriver = driver;
    this.spinnerService.show();
    this.driverService.getDriverBank(driver.Id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          this.driverBankDetails = response;
          if (this.driverBankDetails && ((this.driverBankDetails.Amount > 0 && driver.IsActiveDelivery) || driver.Total > 0)
            && !this.driverBankDetails.IsPaid) {
            this.numpadOptions.disable = false;
            this.numpadOptions.prefix = this.settingParam?.CurrencySymbol ?? '$';
            this.closeCurrentDeliveries();
          } else {
            if (this.selectedDriver.IsActiveDelivery && !this.selectedDriver.Total && !this.selectedDriver.Bank) {
              this.updateDriverBankDetails();
            } else {
              this.driverAmount = this.selectedDriver.Bank;
              this.isFinalSetup = false;
              this.openNumpad('Enter Bank Amount', this.selectedDriver.Bank);
            }
          }
        }, error: this.alertsService.showApiError
      });
  }

  openNumpad(title: string, value: number) {
    const modalRef = this.modalService.getModalWrapper(NumpadWrapperComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      initialState: {
        numpadId: 'driverPayment',
        numpadTitle: title,
        numpadOption: this.numpadOptions,
        value: value
      }

    });
    modal.close.subscribe((response) => {
      if (response && response.value) {
        this.isFinalSetup ? this.updateDriversDetails(response.value) : this.updateDriversBankAmount(response.value);
      }
    });
  }

  updateDriverBankDetails() {
    this.spinnerService.show();
    const driver = {
      IsActiveDelivery: false,
      IsPaid: true
    };
    this.driverService.makeDriverDeliveryPaid(this.selectedDriver.Id, driver)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.alertsService.renderSuccessMessage(Messages.DriverAccountSettleSuccess);
          this.reload();
        }, error: this.alertsService.showApiError
      });
  }

  updateDriversBankAmount(value: number) {
    this.driverAmount = 0;
    const updateAmount: DriverBank = {};
    updateAmount.Amount = value;
    updateAmount.IsPaid = false;
    updateAmount.BankId = this.selectedDriver.DriverBankId ? this.selectedDriver.DriverBankId : null;
    updateAmount.UserId = this.selectedDriver.Id;
    this.spinnerService.show();
    this.driverService.updateDriverBankAmount(updateAmount)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.alertsService.renderSuccessMessage(Messages.DriverBankAmountSaveSuccess);
          this.reload();
        }, error: this.alertsService.showApiError
      });
  }

  closeCurrentDeliveries() {
    this.spinnerService.show();
    this.driverService.getDriverBankAmount(this.selectedDriver.Id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (bankAmount) => {
          this.driverBankTotalAmount = parseFloat((bankAmount.Bank + this.selectedDriver.Total).toFixed(2));
          if (this.driverBankTotalAmount !== 0 || this.selectedDriver.IsActiveDelivery) {
            this.driverAmount = this.selectedDriver.Bank + this.selectedDriver.Total;
            this.driverAmount = parseFloat(this.driverAmount.toFixed(2));
            this.isFinalSetup = true;
            this.openNumpad('Enter Final Amount', this.driverAmount);
          }
        }, error: this.alertsService.showApiError
      });
  }

  updateDriversDetails(amount: number) {
    if (parseFloat((this.selectedDriver.Total + this.selectedDriver.Bank).toFixed(2)) < amount) {
      this.driverAmount = amount - (this.selectedDriver.Total + this.selectedDriver.Bank);
      this.numpadOptions.disable = true;
      this.numpadOptions.prefix = 'C ';
      this.openNumpad('Enter Final Amount', this.driverAmount);
    }
    if (this.driverBankTotalAmount - amount > 0) {
      this.alertsService.renderErrorMessage(Messages.ErrorWhileUnderpayment);
    } else {
      // Open Drawer
      this.spinnerService.show();
      this.driverService.getOpenDeliveryDetails(this.selectedDriver.Id)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (response) => {
            const deliveryPayments = [];
            forEach(response, (item) => {
              const deliveryPayment: DeliveryPayment = {
                OrderId: item.OrderId,
                Amount: item.Total - item.PaidAmount,
                Gratuity: 0,
                UserId: this.selectedDriver.Id,
                DrawerId: this.settingParam.CashDrawerPrinter.Id,
                PaymentType: 1,
                TransactionType: ''
              };
              deliveryPayments.push(deliveryPayment);
            });
            if (deliveryPayments.length) {
              this.spinnerService.show();
              this.driverService.applyPaymentForDriverDelivery(deliveryPayments)
                .pipe(finalize(() => {
                  this.spinnerService.hide();
                }))
                .subscribe({
                  next: () => {
                    this.getUnservedOrders();
                  }, error: this.alertsService.showApiError
                });
            }
            const driverPaymentObj = {
              UserId: this.selectedDriver.Id,
              Amount: this.selectedDriver.Total + this.selectedDriver.Bank
            };
            this.driverService.saveDriverPayment(driverPaymentObj)
              .subscribe({ next: () => { }, error: this.alertsService.showApiError });
            this.updateDriverBankDetails();
          }, error: this.alertsService.showApiError
        });
    }
  }

  getUnservedOrders() {
    this.spinnerService.show();
    this.orderService.getUnservedOrdersData(this.settingParam.CashDrawerPrinter.Id, this.settingParam.UserID)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          this.applicationStateService.unservedOrdersData = response;
        }, error: this.alertsService.showApiError
      });
  }
}
