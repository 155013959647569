<div class="col-12 row mx-0 order-invoice-footer justify-content-between p-0 bold"
    *ngIf="orderData && orderData.Id.Value">
    <div class="col-6 col-xs-12 row p-1 ps-pix-20">
        <div class="col-6 ps-0">
            Discount:
        </div>
        <div class="col-md-4 col-6 ps-0 text-end">
            {{ orderData.DiscountAmount | tark_currency}}
        </div>
    </div>
    <div class="col-6 col-xs-12 row p-1 pe-pix-7 text-end">
        <div class="col-6 ps-0">
            Tax:
        </div>
        <div class="col-6 ps-0">
            {{ orderData.TaxTotal | tark_currency}}
        </div>
    </div>
    <div class="col-6 col-xs-12 row p-1 ps-pix-20">
        <div class="col-6 ps-0">
            Subtotal:
        </div>
        <div class="col-md-4 col-6 ps-0 text-end">
            {{ orderData.SubTotal | tark_currency}}
        </div>
    </div>
    <div class="col-6 col-xs-12 row p-1 pe-pix-7 text-end">
        <div class="col-6 ps-0">
            Total:
        </div>
        <div class="col-6 ps-0">
            {{ orderData.GrandTotal | tark_currency }}
        </div>
    </div>
    <div class="col-6 col-xs-12 row p-1 ps-pix-20">
        <div class="col-6 ps-0">
            <span class="">Markup:</span>
        </div>
        <div class="col-md-4 col-6 ps-0 text-end">
            {{ orderData.MarkupAmount | tark_currency}}
        </div>
    </div>
</div>
