<pos-row class="mx-0">
    <pos-column class="px-pix-7">
        <pos-form-field label="Behavior Type">
            <div class="btn-group">
                <label class="btn btn-radio"
                    [ngClass]="outOfStockBehavior.BehaviorType == outOfStockBehaviorTypes.Hide ? 'active btn-primary normal': 'btn-secondary'">
                    <input [(ngModel)]="outOfStockBehavior.BehaviorType" type="radio" value="Hide" name="Hide"> Hide
                </label>
                <label class="btn btn-radio"
                    [ngClass]="outOfStockBehavior.BehaviorType == outOfStockBehaviorTypes.Disabled ? 'active btn-primary normal': 'btn-secondary'">
                    <input posInput [(ngModel)]="outOfStockBehavior.BehaviorType" type="radio" value="Disabled"
                        name="Disabled">
                    Disabled
                </label>
            </div>
        </pos-form-field>
    </pos-column>

    <pos-column class="p-0" *ngIf="outOfStockBehavior.BehaviorType == outOfStockBehaviorTypes.Disabled">
        <pos-row class="mx-0" colStyleClass="p-0">
            <pos-column class="px-pix-7">
                <pos-form-field label="Disabled Item Color">
                    <pos-color-picker posInput name="{{prefix}}_outOfStockItemColor"
                        [(ngModel)]="outOfStockBehavior.ItemColor" ></pos-color-picker>
                </pos-form-field>
            </pos-column>

            <pos-column class="px-pix-7">
                <pos-form-field label="Disabled Item Decoration">
                    <input posInput name="disabledItemTextDecoration" class="form-control" type="text" [(ngModel)]="outOfStockBehavior.TextDecoration" />
                </pos-form-field>
            </pos-column>

            <pos-column class="px-pix-7">
                <pos-form-field label="Text">
                    <input posInput class="form-control" name="{{prefix}}_outOfStockText" type="text"
                        [(ngModel)]="outOfStockBehavior.Text" />
                </pos-form-field>
            </pos-column>

            <pos-column class="p-0">
                <pos-font-style-config [(fontStyleConfig)]="outOfStockBehavior.TextStyleConfig"
                    prefix="{{prefix}}_outOfStockBehavior"></pos-font-style-config>
            </pos-column>
        </pos-row>
    </pos-column>
</pos-row>
