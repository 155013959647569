import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SalesProductNutrition } from 'src/app/menu-explorer/interfaces/sales-product-nutrition';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import {
    NutritionFactElement, NutritionFactMeasure,
    InventoryProductNutrition
} from 'src/app/shared/interface/inventory-product-nutrition';


@Injectable()

export class InventoryProductNutritionService {

    inventoryProductNutritionApiUrl = `${RuntimeConstants.API_PATH}inventoryproductnutrition/`;

    constructor(private httpService: HttpClient) {
    }

    newInventoryProductNutrition(inventoryProductId) {
        return {
            Id: 0,
            InventoryProductId: inventoryProductId,
            NutritionFactElementId: null,
            Amount: 0,
            MeasureId: null,
            Percent: null,
            NutritionFactElement: {
                Id: 0, Name: null, DateAdded: null,
                DateEdited: null
            },
            NutritionFactMeasure: {
                Id: 0, Name: null, DateAdded: null,
                DateEdited: null
            },
            DateAdded: null,
            DateEdited: null
        };
    }

    getNutritionFactElements(): Observable<NutritionFactElement[]> {
        return this.httpService.get<NutritionFactElement[]>(`${RuntimeConstants.API_PATH}nutritionfactelement`);
    }
    getNutritionFactMeasures(): Observable<NutritionFactMeasure[]> {
        return this.httpService.get<NutritionFactMeasure[]>(`${RuntimeConstants.API_PATH}nutritionfactmeasure`);
    }
    getNutritionsByInventoryProductId(inventoryProductId: number): Observable<InventoryProductNutrition[]> {
        return this.httpService.get<InventoryProductNutrition[]>
            (`${this.inventoryProductNutritionApiUrl}${inventoryProductId}`);
    }
    deleteNutrition(inventoryProductNutritionId: number): Observable<any> {
        return this.httpService.delete(`${this.inventoryProductNutritionApiUrl}${inventoryProductNutritionId}`);
    }

    getNutritionsBySalesProductId(salesProductId: number, sizeId: number): Observable<SalesProductNutrition> {
        return this.httpService.get<SalesProductNutrition>
            (`${this.inventoryProductNutritionApiUrl}sales-product/${salesProductId}/size/${sizeId}`);
    }
}
