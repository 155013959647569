<div class="m-0-auto px-pix-10">
    <pos-list-wrapper heading="Menu Widgets" (close)="cancel()">
        <h4><b><span class="header-lightgrey">Menu Display: {{menu?.Name}} ({{menu?.Width }} X
                    {{menu?.Height}})</span></b></h4>
        <div class="col-12 pb-pix-5">
            <div class="table-responsive">
                <ng-table [columns]="menuWidgetColumns" [data]="menuWidgets" #menuWidgetList>
                </ng-table>
            </div>
        </div>
    </pos-list-wrapper>

    <ng-template let-data="rowData" #operationTemplate>
        <div>
            <span class="pe-pix-3" (click)="editItem(data.Id)" tooltip title="Edit">
                <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
            </span>
            <span class="pe-pix-3" (click)="confirmDelete(data)" tooltip title="Delete">
                <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
            </span>
            <span (click)="configWidgetGroup(data)" tooltip title="Config">
                <pos-action-icon [icon]="icons.cogWhite"></pos-action-icon>
            </span>
        </div>
    </ng-template>
</div>
<pos-print-table [hidden]="true"></pos-print-table>
