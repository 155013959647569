<pos-edit-wrapper heading="Email Template" [headingIcon]="icons.emailTemplatesEnvelopeOpen" (close)="cancel()" [isPopup]="false">
  <form (ngSubmit)="saveEmailTemplate(emailTemplateForm.valid)" #emailTemplateForm="ngForm">
    <div class="col-xs-12 border-top">
      <div class="col-xs-12 pos-page-body">
        <h3 class="setting-title mt-pix-5">Edit Template</h3>
        <pos-row class="pos-title-content">
          <pos-column>
              <pos-form-field label="Name" [validationMessages]="{required: 'Please enter name.'}" inputStyle="col-sm-4 col-xs-6">
                <input posInput class="form-control" name="name" #name="ngModel" [(ngModel)]="emailTemplate.Name" [required]="true">
              </pos-form-field>
          </pos-column>

          <pos-column>
              <pos-form-field [validationMessages]="{required: 'Please enter value.'}" inputStyle="col-xs-12">
                <ngx-codemirror id="templateValue" posInput
                  [ngClass]="{'validate-text-editor': (templateValue.invalid && emailTemplateForm.submitted)}"
                  name="templateValue" #templateValue="ngModel" [(ngModel)]="emailTemplate.Value"
                  (cursorActivity)="getCursorPosition($event)" [options]="codeMirrorOptions" [required]="true"></ngx-codemirror>
                <span class="me-pix-26 mt-pix-n50 relative" style="float: right">
                  <span class="font-20 pointer grey">
                    <pos-icon [icon]="icons.messageCode" style="z-index: 10;" class="pointer relative"
                      (click)="isOpenVariable = !isOpenVariable">
                    </pos-icon>
                  </span>
                  <div *ngIf="isOpenVariable" class="col-200 variable-dropdown">
                    <ng-select [items]="variables" placeholder="Select variable" name="eventVariable"
                      [(ngModel)]="selectedParameter" (change)="changeEmailParameter()" [isOpen]="true">
                    </ng-select>
                  </div>
                </span>
              </pos-form-field>
          </pos-column>
        </pos-row>
      </div>
    </div>
    <div class="col-xs-12 action-button-container page-footer">
      <button type="button" class="btn-warning btn-lg btn btn-save pos-button white" scrollup
        (click)="preview()">Preview</button>
      <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" scrollup>Save</button>
      <button type="button" class="btn-secondary btn-lg btn btn-cancel pos-button" scrollup (click)="cancel()">Cancel</button>
    </div>
  </form>
</pos-edit-wrapper>
