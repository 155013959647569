<pos-edit-wrapper heading="{{scriptType}} Template" (close)="cancel()">
	<form (ngSubmit)="createNewScript(newTemplateForm.valid)" #newTemplateForm="ngForm">
		<div class="col-12 pos-modal-body">
			<pos-row>
				<pos-column>
					<pos-form-field label="Name" [validationMessages]="{required: 'Name is required.'}">
						<input posInput class="form-control" [(ngModel)]="templateName" name="templateName"
							[required]="true" #name="ngModel" />
					</pos-form-field>
				</pos-column>
			</pos-row>
		</div>
		<div class="col-12 action-button-container popup-footer">
			<button class="btn btn-primary btn-lg btn-save pos-button" type="submit">
				Save
			</button>
			<button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="cancel()" type="button">
				Cancel
			</button>
		</div>
		<div class="clearfix"></div>
	</form>
</pos-edit-wrapper>
