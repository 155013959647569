<pos-edit-wrapper heading="Dayparts" (close)="cancel()" [headingIcon]="icons.dayparts" [isPopup]="false">
    <form (ngSubmit)="saveDayparts(daypartsForm.valid)" #daypartsForm="ngForm">
        <context-provider provide="props">
            <div class="col-12 pos-page-body border-top pt-pix-5">
                <div class="col-12">
                    <div>
                        <h3 class="setting-title">Settings</h3>
                    </div>
                    <div class="pos-title-content">
                        <div class="col-12 mt-pix-10 px-pix-5" *ngIf="remainingTimeBlocks.length && isShowTimeBlocks">
                            <a class="close alert-close" (click)="isShowTimeBlocks = false;">×</a>
                            <div class="alert alert-warning mb-0">
                                Following time blocks aren't covered by any of the following dayparts:
                                <ul>
                                    <li *ngFor="let time of remainingTimeBlocks">
                                        {{ time }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="overflow-auto ps-1 pb-1">
                            <pos-row class="pt-pix-10 flex-nowrap">
                                <pos-column xs="5" class="col-320"><strong>Name</strong></pos-column>
                                <pos-column xs="5" class="col-185"><strong>Start Time</strong></pos-column>
                                <pos-column xs="5" class="col-185"><strong>End Time</strong></pos-column>
                            </pos-row>
                            <pos-row
                                *ngFor="let daypart of dayparts; trackBy: customTrackBy; let last = last; let index = index"
                                class="flex-nowrap">
                                <pos-column xs="5" class="col-320">
                                    <pos-form-field [validationMessages]="{ required: 'Please enter name.'}">
                                        <input posInput type="text" name="name{{index}}" #partName="ngModel"
                                            class="form-control" (keypress)="createDaypartsRow(last)"
                                            [(ngModel)]="daypart.Name"
                                            [required]="!last && (daypart.StartTime || daypart.EndTime)" />
                                    </pos-form-field>
                                </pos-column>
                                <pos-column xs="5" class="col-185">
                                    <pos-form-field [validationMessages]="{ required: 'Please enter start time.'}">
                                        <input posInput type="time" class="form-control" name="startTime{{index}}"
                                            #startTime="ngModel" [(ngModel)]="daypart.StartTime"
                                            (keypress)="createDaypartsRow(last); resetBetweenTime();"
                                            (click)="createDaypartsRow(last); resetBetweenTime();"
                                            [required]="!last && (daypart.Name || daypart.EndTime)">
                                        <span *ngIf="daypart.HasBetweeenStartTime">
                                            <label class="text-danger">
                                                Time of a daypart cannot overlap with another daypart.</label>
                                        </span>
                                    </pos-form-field>
                                </pos-column>
                                <pos-column xs="5" class="col-185">
                                    <pos-form-field [validationMessages]="{ required: 'Please enter end time.'}">
                                        <input posInput type="time" class="form-control" name="endTime{{index}}"
                                            #endTime="ngModel" [(ngModel)]="daypart.EndTime"
                                            (keypress)="createDaypartsRow(last); resetBetweenTime();"
                                            (click)="createDaypartsRow(last); resetBetweenTime();"
                                            [required]="!last && (daypart.Name || daypart.StartTime)">
                                        <span *ngIf="daypart.HasBetweeenEndTime">
                                            <label class="text-danger">
                                                Time of a daypart cannot overlap with another daypart.</label>
                                        </span>
                                    </pos-form-field>
                                </pos-column>
                                <pos-column xs="2" class="col-90 pt-pix-13" *ngIf="!last">
                                    <pos-action-icon [icon]="icons.deleteWhite" (click)="removeDaypart(daypart, index)">
                                    </pos-action-icon>
                                </pos-column>
                            </pos-row>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-12 action-button-container page-footer">
                <button class="btn btn-primary btn-lg btn-save pos-button" scrollup type="submit"
                    value="Save">Save</button>
                <button class="btn btn-secondary btn-lg btn-cancel pos-button" (click)="cancel()" scrollup type="button"
                    value="Cancel">Cancel</button>
            </div>
        </context-provider>
    </form>
</pos-edit-wrapper>
