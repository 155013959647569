import { Component, Input, OnChanges } from '@angular/core';
import { cloneDeep, forEach } from 'lodash';
import { IconSpecification } from './icon-specification';

@Component({
  selector: 'pos-icon',
  template: `
  <fa-icon *ngIf="!icons || !icons.length" [icon]="displayIcon.iconClasses" [rotate]="displayIcon.rotate" [ngClass]="displayIcon && displayIcon.additionalClasses?displayIcon.additionalClasses:''" [pulse]="displayIcon.pulse" [fixedWidth]="displayIcon.fixedWidth"></fa-icon>
  <span class="fa-stack" *ngIf="icons?.length">
    <fa-icon *ngFor="let item of icons; let i = index" [rotate]="item.rotate"
     [icon]="item.iconClasses" [ngClass]="item && item.additionalClasses?item.additionalClasses:''"
      [pulse]="item.pulse" [fixedWidth]="item.fixedWidth"></fa-icon>
  </span>`,
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnChanges {

  // CODEREVIEW: Do we need these two separate props?
  @Input()
  icon: IconSpecification;
  @Input() icons?: Array<IconSpecification> = [];

  @Input() additionalClasses?: Array<string>;
  @Input() spin?: boolean;
  @Input() pulse?: boolean;
  @Input() fixedWidth?: boolean;
  @Input() backgroundClasses?: Array<string> | string;
  @Input() additionalClassesAppend?: boolean;
  displayIcon: IconSpecification;

  ngOnChanges(): void {
    if (this.icon) {
      this.displayIcon = cloneDeep(this.icon);
      if (this.additionalClassesAppend) {
        forEach(this.additionalClasses, (c) => {
          this.displayIcon.additionalClasses.push(c);
        });
      } else if (this.additionalClasses) {
        this.displayIcon.additionalClasses = this.additionalClasses;
      }
      this.displayIcon.spin = this.displayIcon.spin !== this.spin ? this.spin : this.displayIcon.spin;
      this.displayIcon.pulse = this.displayIcon.pulse !== this.pulse ? this.pulse : this.displayIcon.pulse;
      this.displayIcon.fixedWidth = this.displayIcon.fixedWidth !== this.fixedWidth ? this.fixedWidth : this.displayIcon.fixedWidth;
      this.displayIcon.backgroundClasses = this.backgroundClasses && this.displayIcon.backgroundClasses !== this.backgroundClasses ?
        this.backgroundClasses : this.displayIcon.backgroundClasses;
    }
  }
}
