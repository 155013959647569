import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { InventoryProductService } from 'src/app/shared/services/inventory-product.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { finalize } from 'rxjs/operators';
import { LogDueDateOverride } from 'src/app/information-management/inventory-products/interface/log-due-date-override';
import { ApplicationStateService, FormUtilityService } from 'src/app/shared/services';
import { ModalFormComponent, ModalService } from 'src/app/shared';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'pos-inventory-log-due-date-override-edit',
    templateUrl: './inventory-log-due-date-override-edit.component.html',
})
export class InventoryLogDueDateOverrideEditComponent extends ModalFormComponent implements OnInit {
    get getForm(): NgForm {
        return this.inventoryLogDueDateOverrideEditForm;
    }

    @Input() inventoryVendorId: number;
    @Input() inventoryZoneId: number;
    @Input() subcategoryId: number;
    @Input() heading: string;
    @ViewChild('inventoryLogDueDateOverrideEditForm') inventoryLogDueDateOverrideEditForm: NgForm;
    isApplyToPrimaryZone = false;
    isApplyToSecondaryZone = false;
    nextLogDueDateOverride = new Date();
    dateFormat = 'mm-dd-yyyy';
    constructor(private inventoryProductService: InventoryProductService,
        private alertService: AlertsService,
        private applicationStateService: ApplicationStateService,
        private spinnerService: SpinnerService,
        modalRef: BsModalRef, modalService: ModalService, formUtilityService: FormUtilityService) {
        super(modalRef, modalService, formUtilityService);
    }

    ngOnInit() {
        this.isApplyToPrimaryZone = this.inventoryZoneId ? true : false;
        this.nextLogDueDateOverride.setDate(new Date().getDate() - 1);
        this.dateFormat = this.applicationStateService.settingParam.PCalendarDateFormat;
    }
    cancelLogDateEdit() {
        this.hide({ reload: false });
    }
    saveInventoryNextLogDate() {
        const inventoryLogDateOverride: LogDueDateOverride = {
            InventoryZoneId: this.inventoryZoneId,
            VendorId: this.inventoryVendorId,
            SubcategoryId: this.subcategoryId,
            LogDueDateOverride: this.nextLogDueDateOverride,
            IsApplyToPrimaryZone: this.isApplyToPrimaryZone,
            IsApplyToSecondaryZone: this.isApplyToSecondaryZone
        };
        this.spinnerService.show();
        this.inventoryProductService.updateLogDueDateOverride(inventoryLogDateOverride)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: () => {
                    this.hide({ reload: true });
                }, error: this.alertService.showApiError
            });
    }

}
