import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DeleteColumn, GridColumn } from '@tarktech/tark-ng-utils/table/public_api';
import * as _ from 'lodash';
import { Observable, finalize, forkJoin } from 'rxjs';
import { AlertsService, ConfirmDeleteModalComponent, Messages, ModalService, MultiProductGroupService, SpinnerService } from 'src/app/shared';
import { MultiProductNextScreen, MultiProductNextScreenService } from 'src/app/shared/services/multi-product-next-screen.service';
import { Permissions, Levels } from 'src/app/shared/constants';
import { plusWhite, editWhiteWithText } from 'src/app/shared/components/icon';
import { MultiProductGroup } from '../multi-product-group/multi-product-group';
import { MultiProductGroupComponent } from '../multi-product-group/multi-product-group.component';
@Component({
  selector: 'pos-multi-product-next-screen',
  templateUrl: './multi-product-next-screen.component.html',
  styleUrls: ['./multi-product-next-screen.component.scss']
})
export class MultiProductNextScreenComponent implements OnInit {

  @Input() screenId: number;
  @Input() screenList = [];
  multiProductNextScreen: MultiProductNextScreen;
  multiProductNextScreens: Array<MultiProductNextScreen> = [];
  multiProductNextScreenColumns: Array<GridColumn> = [];
  @ViewChild('multipleProductForm') multipleProductForm: NgForm;
  groups = [];
  icons = {
    plusWhite,
    editWhiteWithText
  }
  permission = {
    multipleProductGroups: Permissions.MultipleProductGroups,
    editLevel: Levels.Edit,
  };
  constructor(private multiProductNextScreenService: MultiProductNextScreenService,
    private spinnerService: SpinnerService,
    private multiProductGroupService: MultiProductGroupService,
    private modalService: ModalService,
    private alertService: AlertsService) {
    this.multiProductNextScreen = this.newMultiProductNextScreen();
  }

  ngOnInit(): void {
    this.spinnerService.show();
    const observables: Array<Observable<any>> = [];
    observables.push(this.multiProductNextScreenService.getMultiProductNextScreenById(this.screenId))
    observables.push(this.multiProductGroupService.getAll())
    forkJoin(observables).pipe(finalize(() => {
      this.spinnerService.hide();
    }))
      .subscribe({
        next: ([multiProductNextScreens, groups]) => {
          this.multiProductNextScreens = multiProductNextScreens;
          this.groups = groups;
          this.setMultipleProductNextScreenData();
        }, error: this.alertService.showApiError
      });
    this.configureGridColumns();
  }

  getGroups() {
    this.spinnerService.show();
    this.multiProductGroupService.getAll()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe((groups: Array<MultiProductGroup>) => {
        this.groups = groups;
      });
  }

  getMultiProductNextScreenData() {
    this.spinnerService.show();
    this.multiProductNextScreenService.getMultiProductNextScreenById(this.screenId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe((multiProductNextScreens: Array<MultiProductNextScreen>) => {
        this.multiProductNextScreens = multiProductNextScreens;
        this.setMultipleProductNextScreenData();
      });
  }

  private setMultipleProductNextScreenData() {
    this.multiProductNextScreens.forEach((screen) => {
      screen.Group = this.groups.find(x => x.Id == screen.MultiProductGroupId)?.Name;
      screen.NextScreen = this.screenList.find(x => x.Id == screen.MultiProductNextScreenId)?.Title;
    });
  }

  addGroup(isValid) {
    if (!isValid) {
      return;
    }

    if (_.find(this.multiProductNextScreens, (x) => {
      return x.MultiProductGroupId == this.multiProductNextScreen.MultiProductGroupId
        && x.MultiProductNextScreenId == this.multiProductNextScreen.MultiProductNextScreenId
    })) {
      this.alertService.renderErrorMessage(Messages.MultipleProductGroupMappingExist);
      return;
    }
    this.multiProductNextScreen.ScreenId = this.screenId;
    this.spinnerService.show();
    this.multiProductNextScreenService.insert(this.multiProductNextScreen)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.alertService.renderSuccessMessage(Messages.MultipleProductGroupMappingUpdated);
          this.multiProductNextScreen = this.newMultiProductNextScreen();
          this.getMultiProductNextScreenData();
          this.multipleProductForm.resetForm();
        }, error: this.alertService.showApiError
      });
  }
  newMultiProductNextScreen() {
    return { MultiProductGroupId: null, MultiProductNextScreenId: null, ScreenId: null, Id: 0, NextScreen: '', Group: '' } as MultiProductNextScreen;
  }

  protected confirmDelete(data): void {
    const confirmDelete = this.modalService.show(ConfirmDeleteModalComponent, {
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      initialState: {
        message: Messages.ConfirmDeleteGroupScreenMap
      }
    });
    confirmDelete.close.subscribe(res => {
      if (res && res.shouldDelete) {
        this.delete(data.Id);
      }
    });
  }
  delete(id) {
    this.spinnerService.show();
    this.multiProductNextScreenService.delete(id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe(() => {
        this.alertService.renderSuccessMessage(Messages.MultipleProductGroupMappingDeleted);
        this.getMultiProductNextScreenData();
      });
  }
  private configureGridColumns() {
    const deleteColumn = new DeleteColumn({
      Clicked: (data) => {
        this.confirmDelete(data);
      },
      Width: '60px'
    });
    this.multiProductNextScreenColumns = [
      new GridColumn({ HeaderText: 'Group', Field: 'Group', IsSortable: true }),
      new GridColumn({ HeaderText: 'Screen', Field: 'NextScreen', IsSortable: true }),
    ] as Array<GridColumn>;
    this.multiProductNextScreenColumns.push(deleteColumn);
  }

  editGroup(groupId) {
    if (!groupId) {
      return;
    }
    this.openEditGroupModel(groupId);
  }
  addNewGroup() {
    this.openEditGroupModel(0);
  }


  private openEditGroupModel(groupId) {
    const modal = this.modalService.show(MultiProductGroupComponent, {
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      initialState: {
        id: groupId,
        name: groupId > 0 ? this.groups.find(x => x.Id == this.multiProductNextScreen.MultiProductGroupId)?.Name :''
      }
    });
    modal.close.subscribe((res) => {
      this.getGroups();
      if (res.id) {
        this.multiProductNextScreen.MultiProductGroupId = res.id;
      }
    });
  }
}
