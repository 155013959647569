import { Injectable } from '@angular/core';
import { forkJoin, Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AccountAttributeTypes } from 'src/app/information-management/customers/account-attribute-types/interface/account-attribute-types';
import { SalesCategory } from 'src/app/information-management/sales-categories/interface/sales-category';
import { SalesGroup } from 'src/app/information-management/sales-groups/interface/sales-groups';
import { SalesProduct } from 'src/app/information-management/sales-products/interface/sales-product';
import { SalesProductsService } from 'src/app/information-management/sales-products/services/sales-products.service';
import { SpinnerService } from 'src/app/shared';
import { EnumNameValue } from 'src/app/shared/interface/enum-name-value';
import { SalesCategoryService } from 'src/app/shared/services/sales-category.service';
import { SalesGroupService } from 'src/app/shared/services/sales-group.service';
import { AccountAttributeTypeService } from 'src/app/information-management/accounts/account-attribute-types/services/account-attribute-type.service';
import { PromotionConfig, PromotionConfigParam, PromotionRule } from '../../interface';
import { HttpClient } from '@angular/common/http';
import { RuntimeConstants } from '../../constants';
import { forEach, isArray } from 'lodash';
@Injectable()
export class PromotionDataService {

  lastPromotionIndex: number = 0;
  dataSubject = new Subject<Promise<boolean>>();
  constructor(
    private salesGroupService: SalesGroupService,
    private salesCategoryService: SalesCategoryService,
    private salesProductService: SalesProductsService,
    private accountAttributeTypeService: AccountAttributeTypeService,
    private spinnerService: SpinnerService,
    private httpClient: HttpClient) {

  }
  salesGroups: Array<SalesGroup> = [];
  salesCategories: Array<SalesCategory> = [];
  salesProducts: Array<SalesProduct> = [];
  promotionPeriods: Array<EnumNameValue> = [];
  accountAttributeTypes: Array<AccountAttributeTypes> = [];

  loadData() {
    let observables: Array<Observable<any>> = [];
    observables.push(this.salesGroupService.getSalesGroups());
    observables.push(this.salesCategoryService.getSalesCategories(true));
    observables.push(this.salesProductService.getSalesProducts());
    observables.push(this.getPromotionPeriods());
    observables.push(this.accountAttributeTypeService.getAccountAttributeTypes());
    this.spinnerService.show();
    forkJoin(observables).pipe(
      finalize(() => {
        this.spinnerService.hide()
      })).subscribe({
        next: ([salesGroups, salesCategories, salesProducts, promotionPeriods, accountAttributeTypes]:
          [Array<SalesGroup>, Array<SalesCategory>, Array<SalesProduct>, Array<EnumNameValue>, Array<AccountAttributeTypes>]) => {
          this.salesGroups = salesGroups;
          this.salesCategories = salesCategories;
          this.salesProducts = salesProducts;
          this.promotionPeriods = promotionPeriods;
          this.accountAttributeTypes = accountAttributeTypes;
          this.dataSubject.next(Promise.resolve(false));
        }
      });
  }

  getPromotionPeriods(): Observable<Array<EnumNameValue>> {
    return this.httpClient.get<Array<EnumNameValue>>(`${RuntimeConstants.API_PATH}promotion/periods`);
  }

  get data$(): Observable<Promise<boolean>> {
    return this.dataSubject.asObservable();
  }

  newPromotionConfig(name = null): PromotionConfig {
    return {
      Name: name,
      DisplayName: null,
      Config: [],
      Categories: null,
      IsOperator: false,
      Description: null
    }
  }

  validateRuleAndReward(rules: Array<PromotionRule>, rewards: PromotionConfig[]) {
    this.validateRule(rules);
    forEach(rewards, adjustment => {
      forEach(adjustment.Config, config => {
        if (!config.IsRequired) {
          config.IsInvalid = false;
          config.Value = isArray(config.Value) && !config.Value.length ? null : config.Value;
          this.validateDependedConfig(adjustment.Config, config);
        }
      });
    });
  }

  private validateRule(rules: PromotionRule[]) {
    forEach(rules, (rule: PromotionRule) => {
      if (rule.Config?.some(x => x.DependOn)) {
        forEach(rule.Config, config => {
          if (!config.IsRequired) {
            config.IsInvalid = false;
            this.validateDependedConfig(rule.Config, config);
          }
        });
      } else if (rule.Rules?.length) {
        this.validateRule(rule.Rules);
      }
    });
  }

  validateDependedConfig(config: Array<PromotionConfigParam>, configParam: PromotionConfigParam) {
    const childConfig = config.find(c => c.DependOn == configParam.Name);
    if ((isArray(configParam.Value) ? !configParam.Value.length : !configParam.Value) && childConfig?.DependOn) {
      this.validateDependedConfig(config, childConfig);
      return;
    }
    if ((isArray(configParam.Value) ? !configParam.Value.length : !configParam.Value) && configParam?.DependOn) {
      configParam.IsInvalid = this.checkIfParentIsEmpty(config, configParam);
    }
  }

  checkIfParentIsEmpty(config: Array<PromotionConfigParam>, configParam: PromotionConfigParam) {
    const parentConfig = config.find(c => c.Name == configParam.DependOn);
    return (isArray(parentConfig.Value) ? !parentConfig.Value.length : !parentConfig.Value) && (parentConfig?.DependOn ? this.checkIfParentIsEmpty(config, parentConfig) : true);
  }

  isEligibilityValid(rule: PromotionRule): boolean {
    return rule.Condition
      && (!rule.Rules.length
        || rule.Rules.some(ruleChild => !ruleChild.Condition
          && (!ruleChild.Name || ruleChild.Config.some(c => (isArray(c.Value) ? !c.Value.length : (typeof (c.Value) == 'object' ? (!c.Value?.Name || !c.Value?.Value) : !c.Value)) && (c.IsRequired || c.IsInvalid)))
          || this.isEligibilityValid(ruleChild))
      );
  }
}
