<pos-list-wrapper [headingIcon]="icons.infoManageBullHorn" heading="Audio Notifier - Audio Clips" (close)="close()">
  <div class="row">
    <div class="col-12">
      <ng-table #gridComponent [columns]="audioNotificationColumns" [exportFilename]="'audio-notifier-audio-clips'"
        [data]="audioNotificationFiles">
      </ng-table>
    </div>
  </div>
</pos-list-wrapper>
<ng-template #playAudioTemplate let-data="rowData">
  <div (click)="playAudio(data)">
    <pos-action-icon [icon]="icons.playGreenNormalFont" *ngIf="!data.IsPlaying"></pos-action-icon>
    <pos-action-icon [icon]=" icons.pauseGreen" *ngIf="data.IsPlaying"></pos-action-icon>
  </div>
</ng-template>
<pos-confirm-delete [message]="message" (onConfirmAcknowledge)="onConfirmAcknowledge()" #confirmAudioNotificationDelete>
</pos-confirm-delete>
<pos-print-table #printTableReference [hidden]="true"></pos-print-table>
