import { Component, OnInit, ViewChild } from '@angular/core';
import {
  Permissions,
  Levels,
  ModalService,
  ConfirmDeleteComponent,
  SpinnerService,
  AlertsService,
  Messages,
  AuthenticationService,
  BaseGridComponent,
  ApplicationStateService,
  unitsThLarge
} from 'src/app/shared';
import { GridColumn, EditColumn, LinkColumn, DeleteColumn, TableComponent } from '@tarktech/tark-ng-utils';
import { UnitService } from '../../services/unit.service';
import { Unit } from '../../interface';
import { UnitEditComponent } from '../unit-edit';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';

@Component({
  selector: 'pos-units-list',
  templateUrl: './units-list.component.html',
  styleUrls: ['./units-list.component.scss']
})
export class UnitsListComponent extends BaseGridComponent implements OnInit {

  @ViewChild('confirmDeleteOption') private confirmDeleteOption: ConfirmDeleteComponent;
  @ViewChild('unitModal') private unitModal: any;
  // @ViewChild('printTableRefrence') printTableRefrence: PrintTableComponent;
  unitListColumns: Array<GridColumn> = [];
  printUnitListColumns: Array<GridColumn> = [];
  editUnitId: number;
  unitId: number;
  unitDetailsList: Array<Unit> = [];
  modalRef: BsModalRef;
  @ViewChild('unitGrid', {static: true}) unitGrid: TableComponent;
  constructor(private modalService: ModalService,
    private spinnerService: SpinnerService,
    private unitService: UnitService,
    private alertsService: AlertsService,
    private authenticationService: AuthenticationService,
    protected applicationStateService: ApplicationStateService,
    protected route: ActivatedRoute,
    protected router: Router,
    private printService: PrintTableService) {
      super(applicationStateService, route);
    }

  permission = {
    InventoryStorageUnits: Permissions.InventoryStorageUnits
  };

  accessLevels = {
    ReadOnly: Levels.ReadOnly,
    Edit: Levels.Edit
  };
  public icons = {
    unitsThLarge
  };

  ngOnInit() {
    this.configureGridColumns();
    this.reloadUnitsList();
    this.unitGrid.context = this.gridContext;
  }

  configureGridColumns() {
    const editColumn = new EditColumn({
      Clicked: (data) => {
        this.editUnit(data.Id);
      },
      Width: '57px',
      AddIcon: new LinkColumn({
        Clicked: () => {
          this.editUnit(0);
        },
        ToolTip: 'New'
      })
    });

    const deleteColumn = new DeleteColumn({
      Clicked: (data) => {
        this.deleteUnit(data);
      },
      Width: '83px',
      PrintIcon: new LinkColumn({
        Clicked: () => {
          this.printData();
        }
      })
    });

    this.unitListColumns = [
      new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '95%', CellClass: 'tableStyleClass' })
    ] as Array<GridColumn>;
    this.printUnitListColumns = [...this.unitListColumns];
    if (this.authenticationService.userHasPermission([{ Name: this.permission.InventoryStorageUnits, Level: this.accessLevels.Edit }],
      'any')) {
      this.unitListColumns.push(editColumn);
      this.unitListColumns.push(deleteColumn);
    }
  }

  printData() {
    this.printService.printEmitter.next({ gridColumns: this.printUnitListColumns, gridData: this.unitDetailsList });
  }

  editUnit(unitIdEdit: number) {
    this.editUnitId = unitIdEdit;
    const modelRefUnit = this.modalService.show(UnitEditComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        unitId: this.editUnitId
      }
    });
    modelRefUnit.close.subscribe(res => {
      if (res && res.shouldReload) {
        this.onSave();
      } else {
        this.onSaveCancel();
      }
    },
      (erro) => { const etst = erro; });
  }

  deleteUnit(data: Unit) {
    this.unitId = data.Id;
    this.confirmDeleteOption.Show(StringUtils.format(Messages.ConfirmDeleteUnit, { 'unitName': data.Name }));
  }

  onConfirmAcknowledge() {
    this.spinnerService.show();
    this.unitService.deleteUnit(this.unitId).subscribe(res => {
      this.alertsService.renderSuccessMessage(Messages.UnitDeleted);
      this.reloadUnitsList();
    },
      (error) => { this.alertsService.showApiError(error); },
      () => { this.spinnerService.hide(); });
  }

  reloadUnitsList() {
    this.spinnerService.show();
    this.unitService.getUnits().subscribe(res => {
      this.unitDetailsList = (res === null ? [] : res);
    },
      this.alertsService.showApiError,
      () => { this.spinnerService.hide(); });
  }

  onSave() {
    this.alertsService.renderSuccessMessage(Messages.UnitSaveSuccess);
    this.resetUnitForm();
    this.reloadUnitsList();
  }

  resetUnitForm() {
    this.editUnitId = 0;
  }


  onSaveCancel() {
    this.resetUnitForm();
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
