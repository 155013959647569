<pos-edit-wrapper (close)="cancel()" heading="Task Schedule">
  <form #taskScheduleForm="ngForm" autocomplete="off" class="col-xs-12 p-0" (submit)="applyScheduleChanges()">
    <div class="p-pix-10 pb-0 screen-scroll text-center">
      <div class="display-flex justify-flex">
        <div class="bold" *ngFor="let day of daysOfWeek">
          {{ day.Name }}
        </div>
      </div>
      <div class="display-flex justify-flex" *ngIf="isTaskGroupCreated">
        <div *ngFor="let taskGroup of completeTaskList; let i = index;" class="display-flex flex-flow-column">
          <div *ngFor="let task of taskGroup; let j = index;">
            <p-checkbox class="pt-pix-4" name="chkIsCheck_{{i}}{{j}}" #chkIsCheck_{{i}}{{j}}="ngModel"
              [(ngModel)]="task.isCheck" binary="true" [label]="task.hourText">
            </p-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="col-12 action-button-container popup-footer">
      <button class="btn-primary btn-lg btn btn-save pos-button" type="submit" value="Save">Apply Changes</button>
      <button class="btn-secondary btn-lg btn btn-cancel-modal pos-button" (click)="cancel()" type="button" value="Cancel">Cancel
      </button>
    </div>
  </form>
</pos-edit-wrapper>
