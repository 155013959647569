<pos-edit-wrapper (close)="onCancel()" heading="Schedule Override">
    <form #scheduleOverrideForm="ngForm" (ngSubmit)='saveScheduleOverride(scheduleOverrideForm.valid)' [posValidate]="validateTime">
        <div class="col-12 px-4">
            <pos-row>
                <pos-column>
                    <pos-form-field label="From" [validationMessages]="{required: 'Please enter from date.'}">
                        <p-calendar posInput inputStyleClass="form-control" [showIcon]="true" appendTo="body" name="fromDate"
                        [dateFormat]="dateFormat" [(ngModel)]="scheduleOverrides.FromDate" #fromDate="ngModel" [required]="true"
                        [monthNavigator]="true" (onSelect)="onDateChange()" (onInput)="onDateChange()"
                        yearNavigator="true" yearRange="2000:2099"></p-calendar>
                    </pos-form-field>
                </pos-column>

                <pos-column>
                    <pos-form-field label="To" [validationMessages]="{required: 'Please enter to date.'}" [inputStyle]="{'col-12': true,'has-error': scheduleOverrideForm.submitted && (inValidFromDate || inValidToDate)}">
                        <p-calendar posInput inputStyleClass="form-control" [showIcon]="true" appendTo="body" name="toDate"
                            [dateFormat]="dateFormat" [(ngModel)]="scheduleOverrides.ToDate" [monthNavigator]="true"
                            [required]="true" [minDate]="scheduleOverrides.FromDate" (onSelect)="onDateChange()"
                            (onInput)="onDateChange()" #toDate="ngModel" yearNavigator="true" yearRange="2000:2099">
                        </p-calendar>
                        <span *ngIf="(inValidFromDate || inValidToDate) && scheduleOverrideForm.submitted">
                            <label class="control-label ">Period overlaps with another override ({{conflictingSchedule?.FromDate | tark_date}}
                                to {{conflictingSchedule?.ToDate | tark_date}}) </label>
                        </span>
                    </pos-form-field>
                </pos-column>

                <pos-column>
                    <pos-form-field inputStyle="col-12">
                        <div class="btn-group">
                            <label class="btn btn-radio"
                                [ngClass]="scheduleStatus === true? 'active btn-primary normal' : 'btn-secondary'">
                                <input posInput type="radio" name="onlineOrdering" class="form-check-input"
                                    #overridePickUpTime="ngModel" [(ngModel)]="scheduleStatus" value="true "
                                    (click)="scheduleStatus = true"> Override Pickup Time
                            </label>
                            <label class="btn btn-radio"
                                [ngClass]="scheduleStatus === false ? 'active btn-primary normal' : 'btn-secondary'">
                                <input type="radio" name="onlineOrdering" class="form-check-input" #markAsOrder="ngModel"
                                    [(ngModel)]="scheduleStatus" value="false" (click)="scheduleStatus = false"> Mark Closed
                            </label>
                        </div>
                    </pos-form-field>
                </pos-column>

                <pos-column *ngIf="scheduleStatus">
                    <pos-form-field label="Start Time" [validationMessages]="{required: 'Please enter start time.'}">
                        <input posInput type="time" #startTime="ngModel" class="form-control" name="startTime"
                            [required]="scheduleStatus"
                            [(ngModel)]="scheduleOverrides.StartTime" />
                    </pos-form-field>
                </pos-column>

                <pos-column *ngIf="scheduleStatus">
                    <pos-form-field label="End Time" [validationMessages]="{required: 'Please enter end time.', inValidScheduleStatusTime: 'Start Time must be less than End Time.'}">
                        <input posInput type="time" #endTime="ngModel" class="form-control" name="endTime"
                            [(ngModel)]="scheduleOverrides.EndTime" [required]="scheduleStatus"/>
                        <span *ngIf="inValidScheduleStatusTime && scheduleOverrideForm.submitted">
                            <label class="control-label ">Start Time must be less than End Time.</label>
                        </span>
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" scrollup value="Save">Save</button>
            <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" value="Cancel"
                (click)="onCancel()">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
