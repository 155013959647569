import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { RuntimeConstants, Configurations, ApplicationStateService, SettingParam } from 'src/app/shared';
import {
  thLarge,
  terminal
} from 'src/app/shared/components/icon';
import { OrderNavigationComponent } from '../..';
declare let $: any;
@Component({
  selector: 'pos-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  icons = {
    thLarge,
    terminal
  };
  isShowCliTerminal: boolean = false;
  productShow: boolean = false;
  headerLinks = {
    tasks: true,
    home: true,
    warning: true,
    admin: true,
    time: true,
    activeOrders: true,
    signIn: true,
    mySubscriptions: true
  };
  configurations: Configurations;
  settingParam: SettingParam;
  windowHeight: any;
  tabList = {
    ButtonView: 'ButtonView',
    CliTerminal: 'CliTerminal'
  };
  selectedTab = this.tabList.ButtonView;
  orderId: number = 0;
  @ViewChild('orderNavigation') orderNavigation: OrderNavigationComponent;
  
  constructor(private applicationStateService: ApplicationStateService, private router: Router) {
    const navigation = router.getCurrentNavigation();
    this.orderId = (navigation?.extras?.state && navigation?.extras?.state.orderId) ?? 0;
   }
  imagePath: string = RuntimeConstants.IMAGE_BASE_PATH;
  ngOnInit() {
    this.setDefault();
    this.windowHeight = $(window).width();
    $(window).resize(() => {
      this.windowHeight = $(window).width();
    });
  }

  setDefault() {
    this.configurations = this.applicationStateService.configurations;
    this.settingParam = this.applicationStateService.settingParam;
  }

  scanBarcode(barcode: string) {
    this.orderNavigation.getProductByBarcode(barcode);
  }

}
