import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { PickScreenWorkflowParameter } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class PickScreenWorkflowService {

  PickScreenWorkflowApiBasePath = `${RuntimeConstants.API_PATH}pickScreen-workflow-configuration`;
  constructor(private httpService: HttpClient) { }

  newPickScreenWorkflowConfigurationParameter(): PickScreenWorkflowParameter {
    return {
      ShowNonDefaultQuantityOnPickScreen: false,
      PickScreenHighlightItemColor: '',
      PickScreenHighlightItemDuration: 0,
    }
  }

  getPickScreenWorkflowParameters(): Observable<PickScreenWorkflowParameter> {
    return this.httpService.get<PickScreenWorkflowParameter>(`${this.PickScreenWorkflowApiBasePath}`);
  }

  updatePickScreenWorkflowParameters(PickScreenWorkflowParameter: PickScreenWorkflowParameter) {
    return this.httpService.post<PickScreenWorkflowParameter>(`${this.PickScreenWorkflowApiBasePath}`, PickScreenWorkflowParameter);
  }
}
