<pos-edit-wrapper [isPopup]="false"
    heading="{{id ? 'Edit ' : 'Add' }} {{promotionType.DisplayName}}{{id ? ': ' + promotion.Name : ''}}"
    [headingIcon]="icon" (close)="cancel()">
    <form #formPromotionConfig="ngForm" (ngSubmit)="validatePromotionDetails()">
        <context-provider provide="props">
            <div class="col-12 container-fluid border-top">
                <ul class="nav nav-tabs inner-tab pos-nav-tabs pt-pix-5">
                    <li [ngClass]="{'active': selectedTab == tabList.Info }">
                        <a (click)="selectedTab = tabList.Info" class="blue" [ngClass]="{'required': info.invalid }">
                            <span>
                                <pos-icon class="theme-separator pe-pix-5" [icon]="icons.infoCircle"></pos-icon>
                            </span>
                            <span>Info</span>
                        </a>
                    </li>
                    <li [ngClass]="{'active': selectedTab == tabList.Schedule }">
                        <a (click)="selectedTab = tabList.Schedule" class="blue"
                            [ngClass]="{'required': schedule.invalid}">
                            <span class="pe-pix-5">
                                <pos-icon class="theme-separator" [icon]="icons.clock"></pos-icon>
                            </span> <span>Schedule</span> </a>
                    </li>
                    <li [ngClass]="{'active': selectedTab == tabList.Criteria }">
                        <a (click)="selectedTab = tabList.Criteria" class="blue"
                            [ngClass]="{'required': criteriaModelGroup.invalid || isAnyEligibilityOrConfigEmpty}">
                            <span class="pe-pix-5">
                                <pos-icon class="theme-separator" [icon]="icons.clipboardList"></pos-icon>
                            </span> <span>Eligibility</span> </a>
                    </li>
                    <li [ngClass]="{'active': selectedTab == tabList.Rewards }">
                        <a (click)="selectedTab = tabList.Rewards" class="blue"
                            [ngClass]="{'required': rewardsModelGroup.invalid || isAnyRewardOrConfigEmpty}">
                            <span class="pe-pix-5">
                                <pos-icon class="theme-separator" [icon]="icons.coins"></pos-icon>
                            </span> <span>Rewards</span> </a>
                    </li>
                </ul>
                <div class="overflow-auto-tabs screen-scroll pos-tab-body min-height pt-pix-5">
                    <div [hidden]="selectedTab != tabList.Info" ngModelGroup="info" #info="ngModelGroup">
                        <pos-row>
                            <pos-column>
                                <pos-form-field label="Name" [validationMessages]="{required: 'Please enter name.'}">
                                    <input posInput name="productName" id="productName" #productName="ngModel"
                                        type="text" class="form-control" [(ngModel)]="promotion.Name"
                                        [required]="true" />
                                </pos-form-field>
                            </pos-column>
                            <pos-column>
                                <pos-form-field>
                                    <p-checkbox posInput name="active" #active="ngModel"
                                        [(ngModel)]="promotion.IsActive" binary="true" label="Active">
                                    </p-checkbox>
                                </pos-form-field>
                            </pos-column>
                            <pos-column>
                                <pos-form-field label="Type"
                                    [validationMessages]="{required: 'Please select promotion type.'}">
                                    <ng-select name="promotionType" posInput [required]="true" [items]="promotionTypes"
                                        bindLabel="Name" bindValue="Id" appendTo="body" class="col-250"
                                        [(ngModel)]="promotion.PromotionTypeId" placeholder="Select promotion type">
                                    </ng-select>
                                </pos-form-field>
                            </pos-column>
                        </pos-row>
                    </div>
                    <div [hidden]="selectedTab != tabList.Schedule" class="pt-pix-5" ngModelGroup="schedule"
                        #schedule="ngModelGroup">
                        <pos-row>
                            <pos-column>
                                <pos-form-field>
                                    <p-checkbox posInput name="buttonDateCheckbox" #buttonDateCheckbox="ngModel"
                                        [(ngModel)]="isDateScheduled" binary="true" label="Dates">
                                    </p-checkbox>
                                </pos-form-field>
                            </pos-column>

                            <pos-column class="ps-pix-20">
                                <pos-row>
                                    <pos-column xs="6" class="col-220">
                                        <pos-form-field [validationMessages]="{ required: 'Please enter start date.'}"
                                            inputStyle="col-12">
                                            <p-calendar posInput #startDate="ngModel" [readonlyInput]="true"
                                                [dateFormat]="dateFormat" [required]="isDateScheduled"
                                                placeholder="Start date" appendTo="body" inputStyleClass="form-control"
                                                [monthNavigator]="true" [showIcon]="true" name="startDate"
                                                [(ngModel)]="promotion.StartDate" [disabled]="!isDateScheduled"
                                                [minDate]="minDate" (onSelect)="startDateChanged()">
                                            </p-calendar>
                                        </pos-form-field>
                                    </pos-column>
                                    <pos-column xs="6" class="col-220">
                                        <pos-form-field inputStyle="col-12">
                                            <p-calendar posInput #endDate="ngModel" [readonlyInput]="true"
                                                [dateFormat]="dateFormat" placeholder="End date"
                                                inputStyleClass="form-control" [monthNavigator]="true" [showIcon]="true"
                                                name="endDate" [(ngModel)]="promotion.EndDate" id="endDate"
                                                [minDate]="promotion.StartDate" appendTo="body"
                                                [disabled]="!isDateScheduled">
                                            </p-calendar>
                                        </pos-form-field>
                                    </pos-column>
                                </pos-row>
                            </pos-column>

                            <pos-column>
                                <pos-form-field>
                                    <p-checkbox posInput name="timeCheckbox" #timeCheckbox="ngModel" class="pt-pix-10"
                                        [(ngModel)]="isTimeScheduled" binary="true" label="Time">
                                    </p-checkbox>
                                </pos-form-field>
                            </pos-column>

                            <pos-column class="ps-pix-20">
                                <pos-row>
                                    <pos-column xs="6" class="col-200">
                                        <pos-form-field [validationMessages]="{ required: 'Please enter start time.'}"
                                            inputStyle="col-12">
                                            <input posInput type="time" placeholder="HH:mm:ss"
                                                class="form-control col-170" name="startTime" #startTime="ngModel"
                                                [(ngModel)]="promotion.StartTime" [required]="isTimeScheduled"
                                                [disabled]="!isTimeScheduled">
                                        </pos-form-field>
                                    </pos-column>
                                    <pos-column xs="6" class="col-200">
                                        <pos-form-field [validationMessages]="{ required: 'Please enter end time.'}"
                                            inputStyle="col-12">
                                            <input posInput type="time" placeholder="HH:mm:ss"
                                                class="form-control col-170" name="endTime" #endTime="ngModel"
                                                [(ngModel)]="promotion.EndTime" [required]="isTimeScheduled"
                                                [disabled]="!isTimeScheduled">
                                        </pos-form-field>
                                    </pos-column>
                                </pos-row>
                            </pos-column>

                            <pos-column class="pt-pix-10">
                                <pos-form-field>
                                    <p-checkbox posInput name="daysCheckbox" #daysCheckbox="ngModel" class="pt-pix-10"
                                        [(ngModel)]="isDaysScheduled" binary="true" label="Days">
                                    </p-checkbox>
                                </pos-form-field>
                            </pos-column>

                            <pos-column class="ps-pix-20">
                                <pos-form-field [validationMessages]="{required: 'Please select day(s).'}">
                                    <ng-select posInput appendTo="body" [items]="daysOfWeek" bindLabel="Name"
                                        bindValue="Id" [multiple]="true" [hideSelected]="true"
                                        placeholder="Select day(s)" [(ngModel)]="selectedDays" id="days" name="days"
                                        [required]="isDaysScheduled" [disabled]="!isDaysScheduled">
                                    </ng-select>
                                </pos-form-field>
                            </pos-column>
                        </pos-row>
                    </div>
                    <div [hidden]="selectedTab != tabList.Criteria" class="pb-pix-10" ngModelGroup="criteriaModelGroup"
                        #criteriaModelGroup="ngModelGroup">
                        <pos-criteria [operators]="operators" [criteries]="criteria" [(newRuleModel)]="newRuleModel">
                        </pos-criteria>
                    </div>
                    <div [hidden]="selectedTab != tabList.Rewards" class="pt-pix-5" ngModelGroup="rewardsModelGroup"
                        #rewardsModelGroup="ngModelGroup">
                        <pos-reward [rewards]="rewards" [configuredRewards]="configuredRewards"></pos-reward>
                    </div>
                </div>
            </div>
            <div class="col-12 action-button-container page-footer">
                <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" value="Save">Save</button>
                <button type="button" class="btn-secondary btn-lg btn btn-cancel pos-button" value="Cancel"
                    (click)="cancel()">Cancel</button>
            </div>
        </context-provider>
    </form>
</pos-edit-wrapper>
