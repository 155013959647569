import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { User } from 'src/app/information-management/users';
import { UserEntity } from 'src/app/information-management/users/interface/user-entity';
import { ModalFormComponent } from 'src/app/shared/components/modal-form/modal-form.component';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { FormUtilityService } from 'src/app/shared/services/form-utility.service';

@Component({
  selector: 'pos-select-user',
  templateUrl: './select-user.component.html',
  styleUrls: ['./select-user.component.scss']
})
export class SelectUserComponent extends ModalFormComponent implements OnInit {

  userId = null;
  @Input() users: Array<UserEntity> = [];
  @ViewChild('selectUserForm', { static: true }) form: NgForm;
  @ViewChild('userSelection') userSelection: NgSelectComponent;
  constructor(modalRef: BsModalRef,
    modalService: ModalService,
    formUtilityService: FormUtilityService) {
    super(modalRef, modalService, formUtilityService);

  }
  ngOnInit() {

  }
  get getForm(): NgForm {
    return this.form;
  }
  selectUser(selectedUserId) {
    this.hide({ userId: selectedUserId });
  }

  cancel() {
    this.hide({});
  }
}
