<pos-list-wrapper [headingIcon]="icons.hardwareInterfacePlug" heading="Export Connections" (close)="close()">
    <div class="row">
        <div class="col-12">
            <ng-table #exportConnectionTable [columns]="exportConnectionColumns" [data]="exportConnectionList"
                [exportFilename]="'export-connection'">
            </ng-table>
        </div>
    </div>
</pos-list-wrapper>
<pos-print-table [hidden]="true"></pos-print-table>
