<pos-edit-wrapper heading="{{title}}" (close)="closeWidget()">
    <form #widgetEditForm="ngForm" (ngSubmit)="save(widgetEditForm.valid)">
        <div class="pos-modal pb-pix-3 screen-scroll">
            <div class="row mx-0">
                <div class="row mx-0">
                    <div class="col-sm-6 col-xs-12">
                        <pos-form-field label="Title" [validationMessages]="{ required: 'Please enter title.'}" inputStyle="col-md-8 col-xs-12">
                            <input posInput type="text" name="title" class="form-control" [(ngModel)]="widgetConfig.title"
                                placeholder="Enter title" [required]="true">
                        </pos-form-field>
                    </div>

                    <div class="col-sm-6 col-xs-12">
                        <pos-form-field label="Auto Refresh Interval (minutes)">
                            <input posInput type="number" name="refreshInterval" posOnlyNumbers class="form-control col-120"
                                [(ngModel)]="widgetConfig.AutoRefreshInterval">
                        </pos-form-field>
                    </div>
                </div>
                <pos-text-widget-edit [(widgetConfig)]="widgetConfig" *ngIf="widgetConfig.type === 'text_widget'">
                </pos-text-widget-edit>
                <pos-chart-widget-edit [(widgetConfig)]="widgetConfig" *ngIf="widgetConfig.type === 'chart_widget'">
                </pos-chart-widget-edit>
                <pos-gauge-widget-edit [(widgetConfig)]="widgetConfig" *ngIf="widgetConfig.type === 'gauge_widget'">
                </pos-gauge-widget-edit>
                <pos-table-widget-edit [(widgetConfig)]="widgetConfig" *ngIf="widgetConfig.type === 'table_widget'">
                </pos-table-widget-edit>
                <pos-iframe-widget-edit [(widgetConfig)]="widgetConfig" *ngIf="widgetConfig.type === 'iframe'">
                </pos-iframe-widget-edit>
            </div>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn btn-primary btn-lg btn-save pos-button">
                Apply
            </button>
            <button type="button" class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="closeWidget()">
                Cancel
            </button>
        </div>
    </form>
</pos-edit-wrapper>
