<pos-edit-wrapper (close)="closeModal()" heading="{{selectedButtonBehaviorType.Text}}">
    <form #sizeSelectionForm="ngForm" autocomplete="off" (submit)='saveBehavior(sizeSelectionForm.valid)'>
        <div class="col-12 pos-modal-body screen-scroll">
            <pos-row>
                <pos-column>
                    <pos-form-field label="Size"
                        [validationMessages]="{required: 'Please select size.'}">
                        <ng-select posInput [items]="sizes" placeholder="Select size"
                            name="products" bindLabel="Name" bindValue="Id" [(ngModel)]="sizeId"
                            #sizeList="ngModel" appendTo="body" [required]="true">
                        </ng-select>
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save</button>
            <button type="button" (click)="closeModal()" class="btn-secondary btn-lg btn btn-cancel-modal pos-button">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
