import { Component, OnInit, Input } from '@angular/core';
import { AlertsService } from 'src/app/shared';
import { InventoryProductService } from 'src/app/shared/services/inventory-product.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'pos-show-consumption',
  templateUrl: './show-consumption.component.html',
  styleUrls: ['./show-consumption.component.scss']
})
export class ShowConsumptionComponent implements OnInit {
  @Input() inventoryProductId: number;
  isLoad: boolean = false;
  details: Array<any> = [];
  constructor(private alertService: AlertsService,
    private inventoryProductService: InventoryProductService) { }

  ngOnInit() {
    this.getDetails();
  }

  getDetails() {
    this.isLoad = true;
    this.details = [];
    this.inventoryProductService.getInventoryProductConsumption(this.inventoryProductId)
      .pipe(finalize(() => {
        this.isLoad = false;
      }))
      .subscribe({next:(res: any) => {
        if (res) {
          this.details = res;
        }
      }, error: this.alertService.showApiError });
  }

}

