import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';
import { Observable } from 'rxjs';
import { InventoryProductRecipe } from '../../information-management/inventory-products/interface/inventory-product-recipe';
import { RecordInventoryPreparation } from 'src/app/manage-console/inventory/inventory-prep/interfaces/record-inventory-preparation';

@Injectable()
export class InventoryProductRecipeService extends BaseCrudService<InventoryProductRecipe> {

    inventoryProductRecipeApiUrl = '';
    constructor(httpService: HttpClient) {
        super('inventory-product-prep-recipe', httpService);
        this.inventoryProductRecipeApiUrl = RuntimeConstants.API_PATH + 'inventory-product-prep-recipe';
    }

    newInventoryProductRecipe() {
        return {
            Id: 0,
            MainInventoryProductId: null,
            InventoryProductId: null,
            ProductName: '',
            Qty: null,
            Instructions: '',
            UnitName: '',
            SizeName: '',
            Cost: null,
            PrepareQty: 1,
            CalculatedQty: null,
            UsedPrepQty: null,
            IsAvailableQty: false
        };
    }

    newRecordInventoryPreparation() {
        const recordInventoryPreparation: RecordInventoryPreparation = {
            InventoryProductId: null,
            Qty: null,
            Name: '',
            IsPrintPrepLabel: false,
            TerminalId: 0,
            TerminalName: '',
            IsPrintPrepLabels: false
        };
        return recordInventoryPreparation;
    }

    getInventoryProductRecipe(inventoryProductId: number): Observable<any> {
        return this.httpService.get(this.inventoryProductRecipeApiUrl + '/' + inventoryProductId);
    }

    saveInventoryProductRecipe(inventoryProductRecipe: InventoryProductRecipe): Observable<any> {
        return this.httpService.post(this.inventoryProductRecipeApiUrl, inventoryProductRecipe);
    }
}
