<div class="page-content ps-pix-10 pe-pix-10 full-height">
    <div class="tab-content">
        <div class="tab-pane fade in active show">
            <div class="col-12 container-fluid">
                <button class="close pe-pix-0" (click)="cancel()">&times;</button>
                <ul class="nav nav-tabs inner-tab pos-nav-tabs">
                    <li [ngClass]="{'active': selectedTab == tabList.ServiceStatus }">
                        <a (click)="selectedTab = tabList.ServiceStatus">
                            Status</a>
                    </li>
                    <li [ngClass]="{'active': selectedTab == tabList.ActivityStream }">
                        <a (click)="selectedTab = tabList.ActivityStream">
                            Activity Stream</a>
                    </li>
                </ul>
            </div>
            <div class="tab-content pos-tab-body">
                <div *ngIf="services.length && selectedTab == tabList.ServiceStatus">
                    <pos-service-status [services]="services"></pos-service-status>
                </div>
                <div *ngIf="selectedTab == tabList.ActivityStream">
                    <pos-activity-stream [activities]="activities" [services]="services"></pos-activity-stream>
                </div>
            </div>
            <div class="col-xs-12 action-button-container page-footer">
                <button type="button" class="btn btn-lg btn-secondary btn-cancel-modal pos-button" (click)="cancel()">Cancel</button>
            </div>
        </div>
    </div>
</div>
