<pos-list-wrapper [headingIcon]="icons.infoManageShoppingCart" heading="Online Ordering - Categories" (close)="close()">
  <div class="col-12 p-0 remove-top-margin">
    <ng-table #orderingCategoryGrid [sort]="false" [paginator]="false" [columns]="orderingCategoriesColumns"
      [data]="orderingCategories" [isReorderableTable]="true" [ReorderableColumnRow]="'row'"
      [exportFilename]="'online-ordering-categories'" (onRowReorder)="gridRowReorder($event)">
    </ng-table>
    <ng-template let-data="rowData" #categoryIconTemplate>
      <span style="font-size: 13px" *ngIf="(data.Icon)" class="green text-center vertical-mid">
        <pos-icon [icon]="icons.check"></pos-icon>
      </span>
    </ng-template>
    <ng-template let-data="rowData" #categoryActiveTemplate>
      <span style="font-size: 13px" *ngIf="(data.IsActive)" class="green text-center vertical-mid">
        <pos-icon [icon]="icons.check"></pos-icon>
      </span>
    </ng-template>
  </div>
</pos-list-wrapper>
<pos-confirm-delete (onConfirmAcknowledge)="onConfirmAcknowledge()" #confirmDeleteCategory>
</pos-confirm-delete>
<pos-print-table #printTableReference [hidden]="true"></pos-print-table>
