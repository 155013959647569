<pos-edit-wrapper heading="Branding" [headingIcon]="icons.branding" (close)="cancelEditing()" [isPopup]="false">
  <form #brandingForm="ngForm" class="edit-form" (ngSubmit)="saveBranding(brandingForm.valid)">
    <div class="col-12 pos-page-body border-top">
      <div class="col-12">
        <div>
          <h3 class="setting-title">Settings</h3>
        </div>
        <pos-row class="pos-title-content">
          <pos-column>
            <pos-form-field label="Company Name" [fieldInfo]="fieldInfoMessages.BrandingCompanyName"
              [validationMessages]="{required: 'Please enter Company Name.'}">
              <input posInput class="form-control" name="txtCustomerName" [(ngModel)]="parameters.CustomerName"
                [required]="true" type="text" #txtCustomerName="ngModel" />
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Logo (.png)" [fieldInfo]="fieldInfoMessages.BrandingLogo">
              <div class="form-control height-auto">
                <input posInput (change)="uploadLogoFile($event.target.files)" type="file"
                  class="form-control col-400 display-initial">
                <img [src]="logoPath" class="edit-form__section__file-img ms-pix-20" #logo id="logo" />
              </div>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Receipt logo (.jpg or .bmp)" [fieldInfo]="fieldInfoMessages.BrandingReceiptLogo">
              <div class="form-control height-auto">
                <input posInput name="ReceiptLogoFile" (change)="uploadFile($event.target.files)" type="file"
                  class="form-control col-400 display-initial">
                <img [src]="receiptLogoPath" class="edit-form__section__file-img ms-pix-20" #receiptLogo id="receiptLogo" />
              </div>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Receipt Footer" [fieldInfo]="fieldInfoMessages.BrandingReceiptFooter"
              [validationMessages]="{required: 'Please enter Receipt Footer.'}">
              <textarea posInput class="form-control overflow-auto h-100" name="txtReceiptFooter"
                [(ngModel)]="parameters.ReceiptFooter1" [required]="true" #txtReceiptFooter="ngModel"
                rows="3"></textarea>
            </pos-form-field>
          </pos-column>
        </pos-row>
      </div>
    </div>
    <div class="col-xs-12 action-button-container page-footer">
      <div class="form-horizontal">
        <button class="btn btn-primary btn-lg btn-save pos-button" scrollup type="submit" value="Save">Save</button>
        <button class="btn btn-secondary btn-lg btn-cancel pos-button" routerLink="../" scrollup type="button"
          value="Cancel">Cancel</button>
      </div>
    </div>
  </form>
</pos-edit-wrapper>
