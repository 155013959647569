import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { ScheduleOrderActiveHoursOverride } from 'src/app/orders/order-entry/special-functions/interfaces/schedule-order-active-hours-override';
import { ModalComponent } from 'src/app/shared/components/modal';
import { SpinnerService } from 'src/app/shared/components/spinner';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { AlertsService, ScheduleOrderActiveHoursOverrideService } from 'src/app/shared/services';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import * as moment from 'moment';
import { forEach } from 'lodash';
import { TarkTimePipe } from '@tarktech/tark-ng-utils';

@Component({
  selector: 'pos-schedule-active-hour-overrides-edit',
  templateUrl: './schedule-active-hour-overrides-edit.component.html',
})
export class ScheduleActiveHourOverridesEditComponent extends ModalComponent implements OnInit {

  scheduleOverrides: ScheduleOrderActiveHoursOverride;
  schedules: Array<ScheduleOrderActiveHoursOverride> = [];
  @Input() scheduleOverrideId: number;
  scheduleStatus: boolean = false;
  invalidToDate = false;
  invalidFromDate = false;
  dateFormat = 'mm-dd-yy';
  invalidScheduleStatusTime = false;
  conflictingSchedule: ScheduleOrderActiveHoursOverride;

  constructor(protected spinnerService: SpinnerService,
    private scheduleActiveHourOverrideService: ScheduleOrderActiveHoursOverrideService,
    private alertService: AlertsService,
    private applicationStateService: ApplicationStateService,
    private tarkTimePipe: TarkTimePipe,
    onlineOrderScheduleOverrideModalRef: BsModalRef) {
    super(onlineOrderScheduleOverrideModalRef);
    this.scheduleOverrides = this.scheduleActiveHourOverrideService.getNewOverrideSchedule();
    this.conflictingSchedule = this.scheduleActiveHourOverrideService.getNewOverrideSchedule();
    this.dateFormat = this.applicationStateService.settingParam.PCalendarDateFormat;
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.spinnerService.show();
    this.scheduleActiveHourOverrideService.getNextScheduleOverrides()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      })).subscribe({
        next: (response: ScheduleOrderActiveHoursOverride[]) => {
          this.schedules = response;
          if (this.scheduleOverrideId) {
            const scheduleOverride = { ...this.schedules.find(x => x.Id === this.scheduleOverrideId) };
            this.schedules = this.schedules.filter(x => x.Id !== this.scheduleOverrideId);
            this.scheduleOverrides = this.scheduleActiveHourOverrideService.setOverrideSchedule(scheduleOverride);
            if (this.scheduleOverrides.StartTime || this.scheduleOverrides.EndTime) {
              this.scheduleStatus = true;
            }
          }

        }, error: this.alertService.showApiError
      });
  }

  onCancel(reload: boolean = false) {
    this.hide({ shouldReload: reload });
  }

  saveScheduleOverride(isValid: boolean) {
    this.checkForDateConflict();
    this.scheduleStatusChange();
    if (!isValid || this.invalidFromDate || this.invalidToDate || this.invalidScheduleStatusTime) {
      return;
    }
    this.spinnerService.show();

    this.scheduleActiveHourOverrideService.insert(this.scheduleOverrides).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (response) => {
        this.onSaveSuccess();
      }, error: this.alertService.showApiError
    });
  }

  onSaveSuccess() {
    this.onCancel(true);
    this.alertService.renderSuccessMessage(Messages.ScheduleOrderActiveHourOverrideSaveSuccess);
  }

  onDateChange() {
    if (Date.parse(this.scheduleOverrides.FromDate.toString()) > Date.parse(this.scheduleOverrides.ToDate.toString())) {
      this.scheduleOverrides.ToDate = this.scheduleOverrides.FromDate;
    }
    this.invalidToDate = false;
    this.invalidFromDate = false;
    this.checkForDateConflict();
  }

  scheduleStatusChange() {
    if (!this.scheduleStatus) {
      this.scheduleOverrides.StartTime = null;
      this.scheduleOverrides.EndTime = null;
    }
  }

  checkForDateConflict() {
    if (this.scheduleOverrides) {
      const scheduleOverrideFromDate = moment(this.scheduleOverrides.FromDate);
      const scheduleOverrideToDate = moment(this.scheduleOverrides.ToDate);
      const scheduleOverridesStartTime = moment(this.scheduleOverrides.StartTime, 'HH:mm');
      const scheduleOverridesEndTime = moment(this.scheduleOverrides.EndTime, 'HH:mm');

      forEach(this.schedules, (data) => {
        const fromDate = moment(data.FromDate);
        const toDate = moment(data.ToDate);
        if (
          scheduleOverrideFromDate.isBetween(fromDate, toDate, undefined, '[]') // [] indicates inclusive
          || scheduleOverrideToDate.isBetween(fromDate, toDate, undefined, '[]')
          || fromDate.isBetween(scheduleOverrideFromDate, scheduleOverrideToDate, undefined, '[]')
          || toDate.isBetween(scheduleOverrideFromDate, scheduleOverrideToDate, undefined, '[]')) {
          this.checkForTimeConflict(data, scheduleOverridesStartTime, scheduleOverridesEndTime);
        } else {
          this.invalidFromDate = false;
          this.invalidToDate = false;
        }
        if (this.invalidFromDate || this.invalidToDate) {
          return false;
        }
      });
    }
  }

  checkForTimeConflict(data: ScheduleOrderActiveHoursOverride, overrideStartTime: moment.Moment, overrideEndTime: moment.Moment) {
    if (!data.StartTime && !data.EndTime) {
      this.invalidFromDate = true;
      this.invalidToDate = true;
      this.conflictingSchedule.FromDate = new Date(data.FromDate);
      this.conflictingSchedule.ToDate = new Date(data.ToDate);
      return;
    }
    const startTime = moment(data.StartTime, 'HH:mm');
    const endTime = moment(data.EndTime, 'HH:mm');
    if (!this.scheduleStatus
      || overrideStartTime.isBetween(startTime, endTime, undefined, '[]')
      || overrideEndTime.isBetween(startTime, endTime, undefined, '[]')
      || startTime.isBetween(overrideStartTime, overrideEndTime, undefined, '[]')
      || endTime.isBetween(overrideStartTime, overrideEndTime, undefined, '[]')) {
      this.invalidFromDate = true;
      this.invalidToDate = true;
      this.conflictingSchedule.FromDate = new Date(data.FromDate);
      this.conflictingSchedule.ToDate = new Date(data.ToDate);
      this.conflictingSchedule.StartTime = this.tarkTimePipe.transform(startTime.toDate().getTime());
      this.conflictingSchedule.EndTime = this.tarkTimePipe.transform(endTime.toDate().getTime());
    } else {
      this.invalidFromDate = false;
      this.invalidToDate = false;
    }
  }

  onTimeChange() {
    if (this.scheduleOverrides?.StartTime && this.scheduleOverrides?.EndTime) {
      const startTime = moment(this.scheduleOverrides.StartTime, 'HH:mm');
      const endTime = moment(this.scheduleOverrides.EndTime, 'HH:mm');
      if (startTime > endTime) {
        this.invalidScheduleStatusTime = true;
      } else {
        this.invalidScheduleStatusTime = false;
      }
    }
    this.checkForDateConflict();
  }

  resetValidation() {
    this.invalidFromDate = false;
    this.invalidToDate = false;
    this.invalidScheduleStatusTime = false;
  }

  resetTime() {
    this.scheduleOverrides.StartTime = null;
    this.scheduleOverrides.EndTime = null;
    this.conflictingSchedule.StartTime = null;
    this.conflictingSchedule.EndTime = null;
  }

}
