import { Component, OnInit, Input, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ModalBackdropService, ModalComponent } from 'src/app/shared/components/modal';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CustomerCredential } from 'src/app/application-login';
import { RuntimeConstants, Messages, DomainConstants } from 'src/app/shared/constants';
import { ApplicationStateService, AlertsService, SpinnerService, SettingParam, EventBroadcastingService, ModalService, OrderService, InfoModalComponent, ApplicationLoginService } from 'src/app/shared';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TerminalsService } from 'src/app/configurator';
import { NGXLogger } from 'ngx-logger';
import { arrowAltCircleLeft } from 'src/app/shared/components/icon';
import { ManagesService } from 'src/app/shared/services/manages.service';
import { ClockInStatusComponent } from './clock-in-status/clock-in-status.component';

@Component({
  selector: 'pos-clock-in',
  templateUrl: './clock-in.component.html',
})
export class ClockInComponent extends ModalComponent implements OnInit, OnDestroy {
  customerCredential: CustomerCredential;
  @Input() isTimeEntryTerminal = false;
  @Output() clockInCompleted: EventEmitter<any> = new EventEmitter();
  @Output() hideComponent: EventEmitter<any> = new EventEmitter();
  imagePath = RuntimeConstants.IMAGE_BASE_PATH;
  settingParams: SettingParam;
  userClockInStatusMessage: string;
  clockInButtonText = 'Clock In';
  clockInInfractionList: [];
  clockInInfractionRef: BsModalRef;
  clockInStatusModalRef = null;
  clockInUserId: number = null;
  @ViewChild('clockInInfraction') clockInInfraction: any;
  icons = {
    arrowAltCircleLeft
  };

  constructor(clockInModalRef: BsModalRef,
    private applicationStateService: ApplicationStateService,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private orderService: OrderService,
    private managesService: ManagesService,
    private router: Router,
    private eventBroadcastingService: EventBroadcastingService,
    private modalService: BsModalService,
    private modalBackdropService: ModalBackdropService,
    private terminalService: TerminalsService,
    private applicationLoginService: ApplicationLoginService,
    private logger: NGXLogger,
    private modalRefService: ModalService
  ) {
    super(clockInModalRef);
  }

  ngOnInit() {
    this.resetCredential();
    this.settingParams = this.applicationStateService.settingParam;
  }

  ngOnDestroy(): void { }

  onCancel(reload: boolean) {

  }

  private resetCredential(): void {
    this.customerCredential = {
      Password: '',
      ExternalPassword: '',
      Username: ''
    };
  }

  textEnteredForLogin(value: string) {
    this.customerCredential.Password += value;
  }

  clearTextEnteredForLogin() {
    this.customerCredential.Password = '';
  }

  removeCharacterEnteredForLogin() {
    this.customerCredential.Password = this.customerCredential.Password.substring(0, this.customerCredential.Password.length - 1);
  }

  cancel() {
    const userDetails = this.applicationStateService.userDetails;
    if (this.isTimeEntryTerminal && userDetails?.time_keeping) {
      this.customerCredential.Password = '';
      return;
    }
    if (!this.isTimeEntryTerminal) {
      this.hide({});
    }
    this.resetCredential();
  }

  exitTerminal() {
    this.hide({});
    this.logoutTheSession();
  }

  logoutTheSession() {
    if (this.settingParams.PreventLogoutUntilDrawerClosed) {
      this.checkDrawerStatus();
    } else {
      this.removeSettings();
      this.logout();
    }
  }

  checkDrawerStatus() {
    this.spinnerService.show();
    const cashDrawerId = this.settingParams && this.settingParams.CashDrawerPrinter && this.settingParams.CashDrawerPrinter.Id ?
      this.settingParams.CashDrawerPrinter.Id : 0;
    this.managesService.getDrawerStatus(cashDrawerId).pipe(finalize(() => {
      this.spinnerService.hide();
    }))
      .subscribe({
        next: (res) => {
          if (res.action === 'Open') {
          } else {
            this.removeSettings();
            this.logout();
          }
        }, error: this.alertService.showApiError
      });
  }

  removeSettings() {
    this.applicationStateService.gotoLoginScreen = false;
    this.applicationStateService.userDetails = null;
    this.applicationStateService.username = null;
    this.applicationStateService.gotoTerminal = false;
    this.applicationStateService.cartOrder = null;
    this.applicationStateService.parentId = 0;
    this.applicationStateService.unservedOrdersData = [];
    this.applicationStateService.productNavigationDetails = [];
    this.applicationStateService.defaultProductSelected = [];
    this.applicationStateService.screenBehaviorData = [];
    this.applicationStateService.inventoryOrderReceivingProductList = [];
    this.applicationStateService.inventoryOrderReceivingNewBarcodeList = [];
    this.applicationStateService.signInAsDiffUserTerminalDetail = null;
    this.applicationStateService.isImpersonateUser = false;
    this.applicationStateService.impersonateUserDetails = null;
    this.applicationStateService.sessionDesignMode = false;
  }

  logout() {
    const logoutDetails: any = new Object();
    logoutDetails.EventMessage = 'Terminal Sign Out';
    logoutDetails.EventDetails = 'Sign Out';
    logoutDetails.userId = this.applicationStateService.userId;
    logoutDetails.TerminalId = this.applicationStateService.terminalId;
    this.spinnerService.show();
    this.orderService.logoutTheSession(logoutDetails).pipe(finalize(() => {
      this.spinnerService.hide();
    }))
      .subscribe({
        next: (res) => {
          this.router.navigate(['login']);
          this.eventBroadcastingService.stateChangeSuccess.subscribe(
            (stateData: any) => {
              if (stateData.name === 'login') {
                window.location.reload();
              }
            }
          );
        }, error: this.alertService.showApiError
      });
  }

  private handelClockInError = (res) => {
    if (res && res.error && res.error.ReturnMessage && res.error.ReturnMessage.length > 0) {
      this.alertService.renderErrorMessage(res.error.ReturnMessage[0]);
    } else {
      this.alertService.showApiError(res);
    }
    this.resetCredential();
  }

  clockInOut() {
    if (this.customerCredential.Password) {
      if (this.settingParams) {
        if (this.customerCredential.Password.charAt(0) === this.settingParams.RFIDInitiatorSequence &&
          this.customerCredential.Password.charAt(this.customerCredential.Password.length - 1) === this.settingParams.RFIDEndingSequence) {
          return;
        }
      }
      this.spinnerService.show();
      this.applicationLoginService.getUserByCode(this.customerCredential.Password)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res) => {
            if (res) {
              this.getUserByCodeCompleted(res);
            }
          }, error: (error) => { this.handelClockInError(error); }
        });
    }
  }

  getUserByCodeCompleted(res: any) {
    if (res.User) {
      if (!res.User.active_delivery) {
        if (res.User.active) {
          if (res.User.time_keeping) {
            this.clockLoginCheck();
          } else {
            this.customerCredential.Password = '';
            this.showAlert(Messages.UserTimeKeepingError);
          }
        } else {
          this.customerCredential.Password = '';
          this.showAlert(Messages.UserInactiveError);
        }
      } else {
        this.customerCredential.Password = '';
        this.showAlert(Messages.UserHaveActiveDelivery);
      }
    } else {
      this.customerCredential.Password = '';
      this.showAlert(Messages.PasswordIncorrect);
    }
  }

  clockLoginCheck() {
    this.applicationLoginService.clockLoginCheck(this.customerCredential.Password)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          if (res) {
            this.loginCompleted(res);
          }
        }, error: this.alertService.showApiError
      });
  }

  loginCompleted(res: any) {
    this.resetCredential();
    const userId = this.applicationStateService.userId;
    const clockInUserId = res.UserClockStatus.user_id;
    const username = res.UserClockStatus.LastName + ' ' + res.UserClockStatus.FirstName;
    if (res.UserClockStatus == null) {
      this.userClockInStatusMessage = Messages.UserCurrentlyClockOut;
      this.clockInButtonText = 'Clock In';
      this.openClockInStatusModal(clockInUserId, username);
      this.logger.debug('User ' + res.UserClockStatus.user_id + ' is ' + this.clockInButtonText + ' and current terminal id : ' +
        this.applicationStateService.terminalId + '. Response: ' + this.clockInButtonText);

    } else if (res.UserClockStatus.id !== -1) {
      if (!res.UserClockStatus.ClockOutDateTime) {
        this.userClockInStatusMessage = Messages.UserCurrentlyClockIn;
        this.clockInButtonText = 'Clock Out';
        this.openClockInStatusModal(clockInUserId, username);

        this.logger.debug('User ' + res.UserClockStatus.user_id + ' is ' + this.clockInButtonText + ' and current terminal id : '
          + this.applicationStateService.terminalId + '. Response: ' + this.clockInButtonText);
      } else {
        this.userClockInStatusMessage = Messages.UserCurrentlyClockOut;
        this.clockInButtonText = 'Clock In';
        this.openClockInStatusModal(clockInUserId, username);
        this.logger.debug('User ' + res.UserClockStatus.user_id + ' is ' + this.clockInButtonText + ' and current terminal id : ' +
          this.applicationStateService.terminalId + '. Response: ' + this.clockInButtonText);
      }
    } else {
      this.customerCredential.Password = '';
      this.showAlert(Messages.PasswordIncorrect);
    }
  }

  openClockInStatusModal = (clockInUserId, username: string, message?: string, buttonText?: string, clockInUserDetails = null, callBack = null) => {
    this.clockInUserId = clockInUserId;
    this.userClockInStatusMessage = message ? message : this.userClockInStatusMessage;
    this.clockInButtonText = buttonText ? buttonText : this.clockInButtonText;
    if (!this.clockInStatusModalRef) {
      const clockInStatusModal = this.modalRefService.getModalWrapper(ClockInStatusComponent);
      this.clockInStatusModalRef = clockInStatusModal.show({
        'backdrop': 'static',
        'class': 'vertical-center',
        'keyboard': false,
        initialState: {
          userClockInStatusMessage: this.userClockInStatusMessage,
          clockInButtonText: this.clockInButtonText,
          clockedInUsername: username
        }
      });
      this.clockInStatusModalRef.close.subscribe({
        next: (res) => {
         
          setTimeout(() => {
            this.clockInStatusModalRef = null;
            if (res && res.isChangeClockInState) {
              this.clockedInOutToSystem(clockInUserDetails);
            }
            if(callBack) {
              callBack(clockInUserDetails);
            }
          }, 600);
        }
      });
    }
  }

  openClockInInfractionModal() {
    this.clockInInfractionRef = this.modalService.show(this.clockInInfraction, {
      'backdrop': 'static',
      'class': 'vertical-center',
      'keyboard': false
    });
    this.modalBackdropService.addBackDrop(2);
  }

  closeClockInInfraction() {
    this.clockInInfractionRef.hide();
    this.modalBackdropService.removeBackdrop();
  }

  clockedInOutToSystem = (clockInUserDetails = null) => {
    const clockInOut = {
      UserId: this.clockInUserId,
      ClockValue: this.clockInButtonText,
      TerminalId: this.applicationStateService.terminalId,
      TerminalName: this.applicationStateService.terminalName
    };
    this.logger.debug('Requesting ' + clockInOut.ClockValue + ' for user ' + clockInOut.UserId + ' and current terminal id : ' + clockInOut.TerminalId);
    if (this.clockInButtonText === DomainConstants.ClockInOut.CLOCK_IN) {
      this.spinnerService.show();

      this.applicationLoginService.clockIntoPos(clockInOut)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res) => {
            if (res) {
              this.clockIntoSystemCompleted(res);
              this.clockInCompleted.emit(clockInUserDetails);
              if (!this.isTimeEntryTerminal) {
                setTimeout(() => {
                  this.hide({});
                }, 600);
              }
            }
          }, error: this.alertService.showApiError
        });
    } else if (this.clockInButtonText === DomainConstants.ClockInOut.CLOCK_OUT) {
      if (this.settingParams.ShowAllOrders && this.settingParams.SettlePersonalOrdersOnly) {
        this.getOwnOrdersOnly(this.clockInUserId, clockInOut, this.clockIntoSystemCompleted);
      } else {
        this.onClockOut(clockInOut, this.clockIntoSystemCompleted);
      }
    }
  }

  clockIntoSystemCompleted = (response) => {
    if (response) {
      const terminalEventModal = {
        userId: this.clockInUserId,
        terminalname: this.applicationStateService.terminalName,
        terminalid: this.applicationStateService.terminalId,
        eventMessage: this.clockInButtonText === 'Clock Out' ? 'Terminal Clock Out' : 'Terminal Clock In',
        eventDetails: this.clockInButtonText,
      };
      if (this.clockInButtonText === 'Clock Out') {
        this.logger.debug('Response from ClockOut.');
        this.saveTerminalEvent(terminalEventModal);
      } else {
        this.logger.debug('Response from ClockIn.');
        this.clockInInfractionList = response.UserInfractionList;
        this.customerCredential.Password = '';
        const terminalType = this.applicationStateService.terminalType;
        if (terminalType === DomainConstants.TerminalTypes.TIME_ENTRY.Name) {
          if (this.clockInButtonText === 'Clock Out') {
            this.alertService.renderSuccessMessage(Messages.UserSuccessfullyClockOut);
          } else {
            this.alertService.renderSuccessMessage(Messages.UserSuccessfullyClockIn);
          }
        }
        if (this.clockInInfractionList && this.clockInInfractionList.length > 0) {
          this.openClockInInfractionModal();
        }
        this.saveTerminalEvent(terminalEventModal);
      }
    }
  }

  saveTerminalEvent = (terminalDetails: any) => {
    this.spinnerService.show();
    this.terminalService.saveTerminalEvents(terminalDetails)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          const terminalType = this.applicationStateService.terminalType;
          if (this.clockInButtonText === 'Clock Out' &&
            (this.applicationStateService.userId === this.clockInUserId) && !this.isTimeEntryTerminal) {
            if (terminalType === DomainConstants.TerminalTypes.ORDER_ENTRY.Name
              || terminalType === DomainConstants.TerminalTypes.BACK_OFFICE.Name) {
              this.router.navigate(['login']);
              setTimeout(() => {
                window.location.reload();
              }, 1500);
              this.removeSettings();
            }
          }
          this.resetCredential();
        }, error: this.alertService.showApiError
      });
  }

  getOwnOrdersOnly(userId, clockInOut, callBackFunction) {
    this.spinnerService.show();
    this.orderService.getUnservedOrdersData(0, userId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          if (res && res.UnservedOrderModel && res.UnservedOrderModel.length > 0) {
            this.customerCredential.Password = '';
            this.showAlert(Messages.ErrorWhileClockOutUserWithUnsettledOrders);
          } else {
            this.onClockOut(clockInOut, callBackFunction);
          }
        }, error: this.alertService.showApiError
      });
  }

  onClockOut = (params, callbackFunction) => {
    let modalRef = this.modalRefService.show(InfoModalComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        message: Messages.ConfirmPrintClockOutReceipt,
        confirmButtonText: 'Yes',
        rejectButtonText: 'No',
        modalHeaderText: 'Confirm'
      }
    });

    setTimeout(() => {
      if (modalRef) {
        modalRef.close.emit();
      }
    }, 5000);

    modalRef.close.subscribe({
      next: (res) => {
        if (res && (res.shouldConfirm || res.event == 'close')) {
          params.IsPrintReceipt = true;
        } else {
          params.IsPrintReceipt = false;
        }
        modalRef = null;
        this.clockOutPos(params, callbackFunction);
        if (!this.isTimeEntryTerminal) {
          this.hideComponent.emit();
          setTimeout(() => {
            this.hide({});
          });
        }
      }
    });
  }

  clockOutPos(params, callbackFunction) {
    this.spinnerService.show();
    this.applicationLoginService.clockOutOfPos(params)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          callbackFunction(res);
        }, error: this.alertService.showApiError
      });
  }


  showAlert = (alertMessage) => {
    this.alertService.renderErrorMessage(alertMessage);
  }
}
