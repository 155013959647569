import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { Observable } from 'rxjs';
import { Vendor } from '../interface/vendor';
import { VendorOrderSchedule } from '../interface/vendor-order-schedule';
import { InventoryOrderDay } from './inventory-order-day';

@Injectable()
export class VendorService {

    vendorApiUrl = '';
    constructor(private httpService: HttpClient) {
        this.vendorApiUrl = RuntimeConstants.API_PATH + 'vendors';
    }

    newVendor(): Vendor {
        return {
            id: 0,
            name: '',
            phone: '',
            contact: '',
            account_no: '',
            active: true,
            OrderDay: '',
            OrderReceiveDay: '',
            TypicalLeadTime: null,
            Name: null,
            OrderSchedule: [{ Id: 0, VendorId: 0, OrderPlaceDayId: null, OrderReceiveDayId: null }]
        };
    }

    newOrderSchedule(vendorId: number): VendorOrderSchedule {
        return {
            Id: 0,
            VendorId: vendorId,
            OrderPlaceDayId: null,
            OrderReceiveDayId: null,
        };
    }

    getVendorList(): Observable<any[]> {
        return this.httpService.get<any[]>(this.vendorApiUrl);
    }
    getVendorDetails(vendorId: number): Observable<any> {
        return this.httpService.get(`${this.vendorApiUrl}/` + vendorId);
    }
    saveVendorDetails(vendorData: Vendor): Observable<any> {
        return this.httpService.post(this.vendorApiUrl, vendorData);
    }
    deleteInventoryVendor(vendorId: number): Observable<any> {
        return this.httpService.delete(`${this.vendorApiUrl}/` + vendorId);
    }

    getInventoryOrderDays(): Observable<Array<InventoryOrderDay>> {
        return this.httpService.get<Array<InventoryOrderDay>>(`${this.vendorApiUrl}/` + 'order-days');
    }

    deleteInventoryVendorSchedule(scheduleId: number): Observable<any> {
        return this.httpService.delete(`${this.vendorApiUrl}/schedule/` + scheduleId);
    }
}
