import { Component, EventEmitter, Output, Input } from '@angular/core';
import { AdfDashboardService } from '../../services/adf-dashboard.service';
import { AdfModel } from '../../interfaces';
import { WidgetService } from '../../services';

@Component({
  selector: 'pos-add-widget',
  templateUrl: './add-widget.component.html'
})
export class AddWidgetComponent {

  createCategories: object = {};
  @Input() widget: any;
  @Input() widgets: Array<any> = [];
  @Input() adfModel: AdfModel;
  @Input() dashboard: any;
  @Output() close: EventEmitter<any> = new EventEmitter();
  constructor(private adfDashboardService: AdfDashboardService, private widgetService: WidgetService) { }
  cancelDialog = () => {
    this.close.emit();
  }

  addWidget = (widget, widgetKey) => {
    this.cancelDialog();
    const w = widget;
    const widgetDetail = this.widgetService.getWidgetDetails(widgetKey);
    w.editImmediate = widgetDetail.editImmediate;
    w.editReload = widgetDetail.editReload;
    w.frameless = widgetDetail.frameless;
    w.reload = widgetDetail.reload;
    w.widgetId = widget.Id;
    this.adfDashboardService.addNewWidgetToModel(this.adfModel, w, name);
  }

}
