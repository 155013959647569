import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { SettingParam } from 'src/app/shared/interface';

@Injectable({
  providedIn: 'root'
})
export class CancelOrderService {
  cancelOrderConfigApiBasePath = RuntimeConstants.API_PATH + 'cancel-order-config';

  constructor(private httpClient: HttpClient) { }
  getCancelOrderConfig(): Observable<SettingParam> {
    return this.httpClient.get<SettingParam>(`${this.cancelOrderConfigApiBasePath}`);
  }

  save(params, image: File): Observable<SettingParam> {
    const formData = this.prepareImageForm(params, image);
    return this.httpClient.post<SettingParam>(this.cancelOrderConfigApiBasePath, formData);
  }

  prepareImageForm(params, imageFile: File): FormData {
    const formData = new FormData();
    if (imageFile) {
      formData.append('file', imageFile);
    }
    formData.append('model', JSON.stringify(params));
    return formData;
  }
}
