import { Route } from '@angular/router';
import { AuthGuard } from '../app-guard';
import { ServeScreenComponent } from './components/serve-screen/serve-screen.component';

const SERVE_SCREEN: Route = {
  path: '',
  canActivate: [AuthGuard],
  component: ServeScreenComponent
};
export const SERVE_SCREEN_STATES = [SERVE_SCREEN];

