import { MessagePayload } from './message-payload';

export class OrderInfoPayload extends MessagePayload {

    public OrderId: number;

    constructor(orderId: number) {
        super();
        this.OrderId = orderId;
    }
}
