import { MessagePayload } from './message-payload';

export class OrderProductDetailsPayload extends MessagePayload {

    public OrderProductDetails: any;

    constructor(orderProductDetails: any) {
        super();
        this.OrderProductDetails = orderProductDetails;
    }
}
