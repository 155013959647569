<pos-edit-wrapper (close)="cancel()" heading="QuickBooks: Cash Sales">
    <form (ngSubmit)="saveQuickBooksCashSalesConfig(generalWorkflowForm.valid)" #generalWorkflowForm="ngForm">
        <div class="col-12 pos-modal-body screen-scroll">
            <pos-row>
                <pos-column>
                    <pos-form-field label="Customer"
                        [validationMessages]="{required: 'Please enter value for Customer.'}">
                        <input posInput type="text" name="customerName" class="form-control"
                            [(ngModel)]="parameters.QuickBooksCustomerName" #customerName="ngModel" [required]="true" />
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Item" [validationMessages]="{required: 'Please enter value for Item.'}">
                        <input posInput type="text" name="item" class="form-control"
                            [(ngModel)]="parameters.QuickBooksItemName" #item="ngModel" [required]="true" />
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Deposit to"
                        [validationMessages]="{required: 'Please enter value for Deposit to.'}">
                        <input posInput type="text" name="salesAccount" class="form-control"
                            [(ngModel)]="parameters.QuickBooksDepositToAccount" #salesAccount="ngModel"
                            [required]="true" />
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" scrollup value="Save">Save</button>
            <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" value="Cancel"
                (click)="cancel()">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
