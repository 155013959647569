import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { RuntimeConstants, Messages, DomainConstants, Levels, HttpStatusCodes } from 'src/app/shared/constants';
import { CustomerCredential } from '../../interface/customer-credential';
import {
  SpinnerService, AlertsService, ApplicationStateService, Configurations, ApplicationInitializationConfigurations,
  SettingParam, AuthenticationService, UserDetails, EventBroadcastingService, RabbitMQConfigurationService, ModalBackdropService,
  StringUtils,
  CameraAnnotations,
  CameraAnnotationService, ApplicationFeaturesService
} from 'src/app/shared';
import { MainService } from 'src/app/header-entry/services/main.service';
import { finalize } from 'rxjs/operators';
import { ApplicationLoginService } from '../../../shared/services/application-login.service';
import { forkJoin } from 'rxjs';
import { PermissionService } from 'src/app/shared/services/permission.service';
import * as _ from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NGXLogger } from 'ngx-logger';
import { applicationLoginUndo, applicationLoginArrowCircleRight } from 'src/app/shared/components/icon';
import { Router } from '@angular/router';
import { forEach } from 'lodash';

declare let $: any;

@Component({
  selector: 'pos-application-login',
  templateUrl: './application-login.component.html',
  styleUrls: ['./application-login.component.scss', '../../../../styles/pages/login.scss']
})
export class ApplicationLoginComponent implements OnInit {
  imagePath = RuntimeConstants.IMAGE_BASE_PATH;
  isKeyboardWedgeEnabled = true;
  terminalAccessDenied = '';
  test: string;
  customerCredential: CustomerCredential;
  applicationInitializationConfiguration: ApplicationInitializationConfigurations;
  settingParam: SettingParam;
  userHavePermission: boolean;
  externalAuthRequired: boolean = false;
  externalLoginModalRef: BsModalRef;
  self: any;
  icons = {
    applicationLoginUndo,
    applicationLoginArrowCircleRight
  };
  @ViewChild('externalLoginModal') public externalLoginModal: any;
  @ViewChildren('appPassword') appPassword: any;
  constructor(private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private applicationStateService: ApplicationStateService,
    private mainService: MainService,
    private applicationLoginService: ApplicationLoginService,
    private authenticationService: AuthenticationService,
    private permissionService: PermissionService,
    private router: Router,
    private bsModalService: BsModalService,
    private modalBackdropService: ModalBackdropService,
    private eventBroadcastingService: EventBroadcastingService,
    private rabbitMQConfigurationService: RabbitMQConfigurationService,
    private logger: NGXLogger,
    private cameraAnnotationService: CameraAnnotationService,
    private applicationFeatureService: ApplicationFeaturesService) { }

  ngOnInit() {
    this.getConfiguration();
    this.removeSettings();
    this.resetCredential();
    this.settingParam = this.applicationStateService.settingParam;
  }

  private removeSettings(): void {
    this.applicationStateService.userDetails = null;
    this.applicationStateService.cartOrder = null;
    this.applicationStateService.parentId = 0;
    this.applicationStateService.unservedOrdersData = [];
    this.applicationStateService.productNavigationDetails = [];
    this.applicationStateService.defaultProductSelected = [];
    this.applicationStateService.screenBehaviorData = [];
    this.applicationStateService.currentUserId = null;
  }


  onSwipeDetected(badgeNumber: string) {
    this.logger.debug('Sign in requested for badgeNumber : ' + badgeNumber);
    this.cardBasedLogin(badgeNumber);
  }

  textEnteredForLogin(value: string) {
    this.customerCredential.Password += value;
    console.log(this.customerCredential);
  }

  private getConfiguration(): void {
    this.spinnerService.show();
    const loginObservable = [];
    loginObservable.push(this.mainService.getConfigurationsForWebApp());
    loginObservable.push(this.mainService.getApplicationInitalizationConfiguration());
    forkJoin(loginObservable).pipe(finalize(() => {
      this.spinnerService.hide();
    }))
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.setConfigurationForWebApp(res[0]);
            const applicationConfigResponse = res[1];
            if (applicationConfigResponse) {
              this.applicationStateService.applicationInitializationConfigurations = applicationConfigResponse;
              this.applicationInitializationConfiguration = applicationConfigResponse;
            }
          }
          this.appPassword.first.nativeElement.focus();
          this.resetCredential();
        }, error: this.handelLoginError
      });
  }

  private setConfigurationForWebApp(configResponse) {
    if (configResponse) {
      this.applicationStateService.configurations = this.rabbitMQConfigurationService.initializeConfigurationsObject(configResponse);
      this.rabbitMQConfigurationService.setConfig();
      document.title = configResponse.CustomerName;
      this.rabbitMQConfigurationService.subscribeConnectionState();
    }
  }

  cancel() {
    this.resetCredential();
  }

  private resetCredential(): void {
    this.customerCredential = {
      Password: '',
      ExternalPassword: '',
      Username: ''
    };
  }

  customerLogin() {
    this.applicationStateService.isAuthenticated = false;
    if (this.settingParam) {
      if (this.customerCredential.Password.charAt(0) === this.settingParam.RFIDInitiatorSequence &&
        this.customerCredential.Password.charAt(this.customerCredential.Password.length - 1) === this.settingParam.RFIDEndingSequence) {
        return;
      }
    }
    if (!this.customerCredential.Password ||
      (this.externalAuthRequired && (!this.customerCredential.ExternalPassword || !this.customerCredential.Username))) {
      return;
    }
    this.logger.debug('Sign in requested for code : ' + this.customerCredential.Password);
    this.userLogin();
  }

  cardBasedLogin(cardBasedPass) {
    this.spinnerService.show();
    this.authenticationService.GetAuthenticationTokenForCardBasedLogin(cardBasedPass, '', '')
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: any) => {
          if (res && res.StatusMessage) {
            res.data = { StatusMessage: res.StatusMessage };
            if (res.data && res.data.StatusMessage === 'External-Auth-Required') {
              this.terminalAccessDenied = Messages.TerminalExternalAuthRequired;
              $('#divLoginAccessDenied').show();
              setTimeout(() => {
                $('#divLoginAccessDenied').fadeOut('slow');
              }, 5000);
              $('#appPassword').focus();
            }
          } else {
            this.applicationStateService.authTokenInfo = res;
            this.applicationStateService.isAuthenticated = true;
            if (this.externalAuthRequired) {
              this.externalLoginModalRef.hide();
              this.externalAuthRequired = false;
            }
            const customerCredential = {
              Password: res.Password,
              ExternalPassword: '',
              Username: ''
            }
            this.spinnerService.show();
            this.applicationLoginService.getCustomer(customerCredential).pipe(finalize(() => {
              this.spinnerService.hide();
            })).subscribe({
              next: (response) => {
                if (response) {
                  this.loginCompleted(response);
                }
              }, error: (error) => {
                this.handelLoginError(error);
              }
            });
          }
        }, error: this.alertService.showApiError
      });
  }

  private userLogin(): void {
    this.spinnerService.show();
    this.authenticationService.getAuthenticationToken
      (this.customerCredential.Password, this.customerCredential.ExternalPassword, this.customerCredential.Username).pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: any) => {
          if (res && res.StatusMessage) {
            res.data = { StatusMessage: res.StatusMessage };
            this.handelLoginError(res);
          } else {
            if (this.externalAuthRequired) {
              this.externalLoginModalRef.hide();
              this.externalAuthRequired = false;
            }
            this.spinnerService.show();
            this.applicationLoginService.getCustomer(this.customerCredential).pipe(finalize(() => {
              this.spinnerService.hide();
            })).subscribe({
              next: (response) => {
                if (response) {
                  this.loginCompleted(response);
                }
              }, error: (error) => {
                this.handelLoginError(error);
              }
            });
          }
        }, error: (error) => { this.handelLoginError(error); }
      });
  }

  private loginCompleted(res: any): void {
    this.spinnerService.show();
    const loginObservables = [];
    const userDetails = res?.UserTerminalDetails?.UserDetails;
    this.setSettingsToLocalStorage(userDetails);
    loginObservables.push(this.permissionService.getUserPermission());
    loginObservables.push(this.mainService.getApplicationVersion());
    loginObservables.push(this.mainService.initializeApplication());
    loginObservables.push(this.cameraAnnotationService.getActiveCameraAnnotation());
    loginObservables.push(this.applicationFeatureService.getApplicationFeatures());
    loginObservables.push(this.mainService.getConfigurationsForWebApp());
    loginObservables.push(this.mainService.getContactSupportKey());
    forkJoin(loginObservables).pipe(finalize(() => {
      this.spinnerService.hide();
    }))
      .subscribe({
        next: (response: any) => {
          if (res && response) {
            this.setApplicationInitConfig(response);
            this.applicationStateService.applicationInitializationConfigurations = this.applicationInitializationConfiguration;
            this.applicationStateService.userPermissions = response[0];
            this.applicationStateService.licenseInfo = response[2]?.LicenseInfo;
            const cameraAnnotationsResponse: Array<CameraAnnotations> = response[3];
            forEach(cameraAnnotationsResponse, (annotations) => {
              this.applicationStateService.cameraAnnotations[annotations.KeyName] = annotations.Annotation;
            });
            const permissionForRecentTerminal = this.applicationStateService.recentTerminalDetails ?
              this.havePermissionOfRecentTerminal(this.applicationStateService.recentTerminalDetails.TerminalType) : false;
            this.navigateBasedOnUserPermission(res, permissionForRecentTerminal);
            this.setConfigurationForWebApp(response[5]);
            this.applicationStateService.contactSupportKey = response[6] ? response[6].Key : null;
          }
        }, error: this.handelLoginError
      });
  }

  private navigateBasedOnUserPermission(res: any, permissionForRecentTerminal: boolean) {
    if (this.userHaveTerminalAccessPermission()) {
      const requestedLoginFrom = this.applicationStateService.application;
      if (requestedLoginFrom === 'configurator') {
        this.navigateToSystemConfiguration(res);
      } else if (this.checkSignInAsTerminalEvent(permissionForRecentTerminal)) {
        this.eventBroadcastingService.signInAsTerminalEvent();
      } else {
        this.router.navigate(['terminals']);
      }
    } else {
      this.showPermissionError();
    }
  }

  private showPermissionError() {
    this.terminalAccessDenied = Messages.ErrorWhileUserNotHaveAnyTerminalAccessPermission;
    $('#divLoginAccessDenied').show();
    setTimeout(() => {
      $('#divLoginAccessDenied').fadeOut('slow');
    }, 5000);
  }

  private navigateToSystemConfiguration(res: any) {
    if (res.UserTerminalDetails?.UserDetails?.administrator) {
      this.applicationStateService.application = '';
      this.applicationStateService.sessionApplicationMode = 'configurator';
      if (this.applicationStateService.userId) {
        this.router.navigate(['manage/system-configuration']);
      }
    }
  }

  private setApplicationInitConfig(response: any) {
    if (this.applicationInitializationConfiguration) {
      this.applicationInitializationConfiguration.CurrentVersion = response[1]?.CurrentVersion;
    } else if (response[1]) {
      this.applicationInitializationConfiguration = response[1];
    }
  }

  private checkSignInAsTerminalEvent(permissionForRecentTerminal: boolean) {
    return this.applicationStateService.gotoLoginScreen
      && (this.applicationStateService.recentTerminalDetails)
      && this.applicationStateService.signInAsDiffUserTerminalDetail
      && permissionForRecentTerminal;
  }

  private setSettingsToLocalStorage(userDetails: UserDetails): void {
    if (userDetails) {
      this.applicationStateService.userId = userDetails.id;
      this.applicationStateService.cookieUserId = userDetails.id;
      this.applicationStateService.userDetails = userDetails;
      this.applicationStateService.sessionApplicationMode = 'application';
    }
  }

  private havePermissionOfRecentTerminal = (type): boolean => {
    const terminalList = DomainConstants.TerminalTypes;
    const terminalTypes = Object.keys(terminalList).map(it => terminalList[it]);
    const terminalType = _.find(terminalTypes, (terminalType) => {
      return type === terminalType.Name;
    });
    let hasPermission = false;
    if (terminalType) {
      hasPermission = this.authenticationService.userHasPermission(
        [{ 'Name': terminalType.Permission, 'Level': Levels.Access }],
        'any');
    }
    return hasPermission;
  }

  private userHaveTerminalAccessPermission(): boolean {
    const terminalList = DomainConstants.TerminalTypes;
    const terminalTypes = Object.keys(terminalList).map(it => terminalList[it]);
    this.userHavePermission = false;
    _.forEach(terminalTypes, (type) => {
      if (!this.userHavePermission) {
        this.userHavePermission = this.authenticationService.userHasPermission(
          [{ 'Name': type.Permission, 'Level': Levels.Access }],
          'any');
      }
    });
    return this.userHavePermission;
  }

  private handelLoginError = (response) => {
    let message = '';
    this.spinnerService.hide();
    if (response.data && response.data.StatusMessage === 'External-Auth-Required') {
      this.externalAuthRequired = true;
      this.externalLoginModalRef = this.bsModalService.show(this.externalLoginModal, {
        animated: false,
        keyboard: false,
        class: 'vertical-center external-login',
      });
      $('#username').focus();
      this.modalBackdropService.addBackDrop();
    } else {
      if (!this.externalAuthRequired) {
        this.resetCredential();
      }
      if (response.status === HttpStatusCodes.BadRequest) {
        if (response && response.data && !response.data.ReturnStatus && response.data.ReturnMessage.length > 0) {
          message = response.data.ReturnMessage[0];
        } else {
          message = response.data ? response.data : response.error;
        }
      } else {
        if (response.data && response.data.StatusMessage && response.data.StatusMessage !== '') {
          message = response.data.StatusMessage;
        } else if (response.status === HttpStatusCodes.Unauthorized) {
          message = 'Access denied';
        } else {
          if (response.ReturnMessage) {
            message = this.formatMessage(response.ReturnMessage);
          } else if (response.data) {
            message = this.formatMessage(response.data.ReturnMessage);
          }
          message = (message && message !== '') ? message : Messages.UnexpectedErrorOccurred;
        }
      }
      this.terminalAccessDenied = message;
      if (this.externalAuthRequired) {
        $('#divInvalidCredentials').show();
        setTimeout(() => {
          $('#divInvalidCredentials').fadeOut('slow');
        }, 10000);
      } else {
        $('#divLoginAccessDenied').show();
        setTimeout(() => {
          $('#divLoginAccessDenied').fadeOut('slow');
        }, 10000);
        $('#appPassword').focus();
      }
    }
  }

  onCancelExternalLogin() {
    this.customerCredential.Username = '';
    this.customerCredential.ExternalPassword = '';
    this.externalLoginModalRef.hide();
    this.modalBackdropService.removeBackdrop();
    this.externalAuthRequired = false;
    $('#appPassword').focus();
  }

  private formatMessage(message): string {
    let messageBox = '';
    Array.isArray(message);
    if (JSON.parse(message)) {
      for (let i = 0; i < message.length; i++) {
        messageBox = messageBox + message[i];
      }
    } else {
      messageBox = message;
    }
    return messageBox;
  }

}
