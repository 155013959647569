<div id="w_{{definition.widgetAssignmentId}}" class="widget mb-pix-10">

    <div class="m-0 card" [ngClass]="{'radius-25': !isInteractive}">
        <div class="card-header" *ngIf="isInteractive">
            <h3 class="panel-title align-item-center">
                <span class="panel-title__widget-title">{{definition.title}}</span>
                <span class="pull-right">
                    <a title="Reload widget Content" *ngIf="definition.reload" (click)="reload()">
                        <pos-action-icon [icon]="icons.sync" [additionalClasses]="['font-16', 'white']" [backgroundClasses]="['green']"></pos-action-icon>
                    </a>
                    <!-- change widget location -->
                    <a title="Change widget location" class="adf-move move-handle">
                        <pos-action-icon [icon]="icons.arrowsAlt" [additionalClasses]="['font-16', 'white']" [backgroundClasses]="['red']"></pos-action-icon>
                    </a>
                    <!-- collapse -->
                    <a title="Collapse widget" (click)="isCollapsed = !isCollapsed"
                        *ngIf="options.Collapsible && !isCollapsed">
                        <pos-action-icon [icon]="icons.minus" [additionalClasses]="['font-16', 'white']" [backgroundClasses]="['blueviolet']"></pos-action-icon>
                    </a>
                    <!-- expand -->
                    <a title="Expand widget" (click)="isCollapsed = !isCollapsed"
                        *ngIf="options.Collapsible && isCollapsed">
                        <pos-action-icon [icon]="icons.dashboardPlus" [additionalClasses]="['font-16', 'white']" [backgroundClasses]="['blueviolet']"></pos-action-icon>
                    </a>
                    <!-- open edit mode -->
                    <a title="Edit widget configuration" (click)="edit()">
                        <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
                    </a>
                    <a title="Fullscreen widget" (click)="openFullScreen()" *ngIf="options.Maximizable">
                        <pos-action-icon [icon]="icons.expandArrows" [additionalClasses]="['font-16', 'white']" [backgroundClasses]="['green']"></pos-action-icon>
                    </a>
                    <!-- remove widget -->
                    <a title="Remove widget" (click)="remove()">
                        <pos-action-icon [icon]="icons.times" [additionalClasses]="['font-16', 'white']" [backgroundClasses]="['delete-color']"></pos-action-icon>
                    </a>
                </span>
            </h3>
        </div>
        <!-- {{definition.titleTemplateUrl}} -->
        <div [ngClass]="{'card-body p-0': !definition.frameless || isEditMode, 'full-height': !isInteractive}"
            class="show collapse" *ngIf="!isCollapsed">
            <pos-adf-widget-content [adfModel]="adfModel" [model]="definition" [content]="definition"
                [column]="column" [isInteractive]="isInteractive">
            </pos-adf-widget-content>
        </div>
    </div>
</div>
