<pos-edit-wrapper heading="{{selectedDeliveryType.description}}" (close)="onClose(false)">
    <form #emailActivityEditForm="ngForm" autocomplete="off" (submit)='saveActivity(emailActivityEditForm.valid)'>
        <div class="col-12 pos-modal-body screen-scroll">
            <pos-row>
                <pos-column>
                    <pos-form-field label="Recipients" [validationMessages]="{required: 'Please enter recipient(s).'}" inputStyle="col-xs-12">
                        <textarea posInput class="form-control" id="emailRecipients" name="emailRecipients"
                            placeholder="Enter emails separated by semicolon (;)" [(ngModel)]="emailRecipients"
                            [required]="true" type="text" #recipients="ngModel" rows="6"></textarea>
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-repeat-action-configuration [(eventSubscriptionActionAttributes)]="eventSubscriptionActionAttributes"                
                    [isFormSubmitted]="emailActivityEditForm.submitted" #RepeatActionConfiguration [isRecoverableEvent]="isRecoverableEvent">
                    </pos-repeat-action-configuration>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save</button>
            <button type="button" (click)="onClose(false)" class="btn-secondary btn-lg btn btn-cancel-modal pos-button"
                value="Cancel">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
