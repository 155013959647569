import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DeleteColumn, GridColumn, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { filter } from 'lodash';
import { finalize } from 'rxjs/operators';
import { AlertsService, Messages, RuntimeConstants, SpinnerService, StringUtils } from 'src/app/shared';
import { ConfirmDeleteComponent } from 'src/app/shared/components/confirm-delete/confirm-delete.component';
import { sync, times, menuSetupUnusedImage } from 'src/app/shared/components/icon/icons';
import { UnusedImage } from '../../interface/UnusedImage';
import { MenuSetupService } from '../../Services/menu-setup.service';

@Component({
  selector: 'pos-unused-images',
  templateUrl: './unused-images.component.html',
  styleUrls: ['./unused-images.component.scss']
})
export class UnusedImagesComponent implements OnInit {
  @ViewChild('confirmImageDelete') private confirmImageDelete: ConfirmDeleteComponent;
  @ViewChild('confirmBulkDelete') private confirmBulkDelete: ConfirmDeleteComponent;
  public icons = {
    sync,
    times,
    menuSetupUnusedImage
  };
  public message;
  imagePath = RuntimeConstants.IMAGE_BASE_PATH;
  unusedImagesColumns: Array<GridColumn> = [];
  isBulkDeleteAll = false;
  screenHeight = (window.innerHeight - 245) + 'px';
  unusedImages: Array<UnusedImage> = [];
  deleteImage: UnusedImage;
  unusedImagesCount = 0;
  @ViewChild('bulkDeleteHeaderTemplate', { static: true }) bulkDeleteHeaderTemplate: TemplateRef<any>;
  @ViewChild('bulkDeleteCheckboxTemplate', { static: true }) bulkDeleteCheckboxTemplate: TemplateRef<any>;
  @ViewChild('UnusedImageTemplate', { static: true }) private unusedImageTemplate: TemplateRef<any>;
  filteredImages: Array<UnusedImage> = [];
  constructor(private router: Router,
    private menuSetupService: MenuSetupService,
    private spinnerService: SpinnerService,
    private alertService: AlertsService) { }

  ngOnInit(): void {
    this.columnConfiguration();
    this.reloadUnusedImages();
  }

  private columnConfiguration() {
    const checkboxTemplate = new TemplateColumn({
      HeaderText: '', IsSortable: false,
      itemTemplate: this.bulkDeleteCheckboxTemplate, Width: '37px',
      headerTemplate: this.bulkDeleteHeaderTemplate
    });

    const deleteColumn = new DeleteColumn({
      Clicked: (data) => {
        this.deleteButton(data);
      },
      Width: '50px'
    });
    const imageColumn = new TemplateColumn({
      Width: '50px',
      itemTemplate: this.unusedImageTemplate,
      HeaderText: 'Image',
      TextAlign: TextAlign.Center
    });

    this.unusedImagesColumns = [
      new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '71%' }),
      imageColumn,
      deleteColumn
    ] as Array<GridColumn>;
    this.unusedImagesColumns.unshift(checkboxTemplate);

  }

  onTableFiltered(event) {
    this.isBulkDeleteAll = false;
    this.filteredImages = [];
    if (event.filteredValue.length !== this.unusedImages.length) {
      this.filteredImages = event.filteredValue;
    }
  }

  addImageForBulkDelete(image: UnusedImage) {
    this.isBulkDeleteAll = false;
    this.refreshUnusedImagesCount();
  }

  deleteBulkImages() {
    this.confirmBulkDelete.Show(Messages.ConfirmBulkDeleteUnusedImage);
  }

  addAllImagesForBulkDelete() {
    if (this.isBulkDeleteAll) {
      if (this.filteredImages.length) {
        this.unusedImages.forEach(x => x.IsAddToBulkDelete = this.filteredImages.map(p => p.Name).includes(x.Name) ? true : x.IsAddToBulkDelete);
      } else {
        this.unusedImages.forEach(x => x.IsAddToBulkDelete = true);
      }
    } else {
      if (this.filteredImages.length) {
        this.unusedImages.forEach(x => x.IsAddToBulkDelete =
          this.filteredImages.map(p => p.Name).includes(x.Name) ? false : x.IsAddToBulkDelete);
      } else {
        this.unusedImages.forEach(x => x.IsAddToBulkDelete = false);
      }
    }
    this.refreshUnusedImagesCount();
  }

  onConfirmBulkDeleteAcknowledge() {
    const selectedImages = filter(this.unusedImages, (image: UnusedImage) => {
      return image.IsAddToBulkDelete;
    });
    if (selectedImages?.length) {
      this.spinnerService.show();
      this.menuSetupService.bulkDeleteButtonImages(selectedImages)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: () => {
            this.reloadUnusedImages();
            this.alertService.renderSuccessMessage(Messages.UnusedImageBulkDeleted);
          }, error: this.alertService.showApiError
        });
    }
  }

  deleteButton($event: UnusedImage) {
    this.deleteImage = $event;
    this.message = StringUtils.format(Messages.ConfirmDeleteUnusedImage,
      {
        'image': this.deleteImage.Name,
        'imagePath': this.imagePath
      });
    this.confirmImageDelete.Show(this.message);
  }

  onConfirmAcknowledge() {
    this.spinnerService.show();
    this.menuSetupService.deleteButtonImage(this.deleteImage.Name)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.reloadUnusedImages();
          this.alertService.renderSuccessMessage(Messages.UnusedImageDeleted);
        }, error: this.alertService.showApiError
      });
  }

  cancel() {
    this.router.navigate(['manage/console']);
  }

  reloadUnusedImages() {
    this.spinnerService.show();
    this.menuSetupService.getUnusedImages()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: response => {
          if (response) {
            this.unusedImages = response.map(x => ({ Name: x }));
          } else {
            this.unusedImages = [];
          }
          this.refreshUnusedImagesCount();
        }, error: this.alertService.showApiError
      });
  }

  refreshUnusedImagesCount() {
    this.unusedImagesCount = filter(this.unusedImages, x => x.IsAddToBulkDelete).length;
  }
}
