<pos-banner *ngIf="widgetConfig?.WidgetHeaderConfig" [data]="widgetConfig?.WidgetHeaderConfig"
    [styleData]="widgetTilesStyle?.WidgetHeaderStyleConfig" [borderStyle]="widgetTilesStyle?.BorderStyle"
    [imagePath]="imagePath"></pos-banner>
<div class="vertical-center-align flex-grow mt-pix-2" [ngStyle]="widgetContentStyle">
    <div class="display-flex flex-grow" *ngIf="tileItems">
        <ng-container *ngFor="let item of tileItems">
            <span  class="me-pix-1 ms-pix-1 mb-pix-2" [ngStyle]="{'width': tileWidth ?? 'inherit'}" *ngIf="item.IsInStock">
                <pos-tile [ngStyle]="tileBorder" class="display-block" [data]="item" [height]="tileHeight"
                    [textStyle]="textStyle" [backgroundColor]="tileBackgroundColor"
                    [descriptionStyle]="descriptionTextStyle" [priceStyle]="priceText">
                </pos-tile>
            </span>
        </ng-container>
    </div>
</div>
