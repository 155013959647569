import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { cloneDeep, forEach, sortBy } from 'lodash';
import { SalesProduct } from 'src/app/information-management';
import { WidgetComboItemModel } from 'src/app/menu-display/interfaces/widget-combo-item-model';
import { MenuWidgetService } from 'src/app/menu-display/services';
import { ConfirmDeleteModalComponent, Messages, ModalService } from 'src/app/shared';
import { cogWhite, deleteWhite, plus, arrowsAlt } from 'src/app/shared/components/icon';
import { ComboProductsConfigComponent } from './combo-products-config/combo-products-config.component';
declare let $: any;
@Component({
  selector: 'pos-combo-items-config',
  templateUrl: './combo-items-config.component.html',
  styleUrls: ['./combo-items-config.component.scss'],
})
export class ComboItemsConfigComponent implements OnInit, OnChanges {
  @Input() comboItems: Array<WidgetComboItemModel> = [];
  @Output() comboItemsChange = new EventEmitter<WidgetComboItemModel[]>();
  @Input() salesProductList: Array<SalesProduct> = [];
  containerHeight: string;

  icons = {
    plus,
    cogWhite,
    deleteWhite,
    arrowsAlt
  }
  constructor(protected menuWidgetService: MenuWidgetService,
    protected modalService: ModalService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('comboItems')) {
      this.comboItemsChange.emit(this.comboItems);
    }
  }

  ngOnInit(): void {
    this.getHeight()
    this.comboItems = sortBy(this.comboItems, ['Ordinal'])
  }
  getHeight() {
    this.containerHeight = $(window).height() - (340) + 'px';
  }
  addCombo() {
    const comboItem = this.menuWidgetService.newWidgetComboItemModel();
    comboItem.Ordinal = this.comboItems.length + 1;
    this.comboItems.push(comboItem);
    this.comboItemsChange.emit(this.comboItems);
  }

  onDeleteItem(index: number) {
    const confirmDelete = this.modalService.show(ConfirmDeleteModalComponent, {
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      initialState: {
        message: Messages.ConfirmDeleteWidgetComboItem
      }
    });

    confirmDelete.close.subscribe(res => {
      if (res && res.shouldDelete) {
        this.comboItems.splice(index, 1);
        this.comboItemsChange.emit(this.comboItems);
        this.reloadOrdinal();
      }
    })
  }
  configureCombo(index: number) {
    const modal = this.modalService.show(ComboProductsConfigComponent, {
      class: 'vertical-center',
      initialState: {
        comboSalesProducts: cloneDeep(this.comboItems[index].ComboSalesProduct)
      }
    })

    modal.close.subscribe(res => {
      if(res && res.shouldReload) {
        this.comboItems[index].ComboSalesProduct = res.comboSalesProducts;
      }
    })
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.comboItems, event.previousIndex, event.currentIndex);
    this.reloadOrdinal();
  }

  reloadOrdinal() {
    forEach(this.comboItems, (item, index) => {
      item.Ordinal = index + 1;
    })
  }
}
