<pos-edit-wrapper heading="Backups: {{menuDisplayName}}" (close)="onCancel()">
  <div class="col-12 pos-modal-body modal-content__list screen-scroll">
    <div class="col-12">
      <pos-menu-display-backup-edit [menuId]="menuId" (reload)="getBackups()"></pos-menu-display-backup-edit>
    </div>
    <div class="col-12">
      <ng-table #gridComponent [columns]="backupColumns" [sort]="false" [paginator]="false" [data]="backups">
      </ng-table>
      <ng-template let-data="rowData" #operationTemplate>
        <span class="vertical-mid pointer" (click)="confirmRestoreBackup(data)" title="Restore">
          <pos-icon [icons]="[icons.squareGreenStack, icons.undoWhite]"></pos-icon>
        </span>

        <span (click)="confirmDelete(data)" class="vertical-mid ps-pix-3 pointer" title="Delete">
          <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
        </span>
      </ng-template>
    </div>
  </div>
  <div class="clearfix"></div>
  <div class="col-12 action-button-container popup-footer">
    <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button"
      (click)="onCancel()">Close</button>
  </div>
</pos-edit-wrapper>
