import { AfterContentInit, Directive, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { finalize } from 'rxjs/internal/operators/finalize';
import { Subject } from 'rxjs/internal/Subject';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { ConfirmDeleteModalComponent } from 'src/app/shared/components/confirm-delete-modal';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { WidgetComponentProperties } from '../../interfaces/widget-component-properties';
import { BaseWidgetCrudService } from '../../services';
import { MenuDisplayItem, WidgetDetails, WidgetStyleConfig } from '../../interfaces';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { RabbitMQService } from 'src/app/shared/services/rabbitmq/services';
import { find, forEach } from 'lodash';
import { DomainConstants } from 'src/app/shared/constants';
declare let $;

@UntilDestroy({ checkProperties: true })
@Directive()
export abstract class BaseWidgetComponent implements OnInit, WidgetComponentProperties, AfterContentInit, OnDestroy {

  widgetId: number;
  private widgetsDataSubject = new Subject<any>();
  protected widgetForDelete: any;
  protected deleteSuccessMessage: string;
  autoRefreshWidgetInterval: any;
  rabbitMqFilterMenuItemsSubscription: Subscription;
  rabbitMqHighlightMenuItemsSubscription: Subscription;
  widgetData: WidgetDetails;
  highlightedMenuItem: MenuDisplayItem = null;
  filteredTagsAndWarningsIds: { Tags: Array<number>, DietaryWarnings: Array<number>, Widgets: Array<number> };
  actionType: string;
  resetMenuDisplayFilterSeconds: number;
  resetMenuFilterTimeout: ReturnType<typeof setTimeout>;
  menuItemHighlightColor = null;
  baseWidgetDataChangeSubscription: Subscription;

  constructor(protected baseWidgetCrudService: BaseWidgetCrudService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected applicationStateService: ApplicationStateService,
    protected modalService: ModalService,
    protected rabbitMQService: RabbitMQService) { }


  ngAfterContentInit(): void {
    this.resetMenuDisplayFilterSeconds = this.applicationStateService.settingParam.ResetMenuDisplayFilterSeconds;
    this.menuItemHighlightColor = this.applicationStateService.settingParam.MenuItemHighlightColor;

    this.baseWidgetCrudService.menuItemChange$.subscribe({
      next: (response) => {
        if (response?.Type == 'FilterMenuItems') {
          this.subscribeFilterMenuItemsMessage(response.Message);
        } else if (response?.Type == 'HighlightMenuItems') {
          this.subscribeHighlightMenuItemMessage(response.Message);
        }
      }
    });

  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.autoRefreshWidgetInterval) {
      clearInterval(this.autoRefreshWidgetInterval);
    }
    this.baseWidgetDataChangeSubscription?.unsubscribe();
  }

  private subscribeFilterMenuItemsMessage(message) {
    if (this.widgetData && (message.Payload?.Tags?.length || message.Payload?.DietaryWarnings?.length || message.Payload?.Widgets?.length)) {
      this.actionType = message.Payload.ActionType;
      this.setFilteredTagsAndWarnings(message.Payload.Tags, message.Payload.DietaryWarnings, message.Payload.Widgets);
      if (this.filteredTagsAndWarningsIds?.Tags?.length || this.filteredTagsAndWarningsIds?.DietaryWarnings?.length) {
        this.applyFilterByTagsAndWarnings();
      } 
      if (this.widgetData.WidgetType == DomainConstants.DigitalMenuWidgetTypes.Group.Name) {
        this.highlightWidget();
      }
      if (this.resetMenuDisplayFilterSeconds) {
        this.resetMenuInteractivityProperties();
      }
      this.widgetsDataSubject.next(this.widgetData);
    } else if (this.widgetData) {
      this.clearHighlightItems();
    }
  }

  highlightWidget() {
    if (this.filteredTagsAndWarningsIds.Widgets.includes(this.widgetId)) {
      $('.widget-background-' + this.widgetId)?.removeClass('display-none-imp');
      if (this.actionType === DomainConstants.MenuInteractivityActionType.Flash) {
        $('.widget-background-' + this.widgetId)?.addClass('interactive-group-widget-flash');
      } else {
        $('.widget-background-' + this.widgetId)?.removeClass('interactive-group-widget-flash');
      }
    } else {
      $('.widget-background-' + this.widgetId)?.addClass('display-none-imp');
    }
  }

  setFilteredTagsAndWarnings(tags: Array<number> = [], dietaryWarnings: Array<number> = [], widgets: Array<number> = []) {
    this.filteredTagsAndWarningsIds = { Tags: tags, DietaryWarnings: dietaryWarnings, Widgets: widgets };
  }

  private subscribeHighlightMenuItemMessage(message) {
    if (message?.Payload?.ProductId && this.widgetData) {
      this.actionType = message.Payload.ActionType;
      this.findAndHighlightMenuItem(message.Payload.ProductId);
      if (this.resetMenuDisplayFilterSeconds) {
        this.resetMenuInteractivityProperties();
      }
      this.widgetsDataSubject.next(this.widgetData);
    } else if (this.widgetData) {
      this.resetMenuInteractivityProperties();
    }
  }

  resetMenuInteractivityProperties() {
    this.clearMenuFilterTimeout();
    this.resetMenuFilterTimeout = setTimeout(() => {
      this.clearHighlightItems();
    }, this.resetMenuDisplayFilterSeconds * 1000);
  }

  private clearHighlightItems() {
    this.highlightedMenuItem = null;
    this.filteredTagsAndWarningsIds = null;
    this.widgetData?.MenuDisplayItems.forEach(menuDisplayItem => {
      menuDisplayItem.IsInteractive = false;
      menuDisplayItem.InteractiveStyleClass = null;
    });
    if (this.widgetData.WidgetType == DomainConstants.DigitalMenuWidgetTypes.Group.Name) {
      $('.widget-background-' + this.widgetId)?.addClass('display-none-imp');
      $('.widget-background-' + this.widgetId)?.removeClass('interactive-group-widget-flash');
    }
    this.widgetsDataSubject.next(this.widgetData);
    this.clearMenuFilterTimeout();
  }

  clearMenuFilterTimeout() {
    if (this.resetMenuFilterTimeout) {
      clearTimeout(this.resetMenuFilterTimeout);
    }
  }

  protected get widgetData$(): Observable<WidgetDetails> {
    return this.widgetsDataSubject.asObservable();
  }

  protected loadWidget(widgetId: number) {
    this.baseWidgetDataChangeSubscription = this.baseWidgetCrudService.widgetDataSubject
    .subscribe({next: (res) => {
      this.widgetData = res.find(x => x.Id == widgetId);
      if(this.widgetData) {
        this.highlightMenuItem();
        this.widgetsDataSubject.next(this.widgetData);
      }
    }});
  }

  highlightMenuItem() {
    if (this.highlightedMenuItem) {
      this.findAndHighlightMenuItem(this.highlightedMenuItem.Id);
    } else if (this.filteredTagsAndWarningsIds?.Tags?.length || this.filteredTagsAndWarningsIds?.DietaryWarnings?.length) {
      this.applyFilterByTagsAndWarnings();
    } 
    if (this.filteredTagsAndWarningsIds?.Widgets.length && this.widgetData.WidgetType == DomainConstants.DigitalMenuWidgetTypes.Group.Name) {
      this.highlightWidget();
    }
  }

  findAndHighlightMenuItem(salesProductId) {
    this.highlightedMenuItem = find(this.widgetData.MenuDisplayItems, x => x.Id === salesProductId);
    forEach(this.widgetData.MenuDisplayItems, menuDisplayItem => {
      menuDisplayItem.IsInteractive = menuDisplayItem.Id === salesProductId;
      menuDisplayItem.InteractiveStyleClass = this.getInteractiveStyleClass(menuDisplayItem.IsInteractive);
    });
  }

  applyFilterByTagsAndWarnings() {
    forEach(this.widgetData.MenuDisplayItems, menuDisplayItem => {
      menuDisplayItem.IsInteractive =
        (this.filteredTagsAndWarningsIds.Tags?.every(tagId => menuDisplayItem.Tags?.map(x => x.Id).includes(tagId))
          && !menuDisplayItem.DietaryWarnings?.some(warningId => this.filteredTagsAndWarningsIds.DietaryWarnings.includes(warningId)));
      menuDisplayItem.InteractiveStyleClass = this.getInteractiveStyleClass(menuDisplayItem.IsInteractive);
    });
  }

  getInteractiveStyleClass(isInteractive: boolean) {
    document.documentElement.style.setProperty('--menu-item-highlight-color', this.menuItemHighlightColor);
    if (isInteractive && this.actionType === DomainConstants.MenuInteractivityActionType.Highlight
      && this.widgetData.WidgetType !== DomainConstants.DigitalMenuWidgetTypes.Text.Name) {
      return 'interactive-menu-item-highlight';
    } else if (isInteractive && this.actionType === DomainConstants.MenuInteractivityActionType.Flash
      && this.widgetData.WidgetType !== DomainConstants.DigitalMenuWidgetTypes.Text.Name) {
      return 'interactive-menu-item-flash';
    } else if (!isInteractive && this.actionType === DomainConstants.MenuInteractivityActionType.HideExclusions) {
      return 'interactive-menu-item-callout';
    } else {
      return '';
    }
  }

  protected delete(widgetId: number): void {
    this.spinnerService.show();
    this.baseWidgetCrudService.delete(widgetId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: x => {
          this.showDeletedSuccessfullyMessage();
        }, error: this.alertService.showApiError
      });
  }

  protected confirmDelete(data: any): void {
    this.widgetForDelete = data;
    const confirmDelete = this.modalService.show(ConfirmDeleteModalComponent, {
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      initialState: {
        message: Messages.ConfirmDeleteDigitalMenuWidget
      }
    });
    confirmDelete.close.subscribe(res => {
      if (res && res.shouldDelete) {
        this.delete(this.widgetForDelete.Id);
      }
      this.widgetForDelete = null;
    });
  }

  protected showDeletedSuccessfullyMessage(): void {
    this.alertService.renderSuccessMessage(this.deleteSuccessMessage);
  }

  protected parseJson<T>(jsonString: string): T {
    if (jsonString) {
      jsonString = jsonString.trim();
      try {
        const parsedJson: T = JSON.parse(jsonString);
        return parsedJson;
      } catch (ex) {
        this.alertService.renderErrorMessage(Messages.InvalidJsonFormat);
        return;
      }
    } else { return; }
  }

  abstract editWidget(id: number, data?: any): void;
  protected afterDelete() { }
}
