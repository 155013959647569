import { Component, OnInit, ViewChild } from '@angular/core';
import { SettingParam, FieldInfoMessages, AlertsService, SpinnerService, ApplicationStateService, Messages, FormUtilityService, BaseFormComponent } from 'src/app/shared';
import { DeliveryService } from '../../services/delivery.service';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { deliveryTruckMoving } from 'src/app/shared/components/icon';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'pos-delivery',
  templateUrl: './delivery.component.html',
})
export class DeliveryComponent extends BaseFormComponent implements OnInit {

  parameters: SettingParam;
  fieldInfoMessages = FieldInfoMessages;
  icons = {
    deliveryTruckMoving
  };
  @ViewChild('deliveryForm') deliveryForm: NgForm;
  get getForm(): NgForm {
    return this.deliveryForm
  }
  constructor(private alertsService: AlertsService,
    private deliveryService: DeliveryService,
    private spinnerService: SpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private applicationStateService: ApplicationStateService,
    formUtilityService: FormUtilityService) {
    super(formUtilityService);
    this.parameters = applicationStateService.newSettingParams();
  }

  ngOnInit() {
    this.loadParameters();
  }

  loadParameters() {
    this.spinnerService.show();
    this.deliveryService.getDeliveryInfo(this.applicationStateService.terminalId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: SettingParam) => {
          this.parameters = response;
        }, error: this.alertsService.showApiError
      });
  }

  saveDelivery(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.spinnerService.show();
    this.deliveryService.saveDelivery(this.parameters)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.alertsService.renderSuccessMessage(Messages.DeliverySaveSuccess);
          this.router.navigate(['manage/system-configuration']);
        }
      });
  }

  cancel() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
