<div>
  <button class="close" (click)="onCancel(false)">&times;</button>
  <h4 class="modal-content__heading">Training Topics: {{trainingAreaName}}</h4>
</div>
<div class="overflow-hidden">
  <form autocomplete="off" class="col-xs-12 pt-pix-15">
    <div class="col-md-12 pos-modal-body px-pix-5 overflow-tab show" style="min-height: 300px; max-height: 400px;">
      <p-accordion *ngIf="trainingTopics?.length">
        <p-accordionTab class="pb-pix-10" *ngFor="let trainingTopic of trainingTopics">
          <p-header class="width-full">{{trainingTopic.Name}}
            <span class="float-end font-02" *ngIf="trainingTopic.ApprovedAllLevels">
              <pos-icon [icon]="icons.trainingApproveCheckCircle"></pos-icon>
            </span>
          </p-header>

          <div class="pb-pix-5 pt-pix-5"
            *ngFor="let trainingTopicsLevel of trainingTopic.TrainingTopicLevels | orderBy: 'LevelNumber'"
            (click)="trainingTopicLevelOpen(trainingTopicsLevel,trainingTopic)">

            <span class="badge badge-level">
              {{trainingTopicsLevel.LevelNumber}}
            </span>

            <a class="no-decoration"
              [ngStyle]="{'color':trainingTopicsLevel.IsEnabled?'#ea5831':'black', 'cursor':trainingTopicsLevel.IsEnabled?'pointer':'not-allowed'}">
              {{trainingTopicsLevel.Description}}
            </a>

            <span class="float-end" [hidden]="trainingTopicsLevel.IsEnabled">
              <pos-icon [icon]="icons.lock"></pos-icon>
            </span>
            <span class="float-end" [hidden]="!trainingTopicsLevel.isCompleted">
              <pos-icon [icon]="icons.checkCircle"></pos-icon>
            </span>
          </div>

          <div *ngIf="trainingTopic.TrainingTopicLevels?.length == 0">No levels
            available.</div>

        </p-accordionTab>
      </p-accordion>
      <div class="alert condensed inherit-position alert-warning mb-pix-10 mt-pix-10" *ngIf="trainingTopics?.length == 0">
        There are no topic(s) assigned to you.
      </div>
    </div>
  </form>
  <div class="col-12 action-button-container popup-footer pb-pix-10">
    <button class="btn-secondary btn-lg btn btn-cancel-modal pos-button" type="button" (click)="onCancel(false)">Close</button>
  </div>
  <div class="clearfix"></div>
</div>
