import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ColorUtilityService, DashboardConfiguration, DashboardConfigurationService, ModalService, TableWidgetConfiguration } from 'src/app/shared';
import { forEach, find, cloneDeep } from 'lodash';
import { DateColumn, GridColumn, TextAlign } from '@tarktech/tark-ng-utils';

import { BaseWidgetService } from 'src/app/adf/services/base-widget.service';
import * as moment from 'moment';
import { Observable, interval as observableInterval, Subscription } from 'rxjs';
import { DashboardBroadcastingService } from 'src/app/adf/services/dashboard-broadcasting.service';
import { AdfModel } from 'src/app/adf/interfaces/adf-model';
import { WidgetEditComponent } from '../widget-edit/widget-edit.component';

@Component({
  selector: 'pos-table-widget',
  templateUrl: './table-widget.component.html',
  styleUrls: ['./table-widget.component.scss']
})
export class TableWidgetComponent implements OnInit, OnDestroy {

  @Input() widgetConfiguration: DashboardConfiguration;
  data = [];
  @Input() isInteractive = false;
  @Input() config?: TableWidgetConfiguration;
  copiedConfig: TableWidgetConfiguration;
  isLoading = true;
  columns: Array<GridColumn> = [];
  timer: Observable<number>;
  timerSubscription: any;
  eventsSubscription: Array<Subscription> = [];
  @Input() adfModel: AdfModel;
  @Input() column: any;
  constructor(private dashboardService: DashboardConfigurationService,
    private modalService: ModalService,
    private colorConversionService: ColorUtilityService,
    private baseWidgetService: BaseWidgetService,
    private dashboardBroadcastingService: DashboardBroadcastingService) { }

  ngOnInit(): void {
    this.copiedConfig = cloneDeep(this.config);
    if (this.widgetConfiguration && !this.widgetConfiguration.isNewWidget) {
      this.baseWidgetService.loadBaseWidget(this.widgetConfiguration, this.config, this.getWidgetCompleted);
      this.startAutoIntervalTimer();
    } else {
      this.editTextWidget();
    }
    this.subscribeBroadcastEvents();
  }

  subscribeBroadcastEvents = () => {
    this.eventsSubscription.push(this.dashboardBroadcastingService.reloadWidget.subscribe((widget) => {
      if (widget && widget.widgetAssignmentId > 0) {
        this.reloadWidget(widget);
      }
    }));
  }

  reloadWidget = (widget) => {
    if (this.widgetConfiguration.widgetAssignmentId == widget.widgetAssignmentId) {
      this.isLoading = true;
      this.widgetConfiguration = cloneDeep(widget);
      this.config = widget.config;
      this.widgetConfiguration.isReloadWidget = true;
      this.baseWidgetService.loadBaseWidget(this.widgetConfiguration, this.config, this.getWidgetCompleted);
    }
  }

  editTextWidget() {
    const modalRef = this.modalService.getModalWrapper(WidgetEditComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center modal-lg',
      initialState: {
        widgetConfig: cloneDeep(this.widgetConfiguration),
      }
    });
    modal.close.subscribe((res) => {
      if (res && res.reload) {
        this.widgetConfiguration = res.widgetConfiguration;
        this.widgetConfiguration.isNewWidget = false;
        this.config = res.widgetConfiguration.config;
        this.updateAdfModel();
        this.baseWidgetService.loadBaseWidget(this.widgetConfiguration, this.config, this.getWidgetCompleted);
      } else {
        this.isLoading = false;
        this.deleteWidget()
      }
    });
  }

  updateAdfModel = () => {
    const rowIndex = this.widgetConfiguration.rowIndex > 0 ? this.widgetConfiguration.rowIndex - 1 : 0;
    const columnIndex = this.widgetConfiguration.columnIndex ? this.widgetConfiguration.columnIndex - 1 : 0;
    if (this.adfModel && this.adfModel.rows) {
      if (this.adfModel.rows[rowIndex] && this.adfModel.rows[rowIndex].columns) {
        if (this.adfModel.rows[rowIndex].columns[columnIndex].widgets) {
          this.adfModel.rows[rowIndex].columns[columnIndex].widgets[0] = this.widgetConfiguration;
        }
      }
    }
  }

  deleteWidget() {
    if (this.column) {
      const index = this.column.widgets.indexOf(this.widgetConfiguration);
      if (index >= 0) {
        this.column.widgets.splice(index, 1);
        this.dashboardBroadcastingService.widgetDelete(this.widgetConfiguration);
      }
    }
  }

  ngOnDestroy(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
    forEach(this.eventsSubscription, (subscribe) => {
      if (subscribe) {
        subscribe.unsubscribe();
      }
    });
  }

  startAutoIntervalTimer = () => {
    if (this.widgetConfiguration && this.widgetConfiguration.AutoRefreshInterval) {
      this.timer = observableInterval(this.widgetConfiguration.AutoRefreshInterval * 60 * 1000);
      this.timerSubscription = this.timer.subscribe((t) => {
        this.widgetConfiguration.isReloadWidget = true;
        this.baseWidgetService.loadBaseWidget(this.widgetConfiguration, this.config, this.getWidgetCompleted);
      });
    }
  }

  getWidgetCompleted = (response) => {
    this.isLoading = false;
    this.widgetConfiguration.isReloadWidget = false;
    this.columns = [];
    this.data = response ? response : [];
    this.populateTable();
    this.baseWidgetService.setWidgetHeights(this.adfModel);
    if (this.config && this.config.params && this.config.params.length) {
      this.config = this.copiedConfig;
    }
  }

  populateTable() {
    const hiddenColumnList = this.config.hideColumns ? this.config.hideColumns.split(',') : [];
    if (this.data.length > 0) {
      const columns = [];
      const dateColumns = [];
      forEach(Object.keys(this.data[0]), (item) => {
        const hiddenColumn = find(hiddenColumnList, x => x.trim().toLowerCase() === item.toLowerCase());
        if (!item.toLowerCase().startsWith('meta_') && !hiddenColumn) {
          columns.push(item);
        }
        const isDate = item && this.data[0][item] && this.isValidDate(this.data[0][item]);
        if (isDate) { dateColumns.push(item); }
      });
      forEach(columns, (column) => {
        const isDateColumn = find(dateColumns, dateColumn => dateColumn === column);
        if (isDateColumn) {
          this.columns.push(new DateColumn({
            HeaderText: this.dashboardService.splitCamelCaseToString(column),
            Field: column, IsSortable: true
          }));
        } else {
          this.columns.push(new GridColumn({
            HeaderText: this.dashboardService.splitCamelCaseToString(column),
            Field: column, IsSortable: true, TextAlign: this.getCellStyle(this.data[0][column])
          }));
        }
      });
      this.setItemFontColor();
    }
  }

  private setItemFontColor() {
    forEach(this.data, (item) => {
      const metaColor = find(Object.keys(item), x => x.toLowerCase().startsWith('meta_'));
      if (item[metaColor]) {
        item.FontColor = this.colorConversionService.getContrastColor(item[metaColor]);
      }
    });
  }

  getCellStyle(params) {
    if ((params && !isNaN(params.toString().replace(/[()]/g, '')))) { // || params == '0'
      return TextAlign.Right;
    } else if (params && this.isValidDate(params) || params === false || params === true) {
      return TextAlign.Center;
    } else {
      return null;
    }
  }

  getCellRenderer(params) {
    if (params.value === false || params.value === true) {
      const check = '';
      const element = document.createElement('span');
      const i = document.createElement('i');
      if (params.value === true) {
        element.setAttribute('class', 'green');
        i.setAttribute('class', check);
      }
      element.appendChild(i);
      return element;
    } else {
      return params.value;
    }
  }

  getFormattedValue(value) {

    if (isNaN(value) && this.isValidDate(value)) {
      const d = moment(value, 'YYYY-M-DT hh:mm A');
      if (this.isDateTimeFormat(value, d)) {
        return d.format('MM/DD/YYYY hh:mm A');
      }
    }

    return value;
  }

  isValidDate(str) {

    const d1 = moment(str, 'M/D/YYYY');
    const d2 = moment(str, 'YYYY-M-DT');

    if (d1 != null && d1.isValid()) {
      return this.isDateFormat(str, d1);
    }

    if (d2 != null && d2.isValid()) {
      return this.isDateTimeFormat(str, d2);
    }

    return false;
  }

  isDateFormat(str, d) {
    str = str.toString();
    return d != null && d.isValid() && (str.indexOf(d.format('M/D/YYYY')) >= 0
      || str.indexOf(d.format('MM/DD/YYYY')) >= 0
      || str.indexOf(d.format('M/D/YY')) >= 0
      || str.indexOf(d.format('MM/DD/YY')) >= 0);
  }

  isDateTimeFormat(str, d) {
    str = str.toString();
    return d != null && d.isValid() && (str.indexOf(d.format('YYYY-M-DT')) >= 0
      || str.indexOf(d.format('YYYY-MM-DDT')) >= 0
      || str.indexOf(d.format('YY-M-DT')) >= 0
      || str.indexOf(d.format('YY-MM-DDT')) >= 0);
  }

  editTableWidget() {
    const modalRef = this.modalService.getModalWrapper(WidgetEditComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center modal-lg',
      initialState: {
        widgetConfig: cloneDeep(this.widgetConfiguration),
      }
    });
    modal.close.subscribe((res) => {
      if (res && res.shouldReload) {
        this.widgetConfiguration = res.widgetConfiguration;
      }
    });
  }

}
