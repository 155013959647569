<pos-kiosk-header *ngIf="screenConfig.Header.IsShow" [header]="screenConfig.Header" />
<div class="row mx-0 designer-area" [style.background-color]="screenConfig.BackgroundColor"
    [style.height]="sectionHeight + 'px'"
    [style.background-image]="screenConfig.BackgroundImage ? 'url(\''+ imageRootPath + screenConfig.BackgroundImage + '\')' : null">
    <div class="p-0" [ngClass]="screenConfig.ShowInvoice ? 'col-xl-9 col-8' : 'col-12'">
        <div class="allergens-title" *ngIf="screenConfig.Title || screenConfig.Subtitle">
            <p> {{screenConfig.Title}} </p>
            <p class="font-14 normal">{{screenConfig.Subtitle}}</p>
        </div>
        <div class="overflow-auto dietary-warnings-section" [style.height]="warningsHeight + 'px'">
            <div class="warning-item" *ngFor="let warning of dietaryWarnings" [ngClass]="{'has-image': screenConfig.IsShowImage }"
                [ngStyle]="{'background-color': screenConfig.ButtonBackgroundColor, 'color': screenConfig.ButtonFontColor }">
                <img *ngIf="screenConfig.IsShowImage" [src]="allergenRootPath + warning.Image" />
                <p class="text pt-pix-4">{{ warning.Name | uppercase }}
                    <img *ngIf="!screenConfig.IsShowImage" class="float-end mt-pix-n3" height="25"
                        [src]="imageRootPath+'allergen-selected-icon.png'" />
                </p>
            </div>
            <div *ngIf="!dietaryWarnings?.length" class="text-danger bold">No dietary warning(s) selected.</div>
        </div>
        <div class="allergen-notes w-75 m-auto" *ngIf="screenConfig.AllergenNote"
            [innerHTML]="screenConfig.AllergenNote"></div>
        <div class="action-buttons">
            <button class="btn text-uppercase" (click)="buttonClick(screenButtons[0])">
                <div class="config-button">
                    <pos-kiosk-screen-setup [screenButton]="screenButtons[0]" [screenButtons]="screenButtons"
                        [isDesignMode]="isDesignMode" [setupItems]="setupItems"></pos-kiosk-screen-setup>
                </div>
                <img class="mt-pix-n3 me-pix-2" height="18" [src]="imageRootPath+'back-arrow.png'" /> Back to Allergy
                selection
            </button>
            <button class="btn text-uppercase" (click)="buttonClick(screenButtons[1], true)">
                <div class="config-button">
                    <pos-kiosk-screen-setup [screenButton]="screenButtons[1]" [screenButtons]="screenButtons"
                        [isDesignMode]="isDesignMode" [setupItems]="setupItems"></pos-kiosk-screen-setup>
                </div>
                Confirm Allergies and Continue <img class="mt-pix-n3 ms-pix-2" height="18"
                    [src]="imageRootPath+'continue-arrow.png'" />
            </button>
        </div>
    </div>
    <div class="col-xl-3 col-4 p-3" *ngIf="screenConfig.ShowInvoice" [ngStyle]="{'height': invoiceHeight - 3 + 'px'}">
        <pos-kiosk-invoice></pos-kiosk-invoice>
    </div>
</div>
<pos-kiosk-footer *ngIf="screenConfig.Footer.IsShow" [buttons]="screenButtons"
    [footer]="screenConfig.Footer"></pos-kiosk-footer>
