import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseCrudService } from 'src/app/shared/services';
import { EmailTemplate } from '../interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailTemplateService extends BaseCrudService<EmailTemplate> {

  constructor(private httpClient: HttpClient) {
    super('emailTemplates', httpClient);
  }

  getEmailTemplatePreview(templateId: number): Observable<EmailTemplate> {
      return this.httpClient.get<EmailTemplate>(`${this.apiEndpoint}/${templateId}/preview`);
  }

  newEmailTemplate() {
    const emailTemplate: EmailTemplate = {
      Id: 0,
      Name: '',
      KeyName: '',
      Value: '',
      Variables: null,
    };
    return emailTemplate;
  }
}
