import { NgPipesModule } from 'ngx-pipes';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../shared';
import { FormsModule } from '@angular/forms';
import { TaskComponent } from './component/task/task.component';
import { CalendarModule } from 'primeng/calendar';

@NgModule({
    imports: [
      CommonModule,
      ComponentsModule,
      FormsModule,
      NgPipesModule,
      CalendarModule
    ],
    declarations:[TaskComponent],
    providers: [],
    bootstrap: []
  })
  export class TaskModule { }
