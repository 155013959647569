<pos-edit-wrapper (close)="cancelEditing()" [isPopup]="false" [headingIcon]="icons.infoManageTimeClockEntriesClock"
  heading="{{id > 0 ? 'Edit Time Clock Entry': 'Add Time Clock Entry'}}">
  <form #formTimeClockEdit="ngForm" class="edit-form" (ngSubmit)="submit(formTimeClockEdit.valid)">
    <div class="col-12 pos-page-body border-top pt-pix-5">
      <div class="col-12">
        <pos-row>

          <pos-column>
            <pos-form-field label="User" [validationMessages]="{required: 'Please select user.'}">
              <ng-select posInput [items]="users" [hideSelected]="true" appendTo="body" bindLabel="name" bindValue="id"
                placeholder="Select user" [(ngModel)]="timeClockEntryDetail.UserId" name="user" [required]="true"
                [disabled]="id != 0">
              </ng-select>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Clock In Date" [validationMessages]="{required: 'Please select clock in date.'}">
              <p-calendar posInput [(ngModel)]="timeClockEntryDetail.ClockIn" showButtonBar="true" [showIcon]="true"
                [dateFormat]="dateFormat" [readonlyInput]="true" name="ClockInDate" yearNavigator="true"
                yearRange="2000:2099" #ClockInDate="ngModel" [monthNavigator]=true [required]="true"
                [inputStyle]="{'width':'unset','display':'unset'}" inputStyleClass="form-control"></p-calendar>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Time">
              <p-calendar posInput [(ngModel)]="timeClockEntryDetail.ClockInTime" [timeOnly]="true" [inline]="true"
                [hourFormat]="hourFormat" name="ClockInTime" #ClockInTime="ngModel"
                [inputStyle]="{'width':'215px'}" inputStyleClass="form-control">
              </p-calendar>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Clock Out Date" *ngIf="!timeClockEntryDetail.StillClockedIn" [validationMessages]="{required: 'Please select clock out date.'}">
              <p-calendar posInput [(ngModel)]="timeClockEntryDetail.ClockOut" showButtonBar="true" [showIcon]="true"
                  [dateFormat]="dateFormat" [readonlyInput]="true" yearNavigator="true" yearRange="2000:2099"
                  name="ClockOutDate" #ClockOutDate="ngModel" [monthNavigator]=true [required]="true"
                  [inputStyle]="{'width':'unset','display':'unset'}" inputStyleClass="form-control"></p-calendar>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Time" *ngIf="!timeClockEntryDetail.StillClockedIn">
              <p-calendar posInput [(ngModel)]="timeClockEntryDetail.ClockOutTime" [timeOnly]="true" [inline]="true"
                [hourFormat]="hourFormat" name="ClockOutTime" #ClockOutTime="ngModel"
                [inputStyle]="{'width':'215px'}" inputStyleClass="form-control">
              </p-calendar>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field>
              <p-checkbox name="chkStillClockedIn" #chkStillClockedIn="ngModel"
                [(ngModel)]="timeClockEntryDetail.StillClockedIn" binary="true" label="Still Clocked In">
              </p-checkbox>
            </pos-form-field>
          </pos-column>

        </pos-row>
      </div>
    </div>
    <div class="col-12 action-button-container page-footer">
      <button class="btn-primary btn-lg btn btn-save pos-button" scrollup type="submit" value="Save">Save</button>
      <button class="btn-secondary btn-lg btn btn-cancel pos-button" (click)="cancelEditing()" scrollup type="button"
        value="Cancel">Cancel</button>
    </div>
  </form>
</pos-edit-wrapper>
