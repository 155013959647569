import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { cloneDeep, forEach } from 'lodash';
import { DietaryWarning } from 'src/app/information-management';
import { KioskScreenConfig, KioskScreenChoice } from 'src/app/order-kiosk/interfaces';
import { AllergenScreenConfig } from 'src/app/order-kiosk/interfaces/config/allergen-screen-config';
import { KioskConfigSetupItem } from 'src/app/order-kiosk/interfaces/kiosk-config-setup-items';
import { KioskNavigationService, OrderKioskChoiceService, OrderKioskConfigService } from 'src/app/order-kiosk/services';
import { AlertsService, DomainConstants, InventoryProductDietaryWarningService, RuntimeConstants } from 'src/app/shared';
declare let $: any;

@Component({
  selector: 'pos-allergens-screen-one',
  templateUrl: './allergens-screen-one.component.html',
  styleUrls: ['./allergens-screen-one.component.scss']
})
export class AllergensScreenOneComponent {
  @Input() screenConfigs: Array<KioskScreenConfig> = [];
  @Input() screenId: number;
  @Input() screenButtons: Array<KioskScreenChoice> = [];
  screenConfig: AllergenScreenConfig;
  imageRootPath = `${RuntimeConstants.IMAGE_BASE_PATH}/order-kiosk/`;
  allergenRootPath = `${RuntimeConstants.IMAGE_BASE_PATH}/allergens/`;
  sectionHeight: number;
  warningsHeight: number;
  isDesignMode = false;
  setupItems: KioskConfigSetupItem;
  dietaryWarnings: Array<DietaryWarning> = [];
  selectedDietaryWarnings: Array<DietaryWarning> = [];

  constructor(private orderKioskConfigService: OrderKioskConfigService,
    private kioskChoiceService: OrderKioskChoiceService,
    private route: ActivatedRoute,
    private kioskNavigationService: KioskNavigationService,
    private alertsService: AlertsService,
    private inventoryProductDietaryWarningService: InventoryProductDietaryWarningService
  ) {
    this.isDesignMode = route.snapshot.params.id;
    this.screenConfig = orderKioskConfigService.newScreenConfig();
  }

  ngOnInit() {
    this.resizeWindow();
    this.prepareConfigs();
    this.calculateSectionHeight();
    if (!this.kioskNavigationService.allDietaryWarnings?.length) {
      this.getDietaryWarnings();
    } else {
      this.mapDietaryWarnings();
    }
    this.setButtonsInScreenButtons();
    this.setupItems = { IsEditable: true, SetButton: false, RemoveButton: false };
  }

  setButtonsInScreenButtons() {
    forEach(this.screenButtons, screenButton => {
      screenButton.Button = this.kioskChoiceService.newChoice();
    });
  }

  prepareConfigs() {
    const screenConfig = this.orderKioskConfigService.parseConfigProperties(DomainConstants.KioskScreenConfigProperties.ScreenConfig, this.screenConfigs);
    if (screenConfig) this.screenConfig = screenConfig;
  }

  ngAfterViewInit(): void {
    this.calculateSectionHeight();
  }

  calculateSectionHeight() {
    this.sectionHeight = this.orderKioskConfigService.calculateInvoiceHeight(this.screenConfig);
    this.warningsHeight = this.sectionHeight - ($('.allergens-title').outerHeight() ?? 0) - $('.action-buttons').outerHeight() - (this.screenConfig.Title ? 25 : 0);
  }

  resizeWindow() {
    $(window).resize(() => {
      this.calculateSectionHeight();
    });
  }

  mapDietaryWarnings() {
    this.dietaryWarnings = cloneDeep(this.kioskNavigationService.allDietaryWarnings);
    this.dietaryWarnings.map(x => x.IsSelected = this.kioskNavigationService.dietaryWarnings.map(y => y.Id).includes(x.Id));
    this.selectedDietaryWarnings = this.dietaryWarnings.filter(x => x.IsSelected);
  }

  getDietaryWarnings() {
    this.inventoryProductDietaryWarningService.getDietaryWarnings()
      .subscribe({
        next: (res) => {
          this.kioskNavigationService.allDietaryWarnings = res;
          this.mapDietaryWarnings();
        }, error: this.alertsService.showApiError
      });
  }

  selectDietaryWarning(warning: DietaryWarning) {
    warning.IsSelected = !warning.IsSelected;
    if (warning.IsSelected) {
      this.selectedDietaryWarnings.push(warning);
    } else {
      this.selectedDietaryWarnings = this.selectedDietaryWarnings.filter(x => x.Id != warning.Id);
    }
  }

  buttonClick = (kioskScreenChoice: KioskScreenChoice, isSetDietaryWarning = false) => {
    if (!this.isDesignMode) {
      if (!isSetDietaryWarning && this.kioskNavigationService.dietaryWarnings?.length) {
        this.kioskNavigationService.dietaryWarnings = [];
        this.kioskNavigationService.applyDietaryWarnings();
      } else this.kioskNavigationService.dietaryWarnings = this.selectedDietaryWarnings;
      kioskScreenChoice.Button.NextScreenId = kioskScreenChoice.NextScreenId;
      this.kioskNavigationService.buttonClick(kioskScreenChoice.Button, this.screenId, kioskScreenChoice.ConfigId);
    }
  }
}
