import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FontStyleConfig } from 'src/app/shared';
import { MenuWidgetService } from 'src/app/menu-display/services';

@Component({
  selector: 'pos-font-style-config',
  templateUrl: './font-style-config.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class FontStyleConfigComponent implements OnChanges {

  @Input() fontStyleConfig: FontStyleConfig;
  @Input() prefix: string;
  @Output() fontStyleConfigChange = new EventEmitter<FontStyleConfig>();
  constructor(protected menuWidgetService: MenuWidgetService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('fontStyleConfig') && !this.fontStyleConfig) {
      this.fontStyleConfig = this.menuWidgetService.getNewFontStyleConfig();
    }
    this.fontStyleConfigChange.emit(this.fontStyleConfig);
  }

}
