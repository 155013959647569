import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridColumn, TableComponent, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { remove } from 'lodash';
import {
  AlertsService,
  ApplicationStateService,
  AuthenticationService, BaseListComponent,
  DomainConstants,
  Permissions,
  Levels,
  Messages,
  ModalService,
  PrintTableService,
  SpinnerService,
  StringUtils,
  editSolid
} from 'src/app/shared';
import { ReportModel } from '../../interface/report-model';
import { ReportConfigurationService } from '../../service/report-configuration.service';

@Component({
  selector: 'pos-reports-configuration',
  templateUrl: './reports-configuration.component.html',
})
export class ReportsConfigurationComponent extends BaseListComponent<ReportModel> implements OnInit {
  printReportColumns: Array<GridColumn> = [];
  reportConfiguration: Array<ReportModel> = [];
  reportConfigurationColumns: Array<GridColumn> = [];
  permission = {
    name: Permissions.SystemConfigurationReports,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };
  icons = { editSolid };
  @ViewChild('IconTemplate', { static: true }) iconTemplate: TemplateRef<any>;
  @ViewChild('report', { static: true }) report: TableComponent;
  constructor(
    private authenticationService: AuthenticationService,
    protected reportConfigurationService: ReportConfigurationService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    private router: Router,
    protected applicationStateService: ApplicationStateService,
    protected printService: PrintTableService,
    protected route: ActivatedRoute
  ) {
    super(
      reportConfigurationService,
      alertService,
      spinnerService,
      modalService,
      applicationStateService,
      printService,
      route
    );
  }

  ngOnInit(): void {
    this.reportConfigurationColumns = this.configureGridColumns();
    this.deleteSuccessMessage = Messages.ReportDeleted;
    this.load();
    this.checkPermission();
    this.report.context = this.gridContext;
  }

  private checkPermission(): void {
    if (!this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      remove(this.reportConfigurationColumns, (column) => {
        return (column.Type === DomainConstants.GridColumnsTypes.EditColumn
          || column.Type === DomainConstants.GridColumnsTypes.DeleteColumn);
      });
    }
  }

  load() {
    this.data$.subscribe({
      next: (response) => {
        this.reportConfiguration = response;
        this.reportConfiguration.forEach(x => x.ReportCategoryName = x.ReportCategory.Name);
        this.reportConfiguration.forEach(x => x.ReportGroupName = x.ReportGroup?.Name);
      }
    });
  }

  getGridColumns(): GridColumn[] {
    const ReportIcon = new TemplateColumn({
      Width: '27px',
      CellClass: 'no-right-border pri-0',
      itemTemplate: this.iconTemplate,
      TextAlign: TextAlign.Center
    });
    const columns = [
      new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '45%', CellClass: 'no-left-border' }),
      new GridColumn({ HeaderText: 'Group', Field: 'ReportGroupName', IsSortable: true, Width: '20%' }),
      new GridColumn({ HeaderText: 'Category', Field: 'ReportCategoryName', IsSortable: true, Width: '20%' }),
    ];
    this.printReportColumns = [...columns];
    columns.unshift(ReportIcon);
    return columns;
  }

  getConfirmDeleteMessage(data: ReportModel): string {
    return StringUtils.format(Messages.ConfirmDeleteReport, { 'reportName': data.Name });
  }

  printData() {
    this.printService.printEmitter.next({
      gridColumns: this.printReportColumns,
      gridData: this.reportConfiguration,
    });
  }

  editItem(id: number, data?: ReportModel): void {
    this.router.navigate([id], { relativeTo: this.route });
  }

  gridRowReorder(event) {
    this.reportConfigurationService.updateReportOrdinal(this.reportConfiguration)
      .subscribe({
        next: (res) => {
          this.reportConfiguration = res ? res : [];
          this.reportConfiguration.forEach(x => x.ReportCategoryName = x.ReportCategory.Name);
          this.reportConfiguration.forEach(x => x.ReportGroupName = x.ReportGroup?.Name);
        }, error: this.alertService.showApiError
      });
  }

  close() {
    this.router.navigate(['manage/system-configuration']);
  }
}
