import { Component, OnInit } from '@angular/core';
import { ModalComponent, ApplicationStateService, AlertsService } from 'src/app/shared';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TerminalsService } from '../../services';

@Component({
  selector: 'pos-global-logoff',
  templateUrl: './global-logoff.component.html',
})
export class GlobalLogoffComponent extends ModalComponent implements OnInit {

  logoffMessage: string = null;

  constructor(modalRef: BsModalRef,
    private terminalService: TerminalsService,
    private applicationStateService: ApplicationStateService,
    private alertsService: AlertsService) {
    super(modalRef);
  }

  ngOnInit() {
  }

  globalLogoff(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.close.emit();
    this.applicationStateService.signInAsDiffUserTerminalDetail = null;
    this.terminalService.globalLogoff(this.logoffMessage, this.applicationStateService.userDetails)
      .subscribe({
        next: () => {
        }, error: this.alertsService.showApiError
      });
  }

  cancel() {
    this.close.emit();
  }
}
