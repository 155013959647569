import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared';
import { forEach } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DashboardWidgetsService {

  dashboardApi = `${RuntimeConstants.API_PATH}DashboardWidgets`;

  constructor(private httpClient: HttpClient) { }

  getDashboardWidgetDatasourceParameters(dashboardWidgetAssignmentId, datasourceId) {
    return this.httpClient.get(`${this.dashboardApi}/GetDashboardWidgetDatasourceParameters/
      ${dashboardWidgetAssignmentId}/${datasourceId}`);
  }

  getChartData(params, format) {
    return this.httpClient.post(`${this.dashboardApi}/${format}`, params);
  }

  saveDashboardWidgetDataSourceParameters = (model) => {
    return this.httpClient.post(`${this.dashboardApi}/SaveDashboardWidgetDatasourceParameters`, model);
  };

  getWidget = (url, method, params) => {
    url = RuntimeConstants.API_PATH + url;
    switch (method.toUpperCase()) {
      case 'GET':
        {
          forEach(params, (param) => {
            // append query string parameters
            if (url.toLowerCase().indexOf('?') >= 0) {
              url += '&';
            } else {
              url += '?';
            }
            url += param.ParameterName + '=' + param.ParameterValue;
          });
          return this.httpClient.get(url);
        }
      case 'POST':
        {
          const parameters = [];
          forEach(params, (param) => {
            parameters.push({
              Name: param.ParameterName,
              Value: param.ParameterValue
            });
          });
          return this.httpClient.post(url, parameters);
        }
      default:
        break;
    }
  }

}
