import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { AlertsService, SpinnerService, ModalService, ConfirmDeleteModalComponent, Messages, OrderService, ICloseable } from 'src/app/shared';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'pos-copy-buttons',
  templateUrl: './copy-buttons.component.html',
  styleUrls: ['./copy-buttons.component.scss']
})
export class CopyButtonsComponent implements OnInit, ICloseable {
  close: EventEmitter<any> = new EventEmitter();
  @Input() screenId: number;
  screens: Array<any> = [];
  selectedScreenId: number;
  searchFilter: string = '';
  constructor(private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private orderService: OrderService,
    private modalService: ModalService) { }

  ngOnInit() {
    this.getScreens();
  }

  getScreens() {
    this.spinnerService.show();
    this.orderService.getScreenData(this.screenId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: any) => {
          this.screens = res && res.LoadScreenModel ? res.LoadScreenModel : [];
        }, error: this.alertService.showApiError
      });
  }

  onSave() {
    if (this.selectedScreenId) {
      const confirmCommonNextScreen = this.modalService.show(ConfirmDeleteModalComponent, {
        animated: false,
        class: 'vertical-center',
        'backdrop': 'static',
        initialState: {
          message: Messages.ConfirmOverWriteNewButton
        }
      });
      confirmCommonNextScreen.close.subscribe(res => {
        if (res && res.shouldDelete) {
          this.copyButton();
        }
      });
    }
  }

  copyButton() {
    this.spinnerService.show();
    const screenModel = {
      screenid: this.screenId,
      SelectedScreenId: this.selectedScreenId,
      ChoiceId: 1
    };
    this.orderService.getButtonsFromOtherScreen(screenModel)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: any) => {
          this.close.emit({ shouldReload: true });
        }, error: this.alertService.showApiError
      });
  }

  setScreenId(screen: any): void {
    this.selectedScreenId = screen.id;
  }

  onCancel() {
    this.close.emit({ shouldReload: false });
  }

}
