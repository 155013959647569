import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VariableIngredientRecipeService {

  inventoryProductApiPath = `${RuntimeConstants.API_PATH}inventoryproducts`;
  inventoryProductRecipeApiUrl = `${RuntimeConstants.API_PATH}inventory-product-prep-recipe`;

  constructor(private httpService: HttpClient) { }
  getIngredients(inventoryProductId): Observable<any> {
    return this.httpService.get(`${this.inventoryProductRecipeApiUrl}/${inventoryProductId}`);
  }

  getPrepProducts(): Observable<any> {
    return this.httpService.get(`${this.inventoryProductApiPath}/prepared`);
  }

  getInventoryProduct(inventoryProductId): Observable<any> {
    return this.httpService.get(`${this.inventoryProductApiPath}/${inventoryProductId}`);
  }

  prepareVariableInventory(variableIngredientRecipeObj): Observable<any> {
    return this.httpService.post(`${this.inventoryProductApiPath}/variable/prepare`, variableIngredientRecipeObj);
  }

  printInventoryPrep(inventoryProductId, inventoryProduct): Observable<any> {
    return this.httpService.post(`${this.inventoryProductApiPath}/${inventoryProductId}/prepare/variable/print`, inventoryProduct);
  }
}
