import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { Dictionary, groupBy, intersectionBy, orderBy, uniqBy } from 'lodash';
import { finalize } from 'rxjs/operators';
import { SalesProductsService } from 'src/app/information-management/sales-products/services';
import { Tag } from 'src/app/information-management/tags';
import { AlertsService, ColorUtilityService, RuntimeConstants } from 'src/app/shared';
import { NavigationInventoriesBasedOnDietary } from 'src/app/shared/interface/navigation-inventory-based-on-dietary';
import { OrderNavigationButton } from '../../interface/order-navigation-button';

@Component({
  selector: 'pos-button-warnings-and-tags',
  templateUrl: './button-warnings-and-tags.component.html',
  styleUrls: ['./button-warnings-and-tags.component.scss']
})
export class ButtonWarningsAndTagsComponent implements OnInit {

  @Input() button: OrderNavigationButton;
  @Input() filteredWarningsAndTags = { DietaryWarnings: [], Tags: [] };
  close: EventEmitter<any> = new EventEmitter<any>();

  tags: Array<Tag> = [];
  dietaryWarnings: Dictionary<Array<NavigationInventoriesBasedOnDietary>>;
  imagePath = RuntimeConstants.IMAGE_BASE_PATH + '/tags';

  height = window.innerHeight - 500;
  showSpinner = false;

  constructor(private alertsService: AlertsService,
    private colorUtilityService: ColorUtilityService,
    private salesProductService: SalesProductsService) { }

  ngOnInit(): void {
    this.getAppliedWarningsAndTags();
  }

  getAppliedWarningsAndTags() {
    if (this.filteredWarningsAndTags.DietaryWarnings?.length && this.button.DietaryWarnings?.length) {
      const matchedDietary = intersectionBy(this.button.DietaryWarnings, this.filteredWarningsAndTags.DietaryWarnings, 'Id');
      if (matchedDietary && matchedDietary.length) {
        const dietaryWarningIds = matchedDietary.map(x => x.Id);
        this.showSpinner = true;
        this.salesProductService.getInventoryProductsBasedOnDietaryWarnings(this.button.SalesProductId, dietaryWarningIds)
          .pipe(finalize(() => {
            this.showSpinner = false;
          }))
          .subscribe({
            next: (res: Array<NavigationInventoriesBasedOnDietary>) => {
              res = uniqBy(res, x => [x.DietaryWarningName, x.ProductName].join());
              this.dietaryWarnings = groupBy(orderBy(res, ['DietaryWarningName', 'ProductName']), 'DietaryWarningName');
            }, error: this.alertsService.showApiError
          });
      }
    }
    if (this.filteredWarningsAndTags.Tags?.length) {
      if (this.button.SalesProductTags?.length) {
        const matchedTags = this.filteredWarningsAndTags.Tags.filter(filteredTag => this.button.SalesProductTags.every(productTag => (filteredTag.TagId !== productTag.TagId)));
        if (matchedTags && matchedTags.length) {
          this.tags = orderBy(matchedTags, 'Name');
        }
      } else {
        this.tags = this.filteredWarningsAndTags.Tags
      }
    }
  }

  cancel() {
    this.close.emit();
  }

  public getContrastColor(color: string) {
    return this.colorUtilityService.getContrastColor(color);
  }

}
