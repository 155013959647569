import { InventoryProductAliasService } from './../../services/inventory-product-alias.service';
import { InventoryProductAlias } from './../../interface/inventory-product-alias';
import { Messages } from './../../../../shared/constants/ui-messages';
import { AlertsService } from 'src/app/shared/services';
import { GridColumn, DeleteColumn } from '@tarktech/tark-ng-utils';
import { Component, OnInit, Input } from '@angular/core';
import { SpinnerService, normalPlus } from 'src/app/shared';


@Component({
  selector: 'inventory-product-alias',
  templateUrl: './inventory-product-alias.component.html',
  styleUrls: ['./inventory-product-alias.component.scss']
})
export class InventoryProductAliasComponent implements OnInit {

  inventoryProductAliasesColumn: Array<GridColumn> = [];
  @Input("inventoryProductId") inventoryProductId: number;
  inventoryProductAlias: InventoryProductAlias;
  inventoryProductAliases: Array<InventoryProductAlias> = [];
  icons = {
    normalPlus
  }
  constructor(
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private inventoryProductAliasService: InventoryProductAliasService
  ) { }

  ngOnInit() {
    this.resetAlias();
    this.getInventoryProductAliases();
    this.configureGridColumns();
  }


  resetAlias() {
    this.inventoryProductAlias = {
      Id: 0,
      InventoryProductId: 0,
      AliasName: null
    };
    this.inventoryProductAlias.InventoryProductId = this.inventoryProductId;
  }

  public getInventoryProductAliases() {
    this.spinnerService.show();
    this.inventoryProductAliasService.getInventoryProductAliases(this.inventoryProductId)
      .subscribe(
        response => {
          if (response) {
            this.inventoryProductAliases = response;
          } else {
            this.inventoryProductAliases = [];
          }
        },
        this.alertService.showApiError,
        () => {
          this.spinnerService.hide();
        }
      );
  }

  public addAlias(inventoryProductAlias: InventoryProductAlias) {
    if (inventoryProductAlias.AliasName && inventoryProductAlias.AliasName.trim()) {
      this.spinnerService.show();
      this.inventoryProductAliasService.addAlias(inventoryProductAlias)
        .subscribe(
          response => {
            this.getInventoryProductAliases();
            this.alertService.renderSuccessMessage(Messages.InventoryProductAliasSaved);
            this.resetAlias();
          },
          this.alertService.showApiError,
          () => {
            this.spinnerService.hide();
          }
        );
    }
  }

  private configureGridColumns() {
    const deleteColumn = new DeleteColumn({
      Clicked: (data) => {
        this.deleteAlias(data.Id);
      },
      Width: '40px'
    });

    this.inventoryProductAliasesColumn = [
      new GridColumn({ HeaderText: 'Alias', Field: 'AliasName', Width: '94%' })
    ] as Array<GridColumn>;

    this.inventoryProductAliasesColumn.push(deleteColumn);

  }

  deleteAlias(id: number) {
    this.spinnerService.show();
    this.inventoryProductAliasService.deleteAlias(id)
      .subscribe(
        response => {
          this.getInventoryProductAliases();
          this.alertService.renderSuccessMessage(Messages.InventoryProductAliasDeleted);
        },
        this.alertService.showApiError,
        () => {
          this.spinnerService.hide();
        }
      );
  }
}
