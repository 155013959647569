import { Component, EventEmitter, OnInit, ViewChild, Input } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { filter, findIndex, forEach } from 'lodash';
import { ScreenChoice } from '../../interface/screen-choice';
import { EmbedScreenService } from '../../services/embed-screen.service';
import { forkJoin, Observable } from 'rxjs';
import { DirectedGraph } from 'src/app/shared/classes/directed-graph';
import { SpinnerService } from 'src/app/shared/components/spinner';
import { AlertsService, OrderService } from 'src/app/shared/services';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { ScreenPreviewComponent } from 'src/app/orders/choose-next-screens';
import { ScreenButtons } from 'src/app/orders/interface';

@Component({
  selector: 'pos-embed-screen',
  templateUrl: './embed-screen.component.html',
  styleUrls: []
})
export class EmbedScreenComponent implements OnInit {

  @ViewChild('screenPreviewComponent') screenPreviewComponent: ScreenPreviewComponent;
  @Input() screenId: number;
  @Input() allButtons: Array<ScreenButtons> = [];
  close: EventEmitter<any> = new EventEmitter();
  screens: Array<any> = [];
  selectedScreenId: number;
  embeddedScreenChoices: ScreenChoice[];
  directedGraph: DirectedGraph;

  constructor(private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private orderService: OrderService,
    private embedScreenService: EmbedScreenService) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.spinnerService.show();
    const embedScreenObservable: Array<Observable<any>> = []
    embedScreenObservable.push(this.orderService.getScreenData(this.screenId));
    embedScreenObservable.push(this.embedScreenService.getScreenChoiceWithEmbededScreen());
    forkJoin(embedScreenObservable)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe({
        next: ([screen, screenChoices]: [any, ScreenChoice[]]) => {
          this.embeddedScreenChoices = screenChoices ? screenChoices : [];
          const alreadyEmbeddedScreens = filter(this.embeddedScreenChoices, x => x.ScreenId === this.screenId);

          this.screens = screen && screen.LoadScreenModel ? screen.LoadScreenModel.filter(x => x.id != this.screenId && x.id != -1) : [];
          // exclude screens that are already embeded
          this.screens = filter(this.screens, x => findIndex(alreadyEmbeddedScreens, y => y.EmbeddedScreenId === x.id) === -1)
          this.createGraph();
        }, error: this.alertService.showApiError
      });
  }

  embedScreen(isValid) {
    if (!isValid) {
      return;
    }
    this.spinnerService.show();
    if (this.directedGraph.isPathFrom(this.selectedScreenId, this.screenId)) {
      this.alertService.renderErrorMessage("Adding selected screen  into current screen creates cycle")
      this.spinnerService.hide();
      return;
    }
    const embedScreenModel = {
      ScreeId: this.screenId,
      EmbeddedScreenId: this.selectedScreenId
    }
    this.embedScreenService.addEmbeddedScreen(embedScreenModel)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe({
        next: res => {
          this.loadData();
          this.onSaveSuccess();
        }, error: this.alertService.showApiError
      });
  }

  onSaveSuccess() {
    this.alertService.renderSuccessMessage(Messages.EmbeddedScreenSaveSuccess);
    this.close.emit({ shouldReload: true })
  }

  onCancel() {
    this.close.emit({ shouldReload: false });
  }

  createGraph() {
    this.directedGraph = new DirectedGraph();
    forEach(this.embeddedScreenChoices, screen => {
      if (screen.EmbeddedScreenId) {
        this.directedGraph.addEdge(screen.ScreenId, screen.EmbeddedScreenId)
      }
    })
  }

  onScreenSelectionChange(screenId) {
    if (this.selectedScreenId) {
      this.screenPreviewComponent?.reloadScreenButtons(screenId);
    }
  }

  getHeight() {
    return window.innerHeight - 380 + 'px';
  }
}
