import { deleteWhite } from 'src/app/shared/components/icon';
import { Input, OnChanges, SimpleChanges } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { InventoryProductNutritionService } from '../../service';
import { AlertsService, SpinnerService, Messages } from 'src/app/shared';
import { SelectItem } from 'primeng/api';
import {
  InventoryProductNutrition, NutritionFactElement,
  NutritionFactMeasure
} from 'src/app/shared/interface/inventory-product-nutrition';
import * as _ from 'lodash';
import { cloneDeep, filter, find, forEach, remove } from 'lodash';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'pos-inventory-product-nutrition',
  templateUrl: './inventory-product-nutrition.component.html',
  styleUrls: ['./inventory-product-nutrition.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class InventoryProductNutritionComponent implements OnInit, OnChanges {
  @Input() nutritionInfo: Array<InventoryProductNutrition> = [];
  @Input() inventoryProductId: number;
  public nutritionFactMeasures: Array<SelectItem> = [];
  public nutritionFactElements: Array<SelectItem> = [];
  public tempNutritionFactElements: Array<SelectItem> = [];
  public icons = {
    deleteWhite
  };
  constructor(
    private inventoryProductNutritionService: InventoryProductNutritionService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService
  ) {
    this.nutritionFactMeasures = [];
    this.nutritionFactElements = [];
    this.tempNutritionFactElements = [];
  }

  ngOnInit() {
    this.getNutritionFactElements();
    this.getNutritionFactMeasures();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.disableSelectedNutritionFact();
  }

  getNutritionFactElements() {
    this.spinnerService.show();
    this.inventoryProductNutritionService.getNutritionFactElements()
      .subscribe(
        (response: NutritionFactElement[]) => {
          const listNutritionFactElements = response.map(function (data) {
            return { label: data.Name, value: data.Id };
          });
          this.nutritionFactElements = listNutritionFactElements;
          this.tempNutritionFactElements = _.cloneDeep(listNutritionFactElements);
          this.disableSelectedNutritionFact();
        },
        this.alertService.showApiError,
        () => {
          this.spinnerService.hide();
        }
      );
  }
  getNutritionFactMeasures() {
    this.spinnerService.show();
    this.inventoryProductNutritionService.getNutritionFactMeasures()
      .subscribe(
        (response: NutritionFactMeasure[]) => {
          const listNutritionFactMeasures = response.map(function (data) {
            return { label: data.Name, value: data.Id };
          });
          this.nutritionFactMeasures = listNutritionFactMeasures;
        },
        this.alertService.showApiError,
        () => {
          this.spinnerService.hide();
        }
      );
  }
  showNutritionFactElement(nutritionFactElementId) {
    if (nutritionFactElementId > 0) {
      const nutritionFactElement = this.tempNutritionFactElements ?
        this.tempNutritionFactElements.filter(data => data.value === nutritionFactElementId)[0] : null;
      return nutritionFactElement ? nutritionFactElement.label : null;
    } else {
      return nutritionFactElementId ? nutritionFactElementId : null;
    }
  }
  showNutritionFactMeasure(nutritionFactMeasureId) {
    if (nutritionFactMeasureId > 0) {
      const nutritionFactMeasure = this.nutritionFactMeasures ?
        this.nutritionFactMeasures.filter(data => data.value === nutritionFactMeasureId)[0] : null;
      return nutritionFactMeasure ? nutritionFactMeasure.label : null;
    } else {
      return nutritionFactMeasureId ? nutritionFactMeasureId : null;
    }
  }
  createNutritionRow(lastRow: boolean) {
    if (!lastRow) {
      return;
    }
    const paramsObj = {
      Id: 0,
      InventoryProductId: this.inventoryProductId,
      NutritionFactElementId: null,
      Amount: 0,
      MeasureId: null,
      Percent: null,
      NutritionFactElement: {
        Id: 0, Name: null, DateAdded: null,
        DateEdited: null
      },
      NutritionFactMeasure: {
        Id: 0, Name: null, DateAdded: null,
        DateEdited: null
      },
      DateAdded: null,
      DateEdited: null
    };
    this.nutritionInfo.push(paramsObj);
  }
  clearNutritionRow(data, index: number): void {
    if (data.Id > 0) {
      this.inventoryProductNutritionService.deleteNutrition(data.Id).subscribe(
        {
          next: () => {
            this.alertService.renderSuccessMessage(Messages.InventoryProductNutritionDeleted);
          }, error: this.alertService.showApiError
        });
    }
    const tempNutritionInfo = cloneDeep(this.nutritionInfo);
    this.nutritionInfo = [];
    tempNutritionInfo.splice(index, 1);
    setTimeout(() => {
      this.nutritionInfo = tempNutritionInfo;
    });
    this.disableSelectedNutritionFact();
  }
  disableSelectedNutritionFact() {
    this.nutritionFactElements = _.cloneDeep(this.tempNutritionFactElements);
    forEach(this.nutritionFactElements, (item) => {
      const isAvailable = find(this.nutritionInfo, (info) => info.NutritionFactElementId == item.value);
      item.disabled = isAvailable ? true : false;
    });
  }
}
