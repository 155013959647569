import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { BaseCrudModelComponent } from 'src/app/shared/components';
import { EquipmentProperty } from '../../interface/equipment-property';
import { EquipmentType } from 'src/app/information-management/equipment-types/interface/equipment-type';
import { NgForm } from '@angular/forms';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { EquipmentPropertyService } from '../../service/equipment-property.service';
import { EquipmentTypeService } from 'src/app/information-management/equipment-types';
import { Messages, FormUtilityService } from 'src/app/shared';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'pos-equipment-property-edit',
    templateUrl: './equipment-property-edit.component.html',
})
export class EquipmentPropertyEditComponent extends BaseCrudModelComponent<EquipmentProperty> implements OnInit {

    equipmentProperty: EquipmentProperty;
    equipmentTypes: Array<EquipmentType>;
    @Input('id') id = 0;
    @ViewChild('equipmentPropertyForm') equipmentPropertyForm: NgForm;
    @Output() onSave = new EventEmitter();
    get getForm(): NgForm {
        return this.equipmentPropertyForm
    }

    constructor(
        protected alertService: AlertsService,
        protected spinnerService: SpinnerService,
        protected equipmentPropertyService: EquipmentPropertyService,
        private equipmentTypeService: EquipmentTypeService,
        formUtilityService: FormUtilityService
    ) {
        super(equipmentPropertyService, alertService, spinnerService, formUtilityService);
    }

    ngOnInit() {
        this.equipmentProperty = this.equipmentPropertyService.newEquipmentProperty();
        this.saveSuccessMessage = Messages.EquipmentPropertiesSaveSuccess;
        this.dataSubscription();
    }
    submit(isValid: boolean): void {
        if (!isValid) {
            return;
        }
        this.save(this.equipmentProperty);
    }

    private dataSubscription(): void {
        if (this.id > 0) {
            this.spinnerService.show();
            this.loadData().pipe(finalize(() => {
                this.spinnerService.hide();
            }))
                .subscribe({
                    next: (res) => {
                        this.equipmentProperty = res;
                    }, error: this.alertService.showApiError
                });
        }
    }

    public loadDependencies(): void {
        this.spinnerService.show();
        this.equipmentTypeService.getEquipmentTypes()
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (res) => {
                    this.equipmentTypes = res;
                }, error: this.alertService.showApiError
            });
    }

    public onSaveSuccess(model: EquipmentType): void {
        this.onSave.emit();
        this.hide({ reload: true });
    }

    cancelEditing(): void {
        this.hide({ reload: false });
    }
}
