<div>
  <h4 class="modal-content__heading" name="numpadHeader">Gift Card Number Entry</h4>
</div>
<div class="modal-body">
  <div class="">
    <div class="col-12 pe-0">
      <pos-numpad #giftCardCheckBalanceModal [numpadId]="'giftCardCheckBalanceModal'" [onCancel]="cancelGiftCard" [onSubmit]="checkBalance"  
      [onOptionChange]="numpadOptionChange"
        [options]="giftCardNumpadOptions" [value]="giftCardNumber">
      </pos-numpad>
    </div>
    <div class="clearfix"></div>
  </div>
</div>
