import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DigitalSignageComponent } from './digital-signage/digital-signage.component';
import { DIGITAL_SIGNAGE_STATES } from './digital-signage-routes';
import { ComponentsModule, FormsModule } from '../shared/components';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HeaderEntryModule } from '../header-entry/header-entry.module';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ModalModule.forRoot(),
    ComponentsModule,
    RouterModule.forChild(DIGITAL_SIGNAGE_STATES),
    HeaderEntryModule
  ],
  declarations: [DigitalSignageComponent]
})
export class DigitalSignageModule { }
