import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalComponent } from 'src/app/shared/components/modal';
declare const $: any;
@Component({
  selector: 'pos-confirm-backup-modal',
  templateUrl: './confirm-backup-modal.component.html',
})
export class ConfirmBackupModalComponent extends ModalComponent implements OnInit {

  public message: string = '';
  public confirmButtonText: string = 'OK';
  public rejectButtonText: string = '';
  public modalHeaderText: string = 'Info';
  public sendEmail = false;
  constructor(deleteModalRef: BsModalRef) { super(deleteModalRef); }

  ngOnInit() {
    setTimeout(()=>{
      $('#confirmButton')?.focus();
    });
  }

  public onConfirm(): void {
    this.hide({ shouldConfirm: true, sendEmail: this.sendEmail });
  }

  public onRejectConfirm(event): void {
    this.hide({ shouldConfirm: false, event: event });
  }

}
