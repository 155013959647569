<context-consumer consume="props"></context-consumer>


<div class="row mx-pix-n10" [class.pt-pix-10]="appendPaddingTop">
    <div *ngIf="label || fieldInfo || fieldInfoObject" class="display-flex" [ngClass]="labelStyle ? labelStyle : (props.labelClass ? props.labelClass : 'col-12')">
        <ng-content select="[labelPrefix]"></ng-content>
        <label [class.required]="control?.required">{{ label }}</label>
        <field-info *ngIf="fieldInfo || fieldInfoObject" [(info)]="fieldInfoObject ? fieldInfoObject.info : fieldInfo" [image]="fieldInfoObject?.image" [placement]="fieldInfoPlacement"></field-info>
    </div>
    <div [ngClass]="inputStyle ? inputStyle : props.controlBoxClass" [class.has-error]="control?.errorState" [class.has-warning]="hasWarning">
        <ng-content></ng-content>
        <span class="col-12 p-0" *ngIf="control?.errorState">
            <label class="control-label text-nowrap">{{getValidationMessage()}}</label>
        </span>
        <span class="col-12 p-0" *ngIf="hasWarning">
            <label class="control-label text-nowrap">{{warning}}</label>
        </span>
    </div>
</div>
