import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { first } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { Screen } from 'src/app/orders';
import { AlertsService, ButtonBehavior, FormUtilityService, ModalFormComponent, ModalService, ScreenService, SpinnerService } from 'src/app/shared';

@Component({
  selector: 'pos-temporary-navigation',
  templateUrl: './temporary-navigation.component.html',
})
export class TemporaryNavigationComponent extends ModalFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.navigationForm;
  }

  @ViewChild('navigationForm') navigationForm: NgForm;
  @Input() selectedButtonBehaviorType;
  @Input() buttonBehavior = null;
  @Input() screens: Array<any> = [];
  screenId: number = null;

  constructor(private screenService: ScreenService,
    protected spinnerService: SpinnerService,
    protected alertsService: AlertsService,
    modalRef: BsModalRef, modalService: ModalService, protected formUtilityService: FormUtilityService) {
    super(modalRef, modalService, formUtilityService);
  }

  ngOnInit(): void {
    if (this.buttonBehavior && this.buttonBehavior.Id) {
      try {
        if (this.buttonBehavior.BehaviorValue) {
          const behavior = first(this.buttonBehavior.BehaviorValue) as any;
          this.screenId = behavior.ScreenId??null;
        } else {
          const behavior = JSON.parse(this.buttonBehavior.TextValue);
          this.screenId = behavior ? behavior.screenId : null;
        }
      } catch (ex) {
        console.error('Invalid behavior value: ' + this.buttonBehavior.TextValue);
      }
    }
    this.loadData();
  }

  loadData() {
    if (!this.screens?.length) {
      this.spinnerService.show();
      this.screenService.getActiveScreens(0)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res) => {
            this.screens = res;
          }, error: this.alertsService.showApiError
        });
    }
  }

  saveBehavior = (isValid: boolean) => {
    if (isValid && this.screenId) {
      this.hide({ shouldReload: true, Value: { screenId: this.screenId } });
    }
  }

  closeModal = () => {
    this.hide({ shouldReload: false });
  }

}
