<div>
    <button class="close me-pix-10" (click)="closeDialog()">&times;</button>
    <a title="Reload widget content" class="me-pix-5 reload-widget" *ngIf="definition.reload" (click)="reload()">
        <pos-action-icon [icon]="icons.sync" [additionalClasses]="['font-16', 'white']"
            [backgroundClasses]="['green']"></pos-action-icon>
    </a>
    <h4 class="modal-content__heading">{{definition.title}}</h4>
</div>
<div class="p-pix-20 pb-0 div-center">
    <pos-adf-widget-content [column]="column" [adfModel]="adfModel" [model]="definition" [content]="definition"
        [isInteractive]="isInteractive" [isFullScreenMode]="true">
    </pos-adf-widget-content>
</div>
<div class="col-12 action-button-container popup-footer pb-pix-10">
    <button type="button" class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="closeDialog()">Close</button>
</div>
<div class="clearfix"></div>
