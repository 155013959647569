<div *ngIf="isPopup">
    <button class="close" (click)="closeEditing()">&times;</button>
    <h4 class="modal-content__heading" [innerHTML]="heading"></h4>
</div>
<div class="page-content" [ngClass]="{'clearfix': isPopup}">
    <div class="tab-content">
        <div class="tab-pane fade in active show" [ngClass]="{'popup-content pti-5': isPopup}">
            <div class="col-12">
                <a *ngIf="!isPopup" class="close pe-pix-20 pointer" (click)="closeEditing()">×</a>
                <div  *ngIf="!isPopup && heading" class="col-10 display-flex ps-pix-20">
                    <pos-icon *ngIf="headingIcon" [icon]="headingIcon" class="pe-pix-10 vertical-center-align page-content__header-icon"></pos-icon>
                    <h3 class="page-content__header" [innerHTML]="heading"></h3>
                </div>
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>
