import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from './icon/icon.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TooltipModule } from 'primeng/tooltip';
import { ModalService } from './modal/modal.service';
import { ConfirmDeleteComponent } from './confirm-delete/confirm-delete.component';
import { PermissionsDirective } from './permission/permissions.directive';
import { ImageGalleryComponent } from './image-gallery/image-gallery.component';
import { MediaRotatorComponent } from './media-rotator/media-rotator.component';
import { RotateMediaComponent } from './media-rotator/media/rotate-media.component';
import { NumpadComponent } from './numpad/numpad.component';
import { FormsModule } from '@angular/forms';
import { FieldInfoComponent } from './field-info/field-info.component';
import { ConfirmDeleteModalComponent } from './confirm-delete-modal/confirm-delete-modal.component';
import { PrintTableComponent } from './print-table/print-table.component';
import { EditorComponent } from './editor/editor.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DataFilterComponent } from './filter/dataFilter';
import { FixedLengthNumberComponent } from './filter/fixedLengthNumber';
import { CustomFilterComponent } from './filter/custom-filer.component';
import { ScrollupDirective } from './scrollup/scrollup.directive';
import { ValidationGroupDirective } from './validation-group.directive';
import { FloorPipe } from '../floor';
import { InfoModalComponent } from './info-modal';
import { KeyboardWedgeComponent } from './keyboard-wedge';
import { InputDirective } from './input/input.directive';
import { SwipeCardComponent } from './swipe-card/swipe-card.component';
import { PosDecimalDirective } from './pos-decimal.directive';
import { CeilPipe } from './filter/ceil-pipe';
import { ProductQuantityPipe } from '../pipes/product-quantity-pipe';
import { NoMinusSign } from '../pipes/no-minus-sign';
import { CustomDeepFilterComponent } from './filter/custom-deep-filter.component';
import { NumpadButtonsComponent } from './numpad-buttons/numpad-buttons.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { OnlyNumbersDirective } from './only-numbers.directive';
import { OnlyPositiveNumbersDirective } from './only-positive-numbers.directive';
import { ModalBackdropService } from './modal/modal-component';
import { AppVersionCheckComponent } from './app-version-check/app-version-check.component';
import { HideKeyboardDirective } from './hide-keyboard/hide-keyboard.directive';
import { NgxColorPickerDirective } from './input/ngx-color-picker.directive';
import { SpinnerIconComponent } from './svg-icons/spinner-icon/spinner-icon.component';
import { TerminalLoginIconComponent } from './svg-icons/terminal-login-icon/terminal-login-icon.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NgSelectTagComponent } from './ng-select-tag/ng-select-tag.component';
import { ButtonModule } from 'primeng/button';
import { BadgeModule } from 'primeng/badge';
import { ChipModule } from 'primeng/chip';
import { ActionIconComponent } from './action-icon/action-icon.component';
import { FeatureDirective } from './feature/feature.directive';
import { GroupByPipe } from './filter/groupBy-pipe';
import { ReportConfigurationParametersComponent } from './report-configuration-parameters';
import { TableModule } from '@tarktech/tark-ng-utils';
import { CheckboxModule } from 'primeng/checkbox';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { IconInputComponent } from './icon-input/icon-input.component';
import { ExportDropdownComponent } from './export-dropdown/export-dropdown.component';
import { NumpadWrapperComponent } from './numpad/numpad-wrapper/numpad-wrapper.component';
import { FilteredProductsComponent } from 'src/app/configurator/terminals/components/filtered-products/filtered-products.component';
import { ProductBuildQueueComponent } from 'src/app/product-build-queue/components/product-build-queue/product-build-queue.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProductBuildQueueConfigComponent } from 'src/app/product-build-queue/components/product-build-queue-config/product-build-queue-config.component';
import { PCalendarDirective } from './p-calender/pcalendar.directive';
import { FontStyleComponent } from './font-style/font-style.component';
import { ColorPickerModule } from 'ngx-color-picker';
import {ToggleButtonModule} from 'primeng/togglebutton';
import { IConfig, NgxMaskPipe, provideEnvironmentNgxMask, NgxMaskDirective } from 'ngx-mask';
import { PhoneMaskPipe } from '../pipes/phone-mask.pipe';
import { CallCameraAPIDirective } from '../directives/camera/call-camera-api.directive';
import { AutoFocusDirective } from './auto-focus/auto-focus.directive';
import { EditWrapperComponent } from './edit-wrapper/edit-wrapper.component';
import { AccountAttributeComponent } from './account-attribute/account-attribute.component';
import { CalendarModule } from 'primeng/calendar';
import { TranslateLoader, TranslateModule, TranslateStore } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { createTranslateLoader } from '../services/translateLoader';
import { UnsavedFormDataComponent } from './unsaved-form-data-modal/unsaved-form-data.component';
import { FormUtilityService } from '../services/form-utility.service';
import { FormFieldComponent } from './form-controls/form-field';
import { NgxContextModule } from '@tarktech/ngx-context';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { InputControlDirective, ValidateDirective, RangeDirective } from './form-controls/directives';
import { RowComponent } from './grid/row/row.component';
import { ColumnComponent } from './grid/column/column.component';
import { ConditionallyValidateDirective } from './form-controls/directives/validators/conditionally-validate.directive';
import { exportIcons } from './icon/export-icon';
import { SelectOnFocusDirective } from './auto-focus-and-select/select-on-focus.directive';
import { ConfigComponent } from './rules/config';
import { CriteriaComponent } from './rules';
import { SelectButtonModule } from 'primeng/selectbutton';
import { RewardComponent } from './rules/reward';
import { PosDecimalRangeDirective } from './pos-decimal-range.directive';
import { ListWrapperComponent } from './list-wrapper/list-wrapper.component';
import { ScanBarcodeComponent } from './scan-barcode/scan-barcode.component';
import { NutritionFactsComponent } from './nutrition-facts/nutrition-facts.component';
import { ForceLogoutModalComponent } from './force-logout-modal/force-logout-modal.component';
import { AudioComponent } from './audio/audio.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
@NgModule({
  imports: [CommonModule, FormsModule, FontAwesomeModule, TooltipModule, NgSelectModule,
    ProgressBarModule, ProgressSpinnerModule, ChipModule, ButtonModule, BadgeModule, TableModule, CheckboxModule,
        InputSwitchModule, TriStateCheckboxModule, ColorPickerModule, ToggleButtonModule, NgxContextModule,
        PopoverModule.forRoot(), CalendarModule,
        TranslateModule.forChild({
          isolate: true,
          defaultLanguage: 'en',
          loader: {
            provide: TranslateLoader,
            useFactory: (createTranslateLoader),
            deps: [HttpClient]
          }
        }),
        SelectButtonModule,
        NgxMaskDirective
  ],
  declarations: [IconComponent, ConfirmDeleteComponent, PermissionsDirective, CallCameraAPIDirective, ImageGalleryComponent,
    MediaRotatorComponent, RotateMediaComponent, NumpadComponent, FieldInfoComponent,
    ConfirmDeleteModalComponent, UnsavedFormDataComponent, PrintTableComponent, DataFilterComponent, FixedLengthNumberComponent,
    EditorComponent, ScrollupDirective, CustomFilterComponent, CustomDeepFilterComponent, FloorPipe, CeilPipe, GroupByPipe, InfoModalComponent,
    KeyboardWedgeComponent, ValidationGroupDirective, InputDirective, AutoFocusDirective, NgxColorPickerDirective, SwipeCardComponent, PosDecimalDirective,
    ProductQuantityPipe, NoMinusSign, NumpadButtonsComponent, ProgressBarComponent, OnlyNumbersDirective, OnlyPositiveNumbersDirective,
    AppVersionCheckComponent, HideKeyboardDirective, SpinnerIconComponent, TerminalLoginIconComponent, NgSelectTagComponent, ExportDropdownComponent,
    ActionIconComponent, FeatureDirective, NgSelectTagComponent, ReportConfigurationParametersComponent, IconInputComponent, SelectOnFocusDirective,
    NumpadWrapperComponent, FilteredProductsComponent, ProductBuildQueueComponent, ProductBuildQueueConfigComponent, PCalendarDirective,
      FontStyleComponent, PhoneMaskPipe, EditWrapperComponent, FormFieldComponent, ColorPickerComponent, InputControlDirective, ValidateDirective, RangeDirective, RowComponent, ColumnComponent, AccountAttributeComponent, ConditionallyValidateDirective, ConfigComponent, CriteriaComponent, RewardComponent, PosDecimalRangeDirective, ListWrapperComponent,
    ScanBarcodeComponent, NutritionFactsComponent, ForceLogoutModalComponent, AudioComponent],
  exports: [IconComponent, ConfirmDeleteComponent, PermissionsDirective, CallCameraAPIDirective, ImageGalleryComponent,
    MediaRotatorComponent, RotateMediaComponent, NumpadComponent, FieldInfoComponent, PrintTableComponent,
    DataFilterComponent, FixedLengthNumberComponent, EditorComponent, ScrollupDirective, CustomFilterComponent, CustomDeepFilterComponent,
    FloorPipe, CeilPipe, GroupByPipe, KeyboardWedgeComponent, ValidationGroupDirective, InputDirective, AutoFocusDirective, NgxColorPickerDirective, PosDecimalDirective,
    ProductQuantityPipe, NoMinusSign, NumpadButtonsComponent, ProgressBarComponent, OnlyNumbersDirective, ExportDropdownComponent, SelectOnFocusDirective,
    OnlyPositiveNumbersDirective, AppVersionCheckComponent, HideKeyboardDirective, SpinnerIconComponent, TerminalLoginIconComponent, NgSelectTagComponent,
    ChipModule, ButtonModule, BadgeModule, ActionIconComponent, FeatureDirective, ReportConfigurationParametersComponent, IconInputComponent,
    NumpadWrapperComponent, FilteredProductsComponent, ProductBuildQueueComponent, ProductBuildQueueConfigComponent, PCalendarDirective,
      FontStyleComponent, NgxMaskDirective, PhoneMaskPipe, EditWrapperComponent, FormFieldComponent, ColorPickerComponent, InputControlDirective, ValidateDirective, AccountAttributeComponent, RangeDirective, RowComponent, ColumnComponent, ConditionallyValidateDirective, ConfigComponent, CriteriaComponent, RewardComponent,
    PosDecimalRangeDirective, ListWrapperComponent, ScanBarcodeComponent, NutritionFactsComponent],
  providers: [ModalBackdropService, ModalService,NgxMaskPipe, PhoneMaskPipe, TranslateStore, FormUtilityService, provideEnvironmentNgxMask(options)]
})
export class ComponentsModule {
  constructor(falconLibrary: FaIconLibrary) {
    falconLibrary.addIcons(...exportIcons);
  }
}
