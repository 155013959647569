import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseCrudService } from 'src/app/shared';
import { PermissionType } from '../interfaces/permission-type';

@Injectable({
  providedIn: 'root'
})
export class PermissionTypesService extends BaseCrudService<PermissionType> {

  constructor(private httpClient: HttpClient) {
    super('permission-types', httpClient);
  }

  newPermission(): PermissionType {
    return {
      Id: 0,
      Name: null,
      CategoryId: null,
      Tags: null,
      Description: null,
      DefaultAllowedRole: null,
      PermissionId: null,
      Type: null
    }
  }

  updatePermissionGraph() {
    return this.httpClient.get(`${this.apiEndpoint}/permission-graph`);
  }
}
