import { GridColumnTypes } from './grid-column-types';
import { LinkColumn } from './link-column';



export class DeleteColumn extends LinkColumn {
    
    PrintIcon?: LinkColumn;

    get Type(): string {
        return GridColumnTypes.DeleteColumn;
    }
    constructor(init?: Partial<DeleteColumn>) {
        super();
        this.Width = '50px';
        this.CellClass = 'cell-delete cell-padding';
        this.BackgroundColor = '#c72b0c';

        Object.assign(this, init);
    }
}
