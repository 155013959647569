import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableComponent } from '@tarktech/tark-ng-utils';
import { CurrencyColumn } from '@tarktech/tark-ng-utils/table/currency-column';
import { DateColumn } from '@tarktech/tark-ng-utils/table/date-column';
import { GridColumn } from '@tarktech/tark-ng-utils/table/grid-column';
import { TextAlign } from '@tarktech/tark-ng-utils/table/text-align';
import { Observable } from 'rxjs';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { finalize } from 'rxjs/internal/operators/finalize';
import { FilterConfiguration, GridContextInfo, SalesSize, SalesSizeService, TableConfiguration } from 'src/app';
import { BaseListComponent } from 'src/app/shared/components/base-list.component';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import { SalesProduct } from '../../interface/sales-product';
import { SalesProductSizeHistory } from '../../interface/sales-product-sizes-history';
import { SalesProductSizeService } from '../../services/sales-product-size.service';
import { SalesProductsService } from '../../services/sales-products.service';
import { dollarSign } from 'src/app/shared/components/icon'
@Component({
  selector: 'pos-sales-product-size-history',
  templateUrl: './sales-product-size-history.component.html',
  styleUrls: ['./sales-product-size-history.component.scss']
})
export class SalesProductSizeHistoryComponent extends BaseListComponent<SalesProductSizeHistory> implements OnInit {

  salesSizeHistory: Array<SalesProductSizeHistory> = [];
  salesSizeHistoryColumns = [];
  salesSizeHistoryName;
  id: number;

  totalRecords = 0;
  filterConfiguration: FilterConfiguration = {
    PageNumber: 1, PageSize: TableConfiguration.pageSize,
    SortField: 'DateEffective', SortOrder: -1, FilterValue: null
  };
  products: Array<SalesProduct> = []; sizes: Array<SalesSize> = [];
  isCalledFromOnInit = true;
  @ViewChild('salesProductSizeHistory', { static: true }) salesProductSizeHistory: TableComponent;
  icons = { dollarSign }
  salesProductGridContext: GridContextInfo = null;
  salesProductCategory: number = 0;
  salesProductActive: boolean = false;
  
  constructor(protected salesProductSizeService: SalesProductSizeService,
    protected salesProductService: SalesProductsService,
    protected alertService: AlertsService,
    protected salesSizeService: SalesSizeService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected applicationStateService: ApplicationStateService,
    protected route: ActivatedRoute,
    private router: Router,
    protected printService: PrintTableService) {
    super(salesProductSizeService, alertService, spinnerService, modalService, applicationStateService, printService, route);
    this.id = parseInt(route.snapshot.params.id, 10) ?? 0;
    this.deleteSuccessMessage = Messages.SalesProductPriceHistoryDeleted;
    const navigation = this.router.getCurrentNavigation();
    this.salesProductGridContext = navigation?.extras?.state?.GridContext ?? null;
    this.salesProductCategory = navigation?.extras?.state?.Category ?? null;
    this.salesProductActive = navigation?.extras?.state?.Active ?? null;
  }

  ngOnInit(): void {
    this.isPrintIconVisible = false;
    this.salesSizeHistoryColumns = this.configureGridColumns();
    this.salesProductSizeHistory.context = this.gridContext;
  }

  reload() {
    if (this.id) {
      const observable: Array<Observable<any>> = [];
      observable.push(this.salesSizeService.getSalesSizes());
      observable.push(this.salesProductService.getSalesProduct(this.id));
      observable.push(this.salesProductSizeService.getTotalRecordsCount(this.id));
      this.spinnerService.show();
      forkJoin(observable).pipe(finalize(() => {
        this.spinnerService.hide();
      })).subscribe({
        next: (response: [Array<SalesSize>, SalesProduct, number]) => {
          this.sizes = response[0] ?? [];
          this.salesSizeHistoryName = response[1]?.Name;
          this.totalRecords = response[2];
          if (!this.isCalledFromOnInit) {
            this.getPriceHistories();
          }
          this.isCalledFromOnInit = false;
        },
        error: this.alertService.showApiError
      });
    }
  }

  editItem(id: number) {
    this.router.navigate([id], { relativeTo: this.route, state: { GridContext: this.salesProductGridContext, Category: this.salesProductCategory, Active: this.salesProductActive } });
  }

  lazyLoad(event) {
    this.filterConfiguration.PageSize = event.rows;
    this.filterConfiguration.PageNumber = Math.ceil((event.first + 1) / event.rows);
    this.filterConfiguration.SortField = event.sortField;
    this.filterConfiguration.SortOrder = event.sortOrder;
    this.filterConfiguration.FilterValue = event.globalFilter;
    this.getPriceHistories();
  }

  getPriceHistories() {
    this.spinnerService.show();
    this.salesProductSizeService.getPriceHistories(this.id, this.filterConfiguration)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          this.salesSizeHistory = response;
          this.salesSizeHistory.map((data) => {
            data.Size = this.sizes.find(x => x.Id === data.SizeId)?.Name;
          });
        }
      });
  }

  getConfirmDeleteMessage() {
    return Messages.SalesProductPriceHistoryDeleteConfirm;
  }

  getGridColumns() {
    return [
      new GridColumn({ Field: 'SalesSizes.Name', HeaderText: 'Size', Width: '38%' }),
      new CurrencyColumn({ Field: 'Price', HeaderText: 'Price', Width: '20%', IsSortable: true }),
      new DateColumn({ Field: 'DateEffective', HeaderText: 'Created On', Width: '35%', TextAlign: TextAlign.Left, IsSortable: true }),
    ];
  }

  onCancel() {
    this.router.navigate(['manage/app-configuration/sales-products'], { state: { GridContext: this.salesProductGridContext, Category: this.salesProductCategory, Active: this.salesProductActive } });
  }

}
