import { Component, Input } from '@angular/core';
import { GridConfig, KioskScreenChoice, KioskScreenConfig, ScreenConfig } from 'src/app/order-kiosk/interfaces';
import { OrderKioskConfigService, OrderKioskScreenChoiceService } from 'src/app/order-kiosk/services';
import { AlertsService, DomainConstants, RuntimeConstants, SpinnerService, plusWhite } from 'src/app/shared';
import { ButtonConfig } from 'src/app/order-kiosk/interfaces/config/button-config';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
declare let $: any;

@Component({
  selector: 'pos-kiosk-fluid-grid-layout-template',
  templateUrl: './kiosk-fluid-grid-layout-template.component.html',
  styleUrls: ['./kiosk-fluid-grid-layout-template.component.scss']
})
export class KioskFluidGridLayoutTemplateComponent {
  @Input() screenConfigs: Array<KioskScreenConfig> = [];
  @Input() screenId: number;
  @Input() screenButtons: Array<KioskScreenChoice> = [];
  isDesignMode = false;
  primaryScreenButtons: Array<KioskScreenChoice> = [];
  secondaryScreenButtons: Array<KioskScreenChoice> = [];
  screenConfig: ScreenConfig;
  secondaryButtonConfig: ButtonConfig;
  grid: GridConfig;
  secondaryGrid: GridConfig;
  buttonConfig: ButtonConfig;
  templateType = DomainConstants.KioskScreenTemplateTypes.FluidGridLayout;
  imageRootPath = RuntimeConstants.IMAGE_BASE_PATH + "/order-kiosk/";
  invoiceHeight: number;
  primaryDesignerGridId = 1;
  secondaryDesignerGridId = 2;
  icons = {
    plusWhite
  }
  constructor(private orderKioskConfigService: OrderKioskConfigService,
    private orderKioskScreenChoiceService: OrderKioskScreenChoiceService,
    private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private route: ActivatedRoute
  ) {
    this.isDesignMode = route.snapshot.params.id;
    this.screenConfig = orderKioskConfigService.newScreenConfig();
    this.grid = orderKioskConfigService.newGridConfig();
    this.secondaryButtonConfig = orderKioskConfigService.newButtonConfig(50, 300);
    this.buttonConfig = orderKioskConfigService.newButtonConfig();
    this.secondaryGrid = orderKioskConfigService.newGridConfig();
    this.secondaryGrid.NoOfCols = 1;
    this.secondaryGrid.NoOfRows = 1;
    this.secondaryGrid.IsShowTagsAndPrice = false;
  }

  ngOnInit(): void {
    this.prepareConfigs();
    this.calculateInvoiceHeight();
    this.resizeWindow();
  }

  ngAfterViewInit(): void {
    this.calculateInvoiceHeight();
  }

  calculateInvoiceHeight() {
    this.invoiceHeight = this.orderKioskConfigService.calculateInvoiceHeight(this.screenConfig);
  }

  prepareConfigs() {
    const screenConfig = this.orderKioskConfigService.parseConfigProperties(DomainConstants.KioskScreenConfigProperties.ScreenConfig, this.screenConfigs);
    if (screenConfig) this.screenConfig = screenConfig;

    const grid = this.orderKioskConfigService.parseConfigProperties(DomainConstants.KioskScreenConfigProperties.Grid, this.screenConfigs);
    if (grid) this.grid = grid;

    const buttonConfig = this.orderKioskConfigService.parseConfigProperties(DomainConstants.KioskScreenConfigProperties.ButtonConfig, this.screenConfigs);
    if (buttonConfig) this.buttonConfig = buttonConfig;

    const secondaryButtonConfig = this.orderKioskConfigService.parseConfigProperties(DomainConstants.KioskScreenConfigProperties.SecondaryButtonConfig, this.screenConfigs);
    if (secondaryButtonConfig) this.secondaryButtonConfig = secondaryButtonConfig;

    this.primaryScreenButtons = this.screenButtons.filter(x => x.ScreenConfig.Name == 'Buttons');
    this.secondaryScreenButtons = this.screenButtons.filter(x => x.ScreenConfig.Name == 'SecondaryButtons');
  }

  resizeWindow() {
    $(window).resize(() => {
      this.calculateInvoiceHeight();
    });
  }

  addKioskScreenChoice() {
    this.spinnerService.show();
    const kioskChoice = this.orderKioskScreenChoiceService.newScreenChoice();
    kioskChoice.ScreenId = this.screenId;
    kioskChoice.ConfigId = this.secondaryScreenButtons?.[0]?.ConfigId;
    kioskChoice.Ordinal = this.secondaryScreenButtons.length + 1;
    this.orderKioskScreenChoiceService.insertAndGet(kioskChoice)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: KioskScreenChoice) => {
          this.secondaryScreenButtons = [...this.secondaryScreenButtons, res];
        }, error: this.alertsService.showApiError
      });
  }

}
