import { Component, OnInit, ViewChild } from '@angular/core';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { MenuSetupService } from '../../Services/menu-setup.service';
import { ButtonWithInactiveProducts } from '../../interface/ButtonWithInactiveProducts';
import { AlertsService } from 'src/app/shared/services';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { ConfirmDeleteComponent } from 'src/app/shared/components/confirm-delete/confirm-delete.component';
import { sync, times, check,inactiveButtonsDiceOne } from 'src/app/shared/components/icon';
import { GridColumn, DeleteColumn, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { Router } from '@angular/router';

@Component({
  selector: 'pos-button-with-inactive-products',
  templateUrl: './button-with-inactive-products.component.html',
  styleUrls: ['./button-with-inactive-products.component.scss']
})
export class ButtonWithInactiveProductsComponent implements OnInit {
  @ViewChild('confirmButtonDelete') private confirmButtonDelete: ConfirmDeleteComponent;
  @ViewChild('iconTemplate', {static: true}) private iconTemplate: any;
  @ViewChild('toggleTemplate', {static: true}) private toggleTemplate: any;
  inActiveButtonList: Array<ButtonWithInactiveProducts> = [];
  public imagePath = RuntimeConstants.IMAGE_BASE_PATH;
  public icons = {
    sync,
    times,
    check,
    inactiveButtonsDiceOne
  };
  deleteInactiveButton: ButtonWithInactiveProducts;
  public message;
  screenHeight = (window.innerHeight - 245) + 'px';
  inActiveButtonColumns: Array<GridColumn> = [];
  constructor(
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private menuSetupService: MenuSetupService,
    private router: Router
  ) {
    this.reloadInactiveProducts();
  }

  ngOnInit() {
    this.columnConfiguration();
  }
  private columnConfiguration() {
    const deleteColumn = new DeleteColumn({
      Clicked: (data) => {
        this.deleteButton(data);
      },
      Width: '40px'
    });
    const iconColumn = new TemplateColumn({
      HeaderText: 'Icon',
      itemTemplate: this.iconTemplate,
      Width: '10%'
    });
    const toggleColumn = new TemplateColumn({
      HeaderText: 'Toggle',
      itemTemplate: this.toggleTemplate,
      Width: '8%',
      Field: 'Toggle',
      TextAlign: TextAlign.Center,
      IsSortable: true
    });

    this.inActiveButtonColumns = [
      new GridColumn({ HeaderText: 'Button Text', Field: 'Selection', IsSortable: true, Width: '30%' }),
      new GridColumn({ HeaderText: 'Product', Field: 'ProductName', IsSortable: true, Width: '30%' }),
      toggleColumn,
      new GridColumn({ HeaderText: 'Screen', Field: 'ScreenList', IsSortable: true, Width: '18%' }),
      iconColumn,
      deleteColumn
    ] as Array<GridColumn>;

  }
  reloadInactiveProducts() {
    this.spinnerService.show();
    this.menuSetupService.getInactiveProductLists().subscribe(response => {
      if (response) {
        this.inActiveButtonList = response;
      } else {
        this.inActiveButtonList = [];
      }
    },
      this.alertService.showApiError,
      () => {
        this.spinnerService.hide();
      });
  }

  deleteButton($event: ButtonWithInactiveProducts) {
    this.deleteInactiveButton = $event;
    this.message = StringUtils.format(Messages.ConfirmDeleteInactiveButton, { 'button': this.deleteInactiveButton.Selection });
    this.confirmButtonDelete.Show(this.message);
  }
  onConfirmAcknowledge() {
    this.spinnerService.show();
    this.menuSetupService.deleteButton(this.deleteInactiveButton.Id).subscribe(
      () => {
        this.alertService.renderSuccessMessage(
          Messages.InactiveButtonDeleted
        );
        this.reloadInactiveProducts();
      },
      this.alertService.showApiError,
      () => {
        this.spinnerService.hide();
      });
  }

  cancel() {
    this.router.navigate(['manage/console']);
  }
}
