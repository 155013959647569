<span class="product-block__menu cog-icon" *ngIf="designMode">
    <span class="product-block__menu__options" id="menu_{{button.Id}}" aria-expanded="false" data-bs-toggle="dropdown"
        (click)="hideScreenSetupMenu()">
        <pos-icon [icon]="icons.cog"></pos-icon>
    </span>
    <ul class="explorer-dropdown-menu dropdown-menu" aria-labelledby="menu_{{button.Id}}" role="menu">
        <div *ngFor="let menuOption of buttonMenuOptions" [ngClass]="{'text-center': menuOption.Action === menuActions.Heading }"
            [ngStyle]="{'cursor': menuOption.Action === menuActions.Heading ?'auto':'pointer'}">
            <span class="font-13 grey bold ps-pix-5"
                *ngIf="menuOption.Action === menuActions.Heading && button.EmbeddedScreenId">{{menuOption.Name}}</span>
            <li *ngIf="menuOption.Action != menuActions.Heading && ((menuOption.Menu !== buttonMenuOptionsConstants.RemoveEmbeddedScreen && menuOption.Menu !== buttonMenuOptionsConstants.EmbeddedScreenHeading) || button.EmbeddedScreenId)"
                (click)="openMenuConfig(menuOption)" [permissions]="menuOption.Permission" [checkPast]="false"
                [ngClass]="{'custom-orderUserPref': appStateConfigurations.UserPreferenceInvoiceLocation == 'left'}">
                <div class="order-menu-action-icon" *ngIf="menuOption.Icon">
                    <pos-icon class="black" [icon]="menuOption.Icon"></pos-icon>
                </div>
                {{menuOption.Name}}
            </li>
        </div>
    </ul>
</span>
<div [ngStyle]="{'background-color': button.SpecialFunction !== buttonFunctions.PLACEHOLDER.Name || designMode ? button.Color : 'transparent', 'padding-bottom': button.Image ? '5px' : 0, 'height': height + 'px',
    'border': (button.IsSearch ? ('4px solid ' + settingParam.SearchButtonNavigationBorderColor) : '1px solid ' + (button.BorderColor ? button.BorderColor : (button.SpecialFunction !== buttonFunctions.PLACEHOLDER.Name ? '#dadada' : 'transparent'))) }"
    class="product-button product-block" id="product-block"
    [ngClass]="{'pointer': button.SpecialFunction !== buttonFunctions.PLACEHOLDER.Name || designMode,
        'stripe-even': designMode && button.EmbeddedScreenChoiceId && (button.EmbeddedScreenNumber % 2 == 0), 
        'stripe-odd': designMode && button.EmbeddedScreenChoiceId && (button.EmbeddedScreenNumber % 2 != 0)}"
    (click)="buttonClicked()">
    <div class="button" [ngStyle]="{'color': button.TextColor, 'height': button.Image ? '65%' : 0 }">

        <!-- for out of stock product 
            <span class="out-of-stock-text"
            *ngIf="button.SalesProductId && !button.IsInStock && !button.IsAllowOutOfStockOrder"> Coming soon
        </span> -->
        <img src="{{imageRootPath}}/{{button.Image}}" class="product-image" *ngIf="button.Image" />
    </div>
    <div class="button-text" [ngStyle]="{'color': button.TextColor}" [ngClass]="{'button-text-no-img': !button.Image}">
        <div class="text">
            {{ button.ButtonText }}
        </div>
    </div>
</div>
