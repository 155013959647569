<div class="width-98 m-auto">
    <pos-edit-wrapper heading="Inventory Product Barcode Scanner" (close)="cancel()" [headingIcon]="icons.barcode"
        [isPopup]="false">
        <div class="col-12 pos-page-body border-top pt-pix-15 barcode-scanner">
            <pos-row>
                <pos-column>
                    <div class="setting-title">Please scan log label</div>
                </pos-column>

                <pos-column class="pt-pix-10">
                    <barcode-scanner-livestream type="code_128" [torch]="false" (valueChanges)="onCodeResult($event)"
                        (started)="onStarted($event)"></barcode-scanner-livestream>
                </pos-column>
            </pos-row>
            <div class="col-12 mt-pix-10 text-align-center" *ngIf="inventoryScannerBatch?.length">
                <button class="btn-lg btn pos-button submit-batch" type="button" (click)="redirectToBatchList()">Submit
                    Batch <span class="count-circle">{{inventoryScannerBatch?.length ?? 0}}</span></button>
            </div>
        </div>
    </pos-edit-wrapper>
</div>
