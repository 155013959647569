<pos-edit-wrapper (close)="onCancel(false)" [isPopup]="false" [headingIcon]="icons.infoManageCheckSquare"
  heading="Online Ordering - Option{{optionName ?': ' + optionName : ''}}">
  <form #orderingOption="ngForm" (ngSubmit)="saveOrderingOption(orderingOption.valid)" class="edit-form"
    autocomplete="off">
    <div class="col-12 container-fluid border-top pt-pix-5">
      <ul class="nav nav-tabs inner-tab pos-nav-tabs">
        <li [ngClass]="{'active': selectedTab == tabList.OptionSettings }">
          <a (click)="selectedTab = tabList.OptionSettings" class="blue">
            <span>
              <pos-icon class="theme-separator" [icon]="icons.bookmark"></pos-icon>
            </span>
            <span> Option</span>
          </a>
        </li>
        <li
          [ngClass]="{'disabled-tab':optionDetails && optionDetails.Id == 0, 'active': selectedTab == tabList.ProductSetting }">
          <a (click)="selectedTab = tabList.ProductSetting"
            [ngClass]="{'blue':optionDetails && optionDetails.Id > 0 , 'grey': optionDetails && optionDetails.Id == 0}">
            <span>
              <pos-icon class="theme-separator" [icon]="icons.boxes"></pos-icon>
            </span>
            <span> Products </span>
          </a>
        </li>
      </ul>
      <div class="col-12 pt-pix-5 screen-scroll pos-tab-body overflow-auto-tabs" >
        <div *ngIf="selectedTab == tabList.OptionSettings"
          [ngStyle]="{'height': getHeight()}">
          <pos-row>
            <pos-column>
              <pos-form-field label="Name" [validationMessages]="{required: 'Please enter name.'}"
                inputStyle="col-lg-3 col-md-4 col-sm-6 col-12">
                <input posInput class="form-control" name="categoryName" id="categoryName" #categoryName="ngModel"
                  [(ngModel)]="optionDetails.Name" [required]="true">
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field label="Icon" inputStyle="col-12">
                <pos-row>
                  <pos-column lg="3" md="4" sm="7"
                    [ngClass]="{'has-error': optionDetails.hasImageResolutionError && showCategoryIcon }">
                    <input type="file" class="form-control" accept=".png,.jpg,.jpeg,.gif,.bmp" name="optionIcon"
                        id="optionIcon" (change)="handleFileInput($event.target.files)" />
                  </pos-column>
                  <pos-column lg="3" xs="4" [hidden]="!showCategoryIcon">
                    <img class="ps-pix-5 col-40" #optionImage id="optionImage" />
                    <pos-icon [icon]="icons.times" class="pointer mt-pix-n15 remove-icon-badge" (click)="removeIcon()"></pos-icon>
                  </pos-column>
                  <pos-column lg="5" sm="5" class="pt-pix-7 pe-0"
                    *ngIf="optionDetails.Id > 0 && !showCategoryIcon && categoryIconName">
                    <span class="edit-form__section__label ps-pix-5">{{categoryIconName}}</span>
                    <span class="mt-pix-n4" (click)="removeIcon()">
                      <pos-icon [icon]="icons.times" class="pointer mt-pix-n7 remove-icon-badge"></pos-icon>
                    </span>
                  </pos-column>
                  <pos-column xs="12" class="pe-0">
                    <span *ngIf="optionDetails.hasImageResolutionError && showCategoryIcon">
                      <label class="red-extreme">{{resolutionWarningMessage}}</label>
                    </span>
                  </pos-column>
                </pos-row>
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field label="Description">
                <textarea posInput class="form-control" name="description" #description="ngModel"
                  [(ngModel)]="optionDetails.Description"></textarea>
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field label="Min Selection">
                <input posInput type="number" class="form-control col-120" name="minSelection"
                  [(ngModel)]="optionDetails.MinSelections">
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field label="Max Selection">
                <input posInput type="number" class="form-control col-120" name="maxSelection"
                  [(ngModel)]="optionDetails.MaxSelections">
              </pos-form-field>
            </pos-column>
          </pos-row>
        </div>
        <div *ngIf="selectedTab == tabList.ProductSetting"
          [ngStyle]="{'height': getHeight()}">
          <pos-row>
            <pos-column>
              <pos-form-field label="Select Product" inputStyle="col-lg-8 col-12">
                <pos-row colSm="6">
                  <pos-column>
                    <ng-select posInput name="salesProduct" [items]="salesProducts" bindLabel="ButtonText"
                      placeholder="Select product" [(ngModel)]="selectedProduct">
                    </ng-select>
                  </pos-column>
                  <pos-column class="pt-pix-10 pt-pix-sm-0">
                    <button type="button" class="btn btn-primary white normal me-pix-15 pos-button" (click)="addButtonToOption()" [disabled]="!selectedProduct">
                      Add
                    </button>
                    <button type="button" class="btn btn-primary white normal pos-button" (click)="editProduct(0);">
                      <span>
                        <pos-icon [icon]="icons.newProduct" class="pointer"></pos-icon>
                      </span>
                      Create Button
                    </button>
                  </pos-column>
                </pos-row>
              </pos-form-field>
            </pos-column>
          </pos-row>
          <div class="col-12">
            <ng-table #gridComponent [sort]="false" [paginator]="false" [columns]="orderingOptionProductColumns"
              [data]="optionProducts" [isReorderableTable]="true" [ReorderableColumnRow]="'row'"
              (onRowReorder)="gridRowReorder($event)">
            </ng-table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 action-button-container page-footer">
      <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" scrollup>Save </button>
      <button type="button" class="btn-secondary btn-lg btn btn-cancel pos-button" scrollup
        (click)="onCancel(false)">Close</button>
    </div>
  </form>
</pos-edit-wrapper>
<pos-confirm-delete (onConfirmAcknowledge)="onConfirmAcknowledge()" #confirmDeleteOptionButton>
</pos-confirm-delete>
<ng-template let-data="rowData" #productIconTemplate>
  <span style="font-size: 13px" *ngIf="(data.Icon)" class="green text-center vertical-mid">
    <pos-icon [icon]="icons.check"></pos-icon>
  </span>
</ng-template>
<ng-template let-data="rowData" #productActiveTemplate>
  <span style="font-size: 13px" *ngIf="data.IsActive" class="green text-center vertical-mid">
    <pos-icon [icon]="icons.check"></pos-icon>
  </span>
</ng-template>
