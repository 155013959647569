<div>
    <button data-dismiss="modal" aria-label="Close" class="close" type="button" (click)="onCancel()">
        &times;
    </button>
    <h4 class="modal-content__heading">Copy Subscription</h4>
</div>
<form #copySubscription="ngForm" (ngSubmit)='onSave(copySubscription.valid)'>
    <div class="overflow-hidden">
        <div class="col-xs-12 pos-modal-body screen-scroll">
            <pos-row>
                <pos-column>
                    <pos-form-field label="Copy Subscriptions of" [validationMessages]="{required: 'Please select user.'}">
                        <ng-select posInput [items]="users" bindLabel="name" [(ngModel)]="selectedUser" #user="ngModel"
                            name="user" placeholder="Select user" [required]="true" (change)="loadUserSubscription()"
                            [clearOnBackspace]="false">
                        </ng-select>
                    </pos-form-field>
                </pos-column>
            </pos-row>
            <div class="col-md-12 pt-pix-10">
                <div class="overflow-hidden">
                    <ng-table #copyReportSubscription [search]="false" [sort]="false" [paginator]="false"
                        [columns]="reportsSubscriptionColumns" [data]="userSubscriptions" [scrollable]="true"
                        [scrollHeight]="(tableHeight + 'px').toString()">
                    </ng-table>
                </div>
            </div>
        </div>

        <ng-template let-data="rowData" let-index="index" #subscriptionChkHeaderTemplate>
            <p-checkbox name="selectAll" *ngIf="userSubscriptions.length" #chkSelectAll="ngModel"
                (onChange)="selectAllSubscriptions()" [(ngModel)]="selectAll" binary="true">
            </p-checkbox>
        </ng-template>

        <ng-template let-data="rowData" let-index="index" #subscriptionChkTemplate>
            <p-checkbox name="chkSubscription{{index}}" (onChange)="removeSelectAll()" #chkSubscription="ngModel"
                [(ngModel)]="data.Subscribe" binary="true">
            </p-checkbox>
        </ng-template>

        <ng-template let-data="rowData" let-index="index" #scheduleTemplate>
            <div *ngIf="data.Schedule">
                {{data.Schedule}}
            </div>
            <div *ngIf="data.DayOfWeek && !data.Schedule">
                {{data.DayOfWeek}} at {{data.Time | tark_time}}
            </div>
        </ng-template>

        <div class="col-12 action-button-container popup-footer pb-pix-10">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" value="Save">Copy</button>
            <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" value="Cancel"
                (click)="onCancel()">Cancel</button>
        </div>
        <div class="clearfix"></div>
    </div>
</form>
