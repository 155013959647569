import { Component, OnInit, ViewChild } from '@angular/core';
import { SpinnerService, AlertsService, ApplicationStateService, Messages, SettingParam, FieldInfoMessages, BaseFormComponent, FormUtilityService } from 'src/app/shared';
import { LicenseService } from '../../service/license.service';
import { Permissions, Levels } from '../../../../shared/constants';
import { ConfiguratorService } from 'src/app/configurator/configurator.service';
import { Router } from '@angular/router';
import { licenseIdCard } from 'src/app/shared/components/icon';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'pos-license',
  templateUrl: './license.component.html',
})
export class LicenseComponent extends BaseFormComponent implements OnInit {

  @ViewChild('formLicense')form: NgForm;
  currentTerminalId: number;
  parameters: SettingParam;
  get getForm(): NgForm {
    return this.form
  }

  fieldInfoMessages = {
    LicenseAccountId: FieldInfoMessages.LicenseAccountId
  };

  permission = {
    name: Permissions.TerminalsandLicensing,
    readOnlyLevel: Levels.Access
  };

  icons = {
    licenseIdCard
  }

  constructor(private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private licenseService: LicenseService,
    private applicationStateService: ApplicationStateService,
    private router: Router,
    private configuratorService: ConfiguratorService,
    formUtilityService: FormUtilityService) {
    super(formUtilityService);
    this.parameters = this.applicationStateService.newSettingParams();
  }

  ngOnInit() {
    this.currentTerminalId = this.applicationStateService.terminalId;
    this.reloadLicense();
  }

  reloadLicense() {
    this.spinnerService.show();
    this.licenseService.getLicenseInfo(this.currentTerminalId).subscribe(response => {
      this.onFormChange(this.form);
      this.getLicenseInfoCompleted(response);
    },
      this.alertsService.showApiError,
      () => { this.spinnerService.hide(); });
  }

  getLicenseInfoCompleted(response) {
    this.parameters = response;
  }

  saveLicense(valid) {
    if (!valid) {
      return;
    }

    this.spinnerService.show();
    this.licenseService.saveLicense(this.parameters).subscribe(response => {
      this.alertsService.renderSuccessMessage(Messages.LicenseSaveSuccess);
      this.reloadLicense();
      this.configuratorService.startAutoCloseInterval();
    },
      (error) => { this.alertsService.showApiError(error); },
      () => { this.spinnerService.hide(); });
  }

  onFormChange($event: NgForm) {
    this.form = $event;
    this.setInitialValue();
  }

  cancelEditing() {
    this.router.navigate(['/manage/system-configuration']);
  }

  updateLicenseInfo() {
    this.spinnerService.show();
    this.licenseService.refreshLicense().subscribe();
    this.licenseService.initializeApplication().subscribe(response => {
      this.updateLicenseInfoCompleted(response);
      this.cancelEditing();
    },
      (error) => { this.alertsService.showApiError(error); },
      () => { this.spinnerService.hide(); });
  }

  updateLicenseInfoCompleted(response) {
    this.applicationStateService.licenseInfo = response;
  }

}
