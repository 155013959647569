import { Component, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormUtilityService, ModalFormComponent, ModalService } from 'src/app/shared';

@Component({
  selector: 'pos-copy-product-recipe-modal',
  templateUrl: './copy-product-recipe-modal.component.html',
  styleUrls: []
})
export class CopyProductRecipeModalComponent extends ModalFormComponent {
  get getForm(): NgForm {
    return this.copyProductRecipeForm;
  }

  @Input() activeSalesProduct: Array<any> = [];
  @Input() selectedProduct;
  @ViewChild('copyProductRecipeForm') copyProductRecipeForm: NgForm;
  constructor(modalRef: BsModalRef, modalService: ModalService, protected formUtilityService: FormUtilityService) {
    super(modalRef, modalService, formUtilityService);
  }

  selectActiveSalesProduct() {
    this.hide({ shouldReload: true, Value: { selectedProduct: this.selectedProduct }});
  }

  closeModal() {
    this.hide({ shouldReload: false });
  }

}
