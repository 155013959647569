import { MessagePayload } from './message-payload';

export class HicCameraRequestPayload extends MessagePayload {

    public annotationText : string;
    public hardwareDeviceId: number;
    public terminalId: number;
    public terminalName: string;
    public userId: number;
    constructor(hardwareDeviceId, terminalId, terminalName, userId, annotationText: string) {
        super();
        this.hardwareDeviceId =  hardwareDeviceId;
        this.terminalId = terminalId; 
        this.terminalName = terminalName;
        this.annotationText = annotationText;
    }
}
