import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { UserWorkSchedule, WorkSchedule, UserWorkScheduler } from '../../information-management/work-schedule/interface/work-schedule';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';


@Injectable()
export class WorkScheduleService extends BaseCrudService<WorkSchedule> {

    constructor(httpService: HttpClient) {
        super('workschedule', httpService);
    }
    workScheduleApiBasePath = `${RuntimeConstants.API_PATH}workschedule`;

    newWorkSchedule() {
        return {
            Id: 0,
            user_id: null,
            user_name: '',
            start_date: new Date(),
            start_time: new Date(),
            dateadded: new Date(),
            end_date: new Date(),
            end_time: new Date(),
            optional: false,
            TagId: null,
            Message: ''
        };
    }
    getUserWorkSchedules(userWorkSchedule: UserWorkScheduler): Observable<UserWorkSchedule[]> {
        return this.httpService.post<UserWorkSchedule[]>(`${this.workScheduleApiBasePath}/user`, userWorkSchedule);
    }
    addWorkScheduleBatch(workSchedules: WorkSchedule[]): Observable<WorkSchedule[]> {
        return this.httpService.post<WorkSchedule[]>(`${this.workScheduleApiBasePath}/batch`, workSchedules);
    }
    SaveWorkScheduleImport(file: File, skipConflicts, overwriteConflicts): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('importWorkScheduleModel', JSON.stringify({overwriteConflicts: overwriteConflicts, skipConflicts: skipConflicts}));
        return this.httpService.post(`${this.workScheduleApiBasePath}/import`, formData);
    }
}
