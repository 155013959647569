import { AlertsService } from './../../../../shared/services/alerts.service';
import { OrderService } from '../../../../shared/services/order.service';
import { plusMessage } from './../../../../shared/components/icon/icons';
import { RuntimeConstants, ModalService, SpinnerService, ModalFormComponent, FormUtilityService, Permissions, Levels } from 'src/app/shared';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ScreenEditComponent } from 'src/app/information-management';
import { ScreenPreviewComponent } from '../screen-preview';
import { NgForm } from '@angular/forms';
import { ScreenButtons } from 'src/app/orders/interface';

declare let $: any;
@Component({
  selector: 'pos-choose-next-screen',
  templateUrl: './choose-next-screen.component.html',
  styleUrls: ['./choose-next-screen.component.scss']
})
export class ChooseNextScreenComponent extends ModalFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.chooseNextScreenForm;
  }

  @ViewChild('screenEdit') public screenEdit: any;
  @ViewChild('screenPreviewComponent') screenPreviewComponent: ScreenPreviewComponent;
  @ViewChild('chooseNextScreenForm') chooseNextScreenForm: NgForm;
  screens: any = [];
  selectedScreen: number;
  @Input()
  buttonInfo: any;
  @Input() allButtons: Array<ScreenButtons> = [];
  isNewScreenAdded = false;

  public icons = {
    plusMessage
  }

  imageBaseFile: string = RuntimeConstants.IMAGE_BASE_PATH;
  permission = {
    name: Permissions.UIScreens,
    editLevel: Levels.Edit
  };

  constructor(private orderService: OrderService, modalService: ModalService,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    chooseNextScreenModalRef: BsModalRef,
    formUtilityService: FormUtilityService) {
    super(chooseNextScreenModalRef, modalService, formUtilityService);
  }

  ngOnInit() {
    this.getScreenData(this.buttonInfo.NextScreenId ? this.buttonInfo.NextScreenId : -1);
  }

  getScreenData(screenId: number) {
    this.spinnerService.show();
    this.orderService.getScreenData(0)
      .subscribe({
        next: (response) => {
          this.screens = response.LoadScreenModel
          this.onScreenSelectionChange(screenId);
          this.selectedScreen = screenId;
        }, error: this.alertService.showApiError,
        complete: () => {
          this.spinnerService.hide();
        }
      });

  }

  getHeight() {
    return $(window).height() - 380 + 'px';
  }

  addScreen() {
    const modalRef = this.modalService.show(ScreenEditComponent, {
      animated: false,
      class: 'vertical-center modal-lg',
      backdrop: 'static',
    });

    modalRef.close.subscribe(res => {
      if (res?.shouldReload) {
        this.isNewScreenAdded = true;
        this.getScreenData(res.savedScreenId);
      }
    });
  }

  saveNextScreen() {
    this.spinnerService.show();
    let screenObject = { screenid: 0, SelectedScreenId: 0, ChoiceId: 0 };
    screenObject.screenid = this.buttonInfo.ScreenId;
    screenObject.SelectedScreenId = this.selectedScreen == -1 ? null : this.selectedScreen;
    screenObject.ChoiceId = this.buttonInfo.Id;
    this.orderService.setNextScreen(screenObject)
      .subscribe({
        next: (response) => {
          this.hide({ reload: true, isNewScreenAdded: this.isNewScreenAdded });
        }, error: this.alertService.showApiError,
        complete: () => {
          this.spinnerService.hide();
        }
      });
  }

  onScreenSelectionChange(screenId) {
    if (this.selectedScreen) {
      this.screenPreviewComponent?.reloadScreenButtons(screenId);
    }
  }

  cancel() {
    this.hide({ reload: false });
  }

}
