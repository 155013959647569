import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { QuickBooksConfigurationParams } from '../interface/QuickBooksConfigurationParams';


@Injectable({
  providedIn: 'root'
})
export class QuickBooksConfigurationService {

 
  quickBooksConfigurationApiBasePath = `${RuntimeConstants.API_PATH}quick-books-configuration`;
  constructor(private httpService: HttpClient) { }

  newQuickBooksConfigurationParameter(): QuickBooksConfigurationParams {
    return {
      QuickBooksCustomerName: '',
      QuickBooksItemName: '',
      QuickBooksDepositToAccount: ''
    };
  }

  getQuickBooksParameters(): Observable<QuickBooksConfigurationParams> {
    return this.httpService.get<QuickBooksConfigurationParams>(`${this.quickBooksConfigurationApiBasePath}`);
  }

  updateQuickBooksParameters(quickBooksConfigurationParameters: QuickBooksConfigurationParams) {
    return this.httpService.post<QuickBooksConfigurationParams>(`${this.quickBooksConfigurationApiBasePath}`, quickBooksConfigurationParameters);
  }
}
