<pos-edit-wrapper heading="{{ id ? 'Edit' : 'Add' }} Kiosk Screen{{ id ? ': '+ screenName : '' }}" (close)="cancel()"
    [headingIcon]="icons.tabletAlt" [isPopup]="isPopup">
    <form #kioskScreenForm="ngForm" class="edit-form" (ngSubmit)="submit(kioskScreenForm.valid)">
        <div class="col-12 pos-page-body" [ngClass]="{'border-top pt-pix-5': !isPopup}">
            <pos-row class="col-lg-8 col-12">
                <pos-column>
                    <pos-form-field label="Name" [validationMessages]="{required: 'Please enter name.'}">
                        <input posInput type="text" name="name" #name="ngModel" class="form-control"
                            [(ngModel)]="kioskScreen.Name" [required]="true" />
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Template Type"
                        [validationMessages]="{required: 'Please enter template type.'}">
                        <ng-select posInput appendTo="body" [items]="templateTypes" name="templateType"
                            placeholder="Select template type" [(ngModel)]="kioskScreen.TemplateType"
                            [required]="true"></ng-select>
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Default Next Screen">
                        <ng-select posInput [items]="screensList" placeholder="Select default next screen" appendTo="body"
                            [(ngModel)]="kioskScreen.DefaultNextScreenId" name="nextScreen" #nextScreen="ngModel"
                            bindLabel="Name" bindValue="Id">
                        </ng-select>
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field>
                        <p-checkbox posInput name="filteredProductsByTags" #filteredProductsByTags="ngModel"
                            [(ngModel)]="kioskScreen.IsFilteredProductsByTags" binary="true"
                            label="Filter Products by Tags">
                        </p-checkbox>
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container" [ngClass]="{'popup-footer': isPopup, 'page-footer': !isPopup}">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save</button>
            <button type="button" class="btn-secondary btn-lg btn pos-button"
                [ngClass]="{'btn-cancel-modal': isPopup, 'btn-cancel':!isPopup}" (click)="cancel()">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
