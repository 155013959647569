import { EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ApplicationStateService } from './../../../../shared/services/application-state.service';
import { Component, OnInit, ViewChild, Input, Output } from '@angular/core';
import { SpinnerService, AlertsService, Messages, ModalFormComponent, ModalService, FormUtilityService } from 'src/app/shared';
import { UserTrainingNotesService } from '../../services';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'pos-user-training-note-edit',
  templateUrl: './user-training-note-edit.component.html'
})
export class UserTrainingNoteEditComponent extends ModalFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.form;
  }
  @ViewChild('userTrainingNoteForm', { static: true }) form: NgForm;

  @Input('trainingData')
  trainingData: any;

  @Input('userTrainingNoteId')
  userTrainingNoteId: number;
  @Input('userTrainingLevelId')
  userTrainingLevelId: number;
  @Input('userTrainingNote')
  userTrainingNote: string;
  @Output()
  onCancelTrainingNotes: EventEmitter<any> = new EventEmitter();
  @Output()
  onSaveTrainingNotes: EventEmitter<any> = new EventEmitter();
  @ViewChild('userTrainingNoteForm')
  userTrainingNoteForm: NgForm;

  constructor(private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private applicationStateService: ApplicationStateService,
    private userTrainingNotesService: UserTrainingNotesService,
    public modalService: ModalService,
    protected bsModelRef: BsModalRef,
    protected formUtilityService: FormUtilityService) {
      super(bsModelRef, modalService, formUtilityService);
  }

  ngOnInit() {
  }

  saveUserTrainingNote(isValid) {
    if (isValid) {
      let userId = this.applicationStateService.userDetails.id;
      let userTraininigNote = { UserTrainingLevelId: this.userTrainingLevelId, UserId: userId, Note: this.userTrainingNote };
      this.spinnerService.show();
      if (this.userTrainingNoteId) {
        this.userTrainingNotesService.updateUserTrainingNote(this.userTrainingNoteId, userTraininigNote)
          .subscribe({
            next: (response) => {
              //this.userTrainingNotes = response;
              this.alertService.renderSuccessMessage(Messages.TrainingNoteSavedSuccess);
              this.hide({shouldReload: true});
            }, error: this.alertService.showApiError,
            complete: () => {
              this.spinnerService.hide();
            }
          });
      }
      else {
        this.userTrainingNotesService.addUserTrainingNote(userTraininigNote)
          .subscribe({
            next: (response) => {
              this.alertService.renderSuccessMessage(Messages.TrainingNoteSavedSuccess);
              this.hide({shouldReload: true});
            }, error: this.alertService.showApiError,
            complete: () => {
              this.spinnerService.hide();
            }
          });
      }
    }
  }
  onCancel(reload: boolean) {
    this.hide({shouldReload: false});
  }
}
