import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { SettingParam, Messages, BaseFormComponent, FormUtilityService } from 'src/app/shared';
import { finalize } from 'rxjs/operators';
import { ExternalLoginsService } from '../../service/external-logins.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { externalLoginsSigninAlt } from 'src/app/shared/components/icon';
@Component({
  selector: 'pos-external-logins',
  templateUrl: './external-logins.component.html',
})
export class ExternalLoginsComponent extends BaseFormComponent implements OnInit {
  @ViewChild('externalLoginForm') externalLoginForm: NgForm;
  get getForm(): NgForm {
    return this.externalLoginForm
  }
  parameters: SettingParam;
  paramUserFailedLoginAttemptsLimit: number;
  paramIpFailedLoginAttemptsLimit: number;
  icons = {
    externalLoginsSigninAlt
  };
  constructor(private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private router: Router,
    private externalLoginsService: ExternalLoginsService,
    formUtilityService: FormUtilityService) {
    super(formUtilityService);
  }

  ngOnInit() {
    this.reloadExternalLogins();
  }

  reloadExternalLogins() {
    this.spinnerService.show();
    this.externalLoginsService.getExternalLogins()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: response => {
          this.parameters = response;
          this.paramUserFailedLoginAttemptsLimit = this.parameters.UserFailedLoginAttemptsLimit;
          this.paramIpFailedLoginAttemptsLimit = this.parameters.IpFailedLoginAttemptsLimit;
        }, error: this.alertService.showApiError
      });
  }

  saveExternalLogins(isValid) {
    if (!isValid || this.paramUserFailedLoginAttemptsLimit < 0 || this.paramIpFailedLoginAttemptsLimit < 0) {
      return;
    }

    this.parameters.UserFailedLoginAttemptsLimit = this.paramUserFailedLoginAttemptsLimit;
    this.parameters.IpFailedLoginAttemptsLimit = this.paramIpFailedLoginAttemptsLimit;

    this.spinnerService.show();
    this.externalLoginsService.saveExternalLogins(this.parameters)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          this.alertService.renderSuccessMessage(Messages.ExternalLoginsSaveSuccess);
          this.router.navigate(['/manage/system-configuration']);
        }, error: this.alertService.showApiError
      });
  }

  onCancel() {
    this.router.navigate(['/manage/system-configuration']);
  }
}
