<div class="col-12 overflow-hidden p-0" *ngIf="!isLoading" [ngClass]="isInteractive? '' : 'radius-25'"
    [ngStyle]="(config.color || config.bgColor || config.borderColor) && {'color': config.color,'background-color': config.bgColor,'border': config.borderColor?'solid 2px '+ config.borderColor :''}">
    <div class="col-12 pt-pix-10">
        <div class="col-12 p-0">
            <highcharts-chart *ngIf="data && data.length" [Highcharts]="highcharts" [options]="chartOptions">
            </highcharts-chart>
            <div *ngIf="!data || data.length == 0" class="text-center grey">
                No data available
            </div>
        </div>
        <div class="col-12 p-0 text-center font-20" [ngStyle]="{'color': config.color}">
            {{ config.metricsName }}&nbsp;
        </div>
    </div>
</div>
<div *ngIf="isLoading" class="col-12 p-0 full-height align-vertical-center"
    [ngClass]="isInteractive? '' : 'radius-25'"
    [ngStyle]="{'color':config.color,'background-color':config.bgColor,'border':'solid 2px '+(config.borderColor || config.bgColor || '#fff')}">
    <div class="col-12 ps-pix-15 pe-pix-15 pt-pix-5 pb-pix-5">
        <pos-widget-loading></pos-widget-loading>
    </div>
</div>
