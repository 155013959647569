<ng-template #logInventoryProductModal>
  <div>
    <div>
      <button (click)="onCancel()" class="close" type="button">&times;</button>
      <h4 class="modal-content__heading">Log Inventory for {{eventMessage.InventoryProductName}}</h4>
    </div>
    <div class="modal-body pb-0">
      <div>{{ exhaustionMessage }}</div>
      <form #inventoryProductLog="ngForm">
        <pos-inventory-product-log [isPopup]="true" [isFromInventoryExhaustion]="true"
          [(inventoryProduct)]="inventoryProduct"></pos-inventory-product-log>
      </form>
    </div>
    <div class="col-md-12 action-button-container popup-footer pb-pix-10">
      <button class="btn btn-primary btn-lg btn-save pos-button" (click)="onLog()" type="button">Log</button>
      <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="onCancel()" type="button">Cancel</button>
    </div>
  </div>
  <div class="clearfix"></div>
</ng-template>
