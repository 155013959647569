import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RuntimeConstants } from '../../../shared/constants';
import { AlertsService, ApplicationStateService, OrderService } from 'src/app/shared/services';
import { SpinnerService } from 'src/app/shared';
import { finalize } from 'rxjs/operators';
import { ConfirmOrderService, KioskNavigationService } from '../../services';
import { ConfirmOrderStyle } from '../../interfaces/config/confirm-order-style';

@Component({
  selector: 'pos-kiosk-order-confirmed',
  templateUrl: './kiosk-order-confirmed.component.html',
  styleUrls: ['./kiosk-order-confirmed.component.scss']
})
export class KioskOrderConfirmedComponent {
  baseImagePath = `${RuntimeConstants.IMAGE_BASE_PATH}/order-kiosk/default-screens/`;
  image = 'order-kiosk/thank-you.png';
  displayText = "TRANSACTION COMPLETE";
  orderAppreciationText = "";
  orderNumber: number;
  timeoutSeconds = 30;
  timeout: NodeJS.Timeout;
  style: ConfirmOrderStyle;
  constructor(private router: Router, private route: ActivatedRoute,
    private applicationStateService: ApplicationStateService,
    private spinnerService: SpinnerService,
    private orderService: OrderService,
    private kioskNavigationService: KioskNavigationService,
    private alertService: AlertsService,
    private orderConfirmService: ConfirmOrderService
  ) {
    this.style = orderConfirmService.newConfirmOrderStyle();
   }

  ngOnInit() {
    if (!this.kioskNavigationService.orderDetails) {
      this.router.navigate(['..'], { relativeTo: this.route });
    } else {
      const settingParam = this.applicationStateService?.settingParam;
      if (settingParam) {
        this.image = settingParam.OrderKioskOrderConfirmImage;
        this.orderAppreciationText = settingParam.OrderKioskOrderConfirmAppreciationText;
        this.timeoutSeconds = settingParam.OrderKioskOrderConfirmScreenIdleTimeout ?? 30;
        if(settingParam.OrderKioskOrderConfirmStyle) {
          this.style = JSON.parse(settingParam.OrderKioskOrderConfirmStyle) as ConfirmOrderStyle;
        }
      }      
      this.orderNumber = this.kioskNavigationService.orderDetails.SurrogateOrderId;
      this.printOrder();
      this.timeout = setTimeout(() => {
        this.redirectToHome();
        clearTimeout(this.timeout);
      }, this.timeoutSeconds * 1000);
    }
  }

  redirectToHome(data = {}) {
    clearTimeout(this.timeout);
    this.kioskNavigationService.resetProps();
    this.kioskNavigationService.completeNavigation();
    this.router.navigate(['home'], { relativeTo: this.route.parent, state: data })
  }

  printOrder = () => {
    const order = this.kioskNavigationService.orderDetails;
    const settingParam = this.applicationStateService.settingParam;
    const printOrderModel = {
      OrderId: order.Id.Value,
      TerminalId: this.applicationStateService.terminalId,
      Parameter: settingParam,
      PaidAmount: order.PaidAmount,
      SurrogateOrderId: order.SurrogateOrderId,
      ReceiptPrinterId: settingParam?.ReceiptPrinter?.Id,
      PrintSeatDetails: []
    };
    this.spinnerService.show();
    this.orderService.printCheckWithQR(printOrderModel)
      .pipe(finalize(() => {
        this.spinnerService.hide()
      }))
      .subscribe({
        next: (res) => {
        }, error: this.alertService.showApiError
      });
  }

}
