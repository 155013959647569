import { ApplicationStateService } from './../../../../shared/services/application-state.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AlertsService, BaseFormComponent, FormUtilityService, GridContextInfo, Messages, SpinnerService } from 'src/app/shared';
import { zip } from 'rxjs';
import * as _ from 'lodash';
import { TrainingTopicLevel } from '../../interface/training-topic-level';
import { TrainingTopicLevelService } from '../../../../shared/services/training-topic-level.service';
import { TrainingTopic } from 'src/app/information-management/training-topics/interface/training-topic';
import { TrainingAreaService } from 'src/app/shared/services/training-area.service';
import { ActivatedRoute, Router } from '@angular/router';
declare let $: any;
@Component({
    selector: 'pos-training-topic-level-edit',
    templateUrl: './training-topic-level-edit.component.html',
    styleUrls: ['../../../../inbox-messages/components/inbox-messages-edit/inbox-messages-edit.component.scss',
        './training-topic-level-edit.component.scss']
})
export class TrainingTopicLevelEditComponent extends BaseFormComponent implements OnInit {
    get getForm(): NgForm {
        return this.form
    }
    @ViewChild('formTrainingTopicLevel', { static: true }) form: NgForm;
    @ViewChild('ckeditor', { static: true }) ckeditor;
    trainingTopicLevelTransParam: any;
    trainingTopics: Array<TrainingTopic>;
    topicLevel: TrainingTopicLevel;
    areaName: string;
    topicName: string;
    areaGridContext: GridContextInfo = null;
    topicGridContext: GridContextInfo = null;
    props = {
        labelClass: '',
        controlBoxClass: 'col-sm-7 col-xs-12'
    }

    getHeight() {
        return $(window).height() - 300 > 623 ? 623 : $(window).height() - 300;
    }
    constructor(private route: ActivatedRoute,
        private alertService: AlertsService,
        private spinnerService: SpinnerService,
        private router: Router,
        private trainingTopicLevelService: TrainingTopicLevelService,
        private trainingAreaService: TrainingAreaService,
        private applicationStateService: ApplicationStateService,
        formUtilityService: FormUtilityService) {
        super(formUtilityService);
        this.topicLevel = this.trainingTopicLevelService.newTrainingTopicLevel();
        const navigation = router.getCurrentNavigation();
        this.topicLevel.Id = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
        this.topicLevel.TrainingTopicId = route.snapshot.params.topicId ? parseInt(route.snapshot.params.topicId, 10) : 0;
        this.topicLevel.TrainingTopic.TrainingAreaId = route.snapshot.params.areaId ? parseInt(route.snapshot.params.areaId, 10) : 0;
        this.areaName = navigation?.extras?.state?.areaName ?? '';
        this.topicName = navigation?.extras?.state?.topicName ?? '';
        this.topicLevel.TrainingTopic.Name = navigation?.extras?.state?.topicName ?? '';
        this.areaGridContext = navigation?.extras?.state?.AreaGridContext ?? this.areaGridContext;
        this.topicGridContext = navigation?.extras?.state?.TopicGridContext ?? this.topicGridContext;
    }

    ngOnInit() {
        this.getTrainingTopicLevel(this.topicLevel.Id);
        this.setInitialValue();
    }
    getTrainingTopicLevel(topicLevelId: number) {
        if (topicLevelId > 0) {
            this.spinnerService.show();

            zip(
                this.trainingTopicLevelService.getTrainingTopicLevel(topicLevelId),
                (
                    topicLevel: any
                ) => {
                    this.topicLevel = topicLevel;
                    if (!this.topicName && topicLevel) {
                        this.topicName = topicLevel.TrainingTopic.Name;
                    }
                    if (!this.areaName && topicLevel.TrainingTopic) {
                        this.trainingAreaService.getTrainingArea(topicLevel.TrainingTopic.TrainingAreaId)
                            .subscribe({
                                next: (area) => {
                                    this.areaName = area.Name;
                                }, error: this.alertService.showApiError
                            });
                    }
                }
            ).subscribe({
                next: (res) => {
                }, error: this.alertService.showApiError,
                complete: () => {
                    this.spinnerService.hide();
                }
            });
        }
    }
    getheight = function () {
        return $(window).height() - 360 + 'px';
    };
    public saveTrainingTopicLevel(isValid: boolean) {
        const snapshot = this.ckeditor.instance.getSnapshot();
        this.ckeditor.instance.loadSnapshot(snapshot);
        if (!isValid || (this.topicLevel.LevelScore != null && this.topicLevel.LevelScore <= 0)) {
            return;
        }
        if (this.topicLevel.Id > 0) {
            this.spinnerService.show();
            this.trainingTopicLevelService.updateTrainingTopicLevel(this.topicLevel, this.applicationStateService.userDetails.id).subscribe(
                res => {
                    this.alertService.renderSuccessMessage(Messages.TrainingTopicLevelSavedSuccess);
                    this.onCancel();
                }, this.alertService.showApiError,
                () => {
                    this.spinnerService.hide();
                }
            );
        } else {
            this.spinnerService.show();
            this.trainingTopicLevelService.saveTrainingTopicLevel(this.topicLevel, this.applicationStateService.userDetails.id).subscribe(
                res => {
                    this.alertService.renderSuccessMessage(Messages.TrainingTopicLevelSavedSuccess);
                    this.onCancel();
                }, this.alertService.showApiError,
                () => {
                    this.spinnerService.hide();
                }
            );
        }
    }

    onCancel() {
        this.router.navigate(['training-areas', this.topicLevel.TrainingTopic.TrainingAreaId, 'topics', this.topicLevel.TrainingTopicId, 'levels'],
            {
                state: {
                    topicId: this.topicLevel.TrainingTopicId, areaId: this.topicLevel.TrainingTopic.TrainingAreaId,
                    areaName: this.areaName,
                    topicName: this.topicLevel.TrainingTopic.Name,
                    AreaGridContext: this.areaGridContext, TopicGridContext: this.topicGridContext
                }, relativeTo: this.route.parent
            });
    }

    onCancelTopic() {
        this.router.navigate(['training-areas', this.topicLevel.TrainingTopic.TrainingAreaId, 'topics'],
            {
                state: {
                    areaId: this.topicLevel.TrainingTopic.TrainingAreaId, areaName: this.areaName,
                    topicName: this.topicLevel.TrainingTopic.Name, GridContext: this.areaGridContext, TopicGridContext: this.topicGridContext
                }, relativeTo: this.route.parent
            });
    }
}
