<div class="page-content">
    <div class="tab-content">
        <div class="tab-pane fade in active pb-1 show">
            <div class="col-12 px-3">
                <a class="close pointer" (click)="closeList()">×</a>
                <div class="row mx-0">
                    <div class="col-10 px-0 display-flex">
                        <pos-icon *ngIf="headingIcon" [icon]="headingIcon" class="pe-pix-10 vertical-center-align page-content__header-icon"></pos-icon>
                        <h3 class="page-content__header" [innerHTML]="heading"></h3>
                    </div>
                </div>
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>
