import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Permissions, Levels, Messages } from '../../../../shared/constants/';
import { GridColumn, EditColumn, DeleteColumn, LinkColumn, TemplateColumn, TextAlign, TableComponent } from '@tarktech/tark-ng-utils';
import { Vendor } from '../../interface/vendor';
import { ConfirmDeleteComponent } from 'src/app/shared/components/confirm-delete/confirm-delete.component';
import { VendorService } from '../../service/vendor.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { AuthenticationService } from 'src/app/shared/auth/services/authentication.service';
import { finalize } from 'rxjs/operators';
import { listAlt, barsWhite, check, sortNumericUp, vendorStruck } from 'src/app/shared/components/icon';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import { BaseGridComponent, ApplicationStateService } from 'src/app/shared';
import { ActivatedRoute, Router } from '@angular/router';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { LinkTemplateColumn } from '@tarktech/tark-ng-utils/table/link-template-column';
import { InventoryLogDueDateOverrideEditComponent } from '../inventory-log-due-date-override-edit';

@Component({
    selector: 'pos-vendors-list',
    templateUrl: './vendors-list.component.html',
    styleUrls: ['./vendors-list.component.scss']
})
export class VendorsListComponent extends BaseGridComponent implements OnInit {

    permission = {
        name: Permissions.InventoryVendors,
        readOnlyLevel: Levels.ReadOnly,
        editLevel: Levels.Edit
    };
    icons = {
        barsWhite,
        listAlt,
        check,
        sortNumericUp,
        vendorStruck
    };
    vendorColumns: Array<GridColumn> = [];
    printVendorColumns: Array<GridColumn> = [];
    vendors: Vendor[] = [];
    vendorsSearchResult: Vendor[] = [];
    vendor: Vendor;
    @ViewChild('vendorGrid', { static: true }) vendorGrid: TableComponent;
    vendorId = 0;
    inventoryVendorName = '';
    popup: any;
    isActiveVendors = true;
    @ViewChild('confirmVendors') private confirmVendors: ConfirmDeleteComponent;
    @ViewChild('popOverForVendor', { static: true }) popOverForVendor: TemplateRef<any>;
    @ViewChild('iconTemplate', { static: true }) private iconTemplate: any;
    @ViewChild('iconHeaderTemplate') private iconHeaderTemplate: any;
    // @ViewChild('vendorLogDateEditModal') public vendorLogDateEditModal: any;
    @ViewChild('phoneTemplate', { static: true }) private phoneTemplate: TemplateRef<any>;
    constructor(private vendorService: VendorService,
        private alertService: AlertsService,
        private spinnerService: SpinnerService,
        private authenticationService: AuthenticationService,
        private modalService: ModalService,
        protected applicationStateService: ApplicationStateService,
        protected route: ActivatedRoute,
        private router: Router,
        private printService: PrintTableService) {
        super(applicationStateService, route);
        const navigation = router.getCurrentNavigation();
        this.gridContext = navigation?.extras?.state?.GridContext ?? this.gridContext;
        this.isActiveVendors = navigation?.extras?.state?.Active ?? this.isActiveVendors;
    }

    ngOnInit() {
        this.configureGridColumns();
        this.reloadVendors();
        this.vendorGrid.context = this.gridContext;
    }
    private configureGridColumns() {

        const editColumn = new EditColumn({
            Clicked: (vendor) => {
                this.editVendors(vendor.id);
            },
            Width: '55px',
            AddIcon: new LinkColumn({
                Clicked: () => {
                    this.editVendors(0);
                },
                ToolTip: 'New'
            })
        });

        const deleteColumn = new DeleteColumn({
            Clicked: (vendor) => {
                this.deleteVendors(vendor);
            },
            Width: '68px',
            PrintIcon: new LinkColumn({
                Clicked: () => {
                    this.printDiv();
                },
                ToolTip: 'Print'
            })
        });
        const popoverIcon = new LinkTemplateColumn({
            CellClass: 'cell-link greyicon', itemTemplate: this.popOverForVendor, Width: '0px'
        });

        const iconColumn = new TemplateColumn({
            headerTemplate: this.iconHeaderTemplate,
            itemTemplate: this.iconTemplate,
            TextAlign: TextAlign.Center,
            Width: '7%',
            Field: 'active',
            IsSortable: true,
            HeaderText: 'Active'
        });

        const phoneColumn = new TemplateColumn({
            itemTemplate: this.phoneTemplate,
            Width: '15%',
            Field: 'phone',
            IsSortable: true,
            HeaderText: 'Phone No'
        });

        this.vendorColumns = [
            new GridColumn({ HeaderText: 'Vendor', Field: 'name', IsSortable: true, Width: '35%' }),
            phoneColumn,
            new GridColumn({ HeaderText: 'Vendor Contact', Field: 'contact', IsSortable: true, Width: '25%' }),
            new GridColumn({ HeaderText: 'Account No', Field: 'account_no', IsSortable: true, Width: '15%' }),

        ] as Array<GridColumn>;

        this.vendorColumns.push(iconColumn);

        this.printVendorColumns = [...this.vendorColumns];

        if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
            this.vendorColumns.push(editColumn);
            this.vendorColumns.push(deleteColumn);
            this.vendorColumns.push(popoverIcon);
        }
    }
    private reloadVendors() {
        this.spinnerService.show();
        this.vendorService.getVendorList()
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (vendors: Vendor[]) => {
                    this.vendorsSearchResult = (vendors === null ? [] : vendors);
                    this.reload();
                }, error: this.alertService.showApiError
            });
    }
    public reload() {
        if (this.isActiveVendors) {
            this.vendors = this.vendorsSearchResult.filter(v => v.active);
        } else {
            this.vendors = this.vendorsSearchResult.filter(v => !v.active);
        }
    }
    private printDiv() {
        this.printService.printEmitter.next({ gridColumns: this.printVendorColumns, gridData: this.vendors });
    }
    private editVendors(id: number) {
        this.router.navigate([id], { relativeTo: this.route, state: { Active: this.isActiveVendors } });
    }
    private deleteVendors(vendor: Vendor) {
        this.vendorId = vendor.id;
        this.confirmVendors.Show(StringUtils.format(Messages.ConfirmDeleteVendor, { 'vendor': vendor.name }));
    }
    onConfirmAcknowledge() {
        this.spinnerService.show();
        this.vendorService.deleteInventoryVendor(this.vendorId)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: () => {
                    this.alertService.renderSuccessMessage(Messages.VendorDeleted);
                    this.reloadVendors();
                    this.vendorId = 0;
                }, error: this.alertService.showApiError
            });
    }
    openNextInventoryLogDate(vendor: Vendor) {
        this.inventoryVendorName = vendor.name;
        this.vendorId = vendor.id;
        const modalRef = this.modalService.getModalWrapper(InventoryLogDueDateOverrideEditComponent);

        const modal = modalRef.show({
            'backdrop': 'static',
            'class': 'vertical-center',
            keyboard: false,
            initialState: {
                inventoryVendorId: this.vendorId,
                heading: 'Vendor: ' + this.inventoryVendorName
            }
        });
        modal.close.subscribe({
            next: (res) => {
                if (res?.reload) {
                    this.resetVendorData();
                    this.alertService.renderSuccessMessage(Messages.NextInventoryDueDateOverrideEditSuccess);
                }
            }
        });
        this.popup.hide();
    }
    onLinkPopOver(vendor: Vendor, popup: any) {
        this.vendor = vendor;
        this.popup = popup;
    }

    private resetVendorData() {
        this.inventoryVendorName = '';
        this.vendorId = 0;
    }

    openInventoryVendorProfile(vendor: Vendor) {
        this.router.navigate([vendor.id, 'profiles'], { relativeTo: this.route, state: { vendorName: vendor.name, GridContext: this.gridContext, Active: this.isActiveVendors } });
    }

    close() {
        this.router.navigate(['../'], { relativeTo: this.route });
    }
}
