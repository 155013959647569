<div class="m-0-auto width-98">
    <!-- log-inventory-page -->
    <pos-edit-wrapper (close)="onCancel()" [isPopup]="false" class="inventory-receiving">
        <div class="col-12 p-0 ps-pix-15 mb-pix-7 row mx-0">
            <pos-column lg="3" md="4" sm="6" xs="12" class="p-0">
                <h3 class="page-content__header">
                    Edit Inventory Received
                </h3>
            </pos-column>
            <pos-column lg="9" md="12" sm="12" xs="12" class="p-0 pt-pix-5 row mx-0">
                <div class="col-1 col-70"><label class="edit-form__section__label">Location</label>
                </div>
                <div class="col-lg-4 col-md-5 col-sm-4 col-8 mb-xs-10">
                    <pos-form-field inputStyle="col-12" [appendPaddingTop]="false">
                        <ng-select posInput [items]="inventoryLocations" bindLabel="Name" bindValue="Id"
                            placeholder="Select Location" [(ngModel)]="selectedLocation" appendTo="body"
                            name="selectedLocation" [clearable]="false" [clearOnBackspace]="false"
                            (change)="filterInventoryReceived()">
                        </ng-select>
                    </pos-form-field>
                </div>
                <div class="col-sm-6 col-12 p-0">
                    <label class="edit-form__section__label alert alert-warning py-pix-6 mb-0" *ngIf="selectedLocation != 0">
                        You are editing inventory received at <b> {{selectedLocationName}} </b> location
                    </label>
                </div>
            </pos-column>
        </div>
        <div class="col-12 form-group item-separator border-top row mx-0 ps-pix-10 py-pix-5">
            <div class="col-md-1 col-1 col-150 p-pix-10">
                <label></label>
                <div>
                    <button class="btn btn-primary white width-full pos-button" (click)="previousDay()" type="button">
                        <pos-icon [icon]="icons.backward"></pos-icon>
                        Previous
                    </button>
                </div>
            </div>
            <div class="col-md-2 col-12 p-pix-5 col-230">
                <label>Start Date</label>
                <p class="input-group">
                    <p-calendar #effectiveDate="ngModel" [readonlyInput]="true" [dateFormat]="dateFormat"
                        [inputStyle]="{'width':'unset','display':'unset'}" inputStyleClass="form-control"
                        [monthNavigator]=true [showIcon]="true" name="effectiveDate" appendTo="body"
                        [(ngModel)]="startDate" (onSelect)="searchInventoryReceived()" [showIcon]="true">
                    </p-calendar>
                </p>
            </div>
            <div class="col-md-2 col-12 p-pix-5 col-230">
                <label>End Date</label>
                <p class="input-group">
                    <p-calendar #effectiveDate="ngModel" [readonlyInput]="true" [dateFormat]="dateFormat"
                        [inputStyle]="{'width':'unset','display':'unset'}" inputStyleClass="form-control"
                        [monthNavigator]=true [showIcon]="true" name="effectiveDate" appendTo="body"
                        [(ngModel)]="endDate" (onSelect)="searchInventoryReceived()" [showIcon]="true">
                    </p-calendar>
                </p>
            </div>
            <div class="col-md-1 col-1 col-150 p-pix-10">
                <label></label>
                <div>
                    <button class="btn btn-primary white width-full pos-button" (click)="nextDay()" type="button">
                        Next
                        <pos-icon [icon]="icons.forward"></pos-icon>
                    </button>
                </div>
            </div>
            <div class="col-xl-3 col-md-4 col-sm-10 col-12 p-pix-5 p-pix-lg-10">
                <ng-select [items]="inventoryProducts" bindLabel="Name" bindValue="Id" name="product"
                    [(ngModel)]="inventoryProductId" placeholder="Select product" class="mt-pix-0 mt-pix-lg-20">
                </ng-select>
            </div>
            <div class="col-1 p-0 pt-pix-0 pt-pix-lg-5 ps-pix-5 ps-pix-md-0">
                <div class="mt-pix-0 mt-pix-lg-20">
                    <button class="btn btn-lg btn-warning white pos-button" (click)="searchInventoryReceived()"
                        type="button">Search</button>
                </div>
            </div>

        </div>
        <!-- inventory-block-->
        <form #inventoryReceivingEditForm="ngForm" autocomplete="off" (submit)="updateInventoryReceived()">
            <div class="col-12 pos-page-body inner_remaining inventory-block__items">
                <div class="col-12 row mx-0" *ngFor="let item of inventoryReceivedFinalList; index as i">
                    <div class="col-12 inventory-block__items__title item-separator" *ngIf="item.ReceivedDateList?.length">
                        <label>{{item.ReceivedDate | tark_date}}</label>
                    </div>
                    <ng-container *ngFor="let product of item.ReceivedDateList; index as j">
                        <div class="col-md-6 col-12 row mx-0 p-0 inventory-block__items__bottom-line item-vertical-center">
                            <div class="col-1">
                                <p-checkbox [(ngModel)]="product.IsUpdated" binary="true" name="isUpdated_{{i}}_{{j}}">
                                </p-checkbox>
                            </div>
                            <div class="col-md-4 col-sm-5 col-11 pe-0">
                                {{product.ProductName}} <br />
                                <div> {{product.Barcode ? '('+product.Barcode+')':' '}}</div>
                                <div> {{product.ReceivedDate | tark_date_time}}</div>
                            </div>
                            <div class="col-md-6 col-sm-5 col-9 item-vertical-center">
                                <div class="col-lg-2 col-md-2 col-sm-6 col-12 p-0 col-100">
                                    <input class="form-control" id="{{'txtQty'+product.Id}}"
                                        [(ngModel)]="product.NewQty" name="NewQty_{{i}}_{{j}}"
                                        tooltip="{{'Value should be in multiple of ' + product.UnitQtyIncrement}}"
                                        (input)="orderReceivedQuantityChanged(product, inputTooltip)" type="number" 
                                        placement="bottom" #inputTooltip="bs-tooltip" triggers=""/>
                                    <!-- inventory-block__items__qty -->
                                </div>
                                <div
                                    class="col-lg-10 col-md-10 col-sm-6 col-12 p-0 item-vertical-center description">
                                    <!-- inventory-block__items__description -->
                                    {{product.Qty }}
                                    <!-- TODO | number:2 -->
                                    <span class="description--detail">
                                        <!-- inventory-block__items__description--detail -->
                                        {{ product.Qty ? Pluralize(product.Unit, product.Qty) :
                                        product.Unit }}
                                    </span>
                                </div>
                            </div>
                            <div class="col-1 item-vertical-center">
                                <a class="pull-right" (click)="confirmDeleteInventory(product.Id)" title="Delete">
                                    <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
                                </a>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="col-12 action-button-container page-footer">
                <!-- inventory-footer -->
                <button class="btn btn-lg btn-primary btn-save pos-button" type="submit">Update
                    Inventory
                </button>
                <button class="btn btn-lg btn-secondary btn-cancel pos-button" (click)="onCancel();" type="button">Cancel
                </button>
            </div>
        </form>
    </pos-edit-wrapper>
</div>
