<pos-edit-wrapper heading="Amount" (close)="closeDrawerCheckpoint()">
    <form #drawerCheckPointForm="ngForm" class="edit-form">
        <div class="col-12 pos-modal-body mt-pix-15 screen-scroll">
            <pos-row class="mx-0">
                <pos-column lg="6" sm="7" xs="12" class="ps-0 border-right">
                    <div class="row mx-0 pt-pix-5 pb-pix-5 border-bottom"
                        [ngClass]="{'bg-color-grey-90': selectedPrice === checkPointPrice.value.Name }"
                        (click)="selectedPrice = checkPointPrice.value.Name"
                        *ngFor="let checkPointPrice of drawerCheckPointPrice | keyvalue: originalOrder">
                        <div class="col-2 pe-0 label-section">
                            {{ checkPointPrice.value.Name }}
                        </div>
                        <div class="col-1 label-section">x</div>
                        <div class="col-2 p-0 text-center">
                            <button mat-mini-fab class="btn-mat-mini-fab" tabindex="-1"
                                (click)="decreasePrice(checkPointPrice.value.Name)">
                                <pos-icon [icon]="icons.minus"></pos-icon>
                            </button>
                        </div>
                        <div class="col-2 p-0 pt-pix-3">
                            <input type="number" class="form-control text-align-right"
                                (focus)="selectedPrice = checkPointPrice.value.Name"
                                [(ngModel)]="checkPointPrice.value.Value"
                                (ngModelChange)="calculatePriceClosingDrawerOnKeyPress()"
                                name="price_{{checkPointPrice.value.Name}}" />
                        </div>
                        <div class="col-2 p-0 text-center">
                            <button mat-mini-fab class="btn-mat-mini-fab" tabindex="-1"
                                (click)="increasePrice(checkPointPrice.value.Name)">
                                <pos-icon [icon]="icons.drawerCheckPointPlus"></pos-icon>
                            </button>
                        </div>
                        <div class="col-1 label-section">=</div>
                        <div class="col-2 ps-0 text-align-right">
                            <label class="label-section">{{ checkPointPrice.value.Value *
                                checkPointPrice.value.CurrencyInDollar | tark_currency }}</label>
                        </div>
                    </div>
                    <div class="col-12 font-16 bold mt-pix-5 mb-pix-5 ps-pix-5">
                        Total: <span class="float-end"> {{ totalPrice | tark_currency }} </span>
                    </div>
                </pos-column>
                <pos-column lg="6" sm="5" xs="12" class="pe-0">
                    <div class="col-12 p-0 pt-pix-5 font-16">
                        <div class="row mx-0 mb-pix-3">
                            <div class="display-flex col-6 p-0">
                                <div class="col-120 col-lg-5 col-3 p-0">Expected Total:</div>
                                <field-info [info]="fieldInfoMessages.CashDrawerExpectedTotal">
                                </field-info>
                            </div>
                            <div class="col-6 p-0 float-end text-align-right">
                                {{ ( drawerAmountModel.CashAmount + registerAmountInDrawer ) | tark_currency}}</div>
                        </div>
                        <div class="mb-pix-3 col-12 bg-light-grey">Counted Total:
                            <span class="float-end">{{ totalPrice | tark_currency }}</span>
                        </div>
                        <div class="mb-pix-5">Discrepancy:
                            <span class="float-end">{{ ( drawerAmountModel.CashAmount + registerAmountInDrawer -
                                totalPrice
                                ) | tark_currency }}</span>
                        </div>
                    </div>
                    <div class="col-12 p-0">
                        <hr>
                    </div>
                    <pos-numpad-buttons (calculatePrice)="calculatePriceClosingDrawer($event.value)"
                        (clear)="clearPrice()" (close)="enterDollarAmountDuringClosing()"
                        [isShowSubmitButton]="false"></pos-numpad-buttons>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer mti-0">
            <button class="btn btn-primary btn-lg btn-save pos-button"
                (click)="enterDollarAmountDuringClosing()">Submit</button>
            <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button"
                (click)="closeDrawerCheckpoint()">Cancel</button>
        </div>
        <div class="clearfix"></div>
    </form>
</pos-edit-wrapper>
