import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseCrudService, RuntimeConstants } from 'src/app/shared';
import { ActiveReport } from '../interfaces/active-report';
import { ParameterForReport } from '../interfaces/parameter-for-report';
import { ReportDetails } from '../interfaces/report-details';
import { ReportParameter } from '../interfaces/report-parameter';
import { ReportSchedule } from '../interfaces/report-schedule';
import { ReportSubscription } from '../interfaces/report-subscription';
import { ReportSubscriptionParameter } from '../interfaces/report-subscription-parameter';

@Injectable({
  providedIn: 'root'
})
export class ReportService extends BaseCrudService<ReportSchedule> {

  reportApiBasePath = `${RuntimeConstants.API_PATH}report`;

  constructor(protected httpService: HttpClient) {
    super('report', httpService);
  }

  newParameterForReport() {
    const param: ParameterForReport = {
      parameters: [],
      spName: '',
      PageNo: 1,
      PageSize: null,
      ReportName: '',
      IsPaged: false,
      ReportId: null,
      QueryType: ''
    };
    return param;
  }

  newReportDetails() {
    const reportData: ReportDetails = {
      ReportConfiguration: null,
      Data: [],
      ReportSummary: []
    };
    return reportData;
  }

  newActiveReport() {
    const activeReport: ActiveReport = {
      Name: '',
      Id: null,
      IsPaged: false,
      QueryType: ''
    };
    return activeReport;
  }

  newReportSubscriptionParameter() {
    const reportData: ReportSubscriptionParameter = {
      ParameterRules: [],
      ReportSubscriptionSchedules: [],
      SpName: '',
      ReportId: null,
      UserId: null,
      SubscriptionId: 0,
      SendEmptyResults: false,
      IsActive: true
    };
    return reportData;
  }

  newReportSchedule() {
    const reportSchedule: ReportSchedule = {
      Id: 0,
      Parameters: [],
      SubscriptionId: 0,
      DayOfWeek: 'Monday',
      TimeString: '00:00',
      Index: 0,
      Schedule: '0 0/1 * * * ? *',
      EmailSubject: null
    };
    return reportSchedule;
  }

  getReports() {
    return this.httpService.get(`${this.reportApiBasePath}`);
  }

  getReportParameterDetails(reportId: number) {
    return this.httpService.get(`${this.reportApiBasePath}/report-parameters/${reportId}`);
  }

  getDropDownListValue(param: ReportParameter) {
    return this.httpService.post(`${this.reportApiBasePath}/dropdown-values`, param);
  }

  getReportData(reportId, reportParam: ParameterForReport) {
    return this.httpService.post(`${this.reportApiBasePath}/report-data/${reportId}`, reportParam);
  }

  getReportParameterRules(reportId: number) {
    return this.httpService.get(`${this.reportApiBasePath}/report-parameter-rules/${reportId}`);
  }

  saveReportSubscription(reportSubscription: ReportSubscriptionParameter) {
    return this.httpService.post(`${this.reportApiBasePath}/save-subscription`, reportSubscription);
  }

  copyReportSubscriptions(reportSubscriptions: Array<ReportSubscription>, userId: number) {
    return this.httpService.post(`${this.reportApiBasePath}/copy-subscription/${userId}`, reportSubscriptions);
  }

  getReportSubscriptionParameters(reportSubscription: ReportSubscriptionParameter) {
    return this.httpService.post(`${this.reportApiBasePath}/subscription-parameters`, reportSubscription);
  }

  getReportDataForSummationColumn(reportId) {
    return this.httpService.get(`${this.reportApiBasePath}/report-summation-column/${reportId}`);
  }

  getReportSubscription(userId: number): Observable<Array<ReportSubscription>> {
    return this.httpService.get<Array<ReportSubscription>>(`${this.reportApiBasePath}/user-report-subscriptions/` + userId);
  }

  getReportSubscriptionSchedules(userId: number) {
    return this.httpService.get<Array<ReportSubscription>>(`${this.reportApiBasePath}/report/user/${userId}/subscription/schedules`);
  }

  updateReportSubscriptionActivation(reportSubscriptionId: number, isActive: boolean) {
    return this.httpService.post(`${this.reportApiBasePath}/update-report-subscription-activation/${reportSubscriptionId}/${isActive}`,
      null);
  }

  deleteReportSubscription(reportSubscriptionId) {
    return this.httpService.delete(`${this.reportApiBasePath}/delete-subscription/` + reportSubscriptionId);
  }

  sendEmailToUser(reportId: number, params: Array<ReportParameter>) {
    return this.httpService.post(`${this.reportApiBasePath}/${reportId}/email`, params);
  }
}
