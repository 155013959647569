import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RuntimeConstants } from 'src/app/shared';
import { ScreenChoice } from '../interface/screen-choice';

@Injectable({
  providedIn: 'root'
})
export class EmbedScreenService {
  embeddedScreenApiSUrl = '';

  constructor(private httpService: HttpClient) {
    this.embeddedScreenApiSUrl = RuntimeConstants.API_PATH + 'screen-choice'
  }

  getScreenChoiceWithEmbededScreen(): Observable<ScreenChoice[]>{
    return this.httpService.get<ScreenChoice[]>(`${this.embeddedScreenApiSUrl}/embedded-screen`)
  }

  addEmbeddedScreen(embedScreenModel: { ScreeId: number; EmbeddedScreenId: number; }) {
    return this.httpService.post(`${this.embeddedScreenApiSUrl}/embedded-screen`, embedScreenModel)
  }

  deleteEmbeddedScreen(id: number): Observable<any> {
    return this.httpService.delete(`${this.embeddedScreenApiSUrl}/embedded-screen/${id}`);
  }
}
