<pos-edit-wrapper (close)="editCancel()"
  heading="{{ inventoryBinName ? 'Edit Inventory Bin: ' + inventoryBinName : 'Add Inventory Bin' }}">
  <form #formInventoryBinEdit="ngForm" (ngSubmit)="saveInventoryBin(formInventoryBinEdit.valid)"
    name="formInventoryBinEdit">
    <div class="col-12 container-fluid h-350">
      <ul class="nav nav-tabs pos-nav-tabs inner-tab">
        <li [ngClass]="{'active': selectedTab == tabList.Details }">
          <a (click)="selectedTab = tabList.Details" class="blue"
            [ngClass]="{'required':!formInventoryBinEdit.valid}">
            <pos-icon class="theme-separator pe-pix-5" [icon]="icons.infoCircle"></pos-icon> Info</a>
        </li>
        <li [ngClass]="{'active': selectedTab == tabList.Lights }"
          *ngIf="inventoryBinEditId > 0 && appConfig.HasLightingController">
          <a (click)="selectedTab = tabList.Lights" class="blue">
            <pos-icon class="theme-separator pe-pix-5" [icon]="icons.lightBulb"></pos-icon> Lights</a>
        </li>
      </ul>
      <div class="pos-tab-body overflow-auto-tabs">
        <div [hidden]="selectedTab != tabList.Details">
          <div class="pb-3 tab-top-border border-top-0  pt-pix-5">
            <pos-row>
              <pos-column>
                <pos-form-field label="Name" [validationMessages]="{required: 'Please enter inventory bin name.'}">
                  <input posInput class="form-control" #inventoryBinNameInput="ngModel" name="inventoryBinNameInput"
                    id="inventoryBinNameInput" [(ngModel)]="inventoryBinData.Bin" type="text" [required]="true" />
                </pos-form-field>
              </pos-column>
              <pos-column>
                <pos-form-field label="Color" [validationMessages]="{required: 'Please enter inventory bin color.'}"
                  inputStyle="col-12 pe-0">
                  <pos-row>
                    <pos-column class="col-200 pe-0 mb-1" xs="5">
                      <pos-color-picker posInput [required]="true" [(ngModel)]="inventoryBinData.Color"
                        name="inventoryBinColor" #inventoryBinColor="ngModel"></pos-color-picker>
                    </pos-column>
                    <pos-column xs="5" class="col-130 mb-1 pe-0"><button tooltip title="Apply to all" type="button"
                        class="btn btn-primary normal pos-button white" (click)="updateInventoryBinColor()">
                        <span>
                          <pos-icon class="theme-font-color-separator" [icon]="icons.clone"></pos-icon>
                        </span><span> Apply To All</span></button></pos-column>
                  </pos-row>
                </pos-form-field>
              </pos-column>
              <pos-column>
                <pos-form-field label="Description">
                  <input posInput class="form-control" [(ngModel)]="inventoryBinData.Description"
                    #InventoryBinDescription="ngModel" name="InventoryBinDescription" type="text" />
                </pos-form-field>
              </pos-column>
            </pos-row>
          </div>
        </div>
        <div [hidden]="selectedTab != tabList.Lights">
          <div class="tab-top-border border-top-0">
            <pos-inventory-zone-bin-light [inventoryBinId]="inventoryBinEditId">
            </pos-inventory-zone-bin-light>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 action-button-container popup-footer">
      <button type="button" class="bg-theme-separator btn-lg btn normal white-hover float-start  ms-pix-10 pos-button"
        (click)="flash()">Flash</button>
      <button class="btn-primary btn-lg btn btn-save pos-button">Save</button>
      <button class="btn-secondary btn-lg btn btn-cancel-modal pos-button" type="button" (click)="editCancel()">
        Cancel
      </button>
    </div>
  </form>
</pos-edit-wrapper>
