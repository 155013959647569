import { Component, OnInit } from '@angular/core';
import { truncate } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ModalComponent } from 'src/app/shared/components/modal/modal-component';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { DeliverWorkflowState } from '../../interfaces/delivery-workflow-state';
import { OrderProductTransition } from '../../interfaces/order-product-transition';
import { OrderProductWorkflow } from '../../interfaces/order-product-workflow';
import { OrderProductWorkflowService } from '../../service/order-product-workflow.service';
import { chevronCircleRight, checkCirclePlain, circlePlain, fadeCircle } from 'src/app/shared/components/icon/icons';
import { OrderShippingAddress } from '../../interfaces/order-shipping-address';

@Component({
  selector: 'pos-online-order-workflow',
  templateUrl: './online-order-workflow.component.html',
  styleUrls: ['./online-order-workflow.component.scss']
})
export class OnlineOrderWorkflowComponent extends ModalComponent implements OnInit {

  activeIndex = 1;
  steps: Array<DeliverWorkflowState> = [];
  orderType = '';
  nextStep = '';
  isLastStep = false;
  orderProductWorkflow: OrderProductWorkflow;
  comments: Array<string> = [];
  workflowCompleted = true;
  orderProductTransitions: Array<OrderProductTransition> = [];
  icons = {
    chevronCircleRight,
    checkCirclePlain,
    circlePlain,
    fadeCircle
  };
  orderShippingAddress: OrderShippingAddress;

  constructor(modalRef: BsModalRef,
    protected orderProductWorkflowService: OrderProductWorkflowService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
  ) {
    super(modalRef);
  }

  ngOnInit(): void {
    const orderProductComponents = this.orderProductWorkflow?.OrderProduct?.OrderProductComponents;
    orderProductComponents?.forEach(x => x.IsComment ? this.comments.push(x.ButtonText) : '');
    this.loadData();
  }

  loadData() {
    const observable: Array<Observable<any>> = [];
    this.spinnerService.show();
    observable.push(this.orderProductWorkflowService.getOrderProductTransitionState(this.orderProductWorkflow.WorkflowIdentifier));
    observable.push(this.orderProductWorkflowService.getOrderProductTransitionHistory(this.orderProductWorkflow.OrderProductId));
    observable.push(this.orderProductWorkflowService.getOrderShippingAddress(this.orderProductWorkflow.OrderProduct.OrderId));
    forkJoin(observable).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (responses: [Array<DeliverWorkflowState>, Array<OrderProductTransition>, OrderShippingAddress]) => {
        this.steps = [...responses[0]];
        this.orderProductTransitions = [...responses[1]];
        this.orderShippingAddress = responses[2] ? responses[2] : null;
        this.setOrderProductTransition();
      },
      error: this.alertService.showApiError
    });
  }

  setOrderProductTransition() {
    this.orderProductTransitions.map((data) => {
      data.UserName = (data?.User?.FirstName && data?.User?.LastName) ? (data.User.FirstName + ' ' + data.User.LastName) : '';
    });
    this.steps.forEach((data, index) => {
      if (!this.orderProductTransitions.find(x => x.State === data.Name)) {
        this.workflowCompleted = false;
        this.orderProductTransitions.push(this.orderProductWorkflowService.getNewOrderProductTransition(this.orderProductWorkflow.Id, data.Name));
        if (!this.nextStep) {
          this.isLastStep = this.steps.length - 1 === index;
          this.nextStep = data.Name;
        }
      }
    });
  }

  confirmNextStep() {
    this.spinnerService.show();
    this.orderProductWorkflowService.updateOrderProductToNextStage(this.orderProductWorkflow).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (response) => {
        this.saveSuccess();
      }, error: this.alertService.showApiError
    });
  }

  saveSuccess() {
    this.alertService.renderSuccessMessage(Messages.OrderProductWorkflowUpdatedSaveSuccess);
    this.closeModel(true);
  }

  closeModel(reload: boolean = false) {
    this.hide({ shouldReload: reload });
  }

}
