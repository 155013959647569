import { MessagePayload } from './message-payload';

export class UserClockoutMessagePayload extends MessagePayload {

    public UserId: number;

    constructor(userId: number) {
        super();
        this.UserId = userId;
    }
}
