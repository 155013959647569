import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'pos-settle-payments',
  templateUrl: './settle-payments.component.html',
  styleUrls: ['./settle-payments.component.scss', '../../../../../../styles/pages/design/settle.scss']
})
export class SettlePaymentsComponent implements OnInit {

  @Input() paymentsOnOrder: Array<any> = [];
  constructor() { }

  ngOnInit() {
  }

}
