import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'customFilter'})
export class CustomFilterComponent implements PipeTransform {
  transform(value: any[], searchText: string): any[] {
    if(!value || !value.length) return [];
    if(!searchText) return value;    
    var keys = Object.keys(value[0]);
    return value.filter(val => keys.some(key => {
      const currentValue = val[key];
      if (currentValue) {
        return String(currentValue).toLowerCase().includes(searchText.toLowerCase());
      }
      return false;
    }));
  }

}
