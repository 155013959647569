<pos-list-wrapper [headingIcon]="icons.inventoryRawIngredient" heading="Raw Ingredients" (close)="close()">
  <div class="row">
    <div class="col-12">
      <ng-table #rawIngredientGrid [columns]="rawIngredientsColumns" [exportFilename]="'raw-ingredients'" [data]="rawIngredientList">
      </ng-table>
    </div>
  </div>
</pos-list-wrapper>

<ng-template #ingredientPopoverTemplate>
    <ul>
      <li class="listbullet pointer">
        <a class="grey" (click)="inventoryProductsIngredient(selectedRawIngredient.Id)">
          <pos-icon [icon]="icons.rawIngredientInventoryProductStore" class="pe-pix-3" style="margin-left: -5px;" ></pos-icon>
          Inventory Products
        </a>
      </li>
      <li class="listbullet pointer">
        <a class="grey" (click)="salesProductsIngredient(selectedRawIngredient.Id)">
          <pos-icon [icon]="icons.rawIngredientSalesProduct" class="pe-pix-8"></pos-icon>Sales Products
        </a>
      </li>
      <li class="listbullet pointer">
        <a class="grey" (click)="mergeProductIngredient(selectedRawIngredient)">
          <pos-icon [icon]="icons.codeBranch" class="pe-pix-8"></pos-icon>Merge Raw Ingredients
        </a>
      </li>
    </ul>
</ng-template>

<pos-confirm-delete [message]="message" (onConfirmAcknowledge)="onConfirmAcknowledge()" #confirmButtonDelete>
</pos-confirm-delete>

<ng-template #popoverforingredient let-data="rowData">
  <span class="text-center mt-pix-n4" style="overflow-wrap: break-word;">
    <a class="action-btn grey pointer" #pop="bs-popover" placement="left" (click)="onLinkPopOver(data,pop)"
      [outsideClick]="true" [popover]="ingredientPopoverTemplate" triggers="click">
      <pos-action-icon [icon]="icons.barsWhite"></pos-action-icon>
    </a>
  </span>
</ng-template>

<pos-print-table [hidden]="true"></pos-print-table>
