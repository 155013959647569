import { Injectable, EventEmitter } from '@angular/core';
import { TimerOption, QueuedProduct } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class TimerBroadcastingService {
  productRequest = new EventEmitter<any>();
  constructor() { }
  startTimerRequestForProduct(data) {
    this.productRequest.emit(data);
  }
}
