<pos-edit-wrapper heading="Please Verify Order" (close)="closeModal()">
    <div class="col-12 pos-modal-body row mx-0 screen-scroll" [style.height]="confirmOrderHeight + 'px'">
        <div class="row flex-column g-0">
            <div class="order-item-border col-12 ps-0 pe-0" *ngIf="orderData.OrderAttributes.PagerNumber">
                <strong>Pager Number: {{orderData.OrderAttributes.PagerNumber}}</strong>
            </div>
            <div class="order-item-border col-12 ps-0 pe-0" *ngIf="orderData.DiscountPct">
                <strong>Discount {{orderData.DiscountPct * -1}}%</strong>
            </div>
            <div class="order-item-border col-12 ps-0 pe-0" *ngIf="orderData.MarkupPct">
                <strong>Markup {{orderData.MarkupPct}}%</strong>
            </div>

            <div class="order-item-border col-12 ps-0 pe-0" *ngIf="orderData.OrderAttributes.IsCallIn">
                <strong>Call In: {{ orderData.OrderAttributes.CallInOrderTime }}</strong>
            </div>

            <div class="order-item-border col-12 ps-0 pe-0" *ngIf="orderData.OrderAttributes.Name">
                <strong>Name: {{ orderData.OrderAttributes.Name }}</strong>
            </div>

            <div class="order-item-border col-12 ps-0 pe-0" *ngIf="orderData.OrderAttributes.PhoneNumber">
                <strong>Phone: {{ orderData.OrderAttributes.PhoneNumber | phoneMaskPipe }}</strong>
            </div>

            <div class="order-item-border col-12 ps-0 pe-0" *ngIf="orderData.DeliveryFee">
                <strong>Delivery Fee</strong>
            </div>

            <div *ngFor="let item of seats | keyvalue">
                <div class="order-item-border col-12 row mx-0 order-page__block" *ngFor="let product of item.value">
                    <div>
                        <strong *ngIf="!product.IsDeliveryFee">
                            {{product.ReceiptText}}
                            <span *ngIf="product.Qty && product.OrderItemType ==  orderItemTypes.Product">
                                ({{product.Qty}})
                            </span>
                        </strong>

                        <div class="order-page__block__comment" *ngFor="let component of product.OrderProductComponents"
                            [hidden]="product.OrderItemType != orderItemTypes.Product"
                        >
                            <div class="order-page__block__comment__text ps-pix-5"
                            *ngIf="component.ReceiptText.indexOf('-') == 0">
                            No {{component.ReceiptText | noMinusSign }}
                            </div>
                            <div class="order-page__block__comment__text ps-pix-5"
                                *ngIf="component.ReceiptText.indexOf('-') != 0">
                                {{component.ReceiptText}}
                            </div>
                        </div>
                    </div>
                    <!-- add price here 
                    <div class="col-sm-5 col-4 p-0 pe-pix-10">
                        <span class="product-price" style="padding-top: 0px">
                            {{product.Price*product.Qty | tark_currency}}
                        </span>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 action-button-container popup-footer text-align-center d-flex justify-content-center">
        <div>
            <button class="btn btn-lg btn-success white normal pos-button extra-lg-buttons" (click)="closeModal(true)" type="button">
              <pos-icon [icon]="icons.checkCircleSolid"></pos-icon> It's Correct
            </button>
        </div>
        <div class="ms-4">
            <button class="btn btn-lg btn-danger pos-button extra-lg-buttons" (click)="closeModal()" type="button">
              <pos-icon [icon]="icons.exclamationCircle"></pos-icon> Go Back
            </button>
        </div>
    </div>
</pos-edit-wrapper>
