<pos-list-wrapper [headingIcon]="icons.terminalTv" heading="Terminal Configuration List" (close)="close()">
  <div class="row justify-content-between align-items-end">
    <div class="col-sm-2 col-xs-12">
      <button class="btn btn-danger btn-lg normal pos-button text-nowrap" (click)="openGlobalLogoffModal()" type="submit"
        permissions='[{"Name":"{{permission.name}}", "Level":"{{permission.editLevel}}"}]'>
        Global Logoff</button>
    </div>
    <div class="col-sm-10 col-xs-12 row justify-content-end">
      <pos-form-field label="Status" class="col-xs-4 col-200" inputStyle="col-xs-12" [appendPaddingTop]="false">
        <ng-select posInput [items]="terminalStatus" bindLabel="Key" bindValue="Value" [(ngModel)]="filterStatus"
          (ngModelChange)="getTerminalsByStatus()" placeholder="Terminal Status"></ng-select>
      </pos-form-field>
      <pos-form-field label="Auto refresh Status" class="col-xs-4 col-200" inputStyle="col-xs-12"
        [appendPaddingTop]="false">
        <ng-select posInput [items]="statusRefreshIntervalList" bindLabel="key" bindValue="value"
          [(ngModel)]="statusRefreshIntervalValue" (ngModelChange)="statusRefreshIntervalChanged()"
          [clearable]="false"></ng-select>
      </pos-form-field>
    </div>
    <div class="col-12">
      <ng-table #terminalTable [columns]="terminalColumns" [data]="filteredTerminals"></ng-table>
      <ng-template let-data="rowData" #operationHeaderTemplate>
        <div class="text-center" [ngStyle]="{ 'width': operationColumnWidth}">
          <span permissions='[{"Name":"{{permission.name}}", "Level":"{{permission.editLevel}}"}]'>
            <span class="pe-pix-5" (click)="editItem(0)" tooltip title="Add" *ngIf="isLicenseAvailable">
              <pos-action-icon [icon]="icons.plusWhite" iconText="Add"></pos-action-icon>
            </span>
          </span>
          <pos-export-dropdown class="inline-block" (onPrint)="printData()" (onCSV)="exportCSV()">
          </pos-export-dropdown>
        </div>
      </ng-template>
      <ng-template let-data="rowData" #operationTemplate>
        <div permissions='[{"Name":"{{permission.name}}", "Level":"{{permission.editLevel}}"}]'>
          <span class="pe-pix-3" (click)="editItem(data.TerminalId, data)" tooltip title="Edit" scrollup>
            <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
          </span>
          <span (click)="deleteTerminal(data)" tooltip title="Delete" class="pe-pix-3">
            <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
          </span>
          <span (click)="refreshMenuDisplay(data)" tooltip title="Trigger Refresh" class="pe-pix-3">
            <pos-action-icon [icon]="icons.undoWhite"></pos-action-icon>
          </span>
          <span (click)="terminalConfig(data)" tooltip [title]="data.TerminalType" [ngClass]="(data.TerminalType === terminalTypes.PRODUCT_BUILD_QUEUE.Name
            || data.TerminalType === terminalTypes.MENU_EXPLORER.Name 
            || data.HasMappedHardwareTypes) ? '' : 'col-lg-1 col-30 d-inline-block'">
            <pos-action-icon [icon]="icons.cogWhite" class="vertical-mid" *ngIf="data.TerminalType === terminalTypes.PRODUCT_BUILD_QUEUE.Name ||
              data.TerminalType === terminalTypes.MENU_EXPLORER.Name || data.HasMappedHardwareTypes">
            </pos-action-icon>
          </span>
        </div>
      </ng-template>
      <ng-template let-data="rowData" #iconTemplate>
        <span *ngIf="data.Image && !data.Icon">
          <img class="terminal-icon-img" src="images/terminal/{{data.Image}}" />
        </span>
        <span *ngIf="!data.Image && data.Icon">
          <pos-icon class="font-10-3" [icons]="data.Icon"></pos-icon>
        </span>
      </ng-template>
      <ng-template let-data="rowData" #terminalStatusTemplate>
        <div class="pointer terminal-status-indicator" *ngIf="data.MonitorForConnection">
          <a [tooltip]="data.TerminalStatus" placement="top">
            <pos-icon *ngIf="getTerminalStatusIcon(data.TerminalStatus)"
              [icon]="getTerminalStatusIcon(data.TerminalStatus)"></pos-icon>
          </a>
        </div>
      </ng-template>
    </div>
  </div>
</pos-list-wrapper>
<pos-print-table [hidden]="true"></pos-print-table>
