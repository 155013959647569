import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SpinnerService, AlertsService, Messages, EventBroadcastingService, ModalService, InfoModalComponent, SalesSizeService, ConfirmDeleteComponent } from 'src/app/shared';
import { SalesProductsService } from '../../services';
import { deleteWhite } from 'src/app/shared/components/icon';
import { finalize } from 'rxjs/operators';
import * as _ from 'lodash';
import { GridColumn, TemplateColumn } from '@tarktech/tark-ng-utils';
import { Subscription } from 'rxjs';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { SalesProductSize } from '../../interface/sales-product-size';
import { ControlContainer, NgForm } from '@angular/forms';
@Component({
  selector: 'pos-sales-product-price',
  templateUrl: './sales-product-price.component.html',
  styleUrls: ['./sales-product-price.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class SalesProductPriceComponent implements OnInit {

  salesSizes: Array<SalesProductSize> = [];
  isSilentCall: boolean = false;

  @Input('salesCategoryId') salesCategoryId: number;
  @Input('productId') productId: number;
  @Input('salesProductPrices') salesProductPrices: Array<SalesProductSize> = [];
  @Output() salesProductPricesChange = new EventEmitter<Array<SalesProductSize>>();
  @Input('defaultPriceId') defaultPriceId: number;
  @Output('onDelete') onDelete = new EventEmitter<any>();
  sizeSubscription: Subscription;
  @ViewChild('nameTemplate', { static: true }) private nameTemplate: any;
  @ViewChild('priceTemplate', { static: true }) private priceTemplate: any;
  @ViewChild('priceMarginTemplate', { static: true }) private priceMarginTemplate: any;

  @ViewChild('deleteTemplate', { static: true }) private deleteTemplate: any;
  @ViewChild('confirmPriceDelete') private confirmPriceDelete: ConfirmDeleteComponent;
  deletePrice: SalesProductSize;
  salesSizeMappedError: string = '';
  salesProductPricesColumns: Array<GridColumn> = [];
  icons = {
    deleteWhite
  };
  constructor(
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private salesProductService: SalesProductsService,
    private salesSizesService: SalesSizeService,
    private eventBroadcastingService: EventBroadcastingService,
    private modalService: ModalService
  ) {
    this.salesSizeMappedError = Messages.SalesSizeMappedError;
  }

  ngOnDestroy() {
    this.sizeSubscription.unsubscribe();
  }

  ngOnInit() {
    this.configureGridColumn();
    this.sizeSubscription = this.eventBroadcastingService.sizeChanged.subscribe(
      (categoryId: number) => {
        this.reloadSizes(categoryId);
      }
    );
  }

  onChangePrice(salesPrice) {
    salesPrice.MarginPct = this.getMarginPct(salesPrice);
    if (this.salesProductPrices) {
      this.salesProductPricesChange.emit(this.salesProductPrices);
    }
  }

  configureGridColumn() {

    const nameColumn = new TemplateColumn({
      HeaderText: 'Size',
      itemTemplate: this.nameTemplate
    });

    const priceColumn = new TemplateColumn({
      HeaderText: 'Price',
      itemTemplate: this.priceTemplate
    });

    const priceMarginTemplateColumn = new TemplateColumn({
      HeaderText: 'Margin',
      itemTemplate: this.priceMarginTemplate
    });

    const deleteColumn = new TemplateColumn({
      HeaderText: '',
      itemTemplate: this.deleteTemplate,
      Width: '45px'
    });

    this.salesProductPricesColumns = [
      nameColumn,
      priceColumn,
      priceMarginTemplateColumn,
      deleteColumn
    ];
  }

  getProductSizeWithPrice() {
    if (!this.isSilentCall) {
      this.spinnerService.show();
      this.isSilentCall = !this.isSilentCall;
    }
    this.salesSizesService.getSalesProductSizes(this.salesCategoryId ? this.salesCategoryId : 0)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          if (res) {
            this.salesSizes = res;
            if (this.salesCategoryId) {
              this.getPrices();
            } else {
              this.salesProductPrices = [];
            }
          }
        }, error: this.alertService.showApiError
      });
  }

  getMarginPct(salesPrice) {
    return (100 * ((salesPrice.Price - salesPrice.Cost ?? 0) / (salesPrice.Price ?? 1)));
  }

  updateCoasts = (prices) => {
    _.forEach( this.salesProductPrices, (price) => {
      const newPrice = _.find(prices, (newPrice) => {
        return newPrice.SizeId === price.SizeId;
      });
      if(newPrice) {
        price.Cost = newPrice.Cost;
        price.MarginPct = this.getMarginPct(price);
      }
    });
  }

  getPrices() {
    this.spinnerService.show();
    this.salesProductService.getProductSizesWithPrice(this.productId ? this.productId : 0)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          this.salesProductPrices = this.salesSizes;
          if (res) {
            _.forEach(this.salesSizes, (size) => {
              const salesSize = _.find(res, (salesPrice: any) => {
                if (size.SizeId === salesPrice.SizeId) {
                  size.Id = salesPrice.Id;
                  size.Cost = salesPrice.Cost;
                  size.MarginPct = this.getMarginPct(salesPrice);
                  size.Price = salesPrice.Price.toFixed(2);
                  size.SalesProductId = salesPrice.SalesProductId;
                }
                return size.SizeId === salesPrice.SizeId;
              });
            });
          }
        }, error: this.alertService.showApiError
      });
  }

  confirmDeletePrice = (size) => {
    this.deletePrice = size;
    this.confirmPriceDelete.Show(Messages.ConfirmRemovePrice);
  }

  removeSalesPrice() {
    let consumption = {
      InventoryProductId: 0,
      SizeId: this.deletePrice.SizeId,
      IsHistory: false,
      SalesProductId: this.productId ? this.productId : -1
    };
    this.spinnerService.show();
    this.salesProductService.getProductsInventoryConsumption(consumption)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          if (res?.length) {
            const modalTimeout = setTimeout(() => {
              const modalRef = this.modalService.show(InfoModalComponent, {
                animated: false,
                class: 'vertical-center',
                initialState: {
                  message: StringUtils.format(Messages.ConfirmDeletePrice, { "size": this.deletePrice.Name }),
                  confirmButtonText: 'Yes',
                  rejectButtonText: 'No',
                  modalHeaderText: 'Confirm'
                }
              });

              modalRef.close.subscribe((res) => {
                if (res?.shouldConfirm) {
                  this.confirmRemoveSalesPrice(this.deletePrice);
                }
              });
              clearTimeout(modalTimeout);
            }, 200);
          } else {
            this.confirmRemoveSalesPrice(this.deletePrice);
          }
        }, error: this.alertService.showApiError
      });

  }
  confirmRemoveSalesPrice(product) {
    this.spinnerService.show();
    this.salesProductService.deletePrice(this.productId, product.Id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          this.getPrices();
          product.Price = null;
          this.alertService.renderSuccessMessage(Messages.PriceDeleteSuccess);
          this.onDelete.emit({ salesProductPrices: this.salesProductPrices });
        }, error: this.alertService.showApiError
      });
  }

  reloadSizes(categoryId: number) {
    this.salesCategoryId = categoryId;
    this.isSilentCall = true;
    this.getProductSizeWithPrice();
  }

}
