import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { find } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DomainConstants, FormUtilityService, ICloseable, ModalFormComponent, ModalService } from 'src/app/shared';
import { EventSubscriptionActionAttribute } from '../../interfaces/event-subscription-action-attribute';
import { RepeatActionConfigurationComponent } from '../repeat-action-configuration';

@Component({
  selector: 'pos-email-configuration',
  templateUrl: './email-configuration.component.html',
  styleUrls: ['./email-configuration.component.scss']
})
export class EmailConfigurationComponent extends ModalFormComponent implements OnInit, ICloseable {
  get getForm(): NgForm {
    return this.emailActivityEditForm;
  }
  @ViewChild('emailActionEditForm', { static: true }) emailActivityEditForm: NgForm;
  @ViewChild('RepeatActionConfiguration') repeatActionConfiguration: RepeatActionConfigurationComponent;
  emailRecipients = '';
  @Input() actionId = 0;
  @Input() eventSubscriptionActionAttributes: Array<EventSubscriptionActionAttribute> = [];
  @Input() eventAttributes;
  @Input() deliveryTypes = [];
  @Input() isRecoverableEvent;
  selectedDeliveryType;
  @Input() eventIdentifier: string;
  deliveryAttributes = DomainConstants.EventDeliveryAttributes;
  constructor(modalRef: BsModalRef, modalService: ModalService, protected formUtilityService: FormUtilityService) {
    super(modalRef, modalService, formUtilityService);
  }

  ngOnInit(): void {
    this.emailRecipients = find(this.eventSubscriptionActionAttributes,
      x => x.AttributeKey === this.deliveryAttributes.Email.Recipients)?.AttributeValue;
  }

  saveActivity = (isValid: boolean) => {
    if (isValid) {
      const actionAttributes = this.prepareEventActionAttributes();
      this.hide({ shouldReload: true, actionAttributes: actionAttributes });
    }
  }

  onClose = (shouldReload: boolean) => {
    this.hide({ shouldReload: shouldReload });
  }

  prepareEventActionAttributes = () => {
    const actionDeliveryAttributes: Array<EventSubscriptionActionAttribute> = [];
    this.addAttribute(this.deliveryAttributes.Email.Recipients, this.emailRecipients, actionDeliveryAttributes);
    if(this?.repeatActionConfiguration?.repeatConfig) { 
      this.addAttribute(this.deliveryAttributes.Email.Repeat, this?.repeatActionConfiguration?.isRepeat ? JSON.stringify(this?.repeatActionConfiguration?.repeatConfig) : "", actionDeliveryAttributes)
    }
    return actionDeliveryAttributes;
  }

  addAttribute = (key, value, array) => {
    const actionDeliveryAttribute: EventSubscriptionActionAttribute = {
      Id: this.getActionAttributeId(key),
      AttributeKey: key,
      AttributeValue: value,
      EventSubscriptionActionId: this.actionId
    };
    array.push(actionDeliveryAttribute);
  }

  changeSelectedDeliveryType = () => {
    this.hide({ shouldReload: false });
  }

  getActionAttributeId(key) {
    const attribute = find(this.eventSubscriptionActionAttributes, x => x.AttributeKey === key);
    return attribute ? attribute.Id : 0;
  }

}

