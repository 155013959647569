import { MessagePayload } from './message-payload';

export class RefreshMenuDisplayPayload extends MessagePayload {

    public MenuDisplayId?: number;

    constructor(menuDisplayId?: number) {
        super();
        this.MenuDisplayId = menuDisplayId;
    }
}
