<pos-edit-wrapper (close)="onCancel(false)" [isPopup]="isScroll" [headingIcon]="icons.inventoryProductsStore"
  heading="{{inventoryProductId ? 'Inventory Product: ' + (inventoryProductName ? inventoryProductName : inventoryProduct?.name) : 'Inventory Product'}}">
  <form #formInventoryProduct="ngForm" (ngSubmit)='saveProductDetails(formInventoryProduct.valid)'>
    <div *ngIf="isScroll" class="clearfix"></div>
    <div class="col-12">
      <div class="row g-0 mx-2 alert condensed inherit-position alert-success" id="divSalesProductSuccessErrorMsg"
        style="display: none;"></div>
    </div>
    <div class="col-12 container-fluid ddl-parent" [ngClass]="{'border-top': !isScroll }">
      <div class="vertical-tabs">
        <ul class="nav nav-tabs inner-tab pos-nav-tabs">
          <li [ngClass]="{'active': selectedTab == tabList.ProductsInfo }">
            <a (click)="selectedTab = tabList.ProductsInfo" class="blue" [ngClass]="{'required':productInfo.invalid}">
              <span>
                <pos-icon class="theme-separator pe-pix-5" [icon]="icons.productInformationInfoCircle"></pos-icon>
              </span>
              <span>Product Information</span></a>
          </li>
          <li [ngClass]="{'active': selectedTab == tabList.ProductSetup }">
            <a (click)="selectedTab = tabList.ProductSetup" class="blue" [ngClass]="{'required':productSetup.invalid}">
              <span class="pe-pix-5">
                <pos-icon class="theme-separator" [icon]="icons.inventorySetupBoxes"></pos-icon>
              </span> <span>Inventory Config</span> </a>
          </li>
          <li [ngClass]="{'active': selectedTab == tabList.ProductVendor }">
            <a (click)="selectedTab = tabList.ProductVendor" class="blue" [ngClass]="{'required':productVendor.invalid}">
              <span class="pe-pix-5">
                <pos-icon class="theme-separator" [icon]="icons.vendorDetailsTruck"></pos-icon>
              </span> <span>Vendor Details</span> </a>
          </li>
          <li [ngClass]="{'active': selectedTab == tabList.ProductWorkflow }">
            <a (click)="selectedTab = tabList.ProductWorkflow" class="blue"
              [ngClass]="{'required':productWorkflow.invalid}">
              <span class="pe-pix-5">
                <pos-icon class="theme-separator" [icon]="icons.workflowArrowCircleRight"></pos-icon>
              </span> <span>Workflow</span> </a>
          </li>
          <li [ngClass]="{'active': selectedTab == tabList.ProductBarcodes }">
            <a (click)="selectedTab = tabList.ProductBarcodes; barcodeTabClicked()" class="blue">
              <span class="pe-pix-5">
                <pos-icon class="theme-separator" [icon]="icons.barcode"></pos-icon>
              </span> <span>Barcodes</span> </a>
          </li>
          <li [ngClass]="{'active': selectedTab == tabList.ProductInventoryLocations }">
            <a (click)="selectedTab = tabList.ProductInventoryLocations" class="blue">
              <span class="pe-pix-5">
                <pos-icon class="theme-separator" [icon]="icons.inventoryLocationsArrow"></pos-icon>
              </span> <span>Inventory Locations</span> </a>
          </li>
          <li [ngClass]="{'active': selectedTab == tabList.ProductRawIngredients }" *ngIf="inventoryProduct.IsFood">
            <a (click)="selectedTab = tabList.ProductRawIngredients" class="blue">
              <span class="pe-pix-5">
                <pos-icon class="theme-separator" [icon]="icons.inventoryProductIngredientStore"></pos-icon>
              </span> <span>Ingredients</span> </a>
          </li>
          <li [ngClass]="{'active': selectedTab == tabList.ProductNutritionFacts }" *ngIf="inventoryProduct.IsFood">
            <a (click)="selectedTab = tabList.ProductNutritionFacts" class="blue">
              <span class="pe-pix-5">
                <pos-icon class="theme-separator" [icon]="icons.nutritionFacts"></pos-icon>
              </span> <span>Nutrition Facts</span> </a>
          </li>
          <li [ngClass]="{'active': selectedTab == tabList.ProductDietaryWarnings }" *ngIf="inventoryProduct.IsFood">
            <a (click)="selectedTab = tabList.ProductDietaryWarnings" class="blue">
              <span class="pe-pix-5">
                <pos-icon class="theme-separator" [icon]="icons.exclamationTriangle"></pos-icon>
              </span> <span>Dietary Warnings</span> </a>
          </li>
          <li [ngClass]="{'active': selectedTab == tabList.ProductAliases }">
            <a (click)="selectedTab = tabList.ProductAliases" class="blue">
              <span class="pe-pix-5">
                <pos-icon class="theme-separator" [icon]="icons.productSimplyBuilt"></pos-icon>
              </span> <span>Aliases</span> </a>
          </li>
        </ul>
      </div>
      <div class="overflow-auto-tabs screen-scroll" [ngStyle]="{'height': outerContainerHeight}">
        <div [hidden]="selectedTab != tabList.ProductsInfo" ngModelGroup="productInfo" #productInfo="ngModelGroup">
          <div
            class="row pos-tab-body mx-0 border-top-0 pt-pix-5 tab-top-border form-horizontal screen-scroll"
            [ngStyle]="{'max-height': innerContainerHeight}">

            <div class="row row-cols-md-2">
              <div>
                <pos-form-field label="Product Name" [validationMessages]="{required: 'Please enter product name.'}">
                  <input posInput name="productName" #productName="ngModel" type="text" class="form-control" id="productName"
                    [(ngModel)]="inventoryProduct.name" [required]="true" />
                </pos-form-field>
              </div>
            
              <div>
                <pos-form-field label="Category" [validationMessages]="{required: 'Please select category.'}">
                  <ng-select posInput appendTo="body" [items]="invSubCatDetailsList" bindLabel="InventoryCategoryName"
                    bindValue="id" placeholder="Select category" [(ngModel)]="inventoryProduct.subcategory_id"
                    name="selectedRestrictions" [required]="true" id="selectedRestrictions">
                  </ng-select>
                </pos-form-field>
              </div>
            
              <div>
                <pos-form-field label="Details" [fieldInfo]="fieldInfoMessages.Details">
                  <textarea posInput class="form-control" name="details" #description="ngModel"
                    [(ngModel)]="inventoryProduct.unit_details" style="height: 128px"></textarea>
                </pos-form-field>
              </div>
            
              <div>
                <pos-form-field label="Ingredient Instruction">
                  <textarea posInput class="form-control" name="ingredientInstruction" #description="ngModel"
                    [(ngModel)]="inventoryProduct.IngredientInstruction" style="height: 128px"></textarea>
                </pos-form-field>
              </div>
            
              <div>
                <pos-form-field inputStyle="col-lg-10 col-md-10 col-sm-10 col-10" label="Ingredient Image">
                  <div class="row">
                    <div class="col-12 col-lg-8">
                      <input posInput type="file" class="form-control" accept=".png,.jpg,.jpeg,.gif,.bmp,.webp" #image name="image"
                        id="image" (change)="handleFileInput($event.target.files)">
                    </div>
                
                    <div class="col-4" [hidden]="!showImageIcon">
                      <div>
                      <img src="{{imagePath}}/{{inventoryProduct.IngredientImage}}" alt="ingredient image" style="width: 50px"
                        #ingredientImage id="ingredientImage" />
                        <pos-icon [icon]="icons.times" class="pointer remove-icon-badge mt-pix-n20" (click)="removeIcon()"></pos-icon>
                      </div>
                
                    </div>
                  </div>
                </pos-form-field>
              </div>

            
              <div>
                <pos-form-field>
                  <p-checkbox posInput name="activeProduct" #activeProduct="ngModel" [(ngModel)]="inventoryProduct.active"
                    binary="true" label="Active Product">
                  </p-checkbox>
                </pos-form-field>
                <pos-form-field>
                  <p-checkbox posInput name="HumanConsumption" #HumanConsumption="ngModel"
                    [(ngModel)]="inventoryProduct.IsFood" binary="true" label="For Human Consumption">
                  </p-checkbox>
                </pos-form-field>
              </div>
            </div>

          </div>
        </div>
        <div [hidden]="selectedTab != tabList.ProductSetup" ngModelGroup="productSetup" #productSetup="ngModelGroup">
          <div class="row g-0 pos-tab-body pt-pix-5 border-top-0 tab-top-border form-horizontal screen-scroll"
            [ngStyle]="{'max-height': innerContainerHeight}">
            <div class="col-12">
              <div>
                <h3 class="setting-title">Unit and Pricing</h3>
              </div>

              <div class="row row-cols-md-2 ps-2">
                <div>
                  <pos-form-field label="Unit" [validationMessages]="{required: 'Please select unit.'}"
                    inputStyle="col-lg-4 col-md-4 col-sm-5 col-6">
                    <ng-select posInput appendTo="body" [items]="unitDetailsList" bindLabel="Name" bindValue="Id"
                      (change)="showUnitChangedWarning()" placeholder="Select unit" id="selectedUnit"
                      [(ngModel)]="inventoryProduct.unit_id" name="selectedUnit" [required]="true">
                    </ng-select>
                  </pos-form-field>
                </div>

                <div>
                  <pos-form-field label="Unit Qty as Ordered" [fieldInfo]="fieldInfoMessages.UnitQtyasOrdered"
                    [validationMessages]="{required: 'Please enter unit quantity.', range: 'Unit quantity must be between 0 to 9999.'}">

                    <div class="row row-cols-2">
                      <div>
                        <input posInput name="productUnitQty" #productUnitQty="ngModel" type="number" class="form-control"  posDecimal [validDecimal]="4"
                            [range]="[0.01, 9999]" [(ngModel)]="inventoryProduct.unit_qty" [required]="true" id="productUnitQty" />
                      </div>

                      <label class="label-section pt-pix-7 display-flex" *ngIf="inventoryProduct.unit_id">{{
                          inventoryProduct.unit_qty ? Pluralize(selectedUnit.Name, inventoryProduct.unit_qty) :
                          selectedUnit.Name }}
                        </label>
                    </div>
                  </pos-form-field>
                </div>

                <div>
                  <pos-form-field label="Price as Ordered"
                    [validationMessages]="{required: 'Please enter price.', min: 'Please enter positive amount.'}"
                    inputStyle="col-lg-4 col-md-4 col-sm-5 col-6">
                    <input posInput name="productQty" #productQty="ngModel" type="number" class="form-control"
                      [(ngModel)]="inventoryProduct.price" [required]="true" id="productQty" min="0" posDecimal />
                  </pos-form-field>
                </div>
              </div>
            </div>

            <div class="col-12 section-border">
              <div>
                <h3 class="setting-title">Min and Max Quantity</h3>
              </div>

              <div class="row row-cols-sm-2 ps-2">
                <div>
                  <pos-form-field label="Min" [fieldInfo]="fieldInfoMessages.Min" fieldInfoPlacement="bottom"
                    [validationMessages]="{required: 'Please enter min.', min: 'Please enter proper min value.'}"
                    inputStyle="col-lg-2 col-md-4 col-sm-5 col-6">
                    <input posInput class="" posInput name="productMin" #productMin="ngModel" type="number"
                      class="form-control col-100" [(ngModel)]="inventoryProduct.min" [required]="true" id="productMin"
                      min="0" posDecimal [validDecimal]="4"/>
                  </pos-form-field>
                </div>

                <div>
                  <pos-form-field label="Max" [fieldInfo]="fieldInfoMessages.Max"
                    [validationMessages]="{required: 'Please enter max.', min: 'Please enter proper max value.'}">
                    <input posInput name="productMax" #productMax="ngModel" type="number" class="form-control col-100"
                      id="productMax" [(ngModel)]="inventoryProduct.max" [min]="inventoryProduct.min" step="0.01"
                      [required]="true" posDecimal [validDecimal]="4" />
                  </pos-form-field>
                </div>

                <div>
                  <pos-form-field label="Critical Level"
                    [validationMessages]="{min: 'Please enter positive value.', max: 'Critical level must be less than min level.'}">
                    <input posInput name="criticalLevel" #criticalLevel="ngModel" type="number" posDecimal [validDecimal]="4"
                      class="form-control col-100" id="criticalLevel" [(ngModel)]="inventoryProduct.CriticalLevel"
                      step="0.01" min="0" [max]="inventoryProduct.min - 0.01" />
                  </pos-form-field>
                </div>

                <div>
                  <pos-form-field label="Max Days between Inventory"
                    [validationMessages]="{range: 'Max days must be between 1-365'}">
                    <input posInput name="productMaxDay" #productMaxDay="ngModel" type="number" posOnlyNumbers
                      id="productMaxDay" class="form-control col-100" [range]="[0, 365]" step="1"
                      [(ngModel)]="inventoryProduct.max_days_between_inventory" />
                  </pos-form-field>
                </div>
              </div>
            </div>

            <div class="col-12 section-border">
              <div>
                <h3 class="setting-title">Variance</h3>
              </div>

              <div class="row row-cols-sm-2 ps-2">
                <div>
                  <pos-form-field label="Variance Threshold Low" [fieldInfo]="fieldInfoMessages.VarianceThreshold"
                    [validationMessages]="{required: 'Please enter variance threshold.', range: 'Variance threshold should be valid number between 0 and 100.'}">

                    <div class="row">
                      <div class="col-6 col-md-4 col-100">
                        <input posInput name="productThresholdLow" posOnlyNumbers #productThresholdLow="ngModel"
                          type="number" class="form-control" step="1" [range]="[0,100]"
                          [(ngModel)]="inventoryProduct.ConsumptionVarianceLowThresholdPct"
                          [required]="inventoryProduct.IsSkipInventory" />
                      </div>
                      <div class="col-1">
                        <label class="edit-form__section__label">%</label>
                      </div>
                    </div>
                  </pos-form-field>
                </div>

                <div>
                  <pos-form-field label="Variance Threshold Low Amount"
                    [fieldInfo]="fieldInfoMessages.VarianceThresholdAmount"
                    [validationMessages]="{min: 'Please enter positive amount.'}">

                    <div class="row">
                      <div class="col-md-4 col-6 col-100">
                        <input posInput name="productThresholdLowAmount" #productThresholdLowAmount="ngModel"
                          type="number" class="form-control" step="0.01" min="0" posDecimal [validDecimal]="4"
                          [(ngModel)]="inventoryProduct.ConsumptionThresholdLowAmount" />
                      </div>

                      <div class="col-1">
                        <label class="edit-form__section__label display-flex" *ngIf="inventoryProduct.unit_id">{{
                          inventoryProduct.ConsumptionThresholdLowAmount ? Pluralize(selectedUnit.Name,
                          inventoryProduct.ConsumptionThresholdLowAmount) : selectedUnit.Name }}
                        </label>
                      </div>
                    </div>
                  </pos-form-field>
                </div>

                <div>
                  <pos-form-field label="Variance Threshold High" [fieldInfo]="fieldInfoMessages.VarianceThreshold"
                    [validationMessages]="{range: 'Variance threshold should be valid number between 0 and 100.'}">

                    <div class="row">
                      <div class="col-md-4 col-6 col-100">
                        <input posInput name="productThresholdHigh" posOnlyNumbers #productThresholdHigh="ngModel"
                          type="number" class="form-control" step="1" [range]="[0,100]"
                          [(ngModel)]="inventoryProduct.ConsumptionVarianceHighThresholdPct" />
                      </div>
                      <div class="col-1">
                        <label class="edit-form__section__label">%</label>
                      </div>
                    </div>
                  </pos-form-field>
                </div>

                <div>
                  <pos-form-field label="Variance Threshold High Amount"
                    [fieldInfo]="fieldInfoMessages.VarianceThresholdAmount"
                    [validationMessages]="{min: 'Please enter positive amount.'}">

                    <div class="row">
                      <div class="col-md-4 col-6 col-100">
                        <input posInput name="productThresholdHighAmount" #productThresholdHighAmount="ngModel"
                          type="number" class="form-control" step="0.01" min="0" posDecimal [validDecimal]="4"
                          [(ngModel)]="inventoryProduct.ConsumptionThresholdHighAmount" />  
                      </div>

                      <div class="col-1">
                        <label class="edit-form__section__label display-flex" *ngIf="inventoryProduct.unit_id">{{
                          inventoryProduct.ConsumptionThresholdHighAmount ? Pluralize(selectedUnit.Name,
                          inventoryProduct.ConsumptionThresholdHighAmount) : selectedUnit.Name }}
                        </label>
                      </div>
                    </div>
                  </pos-form-field>
                </div>
              </div>
            </div>

            <div class="col-12 section-border">
              <div>
                <h3 class="setting-title">Operations</h3>
              </div>

              <div class="row row-cols-sm-2 col-12 ps-2">
                <div class="pt-pix-7">
                  <div class="row">
                    <pos-form-field inputStyle="col-lg-5 col-md-12 col-sm-12 col-12 col-300">
                      <div class="display-flex">
                        <p-checkbox posInput name="ordered" #ordered="ngModel"
                          [(ngModel)]="inventoryProduct.IsWarnConsumption" binary="true" label="Warn on Consumption">
                        </p-checkbox>
                        <field-info info="{{fieldInfoMessages.WarnonConsumption}}"></field-info>
                      </div>
                    </pos-form-field>
                  </div>
  
                  <div>
                    <pos-form-field inputStyle="col-lg-5 col-12 col-300">
                      <div class="display-flex">
                        <p-checkbox posInput name="ordered" #ordered="ngModel"
                          [(ngModel)]="inventoryProduct.IsWarnUnexpectedInventoryQty" binary="true"
                          label="Warn Unexpected Inventory Qty">
                        </p-checkbox>
                        <field-info info="{{fieldInfoMessages.WarnUnexpectedInventoryQty}}"></field-info>
                      </div>
                    </pos-form-field>
                  </div>
                  </div>

                <div class="pt-pix-7">
                  <pos-form-field label="Auto Decrement" [fieldInfo]="fieldInfoMessages.AutoDecrement"
                    [validationMessages]="{required: 'Please enter qty.'}" inputStyle="col-lg-6 col-12">
    
                    <div class="row">
                      <div class="col-md-5 col-4 col-120 pe-0">
                        <ng-select #AutoDecrementBy="ngModel" name="AutoDecrementBy" id="AutoDecrementBy"
                          [(ngModel)]="inventoryProduct.AutoDecrementBy" (change)="changeAutoDecrement()"
                          appendTo="body" [items]="autoDecrementByList">
                        </ng-select>
                      </div>

                      <div class="col-md-5 col-4 col-100 pe-0">
                        <input posInput name="productMaxDays" #productMaxDays="ngModel" type="number"
                          class="form-control" [(ngModel)]="inventoryProduct.AutoDecrementQty"
                          [disabled]="inventoryProduct.AutoDecrementBy == 'None'"
                          [required]="inventoryProduct.AutoDecrementBy !== 'None'" />
                      </div>
                    </div>
                  </pos-form-field>
                </div>

                <div class="pt-pix-7">
                  <div class="row">
                    <div>
                      <pos-form-field inputStyle="col-lg-5 col-12 col-300">
                        <div class="display-flex">
                          <p-checkbox posInput name="doNotInventory" #doNotInventory="ngModel"
                            [(ngModel)]="inventoryProduct.IsSkipInventory" binary="true" label="Do not Inventory">
                          </p-checkbox>
                          <field-info info="{{fieldInfoMessages.DoNotInventory}}" [styleClass]="'float-right pt-0'">
                          </field-info>
                        </div>
                      </pos-form-field>
                    </div>

                    <div>
                      <pos-form-field inputStyle="col-lg-5 col-12 col-300">
                        <div class="display-flex">
                          <p-checkbox posInput name="isAllowOutOfStockSales" #isAllowOutOfStockSales="ngModel"
                            [(ngModel)]="inventoryProduct.IsAllowOutOfStockSales" binary="true"
                            label="Allow Out of Stock Sales">
                          </p-checkbox>
                          <field-info info="{{fieldInfoMessages.AllowOutOfStockSales}}" [styleClass]="'float-right pt-0'">
                          </field-info>
                        </div>
                      </pos-form-field>
                    </div>

                    <div>
                      <pos-form-field inputStyle="col-lg-5 col-12 col-300">
                        <p-checkbox posInput name="IsPermittedToSellIndividually" #IsPermittedToSellIndividually="ngModel"
                          [(ngModel)]="inventoryProduct.IsPermittedToSellIndividually" binary="true"
                          label="Permit to Sell Individually" (onChange)="inventoryProduct.DefaultConsumptionQty = null">
                        </p-checkbox>
                      </pos-form-field>
                    </div>
                  </div>
                </div>

                <div class="align-content-center">
                  <pos-form-field label="Next Inventory Log Date" inputStyle="col-lg-6 col-12">
                    <p-calendar posInput #logStartDate="ngModel" [required]="false" appendTo="body"
                      [dateFormat]="dateFormat" [inputStyle]="{'width':'unset','display':'unset'}"
                      inputStyleClass="form-control" [monthNavigator]=true [showIcon]="true" name="startDate"
                      [(ngModel)]="inventoryProduct.LogDueDateOverride"></p-calendar>
                  </pos-form-field>
                </div>

                <div class="pt-pix-7">
                  <div class="row">
                    <div>
                      <pos-form-field label="Default Consumption Qty" inputStyle="col-12">
                        <div class="row">
                          <div class="col-3 col-120">
                            <input posInput name="defaultConsumptionQty" #defaultConsumptionQty="ngModel" type="number"
                              class="form-control col-100" [(ngModel)]="inventoryProduct.DefaultConsumptionQty"
                              [disabled]="!inventoryProduct.IsPermittedToSellIndividually" id="defaultConsumptionQty" />
                          </div>

                          <div class="col-sm-6 col-8" [hidden]="(!inventoryProduct.DefaultConsumptionQty)">
                            <label class="pt-pix-7" *ngIf="inventoryProduct.unit_id">{{
                              inventoryProduct.DefaultConsumptionQty ? Pluralize(selectedUnit.Name,
                              inventoryProduct.DefaultConsumptionQty) :
                              selectedUnit.Name }}
                            </label>
                          </div>
                        </div>
                      </pos-form-field>
                    </div>

                    <div>
                      <pos-form-field label="Default Recipe Qty" inputStyle="col-12">
                        <div class="row">
                          <div class="col-3 col-120">
                            <input posInput name="defaultRecipeQty" #defaultRecipeQty="ngModel" type="number"
                              class="form-control col-100" step="0.01" min="0" [(ngModel)]="inventoryProduct.DefaultRecipeQty"
                              id="defaultRecipeQty" />
                          </div>

                          <div class="col-sm-6 col-8" [hidden]="(!inventoryProduct.DefaultRecipeQty)">
                            <label class="pt-pix-7" *ngIf="inventoryProduct.unit_id">{{
                              inventoryProduct.DefaultRecipeQty ? Pluralize(selectedUnit.Name,
                              inventoryProduct.DefaultRecipeQty) :
                              selectedUnit.Name }}
                            </label>
                          </div>
                        </div>
                      </pos-form-field>
                    </div>
                  </div>
                </div>

                <div class="align-content-center">
                  <pos-form-field label="Logging Baseline Date" inputStyle="col-lg-6 col-12">
                    <p-calendar posInput #inventoryBaselineDate="ngModel" [required]="false" appendTo="body"
                      [dateFormat]="dateFormat" [inputStyle]="{'width':'unset','display':'unset'}"
                      inputStyleClass="form-control" [monthNavigator]=true [showIcon]="true"
                      name="inventoryBaselineDate" [(ngModel)]="inventoryProduct.InventoryBaselineDate"></p-calendar>
                  </pos-form-field>
                </div>

                <div>
                  <pos-form-field label="Receiving Instructions" [fieldInfo]="fieldInfoMessages.ReceivingInstructions">
                    <textarea posInput class="form-control" name="receivingInstructions"
                      #receivingInstructions="ngModel" [(ngModel)]="inventoryProduct.ReceivingInstructions"
                      style="height: 128px"></textarea>
                  </pos-form-field>
                </div>

                <div>
                  <pos-form-field label="Recipe" [fieldInfo]="fieldInfoMessages.Recipe">
                    <textarea posInput class="form-control" name="recipe" #recipe="ngModel"
                      [(ngModel)]="inventoryProduct.Recipe" style="height: 128px"></textarea>
                  </pos-form-field>
                </div>

                <div>
                  <pos-form-field label="Max Portions" [fieldInfo]="fieldInfoMessages.MaxPortions">
                    <input posInput name="productMaxPortionsText" #productMaxPortionsText="ngModel" type="text"
                      class="form-control" [(ngModel)]="inventoryProduct.MaxPortionsText" />
                  </pos-form-field>    
                </div>

                <div>
                  <pos-form-field label="Measuring Instructions" [fieldInfo]="fieldInfoMessages.MeasuringInstructions"
                    [hasWarning]="inventoryProduct.MeasureByInstructions && inventoryProduct.MeasureByInstructions.length > 20"
                    [warning]="measuringInstructionsWarning">
                    <input posInput name="productMeasureByInstructions" #productMeasureByInstructions="ngModel"
                      max="100" type="text" class="form-control" [(ngModel)]="inventoryProduct.MeasureByInstructions" />
                  </pos-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div [hidden]="selectedTab != tabList.ProductVendor" ngModelGroup="productVendor" #productVendor="ngModelGroup">
          <div
            class="row pos-tab-body mx-0 pt-pix-5 border-top-0 tab-top-border form-horizontal screen-scroll"
            [ngStyle]="{'max-height': innerContainerHeight}">

            <pos-row colXs="12">
              <pos-column>
                <pos-form-field label="Name" [validationMessages]="{required:'Please select vendor.'}"
                  inputStyle="col-lg-5 col-sm-6 col-12">
                  <ng-select posInput appendTo="body" [items]="vendorDetailsList" bindLabel="name" bindValue="id"
                    placeholder="Select vendor" [(ngModel)]="inventoryProduct.vendor_id" name="vendor" id="vendor"
                    [required]="true">
                  </ng-select>
                </pos-form-field>
              </pos-column>

              <pos-column>
                <pos-form-field label="SKU"
                  [validationMessages]="{maxlength: 'SKU should not be longer than 200 characters.'}"
                  inputStyle="col-lg-5 col-sm-6 col-12">
                  <input posInput name="productSku" #productSku="ngModel" type="text" class="form-control"
                    [(ngModel)]="inventoryProduct.vendor_sku" maxlength="200" id="vendorSku" />
                </pos-form-field>
              </pos-column>

              <pos-column>
                <pos-form-field label="Product URL" inputStyle="col-lg-10 col-md-12 col-sm-12 col-12">
                  <input posInput name="vendorUrl" #vendorUrl="ngModel" type="text" class="form-control"
                    [(ngModel)]="inventoryProduct.VendorUrl" id="vendorUrl" />
                </pos-form-field>
              </pos-column>

              <pos-column>
                <pos-form-field label="Typical Order Lead Time"
                  [validationMessages]="{range: 'Typical Order Lead Time should be between 0 - 365.'}">
                  <input posInput name="typicalLeadTime" #typicalLeadTime="ngModel" type="number" [range]="[0, 365]"
                    class="form-control col-70" [(ngModel)]="inventoryProduct.TypicalLeadTime" id="typicalLeadTime" />
                </pos-form-field>
              </pos-column>

              <pos-column>
                <pos-form-field>
                  <p-checkbox posInput name="IsOnOrder" #IsOnOrder="ngModel" [(ngModel)]="inventoryProduct.IsOnOrder"
                    binary="true" label="Currently on Order">
                  </p-checkbox>
                </pos-form-field>
              </pos-column>
            </pos-row>
          </div>
        </div>
        <div [hidden]="selectedTab != tabList.ProductWorkflow" ngModelGroup="productWorkflow"
          #productWorkflow="ngModelGroup">
          <div
            class="row pos-tab-body mx-0 pt-pix-5 border-top-0 tab-top-border form-horizontal screen-scroll "
            [ngStyle]="{'max-height': innerContainerHeight}">

            <pos-row colXs="12">
              <pos-column>
                <pos-form-field inputStyle="col-md-6 col-12">
                  <p-checkbox posInput name="logMinReached" #logMinReached="ngModel"
                    label="Trigger Inventory Log when minimum is reached"
                    [(ngModel)]="inventoryProduct.IsTriggerInventoryLogOnMinimumReached" binary="true">
                  </p-checkbox>
                </pos-form-field>
              </pos-column>
              <pos-column>
                <pos-form-field inputStyle="col-md-6 col-12">
                  <p-checkbox posInput name="exhaustionPrompt" #exhaustionPrompt="ngModel"
                    label="Prompt to Log Inventory at exhaustion"
                    [(ngModel)]="inventoryProduct.IsExhaustionPromptToTerminalId" binary="true"
                    (onChange)="onChangeExhaustionPrompt()">
                  </p-checkbox>
                </pos-form-field>
              </pos-column>
              <pos-column class="ps-pix-26">
                <pos-form-field label="On terminal" [validationMessages]="{required: 'Please select a terminal.'}"
                  inputStyle="col-md-4 col-lg-3 col-sm-6 col-12">
                  <ng-select posInput appendTo="body" [disabled]="!inventoryProduct.IsExhaustionPromptToTerminalId"
                    [items]="terminalDetailsList" bindLabel="TerminalName" bindValue="TerminalId"
                    placeholder="Select terminal" [(ngModel)]="inventoryProduct.ExhaustionPromptToTerminalId"
                    name="terminal" [required]="inventoryProduct.IsExhaustionPromptToTerminalId" #terminal="ngModel">
                  </ng-select>
                </pos-form-field>
              </pos-column>

              <pos-column>
                <pos-form-field inputStyle="col-md-6 col-12">
                  <p-checkbox posInput name="quantityReached" #quantityReached="ngModel"
                    [(ngModel)]="inventoryProduct.IsMinQuantityReachedTaskScheduled" binary="true"
                    (onChange)="setTaskControls()" label="Create Task when minimum is reached">
                  </p-checkbox>
                </pos-form-field>
              </pos-column>

              <pos-column class="ps-pix-26" *ngIf="inventoryProduct.IsMinQuantityReachedTaskScheduled">

                <div class="row">
                  <div>
                    <pos-form-field label="Task" inputStyle="col-md-4 col-lg-3 col-sm-6 col-12">

                      <div class="row">
                        <div>
                          <ng-select posInput appendTo="body" [items]="inventoryProductTasksList" bindLabel="taskname"
                            bindValue="id" placeholder="Select task" [(ngModel)]="inventoryProduct.selectedTask"
                            name="tasks">
                          </ng-select>
                        </div>
                        
                        <div class="col-sm-6 col-12 pt-pix-8" permissions='[{"Name":"{{permission.tasks}}", "Level":"{{permission.editLevel}}"}]'>
                          <span (click)="taskAdd(0)" class="pe-pix-5">
                            <pos-action-icon [icon]="icons.plusWhite" iconText="Add"></pos-action-icon>
                          </span>
                          <span (click)="taskAdd(inventoryProduct.selectedTask)">
                            <pos-action-icon [icon]="icons.editWhiteWithText" class="text-nowrap" iconText="Edit">
                            </pos-action-icon>
                          </span>
                        </div>
                      </div>
                    </pos-form-field>
                  </div>
                  
                  <div>
                    <pos-form-field label="When">
                      <div class="btn-group">
                        <label class="btn btn-radio"
                          [ngClass]="{'active btn-primary normal': inventoryProduct.TaskWhen == '0', 'btn-secondary': inventoryProduct.TaskWhen != '0'}">
                          <input posInput type="radio" name="schedule" class="form-check-input"
                            [(ngModel)]="inventoryProduct.TaskWhen" value="0">
                          Immediately</label>
                        <label class="btn btn-radio"
                          [ngClass]="{'active btn-primary normal': inventoryProduct.TaskWhen == '1', 'btn-secondary': inventoryProduct.TaskWhen != '1'}">
                          <input type="radio" name="schedule" class="form-check-input"
                            [(ngModel)]="inventoryProduct.TaskWhen" value="1"> Same
                          Day</label>
                        <label class="btn btn-radio"
                          [ngClass]="{'active btn-primary normal': inventoryProduct.TaskWhen == '2', 'btn-secondary': inventoryProduct.TaskWhen != '2'}">
                          <input type="radio" name="schedule" class="form-check-input"
                            [(ngModel)]="inventoryProduct.TaskWhen" value="2"> Next
                          Day</label>
                      </div>
                    </pos-form-field>
                  </div>

                  <div>
                    <pos-form-field *ngIf="inventoryProduct.TaskWhen != '0' && inventoryProduct.TaskTime" label="Time">
                      <p-calendar posInput [(ngModel)]="inventoryProduct.TaskTime" [timeOnly]="true" [inline]="true"
                        [hourFormat]="hourFormat" name="TaskTime" [inputStyle]="{'width':'215px'}"
                        inputStyleClass="form-control" class="time-control">
                      </p-calendar>
                    </pos-form-field>
                  </div>
                </div>
              </pos-column>

              <pos-column>
                <pos-form-field>
                  <p-checkbox posInput name="printPrep" #printPrep="ngModel" label="Print Prep Label"
                    [(ngModel)]="inventoryProduct.IsPrintPrepLabel" binary="true">
                  </p-checkbox>
                </pos-form-field>
              </pos-column>

              <pos-column>
                <pos-form-field>
                  <div class="display-flex">
                    <p-checkbox posInput name="Common" #Common="ngModel" [(ngModel)]="inventoryProduct.common"
                      binary="true" label="Show on Pick Screen">
                    </p-checkbox>
                    <field-info info="{{fieldInfoMessages.ShowonPickScreen}}" [styleClass]="'float-right pt-0'"
                      [placement]="'bottom'" [seconds]="7">
                    </field-info>
                  </div>
                </pos-form-field>
              </pos-column>

              <pos-column>
                <pos-form-field>
                  <p-checkbox posInput name="isLogAfterReceived" #isLogAfterReceived="ngModel"
                    [(ngModel)]="inventoryProduct.IsLogAfterReceived" binary="true"
                    label="Trigger Logging after Receiving">
                  </p-checkbox>
                </pos-form-field>
              </pos-column>

              <pos-column>

                <div class="row row-cols-sm-4">
                  <div class="col-200">
                    <pos-form-field inputStyle="col-12 p-0 col-200 ps-pix-26">
                      <div class="btn-group">
                        <label class="btn btn-radio"
                          [ngClass]="receivedDay == 'false' && inventoryProduct.IsLogAfterReceived ? 'active btn-primary' : 'btn-secondary'">
                          <input posInput type="radio" name="receiving" class="form-check-input"
                            [(ngModel)]="receivedDay" value="false" [disabled]="!inventoryProduct.IsLogAfterReceived">
                          Immediately</label>
                        <label class="btn btn-radio"
                          [ngClass]="receivedDay == 'true' && inventoryProduct.IsLogAfterReceived ? 'active btn-primary' : 'btn-secondary'">
                          <input type="radio" name="receiving" class="form-check-input" [(ngModel)]="receivedDay"
                            value="true" [disabled]="!inventoryProduct.IsLogAfterReceived">
                          Day</label>
                      </div>
                    </pos-form-field>
                  </div>

                  <div class="col-200">
                    <pos-form-field *ngIf="receivedDay == 'true' && inventoryProduct.IsLogAfterReceived"
                      inputStyle="col-12 p-0 col-150"
                      [validationMessages]="{required: 'Please enter days.', min: 'Days must be greater than 0.'}">
                      
                      <div class="row row-cols-3">
                        <div class="p-0 col-20">
                          <label class="edit-form__section__label"> In </label>
                        </div>

                        <div class="p-0">
                          <input posInput name="logAfterReceivedDays" #logAfterReceivedDays="ngModel" type="number"
                            class="form-control" [(ngModel)]="inventoryProduct.LogAfterReceivedDays"
                            [required]="receivedDay && inventoryProduct.IsLogAfterReceived" min="1" />
                        </div>

                        <div class="col-1 col-20">
                          <label class="edit-form__section__label"> Day(s) </label>
                        </div>
                      </div>
                    </pos-form-field>
                  </div>
                </div>
              </pos-column>
            </pos-row>
          </div>
        </div>
        <div [hidden]="selectedTab != tabList.ProductBarcodes">
          <div
            class="row pos-tab-body mx-0 pt-pix-5 border-top-0 tab-top-border form-horizontal screen-scroll overflow-visible"
            [ngStyle]="{'max-height': innerContainerHeight}">
            <div *ngIf="!inventoryProductId" class="alert condensed inherit-position alert-warning mt-pix-10">
              {{saveButtonPressToProceed}}</div>
              <div class="row mx-0 form-group mb-0 p-0" *ngIf="inventoryProductId">
                <pos-manage-barcode [isPopup]="false" [unitName]="inventoryProduct.UnitName"
                  [inventoryProductId]="inventoryProductId"></pos-manage-barcode>
              </div>
            <div class="" >
            </div>
          </div>
        </div>
        <div [hidden]="selectedTab != tabList.ProductInventoryLocations">
          <div
            class="row pos-tab-body mx-0 pt-pix-5 border-top-0 tab-top-border form-horizontal screen-scroll"
            [ngStyle]="{'max-height': innerContainerHeight}">

            <div class="col-sm-6 col-12">
              <h3 class="setting-title">
                <span>Primary</span>
              </h3>
              <pos-row colXs="12" class="ps-2">
                <pos-column>
                  <pos-form-field label="Primary Zone" inputStyle="col-lg-7 col-md-10 col-sm-10 col-12 zone-setup">
                    <div class="relative pe-pix-15">
                      <div class="input-color z-index-1049"
                        [ngStyle]="{'background-color': selectedPrimaryZone && selectedPrimaryZone.Id ? selectedPrimaryZone.Color : ''}">
                      </div>
                      <ng-select posInput [items]="inventoryZoneList" bindLabel="NameWithCode"
                        placeholder="Inventory primary zone" [(ngModel)]="selectedPrimaryZone" name="primaryZone"
                        (change)="changePrimaryBinList()" appendTo="body">
                      </ng-select>
                    </div>
                  </pos-form-field>
                </pos-column>
                <pos-column>
                  <pos-form-field label="Primary Bin" inputStyle="col-lg-7 col-md-10 col-sm-10 col-12 zone-setup">
                    <div class="relative pe-pix-15">
                      <div class="input-color z-index-1049"
                        [ngStyle]="{'background-color': selectedPrimaryBin && selectedPrimaryBin.Id ? selectedPrimaryBin.Color : ''}">
                      </div>
                      <ng-select posInput [disabled]="!selectedPrimaryZone || !selectedPrimaryZone.Id"
                        [items]="inventoryPrimaryBinList" bindLabel="Bin" placeholder="Inventory primary bin"
                        [(ngModel)]="selectedPrimaryBin" name="primaryBin" appendTo="body">
                      </ng-select>
                    </div>
                  </pos-form-field>
                </pos-column>
              </pos-row>
            </div>
            <div class="col-sm-6 col-12">
              <h3 class="setting-title">
                <span>Secondary</span>
              </h3>
              <pos-row colXs="12" class="ps-2">
                <pos-column>
                  <pos-form-field label="Secondary Zone" inputStyle="col-lg-7 col-md-10 col-sm-10 col-12 zone-setup">
                    <div class="relative pe-pix-15">
                      <div class="input-color z-index-1049"
                        [ngStyle]="{'background-color': selectedSecondaryZone && selectedSecondaryZone.Id ? selectedSecondaryZone.Color : ''}">
                      </div>
                      <ng-select posInput [items]="inventoryZoneList" bindLabel="NameWithCode"
                        placeholder="Inventory secondary zone" [(ngModel)]="selectedSecondaryZone" name="secondaryZone"
                        (change)="changeSecondaryBinList()" appendTo="body">
                      </ng-select>
                    </div>
                  </pos-form-field>
                </pos-column>
                <pos-column>
                  <pos-form-field label="Secondary Bin" inputStyle="col-lg-7 col-md-10 col-sm-10 col-12 zone-setup">
                    <div class="relative pe-pix-15">
                      <div posInput class="input-color z-index-1049"
                        [ngStyle]="{'background-color': selectedSecondaryBin && selectedSecondaryBin.Id ? selectedSecondaryBin.Color : ''}">
                      </div>
                      <ng-select [disabled]="!selectedSecondaryZone || !selectedSecondaryZone.Id"
                        [items]="inventorySecondaryBinList" bindLabel="Bin" placeholder="Inventory secondary bin"
                        [(ngModel)]="selectedSecondaryBin" name="secondaryBin" appendTo="body">
                      </ng-select>
                    </div>
                  </pos-form-field>
                </pos-column>
              </pos-row>
            </div>
          </div>
        </div>

        <div [hidden]="selectedTab != tabList.ProductRawIngredients">
          <div
            class="row pos-tab-body mx-0 pt-pix-5 border-top-0 tab-top-border pt-pix-15 form-horizontal screen-scroll pe-0"
            [ngStyle]="{'max-height': innerContainerHeight}">
            <div class="col-md-12 form-group mb-0 p-0">
              <div class="col-md-12 pe-0">
                <pos-inventory-product-raw-ingredients [isSubmit]="isSubmit" [inventoryProductId]="inventoryProductId"
                  (saveIngredients)="onSaveIngredients($event)">
                </pos-inventory-product-raw-ingredients>
              </div>
            </div>
          </div>
        </div>
        <div [hidden]="selectedTab != tabList.ProductNutritionFacts">
          <div
            class="row pos-tab-body mx-0 pt-pix-5 border-top-0 tab-top-border form-horizontal screen-scroll"
            [ngStyle]="{'max-height': innerContainerHeight}">
            <pos-inventory-product-serving-edit [servingInformationData]='servingInformationData'
              [servingUnit]="selectedUnit.Name" [isSubmit]="isSubmit"
              (validateOnSave)="onSaveServingInformation($event)"></pos-inventory-product-serving-edit>
            <pos-inventory-product-nutrition [nutritionInfo]="nutritionInfoList" #inventoryProductNutritionInfoComponent
              [inventoryProductId]="inventoryProductId"></pos-inventory-product-nutrition>
          </div>
        </div>
        <div [hidden]="selectedTab != tabList.ProductDietaryWarnings">
          <div
            class="row pos-tab-body mx-0 pt-pix-5 border-top-0 tab-top-border form-horizontal screen-scroll"
            [ngStyle]="{'max-height': innerContainerHeight}">
            <pos-inventory-product-dietary-warnings [dietaryWarningInfo]="dietaryWarningList"
              #inventoryProductDietaryWarningComponent
              [inventoryProductId]="inventoryProductId"></pos-inventory-product-dietary-warnings>
          </div>
        </div>
        <div [hidden]="selectedTab != tabList.ProductAliases">
          <div
            class="row pos-tab-body mx-0 pt-pix-5 border-top-0 tab-top-border form-horizontal screen-scroll"
            [ngStyle]="{'max-height': innerContainerHeight}">
            <div *ngIf="!inventoryProductId" class="alert condensed inherit-position alert-warning mt-pix-10">
              {{saveButtonPressToProceed}}</div>
              <div class="col-12 form-group mb-0 overflow-auto-tabs" *ngIf="inventoryProductId">
                <inventory-product-alias [inventoryProductId]="inventoryProductId">
                </inventory-product-alias>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 action-button-container"
      [ngClass]="{'page-footer': !isScroll, 'popup-footer': isScroll}">
      <button class="btn-lg btn-success btn normal me-pix-5 white pos-button" type="submit" (click)="isSaveAndContinue = true"
        *ngIf="!isScroll && !inventoryProductId"> Apply </button>
      <button class="btn-lg btn-primary btn btn-save pos-button" type="submit" value="Save"> Save </button>
      <button class="btn-lg btn-warning white btn normal me-pix-5 pos-button" type="button" value="Print"
        (click)="showPrintStockLabel()" *ngIf="inventoryProduct.id && inventoryProduct.IsPrintPrepLabel">Print
        Stock Label</button>
      <button class="btn-secondary btn-lg btn" [ngClass]="{'btn-cancel-modal': isScroll, 'btn-cancel': !isScroll}"
        (click)="onCancel(false)" type="button" value="Cancel">
        Cancel </button>
      <button class="btn btn-lg bg-theme-separator pos-button white ms-pix-10 float-start normal" *ngIf="inventoryProduct.id"
        (click)="printProductBarCode()" type="button">
        <pos-icon [icon]="icons.barcode" class="pe-pix-5"></pos-icon>Print Barcode
      </button>
      <button class="btn-lg bg-theme-separator btn normal ms-pix-10 ms-pix-sm-5 mt-pix-1 mt-pix-sm-0 pos-button float-start" type="button" value="Print"
        [feature]="features.InventoryProductLabelPrint" (click)="printLabel()" *ngIf="inventoryProduct.id">
        <pos-icon [icon]="icons.printing" class="pe-pix-5"></pos-icon>Print
        Label</button>
    </div>
  </form>
</pos-edit-wrapper>
