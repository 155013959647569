<pos-edit-wrapper (close)="cancelEditing()" [isPopup]="false" [headingIcon]="icons.securityRolesUserLock"
    heading="{{securityRoleId > 0 ? 'Security Role: ' + selectedRoleName : 'Security Role'}}">
    <form (submit)="submit(securityRoleForm.valid)" #securityRoleForm="ngForm" autocomplete="off">
        <div class="col-12 container-fluid border-top main-div">
            <ul class="nav nav-tabs inner-tab pos-nav-tabs">
                <li [ngClass]="{'active': selectedTab == tabList.Permissions }">
                    <a (click)="selectedTab = tabList.Permissions" class="blue"
                        [ngClass]="{'required': permissions.invalid }">
                        <span>
                            <pos-icon class="theme-separator pe-pix-5" [icon]="icons.lockOpen"></pos-icon>
                        </span>
                        <span>Permissions</span>
                    </a>
                </li>
                <li [ngClass]="{'active': selectedTab == tabList.ButtonExclusions }">
                    <a (click)="selectedTab = tabList.ButtonExclusions" class="blue" (click)="getAllButtons()"
                        [ngClass]="{'required': buttonExclusions.invalid}">
                        <span class="pe-pix-5">
                            <pos-icon class="theme-separator" [icon]="icons.timesCircle"></pos-icon>
                        </span> <span>Button Exclusions</span> </a>
                </li>
            </ul>

            <div class="col-12 pt-pix-5" [hidden]="selectedTab != tabList.Permissions" ngModelGroup="permissions"
                #permissions="ngModelGroup">
                <pos-row class="pos-tab-body g-0">
                    <pos-column xs="12">
                        <pos-form-field label="Role Name" inputStyle="col-lg-4 col-md-6 col-12"
                            [validationMessages]="{required: 'Please enter role name.'}">
                            <input posInput type="text" name="roleName" class="form-control" #roleName="ngModel"
                                [(ngModel)]="selectedRoleName" [required]="true" [disabled]="securityRoleId > 0 && selectedRoleName == managerRole" />
                        </pos-form-field>
                    </pos-column>
                    <pos-column xs="12" class="pt-pix-5">
                        <pos-form-field inputStyle="row">
                            <div class="col-350 col-4">
                                <input type="text" class="form-control" placeholder="Filter..." name="searchString"
                                    [(ngModel)]="searchString" (input)="filterSearch()">
                            </div>
                            <button type="button" class="ms-pix-10 col-130 col-5 mt-pix-10 mt-pix-sm-0 btn btn-secondary normal pos-button"
                                (click)="closeAllNodes(permissionAssignments)">
                                <pos-icon [icon]="icons.minusSquare"></pos-icon> Collapse All</button>
                        </pos-form-field>
                    </pos-column>
                    <pos-column xs="12">
                        <div class="screen-height mt-pix-5">
                            <div class="col-lg-7 col-md-9 col-12">
                                <pos-permission-node [dataNodes]="permissionAssignments" [accessLevels]="accessLevels"
                                    [permissionLevels]="permissionLevels"></pos-permission-node>
                            </div>
                        </div>
                    </pos-column>
                </pos-row>
            </div>

            <div class="col-12 pt-pix-5" [hidden]="selectedTab != tabList.ButtonExclusions" ngModelGroup="buttonExclusions"
                #buttonExclusions="ngModelGroup">
                <div class="content-height pos-tab-body">
                    <pos-row>
                        <pos-column xs="12">
                            <pos-form-field label="Button to exclude">
                                <div class="row">
                                    <div class="col-md-6 col-sm-10 col-9">
                                        <ng-select posInput [items]="buttonExclusionList" bindLabel="ButtonText"
                                            bindValue="Id" placeholder="Select button" appendTo="body"
                                            [(ngModel)]="selectedButton" name="buttonExclusionList">
                                        </ng-select>
                                    </div>
                                    <div class="col-lg-2 col-md-1 col-2">
                                        <button type="button" class="btn btn-primary pos-button fw-bold text-white"
                                            [disabled]="!selectedButton" (click)="addButtonToExclusion()"
                                            value="Add">Add</button>
                                    </div>
                                </div>
                            </pos-form-field>
                        </pos-column>

                        <pos-column lg="6" sm="9" xs="12">
                            <ng-table id="excludedButtons" [sort]="true" [columns]="excludedButtonsColumns"
                                [data]="excludedButtonList" [paginator]="false" [scrollable]="true"
                                [scrollHeight]="'50vh'">
                            </ng-table>
                        </pos-column>
                    </pos-row>
                </div>
            </div>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button name="btnRoleSubmit" class="btn btn-primary btn-lg btn-save pos-button" type="submit" value="Save">
                Save
            </button>
            <button name="btnRoleCancel" class="btn btn-secondary btn-lg btn-cancel pos-button" data-dismiss="modal"
                (click)="cancelEditing()" type="button" value="Cancel">
                Cancel
            </button>
        </div>
    </form>
</pos-edit-wrapper>
