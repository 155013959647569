<pos-edit-wrapper (close)="onCancel(false)" heading="{{audioNotifierId ? 'Edit' : 'Add'}} Audio Clip">
    <form #audioNotifierForm="ngForm" (ngSubmit)="saveAudioNotificationFile(audioNotifierForm.valid)"
        autocomplete="off">
        <div class="form-horizontal screen-scroll pos-modal-body">
            <div class="row">
                <context-provider provide="props">
                <pos-row>
                    <pos-column>
                        <pos-form-field label="Name" [validationMessages]="{required: 'Please enter file name.'}">
                            <input posInput class="form-control" name="warningMessage" #warningMessage="ngModel"
                                [(ngModel)]="audioNotificationFile.Name" [required]="true">
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="File" [inputStyle]="{'col-md-10 col-9': true, 'has-error': (!audioNotificationFile.FileName && audioNotifierForm.submitted)}">
                                <input posInput type="file" class="form-control" accept=".wav, .mp3" #audioNotifierFile
                                    name="audioNotifierFile" id="audioNotifierFile"
                                    (change)="handleFileInput($event.target.files)" [required]="true"/>
                                <span *ngIf="!(isChooseFile) && audioNotificationFile.FileName">
                                    <label class="control-label">{{audioNotificationFile.FileName}}</label>
                                </span>
                                <span *ngIf="audioNotifierForm.submitted && !audioNotificationFile.FileName">
                                    <label class="control-label">Please choose file.</label>
                                </span>
                            </pos-form-field>
                    </pos-column>
                </pos-row>
                </context-provider>
            </div>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" scrollup>Save </button>
            <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" (click)="onCancel(false)">Close</button>
        </div>
    </form>
</pos-edit-wrapper>
