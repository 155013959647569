import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { djBars, play, stop, stepBackward, stepForward, pause } from '../../../shared/components/icon';
import { Options } from '@angular-slider/ngx-slider/options';

@Component({
  selector: 'pos-sound-player',
  templateUrl: './sound-player.component.html',
  styleUrls: ['./sound-player.component.scss']
})
export class SoundPlayerComponent implements OnInit {

  @Input() djScheme;
  @Input() schemes;
  @Input() djTitle;
  @Input() playBackTitle;
  @Input() isLoadPlaylist;
  @Input() isPlaying;
  @Input() isStopped;
  @Input() volume;
  @Input() currentProgress;
  @Input() duration;
  @Input() counter;
  @Input() playlistName;
  @Input() playlistTracks;
  @Input() selectedPlaylist;
  @Output() nextSchemeClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() startClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() pauseClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() playNextClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() playPreviousClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() setVolumeClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() progressBarClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() loadTrackClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() stopClick = new EventEmitter<any>();
  icons = {
    djBars, play, stop, stepBackward, stepForward, pause
  };
  currentTime: Date = new Date();
  sliderOptions: Options = {
    floor: 1,
    ceil: 100,
    translate: (value: number): string => {
      return '';
    }
  };

  constructor() { }

  ngOnInit(): void {
    setInterval(() => {
      this.currentTime = new Date();
    }, 1000);
  }

  nextScheme = (scheme) => this.nextSchemeClick.emit(scheme);
  start = (fadein: number, toVolume: number) => this.startClick.emit({ fadein: fadein, toVolume: toVolume });
  pause = () => this.pauseClick.emit();
  stop = () => this.stopClick.emit();
  playNext = () => this.playNextClick.emit();
  playPrevious = () => this.playPreviousClick.emit();
  setVolume = (event) => { 
    this.volume = event.value; 
    this.setVolumeClick.emit(this.volume);
  }
  progressBarClicked = (event) => this.progressBarClick.emit(event);
  loadTrack = (index) => this.loadTrackClick.emit(index);
}
