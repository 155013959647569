import { Component, OnInit, Input, ViewChild, EventEmitter, TemplateRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { InventoryProductRecipeService } from 'src/app/shared/services/inventory-product-recipe.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import { finalize } from 'rxjs/operators';
import { BaseListComponent } from 'src/app/shared/components/base-list.component';
import { InventoryProductRecipe } from '../../interface/inventory-product-recipe';
import { GridColumn, TemplateColumn } from '@tarktech/tark-ng-utils';
import { Messages } from '../../../../shared/constants';
import { ICloseable, ApplicationStateService, ModalComponent } from 'src/app/shared';
import { ActivatedRoute } from '@angular/router';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import Pluralize from 'pluralize';
import { PrepRecipeEditModalComponent } from '../prep-recipe-edit/prep-recipe-edit-modal/prep-recipe-edit-modal.component';

@Component({
    selector: 'pos-prep-recipe-list',
    templateUrl: './prep-recipe-list.component.html',
    styleUrls: ['./prep-recipe-list.component.scss']
})
export class PrepRecipeListComponent extends BaseListComponent<InventoryProductRecipe> implements OnInit, ICloseable {

    prepRecipeColumns: Array<GridColumn> = [];
    inventoryProductRecipes: Array<InventoryProductRecipe> = [];
    inventoryProductRecipe: InventoryProductRecipe;
    editInventoryProducts: InventoryProductRecipe;

    @Input() inventoryProductId: number;
    @Input() unitName: string;
    @ViewChild('barCodeForm') barCodeForm: NgForm;
    close: EventEmitter<any> = new EventEmitter();
    @Input() inventoryProductName: string;
    @ViewChild('editPrepRecipeTemplate') public editPrepRecipeTemplate: any;
    @ViewChild('unitTemplate', {static: true}) unitTemplate: TemplateRef<any>;

    modalRef: ModalComponent;
    Pluralize = Pluralize;

    constructor(
        protected alertService: AlertsService,
        protected spinnerService: SpinnerService,
        protected inventoryProductRecipeService: InventoryProductRecipeService,
        protected modalService: ModalService,
        protected printService: PrintTableService,
        private equipmentTypeEditModalService: ModalService,
        protected applicationStateService: ApplicationStateService,
        protected route: ActivatedRoute
    ) {
        super(inventoryProductRecipeService, alertService, spinnerService, modalService, applicationStateService, printService,
            route);
    }

    ngOnInit() {
        this.deleteSuccessMessage = Messages.InventoryProductRecipeDeleted;
        this.inventoryProductRecipe = this.inventoryProductRecipeService.newInventoryProductRecipe();
        this.inventoryProductRecipe.PrepareQty = 1;
        this.inventoryProductRecipe.MainInventoryProductId = this.inventoryProductId;
        this.prepRecipeColumns = this.configureGridColumns();
    }

    public getGridColumns(): Array<GridColumn> {
        return [
            new GridColumn({ HeaderText: 'Product', Field: 'ProductName', IsSortable: true, Width: '30%' }),
            new GridColumn({ HeaderText: 'Qty', Field: 'Qty', IsSortable: true, Width: '12%' }),
            new TemplateColumn({ HeaderText: 'Unit', Field: 'UnitName', IsSortable: true, Width: '18%', itemTemplate: this.unitTemplate }),
            new GridColumn({ HeaderText: 'Instruction', Field: 'Instructions', IsSortable: true, Width: '30%' })
        ] as Array<GridColumn>;
    }

    public reload() {
        if (this.inventoryProductId) {
            this.spinnerService.show();
            this.inventoryProductRecipeService.getInventoryProductRecipe(this.inventoryProductId)
                .pipe(finalize(() => {
                    this.spinnerService.hide();
                }))
                .subscribe({next:(response) => {
                    this.inventoryProductRecipes = response ? response : [];
                    this.inventoryProductRecipe.PrepareQty = response && response[0] ? response[0].PrepareQty : 1;
                    this.gridData = this.inventoryProductRecipes;
                }, error: this.alertService.showApiError });
        }
    }

    public editItem(id: number, data?: InventoryProductRecipe): void {
        if (this.inventoryProductRecipe.PrepareQty) {
            if (data) {
                this.editInventoryProducts = data;
            } else {
                this.editInventoryProducts = this.inventoryProductRecipeService.newInventoryProductRecipe();
                this.editInventoryProducts.PrepareQty = this.inventoryProductRecipe.PrepareQty;
            }
            const prepRecipeModal = this.equipmentTypeEditModalService.getModalWrapper(PrepRecipeEditModalComponent);
            const modalRef = prepRecipeModal.show({
                'backdrop': 'static',
                'class': 'vertical-center',
                initialState: {
                    inventoryProductId: this.inventoryProductId,
                    editInventoryProducts: this.editInventoryProducts
                }
            });
            modalRef.close.subscribe((res) => {
                if (res && res.reload) {
                    this.reload();
                }
            });
        }
    }

    public getConfirmDeleteMessage(data: InventoryProductRecipe): string {
        return StringUtils.format(Messages.ConfirmDeleteInventoryProductRecipe,
            { 'prepRecipe': data ? data.ProductName : '' }
        );
    }

    public submit(isValid: boolean) {
        if (!isValid) {
            return;
        }
        this.inventoryProductRecipe.Qty = 0;
        this.inventoryProductRecipe.UsedPrepQty = 0;
        this.inventoryProductRecipe.CalculatedQty = 0;
        this.inventoryProductRecipe.InventoryProductId = 0;
        this.savePrepRecipe();
    }

    public savePrepRecipe() {
        this.spinnerService.show();
        this.inventoryProductRecipeService.saveInventoryProductRecipe(this.inventoryProductRecipe)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({next:() => {
                this.alertService.renderSuccessMessage(Messages.PrepareQtySaveSuccess);
                this.reload();
            }, error: this.alertService.showApiError });
    }

    public preventMinusSign(event) {
        if (event.key === '-') {
            return false;
        }
        return true;
    }

    public closePopup() {
        this.close.emit(true);
    }

    public onSave() {
        this.reload();
        this.modalRef.modalRef.hide();
    }

    public onClose() {
        this.modalRef.modalRef.hide();
    }
}
