import { Component, OnInit, EventEmitter, Input, ViewChild, OnDestroy, Inject } from '@angular/core';
import {
  NumpadOptions, ApplicationStateService, SettingParam, DomainConstants, Messages, ICloseable, ModalService,
  ConfirmDeleteModalComponent, AlertsService, OrderService, SpinnerService, InfoModalComponent, RabbitMQService, MessageTypes, messageCode,
  OrderEventBroadcastingService, HttpStatusCodes, ModalBackdropService
} from 'src/app/shared';
import * as _ from 'lodash';
import { filter, finalize } from 'rxjs/operators';
import { GiftCardPaymentComponent } from '../gift-card-payment/gift-card-payment.component';
import { OrderInvoice } from '../../interface';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { cloneDeep } from 'lodash';
import { OrderPaymentService } from '../../services/order-payment.service';
import { Subscription } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
declare let $: any;
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { PeripheralModelComponent } from '../peripheral-model/peripheral-model.component';
@Component({
  selector: 'pos-ringup',
  templateUrl: './ringup.component.html',
  styleUrls: ['./ringup.component.scss']
})
export class RingupComponent implements OnInit, ICloseable, OnDestroy {

  dueAmount: number = 0;
  isAllowCreditCard: boolean = false;
  typeAmount: number;
  numpadOptionChange = new EventEmitter<any>();
  tenderTypeColors: any = {};
  @Input() order: OrderInvoice;
  @Input() orderId: number;
  @Input() grandTotal: number;
  @Input() isServeOrder: boolean = false;
  @ViewChild('ringupNumpad') ringupNumpad: any;
  @ViewChild('peripheralModel') peripheralModel: any;
  @ViewChild('creditCardResponseModel') creditCardResponseModel: any;
  cashTendered: number;
  isPaymentByEBT: boolean;
  peripheralCreditCardMessage: string = '';
  numpadPaymentOptions: NumpadOptions = { allowDecimal: true, prefix: '$', max: 9999.99, enterButton: false };
  settingParam: SettingParam;
  canModifyAmount: boolean = true;
  paymentButtonText: string;
  close: EventEmitter<any> = new EventEmitter();
  creditCardResponseRef: BsModalRef;
  creditCardPaymentResponseHeader: string = '';
  creditCardPaymentResponseMessage: string = '';
  creditCardPaymentResponseButtonText: string = 'Cancel';
  ringupRabbitMQSubscriptions: Array<any> = [];
  rabbitMQRequestId: string = '';
  ringupSuccessMessage: string = '';
  isShowCCError: boolean = false;
  // rabbitMq subscriptions
  rabbitMqOrderEntryTerminalQueueSubscription: Subscription;
  rabbitMqHICResponseSubscription: Subscription;
  rabbitMqHICResponseToServerSubscription: Subscription;
  constructor(private applicationStateService: ApplicationStateService,
    private alertService: AlertsService,
    private modalService: ModalService,
    private orderService: OrderService,
    private spinnerService: SpinnerService,
    private bsModalService: BsModalService,
    private modalBackdropService: ModalBackdropService,
    private rabbitMQService: RabbitMQService,
    private eventBroadcastingService: OrderEventBroadcastingService,
    private loggerService: LoggerService,
    private orderPaymentService: OrderPaymentService
  ) {

  }

  ngOnInit() {
    this.settingParam = this.applicationStateService.settingParam;
    this.numpadPaymentOptions.prefix = this.settingParam.CurrencySymbol ?? '$';
    if (this.settingParam.AllowCreditCards && (this.settingParam.CreditCardTerminal.Id != 0 ||
      this.settingParam.CreditCardType === DomainConstants.CreditCardType.Peripheral)) {
      this.isAllowCreditCard = true;
    }
    this.paymentButtonText = Messages.EnterButtonText;
    this.getBalancePriceOfOrder();
    this.peripheralCreditCardMessage = Messages.SwipeCardAndSelectResult;
    this.subscribeToOrderEntryTerminalQueue();
    this.subscribeToHICResponseToTerminal();
    this.subscribeToHICResponseToServerQueue();
  }

  ngOnDestroy(): void {
    if (this.ringupRabbitMQSubscriptions) {
      _.forEach(this.ringupRabbitMQSubscriptions, (subscription) => {
        subscription.unsubscribe();
      });
    }
  }

  getBalancePriceOfOrder() {
    if (this.order) {
      this.dueAmount = cloneDeep(this.order.DueAmount);
      this.typeAmount = cloneDeep(this.order.DueAmount);

    }
  }

  getOrder() {
    this.spinnerService.show();
    this.orderService.getOrderDetails(this.orderId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: OrderInvoice) => {
          this.order = res;
          this.getBalancePriceOfOrder();
          if (!this.dueAmount) {
            this.close.emit({ shouldReload: true });
            // this.eventBroadcastingService.onPaymentCompleted(this.orderId);
          } else {
            this.eventBroadcastingService.onPaymentCompleted(this.orderId);
          }
        }
      });
  }
  makePaymentEBT(e) {
    if (!this.canModifyAmount) {
      return;
    }
    if (this.checkEnteredAmountIsBigger()) {
      return;
    }
    if (this.settingParam.EBTType === DomainConstants.EBTTypes.Peripheral) {
      this.isPaymentByEBT = true;
      this.openPeripheralModel();
    }
  }

  makePaymentGiftCard() {
    if (!this.canModifyAmount) {
      return;
    }
    if (this.checkEnteredAmountIsBigger()) {
      return;
    }
    this.numpadPaymentOptions.disable = true;
    this.numpadOptionChange.next({ value: this.typeAmount, options: this.numpadPaymentOptions });
    const modalRef = this.modalService.getModalWrapper(GiftCardPaymentComponent);
    const modal = modalRef.show({
      keyboard: false,
      animated: false,
      class: 'vertical-center',
      initialState: {
        payableAmount: this.typeAmount,
        orderId: this.orderId
      }
    });
    modal.close.subscribe((response) => {
      if (response && response.shouldReload) {
        this.getOrder();
      }
      this.numpadPaymentOptions.disable = false;
      this.numpadOptionChange.next({ value: this.typeAmount, options: this.numpadPaymentOptions });
    });
  }

  makePayment = (res, isOpenDrawer = true) => {
    this.typeAmount = res.value;
    this.paymentButtonText = 'Close';
    this.canModifyAmount = false;
    if (Number(parseFloat(this.dueAmount.toString()).toFixed(2)) < Number(parseFloat(this.typeAmount.toString()).toFixed(2))) {
      this.numpadPaymentOptions.prefix = `C ${this.settingParam.CurrencySymbol ?? '$'}`;
      setTimeout(() => {
        this.typeAmount = parseFloat((this.typeAmount - this.dueAmount).toFixed(2));
      });
    } else {
      parseFloat((this.typeAmount - this.dueAmount).toFixed(2));
      this.typeAmount = parseFloat((this.dueAmount - this.typeAmount).toFixed(2));
    }
    if (Number(parseFloat(res.value.toString()).toFixed(2)) > Number(parseFloat(this.dueAmount.toString()).toFixed(2))) {
      this.cashTendered = res.value;
    }
    this.numpadPaymentOptions.disable = true;
    this.numpadOptionChange.next({ value: this.typeAmount, options: this.numpadPaymentOptions });
    const payment: any = this.orderPaymentService.preparePaymentDetails(this.orderId, this.order.SurrogateOrderId,
      this.cashTendered ? this.cashTendered - this.typeAmount :
        this.dueAmount - this.typeAmount,
      this.cashTendered, DomainConstants.PaymentTypes.CASH, [], null, false);
    if (isOpenDrawer) {
      this.orderService.openCashDrawer(this.settingParam.CashDrawerPrinter.Id, payment).pipe(finalize(() => {
      }))
        .subscribe({
          next: (response: any) => {
          }, error: this.alertService.showApiError
        });
    }
  }

  cashPayment() {
    const payment: any = this.orderPaymentService.preparePaymentDetails(this.orderId, this.order.SurrogateOrderId,
      this.cashTendered ? this.cashTendered - this.typeAmount :
        this.dueAmount - this.typeAmount,
      this.cashTendered, DomainConstants.PaymentTypes.CASH, [], null, false);
    payment.CashTendered = payment.CashTendered ? payment.CashTendered : payment.Amount;

    this.spinnerService.show();
    this.orderService.cashPayment(this.orderId, payment)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: any) => {
          this.paymentCompleted();
        }, error: this.alertService.showApiError
      });
  }

  paymentCompleted() {
    this.rabbitMQRequestId = '';
    if (this.settingParam.PromptPrintReceipt === true) {
      const printMessage = this.settingParam.ShowOrderNumberAfterPayment ? 'Order number:' + this.order.SurrogateOrderId + '<br/>' +
        Messages.ConfirmPrintOrderReceipt : Messages.ConfirmPrintOrderReceipt;
      const modalRef = this.modalService.show(InfoModalComponent, {
        animated: false,
        class: 'vertical-center',
        initialState: {
          message: printMessage,
          confirmButtonText: 'Yes',
          rejectButtonText: 'No',
          modalHeaderText: 'Confirm'
        }
      });

      modalRef.close.subscribe((res) => {
        if (res && (res.shouldConfirm)) {
        } else {
        }
      });
      if (this.settingParam.ReceiptAutoHideSec > 0) {
        const self = this;
        setTimeout(() => {
          modalRef.close.emit({});
        }, this.settingParam.ReceiptAutoHideSec * 1000);
      }
    }
    if (this.typeAmount) {
      this.numpadPaymentOptions.disable = false;
      this.paymentButtonText = 'Enter';
      this.numpadPaymentOptions.prefix = this.settingParam.CurrencySymbol ?? '$';
      this.numpadOptionChange.next({ options: this.numpadPaymentOptions });
      this.getOrder();
    } else {
      if (this.isServeOrder) {
        this.serveOrder();
      }
      this.rabbitMQService.sendMessageToInvoiceExchange(this.applicationStateService.terminalId, "PaymentCompleted");
      this.close.emit({ shouldReload: true });
    }
    this.canModifyAmount = true;
  }

  cancelPaymentPrice = () => {
    this.numpadPaymentOptions.disable = true;
    this.numpadOptionChange.next({ value: this.typeAmount, options: this.numpadPaymentOptions });
    if (!this.canModifyAmount) {
      const modalRef = this.modalService.getModalWrapper(ConfirmDeleteModalComponent);

      const modal = modalRef.show({
        animated: false,
        keyboard: false,
        class: 'vertical-center',
        initialState: {
          message: Messages.ConfirmCancelPayment,
        }
      }
      );
      modal.close.subscribe((res) => {
        if (res && res.shouldDelete) {
          this.close.emit({ shouldReload: false });
        } else {
          this.canModifyAmount = false;
          // this.getBalancePriceOfOrder();
          this.numpadPaymentOptions.disable = true;
          this.numpadOptionChange.next({ options: this.numpadPaymentOptions });
        }
      })
    } else {
      this.close.emit();
    }
    // }

  }

  checkEnteredAmountIsBigger() {
    this.typeAmount = this.ringupNumpad.numpad.value;
    if (this.typeAmount > this.dueAmount) {
      const self = this;
      setTimeout(() => {
        self.typeAmount = self.dueAmount;
        self.numpadOptionChange.next({ value: self.typeAmount, options: self.numpadPaymentOptions });
        this.ringupNumpad.numpad.validateValue(self.typeAmount);
      });
      return true;
    }
  }
  submitCreditCardPayment = () => {
    if (!this.canModifyAmount) {
      return;
    }
    if (this.checkEnteredAmountIsBigger()) {
      return;
    }
    if (this.settingParam.AllowCreditCards) {
      if (this.settingParam.CreditCardType === DomainConstants.CreditCardType.Peripheral) {
        this.isPaymentByEBT = false;
        this.openPeripheralModel();
      } else {
        this.makeCreditCardPayment();
      }
    }
  }

  openPeripheralModel() {
    const modalRef = this.modalService.getModalWrapper(PeripheralModelComponent);
    const modal = modalRef.show({
      keyboard: false,
      animated: false,
      class: 'vertical-center',
      initialState: {
        peripheralCreditCardMessage: this.peripheralCreditCardMessage
      }
    });
    modal.close.subscribe((response) => {
      if (response && response.approve) {
        this.onPeripheralApproved();
      } else {
        this.onPeripheralCreditCardCancel();
      }
    });
  }

  makeCreditCardPayment = () => {
    const enteredAmount = this.typeAmount;
    this.makePayment({ value: this.typeAmount }, false);
    this.paymentButtonText = '';
    this.creditCardPaymentResponseHeader = 'Info';
    this.creditCardPaymentResponseMessage = Messages.ConnectingCreditCardTerminal;
    this.creditCardPaymentResponseButtonText = 'Cancel';
    this.setRabbitMQRequestId(this.orderId);
    const payment: any = this.orderPaymentService.preparePaymentDetails(this.orderId, this.order.SurrogateOrderId,
      enteredAmount,
      this.cashTendered, DomainConstants.PaymentTypes.CREDITCARD, [], this.rabbitMQRequestId, false);
    payment.CreditCardTerminalId = this.settingParam.CreditCardTerminal.Id;
    this.typeAmount = enteredAmount;
    this.numpadPaymentOptions.disable = true;
    this.numpadOptionChange.next({ options: this.numpadPaymentOptions });
    this.creditCardResponseRef = this.bsModalService.show(this.creditCardResponseModel, {
      'backdrop': 'static',
      'class': 'vertical-center',
      'keyboard': false
    });
    // this.spinnerService.show();
    this.modalBackdropService.addBackDrop();
    this.orderService.integratedCreditCardPayment(this.orderId, payment)
      .pipe(finalize(() => {
        // this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: any) => {
        }, error: (error) => {
          if (error && error.error && error.status === HttpStatusCodes.BadRequest) {
            this.orderAmountMisMatchError(error);
          } else {
            this.alertService.showApiError(error);
          }
        }
      });
  }

  private orderAmountMisMatchError(error: any) {
    this.creditCardPaymentResponseHeader = 'Info';
    this.creditCardPaymentResponseMessage = error.error;
    this.creditCardPaymentResponseButtonText = 'Cancel';
    this.isShowCCError = true;
  }

  onPeripheralApproved() {
    const enteredAmount = this.typeAmount;
    this.makePayment({ value: this.typeAmount }, false);
    this.paymentButtonText = '';
    this.typeAmount = this.typeAmount - this.dueAmount;
    const payment: any = this.orderPaymentService.preparePaymentDetails(this.orderId, this.order.SurrogateOrderId, enteredAmount,
      this.cashTendered, DomainConstants.PaymentTypes.CREDITCARD, [], null, false);
    payment.CreditCardTerminalId = this.settingParam.CreditCardTerminal.Id;
    this.typeAmount = enteredAmount;
    this.numpadPaymentOptions.disable = true;
    this.numpadOptionChange.next({ options: this.numpadPaymentOptions });
    this.spinnerService.show();
    if (this.isPaymentByEBT) {
      this.orderService.peripheralEBTPayment(this.orderId, payment)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (response: any) => {
            this.onPeripheralCompleted();
          }, error: this.alertService.showApiError
        });
    }
    else {
      this.orderService.peripheralCreditCardPayment(this.orderId, payment)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (response: any) => {
            this.onPeripheralCompleted();
          }, error: this.alertService.showApiError
        });
    }
  }
  onPeripheralCompleted() {
    this.paymentCompleted();
  }
  onPeripheralCreditCardCancel() {
    this.numpadPaymentOptions.disable = false;
    this.numpadOptionChange.next({ options: this.numpadPaymentOptions });
  }

  subscribeToHICResponseToServerQueue() {
    this.rabbitMqHICResponseToServerSubscription = this.rabbitMQService.subscribeToHICCreditCardTransactionResponseMessage$()
      .pipe(filter(message =>
        message?.Payload?.CreditCardTransactionRequest?.TerminalId === this.applicationStateService.terminalId &&
        message?.Payload?.CreditCardTransactionRequest?.CreditCardPaymentRequest?.OrderID === this.orderId))
      .subscribe({
        next: (message: any) => {
          if (message?.Payload?.CreditCardTransactionResult?.Result === 'Ok') {
            this.creditCardPaymentResponseMessage = Messages.PaymentRecording;
          }
        }, error: () => {
          this.loggerService.logError('RINGUP: error while subscribe subscribeToHICResponseToServerQueue');
        }
      });
    if (this.rabbitMqHICResponseToServerSubscription) {
      this.ringupRabbitMQSubscriptions.push(this.rabbitMqHICResponseToServerSubscription);
    }
  }

  subscribeToOrderEntryTerminalQueue() {
    this.rabbitMqOrderEntryTerminalQueueSubscription = this.rabbitMQService.subscribeToOrderPaymentResponseTerminalQueue$(this.applicationStateService.terminalId)
      .subscribe({
        next: (message: any) => {
          this.creditCardPaymentCompleted(message.Payload.PaymentResponse);
        }, error: () => {
          this.loggerService.logError('RINGUP: error while subscribe subscribeToOrderEntryTerminalQueue');
        }
      });
    if (this.rabbitMqOrderEntryTerminalQueueSubscription) {
      this.ringupRabbitMQSubscriptions.push(this.rabbitMqOrderEntryTerminalQueueSubscription);
    }
  }

  subscribeToHICResponseToTerminal() {
    this.rabbitMqHICResponseSubscription = this.rabbitMQService.subscribeToHICResponseToTerminal$(this.applicationStateService.terminalId)
      .subscribe({
        next: (message: any) => {
          const response = message.Payload.HICReponse;
          if (response.HardwareType == 'CreditCardTerminal') {
            this.creditCardPaymentResponseHeader = 'Info';
            if (response.Status == DomainConstants.HICResponseStatus.Connected) {
              this.creditCardPaymentResponseMessage = Messages.SwipeCardRequest;
            } else if (response.Status == DomainConstants.HICResponseStatus.Busy) {
              if (response.ConnectedToTerminalId == this.applicationStateService.terminalId) {
                this.creditCardPaymentResponseMessage = Messages.CreditCardTerminalBusyWithPreviousRequest;
              } else {
                this.creditCardPaymentResponseMessage = StringUtils.format(
                  Messages.CreditCardTerminalBusy, {
                  'terminalName': response.ConnectedToTerminalName,
                  'creditCardTerminalName': response.DeviceName
                });
              }
              this.creditCardPaymentResponseButtonText = 'Ok';
              this.rabbitMQRequestId = '';
            } else if (response.Status == DomainConstants.HICResponseStatus.Error) {
              this.creditCardPaymentResponseMessage = response.Message;
              this.creditCardPaymentResponseButtonText = 'Ok';
              this.rabbitMQRequestId = '';
            }
          }
        }, error: () => {
          this.loggerService.logError('RINGUP: error while subscribe subscribeToHICResponseToTerminal');
        }
      });
    if (this.rabbitMqHICResponseSubscription) {
      this.ringupRabbitMQSubscriptions.push(this.rabbitMqHICResponseSubscription);
    }
  }

  creditCardPaymentCompleted(response) {
    if (response && response.PaymentCompleted) {
      this.creditCardPaymentResponseMessage = response.PaymentResponseFromWeb.Message;
      this.rabbitMQRequestId = '';
      this.creditCardPaymentResponseButtonText = 'Ok';
      if (response.PaymentSuccessfullyComplete) {
        this.creditCardPaymentResponseHeader = Messages.Success;
        const self = this;
        setTimeout(() => {
          self.creditCardResponseRef.hide();
          this.modalBackdropService.removeBackdrop();
        }, 2500);
        if (this.settingParam.CardSignaturePrintReceipts) {
        } else {
          this.paymentCompleted();
        }
      } else {
        this.creditCardPaymentResponseHeader = Messages.Error;
      }
    }
  }

  hideOverPaymentModal = () => {
    this.creditCardResponseRef?.hide();
    this.modalBackdropService.removeBackdrop();
    if (this.isShowCCError) {
      this.canModifyAmount = true;
      this.isShowCCError = false;
    }
    this.numpadPaymentOptions.disable = false;
    this.paymentButtonText = 'Enter';
    this.numpadPaymentOptions.prefix = this.settingParam.CurrencySymbol ?? '$';
    this.numpadOptionChange.next({ options: this.numpadPaymentOptions });
  }

  cancelCreditCardPayment() {
    this.creditCardResponseRef.hide();
    this.modalBackdropService.removeBackdrop();
    if (this.isShowCCError) {
      this.canModifyAmount = true;
      this.isShowCCError = false;
    }
    this.numpadPaymentOptions.disable = false;
    this.paymentButtonText = 'Enter';
    this.numpadPaymentOptions.prefix = this.settingParam.CurrencySymbol ?? '$';
    this.numpadOptionChange.next({ options: this.numpadPaymentOptions });
    this.getOrder();
  }

  setRabbitMQRequestId(orderId: number) {
    this.rabbitMQRequestId = this.rabbitMQService.guidWithTimeStamp();
  }

  cancelCreditCardTransaction() {
    this.canModifyAmount = true;
    if (this.rabbitMQRequestId !== '') {
      const cancelRequestModel = {
        TerminalId: this.applicationStateService.terminalId,
        TerminalName: this.applicationStateService.terminalName,
        CreditCardTerminalId: this.settingParam.CreditCardTerminal.Id,
        OrderId: this.orderId,
        RequestId: this.rabbitMQRequestId
      };
      this.spinnerService.show();
      this.orderService.cancelCreditCardPayment(cancelRequestModel)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (response: any) => {
            this.rabbitMQRequestId = '';
            this.getOrder();
          }, error: (e) => {
            if (e && e.error && e.status == HttpStatusCodes.BadRequest) {
              const modalRef = this.modalService.show(InfoModalComponent, {
                animated: false,
                class: 'vertical-center',
                keyboard: false,
                initialState: {
                  message: e.error,
                }
              });
            } else {
              this.alertService.showApiError(e)
            }
          }
        });
    }
    this.cancelCreditCardPayment();
  }


  serveOrder() {
    // this.spinnerService.show();
    if (this.orderId) {
      this.orderService.serveOrder(this.orderId, this.applicationStateService.userDetails.id)
        .pipe(finalize(() => {
          // this.spinnerService.hide();
        }))
        .subscribe({
          next: (res: OrderInvoice) => {
            this.eventBroadcastingService.onStartNewOrderFromOutSide();
          }
        });
    }
  }
}
