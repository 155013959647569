import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GridColumn, TableComponent } from '@tarktech/tark-ng-utils';
import { remove } from 'lodash';
import { AlertsService, SpinnerService, ModalService, ApplicationStateService, PrintTableService, StringUtils, DomainConstants,
  AuthenticationService, Permissions, Levels, hardwareInterfacePlug } from 'src/app/shared';
import { BaseListComponent } from 'src/app/shared/components/base-list.component';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { ExportConnection } from '../../interface';
import { ExportConnectionService } from '../../service/export-connection.service';

@Component({
  selector: 'pos-export-connection',
  templateUrl: './export-connection.component.html',
  styleUrls: ['./export-connection.component.scss']
})
export class ExportConnectionComponent extends BaseListComponent<ExportConnection> implements OnInit {

  exportConnectionList: Array<ExportConnection> = [];
  exportConnectionColumns: Array<GridColumn> = [];
  permission = {
    name: Permissions.AutomatedExportsConfiguration,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };
  icons = { hardwareInterfacePlug };
  @ViewChild('exportConnectionTable', { static: true }) exportConnectionTable: TableComponent;

  constructor(private authenticationService: AuthenticationService,
    protected exportConnectionService: ExportConnectionService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    private router: Router,
    protected applicationStateService: ApplicationStateService,
    protected printService: PrintTableService,
    protected route: ActivatedRoute
  ) {
    super(exportConnectionService, alertService, spinnerService, modalService, applicationStateService, printService, route);
  }

  ngOnInit(): void {
    this.loadSubscriptions();
    this.exportConnectionColumns = this.configureGridColumns();
    this.deleteSuccessMessage = Messages.ExportConnectionDeleted;
    this.checkPermission();
    this.exportConnectionTable.context = this.gridContext;
  }

  private checkPermission(): void {
    if (!this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      remove(this.exportConnectionColumns, (column) => {
        return (column.Type === DomainConstants.GridColumnsTypes.EditColumn
          || column.Type === DomainConstants.GridColumnsTypes.DeleteColumn);
      });
    }
  }

  loadSubscriptions(): void {
    this.data$.subscribe({ next: (res) => {
      this.exportConnectionList = res;
    }});
  }

  getGridColumns(): GridColumn[] {
    return [
      new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '50%' }),
      new GridColumn({ HeaderText: 'Connection Type', Field: 'ConnectionType', IsSortable: true, Width: '42%' })
    ] as Array<GridColumn>;
  }

  editItem(id: number, data?: ExportConnection): void {
    this.router.navigate([id], { relativeTo: this.route });
  }

  public getConfirmDeleteMessage(data: ExportConnection): string {
    return StringUtils.format(Messages.ConfirmDeleteExportConnection, { 'connectionName': data.Name });
  }

  close() {
    this.router.navigate(['manage/automated-exports/configuration']);
  }

}
