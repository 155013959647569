import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertsService, SpinnerService, ApplicationStateService, SettingParam, FieldInfoMessages, DomainConstants, Messages, FormUtilityService, BaseFormComponent } from 'src/app/shared';
import { TaxonomyService } from '../../services/taxonomy.service';
import { finalize } from 'rxjs/operators';
import { star, checkCircleSolid, clock, cogs, rocket, magic, taxonomyFileWord } from '../../../../shared/components/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { InvoiceAreaButtonText } from 'src/app/shared/interface/InvoiceAreaButtonText';
import { forEach } from 'lodash';
import { NgForm } from '@angular/forms';
import { PostSavedConfigurationService } from 'src/app/configurator/post-saved-configuration.service';

@Component({
  selector: 'pos-taxonomy',
  templateUrl: './taxonomy.component.html',
})
export class TaxonomyComponent extends BaseFormComponent implements OnInit {
  @ViewChild('taxonomyForm') taxonomyForm: NgForm;
  get getForm(): NgForm {
    return this.taxonomyForm
  }
  parameters: SettingParam;
  invoiceAreaButtonText: InvoiceAreaButtonText;
  fieldInfoMessages = FieldInfoMessages;
  defaultTerms = DomainConstants.DefaultTerms;
  orderStatusStates = DomainConstants.MakeTableStates;
  defaultTerm = null;

  icons = {
    star, checkCircleSolid, clock, cogs, rocket, magic,
    taxonomyFileWord
  };

  constructor(private alertsService: AlertsService,
    private taxonomyService: TaxonomyService,
    private spinnerService: SpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private postSavedConfigurationService: PostSavedConfigurationService,
    private applicationStateService: ApplicationStateService,
    formUtilityService: FormUtilityService) {
    super(formUtilityService);
    this.parameters = applicationStateService.newSettingParams();
    this.invoiceAreaButtonText = taxonomyService.newInvoiceAreaButtonText();
  }

  ngOnInit() {
    this.loadParameters();
  }

  loadParameters() {
    this.spinnerService.show();
    this.taxonomyService.getTaxonomyDetail(this.applicationStateService.terminalId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: SettingParam) => {
          this.parameters = response;
          this.prepareIconAndFieldInfo();
          if (this.parameters.InvoiceAreaButtonText) {
            this.invoiceAreaButtonText = JSON.parse(this.parameters.InvoiceAreaButtonText);
            this.setInvoiceAreaButtonText();
          }
        }
      });
  }

  setInvoiceAreaButtonText() {
    const invoiceAreaButtonText = this.taxonomyService.newInvoiceAreaButtonText();
    if (!this.invoiceAreaButtonText.SendOrder) {
      this.invoiceAreaButtonText.SendOrder = invoiceAreaButtonText.SendOrder;
    }
    if (!this.invoiceAreaButtonText.CancelOrder) {
      this.invoiceAreaButtonText.CancelOrder = invoiceAreaButtonText.CancelOrder;
    }
    if (!this.invoiceAreaButtonText.ReviewOrder) {
      this.invoiceAreaButtonText.ReviewOrder = invoiceAreaButtonText.ReviewOrder;
    }
    if (!this.invoiceAreaButtonText.SettleOrder) {
      this.invoiceAreaButtonText.SettleOrder = invoiceAreaButtonText.SettleOrder;
    }
  }

  saveTaxonomy() {
    this.spinnerService.show();
    this.prepareParameter();
    this.taxonomyService.saveTaxonomy(this.parameters)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.alertsService.renderSuccessMessage(Messages.TaxonomySaveSuccess);
          this.postSavedConfigurationService.showMessage();
        }
      });
  }

  prepareParameter() {
    let invoiceAreaButtonText = {};
    forEach(this.invoiceAreaButtonText, (value: string, key) => {
      invoiceAreaButtonText = { ...invoiceAreaButtonText, [key]: value };
    });
    this.parameters.InvoiceAreaButtonText = JSON.stringify(invoiceAreaButtonText);
  }

  changeDefaultTerms() {
    if (this.defaultTerm === 'Restaurant') {
      this.parameters.AccountTerm = 'Table';
      this.parameters.SubaccountTerm = 'Seat';
      this.parameters.SessionTerm = 'Visit';
      this.parameters.ServerTerm = 'Server';
    }
    if (this.defaultTerm === 'Retail') {
      this.parameters.AccountTerm = 'Account';
      this.parameters.SubaccountTerm = 'Member';
      this.parameters.SessionTerm = 'Invoice';
      this.parameters.ServerTerm = 'Associate';
    }
    if (this.defaultTerm === 'Campground') {
      this.parameters.AccountTerm = 'Lot';
      this.parameters.SubaccountTerm = '';
      this.parameters.SessionTerm = 'Stay';
      this.parameters.ServerTerm = 'Associate';
    }
  }

  prepareIconAndFieldInfo() {
    forEach(this.parameters.OrderStatusStates, state => {
      switch (state.maketableWorkflowStates.Code) {
        case this.orderStatusStates.PENDING:
          state.FieldInfo = this.fieldInfoMessages.OrderStatusPending;
          state.Icon = this.icons.star;
          break;
        case this.orderStatusStates.DONE:
          state.FieldInfo = this.fieldInfoMessages.OrderStatusDone;
          state.Icon = this.icons.checkCircleSolid;
          break;
        case this.orderStatusStates.COOKING:
          state.FieldInfo = this.fieldInfoMessages.OrderStatusCooking;
          state.Icon = this.icons.clock;
          break;
        case this.orderStatusStates.RETRIEVING_INGREDIENTS:
          state.FieldInfo = this.fieldInfoMessages.OrderStatusRetrievingIngredients;
          state.Icon = this.icons.cogs;
          break;
        case this.orderStatusStates.READY:
          state.FieldInfo = this.fieldInfoMessages.OrderStatusReady;
          state.Icon = this.icons.rocket;
          break;
        case this.orderStatusStates.CRAFTING:
          state.FieldInfo = this.fieldInfoMessages.OrderStatusCrafting;
          state.Icon = this.icons.magic;
          break;
      }
    });
  }
  cancel() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
