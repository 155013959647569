import { TokenService } from '../services/token.service';
import { environment } from 'src/environments/environment';

export function jwtOptionsFactory(tokenService: TokenService) {
  const apiDomain = environment.api.basePath ? getHost(environment.api.basePath) : getHost(location.href);
  const authEndpoint = `${apiDomain}${environment.api.url}auth`;
  return {
    tokenGetter: () => {
      return tokenService.getAsyncToken();
    },
    allowedDomains: [apiDomain],
    skipWhenExpired: true,
    disallowedRoutes: [
      authEndpoint,
      `/${escapeUrlForRegex(apiDomain)}\/(?!api\/).*/`
    ]
  };

  function getHost(baseUrl: string): string {
    return new URL(baseUrl).host;
  }

  function escapeUrlForRegex(url: string): string {
    return url.replace(':', '\:');
  }
}
