import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { DomainConstants, FilterConfiguration } from 'src/app/shared';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';
import { ScreenHistory } from '../interface/screen-history';

@Injectable({
    providedIn: 'root'
})
export class ScreenHistoryService extends BaseCrudService<ScreenHistory> {

    constructor(httpService: HttpClient) {
        super('screen-history', httpService);
    }

    getNewScreenHistory(): ScreenHistory {
        return {
            Id: 0,
            ScreenId: null,
            Name: null,
            StartForm: false,
            SizeForm: false,
            MinSelections: null,
            MaxSelections: null,
            DefaultNextScreenId: null,
            PrintOnOrderReceipt: false,
            ColorName: null,
            BackgroundImage: null,
            ButtonSize: DomainConstants.ButtonSize.Normal,
            Layout: 'Fluid',
            ColumnCount: null,
            DateAdded: new Date(Date.now()),
            DateEdited: new Date(Date.now()),
            ScreenDateAdded: new Date(Date.now()),
            ScreenDateEdited: new Date(Date.now()),
            IsNotified: true,
            UserId: 0
        };
    }

    getScreenHistoryByScreenId(screenId: number): Observable<Array<ScreenHistory>> {
        return this.httpService.get<Array<ScreenHistory>>(`${this.apiEndpoint}/screen/${screenId}`);
    }

    getScreenHistories(productId: number, filterConfiguration: FilterConfiguration): Observable<Array<ScreenHistory>> {
        return this.httpService.post<Array<ScreenHistory>>(`${this.apiEndpoint}/all/${productId}`,
            filterConfiguration);
    }

    getTotalRecordsCount(screenId: number): Observable<number> {
        return this.httpService.get<number>(`${this.apiEndpoint}/count/${screenId}`);
    }

}
