import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridColumn, TableComponent, TemplateColumn } from '@tarktech/tark-ng-utils';
import { BaseListComponent, ModalService, SpinnerService } from 'src/app/shared/components';
import { Levels, Messages, Permissions } from 'src/app/shared/constants';
import { AlertsService, ApplicationStateService, ExportService, PrintTableService } from 'src/app/shared/services';
import { StringUtils } from 'src/app/shared/string-utils';
import { InventoryVendorProfile } from '../../interface';
import { InventoryVendorProfileService } from '../../service/inventory-vendor-profile.service';
import { plusWhite, printWhite, editWhite, deleteWhite, clipboardCheck, sortNumericUp } from 'src/app/shared/components/icon';
import { InventoryVendorProfileProductService } from '../../service';
import { finalize } from 'rxjs/operators';
import { ConfirmDeleteModalComponent } from 'src/app/shared/components/confirm-delete-modal/confirm-delete-modal.component';
import { GridContextInfo } from 'src/app/shared';

@Component({
  selector: 'pos-inventory-vendor-profiles',
  templateUrl: './inventory-vendor-profiles.component.html',
  styleUrls: ['./inventory-vendor-profiles.component.scss']
})
export class InventoryVendorProfilesComponent extends BaseListComponent<InventoryVendorProfile> implements OnInit {

  vendorId: number = null;
  inventoryVendorProfiles: Array<InventoryVendorProfile> = [];
  vendorProfileColumns: Array<GridColumn> = [];
  printProfileColumns: Array<GridColumn> = [];

  vendorName = '';
  permission = {
    name: Permissions.InventoryVendors,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };
  icons = {
    plusWhite, printWhite, editWhite, deleteWhite, clipboardCheck, sortNumericUp
  };

  vendorsGridContext: GridContextInfo = null;
  vendorsActive: boolean = true;
  @ViewChild('profileGrid', { static: true }) profileGrid: TableComponent;
  @ViewChild('nameTemplate', { static: true }) nameTemplate: TemplateRef<any>;
  @ViewChild('operationTemplate', { static: true }) operationTemplate: TemplateRef<any>;
  @ViewChild('operationHeaderTemplate', { static: true }) operationHeaderTemplate: TemplateRef<any>;

  constructor(protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected inventoryVendorProfileService: InventoryVendorProfileService,
    protected inventoryVendorProfileProductService: InventoryVendorProfileProductService,
    protected modalService: ModalService,
    protected applicationStateService: ApplicationStateService,
    protected printService: PrintTableService,
    private exportService: ExportService,
    private router: Router,
    protected route: ActivatedRoute
  ) {
    super(inventoryVendorProfileService, alertService, spinnerService, modalService, applicationStateService, printService, route);
    this.vendorId = parseInt(route.snapshot.params.id, 10) ?? 0;
    const navigation = router.getCurrentNavigation();
    this.vendorName = navigation?.extras?.state?.vendorName ? navigation?.extras?.state.vendorName : '';
    this.vendorsGridContext = navigation?.extras?.state?.GridContext ?? null;
    this.gridContext = navigation?.extras?.state?.ProfileGridContext ?? this.gridContext;
    this.vendorsActive = navigation?.extras?.state?.Active ?? true;
  }

  ngOnInit(): void {
    this.deleteSuccessMessage = Messages.InventoryVendorProfileDeleted;
    this.vendorProfileColumns = this.configureGridColumns();
    this.profileGrid.context = this.gridContext;
  }

  reload(): void {
    this.spinnerService.show();
    this.inventoryVendorProfileService.getAllProfiles(this.vendorId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.inventoryVendorProfiles = res;
          this.vendorName = this.inventoryVendorProfiles.length ? this.inventoryVendorProfiles[0].InventoryVendor.Name : this.vendorName;
        }
      });
  }

  getGridColumns(): Array<GridColumn> {
    return [];
  }

  editItem(id: number, data?: InventoryVendorProfile): void {
    this.router.navigate([id], { relativeTo: this.route, state: { vendorName: this.vendorName, GridContext: this.vendorsGridContext, ProfileGridContext: this.gridContext, Active: this.vendorsActive } });
  }

  getConfirmDeleteMessage(data: InventoryVendorProfile): string {
    return null;
  }

  cancel() {
    this.router.navigate(['vendors'], { relativeTo: this.route.parent, state: { GridContext: this.vendorsGridContext, Active: this.vendorsActive }});
  }

  configureGridColumns() {

    const operationColumn = new TemplateColumn({
      itemTemplate: this.operationTemplate,
      Width: '148px',
      headerTemplate: this.operationHeaderTemplate,
      CellClass: 'cell-padding'
    });

    const columns = [
      new TemplateColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '90%', itemTemplate: this.nameTemplate }),
    ];

    this.printProfileColumns = [...columns];
    columns.push(operationColumn);

    return columns;
  }

  deleteInventoryVendorProfile(data: InventoryVendorProfile): void {
    const modalRef = this.modalService.getModalWrapper(ConfirmDeleteModalComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      initialState: {
        message: StringUtils.format(Messages.ConfirmDeleteInventoryVendorProfile,
          { 'profileName': data ? data.Name : '' })
      }
    });
    modal.close.subscribe(res => {
      if (res?.shouldDelete) {
        this.delete(data.Id);
      }
    });
  }

  applyProfile(profileId: number) {
    this.spinnerService.show();
    this.inventoryVendorProfileProductService.applyProfile(profileId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.alertService.renderSuccessMessage(Messages.InventoryVendorProfileApplied);
          this.reload();
        }, error: this.alertService.showApiError
      });
  }

  confirmApplyProfile(profile: InventoryVendorProfile) {
    const modalRef = this.modalService.getModalWrapper(ConfirmDeleteModalComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      initialState: {
        message: StringUtils.format(Messages.ConfirmApplyInventoryVendorProfile,
          { 'profileName': profile ? profile.Name : '' })
      }
    });
    modal.close.subscribe(res => {
      if (res?.shouldDelete) {
        this.applyProfile(profile.Id);
      }
    });
  }

  printDiv() {
    this.printService.printEmitter.next({ gridColumns: this.printProfileColumns, gridData: this.inventoryVendorProfiles });
  }

  exportCSV() {
    this.exportService.exportCSV('vendor-profile', this.inventoryVendorProfiles, null, this.printProfileColumns);
  }

}
