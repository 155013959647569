
import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertsService, FormUtilityService, ModalFormComponent, ModalService, SpinnerService } from 'src/app/shared';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { CommentWarning } from '../../interface/comment-warning';
import { CommentWarningService } from '../../../../shared/services/comment-warning-service';

@Component({
  selector: 'pos-comment-warning-edit',
  templateUrl: './comment-warning-edit.component.html'
})
export class CommentWarningEditComponent extends ModalFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.form
  }

  @ViewChild('commentWarningForm', { static: true }) form: NgForm;
  commentWarningId: number;
  commentWarningData: CommentWarning;
  props = {
    controlBoxClass: 'col-lg-8 col-md-9 col-sm-12 col-xs-12'
  }

  constructor(
    private commentWarningService: CommentWarningService,
    commentModalRef: BsModalRef,
    modalService: ModalService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    formUtilityService: FormUtilityService) {
    super(commentModalRef, modalService, formUtilityService);
    this.commentWarningData = this.commentWarningService.newCommentWarning();
  }

  ngOnInit() {
    this.getCommentWarningDetails(this.commentWarningId);
    this.setInitialValue();
  }

  getCommentWarningDetails(id: number) {
    if (id > 0) {
      this.spinnerService.show();
      this.commentWarningService.get(id).subscribe(
        response => {
          this.commentWarningData = response;
        },
        this.alertService.showApiError,
        () => {
          this.spinnerService.hide();
        }
      );
    } else {
      this.commentWarningData = this.commentWarningService.newCommentWarning();
    }
  }


  saveCommentWarning(isValid: boolean) {
    if (!isValid) {
      return;
    }

    this.spinnerService.show();

    if (this.commentWarningId > 0) {
      this.commentWarningService.update(this.commentWarningData).subscribe(
        () => {
          this.alertService.renderSuccessMessage(Messages.CommentWarningSaveSuccess);
          this.onCancel(true);
        },
        this.alertService.showApiError,
        () => {
          this.spinnerService.hide();
        }
      );
    } else {
      this.commentWarningService.save(this.commentWarningData).subscribe(
        () => {
          this.alertService.renderSuccessMessage(Messages.CommentWarningSaveSuccess);
          this.onCancel(true);
        },
        this.alertService.showApiError,
        () => {
          this.spinnerService.hide();
        }
      );
    }
  }

  onCancel(reload: boolean) {
    this.hide({ shouldReload: reload });
  }

}
