<div>
  <button data-dismiss="modal" aria-label="Close" class="close" type="button" (click)="onClose()">
    &times;
  </button>
  <h4 class="modal-content__heading">Settle</h4>
</div>
<div class="p-pix-10 pb-0">
  <div class="overflow-hidden">
    <div class="row mx-0 screen-scroll">
      <div class="col-xl-7 col-lg-12 col-xs-12 p-0 row mx-0">
        <div class="col-xl-7 col-lg-6  col-12 p-2 pb-0">
          <fieldset class="fieldset" id="AccountFieldSet">
            <legend class="reset-style bold">
              <span class="bold"> Orders </span>
            </legend>
            <div class="col-12 small-panel panel-overflow" id="settleAccount">
              <pos-settle-accounts *ngIf="accountsList && accountsList.length > 0" [orderId]="orderId"
                [paymentLoader]="paymentLoader" [(accountsList)]="accountsList"
                (accountSelection)="accountSelection($event)">
              </pos-settle-accounts>
            </div>
          </fieldset>
        </div>
        <div class="col-xl-5 col-lg-6 col-12 p-2 pb-0">
          <fieldset class="fieldset">
            <legend class="reset-style bold">
              <span class="bold"> {{subAccountTerm}} </span>
            </legend>
            <div class="col-12 small-panel panel-overflow">
              <pos-settle-seat [seatsDetails]="seats" [isPartialPayment]="isPartialPayment"
                [isSubmitPayment]="isSubmitPayment" [paymentLoader]="paymentLoader"
                (seatSelection)="seatSelection($event)">
              </pos-settle-seat>
            </div>
          </fieldset>
        </div>
        <div class="col-xl-7 col-lg-6 col-12 p-2 pb-0">
          <fieldset class="fieldset" id="OrderFieldSet">
            <legend class="reset-style bold">
              <span class="bold"> Order Invoice</span>
            </legend>
            <div class="col-12 order-panel p-0">
              <pos-settle-order-invoice *ngIf="orderData && seats && seats.length" [(seats)]="seats"
                [orderData]="orderData" [boxHeight]="minHeightOfOrderBox">

              </pos-settle-order-invoice>
            </div>
          </fieldset>
        </div>
        <div class="col-xl-5 col-lg-6 col-12 p-2">
          <div class="col-12">
            <fieldset class="fieldset">
              <legend class="reset-style bold">
                <span class="bold"> Payments</span>
              </legend>
              <div class="col-12 small-panel panel-overflow">
                <pos-settle-payments [paymentsOnOrder]="paymentsOnOrder">
                </pos-settle-payments>
              </div>
            </fieldset>
          </div>
          <div class="col-12 pt-pix-2">
            <fieldset class="fieldset">
              <legend class="reset-style bold">
                <span class="bold"> Splits </span>
              </legend>
              <div class="col-12 panel-overflow p-0">
                <!-- {{dueAmountForSelectedSeats ? dueAmountForSelectedSeats : selectedAccountDetails.GrandTotal}} -->
                <!-- xs-small-panel 
                <settle-seat-splits current-amount="SelectedAccountDetails.Total"
                  print-split="SplitPrintRequestHandler(obj)"></settle-seat-splits>-->
                <pos-settle-seat-splits
                  [(currentBalance)]="dueAmountForSelectedSeats ? dueAmountForSelectedSeats : selectedAccountDetails.GrandTotal"
                  (printSplits)="splitPrintRequestHandler($event)">
  
                </pos-settle-seat-splits>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
      <div class="col-xl-5 col-lg-12 col-xs-12 p-0" id="SettlePayment">
        <div class="col-lg-12 col-md-6 col-12 p-2 pb-0">
          <fieldset class="fieldset" id="NumpadFieldSet">
            <legend class="reset-style bold">
              <span class="bold"> Payment </span>
            </legend>
            <div class="col-md-12 col-12">
              <div class="alert alert-success condensed mb-0" id="divPaymentModalSuccessMsg" style="display: none;"
                *ngIf="orderId">
                {{ringupSuccessMessage}}
              </div>
            </div>
            <div class="col-12 p-0 pb-pix-15 relative">
              <div id="loaderInvoiceDiv" class="payment-loading-block" [ngClass]="isSubmitPayment?'d-block':'d-none'">
                <pos-spinner-icon></pos-spinner-icon>
              </div>

              <pos-ringup-numpad #ringupNumpad [(cashTendered)]="cashTendered"
                [(isShowCashTender)]="dueAmountForSelectedSeats < cashTendered" [(enteredAmount)]="enteredAmount"
                [(numpadOptions)]="numpadPaymentOptions" [paymentButtonText]="paymentButtonText"
                [numpadOptionChange]="numpadOptionChange" [(isSubmitPayment)]="isSubmitPayment"
                [(isDisablePaymentButton)]="numpadPaymentOptions.disable || isSubmitPayment || numpadPaymentOptions.disableCancel"
                [(isShowSubmitButton)]="dueAmountForSelectedSeats < cashTendered" (cancelPayment)="cancelPaymentPrice()"
                (submitPayment)="makeCashPayment($event)" (cashPayment)="cashPayment()"
                (creditCardPayment)="creditCardPayment()" (giftCardPayment)="giftCardPayment()"
                (EBTPayment)="makePaymentEBT($event)"></pos-ringup-numpad>

            </div>
          </fieldset>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-12 action-button-container popup-footer pb-pix-10">
  <button type="button" class="btn btn-primary btn-lg btn-save pos-button" (click)="printSeat()" value="Print">
    Print
  </button>
  <button type="button" class="btn btn-primary btn-lg btn-save pos-button" value="Tables" [disabled]="isSubmitPayment"
  (click)="tableSelection()"> {{tableTerm}}s
</button>
<button type="button" class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="onClose()" value="Close"
[disabled]="isSubmitPayment">Close</button>
</div>
<div class="clearfix"></div>
