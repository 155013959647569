import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { AlertsService, ApplicationStateService } from 'src/app/shared';
import { ICloseable } from 'src/app/shared/components/modal/ICloseable';
import { LightsInfoModel } from '../../../interface/lights-info-model';
import { HardwaresService } from '../../../service/hardware.service';

@Component({
  selector: 'pos-nanoleaf-auth-token-config',
  templateUrl: './nanoleaf-auth-token-config.component.html',
})
export class NanoleafAuthTokenConfigComponent implements ICloseable {

  @Input() typeIdentifier: string;
  @Input() HICId: number;
  @Input() baseUrl: string;
  rabbitMqHICResponseSubscription: Subscription;

  constructor(private hardwareService: HardwaresService,
    private applicationStateService: ApplicationStateService,
    protected alertService: AlertsService) { }
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  cancelEditing() {
    this.close.emit();
  }

  authorize() {
    const authTokenModel: LightsInfoModel = {
      TerminalName: this.applicationStateService.settingParam.TerminalName,
      TerminalId: this.applicationStateService.settingParam.TerminalId,
      TypeIdentifier: this.typeIdentifier,
      HICId: this.HICId,
      AuthToken: null,
      BaseUrl: this.baseUrl,
      PanelId: null
    };
    this.close.emit({ shouldShowLoader: true });
    this.hardwareService.getNanoLeafAuthToken(authTokenModel)
    .subscribe({
      next: (res) => {},
      error: this.alertService.showApiError
    });
  }
}
