import { Injectable } from "@angular/core";
import { IKioskNavigation } from "../interfaces/ikiosk-navigation";
import { ChoiceBehavior } from "./choices-behavior";
import { BehaviorPromiseProviderService, KioskNavigationService, OrderKioskChoiceBehaviorService } from "../services";
import { AlertsService, DomainConstants, Messages, StringUtils } from "src/app/shared";
import { KioskBehaviorExecution } from "./kiosk-behavior-execution";

@Injectable({
    providedIn: 'root'
})
export class AddAutoDiscountBehavior extends ChoiceBehavior {

    constructor(private kioskNavigationService: KioskNavigationService,
        private orderKioskChoiceBehaviorService: OrderKioskChoiceBehaviorService,
        private alertsService: AlertsService,
        private behaviorPromiseProviderService: BehaviorPromiseProviderService) {
        super()
    }

    execute(navObj: IKioskNavigation, orderNavigation: any): Promise<void> {
        if (this.kioskNavigationService.orderDetails?.MarkupPct) {
            this.kioskNavigationService.showInfoMessage(StringUtils.format(Messages.MarkupDiscountRestrict, { 'priceModifier': 'markup' }));
            return Promise.reject("Markup is already applied.");
        }
        if (this.behaviorValues.discountPercent && this.terminalType == DomainConstants.TerminalTypes.ORDER_KIOSK.Name) {
            return this.addDiscount();
        }
        return Promise.resolve();
    }

    addDiscount = (): Promise<any> => {
        // TODO: Validate discount -> MaximumAllowedDiscount
        const model: KioskBehaviorExecution = {
            OrderId: this.kioskNavigationService.orderDetails?.Id?.Value,
            BehaviorId: this.behaviorId
        };
        this.orderKioskChoiceBehaviorService.executeBehavior(model)
            .subscribe({
                next: (order: any) => {
                    this.kioskNavigationService.versionedVal.Obj.Order.Id = order.Id.Value;
                    this.kioskNavigationService.versionedVal.Obj.Order.DiscountPct = order.DiscountPct;
                    this.kioskNavigationService.orderDetails = order;
                    this.behaviorPromiseProviderService.resolvePromise(order);
                },
                error: () => { 
                    this.alertsService.showApiError;
                    this.behaviorPromiseProviderService.rejectPromise();
                }
            });        
        return this.behaviorPromiseProviderService.executionPromise;
    }
}

