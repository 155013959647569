<div *ngIf="settingParam.QuantityChange === HASH_BUTTON">
  <button class="py-pix-6 order-section__summary__buttons__btn"
    [ngClass]="{'px-0 btn-invoice': !isSmallScreen, 'bg-theme-primary': !groupId, 'bg-theme-separator px-pix-7': groupId}" (click)="numpadOpen()">
    <pos-icon [icon]="icons.hashtag"></pos-icon>
  </button>
</div>
<div *ngIf="settingParam.QuantityChange === PLUS_MINUS_BUTTONS">
  <div *ngIf="hasPermission">
    <!-- show -->
    <div [hidden]="!(orderProduct.Status === ORDER_STATUS_PAID && hasPermissionForChange(orderProduct))">
      <button class="py-pix-6 order-section__summary__buttons__btn"
        [ngClass]="{'px-0 btn-invoice': !isSmallScreen, 'bg-theme-primary': !groupId, 'bg-theme-separator px-pix-7': groupId}" (click)="changeQty(orderProduct,0)"
        *ngIf="(userDetails.manager || userDetails.administrator)">
        <pos-icon [icon]="icons.minus"></pos-icon>
      </button>
    </div>
    <!-- && !paidAmount *ngIf="!paidAmount" -->
    <!-- hide -->
    <div [hidden]="orderProduct.Status === ORDER_STATUS_PAID || !hasPermissionForChange(orderProduct)">
      <button class="py-pix-6 order-section__summary__buttons__btn"        
        [ngClass]="{'px-0 btn-invoice': !isSmallScreen, 'bg-theme-primary': !groupId, 'bg-theme-separator px-pix-7': groupId}" (click)="changeQty(orderProduct,0)">
        <pos-icon [icon]="icons.minus"></pos-icon>
      </button>
    </div>
  </div>
  <button class="py-pix-6 order-section__summary__buttons__btn"
    [ngClass]="{'px-0 btn-invoice': !isSmallScreen, 'bg-theme-primary': !groupId, 'bg-theme-separator px-pix-7': groupId}" (click)="changeQty(orderProduct,1)">
    <pos-icon [icon]="icons.plusMessage"></pos-icon>
  </button>
</div>
