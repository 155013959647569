<pos-edit-wrapper [isPopup]="false" [headingIcon]="icons.history"
    heading="{{this.id? 'Edit': 'Add'}} Sales Product History{{this.id? ': ' + salesProductName: ''}}"
    (close)="onCancel()">
    <form #salesProductHistoryForm="ngForm" (ngSubmit)="saveSalesProductHistory(salesProductHistoryForm.valid)"
        autocomplete="off">
        <context-provider provide="props">
            <div class="col-12 pos-page-body border-top screen-scroll pt-pix-5">
                <div class="col-12">
                    <pos-row>
                        <pos-column>
                            <pos-form-field label="Name" [validationMessages]="{required: 'Please enter name.'}">
                                <input posInput class="form-control" name="name" #name="ngModel"
                                    [(ngModel)]="salesProductHistory.Name" [required]="true">
                            </pos-form-field>
                        </pos-column>
    
                        <pos-column>
                            <pos-form-field label="Category" [validationMessages]="{required: 'Please select category.'}">
                                <ng-select posInput name="salesCategory" [items]="salesCategoryList" bindLabel="name"
                                    bindValue="id" #salesCategory="ngModel" appendTo="body"
                                    [(ngModel)]="salesProductHistory.SalesCategoryId" placeholder="Select category"
                                    [required]="true">
                                </ng-select>
                            </pos-form-field>
                        </pos-column>
    
                        <pos-column>
                            <pos-form-field label="Menu Display Text">
                                <input posInput class="form-control" name="displayText" #displayText="ngModel"
                                    [(ngModel)]="salesProductHistory.MenuDisplayText">
                            </pos-form-field>
                        </pos-column>
    
                        <pos-column>
                            <pos-row>
                                <pos-column md="3" sm="6">
                                    <pos-form-field inputStyle="col-xs-12">
                                        <p-checkbox posInput name="active" #active="ngModel" label="Active"
                                            [(ngModel)]="salesProductHistory.IsActive" binary="true">
                                        </p-checkbox>
                                    </pos-form-field>
                                </pos-column>
                                <pos-column md="3" sm="6">
                                    <pos-form-field inputStyle="col-xs-12">
                                        <p-checkbox posInput name="taxable" #taxable="ngModel" label="Taxable"
                                            [(ngModel)]="salesProductHistory.IsTaxable" binary="true">
                                        </p-checkbox>
                                    </pos-form-field>
                                </pos-column>
                            </pos-row>
                        </pos-column>
    
                        <pos-column>
                            <pos-row>
                                <pos-column md="3" sm="6">
                                    <pos-form-field inputStyle="col-xs-12">
                                        <p-checkbox posInput name="timedPrep" #timedPrep="ngModel" class="pe-pix-5"
                                            label="Time Prep" [(ngModel)]="salesProductHistory.IsTimedPrep" binary="true">
                                        </p-checkbox>
                                    </pos-form-field>
                                </pos-column>
                                <pos-column md="3" sm="6">
                                    <pos-form-field inputStyle="col-xs-12">
                                        <p-checkbox posInput name="allowOutOfStockOrder" #allowOutOfStockOrder="ngModel"
                                            class="pe-pix-5" label="Allow Out Of Stock"
                                            [(ngModel)]="salesProductHistory.IsAllowOutOfStockOrder" binary="true">
                                        </p-checkbox>
                                    </pos-form-field>
                                </pos-column>
                            </pos-row>
                        </pos-column>
    
                        <pos-column>
                            <pos-row>
                                <pos-column md="3" sm="6">
                                    <pos-form-field inputStyle="col-xs-12">
                                        <p-checkbox posInput name="skipReadyState" #skipReadyState="ngModel" class="pe-pix-5"
                                            label="Skip Ready State" [(ngModel)]="salesProductHistory.IsSkipReadyState"
                                            binary="true">
                                        </p-checkbox>
                                    </pos-form-field>
                                </pos-column>
                                <pos-column md="3" sm="6">
                                    <pos-form-field inputStyle="col-xs-12">
                                        <p-checkbox posInput name="showInstructions" #showInstructions="ngModel"
                                            class="pe-pix-5" label="Show Instructions"
                                            [(ngModel)]="salesProductHistory.IsShowInstructions" binary="true">
                                        </p-checkbox>
                                    </pos-form-field>
                                </pos-column>
                            </pos-row>
                        </pos-column>
                        <pos-column xs="12">
                            <pos-form-field label="Date" [validationMessages]="{required: 'Please enter edit date.'}"
                                *ngIf="!id">
                                <p-calendar posInput [(ngModel)]="salesProductHistory.DateAdded" [showIcon]="true"
                                    [dateFormat]="dateFormat" [readonlyInput]="true" name="dateEdited" yearNavigator="true"
                                    [inputStyle]="{'width':'unset','display':'unset'}" inputStyleClass="form-control"
                                    yearRange="2000:2099" [showTime]="true" [hourFormat]="hourFormat" #dateEdited="ngModel"
                                    [monthNavigator]=true [required]="true" [maxDate]="today" appendTo="body"
                                    [ngClass]="{'has-error': (dateEdited.invalid && salesProductHistoryForm.submitted)}">
                                </p-calendar>
                            </pos-form-field>
                        </pos-column>
                    </pos-row>
                </div>
            </div>
            <div class="col-12 action-button-container page-footer">
                <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save </button>
                <button type="button" class="btn-secondary btn-lg btn btn-cancel pos-button" (click)="onCancel()">Cancel</button>
            </div>
        </context-provider>
    </form>
</pos-edit-wrapper>
