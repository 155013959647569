import { Messages } from 'src/app/shared/constants/ui-messages';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { AlertsService } from './../../../../shared/services/alerts.service';
import { CentralApiConfigurationService } from './../../service/central-api-configuration.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CentralApiConfiguration } from '../../interface/central-api-configuration';
import { NgForm } from '@angular/forms';
import { ConfiguratorService } from 'src/app/configurator/configurator.service';
import { centralApiConfigurationCogs } from 'src/app/shared/components/icon/icons';
import { BaseFormComponent } from 'src/app/shared/components/base-form.component';
import { FormUtilityService } from 'src/app/shared/services/form-utility.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'pos-central-api-configuration',
  templateUrl: './central-api-configuration.component.html',
})
export class CentralApiConfigurationComponent extends BaseFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.form
  }
  @ViewChild('centralApiConfigForm') form: NgForm;
  centralApiConfig: CentralApiConfiguration;
  icons = {
    centralApiConfigurationCogs
  };
  props = {
    controlBoxClass: 'col-lg-3 col-md-5 col-sm-8 col-xs-12'
  }
  constructor(private centralApiConfigurationService: CentralApiConfigurationService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private configuratorService: ConfiguratorService,
    private router: Router,
    private route: ActivatedRoute,
    formUtilityService: FormUtilityService) {
    super(formUtilityService);
    this.getCentralApiConfiguration();
  }

  ngOnInit() {
    this.centralApiConfig = this.centralApiConfigurationService.newCentralApiConfiguration();
  }

  getCentralApiConfiguration() {
    this.spinnerService.show();
    this.centralApiConfigurationService.getCentralApiConfiguration()
      .subscribe({
        next: (res) => {
          this.centralApiConfig = res;
          this.onFormChange(this.form);
        }, error: this.alertService.showApiError,
        complete: () => {
          this.spinnerService.hide();
        }
      });
  }

  saveCentralApiConfig(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.spinnerService.show();
    this.centralApiConfigurationService.saveCentralApiConfiguration(this.centralApiConfig)
      .subscribe({
        next: (res) => {
          this.alertService.renderSuccessMessage(Messages.CentralApiConfigSaveSuccess);
          this.getCentralApiConfiguration();
          this.configuratorService.startAutoCloseInterval();
        }, error: this.alertService.showApiError,
        complete: () => {
          this.spinnerService.hide();
        }
      });
  }

  onFormChange($event: NgForm) {
    this.form = $event;
    this.setInitialValue();
  }

  onCancel() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
