<pos-edit-wrapper heading="{{ id ? 'Edit' : 'Add' }} Data Source{{ id ? ': ' + sourceName : '' }}" (close)="onCancel()"
  [headingIcon]="icons.database" [isPopup]="false">
  <form #dataSourceForm="ngForm" class="edit-form" (ngSubmit)="submit(dataSourceForm.valid)">
    <div class="col-12 border-top pt-pix-5 report-configuration-parameters">
      <div class="col-12 pos-page-body">
        <pos-row>
          <pos-column>
            <pos-form-field label="Name" [validationMessages]="{ required: 'Please enter name.'}"
              inputStyle="col-md-4 col-12">
              <input posInput type="text" id="dataSourceName" name="dataSourceName" #dataSourceName="ngModel"
                class="form-control" [(ngModel)]="dataSource.Name" [required]="true" />
            </pos-form-field>
          </pos-column>
          <pos-column>
            <pos-form-field label="Type" [validationMessages]="{ required: 'Please select query type.'}"
              inputStyle="col-md-4 col-12">
              <ng-select posInput *ngIf="queryTypes && queryTypes.length" appendTo="body" [items]="queryTypes"
                bindLabel="Name" bindValue="Value" placeholder="Select query type" (change)="onQueryTypeChange()"
                [(ngModel)]="dataSource.QueryType" [clearable]="false" id="selectedRestrictions"
                name="selectedRestrictions" [required]="true">
              </ng-select>
            </pos-form-field>
          </pos-column>
        </pos-row>

        <div class="col-12 px-pix-5 pt-pix-10">
          <div class="col-12 p-0 parameter-parent">
            <ul class="nav nav-tabs inner-tab pos-nav-tabs">
              <li [ngClass]="{'active': selectedTab == tabList.Query }">
                <a (click)="selectedTab = tabList.Query" title="Query" class="blue"
                  [ngClass]="{'required': queryTab.invalid }">
                  <pos-icon [icon]="icons.database" class="theme-separator pe-pix-4"></pos-icon>
                  <span>Query</span>
                </a>
              </li>
              <li [ngClass]="{'active': selectedTab == tabList.Parameters }">
                <a (click)="selectedTab = tabList.Parameters" class="blue">
                  <pos-icon [icon]="icons.list" class="theme-separator pe-pix-4"></pos-icon>
                  <span>Parameters</span>
                </a>
              </li>
            </ul>
          </div>
          <div class="col-12 p-0 pt-pix-5">
            <div [hidden]="selectedTab != tabList.Query" ngModelGroup="queryTab" #queryTab="ngModelGroup">
              <div class="overflow-auto-tabs ps-1 pb-1 width-100" [ngStyle]="{'max-height': height + 'px'}">
                <pos-row>
                  <pos-column>
                    <pos-form-field label="Data Source" *ngIf="!dataSource.QueryType || dataSource.QueryType == 'Sproc'"
                      [validationMessages]="{required: 'Please enter data source.'}">
                      <input posInput type="text" name="dataSourceSproc" #dataSourceName="ngModel" class="form-control"
                        [(ngModel)]="dataSource.DataSource" [required]="true" />
                    </pos-form-field>

                    <pos-form-field label="Data Source" inputStyle="col-12 row"
                      *ngIf="!dataSource.QueryType || dataSource.QueryType == 'POSAPI'"
                      [validationMessages]="{required: 'Please enter url.'}">
                      <span class="col-2 col-220 mb-1">
                        <ng-select [items]="apiVerbs" #apiVerbInput="ngModel" name="apiVerbInput" bindLabel="Name"
                          bindValue="Name" [(ngModel)]="apiVerb" [clearable]="false" appendTo="body"
                          [clearOnBackspace]="false"></ng-select>
                      </span>
                      <span class="col-md-6 col-9">
                        <input posInput type="text" name="url" class="form-control" #url="ngModel" [(ngModel)]="apiURL"
                          placeholder="URL" [required]="true" />
                      </span>
                    </pos-form-field>

                    <pos-form-field label="Data Source" *ngIf="dataSource.QueryType == 'Query'"
                      [validationMessages]="{required: 'Please enter data source.'}" inputStyle="col-12">
                      <ngx-codemirror posInput id="dataSourceQuery" name="dataSourceQuery" class="data-source-query"
                        #dataSourceQuery [(ngModel)]="dataSource.DataSource" [options]="codeMirrorOptions"
                        (cursorActivity)="getCursorPosition($event)" [required]="true">
                      </ngx-codemirror>
                      <span class="me-pix-26 mt-pix-n50 relative" style="float: right">
                        <span class="font-20 pointer grey">
                          <pos-icon [icon]="icons.messageCode" style="z-index: 10;" class="pointer relative"
                            (click)="isOpenVariable = !isOpenVariable">
                          </pos-icon>
                        </span>
                        <div *ngIf="isOpenVariable" class="col-200 variable-dropdown">
                          <ng-select [items]="macroScripts" placeholder="Select script" bindLabel="Name"
                            bindValue="Name" name="eventVariable" [(ngModel)]="selectedParameter"
                            (change)="changeMacroScript()" [isOpen]="true" dropdownPosition="top">
                          </ng-select>
                        </div>
                      </span>
                    </pos-form-field>
                  </pos-column>
                </pos-row>
              </div>
            </div>
            <div [hidden]="selectedTab != tabList.Parameters">
              <div class="overflow-auto-tabs width-100 px-pix-5" [ngStyle]="{'max-height': height + 'px'}">
                <div class="pt-pix-10" *ngIf="isParametersLoaded">
                  <pos-report-configuration-parameters [parameters]="dataSourceParameters" [dataSourceId]="id"
                    [isFormSubmitted]="dataSourceForm.submitted" (reloadParameters)="getParameters()">
                  </pos-report-configuration-parameters>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 action-button-container page-footer">
      <button class="btn normal btn-success btn-lg white me-pix-5" type="submit" (click)="isPreview = true">
        <div class="display-flex">
          <pos-icon class="white pe-pix-5 pt-pix-1" [icon]="icons.fileSearchPreview"></pos-icon>Preview
        </div>
      </button>
      <button type="submit" value="Save" class="btn btn-primary btn-lg btn-save pos-button" scrollup>
        Save
      </button>
      <button type="button" class="btn btn-secondary btn-lg btn-cancel pos-button" value="Cancel" (click)="onCancel()"
        scrollup>
        Cancel
      </button>
    </div>
  </form>
</pos-edit-wrapper>
