import { Component, OnInit, ViewChild } from '@angular/core';
import { SpinnerService, AlertsService, FormUtilityService } from 'src/app/shared';
import { ModalFormComponent, ModalService } from 'src/app/shared/components';
import { InfoLinkSectionsService } from '../../service';
import { InfoLinkSectionsModel } from '../../interface';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'pos-info-link-sections-edit',
  templateUrl: './info-link-sections-edit.component.html',
})
export class InfoLinkSectionsEditComponent extends ModalFormComponent implements OnInit {

  get getForm(): NgForm {
    return this.form
  }
        
  @ViewChild('formInfoLinkSectionEdit', {static: true}) form: NgForm;
  infolinksectionId: number;
  infoLinkSectionDetail: InfoLinkSectionsModel;
  editinfolinksectionId: number;

  constructor(private spinnerService: SpinnerService,
    private infoLinkSectionsService: InfoLinkSectionsService,
    private alertService: AlertsService,
    infoLinkSectionsModalRef: BsModalRef,
    modalService: ModalService,
    formUtilityService: FormUtilityService) {
    super(infoLinkSectionsModalRef, modalService,formUtilityService);
    this.infoLinkSectionDetail = this.infoLinkSectionsService.newInfoLinkSections();
  }

  ngOnInit() {
    this.editinfolinksectionId = this.infolinksectionId;
    this.getInfoLinkSectionDetails();
    this.setInitialValue();
  }

  getInfoLinkSectionDetails() {
    this.spinnerService.show();
    if (this.infolinksectionId > 0) {
      this.infoLinkSectionsService.getInfoLinkSectionDetails(this.infolinksectionId).subscribe(response => {
        this.infoLinkSectionDetail = response;
      },
        (error) => { this.alertService.showApiError(error); },
        () => { this.spinnerService.hide(); }
      );
    } else {
      this.form.resetForm();
      this.infoLinkSectionDetail = this.infoLinkSectionsService.newInfoLinkSections();
      this.spinnerService.hide();
    }
  }

  saveInfoLinkSections(isValid) {
    if (!isValid) {
      return;
    }
    this.spinnerService.show();
    this.infoLinkSectionDetail.Id = this.editinfolinksectionId;
    if (this.editinfolinksectionId > 0) {
      this.infoLinkSectionsService.updateInfoLinkSection(this.infoLinkSectionDetail).subscribe(response => {
        this.resetForm();
        this.hide({ shouldReload: true });
      },
        (error) => { this.alertService.showApiError(error); },
        () => { this.spinnerService.hide(); });
    } else {
      this.infoLinkSectionsService.addInfoLinkSection(this.infoLinkSectionDetail).subscribe(response => {
        this.resetForm();
        this.hide({ shouldReload: true });
      },
        (error) => { this.alertService.showApiError(error); },
        () => { this.spinnerService.hide(); });
    }
  }

  cancelEditing() {
    this.hide({ shouldReload: false });
  }

  resetForm() {
    this.editinfolinksectionId = 0;
  }
}
