import { UserRole } from "./user-roles";

export interface User {
    id: number;
    name: string;
    username: string;
    code: string;
    firstname: string;
    lastname: string;
    FirstName?: string;
    LastName?: string;
    administrator?: boolean;
    DateAdded: Date;
    DateEdited?: Date;
    text_email_address: string;
    active?: boolean;
    manager?: boolean;
    active_delivery?: boolean;
    driver_out?: boolean;
    is_driver?: boolean;
    time_keeping?: boolean;
    EmailAddress: string;
    IsSystemUser?: boolean;
    Password: string;
    IsAllowExternalLogin?: boolean;
    BadgeNumber: string;
    RoleName: string;
    FailedLoginAttemptCount?: number;
    LastFailedAttempt?: Date;
    Roles?: Array<UserRole>;
}
export enum ResetPasswordType {
    UserCode = 'User Code',
    ExternalPassword = 'External Password'
}
