import { Injectable } from '@angular/core';
import { ApplicationStateService } from 'src/app/shared/services';

@Injectable({
  providedIn: 'root'
})
export class OrderPaymentService {

  constructor(private applicationStateService: ApplicationStateService) { }

  preparePaymentDetails(orderId: number, surrogateOrderId: number,
    amount: number, cashTendered: number, paymentTypeId: number, subAccountOrdinals: Array<string>, rabbitMQRequestId: string,
    isPartialPayment: boolean) {
    const paymentDetails: any = {
      Id: 0,
      OrderId: orderId,
      Amount: amount,
      PaymentTypeId: paymentTypeId,
      IsCompleted: false,
      UserId: this.applicationStateService.userDetails.id,
      IsCaptured: false,
      CashDrawerId: this.applicationStateService.settingParam.CashDrawerPrinter.Id,
      CashTendered: cashTendered,
      SubAccountOrdinals: subAccountOrdinals,
      TerminalId: this.applicationStateService.terminalId,
      TerminalName: this.applicationStateService.terminalName,
      SurrogateOrderId: surrogateOrderId,
      RabbitMQRequestId: rabbitMQRequestId,
      IsPartialPayment: isPartialPayment
    };
    return paymentDetails;
  }

}
