import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'pos-numpad-buttons',
  templateUrl: './numpad-buttons.component.html',
  styleUrls: ['./numpad-buttons.component.scss']
})
export class NumpadButtonsComponent implements OnInit {

  @Input() enterButtonText = 'Submit';
  @Input() isShowSubmitButton = true;
  @Output() calculatePrice: EventEmitter<any> = new EventEmitter<any>();
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  @Output() clear: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  calculatePriceClosingDrawer(value) {
    this.calculatePrice.emit({value: value});
  }

  clearPrice() {
    this.clear.emit();
  }

  submit() {
    this.close.emit();
  }

}
