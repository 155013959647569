import { Component, Input, OnInit } from '@angular/core';
import { ImageWidgetStyleConfig, WidgetComponentProperties, WidgetDetails } from '../../interfaces';
import { BaseWidgetComponent } from '../base-widget';
import { RuntimeConstants } from 'src/app/shared/constants';
import { ApplicationStateService } from 'src/app';
import { AlertsService, SpinnerService, ModalService, RabbitMQService } from 'src/app/shared';
import { MenuWidgetStyleService, MenuWidgetService } from '../../services';
import * as $ from 'jquery';

@Component({
  selector: 'pos-menu-video-widget',
  templateUrl: './menu-video-widget.component.html',
})
export class MenuVideoWidgetComponent extends BaseWidgetComponent implements OnInit, WidgetComponentProperties {
  @Input() widgetId: any;
  widgetContentStyle = {};
  filePath = RuntimeConstants.IMAGE_BASE_PATH + '/menu-display';
  widgetStyle: ImageWidgetStyleConfig;
  video: string;
  
  editWidget(id: number, data?: any): void {;
  }

  constructor(protected menuWidgetStyleService: MenuWidgetStyleService,
    protected menuWidgetService: MenuWidgetService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected applicationStateService: ApplicationStateService,
    protected modalService: ModalService,
    protected rabbitMQService: RabbitMQService) {
    super(menuWidgetService, alertService, spinnerService, applicationStateService, modalService, rabbitMQService);
    this.widgetStyle = this.menuWidgetService.getNewImageWidgetStyle();
    this.widgetData = this.menuWidgetService.getNewWidgetDetails();
    const menuItem = this.menuWidgetService.getNewMenuDisplayItem();
    this.widgetData.MenuDisplayItems.push(menuItem);
  }

  ngOnInit(): void {
    this.loadSubscriptions();
    this.loadWidget(this.widgetId);
  }
  private loadSubscriptions(): void {
    this.widgetData$.subscribe({
      next: (res: WidgetDetails) => {
        const menuDisplayItem = res.MenuDisplayItems ? res.MenuDisplayItems : null;
        this.widgetStyle = this.parseJson<ImageWidgetStyleConfig>(res.WidgetStyleConfig);
        this.video = menuDisplayItem[0]?.Video;
        if (this.widgetStyle?.BorderStyle) {
            $('.widget-border-' + this.widgetId)?.css(this.menuWidgetStyleService.getBorderStyle(this.widgetStyle?.BorderStyle));
        }
        $('.widget-background-' + this.widgetId)?.css({ 'background': this.widgetStyle?.WidgetBackgroundColor });
        this.widgetContentStyle = this.menuWidgetStyleService.getWidgetContentStyle(this.widgetStyle);
      }
    });
  }
}
