import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RuntimeConstants } from 'src/app/shared';
import { ServingInformation } from '../interface';

@Injectable()
export class ServicingInformationService {
    constructor(private httpService: HttpClient) {
    }

    getServingInformations(): Observable<ServingInformation[]> {
        return this.httpService.get<ServingInformation[]>(`${RuntimeConstants.API_PATH}servinginformation`);
    }

    getServingInformationByInventoryId(id: number): Observable<any> {
        return this.httpService.get<ServingInformation>(`${RuntimeConstants.API_PATH}servinginformation/` + id);
    }

    getAllMeasures(): Observable<any> {
        return this.httpService.get<ServingInformation>(`${RuntimeConstants.API_PATH}nutritionfactmeasure`);

    }
    servingObject() {
        const servingObj = {
            Id: 0,
            InventoryProductId: 0,
            ServingSize: 0,
            ServingRatioToProduct: 0,
            Calories: 0,
            CaloriesFromFat: 0,
            MeasureId: null,
            ServingsPerContainer: 0,
            DateAdded: new Date()
        };
        return servingObj;
    }
}
