import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';
import { WidgetProductGroups } from '../interfaces/widget-product-group';

@Injectable({
    providedIn: 'root'
})
export class WidgetProductGroupService extends BaseCrudService<WidgetProductGroups> {
    constructor(private httpClient: HttpClient) {
        super('widget-product-group', httpClient);
    }

    getAllProductGroupByWidget(widgetId: number): Observable<Array<WidgetProductGroups>> {
        return this.httpClient.get<Array<WidgetProductGroups>>(this.apiEndpoint + `/${widgetId}/ProductGroups`);
    }

    updateWidgetGroupOrdinal(productGroups: Array<WidgetProductGroups>) {
        return this.httpClient.put<Array<WidgetProductGroups>>(this.apiEndpoint, productGroups);
    }
}
