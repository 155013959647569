import { Component, OnInit, ViewChild } from '@angular/core';
import { SpinnerService } from 'src/app/shared/components/spinner';
import { Messages } from 'src/app/shared/constants';
import { FieldInfoMessages } from 'src/app/shared/constants/field-info-messages';
import { AlertsService, ApplicationStateService, FormUtilityService } from 'src/app/shared/services';
import { GiftCardWorkflowParameter } from '../../interfaces/gift-card-workflow-parameter';
import { GiftCardWorkflowService } from '../../services/gift-card-workflow.service';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { giftCard } from 'src/app/shared/components/icon/icons';
import { NgForm } from '@angular/forms';
import { BaseFormComponent } from 'src/app/shared/components/base-form.component';

@Component({
  selector: 'pos-gift-card-workflow',
  templateUrl: './gift-card-workflow.component.html',
})
export class GiftCardWorkflowComponent extends BaseFormComponent implements OnInit {
  @ViewChild('giftCardWorkflow') giftCardWorkflow: NgForm;
  get getForm(): NgForm {
    return this.giftCardWorkflow
  }
  parameters: GiftCardWorkflowParameter;
  fieldInfoMessages = FieldInfoMessages;
  currencySymbol = null;
  icons = {
    giftCard
  };
  constructor(
    private giftCardWorkflowService: GiftCardWorkflowService,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private applicationStateService: ApplicationStateService,
    private router: Router,
    private route: ActivatedRoute,
    formUtilityService: FormUtilityService
  ) {
    super(formUtilityService);
  }

  ngOnInit(): void {
    this.parameters = this.giftCardWorkflowService.newGiftCardWorkflowConfigurationParameter();
    this.currencySymbol = this.applicationStateService.settingParam.CurrencySymbol;
    this.loadData();
  }

  private loadData() {
    this.spinnerService.show();
    this.giftCardWorkflowService.getGiftCardWorkflowParameters()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (params: GiftCardWorkflowParameter) => {
          this.parameters = params;
        }, error: this.alertService.showApiError
      });
  }

  saveGiftCardWorkflow(isValid: boolean) {
    if (!isValid || this.parameters.GiftCardAutoDeductionAmount < 0 || this.parameters.GiftCardAutoDeductionAfterMonth < 0 ||
      this.parameters.GiftCardAutoDeductionAfterMonth > 12) {
      return;
    }
    this.spinnerService.show();
    this.giftCardWorkflowService.updateGiftCardWorkflowParameters(this.parameters)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.alertService.renderSuccessMessage(Messages.GiftCardWorkflowSaveSuccess);
          this.router.navigate(['/manage/system-configuration']);
        }, error: this.alertService.showApiError
      });
  }

  cancel() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

}
