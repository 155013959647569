import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { ICloseable, AlertsService, SpinnerService, SettingParam, ApplicationStateService, UserDetails } from 'src/app/shared';
import { finalize } from 'rxjs/operators';
import {
  driverReport,
  salesStatisticsReport,
  cashierReport,
  salesReport,
  allSalesReport,
  bars
} from 'src/app/shared/components/icon';
import * as _ from 'lodash';
import { KeyValue, formatDate } from '@angular/common';
import { DriverService } from 'src/app/shared/services/driver.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
declare let $: any;

@Component({
  selector: 'pos-terminal-reports',
  templateUrl: './terminal-reports.component.html',
  styleUrls: ['./terminal-reports.component.scss']
})
export class TerminalReportsComponent implements OnInit, ICloseable {
  close: EventEmitter<any> = new EventEmitter();

  @Input() selectedDriver: any;
  isShowHeader: boolean = false;
  showTotal: boolean = false;
  header: any = {};
  grandTotal: number = 0;
  reportHeader: string = '';
  columns: Array<any> = [];
  reportsHeader = {
    DriverReport: 'Driver Report',
    SalesStatistics: 'Sales Statistics Report',
    CashierReport: 'Cashier Report',
    SalesReport: 'Sales Report',
    AllSales: 'All Sales Report'
  };
  colsLength: number = 0;
  singleLineCols: number = 0;
  settingParam: SettingParam;
  drawerId: number;
  currentUser: UserDetails;
  drawerName: string;
  terminalName: string;
  userName: string;
  taxTotal: number;
  total: number;
  tipTotal: number;
  orderTotal: number;
  fromDate: any;
  toDate: any;
  showHeaderLine: boolean = false;
  reportDetails: SafeHtml = null;
  currentDate: Date = new Date();
  line = '=================================================';
  singleLine = '---------------------------------------------------------------------------------------';
  public icons = {
    driverReport,
    salesStatisticsReport,
    cashierReport,
    salesReport,
    allSalesReport,
    bars
  }
  constructor(private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private driverService: DriverService,
    private applicationStateService: ApplicationStateService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.setDefault();
    this.resetReportsData();
    this.terminalReportBody();
    this.getDriverReport();
  }

  setDefault() {
    this.settingParam = this.applicationStateService.settingParam;
    this.currentUser = this.applicationStateService.userDetails;
    this.drawerId = this.settingParam && this.settingParam.CashDrawerPrinter ? this.settingParam.CashDrawerPrinter.Id : null;
    this.drawerName = this.settingParam && this.settingParam.CashDrawerPrinter ? this.settingParam.CashDrawerPrinter.Name : '';
    this.terminalName = this.settingParam ? this.settingParam.TerminalName : '';
    this.userName = this.currentUser && this.currentUser.username ? this.currentUser.username : '';
    this.currentDate = new Date();
  }

  terminalReportBody() {
    if ($('#reportNavbarMenu').is(':visible')) {
      $('#reportNavbarMenu').collapse('toggle');
    }
  }

  getDriverReport() {
    this.resetReportsData();
    this.isShowHeader = true;
    this.showTotal = true;
    const headerObj = {
      OrderId: 'Order No',
      AccountName: 'Account',
      OrderTotal: 'Order Total'
    };
    this.header = headerObj;
    this.reportHeader = this.reportsHeader.DriverReport;
    this.colsLength = this.getLength(this.header);
    this.singleLineCols = this.getLength(this.header);
    this.spinnerService.show();
    this.driverService.getUnservedOrderForDelivery()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          if (res) {
            this.prepareObjForDriverReport(res);
          }
        }, error: this.alertService.showApiError
      });
  }

  resetReportsData() {
    this.isShowHeader = false;
    this.showTotal = false;
    this.reportHeader = '';
    this.colsLength = 0;
    this.columns = [];
    this.header = null;
    this.grandTotal = 0;
    this.taxTotal = null;
    this.tipTotal = null;
    this.total = null;
    this.orderTotal = null;
    this.showHeaderLine = false;
    this.reportDetails = null;
  }

  getLength(obj) {
    return Object.keys(obj).length;
  }

  prepareObjForDriverReport(orders) {
    const self = this;
    _.forEach(orders, (order) => {
      const dataObj = {
        OrderId: order.SurrogateOrderId,
        AccountName: order.AccountName,
        OrderTotal: order.Total.toFixed(2)
      };
      if (self.selectedDriver) {
        if (order.DeliveryUserID == this.selectedDriver.Id) {
          self.columns.push(dataObj);
          self.grandTotal += order.Total;
        }
      } else {
        self.columns.push(dataObj);
        self.grandTotal += order.Total;
      }
    });
  }

  getSalesStatisticsReport() {
    this.resetReportsData();
    this.reportHeader = this.reportsHeader.SalesStatistics;
    this.spinnerService.show();
    this.driverService.getSalesStatisticsReport(this.drawerId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          if (res) {
            const data = res ? res[0] : {};
            this.prepareObjForSalesStatistics('#Guests, $Avg', data.Guests, data.GuestsAvg.toFixed(2));
            this.prepareObjForSalesStatistics('Avg Turn Time (Mins)', 0, data.AvgTurnTime);
            this.prepareObjForSalesStatistics('Voided Sales', data.DeletedOrders, data.DeletedOrdersTotal.toFixed(2));
            this.prepareObjForSalesStatistics('Canceled Sales', data.CanceledOrders, data.CanceledOrdersTotal.toFixed(2));
            this.prepareObjForSalesStatistics('Gratuity Total', null, data.Gratuity.toFixed(2));
            this.prepareObjForSalesStatistics('Other Markup Total', null, data.Markup.toFixed(2));
            this.prepareObjForSalesStatistics('Discount Total', null, data.DiscountTotal.toFixed(2));
            this.prepareObjForSalesStatistics('Refunded Sales', null, data.RefundedAmount.toFixed(2));
            this.prepareObjForSalesStatistics('To Go Sales', null, data.ToGoAmount.toFixed(2));
          }
        }, error: this.alertService.showApiError
      });
  }

  prepareObjForSalesStatistics(name, count, amount) {
    const dataObj = {
      Name: name,
      Count: count,
      Amount: amount
    };
    this.columns.push(dataObj);
  }

  getSalesReport() {
    this.resetReportsData();
    this.reportHeader = this.reportsHeader.SalesReport;
    this.getSalesReportData(this.drawerId);
  }

  getAllSalesReport() {
    this.resetReportsData();
    this.reportHeader = this.reportsHeader.AllSales;
    this.getSalesReportData(0);
  }

  getSalesReportData(drawerId: number) {
    this.spinnerService.show();
    this.driverService.getSalesReport(drawerId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          if (res) {
            this.isShowHeader = true;
            this.showTotal = true;
            const headerObj = {
              Category: 'Category',
              Qty: 'Qty',
              Sales: 'Sales'
            };
            this.header = headerObj;
            this.colsLength = this.getLength(this.header);
            this.singleLineCols = this.colsLength;
            const self = this;
            _.forEach(res, function (item) {
              const dataObj = {
                Category: item.Category,
                Qty: item.Qty,
                Sales: (item.Price * item.Qty).toFixed(2)
              };
              self.columns.push(dataObj);
              self.grandTotal += (item.Price * item.Qty);
            });
          }
        }, error: this.alertService.showApiError
      });
  }

  printTerminalReport() {
    const headers = [];
    headers.push(this.header);
    const reportData = {
      TerminalId: this.applicationStateService.terminalId,
      ReportName: this.reportHeader,
      SettingsParam: this.settingParam,
      ReportHeaders: headers,
      ReportColumns: this.columns,
      Total: parseFloat(this.grandTotal.toString()),
      ReporterName: this.currentUser.username,
      CashDrawerName: this.drawerName,
      TerminalName: this.terminalName,
      GrandOrderTotal: this.grandTotal,
      GrandTaxTotal: this.taxTotal,
      GrandTipTotal: this.tipTotal,
      From: this.fromDate,
      To: this.toDate
    };
    this.spinnerService.show();
    this.driverService.printTerminalReport(reportData)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          if (res) {
            this.prepareObjForDriverReport(res);
          }
        }, error: this.alertService.showApiError
      });
  }

  getCashDrawerReport() {
    this.resetReportsData();
    this.reportHeader = this.reportsHeader.CashierReport;
    this.spinnerService.show();
    this.driverService.getCashDrawerReport(this.drawerId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          if (res) {
            this.cashDrawerReportCompleted(res);
          }
        }, error: this.alertService.showApiError
      });
  }

  originalOrder = (firstKey: KeyValue<number, string>, secondKey: KeyValue<number, string>): number => {
    return 0;
  }

  cashDrawerReportCompleted(response) {
    this.isShowHeader = true;
    this.showTotal = true;
    const headerObj = {
      CheckNo: 'Check',
      CheckType: 'Type',
      SubTotal: 'Sub',
      TaxTotal: 'Tax',
      OrderTotal: 'Total',
      TipTotal: 'Tip'
    };
    this.showHeaderLine = true;
    this.header = headerObj;
    this.colsLength = 3;
    this.singleLineCols = this.getLength(this.header);
    const self = this;
    _.forEach(response.CashierDetails, function (item) {
      const dataObj = {
        CheckNo: item.CheckNo,
        CheckType: item.Type,
        SubTotal: item.Total.toFixed(2),
        TaxTotal: item.Tax.toFixed(2),
        OrderTotal: item.GrandTotal.toFixed(2),
        TipTotal: item.Tip.toFixed(2)
      };
      self.columns.push(dataObj);
      self.taxTotal += item.Tax;
      self.tipTotal += item.Tip;
      self.orderTotal += item.GrandTotal;
    });
    this.grandTotal = this.orderTotal - this.taxTotal;
    this.total = this.grandTotal + this.taxTotal + this.tipTotal;
    if (response.GeneralCashierInfo && response.GeneralCashierInfo.length > 0) {
      this.fromDate = response.GeneralCashierInfo[0].start_date ? response.GeneralCashierInfo[0].start_date : '';
      this.toDate = response.GeneralCashierInfo[0].end_date ? response.GeneralCashierInfo[0].end_date : '';
    }
    const printTaxTotal = this.taxTotal ? this.total.toFixed(2) : '0.00';
    const printTotal = this.total ? this.total.toFixed(2) : '0.00';
    const printTipTotal = this.tipTotal ? this.tipTotal.toFixed(2) : '0.00'
    this.reportDetails =  this.sanitizer.bypassSecurityTrustHtml('<div class="col-sm-8 col-xs-12 p-0  pt-pix-5 border-bottom-dashed-black">' +
      '</div> <div class="col-12 pt-pix-5"> Cashier: ' + this.drawerName + '</div>' +
      '<div class="col-12"> Terminal: ' + this.terminalName + '</div>' +
      '<div class="col-12"> From: ' + formatDate(this.fromDate,
        this.settingParam.DateFormat + ' ' + this.settingParam.TimeFormat, this.settingParam.Locale)
      // + $filter('date')(this.fromDate, 'MM-dd-yyyy hh:mm') 
      + '</div>' +
      '<div class="col-12"> To: ' + formatDate(this.toDate,
        this.settingParam.DateFormat + ' ' + this.settingParam.TimeFormat, this.settingParam.Locale) +
      // $filter('date')(this.toDate, 'MM-dd-yyyy hh:mm') + 
      '</div>' +
      // '</br> =================================================' +
      '</br> <table class="col-sm-8 col-xs-12 p-0"> <tr> <td colspan="2" class="pb-pix-5"> ' +
      '<div class="border-bottom-dashed-black pt-pix-5">' +
      '</div></td> </tr> <tr> <td class="px-pix-10"> SubTotal </td> ' +
      '<td class="px-pix-10"> <span class="float-end">' + this.grandTotal.toFixed(2) + '</span> </td> ' +
      '</tr> <tr> <td class="px-pix-10"> Tax </td> <td class="px-pix-10"> <span class="float-end">' + printTaxTotal + '</span> </td> </tr>' +
      '<tr> <td colspan="2" class="pb-pix-5"> <div class="border-bottom-dashed-black pt-pix-5"></div> </td>' +
      ' </tr> <tr> <td class="px-pix-10"> Total </td> <td class="px-pix-10"> <span class="float-end">' + printTotal + '</span> </td> </tr> ' +
      ' <tr> <td class="px-pix-10"> Tip </td> <td class="px-pix-10"> <span class="float-end">' + printTipTotal + '</span> </td> </tr> </table>' +
      '</br> <div class="col-12 pt-pix-7 pb-pix-7"> Check Details  ( ' +
      response.CashierDetails.length +
      ' ) </div> </br>');
  }

  isNumeric(data) {
    return !isNaN(data);
  }

  onCancel() {
    this.close.emit({});
  }
}
