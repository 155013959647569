import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { Observable } from 'rxjs';
import { CashDrawer } from '../interface/cash-drawer';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';

@Injectable()
export class CashDrawerEntriesService extends BaseCrudService<CashDrawer> {

    cashDrawerEntriesApiUrl = '';
    constructor(httpService: HttpClient) {
        super('cashdrawersEntries', httpService);
        this.cashDrawerEntriesApiUrl = RuntimeConstants.API_PATH + 'cashdrawersEntries';
    }

    getCashDrawerEntries(): Observable<any> {
        return this.httpService.get(`${this.cashDrawerEntriesApiUrl}`)
    }
}
