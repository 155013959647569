import { Component, Output, EventEmitter, ViewChild } from '@angular/core';
import { Messages } from '../../../../shared/constants';
import { NgForm } from '@angular/forms';
import { SpinnerService, AlertsService } from 'src/app/shared';
import { CacheFlushService } from '../../service/cache-flush.service';
import { finalize } from 'rxjs/operators';
import { flushCache } from 'src/app/shared/components/icon';
import { Router } from '@angular/router';
@Component({
  selector: 'pos-cache-flush',
  templateUrl: './cache-flush.component.html',
})
export class CacheFlushComponent {

  @ViewChild('cacheFlushForm') cacheFlushForm: NgForm;
  @Output() cacheFlushCancel = new EventEmitter();
  icons = {
    flushCache
  };
  constructor(private cacheFlushService: CacheFlushService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    protected router: Router) {
  }

  refreshCache() {
    this.spinnerService.show();
    this.cacheFlushService.refreshCache()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.alertService.renderSuccessMessage(Messages.CacheRefreshSuccess);
        }
      });

  }

  onCancel() {
    this.router.navigate(['/manage/system-configuration']);
    this.cacheFlushCancel.emit();
  }

}
