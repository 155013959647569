import { Component, OnInit, OnDestroy, Input, HostListener } from '@angular/core';
import { ApplicationStateService, Configurations, SettingParam, DomainConstants, UserDetails, AuthenticationService, Levels, Messages, OrderService, RabbitMQService, SpinnerService, AlertsService, InfoModalComponent, MathsUtilityService, CommentWarningService, HttpStatusCodes, CameraAnnotationService, CameraAnnotationObject, LoggerService } from 'src/app/shared';
import {
  editToggleInvoiceArea, arrowsAlth, orderSeatPlus, clock, times, check, undo, caretDown, trashRegularOrderInvoice, printOrderManagement, comments, dollarSign, pencil,
  sendOrderPaperPlane, settleMoneyBill, cancelOrderUndo, reviewOrderSearchDollar, ringupMoneyBill, checkSquare, spinner, exclamationTriangle, trashRegularOrderInvoiceDisable, printOrderInvoice, orderInvoiceBars, nutitionFactPrint, minus, plusMessage
} from 'src/app/shared/components/icon';
import { Permissions, RuntimeConstants } from 'src/app/shared/constants';
import * as _ from 'lodash';
import { OrderInvoice, OrderInvoiceOrderProduct } from '../../interface/order-invoice';
import { KeyboardComponent } from 'src/app/keyboard';
import { TimeEntryComponent } from '../special-functions';
import { NumpadWrapperComponent } from 'src/app/shared/components/numpad/numpad-wrapper/numpad-wrapper.component';
import { OrderEventBroadcastingService } from 'src/app/shared/services/order-event-broadcasting.service';
import { ModalService } from 'src/app/shared/components/modal';
import { SettleComponent } from '../settle/component/settle/settle.component';
import { finalize } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { ReviewOrderComponent } from '../review-order/review-order.component';
import { OrderDietaryRestrictionComponent } from '../order-dietary-restriction';
import { OrderDietaryWarningModel } from '../../interface';
import { CommentWarning, Account } from 'src/app/information-management';
import { QuantityChangePopupComponent } from '../../../shared/components/quantity-change-popup/quantity-change-popup.component';
import { cloneDeep, find, forEach } from 'lodash';
import { UntilDestroy } from '@ngneat/until-destroy';
declare let $: any;
import { KeyValue } from '@angular/common';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { OrderProductPromotion } from 'src/app/manage-console';
import { InvoiceAreaButtonText } from 'src/app/shared/interface/InvoiceAreaButtonText';
import { TarkDateTimePipe } from '@tarktech/tark-ng-utils/table/pipes/tark-date-time.pipe';
import { AudioOperationsService } from '../../../shared/services/audio-operations.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pos-order-invoice',
  templateUrl: './order-invoice.component.html',
  styleUrls: ['./order-invoice.component.scss']
})
export class OrderInvoiceComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  permissions: any = Permissions;
  accessLevels: any = Levels;
  orderEntryRemoveItemPermission: boolean;
  orderEntryOverridePricePermission: boolean;
  configurations: Configurations;
  isScrollHideOrderInvoiceLoader: boolean = false;
  orderInvoiceLoader: boolean = false;
  orderData: OrderInvoice;
  settingParam: SettingParam;
  seats: any = [];
  dietaryRestrictions: any = [];
  seatsCount: number = 0;
  isReviewOrder: boolean = true;
  subaccountTerm: string;
  orderProductStatus: any;
  deliveryTypeId: number;
  cartOrderDataAccountDetails = {}
  currentSeat: number;
  userDetails: UserDetails;
  shiftDutiesUserName: string;
  orderLoaderStackCount: number = 0;
  totalDiscount: number;
  totalMarkup: number;
  inflightItemQtyChangeRequests: number = 0;
  inflightItemComponentDeleteRequests: number = 0;
  inflightItemDeleteRequests: number = 0;
  inflightPriceModifier: boolean = false;
  eventsSubscription: Array<Subscription> = [];
  orderKeyboardOptions = { isOpen: false, capsLock: false, title: 'Enter order name', maxLength: 90 };
  isUseMakeTable: boolean = true;
  isOpen: boolean = false;
  productLabelPrinting: number;
  keyboardValue: string = '';
  promptPromise: Promise<number>;
  currencySymbol: string;
  resolvePromptPromise: (value?: number | PromiseLike<number>) => void;
  rejectPromptPromise: (reason?: any) => void;
  subscriptions: Array<any> = [];
  @Input() loadOrderId: number = null;
  // rabbitMq subscriptions
  rabbitMqOrderServedSubscription: Subscription;
  rabbitMqProductLabelPrintSubscription: Subscription;
  rabbitMqCheckOrderIsOpenSubscription: Subscription;
  rabbitMqOrderCloseRequestSubscription: Subscription;
  rabbitMqOrderDeleteSubscription: Subscription;
  rabbitMqOrderAlreadyOpenSubscription: Subscription;
  newOrderCameraAnnotation: string;
  loyaltyAccountTerm: string = '';
  public icons = {
    editToggleInvoiceArea,
    arrowsAlth,
    orderSeatPlus,
    clock,
    times,
    check,
    undo,
    caretDown,
    trashRegularOrderInvoice,
    trashRegularOrderInvoiceDisable,
    printOrderManagement,
    comments,
    dollarSign,
    sendOrderPaperPlane,
    settleMoneyBill,
    cancelOrderUndo,
    reviewOrderSearchDollar,
    ringupMoneyBill,
    checkSquare,
    spinner,
    exclamationTriangle,
    printOrderInvoice, orderInvoiceBars, nutitionFactPrint,
    pencil,
    minus, plusMessage
  };
  orderItemTypes = DomainConstants.OrderItemTypes;
  isChangingQty: boolean = false;
  removePermission = {
    name: Permissions.OrderEntryRemoveItem,
    accessLevel: Levels.Access
  };

  overridePricePermission = {
    name: Permissions.OrderEntryOverridePrice,
    accessLevel: Levels.Access
  };
  orderInvoiceRabbitMQSubscriptions: Array<any> = [];
  isAllowCreditCard: boolean = false;
  isCashdrawerMapped: boolean = false;
  windowHeight: any;
  commentWarnings: Array<CommentWarning> = [];
  today = new Date().toLocaleDateString();
  pickupDate: string = null;
  showInvoicePopover = false;
  orderProduct = null;
  invoiceAreaButtonText: InvoiceAreaButtonText;
  tableTerm: string = '';
  accountTerm: string = '';
  accountTypes = DomainConstants.AccountTypes;
  orderSubaccount: any = [];
  isSubaccountModificationAllow = false;
  cameraAnnotations: CameraAnnotationObject;
  promotionSummary: Array<OrderProductPromotion> = [];
  currentGroupId = null;
  firstGroupId = null;
  constructor(private applicationStateService: ApplicationStateService,
    private eventBroadcastingService: OrderEventBroadcastingService,
    private authenticationService: AuthenticationService,
    private modalService: ModalService,
    private orderService: OrderService,
    private alertService: AlertsService,
    private rabbitMQService: RabbitMQService,
    private spinnerService: SpinnerService,
    private commentWarningService: CommentWarningService,
    public mathsUtilityService: MathsUtilityService,
    private cameraAnnotationService: CameraAnnotationService,
    protected tarkDateTimePipe: TarkDateTimePipe,
    private loggerService: LoggerService,
    private audioOperationsService: AudioOperationsService) {
    this.currencySymbol = this.applicationStateService.settingParam.CurrencySymbol ?? '$';
    this.isSubaccountModificationAllow = this.applicationStateService.settingParam.AllowModifyingSubaccountNameAtOrderInvoice;
    this.cameraAnnotations = this.applicationStateService.cameraAnnotations;
  }

  originalOrder = (firstKey: KeyValue<number, string>, secondKey: KeyValue<number, string>): number => {
    return 0;
  }

  ngOnInit() {
    this.invoiceAreaButtonText = this.orderService.newInvoiceAreaButtonText();
    this.getServeMethodIds();
    this.setDefaults();
    this.orderProductStatus = RuntimeConstants;
    this.orderData = this.orderService.getNewOrderInvoiceDetails();
    $(".order-section__summary__cart-toggle").unbind().click(() => {
      this.showInvoice();
    });
    this.rabbitMQSubscriptions();
    this.onAddOrderSubscriptions();
    this.orderInvoiceSize();
    this.orderEntryRemoveItemPermission = (this.authenticationService.userHasPermission([{ Name: this.removePermission.name, Level: this.removePermission.accessLevel }], 'any'));
    this.orderEntryOverridePricePermission = (this.authenticationService.userHasPermission([{ Name: this.overridePricePermission.name, Level: this.overridePricePermission.accessLevel }], 'any'));
    this.windowHeight = $(window).width;
    this.resizeWindow();
    this.getCommentWarnings();
    if (this.loadOrderId) {
      this.showOrderInvoiceLoader();
      this.getOrderDetails(this.loadOrderId);
    }
    this.orderProduct = null;
  }

  resizeWindow() {
    $(window).resize(() => {
      this.windowHeight = $(window).width;
      this.orderInvoiceSize();
    });
  }

  @HostListener("window:beforeunload") unloadHandler() {
    this.startANewOrder();
    this.rabbitMQService.sendFilterMenuItemsMessage(this.applicationStateService.terminalId, [], [], []);
  }

  onAddOrderSubscriptions() {
    this.eventsSubscription.push(this.eventBroadcastingService.onAddItemStart.subscribe(() => {
      this.showOrderInvoiceLoader();
    }));
    this.eventsSubscription.push(this.eventBroadcastingService.onAddItemComplete.subscribe((orderId) => {
      this.getOrderDetails(orderId);
      $(".order-section__summary__invoice").stop().animate({
        scrollTop: $(".order-section__summary__invoice")[0].scrollHeight
      }, 800);
    }));
    this.eventsSubscription.push(this.eventBroadcastingService.hideInvoiceLoader.subscribe((orderId) => {
      this.hideOrderInvoiceLoader();
    }));

    this.eventsSubscription.push(this.eventBroadcastingService.paymentCompleted.subscribe((orderId) => {
      this.getOrderDetails(orderId);
    }));
    this.eventsSubscription.push(this.eventBroadcastingService.activeOrderClicked.subscribe((orderId) => {
      this.showOrderInvoiceLoader();
      this.getOrderDetails(orderId);
    }));
    this.eventsSubscription.push(this.eventBroadcastingService.setAccountCompleted.subscribe((res: Account) => {
      if (this.orderData.Id && this.orderData.Id.Value) {
        this.getOrderDetails(this.orderData.Id.Value);
      } else {
        if (res.AccountType === DomainConstants.AccountTypes.Account) {
          this.orderData.CustomerAccount.Name = res.Name;
        } else {
          this.orderData.Account.Type = res.AccountType;
          this.orderData.Account.Name = res.Name;
        }
      }
    }));

    this.eventsSubscription.push(this.eventBroadcastingService.startNewOrderFromOutSide.subscribe(() => {
      this.startANewOrder();
    }));

    this.eventsSubscription.push(this.eventBroadcastingService.addToGoSeat.subscribe(() => {
      this.addToGoSeat();
    }));

    this.rabbitMqProductLabelPrintSubscription = this.rabbitMQService.subscribeToProductLabelPrintingParameterMessage$()
      .subscribe((message: any) => {
        if (message.Payload) {
          this.productLabelPrinting = message.Payload.Message;
        }
      });
  }

  ngOnDestroy() {
    _.forEach(this.eventsSubscription, (subscribe) => {
      if (subscribe) {
        subscribe.unsubscribe();
      }
    });
    this.startANewOrder();
  }

  rabbitMQSubscriptions() {
    this.rabbitMqOrderAlreadyOpenSubscription = this.rabbitMQService.subscribeToOrderAlreadyOpenMessage$(this.applicationStateService.terminalId)
      .subscribe({
        next: (message: any) => {
          console.log('ORDER_ALREADY_OPEN', message);
          const requestOrderId = message.Payload.OrderId;
          if (requestOrderId == this.orderData.SurrogateOrderId) {
            this.openOrderAlreadyOpenModel(message.Source.Name, requestOrderId, message.Source.Id);
          }
        }, error: () => {
          console.log('error while connecting to RabbitMQ.');
        }
      });
    this.rabbitMqOrderCloseRequestSubscription = this.rabbitMQService.subscribeToOrderCloseRequestMessage$(this.applicationStateService.terminalId)
      .subscribe({
        next: (message: any) => {
          this.startANewOrder();
        }, error: () => {
          console.log('error while connecting to RabbitMQ.');
        }
      });

    this.rabbitMqOrderDeleteSubscription = this.rabbitMQService.subscribeToOrderDeletedMessageForAllOrderEntry$()
      .subscribe((message) => {
        if (this.orderData.Id.Value == message.Payload.OrderId) {
          this.startANewOrder();
        }
      });

    this.rabbitMqCheckOrderIsOpenSubscription = this.rabbitMQService.subscribeOrderIsOpenCheckMessage$()
      .subscribe((message: any) => {
        if (this.applicationStateService.terminalId !== message.Source.Id) {
          const requestOrderId = message.Payload.OrderId;
          if (requestOrderId === this.orderData.Id.Value) {
            this.rabbitMQService.sendOrderAlreadyOpenCheckMessage(this.applicationStateService.terminalId,
              this.applicationStateService.terminalName, message.Source.Id, this.orderData.SurrogateOrderId);
          }
        }
      });

    this.rabbitMqOrderServedSubscription = this.rabbitMQService.subscribeToOrderServedMessage$()
      .subscribe((message: any) => {
        console.debug('Order invoice subscribe message' + message.Type);
        if (this.orderData.Id.Value == message.Payload.OrderId) {
          this.startANewOrder();
        }
      });
  }

  openOrderAlreadyOpenModel(sourceTerminalName: string, surrogateOrderId: number, sourceTerminalId: number) {
    const modalRef = this.modalService.show(InfoModalComponent, {
      keyboard: false,
      animated: false,
      class: 'vertical-center',
      initialState: {
        message: StringUtils.format(
          Messages.OrderAlreadyOpen, { 'surrogateOrderId': surrogateOrderId, 'terminalName': sourceTerminalName }),
        confirmButtonText: 'Cancel',
        rejectButtonText: 'Continue Opening',
        modalHeaderText: 'Message'
      }
    });

    modalRef.close.subscribe((res) => {
      if (res && (res.shouldConfirm) || res.event == "close") {
        this.startANewOrder();
      } else {
        this.audioOperationsService.tagAudioRecord(DomainConstants.AudioInteractions.AccessExistingOrder, this.orderData.Id.Value.toString())
        this.sendOrderCloseRequest(sourceTerminalId);
      }
    });
  }

  sendOrderCloseRequest(openInTerminalId: number) {
    this.rabbitMQService.sendOrderCloseRequestMessage(this.applicationStateService.terminalId, this.applicationStateService.terminalName,
      openInTerminalId, this.orderData.Id.Value);
  }

  setDefaults() {
    this.configurations = this.applicationStateService.configurations;
    this.settingParam = this.applicationStateService.settingParam;
    this.productLabelPrinting = this.settingParam.ProductLabelPrinting;
    this.subaccountTerm = this.settingParam.SubaccountTerm ? this.settingParam.SubaccountTerm : 'Seat';
    this.userDetails = this.applicationStateService.userDetails;
    this.shiftDutiesUserName = this.userDetails ? this.userDetails.firstname + ' ' + this.userDetails.lastname : '';
    this.isUseMakeTable = this.settingParam.IsUseMakeTable;
    if (this.settingParam.AllowCreditCards && (this.settingParam.CreditCardTerminal.Id != 0 ||
      this.settingParam.CreditCardType === DomainConstants.CreditCardType.Peripheral)) {
      this.isAllowCreditCard = true;
    }
    if (this.settingParam.CashDrawerPrinter && this.settingParam.CashDrawerPrinter.Id) {
      this.isCashdrawerMapped = true;
    }
    this.invoiceAreaButtonText = JSON.parse(this.settingParam?.InvoiceAreaButtonText);
    this.loyaltyAccountTerm = this.settingParam.LoyalAccountTerm;
    this.tableTerm = this.settingParam.TableTerm ? this.settingParam.TableTerm : 'Table';
    this.accountTerm = this.settingParam.AccountTerm ? this.settingParam.AccountTerm : 'Tab';
  }

  getOrderDetails = (orderId, callback = null) => {
    this.orderInvoiceSize();
    if (orderId) {
      this.orderService.getOrderDetails(orderId).pipe(finalize(() => {
        this.hideOrderInvoiceLoader();
      })).subscribe({
        next: (response: OrderInvoice) => {
          this.isReviewOrder = false;
          this.dietaryRestrictions = [];
          this.rabbitMQService.sendMessageToInvoiceExchange(this.applicationStateService.terminalId, response);
          if (!this.orderData || !this.orderData.Id.Value || this.orderData.Id.Value != orderId) {
            this.rabbitMQService.sendOrderIsOpenCheckMessage(this.applicationStateService.terminalId,
              this.applicationStateService.terminalName, orderId);
          }
          _.forEach(response.OrderItems, (item) => {
            if (item.OrderItemType === DomainConstants.OrderItemTypes.Product &&
              this.mathsUtilityService.floatSafeModulus(item.Qty, 1)) {
              if (!this.mathsUtilityService.floatSafeModulus(item.Qty, 0.01)) {
                item.Qty = item.Qty.toFixed(2);
              }
            }
          });
          this.firstGroupId = response.OrderItems.find(x => x.GroupId !== null)?.GroupId;
          forEach(response.OrderItems, item => {
            item.IsComboProduct = this.setIsComboProduct(item.GroupId);
          });
          this.seats = _.groupBy(response.OrderItems, 'SubAccountOrdinal');
          var maxOrdinal = this.getMaxOrdinal();
          const dietaryWithoutToGo = _.filter(response.DietaryRestrictions, (o) => {
            return o.SubaccountOrdinal != 99;
          });
          var maxDietarySubaccount = _.maxBy(dietaryWithoutToGo, (o) => {
            return o.SubaccountOrdinal;
          });

          if (maxDietarySubaccount) {
            maxOrdinal = Math.max(maxOrdinal, maxDietarySubaccount.SubaccountOrdinal);
          }
          for (var i = 1; i <= maxOrdinal; i++) {
            if (!this.seats[i]) {
              this.seats[i] = [];
            }
          }
          if (_.some(response.DietaryRestrictions, (o) => { return o.SubaccountOrdinal == 99; }) && !this.seats[99]) {
            this.seats[99] = [];
          }
          if (!response.OrderItems.length && response.DietaryRestrictions.length) {
            this.seats[1] = [];
          }
          if (this.orderData.Id.Value != orderId) {
            this.selectSeat(maxOrdinal);
          }
          this.orderData = response;
          this.pickupDate = new Date(this.orderData.OrderAttributes.PickupTime).toLocaleDateString();
          this.dietaryRestrictions = _.groupBy(this.orderData.DietaryRestrictions, 'SubaccountOrdinal');
          this.setSubAccount();
          this.getSeatCount();
          this.eventBroadcastingService.onOrderReload(this.orderData);

          if (callback) {
            callback();
          }
          this.openInvoice();
        }, error: () => {
          this.startANewOrder();
        }
      });
    }
  }

  setSubAccount() {
    if (this.orderData.OrderSubaccounts && this.orderData.OrderSubaccounts.length) {
      this.orderSubaccount = [];
      this.orderData.OrderSubaccounts?.forEach((data) => {
        this.orderSubaccount[data.SubaccountOrdinal] = data;
      });
    } else {
      this.orderSubaccount = [];
    }
  }

  getMaxOrdinal() {
    let maxOrdinal = 1;
    _.forEach(this.seats, (seat, key) => {
      if (key && parseInt(key) > maxOrdinal && parseInt(key) != 99) {
        maxOrdinal = parseInt(key);
      }
    });
    return maxOrdinal;
  }

  saveUserPreference() {
    this.configurations.UserPreferenceInvoiceLocation = this.configurations.UserPreferenceInvoiceLocation == "left" ? "right" : "left";
    this.applicationStateService.configurations.UserPreferenceInvoiceLocation = this.configurations.UserPreferenceInvoiceLocation;
    let userPreference = { user_id: null, key: '', value: null };
    userPreference.user_id = this.userDetails.id;
    userPreference.key = "InvoiceLocation";
    userPreference.value = this.configurations.UserPreferenceInvoiceLocation
    this.orderService.saveUserPreference(userPreference)
      .subscribe(() => {
      });
  }

  getServeMethodIds() {
    const delivery = _.find(DomainConstants.PromptServeMethods, (item) => {
      return item.Name === 'Delivery';
    });
    if (!!delivery.id) {
      this.deliveryTypeId = parseInt(delivery.id, 0);
    }
  }

  selectSeat(seatId) {
    this.currentSeat = seatId;
    this.eventBroadcastingService.selectSeat.emit(this.currentSeat);
  }

  startANewOrder(isRequiredNavigateToHome = false) {
    if (this.orderData?.Id?.Value) {
      this.audioOperationsService.untagAudioRecord();
    }
    this.newOrderCameraAnnotation = this.cameraAnnotations.NewOrder;
    this.orderData = this.orderService.getNewOrderInvoiceDetails();
    this.eventBroadcastingService.newOrder.emit(isRequiredNavigateToHome);
    this.totalDiscount = 0;
    this.rabbitMQService.sendMessageToInvoiceExchange(this.applicationStateService.terminalId, null);
    this.totalMarkup = 0;
    this.seats = [];
    this.isReviewOrder = false;
    this.eventBroadcastingService.onOrderReload(this.orderData);
  }

  deleteDietaryWarning(warning) {
    this.orderService.deleteOrderDietaryRestriction(this.orderData.Id.Value, warning.Id.Value)
      .subscribe({
        next: () => {
          if (this.getItemsCount()) {
            this.getOrderDetails(this.orderData.Id.Value);
          }
        }, error: this.alertService.showApiError
      });
    _.remove(this.orderData.DietaryRestrictions, (dietaryRestriction) => {
      return dietaryRestriction.Id == warning.Id.Value;
    });

    if (this.orderData.DietaryRestrictions.length) {
      this.dietaryRestrictions = _.groupBy(this.orderData.DietaryRestrictions, 'SubaccountOrdinal');
    } else {
      this.dietaryRestrictions = [];
      if (this.orderData.OrderItems.length == 0 && !this.orderData.MarkupPct && !this.orderData.DiscountPct && !this.attributesExists()) {
        this.startANewOrder();
      }
    }

  }

  showOrderInvoiceLoader() {
    this.orderLoaderStackCount++;
    this.orderInvoiceLoader = true;
  }
  hideOrderInvoiceLoader() {
    this.orderLoaderStackCount--;
    if (this.orderLoaderStackCount <= 0) {
      this.orderLoaderStackCount = 0;
      this.orderInvoiceLoader = false;
    }
    if (this.isScrollHideOrderInvoiceLoader) {
      this.isScrollHideOrderInvoiceLoader = false;
      $(".order-section__summary__invoice").stop().animate({
        scrollTop: $(".order-section__summary__invoice")[0].scrollHeight
      }, 800);
    }
  }


  addNewSeat() {
    let maxOrdinal = this.getMaxOrdinal();
    if (maxOrdinal && (this.seats[maxOrdinal].length > 0) || (this.dietaryRestrictions && this.dietaryRestrictions[maxOrdinal] && this.dietaryRestrictions[maxOrdinal].length > 0)) {
      const nextOrdinal = maxOrdinal + 1;
      this.seats[nextOrdinal] = [];
      this.selectSeat(nextOrdinal);
      this.orderSubaccount[nextOrdinal] = this.orderService.setOrderSubaccount(this.orderData.Id.Value, null, nextOrdinal);
      this.cameraAnnotationService.addAnnotationToCamera(this.cameraAnnotations.AddSubaccount, {
        SubaccountName: this.orderSubaccount[nextOrdinal].subaccount ?? this.subaccountTerm + ' ' + nextOrdinal,
        OrderId: this.orderData.SurrogateOrderId,
      });
      this.orderService.updateSeat(this.orderSubaccount[nextOrdinal]).subscribe((response) => {
      });
    }
    this.getSeatCount();
  }

  addToGoSeat() {
    if (!this.seats[99]) {
      this.seats[99] = [];
      this.selectSeat(99);
    }
    this.getSeatCount();
  }


  deleteItemFromOrder(product) {
    this.cameraAnnotationService.addAnnotationToCamera(this.cameraAnnotations.DeleteProduct, {
      OrderProductName: product.MakeTableText,
      OrderId: this.orderData.SurrogateOrderId,
      OrderProductId: product.Id.Value
    })
    if (!this.inflightItemDeleteRequests && !this.inflightItemComponentDeleteRequests) {
      let isEmptyArray = item => _.isArray(item) && _.isEmpty(item);

      let seats = cloneDeep(this.seats);
      _.forEach(cloneDeep(this.seats), (items, index) => {
        const filteredItems = _.some(items, (item) => { return item.OrderItemType != DomainConstants.OrderItemTypes.Promotion });
        seats[index] = filteredItems ? items : _.filter(items, item => { return item.OrderItemType != DomainConstants.OrderItemTypes.Promotion });
      });
      let result = _.reject(seats, isEmptyArray);

      const totalSeats = Object.keys(result).length;
      this.inflightItemDeleteRequests++;
      if (product.OrderItemType === DomainConstants.OrderItemTypes.RemoveTax) {
        this.orderService.deleteRemoveTax(this.orderData.Id.Value, product.Id.Value)
          .pipe(finalize(() => {
            this.hideOrderInvoiceLoader();
          }))
          .subscribe({
            next: () => {
              this.inflightItemDeleteRequests > 0 ? this.inflightItemDeleteRequests-- : this.inflightItemDeleteRequests = 0;
              if (this.orderData.Id && this.orderData.Id.Value > 0 && !this.inflightItemDeleteRequests
                && (this.getItemsCount(product.Id.Value) || this.attributesExists())) {
                this.getOrderDetails(this.orderData.Id.Value);
              }
            }, error: (err) => {
              this.inflightItemDeleteRequests > 0 ? this.inflightItemDeleteRequests-- : this.inflightItemDeleteRequests = 0;
              this.alertService.showApiError(err);
            }
          });
      } else {
        const modifiedOrderId = this.orderData.Id.Value;
        this.orderService.deleteOrderProduct(this.orderData.Id.Value, product.Id.Value)
          .pipe(finalize(() => {
            this.hideOrderInvoiceLoader();
          }))
          .subscribe({
            next: () => {
              this.inflightItemDeleteRequests > 0 ? this.inflightItemDeleteRequests-- : this.inflightItemDeleteRequests = 0;
              if (this.orderData.Id && this.orderData.Id.Value > 0 && !this.inflightItemDeleteRequests &&
                (this.getItemsCount(product.Id.Value) || this.attributesExists())) {
                this.getOrderDetails(this.orderData.Id.Value);
              }
            }, error: (err) => {
              this.inflightItemDeleteRequests > 0 ? this.inflightItemDeleteRequests-- : this.inflightItemDeleteRequests = 0;
              this.alertService.showApiError(err);
              this.errorHandler(err, modifiedOrderId);
            }
          });
      }
      if (totalSeats == 1 &&
        (!this.seats[product.SubAccountOrdinal] || !this.seats[product.SubAccountOrdinal].length) &&
        !this.attributesExists()
      ) {
        this.startANewOrder();
      }
    }
  }

  errorHandler = (e, orderId?) => {
    if (e && e.status === HttpStatusCodes.BadRequest && e.error) {
      if (orderId || this.orderData?.Id?.Value) {
        this.getOrderDetails(orderId ? orderId : this.orderData.Id.Value);
      }
    }
  }

  getSeatCount() {
    const seats = Object.keys(this.seats);
    _.remove(seats, (seat) => {
      return !seat;
    });
    this.seatsCount = 0;
    forEach(seats, (seat) => {
      if (Number(seat)) {
        this.seatsCount++;
      }
    });
    return this.seatsCount;
  }

  deleteDeliveryFee() {
    this.inflightPriceModifier = true;
    this.orderService.deleteDeliveryFee(this.orderData.Id.Value)
      .pipe(finalize(() => {
        this.inflightPriceModifier = false;
      }))
      .subscribe({
        next: () => {
          if (this.orderData.Id && this.orderData.Id.Value > 0) {
            this.getOrderDetails(this.orderData.Id.Value);
          }
        }, error: this.alertService.showApiError
      });
  }


  deleteMarkupPct() {
    this.orderData.MarkupPct = 0;
    this.inflightPriceModifier = true;
    this.orderService.deleteOrderMarkupPct(this.orderData.Id.Value)
      .pipe(finalize(() => {
        this.inflightPriceModifier = false;
      }))
      .subscribe({
        next: () => {
          if (this.orderData.Id && this.orderData.Id.Value > 0 && this.orderData.OrderItems.length > 0 && this.getItemsCount()) {
            this.getOrderDetails(this.orderData.Id.Value);
          } else {
            this.startANewOrder();
          }
        }, error: (err) => {
          this.alertService.showApiError(err);
          this.errorHandler(err);
        }
      });
  }

  deleteDiscountPct() {
    this.orderData.DiscountPct = 0;
    this.inflightPriceModifier = true;
    this.orderService.deleteOrderDiscountPct(this.orderData.Id.Value)
      .pipe(finalize(() => {
        this.inflightPriceModifier = false;
      }))
      .subscribe({
        next: () => {
          if (this.orderData.Id && this.orderData.Id.Value > 0 && this.orderData.OrderItems.length > 0 && this.getItemsCount()) {
            this.getOrderDetails(this.orderData.Id.Value);
          } else {
            this.startANewOrder();
          }
        }, error: this.alertService.showApiError
      });
  }


  changeItemQty = (product, isIncrementType) => {
    const productBeforeUpdate = { ...product };
    const increment = parseFloat((product.SalesProductBusinessEntity?.Increment > 0) ? product.SalesProductBusinessEntity.Increment : 1)
    if (isIncrementType) {
      if (this.mathsUtilityService.floatSafeModulus(increment, 1)) {
        product.Qty = (parseFloat(product.Qty) + increment).toFixed(2);
      } else {
        product.Qty = parseFloat(product.Qty) + increment;
      }
    } else {
      if (parseFloat((parseFloat(product.Qty) - increment).toFixed(2)) <= 0) {
        this.deleteItemFromOrder(product);
        this.calculateChangedQty(product, increment);
        return;
      } else {
        this.calculateChangedQty(product, increment);
      }
    }
    this.isChangingQty = true;
    this.quantityChangeCameraAnnotation(product, productBeforeUpdate.Qty);
    this.sendQtyChangeRequest(product);
  }

  calculateChangedQty = (product, increment: number) => {
    if (this.mathsUtilityService.floatSafeModulus(increment, 1)) {
      product.Qty = (product.Qty - increment).toFixed(2);
    } else {
      product.Qty -= increment;
    }
  }


  sendQtyChangeRequest = _.debounce((product, event = null, updatedProducts: OrderInvoiceOrderProduct[] = []) => {
    this.isChangingQty = false;
    this.inflightItemQtyChangeRequests++;
    const modifiedOrderId = this.orderData.Id.Value;
    const products = updatedProducts.map(x => { return { OrderProductId: x.Id.Value, NewQty: x.Qty } });
    let updateQtyObservable: Observable<Object>;
    if (product?.Qty > 0 || event?.groupId) {
      if (event?.groupId) {
        updateQtyObservable = this.orderService.changeProductGroupQty(this.orderData.Id.Value, products);
      } else {
        updateQtyObservable = this.orderService.updateQty(this.orderData.Id.Value, product.Id.Value, product.Qty);
      }
      updateQtyObservable.pipe(finalize(() => {
      }))
        .subscribe({
          next: () => {
            this.setInflightItemQtyChangeRequests();
            if (this.orderData.Id && this.orderData.Id.Value > 0 && !this.inflightItemQtyChangeRequests && !this.isChangingQty) {
              this.getOrderDetails(this.orderData.Id.Value);
            }
          }, error: (err) => {
            this.setInflightItemQtyChangeRequests();
            this.alertService.showApiError(err);
            this.errorHandler(err, modifiedOrderId);
          }
        });
    } else {
      this.setInflightItemQtyChangeRequests();
    }
  }, 500, { maxWait: 1000 });

  quantityChangeCameraAnnotation(product, oldQty) {
    this.cameraAnnotationService.addAnnotationToCamera(this.cameraAnnotations.ChangeQuantity, {
      OrderProductName: product.MakeTableText,
      OrderProductId: product.Id.Value,
      OrderId: this.orderData.SurrogateOrderId,
      OldQty: oldQty,
      NewQty: product.Qty
    });
  }

  comboProductQuantityChangeCameraAnnotation(product: OrderInvoiceOrderProduct, oldQty: number, newQty: number) {
    this.cameraAnnotationService.addAnnotationToCamera(this.cameraAnnotations.ChangeProductGroupQuantity, {
      GroupName: product.OrderProductGroup?.Name,
      GroupId: product.GroupId,
      OrderId: this.orderData.SurrogateOrderId,
      OldQty: oldQty,
      NewQty: newQty
    });
  }

  addQuantityToItem = (product) => {
    if (this.mathsUtilityService.floatSafeModulus(parseFloat(product.Qty), 1) &&
      !this.mathsUtilityService.floatSafeModulus(parseFloat(product.Qty), 0.01)) {
      product.Qty = parseFloat(product.Qty).toFixed(2);
    }
    if ((parseFloat(product.Qty)) <= 0) {
      this.deleteItemFromOrder(product);
      return;
    }
    const modifiedOrderId = this.orderData.Id.Value;
    this.quantityChangeCameraAnnotation(product, product.OldQty);
    this.orderService.updateQty(this.orderData.Id.Value, product.Id.Value, product.Qty)
      .subscribe({
        next: () => {
          if (this.orderData.Id && this.orderData.Id.Value > 0) {
            this.getOrderDetails(this.orderData.Id.Value);
          }
        }, error: (err) => {
          this.alertService.showApiError(err);
          this.errorHandler(err, modifiedOrderId);
        }
      });
  }

  hasPermissionForChange = (product) => {
    if (product.StatusId != 0 || product.IsSentToKitchen || product.IsDirty) {
      return (this.authenticationService.userHasPermission([{ Name: this.removePermission.name, Level: this.removePermission.accessLevel }], 'any'));
    }
    else {
      return true;
    }
  }

  openInvoice() {
    setTimeout(() => {
      this.orderInvoiceSize();
    }, 350);
  }

  orderInvoiceSize() {
    var orderSummaryInvoice;
    var orderInvoiceHeader = $('#OrderInvoiceHeader').height();
    var OrderSummaryTotalHeight = $('#OrderSummaryTotal').height();
    var screenHeight = $(window).height();
    var screenWidth = $(window).width();
    if (screenWidth <= 767) {
      orderSummaryInvoice = screenHeight - OrderSummaryTotalHeight - orderInvoiceHeader - 30;
    } else {
      orderSummaryInvoice = screenHeight - OrderSummaryTotalHeight - orderInvoiceHeader - 130;
    }
    $('#divOrderContent').css('height', orderSummaryInvoice + 6);
    $('#loaderInvoiceDiv').css('height', $('#divOrderContent').height());
    $('#loaderInvoiceDiv').css('width', $('#divOrderContent').width());
  }

  promptForQtyChange(qty, unitName, subAccountOrdinal): Promise<number> {

    const numpadModalRef = this.modalService.show(QuantityChangePopupComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        value: qty,
        maxQty: qty,
        allowDecrement: true,
        decrementErrorMessage: Messages.OrderItemMoveDecreaseValidationError,
        header: (StringUtils.format(
          Messages.ChooseQtyMove, {
          'seatName': (subAccountOrdinal != 99 ? this.subaccountTerm + ' ' + subAccountOrdinal : '"To Go"')
        })),
        salesProductUnit: unitName,
        maxValidationMessage: Messages.OrderItemMoveDecreaseValidationError
      }
    });

    numpadModalRef.close.subscribe(res => {
      if (res.value) {
        this.resolvePromptPromise(res.value);
      } else {
        this.rejectPromptPromise();
      }
    });

    this.promptPromise = new Promise<number>((resolve, reject) => {
      this.resolvePromptPromise = resolve;
      this.rejectPromptPromise = reject;
    });
    return this.promptPromise;
  }

  moveItemToOtherSeat(item, product, targetSeat) {
    const sourceSubAccountName = this.orderSubaccount[product.SubAccountOrdinal]?.SubaccountName ?? this.subaccountTerm + ' ' + product.SubAccountOrdinal;
    const targetSubAccountName = this.orderSubaccount[targetSeat]?.SubaccountName ?? this.subaccountTerm + ' ' + targetSeat;
    if (product.Qty > 1) {
      this.promptForQtyChange(product.Qty, product.SalesProductBusinessEntity?.SalesUnit?.Name, targetSeat)
        .then((qty: number) => {
          this.spinnerService.show();
          this.orderService.moveItemToOtherSeat(this.orderData.Id.Value, product.Id.Value, targetSeat, qty)
            .pipe(finalize(() => {
              this.spinnerService.hide();
            }))
            .subscribe({
              next: (res: Array<OrderDietaryWarningModel>) => {
                if (res?.length) {
                  this.getOrderDetails(this.orderData.Id.Value);
                  this.openDietaryRestrictionModal(res, product, targetSeat);
                } else {
                  this.getOrderDetails(this.orderData.Id.Value);
                }
                this.cameraAnnotationService.addAnnotationToCamera(this.cameraAnnotations.MoveSubaccount, {
                  OrderId: this.orderData.SurrogateOrderId,
                  OrderProductId: product.Id.Value,
                  SourceSubaccountName: sourceSubAccountName,
                  DestinationSubaccountName: targetSubAccountName,
                  OrderProductName: product.MakeTableText,
                  Qty: qty
                });
              }, error: (err) => {
                this.alertService.showApiError(err);
                this.errorHandler(err);
              }
            });
        }, () => { });
    } else {
      item.SubAccountOrdinal = targetSeat;
      let data = _.remove(item.value, (orderProduct: any) => {
        return orderProduct.Id.Value === product.Id.Value;
      });
      this.seats[targetSeat].push(data[0]);
      product.SubAccountOrdinal = targetSeat;
      this.updateSubAccountOrdinal(product, targetSeat);
      this.cameraAnnotationService.addAnnotationToCamera(this.cameraAnnotations.MoveSubaccount, {
        OrderId: this.orderData.SurrogateOrderId,
        OrderProductId: product.Id.Value,
        SourceSubaccountName: sourceSubAccountName,
        DestinationSubaccountName: targetSubAccountName,
        OrderProductName: product.MakeTableText,
        Qty: product.Qty
      });
    }
  }


  updateSubAccountOrdinal(product, targetSeat) {
    this.orderService.updateSubaccountOrdinal(this.orderData.Id.Value, product.Id.Value, targetSeat)
      .subscribe({
        next: (res: Array<OrderDietaryWarningModel>) => {
          if (res?.length) {
            this.openDietaryRestrictionModal(res, product);
          } else {
            this.getOrderDetails(this.orderData.Id.Value);
          }
        }, error: (err) => {
          this.alertService.showApiError(err);
          this.errorHandler(err);
        }
      });
  }

  deleteOrderProductComponent(product, component) {
    if (!this.inflightItemComponentDeleteRequests) {
      this.cameraAnnotationService.addAnnotationToCamera(this.cameraAnnotations.DeleteComponent, {
        OrderProductName: product.MakeTableText,
        ComponentName: component.MakeTableText,
        ComponentId: component.Id.Value,
        OrderId: this.orderData.SurrogateOrderId
      });

      if (component.IsSize) {
        this.inflightItemComponentDeleteRequests++;
        this.orderService.deleteOrderProductSize(this.orderData.Id.Value, product.Id.Value, component.Id.Value, component.SizeId)
          .subscribe({
            next: () => {
              this.inflightItemComponentDeleteRequests > 0 ? this.inflightItemComponentDeleteRequests-- : this.inflightItemComponentDeleteRequests = 0;
              if (this.orderData.Id && this.orderData.Id.Value > 0 && !this.inflightItemComponentDeleteRequests) {
                this.getOrderDetails(this.orderData.Id.Value);
              }
            }, error: (err) => {
              this.inflightItemComponentDeleteRequests > 0 ? this.inflightItemComponentDeleteRequests-- : this.inflightItemComponentDeleteRequests = 0;
              this.alertService.showApiError(err);
            }
          });
        _.remove(product.OrderProductComponents, (orderProductComponent: any) => {
          return orderProductComponent.IsSize;
        });
      } else {
        this.inflightItemComponentDeleteRequests++;
        this.orderService.deleteOrderProductComponent(this.orderData.Id.Value, product.Id.Value, component.Id.Value)
          .subscribe({
            next: (res: Array<OrderDietaryWarningModel>) => {
              this.inflightItemComponentDeleteRequests > 0 ? this.inflightItemComponentDeleteRequests-- : this.inflightItemComponentDeleteRequests = 0;
              if (this.orderData.Id && this.orderData.Id.Value > 0 && !this.inflightItemComponentDeleteRequests) {
                this.getOrderDetails(this.orderData.Id.Value);
              }
              if (res && res.length) {
                this.openDietaryRestrictionModal(res, product);
              }
            }, error: (err) => {
              this.inflightItemComponentDeleteRequests > 0 ? this.inflightItemComponentDeleteRequests-- : this.inflightItemComponentDeleteRequests = 0;
              this.alertService.showApiError(err);
              this.errorHandler(err);
            }
          });
        _.remove(product.OrderProductComponents, (orderProductComponent: any) => {
          return orderProductComponent.Id.Value == component.Id.Value;
        });
      }

    }
  }

  openDietaryRestrictionModal(res: Array<OrderDietaryWarningModel>, product, subAccountOrdinal = null) {
    this.eventBroadcastingService.hideInvoiceLoader.emit();
    const modalRef = this.modalService.getModalWrapper(OrderDietaryRestrictionComponent);
    const dietaryWarningModalRef = modalRef.show({
      animated: false,
      keyboard: false,
      class: 'vertical-center modal-lg',
      initialState: {
        orderDietaryRestrictions: res
      }
    });

    dietaryWarningModalRef.close.subscribe(response => {
      var orderDietaryRestrictions = response.orderDietaryRestrictions;
      if (orderDietaryRestrictions?.length) {
        var mainProduct = _.find(orderDietaryRestrictions, (warning) => {
          return warning.SalesProductId === product.SalesProductId.Value;
        });
        if (subAccountOrdinal > 0) {
          product = _.find(this.orderData.OrderItems, (orderItem) => {
            return orderItem.SalesProductId.Value === product.SalesProductId.Value && orderItem.SubAccountOrdinal == subAccountOrdinal;
          });
        }
        if (mainProduct) {
          this.deleteItemFromOrder(product);
        } else {
          this.showOrderInvoiceLoader();
          this.orderService.updateDietaryWarningConflictProductsAsync(this.orderData.Id.Value, product.Id.Value, orderDietaryRestrictions)
            .subscribe((res) => {
              this.getOrderDetails(this.orderData.Id.Value);
            });
        }
      }

    });
  }

  getCommentWarnings() {
    this.spinnerService.show();
    this.commentWarningService.getCommentWarnings()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next:
          response => {
            this.commentWarnings = response;
          }, error: this.alertService.showApiError
      });
  }

  checkRestrictedComment(commentText: string) {
    if (this.commentWarnings && this.commentWarnings.length > 0) {
      let commentWarningMatch = _.find(this.commentWarnings, (warning) => {
        return warning.CommentTextMatch.trim().toLowerCase() == commentText.trim().toLowerCase();
      });
      return commentWarningMatch;
    }
    return false;
  }

  enterCommentForItem(product, orderProductName) {
    const orderKeyboardOptions = { title: "Enter Comment for " + orderProductName, maxLength: 90 };
    const comment = '';
    const keyboardModalRef = this.modalService.show(KeyboardComponent, {
      animated: false,
      class: 'vertical-center modal-max-width-95',
      initialState: {
        keyboardId: 'memoKeyboard',
        isOpen: true,
        options: orderKeyboardOptions,
        value: comment
      }
    });
    keyboardModalRef.close.subscribe(res => {
      if (res?.value) {
        this.cameraAnnotationService.addAnnotationToCamera(this.cameraAnnotations.CommentAdd, {
          Comment: res.value,
          OrderProductName: orderProductName,
          OrderId: this.orderData.SurrogateOrderId,
          OrderProductId: product.Id.Value
        });
        let commentWarningMatch = this.checkRestrictedComment(res.value);
        if (commentWarningMatch) {
          this.openCommentWarningModal(commentWarningMatch, product, orderProductName, res.value);
        } else {
          this.addComment(product, orderProductName, res.value);
        }
      }
    });
  }

  addComment(product, orderProductName, commentText) {
    let component = this.orderService.getNewOrderProductComponent();
    component.MakeTableText = `C: ${commentText}`;
    product.OrderProductComponents.push(component);
    var comment = { Text: component.MakeTableText };
    this.orderService.addComment(this.orderData.Id.Value, product.Id.Value, comment)
      .subscribe({
        next: () => {
          this.getOrderDetails(this.orderData.Id.Value);
        }, error: this.alertService.showApiError
      });
  }

  openCommentWarningModal(commentWarningMatch, product, orderProductName, comment) {
    if (!commentWarningMatch.IsBlocked) {
      const modalRef = this.modalService.show(InfoModalComponent, {
        animated: false,
        class: 'vertical-center',
        initialState: {
          message: commentWarningMatch.WarningMessage,
          confirmButtonText: 'Ok',
          rejectButtonText: 'Continue Anyway',
          modalHeaderText: 'Warning'
        }
      });

      modalRef.close.subscribe((res) => {
        if (res && (res.shouldConfirm || res.event == 'close')) {
        } else {
          this.addComment(product, orderProductName, comment);
        }
      });
    } else {
      const modalRef = this.modalService.show(InfoModalComponent, {
        animated: false,
        class: 'vertical-center',
        initialState: {
          message: commentWarningMatch.WarningMessage,
          confirmButtonText: 'Ok',
          modalHeaderText: 'Warning'
        }
      });
    }
  }

  showInvoice() {
    // if ($("#bs-example-navbar-collapse-1").hasClass("in") == false) {
    $(".order-section__summary--fixed").toggle(300, function () {
      var orderSummarydiv = $(".order-section__summary--fixed");
      if (orderSummarydiv.css("display").toLowerCase() == "block") {
        // alert(orderSummarydiv.css('display').toLowerCase());
        $(".navbar-toggle").prop("disabled", true);
      }
      if (orderSummarydiv.css("display").toLowerCase() == "none") {
        // alert(orderSummarydiv.css('display').toLowerCase());
        $(".navbar-toggle").prop("disabled", false);
      }
      // here you code after toggle complete
    });
    $(".navbar-toggle").addClass("collapsed");
    $(".navbar-collapse").removeClass("in");

    $(".navbar-collapse").addClass("collapse");

    this.openInvoice();
    // }
  }

  timedProduct(product) {
    this.showInvoicePopover = false;
    const modalRef = this.modalService.getModalWrapper(TimeEntryComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center modal-lg',
      initialState: {
        title: 'Schedule Product',
        instruction: Messages.TimedProductInstructions,
        scheduleDate: product.ScheduleTime,
        isRescheduleOrder: (product.ScheduleTime && new Date(product.ScheduleTime) > new Date())
      }
    });
    modal.close.subscribe((response) => {
      if (response && response.enteredTime) {
        this.cameraAnnotationService.addAnnotationToCamera(this.cameraAnnotations.ScheduleProduct, {
          OrderId: this.orderData.SurrogateOrderId,
          OrderProductName: product.MakeTableText,
          OrderProductId: product.Id.Value,
          ScheduleTime: (response.enteredTime ? (this.tarkDateTimePipe.transform(Date.parse(new Date(response.enteredTime).toString()))) : '')
        });
        this.updateScheduleDate(product.Id.Value, response.enteredTime);
      }
    });
  }

  updateScheduleDate(productId: number, scheduleDate: Date) {
    this.spinnerService.show();
    this.orderService.updateProductSchedule(this.orderData.Id.Value, productId, scheduleDate)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.getOrderDetails(this.orderData.Id.Value);
        }, error: this.alertService.showApiError
      });
  }

  overridePrice(product) {
    const modalRef = this.modalService.getModalWrapper(NumpadWrapperComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      initialState: {
        numpadId: "overridePriceModal",
        value: product.Price,
        numpadTitle: 'Manual Amount',
        numpadOption: { prefix: this.settingParam.CurrencySymbol ?? '$' }
      }

    });
    modal.close.subscribe((response) => {
      if (response?.value && product.Price?.toFixed(2) !== parseFloat(response.value)?.toFixed(2)) {
        this.cameraAnnotationService.addAnnotationToCamera(this.cameraAnnotations.PriceOverride, {
          OverriddenAmount: parseFloat(response.value)?.toFixed(2),
          CurrentAmount: product.Price?.toFixed(2),
          OrderProductName: product.MakeTableText,
          OrderId: this.orderData.SurrogateOrderId,
          OrderProductId: product.Id.Value
        });
        product.Price = response.value;
        this.orderService.overridePrice(this.orderData.Id.Value, product.Id.Value, product.Price)
          .subscribe({
            next: () => {
              this.getOrderDetails(this.orderData.Id.Value);
            }, error: (err) => {
              this.alertService.showApiError(err);
              this.errorHandler(err);
            }
          });
      }
    });
  }

  checkIsSendAllProduct(): boolean {
    if (this.orderData && this.orderData.Id.Value) {
      let isDoneAllProducts = true;
      if (!this.isUseMakeTable) {
        let orderProductItems = [];
        _.forEach(this.seats, (item) => {
          _.forEach(item, (product) => {
            if (product.OrderItemType == this.orderItemTypes.Product) {
              let orderProduct = this.prepareOrderProductForLabelPrint(product);
              orderProductItems.push(orderProduct);
            }
          });
        });
        if (orderProductItems && orderProductItems.length > 0) {
          this.submitProductLabelPrint(orderProductItems);
        } else {
          this.loggerService.logWarning($`Trying to call label print from orderInvoiceComponent.checkIsSendAllProduct with orderProductItems: 
            ${orderProductItems?.length}`);
        }
      }
      _.forEach(this.orderData.OrderItems, (item) => {
        if (!item.IsSentToKitchen || item.IsDirty) {
          isDoneAllProducts = false;
        }
      });
      return isDoneAllProducts;
    } else {
      return true;
    }
  }

  openConfirmServeOrderModel() {
    let modalRef = null;
    const isScheduledOrder = this.orderData.ScheduleDate ? new Date(this.orderData.ScheduleDate) > new Date() : false;
    const hasScheduledProducts = find(this.orderData.OrderItems,
      orderItem => orderItem.ScheduleTime && new Date(orderItem.ScheduleTime) > new Date());
    const isScheduled = isScheduledOrder || hasScheduledProducts;
    const scheduleMessage = isScheduledOrder ? Messages.OrderIsScheduled : Messages.OrderHasScheduledProductsMessage;
    if (isScheduled) {
      modalRef = this.openModal(scheduleMessage, 'OK', null, 'Info');
    } else {
      modalRef = this.settingParam.IsUseMakeTable ? this.openModal(Messages.OrderPaidFull, 'OK', null, 'Info') : this.openModal(Messages.OrderAlreadyPaid, 'Yes', 'No', 'Confirm');
    }
    modalRef.close.subscribe((res) => {
      if (res?.shouldConfirm && !isScheduled && !this.settingParam.IsUseMakeTable) {
        this.serveOrder();
      }

      if (!isScheduled) {
        this.startANewOrder();
      }
    });

  }

  private openModal(message: string, confirmButtonText, rejectButtonText, modalHeaderText) {
    return this.modalService.show(InfoModalComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        message: message,
        confirmButtonText: confirmButtonText,
        rejectButtonText: rejectButtonText,
        modalHeaderText: modalHeaderText
      }
    });

  }

  sentToKitchen() {
    if (this.orderData && this.orderData.Id.Value && this.checkDrawerAndTerminalMapped()) {
      this.cameraAnnotationService.addAnnotationToCamera(this.cameraAnnotations.SendOrder, {
        OrderId: this.orderData.SurrogateOrderId,
      });
      if (!this.checkIsSendAllProduct()) {
        this.updateIsSendToKitchen();
      } else if (!this.orderData.DueAmount || this.orderData.DueAmount <= 0) {
        this.openConfirmServeOrderModel();
      }
    }
  }

  updateIsSendToKitchen(reloadOrder = true) {
    if (this.orderData && this.orderData.Id.Value) {
      this.spinnerService.show();
      this.orderService.updateIsSentToKitchen(this.orderData.Id.Value)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res: OrderInvoice) => {
            if (reloadOrder) {
              this.getOrderDetails(this.orderData.Id.Value);
            }
          }
        });
    }
  }

  checkDrawerAndTerminalMapped(): boolean {
    if (!this.isCashdrawerMapped && !this.isAllowCreditCard && this.settingParam.AllowCreditCards) {
      const modalRef = this.modalService.show(InfoModalComponent, {
        animated: false,
        class: 'vertical-center',
        initialState: {
          message: Messages.CashDrawerAndCreditCardTerminalNotMapped
        }
      });
      return false;
    } else if (!this.isAllowCreditCard && this.settingParam.AllowCreditCards) {
      const modalRef = this.modalService.show(InfoModalComponent, {
        animated: false,
        class: 'vertical-center',
        initialState: {
          message: Messages.CreditCardTerminalNotMapped
        }
      });
      return false;
    } else if (!this.isCashdrawerMapped) {
      const modalRef = this.modalService.show(InfoModalComponent, {
        animated: false,
        class: 'vertical-center',
        initialState: {
          message: Messages.CashDrawerNotMapped
        }
      });
      return false;
    } else {
      return true;
    }
  }

  preparePaymentDetails(amount: number) {
    const paymentDetails: any = {
      Id: 0,
      OrderId: this.orderData.Id.Value,
      Amount: parseFloat(amount.toString()).toFixed(2),
      PaymentTypeId: DomainConstants.PaymentTypes.ONLINEORDER,
      IsCompleted: false,
      UserId: this.applicationStateService.userDetails.id,
      IsCaptured: false,
      CashDrawerId: this.settingParam.CashDrawerPrinter.Id,
      SubAccountOrdinals: [],
      TerminalId: this.applicationStateService.terminalId,
      TerminalName: this.applicationStateService.terminalName,
      SurrogateOrderId: this.orderData.SurrogateOrderId,
    };
    return paymentDetails;
  }

  private getTipAmount() {
    let tipAmount = 0;
    this.orderData.AuthorizeTransactions?.forEach((transaction) => {
      tipAmount += transaction.IsCaptured ? 0 : transaction.Tip;
    });
    return tipAmount;
  }

  private getAuthorizedAmount() {
    let authorizedAmount = 0;
    this.orderData.AuthorizeTransactions?.forEach((transaction) => {
      authorizedAmount += transaction.IsCaptured ? 0 : transaction.AuthorizedAmount;
    });
    return authorizedAmount;
  }

  capturePayment() {
    this.spinnerService.show();
    const paymentDetails = this.preparePaymentDetails(this.orderData.GrandTotal >
      this.getAuthorizedAmount()
      ? this.getAuthorizedAmount() : this.orderData.GrandTotal);
    this.orderService.capturePayment(this.orderData.Id.Value, paymentDetails)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: any) => {
          if (this.getAuthorizedAmount() >= this.orderData.GrandTotal) {
            if (!this.checkIsSendAllProduct()) {
              this.updateIsSendToKitchen(false);
            }
            this.startANewOrder();
          } else {
            this.getOrderDetails(this.orderData.Id.Value);
          }
        }, error: this.alertService.showApiError
      });
  }

  openCapturePaymentModel = () => {
    const tipAmount = this.getTipAmount();
    const modalRef = this.modalService.show(InfoModalComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        message: this.orderData.GrandTotal > this.getAuthorizedAmount() ? StringUtils.format(
          Messages.ConfirmCaptureAuthorizeAmountWithBalance, {
          'amount': this.getAuthorizedAmount().toFixed(2),
          'authorizedAmount': (this.getAuthorizedAmount() + tipAmount).toFixed(2),
          'balance': (this.orderData.GrandTotal - this.getAuthorizedAmount()).toFixed(2),
          'currencySymbol': this.settingParam.CurrencySymbol,
          'tip': tipAmount > 0 ? `Tip: <b>${this.currencySymbol}${tipAmount.toFixed(2)}</b>` : ''
        }) : StringUtils.format(
          Messages.ConfirmCaptureAuthorizeAmount, {
          'amount': this.orderData.GrandTotal.toFixed(2),
          'authorizedAmount': (this.getAuthorizedAmount() + tipAmount) ?
            (this.getAuthorizedAmount() + tipAmount).toFixed(2) : 0,
          'currencySymbol': this.settingParam.CurrencySymbol,
          'tip': tipAmount > 0 ? `Tip: <b>${this.currencySymbol}${tipAmount.toFixed(2)}</b>` : ''
        }),
        // message: Messages.ConfirmCaptureAuthorizeAmount,
        confirmButtonText: 'Capture',
        rejectButtonText: 'Cancel',
        modalHeaderText: 'Charge Card'
      }
    });

    modalRef.close.subscribe((res) => {
      if (res && (res.shouldConfirm)) {
        this.capturePayment();
      }
    });
  }

  checkPromptForOrderName(): boolean {
    const requireNameOnOrder = this.settingParam.RequireNameOnOrder;
    if (requireNameOnOrder === DomainConstants.RequireNameOnOrder.AT_RING_UP && this.settingParam.RequireNameOnOrderTemporaryDisable) {
      const orderName = this.orderData.OrderAttributes.Name;
      if (!orderName) {
        return true;
      }
    }
  }

  promptForOrderName() {
    this.orderKeyboardOptions.title = "Enter order name";
    this.isOpen = true;
    this.keyboardValue = '';
    const keyboardModalRef = this.modalService.show(KeyboardComponent, {
      animated: false,
      class: 'vertical-center modal-max-width-95',
      initialState: {
        keyboardId: 'memoKeyboard',
        isOpen: true,
        options: this.orderKeyboardOptions,
        value: this.keyboardValue
      }
    });
    keyboardModalRef.close.subscribe(res => {
      if (res?.value) {
        this.addNameToOrder(res);
      }
    });
  }

  addNameToOrder(res) {
    this.keyboardValue = res.value;
    let orderCommand = {
      type: DomainConstants.SpecialFunctionBusinessCommand.SetName,
      value: this.keyboardValue,
      screenChoiceId: 0,
      terminalId: this.applicationStateService.terminalId
    };
    this.eventBroadcastingService.onAddItemStart.emit();
    this.orderService.specialFunction(this.orderData.Id.Value, orderCommand)
      .subscribe({
        next: (res: any) => {
          this.eventBroadcastingService.onAddItemComplete.emit(res.Id.Value);
          this.checkAndOpenSettle();
        }, error: this.alertService.showApiError
      });
  }

  settleOrderPayment = () => {
    if (this.orderData?.Id?.Value && this.checkPromptForOrderName()) {
      this.promptForOrderName();
      return;
    }
    if (this.settingParam.VerifyOrderBeforeSettle && this.orderData.Id.Value && !this.orderData.IsVerified) {
      this.openReviewOrder(() => {
        this.rabbitMQService.sendOrderReviewedMessage(this.applicationStateService.terminalId, this.orderData);
        this.settleOrderPayment();
        this.isReviewOrder = true;
      });
      return;
    }
    this.cameraAnnotationService.addAnnotationToCamera(this.cameraAnnotations.RingUp, {
      OrderId: this.orderData.SurrogateOrderId,
      DueAmount: this.orderData.DueAmount?.toFixed(2)
    });
    this.checkAndOpenSettle();
  }

  openSettleComponent() {
    const modalRef = this.modalService.getModalWrapper(SettleComponent);
    const modal = modalRef.show({
      animated: false,
      keyboard: false,
      class: 'vertical-center modal-max-width-95',
      initialState: {
        orderId: this.orderData && this.orderData.Id ? this.orderData.Id.Value : null,
        promotionSummary: this.promotionSummary
      }
    });
    modal.close.subscribe((response) => {
      if (this.orderData && this.orderData.Id.Value) {
        this.getOrderDetails(this.orderData.Id.Value, () => {
          if (!this.orderData?.DueAmount) {
            this.startANewOrder();
          }
        });
      }
    });
  }

  checkAndOpenSettle() {
    this.promotionSummary = []
    if (this.orderData && this.orderData.Id && this.orderData.Id.Value && (!this.orderData.DueAmount || this.orderData.DueAmount <= 0)) {
      this.openConfirmServeOrderModel();
    } else if (this.orderData.AuthorizeTransactions?.filter(x => !x.IsCaptured)?.length) {
      this.openCapturePaymentModel();
    } else if (this.checkDrawerAndTerminalMapped()) {
      if (this.orderData?.Id?.Value && this.settingParam?.IsUsePromotion) {
        this.spinnerService.show();
        this.orderService.applyPromotion(this.orderData?.Id?.Value)
          .pipe(finalize(() => {
            this.spinnerService.hide();
          }))
          .subscribe({
            next: (res: Array<OrderProductPromotion>) => {
              this.promotionSummary = res?.length ? res : [];
              this.openSettleComponent();
            }, error: this.alertService.showApiError
          });
      } else {
        this.openSettleComponent();
      }
    }
  }
  cancelOrder() {
    if (this.orderData && this.orderData.Id && this.orderData.Id.Value) {
      const modalRef = this.modalService.show(InfoModalComponent, {
        animated: false,
        class: 'vertical-center',
        initialState: {
          message: Messages.ConfirmCancelOrder,
          confirmButtonText: 'Yes',
          rejectButtonText: 'No',
          modalHeaderText: 'Confirm'
        }
      });


      modalRef.close.subscribe((res) => {
        if (res && (res.shouldConfirm)) {
          this.cameraAnnotationService.addAnnotationToCamera(this.cameraAnnotations.CancelOrder, {
            OrderId: this.orderData.SurrogateOrderId,
          });
          this.eventBroadcastingService.onDeleteOrder(this.orderData.Id.Value);
          this.orderService.deleteOrder(this.orderData.Id.Value)
            .subscribe({
              next: () => {
                this.isReviewOrder = true;
              }, error: this.alertService.showApiError
            });
          this.startANewOrder();
        } else {
        }
      });
    }
  }

  prepareOrderProductForLabelPrint(orderProduct): any {
    let product: any = {};
    const orderName = this.orderData.OrderAttributes.Name;
    const subaccountName = find(this.orderData.OrderSubaccounts, (subaccount) => subaccount.SubaccountOrdinal == orderProduct.SubAccountOrdinal)?.SubaccountName;
    product.OrderName = (subaccountName ? subaccountName : orderName);
    product.IsManuallyPrint = true;
    product.IsFromMakeTable = false;
    product.OrderId = this.orderData.Id.Value;
    product.SurrogateOrderId = this.orderData.SurrogateOrderId;
    product.ScreenName = orderProduct.MakeTableText;
    product.ReceiptText = orderProduct.ReceiptText;
    product.SalesProductID = orderProduct.SalesProductId.Value;
    product.IsSentToKitchen = orderProduct.IsSentToKitchen;
    product.IsDirty = orderProduct.IsDirty;
    product.Qty = orderProduct.Qty;
    let components = [];
    _.forEach(orderProduct.OrderProductComponents, (component) => {
      components.push({ ComponentName: component.MakeTableText, ReceiptText: component.ReceiptText });
    });
    product.Items = components;
    return product;
  }

  productLabelPrint(orderProduct) {
    this.cameraAnnotationService.addAnnotationToCamera(this.cameraAnnotations.LabelPrint, {
      OrderProductName: orderProduct.MakeTableText,
      OrderId: this.orderData.SurrogateOrderId,
      OrderProductId: orderProduct.Id.Value
    });
    this.showInvoicePopover = false;
    let orderProductItems = [];
    if (this.productLabelPrinting == 2) {
      const product: any = this.prepareOrderProductForLabelPrint(orderProduct);
      orderProductItems.push(product);

      if (orderProductItems?.length) {
        this.submitProductLabelPrint(orderProductItems);
      } else {
        this.loggerService.logWarning($`Trying to call label print from orderInvoiceComponent.productLabelPrint with orderProductItems: 
          ${orderProductItems?.length} for orderId ${product.OrderId}, surrogateId ${product.SurrogateOrderId} 
          product ${product.ReceiptText} SalesProductID ${product.SalesProductID}`);
      }
    }
  }

  submitProductLabelPrint(orderProductItems: Array<any>) {
    this.orderService.productLabelPrint(orderProductItems)
      .subscribe(function () {
      });
  }

  serveOrder() {
    // this.spinnerService.show();
    if (this.orderData?.Id?.Value) {
      this.orderService.serveOrder(this.orderData.Id.Value, this.userDetails.id)
        .pipe(finalize(() => {
          // this.spinnerService.hide();
        }))
        .subscribe((res: OrderInvoice) => {
        });
    }
  }

  openReviewOrder = (callback = null) => {
    this.isReviewOrder = true;
    this.rabbitMQService.sendReviewOrderMessage(this.applicationStateService.terminalId, this.orderData, this.seats);
    const modalRef = this.modalService.getModalWrapper(ReviewOrderComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center modal-lg',
      initialState: {
        seats: this.seats,
        orderData: this.orderData
      }
    });
    modal.close.subscribe((response) => {
      if (response && response.isConfirmed) {
        const defaultCallBack = () => {
          this.isReviewOrder = true;
          this.rabbitMQService.sendOrderReviewedMessage(this.applicationStateService.terminalId, this.orderData);
        }
        this.orderService.confirmOrder(this.orderData.Id.Value, true)
          .subscribe(() => {
            this.getOrderDetails(this.orderData.Id.Value, callback ?? defaultCallBack);
          });
      } else {
        this.orderService.confirmOrder(this.orderData.Id.Value, false)
          .subscribe(() => {
            this.getOrderDetails(this.orderData.Id.Value, () => this.isReviewOrder = true);
          });
      }
    });


  }

  reviewOrder() {
    if (this.orderData && this.orderData.Id && this.orderData.Id.Value) {
      this.cameraAnnotationService.addAnnotationToCamera(this.cameraAnnotations.ReviewOrder, {
        OrderId: this.orderData.SurrogateOrderId,
      });
      this.getOrderDetails(this.orderData.Id.Value, this.openReviewOrder);
    }
  }

  toggleInvoicePopover(product): void {
    this.showInvoicePopover = true;
    this.orderProduct = product;
  }

  printNutritionFacts(product) {
    if (this.settingParam.ReceiptPrinter?.Id) {
      this.cameraAnnotationService.addAnnotationToCamera(this.cameraAnnotations.NutritionFactsPrint, {
        OrderId: this.orderData.SurrogateOrderId,
        OrderProductId: product?.Id?.Value,
        OrderProductName: product.MakeTableText
      });
      this.showInvoicePopover = false;
      const printOrderNutritionFactModel = {
        OrderProductId: product?.Id?.Value,
        OrderId: this.orderData?.Id?.Value,
        TerminalId: this.applicationStateService.terminalId,
        TerminalName: this.applicationStateService.terminalName
      }
      this.spinnerService.show();
      this.orderService.printOrderNutritionFacts(this.orderData.Id.Value, product?.Id?.Value, printOrderNutritionFactModel)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res) => {
          }, error: this.alertService.showApiError
        });
    } else {
      this.alertService.renderErrorMessage(Messages.ReceiptPrinterNotMapped);
    }
  }

  openSubaccountNameModel(subAccountOrdinal: number) {
    this.orderKeyboardOptions.title = `Enter ${this.subaccountTerm.toLocaleLowerCase()} name`;
    this.isOpen = true;
    this.keyboardValue = '';
    const keyboardModalRef = this.modalService.show(KeyboardComponent, {
      animated: false,
      class: 'vertical-center modal-max-width-95',
      initialState: {
        keyboardId: 'memoKeyboard',
        isOpen: true,
        options: this.orderKeyboardOptions,
        required: false,
        value: this.keyboardValue
      }
    });
    keyboardModalRef.close.subscribe(res => {
      if (!res.isSubmitted) {
        return;
      }
      if (this.orderSubaccount[subAccountOrdinal]) {
        this.orderSubaccount[subAccountOrdinal].SubaccountName = res.value ? res.value : null;
      } else {
        const subaccount = this.orderService.setOrderSubaccount(this.orderData.Id.Value, res.value, subAccountOrdinal);
        this.orderSubaccount[subAccountOrdinal] = subaccount;
      }
      this.cameraAnnotationService.addAnnotationToCamera(this.cameraAnnotations.RenameSubaccount, {
        OrderId: this.orderData.SurrogateOrderId,
        SubaccountName: this.orderSubaccount[subAccountOrdinal].SubaccountName
      });
      this.orderService.updateSeat(this.orderSubaccount[subAccountOrdinal]).subscribe((response) => {
      });
    });
  }

  getItemsCount(productId: number = null) {
    let orderItemsExcludingFees = this.orderData.OrderItems.filter(x => x.OrderItemType !== this.orderItemTypes.SingularFee);
    if (productId) {
      orderItemsExcludingFees = orderItemsExcludingFees.filter(x => x.Id.Value !== productId);
    }
    if (!orderItemsExcludingFees?.length && !this.attributesExists()) {
      this.startANewOrder();
    }
    return orderItemsExcludingFees?.length;
  }

  deleteCallIn() {
    this.orderData.OrderAttributes.IsCallIn = false;
    this.orderData.OrderAttributes.CallInOrderTime = '';
    this.inflightPriceModifier = true;
    this.orderService.deleteCallIn(this.orderData.Id.Value)
      .pipe(finalize(() => {
        this.inflightPriceModifier = false;
      }))
      .subscribe({
        next: () => {
          this.getOrderDetailsOrStartNewOrder();
        }, error: (err) => {
          this.alertService.showApiError(err);
          this.errorHandler(err);
        }
      });
  }

  deleteName() {
    this.orderData.OrderAttributes.Name = '';
    this.inflightPriceModifier = true;
    this.orderService.deleteName(this.orderData.Id.Value)
      .pipe(finalize(() => {
        this.inflightPriceModifier = false;
      }))
      .subscribe({
        next: () => {
          this.getOrderDetailsOrStartNewOrder();
        }, error: (err) => {
          this.alertService.showApiError(err);
          this.errorHandler(err);
        }
      });
  }

  deletePager() {
    this.inflightPriceModifier = true;
    this.orderService.deletePager(this.orderData.Id.Value)
      .pipe(finalize(() => {
        this.inflightPriceModifier = false;
      }))
      .subscribe({
        next: () => {
          this.getOrderDetailsOrStartNewOrder();
        }, error: (err) => {
          this.alertService.showApiError(err);
          this.errorHandler(err);
        }
      });
  }

  deletePhoneNumber() {
    this.orderData.OrderAttributes.PhoneNumber = '';
    this.inflightPriceModifier = true;
    this.orderService.deletePhoneNumber(this.orderData.Id.Value)
      .pipe(finalize(() => {
        this.inflightPriceModifier = false;
      }))
      .subscribe({
        next: () => {
          this.getOrderDetailsOrStartNewOrder();
        }, error: (err) => {
          this.alertService.showApiError(err);
          this.errorHandler(err);
        }
      });
  }

  getOrderDetailsOrStartNewOrder() {
    if (this.orderData?.Id.Value > 0 && ((this.orderData.OrderItems.length > 0 && this.getItemsCount()) || this.attributesExists())) {
      this.getOrderDetails(this.orderData.Id.Value);
    } else {
      this.startANewOrder();
    }
  }

  attributesExists() {
    return this.orderData.OrderAttributes.IsCallIn || this.orderData.OrderAttributes.Name || this.orderData.OrderAttributes.PhoneNumber;
  }

  setIsComboProduct(groupId: number) {
    let isSameGroup = (groupId != null && groupId == this.currentGroupId);
    if (groupId == this.firstGroupId) {
      this.firstGroupId = null;
      isSameGroup = false;
    }
    this.currentGroupId = groupId;
    return isSameGroup;
  }

  changeComboItemQty(event, subAccountOrdinal) {
    const products: Array<OrderInvoiceOrderProduct> = this.seats[subAccountOrdinal]?.filter(x => x.GroupId == event.groupId);
    const productBeforeUpdate = { ...products[0] };
    const updatedProducts = this.setProductQty(products, event);

    this.isChangingQty = true;
    const increment = productBeforeUpdate.SalesProductBusinessEntity?.Increment > 0 ? productBeforeUpdate.SalesProductBusinessEntity.Increment : 1;
    if ((event.isIncreaseItem != null && !event.isIncreaseItem && productBeforeUpdate.Qty - increment <= 0) || (event.newQty <= 0)) {
      this.deleteComboItemFromOrder(event.groupId, products[0]);
    } else {
      let oldQty = Math.round(productBeforeUpdate.Qty / (productBeforeUpdate.SalesProductBusinessEntity?.Increment ?? 1));
      let newQty = Math.round(products[0].Qty / (products[0].SalesProductBusinessEntity?.Increment ?? 1));
      this.comboProductQuantityChangeCameraAnnotation(products[0], event.oldQty ?? oldQty, event.newQty ?? newQty);
      this.sendQtyChangeRequest(null, event, updatedProducts);
    }
  }

  private setProductQty(products: Array<OrderInvoiceOrderProduct>, event) {
    forEach(products, product => {
      if (this.settingParam.QuantityChange == DomainConstants.QuantityChange.PLUS_MINUS_BUTTONS) {
        const increment = product.SalesProductBusinessEntity?.Increment > 0 ? product.SalesProductBusinessEntity.Increment : 1;
        if (event.isIncreaseItem) {
          product.Qty = this.mathsUtilityService.floatSafeModulus(increment, 1) ? (parseFloat(product.Qty) + increment).toFixed(2) : parseFloat(product.Qty) + increment;
        } else {
          this.calculateChangedQty(product, increment);
        }
      } else {
        product.Qty = event.newQty;
      }
    });
    return products;
  }

  deleteComboItemFromOrder(groupId: number, product: OrderInvoiceOrderProduct) {
    this.cameraAnnotationService.addAnnotationToCamera(this.cameraAnnotations.DeleteProductGroup, {
      GroupName: product.OrderProductGroup?.Name,
      OrderId: this.orderData.SurrogateOrderId,
      GroupId: product.GroupId
    });
    if (!this.inflightItemDeleteRequests && !this.inflightItemComponentDeleteRequests) {
      this.inflightItemDeleteRequests++;
      const modifiedOrderId = this.orderData.Id.Value;
      this.orderService.deleteOrderProductGroup(this.orderData.Id.Value, groupId)
        .pipe(finalize(() => {
          this.hideOrderInvoiceLoader();
        }))
        .subscribe({
          next: () => {
            this.setInflightItemDeleteRequests();
            if (this.orderData.Id && this.orderData.Id.Value > 0 && !this.inflightItemDeleteRequests &&
              (this.getItemsCount() || this.attributesExists())) {
              this.getOrderDetails(this.orderData.Id.Value);
            }
          }, error: (err) => {
            this.setInflightItemDeleteRequests();
            this.alertService.showApiError(err);
            this.errorHandler(err, modifiedOrderId);
          }
        });
    }
  }

  moveProductGroupToOtherSeat(groupId: number, targetSeat: number, subAccountOrdinal: number) {
    const products: Array<OrderInvoiceOrderProduct> = this.seats[subAccountOrdinal]?.filter(x => x.GroupId == groupId);
    const product: OrderInvoiceOrderProduct = { ...products[0] };
    const groupQty = this.settingParam.QuantityChange == DomainConstants.QuantityChange.PLUS_MINUS_BUTTONS ? Math.round(product.Qty / (product.SalesProductBusinessEntity?.Increment ?? 1)) : product.Qty;
    const sourceSubAccountName = this.orderSubaccount[product.SubAccountOrdinal]?.SubaccountName ?? this.subaccountTerm + ' ' + product.SubAccountOrdinal;
    const targetSubAccountName = this.orderSubaccount[targetSeat]?.SubaccountName ?? this.subaccountTerm + ' ' + targetSeat;
    this.orderService.moveProductGroupToOtherSeat(this.orderData.Id.Value, groupId, targetSeat, groupQty)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: Array<OrderDietaryWarningModel>) => {
          if (res?.length) {
            this.getOrderDetails(this.orderData.Id.Value);
            this.openDietaryRestrictionModal(res, product, targetSeat);
          } else {
            this.getOrderDetails(this.orderData.Id.Value);
          }
          this.addMoveProductGroupCameraAnnotations(product, sourceSubAccountName, targetSubAccountName);
        }, error: (err) => {
          this.alertService.showApiError(err);
          this.errorHandler(err);
        }
      });
  }

  addMoveProductGroupCameraAnnotations(product: OrderInvoiceOrderProduct, sourceSubAccountName: string, targetSubAccountName: string) {
    this.cameraAnnotationService.addAnnotationToCamera(this.cameraAnnotations.MoveProductGroup, {
      OrderId: this.orderData.SurrogateOrderId,
      GroupId: product.GroupId,
      SourceSubaccountName: sourceSubAccountName,
      DestinationSubaccountName: targetSubAccountName,
      GroupName: product.OrderProductGroup?.Name,
      Qty: product.Qty
    });
  }

  private setInflightItemDeleteRequests() {
    if (this.inflightItemDeleteRequests > 0) {
      this.inflightItemDeleteRequests--;
    } else {
      this.inflightItemDeleteRequests = 0;
    }
  }

  private setInflightItemQtyChangeRequests() {
    if (this.inflightItemQtyChangeRequests > 0) {
      this.inflightItemQtyChangeRequests--;
    } else {
      this.inflightItemQtyChangeRequests = 0;
    }
  }

  getGroupProductsStatus(groupId: number, items: Array<OrderInvoiceOrderProduct>) {
    return items.filter(x => x.GroupId == groupId).some(product => product.StatusId == this.orderProductStatus.ORDER_STATUS_NONE);
  }

  checkGroupItemsRemovePermission(groupId: number, items: Array<OrderInvoiceOrderProduct>) {
    return items.filter(x => x.GroupId == groupId).some(product => product.StatusId != 0 || product.IsSentToKitchen || product.IsDirty);
  }
}
