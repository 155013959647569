import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ParameterService, AlertsService, ApplicationStateService, SpinnerService,
  SettingParam, DomainConstants, Messages, BaseFormComponent, FormUtilityService
} from 'src/app/shared';
import * as _ from 'lodash';
import { FieldInfoMessages } from 'src/app/shared/constants';
import { finalize } from 'rxjs/operators';
import { ReloadSettingsService } from 'src/app/shared/services/reload-settings.service';
import { Router } from '@angular/router';
import { layoutBoxes } from 'src/app/shared/components/icon';
import { NgForm } from '@angular/forms';
import { PostSavedConfigurationService } from 'src/app/configurator/post-saved-configuration.service';

@Component({
  selector: 'pos-layout',
  templateUrl: './layout.component.html',
})
export class LayoutComponent extends BaseFormComponent implements OnInit {
  @ViewChild('formLayout') form: NgForm;
  get getForm(): NgForm {
    return this.form
  }
  parameters: SettingParam;
  fieldInfoMessages = FieldInfoMessages;
  functionLayout = [];
  functionButtons = DomainConstants.FunctionButtons;
  toasterPositions = DomainConstants.ToasterPositions;
  icons = {
    layoutBoxes
  }
  constructor(
    private spinnerService: SpinnerService,
    private parameterService: ParameterService,
    private alertsService: AlertsService,
    private applicationStateService: ApplicationStateService,
    private router: Router,
    private reloadSettingsService: ReloadSettingsService,
    formUtilityService: FormUtilityService,
    private postSavedConfigurationService: PostSavedConfigurationService,
  ) {
    super(formUtilityService);
    this.parameters = applicationStateService.newSettingParams();
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.spinnerService.show();
    this.parameterService.getParameterForTerminal(this.applicationStateService.terminalId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: SettingParam) => {
          this.parameters = res;
          if (this.parameters.FunctionButtons) {
            const functionButtons = this.parameters.FunctionButtons.split(',');
            _.forEach(functionButtons, (functionButton) => {
              if (functionButton) {
                const buttonDetails = {
                  Selection: functionButton
                };
                this.functionLayout.push(buttonDetails);
              }
            });
          }

          _.forEach(this.functionButtons, (button) => {
            if (this.functionLayout.length < 12) {
              const index = _.findLastIndex(this.functionLayout, (functionBtn) => {
                return functionBtn.Selection === button.Selection;
              });
              if (index < 0) {
                const obj = {
                  Name: button.Name,
                  Selection: button.Selection
                };
                this.functionLayout.push(obj);
              }
            }
            this.onFormChange(this.form);
          });
        }, error: this.alertsService.showApiError
      });
  }

  saveLayout(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.spinnerService.show();
    let functionButtons = '';
    _.forEach(this.functionLayout, (button) => {
      const functionButton = _.find(this.functionLayout, x => x.Selection === button.Selection);
      if (functionButton) {
        functionButtons += functionButton.Selection + ',';
      }
    });
    this.parameters.FunctionButtons = functionButtons;
    this.parameterService.saveLayouts(this.parameters)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.spinnerService.show();
          this.reloadSettingsService.reloadSettings(this.applicationStateService.terminalId)
            .pipe(finalize(() => {
              this.spinnerService.hide();
            }))
            .subscribe({
              next: () => {
                this.alertsService.renderSuccessMessage(Messages.LayoutSaveSuccess);
                this.postSavedConfigurationService.showMessage();
              }, error: this.alertsService.showApiError
            });
        }, error: this.alertsService.showApiError
      });
  }

  onFormChange($event: NgForm) {
    this.form = $event;
    this.setInitialValue();
  }

  cancelEditing() {
    this.router.navigate(['/manage/system-configuration']);
  }
}
