<pos-edit-wrapper [heading]="'Copy: ' + salesProductName" (close)="onCancel(false)">
    <div class="overflow-hidden">
        <form #copyProductForm="ngForm" (submit)='submit(copyProductForm.valid)' autocomplete="off">
            <div class="col-12 pos-modal-body form-horizontal screen-scroll">
                <pos-row>
                    <pos-column>
                        <pos-form-field label="New Product Name"
                            [validationMessages]="{required: 'Please enter product name.'}">
                            <input posInput class="form-control" id="salesProductName" name="salesProductName"
                                [(ngModel)]="salesProduct.ProductName" [required]="true" type="text" #name="ngModel" />
                        </pos-form-field>
                    </pos-column>
                    <pos-column>
                        <pos-form-field label="Copy The Following">
                            <p-checkbox posInput name="chkPrices" #chkPrices="ngModel" [disabled]="true"
                                [(ngModel)]="salesProduct.Prices" binary="true" label="Prices" class="ps-pix-15 pt-pix-3">
                            </p-checkbox>
                        </pos-form-field>
                    </pos-column>
                    <pos-column>
                        <pos-form-field>
                            <p-checkbox posInput name="chkRecipes" #chkRecipes="ngModel"
                                [(ngModel)]="salesProduct.IsCopyRecipe" binary="true" label="Recipes" class="ps-pix-15">
                            </p-checkbox>
                        </pos-form-field>
                    </pos-column>
                    <pos-column>
                        <pos-form-field>
                            <p-checkbox name="chkInstructions" #chkInstructions="ngModel" class="ps-pix-15"
                                [(ngModel)]="salesProduct.IsCopyInstruction" binary="true" label="Instructions">
                            </p-checkbox>
                        </pos-form-field>
                    </pos-column>
                </pos-row>
            </div>
            <div class="col-12 action-button-container popup-footer">
                <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" value="Copy">Copy</button>
                <button type="button" data-dismiss="modal" class="btn-secondary btn-lg btn btn-cancel-modal pos-button"
                    (click)="onCancel(false)" value="Cancel">Cancel</button>
            </div>
        </form>
    </div>
</pos-edit-wrapper>
