<pos-edit-wrapper heading="Cancel Order" [headingIcon]="icons.windowCancel" (close)="cancelEditing()" [isPopup]="false">
    <form #configForm="ngForm" class="edit-form" (ngSubmit)="saveConfig(configForm.valid)">
      <div class="col-12 pos-page-body border-top">
        <div class="col-12">
          <pos-row>
            <pos-column>
              <pos-form-field label="Button Text"
                [validationMessages]="{required: 'Please Enter Button Text.'}">
                <input posInput class="form-control" name="startOverButtonText" [(ngModel)]="parameters.OrderKioskStartOverButtonText"
                  [required]="true" type="text" #startOverButtonText="ngModel" />
              </pos-form-field>
            </pos-column>

              <pos-column>
                <pos-form-field label="Image" inputStyle="col-12">
                  <pos-row>
                    <pos-column md="5" sm="8" xs="12">
                      <input type="file" class="form-control" accept=".png,.jpg,.jpeg,.gif,.bmp" #image name="image"
                        id="image" (change)="handleFileInput($event.target.files)">
                    </pos-column>
                    <pos-column md="3" sm="4" xs="6">
                      <pos-row class="p-0">
                        <pos-column md="3" sm="5" xs="8" class="text-right pe-0" [hidden]="!showIcon">
                          <img src="{{imageBasePath}}/order-kiosk/default-screens/{{parameters.OrderKioskOrderCancelledImage}}" height="50px" #cancelOrderImage id="cancelOrderImage" />
                        </pos-column>
                        <pos-column sm="4" xs="2" class="mt-pix-n5" (click)="removeIcon()" [hidden]="!showIcon">
                          <pos-icon [icon]="icons.times" class="pointer remove-icon-badge"></pos-icon>
                        </pos-column>
                      </pos-row>
                    </pos-column>
                  </pos-row>
                </pos-form-field>
              </pos-column>
          </pos-row>
        </div>
      </div>
      <div class="col-xs-12 action-button-container page-footer">
        <div class="form-horizontal">
          <button class="btn btn-primary btn-lg btn-save pos-button" scrollup type="submit" value="Save">Save</button>
          <button class="btn btn-secondary btn-lg btn-cancel pos-button" routerLink="../" scrollup type="button"
            value="Cancel">Cancel</button>
        </div>
      </div>
    </form>
  </pos-edit-wrapper>
  