import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { LoginMessageModel } from '../../interface';
import { BaseCrudComponent, SpinnerService, AlertsService, Messages, FormUtilityService } from 'src/app/shared';
import { LoginMessagesService } from '../../services';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { loginMessagesCommentDots } from 'src/app/shared/components/icon';

@Component({
  selector: 'pos-login-messages-edit',
  templateUrl: './login-messages-edit.component.html',
  styleUrls: ['./login-messages-edit.component.scss']
})
export class LoginMessagesEditComponent extends BaseCrudComponent<LoginMessageModel> implements OnInit {

  @Input() id = 0;
  @ViewChild('formloginmessage', { static: true }) form: NgForm;
  loginMessageDetail: LoginMessageModel;
  icons = {
    loginMessagesCommentDots
  }
  get getForm(): NgForm {
    return this.form
  }

  constructor(private route: ActivatedRoute,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected loginMessageService: LoginMessagesService,
    formUtilityService: FormUtilityService,
    protected router: Router) {
    super(loginMessageService, alertService, spinnerService, formUtilityService);
    this.id = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
    this.scrollToTop();
  }

  ngOnInit() {
    this.loginMessageDetail = this.loginMessageService.newLoginMessage();
    this.saveSuccessMessage = Messages.LoginMessageSaveSuccess;
    this.dataSubscription();
  }

  private dataSubscription(): void {
    if (this.id > 0) {
      this.spinnerService.show();
      this.loadData().pipe(finalize(() => {
        this.spinnerService.hide();
      }))
        .subscribe({
          next: (res) => {
            this.loginMessageDetail = res;
          }, error: this.alertService.showApiError
        });
    } else {
      this.form.reset();
      this.loginMessageDetail = this.loginMessageService.newLoginMessage();
    }
  }

  public loadDependencies(): void { }

  public submit(isMessageLoginValid: boolean): void {
    if (!isMessageLoginValid) {
      return;
    }
    this.save(this.loginMessageDetail);
  }

  public onSaveSuccess(model: LoginMessageModel): void {
    this.id = 0;
    this.loginMessageDetail = this.loginMessageService.newLoginMessage();
    this.router.navigate(['login-messages'], { relativeTo: this.route.parent});
  }

  cancelEditing(): void {
    this.scrollToTop();
    this.router.navigate(['login-messages'], { relativeTo: this.route.parent });
  }

  private scrollToTop(): void {
    window.scrollTo(0, 0);
  }

}
