import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SpinnerService } from 'src/app/shared/components';
import { AlertsService, ModalFormComponent, ModalService, Messages, ConfirmDeleteModalComponent, RuntimeConstants, FormUtilityService } from 'src/app/shared';
import { SalesProductsService } from 'src/app/information-management/sales-products/services/sales-products.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DefaultSalesProduct } from '../../interface';
import { finalize } from 'rxjs/operators';
import { InstructionsService } from '../../services';
import {
  listAlt,
  clone
} from 'src/app/shared/components/icon';
import * as _ from 'lodash';
declare let $: any;
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'pos-copy-instructions',
  templateUrl: './copy-instructions.component.html',
  styleUrls: ['./copy-instructions.component.scss', '../../../../../styles/pages/instructions/instructions.scss']
})
export class CopyInstructionsComponent extends ModalFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.copyInstructionForm;
  }

  @ViewChild('copyInstructionForm') copyInstructionForm: NgForm;
  salesProducts: Array<DefaultSalesProduct> = [];
  selectedSizeId: number = null;
  isClickOnProduct: boolean = false;
  selectedSalesProductId: number = null;
  conflictedInstructions: any;
  @Input('copy') copy: any = {};
  productSizes: Array<any>;
  selectedTargetAreaId: number = null;
  isCopyArea: boolean = true;
  selectedSize: string = '';
  selectedProductName: string = '';
  availableSizes: Array<any> = [];
  copySize: string = '';
  filteredSourceAreas: Array<any> = [];
  height: number = null;
  imagePath = RuntimeConstants.IMAGE_BASE_PATH;
  selectedAreas: number = null;
  icons = {
    listAlt: listAlt,
    clone
  };
  tabList = {
    Area: 'Area',
    Instruction: 'Instruction'
  };
  selectedTab = this.tabList.Area;
  constructor(private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private salesProductService: SalesProductsService,
    private instructionService: InstructionsService,
    modalService: ModalService,
    modalRef: BsModalRef,
    formUtilityService: FormUtilityService) {
    super(modalRef, modalService, formUtilityService);
  }

  ngOnInit() {
    this.height = $(window).height() - 325;
    this.copy.from = {};
    this.copy.to = {};

    if (this.copy.salesProductId > 0) {
      this.loadActiveSalesProducts();
      this.getSalesProductIngredients(this.copy.salesProductId, this.copy.isCopyTo ? this.copy.from : this.copy.to, false);
      if (!this.copy.isCopyTo) {
        this.copy.to.salesProductId = this.copy.salesProductId;
      }
      this.productSizes = this.copy.Sizes;
      if (this.copy.SelectedSizeId && this.copy.Sizes && this.copy.Sizes.length > 0) {
        this.copySize = '(' + _.find(this.productSizes, (size) => {
          return size.Id === this.copy.SelectedSizeId;
        }).Name + ')';
      }

    }
    $(window).resize(() => {
      this.height = $(window).height() - 325;
    });
  }

  loadActiveSalesProducts() {
    this.spinnerService.show();
    this.salesProductService.getProductList()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          if (res) {
            this.salesProducts = res;
          }
        }, error: this.alertService.showApiError
      });
  }

  getSalesProductIngredients(salesProductId, copy, isSilentLoad) {
    if (!isSilentLoad) {
      this.spinnerService.show();
    }

    this.instructionService.getSalesProductIngredients(salesProductId)
      .pipe(finalize(() => {
        if (!isSilentLoad) {
          this.spinnerService.hide();
        }
      }))
      .subscribe({
        next: (res) => {
          if (res && res.length > 0) {
            copy.productName = res[0].SalesProductName;
            copy.instructionAreas = _.groupBy(res, 'InstructionAreaId');
            copy.areaList = [];

            _.forEach(Object.values(copy.instructionAreas), (areas: any) => {
              if ((!this.copy.isCopyTo || this.isClickOnProduct) && (this.selectedSizeId)) {
                if (areas && areas.length > 0 && areas[0].InstructionAreaId > 0 && areas[0].InstructionSizeId === this.selectedSizeId) {
                  copy.areaList.push({
                    Id: areas[0].InstructionAreaId,
                    SalesProductId: areas[0].SalesProductId,
                    SizeId: areas[0].InstructionSizeId,
                    Area: areas[0].InstructionAreaName,
                    InventorySubcategoryId: areas[0].InventorySubCategoryId,
                    AreaOrdinal: areas[0].InstructionAreaOrdinal
                  });
                }
              } else {
                if (areas && areas.length > 0 && areas[0].InstructionAreaId > 0 && areas[0].InstructionSizeId === this.copy.SelectedSizeId) {
                  copy.areaList.push({
                    Id: areas[0].InstructionAreaId,
                    SalesProductId: areas[0].SalesProductId,
                    SizeId: areas[0].InstructionSizeId,
                    Area: areas[0].InstructionAreaName,
                    InventorySubcategoryId: areas[0].InventorySubCategoryId,
                    AreaOrdinal: areas[0].InstructionAreaOrdinal
                  });
                }
              }
            });
            copy.areaList.sort((area1, area2) => {
              return area1.AreaOrdinal - area2.AreaOrdinal;
            });
            _.forEach(copy.instructionAreas, instruction => {
              _.forEach(instruction, i => {
                i.isSelected = false;
              })
            });
          }
        }, error: this.alertService.showApiError
      });
  }

  saveInstruction(instruction) {
    if (instruction.IngredientId) {
      this.spinnerService.show();
      this.instructionService.saveSalesProductInstruction(instruction, null)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: res => {
            this.checkSaveCompleted();
            if (!this.conflictedInstructions) {
              this.onCancel(true);
            }
          }, error: this.alertService.showApiError
        });
    }
  }

  saveAreaInstructions(area, instructions) {
    this.spinnerService.show();
    this.instructionService.saveSalesProductInstructionArea(area)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          this.checkSaveCompleted();
          let ordinal = 0;
          _.forEach(instructions, (instruction) => {
            ordinal++;
            const inst = {
              IngredientId: instruction.IngredientId,
              InstructionAreaId: res,
              InventoryProductId: instruction.InventoryProductId,
              IngredientImage: instruction.IngredientImage,
              IngredientInstruction: instruction.IngredientInstruction,
              Ordinal: ordinal
            }
            if (inst.IngredientId) {
              this.saveInstruction(inst);
            } else {
              if (!this.conflictedInstructions) {
                this.onCancel(true);
              }
            }
          });
        }, error: this.alertService.showApiError
      });
  }

  checkSaveCompleted() {
    if (this.spinnerService._requestCount === 0) {
      this.getSalesProductIngredients(this.copy.salesProductId, this.copy.isCopyTo ? this.copy.from : this.copy.to, true);
      this.getSalesProductIngredients(this.selectedSalesProductId, this.copy.isCopyTo ? this.copy.to : this.copy.from, true);
    }
  }

  onCopyInstructions() {
    const currentCopyFromSize = _.filter(this.productSizes, (size) => {
      return size.Id === this.selectedSizeId;
    });

    const currentCopyToSize = _.filter(this.productSizes, (size) => {
      return size.Id === this.copy.SelectedSizeId;
    });

    const targetArea = _.find(this.copy.to.areaList, { 'Id': this.selectedTargetAreaId });
    let confirmationMessage = '';
    if (this.copy.isCopyTo) {
      confirmationMessage = StringUtils.format(Messages.ConfirmCopyAreasToProduct,
        {
          'fromProduct': this.copy.from.productName + '(' + currentCopyToSize[0].Name + ')',
          'toProduct': this.copy.to.productName + '(' + currentCopyFromSize[0].Name + ')'
        });
    } else {
      confirmationMessage = StringUtils.format(Messages.ConfirmCopyAreasToProduct,
        {
          'fromProduct': this.copy.from.productName + '(' + currentCopyFromSize[0].Name + ')',
          'toProduct': this.copy.to.productName + '(' + currentCopyToSize[0].Name + ')',
          'targetArea': targetArea.Name
        });
    }

    const deleteAreaModalRef = this.modalService.show(ConfirmDeleteModalComponent, {
      animated: false,
      keyboard: false,
      class: 'vertical-center',
      initialState: {
        message: confirmationMessage,
      }
    });
    deleteAreaModalRef.close.subscribe(res => {
      if (res && res.shouldDelete) {
        this.onConfirmCopyInstructions();
      }
    });
  }

  onConfirmCopyInstructions() {
    let ordinal = Math.max.apply(Math, this.copy.to.instructionAreas[this.selectedTargetAreaId]
      .map((item) => {
        return item.IngredientOrdinal;
      })) + 1;

    let unConflictedInstructions = [];
    this.conflictedInstructions = '';
    _.forEach(Object.values(this.copy.from.instructionAreas), (instructions: any) => {
      _.forEach(_.filter(instructions, { 'isSelected': true }), (instruction: any) => {
        const areaInstruction = this.copy.to.instructionAreas[this.selectedTargetAreaId];
        const sameInstruction = _.filter(areaInstruction, (item: any) => {
          return item.InstructionAreaName === instruction.InstructionAreaName &&
            item.IngredientInstruction === instruction.IngredientInstruction &&
            instruction.InstructionSizeId === this.copy.SelectedSizeId;
        });

        if (sameInstruction.length === 0) {
          const inst = {
            IngredientId: instruction.IngredientId,
            InstructionAreaId: this.selectedTargetAreaId,
            InventoryProductId: instruction.InventoryProductId,
            IngredientImage: instruction.IngredientImage,
            IngredientInstruction: instruction.IngredientInstruction,
            Ordinal: ordinal
          };
          unConflictedInstructions.push(inst);
          ordinal++;
        } else {
          this.conflictedInstructions += "<li class='bullated-list'>" + instruction.IngredientInstruction + "</li>";
        }
      });
    });
    if (this.conflictedInstructions) {
      const errorMessage = 'Following Instruction(s) already exists in ' + this.copy.to.productName + " <br><ul class='pad20 ml-10 '>" +
        this.conflictedInstructions + "</ul>";
      $('#divSalesProductErrorMsg').show();
      $('#divSalesProductErrorMsg').html(errorMessage);
      setTimeout(() => {
        $('#divSalesProductErrorMsg').fadeOut('slow');
      }, 10000);
    }
    if (unConflictedInstructions && unConflictedInstructions.length !== 0) {
      const self = this;
      _.forEach(unConflictedInstructions, (instruction) => {
        self.saveInstruction(instruction);
      });
    }
  }

  changePanel(panel) {
    if (panel === 0) {
      this.isCopyArea = true;
    }
    if (panel === 1) {
      this.isCopyArea = false;
    }
  }

  onCopyAreas(selectedAreas) {
    const currentCopyFromSize = _.filter(this.productSizes, (size) => {
      return size.Id === this.selectedSizeId;
    });
    const currentCopyToSize = _.filter(this.productSizes, (size) => {
      return size.Id === this.copy.SelectedSizeId;
    });

    let confirmationMessage;
    if (this.copy.isCopyTo) {
      confirmationMessage = StringUtils.format(Messages.ConfirmCopyAreasToProduct,
        {
          'fromProduct': this.copy.from.productName + '(' + currentCopyToSize[0].Name + ')',
          'toProduct': this.copy.to.productName + '(' + currentCopyFromSize[0].Name + ')'
        });
    } else {
      confirmationMessage = StringUtils.format(Messages.ConfirmCopyAreasToProduct,
        {
          'fromProduct': this.copy.from.productName + '(' + currentCopyFromSize[0].Name + ')',
          'toProduct': this.copy.to.productName + '(' + currentCopyToSize[0].Name + ')'
        });
    }

    const deleteAreaModalRef = this.modalService.show(ConfirmDeleteModalComponent, {
      animated: false,
      keyboard: false,
      class: 'vertical-center',
      initialState: {
        message: confirmationMessage,
      }
    });
    deleteAreaModalRef.close.subscribe(res => {
      if (res && res.shouldDelete) {
        this.onConfirmCopyAreas(selectedAreas);
      }
    });
  }

  onConfirmCopyAreas(selectedAreas) {
    const areaLength = this.copy.to.areaList.length ? this.copy.to.areaList.length - 1 : this.copy.to.areaList.length;
    let ordinalArea = areaLength ? this.copy.to.areaList[areaLength].AreaOrdinal + 1 : 1;
    _.forEach(selectedAreas, (area) => {
      const instructions = this.copy.from.instructionAreas[area.Id];
      const sizeId = this.copy.isCopyTo ? this.selectedSizeId : this.copy.SelectedSizeId;
      const instructionArea = {
        SalesProductId: this.copy.to.salesProductId,
        SizeId: sizeId,
        Area: instructions[0].InstructionAreaName,
        InventorySubcategoryId: instructions[0].InventorySubCategoryId,
        Ordinal: ordinalArea
      };
      ordinalArea++;
      this.saveAreaInstructions(instructionArea, instructions);
    });
  }

  onChangeProduct(id, changeProduct) {
    if (this.copy.isCopyTo) {
      this.copy.to = {
        salesProductId: id
      };
    } else {
      this.copy.from = {};
    }

    this.isClickOnProduct = true;
    if (this.selectedSizeId) {
      this.selectedSize = '(' + _.find(this.productSizes, (size) => {
        return size.Id === this.selectedSizeId;
      }).Name + ')';
    } else {
      this.selectedSize = '';
    }
    this.getSalesProductIngredients(id, this.copy.isCopyTo ? this.copy.to : this.copy.from, false);
    if (changeProduct) {
      this.selectedSizeId = this.productSizes[0].Id;
    }
  }

  getIngredients() {
    if (this.salesProducts) {
      const salesProduct = _.filter(this.salesProducts, (product) => {
        return this.selectedSalesProductId === product.ID;
      });
      if (salesProduct && salesProduct.length) {
        this.selectedProductName = salesProduct[0].Name;
        this.spinnerService.show();
        this.salesProductService.getSalesProductSizes(salesProduct[0].ID)
          .pipe(finalize(() => {
            this.spinnerService.hide();
          }))
          .subscribe({
            next: (res) => {
              this.availableSizes = [];
              if (res) {
                _.forEach(res, (availableSize) => {
                  const productSize = $.grep(this.productSizes, (size) => {
                    return availableSize.SizeId === size.Id;
                  })[0];
                  if (productSize) {
                    this.availableSizes.push(productSize);
                  }
                });
                this.selectedSizeId = null;
              }
            }, error: this.alertService.showApiError
          });
      } else {
        if (!this.copy.isCopyTo) {
          this.selectedSizeId = null;
          this.copy.from = {};
        }
      }
    }
  }

  checkIsAvailable(area) {
    const availableInFilteredSourceAreas = _.find(this.filteredSourceAreas, (filteredArea) => {
      return filteredArea.Id === area.Id;
    });
    return availableInFilteredSourceAreas ? true : false;
  }

  onCancel(reload: boolean) {
    this.hide({ shouldReload: reload });
  }

}
