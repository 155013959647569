import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RuntimeConstants, SettingParam } from 'src/app/shared';

@Injectable({
  providedIn: 'root'
})
export class CardBasedLoginService {

  cardbasedLoginApiBasePath = `${RuntimeConstants.API_PATH}cardbasedlogin`;

  constructor(private httpClient: HttpClient) { }

  getCardbasedLoginInfo(terminalId: number): Observable<SettingParam> {
    return this.httpClient.get<SettingParam>(`${this.cardbasedLoginApiBasePath}/${terminalId}`);
  }

  saveCardbasedLogin(params: SettingParam): Observable<SettingParam> {
    return this.httpClient.post<SettingParam>(`${this.cardbasedLoginApiBasePath}`, params);
  }
}
