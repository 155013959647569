import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AlertsService, SpinnerService, DomainConstants, RuntimeConstants, ApplicationStateService, ModalFormComponent, ModalService, FormUtilityService } from 'src/app/shared';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { ScreenService } from 'src/app/shared/services/screen.service';
import { ScreenDetails } from '../../interface';
import { finalize, zip } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ExtensionValidationService } from 'src/app/shared/services/extension-validation.service';
import { list, screensDesktop, times } from 'src/app/shared/components/icon';

declare let $: any;

@Component({
  selector: 'pos-screen-edit',
  templateUrl: './screen-edit.component.html',
  styleUrls: ['./screen-edit.component.scss']
})
export class ScreenEditComponent extends ModalFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.form
  }
  tabList = {
    ScreenSettings: 'ScreenSettings',
    MultipleProductSetting: 'MultipleProductSetting'
  };
  selectedTab = this.tabList.ScreenSettings;

  isSaveAndContinue: boolean = false;
  screenId: number;
  screenParentId: number;
  choiceId: number;

  @ViewChild('screenDetailForm', { static: true }) form: NgForm;
  @ViewChild('screenImage') screenImage: ElementRef;
  screenDetails: ScreenDetails;
  screenList: Array<any> = [];
  startTime: any;
  endTime: any;
  image: File;
  screenLayouts = DomainConstants.ScreenButtonLayouts;
  buttonSizes = DomainConstants.ButtonSize;
  selectedLayout = null;
  imagePath = RuntimeConstants.IMAGE_BASE_PATH;
  currencySymbol = null;
  icons = {
    times,
    screensDesktop,
    list
  }
  pressApplyToProceed = Messages.PressApplyButtonToProceed;
  getHeight() {
    return $(window).height() > 531 ? 531 : $(window).height() - 213;
  }

  constructor(private screenService: ScreenService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    protected applicationStateService: ApplicationStateService,
    screenModalRef: BsModalRef,

    private extensionValidation: ExtensionValidationService,

    modalService: ModalService,
    formUtilityService: FormUtilityService) {
    super(screenModalRef, modalService, formUtilityService);

    this.screenDetails = screenService.newScreen();
    this.selectedLayout = this.screenLayouts.Fluid;
    this.currencySymbol = applicationStateService.settingParam.CurrencySymbol;
  }

  ngOnInit() {
    this.screenId = this.screenId ? Number(this.screenId) : 0;
    this.getScreenDetails();
    this.setInitialValue();
  }

  dateTimeToTime(timeString: string) {
    const time = timeString.split(':');
    return time[0] + ':' + time[1];
  }
  getScreenDetails() {
    this.form.reset();
    if (this.screenId) {
      this.spinnerService.show();
      zip(
        this.screenService.getScreensForTemporaryNavigation(this.screenId),

        this.screenService.getScreen(this.screenId),
        (
          screenTempData: any,
          screenData: any,
        ) => {
          this.screenList = screenTempData;
          this.screenDetails = screenData;

        }
      ).subscribe({
        next: (res) => {
          if (!this.screenDetails.ButtonSize) {
            this.screenDetails.ButtonSize = this.buttonSizes.Normal;
          }
          if (this.screenDetails.StartTime) {
            this.startTime = this.dateTimeToTime(this.screenDetails.StartTime);
          }
          if (this.screenDetails.EndTime) {
            this.endTime = this.dateTimeToTime(this.screenDetails.EndTime);
          }

          if (!this.screenDetails.Layout) {
            this.screenDetails.Layout = this.screenLayouts.Fluid;
          }

        }, error: this.alertService.showApiError,
        complete: () => {
          this.spinnerService.hide();
        }
      });
    }
    else {
      this.spinnerService.show();
      this.screenService.getScreens()
        .subscribe({
          next: (res) => {
            this.screenList = res;
          }, error: this.alertService.showApiError,
          complete: () => {
            this.spinnerService.hide();
          }
        });
    }
  }

  validScreenDetails() {

    if (this.screenDetails.MaxSelections && !this.screenDetails.MinSelections) {
      this.alertService.renderErrorMessage(Messages.ErrorWhileScreenSaved);
      return false;
    }

    if (this.screenDetails.IsPrice && this.startTime > this.endTime) {
      this.alertService.renderErrorMessage(Messages.ErrorWhileStartTimeIsLaterThanEndTime);
      return false;
    }

    return true;
  }

  saveScreenDetails(isValid) {
    if (!isValid || !this.validScreenDetails()) {
      return;
    }
    else {
      if (!this.screenParentId) {
        this.screenParentId = this.screenId;
      }
      if (!this.choiceId) {
        this.choiceId = 0;
      }
      this.screenDetails.ScreenId = this.screenParentId;
      this.screenDetails.Id = this.screenId;
      this.screenDetails.ChoiceId = this.choiceId;
      this.setPriceAndTime();
      if (!this.screenDetails.PrintOnOrderReceipt) {
        this.screenDetails.PrintOnOrderReceipt = false;
      }
      if (this.screenDetails.Layout != this.screenLayouts.Fixed) {
        this.screenDetails.ColumnCount = null;
      }
      if (this.screenDetails.ButtonSize == this.buttonSizes.Normal) {
        this.screenDetails.ButtonSize = null;
      }
      this.saveScreen();
    }
  }

  private saveScreen() {
    this.spinnerService.show();
    this.screenService.saveScreen(this.screenDetails, this.image)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.alertService.renderSuccessMessage(Messages.ScreenSaveSuccess);
          let savedScreenId;
          if (res?.Id) {
            savedScreenId = res.Id;
          }
          if (!this.isSaveAndContinue) {
            this.cancel(savedScreenId);
          }
          this.screenId = savedScreenId;
        }, error: this.alertService.showApiError
      });
  }

  private setPriceAndTime() {
    if (this.screenDetails.IsPrice) {
      if (this.startTime) {
        this.screenDetails.StartTime = this.startTime;
      }
      if (this.endTime) {
        this.screenDetails.EndTime = this.endTime;
      }
      if (!this.screenDetails.PriceAmount) {
        this.screenDetails.PriceAmount = 0;
      }
    } else {
      this.screenDetails.PriceAmount = 0;
      this.screenDetails.EndTime = '';
      this.screenDetails.StartTime = '';
    }
  }

  setNoOfColumns() {
    if (this.screenDetails.Layout == this.screenLayouts.Fixed) {
      this.screenDetails.ColumnCount = this.screenService.newScreen().ColumnCount;
    } else {
      this.screenDetails.ColumnCount = null;
      this.screenDetails.ButtonSize = this.screenService.newScreen().ButtonSize;
    }
  }

  handleFileInput(files: FileList) {
    this.image = files.item(0);
    const fileToUpload = this.image ? this.image.name : '';
    if (this.extensionValidation.isInvalidExtension(fileToUpload)) {
      if (fileToUpload) {
        this.alertService.renderErrorMessage(Messages.InvalidReceiptLogoFileType);
      }
      this.removeIcon();
      return;
    }
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        $('#screenImage').attr('src', reader.result);
      };
      reader.readAsDataURL(files[0]);
      this.screenDetails.background_image = this.image ? this.image.name : '';
    }
  }

  removeIcon() {
    this.screenDetails.background_image = '';
    $('#screenIcon').val('');
    this.image = null;
  }

  cancel(savedScreenId = -1) {
    this.hide({ shouldReload: true, savedScreenId: savedScreenId });
  }



}
