<pos-edit-wrapper (close)="cancelEditing()" [isPopup]="false" [headingIcon]="icons.workScheduleCalendarAlt"
    heading="{{workSchedule.Id ? 'Edit Work Schedule' : 'Add Work Schedule'}}">
    <form #workScheduleForm="ngForm" class="edit-form" (ngSubmit)="submit(workScheduleForm.valid)" autocomplete="off">
        <context-provider provide="props">
            <div class="col-12 pos-page-body border-top">
                <div class="col-12 pt-pix-5">
                    <pos-row>
                        <pos-column>
                            <pos-form-field label="Username" [validationMessages]="{required: 'Please select user.'}">
                                <ng-select posInput [items]="users" bindLabel="name" bindValue="id" [required]="true"
                                    placeholder="Select user" [(ngModel)]="workSchedule.user_id" name="user">
                                </ng-select>
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field inputStyle="col-12" label="Start Time">
                                <pos-row colXs="6" class="p-0">
                                    <pos-column xxl="3" lg="4" sm="7" xs="12">
                                        <p-calendar posInput [readonlyInput]="true" [required]="true"
                                            [dateFormat]="dateFormat" [inputStyle]="{'width':'unset','display':'unset'}"
                                            inputStyleClass="form-control" (onSelect)="onSelectStartDate()"
                                            [monthNavigator]="true" [showIcon]="true" name="startDate"
                                            [(ngModel)]="workSchedule.start_date" appendTo="body">
                                        </p-calendar>
                                    </pos-column>

                                    <pos-column xxl="2" md="3" sm="4" xs="12" class="timer-padding">
                                        <pos-form-field inputStyle="col-sm-12 col-6" [appendPaddingTop]="false"
                                            [validationMessages]="{required: 'Please enter start time.'}">
                                            <input posInput type="time" placeholder="HH:mm:ss" class="form-control"
                                                name="startTime"
                                                (change)="workSchedule.end_time = workSchedule.start_time"
                                                #startTime="ngModel" [(ngModel)]="workSchedule.start_time"
                                                [required]="true">
                                        </pos-form-field>
                                    </pos-column>
                                </pos-row>
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field inputStyle="col-12" label="End Time">
                                <pos-row colXs="6" class="p-0">
                                    <pos-column xxl="3" lg="4" sm="7" xs="12">
                                        <p-calendar posInput [readonlyInput]="true" [required]="true"
                                            [dateFormat]="dateFormat" [inputStyle]="{'width':'unset','display':'unset'}"
                                            inputStyleClass="form-control" [monthNavigator]="true" [showIcon]="true"
                                            name="endDate" [(ngModel)]="workSchedule.end_date" appendTo="body">
                                        </p-calendar>

                                    </pos-column>
                                    <pos-column xxl="2" md="3" sm="4" xs="12" class="timer-padding">
                                        <pos-form-field inputStyle="col-sm-12 col-6" [appendPaddingTop]="false"
                                            [validationMessages]="{required: 'Please enter end time.'}">
                                            <input posInput type="time" placeholder="HH:mm:ss" class="form-control"
                                                name="endTime" [(ngModel)]="workSchedule.end_time" #endTime="ngModel"
                                                [required]="true">
                                        </pos-form-field>
                                    </pos-column>
                                </pos-row>
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Tag" inputStyle="col-lg-4 col-sm-6 col-12 col-200 pe-pix-15 position-relative">
                                    <div class="input-color z-index"
                                        [ngStyle]="{'background-color': selectedTag && selectedTag.Id ? selectedTag.Color : ''}">
                                    </div>
                                    <ng-select posInput in [items]="tags" bindLabel="Name" placeholder="Select tag"
                                        [(ngModel)]="selectedTag" name="tag">
                                    </ng-select>
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field>
                                <p-checkbox posInput name="chkActive" #chkActive="ngModel"
                                    [(ngModel)]="workSchedule.optional" binary="true" label="Optional Shift">
                                </p-checkbox>
                            </pos-form-field>
                        </pos-column>
                    </pos-row>
                </div>
            </div>
            <div class="col-12 action-button-container page-footer">
                <button type="submit" value="Save" class="btn-primary btn-lg btn btn-save pos-button"
                    data-dismiss="modal">Save</button>
                <button type="button" class="btn-secondary btn-lg btn btn-cancel pos-button" (click)="cancelEditing()"
                    value="Cancel"> Cancel
                </button>
            </div>
        </context-provider>
        <div class="clearfix"></div>
    </form>
</pos-edit-wrapper>
