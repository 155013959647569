import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants';
import { EventConfiguration, EventSubscription } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  eventsApiUrl = '';

  constructor(private httpService: HttpClient) {
    this.eventsApiUrl = RuntimeConstants.API_PATH + 'events';
  }

  getEventConfigurations = (eventIdentifier: string) => {
    return this.httpService.get(this.eventsApiUrl + `/${eventIdentifier}`);
  }

  getAllEventConfigurations = (allowedUserSubscriptionOnly?: boolean) => {
    return this.httpService.get(this.eventsApiUrl + '?allowedUserSubscriptionOnly=' + !!allowedUserSubscriptionOnly);
  }

  saveEvent = (eventConfiguration: EventConfiguration) => {
    return this.httpService.post(this.eventsApiUrl + `/${eventConfiguration.EventIdentifier}`, eventConfiguration);
  }

  changeEventStatus(eventId: number, isActive: boolean) {
    return this.httpService.post(`${this.eventsApiUrl}/${eventId}/state/${isActive}`, null);
  }
}
