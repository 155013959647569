import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridColumn } from '@tarktech/tark-ng-utils';
import { filter, first, forEach, remove } from 'lodash';
import { finalize } from 'rxjs/operators';
import { DataSource } from '../../interface/data-source';
import { DataSourceParameter } from '../../interface/data-source-parameter';
import { DataSourceParameterService } from '../../service/data-source-parameter.service';
import { DataSourceService } from '../../service/data-source.service';
import { deleteWhite, listWhite, databaseWhite, database, list, messageCode, emailTemplatesEnvelopeOpen, fileSearchPreview } from 'src/app/shared/components/icon';
import { NgForm } from '@angular/forms';
import { ManualValuesModel } from '../../interface/parameter-model';
import { BaseCrudComponent, ModalService, SpinnerService } from 'src/app/shared/components';
import { DomainConstants, Messages } from 'src/app/shared/constants';
import { AlertsService, FormUtilityService } from 'src/app/shared/services';
import { MacroScriptService } from '../../service/macro-script-service';
import { forkJoin, Observable } from 'rxjs';
import { MacroScript } from '../../interface';
import { DataSourcePreviewComponent } from '../data-source-preview/data-source-preview.component';

@Component({
  selector: 'pos-data-source-edit',
  templateUrl: './data-source-edit.component.html',
  styleUrls: ['./data-source-edit.component.scss'],
})
export class DataSourceEditComponent extends BaseCrudComponent<DataSource> implements OnInit {

  id = 0;
  isPreview = false;
  dataSource: DataSource;
  dataSourceParameters: Array<DataSourceParameter> = [];
  sourceName = '';
  queryTypes: Array<ManualValuesModel> = [];
  codeMirrorOptions = {
    lineNumbers: true,
    name: 'sql',
    lineWrapping: true
  };
  tabList = {
    Query: 'Query',
    Parameters: 'Parameters'
  };
  selectedTab = this.tabList.Query;
  height = window.innerHeight - 250;

  dataSourceConfigColumns: Array<GridColumn> = [];
  icons = {
    deleteWhite, listWhite, databaseWhite, database, list, messageCode, emailTemplatesEnvelopeOpen, fileSearchPreview
  };
  queryType = DomainConstants.queryType;
  isParametersLoaded = false;
  apiURL: string = '';
  apiVerb: string = first(DomainConstants.ApiVerbs)?.Name;
  apiVerbs = DomainConstants.ApiVerbs;
  isOpenVariable: boolean;
  selectedParameter: string;
  ngxCodeMirrorDoc;
  @ViewChild('dataSourceForm') dataSourceForm: NgForm;
  macroScripts: Array<MacroScript>;
  get getForm(): NgForm {
    return this.dataSourceForm
  }

  constructor(
    protected dataSourceService: DataSourceService,
    protected dataSourceParameterService: DataSourceParameterService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private macroScriptService: MacroScriptService,
    protected modalService: ModalService,
    formUtilityService: FormUtilityService,) {
    super(dataSourceService, alertService, spinnerService, formUtilityService);
    this.id = parseInt(route.snapshot.params.id, 10) ?? 0;
    this.dataSource = this.dataSourceService.newDataSource();
  }

  ngOnInit(): void {
    this.saveSuccessMessage = Messages.DataSourceSaveSuccess;
    this.dataSubscription();
  }

  private dataSubscription(): void {
    if (this.id > 0) {
      this.spinnerService.show();
      this.loadData()
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
          .subscribe({
              next: (response) => {
                  this.onLoadDataSourceDetails(response);
              }, error: this.alertService.showApiError
          });
    } else {
      this.isParametersLoaded = true;
    }
  }

  private onLoadDataSourceDetails(response: DataSource) {
    this.dataSource = response;
    this.id = this.dataSource.Id;
    this.sourceName = response.Name;
    this.getParameters();
    if (this.dataSource.QueryType === 'POSAPI') {
      const dataSourceApiDetails = JSON.parse(this.dataSource.DataSource);
      this.apiURL = dataSourceApiDetails?.ApiURL;
      this.apiVerb = dataSourceApiDetails?.ApiVerb;
    }
  }

  loadDependencies() {
    this.spinnerService.show();
    const observable: Array<Observable<any>> = [];
    observable.push(this.dataSourceService.getDataSourceType());
    observable.push(this.macroScriptService.getAll());
    this.dataSourceService.getDataSourceType()
    forkJoin(observable).pipe(finalize(() => {
      this.spinnerService.hide();
    }))
      .subscribe({
        next: (responses: [object, MacroScript[]]) => {
          forEach(responses[0], (key, value: string) => {
            this.queryTypes.push({ Name: key, Value: value });
          });
          this.createParameterRow(0, true);
          this.macroScripts = responses[1];
        }
      });
  }

  getParameters() {
    this.spinnerService.show();
    this.dataSourceParameterService.getDataSourceParameters(this.id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          this.dataSourceParameters = res;
          this.isParametersLoaded = true;
          forEach(this.dataSourceParameters, param => {
            if (param.DataType === 'BIT') {
              param.InitialValue = param.InitialValue === '1' ? true : (param.InitialValue === '0' ? false : null);
            }
          });
          this.createParameterRow(this.dataSourceParameters.length - 1);
        }, error: this.alertService.showApiError
      });
  }

    submit(isValid: boolean, isPreview: boolean = false) {
        let invalidParameterNames = false;
        let invalidFieldNames = false;
        if (!isValid) {
            return;
        }
        if (this.dataSource.QueryType === 'POSAPI') {
            this.dataSource.DataSource = JSON.stringify({ 'ApiURL': this.apiURL, 'ApiVerb': this.apiVerb });
        }
        forEach(this.dataSourceParameters, x => {
            const existingLabel = filter(this.dataSourceParameters, value => x.Label === value.Label && x.Label);
            if (existingLabel.length > 1) {
                invalidParameterNames = true;
            }
            const existingName = filter(this.dataSourceParameters, value => x.Name === value.Name && x.Name);
            if (existingName.length > 1) {
                invalidFieldNames = true;
            }
        });
        if (invalidParameterNames) {
            this.alertService.renderErrorMessage(Messages.DataSourceParameterDuplicateName);
            return;
        }
        if (invalidFieldNames) {
            this.alertService.renderErrorMessage(Messages.DataSourceParameterDuplicateLabel);
            return;
        }
        remove(this.dataSourceParameters, x => !x.Label);
        this.spinnerService.show();
        forEach(this.dataSourceParameters, param => {
            if (param.DataType === 'BIT') {
                param.InitialValue = param.InitialValue ? '1' :
                    (param.InitialValue !== null && Boolean(param.InitialValue) === false ? '0' : param.InitialValue);
            }
        });
        this.dataSourceService.saveDataSourceWithParameters({ DataSource: this.dataSource, DataSourceParameters: this.dataSourceParameters })
            .pipe(finalize(() => {
                this.spinnerService.hide();
            })).subscribe({
                next: (response: any) => {
                    if (this.isPreview) {
                        this.isPreview = false;
                        this.onLoadDataSourceDetails(response.DataSource);
                        this.preview(response.DataSource as DataSource);
                    } else {
                        this.alertService.renderSuccessMessage(Messages.DataSourceSaveSuccess);
                        this.onCancel();
                    }
                }, error: this.alertService.showApiError
            });
  }

  onSaveSuccess(model: DataSource): void {
    return;
  }

  onCancel() {
    this.router.navigate(['data-source'], { relativeTo: this.route.parent });
  }

  createParameterRow(index: number, firstRow = false) {
    if (this.dataSourceParameters.length !== (index + 1) && !firstRow) {
      return;
    }
    const newParameter = this.dataSourceParameterService.newDataSourceParameter();
    newParameter.DataSourceId = this.id;
    newParameter.Ordinal = index + 1;
    this.dataSourceParameters.push(newParameter);
  }

  getCursorPosition(event) {
    this.ngxCodeMirrorDoc = event.curOp.cm.getDoc();
  }

  preview = (data: DataSource) => {
    const modelRefUnit = this.modalService.show(DataSourcePreviewComponent, {
        animated: false,
        class: 'vertical-center modal-lg',
        keyboard: false,
        initialState: {
          dataSourceId: data.Id,
          dataSourceName: data.Name
        }
    });
    modelRefUnit.close.subscribe((response) => {
    });
  }

  changeMacroScript() {
    this.isOpenVariable = false;
    const cursor = this.ngxCodeMirrorDoc?.getCursor();
    if (cursor) {
      this.ngxCodeMirrorDoc.replaceRange('{{' + this.selectedParameter + '}}', cursor);
    } else {
      this.dataSource.DataSource = this.dataSource.DataSource + '{{' + this.selectedParameter + '}}';
    }
    this.selectedParameter = null;
  }

  onQueryTypeChange() {
    this.dataSource.DataSource = '';
  }
}
