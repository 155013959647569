import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { Observable } from 'rxjs';
import { ProductLossReason } from "../interface";


@Injectable()
export class ProductLossReasonService{
    constructor(private httpService: HttpClient) {}

    getProductLossResasons(): Observable<any> {
      return this.httpService.get(
        `${RuntimeConstants.API_PATH}productlossreason`
      );
    }
  
    getProductLossResasonById(productId: number): Observable<any> {
      return this.httpService.get(
        `${RuntimeConstants.API_PATH}productlossreason/` + productId
      );
    }
  
    addProductLossResason(product:ProductLossReason): Observable<any> {
      return this.httpService.post(
        `${RuntimeConstants.API_PATH}productlossreason/`,
        product
      );
    }
  
    updateProductLossResason(product: ProductLossReason): Observable<any> {
      return this.httpService.put(
        `${RuntimeConstants.API_PATH}productlossreason/` ,
        product
      );
    }
  
    deleteProductLossResason(productId: number): Observable<any> {
      return this.httpService.delete(
        `${RuntimeConstants.API_PATH}productlossreason/` + productId
      );
    }
    
    newProductLossReason() {
      const newProductLossReasonObj: ProductLossReason = {
               id: 0,
               Name: ''
      }
      return newProductLossReasonObj;
    }
  }
  