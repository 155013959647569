import { Injectable } from '@angular/core';
import { DjScheme } from '../interface/dj-scheme';
import { BaseCrudService } from 'src/app/shared';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DjSchemeService extends BaseCrudService<DjScheme> {

  constructor(private httpClient: HttpClient) {
    super('djschemes', httpClient);
  }

  newDJScheme() {
    const djScheme: DjScheme = {
      Id: 0,
      Name: '',
      PlaylistId: null,
      StartTime: null,
      EndTime: null,
      StartFade: false,
      StartFadeSeconds: 0,
      EndAction: null,
      PlayOrder: 'Literal',
      PlayPattern: '',
      Volume: 0,
      FadeInVolumeIncrement: 0,
      OnComplete: null,
      Scheduled: false,
      Pattern: false
    };
    return djScheme;
  }
}
