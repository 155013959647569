<div *ngIf="fontStyle">
    <pos-row>
        <pos-column>
            <pos-form-field label="Font" inputStyle="col-lg-3 col-md-6 col-xs-12">
                <input posInput type="text" [(ngModel)]="fontStyle.fontFamily" class="form-control" name="{{name}}_fontFamily" />
            </pos-form-field>
        </pos-column>
        <pos-column>
            <pos-form-field label="Font Size" inputStyle="col-lg-2 col-md-5 col-xs-12">
                <input posInput type="text" [(ngModel)]="fontStyle.fontSize" class="form-control" name="{{name}}_fontSize" />
            </pos-form-field>
        </pos-column>
        <pos-column>
            <pos-form-field label="Style">
                <p-toggleButton name="{{name}}_fontWeight" [(ngModel)]="fontStyle.fontWeight" onLabel="B" offLabel="B"
                    styleClass="bold mri-5" (onChange)="fontWeightChange($event)"></p-toggleButton>
                <p-toggleButton name="{{name}}_fontStyle" [(ngModel)]="fontStyle.fontStyle" onLabel="I" offLabel="I"
                    styleClass="italic mri-5" (onChange)="fontStyleChange($event)"></p-toggleButton>
                <p-toggleButton name="{{name}}_textDecoration" [(ngModel)]="fontStyle.textDecoration" onLabel="U" offLabel="U"
                    styleClass="underline mri-5" (onChange)="textDecorationChange($event)"></p-toggleButton>
            </pos-form-field>
        </pos-column>
        <pos-column>
            <pos-form-field label="Shadow" [fieldInfo]="fieldInfoMessages.FontStyleShadowSequence" inputStyle="col-lg-2 col-md-5 col-xs-12">
                <input posInput type="text" [(ngModel)]="fontStyle.textShadow" class="form-control" name="{{name}}_shadow" />
            </pos-form-field>
        </pos-column>
        <pos-column>
            <pos-form-field label="Color">
                <pos-color-picker posInput name="{{name}}_color" [(ngModel)]="fontStyle.color" [cpPosition]="'top'">
                </pos-color-picker>
            </pos-form-field>
        </pos-column>
    </pos-row>
</div>
