import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import {
  RabbitMQ, AlertsService, SpinnerService, ModalService, PrintTableService, ApplicationStateService,
  Messages, ConfirmDeleteModalComponent, RabbitMQStompConfig, BaseFormComponent, FormUtilityService
} from 'src/app/shared';
import { RabbitMqConfigurationService } from '../../services/rabbit-mq-configuration.service';
import { finalize } from 'rxjs/operators';
import { GridColumn, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { check, deleteWhite, editWhite, plusWhite, rabbitMqConfigurationCogs } from 'src/app/shared/components/icon';
import { RabbitMqStompHostEditComponent } from '../rabbit-mq-stomp-host-edit/rabbit-mq-stomp-host-edit.component';
import * as _ from 'lodash';
import { NgForm } from '@angular/forms';
import { ConfiguratorService } from 'src/app/configurator/configurator.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'pos-rabbit-mq-configuration',
  templateUrl: './rabbit-mq-configuration.component.html',
})
export class RabbitMqConfigurationComponent extends BaseFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.form
  }
  rabbitMQConfig: RabbitMQ;
  stompHostConfigColumns: Array<GridColumn> = [];
  stompHostList = [];
  @ViewChild('useSSLTemplate', { static: true }) useSSLTemplate: TemplateRef<any>;
  @ViewChild('operationTemplate', { static: true }) operationTemplate: TemplateRef<any>;
  @ViewChild('operationHeaderTemplate', { static: true }) operationHeaderTemplate: TemplateRef<any>;
  useSSLConfigMessage: string = Messages.ConfirmIsUseSSLConfig;
  @ViewChild('rabbitMQConfigForm') form: NgForm;
  showSSLWarning = false;

  icons = {
    check,
    deleteWhite,
    editWhite,
    plusWhite,
    rabbitMqConfigurationCogs
  };
  props = {
    controlBoxClass: 'col-lg-3 col-md-5 col-sm-8 col-xs-12'
  }
  constructor(protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected rabbitMqService: RabbitMqConfigurationService,
    protected modalService: ModalService,
    protected printService: PrintTableService,
    protected applicationStateService: ApplicationStateService,
    private configuratorService: ConfiguratorService,
    private router: Router,
    private route: ActivatedRoute,
    formUtilityService: FormUtilityService) {
    super(formUtilityService);
    this.rabbitMQConfig = this.rabbitMqService.newRabbitMqConfig();
  }

  ngOnInit() {
    this.reloadRabbitMqConfiguration();
    this.configureGridColumns();
  }

  reloadRabbitMqConfiguration() {
    this.spinnerService.show();
    this.rabbitMqService.getRabbitMQConfig()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.rabbitMQConfig = res;
          this.stompHostList = res.RabbitMQStompConfig;
          if (location.protocol.toLowerCase() === 'https:' && !this.rabbitMQConfig.IsUseSSL) {
            this.showSSLWarning = true;
          } else {
            this.showSSLWarning = false;
          }
          this.onFormChange(this.form);
        }, error: this.alertService.showApiError
      });
  }

  private configureGridColumns() {

    const operationColumn = new TemplateColumn({
      itemTemplate: this.operationTemplate,
      Width: '67px',
      headerTemplate: this.operationHeaderTemplate,
      CellClass: 'cell-padding'
    });

    const useSSLColumn = new TemplateColumn({
      HeaderText: 'Use SSL',
      itemTemplate: this.useSSLTemplate,
      TextAlign: TextAlign.Center,
      Field: 'IsUseSSL',
      IsSortable: true,
      Width: '100px'
    });

    this.stompHostConfigColumns = [
      new GridColumn({ HeaderText: 'Application Host', Field: 'ApplicationHost', IsSortable: true, Width: '34%' }),
      new GridColumn({ HeaderText: 'Host', Field: 'Host', IsSortable: true, Width: '30%' }),
      new GridColumn({ HeaderText: 'Port', Field: 'Port', IsSortable: true, Width: '20%' }),
      useSSLColumn,
      operationColumn
    ] as Array<GridColumn>;
  }

  editStompHostConfig(rabbitMQStompConfig) {
    const modalRef = this.modalService.getModalWrapper(RabbitMqStompHostEditComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      initialState: {
        rabbitMQStompConfig: _.cloneDeep(rabbitMQStompConfig)
      }
    });
    modal.close.subscribe(res => {
      if (res && res.data) {
        if (res.mode === 'Add') {
          this.rabbitMQConfig.RabbitMQStompConfig.push(res.data);
        } else if (res.mode === 'Edit') {
          _.forEach(this.rabbitMQConfig.RabbitMQStompConfig, (stompConfig: RabbitMQStompConfig) => {
            if (res.data.ApplicationHost === stompConfig.ApplicationHost) {
              stompConfig.Host = res.data.Host;
              stompConfig.Port = res.data.Port;
              stompConfig.IsUseSSL = res.data.IsUseSSL;
            }
          });
        }
      }
    });
  }

  openConfirmDeleteModal(applicationHost) {
    if (applicationHost === 'Default') {
      return;
    }
    const modalRef = this.modalService.getModalWrapper(ConfirmDeleteModalComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      initialState: {
        message: Messages.ConfirmDeleteRabbitMQStompConfig
      }
    });
    modal.close.subscribe(res => {
      if (res && res.shouldDelete) {
        this.deleteStompHostConfig(applicationHost);
      }
    });
  }

  onFormChange($event: NgForm) {
    this.form = $event;
    this.setInitialValue();
  }

  deleteStompHostConfig(applicationHost) {
    this.spinnerService.show();
    for (let i = 0; i < this.rabbitMQConfig.RabbitMQStompConfig.length; i++) {
      if (applicationHost === this.rabbitMQConfig.RabbitMQStompConfig[i].ApplicationHost) {
        this.rabbitMQConfig.RabbitMQStompConfig.splice(i, 1);
        break;
      }
    }
    this.alertService.renderSuccessMessage(Messages.RabbitMQStompConfigDeleted);
    this.spinnerService.hide();
  }

  saveRabbitMQConfiguration() {
    if (!this.form.valid) {
      return;
    }
    this.spinnerService.show();
    this.rabbitMqService.saveRabbitMQConfiguration(this.rabbitMQConfig)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.alertService.renderSuccessMessage(Messages.RabbitMQStompConfigSaveSuccess);
          this.configuratorService.startAutoCloseInterval();
        }, error: this.alertService.showApiError
      });
  }

  changeUseSSLStatus() {
    if (location.protocol.toLowerCase() === 'https:' && !this.rabbitMQConfig.IsUseSSL) {
      this.showSSLWarning = true;
    } else {
      this.showSSLWarning = false;
    }
  }

  onCancel() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
