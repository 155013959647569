<div class="col-12 details-box row mx-0" id="account_{{account.id}}"
  (click)="onAccountSelection(account)" [ngClass]="{'selected-item': account.id == selectedAccountOrderId}"
  *ngFor="let account of accountsList| orderBy: 'SurrogateOrderId'">
  <div class="float-start col-1 ps-0 pe-xs-0">
    <img *ngIf="!account.IsSent" class="" src="images/icons/order-notassigned.svg"
      [ngStyle]="{'max-height': 30 ,'min-width' : 30 }" alt="" />
    <img *ngIf="account.IsSent" class="" src="images/icons/order-sent.svg"
      [ngStyle]="{'max-height': 30 ,'min-width' : 30 }" alt=""/>
  </div>
  <div class="col-lg-9 col-sm-10 col-9 ps-0 pe-0">
    <div class="col-12 bold">
      <span *ngIf="account.CustomerAccountName">
        {{loyaltyAccountTerm}}: {{account.CustomerAccountName}}
      </span>
      <span *ngIf="account.CustomerAccountName && account.AccountTypeId > 0">|</span>
      <span *ngIf="!account.CustomerAccountName && !account.AccountTypeId">Not Selected</span>
      <span *ngIf="account.AccountTypeId > 0">{{account.AccountType == accountTypes.Tab ? accountTerm : tableTerm}}:
        {{account.AccountName && account.AccountName != "--"  ? account.AccountName : account.LoyaltyCardNumber}}
      </span>
    </div>
    <div class="col-12 row mx-0 pe-0">
      <div class="col-3 ps-0"> #{{account.SurrogateOrderId}}</div>
      <div class="col-lg-5 col-4 ps-0">{{account.GrandTotal | tark_currency}}</div>
      <div class="col-lg-4 col-5 p-0">{{account.orderdate| tark_time}}</div>
    </div>
  </div>
  <div class="col-lg-2 col-sm-1 col-2 text-left p-0">
    {{account.Seat}} {{subAccountTerm}}
  </div>
</div>
