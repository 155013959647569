import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { Infraction } from '../interface/infraction';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';

@Injectable()
export class InfractionService extends BaseCrudService<Infraction> {

    infractionApiUrl = '';
    constructor(httpService: HttpClient) {
        super('infraction', httpService);
        this.infractionApiUrl = RuntimeConstants.API_PATH + 'infraction';
    }
    newInfraction() {
        return {
            Id: 0,
            user_id: null,
            infraction: '',
            points: null,
            user_name: '',
            InfractionDate: null,
            DateEdited: new Date(),
            username: ''
        };
    }
}
