<pos-list-wrapper [headingIcon]="icons.vendorStruck" heading="Vendors" (close)="close()">
    <div class="row">
        <div class="col-12 ps-0">
            <div class="inline-block ps-pix-10 float-end">
                <p-checkbox name="chkActive" #chkActive="ngModel" [(ngModel)]="isActiveVendors"
                    binary="true" (onChange)="reload()" label="Active">
                </p-checkbox>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <ng-table id="vendors" #vendorGrid [sort]="true" [exportFilename]="'vendors'"
                [columns]="vendorColumns" [data]="vendors">
            </ng-table>
            <ng-template let-data="rowData" #iconTemplate>
                <span *ngIf="data.active" class="green text-center vertical-mid font-13">
                    <pos-icon [icon]="icons.check"></pos-icon>
                </span>
            </ng-template>
            <ng-template #iconHeaderTemplate>
            </ng-template>
            <ng-template let-data="rowData" #phoneTemplate>
                <span *ngIf="data.phone">
                    {{ data.phone | phoneMaskPipe }}
                </span>
            </ng-template>
        </div>
    </div>
</pos-list-wrapper>

<pos-confirm-delete (onConfirmAcknowledge)="onConfirmAcknowledge()" #confirmVendors>
</pos-confirm-delete>
<ng-template #popOverForVendor let-data="rowData">
    <span class="text-center mt-pix-n4 overflow-wrap">
        <a class="action-btn grey pointer" #pop="bs-popover" placement="left" (click)="onLinkPopOver(data,pop)"
            [outsideClick]="true" [popover]="vendorPopoverTemplate" triggers="click">
            <pos-action-icon [icon]="icons.barsWhite"></pos-action-icon>
        </a>
    </span>
</ng-template>
<ng-template #vendorPopoverTemplate>
    <div class="">
        <ul>
            <li class="listbullet pointer">
                <a class="grey" (click)="openNextInventoryLogDate(vendor)">
                    <pos-icon [icon]="icons.listAlt" class="pe-pix-3 m-l-5">
                    </pos-icon>
                    Next Inventory Log Date
                </a>
            </li>
            <li class="listbullet pt-pix-3 pointer">
                <a class="grey" (click)="openInventoryVendorProfile(vendor)">
                    <pos-icon [icon]="icons.sortNumericUp" class="pe-pix-5 m-l-5">
                    </pos-icon>
                    Min/Max Profiles
                </a>
            </li>
        </ul>
    </div>
</ng-template>
<pos-print-table [hidden]="true"></pos-print-table>
