import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DeactivateGuard, DomainConstants, Levels } from '.';
import { AuthGuard } from './app-guard';
import { TerminalSelectionComponent } from './application-login/component/terminal-selection';
import { MenuDisplayPreviewComponent } from './menu-display/components/menu-display-preview/menu-display-preview.component';
import { PreventBackGuardService } from './shared/guards/prevent-back-guard.service';

const routes: Routes = [
  {
    path: 'login',
    canDeactivate: [DeactivateGuard],
    loadChildren: () => import('./application-login/application-login.module').then(m => m.ApplicationLoginModule)
  },
  {
    path: 'manage',
    loadChildren: () => import('./manage-console/manage-console.module').then(m => m.ManageConsoleModule)
  },
  {
    path: 'terminals',
    canActivate: [AuthGuard, PreventBackGuardService],
    component: TerminalSelectionComponent
  },
  {
    path: 'menu-display/:id',
    canActivate: [AuthGuard],
    component: MenuDisplayPreviewComponent,
    data: {
      type: DomainConstants.TerminalTypes.MENU_DISPLAY.Name,
      permissions: [{ 'Name': DomainConstants.TerminalTypes.MENU_DISPLAY.Permission, 'Level': Levels.Access }]
    }
  },
  {
    path: 'instruction-terminal',
    canActivate: [AuthGuard],
    data: {
      permissions: [{ 'Name': DomainConstants.TerminalTypes.INSTRUCTION_SCREEN.Permission, 'Level': Levels.Access }]
    },
    loadChildren: () => import('./instruction-terminal/instruction-terminal.module').then(m => m.InstructionTerminalModule)
  },
  {
    path: 'serve-screen',
    data: {
      permissions: [{ 'Name': DomainConstants.TerminalTypes.SERVE_SCREEN.Permission, 'Level': Levels.Access }]
    },
    loadChildren: () => import('./serve-screen/serve-screen.module').then(m => m.ServeScreenModule)
  },
  {
    path: 'make-table',
    data: {
      permissions: [{ 'Name': DomainConstants.TerminalTypes.MAKE_TABLE.Permission, 'Level': Levels.Access }]
    },
    loadChildren: () => import('./make-table/make-table.module').then(m => m.MakeTableModule)
  },
  {
    path: 'pick-screen',
    loadChildren: () => import('./pick-screen/pick-screen.module').then(m => m.PickScreenModule)
  },
  {
    path: 'order-confirmation',
    loadChildren: () => import('./order-confirmation/order-confirmation.module').then(m => m.OrderConfirmationModule)
  },
  {
    path: 'order-status',
    loadChildren: () => import('./order-status/order-status.module').then(m => m.OrderStatusModule)
  },
  {
    path: 'order',
    data: {
      permissions: [{ 'Name': DomainConstants.TerminalTypes.ORDER_ENTRY.Permission, 'Level': Levels.Access }]
    },
    loadChildren: () => import('./orders/order.module').then(m => m.OrderModule)
  },
  {
    path: 'digital-signage',
    loadChildren: () => import('./digital-signage/digital-signage.module').then(m => m.DigitalSignageModule)
  },
  {
    path: 'dj',
    loadChildren: () => import('./dj/dj.module').then(m => m.DjModule)
  },
  {
    path: 'time-entry',
    data: {
      permissions: [{ 'Name': DomainConstants.TerminalTypes.TIME_ENTRY.Permission, 'Level': Levels.Access }]
    },
    loadChildren: () => import('./time-entry-terminal/time-entry-terminal.module').then(m => m.TimeEntryModule)
  },
  {
    path: 'timer',
    loadChildren: () => import('./timer/timer.module').then(m => m.TimerModule)
  },
  {
    path: 'product-build-queue',
    data: {
      permissions: [{ 'Name': DomainConstants.TerminalTypes.PRODUCT_BUILD_QUEUE.Permission, 'Level': Levels.Access }]
    },
    loadChildren: () => import('./product-build-queue/product-build-queue.module').then(m => m.ProductBuildQueueModule)
  },
  {
    path: 'back-office',
    loadChildren: () => import('./back-office/back-office.module').then(m => m.BackOfficeModule)
  },
  {
    path: 'menu-explorer',
    data: {
      permissions: [{ 'Name': DomainConstants.TerminalTypes.MENU_EXPLORER.Permission, 'Level': Levels.Access }]
    },
    loadChildren: () => import('./menu-explorer/menu-explorer.module').then(m => m.MenuExplorerModule)
  },
  {
    path: 'order-kiosk',
    data: {
      permissions: [{ 'Name': DomainConstants.TerminalTypes.ORDER_KIOSK.Permission, 'Level': Levels.Access }]
    },
    loadChildren: () => import('./order-kiosk/order-kiosk.module').then(m => m.OrderKioskModule)
  },
  {
    path: 'wait-list',
    data: {
      permissions: [{ 'Name': DomainConstants.TerminalTypes.WAITLIST.Permission, 'Level': Levels.Access }]
    },
    loadChildren: () => import('./wait-list/wait-list.module').then(m => m.WaitListModule)
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/login',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
