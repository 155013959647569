import { Component, OnInit } from '@angular/core';
import { WidgetHeaderConfig, WidgetStyleConfig } from '../../interfaces';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { WidgetDetails } from '../../interfaces/widget-details';
import { WidgetHeaderStyleConfig } from '../../interfaces/widget-header-style-config';
import { MenuWidgetService } from '../../services/menu-widget.service';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { RabbitMQService } from 'src/app/shared/services/rabbitmq';

@Component({
  selector: 'pos-banner-widget',
  templateUrl: './banner-widget.component.html',
  styleUrls: ['./banner-widget.component.scss']
})
export class BannerWidgetComponent extends BaseWidgetComponent implements OnInit {

  headerConfig: WidgetHeaderConfig;
  widgetData: WidgetDetails;
  widgetBannerStyle: WidgetHeaderStyleConfig;
  imagePath = RuntimeConstants.IMAGE_BASE_PATH + '/menu-display';
  borderStyle;

  constructor(
    protected menuWidgetService: MenuWidgetService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected applicationStateService: ApplicationStateService,
    protected modalService: ModalService,
    protected rabbitMQService: RabbitMQService) {
    super(menuWidgetService, alertService, spinnerService, applicationStateService, modalService, rabbitMQService);
    this.widgetData = this.menuWidgetService.getNewWidgetDetails();
    this.widgetBannerStyle = this.menuWidgetService.getNewWidgetHeaderStyleConfig();
  }

  editWidget() {
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.widgetData$.subscribe({
      next: (response) => {
        this.headerConfig = this.parseJson<WidgetHeaderConfig>(response?.WidgetConfig);
        const widgetStyle = this.parseJson<WidgetStyleConfig>(response?.WidgetStyleConfig);
        this.widgetBannerStyle = widgetStyle?.WidgetHeaderStyleConfig;
        this.borderStyle = widgetStyle?.BorderStyle;
      }
    });
    this.loadWidget(this.widgetId);
  }


}
