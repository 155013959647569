<pos-edit-wrapper (close)="cancelLogDateEdit()" heading="{{heading}}">
    <form #inventoryLogDueDateOverrideEditForm="ngForm" class="edit-form">
        <div class="col-12 pos-modal-body screen-scroll">
            <pos-row>
                <pos-column>
                    <pos-form-field label="Next Inventory Log Date">
                        <p-calendar posInput #logStartDate="ngModel" [readonlyInput]="true" [required]="true"
                            [dateFormat]="dateFormat" [inputStyle]="{'width':'unset','display':'unset'}"
                            inputStyleClass="form-control" [monthNavigator]="true" [showIcon]="true"
                            name="logDueDateOverride" [(ngModel)]="nextLogDueDateOverride" appendTo="body">
                        </p-calendar>
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field *ngIf="inventoryZoneId">
                        <p-checkbox posInput name="primary" #primary="ngModel" [(ngModel)]="isApplyToPrimaryZone"
                            binary="true" label="Apply to primary">
                        </p-checkbox>
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field *ngIf="inventoryZoneId">
                        <p-checkbox posInput name="secondary" #secondary="ngModel" [(ngModel)]="isApplyToSecondaryZone"
                            binary="true" label="Apply to secondary">
                        </p-checkbox>
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn btn-lg btn-primary btn-save pos-button"
                [disabled]="inventoryZoneId && !isApplyToPrimaryZone && !isApplyToSecondaryZone"
                (click)="saveInventoryNextLogDate()">Apply</button>
            <button class="btn btn-lg btn-secondary  btn-cancel-modal pos-button" type="button"
                (click)="cancelLogDateEdit()">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
