import { Component, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { first } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormUtilityService } from 'src/app/shared';
import { ModalFormComponent, ModalService } from 'src/app/shared/components';

@Component({
  selector: 'pos-add-comment',
  templateUrl: './add-comment.component.html',
  styleUrls: ['./add-comment.component.scss']
})
export class AddCommentComponent extends ModalFormComponent {
  @Input() isAddCommentToOrder = false;
  @Input() selectedButtonBehaviorType;
  @Input() buttonBehavior = null;
  @ViewChild('commentForm') commentForm: NgForm;
  comment: string = null;
  get getForm(): NgForm {
    return this.commentForm;
  }

  constructor(
    modalRef: BsModalRef,
    modalService: ModalService,
    protected formUtilityService: FormUtilityService) {
    super(modalRef, modalService, formUtilityService);
  }

  ngOnInit() {
    if (this.buttonBehavior && this.buttonBehavior.Id) {
      try {
        const behavior = JSON.parse(this.buttonBehavior.Value) as any;
        this.comment = behavior ? behavior.comment : null;
      } catch (ex) {
        console.error('Invalid behavior value: ' + this.buttonBehavior.TextValue);
      }
    }
  }

  saveBehavior = (isValid: boolean) => {
    if (isValid) {
      this.hide({ shouldReload: true, Value: { comment: this.comment } });
    }
  }

  closeModal = () => {
    this.hide({ shouldReload: false });
  }
}
