import { HttpClient } from '@angular/common/http';
import { SalesProductAlias } from './../interface/sales-product-alias';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SalesProductAliasService {

    constructor(private httpService: HttpClient) { }

    getSalesProductAliases(salesproductId): Observable<any> {
        return this.httpService.get(
            `${RuntimeConstants.API_PATH}salesproducts/alias/` + salesproductId
        );
    }

    addAlias(salesProductAlias: SalesProductAlias): Observable<any> {        
        return this.httpService.post(
          `${RuntimeConstants.API_PATH}salesproducts/alias`,
          salesProductAlias
        );
      }

      deleteAlias(id: number): Observable<any> {
        return this.httpService.delete(
          `${RuntimeConstants.API_PATH}salesproducts/alias/` + id
        );
      }
}
