import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseCrudService } from "src/app/shared/services/base-crud.service";
import { FeatureConfiguration } from "../interface/feature-configuration";

@Injectable({
    providedIn: 'root'
})
export class FeatureConfigurationService extends BaseCrudService<FeatureConfiguration> {
    constructor(private httpClient: HttpClient) {
        super('feature-configuration', httpClient);
    }

    updateFeatures(_posFeatureConfigurations: Array<FeatureConfiguration>) {
        return this.httpService.put(`${this.apiEndpoint}`,_posFeatureConfigurations);
    }
}
