import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DateColumn } from '@tarktech/tark-ng-utils/table/date-column';
import { GridColumn } from '@tarktech/tark-ng-utils/table/grid-column';
import { TemplateColumn } from '@tarktech/tark-ng-utils/table/template-column';
import { TextAlign } from '@tarktech/tark-ng-utils/table/text-align';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BaseListComponent } from 'src/app/shared/components/base-list.component';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import { ScreenService } from 'src/app/shared/services/screen.service';
import { ScreenDetails } from '../../interface/screen-details';
import { ScreenHistory } from '../../interface/screen-history';
import { ScreenHistoryService } from '../../service/screen-history.service';
import { check } from 'src/app/shared/components/icon';
import { forEach } from 'lodash';
import { DomainConstants } from 'src/app/shared/constants';
import { TableConfiguration } from 'src/app/shared/table/table-configuration';
import { FilterConfiguration } from 'src/app/shared/interface';

@Component({
  selector: 'pos-screen-history',
  templateUrl: './screen-history.component.html',
  styleUrls: ['./screen-history.component.scss']
})
export class ScreenHistoryComponent extends BaseListComponent<ScreenHistory> implements OnInit {

  screenHistory: Array<ScreenHistory> = [];
  screenHistoryColumns = [];
  screenName = '';
  id: number;
  public icons = {
    check
  };
  @ViewChild('printReceiptIconTemplate', { static: true }) private printReceiptIconTemplate: any;

  totalRecords = 0;
  filterConfiguration: FilterConfiguration = {
    PageNumber: 1, PageSize: TableConfiguration.pageSize,
    SortField: 'DateAdded', SortOrder: -1, FilterValue: null
  };
  isCalledFromOnInit = true;

  constructor(
    protected screenHistoryService: ScreenHistoryService,
    protected screenService: ScreenService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected applicationStateService: ApplicationStateService,
    protected route: ActivatedRoute,
    private router: Router,
    protected printService: PrintTableService) {
    super(screenHistoryService, alertService, spinnerService, modalService, applicationStateService, printService, route);
    this.id = parseInt(route.snapshot.params.id, 10) ?? 0;
    this.deleteSuccessMessage = Messages.ScreenHistoryDeleted;
  }

  ngOnInit(): void {
    this.isPrintIconVisible = false;
    this.screenHistoryColumns = this.configureGridColumns();
  }

  reload() {
    if (this.id) {
      const observable: Array<Observable<any>> = [];
      observable.push(this.screenService.get(this.id));
      observable.push(this.screenHistoryService.getTotalRecordsCount(this.id));
      this.spinnerService.show();
      forkJoin(observable).pipe(finalize(() => {
        this.spinnerService.hide();
      })).subscribe({
        next: (response: [ScreenDetails, number]) => {
          this.screenName = response[0].Title;
          this.totalRecords = response[1];
          if (!this.isCalledFromOnInit) {
            this.getScreenHistories();
          }
          this.isCalledFromOnInit = false;
        },
        error: this.alertService.showApiError
      });
    }
  }

  lazyLoad(event) {
    this.filterConfiguration.PageSize = event.rows;
    this.filterConfiguration.PageNumber = Math.ceil((event.first + 1) / event.rows);
    this.filterConfiguration.SortField = event.sortField;
    this.filterConfiguration.SortOrder = event.sortOrder;
    this.filterConfiguration.FilterValue = event.globalFilter;
    this.getScreenHistories();
  }

  getScreenHistories() {
    this.spinnerService.show();
    this.screenHistoryService.getScreenHistories(this.id, this.filterConfiguration)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          this.screenHistory = response;
          forEach(this.screenHistory, x => {
            x.ButtonSize = x.ButtonSize ?? DomainConstants.ButtonSize.Normal;
          });
        }
      });
  }

  editItem(id: number) {
    this.router.navigate([id], { relativeTo: this.route });
  }

  getConfirmDeleteMessage() {
    return Messages.ScreenHistoryDeleteConfirm;
  }

  getGridColumns() {

    const printReceiptIconColumn = new TemplateColumn({
      itemTemplate: this.printReceiptIconTemplate,
      TextAlign: TextAlign.Center,
      Width: '15%',
      Field: 'PrintOnOrderReceipt',
      IsSortable: true,
      HeaderText: 'Print On Order Receipt'
    });

    return [
      new GridColumn({ Field: 'Name', HeaderText: 'Name', Width: '34%', IsSortable: true }),
      new GridColumn({ Field: 'ButtonSize', HeaderText: 'Button Size', Width: '8%', IsSortable: true }),
      new GridColumn({ Field: 'MinSelections', HeaderText: 'Min Selections', Width: '10%', IsSortable: true }),
      new GridColumn({ Field: 'MaxSelections', HeaderText: 'Max Selections', Width: '10%', IsSortable: true }),
      printReceiptIconColumn,
      new DateColumn({ Field: 'DateAdded', HeaderText: 'Created On', Width: '16%', TextAlign: TextAlign.Left, IsSortable: true }),
    ];
  }

  onCancel() {
    this.router.navigate(['manage/app-configuration/screens']);
  }
}
