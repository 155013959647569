<pos-edit-wrapper heading="Send Message" (close)="onCancel(true)">
    <form #sendMessagesForm="ngForm" autocomplete="off" (ngSubmit)="sendMessages(sendMessagesForm.valid)">
        <div class="screen-scroll container-fluid" (keydown.control.enter)="sendMessages(sendMessagesForm.valid)">
            <div class="row mx-0 form-horizontal">
                <pos-row>
                    <pos-column>
                        <pos-form-field label="From">
                            <div class="btn-group message-toggle">
                                <label class="btn btn-radio"
                                    [ngClass]="{'active btn-primary normal': message.senderType == 'Terminal', 'btn-secondary': message.senderType != 'Terminal'}">
                                    <input name="message.senderType" [(ngModel)]="message.senderType" type="radio"
                                        value="Terminal" style="position: initial;margin-right: 0;"> Terminal
                                </label>
                                <label class="btn btn-radio"
                                    [ngClass]="{'active btn-primary normal': message.senderType == 'User', 'btn-secondary': message.senderType != 'User'}">
                                    <input name="message.senderType" [(ngModel)]="message.senderType" type="radio"
                                        value="User" style="position: initial;margin-right: 0;"> Me
                                </label>
                            </div>
                        </pos-form-field>
                    </pos-column>
                    <pos-column>
                        <pos-form-field label="To">
                            <div class="btn-group message-toggle">
                                <label class="btn btn-radio"
                                    [ngClass]="{'active btn-primary normal': message.recipientType == 'Terminal', 'btn-secondary': message.recipientType != 'Terminal'}">
                                    <input name="message.recipientType" [(ngModel)]="message.recipientType" type="radio"
                                        value="Terminal" style="position: initial;margin-right: 0;">
                                    Terminal
                                </label>
                                <label class="btn btn-radio"
                                    [ngClass]="{'active btn-primary normal': message.recipientType == 'User', 'btn-secondary': message.recipientType != 'User'}">
                                    <input name="message.recipientType" [(ngModel)]="message.recipientType" type="radio"
                                        value="User" style="position: initial;margin-right: 0;">
                                    User
                                </label>
                            </div>
                        </pos-form-field>
                    </pos-column>
                    <pos-column *ngIf="message.recipientType == 'User'">
                        <pos-form-field label="User" [validationMessages]="{required: 'Please select user.'}">
                            <ng-select posInput name="ddlUser" [items]="userEmails" bindLabel="fullName" bindValue="id"
                                placeholder="Select user" [(ngModel)]="sender.SelectedEmail"
                                [required]="message.recipientType == 'User'">
                            </ng-select>
                        </pos-form-field>
                    </pos-column>
                    <pos-column *ngIf="message.recipientType == 'Terminal'">
                        <pos-form-field label="Terminal" [validationMessages]="{required: 'Please select terminal.'}">
                            <ng-select posInput name="ddlTerminal" [items]="terminals" bindLabel="TerminalName" bindValue="id"
                                placeholder="Select terminal" [(ngModel)]="sender.SelectedTerminal"
                                [required]="message.recipientType == 'Terminal'">
                            </ng-select>
                        </pos-form-field>
                    </pos-column>
                </pos-row>
                <div class="col-12 pt-pix-10">
                    <div class="col-3 label-section p-0">
                        <label class="vertical-bottom required">
                            Message</label>
                        <a (click)="onMessageKeyboard()" class="pointer bg-white keyboard-button ps-pix-5">
                            <pos-icon [icon]="icons.keyboard" class="font-05"></pos-icon>
                        </a>
                    </div>
                    <div class="col-12 p-0"
                        [ngClass]="{'has-error': ((!messageText) && (sendMessagesForm.submitted))}">
                        <ckeditor [(ngModel)]="messageText" #ckeditor (ready)="editorEvent()"
                            [editorUrl]="'assets/ckeditor/ckeditor.js'" name="messageText">
                        </ckeditor>
                        <span *ngIf="((!messageText) && (sendMessagesForm.submitted))">
                            <label class="control-label">Please enter message.</label>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" scrollup>Send</button>
            <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" (click)="onCancel(true)"
                scrollup>Close</button>
        </div>
        <div class="clearfix"></div>
    </form>
</pos-edit-wrapper>
