import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Diagram } from '../../interfaces/layout-designer';
import { BaseCrudComponent, SpinnerService } from 'src/app/shared/components';
import { LayoutDesignersService } from 'src/app/shared/services/layout-designers.service';
import { ApplicationStateService, AlertsService, Messages, SettingParam, FormUtilityService } from 'src/app/shared';
import { fieldInfoCircle } from 'src/app/shared/components/icon/icons';
import { NgForm } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
declare let mxUtils: any;
declare let $: any;
@Component({
  selector: 'pos-layout-designer-edit',
  templateUrl: './layout-designer-edit.component.html',
  styleUrls: ['./layout-designer-edit.component.scss']
})
export class LayoutDesignerEditComponent extends BaseCrudComponent<Diagram> implements OnInit {

  @Input() id = 0;
  data: Diagram;
  @ViewChild('layoutDesignerForm') layoutDesignerForm: NgForm;
  @ViewChild('layoutDesign') layoutDesign: any;
  @ViewChild('layoutPreviewContainer', { static: true }) layoutPreviewContainer: any;
  showPreview = false;
  updatedDiagram: Diagram;
  height = 0;
  width = 0;
  test = false;
  selectedFillColor = 'lightsalmon';
  selectedStrokeColor = '#000';
  settingParam: SettingParam;
  public icons = {
    fieldInfoCircle
  };
  get getForm(): NgForm {
    return this.layoutDesignerForm
  }
  constructor(protected applicationStateService: ApplicationStateService,
    protected layoutDesignersService: LayoutDesignersService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    formUtilityService: FormUtilityService) {
    super(layoutDesignersService, alertService, spinnerService, formUtilityService);
    this.id = Number(this.route.snapshot.params.id ?? 0);
    route.params.subscribe(
      params => {
        if (params?.id) {
          this.id = Number(params.id);
          this.reloadLayoutData();
        }
      });
  }

  ngOnInit() {
    this.setDefaults();
    this.data = this.layoutDesignersService.newLayoutDesigner();
    this.reloadLayoutData();
    this.saveSuccessMessage = Messages.LayoutDesignSaveSuccess;
    this.height = $(window).height() - 275 > 275 ? $(window).height() - 300 : 275;
    this.width = $(window).width() - 100;
  }

  setDefaults() {
    this.settingParam = this.applicationStateService.settingParam;
  }

  private reloadLayoutData() {
    if (this.id) {
      this.dataSubscription();
    }
  }

  private dataSubscription(): void {
    if (this.id > 0) {
      this.spinnerService.show();
      this.loadData().pipe(finalize(() => {
        this.spinnerService.hide();
      }))
        .subscribe({
          next: (res) => {
            this.data = res;
          }, error: this.alertService.showApiError
        });
    } else {
      this.data = this.layoutDesignersService.newLayoutDesigner();
    }
  }

  layoutPreview(preview) {
    if (this.id) {
      if (this.layoutDesign) {
        this.data = this.layoutDesign._convertGraphToJSON();
      }
      this.changeShapesColor(preview);
      this.changeGridView(preview, this.data.LayoutDefinition);
    }
  }

  changeShapeColor = (cell, colorName, isAccount, strokeColor): any => {
    const currentStyle = this.getAttribute(cell, 'style');
    const color = 'fillColor=' + colorName + ';';
    const shapeStrokeColor = 'strokeColor=' + strokeColor + ';';
    let oldFillColor = '';
    let oldStrokeColor = '';
    let style = '';
    if (currentStyle) {
      const styleArray = currentStyle.split(';');
      _.forEach(styleArray, (key) => {
        if (isAccount) {
          // get fill color from style
          if (key.includes('fillColor')) {
            oldFillColor = key.replace('fillColor=', '');
            oldFillColor = oldFillColor.replace('"', '');
          } else if (key.includes('strokeColor')) { // get stroke color from style
            oldStrokeColor = key.replace('strokeColor=', '');
            oldStrokeColor = oldStrokeColor.replace('"', '');
          }
        }
        // remove fill and stroke color from style
        if (key && !key.includes('fillColor') && !key.includes('strokeColor')) {
          style += (key + ';');
        }
      });
      // add changed fill and stroke color in style
      style += color + shapeStrokeColor;
      cell.setAttribute('style', style);
    } else {
      // add changed fill and stroke color in style
      cell.setAttribute('style', color + shapeStrokeColor);
    }
    return { fillColor: oldFillColor, strokeColor: oldStrokeColor };
  }

  // change all shape color based on isAccount
  changeShapesColor = (isPreview) => {
    const doc = mxUtils.parseXml(this.data.LayoutDefinition);
    const mxCells = doc.getElementsByTagName('mxCell');

    for (let i = 0; i < mxCells.length; i++) {
      const isAccount = this.getAttribute(mxCells[i], 'IsAccount') == 'true';
      let oldFillColor = this.getAttribute(mxCells[i], 'oldFillColor');
      let oldStrokeColor = this.getAttribute(mxCells[i], 'oldStockColor');
      if (isAccount) {
        const color = this.changeShapeColor(mxCells[i], oldFillColor ? oldFillColor : this.selectedFillColor, isAccount,
          oldStrokeColor ? oldStrokeColor : this.selectedFillColor);
        oldFillColor = oldFillColor ? oldFillColor : '#fff';
        oldStrokeColor = oldStrokeColor ? oldStrokeColor : '#000';
        // set oldFillColor for remember actual fill color of shape
        mxCells[i].setAttribute('oldFillColor', color && color.fillColor && color.fillColor != this.selectedFillColor ?
          color.fillColor : oldFillColor);
        // set oldStockColor for remember actual stroke color of shape
        mxCells[i].setAttribute('oldStockColor', color && color.strokeColor &&
          color.strokeColor != this.selectedFillColor ? color.strokeColor : oldStrokeColor);
      }
      if (!isPreview || !isAccount) {
        // remove attributes b'cz it's temporary no need to save it in DB
        this.removeAttribute(mxCells[i], 'oldFillColor');
        this.removeAttribute(mxCells[i], 'oldStockColor');
      }
    }
    this.data.LayoutDefinition = (new XMLSerializer()).serializeToString(doc);
  }

  getAttribute = (cell, attributeName) => {
    return cell ? cell.getAttribute(attributeName) : null;
  }

  removeAttribute = (cell, attributeName) => {
    if (cell) {
      cell.removeAttribute(attributeName);
    }
  }

  // Get account attributes and set in to "shape" object for further use
  getAccountAttributes = (shape, layoutDetails) => {
    const doc = mxUtils.parseXml(layoutDetails.LayoutDefinition);
    const mxCells = doc.getElementsByTagName('mxCell');
    for (let i = 0; i < mxCells.length; i++) {
      const currentTag = this.getAttribute(mxCells[i], 'id');
      if (currentTag == shape.id) {
        shape.IsAccount = this.getAttribute(mxCells[i], 'IsAccount') == 'true';
        shape.AccountId = this.getAttribute(mxCells[i], 'AccountId');
      }
    }
  }

  // Set account related attribute in "shape" object
  setAccountAttribute = (shape, layoutDetails, isAccount) => {
    const doc = mxUtils.parseXml(layoutDetails.LayoutDefinition);
    const mxCells = doc.getElementsByTagName('mxCell');
    for (let i = 0; i < mxCells.length; i++) {
      const currentTag = this.getAttribute(mxCells[i], 'id');
      if (currentTag == shape.id) {
        mxCells[i].setAttribute('IsAccount', isAccount);
        if (!isAccount) {
          this.removeAttribute(mxCells[i], 'AccountId');
        }
      }
    }
    this.data.LayoutDefinition = (new XMLSerializer()).serializeToString(doc);
  }

  selectTable(data) {
    if (data) {
      this.showPreview = false;
      this.spinnerService.show();
      const table = data.shape;
      const layoutDetails = data.layout;
      this.getAccountAttributes(table, layoutDetails);
      if (table) {
        this.setAccountAttribute(table, layoutDetails, !table.IsAccount);

        const doc = mxUtils.parseXml(layoutDetails.LayoutDefinition);
        const mxCells = doc.getElementsByTagName('mxCell');
        for (let i = 0; i < mxCells.length; i++) {
          const oldFillColor = this.getAttribute(mxCells[i], 'oldFillColor');
          const currentTag = this.getAttribute(mxCells[i], 'id');
          const oldStrokeColor = this.getAttribute(mxCells[i], 'oldStockColor');
          if (currentTag == table.id) {
            const color = this.changeShapeColor(mxCells[i], table.IsAccount ? (oldFillColor ? oldFillColor : '#fff')
              : this.selectedFillColor, !table.IsAccount, table.IsAccount ? (oldStrokeColor ? oldStrokeColor : '#000')
              : this.selectedFillColor);
            // set oldFillColor attribute in xml cell
            if (!oldFillColor) {
              mxCells[i].setAttribute('oldFillColor', color.fillColor ? color.fillColor : '#fff');
            }
            // set oldStockColor attribute in xml cell
            if (!oldStrokeColor) {
              mxCells[i].setAttribute('oldStockColor', color.strokeColor ? color.strokeColor : '#000');
            }
          }
        }
        this.data.LayoutDefinition = (new XMLSerializer()).serializeToString(doc);
      }
      setTimeout(() => {
        this.showPreview = true;
      });
      setTimeout(() => {
        this.spinnerService.hide();
      }, 200);
    }
  }

  changeGridView = (isShowGrid, layout) => {
    const doc = mxUtils.parseXml(layout);
    const mxGraph = doc.getElementsByTagName('mxGraphModel');
    if (mxGraph && mxGraph.length) {
      // change grid settings for design/preview mode
      mxGraph[0].setAttribute('grid', isShowGrid ? 0 : 1);
      mxGraph[0].setAttribute('gridSize', isShowGrid ? 0 : 10);
      mxGraph[0].setAttribute('guides', isShowGrid ? 0 : 1);
    }
    this.data.LayoutDefinition = (new XMLSerializer()).serializeToString(doc);
  }

  validateShapesName = (data): any => {
    const doc = mxUtils.parseXml(data.LayoutDefinition);
    const mxCells = doc.getElementsByTagName('mxCell');
    const wordToReplace = {
      br: '<br>',
      endBr: '</br>',
      span: '<span>',
      endSpan: '</span>'
    };
    for (let i = 0; i < mxCells.length; i++) {
      let shapeName = this.getAttribute(mxCells[i], 'value');
      if (shapeName) {
        shapeName = shapeName.replaceAll(wordToReplace.br, '');
        shapeName = shapeName.replaceAll(wordToReplace.endBr, '');
        shapeName = shapeName.replaceAll(wordToReplace.span, '');
        shapeName = shapeName.replaceAll(wordToReplace.endSpan, '');
      }
      mxCells[i].setAttribute('value', shapeName);
    }
    data.LayoutDefinition = (new XMLSerializer()).serializeToString(doc);
    return data;
  }

  saveLayout(isValid: boolean) {
    if (!isValid) {
      return;
    }
    if (this.id) {
      if (this.showPreview) {
        this.changeShapesColor(this.showPreview);
      }
      this.changeGridView(false, this.data.LayoutDefinition);
      const shapeData = this.showPreview ? this.validateShapesName(this.data) :
        this.validateShapesName(this.layoutDesign._convertGraphToJSON());
      this.save(shapeData);
    } else {
      this.data = this.validateShapesName(this.data);
      this.save(this.data);
    }
  }

  onSaveSuccess(model: Diagram): void {
    if (!this.id) {
      this.router.navigate(['manage/layouts/edit', model.Id]);
    }
  }

  loadDependencies(): void { }

  cancelLayout() {
    this.router.navigate(['manage/layouts/list']);
  }
}
