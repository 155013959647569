import { MessagePayload } from './message-payload';

export class TerminalUseCheckPayload extends MessagePayload {

    public TerminalId: number;
    public TerminalName: string;
    public TerminalType: any;

    constructor(terminalId: number, terminalName: string, terminalType: any) {
        super();
        this.TerminalId = terminalId;
        this.TerminalName = terminalName;
        this.TerminalType = terminalType;
    }
}
