<div class="dropdown white" [ngClass]="{'open' : btnExport }" (click)="btnExport = !btnExport">
    <a #posDropdown class="action-btn text-center white button-div pointer bg-print text-nowrap dropdown-toggle"
        type="button" id="tarkDropdown">
        <i class="fas {{actionIcon}} fa" aria-hidden="true" id="dropdownAction"></i> Export
        <span class="caret" id="dropdownAction"></span>
    </a>
    <ul class="tark-dropdown-menu pointer" [ngStyle]="{'right': offsetRight}" aria-labelledby="dropdownMenu1">
        <li><a (click)="exportCSV()"><i class="fas {{exportIcon}} fa" aria-hidden="true"></i> CSV</a></li>
        <li><a (click)="dataPrint()"><i class="fas {{printIcon}} fa pe-pix-1" aria-hidden="true"></i> Print</a></li>
    </ul>
</div>
