import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationLoginComponent } from './component/application-login/application-login.component';
import { ComponentsModule } from '../shared';
import { APPLICATION_LOGIN } from './application-login.routes';
import { FormsModule } from '@angular/forms';
import { TerminalSelectionComponent } from './component/terminal-selection/terminal-selection.component';
import { NgPipesModule } from 'ngx-pipes';
import { RouterModule } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    NgPipesModule,
    RouterModule.forChild(APPLICATION_LOGIN),
    ModalModule.forRoot(),
  ],
  declarations: [ApplicationLoginComponent, TerminalSelectionComponent],
  exports: [ApplicationLoginComponent, TerminalSelectionComponent]
})
export class ApplicationLoginModule { }
