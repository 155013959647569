<div>


    <div class="grid-container-full-height">
        <p-table #ptable [paginator]="paginator && !serverSidePaginator" [paginatorPosition]="paginatorPosition"
            [value]="records" [columns]="columns" [rows]="rows" [rowsPerPageOptions]="rowsPerPageOptions"
            [reorderableColumns]="isReorderableTable" [ngStyle]="autoFitColumn ? {'table-layout':'auto'} : null"
            [styleClass]="styleClass" [lazy]="lazy" [virtualScroll]="virtualScroll" [virtualScrollItemSize]="46" [tableStyle]="tableStyle"
            (onLazyLoad)="onSortChanged($event); loadLazyRecords($event);" [globalFilterFields]="globalfilterColumns"
            [exportFilename]="exportFilename" (onRowReorder)="rowReorder($event)" (onColReorder)="columnReorder($event)"
            [scrollable]="scrollable" [scrollHeight]="scrollHeight" (onPage)="onPageChange($event)"
            [selectionMode]="selectionMode" [(selection)]="selectedRowData" (onRowSelect)="onRowSelect($event)"
            (onRowUnselect)="onRowUnselect($event)" [sortField]="context.SortProperty" responsiveLayout="scroll"
            [sortOrder]="(context.SortDirection === 'Desc' ? -1 : 1)" [first]="first" [lazyLoadOnInit]="false"
            (onFilter)="onTableFiltered($event)" (onSort)="onSort($event)" [totalRecords]="totalRecords">
            <ng-template pTemplate="caption" *ngIf="exportData || allowSearch">
                <div class="text-right">
                    <span *ngIf="allowSearch" class="p-inputgroup me-pix-3">
                        <input class="p-inputtext p-widget p-state-default p-corner-all" pInputText
                            [placeholder]="searchPlaceholder" [(ngModel)]="context.Filter"
                            (input)="(paginator && serverSidePaginator)? onFilterChanged():ptable.filterGlobal($event.target.value, 'contains')"
                            #searchBox>
                    </span>
                    <span *ngIf="exportData" style="margin-left: 5px;">
                        <button pButton type="button" (click)="serverSidePaginator? exportCSVData():ptable.exportCSV()"
                            label="Export" icon="fas fa-download"></button>
                    </span>
                </div>

            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr class="table-header">
                    <th *ngIf="isReorderableRow" class="table-drag-column"></th>
                    <ng-container *ngFor="let col of columns">
                        <th [pSortableColumn]="col.Field" *ngIf="!IsLinkColumn(col)"
                            [pSortableColumnDisabled]="!(col.IsSortable && allowSort)" class="font-weight-bold"
                            [ngStyle]="{'width': getWidth(col), 'min-width': getWidth(col)}" [ngClass]="col.CellClass">
                            {{col.HeaderText}}
                            <p-sortIcon *ngIf="col.IsSortable && allowSort" [field]="col.Field"></p-sortIcon>


                            <ng-container
                                *ngIf="col.Type===gridColumnTypes.TemplateColumn || col.Type===gridColumnTypes.LinkTemplateColumn">
                                <ng-container *ngTemplateOutlet="col.headerTemplate ? col.headerTemplate : ''">
                                </ng-container>
                            </ng-container>
                        </th>

                    </ng-container>
                    <ng-container *ngIf="linkColumnCount > 0">
                        <th class="font-weight-bold" [attr.colspan]="linkColumnCount"
                            [ngStyle]="{'width': getLinkColumnWidth(), 'min-width': getLinkColumnWidth()}">
                            <div class="d-flex justify-content-center">
                                <ng-container *ngFor="let col of getLinkColumns()">
                                    <a *ngIf="col.AddIcon" class="action-btn text-nowrap"
                                        title="{{col.AddIcon.ToolTip && col.AddIcon.ToolTip !== '' ? col.AddIcon.ToolTip : 'Add'}}"
                                        (click)="col.AddIcon.Clicked()">
                                        <span class="text-center button-div pointer bg-add">
                                            <i class="fas {{addIcon}} fa-sm" aria-hidden="true"></i> Add
                                        </span>
                                    </a>
                                    <div class="dropdown ps-pix-5" [ngClass]="{'open' : btnExport }" *ngIf="col.PrintIcon"
                                        (click)="btnExport = !btnExport">
                                        <a class="action-btn text-center button-div pointer bg-print text-nowrap dropdown-toggle"
                                            type="button" id="tarkDropdown" style="margin-top: -3px;">
                                            <i class="fas {{actionIcon}} fa" aria-hidden="true" id="dropdownAction"></i>
                                            Export
                                            <span class="caret" id="dropdownCaret"></span>
                                        </a>
                                        <ul class="tark-dropdown-menu pointer" aria-labelledby="dropdownMenu">
                                            <li><a (click)="ptable.exportCSV()"><i class="fas {{exportIcon}} fa"
                                                        aria-hidden="true"></i> CSV</a></li>
                                            <li><a (click)="col.PrintIcon.Clicked()"><i
                                                        class="fas {{printIcon}} fa pe-pix-1" aria-hidden="true"></i>
                                                    Print</a></li>
                                        </ul>
                                    </div>
                                </ng-container>
                            </div>
                        </th>
                    </ng-container>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-record let-columns="columns" let-index="rowIndex">
                <tr *ngIf="record && groupCategory && rowGroupMetadata[record[groupCategory]].index === index">
                    <td [attr.colspan]="getColumnLength(columns.length)">
                        <span class="p-text-bold p-ml-2">{{record[groupCategory]}}</span>
                    </td>
                </tr>
                <tr *ngIf="record" [ngClass]="rowClass ? record[rowClass] : ''"
                    [pReorderableRow]="isReorderableRow ? index : ''" [pSelectableRow]="record"
                    [pSelectableRowIndex]="index" [pSelectableRowDisabled]="selectionMode === undefined ? true : false"
                    [ngStyle]="{'background-color': record[rowBgColor]}">
                    <td *ngIf="isReorderableRow" class="table-drag-column">
                        <i class="fa fa-bars" pReorderableRowHandle></i>
                    </td>
                    <ng-container *ngFor="let col of columns">
                        <td *ngIf="!IsLinkColumn(col)"
                            [ngStyle]="{'width': getWidth(col), 'min-width': getWidth(col), 'color': record[rowFontColor]}"
                            [ngClass]="col.CellClass">
                            <div *ngIf="col.Type===gridColumnTypes.PrimaryColumn" [ngStyle]="{'word-wrap':'break-word'}"
                                class="{{getTextAlignClass(col)}}">
                                <a class="action-btn primary-column" (click)="col.Clicked(record)">
                                    {{getPropertyValue(record, col.Field)}}
                                </a>
                            </div>
                            <div *ngIf="col.Type===gridColumnTypes.GridColumn" [ngStyle]="{'word-wrap':'break-word'}"
                                class="{{getTextAlignClass(col)}}">
                                <span *ngIf="col.IsPopOver" class="action-btn"
                                    (click)="col.PopOverColumnClicked($event, record)">{{getPropertyValue(record,
                                    col.Field)}}</span>
                                <span *ngIf="!col.IsPopOver">{{getPropertyValue(record, col.Field)}}</span>
                            </div>
                            <div *ngIf="col.Type===gridColumnTypes.TemplateColumn" class="{{getTextAlignClass(col)}}">
                                <ng-container
                                    *ngTemplateOutlet="col.itemTemplate;context:{rowData:record,rowIndex:index}">
                                </ng-container>
                            </div>
                            <div *ngIf="col.Type===gridColumnTypes.BooleanColumn" class="{{getTextAlignClass(col)}}">
                                <span *ngIf="record[col.Field]" class="badge badge-success param-badge">
                                    <i class="fas fa-check" aria-hidden="true"></i>
                                </span>
                            </div>
                            <div *ngIf="col.Type===gridColumnTypes.DateColumn" [ngStyle]="{'word-wrap':'break-word'}"
                                class="{{getTextAlignClass(col)}}">
                                <span *ngIf="col.Format === formatType.Date">
                                    {{record[col.Field] | tark_date}}
                                </span>
                                <span *ngIf="col.Format === formatType.Time">
                                    {{record[col.Field] | tark_time}}
                                </span>
                                <span *ngIf="col.Format === formatType.DateTime">
                                    {{record[col.Field] | tark_date_time}}
                                </span>
                                <span *ngIf="col.Format === formatType.Custom">
                                    {{record[col.Field] | date: col.FormatString}}
                                </span>
                            </div>
                            <div *ngIf="col.Type===gridColumnTypes.DecimalColumn" class="{{getTextAlignClass(col)}}">
                                {{record[col.Field] | tark_number }}
                            </div>
                            <div *ngIf="col.Type===gridColumnTypes.CurrencyColumn" class="{{getTextAlignClass(col)}}">
                                {{record[col.Field] | tark_currency}}
                            </div>
                        </td>
                    </ng-container>
                    <ng-container>
                        <td *ngIf="linkColumnCount > 0" [attr.colspan]="linkColumnCount"
                            [ngStyle]="{'width': getLinkColumnWidth(), 'min-width': getLinkColumnWidth(), 'color': record[rowFontColor]}"
                            class="cell-padding text-center">
                            <div class="d-flex justify-content-center">
                                <span *ngFor="let col of getLinkColumns()" [ngStyle]="{'word-wrap':'break-word'}"
                                    class="{{getTextAlignClass(col)}} padding-3">
                                    <a *ngIf="col.Type!==gridColumnTypes.LinkTemplateColumn" class="action-btn"
                                        title="{{getToolTip(col)}}"
                                        (click)="col.IsPopOver ? col.PopOverColumnClicked($event, record) : col.Clicked(record)">
                                        <span class="fa-stack" *ngIf="col.BackgroundColor">
                                            <i class="fas fa-square fa-stack-2x"
                                                [ngStyle]="{'color': col.BackgroundColor}"></i>
                                            <i class="fas {{getIcon(col)}} fa-stack-1x white"></i>
                                        </span>
                                        <i class="fas {{getIcon(col)}} fa-sm" aria-hidden="true"
                                            *ngIf="!col.BackgroundColor"></i>
                                    </a>
                                    <span *ngIf="col.Type===gridColumnTypes.LinkTemplateColumn"
                                        class="{{getTextAlignClass(col)}}">
                                        <ng-container *ngTemplateOutlet="col.itemTemplate;context:{rowData:record}">
                                        </ng-container>
                                    </span>
                                </span>
                            </div>
                        </td>
                    </ng-container>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <ng-container
                    *ngTemplateOutlet="emptyMessageTemplate ? emptyMessageTemplate : defaultEmptyMessageTemplate">
                </ng-container>
            </ng-template>
            <ng-template pTemplate="footer">
                <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
            </ng-template>
        </p-table>
    </div>

    <div *ngIf="paginator && serverSidePaginator">
        <p-paginator class="paginator" rows="{{rows}}" totalRecords="{{totalRecords}}"
            [rowsPerPageOptions]="rowsPerPageOptions" (onPageChange)="paginate($event)" [first]="first"></p-paginator>
    </div>
    <ng-template #defaultEmptyMessageTemplate>
        <tr>
            <td [attr.colspan]="getColumnLength(columns.length)">
                No records found
            </td>
        </tr>
    </ng-template>
</div>
