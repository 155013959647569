import { Component, OnInit, ViewChild } from '@angular/core';
import { InventorySubcategory } from '../../interface';
import { NgForm } from '@angular/forms';
import { InventorySubCategoryService } from '../../service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { finalize } from 'rxjs/operators';
import { InventoryCategory, InventoryCategoryService } from 'src/app/information-management/inventory-categories';
import { forkJoin } from 'rxjs';
import { PrintingService } from '../../service/printing.service';
import { DomainConstants, FormUtilityService, Messages } from 'src/app/shared';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalFormComponent, ModalService } from 'src/app/shared/components';

@Component({
    selector: 'pos-inventory-subcategory-edit',
    templateUrl: './inventory-subcategory-edit.component.html',
    styleUrls: ['./inventory-subcategory-edit.component.scss']
})
export class InventorySubcategoryEditComponent extends ModalFormComponent implements OnInit {
    get getForm(): NgForm {
        return this.form
    }
    inventorySubCategoryId: number;
    @ViewChild('inventorySubCategoryForm', { static: true }) form: NgForm;
    inventorySubCategory: InventorySubcategory;
    inventoryCategories: InventoryCategory;
    labelPrinters: any;
    constructor(private alertService: AlertsService,
        private spinnerService: SpinnerService,
        private inventorySubCategoryService: InventorySubCategoryService,
        private inventoryCategoryService: InventoryCategoryService,
        private printingService: PrintingService,
        inventorySubCategoryModalRef: BsModalRef,
        modalService: ModalService,
        formUtilityService: FormUtilityService) {
        super(inventorySubCategoryModalRef, modalService, formUtilityService);
    }

    ngOnInit() {
        this.form.reset();
        this.inventorySubCategory = this.inventorySubCategoryService.newInventorySubCategory();
        this.loadData();
        this.setInitialValue();

    }
    onCancel() {
        this.hide({ shouldReload: false });
    }
    private loadData() {
        const inventorySubCategoryObservable = [];
        inventorySubCategoryObservable.push(this.inventoryCategoryService.getInventoryCategoryList());
        inventorySubCategoryObservable.push(this.printingService.getHardwaresOfType(DomainConstants.HardwareTypes.LabelPrinter));
        if (this.inventorySubCategoryId > 0) {
            inventorySubCategoryObservable.push(
                this.inventorySubCategoryService.getInventorySubCategoryDetails(this.inventorySubCategoryId));
        }

        this.spinnerService.show();
        forkJoin(inventorySubCategoryObservable)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({next:(responses: any) => {
                this.inventoryCategories = responses[0];
                this.labelPrinters = responses[1];
                if (responses.length > 2) {
                    this.inventorySubCategory = responses[2];
                } else {
                    this.inventorySubCategory = this.inventorySubCategoryService.newInventorySubCategory();
                }
            }, error: this.alertService.showApiError });
    }

    submit(valid: boolean) {
        if (!valid ||
            (this.inventorySubCategory.days_between_inventory < 0 || this.inventorySubCategory.days_between_inventory > 99 ||
                this.inventorySubCategory.days_between_inventory % 1 !== 0) ||
            (!this.inventorySubCategory.categoryid)) {
            return;
        }
        this.spinnerService.show();
        this.inventorySubCategoryService.saveInventorySubcategory(this.inventorySubCategory)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({next:(inventorySubCategory: InventorySubcategory) => {
                this.alertService.renderSuccessMessage(Messages.InventorySubCategorySaveSuccess);
                this.inventorySubCategoryId = 0;
                this.inventorySubCategory = inventorySubCategory;
                this.hide({ shouldReload: true });
            }, error: this.alertService.showApiError });
    }
}
