import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SetAccountComponent } from './set-account.component';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from '../shared';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ComponentsModule
  ],
  declarations: [ SetAccountComponent ],
  exports: [ SetAccountComponent ]
})
export class SetAccountModule { }
