import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RawIngredientsService } from '../../service';
import { AlertsService, FormUtilityService, SpinnerService } from 'src/app/shared';
import { RawIngredient } from '../../interface';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { ActivatedRoute, Router } from '@angular/router';
import { inventoryRawIngredient } from 'src/app/shared/components/icon';
import { BaseFormComponent } from 'src/app/shared/components/base-form.component';

@Component({
  selector: 'pos-raw-ingredients-edit',
  templateUrl: './raw-ingredients-edit.component.html',
})
export class RawIngredientsEditComponent extends BaseFormComponent implements OnInit {

  @ViewChild('rawIngredientForm', { static: true }) form: NgForm;
  public rawIngredientData: RawIngredient;
  icons = {
    inventoryRawIngredient
  }
  get getForm(): NgForm {
    return this.form
  }
  constructor(
    private ingredientService: RawIngredientsService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    formUtilityService: FormUtilityService
  ) {
    super(formUtilityService);
    this.rawIngredientData = this.ingredientService.newrawIngredients();
    this.rawIngredientData.Id = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
  }

  ngOnInit() {
    this.getRawIngredientById();
    this.setInitialValue();
  }

  getRawIngredientById() {
    if (this.rawIngredientData.Id > 0) {
      this.spinnerService.show();
      this.ingredientService.getRawIngredientByID(this.rawIngredientData.Id).subscribe(
        (response: RawIngredient) => {
          this.rawIngredientData = response;
        },
        this.alertService.showApiError,
        () => {
          this.spinnerService.hide();
        }

      );
    }
  }

  saveRawIngredient(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.spinnerService.show();
    if (this.rawIngredientData.Id === 0) {
      this.ingredientService.addRawIngredient(this.rawIngredientData).subscribe(
        () => {
          this.router.navigate(['raw-ingredients'], { relativeTo: this.route.parent});
          this.alertService.renderSuccessMessage(Messages.RawIngredientsSave);
          this.onCancel();
        },
        this.alertService.showApiError,
        () => {
          this.spinnerService.hide();
        }
      );
    } else {
      this.ingredientService.updateRawIngredient(this.rawIngredientData).subscribe(
        () => {
          this.router.navigate(['raw-ingredients'], { relativeTo: this.route.parent});
          this.alertService.renderSuccessMessage(Messages.RawIngredientsUpdate);
          this.onCancel();
        },
        this.alertService.showApiError,
        () => {
          this.spinnerService.hide();
        }
      );
    }
  }

  onCancel() {
    this.router.navigate(['raw-ingredients'], { relativeTo: this.route.parent});
  }

}
