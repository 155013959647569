<pos-edit-wrapper heading="Message" (close)="cancel()">
  <form (ngSubmit)="globalLogoff(globaLogOffForm.valid)" #globaLogOffForm="ngForm">
    <div class="col-12 pos-modal-body pt-pix-5 mx-0">
      <pos-column>
        <pos-form-field [validationMessages]="{required: 'Please enter message.'}" inputStyle="col-12">
          <textarea posInput class="form-control" name="logoffMessage" [(ngModel)]="logoffMessage" [required]="true"
            #message="ngModel">
          </textarea>
        </pos-form-field>
      </pos-column>
    </div>
    <div class="col-12 action-button-container popup-footer">
      <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" type="submit" value="Logoff">Logoff</button>
    </div>
  </form>
</pos-edit-wrapper>
