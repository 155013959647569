import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { InventoryWorkflowParameter } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class InventoryWorkflowService {

  inventoryWorkflowApiBasePath = `${RuntimeConstants.API_PATH}inventory-workflow-configuration`;
  constructor(private httpService: HttpClient) { }

  newInventoryWorkflowConfigurationParameter(): InventoryWorkflowParameter {
    return {
      NewInventoryRecordOnDuplicateAmount: false,
      InventoryLogPopulateQuantityUsing: '',
      InventoryLogStockWarningMinimum: null,
      InventoryLogUnchangedQtyWarningMinimumPercent: null,
      InventoryLogUnchangedQtyWarningMinimumThreshold: null,
      InventoryReceivingConfirmationWaitTime: 0,
      InventoryReceivingConfirmationWarningText: '',
      DefaultLocationTerm: ''
    };
  }

  getInventoryWorkflowParameters(): Observable<InventoryWorkflowParameter> {
    return this.httpService.get<InventoryWorkflowParameter>(`${this.inventoryWorkflowApiBasePath}`);
  }

  updateInventoryWorkflowParameters(inventoryWorkflowParameter: InventoryWorkflowParameter) {
    return this.httpService.post<InventoryWorkflowParameter>(`${this.inventoryWorkflowApiBasePath}`, inventoryWorkflowParameter);
  }
}
