import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';
import { DataSourceParameter } from '../interface/data-source-parameter';

@Injectable({
  providedIn: 'root'
})
export class DataSourceParameterService extends BaseCrudService<DataSourceParameter> {

  dataSourceParameterApiPath = `${RuntimeConstants.API_PATH}data-source-parameters/`;

  constructor(private httpClient: HttpClient) {
    super('data-source-parameters', httpClient);
  }

  newDataSourceParameter(): DataSourceParameter {
    return {
      Id: 0,
      Name: '',
      Label: '',
      DataType: null,
      DataLength: null,
      InitialValue: null,
      ManualValues: '',
      QueryValues: '',
      IsRequired: false,
      Ordinal: 0,
      DataSourceId: null,
      AllowUserInput: false,
      HasDropdown: false
    };
  }

  getDataSourceParameters(dataSourceId: number): Observable<Array<DataSourceParameter>> {
    return this.httpClient.get<Array<DataSourceParameter>>(`${this.dataSourceParameterApiPath}${dataSourceId}`);
  }

  updateParameterOrdinal(parameters: Array<DataSourceParameter>): Observable<any> {
    return this.httpClient.put(`${this.dataSourceParameterApiPath}ordinal`, parameters);
  }
}
