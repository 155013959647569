<pos-form-field [label]="controlLabel" inputStyle="col-lg-5 col-md-6 col-sm-8 col-12">
<ng-select posInput name="ngSelectTags" id="ngSelectTags" [items]="items" [ngClass]="{'select-disabled': disable}" [(ngModel)]="selectedTags" [bindLabel]="label"
  appendTo="body" [multiple]="true" placeholder="Select tag(s)" [hideSelected]="true" [selectOnTab]="true" [disabled]="disable" [dropdownPosition]="dropdownPosition">
  <ng-template ng-label-tmp let-item="item">
    <div class = "bg-white">
      <p-chip *ngIf="item.Icon"
        [label]="item[label]"
        image="{{imagePath}}/{{item.Icon}}"
        [style]="{ 'background-color': item.Color , 'color':getContrastColor(item.Color)}"
        [removable]="true" (mousedown)="$event.stopPropagation()"
        (onRemove)="removeTagSelection(item)"
      ></p-chip>
      <p-chip *ngIf="!item.Icon"
        [label]="item[label]" (mousedown)="$event.stopPropagation()"
        [style]="{ 'background-color': item.Color , 'color':getContrastColor(item.Color)}"
        [removable]="true"
        (onRemove)="removeTagSelection(item)"
      ></p-chip>
    </div>
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-index="index">
    <div class="display-flex">
      <img class="tag-image" [hidden]="!item.Icon" height="25" width="25" src="{{imagePath}}/{{item.Icon}}" [style]="{ 'background-color': item.Color , 'color':getContrastColor(item.Color)}"/>
      
      <div class="caption" [ngClass]="{'pli-38': !item.Icon}">{{ item[label] }}</div>
    </div>
  </ng-template>
</ng-select>
</pos-form-field>
