import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { data } from 'currency-codes';
import { forEach, orderBy } from 'lodash';
import { SpinnerService } from 'src/app/shared/components/spinner';
import { DomainConstants, Messages } from 'src/app/shared/constants';
import { SettingParam } from 'src/app/shared/interface';
import ISO6391 from 'iso-639-1';
import { AlertsService, ApplicationStateService, FormUtilityService, ParameterService } from 'src/app/shared/services';
import { finalize } from 'rxjs/operators';
import { language } from 'src/app/shared/components/icon';
import { PostSavedConfigurationService } from 'src/app/configurator/post-saved-configuration.service';
import { BaseFormComponent } from 'src/app/shared/components';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'pos-formats',
  templateUrl: './formats.component.html',
})
export class FormatsComponent extends BaseFormComponent implements OnInit {
  
  @ViewChild('formatsForm') formatsForm: NgForm;
  icons = {
    language
  };
  get getForm(): NgForm {
    return this.formatsForm;
  }
  constructor(
    private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private applicationStateService: ApplicationStateService,
    private parameterService: ParameterService,
    private postSavedConfigurationService: PostSavedConfigurationService,
    private router: Router,
    formUtilityService: FormUtilityService) {
      super(formUtilityService);
      this.parameters = applicationStateService.newSettingParams();
  }

  currencies = [];
  locales = [];
  timeFormats = [...DomainConstants.TimeFormats];
  parameters: SettingParam;

  ngOnInit(): void {
    this.loadDependency();
    this.loadData();
  }

  loadDependency() {
    this.currencies = orderBy(data, 'currency');
    this.locales = ISO6391.getLanguages(ISO6391.getAllCodes());
    this.locales = orderBy(forEach(this.locales, x => x.name = `${x.name} (${x.nativeName})`), 'name');
  }

  loadData() {
    this.spinnerService.show();
    this.parameterService.getParameterForTerminal(this.applicationStateService.terminalId).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (response: SettingParam) => {
        this.parameters = response;
        this.parameters.Currency = !this.parameters.Currency ? null : this.parameters.Currency;
        this.parameters.Locale = !this.parameters.Locale ? null : this.parameters.Locale;
      }, error: this.alertsService.showApiError
    });
  }

  saveFormats(isValid) {
    if (!isValid) {
      return;
    }
    this.spinnerService.show();
    this.parameters.Currency = this.parameters.Currency ?? '';
    this.parameters.Locale = this.parameters.Locale ?? '';
    this.parameters.TimeFormat = this.parameters.TimeFormat ?? '';
    this.parameterService.saveFormats(this.parameters).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (response) => {
        this.alertsService.renderSuccessMessage(Messages.FormatParameterSaveSuccess);
        this.postSavedConfigurationService.showMessage();
      }, error: this.alertsService.showApiError
    });
  }

  cancelEditing() {
    this.router.navigate(['/manage/system-configuration']);
  }

}
