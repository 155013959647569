import { formatDate } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { cloneDeep, find, forEach, isArray, orderBy } from 'lodash';
import { SalesCategory } from 'src/app/information-management/sales-categories';
import { SalesProduct } from 'src/app/information-management/sales-products';
import { ApplicationStateService, DomainConstants, PromotionDataService } from 'src/app/shared';
import { PromotionConfigParam } from 'src/app/shared/interface';
const EMPTY_VALUE = '____';
@Component({
  selector: 'pos-config',
  templateUrl: './config.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ConfigComponent implements OnInit, OnChanges {
  @Input() configs: Array<PromotionConfigParam> = [];
  @Input() inputName: string = null;
  @Input() parentElement: string = "body";
  @Input() description: string = null;
  descriptionFormat: string = null;
  promotionConfigPropertyType = DomainConstants.PromotionConfigPropertyTypes;
  accountAttribute;
  salesCategories: Array<SalesCategory> = [];
  salesProducts: Array<SalesProduct> = [];
  daysOfWeek = [];

  constructor(protected form: NgForm,
    protected promotionData: PromotionDataService,
    private applicationStateService: ApplicationStateService) {
  }

  ngOnInit(): void {
    this.prepareWeekDays();
    this.promotionData.data$
      .subscribe({
        next: (res) => {
          forEach(this.configs, x => {
            if (x && x.Type == this.promotionConfigPropertyType.MembershipTier) {
              this.onChangeMemberShip(x);
            } else if (x && x.Type == this.promotionConfigPropertyType.WeekDays) {
              this.daysOfWeek.forEach(day => day.IsChecked = x.Value?.includes(day.Id.toString()));
            }
          });
          this.setDependent(true);
        }
      });
  }

  private setDescriptionFormat() {
    if (this.description) {
      this.descriptionFormat = cloneDeep(this.description);
      this.prepareDescription();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    forEach(this.configs, x => {
      if (x?.Type == this.promotionConfigPropertyType.MembershipTier) {
        if (!x.Value) {
          x.Value = { Name: null, Value: null };
        }
        this.onChangeMemberShip(x);
      }
      if (x?.Type == this.promotionConfigPropertyType.PromotionPeriods && !x.Value) {
        x.Value = this.promotionData?.promotionPeriods[0]?.Value;
      }
    });
    this.setDependent();
    if (changes['description']) {
      this.setDescriptionFormat();
    }
  }

  setDependent = (isFromInit = false) => {
    this.salesCategories = this.promotionData.salesCategories;
    this.salesProducts = this.promotionData.salesProducts;
    forEach(this.configs, (item) => {
      this.clearDependent(item, false);
    });
    if (isFromInit) {
      const timeout = setTimeout(() => {
        this.prepareDescription();
        clearTimeout(timeout);
      });
    }
  }

  onChangeMemberShip = (config) => {
    this.accountAttribute = this.promotionData?.accountAttributeTypes?.find((item) => {
      return item.Name == config.Value.Name;
    });
    if (this.accountAttribute) {
      if (this.accountAttribute.ValueType == "Date") {
        this.accountAttribute.Value = new Date(config.Value.Value);
      } else {
        this.accountAttribute.Value = config.Value?.Value;
      }
    }
  }

  clearDependent = (config: PromotionConfigParam, clearValue: boolean = true) => {
    forEach(this.configs, (item) => {
      let parentIds: Array<number> = [];
      if (item && config?.Name && item.DependOn == config.Name) {
        switch (item.Type) {
          case this.promotionConfigPropertyType.SalesProduct: {
            this.salesProducts = this.promotionData.salesProducts;
            parentIds = this.salesCategories.map(x => x.id);
            this.salesProducts = this.filterDependent(this.promotionData.salesProducts, item, 'CategoryId', parentIds);
            if (this.salesProducts.length) {
              item.Value = isArray(item.Value) ? item.Value?.filter((data) => {
                return this.salesProducts.find(x => x.id == data);
              }) : null;
            }
            break;
          }
          case this.promotionConfigPropertyType.SalesCategory: {
            parentIds = this.promotionData.salesGroups.map(x => x.Id);
            this.salesCategories = this.filterDependent(this.promotionData.salesCategories, item, 'sales_group_id', parentIds);
            if (this.salesCategories.length) {
              item.Value = item.Value?.filter((data) => {
                return this.salesCategories.find(x => x.id == data);
              });
            }
            break;
          }
          default: {
            break;
          }
        }
        this.clearDependent(item, clearValue);
      }
    });
  }

  filterDependent = (items: Array<any>, config: PromotionConfigParam, propertyName: string, parentItems: Array<number> = []) => {
    const parentConfig = this.configs?.find(x => x.Name == config.DependOn);
    if (parentConfig) {
      if (isArray(parentConfig.Value) ? parentConfig.Value.length : parentConfig.Value) {
        if (parentConfig.HasMultipleValues) {
          items = items.filter(x => (x.hasOwnProperty(propertyName) && (parentConfig.Value as Array<number>).includes(x[propertyName])));
        } else {
          items = items.filter(x => (x.hasOwnProperty(propertyName) && x[propertyName] == parentConfig.Value));
        }
      }
      else {
        items = items.filter(x => (x.hasOwnProperty(propertyName) && parentItems.includes(x[propertyName])));
      }
    }
    return items;
  }

  onChangeAccountAttribute = (attribute, config) => {
    config.Value.Value = attribute.Value;
  }

  prepareWeekDays() {
    const weekStart = find(DomainConstants.Weeks, (day) => day.Value === this.applicationStateService.settingParam.WeekStart);
    let daysOfWeek = [];
    forEach(DomainConstants.Weeks, (x) => {
      daysOfWeek.push({ Id: x.Id + 1, Name: x.Abbreviation, IsChecked: false, Key: x.Key });
    });
    daysOfWeek = orderBy(daysOfWeek, 'Id');
    this.daysOfWeek = [...daysOfWeek.slice(weekStart.Id, daysOfWeek.length), ...daysOfWeek.slice(0, weekStart.Id)];
  }

  onWeekDaysChange(config) {
    config.Value = this.daysOfWeek.filter(x => x.IsChecked).map(x => x.Id).join('');
  }

  prepareDescription() {
    if (this.description) {
      this.description = cloneDeep(this.descriptionFormat);
      forEach(this.configs, config => {
        this.description = this.description.replace("{" + config.Name + "}", `<b>${this.getItemsData(config)}</b>`);
      });
    }
  }

  getItemsData(config: PromotionConfigParam) {
    let value;
    const hasValue: boolean = isArray(config.Value) ? config.Value.length  : config.Value;
    switch (config.Type) {
      case this.promotionConfigPropertyType.SalesProduct:
        value = this.prepareSalesProductValue(hasValue, config, value);
        break;
      case this.promotionConfigPropertyType.SalesCategory:
        value = this.prepareSalesCategoryValue(hasValue, config, value);
        break;
      case this.promotionConfigPropertyType.SalesGroup:
        value = this.prepareSalesGroupValue(hasValue, config, value);
        break;
      case this.promotionConfigPropertyType.PromotionPeriods:
        value = config.Value ? this.promotionData.promotionPeriods.find(x => config.Value == x.Value)?.Name : EMPTY_VALUE;
        break;
      case this.promotionConfigPropertyType.Time:
        if (config.Value) {
          const time = config.Value.split(':');
          const date = new Date().setHours(time[0], time[1]);
          value = formatDate(date, this.applicationStateService.settingParam.TimeFormat, 'en');
        } else {
          value = EMPTY_VALUE;
        }
        break;
      case this.promotionConfigPropertyType.WeekDays:
        if (config.Value) {
          const days = this.daysOfWeek.filter(x => config.Value.includes(x.Id))?.map(x => x.Key);
          value = 'On ' + days.join(', ');
        } else {
          value = 'Every day ';
        }
        break;
      default:
        value = config.Value ? config.Value : EMPTY_VALUE;
        break;
    }
    return value;
  }


  private prepareSalesGroupValue(hasValue: boolean, config: PromotionConfigParam, value) {
    if (hasValue) {
      if (config.HasMultipleValues) {
        const groups = this.promotionData.salesGroups.filter(x => config.Value.includes(x.Id))?.map(x => x.name);
        value = groups.join(', ');
      } else {
        value = this.promotionData.salesGroups.find(x => x.Id == config.Value)?.name;
      }
    } else {
      value = 'Any';
    }
    return value;
  }

  private prepareSalesCategoryValue(hasValue: boolean, config: PromotionConfigParam, value) {
    if (hasValue) {
      if (config.HasMultipleValues) {

        let categories = this.salesCategories.filter(x => config.Value.includes(x.id))?.map(x => x.name);
        value = categories.join(', ');
      } else {
        value = this.salesCategories.find(x => x.id == config.Value)?.name;
      }
    } else {
      value = 'Any';
    }
    return value;
  }

  private prepareSalesProductValue(hasValue: boolean, config: PromotionConfigParam, value) {
    if (hasValue) {
      if (config.HasMultipleValues) {
        let products = this.salesProducts.filter(x => config.Value.includes(x.id))?.map(x => x.Name);
        value = products.join(', ');
      } else {
        value = this.salesProducts.find(x => x.id == config.Value)?.Name;
      }
    } else {
      value = 'Any';
    }
    return value;
  }
}
