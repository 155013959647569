import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalComponent } from 'src/app/shared/components/modal/modal-component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TrainingTopicService } from 'src/app/shared/services/training-topic.service';
import { AlertsService, SpinnerService, AuthenticationService, ConfirmDeleteComponent, Messages } from 'src/app/shared';
import * as _ from 'lodash';
import { TrainingArea } from 'src/app/information-management/training-areas';
import { NgForm } from '@angular/forms';
import { TrainingAreaService } from 'src/app/shared/services/training-area.service';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
@Component({
  selector: 'pos-training-topic-move',
  templateUrl: './training-topic-move.component.html',
  styleUrls: ['./training-topic-move.component.scss']
})
export class TrainingTopicMoveComponent extends ModalComponent implements OnInit {

  trainingAreas: Array<TrainingArea> = [];
  trainingTopicId: number;
  trainingAreaId: number;
  selectedArea: any;
  trainingAreaName: string;
  trainingTopicName: string;
  @ViewChild('trainingTopicMoveForm') trainingTopicMoveForm: NgForm;
  @ViewChild('confirmMoveTrainingTopic') private confirmMoveTrainingTopic: ConfirmDeleteComponent;
  props = {
    labelClass: '',
    controlBoxClass: 'col-lg-9 col-xs-8'
  }
  constructor(private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private authenticationService: AuthenticationService,
    private trainingAreaService: TrainingAreaService,
    private trainingTopicService: TrainingTopicService,
    modalRef: BsModalRef) {
    super(modalRef);
  }

  ngOnInit() {
    this.getAreaList();
  }

  getAreaList() {
    this.spinnerService.show();
    this.trainingAreaService.getTrainingAreas().subscribe(
      res => {
        this.trainingAreas = _.filter(res, (area) => {
          return area.Id != Number(this.trainingAreaId);
        });
      },
      this.alertService.showApiError,
      () => {
        this.spinnerService.hide();
      }
    );
  }

  onConfirmAcknowledge() {
    this.spinnerService.show();
    this.trainingTopicService.moveTrainingTopic(this.selectedArea.Id, this.trainingTopicId).subscribe(
      res => {
        this.alertService.renderSuccessMessage(StringUtils.format(Messages.TrainingTopicMoveSuccess,
          { 'trainingTopic': this.trainingTopicName }
        ));
        this.onCancel(true);
      }, this.alertService.showApiError, () => {
        this.spinnerService.hide();
      }
    );
  }

  moveTrainingTopic(isValid) {
    if (!isValid || !this.selectedArea) {
      return
    }
    this.confirmMoveTrainingTopic.Show(
      StringUtils.format(Messages.ConfirmMoveTrainingTopic,
        {
          'trainingTopic': this.trainingTopicName,
          'trainingSourceArea': this.trainingAreaName,
          'trainingDestArea': this.selectedArea.Name
        }
      ));
  }

  onCancel(reload: boolean) {
    this.hide({ shouldReload: reload });
  }

}
