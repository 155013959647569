import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { ServicingInformationService } from '../../service';
import { ServingInformation } from '../../interface';
import { AlertsService, SpinnerService } from 'src/app/shared';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'pos-inventory-product-serving-edit',
  templateUrl: './inventory-product-serving-edit.component.html',
  styleUrls: ['./inventory-product-serving-edit.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm}]
})
export class InventoryProductServingEditComponent implements OnInit, OnChanges {
  @ViewChild('groupServingInformation', { static: true }) groupServingInformation: NgForm;
  @Input("servingUnit") servingUnit: any;
  @Input() servingInformationData: ServingInformation;
  @Input() isSubmit: boolean;
  @Output() validateOnSave = new EventEmitter<boolean>();
  formSubmitted: boolean = false;
  measureList;

  constructor(
    private servingInformationService: ServicingInformationService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService
  ) {

  }

  ngOnInit() {
    this.formSubmitted = false;
    this.servingInformationService.getAllMeasures()
      .subscribe(
        (response) => {
          this.measureList = response;
        }
      );
  }

  ngOnChanges() {
    if(this.isSubmit) {
      this.formSubmitted = true;
      if(this.servingInformationData.ServingsPerContainer < 0 
        || this.servingInformationData.ServingSize < 0
        || this.servingInformationData.Calories < 0
        || this.servingInformationData.CaloriesFromFat < 0
        || this.servingInformationData.ServingRatioToProduct <0
      )
      {
        return;
      }
      this.validateOnSave.emit(this.groupServingInformation.valid);
    }
  }

}
