import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { WidgetHeaderConfig } from '../..';
import { ImageWidgetStyleConfig } from '../../interfaces/image-widget-style-config';
import { MenuDisplayItem } from '../../interfaces/menu-display-item';
import { WidgetDetails } from '../../interfaces/widget-details';
import { MenuWidgetStyleService } from '../../services/menu-widget-style.service';
import { MenuWidgetService } from '../../services/menu-widget.service';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import * as $ from 'jquery';
import { ImageListWidgetConfig } from '../../interfaces/image-list-widget-config';
import { MenuItemsSortService } from '../../services/menu-items-sort.service';
import { Subscription } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { cloneDeep, forEach, some } from 'lodash';
import { RabbitMQService } from 'src/app/shared/services/rabbitmq/services/rabbitmq.service';
import { DomainConstants } from 'src/app/shared/constants/domain-constants';
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pos-menu-image-list-widget',
  templateUrl: './menu-image-list-widget.component.html',
  styleUrls: ['./menu-image-list-widget.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MenuImageListWidgetComponent extends BaseWidgetComponent implements OnInit {

  @Input() widgetId: number;
  outOfStockBehaviorTypes = DomainConstants.OutOfStockBehaviorTypes;
  headerWidgetConfig: WidgetHeaderConfig;
  imageWidgetConfig: ImageListWidgetConfig;
  widgetData: WidgetDetails;
  imageWidgetStyle: ImageWidgetStyleConfig;
  menuListItems: Array<MenuDisplayItem> = [];
  contentStyle = {};
  bannerImagePath = RuntimeConstants.IMAGE_BASE_PATH + '/menu-display';
  imagePath = RuntimeConstants.IMAGE_BASE_PATH + '/menu-display/sales-products';
  imageFontStyle = {};
  rabbitMqSalesProductOutOfStockEventSubscription: Subscription;
  rabbitMqSalesProductReplenishedEventSubscription: Subscription;

  constructor(protected menuWidgetService: MenuWidgetService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected applicationStateService: ApplicationStateService,
    protected modalService: ModalService,
    protected menuWidgetStyleService: MenuWidgetStyleService,
    protected rabbitMQService: RabbitMQService,
    private menuItemSortService: MenuItemsSortService) {
    super(menuWidgetService, alertService, spinnerService, applicationStateService, modalService, rabbitMQService);
  }

  ngOnInit(): void {
    this.loadData();
    this.loadWidget(this.widgetId);
    this.subscribeToSalesProductOutOfStockEventExchange();
    this.subscribeToSalesProductReplenishedEventExchange();
  }

  subscribeToSalesProductOutOfStockEventExchange = () => {
    this.rabbitMqSalesProductOutOfStockEventSubscription = this.rabbitMQService.subscribeToSalesProductOutOfStockEventExchange$()
      .subscribe({
        next: (message) => {
          if (message.Payload.POSEvent) {
            const menuListItems = cloneDeep(this.menuListItems)
            this.menuListItems = this.setSalesProductStockStatus(message.Payload.POSEvent.SalesProductId, message.Payload.POSEvent.SizeIds, false, menuListItems);
          }
        }
      });
  }

  subscribeToSalesProductReplenishedEventExchange = () => {
    this.rabbitMqSalesProductReplenishedEventSubscription = this.rabbitMQService.subscribeToSalesProductReplenishedEventExchange$()
      .subscribe({
        next: (message) => {
          if (message.Payload.POSEvent) {
            const menuListItems = cloneDeep(this.menuListItems);
            this.menuListItems = this.setSalesProductStockStatus(message.Payload.POSEvent.SalesProductId, message.Payload.POSEvent.SizeIds, true, menuListItems);
          }
        }
      });
  }

  setSalesProductStockStatus = (productId: number, sizeIds: number[], status: boolean, menuListItems: any[]) => {
    forEach(menuListItems, (item) => {
      if (item.Id === productId && item?.Prices) {
        forEach(item.Prices, (price) => {
          if (sizeIds?.some((size) => { return size == price?.SizeId })) {
            price.IsInStock = status;
          }
        });
      }
      item.IsInStock = some(item.Prices, (price) => price?.IsInStock);
    });
    return menuListItems
  }

  loadData() {
    this.widgetData$.subscribe({
      next: (response: WidgetDetails) => {
        this.menuListItems = response.MenuDisplayItems;
        this.imageWidgetStyle = this.parseJson<ImageWidgetStyleConfig>(response.WidgetStyleConfig);
        this.imageWidgetConfig = this.parseJson<ImageListWidgetConfig>(response?.WidgetConfig);
        this.headerWidgetConfig = this.imageWidgetConfig?.WidgetHeaderConfig;
        this.imageWidgetStyle?.BorderStyle ? $('.widget-border-' + this.widgetId)?.css(this.menuWidgetStyleService.getBorderStyle(this.imageWidgetStyle?.BorderStyle)) : null;
        $('.widget-background-' + this.widgetId)?.css({ 'background': this.imageWidgetStyle?.WidgetBackgroundColor });
        this.imageFontStyle = this.menuWidgetStyleService.getFontStyle(this.imageWidgetStyle?.ItemFontStyle);
        this.contentStyle = this.getWidgetContentStyle();
        this.menuListItems = this.menuItemSortService.sortItems(this.menuListItems, this.imageWidgetConfig?.ProductDisplayOrdinal?.find(pdo => pdo.GroupId == null)?.Ordinal ?? []);
      }
    });
  }

  editWidget() {
  }

  getWidgetContentStyle() {
    return {
      'justify-content': this.imageWidgetStyle?.Horizontal,
      'background-color': this.imageWidgetStyle?.WidgetBackgroundColor,
    };
  }
}
