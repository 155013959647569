import { ApplicationStateService } from './../../../../shared/services/application-state.service';
import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { GridColumn, EditColumn, LinkColumn, DeleteColumn, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { SettingParam, AlertsService, SpinnerService, spinner } from 'src/app/shared';
import { DashboardTerminalPropertiesService } from '../../../../shared/services/dashboard-terminal-properties.service';
import { TerminalDashboardProperty } from '../../interface/dashboard-terminal-property'

@Component({
  selector: 'pos-dashboard-terminal-properties',
  templateUrl: './dashboard-terminal-properties.component.html',
})
export class DashboardTerminalPropertiesComponent implements OnInit {

  @Input('dashboardList') dashboardList: Array<TerminalDashboardProperty> = [];
  @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEdit: EventEmitter<any> = new EventEmitter<any>();
  dashboardPropertyColumn: Array<GridColumn> = [];
  screenHeight = '150px';
  constructor(private applicationStateService: ApplicationStateService,
    private dashboardTerminalPropertiesService: DashboardTerminalPropertiesService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
  ) { }

  ngOnInit() {
    this.configureGridColumns();
  }

  configureGridColumns() {
    const dashboardColumn = new GridColumn({
      HeaderText: 'Dashboard',
      Field: 'Dashboard.Name',
      IsSortable: false,
      Width: '60%'
    });

    const dashboardSec = new GridColumn({
      HeaderText: 'Seconds',
      Field: 'Seconds',
      IsSortable: false,
      Width: '22%'
    });

    const editColumn = new EditColumn({
      Clicked: (data) => {
        this.editDashboardProperty(data);
      },
      Width: '40px'
    });

    const deleteColumn = new DeleteColumn({
      Clicked: (data) => {
        this.deleteDashboardProperty(data);
      },
      Width: '40px'
    });

    this.dashboardPropertyColumn = [
      dashboardColumn,
      dashboardSec,
      editColumn,
      deleteColumn
    ] as Array<GridColumn>;
  }

  getTerminalProperties() {
    if (this.dashboardList && this.dashboardList.length > 0) {
      this.dashboardTerminalPropertiesService.getTerminalDashboardProperties(this.dashboardList[0].TerminalId)
        .subscribe({
          next: (res: any) => {
            this.dashboardList = res;
          }, error: this.alertService.showApiError
        });
    }
  }

  deleteDashboardProperty(data: TerminalDashboardProperty) {
    this.spinnerService.show();
    this.dashboardTerminalPropertiesService.deleteTerminalDashboardProperty(data.Id)
      .subscribe({
        next: (res) => {
          this.onDelete.emit();
        }, error: this.alertService.showApiError,
        complete: () => {
          this.spinnerService.hide();
        }
      });
  }

  gridRowReorder(event) {
    this.dashboardTerminalPropertiesService.updatePropertyOrdinal(this.dashboardList)
      .subscribe({
        next: (res) => {

        }, error: this.alertService.showApiError
      });
  }

  editDashboardProperty(data: TerminalDashboardProperty) {
    if (data) {
      this.onEdit.emit({ 'property': data });
    }
  }

}
