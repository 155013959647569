<div class="row g-0">
    <div class="col-11 pe-2" [ngClass]="{'has-error': IconInput.invalid && submitted}">
        <input name="iconInput" #IconInput="ngModel" type="text" (input)="onInputData()" class="form-control"
            [(ngModel)]="value" [required]="required" />
    </div>
    <div class="col-1 p-0 mt-pix-8">
        <i class="{{value}}"></i>
    </div>
    <span *ngIf="IconInput.invalid && submitted" [ngClass]="{'has-error': IconInput.invalid && submitted}">
        <label class="control-label">{{validationMessage}}</label>
    </span>
</div>

