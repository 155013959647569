<div class="h-80">
  <pos-header-entry-main [links]="headerLinks" [homeState]="homeState" (filterProductsClick)="filterProduct()"></pos-header-entry-main>
</div>
<div *ngIf="!isShowFilterScreen">
  <div class="{{isOpenCookingPanel?'col-md-9 p-0' : 'col-md-12 p-0'}}" [ngStyle]="{'height': makeTableOrders?.length ? 'inherit' : 'calc(100vh - 80px)'}">
    <div style="padding-top: 0.2%" *ngIf="makeTableOrders && makeTableOrders.length > 0">
      <div class="grid white"
        [ngClass]="{'grid' : currentLayout == makeTableLayoutViews.Grid, 'best-fit': currentLayout == makeTableLayoutViews.BestFit}">
        <div *ngIf="currentLayout == makeTableLayoutViews.BestFit">
          <div class="container best-fit pe-pix-10 ps-pix-10">
            <div *ngFor="let column of columns" [ngStyle]="{'width': 100/noOfColumns + '%'}"
              class="column {{'col-'+ column}}">
              <ng-template ngFor let-item [ngForOf]="getDataForColumn(column)" let-i="index">
                <ng-container
                  *ngTemplateOutlet="itemTemplate;context:{makeTableOrders:item, index:i*noOfColumns+column-1}">
                </ng-container>
              </ng-template>
            </div>
          </div>
        </div>
        <div *ngIf="currentLayout === makeTableLayoutViews.Grid">
          <div class="container grid pe-pix-10 ps-pix-10">
            <ng-template ngFor let-item [ngForOf]="makeTableOrders | orderBy: 'Id'" let-i="index">
              <ng-container *ngTemplateOutlet="itemTemplate;context:{makeTableOrders:item, index:i}"></ng-container>
            </ng-template>
          </div>
        </div>
        <ng-template #itemTemplate let-item="makeTableOrders" let-i="index">
          <div [ngStyle]="{'width': currentLayout == makeTableLayoutViews.Grid ? 100/noOfColumns + '%' : '100%'}">
            <div class='float-start p-2' style="width:100%">
              <pos-order-card [order]="item" [currentLayout]="currentLayout"
                (orderItemStateChangeEmit)="orderItemStateChange($event)"
                (changeOrderUpdateStateEmit)="changeOrderUpdateState($event)"
                [makeTableColorConfig]="makeTableColorConfig" [(noOfColumns)]="noOfColumns" [isStarColor]="isStarColor"
                (serveOrder)="serveThisOrder($event)" [(uiStates)]="makeTableUIStates"
                [isFilteredProductsApplied]="isFilteredProductsApplied"
                ></pos-order-card>
              <div class="row row-cols-2 mx-0" *ngIf="!isReadOnlyMakeTable">
                <div class=""
                  [ngClass]="{'col-12 p-0': makeTableUIStates && !makeTableUIStates[item.Id]?.IsReadyToServe, 'col-lg-6 col-md-5 col-6 ps-0 pe-pix-5' : makeTableUIStates && makeTableUIStates[item.Id]?.IsReadyToServe}"
                  *ngIf="makeTableUIStates && makeTableUIStates[item.Id]?.IsShowNotifyButton">
                  <button class="btn btn-lg normal width-full mt-pix-10 pos-button"
                    [disabled]="makeTableUIStates[item.Id].IsDisableNotifyButton"
                    [ngStyle]="{'background-color': notifyButtonColor, 'color': notifyButtonFontColor}"
                    (click)="notify(item.Id)"><pos-icon [icon]="icons.makeTableNotified" *ngIf="item?.IsNotified"></pos-icon> Notify</button>
                </div>
                <div class=""
                  [ngClass]="{'col-12 p-0': makeTableUIStates && !makeTableUIStates[item.Id]?.IsShowNotifyButton, 'col-lg-6 col-md-5 col-6 pe-0 ps-pix-5' : makeTableUIStates && makeTableUIStates[item.Id]?.IsShowNotifyButton}"
                  *ngIf="makeTableUIStates && makeTableUIStates[item.Id]?.IsReadyToServe">
                  <button class="btn btn-lg normal width-full mt-pix-10 pos-button" (click)="serveThisOrder(item.Id)"
                          [ngStyle]="{'background': serveButtonColor, 'color': serveButtonFontColor}">Serve</button>
                </div>
              </div>
            </div>
          </div>
        </ng-template>

      </div>
    </div>
    <div class="make-table-page__no-active-order" *ngIf="!makeTableOrders || !makeTableOrders.length">
      No active orders</div>
    <pos-order-bottom-clock-and-layout [currentLayout]="currentLayout" (changeLayout)="changeLayout($event)" *ngIf="!isMobileMode">
    </pos-order-bottom-clock-and-layout>
  </div>

  <div *ngIf="!isMobileMode && IsShowCookingPanel">
    <label class="btn-warning btn-updated p-pix-10 web-only bg-theme-separator" id="cookingButton"
      [ngStyle]="{'background-color': trimColor}" (click)="showCookingProduct()">Cooking</label>
  </div>

  <section>
    <a class="scroll-btn scroll-up bg-theme-primary" [ngClass]="{'hidden-visibility':isHiddenScrollTop || isMobileMode}"
      (click)="scrollUp()"></a>
  </section>

  <section>
    <a class="scroll-btn scroll-down bg-theme-primary" [ngClass]="{'hidden-visibility':isHiddenScrollBottom  || isMobileMode}"
      (click)="scrollDown()" address="true"></a>
  </section>

  <div class="maketable-box p-0" #cookingPanel id="cookingProducts" [ngStyle]="{'border-left': '2px solid ' + trimColor }"
    [hidden]="!(isOpenCookingPanel)">
    <div class="make-table-page__content__block mb-pix-5"
      *ngFor="let productGroup of makeTableCookingProductsByGroup | keyvalue">
      <div>
        <div class="text-center">
          <label>
            <span *ngIf="productGroup.key != 'null'" class="group-name">{{productGroup.key}}</span>
          </label>
        </div>
        <div
          (click)="cookingPanelItemStateChange(productGroupItem, {Product: productGroupItem.Product, Order: productGroupItem.Order})"
          *ngFor="let productGroupItem of productGroup.value" class="mb-pix-5">
          <div>
            <div class="p-1 pb-pix-2" [ngStyle]="{'background-color': productGroupItem.BgColor}" style="min-height: 26px;">
              <span class="ms-1 float-start"
                [ngStyle]="{'color':productGroupItem.MakeTableFgColor ? productGroupItem.MakeTableFgColor : productGroupItem.FontColor}">
                <span style="vertical-align: middle;" class="badge qty-circle make-table-page__content__block__qty me-1"
                  [ngStyle]="{'background-color': productGroupItem.QtyBgColor,'color': productGroupItem.QtyFontColor}">
                  {{productGroupItem.Product.Qty | productQty }}
                </span>
                <span class="bold">{{productGroupItem.Product.SizeName}}</span>
                <span> {{productGroupItem.Name}}</span>
              </span>
              <span class="float-end">
                <span class="float-start me-pix-5">
                  <span class="make-table-page__content__block__icon">
                    <pos-icon [icon]="icons.star"
                      *ngIf="productGroupItem.Product.MakeTableState == makeTableStates.PENDING && settingParam.IsShowMakeTablePendingState"
                      class="make-table-page__content__block__icon--i"
                      [ngStyle]="{'color': isStarColor ? '#D4AF37':'black'}"></pos-icon>
                    <pos-icon [icon]="icons.checkCircleMakeTable" class="make-table-page__content__block__icon--i font-15"
                      *ngIf="productGroupItem.Product.MakeTableState==makeTableStates.DONE"
                      [ngStyle]="{'color':productGroupItem.FontColor}"></pos-icon>
                    <pos-icon [icon]="icons.clock" class="make-table-page__content__block__icon--i font-15"
                      [ngStyle]="{'color':productGroupItem.FontColor}"
                      *ngIf="productGroupItem.Product.MakeTableState==makeTableStates.COOKING">
                    </pos-icon>
                    <pos-icon [icon]="icons.cogs" class="bold make-table-page__content__block__icon--i"
                      [ngStyle]="{'color':productGroupItem.FontColor}"
                      *ngIf="productGroupItem.Product.MakeTableState==makeTableStates.RETRIEVING_INGREDIENTS"></pos-icon>
                    <pos-icon [icon]="icons.magic" class="make-table-page__content__block__icon--i"
                      [ngStyle]="{'color':productGroupItem.FontColor}"
                      *ngIf="productGroupItem.Product.MakeTableState==makeTableStates.CRAFTING">
                    </pos-icon>
                    <pos-icon [icon]="icons.rocket" class="make-table-page__content__block__icon--i"
                      [ngStyle]="{'color':productGroupItem.FontColor}"
                      *ngIf="productGroupItem.Product.MakeTableState==makeTableStates.READY">
                    </pos-icon>
                    <pos-icon [icon]="icons.tasks" class="make-table-page__content__block__icon--i"
                      [ngStyle]="{'color': productGroupItem.FontColor}"
                      *ngIf="productGroupItem.Product.MakeTableState==makeTableStates.PARTIALLY_IN_PROGRESS">
                    </pos-icon>
                  </span>
                  <div *ngIf="productGroupItem.MakeTableText" class="float-end box__icon me-3">
                    <pos-icon [icon]="icons.clock" class="white-glyph box__icon--i"
                      *ngIf="productGroupItem.TimerState=='Cooking'">
                    </pos-icon>
                    <pos-icon [icon]="icons.check" class="green-glyph box__icon--i"
                      *ngIf="productGroupItem.TimerState=='Done'">
                    </pos-icon>
                    <span class="me-pix-25"
                      *ngIf="productGroupItem.TimerState!='Cooking' && productGroupItem.TimerState!='Done'"></span>
                  </div>
                </span>
                <span class="badge order-number-circle float-end">{{productGroupItem.Order.SurrogateOrderId}}</span>
              </span>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isShowFilterScreen">
  <pos-filtered-products [terminalId]="settingParam.TerminalId"  
  [isLoadOnPopup]="false"
  (productsFiltered)="productsFiltered($event)"></pos-filtered-products>
</div>
