<div id="KitchenPrinter">
  <div>
    <h3 class="setting-title">Print Settings</h3>
  </div>
  <div class="col-9 pos-title-content">
      <pos-row>
        <pos-column class="pt-pix-10">
          <pos-row colXs="4" colStyleClass="pe-0" class="p-0">
            <pos-column>
              <label><input id="printWholeOrder" name="chkSplitConfiguration" type="radio" (click)="setSplitConfiguration(kitchenPrinterSplitConfiguration.PrintWholeOrder)" [checked]='true' [value]="kitchenPrinterSplitConfiguration.PrintWholeOrder" [(ngModel)]="splitConfiguration" /> Print Whole Order</label>
            </pos-column>

            <pos-column>
              <label><input id="splitBySubaccount" name="chkSplitConfiguration" type="radio" (click)="setSplitConfiguration(kitchenPrinterSplitConfiguration.SplitBySubaccount)" [value]="kitchenPrinterSplitConfiguration.SplitBySubaccount" [(ngModel)]="splitConfiguration"/> Split by {{subaccountTerm?subaccountTerm:'Seat'}}</label>
            </pos-column>

            <pos-column>
              <label><input id="splitByProduct" name="chkSplitConfiguration" type="radio" (click)="setSplitConfiguration(kitchenPrinterSplitConfiguration.SplitByProduct)" [value]="kitchenPrinterSplitConfiguration.SplitByProduct" [(ngModel)]="splitConfiguration"/> Split by Product</label>
            </pos-column>
          </pos-row>
        </pos-column>
      </pos-row>
  </div>
</div>
