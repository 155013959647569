import { minus, hashtag, plusMessage } from '../icon/icons';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { ApplicationStateService } from '../../services/application-state.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { QuantityChangePopupComponent } from '../quantity-change-popup/quantity-change-popup.component';
import { NumpadOptions, UserDetails } from '../../interface';
import { DomainConstants, RuntimeConstants, Permissions, Levels, Messages } from '../../constants';
import { ModalService } from '../modal/modal.service';
import { AuthenticationService } from '../../auth/services';
import { StringUtils } from '../../string-utils';
@Component({
  selector: 'pos-quantity-change',
  templateUrl: './quantity-change.component.html',
  styleUrls: ['./quantity-change.component.scss']
})
export class QuantityChangeComponent {

  @Input() orderProduct: any;
  @Input() changeItemQty: any;
  @Input() groupId: number = null;
  userDetails: UserDetails;
  @Input() addQuantityToItem: any;
  @Input() isSmallScreen: boolean;
  @Input() paidAmount: number;
  @Output() changeComboItemQty: EventEmitter<any> = new EventEmitter<any>();
  public icons = {
    minus,
    plusMessage,
    hashtag
  };
  removePermission = {
    name: Permissions.OrderEntryRemoveItem,
    accessLevel: Levels.Access
  };
  permission = {
    name: Permissions.OrderEntryRemoveNotSentItem,
    accessLevel: Levels.Access
  };
  hasPermission: boolean;
  readonly ORDER_STATUS_PAID = RuntimeConstants.ORDER_STATUS_PAID;
  readonly PLUS_MINUS_BUTTONS = DomainConstants.QuantityChange.PLUS_MINUS_BUTTONS;
  readonly HASH_BUTTON = DomainConstants.QuantityChange.HASH_BUTTON;
  settingParam: any;
  @ViewChild('quantityChangePopup')
  public quantityChangePopup: ModalDirective;
  numpadOptions: NumpadOptions = {
    allowDecimal: false
  };
  constructor(applicationStateService: ApplicationStateService, private modalService: ModalService, private authenticationService: AuthenticationService) {
    this.settingParam = applicationStateService.settingParam;
    if (!this.settingParam.QuantityChange) {
      this.settingParam.QuantityChange = this.PLUS_MINUS_BUTTONS;
    }
    this.hasPermission = authenticationService.userHasPermission([{ "Name": this.permission.name, "Level": this.permission.accessLevel }], 'any');
    this.userDetails = applicationStateService.userDetails;
  }

  hasPermissionForChange(orderProduct) {
    if (orderProduct.StatusId != 0 || orderProduct.IsSentToKitchen || orderProduct.IsDirty) {
      return (this.authenticationService.userHasPermission([{ Name: this.removePermission.name, Level: this.removePermission.accessLevel }], 'any'));
    }
    else {
      return true;
    }
  }


  numpadOpen() {
    this.hasPermission = this.hasPermission && (this.hasPermissionForChange(this.orderProduct));
    const salesProductUnit = (this.orderProduct.SalesProductBusinessEntity && this.orderProduct.SalesProductBusinessEntity.SalesUnit ?
      this.orderProduct.SalesProductBusinessEntity.SalesUnit.Name : '');
    const allowDecrement = this.hasPermission;
    const numpadModalRef = this.modalService.show(QuantityChangePopupComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        value: this.orderProduct.Qty,
        allowDecrement: allowDecrement,
        decrementErrorMessage: StringUtils.format(Messages.OrderItemQuantityDecrementValidationErrorAfterPayment,
          { 
            'qty': ((!allowDecrement) ? this.orderProduct.Qty : (this.settingParam.AllowOrderingFractionalQty ? 0.01 : 1)),
            'productUnit': (salesProductUnit ? ' ' + salesProductUnit : '') 
          }),
        salesProductUnit: salesProductUnit
      }
    });
    numpadModalRef.close.subscribe(response => {
      if (response?.value) {
        this.orderProduct.OldQty = this.orderProduct.Qty;
        this.orderProduct.Qty = response.value;
        if (this.groupId)  {
          this.changeComboItemQty.emit({groupId: this.groupId, oldQty: Math.round(this.orderProduct.OldQty / (this.orderProduct.Increment ?? 1)),
            newQty: Number(this.orderProduct.Qty)});
        } else {
          this.addQuantityToItem(this.orderProduct);
        }
      }
    });
  }

  changeQty(orderProduct, isIncreaseItem: boolean) {
    if (this.groupId) {
      this.changeComboItemQty.emit({groupId: this.groupId, isIncreaseItem: isIncreaseItem});
    } else {
      this.changeItemQty(orderProduct, isIncreaseItem);
    }
  }
}
