<div class="m-0-auto p-pix-5">
    <div class="page-content">
        <div class="tab-content">
            <div class="tab-pane fade in active show">
                <div class="col-12 p-0">
                    <a class="close pe-pix-15" (click)="close()">×</a>
                    <div class="col-md-10 col-xs-9">
                        <h3 class="page-content__header">
                            <pos-icon class="black pe-pix-5" [icon]="icons.upload"></pos-icon> Release Queue Review
                        </h3>
                        <h4 *ngIf="manualExport?.Status === pendingStatus"><strong>Name: {{manualExport?.Name}}</strong></h4>
                        <h4 *ngIf="manualExport?.Status === pendingStatus"><strong>Generated On: {{ manualExport?.ScheduledOn | tark_date_time }}</strong></h4>
                        <h4 *ngIf="manualExport?.Status === pendingStatus"><strong>Send via: {{ manualExport?.SendVia }}</strong></h4>
                    </div>
                    <div class="col-12" *ngIf="manualExport?.Status === pendingStatus">
                        <ng-table #manualDataSubscriptionPreviewTable [search]="true" [sort]="true" [scrollable]="true"
                            [paginator]="true" [columns]="manualExportGridColumns" [scrollHeight] = "getHeight() + 'px'"
                            [data]="manualExportData" exportFilename="manual-data-export-preview">
                        </ng-table>
                    </div>
                    <div class="col-12 mb-pix-10 pt-pix-15" *ngIf="manualExport?.Id && manualExport?.Status !== pendingStatus">
                        <div class="alert alert-warning mb-0">
                            Export was {{manualExport?.Status.toLowerCase()}} at {{manualExport?.DateEdited | tark_date_time}}.
                        </div>
                    </div>
                    <div class="col-12 action-button-container page-footer">
                        <button class="btn btn-primary btn-lg btn-save pos-button"  *ngIf="manualExport?.Status === pendingStatus" (click)="releaseExport()" type="button">
                            <pos-icon [icon]="icons.upload"></pos-icon> Release
                        </button>
                        <button class="btn btn-danger btn-lg btn-save pos-button" *ngIf="manualExport?.Status === pendingStatus" (click)="ignoreExport()" type="button">
                            <pos-icon [icon]="icons.manualSubscriptionReject"></pos-icon> Ignore
                        </button>
                        <button class="btn btn-secondary btn-lg btn-cancel pos-button" (click)="close()"type="button">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<pos-print-table [hidden]="true"></pos-print-table>
