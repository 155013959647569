import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { DateColumn, GridColumn, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { OrderSummary } from '../../interface';
import { AlertsService, OrderService } from 'src/app/shared';
import { finalize } from 'rxjs';
import { Format } from '@tarktech/tark-ng-utils/table/format-type';

declare let $: any;

@Component({
  selector: 'pos-order-wise-refund',
  templateUrl: './order-wise-refund.component.html',
  styleUrls: ['./order-wise-refund.component.scss']
})
export class OrderWiseRefundComponent implements OnInit {

  @Input() orderId: number;
  @Output() closePopOver: EventEmitter<boolean> = new EventEmitter<boolean>();
  orderList: Array<OrderSummary> = [];
  orderColumns: Array<GridColumn> = [];
  popupHeight: number;
  showLoader = false;


  @ViewChild('paidAmountTemplate', { static: true }) private paidAmountTemplate: TemplateRef<any>;

  constructor(private orderService: OrderService, private alertService: AlertsService) { }

  ngOnInit(): void {
    this.resetHeight();
    this.resizeWindow();
    this.setOrderColumns();
    this.loadData();
  }

  loadData() {
    this.showLoader = true;
    this.orderService.getRefundOrderDetailsFromOrderId(this.orderId)
    .pipe(finalize(() => this.showLoader = false))
    .subscribe({
      next: (res: OrderSummary[]) => {
        this.orderList = res;
      },
      error: this.alertService.showApiError
    })
  }

  setOrderColumns() {

    const totalColumn = new TemplateColumn({
      HeaderText: 'Refund Amount',
      itemTemplate: this.paidAmountTemplate,
      Field: 'PaidAmount',
      TextAlign: TextAlign.Right,
      IsSortable: true
    });


    this.orderColumns = [
      new GridColumn({ HeaderText: 'Order Id', Field: 'Order', IsSortable: true }),
      new GridColumn({ HeaderText: 'Order Number', Field: 'SurrogateOrderId', IsSortable: true }),
      new DateColumn({ HeaderText: 'Date', Field: 'Date', IsSortable: true, Format: Format.DateTime }),
      totalColumn,
    ] as Array<GridColumn>;
  }

  resizeWindow() {
    $(window).resize(() => {
      this.resetHeight();
    });
  }

  resetHeight = () => {
    this.popupHeight = $(window).height() - 150;
    $('.order-summary').height(this.popupHeight - 30);
  }

  close() {
    this.closePopOver.emit();
  }

}
