import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule as NgFormsModule } from '@angular/forms';
import { InputComponent } from './input/input.component';

@NgModule({
  imports: [
    CommonModule,
    NgFormsModule
  ],
  declarations: [InputComponent],
  exports: [InputComponent]
})
export class FormsModule { }
