import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { inventoryprepfixed, inventoryprepvariable } from 'src/app/shared/components/icon/icons';

@Component({
  selector: 'pos-inventory-prep',
  templateUrl: './inventory-prep.component.html',
  styleUrls: ['./inventory-prep.component.scss']
})
export class InventoryPrepComponent implements OnInit {

  icons = {
    inventoryprepfixed, inventoryprepvariable
  };
  selectedTab = 'FixedIngredientRecipe';

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  cancel() {
    this.router.navigate(['manage/console']);
  }

}
