<div class="m-0-auto width-70">
    <pos-edit-wrapper heading="<img src='images/admin/inventory-loss.png' class='mt-pix-n4' height='22px' /> Inventory Loss"
        (close)="cancel()" [isPopup]="false">
        <div class="col-12 pos-page-body border-top pt-pix-5">
            <pos-row>
                <pos-column>
                    <pos-form-field label="Product Type" inputStyle="col-12">
                        <div class="btn-group">
                            <label class="btn btn-radio"
                                [ngClass]="selectedProductType === productType.SalesProduct ? 'active btn-primary': 'btn-secondary'">
                                <input [(ngModel)]="selectedProductType" type="radio" [value]="productType.SalesProduct"
                                    (click)="reset(productType.SalesProduct)" name="salesProduct"> Sales Product
                            </label>
                            <label class="btn btn-radio"
                                [ngClass]="selectedProductType === productType.InventoryProduct ? 'active btn-primary': 'btn-secondary'">
                                <input [(ngModel)]="selectedProductType" type="radio"
                                    [value]="productType.InventoryProduct" (click)="reset(productType.InventoryProduct)"
                                    name="inventoryProduct">
                                Inventory
                                Product
                            </label>
                        </div>
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <form (ngSubmit)="saveInventoryLoss(inventoryLossForm.valid)" #inventoryLossForm="ngForm"
            [posValidate]="validateInventoryLoss">
            <div class="pos-page-body col-xl-8 col-md-10 col-12">
                <pos-row>
                    <pos-column *ngIf="selectedProductType === productType.SalesProduct">
                        <pos-form-field label="Sales Product"
                            [validationMessages]="{required: 'Please select sales product.'}">
                            <ng-select posInput [items]="salesProducts" bindLabel="Name" bindValue="id"
                                name="salesProduct" [(ngModel)]="inventoryLoss.SalesProductId"
                                placeholder="Select sales product" [required]="true" #salesProduct="ngModel"
                                (change)="productSelected()">
                            </ng-select>
                        </pos-form-field>
                    </pos-column>
                    <pos-column *ngIf="selectedProductType === productType.InventoryProduct">
                        <pos-form-field label="Inventory Product"
                            [validationMessages]="{required: 'Please select inventory product.'}">
                            <ng-select posInput [items]="inventoryProducts" bindLabel="Name" bindValue="id"
                                name="inventoryProduct" (change)="productSelected()"
                                [(ngModel)]="inventoryLoss.InventoryProductId" placeholder="Select inventory product"
                                [required]="true" #inventoryProduct="ngModel">
                            </ng-select>
                        </pos-form-field>
                    </pos-column>
                    <pos-column *ngIf="selectedProductType === productType.SalesProduct">
                        <pos-form-field label="Size" [validationMessages]="{required: 'Please select size.'}">
                            <ng-select posInput [items]="salesSizes" bindLabel="Name" bindValue="SizeId" class="col-200"
                                name="salesSize" [(ngModel)]="inventoryLoss.SizeId" placeholder="Select sales size"
                                [required]="true" #salesSize="ngModel">
                            </ng-select>
                        </pos-form-field>
                    </pos-column>
                    <pos-column>
                        <pos-form-field label="Qty" inputStyle="row mx-0"
                            [validationMessages]="{required: 'Please enter qty.', min: 'Quantity must be greater than zero.', invalidQty: 'Qty should be less than available qty ' + calculatedQty + '.'}">
                            <input posInput class="form-control col-120 col-3" id="textQty" type="number" min="0.0001"
                                name="textQty" [(ngModel)]="inventoryLoss.QtyLoss" posDecimal [required]="true"
                                #qty="ngModel" />
                            <label class="col-4 edit-form__section__label ps-pix-10">{{ unitName ? (unitName + '(s)'): ''
                                }}</label>
                        </pos-form-field>
                    </pos-column>
                    <pos-column>
                        <pos-form-field label="Note">
                            <textarea posInput class="form-control" cols="50" id="txtNote" name="txtNote"
                                [(ngModel)]="inventoryLoss.Note" rows="5"></textarea>
                        </pos-form-field>
                    </pos-column>
                    <pos-column>
                        <pos-form-field label="Reason" [validationMessages]="{required: 'Please select loss reason.'}">
                            <ng-select posInput [items]="productLossReasons" bindLabel="Name" bindValue="id"
                                name="lossReason" [(ngModel)]="inventoryLoss.LossReasonId" class="col-200"
                                placeholder="Select reason" [required]="true" #lossReason="ngModel">
                            </ng-select>
                        </pos-form-field>
                    </pos-column>
                </pos-row>
            </div>
            <div class="col-12 action-button-container page-footer">
                <button class="btn-lg btn-primary btn btn-save pos-button" scrollup type="submit">Save</button>
                <button class="btn-secondary btn-lg btn btn-cancel pos-button" (click)="cancel()" scrollup
                    type="button">
                    Close
                </button>
            </div>
        </form>
    </pos-edit-wrapper>
</div>
