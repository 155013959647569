<pos-list-wrapper [headingIcon]="icons.sms" heading="SMS Templates" (close)="close()">
  <div class="row">
    <div class="col-md-12 col-sm-12">
      <ng-table id="smsTemplates" [sort]="true" [columns]="smsTemplateColumns" [data]="smsTemplates" #templatesGrid>
      </ng-table>
      <ng-template let-data="rowData" #operationTemplate>
        <span (click)="editItem(data.Id)" class="text-center">
          <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
        </span>
      </ng-template>
    </div>
  </div>
</pos-list-wrapper>
