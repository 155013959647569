<div class="total-block">
    <div class="float-start width-full">
        <span class="total" *ngIf="totalDiscount">{{ orderData.DiscountAmount | tark_currency }}
        </span>
        <span class="float-right" *ngIf="totalDiscount">Discount</span>
    </div>
    <div class="float-start width-full">
        <span class="total" *ngIf="totalMarkup">{{ orderData.MarkupAmount | tark_currency }}
        </span>
        <span class="float-right" *ngIf="totalMarkup">Markup</span>
    </div>
    <div class="float-start width-full">
        <span class="total">{{ orderData.SubTotal | tark_currency}}</span>
        <span class="float-right">Subtotal</span>
    </div>
    <div class="float-start width-full">
        <span class="total">{{ orderData.TaxTotal | tark_currency}}</span>
        <span class="float-right">Tax</span>
    </div>
    <div class="float-start width-full">
        <span
            class="total grant-total">{{ orderData.GrandTotal | tark_currency }}</span>
        <span class="grant-total">Total </span>
    </div>
    <div class="float-start width-full" *ngIf="refundedOrderAmount">
        <span class="total">{{refundedOrderAmount | tark_currency}}</span>
        <span class="float-right">Refund Amount</span>
    </div>
</div>
