import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { filter, find, forEach } from 'lodash';
import { Observable, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LightingService } from 'src/app/configurator';
import { AlertsService, ApplicationStateService, ColorUtilityService, ICloseable, MathsUtilityService, Messages, ModalBackdropService, ModalService, SettingParam, SpinnerService, UserDetails } from 'src/app/shared';
import { ActiveInventory } from '../../interfaces/active-inventory';
import { QuickSearchService } from '../../services/quick-search.service';
import { quickSearchDashboardList } from 'src/app/shared/components/icon';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { InventoryLogStockSalesProduct } from 'src/app/shared/interface/inventory-log-stock-sales-product';
import { InventoryBinLightModel } from 'src/app/shared/interface/inventory-bin-light-model';
import { InvProduct } from 'src/app/shared/interface/inventory-product';
import { InventoryProductService } from 'src/app/shared/services/inventory-product.service';
import { InventoryBinLightService } from 'src/app/shared/services/inventory-bin-light.service';
import { InventoryProductLabelPrintRequest } from 'src/app/shared/interface/inventory-product-label-print-request';
import { InventoryLogService } from 'src/app/shared/services/inventory-log.service';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { NgSelectComponent } from '@ng-select/ng-select';
import { InventoryProductLogModalComponent } from 'src/app/information-management/inventory-products/components/inventory-product-log-modal';
import { InventoryOffSiteInventoryProduct, InventoryOffsiteInventoryProductService } from 'src/app/information-management';
import { GridColumn, TextAlign } from '@tarktech/tark-ng-utils';
declare let $: any;
@Component({
  selector: 'pos-quick-search',
  templateUrl: './quick-search.component.html',
  styleUrls: ['./quick-search.component.scss']
})
export class QuickSearchComponent implements OnInit, ICloseable {

  userDetails: UserDetails;
  settingParam: SettingParam;
  terminalName: string;
  terminalId: number;
  productList: Array<ActiveInventory> = [];
  inventoryPrimaryBinLights: Array<InventoryBinLightModel> = [];
  inventorySecondaryBinLights: Array<InventoryBinLightModel> = [];
  terminalDetails: object = {};
  inventoryProductId: number;
  inventoryProductDetails: InvProduct;
  defaultBGColor: string = '#337ab7';
  icons = {
    quickSearchDashboardList
  };
  logInventoryPopupHeader: string = '';
  inventoryProductForLog: any;
  salesInventoryProducts: Array<InventoryLogStockSalesProduct> = [];
  close: EventEmitter<any> = new EventEmitter();
  inventoryLogModalRef: BsModalRef;
  salesProductStockWarningModelRef: BsModalRef;
  offsiteInventoryProducts: Array<InventoryOffSiteInventoryProduct> = [];
  offsiteInventoryProductColumns: Array<GridColumn> = [];
  @ViewChild('salesProductStockWarning') private salesProductStockWarning: any;
  @ViewChild('inventoryProductQuickSearch') inventoryProductQuickSearch: NgSelectComponent;

  constructor(private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private applicationStateService: ApplicationStateService,
    private quickSearchService: QuickSearchService,
    private lightingService: LightingService,
    private inventoryProductService: InventoryProductService,
    private inventoryOffSiteInventoryProductService: InventoryOffsiteInventoryProductService,
    private inventoryBinLightService: InventoryBinLightService,
    private colorConversionService: ColorUtilityService,
    private bsModalService: BsModalService,
    private modalService: ModalService,
    private inventoryLogService: InventoryLogService,
    private mathsUtilityService: MathsUtilityService, private modalBackdropService: ModalBackdropService) { }


  ngOnInit(): void {
    this.setDefaults();
    this.getAllActiveInventoryProducts();
  }

  setDefaults = () => {
    this.userDetails = this.applicationStateService.userDetails;
    this.settingParam = this.applicationStateService.settingParam;
    this.terminalId = this.applicationStateService.terminalId;
    this.terminalName = this.applicationStateService.terminalName;
    this.terminalDetails = {
      TerminalId: this.applicationStateService.terminalId,
      TerminalName: this.applicationStateService.terminalName
    }
    this.offsiteInventoryProductColumns = [
      new GridColumn({ HeaderText: 'Location', Field: 'InventoryOffsiteLocation.Name', Width: '80%' }),
      new GridColumn({ HeaderText: 'Current Qty', Field: 'CalculatedQty', Width: '20%', TextAlign: TextAlign.Right })
    ];
  }

  getAllActiveInventoryProducts = () => {
    this.spinnerService.show();
    this.quickSearchService.getAllActiveInventoryProducts('undefined')
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: Array<ActiveInventory>) => {
          this.productList = filter(res, (item) => item.id > 0);
          this.focusOnQuickSearch();
        }, error: this.alertService.showApiError
      });
  }

  getInventoryDetails = () => {
    this.spinnerService.show();
    const observables: Array<Observable<any>> = [];
    observables.push(this.inventoryProductService.getInventoryProduct(this.inventoryProductId))
    observables.push(this.inventoryOffSiteInventoryProductService.getOffsiteInventoryByInventoryProductId(this.inventoryProductId))
    forkJoin(observables)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: ([invProduct, offsiteInventoryProducts]: [InvProduct, Array<InventoryOffSiteInventoryProduct>]) => {
          this.inventoryProductDetails = invProduct;
          this.offsiteInventoryProducts = offsiteInventoryProducts;
          this.getPrimaryBinLights();
          this.getSecondaryBinLights();
        }, error: this.alertService.showApiError
      });
  }

  getPrimaryBinLights = () => {
    if (this.inventoryProductDetails.PrimaryBinId) {
      this.spinnerService.show();
      this.inventoryBinLightService.getinventoryBinLights(this.inventoryProductDetails.PrimaryBinId)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res: Array<InventoryBinLightModel>) => {
            this.inventoryPrimaryBinLights = res ? res : [];
          }, error: this.alertService.showApiError
        });
    }
  }

  getSecondaryBinLights = () => {
    if (this.inventoryProductDetails.SecondaryBinId) {
      this.spinnerService.show();
      this.inventoryBinLightService.getinventoryBinLights(this.inventoryProductDetails.SecondaryBinId)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res: Array<InventoryBinLightModel>) => {
            this.inventorySecondaryBinLights = res ? res : [];
          }, error: this.alertService.showApiError
        });
    }
  }

  labelPrint = () => {
    if (this.inventoryProductDetails.id) {
      this.spinnerService.show();
      const inventoryLabelPrintRequest = this.prepareLabelPrintRequest();
      this.inventoryProductService.labelPrint(inventoryLabelPrintRequest)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res) => {
            this.alertService.renderSuccessMessage(Messages.LabelPrintRequestSendSuccess);
          }, error: this.alertService.showApiError
        });
    }
  }

  prepareLabelPrintRequest = (): InventoryProductLabelPrintRequest => {
    const inventoryLabelPrintRequest: InventoryProductLabelPrintRequest = {
      ProductName: this.inventoryProductDetails.name,
      UnitQty: this.inventoryProductDetails.unit_qty,
      UnitName: this.inventoryProductDetails.UnitName,
      MaxPortionsText: this.inventoryProductDetails.MaxPortionsText,
      MeasureByInstructions: this.inventoryProductDetails.MeasureByInstructions,
      NumberOfLabels: 1,
      Parameters: this.applicationStateService.settingParam,
      SubcategoryId: this.inventoryProductDetails.subcategory_id,
      PrimaryZoneCode: this.inventoryProductDetails.PrimaryZoneCode,
      PrimaryBinName: this.inventoryProductDetails.PrimaryBinName,
      SecondaryZoneCode: this.inventoryProductDetails.SecondaryZoneCode,
      SecondaryBinName: this.inventoryProductDetails.SecondaryBinName

    }
    return inventoryLabelPrintRequest;
  }

  triggerFlashLightOfPrimaryBin = () => {
    this.triggerFlashLights(this.inventoryPrimaryBinLights);
  }

  triggerFlashLightOfSecondaryBin = () => {
    this.triggerFlashLights(this.inventorySecondaryBinLights);
  }

  triggerFlashLights = (binLightList) => {
    this.spinnerService.show();
    const binLightObservables = [];
    forEach(binLightList, (light) => {
      binLightObservables.push(this.lightingService.triggerFlash(light.DeviceId, light.LightAddress, this.terminalDetails));
    });
    forkJoin(binLightObservables)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: responses => {
          this.alertService.renderSuccessMessage(Messages.RequestSendSuccess);
        }, error: this.alertService.showApiError
      });
  }

  getFontColor = (bgColor) => this.colorConversionService.getContrastColor(bgColor);

  onCancel = () => {
    this.close.emit();
  }

  getInventoryForLogging = () => {
    const productVendor = {
      Id: this.inventoryProductDetails.vendor_id,
      Type: 'Vendor'
    };
    this.spinnerService.show();
    this.inventoryLogService.getInventoryLogging(productVendor)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.getInventoryForLoggingCompleted(res);
        }, error: this.alertService.showApiError
      });
  }

  getInventoryForLoggingCompleted = (res) => {
    if (res) {
      this.inventoryProductForLog = find(res, (item) => item.Id == this.inventoryProductDetails.id);
      if (this.inventoryProductForLog) {
        if (this.inventoryProductForLog.Category) {
          this.logInventoryPopupHeader =
            this.inventoryProductForLog.SubCategory ? this.inventoryProductForLog.Category +
              ' - ' + this.inventoryProductForLog.SubCategory :
              this.inventoryProductForLog.Category;
        } else {
          this.logInventoryPopupHeader = this.inventoryProductForLog.Name;
        }

        const txtQtyId = '#txtQty' + this.inventoryProductForLog?.Id;
        this.showToolTip(txtQtyId, false);
        this.openInventoryLogModel();
      }
    }
  }

  showToolTip = (controlId: string, hasError: boolean) => {
    if (hasError) {
      $(controlId).parent().addClass('has-error');
      $(controlId).focus();
    }
  }

  openDashboard = () => {

  }

  openInventoryLogModel = () => {
    const modalRef = this.modalService.getModalWrapper(InventoryProductLogModalComponent);

    const modal = modalRef.show({
      'backdrop': 'static',
      'class': 'vertical-center',
      keyboard: false,
      animated: false,
      initialState: {
        logInventoryPopupHeader: this.logInventoryPopupHeader,
        inventoryProductForLog: this.inventoryProductForLog
      }
    });
    modal.close.subscribe((res) => {
      if (res && res.reload) {
        this.inventoryProductForLog = res.inventoryProductForLog;
        this.updateInventoryLog();
      }
    })
  }

  updateInventoryLog = () => {
    if (this.inventoryProductForLog &&
      (this.inventoryProductForLog.UnitQtyIncrement &&
        (this.mathsUtilityService.floatSafeModulus(parseFloat(this.inventoryProductForLog.Qty),
          parseFloat(this.inventoryProductForLog.UnitQtyIncrement)) !== 0) || this.inventoryProductForLog.Qty < 0)) {
      const txtQtyId = '#txtQty' + this.inventoryProductForLog?.Id;
      this.showToolTip(txtQtyId, true);
    } else {
      if (this.inventoryProductForLog) {
        this.getInventoryLogStockSalesProducts();
      }
    }
  }

  getInventoryLogStockSalesProducts = () => {
    const products = [{
      InventoryProductId: this.inventoryProductForLog.Id,
      Qty: this.inventoryProductForLog.Qty
    }];
    this.spinnerService.show();
    this.inventoryProductService.getInventoryLogStockSalesProducts(products)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: Array<InventoryLogStockSalesProduct>) => {
          this.salesInventoryProducts = res ? res : [];
          if (res && res.length && res.length >= this.settingParam.InventoryLogStockWarningMinimum) {
            this.openWarningModel();
          } else {
            this.updateInventoryQty();
          }
        }, error: this.alertService.showApiError
      });
  }

  closeInventoryLogModel = () => {
    if (this.inventoryLogModalRef) {
      this.inventoryLogModalRef.hide();
      this.modalBackdropService.removeBackdrop();
    }
  }

  updateInventoryQty = () => {
    this.closeWarningModel();
    this.inventoryProductForLog.IsUpdated = true;
    const inventoryFinalLog = [{
      CategoryName: this.inventoryProductForLog.Category + ' - ' + this.inventoryProductForLog.SubCategory,
      CategoryList: [this.inventoryProductForLog],
      IsChecked: true
    }];
    const inventoryLogModel = {
      InventoryList: inventoryFinalLog,
      UserName: this.userDetails.username,
      UserId: this.userDetails.id,
      FilterName: 'Vendor',
      FilterValue: this.inventoryProductDetails.VendorName,
      VendorName: 'true'
    };
    this.spinnerService.show();
    this.inventoryLogService.updateInventoryLog(inventoryLogModel)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.alertService.renderSuccessMessage(StringUtils.format(Messages.InventoryLogSuccess,
            { 'productName': this.inventoryProductDetails.name }));
          this.getInventoryDetails();
          this.closeInventoryLogModel();
        }, error: this.alertService.showApiError
      });
  }

  openWarningModel = () => {
    this.salesProductStockWarningModelRef = this.bsModalService.show(this.salesProductStockWarning, {
      'backdrop': 'static',
      'class': 'vertical-center'
    });
    this.modalBackdropService.addBackDrop();
  }

  closeWarningModel = () => {
    if (this.salesProductStockWarningModelRef) {
      this.salesProductStockWarningModelRef.hide();
      this.modalBackdropService.removeBackdrop();
    }
  }

  focusOnQuickSearch() {
    this.inventoryProductQuickSearch.searchInput.nativeElement.focus();
  }
}
