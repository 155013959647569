<div class="m-0-auto width-98">
    <pos-edit-wrapper heading="Batch Mark as Ordered" (close)="onCancel()" [headingIcon]="icons.checkCircleGrey"
        [isPopup]="false">
        <div class="col-12 pos-page-body border-top pt-pix-10">
            <div class="vendor-ddl">
                <pos-column>
                    <pos-form-field label="Vendor" [appendPaddingTop]="false">
                        <ng-select posInput [items]="vendors" bindLabel="name" bindValue="name" name="vendor"
                            [(ngModel)]="selectedVendor" placeholder="Select vendor" (change)="vendorSelected()">
                        </ng-select>
                    </pos-form-field>
                </pos-column>
            </div>
            <div class="col-12 pt-pix-15 mt-pix-40 mt-pix-md-0">
                <ng-table [columns]="batchInventoryProductsColumns" [data]="batchInventoryProducts" [paginator]="true"
                    [search]="true" [scrollable]="true" #batchInventoryProductTable [scrollHeight]="height + 'px'"
                    (onCustomFilter)="onFilterChanged()">
                </ng-table>
            </div>
        </div>
        <ng-template #chkAllInventoryProducts>
            <div class="mt-pix-8">
                <p-checkbox [(ngModel)]="checkAll" binary="true" (onChange)="checkAllProducts()" />
            </div>
        </ng-template>
        <ng-template let-data="rowData" let-index="rowIndex" #chkInventoryProducts>
            <div class="mt-pix-8">
                <p-checkbox [(ngModel)]="data.IsOnOrder" binary="true" name="chk_inventoryProduct_{{index}}"
                    (onChange)="isOnOrderChanged(data.IsOnOrder)"></p-checkbox>
            </div>
        </ng-template>
        <div class="col-12 action-button-container page-footer">
            <button class="btn btn-lg btn-primary btn-save pos-button" (click)="markProductsAsOrdered()" type="button">Mark as
                Ordered <span class="badge font-16 px-pix-8"
                    [ngStyle]="{'color': 'white', 'background-color': 'rgb(205 69 62)' }">{{selectedProduct}}</span>
            </button>
            <button class="btn-secondary btn-lg btn btn-cancel pos-button" (click)="onCancel()" type="button">Close
            </button>
        </div>
    </pos-edit-wrapper>
</div>
