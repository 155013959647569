import { Component, OnInit, Input } from '@angular/core';
import { DefaultSalesProduct } from '../../interface/default-sales-product';
import { AlertsService, SpinnerService, Messages, ModalComponent, ModalService, ConfirmDeleteModalComponent } from 'src/app/shared';
import { SalesProductsService } from '../../services';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SalesProductDefaultButton } from '../../interface/sales-product-default-button';
import * as _ from 'lodash';
import { GridColumn, DeleteColumn } from '@tarktech/tark-ng-utils';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { normalPlus } from 'src/app/shared/components/icon';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';


@Component({
  selector: 'pos-sales-product-default-button',
  templateUrl: './sales-product-default-button.component.html',
  styleUrls: ['./sales-product-default-button.component.scss']
})
export class SalesProductDefaultButtonComponent extends ModalComponent implements OnInit {

  @Input('productId') productId: number;
  @Input('isPopup') isPopup: boolean = false;
  @Input('productName') productName: string = '';
  defaultProductId: number = null;
  salesProductList: Array<DefaultSalesProduct> = [];
  defaultSelectedButtons: Array<SalesProductDefaultButton> = [];
  deleteButtonId: number;
  defaultButtonColumn: Array<GridColumn> = [];
  icons = {
    normalPlus
  }
  props = {
    labelClass: '',
    controlBoxClass: 'col-lg-12 col-md-12 col-sm-12 col-xs-12'
  }

  constructor(private alertService: AlertsService,
    private spinnerService: SpinnerService,
    public modalRef: BsModalRef,
    private salesProductService: SalesProductsService,
    private modalService: ModalService) {
    super(modalRef);
  }

  ngOnInit() {
    this.loadDefaultButtonData();
    this.configureGridColumns();
    this.deleteButtonId = null;
  }

  loadDefaultButtonData() {
    this.spinnerService.show();
    const defaultButtonObservable = [];
    defaultButtonObservable.push(this.salesProductService.getProductList());
    defaultButtonObservable.push(this.salesProductService.getDefaultButtons(this.productId));
    forkJoin(defaultButtonObservable)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (responses: any) => {
          if (responses) {
            this.salesProductList = responses[0] ? responses[0] : [];
            this.defaultSelectedButtons = responses[1] ? responses[1] : [];
          }
        }, error: this.alertService.showApiError
      });
  }

  addToDefaultProduct() {
    if (this.defaultProductId) {
      let status = true;
      _.forEach(this.defaultSelectedButtons, (product) => {
        if (product.selected_product_id === this.defaultProductId) {
          status = false;
          this.alertService.renderErrorMessage(Messages.DuplicateDefaultButtonError);
        }
      });
      if (status) {
        const defaultButtonDetails = {
          ProductId: this.productId ? this.productId : -1,
          DefaultProductId: this.defaultProductId
        };
        this.spinnerService.show();
        this.salesProductService.addToDefaultProduct(this.productId, defaultButtonDetails)
          .pipe(finalize(() => {
            this.spinnerService.hide();
          }))
          .subscribe({
            next: res => {
              this.defaultProductId = null;
              this.loadDefaultButtonData();
            }, error: this.alertService.showApiError
          });
      }
    }
  }

  removeDefaultButton(product: SalesProductDefaultButton) {
    this.deleteButtonId = product.id;
    const modelRef = this.modalService.show(ConfirmDeleteModalComponent ,{
      animated: false,
      initialState: {
        message: StringUtils.format(Messages.ConfirmDeleteDefaultButton, { 'buttonName': product.name })
      }
    });
    modelRef.close.subscribe((res)=>{
      if(res.shouldDelete){
        this.onConfirmAcknowledge();
      }
    })
  }

  onConfirmAcknowledge() {
    this.spinnerService.show();
    this.salesProductService.removeFromDefaultProduct(this.productId, this.deleteButtonId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          this.defaultProductId = null;
          this.deleteButtonId = null;
          this.loadDefaultButtonData();
        }, error: this.alertService.showApiError
      });
  }

  configureGridColumns() {
    const deleteColumn = new DeleteColumn({
      Clicked: (data) => {
        this.removeDefaultButton(data);
      },
      Width: '40px'
    });

    this.defaultButtonColumn = [
      new GridColumn({ HeaderText: 'Default Buttons', Field: 'name', IsSortable: false }),
      deleteColumn
    ] as Array<GridColumn>;
  }

  onCancel() {
    this.hide({});
  }
}
