import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RuntimeConstants } from 'src/app/shared/constants';
import { Observable } from 'rxjs';
import { SettingParam } from 'src/app/shared';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  databaseApiPath = `${RuntimeConstants.API_PATH}database/`;

  constructor(private httpClient: HttpClient) { }

  getDatabaseInfo(terminalId: number): Observable<SettingParam> {
    return this.httpClient.get<SettingParam>(`${this.databaseApiPath}${terminalId}`);
  }

  saveDatabase(params: SettingParam): Observable<SettingParam> {
    return this.httpClient.post<SettingParam>(`${this.databaseApiPath}`, params);
  }

}
