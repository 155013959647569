import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs';
import { AlertsService } from './alerts.service';
import { OrderService } from './order.service';
import { SpinnerService } from '../components/spinner/spinner.service';
import { ApplicationStateService } from './application-state.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  constructor(private orderService: OrderService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private router: Router,
    private applicationStateService: ApplicationStateService
  ) { }

  logout(isForceLogout: boolean, isRequireRefresh = false) {
    let logoutDetails: any = new Object();
    if (isForceLogout) {
      logoutDetails.EventMessage = 'Terminal Auto Logout';
      logoutDetails.EventDetails = 'Auto Logout';
    } else {
      logoutDetails.EventMessage = 'Terminal Sign Out';
      logoutDetails.EventDetails = 'Sign Out';
    }
    logoutDetails.userId = this.applicationStateService.userId;
    logoutDetails.TerminalId = this.applicationStateService.terminalId;
    this.spinnerService.show();
    this.orderService.logoutTheSession(logoutDetails).pipe(finalize(() => {
      this.spinnerService.hide();
    }))
      .subscribe({
        next: (res) => {
          if (isRequireRefresh) {
            this.removeSettings();
          }
          this.router.navigate(['login']);
          if (isRequireRefresh) {
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
        }, error: this.alertService.showApiError
      });
  }

  removeSettings() {
    this.applicationStateService.gotoLoginScreen = false;
    this.applicationStateService.userDetails = null;
    this.applicationStateService.username = null;
    this.applicationStateService.gotoTerminal = false;
    this.applicationStateService.cartOrder = null;
    this.applicationStateService.parentId = 0;
    this.applicationStateService.unservedOrdersData = [];
    this.applicationStateService.productNavigationDetails = [];
    this.applicationStateService.defaultProductSelected = [];
    this.applicationStateService.screenBehaviorData = [];
    this.applicationStateService.inventoryOrderReceivingProductList = [];
    this.applicationStateService.inventoryOrderReceivingNewBarcodeList = [];
    this.applicationStateService.signInAsDiffUserTerminalDetail = null;
    this.applicationStateService.isImpersonateUser = false;
    this.applicationStateService.impersonateUserDetails = null;
    this.applicationStateService.sessionDesignMode = false;
    this.applicationStateService.userCode = null;
  }
}
