<div class="col-12 p-0 screen-scroll" [ngStyle]="{'height': height + 'px'}">
    <div class="tab width-50 m-auto mt-pix-20" *ngFor="let service of services">
        <div class="form-section">
            <div class="head-section">
                <h3>Service Details</h3>
            </div>
            <div class="pt-pix-15">
                <div class="row">
                    <div class="col-md-2 col-4 pt-pix-5 bold">Name:</div>
                    <div class="col-md-10 col-8 pt-pix-5">{{service.Name}}</div>
                </div>
                <div class="row" *ngIf="service.Identifier">
                    <div class="col-md-2 col-4 pt-pix-5 bold">Identifier:</div>
                    <div class="col-md-10 col-8 pt-pix-5">{{service.Identifier}}</div>
                </div>
                <div class="row">
                    <div class="col-md-2 col-4 pt-pix-5 bold">Status:</div>
                    <div class="col-md-10 col-8 pt-pix-5 bold"
                        [ngClass]="{ 'text-success': service.Status == 'Up', 'text-danger': service.Status == 'Down' }">
                        {{service.Status}}</div>
                </div>
            </div>
        </div>
    </div>
</div>
