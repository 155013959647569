import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApplicationStateService, DomainConstants, RabbitMQService, TerminalStatusCheckService } from 'src/app/shared';
import { DigitalSignageService } from './services/digital-signage.service';
import * as _ from 'lodash';
import { RuntimeConstants } from 'src/app/shared';
import { SlideshowMediaRotator } from './interface/slideshow-media-rotator';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pos-digital-signage',
  templateUrl: './digital-signage.component.html',
})
export class DigitalSignageComponent implements OnInit, OnDestroy {

  headerLinks = {
    tasks: false,
    home: false,
    warning: false,
    changeTerminal: false,
    mappedTerminals: false,
    admin: false,
    time: false,
    signIn: false,
    activeOrders: false,
    userMenu: false
  };
  homeState = 'digital-signage';

  isSlideShow = true;
  showHeader = !this.isSlideShow;
  slideShowData: Array<SlideshowMediaRotator> = [];

  userClockOutSubscription: Subscription;
  refreshTerminalRequestSubscription: Subscription;
  rabbitMQTerminalStatusCheckSubscription: Subscription;

  constructor(private applicationStateService: ApplicationStateService,
    private digitalSignageService: DigitalSignageService,
    private rabbitMQService: RabbitMQService,
    private terminalStatusChecker: TerminalStatusCheckService,
    private router: Router) { }

  ngOnInit() {
    this.subscribeToTerminalLogoutMessage();
    this.subscribeTerminalRefreshMessage();
    this.loadSlideshow();
  }

  ngOnDestroy(): void {
    this.userClockOutSubscription?.unsubscribe();
    this.rabbitMQTerminalStatusCheckSubscription?.unsubscribe();
  }

  subscribeToTerminalLogoutMessage() {
    if (this.showHeader) return;
    this.userClockOutSubscription = this.rabbitMQService.subscribeToUserClockOutMessage$()
      .subscribe({
        next: (message: any) => {
          if (message.Payload.UserId === this.applicationStateService.userId) {
            this.router.navigate(['login']);
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
        }, error: () => {
          console.log('error while connecting to RabbitMQ.');
        }
      });
  }

  subscribeTerminalRefreshMessage() {
    this.refreshTerminalRequestSubscription = this.rabbitMQService.subscribeToRefreshTerminal$(this.applicationStateService.terminalId)
    .subscribe({
      next: () => {
        window.location.reload();
      }
    })
  }
  
  subscribeToTerminalStatusCheck() {
    this.rabbitMQTerminalStatusCheckSubscription = this.terminalStatusChecker.subscribeTerminalStatusCheck(this.applicationStateService.terminalId)
    .subscribe({
      next: (res) => this.terminalStatusChecker.publishTerminalStatusResponse(res)
    })
  }

  loadSlideshow() {
    this.digitalSignageService.getTerminalSlideshowDetails(this.applicationStateService.terminalId)
      .subscribe({
        next: (response) => {
          _.forEach(response, (item) => {
            item.ImagePath = RuntimeConstants.IMAGE_BASE_PATH + '/' +
              DomainConstants.ImageDirectories.slideImages + '/' + item.ImagePath;
            const slideshow = {
              source: item.ImagePath,
              duration: item.HoldSeconds * 1000,
              id: item.Id
            };
            this.slideShowData.push(slideshow);
          });
        }
      });
  }

}
