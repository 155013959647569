import { Component, OnInit, ViewChild } from '@angular/core';
import { InventoryCategory } from '../../interface';
import { Permissions, Levels, Messages } from '../../../../shared/constants/';
import { ConfirmDeleteComponent } from 'src/app/shared/components/confirm-delete/confirm-delete.component';
import { EditColumn, LinkColumn, DeleteColumn, GridColumn } from '@tarktech/tark-ng-utils';
import { AuthenticationService } from 'src/app/shared/auth/services/authentication.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { InventoryCategoryService } from '../../service/inventory-category.service';
import { finalize } from 'rxjs/operators';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { InventoryCategoryEditComponent } from '../inventory-category-edit';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import { BaseGridComponent, ApplicationStateService, inventoryCategoriesUnitList } from 'src/app/shared';
import { ActivatedRoute, Router } from '@angular/router';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';

@Component({
    selector: 'pos-inventory-category-list',
    templateUrl: './inventory-category-list.component.html',
})
export class InventoryCategoryListComponent extends BaseGridComponent implements OnInit {

    permission = {
        name: Permissions.InventoryCategories,
        readOnlyLevel: Levels.ReadOnly,
        editLevel: Levels.Edit
    };
    public icons = {
        inventoryCategoriesUnitList
    };
    inventoryCategories: InventoryCategory[] = [];
    inventoryCategoryId: number;
    inventoryCategoriesColumns: Array<GridColumn> = [];
    printInventoryCategoriesColumns: Array<GridColumn> = [];
    @ViewChild('confirmInventoryCategory') private confirmInventoryCategory: ConfirmDeleteComponent;
    constructor(private authenticationService: AuthenticationService,
        private alertService: AlertsService,
        private spinnerService: SpinnerService,
        private modelService: ModalService,
        private inventoryCategoryService: InventoryCategoryService,
        private printService: PrintTableService,
        protected applicationStateService: ApplicationStateService,
        protected route: ActivatedRoute,
        protected router: Router,
    ) {
        super(applicationStateService, route);
    }

    ngOnInit() {
        this.configureGridColumns();
        this.reloadInventoryCategories();
    }
    private configureGridColumns() {

        const editColumn = new EditColumn({
            Clicked: (vendor) => {
                this.editInventoryCategory(vendor.id);
            },
            Width: '57px',
            AddIcon: new LinkColumn({
                Clicked: () => {
                    this.editInventoryCategory(0);
                },
                ToolTip: 'New'
            })
        });

        const deleteColumn = new DeleteColumn({
            Clicked: (inventoryCategory) => {
                this.deleteInventoryCategory(inventoryCategory);
            },
            Width: '83px',
            PrintIcon: new LinkColumn({
                Clicked: () => {
                    this.printDiv();
                },
                ToolTip: 'Print'
            })
        });

        this.inventoryCategoriesColumns = [
            new GridColumn({ HeaderText: 'Category', Field: 'name', IsSortable: true, Width: '92%' }),
        ] as Array<GridColumn>;

        this.printInventoryCategoriesColumns = [...this.inventoryCategoriesColumns];

        if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
            this.inventoryCategoriesColumns.push(editColumn);
            this.inventoryCategoriesColumns.push(deleteColumn);
        }
    }
    private reloadInventoryCategories() {
        this.spinnerService.show();
        this.inventoryCategoryService.getInventoryCategoryList()
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (inventoryCategories: InventoryCategory[]) => {
                    this.inventoryCategories = (inventoryCategories === null ? [] : inventoryCategories);
                }, error: this.alertService.showApiError
            });
    }
    private editInventoryCategory(id: number) {
        this.inventoryCategoryId = id;
        const modalRef = this.modelService.show(InventoryCategoryEditComponent, {
            animated: false,
            class: 'vertical-center',
            initialState: {
                inventoryCategoryId: id
            }
        });
        modalRef.close.subscribe(res => {
            if (res && res.shouldReload) {
                this.inventoryCategoryId = 0;
                this.reloadInventoryCategories();
            } else {
                this.inventoryCategoryId = 0;
            }
        });
    }
    private deleteInventoryCategory(inventoryCategory: InventoryCategory) {
        this.inventoryCategoryId = inventoryCategory.id;
        this.confirmInventoryCategory.Show(
            StringUtils.format(Messages.ConfirmDeleteInventoryCategory, { 'inventoryCategory': inventoryCategory.name }));
    }
    onConfirmAcknowledge() {
        this.spinnerService.show();
        this.inventoryCategoryService.deleteInventoryCategory(this.inventoryCategoryId)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: () => {
                    this.alertService.renderSuccessMessage(Messages.InventoryCategoryDeleted);
                    this.reloadInventoryCategories();
                    this.inventoryCategoryId = 0;
                }, error: this.alertService.showApiError
            });
    }
    private printDiv() {
        this.printService.printEmitter.next({
            gridColumns: this.printInventoryCategoriesColumns,
            gridData: this.inventoryCategories
        });
    }

    close() {
        this.router.navigate(['../'], { relativeTo: this.route });
    }
}
