<pos-edit-wrapper (close)="cancelEditing()" heading="{{infoLinkEditId ? 'Edit Custom Link' : 'Add Custom Link'}}">
  <form #formInfoLinks="ngForm" (ngSubmit)='saveInfoLinks(formInfoLinks.valid)'>
    <context-provider provide="props">
      <div class="col-12 pos-modal-body overflow-hidden screen-scroll">
        <pos-row>
          <pos-column>
            <pos-form-field label="Section" [validationMessages]="{required: 'Please select section.'}">
              <ng-select posInput [items]="infoLinkSectionList" [hideSelected]="true" bindLabel="Name" appendTo="body"
                bindValue="Id" placeholder="Select section" [(ngModel)]="infoLinkDetail.SectionId" name="infoLinkSections"
                #infoLinkSections="ngModel" [required]="true">
              </ng-select>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Name" [validationMessages]="{required: 'Please enter name.'}">
              <input posInput class="form-control" name="infoLinkName" #infoLinkName="ngModel"
                [(ngModel)]="infoLinkDetail.Name" [required]="true" type="text" />
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Link" inputStyle="col-12">
              <input posInput class="form-control" name="link" #link="ngModel" [(ngModel)]="infoLinkDetail.Link"
                type="text" />
            </pos-form-field>
          </pos-column>
        </pos-row>


      </div>
      <div class="col-12 action-button-container popup-footer ">
        <button class="btn-lg btn-primary btn btn-save pos-button" type="submit" value="Save" scrollup> Save</button>
        <button class="btn-secondary btn-lg btn btn-cancel-modal pos-button" data-dismiss="modal" (click)="cancelEditing()" type="button"
          value="Cancel">Cancel</button>
      </div>
    </context-provider>
  </form>
</pos-edit-wrapper>
