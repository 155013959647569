import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { HttpClient } from '@angular/common/http';
import { RabbitMQ, RabbitMQStompConfig } from 'src/app/shared';
import { Observable } from 'rxjs';

@Injectable()
export class RabbitMqConfigurationService {

  rabbitMQConfigurationApiBasePath = `${RuntimeConstants.API_PATH}rabbitmqconfigurations`;

  constructor(private httpService: HttpClient) { }

  newRabbitMqConfig() {
    const rabbitMqConfig: RabbitMQ = {
        Host: '',
        Port: 0,
        Username: '',
        Password: '',
        StompHost: '',
        StompPort: 0,
        VirtualHost: '',
        IsUseSSL: false,
        StompIsUseSSL: false,
        RabbitMQStompConfig: []
    };
    return rabbitMqConfig;
  }

  newRabbitMqStompConfig() {
    const rabbitMqStompConfig: RabbitMQStompConfig = {
        ApplicationHost: '',
        Port: null,
        Host: '',
        IsUseSSL: false
    };
    return rabbitMqStompConfig;
  }

  getRabbitMQConfig(): Observable<RabbitMQ> {
    return this.httpService.get<RabbitMQ>(this.rabbitMQConfigurationApiBasePath);
  }

  saveRabbitMQConfiguration(rabbitMqConfig: RabbitMQ): Observable<RabbitMQ> {
    return this.httpService.post<RabbitMQ>(this.rabbitMQConfigurationApiBasePath, rabbitMqConfig);
  }
}
