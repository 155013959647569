import { Component, Input, OnInit } from '@angular/core';
import { find, forEach, orderBy } from 'lodash';
import { MenuDisplay } from 'src/app/menu-display/interfaces/menu-display';
import { DomainConstants } from 'src/app/shared/constants/domain-constants';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { MenuDisplaySetting } from '../../../interface/menu-display-setting';
import { TerminalsService } from '../../../services';
import { deleteWhite } from 'src/app/shared/components/icon/icons';
import { ControlContainer, NgForm } from '@angular/forms';
import { ConfirmDeleteModalComponent, ModalService, SpinnerService } from 'src/app/shared/components';
import { Messages } from 'src/app/shared/constants';
import { AlertsService } from 'src/app/shared';
import { finalize } from 'rxjs/internal/operators/finalize';

@Component({
  selector: 'pos-menu-display-override-config',
  templateUrl: './menu-display-override-config.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class MenuDisplayOverrideConfigComponent implements OnInit {

  @Input() displayMenuData: Array<MenuDisplay> = [];
  @Input() isFormSubmitted = false;
  menuId: number = null;
  @Input() menuDisplaySettings: Array<MenuDisplaySetting> = [];
  daysOfWeek = [];
  icons = {
    deleteWhite
  };

  constructor(private applicationStateService: ApplicationStateService,
    private modalService: ModalService,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private terminalService: TerminalsService) { }

  ngOnInit(): void {
    this.prepareWeekDay();
    this.createMenuDisplaySettingRow(true);
    forEach(this.menuDisplaySettings, (menuDisplaySetting) => {
      menuDisplaySetting.DaysIds = orderBy(menuDisplaySetting.DaysIds);
    });
  }

  prepareWeekDay() {
    const startWeekDay = parseInt(find(DomainConstants.WeekDay, (dayId, day) => {
      return day === this.applicationStateService.settingParam.WeekStart;
    }), 10);
    forEach(DomainConstants.WeekDay, (value, key) => {
      this.daysOfWeek.push({ Id: value, Name: key });
    });
    if (startWeekDay) {
      const daysOfWeekCopy = [...this.daysOfWeek];
      this.daysOfWeek = [];
      this.daysOfWeek.push(...daysOfWeekCopy.slice(startWeekDay, daysOfWeekCopy.length));
      this.daysOfWeek.push(...daysOfWeekCopy.slice(0, startWeekDay));
    }
  }

  createMenuDisplaySettingRow(lastRow: boolean) {
    if (!lastRow && this.menuDisplaySettings.length > 1) {
      return;
    }
    const newMenuDisplaySetting = this.terminalService.newMenuDisplaySetting();
    this.menuDisplaySettings.push(newMenuDisplaySetting);
  }

  removeMenuDisplaySetting(setting: MenuDisplaySetting, index: number) {
    this.resetOverlappedTime();
    if (setting.Id) {
      const modal = this.modalService.getModalWrapper(ConfirmDeleteModalComponent);
      const modalRef = modal.show({
        animated: false,
        class: 'vertical-center',
        keyboard: false,
        initialState: {
          message: Messages.ConfirmDeleteMenuDisplayOverride
        }
      });
      modalRef.close.subscribe(res => {
        if (res && res.shouldDelete) {
          this.deleteMenuDisplaySetting(setting, index);
        }
      });
    } else {
      this.menuDisplaySettings.splice(index, 1);
    }
  }

  deleteMenuDisplaySetting(setting: MenuDisplaySetting, index) {
    this.spinnerService.show();
    this.terminalService.deleteMenuDisplaySetting(setting.Id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.menuDisplaySettings.splice(index, 1);
          this.alertService.renderSuccessMessage(Messages.MenuDisplaySettingDeleted);
        }, error: this.alertService.showApiError
      });
  }

  resetOverlappedTime() {
    forEach(this.menuDisplaySettings, (setting) => {
      setting.HasBetweeenStartTime = false;
      setting.HasBetweeenEndTime = false;
    });
  }

}
