import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class UserLogInService {

  dataSubject = new Subject<Promise<boolean>>();

  constructor() {
  }

  get data$(): Observable<Promise<boolean>> {
    return this.dataSubject.asObservable();
}


}
