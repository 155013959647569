export const commons = {
    production: true,
    common: { },
    basePath: '/',
    api: {
        basePath: '',
        url: '/api/v1.0/'
    },
    enableVersionCheck: true,
    contactSupportKey: '65afbaf60ff6374032c3c0ac/1hkr74l5l'
};
