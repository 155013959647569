import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ApplicationStateService, RuntimeConstants } from 'src/app/shared';
import { ModalComponent } from 'src/app/shared/components';
import { KioskNavigationService } from '../..';

@Component({
  selector: 'pos-kiosk-user-idle',
  styleUrls: ['./kiosk-user-idle.component.scss'],
  templateUrl: './kiosk-user-idle.component.html',
})
export class KioskUserIdleComponent extends ModalComponent {

  intervalSeconds: number = 30;
  rejectButtonText = 'NO, CANCEL MY ORDER.';
  confirmButtonText = 'YES! I NEED MORE TIME.';
  baseImagePath = `${RuntimeConstants.IMAGE_BASE_PATH}/order-kiosk/default-screens/`;
  backgroundImage = 'idle-timeout.png';
  autoTimeout: NodeJS.Timeout;

  constructor(public bsModalRef: BsModalRef, 
    private applicationStateService: ApplicationStateService,
    private kioskNavigationService: KioskNavigationService
  ) {
    super(bsModalRef);
  }

  ngOnInit() {
    const settingParams = this.applicationStateService?.settingParam;
    if(settingParams) {
      this.rejectButtonText = settingParams.OrderKioskCancelOrderButtonText;
      this.confirmButtonText = settingParams.OrderKioskNeedMoreTimeButtonText;
      this.backgroundImage = settingParams.OrderKioskIdleTimeoutImage;
      this.intervalSeconds = settingParams.OrderKioskIdleScreenIdleTimeout ?? this.kioskNavigationService.userIdleTimeout;
    }
    this.autoTimeout = setTimeout(() => {
      this.hideModal(false);
    }, this.intervalSeconds * 1000);
  }
  
  hideModal(isContinueOrder = true) {
    clearTimeout(this.autoTimeout);
    this.hide({
      continueOrder: isContinueOrder
    })
  }
} 
