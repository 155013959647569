import { Directive, OnInit, Input, ElementRef, ViewChild, Renderer2, ViewChildren, QueryList, HostListener } from '@angular/core';
import { CameraAnnotationService } from '../../services/camera-annotation.service';

@Directive({
    selector: '[callCameraApi]'
})
export class CallCameraAPIDirective implements OnInit {

    @Input('callCameraApi') cameraAnnotationText: string;
    @Input() otherInfo: any;
    currentElement: ElementRef;
    elementRenderer: Renderer2;

    constructor(private el: ElementRef, private renderer: Renderer2,
        private cameraAnnotationService: CameraAnnotationService) {
        this.elementRenderer = renderer;
    }

    ngOnInit() {
      
    }

    @HostListener('click', ['$event']) onClick($event) {
        const timeout = setTimeout(() => {
          if (timeout) {
            clearTimeout(timeout);
          }
          if (this.cameraAnnotationText) {
              this.cameraAnnotationService.addAnnotationToCamera(this.cameraAnnotationText, {});
          }
        });
    }
}
