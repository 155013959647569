<div class="h-80" *ngIf="headerLinks">
    <pos-header-entry-main [links]="headerLinks" [homeState]="homeState"></pos-header-entry-main>
</div>
<div class="dashboard-configuration-page page col-12 p-0 row mx-0">
    <div class="col-12 px-1">
        <pos-dashboard-configuration #dashboardConfiguration [(dashboardId)]="transParam.dashboardId"
            [(mode)]="transParam.mode" (editMode)="toggleEditMode($event)" [(terminalId)]="terminalId">
        </pos-dashboard-configuration>
    </div>
    <div *ngIf="transParam.mode == 'interactive'"
        class="col-12 dashboard-footer action-button-container">
        <button class="btn btn-primary btn-lg me-pix-5 pos-button normal" (click)="saveDashboard()" data-dismiss="modal">Apply </button>
        <button class="btn btn-secondary btn-lg me-pix-5 pos-button normal" (click)="closeDashboard()" data-dismiss="modal">Cancel </button>
    </div>
</div>
