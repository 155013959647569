<div class="col-12 pt-pix-10 mb-pix-5 pe-pix-10" *ngIf="isMultipleReward">
    <button (click)="addReward()" type="button" value="Add" class="btn btn-secondary normal float-right">
        <pos-icon [icon]="icons.newProduct" class="pe-pix-5"></pos-icon> {{ rewardCaption }}
    </button>
</div>
<div class="row mx-0 p-0 pb-pix-10 col-12">
    <div *ngFor="let reward of configuredRewards; let index = index;" class="col-12 pe-0">
        <pos-row class="pb-pix-15 mt-pix-5 shadow reward-config-parent position-relative">
            <pos-column xs="11">
                <pos-form-field [label]="rewardCaption"
                    [validationMessages]="{required: 'Please select ' + (rewardCaption | lowercase) + '.'}">
                    <ng-select posInput [items]="rewards" bindLabel="DisplayName" bindValue="Name" appendTo="body"
                        name="rewardInput_{{index}}" placeholder="Select {{rewardCaption | lowercase}}"
                        [(ngModel)]="reward.Name" (change)="rewardChanged(reward)" #rewardDropdown="ngModel"
                        [required]="true">
                    </ng-select>
                </pos-form-field>
            </pos-column>
            <pos-column xs="1" class="pt-pix-15">
                <button (click)="deleteReward(index)" type="button"
                    class="btn btn-secondary normal float-right pos-button pos-button" *ngIf="index > 0">
                    <pos-icon [icon]="icons.trashAltWhite" class="red"></pos-icon>
                </button>
            </pos-column>
            <pos-column>
                <pos-config [configs]="reward.Config" [inputName]="'reward'+ index" *ngIf="reward.Config?.length"
                    parentElement=".reward-config-parent" [description]="reward.Description">
                </pos-config>
            </pos-column>
        </pos-row>
    </div>
</div>
