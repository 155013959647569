import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { SalesProductBarcode } from '../../interface/sales-product-barcode';
import { SalesProductBarcodeService } from '../../services/sales-product-barcode.service';
import { AlertsService, ApplicationStateService, BaseListComponent, Messages, ModalService, PrintTableService, SettingParam, SpinnerService, StringUtils } from 'src/app/shared';
import { NgForm } from '@angular/forms';
import { GridColumn } from '@tarktech/tark-ng-utils';
import { ActivatedRoute } from '@angular/router';
import { SalesSize } from 'src/app/information-management/sales-sizes';
import { finalize } from 'rxjs';
declare let $: any;

@Component({
  selector: 'pos-sales-product-barcodes',
  templateUrl: './sales-product-barcodes.component.html'
})
export class SalesProductBarcodesComponent extends BaseListComponent<SalesProductBarcode> implements OnInit, OnChanges {
  @Input() salesProductId: number;
  @Input() salesProductSizes: Array<SalesSize> = [];
  barcode: SalesProductBarcode = null;
  barcodes: Array<SalesProductBarcode> = [];
  barcodeColumns: Array<GridColumn> = [];
  printBarcodeColumns: Array<GridColumn> = [];
  settingParam: SettingParam;
  @ViewChild('barcodeForm') barcodeForm: NgForm;

  constructor(private salesProductBarcodeService: SalesProductBarcodeService,
    protected spinnerService: SpinnerService,
    protected alertService: AlertsService,
    protected modalService: ModalService,
    protected applicationStateService: ApplicationStateService,
    protected printService: PrintTableService,
    protected route: ActivatedRoute) {
    super(salesProductBarcodeService, alertService, spinnerService, modalService, applicationStateService, printService, route);
    this.barcode = salesProductBarcodeService.newBarcode();
  }

  ngOnInit(): void {
    this.isAddIconVisible = false;
    this.barcodeColumns = this.configureGridColumns();
    this.printBarcodeColumns = [...this.getGridColumns()];
    $('#barcodeName').focus();
    this.settingParam = this.applicationStateService.settingParam;
    this.deleteSuccessMessage = Messages.SalesProductBarcodeDeleted;
    this.loadData();
  }

  loadData() {
    this.data$.subscribe(res => {
      this.barcodes = res.filter(x => x.SalesProductId == this.salesProductId);
      this.barcodes.forEach(x => x.SalesSizeName = x.SalesSize.Name);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['salesProductSizes']) {
      this.salesProductSizes = this.salesProductSizes.filter(x => x.Price > 0 && x.Id != x.SizeId).sort((a, b) => a.Ordinal - b.Ordinal);
    }
  }

  save(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.spinnerService.show();
    this.barcode.SalesProductId = this.salesProductId;
    this.salesProductBarcodeService.insertOrUpdate(this.barcode)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.alertService.renderSuccessMessage(Messages.SalesProductBarcodeSaveSuccess);
          this.reload();
          this.cancel();
        }, error: this.alertService.showApiError
      });
  }

  cancel() {
    this.barcodeForm.onReset();
    this.barcode = this.salesProductBarcodeService.newBarcode();
    $('#barcodeName').focus();
  }

  getGridColumns(): GridColumn[] {
    return [
      new GridColumn({ HeaderText: 'Size', Field: 'SalesSizeName', IsSortable: true, Width: '38%' }),
      new GridColumn({ HeaderText: 'Barcode', Field: 'Barcode', IsSortable: true, Width: '35%' }),
      new GridColumn({ HeaderText: 'Qty', Field: 'Qty', IsSortable: true, Width: '20%' })
    ] as Array<GridColumn>;
  }

  editItem(id: number, data?: SalesProductBarcode): void {
    $('#barcodeName').focus();
    this.barcode = { ...data };
  }

  getConfirmDeleteMessage(data: SalesProductBarcode): string {
    return StringUtils.format(Messages.ConfirmDeleteSalesProductBarcode,
      { 'barCode': data ? data.Barcode : '' }
    );
  }

  barcodeEntered(value: string) {
    if (value) {
      if (value.startsWith(this.settingParam.BarcodeScanningInitiatorSequence)) {
        value = value.replace(this.settingParam.BarcodeScanningInitiatorSequence, '');
      }
      if (value.endsWith(this.settingParam.BarcodeScanningEndingSequence)) {
        value = value.replace(this.settingParam.BarcodeScanningEndingSequence, '');
      }
      this.barcode.Barcode = value;
    }
  }

  scanBarcode(barcode: string) {
    this.barcode.Barcode = barcode;
  }

  protected printData() {
    this.printService.printEmitter.next({ gridColumns: this.printBarcodeColumns, gridData: this.barcodes });
  }
}
