import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { InventoryZone } from '../../information-management/inventory-zone/interface/inventory-zone';
import { InventoryInfoModel } from '../../information-management/inventory-zone/interface/inventory-info-model';
import { InventoryBinModel } from '../../information-management/inventory-zone/interface/Inventory-bin-model';

@Injectable()
export class InventoryZoneService {
    inventoryzoneApiBasePath = `${RuntimeConstants.API_PATH}inventoryzone`;

    constructor(private httpService: HttpClient) { }

    newInventoryZone() {
        const inventoryZone: InventoryZone = {
            Id: 0,
            Name: '',
            Code: '',
            Description: '',
            Label: '',
            Color: ''
        };

        return inventoryZone;
    }

    getInventoryZones(): Observable<InventoryZone[]> {
        return this.httpService.get<InventoryZone[]>(this.inventoryzoneApiBasePath);
    }

    addInventoryZone(inventoryZone: InventoryZone): Observable<any> {
        return this.httpService.post(this.inventoryzoneApiBasePath + '/', inventoryZone);
    }

    updateInventoryZone(inventoryZoneId: number, inventoryZone: InventoryZone): Observable<any> {
        return this.httpService.put(this.inventoryzoneApiBasePath + '/' + inventoryZoneId,
            inventoryZone);
    }

    deleteInventoryZone(inventoryZoneId: number): Observable<any> {
        return this.httpService.delete(this.inventoryzoneApiBasePath + '/' + inventoryZoneId);
    }

    getInventoryZone(inventoryZoneId: number): Observable<InventoryZone> {
        return this.httpService.get<InventoryZone>(this.inventoryzoneApiBasePath + '/' + inventoryZoneId);
    }

    getInventoryProductsForZone(inventoryZoneId: number): Observable<InventoryInfoModel[]> {
        return this.httpService.get<InventoryInfoModel[]>(
            this.inventoryzoneApiBasePath + '/' + inventoryZoneId + '/inventoryProductsForZone');
    }

    newInventoryBin() {
        const inventoryBin: InventoryBinModel = {
            Id: 0,
            Zone_id: 0,
            Bin: '',
            Description: '',
            Color: ''
        };
        return inventoryBin;
    }

    getInventoryProductsForBin(inventoryBinId: number): Observable<InventoryInfoModel[]> {
        return this.httpService.get<InventoryInfoModel[]>(
            this.inventoryzoneApiBasePath + '/' + inventoryBinId + '/inventoryProductsForBin');
    }

    getInventoryBins(inventoryZoneId): Observable<InventoryBinModel[]> {
        return this.httpService.get<InventoryBinModel[]>(this.inventoryzoneApiBasePath + '/' + inventoryZoneId + '/bins');
    }

    getInventoryBin(inventoryZoneId: number, inventoryBinId: number): Observable<InventoryBinModel> {
        return this.httpService.get<InventoryBinModel>(this.inventoryzoneApiBasePath + '/' + inventoryZoneId + '/bins/' + inventoryBinId);
    }

    deleteInventoryBin(inventoryZoneId: number, inventoryBinId: number): Observable<any> {
        return this.httpService.delete(this.inventoryzoneApiBasePath + '/' + inventoryZoneId + '/bins/' + inventoryBinId);
    }

    addInventoryBin(inventoryZoneId: number, inventoryBin: InventoryBinModel): Observable<any> {
        return this.httpService.post(this.inventoryzoneApiBasePath + '/' + inventoryZoneId + '/bins', inventoryBin);
    }

    updateInventoryBin(inventoryZoneId: number, inventoryBin: InventoryBinModel): Observable<any> {
        return this.httpService.put(this.inventoryzoneApiBasePath + '/' + inventoryZoneId + '/bins/' + inventoryBin.Id, inventoryBin);
    }

    updateInventoryBinColor(inventoryBin: InventoryBinModel) {
        return this.httpService.put(this.inventoryzoneApiBasePath + '/' + inventoryBin.Id + '/color', inventoryBin);
    }

    triggerFlash(inventoryBinId: number, terminalId: number, terminalName: string) {
        return this.httpService.post(`${this.inventoryzoneApiBasePath}/${inventoryBinId}/flash/${terminalId}/${terminalName}`, null);
    }
}
