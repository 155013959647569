import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[posValidate]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: ValidateDirective,
    multi: true
  }]
})
export class ValidateDirective implements Validator {

  @Input('posValidate') fn: (c: AbstractControl) =>  ValidationErrors | null;
  private onChange: () => void;

  validate(control: AbstractControl<any, any>): ValidationErrors {
    if(this.fn) {
      return this.fn(control);
    }
    return null;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onChange = fn;
  }

}
