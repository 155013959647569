import { Injectable } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { ApplicationStateService } from './application-state.service';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class UserIdleService {
  idleState = "";
  subscription: Subscription;
  constructor(
    public idle: Idle,
    public applicationStateService: ApplicationStateService
  ) {
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
  }

  initialize(idleTime: number, callBack: Function): Subscription {
    if (idleTime > 0) {
      this.idle.setIdle(idleTime);
      this.idle.watch();
      this.subscription = this.subscribe(callBack);
      return this.subscription;
    }
    return null;
  }

  subscribe(callBack: Function): Subscription {
    return this.idle.onIdleStart.subscribe(() => {
      this.idleState = "You've gone idle!";
      console.log(`${this.idleState}`);
      callBack();
    });
  }

  stop() {
    this.subscription?.unsubscribe();
    this.idle.stop();
  }
}
