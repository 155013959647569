import { Levels } from "./accessLevel";
import { Permissions } from "./permissions";

 let permissions=Permissions;
 let accessLevels=Levels;

export const ChildStatePermissionConstants = {
    
        LogInventory: {
            "permissions": [{ Name:permissions.InventoryLogInventory, Level: accessLevels.Access }]
        },
        ReceiptScript:{
             "permissions": [{ Name:permissions.ManagePrintout, Level: accessLevels.Access }]
        },
        Events:{
             "permissions": [{ Name: permissions.ManageEventManager, Level: accessLevels.Access }]
        },
        OrderReceiving:{
             "permissions": [{ Name: permissions.InventoryOrderReceiving, Level: accessLevels.Access}]
        },
        InventoryProductLoss:{
             "permissions": [{ Name: permissions.InventoryInventoryLoss, Level: accessLevels.Access}]
        },
        InventoryPrepRecording:{
             "permissions": [{ Name: permissions.InventoryInventoryPrep, Level: accessLevels.Access }]
        }
}
