import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { EditColumn, GridColumn, LinkColumn, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import {
  AuthenticationService, AlertsService, SpinnerService, Messages, Levels, Permissions, ApplicationStateService,
  ConfirmDeleteComponent, SettingParam, FieldInfoMessages, ModalService
} from 'src/app/shared';
import { finalize } from 'rxjs/operators';
import * as _ from 'lodash';
import { editWhite, deleteWhite, baselineExpenseMoneyBill, plusWhite } from 'src/app/shared/components/icon';
import { BaselineExpense } from '../../interface/baseline-expense';
import { BaselineExpenseService } from '../../services/baseline-expense.service';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { BaselineExpensesEditComponent } from '../baseline-expenses-edit';
import { cloneDeep } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'pos-baseline-expenses',
  templateUrl: './baseline-expenses.component.html',
  styleUrls: ['./baseline-expenses.component.scss']
})
export class BaselineExpensesComponent implements OnInit {

  baselineExpenses: Array<BaselineExpense> = [];
  baselineExpenseColumns: Array<GridColumn> = [];
  deleteData: BaselineExpense;
  fieldInfoMessages = FieldInfoMessages;
  message = '';
  @ViewChild('operationTemplate', { static: true }) operationTemplate: TemplateRef<any>;
  @ViewChild('expenseTemplate', { static: true }) expenseTemplate: TemplateRef<any>;
  @ViewChild('amountTemplate', { static: true }) amountTemplate: TemplateRef<any>;
  @ViewChild('confirmDeleteBaselineExpense') private confirmDeleteBaselineExpense: ConfirmDeleteComponent;
  @ViewChild('operationHeaderTemplate', { static: true }) operationHeaderTemplate: TemplateRef<any>;
  permission = {
    name: Permissions.SystemConfigurationBaselineExpenses,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };
  icons = {
    editWhite, deleteWhite, baselineExpenseMoneyBill, plusWhite
  };
  isAddIconVisible: any;

  constructor(protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected baselineExpenseService: BaselineExpenseService,
    protected applicationStateService: ApplicationStateService,
    private authenticationService: AuthenticationService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected modalService: ModalService) {
  }

  ngOnInit() {
    this.message = Messages.BaselineExpenseDeletedSuccess;
    this.baselineExpenseColumns = this.configureGridColumns();
    this.reload();
  }

  reload() {
    this.baselineExpenses = [];
    this.spinnerService.show();
    this.baselineExpenseService.getBaselineExpenses(this.applicationStateService.terminalId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: Array<BaselineExpense>) => {
          _.forEach(response, (expense, i) => {
            this.baselineExpenses.push({ Id: i + 1, Expense: expense.Expense, Amount: expense.Amount });
          });
        }, error: this.alertService.showApiError
      });
  }

  configureGridColumns(): Array<GridColumn> {

    const expenseColumn = new TemplateColumn({
      itemTemplate: this.expenseTemplate,
      Width: '60%',
      HeaderText: 'Expense',
      IsSortable: false,
    });

    const amountColumn = new TemplateColumn({
      itemTemplate: this.amountTemplate,
      Width: '35%',
      HeaderText: 'Amount',
      IsSortable: false,
      TextAlign: TextAlign.Right
    });
    const operationColumn = new TemplateColumn({
      headerTemplate: this.operationHeaderTemplate,
      itemTemplate: this.operationTemplate,
      Width: '67px',
      TextAlign: TextAlign.Center,
      CellClass: 'cell-padding'
    });

    this.baselineExpenseColumns = [
      expenseColumn,
      amountColumn
    ];

    if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      this.baselineExpenseColumns.push(operationColumn);
    }

    return this.baselineExpenseColumns;
  }

  editItem(id: number, data?: BaselineExpense): void {
    const modalRef = this.modalService.getModalWrapper(BaselineExpensesEditComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      initialState: {
        editIndex: data?.Id || id,
        baselineExpenses: cloneDeep(this.baselineExpenses)
      }
    });

    modal.close.subscribe(res => {
      if (res && res.shouldReload) {
        this.reload();
      }
    });
  }

  deleteBaselineExpense(data: BaselineExpense) {
    this.deleteData = data;
    const confirmDeleteMessage = StringUtils.format(Messages.ConfirmDeleteBaselineExpense, { 'ExpenseName': data.Expense });
    this.confirmDeleteBaselineExpense.Show(confirmDeleteMessage);
  }

  onConfirmAcknowledge() {
    this.baselineExpenses.splice(this.deleteData.Id - 1, 1);
    this.saveBaselineExpensesDetails(true);
  }

  saveBaselineExpensesDetails(isDeleteCall = false) {
    this.baselineExpenses = _.filter(this.baselineExpenses, (baselineExpense) => {
      return baselineExpense.Expense !== 'Total';
    });
    this.spinnerService.show();
    this.baselineExpenseService.saveBaselineExpensesDetail(this.baselineExpenses)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          if (!isDeleteCall) {
            this.alertService.renderSuccessMessage(Messages.BaselineExpenseSaveSuccess);
          } else {
            this.alertService.renderSuccessMessage(Messages.BaselineExpenseDeletedSuccess);
          }
          this.reload();
        }, error: this.alertService.showApiError
      });
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route});
  }
}
