import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import {
  salesProductInfo, salesProductPricing, salesProductWorkflow, salesProductButtonsDiceOne,
  salesProductRecipe, salesProductQuickReportFileAlt, salesProductClock,
  productSimplyBuilt, plusWhite, editWhiteWithText, times, list, salesProductsFileInvoiceDollar, barcode
} from 'src/app/shared/components/icon';
import {
  SpinnerService, AlertsService, SettingParam, ApplicationStateService,
  DomainConstants, EventBroadcastingService, ModalService, Messages, MathsUtilityService, ConfirmDeleteModalComponent,
  HardwareService, SalesUnit, SalesCategoryService, SalesTaxService, RuntimeConstants, Permissions, Levels
} from 'src/app/shared';
import { SalesProductsService } from '../../services';
import { finalize } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { SalesTax } from 'src/app/configurator';
import * as _ from 'lodash';
import { SalesProduct } from '../../interface/sales-product';
import { SalesProductFieldInfoMessages } from '../../constants/sales-product-field-info-messages';
import { SalesCategoryEditModelComponent } from 'src/app/information-management/sales-categories/component/sales-category-edit-model/sales-category-edit-model.component';
import { SalesInventoryActivationComponent } from '../sales-inventory-activation';
import { AbstractControl, NgForm, ValidationErrors } from '@angular/forms';
import { ParameterService } from 'src/app/shared/services/parameter.service';
import { SalesUnitService } from 'src/app/information-management/sales-units/services/sales-unit.service';
import { SalesKitchenPrinter } from '../../interface/sales-kitchen-printer';
import { find, forEach } from 'lodash';

declare let $: any;
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { ActivatedRoute, Router } from '@angular/router';
import { Tag } from 'src/app/information-management/tags';
import { TagService } from 'src/app/shared/services/tag.service';
import { SalesProductSize } from '../../interface/sales-product-size';
import { OrderProductWorkflowService } from 'src/app/manage-console/online-order/service/order-product-workflow.service';
import { ManualValuesModel, OrderFulfillmentModel } from 'src/app/manage-console/automated-exports/interface/parameter-model';
import { ExtensionValidationService } from 'src/app/shared/services/extension-validation.service';
import { SalesSize } from 'src/app/information-management/sales-sizes';
import { SalesProductPriceComponent } from '../sales-product-price';

@Component({
  selector: 'pos-sales-product-edit',
  templateUrl: './sales-product-edit.component.html',
  styleUrls: ['./sales-product-edit.component.scss']
})
export class SalesProductEditComponent implements OnInit {

  @ViewChild('formSalesProduct')
  formSalesProduct: NgForm;
  @ViewChild('salesProductPrice') private salesProductPrice: SalesProductPriceComponent;
  @ViewChild('tagSelection') tagSelection: any;
  @Output() close = new EventEmitter<any>();
  @Output() formChange = new EventEmitter<NgForm>();
  @Input() isScroll = false;
  @Input() salesProductId: number = null;
  @Input() salesProductName = '';
  salesProductPrices: Array<SalesProductSize> = [];
  defaultPriceId = 1;
  salesCategoryId = 1;
  isDeletePrice = true;
  settingParams: SettingParam;
  categoryList: Array<any> = [];
  revenueType = [{ value: true, type: 'Revenue' }, { value: false, type: 'Liability' }];
  orderFulfillmentType: Array<OrderFulfillmentModel> = [];
  salesProduct: SalesProduct;
  IsActiveProduct = false;
  selectedSalesProductKitchenPrinters: Array<any> = [];
  isMakeTableCookingState = false;
  isMakeTableCraftingState = false;
  isMakeTableRetrievingIngredientsState = false;
  isMakeTableReadyState = false;
  fieldInfoMessages;
  prepSecondsConvertToTime: Date;
  kitchenPrinters: Array<any> = [];
  inventoryProducts: Array<any> = [];
  useParallelPickScreen: any;
  outerContainerHeight: any;
  innerContainerHeight: any;
  infoMakeTableRetrievingIngredientsState = '';
  infoMakeTableCraftingState = '';
  infoMakeTableReadyState = '';
  salesUnitList: Array<SalesUnit> = [];
  checkDefaultQty = false;
  productIngredientConsumption: Array<any> = [];
  isDefaultQtyFraction = false;
  salesTaxList: Array<SalesTax> = [];
  isSaveAndContinue = false;
  icons = {
    salesProductInfo,
    salesProductPricing,
    salesProductWorkflow,
    salesProductButtonsDiceOne,
    salesProductRecipe,
    salesProductQuickReportFileAlt,
    salesProductClock,
    productSimplyBuilt,
    plusWhite,
    editWhiteWithText,
    times,
    list,
    salesProductsFileInvoiceDollar,
    barcode
  };
  formGroups = {
    'SalesProductsSettings': { '$valid': true },
    'TimerSetting': { '$valid': true }
  };
  saveButtonPressToProceed: string;
  salesSizes: Array<any> = [{ 'Name': 'default' }];
  tabList = {
    ProductsInfo: 'ProductsInfo',
    ProductPrice: 'ProductPrice',
    ProductWorkflow: 'ProductWorkflow',
    ProductDefaultButtons: 'ProductDefaultButtons',
    ProductRecipeCard: 'ProductRecipeCard',
    ProductQuickReport: 'ProductQuickReport',
    ProductTimer: 'ProductTimer',
    ProductAliases: 'ProductAliases',
    MenuDisplay: 'MenuDisplay',
    Barcodes: 'Barcodes'
  };
  selectedTab = this.tabList.ProductsInfo;

  kitchenPrinterInfo: string = '';
  tags: Array<Tag>;
  selectedTags: Array<Tag> = [];
  image: File;
  productImage: File;
  imagePath = RuntimeConstants.IMAGE_BASE_PATH + '/menu-display/sales-products';
  productImagePath = RuntimeConstants.IMAGE_BASE_PATH + '/sales-products';
  props = {
    labelClass:'',
    controlBoxClass:'col-lg-4 col-md-4 col-sm-6 col-12'
  }
  permission = {
    salesCategory: Permissions.SalesCategories,
    editLevel: Levels.Edit,
  };
  constructor(private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private salesProductService: SalesProductsService,
    private salesCategoryService: SalesCategoryService,
    private parametersService: ParameterService,
    private hardwareService: HardwareService,
    private applicationStateService: ApplicationStateService,   
    private eventBroadcastingService: EventBroadcastingService,
    private modalService: ModalService,
    private salesUnitService: SalesUnitService,
    private salesTaxService: SalesTaxService,
    private mathsUtilityService: MathsUtilityService,
    private route: ActivatedRoute,
    private router: Router,
    private orderProductWorkflowService: OrderProductWorkflowService,
      private tagService: TagService,
      private extensionValidationService: ExtensionValidationService  ) {
    this.salesProduct = this.salesProductService.newSalesProduct();
    this.fieldInfoMessages = SalesProductFieldInfoMessages;
    this.infoMakeTableRetrievingIngredientsState = Messages.InfoMakeTableRetrievingIngredientsState;
    this.infoMakeTableCraftingState = Messages.InfoMakeTableCraftingState;
    this.infoMakeTableReadyState = Messages.InfoMakeTableReadyState;
    this.saveButtonPressToProceed = Messages.PressApplyButtonToProceed;

  }

  ngOnInit() {   
    this.setDefaults();
    this.salesProductId = this.salesProductId ? Number(this.salesProductId) : 0;
    this.getSalesProductDetails();
    this.getHeight();
    $(window).resize(() => {
      this.getHeight();
    });    
  }

  setDefaults() {
    this.settingParams = this.applicationStateService.settingParam;
    this.useParallelPickScreen = DomainConstants.UseParallelPickScreen;
  }

  secondsConvertToTime(seconds: number) {
    if (seconds > 0) {
      const lowestDate = new Date(1970, 0, 1);
      lowestDate.setHours(0, 0, seconds, 0);
      this.prepSecondsConvertToTime = lowestDate;
    } else {
      this.prepSecondsConvertToTime = null;
    }
  }

  getSalesProductKitchenPrintersForInfo = (categoryId, isSilentCall = true) => {
    if (categoryId) {
      if (!isSilentCall) {
        this.spinnerService.show();
      }
      this.salesProductService.getKitchenPrintersInfo(categoryId)
        .pipe(finalize(() => {
          if (!isSilentCall) {
            this.spinnerService.hide();
          }
        }))
        .subscribe({ next: (res: SalesKitchenPrinter) => {
          if (res) {
            const printerNames = this.getKitchenPrinterNames(res.KitchenPrinters);
            this.kitchenPrinterInfo = `If not specified, will print to: ${printerNames} <i> (Inherited from 
            ${res.InheritedFrom ? (res.InheritedFrom + ':') : 'default configuration'} ${res.SourceName ? (res.SourceName + ')') : ')'} </i>`;
          }
        }, error: this.alertService.showApiError });
    }
  }

  getKitchenPrinterNames = (printerIds: Array<number>) => {
    let printerNames = '';
    forEach(this.kitchenPrinters, (printer) => {
      const printerDetail = find(printerIds, id => {
        return printer.Id == id;
      });
      if (printerDetail) {
        printerNames += !printerNames ? printer.Name : (', ' + printer.Name);
      }
    });
    return printerNames;
  }

  getSalesProductDetails() {
    this.spinnerService.show();
    if (!this.settingParams || !this.settingParams.MakeTableWorkflowStates) {
      this.parametersService.getParameterForTerminal(this.applicationStateService.terminalId)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({ next: (res: SettingParam) => {          
          this.applicationStateService.settingParam = res;
          this.settingParams = res;
          this.setPrepSettingVisibility();
        }, error: this.alertService.showApiError });
    } else {
      this.setPrepSettingVisibility();
    }
    const salesProductObservables = [];
    salesProductObservables.push(this.hardwareService.getHardwaresOfType(DomainConstants.HardwareTypes.KitchenPrinter));
    salesProductObservables.push(this.salesCategoryService.getSalesCategories(true));
    salesProductObservables.push(this.salesUnitService.getAll());
    salesProductObservables.push(this.salesTaxService.getAll());
    salesProductObservables.push(this.tagService.getAll());
    salesProductObservables.push(this.orderProductWorkflowService.getWorkflowIdentifiers());
    if (this.salesProductId) {
      salesProductObservables.push(this.salesProductService.getSalesProduct(this.salesProductId));
      salesProductObservables.push(this.salesProductService.getKitchenPrinter(this.salesProductId));
    } else {
    }
    forkJoin(salesProductObservables)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({ next: (responses: any) => {
        this.formChange.emit(this.formSalesProduct);
        if (responses) {
          if (responses[0]) {
            this.kitchenPrinters = responses[0];
          }
          this.categoryList = responses[1] ? responses[1] : [];
          this.salesUnitList = responses[2] ? responses[2] : [];
          _.forEach(this.salesUnitList, (salesUnit: SalesUnit) => {
            salesUnit.Name = salesUnit.Name + (salesUnit.AbbreviationName ? ` (${salesUnit.AbbreviationName})` : '');
          });
          this.salesProduct.UnitId = this.salesUnitList.length > 0 && this.settingParams.AllowOrderingFractionalQty ?
            this.salesUnitList[0].Id : null;
          this.salesProduct.Increment = this.settingParams.AllowOrderingFractionalQty ? 1 : null;
          this.salesProduct.DefaultQty = this.settingParams.AllowOrderingFractionalQty ? 1 : null;
          this.salesTaxList = responses[3] ? responses[3] : [];
          if(this.salesTaxList?.length == 1) {
            this.salesProduct.SalesTaxId = this.salesTaxList[0]?.Id
          }
          this.tags = responses[4] ? responses[4] : [];
          this.orderFulfillmentType = [...responses[5]];
          this.orderFulfillmentType.forEach(x => {
            x.Label = x.Name + ' (' + x.DeliveryType + ')'
          });
          if (responses[6]) {
            this.salesSizes = responses[6].ProductSizes;
            this.salesProduct = responses[6];
            if (this.salesProduct?.SalesProductTags) {
              forEach(this.salesProduct.SalesProductTags, (salesTag) => {
                const mappedTag = find(this.tags, (tag) => {
                  return tag.Id === salesTag.TagId;
                });
                if (mappedTag) {
                  this.selectedTags.push(mappedTag);
                }
              });
            }
            if (this.salesProduct.UnitId === 0) {
              this.salesProduct.UnitId = null;
            }
            this.isDefaultQtyFraction = this.salesProduct.Increment % 1 !== 0;
            this.IsActiveProduct = this.salesProduct.Active;
            this.setDefaultPrice(this.salesProduct.CategoryId);
            this.salesProduct.IsTimedPrep = this.salesProduct.IsTimedPrep ?? false;
          }
          if (responses[7]) {
            const selectedKitchenPrinters = [];
            _.forEach(responses[7], function (kitchenPrinter) {
              const salesProductPrinter = {
                id: kitchenPrinter.Id,
                Name: kitchenPrinter.Name
              };
              selectedKitchenPrinters.push(salesProductPrinter);
            });
            this.selectedSalesProductKitchenPrinters = selectedKitchenPrinters;
          }
          if (this.salesProduct.CategoryId) {
            this.reloadSizes(this.salesProduct.CategoryId);
          }
          this.secondsConvertToTime(this.salesProduct.PrepTime);
          this.salesCategoryId = this.salesProduct.CategoryId;
          this.salesProductPrices = this.salesProduct.ProductSizes ? this.salesProduct.ProductSizes : [];
          this.getSalesProductKitchenPrintersForInfo(this.salesProduct.CategoryId, false);
        }
      }, error: this.alertService.showApiError });
  }

  getHeight() {
    this.outerContainerHeight = $(window).height() - 305 + 'px';
    this.innerContainerHeight = $(window).height() - 325 + 'px';
  }


  changeDirectLinkStateForSalesPrice = (res) => {
    _.forEach(this.salesProductPrices, (price) => {
      if (price.SizeId === res.Id) {
        price.IsDirectLinkToInventoryProduct = res.IsDirectLink;
      }
    });
  }

  getProductSizesWithPrice = (event) => {
    if(this.salesProductId && this.salesProductPrice) {
    this.salesProductService.getProductSizesWithPrice(this.salesProductId)
      .subscribe({
        next: (res: Array<SalesProductSize>) => {
          this.salesProductPrice?.updateCoasts(res);
        },
      })
    }
  }

  setDefaultPrice(categoryId: number) {
    const category = _.find(this.categoryList, (cat) => {
      return cat.id === categoryId;
    });
    if (category) {
      this.defaultPriceId = category.DefaultSizeId;
    }
  }

  setPrepSettingVisibility() {
    _.forEach(this.settingParams.MakeTableWorkflowStates, (makeTableWorkflowState) => {
      if (makeTableWorkflowState.Code === DomainConstants.MakeTableStates.COOKING
        && makeTableWorkflowState.IsSelected) {
        this.isMakeTableCookingState = true;
      }
      if (makeTableWorkflowState.Code === DomainConstants.MakeTableStates.CRAFTING
        && makeTableWorkflowState.IsSelected) {
        this.isMakeTableCraftingState = true;
      }
      if (makeTableWorkflowState.Code === DomainConstants.MakeTableStates.RETRIEVING_INGREDIENTS
        && makeTableWorkflowState.IsSelected) {
        this.isMakeTableRetrievingIngredientsState = true;
      }
      if (makeTableWorkflowState.Code === DomainConstants.MakeTableStates.READY && makeTableWorkflowState.IsSelected) {
        this.isMakeTableReadyState = true;
      }
    });
  }

  onNoTagsChange() {
    this.selectedTags = [];
    this.salesProduct.SalesProductTags = [];
  }

  reloadSizes(categoryId: number) {
    this.eventBroadcastingService.reloadSize(categoryId);
  }

  productIngredient() {
    this.isDeletePrice = true;
  }

  deletePrice(event) {
    this.isDeletePrice = false;
    if (event.salesProductPrices) {
      this.salesProductPrices = event.salesProductPrices;
    }
  }

  getSalesCategory(categoryId: number) {
    this.spinnerService.show();
    this.salesCategoryService.getSalesCategories(true)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({ next: (res) => {
        if (res) {
          this.categoryList = res;
          if (categoryId && !this.salesProduct.CategoryId) {
            this.salesProduct.CategoryId = categoryId;
          }
          this.setDefaultPrice(this.salesProduct.CategoryId);
          this.reloadSizes(this.salesProduct.CategoryId);

        }
      }, error: this.alertService.showApiError });
  }

  addNewCategory(categoryId: number) {
    const salesCategoryModalRef = this.modalService.show(SalesCategoryEditModelComponent, {
      animated: false,
      class: 'vertical-center modal-max-width-65',
      initialState: {
        salesCategoryId: categoryId,
        isPopup: true
      }
    });
    salesCategoryModalRef.close.subscribe(res => {
      if (res?.shouldReload) {
        this.getSalesCategory(res.salesCategory && res.salesCategory.id ? res.salesCategory.id : 0);
        this.getSalesProductKitchenPrintersForInfo(res.salesCategory && res.salesCategory.id ? res.salesCategory.id :
          this.salesProduct.CategoryId);
        // this.onSaveSalesGroup(res.salesGroup);
      }
    });
  }

  saveProductDetails(isValid: boolean) {
    if (!isValid) {
      return;
    }

    if (this.salesProduct.OrderFulfillments?.length > 1) {
      const selectedFulfillments = this.orderFulfillmentType.filter(x => _.includes(this.salesProduct.OrderFulfillments, x.Value));
      const groupedFulfillment = _.groupBy(selectedFulfillments, "DeliveryType");
      if (_.find(groupedFulfillment, x => x.length > 1)) {
        this.alertService.renderErrorMessage(Messages.OrderFulfillmentValidation)
        return;
      }
    }

    let isDeleteRecipe = false;
    if (this.salesProduct.Increment % 1 !== 0) {
      this.spinnerService.show();
      this.salesProductService.getProductsInventoryConsumptionWithAllSizes(this.salesProductId)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe(res => {
          this.productIngredientConsumption = res;
          _.forEach(this.productIngredientConsumption, (productIngredient) => {
            const sizeDetails = _.find(this.salesProductPrices, (price) => {
              return price.SizeId === productIngredient[0].SizeId;
            });
            const activeProduct = _.filter(this.productIngredientConsumption, (ingredient) => {
              return !ingredient.DateExpired;
            });
            if (sizeDetails && !sizeDetails.IsDirectLinkToInventoryProduct &&
              (activeProduct.length > 1 || (activeProduct.length === 1 && activeProduct[0].qty !== 1))) {
              isDeleteRecipe = true;
            }
          });
          if (isDeleteRecipe) {
            this.openConfirmDeleteRecipeModal();
          } else {
            this.save();
          }
        });
    } else {
      this.save();
    }
  }

  openConfirmDeleteRecipeModal() {
    const modal = this.modalService.getModalWrapper(ConfirmDeleteModalComponent);
    const modalRef = modal.show({
      animated: false,
      keyboard: false,
      class: 'vertical-center',
      initialState: {
        message: Messages.ConfirmDeleteAllRecipeFromSalesProduct,
      }
    });
    modalRef.close.subscribe(res => {
      if (res && res.shouldDelete) {
        this.updateIsDirectLinkProductStatus();
      }
    });
  }

  save() {
    if (this.IsActiveProduct !== this.salesProduct.Active && this.salesProductId > 0) {
      this.getSalesInventoryProducts(this.salesProductId);
    } else {
      this.saveProduct();
    }
  }

  updateIsDirectLinkProductStatus() {
    this.spinnerService.show();
    this.salesProductService.updateDirectLinkProductStatusAllSizes(this.salesProductId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({ next: () => {
        this.save();
      }, error: this.alertService.showApiError });
  }

  checkDefaultQuantity() {
    if (this.salesProduct.DefaultQty && this.salesProduct.Increment) {
      this.checkDefaultQty = this.mathsUtilityService.floatSafeModulus(parseFloat(this.salesProduct.DefaultQty.toString()),
        parseFloat(this.salesProduct.Increment.toString())) !== 0;
    }
  }

  getSalesInventoryProducts(salesProductId: number) {
    this.spinnerService.show();
    this.salesProductService.getSalesInventoryProducts(salesProductId, this.IsActiveProduct)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({ next: (res) => {
        if (res) {
          this.inventoryProducts = res;
          if (this.inventoryProducts && this.inventoryProducts.length) {
            this.openActivateInventoryProductModal();
          } else {
            this.saveProduct();
          }
        } else {
          this.saveProduct();
        }
      }, error: this.alertService.showApiError });
  }

  openActivateInventoryProductModal() {
    const salesInventoryActivationModalRef = this.modalService.show(SalesInventoryActivationComponent, {
      animated: false,
      class: 'vertical-center modal-max-width-65',
      initialState: {
        inventoryProducts: this.inventoryProducts,
        salesProductId: this.salesProductId,
        salesProductName: this.salesProductName,
        headerText: (this.IsActiveProduct ? 'Deactivate' : 'Activate') + ' Inventory Product(s)',
        activeProduct: this.IsActiveProduct
      }
    });
    salesInventoryActivationModalRef.close.subscribe(res => {
      if (res?.shouldSave) {
        this.saveProduct();
      }
    });
  }

  saveProduct() {
    this.salesProduct.ProductSizes = this.salesProductPrices;
    const category = _.find(this.categoryList, (cat) => {
      return cat.id === this.salesProduct.CategoryId;
    });

    if (category) {
      const salesSize = _.find(this.salesProduct.ProductSizes, (productSize) => {
        return productSize.SizeId === category.DefaultSizeId && productSize.Price != null;
      });

      if (!salesSize) {
        this.alertService.renderErrorMessage(StringUtils.format(Messages.ErrorWhileAddingSalesProduct, { 'size': category.DefaultSize }));
        return;
      }
    }
    this.spinnerService.show();
    this.salesProduct.KitchenPrinterList = this.selectedSalesProductKitchenPrinters;
    if (this.salesProduct.SalesTaxType == null) {
      this.salesProduct.SalesTaxType = '';
    }
    if (!this.salesProduct.IsTimedPrep) {
      this.salesProduct.IsUseWorkflowTimer = false;
      this.salesProduct.PrepTime = null;
      this.salesProduct.PrepButtonColor = null;
    }
    this.salesProduct.SalesProductTags = [];
    this.selectedTags = this.tagSelection.getSelectedTags();
    forEach(this.selectedTags, (tag) => {
      this.salesProduct.SalesProductTags.push({
        SalesProductId: this.salesProductId,
        TagId: tag.Id
      });
    });

    if (this.salesProductId === 0) {
      this.salesProductService.addSalesProduct(this.salesProduct, this.image, this.productImage)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({ next: (res) => {
          this.alertService.renderSuccessMessage(Messages.SalesProductSaveSuccess);
          this.salesProduct = res;
          if (!this.isSaveAndContinue) {
            this.onCancel(true, res);
          } else {
            this.redirectToSalesProduct();
          }
        }, error: this.alertService.showApiError });
    } else {
      this.salesProductService.updateSaleProduct(this.salesProduct, this.image, this.productImage)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({ next: (res) => {
          this.alertService.renderSuccessMessage(Messages.SalesProductSaveSuccess);
          if (!this.isSaveAndContinue) {
            this.onCancel(true, res);
          } else {
            this.redirectToSalesProduct();
          }
        }, error: this.alertService.showApiError });
    }
  }

  redirectToSalesProduct() {
    this.isSaveAndContinue = false;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['sales-products', this.salesProduct.id], {
      state: {
        salesProductId: this.salesProduct.id,
        isScroll: false,
        salesProductName: this.salesProduct.Name
      },
      relativeTo: this.route.parent
    });
    this.router.onSameUrlNavigation = 'ignore';
  }

  reloadProductSizes(categoryId) {
    if (categoryId) {
      this.reloadSizes(categoryId);
      this.setDefaultPrice(categoryId);
      if (this.salesProductId) {
        $('divSalesProductSuccessErrorMsg').show();
        $('divSalesProductSuccessErrorMsg').html(Messages.ConfirmConfigurationChangedOfCategory);
        setTimeout(() => {
          $('#divSalesProductSuccessErrorMsg').fadeOut('slow');
        }, 20000);
      }
    }
  }

  onCancel(reload: boolean, salesProduct?: SalesProduct) {
    this.close.emit({ shouldReload: reload, salesProduct: salesProduct });
  }

  handleFileInput(files: FileList, isProductImage = false) {
    let fileToUpload = '';
    if (isProductImage) {
      this.productImage = files.item(0);
      fileToUpload = this.productImage ? this.productImage.name : '';
    } else {
      this.image = files.item(0);
      fileToUpload = this.image ? this.image.name : '';
    }
    if (this.extensionValidationService.isInvalidExtension(fileToUpload)) {
      if (fileToUpload) {
        this.alertService.renderErrorMessage(Messages.InvalidSalesProductImageFileType);
      }
      this.removeIcon(isProductImage);
      return;
    }

    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        isProductImage ? $('#productImagePreview').attr('src', reader.result) : $('#imagePreview').attr('src', reader.result);
      };
      reader.readAsDataURL(files[0]);
      isProductImage ? this.salesProduct.ProductImage = this.productImage.name : this.salesProduct.Image = this.image.name;
    }
  }


  removeIcon(isProductImage = false) {
    if (isProductImage) {
      this.salesProduct.ProductImage = null;
      this.productImage = null;
      $('#productImage').val(null);
    } else {
      this.salesProduct.Image = null;
      this.image = null;  
      $('#image').val(null);
    }
  }

  validateSalesProductEdit = (control: AbstractControl): ValidationErrors | null => {
    const defaultQty = control.get('defaultQty');
    const increment = control.get('increment');
    if(increment?.value && defaultQty?.value) {
      const checkDefaultQty = this.mathsUtilityService.floatSafeModulus(parseFloat(defaultQty.value), parseFloat(increment.value)) !== 0;
      if(checkDefaultQty) {
        defaultQty.setErrors({checkDefaultQty: true})
      } else {
        defaultQty.setErrors(null);
      }
    }
    return null;
  }
}

