<pos-edit-wrapper (close)="cancel()" [isPopup]="false" [headingIcon]="icons.cogs"
    [heading]="'Inventory Location Settings: ' + offsiteLocation.Name">
    <form #bulkUpdateForm="ngForm" (ngSubmit)="updateInventoryProductSettings(bulkUpdateForm.valid)" autocomplete="off">
        <div class="row mx-0 border-top pt-pix-5">
            <div class="col-12 pos-modal-body">
                <div class="cleanup-button">
                    <button type="button" class="btn btn-danger" (click)="confirmCleanupProducts()">Cleanup Products</button>
                </div>
                <div class="pb-pix-5">
                    <ng-table #gridComponent [paginator]="false" [columns]="columns"
                        [data]="offsiteLocationProductSettings" [scrollable]="true"
                        [scrollHeight]="'calc(100vh - 350px)'">
                    </ng-table>
                </div>
                <ng-template let-data="rowData" #minHeaderTemplate>
                    <div class="row g-0">
                        <div class="col-12 p-0 text-center">Min</div>
                        <hr class="hr-style">
                        <div class="col-5 p-0 min-col-70"> Purchase Units </div>
                        <div class="col-6 p-0 min-col-70"> Qty </div>
                    </div>
                </ng-template>
                <ng-template let-rowData="rowData" let-i="rowIndex" #minTemplate>
                    <div class="row g-0">
                        <div class="col-5 pe-2 min-col-70">
                            <input name="minPurchaseUnit{{rowData.InventoryProductId}}" #minPurchaseUnit="ngModel"
                                type="number" class="form-control text-right" [(ngModel)]="rowData.MinPurchaseUnit"
                                posDecimalRange
                                (ngModelChange)="rowData.Min = setDecimalValue(rowData.MinPurchaseUnit * rowData.InventoryProduct.UnitQty)" />
                            <span [ngClass]="{'has-error': (minPurchaseUnit.invalid || rowData.MinPurchaseUnit < 0)}"
                                *ngIf="rowData.MinPurchaseUnit < 0 && bulkUpdateForm.submitted">
                                <label class="control-label">Please enter positive value.</label>
                            </span>
                        </div>
                        <div class="col-5 pe-2 min-col-70">
                            <input name="min{{rowData.InventoryProductId}}" #min="ngModel" type="number"
                                class="form-control text-right" [(ngModel)]="rowData.Min" posDecimalRange
                                (ngModelChange)="rowData.MinPurchaseUnit = setDecimalValue(rowData.Min / rowData.InventoryProduct.UnitQty)" />
                            <span [ngClass]="{'has-error': (min.invalid || rowData.Min < 0)}"
                                *ngIf="rowData.Min < 0 && bulkUpdateForm.submitted">
                                <label class="control-label">Please enter positive value.</label>
                            </span>
                        </div>
                        <div class="col-2 p-0 unit-label min-col-50">{{ rowData.Min ?
                            pluralize(rowData.InventoryProduct.UnitName,
                            rowData.Min) : rowData.InventoryProduct.UnitName}}</div>
                    </div>
                </ng-template>
                <ng-template let-data="rowData" #maxHeaderTemplate>
                    <div class="row g-0">
                        <div class="col-12 p-0 text-center">Max</div>
                        <hr class="hr-style">
                        <div class="col-5 p-0 min-col-70"> Purchase Units </div>
                        <div class="col-6 p-0 min-col-70"> Qty </div>
                    </div>
                </ng-template>
                <ng-template let-rowData="rowData" let-i="rowIndex" #maxTemplate>
                    <div class="row g-0">
                        <div class="col-5 pe-2 min-col-70">
                            <input name="maxPurchaseUnit{{rowData.InventoryProductId}}" #maxPurchaseUnit="ngModel"
                                type="number" class="form-control text-right" [(ngModel)]="rowData.MaxPurchaseUnit"
                                posDecimalRange
                                (ngModelChange)="rowData.Max = setDecimalValue(rowData.MaxPurchaseUnit * rowData.InventoryProduct.UnitQty)" />
                            <span [ngClass]="{'has-error': (maxPurchaseUnit.invalid || rowData.MaxPurchaseUnit < 0)}"
                                *ngIf="rowData.MaxPurchaseUnit < 0 && bulkUpdateForm.submitted">
                                <label class="control-label">Please enter positive value.</label>
                            </span>
                        </div>
                        <div class="col-5 pe-2 min-col-70">
                            <input name="max{{rowData.InventoryProductId}}" #max="ngModel" type="number" posDecimalRange
                                class="form-control text-right" [(ngModel)]="rowData.Max" [min]="rowData.Min"
                                [required]="rowData.Min"
                                (ngModelChange)="rowData.MaxPurchaseUnit = setDecimalValue(rowData.Max / rowData.InventoryProduct.UnitQty)" />
                            <span [ngClass]="{'has-error': (max.invalid || rowData.Max < 0)}"
                                *ngIf="(max.invalid || rowData.Max < 0 || rowData.Max < rowData.Min) && bulkUpdateForm.submitted">
                                <label class="control-label" *ngIf="rowData.Min >= 0 && !rowData.Max">Please enter max
                                    value.</label>
                                <label class="control-label" *ngIf="rowData.Max < 0">Please enter positive
                                    value.</label>
                                <label class="control-label" *ngIf="rowData.Max > 0 && rowData.Max < rowData.Min">Max
                                    value
                                    should be greater than or equal to min value.</label>
                            </span>
                        </div>
                        <div class="col-2 p-0 min-col-50 unit-label">{{ rowData.Max ?
                            pluralize(rowData.InventoryProduct.UnitName,
                            rowData.Max) : rowData.InventoryProduct.UnitName}}</div>
                    </div>
                </ng-template>
            </div>
        </div>
        <div class="col-12 action-button-container page-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save</button>
            <button type="button" class="btn-secondary btn-lg btn btn-cancel pos-button"
                (click)="cancel()">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
