<div class="col-12 p-0 overflow-hidden"  *ngIf="!isLoading" [ngClass]="isInteractive? '' : 'radius-25'"
    [ngStyle]="{'color': config.color, 'background-color': config.bgColor, 'border':'solid 2px '+ (config.borderColor || config.bgColor || '#fff')}">
    <div class="col-12" [ngClass]="{'p-0': column.styleClass == 'col-md-3' && !isFullScreenMode}"> 
        <!-- pt-pix-5 pb-pix-5 ps-pix-15 -->
        <div class="col-12 p-0 overflow-hidden" style="height: 200px;">
            <highcharts-chart *ngIf="data" [Highcharts]="highcharts" [options]="chartOptions">
            </highcharts-chart>
            <div *ngIf="!data" class="text-center pt-pix-10 grey">
                No data available
            </div>
        </div>
        <div class="col-12 text-center font-20" [ngStyle]="{'color': config.color}">
            {{config.metricsName}}
        </div>
    </div>
</div>
<div *ngIf="isLoading" class="col-12 p-0 full-height align-vertical-center"
    [ngClass]="isInteractive? '' : 'radius-25'"
    [ngStyle]="{'color':config.color,'background-color':config.bgColor,'border':'solid 2px '+(config.borderColor || config.bgColor || '#fff')}">
    <div class="col-12 ps-pix-15 pe-pix-15 pt-pix-5 pb-pix-5">
        <pos-widget-loading></pos-widget-loading>
    </div>
</div>
