<pos-edit-wrapper heading="{{tableTerm}} Selection" (close)="onClose()">
  <form #formUnit="ngForm">
    <div class="overflow-hidden">
      <div class="col-12 pos-modal-body pt-pix-10">
        <div class="col-lg-12 col-12 ps-0 pb-pix-10 display-flex"><div class="pe-pix-5 mt-pix-n1"><pos-icon [icon]="icons.fieldInfoCircle"></pos-icon></div>
        <div>{{settingParam?.AccountTerm ? settingParam.AccountTerm : 'Account'}}s with active orders are shown with dashed borders.
        </div></div>
        <ul class="nav nav-tabs inner-tab pos-nav-tabs">
          <li class="" *ngFor="let layout of layouts; let layoutIndex = index;"
            [ngClass]="{'active': layoutIndex === activeLayoutIndex }">
            <a class="blue" (click)="activeLayoutIndex = layoutIndex">
              <span>{{layout.Name}}</span></a>
          </li>
        </ul>
        <div id="mainContainer" class="tab-content relative overflow-auto screen-scroll" [ngStyle]="{'height': getHeight() + 'px'}">
          <div [ngClass]="{'in active': layoutIndex == 0, 'blank-layout': layout.isBlank}"
            *ngFor="let layout of layouts; let layoutIndex = index;">
            <div *ngIf="activeLayoutIndex == layoutIndex && !layout.isBlank"
              class="col-12">
              <div #container></div>
              <pos-layout-designer #layoutDesign [container]="container" [graphXML]="layout.LayoutDefinition" (shapeClick)="selectTable($event)" [canvasHeight]="height" [canvasWidth]="width"
                [data]="layout" [readOnly]="true" [oneActiveOrderPerAccount]="oneActiveOrderPerAccount" [isShowOccupiedTable]="isShowOccupiedTable">
              </pos-layout-designer>
            </div>
          </div>
        </div>
      </div>

      <div class="clearfix"></div>
      <div class="form-horizontal action-button-container popup-footer">
        <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" value="Cancel" (click)="onClose()">Cancel</button>
      </div>
    </div>
  </form>
</pos-edit-wrapper>
