import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { GeneralStyle } from 'src/app/menu-display/interfaces';
import { MenuWidgetService } from 'src/app/menu-display/services';

@Component({
  selector: 'pos-general-style-config',
  templateUrl: './general-style-config.component.html',
})
export class GeneralStyleConfigComponent implements OnChanges {
  @Input() generalStyle: GeneralStyle;
  @Output() generalStyleChange = new EventEmitter<GeneralStyle>();
  @Input() prefix: string;

  constructor(protected menuWidgetService: MenuWidgetService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('generalStyle') && !this.generalStyle) {
      this.generalStyle = this.menuWidgetService.getNewFontStyleConfig();
    }
    this.generalStyleChange.emit(this.generalStyle);
  }

}
