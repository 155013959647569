import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MenuActions } from 'src/app/orders';
import { MenuOptions, OrderNavigationButton, ScreenButtons, UIButton } from 'src/app/orders/interface';
import { MenuResolveFactoryService } from 'src/app/orders/services/menu-resolve-factory.service';
import { AlertsService, ApplicationStateService, Configurations, DomainConstants, Messages, SettingParam } from 'src/app/shared';
import { cog } from 'src/app/shared/components/icon';
import { RuntimeConstants } from 'src/app/shared/constants';
declare let $: any;

@Component({
  selector: 'pos-menu-explorer-button',
  templateUrl: './menu-explorer-button.component.html',
  styleUrls: ['./menu-explorer-button.component.scss']
})
export class MenuExplorerButtonComponent implements OnInit, OnChanges {

  @Input() button: OrderNavigationButton;
  @Input() uiConfiguration: UIButton;
  @Input() designMode: boolean;
  @Input() screenButtons: Array<ScreenButtons> = [];
  @Output() reloadNavigation = new EventEmitter<boolean>();
  @Output() buttonClick: EventEmitter<any> = new EventEmitter();
  imageRootPath = RuntimeConstants.IMAGE_BASE_PATH + '/buttons';
  settingParam: SettingParam;
  height: number;
  buttonMenuOptions = [];
  buttonFunctions = DomainConstants.ButtonFunctionTypes;
  icons = {
    cog
  };
  buttonMenuOptionsConstants = DomainConstants.ButtonMenu;
  appStateConfigurations: Configurations;
  menuActions = MenuActions;
  constructor(private applicationStateService: ApplicationStateService,
    protected menuComponentResolveFactoryService: MenuResolveFactoryService,
    private alertService: AlertsService) { }
  ngOnInit(): void {
    this.settingParam = this.applicationStateService.settingParam;
    this.height = Number(this.uiConfiguration.height) - 15;
    this.buttonMenuOptions = this.menuComponentResolveFactoryService.getButtonMenu();
    this.appStateConfigurations = this.applicationStateService.configurations;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.height = Number(this.uiConfiguration.height) - 15;
  }

  buttonClicked() {
    this.buttonClick.emit();
  }

  public openMenuConfig(menuOption: MenuOptions): void {
    this.menuComponentResolveFactoryService.menuActionExecutor(menuOption, this.button, null, this.screenButtons);
    const menuSubscription = this.menuComponentResolveFactoryService.modalObserver$.subscribe((res) => {
      menuSubscription.unsubscribe();
      if (res && res.reload) {

        if (menuOption.Menu === DomainConstants.ButtonMenu.DuplicateButtonOnScreen) {
          this.alertService.renderInformationalMessage(Messages.ReplicateButtonCreated);
        }
        if (res.reload) {
          this.reloadNavigation.emit(true);
        }

      }
    });
  }

  hideScreenSetupMenu() {
    $('.custom-order').css('display', 'none');
  }

}
