import { Directive, ElementRef, AfterViewInit, Input } from '@angular/core';

@Directive({
    selector: '[autoFocus]'
})
export class AutoFocusDirective implements AfterViewInit {
    @Input() autoFocus = true;
    constructor (private _elRef: ElementRef) {}

    ngAfterViewInit()
    {
        if(this.autoFocus) {
            window.setTimeout(() =>
            {
                this._elRef.nativeElement.focus();
            });
        }
    }
}
