import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
  BaseCrudModelComponent, Messages, DomainConstants, RuntimeConstants, ModalService, ConfirmDeleteModalComponent, ButtonBehavior,
  StringUtils, InventoryProductDetails, ButtonFunctionType, InfoModalComponent, Permissions, Levels, Promotion
} from 'src/app/shared';
import { Button, ButtonFunctionParametersModel } from '../../interface';
import { ButtonService } from 'src/app/shared/services/button.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { finalize, map } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { ScreenService } from 'src/app/shared/services/screen.service';
import { DietaryWarning } from 'src/app/information-management/dietary-warnings';
import { ButtonFunctionService } from 'src/app/shared/services/button-function.service';
import { SalesProductsService } from 'src/app/information-management/sales-products';
import * as _ from 'lodash';
import { NgForm } from '@angular/forms';
import {
  infoCircle, th, box, plusWhite, editWhiteWithText, editWhite, deleteWhite, buttonBehaviorPlus,
  calendar, times
} from 'src/app/shared/components/icon';
import { KeyValue } from '@angular/common';
import { ApplicationStateService, FormUtilityService, InventoryProductDietaryWarningService, InventoryProductService, MultiProductGroupService, PromotionService, SalesSizeService } from 'src/app/shared/services';
import { SalesProductEditModelComponent } from 'src/app/information-management/sales-products/component/sales-product-edit-model';
import { GridColumn, TarkCurrencyPipe, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { find, forEach, orderBy, sortBy, startCase } from 'lodash';
import { ButtonBehaviorService } from '../../service/button-behavior.service';
import { RevenueCenter, RevenueCenterService } from 'src/app/configurator';
import { ExtensionValidationService } from 'src/app/shared/services/extension-validation.service';
import { OrderNavigationButton } from 'src/app/orders/interface/order-navigation-button';
import { InventorySubCategoryService, InventorySubcategory } from 'src/app/information-management/inventory-subcategories';

declare let $: any;

@Component({
  selector: 'pos-button-edit',
  templateUrl: './button-edit.component.html',
  styleUrls: ['./button-edit.component.scss', '../../../../../styles/pages/design/button-edit.scss'],
})
export class ButtonEditComponent extends BaseCrudModelComponent<Button> implements OnInit {

  icons = {
    infoCircle,
    th,
    box,
    plusWhite,
    editWhiteWithText,
    editWhite, deleteWhite, buttonBehaviorPlus, calendar, times
  };
  tabList = {
    Details: 'Details',
    Design: 'Design',
    Scheduling: 'Scheduling',
    Behaviors: 'Behaviors'
  };
  selectedTab = this.tabList.Details;
  permission = {
    salesProduct: Permissions.SalesProducts,
    editLevel: Levels.Edit,
  };
  @Input('id') id: number = 0;
  @Input('screenId') screenId: number = 0;
  @Input() selectedScreen: number = null;
  @Input() screenButtons: Array<OrderNavigationButton>;
  @Input('selectedScreenForAddButton') selectedScreenForAddButton: number = 0;
  @Input('buttonName') buttonName: string = '';
  @Input('isCopyButton') isCopyButton: boolean = false;
  @Output() onClose = new EventEmitter();
  @Output() onSave = new EventEmitter();
  @ViewChild('formButton') formButton: NgForm;
  screenToggleButtons: Array<OrderNavigationButton> = [];
  formGroups = {
    'Details': { '$valid': true },
    'Scheduling': { '$valid': true }
  };
  daysOfWeek = [];
  hasFunctionParameters: boolean = false;
  get getForm(): NgForm {
    return this.formButton
  }
  imagePath = RuntimeConstants.IMAGE_BASE_PATH + '/buttons';
  button: Button;
  buttonFunctions: any;
  activeScreenList: any = [];
  dietaryWarnings: DietaryWarning[] = [];
  salesSizes: any = [];
  dateCheckBox: boolean = false;
  timeCheckBox: boolean = false;
  dateFrom: Date;
  minDate: Date;
  dateTo: Date;
  startTime: any;
  endTime: any;
  selectedProducts: number;
  functionParameter: any = {};
  selectedExternalApi: any;
  selectedExternalApiParameters: any;
  salesProductList: Array<any> = [];
  selectedFunction: any = null;
  manualValueForSpecialFunction: string;
  requiredManualValueForSpecialFunction: string;
  codeNames: Array<any> = [];
  image: File;
  isLoadCallApi: boolean = false;
  externalApiParameters: Array<any> = [];
  showIcon = false;
  buttonFunctionType = DomainConstants.ButtonFunctionTypes;
  taxTypes = DomainConstants.TaxType;
  daysCheckBox: boolean = false;
  buttonTypes: Array<String> = [];
  multiProductGroups = [];
  printToKitchenMode = [
    { id: 1, name: 'Enabled' },
    { id: 0, name: 'Disabled' },
    { id: 2, name: 'Default' }
  ];
  isTaxSelected = false;
  revenueCenters: Array<RevenueCenter> = [];
  inventorySubcategories: Array<InventorySubcategory> = [];
  promotions: Array<Promotion> = [];
  buttonBehaviors: Array<ButtonBehavior> = [];
  behaviorColumns: Array<GridColumn> = [];
  behaviorTypes = [];
  buttonBehaviorTypes = DomainConstants.ButtonBehaviors;
  selectedButtonBehaviorType = null;
  loadEdit = false;
  buttonBehavior: ButtonBehavior = null;

  buttonList = [];
  activeInventoryProducts: Array<InventoryProductDetails> = [];
  isSaveAndContinue = false;
  pressApplyToProceed = Messages.PressApplyButtonToProceed;
  isBehaviorChanged = false;
  dateFormat = 'mm-dd-yyyy';
  currencySymbol = null;

  @ViewChild('operationTemplate', { static: true }) operationTemplate: TemplateRef<any>;
  @ViewChild('headerTemplate', { static: true }) headerTemplate: TemplateRef<any>;
  @ViewChild('behaviorTemplate', { static: true }) behaviorTemplate: TemplateRef<any>;

  constructor(protected buttonService: ButtonService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    private screenService: ScreenService,
    private inventoryProductDietaryWarningService: InventoryProductDietaryWarningService,
    private buttonFunctionService: ButtonFunctionService,
    private buttonBehaviorService: ButtonBehaviorService,
    private inventoryProductService: InventoryProductService,
    private inventorySubcategoryService: InventorySubCategoryService,
    private salesProductService: SalesProductsService,
    private revenueCenterService: RevenueCenterService,
    public bsModalRef: BsModalRef,
    private salesSizesService: SalesSizeService,
    private modalService: ModalService,
    private applicationStateService: ApplicationStateService,
    private extensionValidation: ExtensionValidationService,
    private promotionService: PromotionService,
    private currencyPipe: TarkCurrencyPipe,
    private multiProductGroupService: MultiProductGroupService,
    formUtilityService: FormUtilityService
  ) {
    super(buttonService, alertService, spinnerService, formUtilityService);
  }

  ngOnInit() {
    this.button = this.buttonService.newButton();
    this.minDate = new Date();
    this.saveSuccessMessage = Messages.ButtonSaveSuccess;
    this.functionParameter.TaxType = 'PreTax';
    this.dateFormat = this.applicationStateService.settingParam.PCalendarDateFormat;
    this.dataSubscription();
    this.prepareGridColumn();
    this.generateBehaviorType();
    this.screenToggleButtons = this.screenButtons?.filter(x => x.Toggle &&
      (x.ButtonType == DomainConstants.ButtonTypes.DetailEntry || x.ButtonType == DomainConstants.ButtonTypes.MainProduct));
    if (this.screenToggleButtons) {
      this.screenToggleButtons = sortBy(this.screenToggleButtons, 'ButtonText');
    }
    this.currencySymbol = this.applicationStateService.settingParam.CurrencySymbol;
    this.prepareWeekDays();
  }

  prepareWeekDays() {
    const weekStart = find(DomainConstants.Weeks, (day) => day.Value === this.applicationStateService.settingParam.WeekStart);
    let daysOfWeek = [];
    forEach(DomainConstants.Weeks, (x) => {
      daysOfWeek.push({ Id: x.Id + 1, Name: x.Abbreviation, IsChecked: false });
    });
    daysOfWeek = orderBy(daysOfWeek, 'Id');
    this.daysOfWeek = [...daysOfWeek.slice(weekStart.Id, daysOfWeek.length), ...daysOfWeek.slice(0, weekStart.Id)];
  }

  private dataSubscription(): void {
    if (this.id > 0) {
      this.spinnerService.show();
      this.loadData().pipe(finalize(() => {
        this.spinnerService.hide();
      }))
        .subscribe({
          next: (res) => {
            this.button = res;
            if (this.isCopyButton) {
              this.button.Selection += ' (Copy)';
              this.button.CodeNames = '';
              this.button.Id = 0;
              this.button.screen_id = this.screenId;
              this.button.next_screen_id = this.selectedScreen;
            }
            this.loadFunctions(this.button.ButtonType);
            this.loadDataCompleted();
          }, error: this.alertService.showApiError
        });
    } else {
      this.button = this.buttonService.newButton();
      if (this.selectedScreenForAddButton != 0) {
        this.button.screen_id = this.selectedScreenForAddButton;
      }
      this.button.next_screen_id = this.selectedScreen;
    }
    _.forEach(DomainConstants.ButtonTypes, item => {
      this.buttonTypes.push(_.startCase(item));
    });
  }

  // Need to Override this method b'cz we need to call with different api end point (need to pass buttonId and also screenId)
  public loadData(): Observable<Button> {
    return this.buttonService.getButton(this.id, this.screenId ? this.screenId : 0);
  }

  prepareGridColumn() {
    const operationColumn = new TemplateColumn({
      itemTemplate: this.operationTemplate,
      Width: '102px',
      headerTemplate: this.headerTemplate,
      TextAlign: TextAlign.Center,
      CellClass: 'cell-padding'
    });

    const behaviorColumn = new TemplateColumn({
      HeaderText: 'Behavior',
      itemTemplate: this.behaviorTemplate,
      Width: '24%',
    });

    this.behaviorColumns = [
      behaviorColumn,
      new GridColumn({ HeaderText: 'Values', Field: 'DisplayValue', IsSortable: true, Width: '65%' }),
      operationColumn
    ];
  }

  originalOrder = (firstKey: KeyValue<number, string>, secondKey: KeyValue<number, string>): number => {
    return 0;
  }

  private loadDataCompleted() {
    this.dateCheckBox = false;
    this.timeCheckBox = false;
    if (this.button.ScheduleStartDate != null && this.button.ScheduleEndDate != null) {
      this.dateCheckBox = true;
      this.dateFrom = new Date(this.button.ScheduleStartDate);
      this.dateTo = new Date(this.button.ScheduleEndDate);
    }
    if (this.button.ScheduleStartTime != null) {
      this.timeCheckBox = true;
    }
    this.setContextual();

    if (this.button.Image === '' || this.button.Image == null) {
      this.showIcon = false;
    } else {
      this.showIcon = true;
    }
    this.button.OldImage = _.cloneDeep(this.button.Image);

    this.selectedProducts = this.button.ProductID === -1 ? null : this.button.ProductID;
    const self = this;
    const selectedButtonFunction = _.find(this.buttonFunctions, function (buttonFunction) {
      return buttonFunction.name === self.button.SpecialFunction;
    });
    if (selectedButtonFunction) {
      this.selectedFunction = selectedButtonFunction.code;
    }
    this.changeSpecialFunction();
    if (this.button.CodeNames != '') {
      this.button.CodeNames = this.button.CodeNames.substring(0, this.button.CodeNames.length - 1);
      this.codeNames = this.button.CodeNames ? this.button.CodeNames.split(',') : [];
    }
    if (this.screenId) {
      this.button.screen_id = this.screenId;
    }
  }

  public loadDependencies(): void {
    if (this.id >= 0) {
      this.spinnerService.show();
      const buttonObservables: Observable<any>[] = [];
      this.buttonFunctions = orderBy(this.buttonFunctionService.getButtonFunctions(), 'name');
      buttonObservables.push(this.screenService.getActiveScreens(0));
      buttonObservables.push(this.inventoryProductDietaryWarningService.getDietaryWarnings());
      buttonObservables.push(this.salesSizesService.getSalesProductSizes(0));
      buttonObservables.push(this.buttonService.getButtonFunctionParameters(this.id));
      buttonObservables.push(this.salesProductService.getAllSalesProducts());
      buttonObservables.push(this.inventoryProductService.getActiveInventoryProducts(true));
      buttonObservables.push(this.buttonService.getButtonListWithScreenName());
      buttonObservables.push(this.revenueCenterService.getAll());
      buttonObservables.push(this.inventorySubcategoryService.getInventorySubcategoryList());
      buttonObservables.push(this.promotionService.getAll());
      buttonObservables.push(this.multiProductGroupService.getAll());
      forkJoin(buttonObservables)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (responses: any) => {
            if (responses) {
              this.isLoadCallApi = true;
              this.activeScreenList = responses[0] ? responses[0] : [];
              this.dietaryWarnings = responses[1] ? responses[1] : [];
              this.dietaryWarnings.forEach(x => {
                x.Name = x.Name.charAt(0).toUpperCase() + x.Name.substring(1);
              });
              this.salesSizes = responses[2] ? responses[2] : [];
              if (responses[3]) {
                this.getButtonFunctionParametersCompleted(responses[3]);
              }
              if (responses[4]) {
                this.salesProductList = _.filter(responses[4], (product) => {
                  return ((product.Active && product.id !== -1) || product.id === this.button.ProductID);
                });
              }
              this.activeInventoryProducts = responses[5] ? responses[5] : [];
              this.buttonList = responses[6] ? responses[6].filter(x => x.Toggle &&
                (x.ButtonType == DomainConstants.ButtonTypes.DetailEntry || x.ButtonType == DomainConstants.ButtonTypes.MainProduct)) : [];
              if (this.buttonList) {
                this.buttonList.forEach((data) => {
                  data.ButtonScreen = data.ScreenName && data.ScreenName != '' ? data.ButtonText + ' - (' + data.ScreenName + ')' : data.ButtonText;
                });
              }
              this.revenueCenters = responses[7] ? responses[7] : [];
              this.inventorySubcategories = responses[8] ? _.orderBy(responses[8], 'InventoryCategoryName') : [];
              this.promotions = responses[9] ? responses[9] : [];
              this.multiProductGroups = responses[10] ? responses[10] : [];
            }
            this.getButtonBehaviors();
          }, error: this.alertService.showApiError
        });
    }
  }

  getButtonBehaviors() {
    this.spinnerService.show();
    this.buttonBehaviorService.getAllButtonBehaviors(this.id)
      .pipe(map((data) => {
        data.forEach(x => x['Icon'] = this.behaviorTypes.find(y => y.Value === x.BehaviorName)?.Icon);
        return data;
      }), finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.buttonBehaviors = res ? res : [];
          this.setDisplayValues();
        }, error: this.alertService.showApiError
      });
  }

  setDisplayValues() {
    forEach(this.buttonBehaviors, (behavior) => {
      const behaviorConstant = find(this.buttonBehaviorTypes, x => x.Value === behavior.BehaviorName);
      if (behaviorConstant) {
        behavior.DisplayName = behaviorConstant.Text;
      }
      try {
        const behaviorValues = JSON.parse(behavior.TextValue);
        if (behavior.BehaviorName === this.buttonBehaviorTypes.DisplayMessage.Value ||
          behavior.BehaviorName === this.buttonBehaviorTypes.DisplayToaster.Value) {
          behavior.DisplayValue = behaviorValues.message;
        } else if (behavior.BehaviorName === this.buttonBehaviorTypes.FlashButtons.Value) {
          behavior.DisplayValue = this.getButtonValues(behaviorValues.buttonIds) + '; Seconds: ' + behaviorValues.seconds;
        } else if (behavior.BehaviorName === this.buttonBehaviorTypes.SelectButtons.Value ||
          behavior.BehaviorName === this.buttonBehaviorTypes.UncheckButtons.Value) {
          behavior.DisplayValue = this.getButtonValues(behaviorValues.buttonIds);
        } else if (behavior.BehaviorName === this.buttonBehaviorTypes.TemporaryNav.Value) {
          behavior.DisplayValue = find(this.activeScreenList, x => x.Id === behaviorValues.screenId).Title;
        } else if (behavior.BehaviorName === this.buttonBehaviorTypes.IncrementInventory.Value ||
          behavior.BehaviorName === this.buttonBehaviorTypes.DecrementInventory.Value) {
          behavior.DisplayValue = this.getInventoryProductValues(behaviorValues.inventoryProductId) + '; Qty: ' + behaviorValues.qty;
        } else if (behavior.BehaviorName === this.buttonBehaviorTypes.SingularFee.Value) {
          behavior.DisplayValue =
            'Name: ' + behaviorValues.name +
            '; Taxable: ' + behaviorValues.isTaxable +
            '; Amount: ' + this.currencyPipe.transform(behaviorValues.amount) +
            '; Fee Type: ' + startCase(behaviorValues.feeType);
        } else if (behavior.BehaviorName === this.buttonBehaviorTypes.MultipleProduct.Value) {
          behavior.DisplayValue = 'Group: ' + this.getGroupValue(behaviorValues.groupId) + ';';
          if (behaviorValues.promotionId) {
            behavior.DisplayValue += ' Promotion: ' + this.getPromotionValues(behaviorValues.promotionId);
          }
        }
      } catch (ex) {
        console.error('Invalid ' + this.buttonBehavior.BehaviorName + ' behavior value: ' + behavior.TextValue);
      }
    });
  }

  getPromotionValues(promotionId) {
    const promotion = find(this.promotions, x => x.Id === promotionId);
    if (promotion) {
      return promotion.Name;
    }
    return '';
  }
  getGroupValue(groupId) {
    const multiProductGroup = find(this.multiProductGroups, x => x.Id === groupId);
    return multiProductGroup ? multiProductGroup.Name : '';
  }

  getInventoryProductValues(productId) {
    const product = find(this.activeInventoryProducts, x => x.Id === productId);
    if (product) {
      return product.Name;
    }
    return '';
  }

  getButtonValues(buttonIds) {
    let selectedButtonNames = '';
    forEach(buttonIds, (id) => {
      const button = find(this.buttonList, x => x.Id === id);
      selectedButtonNames += button.ButtonText + ', ';
    });
    return selectedButtonNames.substr(0, selectedButtonNames.length - 2);
  }

  public onSaveSuccess(model: Button): void {
    this.onSave.emit();
    if (this.isCopyButton || this.isSaveAndContinue) {
      this.id = model.Id;
      this.buttonName = model.Selection;
      this.button = model;
      this.isSaveAndContinue = false;
    } else {
      this.hide({ reload: true });
    }
  }

  getHeight() {
    if ($(window).height() - 275 > 645) {
      return 645;
    }
    return ($(window).height() - 275 > 500) ? 500 : $(window).height() - 275;
  }

  private setContextual(): void {
    if (this.button.DateRange) {
      this.daysCheckBox = true;
      const days = this.button.DateRange.split('');
      this.daysOfWeek.forEach(x => x.IsChecked = days.includes(x.Id.toString()));
    }
  }

  private getButtonFunctionParametersCompleted(parameters: Array<ButtonFunctionParametersModel>): void {
    _.forEach(parameters, (param) => {
      if (param.ButtonId === this.id) {
        this.setParameterValues(param);
      }
    })
  }

  setParameterValues(param) {
    switch (param.Key) {
      case this.buttonFunctionType.SET_SIZE.Code:
        this.functionParameter.Size = parseInt(param.Value, null);
        break;
      case this.buttonFunctionType.SET_DIETARY_RESTRICTION.Code:
        this.functionParameter.DietaryWarning = parseInt(param.Value, null);
        break;
      case this.buttonFunctionType.ADD_COMMENT_TO_ALL.Code:
        this.functionParameter.AddCommentToAll = param.Value;
        break;
      case this.buttonFunctionType.COMMENT_ENTRY.Code:
        this.functionParameter.CommentEntry = param.Value;
        break;
      case 'PercentDiscountType':
        this.functionParameter.Type = !param.Value ? 'Manual' : param.Value;
        break;
      case 'PercentMarkupType':
        this.functionParameter.Type = !param.Value ? 'Manual' : param.Value;
        break;
      case this.buttonFunctionType.NUMERIC_MARKUP.NumericMarkupType:
        this.functionParameter.Type = !param.Value ? 'Manual' : param.Value;
        break;
      case this.buttonFunctionType.PERCENT_DISCOUNT.Code:
        this.functionParameter.Percent = parseFloat(param.Value);
        break;
      case this.buttonFunctionType.GRATUITY_MARKUP_AUTO.Code:
        this.functionParameter.Percent = parseFloat(param.Value);
        break;
      case this.buttonFunctionType.PERCENT_MARKUP.Code:
        this.functionParameter.Percent = parseFloat(param.Value);
        break;
      case this.buttonFunctionType.NUMERIC_MARKUP.NumericMarkupAmount:
        this.functionParameter.Percent = parseFloat(param.Value);
        break;
      case this.buttonFunctionType.NUMERIC_MARKUP.Taxable:
        this.functionParameter.IsTaxable = !param.Value ? 'NonTaxable' : param.Value;
        break;
      case this.buttonFunctionType.CALL_API.Code:
        this.selectedExternalApi = parseFloat(param.Value);
        break;
      case 'TaxType':
        this.functionParameter.TaxType = param.Value;
        break;
      case 'GroupId':
        this.functionParameter.GroupId = param.Value;
        break;
      case 'Promotion':
        this.functionParameter.Promotion = parseInt(param.Value);
        break;
      case 'ExternalApiParams':
        if (param.Value) {
          this.selectedExternalApiParameters = JSON.parse(param.Value);
        }
        break;
      case this.buttonFunctionType.DEDUCT_INVENTORY_PRODUCT.InventorySubcategories:
        this.functionParameter.InventorySubcategories = param.Value.split(',').map(x => parseInt(x, 10));
        break;
      case this.buttonFunctionType.DEDUCT_INVENTORY_PRODUCT.MinPrice:
        this.functionParameter.MinPrice = param.Value;
        break;
      case this.buttonFunctionType.DEDUCT_INVENTORY_PRODUCT.MarginPercent:
        this.functionParameter.MarginPercent = param.Value;
        break;
    }
  }

  private loadFunctions(buttonType): void {
    this.buttonFunctions = [];
    this.buttonFunctions = _.filter(this.buttonFunctionService.getButtonFunctions(), function (item) {
      const functionType = _.find(item.allowedButtonType, function (type) { return type === buttonType; });
      return functionType === buttonType;
    });
    this.buttonFunctions = orderBy(this.buttonFunctions, 'name');
  }

  changeSpecialFunction() {
    this.functionParameter.IsTaxable = 'Taxable';
    this.hasFunctionParameters = true;
    if (this.selectedFunction === this.buttonFunctionType.PERCENT_DISCOUNT.Code) {
      this.manualValueForSpecialFunction = 'Discount';
    } else if (this.selectedFunction === this.buttonFunctionType.PERCENT_MARKUP.Code) {
      this.manualValueForSpecialFunction = 'Markup';
    } else if (this.selectedFunction === this.buttonFunctionType.GRATUITY_MARKUP_AUTO.Code) {
      this.manualValueForSpecialFunction = 'Gratuity';
    } else if (this.selectedFunction === this.buttonFunctionType.NUMERIC_MARKUP.Code) {
      this.manualValueForSpecialFunction = 'Amount';
    }
    else {
      this.functionParameter.Size = this.salesSizes && this.salesSizes.length > 0 ?
        parseInt(this.salesSizes[0].Id, null) : '';
      this.functionParameter.DietaryWarning = null;
      this.functionParameter.Type = null;
      this.manualValueForSpecialFunction = '';
      this.hasFunctionParameters = false;
    }
    const functionsWithParameters = [this.buttonFunctionType.ADD_COMMENT_TO_ALL.Code, this.buttonFunctionType.CALL_API.Code,
    this.buttonFunctionType.NUMERIC_DISCOUNT_MANUAL.Code, this.buttonFunctionType.DEDUCT_INVENTORY_PRODUCT.Code,
    this.buttonFunctionType.SET_DIETARY_RESTRICTION.Code, this.buttonFunctionType.SET_SIZE.Code,
    this.buttonFunctionType.COMMENT_ENTRY.Code];
    if (functionsWithParameters.includes(this.selectedFunction)) {
      this.hasFunctionParameters = true;
    }
    this.checkSingularFees();
    this.requiredManualValueForSpecialFunction = 'Please enter ' + this.manualValueForSpecialFunction + '.';
  }

  checkSingularFees() {
    const singularFees = this.buttonBehaviors.filter(x => x.BehaviorName == this.buttonBehaviorTypes.SingularFee.Value);
    if (singularFees.length) {
      let functionType: ButtonFunctionType = find(DomainConstants.ButtonFunctionTypes, x => x.Code == this.selectedFunction);
      const isSingularFeeApplicable = functionType?.IsSingularFeeApplicable ?? true;
      if (!isSingularFeeApplicable) {
        const modalRef = this.modalService.getModalWrapper(InfoModalComponent);
        modalRef.show({
          animated: false,
          class: 'vertical-center',
          initialState: {
            message: StringUtils.format(Messages.SingularFeeNotApplicable, { functionType: functionType.Name })
          }
        });
      }
    }
  }

  public changeButtonType(buttonType) {
    this.selectedFunction = null;
    this.loadFunctions(buttonType);
    if (buttonType === DomainConstants.ButtonTypes.NavigationOnly) {
      this.selectedFunction = null;
      this.selectedProducts = null;
    }
    if (buttonType === DomainConstants.ButtonTypes.Functional) {
      this.selectedProducts = null;
    }
    this.functionParameter.Type = null;

  }

  public copyButtonText(isOverwrite): void {
    if (isOverwrite) {
      this.button.ReceiptText = this.button.Selection;
      this.button.InternalText = this.button.Selection;
    } else {
      if (this.button.ReceiptText == null || this.button.ReceiptText === '') {
        this.button.ReceiptText = this.button.Selection;
      }
      if (this.button.InternalText == null || this.button.InternalText === '') {
        this.button.InternalText = this.button.Selection;
      }
    }
  }

  public toggleButtonChanged(): void {
    if (!this.button.Toggle) {
      this.button.GroupName = '';
      this.button.ButtonGroupMin = null;
      this.button.ButtonGroupMax = null;
    }
  }

  public handleFileInput(files: FileList) {
    this.image = files.item(0);
    const fileToUpload = this.image ? this.image.name : '';
    if (this.extensionValidation.isInvalidExtension(fileToUpload, ['.jpg', '.bmp', '.png', '.gif'])) {
      if (fileToUpload) {
        this.alertService.renderErrorMessage(Messages.InvalidReceiptLogoFileType);
      }
      this.removeIcon();
      return;
    }
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        $('#buttonImage').attr('src', reader.result);
      };
      reader.readAsDataURL(files[0]);
      this.button.Image = this.image ? this.image.name : '';
      this.showIcon = true;
    }
  }

  public removeIcon(): void {
    this.button.Image = '';
    $('#buttonImage').val('');
    $('#image').val(null);
    this.image = null;
    this.showIcon = false;
  }

  private isSetToProduct = function () {
    return this.selectedProducts !== ''
      && (this.button.ButtonType !== DomainConstants.ButtonTypes.NavigationOnly
        || (this.selectedFunction === this.buttonFunctionType.ADD_COMMENT.Code
          || this.selectedFunction === this.buttonFunctionType.COMMENT_ENTRY.Code));
  }

  setFunctionParams() {
    let buttonFunctionParameters = [];
    let functionParameter: any = {};
    let functionParameterPerCent: any = {};
    let functionParameterTax: any = {};
    switch (this.selectedFunction) {
      case this.buttonFunctionType.PERCENT_DISCOUNT.Code:
        functionParameter.Key = 'PercentDiscountType';
        functionParameter.Value = this.functionParameter.Type;
        buttonFunctionParameters.push(functionParameter);
        functionParameterTax.Key = 'TaxType';
        functionParameterTax.Value = this.functionParameter.TaxType;
        buttonFunctionParameters.push(functionParameterTax);
        if (this.functionParameter.Type == 'Auto') {
          functionParameterPerCent.Key = this.buttonFunctionType.PERCENT_DISCOUNT.Code;
          functionParameterPerCent.Value = this.functionParameter.Percent;
          buttonFunctionParameters.push(functionParameterPerCent);
        }
        break;
      case this.buttonFunctionType.PERCENT_MARKUP.Code:
        functionParameter.Key = 'PercentMarkupType';
        functionParameter.Value = this.functionParameter.Type;
        buttonFunctionParameters.push(functionParameter);
        if (this.functionParameter.Type == 'Auto') {
          functionParameterPerCent.Key = this.buttonFunctionType.PERCENT_MARKUP.Code;
          functionParameterPerCent.Value = this.functionParameter.Percent;
          buttonFunctionParameters.push(functionParameterPerCent);
        }
        break;
      case this.buttonFunctionType.GRATUITY_MARKUP_AUTO.Code:
        functionParameter.Key = this.buttonFunctionType.GRATUITY_MARKUP_AUTO.Code;
        functionParameter.Value = this.functionParameter.Percent;
        buttonFunctionParameters.push(functionParameter);
        break;
      case this.buttonFunctionType.SET_SIZE.Code:
        functionParameter.Key = this.buttonFunctionType.SET_SIZE.Code;
        functionParameter.Value = this.functionParameter.Size;
        buttonFunctionParameters.push(functionParameter);
        break;
      case this.buttonFunctionType.SET_DIETARY_RESTRICTION.Code:
        functionParameter.Key = this.buttonFunctionType.SET_DIETARY_RESTRICTION.Code;
        functionParameter.Value = this.functionParameter.DietaryWarning;
        buttonFunctionParameters.push(functionParameter);
        break;
      case this.buttonFunctionType.ADD_COMMENT_TO_ALL.Code:
        functionParameter.Key = this.buttonFunctionType.ADD_COMMENT_TO_ALL.Code;
        functionParameter.Value = this.functionParameter.AddCommentToAll;
        buttonFunctionParameters.push(functionParameter);
        break;
      case this.buttonFunctionType.COMMENT_ENTRY.Code:
        functionParameter.Key = this.buttonFunctionType.COMMENT_ENTRY.Code;
        functionParameter.Value = this.functionParameter.CommentEntry;
        buttonFunctionParameters.push(functionParameter);
        break;
      case this.buttonFunctionType.NUMERIC_MARKUP.Code:
        functionParameter.Key = this.buttonFunctionType.NUMERIC_MARKUP.Taxable;
        functionParameter.Value = this.functionParameter.IsTaxable;
        buttonFunctionParameters.push({ ...functionParameter });
        functionParameter.Key = this.buttonFunctionType.NUMERIC_MARKUP.NumericMarkupType;
        functionParameter.Value = this.functionParameter.Type;
        buttonFunctionParameters.push({ ...functionParameter });
        if (this.functionParameter.Type === 'Auto') {
          functionParameterPerCent.Key = this.buttonFunctionType.NUMERIC_MARKUP.NumericMarkupAmount;
          functionParameterPerCent.Value = this.functionParameter.Percent;
          buttonFunctionParameters.push(functionParameterPerCent);
        }
        break;
      case this.buttonFunctionType.CALL_API.Code:
        functionParameter.Key = this.buttonFunctionType.CALL_API.Code;
        functionParameter.Value = this.selectedExternalApi;
        buttonFunctionParameters.push(functionParameter);
        let apiParameters = [];
        _.forEach(this.externalApiParameters, function (param) {
          if (param.ParamValue) {
            apiParameters.push({ ParameterId: param.Id, Value: param.ParamValue });
          }
        });
        if (apiParameters?.length > 0) {
          let functionExternalApiParam: any = {};
          functionExternalApiParam.Key = 'ExternalApiParams';
          functionExternalApiParam.Value = JSON.stringify(apiParameters);
          buttonFunctionParameters.push(functionExternalApiParam);
        }
        break;
      case this.buttonFunctionType.NUMERIC_DISCOUNT_MANUAL.Code:
        functionParameterTax.Key = 'TaxType';
        functionParameterTax.Value = this.functionParameter.TaxType;
        buttonFunctionParameters.push(functionParameterTax);
        break;
      case this.buttonFunctionType.DEDUCT_INVENTORY_PRODUCT.Code:
        functionParameter.Key = this.buttonFunctionType.DEDUCT_INVENTORY_PRODUCT.InventorySubcategories;
        functionParameter.Value = this.functionParameter.InventorySubcategories?.length ? this.functionParameter.InventorySubcategories.join(',') : null;
        buttonFunctionParameters.push({ ...functionParameter });
        functionParameter.Key = this.buttonFunctionType.DEDUCT_INVENTORY_PRODUCT.MinPrice;
        functionParameter.Value = this.functionParameter.MinPrice;
        buttonFunctionParameters.push({ ...functionParameter });
        functionParameter.Key = this.buttonFunctionType.DEDUCT_INVENTORY_PRODUCT.MarginPercent;
        functionParameter.Value = this.functionParameter.MarginPercent;
        buttonFunctionParameters.push({ ...functionParameter });
        break;
      case this.buttonFunctionType.END_MULTIPLE_PRODUCTS.Code:
        functionParameter.Key = 'GroupId';
        functionParameter.Value = this.functionParameter.GroupId;
        functionParameter.Key = 'Promotion';
        functionParameter.Value = this.functionParameter.Promotion;
        buttonFunctionParameters.push(functionParameter);
        break;
    }
    return buttonFunctionParameters;
  }

  isValidForm() {
    if ((!this.selectedProducts && (this.button.ButtonType === 'MainProduct'))
      || (this.selectedFunction === this.buttonFunctionType.SET_DIETARY_RESTRICTION.Code &&
        !this.functionParameter.DietaryWarning)) {
      this.isSaveAndContinue = false;
      return false;
    }

    if ((this.selectedFunction === this.buttonFunctionType.PERCENT_DISCOUNT.Code ||
      this.selectedFunction === this.buttonFunctionType.NUMERIC_DISCOUNT_MANUAL.Code) && !this.functionParameter.TaxType) {
      this.alertService.renderErrorMessage(Messages.ErrorWhileTaxTypeNotSelected);
      this.isSaveAndContinue = false;
      return false;
    }
    if (this.button.ButtonGroupMin != null && this.button.ButtonGroupMax != null && this.button.ButtonGroupMin > this.button.ButtonGroupMax) {
      this.alertService.renderErrorMessage(Messages.ButtonMinimumMessage);
      this.isSaveAndContinue = false;
      return false;
    }

    if (this.dateCheckBox && this.dateFrom > this.dateTo) {
      this.alertService.renderErrorMessage(Messages.ErrorWhileFromDateGreater);
      this.isSaveAndContinue = false;
      return false;
    }
    if (this.timeCheckBox && this.button.ScheduleStartTime != null && this.button.ScheduleEndTime != null && this.button.ScheduleStartTime > this.button.ScheduleEndTime) {
      this.alertService.renderErrorMessage(Messages.ErrorWhileMinTimeIsGreater);
      this.isSaveAndContinue = false;
      return false;
    }
    if (this.selectedFunction == this.buttonFunctionType.CALL_API.Code && !this.selectedExternalApi) {
      this.alertService.renderErrorMessage(Messages.RequiredExternalApi);
      this.isSaveAndContinue = false;
      return;
    }
    return true;
  }

  public submit(isButtonFormValid: boolean): void {
    if (!isButtonFormValid || !this.isValidForm()) {
      return;
    }
    this.button.CodeNames = this.codeNames.join(',');
    this.button.ProductID = (this.isSetToProduct()) ? this.selectedProducts : null;
    if (this.button.ButtonType === DomainConstants.ButtonTypes.NavigationOnly) {
      this.selectedFunction = null;
    }

    this.setScheduleTime();

    let buttonFunctionParameters = this.setFunctionParams();
    this.setScheduleDate();
    this.button.DateRange = null;
    this.setScheduleDays();

    if (!this.button.Toggle) {
      this.button.IsDefaultChecked = false;
    }
    this.button.ButtonFunctionParameters = buttonFunctionParameters;
    const self = this;
    const selectedButtonFunction = _.find(this.buttonFunctions, function (buttonFunction) {
      return buttonFunction.code === self.selectedFunction;
    });
    this.button.SpecialFunction = selectedButtonFunction ? selectedButtonFunction.name : null;
    if (this.screenId && !this.button.Id) {
      this.button.screen_id = this.screenId;
    }
    this.isCopyButton = false;
    this.save(this.button, this.button.Image ? this.image : null, true);
  }

  setScheduleTime() {
    if (!this.timeCheckBox) {
      this.button.ScheduleStartTime = null;
      this.button.ScheduleEndTime = null;
    }
  }

  setScheduleDate() {
    if (this.dateCheckBox) {
      this.button.ScheduleStartDate = new Date(this.dateFrom);
      this.button.ScheduleEndDate = new Date(this.dateTo);
    } else {
      this.button.ScheduleStartDate = null;
      this.button.ScheduleEndDate = null;
    }
  }

  setScheduleDays() {
    if (this.daysCheckBox) {
      this.button.DateRange = this.daysOfWeek.filter(x => x.IsChecked).map(x => x.Id).join('');
    }
  }
  public cancelEditing(): void {
    if (this.isBehaviorChanged && this.screenId) {
      this.hide({ reload: true });
      this.buttonService.triggerMenuChange()
        .subscribe({ next: () => { }, error: this.alertService.showApiError });
    } else {
      this.hide({ reload: false });
    }
  }

  public externalApiParameterChange($event: any): void {
    this.externalApiParameters = $event;
  }

  public externalApiChange($event: any): void {
    this.selectedExternalApi = $event;
  }

  getSalesProduct() {
    this.spinnerService.show();
    this.salesProductService.getAllSalesProducts()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: responses => {
          if (responses) {
            this.salesProductList = _.filter(responses, (product) => {
              return ((product.Active && product.id !== -1) || product.id === this.button.ProductID);
            });
          }
        }, error: this.alertService.showApiError
      });
  }

  editSalesProduct(salesProductId: number) {
    const salesProductModalRef = this.modalService.show(SalesProductEditModelComponent, {
      animated: false,
      class: 'vertical-center modal-max-width-70',
      initialState: {
        isScroll: true,
        salesProductId: salesProductId,
        salesProductName: ''
      }
    });

    salesProductModalRef.close.subscribe(res => {
      if (res?.shouldReload) {
        if (res.salesProduct) {
          this.selectedProducts = res.salesProduct.id;
        }
        this.getSalesProduct();
      }
    });
  }

  generateBehaviorType = () => {
    this.behaviorTypes = [];
    forEach(this.buttonBehaviorTypes, (channels) => {
      this.behaviorTypes.push(channels)
    });
    this.behaviorTypes = orderBy(this.behaviorTypes, 'Text');
  }

  addBehavior = (behaviorType) => {
    const existingBehavior = find(this.buttonBehaviors, x => x.BehaviorName === behaviorType.Value
      && !behaviorType.IsAllowMultipleBehavior);
    if (!existingBehavior) {
      this.loadEdit = true;
      this.selectedButtonBehaviorType = behaviorType;
    } else {
      this.alertService.renderErrorMessage(StringUtils.format(Messages.ButtonBehaviorExist, { buttonBehavior: behaviorType.Text }));
    }
  }

  editBehavior = (data: ButtonBehavior) => {
    this.loadEdit = true;
    const behaviorType = find(this.buttonBehaviorTypes, x => x.Value === data.BehaviorName);
    this.selectedButtonBehaviorType = behaviorType;
    this.buttonBehavior = data;
  }

  gridRowReorder(event) {
    this.buttonBehaviorService.updateBehaviorOrdinal(this.buttonBehaviors)
      .subscribe({
        next: (res) => {
          this.buttonBehaviors = res ? res : [];
          this.buttonBehaviors.forEach(x => x['Icon'] = this.behaviorTypes.find(y => y.Value === x.BehaviorName)?.Icon);
          this.setDisplayValues();
          this.isBehaviorChanged = true;
        }, error: this.alertService.showApiError
      });
  }

  confirmDelete = (behavior: ButtonBehavior) => {
    const modalRef = this.modalService.getModalWrapper(ConfirmDeleteModalComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      initialState: {
        message: StringUtils.format(Messages.ConfirmDeleteButtonBehavior,
          { 'behaviorName': behavior.DisplayName })
      }
    });

    modal.close.subscribe(res => {
      if (res && res.shouldDelete) {
        this.deleteButtonBehavior(behavior);
      }
    });
  }

  deleteButtonBehavior = (behavior: ButtonBehavior) => {
    this.spinnerService.show();
    this.buttonBehaviorService.deleteBehavior(behavior)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.alertService.renderSuccessMessage(Messages.ButtonBehaviorDeleted);
          this.isBehaviorChanged = true;
          this.getButtonBehaviors();
        }, error: this.alertService.showApiError
      });
  }

  closeButtonBehaviorEdit(res) {
    if (res && res.shouldReload) {
      this.isBehaviorChanged = true;
      this.getButtonBehaviors();
    }
    this.selectedButtonBehaviorType = null;
    this.loadEdit = false;
    this.buttonBehavior = null;
  }

  saveButtonBehavior(data = null) {
    this.spinnerService.show();
    const buttonBehavior: ButtonBehavior = {
      Id: this.buttonBehavior && this.buttonBehavior.Id ? this.buttonBehavior.Id : 0,
      BehaviorName: this.selectedButtonBehaviorType.Value,
      TextValue: data ? JSON.stringify(data) : null,
      POSChoiceId: this.id,
      Ordinal: this.buttonBehavior && this.buttonBehavior.Ordinal ? this.buttonBehavior.Ordinal : 0
    };
    this.buttonBehaviorService.insertOrUpdate(buttonBehavior)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.alertService.renderSuccessMessage(Messages.ButtonBehaviorSaveSuccess);
          this.closeButtonBehaviorEdit({ shouldReload: true });
        }, error: this.alertService.showApiError
      });
  }

}
