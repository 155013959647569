import { Component, OnInit } from '@angular/core';
import { forEach } from 'lodash';
import { angleDoubleRight, angleDoubleLeft, times, search } from 'src/app/shared/components/icon';
import { DomainConstants, Levels, Permissions } from 'src/app/shared/constants';
import { BaseMenuSearchService } from 'src/app/shared/services';
declare let $: any;

@Component({
  selector: 'pos-promotion-base',
  templateUrl: './promotion-base.component.html'
})
export class PromotionBaseComponent implements OnInit {

  isFullScreen = false;
  icons = {
    angleDoubleRight,
    angleDoubleLeft,
    times,
    search
  };
  permissions = Permissions;
  accessLevels = Levels;
  searchPromotionTabText = '';
  filteredPromotionTabs = [];
  promotionTabs = [];
  constructor(private baseMenuSearchService: BaseMenuSearchService,) {
  }

  ngOnInit(): void {
    const promotionTabs = [];
    forEach(DomainConstants.PromotionTypes, promotionType => {
      promotionTabs.push({
        Name: promotionType.DisplayName, Icon: promotionType.Icon, Permission: promotionType.Permission,
        PermissionLevel: this.accessLevels.Access, RouteLink: promotionType.Route
      });
    });
    this.promotionTabs.push({
      GroupName: 'Basic',
      Permission: this.permissions.ManagePromotions,
      PermissionLevel: this.accessLevels.Access,
      List: promotionTabs
    });
    this.filteredPromotionTabs = [...this.promotionTabs];
  }

  toggleFullScreen() {
    this.isFullScreen = !this.isFullScreen;
    if (this.isFullScreen) {
      $('.tab-content').css('width', '100%');
    } else {
      $('.tab-content').css('width', '80%');
    }
  }

  search() {
    this.filteredPromotionTabs = this.baseMenuSearchService.search(this.promotionTabs, this.searchPromotionTabText);
  }
}
