import { AfterViewInit, Component, ContentChildren, HostBinding, Input, QueryList } from '@angular/core';
import { ColumnComponent } from '../column/column.component';

@Component({
  selector: 'pos-row',
  templateUrl: './row.component.html',
})
export class RowComponent implements AfterViewInit {
  @Input() xxl: number;
  @Input() xl: number;
  @Input() lg: number;
  @Input() md: number;
  @Input() sm: number;
  @Input() xs: number = 12;
  @HostBinding('class') get rowClass() {
    return this.prepareGridClass(this.xxl, this.xl, this.lg, this.md, this.sm, this.xs) + ' row';
  }

  @Input() colXxl: number;
  @Input() colXl: number;
  @Input() colLg: number;
  @Input() colMd: number;
  @Input() colSm: number;
  @Input() colXs: number;
  @Input() colStyleClass: string = '';

  @ContentChildren(ColumnComponent) columns: QueryList<ColumnComponent>

  ngAfterViewInit() {
    const colClass = this.prepareGridClass(this.colXxl, this.colXl, this.colLg, this.colMd, this.colSm, this.colXs);
    if (this.columns?.length) {
      this.columns.forEach(col => {
        col.setClass(colClass, this.colStyleClass);
      })
    }
  }

  prepareGridClass(xxl: number, xl: number, lg: number, md: number, sm: number, xs: number) {
    return [xxl ? `col-xxl-${xxl}` : '',
    xl ? `col-xl-${xl}` : '',
    lg ? `col-lg-${lg}` : '',
    md ? `col-md-${md}` : '',
    sm ? `col-sm-${sm}` : '',
    xs ? `col-${xs}` : ''].filter(x => x).join(' ');
  }
}
