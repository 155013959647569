import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseCrudService } from "src/app/shared/services/base-crud.service";
import { MacroScript } from "../interface/macro-script";
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
  })
  export class MacroScriptService extends BaseCrudService<MacroScript> {
    constructor(private httpClient: HttpClient) {
      super('macro-script', httpClient);
    }

    newMacroScript(): MacroScript{
      return {
        Id: 0,
        Name: '',
        Script: ''
      }
    }

    getNameExists(id: number,name: string): Observable<boolean>{
      return this.httpClient.get<boolean>(this.apiEndpoint+ `/${id}/name/${name}/exists`);
    }
  }
