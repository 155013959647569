import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import * as Highcharts from 'highcharts/highcharts.src';
import HighchartsMore from 'highcharts/highcharts-more.src';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import { BaseWidgetService } from 'src/app/adf/services/base-widget.service';
import { ApplicationStateService, ColorUtilityService, DashboardConfiguration, DashboardConfigurationService, GaugeWidgetConfiguration,
  ModalService } from 'src/app/shared';

import { Observable, interval as observableInterval, Subscription } from 'rxjs';
import { DashboardBroadcastingService } from 'src/app/adf/services/dashboard-broadcasting.service';
import { forEach, cloneDeep } from 'lodash';
import { AdfModel } from 'src/app/adf/interfaces/adf-model';
import { WidgetEditComponent } from '../widget-edit/widget-edit.component';

HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);

@Component({
  selector: 'pos-gauge-widget',
  templateUrl: './gauge-widget.component.html',
  styleUrls: ['./gauge-widget.component.scss']
})
export class GaugeWidgetComponent implements OnInit, OnDestroy {

  @Input() widgetConfiguration: DashboardConfiguration;
  data: any;
  @Input() isInteractive = false;
  @Input() adfModel: AdfModel;
  @Input() column: any;
  isLoading = true;
  @Input() config: GaugeWidgetConfiguration;
  highcharts = Highcharts;
  chartOptions: Highcharts.Options;
  timer: Observable<number>;
  timerSubscription: any;
  eventsSubscription: Array<Subscription> = [];
  @Input() isFullScreenMode?:boolean = false;
  copiedConfig: GaugeWidgetConfiguration;
  constructor(private dashboardService: DashboardConfigurationService,
    private modalService: ModalService,
    private colorConversionService: ColorUtilityService,
    private baseWidgetService: BaseWidgetService,
    private applicationStateService: ApplicationStateService,
    private dashboardBroadcastingService: DashboardBroadcastingService) { }

  ngOnInit(): void {
    this.copiedConfig = cloneDeep(this.config);
    if (this.widgetConfiguration && !this.widgetConfiguration.isNewWidget) {
      this.baseWidgetService.loadBaseWidget(this.widgetConfiguration, this.config, this.getWidgetCompleted);
      this.startAutoIntervalTimer();
    } else {
      this.editTextWidget();
    }
    this.subscribeBroadcastEvents();
  }

  subscribeBroadcastEvents = () => {
    this.eventsSubscription.push(this.dashboardBroadcastingService.reloadWidget.subscribe((widget) => {
      if (widget && widget.widgetAssignmentId > 0) {
        this.reloadWidget(widget);
      }
    }));
  }

  reloadWidget = (widget) => {
    if (this.widgetConfiguration.widgetAssignmentId == widget.widgetAssignmentId) {
      this.isLoading = true;
      this.widgetConfiguration = cloneDeep(widget);
      this.config = widget.config;
      this.widgetConfiguration.isReloadWidget = true;
      this.baseWidgetService.loadBaseWidget(this.widgetConfiguration, this.config, this.getWidgetCompleted);
    }
  }

  ngOnDestroy(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
    forEach(this.eventsSubscription, (subscribe) => {
      if (subscribe) {
        subscribe.unsubscribe();
      }
    });
  }

  startAutoIntervalTimer = () => {
    if (this.widgetConfiguration && this.widgetConfiguration.AutoRefreshInterval) {
      this.timer = observableInterval(this.widgetConfiguration.AutoRefreshInterval * 60 * 1000);
      this.timerSubscription = this.timer.subscribe((t) => {
        this.widgetConfiguration.isReloadWidget = true;
        this.baseWidgetService.loadBaseWidget(this.widgetConfiguration, this.config, this.getWidgetCompleted);
      });
    }
  }

  getWidgetCompleted = (response) => {
    this.isLoading = false;
    this.widgetConfiguration.isReloadWidget = false;
    this.data = response;
    this.populateGauge();
    this.baseWidgetService.setWidgetHeights(this.adfModel);
    if (this.config && this.config.params && this.config.params.length) {
      this.config = this.copiedConfig;
    }
  }

  editTextWidget() {
    const modalRef = this.modalService.getModalWrapper(WidgetEditComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center modal-lg',
      initialState: {
        widgetConfig: cloneDeep(this.widgetConfiguration),
      }
    });
    modal.close.subscribe((res) => {
      if (res && res.reload) {
        this.widgetConfiguration = res.widgetConfiguration;
        this.widgetConfiguration.isNewWidget = false;
        this.config = res.widgetConfiguration.config;
        this.updateAdfModel();
        this.baseWidgetService.loadBaseWidget(this.widgetConfiguration, this.config, this.getWidgetCompleted);
      } else {
        this.isLoading = false;
        this.deleteWidget()
      }
    });
  }

  updateAdfModel = () => {
    const rowIndex = this.widgetConfiguration.rowIndex > 0 ? this.widgetConfiguration.rowIndex - 1 : 0;
    const columnIndex = this.widgetConfiguration.columnIndex ? this.widgetConfiguration.columnIndex - 1 : 0;
    if (this.adfModel && this.adfModel.rows) {
      if (this.adfModel.rows[rowIndex] && this.adfModel.rows[rowIndex].columns) {
        if (this.adfModel.rows[rowIndex].columns[columnIndex].widgets)
          this.adfModel.rows[rowIndex].columns[columnIndex].widgets[0] = this.widgetConfiguration;
      }
    }
  }

  deleteWidget() {
    if (this.column) {
      const index = this.column.widgets.indexOf(this.widgetConfiguration);
      if (index >= 0) {
        this.column.widgets.splice(index, 1);
        this.dashboardBroadcastingService.widgetDelete(this.widgetConfiguration);
      }
    }
  }

  populateGauge() {
    const currencySymbol = this.applicationStateService.settingParam.CurrencySymbol ?? '$';
    if (this.config.bgColor) {
      this.config.color = this.colorConversionService.getContrastColor(this.config.bgColor);
    }
    this.chartOptions = {
      chart: {
        type: this.config.gaugeType,
        backgroundColor: this.config.bgColor,
        height: '300'
      },
      title: null,
      series: [
        {
          type: this.config.gaugeType,
          data: [this.dashboardService.parseByDataType(this.data, this.config.outputType)],
          dataLabels: {
            format: '<div style="text-align:center"><span style="font-size:25px;color:' +
              ((this.config.color || Highcharts.theme && Highcharts.theme.colors) || 'black') + '">' +
              ((this.config.unit) === currencySymbol ? currencySymbol : '')
              + '{y} ' + (this.config.unit && this.config.unit !== currencySymbol ? this.config.unit : '') + '</span></div>'
          }
        }
      ],
      pane: {
        startAngle: -90,
        endAngle: 90,
        background: [{
          backgroundColor: '#EEE',
          innerRadius: '60%',
          outerRadius: '100%',
          shape: 'arc'
        }]
      },
      yAxis: {
        // tslint:disable-next-line: radix
        min: isNaN(parseInt(this.config.minValue)) ? 0 : parseInt(this.config.minValue),
        // tslint:disable-next-line: radix
        max: isNaN(parseInt(this.config.maxValue)) ? 100 : parseInt(this.config.maxValue),
        stops: [
          [0.1, '#DF5353'], // red 
          [0.5, '#DDDF0D'], // yellow
          [0.9, '#55BF3B'] // green
        ],
        lineWidth: 0,
        minorTickInterval: null,
        tickAmount: 2,
        labels: {
          y: 16,
          style: {
            color: this.config.color
          }
        }
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: 5,
            borderWidth: 0,
            useHTML: true
          }
        }
      },
    };
  }

  editGaugeWidget() {
    const modalRef = this.modalService.getModalWrapper(WidgetEditComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center modal-lg',
      initialState: {
        widgetConfig: cloneDeep(this.widgetConfiguration),
      }
    });
    modal.close.subscribe((res) => {
      if (res && res.shouldReload) {
        this.widgetConfiguration = res.widgetConfiguration;
      }
    });
  }
}
