import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseListComponent } from 'src/app/shared/components/base-list.component';
import { JobCode } from '../../interface/job-code';
import { AuthenticationService } from 'src/app/shared/auth/services/authentication.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { JobCodeService } from '../../service/job-code.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { GridColumn, TableComponent } from '@tarktech/tark-ng-utils';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import { Permissions, Levels, Messages, DomainConstants } from '../../../../shared/constants/';
import * as _ from 'lodash';
import { ApplicationStateService } from 'src/app/shared';
import { ActivatedRoute, Router } from '@angular/router';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { jobCodeBusinessTime } from 'src/app/shared/components/icon';

@Component({
    selector: 'pos-job-code-list',
    templateUrl: './job-code-list.component.html',
    styleUrls: ['./job-code-list.component.scss']
})
export class JobCodeListComponent extends BaseListComponent<JobCode> implements OnInit {

    permission = {
        name: Permissions.OperationsJobCodes,
        readOnlyLevel: Levels.ReadOnly,
        editLevel: Levels.Edit
    };
    jobCodeColumns: Array<GridColumn> = [];
    jobCodes: JobCode[] = [];
    @ViewChild('jobCodeGrid', { static: true }) jobCodeGrid: TableComponent;
    icons = { jobCodeBusinessTime };
    constructor(
        private authenticationService: AuthenticationService,
        protected alertService: AlertsService,
        protected spinnerService: SpinnerService,
        protected jobCodeService: JobCodeService,
        protected modalService: ModalService,
        protected route: ActivatedRoute,
        private router: Router,
        protected printService: PrintTableService,
        protected applicationStateService: ApplicationStateService
    ) {
        super(jobCodeService, alertService, spinnerService, modalService, applicationStateService, printService, route);
    }

    ngOnInit() {
        this.deleteSuccessMessage = Messages.JobCodeDeleted;
        this.jobCodeColumns = this.configureGridColumns();
        this.checkPermission();
        this.loadSubscriptions();
        this.jobCodeGrid.context = this.gridContext;
    }

    public getGridColumns(): Array<GridColumn> {
        return [
            new GridColumn({ HeaderText: 'Type', Field: 'name', IsSortable: true, Width: '95%' })
        ] as Array<GridColumn>;
    }
    private checkPermission(): void {
        if (!this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
            _.remove(this.jobCodeColumns, (column) => {
                return (column.Type === DomainConstants.GridColumnsTypes.EditColumn
                    || column.Type === DomainConstants.GridColumnsTypes.DeleteColumn);
            });
        }
    }
    private loadSubscriptions(): void {
        this.data$.subscribe({
            next: (res) => {
                this.jobCodes = res;
            }
        });
    }
    public editItem(id: number, data?: JobCode): void {
        this.router.navigate([id], { relativeTo: this.route });
    }
    public getConfirmDeleteMessage(data: JobCode): string {
        return StringUtils.format(Messages.ConfirmDeleteJobCode,
            { 'jobCode': data ? data.name : '' }
        );
    }

    close() {
        this.router.navigate(['../'], { relativeTo: this.route });
    }

}
