<pos-list-wrapper [headingIcon]="icons.checkDouble" heading="Tasks" (close)="close()">
  <div class="row">
    <div class="col-12">
      <ng-table #gridComponent [search]="true" [sort]="true" [paginator]="true" [exportFilename]="'task'"
        [columns]="taskColumns" [data]="taskList">
      </ng-table>
    </div>
  </div>
</pos-list-wrapper>
<pos-confirm-delete [message]="message" (onConfirmAcknowledge)="onConfirmAcknowledge()" #confirmButtonDelete>
</pos-confirm-delete>
<pos-print-table #printTableReference [hidden]="true"></pos-print-table>
