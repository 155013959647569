import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalComponent } from '../modal/modal-component';

@Component({
  selector: 'pos-confirm-delete-modal',
  templateUrl: './confirm-delete-modal.component.html',
  styleUrls: ['./confirm-delete-modal.component.scss']
})
export class ConfirmDeleteModalComponent extends ModalComponent implements OnInit {

  public message: string = '';
  public confirmButtonText: string = 'Yes';
  public cancelButtonText: string = 'No';
  constructor(deleteModalRef: BsModalRef) {  super(deleteModalRef); }

  ngOnInit() {
  }

  public onConfirm(): void {
    this.hide({ shouldDelete: true });
  }

  public hideChildModal(): void {
    this.hide({ shouldDelete: false });
  }

}
