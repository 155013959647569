<pos-edit-wrapper (close)="onCancel()" [isPopup]="false" [headingIcon]="icons.salesProductsFileInvoiceDollar"
    heading="Update Sales Products">
    <form #bulkUpdateForm="ngForm" (ngSubmit)="updateSalesProducts(bulkUpdateForm.valid)" autocomplete="off">
        <div class="col-12 border-top pt-pix-5">
            <div class="col-12 px-pix-20">
                <div class="pb-pix-5">
                    <p-table [value]="salesProductsForBulkUpdate" #pEditor [globalFilterFields]="globalFilterColumns"
                        *ngIf="salesProductsForBulkUpdate.length" [scrollable]="true">
                        <ng-template pTemplate="caption">
                            <div class="pull-right">
                                <input class="form-control normal" pInputText placeholder="Filter..."
                                    (input)="pEditor.filterGlobal($event.target.value, 'contains')" #searchBox>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 25%" [pSortableColumn]="'Name'">
                                    Name
                                    <p-sortIcon [field]="'Name'"></p-sortIcon>
                                </th>
                                <th *ngFor="let size of salesSizes">{{size.Name}}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                            <tr>
                                <td class="vertical-center">
                                    {{rowData.Name}}
                                </td>
                                <td [ngClass]="{'default-size': rowData.DefaultSizeId == productSize.SizeId}"
                                    *ngFor="let productSize of rowData.SalesProductSizes; let i = index;">
                                    <span *ngIf="productSize.SizeId">
                                        <input name="price{{rowIndex}}{{i}}" #price="ngModel" type="number"
                                            id="price{{rowIndex}}{{i}}" class="form-control text-right"
                                            [isAllowNegative]="true" [(ngModel)]="productSize.Price" step="0.01"
                                            [required]="rowData.DefaultSizeId == productSize.SizeId" posDecimal />
                                        <span [ngClass]="{'has-error': (rowData.DefaultSizeId == productSize.SizeId)}"
                                            *ngIf="rowData.DefaultSizeId == productSize.SizeId && !productSize.Price && productSize.Price != 0">
                                            <label class="control-label">Please enter price.</label>
                                        </span>
                                    </span>
                                    <span class="pull-right" *ngIf="!productSize.SizeId">{{notApplicableValue}}</span>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <div class="pt-pix-15" *ngIf="!salesProductsForBulkUpdate.length">
                        No records found
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="col-12 action-button-container page-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" *ngIf="salesProductsForBulkUpdate.length">Save
            </button>
            <button type="button" class="btn-secondary btn-lg btn btn-cancel pos-button" (click)="onCancel()">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
