<pos-edit-wrapper heading="Data source preview: {{dataSourceName}}" (close)="cancel()">
    <form #screenDetailForm="ngForm" autocomplete="off">
        <div class="col-xs-12 pos-modal-body form-horizontal screen-scroll mt-pix-5" >
            <pos-row colXs="12" class="p-0">
                <pos-column xs="12">
                    <pos-form-field inputStyle="col-xs-12 pe-0" >
                        <ngx-codemirror posInput id="dataSourceQResponse" name="dataSourceQResponse" class="data-source-query"
                        #dataSourceQResponse [(ngModel)]="responseJson" [options]="codeMirrorOptions"
                        [disabled]="true">
                      </ngx-codemirror>

                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button [ngClass]="{'btn-primary': copyButtonText == 'Copy', 'btn-success': copyButtonText == 'Copied'}" [disabled]="copyButtonText == 'Copied'" class="btn-lg btn btn-save" type="button" (click)="copyResponse()">{{copyButtonText}}</button>
            <button class="btn-secondary btn-lg btn btn-cancel-modal" (click)="cancel()" type="button"
                value="Cancel">Close</button>
        </div>
    </form>
</pos-edit-wrapper>
