<div class="text-widget col-12 p-0 full-height align-vertical-center"
    *ngIf="!isLoading" [ngClass]="isInteractive ? '' : 'radius-25'"
    [ngStyle]="{'color': color,'background-color': bgColor,'border':'solid 2px '+(borderColor || bgColor || '#fff')}">

    <div class="col-12 ps-pix-15 pe-pix-15">
        <div class="col-md-12 p-0 text-nowrap">
            <div class="text-widget__stat" *ngIf="config" id="w_{{column.rowIndex}}_{{column.columnIndex}}">
                {{ formattedData }}
            </div>
            <div *ngIf="config.metricsName" class="text-center pt-pix-15 font-20 text">{{config.metricsName}}</div>
        </div>
    </div>
</div>

<div *ngIf="isLoading" class="col-12 p-0 full-height align-vertical-center"
    [ngClass]="isInteractive? '' : 'radius-25'"
    [ngStyle]="{'color':color,'background-color':bgColor,'border':'solid 2px '+(borderColor || bgColor || '#fff')}">
    <div class="col-12 ps-pix-15 pe-pix-15">
        <pos-widget-loading></pos-widget-loading>
    </div>
</div>
