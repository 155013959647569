<div class="loading-block" [hidden]="!showLoader">
    <pos-spinner-icon></pos-spinner-icon>
</div>
<div class="recordings-table">
    <ng-table [data]="recordings" [columns]="audioRecordingColumns" [paginator]="false" [search]="false"
        [scrollable]="true">
    </ng-table>
</div>
<div class="col-md-12 pt-pix-10 pb-pix-10 action-button-container border-top-0-important">
    <button type="button" class="btn-secondary btn normal pos-button" (click)="close()"
        value="Cancel"> Close
    </button>
</div>
<ng-template let-data="rowData" #audioRecordingsOperationTemplate>
    <span class="pe-pix-3" tooltip title="Play">
        <pos-action-icon [icon]="icons.playGreen" (click)="sendPlayAudioRequest(data)"></pos-action-icon>
    </span>
    <span class="pe-pix-3" tooltip title="Download">
        <pos-action-icon [icon]="icons.downloadWhite" (click)="downloadAudio(data)"></pos-action-icon>
    </span>
</ng-template>
<div class="text-align-center" id="audio-div">
    <p class="pt-pix-10 black ps-pix-10 pb-pix-5" *ngIf="audioElement">{{tagName}}</p>
</div>
