import { Injectable } from "@angular/core";
import { IKioskNavigation } from "../interfaces/ikiosk-navigation";
import { ChoiceBehavior } from "./choices-behavior";
import { KioskNavigationService } from "../services/kiosk-navigation.service";
import { DomainConstants } from "src/app/shared";
import { forEach } from "lodash";
import { KioskScreenChoice } from "../interfaces";

@Injectable({
    providedIn: 'root'
})
export class SetSizeBehavior extends ChoiceBehavior {

    constructor(private kioskNavigationService: KioskNavigationService) {
        super();
    }

    execute(navObj: IKioskNavigation, orderNavigation: any): Promise<void> {
        const sizeBehavior = navObj.button.ChoiceBehaviors.find(x => x.Name == DomainConstants.KioskButtonBehaviors.SetSize.Value);
        const sizeId = sizeBehavior.SalesSizeBehaviors?.[0]?.SizeId;
        if (navObj.button.ButtonType == DomainConstants.KioskChoiceType.Toggle && !navObj.button.IsSelected && this.kioskNavigationService.versionedVal.Obj.Order.Product.SizeId == sizeId) {
            this.kioskNavigationService.versionedVal.Obj.Order.Product.SizeId = null;
            this.kioskNavigationService.updateProductsStockAndPrice();
            // this.setStockStatusInButtons(null, orderNavigation);
        } else if (navObj.button.ButtonType != DomainConstants.KioskChoiceType.Toggle || navObj.button.IsSelected) {
            this.kioskNavigationService.versionedVal.Obj.Order.Product.SizeId = sizeId ?? null;
             // this.setStockStatusInButtons(sizeId, orderNavigation);
             this.kioskNavigationService.updateProductsStockAndPrice();
        }
        return Promise.resolve();
    }

    setStockStatusInButtons(sizeId: number = null, orderNavigation) {
        forEach(orderNavigation.KioskScreenChoices, (button: KioskScreenChoice) => {
            if (button?.Button?.SalesProductId) {
                let salesSize = button.Button.SalesProduct.SalesProductSizes?.find(x => x.SizeId == sizeId);
                if(!salesSize) {
                  salesSize = button.Button.SalesProduct.SalesProductSizes?.find(x => x.SizeId == button.Button.SalesProduct?.SalesCategories?.DefaultSizeId);
                }
                const isInStock = (salesSize?.IsInStock || button.Button.SalesProduct.IsAllowOutOfStockOrder);
                button.Button.SalesProduct.IsInStock = isInStock;
                if (!isInStock && button.Button.IsSelected) {
                    button.Button.IsSelected = false;
                    this.kioskNavigationService.versionedVal.Obj.Order.Product.Components = this.kioskNavigationService.versionedVal.Obj.Order.Product.Components.filter(x => x.ButtonId != button.ButtonId);
                }
            }
        });
    }
}

