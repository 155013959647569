import { Directive, OnInit, HostListener, Renderer2, ElementRef } from '@angular/core';

@Directive({
    selector: 'input'
})
export class InputDirective implements OnInit {
    constructor (private _elRef: ElementRef, private _renderer: Renderer2) {}
    ngOnInit() {
        this._renderer.setAttribute(this._elRef.nativeElement, 'data-lpignore', 'true');
        this._renderer.setAttribute(this._elRef.nativeElement, 'autocomplete', 'off');
    }


}
