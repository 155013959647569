import { Route } from "@angular/router";
import { AuthGuard } from "../app-guard";
import { KioskScreenListComponent } from "./components/kiosk-screen-list/kiosk-screen-list.component";
import { KioskScreenEditComponent } from "./components/kiosk-screen-edit/kiosk-screen-edit.component";
import { KioskManagementComponent } from "./components/kiosk-management/kiosk-management.component";
import { KioskChoicesComponent } from "./components/kiosk-choices/kiosk-choices.component";
import { Levels } from "../shared/constants/accessLevel";
import { Permissions } from "../shared/constants/permissions";
import { KioskScreenConfigComponent } from "./components/kiosk-screen-config/kiosk-screen-config.component";
import { CancelOrderComponent, CheckoutConfigComponent, ConfirmOrderConfigComponent, GeneralConfigComponent, IdleTimeoutComponent, KioskChoiceEditComponent, OrderKioskTerminalComponent, PaymentConfigComponent } from "./components";
import { KioskOrderConfirmedComponent } from "./components/kiosk-order-confirmed/kiosk-order-confirmed.component";
import { KioskCheckoutScreenComponent } from "./components/kiosk-checkout-screen/kiosk-checkout-screen.component";
import { CreditCardPaymentProcessingComponent } from "./components/credit-card-payment-processing/credit-card-payment-processing.component";
import { OrderKioskInitialScreenComponent } from "./components/order-kiosk-initial-screen/order-kiosk-initial-screen.component";
import { AiShakeConfigComponent } from "./components/configurations/ai-shake-config/ai-shake-config.component";

export const ORDER_KIOSK_CONFIGS: Route[] = [
    {
        path: '',
        canActivateChild: [AuthGuard],
        children: [
            {
                path: '',
                component: KioskManagementComponent,
                children: [
                    {
                        path: 'buttons',
                        component: KioskChoicesComponent,
                        data: {
                            group: 'button',
                            permissions: [{ 'Name': Permissions.OrderKioskButtons, 'Level': Levels.Access }]
                        }
                    },
                    {
                        path: 'buttons/:id',
                        component: KioskChoiceEditComponent,
                        data: {
                            group: 'button',
                            permissions: [{ 'Name': Permissions.OrderKioskButtons, 'Level': Levels.Edit }]
                        }
                    },
                    {
                        path: 'screens',
                        component: KioskScreenListComponent,
                        data: {
                            group: 'screen',
                            permissions: [{ 'Name': Permissions.OrderKioskScreens, 'Level': Levels.Access }]
                        }
                    },
                    {
                        path: 'screens/:id',
                        component: KioskScreenEditComponent,
                        data: {
                            group: 'screen',
                            permissions: [{ 'Name': Permissions.OrderKioskScreens, 'Level': Levels.Edit }]
                        }
                    },
                    {
                        path: 'screens/:id/design',
                        component: KioskScreenConfigComponent,
                        data: {
                            group: 'screen',
                            permissions: [{ 'Name': Permissions.OrderKioskScreens, 'Level': Levels.Edit }]
                        }
                    },
                    {
                        path: 'cancel-order',
                        component: CancelOrderComponent,
                        data: {
                            permissions: [{ 'Name': Permissions.OrderKioskCancelOrderConfig, 'Level': Levels.Access }]
                        }
                    },
                    {
                        path: 'idle-timeout',
                        component: IdleTimeoutComponent,
                        data: {
                            permissions: [{ 'Name': Permissions.OrderKioskIdleTimeoutConfig, 'Level': Levels.Access }]
                        }
                    },
                    {
                        path: 'kiosk-order-confirm',
                        component: ConfirmOrderConfigComponent,
                        data: {
                            permissions: [{ 'Name': Permissions.OrderKioskOrderConfirmConfig, 'Level': Levels.Access }]
                        }
                    },
                    {
                        path: 'kiosk-payment',
                        component: PaymentConfigComponent,
                        data: {
                            permissions: [{ 'Name': Permissions.OrderKioskPaymentConfig, 'Level': Levels.Access }]
                        }
                    },
                    {
                        path: 'kiosk-checkout',
                        component: CheckoutConfigComponent,
                        data: {
                            permissions: [{ 'Name': Permissions.OrderKioskCheckoutConfig, 'Level': Levels.Access }]
                        }
                    },
                    {
                        path: 'ai-shake',
                        component: AiShakeConfigComponent,
                        data: {
                            permissions: [{ 'Name': Permissions.OrderKioskAIShakeConfig, 'Level': Levels.Access }]
                        }
                    },
                    {
                        path: 'kiosk-general',
                        component: GeneralConfigComponent,
                        data: {
                            permissions: [{ 'Name': Permissions.OrderKioskGeneralConfig, 'Level': Levels.Access }]
                        }
                    }
                ],
                data: {
                    permissions: [{ 'Name': Permissions.ManageOrderKiosk, 'Level': Levels.Access }]
                }
            },
            {
                path: 'terminal',
                component: OrderKioskTerminalComponent,
                children: [
                    {
                        path: 'home',
                        component: OrderKioskInitialScreenComponent
                    },
                    {
                        path: 'confirmed',
                        component: KioskOrderConfirmedComponent,
                        data: {
                            permissions: [{ 'Name': Permissions.OrderKioskTerminal, 'Level': Levels.Access }]
                        }
                    },
                    {
                        path: 'checkout',
                        component: KioskCheckoutScreenComponent,
                        data: {
                            permissions: [{ 'Name': Permissions.OrderKioskTerminal, 'Level': Levels.Access }]
                        }
                    },
                    {
                        path: 'cc-payment',
                        component: CreditCardPaymentProcessingComponent,
                        data: {
                            permissions: [{ 'Name': Permissions.OrderKioskTerminal, 'Level': Levels.Access }]
                        }
                    }
                ],
                data: {
                    permissions: [{ 'Name': Permissions.OrderKioskTerminal, 'Level': Levels.Access }]
                }
            }
        ]
    }
]

export const ORDER_KIOSK_STATE = ORDER_KIOSK_CONFIGS;
