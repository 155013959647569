<button Class="close" (click)="closePagerSelection()" type="button">&times;</button>
<h4 class="modal-content__heading">Pager Selection</h4>
<div class="col-12 px-pix-10 mt-pix-10">
  <div class="overflow-tab">
    <div class="row g-0 col-12 order-management__order-list"
      [ngStyle]="{'max-height': pagerHeight + 'px'}">
      <div *ngFor="let pager of pagerRange; let i = index" [attr.data-index]="i"
        class="width-20 col-3 p-pix-10">
        <div class="btn-border pager-button text-center pointer"
          [ngStyle]="{'background-color': pager.OrderId > 0 ? 'gray':''}" (click)="mapPager(pager)" tooltip
          tooltip="{{pager.SurrogateOrderId}}" placement="{{i > 5 ? 'top' : 'bottom'}}">{{pager.PagerNumber}}
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-12 action-button-container popup-footer pb-pix-10 mti-15">
  <button type="button" class="btn btn-secondary btn-lg normal btn-cancel-modal pos-button"
    (click)="closePagerSelection()">Cancel</button>
</div>
<pos-confirm-delete (onConfirmAcknowledge)="onConfirmAcknowledge()" #confirmRemovePager>
</pos-confirm-delete>
