<pos-kiosk-header *ngIf="screenConfig.Header.IsShow" [header]="screenConfig.Header" />
<div class="row mx-0 designer-area" [style.background-color]="screenConfig.BackgroundColor"
    [style.height]="sectionHeight + 'px'"
    [style.background-image]="screenConfig.BackgroundImage ? 'url(\''+ imageRootPath + screenConfig.BackgroundImage + '\')' : null">
    <div class="p-0 relative" [ngClass]="screenConfig.ShowInvoice ? 'col-xl-9 col-8' : 'col-12'">
        <div class="allergens-title" *ngIf="screenConfig.Title">
            {{screenConfig.Title}}
        </div>
        <div class="overflow-auto dietary-warnings-section" [style.height]="warningsHeight + 'px'">
            <div class="warning-item" *ngFor="let warning of dietaryWarnings" (click)="selectDietaryWarning(warning)"
                [ngClass]="{'active': warning.IsSelected }" [ngStyle]="{'background-color': screenConfig.ButtonBackgroundColor, 'color': screenConfig.ButtonFontColor}">
                <img [src]="allergenRootPath + warning.Image" />
                <span class="text"> {{ warning.Name | uppercase }} </span>
            </div>
        </div>
        <div class="action-buttons">
            <button class="btn text-uppercase no-allergies-btn" (click)="buttonClick(screenButtons[0])" [disabled]="selectedDietaryWarnings?.length">
                <div class="config-button">
                    <pos-kiosk-screen-setup [screenButton]="screenButtons[0]" [screenButtons]="screenButtons"
                        [isDesignMode]="isDesignMode" [setupItems]="setupItems"></pos-kiosk-screen-setup>
                </div>
                I have no allergies
            </button>
            <button class="btn text-uppercase" (click)="buttonClick(screenButtons[1], true)">
                <div class="config-button">
                    <pos-kiosk-screen-setup [screenButton]="screenButtons[1]" [screenButtons]="screenButtons"
                        [isDesignMode]="isDesignMode" [setupItems]="setupItems"></pos-kiosk-screen-setup>
                </div>
                Confirm Allergies and Continue
            </button>
        </div>
    </div>
    <div class="col-xl-3 col-4 p-3" *ngIf="screenConfig.ShowInvoice" [ngStyle]="{'height': invoiceHeight - 3 + 'px'}">
        <pos-kiosk-invoice></pos-kiosk-invoice>
    </div>
</div>
<pos-kiosk-footer *ngIf="screenConfig.Footer.IsShow" [buttons]="screenButtons" [footer]="screenConfig.Footer"></pos-kiosk-footer>
