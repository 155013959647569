import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';

@Injectable({
  providedIn: 'root'
})
export class CopyInventoryIngredientsInfoService {

  constructor(private httpService: HttpClient) { }

  getInventoryProduct(inventoryCategoryId, inventoryVendorId, isActive, inventoryProductId): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}inventoryproducts/` + inventoryCategoryId + '/' + inventoryVendorId + '/' + isActive
      + '/' + inventoryProductId
    );
  }

  copyProductIngredientsInfo(copyData: any): Observable<any> {
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}inventoryproducts/copyProductIngredientsInfo`,
      copyData
    );
  }
}
