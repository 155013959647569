import { Component, ViewChild, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BarcodeScannerLivestreamComponent } from 'ngx-barcode-scanner';
import { DomainConstants, SpinnerService } from 'src/app/shared';
import { barcode, flashlight } from 'src/app/shared/components/icon';
import { InventoryScannerBatchModel } from '../../interfaces';

@Component({
  selector: 'pos-inventory-barcode-scanner',
  templateUrl: './inventory-barcode-scanner.component.html',
  styleUrls: ['./inventory-barcode-scanner.component.scss']
})
export class InventoryBarcodeScannerComponent implements AfterViewInit, OnInit, OnDestroy {

  icons = {
    barcode,
    flashlight
  }
  hideSpinnerTimeOut: any;
  @ViewChild(BarcodeScannerLivestreamComponent) barcodeScanner: BarcodeScannerLivestreamComponent;
  inventoryScannerBatch: Array<InventoryScannerBatchModel> = [];
  sessionKey = DomainConstants.SessionStorageKeys.InventoryScannerBatch;

  constructor(
    protected route: ActivatedRoute,
    private router: Router,
    public spinnerService: SpinnerService
  ) {
    const batchData = sessionStorage.getItem(this.sessionKey);
    if (batchData) {
      this.inventoryScannerBatch = JSON.parse(batchData) as Array<InventoryScannerBatchModel>
    }
  }

  ngOnInit(): void {
    this.spinnerService.show();
    this.hideSpinner();
  }

  hideSpinner() {
    this.hideSpinnerTimeOut = setTimeout(() => {
      this.spinnerService.hide();
      clearTimeout(this.hideSpinnerTimeOut)
    }, 5000);
  }



  ngAfterViewInit() {
    this.barcodeScanner.start();
  }
  
  onStarted(started) {
    if(this.hideSpinnerTimeOut) {
      clearTimeout(this.hideSpinnerTimeOut)
    }
    this.spinnerService.hide();
    console.log('started reading barcode: ',started)
  }

  onCodeResult(result) {
    const barcodeValue = result.codeResult.code;
    if (barcodeValue && barcodeValue.length == 12 && !isNaN(parseInt(barcodeValue, 10))) {
      this.router.navigate([barcodeValue], { relativeTo: this.route });
    }
  }

  cancel() {
    this.router.navigate(['manage']);
  }

  ngOnDestroy(): void {
    this.barcodeScanner.stop();
  }

  redirectToBatchList() {
    this.router.navigate(['inventory-log-batch'], { relativeTo: this.route.parent })
  }
}
