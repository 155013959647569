<pos-edit-wrapper heading="Training Note" (close)="onCancel(false)">
  <form #userTrainingNoteForm="ngForm" (ngSubmit)="saveUserTrainingNote(userTrainingNoteForm.valid)" autocomplete="off">
    <div class="col-12 pos-modal-body screen-scroll">
      <div class="col-12">
        <div class="row g-0 pt-pix-10">
          <div class="col-md-6 col-xs-12">
            <label>Area: {{trainingData.AreaName}}</label>
          </div>
          <div class="col-md-6 col-xs-12">
            <label>Topic: {{trainingData.TopicName}}</label>
          </div>
          <div class="col-md-6 col-xs-12">
            <label>Level: {{trainingData.LevelName}}</label>
          </div>
          <div class="col-md-6 col-xs-12">
            <label>User: {{trainingData.UserName}}</label>
          </div>
        </div>
      </div>
      <pos-row class="g-0">
        <pos-column>
          <pos-form-field label="Note" [validationMessages]="{required: 'Please enter note.'}" inputStyle="col-xs-12" [appendPaddingTop]="false">
            <textarea posInput name="trainingNote" id="trainingNote" #trainingNote="ngModel" class="form-control"
              [(ngModel)]="userTrainingNote" rows="6" maxlength="2000" [required]="true"></textarea>
          </pos-form-field>
        </pos-column>
      </pos-row>
    </div>

    <div class="col-12 action-button-container popup-footer">
      <button type="submit" class="btn btn-primary btn-lg btn-save pos-button">Save</button>
      <button type="reset" class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="onCancel(false)">Cancel</button>
    </div>
    <div class="clearfix"></div>
  </form>
</pos-edit-wrapper>
