<pos-edit-wrapper (close)="cancelEditingStompHost()" heading="{{mode}} RabbitMQ Stomp Config">
    <form (ngSubmit)="saveRabbitMQStompConfig()" #formRMQStompConfig="ngForm" class="edit-form">
        <div class="col-12 pos-modal-body">
            <div class="col-12 px-pix-5 mt-pix-10" *ngIf="showSSLWarning">
                <div class="alert condensed alert-warning mb-pix-5">{{useSSLConfigMessage}}</div>
            </div>
            <pos-row>
                <pos-column>
                    <pos-form-field label="Application Host"
                        [validationMessages]="{required: 'Please enter application host.'}">
                        <input posInput class="form-control" name="applicationHost" [disabled]="mode == 'Edit'"
                            [(ngModel)]="rabbitMQStompConfig.ApplicationHost" [required]="true" type="text"
                            #applicationHost="ngModel" />
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Host" [validationMessages]="{required: 'Please enter host.'}">
                        <input posInput type="text" class="form-control" name="host"
                            [(ngModel)]="rabbitMQStompConfig.Host" [required]="true" #host="ngModel" />
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Port" [validationMessages]="{required: 'Please enter port.'}">
                        <input posInput type="text" class="form-control" name="port"
                            [(ngModel)]="rabbitMQStompConfig.Port" [required]="true" #host="ngModel" />
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field>
                        <p-checkbox posInput name="isUseSSL" #isUseSSL="ngModel" (onChange)="changeUseSSLStompStatus()"
                            [(ngModel)]="rabbitMQStompConfig.IsUseSSL" binary="true" label="Use SSL">
                        </p-checkbox>
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button class="btn btn-primary btn-lg btn-save pos-button" scrollup type="submit" value="Save">Save</button>
            <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="cancelEditingStompHost()" type="button"
                value="Cancel">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
