import { Messages } from 'src/app/shared/constants/ui-messages';
import { SalesProductAlias } from './../../interface/sales-product-alias';
import { AlertsService } from 'src/app/shared/services';
import { EditColumn, DeleteColumn, GridColumn } from '@tarktech/tark-ng-utils';
import { Component, OnInit, Input } from '@angular/core';
import { SalesProductAliasService } from '../../services';
import { SpinnerService, spinner } from 'src/app/shared';
import { normalPlus } from 'src/app/shared/components/icon';

@Component({
  selector: 'sales-product-alias',
  templateUrl: './sales-product-alias.component.html',
  styleUrls: ['./sales-product-alias.component.scss']
})
export class SalesProductAliasComponent implements OnInit {

  salesProductAliasesColumn: Array<GridColumn> = [];
  @Input('salesProductId') salesProductId: number;
  salesProductAlias: SalesProductAlias;
  salesProductAliases: Array<SalesProductAlias> = [];
  icons = {
    normalPlus
  }

  constructor(
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private salesProductAliasService: SalesProductAliasService
  ) { }

  ngOnInit() {
    this.resetAlias();
    this.getSalesProductAliases();
    this.configureGridColumns();
  }


  resetAlias() {
    this.salesProductAlias = {
      Id: 0,
      SalesProductId: 0,
      AliasName: null
    };
    this.salesProductAlias.SalesProductId = this.salesProductId;
  }

  public getSalesProductAliases() {
    this.spinnerService.show();
    this.salesProductAliasService.getSalesProductAliases(this.salesProductId)
      .subscribe(
        response => {
          if (response) {
            this.salesProductAliases = response;
          } else {
            this.salesProductAliases = [];
          }
        },
        this.alertService.showApiError,
        () => {
          this.spinnerService.hide();
        }
      );
  }

  public addAlias(salesProductAlias: SalesProductAlias) {
    if (salesProductAlias.AliasName && salesProductAlias.AliasName.trim()) {
      this.spinnerService.show();
      this.salesProductAliasService.addAlias(salesProductAlias)
        .subscribe(
          response => {
            this.getSalesProductAliases();
            this.alertService.renderSuccessMessage(Messages.SalesProductAliasSaved);
            this.resetAlias();
          },
          this.alertService.showApiError,
          () => {
            this.spinnerService.hide();
          }
        );
    }
  }

  private configureGridColumns() {
    const deleteColumn = new DeleteColumn({
      Clicked: (data) => {
        this.deleteAlias(data.Id);
      },
      Width: '7%'
    });

    this.salesProductAliasesColumn = [
      new GridColumn({ HeaderText: 'Alias', Field: 'AliasName', Width: '93%' })
    ] as Array<GridColumn>;

    this.salesProductAliasesColumn.push(deleteColumn);

  }

  deleteAlias(id: number) {
    this.spinnerService.show();
    this.salesProductAliasService.deleteAlias(id)
      .subscribe(
        response => {
          this.getSalesProductAliases();
          this.alertService.renderSuccessMessage(Messages.SalesProductAliasDeleted);
        },
        this.alertService.showApiError,
        () => {
          this.spinnerService.hide();
        }
      );
  }

}
