import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { Observable } from 'rxjs';
import { TerminalDetails } from 'src/app/orders';
import { VoidOrder } from 'src/app/order-maintenance/interface/void-order';
import { PrinterScript } from '../../manage-console/printout-designer/interface/printer-script';

@Injectable()
export class ManagesService {
  managesApiUrl = '';
  constructor(private httpService: HttpClient) {
    this.managesApiUrl = RuntimeConstants.API_PATH + 'Manages';
  }

  getDrawerStatus(id: number): Observable<any> {
    return this.httpService.get(`${this.managesApiUrl}/drawer-status/` + id);
  }

  getTaskDetails(): Observable<any> {
    return this.httpService.get(`${this.managesApiUrl}/GetTaskDetails`);
  }

  createRabbitMqMessageForShiftDuties(shiftDuty: string): Observable<any> {
    return this.httpService.get(`${this.managesApiUrl}/CreateRabbitMqMessageForShiftDuties/` + shiftDuty);
  }

  updateTaskCompleted(taskDetails: any): Observable<any> {
    return this.httpService.post(`${this.managesApiUrl}/UpdateTaskCompleted`, taskDetails);
  }

  taskHelpRequest(taskDetailsModel: any): Observable<any> {
    return this.httpService.post(`${this.managesApiUrl}/TaskHelpRequest`, taskDetailsModel);
  }

  updateTaskUndone(taskDetails) {
    return this.httpService.post(`${this.managesApiUrl}/UpdateTaskUndone`, taskDetails);
  }

  getMappedTerminalsByType(terminalId: number, terminalType: string): Observable<Array<TerminalDetails>> {
    let params = new HttpParams();
    params = params.append('terminalId', terminalId.toString());
    params = params.append('terminalType', terminalType);
    return this.httpService.get<Array<TerminalDetails>>(`${RuntimeConstants.API_PATH}orders/mapped-terminals/`, { params: params });
  }

  checkGiftCardBalance(giftCardNumber: any) {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}giftcard/` + giftCardNumber
    );
    // return this.httpService.post(`${this.managesApiUrl}/CheckGiftCardBalance`, giftCardModel);
  }

  voidTheSelectedOrder(voidOrderModel: VoidOrder) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}Manages/VoidTheSelectedOrder`, voidOrderModel);
  }

  createFullBackup(isSendEmail) {
    return this.httpService.post(`${this.managesApiUrl}/backup/${isSendEmail}`, null);
  }

  openCashDrawerClicked(objParam) {
    return this.httpService.post(`${this.managesApiUrl}/open/cash-drawer`, objParam);
  }

  getPaymentsDetails (drawerId: number) {
    return this.httpService.get(`${this.managesApiUrl}/payment-details/${drawerId}`);
  }
    
  getUserNameForSendingMessages = () => {
    return this.httpService.get(`${RuntimeConstants.API_PATH}Manages/GetUserNameForSendingMessages`);
  }
}
