import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../shared';
import { FormsModule } from '@angular/forms';
import { HeaderEntryModule } from '../header-entry/header-entry.module';
import { RouterModule } from '@angular/router';
import { ProductBuildQueueTerminalComponent } from './components/product-build-queue-terminal/product-build-queue-terminal.component';
import { PRODUCT_BUILD_STATES } from './product-build-queue.routes';
import { ProductBuildQueueEditComponent } from './components/product-build-queue-edit/product-build-queue-edit.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';

@NgModule({
  declarations: [ProductBuildQueueTerminalComponent, ProductBuildQueueEditComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(PRODUCT_BUILD_STATES),
    ComponentsModule,
    HeaderEntryModule,
    FormsModule,
    PopoverModule.forRoot(),
    ModalModule.forRoot(),
    NgSelectModule,
    ColorPickerModule
  ],
  bootstrap: [
    ProductBuildQueueTerminalComponent
  ]
})
export class ProductBuildQueueModule { }
