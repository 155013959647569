import { Component, Input } from '@angular/core';

@Component({
  selector: 'pos-kiosk-message-strip',
  templateUrl: './kiosk-message-strip.component.html',
  styleUrls: ['./kiosk-message-strip.component.scss']
})
export class KioskMessageStripComponent {
  @Input() messageText = 'Tap screen to order';
  @Input() backgroundColor = '#1eb9b8';
  @Input() fontColor = '#ffffff';
  @Input() verticalPosition = 0;
  @Input() fontSize = 'xx-large';

  ngOnInit() {
    if (!this.messageText) {
      this.messageText = "Tap screen to order";
    }
  }
}
