import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import {
  AuthenticationService,
  AlertsService,
  SpinnerService,
  ModalService,
  PrintTableService,
  Messages,
  Levels,
  Permissions,
  ApplicationStateService,
  BaseGridComponent,
  ConfirmDeleteComponent,
  HardwareModel,
  ExportService
} from 'src/app/shared';
import { GridColumn, TableComponent, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { HardwaresService } from '../../service/hardware.service';
import * as _ from 'lodash';
import { finalize } from 'rxjs/operators';
import { check, plusWhite, editWhite, printWhite, ban, checkCircleWhite, deleteWhite, hardwareToolbox } from 'src/app/shared/components/icon';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'pos-hardware-list',
  templateUrl: './hardware-list.component.html',
  styleUrls: ['./hardware-list.component.scss']
})
export class HardwareListComponent extends BaseGridComponent implements OnInit {

  permission = {
    name: Permissions.SystemConfigurationHardware,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };
  icons = {
    check, plusWhite, editWhite, printWhite, ban, checkCircleWhite, deleteWhite, hardwareToolbox
  };
  hardwareColumns: Array<GridColumn> = [];
  printHardwareColumns: Array<GridColumn> = [];
  hardwareListAll: Array<HardwareModel> = [];
  hardwareList: Array<HardwareModel> = [];
  @ViewChild('activeTemplate', { static: true }) activeTemplate: TemplateRef<any>;
  @ViewChild('operationTemplate', { static: true }) operationTemplate: TemplateRef<any>;
  @ViewChild('operationHeaderTemplate', { static: true }) operationHeaderTemplate: TemplateRef<any>;
  @ViewChild('confirmHardware') private confirmHardware: ConfirmDeleteComponent;
  @ViewChild('hardwareTable', { static: true }) hardwareTable: TableComponent;
  isInactive = false;
  hardwareId: number;

  constructor(private authenticationService: AuthenticationService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected hardwareService: HardwaresService,
    protected printService: PrintTableService,
    protected applicationStateService: ApplicationStateService,
    protected route: ActivatedRoute,
    protected router: Router,
    private exportService: ExportService) {
    super(applicationStateService, route);
  }

  ngOnInit() {
    this.loadData();
    this.configureGridColumns();
    this.hardwareTable.context = this.gridContext;
  }

  loadData() {
    this.spinnerService.show();
    this.hardwareService.getAll()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: response => {
          this.hardwareListAll = response == null ? [] : response;
          this.hardwareListAll.sort((a, b) => a.Type.localeCompare(b.Type));
          this.hardwareList = this.hardwareListAll.filter(x => x.IsActive === true);
          this.changeHardwareList();
        }, error: this.alertService.showApiError
      });
  }

  changeHardwareList() {
    if (this.isInactive) {
      this.hardwareList = this.hardwareListAll;
    } else {
      this.hardwareList = this.hardwareListAll.filter(x => x.IsActive === true);
    }
  }

  configureGridColumns(): Array<GridColumn> {

    const activeColumn = new TemplateColumn({
      HeaderText: 'Active',
      Field: 'IsActive',
      IsSortable: true,
      itemTemplate: this.activeTemplate,
      TextAlign: TextAlign.Center,
      Width: '10%'
    });

    const operationColumn = new TemplateColumn({
      itemTemplate: this.operationTemplate,
      Width: '147px',
      headerTemplate: this.operationHeaderTemplate,
      CellClass: 'cell-padding',
      TextAlign: TextAlign.Center
    });

    this.hardwareColumns = [
      new GridColumn({ HeaderText: 'Device Type', Field: 'HarwareType', IsSortable: true, Width: '30%' }),
      new GridColumn({ HeaderText: 'Device Name', Field: 'Name', IsSortable: true, Width: '20%' }),
      new GridColumn({ HeaderText: 'Asset Tag', Field: 'AssetTag', IsSortable: true, Width: '20%' }),
      new GridColumn({ HeaderText: 'HIC', Field: 'HICName', IsSortable: true, Width: '15%' }),
      activeColumn,
    ] as Array<GridColumn>;

    this.printHardwareColumns = [...this.hardwareColumns];

    if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      this.hardwareColumns.push(operationColumn);
    }

    return this.hardwareColumns;
  }

  editHardware(data: HardwareModel): void {
    this.router.navigate([data ? data.Id : 0], { relativeTo: this.route });
  }

  updateHardwareDeviceStatus(data: HardwareModel, isActive: boolean) {
    this.spinnerService.show();
    this.hardwareService.updateHardwareStatus(data.Id, isActive)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.alertService.renderSuccessMessage(Messages.HardwareStatusUpdated);
          this.loadData();
        }, error: this.alertService.showApiError
      });
  }

  deleteHardware(hardware: HardwareModel) {
    this.hardwareId = hardware.Id;
    this.confirmHardware.Show(Messages.ConfirmDeleteHardwareDevice);
  }

  onConfirmAcknowledge() {
    this.spinnerService.show();
    this.hardwareService.delete(this.hardwareId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.alertService.renderSuccessMessage(Messages.HardwareDeviceDeleted);
          this.loadData();
          this.hardwareId = 0;
        }, error: this.alertService.showApiError
      });
  }

  printDiv() {
    this.printService.printEmitter.next({ gridColumns: this.printHardwareColumns, gridData: this.hardwareList });
  }

  exportCSV() {
    this.exportService.exportCSV('hardware', this.hardwareList, null, this.printHardwareColumns);
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
