<div>
  <button class="close" *ngIf="!isTimeEntryTerminal" (click)="cancel()">&times;</button>
  <h4 class="modal-content__heading">Clock In/Clock Out</h4>
</div>
<div class="overflow-hidden">
  <div class="col-md-12 screen-scroll">
    <div class="col-12 p-0">
      <div class="alert condensed inherit-position alert-danger mt-pix-10" id="divSuccessmsg" style="display: none;"></div>
      <div class="col-12 row mx-0">
        <div class="col-md-4 col-sm-3 col-p">
        </div>
        <div class="col-md-4 col-sm-6 col-12 pt-pix-15">
          <input type="password" name="appPassword" id="appPassword" placeholder="Password" #appPassword="ngModel"
            class="form-control time-entry-modal__password" [(ngModel)]="customerCredential.Password" [autoFocus]
            (keyup.enter)="clockInOut()" />
        </div>
      </div>

      <div class="col-12 mt-2 text-center p-0">

        <button class="btn-keypad clock-in-btn-keypad" (click)="textEnteredForLogin('1')">1</button>

        <button class="btn-keypad clock-in-btn-keypad" (click)="textEnteredForLogin('2')">2</button>

        <button class="btn-keypad clock-in-btn-keypad" (click)="textEnteredForLogin('3')">3</button>

        <button class="btn-keypad clock-in-btn-keypad" (click)="textEnteredForLogin('4')">4</button>

        <button class="btn-keypad clock-in-btn-keypad" (click)="textEnteredForLogin('5')">5</button>

        <button class="btn-keypad clock-in-btn-keypad" (click)="textEnteredForLogin('6')">6</button>

        <button class="btn-keypad clock-in-btn-keypad" (click)="textEnteredForLogin('7')">7</button>

        <button class="btn-keypad clock-in-btn-keypad" (click)="textEnteredForLogin('8')">8</button>

        <button class="btn-keypad clock-in-btn-keypad" (click)="textEnteredForLogin('9')">9</button>

        <button class="btn-keypad clock-in-btn-keypad" (click)="textEnteredForLogin('0')">0</button>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
  <div class="col-12 action-button-container popup-footer pb-pix-10">
    <button class="btn btn-secondary btn-lg me-pix-10 pos-button" (click)="clockInOut()" type="button">
      <img src="images/icons/icon-circle-check.png" /> Continue
    </button>
    <button class="btn btn-secondary btn-lg me-pix-10 pos-button" (click)="cancel()" type="button">
      <img src="images/icons/icon-circle-x.png" /> {{isTimeEntryTerminal ? 'Clear': 'Cancel'}}
    </button>
    <button class="btn btn-secondary btn-lg me-pix-10 pos-button" (click)="exitTerminal()" *ngIf="isTimeEntryTerminal"
      type="button">
      <img src="images/icons/icon-circle-x.png" /> Exit Terminal
    </button>
  </div>
</div>
<ng-template #clockInInfraction>
  <div>
    <div>
      <button class="close" (click)="closeClockInInfraction()">&times;</button>
      <h4 class="modal-content__heading">Welcome</h4>
    </div>
    <div class="pos-modal-body pt-pix-20">
      <div class="row mx-0">
        <div class="border-1 col-md-4 bold p-pix-6">
          Date Added
        </div>
        <div class="border-1 col-md-5 bold p-pix-6">
          Infraction
        </div>
        <div class="border-1 col-md-3 bold p-pix-6">
          Points
        </div>
      </div>
      <div class="row mx-0" *ngFor="let infraction of clockInInfractionList">
        <div class="border-1 col-md-4 p-pix-6">
          {{infraction.DateAdded| tark_time}}
        </div>
        <div class="border-1 col-md-5 p-pix-6">
          {{infraction.infraction}}
        </div>
        <div class="border-1 col-md-3 p-pix-6">
          {{infraction.points}}
        </div>

      </div>
    </div>
    <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 action-button-container popup-footer pb-pix-10">
      <button class="btn btn-primary btn-lg normal me-pix-10" (click)="closeClockInInfraction()" type="button">Ok
      </button>
    </div>
    <div class="clearfix"></div>
  </div>
</ng-template>
