import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Messages } from '../../constants';
import { NumpadOptions, SettingParam } from '../../interface';
import { ApplicationStateService } from '../../services';
import { ModalComponent } from '../modal';

@Component({
  selector: 'pos-quantity-change-popup',
  templateUrl: './quantity-change-popup.component.html',
  styleUrls: ['./quantity-change-popup.component.scss']
})
export class QuantityChangePopupComponent extends ModalComponent implements OnInit {
  @Input() value: any;
  allowDecrement: boolean;
  salesProductUnit: string;
  header: string;
  @Input() maxQty: number;
  numpadOptions: NumpadOptions;
  public onSubmit: Function;
  public onCancel: Function;
  settingParam: SettingParam;
  decrementErrorMessage: string;
  maxValidationMessage: string;
  constructor(modalRef: BsModalRef,
    private applicationStateService: ApplicationStateService) {
    super(modalRef);
  }
  ngOnInit(): void {
    this.settingParam = this.applicationStateService.settingParam;
    this.onSubmit = this.submit.bind(this);
    this.onCancel = this.cancel.bind(this);
    this.numpadOptions = {
      allowDecimal: this.settingParam.AllowOrderingFractionalQty,
      noOfDecimalPlaces: (this.settingParam.AllowOrderingFractionalQty ? 2 : 0),
      startWithDecimal: false,
      min: !this.allowDecrement ? this.value : (this.settingParam.AllowOrderingFractionalQty ? 0 : 0),
      max: this.maxQty ? this.maxQty : Number.MAX_VALUE,
      minValidationMessage: this.decrementErrorMessage ? this.decrementErrorMessage : Messages.OrderQuantityDecreaseValidationError,
      maxValidationMessage: this.maxValidationMessage,
      doubleZero: false
    };
  }
  public submit(value: any) {
    this.hide(value);
  }
  public cancel(reason: any) {
    this.hide(reason);
  }
}
