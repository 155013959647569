<div class="order-summary col-12 px-3 pt-3">
    <h2 class="order-summary__title ms-pix-4">Order Summary</h2>
    <div *ngIf="orderData && !isUnloadOrderSummary" id="order-summary">
        <!-- orderId && -->
        <div class="ms-pix-5" [hidden]="!orderData.SurrogateOrderId">
            Order #{{orderData.SurrogateOrderId}}
        </div>
        <span class="ms-pix-6" *ngIf="orderData.ScheduleDate">
            <pos-icon [icon]="icons.clock"></pos-icon>
            {{ orderData.ScheduleDate | tark_date_time }}
        </span>
        <div class="ms-pix-5" *ngIf="orderData.CustomerAccount && orderData.CustomerAccount.Name">
            <span>{{loyaltyAccountTerm}}:</span>
            {{ orderData.CustomerAccount && orderData.CustomerAccount.Name ? orderData.CustomerAccount.Name : 'Not Selected'}}
        </div>
        <div class="ms-pix-5" *ngIf="orderData.Account && orderData.Account.Name">
            <div>
                <span *ngIf="orderData.Account.Type">{{orderData.Account.Type == accountTypes.Tab ? accountTerm : tableTerm}}:</span>
                {{orderData.Account.Name}}
            </div>
        </div>
        <div class="ms-pix-5 bold" *ngIf="orderData.OrderAttributes.PickupTime">
            Online Order - Pickup Time: {{ today === pickupDate ? (orderData.OrderAttributes.PickupTime | tark_time) : (orderData.OrderAttributes.PickupTime | tark_date_time )}}
        </div>
        <div id="order-products">
            <pos-order-summary-products [(orderData)]="orderData" [(seats)]="seats" #orderSummaryProduct [isSelectableProduct]="isSelectableProduct" (changeProductSelection)="changeOrderProductSelection($event)"></pos-order-summary-products>
        </div>
        <div>
            <pos-order-summary-total [orderData]="orderData" [refundedOrderAmount]="refundedOrderAmount"></pos-order-summary-total>
        </div>
    </div>
</div>
