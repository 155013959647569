import { Injectable } from "@angular/core";
import { IKioskNavigation } from "../interfaces/ikiosk-navigation";
import { ChoiceBehavior } from "./choices-behavior";
import { KioskScreen } from "../interfaces";
import { KioskNavigationService } from "../services";
import { DomainConstants } from "src/app/shared";

@Injectable({
    providedIn: 'root'
})
export class UncheckButtonsBehavior extends ChoiceBehavior {

    constructor(private kioskNavigationService: KioskNavigationService) {
        super();
    }

    execute(navObj: IKioskNavigation, orderNavigation: KioskScreen): Promise<void> {
        if (navObj.button.IsSelected) {
            const unselectButtonsBehavior = navObj.button.ChoiceBehaviors.find(x => x.Name == DomainConstants.KioskButtonBehaviors.UncheckButtons.Value);
            const buttonIds = unselectButtonsBehavior.ButtonBehaviors.map(x => x.ChoiceId);
            this.kioskNavigationService.changeButtonSelectionState(buttonIds, false);
        }
        return Promise.resolve();
    }
}
