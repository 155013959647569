import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared';
import { BaseCrudService, InventoryProductService } from 'src/app/shared/services';
import { InventoryVendorProfileProduct } from '../interface';
import { InventoryVendorProfileService } from './inventory-vendor-profile.service';

@Injectable({
    providedIn: 'root'
})
export class InventoryVendorProfileProductService extends BaseCrudService<InventoryVendorProfileProduct> {

    apiUrl = `${RuntimeConstants.API_PATH}inventory-vendor-profile-products`;

    constructor(httpService: HttpClient,
        private inventoryVendorProfileService: InventoryVendorProfileService,
        private inventoryProductService: InventoryProductService) {
        super('inventory-vendor-profile-products', httpService);
    }

    newVendorProfileProduct(): InventoryVendorProfileProduct {
        return {
            Id: 0,
            InventoryProductId: 0,
            InventoryVendorProfileId: 0,
            MinLevel: 0,
            MaxLevel: 0,
            InventoryProduct: this.inventoryProductService.newInventoryProductDetail(),
            InventoryVendorProfile: this.inventoryVendorProfileService.newVendorProfile()
        };
    }

    updateMultipleProfileProducts(profileProducts: Array<InventoryVendorProfileProduct>) {
        return this.httpService.post(`${this.apiUrl}/profile-products`, profileProducts);
    }

    applyProfile(profileId: number) {
        return this.httpService.post(`${this.apiUrl}/${profileId}/apply-profile`, null);
    }
}
