import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalComponent } from './../../../../shared/components/modal/modal-component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { RawIngredientsService } from '../../service';
import * as _ from 'lodash';
import { DatePipe } from '@angular/common';
import { RawIngredient } from '../../interface';
import { AlertsService, ApplicationStateService, FormUtilityService, ModalFormComponent, ModalService, SpinnerService } from 'src/app/shared';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'pos-merge-raw-ingredient',
  templateUrl: './merge-raw-ingredient.component.html',
  styleUrls: ['./merge-raw-ingredient.component.scss']
})
export class MergeRawIngredientComponent extends ModalFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.mergeRawIngredient;
  }
  @ViewChild('mergeRawIngredient') mergeRawIngredient: NgForm;
  ingredient: RawIngredient;
  sourceIngredientId: number = null;

  rawIngredients: Array<RawIngredient> = [];
  constructor(mergeProductModalRef: BsModalRef,
    private rawIngredientService: RawIngredientsService,
    private datePipe: DatePipe,
    private alertService: AlertsService,
    private applicationStateService: ApplicationStateService,
    private spinnerService: SpinnerService,
    modalService: ModalService, formUtilityService: FormUtilityService
  ) {
    super(mergeProductModalRef, modalService, formUtilityService);
  }

  ngOnInit() {
    this.getRawIngredients();
  }

  getRawIngredients() {
    this.spinnerService.show();
    this.rawIngredientService.getRawIngredientsList()
      .subscribe({
        next: (response) => {
          _.forEach(response, (ingredient) => {
            ingredient.Name = ingredient.Name + ' (' + this.datePipe.transform(ingredient.DateAdded, this.applicationStateService.settingParam.DateFormat) + ')';
          });
          this.rawIngredients = _.filter(response, (ingredient) => {
            return ingredient.Id !== this.ingredient.Id;
          });
        }, error: this.alertService.showApiError,
        complete: () => {
          this.spinnerService.hide();
        }
      });
  }

  mergeIngredient() {
    if (this.sourceIngredientId) {
      this.spinnerService.show();
      this.rawIngredientService.mergeRawIngredient(this.sourceIngredientId, this.ingredient.Id)
        .subscribe({
          next: (response) => {
            this.onCancel(true);
          },
          error: this.alertService.showApiError,
          complete: () => {
            this.spinnerService.hide();
          }
        });
    }
  }

  onCancel(reload: boolean) {
    this.hide({ shouldReload: reload });
  }

}
