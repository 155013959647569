<pos-edit-wrapper heading="{{selectedDeliveryType.description}}" (close)="onClose(false)">
    <form #smsActivityEditForm="ngForm" autocomplete="off" (submit)='saveActivity(smsActivityEditForm.valid)'>
        <div class="col-12 pos-modal-body screen-scroll">
            <pos-row>
                <pos-column>
                    <pos-form-field label="Recipients" inputStyle="col-md-8 col-xs-12"
                        [validationMessages]="{required: 'Please enter phone number(s).'}">
                        <input posInput type="text" class="form-control" id="smsRecipients" name="smsRecipients"
                            placeholder="Enter phone number(s) separated by semicolon(;)" [(ngModel)]="smsRecipients"
                            [required]="true" type="text" #recipients="ngModel" />
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Message" inputStyle="col-md-9 col-xs-12" [validationMessages]="{required: 'Please enter message.'}">
                        <textarea posInput class="form-control" name="audioText" rows="5" #audioText
                            (click)="getCaretPos(audioText)" (keyup)="getCaretPos(audioText)" [(ngModel)]="smsText"
                            maxlength="2000" [required]="true"></textarea>
                        <span class="me-pix-20 mt-pix-n30 relative float-right">
                            <span class="font-20 pointer grey">
                                <pos-icon [icon]="icons.messageCode" class="pointer"
                                    (click)="isOpenVariable = !isOpenVariable">
                                </pos-icon>
                            </span>
                            <div style="position: fixed; z-index: 10;" *ngIf="isOpenVariable" class="col-200">
                                <ng-select [items]="eventAttributes" appendTo="body" placeholder="Select event variable"
                                    name="eventVariable" [(ngModel)]="selectedEventAttribute" class="sms-notification"
                                    (change)="changeEventAttribute()" [isOpen]="true">
                                </ng-select>
                            </div>
                        </span>
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-repeat-action-configuration [(eventSubscriptionActionAttributes)]="eventSubscriptionActionAttributes"
                        [isFormSubmitted]="smsActivityEditForm.submitted" #RepeatActionConfiguration
                        [isRecoverableEvent]="isRecoverableEvent">
                    </pos-repeat-action-configuration>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save</button>
            <button type="button" (click)="onClose(false)"
                class="btn-secondary btn-lg btn btn-cancel-modal pos-button">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
