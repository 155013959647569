import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';
import { EquipmentType } from '../interface/equipment-type';
import { Observable } from 'rxjs';

@Injectable()
export class EquipmentTypeService extends BaseCrudService<EquipmentType> {

    equipmentTypeApiUrl = '';
    constructor(httpService: HttpClient) {
        super('equipmenttype', httpService);
        this.equipmentTypeApiUrl = RuntimeConstants.API_PATH + 'equipmenttype';
    }
    newEquipmentType() {
        return {
            Id: 0,
            Name: '',
            EquipmentProperties: []
        };
    }
    getEquipmentTypes(): Observable<any> {
        return this.httpService.get(this.equipmentTypeApiUrl);
    }

    getNameExists(id: number,name: string): Observable<boolean>{
        return this.httpService.get<boolean>(this.apiEndpoint+ `/${id}/name/${name}/exists`);
    }
}
