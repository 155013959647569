import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';
import { KioskScreenChoice } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class OrderKioskScreenChoiceService extends BaseCrudService<KioskScreenChoice> {

  constructor(httpClient: HttpClient) {
    super('kiosk-screen-choice', httpClient);
  }

  newScreenChoice(): KioskScreenChoice {
    return {
      Id: 0,
      ButtonId: null,
      ConfigId: null,
      ScreenId: null,
      Ordinal: null
    };
  }
}
