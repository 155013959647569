import { IHaveId } from '../models';
import { OnInit, Injector, Host, Self, AfterContentInit, Directive, OnDestroy } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, tap, map, finalize } from 'rxjs/operators';
import { BaseCrudService, PrintTableService, ApplicationStateService, AlertsService } from '../services';
import { GridColumn, EditColumn, DeleteColumn, LinkColumn } from '@tarktech/tark-ng-utils';
import { ConfirmDeleteModalComponent } from './confirm-delete-modal/confirm-delete-modal.component';
import { BaseGridComponent } from './base-grid.component';
import { SpinnerService } from './spinner/spinner.service';
import { ModalService } from './modal/modal.service';
import { ActivatedRoute } from '@angular/router';

@Directive()
export abstract class BaseListComponent<TModel extends IHaveId> extends BaseGridComponent implements OnInit, AfterContentInit {

    protected confirmationMessage: string;
    protected itemForDelete: TModel;
    protected deleteSuccessMessage: string;
    private dataSubject = new Subject<Array<TModel>>();
    private gridListColumns: Array<GridColumn> = [];
    protected gridData: TModel[];
    protected isAddIconVisible = true;
    protected isPrintIconVisible = true;

    constructor(protected crudService: BaseCrudService<TModel>,
        protected alertService: AlertsService,
        protected spinnerService: SpinnerService,
        protected modalService: ModalService,
        protected applicationStateService: ApplicationStateService,
        protected printService: PrintTableService,
        protected route: ActivatedRoute
    ) {
        super(applicationStateService, route);
    }

    ngOnInit(): void { }

    ngAfterContentInit(): void {
        this.reload();
    }

    protected get data$(): Observable<Array<TModel>> {
        return this.dataSubject.asObservable();
    }

    protected reload(isSilentReload = false): void {
        if (!isSilentReload)
            this.spinnerService.show();
        this.crudService.getAll()
            .subscribe({
                next: data => {
                    this.gridData = data;
                    this.dataSubject.next(data ? data : []);
                }, error: this.alertService.showApiError,
                complete: () => {
                    if (!isSilentReload)
                        this.spinnerService.hide();
                }
            });
    }

    protected delete(id: number): void {
        this.spinnerService.show();
        this.crudService.delete(id)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: x => {
                    this.showDeletedSuccessfullyMessage();
                    this.afterDelete();
                    this.reload();
                }, error: this.alertService.showApiError
            });
    }

    protected configureGridColumns(): Array<GridColumn> {
        const gridColumns = this.getGridColumns();
        this.gridListColumns = [...gridColumns];
        gridColumns.push(this.getEditColumn());
        gridColumns.push(this.getDeleteColumn());
        return gridColumns;
    }

    protected confirmDelete(data: TModel): void {
        this.itemForDelete = data;
        const confirmDelete = this.modalService.show(ConfirmDeleteModalComponent, {
            animated: false,
            class: 'vertical-center',
            'backdrop': 'static',
            initialState: {
                message: this.getConfirmDeleteMessage(data)
            }
        });
        confirmDelete.close.subscribe(res => {
            if (res && res.shouldDelete) {
                this.delete(this.itemForDelete.Id);
            }
            this.itemForDelete = null;
        });
    }

    private showDeletedSuccessfullyMessage(): void {
        this.alertService.renderSuccessMessage(this.deleteSuccessMessage);
    }

    private getEditColumn(): EditColumn {
        const editColumn = new EditColumn({
            Clicked: (data) => {
                this.editItem(data.Id, data);
            },
            Width: this.isAddIconVisible ? '57px' : '0px',
            CellClass: this.isAddIconVisible ? 'cell-edit cell-padding' : 'cell-edit pi-0',
            AddIcon: (this.isAddIconVisible ? new LinkColumn({
                Clicked: () => {
                    this.editItem(0);
                },
                ToolTip: 'New'
            }) : null)
        });
        return editColumn;
    }

    private getDeleteColumn(): DeleteColumn {
        const deleteColumn = new DeleteColumn({
            Clicked: (data) => {
                this.confirmDelete(data);
            },
            Width: this.isPrintIconVisible ? '83px' : '0px',
            CellClass: this.isPrintIconVisible ? 'cell-delete cell-padding' : 'cell-delete pi-0',
            PrintIcon: (this.isPrintIconVisible ? new LinkColumn({
                Clicked: () => {
                    this.printData();
                },
                ToolTip: 'Print'
            }) : null)
        });
        return deleteColumn;
    }

    protected printData() {
        this.printService.printEmitter.next({ gridColumns: this.gridListColumns, gridData: this.gridData });
    }

    protected afterDelete() { }

    abstract getGridColumns(): Array<GridColumn>;
    abstract editItem(id: number, data?: TModel): void;
    abstract getConfirmDeleteMessage(data: TModel): string;
}
