import { Component, Input, OnInit } from '@angular/core';
import { OrderInvoice } from 'src/app/orders/interface';

@Component({
  selector: 'pos-settle-total',
  templateUrl: './settle-total.component.html',
  styleUrls: ['./settle-total.component.scss']
})
export class SettleTotalComponent implements OnInit {

  @Input() orderData: OrderInvoice;

  constructor() { }

  ngOnInit(): void {
  }

}
