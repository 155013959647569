<div class="kiosk-screen" *ngIf="!isSlideShow">
    <pos-kiosk-screen-template *ngIf="kioskScreen?.Id" [screenId]="kioskScreen.Id"
        [templateType]="kioskScreen.TemplateType" [screenConfigs]="kioskScreen.KioskScreenConfigs"
        [screenButtons]="kioskScreen.KioskScreenChoices"></pos-kiosk-screen-template>
</div>
<div *ngIf="isSlideShow" class="height-100vh" (click)="onSlideShowTouch()">
    <pos-kiosk-message-strip [messageText]="messageStripConfig.Text" [fontColor]="messageStripConfig.FontColor"
        [backgroundColor]="messageStripConfig.BackgroundColor"
        [fontSize]="messageStripConfig.FontSize"></pos-kiosk-message-strip>
    <div *ngIf="slideShowData && slideShowData.length > 0">
        <pos-media-rotator [media]="slideShowData" />
    </div>
</div>
