import { Injectable } from "@angular/core";
import { IKioskNavigation } from "../interfaces/ikiosk-navigation";
import { ChoiceBehavior } from "./choices-behavior";
import { AlertsService, DomainConstants } from "src/app/shared";
import { BehaviorPromiseProviderService, KioskNavigationService, OrderKioskChoiceBehaviorService } from "../services";

@Injectable({
    providedIn: 'root'
})
export class InventoryDecrementBehavior extends ChoiceBehavior {

    constructor(private orderKioskChoiceBehaviorService: OrderKioskChoiceBehaviorService,
        private kioskNavigationService: KioskNavigationService,
        private alertService: AlertsService,
        private behaviorPromiseProviderService: BehaviorPromiseProviderService) {
        super()
    }

    execute(navObj: IKioskNavigation, orderNavigation: any): Promise<void> {
        if (this.terminalType == DomainConstants.TerminalTypes.ORDER_KIOSK.Name) {
            const orderId = this.kioskNavigationService.versionedVal.Obj.Order.Id;
            this.orderKioskChoiceBehaviorService.executeBehavior({ BehaviorId: this.behaviorId, OrderId: orderId })
                .subscribe({
                    next: () => { this.behaviorPromiseProviderService.resolvePromise() },
                    error: (e) => {
                        this.behaviorPromiseProviderService.rejectPromise();
                        this.alertService.showApiError(e)
                    }
                });
        } else this.behaviorPromiseProviderService.resolvePromise();
        return this.behaviorPromiseProviderService.executionPromise;
    }
}

