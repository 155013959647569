import { ExternalApiListComponent } from '../configurator/external-api/components/external-api-list/external-api-list.component';
import { ExternalApiEditComponent } from './external-api/components/external-api-edit/external-api-edit.component';
import { Permissions, Levels } from '../shared/constants';
import { CentralApiConfigurationComponent } from './central-api-configuration';
import { BlacklistedIpAddressesComponent } from './blacklisted-ip-addresses/components/blacklisted-ip-addresses.component';
import { LicenseComponent } from './license';
import { CacheFlushComponent } from './cache-flush/components/cache-flush/cache-flush.component';
import { ExternalLoginsComponent } from './external-logins/components';
import { EmailTemplatesListComponent } from './email-templates';
import { EmailTemplatesEditComponent } from './email-templates/components/email-templates-edit';
import { RabbitMqConfigurationComponent } from './rabbit-mq-configuration/components/rabbit-mq-configuration/rabbit-mq-configuration.component';
import { DatabaseComponent } from './database/components/database/database.component';
import { HardwareListComponent } from './hardware/components/hardware-list/hardware-list.component';
import { HardwareInterfaceListComponent } from './hardware-interfaces/components/hardware-interface-list/hardware-interface-list.component';
import { CashDrawerComponent } from './cash-drawer';
import { PrintingComponent } from './printing/components/printing/printing.component';
import { BrandingComponent } from './branding';
import { ColorsComponent } from './colors/components/colors/colors.component';
import { LayoutComponent } from './layout/components/layout/layout.component';
import { IconsComponent } from './icons/components/icons/icons.component';
import { TracksComponent } from './tracks/components/tracks/tracks.component';
import { PlaylistsComponent } from './playlists/components/playlists/playlists.component';
import { PlaylistEditComponent } from './playlists/components/playlist-edit/playlist-edit.component';
import { PlaylistTrackComponent } from './playlists/components/playlist-track/playlist-track.component';
import { TrackEditComponent } from './tracks/components/track-edit/track-edit.component';
import { DjSchemesListComponent } from './dj-schemes/components/dj-schemes-list/dj-schemes-list.component';
import { DjSchemeEditComponent } from './dj-schemes/components/dj-scheme-edit/dj-scheme-edit.component';
import { WhitelistedIpAddressListComponent } from './whitelisted-ip-addresses/components/whitelisted-ip-address-list/whitelisted-ip-address-list.component';
import { SlideShowListComponent } from './digital-signage/components/slide-show-list/slide-show-list.component';
import { SlideShowEditComponent, SlideShowImageComponent } from './digital-signage';
import { TerminalsEditComponent } from './terminals/components/terminals-edit/terminals-edit.component';
import { TerminalsListComponent } from './terminals/components/terminals-list/terminals-list.component';
import { TerminalDeviceMappingComponent } from './terminals/components/terminal-device-mapping/terminal-device-mapping.component';
import { TaxonomyComponent } from './taxonomy/components/taxonomy/taxonomy.component';
import { DeliveryComponent } from './delivery/components/delivery/delivery.component';
import { UserSettingsComponent } from './user-settings/components/user-settings/user-settings.component';
import { EndOfDayComponent } from './end-of-day/components/end-of-day/end-of-day.component';
import { BaselineExpensesComponent } from './baseline-expenses/components/baseline-expenses/baseline-expenses.component';
import { AccountsAndTabsComponent } from './accounts-and-tabs/components/accounts-and-tabs/accounts-and-tabs.component';
import { PaymentsComponent } from './payments/components/payments/payments.component';
import { CardBasedLoginComponent } from './card-based-login/components/card-based-login/card-based-login.component';
import { SalesTaxesComponent } from './taxes/components/sales-taxes/sales-taxes.component';
import { Route } from '@angular/router';
import { DaypartsComponent } from './dayparts/components/dayparts/dayparts.component';
import { ConfiguratorBaseComponent } from './configurator-base/configurator-base.component';
import { NotificationComponent } from './notification/notification.component';
import { RevenueCenterListComponent } from './revenue-centers/components/revenue-centers/revenue-center-list.component';
import { ReportGroupComponent, ReportsConfigurationComponent } from './reports/components';
import { ReportGroupEditComponent } from './reports/components/report-group-edit';
import { ReportEditComponent } from './reports/components/report-edit';
import { FormatsComponent } from './localization/components/formats/formats.component';
import { DashboardDataSourceComponent } from './custom-datasource/components/data-source/dashboard-data-source.component';
import { DashboardDataSourceEditComponent } from './custom-datasource/components/dashboard-data-source-edit/dashboard-data-source-edit.component';
import { ProductBuildQueueConfigComponent } from '../product-build-queue/components/product-build-queue-config/product-build-queue-config.component';
import { OrderWorkflowComponent } from './workflow/components/order-workflow/order-workflow.component';
import { InventoryWorkflowComponent } from './workflow/components/inventory-workflow/inventory-workflow.component';
import { MakeTableWorkflowComponent } from './workflow/components/make-table-workflow/make-table-workflow.component';
import { GeneralWorkflowComponent } from './workflow/components/general-workflow/general-workflow.component';
import { GiftCardWorkflowComponent } from './workflow/components/gift-card-workflow/gift-card-workflow.component';
import { PickScreenWorkflowComponent } from './workflow/components/pick-screen-workflow/pick-screen-workflow.component';
import { ConfirmationScreenComponent } from './workflow/components/confirmation-screen';
import { CameraAnnotationListComponent } from './camera-annotation/components/camera-annotation-list/camera-annotation-list.component';
import { CameraAnnotationEditComponent } from './camera-annotation/components/camera-annotation-edit';
import { SmsTemplatesListComponent } from './sms-templates/components/sms-templates-list/sms-templates-list.component';
import { SmsTemplateEditComponent } from './sms-templates/components/sms-template-edit/sms-template-edit.component';
import { FeaturesComponent } from './features/components/features/features.component';
import { HardwareEditComponent } from './hardware';
import { ServeScreenWorkflowComponent } from './workflow/components/serve-screen-workflow/serve-screen-workflow.component';

export const CONFIGURATOR_CHILDREN: Array<Route> = [
    {
        path: 'external-api',
        component: ExternalApiListComponent,
        data: {
            group: 'external-api',
            permissions: [{ 'Name': Permissions.SystemConfigurationApiManager, 'Level': Levels.Access }]
        }
    },
    {
        path: 'external-api/:id',
        component: ExternalApiEditComponent,
        data: {
            group: 'external-api',
            permissions: [{ 'Name': Permissions.SystemConfigurationApiManager, 'Level': Levels.Edit }]
        }
    },
    {
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationCentralAPIConfiguration, 'Level': Levels.Access }]
        },
        path: 'central-api-configuration',
        component: CentralApiConfigurationComponent,
    },
    {
        path: 'blacklisted-ip-addresses',
        component: BlacklistedIpAddressesComponent,
        data: {
            permissions: [{ 'Name': Permissions.SecurityBlacklistedAddresses, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'license',
        component: LicenseComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationLicensing, 'Level': Levels.Access }]
        }
    },
    {
        path: 'flush-cache',
        component: CacheFlushComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationCaching, 'Level': Levels.Access },
            { 'Name': Permissions.FlushCache, 'Level': Levels.Access }]
        }
    },
    {
        path: 'external-logins',
        component: ExternalLoginsComponent,
        data: {
            permissions: [{ 'Name': Permissions.SecurityExternalLogins, 'Level': Levels.Access }]
        }
    },
    {
        path: 'email-templates',
        component: EmailTemplatesListComponent,
        data: {
            group: 'email-template',
            permissions: [{ 'Name': Permissions.SystemConfigurationEmailTemplates, 'Level': Levels.Access }]
        }
    },
    {
        path: 'email-templates/:id',
        component: EmailTemplatesEditComponent,
        data: {
            group: 'email-template',
            permissions: [{ 'Name': Permissions.SystemConfigurationEmailTemplates, 'Level': Levels.Access }]
        }
    },
    {
        path: 'rabbitMQ-configuration',
        component: RabbitMqConfigurationComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationRabbitMQConfiguration, 'Level': Levels.Access }]
        }
    },
    {
        path: 'database',
        component: DatabaseComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationDatabase, 'Level': Levels.Access }]
        }
    },
    {
        path: 'hardwares',
        component: HardwareListComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationHardware, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'hardwares/:id',
        component: HardwareEditComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationHardware, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'hardware-interfaces',
        component: HardwareInterfaceListComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationHardwareInterfaces, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'cash-drawer',
        component: CashDrawerComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationCashDrawer, 'Level': Levels.Access }]
        }
    },
    {
        path: 'printing',
        component: PrintingComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationPrinting, 'Level': Levels.Access }]
        }
    },
    {
        path: 'branding',
        component: BrandingComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationBranding, 'Level': Levels.Access }]
        }
    },
    {
        path: 'colors',
        component: ColorsComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationColors, 'Level': Levels.Access }]
        }
    },
    {
        path: 'layout',
        component: LayoutComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationLayout, 'Level': Levels.Access }]
        }
    },
    {
        path: 'icons',
        component: IconsComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationIcons, 'Level': Levels.Access }]
        }
    },
    {
        path: 'tracks',
        component: TracksComponent,
        data: {
            group: 'track',
            permissions: [{ 'Name': Permissions.SystemConfigurationPlaylists, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'playlist/:id/tracks',
        component: TracksComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationPlaylists, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'tracks/:id',
        component: TrackEditComponent,
        data: {
            group: 'track',
            permissions: [{ 'Name': Permissions.SystemConfigurationPlaylists, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'playlist/:playlistId/tracks/:id',
        component: TrackEditComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationPlaylists, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'playlists',
        component: PlaylistsComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationPlaylists, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'playlists/:id',
        component: PlaylistEditComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationPlaylists, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'playlist/tracks/:id',
        component: PlaylistTrackComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationPlaylists, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'dj-scheme',
        component: DjSchemesListComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationDJSchemes, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'dj-scheme/:id',
        component: DjSchemeEditComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationDJSchemes, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'whitelisted-ip-addresses',
        component: WhitelistedIpAddressListComponent,
        data: {
            permissions: [{ 'Name': Permissions.SecurityWhitelistedAddresses, 'Level': Levels.Access }]
        }
    },
    {
        path: 'digital-signage',
        component: SlideShowListComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationDigitalSignage, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'digital-signage/:id',
        component: SlideShowEditComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationDigitalSignage, 'Level': Levels.Edit }],
        }
    },
    {
        path: 'digital-signage/image/:id',
        component: SlideShowImageComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationDigitalSignage, 'Level': Levels.Edit }],
        }
    },
    {
        path: 'terminal',
        component: TerminalsListComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationTerminals, 'Level': Levels.ReadOnly }],
            group: 'terminal'
        }
    },
    {
        path: 'terminal/:id',
        component: TerminalsEditComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationTerminals, 'Level': Levels.ReadOnly }],
            group: 'terminal'
        }
    },
    {
        path: 'terminal/device-mapping/:id',
        component: TerminalDeviceMappingComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationTerminals, 'Level': Levels.ReadOnly }],
            group: 'terminal'
        }
    },
    {
        path: 'terminal/product-build-queue/:id',
        component: ProductBuildQueueConfigComponent,
        data: {
           
        }
    },
    {
        path: 'taxonomy',
        component: TaxonomyComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationTaxonomy, 'Level': Levels.Access }]
        }
    },
    {
        path: 'delivery',
        component: DeliveryComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationDelivery, 'Level': Levels.Access }]
        }
    },
    {
        path: 'user-settings',
        component: UserSettingsComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationUserSettings, 'Level': Levels.Access }]
        }
    },
    {
        path: 'end-of-day',
        component: EndOfDayComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationEndOfDay, 'Level': Levels.Access }]
        }
    },
    {
        path: 'baseline-expenses',
        component: BaselineExpensesComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationBaselineExpenses, 'Level': Levels.Access }]
        }
    },
    {
        path: 'accounts-and-tabs',
        component: AccountsAndTabsComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationAccountsAndTab, 'Level': Levels.Access }]
        }
    },
    {
        path: 'payments',
        component: PaymentsComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationPayments, 'Level': Levels.Access }]
        }
    },
    {
        path: 'revenue-centers',
        component: RevenueCenterListComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationRevenueCenters, 'Level': Levels.Access }]
        }
    },
    {
        path: 'card-based-login',
        component: CardBasedLoginComponent,
        data: {
            permissions: [{ 'Name': Permissions.SecurityCardBasedLogins, 'Level': Levels.Access }]
        }
    },
    {
        path: 'sales-taxes',
        component: SalesTaxesComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationTaxes, 'Level': Levels.Access }]
        }
    },
    {
        path: 'notification',
        component: NotificationComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationNotification, 'Level': Levels.Access }]
        }
    },
    {
        path: 'dayparts',
        component: DaypartsComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationDayparts, 'Level': Levels.Access }]
        }
    },
    {
        path: 'report-groups',
        component: ReportGroupComponent,
        data: {
            group: 'report-group',
            permissions: [{ 'Name': Permissions.SystemConfigurationReportGroups, 'Level': Levels.Access }]
        }
    },
    {
        path: 'report-groups/:id',
        component: ReportGroupEditComponent,
        data: {
            group: 'report-group',
            permissions: [{ 'Name': Permissions.SystemConfigurationReportGroups, 'Level': Levels.Access }]
        }
    },
    {
        path: 'reports',
        component: ReportsConfigurationComponent,
        data: {
            group: 'report',
            permissions: [{ 'Name': Permissions.SystemConfigurationReports, 'Level': Levels.Access }]
        }
    },
    {
        path: 'reports/:id',
        component: ReportEditComponent,
        data: {
            group: 'report',
            permissions: [{ 'Name': Permissions.SystemConfigurationReports, 'Level': Levels.Access }]
        }
    },
	{
        path: 'formats',
        component: FormatsComponent,
        data: {
            group: 'formats',
        }
    },
    {
        path: 'data-source',
        component: DashboardDataSourceComponent,
        data: {
            permissions: [{ 'Name': Permissions.DashboardDataSource, 'Level': Levels.Access }],
            group: 'data-source'
        }
    },
    {
        path: 'data-source/:id',
        component: DashboardDataSourceEditComponent,
        data: {
            permissions: [{ 'Name': Permissions.DashboardDataSource, 'Level': Levels.Edit }],
            group: 'data-source'
        }
    },
    {
        path: 'order-workflow',
        component: OrderWorkflowComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationOrderWorkflow, 'Level': Levels.Access }]
        }
    },
    {
        path: 'inventory-workflow',
        component: InventoryWorkflowComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationInventoryWorkflow, 'Level': Levels.Access }]
        }
    },
    {
        path: 'maketable-workflow',
        component: MakeTableWorkflowComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationMakeTableWorkflow, 'Level': Levels.Access }]
        }
    },
    {
        path: 'pickscreen-workflow',
        component: PickScreenWorkflowComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationPickScreenWorkflow, 'Level': Levels.Access }]
        }
    },
    {
        path: 'servescreen-workflow',
        component: ServeScreenWorkflowComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationServeScreenWorkflow, 'Level': Levels.Access }]
        }
    },
    {
        path: 'general-workflow',
        component: GeneralWorkflowComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationGeneralWorkflow, 'Level': Levels.Access }]
        }
    },
    {
        path: 'gift-card-workflow',
        component: GiftCardWorkflowComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationGiftCardWorkflow, 'Level': Levels.Access }]
        }
    },
    {
        path: 'confirmation-screen-workflow',
        component: ConfirmationScreenComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationConfirmationScreenWorkflow, 'Level': Levels.Access }]
        }
    },
    {
        path: 'camera-annotation',
        component: CameraAnnotationListComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationCameraAnnotation, 'Level': Levels.Access }],
            group: 'camera-annotation'
        }
    },
    {
        path: 'camera-annotation/:id',
        component: CameraAnnotationEditComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationCameraAnnotation, 'Level': Levels.Edit }],
            group: 'camera-annotation'
        }
    },
    {
        path: 'sms-templates',
        component: SmsTemplatesListComponent,
        data: {
            group: 'sms-template',
            permissions: [{ 'Name': Permissions.SystemConfigurationSMSTemplates, 'Level': Levels.Access }]
        }
    },
    {
        path: 'sms-templates/:id',
        component: SmsTemplateEditComponent,
        data: {
            group: 'sms-template',
            permissions: [{ 'Name': Permissions.SystemConfigurationSMSTemplates, 'Level': Levels.Access }]
        }
    },
    {
        path: 'features',
        component: FeaturesComponent,
        data: {
            permissions: [{ 'Name': Permissions.SystemConfigurationFeatures, 'Level': Levels.Access }]
        }
    }    
];

export const CONFIGURATOR_STATES: Array<Route> = [
    {
        path: '',
        component: ConfiguratorBaseComponent,
        data: {
            permissions: [{ 'Name': Permissions.ManageSystemConfiguration, 'Level': Levels.Access },
            { 'Name': Permissions.ManageConsole, 'Level': Levels.Access }]
        },
        children: CONFIGURATOR_CHILDREN
    }];
