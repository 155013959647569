import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RuntimeConstants } from '../constants';
import { IHaveId } from '../models';
import { BaseSearchService } from './base-search.service';

export class BaseCrudService<TModel extends IHaveId> extends BaseSearchService<TModel> {
    constructor(baseUrl: string, httpService: HttpClient) {
        super(baseUrl, httpService);
    }

    insert(model: TModel): Observable<any> {
        return this.httpService.post(this.apiEndpoint, model);
    }

    insertAndGet(model: TModel): Observable<TModel> {
        return this.httpService.post<TModel>(this.apiEndpoint, model);
    }

    update(model: TModel): Observable<any> {
        return this.httpService.put(`${this.apiEndpoint}/${model.Id}`, model);
    }

    insertOrUpdate(model: TModel, file?: File, isMultipartRequest: boolean = false): Observable<any> {
        if (!file && !isMultipartRequest) {
            if (model.Id) {
                return this.update(model);
            } else {
                return this.insert(model);
            }
        } else {
            return this.saveWithFile(model, file);
        }
    }

    saveWithFile(modal: TModel, file: File): Observable<any> {
        const formData = this.prepareIconFormData(modal, file);
        return this.httpService.post<TModel>(this.apiEndpoint, formData);
    }

    delete(id: number): Observable<any> {
        return this.httpService.delete(`${this.apiEndpoint}/${id}`);
    }

    private prepareIconFormData(param: TModel, iconFile: File): FormData {
        const formData = new FormData();
        if (iconFile) {
            formData.append('file', iconFile);
        }
        formData.append('model', JSON.stringify(param));
        return formData;
    }
}
