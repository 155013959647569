import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forEach } from 'lodash';
import { finalize } from 'rxjs/operators';
import { TerminalsService } from 'src/app/configurator/terminals/services/terminals.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { DomainConstants } from 'src/app/shared/constants/domain-constants';
import { ProductBuildQueueGridInfo } from '../../interfaces/product-build-queue-grid-info';

@Component({
  selector: 'pos-product-build-queue-config',
  templateUrl: './product-build-queue-config.component.html',
  styleUrls: ['./product-build-queue-config.component.scss']
})
export class ProductBuildQueueConfigComponent implements OnInit {
  terminalId: number;
  terminalName: string;
  gridColumns: number = 0;
  gridRows: number = 0;
  constructor(
    private spinnerService: SpinnerService,
    private terminalService: TerminalsService,
    private route: ActivatedRoute,
    protected router: Router
  ) {
    const navigation = router.getCurrentNavigation();
    this.terminalId = route?.snapshot?.params?.id ? parseInt(route.snapshot.params.id, 10) : 0;
    this.terminalName = (navigation?.extras?.state && navigation?.extras?.state.name) ? navigation?.extras?.state.name : '';
  }

  ngOnInit(): void {
    this.spinnerService.show();
    this.terminalService.getTerminalProperties(this.terminalId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          const terminalProperties = response ? response : [];
          forEach(terminalProperties, (property) => {
            if (property.PropertyKey === DomainConstants.TerminalProperties.PRODUCT_BUILD_QUEUE_GRID.Key) {
              const gridInfo: ProductBuildQueueGridInfo = JSON.parse(property.PropertyValue);
              if (gridInfo) {
                this.gridColumns = gridInfo.Columns;
                this.gridRows = gridInfo.Rows;
              }
            }
          });
        }
      });
  }

  cancelTerminalEdit() {
    this.router.navigate(['terminal'], { relativeTo: this.route.parent });
  }
}
