<pos-edit-wrapper heading="Change Order Method" (close)="closeModal()">
    <form #orderMethodForm="ngForm" (ngSubmit)="saveOrderMethod(orderMethodForm.valid)">
        <div class="overflow-hidden col-12 pos-modal-body pt-pix-10">
            <pos-row class="g-0" *ngIf="order">
                <pos-column>
                    <pos-row>
                        <pos-column lg="3" xs="5">
                            <label>Order:</label>
                        </pos-column>
                        <pos-column xs="6">
                            <label>#{{order.SurrogateOrderId}}</label>
                        </pos-column>
                    </pos-row>
                </pos-column>
                <pos-column>
                    <pos-row>
                        <pos-column lg="3" xs="5">
                            <label>Revenue Center:</label>
                        </pos-column>
                        <pos-column xs="6">
                            <label>{{ order.RevenueCenter }}</label>
                        </pos-column>
                    </pos-row>
                </pos-column>
                <pos-column>
                    <pos-row>
                        <pos-column lg="3" xs="5">
                            <label>Payment Type:</label>
                        </pos-column>
                        <pos-column xs="6">
                            <label>{{ order.Type == 'None' ? '' : order.Type }}</label>
                        </pos-column>
                    </pos-row>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Order Method" [appendPaddingTop]="false">
                        <ng-select posInput [clearable]="false" [items]="orderMethods" name="orderMethodId"
                            appendTo="body" bindLabel="Name" bindValue="Id" placeholder="Select order method"
                            [(ngModel)]="orderMethodId" [required]="true">
                        </ng-select>
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" value="Save">Save</button>
            <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" value="Cancel"
                (click)="closeModal()">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
