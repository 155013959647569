<div>
  <div>
    <button class="close" data-dismiss="modal" type="button" (click)="onSubmit()">
      <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
    </button>
    <h4 class="modal-content__heading">Duplicate Ingredients</h4>
  </div>
  <div class="modal-body text-left">
    <ng-table id="orderDietaryRestrictions" [paginator]="false" [search]="false" [scrollHeight]="'600px'"
      [columns]="ingredientsColumns" [data]="ingredients">
    </ng-table>
  </div>
  <div class="col-12 action-button-container popup-footer pb-pix-10">
    <button class="btn btn-lg btn-primary btn-cancel-modal pos-button" (click)="onSubmit()" type="button">Ok</button>
  </div>
  <div class="clearfix"></div>
</div>
