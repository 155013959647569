import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridColumn, TableComponent, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import {
  plusWhite,
  printWhite,
  editWhite,
  deleteWhite,
  check,
  fileExportWhite, rssSquare
} from 'src/app/shared/components/icon';
import {
  AlertsService,
  ApplicationStateService,
  AuthenticationService,
  BaseListComponent,
  Permissions,
  Levels,
  Messages,
  ModalService,
  PrintTableService,
  SpinnerService,
  ConfirmDeleteModalComponent,
  ExportService,
} from 'src/app/shared';
import { DataSourceSubscribe } from '../../interface/data-source-subscribe';
import { DataSourceSubscribeService } from '../../service/data-source-subscribe.service';
import cronstrue from 'cronstrue';
import { finalize } from 'rxjs/operators';
@Component({
  selector: 'pos-data-source-subscribe',
  templateUrl: './data-source-subscribe.component.html',
  styleUrls: ['./data-source-subscribe.component.scss'],
})
export class DataSourceSubscribeComponent extends BaseListComponent<DataSourceSubscribe> implements OnInit {

  subscribeListFilter: Array<DataSourceSubscribe> = [];
  subscribeColumns: Array<GridColumn> = [];
  subscribeList: Array<DataSourceSubscribe> = [];
  dataSourceId: number;
  isInactive = false;
  printDataSourceSubscribeColumns: Array<GridColumn>;
  dataSourceName: string;
  icons = {
    plusWhite,
    printWhite,
    editWhite,
    deleteWhite,
    fileExportWhite,
    check, rssSquare
  };
  permission = {
    name: Permissions.AutomatedExportsSubscriptions,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };

  @ViewChild('operationTemplate', { static: true }) operationTemplate: TemplateRef<any>;
  @ViewChild('operationHeaderTemplate', { static: true }) operationHeaderTemplate: TemplateRef<any>;
  @ViewChild('IsActiveTemplate', { static: true }) isActiveTemplate: TemplateRef<any>;
  @ViewChild('SendEmptyResultsTemplate', { static: true }) sendEmptyResultsTemplate: TemplateRef<any>;
  @ViewChild('dataSourceSubscribeTable', { static: true }) dataSourceSubscribeTable: TableComponent;

  constructor(private authenticationService: AuthenticationService,
    protected dataSourceSubscribeService: DataSourceSubscribeService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    private router: Router,
    protected applicationStateService: ApplicationStateService,
    protected printService: PrintTableService,
    protected route: ActivatedRoute,
    private exportService: ExportService
  ) {
    super(
      dataSourceSubscribeService,
      alertService,
      spinnerService,
      modalService,
      applicationStateService,
      printService,
      route
    );
    const navigation = router.getCurrentNavigation();
    this.dataSourceId = parseInt(route.snapshot.params.id, 10) ?? 0;
    this.isInactive = (navigation?.extras?.state && navigation?.extras?.state.isInActive) ?? false;
    this.dataSourceName = (navigation?.extras?.state && navigation?.extras?.state.Name) ?? '';
  }

  ngOnInit(): void {
    this.deleteSuccessMessage = Messages.DataSourceSubscriptionDeleted;
    this.subscribeColumns = this.configureGridColumns();
    this.dataSourceSubscribeTable.context = this.gridContext;
  }

  reload() {
    this.spinnerService.show();
    this.dataSourceSubscribeService.getAllSubscriptions()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.subscribeList = res;
          this.subscribeList.forEach(x => x.DataSourceName = x.DataSource.Name);
          this.filterSubscriptionList();
          this.subscribeList.forEach((data) => {
            data.DataSourceName = data.DataSource.Name;
            if (data.Schedule) {
              try {
                data.Schedule = cronstrue.toString(data.Schedule);
              } catch (ex) {
                throw new Error('Invalid schedule expression found in data source subscription.');
              }
            }
          });
        }, error: this.alertService.showApiError
      });
  }

  getGridColumns() {
    return this.subscribeColumns;
  }

  configureGridColumns() {
    const activeColumn = new TemplateColumn({
      itemTemplate: this.isActiveTemplate,
      Width: '90px',
      HeaderText: 'Active',
      IsSortable: true,
      Field: 'IsActive',
      TextAlign: TextAlign.Center,
      CellClass: 'cell-padding',
    });
    const sendEmptyResultsColumn = new TemplateColumn({
      itemTemplate: this.sendEmptyResultsTemplate,
      Width: '19%',
      HeaderText: 'Send Empty Results',
      IsSortable: true,
      Field: 'SendEmptyResults',
      TextAlign: TextAlign.Center,
      CellClass: 'cell-padding',
    });

    const operationColumn = new TemplateColumn({
      itemTemplate: this.operationTemplate,
      Width: '147px',
      TextAlign: TextAlign.Center,
      headerTemplate: this.operationHeaderTemplate,
      CellClass: 'cell-padding',
    });

    const columns = [
      new GridColumn({
        HeaderText: 'Data Source',
        Field: 'DataSourceName',
        IsSortable: true,
        Width: '30%',
      }),
      new GridColumn({ HeaderText: 'Schedule', Field: 'Schedule', IsSortable: true, Width: '40%' }),
      sendEmptyResultsColumn,
      activeColumn,
    ];
    this.printDataSourceSubscribeColumns = [...columns];
    if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      columns.push(operationColumn);
    }
    return columns;
  }

  filterSubscriptionList() {
    if (!this.isInactive) {
      this.subscribeListFilter = this.subscribeList.filter(x => x.IsActive).slice();
    } else {
      this.subscribeListFilter = [...this.subscribeList];
    }
  }

  editItem(id: number, data?: DataSourceSubscribe): void {
    this.router.navigate([id], { relativeTo: this.route, state: { isInActive: this.isInactive } });
  }

  public getConfirmDeleteMessage(data: DataSourceSubscribe): string {
    return Messages.DataSourceSubscriptionConfirmDelete;
  }

  close() {
    this.router.navigate(['manage/console']);
  }

  printData() {
    this.printService.printEmitter.next({
      gridColumns: this.printDataSourceSubscribeColumns,
      gridData: this.subscribeListFilter,
    });
  }

  exportCSV() {
    this.exportService.exportCSV('data-source-subscribe', this.subscribeListFilter, null, this.printDataSourceSubscribeColumns);
  }

  deleteSubscription(data: DataSourceSubscribe): void {
    const modalRef = this.modalService.getModalWrapper(ConfirmDeleteModalComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      initialState: {
        message: Messages.DataSourceSubscriptionConfirmDelete
      }
    });
    modal.close.subscribe(res => {
      if (res && res.shouldDelete) {
        this.delete(data.Id);
      }
    });
  }

  exportSubscription(data: DataSourceSubscribe) {
    this.spinnerService.show();
    this.dataSourceSubscribeService.executeDataSourceSubscription(data.Id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.alertService.renderSuccessMessage(Messages.DataSourceSubsciptionExported);
        }, error: this.alertService.showApiError
      });
  }
}
