import { NgSelectModule } from '@ng-select/ng-select';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../shared';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ExternalApiListComponent } from '../configurator/external-api/components/external-api-list/external-api-list.component';
import { ExternalApiEditComponent } from './external-api/components/external-api-edit/external-api-edit.component';
import { TableModule } from '@tarktech/tark-ng-utils';
import { TableConfiguration } from '../shared/table/table-configuration';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { CronEditorModule } from '@tarktech/ngx-cron-editor'
import { TableModule as PrimengTableModule } from 'primeng/table';
import {
    CentralApiConfigurationComponent
} from './central-api-configuration/components/central-api-configuration/central-api-configuration.component';
import { BlacklistedIpAddressesComponent } from './blacklisted-ip-addresses/components/blacklisted-ip-addresses.component';
import { DashboardTerminalPropertiesComponent } from './dashboard-terminal-property/components/dashboard-terminal-properties/dashboard-terminal-properties.component';
import { LicenseComponent } from './license/components/license/license.component';
import { LicenseService } from './license';
import { KitchenPrinterConfigComponent } from './hardware/components/kitchen-printer-config';
import { CacheFlushComponent } from './cache-flush/components/cache-flush/cache-flush.component';
import { ExternalLoginsComponent } from './external-logins/components/external-logins/external-logins.component';
import { ExternalLoginsService } from './external-logins/service/external-logins.service';
import { EmailTemplatesListComponent } from './email-templates/components/email-templates-list/email-templates-list.component';
import { EmailTemplatesEditComponent } from './email-templates/components/email-templates-edit/email-templates-edit.component';
import { RabbitMqConfigurationComponent } from './rabbit-mq-configuration/components/rabbit-mq-configuration/rabbit-mq-configuration.component';
import { RabbitMqConfigurationService } from './rabbit-mq-configuration/services/rabbit-mq-configuration.service';
import { RabbitMqStompHostEditComponent } from './rabbit-mq-configuration/components/rabbit-mq-stomp-host-edit/rabbit-mq-stomp-host-edit.component';
import { DatabaseComponent } from './database/components/database/database.component';
import { HardwareListComponent } from './hardware/components/hardware-list/hardware-list.component';
import { HardwareEditComponent } from './hardware/components/hardware-edit/hardware-edit.component';
import { LightTestComponent } from './hardware/components/light-test/light-test.component';
import { HardwareInterfaceListComponent } from './hardware-interfaces/components/hardware-interface-list/hardware-interface-list.component';
import { HardwareInterfaceEditComponent } from './hardware-interfaces/components/hardware-interface-edit/hardware-interface-edit.component';
import { CashDrawerComponent } from './cash-drawer/components/cash-drawer/cash-drawer.component';
import { PrintingComponent } from './printing/components/printing/printing.component';
import { BrandingComponent } from './branding';
import { ColorsComponent } from './colors/components/colors/colors.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { LayoutComponent } from './layout/components/layout/layout.component';
import { IconsComponent } from './icons/components/icons/icons.component';
import { TracksComponent } from './tracks/components/tracks/tracks.component';
import { PlaylistEditComponent } from './playlists/components/playlist-edit/playlist-edit.component';
import { PlaylistsComponent } from './playlists/components/playlists/playlists.component';
import { PlaylistTrackComponent } from './playlists/components/playlist-track/playlist-track.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TrackEditComponent } from './tracks/components/track-edit/track-edit.component';
import { DjSchemesListComponent } from './dj-schemes/components/dj-schemes-list/dj-schemes-list.component';
import { DjSchemeEditComponent } from './dj-schemes/components/dj-scheme-edit/dj-scheme-edit.component';
import { ReorderTagsComponent } from './dj-schemes/components/reorder-tags/reorder-tags.component';
import { WhitelistedIpAddressListComponent } from './whitelisted-ip-addresses/components/whitelisted-ip-address-list/whitelisted-ip-address-list.component';
import { WhitelistedIpAddressEditComponent } from './whitelisted-ip-addresses/components/whitelisted-ip-address-edit/whitelisted-ip-address-edit.component';
import { SlideShowListComponent } from './digital-signage/components/slide-show-list/slide-show-list.component';
import { SlideShowEditComponent } from './digital-signage/components/slide-show-edit/slide-show-edit.component';
import { SlideShowImageComponent } from './digital-signage/components/slide-show-image/slide-show-image.component';
import { SlideShowService } from './digital-signage/services/slide-show.service';
import { TerminalsListComponent } from './terminals/components/terminals-list/terminals-list.component';
import { TerminalsEditComponent } from './terminals/components/terminals-edit/terminals-edit.component';
import { GlobalLogoffComponent } from './terminals/components/global-logoff/global-logoff.component';
import { TerminalDeviceMappingComponent } from './terminals/components/terminal-device-mapping/terminal-device-mapping.component';
import { TaxonomyComponent } from './taxonomy/components/taxonomy/taxonomy.component';
import { DeliveryComponent } from './delivery/components/delivery/delivery.component';
import { UserSettingsComponent } from './user-settings/components/user-settings/user-settings.component';
import { EndOfDayComponent } from './end-of-day/components/end-of-day/end-of-day.component';
import { CalendarModule } from 'primeng/calendar';
import { BaselineExpensesComponent } from './baseline-expenses/components/baseline-expenses/baseline-expenses.component';
import { AccountsAndTabsComponent } from './accounts-and-tabs/components/accounts-and-tabs/accounts-and-tabs.component';
import { PaymentsComponent } from './payments/components/payments/payments.component';
import { CardBasedLoginComponent } from './card-based-login/components/card-based-login/card-based-login.component';
import { ConfiguratorBaseComponent } from './configurator-base/configurator-base.component';
import { ConfiguratorService } from './configurator.service';
import { SalesTaxesComponent } from './taxes/components/sales-taxes/sales-taxes.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { RouterModule } from '@angular/router';
import { DaypartsComponent } from './dayparts/components/dayparts/dayparts.component';

import { CONFIGURATOR_CHILDREN, CONFIGURATOR_STATES } from './configurator.routes';
import { PrintingService } from '../information-management/inventory-subcategories/service/printing.service';
import { CheckboxModule } from 'primeng/checkbox';
import { NotificationComponent } from './notification/notification.component';
import { RevenueCenterListComponent } from './revenue-centers/components/revenue-centers/revenue-center-list.component';
import { RevenueCenterEditComponent } from './revenue-centers/components/revenue-centers-edit/revenue-center-edit.component';
import { ReportGroupComponent, ReportsConfigurationComponent } from './reports';
import { ReportEditComponent } from './reports/components/report-edit';
import { ReportGroupEditComponent } from './reports/components/report-group-edit';
import { FormatsComponent } from './localization/components/formats/formats.component';
import { DashboardDataSourceComponent } from './custom-datasource/components/data-source/dashboard-data-source.component';
import { DashboardDataSourceEditComponent } from './custom-datasource/components/dashboard-data-source-edit/dashboard-data-source-edit.component';
import { NanoleafHardwareConfigComponent } from './hardware/components/nanoleaf-hardware-config/nanoleaf-hardware-config.component';
import { NanoleafAuthTokenConfigComponent } from './hardware/components/nanoleaf-hardware-config/nanoleaf-auth-token-config/nanoleaf-auth-token-config.component';
import { MenuDisplayOverrideConfigComponent } from './terminals/components/terminals-edit/menu-display-override-config/menu-display-override-config.component';
import { EmailTemplatesPreviewComponent } from './email-templates/components/email-templates-preview/email-templates-preview.component';
import { OrderWorkflowComponent } from './workflow/components/order-workflow/order-workflow.component';
import { ScheduleActiveHourOverridesEditComponent } from './workflow/components/order-workflow/schedule-active-hour-overrides-edit';
import { ScheduleActiveHoursOverrideComponent } from './workflow/components/order-workflow/schedule-order-active-hours-override';
import { InventoryWorkflowComponent } from './workflow/components/inventory-workflow/inventory-workflow.component';
import { MakeTableWorkflowComponent } from './workflow/components/make-table-workflow/make-table-workflow.component';
import { PickScreenWorkflowComponent } from './workflow/components/pick-screen-workflow/pick-screen-workflow.component';
import { GeneralWorkflowComponent } from './workflow/components/general-workflow/general-workflow.component';
import { GiftCardWorkflowComponent } from './workflow/components/gift-card-workflow/gift-card-workflow.component';
import { ConfirmationScreenComponent } from './workflow/components/confirmation-screen/confirmation-screen.component';
import { BaselineExpensesEditComponent } from './baseline-expenses/components/baseline-expenses-edit/baseline-expenses-edit.component';
import { CameraAnnotationListComponent } from './camera-annotation/components/camera-annotation-list/camera-annotation-list.component';
import { CameraAnnotationEditComponent } from './camera-annotation/components/camera-annotation-edit/camera-annotation-edit.component';
import { TranslateLoader, TranslateModule, TranslateStore } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { createTranslateLoader } from '../shared/services/translateLoader';
import { RouteConfigService } from '../shared/services/route-config.service';
import { SmsTemplatesListComponent } from './sms-templates/components/sms-templates-list/sms-templates-list.component';
import { SmsTemplateEditComponent } from './sms-templates/components/sms-template-edit/sms-template-edit.component';
import { FeaturesComponent } from './features/components/features/features.component';
import { NgxContextModule } from '@tarktech/ngx-context';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PostSavedConfigurationService } from './post-saved-configuration.service';
import { QuickBooksComponent } from '../manage-console/quick-books/quick-books.component';
import { ServeScreenWorkflowComponent } from './workflow/components/serve-screen-workflow/serve-screen-workflow.component';

RouteConfigService.addGuardsToRoutes(CONFIGURATOR_CHILDREN);
@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        ComponentsModule,
        PrimengTableModule,
        NgSelectModule,
        ModalModule.forRoot(),
        RouterModule.forChild(CONFIGURATOR_STATES),
        TableModule.forRoot(TableConfiguration),
        CodemirrorModule,
        CronEditorModule,
        ReactiveFormsModule,
        TranslateModule.forChild({
            isolate: true,
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        ColorPickerModule,
        NgxSliderModule,
        DragDropModule,
        CalendarModule,
        CheckboxModule,
        NgxContextModule,
        TooltipModule.forRoot(),
    ],
    providers: [LicenseService, PrintingService,
        ExternalLoginsService, RabbitMqConfigurationService, SlideShowService, ConfiguratorService, TranslateStore, PostSavedConfigurationService],
    declarations: [ExternalApiListComponent, ExternalApiEditComponent, CentralApiConfigurationComponent, BlacklistedIpAddressesComponent,
        DashboardTerminalPropertiesComponent, LicenseComponent, KitchenPrinterConfigComponent, CacheFlushComponent, ExternalLoginsComponent,
        EmailTemplatesListComponent, EmailTemplatesEditComponent, RabbitMqConfigurationComponent,
        RabbitMqStompHostEditComponent, DatabaseComponent, HardwareListComponent, HardwareEditComponent,
        LightTestComponent, HardwareInterfaceListComponent, HardwareInterfaceEditComponent,
        CashDrawerComponent, PrintingComponent, BrandingComponent, ColorsComponent, LayoutComponent, IconsComponent,
        TracksComponent, PlaylistEditComponent, PlaylistsComponent, PlaylistTrackComponent, TrackEditComponent,
        DjSchemesListComponent, DjSchemeEditComponent, ReorderTagsComponent, WhitelistedIpAddressListComponent,
        WhitelistedIpAddressEditComponent, SlideShowListComponent, SlideShowEditComponent, SlideShowImageComponent,
        TerminalsListComponent, TerminalsEditComponent, GlobalLogoffComponent, TerminalDeviceMappingComponent, TaxonomyComponent,
        DeliveryComponent, UserSettingsComponent, EndOfDayComponent, BaselineExpensesComponent,
        AccountsAndTabsComponent, PaymentsComponent, CardBasedLoginComponent, ConfiguratorBaseComponent, SalesTaxesComponent,
        DaypartsComponent, NotificationComponent, RevenueCenterListComponent, RevenueCenterEditComponent, ReportGroupComponent,
        ReportGroupEditComponent, ReportEditComponent, ReportsConfigurationComponent, DashboardDataSourceComponent,
        DashboardDataSourceEditComponent, FormatsComponent, NanoleafHardwareConfigComponent, NanoleafAuthTokenConfigComponent,
        MenuDisplayOverrideConfigComponent, EmailTemplatesPreviewComponent, OrderWorkflowComponent, ScheduleActiveHoursOverrideComponent,
        ScheduleActiveHourOverridesEditComponent, InventoryWorkflowComponent, MakeTableWorkflowComponent, PickScreenWorkflowComponent,
        GeneralWorkflowComponent, GiftCardWorkflowComponent, ConfirmationScreenComponent, BaselineExpensesEditComponent, CameraAnnotationListComponent,
        CameraAnnotationEditComponent, SmsTemplatesListComponent, SmsTemplateEditComponent, FeaturesComponent, QuickBooksComponent, ServeScreenWorkflowComponent]
})
export class ConfiguratorModule { }
