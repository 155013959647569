import { InjectionToken } from '@angular/core';

export const LOCAL_PROVIDER:
    InjectionToken<TarkLocalProvider> = new InjectionToken<TarkLocalProvider>('Formatter');

export interface TarkLocalProvider {
    getCurrencyCode(): string;
    getLocale(): string;
    getDateFormat(): string;
    getTimeFormat(): string;
}
