import { Component, OnInit, ViewChild } from '@angular/core';
import { PrintingService } from 'src/app/information-management/inventory-subcategories/service';
import {
  SettingParam, FieldInfoMessages, SpinnerService, AlertsService, ApplicationStateService,
  DomainConstants, Messages, HardwareModel, BaseFormComponent, FormUtilityService
} from 'src/app/shared';
import { finalize } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { printing } from 'src/app/shared/components/icon/icons';
import { NgForm } from '@angular/forms';
import { PostSavedConfigurationService } from 'src/app/configurator/post-saved-configuration.service';

@Component({
  selector: 'pos-printing',
  templateUrl: './printing.component.html',
  styleUrls: ['./printing.component.scss']
})
export class PrintingComponent extends BaseFormComponent implements OnInit {
  @ViewChild('printingForm') form: NgForm;
  get getForm(): NgForm {
    return this.form
  }
  fieldInfoMessages = FieldInfoMessages;
  parameters: SettingParam;
  serveMethods = [];
  orderClosurePrintActions = [];
  printCheckOrderStatus = [];
  labelPrinters: Array<HardwareModel> = [];
  kitchenPrinters: Array<HardwareModel> = [];
  productLabelPrintingValue = false;
  icons = {
    printing
  }
  windowRef = window;

  constructor(
    protected spinnerService: SpinnerService,
    protected alertsService: AlertsService,
    protected router: Router,
    private printingService: PrintingService,
    private postSavedConfigurationService: PostSavedConfigurationService,
    protected applicationStateService: ApplicationStateService,
    formUtilityService: FormUtilityService
  ) {
    super(formUtilityService);
    this.parameters = this.applicationStateService.newSettingParams();
  }

  ngOnInit() {
    this.load();
    this.loadRequiredData();
  }

  load() {
    const printingObservable: Array<Observable<any>> = [];
    printingObservable.push(this.printingService.getPrintingDetail(this.applicationStateService.terminalId));
    printingObservable.push(this.printingService.getHardwaresOfType(DomainConstants.HardwareTypes.LabelPrinter));
    printingObservable.push(this.printingService.getHardwaresOfType(DomainConstants.HardwareTypes.KitchenPrinter));
    this.spinnerService.show();
    forkJoin(printingObservable)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: ([parameters, labelPrinters, kitchenPrinters]: [SettingParam, Array<HardwareModel>, Array<HardwareModel>]) => {
          this.parameters = parameters;
          this.parameters.ReceiptAutoHide = this.parameters.ReceiptAutoHideSec > 0;
          this.labelPrinters = labelPrinters;
          this.kitchenPrinters = kitchenPrinters;
          this.productLabelPrintingValue = this.parameters.ProductLabelPrinting === 0 ? false : true;
          this.loadPromptOrders(this.parameters.PrintToKitchenOrderTypes);
          this.onFormChange(this.form);
        }
      });
  }

  loadRequiredData() {
    this.serveMethods = cloneDeep(DomainConstants.PromptServeMethods);
    const orderClosurePrintActions = Object.entries(DomainConstants.OrderClosurePrintAction);
    orderClosurePrintActions.forEach(([key, value]) => {
      const temp = { Key: '', Value: '' };
      temp.Key = key;
      temp.Value = value;
      this.orderClosurePrintActions.push(temp);
    });
    const printCheckOrderStatus = Object.entries(DomainConstants.PrintCheckOrderStatus);
    printCheckOrderStatus.forEach(([key, value]) => {
      const temp = { Key: '', Value: '' };
      temp.Key = key;
      temp.Value = value;
      this.printCheckOrderStatus.push(temp);
    });
  }

  loadPromptOrders(printToKitchenOrderTypes) {
    if (printToKitchenOrderTypes) {
      const currentConfigureOrderTypes = String(printToKitchenOrderTypes).split(',');
      if (currentConfigureOrderTypes.length > 0) {
        _.forEach(currentConfigureOrderTypes, (item) => {
          if (item !== '' && item !== undefined) {
            _.forEach(this.serveMethods, (promptType) => {
              if (item === promptType.id) {
                promptType.isSelected = true;
              }
            });
          }
        });
      }
    }
  }

  printingSetting() {
    if (!this.parameters.PrintPerOrder) {
      this.parameters.PrintAccountAddressOnOrderReceipt = false;
      this.parameters.PrintOnlySelectedItems = false;
      this.parameters.PrintOrderRecalls = false;
      this.parameters.PrintDeliveryReceiptToKitchen = false;
      this.parameters.DeliveryReceiptCopies = null;
    }
  }

  receiptSetting() {
    if (!this.parameters.ReceiptAutoHide) {
      this.parameters.ReceiptAutoHideSec = null;
    }
  }

  customerReceiptSetting() {
    if (!this.parameters.PrintCreditCustomerReceipt) {
      this.parameters.PromptCustomerReceipt = false;
    }
  }

  merchantReceiptSetting() {
    if (!this.parameters.PrintCreditMerchantReceipt) {
      this.parameters.PromptMerchantReceipt = false;
    }
  }

  productLabelPrinting() {
    if (!this.productLabelPrintingValue) {
      this.parameters.DefaultProductLabelPrinterId = null;
    }
  }

  save(isValid: boolean) {
    if (!isValid) {
      return;
    }
    if (!this.parameters.PrintPerOrder) {
      this.parameters.OrderClosurePrintAction = DomainConstants.OrderClosurePrintAction.DO_NOT_PRINT;
    }
    let printToKitchenOrderTypes = '';
    _.forEach(this.serveMethods, (item) => {
      printToKitchenOrderTypes += item.isSelected === true ? item.id + ',' : '';
    });
    const labelPrintValue = (this.parameters.ProductLabelPrinting === 2 && this.productLabelPrintingValue) ? this.parameters.ProductLabelPrinting : 1
    this.parameters.ProductLabelPrinting = !this.productLabelPrintingValue ? 0 : labelPrintValue;
    this.parameters.MaxPrepLabels = this.parameters.MaxPrepLabels ? this.parameters.MaxPrepLabels : 0;
    this.parameters.ReceiptAutoHideSec = this.parameters.ReceiptAutoHideSec ? this.parameters.ReceiptAutoHideSec : 0;
    this.parameters.DeliveryReceiptCopies = this.parameters.PrintDeliveryReceiptToKitchen && this.parameters.DeliveryReceiptCopies ?
      this.parameters.DeliveryReceiptCopies : 0;
    this.parameters.PrintToKitchenOrderTypes = this.parameters.PrintPerOrder ? printToKitchenOrderTypes : '';
    this.parameters.PrintToKitchenOrderTypes = printToKitchenOrderTypes;
    this.spinnerService.show();
    this.printingService.savePrinting(this.parameters)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          this.alertsService.renderSuccessMessage(Messages.PrintingSaveSuccess);
          this.postSavedConfigurationService.showMessage();
        }
      });
  }

  cancelEditing() {
    this.router.navigate(['manage/system-configuration']);
  }

  onFormChange($event: NgForm) {
    this.form = $event;
    this.setInitialValue();
  }

}
