<div class="width-98 m-auto">
    <pos-edit-wrapper heading="<img src='images/admin/inventory-prep.png' height='22px'> Inventory Prep Recording"
        (close)="cancel()" [isPopup]="false">
        <form>
            <div class="col-12 container-fluid border-top">
                <ul class="nav nav-tabs inner-tab pos-nav-tabs pt-pix-10">
                    <li [ngClass]="{'active': selectedTab=='FixedIngredientRecipe'}">
                        <a (click)="selectedTab='FixedIngredientRecipe'" class="blue">
                            <span>
                                <pos-icon class="theme-separator pe-pix-5" [icon]="icons.inventoryprepfixed"></pos-icon>
                                Fixed ingredient recipe
                            </span></a>
                    </li>
                    <li [ngClass]="{'active': selectedTab=='VariableIngredientRecipe'}">
                        <a (click)="selectedTab='VariableIngredientRecipe'" class="blue">
                            <span>
                                <pos-icon class="theme-separator pe-pix-5" [icon]="icons.inventoryprepvariable"></pos-icon>
                                Variable ingredient recipe
                            </span></a>
                    </li>
                </ul>
                <div class="tab-content pos-tab-body">
                    <div [hidden]="selectedTab!='FixedIngredientRecipe'">
                        <pos-fixed-ingredient-recipe></pos-fixed-ingredient-recipe>
                    </div>
                    <div [hidden]="selectedTab!='VariableIngredientRecipe'">
                        <pos-variable-ingredient-recipe></pos-variable-ingredient-recipe>
                    </div>
                </div>
            </div>
        </form>
    </pos-edit-wrapper>
</div>
