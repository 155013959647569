import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RuntimeConstants } from 'src/app/shared/constants';
import { ButtonBehavior } from 'src/app/shared/interface/button-behavior';
import { BaseCrudService } from 'src/app/shared/services';

@Injectable({
  providedIn: 'root'
})
export class ButtonBehaviorService extends BaseCrudService<ButtonBehavior> {

  behaviorApiBasePath = `${RuntimeConstants.API_PATH}button-behavior`;

  constructor(httpService: HttpClient) {
    super('button-behavior', httpService);
  }

  getAllButtonBehaviors(buttonId: number): Observable<Array<ButtonBehavior>> {
    return this.httpService.get<Array<ButtonBehavior>>(`${this.behaviorApiBasePath}/all/${buttonId}`);
  }

  updateBehaviorOrdinal(behaviors: Array<ButtonBehavior>): Observable<Array<ButtonBehavior>> {
    return this.httpService.put<Array<ButtonBehavior>>(`${this.behaviorApiBasePath}/ordinal`, behaviors);
  }

  deleteBehavior(behavior: ButtonBehavior) {
    return this.httpService.post(`${this.apiEndpoint}/${behavior.Id}`, behavior);
  }
}
