<div class="custom-links-page ps-3">
	<div class="col-md-3 pt-pix-10">
		<div class="left-nav-toggle" id="menu-toggle-div" (click)="toggleFullScreen()">
			<a class="left-nav-toggle__icon">
				<pos-icon class="black"
					[icon]="isFullScreen ? icons.angleDoubleRight : icons.angleDoubleLeft"></pos-icon>
			</a>
		</div>
	</div>
	<div class="col-12">
		<div class="row col-md-12 tab-section p-0">
			<div class="vertical-tabs pe-1">
				<ul class="nav nav-pills nav-stacked">
					<div class="custom-links-nav nav nav-pills flex-column nav-stacked"
						*ngFor="let item of customSection">
						<li class="tab-head">
							{{item.SectionName}}
						</li>
						<li class="pointer" role="presentation" [ngClass]="{'active': activeTabIndex == subtitle.Id}"
							scrollup *ngFor="let subtitle of item.Items">
							<a class="display-flex" (click)="getCustomLinkPage(subtitle.Link,subtitle.Id)">
								{{subtitle.Name}}
							</a>
						</li>
					</div>
				</ul>
				<div class="tab-content" *ngIf="activeTabIndex != -1">
					<div class="tab-pane fade in active show">
						<div class="row g-0 px-3">
							<div class="col-md-12 py-2">
								<a class="close close--small" (click)="hideActiveTabContent()">×</a>
							</div>
							<iframe class="col-md-12 col-sm-12" [ngStyle]="{'height': getHeight() + 'px'}"
								[src]="customLinkURL" allow="fullscreen" title="Custom Link">
							</iframe>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
