<div class="m-0-auto px-pix-10">
    <pos-edit-wrapper heading="Menu widget config" (close)="onCancel()" [headingIcon]="icons.cogs" [isPopup]="false">
        <form #formWidgetConfig="ngForm" (ngSubmit)="saveWidget(formWidgetConfig.valid)" autocomplete="off">
            <div class="col-12 pos-page-body border-top pt-pix-5">
                <pos-row>
                    <pos-column>
                        <pos-form-field label="Widget" [validationMessages]="{required: 'Please enter config.'}"
                            inputStyle="col-12">
                            <div class="widget-config" [ngStyle]="{'height': screenHeight - 310 + 'px'}">
                                <ngx-codemirror posInput id="widgetConfig" name="widgetConfig" #widgetConfig="ngModel"
                                    [(ngModel)]="widgetConfiguration" [options]="codeMirrorOptions" [required]="true">
                                </ngx-codemirror>
                            </div>
                        </pos-form-field>
                    </pos-column>
                </pos-row>
            </div>
            <div class="col-12 action-button-container page-footer">
                <button type="submit" value="Save" class="btn btn-primary btn-lg btn-save pos-button"
                    scrollup>Save</button>
                <button type="button" class="btn btn-secondary btn-lg btn-cancel pos-button" value="Cancel"
                    (click)="onCancel()" scrollup>Cancel</button>
            </div>
        </form>
    </pos-edit-wrapper>
</div>
