import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, NgForm, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { cloneDeep, filter, find } from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ProductLossReason } from 'src/app/information-management/product-loss-reasons/interface';
import { SalesProduct } from 'src/app/information-management/sales-products/interface';
import { SalesProductsService } from 'src/app/information-management/sales-products/services';
import { SalesSize } from 'src/app/information-management/sales-sizes/interface';
import { SalesSizeService } from 'src/app/shared/services/sales-size.service';
import { ActiveInventory } from 'src/app/manage-console/quick-search';
import { AlertsService, ApplicationStateService, BaseFormComponent, DomainConstants, FormUtilityService, Messages, SpinnerService } from 'src/app/shared';
import { InventoryLoss } from '../interface/inventory-loss';
import { InventoryLossService } from '../services/inventory-loss.service';

@Component({
  selector: 'pos-inventory-loss',
  templateUrl: './inventory-loss.component.html',
  styleUrls: ['./inventory-loss.component.scss']
})
export class InventoryLossComponent extends BaseFormComponent implements OnInit {

  salesProducts: Array<SalesProduct> = [];
  inventoryProducts: Array<ActiveInventory> = [];
  salesSizes: Array<SalesSize> = [];
  productLossReasons: Array<ProductLossReason> = [];
  selectedProduct: number = null;
  productType = DomainConstants.ProductType;
  selectedProductType: string;
  inventoryLoss: InventoryLoss;
  unitName = '';
  calculatedQty = 0;
  allSalesSizes: Array<SalesSize> = [];
  @ViewChild('inventoryLossForm', { static: true }) inventoryLossForm: NgForm;

  get getForm(): NgForm {
    return this.inventoryLossForm;
  }

  constructor(private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private applicationStateService: ApplicationStateService,
    private salesProductsService: SalesProductsService,
    private inventoryLossService: InventoryLossService,
    private salesSizeService: SalesSizeService,
    private router: Router,
    protected formUtilityService: FormUtilityService) {
    super(formUtilityService);
    this.inventoryLoss = inventoryLossService.newInventoryLoss();
  }

  ngOnInit(): void {
    this.loadData();
    this.selectedProductType = this.productType.SalesProduct;
    this.setInitialValue();
  }

  loadData() {
    const observable: Array<Observable<any>> = [];
    observable.push(this.salesProductsService.getSalesProducts());
    observable.push(this.salesProductsService.getAllActiveInventoryProducts('undefined'));
    observable.push(this.salesSizeService.getSalesSizes());
    observable.push(this.inventoryLossService.getProductLossReasons());
    this.spinnerService.show();
    forkJoin(observable)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: ([salesProductList, inventoryProductList, salesSizeList, productLossReasonList]:
          [Array<SalesProduct>, Array<ActiveInventory>, Array<SalesSize>, Array<ProductLossReason>]) => {
          this.salesProducts = filter(salesProductList, x => x.id > 0);
          this.inventoryProducts = filter(inventoryProductList, x => x.id > 0);
          this.salesSizes = salesSizeList;
          this.allSalesSizes = salesSizeList;
          this.productLossReasons = productLossReasonList;
        },
        error: this.alertsService.showApiError
      });
  }

  productSelected() {
    this.unitName = '';
    this.salesSizes = cloneDeep(this.allSalesSizes);
    if (this.inventoryLoss.SalesProductId && this.selectedProductType === this.productType.SalesProduct) {
      this.unitName = find(this.salesProducts, x => x.id === this.inventoryLoss.SalesProductId).UnitName;
      this.getSalesProductSizes();
    } else if (this.inventoryLoss.InventoryProductId && this.selectedProductType === this.productType.InventoryProduct) {
      const inventoryProduct = find(this.inventoryProducts, x => x.id === this.inventoryLoss.InventoryProductId);
      this.unitName = inventoryProduct.Unit;
      this.calculatedQty = inventoryProduct.CalculatedQty;
    }
  }

  getSalesProductSizes() {
    this.salesProductsService.getProductSizesWithPrice(this.inventoryLoss.SalesProductId)
      .subscribe({
        next: (res) => {
          this.salesSizes = res;
          const size = find(this.salesSizes, x => x.SizeId === this.inventoryLoss.SizeId);
          if (!size) {
            this.inventoryLoss.SizeId = null;
          }
          if (this.salesSizes && this.salesSizes.length === 1) {
            this.inventoryLoss.SizeId = this.salesSizes[0].SizeId;
          }
        }, error: this.alertsService.showApiError
      });
  }

  saveInventoryLoss(isValid) {
    if (!isValid) {
      return;
    }
    this.spinnerService.show();
    this.inventoryLoss.UserId = this.applicationStateService.userId;
    this.inventoryLoss.InventoryProductId = this.inventoryLoss.InventoryProductId ? this.inventoryLoss.InventoryProductId : 0;
    this.inventoryLoss.SalesProductId = this.inventoryLoss.SalesProductId ? this.inventoryLoss.SalesProductId : 0;
    this.inventoryLoss.Note = this.inventoryLoss.Note ? this.inventoryLoss.Note : '';
    this.inventoryLossService.saveInventoryProductLoss(this.inventoryLoss)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.alertsService.renderSuccessMessage(Messages.InventoryProductLossSaveSuccess);
          this.reset(this.productType.SalesProduct);
        }, error: this.alertsService.showApiError
      });
  }

  reset(productType: string) {
    this.inventoryLossForm.resetForm();
    this.inventoryLoss = this.inventoryLossService.newInventoryLoss();
    this.unitName = '';
    this.loadData();
    this.selectedProductType = productType;
    this.setInitialValue();
  }

  cancel() {
    this.router.navigate(['manage/console']);
  }

  validateInventoryLoss = (control: AbstractControl): ValidationErrors | null => {
    setTimeout(() => {
      const qty = control.get('textQty');
      if (qty?.value) {
        const checkQty = this.inventoryLoss.InventoryProductId && this.selectedProductType === this.productType.InventoryProduct && qty.value > this.calculatedQty;
        if (checkQty) {
          qty.setErrors({ invalidQty: true })
        } else {
          qty.setErrors(null);
        }
      }
      return null;
    });
    return null;
  }

}
