<svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg"
    style="width: 150px; height: 150px;">
    <style>
        .line-loading7-a {
            animation: line-loading7-pulse 1s infinite linear;
            opacity: .2;
        }
        .line-loading7-b {
            animation: line-loading7-pulse 1s 143ms infinite linear;
            opacity: .2;
        }
        .line-loading7-c {
            animation: line-loading7-pulse 1s 286ms infinite linear;
            opacity: .2;
        }
        .line-loading7-d {
            animation: line-loading7-pulse 1s 429ms infinite linear;
            opacity: .2;
        }
        .line-loading7-e {
            animation: line-loading7-pulse 1s 572ms infinite linear;
            opacity: .2;
        }
        .line-loading7-f {
            animation: line-loading7-pulse 1s 715ms infinite linear;
            opacity: .2;
        }
        .line-loading7-g {
            animation: line-loading7-pulse 1s 858ms infinite linear;
            opacity: .2;
        }
        .line-loading7-h {
            animation: line-loading7-pulse 1s 1s infinite linear;
            opacity: .2;
        }
        @keyframes line-loading7-pulse {
            0% {
                opacity: .2;
            }
            30% {
                opacity: 1;
            }
            50% {
                opacity: .2;
            }
            100% {
                opacity: .2;
            }
        }
    </style>
    <path class="fill1"
        d="M13 8H88C92.4183 8 96 11.5817 96 16V60.4496V69.1923C96 73.6105 92.4183 77.1923 88 77.1923H63.6992L71.879 87.7937H76.595C78.585 87.7937 80.1982 89.4069 80.1982 91.3968C80.1982 93.3868 78.585 95 76.595 95H21.2446C19.2547 95 17.6415 93.3868 17.6415 91.3968C17.6415 89.4069 19.2547 87.7937 21.2446 87.7937H28.3309L36.5107 77.1923H13C8.58172 77.1923 5 73.6105 5 69.1922V60.4496V16C5 11.5817 8.58172 8 13 8Z"
        fill="rgba(255,255,255,1)" stroke-width="2px" style="animation-duration: 1s;"></path>
    <path class="stroke1"
        d="M5 60.4496V69.1923C5 73.6105 8.58172 77.1922 13 77.1922H36.5107M5 60.4496V16C5 11.5817 8.58172 8 13 8H88C92.4183 8 96 11.5817 96 16V60.4496M5 60.4496H96M96 60.4496V69.1923C96 73.6105 92.4183 77.1922 88 77.1922H63.6992M63.6992 77.1922L71.879 87.7937M63.6992 77.1922H36.5107M71.879 87.7937H76.595C78.585 87.7937 80.1982 89.4069 80.1982 91.3968V91.3968C80.1982 93.3868 78.585 95 76.595 95H21.2446C19.2547 95 17.6415 93.3868 17.6415 91.3968V91.3968C17.6415 89.4069 19.2547 87.7937 21.2446 87.7937H28.3309M71.879 87.7937H28.3309M28.3309 87.7937L36.5107 77.1922"
        stroke="rgba(0,0,0,1)" stroke-width="2px" style="animation-duration: 1s;"></path>
    <circle class="stroke1 fill1" cx="50" cy="69" r="4" fill="rgba(255,255,255,1)" stroke="rgba(0,0,0,1)"
        stroke-width="2px" style="animation-duration: 1s;"></circle>
    <circle class="line-loading7-h fill2" cx="41.0444" cy="23.2222" r="3.11112" style="animation-duration: 1s;"></circle>
    <circle class="line-loading7-a fill2" cx="50.9111" cy="19.1111" r="3.11112" style="animation-duration: 1s;"></circle>
    <circle class="line-loading7-b fill2" cx="60.7778" cy="24.0445" r="3.11112" style="animation-duration: 1s;"></circle>
    <circle class="line-loading7-e fill2" cx="41.0444" cy="43.7779" r="3.11112" style="animation-duration: 1s;"></circle>
    <circle class="line-loading7-c fill2" cx="64.0667" cy="33.9112" r="3.11112" style="animation-duration: 1s;"></circle>
    <circle class="line-loading7-g fill2" cx="36.9333" cy="33.9112" r="3.11112" style="animation-duration: 1s;"></circle>
    <circle class="line-loading7-d fill2" cx="59.9556" cy="43.7779" r="3.11112" style="animation-duration: 1s;"></circle>
    <circle class="line-loading7-e fill2" cx="50.9111" cy="47.889" r="3.11112" style="animation-duration: 1s;"></circle>
</svg>
