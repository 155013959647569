import { AuthenticationService } from './../../../../shared/auth/services/authentication.service';
import { GridColumn, DeleteColumn, LinkColumn, EditColumn } from '@tarktech/tark-ng-utils';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertsService, PrintTableService, ApplicationStateService } from 'src/app/shared/services';
import { CommentWarningService } from '../../../../shared/services/comment-warning-service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { CommentWarning } from '../../interface/comment-warning';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { ConfirmDeleteComponent } from 'src/app/shared/components/confirm-delete/confirm-delete.component';

import { CommentWarningEditComponent } from '../comment-warning-edit/comment-warning-edit.component';
import { Permissions, Levels } from '../../../../shared/constants/';
import { BaseGridComponent } from 'src/app/shared';
import { ActivatedRoute, Router } from '@angular/router';
import { buttonsDiceOne } from 'src/app/shared/components/icon';

@Component({
  selector: 'pos-comment-warnings-list',
  templateUrl: './comment-warnings-list.component.html'
})
export class CommentWarningsListComponent extends BaseGridComponent implements OnInit {
  commentWarningsColumns: Array<GridColumn> = [];
  printCommentWarningsColumns: Array<GridColumn> = [];
  @ViewChild('confirmButtonDelete') private confirmButtonDelete: ConfirmDeleteComponent;
  commentWarnings: CommentWarning[] = [];

  permission = {
    name: Permissions.CommentWarnings,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };
  icons = { buttonsDiceOne };
  public message = Messages.ConfirmDeleteCommentWarning;
  reverse = false;
  order = 'CommentTextMatch';
  commentWarningId = 0;
  constructor(
    private alertService: AlertsService,
    private commentWarning: CommentWarningService,
    private modalService: ModalService,
    private spinnerService: SpinnerService,
    private authenticationService: AuthenticationService,
    protected applicationStateService: ApplicationStateService,
    protected route: ActivatedRoute,
    private printService: PrintTableService,
    protected router: Router,
  ) {
    super(applicationStateService, route);
  }

  ngOnInit() {
    this.reloadCommentWarningList();
    this.configureGridColumns();
  }

  reloadCommentWarningList() {
    this.spinnerService.show();
    this.commentWarning.getCommentWarnings().subscribe(
      response => {
        if (response) {
          this.commentWarnings = response;
        } else {
          this.commentWarnings = [];
        }
      },
      this.alertService.showApiError,
      () => {
        this.spinnerService.hide();
      }
    );
  }

  private configureGridColumns() {
    const deleteColumn = new DeleteColumn({
      Clicked: (data) => {
        this.deleteCommentWarning(data.Id);
      },
      Width: '83px',
      PrintIcon: new LinkColumn({
        Clicked: () => {
          this.printData();
        }
      })
    });

    const editColumn = new EditColumn({
      Clicked: (data) => {
        this.editCommentWarning(data.Id);
      },
      Width: '57px',
      AddIcon: new LinkColumn({
        Clicked: () => {
          this.editCommentWarning(0);
        },
        ToolTip: 'New'
      })
    });

    this.commentWarningsColumns = [
      new GridColumn({ HeaderText: 'Text to Match', Field: 'CommentTextMatch', IsSortable: true, Width: '40%' }),
      new GridColumn({ HeaderText: 'Warning Message', Field: 'WarningMessage', IsSortable: true, Width: '55%' })
    ] as Array<GridColumn>;

    this.printCommentWarningsColumns = [...this.commentWarningsColumns];
    if (this.authenticationService.userHasPermission([{ 'Name': this.permission.name, 'Level': this.permission.editLevel }], 'any')) {
      this.commentWarningsColumns.push(editColumn);
      this.commentWarningsColumns.push(deleteColumn);
    }

  }

  editCommentWarning(data) {
    const commentModalRef = this.modalService.show(CommentWarningEditComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        commentWarningId: data
      }
    });

    commentModalRef.close.subscribe(res => {
      if (res && res.shouldReload) {
        this.reloadCommentWarningList();
      }
    });
  }
  deleteCommentWarning(commentWarningId: number): void {
    this.commentWarningId = commentWarningId;
    this.confirmButtonDelete.Show(this.message);
  }

  onConfirmAcknowledge() {
    this.spinnerService.show();
    this.commentWarning.delete(this.commentWarningId).subscribe(
      () => {
        this.alertService.renderSuccessMessage(
          Messages.CommentWarningDeleted
        );
        this.reloadCommentWarningList();
      },
      this.alertService.showApiError,
      () => {
        this.spinnerService.hide();
      }
    );
  }

  setSorting(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }

  printData() {
    this.printService.printEmitter.next({ gridColumns: this.printCommentWarningsColumns, gridData: this.commentWarnings });
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
