<div>
    <h4 class="modal-content__heading">System Log Out</h4>
</div>
<div class="modal-body pb-0">
    <div class="font-20">
        {{forceLogOutMessage}}
    </div>
</div>
<div class="col-12 action-button-container popup-footer pb-pix-10">
    <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="closeForceLogOutModal()"
        type="button">
        <pos-icon [icon]="icons.userTimes"></pos-icon>
        Logout
    </button>
</div>
