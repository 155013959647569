<pos-edit-wrapper heading="{{ id ? 'Edit' : 'Add' }} Export Connection{{ id ? ': '+ exportConnection.Name:''}}"
  (close)="cancel()" [headingIcon]="icons.hardwareInterfacePlug" [isPopup]="false">
  <form #exportConnectionForm="ngForm" class="edit-form" (ngSubmit)="submit(exportConnectionForm.valid)">
    <div class="col-12 border-top pt-pix-5">
      <div class="col-12 pos-page-body">
        <pos-row>
          <pos-column>
            <pos-form-field label="Name" [validationMessages]="{required: 'Please enter name.'}" inputStyle="col-md-4 col-12">
              <input posInput type="text" id="exportConnectionName" name="name" #exportConnectionName="ngModel"
                class="form-control" [(ngModel)]="exportConnection.Name" [required]="true" />
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Connection Type" [validationMessages]="{required: 'Please select connection type.'}" inputStyle="col-md-4 col-12">
              <ng-select posInput [items]="connectionTypes" placeholder="Select connection type"
                #connectionType="ngModel" bindLabel="Name" bindValue="Name" appendTo="body" [clearable]="false"
                (change)="changeConnectionTypeParameters()" [(ngModel)]="exportConnection.ConnectionType"
                name="connectionType" [required]="true"></ng-select>
            </pos-form-field>
          </pos-column>
        </pos-row>

        <div class="col-12 pt-pix-10" *ngIf="exportConnection.ConnectionType && connectionTypeParameters?.length">
          <div class="col-12 row g-0">
            <div class="col-lg-2 col-md-2 col-sm-3 col-5 theme-separator ps-pix-5">
              <strong>Parameter</strong>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-4 col-5 theme-separator">
              <strong>
                <span class="display-flex">
                  Value
                </span>
              </strong>
            </div>
          </div>
          <div class="col-12 row g-0 mt-pix-10" *ngFor="let parameter of connectionTypeParameters; let index = index;">
            <div class="col-lg-2 col-md-2 col-sm-3 col-5 ps-pix-5">
              <label class="edit-form__section__label">
                {{parameter.DisplayName}}
                <field-info *ngIf="parameter.Info" [info]="parameter.Info"></field-info>
              </label>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-4 col-5">
              <input type="text" class="form-control" #typeParam="ngModel" name="typeParam{{index}}"
                [(ngModel)]="parameter.Value" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 action-button-container page-footer">
      <button type="submit" value="Save" class="btn btn-primary btn-lg btn-save pos-button" scrollup>
        Save
      </button>
      <button type="button" class="btn btn-secondary btn-lg btn-cancel pos-button" value="Cancel" (click)="cancel()" scrollup>
        Cancel
      </button>
    </div>
  </form>
</pos-edit-wrapper>
