import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SettingParam } from 'src/app/shared/interface/setting-param';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';

@Component({
  selector: 'pos-ebt-button',
  templateUrl: './ebt-button.component.html',
  styleUrls: ['./ebt-button.component.scss']
})
export class EbtButtonComponent implements OnInit {

  @Input() ebtTenderTypeColor: string;
  @Input() canModifyAmount: string;
  settingParam: SettingParam;
  @Output() makePaymentEBT = new EventEmitter();
  constructor(private applicationStateService: ApplicationStateService) { 
    this.settingParam = this.applicationStateService.settingParam;
  }

  ngOnInit(): void {
  }

  makePaymentEBTClick() {
    this.makePaymentEBT.emit();
  }
}
