import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormUtilityService, ModalFormComponent, ModalService } from 'src/app/shared';
import { DashboardConfiguration } from 'src/app/shared/interface';

@Component({
  selector: 'pos-widget-edit',
  templateUrl: './widget-edit.component.html',
})
export class WidgetEditComponent extends ModalFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.widgetEditForm;
  }

  @ViewChild('widgetEditForm') widgetEditForm: NgForm;
  @Input() widgetConfig: any;
  close: EventEmitter<any> = new EventEmitter();
  title: string;
  constructor(modalRef: BsModalRef, modalService: ModalService, formUtilityService: FormUtilityService) {
    super(modalRef, modalService, formUtilityService);
  }

  ngOnInit(): void {
    this.title = this.widgetConfig.title;
  }

  save(isValid: boolean) {
    if (!isValid) {
      return;
    }
    if (this.widgetConfig?.config?.height) {
      if(!isNaN(Number(this.widgetConfig.config.height))) {
        this.widgetConfig.config.height = Number(this.widgetConfig.config.height) > 0 ? this.widgetConfig.config.height + 'px' : null
      }
    }
    this.isSubmitted = true;
    this.hide({ reload: true, widgetConfiguration: this.widgetConfig });
  }

  closeWidget() {
    this.hide({ reload: false });
  }
}
