import { trainingCog, trainingMove } from './../../../../shared/components/icon/icons';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import {
  AlertsService, Messages, SpinnerService, ConfirmDeleteComponent, ModalService, AuthenticationService,
  Permissions, Levels, BaseGridComponent, ApplicationStateService, GridContextInfo
} from 'src/app/shared';
import { TrainingTopicService } from '../../../../shared/services/training-topic.service';
import { TrainingTopic } from '../../interface/training-topic';
import { GridColumn, EditColumn, DeleteColumn, LinkColumn, TableComponent } from '@tarktech/tark-ng-utils';
import * as _ from 'lodash';
import { TrainingTopicMoveComponent } from '../training-topic-move';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
declare let $: any;
@Component({
  selector: 'pos-training-topic-list',
  templateUrl: './training-topic-list.component.html',
  styleUrls: ['./training-topic-list.component.scss']
})
export class TrainingTopicListComponent extends BaseGridComponent implements OnInit {

  @ViewChild('confirmTrainingTopicDelete') private confirmTrainingTopicDelete: ConfirmDeleteComponent;
  @ViewChild('gridComponent', { static: true }) gridComponent: TableComponent;
  trainingTopics: Array<TrainingTopic> = [];
  trainingTopicColumns: Array<GridColumn> = [];
  trainingTopic: TrainingTopic;
  filterColumns: Array<string>;
  @Input() areaId: number;
  @Input() areaName: string;
  @Input() topicName: string;
  public confirmDeleteMessage = Messages.ConfirmDeleteTrainingTopic;

  permission = {
    name: Permissions.TrainingTopics,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };

  levelPermission = {
    name: Permissions.TrainingTopicLevels,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };
  areaGridContext: GridContextInfo = null;

  constructor(private alertService: AlertsService,
    private spinnerService: SpinnerService,
    protected applicationStateService: ApplicationStateService,
    private modalService: ModalService,
    private authenticationService: AuthenticationService,
    private trainingTopicService: TrainingTopicService,
    private router: Router,
    protected route: ActivatedRoute) {
    super(applicationStateService, route);
    const navigation = router.getCurrentNavigation();
    this.areaId = route.snapshot.params.areaId ? parseInt(route.snapshot.params.areaId, 10) : 0;
    this.areaName = navigation?.extras?.state?.areaName ?? '';
    this.topicName = navigation?.extras?.state?.topicName ?? '';
    this.areaGridContext = navigation?.extras?.state?.GridContext ?? null;
    this.gridContext = navigation?.extras?.state?.TopicGridContext ?? this.gridContext;
    this.reloadTrainingTopicList();
  }

  ngOnInit() {
    this.columnConfiguration();
    this.gridComponent.context = this.gridContext;
  }

  private columnConfiguration() {
    const editColumn = new EditColumn({
      Clicked: (data) => {
        this.editTrainingTopic(data.Id);
        $('html, body').animate({ scrollTop: 0 }, 'medium');
      },
      Width: '132px',
      AddIcon: new LinkColumn({
        Clicked: () => {
          this.editTrainingTopic(0);
          $('html, body').animate({ scrollTop: 0 }, 'medium');
        },
        ToolTip: 'New'
      })
    });

    const deleteColumn = new DeleteColumn({
      Clicked: (data) => {
        this.deleteTopicButton(data);
      },
      Width: '0px',
      CellClass: 'cell-delete pi-0'
    });

    const moveColumn = new LinkColumn({
      Clicked: (data) => {
        this.moveTrainingTopic(data);
      },
      CellClass: 'cell-link training-gear-icon pi-0',
      Icon: trainingMove.iconClasses + ' font-17em',
      ToolTip: 'Move',
      Width: '0'

    });

    const topicLevelIconColumn = new LinkColumn({
      Clicked: (data) => {
        this.onTopicLevelIcon(data);
        $('html, body').animate({ scrollTop: 0 }, 'medium');
      },
      CellClass: 'cell-link training-gear-icon pi-0',
      BackgroundColor: 'grey',
      Icon: trainingCog.iconClasses + '',
      ToolTip: 'Training Topic Levels',
      Width: '0'
    });
    this.filterColumns = ['TrainingLevels.Descriptions'];
    this.trainingTopicColumns = [
      new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '27%' }),
      new GridColumn({ HeaderText: 'Description', Field: 'Description', IsSortable: true, Width: '46%' }),
      new GridColumn({ HeaderText: 'Total Points', Field: 'TotalScore', IsSortable: true, Width: '16%' }),
    ] as Array<GridColumn>;

    if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      this.trainingTopicColumns.push(editColumn);
      this.trainingTopicColumns.push(deleteColumn);
    }

    if (this.authenticationService.userHasPermission([{ Name: this.levelPermission.name, Level: this.levelPermission.readOnlyLevel }], 'any')) {
      this.trainingTopicColumns.push(topicLevelIconColumn);
    }

    this.trainingTopicColumns.push(moveColumn);

  }

  moveTrainingTopic(topic) {
    const modalRef = this.modalService.show(TrainingTopicMoveComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        trainingTopicId: topic.Id,
        trainingAreaId: this.areaId,
        trainingAreaName: this.areaName,
        trainingTopicName: topic.Name
      }
    });

    modalRef.close.subscribe(res => {
      if (res?.shouldReload) {
        this.reloadTrainingTopicList();
      }
    });
  }

  reloadTrainingTopicList() {
    this.spinnerService.show();
    this.trainingTopicService.getTrainingTopicsByAreaId(this.areaId, 0)
    .pipe(finalize(() => {
      this.spinnerService.hide();
    }))
    .subscribe({
      next: (response) => {
        if (!this.areaName && response && response.length) {
          this.areaName = response[0].TrainingArea.Name;
          if (!this.areaId) {
            this.areaName = response[0].TrainingArea.Id;
          }
        }
        _.forEach(response, (topic) => {
          topic.TrainingLevels = { Descriptions: [] };
          _.forEach(topic.TrainingTopicLevels, (level) => {
            topic.TrainingLevels.Descriptions.push(level.Description);
          });
        });
        if (response) {
          this.trainingTopics = response;
        } else {
          this.trainingTopics = [];
        }
        this.getTotalScore();
      }, error: this.alertService.showApiError
    });
  }

  getTotalScore() {
    if (this.trainingTopics.length > 0) {
      this.trainingTopics.forEach(topic => {
        topic.TotalScore = 0;
        if (topic.TrainingTopicLevels) {
          topic.TrainingTopicLevels.forEach(level => {
            topic.TotalScore += level.LevelScore ? level.LevelScore : 0;
          });
        }
      });
    }
  }

  gridRowReorder(event) {
    const dragElementIndex = event.dragIndex;
    const dropElementIndex = event.dropIndex;
    if (dragElementIndex < dropElementIndex) {
      this.trainingTopics[dropElementIndex - 1].Ordinal = dropElementIndex;
    } else {
      this.trainingTopics[dropElementIndex].Ordinal = dropElementIndex + 1;
    }
    this.trainingTopicService.updateOrdinal(dragElementIndex < dropElementIndex ?
      this.trainingTopics[dropElementIndex - 1] : this.trainingTopics[dropElementIndex])
      .subscribe({
        next: () => { },
        error: this.alertService.showApiError
      });
  }

  deleteTopicButton($event: TrainingTopic) {
    this.trainingTopic = $event;
    this.confirmTrainingTopicDelete.Show(this.confirmDeleteMessage);
  }

  onConfirmAcknowledge() {
    this.spinnerService.show();
    this.trainingTopicService.deleteTrainingTopic(this.trainingTopic.Id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.alertService.renderSuccessMessage(
            Messages.TrainingTopicDeleteSuccess
          );
          this.reloadTrainingTopicList();
        },
        error: this.alertService.showApiError
      });
  }

  editTrainingTopic(topicId: number) {
    this.router.navigate([topicId], {
      state: {
        topicId: topicId, areaId: this.areaId, areaName: this.areaName, GridContext: this.areaGridContext
      }, relativeTo: this.route
    });
  }
  onTopicLevelIcon(data: any) {
    this.router.navigate([data.Id, 'levels'], {
      state: {
        topicId: data.Id, areaId: this.areaId, areaName: this.areaName, topicName: data.Name, AreaGridContext: this.areaGridContext, TopicGridContext: this.gridContext,
      }, relativeTo: this.route
    });
  }

  cancel() {
    this.router.navigate(['training-areas'], { relativeTo: this.route.parent, state: { GridContext: this.areaGridContext } });
  }
}
