import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { UnusedImage } from '../interface/UnusedImage';

@Injectable({
  providedIn: 'root'
})
export class MenuSetupService {

  constructor(private httpService: HttpClient) { }

  getInactiveProductLists(): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}buttons/inActive`
    )
  }
  deleteButton(buttonId: number): Observable<any> {
    return this.httpService.delete(
      `${RuntimeConstants.API_PATH}buttons/inActive/` + buttonId
    );
  }

  getUnlinkedScreenList(): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}screens/unLinked`
    )
  }

  getUnusedImages(): Observable<Array<string>> {
    return this.httpService.get<Array<string>>(`${RuntimeConstants.API_PATH}buttons/unusedImages`)
  }

  deleteUnlinkedScreen(screenId: number): Observable<any> {
    return this.httpService.delete(
      `${RuntimeConstants.API_PATH}screens/unLinked/` + screenId
    );
  }

  deleteButtonImage(name: string){
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}buttons/image/delete`, {Name: name}
    );
  }

  bulkDeleteButtonImages(images) {
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}buttons/images/bulk-delete`, images
    );
  }


}
