<pos-edit-wrapper (close)="onCancel()" heading="Product Dashboard: {{inventoryProductName}}">
    <div class="col-12 p-0">
        <div class="col-12 form-horizontal overflow-auto-tabs inventory-dashboard"
            [ngStyle]="{'height': height + 'px'}">
            <pos-dashboard-configuration [dashboardId]="dashboardId" [inventoryProductId]="inventoryProductId">
            </pos-dashboard-configuration>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="button" data-dismiss="modal" class="btn-secondary btn-lg btn btn-cancel-modal pos-button"
                (click)="onCancel()" value="Cancel">Close</button>
        </div>
    </div>
</pos-edit-wrapper>
