<pos-kiosk-header *ngIf="screenConfig.Header.IsShow" [header]="screenConfig.Header"></pos-kiosk-header>
<div class="row mx-0 designer-area"
    [ngStyle]="{'background-color': screenConfig.BackgroundColor, 'background-image': screenConfig.BackgroundImage ? 'url(\''+ imageRootPath + screenConfig.BackgroundImage + '\')' : '' }">
    <span *ngIf="isDesignMode && grid.NoOfCols" class="pt-2 text-right">
        <pos-action-icon [icon]="icons.plusWhite" iconText="Add Button"
            class="text-nowrap" (click)="addKioskScreenChoice()"
           ></pos-action-icon>
    </span>
    <div class="overflow-auto p-0" [ngClass]="screenConfig.ShowInvoice ? 'col-xl-9 col-8' : 'col-12'"
        [ngStyle]="{'height': invoiceHeight - 12 + 'px'}">
        <pos-kiosk-designer-grid class="kiosk-designer-grid-{{screenId}}-{{designerGridId}}" [isAllowDelete]="true" [grid]="grid" [screenId]="screenId" 
            [designerGridId]="designerGridId"
            [buttonConfig]="buttonConfig" [templateType]="templateType" [(screenButtons)]="screenButtons"
            [isDesignMode]="isDesignMode"></pos-kiosk-designer-grid>
    </div>
    <div class="col-xl-3 col-4 p-3" *ngIf="screenConfig.ShowInvoice" [ngStyle]="{'height': invoiceHeight - 3 + 'px'}">
        <pos-kiosk-invoice></pos-kiosk-invoice>
    </div>
</div>

<pos-kiosk-footer *ngIf="screenConfig.Footer.IsShow" [buttons]="screenButtons"
    [footer]="screenConfig.Footer"></pos-kiosk-footer>
