<pos-edit-wrapper heading="{{ id > 0 ? 'Edit' : 'Add' }} Dashboard" (close)="cancelDashboard()">
    <form #dashboardForm="ngForm" (ngSubmit)="saveDashboard(dashboardForm.valid)" class="edit-form">
        <div class="pos-modal-body">
            <div class="row mx-0">
                <pos-row>
                    <pos-column>
                        <pos-form-field label="Name" [validationMessages]="{required: 'Please enter dashboard name.'}">
                            <input posInput type="text" class="form-control" name="layoutName" [(ngModel)]="dashboard.Name"
                                [required]="true" #layoutName="ngModel" />
                        </pos-form-field>
                    </pos-column>
                    <pos-column>
                        <pos-form-field label="Layout" [validationMessages]="{required: 'Please select a default layout.'}">
                            <ng-select posInput name="layout" [items]="layouts" bindValue="Id" bindLabel="Name"
                                appendTo="body" placeholder="Select default layout" [(ngModel)]="dashboard.LayoutId"
                                [required]="true" #layoutId="ngModel">
                            </ng-select>
                        </pos-form-field>
                    </pos-column>
                    <pos-column>
                        <pos-form-field label="Auto Refresh Interval (minutes)">
                            <input class="form-control col-120" name="AutoRefreshInterval" posOnlyNumbers
                                [(ngModel)]="dashboard.AutoRefreshInterval" type="number" #autoRefreshInterval="ngModel" />
                        </pos-form-field>
                    </pos-column>
                    <pos-column>
                        <pos-form-field>
                            <p-checkbox posInput name="isShowClock" #isShowClock="ngModel"
                                [(ngModel)]="dashboard.IsShowClock" binary="true" label="Show Clock">
                            </p-checkbox>
                        </pos-form-field>
                    </pos-column>
                </pos-row>
            </div>
        </div>
        <div class="clearfix"></div>
        <div class="col-12 action-button-container popup-footer">
            <button class="btn btn-primary btn-lg btn-save pos-button" scrollup type="submit" value="Save">Save</button>
            <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="cancelDashboard()" scrollup type="button"
                value="Cancel">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
