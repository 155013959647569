import { OrderConfirmationComponent } from './order-confirmation/order-confirmation.component';
import { Levels, DomainConstants } from '../shared/constants';
import { Route } from '@angular/router';
import { AuthGuard } from '../app-guard';

const ORDER_CONFIRMATION: Route = {
    path: '',
    canActivate: [AuthGuard],
    component: OrderConfirmationComponent,
    data: {
        permissions: [{ 'Name': DomainConstants.TerminalTypes.ORDER_CONFIRMATION_SCREEN.Permission, 'Level': Levels.Access }]
    }
};

export const ORDER_CONFIRMATION_STATES = [ORDER_CONFIRMATION];
