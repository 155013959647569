import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ComponentsModule, Levels, Permissions } from 'src/app/shared';
import { HicDashboardComponent } from './components/hic-dashboard/hic-dashboard.component';
import { ActivityStreamComponent } from './components/activity-stream/activity-stream.component';
import { ServiceStatusComponent } from './components/service-status/service-status.component';
import { NgPipesModule } from 'ngx-pipes';
import { Route, RouterModule } from '@angular/router';
import { TableModule, TarkDateTimePipe } from '@tarktech/tark-ng-utils';

const routes: Array<Route> = [{
  path: '',
  component: HicDashboardComponent,
  data: {
    permissions: [{ 'Name': Permissions.ManageHICDashboard, 'Level': Levels.Access },
    { 'Name': Permissions.ManageConsole, 'Level': Levels.Access }]
  }
}];

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    NgPipesModule,
    TableModule,
    RouterModule.forChild(routes)
  ],
  declarations: [HicDashboardComponent, ActivityStreamComponent, ServiceStatusComponent]
})
export class HicDashboardModule { }
