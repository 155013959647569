<div>
  <h4 class="modal-content__heading">{{header? header: 'Change Quantity'}}</h4>
</div>
<div class="modal-body">
  <label class="bold font-16 float-right mt-pix-n10 mb-pix-n15"
    *ngIf="settingParam.AllowOrderingFractionalQty">{{salesProductUnit}}</label>
  <pos-numpad numpadId="qtyChange" [value]="value" [options]="numpadOptions" [onCancel]="onCancel"
    [onSubmit]="onSubmit">
  </pos-numpad>
</div>
