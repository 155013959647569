import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AlertsService, BaseCrudComponent, FormUtilityService, Messages, SpinnerService } from 'src/app/shared';
import { layerGroup } from 'src/app/shared/components/icon/icons';
import { ReportCategory, ReportGroup } from '../../interface';
import { ReportGroupService } from '../../service';
import { ReportCategoryService } from '../../service/report-category.service';
import { PermissionTypesService } from 'src/app/information-management/permissions';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'pos-report-group-edit',
  templateUrl: './report-group-edit.component.html',
})
export class ReportGroupEditComponent extends BaseCrudComponent<ReportGroup> implements OnInit {
  @ViewChild('reportGroupForm') hardwareForm: NgForm;
  get getForm(): NgForm {
    return this.hardwareForm
  }
  reportGroup: ReportGroup;
  reportGroupCopy: ReportGroup;
  reportCategory: Array<ReportCategory> = [];
  reportGroupName = '';
  icons = {
    layerGroup
  }

  props = {
    labelClass: '',
    controlBoxClass: 'col-lg-3 col-sm-5 col-xs-12'
  }
  constructor(
    protected reportGroupService: ReportGroupService,
    protected reportCategoryService: ReportCategoryService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private permissionTypeService: PermissionTypesService,
    formUtilityService: FormUtilityService
  ) {
    super(reportGroupService, alertService, spinnerService, formUtilityService);
    this.reportGroup = this.reportGroupService.newReportGroup();
    this.id = parseInt(route.snapshot.params.id, 10) ?? 0;
  }

  ngOnInit(): void {
    this.saveSuccessMessage = Messages.ReportGroupSaveSuccess;
    this.loadReportGroup();
  }

  loadReportGroup() {
    if (this.id) {
      this.loadData().subscribe({
        next: (response) => {
          this.reportGroup = response;
          this.reportGroupCopy = cloneDeep(this.reportGroup);
          this.reportGroupName = response.Name;
        }
      });
    }
  }

  loadDependencies() {
    this.spinnerService.show();
    this.reportCategoryService.getAll().pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (response) => {
        this.reportCategory = response;
      }
    });
  }

  submit(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.save(this.reportGroup);
  }

  onSaveSuccess() {
    if (!this.reportGroup.Id || this.reportGroup.Name != this.reportGroupCopy.Name || this.reportGroup.ReportCategoryId != this.reportGroupCopy.ReportCategoryId) {
      this.spinnerService.show();
      this.permissionTypeService.updatePermissionGraph()
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe((res) => { });
    }
    this.cancel();
  }

  cancel() {
    this.router.navigate(['report-groups'], { relativeTo: this.route.parent });
  }

}
