import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { cloneDeep, find, forEach } from 'lodash';
import { finalize } from 'rxjs/operators';
import { CashDrawerService } from 'src/app/configurator/cash-drawer/services/cash-drawer.service';
import {
  AlertsService, ApplicationStateService, DomainConstants, FieldInfoMessages, ICloseable, InfoModalComponent, Messages, ModalService,
  SettingParam, SpinnerService
} from 'src/app/shared';
import { minus, drawerCheckPointPlus } from 'src/app/shared/components/icon';
import { DrawerVarianceComponent } from '../drawer-variance/drawer-variance.component';
import { GiftCardService } from '../gift-card/services/gift-card.service';
import { DrawerAmount } from '../interfaces';
import { Drawer } from '../interfaces/drawer';
import { AmountPaidDetail, CashDrawerDetail } from '../load-drawer/cash-drawer-detail';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';

@Component({
  selector: 'pos-drawer-checkpoint',
  templateUrl: './drawer-checkpoint.component.html',
  styleUrls: ['./drawer-checkpoint.component.scss']
})
export class DrawerCheckpointComponent implements OnInit, OnChanges, ICloseable {

  @Input() drawerAmountModel: CashDrawerDetail;
  @Input() isDrawerCheckPoint: boolean;
  @Input() registerAmountInDrawer = 0.00;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  drawerCheckPointPrice = cloneDeep(DomainConstants.DrawerCheckPointPrice);
  selectedPrice = '';
  totalPrice = 0;
  icons = {
    minus, drawerCheckPointPlus
  };
  totalExpectedAmount = 0;
  settingParam: SettingParam;
  drawerVarianceMessage: string;
  drawer: Drawer;
  drawerPrice: number;
  giftCardPurchase = 0;
  fieldInfoMessages = {
    CashDrawerExpectedTotal: ''
  };
  giftCardPurchaseAmount: number = 0;

  constructor(private applicationStateService: ApplicationStateService,
    private modalService: ModalService,
    private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private router: Router,
    private cashDrawerService: CashDrawerService,
    private giftCardService: GiftCardService) {
  }

  ngOnInit(): void {
    this.settingParam = this.applicationStateService.settingParam;
    this.setCurrencySymbol();
    this.getGiftCardPurchaseAmount();
    this.drawer = this.cashDrawerService.newDrawer();
    this.selectedPrice = this.drawerCheckPointPrice.Cent1.Name;
    const giftCard: AmountPaidDetail = find(this.drawerAmountModel.DrawerAmountModel, x => x.PaymentType === 'Gift Card');
    this.giftCardPurchase = giftCard ? giftCard.Amount : 0;
    this.setFieldInfoMessage();
  }

  setCurrencySymbol() {
    forEach(Object.values(this.drawerCheckPointPrice), (price) => {
      price.Name = price.Name.replace('$', this.settingParam.CurrencySymbol);
    });
  }

  setFieldInfoMessage = () => {
    this.fieldInfoMessages.CashDrawerExpectedTotal = StringUtils.format(FieldInfoMessages.CashDrawerExpectedTotal,
      {
        'balance': this.registerAmountInDrawer.toFixed(2),
        'cashSales': this.drawerAmountModel.CashAmount - this.giftCardPurchaseAmount > 0
          ? (this.drawerAmountModel.CashAmount - this.giftCardPurchaseAmount).toFixed(2) : '0.00',
        'giftCardPurchases': this.giftCardPurchaseAmount.toFixed(2),
        'currencySymbol': this.settingParam.CurrencySymbol
      });
  }

  getGiftCardPurchaseAmount = () => {
    if (this.settingParam && this.settingParam.CashDrawerPrinter && this.settingParam.CashDrawerPrinter.Id) {
      this.spinnerService.show();
      this.giftCardService.getGiftCardPurchaseAmount(this.settingParam.CashDrawerPrinter.Id)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res: number) => {
            this.giftCardPurchaseAmount = res ? res : 0;
            this.setFieldInfoMessage();
          }, error: this.alertsService.showApiError
        });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.drawerCheckPointPrice = cloneDeep(DomainConstants.DrawerCheckPointPrice);
    this.setCurrencySymbol();
    this.totalPrice = 0;
  }

  originalOrder = (firstKey: KeyValue<number, string>, secondKey: KeyValue<number, string>): number => {
    return 0;
  }

  closeDrawerCheckpoint() {
    if (this.totalPrice) {
      const modalRef = this.modalService.getModalWrapper(InfoModalComponent);
      const modal = modalRef.show({
        animated: false,
        class: 'vertical-center',
        'backdrop': 'static',
        keyboard: true,
        initialState: {
          modalHeaderText: 'Confirm',
          confirmButtonText: 'Yes',
          rejectButtonText: 'No',
          message: Messages.CancelClosingDrawer
        }
      });
      modal.close.subscribe(res => {
        if (res && res.shouldConfirm) {
          this.close.emit();
        }
      });
    } else {
      this.close.emit();
    }
  }

  calculatePriceClosingDrawer(value) {
    const currentCheckPointPrice = find(Object.values(this.drawerCheckPointPrice), x => x.Name === this.selectedPrice);
    currentCheckPointPrice.Value = parseInt(currentCheckPointPrice.Value.toString() + value, 10);
    this.calculatePriceClosingDrawerOnKeyPress();
  }

  increasePrice(name) {
    const currentCheckPointPrice = find(Object.values(this.drawerCheckPointPrice), x => x.Name === name);
    currentCheckPointPrice.Value += 1;
    this.calculatePriceClosingDrawerOnKeyPress();
  }

  decreasePrice(name) {
    const currentCheckPointPrice = find(Object.values(this.drawerCheckPointPrice), x => x.Name === name);
    currentCheckPointPrice.Value = currentCheckPointPrice.Value ? currentCheckPointPrice.Value - 1 : 0;
    this.calculatePriceClosingDrawerOnKeyPress();
  }

  clearPrice() {
    const currentCheckPointPrice = find(Object.values(this.drawerCheckPointPrice), x => x.Name === this.selectedPrice);
    const value = currentCheckPointPrice.Value.toString();
    currentCheckPointPrice.Value = currentCheckPointPrice.Value ? parseInt(value.substring(0, value.length - 1), 10) : 0;
    this.calculatePriceClosingDrawerOnKeyPress();
  }

  calculatePriceClosingDrawerOnKeyPress() {
    this.totalPrice = 0.00;
    forEach(Object.values(this.drawerCheckPointPrice), (price) => {
      price.Value = price.Value ? price.Value : 0;
      this.totalPrice += (price.Value * price.CurrencyInDollar);
    });
  }

  enterDollarAmountDuringClosing() {
    this.totalExpectedAmount = this.drawerAmountModel.CashAmount + this.registerAmountInDrawer;
    const varianceThreshold = this.settingParam.CashDrawerVarianceThreshold * this.totalExpectedAmount / 100;
    if (!this.totalExpectedAmount) {
      this.drawerVarianceMessage = StringUtils.format(Messages.ConfirmWithZeroAmount, { 'currencySymbol': this.settingParam.CurrencySymbol });
    } else if (this.totalExpectedAmount - this.totalPrice > varianceThreshold) {
      const msgDollar = (this.totalExpectedAmount - this.totalPrice).toFixed(2);
      const msgVariance = (100 * (this.totalExpectedAmount - this.totalPrice) / this.totalExpectedAmount).toFixed(2);
      this.drawerVarianceMessage = StringUtils.format(Messages.ConfirmWithAmountVariance, { 'enteredAmount': this.totalPrice.toFixed(2), 'totalAmount': this.totalExpectedAmount.toFixed(2), 'varianceAmount': parseFloat(msgDollar).toFixed(2), 'variance': msgVariance, 'currencySymbol': this.settingParam.CurrencySymbol });
    } else if (this.totalExpectedAmount - this.totalPrice <= varianceThreshold) {
      this.drawerVarianceMessage = StringUtils.format(Messages.ConfirmCheckpoint, {
        'amount': this.totalPrice.toFixed(2),
        'currencySymbol': this.settingParam.CurrencySymbol
      });
      this.drawerVarianceMessage = StringUtils.format(this.isDrawerCheckPoint ? Messages.ConfirmCheckpoint : Messages.ConfirmCloseDrawer,
        { 'amount': this.totalPrice.toFixed(2), 'currencySymbol': this.settingParam.CurrencySymbol });
    }
    this.openDrawerVarianceModal();
  }

  openDrawerVarianceModal() {
    const modalRef = this.modalService.getModalWrapper(DrawerVarianceComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      keyboard: false,
      initialState: {
        message: this.drawerVarianceMessage
      }
    });
    modal.close.subscribe(res => {
      if (res && res.isDrawerVarianceOk) {
        this.drawerVarianceOk(res.note);
      }
    });
  }

  drawerVarianceOk(note: string) {
    if (this.drawerAmountModel.AllAmount >= 0) {
      this.drawer.Amount = this.totalPrice;
      this.drawer.UserId = this.applicationStateService.userId;
      this.drawer.DrawerId = this.settingParam.CashDrawerPrinter.Id;
      this.drawer.objParam = this.settingParam;
      this.drawer.objParam.CurrentUser = this.applicationStateService.userDetails.username;
      this.drawer.OneCentPrice = this.drawerCheckPointPrice.Cent1.Value * this.drawerCheckPointPrice.Cent1.CurrencyInDollar;
      this.drawer.FiveCentPrice = this.drawerCheckPointPrice.Cent5.Value * this.drawerCheckPointPrice.Cent5.CurrencyInDollar;
      this.drawer.TenCentPrice = this.drawerCheckPointPrice.Cent10.Value * this.drawerCheckPointPrice.Cent10.CurrencyInDollar;
      this.drawer.TwentyFiveCentPrice = this.drawerCheckPointPrice.Cent25.Value * this.drawerCheckPointPrice.Cent25.CurrencyInDollar;
      this.drawer.FiftyCentPrice = this.drawerCheckPointPrice.Cent50.Value * this.drawerCheckPointPrice.Cent50.CurrencyInDollar;
      this.drawer.OneDollarPrice = this.drawerCheckPointPrice.Dollar1.Value;
      this.drawer.FiveDollarPrice = this.drawerCheckPointPrice.Dollar5.Value * this.drawerCheckPointPrice.Dollar5.CurrencyInDollar;
      this.drawer.TenDollarPrice = this.drawerCheckPointPrice.Dollar10.Value * this.drawerCheckPointPrice.Dollar10.CurrencyInDollar;
      this.drawer.TwentyDollarPrice = this.drawerCheckPointPrice.Dollar20.Value * this.drawerCheckPointPrice.Dollar20.CurrencyInDollar;
      this.drawer.FiftyDollarPrice = this.drawerCheckPointPrice.Dollar50.Value * this.drawerCheckPointPrice.Dollar50.CurrencyInDollar;
      this.drawer.HundredDollarPrice = this.drawerCheckPointPrice.Dollar100.Value * this.drawerCheckPointPrice.Dollar100.CurrencyInDollar;
      this.drawer.TerminalId = this.applicationStateService.terminalId;
      this.drawer.VarianceThreshold = this.settingParam.CashDrawerVarianceThreshold;
      this.drawer.ExceptedAmount = this.drawerAmountModel.CashAmount + this.registerAmountInDrawer;
      this.drawer.Note = note;
      this.spinnerService.show();
      this.cashDrawerService.closeOutCashDrawer(this.drawer)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res) => {
            this.closeOutCashDrawerCompleted();
          }, error: this.alertsService.showApiError
        });
    }
  }

  closeOutCashDrawerCompleted() {
    if (this.isDrawerCheckPoint) {
      this.drawerPrice = this.totalPrice;
      this.saveAmountInDrawer();
    } else {
      this.close.emit({ shouldUpdateStatus: true });
    }
  }

  saveAmountInDrawer() {
    if (isNaN(this.drawerPrice)) {
      this.alertsService.renderErrorMessage(Messages.ErrorWhileDrawerPriceEmpty);
    } else {
      const drawerAmount: DrawerAmount = this.cashDrawerService.newDrawerAmount();
      drawerAmount.Price = this.drawerPrice;
      drawerAmount.objParam = this.settingParam;
      drawerAmount.RegisterId = this.settingParam.CashDrawerPrinter.Id;
      drawerAmount.UserId = this.applicationStateService.userId;
      this.cashDrawerService.saveAmountInDrawer(drawerAmount)
        .pipe(finalize(() => {
          this.spinnerService.show();
        }))
        .subscribe({
          next: (res) => {
            this.saveAmountInDrawerCompleted(res);
          }, error: this.alertsService.showApiError
        });
    }
  }

  saveAmountInDrawerCompleted(response) {
    this.close.emit({ shouldUpdateStatus: true });
    if (response.ReturnStatus) {
      this.router.navigate(['order/order-entry']);
    } else {
      const modalRef = this.modalService.getModalWrapper(InfoModalComponent);
      modalRef.show({
        animated: false,
        class: 'vertical-center',
        initialState: {
          message: response.ReturnMessage[0]
        }
      });
    }
  }
}
