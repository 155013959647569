<div class="h-80 menu-explorer-header">
    <pos-header-entry-main [links]="headerLinks" [isShowBarIcon]="false"></pos-header-entry-main>
</div>
<div class="header">
    <div class="font-20 bold-700" [ngStyle]="{'color': headingFontColor}">
        {{currentScreenButton?.Screen.Name}}</div>
</div>
<div class="float-end r-20 display-flex sub-header">
    <div class="relative">
        <span class="search-icons">
            <pos-icon [icon]="icons.search"></pos-icon>
        </span>
        <p-autoComplete [(ngModel)]="searchText" [suggestions]="searchButtons" (completeMethod)="search()" (input)="search()"
            field="ButtonText" [size]="20" placeholder="Search..." [minLength]="3" (onSelect)="selectSearch($event)">
        </p-autoComplete>
    </div>
    <span *ngIf="designMode" class="ps-pix-5 screen-setup">
        <pos-screen-setup [screenId]="currentScreenButton?.Screen?.Id" [homeScreenId]="screenId"
            [screenWidth]="screenWidth" [screenName]="currentScreenButton?.Screen?.Name"
            [navigation]="currentScreenButton" [menuExplorer]="true" [screenButtons]="screenButtons"
            (reloadNavigation)="getOrderNavigationDetails($event)">
        </pos-screen-setup>
    </span>
</div>
<div id="buttons-div" class="screen-buttons pt-pix-10"
    [ngClass]="{'home-screen': screenId == currentScreenButton?.Screen.Id && !designMode}">
    <div class="buttons-container"
        [ngStyle]="{'grid-template-columns': 'repeat(auto-fill, ' + currentScreenButton?.UiConfiguration?.width+')'}">
        <div *ngFor="let button of currentScreenButton?.Buttons"
            [hidden]="!button.IsVisible" class="button"
            [ngStyle]="{'height': currentScreenButton?.UiConfiguration.height+ 'px'}">
            <!-- for out of stock product 
            [ngClass]="{'opacity-0-6': button.SalesProductId && !button.IsInStock && !button.IsAllowOutOfStockOrder}" -->
            <pos-menu-explorer-button [button]="button" [uiConfiguration]="currentScreenButton.UiConfiguration"
                (buttonClick)="buttonClick(button)" [designMode]="designMode" [screenButtons]="screenButtons"
                (reloadNavigation)="getOrderNavigationDetails($event)">
            </pos-menu-explorer-button>
        </div>
    </div>
</div>
<div class="footer" *ngIf="screenId != currentScreenButton?.Screen.Id || designMode">
    <button class="btn btn-primary menu-explorer-primary-button pos-button" (click)="redirectToHomeScreen()"
        *ngIf="screenId != currentScreenButton?.Screen.Id">
        <pos-icon [icon]="icons.home" class="pe-pix-3">
        </pos-icon> HOME
    </button>
    <button type="button" class="btn btn-secondary font-size-larger font-nobold border-radius ms-pix-10 pos-button" value="Cancel"
        *ngIf="designMode" (click)="closeDesignMode()">
        <pos-icon [icon]="icons.undo" class="pe-pix-3">
        </pos-icon> Cancel
    </button>
</div>
