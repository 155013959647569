import { Component, OnInit } from '@angular/core';
import {
  BaseListComponent, AlertsService, SpinnerService, ModalService, PrintTableService,
  ApplicationStateService, Messages, whiteListedAddressBook, Permissions, Levels, AuthenticationService, DomainConstants
} from 'src/app/shared';
import { WhitelistedIpAddress } from '../../interface/whitelisted-ip-address';
import { WhitelistedIpAddressService } from '../../services/whitelisted-ip-address.service';
import { GridColumn } from '@tarktech/tark-ng-utils';
import { WhitelistedIpAddressEditComponent } from '../whitelisted-ip-address-edit/whitelisted-ip-address-edit.component';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'pos-whitelisted-ip-address-list',
  templateUrl: './whitelisted-ip-address-list.component.html',
})
export class WhitelistedIpAddressListComponent extends BaseListComponent<WhitelistedIpAddress> implements OnInit {

  whitelistedColumns: Array<GridColumn> = [];
  whitelistedAddresses: Array<WhitelistedIpAddress> = [];
  icons = { whiteListedAddressBook };
  permission = {
    name: Permissions.SecurityWhitelistedAddresses,
    editLevel: Levels.Edit
  };
  constructor(protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected whitelistedAddressService: WhitelistedIpAddressService,
    protected printService: PrintTableService,
    protected applicationStateService: ApplicationStateService,
    private authenticationService: AuthenticationService,
    protected router: Router,
    protected route: ActivatedRoute) {
    super(whitelistedAddressService, alertService, spinnerService, modalService, applicationStateService, printService, route);
  }

  ngOnInit() {
    this.deleteSuccessMessage = Messages.WhitelistedIpDeleted;
    this.whitelistedColumns = this.configureGridColumns();
    this.loadSubscriptions();
    this.checkPermission();
  }

  private loadSubscriptions(): void {
    this.data$.subscribe({
      next: (res) => {
        this.whitelistedAddresses = res;
        this.whitelistedAddresses = _.sortBy(this.whitelistedAddresses, 'IpAddress');
        this.gridData = this.whitelistedAddresses;
      }
    });
  }

  private checkPermission(): void {
    if (!this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      _.remove(this.whitelistedColumns, (column) => {
        return (column.Type === DomainConstants.GridColumnsTypes.EditColumn
          || column.Type === DomainConstants.GridColumnsTypes.DeleteColumn);
      });
    }
  }

  getGridColumns(): Array<GridColumn> {
    return [
      new GridColumn({ HeaderText: 'IP Address', Field: 'IpAddress', IsSortable: true, Width: '90%' })
    ];
  }

  editItem(id: number, data?: WhitelistedIpAddress): void {
    const modalRef = this.modalService.getModalWrapper(WhitelistedIpAddressEditComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      initialState: {
        id: id
      }
    });

    modal.close.subscribe(res => {
      if (res && res.shouldReload) {
        this.reload();
      }
    });
  }

  getConfirmDeleteMessage(data: WhitelistedIpAddress): string {
    return Messages.ConfirmDeleteWhitelistedIp;
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
