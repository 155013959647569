<pos-list-wrapper [headingIcon]="icons.externalApiLink"
    permissions='[{"Name":"{{permission.name}}", "Level":"{{permission.readOnlyLevel}}"}]' heading="External API"
    (close)="close()">
    <div>
        <ng-table #gridComponent [columns]="externalApiColumn" [data]="externalApis">
        </ng-table>
        <pos-confirm-delete [message]="message" (onConfirmAcknowledge)="onConfirmAcknowledge()"
            #confirmExternalApiDelete>
        </pos-confirm-delete>
    </div>
</pos-list-wrapper>
<ng-template #responseModal>
    <div>
        <div>
            <button class="close" (click)="closeForcefullyOpenDialog()">&times;</button>
            <h4 class="modal-content__heading">Response</h4>
        </div>
        <div class="modal-body screen-scroll p-pix-5" style="overflow-x: hidden">
            <div class="p-0 pt-pix-10">
                <div class="row ps-pix-15">
                    <div class="col-md-2 pe-0">
                        <label>Status Code: </label>
                    </div>
                    <div class="col-md-10">
                        {{externalAPIResponse.StatusCode}}
                    </div>
                </div>
                <div class="row ps-pix-15" [hidden]="!externalAPIResponse.Response">
                    <div class="col-md-2">
                        <label>Data:</label>
                    </div>
                    <div class="col-md-10 pe-pix-25" style="word-break: break-all;">
                        {{externalAPIResponse.Response}}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 action-button-container popup-footer pb-pix-10">
            <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="closeForcefullyOpenDialog();"
                type="button">Close</button>
        </div>
        <div class="clearfix"></div>
    </div>
</ng-template>
