import { Route } from "@angular/router";
import { forEach } from "lodash";
import { AuthGuard } from "../../app-guard";
import { DomainConstants, Levels, Permissions } from "../../shared/constants";
import { PromotionBaseComponent } from "./components/promotion-base/promotion-base.component";
import { PromotionConfigComponent } from "./components/promotion-config";
import { PromotionsComponent } from "./components/promotions";

const promotionChildren: Array<Route> = [];
forEach(DomainConstants.PromotionTypes, promotionType => {
    promotionChildren.push(...[{
        path: promotionType.Route,
        component: PromotionsComponent,
        data: {
            group: promotionType.Route,
            permissions: [{ 'Name': promotionType.Permission, 'Level': Levels.Access }]
        }
    }, {
        path: promotionType.Route + '/:id',
        component: PromotionConfigComponent,
        data: {
            group: promotionType.Route,
            permissions: [{ 'Name': promotionType.Permission, 'Level': Levels.Edit }]
        }
    }]);
});

const PROMOTION: Route = {
    path: '',
    canActivateChild: [AuthGuard],
    component: PromotionBaseComponent,
    data: {
        permissions: [{ 'Name': Permissions.ManagePromotions, 'Level': Levels.Access }]
    },
    children: promotionChildren
};
export const PROMOTION_STATES = [PROMOTION];
