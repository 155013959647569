<div class="m-0-auto width-65">
    <div class="page-content">
        <div class="tab-content">
            <div class="tab-pane fade show active">
                <div class="col-12 px-3" >
                    <a class="close pe-pix-10 pointer" (click)="cancel()">×</a>
                    <div class="row">
                        <div class="col-10 display-flex align-items-center">
                            <img src='images/admin/manage-layout.png' height="20px" class="pe-pix-10 page-content__header-icon"/> 
                            <h3 class="page-content__header">Dashboard List
                            </h3>
                        </div>
                    </div>

                    <div class="row mx-0">
                        <div class="col-12">
                            <ng-table [columns]="dashboardColumns" [data]="dashboardList" [paginator]="false">
                            </ng-table>
                            <ng-template let-data="rowData" #operationHeaderTemplate>
                                <span (click)="editItem(0, null)" tooltip title="Add" scrollup>
                                    <pos-action-icon [icon]="icons.plusWhite" iconText="Add"></pos-action-icon>
                                </span>
                            </ng-template>
                            <ng-template let-data="rowData" #operationTemplate>
                                <span (click)="editItem(data.Id, data)" tooltip title="Edit" scrollup>
                                    <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
                                </span>
                                <span class="vertical-mid ps-pix-3 pointer" tooltip (click)="loadDashboard(data.Id)" title="View">
                                    <pos-action-icon [icon]="icons.search" [additionalClasses]="['white']" [backgroundClasses]="['orange-red']"></pos-action-icon>
                                </span>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
