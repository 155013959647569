import { Component, OnInit, ViewChild } from '@angular/core';
import { JobCode } from '../../interface/job-code';
import { NgForm } from '@angular/forms';
import { BaseCrudComponent } from 'src/app/shared/components/base-crud.component';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { Messages } from 'src/app/shared/constants';
import { JobCodeService } from '../../service/job-code.service';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { jobCodeBusinessTime } from 'src/app/shared/components/icon';
import { FormUtilityService } from 'src/app/shared/services/form-utility.service';

@Component({
    selector: 'pos-job-code-edit',
    templateUrl: './job-code-edit.component.html',
    styleUrls: ['./job-code-edit.component.scss']
})
export class JobCodeEditComponent extends BaseCrudComponent<JobCode> implements OnInit {

    jobCode: JobCode;
    @ViewChild('jobCodeForm') jobCodeForm: NgForm;
    icons = {
        jobCodeBusinessTime
    }
    get getForm(): NgForm {
        return this.jobCodeForm
    }
    props = {
        controlBoxClass: 'col-lg-4 col-md-5 col-sm-8 col-xs-12'
    }
    constructor(
        protected alertService: AlertsService,
        protected spinnerService: SpinnerService,
        protected jobCodeService: JobCodeService,
        private router: Router,
        private route: ActivatedRoute,
        formUtilityService: FormUtilityService
    ) {
        super(jobCodeService, alertService, spinnerService, formUtilityService);
        this.id = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
    }

    ngOnInit() {
        this.jobCode = this.jobCodeService.newJobCode();
        this.saveSuccessMessage = Messages.JobCodeSaveSuccess;
        this.dataSubscription();
    }

    private dataSubscription(): void {
        if (this.id > 0) {
            this.spinnerService.show();
            this.loadData().pipe(finalize(() => {
                this.spinnerService.hide();
            }))
                .subscribe({
                    next: (res) => {
                        this.jobCode = res;
                    }, error: this.alertService.showApiError
                });
        }
    }
    public loadDependencies() {
    }
    public submit(isValid: boolean) {
        if (!isValid) {
            return;
        }
        this.save(this.jobCode);
    }
    public onSaveSuccess(model: JobCode): void {
        this.cancelEditing();
    }
    public cancelEditing(): void {
        this.router.navigate(['job-codes'], { relativeTo: this.route.parent });
    }

}
