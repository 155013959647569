import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { FilterConfiguration } from 'src/app/shared/interface';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';
import { ButtonHistory } from '../interface/button-history';

@Injectable({
    providedIn: 'root'
})
export class ButtonHistoryService extends BaseCrudService<ButtonHistory> {

    constructor(httpService: HttpClient) {
        super('button-history', httpService);
    }

    getNewButtonHistory(): ButtonHistory {
        return {
            Id: 0,
            ButtonId: null,
            ButtonText: null,
            Toggle: false,
            SalesProductId: null,
            SalesProduct: null,
            Color: '',
            Image: null,
            ButtonDateAdded: new Date(Date.now()),
            ButtonDateEdited: new Date(Date.now()),
            ManagerOnly: false,
            SpecialFunction: null,
            DateRange: null,
            InternalText: null,
            ReceiptText: null,
            TextColor: '',
            GroupName: null,
            PrintToKitchen: 0,
            ButtonGroupMin: null,
            ButtonGroupMax: null,
            MakeTableFgColor: null,
            MakeTableBgColor: null,
            PickScreenText: null,
            PickScreenTextBgColor: null,
            ScheduleStartDate: new Date(Date.now()),
            ScheduleEndDate: new Date(Date.now()),
            ScheduleStartTime: null,
            ScheduleEndTime: null,
            BorderColor: null,
            ButtonType: null,
            BackgroundColor: null,
            DateAdded: new Date(Date.now()),
            DateEdited: new Date(Date.now()),
            IsNotified: true,
            UserId: 0
        };
    }

    getButtonHistoryByButtonId(buttonId: number): Observable<Array<ButtonHistory>> {
        return this.httpService.get<Array<ButtonHistory>>(`${this.apiEndpoint}/button/${buttonId}`);
    }

    getButtonHistories(productId: number, filterConfiguration: FilterConfiguration): Observable<Array<ButtonHistory>> {
        return this.httpService.post<Array<ButtonHistory>>(`${this.apiEndpoint}/all/${productId}`,
            filterConfiguration);
    }

    getTotalRecordsCount(screenId: number): Observable<number> {
        return this.httpService.get<number>(`${this.apiEndpoint}/count/${screenId}`);
    }

}
