<pos-list-wrapper [headingIcon]="icons.accountAttributeType" heading="Account Attribute Types" (close)="close()">
    <div class="row">
        <div class="col-12">
            <div>
                <ng-table #gridComponent [sort]="false" [paginator]="false" [columns]="accountAttributeTypeColumns"
                    [data]="accountAttributeTypes" [isReorderableTable]="true" [exportFilename]="'account-attribute-types'"
                    [ReorderableColumnRow]="'row'" (onRowReorder)="gridRowReorder($event)">
                </ng-table>
            </div>
        </div>
    </div>
</pos-list-wrapper>
<pos-confirm-delete (onConfirmAcknowledge)="onConfirmAcknowledge()" #confirmAccountAttributeTypeDelete>
</pos-confirm-delete>
<pos-print-table #printTableReference [hidden]="true"></pos-print-table>
