<div class="page-content">
    <div class="" [ngClass]="{'tab-content' : fromListPage}">
        <div class="tab-pane fade in active show">
            <div class="col-md-12 pb-pix-5">
                <a class="close" *ngIf="fromListPage" routerLink="../">×</a>
                <div class="row" *ngIf="fromListPage">
                    <div class="col-md-10 col-xs-9">
                        <h3 class="page-content__header">
                            Online Order Schedule Overrides
                        </h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-1 col-sm-1"></div>
                    <div class="col-md-12 col-sm-12">
                        <ng-table #promotionGrid [columns]="scheduleOverrideColumns" [paginator]="fromListPage"
                            [exportFilename]="'schedule-overrides'" [data]="scheduleOverrides">
                        </ng-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<pos-print-table [hidden]="true"></pos-print-table>
