import { Component, OnInit } from '@angular/core';
import { NumpadOptions, ModalComponent, Messages, DomainConstants } from 'src/app/shared';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OrderNavigationButton } from 'src/app/orders/interface';
@Component({
  selector: 'pos-manual-discount',
  templateUrl: './manual-discount.component.html',
  styleUrls: ['./manual-discount.component.scss']
})
export class ManualDiscountComponent extends ModalComponent implements OnInit {
  public onAddDiscountSubmit: Function;
  public onAddDiscountCancel: Function;
  isDisplayTaxable: boolean = true;
  numpadTitle: string = "Manual Amount"
  taxableItem: string = Messages.TaxableItem;
  markupManual = DomainConstants.ButtonFunctionTypes.NUMERIC_MARKUP.Name;
  discountNumeric = DomainConstants.ButtonFunctionTypes.NUMERIC_DISCOUNT_MANUAL.Name;
  selectedIsTaxable: boolean = true;
  
  discountValue: number = 0;
  button: OrderNavigationButton;
  numpadOptions: NumpadOptions = {
    allowAlphabets: false,
    allowLeadingZero: false,
    allowDecimal: true,
    
  };
  constructor(modalRef: BsModalRef) {
    super(modalRef);

  }

  ngOnInit() {
    this.onAddDiscountSubmit = this.onAddDiscount.bind(this);
    this.onAddDiscountCancel = this.cancelDiscount.bind(this);
    this.numpadOptions.doubleZero = this.button.SpecialFunction !== this.discountNumeric;
  }

  cancelDiscount() {
    this.hide({ shouldReload: false })
  }

  onAddDiscount(event: any) {
    this.hide({ amountValue: event.value, isTaxable: this.selectedIsTaxable })
  }

  taxableSelection() {
    if (!this.selectedIsTaxable) {
      this.taxableItem = Messages.TaxableItem;
      this.selectedIsTaxable = true;
    }
    else {
      this.taxableItem = Messages.NonTaxable;
      this.selectedIsTaxable = false;
    }
  }


}
