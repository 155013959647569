import { Component, OnInit, ViewChild } from '@angular/core';
import { SpinnerService, AlertsService, Messages, ApplicationStateService, SettingParam, FormUtilityService } from 'src/app/shared';
import { ConfirmDeleteModalComponent, ModalFormComponent, ModalService } from 'src/app/shared/components';
import { InventoryZoneService } from 'src/app/shared/services/inventory-zone.service';
import { InventoryBinModel } from 'src/app/information-management/inventory-zone/interface';
import { clone, infoCircle, lightBulb } from 'src/app/shared/components/icon';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'pos-inventory-zone-bins-edit-model',
  templateUrl: './inventory-zone-bins-edit-model.component.html'
})
export class InventoryZoneBinsEditModelComponent extends ModalFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.form
  }
  inventoryBinEditId: number;
  editInventoryzoneId: number;
  settingParams: SettingParam;
  @ViewChild('formInventoryBinEdit', { static: true }) form: NgForm;
  inventoryBinData: InventoryBinModel;
  inventoryBinName: string;
  appConfig: any;

  icons = {
    clone, infoCircle, lightBulb
  };

  tabList = {
    Details: 'Details',
    Lights: 'Lights'
  };
  selectedTab = this.tabList.Details;

  constructor(private spinnerService: SpinnerService,
    private inventoryZoneService: InventoryZoneService,
    private alertService: AlertsService,
    private applicationStateService: ApplicationStateService,
    inventoryBinEditModalRef: BsModalRef, modalService: ModalService,
    formUtilityService: FormUtilityService) {
    super(inventoryBinEditModalRef, modalService, formUtilityService);
    this.inventoryBinData = this.inventoryZoneService.newInventoryBin();
  }

  ngOnInit() {
    this.loadInventoryBinData();
    this.settingParams = this.applicationStateService.settingParam;
    this.setInitialValue();
  }

  loadInventoryBinData() {
    this.appConfig = this.applicationStateService.applicationInitializationConfigurations;
    if (this.inventoryBinEditId > 0) {
      this.spinnerService.show();
      this.inventoryZoneService.getInventoryBin(this.editInventoryzoneId, this.inventoryBinEditId)
        .subscribe(response => {
          this.inventoryBinData = response;
          this.inventoryBinName = response.Bin;
        },
          (error) => { this.alertService.showApiError(error); },
          () => { this.spinnerService.hide(); });
    } else {
      this.inventoryBinData = this.inventoryZoneService.newInventoryBin();
    }
  }

  editCancel() {
    this.hide({ shouldReload: false });
  }

  flash() {
    this.spinnerService.show();
    this.inventoryZoneService.triggerFlash(this.inventoryBinEditId, this.settingParams.TerminalId, this.settingParams.TerminalName)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.alertService.renderSuccessMessage(Messages.RequestSendSuccess);
        }, error: this.alertService.showApiError
      });
  }

  updateInventoryBinColor() {
    if (this.inventoryBinData.Color !== '' && this.inventoryBinData.Color !== null) {
      const confirmDelete = this.modalService.show(ConfirmDeleteModalComponent, {
        animated: false,
        class: 'vertical-center',
        'backdrop': 'static',
        initialState: {
          message: StringUtils.format(Messages.ConfirmUpdateBinColor,
            { 'binName': this.inventoryBinData.Bin })
        }
      });
      confirmDelete.close.subscribe(res => {
        if (res && res.shouldDelete) {
          this.onConfirmAcknowledgeUpdate();
        }
      });
    }
  }

  onConfirmAcknowledgeUpdate() {
    this.spinnerService.show();
    this.inventoryZoneService.updateInventoryBinColor(this.inventoryBinData).subscribe(response => {
      this.alertService.renderSuccessMessage('Apply color to bin ' + this.inventoryBinData.Bin + ' updated successfully');
    },
      (error) => { this.alertService.showApiError(error); },
      () => { this.spinnerService.hide(); });
  }

  saveInventoryBin(isValid) {
    if (!isValid) {
      return;
    }
    this.spinnerService.show();
    const params: InventoryBinModel = {
      Bin: this.inventoryBinData.Bin,
      Color: this.inventoryBinData.Color,
      Description: this.inventoryBinData.Description,
      Zone_id: this.editInventoryzoneId,
      Id: this.inventoryBinEditId
    };
    if (this.inventoryBinEditId > 0) {
      this.inventoryZoneService.updateInventoryBin(this.editInventoryzoneId, params)
        .subscribe(response => {
          this.hide({ shouldReload: true });
        },
          (error) => { this.alertService.showApiError(error); },
          () => { this.spinnerService.hide(); });
    } else {
      this.inventoryZoneService.addInventoryBin(this.editInventoryzoneId, params)
        .subscribe(response => {
          this.hide({ shouldReload: true });
        },
          (error) => { this.alertService.showApiError(error); },
          () => { this.spinnerService.hide(); });
    }
  }
}
