import { formatDate } from '@angular/common';
import { AfterContentInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DisplayGrid, GridsterConfig, GridType } from 'angular-gridster2';
import { filter, find, forEach, range } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { finalize } from 'rxjs/internal/operators/finalize';
import { MenuDisplaySetting, TerminalsService } from 'src/app/configurator/terminals';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { DomainConstants } from 'src/app/shared/constants';
import { ApplicationStateService, RabbitMQService, TerminalStatusCheckService } from 'src/app/shared/services';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { FontService } from 'src/app/shared/services/font.service';
import { MenuDisplay, MenuDisplayConfig } from '../../interfaces/menu-display';
import { MenuWidget } from '../../interfaces/menu-widget';
import { MenuDisplayService } from '../../services/menu-display.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MenuWidgetService } from '../../services';
import { WidgetDetails } from '../../interfaces';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pos-menu-display-preview',
  templateUrl: './menu-display-preview.component.html',
  styleUrls: ['./menu-display-preview.component.scss']
})
export class MenuDisplayPreviewComponent implements OnInit, AfterContentInit, OnDestroy {

  menuId: number;
  options: GridsterConfig;
  menuWidgets: Array<MenuWidget> = [];
  digitalMenu: MenuDisplay;
  menuDisplayConfig: MenuDisplayConfig;

  menuDisplayOverrides: Array<MenuDisplaySetting> = [];
  isSilentReload = false;
  lastLoadedMenuId = 0;
  delay = null;
  terminalType = '';

  autoRefreshWidgetInterval: any;
  rabbitMqFilterMenuItemsSubscription: Subscription;
  rabbitMqHighlightMenuItemsSubscription: Subscription;
  rabbitMqRefreshMenuItemsSubscription: Subscription;
  userClockOutSubscription: Subscription;
  refreshTerminalRequestSubscription: Subscription;
  rabbitMQTerminalStatusCheckSubscription: Subscription;
  overrideMenuId: number;
  groupWidgetName = DomainConstants.DigitalMenuWidgetTypes.Group.Name;

  constructor(private route: ActivatedRoute,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private router: Router,
    private fontService: FontService,
    private applicationStateService: ApplicationStateService,
    protected terminalService: TerminalsService,
    private menuDisplayService: MenuDisplayService,
    protected rabbitMQService: RabbitMQService,
    private terminalStatusChecker: TerminalStatusCheckService,
    public menuWidgetService: MenuWidgetService) {
    this.menuId = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
    this.terminalType = route?.snapshot?.data?.type ? route.snapshot.data.type : applicationStateService.terminalType;
  }

  ngAfterContentInit(): void {
    this.subscribeToFilterMenuItemsBroadcastQueue();
    this.subscribeToHighlightMenuItemsBroadcastQueue();
    this.subscribeToRefreshMenuDisplayBroadcastQueue();
    this.subscribeToTerminalLogoutMessage();
    this.subscribeTerminalRefreshMessage();
    this.subscribeToTerminalStatusCheck();
  }

  ngOnInit(): void {
    this.setGridConfigurations();
    if (this.terminalType === DomainConstants.TerminalTypes.MENU_DISPLAY.Name) {
      this.loadMenuDisplaySettings();
    } else {
      this.loadData(this.menuId);
    }
    this.fontService.loadMenuDisplayFonts();
  }

  ngOnDestroy(): void {
    if (this.autoRefreshWidgetInterval) {
      clearInterval(this.autoRefreshWidgetInterval);
    }
  }

  private setGridConfigurations() {
    this.options = {
      gridType: GridType.Fixed,
      displayGrid: DisplayGrid.None,
      pushItems: true,
      draggable: {
        enabled: false
      },
      resizable: {
        enabled: false
      },
      margin: 0,
      maxItemCols: 1000000,
      maxItemRows: 1000000,
      maxItemArea: 1000000000,
      fixedColWidth: 5,
      fixedRowHeight: 5,
      maxRows: 2000,
      maxCols: 2000,
      allowMultiLayer: true,
      defaultLayerIndex: 1,
      maxLayerIndex: 2,
      baseLayerIndex: 1,
    };
  }

  loadMenuDisplaySettings() {
    this.spinnerService.show();
    this.terminalService.getMenuDisplaySettings(this.applicationStateService.terminalId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (menuDisplaySettings) => {
          this.menuDisplayOverrides = menuDisplaySettings;
          this.setOverriddenMenu(true);
        }, error: this.alertService.showApiError
      });
  }

  setOverriddenMenu(isFirstReload = false) {
    this.isSilentReload = !isFirstReload;
    const today = new Date();
    const currentTime = formatDate(today, 'HH:mm:ss', 'en');
    this.overrideMenuId = null;
    if (this.menuDisplayOverrides.length) {
      const currentOverriddenMenu: MenuDisplaySetting = find(this.menuDisplayOverrides, (x) => { 
        let parsedEndTime = this.parseTime(x.EndTime);
        return this.parseTime(x.StartTime) <= currentTime
        && (currentTime) < parsedEndTime && x.Days.includes(today.getDay().toString())
    });
      if (currentOverriddenMenu) {
        this.overrideMenuId = currentOverriddenMenu.MenuDisplayId;
        const endDateTime =  this.convertStringToTime(currentOverriddenMenu.EndTime).getTime()
        this.delay = endDateTime - today.getTime();
        this.setMenuOnTimeout();
        this.loadData(this.overrideMenuId);
      } else {
        this.checkForNextOverriddenMenu();
      }
    } else {
      this.loadData(this.menuId);
    }
  }

  checkForNextOverriddenMenu() {
    const today = new Date();
    const currentTime = formatDate(today, 'HH:mm:ss', 'en');
    const nextOverriddenMenusOfDay: Array<MenuDisplaySetting> = filter(this.menuDisplayOverrides,
      x => x.Days.includes(today.getDay().toString()) &&
        currentTime <= this.parseTime(x.StartTime)).sort((a, b) => a.StartTime.localeCompare(b.StartTime));
    if (nextOverriddenMenusOfDay.length) {
      this.delay = this.convertStringToTime(nextOverriddenMenusOfDay[0].StartTime).getTime() - today.getTime();
    } else {
      this.getNextDayTimeout();
    }
    this.loadData(this.menuId);
    this.setMenuOnTimeout();
  }

  getNextDayTimeout() {
    const today = new Date();
    const daysOfWeek = range(0, 7);
    const days = [...daysOfWeek.slice(today.getDay() + 1, daysOfWeek.length),
    ...daysOfWeek.slice(0, today.getDay() + 1)];
    forEach(days, (day, i) => {
      const overriddenMenus = filter(this.menuDisplayOverrides, x => x.Days.includes(day.toString()))
        .sort((a, b) => a.StartTime.localeCompare(b.StartTime));
      if (overriddenMenus.length) {        
        this.delay = this.nextDate(i + 1, overriddenMenus[0].StartTime).getTime() - today.getTime();
        return false;
      }
    });
  }

  nextDate(x, time: string) {
    const hoursAndMinutes = time.split(':').map(t => parseInt(t, 10));
    const nextDate = new Date();
    nextDate.setDate(nextDate.getDate() + x);
    nextDate.setHours(hoursAndMinutes[0], hoursAndMinutes[1], hoursAndMinutes[2]);
    return nextDate;
  }

  setMenuOnTimeout() {
    const menuOverrideTimeout = setTimeout(() => {
      this.setOverriddenMenu();
      if (menuOverrideTimeout) {
        clearTimeout(menuOverrideTimeout);
      }
    }, this.delay);
  }

  convertStringToTime(time: string) {
    const hoursAndMinutes = time.split(':').map(x => parseInt(x, 10));
    const date = new Date();
    date.setHours(hoursAndMinutes[0], hoursAndMinutes[1], hoursAndMinutes[2]);
    return date;
  }

  loadData(menuId: number, autoRefresh: boolean = false) {
    if (menuId !== this.lastLoadedMenuId || autoRefresh) {
      const observable: Array<Observable<any>> = [];
      observable.push(this.menuDisplayService.getMenuWidgets(menuId));
      observable.push(this.menuDisplayService.get(menuId));
      observable.push(this.menuWidgetService.getMenuWidgets(menuId));
      if (!this.isSilentReload) {
        this.spinnerService.show();
      }
      forkJoin(observable).pipe(finalize(() => {
        if (!this.isSilentReload) {
          this.spinnerService.hide();
        }
      })).subscribe({
        next: ([menuWidgets, digitalMenu, widgets]: [Array<MenuWidget>, MenuDisplay, Array<WidgetDetails>]) => {
          this.lastLoadedMenuId = menuId;
          this.menuWidgets = menuWidgets;
          this.menuWidgets.forEach((data) => {
            data.GridItem = JSON.parse(data.WidgetDetails);
          });
          this.digitalMenu = digitalMenu;
          this.menuDisplayConfig = JSON.parse(this.digitalMenu.Config);
          this.options.maxCols = ((digitalMenu.Orientation ? digitalMenu.Height : digitalMenu.Width) / this.options.fixedColWidth) * 2;
          this.options.maxRows = ((digitalMenu.Orientation ? digitalMenu.Width : digitalMenu.Height) / this.options.fixedRowHeight) * 2;
          this.options.maxItemArea = (this.options.maxCols * this.options.maxRows);
          this.menuWidgetService.widgetDataSubject.next(widgets);
          this.autoRefreshMenuDisplay();
        },
        error: this.alertService.showApiError
      });
    }
  }

  autoRefreshMenuDisplay() {
    if (this.autoRefreshWidgetInterval) {
      clearInterval(this.autoRefreshWidgetInterval);
    }
    const menuDisplayAutoRefreshIntervalMinutes = this.applicationStateService.settingParam?.MenuDisplayAutoRefreshIntervalMinutes;
    this.autoRefreshWidgetInterval = setInterval(() => {
      this.loadWidget();
    }, 60 * 1000 * (menuDisplayAutoRefreshIntervalMinutes ? menuDisplayAutoRefreshIntervalMinutes : 10));
  }

  loadWidget() {
    this.menuWidgetService.getMenuWidgets(this.overrideMenuId??this.menuId)
    .subscribe({
      next: (res: Array<WidgetDetails>) => {
        this.menuWidgetService.widgetDataSubject.next(res);
        this.autoRefreshMenuDisplay();
      },
      error: this.alertService.showApiError
    });
  }

  subscribeToFilterMenuItemsBroadcastQueue() {
    this.rabbitMqFilterMenuItemsSubscription = this.rabbitMQService.subscribeToMenuDisplayFilteredMessage$()
      .subscribe({
        next: (message: any) => {
          this.menuWidgetService.menuItemChangeSubject.next({ Type: 'FilterMenuItems', Message: message })
        }, error: this.alertService.showApiError
      });
  }

  subscribeToHighlightMenuItemsBroadcastQueue() {
    this.rabbitMqHighlightMenuItemsSubscription = this.rabbitMQService.subscribeToMenuDisplayItemHighlightedMessage$()
      .subscribe({
        next: (message: any) => {
          this.menuWidgetService.menuItemChangeSubject.next({ Type: 'HighlightMenuItems', Message: message })
        }, error: this.alertService.showApiError
      });
  }

  subscribeToTerminalLogoutMessage() {
    this.userClockOutSubscription = this.rabbitMQService.subscribeToUserClockOutMessage$()
      .subscribe({
        next: (message: any) => {
          if (message.Payload.UserId === this.applicationStateService.userId) {
            this.router.navigate(['login']);
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
        }, error: () => {
          console.log('error while connecting to RabbitMQ.');
        }
      });
  }

  subscribeTerminalRefreshMessage() {
    this.refreshTerminalRequestSubscription = this.rabbitMQService.subscribeToRefreshTerminal$(this.applicationStateService.terminalId)
    .subscribe({
      next: () => {
        window.location.reload();
      }
    })
  }

  subscribeToRefreshMenuDisplayBroadcastQueue() {
    this.rabbitMqRefreshMenuItemsSubscription = this.rabbitMQService.subscribeToRefreshMenuDisplayMessage$()
      .subscribe({
        next: (message) => {
          this.loadData(this.menuId, true);
        }, error: this.alertService.showApiError
      });
  }

  subscribeToTerminalStatusCheck() {
    this.rabbitMQTerminalStatusCheckSubscription = this.terminalStatusChecker.subscribeTerminalStatusCheck(this.applicationStateService.terminalId)
    .subscribe({
      next: (res) => this.terminalStatusChecker.publishTerminalStatusResponse(res)
    })
  }

  parseTime(timeString) {
    const time = timeString.split(':');
    const date = new Date();
    date.setHours(time[0], time[1], time[2]);
    return formatDate(date, 'HH:mm:ss', 'en');
  }

}
