import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertsService, Messages, RuntimeConstants } from 'src/app/shared';
import { ModalComponent, ModalService, SpinnerService, editWhiteWithText, plusWhite } from 'src/app/shared/components';
import { finalize } from 'rxjs/operators';
import { KioskChoice, KioskScreenChoice } from '../../../interfaces';
import { OrderKioskScreenChoiceService } from '../../../services/order-kiosk-screen-choice.service';
import { KioskChoiceEditComponent } from '../../kiosk-choice-edit';
import { OrderKioskChoiceService } from '../../../services/order-kiosk-choice.service';
import { find } from 'lodash';

@Component({
  selector: 'pos-set-kiosk-button',
  templateUrl: './set-kiosk-button.component.html',
  styleUrls: ['./set-kiosk-button.component.scss']
})
export class SetKioskButtonComponent extends ModalComponent {
  @Input() kioskScreenButton: KioskScreenChoice;
  selectedScreenButtonId: number = null;
  kioskButtons: Array<KioskChoice> = [];
  imageRootPath = RuntimeConstants.IMAGE_BASE_PATH + "/order-kiosk/buttons/";
  icons = {
    plusWhite,
    editWhiteWithText
  }
  isReloadButtons = false;
  constructor(
    public modalRef: BsModalRef,
    private modalService: ModalService,
    private alertsService: AlertsService,
    private spinnerService: SpinnerService,
    private orderKioskChoiceService: OrderKioskChoiceService,
    private orderKioskScreenChoiceService: OrderKioskScreenChoiceService
  ) {
    super(modalRef);
    this.kioskScreenButton = orderKioskScreenChoiceService.newScreenChoice();
  }

  ngOnInit() {
    this.selectedScreenButtonId = this.kioskScreenButton.ButtonId;
    this.loadButtons();
  }

  loadButtons() {
    this.spinnerService.show();
    this.orderKioskChoiceService.getAll()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (buttons: Array<KioskChoice>) => {
          this.kioskButtons = buttons;
        }, error: this.alertsService.showApiError
      });
  }

  save(isValid: boolean) {
    if (!isValid) {
      return;
    }
    if (this.selectedScreenButtonId) {
      this.isReloadButtons = this.isReloadButtons || this.selectedScreenButtonId != this.kioskScreenButton.ButtonId;
      this.kioskScreenButton.ButtonId = this.selectedScreenButtonId;
      this.kioskScreenButton.Button = this.kioskButtons.find(x => x.Id == this.selectedScreenButtonId);
    }
    else {
      this.kioskScreenButton.Button = null;
      this.kioskScreenButton.ButtonId = null;
      this.kioskScreenButton.NextScreenId = null;
    }
    this.spinnerService.show();
    this.orderKioskScreenChoiceService.insertOrUpdate(this.kioskScreenButton)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.alertsService.renderSuccessMessage(Messages.KioskScreenChoiceSaveSuccess);
          this.hide({ isReloadButtons: this.isReloadButtons });
        }, error: this.alertsService.showApiError
      });
  }

  openKioskButtonModal(id: number) {
    const modalRef = this.modalService.show(KioskChoiceEditComponent, {
      backdrop: 'static',
      class: 'vertical-center modal-max-width-65',
      animated: false,
      keyboard: false,
      initialState: {
        id: id ?? 0,
        isPopup: true
      }
    });
    modalRef.close.subscribe((res) => {
      if (res?.button) {
        this.selectedScreenButtonId = res.buttonId;
        let buttonInArray = find(this.kioskButtons, x => x.Id == res.buttonId);
        if (!buttonInArray) {
          this.loadButtons();
        } else {
          buttonInArray.ButtonText = res.button.ButtonText;
          buttonInArray.Image = res.button.Image;
          this.isReloadButtons = true;
        }
      }
    });
  }

  cancel() {
    this.hide({ isReloadButtons: this.isReloadButtons });
  }

}
