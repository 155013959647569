import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { FilterConfiguration } from 'src/app/shared/interface';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';
import { SalesProductPrice } from '../interface';
import { SalesProductSizeHistory } from '../interface/sales-product-sizes-history';

@Injectable({
    providedIn: 'root'
})
export class SalesProductSizeService extends BaseCrudService<SalesProductSizeHistory> {

    constructor(httpService: HttpClient) {
        super('sales-product-sizes', httpService);
    }

    getNewSalesSize(): SalesProductSizeHistory {
        return {
            Id: 0,
            SalesProductId: null,
            SalesProduct: null,
            SizeId: null,
            Size: null,
            Price: null,
            DateEffective: new Date(Date.now()),
            DateExpired: new Date(Date.now()),
            DateAdded: new Date(Date.now()),
            DateEdited: new Date(Date.now()),
            IsDirectLinkToInventoryProduct: false,
            IsInStock: false,
        };
    }

    getNewSalesProductPrice(): SalesProductPrice {
        return {
            Id: 0,
            SalesProductId: null,
            SizeId: null,
            Price: null,
            DateEffective: new Date(),
            DateExpired: null,
            IsDirectLinkToInventoryProduct: false,
            IsInStock: true,
            SalesSizes: null
        };
    }

    getPriceHistories(productId: number, filterConfiguration: FilterConfiguration): Observable<Array<SalesProductSizeHistory>> {
        return this.httpService.post<Array<SalesProductSizeHistory>>(`${this.apiEndpoint}/all/${productId}`,
            filterConfiguration);
    }

    getTotalRecordsCount(productId: number): Observable<number> {
        return this.httpService.get<number>(`${this.apiEndpoint}/count/${productId}`);
    }

    getProductsForBulkUpdate(salesProductIds: Array<number>) {
        return this.httpService.post(`${this.apiEndpoint}/products`, salesProductIds);
    }

    saveProductsForBulkUpdate(salesProducts: Array<SalesProductPrice>) {
        return this.httpService.post(`${this.apiEndpoint}/update-products`, salesProducts);
    }

}
