import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { TimeClockEntry } from '../../interface';
import { AlertsService, SpinnerService, Messages, BaseCrudComponent } from 'src/app/shared';
import { NgForm } from '@angular/forms';
import { TimeClockEntriesService } from '../../service';
import { finalize } from 'rxjs/operators';
import { ApplicationStateService, FormUtilityService, UserService } from 'src/app/shared/services';
import { infoManageTimeClockEntriesClock, timeClockEntriesClock } from 'src/app/shared/components/icon';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'pos-time-clock-entries-edit',
  templateUrl: './time-clock-entries-edit.component.html',
  styleUrls: ['./time-clock-entries-edit.component.scss']
})
export class TimeClockEntriesEditComponent extends BaseCrudComponent<TimeClockEntry> implements OnInit {

  @Input() id = 0;
  @ViewChild('formTimeClockEdit', { static: true }) formTimeClockEdit: NgForm;
  timeClockEntryDetail: TimeClockEntry;
  users: Array<any> = [];
  dateFormat = 'mm-dd-yyyy';
  hourFormat = '12';

  public icons = {
    timeClockEntriesClock,
    infoManageTimeClockEntriesClock
  };
  get getForm(): NgForm {
    return this.formTimeClockEdit
  }

  constructor(private route: ActivatedRoute,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected timeClockService: TimeClockEntriesService,
    private router: Router,
    private applicationStateService: ApplicationStateService,
    private userService: UserService,
    formUtilityService: FormUtilityService) {
    super(timeClockService, alertService, spinnerService, formUtilityService);
    this.id = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
    this.scrollToTop();
  }

  ngOnInit() {
    this.timeClockEntryDetail = this.timeClockService.newTimeClockEntry();
    this.saveSuccessMessage = Messages.TimeClockEntrySaveSuccess;
    this.dataSubscription();
    this.dateFormat = this.applicationStateService.settingParam.PCalendarDateFormat;
    this.hourFormat = this.applicationStateService.settingParam.PCalendarHourFormat;
  }

  private dataSubscription(): void {
    if (this.id > 0) {
      this.spinnerService.show();
      this.loadData().pipe(finalize(() => {
        this.spinnerService.hide();
      }))
          .subscribe({
              next: (res) => {
                  this.timeClockEntryDetail = res;
                  this.timeClockEntryDetail.ClockIn = new Date(this.timeClockEntryDetail.ClockIn);
                  this.timeClockEntryDetail.ClockInTime = this.timeClockEntryDetail.ClockIn;
                  if (this.timeClockEntryDetail.ClockOut != null) {
                      this.timeClockEntryDetail.ClockOut = new Date(this.timeClockEntryDetail.ClockOut);
                      this.timeClockEntryDetail.ClockOutTime = this.timeClockEntryDetail.ClockOut;
                      this.timeClockEntryDetail.StillClockedIn = false;
                  } else {
                      const today = new Date();
                      this.timeClockEntryDetail.StillClockedIn = true;
                      this.timeClockEntryDetail.ClockOut = _.cloneDeep(this.timeClockEntryDetail.ClockIn);
                      this.timeClockEntryDetail.ClockOutTime = _.cloneDeep(this.timeClockEntryDetail.ClockInTime);
                      const defaultTime = this.timeClockEntryDetail.ClockOutTime.getHours();
                      this.timeClockEntryDetail.ClockOutTime.setHours(defaultTime + 1);
                      this.timeClockEntryDetail.ClockOut.setHours(defaultTime + 1);
                  }
              }, error: this.alertService.showApiError
          });
    }
  }

  public loadDependencies(): void {
    this.spinnerService.show();
    this.userService.getAllActiveUser()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      })).subscribe({
        next: resposne => {
          this.users = resposne;
        }, error: this.alertService.showApiError
      });
  }

  public submit(isMessageLoginValid: boolean): void {
    if (!isMessageLoginValid) {
      return;
    }

    if (this.timeClockEntryDetail.ClockIn != null) {
      const intime = this.timeClockEntryDetail.ClockInTime;
      this.timeClockEntryDetail.ClockIn.setHours(intime.getHours(), intime.getMinutes(), intime.getSeconds());
    }
    if (!this.timeClockEntryDetail.StillClockedIn) {
      if (this.timeClockEntryDetail.ClockOut != null) {
        const outtime = this.timeClockEntryDetail.ClockOutTime;
        this.timeClockEntryDetail.ClockOut.setHours(outtime.getHours(), outtime.getMinutes(), outtime.getSeconds());
      }
    } else {
      this.timeClockEntryDetail.ClockOut = null;
    }

    if (!this.timeClockEntryDetail.StillClockedIn) { // validate only if clockout time is specified.
      if (this.timeClockEntryDetail.ClockIn > this.timeClockEntryDetail.ClockOut) {
        this.alertService.renderErrorMessage(Messages.ErrorWhileClockInDateGreater);
        return;
      } else if (this.timeClockEntryDetail.ClockIn.getTime() === this.timeClockEntryDetail.ClockOut.getTime()) {
        if (this.timeClockEntryDetail.ClockInTime > this.timeClockEntryDetail.ClockOutTime) {
          this.alertService.renderErrorMessage(Messages.ErrorWhileClockInDateGreater);
          return;
        }
      }
    }

    if (this.timeClockEntryDetail.JobCodeId == null) {
      this.timeClockEntryDetail.JobCodeId = 1;
    }

    this.save(this.timeClockEntryDetail);
  }

  public onSaveSuccess(model: TimeClockEntry): void {
    this.router.navigate(['time-clock-entries'], { relativeTo: this.route.parent });
  }

  cancelEditing(): void {
    this.scrollToTop();
    this.router.navigate(['time-clock-entries'], { relativeTo: this.route.parent });
  }

  private scrollToTop(): void {
    window.scrollTo(0, 0);
  }
}
