import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { FontStyle } from 'src/app/shared/interface/font-style';
import { ConfirmationScreenWorkflowParameter } from '../interfaces/ConfirmationScreenWorkflowParameter';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationScreenWorkflowService {

  confirmationScreenWorkflowApiBasePath = `${RuntimeConstants.API_PATH}confirmationScreen-workflow-configuration`;
  constructor(private httpService: HttpClient) { }

  newConfirmationScreenWorkflowConfigurationParameter(): ConfirmationScreenWorkflowParameter {
    return {
      ConfirmationScreenDisplayImageOnAddProduct: false,
      ConfirmationScreenDisplayImageSeconds: 0,
      ConfirmationScreenDisplayImageTransitionSeconds: 0,
      ConfirmationScreenDisplayImageBackground: '',
      ConfirmationScreenDisplayImageHeaderStyle: '',
      ConfirmationScreenDisplayImageProductStyle: '',
    };
  }

  getConfirmationScreenWorkflowParameters(): Observable<ConfirmationScreenWorkflowParameter> {
    return this.httpService.get<ConfirmationScreenWorkflowParameter>(`${this.confirmationScreenWorkflowApiBasePath}`);
  }

  updateConfirmationScreenWorkflowParameters(confirmationScreenWorkflowParameter: ConfirmationScreenWorkflowParameter) {
    return this.httpService.post<ConfirmationScreenWorkflowParameter>(
      `${this.confirmationScreenWorkflowApiBasePath}`,
      confirmationScreenWorkflowParameter
    );
  }

  newFontStyle(): FontStyle {
    return {
        color: '',
        fontFamily: '',
        fontSize: '',
        fontStyle: '',
        fontWeight: '',
        textDecoration: '',
        textShadow: ''
    };
}
}
