<pos-edit-wrapper (close)="onCancel()" [isPopup]="false" [headingIcon]="icons.database" heading="Database">
  <form (ngSubmit)="saveDatabase(databaseForm.valid)" #databaseForm="ngForm" name="formDatabase" class="edit-form">
    <context-provider provide="props">
      <div class="col-12 pos-modal-body border-top pt-pix-5">
        <div class="col-12">
          <div>
            <h3 class="setting-title">Settings</h3>
          </div>
          <pos-row class="px-2">
            <pos-column>
              <pos-form-field label="Database Server"
                [validationMessages]="{required: 'Please enter database server.'}">
                <input posInput class="form-control" name="txtDBServer" [(ngModel)]="parameters.DBServer"
                  [required]="true" type="text" #txtDBServer="ngModel" />
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field label="Instance Name" [validationMessages]="{required: 'Please enter instance name.'}">
                <input posInput class="form-control" name="txtDBInstanceName" [(ngModel)]="parameters.DBInstanceName"
                  [required]="true" type="text" #txtDBInstanceName="ngModel" />
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field label="Database Name" [validationMessages]="{required: 'Please enter database name.'}">
                <input posInput class="form-control" name="txtDbName" [(ngModel)]="parameters.DBName" [required]="true"
                  type="text" #txtDbName="ngModel" />
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field label="User Name" [validationMessages]="{required: 'Please enter username.'}">
                <input posInput class="form-control" name="txtUsername" [(ngModel)]="parameters.DBUserName"
                  [required]="true" type="text" #txtUsername="ngModel" />
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field label="Password" [validationMessages]="{required: 'Please enter password.'}">
                <input posInput class="form-control" name="txtPassword" [(ngModel)]="parameters.DBPassword"
                  [required]="true" type="password" #txtPassword="ngModel" />
              </pos-form-field>
            </pos-column>
            <pos-column>
              <!-- Disabled: button is disable because code is not available currently  -->
              <button class="btn btn-primary normal mt-pix-10 cursor-no-drop pos-button" type="button" disabled="true">
                <pos-icon [icon]="icons.databasePlus" class="plus-icon"></pos-icon>
                New Database
              </button>
            </pos-column>
          </pos-row>
        </div>
        <div class="col-12 section-border">
          <div>
            <h3 class="setting-title">HIC History Size</h3>
          </div>
          <pos-row class="px-2">
            <pos-column>
              <pos-form-field label="HIC History Size" [fieldInfo]="fieldInfoMessages.DatabaseHICHistorySize"
                [validationMessages]="{required: 'Please enter HIC history size.'}">
                <input posInput class="form-control col-120" name="txtHicHistorySize"
                  [(ngModel)]="parameters.HicHistorySize" [required]="true" type="number"
                  #txtHicHistorySize="ngModel" />
              </pos-form-field>
            </pos-column>
          </pos-row>
        </div>
      </div>
      <div class="col-12 action-button-container page-footer">
        <button class="btn btn-primary btn-lg btn-save pos-button" scrollup type="submit" value="Save">Save</button>
        <button class="btn btn-secondary btn-lg btn-cancel pos-button" (click)="cancelEditing()" scrollup type="button"
          value="Cancel">Cancel</button>
      </div>
    </context-provider>
  </form>
</pos-edit-wrapper>
