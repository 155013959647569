import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ComponentsModule, createTranslateLoader, Levels, Permissions, TableConfiguration } from 'src/app/shared';
import { TableModule } from '@tarktech/tark-ng-utils';
import { EventManagementComponent, SelectAudioNotificationComponent } from './components';
import { AccordionModule } from 'primeng/accordion';
import { EventConfigurationListComponent } from './components/event-configuration-list/event-configuration-list.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EventConfigurationEditComponent } from './components/event-configuration-edit/event-configuration-edit.component';
import { SelectExternalApiComponent } from './components/select-external-api/select-external-api.component';
import { TableModule as PrimengTableModule } from 'primeng/table';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CheckboxModule } from 'primeng/checkbox';
import { EmailConfigurationComponent } from './components/email-configuration/email-configuration.component';
import { TerminalMessageConfigurationComponent } from './components/terminal-message-configuration/terminal-message-configuration.component';
import { DelayConfigurationComponent } from './components/delay-configuration/delay-configuration.component';
import { TaskConfigurationComponent } from './components/task-configuration/task-configuration.component';
import { TooltipModule } from 'primeng/tooltip';
import { Route, RouterModule } from '@angular/router';
import { SmsConfigurationComponent } from './components/sms-configuration/sms-configuration.component';
import { DjConfigurationComponent } from './components/dj-configuration/dj-configuration.component';
import { LightConfigurationComponent } from './components/light-configuration/light-configuration.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { PushNotificationConfigurationComponent } from './components/push-notification-configuration/push-notification-configuration.component';
import { CronEditorModule } from '@tarktech/ngx-cron-editor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { RepeatActionConfigurationComponent } from './components/repeat-action-configuration/repeat-action-configuration.component';
import { NgxContextModule } from '@tarktech/ngx-context';

const routes: Array<Route> = [
  {
    path: '',
    component: EventManagementComponent,
    data: {
      permissions: [{ 'Name': Permissions.ManageEventManager, 'Level': Levels.Access },
      { 'Name': Permissions.ManageConsole, 'Level': Levels.Access }]
    }
  },
  {
    path: 'scheduled',
    component: EventManagementComponent,
    data: {
      isScheduled: true,
      permissions: [{ 'Name': Permissions.ManageEventManager, 'Level': Levels.Access },
      { 'Name': Permissions.ManageConsole, 'Level': Levels.Access }]
    }
  }
];

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    ModalModule.forRoot(),
    PrimengTableModule,
    ComponentsModule,
    TableModule.forRoot(TableConfiguration),
    AccordionModule,
    DragDropModule,
    NgxSliderModule,
    CheckboxModule,
    TooltipModule,
    ColorPickerModule,
    RouterModule.forChild(routes),
    CronEditorModule,
    TranslateModule.forChild({
      isolate:true,
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    NgxContextModule
  ],
  declarations: [EventManagementComponent, EventConfigurationListComponent, EventConfigurationEditComponent,
    SelectExternalApiComponent, SelectAudioNotificationComponent, EmailConfigurationComponent, TerminalMessageConfigurationComponent,
    DelayConfigurationComponent, TaskConfigurationComponent, SmsConfigurationComponent,
    DjConfigurationComponent, LightConfigurationComponent, PushNotificationConfigurationComponent, RepeatActionConfigurationComponent]
})
export class EventModule { }
