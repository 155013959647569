import { Directive, OnInit, HostListener } from '@angular/core';

declare let $: any;

@Directive({
    selector: '[scrollup]'
})
export class ScrollupDirective implements OnInit {
    @HostListener('click', ['$event'])
    handleClickEvent(event: KeyboardEvent) {
        $('html, body').animate({ scrollTop: 0 }, 'medium');
    }
    ngOnInit() {
    }


}
