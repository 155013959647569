import { Component, EventEmitter, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { finalize } from 'rxjs/operators';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { EmailTemplate } from '../../interface';
import { EmailTemplateService } from '../../service';

@Component({
  selector: 'pos-email-templates-preview',
  templateUrl: './email-templates-preview.component.html',
  encapsulation: ViewEncapsulation.None
})
export class EmailTemplatesPreviewComponent implements OnInit {

  id :number = 0;
  @Input() emailContent : string;
  sanitizedContent: SafeHtml;
  close: EventEmitter<any> = new EventEmitter();

  constructor(private sanitizer: DomSanitizer,
    private spinnerService: SpinnerService,
    private emailTemplateService: EmailTemplateService,
    private alertSerive: AlertsService) { }

  ngOnInit(): void {
    if (!this.emailContent) {
      this.loadData();
    } else {
      this.sanitizeHTMLContent();
    }
  }

  loadData() {
    this.spinnerService.show();
    this.emailTemplateService.getEmailTemplatePreview(this.id).pipe(finalize(() => {
      this.spinnerService.hide()
    })).subscribe({ next: (response) => {
      this.emailContent = response.Value;
      this.sanitizeHTMLContent();
    }, error: this.alertSerive.showApiError})
  }

  sanitizeHTMLContent() {
    this.sanitizedContent = this.sanitizer.bypassSecurityTrustHtml(this.emailContent);

  }

  closeModal() {
    this.close.emit({ shouldReload: false });
  }

}
