import { Route } from '@angular/router';
import { ButtonWithInactiveProductsComponent, UnlinkedScreenComponent } from './Components';
import { Levels, Permissions } from '../shared/constants';
import { UnusedImagesComponent } from './Components/unused-images/unused-images.component';

export const MENU_SETUP_ROUTES: Array<Route> = [
    {
        path: 'inactive-products',
        component: ButtonWithInactiveProductsComponent,
        data: {
            permissions: [{ 'Name': Permissions.ManageInactiveButtons, 'Level': Levels.Access }, { 'Name': Permissions.ManageConsole, 'Level': Levels.Access }]
        }
    },
    {
        path: 'unlinked-screen',
        component: UnlinkedScreenComponent,
        data: {
            permissions: [{ 'Name': Permissions.ManageUnlinkedScreens, 'Level': Levels.Access }, { 'Name': Permissions.ManageConsole, 'Level': Levels.Access }]
        }
    },
    {
        path: 'unused-images',
        component: UnusedImagesComponent,
        data: {
            permissions: [{ 'Name': Permissions.ManageUnusedImages, 'Level': Levels.Access }, { 'Name': Permissions.ManageConsole, 'Level': Levels.Access }]
        }
    },
];
