import { Injectable } from '@angular/core';
import { BaseCrudService, SettingParam, RuntimeConstants } from 'src/app/shared';
import { BaselineExpense } from '../interface/baseline-expense';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BaselineExpenseService extends BaseCrudService<BaselineExpense> {

  baselineExpenseApiBasePath = `${RuntimeConstants.API_PATH}baselineExpenses`;

  constructor(private httpClient: HttpClient) {
    super('baselineExpenses', httpClient);
  }

  newBaselineExpense() {
    const baselineExpense: BaselineExpense = {
      Id: 0,
      Expense: '',
      Amount: null
    };
    return baselineExpense;
  }

  getBaselineExpenses(terminalId: number): Observable<Array<BaselineExpense>> {
    return this.httpClient.get<Array<BaselineExpense>>(`${this.baselineExpenseApiBasePath}/${terminalId}`);
  }

  saveBaselineExpensesDetail(params: Array<BaselineExpense>): Observable<SettingParam> {
    return this.httpClient.post<SettingParam>(`${this.baselineExpenseApiBasePath}`, params);
  }
}
