<pos-edit-wrapper (close)="cancelEditing()" [isPopup]="false" [headingIcon]="icons.inventoryScheduleEditor"
  heading="Inventory Schedule Editor">
  <form #inventoryScheduleForm="ngForm" (submit)="applyDates(inventoryScheduleForm.valid)" class="edit-form"
    autocomplete="off">
    <context-provider provide="props">
      <div class="col-12 row g-0 border-top pt-pix-20 pos-page-body">
        <div class="col-lg-8 col-md-9 col-sm-7 col-12">
          <div class="btn-group flex-wrap">
            <label class="btn btn-radio"
              [ngClass]="selectedFunction == inventoryScheduleOption.ByDate ?'active btn-primary font-nobold':'btn-secondary'">
              <input type="radio" name="selectedFunction" class="form-check-input" (change)="changeFilter()"
                [(ngModel)]="selectedFunction" value="Date"> By Date</label>
            <label class="btn btn-radio"
              [ngClass]="selectedFunction == inventoryScheduleOption.BySubcategory ? 'active btn-primary font-nobold': 'btn-secondary'">
              <input type="radio" name="selectedFunction" class="form-check-input" (change)="changeFilter()"
                [(ngModel)]="selectedFunction" value="Subcategory"> By Inventory Subcategory</label>
            <label class="btn btn-radio"
              [ngClass]="selectedFunction == inventoryScheduleOption.ByVendor ?'active btn-primary font-nobold': 'btn-secondary'">
              <input type="radio" name="selectedFunction" class="form-check-input" [(ngModel)]="selectedFunction"
                (change)="changeFilter()" value="Vendor"> By Vendor</label>
            <label class="btn btn-radio"
              [ngClass]="selectedFunction == inventoryScheduleOption.ByZone ? 'active btn-primary font-nobold': 'btn-secondary'">
              <input type="radio" name="selectedFunction" class="form-check-input" [(ngModel)]="selectedFunction"
                (change)="changeFilter()" value="Zone"> By Inventory Zone</label>
          </div>
        </div>
        <div class="col-lg-4 col-md-5 col-sm-6 col-12 pt-custom-pix-sm-0">
          <pos-row class="float-end">
            <pos-column>
              <pos-form-field *ngIf="selectedFunction == inventoryScheduleOption.ByDate" [appendPaddingTop]="false">
                <p-calendar posInput #logStartDate="ngModel" [readonlyInput]="true" [required]="true" appendTo="body"
                  [dateFormat]="dateFormat" [inputStyle]="{'width':'unset','display':'unset'}"
                  inputStyleClass="form-control" [monthNavigator]=true [showIcon]="true" name="startDate"
                  (onSelect)="getInventoryProduct()" [(ngModel)]="startDate"></p-calendar>
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field *ngIf="selectedFunction == inventoryScheduleOption.BySubcategory"
                [appendPaddingTop]="false">
                <ng-select posInput #subcategory name="subcategory" [items]="subcategories"
                  bindLabel="InventoryCategoryName" (change)="getInventoryProduct()" bindValue="id"
                  [(ngModel)]="subcategoryId" placeholder="Select subcategory">
                </ng-select>
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field *ngIf="selectedFunction == inventoryScheduleOption.ByVendor" [appendPaddingTop]="false">
                <ng-select posInput #subcategory name="subcategory" [items]="vendors" bindLabel="name"
                  (change)="getInventoryProduct()" bindValue="id" [(ngModel)]="vendorId" placeholder="Select vendor">
                </ng-select>
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field *ngIf="selectedFunction == inventoryScheduleOption.ByZone" [appendPaddingTop]="false">
                <ng-select posInput #subcategory name="subcategory" [items]="zones" bindLabel="NameWithCode"
                  (change)="getInventoryProduct()" bindValue="Id" [(ngModel)]="zoneId" placeholder="Select zone">
                </ng-select>
              </pos-form-field>
            </pos-column>
          </pos-row>
        </div>
        <div class="col-12 pt-pix-10" class="inventory-schedule-editor">
          <ng-table #inventoryScheduleGrid [scrollable]="true" [scrollHeight]="'calc(100vh - 450px)'"
            (onFilter)="scheduleGridFilter($event)" [rows]="inventoryProducts?.length"
            [columns]="inventoryScheduleColumns" [data]="inventoryProducts">
          </ng-table>
        </div>
        <div class="col-12">
          <pos-row>
            <pos-column>
              <pos-form-field label="Set next inventory date to:"
                [validationMessages]="{required: 'Please enter next inventory date.'}">
                <p-calendar posInput #inventoryLogDate="ngModel" [readonlyInput]="true" [minDate]="minDate"
                  [dateFormat]="dateFormat"
                  [ngClass]="{'has-error': (inventoryLogDate.invalid && inventoryScheduleForm.submitted)}"
                  [required]="true" [inputStyle]="{'width':'unset','display':'unset'}" inputStyleClass="form-control"
                  [monthNavigator]=true [showIcon]="true" name="inventoryLogDate" [(ngModel)]="nextInventoryLogDate">
                </p-calendar>
              </pos-form-field>
            </pos-column>
          </pos-row>
        </div>
      </div>
      <div class="col-12 action-button-container page-footer">
        <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" scrollup>Assign Dates </button>
        <button type="button" class="btn-secondary  btn-lg btn btn-save black pos-button" scrollup (click)="onClear()">Clear</button>
        <button type="button" class="btn-secondary btn-lg btn btn-cancel pos-button" scrollup routerLink="../">Cancel</button>
      </div>
    </context-provider>
  </form>
</pos-edit-wrapper>
<ng-template let-data="rowData" #chkTemplate>
  <span>
    <p-checkbox name="update" #update="ngModel" [(ngModel)]="data.Update" binary="true"></p-checkbox>
  </span>
</ng-template>
<ng-template #headerTemplate>
  <span class="me-pix-5" style="display: flex;flex-direction: row-reverse;justify-content: left;flex-wrap: wrap-reverse;">
    <p-checkbox name="checkAll" #checkAll="ngModel" [(ngModel)]="isCheckedAll" binary="true" class="ps-pix-5"
      (onChange)="selectAll(isCheckedAll)" inputId="headerCheckbox"/>
    <label for="headerCheckbox"><b>Update</b></label>
  </span>
</ng-template>
