import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { Observable } from 'rxjs';
import { MakeTableOrderModel } from 'src/app/make-table';

@Injectable({
  providedIn: 'root'
})
export class ServeScreenService {
  constructor(private httpService: HttpClient) { }
  getServeScreenOrders(): Observable<Array<MakeTableOrderModel>> {
    return this.httpService.get<Array<MakeTableOrderModel>>(
      `${RuntimeConstants.API_PATH}servescreen/`
    );
  }

  getServeScreenOrder(orderId): Observable<MakeTableOrderModel> {
    return this.httpService.get<MakeTableOrderModel>(
      `${RuntimeConstants.API_PATH}servescreen/${orderId}`
    );
  }

  updateProductState(terminalId, orderId, productStatusUpdateModel): Observable<any> {
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}make-table/${terminalId}/order/${orderId}/update-product-state`, productStatusUpdateModel
    );
  }
}
