import { Component, OnInit, ViewChild } from '@angular/core';
import { GridColumn, TemplateColumn } from '@tarktech/tark-ng-utils';
import { WorkScheduleTag } from '../../interface';
import { BaseListComponent } from 'src/app/shared/components/base-list.component';
import { WorkScheduleTagService } from 'src/app/shared/services/work-schedule-tag.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { Messages } from '../../../../shared/constants/';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import { ApplicationStateService } from 'src/app/shared';
import { ActivatedRoute, Router } from '@angular/router';
declare let $: any;
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { WorkScheduleTagEditComponent } from '../work-schedule-tag-edit';

@Component({
    selector: 'pos-work-schedule-tag-list',
    templateUrl: './work-schedule-tag-list.component.html',
    styleUrls: ['./work-schedule-tag-list.component.scss']
})
export class WorkScheduleTagListComponent extends BaseListComponent<WorkScheduleTag> implements OnInit {

    workScheduleTagColumns: Array<GridColumn> = [];
    tags: WorkScheduleTag[] = [];
    tagId: number;
    @ViewChild('colorTemplate', { static: true }) private colorTemplate: any;
    screenHeight = '300px';
    modalRef: BsModalRef;
    constructor(
        protected workScheduleTagService: WorkScheduleTagService,
        protected alertService: AlertsService,
        protected spinnerService: SpinnerService,
        protected modalService: ModalService,
        private router: Router,
        protected route: ActivatedRoute,
        // private modalBackdropService: ModalBackdropService,
        protected applicationStateService: ApplicationStateService,
        protected printService: PrintTableService
    ) {
        super(workScheduleTagService, alertService, spinnerService, modalService, applicationStateService, printService, route);
    }

    ngOnInit() {
        this.screenHeight = ($(window).height() - 360) < 150 ? '105px' : ($(window).height() - 355) + 'px';
        this.deleteSuccessMessage = Messages.TagDeletedSuccess;
        this.workScheduleTagColumns = this.configureGridColumns();
        this.loadSubscriptions();
    }

    public getGridColumns(): Array<GridColumn> {
        const iconColumn = new TemplateColumn({
            headerTemplate: null,
            itemTemplate: this.colorTemplate,
            Width: '16px',
            CellClass: 'p-t-0'
        });
        return [
            iconColumn,
            new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '91%' }),
        ] as Array<GridColumn>;
    }

    private loadSubscriptions(): void {
        this.data$.subscribe({
            next: (res) => {
                this.tags = res;
            }
        });
    }

    public editItem(id: number, data?: WorkScheduleTag): void {
        this.tagId = id;
        const modalRef = this.modalService.getModalWrapper(WorkScheduleTagEditComponent);

        const modal = modalRef.show({
            'backdrop': 'static',
            'class': 'vertical-center',
            keyboard: false,
            initialState: {
                id: this.tagId
            }
        });
        modal.close.subscribe({
            next: (res) => {
                if (res && res.reload) {
                    this.reload();
                }
            }
        });
    }

    private reset() {
        this.tagId = 0;
    }

    public getConfirmDeleteMessage(data: WorkScheduleTag): string {
        return StringUtils.format(Messages.ConfirmDeleteWorkScheduleTag,
            { 'tag': data ? data.Name : '' }
        );
    }

    public onCancel() {
        this.router.navigate(['work-schedule'], { relativeTo: this.route.parent });
    }

}
