<pos-banner *ngIf="headerWidgetConfig" [data]="headerWidgetConfig"
    [styleData]="imageWidgetStyle?.WidgetHeaderStyleConfig" [borderStyle]="imageWidgetStyle?.BorderStyle"
    [imagePath]="bannerImagePath"></pos-banner>
<div class="vertical-center-align flex-grow" [ngStyle]="contentStyle">
    <div class="p-pix-10 display-flex" class="menu-list"
        [ngStyle]="{'align-self': imageWidgetStyle?.Vertical  === 'top' ? 'start' : (imageWidgetStyle?.Vertical === 'bottom' ? 'end' : 'center')}"
        *ngIf="menuListItems">
        <div *ngFor="let item of menuListItems" [ngStyle]="imageWidgetStyle?.ListItemStyle">
            <div class="p-pix-5" [hidden]="(!item?.IsInStock && imageWidgetConfig?.OutOfStockBehavior?.BehaviorType === outOfStockBehaviorTypes.Hide)" >
                <pos-menu-image [image]="item?.Image" [height]="imageWidgetStyle?.Height"
                    [width]="imageWidgetStyle?.Width" [text]="item?.Text" [imagePath]="imagePath"
                    [textStyle]="imageFontStyle" [showImageText]="imageWidgetConfig?.IsShowImageText"
                    [textPosition]="imageWidgetStyle?.ImageTextPosition" [interactiveStyleClass]="item?.InteractiveStyleClass"
                    [outOfStock]="(!item?.IsInStock ? imageWidgetConfig?.OutOfStockBehavior : null)">
                </pos-menu-image>
            </div>
        </div>
    </div>
</div>
