<div class="width-80 m-0-auto">
    <div class="page-content">
        <div class="tab-content">
            <div class="tab-pane fade in active show">
                <div class="col-12 px-3">
                    <div class="row">
                        <div class="col-lg-10 col-sm-9 col-7 display-flex align-items-center">
                            <pos-icon [icon]="icons.menuSetupUnusedImage"
                                class="pe-pix-5 vertical-center-align page-content__header-icon"></pos-icon>
                            <h3 class="page-content__header pi-0">
                                Unused Images
                            </h3>
                        </div>
                        <div class="col-lg-2 col-sm-3 col-5 text-align-right p-0">
                            <button class="btn btn-secondary pos-button" (click)="reloadUnusedImages()">
                                <pos-icon [icon]="icons.sync"></pos-icon> Refresh
                            </button>
                            <span (click)="cancel()" class="pointer ps-pix-20 pe-pix-8 font-20 grey vertical-mid">
                                <pos-icon [icon]="icons.times"></pos-icon>
                            </span>
                        </div>
                        <div class="col-lg-2 col-sm-4 col-6 bulk-delete-all">
                            <button class="btn btn-danger normal pos-button" [disabled]="!unusedImagesCount"
                                (click)="deleteBulkImages()">
                                Delete selected <span class="count-circle">{{unusedImagesCount}}</span>
                            </button>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-12">
                            <ng-table #gridComponent [scrollable]="true" [scrollHeight]="screenHeight.toString()"
                                [paginator]="false" [columns]="unusedImagesColumns" [data]="unusedImages"
                                (onFilter)="onTableFiltered($event)">
                            </ng-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<pos-confirm-delete [message]="message" (onConfirmAcknowledge)="onConfirmAcknowledge()" #confirmImageDelete>
</pos-confirm-delete>

<pos-confirm-delete [message]="message" (onConfirmAcknowledge)="onConfirmBulkDeleteAcknowledge()" #confirmBulkDelete>
</pos-confirm-delete>


<ng-template let-data="rowData" let-rowIndex="rowIndex" #UnusedImageTemplate>
    <img src="{{imagePath}}/buttons/{{data.Name}}" alt="" class="image" height="50" />
</ng-template>
<ng-template let-data="rowData" #bulkDeleteHeaderTemplate>
    <p-checkbox name="bulkDeleteAll" (onChange)="addAllImagesForBulkDelete()" [(ngModel)]="isBulkDeleteAll"
        binary="true">
    </p-checkbox>
</ng-template>
<ng-template let-data="rowData" #bulkDeleteCheckboxTemplate>
    <p-checkbox name="bulkDelete" #bulkDelete="ngModel" (onChange)="addImageForBulkDelete(data)"
        [(ngModel)]="data.IsAddToBulkDelete" binary="true">
    </p-checkbox>
</ng-template>
