import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { Observable } from 'rxjs';
import { IpFailedLoginAttempt } from '../interface/ip-failed-login-attempt';

@Injectable({
    providedIn: 'root'
})
export class IpFailedLoginAttemptService {

    constructor(private httpService: HttpClient) { }

    getIpFailedLoginAttempt(): Observable<IpFailedLoginAttempt[]> {
        return this.httpService.get<IpFailedLoginAttempt[]>(
            `${RuntimeConstants.API_PATH}IpFailedLoginAttempts`
        );
    }
    updateIpFailedLoginAttempt(ipFailedLoginAttempt: IpFailedLoginAttempt): Observable<IpFailedLoginAttempt> {
        return this.httpService.put<IpFailedLoginAttempt>(
            `${RuntimeConstants.API_PATH}IpFailedLoginAttempts/update`, ipFailedLoginAttempt
        );
    }
}
