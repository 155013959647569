import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DateColumn, GridColumn, TableComponent, TextAlign } from '@tarktech/tark-ng-utils';
import { CurrencyColumn } from '@tarktech/tark-ng-utils/table/currency-column';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { InventorySubcategory } from 'src/app/information-management/inventory-subcategories/interface/inventory-subcategories';
import { InventorySubCategoryService } from 'src/app/information-management/inventory-subcategories/service/inventory-subcategory.service';
import { Unit } from 'src/app/information-management/units/interface/unit';
import { UnitService } from 'src/app/information-management/units/services/unit.service';
import { Vendor } from 'src/app/information-management/vendors/interface/vendor';
import { VendorService } from 'src/app/information-management/vendors/service/vendor.service';
import { BaseListComponent } from 'src/app/shared/components/base-list.component';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { FilterConfiguration } from 'src/app/shared/interface/filter-configuration';
import { InvProduct } from 'src/app/shared/interface/inventory-product';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { InventoryProductService } from 'src/app/shared/services/inventory-product.service';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import { TableConfiguration } from 'src/app/shared/table/table-configuration';
import { InventoryProductHistory } from '../../interface/inventory-product-history';
import { InventoryProductHistoryService } from '../../services/inventory-product-history.service';
import { history } from 'src/app/shared/components/icon';
import { GridContextInfo } from 'src/app/shared';

@Component({
  selector: 'pos-inventory-product-history-list',
  templateUrl: './inventory-product-history-list.component.html',
  styleUrls: ['./inventory-product-history-list.component.scss']
})
export class InventoryProductHistoryListComponent extends BaseListComponent<InventoryProductHistory> implements OnInit {

  inventoryProductHistory: Array<InventoryProductHistory> = [];
  vendors: Array<Vendor> = [];
  subCategory: Array<InventorySubcategory> = [];
  productHistoryColumns: Array<GridColumn> = [];
  units: Array<Unit> = [];
  id: number;
  inventoryProductDetails: InvProduct;

  totalRecords = 0;
  filterConfiguration: FilterConfiguration = {
    PageNumber: 1, PageSize: TableConfiguration.pageSize,
    SortField: 'DateAdded', SortOrder: -1, FilterValue: null
  };
  isCalledFromOnInit = true;
  @ViewChild('inventoryProductHistoryTable', { static: true }) inventoryProductHistoryTable: TableComponent;
  icons = {
    history
  }

  inventoryProductsGridContext: GridContextInfo = null;
  inventoryProductCategory: number = null;
  inventoryProductVendor: number = null;
  inventoryProductZone: number = null;
  inventoryProductActiveFilter: boolean = true;

  constructor(protected inventoryProductHistoryService: InventoryProductHistoryService,
    private vendorService: VendorService,
    private inventorySubCategoryService: InventorySubCategoryService,
    private inventoryProductService: InventoryProductService,
    private unitService: UnitService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected applicationStateService: ApplicationStateService,
    protected route: ActivatedRoute,
    private router: Router,
    protected printService: PrintTableService) {
    super(inventoryProductHistoryService, alertService, spinnerService, modalService, applicationStateService, printService, route);
    this.id = parseInt(route.snapshot.params.inventoryProductId, 10) ?? 0;
    const navigation = router.getCurrentNavigation();
    this.inventoryProductsGridContext = navigation?.extras?.state?.GridContext ?? null;
    this.inventoryProductCategory = navigation?.extras?.state?.Category ?? null;
    this.inventoryProductVendor = navigation?.extras?.state?.Vendor ?? null;
    this.inventoryProductZone = navigation?.extras?.state?.Zone ?? null;
    this.inventoryProductActiveFilter = navigation?.extras?.state?.Active ?? null;
  }

  ngOnInit(): void {
    this.deleteSuccessMessage = Messages.InventoryProductHistoryDeleted;
    this.isPrintIconVisible = false;
    this.productHistoryColumns = this.configureGridColumns();
    this.inventoryProductHistoryTable.context = this.gridContext;
  }

  reload() {
    if (this.id) {
      this.spinnerService.show();
      const observable: Array<Observable<any>> = [];
      observable.push(this.vendorService.getVendorList());
      observable.push(this.inventorySubCategoryService.getInventorySubcategoryList());
      observable.push(this.unitService.getUnits());
      observable.push(this.inventoryProductService.getInventoryProduct(this.id));
      observable.push(this.inventoryProductHistoryService.getTotalRecordsCount(this.id));
      forkJoin(observable).pipe(finalize(() => {
        this.spinnerService.hide();
      })).subscribe({
        next: (response: [Array<Vendor>, Array<InventorySubcategory>, Array<Unit>, InvProduct, number]) => {
          this.vendors = response[0];
          this.subCategory = response[1];
          this.units = response[2];
          this.inventoryProductDetails = response[3];
          this.totalRecords = response[4];
          if (!this.isCalledFromOnInit) {
            this.getProductHistories();
          }
          this.isCalledFromOnInit = false;
        }
      });
    }
  }

  editItem(id: number) {
    this.router.navigate([id], { relativeTo: this.route, state: { GridContext: this.inventoryProductsGridContext, Category: this.inventoryProductCategory, Vendor: this.inventoryProductVendor, Zone: this.inventoryProductZone, Active: this.inventoryProductActiveFilter } });
  }

  getConfirmDeleteMessage() {
    return Messages.InventoryProductHistoryDeleteConfirm;
  }

  getGridColumns() {
    return [
      new GridColumn({ Field: 'Name', HeaderText: 'Name', Width: '17%', IsSortable: true }),
      new CurrencyColumn({ Field: 'Price', HeaderText: 'Price', Width: '8%', IsSortable: true }),
      new GridColumn({ Field: 'UnitQty', HeaderText: 'Unit Qty', Width: '8%', IsSortable: true }),
      new GridColumn({ Field: 'UnitName', HeaderText: 'Unit', Width: '7%' }),
      new GridColumn({ Field: 'UnitDetails', HeaderText: 'Unit Details', Width: '12%', IsSortable: true }),
      new GridColumn({ Field: 'MinLevel', HeaderText: 'Min', Width: '5%', IsSortable: true }),
      new GridColumn({ Field: 'MaxLevel', HeaderText: 'Max', Width: '5%', IsSortable: true }),
      new GridColumn({ Field: 'Vendor', HeaderText: 'Vendor', Width: '8%' }),
      new GridColumn({ Field: 'VendorSku', HeaderText: 'Vendor SKU', Width: '9%', IsSortable: true }),
      new DateColumn({ Field: 'DateAdded', HeaderText: 'Created On', Width: '13%', TextAlign: TextAlign.Left, IsSortable: true }),
    ];
  }

  lazyLoad(event) {
    this.filterConfiguration.PageSize = event.rows;
    this.filterConfiguration.PageNumber = Math.ceil((event.first + 1) / event.rows);
    this.filterConfiguration.SortField = event.sortField;
    this.filterConfiguration.SortOrder = event.sortOrder;
    this.filterConfiguration.FilterValue = event.globalFilter;
    this.getProductHistories();
  }

  getProductHistories() {
    this.spinnerService.show();
    this.inventoryProductHistoryService.getProductHistories(this.id, this.filterConfiguration)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.inventoryProductHistory = res;
          this.inventoryProductHistory.map((data) => {
            data.Vendor = this.vendors.find(x => x.id === data.InventoryVendorId)?.name;
            data.SubCategory = this.subCategory.find(x => x.id === data.InventorySubcategoryId)?.InventoryCategoryName;
            data.UnitName = this.units.find(x => x.Id === data.InventoryUnitId)?.Name;
          });
        }, error: this.alertService.showApiError
      });
  }

  onCancel() {
    this.router.navigate(['manage/app-configuration/inventory-products'], { state: { GridContext: this.inventoryProductsGridContext, Category: this.inventoryProductCategory, Vendor: this.inventoryProductVendor, Zone: this.inventoryProductZone, Active: this.inventoryProductActiveFilter } });
  }

}
