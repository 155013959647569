import { IHaveId } from '../models';
import { RuntimeConstants } from '../constants';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export class BaseSearchService<TModel extends IHaveId> {
    protected apiEndpoint: string;

    constructor(protected baseUrl: string, protected httpService: HttpClient) {
        this.apiEndpoint = `${RuntimeConstants.API_PATH}${this.baseUrl}`;
    }

    getAll(): Observable<Array<TModel>> {
        return this.httpService.get<Array<TModel>>(this.apiEndpoint);
    }

    get(id: number): Observable<TModel> {
        return this.httpService.get<TModel>(
            `${this.apiEndpoint}/${id}`
        );
    }
}
