<div *ngIf="isPopup">
  <button class="close" (click)="onCancel()">&times;</button>
  <h4 class="modal-content__heading">Sales Report: {{productName}}
  </h4>
</div>

<div [ngClass]="{'pos-modal-body pt-0 quick-report': isPopup}">
  <div class="pt-pix-15">
    <div class="col-12 p-0 " [ngClass]="isPopup ? 'col-12': 'col-lg-7 col-md-9 col-12'">
      <div class="table-responsive overflow-auto">
        <ng-table id="salesProductQuickReport" [sort]="false" [search]="false" [paginator]="false"
          [columns]="reportColumns" [data]="reportData" [scrollable]="true" [scrollHeight]="'calc(100vh - 300px)'">
        </ng-table>
        <ng-template let-data="rowData" #priceTemplate>
          <span>
            {{data.Price | tark_currency}}
          </span>
        </ng-template>
        <ng-template let-data="rowData" #taxTemplate>
          <span>
            {{data.Tax | tark_currency}}
          </span>
        </ng-template>
        <ng-template let-data="rowData" #totalTemplate>
          <span>
            {{data.Total | tark_currency}}
          </span>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isPopup" class="col-12 action-button-container popup-footer mti-15 pb-pix-10">
  <button type="button" data-dismiss="modal" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" value="Close"
    (click)="onCancel()">Close</button>
</div>
