import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DjComponent } from './dj/dj.component';
import { ComponentsModule } from '../shared/components';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HeaderEntryModule } from '../header-entry/header-entry.module';
import { DJ_STATES } from './dj-routes';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SoundPlayerComponent } from './dj/sound-player/sound-player.component';
import { TableConfiguration } from '../shared/table/table-configuration';
import { TableModule } from '@tarktech/tark-ng-utils';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ModalModule.forRoot(),
    ComponentsModule,
    RouterModule.forChild(DJ_STATES),
    HeaderEntryModule,
    NgxSliderModule,
    TableModule.forRoot(TableConfiguration),
    NgbDropdownModule
  ],
  declarations: [DjComponent, SoundPlayerComponent]
})
export class DjModule { }
