<pos-edit-wrapper heading="{{salesUnit.Id ? 'Edit' : 'Add'}} Sales Unit" (close)="cancelEditing()">
  <form #salesUnitForm="ngForm" (ngSubmit)='saveSalesUnit(salesUnitForm.valid)'>
    <context-provider provide="props">
      <div class="overflow-hidden col-12 pos-modal-body">
        <pos-row class="g-0">
          <pos-column>
            <pos-form-field label="Unit Name" [validationMessages]="{required: 'Please enter unit name.'}">
              <input posInput name="unitName" #unitName="ngModel" type="text" class="form-control"
                [(ngModel)]="salesUnit.Name" [required]="true" />
            </pos-form-field>
          </pos-column>
        </pos-row>
        <pos-row>
          <pos-column>
            <pos-form-field label="Abbreviation Name">
              <input posInput name="abbreviationName" #abbreviationName="ngModel" type="text" class="form-control"
                [(ngModel)]="salesUnit.AbbreviationName" />
            </pos-form-field>
          </pos-column>
        </pos-row>
        <pos-row>
          <pos-column>
            <pos-form-field>
              <p-checkbox posInput name="isShowInOnlineOrdering" #featured="ngModel"
                [(ngModel)]="salesUnit.IsShowInOnlineOrdering" binary="true" label="Show in online ordering">
              </p-checkbox>
            </pos-form-field>
          </pos-column>
        </pos-row>
      </div>
      <div class="col-12 action-button-container popup-footer">
        <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" value="Save">Save</button>
        <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" value="Cancel"
          (click)="cancelEditing()">Cancel</button>
      </div>
    </context-provider>
  </form>
</pos-edit-wrapper>
