import { Injectable } from '@angular/core';
import { AdfModel, AdfModelRow } from '../interfaces';
import { forEach } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AdfStructurePreviewService {

  adjustRowHeight = (container) => {
    if (container && container.value && container.value.rows && container.value.rows.length > 0) {
      const height = 100 / container.value.rows.length;
      forEach(container.value.rows, (row: AdfModelRow) => {
        row.style = {
          height: height + '%'
        };

        if (row.columns) {
          forEach(row.columns, (column: AdfModel) => {
            this.adjustRowHeight(column);
          });
        }
      });
    }
  }
}
