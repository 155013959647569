import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { GridColumn } from '@tarktech/tark-ng-utils';
import { forEach } from 'lodash';
import { Subscription } from 'rxjs';
import { AudioRecording } from 'src/app/manage-console/audio-recordings';
import { SpinnerService } from 'src/app/shared/components';
import { downloadWhite, playGreen } from 'src/app/shared/components/icon';
import { AudioRecordingsForOrderPayload } from 'src/app/shared/interface';
import { AlertsService, ApplicationStateService, AudioOperationsService, RabbitMQMessage, RabbitMQService } from 'src/app/shared/services';
import { RuntimeConstants } from 'src/app/shared/constants';
import { AudioRecorderService, Messages } from 'src/app/shared';

@Component({
  selector: 'pos-order-recordings',
  templateUrl: './order-recordings.component.html',
  styleUrls: ['./order-recordings.component.scss']
})
export class OrderRecordingsComponent implements OnInit, OnDestroy {

  @Input('orderId') orderId: number;
  audioRecordingColumns: Array<GridColumn> = [];
  tagName: string;
  isDownloadFile = false;
  recordings: Array<AudioRecording> = [];
  icons = {
    playGreen, downloadWhite
  };
  @ViewChild('audioRecordingsOperationTemplate', { static: true }) audioRecordingsOperationTemplate: TemplateRef<any>;
  terminalAudioRecordingsSubscription: Subscription;
  terminalAudioRecordFileSubscription: Subscription;
  rabbitMQRequestId: string;
  rabbitMQRequestIdForOrder: string;
  loaderTimeout: ReturnType<typeof setTimeout>;
  showLoader = false;
  recordersCount = 0;
  audioFilePath = `${RuntimeConstants.MEDIA_BASE_PATH}/captured-audio`;
  audioContainer= null;
  audioElement = null;
  @Output() closePopOver: EventEmitter<any> = new EventEmitter<any>();

  constructor(private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private applicationStateService: ApplicationStateService,
    private audioRecorderService: AudioRecorderService,
    private renderer: Renderer2, private el: ElementRef,
    private rabbitMQService: RabbitMQService,
    private audioOperationsService: AudioOperationsService) { }

  ngOnInit(): void {
    this.audioRecordingColumns = this.audioOperationsService.prepareAudioRecordingColumns(this.audioRecordingsOperationTemplate, true);
    this.getRecordingsForOrder();
    this.subscribeRabbitMQForAudioOperations();
  }
  ngOnDestroy(): void {
    this.terminalAudioRecordFileSubscription.unsubscribe();
    this.terminalAudioRecordingsSubscription.unsubscribe();
  }
  getRecordingsForOrder() {
    this.showLoaderOnGetRecordings();
    this.rabbitMQRequestIdForOrder = this.rabbitMQService.guidWithTimeStamp();
    this.audioOperationsService.getAudioRecordingsForOrder(this.orderId, this.rabbitMQRequestIdForOrder)
      .subscribe({
        next: (recordersCount: number) => {
          this.recordersCount = recordersCount;
        },
        error: this.alertsService.showApiError
      });
  }

  showLoaderOnGetRecordings = () => {
    this.showLoader = true;
    this.loaderTimeout = setTimeout(() => {
      this.alertsService.renderErrorMessage(Messages.GetAudioFilesResponseError);
      this.showLoader = false;
      this.rabbitMQRequestIdForOrder = null;
      clearTimeout(this.loaderTimeout);
    }, 10000);
  }
  subscribeRabbitMQForAudioOperations = () => {
    this.terminalAudioRecordingsSubscription = this.rabbitMQService.subscribeToAudioRecordingsForOrder$(this.applicationStateService.terminalId)
      .subscribe({
        next: (message: RabbitMQMessage<AudioRecordingsForOrderPayload>) => {
          this.recordersCount = this.recordersCount ? this.recordersCount - 1 : 0;
          if (!this.recordersCount) {
            this.showLoader = false;
            clearTimeout(this.loaderTimeout);
          }

          const recordings = message.Payload.Recordings;
          forEach(recordings, (audio) => {
            this.recordings.push({ Name: audio.FileName, CreatedDate: audio.CreatedDate, HardwareId: message.Payload.HardwareId });
          });
          this.recordings.sort((a, b) => a.Name.localeCompare(b.Name));
        }, error: () => {
          console.log('error while connecting to RabbitMQ.');
        }
      });

    this.terminalAudioRecordFileSubscription = this.rabbitMQService.subscribeToTerminalAudioFile$(this.applicationStateService.terminalId)
      .subscribe({
        next: (message: any) => {
          console.log(`AudioFile received for ${this.tagName} at ${new Date(Date.now())}`);
          clearTimeout(this.audioOperationsService.loaderTimeout);
          
          if (message.Payload.RabbitMQRequestId == this.rabbitMQRequestId) {
            this.spinnerService.hide();
            const fileUrl: string = `${this.audioFilePath}/${message.Payload.FileName}`;

            if (this.isDownloadFile) {
              this.isDownloadFile = false;
              this.audioRecorderService.download(fileUrl, this.tagName);
            } else {
              this.playAudio(fileUrl);
            }
            
            this.rabbitMQRequestId = null;
          }
        }, error: () => {
          console.log('error while connecting to RabbitMQ.');
        }
      });
  }

  downloadAudio = (data: AudioRecording) => {
    this.isDownloadFile = true;
    this.tagName = data.Name;
    this.rabbitMQRequestId = this.rabbitMQService.guidWithTimeStamp();
    this.audioOperationsService.getAudioBytes(data, this.rabbitMQRequestId, () => {
      this.rabbitMQRequestId = null;
    });
  }

  playAudio(fileUrl: string) {
    this.audioContainer = this.el.nativeElement.querySelector('#audio-div');
    this.audioElement = this.renderer.createElement('audio');
    this.audioElement.src = fileUrl;
    this.audioElement.controls = true;
    this.audioElement.setAttribute('controlsList', 'nodownload');
    this.renderer.appendChild(this.audioContainer, this.audioElement);
    this.audioElement.play();
  }

  sendPlayAudioRequest(data: AudioRecording) {
    if (this.audioContainer && this.audioElement) {
      this.renderer.removeChild(this.audioContainer, this.audioElement);
    }
    this.tagName = data.Name;
    this.rabbitMQRequestId = this.rabbitMQService.guidWithTimeStamp();
    this.audioOperationsService.getAudioBytes(data, this.rabbitMQRequestId, () => {
      this.rabbitMQRequestId = null;
    });
  }

  close() {
    this.closePopOver.emit();
  }
}
