import { GridColumnTypes } from './grid-column-types';
import { LinkColumn } from './link-column';

export class EditColumn extends LinkColumn {
    AddIcon?: LinkColumn;

    get Type(): string {
        return GridColumnTypes.EditColumn;
    }

     constructor(init?: Partial<EditColumn>) {
        super();

        this.Width = '50px';
        this.CellClass = 'cell-edit cell-padding';
        this.BackgroundColor = '#54a4de';

        Object.assign(this, init);
    }
}
