import { Component, OnInit, ViewChild } from '@angular/core';
import { CashDrawerService } from '../../services/cash-drawer.service';
import {
  SpinnerService, AlertsService, ApplicationStateService, FieldInfoMessages, SettingParam,
  DomainConstants, Messages, BaseFormComponent, FormUtilityService
} from 'src/app/shared';
import { finalize } from 'rxjs/operators';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { cashDrawerMoneyBillWave } from 'src/app/shared/components/icon';
import { NgForm } from '@angular/forms';
import { PostSavedConfigurationService } from 'src/app/configurator/post-saved-configuration.service';

@Component({
  selector: 'pos-cash-drawer',
  templateUrl: './cash-drawer.component.html',
})
export class CashDrawerComponent extends BaseFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.form
  }
  @ViewChild('cashDrawerForm') form: NgForm;
  fieldInfoMessages = FieldInfoMessages;
  parameters: SettingParam;
  icons = {
    cashDrawerMoneyBillWave
  };

  constructor(protected spinnerService: SpinnerService,
    protected alertsService: AlertsService,
    protected router: Router,
    private cashDrawerService: CashDrawerService,
    protected applicationStateService: ApplicationStateService,
    private postSavedConfigurationService: PostSavedConfigurationService,
    formUtilityService: FormUtilityService) {
    super(formUtilityService);
    this.parameters = this.applicationStateService.newSettingParams();
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.spinnerService.show();
    this.cashDrawerService.getCashDrawerDetail(this.applicationStateService.terminalId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: SettingParam) => {
          this.parameters = response;
          const openDrawerConditions = [];
          _.forEach(response.AvailableTenderTypes, (availableTenderType) => {
            const cashDrawer: any = {};
            cashDrawer.Name = availableTenderType.Name;
            cashDrawer.Code = availableTenderType.Code;
            cashDrawer.IsSelected = false;
            const tender = _.find(DomainConstants.TenderType, (type) => type.Code === availableTenderType.Code);
            cashDrawer.TenderFieldInfo = tender ? tender.FieldInfo : null;
            cashDrawer.ShouldOpenCashDrawer = false;
            _.forEach(response.TenderTypes, (objTenderType) => {
              if (objTenderType.Code === availableTenderType.Code) {
                cashDrawer.IsSelected = true;
              }
            });
            _.forEach(response.OpenDrawerConditions, (objOpenCashDrawer) => {
              if (objOpenCashDrawer.Code === availableTenderType.Code && cashDrawer.IsSelected) {
                cashDrawer.ShouldOpenCashDrawer = true;
              }
            });
            openDrawerConditions.push(cashDrawer);
          });
          this.parameters.OpenDrawerConditions = openDrawerConditions;
          this.onFormChange(this.form);
        }
      });
  }

  save(isValid: boolean) {
    if (!isValid || this.parameters.CashDrawerVarianceThreshold < 0 || this.parameters.CashDrawerVarianceThreshold > 100) {
      return;
    }
    this.parameters.CashDrawerVarianceThreshold = this.parameters.CashDrawerVarianceThreshold ?
      this.parameters.CashDrawerVarianceThreshold : 0;
    const openCashDrawerConditions = [];
    _.forEach(this.parameters.OpenDrawerConditions, (openDrawerConditions) => {
      if (openDrawerConditions.ShouldOpenCashDrawer) {
        const param: any = {};
        param.Code = openDrawerConditions.Code;
        param.Name = openDrawerConditions.Name;
        openCashDrawerConditions.push(param);
      }
    });
    this.parameters.OpenDrawerConditions = openCashDrawerConditions;
    this.spinnerService.show();
    this.cashDrawerService.saveCashDrawer(this.parameters)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          this.alertsService.renderSuccessMessage(Messages.CashDrawerSaveSuccess);
          this.postSavedConfigurationService.showMessage();
        }
      });
  }

  onFormChange($event: NgForm) {
    this.form = $event;
    this.setInitialValue();
  }

  cancel() {
    this.router.navigate(['/manage/system-configuration'])
  }

}
