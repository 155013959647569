import { DecimalPipe } from '@angular/common';
import { Inject, Pipe, PipeTransform } from '@angular/core';
import { TarkLocalProvider, LOCAL_PROVIDER } from '../service/tark-local-provider.service';

@Pipe({
  name: 'tark_number'
})
export class TarkNumberPipe implements PipeTransform {

  constructor(@Inject(LOCAL_PROVIDER) private tarkLocalProvider: TarkLocalProvider,
  private decimalPipe: DecimalPipe) {  }

  transform(value: number): string | null {
    return this.decimalPipe.transform(value, null, this.tarkLocalProvider.getLocale());
  }

}
