import { Component, OnInit, Input } from '@angular/core';
import { ModalFormComponent, ModalService } from 'src/app/shared/components';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgForm } from '@angular/forms';
import { FormUtilityService } from 'src/app/shared/services/form-utility.service';

@Component({
  selector: 'pos-sales-category-edit-model',
  templateUrl: './sales-category-edit-model.component.html',
  styleUrls: ['./sales-category-edit-model.component.scss']
})
export class SalesCategoryEditModelComponent extends ModalFormComponent implements OnInit {
  form: NgForm;
  @Input('salesCategoryId') salesCategoryId: number;
  @Input('isPopup') isPopup: boolean = false;
  initialize: boolean = false;
  get getForm(): NgForm {
    return this.form
  }
  constructor(categoryModalRef: BsModalRef, modalService: ModalService, formUtilityService: FormUtilityService) {
    super(categoryModalRef, modalService, formUtilityService);
    this.initialize = false;
  }

  ngOnInit() {
    this.initialize = true;
    // if ($('.modal:visible').length) {
    //   $('.modal-backdrop').last().css('z-index', 1090);
    //   $('.modal').last().css('z-index', 1090);
    // }
  }

  onClose($event: any) {
    this.hide($event);
  }

  onFormChange($event: NgForm) {
    this.form = $event;
    this.setInitialValue();
  }
}
