<div class="page">
    <div class="width-full">
        <div class="col-12 px-2 pt-pix-10 pb-pix-10">
            <div class="m-0-auto width-full overflow-x-hidden">
                <div class="col-12 mt-pix-5 p-0 vertical-mid px-2 relative">
                    <a class="close mt-pix-n8" (click)="cancel()">×</a>
                    <h3 class="page-content__header">
                        Menu Display<span *ngIf="digitalMenu">: {{digitalMenu?.Name}}
                            ({{digitalMenu?.Width}}×{{digitalMenu?.Height}}) px <pos-icon
                                [icon]="digitalMenu.Orientation ? icons.rectanglePortrait : icons.rectangleLandscape"
                                class="font-25 ps-pix-5 absolute orientation">
                            </pos-icon></span>
                    </h3>
                </div>
                <div class="my-pix-15 col-12 px-pix-15 text-align-right" *ngIf="isEditable">
                    <p-checkbox posInput [(ngModel)]="isShowGroupWidgets" label="Show Groups" class="float-start pt-pix-7"
                        (onChange)="showGroupWidgets()" binary="true"></p-checkbox>
                    <button type="button" class="btn btn-primary white btn-radio col-50 pos-button" (click)="resetPanZoom()">
                        <pos-icon [icon]="icons.undo"></pos-icon>
                    </button>
                    <button type="button" (click)="enablePanZoom()" class="btn-primary btn-md white btn normal ms-pix-10 pos-button">
                        <pos-icon [icon]="dragMode? icons.mousePointer : icons.handRock"></pos-icon>
                        {{dragMode?"Cursor": "Drag"}}
                    </button>
                    <div class="inline-block ms-pix-10">
                        <button type="button" class="btn btn-primary white btn-radio col-50 pos-button"
                            (click)="onZoom(true)">
                            <pos-icon [icon]="icons.plusMessage"></pos-icon>
                        </button>
                        <span class="pe-pix-5 ps-pix-5">{{zoomScale * 100}}%</span>
                        <button type="button" class="btn btn-primary white btn-radio col-50 pos-button"
                            (click)="onZoom(false)">
                            <pos-icon [icon]="icons.minus"></pos-icon>
                        </button>
                    </div>
                </div>
                <div class="p-pix-10 ps-0 display-flex">
                    <div class="pe-pix-10 col-120 overflow-auto" *ngIf="isEditable" [ngStyle]="{'height': screenHeight - 260 + 'px'}">
                        <div class="text-align-center border-1 p-pix-5 h-80 vertical-center-align flex-flow-column pointer"
                            *ngFor="let widgetType of widgetTypes" (click)="openNewWidgetModal(widgetType)" 
                            [pTooltip]="(widgetType.Name == groupWidgetName && !isShowGroupWidgets) ? 'Available only when \'Show Groups\' is selected': ''" tooltipPosition="right"
                            [ngClass]="{'disabled-tool': widgetType.Name == groupWidgetName && !isShowGroupWidgets }">
                            <pos-icon [icon]="widgetType.Icon" class="font-20 vertical-center-align flex-grow">
                            </pos-icon>
                            <div class="lh-15">{{ widgetType.Value }}</div>
                        </div>
                    </div>
                    <div class="mb-pix-15 overflow-auto width-full relative"
                        [ngStyle]="{'height': screenHeight - 260 + 'px'}" #menuDisplay>

                        <div *ngIf="digitalMenu" class="grid-height"
                            [ngStyle]="(panZoomOn ? '' : menuDisplayResolution)" [ngClass]="{'grab' : dragMode}">
                            <gridster [options]="options" id="gridster" #gridster [ngStyle]="{'--menu-background-color': menuDisplayConfig?.BackgroundColor}">
                                <div class="border" [ngStyle]="menuDisplayResolution"></div>
                                <div class="display-contents" *ngFor="let item of dashboard; let index = index">
                                    <gridster-item [item]="item.GridItem" class="widget-border-{{item.Id}} widget-background-{{item.Id}}"
                                        #gridsterItem [ngClass]="{'display-none-imp': item.WidgetType == groupWidgetName}">
                                        <div class="widget-overflow">
                                            <pos-menu-widget [widgetType]="item.WidgetType" [widgetId]="item.Id"
                                                [layerIndex]="item.GridItem.layerIndex" [widgetIndex]="index"
                                                (widgetDelete)="deleteWidget($event)" (copyWidget)="copyWidget(item)"
                                                (reload)="reload($event)" [previewMode]="!isEditable || preview" (edit)="editWidget(item)"
                                                [gridsterItem]="gridsterItem">
                                            </pos-menu-widget>
                                        </div>
                                    </gridster-item>
                                </div>
                            </gridster>
                        </div>
                    </div>
                    <div class="col-md-12 p-pix-10 fix-footer action-button-container">
                        <div class="ps-pix-10 pe-pix-10">
                            <button type="button"  *ngIf="isEditable" class="btn-warning btn-lg btn btn-save pos-button"  (click)="preview = !preview" value="Preview">{{preview ? 'Edit' : 'Preview'}}</button>
                            <button type="submit"  *ngIf="isEditable" class="btn-success btn-lg btn btn-save pos-button"  (click)="save(false)" value="Apply" [disabled]="applyingChanges">
                                <pos-icon [icon]="icons.spinner" *ngIf="applyingChanges"></pos-icon>
                                Apply
                            </button>
                            <button type="button" value="Save" (click)="save()" *ngIf="isEditable" [disabled]="applyingChanges"
                                class="btn-primary btn-lg white btn btn-save pos-button" data-dismiss="modal">Save</button>
                            <button type="button" class="btn-secondary btn-lg btn normal me-pix-5 pos-button" (click)="cancel()"
                                value="Cancel"> Cancel
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #newWidget>
    <form #menuDisplayForm="ngForm" class="edit-form" (ngSubmit)="addWidget(menuDisplayForm.valid)" autocomplete="off">
        <div>
            <button (click)="closeWidgetModal()" aria-label="Close" class="close" type="button">&times;</button>
            <h4 class="modal-content__heading">{{newWidgetHeader}}</h4>
        </div>
        <div class="overflow-auto-tabs pos-modal-body pt-pix-10">
            <div class="col-lg-1 col-md-1 col-sm-1 ps-0 col-60">
                <label class="label-section required">Name</label>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-10 col-12"
                [ngClass]="{'has-error': (name.invalid && menuDisplayForm.submitted)}">
                <input class="form-control" name="name" #name="ngModel" required [(ngModel)]="widgetName">
                <span *ngIf="name.invalid && menuDisplayForm.submitted">
                    <label class="control-label text-nowrap" *ngIf="name.errors?.required">Please enter
                        name.</label>
                </span>
            </div>
        </div>
        <div class="col-12 action-button-container border-top popup-footer">
            <button type="submit" class="btn-primary btn-lg btn normal me-pix-5 mb-pix-10 pos-button white" value="Save">Save</button>
            <button type="button" data-dismiss="modal" class="btn-secondary btn-lg btn normal me-pix-10 mb-pix-10 pos-button"
                (click)="closeWidgetModal()">Cancel</button>
        </div>
        <div class="clearfix"></div>
    </form>
</ng-template>
