import { Component, OnInit, ViewChild } from '@angular/core';
import {
  SettingParam, FieldInfoMessages, AlertsService, SpinnerService, ApplicationStateService, ModalService,
  InfoModalComponent, Messages, FormUtilityService, BaseFormComponent
} from 'src/app/shared';
import { UserSettingsService } from '../../services/user-settings.service';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { usersCogs } from 'src/app/shared/components/icon';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'pos-user-settings',
  templateUrl: './user-settings.component.html',
})
export class UserSettingsComponent extends BaseFormComponent implements OnInit {

  parameters: SettingParam;
  fieldInfoMessages = FieldInfoMessages;
  icons = {
    usersCogs
  };
  @ViewChild('userSettingsForm') userSettingsForm: NgForm;
  get getForm(): NgForm {
    return this.userSettingsForm
  }
  constructor(private alertsService: AlertsService,
    private userSettingsService: UserSettingsService,
    private spinnerService: SpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private applicationStateService: ApplicationStateService,
    formUtilityService: FormUtilityService) {
    super(formUtilityService);
    this.parameters = applicationStateService.newSettingParams();
  }

  ngOnInit() {
    this.loadParameters();
  }

  loadParameters() {
    this.spinnerService.show();
    this.userSettingsService.getUserSettingsDetail(this.applicationStateService.terminalId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: SettingParam) => {
          this.parameters = response;
        }, error: this.alertsService.showApiError
      });
  }

  saveUserSetting() {
    this.spinnerService.show();
    this.parameters.TerminalId = this.applicationStateService.terminalId;
    this.userSettingsService.saveUserSetting(this.parameters)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.alertsService.renderSuccessMessage(Messages.UserSettingSaveSuccess);
          this.router.navigate(['/manage/system-configuration']);
        }
      });
  }

  changeGratuity() {
    if (!this.parameters.AutoGratuity) {
      this.parameters.ManagerOnlyOverrideGratuity = false;
      this.parameters.AutoGratuityPct = 0;
    }
  }

  createManagerLogin() {
    this.spinnerService.show();
    this.userSettingsService.createManagerLogin()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          const modalRef = this.modalService.getModalWrapper(InfoModalComponent);
          modalRef.show({
            animated: false,
            class: 'vertical-center',
            initialState: {
              message: Messages.CreateManagerLoginSaveSuccess
            }
          });
        }, error: this.alertsService.showApiError
      });
  }

  cancel() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
