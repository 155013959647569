import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { memoize, filter, find, forEach, groupBy, Dictionary } from 'lodash';
import * as moment from 'moment';
import { ApplicationStateService, ColorUtilityService, DomainConstants, SettingParam } from 'src/app/shared';
import { MakeTableColorConfig, MakeTableOrderItem, MakeTableOrderModel, MakeTableUIState } from '../../../make-table/interfaces';
import {
  makeTableCollapseCaretRight, makeTableCollapseCaretDown, exclamationTriangle, clock, star, checkCircleMakeTable,
  rocket, magic, cogs, times, check, plusMessage, cog, tasks,
  makeTableNotified,
  digitalSignageBullHorn,
  bellOn,
  bell
} from 'src/app/shared/components/icon';
import { KeyValue } from '@angular/common';
declare var $;
@Component({
  selector: 'pos-order-card',
  templateUrl: './order-card.component.html',
  styleUrls: ['./order-card.component.scss']
})
export class MakeTableOrderComponent implements OnInit, OnChanges {

  @Input() order: MakeTableOrderModel;
  @Input() currentLayout: string;
  @Input() uiStates: MakeTableUIState;
  @Input() noOfColumns: number;
  @Input() isShowOrderUpdates: boolean = true;
  @Input() isFilteredProductsApplied: boolean = false;
  @Input() showProductsWithState: Array<string>;
  seats: Dictionary<MakeTableOrderItem[]>;
  @Output() orderItemStateChangeEmit = new EventEmitter();
  @Output() changeOrderUpdateStateEmit = new EventEmitter();
  @Output() serveOrder: EventEmitter<number> = new EventEmitter<number>();
  @Input() makeTableColorConfig: MakeTableColorConfig = {};
  @Input() isStarColor: boolean;
  @Input() isShowNotifyButtonOnEachItem = false;
  @Output() notifyOrderItemReady = new EventEmitter(); 
  makeTableThresholdColors: Array<{ Second: number; Color: string }> = [];
  orderItemType = DomainConstants.OrderItemTypes;
  makeTableStates = DomainConstants.MakeTableStates;
  settingParam: SettingParam;
  loyaltyAccountTerm: string = '';
  subAccountTerm: string = '';
  notifyButtonColor: string = '';
  notifyButtonFontColor: string = '';
  isDisableNotifyButton = false;
  

  getFontColor = memoize((bgColor) => {
    return this.colorUtilityService.getContrastColor(bgColor);
  });
  public icons = {
    makeTableCollapseCaretRight, makeTableCollapseCaretDown, exclamationTriangle, checkCircleMakeTable, rocket,
    cogs, magic, clock, times, check, star, plusMessage, cog, tasks, makeTableNotified, digitalSignageBullHorn, bell, bellOn
  };
  today = new Date().toLocaleDateString();
  pickupDate: string = null;

  originalOrder = (firstKey: KeyValue<number, string>, secondKey: KeyValue<number, string>): number => {
    return 0;
  }

  constructor(private colorUtilityService: ColorUtilityService,
    private applicationStateService: ApplicationStateService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.order?.OrderItems && this.order?.DietaryRestrictions) {
      this.setProductDietaries();
    }
    if (this.order?.OrderSubaccounts?.length) {
      this.setSubaccountName();
    }
  }

  setDefault() {
    this.settingParam = this.applicationStateService.settingParam;
    this.loyaltyAccountTerm = this.settingParam.LoyalAccountTerm ? this.settingParam.LoyalAccountTerm : 'Account';
    this.seats = groupBy(this.order.OrderItems, 'SubAccountOrdinal');
    this.subAccountTerm = this.settingParam.SubaccountTerm ? this.settingParam.SubaccountTerm : 'Seat';
  }

  ngOnInit(): void {
    this.pickupDate = new Date(this.order.OrderAttributes.PickupTime).toLocaleDateString();
    this.settingParam = this.applicationStateService.settingParam;
    this.notifyButtonColor = this.settingParam.MakeTableNotifyColor ? this.settingParam.MakeTableNotifyColor : 'Green';
    this.notifyButtonFontColor = this.settingParam.MakeTableNotifyFontColor ?? this.colorUtilityService.getContrastColor(this.notifyButtonColor);
    this.setMakeTableThresholdColors();
    if (this.order.CommentDefaultColor) {
      this.order.CommentDefaultForeColor = this.colorUtilityService.getContrastColor(this.order.CommentDefaultColor);
    }
    this.setDefault();
  }

  serveThisOrder(orderId) {
    this.serveOrder.emit(orderId);
  }

  collapseToggle = () => {
    if (this.uiStates[this.order.Id].IsCollapsible) {
      this.uiStates[this.order.Id].IsCollapsed = !this.uiStates[this.order.Id].IsCollapsed;
    }
    if (this.order?.Id) {
      $('.' + this.order?.Id).popover('hide');
    }
  }

  getColor(makeTableDateTime: Date): object {
    const currentMomentDate = moment(new Date());
    const duration = moment.duration(currentMomentDate.diff(makeTableDateTime));
    const seconds = duration.asSeconds();
    const thresholdColor = filter(this.makeTableThresholdColors, (element) => {
      return element.Second > seconds;
    });
    if (thresholdColor.length > 0) {
      return {
        'background-color': thresholdColor[0].Color,
        'color': this.getFontColor(thresholdColor[0].Color)
      };
    } else {
      if (!this.makeTableThresholdColors || this.makeTableThresholdColors.length <= 0) {
        return {
          'background-color': 'Red',
          'color': this.getFontColor('Red')
        };
      } else {
        const lastPosition = this.makeTableThresholdColors.length - 1;
        return {
          'background-color': this.makeTableThresholdColors[lastPosition].Color,
          'color': this.getFontColor(this.makeTableThresholdColors[lastPosition].Color)
        };
      }
    }

  }

  orderItemStateChange(product, order) {
    this.orderItemStateChangeEmit.emit({ Product: product, Order: order });
  }

  changeOrderUpdateState(product, order) {
    this.changeOrderUpdateStateEmit.emit({ Product: product, Order: order });
  }

  onNotifyOrderItemReady(order, product, event) {
    event?.stopPropagation();
    this.notifyOrderItemReady.emit({ Order: order, Product: product });
    this.isDisableNotifyButton = true;
    this.enableNotifyButton()
  }

  enableNotifyButton() {
    setTimeout(() => {
      this.isDisableNotifyButton = false;
    }, 5000, true);
  }

  setMakeTableThresholdColors() {
    this.makeTableThresholdColors = [];
    const makeTableThresholdColors = this.settingParam.MakeTableThresholdColors && this.settingParam.MakeTableThresholdColors.length > 0 ?
      this.settingParam.MakeTableThresholdColors.split(',') : [];
    makeTableThresholdColors.forEach(element => {
      if (element) {
        const color = element.split(':');
        const colorObj = {
          Second: parseInt(color[0], 0),
          Color: color[1]
        }
        this.makeTableThresholdColors.push(colorObj);
      }
    });
  }

  isShowDietaryForProduct(product, dietaryRestrictions) {
    if (product && dietaryRestrictions) {
      const productDietaries = filter(dietaryRestrictions, (warning) => {
        return warning.SubaccountOrdinal === product.SubAccountOrdinal;
      });
      product.DietaryWarnings = productDietaries;
      return productDietaries && productDietaries.length ? true : false;
    }
    return;
  }

  setSubaccountName() {
    forEach(this.order.OrderItems, (product) => {
      const subaccountName = find(this.order.OrderSubaccounts, (subaccount) => subaccount.SubaccountOrdinal == product.SubAccountOrdinal)?.SubaccountName
      product.SubaccountName = subaccountName;
    });
  }


  setProductDietaries() {
    forEach(this.order.OrderItems, (product) => {
      const productDietaries = filter(this.order.DietaryRestrictions, (warning) => {
        return warning.SubaccountOrdinal === product.SubAccountOrdinal;
      });
      product.DietaryWarnings = productDietaries;
    });
  }

}
