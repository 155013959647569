<div class="m-0-auto width-80">
    <div class="page-content">
        <div class="tab-content">
            <div class="tab-pane fade in active show">
                <div class="col-12 p-0 relative">
                    <a class="close pe-pix-20" (click)="redirectToManageConsole()">×</a>
                    <div class="col-12 p-0 ps-pix-15 mb-pix-7 row mx-0">
                        <pos-column xl="3" md="4" sm="6" xs="12" class="p-0">
                            <h3 class="page-content__header">
                                <img alt="" class="me-pix-10" src='images/admin/inventory-receiving.png' height='18px'>
                                Inventory Receiving
                            </h3>
                        </pos-column>
                        <pos-column xl="9" md="8" xs="12" class="row mx-0 p-0 pt-pix-5">
                            <div class="col-1 col-70"><label class="edit-form__section__label">Location</label>
                            </div>
                            <div class="col-lg-4 col-md-5 col-sm-4 col-8 mb-xs-10">
                                <pos-form-field inputStyle="col-12" [appendPaddingTop]="false">
                                    <ng-select posInput [items]="inventoryLocations" bindLabel="Name" bindValue="Id"
                                        placeholder="Select Location" [(ngModel)]="selectedLocation" appendTo="body"
                                        name="selectedLocation" [clearable]="false" [clearOnBackspace]="false"
                                        (change)="setLocationName()">
                                    </ng-select>
                                </pos-form-field>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-12 p-0">
                                <label class="edit-form__section__label alert alert-warning py-pix-6 mb-0"
                                    *ngIf="selectedLocation != 0">
                                    You are receiving products at <b> {{selectedLocationName}} </b> location
                                </label>
                            </div>
                        </pos-column>
                    </div>
                    <form #formInventoryReceiving="ngForm" class="edit-form" autocomplete="off">
                        <div class="col-12 pe-0 pos-page-body border-top pt-pix-5">
                            <pos-row class="g-0">
                                <pos-column>
                                    <pos-form-field label="Receipt Date" inputStyle="col-12 pe-0">
                                        <pos-row class="g-0">
                                            <pos-column xl="2" md="2" sm="3" xs="12" class="p-0">
                                                <div class="btn-group">
                                                    <label class="btn btn-radio"
                                                        [ngClass]="receivingType == 'Now' ? 'active btn-primary normal': 'btn-secondary'">
                                                        <input type="radio" name="receivingTypeSelection"
                                                            (change)="setShowCancelButton()" class="form-check-input"
                                                            (input)="setDefaultDate()" [(ngModel)]="receivingType"
                                                            value="Now">Now</label>
                                                    <label class="btn btn-radio"
                                                        [ngClass]="receivingType == 'Select' ? 'active btn-primary normal': 'btn-secondary'">
                                                        <input type="radio" name="receivingTypeSelection"
                                                            (change)="setShowCancelButton()" class="form-check-input"
                                                            [(ngModel)]="receivingType" value="Select">Select</label>
                                                </div>
                                            </pos-column>
                                            <pos-column xl="7" md="6" sm="8" xs="12" class="pt-pix-10 pt-pix-sm-0">
                                                <div class="row">
                                                    <div class="col-230 pe-0">
                                                        <p-calendar #effectiveDate="ngModel" [readonlyInput]="true"
                                                            [dateFormat]="dateFormat"
                                                            [ngClass]="{'has-error': (effectiveDate.invalid && formInventoryReceiving.submitted)}"
                                                            [inputStyle]="{'width':'unset','display':'unset'}"
                                                            inputStyleClass="form-control" [monthNavigator]=true
                                                            [showIcon]="true" name="effectiveDate" appendTo="body"
                                                            [(ngModel)]="currentDate" [showIcon]="true"
                                                            [disabled]="receivingType == 'Now'">
                                                        </p-calendar>
                                                    </div>
                                                    <div class="col-150 pe-0" *ngIf="receivingType == 'Select'">
                                                        <input type="time" placeholder="HH:mm:ss"
                                                            class="form-control col-150" name="receiveTime"
                                                            #receiveTime="ngModel" [(ngModel)]="currentTime"
                                                            [required]="receivingType == 'Select'">
                                                    </div>
                                                </div>
                                            </pos-column>
                                            <pos-column xl="3" md="4" xs="12" class="float-end text-align-right pe-pix-10 pt-pix-10 pt-pix-md-0">
                                                <button class="btn btn-warning white pos-button"
                                                    (click)="editInventoryReceived()" type="button">Edit
                                                    Inventory Received</button>
                                            </pos-column>
                                        </pos-row>
                                    </pos-form-field>
                                </pos-column>
                                <pos-column class="pt-pix-5">
                                    <pos-form-field label="Barcode" [appendPaddingTop]="false">
                                        <input posInput type="text" name="barcodeName" id="barcodeName"
                                            #barcodeName="ngModel" class="form-control col-300"
                                            [(ngModel)]="barcodeText" (keydown.enter)="searchBarcode()" />
                                    </pos-form-field>
                                </pos-column>
                            </pos-row>

                            <div class="col-12 p-0 mt-pix-15">
                                <span *ngIf="isMobileMode">
                                    <ng-table #inventoryProductReceiving [search]="false" [data]="productList"
                                        [columns]="inventoryProductReceivingColumns" [paginator]="false">
                                    </ng-table>
                                </span>
                                <span *ngIf="!isMobileMode">
                                    <ng-table #inventoryProductReceiving [scrollable]="true" [search]="false"
                                        [columns]="inventoryProductReceivingColumns" [paginator]="false"
                                        [scrollHeight]="screenHeight.toString()" [data]="productList">
                                    </ng-table>
                                </span>
                            </div>
                        </div>

                        <div class="col-12 action-button-container page-footer">
                            <button class="btn btn-primary btn-lg btn-save pos-button"
                                (click)="openConfirmInventoryReceivingModal()" type="button">Save</button>
                            <button class="btn btn-secondary btn-lg btn-cancel pos-button"
                                (click)="showCancelButton ? cancel() : redirectToManageConsole()"
                                type="button">{{showCancelButton ? "Cancel" : "Close"}}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template let-data="rowData" let-rowIndex="rowIndex" #deleteTemplate>
    <div (click)="deleteProductFromReceiving(data, rowIndex)">
        <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
    </div>
</ng-template>


<ng-template #addProductTemplate>
    <div class="text-align-center">
        <pos-action-icon (click)="openInventoryProductSelectionModal()" class="no-wrap" [icon]="icons.plusWhite"
            iconText="Add">
        </pos-action-icon>
    </div>
</ng-template>

<ng-template #qtyReceivedTemplate let-data="rowData" let-rowIndex="rowIndex">
    <div class="override-qty" (click)="openOverridePriceModal(data,true, rowIndex)">
        <pos-action-icon [icon]="icons.editWhite" class="me-pix-5 ms-pix-5"></pos-action-icon>
        <span>{{data.QtyReceived}} </span>
    </div>
</ng-template>

<ng-template #unitQtyOverrideTemplate let-data="rowData" let-rowIndex="rowIndex">
    <div class="override-qty" (click)="openUpdateUnitQtyOverrideModal(data, rowIndex)">
        <pos-action-icon [icon]="icons.editWhite" class="me-pix-5 ms-pix-5"></pos-action-icon>
        <span>{{data.UnitQtyOverride}}</span>
    </div>
</ng-template>

<ng-template #selectFromMultipleInventoryProductModal>
    <pos-edit-wrapper heading="Select Inventory Product" (close)="closeMultipleInventoryProductSelectionModal()">
        <div class="modal-body pos-modal-body screen-scroll pt-pix-10">
            <div class="col-12">
                <div class="col-12 details-box" *ngFor="let product of barcodeProductList"
                    (click)="addInventoryProductRowFromMultiple(product)">
                    {{product.ProductName}}
                </div>
            </div>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="button" class="btn btn-secondary btn-lg btn-cancel-modal pos-button" value="Cancel"
                (click)="closeMultipleInventoryProductSelectionModal()">Cancel</button>
        </div>
    </pos-edit-wrapper>
</ng-template>



<ng-template #productSelectionModal>
    <pos-edit-wrapper heading="Select Inventory Product" (close)="closeInventoryProductSelectionModal()">
        <div class="col-12 pos-modal-body pt-pix-5">
            <pos-row>
                <pos-column>
                    <pos-form-field label="Vendor">
                        <ng-select posInput [items]="vendors" bindLabel="Name" bindValue="Id"
                            placeholder="Select vendor" [(ngModel)]="selectedVendorId"
                            (change)="inventoryId = null; selectedProduct = null; getInventoryProductByVendor(selectedVendorId)"
                            appendTo="body" name="selectedProductVendor">
                        </ng-select>
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Product" inputStyle="col-12 row mx-0">
                        <ng-select posInput [items]="inventoryProductLogList" bindLabel="Product" autofocus
                            placeholder="Select product" [(ngModel)]="selectedProduct" class="col-300 col-3 p-0"
                            (change)="inventoryId = selectedProduct ? selectedProduct.Id : null" appendTo="body"
                            name="selectedProduct">
                        </ng-select>
                        <span class="col-md-3 col-sm-4 col-12 pt-pix-7 p-0 ps-pix-0 ps-pix-sm-5"
                            permissions='[{"Name":"{{permission.inventoryProduct}}", "Level":"{{permission.editLevel}}"}]'>
                            <pos-action-icon (click)="addEditInventoryProduct(0)" [icon]="icons.plusWhite"
                                iconText="Add" class="me-pix-5">
                            </pos-action-icon>
                            <pos-action-icon *ngIf="selectedProduct"
                                (click)="addEditInventoryProduct(selectedProduct ? selectedProduct.Id : 0)"
                                [icon]="icons.editWhiteWithText" iconText="Edit"></pos-action-icon>
                        </span>
                    </pos-form-field>
                </pos-column>
                <pos-column *ngIf="isShowUnitQtyOverride">
                    <pos-form-field label="Unit Qty Override">
                        <input posInput type="number" class="form-control col-120" name="unitQty"
                            [(ngModel)]="unitOverride" #unitQty="ngModel" posDecimal />
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn btn-primary btn-lg btn-save pos-button" (click)="addInventoryProductRow()"
                value="Ok">Ok</button>
            <button type="button" class="btn btn-secondary btn-lg btn-cancel-modal pos-button" value="Cancel"
                (click)="closeInventoryProductSelectionModal()">Cancel</button>
        </div>
    </pos-edit-wrapper>
</ng-template>
