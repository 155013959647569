import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EditorType } from '../../interface/editor-type';


@Component({
    selector: 'pos-editor',
    templateUrl: './editor.component.html',
    styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit {

    @Input('submit') submit = false;
    @Input('propertyValues') propertyValues: any = [];
    @Input('propertyValue') propertyValue: any;
    @Input('required') required = false;
    @Input('propertyName') propertyName = '';
    @Input('propertyPlaceHolder') propertyPlaceHolder = '';
    @Input('controlType') controlType = EditorType.Text;
    @Input('requiredMessage') requiredMessage = '';
    @Input('bindLabel') bindLabel = 'Name';
    @Input('bindValue') bindValue = 'Id';
    @Output() changeValue = new EventEmitter<any>();

    constructor() { }

    ngOnInit() {
    }

    get getEditorType() {
        return EditorType;
    }
    public changeProperty() {
        this.changeValue.emit(this.propertyValue);
    }

}
