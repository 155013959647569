import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';
import { OnlineOrderingScheduleOverrides } from '../interface/online-ordering-schedule-override';

@Injectable({
    providedIn: 'root'
})
export class OnlineOrderingScheduleOverridingService extends BaseCrudService<OnlineOrderingScheduleOverrides> {
    constructor(protected httpClient: HttpClient) {
        super('online-order-schedule-overrides', httpClient);
    }

    getNewOverrideSchedule(): OnlineOrderingScheduleOverrides {
        return {
            Id: 0,
            FromDate: new Date(new Date(Date.now()).setHours(0, 0, 0, 0)),
            ToDate: new Date(new Date(Date.now()).setHours(0, 0, 0, 0)),
            StartTime: null,
            EndTime: null,
            Description: ''
        };
    }

    setOverrideSchedule(schedule: OnlineOrderingScheduleOverrides): OnlineOrderingScheduleOverrides {
        return {
            Id: schedule.Id,
            FromDate: new Date(schedule.FromDate.toString()),
            ToDate: new Date(schedule.ToDate.toString()),
            StartTime: schedule.StartTime,
            EndTime: schedule.EndTime,
            Description: schedule?.Description,
        };
    }
}
