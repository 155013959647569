import { Component, Input, OnInit } from '@angular/core';
import { forEach, remove } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/internal/operators/finalize';
import { TerminalProperty } from 'src/app/configurator/terminals/interface';
import { InventoryProduct } from 'src/app/manage-console/inventory';
import { AlertsService, ModalComponent, SpinnerService } from 'src/app/shared';
import { ProductBuildQueue } from '../../interfaces/product-build-queue';
import { ProductBuildQueueService } from '../../services';

@Component({
  selector: 'pos-product-build-queue-edit',
  templateUrl: './product-build-queue-edit.component.html',
  styleUrls: ['./product-build-queue-edit.component.scss']
})
export class ProductBuildQueueEditComponent extends ModalComponent implements OnInit {

  @Input('productQueue') productQueue: ProductBuildQueue;
  @Input('inventoryProducts') inventoryProducts: Array<InventoryProduct> = [];
  @Input('terminalProperty') terminalProperty: TerminalProperty;
  constructor(protected productQueueModel: BsModalRef,
    protected productBuildQueueService: ProductBuildQueueService,
    private spinnerService: SpinnerService,
    private alertsService: AlertsService) {
    super(productQueueModel);
  }

  ngOnInit(): void {
    this.productQueue = this.productQueue ? this.productQueue : this.productBuildQueueService.newProductBuildQueue();
  }

  onCancel() {
    this.hide({ shouldReload: false });
  }

  removeBgColor() {
    this.productQueue.BackgroundColor = null;
  }

  removeForegroundColor() {
    this.productQueue.ForegroundColor = null;
  }

  saveProductBuildQueue(isValid) {
    if (!isValid) {
      return;
    }
    this.hide({ shouldReload: true, queue: this.productQueue });
  }

  

}
