import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AccountAttribute } from '../../interface/account-attribute';
import * as _ from 'lodash';
import { TemplateColumn, GridColumn } from '@tarktech/tark-ng-utils';

@Component({
  selector: 'pos-account-attributes-view',
  templateUrl: './account-attributes-view.component.html',
})
export class AccountAttributesViewComponent implements OnInit {

  @Input('accountAttributes') accountAttributes: Array<AccountAttribute> = [];
  @ViewChild('nameTemplate', { static: true }) private nameTemplate: any;
  accountAttributeColumns: Array<GridColumn> = [];
  constructor() { }

    ngOnInit() {
      this.configureAccountAttributes();
    }
    // ngOnChanges() {
    //   this.configureAccountAttributes();
    // }

  configureAccountAttributes() {
    const nameColumn = new TemplateColumn({
      itemTemplate: this.nameTemplate,
      Width: '20%',
      CellClass: 'account-attributes mine-shaft'
    });
    this.accountAttributeColumns = [
      new GridColumn({Field: 'AttributeName', IsSortable: true, Width: '20%', CellClass: 'account-attributes mine-shaft' }),
      nameColumn
    ] as Array<GridColumn>;
  }

}
