import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { AlertsService, SpinnerService, ModalService, ModalFormComponent, FormUtilityService, DomainConstants, ApplicationStateService } from 'src/app/shared';
import { Task } from '../../interface';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { TaskService } from 'src/app/shared/services/task.service';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { find, forEach, groupBy, map, orderBy } from 'lodash';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'pos-task-schedule',
  templateUrl: './task-schedule.component.html',
  styleUrls: ['./task-schedule.component.scss']
})
export class TaskScheduleComponent extends ModalFormComponent implements OnInit {
  daysOfWeek = [];
  weekStart: number;
  isTaskGroupCreated = false;
  get getForm(): NgForm {
    return this.taskScheduleForm;
  }

  @ViewChild('taskScheduleForm') taskScheduleForm: NgForm
  public taskDetail: Task;
  close: EventEmitter<any> = new EventEmitter();
  public schedule = {
    day_of_week: 0,
    hour: null,
    hourText: null,
    isCheck: false,
    TaskScheduleDay: null,
    TaskScheduleHour: null,
    task_id: 0
  };

  public editTaskId: number = 0;
  public completeTaskList: any = [];
  public taskScheduleList = [];

  constructor(

    private taskService: TaskService,
    private spinnerService: SpinnerService,
    modalService: ModalService,
    private alertService: AlertsService,
    private applicationStateService: ApplicationStateService,
    modalRef: BsModalRef, formUtilityService: FormUtilityService
  ) {
    super(modalRef, modalService, formUtilityService);
    const self = this;
    this.taskDetail = {
      CurrentPage: null,
      DateAdded: null,
      DateEdited: null,
      PageSize: null,
      active: false,
      id: null,
      taskname: null,
      IsStart: false,
    }
    this.assignCompleteTaskTime();

  }


  ngOnInit() {

    this.showScheduleModel();
    this.weekStart = parseInt(find(DomainConstants.WeekDay, (dayId, day) => day === this.applicationStateService.settingParam.WeekStart), 10) ?? 0;
    const daysOfWeek = [];
    forEach(DomainConstants.WeekDay, (value, key) => {
      daysOfWeek.push({ Id: value, Name: key });
    });
    this.daysOfWeek = [...daysOfWeek.slice(this.weekStart, daysOfWeek.length), ...daysOfWeek.slice(0, this.weekStart)];
  }

  assignCompleteTaskTime() {

    let time = 0;
    let day = 1;
    this.completeTaskList = [];

    for (time = 0; time < 24; time++) {
      for (day = 1; day < 8; day++) {
        this.schedule = {
          day_of_week: day,
          hour: time,
          hourText: null,
          isCheck: false,
          TaskScheduleDay: null,
          TaskScheduleHour: null,
          task_id: 0
        }
        if (time < 12) {
          if (time == 0)
            this.schedule.hourText = (time + 12) + ':00 AM';
          else
            this.schedule.hourText = time.toString().padStart(2, '0') + ':00 AM';
        }
        else {
          if (time == 12)
            this.schedule.hourText = time + ':00 PM';
          else
            this.schedule.hourText = (time - 12).toString().padStart(2, '0') + ':00 PM';
        }
        //this.schedule.isCheck = false;

        this.completeTaskList.push(this.schedule);
      }
    }
    this.spinnerService.hide();
  }

  applyScheduleChanges() {

    var taskModelToSave = {
      'Schedules': [],
      'TaskId': this.editTaskId
    };
    var schedule = {
      day_of_week: 0,
      hour: null,
      TaskScheduleDay: null,
      TaskScheduleHour: null,
      task_id: 0
    }
    this.completeTaskList.flat().filter(x => x.isCheck).forEach(element => {
      const schedule = {
        day_of_week: element.day_of_week,
        hour: element.hour,
        TaskScheduleDay: element.day_of_week,
        TaskScheduleHour: element.hour,
        task_id: this.editTaskId
      }
      taskModelToSave.Schedules.push(schedule);
    });

    this.hide({ reload: false });
    this.spinnerService.show();
    this.taskService.updateTaskSchedule(this.editTaskId, taskModelToSave)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.alertService.renderSuccessMessage(Messages.ScheduleUpdate);
        }
      });

  }

  showScheduleModel() {
    const self = this;
    this.spinnerService.show();
    this.completeTaskList.forEach((item) => {
      item.isCheck = false;
    });
    this.taskDetail.id = this.editTaskId;
    if (!this.editTaskId) {
      this.taskService.addTaskDetail(this.taskDetail)
        .subscribe({
          next: (task) => {
            this.editTaskId = task.data.id;
            this.taskService.getTaskSchedule(task.data.id)
              .subscribe({
                next: (response) => {
                  this.taskScheduleList = response;
                  this.taskScheduleList.forEach((schedule) => {
                    if (schedule.TaskScheduleDay != null) {
                      self.completeTaskList.forEach((item) => {
                        if (item.day_of_week == schedule.TaskScheduleDay && item.hour == schedule.TaskScheduleHour) {
                          item.isCheck = true;
                        }
                      });
                    }
                  });
                  this.createTaskGroups();
                  this.spinnerService.hide();
                }
              });
          }
        });
    }
    else {
      this.taskService.getTaskSchedule(this.editTaskId)
        .subscribe({
          next: (response) => {
            this.taskScheduleList = response;
            this.taskScheduleList.forEach((schedule) => {

              if (schedule.TaskScheduleDay != null) {

                self.completeTaskList.forEach((item) => {
                  if (item.day_of_week == schedule.TaskScheduleDay && item.hour == schedule.TaskScheduleHour) {
                    item.isCheck = true;
                  }
                });
              }

            });
            this.createTaskGroups();
          }
        });
      this.spinnerService.hide();
    }
  }

  createTaskGroups() {
    this.completeTaskList = orderBy(this.completeTaskList, x => x.day_of_week);
    const groupedTasks = map(groupBy(this.completeTaskList, x => x.day_of_week), (key, value) => key);
    this.completeTaskList = [...groupedTasks.slice(this.weekStart, groupedTasks.length), ...groupedTasks.slice(0, this.weekStart)];
    this.isTaskGroupCreated = true;
  }


  cancel() {
    this.hide({ reload: false });
  }

}


