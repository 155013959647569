import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DateColumn, GridColumn, TableComponent, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { ManualDataExport, ManualDataExportPreview } from '../../interface/manual-data-export';
import {
  AlertsService,
  ApplicationStateService,
  BaseGridComponent,
  deleteWhite,
  editWhite,
  ExportService,
  fileSearchWhite,
  Messages,
  PrintTableService,
  SpinnerService,
  squareLightOrangeStack,
  upload,
  StringUtils,
} from 'src/app/shared';
import { ActivatedRoute, Router } from '@angular/router';
import { ManualDataExportService } from '../../service/manual-data-export.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'pos-manual-data-export-list',
  templateUrl: './manual-data-export-list.component.html',
  styleUrls: ['./manual-data-export-list.component.scss']
})
export class ManualDataExportListComponent extends BaseGridComponent implements OnInit {

  manualDataExportList: Array<ManualDataExportPreview> = [];
  manualDataExportColumns: Array<GridColumn> = [];
  manualDataInterfaceColumns: Array<GridColumn> = [];
  icons = {
    editWhite,
    deleteWhite,
    fileSearchWhite,
    squareLightOrangeStack,
    upload
  };

  @ViewChild('operationTemplate', { static: true }) operationTemplate: TemplateRef<any>;
  @ViewChild('headerTemplate', { static: true }) headerTemplate: TemplateRef<any>;
  @ViewChild('manualExportGrid', { static: true }) manualExportGrid: TableComponent;
  constructor(protected manualDataSubscriptionService: ManualDataExportService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected applicationStateService: ApplicationStateService,
    protected route: ActivatedRoute,
    private router: Router,
    protected printService: PrintTableService,
    private exportService: ExportService,
  ) {
    super(applicationStateService, route);
  }

  ngOnInit(): void {
    this.manualDataExportColumns = this.configureGridColumns();
    this.loadExports();
    this.manualExportGrid.context = this.gridContext;
  }

  loadExports() {
    this.spinnerService.show();
    this.manualDataSubscriptionService.getPendingManualExport()
    .pipe(
      finalize(() => {
        this.spinnerService.hide();
      })
    )
    .subscribe({ next: (res) => {
      this.manualDataExportList = res;
    }, error: this.alertService.showApiError });
  }

  configureGridColumns(): GridColumn[] {
    const operationColumn = new TemplateColumn({
      itemTemplate: this.operationTemplate,
      Width: '130px',
      headerTemplate: this.headerTemplate,
      TextAlign: TextAlign.Center
    });
    const columns = [
      new GridColumn({
        HeaderText: 'Name',
        Field: 'Name',
        Width: '55%',
        IsSortable: true,
      }),
      new GridColumn({
        HeaderText: 'Send Via',
        Field: 'SendVia',
        Width: '20%',
      }),
      new DateColumn({
        HeaderText: 'Scheduled On',
        Field: 'ScheduledOn',
        Width: '20%',
        IsSortable: true,
        TextAlign: TextAlign.Left
      })
    ];
    this.manualDataInterfaceColumns  = [... columns];
    columns.push(operationColumn);
    return columns;
  }

  review(data?: ManualDataExport): void {
    this.router.navigate(['review', data.Id], { relativeTo: this.route });
  }

  getConfirmDeleteMessage(data: ManualDataExport): string {
    return StringUtils.format(Messages.ConfirmDeleteWidgetGroup, { 'sourceName': data.Id });
  }

  close() {
    this.router.navigate(['manage/console']);
  }

  protected printData() {
    this.printService.printEmitter.next({ gridColumns: this.manualDataInterfaceColumns, gridData: this.manualDataExportList });
  }

  exportCSV() {
    this.exportService.exportCSV('manual-subscriptions', this.manualDataExportList, null, this.manualDataInterfaceColumns);
  }
}
