<pos-edit-wrapper heading="Confirm" (close)="closeDrawerVariance()">
    <form class="edit-form" #varianceForm="ngForm">
        <div class="col-12 pos-modal-body pt-pix-10">
            <div class="col-12">
                {{ message }}
            </div>
            <pos-row>
                <pos-column>
                    <pos-form-field label="Note" inputStyle="col-12 pe-0">
                        <textarea class="form-control" [(ngModel)]="note" name="note" id="note">{{ note }}</textarea>
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button class="btn btn-primary btn-lg btn-save pos-button" (click)="drawerVarianceOk()">Yes</button>
            <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button"
                (click)="closeDrawerVariance()">No</button>
        </div>
    </form>
</pos-edit-wrapper>
