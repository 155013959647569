import { Route } from '@angular/router';
import { AuthGuard } from '../app-guard';
import { ProductBuildQueueTerminalComponent } from './components/product-build-queue-terminal/product-build-queue-terminal.component';

const PRODUCT_BUILD: Route = {
  path: '',
  canActivate: [AuthGuard],
  component: ProductBuildQueueTerminalComponent
};
export const PRODUCT_BUILD_STATES = [PRODUCT_BUILD];

