import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { CashDrawerService } from 'src/app/configurator/cash-drawer';
import {
  AlertsService, ApplicationStateService, ICloseable, InfoModalComponent, ModalService, NumpadOptions, SettingParam,
  SpinnerService
} from 'src/app/shared';
import { DrawerAmount } from '../interfaces/drawer-amount';

@Component({
  selector: 'pos-load-drawer',
  templateUrl: './load-drawer.component.html',
  styleUrls: ['./load-drawer.component.scss']
})
export class LoadDrawerComponent implements OnInit, ICloseable {

  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  numpadOption: NumpadOptions;
  value = 0;
  settingParam: SettingParam;

  constructor(private applicationStateService: ApplicationStateService,
    private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private router: Router,
    private modalService: ModalService,
    private cashDrawerService: CashDrawerService) { }

  ngOnInit(): void {
    this.numpadOption = {
      enterButtonText: 'Load',
      doubleZero: false
    };
    this.settingParam = this.applicationStateService.settingParam;
  }

  saveAmountInDrawer = (res) => {
    if (res && res.value) {
      const drawerAmount: DrawerAmount = this.cashDrawerService.newDrawerAmount();
      drawerAmount.Price = res.value;
      this.settingParam.CurrentUserName = this.applicationStateService.userDetails.username;
      drawerAmount.objParam = this.settingParam;
      drawerAmount.RegisterId = this.settingParam.CashDrawerPrinter.Id;
      drawerAmount.UserId = this.applicationStateService.userId;
      this.spinnerService.show();
      this.cashDrawerService.saveAmountInDrawer(drawerAmount)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (response) => {
            this.saveAmountInDrawerCompleted(response);
          }, error: this.alertsService.showApiError
        });
    }
  }

  saveAmountInDrawerCompleted(response) {
    this.close.emit();
    if (response.ReturnStatus) {
      this.router.navigate(['order/order-entry']);
    } else {
      const modalRef = this.modalService.getModalWrapper(InfoModalComponent);
      const modal = modalRef.show({
        animated: false,
        class: 'vertical-center',
        initialState: {
          message: response.ReturnMessage[0]
        }
      });
    }
  }

  cancelLoadDrawer = () => {
    this.close.emit();
  }

}
