<div>
    <a class="close" (click)="closeModel()">×</a>
    <h4 class="modal-content__heading">Delivery Workflow: {{orderProductWorkflow.SalesProductName}}</h4>
</div>
<div class="modal-body pos-modal-body pb-0">
    <div class="row p-0">
        <div>
            <h3 class="setting-title"> Delivery Details </h3>
        </div>
        <div class="row pos-title-content pt-pix-10">
            <div class="row mb-pix-5 mx-0 mt-pix-5">
                <div class="col-2 col-120 bold">Order#: </div>
                <div class="col-9 p-0">{{orderProductWorkflow.OrderProduct.OrderId}}</div>
            </div>
            <div class="row mb-pix-5 mx-0">
                <div class="col-2 col-120 bold">Type: </div>
                <div class="col-9 p-0">{{orderProductWorkflow.OrderFulfillment}} </div>
            </div>
            <div class="row mb-pix-5 mx-0">
                <div class="col-2 col-120 bold">Product: </div>
                <div class="col-9 p-0">{{orderProductWorkflow.SalesProductName}} <span
                        class="ps-pix-5">(Qty: {{orderProductWorkflow.OrderProduct.Qty}})</span></div>
            </div>
            <div class="row mb-pix-5 mx-0"
                *ngIf="orderProductWorkflow.OrderProduct?.ActualPrice">
                <div class="col-2 col-120 bold">Total:</div>
                <div class="col-9 p-0">{{orderProductWorkflow.OrderProduct.ActualPrice | tark_currency}}
                </div>
            </div>
            <ng-container *ngIf="orderShippingAddress">
                <div class="row mb-pix-5 mx-0" *ngIf="orderShippingAddress.Name">
                    <div class="col-2 col-120 bold">Customer: </div>
                    <div class="col-9 p-0">{{orderShippingAddress.Name}}</div>
                </div>
                <div class="row mb-pix-5 mx-0"
                    *ngIf="orderShippingAddress.AddressLine1 || orderShippingAddress.AddressLine2 || orderShippingAddress.City || orderShippingAddress.State || orderShippingAddress.ZipCode">
                    <div class="col-2 col-120 bold">Address: </div>
                    <div class="col-9 p-0">
                        {{orderShippingAddress.AddressLine1}}{{orderShippingAddress.AddressLine2 ? (', ' +
                        orderShippingAddress.AddressLine2) : '' }}{{orderShippingAddress.City ? (', ' +
                        orderShippingAddress.City) : '' }}{{orderShippingAddress.State ? (', ' +
                        orderShippingAddress.State) : '' }}{{orderShippingAddress.ZipCode ? (', ') +
                        orderShippingAddress.ZipCode : ''}}</div>
                </div>
                <div class="row mb-pix-5 mx-0" *ngIf="orderShippingAddress.Email">
                    <div class="col-2 col-120 bold">Email: </div>
                    <div class="col-9 p-0">{{orderShippingAddress.Email}}</div>
                </div>
                <div class="row mb-pix-5 mx-0" *ngIf="orderShippingAddress.Phone">
                    <div class="col-2 col-120 bold">Phone: </div>
                    <div class="col-9 p-0">{{orderShippingAddress.Phone | phoneMaskPipe }}</div>
                </div>
            </ng-container>

            <div class="row mb-pix-5 mx-0" *ngIf="comments?.length">
                <div class="col-2 col-120 bold">Comments: </div>
                <div class="col-9 p-0">
                    <ul class="ps-pix-5">
                        <li style="list-style-type: disc" *ngFor="let comment of comments">{{comment}}</li>
                    </ul>
                </div>
            </div>
            <!-- <div class="row p-0">
            </div> -->
        </div>
    </div>
    <div class="col-12 section-border p-0">
        <div>
            <h3 class="setting-title pt-pix-10"> Workflow States </h3>
        </div>
        <div class="col-12 pt-pix-10">
            <ul class="ps-pix-5">
                <span *ngFor="let transition of orderProductTransitions; let last=last;">
                    <li [ngClass]="{'grey': !transition?.DateTime && transition.State != nextStep }"
                        class="display-flex">
                        <div class="progress-track pe-pix-20">
                            <pos-icon class="progress-done font-15 pi-0 progress-complete progress-track-icon"
                                [icon]="icons.circlePlain" *ngIf="transition?.DateTime || workflowCompleted">
                            </pos-icon>
                            <pos-icon class=" pi-0 font-15 progress-incomplete progress-track-icon"
                                [icon]="icons.fadeCircle" *ngIf="!transition?.DateTime && transition.State != nextStep">
                            </pos-icon>
                            <pos-icon class="pi-0 font-15 progress-current progress-track-icon"
                                [icon]="icons.fadeCircle"
                                *ngIf="(!transition?.DateTime) && transition.State == nextStep && !workflowCompleted">
                            </pos-icon>
                            <span class="vertical-line p-0"
                                [ngClass]="{'vertical-grey': !transition?.DateTime && orderProductWorkflow.CurrentState != transition.State}"
                                *ngIf="!last"></span>
                        </div>
                        <div class="display-contents pe-pix-3"
                            [ngClass]="{'bold': transition?.DateTime ||  transition.State == nextStep }">
                            {{transition.State}} </div>
                        <br />
                        {{transition?.DateTime? ' on ':''}}
                        {{transition?.DateTime |tark_date_time}}{{transition.UserName ? ' by ':
                        ''}}{{transition?.UserName}}
                </span>
            </ul>
        </div>
    </div>
</div>
<div class="col-12 action-button-container popup-footer pb-pix-10">
    <button class="btn btn-primary btn-lg btn-save pos-button" *ngIf="nextStep" (click)="confirmNextStep()">
        {{nextStep}}
    </button>
    <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="closeModel()">Close</button>
</div>
<div class="clearfix"></div>
