import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseCrudService } from 'src/app/shared';
import { SalesProductBarcode } from '../interface/sales-product-barcode';

@Injectable({
  providedIn: 'root'
})
export class SalesProductBarcodeService extends BaseCrudService<SalesProductBarcode> {

  constructor(protected httpService: HttpClient) {
    super('sales-product-barcodes', httpService);
  }

  newBarcode(): SalesProductBarcode {
    return {
      Id: 0,
      Barcode: null,
      SalesSizeId: null,
      SalesProductId: null,
      Qty: null,
      SalesSize: null,
      SalesProduct: null
    }
  }
}
