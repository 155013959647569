import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';
import { MenuBackup } from '../interfaces/menu-backup';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuBackupService extends BaseCrudService<MenuBackup> {
  constructor(private httpClient: HttpClient) {
      super('menu-backup', httpClient);
  }

  getMenuBackups(menuId: number): Observable<Array<MenuBackup>> {
    return this.httpService.get<Array<MenuBackup>>(
        `${this.apiEndpoint}/${menuId}`
    );
  }

  createBackup(menuId: number, backupName: string): Observable<any> {
    return this.httpService.post(
        `${this.apiEndpoint}/${menuId}/backup/${backupName}`,
        null
    );
  }

  restoreBackup(backupId: Number): Observable<any> {
    return this.httpService.post(
        `${this.apiEndpoint}/${backupId}/restore`,
        null
    );
  }

}


