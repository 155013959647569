import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutDesignerListComponent } from './components/layout-designer-list/layout-designer-list.component';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ComponentsModule } from '../shared';
import { TableModule as PrimengTableModule } from 'primeng/table';
import { TableConfiguration } from '../shared';
import { NgSelectModule } from '@ng-select/ng-select';
import { CalendarModule } from 'primeng/calendar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';

import { ColorPickerModule } from 'ngx-color-picker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TableModule } from '@tarktech/tark-ng-utils';
import { HeaderEntryModule } from '../header-entry';
import { LayoutDesignerEditComponent } from './components/layout-designer-edit/layout-designer-edit.component';
import { LayoutDesignerComponent } from './components/layout-designer';
import { TableOrderDetailsComponent } from './components/table-order-details/table-order-details.component';
import { RouterModule } from '@angular/router';
import { LAYOUT_DESIGNER_ROUTES } from './layout-designer.routes';
import { RouteConfigService } from '../shared/services/route-config.service';

RouteConfigService.addGuardsToRoutes(LAYOUT_DESIGNER_ROUTES);

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ModalModule.forRoot(),
    ComponentsModule,
    TableModule.forRoot(TableConfiguration),
    NgSelectModule,
    PrimengTableModule,
    CalendarModule,
    AutoCompleteModule,
    ColorPickerModule,
    CheckboxModule,
    TooltipModule.forRoot(),
    HeaderEntryModule,
    RouterModule.forChild(LAYOUT_DESIGNER_ROUTES)
  ],
  providers: [],
  declarations: [LayoutDesignerListComponent, LayoutDesignerEditComponent, LayoutDesignerComponent, TableOrderDetailsComponent],
  exports: [LayoutDesignerListComponent, LayoutDesignerComponent]
})
export class LayoutDesignerModule { }
