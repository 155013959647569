<pos-edit-wrapper heading="Confirmation Screen" (close)="cancel()" [headingIcon]="icons.confirmationScreenSolid"
    [isPopup]="false">
    <form (ngSubmit)="saveConfirmationScreenWorkflow(confirmationScreenWorkflowForm.valid)"
        #confirmationScreenWorkflowForm="ngForm" class="edit-form">
        <div class="col-12 border-top pt-pix-5">
            <div class="col-12 content-scroll pos-page-body">
                <pos-row>
                    <pos-column>
                        <pos-form-field inputStyle="row"
                            [validationMessages]="{required: 'Please enter value.', min: 'Please enter positive value.'}">
                            <pos-column xs="3" class="w-auto pe-0 edit-form__section__label">
                                <p-checkbox posInput name="displayImageOnAddProduct" #displayImageOnAddProduct="ngModel"
                                    [(ngModel)]="confirmationScreenWorkflowParameter.ConfirmationScreenDisplayImageOnAddProduct"
                                    binary="true" label="Show Product Image on Confirmation Terminals for">
                                </p-checkbox>
                            </pos-column>
                            <pos-column xs="3" class="col-135">
                                <input posInput class="form-control col-120" name="displayImageSeconds"
                                    [(ngModel)]="confirmationScreenWorkflowParameter.ConfirmationScreenDisplayImageSeconds"
                                    type="number" min="0" #displayImageSeconds="ngModel" posOnlyNumbers
                                    [disabled]="!confirmationScreenWorkflowParameter.ConfirmationScreenDisplayImageOnAddProduct" />
                            </pos-column>
                            <pos-column xs="3" class="no-wrap">
                                <label class="edit-form__section__label">seconds</label>
                            </pos-column>
                        </pos-form-field>
                    </pos-column>
                    <pos-column *ngIf="confirmationScreenWorkflowParameter.ConfirmationScreenDisplayImageOnAddProduct">
                        <pos-form-field inputStyle="row"
                            [validationMessages]="{required: 'Please enter value.', min: 'Please enter positive value.'}">
                            <pos-column xs="3" class="w-auto pe-0">
                                <label class="edit-form__section__label">Image transition time</label>
                            </pos-column>
                            <pos-column xs="3" class="col-135 pe-0">
                                <input posInput class="form-control" name="transitionSeconds"
                                    [(ngModel)]="confirmationScreenWorkflowParameter.ConfirmationScreenDisplayImageTransitionSeconds"
                                    type="number" min="0" #transitionSeconds="ngModel" posOnlyNumbers />
                            </pos-column>
                            <pos-column xs="3" class="no-wrap">
                                <label class="edit-form__section__label">seconds</label>
                            </pos-column>
                        </pos-form-field>
                    </pos-column>
                    <pos-column *ngIf="confirmationScreenWorkflowParameter.ConfirmationScreenDisplayImageOnAddProduct">
                        <pos-form-field label="Background Color">
                            <pos-color-picker posInput name="backgroundColor"
                                [(ngModel)]="confirmationScreenWorkflowParameter.ConfirmationScreenDisplayImageBackground">
                            </pos-color-picker>
                        </pos-form-field>
                    </pos-column>
                </pos-row>
                <pos-row>
                    <pos-column *ngIf="confirmationScreenWorkflowParameter.ConfirmationScreenDisplayImageOnAddProduct">
                        <h3 class="setting-title">Header Style</h3>
                        <div class="pos-title-content">
                            <pos-font-style class="col-12" [name]="'headerStyle'"
                                [fontStyle]="confirmationScreenDisplayImageHeaderStyle">
                            </pos-font-style>
                        </div>
                    </pos-column>
                </pos-row>
                <pos-row>
                    <pos-column *ngIf="confirmationScreenWorkflowParameter.ConfirmationScreenDisplayImageOnAddProduct">
                        <h3 class="setting-title">Product Style</h3>
                        <div class="pos-title-content">
                            <pos-font-style class="col-12 " [name]="'productStyle'"
                                [fontStyle]="confirmationScreenDisplayImageProductStyle">
                            </pos-font-style>
                        </div>
                    </pos-column>
                </pos-row>
            </div>
        </div>
        <div class="col-12 action-button-container page-footer">
            <button class="btn-primary btn-lg btn btn-save pos-button" scrollup type="submit" value="Save">Save</button>
            <button class="btn-secondary btn-lg btn btn-cancel pos-button" routerLink="../" scrollup type="button"
                value="Cancel">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
