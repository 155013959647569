import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RuntimeConstants } from 'src/app/shared';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InstructionsService {

  instructionsApiUrl = '';
  constructor(private httpService: HttpClient) {
    this.instructionsApiUrl = RuntimeConstants.API_PATH + 'SalesProductInstructions';
  }


  getInstructions(orderProductId: number): Observable<any> {
    return this.httpService.get(`${this.instructionsApiUrl}/` + orderProductId + `/product-instructions`);
  }

  getOrderProducts(currentTerminalId): Observable<any> {
    return this.httpService.get(`${this.instructionsApiUrl}/` + currentTerminalId + `/open-orders`);
  }

  getMappedOrderEntryTerminals(currentTerminalId): Observable<any> {
    return this.httpService.get(`${this.instructionsApiUrl}/` + currentTerminalId + `/mapped-order-entries`);
  }

  getSalesProductIngredients(salesProductId: number): Observable<any> {
    return this.httpService.get(`${this.instructionsApiUrl}/` + salesProductId + `/product-ingredients`);
  }

  saveSalesProductInstructionArea(model: any): Observable<any> {
    return this.httpService.post(`${this.instructionsApiUrl}/instruction-area`, model);
  }

  saveSalesProductInstruction(model: any, file: File): Observable<any> {
    const formData = this.prepareIconFormData(model, file);
    return this.httpService.post(
      `${this.instructionsApiUrl}/instruction-area/instruction`,
      formData
    );
  }

  deleteSalesProductInstructionArea(id: number): Observable<any> {
    return this.httpService.delete(`${this.instructionsApiUrl}/instruction-areas/` + id);
  }

  deleteSalesProductInstruction(id: number): Observable<any> {
    return this.httpService.delete(`${this.instructionsApiUrl}/instruction-area/instructions/` + id);
  }

  getInstructionsImages(): Observable<any> {
    return this.httpService.get(`${this.instructionsApiUrl}/images`);
  }

  saveSalesProductInstructionExclusion(model: any): Observable<any> {
    return this.httpService.post(`${this.instructionsApiUrl}/instruction-exclusion`, model);
  }

  getSalesProductInstructionExclusions(salesProductId: number): Observable<any> {
    return this.httpService.get(`${this.instructionsApiUrl}/` + salesProductId + `/instruction-exclusion`);
  }

  deleteSalesProductInstructionExclusion(instructionExclusionId: number): Observable<any> {
    return this.httpService.delete(`${this.instructionsApiUrl}/` + instructionExclusionId + `/instruction-exclusion`);
  }

  private prepareIconFormData(param, iconFile: File): FormData {
    const formData = new FormData();
    if (iconFile) {
      formData.append('file', iconFile);
    }
    formData.append('model', JSON.stringify(param));
    return formData;
  }

}
