import { Route } from '@angular/router';
import { AuthGuard } from '../app-guard';
import { Levels, DomainConstants } from '../shared/constants';
import { DashboardConfigurationWrapperComponent } from './components/dashboard-configuration-wrapper';

export const MANAGEMENT_DASHBOARD_STATES: Array<Route> = [
    {
        path: 'management-dashboard/:terminalId',
        component: DashboardConfigurationWrapperComponent,
        canActivate: [AuthGuard],
        data: {
            permissions: [{ 'Name': DomainConstants.TerminalTypes.MANAGEMENT_DASHBOARD.Permission, 'Level': Levels.Access }]
        }
    },
    {
        path: 'dashboards/template/:mode/:dashboardId',
        component: DashboardConfigurationWrapperComponent,
        canActivate: [AuthGuard],
        data: {
            permissions: [{ 'Name': DomainConstants.TerminalTypes.MANAGEMENT_DASHBOARD.Permission, 'Level': Levels.Access }]
        }
    }
];
