<pos-edit-wrapper (close)="onCancel(false)" heading="Copy to New Role">
    <form #copyToNewSecurityRoleNgForm="ngForm" (ngSubmit)="submit(copyToNewSecurityRoleNgForm.valid)"
        autocomplete="off">
        <div class="col-12 pos-modal-body form-horizontal screen-scroll pt-pix-5">
            <pos-row colXs="12">
                <pos-column>
                    <label>
                        Copy From: {{roleName}}
                    </label>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Copy To" [validationMessages]="{required: 'Please enter new role name.'}"
                        [inputStyle]="{'col-lg-9 col-xs-8': true, 'has-error': roleAlreadyExists && copyToNewSecurityRoleNgForm.submitted}">
                        <input posInput class="form-control" #copyTo="ngModel" id="copyToSecurityRole" name="copyToSecurityRole"
                        (input)="roleAlreadyExists = false" [(ngModel)]="copyToRole" [required]="true">
                        <span *ngIf="roleAlreadyExists && copyToNewSecurityRoleNgForm.submitted">
                            <label class="control-label"><span class="bold">{{copyToRole}} </span> security role name already exists.</label>
                        </span>
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="clearfix"></div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" scrollup>Save </button>
            <button type="button" class="btn-secondary btn-lg btn normal btn-cancel-modal pos-button" (click)="onCancel(false)">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
