import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RuntimeConstants } from 'src/app/shared/constants';
import { OrderItemStatus } from '../interfaces/order-item-status';
import { SlideShowDetails } from 'src/app/configurator/digital-signage/interface/slide-show-details';
import { ActiveOrder } from 'src/app/shared';
@Injectable({
  providedIn: 'root'
})
export class OrderStatusService {

  constructor(private httpService: HttpClient) { }

  getTerminalSlideshowDetails(terminalId: number): Observable<Array<SlideShowDetails>> {
    return this.httpService.get<Array<SlideShowDetails>>(
      `${RuntimeConstants.API_PATH}slideshows/` + terminalId + '/slideshowdetails'
    );
  }

  getActiveOrdersForOrderStatus(): Observable<Array<ActiveOrder>> {
    return this.httpService.get<Array<ActiveOrder>>(
      `${RuntimeConstants.API_PATH}order-status/`
    );
  }

}
