import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { OrderNavigationButton, ScreenButtons } from 'src/app/orders';
import { forEach } from 'lodash';
import { finalize } from 'rxjs/operators';
import { ReArrangeService } from '../../services/re-arrange.service';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { SettingParam } from 'src/app/shared/interface/setting-param';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { SpinnerService } from 'src/app/shared/components/spinner';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { OrderService } from 'src/app/shared/services/order.service';
import { arrowsAlt, fileSearchPreview } from 'src/app/shared/components/icon/icons';
import { ICloseable, ModalService } from 'src/app/shared/components';
import { ButtonNavigationService } from 'src/app';
@Component({
  selector: 'pos-re-arrange-button',
  templateUrl: './re-arrange-button.component.html',
  styleUrls: ['./re-arrange-button.component.scss']
})
export class ReArrangeButtonComponent implements OnInit, ICloseable {

  @Input() orderNavigation: ScreenButtons;
  @Input() screenId: number;
  @Input() isPreviewMode = false;
  close: EventEmitter<any> = new EventEmitter();
  public imageRootPath = RuntimeConstants.IMAGE_BASE_PATH + '/buttons';
  settingParam: SettingParam;
  previewButtons: Array<OrderNavigationButton> = [];
  icons = {
    arrowsAlt,
    fileSearchPreview
  };

  constructor(private applicationStateService: ApplicationStateService,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private reArrangeService: ReArrangeService,
    private buttonNavigationService: ButtonNavigationService,
    private orderService: OrderService,
    private modalService: ModalService
  ) {
  }

  ngOnInit(): void {
    if (!this.isPreviewMode) {
      this.prepareGroupedEmbeddedButtons();
    }
    this.settingParam = this.applicationStateService.settingParam;
  }

  dragEntered(event) {
    if (this.isPreviewMode) {
      return;
    }
    const phContainer = event.container.element.nativeElement;
    const phElement = phContainer.querySelector('.cdk-drag-placeholder');
    phContainer.removeChild(phElement);
    phContainer.parentElement.insertBefore(phElement, phContainer);
    moveItemInArray(this.orderNavigation.Buttons, event.item.data, event.container.data);
  }

  saveReArrangeButtons = () => {
    let ordinal: any = -1;
    let reArrangeId: any = -1;
    forEach(this.orderNavigation.Buttons, (item, $index) => {
      ordinal = ordinal + ',' + ($index + 1);
      reArrangeId = reArrangeId + ',' + (item.EmbeddedScreenChoiceId ? item.EmbeddedScreenChoiceId : item.ScreenChoiceId);
    });
    const buttonModel = {
      id: reArrangeId,
      ordinal: ordinal,
      ScreenId: this.screenId
    };

    this.spinnerService.show();
    this.reArrangeService.reArrangeButtons(buttonModel)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: any) => {
          this.close.emit({ shouldReload: true });
        }, error: this.alertService.showApiError
      });
  }

  prepareGroupedEmbeddedButtons() {
    const embeddedButtons = new Map();
    const orignalArray = [...this.orderNavigation.Buttons];
    this.orderNavigation.Buttons = this.orderNavigation.Buttons.filter((data) => {
      if (data.EmbeddedScreenId) {
        if (!embeddedButtons.has(data.EmbeddedScreenId)) {
          const embeddedButton = this.orderService.newEmbeddedScreenButton(data);
          embeddedButtons.set(data.EmbeddedScreenId, embeddedButton);
        }
        return false;
      }
      return true;
    });
    if (embeddedButtons.size) {
      this.previewButtons = orignalArray;
      this.addEmbeddedScreenGroup(embeddedButtons);
    }
  }

  addEmbeddedScreenGroup(embeddedButtons) {
    embeddedButtons.forEach((value, key) => {
      this.orderNavigation.Buttons.push(value);
    })
    this.sortByOrdinal(this.orderNavigation.Buttons);
  }

  onCancel() {
    this.close.emit({ shouldReload: false });
  }

  preview() {
    const modalRef = this.modalService.getModalWrapper(ReArrangeButtonComponent);
    this.setPreviewButtons();
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center modal-lg modal-max-width-75',
      initialState: {
        orderNavigation: this.orderNavigation,
        isPreviewMode: true
      }
    });
    modal.close.subscribe(res => {
      this.prepareGroupedEmbeddedButtons();
    });
  }

  setPreviewButtons() {
    this.previewButtons.forEach((data) => {
      data.Ordinal = this.orderNavigation.Buttons.findIndex(x => {
        if (x.EmbeddedScreenId) {
          return x.EmbeddedScreenChoiceId === data.EmbeddedScreenChoiceId;
        } else {
          return x.ScreenChoiceId === data.ScreenChoiceId;
        }
      });
    });
    this.sortByOrdinal(this.previewButtons);
    this.buttonNavigationService.setEmbeddedScreenOrdinal(this.previewButtons);
    this.orderNavigation.Buttons = this.previewButtons;
  }

  sortByOrdinal(buttons: Array<OrderNavigationButton>) {
    buttons.sort((a, b) => {
      return a.Ordinal - b.Ordinal;
    })
  }
}
