import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DashboardConfiguration, IFrameWidgetConfiguration, ModalService } from 'src/app/shared';
import { WidgetEditComponent } from '../widget-edit';
import { cloneDeep, forEach } from 'lodash';
import { BaseWidgetService } from 'src/app/adf/services/base-widget.service';
import { AdfColumn, AdfModel } from 'src/app/adf/interfaces/adf-model';
import { Observable, interval as observableInterval, Subscription } from 'rxjs';
import { DashboardBroadcastingService } from 'src/app/adf/services/dashboard-broadcasting.service';

@Component({
  selector: 'pos-iframe-widget',
  templateUrl: './iframe-widget.component.html',
})
export class IframeWidgetComponent implements OnInit, OnDestroy {

  @Input() widgetConfiguration: DashboardConfiguration;
  @Input() config: IFrameWidgetConfiguration;
  @Input() isInteractive = false;
  @Input() adfModel: AdfModel;
  @Input() column: AdfColumn;
  copiedConfig: IFrameWidgetConfiguration;
  isLoading = false;
  timerSubscription: any;
  eventsSubscription: Array<Subscription> = [];
  url: SafeResourceUrl;
  timer: Observable<number>;
  constructor(private modalService: ModalService,
    private sanitizer: DomSanitizer,
    private baseWidgetService: BaseWidgetService,
    private dashboardBroadcastingService: DashboardBroadcastingService) { }

  ngOnInit(): void {
    this.copiedConfig = cloneDeep(this.config);
    if (this.widgetConfiguration && !this.widgetConfiguration.isNewWidget) {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.config.url);
      this.baseWidgetService.setWidgetHeights(this.adfModel);
      this.startAutoIntervalTimer();
    } else {
      this.editIframeWidget();
    }
    this.subscribeBroadcastEvents();
  }

  ngOnDestroy(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
    forEach(this.eventsSubscription, (subscribe) => {
      if (subscribe) {
        subscribe.unsubscribe();
      }
    });
  }

  subscribeBroadcastEvents = () => {
    this.eventsSubscription.push(this.dashboardBroadcastingService.reloadWidget.subscribe((widget) => {
      if (widget && widget.widgetAssignmentId > 0) {
        this.reloadWidget(widget);
      }
    }));
  }

  reloadWidget = (widget) => {
    if (this.widgetConfiguration.widgetAssignmentId == widget.widgetAssignmentId) {
      this.isLoading = true;
      this.widgetConfiguration = cloneDeep(widget);
      this.config = widget.config;
      this.widgetConfiguration.isReloadWidget = true;
      this.getWidgetCompleted();
      // this.baseWidgetService.loadBaseWidget(this.widgetConfiguration, this.config, this.getWidgetCompleted);
      if (this.config) {
        this.config = this.copiedConfig;
      }
    }
  }

  startAutoIntervalTimer = () => {
    if (this.widgetConfiguration && this.widgetConfiguration.AutoRefreshInterval) {
      this.timer = observableInterval(this.widgetConfiguration.AutoRefreshInterval * 60 * 1000);
      this.timerSubscription = this.timer.subscribe((t) => {
        this.widgetConfiguration.isReloadWidget = true;
        this.baseWidgetService.loadBaseWidget(this.widgetConfiguration, this.config, this.getWidgetCompleted);
      });
    }
  }

  getWidgetCompleted = () => {
    this.widgetConfiguration.isReloadWidget = false;
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.config.url);
    this.isLoading = false;
  }

  editIframeWidget() {
    const modalRef = this.modalService.getModalWrapper(WidgetEditComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center modal-lg',
      initialState: {
        widgetConfig: cloneDeep(this.widgetConfiguration),
      }
    });
    modal.close.subscribe((res) => {
      if (res && res.reload) {
        this.widgetConfiguration = res.widgetConfiguration;
        this.widgetConfiguration.isNewWidget = false;
        this.config = res.widgetConfiguration.config;
        this.updateAdfModel();
        this.baseWidgetService.setWidgetHeights(this.adfModel);
        this.getWidgetCompleted();
      } else {
        this.isLoading = false;
        this.deleteWidget()
      }
    });
  }

  updateAdfModel = () => {
    const rowIndex = this.widgetConfiguration.rowIndex > 0 ? this.widgetConfiguration.rowIndex - 1 : 0;
    const columnIndex = this.widgetConfiguration.columnIndex ? this.widgetConfiguration.columnIndex - 1 : 0;
    if (this.adfModel && this.adfModel.rows) {
      if (this.adfModel.rows[rowIndex] && this.adfModel.rows[rowIndex].columns) {
        if (this.adfModel.rows[rowIndex].columns[columnIndex].widgets)
          this.adfModel.rows[rowIndex].columns[columnIndex].widgets[0] = this.widgetConfiguration;
      }
    }
  }

  deleteWidget() {
    if (this.column) {
      const index = this.column.widgets.indexOf(this.widgetConfiguration);
      if (index >= 0) {
        this.column.widgets.splice(index, 1);
        this.dashboardBroadcastingService.widgetDelete(this.widgetConfiguration);
      }
    }
  }


}
