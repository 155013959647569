<div class="col-3 pt-pix-10">
    <div class="left-nav-toggle" id="menu-toggle-div" (click)="toggleFullScreen()">
        <a class="left-nav-toggle__icon">
            <pos-icon class="black" [icon]="isFullScreen ? icons.angleDoubleRight : icons.angleDoubleLeft"></pos-icon>
        </a>
    </div>
</div>
<div class="col-12 tab-section px-3">
    <div class="vertical-tabs">
        <ul class="nav nav-pills nav-stacked flex-column" [hidden]="isFullScreen">
            <div class="inline-block width-full">
                <div class="page-search">
                    <span class="search-icons">
                        <pos-icon [icon]="icons.times" *ngIf="searchEvent"
                            (click)="filteredEvents = events;searchEvent = ''">
                        </pos-icon>
                    </span>
                    <pos-icon [icon]="icons.search" *ngIf="!searchEvent"></pos-icon>
                    <input class="form-control" [(ngModel)]="searchEvent" (input)="search()"
                        placeholder="Event Filter...">
                </div>
            </div>
            <ng-container *ngFor="let groupEvent of filteredEvents | keyvalue: originalOrder">
                <li class="tab-head">
                    {{ groupEvent.key }}
                </li>
                <li class="pointer" role="presentation"
                    [ngClass]="{'active': selectedEventName == event.Configuration.Name, 'display-none-imp': !event.Configuration.AllowUserSubscription}"
                    *ngFor="let event of groupEvent.value" [hidden]="!event.Configuration.AllowUserSubscription"
                    scrollup (click)="selectedEventName = event.Configuration.Name; selectEventConfiguration(event)">
                    <a>
                        <div class="display-flex">
                            {{event.Configuration.Name}}
                        </div>
                    </a>
                </li>
            </ng-container>
        </ul>
        <pos-event-configuration-list [(isFullScreen)]="isFullScreen" *ngIf="selectedEventName"
            [(eventConfiguration)]="selectedEventConfiguration.Configuration"
            [(availableDeliveryChannels)]="selectedEventConfiguration.AvailableDeliveryChannels"
            [(eventId)]="selectedEventConfiguration.Configuration.Id" (reload)="getEventConfiguration($event)"
            (closeTab)="selectedEventName = null">
        </pos-event-configuration-list>
    </div>
</div>
