<div class="col-12 pb-pix-3 pt-pix-3">
  <div #groupServingInformation="ngModelGroup" ngModelGroup="groupServingInformation" autocomplete="off">
    <div class="row">
      <div class="col-12">
        <legend class="reset-style bold"><span class="bold">Serving Information</span></legend>

        <div class="row row-cols-sm-3 ps-2">
          <div>
            <pos-form-field label="Size" [validationMessages]="{min: 'Please enter valid size.'}">
              <input posInput type="number" min="0" class="form-control" name="size" #size="ngModel" [(ngModel)]="servingInformationData.ServingSize" />
            </pos-form-field>
          </div>

          <div>
            <pos-form-field label="Measure">
              <ng-select posInput [items]="measureList" bindLabel="Name" bindValue="Id" [(ngModel)]="servingInformationData.MeasureId" placeholder="Select measure"
                name="measures" [selectOnTab]="true" appendTo="body">
              </ng-select>
            </pos-form-field>
          </div>

          <div>
            <pos-form-field [label]="'Serving(s) per '+ servingUnit" [validationMessages]="{min: 'Please enter valid value.'}">
              <input posInput type="number" min="0" class="form-control" name="ratio" #ratio="ngModel" [(ngModel)]="servingInformationData.ServingRatioToProduct"/>
            </pos-form-field>
          </div>

          <div>
            <pos-form-field label="Calories" [validationMessages]="{min: 'Please enter valid calories.'}">
              <input posInput type="number" min="0" name="calories" #calories="ngModel" [(ngModel)]="servingInformationData.Calories"
                class="form-control" />
            </pos-form-field>
          </div>

          <div>
            <pos-form-field label="Calories from Fat" [validationMessages]="{min: 'Please enter valid fat.'}">
              <input posInput type="number" min="0" name="caloriesfromfat" #caloriesfromfat="ngModel" [(ngModel)]="servingInformationData.CaloriesFromFat"
                class="form-control" />
            </pos-form-field>
          </div>

          <div>
            <pos-form-field label="Servings per Container" [validationMessages]="{min: 'Please enter valid value.'}">
              <input posInput type="number" min="0" id="servingsPerContainer" class="form-control" name="servingsPerContainer"
                #servingsPerContainer="ngModel" [(ngModel)]="servingInformationData.ServingsPerContainer" />
            </pos-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
