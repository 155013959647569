import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Track } from '../../interface/track';
import {
  AlertsService, SpinnerService, ModalService, PrintTableService, ApplicationStateService,
  BaseListComponent, Messages, ConfirmDeleteModalComponent, music,
  Levels, Permissions,
  RuntimeConstants,
  editWhite,
  deleteWhite,
  pauseGreen,
  playGreenNormalFont,
  plusWhite,
  AuthenticationService,
  AudioComponent
} from 'src/app/shared';
import { TracksService } from '../../services/tracks.service';
import { GridColumn, TableComponent, TemplateColumn } from '@tarktech/tark-ng-utils';
import * as _ from 'lodash';
import { finalize } from 'rxjs/operators';
import { PlaylistTrack } from 'src/app/configurator/playlists/interface/playlist-track';
import { PlaylistsService } from 'src/app/configurator/playlists/services/playlists.service';
import { forkJoin, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { find, forEach } from 'lodash';

@Component({
  selector: 'pos-tracks',
  templateUrl: './tracks.component.html',
})
export class TracksComponent extends BaseListComponent<Track> implements OnInit {

  tracksList: Array<Track> = [];
  trackListColumns: Array<GridColumn> = [];
  isEditTrack = false;
  playlistId = 0;
  playlistName = null;
  newPlaylistTracks: Array<Track> = [];
  @ViewChild('checkboxTemplate', { static: true }) checkboxTemplate: TemplateRef<any>;
  @ViewChild('operationTemplate', { static: true }) operationTemplate: TemplateRef<any>;
  @ViewChild('operationHeaderTemplate', { static: true }) operationHeaderTemplate: TemplateRef<any>;
  @ViewChild('tracks', { static: true }) tracks: TableComponent;
  icons = { editWhite, deleteWhite, music, playGreenNormalFont, pauseGreen, plusWhite };
  isTrackSelected = false;
  audioFilePath = `${RuntimeConstants.MEDIA_BASE_PATH}/DJ/tracks/`;
  permission = {
    name: Permissions.SystemConfigurationPlaylists,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };

  constructor(protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected tracksService: TracksService,
    protected printService: PrintTableService,
    protected applicationStateService: ApplicationStateService,
    private authenticationService: AuthenticationService,
    private playlistService: PlaylistsService,
    protected router: Router,
    protected route: ActivatedRoute) {
    super(tracksService, alertService, spinnerService, modalService, applicationStateService, printService, route);
    const navigation = router.getCurrentNavigation();
    this.playlistId = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
    this.playlistName = (navigation?.extras?.state && navigation?.extras?.state.playlistName) ? navigation?.extras?.state.playlistName : '';
    this.isEditTrack = (navigation?.extras?.state && navigation?.extras?.state.isEditTrack) ? navigation?.extras?.state.isEditTrack : false;
    this.newPlaylistTracks = (navigation?.extras?.state && navigation?.extras?.state.tracks) ? navigation?.extras?.state.tracks : [];
  }

  ngOnInit() {
    this.isPrintIconVisible = false;
    this.deleteSuccessMessage = Messages.TrackDeleted;
    this.trackListColumns = this.getGridColumns();
    this.tracks.context = this.gridContext;
  }

  reload() {
    this.spinnerService.show();
    const observable: Array<Observable<any>> = [];
    observable.push(this.tracksService.getAll());
    if (this.playlistId) {
      observable.push(this.playlistService.getPlaylistTracks(this.playlistId));
    }
    forkJoin(observable)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: ([tracks, playlistTracks]: [Array<Track>, Array<PlaylistTrack>]) => {
          this.tracksList = tracks == null ? [] : tracks;
          const playlistTrackList = playlistTracks == null ? [] : playlistTracks;
          _.remove(this.tracksList, (x) => {
            const track = _.find(playlistTrackList, pTrack => pTrack.Filename === x.Filename);
            if (track) {
              return track;
            }
          });
          _.forEach(this.tracksList, (track) => {
            track.Name = track.Filename.substring(19, track.Filename.length);
            if (this.newPlaylistTracks.length) {
              const newPlaylistTrack = find(this.newPlaylistTracks, newTrack => newTrack.Id === track.Id);
              if (newPlaylistTrack) {
                track.IsChecked = newPlaylistTrack.IsChecked;
                this.isTrackSelected = true;
              }
            }
          });
          this.tracksList = _.sortBy(this.tracksList, 'Name');
        },
        error: this.alertService.showApiError
      });
  }

  getGridColumns(): Array<GridColumn> {
    const checkboxColumn = new TemplateColumn({
      itemTemplate: this.checkboxTemplate,
      Width: '38px'
    });

    const operationTemplate = new TemplateColumn({
      itemTemplate: this.operationTemplate,
      Width: '125px',
      headerTemplate: this.operationHeaderTemplate,
      CellClass: 'cell-padding'
    });

    if (this.isEditTrack) {
      this.trackListColumns.push(checkboxColumn);
    }

    this.trackListColumns.push(
      new GridColumn({ HeaderText: 'Tracks', Field: 'Name', IsSortable: true, Width: '66%' }),
      new GridColumn({ HeaderText: 'Category', Field: 'Category', IsSortable: true, Width: '16%' }),
      new GridColumn({ HeaderText: 'Volume', Field: 'Volume', IsSortable: true, Width: '10%' })
    );

    if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      this.trackListColumns.push(operationTemplate);
    }

    return this.trackListColumns;
  }

  editItem(id: number, data?: Track): void {
    if (this.playlistId) {
      this.router.navigate(['playlist', this.playlistId, 'tracks', id], {
        state: {
          isEditTrack: this.isEditTrack,
          playlistName: this.playlistName
        }, relativeTo: this.route.parent
      });
    } else {
      this.router.navigate([id], { relativeTo: this.route });
    }
  }

  deleteTrack(data: Track): void {
    const modalRef = this.modalService.getModalWrapper(ConfirmDeleteModalComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      initialState: {
        message: Messages.ConfirmDeleteTrack
      }
    });
    modal.close.subscribe(res => {
      if (res && res.shouldDelete) {
        this.delete(data.Id);
      }
    });
  }

  getConfirmDeleteMessage(data: Track): string {
    return Messages.ConfirmDeleteTrack;
  }

  addTracks() {
    if (this.isTrackSelected) {
      const playlistTracks = [];
      _.forEach(this.tracksList, (track: Track) => {
        if (track.IsChecked) {
          const playlistTrack = {
            Id: 0,
            TrackId: track.Id,
            PlaylistId: this.playlistId,
            Ordinal: 0,
            Category: track.Category
          };
          playlistTracks.push(playlistTrack);
        }
      });
      if (playlistTracks.length > 0) {
        this.addTracksToList(playlistTracks);
      }
      this.isTrackSelected = false;
    } else {
      this.alertService.renderErrorMessage(Messages.SelectAtLeastOne);
    }
  }

  addTracksToList(playlistTracks: Array<PlaylistTrack>) {
    this.spinnerService.show();
    this.playlistService.addTracksToPlaylist(this.playlistId, playlistTracks)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: response => {
          this.router.navigate(['playlist/tracks', this.playlistId], {
            state: {
              playlistId: this.playlistId,
              playlistName: this.playlistName
            }, relativeTo: this.route.parent
          });
        }, error: this.alertService.showApiError
      });
  }

  getSelectedState() {
    this.isTrackSelected = false;
    _.forEach(this.tracksList, (track: Track) => {
      if (track.IsChecked) {
        this.isTrackSelected = true;
      }
    });
  }

  close() {
    if (this.playlistId) {
      this.router.navigate(['playlist/tracks', this.playlistId], {
        state: {
          playlistId: this.playlistId,
          playlistName: this.playlistName
        }, relativeTo: this.route.parent
      });
    } else {
      this.router.navigate(['/manage/system-configuration']);
    }
  }

  playAudio(data: Track) {
    data.IsPlaying = true;
    const modalRef = this.modalService.getModalWrapper(AudioComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center fit-content',
      initialState: {
        audioUrl: `${this.audioFilePath}${data.Filename}`,
        fileName: data.Name
      }
    });
    modal.close.subscribe({
      next: () => data.IsPlaying = false
    })
  }
}
