import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants';
import { HttpClient } from '@angular/common/http';
import { SettingParam } from 'src/app/shared';
import { Observable } from 'rxjs';
import { DrawerAmount } from 'src/app/manage-console/interfaces/drawer-amount';
import { Drawer } from 'src/app/manage-console/interfaces/drawer';
import { CashDrawerDetail } from 'src/app/manage-console/load-drawer';

@Injectable({
  providedIn: 'root'
})
export class CashDrawerService {

  cashDrawerApiBasePath = `${RuntimeConstants.API_PATH}cash-drawer`;

  constructor(private httpClient: HttpClient) { }

  newDrawer() {
    const drawer: Drawer = {
      DrawerId: 0,
      UserId: 0,
      Amount: 0,
      objParam: null,
      OneCentPrice: 0,
      FiveCentPrice: 0,
      TenCentPrice: 0,
      TwentyFiveCentPrice: 0,
      FiftyCentPrice: 0,
      OneDollarPrice: 0,
      FiveDollarPrice: 0,
      TenDollarPrice: 0,
      TwentyDollarPrice: 0,
      FiftyDollarPrice: 0,
      HundredDollarPrice: 0,
      ExceptedAmount: 0,
      TerminalId: 0,
      VarianceThreshold: 0,
      Note: ''
    };
    return drawer;
  }

  newDrawerAmount() {
    const drawerAmount: DrawerAmount = {
      RegisterId: 0,
      Price: 0,
      UserId: 0,
      objParam: null
    };
    return drawerAmount;
  }

  newDrawerDetail() {
    const drawerDetail: CashDrawerDetail = {
      AllAmount: 0,
      AllTips: 0,
      CardTerminalName: '',
      CashAmount: 0,
      CashDrawerPrinterName: '',
      CreditAmount: 0,
      CurrentVersion: '',
      DrawerName: '',
      TerminalName: '',
      DrawerAmountModel: []
    };
    return drawerDetail;
  }

  getCashDrawerDetail(terminalId: number): Observable<SettingParam> {
    return this.httpClient.get<SettingParam>(`${this.cashDrawerApiBasePath}/${terminalId}`);
  }

  saveCashDrawer(params: SettingParam): Observable<SettingParam> {
    return this.httpClient.post<SettingParam>(`${this.cashDrawerApiBasePath}`, params);
  }

  saveAmountInDrawer(drawerAmount: DrawerAmount) {
    return this.httpClient.post(`${this.cashDrawerApiBasePath}/save-amount-in-drawer`, drawerAmount);
  }

  closeOutCashDrawer(drawer: Drawer) {
    return this.httpClient.post(`${this.cashDrawerApiBasePath}/close-out-cash-drawer`, drawer);
  }
}
