export class MessagePayload {

    public SentOn: any;

    constructor() {
        const now = new Date();
        // Set Sent on time yyyy-MM-dd HH:mm:ss.fff
        this.SentOn = (now.getFullYear() +
            '-' + (now.getMonth() + 1) +
            '-' + now.getDate() +
            ' ' + now.getHours() +
            ':' + now.getMinutes() +
            ':' + now.getSeconds() +
            '.' + now.getMilliseconds());
    }
}
