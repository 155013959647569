<pos-edit-wrapper (close)="closeModal()" heading="{{selectedButtonBehaviorType.Text}}">
    <form #displayMessageForm="ngForm" autocomplete="off" (submit)='saveBehavior(displayMessageForm.valid)'>
        <div class="col-12 screen-scroll pos-modal-body">
            <pos-row class="g-0">
                <pos-column>
                    <pos-form-field label="Message" [validationMessages]="{required: 'Please enter display message.'}" inputStyle="col-sm-9 col-xs-12">
                        <textarea posInput class="form-control" name="message" placeholder="Enter display message"
                            [(ngModel)]="displayMessage" required type="text" #message="ngModel" rows="6"
                            [required]="true"></textarea>
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save</button>
            <button type="button" (click)="closeModal()" class="btn-secondary btn-lg btn btn-cancel-modal pos-button">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
