<div class="tab-top-border border-top-0"
    [ngStyle]="{'height':getHeight(false)}">
    <div class="col-md-8 col-sm-12 col-xs-12 position-relative">
        <div class="absolute ckb-active-zone pt-pix-17">
              <p-checkbox  name="Active" #Active="ngModel" (onChange)="filterActiveProduct()"
                [(ngModel)]="isActive" binary="true" label="Active" />
          </div>
        <div class="table-responsive">
            <ng-table [columns]="inventoryZoneEditProductsListColumns"
            [scrollable]="true" [scrollHeight]="height + 'px'" [data]="filteredProducts" [paginator]="false"
                [search]="true"></ng-table>
        </div>
    </div>
</div>
