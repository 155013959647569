<pos-edit-wrapper (close)="closeModal()" heading="{{selectedButtonBehaviorType.Text}}">
    <form #uncheckButtonForm="ngForm" autocomplete="off" (submit)='saveBehavior(uncheckButtonForm.valid)'>
        <div class="col-12 pos-modal-body screen-scroll">
            <pos-row class="g-0">
                <pos-column>
                    <pos-form-field label="Buttons"
                        inputStyle="col-12"
                        [validationMessages]="{required: 'Please select button(s).'}">
                        <ng-select posInput [items]="buttons" placeholder="Select button(s)" name="buttons"
                            bindLabel="ButtonScreen" bindValue="Id" [(ngModel)]="buttonIds" #buttonList="ngModel"
                            [multiple]="true" [hideSelected]="true" appendTo="body" *ngIf="!screenToggleButtons"
                            [required]="true">
                        </ng-select>
                        <ng-select [items]="screenToggleButtons" placeholder="Select button(s)" name="screenButtons"
                            bindLabel="ButtonText" bindValue="Id" [(ngModel)]="buttonIds" #screenButtons="ngModel"
                            [multiple]="true" [hideSelected]="true" appendTo="body" *ngIf="screenToggleButtons"
                            [required]="true">
                        </ng-select>
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-lg-12  action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save</button>
            <button type="button" (click)="closeModal()" class="btn-secondary btn-lg btn btn-cancel-modal pos-button">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
