<pos-list-wrapper [headingIcon]="icons.salesUnitsThLarge" heading="Sales Units" (close)="close()">
  <div class="row">
    <div class="col-12">
      <ng-table #salesUnitGrid [columns]="salesUnitColumns" [exportFilename]="'sales-units'" [data]="salesUnits"
        id="SalesUnits" [sort]="true">
      </ng-table>
    </div>
  </div>
</pos-list-wrapper>
<pos-print-table [hidden]="true"></pos-print-table>
