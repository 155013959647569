import { Component, OnInit, EventEmitter } from '@angular/core';
import { InventoryProductIngredientModel } from 'src/app/orders';
import { GridColumn, TemplateColumn } from '@tarktech/tark-ng-utils';

@Component({
  selector: 'pos-duplicate-ingredient-list',
  templateUrl: './duplicate-ingredient-list.component.html',
  styleUrls: ['./duplicate-ingredient-list.component.scss']
})
export class DuplicateIngredientListComponent implements OnInit {

  close: EventEmitter<any> = new EventEmitter();
  ingredients: Array<InventoryProductIngredientModel> = [];
  ingredientsColumns: Array<GridColumn> = [];
  constructor() { }

  ngOnInit() {
    this.configureGridColumns();
  }

  configureGridColumns() {
    this.ingredientsColumns = [
      new GridColumn({ HeaderText: 'Ingredient', Field: 'RawIngredient' }),
      new GridColumn({ HeaderText: 'Parent Ingredient', Field: 'ParentIngredient' })
    ] as Array<GridColumn>;
  }

  onSubmit() {
    this.close.emit();
  }

}
