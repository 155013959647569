import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GridColumn, TemplateColumn } from '@tarktech/tark-ng-utils';
import { filter, forEach, orderBy } from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { InventoryBinModel, InventoryZone } from 'src/app/information-management/inventory-zone';
import { Unit, UnitService } from 'src/app/information-management/units';
import { BaseFormComponent, SpinnerService } from 'src/app/shared/components';
import { Messages } from 'src/app/shared/constants';
import { AlertsService, FormUtilityService, InventoryProductService, InventoryZoneService } from 'src/app/shared/services';
import { InventoryProductBulkUpdate } from '../../interface/inventory-product-bulk-update';
import { inventoryProductsStore } from 'src/app/shared/components/icon';
import { NgForm } from '@angular/forms';
declare let $: any;

@Component({
  selector: 'pos-inventory-products-bulk-update',
  templateUrl: './inventory-products-bulk-update.component.html',
  styleUrls: ['./inventory-products-bulk-update.component.scss']
})
export class InventoryProductsBulkUpdateComponent extends BaseFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.bulkUpdateForm;
  }
  inventoryProductIds: Array<number> = [];
  inventoryProductsForBulkUpdate: Array<InventoryProductBulkUpdate> = [];
  inventoryZoneList: Array<InventoryZone> = [];
  inventoryBinList: Array<InventoryBinModel> = [];
  units: Array<Unit> = [];
  columns: Array<GridColumn> = [];
  icons = { inventoryProductsStore }

  @ViewChild('priceTemplate', { static: true }) private priceTemplate: TemplateRef<any>;
  @ViewChild('unitQtyTemplate', { static: true }) private unitQtyTemplate: TemplateRef<any>;
  @ViewChild('unitTemplate', { static: true }) private unitTemplate: TemplateRef<any>;
  @ViewChild('primaryZoneTemplate', { static: true }) private primaryZoneTemplate: TemplateRef<any>;
  @ViewChild('primaryBinTemplate', { static: true }) private primaryBinTemplate: TemplateRef<any>;
  @ViewChild('secondaryZoneTemplate', { static: true }) private secondaryZoneTemplate: TemplateRef<any>;
  @ViewChild('secondaryBinTemplate', { static: true }) private secondaryBinTemplate: TemplateRef<any>;
  @ViewChild('bulkUpdateForm') bulkUpdateForm: NgForm;
  constructor(private router: Router,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private inventoryZoneService: InventoryZoneService,
    private unitService: UnitService,
    private inventoryProductService: InventoryProductService,
    formUtilityService: FormUtilityService) {
    super(formUtilityService);
    const navigation = router.getCurrentNavigation();
    this.inventoryProductIds = (navigation?.extras?.state && navigation?.extras?.state.inventoryProductIds) ?
      navigation?.extras?.state.inventoryProductIds : [];
  }

  ngOnInit(): void {
    this.loadDependencies();
    this.prepareColumns();
  }

  loadDependencies() {
    this.spinnerService.show();
    const observables: Array<Observable<any>> = [];
    observables.push(this.inventoryZoneService.getInventoryZones());
    observables.push(this.inventoryZoneService.getInventoryBins(0));
    observables.push(this.inventoryProductService.getProductsForBulkUpdate(this.inventoryProductIds));
    observables.push(this.unitService.getUnits());
    forkJoin(observables)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: ([inventoryZones, inventoryBins, inventoryProducts, units]:
          [Array<InventoryZone>, Array<InventoryBinModel>, Array<InventoryProductBulkUpdate>, Array<Unit>]) => {
          this.inventoryZoneList = inventoryZones;
          this.inventoryBinList = inventoryBins;
          this.inventoryProductsForBulkUpdate = orderBy(inventoryProducts, 'Name');
          this.units = units;
          this.prepareDataForInventoryProducts();
        },
        error: this.alertService.showApiError
      });
  }

  prepareDataForInventoryProducts() {
    forEach(this.inventoryProductsForBulkUpdate, inventoryProduct => {
      this.setPrimaryBins(inventoryProduct);
      this.setSecondaryBins(inventoryProduct);
    });
    const timeout = setTimeout(() => {
      this.focusOnFirstElement();
      this.convertPricesToDecimal();
      if (timeout) {
        clearTimeout(timeout);
      }
    });
  }

  setPrimaryBins(inventoryProduct: InventoryProductBulkUpdate) {
    inventoryProduct.PrimaryBinList = filter(this.inventoryBinList, x => x.Zone_id === inventoryProduct.PrimaryZoneId);
  }

  setSecondaryBins(inventoryProduct: InventoryProductBulkUpdate) {
    inventoryProduct.SecondaryBinList = filter(this.inventoryBinList, x => x.Zone_id === inventoryProduct.SecondaryZoneId);
  }

  updateInventoryProducts(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.spinnerService.show();
    this.inventoryProductService.saveProductsForBulkUpdate(this.inventoryProductsForBulkUpdate)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.alertService.renderSuccessMessage(Messages.InventoryProductsUpdateSuccess);
          this.router.navigate([`manage/app-configuration/inventory-products`]);
        }, error: this.alertService.showApiError
      });
  }

  onCancel() {
    this.router.navigate([`manage/app-configuration/inventory-products`]);
  }

  prepareColumns() {
    this.columns = [
      new GridColumn({ HeaderText: 'Name', Field: 'Name', Width: '15%', IsSortable: true }),
      new TemplateColumn({ HeaderText: 'Price', Field: 'Price', Width: '10%', IsSortable: true, itemTemplate: this.priceTemplate }),
      new TemplateColumn({ HeaderText: 'Unit Qty', Field: 'UnitQty', Width: '10%', IsSortable: true, itemTemplate: this.unitQtyTemplate }),
      new TemplateColumn({ HeaderText: 'Unit', Field: 'Unit.Name', Width: '10%', IsSortable: true, itemTemplate: this.unitTemplate }),
      new TemplateColumn({
        HeaderText: 'Primary Zone', Field: 'PrimaryZone.Name', Width: '18%', IsSortable: true,
        itemTemplate: this.primaryZoneTemplate
      }),
      new TemplateColumn({
        HeaderText: 'Primary Bin', Field: 'PrimaryBin.Name', Width: '7%', IsSortable: true,
        itemTemplate: this.primaryBinTemplate
      }),
      new TemplateColumn({
        HeaderText: 'Secondary Zone', Field: 'SecondaryZone.Name', Width: '18%', IsSortable: true,
        itemTemplate: this.secondaryZoneTemplate
      }),
      new TemplateColumn({
        HeaderText: 'Secondary Bin', Field: 'SecondaryBin.Name', Width: '10%', IsSortable: true,
        itemTemplate: this.secondaryBinTemplate
      }),
    ];
  }

  focusOnFirstElement = () => {
    const firstElement = $('tr').eq(1).find('td').eq(1).find('input')[0];
    if (firstElement) {
      firstElement.focus();
    }
  }

  convertPricesToDecimal() {
    const inputs = $('input');
    forEach(inputs, (input) => {
      if (input.value && input?.id.includes('price')) {
        if (input.value || input.value == 0) {
          input.value = parseFloat(input.value).toFixed(2);
        }
      }
      if (isNaN(input.value)) {
        input.value = '0.00';
      }
    });
  }

}
