import { Injectable } from '@angular/core';
import { find, forEach } from 'lodash';
import { EventSubscriptionActionAttribute } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class EventActionCommonService {

  constructor() { }

  addActionAttributes(actionAttributes: Array<{ key: string, value: string }>, actionId: number,
    eventSubscriptionActionAttributes: Array<EventSubscriptionActionAttribute>) {
    const newSubscriptionActionAttributes: Array<EventSubscriptionActionAttribute> = [];
    forEach(actionAttributes, action => {

      const actionDeliveryAttribute: EventSubscriptionActionAttribute = {
        Id: this.getActionAttributeId(action.key, eventSubscriptionActionAttributes),
        AttributeKey: action.key,
        AttributeValue: action.value,
        EventSubscriptionActionId: actionId
      };
      if ((actionDeliveryAttribute.Id > 0) ||
        (actionDeliveryAttribute.Id === 0 && actionDeliveryAttribute.AttributeValue)) {
        newSubscriptionActionAttributes.push(actionDeliveryAttribute);
      }
    });
    return newSubscriptionActionAttributes;
  }

  getActionAttributeId(key, eventSubscriptionActionAttributes) {
    const attribute = find(eventSubscriptionActionAttributes, x => x.AttributeKey === key);
    return attribute ? attribute.Id : 0;
  }
}
