import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { ApplicationStateService, FormUtilityService } from 'src/app/shared';
import { ModalFormComponent, ModalService } from 'src/app/shared/components';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { WidgetProductGroups } from '../../interfaces/widget-product-group';
import { MenuWidgetService } from '../../services/menu-widget.service';
import { WidgetProductGroupService } from '../../services/widget-product-group.service';

@Component({
  selector: 'pos-widget-group-config',
  templateUrl: './widget-group-config.component.html',
  styleUrls: ['./widget-group-config.component.scss']
})
export class WidgetGroupConfigComponent extends ModalFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.widgetProductGroup;
  }

  widgetProductGroupId: number;
  widgetId: number;
  widgetGroup: WidgetProductGroups;
  groupConfig = {Description: ''};
  desc = '';
  @ViewChild('widgetProductGroup') widgetProductGroup: NgForm;
  currencySymbol = '$';

  constructor(widgetMenuService: MenuWidgetService,
    private spinnerService: SpinnerService,
    private widgetProductGroupService: WidgetProductGroupService,
    private alertService: AlertsService,
    widgetGroupModelRef: BsModalRef,
    modalService: ModalService,
    private applicationStateService: ApplicationStateService,
    formUtilityService: FormUtilityService) {
    super(widgetGroupModelRef, modalService, formUtilityService);
    this.widgetProductGroupId = 0;
    this.widgetGroup = widgetMenuService.getNewWidgetProduct();
    this.currencySymbol = applicationStateService.settingParam.CurrencySymbol;
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    if (this.widgetProductGroupId) {
      this.spinnerService.show();
      this.widgetProductGroupService.get(this.widgetProductGroupId).pipe(finalize(() => {
        this.spinnerService.hide();
      })).subscribe({ next: (response) => {
        this.widgetGroup = response;
        this.widgetGroup.GroupConfig = response?.GroupConfig ? JSON.stringify(JSON.parse(response?.GroupConfig), null, 2) : null;
        this.groupConfig = response?.GroupConfig ? JSON.parse(response?.GroupConfig) : { Description: '' };
      }, error: this.alertService.showApiError });
    }
  }

  save(isValid) {
    if (!isValid) {
      return;
    }
    this.widgetGroup.MenuWidgetId = this.widgetId;
    this.widgetGroup.GroupConfig = JSON.stringify(this.groupConfig);
    this.spinnerService.show();
    this.widgetProductGroupService.insertAndGet(this.widgetGroup).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({ next: (response: WidgetProductGroups) => {
      this.onSaveSuccess(response);
    }, error: this.alertService.showApiError });
  }

  onSaveSuccess(widgetProductGroup: WidgetProductGroups) {
    this.onCancel(true, widgetProductGroup);
    this.alertService.renderSuccessMessage(Messages.WidgetGroupSaveSuccess);
  }

  onCancel(reload = false, widgetProductGroup: WidgetProductGroups = null) {
    this.hide({ shouldReload: reload, widgetProductGroup: widgetProductGroup });
  }

}
