import { Injectable } from '@angular/core';
import { RabbitMQMessageService } from './rabbitmq-message.service';
import { RabbitMQExchangeFactoryService } from './rabbitmq-exchange-factory.service';
import { Observable } from 'rxjs';
import { RabbitMQMessage } from '../models/rabbitmq-message';

import { exhaustAll, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { ActiveOrderItemRabbitMQPayload, ActiveOrderRabbitMQPayload, AudioRecordingsForOrderPayload, AudioRecordingsPayload, OrderIdRabbitMQPayload, SalesProductUpdatedPayload } from 'src/app/shared/interface';
import { DJTerminalSpeakTextPayload } from '../models/djterminal-speak-text-payload';
import { InboxMessage } from 'src/app/inbox-messages/interface/inbox-messages';
import { RxStompService } from './rx-stomp.service';
import { ExchangeInfo } from '../models';
import { OrderKioskStateUpdatedPayload } from '../models/order-kiosk-state-updated-payload';

@Injectable({
    providedIn: 'root'
})
export class RabbitMQService {

    constructor(private rxStompService: RxStompService,
        private rabbitMQMessageService: RabbitMQMessageService,
        private rabbitMQExchangeFactoryService: RabbitMQExchangeFactoryService) {
    }
    activeSubscriptions = [];

    // Subscribes to maketable specific queue for pickscreen (terminals.pickscreen/{terminalId})
    subscribeToOrderUpdatesFromMakeTable$(makeTableTerminalId): Observable<RabbitMQMessage<ActiveOrderItemRabbitMQPayload>> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getMakeTableActivityExchangeByMakeTable(makeTableTerminalId);
        return this.subscribe<RabbitMQMessage<ActiveOrderItemRabbitMQPayload>>(exchangeInfo);
    }

    // Subscribes to pickscreen exchange of all maketables(terminals.pickscreen)
    subscribeToOrderUpdatesFromAllMakeTables$(): Observable<RabbitMQMessage<ActiveOrderItemRabbitMQPayload>> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getMakeTableActivityExchangeForAllMakeTables();
        return this.subscribe<RabbitMQMessage<ActiveOrderItemRabbitMQPayload>>(exchangeInfo);
    }

    subscribeToTimerTerminalBroadcastExchange$(): Observable<RabbitMQMessage<any>> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getTimerTerminalBroadcastExchange();
        return this.subscribe<RabbitMQMessage<any>>(exchangeInfo);
    }

    subscribeToUserTrainingBadgeExchange$(): Observable<RabbitMQMessage<any>> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getUserTrainingBadgeExchange();
        return this.subscribe<RabbitMQMessage<any>>(exchangeInfo);
    }

    subscribe<T>(exchangeInfo): Observable<T> {
        return this.rxStompService.watch(exchangeInfo.getUri())
            .pipe(map(e => JSON.parse(e.body)));
    }

    subscribeLegacy(exchangeInfo, subscriptionCallback, successCallback, errorCallback) {
        const subscription = this.rxStompService.watch(exchangeInfo.getUri()).subscribe({
            next: (e) => {
                const message = JSON.parse(e.body);
                console.log('message', message);
                if (subscriptionCallback) {
                    subscriptionCallback(message);
                }
            }
        });

        this.activeSubscriptions.push(subscription);
        if (successCallback) {
            successCallback(subscription);
        }
    }

    // Subscribes to order specific queue for orderscreen (terminals.invoice/{orderEntryTerminalId})
    subscribeToInvoiceExchange$(orderEntryTerminalId): Observable<RabbitMQMessage<any>> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getInvoiceExchange(orderEntryTerminalId);
        return this.subscribe<RabbitMQMessage<any>>(exchangeInfo);
    }

    // Subscribes to order entry terminal exchange (terminals.orderentry/{terminalId})
    subscribeToOrderPaymentResponseTerminalQueue$(terminalId): Observable<RabbitMQMessage<any>> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getOrderEntryTerminalExchange(terminalId);
        return this.subscribe<RabbitMQMessage<any>>(exchangeInfo);
    }

    // Subscribes to delete order  (terminals.maketable/{orderEntryTerminalId})
    subscribeToMakeTableExchange$(orderEntryTerminalId): Observable<RabbitMQMessage<ActiveOrderRabbitMQPayload>> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getMakeTableExchange(orderEntryTerminalId);
        return this.subscribe<RabbitMQMessage<ActiveOrderRabbitMQPayload>>(exchangeInfo);
    }

    // Subscribes to pickscreen exchange of all maketables(hic.response/{terminalId})
    subscribeToHICResponseToTerminal$(terminalId): Observable<RabbitMQMessage<any>> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getHICResponseToTerminalExchange(terminalId);
        return this.subscribe<RabbitMQMessage<any>>(exchangeInfo);
    }

    // Subscribes to event activity exchange
    subscribeToActivityExchange$(service, activityType): Observable<RabbitMQMessage<any>> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getActivityReportingExchange(
            this.convertToValidRabbitMQAddress(service),
            this.convertToValidRabbitMQAddress(activityType)
        );
        return this.subscribe<RabbitMQMessage<any>>(exchangeInfo);
    }

    // Subscribes to SalesProductOutOfStockEvent exchange.
    subscribeToSalesProductOutOfStockEventExchange$(): Observable<RabbitMQMessage<any>> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getSalesProductOutOfStockEventUpdate();
        return this.subscribe<RabbitMQMessage<any>>(exchangeInfo);
    }

    // Subscribes to SalesProductOutOfStockEvent exchange.
    subscribeToSalesProductReplenishedEventExchange$(): Observable<RabbitMQMessage<any>> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getSalesProductReplenishedEventUpdate();
        return this.subscribe<RabbitMQMessage<any>>(exchangeInfo);
    }

    // Subscribes to CashDrawerClosedEvent exchange.  
    subscribeToCashDrawerClosedEventExchange$(): Observable<RabbitMQMessage<any>> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getCloseCashDrawerEventUpdate();
        return this.subscribe<RabbitMQMessage<any>>(exchangeInfo);
    }

    // Subscribes to CashDrawerOpenedEvent exchange.
    subscribeToCashDrawerOpenedEventExchange$(): Observable<RabbitMQMessage<any>> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getCashDrawerOpenedEventUpdate();
        return this.subscribe<RabbitMQMessage<any>>(exchangeInfo);
    }


    // Subscribes to InventoryExhaustionEvent exchange.
    subscribeToInventoryExhaustionEventExchange$(): Observable<RabbitMQMessage<any>> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getInventoryExhaustionEventUpdate();
        return this.subscribe<RabbitMQMessage<any>>(exchangeInfo);
    }

    convertToValidRabbitMQAddress(text) {
        if (text === '' || text === '*' || text === '#') {
            return text;
        }
        return text.replace(/[^a-zA-Z ]/g, '').replace(/ /g, '.').toLowerCase();
    }

    sendConfirmTerminalUseMessage(destinationTerminalId, terminalId, terminalName, terminalType) {
        const rabbitMQMessage = this.rabbitMQMessageService.getTerminalInfoMessage(terminalId, terminalName, terminalType);
        this.sendMessageToTerminalUseResponse(destinationTerminalId, rabbitMQMessage);
    }

    // Send message to terminal specific queue (terminals/{terminalId})
    sendMessageToTerminalUseResponse(destinationTerminalId, message) {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getTerminalUseResponseExchange(destinationTerminalId);
        this.sendMessage(exchangeInfo, message);
    }

    sendOrderKioskStateUpdatedMessage = (terminalId: number, state: boolean) => {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getOrderKioskStateChangeExchange();
        const rabbitMQMessage = this.rabbitMQMessageService.getOrderKioskStateUpdatedMessage(terminalId, state);
        this.sendMessage(exchangeInfo, rabbitMQMessage);
    }

    // Send message to pickscreen exchange of make table
    sendOrderUpdateFromMakeTable(makeTableTerminalId, orderProductDetails) {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getMakeTableActivityExchangeByMakeTable(makeTableTerminalId);
        const rabbitMQMessage = this.rabbitMQMessageService.getOrderProductDetailsMessageForPickScreen(
            makeTableTerminalId,
            orderProductDetails
        );
        this.sendMessage(exchangeInfo, rabbitMQMessage);
    }

    // Send message to Invoice exchange of order
    sendMessageToInvoiceExchange(orderEntryTerminalId, orderDetails) {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getInvoiceExchange(orderEntryTerminalId);
        let rabbitMQMessage;
        rabbitMQMessage = this.rabbitMQMessageService.getOrderDetailsMessage(orderEntryTerminalId, orderDetails);
        this.sendMessage(exchangeInfo, rabbitMQMessage);
    }

    sendOrderReviewedMessage(orderEntryTerminalId, orderDetails) {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getOrderReviewedExchange(orderEntryTerminalId);
        let rabbitMQMessage;
        rabbitMQMessage = this.rabbitMQMessageService.getOrderReviewedMessage(orderEntryTerminalId, orderDetails);
        this.sendMessage(exchangeInfo, rabbitMQMessage);
    }

    sendReviewOrderMessage(orderEntryTerminalId, orderData, seats) {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getReviewOrderExchange(orderEntryTerminalId);
        const rabbitMQMessage = this.rabbitMQMessageService.getReviewOrderMessage(orderEntryTerminalId, orderData, seats);
        this.sendMessage(exchangeInfo, rabbitMQMessage);
    }

    sendCloseReviewOrderMessage(orderEntryTerminalId, isConfirmed) {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getCloseReviewOrderModalExchange(orderEntryTerminalId);
        const rabbitMQMessage = this.rabbitMQMessageService.getCloseReviewOrderModalMessage(orderEntryTerminalId, isConfirmed);
        this.sendMessage(exchangeInfo, rabbitMQMessage);
    }

    sendOrderIsOpenCheckMessage(sourceTerminalId, sourceTerminalName, orderId) {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getOrderIsOpenCheckExchange();
        const message = this.rabbitMQMessageService.getOrderIsOpenCheckMessage(sourceTerminalId, sourceTerminalName, orderId);

        this.sendMessage(exchangeInfo, message);
    }

    sendOrderAlreadyOpenCheckMessage(sourceTerminalId, sourceTerminalName, destinationTerminalId, orderId) {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getOrderAlreadyOpenExchange(destinationTerminalId);
        const message = this.rabbitMQMessageService.getOrderAlreadyOpenCheckMessage(
            sourceTerminalId,
            sourceTerminalName,
            destinationTerminalId,
            orderId
        );

        this.sendMessage(exchangeInfo, message);
    }

    sendOrderCloseRequestMessage(sourceTerminalId, sourceTerminalName, destinationTerminalId, orderId) {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getOrderCloseRequestExchange(destinationTerminalId);
        const message = this.rabbitMQMessageService.getOrderCloseRequestMessage(
            sourceTerminalId,
            sourceTerminalName,
            destinationTerminalId,
            orderId
        );

        this.sendMessage(exchangeInfo, message);
    }

    sendCameraAnnotationMessageToHIC(terminalId, requestType, hicGuid, hardwareId, cameraAnnotationText) {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getHICRequestExchange(hicGuid, requestType, hardwareId);
        const message = this.rabbitMQMessageService.getHICRequestMessage(hardwareId, terminalId, "", 1, cameraAnnotationText);
        this.sendMessage(exchangeInfo, message);
    }

    sendOrderProductIsCraftingMessage(sourceTerminalId, orderId, orderProductId, itemSelectedOn) {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getInstructionsTerminalBroadcastExchange();
        const rabbitMQMessage = this.rabbitMQMessageService.getOrderProductIsCraftingMessage(
            sourceTerminalId,
            orderId,
            orderProductId,
            itemSelectedOn
        );
        this.sendMessage(exchangeInfo, rabbitMQMessage);
    }

    sendFilterMenuItemsMessage(sourceTerminalId: number, dietaryWarnings: Array<number>, tags: Array<number>, widgets?: Array<number>, actionType: string = null) {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getMenuDisplayFilterExchange();
        const message = this.rabbitMQMessageService.getFilterMenuItemsMessage(sourceTerminalId, dietaryWarnings, tags, widgets, actionType);

        this.sendMessage(exchangeInfo, message);
    }

    sendHighlightMenuItemsMessage(sourceTerminalId: number, productId: number, actionType: string = null) {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getMenuDisplayHighlightItemExchange();
        const message = this.rabbitMQMessageService.getHighlightMenuItemsMessage(sourceTerminalId, productId, actionType);

        this.sendMessage(exchangeInfo, message);
    }

    sendRefreshMenuDisplayMessage(sourceTerminalId: number, menuDisplayId: number) {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getRefreshMenuDisplayExchange();
        const message = this.rabbitMQMessageService.getRefreshMenuDisplayMessage(sourceTerminalId, menuDisplayId);

        this.sendMessage(exchangeInfo, message);
    }

    sendRefreshDashBoardMessage() {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getRefreshDashboardExchange();
        const message = this.rabbitMQMessageService.getRefreshDashboardMessage();

        this.sendMessage(exchangeInfo, message);
    }

    sendRefreshTerminalMessage(terminalId: number) {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getTerminalRefreshExchange(terminalId);
        const message = this.rabbitMQMessageService.getRefreshTerminalMessage();

        this.sendMessage(exchangeInfo, message);
    }

    sendAutoSignOutUserMessage(sourceTerminalId: number, sourceTerminalName: string, userId: number, eventName: string) {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getAutoSignOutUserExchange();
        const message = this.rabbitMQMessageService.getAutoSignOutUserMessage(
            sourceTerminalId,
            sourceTerminalName,
            userId,
            eventName,
        );

        this.sendMessage(exchangeInfo, message);
    }

    sendMessage(exchangeInfo, message) {
        const self = this;

        this.sendMessageToRabbitMQ(exchangeInfo, message);
    }

    sendMessageToRabbitMQ(exchangeInfo, message) {
        const self = this;
        try {
            const now = new Date();
            if (!message.Payload.SentOn) {
                message.Payload.SentOn = (now.getFullYear() +
                    '-' + (now.getMonth() + 1) +
                    '-' + now.getDate() +
                    ' ' + now.getHours() +
                    ':' + now.getMinutes() +
                    ':' + now.getSeconds() +
                    '.' + now.getMilliseconds());
            }
            this.rxStompService.publish({ destination: exchangeInfo.getUri(), body: JSON.stringify(message) });
            console.log(message);
            //self.sendMessageClient.send(exchangeInfo.getUri(), {}, JSON.stringify(message));
        } catch (err) {
            if (err.message === 'INVALID_STATE_ERR') {

                setTimeout(() => {
                    self.sendMessage(exchangeInfo, message);
                }, 1000);
                return;
            }
        }
    }

    sendRpcResponse(replyTo: string, responseBody: string, correlationId: string) {
        this.rxStompService.publish({
            destination: replyTo,
            body: responseBody,
            headers: { 'correlation-id': correlationId }
        });
    }

    guid() {
        return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + this.s4() + this.s4();
    }

    guidWithTimeStamp() {
        return this.guid() + new Date().getTime();
    }

    s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }


    // Make table order delete

    subscribeToOrderDeletedMessage$(orderEntryTerminalId): Observable<RabbitMQMessage<OrderIdRabbitMQPayload>> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getOrderDeletedExchange(orderEntryTerminalId);
        return this.subscribe<RabbitMQMessage<OrderIdRabbitMQPayload>>(exchangeInfo);
    }

    subscribeToOrderDeletedMessageForAllOrderEntry$(): Observable<RabbitMQMessage<OrderIdRabbitMQPayload>> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getOrderDeletedForAllOrderEntryExchange();
        return this.subscribe<RabbitMQMessage<OrderIdRabbitMQPayload>>(exchangeInfo);
    }

    // Order Serve Messages
    subscribeToOrderServedMessage$(): Observable<RabbitMQMessage<OrderIdRabbitMQPayload>> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getOrderServedForAllOrderEntryExchange();
        return this.subscribe<RabbitMQMessage<OrderIdRabbitMQPayload>>(exchangeInfo);
    }

    // Order Updated Messages
    subscribeToOrderUpdatedMessage$(orderEntryTerminalId): Observable<RabbitMQMessage<ActiveOrderRabbitMQPayload>> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getOrderUpdatedExchange(orderEntryTerminalId);
        return this.subscribe<RabbitMQMessage<ActiveOrderRabbitMQPayload>>(exchangeInfo);
    }

    subscribeToOrderUpdatedMessageForAllOrderEntry$(): Observable<RabbitMQMessage<ActiveOrderRabbitMQPayload>> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getOrderUpdatedForAllOrderEntryExchange();
        return this.subscribe<RabbitMQMessage<ActiveOrderRabbitMQPayload>>(exchangeInfo);
    }

    // Order Item Delete
    subscribeToOrderItemDeletedMessage$(): Observable<RabbitMQMessage<ActiveOrderRabbitMQPayload>> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getOrderItemDeleteExchange();
        return this.subscribe<RabbitMQMessage<ActiveOrderRabbitMQPayload>>(exchangeInfo);
    }

    // Order Item State change
    subscribeToOrderItemStateChangedMessage$(orderEntryTerminalId): Observable<RabbitMQMessage<ActiveOrderRabbitMQPayload>> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getOrderItemStateChangedExchange(orderEntryTerminalId);
        return this.subscribe<RabbitMQMessage<ActiveOrderRabbitMQPayload>>(exchangeInfo);
    }

    subscribeToOrderItemStateChangedForAllOrderEntryMessage$(): Observable<RabbitMQMessage<ActiveOrderRabbitMQPayload>> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getOrderItemStateChangedAllOrderEntryExchange();
        return this.subscribe<RabbitMQMessage<ActiveOrderRabbitMQPayload>>(exchangeInfo);
    }

    // Order Item Qty Changed
    subscribeToOrderItemQtyChangedMessage$(): Observable<RabbitMQMessage<ActiveOrderRabbitMQPayload>> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getOrderItemQtyChangedExchange();
        return this.subscribe<RabbitMQMessage<ActiveOrderRabbitMQPayload>>(exchangeInfo);
    }

    // Terminal is in use
    subscribeToTerminalUseResponseMessage$(terminalId): Observable<RabbitMQMessage> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getTerminalUseResponseExchange(terminalId);
        return this.subscribe<RabbitMQMessage>(exchangeInfo);
    }

    subscribeToOrderKioskStateUpdatedMessage$ = (): Observable<RabbitMQMessage> => {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getOrderKioskStateChangeExchange();
        return this.subscribe<RabbitMQMessage<OrderKioskStateUpdatedPayload>>(exchangeInfo);
    }

    subscribeToTerminalUseMessage$(): Observable<RabbitMQMessage> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getTerminalUseExchange();
        return this.subscribe<RabbitMQMessage>(exchangeInfo);
    }

    subscribeToTerminalAudioRecordings$(terminalId: number): Observable<RabbitMQMessage<AudioRecordingsPayload>> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getAudioRecordingsExchange(terminalId);
        return this.subscribe<RabbitMQMessage<AudioRecordingsPayload>>(exchangeInfo);
    }

    subscribeToAudioRecordingsForOrder$(terminalId: number): Observable<RabbitMQMessage<AudioRecordingsForOrderPayload>> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getAudioRecordingsForOrderExchange(terminalId);
        return this.subscribe<RabbitMQMessage<AudioRecordingsForOrderPayload>>(exchangeInfo);
    }

    subscribeToTerminalAudioFile$(terminalId: number): Observable<RabbitMQMessage> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getAudioFileExchange(terminalId);
        return this.subscribe<RabbitMQMessage>(exchangeInfo);
    }

    subscribeToTerminalLogoutMessage$(terminalId): Observable<RabbitMQMessage> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getTerminalLogoutExchange(terminalId);
        return this.subscribe<RabbitMQMessage>(exchangeInfo);
    }

    // Label print param change
    subscribeToProductLabelPrintingParameterMessage$(): Observable<RabbitMQMessage> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getProductLabelPrintingParameterExchange();
        return this.subscribe<RabbitMQMessage>(exchangeInfo);
    }

    // Refresh active order    
    subscribeToRefreshActiveOrderMessage$(): Observable<RabbitMQMessage> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getRefreshActiveOrderExchange();
        return this.subscribe<RabbitMQMessage>(exchangeInfo);
    }

    // Global logoff
    subscribeToGlobalLogoffMessage$(): Observable<RabbitMQMessage<RabbitMQMessage>> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getGlobalLogoffExchange();
        return this.subscribe<RabbitMQMessage<RabbitMQMessage>>(exchangeInfo);
    }

    // User Clock Out
    subscribeToUserClockOutMessage$(): Observable<RabbitMQMessage> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getClockOutExchange();
        return this.subscribe<RabbitMQMessage>(exchangeInfo);
    }

    subscribeToUserClockInMessage$(): Observable<RabbitMQMessage> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getClockInEventExchange();
        return this.subscribe<RabbitMQMessage>(exchangeInfo);
    }

    // Terminal message
    subscribeToTerminalInboxMessage$(): Observable<RabbitMQMessage<InboxMessage>> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getTerminalMessageExchange();
        return this.subscribe<RabbitMQMessage<InboxMessage>>(exchangeInfo);
    }

    subscribeToTerminalMessageMarkAsReadMessage$(): Observable<RabbitMQMessage<InboxMessage>> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getTerminalMessageMarkAsReadExchange();
        return this.subscribe<RabbitMQMessage<InboxMessage>>(exchangeInfo);
    }

    // Changed parameters
    subscribeToRequireNameOnOrderTemporaryDisableParameterChangeMessage$(): Observable<RabbitMQMessage> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getRequireNameOnOrderTemporaryDisableParameterExchange();
        return this.subscribe<RabbitMQMessage>(exchangeInfo);
    }

    // DJ Terminal
    subscribeToDJTerminalSpeakTextMessage$(terminalId): Observable<RabbitMQMessage<DJTerminalSpeakTextPayload>> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getDJSpeakTextExchange(terminalId);
        return this.subscribe<RabbitMQMessage<DJTerminalSpeakTextPayload>>(exchangeInfo);
    }

    // Order Invoice messages
    subscribeToUpdateScheduleOrderMessage$(): Observable<RabbitMQMessage> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getScheduleOrderUpdateExchange();
        return this.subscribe<RabbitMQMessage>(exchangeInfo);
    }

    subscribeToOrderAlreadyOpenMessage$(terminalId): Observable<RabbitMQMessage> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getOrderAlreadyOpenExchange(terminalId);
        return this.subscribe<RabbitMQMessage>(exchangeInfo);
    }

    subscribeToOrderCloseRequestMessage$(terminalId): Observable<RabbitMQMessage> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getOrderCloseRequestExchange(terminalId);
        return this.subscribe<RabbitMQMessage<any>>(exchangeInfo);
    }
    subscribeOrderIsOpenCheckMessage$(): Observable<RabbitMQMessage> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getOrderIsOpenCheckExchange();
        return this.subscribe<RabbitMQMessage>(exchangeInfo);
    }

    subscribeToOrderReviewedMessage$(orderEntryTerminalId): Observable<RabbitMQMessage<any>> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getOrderReviewedExchange(orderEntryTerminalId);
        return this.subscribe<RabbitMQMessage<any>>(exchangeInfo);
    }

    subscribeToReviewOrderMessage$(orderEntryTerminalId): Observable<RabbitMQMessage<any>> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getReviewOrderExchange(orderEntryTerminalId);
        return this.subscribe<RabbitMQMessage<any>>(exchangeInfo);
    }

    subscribeToCloseReviewOrderModalMessage$(orderEntryTerminalId): Observable<RabbitMQMessage<any>> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getCloseReviewOrderModalExchange(orderEntryTerminalId);
        return this.subscribe<RabbitMQMessage<any>>(exchangeInfo);
    }

    //HIC CC Message
    subscribeToHICCreditCardTransactionResponseMessage$(): Observable<RabbitMQMessage<any>> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getHICCreditCardTransactionResponseExchange();
        return this.subscribe<RabbitMQMessage<any>>(exchangeInfo);
    }


    // Terminal Warnings
    subscribeToTerminalWarningMessage$(): Observable<RabbitMQMessage> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getTerminalWarningExchange();
        return this.subscribe<RabbitMQMessage>(exchangeInfo);
    }

    // Menu display
    subscribeToRefreshMenuDisplayMessage$(): Observable<RabbitMQMessage> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getRefreshMenuDisplayExchange();
        return this.subscribe<RabbitMQMessage>(exchangeInfo);
    }

    subscribeToMenuDisplayItemHighlightedMessage$(): Observable<RabbitMQMessage> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getMenuDisplayHighlightItemExchange();
        return this.subscribe<RabbitMQMessage>(exchangeInfo);
    }

    subscribeToMenuDisplayFilteredMessage$(): Observable<RabbitMQMessage> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getMenuDisplayFilterExchange();
        return this.subscribe<RabbitMQMessage>(exchangeInfo);
    }

    subscribeToDashboardConfiguration$(): Observable<RabbitMQMessage> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getRefreshDashboardExchange();
        return this.subscribe<RabbitMQMessage>(exchangeInfo);
    }

    subscribeToRefreshTerminal$(terminalId: number): Observable<RabbitMQMessage> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getTerminalRefreshExchange(terminalId);
        return this.subscribe<RabbitMQMessage>(exchangeInfo);
    }

    subscribeToAutoSignOutUserMessage$(): Observable<RabbitMQMessage> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getAutoSignOutUserExchange();
        return this.subscribe<RabbitMQMessage>(exchangeInfo);
    }

    subscribeToTerminalStatusCheck$(terminalId: number) {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getTerminalsStatusCheckInfo(terminalId);
        return this.subscribeToRpcServer$(exchangeInfo)
    }

    subscribeToRpcServer$(exchangeInfo: ExchangeInfo) {
        return this.rxStompService.watch(exchangeInfo.getUri());
    }

    subscribeToSalesProductUpdatedMessage$(): Observable<RabbitMQMessage<any>> {
        const exchangeInfo = this.rabbitMQExchangeFactoryService.getSalesProductChangedExchange();
        return this.subscribe<RabbitMQMessage<SalesProductUpdatedPayload>>(exchangeInfo);
    }
}
