import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { WorkScheduleTag } from '../../interface';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { WorkScheduleTagService } from 'src/app/shared/services/work-schedule-tag.service';
import { BaseCrudModelComponent } from 'src/app/shared/components';
import { NgForm } from '@angular/forms';
import { FormUtilityService, Messages } from 'src/app/shared';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'pos-work-schedule-tag-edit',
    templateUrl: './work-schedule-tag-edit.component.html',
    styleUrls: ['./work-schedule-tag-edit.component.scss']
})
export class WorkScheduleTagEditComponent extends BaseCrudModelComponent<WorkScheduleTag> implements OnInit {

    workScheduleTag: WorkScheduleTag;
    @Input('id') id = 0;
    @ViewChild('tagForm', { static: true }) tagForm: NgForm;
    @Output() saveTag = new EventEmitter();
    get getForm(): NgForm {
        return this.tagForm;
    }
    constructor(
        protected alertService: AlertsService,
        protected spinnerService: SpinnerService,
        protected workScheduleTagService: WorkScheduleTagService,
        formUtilityService: FormUtilityService
    ) {
        super(workScheduleTagService, alertService, spinnerService, formUtilityService);
    }

    ngOnInit() {
        this.workScheduleTag = this.workScheduleTagService.newWorkScheduleTag();
        this.saveSuccessMessage = Messages.TagSavedSuccess;
        this.dataSubscription();
    }

    submit(isValid: boolean): void {
        if (!isValid) {
            return;
        }
        this.save(this.workScheduleTag);
    }

    private dataSubscription(): void {
        if (this.id > 0) {
            this.spinnerService.show();
            this.loadData().pipe(finalize(() => {
                this.spinnerService.hide();
            }))
                .subscribe({
                    next: (res) => {
                        this.workScheduleTag = res;
                    }, error: this.alertService.showApiError
                });
        }
    }

    public loadDependencies(): void {
    }

    public onSaveSuccess(model: WorkScheduleTag): void {
        this.hide({ reload: true });
    }

    cancelEditing(): void {
        this.hide({ reload: false });
    }

    private reset(): void {
        this.id = 0;
    }

}
