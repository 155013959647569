import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { forEach } from 'lodash';
import { OrderInvoice } from 'src/app/orders/interface';
import { DomainConstants } from 'src/app/shared';

@Component({
  selector: 'pos-order-summary-total',
  templateUrl: './order-summary-total.component.html',
  styleUrls: ['./order-summary-total.component.scss']
})
export class OrderSummaryTotalComponent implements OnInit, OnChanges {

  @Input() orderData: OrderInvoice;
  @Input() refundedOrderAmount: number;
  totalDiscount: number = 0;
  totalMarkup: number = 0;
  orderItemTypes = DomainConstants.OrderItemTypes;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.totalDiscount = 0;
    this.prepareDiscountAndMarkup();
  }

  prepareDiscountAndMarkup() {
    let discount = 0;
    let markup = 0;
    forEach(this.orderData.OrderItems, (item) => {
      if (item.OrderItemType === this.orderItemTypes.Discount) {
        discount += Math.abs(item.Price);
      }
      if (item.OrderItemType === this.orderItemTypes.Markup) {
        markup += item.Price;
      }
    });
    this.totalDiscount = this.orderData.DiscountAmount + discount;
    this.totalMarkup = this.orderData.MarkupAmount + markup;
  }
}
