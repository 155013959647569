import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs';
import { ModalFormComponent, ModalService, SpinnerService } from 'src/app/shared/components';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { AlertsService, FormUtilityService, MultiProductGroupService } from 'src/app/shared/services';
import { MultiProductGroup } from './multi-product-group';


@Component({
  selector: 'pos-multi-product-group',
  templateUrl: './multi-product-group.component.html',
  styleUrls: ['./multi-product-group.component.scss']
})
export class MultiProductGroupComponent extends ModalFormComponent implements OnInit {

  @Input('id') id: number = 0;
  name: string = '';
  @ViewChild('multiProductGroupForm') multiProductGroupForm: NgForm;
  get getForm(): NgForm {
    return this.multiProductGroupForm;
  }
  constructor(private multiProductGroupService: MultiProductGroupService,
    private alertService: AlertsService,
    groupModalRef: BsModalRef,
    modalService: ModalService,
    private spinnerService: SpinnerService,
    formUtilityService: FormUtilityService) {
    super(groupModalRef, modalService, formUtilityService);
  }

  ngOnInit(): void {
  }


  saveMultiProductGroup(isValid) {
    if (!isValid) {
      return;
    }
    this.spinnerService.show();
    const group = { Id: this.id, Name: this.name } as MultiProductGroup;

    if (this.id > 0) {
      this.multiProductGroupService.update(group)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe(() => {
          this.spinnerService.hide();
          this.alertService.renderSuccessMessage(Messages.MultiProductGroupSaved);
          this.cancel();
        }, this.alertService.showApiError);
    }
    else {
      this.multiProductGroupService.insert(group)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe((res) => {
          this.spinnerService.hide();
          this.alertService.renderSuccessMessage(Messages.MultiProductGroupSaved);
          this.hide({ shouldReload: true, id: res.Id });
        }, this.alertService.showApiError);
    }

  }
  cancel() {
    this.hide({ shouldReload: true });
  }
}
