import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SalesProduct } from '../../interface/sales-product';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { SalesProductsService } from '../../services/sales-products.service';
import { DatePipe } from '@angular/common';
import { finalize } from 'rxjs/operators';
import { ApplicationStateService, FormUtilityService, Messages, ModalFormComponent, ModalService } from 'src/app/shared';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'pos-merge-sales-product',
    templateUrl: './merge-sales-product.component.html',
    styleUrls: ['./merge-sales-product.component.scss']
})
export class MergeSalesProductComponent extends ModalFormComponent implements OnInit {
    get getForm(): NgForm {
        return this.mergeProductForm;
    }

    @Input('salesProductId') salesProductId: number;
    @Input('productName') productName: string;
    @Input('dateAdded') dateAdded: Date;
    @ViewChild('mergeProductForm') mergeProductForm: NgForm;

    salesProducts: Array<SalesProduct>;
    selectedProduct: SalesProduct  = null;

    constructor(
        private alertService: AlertsService,
        private spinnerService: SpinnerService,
        private salesProductsService: SalesProductsService,
        private applicationStateService: ApplicationStateService,
        private datePipe: DatePipe,
        public modalRef: BsModalRef,
        modalService: ModalService,
        formUtilityService: FormUtilityService
    ) {
        super(modalRef, modalService, formUtilityService);
    }

    ngOnInit() {
        this.loadData();
    }

    public loadData() {
        this.spinnerService.show();
        this.salesProductsService.getSalesProducts()
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({ next: (response) => {
                this.salesProducts = response ? response : [];
                this.salesProducts = this.salesProducts.filter(p => p.id !== this.salesProductId);
                this.salesProducts.forEach((salesProduct: SalesProduct) => {
                    salesProduct.Name += ' (' + this.datePipe.transform(salesProduct.DateAdded, this.applicationStateService.settingParam.DateFormat) + ')';
                });
            }, error: this.alertService.showApiError });
    }

    submit(isValid: boolean) {
        if (!isValid || !this.selectedProduct) {
            return;
        }
        this.spinnerService.show();
        this.salesProductsService.mergeProduct(this.selectedProduct.id, this.salesProductId)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({ next: () => {
                this.alertService.renderSuccessMessage(StringUtils.format(Messages.MergeSalesProduct,
                    { 'oldProductName': this.selectedProduct.Name, 'masterProductName': this.productName }));
                this.onCancel(true);
            }, error: this.alertService.showApiError });
    }

    public onCancel(reload: boolean) {
        this.hide({shouldReload: reload});
    }

}
