<pos-edit-wrapper heading="Filter" (close)="closeDialog()">
    <div class="row g-0 col-xs-12 pt-pix-5 pos-modal-body">
        <div class="col-md-6 col-xs-12 ps-0 pt-pix-7">
            <pos-icon [icon]="icons.fieldInfoCircle" class="pe-pix-4"></pos-icon>
            <span class="relative top-1">
                Select Allergens to exclude and Tags, Groups to find
            </span>
        </div>
        <div class="col-md-6 col-xs-12 pe-0 pb-pix-10 d-flex align-items-center justify-content-end">
            <span class="btn-group pe-2">
                <label class="btn btn-default btn-radio"
                    [ngClass]="filterAction == filterActions.DISABLE ? 'active btn-primary normal' : 'btn-secondary'">
                    <input [(ngModel)]="filterAction" [value]="filterActions.DISABLE" type="radio"
                        name="filterAction" />
                    Disable
                </label>
                <label class="btn btn-default btn-radio"
                    [ngClass]="filterAction == filterActions.HIDE ? 'active btn-primary normal' : 'btn-secondary' ">
                    <input [(ngModel)]="filterAction" [value]="filterActions.HIDE" type="radio" name="filterAction" />
                    Hide
                </label>
            </span>
            <span>
                filtered products
            </span>
        </div>
        <div class="col-md-6 col-sm-7 col-xs-12 ps-0 pe-3 vertical-tabs mt-pix-n10">
            <ul class="nav nav-tabs inner-tab pos-nav-tabs">
                <li [ngClass]="{'active': selectedTab == tabList.DietaryWarnings }">
                    <a (click)="selectedTab = tabList.DietaryWarnings" class="blue pointer">
                        <span>
                            <pos-icon class="theme-separator" [icon]="icons.exclamationTriangle"></pos-icon>
                            {{tabList.DietaryWarnings}}
                        </span></a>
                </li>
                <li [ngClass]="{'active': selectedTab == tabList.Tags }">
                    <a (click)="selectedTab = tabList.Tags" class="blue pointer">
                        <span>
                            <pos-icon class="theme-separator" [icon]="icons.tag"></pos-icon>
                            {{tabList.Tags}}
                        </span> </a>
                </li>
                <li [ngClass]="{'active': selectedTab == tabList.Groups}">
                    <a (click)="selectedTab = tabList.Groups" class="blue pointer">
                        <span>
                            <pos-icon class="theme-separator" [icon]="icons.objectGroup"></pos-icon>
                            {{tabList.Groups}}
                        </span> </a>
                </li>
            </ul>
            <div [hidden]="selectedTab != tabList.DietaryWarnings">
                <div class="row g-0 px-3 overflow-auto-tabs" [ngStyle]="{'height': screenHeight + 'px'}">

                    <div *ngFor="let warning of dietaryWarnings; let i = index;" class="row g-0  pt-pix-7">
                        <div class="col-sm-6 col-xs-4 col-4 p-0 pt-pix-7">{{ warning.Name }}</div>
                        <div class="col-sm-6 col-xs-8 col-8 pe-0 text-end">
                            <button class="btn btn-primary pos-button white" *ngIf="warning.Value" style="width: 109px;"
                                (click)="countWarnings(warning, false)">
                                <pos-icon [icon]="icons.check"></pos-icon>
                                Applied
                            </button>
                            <button class="btn btn-secondary pos-button" *ngIf="!warning.Value"
                                (click)="countWarnings(warning, true)">Not Applied</button>
                        </div>
                    </div>
                </div>
            </div>
            <div [hidden]="selectedTab != tabList.Tags">
                <div class="row g-0 px-3 overflow-auto-tabs" [ngStyle]="{'height': screenHeight + 'px'}">
                    <div *ngFor="let tag of tags; let i = index;" class="row g-0 p-0  pt-pix-7">
                        <div class="col-sm-6 col-xs-4 col-4 p-0 pt-pix-7">{{ tag.Name }}</div>
                        <div class="col-sm-6 col-xs-8 col-8 pe-0 text-end">
                            <button class="btn btn-primary pos-button white" *ngIf="tag.Value" style="width: 109px;"
                                (click)="countTags(tag, false)">
                                <pos-icon [icon]="icons.check"></pos-icon>
                                Applied
                            </button>
                            <button class="btn btn-secondary pos-button" *ngIf="!tag.Value"
                                (click)="countTags(tag, true)">Not
                                Applied</button>
                        </div>
                    </div>
                </div>
            </div>
            <div [hidden]="selectedTab != tabList.Groups">
                <div class="row g-0 px-3 overflow-auto-tabs" [ngStyle]="{'height': screenHeight + 'px'}">
                    <div *ngFor="let widget of groupWidgets; let i = index;" class="row g-0 pt-pix-7">
                        <div class="col-sm-6 col-xs-4 col-4 p-0 pt-pix-7">{{ widget.Name }}</div>
                        <div class="col-sm-6 col-xs-8 col-8 pe-0 text-end">
                            <button class="btn btn-primary pos-button white" *ngIf="widget.IsAppliedFilter"
                                style="width: 109px;" (click)="countWidgets(widget, false)">
                                <pos-icon [icon]="icons.check"></pos-icon>
                                Applied
                            </button>
                            <button class="btn btn-secondary pos-button" *ngIf="!widget.IsAppliedFilter"
                                (click)="countWidgets(widget, true)">Not
                                Applied</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-sm-5 col-xs-12 px-3 border-1 overflow-auto-tabs"
            [ngStyle]="{'height': screenHeight + 45 + 'px'}">
            <div *ngIf="dietaryWarningsCount > 0" class="pt-pix-5 pb-pix-20">
                <h3 class="theme-separator">
                    <pos-icon class="font-15" [icon]="icons.exclamationTriangle"></pos-icon> Dietary Warnings
                    <span class="badge"
                        [ngStyle]="{'color': badgeFontColor, 'background-color': badgeBgColor }">{{dietaryWarningsCount}}</span>
                </h3>
                <span *ngFor="let warning of dietaryWarnings">
                    <p-chip [removable]="true" [label]="warning.Name"
                        (onRemove)="warning.Value = false; countWarnings(warning);" *ngIf="warning.Value"
                        class="pt-pix-7 pe-pix-5">
                    </p-chip>
                </span>
            </div>
            <div *ngIf="tagsCount > 0" class="pt-pix-5 pb-pix-10">
                <h3 class="theme-separator">
                    <pos-icon class="font-15" [icon]="icons.tag"></pos-icon> Tags
                    <span class="badge"
                        [ngStyle]="{'color': badgeFontColor, 'background-color': badgeBgColor }">{{tagsCount}}</span>
                </h3>
                <span *ngFor="let tag of tags">
                    <p-chip [removable]="true" [label]="tag.Name" (onRemove)="tag.Value = false; countTags(tag);"
                        *ngIf="tag.Value" class="pt-pix-7 pe-pix-5"></p-chip>
                </span>
            </div>
            <div *ngIf="widgetsCount > 0" class="pt-pix-5 pb-pix-10">
                <h3 class="theme-separator">
                    <pos-icon class="font-15" [icon]="icons.objectGroup"></pos-icon> Groups
                    <span class="badge"
                        [ngStyle]="{'color': badgeFontColor, 'background-color': badgeBgColor }">{{widgetsCount}}</span>
                </h3>
                <span *ngFor="let widget of groupWidgets">
                    <p-chip [removable]="true" [label]="widget.Name"
                        (onRemove)="widget.IsAppliedFilter = false; countWidgets(widget);"
                        *ngIf="widget.IsAppliedFilter" class="pt-pix-7 pe-pix-5"></p-chip>
                </span>
            </div>
        </div>
    </div>
    <div class="col-xs-12 action-button-container popup-footer">
        <div class="btn-cancel-modal">
            <div class="btn-group me-pix-5 mb-1">
                <div class="dropup">
                    <button type="button" class="btn btn-success dropdown-toggle white btn-lg normal pos-button"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        Apply to Menu Display
                    </button>

                    <ul class="dropdown-menu" role="menu">
                        <li class="dropdown-item" (click)="sendFilterDataToMenu(menuInteractivityActionType.Highlight)">
                            <a class="pointer">Highlight</a>
                        </li>
                        <li class="dropdown-item" (click)="sendFilterDataToMenu(menuInteractivityActionType.Flash)">
                            <a class="pointer">Flash</a></li>
                        <li class="dropdown-item" [class.disabled]="widgetsCount != 0" [isDisabled]="widgetsCount == 0"
                            tooltip="This option is not available when group is selected." placement="right" 
                            (click)="widgetsCount == 0 ? sendFilterDataToMenu(menuInteractivityActionType.HideExclusions) : $event.stopPropagation()">
                            <a class="pointer">Hide Exclusions</a>
                        </li>
                    </ul>
                </div>
            </div>
            <button class="btn btn-secondary btn-lg normal me-pix-5 pos-button mb-1" type="button"
                (click)="reset()">Reset</button>
            <button class="btn btn-primary btn-lg normal pos-button me-pix-5 white mb-1" type="button"
                (click)="closeAndApply()">Apply
                and Close</button>
        </div>
    </div>
</pos-edit-wrapper>
