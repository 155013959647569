import { Injectable } from '@angular/core';
import { forEach } from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class MenuItemsSortService {

    sortItems(menuItemList, priorityItemList = [0]) {
        forEach(menuItemList, (data) => {
            if (data?.Ordinal) {
                priorityItemList.splice(0, 0, data.Id);
            }
        });
        return menuItemList.sort((a, b) => {
            const aIndexInPriorityList = a.Ordinal ? a.Ordinal : priorityItemList.indexOf(a?.Id);
            const bIndexInPriorityList = b.Ordinal ? b.Ordinal : priorityItemList.indexOf(b?.Id);
            if (aIndexInPriorityList >= 0 && bIndexInPriorityList >= 0) {
                return aIndexInPriorityList - bIndexInPriorityList;
            }
            if (aIndexInPriorityList >= 0) {
                return -1;
            }
            if (bIndexInPriorityList >= 0) {
                return 1;
            }
            if (a.Text?.toLocaleLowerCase() > b.Text?.toLocaleLowerCase()) {
                return 1;
            }
            if (a.Text?.toLocaleLowerCase() < b.Text?.toLocaleLowerCase()) {
                return -1;
            }
            return 0;
        });
    }
}
