Heros
<div>
    <ul>
        <li *ngFor="let hero of heros">{{hero}}</li>
    </ul>
</div>
<div *ngIf="myVar">
    True
 </div>
 <div *ngIf="!myVar">
    False
 </div>
 <button (click)="myVar = !myVar">Click</button>
