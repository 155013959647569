<pos-edit-wrapper heading="Choose button(s) to add" (close)="onCancel()">
    <div class="col-12 pos-modal-body modal-content__list px-pix-5">
      <ng-table #gridComponent [columns]="buttonColumns" [sort]="false" [data]="buttons">
      </ng-table>
      <ng-template let-data="rowData" #chkTemplate>
        <span>
            <p-checkbox name="button" #button="ngModel" [(ngModel)]="data.IsCheckedButton" binary="true" />
        </span>
      </ng-template>
  </div>
  <div class="clearfix"></div>
  <div class="col-12 action-button-container popup-footer">
    <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" (click)="addButtons()">Add</button>
    <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" (click)="onCancel()">Cancel</button>
  </div>
</pos-edit-wrapper>
