<pos-edit-wrapper heading="Mapped Buttons: {{productName}}" (close)="onCancel()">
  <form #copyProductForm="ngForm" autocomplete="off">
    <div class="col-12 pos-modal-body mt-pix-5 form-horizontal screen-scroll">
      <ng-table id="ListAllButtons" [sort]="true" [columns]="mappedButtonColumns" [data]="mappedButtons">
      </ng-table>
    </div>
    <div class="clearfix"></div>
    <div class="col-12 action-button-container popup-footer">
      <button type="button" data-dismiss="modal" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" (click)="onCancel()"
        value="Cancel">Close</button>
    </div>
  </form>
</pos-edit-wrapper>
