import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalComponent } from 'src/app/shared/components/modal';

@Component({
  selector: 'pos-inventory-receiving-warning',
  templateUrl: './inventory-receiving-warning.component.html',
  styleUrls: ['./inventory-receiving-warning.component.scss']
})
export class InventoryReceivingWarningComponent extends ModalComponent implements OnInit {
  
  public message: string = '';
  public okButtonText: string = 'Ok';
  constructor(deleteModalRef: BsModalRef) {  super(deleteModalRef); }

  ngOnInit(): void {
  }

  public hideChildModal(): void {
    this.hide({ shouldDelete: false });
  }

}
