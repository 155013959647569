import { cloneObject } from "./object-helpers";

export class VersionedObject<T> {
  private _currentVersion: number = 0;
  private _versionHistory: Map<string, T>;
  private _checkPoint = [];
  constructor(private _obj: T, initialVersionTag: string = undefined) {
    this._versionHistory = new Map();
    this.commit(initialVersionTag);
  }

  get Obj(): T {
    return this._obj;
  }

  get VersionHistory(): Map<string, T> {
    return cloneObject(this._versionHistory);
  }

  get LatestVersion(): string {
    return Array.from(this._versionHistory.keys()).pop();
  }

  commit(versionTag: string = undefined, setCheckpoint = false): string {
    if (!versionTag) {
      versionTag = `v${this._currentVersion}`;
    }

    if (this.versionExists(versionTag)) {
      throw new Error(`Duplicate version tag: ${versionTag}`);
    }

    this._versionHistory.set(versionTag, cloneObject(this._obj));

    if (setCheckpoint) {
      let checkpointVersion: { [id: string]: any; } = {};
      checkpointVersion[versionTag] = (cloneObject(this._obj));
      this._checkPoint.push(checkpointVersion);
    }

    this._currentVersion++;
    return versionTag;
  }

  clear() {
    this._versionHistory.clear();
  }

  getVersion(versionTag: string): T {
    this.ensureVersionExists(versionTag);
    return cloneObject(this._versionHistory.get(versionTag));
  }

  getLastCommittedVersion(): T {
    return this.getVersion(this.LatestVersion);
  }

  revertToLastCommittedVersion(): T {
    const currentVersion = this._obj;
    this._obj = this.getLastCommittedVersion();
    return currentVersion;
  }

  revert(noOfVersions: number) {
    let count = 0;
    let targetHistoryLength = this._versionHistory.size - noOfVersions;
    if (targetHistoryLength) {
      this._versionHistory.forEach((val, key) => {
        if (count >= targetHistoryLength) {
          this._versionHistory.delete(key)
        }
        count++;
      });
    }
    this._obj = this.getLastCommittedVersion();
  }

  revertToVersion(versionTag: string) {
    const currentVersion = this._obj;
    this._obj = this.getVersion(versionTag);
    let removeNext = false;
    const entriesToRemove = [];
    this._versionHistory.forEach((val, key) => {
      if (removeNext) {
        entriesToRemove.push(key);
      }
      removeNext = removeNext || key === versionTag;
    });
    entriesToRemove.forEach(key => this._versionHistory.delete(key));
    return currentVersion;
  }

  private versionExists(versionTag: string): boolean {
    return this._versionHistory.has(versionTag);
  }

  private ensureVersionExists(versionTag: string): void {
    if (!this.versionExists(versionTag)) {
      throw new Error(`Invalid version tag: ${versionTag}`);
    }
  }

  setCheckpoint(versionTag: string) {
    this.commit(versionTag, true);
  }

  sqaushIntoLastCheckpoint() {
    const currentVersionCopy = cloneObject(this.getLastCommittedVersion());
    let lastCheckpoint = this._checkPoint.pop();
    this.revertToVersion(Object.keys(lastCheckpoint)[0]);
    this.revert(1);
    this._obj = currentVersionCopy;
    this.commit(Object.keys(lastCheckpoint)[0]);
  }
}
