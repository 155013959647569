<div class="row row-cols-xs-12 row-cols-12 mx-0 px-0">
    <div class="row-cols-xs-6">
        <button class="btn-lg kiosk-payment-buttons col-12" type="button" (click)="payViaCC()">
            Pay here with card
        </button>
    </div>
    <div class="row-cols-xs-6">
        <button class="btn-lg kiosk-payment-buttons col-12 mt-5" type="button" (click)="payAtCounter()">
            pay at the counter
        </button>
    </div>
    <div class="row-cols-xs-6 mt-5 text-center">
        <button class="cancel-order-button" (click)="close.emit()">CANCEL</button>
    </div>
</div>
