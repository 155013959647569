<div *ngIf="!removeHeader">
    <button class="close" (click)="closeUserWorkSchedule()">&times;</button>
    <h4 class="modal-content__heading">Work Schedules</h4>
</div>
<div class="pos-modal-body pt-pix-5 overflow-hidden">
    <div class="col-12">
        <pos-row class="justify-content-between">
            <pos-column lg="6" md="8" sm="10" xs="12">
                <pos-row class="g-0">
                    <pos-column lg="4" md="5" sm="5" xs="12">
                        <pos-form-field inputStyle="col-12 ps-pix-10">
                            <p-checkbox posInput name="chkUserSchedule" #chkUserSchedule="ngModel"
                                [(ngModel)]="isOnlyCurrentUserSchedules" binary="true" (onChange)="reloadWorkSchedules()"
                                label="Only my schedule">
                            </p-checkbox>
                        </pos-form-field>
                    </pos-column>
                    <pos-column lg="8" md="7" sm="7" xs="12">
                        <pos-form-field label="Date" inputStyle="col-12">
                            <p-calendar posInput [readonlyInput]="true" [required]="true" [dateFormat]="dateFormat"
                                (onSelect)="searchWorkSchedules()" [inputStyle]="{'width':'unset','display':'unset'}"
                                inputStyleClass="form-control" [monthNavigator]="true" [yearRange]="'2000:2100'"
                                [yearNavigator]="true" [showIcon]="true" name="startDate" [(ngModel)]="startDate"
                                appendTo="body">
                            </p-calendar>
                        </pos-form-field>
                    </pos-column>
                </pos-row>
            </pos-column>

            <pos-column xs="4" class="mt-pix-12 font-12 float-right tag-height">
                <ul class="col-12 p-0 tag-height">
                    <div *ngIf="tags" class="row g-0">
                        <li *ngFor="let tag of tags" class="col-md-4 col-sm-6 col-12 px-2 ps-0 text-start">
                            <div class="ps-2 w-100"
                                [ngStyle]="{'background-color':tag.Color,'color':getContrastColor(tag.Color)}">
                                {{toCamelCase(tag.Name)}}
                            </div>
                        </li>
                    </div>
                </ul>
            </pos-column>
        </pos-row>
    </div>
    <div class="col-12 pt-pix-10">
        <full-calendar #calendar [options]="calendarOptions" *ngIf="!isOnlyCurrentUserSchedules"
        ></full-calendar>

        <full-calendar #currentUserCalendar [options]="calendarOptions" *ngIf="isOnlyCurrentUserSchedules"
        ></full-calendar>
    </div>
</div>
<div class="col-12 pb-3 action-button-container popup-footer" *ngIf="!removeHeader">
    <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button"
        (click)="closeUserWorkSchedule()">Close</button>
</div>
<div class="clearfix"></div>
