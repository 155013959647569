import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpResponseUtilsService } from './http-response-utils.service';
import { SpinnerService } from '../components/spinner/spinner.service';


@Injectable()
export class AlertsService {
  constructor(
    private toastrService: ToastrService,
    private httpResponseUtilService: HttpResponseUtilsService,
    private spinnerService: SpinnerService
  ) {}

  renderErrorMessage(message) {
    this.toastrService.error(message, null, { enableHtml: true });
  }

  renderSuccessMessage(message) {
    this.toastrService.success(message, null, {
      enableHtml: true,
      timeOut: 2000
    });
  }

  renderWarningMessage(message) {
    this.toastrService.warning(message);
  }

  renderInformationalMessage(message) {
    this.toastrService.info(message);
  }

  closeAlert() {
    this.toastrService.clear();
  }

  showApiError = errorResponse => {
    const error: any = this.httpResponseUtilService.parseError(errorResponse);
    this.renderErrorMessage(error.message);
    this.spinnerService.hide();
  }
}
