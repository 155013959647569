import { Component, OnInit } from '@angular/core';
import { ApplicationStateService } from 'src/app/shared';
import { Configurations, SettingParam, UserDetails } from 'src/app/shared';

@Component({
  selector: 'pos-time-entry-terminal',
  templateUrl: './time-entry-terminal.component.html',
  styleUrls: ['./time-entry-terminal.component.scss']
})
export class TimeEntryTerminalComponent implements OnInit {

  headerLinks = {
    tasks: false,
    time: false,
    admin: false,
    activeOrders: false,
    warning: false,
    home: false,
    userMenu: false,
    signIn: false
  };
  homeState = 'time-entry';
  userId: number;
  userRollId: number;
  userName: string;
  timeKeeping = false;
  currentTerminalName = '';
  applicationModule = 'Time';
  userDetails: UserDetails;
  settingParam: SettingParam;
  configurations: Configurations;
  shiftDutiesUserName: string;

  constructor(private applicationStateService: ApplicationStateService) { }

  ngOnInit(): void {
    this.userDetails = this.applicationStateService.userDetails;
    this.userName = this.userDetails.lastname + ' ' + this.userDetails.firstname;
    this.userId = this.userDetails.id;
    this.currentTerminalName = this.applicationStateService.terminalName;
    this.timeKeeping = this.userDetails.time_keeping;
    this.userId = this.userDetails.id;
    this.shiftDutiesUserName = this.userDetails.firstname + ' ' + this.userDetails.lastname;

    this.settingParam = this.applicationStateService.settingParam;
    this.configurations = this.applicationStateService.configurations;
  }

}
