import { Component, OnInit, Input } from '@angular/core';
import { AlertsService, ModalComponent, SpinnerService } from 'src/app/shared';
import { OrderService } from '../../shared/services/order.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { ScreenTitle } from '../interface/screen-title';

@Component({
  selector: 'pos-screen-containing-list',
  templateUrl: './screen-containing-list.component.html',
  styleUrls: ['./screen-containing-list.component.scss']
})
export class ScreenContainingListComponent extends ModalComponent implements OnInit {

  @Input('buttonId') buttonId: number;
  @Input('buttonName') buttonName: number;
  screenContainingList: Array<ScreenTitle> = [];
  screenNames: string[];
  constructor(screenContainingModalRef: BsModalRef,
    private orderService: OrderService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService) {
    super(screenContainingModalRef);
  }

  ngOnInit() {
    this.getScreenList(this.buttonId);
  }

  private getScreenList(buttonId: number): void {
    this.spinnerService.show();
    this.orderService.findButtonOnScreen(buttonId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (screenList: any) => {
          this.screenContainingList = screenList ? screenList.ScreenTitleModel : [];
          this.screenNames = [];
          for (let i = 0; i < this.screenContainingList.length; i++) {
            this.screenNames.push(this.screenContainingList[i].Title);
          }
        }, error: this.alertService.showApiError
      });
  }

  onConfirm() {
    this.hide({});
  }

}
