import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[posOnlyNumbers]'
})
export class OnlyNumbersDirective {

  @HostListener('keypress', ['$event']) onKeypress(event) {
    const charCode = event.charCode;
    const enterKeyCode = 13;

    // Allow only numbers (0-9) or the Enter key
    if (!(charCode >= 48 && charCode <= 57) && charCode !== enterKeyCode) {
      event.preventDefault();
    }
  }
}
