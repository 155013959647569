import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { finalize } from 'rxjs/operators';
import { InfoLinkModel } from 'src/app/information-management/info-link/interface/info-link-model';
import { InfoLinkService } from 'src/app/information-management/info-link/service/info-links.service';
import { AlertsService, ApplicationStateService, SpinnerService, UserDetails } from 'src/app/shared';
import { angleDoubleRight, angleDoubleLeft } from 'src/app/shared/components/icon';
import { CustomLink } from '../../interfaces/custom-link';

declare let $: any;

@Component({
    selector: 'pos-custom-links',
    templateUrl: './custom-links.component.html',
    styleUrls: ['./custom-links.component.scss']
})

export class CustomLinksComponent implements OnInit {

    icons = {
        angleDoubleRight, angleDoubleLeft
    };
    isFullScreen = false;
    infoLinkDetailsList: Array<InfoLinkModel> = [];
    customSection: Array<CustomLink> = [];
    itemPerSection: Array<InfoLinkModel> = [];
    idForCustomSection = 0;
    sectionTabName = '';
    userDetails: UserDetails;
    customLink: CustomLink = { Items: [], SectionName: '' };
    customLinkURL: SafeResourceUrl;
    activeTabIndex = 0;

    constructor(private applicationStateService: ApplicationStateService,
        private spinnerService: SpinnerService,
        private infoLinkService: InfoLinkService,
        private sanitizer: DomSanitizer,
        private alertService: AlertsService) { }

    ngOnInit(): void {
        this.customLinkURL = this.sanitizer.bypassSecurityTrustResourceUrl('');
        this.setDefaults();
        this.getInfoLinks();
    }

    setDefaults(): void {
        this.userDetails = this.applicationStateService.userDetails;
    }

    getInfoLinks(): void {
        this.spinnerService.show();
        this.infoLinkService.getInfoLinks()
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (res: Array<InfoLinkModel>) => {
                    this.getCustomLinksListCompleted(res);
                }
            });
    }

    toggleFullScreen(): void {
        this.isFullScreen = !this.isFullScreen;
        if (this.isFullScreen) {
            $('.nav-pills').hide();
            $('.tab-content').css('width', '100%');
        } else {
            $('.nav-pills').show();
            $('.tab-content').css('width', '80%');
        }
    }

    hideActiveTabContent(): void {
        this.activeTabIndex = -1;
    }

    getCustomLinkPage(link: string, id: number): void {
        this.activeTabIndex = -1;
        this.spinnerService.show();
        this.infoLinkService.saveCustomLinkClicks({ Id: id, UserId: this.userDetails.id } as InfoLinkModel)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            })).subscribe({
                next: res => {
                    this.activeTabIndex = id;
                    this.customLinkURL = this.sanitizer.bypassSecurityTrustResourceUrl(link);
                }, error: this.alertService.showApiError
            });
    }

    getCustomLinksListCompleted(links: Array<InfoLinkModel>): void {
        this.infoLinkDetailsList = links;
        this.infoLinkDetailsList.forEach((item: InfoLinkModel) => {
            if (!this.sectionTabName) {
                this.sectionTabName = item.SectionName;
            }
            if (this.idForCustomSection === item.SectionId) {
                this.itemPerSection.push(item);
            } else {
                if (Object.keys(this.customLink).length > 0 && this.customLink.SectionName !== '') {
                    this.customLink.Items = this.itemPerSection;
                    this.customSection.push(this.customLink);
                }
                this.itemPerSection = [];
                this.customLink = { Items: [], SectionName: '' };
                this.customLink.SectionName = item.SectionName;
                this.itemPerSection.push(item);
                this.idForCustomSection = item.SectionId;
            }
        });
        if (Object.keys(this.customLink).length > 0) {
            this.customLink.Items = this.itemPerSection;
            this.customSection.push(this.customLink);
            this.getCustomLinkPage(this.customSection[0].Items[0].Link, this.customSection[0].Items[0].Id);
        }
    }

    getHeight(): number {
        return $(window).height() - 150;
    }

}
