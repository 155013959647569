import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { GridColumn, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { map, sortBy, union } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SalesProduct } from 'src/app/information-management';
import { DomainConstants, FormUtilityService, ModalFormComponent, ModalService } from 'src/app/shared';
import { sortAlphaDown } from 'src/app/shared/components/icon';

@Component({
  selector: 'pos-product-ordinal-config',
  templateUrl: './product-ordinal-config.component.html',
  styleUrls: ['./product-ordinal-config.component.scss'],
})
export class ProductOrdinalConfigComponent extends ModalFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.productOrdinalForm;
  }

  @Input() widgetType: any;
  menuWidgetTypes = DomainConstants.DigitalMenuWidgetTypes;
  @Input() productOrdinal: Array<number> = [];
  @Input() showInlinePrices: Array<number> = [];
  @Input() salesProducts: Array<SalesProduct> = [];
  @ViewChild('ShowProductPriceTemp', { static: true }) ShowProductPriceTemp: TemplateRef<any>;
  @ViewChild('productOrdinalForm', { static: true }) productOrdinalForm: NgForm;
  saveOrdinal = false;
  icons = {
    sortAlphaDown
  }

  productTableItems: Array<{
    Id: number,
    Name: string,
    Ordinal: number,
    ShowProductPrice: boolean,
  }> = [];
  columns: Array<GridColumn> = [];

  constructor(public modalRef: BsModalRef,
    modalService: ModalService,
    formUtilityService: FormUtilityService) {
    super(modalRef, modalService, formUtilityService);

  }
  ngOnInit(): void {
    this.saveOrdinal = this.productOrdinal?.length != 0 ?? false;
    let i = 1;
    const productTableIds = union(this.productOrdinal, this.salesProducts.map(p => p.id))
    this.productTableItems = map(productTableIds, id => {
      return {
        Id: id,
        Ordinal: i++,
        Name: this.getName(id),
        ShowProductPrice: this.showInlinePrices.includes(id),
      }
    });
    this.configureColumns();
  }

  getName(id: number): string {
    const product = this.salesProducts.find(s => s.id == id)
    if(product) {
      return product.MenuDisplayText ? product.MenuDisplayText : product.Name
    }
    return null;
  }
  configureColumns() {
    this.columns = [
      new GridColumn( { HeaderText: 'Name', Field: 'Name', IsSortable: false } ),
    ]
    if(this.widgetType.Name == this.menuWidgetTypes.List.Name) {
      this.columns.push(new TemplateColumn( {itemTemplate: this.ShowProductPriceTemp, HeaderText: 'Show Price', IsSortable: false, TextAlign: TextAlign.Center } ));
    }
  }

  gridRowReorder($event) {
    this.saveOrdinal = true;
  }

  sortAlphabetically() {
    this.saveOrdinal = false;
    this.productTableItems = sortBy(this.productTableItems, ['Name']);
  }

  onSave(isValid: boolean) {
    if(!isValid) return;
    this.showInlinePrices = this.showInlinePrices.filter(ip => !this.productTableItems.some(pti => pti.Id == ip));
    const newShowProductPrices = union(this.showInlinePrices, this.productTableItems.filter(s => s.ShowProductPrice).map(s => s.Id))
    this.close.emit({
      shouldReload: true,
      productOrdinal: this.saveOrdinal ? this.productTableItems.map(s => s.Id) : null,
      showProductPrices: newShowProductPrices,
    });
  }

  onCancel() {
    this.close.emit({ shouldReload: false })
  }
}
