<fieldset class="col-12 ps-1 ingredient-recipe-height">
    <form #invetoryPrepForm="ngForm" (ngSubmit)="getInventoryProductRecipe(invetoryPrepForm.valid)">
        <pos-row>
            <pos-column lg="3" sm="6" xs="12">
                <pos-form-field label="Product" [validationMessages]="{required: 'Please select product.'}"
                    inputStyle="col-xl-9 col-12 pe-0">
                    <ng-select posInput [items]="inventoryPrepRecordingProducts" bindLabel="Name" bindValue="Id"
                        placeholder="Select product" [(ngModel)]="inventoryPrepProduct.InventoryProductId"
                        name="prepProduct" (change)="inventoryProductChanged()" [required]="true">
                    </ng-select>
                </pos-form-field>
            </pos-column>
            <pos-column lg="3" sm="6" xs="12">
                <pos-form-field label="Qty" inputStyle="col-12 row mx-0"
                    [validationMessages]="{required: 'Please enter product qty.'}">
                    <input posInput class="form-control col-120 col-3 ps-0" id="textQty" type="number" name="textQty"
                        [(ngModel)]="inventoryPrepProduct.Qty" min="0" posDecimal [required]="true" #qty="ngModel"
                        (change)="qtyChanged()" />
                    <label *ngIf="unitName" class="col-75 edit-form__section__label p-0 ps-pix-10">{{ unitName }}</label>
                </pos-form-field>
            </pos-column>
            <pos-column lg="3" sm="6" xs="12" class="pt-pix-10 pt-pix-lg-30">
                <button class="btn btn-lg btn-warning white me-pix-5 pos-button normal" type="submit">Prep</button>
                <button class="btn btn-lg btn-primary white pos-button normal" type="button"
                    (click)="printInventoryPrep()">Print
                    Recipe</button>
            </pos-column>
        </pos-row>
    </form>
    <div class="col-12 mt-pix-10 mb-pix-5" *ngIf="isRecipeLoaded">
        <fieldset class="fieldset mb-pix-10 pl-075em pr-075em">
            <legend class="reset-style bold"><span class="bold">Ingredients</span></legend>
            <div class="overflow-auto" [ngStyle]="{'max-height': ingredientsHeight}">
                <div class="ms-pix-10" *ngFor="let recipe of recipeList">
                    <h4 class="mb-pix-5">
                        <b> {{recipe.ProductName}}: {{recipe.Qty * prepQuantity /
                            recipe.PrepareQty | tark_number }} {{recipe.UnitName}}</b>
                        <span class="red ps-pix-5" *ngIf="!recipe.IsAvailableQty" tooltipPosition="top"
                            tooltipStyleClass="custom-tooltip"
                            pTooltip="Only {{recipe.CalculatedQty}} {{recipe.UnitName}} are available in inventory">
                            <pos-icon [icon]="icons.exclamationTriangle">
                            </pos-icon>
                        </span>
                    </h4>
                    {{recipe.Instructions}}
                </div>
            </div>
        </fieldset>
    </div>
    <div class="col-12 mb-pix-5" *ngIf="isRecipeLoaded && recipeInformation">
        <fieldset class="fieldset pl-075em pr-075em">
            <legend class="reset-style bold"><span class="bold">Recipe</span></legend>
            <div class="mb-pix-5 pe-pix-15 ms-pix-10 overflow-auto" [ngStyle]="{'max-height': recipeHeight}">
                <h4>{{recipeInformation}}</h4>
            </div>
        </fieldset>
    </div>
</fieldset>
<div class="row mx-pix-n25">
    <div class="col-12 action-button-container page-footer">
        <button class="btn btn-lg btn-primary btn-save pos-button" (click)="updatePrepQty()" type="button">Update
            Inventory</button>
        <button class="btn btn-lg btn-secondary btn-cancel pos-button"
            (click)="showCancelButton ? resetRecipe() : close()" type="button">{{ showCancelButton ? "Cancel" :
            "Close"}}</button>
    </div>
</div>
