<div class="login-page">
  <form>

    <header class="pull-left width-full bg-theme-primary">
      <pos-keyboard-wedge [(isEnabled)]="isKeyboardWedgeEnabled" (onSwipeDetected)="onSwipeDetected($event)"></pos-keyboard-wedge>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12 login-page__logo text-center">
        <a href="">
          <img class="login-page__logo-image" src="{{imagePath}}/logo/logo.png" />
        </a>
      </div>
    </header>

    <div class="login-page__section">

      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12 col-p">
          <div class="mt-pix-10 col-lg-10 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-10 offset-sm-1 col-12">
            <div class="alert condensed inherit-position alert-danger display-none" id="divLoginAccessDenied">{{terminalAccessDenied}}</div>
          </div>
        </div>
      </div>

      <div class="login-page__section__body col-lg-12 col-12 p-0 px-4">
        <h2 class="login-page__section__body__heading">Login</h2>
        <div class="login-form">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-p col-sm-3">
            </div>
            <div class="col-lg-4 col-md-4 col-p col-sm-6 col-12 mb-pix-10">
              <input type="password" name="appPassword" id="appPassword" placeholder="Password" #appPassword
                class="form-control login-form__input" posHideKeyboard [(ngModel)]="customerCredential.Password"
                (keyup.enter)="customerLogin()"/>
            </div>
            <div class="container-fluid">
              <div class="row">
                <div class="col-lg-2 col-md-2 col-p col-sm-2 offset-sm-1 col-4">
                  <div class="btn-keypad" (click)="textEnteredForLogin('1')">1</div>
                </div>
                <div class="col-lg-2 col-md-2 col-p col-sm-2 col-4">
                  <div class="btn-keypad" (click)="textEnteredForLogin('2')">2</div>
                </div>
                <div class="col-lg-2 col-md-2 col-p col-sm-2 col-4">
                  <div class="btn-keypad" (click)="textEnteredForLogin('3')">3</div>
                </div>
                <div class="col-lg-2 col-md-2 col-p col-sm-2 col-4">
                  <div class="btn-keypad" (click)="textEnteredForLogin('4')">4</div>
                </div>
                <div class="col-lg-2 col-md-2 col-p col-sm-2 col-4">
                  <div class="btn-keypad" (click)="textEnteredForLogin('5')">5</div>
                </div>
                <div class="col-lg-2 col-md-2 col-p col-sm-2 offset-sm-1 col-4">
                  <div class="btn-keypad" (click)="textEnteredForLogin('6')">6</div>
                </div>
                <div class="col-lg-2 col-md-2 col-p col-sm-2 col-4">
                  <div class="btn-keypad" (click)="textEnteredForLogin('7')">7</div>
                </div>
                <div class="col-lg-2 col-md-2 col-p col-sm-2 col-4">
                  <div class="btn-keypad" (click)="textEnteredForLogin('8')">8</div>
                </div>
                <div class="col-lg-2 col-md-2 col-p col-sm-2 col-4">
                  <div class="btn-keypad" (click)="textEnteredForLogin('9')">9</div>
                </div>
                <div class="col-lg-2 col-md-2 col-p col-sm-2 d-none d-sm-block">
                  <div class="btn-keypad" (click)="textEnteredForLogin('0')">0</div>
                </div>
                <div class="col-p col-4 d-sm-none">
                  <div class="bg-theme-primary btn-keypad" (click)="cancel()">
                    <pos-icon [icon]="icons.applicationLoginUndo"></pos-icon>
                  </div>
                </div>
                <div class="col-p col-4 d-sm-none">
                  <div class="btn-keypad" (click)="textEnteredForLogin('0')">0</div>
                </div>
                <div class="col-p col-4 d-sm-none">
                  <div class="bg-theme-primary btn-keypad" (click)="customerLogin()" (keyup.enter)="customerLogin()">
                    <pos-icon [icon]="icons.applicationLoginArrowCircleRight"></pos-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-p col-sm-12 col-12 d-none d-sm-block text-center">
            <button class="btn btn-secondary btn-lg login-form__submit bg-theme-primary pos-button" (click)="cancel()" type="button">
              <pos-icon [icon]="icons.applicationLoginUndo" class="icon-size"></pos-icon> Clear
            </button>
            <button class="btn btn-secondary btn-lg login-form__submit bg-theme-primary pos-button" (click)="customerLogin()"
              (keyup.enter)="customerLogin()" type="button">
              <pos-icon [icon]="icons.applicationLoginArrowCircleRight" class="icon-size"></pos-icon>
              Continue
            </button>
          </div>
        </div>
      </div>

    </div>

    <ng-template #externalLoginModal>
      <div (keyup.enter)="customerLogin()">
        <pos-edit-wrapper heading="External Login" (close)="onCancelExternalLogin()">
          <div class="col-12 pos-modal-body">
            <pos-row class="mx-0" colStyleClass="p-0">
              <pos-column>
                <div class="alert condensed inherit-position alert-danger p-pix-5 ps-pix-10 mt-pix-10 mb-0" id="divInvalidCredentials"
                  [ngStyle]="{'display': 'none'}">{{terminalAccessDenied}}</div>
              </pos-column>

              <pos-column>
                <pos-form-field label="Username" inputStyle="col-8">
                  <input name="userName" id="username" #userName="ngModel" type="text" class="form-control"
                    [(ngModel)]="customerCredential.Username" autocapitalize="off" required />
                </pos-form-field>
              </pos-column>

              <pos-column>
                <pos-form-field label="Password" inputStyle="col-8">
                  <input name="externalPassword" #externalPassword="ngModel" type="password" class="form-control"
                    [(ngModel)]="customerCredential.ExternalPassword" maxlength="24" required />
                </pos-form-field>
              </pos-column>
            </pos-row>
          </div>
          <div class="col-12 action-button-container popup-footer">
            <button type="button" class="btn-primary btn-lg btn btn-save pos-button" (click)="customerLogin()">Ok</button>
            <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" (click)="onCancelExternalLogin()">Close</button>
          </div>
        </pos-edit-wrapper>
      </div>
    </ng-template>

  </form>
</div>
