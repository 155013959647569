<div class="m-0-auto width-80">
  <pos-list-wrapper heading="<img src='images/admin/manage-layout.png' height='22px' /> Layouts" (close)="cancel()">
    <div class="row mx-0">
      <div class="col-12 p-0">
        <ng-table id="Button" [sort]="true" [columns]="layoutColumns" [data]="layouts" [exportFilename]="'layouts'"
          #layoutsGrid>
        </ng-table>
        <ng-template let-data="rowData" #activeTemplate>
          <span *ngIf="(data.IsActive)" class="green text-center vertical-mid font-13">
            <pos-icon [icon]="icons.check"></pos-icon>
          </span>
        </ng-template>
      </div>
    </div>
  </pos-list-wrapper>
</div>
<pos-print-table [hidden]="true"></pos-print-table>
