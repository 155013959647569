import { Route } from '@angular/router';
import { AuthGuard } from '../app-guard';
import { DomainConstants, Levels } from '../shared/constants';
import { PickScreenComponent } from './components/pick-screen/pick-screen.component';

const PICK_SCREEN: Route = {
    path: '',
    canActivate: [AuthGuard],
    component: PickScreenComponent,
    data: {
        permissions: [{ 'Name': DomainConstants.TerminalTypes.PICK_SCREEN.Permission, 'Level': Levels.Access }]
    }
};
export const PICK_SCREEN_STATES = [PICK_SCREEN];
