import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LayoutService {

    constructor(private httpService: HttpClient) { }

    getLayouts(): Observable<any> {
        return this.httpService.get(`${RuntimeConstants.API_PATH}layouts`);
    }
}
