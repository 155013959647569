<pos-edit-wrapper heading="Slide Show" [headingIcon]="icons.digitalSignageBullHorn" (close)="cancelEditing()" [isPopup]="false">
  <form #formSlideShow="ngForm" (ngSubmit)='submit(formSlideShow.valid)' class="edit-form">
    <div class="col-xs-12 pos-page-body border-top">
      <div class="col-xs-12">
        <div>
          <h3 class="setting-title">{{id > 0 ? 'Edit' : 'Add' }} Slide Show</h3>
        </div>
        <pos-row class="px-2">
          <pos-column>
            <pos-form-field label="Name" [fieldInfo]="fieldInfoMessages.DigitalSignageName" [validationMessages]="{required: 'Please enter name.'}"
              inputStyle="col-md-8 col-xs-9">
              <input posInput class="form-control" name="slideshowName" [(ngModel)]="slideShowDetail.Name" [required]="true"
                type="text" #slideshowName="ngModel" />
            </pos-form-field>
          </pos-column>
        </pos-row>
      </div>
    </div>
    <div class="col-xs-12 action-button-container page-footer">
      <button class="btn btn-primary btn-lg normal btn-save pos-button" scrollup type="submit" value="Save">Save</button>
      <button class="btn btn-secondary btn-lg normal btn-cancel pos-button" routerLink="../" scrollup
        type="button" value="Cancel">Cancel</button>
    </div>
  </form>
</pos-edit-wrapper>
