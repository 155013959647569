<div>
  <button data-dismiss="modal" aria-label="Close" class="close" type="button" (click)="onCancel()">
    &times;
  </button>
  <h4 class="modal-content__heading">Terminal Reports </h4>
</div>
<div class="p-pix-10 overflow-hidden">
  <div class="display-grid">
    <div class="col-12 ps-pix-15 pe-0">
      <div class="row">
        <div class="col-12 p-0 web-only">
          <button class="btn btn-secondary btn-terminal-reports active pos-button" (click)="getDriverReport()"
            [ngClass]="{'active': reportHeader == reportsHeader.DriverReport}">
            <img src="images/icons/icon-dollar-bills.png" />
            <div>Driver Report</div>
            <!-- file-invoice-dollar -->
          </button>
          <button class="btn btn-secondary btn-terminal-reports pos-button" (click)="getSalesStatisticsReport()"
            [ngClass]="{'active': reportHeader == reportsHeader.SalesStatistics}">
            <img class="order-management__top-section__img-all-orders" src="images/icons/icon-check-all.png"
              height="48" />
            <div>Sales Statistics</div>
            <!-- tasks -->
          </button>
          <button class="btn btn-secondary btn-terminal-reports pos-button" (click)="getCashDrawerReport()"
            [ngClass]="{'active': reportHeader == reportsHeader.CashierReport}">
            <img src="images/replace-check-number.png" />
            <div>Cashier Report</div>
            <!-- money-check-alt -->
          </button>
          <button class="btn btn-secondary btn-terminal-reports pos-button" (click)="getSalesReport()"
            [ngClass]="{'active': reportHeader == reportsHeader.SalesReport}">
            <img src="images/replace-check-number.png" />
            <div>Sales Report</div>
            <!-- money-check-alt -->
          </button>
          <button class="btn btn-secondary btn-terminal-reports pos-button" (click)="getAllSalesReport()"
            [ngClass]="{'active': reportHeader == reportsHeader.AllSales}">
            <img src="images/icons/icon-credit-card.png" />
            <div>All Sales</div>
            <!-- credit-card -->
          </button>
        </div>
        <div class="col-12 p-0 mobile-only">
          <div class="float-start pt-pix-7">
            <section class="float-start text-center col-33">
              <pos-icon *ngIf="reportHeader == reportsHeader.DriverReport" [icon]="icons.driverReport"></pos-icon>
              <pos-icon *ngIf="reportHeader == reportsHeader.SalesStatistics" [icon]="icons.salesStatisticsReport">
              </pos-icon>
              <pos-icon *ngIf="reportHeader == reportsHeader.CashierReport" [icon]="icons.cashierReport"></pos-icon>
              <pos-icon *ngIf="reportHeader == reportsHeader.SalesReport" [icon]="icons.salesReport"></pos-icon>
              <pos-icon *ngIf="reportHeader == reportsHeader.AllSales" [icon]="icons.allSalesReport"></pos-icon>
            </section>
            <div class="display-flex bold">{{reportHeader}}</div>
          </div>
          <button class="mobile-only float-end me-pix-15 col-50" data-bs-target="#reportNavbarMenu"
            data-bs-toggle="collapse" aria-controls="reportNavbarMenu" aria-expanded="false" type="button">
            <div class="p-pix-5">
              <pos-icon [icon]="icons.bars"></pos-icon>
            </div>
          </button>
        </div>
        <div class="mobile-only position-relative">
          <div aria-haspopup="true" class="collapse terminal-report-navbar py-2 pe-pix-15 ps-pix-15"
            id="reportNavbarMenu">
            <ul class="nav navbar-nav">
              <li class="text-left">
                <div class="white" (click)="getDriverReport()">
                  <section class="float-start text-center col-33">
                    <pos-icon [icon]="icons.driverReport"></pos-icon>
                  </section>
                  <div class="display-flex">Driver Report</div>
                </div>
              </li>
              <li class="text-left">
                <div class="white pt-pix-10" (click)="getSalesStatisticsReport()">
                  <section class="float-start text-center col-33">
                    <pos-icon [icon]="icons.salesStatisticsReport"></pos-icon>
                  </section>
                  <div class="display-flex">Sales Statistics</div>
                </div>
              </li>
              <li class="text-left">
                <div class="white pt-pix-10" (click)="getCashDrawerReport()">
                  <section class="float-start text-center col-33">
                    <pos-icon [icon]="icons.cashierReport"></pos-icon>
                  </section>
                  <div class="display-flex">Cashier Report</div>
                </div>
              </li>
              <li class="text-left">
                <div class="white pt-pix-10" (click)="getSalesReport()">
                  <section class="float-start text-center col-33">
                    <pos-icon [icon]="icons.salesReport"></pos-icon>
                  </section>
                  <div class="display-flex">Sales Report</div>
                </div>
              </li>
              <li class="text-left">
                <div class="white pt-pix-10" (click)="getAllSalesReport()">
                  <section class="float-start text-center col-33">
                    <pos-icon [icon]="icons.allSalesReport"></pos-icon>
                  </section>
                  <div class="display-flex">All Sales</div>
                </div>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </div>


    <div class="col-12 mt-pix-10 px-2 screen-scroll">
      <div class="min-height modal-content__section mbi-0 p-pix-5 overflow-auto">
        <div class="row g-0">
          <div class="row col-12">
            <div class="text-start col-sm-4 col-6">{{reportHeader}}</div>
            <div class="text-end col-lg-3 col-md-3 col-sm-3 col-6">{{currentDate | tark_date }}</div>
            <div class="text-start col-12">{{userName}}</div>
          </div>
          <div class="row col-12" [innerHTML]="reportDetails">
          </div>
        </div>

        <table id="ReportTable" class="terminal-reports" aria-describedby="terminal-reports">
          <tr>
            <td class="pb-pix-5" [attr.colspan]="singleLineCols">
              <div class="border-bottom-dashed-black pt-pix-5">
              </div>
            </td>
          </tr>
          <tr *ngIf="isShowHeader">
            <th scope="col" *ngFor="let item of header | keyvalue: originalOrder">
              <span class="px-pix-15 pt-pix-5" [ngClass]="{'float-end': isNumeric(item.value)}">{{item.value}}</span>
            </th>
          </tr>
          <tr [hidden]="!showHeaderLine">
            <td [attr.colspan]="singleLineCols">
              <div class="border-bottom-dashed-black pt-pix-5">
              </div>
            </td>
          </tr>
          <tr *ngFor="let item of columns | keyvalue: originalOrder">
            <td *ngFor="let col of item.value | keyvalue: originalOrder">
              <span class="px-pix-15" [ngClass]="{'float-end': isNumeric(col.value)}">
                {{col.value}}
              </span>
            </td>
            <td *ngIf="!columns || columns.length == 0">
              No Data
            </td>
          </tr>

          <tfoot *ngIf="showTotal">
            <tr>
              <td class="pb-pix-5" [attr.colspan]="singleLineCols">
                <div class="border-bottom-dashed-black pt-pix-5">
                </div>
              </td>
            </tr>

            <tr class="pt-pix-5">
              <td [attr.colspan]="colsLength - 1" class="text-end bold px-pix-15">
                Total:
              </td>
              <td *ngIf="grandTotal">
                <span class="px-pix-15 float-end">
                  {{grandTotal | tark_number }}
                </span>
              </td>
              <td *ngIf="taxTotal || taxTotal == 0">
                <span class="px-pix-15 float-end">
                  {{taxTotal | tark_number }}
                </span>
              </td>
              <td *ngIf="orderTotal || orderTotal == 0">
                <span class="px-pix-15 float-end">
                  {{orderTotal | tark_number }}
                </span>
              </td>
              <td *ngIf="tipTotal || tipTotal == 0">
                <span class="px-pix-15 float-end">
                  {{tipTotal | tark_number }}
                </span>
              </td>
            </tr>
            <tr>
              <td [attr.colspan]="singleLineCols">
                <div class="border-bottom-dashed-black pt-pix-5">
                </div>
              </td>
            </tr>
          </tfoot>
          <tr *ngIf="!showTotal">
            <td class="pb-pix-5" [attr.colspan]="singleLineCols">
              <div class="border-bottom-dashed-black pt-pix-5">
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="col-12 action-button-container popup-footer pb-pix-10 mti-0">
  <button type="button" class="btn btn-primary btn-lg btn-save pos-button" (click)="printTerminalReport()"
    value="Print">Print</button>
  <button type="button" class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="onCancel()"
    value="Cancel">Close</button>
</div>
<div class="clearfix"></div>
