import { Injectable } from "@angular/core";
import { IKioskNavigation } from "../interfaces/ikiosk-navigation";
import { ChoiceBehavior } from "./choices-behavior";
import { BehaviorPromiseProviderService, KioskNavigationService, OrderKioskChoiceBehaviorService } from "../services";
import { AlertsService, DomainConstants, ModalService } from "src/app/shared";
import { KioskBehaviorExecution } from "./kiosk-behavior-execution";
import { KeyboardComponent } from "src/app/keyboard";

@Injectable({
    providedIn: 'root'
})
export class AddCommentToOrderBehavior extends ChoiceBehavior {
    commentEntryKeyboardOptions = { isOpen: false, capsLock: false, title: 'Enter comment', maxLength: 90 };

    constructor(private kioskNavigationService: KioskNavigationService,
        private orderKioskChoiceBehaviorService: OrderKioskChoiceBehaviorService,
        private alertsService: AlertsService,
        private modalService: ModalService,
        private behaviorPromiseProviderService: BehaviorPromiseProviderService
    ) {
        super();
    }

    execute(navObj: IKioskNavigation, orderNavigation: any): Promise<void> {
        navObj.button.IsSelected = false;
        if (this.behaviorValues?.comment) {
            this.addCommentToOrder(this.behaviorValues.comment);
        } else {
            this.commentEntryKeyboardOptions.title = 'Enter Comment';
            this.openCommentEntryKeyboard();
        }
        return this.behaviorPromiseProviderService.executionPromise;
    }

    openCommentEntryKeyboard() {
        this.commentEntryKeyboardOptions.isOpen = true;
        const keyboardModalRef = this.modalService.show(KeyboardComponent, {
            animated: false,
            class: 'vertical-center modal-max-width-95',
            initialState: {
                keyboardId: 'memoKeyboard',
                isOpen: true,
                options: this.commentEntryKeyboardOptions,
                value: ''
            }
        });

        keyboardModalRef.close.subscribe(res => {
            res?.value && this.terminalType == DomainConstants.TerminalTypes.ORDER_KIOSK.Name ? this.addCommentToOrder(res.value) : this.behaviorPromiseProviderService.rejectPromise();
        }, (e) => this.behaviorPromiseProviderService.rejectPromise(e));

    }

    addCommentToOrder(comment: string) {
        const model: KioskBehaviorExecution = {
            OrderId: this.kioskNavigationService.orderDetails?.Id?.Value,
            BehaviorId: this.behaviorId,
            Comment: comment
        };
        this.orderKioskChoiceBehaviorService.executeBehavior(model)
            .subscribe({
                next: (order: any) => {
                    this.kioskNavigationService.versionedVal.Obj.Order.Id = order.Id.Value;
                    this.kioskNavigationService.orderDetails = order;
                    this.behaviorPromiseProviderService.resolvePromise(order);
                },
                error: () => {
                    this.alertsService.showApiError;
                    this.behaviorPromiseProviderService.rejectPromise();
                }
            });
    }
}

