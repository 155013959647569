<div class="display-flex" [ngStyle]="{'font-size': widgetStyleConfig?.ItemFontStyle?.fontSize}">
    <span class="p-pix-3 text-center display-flex justify-content-center align-items-center" *ngFor="let product of combinedProduct"
        [ngStyle]="product.ProductStyle"
        [style.height]="widgetStyleConfig?.ItemHeight ? widgetStyleConfig?.ItemHeight : ''"
        [style.width]="widgetStyleConfig?.ItemWidth ? widgetStyleConfig?.ItemWidth : ''">
        <span [ngStyle]="widgetStyleConfig?.ItemFontStyle" [innerHTML]="product?.DisplayText"
            [ngClass]="interactiveStyleClass"></span>
    </span>
</div>
<div class="bg-black white text-center animation-none" [ngStyle]="widgetStyleConfig?.DescriptionStyle" [ngClass]="interactiveStyleClass">
    <span [innerHTML]="description">
    </span>
</div>
