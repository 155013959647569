import { Component, EventEmitter, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GridColumn, TemplateColumn } from '@tarktech/tark-ng-utils';
import { cloneDeep, find, forEach } from 'lodash';
import { finalize } from 'rxjs/operators';
import {
  SpinnerService, AlertsService, ModalService, ICloseable, ApplicationStateService, DomainConstants, BaseListComponent,
  PrintTableService, Messages, FieldInfoMessages, CronEditorService
} from 'src/app/shared';
import { reportBan, runReport, times, editSolid } from 'src/app/shared/components/icon/icons';
import { ReportParameter, ReportParameterRules } from '../../interfaces';
import { ReportSchedule } from '../../interfaces/report-schedule';
import { ReportSubscriptionParameter } from '../../interfaces/report-subscription-parameter';
import { ReportService } from '../../services/report.service';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { CronOptions } from '@tarktech/ngx-cron-editor';
import cronstrue from 'cronstrue';

@Component({
  selector: 'pos-report-subscription-edit',
  templateUrl: './report-subscription-edit.component.html',
  styleUrls: ['./report-subscription-edit.component.scss']
})
export class ReportSubscriptionEditComponent extends BaseListComponent<ReportSchedule> implements OnInit, ICloseable {

  @ViewChild('reportSubscriptionForm', { static: true }) reportSubscriptionForm: FormData;
  @Input() reportId: number;
  @Input() reportName: string;
  close: EventEmitter<any> = new EventEmitter<any>();
  cronOptions: CronOptions;
  reportSubscriptionParameter: ReportSubscriptionParameter;
  daysOfTheWeek = DomainConstants.ReportDaysOfTheWeek;
  reportDateRangeType = DomainConstants.ReportDateRangeType;
  reportDateRangeOffsetType = DomainConstants.ReportDateRangeOffsetType;
  scheduleColumns: Array<GridColumn> = [];
  isEditItem = false;
  editData: ReportSchedule;
  reportParameterList: Array<ReportParameter> = [];
  newReportParameterList: Array<ReportParameter> = [];
  reportParameterRules: Array<ReportParameterRules> = [];
  dayOfWeek = 'Mon';
  isShowScheduleExpressionEdit: boolean = false;
  isDataChanged = false;
  time = '';
  height = window.innerHeight - 520;
  @ViewChild('scheduleTemplate', { static: true }) scheduleTemplate: TemplateRef<any>;
  fieldInfoMessages = FieldInfoMessages;
  icons = {
    reportBan, runReport, times, editSolid
  };
  isCronValid = true;
  defaultEmailSubject: string = null;

  constructor(protected spinnerService: SpinnerService,
    protected alertsService: AlertsService,
    protected modalService: ModalService,
    protected applicationStateService: ApplicationStateService,
    protected reportService: ReportService,
    protected printService: PrintTableService,
    protected route: ActivatedRoute,
    protected cronEditorService: CronEditorService) {
    super(reportService, alertsService, spinnerService, modalService, applicationStateService, printService, route);
    this.reportSubscriptionParameter = reportService.newReportSubscriptionParameter();
    this.cronOptions = cronEditorService.newCronSettings(false);

  }

  ngOnInit(): void {
    this.isPrintIconVisible = false;
    this.deleteSuccessMessage = Messages.ScheduleDeleted;
    this.scheduleColumns = this.configureGridColumns();
    this.defaultEmailSubject = `${this.applicationStateService.settingParam.CustomerName}, POS Report: ${this.reportName}`;
  }

  showScheduleExpressionClicked() {
    this.isShowScheduleExpressionEdit = !this.isShowScheduleExpressionEdit;
    this.height = this.isShowScheduleExpressionEdit ? this.height - 220 : this.height + 220;
  }

  getReportParameterDetails() {
    this.spinnerService.show();
    this.reportService.getReportParameterDetails(this.reportId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: Array<ReportParameter>) => {
          this.reportParameterList = response;
          forEach(this.reportParameterList, (param) => {
            if (param.DataType === 'DATE') {
              const reportDate = new Date(param.InitialValue);

              reportDate.setHours(0, 0, 0, 0);
              const todayDate = new Date();
              todayDate.setHours(0, 0, 0, 0);

              const ONE_DAY = 1000 * 60 * 60 * 24;

              // Convert both dates to milliseconds
              const reportDateMs = reportDate.getTime();
              const todayDateMs = todayDate.getTime();

              // Calculate the difference in milliseconds
              const differenceMs = Math.abs(reportDateMs - todayDateMs);

              // Convert back to days and return
              param.InitialValue = Math.round(differenceMs / ONE_DAY).toString();
              param.RangeType = 'd';
              if (reportDate >= new Date(new Date().setHours(0, 0, 0, 0))) {
                param.RangeOffsetType = '+';
              } else {
                param.RangeOffsetType = '-';
              }
            }
          });
          this.setControls();
          this.getReportParameterRules();
        }, error: this.alertsService.showApiError
      });
  }

  getReportParameterRules() {
    this.spinnerService.show();
    this.reportService.getReportParameterRules(this.reportId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: Array<ReportParameterRules>) => {
          this.reportParameterRules = response;
        }, error: this.alertsService.showApiError
      });
  }

  getGridColumns(): Array<GridColumn> {
    return [
      new TemplateColumn({ HeaderText: 'Schedules', itemTemplate: this.scheduleTemplate, Width: window.innerWidth > 571 ? '87%' : '78%' })
    ];
  }

  editItem(id: number, data?: ReportSchedule): void {
    this.isEditItem = true;
    this.editData = data ?? this.reportService.newReportSchedule();
    this.editData.EmailSubject = !this.editData.EmailSubject ? this.defaultEmailSubject : this.editData.EmailSubject;
    this.time = this.editData.TimeString;
    const day = find(this.daysOfTheWeek, x => x.Text === this.editData.DayOfWeek);
    this.dayOfWeek = day.Value;
    if (!data) {
      this.reportParameterList = cloneDeep(this.newReportParameterList);
    }
    forEach(this.reportParameterList, (editFieldParam) => {
      const scheduleParam = find(this.editData.Parameters, x => x.Id === editFieldParam.Id);
      if (scheduleParam) {
        editFieldParam.ReportSubscriptionParameterId = scheduleParam.ReportSubscriptionParameterId;
        if (editFieldParam.Name === '@StartDate' || editFieldParam.Name === '@EndDate' || editFieldParam.Name === '@Date') {

          editFieldParam.RangeOffsetType = '-';
          if (scheduleParam.InitialValue.indexOf('+') !== -1) {
            editFieldParam.RangeOffsetType = '+';
          }

          if (scheduleParam.InitialValue.indexOf('now') !== -1) {
            if (scheduleParam.InitialValue === 'now') {
              editFieldParam.InitialValue = 0;
              editFieldParam.RangeType = 'h';
            } else {
              const number = scheduleParam.InitialValue.substring(4, scheduleParam.InitialValue.length - 1);
              editFieldParam.InitialValue = parseInt(number, 10);
              editFieldParam.RangeType = scheduleParam.InitialValue.substring(scheduleParam.InitialValue.length - 1);
            }
          } else if (scheduleParam.InitialValue.indexOf('today') !== -1) {
            if (scheduleParam.InitialValue === 'today') {
              editFieldParam.InitialValue = 0;
              editFieldParam.RangeType = 'd';
            } else {
              const number = scheduleParam.InitialValue.substring(6, scheduleParam.InitialValue.length - 1);
              editFieldParam.InitialValue = parseInt(number, 10);
              editFieldParam.RangeType = scheduleParam.InitialValue.substring(scheduleParam.InitialValue.length - 1);
            }
          }
        } else if (editFieldParam.DataType === 'BIT') {
          if (scheduleParam.InitialValue === '1') {
            editFieldParam.InitialValue = true;
          } else {
            editFieldParam.InitialValue = false;
          }
        } else if (editFieldParam.DataType === 'MULTISELECT') {
          editFieldParam.InitialValue = scheduleParam.InitialValue ? scheduleParam.InitialValue.split(',') : [];
        } else {
          editFieldParam.InitialValue = scheduleParam.InitialValue;
        }
      }
    });
  }

  afterDelete() {
    this.isDataChanged = true;
  }

  getConfirmDeleteMessage(data: ReportSchedule) {
    return Messages.ConfirmDeleteSchedule;
  }

  reload() {
    this.spinnerService.show();
    this.reportSubscriptionParameter.UserId = this.applicationStateService.userId;
    this.reportSubscriptionParameter.ReportId = this.reportId;
    this.reportService.getReportSubscriptionParameters(this.reportSubscriptionParameter)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: ReportSubscriptionParameter) => {
          this.reportSubscriptionParameter = response;
          forEach(this.reportSubscriptionParameter.ReportSubscriptionSchedules, (subscription, i) => {
            const day = find(this.daysOfTheWeek, x => x.Value === subscription.DayOfWeek);
            if (day) {
              subscription.DayOfWeek = day?.Text;
            }
            subscription.Index = i;
          });
          if (!this.reportSubscriptionParameter.IsActive && this.reportSubscriptionParameter.ReportSubscriptionSchedules.length <= 0) {
            this.reportSubscriptionParameter.IsActive = true;
          }
          this.getReportParameterDetails();
        }, error: this.alertsService.showApiError
      });
  }

  setControls() {
    forEach(this.reportParameterList, (param) => {
      if (param.DataType === 'BIT') {
        if (param.InitialValue === '0') {
          param.InitialValue = false;
        } else {
          param.InitialValue = true;
        }
      }
    });
    this.newReportParameterList = cloneDeep(this.reportParameterList);
  }

  closeModal() {
    this.close.emit({ success: this.isDataChanged });
    this.isDataChanged = false;
  }

  subscribeToReport(isValid) {
    this.isCronValid = this.editData ? CronEditorService.cronIsValid(this.cronOptions.cronFlavor, this.editData.Schedule) : true;
    if (!isValid || (this.editData && !this.isCronValid)) {
      return;
    }
    if (this.validateReportSubscriptionParameters()) {
      this.isDataChanged = true;
      this.setSubscriptionSchedule();
      if (!this.reportSubscriptionParameter.IsActive || (this.reportSubscriptionParameter.IsActive && !this.isEditItem)) {
        this.saveReportSubscription();
        return;
      }
      this.setInitialValues();
      this.editData.DayOfWeek = this.dayOfWeek;
      this.editData.Parameters = this.reportParameterList;
      this.editData.TimeString = this.time;
      this.editData.EmailSubject = (!this.editData.EmailSubject || this.editData.EmailSubject == this.defaultEmailSubject) ? null : this.editData.EmailSubject;
      if (!this.editData.Id) {
        this.reportSubscriptionParameter.ReportSubscriptionSchedules.push(this.editData);
      }
      this.saveReportSubscription();
    }
  }

  private setSubscriptionSchedule() {
    forEach(this.reportSubscriptionParameter.ReportSubscriptionSchedules, (subscription) => {
      const day = find(this.daysOfTheWeek, x => x.Text === subscription.DayOfWeek);
      if (day) {
        subscription.DayOfWeek = day.Value;
      }
      if (this.editData && this.editData.Id === subscription.Id) {
        subscription.Schedule = this.editData.Schedule;
      }
      subscription.EmailSubject = (!subscription.EmailSubject || subscription.EmailSubject == this.defaultEmailSubject) ? null : subscription.EmailSubject;
    });
  }

  private setInitialValues() {
    forEach(this.reportParameterList, (param) => {
      if (param.DataType === 'BIT') {
        param.InitialValue = param.InitialValue ? '1' : '0';
      } else if (param.DataType === 'DATE') {
        this.setInitialValuesOfDate(param);
      } else if (param.DataType === 'MULTISELECT') {
        if (!param.InitialValue && param.IsRequired) {
          this.alertsService.renderErrorMessage(StringUtils.format(Messages.ReportParameterRequired,
            { 'parameterName': param.Label }));
          return;
        } else if (param.ParameterQueryValues) {
          param.InitialValue = param.InitialValue.join();
        } else {
          param.InitialValue = '';
        }
      }
    });
  }

  private setInitialValuesOfDate(param: ReportParameter) {
    switch (param.RangeType) {
      case 'd': {
        if (param.InitialValue === 0) {
          param.InitialValue = 'today';
        } else {
          param.InitialValue = 'today' + param.RangeOffsetType + param.InitialValue.toString() + param.RangeType;
        }
        break;
      }
      case 'h':
      case 'm': {
        if (param.InitialValue === 0) {
          param.InitialValue = 'now';
        } else {
          param.InitialValue = 'now' + param.RangeOffsetType + param.InitialValue.toString() + param.RangeType;
        }
        break;
      }
      default:
        break;
    }
  }

  saveReportSubscription() {
    this.spinnerService.show();
    this.reportService.saveReportSubscription(this.reportSubscriptionParameter)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.isEditItem = false;
          this.alertsService.renderSuccessMessage(Messages.SubscriptionSaveSuccess);
          this.closeModal();
        }, error: this.alertService.showApiError
      });
  }

  validateReportSubscriptionParameters() {
    let validationErrors = '';
    forEach(this.reportParameterRules, (rule) => {
      const firstParam = find(this.reportParameterList, { Id: rule.Parameter1Id });
      const secondParam = find(this.reportParameterList, { Id: rule.Parameter2Id });

      if (firstParam.RangeType === 'd' || firstParam.RangeType === 'h' || firstParam.RangeType === 'm') {
        if (!this.compare(this.paramToDate(firstParam), this.paramToDate(secondParam), rule.Comparator)) {
          validationErrors += (validationErrors.length > 0 ? '<br>' : '') + rule.Message;
        }
      } else {
        if (!this.compare(firstParam.InitialValue, secondParam.InitialValue, rule.Comparator)) {
          validationErrors += (validationErrors.length > 0 ? '<br>' : '') + rule.Message;
        }
      }
    });

    if (validationErrors.length > 0) {
      this.alertsService.renderErrorMessage(validationErrors);
      return false;
    }
    return true;
  }

  paramToDate(param: ReportParameter) {
    const paramDate = new Date();
    switch (param.RangeType) {
      case 'd':
        paramDate.setDate(this.arithmeticOperation(paramDate.getDate(), parseInt(param.InitialValue > 0 ? param.InitialValue : '0', 10),
          param.RangeOffsetType));
        break;
      case 'h':
        paramDate.setHours(this.arithmeticOperation(paramDate.getHours(), parseInt(param.InitialValue > 0 ? param.InitialValue : '0', 10),
          param.RangeOffsetType));
        break;
      case 'm':
        paramDate.setMinutes(this.arithmeticOperation(paramDate.getMinutes(), parseInt(param.InitialValue > 0 ?
          param.InitialValue : '0', 10), param.RangeOffsetType));
        break;
      default:
        break;
    }
    return paramDate;
  }

  compare(val1, val2, op) {
    switch (op) {
      case '<=':
        return val1 <= val2;
      case '<':
        return val1 < val2;
      case '=':
        return val1 === val2;
      case '>=':
        return val1 >= val2;
      case '>':
        return val1 > val2;
      default:
        return false;
    }
  }

  arithmeticOperation(val1, val2, op) {
    if (op === '+') {
      return val1 + val2;
    }
    if (op === '-') {
      return val1 - val2;
    }
  }

  getCronStrue = (schedule) => schedule ? cronstrue.toString(schedule) : '';

  onCronChange(event) {
    this.editData.Schedule = event;
    this.isCronValid = CronEditorService.cronIsValid(this.cronOptions.cronFlavor, this.editData.Schedule);
  }

}
