import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';

@Injectable({ providedIn: 'root' })
export class PrepLabelPrintService {

  constructor(private httpService: HttpClient) { }

  prepLabelPrint(prepLabelPrint: any): Observable<any> {
    return this.httpService.put(
      `${RuntimeConstants.API_PATH}inventoryproducts/prep/label-print`, prepLabelPrint
    );
  }
}
