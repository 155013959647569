import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalComponent } from 'src/app/shared/components';

@Component({
  selector: 'pos-training-approval-modal',
  templateUrl: './training-approval-modal.component.html'
})
export class TrainingApprovalModalComponent extends ModalComponent {
  confirmMessage: string;

  constructor(
    modalRef: BsModalRef) {
    super(modalRef);
  }

  onConfirm(){
    this.hide({approve: true})
  }

  hideChildModal(){
    this.hide({});
  }

}
