<pos-list-wrapper [headingIcon]="icons.lockOpen" heading="Permissions" (close)="close()">
    <div class="row">
        <div class="col-12">
            <ng-table #permissionsGrid [columns]="permissionsColumns" [exportFilename]="'permissions'"
                [data]="permissions">
            </ng-table>
            <ng-template let-data="rowData" #operationHeaderTemplate>
                <pos-export-dropdown class="inline-block" (onPrint)="printDiv()"
                    (onCSV)="exportCSV()"></pos-export-dropdown>
            </ng-template>
            <ng-template let-data="rowData" #operationTemplate>
                <span (click)="editItem(data.Id)" tooltip title="Edit" scrollup>
                    <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
                </span>
            </ng-template>
        </div>
    </div>
</pos-list-wrapper>
<pos-print-table [hidden]="true"></pos-print-table>
