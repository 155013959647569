<div class="notification-popup" *ngIf="inboxMessages?.length && !isReadAllMessages">
    <div class="notification-popup__message" *ngIf="isDismissAllDisplay()">
        <div class="notification-popup__message__text mb-pix-10" (click)="dismissAll()">
            <div class="col-md-12 bold theme-primary">
                <div class="text-center pb-pix-10 pointer">
                    Dismiss All
                </div>
            </div>
        </div>
    </div>
    <div class="notification-popup__message"
        *ngIf="inboxMessages.length > settingParam.MaxUnreadMessagePopoutCount && !isShowAll">
        <div class="notification-popup__message__text mb-pix-10" (click)="showAll()">
            <div class="col-md-12 bold theme-primary">
                <div class="text-center pb-pix-10 pointer">
                    You have {{inboxMessages.length}} unread messages.
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="inboxMessages.length <= settingParam.MaxUnreadMessagePopoutCount || isShowAll">
        <div *ngFor="let message of inboxMessages" [hidden]="message.Marked">
            <div class="notification-popup__message">
                <div class="notification-popup__message__text mb-pix-10">
                    <div class="pointer" (click)="viewMessage(message)">
                        <h3>
                            <span class="theme-primary">
                                <pos-icon [icon]="icons.comments"></pos-icon>
                            </span> <span class="theme-separator ps-pix-3">{{message.Sender}}</span>
                        </h3>
                        <div class="col-md-12 p-pix-10" innerHTML="{{sliceMessage(message)}}">
                        </div>
                    </div>
                    <div class="col-md-12 display-flex border-top bold theme-primary action-button-container justify-content-center">
                        <div class="text-center pt-pix-10 pb-pix-10 pointer"
                            [ngClass]="{'col-md-12 col-xs-12':!isOrderEntryTerminal,'col-md-6 col-xs-6 border-right': isOrderEntryTerminal && !message.IsSystemMessage}"
                            (click)="markAsRead(message)">
                            OK
                        </div>
                        <div class="col-md-6 col-xs-6 text-center pt-pix-10 pb-pix-10 pointer" (click)="replyMessage(message)"
                            *ngIf="isOrderEntryTerminal && !message.IsSystemMessage">
                            Reply
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
