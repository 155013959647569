import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { WidgetHeaderConfig, WidgetHeaderStyleConfig } from 'src/app/menu-display/interfaces';
import { MenuWidgetService } from 'src/app/menu-display/services';
import { ExtensionValidationService, RuntimeConstants } from 'src/app/shared';
import { DomainConstants } from 'src/app/shared/constants';
import { times } from 'src/app/shared/components/icon'

@Component({
  selector: 'pos-widget-header-config',
  templateUrl: './widget-header-config.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class WidgetHeaderConfigComponent implements OnChanges {
  @Input() widgetHeaderConfig: WidgetHeaderConfig;
  @Output() widgetHeaderConfigChange = new EventEmitter<WidgetHeaderConfig>();

  @Input() headerStyle: WidgetHeaderStyleConfig;
  @Output() headerStyleChange = new EventEmitter<WidgetHeaderStyleConfig>();

  @Input() prefix: string;
  @Input() handleFileInput: (files: FileList, id: string, currentFileName: string) => string;
  @Input() removeIcon: (id: string, currentFileName: string) => void;
  menuWidgetPath = RuntimeConstants.IMAGE_BASE_PATH + '/menu-display';
  isShowHeader = true;
  textAlign = DomainConstants.TextAlign;
  fieldInfoObject = DomainConstants.WidgetFieldInfo;
  icons = { times }
  constructor(protected menuWidgetService: MenuWidgetService,
    protected extensionValidation: ExtensionValidationService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('widgetHeaderConfig') && !this.widgetHeaderConfig) {
      this.isShowHeader = false;
    }
    if (changes.hasOwnProperty('headerStyle') && !this.headerStyle && this.isShowHeader) {
      this.headerStyle = this.menuWidgetService.getNewWidgetHeaderStyleConfig();
    }
    this.widgetHeaderConfigChange.emit(this.widgetHeaderConfig);
    this.headerStyleChange.emit(this.headerStyle);
  }

  onChangeIsShowHeader() {
    if (this.isShowHeader) {
      if (!this.widgetHeaderConfig) {
        this.widgetHeaderConfig = this.menuWidgetService.getNewWidgetHeaderConfig();
      }
      if (!this.headerStyle) {
        this.headerStyle = this.menuWidgetService.getNewWidgetHeaderStyleConfig();
      }
    } else {
      this.widgetHeaderConfig = null;
      this.headerStyle = null;
    }
    this.widgetHeaderConfigChange.emit(this.widgetHeaderConfig);
    this.headerStyleChange.emit(this.headerStyle);
  }

}
