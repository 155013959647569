<pos-edit-wrapper heading="Taxonomy" (close)="cancel()" [headingIcon]="icons.taxonomyFileWord" [isPopup]="false">
  <form (ngSubmit)="saveTaxonomy()" #taxonomyForm="ngForm" class="edit-form">
    <div class="col-12 pos-page-body border-top pt-pix-5 content-scroll">
      <div class="col-12">
        <div>
          <h3 class="setting-title">Settings</h3>
        </div>
        <pos-row colSm="6" colXs="12" class="px-2">
          <pos-column>
            <pos-form-field label="Set Term Defaults">
              <ng-select posInput [items]="defaultTerms" appendTo="body" bindLabel="Name" bindValue="Value" placeholder="Select term defaults"
                [(ngModel)]="defaultTerm" name="defaultTerm" #layout="ngModel" (change)="changeDefaultTerms()">
              </ng-select>
            </pos-form-field>
          </pos-column>
          <pos-column>
            <pos-form-field label="Loyalty Account Term">
              <input posInput class="form-control" name="LoyalAccountTerm" [(ngModel)]="parameters.LoyalAccountTerm"
                type="text" />
            </pos-form-field>
          </pos-column>
          <pos-column>
            <pos-form-field label="Table Term">
              <input posInput class="form-control" name="TableTerm" [(ngModel)]="parameters.TableTerm" type="text" />
            </pos-form-field>
          </pos-column>
          <pos-column>
            <pos-form-field label="Account Term" [fieldInfo]="fieldInfoMessages.TaxonomyAccountTerm">
              <input posInput class="form-control" name="AccountTerm" [(ngModel)]="parameters.AccountTerm"
                type="text" />
            </pos-form-field>
          </pos-column>
          <pos-column>
            <pos-form-field label="Subaccount Term" [fieldInfo]="fieldInfoMessages.TaxonomySubAccountTerm">
              <input posInput class="form-control" name="SubaccountTerm" [(ngModel)]="parameters.SubaccountTerm"
                type="text" />
            </pos-form-field>
          </pos-column>
          <pos-column>
            <pos-form-field label="Session Term" [fieldInfo]="fieldInfoMessages.TaxonomySessionTerm">
              <input posInput class="form-control" name="SessionTerm" [(ngModel)]="parameters.SessionTerm"
                type="text" />
            </pos-form-field>
          </pos-column>
          <pos-column>
            <pos-form-field label="Server Term" [fieldInfo]="fieldInfoMessages.TaxonomyServerTerm">
              <input posInput class="form-control" name="ServerTerm" [(ngModel)]="parameters.ServerTerm" type="text" />
            </pos-form-field>
          </pos-column>
          <pos-column>
            <pos-form-field label="Send Order Term">
              <input posInput class="form-control" name="sendOrder" [(ngModel)]="invoiceAreaButtonText.SendOrder"
                type="text" />
            </pos-form-field>
          </pos-column>
          <pos-column>
            <pos-form-field label="Cancel Order Term">
              <input posInput class="form-control" name="cancelOrder" [(ngModel)]="invoiceAreaButtonText.CancelOrder"
                type="text" />
            </pos-form-field>
          </pos-column>
          <pos-column>
            <pos-form-field label="Review Order Term">
              <input posInput class="form-control" name="reviewOrder" [(ngModel)]="invoiceAreaButtonText.ReviewOrder"
                type="text" />
            </pos-form-field>
          </pos-column>
          <pos-column>
            <pos-form-field label="Settle Order Term">
              <input posInput class="form-control" name="PaymentTerm" [(ngModel)]="invoiceAreaButtonText.SettleOrder"
                type="text" />
            </pos-form-field>
          </pos-column>
        </pos-row>
      </div>
      <div class="col-12 section-border">
        <div>
          <h3 class="setting-title">Order Status Terminology</h3>
        </div>
        <pos-row class="px-2">
          <pos-column *ngFor="let orderState of parameters.OrderStatusStates; let i = index;" sm="6" xs="12"
            [ngClass]="{'display-none': !orderState.maketableWorkflowStates.IsSelected}">
            <pos-form-field [label]="orderState.maketableWorkflowStates.Name"
              [fieldInfo]="orderState.FieldInfo">
              <pos-icon labelPrefix [icon]="orderState.Icon" class="pe-pix-5"></pos-icon>
              <input posInput class="form-control" name="orderStatusStates_{{i}}" [(ngModel)]="orderState.OrderState"
                type="text" />
            </pos-form-field>
          </pos-column>
        </pos-row>
      </div>
    </div>
    <div class="col-12 action-button-container page-footer">
      <button class="btn btn-primary btn-lg btn-save pos-button" scrollup type="submit">Save</button>
      <button class="btn btn-secondary btn-lg btn-cancel pos-button" routerLink="../" scrollup type="button">Cancel</button>
    </div>
  </form>
</pos-edit-wrapper>
