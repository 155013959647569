<pos-edit-wrapper heading="{{title ? title:'Time Entry'}}" (close)="closeTimeEntry()">
  <div class="p-0 screen-scroll pt-pix-15 product-time-modal__inputs">
    <div class="row mx-0">
      <div class="col-lg-5 col-md-6 col-sm-7 col-12" *ngIf="isShowCalender">
        <p-calendar [(ngModel)]="scheduleDate" class="ps-pix-5 pt-pix-5" appendTo="body" [inline]="true"
          [showIcon]="true" name="scheduleDate" [readonlyInput]="true" [minDate]="minDateValue" [maxDate]="maxDateValue"
          (onSelect)="changeDate()">
        </p-calendar>
      </div>
      <div [ngClass]="isShowCalender ? 'col-lg-7 col-md-6 col-sm-5 col-12' : 'col-12'">
        <div class="col-12 pe-0">
          <div class="p-0 time-picker">
            <p-calendar class="pt-pix-5 ps-pix-5" [(ngModel)]="selectedTime" [timeOnly]="true" [inline]="true"
              (onSelect)="changeTime()" [hourFormat]="hourFormat" name="timeEntry" #ClockOutTime="ngModel"
              [inputStyle]="{'width':'215px'}" inputStyleClass="form-control">
            </p-calendar>
          </div>
          <div class="row g-0 p-0 mt-pix-20">
            <div class="col-lg-4 col-4 text-center p-0" [ngClass]="isShowCalender ? 'col-sm-12' : 'col-sm-4'">
              <div class="col-12">
                <button class="btn btn-secondary black p-pix-8 me-pix-5 pos-button" (click)="addHour(1)" type="button">
                  <img alt="" src="images/icons/icon-add-hour.png" class="add-subtract-icon" />
                </button>
                <button class="btn btn-secondary black p-pix-8 pos-button" (click)="addHour(-1)" type="button">
                  <img alt="" src="images/icons/icon-subtract-hour.png" class="add-subtract-icon" />
                </button>
              </div>
              <div class="col-12 p-0 bold font-18">
                1 Hour
              </div>
            </div>
            <div class="col-lg-4 col-4 text-center p-0" [ngClass]="isShowCalender ? 'col-sm-12' : 'col-sm-4'">
              <div class="col-12">
                <button class="btn btn-secondary black p-pix-8 me-pix-5 pos-button" (click)="addMinutes(5)"
                  type="button">
                  <img alt="" src="images/icons/icon-add-1min.png" class="add-subtract-icon" />
                </button>
                <button class="btn btn-secondary black p-pix-8 pos-button" (click)="addMinutes(-5)" type="button">
                  <img alt="" src="images/icons/icon-subtract-1min.png" class="add-subtract-icon" />
                </button>
              </div>
              <div class="col-12 p-0 bold font-18">
                5 min
              </div>
            </div>
            <div class="col-lg-4 col-4 text-center p-0" [ngClass]="isShowCalender ? 'col-sm-12' : 'col-sm-4'">
              <div class="col-12">
                <button class="btn btn-secondary black p-pix-8 me-pix-5 pos-button" (click)="addMinutes(1)"
                  type="button">
                  <img alt="" src="images/icons/icon-add-1min.png" class="add-subtract-icon" />
                </button>
                <button class="btn btn-secondary black p-pix-8 pos-button" (click)="addMinutes(-1)" type="button">
                  <img alt="" src="images/icons/icon-subtract-1min.png" class="add-subtract-icon" />
                </button>
              </div>
              <div class="col-12 p-0 bold font-18">
                1 Minute
              </div>
            </div>
          </div>

        </div>
        <div class="col-12 float-start p-0">
          <div class="col-12 p-0 bold font-18 text-center">
            Shortcuts (from now)
          </div>
          <div class="row g-0 px-pix-10">
            <div *ngFor="let slot of timeSlots"
              [ngClass]="timeSlots?.length >= 8 ? 'col-lg-3 col-4' : 'col-lg-4 col-sm-6 col-4'" class="p-pix-5">
              <span class="btn btn-secondary mb-pix-5 col-12" type="button" (click)="setAfterXMinute(slot.value)">{{
                slot.text }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 p-0 pt-pix-10 text-center" *ngIf="!isValidSelectedTime">
      <span class="red">{{scheduleOrderActiveHoursInvalidMessage}}</span>
    </div>
  </div>
  <div class="col-12 action-button-container popup-footer">
    <div class="schedule-timer-diff-stripe ms-pix-10">
      wait of: {{(waitOfTime.hour | number:'2.0') + ':' + ( waitOfTime.minute | number:'2.0')}}
    </div>
    <button class="btn btn-success btn-lg normal btn-save pos-button" (click)="enterCallInTime(true)" type="button"
      *ngIf="isRescheduleOrder">
      Activate
    </button>
    <button class="btn btn-primary btn-lg btn-save pos-button" (click)="enterCallInTime();" type="button">
      OK
    </button>
    <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="closeTimeEntry()"
      type="button">Cancel</button>
  </div>

</pos-edit-wrapper>
