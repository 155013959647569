<pos-edit-wrapper heading="{{ id > 0 ? 'Edit' : 'Add' }} Location Address" (close)="closeModal()">
    <form (ngSubmit)="submit(locationEditForm.valid)" #locationEditForm="ngForm" class="edit-form">
        <div class="col-12 pos-modal-body">
            <pos-row>
                <pos-column>
                    <pos-form-field label="Customer Id" [validationMessages]="{ required: 'Please enter customer id'}">
                        <input posInput class="form-control" name="customerId" [(ngModel)]="data.customerId" type="text" [required]="true" />
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Name" [validationMessages]="{ required: 'Please enter name'}">
                        <input posInput class="form-control" name="name" [(ngModel)]="data.name" type="text" [required]="true" />
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Location" [validationMessages]="{ required: 'Please enter location'}" inputStyle="col-8">
                        <textarea posInput class="form-control" name="location" [(ngModel)]="data.address" [required]="true">
                        </textarea>
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field  label="URL Slug" [validationMessages]="{ required: 'Please enter slug'}">
                        <p class="input-group">
                            <span class="input-group-addon">/</span>
                            <input posInput class="form-control" name="urlSlug" [(ngModel)]="data.urlSlug" type="text" [required]="true" />
                        </p>
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button class="btn btn-primary btn-lg btn-save pos-button" type="submit">Save</button>
            <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="closeModal()"
                type="button">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
