<div class="display-flex flex-wrap" *ngIf="ingredientsInfo && ingredientsInfo !== ''">
    <div class="ingredient-info-card card">
        <div class="card-body">
            <div class="card-text overflow-tab custom-scrollbar">
                <span class="ingredient-info">
                    <span class="bold">Ingredients: </span>
                    <span>{{ ingredientsInfo }}</span>
                </span>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!ingredientsInfo || ingredientsInfo.trim() == ''" class="p-pix-10">
    <span class="ingredient-info">No ingredients found.</span>
</div>
