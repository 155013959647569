<pos-edit-wrapper (close)="cancelEditing()" heading="{{unitId ? 'Edit Unit' : 'Add Unit'}}">
  <form #formUnit="ngForm" (ngSubmit)='saveUnit(formUnit.valid)'>
    <div class="overflow-hidden">
      <div class="col-12 pos-modal-body screen-scroll">
        <pos-row>
          <pos-column>
            <pos-form-field label="Unit Name" [validationMessages]="{required: 'Please enter unit name.'}">
              <input posInput name="unitName" #unitName="ngModel" type="text" class="form-control"
                [(ngModel)]="unit.Name" [required]="true" />
            </pos-form-field>
          </pos-column>
          <pos-column>
            <div class="label-section pt-pix-10 pb-pix-10">
              <p-checkbox posInput name="unitIncrement" #unitIncrement="ngModel" [(ngModel)]="isUnitQtyIncrement" binary="true"
                (onChange)="unit.Increment = null" label="Increments" />
            </div>
            <pos-form-field [appendPaddingTop]="false"
              [validationMessages]="{max: 'Value for increments must not be greater than 9.'}">
              <input posInput type="number" name="increments" #increments="ngModel" class="form-control mcol-100"
                max="9" step="0.01" [(ngModel)]="unit.Increment" [disabled]="!isUnitQtyIncrement" />
            </pos-form-field>
          </pos-column>
        </pos-row>
      </div>
      <div class="col-12 action-button-container popup-footer">
        <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" scrollup value="Save">Save</button>
        <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" value="Cancel"
          (click)="cancelEditing()">Cancel</button>
      </div>
    </div>
  </form>
</pos-edit-wrapper>
