import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BaseFormComponent, ModalService, UnsavedFormDataComponent } from '../components';

export declare interface IDeactivateGuard {
  canDeactivate: () => boolean | Promise<boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class DeactivateGuard  {

  constructor(private modalService: ModalService) {

  }
  canDeactivate(
    component: IDeactivateGuard,
    _currentRoute: ActivatedRouteSnapshot,
    _currentState: RouterStateSnapshot,
    _nextState?: RouterStateSnapshot): boolean | Promise<boolean> {
    if ((component instanceof BaseFormComponent) && !component?.canDeactivate()) {
      return this.openConfirmModal();
    }
    return true;
  }

  openConfirmModal(): Promise<boolean> {
    return new Promise<boolean>((resolve, _reject) => {
      const modalRef = this.modalService.getModalWrapper(UnsavedFormDataComponent);
      const modal = modalRef.show({
        animated: false,
        class: 'vertical-center',
        'backdrop': 'static',
        initialState: {
        }
      });
      modal.close.subscribe(res => {
        return resolve(res.shouldHide);
      });
    });
  }

}
