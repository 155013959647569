<pos-list-wrapper [headingIcon]="icons.workScheduleCalendarAlt" heading="Work Schedule" (close)="close()">
    <div class="row">
        <div class="col-12 d-flex justify-content-start flex-row-reverse flex-wrap">
            <button class="btn btn-primary btn-lg white mt-pix-5 ms-pix-15 normal pos-button"
                (click)="onImportWorkSchedule()" type="button" value="Work Schedule">Import Work
                Schedule</button>
            <button class="btn btn-primary btn-lg white mt-pix-5 ms-pix-15 normal pos-button"
                (click)="loadUserWorkSchedule()" type="button" value="Work Schedule">Work Schedule</button>
            <button class="btn btn-primary btn-lg white mt-pix-5 ms-pix-15 normal pos-button" (click)="loadBathEntry()"
                type="button" value="Batch Entry">Batch Entry</button>
            <button class="btn btn-info btn-lg white mt-pix-5 ms-pix-15 normal pos-button" (click)="showTags()"
                type="button" value="Tags">Tags</button>
        </div>
    </div>
    <div class="row mt-pix-10 mb-pix-10 pe-pix-15 justify-content-start flex-row-reverse">
        <input type="submit" value="2 Weeks" (click)="filterWorkScheduleList(filterBy.TwoWeeks)"
            [ngClass]="{'fltr-btn-active nohover' : currentFilter === filterBy.TwoWeeks}"
            class="fltr-btn" />
    
        <input type="submit" value="Last 7 Days" (click)="filterWorkScheduleList(filterBy.Last7Days)"
            [ngClass]="{'fltr-btn-active nohover' : currentFilter === filterBy.Last7Days}"
            class="fltr-btn" />
    
        <input type="submit" value="Next 7 Days" (click)="filterWorkScheduleList(filterBy.Next7Days)"
            [ngClass]="{'fltr-btn-active nohover' : currentFilter === filterBy.Next7Days}"
            class="fltr-btn" />
    
        <input type="submit" value="Today" (click)="filterWorkScheduleList(filterBy.Today)"
            [ngClass]="{'fltr-btn-active nohover' : currentFilter === filterBy.Today}" class="fltr-btn" />
    
        <input type="submit" value="All" (click)="clearFilter()"
            [ngClass]="{'fltr-btn-active nohover' : currentFilter === ''}" class="fltr-btn" />
    </div>
    <div class="row">
        <div class="col-md-12 col-sm-12">
                <ng-table [sort]="true" [columns]="workScheduleColumns" [exportFilename]="'work-schedule'" #workScheduleGrid [data]="workSchedules">
                </ng-table>
        </div>
    </div>
</pos-list-wrapper>
<pos-print-table [hidden]="true"></pos-print-table>
