import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertsService, ApplicationStateService, DomainConstants, FormUtilityService, MarketingPromotionTypesService, Messages, PromotionDataService } from 'src/app/shared';
import { BaseCrudComponent, SpinnerService } from 'src/app/shared/components';
import { infoCircle, clock, clipboardList, coins, newProduct, IconSpecification } from 'src/app/shared/components/icon';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { cloneDeep, filter, find, forEach, isArray, map } from 'lodash';
import { PromotionRule } from 'src/app/shared/interface/rules/promotion-rule';
import { NgForm } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { PromotionType } from '../../interfaces/promotion-type';
import { PromotionService } from 'src/app/shared/services/rules/promotion.service';
import { Promotion, PromotionJsonModel } from 'src/app/shared/interface/rules';
import { PromotionConfig } from 'src/app/shared/interface';
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pos-promotion-config',
  templateUrl: './promotion-config.component.html',
  styleUrls: ['./promotion-config.component.scss'],
  providers: [PromotionDataService]
})
export class PromotionConfigComponent extends BaseCrudComponent<Promotion> implements OnInit {

  @Input() promotion: Promotion;
  @Input() operators;
  @ViewChild('formPromotionConfig') formPromotionConfig: NgForm;

  get getForm(): NgForm {
    return this.formPromotionConfig;
  }

  newRuleModel: PromotionRule;
  configuredRewards: Array<PromotionConfig> = [];
  promotionType;
  icon: IconSpecification;
  tabList = {
    Info: 'Info',
    Schedule: 'Schedule',
    Criteria: 'Criteria',
    Rewards: 'Rewards'
  };
  selectedTab = this.tabList.Info;

  icons = {
    infoCircle, clock, clipboardList, coins, newProduct
  };
  criteria: Array<PromotionConfig> = [];
  rewards: Array<PromotionConfig> = [];
  rewardAfterEligibility = DomainConstants.RewardAfterEligibility;
  minDate = new Date();
  isDateScheduled = false;
  isTimeScheduled = false;
  isDaysScheduled = false;
  dateFormat = 'mm-dd-yyyy';
  daysOfWeek = [];
  selectedDays = [];
  isAnyRewardOrConfigEmpty = false;
  isAnyEligibilityOrConfigEmpty = false;
  props = {
    controlBoxClass: 'col-lg-4 col-md-6 col-sm-8 col-xs-12'
  }
  promotionTypes: Array<PromotionType> = [];

  constructor(protected spinnerService: SpinnerService,
    protected alertService: AlertsService,
    private applicationStateService: ApplicationStateService,
    protected router: Router,
    private route: ActivatedRoute,
    protected promotionService: PromotionService,
    private marketingPromotionsService: MarketingPromotionTypesService,
    protected promotionData: PromotionDataService,
    protected formUtilityService: FormUtilityService) {
    super(promotionService, alertService, spinnerService, formUtilityService);
    const promotionType = this.promotionService.getPromotionTypeByRoute(route.snapshot?.url[0]?.path);
    this.promotionType = promotionType;
    this.icon = promotionType.Icon;
    this.promotion = promotionService.newPromotion();
    this.id = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
  }

  ngOnInit(): void {
    this.dateFormat = this.applicationStateService.settingParam.PCalendarDateFormat;
    this.saveSuccessMessage = Messages.PromotionSaveSuccess;
    const weekStart = parseInt(find(DomainConstants.WeekDay, (dayId, day) => day === this.applicationStateService.settingParam.WeekStart), 10) ?? 0;
    const daysOfWeek = [];
    forEach(DomainConstants.WeekDay, (value, key) => {
      daysOfWeek.push({ Id: value, Name: key });
    });
    this.daysOfWeek = [...daysOfWeek.slice(weekStart, daysOfWeek.length), ...daysOfWeek.slice(0, weekStart)];
  }

  addReward() {
    this.configuredRewards.push(this.promotionData.newPromotionConfig());
  }

  validatePromotionDetails() {
    this.promotionData.validateRuleAndReward(this.newRuleModel.Rules, this.configuredRewards);
    this.isAnyRewardOrConfigEmpty = this.configuredRewards.some(x => !x.Name || x.Config.some(c => (isArray(c.Value) ? !c.Value.length : !c.Value) && (c.IsRequired || c.IsInvalid)));
    this.isAnyEligibilityOrConfigEmpty = this.promotionData.isEligibilityValid(this.newRuleModel);
    setTimeout(() => {
      this.savePromotion();
    });
  }

  savePromotion() {
    if (!this.formPromotionConfig.valid || this.isAnyEligibilityOrConfigEmpty || this.isAnyRewardOrConfigEmpty) {
      return;
    }
    if (this.promotion.StartTime > this.promotion.EndTime) {
      this.alertService.renderErrorMessage(Messages.ErrorWhileStartTimeIsGreaterEndTime);
      return;
    }
    this.promotion.Type = this.promotionType.DisplayName;
    this.setSchedule();
    this.promotion.Reward = this.convertConfigToJson(this.configuredRewards ?? []);
    const config = this.promotionService.preparePromotionRule(this.newRuleModel);
    this.promotion.Criteria = JSON.stringify(config);
    this.save(this.promotion);
  }

  setSchedule() {
    if (!this.isDateScheduled) {
      this.promotion.StartDate = null;
      this.promotion.EndDate = null;
    }
    if (!this.isTimeScheduled) {
      this.promotion.StartTime = null;
      this.promotion.EndTime = null;
    }
    this.promotion.Days = this.isDaysScheduled && this.selectedDays?.length ? this.selectedDays.join(',') : null;
  }

  convertConfigToJson(promotionConfig: Array<PromotionConfig>): string {
    let promotionJsonConfig: Array<PromotionJsonModel> = [];
    forEach(promotionConfig, config => {
      if (config) {
        let promoConfigParam = {};
        forEach(config.Config, param => {
          promoConfigParam[param.Name] = param.Value;
        });
        promotionJsonConfig.push({ Name: config.Name, Config: promoConfigParam });
      }
    });
    return JSON.stringify(promotionJsonConfig);
  }

  prepareRewards(config: string) {
    let promoConfig: Array<PromotionJsonModel> = JSON.parse(config);
    if (promoConfig?.length) {
      forEach(promoConfig, config => {
        const rewards = cloneDeep(this.rewards);
        let promotionConfig = find(rewards, x => x.Name == config.Name);
        if (promotionConfig) {
          forEach(promotionConfig.Config, param => {
            param.Value = config.Config[param.Name];
          });
          this.configuredRewards.push(promotionConfig);
        } else {
          this.addReward();
        }
      });
    } else {
      this.addReward();
    }
  }

  onSaveSuccess(model: Promotion): void {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
  loadDependencies(): void {
    this.promotionData.loadData();
    const observables: Array<Observable<any>> = [];
    observables.push(this.promotionService.getCriteria());
    observables.push(this.promotionService.getRewards());
    observables.push(this.marketingPromotionsService.getPromotionTypes());
    if (this.id) {
      observables.push(this.loadData());
    }
    this.spinnerService.show();
    forkJoin(observables)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: ([criteria, rewards, promotionTypes, promotion]:
          [Array<PromotionConfig>, Array<PromotionConfig>, Array<PromotionType>, Promotion]) => {
          this.criteria = filter(criteria, x => x.Categories.length ? x.Categories.includes(this.promotionType.Name) : !x.IsOperator);
          this.operators = filter(criteria, x => x.IsOperator);
          this.rewards = filter(rewards, x => x.Categories.length ? x.Categories.includes(this.promotionType.Name) : true);
          this.promotionTypes = promotionTypes;
          map(promotionTypes, type => type.Name = type.PromotionCategory.Name + ': ' + type.Name);
          if (promotion) {
            this.promotion = promotion;
            this.promotion.StartDate = this.promotion.StartDate ? new Date(this.promotion.StartDate) : null;
            this.promotion.EndDate = this.promotion.EndDate ? new Date(this.promotion.EndDate) : null;
            this.setScheduleFlags();
            this.prepareRewards(promotion.Reward);
            this.newRuleModel = this.promotionService.setPromotionRule(promotion.Criteria, criteria);
          } else {
            this.addReward();
          }
        }, error: this.alertService.showApiError
      });
  }
  setScheduleFlags() {
    this.isDateScheduled = this.promotion.StartDate != null;
    this.isTimeScheduled = this.promotion.StartTime != null;
    if (this.promotion.Days) {
      this.isDaysScheduled = true;
      this.selectedDays = this.promotion.Days.split(',');
    }
  }

  cancel() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  startDateChanged() {
    if (this.promotion.StartDate > this.promotion.EndDate) {
      this.promotion.EndDate = null;
    }
  }

}
