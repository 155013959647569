import { Injectable } from "@angular/core";
import { IKioskNavigation } from "../interfaces/ikiosk-navigation";
import { ChoiceBehavior } from "./choices-behavior";
import { KioskScreen } from "../interfaces";
import { KioskNavigationService } from "../services";
import { DomainConstants } from "src/app/shared";
import { forEach } from "lodash";

@Injectable({
    providedIn: 'root'
})
export class FilterProductsBehavior extends ChoiceBehavior {

    constructor(private kioskNavigationService: KioskNavigationService) {
        super();
    }

    execute(navObj: IKioskNavigation, orderNavigation: KioskScreen): Promise<void> {
        const tagsBehavior = navObj.button.ChoiceBehaviors.find(x => x.Name == DomainConstants.KioskButtonBehaviors.FilterProducts.Value);
        forEach(tagsBehavior?.TagBehaviors, (t) => {
            this.kioskNavigationService.versionedVal.Obj.filterProductTags.push(t.TagId)
        });
        return Promise.resolve();
    }
}
