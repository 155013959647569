import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertsService, ButtonBehavior, Messages, ModalService, SpinnerService } from 'src/app/shared';
import { DomainConstants } from 'src/app/shared/constants/domain-constants';
import { ButtonBehaviorService } from '../../service/button-behavior.service';
import { DisplayMessageComponent } from '../display-message';
import { FlashButtonComponent } from '../flash-button';
import { ButtonInventoryIncrementDecrementComponent } from '../button-inventory-increment-decrement';
import { SelectButtonComponent } from '../select-button/select-button.component';
import { TemporaryNavigationComponent } from '../temporary-navigation';
import { OrderNavigationButton } from 'src/app/orders/interface/order-navigation-button';
import { SingularFeeComponent } from '../singular-fee/singular-fee.component';
import { MultipleProductComponent } from '../multiple-product/multiple-product.component';
import { AutoDiscountComponent } from '../auto-discount/auto-discount.component';
import { AutoMarkupComponent } from '../auto-markup/auto-markup.component';
import { SetSizeComponent } from '../set-size/set-size.component';
import { MainProductComponent } from '../main-product/main-product.component';
import { DetailEntryComponent } from '../detail-entry/detail-entry.component';
import { AddCommentComponent } from '../add-comment/add-comment.component';
import { cloneDeep } from 'lodash';
import { FilterProductComponent } from '../filter-product/filter-product.component';

@Component({
  standalone: true,
  selector: 'pos-button-behavior-edit',
  templateUrl: './button-behavior-edit.component.html',
})
export class ButtonBehaviorEditComponent implements OnInit {

  @Input() buttonId = 0;
  @Input() screenToggleButtons: Array<OrderNavigationButton>;
  @Input() selectedButtonBehaviorType;
  @Input() buttonBehavior: ButtonBehavior = null;
  @Input() buttonList = [];
  @Input() buttonType: string;
  @Input() selectedFunction: string;
  @Input() screens: Array<any> = [];
  buttonBehaviors;
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() onSave: EventEmitter<any> = new EventEmitter();

  constructor(protected modalService: ModalService,
    protected buttonBehaviorService: ButtonBehaviorService,
    protected spinnerService: SpinnerService,
    protected alertsService: AlertsService) { }

  ngOnInit(): void {
    this.buttonBehaviors = Object.assign(cloneDeep(DomainConstants.ButtonBehaviors), cloneDeep(DomainConstants.KioskButtonBehaviors));
    this.loadModal();
  }

  loadModal() {
    switch (this.selectedButtonBehaviorType.Value) {
      case this.buttonBehaviors.DisplayMessage.Value:
        this.openModal(DisplayMessageComponent);
        break;
      case this.buttonBehaviors.DisplayToaster.Value:
        this.openModal(DisplayMessageComponent);
        break;
      case this.buttonBehaviors.UncheckButtons.Value:
        this.openModal(SelectButtonComponent, 'modal-max-width-40');
        break;
      case this.buttonBehaviors.UncheckAllButtons.Value:
        this.onSave.emit(null);
        break;
      case this.buttonBehaviors.SelectButtons.Value:
        this.openModal(SelectButtonComponent, 'modal-max-width-40');
        break;
      case this.buttonBehaviors.FlashButtons.Value:
        this.openModal(FlashButtonComponent, 'modal-max-width-40');
        break;
      case this.buttonBehaviors.TemporaryNav.Value:
        this.openModal(TemporaryNavigationComponent);
        break;
      case this.buttonBehaviors.IncrementInventory.Value:
        this.openModal(ButtonInventoryIncrementDecrementComponent);
        break;
      case this.buttonBehaviors.DecrementInventory.Value:
        this.openModal(ButtonInventoryIncrementDecrementComponent);
        break;
      case this.buttonBehaviors.SendPressEvent.Value:
        this.onSave.emit(null);
        break;
      // case this.buttonBehaviors.RevenueCenter.Value:
      //   this.openModal(RevenueCenterComponent);
      //   break;
      case this.buttonBehaviors.SignIn.Value:
        this.onSave.emit(null);
        break;
      case this.buttonBehaviors.SingularFee.Value:
        this.openModal(SingularFeeComponent);
        break;
      case this.buttonBehaviors.MultipleProduct.Value:
        this.openModal(MultipleProductComponent);
        break;
      case this.buttonBehaviors.AutoDiscount.Value:
        this.openModal(AutoDiscountComponent);
        break;
      case this.buttonBehaviors.AutoMarkup.Value:
        this.openModal(AutoMarkupComponent);
        break;
      case this.buttonBehaviors.AddComment.Value:
        this.openModal(AddCommentComponent);
        break;
      case this.buttonBehaviors.AddCommentToAllProducts.Value:
        this.openModal(AddCommentComponent);
        break;
      case this.buttonBehaviors.AddMainProduct.Value:
        this.openModal(MainProductComponent);
        break;
      case this.buttonBehaviors.AddDetailEntry.Value:
        this.openModal(DetailEntryComponent);
        break;
      case this.buttonBehaviors.SetSize.Value:
        this.openModal(SetSizeComponent);
        break;
      case this.buttonBehaviors.FilterProducts.Value:
        this.openModal(FilterProductComponent);
        break;
      case this.buttonBehaviors.CallShakeGenerateAPI.Value:
        this.onSave.emit(null);
        break;
      default:
        break;
    }
  }

  openModal<TComponent>(component: TComponent, extraClass = ''): void {
    const modalRef = this.modalService.getModalWrapper(component);
    const modal = modalRef.show({
      keyboard: false,
      class: 'vertical-center ' + extraClass,
      initialState: {
        selectedButtonBehaviorType: this.selectedButtonBehaviorType,
        buttonBehavior: this.buttonBehavior,
        buttons: this.buttonList,
        screenToggleButtons: this.screenToggleButtons,
        buttonType: this.buttonType,
        selectedFunction: this.selectedFunction,
        screens: this.screens,
        isAddCommentToOrder: this.selectedButtonBehaviorType.Value == this.buttonBehaviors.AddComment.Value
      }
    });
    modal.close.subscribe(res => {
      if (res) {
        if (res.shouldReload) {
          this.onSave.emit(res.Value);
        } else {
          this.close.emit({ shouldReload: false });
        }
      }
    });
  }

}
