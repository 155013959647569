<div id="table" class="receipt-script-page">
    <div class="pt-pix-10">
        <div class="left-nav-toggle" id="menu-toggle-div" (click)="toggleFullScreen()">
            <a class="left-nav-toggle__icon">
                <pos-icon class="black" [icon]="isFullScreen ? icons.angleDoubleRight : icons.angleDoubleLeft">
                </pos-icon>
            </a>
        </div>
    </div>
    <div class="tab-section p-0">
        <div class="vertical-tabs">
            <ul class="nav nav-pills nav-stacked overflow-auto-tabs screen-scroll" *ngIf="!isFullScreen">
                <div class="receipt-script-page-nav nav nav-pills flex-column nav-stacked">
                    <li class="tab-head">
                        Print Script (JPS)
                    </li>
                    <li class="pointer" role="presentation" [ngClass]="{'active': activeTabIndex == item.Id}" scrollup
                        *ngFor="let item of onlyReceiptPrint" (click)="selectedVerticalTab(item)">
                        <a class="display-flex">
                            <div class="d-flex">
                                <div class="flex-grow">{{item.Name}}</div>
                                <div *ngIf="item.IsSystem" class="float-end text-center col-33">
                                    <pos-icon [icon]="icons.receiptDesignerLock" class="black font-x-normal">
                                    </pos-icon>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li class="tab-head">
                        Label Script (ZPL)
                    </li>
                    <li class="pointer" role="presentation" [ngClass]="{'active': activeTabIndex == item.Id}" scrollup
                        *ngFor="let item of notReceiptPrint" (click)="selectedVerticalTab(item)">
                        <a class="display-flex">
                            <div class="d-flex">
                                <div class="flex-grow">{{item.Name}}</div>
                                <div *ngIf="item.IsSystem" class="float-end text-center col-33">
                                    <pos-icon [icon]="icons.receiptDesignerLock" class="black font-x-normal">
                                    </pos-icon>
                                </div>
                            </div>
                        </a>
                    </li>
                </div>
            </ul>
            <div class="tab-content px-3 row g-0">
                <div class="col-lg-9 col-md-9 col-sm-7 col-12 pe-3 mb-pix-15">
                    <div class="half-box-div h-100 mti-0 p-pix-10" *ngIf="activeTabIndex != -1"
                        [ngClass]="isFullScreen? 'full-box-div' : ''">
                        <div class="col-md-12 p-0">
                            <a class="close close--small" (click)="hideActiveTabContent()">×</a>
                            <h3 class="page-content__header">{{selectedReceipt.Name}}</h3>
                        </div>
                        <ngx-codemirror class="col-lg-12 col-md-12 p-0" [(ngModel)]="oldScript"
                            [options]="codeMirrorOptions">
                        </ngx-codemirror>
                    </div>
                </div>
                <div class="row col-lg-3 col-md-3 col-sm-5 col-12 float-end script-section__preview mb-pix-15"
                    [ngStyle]="{'height': getHeight(true) + 'px'}" *ngIf="isShowButtons">
                    <div class="script-section__preview__block" [ngStyle]="{'height': getHeight(false) + 'px'}"
                        id="sampleReceiptDiv">
                    </div>
                </div>
                <div class="script-section__footer col-12" *ngIf="isShowButtons">
                    <div class="dropdown d-inline-block me-pix-10 pb-1">
                        <button class="btn dropdown-toggle  btn-secondary btn-lg normal pos-button" aria-expanded="true"
                            data-bs-toggle="dropdown">
                            <pos-icon [icon]="icons.receiptDesignerPlusSquare"></pos-icon> New Template
                        </button>

                        <ul class="dropdown-menu dropdown-menu-end" role="menu">
                            <li role="presentation" (click)="loadCreateTemplateModal('Print Script')">
                                <a class="dropdown-item" role="menuitem" tabindex="-1">
                                    <div class="display-flex">Print Script (JPS)</div>
                                </a>
                            </li>
                            <li role="presentation" (click)="loadCreateTemplateModal('Label Script')">
                                <a class="dropdown-item" role="menuitem" tabindex="-1">
                                    <div class="display-flex">Label Script (ZPL)</div>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <button class="btn btn-secondary btn-lg normal me-pix-10 mb-1 pos-button"
                        (click)="deletePrinterScript()" [disabled]="activeTabIndex < 0 || selectedReceipt.IsSystem"
                        scrollup>
                        <pos-icon [icon]="icons.receiptDesignerTimesCircle"></pos-icon> Delete
                    </button>
                    <button class="btn btn-secondary btn-lg normal me-pix-10 mb-1 pos-button"
                        (click)="showReceiptSample()" [disabled]="activeTabIndex < 0 || !isReceiptPrint" scrollup>
                        <pos-icon [icon]="icons.receiptDesignerArrowCircleRight"></pos-icon> Sample
                    </button>
                    <button class="btn btn-secondary btn-lg normal me-pix-10 mb-1 pos-button"
                        (click)="saveReceiptScript()" [disabled]="activeTabIndex < 0" scrollup>
                        <pos-icon [icon]="icons.receiptDesignerCheckCircle"></pos-icon> Save
                        Changes
                    </button>
                    <button class="btn btn-secondary btn-lg normal mb-1 pos-button" (click)="revertDataReceiptScript()"
                        [disabled]="activeTabIndex < 0" scrollup>
                        <pos-icon [icon]="icons.receiptDesignerArrowCircleLeft"></pos-icon> Revert
                    </button>
                </div>
            </div>
        </div>

    </div>
</div>
