import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';
import { Dayparts } from '../interface';

@Injectable({
  providedIn: 'root'
})
export class DaypartsService extends BaseCrudService<Dayparts> {

  daypartsBaseApiPath = `${RuntimeConstants.API_PATH}dayparts`;

  constructor(private httpClient: HttpClient) {
    super('dayparts', httpClient);
  }

  newDayparts() {
    const daypart: Dayparts = {
      Id: 0,
      Name: null,
      StartTime: null,
      EndTime: null,
      HasBetweeenStartTime: false,
      HasBetweeenEndTime: false
    };
    return daypart;
  }

  save(daypartsList: Array<Dayparts>) {
    return this.httpClient.post(this.daypartsBaseApiPath, daypartsList);
  }
}
