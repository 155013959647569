import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AlertsService, ApplicationStateService, SpinnerService, SettingParam, Messages, RuntimeConstants, BaseFormComponent, FormUtilityService
} from 'src/app/shared';
import { finalize } from 'rxjs/operators';
import { IconsService } from '../../services/icons.service';
import { Router } from '@angular/router';
import { ExtensionValidationService } from 'src/app/shared/services/extension-validation.service';
import { iconsVectorSquare } from 'src/app/shared/components/icon';
import { NgForm } from '@angular/forms';
import { PostSavedConfigurationService } from 'src/app/configurator/post-saved-configuration.service';
@Component({
  selector: 'pos-icons',
  templateUrl: './icons.component.html',
})
export class IconsComponent extends BaseFormComponent implements OnInit {
  @ViewChild('iconsForm', { static: true }) form: NgForm;
  get getForm(): NgForm {
    return this.form
  }
  parameters: SettingParam;
  imagePath: string;
  updatedIconFileName = '';
  selectedFiles: File;
  icons = {
    iconsVectorSquare
  };

  constructor(
    private spinnerService: SpinnerService,
    private iconsService: IconsService,
    private alertsService: AlertsService,
    private applicationStateService: ApplicationStateService,
    private router: Router,
    private postSavedConfigurationService: PostSavedConfigurationService,
    private validationService: ExtensionValidationService,
    formUtilityService: FormUtilityService) {
    super(formUtilityService);
    this.parameters = applicationStateService.newSettingParams();
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.spinnerService.show();
    this.iconsService.getIconsDetail(this.applicationStateService.terminalId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: SettingParam) => {
          this.parameters = res;
          this.imagePath = `${RuntimeConstants.IMAGE_BASE_PATH}/buttons/${this.parameters.AutoNextButtonIcon}`;
          this.onFormChange(this.form);
        }, error: (error) => this.alertsService.showApiError(error)
      });
  }

  // Choose file
  uploadIconsFile(files) {
    if (files && files[0]) {
      this.selectedFiles = files[0];
      this.updatedIconFileName = files[0].name;
    }
    if (this.validationService.isInvalidExtension(this.updatedIconFileName)) {
      if (this.updatedIconFileName) {
        this.alertsService.renderErrorMessage(Messages.InvalidReceiptLogoFileType);
      }
      return;
    }
    // Read file from input
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imagePath = reader.result.toString();
      };
      reader.readAsDataURL(files[0]);
    }
  }

  saveIcons(isValid: boolean) {
    if (this.updatedIconFileName && this.validationService.isInvalidExtension(this.updatedIconFileName)) {
      this.alertsService.renderErrorMessage(Messages.InvalidReceiptLogoFileType);
      return;
    }
    if (!isValid) {
      return;
    }
    this.parameters.AutoNextButtonIcon = (this.updatedIconFileName && this.updatedIconFileName !== '') ?
      this.updatedIconFileName : this.parameters.AutoNextButtonIcon;
    this.spinnerService.show();
    this.iconsService.saveIcons(this.parameters, this.selectedFiles)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.alertsService.renderSuccessMessage(Messages.IconsSaveSuccess);
          this.postSavedConfigurationService.showMessage();
        }
      });
  }

  onFormChange($event: NgForm) {
    this.form = $event;
    this.setInitialValue();
  }

  cancelEditing() {
    this.router.navigate(['/manage/system-configuration']);
  }
}
