import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants';
import { RefundOrder } from '../interface';
import { MergeOrder } from '../interface/merge-order';

@Injectable({
  providedIn: 'root'
})
export class OrderMaintenanceService {

  constructor(private httpService: HttpClient) { }

  newMergeOrder() {
    return {
      SourceOrderId: 0,
      DestinationOrderId: 0,
      TerminalId: 0
    } as MergeOrder;
  }

  mergeOrder(mergeOrderModel: MergeOrder) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}orders/merge-order`, mergeOrderModel);
  }

  markUnservedOrder(orderId: number) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}orders/${orderId}/mark-unserved`, null);
  }

  applyRefundViaCash(refundOrder: RefundOrder) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}orders/${0}/refund/record/cash`, refundOrder);
  }

  applyRefundViaIntegratedCreditCard(refundOrder: RefundOrder) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}orders/${0}/refund/request/credit-card`, refundOrder);
  }

  applyRefundViaPeripheralCreditCard(refundOrder: RefundOrder) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}orders/${0}/refund/record/credit-card`, refundOrder);
  }

  applyRefundViaEBT(refundOrder: RefundOrder) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}orders/${0}/refund/record/ebt`, refundOrder);
  }

}
