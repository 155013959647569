import { Component, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormUtilityService, ModalFormComponent, ModalService } from 'src/app/shared';
import { LocationAddress } from '../../../interface/location-address';

@Component({
  selector: 'pos-location-address-edit',
  templateUrl: './location-address-edit.component.html',
  styleUrls: []
})
export class LocationAddressEditComponent extends ModalFormComponent {

  @Input() id: number = 0;
  @Input() data: LocationAddress;
  @ViewChild('locationEditForm') baselineExpensesForm: NgForm;

  get getForm(): NgForm {
    return this.baselineExpensesForm;
  }

  constructor(public modalRef: BsModalRef,
    public modalService: ModalService, 
    public formUtilityService: FormUtilityService) {
    super(modalRef, modalService, formUtilityService);
  }

  submit(isValid: boolean) {
    if (!isValid) return;
    this.closeModal({
      shouldReload: true,
      data: this.data,
    })
  }

  closeModal(data: any =  null) {
    this.hide(data);
  }
}
