<pos-edit-wrapper heading="Training Level" [isPopup]="false" (close)="onCancel()">
  <h4><b><span class="header-lightgrey pointer ps-pix-20" (click)="onCancelTopic()">Area: {{areaName}}</span></b></h4>
  <h4 class="pb-pix-10"><b><span class="header-lightgrey pointer ps-pix-20" (click)="onCancel()">Topic: {{topicName}}</span></b></h4>
  <form #formTrainingTopicLevel="ngForm" (ngSubmit)="saveTrainingTopicLevel(formTrainingTopicLevel.valid)"
    autocomplete="off">
    <div class="col-12 border-top pos-page-body screen-scroll pt-pix-5">
      <div class="col-12" [style]="{'height': getHeight() + 'px'}">
        <context-provider provide="props">
        <pos-row>
          <pos-column>
            <pos-form-field label="Description" [validationMessages]="{required: 'Please enter topic description.'}">
              <input posInput type="text" id="topicLevelDescription" name="topicLevelDescription"
                #topicLevelDescription="ngModel" class="form-control" [(ngModel)]="topicLevel.Description" [required]="true" />
            </pos-form-field>
          </pos-column>
  
          <pos-column>
            <pos-form-field label="Instructional Text" inputStyle="col-md-7 col-12">
              <ckeditor posInput [(ngModel)]="topicLevel.PrerequisiteText" #ckeditor [editorUrl]="'assets/ckeditor/ckeditor.js'"
                name="PrerequisiteText">
              </ckeditor>
            </pos-form-field>
          </pos-column>
  
          <pos-column>
            <pos-form-field label="Video URL">
              <input type="text" id="topicLevelVideoUrl" name="topicLevelVideoUrl" #topicLevelVideoUrl="ngModel"
                class="form-control" [(ngModel)]="topicLevel.VideoUrl" />
            </pos-form-field>
          </pos-column>
  
          <pos-column>
            <pos-row class="p-0">
              <pos-column xs="12" sm="12" md="2">
                <pos-form-field label="Level Score" [validationMessages]="{required: 'Please enter level score.', min: 'Please enter positive score', pattern: 'Please enter integer values.'}">
                  <input posInput type="number" id="topicLevelScore" name="topicLevelScore" #topicLevelScore="ngModel"
                    class="form-control col-75" [(ngModel)]="topicLevel.LevelScore" min="1" pattern="[0-9]+" [required]="true" />
                </pos-form-field>
              </pos-column>
  
              <pos-column xs="12" sm="12" md="4">
                <pos-form-field label="Succession Limit Delay Minutes" [validationMessages]="{min: 'Please enter positive succession limit delay minutes.'}">
                  <input posInput type="number" id="topicLevelDelayMinutes" name="topicDelayMinutes"
                    #topicLevelDelayMinutes="ngModel" class="form-control col-75"
                    [(ngModel)]="topicLevel.SuccessionLimitDelayMinutes" min="1" />
                </pos-form-field>
              </pos-column>
            </pos-row>
  
          </pos-column>
  
          <pos-column *ngIf="topicLevel.LevelNumber" xs="12" class="pt-pix-10">
            <label>Level Number: {{topicLevel.LevelNumber}}</label>
          </pos-column>
        </pos-row>
        </context-provider>
      </div>
    </div>
    <div class="col-12 action-button-container page-footer">
      <div class="form-horizontal">
        <button type="submit" value="Save" class="btn btn-primary btn-lg btn-save pos-button" scrollup>Save</button>
        <button type="button" class="btn btn-secondary btn-lg btn-cancel pos-button" value="Cancel" (click)="onCancel()"
          scrollup>Cancel</button>
      </div>
    </div>
  </form>
</pos-edit-wrapper>
