<pos-edit-wrapper heading="Notification" [headingIcon]="icons.bell" (close)="cancel()" [isPopup]="false">
  <form (ngSubmit)="saveNotification(notificationForm.valid)" #notificationForm="ngForm" class="edit-form">
    <div class="col-xs-12 border-top pt-pix-5">
      <div class="pos-page-body">
        <div class="col-xs-12">
          <div>
            <h3 class="setting-title">SMS Settings</h3>
          </div>
          <pos-row class="pos-title-content">
            <pos-column>
              <pos-form-field label="SMS">
                <div class="btn-group">
                  <label class="btn btn-default btn-radio col-75"
                    [ngClass]="parameters.FeatureNotificationSMS ? 'active btn-primary normal' : 'btn-secondary normal'">
                    <input posInput [(ngModel)]="parameters.FeatureNotificationSMS" [value]="true" type="radio"
                      name="globalShutOff" />
                    On
                  </label>
                  <label class="btn btn-default btn-radio col-75"
                    [ngClass]="!parameters.FeatureNotificationSMS ? 'active btn-danger normal': 'btn-secondary normal'">
                    <input [(ngModel)]="parameters.FeatureNotificationSMS" [value]="false" type="radio"
                      name="globalShut" />
                    Off
                  </label>
                </div>
              </pos-form-field>
            </pos-column>
          </pos-row>
        </div>
        <div class="col-xs-12 section-border">
          <div>
            <h3 class="setting-title">Email Settings</h3>
          </div>
          <pos-row class="pos-title-content">
            <pos-column>
              <pos-form-field label="Event - From Email"
                [validationMessages]="{required: 'Please enter Event - From Email.'}" inputStyle="col-sm-3 col-xs-6">
                <input posInput class="form-control" name="EventEmailFromEmail"
                  [(ngModel)]="parameters.EventEmailFromEmail" [required]="true">
              </pos-form-field>
            </pos-column>

            <pos-column>
              <pos-form-field label="Event - From Name"
                [validationMessages]="{required: 'Please enter Event - From Name.'}" inputStyle="col-sm-3 col-xs-6">
                <input posInput class="form-control" name="EventEmailDisplayName"
                  [(ngModel)]="parameters.EventEmailDisplayName" [required]="true">
              </pos-form-field>
            </pos-column>

            <pos-column>
              <pos-form-field label="Report - From Email"
                [validationMessages]="{required: 'Please enter Report - From Email.'}" inputStyle="col-sm-3 col-xs-6">
                <input posInput class="form-control" name="ReportEmailFromEmail"
                  [(ngModel)]="parameters.ReportEmailFromEmail" [required]="true">
              </pos-form-field>
            </pos-column>

            <pos-column>
              <pos-form-field label="Report - From Name"
                [validationMessages]="{required: 'Please enter Report - From Name.'}" inputStyle="col-sm-3 col-xs-6">
                <input posInput class="form-control" name="ReportEmailDisplayName"
                  [(ngModel)]="parameters.ReportEmailDisplayName" [required]="true">
              </pos-form-field>
            </pos-column>
          </pos-row>
        </div>
      </div>
    </div>
    <div class="col-xs-12 action-button-container page-footer">
      <button class="btn-primary btn-lg btn btn-save pos-button" scrollup type="submit" value="Save">Save</button>
      <button class="btn-secondary btn-lg btn btn-cancel pos-button" routerLink="../" scrollup type="button"
        value="Cancel">Cancel</button>
    </div>
  </form>
</pos-edit-wrapper>
