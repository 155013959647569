import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { box, buttonBehaviorPlus, buttonsDiceOne, calendar, deleteWhite, editWhite, editWhiteWithText, infoCircle, plusWhite, th, times } from 'src/app/shared/components/icon';
import { KioskChoice, KioskChoiceBehavior, KioskScreen } from '../../interfaces';
import { BaseCrudModelComponent } from 'src/app/shared/components/base-crud-model.component';
import { NgForm } from '@angular/forms';
import { OrderKioskChoiceService } from '../../services';
import { AlertsService, ExtensionValidationService, FormUtilityService, OrderKioskScreenService } from 'src/app/shared/services';
import { SpinnerService } from 'src/app/shared/components/spinner';
import { ConfirmDeleteModalComponent, DomainConstants, Messages, ModalService, RuntimeConstants, StringUtils } from 'src/app/shared';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, map } from 'rxjs/operators';
import { forEach, orderBy, startCase, find, filter } from 'lodash';
import { GridColumn, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { Observable } from 'rxjs/internal/Observable';
import { forkJoin } from 'rxjs';
import { OrderKioskChoiceBehaviorService } from '../../services/order-kiosk-choice-behavior.service';
declare let $: any;

@Component({
  selector: 'pos-kiosk-choice-edit',
  templateUrl: './kiosk-choice-edit.component.html',
  styleUrls: ['./kiosk-choice-edit.component.scss']
})
export class KioskChoiceEditComponent extends BaseCrudModelComponent<KioskChoice> implements OnInit {

  @ViewChild('formButton') formButton: NgForm;
  @Input('id') id: number = 0;
  @Input() isPopup = false;
  buttonName: string = '';
  @ViewChild('operationTemplate', { static: true }) operationTemplate: TemplateRef<any>;
  @ViewChild('headerTemplate', { static: true }) headerTemplate: TemplateRef<any>;
  @ViewChild('behaviorTemplate', { static: true }) behaviorTemplate: TemplateRef<any>;
  tabList = {
    Details: 'Details',
    Behaviors: 'Behaviors'
  };
  formGroups = {
    'Details': { '$valid': true }
  };
  selectedTab = this.tabList.Details;
  icons = {
    infoCircle,
    th,
    box,
    plusWhite,
    editWhiteWithText,
    editWhite, deleteWhite, buttonBehaviorPlus, calendar, times, buttonsDiceOne
  };
  button: KioskChoice = null;
  image: File;
  buttonTypes: Array<string> = [];
  buttonImagePath = `${RuntimeConstants.IMAGE_BASE_PATH}/order-kiosk/buttons/`;
  behaviorColumns: Array<GridColumn> = [];
  buttonBehaviorTypes = DomainConstants.KioskButtonBehaviors;
  behaviorTypes = [];
  screenList: KioskScreen[] = [];
  buttonList: KioskChoice[] = [];
  isBehaviorChanged = false;
  selectedButtonBehaviorType = null;
  loadEdit = false;
  buttonBehavior = null;
  pressApplyToProceed = Messages.PressApplyButtonToProceed;
  isSaveAndContinue = false;
  placeholderButtonType = DomainConstants.KioskChoiceType.Placeholder;
  constructor(public buttonService: OrderKioskChoiceService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    formUtilityService: FormUtilityService,
    private extensionValidationService: ExtensionValidationService,
    private route: ActivatedRoute,
    private router: Router,
    private kioskScreenService: OrderKioskScreenService,
    private modalService: ModalService,
    private orderKioskChoiceBehaviorService: OrderKioskChoiceBehaviorService,
  ) {
    super(buttonService, alertService, spinnerService, formUtilityService);
    this.button = buttonService.newChoice();
    this.id = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
    this.saveSuccessMessage = Messages.KioskChoiceSaveSuccess;
  }

  ngOnInit(): void {
    forEach(DomainConstants.KioskChoiceType, item => {
      this.buttonTypes.push(startCase(item));
    });
    this.prepareGridColumn();
    if (this.id) {
      this.dataSubscription();
    }
    this.generateBehaviorType();
  }

  dataSubscription() {
    if (this.id > 0) {
      this.spinnerService.show();
      this.loadData()
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res: KioskChoice) => {
            this.button = res;
            this.buttonName = res.ButtonText;
          }, error: this.alertService.showApiError
        });
    }
  }

  get getForm(): NgForm {
    return this.formButton;
  }
  onSaveSuccess(model: KioskChoice): void {
    this.cancelEditing(model);
  }

  loadDependencies(): void {
    if (this.id >= 0) {
      this.spinnerService.show();
      const buttonObservables: Observable<any>[] = [];
      buttonObservables.push(this.kioskScreenService.getAll());
      buttonObservables.push(this.buttonService.getAll());
      forkJoin(buttonObservables)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (responses: any) => {
            if (responses) {
              this.screenList = responses[0] ? responses[0] : [];
              this.buttonList = responses[1] ? responses[1] : [];
              this.buttonList = filter(this.buttonList, x => x.Id != this.id);
              forEach(this.buttonList, x => x.ButtonScreen = x.ButtonText);
              forEach(this.screenList, x => x.Title = x.Name);
            }
            this.getButtonBehaviors();
          }, error: this.alertService.showApiError
        });
    }
  }

  getHeight() {
    if ($(window).height() - 275 > 645) {
      return 645;
    }
    return ($(window).height() - 275 > 500) ? 500 : $(window).height() - 275;
  }

  public submit(isButtonFormValid: boolean): void {
    if (!isButtonFormValid) {
      return;
    }
    this.save(this.button, this.image, true);
  }

  public cancelEditing(model: KioskChoice = null): void {
    this.id = model?.Id ?? this.id;
    this.button = model ?? this.button;
    this.buttonName = model ? model.ButtonText : this.button.ButtonText;
    if (this.isSaveAndContinue) {
      this.isSaveAndContinue = false;
      if (!this.isPopup)
        this.redirectToKioskChoiceEdit();
    } else {
      this.cancel(model);
    }
  }

  cancel(model: KioskChoice = null) {
    if (this.isPopup) {
      this.hide({ button: model ?? null, buttonId: this.id });
    } else {
      this.router.navigate(['buttons'], { relativeTo: this.route.parent });
    }
  }

  redirectToKioskChoiceEdit() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['buttons', this.button.Id], {
      state: {
        id: this.button.Id
      },
      relativeTo: this.route.parent
    });
    this.router.onSameUrlNavigation = 'ignore';
  }

  handleFileInput(files: FileList) {
    let fileToUpload = '';
    this.image = files.item(0);
    fileToUpload = this.image ? this.image.name : '';
    if (this.extensionValidationService.isInvalidExtension(fileToUpload)) {
      if (fileToUpload) {
        this.alertService.renderErrorMessage(Messages.InvalidSalesProductImageFileType);
      }
      this.removeIcon();
      return;
    }

    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        $('#buttonImagePreview').attr('src', reader.result);
      };
      reader.readAsDataURL(files[0]);
      this.button.Image = this.image.name;
    }
  }

  removeIcon() {
    this.button.Image = null;
    this.image = null;
    $('#buttonImage').val(null);
  }

  prepareGridColumn() {
    const operationColumn = new TemplateColumn({
      itemTemplate: this.operationTemplate,
      Width: '102px',
      headerTemplate: this.headerTemplate,
      TextAlign: TextAlign.Center,
      CellClass: 'cell-padding'
    });

    const behaviorColumn = new TemplateColumn({
      HeaderText: 'Behavior',
      itemTemplate: this.behaviorTemplate,
      Width: '24%',
    });

    this.behaviorColumns = [
      behaviorColumn,
      new GridColumn({ HeaderText: 'Values', Field: 'DisplayValue', IsSortable: true, Width: '65%' }),
      operationColumn
    ];
  }

  getButtonBehaviors() {
    this.spinnerService.show();
    this.orderKioskChoiceBehaviorService.getAllBehavior(this.id)
      .pipe(map((data) => {
        data.forEach(x => x['Icon'] = this.behaviorTypes.find(y => y.Value === x.Name)?.Icon);
        return data;
      }), finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.button.ChoiceBehaviors = res ?? [];
          this.setBehaviorDisplayName();
        }, error: this.alertService.showApiError
      });
  }

  setBehaviorDisplayName() {
    forEach(this.button.ChoiceBehaviors, (behavior) => {
      const behaviorConstant = find(this.buttonBehaviorTypes, x => x.Value === behavior.Name);
      if (behaviorConstant) {
        behavior.DisplayName = behaviorConstant.Text;
      }
    });
  }

  generateBehaviorType = () => {
    this.behaviorTypes = [];
    forEach(this.buttonBehaviorTypes, (channels) => {
      this.behaviorTypes.push(channels)
    });
    this.behaviorTypes = orderBy(this.behaviorTypes, 'Text');
  }

  addBehavior = (behaviorType) => {
    const existingBehavior = find(this.button.ChoiceBehaviors, x => x.Name === behaviorType.Value
      && !behaviorType.IsAllowMultipleBehavior);
    if (!existingBehavior) {
      this.loadEdit = true;
      this.selectedButtonBehaviorType = behaviorType;
    } else {
      this.alertService.renderErrorMessage(StringUtils.format(Messages.ButtonBehaviorExist, { buttonBehavior: behaviorType.Text }));
    }
  }

  editBehavior = (data: KioskChoiceBehavior) => {
    this.loadEdit = true;
    const behaviorType = find(this.buttonBehaviorTypes, x => x.Value === data.Name);
    this.selectedButtonBehaviorType = behaviorType;
    this.buttonBehavior = data;
    this.buttonBehavior.TextValue = data.Value;
  }

  confirmDelete = (behavior: KioskChoiceBehavior) => {
    const modalRef = this.modalService.getModalWrapper(ConfirmDeleteModalComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      initialState: {
        message: StringUtils.format(Messages.ConfirmDeleteButtonBehavior,
          { 'behaviorName': behavior.DisplayName })
      }
    });

    modal.close.subscribe(res => {
      if (res && res.shouldDelete) {
        this.deleteButtonBehavior(behavior);
      }
    });
  }

  deleteButtonBehavior = (behavior: KioskChoiceBehavior) => {
    this.spinnerService.show();
    this.orderKioskChoiceBehaviorService.delete(behavior.Id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.alertService.renderSuccessMessage(Messages.ButtonBehaviorDeleted);
          this.isBehaviorChanged = true;
          this.getButtonBehaviors();
        }, error: this.alertService.showApiError
      });
  }

  closeButtonBehaviorEdit(res) {
    if (res && res.shouldReload) {
      this.isBehaviorChanged = true;
      this.getButtonBehaviors();
    }
    this.selectedButtonBehaviorType = null;
    this.loadEdit = false;
    this.buttonBehavior = null;
  }

  onSaveBehavior(data = null) {
    const behavior: KioskChoiceBehavior = {
      Id: this.buttonBehavior?.Id ?? 0,
      ButtonId: this.id,
      Name: this.selectedButtonBehaviorType.Value,
      Value: data ? JSON.stringify(data) : null,
      Ordinal: this.buttonBehavior && this.buttonBehavior.Ordinal ? this.buttonBehavior.Ordinal : 0
    };
    this.spinnerService.show();
    this.orderKioskChoiceBehaviorService.insertOrUpdate(behavior)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.alertService.renderSuccessMessage(Messages.ButtonBehaviorSaveSuccess);
          this.closeButtonBehaviorEdit({ shouldReload: true });
        }, error: this.alertService.showApiError
      });
  }

}
