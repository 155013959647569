<pos-edit-wrapper heading="Payments" [headingIcon]="icons.paymentsMoneyBillAlt" (close)="closeEdit()" [isPopup]="false">
    <form (ngSubmit)="savePayments(paymentsForm.valid)" #paymentsForm="ngForm" class="edit-form">
        <context-provider provide="props">
            <div class="col-12 border-top content-scroll">
                <div class="pos-page-body">
                    <div class="col-12">
                        <div>
                            <h3 class="setting-title mt-pix-5">Order Payment Flow</h3>
                        </div>
                        <pos-row class="pos-title-content">
                            <pos-column>
                                <pos-form-field label="After Completing entry:">
                                    <div class="d-flex flex-nowrap align-items-center">
                                        <input name="AutoDefer" type="radio" class="chk-section__input" id="autoDefer"
                                            [(ngModel)]="autoDefer" [value]="autoDefer" disabled="true">
                                        <span tooltip title="Change WorkFlow's Service Model">
                                            Always defer payment (Send Order button)</span>
                                        <field-info [info]="fieldInfoMessages.PaymentsAlwaysDeferPayment"
                                            styleClass="pull-right pt-pix-2"></field-info>
                                    </div>
                                </pos-form-field>
                                <pos-form-field>
                                    <div class="d-flex flex-nowrap align-items-center">
                                        <input name="AutoDefer" type="radio" class="chk-section__input" id="ringUp"
                                            [(ngModel)]="ringUp" [value]="ringUp" disabled="true">
                                        <span tooltip title="Change WorkFlow's Service Model">
                                            Ring Up Immediately (Ring Up button)</span>
                                        <field-info [info]="fieldInfoMessages.PaymentsRingupImmediately"
                                            styleClass="pull-right pt-pix-2"></field-info>
                                    </div>
                                </pos-form-field>
                            </pos-column>
                        </pos-row>
                    </div>
                    <div class="col-12 section-border">
                        <div>
                            <h3 class="setting-title">Tender</h3>
                        </div>
                        <pos-row class="pos-title-content">
                            <pos-column *ngFor="let tenderType of parameters.TenderTypes; let i=index;">
                                <pos-row>
                                    <pos-column>
                                        <pos-form-field>
                                            <p-checkbox posInput name="tenderType_{{i}}" #tenderType_{{i}}="ngModel"
                                                [(ngModel)]="tenderType.IsSelected" binary="true"
                                                [disabled]="tenderType.Code === 'HouseAccount'"
                                                [label]="tenderType.Name">
                                            </p-checkbox>
                                        </pos-form-field>
                                    </pos-column>

                                    <pos-column class="ps-4">
                                        <pos-row colXs="12">
                                            <pos-column class="col-200" *ngIf="tenderType.Code === 'CreditCard'">
                                                <pos-form-field>
                                                    <ng-select posInput [items]="creditCardTypes" appendTo="body"
                                                        [(ngModel)]="parameters.CreditCardType"
                                                        placeholder="Select Type" name="creditCardTypes"
                                                        bindLabel="Name" bindValue="Name" [clearable]="false"
                                                        [clearOnBackspace]="false"
                                                        [disabled]="tenderType.Code === 'CreditCard' && !tenderType.IsSelected">
                                                    </ng-select>
                                                </pos-form-field>
                                            </pos-column>

                                            <pos-column class="col-200"
                                                *ngIf="tenderType.Code === allTenderTypes.EBT.Code">
                                                <pos-form-field>
                                                    <ng-select posInput [items]="ebtTypes" appendTo="body"
                                                        [(ngModel)]="parameters.EBTType" placeholder="Select Type"
                                                        name="ebtType" bindLabel="Name" bindValue="Name"
                                                        [clearable]="false" [clearOnBackspace]="false"
                                                        [disabled]="tenderType.Code === 'EBT' && !tenderType.IsSelected">
                                                    </ng-select>
                                                </pos-form-field>
                                            </pos-column>

                                            <pos-column *ngIf="tenderType.Code === 'CreditCard'">
                                                <pos-form-field>
                                                    <p-checkbox posInput name="allowCreditCardTip"
                                                        #allowCreditCardTip="ngModel"
                                                        [(ngModel)]="parameters.AllowCreditCardTip" binary="true"
                                                        [disabled]="parameters.CreditCardType !== 'Integrated' || !tenderType.IsSelected"
                                                        label="Allow Credit Card Tips">
                                                    </p-checkbox>
                                                </pos-form-field>
                                            </pos-column>

                                            <pos-column *ngIf="tenderType.Code === 'CreditCard'" class="ps-4">
                                                <pos-form-field label="Tips can be added until"
                                                    labelStyle="col-1 pt-pix-7 fit-content pe-0" inputStyle="col-8"
                                                    [validationMessages]="{min: 'Day must be positive value.'}">
                                                    <div class="row">
                                                        <div class="col-6 pe-0 col-100 text-nowrap">
                                                            <input posInput class="form-control text-right"
                                                                name="txtAllowAddingTipTillDays" type="number" min="0"
                                                                [(ngModel)]="parameters.AllowAddingTipTillDays"
                                                                #allowAddingTipTillDays="ngModel" posOnlyNumbers
                                                                [disabled]="(parameters.CreditCardType !== 'Integrated' || !parameters.AllowCreditCardTip) || !tenderType.IsSelected">
                                                        </div>
                                                        <div class="col-1">
                                                            <label class="label-section pt-pix-7">
                                                                days
                                                            </label>
                                                        </div>
                                                    </div>
                                                </pos-form-field>
                                            </pos-column>

                                            <pos-column *ngIf="tenderType.Code === 'CreditCard'">
                                                <pos-form-field inputStyle="d-flex flex-nowrap">
                                                    <p-checkbox posInput name="giftcardAddBalanceByCreditCard"
                                                        #giftcardAddBalanceByCreditCard="ngModel"
                                                        [(ngModel)]="parameters.GiftcardAddBalanceByCreditCard"
                                                        binary="true"
                                                        [disabled]="tenderType.Code === 'CreditCard' && !tenderType.IsSelected"
                                                        label="Allow gift card balance additions by credit card">
                                                    </p-checkbox>
                                                    <field-info [info]="fieldInfoMessages.PaymentsAllowGiftCardBalance"
                                                        styleClass="pull-right pt-0"></field-info>
                                                </pos-form-field>
                                            </pos-column>

                                            <pos-column *ngIf="tenderType.Code === 'CreditCard'" class="ps-4">
                                                <pos-form-field label="Transaction Fee: "
                                                    labelStyle="col-1 pt-pix-7 col-130"
                                                    inputStyle="col-11 pe-0 col-100"
                                                    [validationMessages]="{min: 'Transaction Fee must be positive value.'}">
                                                    <input posInput class="form-control text-right"
                                                        name="txtGiftCardAdditionalFee" min="0"
                                                        [disabled]="(tenderType.Code === 'CreditCard' && !tenderType.IsSelected) || !parameters.GiftcardAddBalanceByCreditCard"
                                                        [(ngModel)]="parameters.GiftcardTransactionFee" posDecimal
                                                        type="number">
                                                </pos-form-field>
                                            </pos-column>

                                            <pos-column *ngIf="tenderType.Code === 'GiftCard'">
                                                <pos-form-field label="Mask: " labelStyle="col-1 pt-pix-7 col-100"
                                                    inputStyle="col-11 pe-0 col-150"
                                                    [fieldInfo]="fieldInfoMessages.PaymentsGiftCardMask">
                                                    <input posInput name="txtGiftCardMask"
                                                        [disabled]="tenderType.Code === 'GiftCard' && !tenderType.IsSelected"
                                                        [(ngModel)]="parameters.GiftCardMask" class="form-control"
                                                        type="text">
                                                </pos-form-field>
                                            </pos-column>
                                        </pos-row>
                                    </pos-column>
                                </pos-row>
                            </pos-column>
                        </pos-row>
                    </div>
                    <div class="section-border">
                        <div>
                            <h3 class="setting-title">Online Orders</h3>
                        </div>
                        <pos-row class="pos-title-content">
                            <pos-column>
                                <pos-form-field label="Authorize" inputStyle="row flex-wrap"
                                    [validationMessages]="{min: 'Authorize amount factor should be positive value.'}">
                                    <div class="col-3 col-150">
                                        <p class="input-group workflow-variance flex-grow text-nowrap">
                                            <input posInput class="form-control text-right" name="AuthorizeAmountFactor"
                                                [(ngModel)]="parameters.AuthorizeAmountFactor" type="number" min="0"
                                                posOnlyNumbers />
                                            <span class="input-group-addon">%</span>
                                        </p>
                                    </div>
                                    <div class="col-sm-4 col-12">
                                        <label class="edit-form__section__label">more than order amount </label>
                                    </div>
                                </pos-form-field>
                            </pos-column>
                        </pos-row>
                    </div>
                </div>
            </div>
        </context-provider>
        <div class="col-12 action-button-container page-footer">
            <button class="btn btn-primary btn-lg btn-save pos-button" scrollup type="submit">Save</button>
            <button class="btn btn-secondary btn-lg btn-cancel pos-button" routerLink="../" scrollup
                type="button">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
