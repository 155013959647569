import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataSourceComponent } from './components/data-source/data-source.component';
import { AUTOMATED_EXPORTS } from './automated-exports.routes';
import { RouterModule } from '@angular/router';
import { TableModule } from '@tarktech/tark-ng-utils';
import { ComponentsModule } from 'src/app/shared/components/components.module';
import { FormsModule } from '@angular/forms';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { CronEditorModule } from '@tarktech/ngx-cron-editor'
import { NgSelectModule } from '@ng-select/ng-select';
import { DataSourceSubscribeComponent } from './components/data-source-subscribe/data-source-subscribe.component';
import { AutomatedExportBaseComponent } from './components/automated-export-base/automated-export-base.component';
import { CheckboxModule } from 'primeng/checkbox';
import { DataSourceSubscribeEditComponent } from './components/data-source-subscribe-edit/data-source-subscribe-edit.component';
import { DataSourceEditComponent } from './components/data-source-edit/data-source-edit.component';
import { QueryValuesComponent } from './components/query-values/query-values.component';
import { ExportConnectionComponent } from './components/export-connection/export-connection.component';
import { ExportConnectionEditComponent } from './components/export-connection-edit/export-connection-edit.component';
import { CalendarModule } from 'primeng/calendar';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { ManualDataExportListComponent } from './components/manual-data-export-list/manual-data-export-list.component';
import { ManualDataExportPreviewComponent } from './components/manual-data-export-preview/manual-data-export-preview.component';
import { MatSliderModule } from '@angular/material/slider';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { TranslateLoader, TranslateModule, TranslateStore } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { createTranslateLoader } from 'src/app/shared/services/translateLoader';
import { MacroScriptsComponent } from './components/macro-scripts/macro-scripts.component';
import { MacroScriptEditComponent } from './components/macro-script-edit/macro-script-edit.component';
import { RouteConfigService } from 'src/app/shared/services/route-config.service';
import { NgxContextModule } from '@tarktech/ngx-context';
import { DataSourcePreviewComponent } from './components/data-source-preview/data-source-preview.component';

RouteConfigService.addGuardsToRoutes(AUTOMATED_EXPORTS);

@NgModule({
  declarations: [DataSourceComponent, DataSourceEditComponent, DataSourceSubscribeComponent, AutomatedExportBaseComponent,
    DataSourceSubscribeEditComponent, QueryValuesComponent, ExportConnectionComponent, ExportConnectionEditComponent, ManualDataExportListComponent, ManualDataExportPreviewComponent, MacroScriptsComponent, MacroScriptEditComponent, DataSourcePreviewComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    RouterModule.forChild(AUTOMATED_EXPORTS),
    TableModule,
    NgSelectModule,
    FormsModule,
    CodemirrorModule,
    MatCardModule,
    MatSelectModule,
    MatSliderModule,
    CronEditorModule,
    CheckboxModule,
    CalendarModule,
    TriStateCheckboxModule,
    TranslateModule.forChild({
      isolate:true,
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    NgxContextModule
  ],
  exports:[],
  providers:[TranslateStore]
})
export class AutomatedExportsModule { }
