import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ApplicationStateService, AlertsService } from 'src/app/shared/services';
import { BaseGridComponent, ModalService, SpinnerService } from 'src/app/shared/components';
import { editWhite, emailTemplatesEnvelopeOpen, fileSearch, squareLightOrangeStack } from './../../../../shared/components/icon';
import { GridColumn, TableComponent, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { EmailTemplate } from '../../interface';
import { EmailTemplateService } from '../../service';
import { finalize } from 'rxjs/operators';
import { Levels, Permissions } from 'src/app/shared/constants';
import { AuthenticationService } from 'src/app/shared/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailTemplatesPreviewComponent } from '../email-templates-preview/email-templates-preview.component';

@Component({
  selector: 'pos-email-templates-list',
  templateUrl: './email-templates-list.component.html',
})
export class EmailTemplatesListComponent extends BaseGridComponent implements OnInit {

  emailTemplates: Array<EmailTemplate> = [];
  emailTemplateColumns: Array<GridColumn> = [];
  @ViewChild('operationTemplate', { static: true }) operationTemplate: TemplateRef<any>;
  @ViewChild('templatesGrid', { static: true }) templatesGrid: TableComponent;

  icons = {
    editWhite,
    fileSearch,
    squareLightOrangeStack,
    emailTemplatesEnvelopeOpen
  };

  permission = {
    name: Permissions.SystemConfigurationEmailTemplates,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };

  constructor(protected applicationStateService: ApplicationStateService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected spinnerService: SpinnerService,
    protected alertService: AlertsService,
    private authenticationService: AuthenticationService,
    private modalService: ModalService,
    protected emailTemplateService: EmailTemplateService) {
    super(applicationStateService, route);
  }

  ngOnInit() {
    this.loadData();
    this.configureGridColumns();
    this.templatesGrid.context = this.gridContext;
  }

  loadData() {
    this.spinnerService.show();
    this.emailTemplateService.getAll()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.emailTemplates = res;
        }, error: (err) => this.alertService.showApiError(err)
      });
  }

  configureGridColumns() {
    const editColumn = new TemplateColumn({
      itemTemplate: this.operationTemplate,
      Width: '90px',
      TextAlign: TextAlign.Center,
      CellClass: 'cell-padding'
    });
    this.emailTemplateColumns = [
      new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '97%' })
    ] as Array<GridColumn>;

    if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }],
      'any')) {
      this.emailTemplateColumns.push(editColumn);
    }
  }

  showPreview(emailTemplate) {
    const modalRef = this.modalService.getModalWrapper(EmailTemplatesPreviewComponent);
    modalRef.show({
      animated: false,
      class: 'vertical-center modal-lg',
      initialState: {
        id: emailTemplate.Id,
      }
    });
  }

  editItem(emailTemplateId: number) {
    this.router.navigate([emailTemplateId], { relativeTo: this.route });
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
