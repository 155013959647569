import { Route } from '@angular/router';
import { AuthGuard } from '../app-guard';
import { MakeTableComponent } from './components/make-table.component';

const MAKE_TABLE_FILTER: Route = {
  path: 'filter',
  component: MakeTableComponent
};
export const MAKE_TABLE_STATES: Array<Route> = [
  {
      path: '',
      canActivate: [AuthGuard],
      component: MakeTableComponent,
      children: [MAKE_TABLE_FILTER],
  }];
