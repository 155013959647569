import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { SpinnerService } from '../spinner/spinner.service';
@Component({
  selector: 'pos-progress-bar',
  templateUrl: './progress-bar.component.html',
//   styles: [`
//   ::ng-deep .p-progressbar {
//     height: 1px !important;
// }, 
//   :host ::ng-deep .ui-progressbar {
//       height: 2px;
//       background: #c5c5c5;
//   }`, `
//   :host ::ng-deep .ui-widget-header {
//       border: none;
//   }`, `
//   :host ::ng-deep .ui-widget-content {
//       border: none;
//   }`, `
//   :host ::ng-deep .ui-corner-all {
//       border-radius: 0;
//   }
//   `
//   ]
styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

  mode$ = this.router.events.pipe(
    filter(evt => evt instanceof NavigationStart || evt instanceof NavigationEnd),
    map(evt => evt instanceof NavigationStart ? 'indeterminate' : '')
  );

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

}
