<pos-edit-wrapper heading="{{selectedDeliveryType.description}}" (close)="onClose(false)">
  <form #audioNotificationActivityEditForm="ngForm" autocomplete="off"
    (submit)='saveActivity(audioNotificationActivityEditForm.valid)'>
    <div class="col-12 pos-modal-body screen-scroll">
      <pos-row>
        <pos-column>
          <pos-form-field label="Audio Devices" [validationMessages]="{required: 'Please select audio device.'}">
            <ng-select posInput [items]="audioDevices" bindLabel="Name" appendTo="body" bindValue="Id"
              placeholder="Select devices" (change)="changeAudioDevice()" [multiple]="true" [hideSelected]="true"
              id="audioDevice" [(ngModel)]="selectedAudioDevices" name="audioDevice" [required]="true">
            </ng-select>
          </pos-form-field>
        </pos-column>
        <pos-column>
          <pos-form-field label="Audio Mode">
            <div class="btn-group">
              <label class="btn btn-radio" [ngClass]="audioMode ? 'active btn-primary font-nobold': 'btn-secondary'">
                <input type="radio" name="audioMode" class="form-check-input" (change)="onChangeAudioMode()"
                  [(ngModel)]="audioMode" [value]="true"> Audio File </label>
              <label class="btn btn-radio" [ngClass]="!audioMode ? 'active btn-primary font-nobold': 'btn-secondary'">
                <input type="radio" name="audioMode" class="form-check-input" (change)="onChangeAudioMode()"
                  [(ngModel)]="audioMode" [value]="false"> Speak Text </label>
            </div>
          </pos-form-field>
        </pos-column>
        <pos-column *ngIf="audioMode">
          <pos-form-field label="Audio File" [validationMessages]="{required: 'Please select audio files.'}">
            <pos-row class="mx-0">
              <pos-column xs="11" class="p-0">
                <ng-select posInput [items]="audioFiles" bindLabel="Name" appendTo="body" bindValue="Id"
                  placeholder="Select audio file" [(ngModel)]="selectedAudioFile" name="audioFile"
                  [required]="audioMode">
                </ng-select>
              </pos-column>
              <pos-column xs="1" class="pe-0 pt-pix-5" *ngIf="userHasPermission" (click)="addNewAudioFile();">
                <pos-icon [icon]="icons.newAudioFile" class="pointer"></pos-icon>
              </pos-column>
            </pos-row>
          </pos-form-field>
        </pos-column>
        <pos-column *ngIf="!audioMode">
          <pos-form-field label="Speak Text" [validationMessages]="{required: 'Please add audio text.'}"
            inputStyle="col-md-8 col-12">
            <textarea posInput class="form-control" name="audioText" rows="5" #audioText [(ngModel)]="audioTextString"
              maxlength="2000" [required]="!audioMode" (keyup)="getCaretPos(audioText)"
              (click)="getCaretPos(audioText)"></textarea>
            <span class="me-pix-20 mt-pix-n30 relative" style="float: right">
              <span class="font-20 pointer grey">
                <pos-icon [icon]="icons.messageCode" class="pointer" (click)="isOpenVariable = !isOpenVariable">
                </pos-icon>
              </span>
              <div style="position: fixed; z-index: 10;" *ngIf="isOpenVariable && !isDisabled" class="col-200">
                <ng-select [items]="eventAttributes" appendTo="body" placeholder="Select event variable"
                  name="eventVariable" [(ngModel)]="selectedEventAttribute" (change)="changeEventAttribute()"
                  [isOpen]="true" class="audio-notification">
                </ng-select>
              </div>
            </span>
          </pos-form-field>
        </pos-column>
        <pos-column *ngIf="!audioMode">
          <pos-form-field label="Audio Voice" [validationMessages]="{required: 'Please select audio voice.'}">
            <ng-select posInput [items]="audioVoices" appendTo="body" placeholder="Select audio voice" name="audioVoice"
              [(ngModel)]="selectedAudioVoice" [required]="!audioMode">
            </ng-select>
          </pos-form-field>
        </pos-column>
        <pos-column>
          <pos-form-field label="Audio Volume">
            <pos-row class="mx-0">
              <pos-column xs="11" class="ps-0">
                <ngx-slider [(value)]="volumeValue" [options]="options"></ngx-slider>
              </pos-column>
              <pos-column xs="1" class="p-0">
                <span class="badge mt-pix-2 volume-circle">{{ volumeValue }}</span>
              </pos-column>
            </pos-row>
          </pos-form-field>
        </pos-column>
        <pos-column>
          <pos-repeat-action-configuration [(eventSubscriptionActionAttributes)]="eventSubscriptionActionAttributes"
            [isFormSubmitted]="audioNotificationActivityEditForm.submitted" #RepeatActionConfiguration
            [isRecoverableEvent]="isRecoverableEvent">

          </pos-repeat-action-configuration>
        </pos-column>
      </pos-row>
    </div>
    <div class="col-12 action-button-container popup-footer">
      <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save</button>
      <button type="button" (click)="onClose(false)"
        class="btn-secondary btn-lg btn btn-cancel-modal pos-button">Cancel</button>
    </div>
  </form>
</pos-edit-wrapper>
