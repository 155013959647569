import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { Observable } from 'rxjs';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';
import { DomainConstants } from 'src/app/shared/constants/domain-constants';
import { ScreenDetails } from '../../information-management/screen/interface/screen-details';

@Injectable()
export class ScreenService extends BaseCrudService<ScreenDetails> {

  screenApiUrl = '';
  constructor(httpService: HttpClient) {
    super('screens', httpService);
    this.screenApiUrl = RuntimeConstants.API_PATH + 'screens';
  }

  newScreen() {
    const screenDetails: ScreenDetails = {
      Id: 0,
      Title: null,
      MainProduct: false,
      PrintOnOrderReceipt: false,
      MinSelections: null,
      MaxSelections: null,
      DefaultNextScreenId: null,
      color: '',
      IsPrice: false,
      PriceAmount: 0,
      SizeForm: false,
      background_image: null,
      ScreenId: 0,
      ChoiceId: 0,
      ButtonSize: DomainConstants.ButtonSize.Normal,
      ColumnCount: 6,
      StartTime: new Date(),
      EndTime: new Date(),
      MultiProductNextScreenId: null,
      Layout: DomainConstants.ScreenButtonLayouts.Fluid,
    };
    return screenDetails;
  }

  getScreens(): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}screens/`
    );
  }

  getScreen(screenId: number): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}screens/` + screenId
    );
  }

  getScreensForTemporaryNavigation(screenId: number): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}screens/` + screenId + `/TemporaryNavigation`
    );
  }

  saveScreen(screen, icon: File): Observable<any> {
    const formData = this.prepareIconFileFormData(screen, icon);
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}screens`, formData
    );
  }

  private prepareIconFileFormData(param: any, iconFile: File): FormData {
    const formData = new FormData();
    if (iconFile) {
      formData.append('file', iconFile);
    }
    formData.append('model', JSON.stringify(param));
    return formData;
  }

  getActiveScreens(screenId: number): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}screens/` + screenId + `/ActiveScreen`
    );
  };

}
