<div>
  <button class="close" (click)="onCancel(false)">&times;</button>
  <h4 class="modal-content__heading">Add to balance</h4>
</div>
<div class="overflow-hidden">
  <form #addBalanceForm="ngForm" autocomplete="off">
    <div class="col-lg-12 col-12 screen-scroll px-pix-5 pt-pix-5">
      <div class="row mx-0 col-12 mt-pix-10">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 ">
          <span class="bold">Card Number: </span><span>{{giftCardDetail.CardNumber}}</span>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12  text-end">
          <span class="bold">Current Balance: </span> <span>{{giftCardDetail.CurrentBalance | tark_currency}}</span>
        </div>
        <div class="col-12 ">
          <span class="bold">
            Memo:
          </span>
          <span class="me-pix-5 word-break-all">
            {{memo}}
          </span>
          <span class="font-20 pointer grey vertical-mid" (click)="onMessageKeyboard()">
            <pos-icon [icon]="icons.keyboard" class="pointer">
            </pos-icon>
          </span>
        </div>
      </div>

      <div class="col-12 mx-0 row">
        <div class="col-lg-3 col-md-3 col-sm-3 col-3">
          <div class="col-12 pt-pix-15">
            <div class="btn-blank numpad__input"> &nbsp;</div>
          </div>
          <div class="col-12 p-0 pt-custom-pix-lg-10">
            <div class="btn-cal" (click)="numpad.addValueFn(5)">{{settingParams.CurrencySymbol}}5</div>
          </div>
          <div class="col-12 p-0 pt-pix-15">
            <div class="btn-cal" (click)="numpad.addValueFn(10)">{{settingParams.CurrencySymbol}}10</div>
          </div>
          <div class="col-12 p-0 pt-pix-15">
            <div class="btn-cal" (click)="numpad.addValueFn(20)">{{settingParams.CurrencySymbol}}20</div>
          </div>
          <div class="col-12 p-0 pt-pix-15">
            <div class="btn-cal" (click)="positiveNegativeGiftCardBalance()">+/-</div>
          </div>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9 col-9">
          <pos-numpad #numpad [numpadId]="'addBalance'" [onCancel]="onAddBalanceCancel"
            [onOptionChange]="numpadOptionChange" [options]="numpadOptions" [value]="amount">
          </pos-numpad>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-3 col-3 p-0">
        </div>
        <div class="row mx-0 col-12">
          <div class="col-lg-4 col-md-4 col-sm-4 col-4 ps-0 pe-0 pt-pix-10 pull-left" *ngIf="allowGiftCardBalanceByCC">
            <div class="btn-cal btn-cal-green btn-gift" [ngStyle]="{'background-color':tenderTypeColors.CreditCard}"
              (click)="addGiftCardBalanceByCC()">Credit Card</div>
          </div>
          <div permissions='[{"Name":"{{permissions.ManageGiftCardGratis}}", "Level":"{{accessLevels.Access}}"}]'
            class="col-lg-4 col-md-4 col-sm-4 col-4 pe-0 pt-pix-10 pull-left">
            <div class="btn-cal btn-cal-green btn-gift" [ngStyle]="{'background-color':tenderTypeColors.Gratis}"
              (click)="onAddGratis()">Gratis</div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 col-4 pe-0 pt-pix-10 pull-left">
            <div class="btn-cal btn-cal-green btn-gift" [ngStyle]="{'background-color':tenderTypeColors.Cash}"
              (click)="onAddBalance()">Cash</div>
          </div>
        </div>
      </div>

    </div>
    <div class="clearfix"></div>
    <div class="col-12 action-button-container popup-footer pb-pix-10">
      <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" scrollup
        (click)="onCancel(false)">Close</button>
    </div>
  </form>
</div>

<ng-template #confirmApproveModalTemplate>
  <div>
    <h4 class="modal-content__heading" id="keyboardTitle">Confirm</h4>
  </div>
  <div class="modal-body pb-0">
    <span innerHTML="{{confirmationMessageForApprove}}"></span>
  </div>
  <div class="col-12 action-button-container popup-footer pb-pix-10">
    <button class="btn btn-lg btn-save pos-button" [disabled]="!enableOperationButtons"
      [ngClass]="{'btn-success': enableOperationButtons, 'btn-secondary black': !enableOperationButtons}"
      (click)="onApprove()" type="button">Approved</button>
    <button class="btn btn-lg btn-cancel-modal pos-button" [disabled]="!enableOperationButtons"
      [ngClass]="{'btn-danger': enableOperationButtons, 'btn-secondary': !enableOperationButtons}" (click)="onDecline()"
      type="button">Declined</button>
  </div>
  <div class="clearfix"></div>
</ng-template>

<ng-template #creditCardPaymentPopup>
  <div>
    <h4 class="modal-content__heading" id="keyboardTitle">{{creditCardPaymentModalDetails.HeaderText}}</h4>
  </div>
  <div class="modal-body text-center pb-0">
    <span innerHTML="{{creditCardPaymentModalDetails.Message}}"></span>
  </div>
  <div class="col-12 action-button-container popup-footer pb-pix-10">
    <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="onCancelCCPayment()"
      type="button">{{creditCardPaymentModalDetails.ButtonText}}</button>
  </div>
  <div class="clearfix"></div>
</ng-template>
