import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/internal/operators/finalize';
import { InventoryProductDetail } from 'src/app/information-management/inventory-products/interface/inventory-product-detail';
import { ConfirmDeleteModalComponent } from 'src/app/shared/components/confirm-delete-modal/confirm-delete-modal.component';
import { ModalFormComponent } from 'src/app/shared/components';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { SalesProductsService } from '../../services/sales-products.service';
import { NgForm } from '@angular/forms';
import { FormUtilityService } from 'src/app/shared';

@Component({
  selector: 'pos-sales-product-recipe-swap',
  templateUrl: './sales-product-recipe-swap.component.html',
  styleUrls: ['./sales-product-recipe-swap.component.scss']
})
export class SalesProductRecipeSwapComponent extends ModalFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.recipeSwapForm;
  }

  @ViewChild('recipeSwapForm') recipeSwapForm: NgForm;
  salesProductId: number;
  recipeId: number;
  recipeItem;
  swapRecipeItem: number = null;
  ingredientList: Array<InventoryProductDetail> = [];

  constructor(private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private salesProductService: SalesProductsService,
    modalService: ModalService,
    protected recipeSwapModelRef: BsModalRef,
    formUtilityService: FormUtilityService) {
    super(recipeSwapModelRef, modalService, formUtilityService);
  }

  ngOnInit(): void {
  }



  swapRecipe(isValid: boolean) {
    if (!isValid) {
      return;
    }
    const confirmRemoveProductFromButton = this.modalService.show(ConfirmDeleteModalComponent, {
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      initialState: {
        message: StringUtils.format(Messages.ConfirmSwapRecipe,
          { 'productName': this.recipeItem.Product })
      }
    });
    confirmRemoveProductFromButton.close.subscribe(res => {
      if (res && res.shouldDelete) {
        this.spinnerService.show();
        const consumption = {
          InventoryProductId: this.swapRecipeItem
        };
        this.salesProductService.swapIngredientToProduct(this.salesProductId, this.recipeItem.inventory_product_id, consumption).pipe(
          finalize(() => {
            this.spinnerService.hide();
          })).subscribe({
            next: (response) => {
              this.onSaveSuccess();
            }, error: this.alertService.showApiError
          });
      }
    });
  }


  onSaveSuccess() {
    this.alertService.renderSuccessMessage(Messages.SalesProductRecipeSaveSuccess);
    this.onCancel(true);
  }

  onCancel(reload = false) {
    this.hide({ shouldReload: reload });
  }

}
