import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RuntimeConstants } from 'src/app/shared/constants';
import { SalesInventoryProduct } from '../interface/sales-inventory-product';

@Injectable({
  providedIn: 'root'
})
export class SalesInventoryProductService {

  constructor(private httpService: HttpClient) { }

  newSalesInventoryProduct = (): SalesInventoryProduct => {
    return {
      Id: 0,
      DateEffective: new Date(),
      Qty: 1,
      SalesProductId: 0,
      InventoryProductId: 0,
      CustomColor: '',
      DateExpired: null,
      Instruction: null,
      Ordinal: null,
      SizeId: null,
      Product: '',
      Unit: '',
      UnitQty: 0,
    }

  }

  saveSalesInventoryProduct(model: SalesInventoryProduct): Observable<any> {
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}sales-inventory-product/sales-product/${model.SalesProductId}/size/${model.SizeId??0}`, model
    );
  }
}
