import { Injectable } from "@angular/core";
import { IKioskNavigation } from "../interfaces/ikiosk-navigation";
import { ChoiceBehavior } from "./choices-behavior";
import { KioskScreen, KioskScreenChoice } from "../interfaces";
import { DomainConstants } from "src/app/shared";
import { KioskNavigationService } from "../services";
import { remove } from "lodash";

@Injectable({
    providedIn: 'root'
})
export class UncheckAllButtonsBehavior extends ChoiceBehavior {

    constructor(private kioskNavigation: KioskNavigationService) {
        super();
    }

    execute(navObj: IKioskNavigation, orderNavigation: KioskScreen): Promise<void> {
        this.uncheckAllButtons(orderNavigation.KioskScreenChoices, navObj.orderCommand.configId);
        return Promise.resolve();
    }

    uncheckAllButtons(screenChoices: KioskScreenChoice[], configId: number) {
        // Filtered by ConfigId as screen can have 2 button panels
        screenChoices.filter(x => x.ConfigId == configId).forEach(x => {
            if (x.Button?.IsSelected && x.Button.ButtonType == DomainConstants.KioskChoiceType.Toggle) {
                x.Button.IsSelected = false;
                remove(this.kioskNavigation.versionedVal.Obj.Order.Product.Components, (component) => {
                    return component.ButtonId == x.Button.Id;
                });
            }
        });

    }
}

