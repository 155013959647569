import { Component, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MenuItem } from 'primeng/api';
import { AlertsService, ExtensionValidationService, FormUtilityService, Messages, ModalFormComponent, ModalService } from 'src/app/shared';
import { ApplePayConfiguration } from '../../interface';
import { OnlineOrderingConfigurationsService } from '../../service';

@Component({
  selector: 'pos-apple-pay-configuration',
  templateUrl: './apple-pay-configuration.component.html',
  styleUrls: ['./apple-pay-configuration.component.scss']
})
export class ApplePayConfigurationComponent extends ModalFormComponent {

  @Input() configuration: ApplePayConfiguration;
  @ViewChild('applePayConfigurationForm') applePayConfigurationForm: NgForm;
  domainVerificationFile: File;
  merchantIdentityCertificate: File;

  get getForm(): NgForm {
    return this.applePayConfigurationForm;
  }

  items: Array<MenuItem> = [{ label: 'Merchant Details' }, { label: 'Generate CSR' }, { label: 'Payment Processing Certificate' }, { label: 'Merchant Domain Association' }, { label: 'Verify Domain' }, { label: 'Merchant Identity Certificate' }, { label: 'Upload Certificate' } ];
  activeIndex = 0;
  lastIndex = this.items.length - 1;


  constructor(bsModalRef: BsModalRef, modalService: ModalService, formUtilityService: FormUtilityService,
    private extensionValidationService: ExtensionValidationService,
    private alertService: AlertsService,
    private onlineOrderingConfigurationService: OnlineOrderingConfigurationsService
    ) {
    super(bsModalRef, modalService, formUtilityService);

  }

  onClose($event: any = null) {
    this.hide($event);
  }

  next() {
    if (this.activeIndex < this.lastIndex) {
      this.configuration.IsValid = true;
      this.activeIndex++;
    } else {
      this.configuration.IsValid = true;
      this.onClose({ configuration: this.configuration, merchantIdentityCertificate: this.merchantIdentityCertificate, isValid: this.configuration.IsValid });
    }
  }

  handleFileInput(files: FileList, isDomainVerificationFile = false) {
    const tempFile = files.item(0);
    const fileToUpload = tempFile?.name;
    if (isDomainVerificationFile) {
      if (this.extensionValidationService.isInvalidExtension(fileToUpload, ['.txt'])) {
        this.alertService.renderErrorMessage(Messages.InvalidDomainVerificationFileType);
        this.domainVerificationFile = null
        return;
      }
      this.domainVerificationFile = tempFile;
    } else {
      if (this.extensionValidationService.isInvalidExtension(fileToUpload, ['.pfx', '.p12'])) {
        this.alertService.renderErrorMessage(Messages.InvalidMerchantIdentityCertificateFileType);
        this.merchantIdentityCertificate = null;
        return;
      }
      this.merchantIdentityCertificate = tempFile;
      this.configuration.MerchantIdentityCertificateName = fileToUpload;
    }
  }

  uploadDomainVerificationCertificate() {
    if (!this.domainVerificationFile) {
      this.alertService.renderErrorMessage(Messages.InvalidDomainVerificationFileType);
      return;
    }
    this.onlineOrderingConfigurationService.uploadDomainVerificationFile(this.domainVerificationFile)
    .subscribe({
      next: () => {
        this.alertService.renderSuccessMessage("File uploaded Successfully.");
      }, error: this.alertService.showApiError
    });
  }

}
