import { Component, OnInit, OnDestroy, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import {
  AlertsService, DashboardConfiguration, DashboardConfigurationService, DashboardLayoutSpecification,
  Messages, SpinnerService
} from 'src/app/shared';
import { filter, forEach, groupBy } from 'lodash';
import { forkJoin, Observable, Subscription, interval as observableInterval } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { DashboardTerminalPropertiesService, RabbitMQService } from 'src/app/shared/services';
import { TerminalDashboardProperty } from 'src/app/configurator/dashboard-terminal-property/interface';
import { WidgetService } from '../../../adf/services/widget.service';
import { AdfModel } from '../../../adf/interfaces';
import { DashboardBroadcastingService } from '../../../adf/services';
import { DashboardStateParam } from '../../../adf/interfaces/dashboard-state-param';
import { Router } from '@angular/router';

@Component({
  selector: 'pos-dashboard-configuration',
  templateUrl: './dashboard-configuration.component.html',
  styleUrls: ['./dashboard-configuration.component.scss']
})
export class DashboardConfigurationComponent implements OnInit, OnDestroy {
  isShowClock = true;
  mappedDashboards: Array<TerminalDashboardProperty> = [];
  dashboardConfigurations: Array<DashboardConfiguration> = [];
  widgetsDeleted = [];
  dashboard: AdfModel;
  currentDashboardNumber = 0;
  dashboardTimer;
  @Input() dashboardId?= 0;
  dashboardStateParams: DashboardStateParam = {};
  isEditMode = false;
  @ViewChild('posAdfDashboard') posAdfDashboard;

  headerLinks: any;
  homeState = '/order/order-entry';
  layouts: {};
  @Input() salesProductId?: number;
  @Input() inventoryProductId?: number;
  @Input() mode?: string;
  @Input() terminalId?: number;
  @Output() editMode = new EventEmitter<boolean>();
  transParam: any;
  title = 'Nothing';
  currentTime = new Date().getTime();
  widgetsConfiguration: Array<DashboardConfiguration> = [];
  eventsSubscription: Array<Subscription> = [];
  tableData = [];
  timer: Observable<number>;
  dashboardRefreshSubscription = null;
  redirectRoute = false;
  constructor(private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private configurationService: DashboardConfigurationService,
    private dashboardTerminalService: DashboardTerminalPropertiesService,
    private widgetService: WidgetService,
    private dashboardBroadcastingService: DashboardBroadcastingService,
    protected router: Router,
    public rabbitMQService: RabbitMQService
  ) {
    const navigation = router.getCurrentNavigation();
    this.dashboard = configurationService.newDashboard();
    this.redirectRoute = (navigation?.extras?.state && navigation?.extras?.state.redirectRoute) ?
      navigation?.extras?.state.redirectRoute : this.redirectRoute;
  }

  ngOnInit(): void {
    this.initDashboardStateParams();
    this.initializeMappedDashboard();
    this.subscribeBroadcastEvents();
    this.setCurrentTimeInterval();
  }

  setCurrentTimeInterval() {
    setInterval(() => {
      this.currentTime = new Date().getTime();
    }, 60000);
  }

  initDashboardStateParams = () => {
    this.dashboardStateParams = {};
    this.dashboardStateParams.terminalId = this.terminalId ? this.terminalId : null; // transParam.terminalId ? parseInt(transParam.terminalId, 10) : null;
    this.dashboardStateParams.dashboardId = this.dashboardId ? this.dashboardId : null;
    this.dashboardStateParams.mode = this.mode ? this.mode : null; //this.transParam.mode;
    this.dashboardStateParams.InventoryProductId = this.inventoryProductId ? this.inventoryProductId : null; // transParam.inventoryProductId ? parseInt(transParam.inventoryProductId, 10) : null;
    this.dashboardStateParams.SalesProductId = this.salesProductId ? this.salesProductId : null; // transParam.salesProductId ? parseInt(transParam.salesProductId, 10) : null;
  }

  initializeMappedDashboard = () => {
    if (this.dashboardStateParams && this.dashboardStateParams.dashboardId) {
      this.configurationService.setDashboardStatesParams(this.dashboardStateParams);
      this.initializeLayouts();
      this.initializeDashboard(false);
    } else {
      this.getTerminalDashboardProperties();
      this.headerLinks = {
        tasks: false,
        time: false,
        admin: false,
        activeOrders: false,
        warning: false,
        home: false,
        customLinks: false,
        reload: false,
        signIn: false
      };
    }
  }

  ngOnDestroy() {
    if (this.dashboardRefreshSubscription) {
      this.dashboardRefreshSubscription.unsubscribe();
      this.dashboardRefreshSubscription = null;
    }
    forEach(this.eventsSubscription, (subscribe) => {
      if (subscribe) {
        subscribe.unsubscribe();
      }
    });
  }

  subscribeBroadcastEvents = () => {
    this.eventsSubscription.push(this.dashboardBroadcastingService.adfDashboardChanged.subscribe((model) => {
      this.saveDashboard(model);
    }));

    this.eventsSubscription.push(this.dashboardBroadcastingService.adfSaveWidgetsCompleted.subscribe(() => {
      this.initializeDashboard(true);
    }));

    this.eventsSubscription.push(this.dashboardBroadcastingService.adfWidgetRemovedFromColumn.subscribe((widget) => {
      if (widget && widget.widgetAssignmentId > 0) {
        this.widgetsDeleted.push(widget.widgetAssignmentId);
      }
    }));
  }

  toggleEditMode = (isEditMode) => {
    if (!isEditMode) {
      this.widgetsDeleted = [];
    }
    this.editMode.emit(this.dashboard.IsInteractive);
    this.dashboard.IsEditMode = this.dashboard.IsInteractive;
  }

  initializeDashboard(isSaved = false) {
    this.spinnerService.show();
    this.configurationService.getDashboardConfigurations(this.dashboardStateParams.dashboardId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          this.getConfigurationsCompleted(response);
          // display success message if initialized dashboard after save
          if (isSaved) {
            this.rabbitMQService.sendRefreshDashBoardMessage();
            this.alertsService.renderSuccessMessage(Messages.DashboardConfigSaveSuccess);
          }
        }, error: this.alertsService.showApiError
      });
  }

  nextDashboard() {
    this.currentDashboardNumber = (this.currentDashboardNumber + 1) % this.mappedDashboards.length;
    this.showDashboard();
  }

  showDashboard() {
    if (this.mappedDashboards && this.mappedDashboards.length > 1) {
      const currentDashboard = this.mappedDashboards[this.currentDashboardNumber];
      if (currentDashboard) {
        const dashboardConfig = filter(this.dashboardConfigurations, (dashboard) => {
          return dashboard.DashboardId === currentDashboard.DashboardId;
        });
        this.getConfigurationsCompleted(dashboardConfig);
        this.dashboardTimer = setTimeout(() => {
          if (this.dashboardTimer) {
            clearTimeout(this.dashboardTimer);
          }
          this.nextDashboard();
        }, currentDashboard.Seconds * 1000);
      }
    } else {
      this.dashboardId = this.mappedDashboards && this.mappedDashboards[0] ? this.mappedDashboards[0].DashboardId : 0;
      this.initDashboardStateParams();
      this.initializeMappedDashboard();
    }
  }

  getTerminalDashboardProperties() {
    if (this.dashboardStateParams && this.dashboardStateParams.terminalId) {
      this.spinnerService.show();
      const observables: Array<Observable<any>> = [];
      observables.push(this.configurationService.getDashboardLayoutSpecifications());
      observables.push(this.dashboardTerminalService.getTerminalDashboardProperties(this.dashboardStateParams.terminalId));
      observables.push(this.configurationService.getDashboardConfigurationsByTerminal(this.dashboardStateParams.terminalId));

      forkJoin(observables)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: ([layoutSpecification, dashboardProperties, dashboardConfigurations]:
            [Array<DashboardLayoutSpecification>, Array<TerminalDashboardProperty>, Array<DashboardConfiguration>]) => {
            this.getLayoutSpecificationsCompleted(layoutSpecification);
            this.mappedDashboards = dashboardProperties;
            this.dashboardConfigurations = dashboardConfigurations;
            this.widgetsConfiguration = this.dashboardConfigurations;
            this.showDashboard();
          },
          error: this.alertsService.showApiError
        });
    }
  }

  // closeDashboard() {
  //   this.stateService.go('manage.dashboards.list');
  // }

  initializeLayouts() {
    this.configurationService.getDashboardLayoutSpecifications()
      .subscribe({
        next: (response) => {
          this.getLayoutSpecificationsCompleted(response);
        }, error: this.alertsService.showApiError
      });
  }

  getConfigurationsCompleted(response) {
    this.dashboard = this.configurationService.newDashboard();
    setTimeout(() => {
      this.widgetsConfiguration = response;
      if (response && response[0]) {
        this.dashboard.Id = response[0].DashboardId;
        this.dashboard.Name = response[0].Name;
        this.dashboard.AutoRefreshInterval = response[0].AutoRefreshInterval;
        this.dashboard.LayoutId = response[0].LayoutId;
        this.dashboard.LayoutName = response[0].LayoutName;
        this.dashboard.layouts = this.layouts;
        this.dashboard.IsEditMode = true;
        this.isEditMode = true;
        this.isShowClock = response[0].IsShowClock;
        // always customizations Allowed if it comes for admin
        if (this.dashboardStateParams.mode === 'interactive') {
          this.dashboard.IsInteractive = true;
          this.headerLinks = {
            tasks: true,
            time: true,
            admin: true,
            activeOrders: true,
            warning: true,
            home: true,
            mySubscriptions: true,
            customLinks: true,
            reload: true,
            signIn: true,
          };
        } else {
          this.dashboard.IsInteractive = false;
        }

        if (!this.dashboardRefreshSubscription && this.dashboard.AutoRefreshInterval && this.dashboardStateParams.mode !== 'interactive') {
          this.subscribeToReload();
          this.organizeWidgets(response);
        } else
          this.organizeWidgets(response);
      }
    });
  }

  subscribeToReload() {
    this.dashboardRefreshSubscription = this.rabbitMQService.subscribeToDashboardConfiguration$().subscribe({
      next: (message: any) => {
        this.initDashboardStateParams();
        this.initializeMappedDashboard();
      }
    })
  }

  getLayoutSpecificationsCompleted(resLayouts) {
    // organize the layouts with rows and columns
    const layouts = {};

    // group by layout name
    const groupLayouts = groupBy(resLayouts, (l) => {
      return l.Name;
    });

    // iterate for every unique layout
    forEach(groupLayouts, (value, key) => {
      // group by rowId
      const groupRows = groupBy(value, (r) => {
        return r.RowId;
      });

      const rows = [];

      // iterate for every unique row
      forEach(groupRows, (colValue) => {
        const cols = [];

        // iterate for every row
        // as a row have multiple columns
        forEach(colValue, (cValue) => {
          cols.push({ styleClass: cValue.Style, rowIndex: rows.length + 1, columnIndex: cols.length + 1 });
        });
        rows.push({ columns: cols });
      });

      layouts[key] = { layoutId: value[0].LayoutId, rows: rows };
    });
    this.layouts = layouts;
    this.dashboard.layouts = layouts;
  }

  organizeWidgets(res) {
    // group by rowId
    const groupRows = groupBy(res, (r) => {
      return r.RowId;
    });

    const rows = [];
    // iterate for every unique rowId
    forEach(groupRows, (rowsData) => {

      // group by columnId
      const groupCols = groupBy(rowsData, (c) => {
        return c.ColumnId;
      });

      const cols = [];
      // iterate for every unique columnId
      forEach(groupCols, (arrCol) => {
        const col = { styleClass: arrCol[0].Style, rowIndex: arrCol[0].RowId, columnIndex: arrCol[0].ColumnId };
        const widgets = [];

        // iterate for every column
        // as there might be multiple widgets in a column
        forEach(arrCol, (val) => {
          if (val.WidgetId) {
            const widgetDetail = this.widgetService.getWidgetDetails(val.WidgetKey);

            const widget = {
              reload: widgetDetail.reload,
              frameless: widgetDetail.frameless,
              widgetAssignmentId: val.WidgetAssignmentId,
              widgetId: val.WidgetId,
              rowIndex: val.RowId,
              columnIndex: val.ColumnId,
              type: val.WidgetKey,
              config: this.configurationService.jsonData(val.Configuration),
              title: val.WidgetTitle,
              icon: val.Icon,
              AutoRefreshInterval: this.dashboardStateParams.mode !== 'interactive' && !val.WidgetAutoRefreshInterval ? this.dashboard.AutoRefreshInterval : val.WidgetAutoRefreshInterval,
              allowedInputTypes: val.AllowedInputTypes,
              DatasourceId: val.DatasourceId,
              edit: {
              },
              widgetData: val.WidgetData
            };
            widgets.push(widget);
          }
        });
        if (widgets.length > 0) {
          col['widgets'] = widgets;
        }
        cols.push(col);
      });
      rows.push({ columns: cols });
    });
    this.dashboard.rows = rows;
  }

  saveDashboard(model: AdfModel) {
    if (this.widgetsDeleted && this.widgetsDeleted.length) {
      this.deleteWidgetAssignments(this.widgetsDeleted.join());
    }
    const dataModel = {
      dashboardId: model.Id,
      name: model.Name,
      AutoRefreshInterval: model.AutoRefreshInterval,
      layoutId: model.LayoutId
    };
    this.spinnerService.show();
    this.configurationService.saveDashboard(dataModel)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (dashboardId) => {
          if (dashboardId > 0) {
            this.dashboard.Id = dashboardId;
            this.dashboardBroadcastingService.saveWidget(this.dashboard.Id);
            this.widgetsDeleted = [];
            this.dashboard.IsEditMode = false;
            this.isEditMode = false;
          }
          if (this.spinnerService._requestCount <= 1) {
            this.dashboardBroadcastingService.widgetSaved();
          }
        }, error: this.alertsService.showApiError
      });
  }

  deleteWidgetAssignments(widgetAssignmentIds) {
    this.spinnerService.show();
    this.configurationService.deleteDashboardWidgetAssignments(widgetAssignmentIds).pipe(finalize(() => {
      this.spinnerService.hide();
    }))
      .subscribe({
        next: () => {
        }, error: this.alertsService.showApiError
      });
  }
}
