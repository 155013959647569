import { Component, OnInit } from '@angular/core';
import { BaseListComponent } from 'src/app/shared/components/base-list.component';
import { CashDrawer } from '../../interface/cash-drawer';
import { GridColumn, DateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { AuthenticationService } from 'src/app/shared/auth/services/authentication.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { CashDrawerEntriesService } from '../../service/cash-drawer-entries.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import { Permissions, Levels, DomainConstants } from '../../../../shared/constants/';
import { cashDrawerEntriesMoneyBillWave, printWhite } from 'src/app/shared/components/icon';
import * as _ from 'lodash';
import { ApplicationStateService, ExportService } from 'src/app/shared';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrencyColumn } from '@tarktech/tark-ng-utils/table/currency-column';
import { Format } from '@tarktech/tark-ng-utils/table/format-type';
@Component({
    selector: 'pos-cash-drawer-entries',
    templateUrl: './cash-drawer-entries.component.html',
    styleUrls: ['./cash-drawer-entries.component.scss']
})
export class CashDrawerEntriesComponent extends BaseListComponent<CashDrawer> implements OnInit {

    permission = {
        name: Permissions.OperationsCashDrawerEntries,
        readOnlyLevel: Levels.ReadOnly,
        editLevel: Levels.Edit
    };
    public icons = {
        printWhite,
        cashDrawerEntriesMoneyBillWave
    };
    cashDrawerEntryColumns: Array<GridColumn> = [];
    cashDrawerEntries: CashDrawer[] = [];
    constructor(
        private authenticationService: AuthenticationService,
        protected alertService: AlertsService,
        protected spinnerService: SpinnerService,
        protected cashDrawerEntriesService: CashDrawerEntriesService,
        protected modalService: ModalService,
        protected printService: PrintTableService,
        protected route: ActivatedRoute,
        protected applicationStateService: ApplicationStateService,
        private router: Router,
        private exportService: ExportService
    ) {
        super(cashDrawerEntriesService, alertService, spinnerService, modalService, applicationStateService, printService, route);
    }

    ngOnInit() {
        this.deleteSuccessMessage = '';
        this.cashDrawerEntryColumns = this.configureGridColumns();
        this.checkPermission();
        this.loadSubscriptions();
    }
    public getGridColumns(): Array<GridColumn> {
        const amountColumn = new CurrencyColumn({
            HeaderText: 'Amount',
            Field: 'amount',
            Width: '20%',
            IsSortable: true
        });

        return [
            new DateColumn({
                HeaderText: 'Date',
                Format: Format.Date,
                Field: 'date',
                TextAlign: TextAlign.Left,
                IsSortable: true,
                Width: '20%'
            }),
            amountColumn,
            new GridColumn({ HeaderText: 'Cashier', Field: 'user_name', IsSortable: true, Width: '20%' }),
            new GridColumn({ HeaderText: 'Action', Field: 'action', IsSortable: true, Width: '20%' }),
            new GridColumn({ HeaderText: 'Drawer', Field: 'register_name', IsSortable: true, Width: '20%' })
        ] as Array<GridColumn>;
    }
    private checkPermission(): void {
        _.remove(this.cashDrawerEntryColumns, (column) => {
            return (column.Type === DomainConstants.GridColumnsTypes.EditColumn
                || column.Type === DomainConstants.GridColumnsTypes.DeleteColumn);
        });
    }
    private loadSubscriptions(): void {
        this.data$.subscribe({
            next: (res) => {
                this.cashDrawerEntries = res;
            }
        });
    }
    public editItem(id: number, data?: CashDrawer): void {
    }
    public getConfirmDeleteMessage(data: CashDrawer): string {
        return '';
    }
    public onPrint(): void {
        this.printData();
    }

    public exportCSV() {
        this.exportService.exportCSV('cash-drawer-entries', this.cashDrawerEntries, null, this.getGridColumns());
    }

    close() {
        this.router.navigate(['../'], { relativeTo: this.route });
    }
}
