import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICloseable } from 'src/app/shared';

@Component({
  selector: 'pos-peripheral-model',
  templateUrl: './peripheral-model.component.html',
  styleUrls: ['./peripheral-model.component.scss']
})
export class PeripheralModelComponent implements OnInit, ICloseable {

  @Input() peripheralCreditCardMessage: string;
  isDisableApprovedButton: boolean = true;
  @Output() close: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
    const self = this;
    setTimeout(() => {
      self.isDisableApprovedButton = false;
    }, 5000);
  }

  onPeripheralApproved = () => {
    this.close.emit({ approve: true });
  }

  onPeripheralCreditCardCancel = () => {
    this.close.emit({ approve: false });
  }

}
