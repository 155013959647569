<pos-edit-wrapper heading="Duplicate Receiving" (close)="onCancel()">
    <div class="modal-body pos-modal-body">
        <div class="col-12">
            <span class="font-weight-600">{{productReceived.Product}} </span>
            <span>has been received {{date != 'Today'? 'on ':''}}</span>
            <span class="italic font-weight-600">{{date}}</span><span>,</span>
        </div>
        <div class="col-12 ps-pix-20 pt-pix-10 color-grey-dark-6">
            <ul>
                <li *ngFor="let product of productList" class="list-style-type-disc">
                    <span class="font-weight-600">{{product.Qty}} {{product.Unit}}</span>
                    <span class="ms-pix-30 italic color-grey-9">{{product.DateReceived | tark_time}}
                        ({{product.InventoryOffsiteLocation}})</span>
                </li>
            </ul>
        </div>
    </div>
    <div class="col-12 action-button-container popup-footer">
        <button class="btn btn-primary btn-lg btn-cancel-modal pos-button white" (click)="onConfirm()"
            type="button">Ok</button>
    </div>
</pos-edit-wrapper>
