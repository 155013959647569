import { ElementRef } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import {
  AlertsService,
  BaseCrudComponent,
  FormUtilityService,
  Messages,
  RuntimeConstants,
  SpinnerService,
} from 'src/app/shared';
import { TagService } from 'src/app/shared/services/tag.service';
import { Tag } from '../../interface';
import { tag, times } from 'src/app/shared/components/icon/icons';
import { forkJoin, Observable } from 'rxjs';
import { forEach } from 'lodash';
import { ExtensionValidationService } from 'src/app/shared/services/extension-validation.service';
declare let $: any;
@Component({
  selector: 'pos-tags-edit',
  templateUrl: './tags-edit.component.html',
  styleUrls: ['./tags-edit.component.scss'],
})
export class TagsEditComponent extends BaseCrudComponent<Tag> implements OnInit {
  imagePath = RuntimeConstants.IMAGE_BASE_PATH + '/tags';
  @ViewChild('tagForm') tagForm: NgForm;
  @ViewChild('tagImage') tagImage: ElementRef;
  tag: Tag;
  tagIcon: File;
  showTagIcon = false;
  tagIconName = '';
  id = 0;
  icons = {
    times,
    tag
  };
  props = {
    controlBoxClass: 'col-lg-3 col-md-5 col-sm-8 col-xs-12'
  }
  tagCategories: Array<{ Name: string, Value: string }> = [];
  recommendedSizeMessage = Messages.TagIconRecommendedSize;
  get getForm(): NgForm {
    return this.tagForm
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    protected alertService: AlertsService,
    protected tagService: TagService,
    protected spinnerService: SpinnerService,
    private extensionValidation: ExtensionValidationService,
    formUtilityService: FormUtilityService
  ) {
    super(tagService, alertService, spinnerService, formUtilityService);
    this.id = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
  }

  ngOnInit(): void {
    this.tag = this.tagService.newTag();
    this.saveSuccessMessage = Messages.TagSaveSuccess;
  }

  public loadDependencies(): void {
    if (this.id > 0) {
      this.spinnerService.show();
      const observable: Array<Observable<any>> = [];
      observable.push(this.loadData());
      observable.push(this.tagService.getTagCategories());
      forkJoin(observable)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: ([data, categories]: [Tag, Array<string>]) => {
            this.tag = data;
            forEach(categories, (key, value: string) => {
              this.tagCategories.push({ Name: key, Value: value });
            });
          },
          error: this.alertService.showApiError
        });
    } else {
      this.tag = this.tagService.newTag();
      this.tagService.getTagCategories()
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (categories: Array<string>) => {
            forEach(categories, (key, value: string) => {
              this.tagCategories.push({ Name: key, Value: value });
            });
          }, error: this.alertService.showApiError
        });
    }
  }

  public onSaveSuccess(): void {
    this.router.navigate(['tags'], { relativeTo: this.route.parent });
  }

  public submit(isValid: Boolean) {
    if (!isValid) {
      return;
    }
    this.save(this.tag, this.tagIcon, true);
  }

  handleFileInput(files: FileList) {
    this.tagIcon = files.item(0);
    const fileToUpload = this.tagIcon ? this.tagIcon.name : '';
    if (this.extensionValidation.isInvalidExtension(fileToUpload)) {
      if (fileToUpload) {
        this.alertService.renderErrorMessage(Messages.InvalidReceiptLogoFileType);
      }
      this.removeIcon();
      return;
    }

    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        $('#tagImage').attr('src', reader.result);
      };
      reader.readAsDataURL(files[0]);
      this.showTagIcon = true;
      this.tag.Icon = this.tagIcon.name;
    }
  }

  removeIcon() {
    this.tag.Icon = '';
    $('#tagIcon').val('');
    this.showTagIcon = false;
    this.tagIconName = '';
  }

  removeTagColor() {
    this.tag.Color = null;
  }

  onCancel() {
    this.router.navigate(['tags'], { relativeTo: this.route.parent });
  }

}
