import { NgModule } from '@angular/core';
import { TokenService } from './services/token.service';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { jwtOptionsFactory } from './factories/jwtOptionsFactory';
import { HttpClientModule } from '@angular/common/http';
import { AuthenticationService } from './services/authentication.service';

@NgModule({
  imports: [
    HttpClientModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [TokenService]
      }
    })
  ],
  declarations: [],
  exports: [],
  providers: [TokenService, AuthenticationService]
})
export class AuthModule {}
