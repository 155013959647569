import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstructionTerminalComponent } from './components/instruction-terminal/instruction-terminal/instruction-terminal.component';
import { INSTRUCTION_STATES } from './instruction-terminal.routes';
import { NgPipesModule } from 'ngx-pipes';
import { InstructionComponent } from '../information-management';
import { ComponentsModule } from '../shared';
import { RouterModule } from '@angular/router';
import { HeaderEntryModule } from '../header-entry/header-entry.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(INSTRUCTION_STATES),
    NgPipesModule,
    ComponentsModule,
    HeaderEntryModule
  ],
  declarations: [InstructionComponent, InstructionTerminalComponent]
})

export class InstructionTerminalModule { }
