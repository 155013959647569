import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { GridColumn, EditColumn, DeleteColumn, LinkColumn } from '@tarktech/tark-ng-utils';
import {
  SpinnerService,
  AlertsService,
  ModalService,
  ConfirmDeleteComponent,
  Messages,
  Permissions,
  Levels,
  AuthenticationService,
  PrintTableService,
  BaseGridComponent,
  ApplicationStateService
} from 'src/app/shared';
import { InfoLinkSectionsService } from '../../service/info-link-sections.service';
import { InfoLinkSectionsModel } from '../../interface/info-link-sections-model';
import { InfoLinkSectionsEditComponent } from '../info-link-sections-edit/info-link-sections-edit.component';
import { ActivatedRoute, Router } from '@angular/router';
import { infoLinkSectionsExternalLinkSquareAlt } from 'src/app/shared/components/icon';

@Component({
  selector: 'pos-info-link-sections',
  templateUrl: './info-link-sections.component.html',
  styleUrls: ['./info-link-sections.component.scss']
})
export class InfoLinkSectionsComponent extends BaseGridComponent implements OnInit {

  @ViewChild('confirmDeleteOption') private confirmDeleteOption: ConfirmDeleteComponent;
  infoLinkSectionsColumns: Array<GridColumn> = [];
  printInfoLinkSectionsColumns: Array<GridColumn> = [];
  infoLinkSectionDetailsList: Array<InfoLinkSectionsModel> = [];
  selectedInfoLinkId: number;
  modalRefInfoLink: BsModalRef;

  constructor(private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private infoLinkSectionsService: InfoLinkSectionsService,
    private modalService: ModalService,
    private authenticationService: AuthenticationService,
    protected applicationStateService: ApplicationStateService,
    protected route: ActivatedRoute,
    protected router: Router,
    private printService: PrintTableService) {
    super(applicationStateService, route);
  }

  permission = {
    ManageInfoLinkSections: Permissions.ManageInfoLinkSections
  };

  icons = { infoLinkSectionsExternalLinkSquareAlt };
  accessLevels = {
    ReadOnly: Levels.ReadOnly,
    Edit: Levels.Edit
  };

  ngOnInit() {
    this.configureInfoLinkSectionsGridColumns();
    this.reloadInfoLinkSections();
  }

  configureInfoLinkSectionsGridColumns() {
    const editColumn = new EditColumn({
      Clicked: (data) => {
        this.editInfoLinkSections(data.Id);
      },
      Width: '57px',
      AddIcon: new LinkColumn({
        Clicked: () => {
          this.editInfoLinkSections(0);
        },
        ToolTip: 'New'
      })
    });

    const deleteColumn = new DeleteColumn({
      Clicked: (data) => {
        this.deleteInfoLinkSections(data.Id);
      },
      Width: '83px',
      PrintIcon: new LinkColumn({
        Clicked: () => {
          this.printData();
        }
      })
    });

    this.infoLinkSectionsColumns = [
      new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '93%' })
    ];

    this.printInfoLinkSectionsColumns = [...this.infoLinkSectionsColumns];

    if (this.authenticationService.userHasPermission([{ Name: this.permission.ManageInfoLinkSections, Level: this.accessLevels.Edit }],
      'any')) {
      this.infoLinkSectionsColumns.push(editColumn);
      this.infoLinkSectionsColumns.push(deleteColumn);
    }
  }

  reloadInfoLinkSections() {
    this.spinnerService.show();
    return this.infoLinkSectionsService.getInfoLinkSections().subscribe(response => {
      this.infoLinkSectionDetailsList = (response === null ? [] : response);
    },
      (error) => { this.alertService.showApiError(error); },
      () => { this.spinnerService.hide(); });
  }

  editInfoLinkSections(infoLinkId: number) {
    const modelRefInfoLinkSection = this.modalService.show(InfoLinkSectionsEditComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        infolinksectionId: infoLinkId
      }
    });
    modelRefInfoLinkSection.close.subscribe(res => {
      if (res && res.shouldReload) {
        this.onSave();
      } else {
        this.onSaveCancel();
      }
    });
  }

  deleteInfoLinkSections(infoLinkSectionId: number) {
    this.selectedInfoLinkId = infoLinkSectionId;
    this.confirmDeleteOption.Show(Messages.ConfirmDeleteInfoLinkSection);
  }

  onConfirmAcknowledge() {
    this.spinnerService.show();
    this.infoLinkSectionsService.deleteInfoLinkSection(this.selectedInfoLinkId).subscribe(response => {
      this.alertService.renderSuccessMessage(Messages.InfoLinkSectionDeleted);
      this.reloadInfoLinkSections();
    },
      (error) => { this.alertService.showApiError(error); },
      () => { this.spinnerService.hide(); });
  }

  onSave() {
    this.alertService.renderSuccessMessage(Messages.InfoLinkSectionSaveSuccess);
    this.resetInfoLinkSectionForm();
    this.reloadInfoLinkSections();
  }

  onSaveCancel() {
    this.resetInfoLinkSectionForm();
  }

  resetInfoLinkSectionForm() {
    this.selectedInfoLinkId = 0;
  }

  printData() {
    this.printService.printEmitter.next({ gridColumns: this.printInfoLinkSectionsColumns, gridData: this.infoLinkSectionDetailsList });
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
