<div class="m-0-auto">
    <div class="page-content">
        <div class="tab-content">
            <div class="tab-pane fade in active show">
                <div class="col-12">
                    <a class="close" (click)="onCancel()">×</a>
                    <h3 class="page-content__header">
                        Button History: {{buttonName}}
                    </h3>
                </div>
                <div class="col-12 pb-pix-5">
                    <div class="table-responsive">
                        <ng-table [columns]="buttonHistoryColumns" [exportFilename]="'button-history'"
                            [data]="buttonHistory" (lazyLoad)="lazyLoad($event)" [lazy]="true" [totalRecords]="totalRecords">
                        </ng-table>
                        <ng-template let-data="rowData" #toggleTemplate>
                            <span *ngIf="(data.Toggle)" class="green text-center vertical-mid font-13">
                                <pos-icon [icon]="icons?.check"></pos-icon>
                            </span>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
