<div>
  <div>
    <h4 class="modal-content__heading">Warning</h4>
  </div>
  <div class="modal-body">
    <span innerHTML="{{message}}"></span>
  </div>
  <div class="col-12 action-button-container popup-footer pb-pix-10">
    <button class="btn btn-primary btn-lg btn-cancel-modal white pos-button" (click)="hideChildModal()" type="button">{{okButtonText}}</button>
  </div>
  <div class="clearfix"></div>
</div>
