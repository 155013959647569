import { GridColumn, DateColumn, TextAlign, TemplateColumn } from '@tarktech/tark-ng-utils';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Format } from '@tarktech/tark-ng-utils/table/format-type';


@Component({
  selector: 'pos-account-orders',
  templateUrl: './account-orders.component.html',
})
export class AccountOrdersComponent implements OnInit {

  accountOrdersColumns: Array<GridColumn> = [];
  @Input('ordersData') ordersData: Array<any> = [];
  @ViewChild('amountTemplate', {static: true}) private amountTemplate: any;

  ngOnInit() {
    this.configureGridColumns();    
  }


  private configureGridColumns() {
    const amountColumn = new TemplateColumn({
      HeaderText: 'Amount',
      itemTemplate: this.amountTemplate,
      Field: 'GrandTotal',
      TextAlign: TextAlign.Right,
      IsSortable: true
    });
    this.accountOrdersColumns = [
      new DateColumn({ HeaderText: 'Date', Format: Format.Date, Field: 'DateAdded', IsSortable: true, Width: '40%',
        TextAlign: TextAlign.Left }),
      // new GridColumn({ HeaderText: 'Date', Field: 'ServedDate', IsSortable: true, Width: '40%' }),
      new GridColumn({ HeaderText: 'Order', Field: 'SurrogateOrderId', IsSortable: true, Width: '40%' }),
      amountColumn
    ] as Array<GridColumn>;

  }

}
