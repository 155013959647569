<button class="close kiosk-close" *ngIf="showCloseIcon" (click)="closeModal()">&times;</button>
<div class="kiosk-modal-header">
    <h1 *ngIf="heading" [innerHTML]="heading"></h1>
</div>
<div class="page-content clearfix">
    <div class="tab-content">
        <div class="tab-pane fade in active show popup-content pti-0 pbi-0 mti-0">
            <div class="col-12 p-0">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>
