import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertsService, ApplicationStateService, ColorUtilityService, InventoryProductDietaryWarningService, RabbitMQService, TagService } from '../shared/services';
import { exclamationTriangle, tag, filterToggleOn, filterToggleOff, fieldInfoCircle, check, objectGroup } from 'src/app/shared/components/icon';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SpinnerService } from '../shared/components';
import { DietaryWarning } from '../information-management/dietary-warnings';
import { find, forEach, orderBy } from 'lodash';
import { Tag } from '../information-management/tags';
import { DomainConstants } from '../shared/constants/domain-constants';
import { MenuDisplayService, MenuWidget } from '../menu-display';
declare let $;

@Component({
  selector: 'pos-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  @Input() filteredWarningsAndTags: { Tags: Array<Tag>, DietaryWarnings: Array<DietaryWarning>, Widgets: Array<MenuWidget>, filterAction: string } =
    { Tags: [], DietaryWarnings: [], Widgets: [], filterAction: DomainConstants.OrderNavigationFilterActions.DISABLE };
  screenHeight = $(window).height() - 500 < 150 ? 210 : 410;
  close: EventEmitter<any> = new EventEmitter();
  @Output() filteredData: EventEmitter<any> = new EventEmitter();

  tabList = {
    DietaryWarnings: 'Allergens',
    Tags: 'Tags',
    Groups: 'Groups'
  };

  filterActions = DomainConstants.OrderNavigationFilterActions;
  filterAction: string;
  icons = {
    exclamationTriangle, tag, filterToggleOn, filterToggleOff, fieldInfoCircle, check, objectGroup
  };
  dietaryWarnings: Array<{ Id: number; Name: string; Value: boolean }> = [];
  dietaryWarningsCount = 0;
  tags: Array<{ TagId: number; Color: string; Name: string; Value: boolean, Icon: string }> = [];
  tagsCount = 0;
  groupWidgets: Array<MenuWidget> = [];
  widgetsCount = 0;
  selectedTab = this.tabList.DietaryWarnings;
  badgeBgColor = null;
  badgeFontColor = null;
  menuInteractivityActionType = DomainConstants.MenuInteractivityActionType;
  isFilterAppliedOnMenu = false;

  constructor(private colorUtilityService: ColorUtilityService,
    private applicationStateService: ApplicationStateService,
    protected spinnerService: SpinnerService,
    protected alertService: AlertsService,
    private tagService: TagService,
    private menuDisplayService: MenuDisplayService,
    private inventoryProductDietaryWarningService: InventoryProductDietaryWarningService,
    private rabbitMQService: RabbitMQService) {
  }

  ngOnInit(): void {
    this.badgeBgColor = this.applicationStateService.settingParam.ColorPreference.TrimColor;
    this.badgeFontColor = this.colorUtilityService.getContrastColor(this.badgeBgColor);
    this.loadData();
    this.resizeWindow();
  }

  resizeWindow = () => {
    $(window).resize(() => {
      this.screenHeight = $(window).height() - 500 < 150 ? 210 : 410;
    });
  }

  loadData() {
    this.spinnerService.show();
    const filterObservables: Array<Observable<any>> = [];
    filterObservables.push(this.inventoryProductDietaryWarningService.getDietaryWarnings());
    filterObservables.push(this.tagService.getAll());
    filterObservables.push(this.menuDisplayService.getMenuWidgetsOfType(DomainConstants.DigitalMenuWidgetTypes.Group.Name));
    forkJoin(filterObservables)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: ([dietaryWarnings, tags, groupWidgets]: [Array<DietaryWarning>, Array<Tag>, Array<MenuWidget>]) => {
          forEach(dietaryWarnings, (warning) => {
            const name = warning.Name.charAt(0).toUpperCase() + warning.Name.substring(1);
            const dietaryWarning = find(this.filteredWarningsAndTags.DietaryWarnings, x => x.Name === name);
            if (dietaryWarning) this.dietaryWarningsCount++;
            this.dietaryWarnings.push({ Id: warning.Id, Name: name, Value: dietaryWarning ? true : false });
          });
          forEach(tags, (t) => {
            const filteredTag = find(this.filteredWarningsAndTags.Tags, x => x.Name === t.Name);
            if (t.IsSearchable) {
              if (filteredTag) this.tagsCount++;
              this.tags.push({ TagId: t.Id, Name: t.Name, Color: t.Color, Icon: t.Icon, Value: filteredTag ? true : false });
            }
          });
          forEach(groupWidgets, (widget) => {
            const filteredWidget = find(this.filteredWarningsAndTags.Widgets, x => x.Name === widget.Name);
            if (filteredWidget) this.widgetsCount++;
            widget.IsAppliedFilter = filteredWidget != null;
          });
          this.filterAction = this.filteredWarningsAndTags?.filterAction ? this.filteredWarningsAndTags.filterAction : DomainConstants.OrderNavigationFilterActions.DISABLE;
          this.dietaryWarnings = orderBy(this.dietaryWarnings, 'Name');
          this.tags = orderBy(this.tags, 'Name');
          this.groupWidgets = groupWidgets;
        },
        error: this.alertService.showApiError
      });
  }

  countWarnings(warning: { Name: string; Value: boolean }, value: boolean) {
    if (value) {
      this.dietaryWarningsCount++;
    } else {
      this.dietaryWarningsCount--;
    }
    warning.Value = value;
  }

  countTags(tag: { Name: string; Value: boolean }, value: boolean) {
    if (value) {
      this.tagsCount++;
    } else {
      this.tagsCount--;
    }
    tag.Value = value;
  }

  countWidgets(widget: MenuWidget, value: boolean) {
    if (value) {
      this.widgetsCount++;
    } else {
      this.widgetsCount--;
    }
    widget.IsAppliedFilter = value;
  }

  reset() {
    this.tagsCount = 0;
    this.dietaryWarningsCount = 0;
    this.widgetsCount = 0;
    forEach(this.dietaryWarnings, (warning) => { warning.Value = false; });
    forEach(this.tags, (t) => { t.Value = false; });
    forEach(this.groupWidgets, (widget) => { widget.IsAppliedFilter = false; });
    this.filterAction = DomainConstants.OrderNavigationFilterActions.DISABLE;
    this.resetMenuDisplayInteractivity();
  }

  closeAndApply() {
    this.sendFilteredData();
    this.close.emit({ IsFilterApplied: this.tagsCount > 0 || this.dietaryWarningsCount > 0 || this.widgetsCount > 0 });
  }

  sendFilterDataToMenu = (actionType: string) => {
    const dietaryWarnings = this.dietaryWarnings.filter(x => x.Value)?.map(x => x.Id);
    const tags = this.tags.filter(x => x.Value)?.map(x => x.TagId);
    const widgets = this.groupWidgets.filter(x => x.IsAppliedFilter)?.map(x => x.Id);
    if (dietaryWarnings?.length || tags?.length || widgets?.length) {
      this.isFilterAppliedOnMenu = true;
      this.rabbitMQService.sendFilterMenuItemsMessage(this.applicationStateService.terminalId, dietaryWarnings, tags, widgets, actionType);
    }
  }

  closeDialog() {
    this.resetMenuDisplayInteractivity();
    this.close.emit({ IsFilterApplied: this.filteredWarningsAndTags.Tags?.length > 0 || this.filteredWarningsAndTags.DietaryWarnings?.length > 0 || this.filteredWarningsAndTags.Widgets?.length > 0 });
  }

  sendFilteredData() {
    this.filteredData.emit({
      filteredData:
      {
        DietaryWarnings: this.dietaryWarnings.filter(x => x.Value),
        Tags: this.tags.filter(x => x.Value),
        Widgets: this.groupWidgets.filter(x => x.IsAppliedFilter),
        filterAction: this.filterAction
      }
    });
  }

  resetMenuDisplayInteractivity() {
    this.rabbitMQService.sendFilterMenuItemsMessage(this.applicationStateService.terminalId, [], [], []);
  }

}
