<div class="row edit-form ps-pix-15 menu-display-overrides">
    <div class="form-horizontal">
        <div class="row pb-pix-10">

            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-5 ps-pix-12">
                <strong>
                    <span class="display-flex">
                        Days
                    </span>
                </strong>
            </div>
            <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 col-170">
                <strong>
                    <span class="display-flex">
                        Start Time
                    </span>
                </strong>
            </div>
            <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 col-170">
                <strong>
                    <span class="display-flex">
                        End Time
                    </span>
                </strong>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-4 col-xs-5 col-250">
                <strong>Menu</strong>
            </div>
        </div>
        <div class="row mb-pix-10"
            *ngFor="let menuDisplaySetting of menuDisplaySettings; let last = last; let index = index;">

            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-5"
                [ngClass]="{'has-error': (day.invalid && isFormSubmitted && !last)}">
                <ng-select [items]="daysOfWeek" [multiple]="true" bindLabel="Name" bindValue="Id"
                    placeholder="Select day(s)" name="day{{index}}" appendTo="body"
                    (change)="createMenuDisplaySettingRow(last); resetOverlappedTime()" #day="ngModel"
                    [(ngModel)]="menuDisplaySetting.DaysIds" [required]="!last" [closeOnSelect]="false">

                    <ng-template ng-option-tmp let-item="item" let-index="index" let-item$="item$">
                        <p-checkbox class="me-pix-5" name="item.{{index}}" id="item.{{index}}"
                            (click)="item$.selected = !item$.selected" #item.index="ngModel"
                            [(ngModel)]="item$.selected" binary="true">
                        </p-checkbox>
                        <span>{{item.Name}}</span>
                    </ng-template>

                </ng-select>

                <span *ngIf="day.invalid && isFormSubmitted && !last">
                    <label class="control-label" *ngIf="day.errors?.required">Please select day(s).</label>
                </span>
            </div>
            <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 col-170"
                [ngClass]="{'has-error': (startTime.invalid && isFormSubmitted && !last)}">
                <input type="time" class="form-control" name="startTime{{index}}" #startTime="ngModel"
                    [(ngModel)]="menuDisplaySetting.StartTime"
                    (change)="createMenuDisplaySettingRow(last); resetOverlappedTime()" [required]="!last">
                <span *ngIf="startTime.invalid && isFormSubmitted && !last">
                    <label class="control-label align-left" *ngIf="startTime.errors?.required">Please select start
                        time.</label>
                </span>
                <span *ngIf="menuDisplaySetting.HasBetweeenStartTime">
                    <label class="text-danger">
                        Time of a menu cannot overlap with another menu.</label>
                </span>
            </div>
            <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 col-170"
                [ngClass]="{'has-error': (endTime.invalid && isFormSubmitted && !last)}">
                <input type="time" class="form-control" name="endTime{{index}}" #endTime="ngModel" step="1"
                    [(ngModel)]="menuDisplaySetting.EndTime"
                    (change)="createMenuDisplaySettingRow(last); resetOverlappedTime()" [required]="!last">
                <span *ngIf="endTime.invalid && isFormSubmitted && !last">
                    <label class="control-label align-left" *ngIf="endTime.errors?.required">Please select end
                        time.</label>
                </span>
                <span *ngIf="menuDisplaySetting.HasBetweeenEndTime">
                    <label class="text-danger">
                        Time of a menu cannot overlap with another menu.</label>
                </span>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-4 col-xs-5 col-250"
                [ngClass]="{'has-error': (menu.invalid && isFormSubmitted && !last)}">
                <ng-select [items]="displayMenuData" [hideSelected]="true" bindLabel="Name" bindValue="Id" appendTo="body"
                    placeholder="Select menu" name="menu{{index}}" (change)="createMenuDisplaySettingRow(last)"
                    #menu="ngModel" [(ngModel)]="menuDisplaySetting.MenuDisplayId" [required]="!last">
                </ng-select>
                <span *ngIf="menu.invalid && isFormSubmitted && !last">
                    <label class="control-label" *ngIf="menu.errors?.required">Please select menu.</label>
                </span>
            </div>
            <div class="col-1 pt-pix-3" *ngIf="!last">
                <pos-action-icon [icon]="icons.deleteWhite"
                    (click)="removeMenuDisplaySetting(menuDisplaySetting, index)">
                </pos-action-icon>
            </div>
        </div>

    </div>
</div>
