import { Component, Input } from '@angular/core';
import moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs';
import { ButtonDesign, KioskScreenConfig } from 'src/app/order-kiosk/interfaces';
import { OrderKioskConfigService } from 'src/app/order-kiosk/services';
import { AlertsService, ExtensionValidationService, Messages, RuntimeConstants } from 'src/app/shared';
import { ModalComponent, SpinnerService, times } from 'src/app/shared/components';
declare let $: any;

@Component({
  selector: 'pos-design-button',
  templateUrl: './design-button.component.html',
  styleUrls: ['./design-button.component.scss']
})
export class DesignButtonComponent extends ModalComponent {
  @Input() kioskScreenConfig: KioskScreenConfig;
  @Input() buttonIndex = 0;
  @Input() buttonsLength: number;
  buttonDesignConfig: { Icon: string, IconBackground: string };
  buttonsDesign: Array<ButtonDesign> = [];
  imageFile: File;
  imageName: string;
  imageRootPath = `${RuntimeConstants.IMAGE_BASE_PATH}/order-kiosk/buttons/`;
  icons = {
    times
  }
  constructor(
    public modalRef: BsModalRef,
    private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private orderKioskConfigService: OrderKioskConfigService,
    private extensionValidation: ExtensionValidationService
  ) {
    super(modalRef);
    this.buttonDesignConfig = { Icon: null, IconBackground: '' };
  }
  ngOnInit() {
    this.buttonsDesign = this.kioskScreenConfig.Config ? JSON.parse(this.kioskScreenConfig.Config) : new Array<ButtonDesign>(this.buttonsLength);
    this.buttonDesignConfig = this.buttonsDesign[this.buttonIndex] ?? { Icon: null, IconBackground: '' };
  }

  save() {
    this.buttonsDesign[this.buttonIndex] = this.buttonDesignConfig;
    this.kioskScreenConfig.Config = JSON.stringify(this.buttonsDesign);
    this.spinnerService.show();
    this.orderKioskConfigService.save(this.kioskScreenConfig, this.imageFile, this.imageName)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.hide({ isReload: true });
        }, error: this.alertsService.showApiError
      });
  }

  cancel() {
    this.hide({});
  }

  handleFileInput(files: FileList, previewName: string, inputName: string) {
    const image = files[0];
    const timestamp = moment(Date.now()).format('yyyyMMDDHHmmssSSSS');
    const imageName = `${timestamp}-${image.name}`;

    const fileToUpload = image ? imageName : '';
    if (this.extensionValidation.isInvalidExtension(fileToUpload)) {
      if (fileToUpload) {
        this.alertsService.renderErrorMessage(Messages.InvalidReceiptLogoFileType);
      }
      this.removeIcon(inputName);
      return;
    }
    if (files?.[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        $('#' + previewName).attr('src', reader.result);
      };
      reader.readAsDataURL(files[0]);
      this.imageFile = image;
      this.imageName = imageName;
      return image ? imageName : '';
    }
  }

  removeIcon(imageInputName: string) {
    $('#' + imageInputName).val('');
  }

}
