import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseCrudComponent, FieldInfoMessages, AlertsService, SpinnerService, Messages, FormUtilityService } from 'src/app/shared';
import { SlideShow } from '../../interface';
import { NgForm } from '@angular/forms';
import { SlideShowService } from '../../services';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { digitalSignageBullHorn } from 'src/app/shared/components/icon/icons';

@Component({
  selector: 'pos-slide-show-edit',
  templateUrl: './slide-show-edit.component.html',
})
export class SlideShowEditComponent extends BaseCrudComponent<SlideShow> implements OnInit {
  get getForm(): NgForm {
    return this.form
  }
  id = 0;
  @ViewChild('formSlideShow') form: NgForm;
  slideShowDetail: SlideShow;

  fieldInfoMessages = FieldInfoMessages;
  icons = { digitalSignageBullHorn }

  constructor(private route: ActivatedRoute,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected slideShowService: SlideShowService,
    private router: Router,
    formUtilityService: FormUtilityService) {
    super(slideShowService, alertService, spinnerService, formUtilityService);
    this.id = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
  }

  ngOnInit() {
    this.slideShowDetail = this.slideShowService.newSlideShow();
    this.saveSuccessMessage = Messages.SlideshowSaveSuccess;
  }

  public loadDependencies(): void {
    if (this.id > 0) {
      this.spinnerService.show();
      this.loadData().pipe(finalize(() => {
        this.spinnerService.hide();
      }))
        .subscribe({
          next: (res) => {
            this.slideShowDetail = res;
            this.onFormChange(this.form);
          }, error: this.alertService.showApiError
        });
    }
    this.onFormChange(this.form);
  }

  public submit(isValid: boolean): void {
    if (!isValid) {
      return;
    }
    this.save(this.slideShowDetail);
  }

  public onSaveSuccess(model: SlideShow): void {
    this.router.navigate(['digital-signage'], { relativeTo: this.route.parent });
  }

  onFormChange($event: NgForm) {
    this.form = $event;
    this.setInitialValue();
  }

  cancelEditing() {
    this.router.navigate(['digital-signage'], { relativeTo: this.route.parent })
  }
}
