import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { ICloseable, ApplicationStateService, AlertsService, ModalService, OrderService, SpinnerService, SettingParam, Messages, InfoModalComponent } from 'src/app/shared';
import { finalize } from 'rxjs/operators';
import { ManagesService } from 'src/app/shared/services/manages.service';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';

@Component({
  selector: 'pos-gift-card-payment',
  templateUrl: './gift-card-payment.component.html',
  styleUrls: ['./gift-card-payment.component.scss']
})
export class GiftCardPaymentComponent implements OnInit, ICloseable {

  @Input() payableAmount: number;
  @Input() orderId: number;
  @Input() subAccountOrdinal: Array<any> = [];
  @Input() isPartialPayment: boolean = false;
  close: EventEmitter<any> = new EventEmitter();
  giftCardNumpadOptions = { allowDecimal: false, allowLeadingZero: true, allowAlphabets: true, doubleZero: false };
  giftCardNumber: number;
  numpadOptionChange = new EventEmitter<any>();
  settingParam: SettingParam;
  constructor(private applicationStateService: ApplicationStateService,
    private alertService: AlertsService,
    private modalService: ModalService,
    private orderService: OrderService,
    private spinnerService: SpinnerService,
    private managesService: ManagesService) { }

  ngOnInit() {
    this.setDefaults();
  }

  setDefaults() {
    this.settingParam = this.applicationStateService.settingParam;
  }

  cancelGiftCard = () => {
    this.close.emit({ paid: false });
  }

  checkBalance = (res) => {
    if (res && res.value != null && res.value !== '') {
      this.giftCardNumber = res.value;
      this.spinnerService.show();
      this.managesService.checkGiftCardBalance(res.value)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (response: any) => {
            this.giftCardCheckBalanceCompleted(response);
          }, error: this.alertService.showApiError
        });
    }
  }

  giftCardCheckBalanceCompleted(res) {
    if (res) {
      const giftCardDetails = res;
      if (giftCardDetails) {
        if (this.payableAmount > giftCardDetails.CurrentBalance) {
          if (giftCardDetails.CurrentBalance && giftCardDetails.CurrentBalance > 0) {
            const modalRef = this.modalService.show(InfoModalComponent, {
              keyboard: false,
              animated: false,
              class: 'vertical-center',
              initialState: {
                message: StringUtils.format(Messages.InsufficientGiftCardBalance,
                  {
                    'balance': parseFloat(giftCardDetails.CurrentBalance).toFixed(2),
                    'currencySymbol': this.settingParam.CurrencySymbol
                  }),
                confirmButtonText: 'Yes',
                rejectButtonText: 'No',
                modalHeaderText: 'Confirm'
              }
            });
            modalRef.close.subscribe((res) => {
              if (res && (res.shouldConfirm)) {
                // this.openCommentEntryKeyboard();
                this.payableAmount = giftCardDetails.CurrentBalance;
                this.makePayment(giftCardDetails.CardNumber);
              } else {
                // this.resolvePromptPromise(commentWarningMatch.CommentTextMatch);
              }
            });
          } else {
            const modalRef = this.modalService.show(InfoModalComponent, {
              keyboard: false,
              animated: false,
              class: 'vertical-center',
              initialState: {
                message: Messages.GiftCardBalanceZero
              }
            });
          }
        } else {
          this.makePayment(giftCardDetails.CardNumber);
        }
      }
    } else {
      const modalRef = this.modalService.show(InfoModalComponent, {
        keyboard: false,
        animated: false,
        class: 'vertical-center',
        initialState: {
          message: StringUtils.format(Messages.GiftCardNotFound,
            { 'giftCardNumber': this.giftCardNumber }),
        }
      });
    }
  }

  makePayment(giftCardId: number) {
    let payment: any = {
      Id: 0,
      OrderId: this.orderId,
      Amount: this.payableAmount,
      PaymentTypeId: 3,
      IsCompleted: false,
      UserId: this.applicationStateService.userDetails.id,
      IsCaptured: false,
      CashDrawerId: this.settingParam.CashDrawerPrinter.Id,
      SubAccountOrdinals: this.subAccountOrdinal,
      TerminalId: this.applicationStateService.terminalId,
      GiftcardNumber: giftCardId,
      IsPartialPayment: this.isPartialPayment
    };
    this.spinnerService.show();
    this.orderService.giftCardPayment(this.orderId, payment)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: any) => {
          this.close.emit({ shouldReload: true });
          if (res && res.CardNumber) {
            const modalRef = this.modalService.show(InfoModalComponent, {
              keyboard: false,
              animated: false,
              class: 'vertical-center',
              initialState: {
                message: StringUtils.format(Messages.GiftCardBalance,
                  { 'balance': res.CurrentBalance, 'currencySymbol': this.settingParam.CurrencySymbol }),
              }
            });
          }
        }, error: this.alertService.showApiError
      });
  }

}
