<div>
  <h4 class="modal-content__heading" name="numpadHeader">Balance {{dueAmount | tark_currency}}</h4>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-12">
      <div class="alert alert-danger condensed inherit-position" id="divPaymentModalErrormsg" style="display: none;">
      </div>
      <div class="alert alert-success condensed mb-0" id="divPaymentModalSuccessMsg" style="display: none;">
        {{ringupSuccessMessage}}
      </div>
    </div>
    <pos-ringup-numpad #ringupNumpad [(cashTendered)]="cashTendered" [(isShowCashTender)]="cashTendered"
      [(enteredAmount)]="typeAmount" [(numpadOptions)]="numpadPaymentOptions" [(paymentButtonText)]="paymentButtonText"
      [numpadOptionChange]="numpadOptionChange" [isSubmitPayment]="false" [(isDisablePaymentButton)]="!canModifyAmount"
      [isShowSubmitButton]="true" (cancelPayment)="cancelPaymentPrice()" (submitPayment)="makePayment($event)"
      (cashPayment)="cashPayment()" (creditCardPayment)="submitCreditCardPayment()"
      (giftCardPayment)="makePaymentGiftCard()" (EBTPayment)="makePaymentEBT($event)">
    
    </pos-ringup-numpad>
  </div>
</div>
<ng-template #creditCardResponseModel>
  <div>
    <div>
      <h4 class="modal-content__heading">{{creditCardPaymentResponseHeader}}</h4>
    </div>
    <div class="">
      <div class="col-md-12 col-sm-12 pt-pix-10 pb-pix-10 text-center">
        {{creditCardPaymentResponseMessage}}
      </div>
    </div>
    <div class="modal-footer">
      <div class="col-md-12 text-center">
        <button type="button" [hidden]="isShowCCError" (click)="cancelCreditCardTransaction()" class="btn-primary btn-lg me-pix-5">
          {{creditCardPaymentResponseButtonText}}
        </button>
        <button type="button" [hidden]="!isShowCCError" (click)="hideOverPaymentModal()" class="btn-primary btn-lg me-pix-5">
          Ok
        </button>
      </div>
    </div>
  </div>
</ng-template>
