<pos-list-wrapper [headingIcon]="icons.infoLinkSectionsExternalLinkSquareAlt" heading="Info Link Sections" (close)="close()">
  <div class="row">
    <div class="col-12">
      <ng-table #infoLinkSectionsList [columns]="infoLinkSectionsColumns" [exportFilename]="'info-link-sections'" [data]="infoLinkSectionDetailsList"
        [paginator]="false">
      </ng-table>
    </div>
  </div>
</pos-list-wrapper>
<pos-confirm-delete (onConfirmAcknowledge)="onConfirmAcknowledge()" #confirmDeleteOption>
</pos-confirm-delete>
<pos-print-table #printTableReference [hidden]="true"></pos-print-table>
