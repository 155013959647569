<div class="m-pix-10">
  <pos-edit-wrapper heading="<img src='images/admin/manage-layout.png' height='22px' /> Layout" (close)="cancelLayout()"
    [isPopup]="false">
    <form #layoutDesignerForm="ngForm" class="edit-form relative border-top"
      (ngSubmit)="saveLayout(layoutDesignerForm.valid)" autocomplete="off">
      <div class="col-12 pos-page-body">
        <pos-row class="mx-0 mb-pix-10">
          <pos-column class="p-0">
            <pos-form-field label="Name" [validationMessages]="{required: 'Please enter name.'}" inputStyle="col-12">
              <pos-row class="mx-0">
                <pos-column lg="3" sm="6" xs="12" class="p-0">
                  <input posInput type="text" name="name" #layoutName="ngModel" class="form-control"
                    [(ngModel)]="data.Name" [required]="true" />
                </pos-column>
                <pos-column lg="3" sm="6" xs="12" class="pt-pix-7">
                  <p-checkbox posInput name="active" #active="ngModel" [(ngModel)]="data.IsActive" binary="true"
                    label="Active">
                  </p-checkbox>
                </pos-column>
                <pos-column lg="6" xs="12" class="p-0 float-end" [hidden]="!data.Id">
                  <div class="btn-group float-end">
                    <label class="btn btn-radio"
                      [ngClass]="!showPreview ? 'active btn-primary normal' : 'btn-secondary'">
                      <input name="selectedLayout" (change)="layoutPreview(false)" [(ngModel)]="showPreview"
                        type="radio" [value]="false" /> Design
                    </label>
                    <label class="btn btn-radio"
                      [ngClass]="showPreview ? 'active btn-primary normal' : 'btn-secondary'">
                      <input name="selectedLayout" (click)="layoutPreview(true)" [(ngModel)]="showPreview" type="radio" [value]="true" /> Preview
                    </label>
                  </div>
                </pos-column>
              </pos-row>
            </pos-form-field>
          </pos-column>
        </pos-row>
        <div *ngIf="data.Id > 0 && !showPreview" class="col-12 mt-pix-n10">
          <div class="col-12 relative">
            <div #container></div>
            <pos-layout-designer #layoutDesign [container]="container" [canvasHeight]="height" [canvasWidth]="width"
              [graphXML]="data.LayoutDefinition" [data]="data" [readOnly]="false">
            </pos-layout-designer>
          </div>
        </div>
        <div *ngIf="data.Id > 0 && showPreview" class="col-12">
          <div class="col-12 ps-0 pb-pix-10 display-flex">
            <div class="pe-pix-5 mt-pix-n1">
              <pos-icon [icon]="icons.fieldInfoCircle"></pos-icon>
            </div>
            <div>Click any shape to mark it as {{settingParam?.AccountTerm ? settingParam.AccountTerm : 'Account'}}.
            </div>
          </div>
          <div class="col-12 p-0 relative" #previewContainer></div>
          <pos-layout-designer #layoutPreviewContainer [container]="previewContainer" [canvasHeight]="height - 60"
            [graphXML]="data.LayoutDefinition" [(data)]="data" [readOnly]="true" (shapeClick)="selectTable($event)">
          </pos-layout-designer>
        </div>
      </div>
      <div class="col-12 action-button-container page-footer">
        <button type="submit" value="Save" class="btn-primary btn-lg btn btn-save pos-button">Save</button>
        <button type="button" class="btn-secondary btn-lg btn btn-cancel pos-button" (click)="cancelLayout()"
          value="Cancel">
          Cancel
        </button>
      </div>
    </form>
  </pos-edit-wrapper>
</div>
