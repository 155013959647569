import { Component, OnInit, ViewChild } from '@angular/core';
import { ApplicationStateService, SpinnerService, SettingParam, AlertsService, Messages, BaseFormComponent, FormUtilityService } from '../../../../../app/shared';
import { FieldInfoMessages } from '../../../../../app/shared/constants/field-info-messages';
import { DatabaseService } from '../../services/database.service';
import { finalize } from 'rxjs/operators';
import { database, databasePlus } from 'src/app/shared/components/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'pos-database',
  templateUrl: './database.component.html',
  styleUrls: ['./database.component.scss']
})
export class DatabaseComponent extends BaseFormComponent implements OnInit {
  @ViewChild('databaseForm') form: NgForm;
  get getForm(): NgForm {
    return this.form
  }
  icons = {
    databasePlus,
    database
  };
  fieldInfoMessages = {
    DatabaseHICHistorySize: FieldInfoMessages.DatabaseHICHistorySize
  };
  parameters: SettingParam;
  props = {
    controlBoxClass: 'col-lg-3 col-md-5 col-sm-8 col-xs-12'
  }

  constructor(private spinnerService: SpinnerService,
    private applicationStateService: ApplicationStateService,
    private databaseService: DatabaseService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertsService,
    formUtilityService: FormUtilityService) {
    super(formUtilityService);
    this.parameters = this.applicationStateService.newSettingParams();
  }

  ngOnInit() {
    this.reloadDatabaseDetails();
  }

  reloadDatabaseDetails() {
    this.spinnerService.show();
    this.databaseService.getDatabaseInfo(this.applicationStateService.terminalId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.parameters = res;
          this.onFormChange(this.form);
        }
      });
  }

  saveDatabase(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.spinnerService.show();
    this.databaseService.saveDatabase(this.parameters)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.alertService.renderSuccessMessage(Messages.DatabaseSaveSuccess);
          this.router.navigate(['/manage/system-configuration']);
        }, error: (error) => this.alertService.showApiError(error)
      });
  }

  cancelEditing() {
    this.router.navigate(['/manage/system-configuration']);
  }

  onFormChange($event: NgForm) {
    this.form = $event;
    this.setInitialValue();
  }
  onCancel() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
