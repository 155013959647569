import { IKioskNavigation } from "../interfaces/ikiosk-navigation";
import { KioskChoiceBehaviorExecutor } from "../interfaces/kiosk-choice-behavior-executor";

export abstract class ChoiceBehavior implements KioskChoiceBehaviorExecutor {
  behaviorValues: any;
  behaviorId: number;
  terminalType: string;
  // constructor(...args: any[]) {}

  abstract execute(navObj: IKioskNavigation, orderNavigation): Promise<void>;
}
