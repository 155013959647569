import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GridColumn, TableComponent, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { SpinnerService } from 'src/app/shared/components/spinner';
import { AlertsService, ApplicationStateService, CameraAnnotationService, ExportService, PrintTableService } from 'src/app/shared/services';
import { finalize } from 'rxjs/operators';
import { AuthenticationService, BaseGridComponent, CameraAnnotations, Levels, Messages, Permissions } from 'src/app/shared';
import { ban, camera, check, checkCircleWhite, editWhite } from 'src/app/shared/components/icon';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'pos-camera-annotation-list',
  templateUrl: './camera-annotation-list.component.html',
  styleUrls: ['./camera-annotation-list.component.scss']
})
export class CameraAnnotationListComponent extends BaseGridComponent implements OnInit {

  @ViewChild('operationTemplate', { static: true }) operationTemplate: TemplateRef<any>;
  @ViewChild('headerTemplate', { static: true }) headerTemplate: TemplateRef<any>;
  @ViewChild('activeTemplate', { static: true }) activeTemplate: TemplateRef<any>;
  @ViewChild('cameraGrid', { static: true }) cameraGrid: TableComponent;
  cameraAnnotationColumns: Array<GridColumn>;
  cameraAnnotationsListAll: CameraAnnotations[] = [];
  cameraAnnotations: CameraAnnotations[] = [];
  showInactive = false;
  icons = {
    editWhite,
    check,
    ban,
    checkCircleWhite,
    camera
  };
  permission = {
    name: Permissions.SystemConfigurationCameraAnnotation,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };
  printColumns: Array<GridColumn>;

  constructor(private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private cameraAnnotationService: CameraAnnotationService,
    private printService: PrintTableService,
    private exportService: ExportService,
    protected route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    protected applicationStateService: ApplicationStateService,
  ) {
    super(applicationStateService, route);
  }

  ngOnInit(): void {
    this.configureGridColumns();
    this.loadData();
    this.cameraGrid.context = this.gridContext;
  }


  loadData() {
    this.spinnerService.show();
    this.cameraAnnotationService.getCameraAnnotation()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: CameraAnnotations[]) => {
          this.cameraAnnotationsListAll = res;
          this.cameraAnnotationsListAll.sort((a, b) => a.Description.localeCompare(b.Description));
          this.changeCameraAnnotationList();
        }, error: this.alertService.showApiError
      });
  }

  configureGridColumns() {
    const operationColumn = new TemplateColumn({
      itemTemplate: this.operationTemplate,
      Width: '120px',
      headerTemplate: this.headerTemplate,
      TextAlign: TextAlign.Center
    });

    const activeColumn = new TemplateColumn({
      HeaderText: 'Active',
      itemTemplate: this.activeTemplate,
      TextAlign: TextAlign.Center,
      Width: '80px',
      Field: 'IsActive',
      IsSortable: true,
    });

    this.cameraAnnotationColumns = [
      new GridColumn({ HeaderText: 'Event', Field: 'Description', IsSortable: true, Width: '25%' }),
      new GridColumn({ HeaderText: 'Annotation', Field: 'Annotation', IsSortable: true, Width: '65%' }),
      activeColumn
    ] as Array<GridColumn>;
    this.printColumns = [... this.cameraAnnotationColumns];
    if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      this.cameraAnnotationColumns.push(operationColumn);
    }
  }

  changeCameraAnnotationList() {
    if (this.showInactive) {
      this.cameraAnnotations = this.cameraAnnotationsListAll;
    } else {
      this.cameraAnnotations = this.cameraAnnotationsListAll.filter(x => x.IsActive);
    }
  }

  editItem(data: CameraAnnotations) {
    this.router.navigate([data.Id], { relativeTo: this.route });
  }

  protected printData() {
    this.printService.printEmitter.next({ gridColumns: this.printColumns, gridData: this.cameraAnnotations });
  }

  exportCSV() {
    this.exportService.exportCSV('camera-annotations', this.cameraAnnotations, null, this.printColumns);
  }

  updateCameraAnnotationStatus(data: CameraAnnotations, isActive: boolean) {
    data.IsActive = isActive;
    this.spinnerService.show();
    this.cameraAnnotationService.updateCameraAnnotation(data)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: _res => {
          this.alertService.renderSuccessMessage(Messages.CameraAnnotationStatusUpdated);
          this.loadData();
        }, error: this.alertService.showApiError
      });
  }

  close(){
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
