<pos-edit-wrapper heading="Schedule Overrides" (close)="onCancel()">
	<form #scheduleOverrideForm="ngForm" (ngSubmit)='saveScheduleOverride(scheduleOverrideForm.valid)'>
		<div class="col-xs-12 pos-modal-body">
			<pos-row>
				<pos-column>
					<pos-form-field label="From" [validationMessages]="{required: 'Please enter from date.'}">
						<p-calendar posInput inputStyleClass="form-control" [showIcon]="true" appendTo="body"
							name="fromDate" [dateFormat]="dateFormat" [(ngModel)]="scheduleOverrides.FromDate"
							#fromDate="ngModel" required [monthNavigator]="true" (onSelect)="onDateChange()"
							(onInput)="onDateChange()" yearNavigator="true" yearRange="2000:2099"></p-calendar>
					</pos-form-field>
				</pos-column>
				<pos-column>
					<pos-form-field label="To" [validationMessages]="{required: 'Please enter to date.'}">
						<p-calendar posInput inputStyleClass="form-control" [showIcon]="true" appendTo="body"
							name="toDate" [dateFormat]="dateFormat" [(ngModel)]="scheduleOverrides.ToDate"
							[monthNavigator]="true" required [minDate]="scheduleOverrides.FromDate"
							(onSelect)="onDateChange()" (onInput)="onDateChange()" #toDate="ngModel"
							yearNavigator="true" yearRange="2000:2099">
						</p-calendar>
					</pos-form-field>
				</pos-column>
				<pos-column>
					<pos-form-field inputStyle="col-xs-12">
						<div class="btn-group">
							<label class="btn btn-default btn-radio"
								[ngClass]="scheduleStatus === true ? 'active btn-primary normal': 'btn-secondary normal'">
								<input posInput type="radio" name="scheduleOrdering" class="form-check-input"
									#overridePickUpTime="ngModel" [(ngModel)]="scheduleStatus" [value]="true"
									(click)="scheduleStatus = true">
								Override Schedule Time
							</label>
							<label class="btn btn-default btn-radio"
								[ngClass]="scheduleStatus === false ?'active btn-primary normal': 'btn-secondary normal'">
								<input posInput type="radio" name="scheduleOrdering" class="form-check-input"
									#markAsClosed="ngModel" [(ngModel)]="scheduleStatus" [value]="false"
									(click)="scheduleStatus = false">
								Mark Closed
							</label>
						</div>
					</pos-form-field>
				</pos-column>

				<pos-column *ngIf="scheduleStatus">
					<pos-form-field label="Start Time" [validationMessages]="{required: 'Please enter start time.'}">
						<input posInput type="time" #startTime="ngModel" class="form-control col-180" name="startTime"
							(ngModelChange)="onTimeChange()" [required]="scheduleStatus"
							[(ngModel)]="scheduleOverrides.StartTime" />
					</pos-form-field>
				</pos-column>

				<pos-column *ngIf="scheduleStatus">
					<pos-form-field label="End Time" [validationMessages]="{required: 'Please enter end time.'}">
						<input posInput type="time" #endTime="ngModel" class="form-control col-180" name="endTime"
							[(ngModel)]="scheduleOverrides.EndTime" [required]="scheduleStatus"
							(ngModelChange)="onTimeChange()" />
					</pos-form-field>
				</pos-column>
				<pos-column *ngIf="invalidScheduleStatusTime && scheduleOverrideForm.submitted">
					<label class="red-extreme ps-pix-5">Start time should be less than end time.</label>
				</pos-column>
				<pos-column
					*ngIf="(invalidFromDate || invalidToDate || invalidScheduleStatusTime) && !invalidScheduleStatusTime && scheduleOverrideForm.submitted">
					<label class="red-extreme ps-pix-5">Period overlaps with another override
						({{conflictingSchedule?.FromDate | tark_date}} to
						{{conflictingSchedule?.ToDate | tark_date}})
						<span *ngIf="conflictingSchedule?.StartTime && conflictingSchedule?.EndTime">
							for time slot ({{conflictingSchedule?.StartTime }} - {{conflictingSchedule?.EndTime }})
						</span>
					</label>
				</pos-column>
			</pos-row>
		</div>
		<div class="col-12 action-button-container popup-footer">
			<button type="submit" class="btn-primary btn-lg btn btn-save pos-button" scrollup value="Save">Save</button>
			<button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" value="Cancel"
				(click)="onCancel()">Cancel</button>
		</div>
	</form>
</pos-edit-wrapper>
