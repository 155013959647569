import { Injectable } from '@angular/core';
import { PosHardwareType } from '../../device-mapping';
import { RuntimeConstants, BaseCrudService, HardwareModel, HardwareProperty } from 'src/app/shared';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { HardwareInterface } from '../../hardware-interfaces';
import { HardwareType } from '../interface/hardware-type';
import { LightsInfoModel } from '../interface/lights-info-model';

@Injectable({
  providedIn: 'root'
})
export class HardwaresService extends BaseCrudService<HardwareModel> {

  hardwareApiBasePath = `${RuntimeConstants.API_PATH}hardwares`;

  constructor(httpClient: HttpClient) {
    super('hardwares', httpClient);
  }

  newHardware() {
    const hardware: HardwareModel = {
        Id: 0,
        Name: '',
        HICId: null,
        HICName: '',
        IsActive: true,
        TypeIdentifier: null,
        Type: null,
        HarwareType: null,
        Properties: [],
        AssetTag: ''
    };
    return hardware;
  }

  newProperty(name: string, value: string): HardwareProperty {
    return {
      Name: name,
      Value: value
    };
  }

  updateHardwareStatus(id: number, isActive: boolean) {
    const request = isActive ? 'activate' : 'deactivate';
    return this.httpService.put(`${this.hardwareApiBasePath}/${id}/${request}`, id);
  }

  getAvailableHardwareTypes(): Observable<Array<HardwareType>> {
    return this.httpService.get<Array<HardwareType>>(`${this.hardwareApiBasePath}/types`);
  }

  getAvailablePosHardwareTypes(): Observable<Array<PosHardwareType>> {
    return this.httpService.get<Array<PosHardwareType>>(`${this.hardwareApiBasePath}/postypes`);
  }

  getHardwareInterfaceComputers(): Observable<Array<HardwareInterface>> {
    return this.httpService.get<Array<HardwareInterface>>(`${this.hardwareApiBasePath}/GetHardwareInterfaceComputers`);
  }

  getNanoLeafAuthToken(authTokenModel: LightsInfoModel) {
    return this.httpService.post(`${this.hardwareApiBasePath}/nano-leaf/auth-token`, authTokenModel);
  }

  getLightsInfo(authTokenModel: LightsInfoModel) {
    return this.httpService.post(`${this.hardwareApiBasePath}/nano-leaf/read-lights`, authTokenModel);
  }

  triggerFlash(authTokenModel: LightsInfoModel) {
    return this.httpService.post(`${this.hardwareApiBasePath}/nano-leaf/flash`, authTokenModel);
  }
}
