import { Component, OnInit, Input } from '@angular/core';
import { FormUtilityService, ModalFormComponent, ModalService } from 'src/app/shared';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'pos-training-area-model',
  templateUrl: './training-area-model.component.html',
  styleUrls: ['./training-area-model.component.scss']
})
export class TrainingAreaModelComponent extends ModalFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.form
  }
  form: NgForm
  @Input('areaId') areaId: number;
  @Input('fromListPage') fromListPage: boolean = false;
  initialize: boolean = false;
  constructor(areaModalRef: BsModalRef, modalService: ModalService, formUtilityService: FormUtilityService) {
    super(areaModalRef, modalService, formUtilityService);
    this.initialize = false;
  }

  ngOnInit() {
    this.initialize = true;
  }

  onClose($event: any) {
    this.hide($event);
  }

  onFormChange($event: NgForm) {
    this.form = $event;
    this.setInitialValue();
  }
}
