import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginatorModule } from 'primeng/paginator';
import { ButtonModule } from 'primeng/button';
import { TableModule as PrimeNgDataTableModule} from 'primeng/table';
import { OverlayPanelModule as PrimeNgOverlayPanel } from 'primeng/overlaypanel';

import { TableComponent } from './table.component';

import { TableConfig } from './table-config';
import { TableConfigService } from './service/table-config.service';
import { OverlayPanelComponent } from './overlay-panel/overlay-panel.component';
import { TarkCurrencyPipe } from './pipes/tark-currency.pipe';
import { TarkDatePipe } from './pipes/tark-date.pipe';
import { TarkDateTimePipe } from './pipes/tark-date-time.pipe';
import { TarkTimePipe } from './pipes/tark-time.pipe';
import { TarkNumberPipe } from './pipes/tark-number.pipe';
@NgModule({
  imports: [
    CommonModule,
    PaginatorModule,
    PrimeNgDataTableModule,
    ButtonModule,
    PrimeNgOverlayPanel
  ],
  declarations: [TableComponent, OverlayPanelComponent, TarkCurrencyPipe, TarkDatePipe, TarkDateTimePipe, TarkTimePipe, TarkNumberPipe],
  exports: [TableComponent, OverlayPanelComponent, TarkCurrencyPipe, TarkDatePipe, TarkDateTimePipe, TarkTimePipe, TarkNumberPipe]
})
export class TableModule {

  static forRoot(config: TableConfig): ModuleWithProviders<TableModule> {
    return {
      ngModule: TableModule,
      providers: [
        {
          provide: TableConfigService,
          useValue: config
        }
      ]
    }
  }
 }
