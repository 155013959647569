<div *ngIf="isPopup">
    <button class="close" (click)="onCancel()">&times;</button>
    <h4 class="modal-content__heading">Preview Instructions</h4>
</div>
<div [ngClass]="{'p-pix-10 mb-pix-10 overflow-hidden': isPopup}">
    <div class="instructions-component width-full" #instructionTerminal>
        <div class="instruction-block width-full border-lrt display-flex flex-column col-12 ps-pix-15 pe-pix-15">
            <div class="row p-pix-2 vertical-center-align">
                <div class="col-lg-1 col-md-1 col-2 p-0">
                    <div class="pull-left">
                        <span
                            class="badge volume-circle font-18 instruction-quantity"
                            [ngStyle]="{'background-color': qtyBgColor,'color': qtyFontColor }">
                                {{ instruction.Quantity | productQty}}
                                </span>
                    </div>
                </div>
                <div class="col-lg-8 col-md-7 col-6 p-0 ps-pix-5 pe-pix-5 text-center headerContainer vertical-center-align"
                    style="height:29px;" id="{{instruction.OrderProductId}}">
                    <div class="theme-separator textContainer">
                        <b>{{instruction.SalesProductName}}</b>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-4 p-0 vertical-center-align pull-right">
                    <div class="col-md-5 col-3 p-0 pb-pix-1">
                        <span class="badge">{{instruction.Size}}</span>
                    </div>
                    <div class="col-md-4 col-3 p-0">
                        <span class="sky-blue" *ngIf="instruction.OrderId>0">
                            <b>{{instruction.SurrogateOrderId}}</b>
                        </span>
                    </div>
                    <div class="col-md-3 col-3 p-0">
                        <span *ngIf="instruction.OrderProductId">
                            <a class="close ps-pix-5" (click)="removeInstruction(instruction.OrderProductId)">×</a>
                        </span>
                    </div>
                </div>
            </div>
            <div class="row instruction-block__instruction-area" *ngIf="instruction.InstructionAreas?.length>0"
                [ngStyle]="{'height': instruction.InstructionAreas.height}">
                <div class="col-12 ps-0 pe-0 full-height">
                    <div class="col-12" id="divInstructionArea"
                        [ngStyle]="{'height': 'calc(100%/'+instruction.InstructionAreas.totalIngredients+'*'+area.SalesProductIngredients?.length+')'}"
                        *ngFor="let area of instruction.InstructionAreas; index as i ">
                        <div *ngIf="i>0" class="row border-lr" style="height: 5px; background-color: #eee;">
                        </div>
                        <div class="row border-lrb bg-white" id="divSalesProductIngredients"
                            *ngFor="let ingredient of area.SalesProductIngredients; index as j"
                            [ngStyle]="{'height': area.SalesProductIngredients.length > 1?
                            'calc(calc(100%/' + area.SalesProductIngredients.length + ') - '+(5/area.SalesProductIngredients.length + 'px')+')' : 
                            'calc(calc(100%/' + area.SalesProductIngredients.length + ')  - 5px )' }">
                            <div class="col-md-2 col-2 full-height instruction-block__ingredient--imagedivcenter ps-0 pe-0">
                                <img *ngIf="ingredient.Image" class="instruction-block__ingredient--imagesize"
                                    src="{{imagePath}}/instructions/{{ingredient.Image}}" alt=""/>

                            </div>
                            <div class="instruction-block__ingredient ps-pix-5 col-md-10 col-10 instruction-block__ingredient--center"
                            [ngClass]="area.SalesProductIngredients.length == 1 ?'pb-pix-4':'pt-pix-1 pb-pix-1'"
                                id="i_{{instruction.OrderId}}_{{area.AreaId}}_{{j}}">
                                <!--  -->
                                <div id="insTtextcontainer" class="instruction-block__ingredient__text">
                                    {{ingredient.Text}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="instruction.InstructionAreas?.length>0 && instruction.ExceptionalinstructionArea.SalesProductIngredients?.length>0"
                class="row border-lr instruction-block__separator-row">
                <div class="instruction-block__separator"></div>
            </div>
            <div class="row border-1 bg-danger instruction-block__exec-instruction-area" id="divExecInstructionArea"
                *ngIf="instruction.ExceptionalinstructionArea.SalesProductIngredients?.length>0"
                [ngStyle]="{'height': instruction.ExceptionalinstructionArea.height}">
                <div class="row ms-pix-1" id="divExecInstructionAreaSalesIngredients"
                    *ngFor="let ingredient of instruction.ExceptionalinstructionArea.SalesProductIngredients; index as k"
                    [ngClass]="k>0?'border-top':''"
                    [ngStyle]="{'height': 'calc(100%/'+instruction.ExceptionalinstructionArea.totalExecIngredients+')'}">
                    <div class="col-2 full-height instruction-block__ingredient--imagedivcenter ps-0 pe-0">
                        <img *ngIf="ingredient.Image" class="instruction-block__ingredient--imagesize"
                            src="{{imagePath}}/instructions/{{ingredient.Image}}" alt=""/>
                    </div>
                    <div class="instruction-block__ingredient ps-pix-5 pt-pix-1 pb-pix-1 col-md-10 col-10 instruction-block__ingredient--center"
                        id="e_{{instruction.OrderId}}_{{k}}">
                        <div id="execTextContainer" class="instruction-block__ingredient__text">{{ingredient.Text}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
