<pos-edit-wrapper heading="{{selectedDeliveryType.description}}" (close)="onClose(false)">
    <form #lightActivityEditForm="ngForm" autocomplete="off" (submit)='saveActivity(lightActivityEditForm.valid)'>
        <div class="col-12 pos-modal-body screen-scroll">
            <pos-row>
                <pos-column>
                    <pos-form-field label="Lighting Controller"
                        [validationMessages]="{required: 'Please select lighting controller.'}"
                        inputStyle="col-md-10 col-xs-12">
                        <ng-select posInput [items]="lightingControllers" bindLabel="Name" bindValue="Id"
                            placeholder="Select lighting controller" [(ngModel)]="lightingControllerId" [hideSelected]="true"
                            name="lightingController" (change)="lightingControllerSelected()" [required]="true">
                        </ng-select>
                    </pos-form-field>
                </pos-column>

                <pos-column>
                    <pos-form-field label="Action">
                        <div class="btn-group">
                            <label class="btn btn-radio"
                                [ngClass]="indicationType == 'On' ? 'active btn-primary font-nobold' : 'btn-secondary'">
                                <input [(ngModel)]="indicationType" type="radio" value="On" name="On"
                                    (change)="actionChanged()"> On
                            </label>
                            <label class="btn btn-radio"
                                [ngClass]="indicationType == 'Off' ? 'active btn-primary font-nobold' : 'btn-secondary'">
                                <input [(ngModel)]="indicationType" type="radio" value="Off" name="Off"
                                    (change)="actionChanged()"> Off
                            </label>
                            <label class="btn btn-radio"
                                [ngClass]="indicationType == 'Flash' ? 'active btn-primary font-nobold' : 'btn-secondary'">
                                <input [(ngModel)]="indicationType" type="radio" value="Flash" name="Flash"
                                    (change)="actionChanged()"> Flash
                            </label>
                        </div>
                    </pos-form-field>
                </pos-column>

                <pos-column>
                    <pos-form-field label="Duration (seconds)" inputStyle="col-md-10 col-xs-12 col-120">
                        <input class="form-control" posInput name="duration" [(ngModel)]="duration" type="number"
                            min="0" posOnlyNumbers />
                    </pos-form-field>
                </pos-column>

                <pos-column *ngIf="lightingControllerId">
                    <pos-form-field inputStyle="col-md-12 col-xs-12">
                        <div class="col-12 p-0" *ngIf="panelIds.length > 0">

                            <p-table [value]="lightsConfiguration">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="width: 50%">Light</th>
                                        <th style="width: 40%">Color</th>
                                        <th style="width: 30px"></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-i="rowIndex">
                                    <tr style="height: 37px;">
                                        <td>
                                            <pos-column>
                                                <pos-form-field [appendPaddingTop]="false"
                                                    [validationMessages]="{required: 'Please select light.'}"
                                                    inputStyle="col-md-11 col-xs-12">
                                                    <ng-select posInput [(items)]="panelIds" appendTo="body"
                                                        name="lightId{{i}}"
                                                        placeholder="Select light" bindLabel="Name" bindValue="PanelId"
                                                        [(ngModel)]="rowData.PanelId" [hideSelected]="true"
                                                        (change)="createLightsConfigRow(i)"
                                                        [required]="!(i+1== lightsConfiguration.length)">
                                                    </ng-select>
                                                </pos-form-field>
                                            </pos-column>

                                        </td>
                                        <td>
                                            <pos-column>
                                                <pos-form-field [validationMessages]="{required: 'Please enter color.'}"
                                                    inputStyle="col-md-10 col-xs-12" [appendPaddingTop]="false">
                                                    <pos-color-picker posInput #colorId="ngModel"
                                                        [(ngModel)]="rowData.Color"
                                                        [required]="!(i+1== lightsConfiguration.length)"
                                                        name="colorId{{i}}"></pos-color-picker>
                                                </pos-form-field>
                                            </pos-column>


                                        </td>
                                        <td class="text-center">
                                            <a (click)="deleteLightConfiguration(rowData.PanelId)" title="Delete"
                                                *ngIf="!(i+1== lightsConfiguration.length)">
                                                <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
                                            </a>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </pos-form-field>
                </pos-column>

                <pos-column>
                    <pos-repeat-action-configuration
                        [(eventSubscriptionActionAttributes)]="eventSubscriptionActionAttributes"
                        [isFormSubmitted]="lightActivityEditForm.submitted" #RepeatActionConfiguration
                        [isRecoverableEvent]="isRecoverableEvent">
                    </pos-repeat-action-configuration>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save</button>
            <button type="button" (click)="onClose(false)" class="btn-secondary btn-lg btn btn-cancel-modal pos-button"
                value="Cancel">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
