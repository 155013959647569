<pos-edit-wrapper (close)="cancelEditing()" [isPopup]="!fromListPage" [headingIcon]="icons.infoManageTablesSolarPanel"
  heading="{{account.Id ? 'Edit Account' : 'Add Account'}}">
    <form #accountForm="ngForm" class="edit-form" (ngSubmit)="submit(accountForm.valid && !ZipCode?.invalid)" autocomplete="off">
        <div class="col-12 container-fluid" [ngClass]="{'border-top': fromListPage}">
            <ul class="nav nav-tabs inner-tab pos-nav-tabs">
                <li [ngClass]="{'active': selectedTab == tabList.Account }">
                    <a (click)="selectedTab = tabList.Account" class="blue"
                        [ngClass]="{'required':!formGroups.Account.$valid}">
                        <pos-icon class="theme-separator pe-pix-5" [icon]="icons.tablesSolarPanel"></pos-icon>
                        Account
                    </a>
                </li>
                <li [ngClass]="{'active': selectedTab == tabList.Orders }">
                    <a (click)="selectedTab = tabList.Orders" class="blue">
                        <pos-icon class="theme-separator pe-pix-5" [icon]="icons.accountOrdersShoppingCart">
                        </pos-icon>
                        Orders
                    </a>
                </li>
            </ul>
            <context-provider provide="props">
            <div class="overflow-auto-tabs screen-scroll row mx-0" [ngStyle]="{'height':getHeight(true)}">
                <div *ngIf="selectedTab == tabList.Account" class="col-12 pos-modal-body" [ngStyle]="{'height':getHeight(false)}">
                    <pos-row colSm="6" colXs="12">
                        <pos-column>
                            <pos-form-field label="Name" [validationMessages]="{required: 'Please enter name.'}">
                                <input posInput class="form-control" id="AccountName" name="AccountName"
                                    [(ngModel)]="account.Name" #Account="ngModel" [required]="true" type="text"
                                    [posValidationGroup]="formGroups.Account" />
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Address">
                                <textarea posInput class="form-control" name="address" rows="3"
                                        [(ngModel)]="account.Address"></textarea>
                            </pos-form-field>
                        </pos-column>
                    </pos-row>

                    <pos-row colSm="6" colXs="12">
                        <pos-column>
                            <pos-form-field label="City">
                                <input posInput class="form-control" name="city" [(ngModel)]="account.City"
                                    type="text" />
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="State">
                                <input posInput class="form-control" name="state" [(ngModel)]="account.State"
                                    type="text" />
                            </pos-form-field>
                        </pos-column>
                    </pos-row>

                    <pos-row colSm="6" colXs="12">
                        <pos-column>
                            <pos-form-field label="Phone" [validationMessages]="{mask: 'Please enter valid phone number'}">
                                <input posInput class="form-control" data-cke-saved-name="phone" name="phone"
                                    [(ngModel)]="account.Phone"
                                    [mask]="settingParam?.PhoneNumberMask.trim() ? settingParam.PhoneNumberMask : defaultMask"
                                    type="text" #phoneMask="ngModel" />
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="ZIP Code" [validationMessages]="{pattern: 'Please enter valid code.'}">
                                <input posInput type="text" class="form-control" [(ngModel)]="account.PostalCode"
                                    id="ZipCode" name="ZipCode" #ZipCode="ngModel"
                                    pattern="^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] *\d[A-Z]\d)$" />
                            </pos-form-field>
                        </pos-column>
                    </pos-row>

                    <pos-row colSm="6" colXs="12">
                        <pos-column>
                            <pos-form-field label="Notes">
                                <textarea posInput class="form-control h-100" name="notes"
                                    [(ngModel)]="account.Notes"></textarea>
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-row colXs="12">
                                <pos-column>
                                    <pos-form-field label="Account Category">
                                        <ng-select posInput [items]="accountCategories" bindLabel="Category" bindValue="Id"
                                            placeholder="Select account category"
                                            [(ngModel)]="account.AccountCategoryID" name="accountCategory">
                                        </ng-select>
                                    </pos-form-field>
                                </pos-column>

                                <pos-column>
                                    <pos-form-field label="Account Type" [validationMessages]="{required: 'Please select account type.'}">
                                        <ng-select posInput [required]="true" [posValidationGroup]="formGroups.Account" [items]="accountTypes"
                                            bindLabel="AccountTerm" bindValue="Id" placeholder="Select account type" appendTo="body"
                                            [disabled]="accountTypeId > 0" [(ngModel)]="account.AccountTypeId"
                                            name="accountType" (change)="checkIsTypeAccount(account.AccountTypeId)">
                                        </ng-select>
                                    </pos-form-field>
                                </pos-column>
                            </pos-row>
                        </pos-column>
                    </pos-row>

                    <pos-row colSm="6" colXs="12">
                        <pos-column sm="6" xs="12" *ngIf="isAccountType">
                            <pos-form-field label="Loyalty Card Number" [validationMessages]="{pattern: 'Loyalty card number should not contains special characters or space.'}"
                                inputStyle="col-12 row mx-0">
                                <div class="col-lg-4 col-md-8 col-10 p-0">
                                    <input posInput type="text" class="form-control"
                                        [(ngModel)]="account.LoyaltyCardNumber" id="LoyaltyCardNumber"
                                        name="LoyaltyCardNumber" pattern="^[a-zA-Z0-9\.]*$" type="text"
                                        (focus)="accountSwipeBroadCast(false)"
                                        (blur)="accountSwipeBroadCast(true)" #LoyaltyCardNumber="ngModel" />
                                </div>
                                <div class="col-2 pt-pix-2">
                                    <button type="button" class="btn btn-sm bg-theme-separator white normal pos-button" *ngIf="settingParam.IsEnableLoyaltyCardProgram"
                                    (click)="swipeCard()">
                                        <pos-icon [icon]="icons.loyaltyCreditCard"></pos-icon>
                                    </button>
                                </div>
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field>
                                <p-checkbox posInput name="chkActive" #chkActive="ngModel"
                                    [(ngModel)]="account.IsActive" binary="true" label="Active">
                                </p-checkbox>
                            </pos-form-field>
                        </pos-column>
                    </pos-row>

                    <div class="col-12 p-0 section-border pb-pix-5" *ngIf="(account.AccountAttributes || account.Id === 0) && isAccountType">
                        <pos-account-attributes [accountAttributes]="account.AccountAttributes"
                            (accountAttributeType)="onAttributeTypeChange($event)">
                        </pos-account-attributes>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div *ngIf="selectedTab == tabList.Orders">
                    <div class="col-12 overflow-auto-tabs m-0 w-100 pos-tab-body"
                        [ngStyle]="{'height':getHeight(false)}">
                        <pos-account-orders [ordersData]="accountOrderDetails">
                        </pos-account-orders>
                    </div>
                </div>
            </div>
            </context-provider>
        </div>
        <div class="clearfix"></div>
        <div class="col-12 action-button-container" [ngClass]="{'page-footer': fromListPage, 'popup-footer': !fromListPage}">
            <button class="btn-lg btn-primary btn btn-save pos-button" type="submit" value="Save">Save</button>
            <button class="btn-primary btn-lg btn btn-save pos-button" *ngIf="account.Id"
                (click)="onPrintAccountDetails()" type="button" value="Print">Print</button>
            <button class="btn-secondary btn-lg btn pos-button" [ngClass]="{'btn-cancel': fromListPage, 'btn-cancel-modal': !fromListPage}" (click)="cancelEditing()" type="button"
                value="Cancel">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
