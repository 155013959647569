<form #multipleProductForm="ngForm" class="edit-form" (ngSubmit)="addGroup(multipleProductForm.valid)"
    autocomplete="off">
    <pos-row colXs="12" styleClass="p-0">
        <pos-column xs="12">
            <pos-form-field label="Group" inputStyle="col-md-8 col-12"
                [validationMessages]="{required: 'Please select group.'}">
                <pos-row>
                    <pos-column md="8" xs="12" styleClass="pl-0">
                        <ng-select name="Group" id="Group" [clearable]="false"
                            [(ngModel)]="multiProductNextScreen.MultiProductGroupId" [items]="groups" bindLabel="Name"
                            placeholder="Select group" bindValue="Id" posInput [clearable]="true" [required]="true">
                        </ng-select>
                    </pos-column>
                    <pos-column md="4" xs="12" class="pt-pix-8 ps-0"
                        permissions='[{"Name":"{{permission.multipleProductGroups}}", "Level":"{{permission.editLevel}}"}]'>
                        <span (click)="addNewGroup()">
                            <pos-action-icon [icon]="icons.plusWhite" iconText="Add">
                            </pos-action-icon>
                        </span>
                        <span (click)="editGroup(multiProductNextScreen.MultiProductGroupId)">
                            <pos-action-icon class="text-nowrap ms-pix-5" [icon]="icons.editWhiteWithText" iconText="Edit">
                            </pos-action-icon>
                        </span>
                    </pos-column>
                </pos-row>
            </pos-form-field>
        </pos-column>
        <pos-column md="6" xs="12">
            <pos-form-field label="Next Screen" inputStyle="col-12"
                [validationMessages]="{required: 'Please select screen.'}">
                <ng-select name="multiProductNextScreen" id="multiProductNextScreen" [clearable]="false"
                    [(ngModel)]="multiProductNextScreen.MultiProductNextScreenId" [items]="screenList" bindLabel="Title"
                    placeholder="Select screen" bindValue="Id" posInput [clearable]="true" [required]="true">
                </ng-select>
            </pos-form-field>
        </pos-column>
        <pos-column md="3" xs="12" class="inline-form-operation-button">
            <button class="btn-primary btn-md btn btn-save pos-button" type="submit" value="Save">Add</button>
            <button class="btn-secondary btn-md btn pos-button normal" type="reset" value="Clear">Cancel</button>
        </pos-column>
    </pos-row>

    <div class="col-12">
        <ng-table #gridComponent [columns]="multiProductNextScreenColumns" [data]="multiProductNextScreens"
            [paginator]="false">
        </ng-table>
    </div>
</form>
