import { RuntimeConstants } from '../constants/runtime-constants';
import { Observable, of } from 'rxjs';
import { HttpParams, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeductInventoryProduct, OrderDietaryRestriction, OrderInvoice, OrderInvoiceOrderProduct, OrderNavigationButton, OrderSubaccount, TerminalDetails } from 'src/app/orders';
import { OrderSummary } from 'src/app/order-maintenance/interface/order-summary';
import { PrintOrder } from 'src/app/order-maintenance/interface/print-order';
import { VoidOrder } from 'src/app/order-maintenance/interface/void-order';
import { MergeOrder } from 'src/app/order-maintenance/interface/merge-order';
import { RefundOrder } from 'src/app/order-maintenance/interface/refund-item';
import { OrderCancel } from 'src/app/manage-console/manage-console/interface/order-cancel';
import { ServeMethod } from '../interface/serve-method';
import { InvoiceAreaButtonText } from '../interface/InvoiceAreaButtonText';
import { PaymentType } from '../interface/payment-type';
import { VoidTransaction } from 'src/app/orders/interface/void-transaction';
@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private httpService: HttpClient) { }

  // getServeScreenData(): Observable<any> {
  //   return this.httpService.get(
  //     `${RuntimeConstants.API_PATH}servescreen/`
  //   );
  // }
  newInvoiceAreaButtonText(): InvoiceAreaButtonText {
    return {
      SendOrder: '',
      ReviewOrder: '',
      CancelOrder: '',
      SettleOrder: '',
    };
  }

  newOrder() {
    return {
      Id: 0,
      OrderTypeId: null,
      Name: '',
      AccountId: null,
      CustomerAccountId: null,
      LoyaltyAccountId: null,
      PagerNumber: null,
      IsTrainingOrder: false,
      Product: this.newOrderProduct(),
      Products: [],
      Discount: { Type: '', Value: 0 },
      TerminalId: null,
      OrderAttributes: { IsTaxable: true },
      RevenueCenterId: null,
      OrderSubaccounts: []
    }
  }

  newOrderProduct() {
    return {
      SubAccountOrdinal: 0,
      ScreenChoiceId: 0,
      Qty: 0,
      Comment: '',
      Components: [],
      SizeId: null,
      SizeButtonId: null,
      ItemId: null,
      DefaultQty: null,
      UnitName: '',
      ButtonText: '',
      AdditionalInventories: []
    };
  }

  newScreenButtons() {
    return {
      Screen: {
        Id: 0,
        Name: '',
        StartForm: false,
        SizeForm: false,
        MinSelections: null,
        MaxSelections: null,
        DefaultNextScreenId: null,
        PrintOnOrderReceipt: false,
        ColorName: '',
        BackgroundImage: '',
        ButtonSize: '',
        Layout: '',
        ColumnCount: null,
        ScreenBehaviors: [],
        DateAdded: null,
        DateEdited: null
      },
      Buttons: [],
      UiConfiguration: {
        width: '',
        height: '',
        imageSize: ''
      }
    }
  }

  newEmbeddedScreenButton(data): OrderNavigationButton {
    const button = this.newButton();
    button.ButtonText = "<b class='font-14'><i class='fa fa-link'></i> Embedded Screen</b><br />" + data.EmbeddedScreenName;
    button.ScreenChoiceId = data.ScreenChoiceId;
    button.Color = 'white';
    button.BackgroundColor = 'white';
    button.Ordinal = data.Ordinal;
    button.EmbeddedScreenId = data.EmbeddedScreenId;
    button.UiConfiguration = data.UiConfiguration;
    button.EmbeddedScreenChoiceId = data.EmbeddedScreenChoiceId;
    return button;
  }

  newButton(): OrderNavigationButton {
    return {
      Id: 0,
      ButtonText: '',
      SalesProductId: null,
      ScreenChoiceId: null,
      NextScreenId: null,
      StackScreenId: null,
      RevenueCenterId: null,
      Toggle: false,
      ManagerOnly: false,
      SpecialFunction: '',
      ScheduleStartDate: null,
      ScheduleEndDate: null,
      ScheduleStartTime: null,
      ScheduleEndTime: null,
      Image: '',
      TextColor: '',
      Color: '',
      OriginalColor: '',
      ButtonType: '',
      DateRange: '',
      InternalText: '',
      GroupName: '',
      ButtonGroupMin: null,
      ButtonGroupMax: null,
      BorderColor: '',
      BackgroundColor: '',
      IsDefaultChecked: null,
      Ordinal: null,
      IsTaxable: null,
      IsActive: null,
      SalesTaxType: '',
      IsInStock: true,
      ProductName: '',
      IsAllowOutOfStockOrder: false,
      Information: '',
      Price: null,
      StartForm: null,
      ScreenId: null,
      PrintToKitchen: null,
      Behaviors: [],
      IsDefault: false,
      IsSelected: false,
      IsVisible: true,
      IsDisabled: false,
      DefaultQty: 1,
      UnitName: '',
      SalesProductTags: [],
      SearchableTags: "",
      DietaryWarnings: [],
      IsFlash: false,
      IsHide: false,
      NoTags: false,
      SalesProductSizes: [],
      DefaultSizeId: null,
      IsAppliedFilter: false,
      UiConfiguration: null
    }
  }

  newPrintOrder() {
    return {
      OrderId: 0,
      Parameter: null,
      TransRefNum: '',
      BankAuthCode: '',
      CardNumber: '',
      CardSchemeName: '',
      IsCreditPrint: false,
      TerminalId: 0,
      PaidAmount: 0,
      SubAccountOrdinals: '',
      SurrogateOrderId: 0,
      PrintCount: 0,
      SplitAmount: 0,
      ReceiptPrinterId: 0,
      PrintSeatDetails: []
    } as PrintOrder;
  }

  newVoidOrder() {
    return {
      OrderId: 0,
      UserId: 0,
      DrawerId: 0,
      CreditCardTerminal: null,
      TerminalId: 0,
      TerminalName: '',
      IsUnserved: false,
      IsOnlyRefund: false
    } as VoidOrder;
  }

  getNewOrderInvoiceDetails() {
    return {
      Id: { Value: 0 },
      Account: {
        Id: { Value: 0 },
        Name: "",
        Type: ''
      },
      CustomerAccount: {
        Id: { Value: 0 },
        Name: "",
        Type: ''
      },
      PaidAmount: 0,
      DiscountPct: 0,
      OrderTypeId: 0,
      ScheduleDate: null,
      GrandTotal: 0,
      SubTotal: 0,
      TaxTotal: 0,
      ActualTotal: 0,
      DueAmount: 0,
      ActualTax: 0,
      Total: 0,
      SurrogateOrderId: 0,
      MarkupAmount: 0,
      DiscountAmount: 0,
      DeliveryFee: null,
      MarkupPct: null,
      PagerNumber: null,
      Name: '',
      Comments: [],
      OrderItems: [],
      OrderTransactions: [],
      DietaryRestrictions: [],
      AuthorizeTransactions: [],
      OrderDateTime: new Date(),
      OrderAttributes: {
        IsTaxable: true,
        IsCallIn: false,
        CallInOrderTime: '',
        Name: '',
        PagerNumber: 0,
        PhoneNumber: '',
        OnlineOrderId: '',
        PickupTime: null,
        ArrivalTime: null
      },
      RevenueCenterId: 0,
      OrderSubaccounts: [],
      IsVerified: false,
    } as OrderInvoice;
  }

  setOrderSubaccount(orderId: number,subAccountName:string,ordinal:number):OrderSubaccount{
    return {
      Id: { Value: 0 },
      OrderId: orderId,
      SubaccountName: subAccountName,
      SubaccountOrdinal: ordinal
    }
  }


  getNewOrderInvoiceProduct() {
    return {
      Id: null,
      SalesProductId: null,
      Price: 0,
      TaxAmount: 0,
      Qty: 0,
      SizeId: null,
      StatusId: 0,
      SubAccountOrdinal: 0,
      IsPrintOnOrderReceipt: null,
      IsSentToKitchen: null,
      IsDirty: null,
      MakeTableText: '',
      Components: [],
      Color: ''
    } as OrderInvoiceOrderProduct;
  }

  // newRefundItem() {
  //   return {
  //     OrderId: 0,
  //     RefundOrderId: 0,
  //     Qty: 1,
  //     ItemId: 0,
  //     RegisterId: 0,
  //     UserId: 0,
  //     TerminalId: 0
  //   } as RefundItem;
  // }

  getNewOrderProductComponent() {
    return {
      Id: 0,
      SalesProductId: 0,
      ButtonText: "",
      Price: 0,
      ReceiptText: "",
      MakeTableText: "",
      ButtonId: 0
    }
  }

  getUnservedOrdersForPrintCheck(drawerId, userId, status, orderId): Observable<any> {
    let params = new HttpParams();
    params = params.append('drawerId', drawerId);
    params = params.append('userId', userId);
    params = params.append('status', status);
    params = params.append('orderId', orderId);
    return this.httpService.get(`${RuntimeConstants.API_PATH}Orders/printcheck/${drawerId}/${userId}/${status}/${orderId}`, { params: params });
  }

  getScreenData(screenId): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}Orders/LoadScreens/` + screenId
    );
  }

  setNextScreen(screenModel) {
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}Orders/SetNextScreen`,
      screenModel
    );
  };

  getServeMethods(terminalId: number, parameterName: string) {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}Orders/serve-methods/` + terminalId + '/' + parameterName
    );
  }

  updateAccountId(updatedAccount) {
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}Orders/set/account`,
      updatedAccount
    );
  }

  applyPromotion(orderId) {
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}promotion/apply-promotion/${orderId}`, null
    );
  }

  unlinkOrderMember(orderId) {
    return this.httpService.delete(
      `${RuntimeConstants.API_PATH}Orders/${orderId}/unlink/member`);
  }

  notificationButton = function (notificationModel) {
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}Orders/NotificationButton`,
      notificationModel
    );
  }

  setOrderRevenueCenterId(orderId: number, revenueCenterId: number, orderModel) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}Orders/${orderId}/set/revenue-center/${revenueCenterId}`, orderModel);
  }

  getButtonFunctionParameters = function (buttonId) {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}buttons/parameter` + (buttonId ? ('/' + buttonId) : ''));
  }

  printCheck(printOrderModel): Observable<any> {
    return this.httpService.post(`${RuntimeConstants.API_PATH}Orders/${printOrderModel.OrderId}/print-check/`, printOrderModel);
  }

  printCheckWithQR(printCheckModel): Observable<any> {
    return this.httpService.post(`${RuntimeConstants.API_PATH}Orders/${printCheckModel.OrderId}/qr-print`, printCheckModel);
  }

  duplicateButtonOnScreen(screenChoiceId: number): Observable<any> {
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}Orders/button/duplicate/`+screenChoiceId,  null);
  }

  findButtonOnScreen(buttonId: number): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}Orders/FindButtonOnScreen/` + buttonId
    );
  }

  removeButtonFromScreen(screenId: number, screenChoiceId: number): Observable<any> {
    return this.httpService.delete(
      `${RuntimeConstants.API_PATH}Orders/RemoveButton/screen/` + screenId + `/screenChoice/` + screenChoiceId
    );
  }

  removeProductFromButton(buttonId: number): Observable<any> {
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}Orders/RemoveProduct/` + buttonId, {}
    );
  }

  getProductList(): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}Orders/GetProductList`
    );
  }

  setProduct(productDetails: any): Observable<any> {
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}Orders/SetProduct`,
      productDetails
    );
  }

  addSpecialComment(saveSpecialCommentmodel) {
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}Orders/AddSpecialComment`,
      saveSpecialCommentmodel
    );
  }

  getSalesProductsInfo(): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}Orders/GetSalesProductsInfo`
    );
  }

  printNutritionFacts(nutritionFactPrint) {
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}Orders/print-nutrition-fact`,
      nutritionFactPrint
    );
  }

  printSalesRecipeNutritionFacts(nutritionFactPrint) {
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}Orders/print-nutrition-fact/recipe`,
      nutritionFactPrint
    );
  }

  printOrderNutritionFacts(orderId: number, orderProductId: number, nutritionFactPrint) {
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}Orders/${orderId}/${orderProductId}/print-nutrition-fact`,
      nutritionFactPrint
    );
  }

  getIngredientsInfo(productId: number) {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}Orders/GetIngredientsInfo/` + productId
    );
  };

  getOrderNavigationDetails() {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}Orders/navigations`
    );
  }

  getSalesProductDefaults() {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}Orders/product/defaults`
    );
  }

  logoutTheSession(logoutDetails: any): Observable<any> {
    return this.httpService.post(`${RuntimeConstants.API_PATH}Orders/LogoutTheSession`, logoutDetails);
  }

  getUnservedOrdersData(drawerId: number, userId: number): Observable<any> {
    return this.httpService.get(`${RuntimeConstants.API_PATH}Orders/unserved/drawer/${drawerId}/user/${userId}`);
  }

  getUnservedOrdersDataForSettle(drawerId: number, userId: number): Observable<any> {
    return this.httpService.get(`${RuntimeConstants.API_PATH}Orders/unserved-settle/drawer/${drawerId}/user/${userId}`);
  }

  getUnservedOrdersForMakeTable(terminalId: number): Observable<any> {
    return this.httpService.get(`${RuntimeConstants.API_PATH}make-table/unserved/terminal/` + terminalId);
  }

  getMappedTerminalsByType(terminalId: number, terminalType: string): Observable<Array<TerminalDetails>> {
    let params = new HttpParams();
    params = params.append('terminalId', terminalId.toString());
    params = params.append('terminalType', terminalType);
    return this.httpService.get<Array<TerminalDetails>>(`${RuntimeConstants.API_PATH}orders/mapped-terminals/`, { params: params });
  }

  updateButtonColor(screenId: number, color: string): Observable<any> {
    const colorModel = { ScreenId: screenId.toString(),  Color: color};
    return this.httpService.post(`${RuntimeConstants.API_PATH}Orders/button/update-color`, colorModel);
  }

  checkForMostCommonNextScreen(screenId: number): Observable<any> {
    return this.httpService.get(`${RuntimeConstants.API_PATH}Orders/CheckForMostCommonNextScreen/` + screenId);
  }

  getExistingButtons(): Observable<any> {
    return this.httpService.get(`${RuntimeConstants.API_PATH}Orders/GetListofExistingButtons`);
  }

  saveButtonDetailsForScreen(buttons: Array<any>): Observable<any> {
    return this.httpService.post(`${RuntimeConstants.API_PATH}Orders/SaveButtonDetailsForScreen`, buttons);
  }

  getButtonsFromOtherScreen(screenModel: any): Observable<any> {
    return this.httpService.post(`${RuntimeConstants.API_PATH}Orders/GetButtonsFromOtherScreen`, screenModel);
  }

  checkScreenReferences(screenId: number): Observable<any> {
    return this.httpService.get(`${RuntimeConstants.API_PATH}Orders/ScreenReferences/` + screenId);
  }

  deleteScreen(screenId: number): Observable<any> {
    return this.httpService.delete(`${RuntimeConstants.API_PATH}Orders/DeleteScreen/` + screenId);
  }

  saveUserPreference(model) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}Manages/SaveUserPreference`, model);
  }

  deleteOrderProduct(orderId: number, orderProductId: number): Observable<any> {
    return this.httpService.delete(`${RuntimeConstants.API_PATH}Orders/${orderId}/products/${orderProductId}`);
  }

  deleteOrderProductGroup(orderId: number, orderProductGroupId: number): Observable<any> {
    return this.httpService.delete(`${RuntimeConstants.API_PATH}Orders/${orderId}/product-group/${orderProductGroupId}`);
  }

  deleteRemoveTax(orderId: number, orderProductId: number) {
    return this.httpService.delete(`${RuntimeConstants.API_PATH}Orders/${orderId}/remove-tax`);
  }
  

  deleteOrderMarkupPct(orderId: number): Observable<any> {
    return this.httpService.delete(`${RuntimeConstants.API_PATH}Orders/${orderId}/remove/markup-percent`);
  }

  deleteDeliveryFee(orderId: number): Observable<any> {
    return this.httpService.delete(`${RuntimeConstants.API_PATH}Orders/${orderId}/remove/delivery-fee`);
  }


  deleteOrderDiscountPct(orderId: number): Observable<any> {
    return this.httpService.delete(`${RuntimeConstants.API_PATH}Orders/${orderId}/remove/discount-percent`);
  }

  deleteOrderProductComponent(orderId: number, orderProductId: number, orderProductComponentId: number) {
    return this.httpService.delete(`${RuntimeConstants.API_PATH}Orders/${orderId}/products/${orderProductId}/components/${orderProductComponentId}`);
  }

  deleteOrderProductSize(orderId: number, orderProductId: number, componentId: number, sizeId: number) {
    return this.httpService.delete(`${RuntimeConstants.API_PATH}Orders/${orderId}/products/${orderProductId}/component/${componentId}/size/${sizeId}`);
  }

  deleteOrder(orderId: number) {
    return this.httpService.delete(`${RuntimeConstants.API_PATH}Orders/${orderId}`);
  }

  makePayment(paymentModel) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}Orders/SavePaymentByCreditCard`, paymentModel);
  }

  updateSubaccountOrdinal(orderId, orderItemId, subaccountOrdinal) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}Orders/${orderId}/update/${orderItemId}/subaccount-ordinal/${subaccountOrdinal}`, null);
  }

  moveItemToOtherSeat(orderId, orderItemId, subaccountOrdinal, qty) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}Orders/${orderId}/move/${orderItemId}/subaccount-ordinal/${subaccountOrdinal}/qty/${qty}`, null);
  }

  moveProductGroupToOtherSeat(orderId: number, groupId: number, subaccountOrdinal: number, qty: number) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}Orders/${orderId}/move-product-group/${groupId}/subaccount-ordinal/${subaccountOrdinal}/qty/${qty}`, null);
  }

  paymentPriceOfOrder(paymentModel) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}Orders/PaymentPriceOfOrder`, paymentModel);
  }

  addItemToOrder(orderId: number, orderProduct) {
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}Orders/${orderId}/products`,
      orderProduct
    );
  }

  updateSeat(orderSubaccount:OrderSubaccount){
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}Orders/${orderSubaccount.OrderId}/update-seat/${orderSubaccount.Id}`,
      orderSubaccount
    );
  }

  createOrder(orderData) {
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}Orders`,
      orderData
    );
  }

  getOrderDetails(orderId) {
    return this.httpService.get(`${RuntimeConstants.API_PATH}Orders/` + orderId);
  }
  getOrderDetailsWithAllComponents(orderId) {
    return this.httpService.get(`${RuntimeConstants.API_PATH}Orders/` + orderId + `/with-components`);
  }

  getSeatWiseOrderDetails(orderId) {
    return this.httpService.get(`${RuntimeConstants.API_PATH}Orders/seat-wise-order-details/` + orderId);
  }

  getTransactions(orderId) {
    return this.httpService.get(`${RuntimeConstants.API_PATH}Orders/` + orderId + '/transactions');
  }


  getDbOrderDetails(orderId) {
    return this.httpService.get(`${RuntimeConstants.API_PATH}Orders/GetOrderDetails/` + orderId);
  }

  getRefundOrderDetailsFromOrderId(orderId: number) {
    return this.httpService.get(`${RuntimeConstants.API_PATH}Orders/orderRefundDetails/${orderId}`);
  }

  updateQty(orderId, orderProductId, qty) {
    return this.httpService.put(
      `${RuntimeConstants.API_PATH}Orders/${orderId}/products/${orderProductId}/qty/${qty}`,
      null
    );
  }

  changeProductGroupQty(orderId: number, updateGroupProducts: Array<{OrderProductId: number, NewQty: number}>) {
    return this.httpService.put(
      `${RuntimeConstants.API_PATH}Orders/${orderId}/update-group-qty`,
      updateGroupProducts
    );
  }

  specialFunction(orderId, command) {
    const orderSpecialFunctionCommand = { OrderSpecialCommand: command };
    return this.httpService.put(
      `${RuntimeConstants.API_PATH}Orders/${orderId}/execute/special-function`,
      orderSpecialFunctionCommand
    );
  }

  cashPayment(orderId: number, paymentDetails) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}Orders/${orderId}/payments/record/cash`, paymentDetails);
  }

  capturePayment(orderId: number, paymentDetails) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}Orders/capture-payment/` + orderId, paymentDetails);
  }

  openCashDrawer(drawerId: number, paymentDetails) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}Orders/open/cash-drawer/` + drawerId, paymentDetails);
  }

  giftCardPayment(orderId: number, paymentDetails) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}Orders/${orderId}/payments/record/gift-card`, paymentDetails);
  }

  integratedCreditCardPayment(orderId: number, paymentDetails) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}Orders/${orderId}/payments/request/credit-card`, paymentDetails);
  }

  overridePrice(orderId, orderProductId, price) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}Orders/${orderId}/product/${orderProductId}/price/${price}`, null);
  }

  serveOrder(orderId: number, userId: number) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}Orders/serve-order/${orderId}/${userId}`, null);
  }

  notify(orderId: number) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}Orders/${orderId}/notify`, null);
  }

  notifyItemReady(orderId: number, itemId: number) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}Orders/${orderId}/product/${itemId}/notify`, null)
  }

  peripheralCreditCardPayment(orderId: number, paymentDetails) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}Orders/${orderId}/payments/record/credit-card`, paymentDetails);
  }

  peripheralEBTPayment(orderId: number, paymentDetails) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}Orders/${orderId}/payments/record/ebt`, paymentDetails);
  }

  cancelCreditCardPayment(requestDetails) {
    console.log('cancel credit card transaction request to end point: ' +
      `${RuntimeConstants.API_PATH}Orders/cancel-creditcard-transaction/` + requestDetails.OrderId);
    console.log(JSON.stringify(requestDetails));
    return this.httpService.post(`${RuntimeConstants.API_PATH}Orders/cancel-creditcard-transaction/` + requestDetails.OrderId,
      requestDetails);
  }

  addComment(orderId, orderProductId, comment) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}Orders/${orderId}/products/${orderProductId}/comment`, comment);
  }

  productLabelPrint(orderProductItems) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}Orders/product/labelPrint`, orderProductItems);
  }

  updateIsSentToKitchen(orderId: number) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}Orders/${orderId}/sent-to-kitchen`, null);
  }

  getDietaryWarningsOfOrder(orderId, subaccountOrdinal, dietaryRestrictionId, orderDietaryRestriction) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}Orders/${orderId}/subaccount-ordinal/${subaccountOrdinal}/dietary-restriction/${dietaryRestrictionId}`, orderDietaryRestriction);
  }

  deleteOrderDietaryRestriction(orderId, orderDietaryRestrictionId) {
    return this.httpService.delete(`${RuntimeConstants.API_PATH}Orders/${orderId}/order-dietary-restriction/${orderDietaryRestrictionId}`);
  }


  updateDietaryWarningConflictProductsAsync(orderId: number, orderProductId: number, orderDietaryRestrictions: Array<OrderDietaryRestriction>) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}Orders/${orderId}/product/${orderProductId}/conflict/dietary`, orderDietaryRestrictions);
  }

  confirmOrder(orderId, isVerified) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}orders/${orderId}/confirm?isVerified=${isVerified}`, null);
  }

  removeSubAccountPayment(orderId): Observable<any> {
    return this.httpService.post(`${RuntimeConstants.API_PATH}orders/${orderId}/remove-subaccount-payment`, null);
  }

  printIngredients(salesProductId: number, terminalId: number) {
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}Orders/${terminalId}/printIngredients/${salesProductId}`,
      null
    );
  }

  tipAdjust(transactionId, tipTransaction) {
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}Orders/transaction/${transactionId}/tip-adjust`,
      tipTransaction
    );
  }

  getAllOrdersBetweenDate(orderSummary: { Date: Date, EnteredByUserID?: number }): Observable<Array<OrderSummary>> {
    return this.httpService.post<Array<OrderSummary>>(`${RuntimeConstants.API_PATH}Orders/GetAllOrdersBetweenDate`, orderSummary);
  }

  mergeOrder(mergeOrderModel: MergeOrder) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}Orders/MergeOrder`, mergeOrderModel);
  }

  applyForOrderRefund(itemForOrderModel: RefundOrder) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}Orders/${itemForOrderModel.OrderId}/apply-refund`, itemForOrderModel);
  }

  getOrderActual(itemForOrderModel: RefundOrder): Observable<OrderInvoice> {
    return this.httpService.post<OrderInvoice>(`${RuntimeConstants.API_PATH}Orders/update-order-actuals`, itemForOrderModel);
  }

  getSeatWiseRefundOrderDetails(itemForOrderModel: RefundOrder) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}Orders/seat-wise-refund-order-details`, itemForOrderModel);
  }

  deleteTrainingOrders(cancelOrder: OrderCancel) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}orders/delete-training-orders`, cancelOrder);
  }

  getAllServeMethods(): Observable<Array<ServeMethod>> {
    return this.httpService.get<Array<ServeMethod>>(`${RuntimeConstants.API_PATH}orders/serve-methods`);
  }

  getPaymentTypes(): Observable<Array<PaymentType>> {
    return this.httpService.get<Array<PaymentType>>(`${RuntimeConstants.API_PATH}orders/payment-types`);
  }

  getOrderRefundedAmount(orderId: number): Observable<number> {
    return this.httpService.get<number>(`${RuntimeConstants.API_PATH}orders/${orderId}/refunded-amount`);
  }

  voidTransaction(transaction: VoidTransaction) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}orders/transaction/${transaction.TransactionId}/void`, transaction);
  }

  scheduleOrder(orderId: number, scheduleDate?: Date) {
    const scheduleDateModel = { OrderId: orderId, ScheduleDate: scheduleDate };
    return this.httpService.post(`${RuntimeConstants.API_PATH}orders/schedule`, scheduleDateModel);
  }

  updateProductSchedule(orderId: number, orderProductId: number, scheduleDate?: Date) {
    const scheduleDateModel = { OrderId: orderId, ScheduleDate: scheduleDate };
    return this.httpService.post(`${RuntimeConstants.API_PATH}Orders/products/${orderProductId}/schedule`, scheduleDateModel);
  }

  getTransactionStatus(requestId: string) {
    return this.httpService.get(`${RuntimeConstants.API_PATH}Orders/transactionRequest/${requestId}/status`);
  }

  deleteCallIn(orderId: number): Observable<any> {
    return this.httpService.delete(`${RuntimeConstants.API_PATH}Orders/${orderId}/remove/call-in`);
  }

  deleteName(orderId: number): Observable<any> {
    return this.httpService.delete(`${RuntimeConstants.API_PATH}Orders/${orderId}/remove/name`);
  }

  deletePager(orderId: number): Observable<any> {
    return this.httpService.delete(`${RuntimeConstants.API_PATH}Orders/${orderId}/remove/pager`);
  }

  deletePhoneNumber(orderId: number): Observable<any> {
    return this.httpService.delete(`${RuntimeConstants.API_PATH}Orders/${orderId}/remove/phone-number`);
  }

  getCurrentWaitTime(): Observable<number> {
    return this.httpService.get<number>(`${RuntimeConstants.API_PATH}Orders/wait-time`);
  }

  addAdditionalInventory(orderId, orderProductId, deductInventoryProduct: DeductInventoryProduct) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}Orders/${orderId}/product/${orderProductId}/inventory`, deductInventoryProduct);
  }

  addScannedItemToOrder(orderId: number, orderProduct) {
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}Orders/${orderId}/barcode-product`, orderProduct
    );
  }

  createScannedProductOrder(orderData) {
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}Orders/barcode-order`, orderData
    );
  }
  
  updateOrderMethod(orderId: number, orderMethodId: number) {
    return this.httpService.post(`${RuntimeConstants.API_PATH}Orders/${orderId}/order-method/${orderMethodId}`, null);
  }

}
