import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { AlertsService, FormUtilityService, Messages, ModalFormComponent, ModalService, SpinnerService } from 'src/app/shared';
import { DataSourceService } from '../../service';
import { Clipboard } from '@angular/cdk/clipboard';
declare let $: any;

@Component({
  selector: 'pos-data-source-preview',
  templateUrl: './data-source-preview.component.html',
})
export class DataSourcePreviewComponent extends ModalFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.form;
  }
  codeMirrorOptions = {
    lineNumbers: false,
    name: 'string',
    lineWrapping: true
  };

  @Input() dataSourceId: number;
  @Input() dataSourceName: string;
  @ViewChild('dataSourcePreview', { static: true }) form: NgForm;
  responseJson: string = '';
  copyButtonText: string = 'Copy';
  constructor(private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private dataSourceService: DataSourceService,
    formUtilityService: FormUtilityService,
    modalService: ModalService,
    dataSourcePreviewModalRef: BsModalRef,
    private clipboard: Clipboard) {
    super(dataSourcePreviewModalRef, modalService, formUtilityService);
  }

  ngOnInit(): void {
    if (this.dataSourceId) {
      this.LoadPreview();
    }
  }

  cancel = () => {
    this.hide(null);
  }

  private LoadPreview() {
    this.spinnerService.show();
    this.dataSourceService.getDataFromDataSource(this.dataSourceId)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe({
        next: (_res) => {
          this.responseJson = JSON.stringify(_res);
        },
        error: this.alertService.showApiError
      });
  }

  copyResponse = () => {
    const isCopied = this.clipboard.copy(this.responseJson);
    if (isCopied) {
      this.copyButtonText = 'Copied';
      const copyTimeout = setTimeout(() => {
        this.copyButtonText = 'Copy';
        clearTimeout(copyTimeout);
      }, 2000);

    } else {
      this.alertService.renderErrorMessage(Messages.CopiedDataSourceResponseError);
      this.copyButtonText = 'Copy'
    }

  }
}
