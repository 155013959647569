import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { KioskScreenImage } from 'src/app/order-kiosk';
import { RuntimeConstants } from 'src/app/shared/constants';
import { SettingParam } from 'src/app/shared/interface';


@Injectable({
  providedIn: 'root'
})
export class PaymentConfigService {

  idleTimeoutConfigApiBasePath = RuntimeConstants.API_PATH + 'payment-config';
  constructor(private httpClient: HttpClient) { }
  getPaymentConfig(): Observable<SettingParam> {
    return this.httpClient.get<SettingParam>(`${this.idleTimeoutConfigApiBasePath}`);
  }

  save(params, images: Array<KioskScreenImage>): Observable<SettingParam> {
    const formData = new FormData();
    if (images?.length) {
      images.forEach(image => {
        formData.append('files', image.ImageFile, image.ImageName);
      });
    }
    formData.append('model', JSON.stringify(params));
    return this.httpClient.post<SettingParam>(this.idleTimeoutConfigApiBasePath, formData);
  }
}
