<div *ngIf="isPopup">
    <button class="close" (click)="cancel()">&times;</button>
    <h4 class="modal-content__heading">
        <pos-icon [icon]="icons.tabletAlt" class="pe-pix-10 black"></pos-icon>{{kioskScreen.Name}} -
        {{kioskScreen.TemplateType}}
    </h4>
</div>
<div [ngClass]="{'m-0-auto px-pix-10': !isPopup}">
    <div class="page-content">
        <div class="tab-content">
            <div class="tab-pane fade in active show" [ngClass]="{'popup-content pti-5': isPopup}">
                <div class="col-12">
                    <pos-row xs="12" class="p-0 ps-pix-20">
                        <pos-column lg="6" xs="12" class="p-0">
                            <h3 class="page-content__header" *ngIf="!isPopup">
                                <pos-icon [icon]="icons.tabletAlt" class="pe-pix-10 black"></pos-icon>
                                {{kioskScreen.Name}} - {{kioskScreen.TemplateType}}
                            </h3>
                        </pos-column>
                        <pos-column lg="6" xs="12" class="row p-0 justify-content-end align-items-center">
                            <div class="col-6 col-240 p-0">
                                <button class="btn btn-primary white" (click)="previewTerminal()">Preview</button>
                                <button class="btn btn-success white ms-pix-10"
                                    (click)="changeConfiguration()">Configure
                                    Screen</button>
                            </div>
                            <a class="close col-30 pointer" *ngIf="!isPopup" (click)="cancel()">×</a>
                        </pos-column>
                    </pos-row>
                    <form #kioskScreenForm="ngForm" class="edit-form">
                        <div class="col-12 pos-page-body pt-pix-5" [ngClass]="{'border-top': !isPopup }">
                            <div class="col-12 mt-pix-10 kiosk-screen">
                                <pos-kiosk-screen-template *ngIf="kioskScreen?.Id" [screenId]="kioskScreen.Id"
                                    [templateType]="kioskScreen.TemplateType" [isDesignMode]="isDesignMode"
                                    [screenConfigs]="kioskScreen.KioskScreenConfigs"
                                    [screenButtons]="kioskScreen.KioskScreenChoices"></pos-kiosk-screen-template>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
