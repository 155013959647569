import { GridContextInfo } from '../interface';
import { ApplicationStateService } from '../services';
import { BaseComponent } from './base.component';
import { OnDestroy, Directive } from '@angular/core';
import { GridRouteContextInfo } from '../interface/grid-route-context-info';
import { ActivatedRoute } from '@angular/router';
declare let $: any;
@Directive()
export abstract class BaseGridComponent extends BaseComponent implements OnDestroy {
    gridContext: GridContextInfo;
    othersProperties: any = {};
    currentRouteGroup: string;
    constructor(protected applicationStateService: ApplicationStateService,
        protected route: ActivatedRoute) {
        super();
        this.getGridContext();
        this.currentRouteGroup = route.snapshot.data.group;
    }

    ngOnDestroy() {
        this.getOtherProperties();
        this.setGridContext(this.currentRouteGroup, this.gridContext);
        this.gridContext = this.newGridContextInfo();
        // Scroll up while unload list page like click on edit, config etc...
        $('html, body').animate({ scrollTop: 0 }, 'medium');
    }

    protected setGridContext(routeGroup: string, gridContextInfo: GridContextInfo) {
        const context: GridRouteContextInfo = { RouteGroup: routeGroup,
             OtherProperty: this.othersProperties, GridContextInfo: gridContextInfo };
        this.applicationStateService.gridRouteContext = context;
    }

    protected getGridContext() {
        if (this.applicationStateService.gridRouteContext) {
            const context = this.applicationStateService.gridRouteContext;
            if (context && context.RouteGroup === this.route.snapshot.data.group) {
                this.gridContext = context.GridContextInfo;
                this.othersProperties = context.OtherProperty;
            } else {
                this.gridContext = this.newGridContextInfo();
                this.applicationStateService.gridRouteContext = null;
            }
        } else {
            this.gridContext = this.newGridContextInfo();
        }
    }

    newGridContextInfo() {
        return {
            PageNumber: 1,
            PageSize: 20,
            Filter: '',
            SortProperty: '',
            SortDirection: 'Asc'
        };
    }
     protected getOtherProperties(): any | void {}
}
