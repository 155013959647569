import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalComponent } from 'src/app/shared';

@Component({
  selector: 'pos-credit-card-response-modal',
  templateUrl: './credit-card-response-modal.component.html'
})
export class CreditCardResponseModalComponent extends ModalComponent {
  constructor(bsModelRef: BsModalRef) {  super(bsModelRef); }
  creditCardResponse = {
    header: '',
    message: '',
    buttonText: 'Cancel',
    isShowCCError: false ,
  }


  hideOverPaymentModal(){
    this.hide({ cancel: false});
  }

  cancelCreditCardTransaction(){
    this.hide({ cancel: true});
  }

}
