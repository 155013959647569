import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { find } from 'lodash';
import {
  AlertsService, AudioNotificationService, AuthenticationService, DomainConstants, FormUtilityService, Levels, ModalFormComponent, ModalService, Permissions,
  SpinnerService
} from 'src/app/shared';
import { AudioNotificationEditComponent, AudioNotificationFile } from 'src/app/information-management';
import { EventSubscriptionActionAttribute } from '../../interfaces';
import { finalize } from 'rxjs/operators';
import { newAudioFile, messageCode } from '../../../../shared/components/icon';
import { EventActionCommonService } from '../../services/event-action-common.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgForm } from '@angular/forms';
import { RepeatActionConfigurationComponent } from '../repeat-action-configuration';

@Component({
  selector: 'pos-push-notification-configuration',
  templateUrl: './push-notification-configuration.component.html',
  styleUrls: ['./push-notification-configuration.component.scss']
})
export class PushNotificationConfigurationComponent extends ModalFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.pushNotificationEditForm;
  }
  @ViewChild('RepeatActionConfiguration') repeatActionConfiguration: RepeatActionConfigurationComponent;
  @ViewChild('pushNotificationEditForm') pushNotificationEditForm: NgForm;
  @Input() actionId = 0;
  @Input() eventSubscriptionActionAttributes: Array<EventSubscriptionActionAttribute> = [];
  @Input() eventAttributes: Array<string> = [];
  @Input() audioFiles: Array<AudioNotificationFile> = [];
  @Input() audioVoices: Array<string> = [];
  @Input() selectedDeliveryType;
  close: EventEmitter<any> = new EventEmitter();
  @Input() isRecoverableEvent;
  deliveryAttributes = DomainConstants.EventDeliveryAttributes;
  selectedAudioFile: number = null;
  audioTextString = '';
  selectedAudioVoice: string = null;
  pushNotificationModes = DomainConstants.PushNotificationModes;
  notificationMode = this.pushNotificationModes.Audio;
  userHasPermission = false;
  isOpenVariable = false;
  cursorPosition = 0;
  selectedEventAttribute: string;
  permission = {
    name: Permissions.AudioClips,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };
  public icons = {
    newAudioFile,
    messageCode
  };
  props = {
    controlBoxClass: 'col-lg-8 col-md-9 col-sm-10 col-xs-12'
  }
  constructor(modalService: ModalService, private authenticationService: AuthenticationService,
    private audioNotificationService: AudioNotificationService,
    private spinnerService: SpinnerService,
    private eventActionCommonService: EventActionCommonService,
    private alertsService: AlertsService,
    modalRef: BsModalRef, protected formUtilityService: FormUtilityService) {
    super(modalRef, modalService, formUtilityService);
  }


  ngOnInit(): void {
    if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      this.userHasPermission = true;
    }
    this.prepareAttributes();
  }

  prepareAttributes() {
    const selectedAudioFile = parseInt(find(this.eventSubscriptionActionAttributes,
      x => x.AttributeKey === this.deliveryAttributes.PushNotification.AudioFile)?.AttributeValue, 10);
    this.selectedAudioFile = isNaN(selectedAudioFile) ? null : selectedAudioFile;
    this.selectedAudioVoice = find(this.eventSubscriptionActionAttributes,
      x => x.AttributeKey === this.deliveryAttributes.PushNotification.SpeakVoiceId)?.AttributeValue;
    const speakText = find(this.eventSubscriptionActionAttributes,
      x => x.AttributeKey === this.deliveryAttributes.PushNotification.NotificationSpeakText);
    if (speakText) {
      this.audioTextString = speakText.AttributeValue ?? '';
      this.notificationMode = this.pushNotificationModes.SpeakText;
    }
    const notificationText = find(this.eventSubscriptionActionAttributes,
      x => x.AttributeKey === this.deliveryAttributes.PushNotification.NotificationText);
    if (notificationText) {
      this.audioTextString = notificationText.AttributeValue ?? '';
      this.notificationMode = this.pushNotificationModes.Text;
    }
  }

  changeAudioMode(audioMode: string) {
    this.notificationMode = audioMode;
    if (this.notificationMode === this.pushNotificationModes.Audio) {
      this.audioTextString = '';
      this.selectedAudioVoice = null;
      this.cursorPosition = this.audioTextString.length;
    } else if (this.notificationMode === this.pushNotificationModes.SpeakText) {
      this.selectedAudioFile = null;
      this.cursorPosition = this.audioTextString.length
    } else if (this.notificationMode === this.pushNotificationModes.Text) {
      this.selectedAudioFile = null;
      this.selectedAudioVoice = null;
      this.cursorPosition = this.audioTextString.length
    }
  }

  changeEventAttribute() {
    this.isOpenVariable = false;
    this.audioTextString = this.audioTextString.substring(0, this.cursorPosition) + ' {{' + this.selectedEventAttribute + '}} '
      + this.audioTextString.substring(this.cursorPosition);
    this.selectedEventAttribute = null;
  }

  saveActivity = (isValid: boolean) => {
    if (isValid) {
      const actionAttributes = this.prepareEventActionAttributes();
      this.hide({ shouldReload: true, actionAttributes: actionAttributes, audioFiles: this.audioFiles });
    }
  }

  prepareEventActionAttributes() {
    const actionDeliveryAttributes: Array<{ key: string, value: string }> = [];
    actionDeliveryAttributes.push({ key: this.deliveryAttributes.PushNotification.AudioFile, value: this.selectedAudioFile?.toString() });
    actionDeliveryAttributes.push({
      key: this.deliveryAttributes.PushNotification.NotificationSpeakText,
      value: (this.notificationMode === this.pushNotificationModes.SpeakText ? this.audioTextString?.toString() : null)
    });
    actionDeliveryAttributes.push({
      key: this.deliveryAttributes.PushNotification.SpeakVoiceId,
      value: this.selectedAudioVoice?.toString()
    });
    actionDeliveryAttributes.push({
      key: this.deliveryAttributes.PushNotification.NotificationText,
      value: (this.notificationMode === this.pushNotificationModes.Text ? this.audioTextString?.toString() : null)
    });
    if (this?.repeatActionConfiguration?.repeatConfig) {
      actionDeliveryAttributes.push({
        key: this.deliveryAttributes.PushNotification.Repeat,
        value: this?.repeatActionConfiguration?.isRepeat ? JSON.stringify(this?.repeatActionConfiguration?.repeatConfig) : ""
      });
    }
    return this.eventActionCommonService.addActionAttributes(actionDeliveryAttributes, this.actionId,
      this.eventSubscriptionActionAttributes);
  }

  closeModal(shouldReload: boolean) {
    this.hide({ shouldReload: shouldReload, audioFiles: this.audioFiles });
  }

  addNewAudioFile() {
    const audioNotificationFileModalRef = this.modalService.show(AudioNotificationEditComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        audioNotifierId: 0,
        audioNotifierName: ''
      }
    });

    audioNotificationFileModalRef.close.subscribe(res => {
      if (res && res.shouldReload && res.Id) {
        this.selectedAudioFile = res.Id;
        this.reloadAudioFiles();
      }
    });
  }

  reloadAudioFiles() {
    this.spinnerService.show();
    this.audioNotificationService.getAudioNotificationFiles()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (audioFiles: Array<AudioNotificationFile>) => {
          this.audioFiles = audioFiles;
        }, error: this.alertsService.showApiError
      });
  }

  getCaretPos(text) {
    this.cursorPosition = this.audioTextString.length;
    if (text.selectionStart || text.selectionStart === '0') {
      this.cursorPosition = text.selectionStart;
    }
  }

}
