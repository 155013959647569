<pos-edit-wrapper heading="Create Task" (close)="cancelEditing()">
  <form #taskForm="ngForm" (ngSubmit)="addTask(taskForm.valid)" autocomplete="off">
    <div class="col-xs-12 pos-modal-body">
      <pos-row>
        <pos-column>
          <pos-form-field label="Name" [validationMessages]="{required: 'Please enter name.'}"
            inputStyle="col-md-10 col-xs-12">
            <textarea posInput class="form-control" name="taskname" #taskname="ngModel" maxlength="100" rows="6"
              [(ngModel)]="task.Task.TaskName" [required]="true">
          </textarea>
          </pos-form-field>
        </pos-column>
        <pos-column>
          <pos-form-field label="Schedule">
            <p-calendar posInput (onSelect)="validateDate($event)" #dueDate="ngModel" [showTime]="true"
              [ngClass]="{'has-error': (!isValidDateTime && taskForm.submitted)}" [hourFormat]="hourFormat" appendTo="body"
              [readonlyInput]="true" [inputStyle]="{'width':'unset','display':'unset'}" inputStyleClass="form-control"
              [monthNavigator]=true [showIcon]="true" name="dueDate" [(ngModel)]="task.DateDue"></p-calendar>
            <span *ngIf="(!isValidDateTime && !dueDate.invalid) && taskForm.submitted">
              <label class="red-extreme control-label">Please set a date and time in the future.</label>
            </span>
          </pos-form-field>
        </pos-column>
      </pos-row>
    </div>

    <div class="col-12 action-button-container popup-footer">
      <button class="btn-primary btn-lg btn btn-save pos-button" type="submit" value="Save">Save</button>
      <button class="btn-secondary btn-lg btn btn-cancel-modal pos-button" (click)="cancelEditing(false)" type="button"
        value="Close">Close
      </button>
    </div>
  </form>
</pos-edit-wrapper>
