import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { ICloseable } from 'src/app/shared';


@Component({
  selector: 'pos-drawer-variance',
  templateUrl: './drawer-variance.component.html',
  styleUrls: ['./drawer-variance.component.scss']
})
export class DrawerVarianceComponent implements OnInit, ICloseable {

  @Input() message = '';
  note: string;
  close: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
  }

  drawerVarianceOk() {
    this.close.emit({ isDrawerVarianceOk: true, note: this.note });
  }

  closeDrawerVariance() {
    this.close.emit();
  }

}
