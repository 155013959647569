<pos-list-wrapper heading="Training Levels" (close)="onCancel()">
    <h4><b><span class="header-lightgrey pointer" (click)="onCancel()">Area: {{areaName}}</span></b></h4>
    <h4><b><span class="header-lightgrey pointer">Topic: {{topicName}}</span></b></h4>
    <ng-table #gridComponent [sort]="false" [paginator]="false" [columns]="trainingTopicLevelColumns"
        [data]="trainingTopicLevels" [isReorderableTable]="true" [ReorderableColumnRow]="'row'"
        (onRowReorder)="gridRowReorder($event)">
    </ng-table>
    <pos-confirm-delete [message]="confirmDeleteMessage" (onConfirmAcknowledge)="onConfirmAcknowledge()"
        #confirmTrainingTopicLevelDelete>
    </pos-confirm-delete>
</pos-list-wrapper>
