import {
  faAd,
  faAddressBook,
  faAlignCenter,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faArrowAltCircleLeft,
  faArrowAltRight,
  faArrowCircleRight,
  faArrowLeft,
  faArrowsAlt,
  faArrowsAltH,
  faBackward,
  faBadgeCheck,
  faBagsShopping,
  faBallot,
  faBan,
  faBarcode,
  faBarcodeScan,
  faBars,
  faBell,
  faBook,
  faBookmark,
  faBox,
  faBoxCheck,
  faBoxes,
  faBroom,
  faBug,
  faBuilding,
  faBullhorn,
  faBurgerSoda,
  faBusinessTime,
  faCalendar,
  faCalendarAlt,
  faCalendarEdit,
  faCamera,
  faCar,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCartArrowDown,
  faChartLine,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faChevronCircleRight,
  faChevronDown,
  faChevronRight,
  faCircle,
  faClipboardCheck,
  faClipboardList,
  faClock,
  faClone,
  faCode,
  faCodeBranch,
  faCog,
  faCogs,
  faCoins,
  faColumns,
  faCommentAlt,
  faCommentDollar,
  faCommentDots,
  faComments,
  faCommentsDollar,
  faConciergeBell,
  faCreditCard,
  faCrown,
  faDatabase,
  faDesktop,
  faDiceOne,
  faDollarSign,
  faDolly,
  faDownload,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faEnvelopeOpen,
  faExchangeAlt,
  faExclamationCircle,
  faExclamationTriangle,
  faExpand,
  faExpandArrows,
  faExternalLink,
  faExternalLinkAlt,
  faExternalLinkSquareAlt,
  faFile,
  faFileAlt,
  faFileAudio,
  faFileChartLine,
  faFileExport,
  faFileImport,
  faFileInvoiceDollar,
  faFilePowerpoint,
  faFileSearch,
  faFileWord,
  faFilter,
  faFlashlight,
  faFlask,
  faFont,
  faForward,
  faFunnelDollar,
  faGiftCard,
  faGlobe,
  faGripVertical,
  faHandRock,
  faHashtag,
  faHeading,
  faHeartRate,
  faHistory,
  faHome,
  faIdBadge,
  faIdCard,
  faImages,
  faInbox,
  faInboxIn,
  faInboxOut,
  faIndustry,
  faInfoCircle,
  faInventory,
  faLanguage,
  faLayerGroup,
  faLevelDownAlt,
  faLink,
  faList,
  faListAlt,
  faLocationArrow,
  faLock,
  faLockOpen,
  faLongArrowAltLeft,
  faMagic,
  faMapPin,
  faMapMarkerAlt,
  faMegaphone,
  faMinus,
  faMinusCircle,
  faMinusSquare,
  faMoneyBill,
  faMoneyBillAlt,
  faMoneyBillWave,
  faMoneyCheckAlt,
  faMoon,
  faMousePointer,
  faMusic,
  faObjectGroup,
  faPaintBrush,
  faPalette,
  faPaperPlane,
  faPaste,
  faPencilAlt,
  faPhone,
  faPhoneAlt,
  faPlay,
  faPlug,
  faPlus,
  faPlusCircle,
  faPlusSquare,
  faPrint,
  faPuzzlePiece,
  faQuestionCircle,
  faRedo,
  faReply,
  faReplyAll,
  faRoad,
  faRocket,
  faRssSquare,
  faSave,
  faScroll,
  faSearch,
  faSearchDollar,
  faSearchMinus,
  faSearchPlus,
  faShare,
  faShareAll,
  faShip,
  faShippingFast,
  faShoppingBag,
  faShoppingCart,
  faSignInAlt,
  faSignOutAlt,
  faSms,
  faSolarPanel,
  faSort,
  faSortAlphaDown,
  faSortDown,
  faSortNumericUp,
  faSortUp,
  faSpinner,
  faSquare,
  faStar,
  faStepBackward,
  faStepForward,
  faStickyNote,
  faStop,
  faStore,
  faSync,
  faTable,
  faTabletAlt,
  faTachometer,
  faTachometerAlt,
  faTag,
  faTasks,
  faTaxi,
  faTerminal,
  faTh,
  faThLarge,
  faThList,
  faTheaterMasks,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faToggleOff,
  faToggleOn,
  faToolbox,
  faTools,
  faTrash,
  faTrashAlt,
  faTruck,
  faTruckMoving,
  faTv,
  faTvAlt,
  faUndo,
  faUndoAlt,
  faUnlink,
  faUnlock,
  faUnlockAlt,
  faUpload,
  faUser,
  faUserCircle,
  faUserClock,
  faUserLock,
  faUserRobot,
  faUserTag,
  faUserTimes,
  faUsers,
  faUsersCog,
  faUtensils,
  faVectorSquare,
  faVoteNay,
  faVideo,
  faWarehouse,
  faWrench,
  faWarehouseAlt,
  faDollyFlatbedAlt,
  faUserHeadset,
  faPause,
  faWindowClose,
  faBellOn,
  faWatch,
  faEye,
  faEyeSlash,
} from "@fortawesome/pro-solid-svg-icons";

import {
  faAddressBook as farAddressBook,
  faAddressCard as farAddressCard,
  faCheckCircle as farCheckCircle,
  faCheckSquare as farCheckSquare,
  faCircle as farCircle,
  faClock as farClock,
  faClone as farClone,
  faCog as farCog,
  faComments as farComments,
  faCopy as farCopy,
  faEdit as farEdit,
  faEnvelope as farEnvelope,
  faExternalLink as farExternalLink,
  faFile as farFile,
  faFileAlt as farFileAlt,
  faFilePowerpoint as farFilePowerpoint,
  faFileSearch as farFileSearch,
  faIdBadge as farIdBadge,
  faImage as farImage,
  faKeyboard as farKeyboard,
  faLightbulb as farLightbulb,
  faListAlt as farListAlt,
  faObjectUngroup as farObjectUngroup,
  faPiggyBank as farPiggyBank,
  faPlay as farPlay,
  faPlusSquare as farPlusSquare,
  faQuestionCircle as farQuestionCircle,
  faSave as farSave,
  faSquare as farSquare,
  faStickyNote as farStickyNote,
  faThumbsUp as farThumbsUp,
  faTimesCircle as farTimesCircle,
  faTrashAlt as farTrashAlt,
  faHourglassHalf as farHourglassHalf
} from "@fortawesome/pro-regular-svg-icons";

import {
  faAllergies as falAllergies,
  faEllipsisV as falEllipsisV,
  faExclamationTriangle as falExclamationTriangle,
  faPiggyBank as falPiggyBank,
  faTruckLoading as falTruckLoading,
  faPlusCircle as falPlusCircle,
  faMinusCircle as falMinusCircle
} from "@fortawesome/pro-light-svg-icons";

import {
  faBrowser,
  faBadgeCheck as fadBadgeCheck,
  faBoxes as fadBoxes,
  faBurgerSoda as fadBurgerSoda,
  faCircle as fadCircle,
  faClone as fadClone,
  faDiceOne as fadDiceOne,
  faForward as fadForward,
  faImage as fadImage,
  faImages as fadImages,
  faListUl as fadListUl,
  faRectangleLandscape as fadRectangleLandscape,
  faRectanglePortrait as fadRectanglePortrait,
  faText as fadText
} from "@fortawesome/pro-duotone-svg-icons";

import {
  faNutritionix as fabNutritionix,
  faSimplybuilt as fabSimplybuilt,
  faTwitterSquare as fabTwitterSquare,
  faApplePay as fabApplePay,
  faGooglePay as fabGooglePay,
} from "@fortawesome/free-brands-svg-icons";

const faRegularIcons = [
  farAddressBook,
  farAddressCard,
  farCheckCircle,
  farCheckSquare,
  farCircle,
  farClock,
  farClone,
  farCog,
  farComments,
  farCopy,
  farEdit,
  farEnvelope,
  farExternalLink,
  farFile,
  farFileAlt,
  farFilePowerpoint,
  farFileSearch,
  farIdBadge,
  farImage,
  farKeyboard,
  farLightbulb,
  farListAlt,
  farObjectUngroup,
  farPiggyBank,
  farPlay,
  farPlusSquare,
  farQuestionCircle,
  farSave,
  farSquare,
  farStickyNote,
  farThumbsUp,
  farTimesCircle,
  farTrashAlt,
  farHourglassHalf
];

const faLightIcons = [
  falAllergies,
  falEllipsisV,
  falExclamationTriangle,
  falPiggyBank,
  falTruckLoading,
  falPlusCircle,
  falMinusCircle
];

const faDuotoneIcons = [
  fadBadgeCheck,
  fadBoxes,
  faBrowser,
  fadBurgerSoda,
  fadCircle,
  fadClone,
  fadDiceOne,
  fadImage,
  fadImages,
  fadForward,
  fadListUl,
  fadRectangleLandscape,
  fadRectanglePortrait,
  fadText,
];

const faBrandIcons = [fabNutritionix, fabSimplybuilt, fabTwitterSquare];

const faSolidIcons = [
  faAd,
  faAddressBook,
  faAlignCenter,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faArrowAltCircleLeft,
  faArrowAltRight,
  faArrowCircleRight,
  faArrowLeft,
  faArrowsAlt,
  faArrowsAltH,
  faBackward,
  faBadgeCheck,
  faBagsShopping,
  faBallot,
  faBan,
  faBarcode,
  faBarcodeScan,
  faBars,
  faBell,
  faBook,
  faBookmark,
  faBox,
  faBoxCheck,
  faBoxes,
  faBroom,
  faBug,
  faBuilding,
  faBullhorn,
  faBurgerSoda,
  faBusinessTime,
  faCalendar,
  faCalendarAlt,
  faCalendarEdit,
  faCamera,
  faCar,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCartArrowDown,
  faChartLine,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faChevronCircleRight,
  faChevronDown,
  faChevronRight,
  faCircle,
  faClipboardCheck,
  faClipboardList,
  faClock,
  faClone,
  faCode,
  faCodeBranch,
  faCog,
  faCogs,
  faCoins,
  faColumns,
  faCommentAlt,
  faCommentDollar,
  faCommentDots,
  faComments,
  faCommentsDollar,
  faConciergeBell,
  faCreditCard,
  faCrown,
  faDatabase,
  faDesktop,
  faDiceOne,
  faDollarSign,
  faDolly,
  faDownload,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faEnvelopeOpen,
  faExchangeAlt,
  faExclamationCircle,
  faExclamationTriangle,
  faExpand,
  faExpandArrows,
  faExternalLink,
  faExternalLinkAlt,
  faExternalLinkSquareAlt,
  faFile,
  faFileAlt,
  faFileAudio,
  faFileChartLine,
  faFileExport,
  faFileImport,
  faFileInvoiceDollar,
  faFilePowerpoint,
  faFileSearch,
  faFileWord,
  faFilter,
  faFlashlight,
  faFlask,
  faFont,
  faForward,
  faFunnelDollar,
  faGiftCard,
  faGlobe,
  faGripVertical,
  faHandRock,
  faHashtag,
  faHeading,
  faHeartRate,
  faHistory,
  faHome,
  faIdBadge,
  faIdCard,
  faImages,
  faInbox,
  faInboxIn,
  faInboxOut,
  faIndustry,
  faInfoCircle,
  faInventory,
  faLanguage,
  faLayerGroup,
  faLevelDownAlt,
  faLink,
  faList,
  faListAlt,
  faLocationArrow,
  faLock,
  faLockOpen,
  faLongArrowAltLeft,
  faMagic,
  faMapPin,
  faMapMarkerAlt,
  faMegaphone,
  faMinus,
  faMinusCircle,
  faMinusSquare,
  faMoneyBill,
  faMoneyBillAlt,
  faMoneyBillWave,
  faMoneyCheckAlt,
  faMoon,
  faMousePointer,
  faMusic,
  faObjectGroup,
  faPaintBrush,
  faPalette,
  faPaperPlane,
  faPaste,
  faPencilAlt,
  faPhone,
  faPhoneAlt,
  faPlay,
  faPlug,
  faPlus,
  faPlusCircle,
  faPlusSquare,
  faPrint,
  faPuzzlePiece,
  faQuestionCircle,
  faRedo,
  faReply,
  faReplyAll,
  faRoad,
  faRocket,
  faRssSquare,
  faSave,
  faScroll,
  faSearch,
  faSearchDollar,
  faSearchMinus,
  faSearchPlus,
  faShare,
  faShareAll,
  faShip,
  faShippingFast,
  faShoppingBag,
  faShoppingCart,
  faSignInAlt,
  faSignOutAlt,
  faSms,
  faSolarPanel,
  faSort,
  faSortAlphaDown,
  faSortDown,
  faSortNumericUp,
  faSortUp,
  faSpinner,
  faSquare,
  faStar,
  faStepBackward,
  faStepForward,
  faStickyNote,
  faStop,
  faStore,
  faSync,
  faTable,
  faTabletAlt,
  faTachometer,
  faTachometerAlt,
  faTag,
  faTasks,
  faTaxi,
  faTerminal,
  faTh,
  faThLarge,
  faThList,
  faTheaterMasks,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faToggleOff,
  faToggleOn,
  faToolbox,
  faTools,
  faTrash,
  faTrashAlt,
  faTruck,
  faTruckMoving,
  faTv,
  faTvAlt,
  faUndo,
  faUndoAlt,
  faUnlink,
  faUnlock,
  faUnlockAlt,
  faUpload,
  faUser,
  faUserCircle,
  faUserClock,
  faUserLock,
  faUserRobot,
  faUserTag,
  faUserTimes,
  faUsers,
  faUsersCog,
  faUtensils,
  faVectorSquare,
  faVoteNay,
  faVideo,
  faWarehouse,
  faWrench,
  faWarehouseAlt,
  faDollyFlatbedAlt,
  faUserHeadset,
  faPause,
  faWindowClose,
  faBellOn,
  faWatch,
  faEye,
  faEyeSlash,
];

export const exportIcons = [
  ...faSolidIcons,
  ...faRegularIcons,
  ...faLightIcons,
  ...faDuotoneIcons,
  ...faBrandIcons,
];
