<pos-list-wrapper heading="Training Topics" (close)="cancel()">
  <h4><b><span class="header-lightgrey pointer">Area: {{areaName}}</span></b></h4>
  <ng-table #gridComponent [sort]="false" [paginator]="false" [columns]="trainingTopicColumns"
    [customFilterColumns]="filterColumns" [data]="trainingTopics" [isReorderableTable]="true"
    [ReorderableColumnRow]="'row'" (onRowReorder)="gridRowReorder($event)">
  </ng-table>
  <pos-confirm-delete [message]="confirmDeleteMessage" (onConfirmAcknowledge)="onConfirmAcknowledge()"
    #confirmTrainingTopicDelete>
  </pos-confirm-delete>
</pos-list-wrapper>
