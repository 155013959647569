import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { Observable } from "rxjs";
import { Task, SingleTask } from "../../information-management/tasks/interface/task";

@Injectable()

export class TaskService {

    constructor(private httpService: HttpClient) { }

    newTask() {
        const taskObj: Task = {
            CurrentPage: null,
            DateAdded: new Date(),
            DateEdited: null,
            PageSize: null,
            active: false,
            id: 0,
            taskname: '',
            IsStart: false,
        };
        return taskObj;
    }

    newSingleTask() {
        const task: SingleTask = {
            DateDue: new Date(),
            UserId: null,
            IsSkipped: false,
            Task: {
                TaskName: '',
                IsActive: true,
                IsSingleUse: true
            }
        };
        return task;
    }

    public getTaskList(): Observable<any> {
        return this.httpService.get(`${RuntimeConstants.API_PATH}`+'tasks')

    }

    public getTask(taskId: number): Observable<any> {

        return this.httpService.get(`${RuntimeConstants.API_PATH}`+'tasks/' + taskId)

    }

    public updateTaskDetail(id, taskData): Observable<any> {
        return this.httpService.put( `${RuntimeConstants.API_PATH}`+'tasks/' + id,   taskData );
    }

    public addTaskDetail(taskData): Observable<any> {
        return this.httpService.post(`${RuntimeConstants.API_PATH}`+'tasks', taskData)
    }

    public deleteTask(id: number): Observable<any> {
        return this.httpService.delete(`${RuntimeConstants.API_PATH}`+'tasks/' + id)
    }

    public getTaskSchedule = function (taskId) {
        return this.httpService.get(`${RuntimeConstants.API_PATH}`+'tasks/' + taskId + '/TaskSchedule')

    }

    public updateTaskSchedule = function (taskId,scheduleData) {
        return this.httpService.put(`${RuntimeConstants.API_PATH}`+'tasks/' +taskId + '/TaskSchedule',scheduleData)

    }

    public addTask(taskData): Observable<any> {
        return this.httpService.post(`${RuntimeConstants.API_PATH}`+'tasks/addsingletask',taskData);
    }

}
