<pos-edit-wrapper heading="{{ id ? 'Edit' : 'Add' }} Macro Script{{ id ? ': '+ scriptName : '' }}" (close)="onCancel()"
    [headingIcon]="icons.scroll" [isPopup]="false">
    <form #macroScriptForm="ngForm" class="edit-form" (ngSubmit)="submit(macroScriptForm.valid)">
        <div class="col-12 border-top pt-pix-5">
            <div class="col-12 pos-page-body">
                <pos-row>
                    <pos-column>
                        <pos-form-field label="Name" [validationMessages]="{required: 'Please enter name.'}"
                            [hasWarning]="macroScript.Name !== scriptName && id"
                            [warning]="macroScriptNameChangeWarning">
                            <input posInput type="text" id="macroScriptName" name="macroScriptName"
                                #macroScriptName="ngModel" class="form-control" [(ngModel)]="macroScript.Name"
                                [required]="true"/>
                        </pos-form-field>
                    </pos-column>
                    <pos-column>
                        <pos-form-field label="Macro Script" inputStyle="col-xs-12"
                            [validationMessages]="{required: 'Please enter macro script.'}">
                            <ngx-codemirror posInput id="macroScriptQuery" name="macroScriptQuery" class=""
                                #macroScriptQuery="ngModel" [(ngModel)]="macroScript.Script"
                                [options]="codeMirrorOptions" [required]="true"></ngx-codemirror>
                        </pos-form-field>
                    </pos-column>
                </pos-row>
            </div>
        </div>
        <div class="col-12 action-button-container page-footer">
            <button type="submit" value="Save" class="btn btn-primary btn-lg btn-save pos-button" scrollup>
                Save
            </button>
            <button type="button" class="btn btn-secondary btn-lg btn-cancel pos-button" value="Cancel" (click)="onCancel()"
                scrollup>
                Cancel
            </button>
        </div>
    </form>
</pos-edit-wrapper>
