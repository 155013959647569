import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AlertsService, Messages, SpinnerService, DomainConstants, BaseFormComponent, FormUtilityService, GridContextInfo } from 'src/app/shared';
import { TrainingTopicService } from '../../../../shared/services/training-topic.service';
import { TrainingTopic, PermissionRole } from '../../interface/training-topic';
import { TrainingArea } from '../../../training-areas/interface/training-area';
import { finalize, zip } from 'rxjs';
import * as _ from 'lodash';
import { ActivatedRoute, Navigation, Router } from '@angular/router';

@Component({
  selector: 'pos-training-topic-edit',
  templateUrl: './training-topic-edit.component.html',
  styleUrls: ['./training-topic-edit.component.scss']
})
export class TrainingTopicEditComponent extends BaseFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.form
  }
  @ViewChild('formTrainingTopic', { static: true }) form: NgForm;
  areas: Array<TrainingArea>;
  roles: Array<PermissionRole>;
  topic: TrainingTopic;
  defaultRoleName: string;
  areaName: string;
  navigation: Navigation;
  props = {
    label: '',
    controlBoxClass: 'col-sm-7 col-xs-12'
  }

  areaGridContext: GridContextInfo = null;

  constructor(private route: ActivatedRoute,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private router: Router,
    private trainingTopicService: TrainingTopicService,
    formUtilityService: FormUtilityService) {
    super(formUtilityService);
    this.topic = this.trainingTopicService.newTrainingTopic();
    this.navigation = router.getCurrentNavigation();
    this.topic.Id = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
    this.topic.TrainingAreaId = route.snapshot.params.areaId ? parseInt(route.snapshot.params.areaId, 10) : 0;
    this.areaName = this.navigation?.extras?.state?.areaName ?? '';
    const navigation = router.getCurrentNavigation();
    this.areaGridContext = navigation?.extras?.state?.GridContext ?? this.areaGridContext;
    this.topic.TrainingArea.Name = this.areaName;
    this.defaultRoleName = DomainConstants.SystemPermissionRoles.Administrator;
  }

  ngOnInit() {
    this.getTrainingTopic(this.topic.Id);
    this.setInitialValue();
  }
  onRoleRemove(event: any) {
    if (this.defaultRoleName === event.value.Name) {
      this.topic.TopicsRoles = [event.value.Id];
    }
  }
  getRoles() {
    this.spinnerService.show();
    this.trainingTopicService.getRoles(true)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          if (response) {
            this.roles = response;
          } else {
            this.roles = [];
          }
          const roleName = DomainConstants.SystemPermissionRoles.Administrator;
          let roleId;
          _.filter(this.roles, (o) => {
            if (o.Name === roleName) {
              o.disabled = true;
              roleId = o.Id;
            }
          });
          this.topic.TopicsRoles = [roleId];
        },
        error: this.alertService.showApiError
      });
  }

  getTrainingTopic(topicId: number) {
    if (topicId > 0) {
      this.spinnerService.show();
      zip(
        this.trainingTopicService.getRoles(true),
        this.trainingTopicService.getTrainingTopic(topicId),
        (
          roles: Array<PermissionRole>,
          topic: any
        ) => {
          this.roles = roles;
          const roleName = DomainConstants.SystemPermissionRoles.Administrator;
          _.filter(this.roles, function (o) {
            if (o.Name === roleName) {
              o.disabled = true;
            }
          });
          this.topic = topic;
          if (!this.areaName && topic.TrainingArea) {
            this.areaName = topic.TrainingArea.Name;
          }
          this.topic.TopicsRoles = this.topic.TrainingTopicRoles.map(a => a.RoleId);
        }
      ).subscribe({
        next: (res) => {
        }, error: this.alertService.showApiError,
        complete: () => {
          this.spinnerService.hide();
        }
      });
    } else {
      this.getRoles();
      this.topic = this.trainingTopicService.newTrainingTopic();
      this.topic.TrainingAreaId = this.route.snapshot.params.areaId ?? 0;
      this.topic.TrainingArea.Name = this.navigation?.extras?.state?.areaName ?? '';
    }
  }
  public saveTrainingTopic(isValid: boolean) {
    if (!isValid || (!this.topic.TopicsRoles.length)) {
      return;
    }
    this.topic.TrainingTopicRoles = [];
    for (const topicsRole of this.topic.TopicsRoles) {
      const trainingTopicsRole = {
        Id: 0,
        TrainingTopicId: this.topic.Id,
        RoleId: topicsRole,
        DateAdded: new Date(),
        DateEdited: null,
      };
      this.topic.TrainingTopicRoles.push(trainingTopicsRole);
    }
    if (this.topic.Id > 0) {
      this.spinnerService.show();
      this.trainingTopicService.updateTrainingTopic(this.topic)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res) => {
            this.alertService.renderSuccessMessage(Messages.TrainingTopicSavedSuccess);
            this.onCancel();
          }, error: this.alertService.showApiError
        });
    } else {
      this.spinnerService.show();
      this.trainingTopicService.saveTrainingTopic(this.topic)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res) => {
            this.alertService.renderSuccessMessage(Messages.TrainingTopicSavedSuccess);
            this.onCancel();
          }, error: this.alertService.showApiError
        });
    }
  }

  onCancel() {
    this.router.navigate(['training-areas', this.topic.TrainingAreaId, 'topics'],
      {
        state: { areaId: this.topic.TrainingAreaId, areaName: this.topic.TrainingArea.Name, GridContext: this.areaGridContext },
        relativeTo: this.route.parent
      });
  }

}
