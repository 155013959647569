<div class="row mx-0">
    <div class="col-3 pt-pix-10">
        <div class="left-nav-toggle" id="menu-toggle-div" (click)="toggleFullScreen()">
            <a class="left-nav-toggle__icon">
                <pos-icon class="black"
                    [icon]="isFullScreen ? icons.angleDoubleRight : icons.angleDoubleLeft"></pos-icon>
            </a>
        </div>
    </div>
    <div class="col-12 tab-section">
        <div class="vertical-tabs">
            <ul class="nav nav-pills nav-stacked flex-column" [hidden]="isFullScreen">
                <div class="relative">
                    <span class="search-icons">
                        <pos-icon [icon]="icons.times" *ngIf="searchPromotionTabText"
                            (click)="searchPromotionTabText = '';search()">
                        </pos-icon>
                        <pos-icon [icon]="icons.search" *ngIf="!searchPromotionTabText"></pos-icon>
                    </span>
                    <input class="form-control" [(ngModel)]="searchPromotionTabText" (input)="search()"
                        placeholder="Promotion Filter...">
                </div>
                <ng-container *ngFor="let menuGroup of filteredPromotionTabs">
                    <li class="tab-head" *ngIf="menuGroup.Permission && menuGroup.PermissionLevel"
                        permissions='[{"Name":"{{menuGroup.Permission}}", "Level":"{{menuGroup.PermissionLevel}}"}]'>
                        {{menuGroup.GroupName}}
                    </li>
                    <li class="tab-head" *ngIf="!menuGroup.Permission && !menuGroup.PermissionLevel">
                        {{menuGroup.GroupName}}
                    </li>
                    <ng-container *ngFor="let menulist of menuGroup.List">
                        <li *ngIf="menulist.Permission && menulist.PermissionLevel" class="pointer" role="presentation"
                            grantif="all" [routerLink]="menulist.RouteLink" routerLinkActive="active" permissions='[{"Name":"{{menulist.Permission}}", "Level":"{{menulist.PermissionLevel}}"},
                        {"Name":"{{menuGroup.Permission}}", "Level":"{{menuGroup.PermissionLevel}}"}]' scrollup>
                            <a>
                                <div class="display-contents">
                                    <section class="float-start text-center col-33">
                                        <pos-icon class="black font-x-normal" [icon]="menulist.Icon"></pos-icon>
                                    </section>
                                </div>
                                <div class="display-flex">
                                    {{menulist.Name}}
                                </div>
                            </a>
                        </li>
                        <li *ngIf="!menulist.Permission && !menulist.PermissionLevel" class="pointer"
                            role="presentation" grantif="all" [routerLink]="menulist.RoutLink" routerLinkActive="active"
                            scrollup>
                            <a>
                                <div class="display-contents">
                                    <section class="float-start text-center col-33">
                                        <pos-icon class="black font-x-normal" [icon]="menulist.Icon"></pos-icon>
                                    </section>
                                </div>
                                <div class="display-flex">
                                    {{menulist.Name}}
                                </div>
                            </a>
                        </li>
                    </ng-container>
                </ng-container>
            </ul>
            <div class="p-0">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
