<div class="row mx-0 mx-0 p-0 pb-pix-10">
    <div class="col-lg-4 col-md-6 col-sm-10 offset-sm-1 col-12 mx-0 p-0 nutrition-facts-section row mx-0">
        <div class="col-12 row mx-0 pt-pix-10 pb-pix-10">
            <div class="col-12 row mx-0 p-0 border-bottom-3px">
                <div class="col-12 pull-left p-0">
                    one serving
                </div>
                <div class="col-6 p-0 text-start">
                    Serving size
                </div>
                <div class="col-6 p-0 text-end">
                    1 item
                </div>
            </div>
            <div class="col-12 row mx-0 border-bottom-3px p-0">
                <div class="col-12 pull-left p-0">
                    amount per serving
                </div>
                <div class="col-12 p-0 row mx-0 font-33 bold">
                    <div class="col-6 p-0">
                        Calories
                    </div>
                    <div class="col-6 p-0 text-end">
                        {{ nutritionFacts.Calories}}
                    </div>
                </div>
            </div>
            <div class="col-12 row mx-0 p-0 text-end border-bottom-1px">% Daily Value*</div>
            <div class="col-12 row mx-0 p-0 border-bottom-1px"
                [ngClass]="{'border-bottom-3px': nutritionDetails.IsLastDefaultElement }"
                *ngFor="let nutritionDetails of nutritionFacts.NutritionInfo">
                <div class="col-6" [ngClass]="{'p-0': !nutritionDetails.IsChildNutrition}">
                    <span [ngStyle]="{'font-weight': nutritionDetails.FontStyle, 'font-style': nutritionDetails.FontStyle}">{{ nutritionDetails.Name }}</span> {{ nutritionDetails.MeasureAmount}}{{ nutritionDetails.MeasureName}}
                </div>
                <div class="col-6 text-end p-0">
                    {{nutritionDetails.MeasurePercent}}%
                </div>
            </div>
            <div class="col-12 row mx-0 p-0 font-10 border-top-3px">
                * The % Daily Value (DV) tells you how much a nutrient in a serving of food contributes to a daily diet.
                2,000 calories a day is used for general nutrition advice.
            </div>
            <div class="col-12 p-0 font-10 border-top-3px"
                *ngIf="nutritionFacts.Ingredients">
                {{nutritionFacts.Ingredients}}
            </div>
        </div>
    </div>
</div>
