<pos-edit-wrapper [isPopup]="false" [headingIcon]="icons.vendorStruck" (close)="cancel()"
    heading="{{ vendorId ? 'Edit' : 'Add' }} Vendor{{vendorId ? ': '+ vendorName : ''}}">
    <form #vendorForm="ngForm" class="edit-form" (ngSubmit)="saveVendor(vendorForm.valid)">
        <context-provider provide="props">
            <div class="col-12 pos-page-body border-top pt-pix-5 schedule-form">
                <pos-row colXs="12">
                    <pos-column>
                        <pos-form-field label="Name" [validationMessages]="{required: 'Please enter vendor name.'}">
                            <input posInput type="text" name="Name" class="form-control" [(ngModel)]="vendor.Name"
                                [required]="true" #Name="ngModel" />
                        </pos-form-field>
                    </pos-column>
                    <pos-column>
                        <pos-form-field label="Phone #"
                            [validationMessages]="{mask: 'Please enter valid phone number'}">
                            <input posInput type="text" name="phone" class="form-control"
                                [mask]="settingParam?.PhoneNumberMask.trim() ? settingParam.PhoneNumberMask : defaultMask"
                                [(ngModel)]="vendor.phone" #phoneMask="ngModel" />
                        </pos-form-field>
                    </pos-column>
                    <pos-column>
                        <pos-form-field label="Contact">
                            <input posInput type="text" name="contact" class="form-control"
                                [(ngModel)]="vendor.contact" />
                        </pos-form-field>
                    </pos-column>
                    <pos-column>
                        <pos-form-field label="Account #">
                            <input type="text" name="accountNo" class="form-control" [(ngModel)]="vendor.account_no" />
                        </pos-form-field>
                    </pos-column>
                    <pos-column>
                        <pos-row colXs="12" class="pt-pix-10">
                            <pos-column class="col-180">
                                <strong class="theme-separator">Order On</strong>
                            </pos-column>
                            <pos-column class="col-180">
                                <strong class="theme-separator">Receive On</strong>
                            </pos-column>
                        </pos-row>
                        <div class="clearfix"></div>
                        <div class="overflow-tab" [ngStyle]="{'max-height': screenHeight +'px', 'min-height': '50px'}">
                            <pos-row *ngFor="let schedule of vendor.OrderSchedule; let last = last; let index = index"
                                colStyleClass="pe-pix-5 col-180 mb-pix-5" colXs="4">
                                <pos-column class="col-180">
                                        <pos-form-field inputStyle="col-12"
                                            [validationMessages]="{invalidSchedule: 'Schedule already exists'}">
                                            <ng-select posInput appendTo="body" [items]="vendorOrderDays"
                                                bindLabel="NameOfDay" [posValidate]="validateSchedule(index, true)"
                                                (change)="createScheduleRow(last,index)" bindValue="Id"
                                                placeholder="Select day" [(ngModel)]="schedule.OrderPlaceDayId"
                                                name="orderOn_{{index}}_{{updatedIndex}}"
                                                #orderOn_{{index}}_{{updatedIndex}}="ngModel">
                                            </ng-select>
                                        </pos-form-field>
                                </pos-column>
                                <pos-column class="col-180">
                                    <pos-form-field inputStyle="col-12"
                                        [validationMessages]="{invalidSchedule: 'Schedule already exists'}">
                                            <ng-select posInput appendTo="body" [items]="vendorOrderDays"
                                                bindLabel="NameOfDay" bindValue="Id" placeholder="Select day"
                                                [(ngModel)]="schedule.OrderReceiveDayId"
                                                [posValidate]="validateSchedule(index)"
                                                (change)="createScheduleRow(last,index)"
                                                name="receiveOn_{{index+updatedIndex}}"
                                                #receiveOn_{{index+updatedIndex}}="ngModel">
                                            </ng-select>
                                    </pos-form-field>
                                </pos-column>
                                <pos-column xs="1" class="col-50 pt-pix-13" *ngIf="!last">
                                    <pos-action-icon [icon]="icons.deleteWhite"
                                        (click)="schedule.OrderReceiveDayId = null; deleteScheduleRow(index)">
                                    </pos-action-icon>
                                </pos-column>
                            </pos-row>
                        </div>
                    </pos-column>
                    <pos-column>
                        <pos-form-field>
                            <p-checkbox name="chkActive" #chkActive="ngModel" [(ngModel)]="vendor.active" binary="true"
                                label="Active">
                            </p-checkbox>
                        </pos-form-field>
                    </pos-column>
                    <pos-column>
                        <pos-form-field label="Typical Order Lead Time"
                            [validationMessages]="{range: 'Typical Order Lead Time should be between 0 - 365.'}">
                            <input posInput type="number" min="0" max="365" name="typicalLeadTime"
                                class="form-control col-120" [(ngModel)]="vendor.TypicalLeadTime" [range]="[0, 365]" />
                        </pos-form-field>
                    </pos-column>
                </pos-row>
            </div>
            <div class="col-12 action-button-container page-footer">
                <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save</button>
                <button type="button" class="btn-secondary  btn-lg btn btn-cancel pos-button"
                    (click)="cancel()">Cancel</button>
            </div>
        </context-provider>
    </form>
</pos-edit-wrapper>
