<pos-edit-wrapper heading="Flush Cache" [headingIcon]="icons.flushCache" (close)="onCancel()" [isPopup]="false">
  <div class="col-xs-12 border-top">
    <div class="col-xs-12 pos-page-body">
      <div class="col-md-2 text-left pt-pix-10 px-pix-5">
        <button class="btn bg-theme-separator normal white-hover pos-button text-nowrap" type="submit" (click)="refreshCache()">Refresh All
          Entities</button>
      </div>
    </div>
  </div>
</pos-edit-wrapper>
