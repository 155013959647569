import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { Observable } from 'rxjs';
import { InventoryProductIngredientModel, InventoryProductRawIngredientModel } from 'src/app/orders';
@Injectable()
export class InventoryProductIngredientsService {

    constructor(private httpService: HttpClient) {
    }

    newIngredient(): InventoryProductRawIngredientModel {
        const ingredient = {
            Id: 0,
            InventoryProductId: 0,
            RawIngredientId: 0,
            ParentIngredientId: null,
            LessThan2Percent: false,
            Ordinal: 0,
            InventoryProduct: null,
            RawIngredient: null,
            ParentIngredient: null,
            IngredientHierarchy: '',
            ParentIngredientHierarchy: '',
            OldRawIngredientName: '',
            OrdinalString: '000',
            Level: 0,
            OldOrdinalString: ''
        };
        return ingredient;
    }

    getIngredientsByInventoryProductId(inventoryProductId: number): Observable<InventoryProductIngredientModel[]> {
        return this.httpService.get<InventoryProductIngredientModel[]>
            (`${RuntimeConstants.API_PATH}inventoryproductingredients/` + 'ingredients/' + inventoryProductId);
    }
    saveIngredients(ingredient: InventoryProductIngredientModel[]) {
        return this.httpService.post(
            `${RuntimeConstants.API_PATH}inventoryproductingredients`, ingredient
        );
    }
    updateIngredientsOrdinal(ingredient: InventoryProductIngredientModel[]): Observable<any> {
        return this.httpService.put(
            `${RuntimeConstants.API_PATH}inventoryproductingredients/ordinal`,
            ingredient
        );
    }
    deleteIngredient(inventoryProductIngredientId: number): Observable<any> {
        return this.httpService.delete(
            `${RuntimeConstants.API_PATH}inventoryproductingredients/` + inventoryProductIngredientId
        );
    }

    deleteIngredients(ingredients): Observable<any> {
        return this.httpService.post(
            `${RuntimeConstants.API_PATH}inventoryproductingredients/delete-ingredients`, ingredients
        );
    }
}
