import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { Observable } from 'rxjs';
import { OrderDietaryRestriction } from '../interface/order-dietary-restriction';

@Injectable({
    providedIn: 'root'
})
export class OrderDietaryRestrictionService {

    constructor(private httpService: HttpClient) { }

    getOrderDietaryRestrictionByOrderId(orderId): Observable<any> {
        return this.httpService.get(`${RuntimeConstants.API_PATH}orderdietaryrestriction/` + orderId);
    }

    saveOrderDietaryRestriction(orderDietaryRestriction: OrderDietaryRestriction): Observable<any> {
        return this.httpService.post(`${RuntimeConstants.API_PATH}orderdietaryrestriction`, orderDietaryRestriction);
    }

    deleteOrderDietaryRestriction(orderDietaryRestrictionId: number): Observable<any> {
        return this.httpService.delete(
            `${RuntimeConstants.API_PATH}orderdietaryrestriction/` + orderDietaryRestrictionId
        );
    }
}
