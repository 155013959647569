import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { WhitelistedIpAddress } from '../../interface/whitelisted-ip-address';
import { AlertsService, SpinnerService, Messages, FieldInfoMessages, DomainConstants, FormUtilityService, BaseCrudModelComponent } from 'src/app/shared';
import { WhitelistedIpAddressService } from '../../services/whitelisted-ip-address.service';
import { finalize } from 'rxjs/operators';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'pos-whitelisted-ip-address-edit',
  templateUrl: './whitelisted-ip-address-edit.component.html',
})
export class WhitelistedIpAddressEditComponent extends BaseCrudModelComponent<WhitelistedIpAddress> implements OnInit {

  @Input('id') id: number;
  addressType = '0';
  addressTypes = DomainConstants.WhitelistedAddressTypes;
  ipRangeFrom: string;
  ipRangeTo: string;
  whitelistedAddress: WhitelistedIpAddress;
  fieldInfoMessages = FieldInfoMessages;
  @ViewChild('whitelistedIpForm') whitelistedIpForm: NgForm;
  get getForm(): NgForm {
    return this.whitelistedIpForm
  }

  constructor(protected whitelistedAddressService: WhitelistedIpAddressService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    formUtilityService: FormUtilityService) {
    super(whitelistedAddressService, alertService, spinnerService, formUtilityService);
    this.whitelistedAddress = whitelistedAddressService.newWhitelistedIp();
  }

  ngOnInit() {
    this.saveSuccessMessage = Messages.WhitelistedIpSaveSuccess;
  }

  loadDependencies(): void {
    if (this.id > 0) {
      this.spinnerService.show();
      this.whitelistedAddressService.get(this.id)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: response => {
            this.whitelistedAddress = response;
            const ipRange = this.whitelistedAddress.IpAddress.split('-');
            this.ipRangeFrom = ipRange[0] ? ipRange[0] : '';
            this.ipRangeTo = ipRange.length > 1 ? ipRange[1] : '';
            this.addressType = ipRange.length > 1 ? '1' : '0';
          }, error: this.alertService.showApiError
        });
    }
  }

  saveWhitelistedIps(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.ipRangeTo = this.addressType === '0' ? '' : this.ipRangeTo;
    this.whitelistedAddress.IpAddress = this.ipRangeFrom +
      (this.ipRangeTo !== undefined && this.ipRangeTo !== '' ? '-' + this.ipRangeTo : '');
    this.save(this.whitelistedAddress);
  }

  onSaveSuccess(model: WhitelistedIpAddress): void {
    this.hide({ shouldReload: true });
  }

  cancelWhitelistedIpAddress() {
    this.hide({});
  }
}
