import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { GridColumn, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { ParsedIngredient } from 'src/app/information-management/raw-ingredients/interface/parsed-ingredient';
import { ICloseable } from 'src/app/shared';
import { check } from './../../../../shared/components/icon/icons';
declare let $: any;
@Component({
  selector: 'pos-import-ingredient-preview',
  templateUrl: './import-ingredient-preview.component.html',
})
export class ImportIngredientPreviewComponent implements OnInit, ICloseable {
  close: EventEmitter<any> = new EventEmitter();
  inventoryProductColumns: Array<GridColumn> = [];
  @ViewChild('chkTemplate', {static: true}) private chkTemplate: any;
  @Input() ingredients: Array<ParsedIngredient> = [];
  screenHeight = '300px';
  public icons = {
    check
  };
  constructor() { }

  ngOnInit(): void {
    this.columnConfiguration();
    this.screenHeight = $(window).height() > 776 ? ($(window).height() - 560) + 'px' : ($(window).height() - 510) + 'px';
  }

  private columnConfiguration() {

    const chkColumn = new TemplateColumn({
      itemTemplate: this.chkTemplate,
      Width: '5%',
      TextAlign: TextAlign.Center,
      HeaderText: '< 2%'
    });

    this.inventoryProductColumns = [
      new GridColumn({ HeaderText: 'Raw Ingredient', Field: 'Name', IsSortable: false, Width: '15%' }),
      new GridColumn({ HeaderText: 'Parent Ingredient', Field: 'Parent', IsSortable: false, Width: '15%' }),
      chkColumn
    ] as Array<GridColumn>;
  }

  onCancel = () => {
    this.close.emit();
  }

  importIngredients = () => {
    this.close.emit({shouldImport: true});
  }

}
