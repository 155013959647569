import { environment } from 'src/environments/environment';

const protocol = window.location.protocol;
const port = window.location.port;
const hostname = window.location.hostname + ':' + port;
const host = window.location.hostname;

const staticConstants = {
    promisesMsg: [],
    API_VERSION: '1.0',
    HostName: protocol + '//' + hostname,
    ShiftDuties: true,
    ORDER_STATUS_NONE: 0,
    ORDER_STATUS_SENT: 1,
    ORDER_STATUS_VOIDED: 2,
    ORDER_STATUS_DELETED: 3,
    ORDER_STATUS_DISCOUNT: 4,
    ORDER_STATUS_PAID: 5,
    ORDER_STATUS_TIMED: 6,
    ORDER_STATUS_REFUNDED: 7
};

export const RuntimeConstants = {
    ...staticConstants,
    ...environment,
    ...{
        API_PATH: `${environment.api.basePath}${environment.api.url}`,
        IMAGE_BASE_PATH: `${environment.api.basePath}/images`,
        FONT_BASE_PATH: `${environment.api.basePath}/fonts`,
        MEDIA_BASE_PATH: `${environment.api.basePath}/media`
    },
    API_PATH: `${environment.api.basePath}${environment.api.url}`
};
