import { MessagePayload } from "./message-payload";

export class CloseReviewOrderModalPayload extends MessagePayload {
    public IsConfirmed: boolean;

    constructor(isConfirmed: boolean) {
        super();
        this.IsConfirmed = isConfirmed;
    }
}
