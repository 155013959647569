<pos-edit-wrapper heading="Subscribe {{reportName}} report" (close)="closeModal()">
    <form #reportSubscriptionForm="ngForm" class="edit-form"
        (ngSubmit)="subscribeToReport(reportSubscriptionForm.valid)">
        <div class="col-12 pos-modal-body">
            <div class="subscription-header row px-pix-5 pt-pix-10">
                <div class="col-100 ps-0">
                    <label class="edit-form__section__label"> Subscription</label>
                </div>
                <div class="col-md-8 col-sm-8 col-8">
                    <div class="form-inline row text-left">
                        <div class="btn-group col-150 me-pix-10">
                            <label class="btn btn-default col-75 btn-radio"
                                [ngClass]="reportSubscriptionParameter.IsActive ?'active btn-primary normal': 'btn-secondary normal'  ">
                                <input [(ngModel)]="reportSubscriptionParameter.IsActive" [value]="true" type="radio"
                                    name="active">
                                Active
                            </label>
                            <label class="btn btn-default col-75 btn-radio"
                                [ngClass]="!reportSubscriptionParameter.IsActive ?'active btn-danger normal': 'btn-secondary normal'">
                                <input [(ngModel)]="reportSubscriptionParameter.IsActive" [value]="false" type="radio"
                                    name="inactive">
                                Inactive
                            </label>
                        </div>
                        <div class="col-12 col-sm-6 label-section"
                            *ngIf="reportSubscriptionParameter.IsActive">
                            <label>
                                <p-checkbox name="isSendEmptyResults"
                                    [(ngModel)]="reportSubscriptionParameter.SendEmptyResults" binary="true">
                                </p-checkbox> Send empty results
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-12 p-0 pt-pix-10 table-height overflow-auto"
                    *ngIf="reportSubscriptionParameter.IsActive">
                    <ng-table [data]="reportSubscriptionParameter.ReportSubscriptionSchedules"
                        [columns]="scheduleColumns" [search]="false" [paginator]="false"></ng-table>
                    <div class="edit-form pt-pix-10" *ngIf="isEditItem">
                        <div class="edit-form__section mb-0">
                            <h3 class="edit-form__section__heading">
                                <span *ngIf="editData.Id > 0"> Edit schedule #{{editData.Index + 1}}</span>
                                <span *ngIf="editData.Id === 0"> Add new schedule </span>
                                <a class="float-end" (click)="isEditItem = false" title="Close">
                                    <pos-icon [icon]="icons.times"></pos-icon>
                                </a>
                            </h3>
                            <div *ngFor="let param of reportParameterList; let index = index;">
                                <pos-row *ngIf="param.AllowUserInput">
                                    <pos-column>
                                        <pos-form-field [label]="param.DataType == 'BIT' ? '' : param.Label"
                                            inputStyle="col-12 pe-0 row">
                                            <span *ngIf="param.DataType=='DATE'" class="col-12 row pe-0 mx-0 ">
                                                <input type="number" name="parameterValue{{index}}"
                                                    id="parameterValue{{index}}" #dateParam="ngModel"
                                                    class="form-control col-120 col-2"
                                                    [(ngModel)]="param.InitialValue" />
                                                <span class="col-140 col-2 ">
                                                    <ng-select [items]="reportDateRangeType" bindLabel="Text"
                                                        bindValue="Value" [(ngModel)]="param.RangeType" name="rangeType"
                                                        appendTo="body" [clearable]="false">
                                                    </ng-select>
                                                </span>
                                                <span class="col-120 pe-0 col-2 ps-0">
                                                    <ng-select [items]="reportDateRangeOffsetType" bindLabel="Text"
                                                        bindValue="Value" [(ngModel)]="param.RangeOffsetType"
                                                        name="rangeOffsetType" appendTo="body" [clearable]="false">
                                                    </ng-select>
                                                </span>
                                                <label class="edit-form__section__label col-1"
                                                    *ngIf="param.RangeType==='d'">today</label>
                                                <label class="edit-form__section__label col-1"
                                                    *ngIf="param.RangeType=='h' || param.RangeType==='m'">now</label>
                                            </span>

                                            <span *ngIf="param.DataType=='INT'">
                                                <span *ngIf="param.QueryValues">
                                                    <ng-select posInput [items]="param.ParameterQueryValues"
                                                        bindLabel="Name" bindValue="Id" [(ngModel)]="param.InitialValue"
                                                        name="selectInt" appendTo="body" [clearable]="false"
                                                        placeholder="Select {{param.Label}}"></ng-select>
                                                </span>
                                                <span *ngIf="!param.QueryValues">
                                                    <input posInput class="form-control"
                                                        [(ngModel)]="param.InitialValue" type="text" name="paramInt" />
                                                </span>
                                            </span>
                                            <span *ngIf="param.DataType=='CSV'">
                                                <ng-select posInput [items]="param.ParameterQueryValues"
                                                    bindLabel="Name" bindValue="Id" [(ngModel)]="param.InitialValue"
                                                    name="selectCsv" appendTo="body" [clearable]="false"></ng-select>
                                            </span>
                                            <span *ngIf="param.DataType=='BIT'">
                                                <p-checkbox posInput name="paramCheckbox{{index}}"
                                                    [(ngModel)]="param.InitialValue" binary="true"
                                                    [label]="param.Label">
                                                </p-checkbox>
                                            </span>
                                            <span *ngIf="param.DataType=='TEXT'">
                                                <input posInput class="form-control" [(ngModel)]="param.InitialValue"
                                                    type="text" name="paramText" />
                                            </span>
                                            <span *ngIf="param.DataType=='DROPDOWN'">
                                                <ng-select posInput [items]="param.ParameterQueryValues"
                                                    bindLabel="Name" bindValue="Id" [(ngModel)]="param.InitialValue"
                                                    placeholder="Select {{param.Label}}" name="selectDropdown"
                                                    appendTo="body" [clearable]="false">
                                                </ng-select>
                                            </span>
                                            <span *ngIf="param.DataType=='MULTISELECT'">
                                                <ng-select posInput [items]="param.ParameterQueryValues"
                                                    [multiple]="true" [hideSelected]="true" bindValue="Id"
                                                    bindLabel="Name" placeholder="Select {{param.Label}}"
                                                    [(ngModel)]="param.InitialValue" name="multiselect" appendTo="body"
                                                    [clearable]="false">
                                                </ng-select>
                                            </span>
                                        </pos-form-field>
                                    </pos-column>
                                </pos-row>
                            </div>
                            <pos-row>
                                <pos-column>
                                    <pos-form-field label="Email Subject" inputStyle="col-md-5 col-12">
                                        <input posInput class="form-control" [(ngModel)]="editData.EmailSubject"
                                            type="text" name="emailSubject" />
                                    </pos-form-field>
                                </pos-column>
                            </pos-row>
                            <pos-row>
                                <pos-column>
                                    <pos-form-field label="Time to report" inputStyle="col-12">
                                        <app-cron-editor [cronStartingValue]="editData.Schedule"
                                            (cronChange)="onCronChange($event)" [options]="cronOptions">
                                        </app-cron-editor>
                                        <span *ngIf="!isCronValid && reportSubscriptionForm.submitted">
                                            <label class="red-extreme">Please
                                                enter valid cron expression
                                            </label>
                                        </span>
                                    </pos-form-field>
                                </pos-column>
                            </pos-row>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button class="btn btn-primary btn-lg btn-save pos-button" type="submit" scrollup>
                <pos-icon [icon]="icons.runReport"></pos-icon> Save
            </button>
            <button type="button" class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="closeModal()">
                <pos-icon [icon]="icons.reportBan"></pos-icon> Cancel
            </button>
        </div>
        <div class="clearfix"></div>
    </form>
</pos-edit-wrapper>
<ng-template let-data="rowData" #scheduleTemplate>
    Schedule #{{ data.Index + 1 }} runs
    {{ getCronStrue(data.Schedule)}}
</ng-template>
