import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';
import { ShakeFlavorGroup } from '../../interfaces/shake-flavor-group';

@Injectable({
  providedIn: 'root'
})
export class ShakeFlavorGroupService extends BaseCrudService<ShakeFlavorGroup> {

  constructor(httpClient: HttpClient) {
    super('shake-flavor-group', httpClient);
  }
}
