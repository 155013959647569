<pos-list-wrapper [headingIcon]="icons.trainingBadge" heading="Training Badges" (close)="close()"
    permissions='[{"Name":"{{permission.name}}", "Level":"{{permission.readOnlyLevel}}"}]'>
    <ng-table #trainingBadgeGrid [columns]="trainingBadgeColumns" [exportFilename]="'training-badges'"
        [data]="trainingBadges">
    </ng-table>
    <ng-template let-data="rowData" #iconTemplate>
        <span *ngIf="(data.Icon !== null && data.Icon !== '')">
            <img class="image-size" src="{{imagePath}}/training-badge-icon/{{data.Icon}}" height="50px"
                width="50px" atl=""/>
        </span>
    </ng-template>
    <pos-confirm-delete [message]="confirmDeleteMessage" (onConfirmAcknowledge)="onConfirmAcknowledge()"
        #confirmTrainingBadgeDelete>
    </pos-confirm-delete>
</pos-list-wrapper>
<pos-print-table #printTableReference [hidden]="true"></pos-print-table>
