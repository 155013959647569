import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { Observable } from 'rxjs';
import { HardwareModel, SettingParam } from 'src/app/shared';

@Injectable()
export class PrintingService {

    printingApiUrl = '';
    constructor(private httpService: HttpClient) {
        this.printingApiUrl = RuntimeConstants.API_PATH + 'printing';
    }

    getHardwaresOfType(hardwareType: string): Observable<Array<HardwareModel>> {
        return this.httpService.get<Array<HardwareModel>>(`${this.printingApiUrl}/` + hardwareType + '/HardwareType');
    }

    testLabelPrint(labelPrinterId: number, terminalId: number) {
        return this.httpService.post(`${this.printingApiUrl}/labelPrint/${labelPrinterId}/${terminalId}`,
            {'labelPrinterId': labelPrinterId, 'terminalId': terminalId });
    }

    getPrintingDetail(terminalId): Observable<SettingParam> {
        return this.httpService.get<SettingParam>(`${this.printingApiUrl}/${terminalId}`);
    }

    savePrinting(params): Observable<SettingParam> {
        return this.httpService.post<SettingParam>(this.printingApiUrl, params);
    }
}
