import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DomainConstants } from 'src/app/shared/constants';
import { BaseCrudService } from 'src/app/shared/services';
import { DataSourceSubscribe } from '../interface/data-source-subscribe';
import { DataSubscriptionParameter } from '../interface/data-subscription-parameter';

@Injectable({
  providedIn: 'root',
})
export class DataSourceSubscribeService extends BaseCrudService<DataSourceSubscribe> {
  constructor(private httpClient: HttpClient) {
    super('data-subscription', httpClient);
  }

  newDataSourceSubscribe() {
    const data: DataSourceSubscribe = {
      Id: 0,
      Schedule: '',
      IsActive: true,
      DestinationParameter: null,
      ExportConnectionId: null,
      SendEmptyResults: false,
      DataSourceId: null,
      DataSource: null,
      FileName: '',
      ExportFormat: null,
      IsAppendTimeStampToFileName: true,
      ExportType: DomainConstants.ExportTypes.Auto,
      IsSourceReturnsJson: true
    };
    return data;
  }

  saveDataSubscriptionWithParameters(dataSubscriptionSaveModel: {
    DataSubscription: DataSourceSubscribe;
    DataSubscriptionParameters: Array<DataSubscriptionParameter>;
  }) {
    return this.httpClient.post(`${this.apiEndpoint}`, dataSubscriptionSaveModel);
  }

  getAllSubscriptions(): Observable<Array<DataSourceSubscribe>> {
    return this.httpClient.get<Array<DataSourceSubscribe>>(`${this.apiEndpoint}/subscriptions`);
  }

  executeDataSourceSubscription(dataSourceSubscriptionId: number) {
    return this.httpClient.post(`${this.apiEndpoint}/${dataSourceSubscriptionId}/execute`, null);
  }
}
