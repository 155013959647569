import { Format } from './format-type';
import { GridColumn } from './grid-column';
import { GridColumnTypes } from './grid-column-types';
import { TextAlign } from './text-align';


export class DateColumn extends GridColumn {

    FormatString: string;
    Format: Format = Format.DateTime;
    get Type(): string {
        return GridColumnTypes.DateColumn;
    }

     constructor(init?: Partial<DateColumn>) {
        super();
        this.TextAlign = TextAlign.Center;
        this.CellClass = 'cell-date';

        Object.assign(this, init);
    }
}
