import { codeBranch, rawIngredientSalesProduct, rawIngredientInventoryProductStore, barsWhite, inventoryRawIngredient} from './../../../../shared/components/icon/icons';
import { SalesProductsIngredientComponent } from './../sales-products-ingredient/sales-products-ingredient.component';
import { InventoryProductsIngredientComponent } from './../inventory-products-ingredient/inventory-products-ingredient.component';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { RawIngredientsService } from '../../service';
import { AlertsService, SpinnerService, ModalService, ConfirmDeleteComponent,
   AuthenticationService, Permissions, Levels, BaseGridComponent, ApplicationStateService, PrintTableService } from 'src/app/shared';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { RawIngredient } from '../../interface';
import { GridColumn, EditColumn, LinkColumn, DeleteColumn, TableComponent } from '@tarktech/tark-ng-utils';
import { MergeRawIngredientComponent } from '../merge-raw-ingredient/merge-raw-ingredient.component';
import { ActivatedRoute, Router } from '@angular/router';
import { LinkTemplateColumn } from '@tarktech/tark-ng-utils/table/link-template-column';

@Component({
  selector: 'pos-raw-ingredients-list',
  templateUrl: './raw-ingredients-list.component.html',
})
export class RawIngredientsListComponent extends BaseGridComponent implements OnInit {
  @ViewChild('confirmButtonDelete') confirmButtonDelete: ConfirmDeleteComponent;
  message: string = Messages.ConfirmDeleteRawIngredient;
  rawIngredientList: Array<RawIngredient> = [];
  selectedRawIngredient: RawIngredient;
  rawIngredientsColumns: Array<GridColumn> = [];
  rawIngredientsPrintColumns: Array<GridColumn> = [];
  ingredientId = 0;
  loaded = false;
  modeName: string;
  popup: any;
  @ViewChild('rawIngredientGrid', {static: true}) rawIngredientGrid: TableComponent;

  public icons = {
    codeBranch,
    rawIngredientSalesProduct,
    rawIngredientInventoryProductStore,
    barsWhite,
    inventoryRawIngredient
  };

  permission = {
    name: Permissions.InventoryRawIngredients,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };

  @ViewChild('popoverforingredient', {static: true}) popoverforingredient: TemplateRef<any>;
  constructor(
    private rawIngredientService: RawIngredientsService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private modalService: ModalService,
    protected route: ActivatedRoute,
    private router: Router,
    protected applicationStateService: ApplicationStateService,
    private authenticationService: AuthenticationService,
    protected printService: PrintTableService,
  ) {
    super(applicationStateService, route);
    this.getRawIngredients();
  }

  ngOnInit() {
    this.configureGridColumns();
    this.rawIngredientGrid.context = this.gridContext;
  }

  configureGridColumns() {
    const editColumn = new EditColumn({
      Clicked: (data) => {
        this.editRawIngredient(data);
      },
      Width: '57px',
      AddIcon: new LinkColumn({
        Clicked: () => {
          this.addRawIngredient();
        },
        ToolTip: 'New'
      })
    });

    const deleteColumn = new DeleteColumn({
      Clicked: (data) => {
        this.deleteRawIngredient(data);
      },
      Width: '67px',
      PrintIcon: new LinkColumn({
        Clicked: () => {
          this.printData();
        }
      })
    });

    const popoverIcon = new LinkTemplateColumn({
      CellClass: 'cell-link greyicon', itemTemplate: this.popoverforingredient, Width: '0'
    });

    this.rawIngredientsColumns = [
      new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '93%' })
    ] as Array<GridColumn>;

    this.rawIngredientsPrintColumns = [...this.rawIngredientsColumns];
    if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      this.rawIngredientsColumns.push(editColumn);
      this.rawIngredientsColumns.push(deleteColumn);
    }

    this.rawIngredientsColumns.push(popoverIcon);
  }
  onLinkPopOver(data: RawIngredient, popup: any) {
    this.selectedRawIngredient = data;
    this.popup = popup;
  }
  getRawIngredients() {
    this.spinnerService.show();
    this.rawIngredientService.getRawIngredientsList().subscribe(
      (response: RawIngredient[]) => {
        if (response) {
          this.rawIngredientList = response;
        } else {
          this.rawIngredientList = [];
        }
      },
      this.alertService.showApiError,
      () => {
        this.spinnerService.hide();
      }
    );
  }

  onConfirmAcknowledge() {
    this.spinnerService.show();
    this.rawIngredientService.deleteRawIngredient(this.ingredientId).subscribe(
      () => {
        this.alertService.renderSuccessMessage(
          Messages.RawIngredientsDelete
        );
        this.getRawIngredients();
      },
      this.alertService.showApiError,
      () => {
        this.spinnerService.hide();
      }
    );
  }

  deleteRawIngredient(data) {
    this.ingredientId = data.Id;
    this.confirmButtonDelete.Show(this.message);
  }

  editRawIngredient(data) {
    this.router.navigate([data.Id], { relativeTo: this.route});
  }

  addRawIngredient() {
    this.router.navigate([0], { relativeTo: this.route});
  }

  inventoryProductsIngredient(id) {
    const inventoryProductsModalRef = this.modalService.show(InventoryProductsIngredientComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        rawIngredientId: id
      }
    });

    inventoryProductsModalRef.close.subscribe(res => {
      if (res && res.shouldReload) {

      }
    });
    this.popup.hide();
  }

  salesProductsIngredient(id) {
    const salesProductsModalRef = this.modalService.show(SalesProductsIngredientComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        rawIngredientId: id
      }
    });

    salesProductsModalRef.close.subscribe(res => {
      if (res && res.shouldReload) {

      }

    });
    this.popup.hide();
  }

  mergeProductIngredient(ingredient) {
    const mergeProductModalRef = this.modalService.show(MergeRawIngredientComponent, {
      animated: false,
      class: 'vertical-center modal-lg',
      initialState: {
        ingredient: ingredient
      }
    });

    mergeProductModalRef.close.subscribe(res => {
      if (res && res.shouldReload) {
        this.getRawIngredients();
      }
    });
    this.popup.hide();
  }

  printData() {
    this.printService.printEmitter.next({ gridColumns: this.rawIngredientsPrintColumns, gridData: this.rawIngredientList });
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

}
