import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { tablesSolarPanel, accountOrdersShoppingCart } from 'src/app/shared/components/icon';
import { AccountOrder } from '../../interface/account-order';
import { ICloseable, SpinnerService, AlertsService, ApplicationStateService } from 'src/app/shared';
import { AccountService } from 'src/app/shared/services/account.service';
import { finalize } from 'rxjs/operators';
import { AccountDetailsPrint } from '../../interface/account-details-print';
declare let $: any;
@Component({
  selector: 'pos-account-detail-view',
  templateUrl: './account-detail-view.component.html',
})
export class AccountDetailViewComponent implements OnInit, ICloseable {
  close: EventEmitter<any> = new EventEmitter();

  @Input() accountDetails: any;
  @Input() accountId: number = 0;
  accountOrderDetails: AccountOrder[] = [];
  public icons = {
    tablesSolarPanel,
    accountOrdersShoppingCart
  };
  tabList = {
    Account: 'Account',
    Orders: 'Orders'
  };
  selectedTab = this.tabList.Account;
  constructor(private spinnerService: SpinnerService,
    private accountService: AccountService,
    private alertService: AlertsService,
    private applicationStateService: ApplicationStateService) { }

  ngOnInit() {
    if (this.accountDetails && this.accountDetails.LoyaltyCardNumber) {
      const loyaltyCard = this.accountDetails.LoyaltyCardNumber.toString();
      if (loyaltyCard.length >= 4) {
        this.accountDetails.LoyaltyCardNumber = 'XXXX XXXX XXXX ' + loyaltyCard.substr(loyaltyCard.length - 4);
      } else {
        this.accountDetails.LoyaltyCardNumber = 'XXXX XXXX XXXX ' + loyaltyCard;
      }
    }
  }

  onTabChange(isLoadOrder: boolean) {
    if (isLoadOrder) {
      this.spinnerService.show();
      this.accountService.getCustomerAccountOrders(this.accountId)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res) => {
            this.accountOrderDetails = res ? res : [];
          }, error: this.alertService.showApiError
        });
    } else {
      this.accountOrderDetails = [];
    }
  }

  public getHeight(isOuterContainer: boolean): string {
    return $(window).height() - 290 + 'px';
  }

  onPrintAccountDetails() {
    const accountDetailPrint: AccountDetailsPrint = {
      AccountId: this.accountId,
      TerminalId: this.applicationStateService.terminalId,
      TerminalName: this.applicationStateService.terminalName
    };
    this.spinnerService.show();
    this.accountService.printAccountDetails(accountDetailPrint)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
        }, error: this.alertService.showApiError
      });
  }

  onCancel() {
    this.close.emit({ shouldReload: false });
  }

}
