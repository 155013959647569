import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { filter, forEach } from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Vendor, VendorService } from 'src/app/information-management/vendors';
import { SpinnerService } from 'src/app/shared/components';
import { Messages } from 'src/app/shared/constants';
import { InventoryProductDetails } from 'src/app/shared/interface';
import { AlertsService, ApplicationStateService, InventoryProductService } from 'src/app/shared/services';
import { MarkAsOrderedInventoryProduct, MarkAsOrderedModel } from '../../interfaces/mark-as-ordered';
import { checkCircleGrey, times } from 'src/app/shared/components/icon';
import { GridColumn, TableComponent, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { Router } from '@angular/router';


@Component({
  selector: 'pos-inventory-batch-mark-as-ordered',
  templateUrl: './inventory-batch-mark-as-ordered.component.html',
  styleUrls: ['./inventory-batch-mark-as-ordered.component.scss']
})
export class InventoryBatchMarkAsOrderedComponent implements OnInit {

  vendors: Array<Vendor> = [];
  selectedVendor = null;
  height = window.innerHeight - 320;
  inventoryProducts: Array<InventoryProductDetails> = [];
  batchInventoryProducts: Array<MarkAsOrderedInventoryProduct> = [];
  batchInventoryProductsColumns: Array<GridColumn> = [];
  selectedProduct = 0;
  badgeBgColor: string;
  badgeFontColor: string;
  checkAll = false;
  icons = { times, checkCircleGrey };

  @ViewChild('chkInventoryProducts', { static: true }) chkInventoryProducts: TemplateRef<any>;
  @ViewChild('chkAllInventoryProducts', { static: true }) chkAllInventoryProducts: TemplateRef<any>;
  @ViewChild('batchInventoryProductTable', { static: true }) batchInventoryProductTable: TableComponent;
  constructor(private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private vendorService: VendorService,
    private inventoryProductService: InventoryProductService,
    private applicationStateService: ApplicationStateService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.batchInventoryProductsColumns = [...this.configureColumns()];
    this.loadData();
  }

  configureColumns() {
    return [
      new TemplateColumn({
        headerTemplate: this.chkAllInventoryProducts, itemTemplate: this.chkInventoryProducts,
        Width: '38px', CellClass: "mt-5 pti-0 pbi-0", TextAlign: TextAlign.Center
      }),
      new GridColumn({ HeaderText: 'Product', Field: 'InventoryProductName', Width: '65%' }),
      new GridColumn({ HeaderText: 'Unit Qty', Field: 'UnitQuantity', Width: '11%' }),
      new GridColumn({ HeaderText: 'Unit', Field: 'Unit', Width: '11%' }),
      new GridColumn({ HeaderText: 'Calculated Qty', Field: 'CalculatedQuantity', Width: '11%' }),
    ];
  }

  loadData() {

    const inventoryProductObservables: Array<Observable<any>> = [];
    inventoryProductObservables.push(this.vendorService.getVendorList());
    inventoryProductObservables.push(this.inventoryProductService.getActiveInventoryProducts(true));
    this.spinnerService.show();

    forkJoin(inventoryProductObservables)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: ([vendors, inventoryProducts]: [Array<Vendor>, Array<InventoryProductDetails>]) => {
          this.vendors = vendors ? vendors : [];
          this.vendors = filter(this.vendors, (vendor) => vendor.active);
          this.inventoryProducts = inventoryProducts ? inventoryProducts.filter(x => !x.IsPrepRecipe) : [];
        },
        error: this.alertService.showApiError
      });
  }

  vendorSelected() {
    this.batchInventoryProducts = [];
    this.checkAll = false;
    this.selectedProduct = 0;
    this.batchInventoryProductTable.context.PageNumber = 1;
    if (this.selectedVendor) {
      const products = filter(this.inventoryProducts, x => x.Vendor === this.selectedVendor && !x.IsOnOrder);
      if (products.length) {
        forEach(products, product => {
          const newInventoryProduct: MarkAsOrderedInventoryProduct = {
            InventoryProductId: product.Id,
            InventoryProductName: product.Name,
            IsOnOrder: false,
            CalculatedQuantity: product.CalculatedQty,
            UnitQuantity: product.UnitQty,
            Unit: product.UnitName
          };
          this.batchInventoryProducts.push(newInventoryProduct);
        });
      } else {
        this.batchInventoryProducts = [];
      }
    } else {
      this.batchInventoryProducts = [];
      this.selectedProduct = this.batchInventoryProducts.filter(x => x.IsOnOrder).length;
    }
  }

  markProductsAsOrdered() {
    const changedProducts = filter(this.batchInventoryProducts, x => x.IsOnOrder);
    if (!changedProducts.length) {
      this.alertService.renderErrorMessage(Messages.SelectAtLeastOneProduct);
      return;
    }
    const markProductsAsOrdered: MarkAsOrderedModel = {
      InventoryProductIds: changedProducts.map(x => x.InventoryProductId),
      UserId: this.applicationStateService.userId
    };
    this.spinnerService.show();
    this.inventoryProductService.markInventoryProductsAsOrdered(markProductsAsOrdered)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.selectedVendor = null;
          this.checkAll = false;
          this.batchInventoryProducts = [];
          this.selectedProduct = 0;
          this.batchInventoryProductTable.context.Filter = '';
          this.batchInventoryProductTable.ptable.filterGlobal('', 'contains');
          this.alertService.renderSuccessMessage(Messages.ProductsMarkedAsOrdered);
          this.loadData();
        }, error: this.alertService.showApiError
      });
  }

  onFilterChanged() {
    this.checkAll = false;
  }

  checkAllProducts() {
    if (this.checkAll) {
      if (this.batchInventoryProductTable.context.Filter) {
        this.batchInventoryProductTable.ptable.filteredValue.forEach(x => x.IsOnOrder = true);
      } else {
        this.batchInventoryProducts.forEach(x => x.IsOnOrder = true);
      }
    } else {
      this.batchInventoryProducts.forEach(x => x.IsOnOrder = false);
    }
    this.selectedProduct = this.batchInventoryProducts.filter(x => x.IsOnOrder).length;
  }

  isOnOrderChanged(isOnOrder) {
    if (this.checkAll && !isOnOrder) {
      this.checkAll = false;
    }
    this.selectedProduct = this.batchInventoryProducts.filter(x => x.IsOnOrder).length;
  }


  onCancel() {
    this.router.navigate(['manage/console']);
  }
}
