<div class="pt-0 d-flex message-buttons">
    <div class="pe-pix-10 mi-0">
        <p-checkbox (onChange)="onChangeReadMessage()" name="unreadMessages" #Active="ngModel"
            [(ngModel)]="showUnreadMessages" binary="true" label="Unread Only" labelStyleClass="font-weight-600" />
    </div>
    <button type="button" class="btn btn-orange compose-message pos-button mt-1 mb-1 me-1 text-nowrap" (click)="replyMessage(0)">
        <pos-icon [icons]="[icons.composeEnvelopeStack,icons.composeCircleStack,icons.composePlusStack]"
            class="pe-pix-3 pt-pix-2"></pos-icon> Compose
    </button>
    <button type="button" class="btn btn-primary white pos-button text-nowrap" (click)="markAllAsRead()">
        <pos-icon [icons]="[icons.composeEnvelopeOpenStack,icons.markAllCircleStack, icons.markAllCheckStack]"
            class="pe-pix-3 pt-pix-1"></pos-icon>Mark
        All as Read
    </button>
</div>
<div class="table-responsive  order-page__message-modal__inbox mb-pix-10 pb-0 mi-0">
    <ng-table [columns]="inboxMessageColumns" [data]="inboxMessageList" [totalRecords]="totalRecords"
        [rows]="filterConfiguration.PageSize" [rowClass]="'RowClass'" class="inbox-message" [scrollable]="true"
        [scrollHeight]="height-114 +'px'" [rowsPerPageOptions]="[8,10,20]" [styleClass]="'no-border filter-left'"
        [lazy]="true" (lazyLoad)="lazyLoad($event)" #inboxMessageTable>
    </ng-table>

    <ng-template let-data="rowData" #sendToTemplate>
        <span class="pointer pt-pix-2" (click)="showMessage(data)">
            <span *ngIf="(data.Sender !== null && data.Sender !== '')">
                {{data.Sender}}
            </span>
        </span>
    </ng-template>
    <ng-template let-data="rowData" #messageTextTemplate>
        <span class="pointer pt-pix-2" (click)="showMessage(data)">
            <span innerHTML="{{sliceMessage(data.MessageText)}}"
                *ngIf="(data.MessageText !== null && data.MessageText !== '')">
            </span>
        </span>
    </ng-template>
    <ng-template let-data="rowData" #dateTemplate>
        <span class="pointer pt-pix-2" (click)="showMessage(data)">
            <span *ngIf="(data.DateAdded !== null && data.DateAdded !== '')">
                {{data.DateAdded | tark_date_time}}
            </span>
        </span>
    </ng-template>
    <ng-template let-data="rowData" #actionTemplate>
        <span class="overflow-break-word" *ngIf="!data.IsViewed">
            <a class="action-btn" (click)="markAsRead(data)" title="Mark as Read">
                <pos-icon class="pointer font-14" [icons]="[icons.squareLightYellowOrangeStack, icons.envelopeStack]">
                </pos-icon>
            </a>
        </span>
        <span class="overflow-break-word" *ngIf="data.IsViewed">
            <a class="action-btn" (click)="markAsUnRead(data)" title="Mark as Unread">
                <pos-icon class="pointer font-14" [icons]="[icons.squareWhiteGreyStack, icons.envelopeOpenStack]">
                </pos-icon>
            </a>
        </span>
        <span class="pe-pix-3 overflow-break-word" *ngIf="!data.IsSystemMessage">
            <a class="action-btn" (click)="replyMessage(data)" title="Reply">
                <pos-action-icon class="font-14" [icon]="icons.reply" [additionalClasses]="['white','pt-pix-1']">
                </pos-action-icon>
            </a>
        </span>
        <span class="overflow-break-word inline-block" *ngIf="data.IsSystemMessage" [ngStyle]="{width: '28px'}">
        </span>
    </ng-template>
</div>
