import { Injectable, EventEmitter } from '@angular/core';
import { AdfModel } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class DashboardBroadcastingService {

  adfDashboardChanged = new EventEmitter<AdfModel>();
  adfDashboardCollapseExpand = new EventEmitter<boolean>();
  adfDashboardSaved = new EventEmitter<number>();
  adfSaveWidgetsCompleted = new EventEmitter();
  adfWidgetRemovedFromColumn = new EventEmitter<any>();
  reloadWidget = new EventEmitter<any>();

  changeAdfDashboard = (adfModel: AdfModel) => {
    this.adfDashboardChanged.emit(adfModel);
  }

  changeAdfDashboardCollapseExpand = (isCollapsed: boolean) => {
    this.adfDashboardCollapseExpand.emit(isCollapsed);
  }

  saveWidget = (dashboardId: number) => {
    this.adfDashboardSaved.emit(dashboardId);
  }

  widgetSaved = () => {
    this.adfSaveWidgetsCompleted.emit();
  }

  widgetDelete = (definition) => {
    this.adfWidgetRemovedFromColumn.emit(definition);
  }
  widgetReload = (widget) => {
    this.reloadWidget.emit(widget);
  }
}
