<pos-edit-wrapper [isPopup]="true" [heading]="logInventoryPopupHeader" (close)="closeInventoryLogModel()">
    <form #inventoryProductModalForm="ngForm" autocomplete="off" (submit)="updateInventoryLog()">
        <div class="pt-pix-10 pos-modal-body">
            <pos-inventory-product-log [(inventoryProduct)]="inventoryProductForLog" [focus]="true"
                [isPopup]="true"></pos-inventory-product-log>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn btn-primary btn-lg btn-save pos-button" type="submit">Save</button>
            <button type="button" class="btn btn-secondary btn-lg btn-cancel-modal pos-button" type="button"
                (click)="closeInventoryLogModel()">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
