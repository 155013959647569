import { NgPipesModule } from 'ngx-pipes';
import { NgModule } from '@angular/core';
import { ServeScreenService } from './services/serve-screen.service';
import { CommonModule } from '@angular/common';
import { SERVE_SCREEN_STATES } from './serve-screen.routes';
import { ServeScreenComponent } from './components';
import { ComponentsModule } from '../shared';
import { FormsModule } from '@angular/forms';
import { HeaderEntryModule } from '../header-entry/header-entry.module';
import { OrderCardSharedModule } from '../order-shared/order-card-shared.module';
import { ReadyOrderFilter } from './pipes/ready-order-filter';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(SERVE_SCREEN_STATES),
    ComponentsModule,
    HeaderEntryModule,
    FormsModule,
    OrderCardSharedModule,
    NgPipesModule
  ],
  declarations: [ServeScreenComponent, ReadyOrderFilter],
  providers: [ServeScreenService],
  bootstrap: [
    ServeScreenComponent
  ]
})
export class ServeScreenModule { }
