import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertsService, Messages, OrderKioskScreenService } from 'src/app/shared';
import { ModalComponent, ModalService, SpinnerService, editWhiteWithText, plusWhite } from 'src/app/shared/components';
import { finalize } from 'rxjs/operators';

import { cloneDeep } from 'lodash';
import { KioskScreen, KioskScreenChoice } from 'src/app/order-kiosk/interfaces';
import { OrderKioskScreenChoiceService } from 'src/app/order-kiosk/services';
import { KioskScreenEditComponent } from 'src/app/order-kiosk';

@Component({
  selector: 'pos-set-kiosk-next-screen',
  templateUrl: './set-kiosk-next-screen.component.html',
  styleUrls: ['./set-kiosk-next-screen.component.scss']
})
export class SetKioskNextScreenComponent extends ModalComponent {
  @Input() kioskScreenButton: KioskScreenChoice;
  kioskScreens: Array<KioskScreen> = [];
  icons = {
    plusWhite,
    editWhiteWithText
  }
  constructor(
    public modalRef: BsModalRef,
    private modalService: ModalService,
    private alertsService: AlertsService,
    private spinnerService: SpinnerService,
    private kioskScreenService: OrderKioskScreenService,
    private orderKioskScreenChoiceService: OrderKioskScreenChoiceService
  ) {
    super(modalRef);
    this.kioskScreenButton = orderKioskScreenChoiceService.newScreenChoice();
  }

  ngOnInit() {
    this.loadScreens();
  }

  loadScreens() {
    this.spinnerService.show();
    this.kioskScreenService.getAll()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (screens: Array<KioskScreen>) => {
          this.kioskScreens = screens.filter(x => x.Id != this.kioskScreenButton.ScreenId);
        }, error: this.alertsService.showApiError
      });
  }

  save(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.spinnerService.show();
    this.orderKioskScreenChoiceService.insertOrUpdate(this.kioskScreenButton)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.alertsService.renderSuccessMessage(Messages.KioskScreenChoiceSaveSuccess);
          this.hide({ button: this.kioskScreenButton.Button });
        }, error: this.alertsService.showApiError
      });
  }

  cancel() {
    this.hide({});
  }

  openScreenModal(id: number) {
    const modalRef = this.modalService.show(KioskScreenEditComponent, {
      backdrop: 'static',
      class: 'vertical-center modal-max-width-55',
      animated: false,
      keyboard: false,
      initialState: {
        id: id ?? 0,
        isPopup: true
      }
    });

    modalRef.close.subscribe((res) => {
      if (res.screenId) {
        this.kioskScreenButton.NextScreenId = res.screenId;
        const kioskScreens = cloneDeep(this.kioskScreens);
        let screenInArray = kioskScreens.find(x => x.Id == res.screenId);
        if (!screenInArray) {
          this.loadScreens();
        } else {
          screenInArray.Name = res.screen.Name;
          this.kioskScreens = kioskScreens;
        }
      }
    });
  }

}
