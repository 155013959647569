<pos-edit-wrapper heading="Edit Dashboard" (close)="cancelEditing()">
    <form class="edit-form">
        <div class="pos-modal-body screen-scroll">
            <pos-row>
                <pos-column>
                    <pos-form-field label="Title">
                        <input posInput type="text" class="form-control" name="layoutName" [(ngModel)]="adfModel.Name"
                            #layoutName="ngModel" />
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Auto Refresh Interval (minutes)">
                        <input posInput type="number" class="form-control col-120" posOnlyNumbers name="interval"
                            [(ngModel)]="adfModel.AutoRefreshInterval" #interval="ngModel" />
                    </pos-form-field>
                </pos-column>
            </pos-row>
            <div class="row">
                <div class="col-12">
                    <label class="label-section ps-pix-5">Structure</label>
                </div>
                <div class="col-12 row mx-0">
                    <div class="col-lg-4" *ngFor="let structureColumn of layouts">
                        <div *ngFor="let structure of structureColumn | keyvalue">
                            <div class="row mb-pix-10">
                                <div class="col-sm-2">
                                    <label>
                                        <input type="radio" [value]="structure.key" name="adfLayoutName"
                                            [(ngModel)]="adfModel.LayoutName"
                                            (change)="changeStructure(structure.key, structure)">
                                    </label>
                                </div>
                                <div class="col-sm-9" (click)="changeStructure(structure.key, structure)">
                                    <pos-adf-structure-preview [name]="structure.key" [adfModel]="structure"
                                        [isSelected]="adfModel.LayoutName == structure.key">
                                    </pos-adf-structure-preview>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="clearfix"></div>
        <div class="col-12 action-button-container popup-footer">
            <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="cancelEditing()" scrollup
                type="button" value="Cancel">Close</button>
        </div>
    </form>
</pos-edit-wrapper>
