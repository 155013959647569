import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { User } from '../../information-management/users/interface/user';
import { UserPayRate } from '../../information-management/users/interface/user-pay-rate';

@Injectable()
export class UserService {
    constructor(private httpService: HttpClient) { }
    userApiBasePath = `${RuntimeConstants.API_PATH}users`;

    newUser() {
        return {
            id: 0,
            name: '',
            username: '',
            code: '',
            firstname: '',
            lastname: '',
            administrator: false,
            DateAdded: new Date(),
            DateEdited: null,
            text_email_address: '',
            active: true,
            manager: false,
            active_delivery: false,
            driver_out: false,
            is_driver: false,
            time_keeping: false,
            EmailAddress: '',
            IsSystemUser: false,
            Password: '',
            IsAllowExternalLogin: false,
            BadgeNumber: '',
            RoleName: '',
            FailedLoginAttemptCount: 0,
            LastFailedAttempt: null,
            Roles: [],
        };
    }

    newUserPayRate() {
        return {
            id: 0,
            user_id: 0,
            amount: null,
            effective_date: new Date(),
            DateAdded: new Date(),
            DateEdited: new Date(),
            job_code_id: null,
            name: ''
        };
    }

    getUsersList(decryptedCode = false): Observable<any> {
        return this.httpService.get(this.userApiBasePath + '/' + decryptedCode);
    }

    deleteUser(userId: number): Observable<any> {
        return this.httpService.delete(this.userApiBasePath + '/' + userId);
    }

    getUserData(userId: number): Observable<any> {
        return this.httpService.get(this.userApiBasePath + '/' + userId);
    }

    getUserPayRate(userId: number): Observable<any> {
        return this.httpService.get(this.userApiBasePath + '/' + userId + '/PayRate');
    }

    deleteUserPayRate(userId: number, payRateId: number): Observable<any> {
        return this.httpService.delete(this.userApiBasePath + '/' + userId + '/payRate/' + payRateId);
    }

    addUserPayRate(userId: number, payRateModel: UserPayRate): Observable<any> {
        return this.httpService.post(this.userApiBasePath + '/' + userId + '/payRate', payRateModel);
    }

    updateUserPayRate(userId: number, payRateModel: UserPayRate): Observable<any> {
        return this.httpService.put(this.userApiBasePath + '/' + userId + '/payRate/' + payRateModel.id, payRateModel);
    }

    addUser(user: User): Observable<any> {
        return this.httpService.post(this.userApiBasePath, user);
    }

    updateUser(user: User): Observable<any> {
        return this.httpService.put(this.userApiBasePath + '/' + user.id, user);
    }

    updateUserCode(user: any): Observable<any> {
        return this.httpService.put(this.userApiBasePath + '/' + user.id + '/code', user);
    }

    updateUserPassword(user: any): Observable<any> {
        return this.httpService.put(this.userApiBasePath + '/' + user.id + '/password', user);
    }

    getAllActiveUser(): Observable<any> {
        return this.httpService.get(this.userApiBasePath + '/activeUser');
    }

    unblockUser(userId: number) {
        return this.httpService.put(`${this.userApiBasePath}/${userId}/unblock`, null);
    }
    getAllClockedInUsers(): Observable<any> {
        return this.httpService.get(this.userApiBasePath + '/clockedIn');
    }
}
