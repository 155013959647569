import { Injectable, EventEmitter } from '@angular/core';
import { OrderInvoice } from 'src/app/orders';
import { Account } from 'src/app/information-management';

@Injectable()
export class OrderEventBroadcastingService {

  menuChangeClicked = new EventEmitter<any>();
  inboxMessage = new EventEmitter<boolean>();
  newOrder = new EventEmitter<boolean>();
  selectSeat = new EventEmitter<number>();
  terminalWarning = new EventEmitter<any>();
  activeOrderClicked = new EventEmitter<number>();
  onAddItemStart = new EventEmitter<number>();
  onAddItemComplete = new EventEmitter<number>();
  hideInvoiceLoader = new EventEmitter<any>();
  reloadHomeState = new EventEmitter<any>();
  reloadOrder = new EventEmitter<OrderInvoice>();
  startNewOrderFromOutSide = new EventEmitter<any>();
  paymentCompleted = new EventEmitter<number>();
  setAccountCompleted = new EventEmitter<Account>();
  deleteOrder = new EventEmitter<number>();
  addToGoSeat = new EventEmitter();
  autoSignOutUser = new EventEmitter();
  
  constructor() { }

  changeHomeState() {
    this.reloadHomeState.emit();
  }

  onMenuChangeClicked() {
    this.menuChangeClicked.emit();
  }

  messageButtonEvent(messageFound: boolean) {
    this.inboxMessage.emit(messageFound);
  }

  newOrderEvent(isRequiredNavigateToHome) {
    this.newOrder.emit(isRequiredNavigateToHome);
  }

  onTerminalWarning(warnings: any) {
    this.terminalWarning.emit(warnings);
  }

  onActiveOrderClicked(orderId) {
    this.activeOrderClicked.emit();
  }

  onAddItemStarted() {
    this.onAddItemStart.emit();
  }

  onAddItemCompleted() {
    this.onAddItemComplete.emit();
  }

  onOrderReload(order: OrderInvoice) {
    this.reloadOrder.emit(order);
  }

  onStartNewOrderFromOutSide() {
    this.startNewOrderFromOutSide.emit();
  }
  onPaymentCompleted(orderId: number) {
    this.paymentCompleted.emit(orderId);
  }

  onSetAccountCompleted(account: Account) {
    this.setAccountCompleted.emit(account);
  }

  onSeatSelect(seatId) {
    this.selectSeat.emit(seatId);
  }

  onHideInvoiceLoader() {
    this.hideInvoiceLoader.emit();
  }

  onDeleteOrder(orderId: number) {
    this.deleteOrder.emit(orderId);
  }

  onAddToGoSeat() {
    this.addToGoSeat.emit();
  }

  onAutoSignOutUser() {
    this.autoSignOutUser.emit();
  }
}

