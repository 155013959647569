import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { Observable } from 'rxjs';
import { InventoryCategory } from '../interface/inventory-category';

@Injectable()
export class InventoryCategoryService {

    inventoryCategoryApiUrl = '';
    constructor(private httpService: HttpClient) {
        this.inventoryCategoryApiUrl = RuntimeConstants.API_PATH + 'inventorycategories';
    }
    newInventoryCategory() {
        return {
            id: 0,
            name: '',
            days_between_inventory: null
        };
    }

    getInventoryCategoryList(): Observable<any[]> {
        return this.httpService.get<any[]>(`${this.inventoryCategoryApiUrl}`);
    }
    getInventoryCategoryDetails(inventoryCategoryId: number): Observable<any> {
        return this.httpService.get(`${this.inventoryCategoryApiUrl}/` + inventoryCategoryId);
    }
    saveInventoryCategory(inventoryCategory: InventoryCategory): Observable<any> {
        return this.httpService.post(this.inventoryCategoryApiUrl, inventoryCategory);
    }
    deleteInventoryCategory(inventoryCategoryId: number): Observable<any> {
        return this.httpService.delete(`${this.inventoryCategoryApiUrl}/` + inventoryCategoryId);
    }
}
