import { Component, HostListener, Input, ViewChild } from '@angular/core';
import { SetAccountComponent } from 'src/app/set-account';
import { SettingParam } from 'src/app/shared/interface';
import { ApplicationStateService } from 'src/app/shared/services';

@Component({
  selector: 'pos-scan-qr-code',
  templateUrl: './scan-qr-code.component.html'
})
export class ScanQrCodeComponent {
  @Input() orderId: number;
  settingParam: SettingParam;
  isScanningInProgress = false;
  qrChars = [];
  prefixChars = [];
  qrScanPrefix: string = null;
  @ViewChild('setAccount') setAccount: SetAccountComponent;

  @HostListener('document:keypress', ['$event']) onKeypress(event) {
    this.subscribeToKeypressEvent(event);
  }

  constructor(private applicationStateService: ApplicationStateService) { }

  ngOnInit() {
    this.settingParam = this.applicationStateService.settingParam;
    this.qrScanPrefix = "<xml>";
  }

  subscribeToKeypressEvent(event) {
    if (!(event.target instanceof HTMLInputElement)) {
      const last5Chars = this.qrChars.join('').substring(this.qrChars.length - 5);
      if (last5Chars == this.qrScanPrefix) {
        this.prefixChars = last5Chars.split('');
        this.qrChars = [];
      }
      if (this.prefixChars.length < this.qrScanPrefix.length) {
        this.prefixChars.push(event.key);
      } else if (this.prefixChars.join('').substring(0, 5) == this.qrScanPrefix) {
        this.scanQrCode(event);
      } else {
        this.resetVariables();
      }
    } else {
      this.resetVariables();
    }
  }

  scanQrCode(event: any) {
    this.qrChars.push(event.key);
    const endOfXmlKeys = this.qrChars.join('').substring(this.qrChars.length - 6);
    if (endOfXmlKeys == '</xml>') {
      const jsonString = this.qrChars.join('').slice(0, this.qrChars.length - 6);
      let accountDetails: { Name: string, LoyaltyCardNumber: string } = JSON.parse(jsonString);
      this.setAccount.onSwipeDetected(accountDetails.LoyaltyCardNumber, accountDetails.Name);
      this.resetVariables();
    }
  }

  resetVariables() {
    this.prefixChars = [];
    this.qrChars = [];
  }
}
