<div>
  <div>
    <h4 class="modal-content__heading">Confirm</h4>
  </div>
  <div class="modal-body">
    <span innerHTML="{{message}}"></span>
  </div>
  <div class="col-12 action-button-container popup-footer pb-pix-10">
    <button class="btn btn-primary btn-lg btn-save pos-button" (click)="onConfirm()" type="button">{{confirmButtonText}}</button>
    <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="hideChildModal()" type="button">{{cancelButtonText}}</button>
  </div>
  <div class="clearfix"></div>
</div>
