import { Injectable } from '@angular/core';
import { BaseCrudService, RuntimeConstants } from 'src/app/shared';
import { HardwareInterface } from '../interface/hardware-interface';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HardwareInterfaceService extends BaseCrudService<HardwareInterface> {

  hardwareApiUrl = `${RuntimeConstants.API_PATH}hardwareinterfaces`;

  constructor(private httpClient: HttpClient) {
    super('hardwareinterfaces', httpClient);
  }

  newHardwareInterface() {
    const hardwareInterface: HardwareInterface = {
        Id: 0,
        Name: '',
        Guid: '',
        MonitorUrl: ''
    };
    return hardwareInterface;
  }

  saveHIC(hic: HardwareInterface) {
    return this.httpClient.post(this.hardwareApiUrl, hic);
  }

}
