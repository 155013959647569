import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RuntimeConstants } from 'src/app/shared/constants';
import { UpdateTimerStatusModel } from '../interfaces/update-timer-status-model';
import { QueuedProduct } from '../interfaces/queued-product';

@Injectable({
  providedIn: 'root'
})
export class TimerService {

  constructor(private httpService: HttpClient) { }

  getSalesProductTimerDetails(terminalId: number): Observable<Array<QueuedProduct>> {
    return this.httpService.get<Array<QueuedProduct>>(
      `${RuntimeConstants.API_PATH}timer-terminal/${terminalId}`
    );
  }

  getActiveProductForTimer(): Observable<Array<QueuedProduct>> {
    return this.httpService.get<Array<QueuedProduct>>(
      `${RuntimeConstants.API_PATH}timer-terminal/products`
    );
  }
  
  updateProductState(orderId: number, orderProductId: number,
    orderProductComponentId: number, updateTimerStatusData: UpdateTimerStatusModel): Observable<void> {
    return this.httpService.put<void>(
      `${RuntimeConstants.API_PATH}timer-terminal/${updateTimerStatusData.TerminalId}/orders/${orderId}/product/${orderProductId}/component/${orderProductComponentId}`,
      updateTimerStatusData
    );
  }
}
