<pos-edit-wrapper heading="Messages Box" (close)="closeDialog()">
    <div class="col-12 form-horizontal container-fluid" id="divMessagesBox">
        <ul class="nav nav-tabs inner-tab pos-nav-tabs">
            <li [ngClass]="{'active': selectedTab == tabList.Inbox }">
                <a (click)="selectedTab = tabList.Inbox" class="blue">
                    <pos-icon [icon]="icons.inboxIn" class="theme-separator">
                    </pos-icon> Inbox <span class="badge font-9 pl-pix-6 bg-orange">{{inboxMessages}}</span>
                </a>
            </li>
            <li [ngClass]="{'active': selectedTab == tabList.Sent }">
                <a (click)="selectedTab = tabList.Sent" class="blue">
                    <pos-icon [icon]="icons.inboxOut" class="theme-separator">
                    </pos-icon> Sent
                </a>
            </li>
        </ul>

        <div class="overflow-auto-tabs width-100 pt-pix-10 row mx-0" [ngStyle]="{'height': screenHeight + 'px'}">
            <div [hidden]="selectedTab != tabList.Inbox">
                <pos-inbox-messages-list *ngIf="selectedTab == tabList.Inbox" [height]="screenHeight"
                    (inboxMessage)="inboxMessageChange($event)">
                </pos-inbox-messages-list>
            </div>
            <div [hidden]="selectedTab != tabList.Sent">
                <pos-sent-messages *ngIf="selectedTab == tabList.Sent" [height]="screenHeight"></pos-sent-messages>
            </div>
        </div>
    </div>
    <div class="col-12 action-button-container popup-footer">
        <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="closeDialog()" type="button">Close</button>
    </div>
</pos-edit-wrapper>
