<div>
  <h4 class="modal-content__heading" name="numpadHeader">Manual Amount</h4>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-3 pe-0">
      <div class="col-12 pt-pix-15">
        <div class="numpad__input"> &nbsp;</div>
      </div>
      <div class="col-12 pt-pix-15">
        <div class="btn-cal">#</div>
      </div>
      <div class="col-12 pt-pix-15" *ngIf="isShowTaxableSelection">
        <div class="btn-cal" (click)="taxableSelection()">{{taxableItem}}</div>
      </div>
    </div>
    <div class="col-9">
      <pos-numpad numpadId="manualAmountModal" [value]="typeSpecialAmount" [options]="numpadOptions"
        [onCancel]="onAmountCancel" [onSubmit]="onAmountSubmit">
      </pos-numpad>
    </div>
  </div>
</div>
