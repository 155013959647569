import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseListComponent } from 'src/app/shared/components/base-list.component';
import { ScreenDetails } from '../../interface/screen-details';
import { Permissions, Levels, Messages, DomainConstants } from '../../../../shared/constants/';
import { GridColumn, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { AuthenticationService } from 'src/app/shared/auth/services/authentication.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { ScreenService } from 'src/app/shared/services/screen.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { check, barsWhite, deleteWhite, editWhite, history, plusWhite, printWhite, screensDesktop } from 'src/app/shared/components/icon';
import * as _ from 'lodash';
import { ApplicationStateService, ExportService, ModalBackdropService } from 'src/app/shared';
import { ActivatedRoute, Router } from '@angular/router';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { ScreenEditComponent } from '../screen-edit/screen-edit.component';

@Component({
    selector: 'pos-screen-list',
    templateUrl: './screen-list.component.html',
    styleUrls: ['./screen-list.component.scss']
})
export class ScreenListComponent extends BaseListComponent<ScreenDetails> implements OnInit {

    permission = {
        name: Permissions.UIScreens,
        readOnlyLevel: Levels.ReadOnly,
        editLevel: Levels.Edit
    };

    public icons = {
        check,
        barsWhite,
        deleteWhite,
        editWhite,
        history,
        plusWhite,
        printWhite,
        screensDesktop
    };

    screenColumns: Array<GridColumn> = [];
    screenPrintColumns: Array<GridColumn> = [];
    screens: Array<ScreenDetails> = [];
    popover: any;
    screen: ScreenDetails;

    id: number;

    @ViewChild('printReceiptIconTemplate', { static: true }) private printReceiptIconTemplate: any;
    @ViewChild('operationTemplate', { static: true }) public operationTemplate: any;
    @ViewChild('operationHeaderTemplate', { static: true }) public operationHeaderTemplate: any;
    modalRef: BsModalRef;

    constructor(
        private authenticationService: AuthenticationService,
        protected alertService: AlertsService,
        protected spinnerService: SpinnerService,
        protected screenService: ScreenService,
        protected modalService: ModalService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected exportService: ExportService,
        protected printService: PrintTableService,
        private modalBackdropService: ModalBackdropService,
        protected applicationStateService: ApplicationStateService
    ) {
        super(screenService, alertService, spinnerService, modalService, applicationStateService, printService, route);
    }

    ngOnInit() {
        this.deleteSuccessMessage = Messages.ScreenDeleted;
        this.screenColumns = this.getGridColumns();
        this.checkPermission();
        this.loadSubscriptions();
    }

    public getGridColumns(): Array<GridColumn> {

        const printReceiptIconColumn = new TemplateColumn({
            itemTemplate: this.printReceiptIconTemplate,
            TextAlign: TextAlign.Center,
            Width: '15%',
            Field: 'PrintOnOrderReceipt',
            IsSortable: true,
            HeaderText: 'Print On Order Receipt'
        });


        const columns = [
            new GridColumn({ HeaderText: 'Title', Field: 'Title', IsSortable: true, Width: '60%' }),
            new GridColumn({
                HeaderText: 'Min Selections', Field: 'MinSelections', IsSortable: true, Width: '10%',
                TextAlign: TextAlign.Center
            }),
            new GridColumn({
                HeaderText: 'Max Selections', Field: 'MaxSelections', IsSortable: true, Width: '10%',
                TextAlign: TextAlign.Center
            }),
            printReceiptIconColumn,
        ];
        this.screenPrintColumns = [...columns];
        return columns;
    }
    private checkPermission(): void {
        const operation = new TemplateColumn({
            itemTemplate: this.operationTemplate,
            headerTemplate: this.operationHeaderTemplate,
            TextAlign: TextAlign.Center,
            Width: '156px'
        });
        if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
            this.screenColumns.push(operation);
        }
    }
    private loadSubscriptions(): void {
        this.data$.subscribe({
            next: (res) => {
                this.screens = res;
            }
        });
    }

    exportCSV() {
        this.exportService.exportCSV('screens', this.screens, null, this.screenPrintColumns);
    }

    printData() {
        this.printService.printEmitter.next({
            gridColumns: this.screenPrintColumns,
            gridData: this.screens
        });
    }


    onLinkPopOver(data, popover) {
        this.popover = popover;
        this.screen = data;
    }

    onScreenHistory() {
        this.router.navigate([this.screen.Id, 'screen-history'], { relativeTo: this.route });
    }

    public editItem(id: number, data?: ScreenDetails): void {
        this.id = id;
        const modelRefUnit = this.modalService.show(ScreenEditComponent, {
            animated: false,
            class: 'vertical-center modal-lg',
            keyboard: false,
            initialState: {
                screenId: id
            }
        });

        modelRefUnit.close.subscribe((response) => {
            if (response.shouldReload) {
                this.reload();
            }
        });
    }
    public getConfirmDeleteMessage(data: ScreenDetails): string {
        return StringUtils.format(Messages.ConfirmDeleteScreen,
            { 'screenTitle': data ? data.Title : '' }
        );
    }

    close() {
        this.router.navigate(['../'], { relativeTo: this.route });
    }
}
