import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { DomainConstants } from 'src/app/shared/constants/domain-constants';

@Component({
  selector: 'pos-kitchen-printer-config',
  templateUrl: './kitchen-printer-config.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class KitchenPrinterConfigComponent implements OnInit {

  @Input() splitConfiguration: string;
  @Output() splitConfig: EventEmitter<any> = new EventEmitter<any>();
  @Input() subaccountTerm: string = 'Seat';
  kitchenPrinterSplitConfiguration = DomainConstants.KitchenPrinterSplitByConfig;

  ngOnInit() {
    this.splitConfiguration = this.splitConfiguration ? this.splitConfiguration : this.kitchenPrinterSplitConfiguration.PrintWholeOrder;
  }

  setSplitConfiguration(value) {
    this.splitConfig.emit({splitConfig: value});
  }
}
