<pos-kiosk-header *ngIf="screenConfig.Header.IsShow" [header]="screenConfig.Header"></pos-kiosk-header>
<div class="row mx-0 designer-area"
    [ngStyle]="{'background-color': screenConfig.BackgroundColor, 'background-image': screenConfig.BackgroundImage ? 'url(\''+ imageRootPath + screenConfig.BackgroundImage + '\')' : '' }">
    <div class="p-0" [ngClass]="screenConfig.ShowInvoice ? 'col-xl-9 col-8' : 'col-12'"
        [ngStyle]="{'height': invoiceHeight - 12 + 'px'}">
        <div class="overflow-auto" [ngStyle]="{'height': secondaryButtonConfig?.IsShow && secondaryButtonConfig.ContainerHeight ? 'calc(100% - '+secondaryButtonConfig.ContainerHeight+'px)' 
            : (secondaryButtonConfig?.IsShow ? '80%' : '100%')}">
            <pos-kiosk-designer-grid [grid]="grid" class="primary-grid kiosk-designer-grid-{{screenId}}-{{primaryDesignerGridId}}" [screenId]="screenId" [templateType]="templateType"
            [designerGridId]="primaryDesignerGridId"    
            [buttonConfig]="buttonConfig" [(screenButtons)]="primaryScreenButtons"
                [isDesignMode]="isDesignMode"></pos-kiosk-designer-grid>
        </div>
        <pos-action-icon [icon]="icons.plusWhite" iconText="Add" class="text-nowrap float-end my-pix-5 me-pix-5"
            (click)="addKioskScreenChoice()" *ngIf="isDesignMode && secondaryButtonConfig?.IsShow"></pos-action-icon>
        <div *ngIf="secondaryButtonConfig?.IsShow" class="d-flex secondary-grid overflow-auto"
            [ngStyle]="{'align-items': secondaryButtonConfig.Alignment?.Vertical,
                        'justify-content': secondaryButtonConfig.Alignment?.Horizontal, 'height': secondaryButtonConfig.ContainerHeight ? secondaryButtonConfig.ContainerHeight + 'px' : '20%', 'width': secondaryButtonConfig.ContainerWidth ? secondaryButtonConfig.ContainerWidth : '100%' }">
            <pos-kiosk-designer-grid [grid]="secondaryGrid" class="kiosk-designer-grid-{{screenId}}-{{secondaryDesignerGridId}}"
                [designerGridId]="secondaryDesignerGridId" [screenId]="screenId"
                [(screenButtons)]="secondaryScreenButtons" [isAllowDelete]="true" [buttonConfig]="secondaryButtonConfig"
                [templateType]="templateType" [isDesignMode]="isDesignMode"></pos-kiosk-designer-grid>
        </div>
    </div>
    <div class="col-xl-3 col-4 p-3" *ngIf="screenConfig.ShowInvoice" [ngStyle]="{'height': invoiceHeight - 3 + 'px'}">
        <pos-kiosk-invoice></pos-kiosk-invoice>
    </div>
</div>
<pos-kiosk-footer *ngIf="screenConfig.Footer.IsShow" [buttons]="screenButtons"
    [footer]="screenConfig.Footer"></pos-kiosk-footer>
