import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalComponent } from 'src/app/shared';
import { RuntimeConstants } from 'src/app/shared/constants';
import { ApplicationStateService, ColorUtilityService } from 'src/app/shared/services';

@Component({
  selector: 'pos-kiosk-overlay',
  templateUrl: './kiosk-overlay.component.html',
  styleUrls: ['./kiosk-overlay.component.scss']
})
export class KioskOverlayComponent extends ModalComponent {

  idleTimeout: NodeJS.Timeout;
  baseImagePath = `${RuntimeConstants.IMAGE_BASE_PATH}/`;

  @Input() intervalSeconds = 0;
  @Input() rejectButtonText = null;
  @Input() confirmButtonText = 'CONTINUE';
  @Input() backgroundImage: string = null;
  @Input() isShowButtons = true;
  footerBackgroundColor = '#EEEEEE';
  @Output() userResponseEvent: EventEmitter<any> = new EventEmitter<any>();
  isVideo = false;

  constructor(applicationStateService: ApplicationStateService,
    private colorUtilityService: ColorUtilityService,
    bsModalRef: BsModalRef
  ) {
    super(bsModalRef);
    this.footerBackgroundColor = this.colorUtilityService.convertColorToRgba(applicationStateService.settingParam.KioskButtonsBackgroundColor ?? '#ffa800', applicationStateService.settingParam.KioskButtonsBackgroundOpacity ?? 0.4);
  }

  ngOnInit() {
    if (this.intervalSeconds) {
      this.idleTimeout = setTimeout(() => {
        clearTimeout(this.idleTimeout);
        this.emitUserResponse({});
      }, this.intervalSeconds * 1000)
    }
    const videoExtensions = ['mp4'];
    const extension = this.backgroundImage.substring(this.backgroundImage.lastIndexOf('.') + 1);
    this.isVideo = videoExtensions.includes(extension);
  }

  continueOperation() {
    this.emitUserResponse({
      continueOperation: true
    });
  }

  emitUserResponse(data = {}) {
    if (this.idleTimeout) {
      clearInterval(this.idleTimeout);
    }
    this.userResponseEvent.emit(data);
  }
}
