import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridColumn } from '@tarktech/tark-ng-utils';
import { BaseListComponent } from 'src/app/shared/components/base-list.component';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import { WidgetProductGroups } from '../../interfaces/widget-product-group';
import { WidgetProductGroupService } from '../../services/widget-product-group.service';
import {
  editWhite,
  deleteWhite,
  cogWhite,
  plusWhite,
  printWhite
} from 'src/app/shared/components/icon';
import { WidgetGroupConfigComponent } from '../widget-group-config/widget-group-config.component';
import { finalize } from 'rxjs/operators';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { MenuWidget } from '../../interfaces/menu-widget';
import { MenuDisplay } from '../../interfaces/menu-display';
import { MenuDisplayService } from '../../services/menu-display.service';
import { forkJoin, Observable } from 'rxjs';

@Component({
  selector: 'pos-widget-group',
  templateUrl: './widget-group.component.html',
  styleUrls: ['./widget-group.component.scss']
})
export class WidgetGroupComponent extends BaseListComponent<WidgetProductGroups> implements OnInit {

  widgetId: number;
  widgetGroupList: Array<WidgetProductGroups> = [];
  menuWidgetColumns: Array<GridColumn> = [];
  icons = {
    editWhite,
    deleteWhite,
    cogWhite,
    plusWhite,
    printWhite
  };
  widget: MenuWidget;
  menu: MenuDisplay;
  menuId: number;

  constructor(protected route: ActivatedRoute,
    protected spinnerService: SpinnerService,
    protected alertService: AlertsService,
    protected applicationStateService: ApplicationStateService,
    protected router: Router,
    protected menuDisplayService: MenuDisplayService,
    protected widgetProductGroupService: WidgetProductGroupService,
    protected modalService: ModalService,
    protected printService: PrintTableService) {
    super(widgetProductGroupService, alertService, spinnerService, modalService, applicationStateService, printService, route);
    this.widgetId = route.snapshot.params.widgetId ? parseInt(route.snapshot.params.widgetId, 10) : 0;
    this.menuId = route.snapshot.params.menuId ? parseInt(route.snapshot.params.menuId, 10) : 0;
    this.deleteSuccessMessage = Messages.WidgetGroupDeleteSuccess;
  }

  ngOnInit(): void {
    this.menuWidgetColumns = this.configureGridColumns();
    this.reload();
    this.loadDependance();
  }

  reload() {
    this.spinnerService.show();
    this.widgetProductGroupService.getAllProductGroupByWidget(this.widgetId).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (response) => {
        this.widgetGroupList = response;
      }
    });
  }

  loadDependance() {
    const observable: Array<Observable<any>> = [];
    observable.push(this.menuDisplayService.getMenuWidget(this.widgetId));
    observable.push(this.menuDisplayService.get(this.menuId));

    this.spinnerService.show();
    forkJoin(observable).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: ([widget, menu]: [MenuWidget, MenuDisplay]) => {
        this.widget = widget;
        this.menu = menu;
      },
      error: this.alertService.showApiError
    });
  }


  getConfirmDeleteMessage(data) {
    return StringUtils.format(Messages.ConfirmDeleteWidgetGroup, { 'widgetGroup': data.Name });
  }

  editItem(groupId) {
    const modalRef = this.modalService.show(WidgetGroupConfigComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        widgetProductGroupId: groupId,
        widgetId: this.widgetId
      }
    });

    modalRef.close.subscribe(res => {
      if (res && res.shouldReload) {
        this.reload();
      }
    });
  }

  gridRowReorder(event) {
    this.widgetProductGroupService.updateWidgetGroupOrdinal(this.widgetGroupList).subscribe(
      (res) => {
        this.widgetGroupList = res;
      },
      this.alertService.showApiError,
      () => { }
    );
  }

  getGridColumns() {
    return [
      new GridColumn({ HeaderText: 'Name', Field: 'Name', Width: '93%' }),
    ];
  }


  cancel() {
    this.router.navigate([`/manage/menu-display/${this.menuId}/widgets`]);
  }

}
