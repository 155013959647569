import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { ConfirmationScreenWorkflowService } from 'src/app/configurator/workflow/services/confirmation-screen.service';
import { DomainConstants, FieldInfoMessages } from '../../constants';
import { FontStyle } from '../../interface/font-style';

@Component({
  selector: 'pos-font-style',
  templateUrl: './font-style.component.html',
  styleUrls: ['./font-style.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class FontStyleComponent implements OnInit {

  @Input() fontStyle: FontStyle;
  @Input() name = 'fontStyle';

  fieldInfoMessages = {
    FontStyleShadowSequence: FieldInfoMessages.WorkflowFontStyleShadowSequence
  };

  constructor(private confirmationScreenWorkflowService: ConfirmationScreenWorkflowService) {
    this.fontStyle = this.confirmationScreenWorkflowService.newFontStyle();
  }

  ngOnInit(): void {}

  fontWeightChange(e) {
    this.fontStyle.fontWeight = e.checked ? DomainConstants.FontStyle.Bold : null;
  }

  fontStyleChange(e) {
    this.fontStyle.fontStyle = e.checked ? DomainConstants.FontStyle.Italic : null;
  }

  textDecorationChange(e) {
    this.fontStyle.textDecoration = e.checked ? DomainConstants.FontStyle.Underline : null;
  }

}
