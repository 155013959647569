import { Route } from '@angular/router';
import { AuthGuard } from '../app-guard';
import { Levels, DomainConstants } from '../shared/constants';
import { DjComponent } from './dj';

const DJ: Route = {
    path: '',
    component: DjComponent,
    canActivate: [AuthGuard],
    data: {
        permissions: [{ 'Name': DomainConstants.TerminalTypes.DJ.Permission, 'Level': Levels.Access }]
    }
};

export const DJ_STATES = [DJ];
