import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';
import { JobCode } from '../interface/job-code';
import { Observable } from 'rxjs';

@Injectable()
export class JobCodeService extends BaseCrudService<JobCode> {

    jobCodeApiUrl = '';
    constructor(httpService: HttpClient) {
        super('jobcode', httpService);
        this.jobCodeApiUrl = RuntimeConstants.API_PATH + 'jobcode';
    }

    newJobCode() {
        return {
            Id: 0,
            name: '',
            DateAdded: new Date(),
            DateEdited: new Date()
        };
    }

    getJobCodeList(): Observable<any> {
        return this.httpService.get(this.jobCodeApiUrl);
    }
}
