import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Unit } from '../interface/unit';

@Injectable()
export class UnitService {
    constructor(private httpService: HttpClient) { }
    unitApiBasePath = `${RuntimeConstants.API_PATH}unit`;

    getUnits(): Observable<Unit[]> {
        return this.httpService.get<Unit[]>(this.unitApiBasePath);
    }

    deleteUnit(unitId: number): Observable<any> {
        return this.httpService.delete(this.unitApiBasePath + '/' + unitId);
    }

    getUnit(unitId: number): Observable<Unit> {
        return this.httpService.get<Unit>(this.unitApiBasePath + '/' + unitId);
    }

    newUnit() {
        const unit: Unit = {
            Id: 0,
            Name: '',
            Increment: null
        };

        return unit;
    }

    addUnit(unit: Unit): Observable<any> {
        return this.httpService.post(this.unitApiBasePath + '/', unit);
    }

    updateUnit(unit: Unit): Observable<any> {
        return this.httpService.put(this.unitApiBasePath + '/' + unit.Id,
            unit);
    }
}
