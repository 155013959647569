import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AlertsService, FormUtilityService, TagService } from 'src/app/shared';
import { ModalFormComponent, ModalService, SpinnerService } from 'src/app/shared/components';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { filter, first, forEach } from 'lodash';
import { finalize } from 'rxjs/operators';
import { Tag } from 'src/app/information-management/tags';

@Component({
  selector: 'pos-filter-product',
  templateUrl: './filter-product.component.html',
  styleUrls: ['./filter-product.component.scss']
})
export class FilterProductComponent extends ModalFormComponent implements OnInit {
  @ViewChild('tagSelectionForm') tagSelectionForm: NgForm;
  @Input() selectedButtonBehaviorType;
  @Input() buttonBehavior = null;

  tagIds: Array<number> = [];
  tags: Array<Tag> = [];

  get getForm(): NgForm {
    return this.tagSelectionForm;
  }

  constructor(private tagsService: TagService,
    protected spinnerService: SpinnerService,
    protected alertsService: AlertsService,
    modalRef: BsModalRef, modalService: ModalService, protected formUtilityService: FormUtilityService) {
    super(modalRef, modalService, formUtilityService);
  }

  ngOnInit(): void {
    if (this.buttonBehavior && this.buttonBehavior.Id) {
      try {
        if(this.buttonBehavior.BehaviorValue) {
          forEach(this.buttonBehavior.BehaviorValue, (b) => {
            this.tagIds.push(b.TagId);
          });
        }
      } catch (ex) {
        console.error('Invalid behavior value: ' + this.buttonBehavior.TextValue);
      }
    }
    this.loadData();
    this.setInitialValue();
  }

  loadData() {
    this.spinnerService.show();
    this.tagsService.getAll()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.tags = res??[];
        }, error: this.alertsService.showApiError
      });
  }

  saveBehavior = (isValid: boolean) => {
    if (isValid && this.tagIds) {
      this.hide({ shouldReload: true, Value: { tagIds: this.tagIds } });
    }
  }

  closeModal = () => {
    this.hide({ shouldReload: false });
  }
}
