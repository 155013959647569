import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { Observable } from 'rxjs';
import { ExternalApiObj, ExternalApi } from '../../configurator/external-api/interface/external-api';

@Injectable()
export class ExternalApiService {
    constructor(private httpService: HttpClient) { }

    newExternalApi() {
        const externalApiObj: ExternalApiObj = {
            Id: 0,
            Name: '',
            ApiVerb: '1',
            Url: '',
            DateAdded: new Date(),
            DateEdited: null,
            AuthorizationType: 0,
            UserName: '',
            Password: '',
            Token: '',
            ExternalApiQueryParams: [],
            ExternalApiBodyParams: []
        };
        return externalApiObj;
    }

    getExternalApis(): Observable<ExternalApi[]> {
        return this.httpService.get<ExternalApi[]>(`${RuntimeConstants.API_PATH}externalapi`);
    }
    getExternalApi(externalApiId: number): Observable<ExternalApi> {
        return this.httpService.get<ExternalApi>(
            `${RuntimeConstants.API_PATH}externalapi/` + externalApiId
        );
    }
    addExternalApi(externalApi: ExternalApi): Observable<ExternalApi> {
        return this.httpService.post<ExternalApi>(
            `${RuntimeConstants.API_PATH}externalapi/`,
            externalApi
        );
    }
    updateExternalApi(externalApi: ExternalApi): Observable<ExternalApi> {
        return this.httpService.put<ExternalApi>(
            `${RuntimeConstants.API_PATH}externalapi/`,
            externalApi
        );
    }
    deleteExternalApi(externalApiId: number): Observable<ExternalApi> {
        return this.httpService.delete<ExternalApi>(
            `${RuntimeConstants.API_PATH}externalapi/` + externalApiId
        );
    }

    testExternalApi(externalApiId: number): Observable<any> {
        return this.httpService.get<any>(`${RuntimeConstants.API_PATH}externalapi/`+ externalApiId + `/test`);
    }

    executeExternalApi(externalApiId, externalApiParams) {
        return this.httpService.post(
            `${RuntimeConstants.API_PATH}externalapi/${externalApiId}/execute`,
            externalApiParams
        );
    }
}
