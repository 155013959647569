import { Component, OnInit, Input } from '@angular/core';
import { AdfModel } from '../../interfaces';
import { cloneDeep } from 'lodash';
import { AdfStructurePreviewService } from '../../services/adf-structure-preview.service';
@Component({
  selector: 'pos-adf-structure-preview',
  templateUrl: './adf-structure-preview.component.html',
  styleUrls: ['./adf-structure-preview.component.scss']
})
export class AdfStructurePreviewComponent implements OnInit {

  @Input() name: string;
  @Input() adfModel: AdfModel;
  @Input() isSelected: boolean;
  preview: any;
  constructor(private adfStructurePreviewService: AdfStructurePreviewService) { }

  ngOnInit(): void {
    const copiedAdfModel: any = cloneDeep(this.adfModel);
    this.adfStructurePreviewService.adjustRowHeight(copiedAdfModel);
    this.preview = cloneDeep(copiedAdfModel.value);
  }

}
