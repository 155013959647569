import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterConfiguration } from 'src/app/shared/interface/filter-configuration';
import { RuntimeConstants } from '../../shared/constants/runtime-constants';
import { InboxMessage } from '../interface/inbox-messages';

@Injectable()
export class InboxMessageService {

    baseUrl = `${RuntimeConstants.API_PATH}message/`;
    constructor(private httpService: HttpClient) { }

    getNewInboxMessage(): InboxMessage {
        return {
            Id: 0,
            FromUserId: 0,
            FromTerminalId: 0,
            RecipientUserId: 0,
            RecipientTerminalId: 0,
            MessageText: null,
            FromEmailId: null,
            RecipientEmailId: null,
            TerminalId: null,
            Sender: null,
            DateAdded: new Date(),
            FromUser: null,
            FromTerminal: null,
            IsViewed: false,
            Receiver: null
        };
    }

    getTerminals(): Observable<any[]> {
        return this.httpService.get<any[]>(
            `${RuntimeConstants.API_PATH}terminals`
        );
    }
    getUserNameForSendingMessages(): Observable<any> {
        return this.httpService.get<any>(
            `${this.baseUrl}usernames`
        );
    }
    getMessageDetails(message, filterConfiguration: FilterConfiguration): Observable<any> {
        return this.httpService.post(
            `${this.baseUrl}received`,
            { message, filterConfiguration }
        );
    }

    getInboxMessageCount(inboxMessage): Observable<number> {
        return this.httpService.get<number>(
            `${this.baseUrl}inboxCount/recipientUser/${inboxMessage.RecipientUserId}/recipientTerminal/${inboxMessage.RecipientTerminalId}/isView/${inboxMessage?.IsViewed}`
        );
    }

    getSentMessageCount(inboxMessage): Observable<number> {
        return this.httpService.get<number>(
            `${this.baseUrl}sentCount/fromUser/${inboxMessage.FromUserId}/fromTerminal/${inboxMessage.FromTerminalId}`
        );
    }

    markAsRead(terminalMessageModel): Observable<any> {
        return this.httpService.post(
            `${this.baseUrl}markAsRead`,
            terminalMessageModel
        );
    }

    markAsUnread(terminalMessageModel): Observable<any> {
        return this.httpService.post(
            `${this.baseUrl}markAsUnread`,
            terminalMessageModel
        );
    }

    markAllAsRead(terminalMessageModel): Observable<any> {
        return this.httpService.post(
            `${this.baseUrl}markAsRead/all`,
            terminalMessageModel
        );
    }


    getSendMessages(message, filterConfiguration: FilterConfiguration): Observable<any> {
        return this.httpService.post(`${this.baseUrl}sent`, { message, filterConfiguration });
    }

    sendMessage(terminalMessageModel): Observable<any> {
        return this.httpService.post(`${this.baseUrl}send`, terminalMessageModel);
    }
}
