import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs';
import { KioskScreenConfig, KioskScreenChoice, ShakeFlavorGroup, CreatedShake, ScreenConfig } from 'src/app/order-kiosk/interfaces';
import { ShakeButtonConfig } from 'src/app/order-kiosk/interfaces/config/shake-button-config';
import { KioskNavigationService, OrderKioskConfigService, OrderKioskStorageService, ShakeFlavorGroupService } from 'src/app/order-kiosk/services';
import { AlertsService, DomainConstants, RuntimeConstants, SpinnerService } from 'src/app/shared';
declare let $: any;

@Component({
  selector: 'pos-ai-shake-group-selection',
  templateUrl: './ai-shake-group-selection.component.html',
  styleUrls: ['./ai-shake-group-selection.component.scss']
})
export class AIShakeGroupSelectionComponent {
  @Input() screenConfigs: Array<KioskScreenConfig> = [];
  @Input() screenId: number;
  @Input() screenButtons: Array<KioskScreenChoice> = [];
  screenConfig: ScreenConfig;
  buttonConfig: ShakeButtonConfig;
  imageRootPath = `${RuntimeConstants.IMAGE_BASE_PATH}/order-kiosk/`;
  shakeGroupImageRootPath = `${this.imageRootPath}shake-flavor-groups/`;
  isDesignMode = false;
  sectionHeight: number;
  itemWidth: string = '';
  shakeFlavorGroups: ShakeFlavorGroup[] = [];
  createdShake: CreatedShake;

  constructor(private orderKioskConfigService: OrderKioskConfigService,
    private orderKioskStorageService: OrderKioskStorageService,
    private shakeFlavorGroupService: ShakeFlavorGroupService,
    private kioskNavigationService: KioskNavigationService,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private route: ActivatedRoute
  ) {
    this.isDesignMode = route.snapshot.params.id;
    this.screenConfig = orderKioskConfigService.newScreenConfig();
    this.buttonConfig = { BackgroundColor: null, FontColor: null };
  }

  ngOnInit() {
    this.prepareConfigs();
    this.resizeWindow();
    this.shakeFlavorGroups = this.orderKioskStorageService.shakeFlavorGroups;
    if (!this.shakeFlavorGroups?.length) {
      this.getShakeFlavorGroups();
    }
  }

  getShakeFlavorGroups() {
    this.spinnerService.show();
    this.shakeFlavorGroupService.getAll()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.shakeFlavorGroups = res;
        }, error: this.alertService.showApiError
      });
  }

  prepareConfigs() {
    const screenConfig = this.orderKioskConfigService.parseConfigProperties(DomainConstants.KioskScreenConfigProperties.ScreenConfig, this.screenConfigs);
    if (screenConfig) this.screenConfig = screenConfig;

    const buttonConfig = this.orderKioskConfigService.parseConfigProperties(DomainConstants.KioskScreenConfigProperties.ButtonConfig, this.screenConfigs);
    if (buttonConfig) this.buttonConfig = buttonConfig;
  }

  resizeWindow() {
    $(window).resize(() => {
      this.calculateHeightWidth();
    });
  }

  ngAfterViewInit(): void {
    this.calculateHeightWidth();
  }

  calculateHeightWidth() {
    this.sectionHeight = this.orderKioskConfigService.calculateInvoiceHeight(this.screenConfig);
    this.itemWidth = ($('.designer-area').width() / 3 - 25) + 'px';
  }

  buttonClick(groupId: number) {
    if (!this.isDesignMode) {
      this.kioskNavigationService.createAIShake(groupId, this.screenId);
    }
  }

}
