<pos-edit-wrapper (close)="cancelEditing()" [isPopup]="false" [headingIcon]="icons.users"
    heading="{{userId ? ('Edit User: ' + userName) : 'Add User'}}">
    <form #userForm="ngForm" (submit)="submit(userForm.valid)" autocomplete="off" class="edit-form"
        [posValidate]="validateUserEdit">
        <context-provider provide="props">
            <div class="row g-0">
                <div class="col-12 container-fluid border-top">
                    <ul class="nav nav-tabs inner-tab pos-nav-tabs">
                        <li [ngClass]="{'active': selectedTab == tabList.Account }">
                            <a (click)="selectedTab = tabList.Account" class="blue"
                                [ngClass]="{'required':!userForm.valid }">
                                <pos-icon class="theme-separator pe-pix-5"
                                    [icon]="icons.informationManagementUser"></pos-icon>
                                Account
                            </a>
                        </li>
                        <li [ngClass]="{'disabled-tab':!userId, 'active': selectedTab == tabList.PayRates }">
                            <a (click)="selectedTab = tabList.PayRates" [ngClass]="{'blue':userId , 'grey': !userId}">
                                <pos-icon class="theme-separator pe-pix-5"
                                    [icon]="icons.userMoneyCheckAlt"></pos-icon>
                                Pay Rates
                            </a>
                        </li>
                    </ul>
                    <div class="col-12 pt-pix-5" [ngStyle]="{'height':getHeight(true)}">
                        <div [hidden]="selectedTab == tabList.PayRates">
                            <div class="tab-top-border overflow-auto-tabs pos-tab-body width-100"
                                [ngStyle]="{'height':getHeight(false)}">
                                <pos-row>
                                    <pos-column>
                                        <pos-form-field label="Username"
                                            [validationMessages]="{required: 'Please enter username.'}">
                                            <input posInput type="text" name="username" #username="ngModel"
                                                (keypress)="preventSpecialCharacter($event)" class="form-control"
                                                [(ngModel)]="userData.username" [required]="true" />
                                        </pos-form-field>
                                    </pos-column>

                                    <pos-column>
                                        <pos-form-field>
                                            <p-checkbox posInput name="active" #active="ngModel"
                                                [(ngModel)]="userData.active" binary="true" label="Active">
                                            </p-checkbox>
                                        </pos-form-field>
                                    </pos-column>

                                    <pos-column>
                                        <pos-form-field label="Role"
                                            [validationMessages]="{required: 'Please enter role.'}">
                                            <ng-select posInput [items]="userRoleList" bindLabel="Name" bindValue="Id"
                                                placeholder="Select role" [multiple]="true" [hideSelected]="true"
                                                [(ngModel)]="selectedUserRole" name="selectedUserRole"
                                                [required]="true">
                                            </ng-select>
                                        </pos-form-field>
                                    </pos-column>

                                    <pos-column>
                                        <pos-form-field label="First Name"
                                            [validationMessages]="{required: 'Please enter first name.'}">
                                            <input posInput type="text" name="firstName" #firstName="ngModel"
                                                class="form-control" [(ngModel)]="userData.firstname"
                                                [required]="true" />
                                        </pos-form-field>
                                    </pos-column>

                                    <pos-column>
                                        <pos-form-field label="Last Name"
                                            [validationMessages]="{required: 'Please enter last name.'}">
                                            <input posInput type="text" name="lastName" #lastName="ngModel"
                                                class="form-control" [(ngModel)]="userData.lastname"
                                                [required]="true" />
                                        </pos-form-field>
                                    </pos-column>

                                    <pos-column>
                                        <pos-form-field label="Login Code" *ngIf="!userData.id"
                                            [validationMessages]="{required: 'Please enter login code.', invalidLoginCode: 'Login code must have at least 4 digits.'}">
                                            <input posInput type="password" name="loginCode" #loginCode="ngModel"
                                                maxlength="8" class="form-control" [(ngModel)]="userData.code"
                                                [required]="true" />
                                        </pos-form-field>
                                    </pos-column>

                                    <pos-column>
                                        <pos-form-field label="Badge Number">
                                            <input posInput type="text" name="badgeNumber" #badgeNumber="ngModel"
                                                class="form-control" [(ngModel)]="userData.BadgeNumber"
                                                (keypress)="checkEnter($event)" (focus)="userSwipeBroadCast(false)"
                                                (blur)="userSwipeBroadCast(true)" autocomplete="off" />
                                        </pos-form-field>
                                    </pos-column>

                                    <pos-column>
                                        <pos-form-field>
                                            <p-checkbox posInput name="isAllowExternalLogin"
                                                #isAllowExternalLogin="ngModel"
                                                [(ngModel)]="userData.IsAllowExternalLogin" binary="true"
                                                (onChange)="allowExternalLoginChanged()" label="Allow External Login">
                                            </p-checkbox>
                                        </pos-form-field>
                                    </pos-column>

                                    <pos-column>
                                        <pos-form-field label="External Login Password"
                                            [validationMessages]="{required: 'Please enter external password.'}"
                                            *ngIf="(!isResetPasswordDisplay || !userData.id) && userData.IsAllowExternalLogin">
                                            <input posInput type="password" name="externalLoginPassword"
                                                #externalLoginPassword="ngModel" class="form-control"
                                                [(ngModel)]="userData.Password" autocomplete="off" maxlength="24"
                                                [required]="(!isResetPasswordDisplay || userData.id === 0) && userData.IsAllowExternalLogin"
                                                [disabled]="!userData.IsAllowExternalLogin" />
                                        </pos-form-field>
                                    </pos-column>

                                    <pos-column>
                                        <pos-form-field label="Email Address"
                                            [validationMessages]="{pattern: 'Please enter valid email address.'}">
                                            <input posInput class="form-control" name="emailAddress"
                                                #emailAddress="ngModel"
                                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                                [(ngModel)]="userData.EmailAddress" type="email" />
                                        </pos-form-field>
                                    </pos-column>

                                    <pos-column>
                                        <pos-form-field label="Text (SMS) Email Address"
                                            [validationMessages]="{pattern: 'Please enter valid text email address.'}">
                                            <input posInput class="form-control" name="textEmailAddress"
                                                #textEmailAddress="ngModel"
                                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                                [(ngModel)]="userData.text_email_address" type="email" />
                                        </pos-form-field>
                                    </pos-column>

                                    <pos-column xs="6">
                                        <pos-row class="p-0"
                                            colStyleClass="no-wrap normal-lg-2">
                                            <pos-column xs="12" md="4">
                                                <pos-form-field inputStyle="col-12">
                                                    <p-checkbox posInput name="timeKeeping" #timeKeeping="ngModel"
                                                        [(ngModel)]="userData.time_keeping" binary="true"
                                                        label="Time Keeping">
                                                    </p-checkbox>
                                                </pos-form-field>
                                            </pos-column>

                                            <pos-column xs="12" md="4">
                                                <pos-form-field inputStyle="col-12">
                                                    <p-checkbox posInput name="isSystemUser" #isSystemUser="ngModel"
                                                        [(ngModel)]="userData.IsSystemUser" binary="true"
                                                        label="System User" [disabled]="atEditChkSystemUser">
                                                    </p-checkbox>
                                                </pos-form-field>
                                            </pos-column>

                                            <pos-column xs="12" md="4">
                                                <pos-form-field inputStyle="col-12">
                                                    <p-checkbox posInput name="isDriver" #isDriver="ngModel"
                                                        [(ngModel)]="userData.is_driver" binary="true" label="Driver">
                                                    </p-checkbox>
                                                </pos-form-field>
                                            </pos-column>
                                        </pos-row>
                                    </pos-column>
                                </pos-row>
                            </div>
                        </div>
                        <div [hidden]="selectedTab == tabList.Account">
                            <div class="tab-top-border border-top-0 pt-0 m-0 overflow-auto-tabs width-100 pos-tab-body"
                                [ngStyle]="{'height':getHeight(false)}">
                                <pos-user-pay-rates-add-edit [userId]="userId"></pos-user-pay-rates-add-edit>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 row g-0 action-button-container page-footer">
                    <div class="col-xl-6 col-md-8 col-12 p-0">
                        <button class="btn-lg btn-success btn normal float-start white ms-pix-15 pos-button"
                            type="button" (click)="resetPassword(passwordType.UserCode)" *ngIf="userData.id > 0">Reset
                            Login Code</button>
                        <button class="btn-lg btn-success btn normal float-start white ms-pix-5 pos-button mt-pix-10 mt-pix-sm-0"
                            type="button" (click)="resetPassword(passwordType.ExternalPassword)"
                            *ngIf="isResetPasswordDisplay && userData.IsAllowExternalLogin">Reset Remote
                            Password</button>
                    </div>
                    <div class="col-xl-6 col-md-4 col-12 p-0 pt-pix-10 pt-pix-md-0">
                        <button class="btn-lg btn-success btn btn-save pos-button" type="submit" value="Apply"
                            (click)="isSaveAndContinue = true" *ngIf="!userId"> Apply </button>
                        <button class="btn-lg btn-primary btn btn-save pos-button" type="submit"
                            value="Save">Save</button>
                        <button class="btn-secondary btn-lg btn btn-cancel pos-button" (click)="cancel()" type="button"
                            value="Cancel">Cancel</button>
                    </div>
                </div>
            </div>
        </context-provider>
    </form>
</pos-edit-wrapper>

<pos-confirm-delete (onConfirmAcknowledge)="onConfirmAcknowledge()" (onCancelAcknowledge)="onCancelAcknowledge()"
    #confirmBadge></pos-confirm-delete>
<ng-template #resetPasswordPopup>
    <div>
        <button (click)="closePasswordDialog(savePasswordForm)" aria-label="Close" class="close"
            type="button">&times;</button>
        <h4 class="modal-content__heading">{{resetPasswordType}}</h4>
    </div>
    <div class="p-0 overflow-hidden">
        <form #savePasswordForm="ngForm" (ngSubmit)="savePassword(savePasswordForm)" class="edit-form"
            [posValidate]="validateUserPassword">
            <div class="col-12 pos-modal-body pt-pix-5">
                <pos-row>
                    <pos-column>
                        <pos-form-field label="New Password"
                            [validationMessages]="{ required: 'Please enter password.', invalidPassword : 'Login code must have at least 4 digits.'}">
                            <input posInput class="form-control" name="password" #password="ngModel"
                                [(ngModel)]="userCodePassword" [required]="true"
                                [maxlength]="passwordType.ExternalPassword == resetPasswordType ? 24 : 8"
                                type="password" autocomplete="off" />
                        </pos-form-field>
                    </pos-column>
                    <pos-column>
                        <pos-form-field label="Confirm Password"
                            [validationMessages]="{ required: 'Please enter confirm password.', invalidConfirmPassword: 'New password and confirm password does not match.'}">
                            <input posInput type="password" name="confirmPasswordInput" #confirmPasswordInput="ngModel"
                                class="form-control" [(ngModel)]="confirmPassword" [required]="true"
                                [maxlength]="passwordType.ExternalPassword == resetPasswordType ? 24 : 8" />
                        </pos-form-field>
                    </pos-column>
                </pos-row>



            </div>
            <div class="clearfix"></div>
            <div class="col-12 action-button-container popup-footer pb-pix-10">
                <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" value="Save">Save</button>
                <button type="button" data-dismiss="modal" class="btn-secondary  btn-lg btn btn-cancel-modal pos-button"
                    (click)="closePasswordDialog(savePasswordForm)">Cancel</button>
            </div>
        </form>
    </div>
</ng-template>
