<pos-banner *ngIf="productWidgetConfig?.WidgetHeaderConfig" [data]="productWidgetConfig.WidgetHeaderConfig"
    [styleData]="widgetStyleConfig?.WidgetHeaderStyleConfig" [imagePath]="imagePath"
    [borderStyle]="widgetStyleConfig?.BorderStyle"></pos-banner>
<div class="display-flex" [ngClass]="interactiveStyleClass" [ngStyle]="{
        'line-height': widgetStyleConfig?.ItemFontStyle?.fontSize,
        'font-size': widgetStyleConfig?.ItemFontStyle?.fontSize,
        'height': widgetStyleConfig?.WidgetHeaderStyleConfig ? 'calc(100% - ' + bannerHeight + ')' : '',
        'justify-content': widgetStyleConfig?.Horizontal,
        'align-items': (widgetStyleConfig?.Vertical  === 'top' ? 'start' : (widgetStyleConfig?.Vertical === 'bottom' ? 'end' : 'center'))
    }">
    <span [hidden]="!salesProduct?.IsInStock &&
                productWidgetConfig?.OutOfStockBehavior?.BehaviorType === outOfStockBehaviorTypes.Hide">
        <span [ngStyle]="widgetStyleConfig?.ItemFontStyle"
            [innerHTML]="(salesProduct?.Index ? salesProduct?.Index + '. ' : '')"></span>
        <pos-menu-image [imagePath]="imagePath" [image]="productWidgetConfig?.Image ?? salesProduct?.Image" [height]="productImageSize"
            [width]="productImageSize" *ngIf="(productWidgetConfig?.Image || salesProduct?.Image) && productWidgetConfig?.IsShowProductImage"
            class="pe-pix-3">
        </pos-menu-image>

        <span [ngStyle]="widgetStyleConfig?.ItemFontStyle">
            <span [innerHTML]="productWidgetConfig?.DisplayText ? productWidgetConfig.DisplayText : salesProduct?.Text"
                [ngStyle]="{'color': salesProduct?.IsInStock ? (widgetStyleConfig?.ItemFontStyle?.color ? widgetStyleConfig.ItemFontStyle.color 
                            : (productWidgetConfig?.IsUseMakeTableColor ? salesProduct?.Color : '')) : (salesProduct?.Id ? (productWidgetConfig?.OutOfStockBehavior?.ItemColor ? productWidgetConfig.OutOfStockBehavior.ItemColor : 'lightgrey') : 'black'),
                            'text-decoration': salesProduct?.IsInStock ? '' : (productWidgetConfig?.OutOfStockBehavior?.TextDecoration ? productWidgetConfig.OutOfStockBehavior.TextDecoration : '')}">
            </span>
        </span>

        <span class="ps-pix-5" [innerHTML]="productWidgetConfig?.OutOfStockBehavior?.Text"
            [ngStyle]="productWidgetConfig?.OutOfStockBehavior?.TextStyleConfig"
            *ngIf="!salesProduct?.IsInStock && productWidgetConfig?.OutOfStockBehavior?.Text && !salesProduct?.WidgetProductGroupId">
        </span>
        <span *ngIf="salesProduct?.Tags?.length > 0 && !productWidgetConfig?.HideTags">
            <span *ngFor="let tag of salesProduct?.Tags">
                <pos-menu-image [imagePath]="tagIconPath" [image]="tag.Icon" [height]="tagIconSize"
                    [width]="tagIconSize" *ngIf="tag.Icon" class="ps-pix-2"></pos-menu-image>
            </span>
        </span>
        <div *ngIf="salesProduct?.Description && productWidgetConfig?.IsShowItemDescription"
            [ngStyle]="{'line-height': widgetStyleConfig?.DescriptionStyle?.fontSize }" class="text-left">
            <div [innerHTML]="salesProduct?.Description" [ngStyle]="widgetStyleConfig?.DescriptionStyle">
            </div>
        </div>
    </span>

    <span *ngIf="salesProduct?.Prices?.length && !productWidgetConfig?.Price"
        [hidden]="(!salesProduct?.IsInStock && productWidgetConfig?.OutOfStockBehavior?.BehaviorType === outOfStockBehaviorTypes.Hide)"
        [ngStyle]="{'min-width': (!salesProduct?.Prices?.length && productWidgetConfig?.IsShowProductPrice) ? '30px' : '',
                                'line-height': widgetStyleConfig?.PriceStyle?.fontSize}">
        <span class="pull-right text-nowrap ps-pix-5 pe-pix-5" [ngStyle]="widgetStyleConfig?.PriceStyle"
            *ngIf="salesProduct?.IsShowPrice || this.productWidgetConfig?.IsShowProductPrice">
            <span *ngFor="let price of salesProduct?.Prices; let last = last;">
                <span [ngStyle]="{'color':!price?.IsInStock ? (productWidgetConfig?.OutOfStockBehavior?.ItemColor ? productWidgetConfig.OutOfStockBehavior.ItemColor : 'grey'):''}"
                    [innerHTML]="(productWidgetConfig?.IsShowPriceSize ? price.MakeTableSize + ' ' : '') + (price.Price | number: '0.2-2') + (last ? '' : '/')"> </span>
            </span>
        </span>
    </span>
    <span *ngIf="!salesProduct?.Prices?.length || productWidgetConfig?.Price" [innerHTML]="productWidgetConfig?.Price ?? salesProduct?.Price" [ngClass]="interactiveStyleClass"
        [ngStyle]="widgetStyleConfig?.PriceStyle" class="ps-pix-5 pe-pix-5">
    </span>
</div>
