import { Component, OnInit, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as _ from 'lodash';
import {
  productInformationInfoCircle, inventorySetupBoxes, vendorDetailsTruck, workflowArrowCircleRight, nutritionFacts,
  barcode, inventoryLocationsArrow, inventoryProductIngredientStore, exclamationTriangle, productSimplyBuilt, plusWhite, editWhiteWithText, inventoryProductsStore, times, printing
} from 'src/app/shared/components/icon';
import {
  SpinnerService, AlertsService, ApplicationStateService, DomainConstants, Messages, ModalService, RuntimeConstants,
  InfoModalComponent, InventoryProductDietaryWarningService, TaskService, Features, Levels, Permissions, StringUtils, InventoryProductBarCodeService
} from 'src/app/shared';

import { ServicingInformationService, InventoryProductNutritionService, InventoryProductNutritionComponent } from 'src/app/information-management/nutrition-facts';
import { InventoryProductNutrition } from 'src/app/shared/interface/inventory-product-nutrition';
import { InventoryProductDietaryWarningComponent } from 'src/app/information-management/dietary-warnings';
import { InventorySubCategoryService } from 'src/app/information-management/inventory-subcategories';
import { VendorService, Vendor } from 'src/app/information-management/vendors';
import { UnitService, Unit } from 'src/app/information-management/units';
import { TerminalsService } from 'src/app/configurator';
import { InventoryZoneService } from 'src/app/shared/services/inventory-zone.service';
import { Task, TaskEditComponent } from 'src/app/information-management/tasks';
import { InvProduct } from '../../../../shared/interface/inventory-product';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { PrepLabelPrintComponent } from 'src/app/information-management/prep-label-print';
import { InventoryProductFieldInfoMessages } from '../../constants/inventory-product-field-info-messages';
import { DuplicateIngredientListComponent } from '../duplicate-ingredient-list/duplicate-ingredient-list.component';
import { LabelPrintIntermediaryComponent } from '../label-print-intermediary/label-print-intermediary.component';
import { HardwareService } from 'src/app/shared/services/hardware.service';
import { InventoryProductService } from 'src/app/shared/services/inventory-product.service';
import Pluralize from 'pluralize';
import { ActivatedRoute, Router } from '@angular/router';
import { ExtensionValidationService } from 'src/app/shared/services/extension-validation.service';
declare let $: any;

@Component({
  selector: 'pos-inventory-product-edit',
  templateUrl: './inventory-product-edit.component.html',
  styleUrls: ['./inventory-product-edit.component.scss']
})
export class InventoryProductEditComponent implements OnInit {


  @Output() close = new EventEmitter<any>();
  @Input('isScroll') isScroll: boolean = false;
  @Input('inventoryProductName') inventoryProductName: string = '';
  @Input('inventoryProductId') inventoryProductId: number = null;
  @Input('additionalParameters') additionalParameters: any;
  @Output() formChange = new EventEmitter<NgForm>();
  @ViewChild('formInventoryProduct')
  formInventoryProduct: NgForm;
  @ViewChild('inventoryProductDietaryWarningComponent') inventoryProductDietaryWarningComponent: InventoryProductDietaryWarningComponent;
  @ViewChild('inventoryProductNutritionInfoComponent') inventoryProductNutritionInfoComponent: InventoryProductNutritionComponent;
  features = Features;
  imagePath = RuntimeConstants.IMAGE_BASE_PATH + '/instructions';
  outerContainerHeight: any;
  innerContainerHeight: any;
  servingInformationData: any;
  dietaryWarningList: Array<any> = [];
  inventoryProduct: InvProduct;
  invSubCatDetailsList: Array<any> = [];
  vendorDetailsList: Array<Vendor> = [];
  unitDetailsList: Array<Unit> = [];
  terminalDetailsList: Array<any> = [];
  inventoryZoneList: Array<any> = [];
  inventoryBinList: Array<any> = [];
  inventoryPrimaryBinList: Array<any> = [];
  inventorySecondaryBinList: Array<any> = [];
  autoDecrementByList: Array<any> = [];
  inventoryProductTasksList: Array<Task> = [];
  nutritionInfoList: Array<InventoryProductNutrition> = [];
  labelPrintersList: Array<any> = [];
  selectedUnit: Unit;
  receivedDay: string;
  showImageIcon: boolean;
  isValidServingInformation: boolean = false;
  isSubmit: boolean = false;
  saveButtonPressToProceed: string = '';
  selectedPrimaryZone: any;
  selectedPrimaryBin: any = null;
  selectedSecondaryZone: any;
  selectedSecondaryBin: any;
  measuringInstructionsWarning: string;
  image: File;
  fieldInfoMessages: any;
  isSaveAndContinue = false;

  icons = {
    productInformationInfoCircle,
    inventorySetupBoxes,
    vendorDetailsTruck,
    workflowArrowCircleRight,
    barcode,
    inventoryLocationsArrow,
    inventoryProductIngredientStore,
    exclamationTriangle,
    productSimplyBuilt,
    plusWhite,
    editWhiteWithText,
    nutritionFacts,
    inventoryProductsStore, times,
    printing
  };
  formGroups = {
    'ProductsInfo': { '$valid': true },
    'ProductSetup': { '$valid': true },
    'ProductVendor': { '$valid': true },
    'ProductWorkflow': { '$valid': true }
  };
  tabList = {
    ProductsInfo: 'ProductsInfo',
    ProductSetup: 'ProductSetup',
    ProductVendor: 'ProductVendor',
    ProductWorkflow: 'ProductWorkflow',
    ProductBarcodes: 'ProductBarcodes',
    ProductInventoryLocations: 'ProductInventoryLocations',
    ProductRawIngredients: 'ProductRawIngredients',
    ProductNutritionFacts: 'ProductNutritionFacts',
    ProductDietaryWarnings: 'ProductDietaryWarnings',
    ProductAliases: 'ProductAliases'
  };
  permission = {
    tasks: Permissions.OperationsTasks,
    editLevel: Levels.Edit,
  };
  selectedTab = this.tabList.ProductsInfo;
  Pluralize = Pluralize;
  dateFormat = 'mm-dd-yyyy';
  hourFormat = '12';
  constructor(private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private inventoryProductService: InventoryProductService,
    private servicingInformationService: ServicingInformationService,
    private inventoryProductNutritionService: InventoryProductNutritionService,
    private inventoryProductDietaryWarningService: InventoryProductDietaryWarningService,
    private inventorySubCategoryService: InventorySubCategoryService,
    private vendorService: VendorService,
    private unitService: UnitService,
    private terminalService: TerminalsService,
    private inventoryZoneService: InventoryZoneService,
    private taskService: TaskService,
    private applicationStateService: ApplicationStateService,
    private modalService: ModalService,
    private hardwareService: HardwareService,
    private route: ActivatedRoute,
    private router: Router,
    private extensionValidation: ExtensionValidationService,
    private inventoryProductBarCodeService: InventoryProductBarCodeService) {
    this.fieldInfoMessages = InventoryProductFieldInfoMessages;
  }

  ngOnInit() {
    this.resetDefaults();
    this.getProductDetails();
    this.getHeight();
    $(window).resize(() => {
      this.getHeight();
    });
    this.dateFormat = this.applicationStateService.settingParam.PCalendarDateFormat;
    this.hourFormat = this.applicationStateService.settingParam.PCalendarHourFormat;
  }

  resetDefaults() {
    this.inventoryProduct = this.inventoryProductService.newInventoryProduct();
    this.invSubCatDetailsList = [];
    this.vendorDetailsList = [];
    this.unitDetailsList = [];
    this.terminalDetailsList = [];
    this.inventoryZoneList = [];
    this.inventoryBinList = [];
    this.inventoryPrimaryBinList = [];
    this.autoDecrementByList = [];
    this.inventoryProductTasksList = [];
    this.servingInformationData = {};
    this.nutritionInfoList = [];
    this.dietaryWarningList = [];
    this.selectedUnit = this.unitService.newUnit();
    this.receivedDay = 'false';
    this.showImageIcon = false;
    this.inventorySecondaryBinList = [];
    this.isValidServingInformation = false;
    this.isSubmit = false;
    this.saveButtonPressToProceed = Messages.PressApplyButtonToProceed;
    this.selectedPrimaryZone = null;
    this.selectedPrimaryBin = null;
    this.selectedSecondaryZone = null;
    this.selectedSecondaryBin = null;
    this.measuringInstructionsWarning = Messages.InventoryProductMeasuringInstructionsWarning;
  }

  getProductDetails() {
    this.spinnerService.show();
    const inventoryProductObservables = [];
    this.servingInformationData = this.servicingInformationService.servingObject();
    this.nutritionInfoList.push(this.inventoryProductNutritionService.newInventoryProductNutrition(this.inventoryProductId));
    this.dietaryWarningList.push(this.inventoryProductDietaryWarningService.newInventoryProductDietaryWarning(this.inventoryProductId));
    inventoryProductObservables.push(this.inventorySubCategoryService.getInventorySubcategoryList());
    inventoryProductObservables.push(this.vendorService.getVendorList());
    inventoryProductObservables.push(this.unitService.getUnits());
    inventoryProductObservables.push(this.terminalService.getTerminalSummary());
    inventoryProductObservables.push(this.inventoryZoneService.getInventoryZones());
    inventoryProductObservables.push(this.inventoryZoneService.getInventoryBins(0));
    inventoryProductObservables.push(this.inventoryProductService.getAutoDecrementBy());
    inventoryProductObservables.push(this.taskService.getTaskList());
    if (this.inventoryProductId) {
      inventoryProductObservables.push(this.servicingInformationService.getServingInformationByInventoryId(this.inventoryProductId));
      inventoryProductObservables.push(this.inventoryProductService.getInventoryProduct(this.inventoryProductId));
      inventoryProductObservables.push(this.inventoryProductNutritionService.getNutritionsByInventoryProductId(this.inventoryProductId));
      inventoryProductObservables.push(
        this.inventoryProductDietaryWarningService.getDietaryWarningsByInventoryProductId(this.inventoryProductId)
      );
      inventoryProductObservables.push(this.hardwareService.getHardwaresOfType(DomainConstants.HardwareTypes.LabelPrinter));
    } else {
      this.inventoryProduct = this.inventoryProductService.newInventoryProduct();
      this.inventoryProduct.vendor_id = this.additionalParameters &&
        this.additionalParameters.VendorId ? this.additionalParameters.VendorId : null;
      this.inventoryProduct.UserId = this.applicationStateService.userId;
      this.inventoryProduct.Parameters = this.applicationStateService.settingParam;
    }

    forkJoin(inventoryProductObservables)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: responses => {
          if (responses) {
            this.getInventoryDetailCompleted(responses);
          }
          this.formChange.emit(this.formInventoryProduct);
        }, error: this.alertService.showApiError
      });
  }

  getInventoryDetailCompleted(responses: any) {
    this.invSubCatDetailsList = responses[0] ? responses[0] : [];
    this.vendorDetailsList = responses[1] ? responses[1] : [];
    this.unitDetailsList = responses[2] ? responses[2] : [];
    if (responses[3]) {
      this.terminalDetailsList = _.filter(responses[3], (terminal) => {
        return terminal.TerminalType === DomainConstants.TerminalTypes.ORDER_ENTRY.Name;
      });
      this.terminalDetailsList = _.orderBy(this.terminalDetailsList, 'TerminalName');
    }
    if (responses[4]) {
      this.inventoryZoneList = responses[4];      
    }
    if (responses[5]) {
      this.inventoryBinList = responses[5];
      this.inventoryPrimaryBinList = responses[5];
    }
    this.autoDecrementByList = responses[6] ? responses[6] : [];
    this.inventoryProductTasksList = responses[7] ? responses[7] : [];
    if (this.inventoryProductId) {
      if (responses[8]) {
        this.servingInformationData = responses[8];
      } else {
        this.servingInformationData.InventoryProductId = this.inventoryProductId;
      }
      this.inventoryProduct = responses[9] ? responses[9] : this.inventoryProduct;
      this.inventoryProduct.price = responses[9]?.price.toFixed(2);
      this.inventoryProduct.IsExhaustionPromptToTerminalId = this.inventoryProduct.IsExhaustionPromptToTerminalId ?? false;
      this.nutritionInfoList = responses[10] ? responses[10] : [];
      this.dietaryWarningList = responses[11] ? responses[11] : [];
      this.labelPrintersList = responses[12] ? responses[12] : [];
      this.dietaryWarningList.push(this.inventoryProductDietaryWarningService.newInventoryProductDietaryWarning(this.inventoryProductId));
      this.nutritionInfoList.push(this.inventoryProductNutritionService.newInventoryProductNutrition(this.inventoryProductId));
    }
    this.prepareData();
  }

  public handleFileInput(files: FileList) {
    this.image = files.item(0);
    const fileToUpload = this.image ? this.image.name : '';
    if (this.extensionValidation.isInvalidExtension(fileToUpload, ['.jpg', '.bmp', '.png', '.gif', '.webp'])) {
      if (fileToUpload) {
        this.alertService.renderErrorMessage(Messages.InvalidInventoryProductImageType);
      }
      this.removeIcon();
      return;
    }
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        $('#ingredientImage').attr('src', reader.result);
      };
      reader.readAsDataURL(files[0]);
      this.inventoryProduct.IngredientImage = this.image ? this.image.name : '';
      this.showImageIcon = true;
    }
  }

  public removeIcon(): void {
    this.inventoryProduct.IngredientImage = '';
    $('#image').val('');
    this.image = null;
    this.showImageIcon = false;
  }
  prepareData() {
    if (this.inventoryProduct.ExhaustionPromptToTerminalId) {
      this.inventoryProduct.IsExhaustionPromptToTerminalId = true;
    }
    this.inventoryProduct.common = !this.inventoryProduct.common;
    this.receivedDay = this.inventoryProduct.LogAfterReceivedDays > 0 ? 'true' : 'false';
    this.inventoryProduct.LogDueDateOverride = this.inventoryProduct.LogDueDateOverride ?
      new Date(this.inventoryProduct.LogDueDateOverride) : null;
    this.inventoryProduct.InventoryBaselineDate = this.inventoryProduct.InventoryBaselineDate ?
      new Date(this.inventoryProduct.InventoryBaselineDate) : null;
    if (!this.inventoryProduct.IngredientImage) {
      this.inventoryProduct.IngredientImage = '';
      this.showImageIcon = false;
    } else {
      this.showImageIcon = true;
    }
    if (this.inventoryProduct.PrimaryZoneId) {
      this.getPrimaryBinList();
      this.selectedPrimaryZone = _.find(this.inventoryZoneList, (zone) => {
        return zone.Id == this.inventoryProduct.PrimaryZoneId;
      });
      this.selectedPrimaryBin = _.find(this.inventoryPrimaryBinList, (bin) => {
        return bin.Id == this.inventoryProduct.PrimaryBinId;
      });
      this.selectedPrimaryBin = this.selectedPrimaryBin ?? null
    }
    if (this.inventoryProduct.SecondaryZoneId) {
      this.getSecondaryBinList();
      this.selectedSecondaryZone = _.find(this.inventoryZoneList, (zone) => {
        return zone.Id == this.inventoryProduct.SecondaryZoneId;
      });
      this.selectedSecondaryBin = _.find(this.inventorySecondaryBinList, (bin) => {
        return bin.Id == this.inventoryProduct.SecondaryBinId;
      });
    }
    this.inventoryProduct.selectedTask = this.inventoryProduct.MinTaskId ? this.inventoryProduct.MinTaskId : null;
    if (this.inventoryProduct.selectedTask) {
      this.inventoryProduct.IsMinQuantityReachedTaskScheduled = true;
      this.inventoryProduct.TaskTime = this.inventoryProduct.MinTaskTiming ?
        this.parseTime(this.inventoryProduct.MinTaskTimeString) : new Date();
      this.inventoryProduct.TaskWhen = this.inventoryProduct.MinTaskTiming;

    } else {
      this.inventoryProduct.IsMinQuantityReachedTaskScheduled = false;
      this.inventoryProduct.TaskTime = new Date();
      this.inventoryProduct.TaskWhen = '0';
    }
    this.getUnitName();
    this.vendorDetailsList = _.filter(this.vendorDetailsList, (vendor) => {
      return vendor.active || (this.inventoryProduct && vendor.id === this.inventoryProduct.vendor_id);
    });
  }


  saveProductDetails(isValid: boolean) {
    if (this.inventoryProduct.price < 0 || !isValid) {
      this.isSaveAndContinue = false;
      return;
    }
    this.isSubmit = true;
  }

  changeAutoDecrement() {
    if (!this.inventoryProduct.AutoDecrementBy) {
      this.inventoryProduct.AutoDecrementBy = 'None';
    }
    this.inventoryProduct.AutoDecrementQty = this.inventoryProduct.AutoDecrementBy === 'None' ||
      !this.inventoryProduct.AutoDecrementBy ? null : 0;
  }

  showUnitChangedWarning() {
    if (this.inventoryProduct.unit_id) {
      this.getUnitName();
    }
    if (this.inventoryProductId && this.selectedUnit) {
      $('#divSalesProductSuccessErrorMsg').show();
      $('#divSalesProductSuccessErrorMsg').html(
        "Please verify the following:<br> <ul class='pad20 row px-3 bullatedList' style='margin-bottom: 5px !important'> " +
        " <span class='w-fit-content pr-3'><li>Unit Qty</li></span><span class='w-fit-content pr-3'><li>Min</li></span><span class='w-fit-content pr-3'> " +
        "<li>Max</li></span><span class='w-fit-content pr-3'><li>Recipe</li></span></ul>"
      );
      this.getHeight(55)
      setTimeout(() => {
        $('#divSalesProductSuccessErrorMsg').fadeOut('slow');
        this.getHeight();
      }, 20000);
    }
  }


  getUnitName() {
    this.selectedUnit = _.find(this.unitDetailsList, (unit) => {
      return unit.Id === this.inventoryProduct.unit_id;
    });
    if (!this.selectedUnit) {
      this.selectedUnit = this.unitService.newUnit();
    }
  }

  onCancel(reload: boolean, inventoryProduct?: any) {
    this.close.emit({ shouldReload: reload, inventoryProduct: inventoryProduct });
  }

  getHeight(reduceSize = 0) {
    this.outerContainerHeight = $(window).height() - (310 + reduceSize) + 'px';
    this.innerContainerHeight = $(window).height() - (311 + reduceSize) + 'px';
  }

  setTaskControls() {
    if (!this.inventoryProduct.IsMinQuantityReachedTaskScheduled) {
      this.inventoryProduct.selectedTask = null;
      this.inventoryProduct.TaskTime = new Date();
      this.inventoryProduct.TaskWhen = '0';
    }
  }

  getPrimaryBinList() {
    if (this.inventoryProduct.PrimaryZoneId) {
      this.inventoryPrimaryBinList = [];
      this.prepareBinList(this.inventoryPrimaryBinList, this.inventoryProduct.PrimaryZoneId);
    }
  }

  getSecondaryBinList() {
    if (this.inventoryProduct.SecondaryZoneId) {
      this.inventorySecondaryBinList = [];
      this.prepareBinList(this.inventorySecondaryBinList, this.inventoryProduct.SecondaryZoneId);
    }
  }

  prepareBinList(binListArray, zoneId) {
    _.forEach(this.inventoryBinList, (inventoryBin) => {
      if (zoneId === inventoryBin.Zone_id) {
        binListArray.push(inventoryBin);
      }
    });
  }

  showPrintStockLabel() {
    if (this.labelPrintersList.length > 0) {
      const prepLabelModalRef = this.modalService.show(PrepLabelPrintComponent, {
        animated: false,
        class: 'vertical-center',
        initialState: {
          data: true,
          productId: this.inventoryProduct.id,
          productName: this.inventoryProduct.name
        }
      });

      prepLabelModalRef.close.subscribe(res => {
      });
    } else {
      this.modalService.show(InfoModalComponent, {
        animated: false,
        class: 'vertical-center',
        initialState: {
          message: Messages.LabelPrinterNotConfigured
        }
      });
    }
  }

  printLabel() {
    if (this.inventoryProductId) {
      if (this.labelPrintersList.length > 0) {
        this.modalService.show(LabelPrintIntermediaryComponent, {
          animated: false,
          class: 'vertical-center',
          initialState: {
            data: true,
            inventoryProduct: _.cloneDeep(this.inventoryProduct)
          }
        });

      } else {
        this.modalService.show(InfoModalComponent, {
          animated: false,
          class: 'vertical-center',
          initialState: {
            message: Messages.LabelPrinterNotConfigured
          }
        });
      }
    }
  }

  changePrimaryBinList() {
    this.selectedPrimaryBin = null;
    if (this.selectedPrimaryZone) {
      this.inventoryProduct.PrimaryZoneId = this.selectedPrimaryZone.Id;
      this.getPrimaryBinList();
    }
  }

  changeSecondaryBinList() {
    this.selectedSecondaryBin = null;
    if (this.selectedSecondaryZone) {
      this.inventoryProduct.SecondaryZoneId = this.selectedSecondaryZone.Id;
      this.getSecondaryBinList();
    }
  }

  onChangeExhaustionPrompt() {
    if (!this.inventoryProduct.IsExhaustionPromptToTerminalId) {
      this.inventoryProduct.ExhaustionPromptToTerminalId = null;
    }
  }

  taskAdd(taskId: number) {
    const taskModalRef = this.modalService.show(TaskEditComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        data: true,
        taskId: taskId
      }
    });

    taskModalRef.close.subscribe(res => {
      if (res && res.shouldReload) {
        this.onSaveTask(res);
      }
    });
  }

  onSaveTask(res) {
    let taskObj;
    this.inventoryProductTasksList = [];
    this.spinnerService.show();
    this.taskService.getTaskList()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: response => {
          if (response) {
            this.inventoryProductTasksList = response;
            if (res && res.id) {
              taskObj = _.find(this.inventoryProductTasksList, (task) => {
                return task.id === res.id;
              });
            } else {
              taskObj = _.find(this.inventoryProductTasksList, (task) => {
                return task.taskname === res.taskname;
              });
            }
            this.inventoryProduct.selectedTask = taskObj.id;
          }
        }, error: this.alertService.showApiError
      });
  }

  onSaveServingInformation(valid) {
    this.isValidServingInformation = valid;
    if (this.inventoryProduct.max_days_between_inventory == 0) {
      this.inventoryProduct.max_days_between_inventory = null;
    }
    if (this.isValidServingInformation) {
      this.inventoryProduct.PrimaryZoneId = this.selectedPrimaryZone?.Id;
      this.inventoryProduct.PrimaryBinId = this.selectedPrimaryBin?.Id;
      this.inventoryProduct.SecondaryZoneId = this.selectedSecondaryZone?.Id;
      this.inventoryProduct.SecondaryBinId = this.selectedSecondaryBin?.Id;

      if (this.checkDuplicateIngredient()) {
        this.isSubmit = false;
        return;
      }

      this.inventoryProduct.servingInformationData = this.servingInformationData;
      this.setNutritionAndDietaryWarnings();

      this.inventoryProduct.ConsumptionThresholdLowAmount = !this.inventoryProduct.ConsumptionThresholdLowAmount ? 0
        : this.inventoryProduct.ConsumptionThresholdLowAmount;
      this.inventoryProduct.ConsumptionThresholdHighAmount = !this.inventoryProduct.ConsumptionThresholdHighAmount ? 0
        : this.inventoryProduct.ConsumptionThresholdHighAmount;

      if (this.inventoryProduct.IsMinQuantityReachedTaskScheduled && this.inventoryProduct.selectedTask) {
        this.inventoryProduct.MinTaskId = this.inventoryProduct.selectedTask;
        this.inventoryProduct.MinTaskTiming = this.inventoryProduct.TaskWhen;
        this.inventoryProduct.MinTaskTimeString = parseInt(this.inventoryProduct.TaskWhen, null) !== 0 ?
          this.getTimeString(this.inventoryProduct.TaskTime) : null;
      } else {
        this.inventoryProduct.MinTaskId = null;
        this.inventoryProduct.MinTaskTiming = null;
        this.inventoryProduct.MinTaskTimeString = null;
      }

      this.inventoryProduct.LogAfterReceivedDays = this.inventoryProduct.IsLogAfterReceived && this.receivedDay == 'true' ?
        this.inventoryProduct.LogAfterReceivedDays : 0;
      if (this.applicationStateService.userId) {
        this.inventoryProduct.UserId = this.applicationStateService.userId;
      }
      this.inventoryProduct.common = !this.inventoryProduct.common;
      this.setParentIngredient();
      this.saveInventoryProduct();

    } else {
      this.isSubmit = false;
    }
  }
  openDuplicateIngredientModel = (duplicateIngredients) => {
    this.setParentIngredient();
    const modalRef = this.modalService.getModalWrapper(DuplicateIngredientListComponent);
    modalRef.show({
      animated: false,
      keyboard: false,
      class: 'vertical-center modal-md',
      initialState: {
        ingredients: duplicateIngredients
      }
    });
  }

  barcodeTabClicked = () => {
    setTimeout(() => {
      $('#barcodeName').focus();
    });
  }

  setParentIngredient = () => {
    _.forEach(this.inventoryProduct.Ingredients, (ingredient: any) => {
      ingredient.RawIngredient = ingredient.RawIngredient.trim();
      ingredient.ParentIngredient = ingredient.ParentIngredient ? ingredient.ParentIngredient.RawIngredient.trim() : null;
    });
  }

  getMaxLevelFromIngredients = (): number => {
    let maxLevel = 0;
    _.forEach(this.inventoryProduct.Ingredients, (ingredient: any) => {
      const level = ingredient.OrdinalString.split('.').length ? ingredient.OrdinalString.split('.').length - 1 : 0;
      if (level > maxLevel) {
        maxLevel = level;
      }
    });
    return maxLevel;
  }

  checkDuplicateIngredient = () => {
    let duplicateIngredients = [];
    const maxAvailableLevel = this.getMaxLevelFromIngredients();
    for (let level = 0; level <= maxAvailableLevel; level++) {
      const ingredientGroup = [];
      _.forEach(this.inventoryProduct.Ingredients, (ingredient: any) => {
        if (ingredient.Level == level && ingredient.RawIngredient) {
          ingredientGroup.push(ingredient);
        }
      });
      if (ingredientGroup && ingredientGroup.length > 0) {
        const ingredientsGroupByParent = _.groupBy(ingredientGroup, (ing) => {
          return ing.OrdinalString.substr(0, ing.Level ? ing.OrdinalString.lastIndexOf('.') : '');
        });
        _.forEach(ingredientsGroupByParent, (ing) => {
          if (ing.length) {
            const duplicateIngredient = _.groupBy(ing, 'RawIngredient');
            _.forEach(duplicateIngredient, (duplicateIng) => {
              if (duplicateIng.length > 1) {
                duplicateIngredients.push(duplicateIng[0]);
              }
            });
          }
        });
      }
    }
    if (duplicateIngredients && duplicateIngredients.length > 0) {
      this.openDuplicateIngredientModel(duplicateIngredients);
    }
    return duplicateIngredients.length > 0;
  }

  private saveInventoryProduct() {
    this.spinnerService.show();
    this.inventoryProductService.saveInventoryProduct(this.inventoryProduct, this.image)
      .pipe(finalize(() => {
        this.isSubmit = false;
        this.isValidServingInformation = false;
        this.spinnerService.hide();
      }))
      .subscribe({
        next: response => {
          if (response) {
            this.alertService.renderSuccessMessage(Messages.InventoryProductSaveSuccess);
            const obj = {
              inventoryProduct: response.InventoryProducts.length > 0 ? response.InventoryProducts[0] : {},
              shouldReload: true
            };
            this.inventoryProductId = obj.inventoryProduct.id;
            this.inventoryProductName = obj.inventoryProduct.name;
            if (!this.isSaveAndContinue) {
              this.close.emit(obj);
            } else {
              this.isSaveAndContinue = false;
              this.router.routeReuseStrategy.shouldReuseRoute = () => false;
              this.router.onSameUrlNavigation = 'reload';
              this.router.navigate(['inventory-products', this.inventoryProductId], {
                state: {
                  inventoryProductId: this.inventoryProductId,
                  isScroll: false,
                  inventoryProductName: this.inventoryProductName ?? ''
                },
                relativeTo: this.route.parent
              });
              this.router.onSameUrlNavigation = 'ignore';
            }
          }
        }, error: this.alertService.showApiError
      });
  }

  private setNutritionAndDietaryWarnings() {
    let nutritions = [];
    _.forEach(this.inventoryProductNutritionInfoComponent?.nutritionInfo, (nutrition) => {
      if (nutrition.Percent < 0) {
        nutrition.Percent = null;
      }
      if (nutrition.NutritionFactElementId > 0) {
        nutrition.NutritionFactElement = null;
        nutrition.NutritionFactMeasure = null;
        nutritions.push(nutrition);
      }
    });
    this.inventoryProduct.NutritionInfo = nutritions;
    let dietaryWarningInfo = [];
    _.forEach(this.inventoryProductDietaryWarningComponent?.dietaryWarningInfo, (dietaryWarning) => {
      if (dietaryWarning.DietaryWarningId > 0 && dietaryWarning.DietaryWarningTypeId > 0) {
        dietaryWarning.DietaryWarning = null;
        dietaryWarning.DietaryWarningType = null;
        dietaryWarningInfo.push(dietaryWarning);
      }
    });
    this.inventoryProduct.DietaryWarningInfo = dietaryWarningInfo;
  }

  onSaveIngredients(data) {
    if (data && data.valid) {
      this.inventoryProduct.Ingredients = data.ingredient;
      if (this.inventoryProduct.id) {
        this.inventoryProduct.RemovedIngredients = data.deletedIngredients;
      }
    } else {
      this.isSubmit = false;
    }

  }

  parseTime(timeString) {
    if (timeString === '') { return null; }

    const time = timeString.match(/(\d+)(:(\d\d))?\s*(p?)/i);
    if (time == null) { return null; }

    let hours = parseInt(time[1], 10);
    if (hours === 12 && !time[4]) {
      hours = 0;
    } else {
      hours += (hours < 12 && time[4]) ? 12 : 0;
    }
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(parseInt(time[3], 10) || 0);
    date.setSeconds(0, 0);
    return date;
  }

  getTimeString(date) {
    let hour = date.getHours();
    let minute = date.getMinutes();
    let ap = 'AM';
    if (hour > 11) {
      ap = 'PM';
    }
    if (hour > 12) {
      hour = hour - 12;
    }
    if (hour === 0) {
      hour = 12;
    }
    if (hour < 10) {
      hour = '0' + hour;
    }
    if (minute < 10) {
      minute = '0' + minute;
    }
    const timeString = hour + ':' + minute + ' ' + ap;
    return timeString;
  }

  printProductBarCode() {
    this.spinnerService.show();
    this.inventoryProductBarCodeService.printInventoryProductBarCodes(this.applicationStateService.terminalId, [this.inventoryProductId])
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe({
        next: () => {
          this.alertService.renderSuccessMessage(Messages.PrintBarcodeRequestSent);
        },
        error: this.alertService.showApiError
      })
  }
}
