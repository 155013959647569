<pos-edit-wrapper (close)="onCancel()" [isPopup]="false" [headingIcon]="icons.tag"
  heading="{{id > 0 ? 'Edit Tag' : 'Add Tag'}}">
  <form #tagForm="ngForm" (ngSubmit)="submit(tagForm.valid)">
    <context-provider provide="props">
      <div class="col-12 pos-page-body border-top pt-pix-5">
        <div class="col-12">
          <pos-row>
            <pos-column>
              <pos-form-field label="Name" [validationMessages]="{required: 'Please enter tag name.'}">
                <input posInput name="tagName" #tagName="ngModel" type="text" class="form-control" [(ngModel)]="tag.Name"
                  [required]="true" />
              </pos-form-field>
            </pos-column>

            <pos-column>
              <pos-form-field label="Category" [validationMessages]="{required: 'Please select category.'}">
                <ng-select posInput *ngIf="tagCategories && tagCategories.length" name="tagCategory" #tagCategory="ngModel"
                  appendTo="body" placeholder="Select category" [(ngModel)]="tag.Category" [required]="true"
                  [items]="tagCategories" bindLabel="Name" bindValue="Value"></ng-select>
              </pos-form-field>
            </pos-column>

            <pos-column>
              <pos-form-field label="Icon" inputStyle="col-xs-6">
                <pos-row>
                  <pos-column lg="6" md="10" sm="9" xs="12">
                    <input posInput type="file" class="form-control" accept=".png,.jpg,.jpeg,.gif,.bmp" #tagIcon name="tagIcon"
                      id="tagIcon" (change)="handleFileInput($event.target.files)" />
                    <p class="green">{{recommendedSizeMessage}}</p>
                  </pos-column>

                  <pos-column md="1" xs="3" class="p-0 col-50 mt-pix-n1" [hidden]="!showTagIcon && tag.Icon == ''">
                    <img class="icon" src="{{imagePath}}/{{tag.Icon}}" name="tagImage" #tagImage id="tagImage" />
                    <div class="mt-pix-n7 relative float-end" (click)="removeIcon()">
                      <pos-icon [icon]="icons.times" class="pointer ms-pix-n7 remove-icon-badge"></pos-icon>
                    </div>
                  </pos-column>
                </pos-row>
              </pos-form-field>
            </pos-column>

            <pos-column>
              <pos-form-field label="Color">
                <pos-color-picker posInput name="prepButtonColor" [(ngModel)]="tag.Color">
                </pos-color-picker>
              </pos-form-field>
            </pos-column>

            <pos-column>
              <pos-form-field>
                <p-checkbox posInput class="chk-section__input" name="featured" #featured="ngModel"
                  [(ngModel)]="tag.IsSearchable" binary="true" label="Searchable">
                </p-checkbox>
              </pos-form-field>
            </pos-column>
          </pos-row>
        </div>
      </div>
      <div class="col-12 action-button-container page-footer">
        <div class="form-horizontal">
          <button type="submit" class="btn-primary btn-lg btn btn-save pos-button pos-button" value="Save">
            Save
          </button>
          <button type="button" class="btn-secondary btn-lg btn btn-cancel pos-button pos-button" value="Cancel" (click)="onCancel()">
            Cancel
          </button>
        </div>
      </div>
    </context-provider>
  </form>
</pos-edit-wrapper>
