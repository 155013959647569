<pos-list-wrapper [headingIcon]="icons.hardwareToolbox" heading="Terminal Configuration List" (close)="close()">
  <div class="row">
    <div class="col-12 position-relative ">
      <div class="label-section active-label">
        <p-checkbox name="chkActive" #chkActive="ngModel" (onChange)="changeHardwareList()" [(ngModel)]="isInactive"
          binary="true" label="Show Inactive" labelStyleClass="font-weight-600" />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <ng-table #hardwareTable [columns]="hardwareColumns" [data]="hardwareList" [search]="true" [paginator]="false">
      </ng-table>
      <ng-template let-data="rowData" #activeTemplate>
        <span *ngIf="(data.IsActive)" class="green vertical-mid font-13">
          <pos-icon [icon]="icons.check"></pos-icon>
        </span>
      </ng-template>
      <ng-template let-data="rowData" #operationHeaderTemplate>
        <pos-action-icon (click)="editHardware(null)" class="inline-block" [icon]="icons.plusWhite" iconText="Add">
        </pos-action-icon>
        <pos-export-dropdown class="inline-block ps-pix-5" (onPrint)="printDiv()"
          (onCSV)="exportCSV()"></pos-export-dropdown>
      </ng-template>
      <ng-template let-data="rowData" #operationTemplate>
        <span class="pe-pix-3" (click)="editHardware(data)" tooltip title="Edit" scrollup>
          <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
        </span>
        <span class="pe-pix-3" (click)="deleteHardware(data)" tooltip title="Delete">
          <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
        </span>
        <span *ngIf="(data.IsActive)" class="pt-pix-3" (click)="updateHardwareDeviceStatus(data, false)" tooltip
          title="Click here to deactivate this hardware">
          <pos-action-icon [icon]="icons.ban"></pos-action-icon>
        </span>
        <span *ngIf="!(data.IsActive)" class="pt-pix-3" (click)="updateHardwareDeviceStatus(data, true)" tooltip
          title="Click here to activate this hardware">
          <pos-action-icon [icon]="icons.checkCircleWhite"></pos-action-icon>
        </span>
      </ng-template>
    </div>
  </div>
</pos-list-wrapper>
<pos-confirm-delete (onConfirmAcknowledge)="onConfirmAcknowledge()" #confirmHardware>
</pos-confirm-delete>
<pos-print-table [hidden]="true"></pos-print-table>
