export const SalesProductFieldInfoMessages = {
    Category: 'If specified, this overrides the tax bracket which will be applied for this product.',
    Type: 'Revenue: sales of this product will be recorded as income. Liability: sales of this product will be recorded as a liability; for example, a gift certificate.',
    Information: 'This is an optional description of this product, which will appear in the INFO popup.',
    MakeTableColor: 'This color setting overrides the background color of this item when it appears on a Make Table terminal.',
    UsePickScreen: 'If checked, this item will appear on Pick Screen terminals when selected on a Make Table terminal.',
    UseInstructionScreen: 'If checked, this item will appear on associated Instruction Screen terminals when selected on a Make Table terminal.',
    UseParallelPickScreen: 'Default: ((help)) Yes: When selected on the Make Table, this product appear on both the Pick Screen terminal and Instruction Screen terminal. No: The product will move to the Pick Screen first, then to the Instruction Screen.',
    TimedPrep: 'Utilize a Timer terminal for preparation of this product.',
    UseWorkflowTimerPrepGroup: 'A name that allows you to group products on the Timer. Assign like products to the same Prep Group.',
    UseWorkflowTimerPrepTime: 'The number of seconds to cook/prepare this product.',
    UseWorkflowTimerPrepButtonColor: 'This sets the color of the button on the Timer terminal for this product.',
    IsReadyState: 'If checked, this item will skip Ready state in maketable and transition to Served directly'
}
