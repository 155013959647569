import { Component, OnInit, Input } from '@angular/core';
import { FormUtilityService, ModalService } from 'src/app/shared';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalFormComponent } from 'src/app/shared/components/modal-form/modal-form.component';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'pos-sales-product-edit-model',
  templateUrl: './sales-product-edit-model.component.html',
  styleUrls: ['./sales-product-edit-model.component.scss']
})
export class SalesProductEditModelComponent extends ModalFormComponent implements OnInit {
  form: NgForm;
  get getForm(): NgForm {
    return this.form
  }
  @Input('isScroll') isScroll: boolean = false;
  @Input('salesProductId') salesProductId: number = null;
  @Input('salesProductName') salesProductName: string = '';
  initialize: boolean = false;
  constructor(salesModalRef: BsModalRef, modalService: ModalService, formUtilityService: FormUtilityService) {
    super(salesModalRef, modalService, formUtilityService);
    this.initialize = false;
  }
  ngOnInit() {
    this.initialize = true;
  }

  onClose($event: any) {
    this.hide($event);
  }
  onFormChange($event: NgForm) {
    this.form = $event;
    this.setInitialValue();
  }

}
