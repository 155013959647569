<div class="h-80">
    <pos-header-entry-main [links]="headerLinks" [isShowBarIcon]="false"></pos-header-entry-main>
</div>
<div class="'col-md-12 pt-pix-5 p-0'">
    <div style="padding-top: 0.2%" *ngIf="serveScreenOrders && serveScreenOrders.length > 0">
        <div class="grid white"
            [ngClass]="{'grid' : currentLayout == serveScreenLayoutViews.Grid, 'best-fit': currentLayout == serveScreenLayoutViews.BestFit}">
            <div *ngIf="currentLayout == serveScreenLayoutViews.BestFit">
                <div class="container best-fit pe-pix-10 ps-pix-10">
                    <div *ngFor="let column of columns" [ngStyle]="{'width': 100/noOfColumns + '%'}"
                        class="column {{'col-'+ column}}">
                        <ng-template ngFor let-item [ngForOf]="getDataForColumn(column)" let-i="index">
                            <ng-container
                                *ngTemplateOutlet="itemTemplate;context:{serveScreenOrders:item, index:i*noOfColumns+column-1}">
                            </ng-container>
                        </ng-template>
                    </div>
                </div>
            </div>
            <div *ngIf="currentLayout === serveScreenLayoutViews.Grid">
                <div class="container grid pe-pix-10 ps-pix-10">
                    <ng-template ngFor let-item [ngForOf]="serveScreenOrders | readyOrderFilter:serveScreenUIStates"
                        let-i="index">
                        <ng-container *ngTemplateOutlet="itemTemplate;context:{serveScreenOrders:item, index:i}">
                        </ng-container>
                    </ng-template>
                </div>
            </div>
            <ng-template #itemTemplate let-item="serveScreenOrders" let-i="index">
                <div
                    [ngStyle]="{'width': currentLayout == serveScreenLayoutViews.Grid ? 100/noOfColumns + '%' : '100%'}">
                    <div class='pull-left p-pix-5' style="width:100%">
                        <pos-order-card [order]="item" [currentLayout]="currentLayout"
                            (orderItemStateChangeEmit)="orderItemStateChange($event)"
                            [makeTableColorConfig]="serveScreenColorConfig" [(noOfColumns)]="noOfColumns"
                            (serveOrder)="serveThisOrder($event)" [isShowOrderUpdates]="false"
                            [showProductsWithState]="showProductsWithState" [(uiStates)]="serveScreenUIStates"
                            [isShowNotifyButtonOnEachItem]="isShowNotifyButtonOnEachItem"
                            (notifyOrderItemReady)="notifyItemReady($event)">
                        </pos-order-card>
                        <div>
                            <div class="col-12 p-0"
                            *ngIf="serveScreenUIStates && serveScreenUIStates[item.Id]?.IsReadyToServe">
                              <button class="btn btn-lg normal width-full mt-pix-10 pos-button" (click)="serveAll(item)"
                                      [ngStyle]="{'background': serveButtonColor, 'color': serveButtonFontColor}">Serve All</button>
                            </div>
                          </div>
                    </div>
                </div>
            </ng-template>

        </div>
    </div>
    <div class="make-table-page__no-active-order" *ngIf="!serveScreenOrders || !serveScreenOrders.length">
        No active orders</div>
    <pos-order-bottom-clock-and-layout [currentLayout]="currentLayout" (changeLayout)="changeLayout($event)" *ngIf="!isMobileMode">
    </pos-order-bottom-clock-and-layout>
</div>
