<div class="col-12 p-0">
    <pos-row>
        <pos-column>
            <pos-form-field>
                <p-checkbox posInput name="isShowHeader" #isShowHeaderRef="ngModel" [(ngModel)]="isShowHeader"
                    binary="true" label="Show Header" (onChange)="onChangeIsShowHeader()">
                </p-checkbox>
            </pos-form-field>
        </pos-column>
    </pos-row>
    <pos-row class="mx-0" colXs="12" colStyleClass="p-0" *ngIf="isShowHeader">
        <pos-column>
            <pos-form-field label="Background Color">
                <pos-color-picker posInput name="{{prefix}}_backgroundColor"
                    [(ngModel)]="headerStyle.BackgroundColor"></pos-color-picker>
            </pos-form-field>
        </pos-column>

        <pos-column>
            <pos-form-field label="Header Height">
                <input posInput type="number" name="headerHeight" [(ngModel)]="headerStyle.HeaderHeight"
                    class="form-control col-120" />
            </pos-form-field>
        </pos-column>

        <pos-column>
            <pos-form-field>
                <p-checkbox name="{{prefix}}_showHeaderBorder" [(ngModel)]="headerStyle.ShowHeaderBorder" binary="true"
                    label="Show Header Border"></p-checkbox>
            </pos-form-field>
        </pos-column>
        <pos-column>
            <pos-form-field label="Image" inputStyle="col-12" [fieldInfoObject]="fieldInfoObject.HeaderImage">
                <pos-row colXs="6" class="mx-0">
                    <pos-column class="p-0">
                        <input posInput type="file" id="headerConfigImage" class="form-control"
                            accept=".png,.jpg,.ico,.bmp"
                            (change)="widgetHeaderConfig.Image = handleFileInput($event.target.files, 'headerConfigImage', widgetHeaderConfig.Image)" />
                    </pos-column>

                    <pos-column [hidden]="!widgetHeaderConfig.Image">
                        <pos-row>
                            <pos-column xs="2" class="col-70 pe-0">
                                <img alt="" id="image_headerConfigImage"
                                    src="{{menuWidgetPath}}/{{widgetHeaderConfig.Image}}" height="50px" />
                            </pos-column>
                            <pos-column xs="12" class="width-10 p-0 mt-pix-n5">
                                <a (click)="removeIcon('headerConfigImage', widgetHeaderConfig.Image); widgetHeaderConfig.Image = ''"
                                    class="black">
                                    <pos-icon [icon]="icons.times" class="pointer remove-icon-badge"></pos-icon>
                                </a>
                            </pos-column>
                        </pos-row>
                    </pos-column>
                </pos-row>
            </pos-form-field>
        </pos-column>

        <pos-column>
            <pos-form-field label="Badge" inputStyle="col-12" [fieldInfoObject]="fieldInfoObject.HeaderBadge">
                <pos-row colXs="6" class="mx-0">
                    <pos-column class="p-0">
                        <input posInput type="file" id="headerBadge" class="form-control" accept=".png,.jpg,.ico,.bmp"
                            (change)="widgetHeaderConfig.Badge = handleFileInput($event.target.files, 'headerBadge', widgetHeaderConfig.Badge)" />
                    </pos-column>

                    <pos-column [hidden]="!widgetHeaderConfig.Badge">
                        <pos-row>
                            <pos-column xs="2" class="col-70 pe-0">
                                <img alt="" id="image_headerBadge" src="{{menuWidgetPath}}/{{widgetHeaderConfig.Badge}}"
                                    height="50px" />
                            </pos-column>
                            <pos-column xs="12" class="width-10 p-0 mt-pix-n5">
                                <a (click)="removeIcon('headerBadge', widgetHeaderConfig.Badge); widgetHeaderConfig.Badge = ''"
                                    class="black">
                                    <pos-icon [icon]="icons.times" class="pointer remove-icon-badge"></pos-icon>
                                </a>
                            </pos-column>
                        </pos-row>
                    </pos-column>
                </pos-row>
            </pos-form-field>
        </pos-column>

        <pos-column class="section-border p-0">
            <div class="display-flex pt-pix-10">
                <h3 class="setting-title pti-0">Text</h3>
                <field-info [info]="fieldInfoObject.HeaderText.info" [image]="fieldInfoObject.HeaderText.image">
                </field-info>
            </div>
        </pos-column>

        <pos-column class="pos-title-content">
            <pos-form-field label="Text">
                <input posInput name="headerText" type="text" class="form-control"
                    [(ngModel)]="widgetHeaderConfig.Text" />
            </pos-form-field>
        </pos-column>

        <pos-column class="pos-title-content">
            <pos-form-field label="Text Align" inputStyle="col-md-3 col-sm-4 col-6">
                <ng-select [items]="textAlign" name="{{prefix}}_textAlign" [(ngModel)]="headerStyle.textAlign"
                    [clearOnBackspace]="false" dropdownPosition="auto" bindLabel="Name" bindValue="Value"
                    appendTo="body" placeholder="Select text alignment"></ng-select>
            </pos-form-field>
        </pos-column>

        <pos-column>
            <pos-font-style-config [(fontStyleConfig)]="headerStyle.TextFontStyle"
                prefix="{{prefix}}_textFontStyle"></pos-font-style-config>
        </pos-column>

        <pos-column class="section-border p-0">
            <div class="display-flex pt-pix-10">
                <h3 class="setting-title pti-0">Price</h3>
                <field-info [info]="fieldInfoObject.HeaderPrice.info" [image]="fieldInfoObject.HeaderPrice.image">
                </field-info>
            </div>
        </pos-column>

        <pos-column class="pos-title-content">
            <pos-form-field label="Price Info">
                <input posInput name="headerPriceInfo" type="text" class="form-control"
                    [(ngModel)]="widgetHeaderConfig.PriceInfo" />
            </pos-form-field>
        </pos-column>

        <pos-column>
            <pos-font-style-config [(fontStyleConfig)]="headerStyle.PriceInfoStyle"
                prefix="{{prefix}}_priceInfoStyle"></pos-font-style-config>
        </pos-column>

    </pos-row>
</div>
