import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { InfoLinkSectionsModel } from '../interface/info-link-sections-model';
import { Observable } from 'rxjs';

@Injectable()
export class InfoLinkSectionsService {

    infolinksectionsApiBasePath = `${RuntimeConstants.API_PATH}infolinksections`;

    constructor(private httpService: HttpClient) {}

    getInfoLinkSections(): Observable<InfoLinkSectionsModel[]> {
        return this.httpService.get<InfoLinkSectionsModel[]>(this.infolinksectionsApiBasePath);
    }

    deleteInfoLinkSection(infoLinkSectionId: number) {
        return this.httpService.delete(this.infolinksectionsApiBasePath + '/' + infoLinkSectionId );
    }

    getInfoLinkSectionDetails(infolinksectionId: number): Observable<InfoLinkSectionsModel> {
        return this.httpService.get<InfoLinkSectionsModel>(this.infolinksectionsApiBasePath + '/' + infolinksectionId);
    }

    addInfoLinkSection(infoLinkSection: InfoLinkSectionsModel): Observable<any> {
        return this.httpService.post(this.infolinksectionsApiBasePath + '/', infoLinkSection);
    }

    updateInfoLinkSection(infoLinkSection: InfoLinkSectionsModel): Observable<any> {
        return this.httpService.put(this.infolinksectionsApiBasePath + '/' + infoLinkSection.Id, infoLinkSection);
    }

    newInfoLinkSections() {
        const infoLinkModel: InfoLinkSectionsModel = {
            Id: 0,
            Name: ''
        };

        return infoLinkModel;
    }

}
