import { Injectable, EventEmitter } from '@angular/core';
import { UserDetails } from '../interface';
import { UserSignInByButtonModel } from '../interface/user-sign-in-by-button-model';

@Injectable()
export class EventBroadcastingService {
  cardBasedSignInChanged = new EventEmitter<boolean>();

  stateChangeSuccess = new EventEmitter<any>();
  signInAsTerminal = new EventEmitter<any>();
  reloadSettings = new EventEmitter<any>();
  noOfUnservedOrder = new EventEmitter<number>();
  openClockInStatus = new EventEmitter<any>();

  terminalWarningClosure = new EventEmitter<any>();
  shiftDutiesDue = new EventEmitter<boolean>();
  sizeChanged = new EventEmitter<number>();
  userSignInByButton = new EventEmitter<UserSignInByButtonModel>();
  productsFiltered = new EventEmitter<any>();
  terminalDesignModeChanged = new EventEmitter<boolean>();
  configurationFullScreenChanged = new EventEmitter<boolean>();
  detectCardSwipe = new EventEmitter<any>();
  permissionLevelChanged = new EventEmitter<any>();
  constructor() { }

  changeCardBasedSignIn(enabled: boolean) {
    this.cardBasedSignInChanged.emit(enabled);
  }

  userSignInByButtonEvent(details: UserSignInByButtonModel) {
    this.userSignInByButton.emit(details);
  }

  changeStateSuccess(stateData: any) {
    this.stateChangeSuccess.emit(stateData);
  }

  signInAsTerminalEvent() {
    this.signInAsTerminal.emit();
  }

  reloadSettingsEvent() {
    this.reloadSettings.emit();
  }

  changeNoOfUnservedOrder(orderCount: number) {
    this.noOfUnservedOrder.emit(orderCount);
  }

  openClockInStatusModal() {
    this.openClockInStatus.emit();
  }
  
  onTerminalWarningClosure(warningPayload: any) {
    this.terminalWarningClosure.emit();
  }
  
  onShiftDutiesDue(shiftDutiesDue: boolean) {
    this.shiftDutiesDue.emit(shiftDutiesDue);
  }

  reloadSize(categoryId: number) {
    this.sizeChanged.emit(categoryId);
  }

  onTerminalDesignModeChanged(designMode: boolean) {
    this.terminalDesignModeChanged.emit(designMode);
  }

  onConfiguratorFullScreen(isFullScreen: boolean) {
    this.configurationFullScreenChanged.emit(isFullScreen);
  }

  filterProducts() {
    this.productsFiltered.emit();
  }
  
  onPermissionLevelChanged(permissionId: string, permissionLevel) {
    this.permissionLevelChanged.emit({PermissionId: permissionId, PermissionLevel: permissionLevel});
  }
}

