<pos-edit-wrapper (close)="onCancel(false)" [isPopup]="false" [headingIcon]="icons.infoManageShoppingCart"
  heading="Online Ordering - Category{{currentCategoryName ?': ' + currentCategoryName : ''}}">
  <form #orderingCategory="ngForm" (ngSubmit)="saveOrderingCategory(orderingCategory.valid)" class="edit-form"
    autocomplete="off">
    <div class="col-12 container-fluid border-top pt-pix-5">
      <ul class="nav nav-tabs inner-tab pos-nav-tabs">
        <li [ngClass]="{'active': selectedTab == tabList.CategorySetting }">
          <a (click)="selectedTab = tabList.CategorySetting" class="blue">
            <span>
              <pos-icon class="theme-separator" [icon]="icons.bookmark"></pos-icon>
            </span>
            <span> Category </span>
          </a>
        </li>
        <li
          [ngClass]="{'disabled-tab':orderingCategoryData.Id == 0, 'active': selectedTab == tabList.SectionsSetting }">
          <a (click)="selectedTab = tabList.SectionsSetting"
            [ngClass]="{'blue':orderingCategoryData.Id > 0 , 'grey': orderingCategoryData.Id == 0}">
            <span>
              <pos-icon class="theme-separator" [icon]="icons.layerGroup"></pos-icon>
            </span>
            <span> Sections </span>
          </a>
        </li>
        <li
          [ngClass]="{'disabled-tab':orderingCategoryData.Id == 0, 'active': selectedTab == tabList.ProductsSettings }">
          <a (click)="selectedTab = tabList.ProductsSettings"
            [ngClass]="{'blue':orderingCategoryData.Id > 0 , 'grey': orderingCategoryData.Id == 0}">
            <span>
              <pos-icon class="theme-separator" [icon]="icons.boxes"></pos-icon>
            </span>
            <span> Products </span>
          </a>
        </li>
        <li [ngClass]="{'active': selectedTab == tabList.ScheduleSettings }">
          <a (click)="selectedTab = tabList.ScheduleSettings" class="blue">
            <span>
              <pos-icon class="theme-separator" [icon]="icons.clock"></pos-icon>
            </span>
            <span> Schedule </span>
          </a>
        </li>
      </ul>
      <div class="col-12 pos-tab-body screen-scroll pt-pix-5">
        <div *ngIf="selectedTab == tabList.CategorySetting">
          <div [ngStyle]="{'height': getHeight()}">
            <pos-row>
              <pos-column>
                <pos-form-field label="Name" [validationMessages]="{required: 'Please enter name.'}"
                  inputStyle="col-lg-3 col-md-4 col-sm-6 col-12">
                  <input posInput class="form-control" name="categoryName" id="categoryName" #categoryName="ngModel"
                    [(ngModel)]="orderingCategoryData.Name" [required]="true">
                </pos-form-field>
              </pos-column>
              <pos-column>
                <pos-form-field>
                  <p-checkbox posInput name="active" #active="ngModel" [(ngModel)]="orderingCategoryData.IsActive"
                    binary="true" label="Visible">
                  </p-checkbox>
                </pos-form-field>
              </pos-column>
              <pos-column>
                <pos-form-field label="Description">
                  <textarea posInput class="form-control" name="description" #description="ngModel"
                    [(ngModel)]="orderingCategoryData.Description"></textarea>
                </pos-form-field>
              </pos-column>
              <pos-column>
                <pos-form-field label="Icon" [validationMessages]="{required: 'Please choose category icon.'}"
                  inputStyle="col-12">
                  <pos-row>
                    <pos-column lg="3" md="4" sm="7"
                      [styleClass]="{'p-0': true, 'has-error': !showCategoryIcon && orderingCategory.submitted && orderingCategoryData.Icon == ''}">
                      <input posInput type="file" class="form-control" accept=".png,.jpg,.jpeg,.gif,.bmp" #categoryIcon
                        name="categoryIcon" id="categoryIcon" (change)="handleFileInput($event.target.files)"
                        [required]="true">
                      <span *ngIf="!showCategoryIcon && orderingCategory.submitted && orderingCategoryData.Icon == ''">
                        <label class="control-label">Please choose category icon.</label>
                      </span>
                    </pos-column>
                    <pos-column lg="3" [hidden]="!showCategoryIcon">
                      <img class="ps-pix-5 col-40" #categoryImage id="categoryImage" />
                      <pos-icon [icon]="icons.times" class="pointer mt-pix-n15 remove-icon-badge"
                        (click)="removeIcon()"></pos-icon>
                    </pos-column>
                    <pos-column lg="5" sm="4" class="pt-pix-7 pe-0 ps-0"
                      *ngIf="orderingCategoryData.Id > 0 && !showCategoryIcon && categoryIconName">
                      <span class="edit-form__section__label ps-pix-5">{{categoryIconName}}</span>
                      <span class="ps-pix-5 mt-pix-n4" (click)="removeIcon()">
                        <pos-icon [icon]="icons.times" class="pointer mt-pix-n7 remove-icon-badge"></pos-icon>
                      </span>
                    </pos-column>
                    <pos-column xs="12" class="p-0">
                      <span *ngIf="orderingCategoryData.hasImageResolutionError && showCategoryIcon">
                        <label class="red-extreme">{{resolutionWarningMessage}}</label>
                      </span>
                    </pos-column>
                  </pos-row>
                </pos-form-field>
              </pos-column>
              <pos-column>
                <pos-form-field>
                  <p-checkbox posInput name="featured" #featured="ngModel" [(ngModel)]="orderingCategoryData.IsFeatured"
                    binary="true" label="Featured Category">
                  </p-checkbox>
                </pos-form-field>
              </pos-column>
              <pos-column>
                <pos-form-field>
                  <p-checkbox posInput name="mobile" #mobile="ngModel" [(ngModel)]="orderingCategoryData.IsMobile"
                    binary="true" label="Mobile only">
                  </p-checkbox>
                </pos-form-field>
              </pos-column>
            </pos-row>
          </div>
        </div>
        <div *ngIf="selectedTab == tabList.SectionsSetting">
          <div [ngStyle]="{'height': getHeight()}">
            <div class="col-12 row mt-pix-15 pb-pix-5">
              <ng-table #gridComponent [sort]="false" [search]="false" [paginator]="false"
                [columns]="orderingCategorySectionsColumns" [data]="orderingCategorySections"
                [isReorderableTable]="true" [ReorderableColumnRow]="'row'" (onRowReorder)="gridRowReorder($event)">
              </ng-table>
            </div>
          </div>
        </div>
        <div *ngIf="selectedTab == tabList.ProductsSettings">
          <div [ngStyle]="{'height': getHeight()}">
            <pos-row >
              <pos-column>
                <pos-form-field label="Existing Product" inputStyle="col-md-10">
                  <pos-row>
                    <pos-column lg="5" class="mb-pix-sm-10 mb-pix-lg-0">
                      <ng-select posInput name="salesProduct" [items]="orderingProductList" bindLabel="ButtonText"
                        bindValue="Id" placeholder="Select product" [(ngModel)]="selectedProduct">
                      </ng-select>
                    </pos-column>
                    <pos-column lg="7">
                      <button type="button" class="btn btn-primary btn normal white me-pix-15 pos-button"
                        (click)="addButtonToSection()" [disabled]="!selectedProduct">
                        Add
                      </button>
                      <span class="me-pix-15">or</span>
                      <span>
                        <button type="button" class="btn btn-primary btn normal white me-pix-15 pos-button" (click)="editProduct(null)">
                          Add Product to Online Ordering
                        </button>
                      </span>
                    </pos-column>
                  </pos-row>
                </pos-form-field>
              </pos-column>
            </pos-row>
            <div class="col-12 pt-pix-10 pe-pix-5">
              <div class="page-content__search pull-right">
                <input class="form-control" name="textToMatch" placeholder="Filter..." #textToMatch="ngModel"
                  [(ngModel)]="searchFilter">
              </div>
            </div>
            <div class="col-12" *ngFor="let section of orderingCategorySections; let sectionIndex = index">
              <p-table [value]="productsWithSection[section.Name] | customFilter:searchFilter"
                (onRowReorder)="ProductGridRecorder($event, productsWithSection[section.Name])">
                <ng-template pTemplate="header">
                  <tr [ngClass]="{'display-none': sectionIndex > 0}">
                    <th style="width: 2em">
                      <!-- placeholder for row handler column -->
                    </th>
                    <th style="width: 15%">Section Name</th>
                    <th style="width: 20%">Button Text</th>
                    <th style="width: 20%">Sales Product</th>
                    <th style="width: 10%">Background Color</th>
                    <th style="width: 8%">Text Color</th>
                    <th style="width: 100px">Has Icon</th>
                    <th style="width: 100px">Visible</th>
                    <th style="width: 97px">
                      
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                  <tr class="p-widget-header" [pReorderableRow]="rowIndex"
                    [ngClass]="{'row-grouping-reorder': rowIndex == 0 && sectionIndex > 0}">
                    <td style="width: 2em">
                      <i [ngClass]="icons.ordinalBars.iconClasses" pReorderableRowHandle></i>
                    </td>
                    <td style="width: 15%">{{rowData.SectionName}}</td>
                    <td style="width: 20%">{{rowData.ButtonText}}</td>
                    <td style="width: 20%">{{rowData.SalesProductName}}</td>
                    <td style="width: 10%">{{rowData.BackgroundColor}}</td>
                    <td style="width: 8%">{{rowData.TextColor}}</td>
                    <td style="width: 100px" class="text-center"><span *ngIf="rowData.Icon" class="green">
                        <pos-icon [icon]="icons.check"></pos-icon>
                      </span></td>
                    <td style="width: 100px" class="text-center"><span *ngIf="rowData.IsActive == true" class="green">
                        <pos-icon [icon]="icons.check"></pos-icon>
                      </span></td>
                    <td style="width: 97px" class="cell-padding">
                      <div class="col-12 p-0 item-vertical-center">
                        <div class="flex-grow-1" (click)="editProduct(rowData)" tooltip title="Edit">
                          <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
                        </div>
                        <div class="flex-grow-1" (click)="deleteProduct(rowData)" tooltip title="Delete">
                          <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
                        </div>
                        <div (click)="showProductConfiguration(rowData)" class="pointer flex-grow-1" tooltip
                          title="Configuration">
                          <pos-action-icon [icon]="icons.cogWhite"></pos-action-icon>
                        </div>
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
        <div *ngIf="selectedTab == tabList.ScheduleSettings">
          <div [ngStyle]="{'height': getHeight()}">
            <pos-row>
              <pos-column>
                <pos-form-field label="Start Time">
                  <input posInput type="time" placeholder="HH:mm:ss" class="form-control col-200" name="startTime"
                    #startTime="ngModel" [(ngModel)]="orderingCategoryData.StartTime">
                </pos-form-field>
              </pos-column>
              <pos-column>
                <pos-form-field label="End Time"
                  [validationMessages]="{condition: 'End time should be less then Start time.'}">
                  <input posInput type="time" placeholder="HH:mm:ss" class="form-control col-200" name="endTime"
                    #endTime="ngModel" [(ngModel)]="orderingCategoryData.EndTime"
                    [conditionallyValidate]="!orderingCategoryData.StartTime || orderingCategoryData.StartTime < endTime.value">
                </pos-form-field>
              </pos-column>
            </pos-row>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 action-button-container page-footer">
      <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" scrollup>Save </button>
      <button type="button" class="btn-secondary btn-lg btn btn-cancel pos-button" scrollup
        (click)="onCancel(false)">Close</button>
    </div>
  </form>
</pos-edit-wrapper>
<pos-confirm-delete [message]="message" (onConfirmAcknowledge)="onConfirmAcknowledge()" #confirmButtonDelete>
</pos-confirm-delete>

<pos-confirm-delete [message]="sectionDeleteMessage" (onConfirmAcknowledge)="onConfirmDeleteSection()"
  #confirmSectionDelete>
</pos-confirm-delete>


<ng-template let-data="rowData" #nameTemplate>
  <span [ngClass]="{'disabled': data.IsDefault,'has-error': (sectionName.invalid && orderingCategory.submitted)}">
    <input type="text" name="Name{{data.Id}}{{data.Ordinal}}" #sectionName="ngModel"
      [attr.tabindex]="data.IsDefault ? -1 : null" (keypress)="createSectionRow(data)" class="form-control"
      [(ngModel)]="data.Name" [required]="data.Id > 0 || data.Description != '' || data.Icon != ''" />
    <span *ngIf="sectionName.invalid && orderingCategory.submitted">
      <label class="control-label" *ngIf="sectionName.errors?.required">Please enter section
        name.</label>
    </span>
  </span>
</ng-template>
<ng-template let-data="rowData" #descriptionTemplate>
  <span [ngClass]="{'disabled': data.IsDefault}">
    <textarea type="text" name="Description{{data.Id}}{{data.Ordinal}}" class="form-control"
      [attr.tabindex]="data.IsDefault ? -1 : null" [(ngModel)]="data.Description"
      (keypress)="createSectionRow(data)"> </textarea>
  </span>
</ng-template>
<ng-template let-data="rowData" #iconTemplate>
  <span class="row g-0"
    [ngClass]="{'disabled': data.IsDefault, 'has-error': data.hasImageResolutionError && data.Icon}">
    <div class="col-9 p-0">
      <input type="file" id="imageUpload{{data.Id}}{{data.Ordinal}}" class="form-control"
        accept=".png,.jpg,.jpeg,.gif,.bmp" [attr.tabindex]="data.IsDefault ? -1 : null"
        (change)="handleSectionFileInput($event.target.files, data.Id, data)" />
    </div>
    <div class="col-3 ps-2 " [hidden]="!data.IsChangedImage">
      <div class="text-right pe-0 w-fit-content position-relative" [hidden]="!data.Icon">
        <img style="width: 50px;" id="sectionImage{{data.Id}}{{data.Ordinal}}" />
        <div class="position-absolute " style="top:-10px ; right:-8px" (click)="removeSectionIcon(data.Id, data)"
          [hidden]="!data.Icon">
          <pos-icon [icon]="icons.times" class="pointer remove-icon-badge"></pos-icon>
        </div>
      </div>


    </div>
    <div class="col-12 black" *ngIf="data.Id > 0 && !data.IsChangedImage && data.iconName">
      <span>{{data.iconName}}</span>
      <span class="ps-pix-5" (click)="removeSectionIcon(data.Id, data)" style="margin-top: -5px">
        <a class="black" style="cursor: pointer;">X</a>
      </span>
    </div>
    <div class="col-md-12 p-0 control-label" *ngIf="data.hasImageResolutionError && data.Icon">
      <label>{{resolutionWarningMessage}}</label>
    </div>
  </span>
</ng-template>

<ng-template let-data="rowData" #iconTemplate1>
  <div *ngIf="data.Id > 0 && !data.IsDefault" (click)="deleteSection(data)" class="text-center" tooltip title="Delete">
    <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
  </div>
</ng-template>
