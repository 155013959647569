import { GridColumn } from '@tarktech/tark-ng-utils';
import { ModalComponent } from '../../../../shared/components/modal/modal-component';
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RawIngredientsService } from '../../service';
import { SpinnerService, AlertsService } from 'src/app/shared';
@Component({
  selector: 'pos-inventory-products-ingredient',
  templateUrl: './inventory-products-ingredient.component.html',
  styleUrls: ['./inventory-products-ingredient.component.scss']
})
export class InventoryProductsIngredientComponent extends ModalComponent implements OnInit {

  rawIngredientId: number;
  inventoryProducts: any = [];
  inventoryProductColumns: Array<GridColumn> = [];
  constructor(
    inventoryIngredientModalRef: BsModalRef,
    private rawIngredientService: RawIngredientsService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService
  ) {
    super(inventoryIngredientModalRef);
  }


  ngOnInit() {
    this.getInventoryProducts(this.rawIngredientId);
    this.configureGridColumns();
  }

  private configureGridColumns() {
    this.inventoryProductColumns = [
      new GridColumn({ HeaderText: 'Product Name', Field: 'InventoryProduct', Width: '30%' }),
    ] as Array<GridColumn>;
  }

  onCancel(reload: boolean) {
    this.hide({ shouldReload: reload });
  }

  getInventoryProducts(rawIngredientId) {
    this.spinnerService.show();
    this.rawIngredientService.getInventoryProduct(rawIngredientId)
      .subscribe({
        next: (response) => {
          if (response) {
            this.inventoryProducts = response;
          } else {
            this.inventoryProducts = [];
          }
        }, error: this.alertService.showApiError,
        complete: () => {
          this.spinnerService.hide();
        }
      });
  }

}
