import { Injectable } from '@angular/core';
import { forEach } from 'lodash';
import { ApplicationStateService } from './application-state.service';
import { RabbitMQService } from './rabbitmq/services/rabbitmq.service';
import { HttpClient } from '@angular/common/http';
import { DomainConstants, RuntimeConstants } from '../constants';
import { Observable } from 'rxjs';
import { CameraAnnotations } from '../interface/camera-annotations';
import { StringUtils } from '../string-utils/string-utils';
@Injectable()
export class CameraAnnotationService {
    cameraAnnotationApiUrl = RuntimeConstants.API_PATH + 'camera-annotations';
    constructor(
        private applicationStateService: ApplicationStateService,
        private rabbitMQService: RabbitMQService,
        private httpService: HttpClient) {
        }

    addAnnotationToCamera(message, obj: any) {
        const clonedObj = { ...obj };
        clonedObj.Username =
            this.applicationStateService.userDetails.lastname + ', ' +
            this.applicationStateService.userDetails.firstname;
        clonedObj.TerminalName = this.applicationStateService.terminalName;
        const cameraAnnotationText = StringUtils.format(message, clonedObj);
        this.sendCameraMessageToHic(cameraAnnotationText);
    }

    sendCameraMessageToHic(annotationText) {
        const cameraLicence = this.applicationStateService.applicationFeatures.find(x=> x.Name == DomainConstants.HardwareTypes.Camera)
        if (annotationText && cameraLicence.IsEnabled) {
            const mappedCameras = this.applicationStateService.settingParam.MappedCameraDevices;
            if (mappedCameras?.length > 0) {
                forEach(mappedCameras, (mappedCamera) => {
                    this.rabbitMQService.sendCameraAnnotationMessageToHIC(this.applicationStateService.terminalId,
                        mappedCamera.DeviceType,
                        mappedCamera.HicGuId, mappedCamera.HardwareId,
                        annotationText);
                });
            }
        }
    }

    getCameraAnnotation(): Observable<Array<CameraAnnotations>> {
        return this.httpService.get<Array<CameraAnnotations>>(`${this.cameraAnnotationApiUrl}`);
    }

    getActiveCameraAnnotation(): Observable<Array<CameraAnnotations>> {
        return this.httpService.get<Array<CameraAnnotations>>(`${this.cameraAnnotationApiUrl}/active`);
    }

    updateCameraAnnotation(cameraAnnotations: CameraAnnotations) {
        return this.httpService.post(`${this.cameraAnnotationApiUrl}`, cameraAnnotations);
    }

    getCameraAnnotationById(id: number): Observable<CameraAnnotations> {
        return this.httpService.get<CameraAnnotations>(`${this.cameraAnnotationApiUrl}/${id}`);
    }

    getNewCameraAnnotation(): CameraAnnotations {
        return {
            Id: 0,
            KeyName: "",
            Annotation: "",
            Tokens: "",
            IsActive: false,
            Description: ""
        }
    }
}
