import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import {
    listAlt,
    barsWhite,
    check,
    dashboardList,
    barcode,
    inventoryReceivedTruck,
    clone,
    inventoryPrepUtensils,
    shoppingCart,
    codeBranch,
    checkCircleSolid,
    prepLabelPriting,
    history,
    lightBulb, editWhite, plusWhite, printWhite,
    filterListStack, squareSolidPaginationLightBlue,
    inventoryProductsStore
} from 'src/app/shared/components/icon';
import { Permissions, Levels, Messages } from '../../../../shared/constants/';
import { GridColumn, TarkCurrencyPipe, TarkDatePipe } from '@tarktech/tark-ng-utils';
import { InventoryProductDetails } from 'src/app/shared/interface/inventory-product-deail';
import { VendorService } from 'src/app/information-management/vendors/service/vendor.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { AuthenticationService } from 'src/app/shared/auth/services/authentication.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import { finalize } from 'rxjs/operators';
import { InventoryProductService } from '../../../../shared/services/inventory-product.service';
import { InventorySubCategoryService, InventorySubcategory } from 'src/app/information-management/inventory-subcategories';
import { forkJoin } from 'rxjs';
import { Vendor } from 'src/app/information-management/vendors';
import { ManageBarcodeComponent } from '../manage-barcode';
import { InventoryReceivedComponent } from '../inventory-received';
import { PrepRecipeListComponent } from '../prep-recipe-list';
import { RecipesIncludedComponent } from '../recipes-included';
import { RecipeEditorComponent } from '../recipe-editor';
import { MergeProductComponent } from '../merge-product';
import { ApplicationStateService, DashboardConfigurationService, ExportService, InfoModalComponent, InventoryProductBarCodeService, InventoryZoneService } from 'src/app/shared';
import {
    CopyInventoryIngredientsInfoComponent
} from '../../../copy-inventory-ingredients-info/component/copy-inventory-ingredients-info.component';
import { PrepLabelPrintComponent } from 'src/app/information-management/prep-label-print';
import * as _ from 'lodash';
import { Table } from 'primeng/table';
import { BaseGridComponent } from 'src/app/shared/components/base-grid.component';
import { InventoryProductDashboardComponent } from '../inventory-product-dashboard/inventory-product-dashboard.component';
import { ActivatedRoute, Router } from '@angular/router';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { GridColumnTypes } from '@tarktech/tark-ng-utils/table/grid-column-types';
import { sortBy, remove, uniq } from 'lodash';
import { InventoryZone } from 'src/app/information-management/inventory-zone/interface/inventory-zone';

@Component({
    selector: 'pos-inventory-product-list',
    templateUrl: './inventory-product-list.component.html',
    styleUrls: ['./inventory-product-list.component.scss']
})
export class InventoryProductListComponent extends BaseGridComponent implements OnInit, AfterViewInit {

    permission = {
        name: Permissions.InventoryProducts,
        readOnlyLevel: Levels.ReadOnly,
        editLevel: Levels.Edit
    };
    icons = {
        barsWhite,
        listAlt,
        check,
        dashboardList,
        barcode,
        inventoryReceivedTruck,
        inventoryPrepUtensils,
        shoppingCart,
        codeBranch,
        clone,
        checkCircleSolid,
        prepLabelPriting,
        history,
        lightBulb, editWhite, plusWhite, printWhite,
        filterListStack, squareSolidPaginationLightBlue,
        inventoryProductsStore
    };
    public gridColumnTypes = GridColumnTypes;
    inventoryProductColumns: Array<GridColumn> = [];
    isLoadTable = false;
    inventoryProductColumnsNew: any[];
    dynamicInventoryProductColumns: any[];
    inventoryProductData: InventoryProductDetails;
    printInventoryProductColumns: Array<GridColumn> = [];
    inventoryProducts: Array<InventoryProductDetails> = [];
    inventoryProductsSearchResult: Array<InventoryProductDetails> = [];
    vendors: Array<Vendor> = [];
    inventorySubCategory: Array<InventorySubcategory> = [];
    popup: any;
    inventoryProductConsumptionId: number = null;
    isActiveInventory = true;
    vendorId = null;
    subCategoryId = null;
    inventoryZoneId = null;
    inventoryProduct: any = null;
    id: number;
    globalfilterColumns: Array<string> = [];
    editPermission = false;
    readOnlyPermission = false;
    columnsLength = 0;
    first = 0;
    dashboardId?: number;
    selectedDynamicColumns = [];
    inventoryProductsForBulkUpdateIds: Array<number> = [];
    inventoryZones: Array<InventoryZone> = [];
    isBulkUpdateAllProducts = false;
    filteredInventoryProducts: Array<InventoryProductDetails> = [];

    @ViewChild('popOverForInventoryProduct') popOverForInventoryProduct: TemplateRef<any>;
    @ViewChild('popOverForDashboard') popOverForDashboard: TemplateRef<any>;
    @ViewChild('iconTemplate', { static: true }) private iconTemplate: any;
    @ViewChild('priceTemplate', { static: true }) private priceTemplate: any;
    @ViewChild('popoverForInventoryProductName', { static: true }) popoverForInventoryProductName: TemplateRef<any>;
    @ViewChild('ptable') public ptable: Table;
    @ViewChild('priceUnitTemplate', { static: true }) private priceUnitTemplate: any;
    @ViewChild('VendorSkuUnitTemplate', { static: true }) private vendorSkuUnitTemplate: any;
    @ViewChild('unitDetailsTemplate', { static: true }) private unitDetailsTemplate: any;
    @ViewChild('calculatedQuantityTemplate', { static: true }) private calculatedQuantityTemplate: any;
    // @ViewChild('vendorLogDateEditModal') public vendorLogDateEditModal: any;
    constructor(
        private vendorService: VendorService,
        private inventorySubCategoryService: InventorySubCategoryService,
        private alertService: AlertsService,
        protected applicationStateService: ApplicationStateService,
        private spinnerService: SpinnerService,
        private authenticationService: AuthenticationService,
        private printService: PrintTableService,
        private inventoryProductService: InventoryProductService,
        protected route: ActivatedRoute,
        private router: Router,
        private modalService: ModalService,
        private dashboardConfigurationService: DashboardConfigurationService,
        private exportService: ExportService,
        private currencyPipe: TarkCurrencyPipe,
        private datePipe: TarkDatePipe,
        private inventoryProductBarCodeService: InventoryProductBarCodeService,
        private inventoryZoneService: InventoryZoneService,
    ) {
        super(applicationStateService, route);
        const navigation = router.getCurrentNavigation();
        this.gridContext = navigation?.extras?.state?.GridContext ?? this.gridContext;
        this.subCategoryId = navigation?.extras?.state?.Category ?? null;
        this.vendorId = navigation?.extras?.state?.Vendor ?? null;
        this.inventoryZoneId = navigation?.extras?.state?.Zone ?? null;
        this.isActiveInventory = navigation?.extras?.state?.Active ?? true;
    }

    ngOnInit() {
        this.configureGridColumns();
        this.vendorId = this.othersProperties.vendorId ?? this.vendorId;
        this.subCategoryId = this.othersProperties.subCategoryId ?? this.subCategoryId;
        this.isActiveInventory = this.othersProperties.isActiveInventory === false ? false : this.isActiveInventory;
        this.inventoryZoneId = this.othersProperties.inventoryZoneId ?? this.inventoryZoneId;
        this.reloadInventoryProducts();
        this.loadChildren();
        this.inventoryProduct = null;
        this.inventoryProductConsumptionId = null;
        this.getProductDashboardId();
    }

    ngAfterViewInit() {
        if (this.gridContext) {
            this.ptable.filterGlobal(this.gridContext.Filter, 'contains');
            if (this.gridContext.PageNumber > 0) {
                setTimeout(() => {
                    this.setPageNumber();
                }, 300);
            }
        }
    }
    setPageNumber = () => {
        this.ptable.first = (this.gridContext.PageNumber - 1) * this.gridContext.PageSize;
        this.first = (this.gridContext.PageNumber - 1) * this.gridContext.PageSize;
    }
    getProductDashboardId = () => {
        this.spinnerService.show();
        this.dashboardConfigurationService.getDashboardByName('Inventory Product Dashboard')
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (response) => {
                    this.dashboardId = response ? response.Id : null;
                }, error: this.alertService.showApiError
            });
    }

    openDashboard = (inventoryProductDetails: InventoryProductDetails) => {
        const modalRef = this.modalService.getModalWrapper(InventoryProductDashboardComponent);
        const modal = modalRef.show({
            animated: false,
            class: 'vertical-center modal-lg modal-max-width-95',
            initialState: {
                inventoryProductName: inventoryProductDetails.Name,
                inventoryProductId: inventoryProductDetails.Id,
                dashboardId: this.dashboardId
            }
        });

        modal.close.subscribe(res => {

        });
    }


    getOtherProperties() {
        this.othersProperties = {
            subCategoryId: this.subCategoryId, vendorId: this.vendorId, isActiveInventory: this.isActiveInventory
            , selectedDynamicColumns: this.selectedDynamicColumns, inventoryZoneId: this.inventoryZoneId
        };
    }

    onSort(event) {
        this.gridContext.SortProperty = event.field;
        this.gridContext.SortDirection = event.order === 1 ? 'Asc' : 'Desc';
    }

    public onPageChange(event) {
        this.gridContext.PageSize = event.rows;
        this.gridContext.PageNumber = Math.ceil((event.first + 1) / this.gridContext.PageSize);
    }

    private configureGridColumns() {

        if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
            this.editPermission = true;
        }

        if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.readOnlyLevel }], 'any')) {
            this.readOnlyPermission = true;
        }

        const commonColumns = [
            { Field: 'SubCategory', HeaderText: 'Category', Width: 'auto', Type: this.gridColumnTypes.GridColumn, IsSortable: true },
            { Field: 'Vendor', HeaderText: 'Vendor', Width: 'auto', Type: this.gridColumnTypes.GridColumn, IsSortable: true },
            { Field: 'UnitQty', HeaderText: 'Unit Qty', Width: 'auto', Type: this.gridColumnTypes.GridColumn, IsSortable: true },
            { Field: 'UnitName', HeaderText: 'Unit', Width: 'auto', Type: this.gridColumnTypes.GridColumn, IsSortable: true },
            { Field: 'CalculatedQty', HeaderText: 'Calculated Qty', Width: 'auto', IsSortable: true },
            {
                Field: 'Price', HeaderText: 'Price', Width: 'auto', Type: this.gridColumnTypes.TemplateColumn,
                itemTemplate: this.priceTemplate, IsSortable: true
            },
            {
                Field: 'PriceUnit', HeaderText: 'Price/Unit', Width: 'auto', Type: this.gridColumnTypes.TemplateColumn,
                itemTemplate: this.priceUnitTemplate, IsSortable: true
            },
            {
                Field: 'Active', HeaderText: 'Active', Width: 'auto', Type: this.gridColumnTypes.TemplateColumn,
                itemTemplate: this.iconTemplate, IsSortable: true
            }
        ];

        this.inventoryProductColumnsNew = [
            this.getNameColumn(), ...commonColumns
        ];

        this.dynamicInventoryProductColumns = [
            {
                Field: 'ProjectedExhaustionDate', HeaderText: 'Projected Exhaustion Date', Width: 'auto', Format: 'MM-dd-yyyy',
                Type: this.gridColumnTypes.DateColumn, IsSortable: true
            },
            {
                Field: 'VendorSku', HeaderText: 'SKU', Width: '150px', Type: this.gridColumnTypes.TemplateColumn,
                itemTemplate: this.vendorSkuUnitTemplate, IsSortable: true
            },
            {
                Field: 'UnitDetails', itemTemplate: this.unitDetailsTemplate, HeaderText: 'Unit Details', Width: '150px',
                Type: this.gridColumnTypes.TemplateColumn, IsSortable: true
            },
            {
                Field: 'PrimaryZoneName', HeaderText: 'Primary Zone', Width: 'auto', Type: this.gridColumnTypes.GridColumn,
                IsSortable: true
            },
            {
                Field: 'PrimaryBinName', HeaderText: 'Primary Bin', Width: 'auto', Type: this.gridColumnTypes.GridColumn,
                IsSortable: true
            },
            {
                Field: 'SecondaryZoneName', HeaderText: 'Secondary Zone', Width: 'auto', Type: this.gridColumnTypes.GridColumn,
                IsSortable: true
            },
            {
                Field: 'SecondaryBinName', HeaderText: 'Secondary Bin', Width: 'auto', Type: this.gridColumnTypes.GridColumn,
                IsSortable: true
            },
            ...commonColumns
        ];

        this.selectedDynamicColumns = this.othersProperties.selectedDynamicColumns ? this.getPropertySelectedColumns() : [...commonColumns];

        this.dynamicInventoryProductColumns = sortBy(this.dynamicInventoryProductColumns, 'HeaderText');


        this.printInventoryProductColumns = [...this.inventoryProductColumnsNew];

        this.onColumnSelection();

    }

    getPropertySelectedColumns() {
        return this.dynamicInventoryProductColumns.filter((data) => {
            return this.othersProperties.selectedDynamicColumns.find(x => x.Field === data.Field);
        });
    }

    getNameColumn() {
        return {
            Field: 'Name', HeaderText: 'Product', Width: 'auto', Type: this.gridColumnTypes.TemplateColumn,
            itemTemplate: this.popoverForInventoryProductName, IsSortable: true
        };
    }
    reloadInventoryProductsWithFilter() {
        this.isBulkUpdateAllProducts = false;
        this.gridContext.PageNumber = 1;
        this.ptable.first = (this.gridContext.PageNumber - 1) * this.gridContext.PageSize;
        this.first = (this.gridContext.PageNumber - 1) * this.gridContext.PageSize;
        this.reloadInventoryProducts();
    }
    reloadInventoryProducts() {
        this.spinnerService.show();
        this.inventoryProductService.getInventoryProductList(
            this.subCategoryId ? this.subCategoryId : 0, this.vendorId ? this.vendorId : 0,
            this.inventoryZoneId ? this.inventoryZoneId : 0, this.isActiveInventory, 0)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (response) => {
                    this.inventoryProducts = response;
                    const allColumns = [this.inventoryProductColumnsNew, ... this.dynamicInventoryProductColumns];
                    allColumns.forEach(col => {
                        if (col.Field === 'ProjectedExhaustionDate') {
                            this.inventoryProducts.forEach((data) => {
                                data[col.Field + 1] = this.datePipe.transform(data[col.Field]);
                            });
                        } else if (col.Field === 'Price' || col.Field === 'PriceUnit') {
                            this.inventoryProducts.forEach((data) => {
                                data[col.Field + 1] = this.currencyPipe.transform(data[col.Field]);
                                data[col.Field] = data[col.Field].toFixed(2);
                            });
                        }
                    });
                    this.inventoryProducts.forEach(x => x.IsAddToBulkUpdate = this.inventoryProductsForBulkUpdateIds.includes(x.Id));
                    setTimeout(() => {
                        this.setPageNumber();
                    }, 200);
                }, error: this.alertService.showApiError
            });
    }

    openInventoryProductConsumption(data: InventoryProductDetails) {
        this.inventoryProductConsumptionId = data.Id;
    }
    private loadChildren() {
        const inventoryProductObservables = [];
        inventoryProductObservables.push(this.vendorService.getVendorList());
        inventoryProductObservables.push(this.inventorySubCategoryService.getInventorySubcategoryList());
        inventoryProductObservables.push(this.inventoryZoneService.getInventoryZones());
        this.spinnerService.show();
        forkJoin(inventoryProductObservables)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (responses: any) => {
                    this.vendors = responses[0] ?? [];
                    this.vendors = _.filter(this.vendors, (vendor) => {
                        return vendor.active === true;
                    });
                    this.inventorySubCategory = responses[1] ?? [];
                    this.inventoryZones = responses[2] ?? [];
                }, error: this.alertService.showApiError
            });
    }
    public printDiv() {
        this.printService.printEmitter.next({
            gridColumns: [this.getNameColumn(), ...this.selectedDynamicColumns],
            gridData: this.inventoryProducts
        });
    }

    public exportCSV() {
        this.exportService.exportCSV('inventory-products', this.inventoryProducts, null,
            [this.getNameColumn(), ...this.selectedDynamicColumns]);
    }
    editInventoryProduct(id, data) {
        this.inventoryProduct = data;
        this.router.navigate([id], {
            state: {
                inventoryProductId: id,
                isScroll: false,
                inventoryProductName: (this.inventoryProduct && this.inventoryProduct.Name) ?? ''
            },
            relativeTo: this.route
        });
    }
    onTableFiltered(event) {
        this.isBulkUpdateAllProducts = false;
        this.filteredInventoryProducts = [];
        if (event.filters && event.filters.global) {
            this.gridContext.Filter = event.filters.global.value;
        }
        const rows = this.gridContext.PageSize ? this.gridContext.PageSize : 20;
        if (event.filteredValue && event.filteredValue.length && ((rows * (this.gridContext.PageNumber - 1)) + 1) >
            event.filteredValue.length) {
            this.gridContext.PageNumber = 1;
        }
        if (event.filteredValue.length !== this.inventoryProducts.length) {
            this.filteredInventoryProducts = event.filteredValue;
        }
    }
    onColumnSelection() {
        const staticFilterColumn = ['PrimaryZoneName', 'PrimaryBinName', 'SecondaryZoneName', 'SecondaryBinName'];
        this.inventoryProductColumnsNew = [this.getNameColumn(), ...this.selectedDynamicColumns];
        this.globalfilterColumns = [this.getNameColumn().Field, ...this.selectedColumnsForGlobalFilter()];
        staticFilterColumn.forEach((column) => {
            if (!this.globalfilterColumns.includes(column)) {
                this.globalfilterColumns.push(column);
            }
        });
        this.setColumnsLength();
    }

    setColumnsLength() {
        if (this.editPermission || this.readOnlyPermission) {
            this.columnsLength = this.inventoryProductColumnsNew.length + 4;
        } else {
            this.columnsLength = this.inventoryProductColumnsNew.length;
        }
        // add column for bulk select column
        this.columnsLength += 1;
    }

    selectedColumnsForGlobalFilter(): Array<string> {
        return this.selectedDynamicColumns.map((data) => {
            if (data.Field === 'ProjectedExhaustionDate' || data.Field === 'Price' || data.Field === 'PriceUnit') {
                return data.Field + '1';
            }
            return data.Field;
        });
    }

    openBarcodeAddEdit() {
        const modalRef = this.modalService.getModalWrapper(ManageBarcodeComponent);
        modalRef.show({
            animated: false,
            class: 'vertical-center modal-lg',
            initialState: {
                isPopup: true,
                inventoryProductId: this.inventoryProductData.Id,
                unitName: this.inventoryProductData.UnitName,
                productName: this.inventoryProductData.Name
            }
        });
        this.popup.hide();
    }

    openInventoryReceived() {
        this.modalService.show(InventoryReceivedComponent, {
            animated: false,
            class: 'vertical-center modal-lg',
            initialState: {
                inventoryProductId: this.inventoryProductData.Id,
                inventoryProductName: this.inventoryProductData.Name
            }
        });
        this.popup.hide();
    }

    openPrepRecipe() {
        const modalRef = this.modalService.getModalWrapper(PrepRecipeListComponent);
        modalRef.show({
            animated: false,
            class: 'vertical-center',
            initialState: {
                inventoryProductId: this.inventoryProductData.Id,
                inventoryProductName: this.inventoryProductData.Name,
                unitName: this.inventoryProductData.UnitName
            }
        });

        this.popup.hide();
    }
    openRecipesIncluded() {
        this.modalService.show(RecipesIncludedComponent, {
            animated: false,
            class: 'vertical-center',
            initialState: {
                inventoryProductId: this.inventoryProductData.Id,
                inventoryProductName: this.inventoryProductData.Name,
            }
        });
        this.popup.hide();

    }

    openRecipeEditor() {
        this.modalService.show(RecipeEditorComponent, {
            animated: false,
            class: 'vertical-center modal-lg',
            initialState: {
                inventoryProductId: this.inventoryProductData.Id,
                inventoryProductName: this.inventoryProductData.Name,
            }
        });
        this.popup.hide();

    }

    openMergeProduct() {
        const modalRef = this.modalService.show(MergeProductComponent, {
            animated: false,
            class: 'vertical-center',
            initialState: {
                inventoryProductId: this.inventoryProductData.Id,
                inventoryProductName: this.inventoryProductData.Name,
                dateAdded: this.inventoryProductData.DateAdded
            }
        });
        modalRef.close.subscribe((res) => {
            if (res && res.shouldReload) {
                this.reloadInventoryProducts();
            }
        });
        this.popup.hide();

    }

    markAsOrdered() {
        if (!this.inventoryProductData.IsOnOrder) {
            this.spinnerService.show();
            this.inventoryProductService.updateInventoryProductAsOrdered(this.inventoryProductData.Id,
                this.applicationStateService.userDetails.id)
                .pipe(finalize(() => {
                    this.spinnerService.hide();
                }))
                .subscribe({
                    next: () => {
                        this.reloadInventoryProducts();
                        this.alertService.renderSuccessMessage(StringUtils.format(Messages.InventoryMarkAsOrderedSuccess,
                            { 'productName': this.inventoryProductData.Name }));
                    }, error: this.alertService.showApiError
                });
        }
        this.popup.hide();

    }

    openCopyNutritionAndIngredients() {
        const modalRef = this.modalService.show(CopyInventoryIngredientsInfoComponent, {
            animated: false,
            class: 'vertical-center',
            initialState: {
                productId: this.inventoryProductData.Id,
                productName: this.inventoryProductData.Name
            }
        });
        modalRef.close.subscribe((res) => {
            if (res && res.shouldReload) {
                this.reloadInventoryProducts();
            }
        });
        this.popup.hide();

    }

    showPrintStockLabel() {
        this.modalService.show(PrepLabelPrintComponent, {
            animated: false,
            class: 'vertical-center',
            initialState: {
                productId: this.inventoryProductData.Id,
                productName: this.inventoryProductData.Name
            }
        });
        this.popup.hide();
    }

    onLinkPopOver(inventoryProductDetails: InventoryProductDetails, popup: any) {
        this.inventoryProductData = inventoryProductDetails;
        this.popup = popup;
    }

    getPropertyValue(data: any, propertyName: string): any {
        return _.get(data, propertyName);
    }

    flashLights() {
        if (this.inventoryProductData.PrimaryBinId || this.inventoryProductData.SecondaryBinId) {
            this.triggerFlash(this.inventoryProductData);
        } else {
            this.alertService.renderInformationalMessage(StringUtils.format(
                Messages.NoInventoryBinMapped, { 'inventoryProduct': this.inventoryProductData.Name }));
        }
        this.popup.hide();
    }

    triggerFlash(inventoryProduct: InventoryProductDetails) {
        this.spinnerService.show();
        inventoryProduct.PrimaryBinId = inventoryProduct.PrimaryBinId ? inventoryProduct.PrimaryBinId : 0;
        inventoryProduct.SecondaryBinId = inventoryProduct.SecondaryBinId ? inventoryProduct.SecondaryBinId : 0;
        this.inventoryProductService.triggerFlash(inventoryProduct.Id, inventoryProduct.PrimaryBinId, inventoryProduct.SecondaryBinId,
            this.applicationStateService.terminalId, this.applicationStateService.terminalName)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (res) => {
                    this.alertService.renderSuccessMessage(Messages.RequestSendSuccess);
                }, error: this.alertService.showApiError
            });
    }

    productHistory() {
        this.router.navigate([`${this.inventoryProductData.Id}/product-history`], { relativeTo: this.route, state: { GridContext: this.gridContext, Category: this.subCategoryId, Vendor: this.vendorId, Zone: this.inventoryZoneId, Active: this.isActiveInventory } });
    }

    addProductForBulkUpdate(inventoryProduct: InventoryProductDetails) {
        this.isBulkUpdateAllProducts = false;
        if (inventoryProduct.IsAddToBulkUpdate) {
            this.inventoryProductsForBulkUpdateIds.push(inventoryProduct.Id);
        } else {
            remove(this.inventoryProductsForBulkUpdateIds, x => x === inventoryProduct.Id);
        }
    }

    addAllProductsForBulkUpdate() {
        if (this.isBulkUpdateAllProducts) {
            if (this.filteredInventoryProducts.length) {
                this.inventoryProducts.forEach(x => x.IsAddToBulkUpdate
                    = this.filteredInventoryProducts.map(p => p.Id).includes(x.Id) ? true : x.IsAddToBulkUpdate);
                this.inventoryProductsForBulkUpdateIds.push(...this.filteredInventoryProducts.map(x => x.Id));
            } else {
                this.inventoryProducts.forEach(x => x.IsAddToBulkUpdate = true);
                this.inventoryProductsForBulkUpdateIds.push(...this.inventoryProducts.map(x => x.Id));
            }
        } else {
            if (this.filteredInventoryProducts.length) {
                this.inventoryProducts.forEach(x => x.IsAddToBulkUpdate =
                    this.filteredInventoryProducts.map(p => p.Id).includes(x.Id) ? false : x.IsAddToBulkUpdate);
                remove(this.inventoryProductsForBulkUpdateIds, x => this.filteredInventoryProducts.map(p => p.Id).includes(x));
            } else {
                this.inventoryProducts.forEach(x => x.IsAddToBulkUpdate = false);
                remove(this.inventoryProductsForBulkUpdateIds, x => this.inventoryProducts.map(p => p.Id).includes(x));
            }
        }
        this.inventoryProductsForBulkUpdateIds = uniq(this.inventoryProductsForBulkUpdateIds);
    }

    updateBulkProducts() {
        this.router.navigate(['bulk-update'], {
            relativeTo: this.route,
            state: { inventoryProductIds: this.inventoryProductsForBulkUpdateIds }
        });
    }

    printProductBarCodes() {
        const modalRef = this.modalService.getModalWrapper(InfoModalComponent)
        const modal = modalRef.show({
            animated: false,
            class: 'vertical-center',
            initialState: {
                message: StringUtils.format(Messages.ConfirmPrintInventoryBarCodes, { idsLength: this.inventoryProductsForBulkUpdateIds.length }),
                confirmButtonText: 'Confirm',
                rejectButtonText: 'Cancel'
            }
        });

        modal.close.subscribe((res) => {
            if (res?.shouldConfirm) {
                this.spinnerService.show();
                this.inventoryProductBarCodeService.printInventoryProductBarCodes(this.applicationStateService.terminalId, this.inventoryProductsForBulkUpdateIds)
                    .pipe(finalize(() => this.spinnerService.hide()))
                    .subscribe({
                        next: () => {
                            this.alertService.renderSuccessMessage(Messages.PrintBarcodeRequestSent);
                            this.inventoryProductsForBulkUpdateIds = [];
                            this.inventoryProducts.forEach(x => x.IsAddToBulkUpdate = false);
                        },
                        error: this.alertService.showApiError
                    })
            }
        })
    }

    close() {
        this.router.navigate(['../'], { relativeTo: this.route });
    }
}
