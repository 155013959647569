import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { newProduct, trashAltWhite } from 'src/app/shared/components/icon';
import { PromotionConfig } from 'src/app/shared/interface/rules/promotion-config';
import { PromotionDataService } from 'src/app/shared/services';
import { PromotionService } from 'src/app/shared/services/rules/promotion.service';

@Component({
  selector: 'pos-reward',
  templateUrl: './reward.component.html',
  styleUrls: ['./reward.component.scss'],
  viewProviders : [{ provide : ControlContainer, useExisting : NgForm }]
})
export class RewardComponent implements OnChanges {

  @Input() rewards: Array<PromotionConfig> = [];
  @Input() configuredRewards: Array<PromotionConfig>;
  @Input() index = 0;
  @Input() rewardCaption = 'Reward';
  @Input() isMultipleReward = true;
  icons = {
    newProduct, trashAltWhite
  }
  selectedReward: PromotionConfig;
  rewardsCopy: Array<PromotionConfig>;
  constructor(protected form: NgForm,
    protected promotionData: PromotionDataService,
    protected promotionService: PromotionService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.rewardsCopy = cloneDeep(this.rewards);
  }
  rewardChanged(reward: PromotionConfig) {
    this.rewards = cloneDeep(this.rewardsCopy);
    const selectedReward = this.rewards.find(x => x.Name == reward.Name);
    reward.Config = selectedReward?.Config ?? null;
    reward.Description = selectedReward?.Description ?? null;
  }

  addReward() {
    this.configuredRewards.push(this.promotionData.newPromotionConfig());
  }

  deleteReward(index: number) {
    this.configuredRewards.splice(index, 1);
  }

}
