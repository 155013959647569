import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'pos-inventory-product-dashboard',
  templateUrl: './inventory-product-dashboard.component.html',
  styleUrls: ['./inventory-product-dashboard.component.scss']
})
export class InventoryProductDashboardComponent implements OnInit {

  @Input() inventoryProductName: string;
  @Input() inventoryProductId: number;
  @Input() dashboardId: number;
  @Output() close: EventEmitter<any> = new EventEmitter();

  height = window.innerHeight - 150;

  constructor() { }

  ngOnInit(): void {
  }

  onCancel() {
    this.close.emit();
  }
}
