import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ScheduleOrderActiveHoursOverride } from 'src/app/orders/order-entry/special-functions/interfaces/schedule-order-active-hours-override';
import { RuntimeConstants } from '../constants/runtime-constants';
import { BaseCrudService } from './base-crud.service';

@Injectable({
  providedIn: 'root'
})
export class ScheduleOrderActiveHoursOverrideService extends BaseCrudService<ScheduleOrderActiveHoursOverride> {

  scheduleOverrideApiPath = `${RuntimeConstants.API_PATH}schedule-order-active-hours-override`;

  constructor(private httpClient: HttpClient) {
    super('schedule-order-active-hours-override', httpClient);
  }

  getNewOverrideSchedule(): ScheduleOrderActiveHoursOverride {
    return {
        Id: 0,
        FromDate: new Date(new Date(Date.now()).setHours(0, 0, 0, 0)),
        ToDate: new Date(new Date(Date.now()).setHours(0, 0, 0, 0)),
        StartTime: null,
        EndTime: null,
    };
  }

  setOverrideSchedule(schedule: ScheduleOrderActiveHoursOverride): ScheduleOrderActiveHoursOverride {
    return {
        Id: schedule.Id,
        FromDate: new Date(schedule.FromDate.toString()),
        ToDate: new Date(schedule.ToDate.toString()),
        StartTime: schedule.StartTime,
        EndTime: schedule.EndTime,
    };
  }

  getNextScheduleOverrides = () => {
    return this.httpClient.get(`${this.scheduleOverrideApiPath}/next`);
  }
}
