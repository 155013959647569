import { Component, OnInit, ViewChild } from '@angular/core';
import { orderBuilding, times } from 'src/app/shared/components/icon';
import { cloneDeep, filter, findIndex, forEach } from 'lodash';
import { ScheduleDaysSettings } from '../../interfaces/schedule-days-settings';
import { finalize } from 'rxjs/operators';
import { OrderWorkflowService } from '../../services/order-workflow.service';
import { OrderWorkflowConfigurationParameter } from '../../interfaces/order-workflow-configuratoin-parameter';
import { FieldInfoMessages } from 'src/app/shared/constants/field-info-messages';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { DomainConstants } from 'src/app/shared/constants/domain-constants';
import { Terminal, TerminalsService } from 'src/app/configurator/terminals';
import { forkJoin, Observable } from 'rxjs';
import { find } from 'highcharts';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { BaseFormComponent, FormUtilityService, LoggerService, SettingParam } from 'src/app/shared';
import { NgForm } from '@angular/forms';
import { PostSavedConfigurationService } from 'src/app/configurator/post-saved-configuration.service';

@Component({
  selector: 'pos-order-workflow',
  templateUrl: './order-workflow.component.html',
  styleUrls: ['./order-workflow.component.scss']
})
export class OrderWorkflowComponent extends BaseFormComponent implements OnInit {
  @ViewChild('orderWorkflowForm') orderWorkflowForm: NgForm;
  get getForm(): NgForm {
    return this.orderWorkflowForm
  }
  orderWorkflowParameters: OrderWorkflowConfigurationParameter;
  scheduleDaysSettings: Array<ScheduleDaysSettings> = [];
  fieldInfoMessages = FieldInfoMessages;
  promptServeMethods = cloneDeep(DomainConstants.PromptServeMethods);
  pagerStartNumber: number;
  pagerEndNumber: number;
  orderEntryTerminals = [];
  isOrderPrompt = false;
  isValidOrderPrompts = true;
  subAccountTerm: string;
  isAutoSignOutOnUserIdle = false;
  settingParam: SettingParam;
  quantityChangeOptions = [
    { Name: DomainConstants.QuantityChange.PLUS_MINUS_BUTTONS },
    { Name: DomainConstants.QuantityChange.HASH_BUTTON }
  ];
  requireNameOnOrderOptions = [
    { Name: DomainConstants.RequireNameOnOrder.NEVER },
    { Name: DomainConstants.RequireNameOnOrder.BEFORE_ORDER },
    { Name: DomainConstants.RequireNameOnOrder.AT_RING_UP }];
  icons = {
    times,
    orderBuilding
  };
  constructor(
    private orderWorkflowService: OrderWorkflowService,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private terminalsService: TerminalsService,
    private router: Router,
    private applicationStateService: ApplicationStateService,
    private loggerService: LoggerService,
    formUtilityService: FormUtilityService,
    private postSavedConfigurationService: PostSavedConfigurationService,
    private route: ActivatedRoute
  ) {
    super(formUtilityService);
  }

  ngOnInit(): void {
    this.orderWorkflowParameters = this.orderWorkflowService.newOrderWorkflowConfigurationParameter();
    this.subAccountTerm = this.applicationStateService.settingParam.SubaccountTerm?.trim() || 'Seat';
    this.settingParam = this.applicationStateService.settingParam;
    this.loadData();
  }

  private loadData() {
    this.spinnerService.show();
    const observables: Array<Observable<any>> = [];
    observables.push(this.terminalsService.getTerminals());
    observables.push(this.orderWorkflowService.getOrderWorkflowParameters());
    forkJoin(observables).pipe(finalize(() => {
      this.spinnerService.hide();
    }))
      .subscribe({
        next: ([terminals, params]: [Terminal[], OrderWorkflowConfigurationParameter]) => {
          this.orderWorkflowParameters = params;
          if (this.orderWorkflowParameters.PagerRange) {
            const range = this.orderWorkflowParameters.PagerRange.split('-');
            this.pagerStartNumber = parseInt(range[0], 10);
            this.pagerEndNumber = parseInt(range[1], 10);
          }
          this.orderEntryTerminals = filter(terminals, (terminal) =>
            terminal.TerminalType === DomainConstants.TerminalTypes.ORDER_ENTRY.Name);

          forEach(this.orderWorkflowParameters.OrderPrompts, (item) => {
            const promptOrder = find(this.promptServeMethods, x => x.id === item);
            if (promptOrder) {
              promptOrder.isSelected = true;
              this.isOrderPrompt = true;
            }
          });
          this.scheduleDaysSettings = JSON.parse(this.orderWorkflowParameters.ScheduleOrderActiveHours);
          this.isAutoSignOutOnUserIdle = this.orderWorkflowParameters.AutoSignOutOnUserIdleSeconds > 0;
          this.prepareWeekDay();
        },
        error: this.alertService.showApiError
      });
  }

  removeTime(startTime: boolean, index: number) {
    const settings = this.scheduleDaysSettings;
    if (startTime) {
      settings[index].startTime = '';
    } else {
      settings[index].endTime = '';
    }
  }

  saveOrderWorkflow(isValid: boolean) {
    let invalidSchedule = false;

    if (!isValid || (this.pagerEndNumber > (this.pagerStartNumber + 49))) {
      return
    }

    if (this.isOrderPrompt && !this.isValidOrderPrompts) {
      return;
    }

    if (this.orderWorkflowParameters.IsUsePager) {
      // Default pager range is 1-20 if min or max not specified then default range will apply.
      this.orderWorkflowParameters.PagerRange = (this.pagerStartNumber ? this.pagerStartNumber : 1) + '-' +
        (this.pagerEndNumber ? this.pagerEndNumber : 20);
    } else {
      this.orderWorkflowParameters.PagerRange = '';
    }

    forEach(this.scheduleDaysSettings, (day) => {

      if (day.startTime === '') {
        day.startTime = '00:00';
      }

      if (day.endTime === '') {
        day.endTime = '23:59';
      }
      if (this.timeToDateTime(day.startTime) > this.timeToDateTime(day.endTime)) {
        invalidSchedule = true;
        return false;
      }
    });

    if (invalidSchedule) {
      this.alertService.renderErrorMessage(Messages.ErrorWhileScheduleEndTimeIsLessThanStartTime);
      return;
    }

    if (this.orderWorkflowParameters.BarcodeScanningInitiatorSequence == this.settingParam.RFIDInitiatorSequence
      && this.orderWorkflowParameters.BarcodeScanningEndingSequence == this.settingParam.RFIDEndingSequence) {
        this.alertService.renderErrorMessage(Messages.ErrorWhileBarcodeSequenceSameAsRFID);
        return;
    }

    this.orderWorkflowParameters.OrderPrompts = this.promptServeMethods.filter(x => x.isSelected).map(x => x.id).join(',');
    this.prepareWeekDay(true);
    this.orderWorkflowParameters.ScheduleOrderActiveHours = JSON.stringify(this.scheduleDaysSettings);
    this.spinnerService.show();
    this.orderWorkflowService.updateOrderWorkflowParameters(this.orderWorkflowParameters).pipe(finalize(() => {
      this.spinnerService.hide();
    }))
      .subscribe({
        next: () => {
          this.alertService.renderSuccessMessage(Messages.OrderWorkflowSaveSuccess);
          this.postSavedConfigurationService.showMessage();
        }, error: this.alertService.showApiError
      });
  }

  promptOrders() {
    if (!this.isOrderPrompt) {
      forEach(this.promptServeMethods, (orderPrompt) => {
        orderPrompt.isSelected = false;
      });
    }
    this.checkOrderPromptsValidation();
  }

  checkOrderPromptsValidation() {
    this.isValidOrderPrompts = false;
    const selectedServeMethods = filter(this.promptServeMethods, (item) => {
      return item.isSelected;
    });
    if (selectedServeMethods.length > 0) {
      this.isValidOrderPrompts = true;
    }
  }
  changeUsePager() {
    if (this.orderWorkflowParameters.IsUsePager && !this.orderWorkflowParameters.PagerRange) {
      this.pagerStartNumber = 1;
      this.pagerEndNumber = 20;
    } else {
      this.pagerStartNumber = null;
      this.pagerEndNumber = null;
    }
  }

  private timeToDateTime(timeString) {
    const timeTokens = timeString.split(':');
    if (timeTokens && timeTokens.length > 0) {
      return new Date(1970, 0, 1, timeTokens[0], timeTokens[1]).getTime();
    }
  }

  prepareWeekDay(rearrange = false) {
    const startWeekDay = rearrange ? 'Sunday' : this.applicationStateService.settingParam.WeekStart;
    const startWeekDayIndex = findIndex(this.scheduleDaysSettings, (day) => {
      return day.day === startWeekDay;
    });
    if (startWeekDayIndex) {
      const scheduleDaysSettingsCopy = [...this.scheduleDaysSettings];
      this.scheduleDaysSettings = [];
      this.scheduleDaysSettings.push(...scheduleDaysSettingsCopy.slice(startWeekDayIndex, scheduleDaysSettingsCopy.length));
      this.scheduleDaysSettings.push(...scheduleDaysSettingsCopy.slice(0, startWeekDayIndex));
    }
  }

  cancel() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
