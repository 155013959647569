import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { Terminal } from '../../interface/terminal';
import {
  BaseCrudComponent, AlertsService, SpinnerService, Messages, FieldInfoMessages, DomainConstants,
  ApplicationStateService,
  SettingParam,
  ModalService,
  ConfirmDeleteModalComponent,
  DashboardTerminalPropertiesService,
  FormUtilityService,
  RuntimeConstants,
  OrderKioskScreenService,
  RabbitMQService
} from 'src/app/shared';
import { TerminalsService } from '../../services/terminals.service';
import { finalize } from 'rxjs/operators';
import { TerminalType } from '../../interface/terminal-type';
import { forkJoin, Observable } from 'rxjs';
import { SlideShowService } from 'src/app/configurator/digital-signage/services/slide-show.service';
import { SlideShow } from 'src/app/configurator/digital-signage/interface/slide-show';
import { TerminalSlideshowMap } from '../../interface/terminal-slideshow-map';
import * as _ from 'lodash';
import { LayoutService } from 'src/app/orders/services/layout.service';
import { DjSchemeService } from 'src/app/configurator/dj-schemes/services/dj-scheme.service';
import { TerminalDashboardProperty } from 'src/app/configurator/dashboard-terminal-property/interface/dashboard-terminal-property';
import { TerminalDetail } from '../../interface/terminal-detail';
import { Layout } from 'src/app/orders';
import { SalesProduct } from 'src/app/information-management/sales-products/interface';
import { SalesProductsService } from 'src/app/information-management/sales-products/services';
import { ScreenListModel } from '../../interface/screen-list-model';
import { Options } from '@angular-slider/ngx-slider/options';
import { DjScheme } from 'src/app/configurator/dj-schemes';
import { Dashboard } from 'src/app/shared/interface/dashboard';
import { cloneDeep, find, forEach, remove } from 'lodash';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { NgForm } from '@angular/forms';
import { ProductBuildQueueGridInfo } from 'src/app/product-build-queue/interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { FilteredProducts } from 'src/app/make-table/filtered-product';
import { TerminalProperty } from '../../interface/terminal-property';
import { MenuDisplayService } from 'src/app/menu-display/services/menu-display.service';
import { MenuDisplay } from 'src/app/menu-display/interfaces/menu-display';
import { MenuDisplaySetting } from '../../interface/menu-display-setting';
import { OrderWorkflowService } from 'src/app/configurator/workflow/services/order-workflow.service';
import { stopRed, playGreen, terminalTv, code } from 'src/app/shared/components/icon/icons';
import { PostSavedConfigurationService } from 'src/app/configurator/post-saved-configuration.service';
import { KioskScreen } from 'src/app/order-kiosk/interfaces';

@Component({
  selector: 'pos-terminals-edit',
  templateUrl: './terminals-edit.component.html',
  styleUrls: ['./terminals-edit.component.scss']
})
export class TerminalsEditComponent extends BaseCrudComponent<Terminal> implements OnInit, OnDestroy {
  @ViewChild('terminalForm') terminalForm: NgForm;
  get getForm(): NgForm {
    return this.terminalForm
  }
  @Input() id: number;
  terminal: Terminal;
  terminalTypes: Array<TerminalType> = [];
  fieldInfoMessages = FieldInfoMessages;
  terminalTypeNames = DomainConstants.TerminalTypes;
  terminalPropertyNames = DomainConstants.TerminalProperties;
  terminals: Array<Terminal> = [];
  settingParam: SettingParam;
  timerTerminalOptions = DomainConstants.TimerTerminalOptions;
  tagAudioRequestTypes = DomainConstants.TagAudioRequestTypes;
  audioInteractionModeList = DomainConstants.AudioInteractionModes;
  slideshows: Array<SlideShow> = [];
  terminalSlideshowMap: TerminalSlideshowMap;
  selectedMakeTableTerminals = [];
  makeTableTerminalIds: Array<TerminalDetail> = [];
  isUseMakeTable = false;
  ipAddress: string;
  ipAddressLevel: boolean;
  maxProducts: number;
  displayMenuData: Array<MenuDisplay> = [];
  displayMenuId: number = null;
  menuDisplaySettings: Array<MenuDisplaySetting> = [];
  isLoadMenuDisplayOverride = false;
  DJSchemeIds = [];
  djSchemes: Array<DjScheme> = [];
  volume = 0;
  options: Options = {
    floor: 0,
    ceil: 100,
    translate: (value: number): string => {
      return '';
    }
  };
  isValidOrderPrompts = true;
  isOrderPrompt = false;
  selectedHomeScreen: number;
  selectedContinueOrderScreen: number;
  tagAudioRequestType: string = 'tag';
  audioInteractionModes: Array<string> = [];
  tableLayoutIds: Array<number> = [];
  screenList: Array<Screen> = [];
  tableLayouts: Array<Layout> = [];
  promptServeMethods = [];

  orderEntryTerminals: Array<Terminal> = [];
  scrollSpeed: number;
  idleTimeout: number;
  manualAdjustment = true;
  fontSize: number;

  gridColumns: number;
  gridRows: number;
  layouts = DomainConstants.MakeTableLayout;
  selectedLayout = 'Grid';

  timerOptions: Array<string> = [];
  timerOption;
  noOfSlot: number;
  prepGroups: Array<string> = [];
  prepGroupsList: Array<SalesProduct> = [];
  slotTimes: Array<{ seconds: number; minutes: Date; sound?: string, soundPlaying?: boolean }> = [];

  dashboardList: Array<Dashboard> = [];
  managementDashboard: number;
  dashboardSec = 1;
  editedDashboard: TerminalDashboardProperty;
  terminalDashboardProperties: Array<TerminalDashboardProperty> = [];
  isLinkNewMakeTable = false;
  isNewTerminal = false;
  orderEntryTerminalIds: Array<number> = [];
  clonedOrderEntryTerminalIds: Array<number> = [];
  isReadOnly = false;
  isFilteredProducts = false;
  filteredProducts: FilteredProducts = { SalesCategories: [], SalesGroups: [] };
  isTimeOverlapping = false;
  menuExplorerIdleTimeout: number = null;
  icons = { terminalTv, playGreen, stopRed, code }
  timerSoundsObject: Array<{ Key: string, Value: string }> = [];
  timerSound: string = null;
  monitorForConnection: boolean = false;
  props = {
    controlBoxClass: 'col-lg-3 col-md-5 col-sm-8 col-xs-12'
  }

  audio: HTMLAudioElement = new Audio();
  audioPlayedCount = 0;
  kioskScreens: Array<KioskScreen> = [];
  kioskHeight: number = null;
  kioskWidth: number = null;
  fontFamily: string = null;
  userIdleTimeout: number = 0;
  isEnabled = true;

  maxEntries = 0;
  maxWaitTime = 0;
  notificationText = '';
  isOpenVariableForNotificationText = false;
  notificationTextVariables = [ 'Code', 'NotifyCount', 'PhoneNumber', 'WaitingSince' ];
  selectedNotificationTextVariable = null;
  cursorPosition = 0;

  constructor(protected terminalService: TerminalsService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    private slideshowService: SlideShowService,
    private djSchemeService: DjSchemeService,
    private layoutService: LayoutService,
    private orderWorkflowService: OrderWorkflowService,
    private dashboardTerminalPropertiesService: DashboardTerminalPropertiesService,
    private modalService: ModalService,
    private salesProductService: SalesProductsService,
    private applicationStateService: ApplicationStateService,
    private menuDisplayService: MenuDisplayService,
    private orderKioskScreenService: OrderKioskScreenService,
    private router: Router,
    private postSavedConfigurationService: PostSavedConfigurationService,
    private route: ActivatedRoute,
    private rabbitMQService: RabbitMQService,
    formUtilityService: FormUtilityService) {
    super(terminalService, alertService, spinnerService, formUtilityService);
    this.terminal = terminalService.newTerminal();
    this.terminalSlideshowMap = terminalService.newSlideshowMapModel();
    this.id = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
  }

  ngOnInit() {
    this.settingParam = this.applicationStateService.settingParam;
    this.isUseMakeTable = this.settingParam.IsUseMakeTable;
  }

  getDataForMenuDisplay() {
    const menuDisplayTerminalType = find(this.terminalTypes, (terminalType) => {
      return terminalType.Name === this.terminalTypeNames.MENU_DISPLAY.Name;
    });
    if (menuDisplayTerminalType?.Id === this.terminal.TerminalTypeId) {
      this.spinnerService.show();
      this.menuDisplayService.getAll()
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res) => {
            this.displayMenuData = res;
          }
        });
    }
  }

  loadDependencies(): void {
    const terminalObservable: Array<Observable<any>> = [];
    terminalObservable.push(this.terminalService.getTerminalTypes());
    terminalObservable.push(this.slideshowService.getAll());
    terminalObservable.push(this.terminalService.getAll());
    terminalObservable.push(this.djSchemeService.getAll());
    terminalObservable.push(this.terminalService.getScreenList());
    terminalObservable.push(this.layoutService.getLayouts());
    terminalObservable.push(this.terminalService.getTimerOptions());
    terminalObservable.push(this.terminalService.getPrepGroupName());
    terminalObservable.push(this.terminalService.getDashboards());
    terminalObservable.push(this.terminalService.getTimerSounds());
    terminalObservable.push(this.orderKioskScreenService.getAll());

    this.spinnerService.show();
    if (this.id > 0) {
      terminalObservable.push(this.terminalService.get(this.id));
    }
    forkJoin(terminalObservable)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: ([terminalTypes, slideshows, terminals, djSchemes, screenList, tableLayouts,
          timerOptions, prepGroups, dashboardList, timerSounds, kioskScreens, terminal]:
          [Array<TerminalType>, Array<SlideShow>, Array<Terminal>, Array<DjScheme>, ScreenListModel, Array<Layout>,
            Array<string>, Array<SalesProduct>, Array<Dashboard>, Array<string>, Array<KioskScreen>, Terminal]) => {
          this.terminalTypes = terminalTypes;
          this.slideshows = slideshows;
          this.terminals = terminals;
          this.djSchemes = djSchemes;
          this.screenList = screenList.ScreenList;
          this.tableLayouts = tableLayouts;
          this.timerOptions = timerOptions;
          this.kioskScreens = kioskScreens;
          this.prepTimerSoundObject(timerSounds)
          const newPrepGroup = this.salesProductService.newSalesProduct();
          newPrepGroup.PrepGroupName = 'All';
          this.prepGroupsList.push(newPrepGroup);
          this.prepGroupsList.push(...prepGroups);
          this.dashboardList = dashboardList;
          this.bindMakeTableDropdown();
          this.bindOrderEntryDropdown();
          this.getCurrentOrderPrompts();
          if (this.id > 0) {
            this.terminal = terminal[0];
            this.terminal.Id = this.terminal.TerminalId;
            this.terminal.MakeTableTerminalIds = '';
            this.terminal.OrderEntryTerminalIds = '';
            this.getTerminalProperties();
            if (this.terminal.TerminalType === this.terminalTypeNames.DIGITAL_SIGNAGE.Name) {
              this.getTerminalSlideshow();
            } else if (this.terminal.TerminalType === this.terminalTypeNames.INSTRUCTION_SCREEN.Name) {
              this.getTerminalPropertiesForInstructionScreen();
            } else if (this.terminal.TerminalType === this.terminalTypeNames.DJ.Name) {
              this.getTerminalPropertiesForDJ();
            } else if (this.terminal.TerminalType === this.terminalTypeNames.ORDER_ENTRY.Name) {
              this.getCurrentOrderPrompts();
            } else if (this.terminal.TerminalType === this.terminalTypeNames.ORDER_CONFIRMATION_SCREEN.Name) {
              this.getTerminalSlideshow();
              this.getMappedTerminals(this.terminalTypeNames.ORDER_ENTRY.Name);
              this.getTerminalPropertiesForOrderConfirmation();
            } else if (this.terminal.TerminalType === this.terminalTypeNames.ORDER_STATUS.Name) {
              this.getTerminalSlideshow();
              this.getTerminalPropertiesForOrderStatus();
            } else if (this.terminal.TerminalType === this.terminalTypeNames.MAKE_TABLE.Name) {
              this.getTerminalPropertiesForMakeTable();
              this.getMappedTerminals(this.terminalTypeNames.ORDER_ENTRY.Name + ',' + this.terminalTypeNames.ORDER_KIOSK.Name);
            } else if (this.terminal.TerminalType === this.terminalTypeNames.TIMER.Name) {
              this.getTerminalPropertiesForTimer();
            } else if (this.terminal.TerminalType === this.terminalTypeNames.MANAGEMENT_DASHBOARD.Name) {
              this.getTerminalPropertiesForDashboard();
            } else if (this.terminal.TerminalType === this.terminalTypeNames.PRODUCT_BUILD_QUEUE.Name) {
              this.getTerminalPropertiesForProductBuildQueue();
            } else if (this.terminal.TerminalType === this.terminalTypeNames.MENU_DISPLAY.Name) {
              this.getMenuDisplaySettings();
              this.getTerminalPropertiesForMenuDisplay();
            } else if (this.terminal.TerminalType === this.terminalTypeNames.MENU_EXPLORER.Name) {
              this.getTerminalPropertiesForMenuExplorer();
            } else if (this.terminal.TerminalType === this.terminalTypeNames.ORDER_KIOSK.Name) {
              this.getTerminalSlideshow();
              this.getTerminalPropertiesForOrderKiosk();
            } else if (this.terminal.TerminalType === this.terminalTypeNames.WAITLIST.Name) {
              this.getTerminalPropertiesForWaitList();
            }
            if (this.terminal.TerminalType === this.terminalTypeNames.PICK_SCREEN.Name ||
              this.terminal.TerminalType === this.terminalTypeNames.INSTRUCTION_SCREEN.Name ||
              this.terminal.TerminalType === this.terminalTypeNames.ORDER_ENTRY.Name || 
              this.terminal.TerminalType === this.terminalTypeNames.ORDER_KIOSK.Name) {
              this.getMappedTerminals(this.terminalTypeNames.MAKE_TABLE.Name);
            }
          }
          this.getDataForMenuDisplay();
        },
        error: this.alertService.showApiError
      });
  }
  prepTimerSoundObject(timerSounds: string[]) {
    forEach(timerSounds, file => {
      const key = file.replace(/\.[^/.]+$/, "").replace(/[-_]/g, " ");
      this.timerSoundsObject.push({ Key: key, Value: file });

    })
  }

  saveTerminal(isValid: boolean) {
    let isValidTime = true;
    this.isTimeOverlapping = false;
    if (!isValid || this.maxProducts < 1 || this.maxProducts > 8 || this.scrollSpeed < 1 || this.scrollSpeed > 99
      || this.idleTimeout < 1 || this.idleTimeout > 60 || (this.isOrderPrompt && !this.isValidOrderPrompts)) {
      return;
    }
    forEach(this.menuDisplaySettings, (daypart) => {
      if (daypart.StartTime && daypart.EndTime) {
        if (this.timeToDateTime(daypart.StartTime) > this.timeToDateTime(daypart.EndTime)) {
          isValidTime = false;
        }
      }
    });
    if (!isValidTime) {
      this.alertService.renderErrorMessage(Messages.ErrorWhileStartTimeIsLaterThanEndTime);
      return;
    }
    forEach(this.menuDisplaySettings, (setting, index) => {
      if (setting.StartTime && setting.EndTime) {
        this.checkOverlappingTime(setting, index);
      }
    });
    if (this.isTimeOverlapping) {
      return;
    }
    this.setTerminalPropertiesForIpAddress();
    this.setTerminalProperty(this.terminalPropertyNames.MONITOR_FOR_CONNECTION.Key, this.monitorForConnection);
    if (this.terminal.TerminalType === this.terminalTypeNames.PICK_SCREEN.Name) {
      this.setMakeTableTerminalIds();
    } else if (this.terminal.TerminalType === this.terminalTypeNames.DIGITAL_SIGNAGE.Name) {
      this.terminal.TerminalSlideshowMapModel = this.terminalSlideshowMap;
    } else if (this.terminal.TerminalType === this.terminalTypeNames.INSTRUCTION_SCREEN.Name) {
      this.setInstructionScreenProperties();
      this.setMakeTableTerminalIds();
    } else if (this.terminal.TerminalType === this.terminalTypeNames.DJ.Name) {
      this.setDjTerminalProperties();
    } else if (this.terminal.TerminalType === this.terminalTypeNames.ORDER_ENTRY.Name) {
      this.setOrderEntryTerminalProperties();
      this.setMakeTableTerminalIds();
    } else if (this.terminal.TerminalType === this.terminalTypeNames.ORDER_CONFIRMATION_SCREEN.Name) {
      this.setTerminalSlideshowMapModel();
      this.setOrderConfirmationTerminalProperties();
    } else if (this.terminal.TerminalType === this.terminalTypeNames.ORDER_STATUS.Name) {
      this.setTerminalSlideshowMapModel();
      this.setOrderStatusTerminalProperties();
    } else if (this.terminal.TerminalType === this.terminalTypeNames.MAKE_TABLE.Name) {
      this.setMakeTableTerminalProperties();
    } else if (this.terminal.TerminalType === this.terminalTypeNames.TIMER.Name) {
      this.setTimerTerminalProperties();
    } else if (this.terminal.TerminalType === this.terminalTypeNames.PRODUCT_BUILD_QUEUE.Name) {
      this.setProductBuildQueueTerminalProperties();
    } else if (this.terminal.TerminalType === this.terminalTypeNames.MENU_DISPLAY.Name) {
      this.setTerminalPropertiesForMenuDisplay();
    } else if (this.terminal.TerminalType === this.terminalTypeNames.MENU_EXPLORER.Name) {
      this.setTerminalPropertiesForMenuExplorer();
    } else if (this.terminal.TerminalType === this.terminalTypeNames.ORDER_KIOSK.Name) {
      this.setTerminalSlideshowMapModel();
      this.setTerminalPropertiesForOrderKiosk();
      this.setMakeTableTerminalIds();
    } else if (this.terminal.TerminalType === this.terminalTypeNames.WAITLIST.Name) {
      this.setTerminalPropertiesForWaitList();
    }
    this.spinnerService.show();
    this.terminalService.saveTerminalDetails(this.terminal)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: Terminal) => {
          this.terminal.Id = response.Id;
          this.onSaveSuccess(this.terminal);
          this.alertService.renderSuccessMessage(Messages.TerminalConfigAddSuccess);
          this.isNewTerminal = true;
          if (this.terminal.TerminalType === this.terminalTypeNames.MAKE_TABLE.Name && this.isLinkNewMakeTable) {
            this.linkMakeTable();
          }
          if(this.terminal.TerminalType === this.terminalTypeNames.ORDER_KIOSK.Name) {
            this.rabbitMQService.sendOrderKioskStateUpdatedMessage(this.terminal.Id, !this.isEnabled);
          }
          this.postSavedConfigurationService.showMessage(false);
        }, error: (err) => {
          this.orderEntryTerminalIds = this.clonedOrderEntryTerminalIds;
          this.alertService.showApiError(err);
        }
      });
  }

  timeToDateTime(timeString) {
    const timeTokens = timeString.split(':');
    if (timeTokens && timeTokens.length > 0) {
      return new Date(1970, 0, 1, timeTokens[0], timeTokens[1]).getTime();
    }
  }

  checkOverlappingTime(setting: MenuDisplaySetting, settingIndex: number) {
    forEach(this.menuDisplaySettings, (menuDisplaySetting, index) => {
      const hasSameDays = (setting.DaysIds.filter(day => menuDisplaySetting.DaysIds.includes(day)))?.length > 0;
      if (menuDisplaySetting.StartTime && menuDisplaySetting.EndTime) {
        if (menuDisplaySetting.StartTime === setting.StartTime && setting.EndTime === menuDisplaySetting.EndTime
          && index !== settingIndex) {
          this.isTimeOverlapping = true;
          setting.HasBetweeenStartTime = true;
          setting.HasBetweeenEndTime = true;
        }
        if (menuDisplaySetting.StartTime < setting.StartTime && setting.StartTime < menuDisplaySetting.EndTime && hasSameDays) {
          this.isTimeOverlapping = true;
          setting.HasBetweeenStartTime = true;
        }
        if (menuDisplaySetting.StartTime < setting.EndTime && setting.EndTime < menuDisplaySetting.EndTime && hasSameDays) {
          this.isTimeOverlapping = true;
          setting.HasBetweeenEndTime = true;
        }
      }
    });
  }

  onSaveSuccess(model: Terminal): void {
    if (!this.isLinkNewMakeTable) {
      this.router.navigate(['terminal'], { relativeTo: this.route.parent });
    }
  }

  cancelTerminalEdit() {
    this.router.navigate(['terminal'], { relativeTo: this.route.parent });
  }

  changeTerminalTypeName() {
    const selectedTerminalType = this.terminalTypes.find(x => x.Id === this.terminal.TerminalTypeId);
    this.terminal.TerminalType = selectedTerminalType && selectedTerminalType.Name ? selectedTerminalType.Name : null;
    this.terminal.OrderEntryTerminalId = null;
    this.getDataForMenuDisplay();
  }

  getTerminalSlideshow() {
    this.spinnerService.show();
    this.terminalService.getTerminalSlideshow(this.terminal.Id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          this.terminalSlideshowMap = response.length > 0 ? response[0] : this.terminalService.newSlideshowMapModel();
        }, error: this.alertService.showApiError
      });
  }

  setTerminalSlideshowMapModel() {
    if (this.terminalSlideshowMap && this.terminalSlideshowMap.Id === 0 &&
      this.terminalSlideshowMap.SlideshowId === null && this.terminalSlideshowMap.TerminalId === 0) {
      this.terminal.TerminalSlideshowMapModel = null;
    } else if (this.terminalSlideshowMap && this.terminalSlideshowMap.SlideshowId === null) {
      this.terminalSlideshowMap.SlideshowId = 0;
      this.terminal.TerminalSlideshowMapModel = this.terminalSlideshowMap;
    } else {
      this.terminal.TerminalSlideshowMapModel = this.terminalSlideshowMap;
    }
  }

  bindMakeTableDropdown() {
    this.selectedMakeTableTerminals = _.filter(this.terminals,
      (makeTableTerminal) => makeTableTerminal.TerminalType === this.terminalTypeNames.MAKE_TABLE.Name);
  }

  getMappedTerminals(mappedTerminalType) {
    this.spinnerService.show();
    this.terminalService.getMappedTerminals(this.terminal.Id, mappedTerminalType)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: Array<TerminalDetail>) => {
          if (response.length > 0) {
            this.makeTableTerminalIds = response.filter(mappedTableTerminalsForAllMakeTable => {
              if (mappedTableTerminalsForAllMakeTable.TypeName === this.terminalTypeNames.MAKE_TABLE.Name) {
                return true;
              }
            });
            _.forEach(response, (mappedTableTerminalsForAllMakeTable) => {
              if (mappedTableTerminalsForAllMakeTable.TypeName === this.terminalTypeNames.ORDER_ENTRY.Name) {
                this.terminal.OrderEntryTerminalId = mappedTableTerminalsForAllMakeTable.id;
              }
            });
            _.forEach(this.makeTableTerminalIds, (terminal) => {
              terminal.TerminalName = terminal.terminal_name;
              terminal.TerminalId = terminal.id;
            });
            this.orderEntryTerminalIds = response.map(x => x.id);
            this.clonedOrderEntryTerminalIds = cloneDeep(this.orderEntryTerminalIds);
          }
        }, error: this.alertService.showApiError
      });
  }

  setMakeTableTerminalIds() {
    this.terminal.MakeTableTerminalIds = this.makeTableTerminalIds.map(x => x.TerminalId).join(',');
  }

  linkToAllMakeTable() {
    this.makeTableTerminalIds = this.selectedMakeTableTerminals;
  }

  getTerminalPropertiesForInstructionScreen() {
    this.spinnerService.show();
    this.terminalService.getTerminalProperties(this.terminal.Id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          this.terminal.TerminalPropertiesModel = response ? response : [];
          _.forEach(this.terminal.TerminalPropertiesModel, (property) => {
            if (property.PropertyKey === this.terminalPropertyNames.INSTRUCTION_MAX_PRODUCTS.Key) {
              this.maxProducts = parseInt(property.PropertyValue, 10);
            }
          });
        }
      });
  }

  setInstructionScreenProperties() {
    _.forEach(this.terminal.TerminalPropertiesModel, (property) => {
      if (property.PropertyKey === this.terminalPropertyNames.INSTRUCTION_MAX_PRODUCTS.Key) {
        property.PropertyValue = this.maxProducts.toString();
      }
    });
    const maxProductsProperty = _.find(this.terminal.TerminalPropertiesModel,
      (prop) => prop.PropertyKey === this.terminalPropertyNames.INSTRUCTION_MAX_PRODUCTS.Key);
    if (!maxProductsProperty) {
      const selectedTerminalProperties = {
        Id: 0,
        PropertyKey: this.terminalPropertyNames.INSTRUCTION_MAX_PRODUCTS.Key,
        PropertyValue: this.maxProducts.toString(),
        TerminalId: 0
      };
      this.terminal.TerminalPropertiesModel.push(selectedTerminalProperties);
    }
  }

  bindDjSchemesDropdown() {
    this.spinnerService.show();
    this.djSchemeService.getAll()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          this.djSchemes = response;
        }, error: this.alertService.showApiError
      });
  }

  getTerminalPropertiesForDJ() {
    this.spinnerService.show();
    this.terminalService.getTerminalProperties(this.terminal.Id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          this.terminal.TerminalPropertiesModel = response ? response : [];
          _.forEach(this.terminal.TerminalPropertiesModel, (property) => {
            if (property.PropertyKey === this.terminalPropertyNames.VOLUME.Key) {
              this.volume = parseInt(property.PropertyValue, 10);
            } else if (property.PropertyKey === this.terminalPropertyNames.DJ_SCHEME.Key) {
              this.DJSchemeIds = property.PropertyValue.split(',').map(x => parseInt(x, 10));
            }
          });
        }
      });
  }

  setDjTerminalProperties() {
    _.forEach(this.terminal.TerminalPropertiesModel, (property) => {
      if (property.PropertyKey === this.terminalPropertyNames.VOLUME.Key) {
        property.PropertyValue = this.volume.toString();
      } else if (property.PropertyKey === this.terminalPropertyNames.DJ_SCHEME.Key) {
        property.PropertyValue = this.DJSchemeIds.join(',');
      }
    });
    const volumeProperty = _.find(this.terminal.TerminalPropertiesModel,
      (prop) => prop.PropertyKey === this.terminalPropertyNames.VOLUME.Key);
    if (!volumeProperty) {
      const selectedTerminalPropertiesForDJ = {
        Id: 0,
        PropertyKey: this.terminalPropertyNames.VOLUME.Key,
        PropertyValue: this.volume.toString(),
        TerminalId: 0
      };
      this.terminal.TerminalPropertiesModel.push(selectedTerminalPropertiesForDJ);
    }
    const djSchemeProperty = _.find(this.terminal.TerminalPropertiesModel,
      (prop) => prop.PropertyKey === this.terminalPropertyNames.DJ_SCHEME.Key);
    if (!djSchemeProperty) {
      const selectedTerminalPropertiesForDJ = {
        Id: 0,
        PropertyKey: this.terminalPropertyNames.DJ_SCHEME.Key,
        PropertyValue: this.DJSchemeIds.join(','),
        TerminalId: 0
      };
      this.terminal.TerminalPropertiesModel.push(selectedTerminalPropertiesForDJ);
    }
  }

  getTerminalPropertiesForOrderEntry() {
    this.spinnerService.show();
    this.terminalService.getTerminalProperties(this.terminal.Id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          this.terminal.TerminalPropertiesModel = response ? response : [];
          _.forEach(this.terminal.TerminalPropertiesModel, (property) => {
            if (property.PropertyKey === this.terminalPropertyNames.HOME_SCREEN_ID.Key) {
              this.selectedHomeScreen = parseInt(property.PropertyValue, 10);
            } else if (property.PropertyKey === this.terminalPropertyNames.TABLE_LAYOUT.Key) {
              this.tableLayoutIds = property.PropertyValue.split(',').map(x => parseInt(x, 10));
            } else if (property.PropertyKey === this.terminalPropertyNames.ORDER_PROMPT.Key) {
              const selectedOrderPrompts = property.PropertyValue.split(',');
              _.forEach(selectedOrderPrompts, (item) => {
                const promptOrder = _.find(this.promptServeMethods, x => x.id === item);
                if (promptOrder) {
                  promptOrder.isSelected = true;
                  this.isOrderPrompt = true;
                }
              });
            } else if (property.PropertyKey === this.terminalPropertyNames.TAG_AUDIO_TYPE.Key) {
              this.tagAudioRequestType = property.PropertyValue.toString()
            } else if (property.PropertyKey === this.terminalPropertyNames.AUDIO_INTERACTION_MODE.Key) {
              this.audioInteractionModes = property.PropertyValue ? property.PropertyValue.split(',') : [];
            }
          });
        }
      });
  }

  setOrderEntryTerminalProperties() {
    _.forEach(this.terminal.TerminalPropertiesModel, (property) => {
      if (property.PropertyKey === this.terminalPropertyNames.HOME_SCREEN_ID.Key) {
        property.PropertyValue = this.selectedHomeScreen.toString();
      } else if (property.PropertyKey === this.terminalPropertyNames.TABLE_LAYOUT.Key) {
        property.PropertyValue = this.tableLayoutIds.join(',');
      } else if (property.PropertyKey === this.terminalPropertyNames.ORDER_PROMPT.Key) {
        property.PropertyValue = this.promptServeMethods.filter(x => x.isSelected).map(x => parseInt(x.id, 10)).join(',');
      } else if (property.PropertyKey === this.terminalPropertyNames.TAG_AUDIO_TYPE.Key) {
        property.PropertyValue = this.tagAudioRequestType.toString();
      } else if (property.PropertyKey === this.terminalPropertyNames.AUDIO_INTERACTION_MODE.Key) {
        property.PropertyValue = this.audioInteractionModes.join(',');
      }
    });
    const tagAudioRecordProperty = _.find(this.terminal.TerminalPropertiesModel,
      (prop) => prop.PropertyKey === this.terminalPropertyNames.TAG_AUDIO_TYPE.Key);
    if (!tagAudioRecordProperty) {
      const terminalProperty = {
        Id: 0,
        PropertyKey: this.terminalPropertyNames.TAG_AUDIO_TYPE.Key,
        PropertyValue: this.tagAudioRequestType.toString(),
        TerminalId: 0
      };
      this.terminal.TerminalPropertiesModel.push(terminalProperty);
    }
    const audioInteractionModesProperty = _.find(this.terminal.TerminalPropertiesModel, (prop) => prop.PropertyKey === this.terminalPropertyNames.AUDIO_INTERACTION_MODE.Key);
    if (!audioInteractionModesProperty) {
      const terminalProperty = {
        Id: 0,
        PropertyKey: this.terminalPropertyNames.AUDIO_INTERACTION_MODE.Key,
        PropertyValue: this.audioInteractionModes.join(','),
        TerminalId: 0
      };
      this.terminal.TerminalPropertiesModel.push(terminalProperty);
    }

    const homeScreenProperty = _.find(this.terminal.TerminalPropertiesModel,
      (prop) => prop.PropertyKey === this.terminalPropertyNames.HOME_SCREEN_ID.Key);
    if (!homeScreenProperty) {
      const terminalProperty = {
        Id: 0,
        PropertyKey: this.terminalPropertyNames.HOME_SCREEN_ID.Key,
        PropertyValue: this.selectedHomeScreen.toString(),
        TerminalId: 0
      };
      this.terminal.TerminalPropertiesModel.push(terminalProperty);
    }
    const tableLayoutsProperty = _.find(this.terminal.TerminalPropertiesModel,
      (prop) => prop.PropertyKey === this.terminalPropertyNames.TABLE_LAYOUT.Key);
    if (!tableLayoutsProperty) {
      const terminalProperty = {
        Id: 0,
        PropertyKey: this.terminalPropertyNames.TABLE_LAYOUT.Key,
        PropertyValue: this.tableLayoutIds.join(','),
        TerminalId: 0
      };
      this.terminal.TerminalPropertiesModel.push(terminalProperty);
    }
    const orderPromptsProperty = _.find(this.terminal.TerminalPropertiesModel,
      (prop) => prop.PropertyKey === this.terminalPropertyNames.ORDER_PROMPT.Key);
    if (!orderPromptsProperty) {
      const terminalProperty = {
        Id: 0,
        PropertyKey: this.terminalPropertyNames.ORDER_PROMPT.Key,
        PropertyValue: this.promptServeMethods.filter(x => x.isSelected).map(x => parseInt(x.id, 10)).join(','),
        TerminalId: 0
      };
      this.terminal.TerminalPropertiesModel.push(terminalProperty);
    }
  }

  getCurrentOrderPrompts() {
    this.spinnerService.show();
    this.orderWorkflowService.getOrderWorkflowParameters()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          const availableOrderPrompts = response.OrderPrompts.split(',');
          if (availableOrderPrompts.length > 0) {
            this.promptServeMethods = [];
            _.forEach(availableOrderPrompts, (item) => {
              if (item !== '' && item !== undefined) {
                const currentPrompt = _.find(cloneDeep(DomainConstants.PromptServeMethods), (promptType) => promptType.id === item);
                this.promptServeMethods.push(currentPrompt);
              }
            });
          }
          if (this.id > 0) {
            this.getTerminalPropertiesForOrderEntry();
          }
        }, error: this.alertService.showApiError
      });
  }

  promptOrders() {
    const orderPromptObj = [];
    if (this.isOrderPrompt) {
      _.forEach(this.promptServeMethods, (value, key) => {
        value.isSelected = orderPromptObj[key];
      });
    } else {
      _.forEach(this.promptServeMethods, (orderPrompt) => {
        orderPromptObj.push(orderPrompt.isSelected);
        orderPrompt.isSelected = false;
      });
    }
    this.checkOrderPromptsValidation();
  }

  checkOrderPromptsValidation() {
    this.isValidOrderPrompts = false;
    const selectedPrompts = _.filter(this.promptServeMethods, (item) => {
      return item.isSelected;
    });
    if (selectedPrompts.length > 0) {
      this.isValidOrderPrompts = true;
    }
  }

  bindOrderEntryDropdown() {
    this.orderEntryTerminals = _.filter(this.terminals,
      (terminal) => terminal.TerminalType === this.terminalTypeNames.ORDER_ENTRY.Name || terminal.TerminalType === this.terminalTypeNames.ORDER_KIOSK.Name);
  }

  getTerminalPropertiesForProductBuildQueue() {
    this.spinnerService.show();
    this.terminalService.getTerminalProperties(this.terminal.Id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          this.terminal.TerminalPropertiesModel = response ? response : [];
          _.forEach(this.terminal.TerminalPropertiesModel, (property) => {
            if (property.PropertyKey === this.terminalPropertyNames.PRODUCT_BUILD_QUEUE_GRID.Key) {
              const gridInfo: ProductBuildQueueGridInfo = JSON.parse(property.PropertyValue);
              this.gridColumns = gridInfo.Columns;
              this.gridRows = gridInfo.Rows;
            }
          });
        }
      });
  }

  getTerminalProperties() {
    this.spinnerService.show();
    this.terminalService.getTerminalProperties(this.terminal.Id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          this.terminal.TerminalPropertiesModel = response ? response : [];
          _.forEach(this.terminal.TerminalPropertiesModel, (property) => {
            if (property.PropertyKey === this.terminalPropertyNames.IP_ADDRESS.Key) {
              this.ipAddress = property.PropertyValue;
            }
            else if (property.PropertyKey === this.terminalPropertyNames.IP_ADDRESS_LEVEL.Key) {
              this.ipAddressLevel = property.PropertyValue === 'true' ? true : false;
            }
            else if (property.PropertyKey === this.terminalPropertyNames.MONITOR_FOR_CONNECTION.Key) {
              this.monitorForConnection = property.PropertyValue == 'true' ? true : false;
            }
          });
        }
      });
  }

  getMenuDisplaySettings() {
    this.spinnerService.show();
    this.terminalService.getMenuDisplaySettings(this.terminal.Id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          this.menuDisplaySettings = response.length > 0 ? response : [];
          this.menuDisplaySettings.forEach(x => x.DaysIds = x.Days.split(','));
          this.isLoadMenuDisplayOverride = true;
        }, error: this.alertService.showApiError
      });
  }

  getTerminalPropertiesForMenuDisplay() {
    this.spinnerService.show();
    this.terminalService.getTerminalProperties(this.terminal.Id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          this.terminal.TerminalPropertiesModel = response ? response : [];
          _.forEach(this.terminal.TerminalPropertiesModel, (property) => {
            if (property.PropertyKey === this.terminalPropertyNames.MENU_DISPLAY_ID.Key) {
              this.displayMenuId = parseInt(property.PropertyValue, 10);
            }
          });
        }
      });
  }

  setTerminalProperty = (propertyName, propertyValue) => {
    const terminalProperty = _.find(this.terminal.TerminalPropertiesModel,
      (prop) => {
        if (prop.PropertyKey === propertyName) {
          prop.PropertyValue = propertyValue;
          return prop;
        }
      });
    if (!terminalProperty) {
      const newTerminalIpProperty = {
        Id: 0,
        PropertyKey: propertyName,
        PropertyValue: propertyValue,
        TerminalId: 0
      };
      this.terminal.TerminalPropertiesModel.push(newTerminalIpProperty);
    }
  }

  setTerminalPropertiesForIpAddress() {
    this.terminal.TerminalPropertiesModel = this.terminal.TerminalPropertiesModel ? this.terminal.TerminalPropertiesModel : [];
    this.setTerminalProperty(this.terminalPropertyNames.IP_ADDRESS.Key, (this.ipAddress ? this.ipAddress.toString() : null));
    this.setTerminalProperty(this.terminalPropertyNames.IP_ADDRESS_LEVEL.Key, (this.ipAddressLevel ? this.ipAddressLevel.toString() : 'false'));
  }

  setTerminalPropertiesForMenuDisplay() {
    _.forEach(this.terminal.TerminalPropertiesModel, (property) => {
      if (property.PropertyKey === this.terminalPropertyNames.MENU_DISPLAY_ID.Key) {
        property.PropertyValue = this.displayMenuId ? this.displayMenuId.toString() : null;
      }
    });
    this.terminal.TerminalPropertiesModel = this.terminal.TerminalPropertiesModel ? this.terminal.TerminalPropertiesModel : [];
    const displayMenuProperty = _.find(this.terminal.TerminalPropertiesModel,
      (prop) => prop.PropertyKey === this.terminalPropertyNames.MENU_DISPLAY_ID.Key);
    if (!displayMenuProperty) {
      const terminalProperty = {
        Id: 0,
        PropertyKey: this.terminalPropertyNames.MENU_DISPLAY_ID.Key,
        PropertyValue: this.displayMenuId ? this.displayMenuId.toString() : null,
        TerminalId: 0
      };
      this.terminal.TerminalPropertiesModel.push(terminalProperty);
    }
    remove(this.menuDisplaySettings, x => !x.MenuDisplayId);
    this.menuDisplaySettings.forEach(x => x.Days = x.DaysIds.join(','));
    this.terminal.MenuDisplaySettings = this.menuDisplaySettings;
  }

  getTerminalPropertiesForOrderConfirmation() {
    this.spinnerService.show();
    this.terminalService.getTerminalProperties(this.terminal.Id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          this.terminal.TerminalPropertiesModel = response ? response : [];
          _.forEach(this.terminal.TerminalPropertiesModel, (property) => {
            if (property.PropertyKey === this.terminalPropertyNames.ORDER_CONFIRMATION_SCROLL_SPEED.Key) {
              this.scrollSpeed = parseInt(property.PropertyValue, 10);
            } else if (property.PropertyKey === this.terminalPropertyNames.ORDER_CONFIRMATION_IDLE_TIMEOUT.Key) {
              this.idleTimeout = parseInt(property.PropertyValue, 10);
            }
          });
        }
      });
  }

  setProductBuildQueueTerminalProperties() {
    const gridConfig = {
      Columns: this.gridColumns,
      Rows: this.gridRows
    };
    _.forEach(this.terminal.TerminalPropertiesModel, (property) => {
      if (property.PropertyKey === this.terminalPropertyNames.PRODUCT_BUILD_QUEUE_GRID.Key) {
        property.PropertyValue = gridConfig ? JSON.stringify(gridConfig) : null;
      }
    });
    this.terminal.TerminalPropertiesModel = this.terminal.TerminalPropertiesModel ? this.terminal.TerminalPropertiesModel : [];
    const gridProperty = _.find(this.terminal.TerminalPropertiesModel,
      (prop) => prop.PropertyKey === this.terminalPropertyNames.PRODUCT_BUILD_QUEUE_GRID.Key);
    if (!gridProperty) {
      const terminalProperty = {
        Id: 0,
        PropertyKey: this.terminalPropertyNames.PRODUCT_BUILD_QUEUE_GRID.Key,
        PropertyValue: gridConfig ? JSON.stringify(gridConfig) : null,
        TerminalId: 0
      };
      this.terminal.TerminalPropertiesModel.push(terminalProperty);
    }
  }

  setOrderConfirmationTerminalProperties() {
    _.forEach(this.terminal.TerminalPropertiesModel, (property) => {
      if (property.PropertyKey === this.terminalPropertyNames.ORDER_CONFIRMATION_SCROLL_SPEED.Key) {
        property.PropertyValue = this.scrollSpeed.toString();
      } else if (property.PropertyKey === this.terminalPropertyNames.ORDER_CONFIRMATION_IDLE_TIMEOUT.Key) {
        property.PropertyValue = this.idleTimeout.toString();
      }
    });
    const scrollSpeedProperty = _.find(this.terminal.TerminalPropertiesModel,
      (prop) => prop.PropertyKey === this.terminalPropertyNames.ORDER_CONFIRMATION_SCROLL_SPEED.Key);
    if (!scrollSpeedProperty) {
      const terminalProperty = {
        Id: 0,
        PropertyKey: this.terminalPropertyNames.ORDER_CONFIRMATION_SCROLL_SPEED.Key,
        PropertyValue: this.scrollSpeed.toString(),
        TerminalId: 0
      };
      this.terminal.TerminalPropertiesModel.push(terminalProperty);
    }
    const idleTimeoutProperty = _.find(this.terminal.TerminalPropertiesModel,
      (prop) => prop.PropertyKey === this.terminalPropertyNames.ORDER_CONFIRMATION_IDLE_TIMEOUT.Key);
    if (!idleTimeoutProperty) {
      const terminalProperty = {
        Id: 0,
        PropertyKey: this.terminalPropertyNames.ORDER_CONFIRMATION_IDLE_TIMEOUT.Key,
        PropertyValue: this.idleTimeout.toString(),
        TerminalId: 0
      };
      this.terminal.TerminalPropertiesModel.push(terminalProperty);
    }
  }

  getTerminalPropertiesForOrderStatus() {
    this.spinnerService.show();
    this.terminalService.getTerminalProperties(this.terminal.Id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          this.terminal.TerminalPropertiesModel = response ? response : [];
          _.forEach(this.terminal.TerminalPropertiesModel, (property) => {
            if (property.PropertyKey === this.terminalPropertyNames.FONT_SIZE.Key) {
              this.fontSize = parseInt(property.PropertyValue, 10);
            }
          });
        }
      });
  }

  setOrderStatusTerminalProperties() {
    _.forEach(this.terminal.TerminalPropertiesModel, (property) => {
      if (property.PropertyKey === this.terminalPropertyNames.FONT_SIZE.Key) {
        property.PropertyValue = this.fontSize ? this.fontSize.toString() : null;
      }
    });
    this.terminal.TerminalPropertiesModel = this.terminal.TerminalPropertiesModel ? this.terminal.TerminalPropertiesModel : [];
    const fontSizeProperty = _.find(this.terminal.TerminalPropertiesModel,
      (prop) => prop.PropertyKey === this.terminalPropertyNames.FONT_SIZE.Key);
    if (!fontSizeProperty) {
      const terminalProperty = {
        Id: 0,
        PropertyKey: this.terminalPropertyNames.FONT_SIZE.Key,
        PropertyValue: this.fontSize ? this.fontSize.toString() : null,
        TerminalId: 0
      };
      this.terminal.TerminalPropertiesModel.push(terminalProperty);
    }
  }

  linkMakeTableToAllOrderEntry() {
    const modalRef = this.modalService.getModalWrapper(ConfirmDeleteModalComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      initialState: {
        message: StringUtils.format(Messages.ConfirmLinkMakeTableToAllOrderEntryTerminal, { 'terminalName': this.terminal.TerminalName })
      }
    });
    modal.close.subscribe(res => {
      if (res && res.shouldDelete) {
        if (this.terminal.Id) {
          this.linkMakeTable();
        } else {
          const orderEntryTerminalId = [];
          this.orderEntryTerminals.forEach((data) => {
            orderEntryTerminalId.push(data.TerminalId);
          });
          this.orderEntryTerminalIds = orderEntryTerminalId;
        }
      }
    });
  }

  linkMakeTable() {
    this.isLinkNewMakeTable = false;
    if (!this.terminal.Id) {
      this.isLinkNewMakeTable = true;
    } else {
      this.spinnerService.show();
      this.terminalService.linkMakeTableToAllOrderEntry(this.terminal.Id)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (response) => {
            this.getMappedTerminals(this.terminalTypeNames.ORDER_ENTRY.Name + ',' + this.terminalTypeNames.ORDER_KIOSK.Name);
            this.alertService.renderSuccessMessage(StringUtils.format(Messages.TerminalMappedWithAllOrderEntrySuccess,
              { 'terminalName': this.terminal.TerminalName }));
          }, error: this.alertService.showApiError
        });
    }
  }

  getTerminalPropertiesForMakeTable() {
    this.spinnerService.show();
    this.terminalService.getTerminalProperties(this.terminal.Id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          this.terminal.TerminalPropertiesModel = response ? response : [];
          _.forEach(this.terminal.TerminalPropertiesModel, (property) => {
            if (property.PropertyKey === this.terminalPropertyNames.LAYOUT.Key) {
              this.selectedLayout = property.PropertyValue;
            } else if (property.PropertyKey === this.terminalPropertyNames.READ_ONLY.Key) {
              this.isReadOnly = property.PropertyValue === 'true' ? true : false;
            }
          });
          this.checkFilteredProduct(this.terminal.TerminalPropertiesModel)
        }
      });
  }
  checkFilteredProduct(properties: Array<TerminalProperty>) {
    let filteredProductProperty = find(properties, (property) => {
      return property.PropertyKey === DomainConstants.TerminalProperties.FILTERED_PRODUCTS.Key;
    })?.PropertyValue;
    if (filteredProductProperty) {
      const filteredProductData = JSON.parse(filteredProductProperty);
      const salesCategoriesFilter = filteredProductData.SalesCategories;
      const salesGroupsFilter = filteredProductData.SalesGroups;
      if (salesGroupsFilter?.length || salesCategoriesFilter?.length) {
        this.filteredProducts = filteredProductProperty ? JSON.parse(filteredProductProperty) : { SalesCategories: [], SalesGroups: [] };
        this.isFilteredProducts = true;
        return;
      }
    }
    this.isFilteredProducts = false;
  }

  setMakeTableTerminalProperties() {
    _.forEach(this.terminal.TerminalPropertiesModel, (property) => {
      if (property.PropertyKey === this.terminalPropertyNames.LAYOUT.Key) {
        property.PropertyValue = this.selectedLayout;
      } else if (property.PropertyKey === this.terminalPropertyNames.READ_ONLY.Key) {
        property.PropertyValue = this.isReadOnly ? 'true' : 'false';
      } else if (property.PropertyKey === this.terminalPropertyNames.FILTERED_PRODUCTS.Key) {
        property.PropertyValue = this.isFilteredProducts ? JSON.stringify(this.filteredProducts) : null;
      }
    });
    this.terminal.TerminalPropertiesModel = this.terminal.TerminalPropertiesModel ? this.terminal.TerminalPropertiesModel : [];
    const selectedLayoutProperty = _.find(this.terminal.TerminalPropertiesModel,
      (prop) => prop.PropertyKey === this.terminalPropertyNames.LAYOUT.Key);
    if (!selectedLayoutProperty) {
      const terminalProperty = {
        Id: 0,
        PropertyKey: this.terminalPropertyNames.LAYOUT.Key,
        PropertyValue: this.selectedLayout,
        TerminalId: 0
      };
      this.terminal.TerminalPropertiesModel.push(terminalProperty);
    }
    const readOnlyProperty = _.find(this.terminal.TerminalPropertiesModel,
      (prop) => prop.PropertyKey === this.terminalPropertyNames.READ_ONLY.Key);
    if (!readOnlyProperty) {
      const terminalProperty = {
        Id: 0,
        PropertyKey: this.terminalPropertyNames.READ_ONLY.Key,
        PropertyValue: this.isReadOnly ? 'true' : 'false',
        TerminalId: 0
      };
      this.terminal.TerminalPropertiesModel.push(terminalProperty);
    }
    const filteredProductsProperty = _.find(this.terminal.TerminalPropertiesModel,
      (prop) => prop.PropertyKey === this.terminalPropertyNames.FILTERED_PRODUCTS.Key);
    if (!filteredProductsProperty) {
      const terminalProperty = {
        Id: 0,
        PropertyKey: this.terminalPropertyNames.FILTERED_PRODUCTS.Key,
        PropertyValue: this.isFilteredProducts ? JSON.stringify(this.filteredProducts) : null,
        TerminalId: 0
      };
      this.terminal.TerminalPropertiesModel.push(terminalProperty);
    }
    this.terminal.OrderEntryTerminalId = null;
    this.terminal.OrderEntryTerminalIds = this.orderEntryTerminalIds.join(',');
  }

  getTerminalPropertiesForTimer() {
    this.spinnerService.show();
    this.terminalService.getTerminalProperties(this.terminal.Id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          this.terminal.TerminalPropertiesModel = response ? response : [];
          _.forEach(this.terminal.TerminalPropertiesModel, (property) => {
            if (property.PropertyKey === this.terminalPropertyNames.TIMER_OPTIONS.Key) {
              this.timerOption = property.PropertyValue;
            } else if (property.PropertyKey === this.terminalPropertyNames.TIMER_NUMBER_OF_SLOT.Key) {
              this.noOfSlot = parseInt(property.PropertyValue, 10);
            } else if (property.PropertyKey === this.terminalPropertyNames.MANUAL_ADJUSTMENT.Key) {
              this.manualAdjustment = (property.PropertyValue === 'true');
            } else if (property.PropertyKey === this.terminalPropertyNames.TIMER_PREPGROUP.Key) {
              this.prepGroups = property.PropertyValue.split(',');
              const selectedAll = _.find(this.prepGroups, (prepGroup) => prepGroup === 'All');
              if (selectedAll) {
                this.prepGroups = this.prepGroups.filter(x => x === 'All');
              }
            }
          });
          this.configureTimeSlots();
        }
      });
  }


  configureTimeSlots() {
    const timerSoundsProperty = this.terminal.TerminalPropertiesModel.find(prop => prop.PropertyKey === this.terminalPropertyNames.TIMER_SOUND.Key);
    const timerSounds = timerSoundsProperty?.PropertyValue ? JSON.parse(timerSoundsProperty.PropertyValue) : [];

    const timerSecondsProperty = this.terminal.TerminalPropertiesModel.find(prop => prop.PropertyKey === this.terminalPropertyNames.TIMER_SLOT_TIMES.Key);
    const timerSeconds = timerSecondsProperty?.PropertyValue ? JSON.parse(timerSecondsProperty?.PropertyValue) : [];

    for (let index = 0; index < Math.max(timerSounds.length, timerSeconds.length); index++) {
      const seconds = timerSeconds[index] ? timerSeconds[index] : null;
      const sound = timerSounds[index] ? timerSounds[index] : null;
      this.slotTimes.push({ seconds: seconds, minutes: this.calculateMinutes(seconds), sound: sound });
    }

    if (!this.slotTimes.length) {
      this.createSlotTimesArray();
    }
  }

  setTimerTerminalProperties() {
    _.forEach(this.terminal.TerminalPropertiesModel, (property) => {
      if (property.PropertyKey === this.terminalPropertyNames.TIMER_OPTIONS.Key) {
        property.PropertyValue = this.timerOption;
      } else if (property.PropertyKey === this.terminalPropertyNames.TIMER_NUMBER_OF_SLOT.Key) {
        property.PropertyValue = this.noOfSlot.toString();
      } else if (property.PropertyKey === this.terminalPropertyNames.TIMER_PREPGROUP.Key) {
        property.PropertyValue = this.prepGroups.join(',');
      } else if (property.PropertyKey === this.terminalPropertyNames.TIMER_SLOT_TIMES.Key) {
        property.PropertyValue = this.slotTimes ? JSON.stringify(this.slotTimes.map(x => x.seconds)) : null;
      } else if (property.PropertyKey === this.terminalPropertyNames.MANUAL_ADJUSTMENT.Key) {
        property.PropertyValue = this.manualAdjustment ? 'true' : 'false';
      } else if (property.PropertyKey === this.terminalPropertyNames.TIMER_SOUND.Key) {
        property.PropertyValue = this.slotTimes ? JSON.stringify(this.slotTimes.map(x => x.sound)) : null;
      }
    });
    this.AddMissingTimerTerminalProperties();
  }

  AddMissingTimerTerminalProperties() {
    this.terminal.TerminalPropertiesModel = this.terminal.TerminalPropertiesModel ? this.terminal.TerminalPropertiesModel : [];
    const timerOptionsProperty = _.find(this.terminal.TerminalPropertiesModel,
      (prop) => prop.PropertyKey === this.terminalPropertyNames.TIMER_OPTIONS.Key);
    if (!timerOptionsProperty) {
      const terminalProperty = {
        Id: 0,
        PropertyKey: this.terminalPropertyNames.TIMER_OPTIONS.Key,
        PropertyValue: this.timerOption,
        TerminalId: 0
      };
      this.terminal.TerminalPropertiesModel.push(terminalProperty);
    }
    const noOfSlotProperty = _.find(this.terminal.TerminalPropertiesModel,
      (prop) => prop.PropertyKey === this.terminalPropertyNames.TIMER_NUMBER_OF_SLOT.Key);
    if (!noOfSlotProperty) {
      const terminalProperty = {
        Id: 0,
        PropertyKey: this.terminalPropertyNames.TIMER_NUMBER_OF_SLOT.Key,
        PropertyValue: this.noOfSlot.toString(),
        TerminalId: 0
      };
      this.terminal.TerminalPropertiesModel.push(terminalProperty);
    }
    const prepGroupProperty = _.find(this.terminal.TerminalPropertiesModel,
      (prop) => prop.PropertyKey === this.terminalPropertyNames.TIMER_PREPGROUP.Key);
    if (!prepGroupProperty) {
      const terminalProperty = {
        Id: 0,
        PropertyKey: this.terminalPropertyNames.TIMER_PREPGROUP.Key,
        PropertyValue: this.prepGroups.join(','),
        TerminalId: 0
      };
      this.terminal.TerminalPropertiesModel.push(terminalProperty);
    }
    const slotTimesProperty = _.find(this.terminal.TerminalPropertiesModel,
      (prop) => prop.PropertyKey === this.terminalPropertyNames.TIMER_SLOT_TIMES.Key);
    if (!slotTimesProperty) {
      const terminalProperty = {
        Id: 0,
        PropertyKey: this.terminalPropertyNames.TIMER_SLOT_TIMES.Key,
        PropertyValue: this.slotTimes ? JSON.stringify(this.slotTimes.map(x => x.seconds)) : null,
        TerminalId: 0
      };
      this.terminal.TerminalPropertiesModel.push(terminalProperty);
    }
    const manualAdjustmentProperty = _.find(this.terminal.TerminalPropertiesModel,
      (prop) => prop.PropertyKey === this.terminalPropertyNames.MANUAL_ADJUSTMENT.Key);
    if (!manualAdjustmentProperty) {
      const terminalProperty = {
        Id: 0,
        PropertyKey: this.terminalPropertyNames.MANUAL_ADJUSTMENT.Key,
        PropertyValue: this.manualAdjustment ? 'true' : 'false',
        TerminalId: 0
      };
      this.terminal.TerminalPropertiesModel.push(terminalProperty);
    }
    const timerSoundProperty = _.find(this.terminal.TerminalPropertiesModel, p => p.PropertyKey === this.terminalPropertyNames.TIMER_SOUND.Key);
    if (!timerSoundProperty) {
      const terminalProperty = {
        Id: 0,
        PropertyKey: this.terminalPropertyNames.TIMER_SOUND.Key,
        PropertyValue: this.slotTimes ? JSON.stringify(this.slotTimes.map(x => x.sound)) : null,
        TerminalId: 0
      };
      this.terminal.TerminalPropertiesModel.push(terminalProperty);
    }
  }

  selectAllPrepGroup(item) {
    if (item.PrepGroupName === 'All') {
      this.prepGroupsList = _.forEach(this.prepGroupsList, (prepGroup: any) => {
        prepGroup.disabled = item.selected ? true : false;
      });
    }
  }

  changePrepGroups() {
    if (this.prepGroups.length > 0) {
      const lastSelectedPrepGroup = this.prepGroups[this.prepGroups.length - 1];
      if (lastSelectedPrepGroup === 'All') {
        this.prepGroups = this.prepGroups.filter(x => x === 'All');
      } else {
        this.prepGroups = this.prepGroups.filter(x => x !== 'All');
      }
    }
  }

  getTerminalPropertiesForDashboard() {
    this.spinnerService.show();
    this.dashboardTerminalPropertiesService.getTerminalDashboardProperties(this.terminal.Id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: any) => {
          this.terminalDashboardProperties = response;
        }
      });
  }

  addTerminalDashboardProperty() {
    if (this.managementDashboard && this.dashboardSec && this.dashboardSec > 0) {
      const availableProperty = _.filter(this.terminalDashboardProperties, (prop) => {
        return prop.DashboardId === this.managementDashboard && (!this.editedDashboard || prop.Id !== this.editedDashboard.Id);
      });
      if (availableProperty && availableProperty.length > 0) {
        this.alertService.renderErrorMessage(Messages.DuplicateDashboardError);
      } else {
        if (this.editedDashboard) {
          this.editedDashboard.DashboardId = this.managementDashboard;
          this.editedDashboard.Seconds = this.dashboardSec;
          this.spinnerService.show();
          this.terminalService.updateTerminalDashboardProperty(this.editedDashboard)
            .pipe(finalize(() => {
              this.spinnerService.hide();
            }))
            .subscribe({
              next: (response) => {
                this.cancelTerminalDashboardProperty();
                this.getTerminalPropertiesForDashboard();
              }
            });
        } else {
          const editedDashboard: TerminalDashboardProperty = {
            Id: 0,
            DashboardId: this.managementDashboard,
            Seconds: this.dashboardSec,
            TerminalId: this.terminal.Id
          };
          this.spinnerService.show();
          this.dashboardTerminalPropertiesService.saveTerminalDashboardProperty(editedDashboard)
            .pipe(finalize(() => {
              this.spinnerService.hide();
            }))
            .subscribe({
              next: (response) => {
                this.cancelTerminalDashboardProperty();
                this.getTerminalPropertiesForDashboard();
              }
            });
        }
      }
    }
  }

  cancelTerminalDashboardProperty() {
    this.editedDashboard = null;
    this.managementDashboard = null;
    this.dashboardSec = 1;
  }

  onDeleteTerminalDashboardProperties() {
    this.getTerminalPropertiesForDashboard();
  }

  onEditTerminalDashboardProperty(event) {
    this.editedDashboard = event && event.property ? event.property : {};
    this.managementDashboard = this.editedDashboard && this.editedDashboard.DashboardId ? this.editedDashboard.DashboardId : 0;
    this.dashboardSec = event && this.editedDashboard.Seconds ? this.editedDashboard.Seconds : 1;
  }

  createSlotTimesArray(clearSeconds = false) {
    this.slotTimes = this.slotTimes ?? [];
    let slotArrayLength = this.noOfSlot - this.slotTimes.length;
    if (slotArrayLength > 0) {
      while (slotArrayLength) {
        const newSlot = {
          seconds: null, minutes: null, sound: null
        };
        this.slotTimes.push(newSlot);
        slotArrayLength--;
      }
    } else {
      const length = Math.abs(slotArrayLength);
      this.slotTimes.splice(this.slotTimes.length - length, length);
    }
    if (clearSeconds) {
      forEach(this.slotTimes, slot => {
        slot.seconds = null;
        slot.minutes = null;
      });
    }
  }

  onFormChange($event: NgForm) {
    this.terminalForm = $event;
    this.setInitialValue();
  }

  calculateMinutes(slotSeconds: number) {
    if (slotSeconds) {
      const date = new Date(1970, 0, 1);
      date.setHours(0, 0, slotSeconds, 0);
      return date;
    }
  }

  productsFiltered(event) {
    this.filteredProducts = event.products;
  }

  getTerminalPropertiesForMenuExplorer() {
    this.spinnerService.show();
    this.terminalService.getTerminalProperties(this.terminal.Id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          this.terminal.TerminalPropertiesModel = response ?? [];
          _.forEach(this.terminal.TerminalPropertiesModel, (property) => {
            if (property.PropertyKey === this.terminalPropertyNames.HOME_SCREEN_ID.Key) {
              this.selectedHomeScreen = parseInt(property.PropertyValue, 10);
            } else if (property.PropertyKey === this.terminalPropertyNames.IDLE_TIMEOUT.Key) {
              this.menuExplorerIdleTimeout = property.PropertyValue ? parseInt(property.PropertyValue, 10) : null;
            }
          });
        }
      });
  }

  setTerminalPropertiesForMenuExplorer() {
    _.forEach(this.terminal.TerminalPropertiesModel, (property) => {
      if (property.PropertyKey === this.terminalPropertyNames.HOME_SCREEN_ID.Key) {
        property.PropertyValue = this.selectedHomeScreen.toString();
      } else if (property.PropertyKey === this.terminalPropertyNames.IDLE_TIMEOUT.Key) {
        property.PropertyValue = this.menuExplorerIdleTimeout ? this.menuExplorerIdleTimeout.toString() : null;
      }
    });
    const homeScreenProperty = _.find(this.terminal.TerminalPropertiesModel,
      (prop) => prop.PropertyKey === this.terminalPropertyNames.HOME_SCREEN_ID.Key);
    if (!homeScreenProperty) {
      const terminalProperty = {
        Id: 0,
        PropertyKey: this.terminalPropertyNames.HOME_SCREEN_ID.Key,
        PropertyValue: this.selectedHomeScreen.toString(),
        TerminalId: 0
      };
      this.terminal.TerminalPropertiesModel.push(terminalProperty);
    }
    const timeoutSecondsProperty = _.find(this.terminal.TerminalPropertiesModel,
      (prop) => prop.PropertyKey === this.terminalPropertyNames.IDLE_TIMEOUT.Key);
    if (!timeoutSecondsProperty) {
      const terminalProperty = {
        Id: 0,
        PropertyKey: this.terminalPropertyNames.IDLE_TIMEOUT.Key,
        PropertyValue: this.menuExplorerIdleTimeout ? this.menuExplorerIdleTimeout.toString() : null,
        TerminalId: 0
      };
      this.terminal.TerminalPropertiesModel.push(terminalProperty);
    }
  }

  getTerminalPropertiesForOrderKiosk() {
    this.spinnerService.show();
    this.terminalService.getTerminalProperties(this.terminal.Id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          this.terminal.TerminalPropertiesModel = response ?? [];
          _.forEach(this.terminal.TerminalPropertiesModel, (property) => {
            if (property.PropertyKey === this.terminalPropertyNames.HOME_SCREEN_ID.Key) {
              this.selectedHomeScreen = parseInt(property.PropertyValue, 10);
            } else if(property.PropertyKey === this.terminalPropertyNames.CONTINUE_ORDER_SCREEN_ID.Key) {
              this.selectedContinueOrderScreen = parseInt(property.PropertyValue, 10);
            } else if (property.PropertyKey === this.terminalPropertyNames.SCREEN_WIDTH.Key) {
              this.kioskWidth = parseInt(property.PropertyValue, 10);
            } else if (property.PropertyKey === this.terminalPropertyNames.SCREEN_HEIGHT.Key) {
              this.kioskHeight = parseInt(property.PropertyValue, 10);
            } else if (property.PropertyKey === this.terminalPropertyNames.FONT_FAMILY.Key) {
              this.fontFamily = property.PropertyValue;
            } else if (property.PropertyKey === this.terminalPropertyNames.USER_IDLE_TIMEOUT.Key) {
              this.userIdleTimeout = parseInt(property.PropertyValue, 10);
            } else if (property.PropertyKey === this.terminalPropertyNames.IS_ENABLED.Key) {
              this.isEnabled = property.PropertyValue == 'true' || property.PropertyValue == 'True' ? false : true;
            }
          });
        }
      });
  }

  setTerminalPropertiesForOrderKiosk() {
    _.forEach(this.terminal.TerminalPropertiesModel, (property) => {
      if (property.PropertyKey === this.terminalPropertyNames.HOME_SCREEN_ID.Key) {
        property.PropertyValue = this.selectedHomeScreen.toString();
      } else if(property.PropertyKey === this.terminalPropertyNames.CONTINUE_ORDER_SCREEN_ID.Key) {
        property.PropertyValue = this.selectedContinueOrderScreen.toString();
      } else if (property.PropertyKey === this.terminalPropertyNames.SCREEN_WIDTH.Key) {
        property.PropertyValue = this.kioskWidth?.toString();
      } else if (property.PropertyKey === this.terminalPropertyNames.SCREEN_HEIGHT.Key) {
        property.PropertyValue = this.kioskHeight?.toString();
      } else if (property.PropertyKey === this.terminalPropertyNames.FONT_FAMILY.Key) {
        property.PropertyValue = this.fontFamily;
      } else if (property.PropertyKey === this.terminalPropertyNames.USER_IDLE_TIMEOUT.Key) {
        property.PropertyValue = this.userIdleTimeout.toString();
      } else if (property.PropertyKey === this.terminalPropertyNames.IS_ENABLED.Key) {
        property.PropertyValue = (!this.isEnabled).toString();
      }
    });
    const homeScreenProperty = _.find(this.terminal.TerminalPropertiesModel,
      (prop) => prop.PropertyKey === this.terminalPropertyNames.HOME_SCREEN_ID.Key);
    if (!homeScreenProperty) {
      this.terminal.TerminalPropertiesModel.push(this.newTerminalProperty(this.terminalPropertyNames.HOME_SCREEN_ID.Key, this.selectedHomeScreen.toString()));
    }
    const continueOrderScreenProperty = _.find(this.terminal.TerminalPropertiesModel,
      (prop) => prop.PropertyKey === this.terminalPropertyNames.CONTINUE_ORDER_SCREEN_ID.Key);
    if (!continueOrderScreenProperty) {
      this.terminal.TerminalPropertiesModel.push(this.newTerminalProperty(this.terminalPropertyNames.CONTINUE_ORDER_SCREEN_ID.Key, this.selectedContinueOrderScreen.toString()));
    }
    const screenWidthProperty = _.find(this.terminal.TerminalPropertiesModel,
      (prop) => prop.PropertyKey === this.terminalPropertyNames.SCREEN_WIDTH.Key);
    if (!screenWidthProperty) {
      this.terminal.TerminalPropertiesModel.push(this.newTerminalProperty(this.terminalPropertyNames.SCREEN_WIDTH.Key, this.kioskWidth?.toString()));
    }
    const screenHeightProperty = _.find(this.terminal.TerminalPropertiesModel,
      (prop) => prop.PropertyKey === this.terminalPropertyNames.SCREEN_HEIGHT.Key);
    if (!screenHeightProperty) {
      this.terminal.TerminalPropertiesModel.push(this.newTerminalProperty(this.terminalPropertyNames.SCREEN_HEIGHT.Key, this.kioskHeight?.toString()));
    }
    const fontFamilyProperty = _.find(this.terminal.TerminalPropertiesModel,
      (prop) => prop.PropertyKey === this.terminalPropertyNames.FONT_FAMILY.Key);
    if (!fontFamilyProperty) {
      this.terminal.TerminalPropertiesModel.push(this.newTerminalProperty(this.terminalPropertyNames.FONT_FAMILY.Key, this.fontFamily));
    }
    const userIdleProperty = _.find(this.terminal.TerminalPropertiesModel, (prop) => prop.PropertyKey === this.terminalPropertyNames.USER_IDLE_TIMEOUT.Key);
    if (!userIdleProperty) {
      this.terminal.TerminalPropertiesModel.push(this.newTerminalProperty(this.terminalPropertyNames.USER_IDLE_TIMEOUT.Key, this.userIdleTimeout.toString()));
    }
    const isEnabledProperty = _.find(this.terminal.TerminalPropertiesModel, (prop) => prop.PropertyKey === this.terminalPropertyNames.IS_ENABLED.Key);
    if (!isEnabledProperty) {
      this.terminal.TerminalPropertiesModel.push(this.newTerminalProperty(this.terminalPropertyNames.IS_ENABLED.Key, (!this.isEnabled).toString()));
    }
  }

  getTerminalPropertiesForWaitList() {
    this.spinnerService.show();
    this.terminalService.getTerminalProperties(this.terminal.Id)
    .pipe(finalize(() => this.spinnerService.hide()))
    .subscribe({
      next: (response) => {
        this.terminal.TerminalPropertiesModel = response ?? [];
        _.forEach(this.terminal.TerminalPropertiesModel, (property) => {
          if (property.PropertyKey === this.terminalPropertyNames.MAX_ENTRIES.Key) {
            this.maxEntries = parseInt(property.PropertyValue, 10);
          } else if (property.PropertyKey === this.terminalPropertyNames.MAX_WAIT_TIME.Key) {
            this.maxWaitTime = parseInt(property.PropertyValue, 10);
          } else if (property.PropertyKey === this.terminalPropertyNames.NOTIFICATION_TEXT.Key) {
            this.notificationText = property.PropertyValue;
          }
        });
      }
    })
  }
  setTerminalPropertiesForWaitList() {
    _.forEach(this.terminal.TerminalPropertiesModel, (property) => {
      if (property.PropertyKey === this.terminalPropertyNames.MAX_ENTRIES.Key) {
        property.PropertyValue = this.maxEntries.toString();
      } else if(property.PropertyKey === this.terminalPropertyNames.MAX_WAIT_TIME.Key) {
        property.PropertyValue = this.maxWaitTime.toString();
      } else if (property.PropertyKey === this.terminalPropertyNames.NOTIFICATION_TEXT.Key) {
        property.PropertyValue = this.notificationText.toString();
      }
    });
    const maxEntriesProperty = _.find(this.terminal.TerminalPropertiesModel,
      (prop) => prop.PropertyKey === this.terminalPropertyNames.MAX_ENTRIES.Key);
    if (!maxEntriesProperty) {
      this.terminal.TerminalPropertiesModel.push(this.newTerminalProperty(this.terminalPropertyNames.MAX_ENTRIES.Key, this.maxEntries.toString()));
    }
    const maxWaitTimeProperty = _.find(this.terminal.TerminalPropertiesModel,
      (prop) => prop.PropertyKey === this.terminalPropertyNames.MAX_WAIT_TIME.Key);
    if (!maxWaitTimeProperty) {
      this.terminal.TerminalPropertiesModel.push(this.newTerminalProperty(this.terminalPropertyNames.MAX_WAIT_TIME.Key, this.maxWaitTime.toString()));
    }
    const notificationTextProperty = _.find(this.terminal.TerminalPropertiesModel,
      (prop) => prop.PropertyKey === this.terminalPropertyNames.NOTIFICATION_TEXT.Key);
    if (!notificationTextProperty) {
      this.terminal.TerminalPropertiesModel.push(this.newTerminalProperty(this.terminalPropertyNames.NOTIFICATION_TEXT.Key, this.notificationText.toString()));
    }
  }

  private newTerminalProperty(propertyName: string, propertyValue: string) {
    return {
      Id: 0,
      PropertyKey: propertyName,
      PropertyValue: propertyValue,
      TerminalId: 0
    };
  }

  playTimerAudio(slotTime, index: number) {
    forEach(this.slotTimes, (slot, i) => {
      slot.soundPlaying = (i == index && !slotTime.soundPlaying && slotTime.sound) ? true : false;
    });
    if (slotTime.soundPlaying) {
      this.audio.src = `${RuntimeConstants.MEDIA_BASE_PATH}/timer-audio/${slotTime.sound}`;
      this.audio.load();
      this.audio.play();

      this.audioPlayedCount = 1;
      this.audio.addEventListener("ended", this.audioEndedEventListener)
    } else {
      this.audio.pause();
      this.removeAudioEventListener();
    }
  }

  audioEndedEventListener = () => {
    if (this.audioPlayedCount++ < 5) {
      this.audio.play();
    } else {
      this.removeAudioEventListener();
    }
  }

  removeAudioEventListener() {
    this.audio.removeEventListener("ended", this.audioEndedEventListener);
    const playingSlot = find(this.slotTimes, st => st.soundPlaying);
    if (playingSlot) {
      playingSlot.soundPlaying = false;
    }
    this.audioPlayedCount = 0;
  }

  onSlotSoundChange(slotTime) {
    if (slotTime.soundPlaying) {
      this.audio.pause();
      this.removeAudioEventListener();
      this.audio = new Audio();
    }
  }

  ngOnDestroy(): void {
    this.removeAudioEventListener();
    this.audio.pause();
    this.audio = null;
  }

  cancel() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  setCaretPosition(inputId) {
    const input: HTMLInputElement = <HTMLInputElement>document.getElementById(inputId);
    this.cursorPosition = input ? input.selectionStart : 0;
  }

  changeOrderVariable(selectedVariable, messageText, inputId) {
    this.isOpenVariableForNotificationText = false;
    messageText = messageText ?? '';
    messageText = messageText.substring(0, this.cursorPosition) + ' {{' + selectedVariable + '}} '
      + messageText.substring(this.cursorPosition);
    if (inputId === 'notificationText') {
      this.notificationText = messageText;
    }
    this.setCaretPosition(inputId);
    this.selectedNotificationTextVariable = null;
  }
}
