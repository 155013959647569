import { BaseCrudService } from "src/app/shared";
import { OnlineOrderingAnnouncementSchedule } from "../interface";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class OnlineOrderingAnnouncementScheduleService extends BaseCrudService<OnlineOrderingAnnouncementSchedule> {
    
    constructor(protected httpClient: HttpClient) {
        super('online-ordering-announcement-schedule', httpClient);
        
    }

    getNewAnnouncementSchedule(): OnlineOrderingAnnouncementSchedule {
        const today = new Date();
        return {
            Id: 0,
            StartDateTime: new Date(new Date().setDate(today.getDate() + 1)),
            EndDateTime: new Date(new Date().setDate(today.getDate() + 2))
        }
    }
}
