<div class="row mx-0">
    <div class="col-sm-6 col-xs-12">
        <pos-form-field label="URL" inputStyle="col-xs-11">
            <input posInput class="form-control" name="url" [(ngModel)]="widgetConfig.config.url" type="text" />
        </pos-form-field>
    </div>
    <div class="col-sm-6 col-xs-12">
        <pos-form-field label="Height">
            <input posInput class="form-control" name="height" [(ngModel)]="widgetConfig.config.height" type="text" />
        </pos-form-field>
    </div>
    <div class="col-sm-6 col-xs-12">
        <pos-form-field label="Border">
            <pos-color-picker posInput name="borderColor" [(ngModel)]="widgetConfig.config.borderColor">
            </pos-color-picker>
        </pos-form-field>
    </div>
</div>
