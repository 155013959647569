import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RuntimeConstants } from '../constants/runtime-constants';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../auth/services';
import { ApplicationStateService } from './application-state.service';
import { map } from 'rxjs/operators';
import { PermissionArguments } from '../interface/permission-arguments';
import { PermissionGrant, UserPermissionModel } from '../interface';
import { forEach, uniqBy } from 'lodash';
declare let $: any;

@Injectable()
export class PermissionService {
  permissionApiUrl = '';
  constructor(private httpService: HttpClient,
    private authenticationService: AuthenticationService,
    private applicationStateService: ApplicationStateService) {
    this.permissionApiUrl = RuntimeConstants.API_PATH + 'permissions';
  }

  getUserPermission(userId: number = null): Observable<any> {
    return this.httpService.get(`${this.permissionApiUrl}${userId ? ('/' + userId) : ''}`)
      .pipe((map((response: UserPermissionModel) => {
        if (response) {
          response.Permissions = this.getPermissionObject(response.Permissions);
          this.applicationStateService.userPermissions = response;
        }
        return response;
      })));
  }

  getPermissionObject = (permission: Array<PermissionGrant>): Array<PermissionGrant> => {
    let permissions:Array<PermissionGrant> = new Array<PermissionGrant>();
    forEach(permission, (item) => {
      permissions.push(item);
      if(item.Children.length) {
        const childPermissions = this.getPermissionObject(item.Children)
        forEach(childPermissions, (cp)=> {
          permissions.push(cp);
        });
      }
    })
    return uniqBy(permissions, x => x.Permission.Id);
  }

  reflectPermission = (data: PermissionArguments) => {
     if (data) {
        const grant = data.grantif ? data.grantif.toLowerCase() : 'any';
        const permission = JSON.parse(data.permissions);
        if (this.authenticationService.userHasPermission(permission, grant)) {
          data.elementRenderer.removeClass(data.currentElement.nativeElement, 'pointers-none');
          $(data.currentElement.nativeElement).show();
          data.elementRenderer.removeClass(data.currentElement.nativeElement, 'display-none');
        } else {
          if (data.disableIfNotGranted) {
            data.elementRenderer.addClass(data.currentElement.nativeElement, 'pointers-none');
          } else {
            $(data.currentElement.nativeElement).hide();
            data.elementRenderer.addClass(data.currentElement.nativeElement, 'display-none');
          }
        }
      }
  }
}
