import { DomainConstants } from 'src/app/shared/constants/domain-constants';
import { RuntimeConstants } from 'src/app/shared';
import { Component, OnInit, Input } from '@angular/core';
import { OrderNavigationButton, ScreenButtons } from 'src/app/orders/interface';
declare let $: any;
@Component({
  selector: 'pos-screen-preview',
  templateUrl: './screen-preview.component.html',
  styleUrls: ['./screen-preview.component.scss']
})
export class ScreenPreviewComponent implements OnInit {

  @Input()
  screenId: number;
  @Input() allButtons: Array<ScreenButtons> = [];

  buttonHeight: number;
  buttonWidth: string;
  width: number = 0;
  imageSize: string = '';
  buttonSize: string;
  imageBaseFile: string = RuntimeConstants.IMAGE_BASE_PATH;

  buttons: Array<OrderNavigationButton> = [];

  ngOnInit() {
    if (this.screenId > 0) {
      this.reloadScreenButtons(this.screenId);
    }
  }

  reloadScreenButtons(screenId) {
    const currentScreenButtons = this.allButtons[screenId];
    if (currentScreenButtons != null) {
      this.buttons = currentScreenButtons.Buttons;
      this.buttonSize = currentScreenButtons.Screen.ButtonSize;
      this.setButtonWidth(currentScreenButtons.Screen);
    } else {
      this.buttons = [];
    }
  }

  setButtonHeight() {
    let numberOfButtonDisplay = Math.ceil(100 / parseFloat(this.buttonWidth));
    let buttonViewWidth = $("#screenPreview").width();
    if (this.width != Math.floor(buttonViewWidth / numberOfButtonDisplay)) {
      this.width = Math.floor(buttonViewWidth / numberOfButtonDisplay);
      this.buttonHeight = Math.floor(((buttonViewWidth * parseFloat(this.buttonWidth)) / 100) - 30);
      this.imageSize = numberOfButtonDisplay <= 4 && this.width > 300 ? (this.width - 200 + 'px') : this.width - Math.floor(this.width / 1.5) + 'px';
    }
  }

  //var areaWidth
  setButtonWidth(button) {
    let areaWidth = $(".order-section__products__block").width();
    if (button.Layout != DomainConstants.ScreenButtonLayouts.Fixed || ($(window).width() < 768)) {
      if (($(window).width() < 768) && button.Layout != DomainConstants.ScreenButtonLayouts.Fixed) {
        this.buttonSize = null;
      }
      // Icon display minimum size small because of Tiny icons mode not display properly in mobile.(DomainConstants.ButtonSize.Tiny ? DomainConstants.ButtonSize.Small : this.buttonSize)
      areaWidth < 440 ?
        this.buttonWidth = this.calcPerOfWidth(2, this.buttonSize == DomainConstants.ButtonSize.Tiny ? DomainConstants.ButtonSize.Small : this.buttonSize)
        : areaWidth < 600 ? this.buttonWidth = this.calcPerOfWidth(this.buttonSize == DomainConstants.ButtonSize.Large ? 4 : 3, this.buttonSize)
          : areaWidth < 790 ? this.buttonWidth = this.calcPerOfWidth(4, this.buttonSize)
            : areaWidth < 991 ? this.buttonWidth = this.calcPerOfWidth(5, this.buttonSize)
              : areaWidth < 1300 ? this.buttonWidth = this.calcPerOfWidth(6, this.buttonSize)
                : this.buttonWidth = this.calcPerOfWidth(10, this.buttonSize);
      this.setButtonHeight();
      return this.buttonWidth;
    }
    else if (button.Layout == DomainConstants.ScreenButtonLayouts.Fixed && button.ColumnCount) {
      this.buttonWidth = this.calcPerOfWidth(button.ColumnCount, null);
      this.setButtonHeight();
      return this.buttonWidth;
    }
    else {
      this.buttonWidth = this.calcPerOfWidth(10, this.buttonSize);
      this.setButtonHeight();
      return this.buttonWidth;
    }
  }

  calcPerOfWidth(numberOfButtons, Size) {
    if (Size == DomainConstants.ButtonSize.Small) {
      if ($(window).width() < 991) {
        return (100 / (numberOfButtons + 1)) + "%";
      }
      return (100 / (numberOfButtons + 2)) + "%";
    }
    if (Size == DomainConstants.ButtonSize.Large) {
      // When numberOfButtons's value 2 then display 2 buttons display in mobile screen
      if ((numberOfButtons - 2) == 0) {
        return (100 / (numberOfButtons)) + "%";
      }
      return (100 / (numberOfButtons - 2)) + "%";
    }
    if (Size == DomainConstants.ButtonSize.Tiny) {
      if ($(window).width() < 768) {
        return (100 / numberOfButtons) + "%";
      }
      if ($(window).width() < 1300) {
        return (100 / (numberOfButtons + 2)) + "%";
      }
      return (100 / (numberOfButtons + 4)) + "%";
    }
    return (100 / numberOfButtons) + "%";
  }
}
