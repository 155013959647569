import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SecurityRoleService } from '../../service/security-role.service';
import { SpinnerService, AlertsService, Messages, ModalFormComponent, ModalService, FormUtilityService } from 'src/app/shared';
import { PermissionRoles } from '../../interface/permission-roles';
import { finalize } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'pos-copy-security-role',
    templateUrl: './copy-security-role.component.html',
    styleUrls: ['./copy-security-role.component.scss']
})
export class CopySecurityRoleComponent extends ModalFormComponent implements OnInit {
    get getForm(): NgForm {
        return this.copyToNewSecurityRoleNgForm;
    }

    @ViewChild('copyToNewSecurityRoleNgForm') copyToNewSecurityRoleNgForm: NgForm;
    roleId: number;
    roleName: string;
    copyToRole: string;
    roleAlreadyExists: boolean;

    constructor(private securityRoleService: SecurityRoleService,
        copySecurityRoleModalRef: BsModalRef,
        private spinnerService: SpinnerService,
        private alertService: AlertsService,
        modalService: ModalService, formUtilityService: FormUtilityService) {
        super(copySecurityRoleModalRef, modalService, formUtilityService);
    }

    ngOnInit() {
    }

    submit(isValid: boolean) {
        if (!isValid) {
            return;
        }
        this.spinnerService.show();
        this.securityRoleService.getSecurityRoles(true)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (permissionRoles: PermissionRoles[]) => {
                    this.roleAlreadyExists = false;
                    const existingPermissionRole = permissionRoles.find(
                        p => p.Name.toLowerCase().trim() === this.copyToRole.toLowerCase().trim());
                    if (!existingPermissionRole) {
                        this.spinnerService.show();
                        const permissionRole: PermissionRoles = {
                            Id: this.roleId,
                            Name: this.copyToRole,
                            DateAdded: new Date(),
                            DateEdited: null,
                            IsSystemDefined: false
                        };
                        this.securityRoleService.copySecurityRole(permissionRole)
                            .subscribe({
                                next: () => {
                                    this.alertService.renderSuccessMessage(Messages.SecurityRoleCopiedSuccess);
                                    this.onCancel(true);
                                }, error: this.alertService.showApiError,
                                complete: () => {
                                    this.spinnerService.hide();
                                }
                            });
                    } else {
                        this.roleAlreadyExists = true;
                    }
                }, error: this.alertService.showApiError
            });
    }
    onCancel(reload: boolean) {
        this.hide({ shouldReload: reload });
    }

}
