<pos-edit-wrapper (close)="cancelEditing()" [isPopup]="false" [headingIcon]="icons.inventoryZonesWarehouse"
    heading="{{ inventoryZoneName ? 'Edit Inventory Zone: ' + inventoryZoneName : 'Add Inventory Zone' }}">
    <form #formInventoryZone="ngForm" (ngSubmit)="saveInventoryZone(formInventoryZone.valid)" class="edit-form">
        <div class="col-12 container-fluid border-top pt-pix-10">
            <ul class="nav nav-tabs inner-tab pos-nav-tabs">
                <li [ngClass]="{'active': selectedTab == tabList.ZoneDetailsSettings}">
                    <a (click)="selectedTab = tabList.ZoneDetailsSettings" class="blue"
                        [ngClass]="{'required':!childFormValidCheck}">
                        <span>
                            <pos-icon class="theme-separator pe-pix-5" [icon]="icons.infoCircle"></pos-icon>
                        </span>
                        <span>Details</span></a>
                </li>
                <li [ngClass]="{'disabled-tab':inventoryZone.Id == 0, 'active': selectedTab == tabList.BinsSettings}">
                    <a (click)="selectedTab = tabList.BinsSettings"
                        [ngClass]="{'blue':inventoryZone.Id != 0 , 'grey': inventoryZone.Id == 0}">
                        <span>
                            <pos-icon class="theme-separator pe-pix-5" [icon]="icons.th"></pos-icon>
                        </span> <span>Bins</span> </a>
                </li>
                <li
                    [ngClass]="{'disabled-tab':inventoryZone.Id == 0, 'active': selectedTab == tabList.ProductsSettings}">
                    <a (click)="selectedTab = tabList.ProductsSettings"
                        [ngClass]="{'blue':inventoryZone.Id, 'grey': !inventoryZone.Id}">
                        <span>
                            <pos-icon class="theme-separator pe-pix-5" [icon]="icons.box"></pos-icon>
                        </span> <span>Products</span></a>
                </li>
            </ul>
            <div class="pos-tab-body overflow-auto-tabs">
                <div [hidden]="selectedTab != tabList.ZoneDetailsSettings" class="pt-pix-5">
                    <pos-inventory-zone-edit-details #childInventoryZoneDetail [inventoryZone]="inventoryZone"
                        [(parentForm)]="form">
                    </pos-inventory-zone-edit-details>
                </div>
                <div [hidden]="selectedTab != tabList.BinsSettings" class="pt-pix-5">
                    <pos-inventory-zone-edit-bins [editInventoryzoneId]="editInventoryzoneId"
                        [inventoryBinList]="inventoryBinList"></pos-inventory-zone-edit-bins>
                </div>
                <div [hidden]="selectedTab != tabList.ProductsSettings" class="pt-pix-5">
                    <pos-inventory-zone-edit-products [productList]="productList">
                    </pos-inventory-zone-edit-products>
                </div>
            </div>
        </div>
        <div class="col-12 action-button-container page-footer">
            <button class="btn-primary btn-lg btn btn-save pos-button" scrollup>
                Save
            </button>
            <button class="btn-secondary btn-lg btn btn-cancel pos-button" (click)="cancelEditing()" scrollup type="button">
                Cancel
            </button>
        </div>
    </form>
</pos-edit-wrapper>
