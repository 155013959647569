<pos-edit-wrapper heading="Order Kiosk" (close)="closeModal()">
    <div class="pos-modal-body pt-pix-15" [ngStyle]="{'min-height': screenHeight + 'px'}">

        <div class="col-12 position-relative">
            <div class="label-section active-label">
                <button class="btn btn-primary" (click)="disableAllKiosk()">
                    <pos-icon [icon]="icons.ban" class="pe-2"></pos-icon>Disable All</button>
            </div>
        </div>

        <ng-table [columns]="kioskTerminalStatusConfigColumns" [data]="kioskTerminalStatusConfig" [scrollHeight]="screenHeight + 'px'">
        </ng-table>

        <ng-template #headerTemplate>
        </ng-template>

        <ng-template let-data="rowData" #operationTemplate>
            <div class="d-flex justify-content-center">
                <p-inputSwitch [(ngModel)]="data.IsEnabled" [name]="'isEnabled' + data.TerminalId" 
                    (onChange)="changeKioskStatus(data)"/>
            </div>
        </ng-template>
    </div>
    <div class="col-12 action-button-container popup-footer">
        <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" value="Cancel"
          (click)="closeModal()">Close</button>
      </div>
</pos-edit-wrapper>
