import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseCrudService } from 'src/app/shared';
import { WhitelistedIpAddress } from '../interface/whitelisted-ip-address';

@Injectable({
  providedIn: 'root'
})
export class WhitelistedIpAddressService extends BaseCrudService<WhitelistedIpAddress> {

  constructor(private httpClient: HttpClient) {
    super('whitelistedIps', httpClient);
  }

  newWhitelistedIp() {
    const whitelistedIp: WhitelistedIpAddress = {
      Id: 0,
      IpAddress: ''
    };
    return whitelistedIp;
  }
}
