<pos-edit-wrapper (close)="onCancel()" [isPopup]="true" heading="Embed Screen">
  <form #embeddedScreenForm="ngForm" class="edit-form" (ngSubmit)="embedScreen(embeddedScreenForm.valid)">
    <div class="col-12 pos-modal-body">
      <pos-row>
        <pos-column>
          <pos-form-field label="Select Screen" [validationMessages]="{required: 'Please select screen.'}">
            <ng-select posInput appendTo="body" [items]="screens" bindLabel="Screen" #embeddedScreen="ngModel"
              bindValue="id" placeholder="Select screen to embed" [(ngModel)]="selectedScreenId" name="selectedFunction"
              name="embeddedScreen" required (change)="onScreenSelectionChange(selectedScreenId)">
            </ng-select>
          </pos-form-field>
        </pos-column>
      </pos-row>
      <div class="col-12 ps-0 border-top mt-pix-10 pt-pix-10">
        <label class="setting-title ps-pix-5">Preview</label>
        <div class="col-12 pos-title-content overflow-auto" [ngStyle]="{'height' : getHeight()}"
          *ngIf="selectedScreenId">
          <pos-screen-preview #screenPreviewComponent [screenId]="selectedScreenId" [allButtons]="allButtons">
          </pos-screen-preview>
        </div>
        <div class="col-12 pos-title-content" *ngIf="!selectedScreenId"
          [ngStyle]="{'height' : getHeight()}">
          No button(s) found.
        </div>
      </div>
    </div>

    <div class="col-12 action-button-container popup-footer">
      <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save</button>
      <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" (click)="onCancel()">Close</button>
    </div>
  </form>
</pos-edit-wrapper>
