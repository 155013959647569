<div class="m-0-auto list-width-70">
  <pos-list-wrapper [headingIcon]="icons.rssSquare" heading="Subscriptions" (close)="close()">
    <div class="row">
      <div class="col-md-12 pb-pix-10">
        <p-checkbox name="chkActive" #chkActive="ngModel" (onChange)="filterSubscriptionList()"
          [(ngModel)]="isInactive" binary="true" label="Show Inactive" class="float-end">
        </p-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ng-table #dataSourceSubscribeTable [search]="false" [paginator]="false" [columns]="subscribeColumns"
          [data]="subscribeListFilter">
        </ng-table>
        <ng-template let-data="rowData" #operationHeaderTemplate>
          <span (click)="editItem(0)" tooltip title="Add" class="inline-block">
            <pos-action-icon [icon]="icons.plusWhite" iconText="Add"></pos-action-icon>
          </span>
          <pos-export-dropdown class="inline-block ps-pix-5" (onPrint)="printData()" (onCSV)="exportCSV()">
          </pos-export-dropdown>
        </ng-template>
        <ng-template let-data="rowData" #operationTemplate>
          <div class="text-center">
            <span class="pe-pix-3 d-inline-block" (click)="editItem(data.Id, data)" tooltip title="Edit">
              <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
            </span>
            <span class="pe-pix-3 d-inline-block" (click)="deleteSubscription(data)" tooltip title="Delete">
              <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
            </span>
            <span class="d-inline-block" (click)="exportSubscription(data)" tooltip title="Export">
              <pos-action-icon [icon]="icons.fileExportWhite"></pos-action-icon>
            </span>
          </div>
        </ng-template>
        <ng-template let-data="rowData" #IsActiveTemplate>
          <span *ngIf="data.IsActive" class="green text-center vertical-mid font-13">
            <pos-icon [icon]="icons.check"></pos-icon>
          </span>
        </ng-template>
        <ng-template let-data="rowData" #SendEmptyResultsTemplate>
          <span *ngIf="data.SendEmptyResults" class="green text-center vertical-mid font-13">
            <pos-icon [icon]="icons.check"></pos-icon>
          </span>
        </ng-template>
      </div>
    </div>
  </pos-list-wrapper>
</div>
<pos-print-table [hidden]="true"></pos-print-table>
