import { SlicePipe } from '@angular/common';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TemplateColumn } from '@tarktech/tark-ng-utils/table/template-column';
import { Observable } from 'rxjs/internal/Observable';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { finalize } from 'rxjs/operators';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { FilterConfiguration } from 'src/app/shared/interface/filter-configuration';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { InboxMessage } from '../../interface/inbox-messages';
import { InboxMessageService } from '../../services/inbox-messages.service';
import { ViewMessageComponent } from '../view-message/view-message.component';
@Component({
  selector: 'pos-sent-messages',
  templateUrl: './sent-messages.component.html',
  styleUrls: ['./sent-messages.component.scss']
})
export class SentMessagesComponent implements OnInit {

  userDetail;
  messageModel: InboxMessage;
  sentMessages: Array<InboxMessage> = [];
  sentMessageColumns = [];
  totalRecords = 0;
  isLoad = false;
  filterConfiguration: FilterConfiguration = {
    PageNumber: 1, PageSize: 10,
    SortField: 'DateAdded', SortOrder: -1, FilterValue: null
  };
  @Input() height = 500;
  @ViewChild('sendToTemplate', { static: true }) sendToTemplate: TemplateRef<any>;
  @ViewChild('messageTextTemplate', { static: true }) messageTextTemplate: TemplateRef<any>;
  @ViewChild('dateTemplate', { static: true }) dateTemplate: TemplateRef<any>;
  constructor(private applicationStateService: ApplicationStateService,
    private inboxMessageService: InboxMessageService,
    private spinnerService: SpinnerService,
    private slicePipe: SlicePipe,
    private alertService: AlertsService,
    private modalService: ModalService) { }


  ngOnInit(): void {
    this.messageModel = this.inboxMessageService.getNewInboxMessage();
    this.messageModel.FromUserId = this.applicationStateService.userDetails.id;
    this.messageModel.FromTerminalId = this.applicationStateService.terminalId;
    this.configureGridColumns();
    this.loadData();
  }

  loadData() {
    const observable: Array<Observable<any>> = [];
    observable.push(this.inboxMessageService.getSentMessageCount(this.messageModel));
    observable.push(this.inboxMessageService.getSendMessages(this.messageModel, this.filterConfiguration));
    this.spinnerService.show();
    forkJoin(observable).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next:(response: [number, Array<InboxMessage>]) => {
        this.totalRecords = response[0];
        this.sentMessages = response[1];
      },
      error: this.alertService.showApiError
    });
  }



  configureGridColumns(): any {
    this.sentMessageColumns = [
      new TemplateColumn({
        HeaderText: 'Receiver',
        Field: 'Receiver',
        Width: '33%',
        IsSortable: true,
        itemTemplate: this.sendToTemplate,
      }),
      new TemplateColumn({
        HeaderText: 'Message Text',
        Field: 'MessageText',
        Width: '40%',
        IsSortable: true,
        itemTemplate: this.messageTextTemplate,
      }),
      new TemplateColumn({
        HeaderText: 'Date & Time',
        Field: 'DateAdded',
        Width: '23%',
        IsSortable: true,
        itemTemplate: this.dateTemplate,
      })
    ];
  }

  sliceMessage(message) {
    if (message.length > 40) {
      const messageArray = [...this.slicePipe.transform(message, 0, 40)];
      const shortMessage = messageArray.splice(0, messageArray.length - 1);
      let finalMessage = '';
      shortMessage.forEach((data) => {
        finalMessage = finalMessage + data;
      });
      finalMessage = finalMessage + '...';
      return finalMessage;
    }
    return message;
  }

  showMessage(data) {
    const modalRef = this.modalService.getModalWrapper(ViewMessageComponent);
    modalRef.show({
      animated: false,
      backdrop: 'static',
      keyboard: false,
      class: 'vertical-center modal-lg',
      initialState: {
        terminalMessageModel: data,
        isRequiredMarkAsRead: true,
        isSentMessage: true
      }
    });
  }

  lazyLoad(event) {
    if (this.isLoad) {
      this.filterConfiguration.PageSize = 10;
      this.filterConfiguration.PageNumber = Math.ceil((event.first + 1) / 10);
      this.filterConfiguration.SortField = event.sortField;
      this.filterConfiguration.SortOrder = event.sortOrder;
      this.filterConfiguration.FilterValue = event.globalFilter;
      this.loadData();
    }
    this.isLoad = true;
  }


}
