import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { filter, find, isNull } from 'lodash';
import { DomainConstants, FormUtilityService, ICloseable, ModalFormComponent, ModalService } from 'src/app/shared';
import { User } from '../../../../information-management/users';
import { Terminal } from '../../../../configurator/terminals/';
import { EventSubscriptionActionAttribute } from '../../interfaces/event-subscription-action-attribute';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RepeatActionConfigurationComponent } from '../repeat-action-configuration';

@Component({
  selector: 'pos-terminal-message-configuration',
  templateUrl: './terminal-message-configuration.component.html',
  styleUrls: ['./terminal-message-configuration.component.scss']
})
export class TerminalMessageConfigurationComponent extends ModalFormComponent implements OnInit, ICloseable {
  get getForm(): NgForm {
    return this.terminalMessageActivityEditForm;
  }
  @ViewChild('RepeatActionConfiguration') repeatActionConfiguration: RepeatActionConfigurationComponent;
  @ViewChild('terminalMessageActivityEditForm', { static: true }) terminalMessageActivityEditForm: NgForm;
  @Input() actionId = 0;
  @Input() eventSubscriptionActionAttributes: Array<EventSubscriptionActionAttribute> = [];
  @Input() eventAttributes;
  @Input() deliveryTypes = [];
  selectedDeliveryType;
  @Input() eventIdentifier: string;
  @Input() users: Array<User> = [];
  @Input() terminals: Array<Terminal> = [];
  @Input() isRecoverableEvent;
  deliveryAttributes = DomainConstants.EventDeliveryAttributes;
  selectedUsers: Array<number> = [];
  selectedTerminals: Array<number> = [];
  props = {
    controlBoxClass: 'col-lg-9 col-md-9 col-sm-12 col-xs-12'
  }
  constructor(modalRef: BsModalRef, modalService: ModalService, protected formUtilityService: FormUtilityService) {
    super(modalRef, modalService, formUtilityService);
  }

  ngOnInit(): void {
    this.terminals = filter(this.terminals, (terminal) => {
      return terminal.TerminalType === DomainConstants.TerminalTypes.ORDER_ENTRY.Name ||
        terminal.TerminalType === DomainConstants.TerminalTypes.MAKE_TABLE.Name || !terminal.TerminalId;
    });
    const selectedTerminals = find(this.eventSubscriptionActionAttributes,
      x => x.AttributeKey === this.deliveryAttributes.TerminalMessage.Terminals);
    this.selectedTerminals = selectedTerminals?.AttributeValue ?
      selectedTerminals.AttributeValue.split(',').map(x => parseInt(x, 10)) : null;
    const selectedUsers = find(this.eventSubscriptionActionAttributes,
      x => x.AttributeKey === this.deliveryAttributes.TerminalMessage.Users);
    this.selectedUsers = selectedUsers?.AttributeValue ? selectedUsers.AttributeValue.split(',').map(x => parseInt(x, 10)) : null;
  }

  saveActivity = (isValid: boolean) => {
    if (isValid) {
      const actionAttributes = this.prepareEventActionAttributes();
      this.hide({ shouldReload: true, actionAttributes: actionAttributes });
    }
  }

  onClose = (shouldReload: boolean) => {
    this.hide({ shouldReload: shouldReload });
  }

  prepareEventActionAttributes() {
    const actionDeliveryAttributes: Array<EventSubscriptionActionAttribute> = [];
    this.addAttribute(this.deliveryAttributes.TerminalMessage.Users, this.selectedUsers ?
      this.selectedUsers.join() : '', actionDeliveryAttributes);
    this.addAttribute(this.deliveryAttributes.TerminalMessage.Terminals, this.selectedTerminals ?
      this.selectedTerminals.join() : '', actionDeliveryAttributes);
      if(this?.repeatActionConfiguration?.repeatConfig) { 
        this.addAttribute(this.deliveryAttributes.TerminalMessage.Repeat, this?.repeatActionConfiguration?.isRepeat ? JSON.stringify(this?.repeatActionConfiguration?.repeatConfig) : "", actionDeliveryAttributes)
      }
    return actionDeliveryAttributes;
  }

  addAttribute = (key, value, array) => {
    const actionDeliveryAttribute: EventSubscriptionActionAttribute = {
      Id: this.getActionAttributeId(key),
      AttributeKey: key,
      AttributeValue: value,
      EventSubscriptionActionId: this.actionId
    };
    if ((actionDeliveryAttribute.Id > 0 && !isNull(actionDeliveryAttribute.AttributeValue)) ||
     (actionDeliveryAttribute.Id === 0 && actionDeliveryAttribute.AttributeValue)) {
      array.push(actionDeliveryAttribute);
    }
  }

  getActionAttributeId(key) {
    const attribute = find(this.eventSubscriptionActionAttributes, x => x.AttributeKey === key);
    return attribute ? attribute.Id : 0;
  }

  changeSelectedTerminals() {
    if (this.terminals.length > 0) {
      const lastSelectedTerminal = this.selectedTerminals[this.selectedTerminals.length - 1];
      if (lastSelectedTerminal === 0) {
        this.selectedTerminals = this.selectedTerminals.filter(x => x === 0);
      } else {
        this.selectedTerminals = this.selectedTerminals.filter(x => x !== 0);
      }
    }
  }

  changeSelectedUsers() {
    if (this.users.length > 0) {
      const lastSelectedTerminal = this.selectedUsers[this.selectedUsers.length - 1];
      if (lastSelectedTerminal === 0) {
        this.selectedUsers = this.selectedUsers.filter(x => x === 0);
      } else {
        this.selectedUsers = this.selectedUsers.filter(x => x !== 0);
      }
    }
  }
}
