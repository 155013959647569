import { Component, OnInit, Input } from '@angular/core';
import { GridColumn } from '@tarktech/tark-ng-utils';
import { InventoryZoneService } from 'src/app/shared/services/inventory-zone.service';
import { InventoryInfoModel } from '../../interface';
import { AlertsService } from 'src/app/shared';
declare let $: any;
@Component({
  selector: 'pos-inventory-products-for-zone',
  templateUrl: './inventory-products-for-zone.component.html',
  styleUrls: ['./inventory-products-for-zone.component.scss']
})
export class InventoryProductsForZoneComponent implements OnInit {

  @Input() inventoryZoneId: number;
  inventoryForZoneColumns: Array<GridColumn> = [];
  inventoryInfoModelList: Array<InventoryInfoModel> = [];
  isLoad = false;

  constructor(private inventoryZoneService: InventoryZoneService,
    private alertService: AlertsService) { }

  ngOnInit() {
    this.configureGridColumnsForZone();
    this.getDetails();
  }

  configureGridColumnsForZone() {
    this.inventoryForZoneColumns = [
      new GridColumn({ HeaderText: 'Bin', Field: 'PrimaryBin', Width: '15%' }),
      new GridColumn({ HeaderText: 'Inventory Product', Field: 'Name', Width: '56%' }),
      new GridColumn({ HeaderText: 'Zone Type', Field: 'ZoneType', Width: '29%' })
    ];
  }

  getDetails() {
    this.isLoad = true;
    this.inventoryZoneService.getInventoryProductsForZone(this.inventoryZoneId)
      .subscribe(response => {
        this.inventoryInfoModelList = response  == null ? [] : response;
        $('.popover').last().css('min-width', 350);
      },
        (error) => { this.alertService.showApiError(error); },
        () => { this.isLoad = false; });
  }

}
