import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RawIngredientDescriptor } from 'src/app/orders';
import { RuntimeConstants } from 'src/app/shared';

@Injectable({
  providedIn: 'root'
})
export class RawIngredientDescriptorService {

  constructor(private httpService: HttpClient) { }

  getAll(): Observable<Array<RawIngredientDescriptor>> {
    return this.httpService.get<Array<RawIngredientDescriptor>>
      (`${RuntimeConstants.API_PATH}raw-ingredient-descriptor/`);
  }
}
