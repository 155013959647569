import { Component, OnInit, OnChanges, EventEmitter, Input, Output } from '@angular/core';
import { ICloseable, Messages, SettingParam, ApplicationStateService, StringUtils } from 'src/app/shared';
import * as _ from 'lodash';

@Component({
  selector: 'pos-settle-seat',
  templateUrl: './settle-seat.component.html',
  styleUrls: ['./settle-seat.component.scss', '../../../../../../styles/pages/design/settle.scss']
})
export class SettleSeatComponent implements OnInit, OnChanges, ICloseable {
  close: EventEmitter<any> = new EventEmitter();
  @Input() orderId: number;
  @Input() seatsDetails: any = [];
  @Input() isPartialPayment: boolean;
  @Input() isSubmitPayment: boolean;
  @Input() paymentLoader: boolean;
  @Output() seatSelection: EventEmitter<any> = new EventEmitter();
  subAccountTerm: string;
  selectedSeats: Array<any> = [];
  seatKeys: any;
  settingParam: SettingParam;
  seatSelectionPrevent = '';
  seatPaymentCompleted = Messages.SeatPaymentCompleted;
  constructor(private applicationStateService: ApplicationStateService) { }

  ngOnInit() {
    this.setDefault();
  }

  setDefault() {
    this.settingParam = this.applicationStateService.settingParam;
    this.seatSelectionPrevent = StringUtils.format(Messages.SeatSelectionPrevent,
      {'subaccountTerm': !this.settingParam.SubaccountTerm ? 'Seat' : this.settingParam.SubaccountTerm});
    this.subAccountTerm = this.settingParam.SubaccountTerm ? this.settingParam.SubaccountTerm : 'Seat';
  }

  ngOnChanges(): void {
    if (this.seatsDetails) {
      this.seatKeys = Object.keys(this.seatsDetails);
      if(this.seatsDetails[0]?.SubAccountOrdinal == '0') {
        this.seatsDetails.shift();
      }
      if (this.seatsDetails.length) {
        this.selectedSeats = [];
        if (this.seatsDetails.length === 1) {
          // Object.keys(this.seatsDetails)[0]
          // this.seatsDetails[0].IsSelected = true;
          // const seat = {
          //   key: this.seatsDetails[0]?.SubAccountOrdinal,
          //   value: this.seatsDetails[0]
          // }
          // Object.keys(this.seatsDetails)[0]
          this.selectSeat(this.seatsDetails[0]);
        }
      }
    }
  }

  selectSeat(seat, click = false) {
    if (!this.isPartialPayment && !this.isSubmitPayment && !this.paymentLoader && !seat.Status) {
      if (!seat.IsSelected) {
        const sameSeatAvailable = _.filter(this.selectedSeats, (item) => {
          return seat.SubAccountOrdinal == item.SubaccountOrdinal;
        });
        if (!sameSeatAvailable || sameSeatAvailable.length == 0) {
          seat.IsSelected = true;
          const seatDetails = {
            key: seat?.SubAccountOrdinal,
            value: seat
          };
          this.selectedSeats.push(seat);
        }
      } else {
        // const seatKeys = Object.keys(this.seatsDetails);
        if(this.seatsDetails && this.seatsDetails[0]?.SubAccountOrdinal == 0)
        {
          this.seatsDetails.shift();
        }
        if (this.seatsDetails.length > 1) {
          seat.IsSelected = false;
          const index = this.selectedSeats.indexOf(seat);
          this.selectedSeats.splice(index, 1);
        }
      }
      this.seatSelection.emit({IsSubaccountSelected: (click && seat.IsSelected), SelectedSeat: this.selectedSeats});
    }
  }


  separateAll() {
    if (!this.isSubmitPayment && !this.paymentLoader) {
      _.forEach(this.seatsDetails, (item) => {
        item.IsSelected = false;
      });
      this.selectedSeats = [];
      this.seatSelection.emit({IsSubaccountSelected: false, SelectedSeat: this.selectedSeats});
    }
  }

}
