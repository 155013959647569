<pos-list-wrapper [headingIcon]="icons.infoManageCheckSquare" heading="Online Ordering - Options" (close)="close()">
  <div class="col-12 p-0">
    <ng-table #orderingOptionGrid [columns]="orderingOptionListColumns"
      [exportFilename]="'online-ordering-option-list'" [data]="optionList">
    </ng-table>
    <ng-template let-data="rowData" #iconTemplate>
      <span style="font-size: 13px" *ngIf="(data.Icon)" class="green text-center vertical-mid">
        <pos-icon [icon]="icons.check"></pos-icon>
      </span>
    </ng-template>
  
  </div>
</pos-list-wrapper>
<pos-confirm-delete (onConfirmAcknowledge)="onConfirmAcknowledge()" #confirmDeleteOption>
</pos-confirm-delete>
<pos-print-table #printTableReference [hidden]="true"></pos-print-table>
