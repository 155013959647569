<pos-edit-wrapper (close)="closeBatchEntry(true)"
  heading="Work Schedule Batch Entry">
    <form class="row m-0" #workScheduleBatchForm="ngForm" (submit)="submit(workScheduleBatchForm.valid)"
        autocomplete="off">
        <div [ngClass]="getFormHeight()">
            <div class="col-12">
                <div id="ConflictAlert" *ngIf="isConflict">
                    <ul class="ul-disc alert-danger alert">
                        <li *ngFor="let conflictSchedule of conflictSchedules">
                            {{conflictSchedule.Message}} </li>
                    </ul>
                </div>

            </div>
            <div class="row g-0">
                <pos-row class="mb-pix-10">
                    <pos-column md="1" sm="2" xs="12"></pos-column>
                    <pos-column md="11" sm="10" xs="12" class="p-0">
                        <pos-form-field label="Select User" inputStyle="col-12" [validationMessages]="{required: 'Please select user.'}">
                            <pos-row class="p-0">
                                <pos-column md="4" sm="6" xs="12">
                                    <ng-select posInput [items]="users" bindLabel="name" bindValue="id" placeholder="Select user"
                                        [(ngModel)]="userId" name="user" [required]="true">
                                    </ng-select>
                                </pos-column>
                                <pos-column md="7" xs="12">
                                    <button type="button" class="btn btn-primary btn-save col-150 pos-button" (click)="changeWeek(-7)"><pos-icon [icon]="icons.backward"></pos-icon> Previous Week
                                    </button>

                                    <button type="button" class="btn btn-primary btn-save col-150 ms-pix-4 pos-button" (click)="changeWeek(7)">Next Week <pos-icon [icon]="icons.forward"></pos-icon></button>
                                </pos-column>
                            </pos-row>
                        </pos-form-field>

                    </pos-column>
                </pos-row>
                <context-provider provide="props">
                <pos-row colSm="2" colXs="12">
                    <pos-column xs="1" class="text-center">
                        <div class="edit-form__section__label text-nowrap">
                            <p-checkbox name="chkCheckAll" #chkCheckAll="ngModel" [(ngModel)]="checkAll" binary="true"
                                (click)="checkAllSchedule(checkAll)" />
                        </div>
                    </pos-column>
                    <pos-column sm="2" xs="10">
                        <label class="edit-form__section__label  text-nowrap"><span class="bold"> Start Date </span></label>
                    </pos-column>
                    <pos-column>
                        <label class="edit-form__section__label  text-nowrap"><span class="bold"> Start Time </span></label>
                    </pos-column>
                    <pos-column sm="2" xs="10" class="ps-0">
                        <label class="edit-form__section__label  text-nowrap"><span class="bold"> End  Date </span></label>

                    </pos-column>
                    <pos-column>
                        <label class="edit-form__section__label  text-nowrap"><span class="bold"> End Time </span> </label>
                    </pos-column>

                    <pos-column>
                        <label class="edit-form__section__label"><span class="bold"> Tag </span></label>
                    </pos-column>

                    <pos-column sm="1" xs="12" class="pe-0 text-center">
                        <label class="edit-form__section__label text-nowrap"><span class="bold"> Optional Shift </span></label>
                    </pos-column>
                </pos-row>

                <div *ngIf="batchEntryData && batchEntryData.length > 0">
                    <div *ngFor="let data of batchEntryData; let i = index">
                        <pos-row colSm="2" colXs="12" >
                            <pos-column xs="1" class="text-center">
                                <pos-form-field inputStyle="col-12 pt-pix-7" inputStyleClass="ps-3">
                                    <p-checkbox posInput name="isActive_{{i}}" #isActive_{{i}}="ngModel" [(ngModel)]="data.isActive"
                                        binary="true" (click)="changeIsActive()">
                                    </p-checkbox>
                                </pos-form-field>
                            </pos-column>

                            <pos-column sm="2" xs="10">
                                <pos-form-field inputStyleClass="ps-3">
                                    <p-calendar posInput [readonlyInput]="true" [required]="true" [dateFormat]="dateFormat"
                                        [inputStyle]="{'min-width':'100px','display':'unset'}" id="startDate_{{i}}"
                                        (onSelect)="changeScheduleData(data, workScheduleBatchEntryControls.StartDate, i)"
                                        inputStyleClass="form-control" [monthNavigator]="true" [showIcon]="true"
                                        name="startDate_{{i}}" [(ngModel)]="data.start_date" appendTo="body">
                                    </p-calendar>
                                </pos-form-field>
                            </pos-column>

                            <pos-column>
                                <pos-form-field [validationMessages]="{required: 'Please enter start time.'}" 
                                    inputStyleClass="ps-3"[inputStyle]="{'col-12': true, 'hasError': data.conflict}">
                                    <input posInput type="time" placeholder="HH:mm:ss" class="form-control" id="startTime_{{i}}"
                                        name="startTime_{{i}}" [required]="true"
                                        (change)="changeScheduleData(data, workScheduleBatchEntryControls.StartTime)"
                                        [(ngModel)]="data.StartTime" #startTime="ngModel" [required]="data.isActive">
                                </pos-form-field>
                            </pos-column>

                            <pos-column sm="2" xs="10" class="ps-0">
                                <pos-form-field inputStyleClass="ps-3">
                                    <p-calendar posInput [readonlyInput]="true" [required]="true" [dateFormat]="dateFormat"
                                        [inputStyle]="{'min-width ':'100px','display':'unset'}" id="endDate_{{i}}"
                                        (onSelect)="changeScheduleData(data, '')" inputStyleClass="form-control ps-3"
                                        [monthNavigator]="true" [showIcon]="true" name="endDate_{{i}}"
                                        [(ngModel)]="data.end_date" appendTo="body">
                                    </p-calendar>
                                </pos-form-field>
                            </pos-column>

                            <pos-column>
                                <pos-form-field [validationMessages]="{required: 'Please enter end time.'}" inputStyleClass="ps-3" [inputStyle]="{'col-12': true, 'hasError': data.conflict }">
                                    <input posInput type="time" placeholder="HH:mm:ss" class="form-control" id="endTime_{{i}}"
                                        name="endTime_{{i}}" (change)="changeScheduleData(data, '')"
                                        [(ngModel)]="data.EndTime" #endTime="ngModel" [required]="data.isActive">
                                </pos-form-field>
                            </pos-column>

                            <pos-column >
                                <pos-form-field inputStyleClass="ps-3">
                                    <div class="col-12 position-relative tag-setup" [ngStyle]="{minWidth: '150px'}">
                                        <div class="input-color z-index"
                                            [ngStyle]="{'background-color': data.TagColor ? data.TagColor : ''}">
                                        </div>
                                        <ng-select posInput [items]="tags" id="workScheduleTagName_{{i}}" bindLabel="Name"
                                            bindValue="Id" placeholder="Select tag" [(ngModel)]="data.TagId" class="w-100"
                                            name="workScheduleTagName_{{i}}" (change)="getTagColor(data)" appendTo="body">
                                        </ng-select>
                                    </div>
                                </pos-form-field>
                            </pos-column>

                            <pos-column sm="1" xs="12">
                                <pos-form-field class="pt-pix-7" inputStyle="col-12 pt-pix-7 text-center" inputStyleClass="ps-3">
                                    <p-checkbox posInput name="optional_{{i}}" #optional_{{i}}="ngModel"
                                        [(ngModel)]="data.optional" binary="true"
                                        (click)="changeScheduleData(data, '')">
                                    </p-checkbox>
                                </pos-form-field>
                            </pos-column>
                        </pos-row>
                    </div>
                </div>
                </context-provider>
            </div>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save</button>
            <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button"
                (click)="closeBatchEntry(true)">Close</button>
        </div>
    </form>
</pos-edit-wrapper>
