<div class="offset-md-11 col-md-1 debug-button">
  <button ngDraggable *ngIf="isDebug" class="btn btn-circle btn-inverse white btn-lg btn-primary draggable-debugger"
    (click)="openDebuggerModal()" id="btnShowLogViewer" type="button">
    <pos-icon [icon]="icons.turnDebugMode"></pos-icon>
  </button>
</div>
<ng-template #debuggerModal>
  <div>
    <div>
      <button class="close" (click)="closeDebuggerModal()">&times;</button>
      <h4 class="modal-content__heading">
        Logs
        <button class="btn btn-danger me-pix-5 float-end debugger-button pos-button" (click)="clearLog()" type="button">
          Clear
        </button>
        <button class="btn btn-secondary me-pix-5 float-end black debugger-button pos-button" (click)="uploadLog()" type="button">
          Upload
        </button>
        <select class="form-control me-pix-5 float-end col-100 debugger-button" id="level" name="level"
          [(ngModel)]="logLevel">
          <option *ngFor="let item of logLevels | keyvalue: originalOrder| orderBy: 'value': true" [value]="item.value">
            {{item.key}}</option>
        </select>
      </h4>
    </div>
    <div class="pos-modal-body pb-3 pt-3 screen-scroll">
      <div>
        <b>Terminal:</b> {{terminalName}} &nbsp;
        <b>Browser: </b> {{browser}}
      </div>
      <hr />
      <div class="log-viewer-modal__logs">
        <div class="{{log.cssClass}}" *ngFor="let log of logs| orderBy: 'time': true">
          <span *ngIf="log.levelValue <= logLevel">
            <b>{{log.level}} ({{log.time | tark_date_time: true}}):</b> {{log.message}}
          </span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
