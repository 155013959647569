import { Component, OnChanges, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/internal/operators/finalize';
import { inboxIn, inboxOut } from 'src/app/shared/components/icon';
import { ModalComponent } from 'src/app/shared/components/modal/modal-component';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { InboxMessage } from '../../interface/inbox-messages';
import { InboxMessageService } from '../../services';
declare var $;

@Component({
  selector: 'pos-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent extends ModalComponent implements OnInit, OnChanges {

  icons = {
    inboxIn,
    inboxOut
  };
  tabList = {
    Inbox: 'Inbox',
    Sent: 'Sent'
  };
  inboxMessages = 0;
  sentMessages = 0;
  screenHeight = 0;
  messageModel: InboxMessage;
  selectedTab = this.tabList.Inbox;
  constructor(
    unitListModalRef: BsModalRef,
    private messageService: InboxMessageService,
    private spinnerService: SpinnerService,
    private applicationStateService: ApplicationStateService,
    private alertService: AlertsService) {
    super(unitListModalRef);
    this.messageModel = this.messageService.getNewInboxMessage();
    this.messageModel.FromUserId = this.applicationStateService.userDetails.id;
    this.messageModel.FromTerminalId = this.applicationStateService.terminalId;
    this.messageModel.RecipientUserId = this.applicationStateService.userDetails.id;
    this.messageModel.RecipientTerminalId = this.applicationStateService.terminalId;
  }

  ngOnChanges() {

  }

  ngOnInit(): void {
    this.loadCount();
    this.screenHeight = $(window).height() - 500 < 150 ? 300 : 503;
    this.resizeWindow();
  }

  resizeWindow = () => {
    $(window).resize(() => {
      this.screenHeight = $(window).height() - 500 < 150 ? 300 : 503;
    });
  }

  loadCount() {
    this.spinnerService.show();
    this.messageService.getInboxMessageCount(this.messageModel).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (response: number) => {
        this.inboxMessages = response;
      }, error: this.alertService.showApiError
    });
  }

  inboxMessageChange(messageCount: number) {
    this.inboxMessages = messageCount;
  }

  sentMessageChange(messageCount: number) {
    this.sentMessages = messageCount;
  }

  closeDialog() {
    this.hide({ shouldReload: false });
  }

}
