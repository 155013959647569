import { Component, OnInit } from '@angular/core';
import { SpinnerService } from './spinner.service';

@Component({
  selector: 'pos-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  showSpinner: boolean;
  constructor(private _spinnerService: SpinnerService) {
  }

  ngOnInit() {
    this._spinnerService._spinnerVisibility.subscribe(showSpinner => {
      this.showSpinner = showSpinner;
    });
  }
}
