import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { forEach } from 'lodash';
import { finalize } from 'rxjs/operators';
import { AlertsService, DomainConstants } from 'src/app/shared';
import { manualSubscriptionReject, SpinnerService, upload } from 'src/app/shared/components';
import { ManualDataExportPreview, ExportDataColumn } from '../../interface';
import { ManualDataExportService } from '../../service/manual-data-export.service';

@Component({
  selector: 'pos-manual-data-export-preview',
  templateUrl: './manual-data-export-preview.component.html',
  styleUrls: ['./manual-data-export-preview.component.scss']
})
export class ManualDataExportPreviewComponent implements OnInit {

  id: number;
  manualExport: ManualDataExportPreview;
  manualExportGridColumns: Array<GridColumn> = [];
  manualExportData: Array<any> = [];
  showOperationDoneBlock = false;
  pendingStatus = DomainConstants.ExportStatus.Pending;
  icons = {
    upload,
    manualSubscriptionReject
  };
  constructor(
    private manualDataSubscriptionService: ManualDataExportService,
    private route: ActivatedRoute,
    private router: Router,
    private spinnerService: SpinnerService,
    private alertService: AlertsService
  ) {
    this.id = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
    if (!this.id) {
      this.close();
    }
    this.manualExport = manualDataSubscriptionService.newManualSubscription();
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.spinnerService.show();
    this.manualDataSubscriptionService.getManualExportPreview(this.id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: ManualDataExportPreview) => {
          this.manualExport = res;
          this.manualExportGridColumns = this.prepareColumns(this.manualExport.Columns);
          this.manualExportData = this.manualExport?.Data;
        }, error: this.alertService.showApiError
      });
  }

  prepareColumns(subscriptionColumns: Array<ExportDataColumn>) {
    const columns: Array<GridColumn> = [];
    forEach(subscriptionColumns, (column) => {
      columns.push(new GridColumn({
        HeaderText: column.Name, Field: column.FieldName,
        TextAlign: TextAlign.Left
      }));
    });
    if (!columns.length) {
      columns.push(new GridColumn({
        HeaderText: 'No data'
      }));
    }
    return columns;
  }

  getHeight(): number {
    const smallInnerHeight = (window.innerHeight - 275 > 500) ? 500 : window.innerHeight - 275;
    return window.innerHeight - 275 > 630 ? 630 : smallInnerHeight;
  }

  close() {
    this.router.navigate(['subscriptions/manual'], { relativeTo: this.route.parent });
  }

  releaseExport() {
    this.spinnerService.show();
    this.manualDataSubscriptionService.releaseManualExport(this.manualExport.Id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.alertService.renderSuccessMessage('Export is released');
          this.close();
        }, error: this.alertService.showApiError
      });
  }

  ignoreExport() {
    this.spinnerService.show();
    this.manualDataSubscriptionService.ignoreManualExport(this.manualExport.Id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.alertService.renderSuccessMessage('Export is ignored');
          this.close();
        }, error: this.alertService.showApiError
      });
  }
}

