<div>
  <button (click)="closeReorder()" class="close" type="button">&times;</button>
  <h4 class="modal-content__heading">Tag Reorder</h4>
</div>
<div class="p-pix-10 overflow-hidden">
  <div class="screen-scroll">
    <div cdkDropList (cdkDropListDropped)="onSort($event)" class="g-0 row pt-0">
      <div *ngFor="let tag of tags" class="col-12 move drag-item mb-pix-5" cdkDrag>
        <div class="well p-pix-20">
          <div>{{ tag }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
