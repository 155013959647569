<pos-kiosk-header *ngIf="screenConfig.Header.IsShow" [header]="screenConfig.Header" />
<div class="row mx-0 designer-area" [style.background-color]="screenConfig.BackgroundColor"
    [style.height]="sectionHeight + 'px'"
    [style.background-image]="screenConfig.BackgroundImage ? 'url(\''+ imageRootPath + screenConfig.BackgroundImage + '\')' : null">
    <div class="p-0 relative" [ngClass]="screenConfig.ShowInvoice ? 'col-xl-9 col-8' : 'col-12'">
        <div class="d-flex" [ngStyle]="{'justify-content': layoutConfig.ShakeDetails.ContainerWidth ? 'center' : ''}">
            <div class="d-flex h-100 absolute" [ngStyle]="{'align-items': layoutConfig.ShakeDetails.Alignment?.Vertical,
        'width': layoutConfig.ShakeDetails.ContainerWidth ? layoutConfig.ShakeDetails.ContainerWidth : '100%'
     }">
                <div class="shake-details"
                    [ngStyle]="{'top': layoutConfig.ShakeDetails.VerticalPosition + 'px', 'justify-content': layoutConfig.ShakeDetails.Alignment?.Horizontal}">
                    <div class="ai-shake-name" [ngStyle]="{'color': layoutConfig.ShakeDetails.BackgroundColor}">
                        {{ createdShake.Name }}
                    </div>
                    <div class="ai-shake" *ngIf="createdShake"
                        [ngStyle]="{'background-color': layoutConfig.ShakeDetails.BackgroundColor }">
                        <p class="primary-flavor">
                            {{ createdShake.PrimaryFlavor.Name }} </p>
                        <p>+</p>
                        <p class="flavor bold" [ngStyle]="{'color': layoutConfig.ShakeDetails.Color}"
                            *ngFor="let flavor of createdShake.SecondaryFlavors"> {{ flavor.Name }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex" [ngStyle]="{'justify-content': layoutConfig.Buttons.ContainerWidth ? 'center' : ''}">
            <div class="d-flex h-100 absolute" [ngStyle]="{'justify-content': layoutConfig.Buttons.ContainerWidth ? 'center' : '', 'align-items': layoutConfig.Buttons.Alignment?.Vertical,
        'width': layoutConfig.Buttons.ContainerWidth ? layoutConfig.Buttons.ContainerWidth : '100%'
     }">
                <div class="buttons"
                    [ngStyle]="{'justify-content': layoutConfig.Buttons.Alignment?.Horizontal, 'top': layoutConfig.Buttons.VerticalPosition + 'px' }">
                    <div class="try-again button" (click)="buttonClick(screenButtons[0], true)"
                        [ngStyle]="{'background-color': layoutConfig.TryAgain.BackgroundColor, 'color': layoutConfig.TryAgain.Color, 'height': layoutConfig.Buttons.Height + 'px', 'width': layoutConfig.Buttons.Width + 'px', 'border-radius': layoutConfig.Buttons.Radius + 'px', 'font-size': layoutConfig.Buttons.FontSize}">
                        <div class="config-button">
                            <pos-kiosk-screen-setup [screenButton]="screenButtons[0]" [screenButtons]="screenButtons"
                                [isDesignMode]="isDesignMode" [setupItems]="setupItems"></pos-kiosk-screen-setup>
                        </div>
                        Try Again
                    </div>
                    <div class="button" (click)="buttonClick(screenButtons[1])"
                        [ngStyle]="{'background-color': layoutConfig.AddToCart.BackgroundColor, 'color': layoutConfig.AddToCart.Color, 'height': layoutConfig.Buttons.Height + 'px', 'width': layoutConfig.Buttons.Width + 'px', 'border-radius': layoutConfig.Buttons.Radius + 'px', 'font-size': layoutConfig.Buttons.FontSize }">
                        <div class="config-button">
                            <pos-kiosk-screen-setup [screenButton]="screenButtons[1]" [screenButtons]="screenButtons"
                                [isDesignMode]="isDesignMode" [setupItems]="setupItems"></pos-kiosk-screen-setup>
                        </div>
                        ORDER THIS AI SHAKE
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-4 p-3" *ngIf="screenConfig.ShowInvoice" [ngStyle]="{'height': invoiceHeight - 3 + 'px'}">
        <pos-kiosk-invoice></pos-kiosk-invoice>
    </div>
</div>
<pos-kiosk-footer *ngIf="screenConfig.Footer.IsShow" [buttons]="screenButtons"
    [footer]="screenConfig.Footer"></pos-kiosk-footer>
