import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  tv, editScreenSetup, paintBrush, screenSetupPlus, plusSquare, copy, times, objectUngroup,
  arrowsAlt, save, embedScreen
} from 'src/app/shared/components/icon';
import * as _ from 'lodash';
import { Configurations } from 'src/app/shared/interface';
import {
  ApplicationStateService, AlertsService, SpinnerService, ModalService, ConfirmDeleteModalComponent, Messages, OrderService,
  Permissions, Levels
} from 'src/app/shared';
import { ScreenEditComponent, ButtonEditComponent } from 'src/app/information-management';
import { ChooseColorScreenComponent } from './components/choose-color-screen/choose-color-screen.component';
import { finalize } from 'rxjs/operators';
import { AddExistingButtonComponent } from './components/add-existing-button/add-existing-button.component';
import { CopyButtonsComponent } from './components/copy-buttons/copy-buttons.component';
import { InfoModalComponent } from 'src/app/shared/components/info-modal';
import { ScreenBackupComponent } from './components/screen-backup/screen-backup.component';
import { ReArrangeButtonComponent } from './components/re-arrange-button/re-arrange-button.component';
import { ScreenButtons } from '../../interface/screen-buttons';
import { cloneDeep, parseInt } from 'lodash';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { EmbedScreenComponent } from 'src/app/orders/order-entry/screen-setup/components/embed-screen/embed-screen.component';
declare let $: any;
@Component({
  selector: 'pos-screen-setup',
  templateUrl: './screen-setup.component.html',
  styleUrls: ['./screen-setup.component.scss', '../order/order.component.scss']
})
export class ScreenSetupComponent implements OnInit {

  @Input() screenId: number;
  @Input() screenName: string = '';
  @Input() homeScreenId: number;
  @Input() navigation: ScreenButtons;
  @Input() oldMenuIndex: any;
  @Input() menuExplorer = false;
  @Input() screenWidth;
  @Input() screenButtons: Array<ScreenButtons> = [];
  @Output() reloadNavigation: EventEmitter<any> = new EventEmitter();
  @Output() reloadScreens: EventEmitter<any> = new EventEmitter();
  public icons = {
    tv,
    editScreenSetup,
    paintBrush,
    screenSetupPlus,
    plusSquare,
    copy,
    times,
    objectUngroup,
    arrowsAlt,
    save,
    embedScreen
  };

  permission = {
    UIScreens: Permissions.UIScreens,
    UIButtons: Permissions.UIButtons,
    ManageScreenBackups: Permissions.ManageScreenBackups,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit,
    accessLevel: Levels.Access
  };

  appStateConfigurations: Configurations;
  constructor(private applicationStateService: ApplicationStateService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private modalService: ModalService,
    private orderService: OrderService) { }

  ngOnInit() {
  }

  setDefaults() {
    this.appStateConfigurations = this.applicationStateService.configurations;
  }

  screenSetup(event: Event) {
    if (this.oldMenuIndex != null) {
      $('.custom-order').css('display', 'none');
      this.oldMenuIndex = null;
    }
    event.stopPropagation();
    $('#screenSetting').toggle();
  }

  editScreen(isNew = false) {
    const screenModalRef = this.modalService.show(ScreenEditComponent, {
      animated: false,
      class: 'vertical-center modal-lg',
      initialState: {
        screenId: isNew ? 0 : this.screenId,
        screenParentId: this.screenId
      }
    });

    screenModalRef.close.subscribe(res => {
      if (res && res.shouldReload) {
        this.reloadScreens.emit(res.savedScreenId ?? this.screenId);
      }
    });

  }

  applyColorToButton() {
    const modalRef = this.modalService.getModalWrapper(ChooseColorScreenComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      initialState: {
        screenId: this.screenId
      }
    });
    modal.close.subscribe(res => {
      if (res && res.shouldReload) {
        this.reloadNavigation.emit(this.screenId);
      }
    });
  }

  addNewButton() {
    this.spinnerService.show();
    this.orderService.checkForMostCommonNextScreen(this.screenId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: any) => {
          if (res && res.CheckNextScreen && res.CheckNextScreen.Id) {
            this.confirmSetMostCommonNextScreen(res.CheckNextScreen.Id, res.CheckNextScreen.Title);
          } else {
            this.addButton(null);
          }
        }, error: this.alertService.showApiError
      });
  }

  confirmSetMostCommonNextScreen(mostCommonNextScreen: number, screenName: string) {
    const confirmCommonNextScreen = this.modalService.show(ConfirmDeleteModalComponent, {
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      initialState: {
        message: Messages.ConfirmAddNewButton + screenName + '?'
      }
    });

    confirmCommonNextScreen.close.subscribe(res => {
      if (res?.shouldDelete) {
        this.addButton(mostCommonNextScreen);
      } else {
        this.addButton(null);
      }
    });
  }

  addButton(mostCommonNextScreen: number) {
    const modalRef = this.modalService.getModalWrapper(ButtonEditComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center modal-lg modal-max-width-65',
      initialState: {
        screenId: this.screenId,
        selectedScreen: mostCommonNextScreen,
        screenButtons: this.navigation.Buttons
      }
    });
    modal.close.subscribe(res => {
      if (res?.reload) {
        this.reloadNavigation.emit(this.screenId);
      }
    });
  }

  addExistingButton() {
    const modalRef = this.modalService.getModalWrapper(AddExistingButtonComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center modal-lg',
      initialState: {
        screenId: this.screenId
      }
    });
    modal.close.subscribe(res => {
      if (res && res.shouldReload) {
        this.reloadNavigation.emit(this.screenId);
      }
    });
  }

  copyButton() {
    const modalRef = this.modalService.getModalWrapper(CopyButtonsComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center modal-lg',
      initialState: {
        screenId: this.screenId
      }
    });
    modal.close.subscribe(res => {
      if (res && res.shouldReload) {
        this.reloadNavigation.emit(this.screenId);
      }
    });
  }

  deleteScreen() {
    if (this.screenId == this.homeScreenId) {
      const modalRef = this.modalService.show(InfoModalComponent, {
        animated: false,
        class: 'vertical-center',
        initialState: {
          message: Messages.DeleteHomeScreen
        }
      });
      return;
    }
    const confirmCommonNextScreen = this.modalService.show(ConfirmDeleteModalComponent, {
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      initialState: {
        message: StringUtils.format(Messages.ConfirmDeleteScreen,
          {
            'screenTitle': this.screenName
          })
      }
    });

    confirmCommonNextScreen.close.subscribe(res => {
      if (res && res.shouldDelete) {
        this.confirmDeleteScreen();
      }
    });
  }

  confirmDeleteScreen() {
    this.spinnerService.show();
    this.orderService.checkScreenReferences(this.screenId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: any) => {
          if (res && res.CheckReferenceScreen && res.CheckReferenceScreen.length > 1) {
            const checkReferenceScreen = res.CheckReferenceScreen;
            let mappedScreenInfoMessage = "<div class='text-left mb-5'>The screen is being used in the order entry in following navigation paths:</div><div class='table-height overflow-auto-tabs text-left'><ul class='pad20 ml-10 bullatedlist'>";
            _.forEach(checkReferenceScreen, (item) => {
              mappedScreenInfoMessage += '<li>' + item.Title + ' > ' + item.Selection + '</li>';
            });
            mappedScreenInfoMessage += '</ul></div>';
            const modalRef = this.modalService.getModalWrapper(InfoModalComponent);
            modalRef.show({
              animated: false,
              class: 'vertical-center',
              initialState: {
                message: mappedScreenInfoMessage
              }
            });
          } else {
            this.deleteCurrentScreen();
          }
        }, error: this.alertService.showApiError
      });
  }

  deleteCurrentScreen() {
    this.spinnerService.show();
    this.orderService.deleteScreen(this.screenId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: any) => {
          this.alertService.renderSuccessMessage(Messages.ScreenDeleted);
          this.reloadScreens.emit(this.homeScreenId);
        }, error: this.alertService.showApiError
      });
  }

  reArrangeButton() {
    const modalRef = this.modalService.getModalWrapper(ReArrangeButtonComponent);
    const navigate = cloneDeep(this.navigation);
    if (this.menuExplorer) {
      const height = parseInt(navigate.UiConfiguration.height);
      const calculatedHeight = (height - (height * 0.2));
      navigate.UiConfiguration.height = calculatedHeight.toString();
      navigate.UiConfiguration.imageSize = (calculatedHeight - (calculatedHeight * 0.4)).toString() + 'px';
    }
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center modal-lg modal-max-width-75',
      initialState: {
        orderNavigation: navigate
      }
    });
    modal.close.subscribe(res => {
      if (res?.shouldReload) {
        this.reloadNavigation.emit(this.screenId);
      }
    });
  }

  screenBackups() {
    const modalRef = this.modalService.getModalWrapper(ScreenBackupComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center modal-lg',
      initialState: {
      }
    });
    modal.close.subscribe(res => {
      if (res && res.shouldReload) {
        this.reloadNavigation.emit(this.screenId);
      }
    });
  }

  embedScreen() {
    const modalRef = this.modalService.getModalWrapper(EmbedScreenComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center modal-lg',
      initialState: {
        screenId: this.screenId,
        allButtons: this.screenButtons
      }
    });
    modal.close.subscribe(res => {
      if (res && res.shouldReload) {
        this.reloadNavigation.emit(this.screenId);
      }
    });
  }
}
