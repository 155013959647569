<pos-edit-wrapper heading="{{screenDetails.Id ? 'Edit Screen': 'Add Screen'}}" (close)="cancel()">
  <form #screenDetailForm="ngForm" (ngSubmit)="saveScreenDetails(screenDetailForm.valid)" autocomplete="off">
    <div class="col-12 container-fluid form-horizontal">
      <ul class="nav nav-tabs inner-tab pos-nav-tabs">
        <li [ngClass]="{'active': selectedTab == tabList.ScreenSettings }">
          <a (click)="selectedTab = tabList.ScreenSettings" class="blue"
            [ngClass]="{'required': screenSettings.invalid }">
            <pos-icon [icon]="icons.screensDesktop" class="pr-5 theme-separator"></pos-icon>
            Screen
          </a>
        </li>
        <li [ngClass]="{'active': selectedTab == tabList.MultipleProductSetting }">
          <a (click)="selectedTab = tabList.MultipleProductSetting" class="blue">
            <pos-icon [icon]="icons.list" class="pr-5 theme-separator"></pos-icon>
            Multiple Product Workflow
          </a>
        </li>
      </ul>
      <pos-row colXs="12" class="mx-0 pos-tab-body screen-scroll" [ngStyle]="{'height': getHeight() + 'px'}">
        <pos-column class="p-0">
          <div [hidden]="selectedTab != tabList.ScreenSettings" ngModelGroup="screenSettings"
            #screenSettings="ngModelGroup">
            <pos-column>
              <pos-form-field label="Title" [validationMessages]="{required: 'Please enter screen title.'}">
                <input posInput name="screenTitle" #screenTitle="ngModel" type="text" class="form-control"
                  [(ngModel)]="screenDetails.Title" [required]="true" />
              </pos-form-field>
            </pos-column>

            <pos-column>
              <pos-form-field>
                <p-checkbox posInput name="printOnOrderReceipt" #printOnOrderReceipt="ngModel"
                  [(ngModel)]="screenDetails.PrintOnOrderReceipt" binary="true"
                  label="Print to Kitchen/Show on Make Table">
                </p-checkbox>
              </pos-form-field>
            </pos-column>

            <pos-column sm="6" xs="12">
              <pos-form-field label="Min Selections"
                [validationMessages]="{ min: 'Please enter min greater than 0.', max: 'Min selection must be less than or equal to 99.'}">
                <input type="number" id="minSelection" name="minSelection" #minSelection="ngModel"
                  class="form-control col-120" posInput [(ngModel)]="screenDetails.MinSelections" min="0" max="99" />
              </pos-form-field>
            </pos-column>
            <pos-column sm="6" xs="12">
              <pos-form-field label="Max Selections"
                [validationMessages]="{ required: 'Please enter max selection.', min: 'Max selection must be greater than min selection.', max: 'Max selection must be less than or equal to 99.'}">
                <input type="number" id="maxSelection" name="maxSelection" #maxSelection="ngModel"
                  class="form-control col-120 " posInput [(ngModel)]="screenDetails.MaxSelections"
                  [min]="screenDetails.MinSelections ?? 0" max="99" [required]="screenDetails.MinSelections" />
              </pos-form-field>
            </pos-column>

            <pos-column sm="6" xs="12">
              <pos-form-field label="Color">
                <pos-color-picker posInput name="MakeTableColor" [(ngModel)]="screenDetails.color">
                </pos-color-picker>
              </pos-form-field>
            </pos-column>
            <pos-column sm="6" xs="12">
              <pos-form-field label="Background" inputStyle="col-12">
                <pos-row>
                  <pos-column md="8" xs="9">
                    <input posInput type="file" id="screenIcon" name="screenIcon" class="form-control"
                      accept=".png,.jpg,.jpeg,.gif,.bmp" ngModel (change)="handleFileInput($event.target.files)" />
                  </pos-column>
                  <pos-column md="1" xs="3" class=" p-0 col-50 mt-pix-n1" [hidden]="!screenDetails.background_image">
                    <img #screenImage id="screenImage" alt="Screen background image" class="col-40"
                      src="{{imagePath}}/buttons/{{screenDetails.background_image}}" />
                    <div class="relative mt-pix-n7 pull-right" (click)="removeIcon()">
                      <pos-icon [icon]="icons.times" class="pointer ms-pix-n7 remove-icon-badge"></pos-icon>
                    </div>
                  </pos-column>
                </pos-row>
              </pos-form-field>
            </pos-column>

            <pos-column sm="9" xs="12">
              <pos-form-field label="Default Next">
                <ng-select #screens="ngModel" name="screens" [items]="screenList" bindLabel="Title" bindValue="Id"
                  posInput [(ngModel)]="screenDetails.DefaultNextScreenId" placeholder="Select default screen"
                  appendTo="body" [clearable]="true">
                </ng-select>
              </pos-form-field>
            </pos-column>
            <pos-column sm="9" xs="12">
              <pos-form-field label="Next Screen for Multiple Products">
                <ng-select posInput #buttonSize="ngModel" name="multiProductNextScreen" id="multiProductNextScreen"
                  appendTo="body" [clearable]="false" [(ngModel)]="screenDetails.MultiProductNextScreenId"
                  [items]="screenList" bindLabel="Title" placeholder="Select screen" bindValue="Id" posInput
                  dropdownPosition="top" [clearable]="true">
                </ng-select>
              </pos-form-field>
            </pos-column>

            <pos-column>
              <pos-form-field label="Behavior">
                <p-checkbox posInput name="screenPrice" #screenPrice="ngModel" [(ngModel)]="screenDetails.IsPrice"
                  binary="true" label="Price Override by time" class="ps-pix-15">
                </p-checkbox>
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-row class="mx-0" *ngIf="screenDetails.IsPrice">
                <pos-column xs="6" class="col-200">
                  <pos-form-field [validationMessages]="{ required: 'Please enter start time.'}" inputStyle="col-xs-12">
                    <input posInput type="time" placeholder="HH:mm:ss" class="form-control" name="priceStartTime"
                      #priceStartTime="ngModel" [(ngModel)]="startTime" [required]="screenDetails.IsPrice">
                  </pos-form-field>
                </pos-column>
                <pos-column xs="6" class="col-200">
                  <pos-form-field [validationMessages]="{ required: 'Please enter end time.'}" inputStyle="col-xs-12">
                    <input posInput type="time" placeholder="HH:mm:ss" class="form-control" name="priceEndTime"
                      #priceEndTime="ngModel" [(ngModel)]="endTime" [required]="screenDetails.IsPrice">
                  </pos-form-field>
                </pos-column>
              </pos-row>
              <pos-column *ngIf="screenDetails.IsPrice">
                <pos-form-field label="Price Adjust" inputStyle="ps-pix-20" labelStyle="ps-pix-20"
                  [validationMessages]="{max: 'Price must be less than or equal to 9999.'}">
                  <p class="input-group col-170">
                    <span class="input-group-addon">{{currencySymbol}}</span>
                    <input posInput posDecimal type="number" class="form-control price-input" name="price"
                      #price="ngModel" [(ngModel)]="screenDetails.PriceAmount" max="9999" />
                  </p>
                </pos-form-field>
              </pos-column>
              <pos-column>
                <pos-form-field>
                  <p-checkbox name="screenSizeForm" #screenSizeForm="ngModel" [(ngModel)]="screenDetails.SizeForm"
                    binary="true" label="Generate Half/Whole Buttons" class="ps-pix-15">
                  </p-checkbox>
                </pos-form-field>
              </pos-column>
            </pos-column>
            <pos-column>
              <pos-form-field label="Layout">
                <div class="p-0 btn-group">
                  <label class="btn col-75 btn-radio"
                    [ngClass]="screenDetails.Layout == screenLayouts.Fluid ? 'active btn-primary normal': 'btn-secondary'">
                    <input name="selectedLayout" (change)="setNoOfColumns()" [(ngModel)]="screenDetails.Layout"
                      type="radio" [value]="screenLayouts.Fluid" /> {{screenLayouts.Fluid}}
                  </label>
                  <label class="btn col-75 btn-radio"
                    [ngClass]="screenDetails.Layout == screenLayouts.Fixed ? 'active btn-primary normal':'btn-secondary'">
                    <input name="selectedLayout" (change)="setNoOfColumns()" [(ngModel)]="screenDetails.Layout"
                      type="radio" [value]="screenLayouts.Fixed" /> {{screenLayouts.Fixed}}
                  </label>
                </div>
              </pos-form-field>
            </pos-column>

            <pos-column>
              <pos-form-field label="Buttons Size" *ngIf="screenDetails.Layout == screenLayouts.Fluid">
                <ng-select posInput #buttonSize="ngModel" name="buttonSize" id="buttonSize" [clearable]="false"
                  class="col-150" [(ngModel)]="screenDetails.ButtonSize" bindValue="value" bindLabel="key"
                  [items]="buttonSizes | keyvalue" dropdownPosition="top">
                </ng-select>
              </pos-form-field>
            </pos-column>

            <pos-column>
              <pos-form-field label="Columns" *ngIf="screenDetails.Layout == screenLayouts.Fixed"
                [validationMessages]="{required: 'Please enter columns.', min: 'Please enter positive number of columns.'}">
                <input posInput type="number" id="noOfColumns" name="noOfColumns" #noOfColumns="ngModel"
                  class="form-control col-120" [(ngModel)]="screenDetails.ColumnCount" min="1" [required]="true" />
              </pos-form-field>
            </pos-column>
          </div>
          <div [hidden]="selectedTab != tabList.MultipleProductSetting" ngModelGroup="multipleProductSetting"
            #multipleProductSetting="ngModelGroup">

            <div class="col-12">
              <div *ngIf="!screenId" class="alert condensed inherit-position alert-warning mt-pix-10">
                {{pressApplyToProceed}}</div>
              <pos-multi-product-next-screen [screenId]="screenId" [screenList]="screenList"
                *ngIf="screenId && screenList?.length > 0"></pos-multi-product-next-screen>
            </div>

          </div>
        </pos-column>
      </pos-row>
    </div>
    <div class="col-12 action-button-container popup-footer">
      <button class="btn-lg btn-success btn btn-save pos-button" type="submit" value="Apply" (click)="isSaveAndContinue = true"
            *ngIf="!screenId"> Apply </button>
      <button class="btn-primary btn-lg btn btn-save normal pos-button" type="submit" value="Save">Save</button>
      <button class="btn-secondary btn-lg btn btn-cancel-modal pos-button" (click)="cancel()" type="button"
        value="Cancel">Cancel</button>
    </div>
  </form>
</pos-edit-wrapper>
