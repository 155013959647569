<pos-list-wrapper [headingIcon]="icons.tag" heading="Tags" (close)="close()">
  <div class="row pb-pix-5">
    <div class="col-12">
      <div class="inline-block pt-pix-5 ps-pix-10 float-end col-200">
        <ng-select *ngIf="tagCategories && tagCategories.length" [items]="tagCategories" bindLabel="Name"
          bindValue="Value" placeholder="Category" [(ngModel)]="tagCategory" appendTo="body" name="tagCategories"
          (change)="getTagsWithFilter()">
        </ng-select>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <ng-table #gridComponent [search]="true" [sort]="true" [paginator]="true" [columns]="tagColumns"
        [data]="tagListFilter" [isReorderableTable]="true" [exportFilename]="'tags'"
        [ReorderableColumnRow]="'row'" (onRowReorder)="gridRowReorder($event)">
      </ng-table>
    </div>
  </div>
</pos-list-wrapper>
<ng-template let-data="rowData" #searchableTemplate>
  <span *ngIf="data.IsSearchable" class="green text-center vertical-mid font-13">
    <pos-icon [icon]="icons.check"></pos-icon>
  </span>
</ng-template>
<ng-template let-data="rowData" #tagColor>
  <div class="h-50 col-w-10" [ngStyle]="{'background-color': data.Color }">
  </div>
</ng-template>
<ng-template let-data="rowData" #iconTemplate>
  <span *ngIf="data.Icon">
    <img src="{{imagePath}}/{{data.Icon}}" height="40px" width="40px" class="radius-25" />
  </span>
</ng-template>

<pos-print-table #printTableReference [hidden]="true"></pos-print-table>
