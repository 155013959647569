import { Directive, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[range][ngModel]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => RangeDirective),
    multi: true
  }]
})
export class RangeDirective implements Validator {
  @Input() range: Array<number>;

  private onChange: () => void;
  validate(control: AbstractControl): ValidationErrors {
    if (!this.range?.length || (!control.value && control.value != 0)) return null;
    let value: number = +control.value;
    return value >= this.range[0] && value <= this.range[1] ? null : {range: {actualValue: value, range: this.range}};
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onChange = fn;
  }
}
