import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { find, first } from 'lodash';
import { finalize } from 'rxjs/operators';
import { AlertsService, ButtonBehavior, FormUtilityService, InventoryProductDetails, InventoryProductService, ModalFormComponent, ModalService, SpinnerService } from 'src/app/shared';
import Pluralize from 'pluralize';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'pos-button-inventory-increment-decrement',
  templateUrl: './button-inventory-increment-decrement.component.html',
  styleUrls: ['./button-inventory-increment-decrement.component.scss']
})
export class ButtonInventoryIncrementDecrementComponent extends ModalFormComponent implements OnInit {
  @ViewChild('incrementInventoryForm') incrementInventoryForm: NgForm;
  @Input() selectedButtonBehaviorType;
  @Input() buttonBehavior = null;

  inventoryProductId: number = null;
  qty: number = null;
  unit = '';
  Pluralize = Pluralize;
  inventoryProducts: Array<InventoryProductDetails> = [];

  get getForm(): NgForm {
    return this.incrementInventoryForm;
  }

  constructor(private inventoryProductService: InventoryProductService,
    protected spinnerService: SpinnerService,
    protected alertsService: AlertsService,
    modalRef: BsModalRef, modalService: ModalService, protected formUtilityService: FormUtilityService) {
    super(modalRef, modalService, formUtilityService);
  }

  ngOnInit(): void {
    if (this.buttonBehavior && this.buttonBehavior.Id) {
      try {
        if(this.buttonBehavior.BehaviorValue) {
          const behavior = first(this.buttonBehavior.BehaviorValue) as any;
          this.inventoryProductId = behavior.InventoryProductId??null;
          this.qty = behavior.Qty??null;
        } else {
          const behavior =JSON.parse(this.buttonBehavior.TextValue);
          this.inventoryProductId = behavior ? behavior.inventoryProductId : null;
          this.qty = behavior && behavior.qty ? behavior.qty : null;
        }
      } catch (ex) {
        console.error('Invalid behavior value: ' + this.buttonBehavior.TextValue);
      }
    }
    this.loadData();
    this.setInitialValue();
  }

  loadData() {
    this.spinnerService.show();
    this.inventoryProductService.getActiveInventoryProducts(true)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.inventoryProducts = res;
          this.selectInventoryProduct(this.inventoryProductId);
        }, error: this.alertsService.showApiError
      });
  }

  saveBehavior = (isValid: boolean) => {
    if (isValid && this.inventoryProductId) {
      this.hide({ shouldReload: true, Value: { inventoryProductId: this.inventoryProductId, qty: this.qty } });
    }
  }

  closeModal = () => {
    this.hide({ shouldReload: false });
  }

  selectInventoryProduct(inventoryProductId: number) {
    this.unit = find(this.inventoryProducts, x => x.Id === inventoryProductId)?.UnitName;
  }

}
