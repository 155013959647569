import { Route } from '@angular/router';
import { AuthGuard } from '../app-guard';
import { MenuDisplayConfigComponent } from './components/menu-display-config/menu-display-config.component';
import { MenuDisplayEditComponent } from './components/menu-display-edit/menu-display-edit.component';
import { MenuDisplayComponent } from './components/menu-display/menu-display.component';
import { MenuWidgetConfigComponent } from './components/menu-widget-config/menu-widget-config.component';
import { MenuWidgetListComponent } from './components/menu-widget-list/menu-widget-list.component';
import { WidgetGroupComponent } from './components/widget-group/widget-group.component';

export const MENU_DISPLAY_CONFIG: Route = {
    path: '',
    canActivateChild: [AuthGuard],
    data: {
        // permissions: [{ 'Name': DomainConstants.TerminalTypes.DIGITAL_SIGNAGE.Permission, 'Level': Levels.Access }]
    },
    children: [
        {
            path: '',
            component: MenuDisplayComponent,
            data: {
                group: 'menu',
            }
        },
        {
            path: ':id',
            component: MenuDisplayEditComponent,
            data: {
                group: 'menu',
            }
        },
        {
            path: 'config/:id',
            component: MenuDisplayConfigComponent
        },
        {
            path: ':id/preview',
            component: MenuDisplayConfigComponent,
            data: {
                group: 'menu',
                isEditable: false
            }
        },
        {
            path: ':id/widgets',
            component: MenuWidgetListComponent,
            data: {
                group: 'menu-widget',
            }
        },
        {
            path: ':menuId/widgets/:widgetId/groups',
            component: WidgetGroupComponent,
            data: {
                group: 'menu-widget',
            }
        },
        {
            path: ':menuId/widgets/:widgetId',
            component: MenuWidgetConfigComponent,
            data: {
                group: 'menu-widget',
            }
        }
    ]
};
export const MENU_DISPLAY_STATE = [MENU_DISPLAY_CONFIG];
