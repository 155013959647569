import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  BaseListComponent, AlertsService, SpinnerService, ModalService, Permissions, Levels,
  PrintTableService, ApplicationStateService, Messages, DomainConstants, ConfirmDeleteModalComponent,
  AuthenticationService
} from 'src/app/shared';
import { Playlist } from '../../interface/playlist';
import { PlaylistsService } from '../../services/playlists.service';
import { GridColumn, TemplateColumn } from '@tarktech/tark-ng-utils';
import { plusWhite, editWhite, deleteWhite, cogWhite, playListThList } from 'src/app/shared/components/icon';
import * as  _ from 'lodash';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'pos-playlists',
  templateUrl: './playlists.component.html',
})
export class PlaylistsComponent extends BaseListComponent<Playlist> implements OnInit {

  playlist: Playlist;
  displayModes = {
    'List': 'List',
    'AddEdit': 'AddEdit',
    'TrackConfiguration': 'TrackConfiguration',
    'TrackAdd': 'TrackAdd'
  };
  icons = {
    plusWhite, editWhite, deleteWhite, cogWhite, playListThList
  };
  permission = {
    name: Permissions.SystemConfigurationPlaylists,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };
  displayMode: string;
  playlists: Array<Playlist> = [];
  playlistColumns: Array<GridColumn> = [];
  @ViewChild('operationTemplate', { static: true }) operationTemplate: TemplateRef<any>;
  @ViewChild('operationHeaderTemplate', { static: true }) operationHeaderTemplate: TemplateRef<any>;

  constructor(protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected playlistsService: PlaylistsService,
    protected printService: PrintTableService,
    protected applicationStateService: ApplicationStateService,
    protected router: Router,
    protected route: ActivatedRoute,
    private authenticationService: AuthenticationService) {
    super(playlistsService, alertService, spinnerService, modalService, applicationStateService, printService, route);
    this.playlist = playlistsService.newPlaylist();
  }

  ngOnInit() {
    this.displayMode = this.displayModes.List;
    this.deleteSuccessMessage = Messages.PlaylistDeleted;
    this.playlistColumns = this.configureGridColumns();
  }

  reload() {
    this.spinnerService.show();
    this.playlistsService.getAll()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: response => {
          this.playlists = response == null ? [] : response;
        }, error: this.alertService.showApiError
      });
  }

  configureGridColumns(): Array<GridColumn> {

    const operationColumn = new TemplateColumn({
      itemTemplate: this.operationTemplate,
      Width: '100px',
      headerTemplate: this.operationHeaderTemplate,
      CellClass: 'cell-padding'
    });

    this.playlistColumns = [
      new GridColumn({ HeaderText: ' Name', Field: 'Name', IsSortable: true, Width: '95%' })
    ];

    if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      this.playlistColumns.push(operationColumn);
    }

    _.remove(this.playlistColumns, (column) => {
      return (column.Type === DomainConstants.GridColumnsTypes.EditColumn || column.Type === DomainConstants.GridColumnsTypes.DeleteColumn);
    });

    return this.playlistColumns;
  }

  getGridColumns(): Array<GridColumn> {
    return this.playlistColumns;
  }

  editItem(id: number, data?: Playlist): void {
    this.displayMode = this.displayModes.AddEdit;
    this.router.navigate([id], { relativeTo: this.route });
  }

  deletePlaylist(data: Playlist): void {
    const modalRef = this.modalService.getModalWrapper(ConfirmDeleteModalComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      initialState: {
        message: Messages.ConfirmDeletePlaylist
      }
    });
    modal.close.subscribe(res => {
      if (res && res.shouldDelete) {
        this.delete(data.Id);
      }
    });
  }

  openPlaylistTracksAddEdit(id, name) {
    this.displayMode = this.displayModes.TrackConfiguration;
    this.router.navigate(['playlist/tracks', id], { state: { playlistId: id, playlistName: name }, relativeTo: this.route.parent });
  }

  getConfirmDeleteMessage(data: Playlist): string {
    return Messages.ConfirmDeletePlaylist;
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route});
  }

}
