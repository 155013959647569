import { Component, OnInit, ViewChild, OnChanges } from '@angular/core';
import { SalesUnit } from '../../../../shared/interface/sales-units';
import { BaseListComponent } from 'src/app/shared/components/base-list.component';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { GridColumn, TableComponent } from '@tarktech/tark-ng-utils';
import { SalesUnitService } from '../../services/sales-unit.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import { SalesUnitEditComponent } from '../sales-unit-edit';
import { ActivatedRoute, Router } from '@angular/router';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { salesUnitsThLarge } from 'src/app/shared/components/icon';
import { Permissions, Levels, AuthenticationService, DomainConstants } from 'src/app/shared';
import { cloneDeep, remove } from 'lodash';

@Component({
  selector: 'pos-sales-units-list',
  templateUrl: './sales-units-list.component.html',
  styleUrls: ['./sales-units-list.component.scss']
})
export class SalesUnitsListComponent extends BaseListComponent<SalesUnit> implements OnInit, OnChanges {

  salesUnitColumns: Array<GridColumn> = [];
  salesUnits: SalesUnit[] = [];
  @ViewChild('salesUnitGrid', { static: true }) salesUnitGrid: TableComponent;
  public icons = {
    salesUnitsThLarge
  };

  permission = {
    name: Permissions.SalesUnits,
    editLevel: Levels.Edit
  };

  constructor(protected salesUnitService: SalesUnitService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected applicationStateService: ApplicationStateService,
    protected printService: PrintTableService,
    private authenticationService: AuthenticationService,
    protected route: ActivatedRoute,
    protected router: Router) {
    super(salesUnitService, alertService, spinnerService, modalService, applicationStateService, printService, route);
    this.scrollToTop();
  }

  ngOnInit() {
    this.deleteSuccessMessage = Messages.SalesUnitDeleted;
    this.salesUnitColumns = this.configureGridColumns();
    this.checkPermission();
    this.loadSubscriptions();
    this.salesUnitGrid.context = this.gridContext;
  }

  ngOnChanges() {
    this.loadSubscriptions();
  }

  private loadSubscriptions(): void {
    this.data$.subscribe({
      next: (res) => {
        this.salesUnits = res;
      }
    });
  }

  getGridColumns(): Array<GridColumn> {
    return [
      new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '77%' }),
      new GridColumn({ HeaderText: 'Abbreviation Name', Field: 'AbbreviationName', IsSortable: true, Width: '15%' })
    ] as Array<GridColumn>;
  }

  private checkPermission(): void {
    if (!this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      remove(this.salesUnitColumns, (column) => {
        return (column.Type === DomainConstants.GridColumnsTypes.EditColumn
          || column.Type === DomainConstants.GridColumnsTypes.DeleteColumn);
      });
    }
  }

  editItem(id: number, data?: SalesUnit): void {
    const modalRef = this.modalService.getModalWrapper(SalesUnitEditComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      initialState: {
        salesUnit: cloneDeep(data)
      }
    });
    modal.close.subscribe(res => {
      if (res && res.shouldReload) {
        this.reload();
      }
    });
  }

  getConfirmDeleteMessage(data: SalesUnit): string {
    return StringUtils.format(Messages.ConfirmDeleteSalesUnit,
      { 'salesUnit': data ? data.Name : '' }
    );
  }

  private scrollToTop(): void {
    window.scrollTo(0, 0);
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
