import { Component, OnInit, Input, OnChanges, HostListener } from '@angular/core';
import { SpinnerService, AlertsService, OrderService, Messages, ApplicationStateService } from 'src/app/shared';
import { GridColumn, BooleanColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { IngredientsInfoService } from '../services';
import { InventoryProductIngredientModel } from '../interface';
import { finalize } from 'rxjs/operators';
import { ingredientsInfoPrint } from 'src/app/shared/components/icon';
import { number } from 'yargs';
declare let $: any;
@Component({
    selector: 'pos-ingredients-info',
    templateUrl: './ingredients-info.component.html',
    styleUrls: ['./ingredients-info.component.scss']
})
export class IngredientsInfoComponent implements OnInit, OnChanges {
    @Input() productId: number;
    @Input() Height: number;
    ingredientsInfo: Array<InventoryProductIngredientModel> = [];
    ingredientsInfoColumns: Array<GridColumn> = [];
    screenHeight: number;
    icons = {
        ingredientsInfoPrint
    }
    constructor(
        private ingredientsInfoService: IngredientsInfoService,
        private spinnerService: SpinnerService,
        private alertService: AlertsService,
        private ordersService: OrderService,
        private applicationStateService: ApplicationStateService
    ) {
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.screenHeight = $(window).height() - 500 < 150 ? 100 : 290;
    }

    ngOnInit() {
        this.configureGridColumns();
        this.screenHeight = $(window).height() - 500 < 150 ? 100 : 290;
    }


    ngOnChanges() {
        if (this.productId) {
            this.getIngredientsInfo(this.productId);
        }
    }
    configureGridColumns() {
        this.ingredientsInfoColumns = [
            new GridColumn({ HeaderText: 'Inventory Product', Field: 'InventoryProduct', Width: '46%' }),
            new GridColumn({ HeaderText: 'Raw Ingredient', Field: 'RawIngredient', Width: '46%' }),
            new BooleanColumn({
                HeaderText: '< 2%',
                Field: 'LessThan2Percent', Width: '8%', TextAlign: TextAlign.Center
            })
        ] as Array<GridColumn>;

    }

    getIngredientsInfo(productId) {
        this.spinnerService.show();
        this.ingredientsInfoService.getIngredientsInfo(productId).subscribe(
            (response: InventoryProductIngredientModel[]) => {
                this.ingredientsInfo = response;
            },
            this.alertService.showApiError,
            () => {
                this.spinnerService.hide();
            }
        );
    }

    printIngredients() {
        if (this.applicationStateService.settingParam.ReceiptPrinter?.Id) {
            this.spinnerService.show();
            const terminalId = this.applicationStateService.terminalId;
            this.ordersService.printIngredients(this.productId, terminalId)
                .pipe(finalize(() => {
                    this.spinnerService.hide();
                }))
                .subscribe({
                    next: res => {
                        this.alertService.renderSuccessMessage(Messages.PrintRequestSendSuccess);
                    }, error: this.alertService.showApiError
                });
        } else {
            this.alertService.renderErrorMessage(Messages.ReceiptPrinterNotMapped);
        }
    }
}
