<pos-edit-wrapper heading="Instructions: {{salesProductName}}" (close)="onCancel()">
  <div class="col-12 pos-modal-body form-horizontal screen-scroll">
    <div class="instructions-configuration-page">
      <div class="alert condensed inherit-position alert-warning mt-pix-10 mb-pix-5 font-16"
        *ngIf="isDisplayInstructionWarning" id="divDoNotInstructions">
        {{warningMessage}}
      </div>

      <div class="row g-0">
        <pos-row *ngIf="salesProductName" class="mb-pix-5 mt-pix-5 justify-content-between p-0">
          <pos-column md="3" sm="5" xs="6">
            <h3 class="pti-5"><strong>{{salesProductName}}</strong></h3>
          </pos-column>
          <pos-column lg="2" md="3" sm="7" xs="6" class="float-end" class="pe-0">
            <pos-form-field label="Size" labelStyle="col-2 pt-pix-7" class="row justify-content-end pe-0"
              [appendPaddingTop]="false" inputStyle="col-10 ps-4 pe-0">
              <ng-select posInput appendTo="body" [items]="salesSizes" bindLabel="Name" bindValue="SizeId"
                (change)="setInstructionSize(instructionSizeId)" placeholder="Select size"
                [(ngModel)]="instructionSizeId" [clearable]="false" name="productSize">
              </ng-select>
            </pos-form-field>
          </pos-column>
        </pos-row>
        <div class="col-lg-3 col-12 area-block pr-custom-pix-sm-0 border-right">
          <div class="row g-0 col-12 pt-pix-12 pb-pix-12 bg-theme-primary ">
            <div class="row g-0 vertical-center-align">
              <div class="col-lg-9 col-8"><strong>Areas</strong></div>
              <div class="col-lg-2 col-4 text-end p-0">
                <span class="text-nowrap" (click)="editInstructionArea(0, true)">
                  <pos-action-icon [icon]="icons.plusWhite" iconText="Add"></pos-action-icon>
                </span>
              </div>
            </div>
          </div>
          <div class="col-12 area-block__list">
            <div cdkDropList (cdkDropListDropped)="onSortArea($event)" class="row g-0 p-pix-5 pt-0">
              <div
                *ngFor="let area of areaList | dataFilter:{'InstructionSizeId': instructionSizeId} | orderBy: 'InstructionAreaOrdinal'"
                class="col-12 mt-pix-5 p-pix-5 pt-pix-10 pb-pix-10 bg-white move" cdkDrag
                (click)="editInstructionArea(area.InstructionAreaId)"
                [ngClass]="{'bg-antiquewhite': instructionArea.Id == area.InstructionAreaId}">
                <div class="row g-0 vertical-center-align">
                  <div class="col-10">{{area.InstructionAreaName}}
                  </div>
                  <div class="col-2 text-end ps-0">
                    <span class="pe-pix-5 pointer"
                      (click)="deleteInstructionArea(area.InstructionAreaId, area.InstructionAreaName)">
                      <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <form #formArea="ngForm" (ngSubmit)='updateInstructionArea(formArea.valid)' class="col-12 border-top">
            <context-provider provide="props">
              <h3 class="setting-title"> {{editAreaLabel}} </h3>

              <pos-row>
                <pos-column>
                  <pos-form-field label="Name" [validationMessages]="{required: 'Please enter area name.'}">
                    <input posInput name="areaName" #areaName="ngModel" type="text" class="form-control"
                      [(ngModel)]="instructionArea.Area" [required]="true" id="areaName" />
                  </pos-form-field>
                </pos-column>
                <pos-column xs="12">
                  <pos-form-field label="Category" inputStyle="col-12 pe-0">
                    <ng-select posInput [items]="inventoryCategories" bindLabel="InventoryCategoryName" bindValue="id"
                      (change)="onChangeInventoryCategory(instructionArea.InventorySubcategoryId)"
                      placeholder="Select inventory category" [(ngModel)]="instructionArea.InventorySubcategoryId"
                      name="inventoryCategory">
                    </ng-select>
                  </pos-form-field>
                </pos-column>
              </pos-row>
              <div class="col-12 pe-pix-5 mt-pix-10">
                <button type="submit" value="save" [disabled]="!instructionSizeId"
                  class="btn btn-primary btn-save float-end normal pos-button mb-1">{{instructionArea.Id ? "Update" :
                  "Add"}}</button>
              </div>
            </context-provider>
          </form>
        </div>
        <div class="col-lg-9 col-12 row m-0 pe-0 instruction-block">
          <div class="col-12 pt-pix-5 pb-pix-5 bg-theme-primary h-fit-content">
            <div class="row vertical-center-align">
              <div class="col-sm-7 col-6 ps-pix-10"><strong>Ingredients</strong></div>
              <div class="col-sm-5 col-6 pe-pix-10">
                <span class="float-end ps-pix-10 pt-pix-7" (click)="editInstruction(0)">
                  <pos-action-icon [icon]="icons.plusWhite" iconText="Add"></pos-action-icon>
                </span>
                <button class="btn btn-secondary float-end pe-pix-10 normal pos-button"
                  (click)="openImportRecipes()">Import
                  Recipe</button>
              </div>
            </div>
          </div>

          <div class="col-12 instruction-block__list">
            <div class="row p-pix-5" cdkDropList (cdkDropListDropped)="onSortInstruction($event)">
              <div class="col-12 me-pix-5 move"
                *ngFor="let instruction of areaInstructions | dataFilter:{'InstructionSizeId': instructionSizeId} | orderBy: 'IngredientOrdinal'; let i = index"
                [ngClass]="{'mt-pix-5': i > 0}" cdkDrag>
                <div class="row mx-0 vertical-center-align bg-white full-height"
                  [ngClass]="{'bg-antiquewhite':areaInstruction.Id==instruction.IngredientId}"
                  (click)="editInstruction(instruction.IngredientId)">
                  <div class="col-sm-1 col-2 p-pix-1 instruction-block__list__img-wrap">
                    <img class="instruction-block__list__img-wrap__img width-full"
                      [hidden]="!instruction.IngredientImage" alt="" height="60px"
                      src="{{imagePath}}/instructions/{{instruction.IngredientImage}}" #instructionImage
                      id="instructionImage" />
                  </div>
                  <div class="col-sm-10 col-8" [innerHTML]="replaceLineBreak(instruction.IngredientInstruction)">
                  </div>
                  <div class="col-sm-1 col-2 float-right">
                    <a class="anchor" (click)="deleteInstruction(instruction.IngredientId)">
                      <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <form #formInstructions="ngForm" (ngSubmit)='updateInstruction(formInstructions.valid)'
            class="col-12 p-0 instruction-block__add-instruction border-top">
            <context-provider provide="props">
              <div class="pl-075em">

                <h3 class="setting-title"> {{editInstructionLabel}} </h3>
                <pos-row class="g-0">
                  <pos-column md="5" xs="12" class="ps-0">
                    <pos-form-field label="Inventory Product">
                      <ng-select posInput appendTo="body" [items]="inventoryProducts" bindLabel="Name" bindValue="Id"
                        placeholder="Select product" [(ngModel)]="areaInstruction.InventoryProduct"
                        name="inventoryProduct">
                      </ng-select>
                    </pos-form-field>
                  </pos-column>
                  <pos-column md="7" xs="12" class="p-0">
                    <pos-form-field label="Image">
                      <pos-row>
                        <pos-column xs="6" class="flex-grow-1">
                          <input posInput type="file" class="form-control" accept=".png,.jpg,.jpeg,.gif,.bmp"
                            #fileIngredient name="fileIngredient" id="fileIngredient"
                            (change)="handleFileInput($event.target.files)">
                        </pos-column>
                        <pos-column xs="2" class="col-75 position-relative">
                          <div class="instruction-block__add-instruction__img-wrap" [hidden]="!instructionImg">
                            <img class="instruction-block__add-instruction__img-wrap__img" src="{{instructionImg}}"
                              name="imageIngredient" id="imageIngredient" />
                          </div>
                          <div class="ps-0 z-index position-absolute" style="right: -3px;top: -12px;"
                            (click)="removeIcon()" [hidden]="!instructionImg">
                            <pos-icon [icon]="icons.times" class="pointer ms-pix-n7 remove-icon-badge"></pos-icon>
                          </div>
                        </pos-column>
                        <pos-column xs="4">
                          <button class="btn btn-secondary normal pos-button text-nowrap" (click)="openImageGallery()"
                            type="button" value="Choose">Icon Library</button>
                        </pos-column>
                      </pos-row>
                    </pos-form-field>
                  </pos-column>
                </pos-row>
                <pos-row class="g-0">
                  <pos-column class="pe-0">
                    <pos-form-field label="Instruction" [validationMessages]="{required: 'Please enter instruction.'}"
                      inputStyle="col-12">
                      <textarea posInput class="form-control" name="IngredientInstruction"
                        #IngredientInstruction="ngModel" rows="5" [(ngModel)]="areaInstruction.IngredientInstruction"
                        [required]="true"></textarea>
                    </pos-form-field>
                  </pos-column>
                </pos-row>
                <div class="col-12 mt-pix-10">
                  <button type="submit" value="Save" class="btn btn-primary float-end normal pos-button"
                    [disabled]="!areaInstruction.InstructionAreaId">{{areaInstruction.Id ? "Update" : "Add"}}</button>
                </div>
              </div>
            </context-provider>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="clearfix"></div>
  <div class="col-12 action-button-container popup-footer mti-10">
    <button class="btn btn-secondary me-pix-5 normal pos-button mb-1" (click)="openCopyInstructions(true)">
      Copy To
    </button>
    <button class="btn btn-secondary me-pix-5 normal pos-button mb-1" (click)="openCopyInstructions(false)">
      Copy From
    </button>
    <button class="btn btn-secondary me-pix-5 normal pos-button mb-1" (click)="preview()">Preview</button>
    <button class="btn btn-danger me-pix-5 normal pos-button mb-1" (click)="openExclusions()">Exclusions</button>
    <button class="btn btn-secondary pull-right btn-cancel-modal pos-button mb-1" (click)="onCancel()">Close</button>
  </div>
</pos-edit-wrapper>
