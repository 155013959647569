import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { AlertsService, SpinnerService } from 'src/app/shared';
import { EventService } from '../../services/event.service';
import { angleDoubleRight, angleDoubleLeft, times, search } from 'src/app/shared/components/icon';
import { cloneDeep, filter, forEach, groupBy } from 'lodash';
import { KeyValue } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Event } from '../../interfaces/event';
import { EventConfiguration } from '../../interfaces/event-configuration';

declare let $: any;
@Component({
  selector: 'pos-event-management',
  templateUrl: './event-management.component.html',
  styleUrls: ['./event-management.component.scss']
})
export class EventManagementComponent implements OnInit {
  isFullScreen = false;
  icons = { angleDoubleRight, angleDoubleLeft, times, search };
  searchEvent = '';
  selectedEventName = '';
  events;
  filteredEvents;
  selectedEventConfiguration: any;
  isShowScheduledEventsOnly = false;
  constructor(private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private route: ActivatedRoute,
    private eventService: EventService) { 
      this.isShowScheduledEventsOnly = route.snapshot.data.isScheduled ?? false;
    }

  ngOnInit(): void {
    this.getAllEventCategories();
  }

  getAllEventCategories = () => {
    this.spinnerService.show();
    this.eventService.getAllEventConfigurations()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({ next: (res: Array<Event>) => {
        res = filter(res, x => x.Configuration.IsScheduled == this.isShowScheduledEventsOnly);
        this.events = res ? groupBy(res, (event) => event.Configuration.Category) : [];
        this.filteredEvents = cloneDeep(this.events);
      }, error: this.alertService.showApiError });
  }

  selectEventConfiguration = (event) => {
    if (event && event.Configuration) {
      event.Configuration.EventAttributes = event.Configuration.EventAttributes ? event.Configuration.EventAttributes.sort() : [];
      this.selectedEventConfiguration = cloneDeep(event);
    }
  }

  getEventConfiguration = (eventConfiguration: EventConfiguration, isSilentReload: boolean = true) => {
    if (!isSilentReload) {
      this.spinnerService.show();
    }
    this.eventService.getEventConfigurations(eventConfiguration.EventIdentifier)
      .pipe(finalize(() => {
        if (!isSilentReload) {
          this.spinnerService.hide();
        }
      }))
      .subscribe({ next: (res: any) => {
        if (res) {
          forEach(this.events, (events) => {
            forEach(events, (event) => {
              if (eventConfiguration.EventIdentifier === event.Configuration.EventIdentifier) {
                event.Subscriptions = res.Subscriptions;
                event.Configuration = res.Configuration;
                event.IsActive = res.IsActive;
                event.Configuration.Schedule = eventConfiguration.Schedule;
                this.selectEventConfiguration(event);
              }
            });
          });
          this.filteredEvents = cloneDeep(this.events);
          this.search();
        }
      }, error: this.alertService.showApiError });

  }

  originalOrder = (firstKey: KeyValue<number, string>, secondKey: KeyValue<number, string>): number => {
    return 0;
  }

  search() {
    const eventTypes = [];
    this.filteredEvents = cloneDeep(this.events);
    forEach(this.filteredEvents, (value, key) => {
      forEach(value, (eventInfo) => {
        if (eventInfo.Configuration.AllowUserSubscription && (String(key).toLowerCase().includes(this.searchEvent.toLowerCase()) ||
          String(eventInfo.Configuration.Name).toLowerCase().includes(this.searchEvent.toLowerCase()))) {
          eventTypes.push(eventInfo);
        }
      });
    });
    this.filteredEvents = eventTypes ? groupBy(eventTypes, (event) => event.Configuration.Category) : [];
  }

  toggleFullScreen = () => {
    this.isFullScreen = !this.isFullScreen;
    if (this.isFullScreen) {
      $('.nav-pills').hide();
      // $('.tab-content').css('width', '100%');
    } else {

      $('.nav-pills').show();
      // $('.tab-content').css('width', '80%');
    }
  }
}
