<pos-edit-wrapper heading="Slide Show" [headingIcon]="icons.digitalSignageBullHorn" (close)="cancelEditing()"
  [isPopup]="false">
  <div class="col-xs-12 pos-page-body border-top">
    <div class="col-xs-12">
      <div>
        <h3 class="setting-title">Slide Show: {{ slideshowDetail.Name }}</h3>
      </div>
      <div class="row pt-pix-10 px-2">
        <div class="col-md-12 slide-show-image sortable-container display-flex flex-wrap" cdkDropListGroup>
          <div class="slide-show-image__block" cdkDropList (cdkDropListDropped)="ordinalChanged($event)"
            *ngFor="let slideshowImage of slideshowImages; let index = index" [cdkDropListData]="index">
            <div cdkDrag (cdkDragEntered)="dragEntered($event)" [cdkDragData]="index">
              <div class="slide-show-image__block__delete pointer" (click)="deleteImage(slideshowImage)">
                <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
              </div>
              <div class="slide-show-image__block__img-section">
                <img class="slide-show-image__block__img-section--img width-full"
                  *ngIf="slideshowImage.MediaType == 'image'"
                  src="{{slideshowImageBaseUrl + slideshowImage.ImagePath}}" />
                <pos-icon [icon]="icons.solidVideo" class="black" style="font-size: 30px"
                  *ngIf="slideshowImage.MediaType == 'video'"></pos-icon>
                <div class="slide-show-image__block__img-section--div" *ngIf="slideshowImage.MediaType == 'video'">
                  {{slideshowImage.FileNameWithText}}
                </div>
              </div>
              <a class="slide-show-image__block__action text-primary pointer"
                (click)="changeHoldSecond(slideshowImage)">
                <span class="bold">{{slideshowImage.HoldSeconds}}</span> seconds
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xs-12 section-border">
      <div>
        <h3 class="setting-title">Add Media</h3>
      </div>
      <form name="formImage" #formImage="ngForm" role="form">
        <pos-row class="px-2">
          <pos-column lg="3" md="3" xs="12">
            <pos-form-field label="Files" [fieldInfo]="fieldInfoMessages.DigitalSignageFileName" inputStyle="col-xs-12">
              <input posInput accept="{{getAll()}}" class="form-control" multiple="multiple" name="imageFiles"
                (change)="filesSelected($event)" type="file" #imageFiles [required]="true" />
            </pos-form-field>
          </pos-column>

          <pos-column lg="2" md="3" sm="6" xs="12">
            <pos-form-field label="Hold seconds" [fieldInfo]="fieldInfoMessages.DigitalSignageHoldSeconds"
              [validationMessages]="{required: 'Please enter hold seconds.', min: 'Please enter seconds greater than zero.'}">
              <input posInput class="form-control col-120" name="inputHoldSeconds" #inputHoldSeconds="ngModel"
                [(ngModel)]="slideshowImageToAdd.HoldSeconds" [required]="true" step="1" type="number" min="0" />
            </pos-form-field>
          </pos-column>

          <pos-column xs="2" class="pt-pix-30">
            <button class="btn btn-primary btn-lg white normal text-nowrap pos-button"
              (click)="addImage(slideshowImageToAdd, selectedFiles)" type="submit">Add File(s)</button>
          </pos-column>
        </pos-row>
      </form>
    </div>
  </div>
  <div class="col-xs-12 action-button-container page-footer">
    <button class="btn btn-secondary btn-lg btn-cancel pos-button" routerLink="../../" scrollup type="button"
      value="Cancel">Cancel</button>
  </div>
</pos-edit-wrapper>


<pos-confirm-delete (onConfirmAcknowledge)="onConfirmAcknowledgeSlide()" #confirmDeleteOptionSlide>
</pos-confirm-delete>
