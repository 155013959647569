import { Component, OnInit, Input, OnChanges, SimpleChanges, HostListener, Output, EventEmitter } from '@angular/core';
import { NumpadOptions } from '../../interface/numpad-options';
import * as _ from 'lodash';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { ApplicationStateService } from '../../services/application-state.service';
import { forEach } from 'lodash';
@Component({
  selector: 'pos-numpad',
  templateUrl: './numpad.component.html',
  styleUrls: ['./numpad.component.scss']
})
export class NumpadComponent implements OnInit, OnChanges {
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.numpadKeyPressed(event);
  }

  @Input() numpadId: number;
  @Input() options: NumpadOptions = { prefix: '' };
  @Input() value: any;
  @Input() onSubmit: any;
  @Input() onCancel: any;
  @Input() open: Function;
  @Input() addValue: Function;
  @Input() subValue: Function;
  formattedValue: any = 0;
  @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();
  showDecimal: boolean;
  isInitialize = false;
  validationErrors: any = [];
  oldValue: any;
  @Input() onOptionChange = new EventEmitter<any>();
  constructor(private applicationStateService: ApplicationStateService
  ) {
    this.value = 0;
    this.oldValue = 0;
    this.options.prefix = this.applicationStateService.settingParam.CurrencySymbol;
  }


  ngOnInit() {
    this.open = this.openFn.bind(this);
    this.addValue = this.addValueFn.bind(this);
    this.subValue = this.subValueFn.bind(this);
    if (this.numpadId === undefined) {
      throw new Error('numpad-id is required. Please specify unique identifier.');
    }

    if (this.options.allowDecimal === false) {
      this.options.noOfDecimalPlaces = 0;
    }

    if (!this.value) {
      this.value = parseFloat(this.formattedValue).toFixed(this.noOfDecimalPlaces(0));
      this.formateValue(this.value, null);
    }
    this.initialize();
    this.onOptionChange.subscribe(res => {
      if (res && res.options) {
        this.options = _.merge(this.options, res.options);
      }
      this.openFn(res && res.value ? res.value : null);
      if(res.value) {
        this.value = res.value;
        this.initialize(res.value);
        this.formateValue(this.value, null);
      }
    });
  }

  ngDoCheck() {
    if (this.valueChange) {
      this.valueChange.emit(this.value);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const defaultOptions: NumpadOptions = {
      deviceType: 'desktop',
      allowDecimal: true,
      noOfDecimalPlaces: 2,
      min: 0,
      max: Number.MAX_VALUE,
      doubleZero: true,
      prefix: '',
      disable: false,
      enterDisable: false,
      cancelButtonText: 'Cancel',
      startWithDecimal: true,
      allowLeadingZero: false,
      allowAlphabets: false,
      isDecrement: true,
      isIncrement: true,
      disableCancel: false,
      decrementErrorMessage: Messages.NumpadDecrementValidationError,
      enterButton: true,
      isReadOnly: false
    };
    defaultOptions.container = '#' + this.numpadId;
    this.options = _.merge(defaultOptions, this.options);
    const newVal = changes.value.currentValue;
    if (newVal || newVal === 0) {
      this.initialize(newVal);
      this.formateValue(newVal, null);
    }
  }

  openFn(value?) {
    this.initialize(value);
  }

  addValidationError(field, error) {
    if (!this.validationErrors[field]) {
      this.validationErrors[field] = [];
    }
    this.validationErrors[field].push(error);
  }

  validateValue = (value) => {
    const field = 'value';
    let isValid = true;
    this.validationErrors[field] = [];
    if (!this.options.allowAlphabets) {
      if (value < this.options.min) {
        this.addValidationError(field, this.options.minValidationMessage ? this.options.minValidationMessage : `Value must not be less than ${this.options.min}`);
        isValid = false;
      }
      if (value > this.options.max) {
        this.addValidationError(field, this.options.maxValidationMessage ? this.options.maxValidationMessage : `Value must not be greater than ${this.options.max}`);
        isValid = false;
      }
    }
    if (this.options.validationCallBack) {
      var response = this.options.validationCallBack({field: field, value: value});
      forEach(response, res => {
        this.addValidationError(res.field, res.message);
        isValid = false;
      })
    }
    if (isValid) {
      this.validationErrors = [];
    }
    return isValid;
  }

  triggerOnCancel = (reason) => {
    if (this.onCancel && typeof this.onCancel === 'function') {
      this.onCancel({ 'reason': reason });
    }
  }

  noOfDecimalPlaces(input) {
    if (!this.options.allowDecimal || (!this.showDecimal && (!this.options.startWithDecimal && input !== '.'))) {
      return 0;
    }
    return this.options.noOfDecimalPlaces;
  }

  initialize = (value?) => {
    this.isInitialize = true;
    if (!this.options.allowDecimal) {
      this.showDecimal = false;
    } else {
      if (value % 1 > 0) {
        this.showDecimal = true;
      } else {
        this.showDecimal = this.options.startWithDecimal;
      }
    }

    this.oldValue = this.value;
  }

  clearClicked(val) {
    if (!this.options.disable) {
      if (!this.options.allowDecimal) {
        this.value = 0;
        this.showDecimal = false;
      } else if (!val) {
        this.value = this.options.startWithDecimal ? ('0.' + '0'.repeat(this.options.noOfDecimalPlaces - 1)) : '';
        this.showDecimal = this.options.startWithDecimal;
      } else {
        this.value = val.toString();
        const isDecimalNumber = val.toString().indexOf('.') >= 0;
        if (this.options.startWithDecimal && !isDecimalNumber) {
          this.NumpadKeyClicked('.');
        } else if (isDecimalNumber) {
          this.showDecimal = true;
        }
      }
      if (this.options.allowLeadingZero) {
        this.isInitialize = true;
      }
    }
    this.formateValue(val, null);
    this.allowChange();
  }

  numpadKeyPressed = (e) => {
    if (!this.options.disable) {
      if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) {
        this.NumpadKeyClicked(e.key);
      } else if (e.keyCode === 110 || e.keyCode === 190) {
        if (this.options.allowDecimal) {
          this.NumpadKeyClicked('.');
        }
      }

      if (e.keyCode === 13 || e.which === 13) {
        this.EnterClicked();
      }
      if (e.keyCode === 8) {
        this.NumpadKeyClicked('delete');
      }
      if (this.options.allowAlphabets && (e.keyCode >= 65 && e.keyCode <= 90)) {
        this.NumpadKeyClicked(e.key);
      }
      e.preventDefault();
    }
  }

  NumpadKeyClicked(value) {
    let updatedValue;
    if (value == null || value === 'delete' || !this.options.maxLength || this.formattedValue.length < this.options.maxLength) {
      if (!this.options.disable) {
        this.handleInput(value);
        this.validateValue(this.addDecimal(this.value));
      }
    }
  }

  handleInput(input) {
    if (!this.options.disable) {
      if (this.isInitialize) {
        if (input !== 'delete') {
          this.clearClicked(0);
        }
        if (this.options.allowLeadingZero) {
          this.value = '';
        }
        this.isInitialize = false;
      }
      let val = this.value.toString();
      if (input === 10) {
        val += '0';
      } else if (input === 100) {
        val += '00';
      } else if (input === 'delete') {
        if (this.showDecimal) {
          if (this.value && this.options.noOfDecimalPlaces > 0) {
            val = parseFloat(this.value).toFixed(this.options.noOfDecimalPlaces);
          }
          if (val.split('.')[1] && val.split('.')[1].length === this.options.noOfDecimalPlaces) {
            val = val.substring(0, val.length - 1);
          } else if (val.split('.')[1] && val.split('.')[1].length < this.options.noOfDecimalPlaces) {
            if (val.split('.')[1] && val.split('.')[1].length === 0) {
              val = val + '0'.repeat(this.options.noOfDecimalPlaces - 1);
            } else {
              val = '.0' + val.replace('.', '');
            }
          }
          val = val.replace('.', '');
          val = val.slice(0, val.length - this.options.noOfDecimalPlaces) + '.' + val.slice(val.length - this.options.noOfDecimalPlaces);

          if (val.indexOf('.') === 0) {
            val = '0' + val;
          }
        } else if (!this.options.allowDecimal) {
          val = (val.toString()).substring(0, (val.toString()).length - 1);
          val = val === '' ? (this.options?.mask ? '' : '0') : val;
        } else if (val.replace('.', '').replace('0', '').length > 0) {
          val = val.substring(0, val.length - 1);
        }
        if (this.options.allowLeadingZero && val === '0') {
          this.isInitialize = true;
        }
      } else if (input === '.' && this.options.allowDecimal) {
        if (!this.showDecimal) {
          this.showDecimal = true;
          if (val.replace('0', '') !== '') {
            val += '.';
          } else {
            val = '.' + '0'.repeat(this.options.noOfDecimalPlaces - 1);
          }
        }
      } else {
        val += input.toString();
      }
      this.value = this.options.allowDecimal || this.options.allowLeadingZero ? this.addDecimal(val) : parseInt(this.addDecimal(val));

      this.formateValue(val, input);
      this.allowChange();
    }
  }

  formateValue(val, input) {
      if (this.options.allowLeadingZero) {
        this.formattedValue = (val == "" ? (this.options?.mask ? '' : '0') : val);
      }
      else {
        this.formattedValue = (this.value == '' ? (this.options?.mask ? '' : 0) : !isNaN(parseFloat(this.value)) ? parseFloat(this.value) : this.value);
        this.formattedValue = (!isNaN(parseFloat(this.formattedValue)) ? parseFloat(this.formattedValue).toFixed(this.noOfDecimalPlaces(input)) :
          this.formattedValue);
      }
  }

  allowChange() {
    const field = 'value';
    this.validationErrors[field] = [];
    if (this.value > this.oldValue && !this.options.isIncrement) {
      this.addValidationError(field, `Value must not be greater than ${this.oldValue}`);
    } else if (this.value < this.oldValue && !this.options.isDecrement) {
      this.addValidationError(field, this.options.decrementErrorMessage);
      // `Ordered quantity can not be decreased once the order is placed.`
    }
    else {
      this.validationErrors = [];
    }
  }

  CancelClicked() {
    if (!this.options.disableCancel) {
      this.triggerOnCancel('Cancel');
    }
  }

  EnterClicked() {
    if (!this.options.disable && !this.options.enterDisable && this.validateValue(this.value) && !(Object.entries(this.validationErrors).length > 0) && this.validateValueOnEnter(this.value)) {
      if (this.onSubmit && typeof this.onSubmit === 'function') {
        this.onSubmit({ 'value': this.value });
      }
    }
  }

  validateValueOnEnter(value): boolean {
    const field = 'value';
    let isValid = true;
    if (this.options.minLength > value?.length) {
      this.addValidationError(field, this.options.minValidationMessage ? this.options.minValidationMessage : `Value length must not be less than ${this.options.minLength}`);
      isValid = false;
      return false;
    }
    if (isValid) {
      this.validationErrors = [];
    }
    return isValid;
  }

  addDecimal(value) {
    if (this.options.allowDecimal) {
      const frags = value.split('.');
      if (frags.length > 1 && value.split('.')[1].length > this.options.noOfDecimalPlaces) {
        // tslint:disable-next-line: max-line-length
        value = frags[0] + frags[1].slice(0, frags[1].length - this.options.noOfDecimalPlaces) + '.' + frags[1].slice(frags[1].length - this.options.noOfDecimalPlaces);
      }
    }
    return value;
  }

  addValueFn(val) {
    if (!this.options.disable) {
      if (this.isInitialize) {
        this.value = val + '';
        this.showDecimal = false;
        this.isInitialize = false;
        if (this.options.startWithDecimal && this.options.allowDecimal) {
          this.handleInput('.');
        }
      } else {
        if (this.value.toString().split('.')[1].length !== this.options.noOfDecimalPlaces || (parseFloat(this.value) === 0)) {
          this.value = parseFloat(parseFloat(this.value) + val) + ((this.options.startWithDecimal && this.options.allowDecimal) ? '.' : '');
        } else {
          this.value = parseFloat(parseFloat(this.value) + val).toFixed(this.options.noOfDecimalPlaces);
        }
      }
      this.validateValue(this.addDecimal(this.value));
    }
    this.formateValue(val, null);
  }

  subValueFn(val) {
    if (!this.options.disable) {
      this.value = parseFloat(this.value) - val;
      this.validateValue(this.addDecimal(this.value));
    }
  }
}
