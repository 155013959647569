import { HttpClient } from '@angular/common/http';
import { InventoryProductAlias } from './../interface/inventory-product-alias';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class InventoryProductAliasService {

    constructor(private httpService: HttpClient) { }

    getInventoryProductAliases(inventoryProductId): Observable<any> {
        return this.httpService.get(
            `${RuntimeConstants.API_PATH}inventoryproducts/alias/` + inventoryProductId
        );
    }

    addAlias(inventoryProductAlias: InventoryProductAlias): Observable<any> {
        return this.httpService.post(
            `${RuntimeConstants.API_PATH}inventoryproducts/alias`,
            inventoryProductAlias
        );
    }

    deleteAlias(id: number): Observable<any> {
        return this.httpService.delete(
            `${RuntimeConstants.API_PATH}inventoryproducts/alias/` + id
        );
    }
}
