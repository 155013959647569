import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridColumn, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { cloneDeep } from 'lodash';
import { finalize } from 'rxjs';
import { StringUtils } from 'src/app/shared';
import { AuthenticationService } from 'src/app/shared/auth/services/authentication.service';
import { BaseListComponent, ConfirmDeleteModalComponent, ModalService, SpinnerService } from 'src/app/shared/components';
import { ban, check, checkCircleWhite, editWhite, plusWhite, warehouseAlt, barsWhite, cogs } from 'src/app/shared/components/icon/icons';
import { Levels, Messages, Permissions } from 'src/app/shared/constants';
import { AlertsService, ApplicationStateService, ExportService, PrintTableService } from 'src/app/shared/services';
import { InventoryOffSiteLocation } from '../../interfaces';
import { InventoryOffSiteLocationService } from '../../services';
import { InventoryOffSiteLocationEditComponent } from '../inventory-off-site-location-edit';

@Component({
  selector: 'pos-inventory-off-site-locations',
  templateUrl: './inventory-off-site-locations.component.html'
})
export class InventoryOffSiteLocationsComponent extends BaseListComponent<InventoryOffSiteLocation> implements OnInit {
  locationsColumns: Array<GridColumn> = [];
  printLocationsColumns: Array<GridColumn> = [];
  offSiteLocations: Array<InventoryOffSiteLocation> = [];
  filteredOffSiteLocations: Array<InventoryOffSiteLocation> = [];
  showActive = true;

  @ViewChild('activeTemplate', { static: true }) activeTemplate: TemplateRef<any>;
  @ViewChild('operationTemplate', { static: true }) private operationTemplate: TemplateRef<any>;
  @ViewChild('headerTemplate', { static: true }) private headerTemplate: TemplateRef<any>;

  icons = {
    check, plusWhite, editWhite, ban, checkCircleWhite, warehouseAlt, barsWhite, cogs
  };
  permission = {
    name: Permissions.OffSiteInventory,
    editLevel: Levels.Edit
  };
  offsiteLocation: InventoryOffSiteLocation = null;

  constructor(protected inventoryOffSiteLocationService: InventoryOffSiteLocationService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected applicationStateService: ApplicationStateService,
    private authenticationService: AuthenticationService,
    protected printService: PrintTableService,
    private exportService: ExportService,
    private router: Router,
    protected route: ActivatedRoute) {
    super(inventoryOffSiteLocationService, alertService, spinnerService, modalService, applicationStateService, printService, route);
  }

  ngOnInit(): void {
    this.locationsColumns = this.configureGridColumns();
    this.data$.subscribe({
      next: (res) => {
        this.offSiteLocations = res;
        this.filteredOffSiteLocations = this.showActive ? [...this.offSiteLocations.filter(x => x.IsActive)] : [...this.offSiteLocations];
      }
    });
  }
  getGridColumns(): GridColumn[] {
    return this.locationsColumns;
  }

  configureGridColumns(): GridColumn[] {
    const activeColumn = new TemplateColumn({
      HeaderText: 'Active',
      itemTemplate: this.activeTemplate,
      TextAlign: TextAlign.Center,
      Width: '110px',
      Field: 'IsActive',
      IsSortable: true,
    });

    const operationColumn = new TemplateColumn({
      itemTemplate: this.operationTemplate,
      Width: '147px',
      headerTemplate: this.headerTemplate,
      CellClass: 'cell-padding',
      TextAlign: TextAlign.Center
    });

    this.locationsColumns = [
      new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '95%' }),
      activeColumn
    ];

    this.printLocationsColumns = [...this.locationsColumns];

    if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      this.locationsColumns.push(operationColumn);
    }

    return this.locationsColumns;
  }

  editItem(id: number, data?: InventoryOffSiteLocation): void {
    const modalRef = this.modalService.getModalWrapper(InventoryOffSiteLocationEditComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      initialState: {
        inventoryOffSiteLocation: cloneDeep(data)
      }
    });
    modal.close.subscribe(res => {
      if (res && res.shouldReload) {
        this.reload();
      }
    });
  }

  getConfirmDeleteMessage(data: InventoryOffSiteLocation): string {
    throw new Error('Method not implemented.');
  }

  changeActiveStatus(data: InventoryOffSiteLocation, isActive: boolean) {
    if (!isActive) {
      const modalRef = this.modalService.getModalWrapper(ConfirmDeleteModalComponent);
      const modal = modalRef.show({
        animated: false,
        class: 'vertical-center',
        initialState: {
          message: StringUtils.format(Messages.ConfirmDeleteMappedOffsiteProducts, { offsiteLocation: data.Name })
        }
      });

      modal.close.subscribe(res => {
        if (res?.shouldDelete) {
          this.saveActivityStatus(data, isActive);
        }
      })
    } else {
      this.saveActivityStatus(data, isActive);
    }
  }

  saveActivityStatus(data: InventoryOffSiteLocation, isActive: boolean) {
    this.spinnerService.show();
    data.IsActive = isActive;
    this.inventoryOffSiteLocationService.changeActiveStatus(data)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.alertService.renderSuccessMessage(StringUtils.format(Messages.OffSiteLocationStatusChange, { activeMessage: (isActive ? 'activated' : 'deactivated') }));
          this.reload();
        }, error: this.alertService.showApiError
      });
  }

  protected printData() {
    this.printService.printEmitter.next({ gridColumns: this.printLocationsColumns, gridData: this.filteredOffSiteLocations });
  }

  exportCSV() {
    this.exportService.exportCSV('off-site-locations', this.filteredOffSiteLocations, null, this.printLocationsColumns);
  }

  changeLocationList() {
    if (this.showActive) {
      this.filteredOffSiteLocations = [...this.offSiteLocations.filter(x => x.IsActive)];
    } else {
      this.filteredOffSiteLocations = [...this.offSiteLocations];
    }
  }

  openInventorySettings(offsiteLocation: InventoryOffSiteLocation) {
    this.router.navigate([offsiteLocation.Id, 'inventory-settings'], { relativeTo: this.route })
  }

  onLinkPopOver(offsiteLocation: InventoryOffSiteLocation) {
    this.offsiteLocation = offsiteLocation;
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
