import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OrderNavigationButton } from 'src/app/orders/interface/order-navigation-button';
import { ModalFormComponent, ModalService, SpinnerService } from 'src/app/shared/components';
import { ButtonBehavior } from 'src/app/shared/interface/button-behavior';
import { AlertsService, ButtonFunctionService, FormUtilityService } from 'src/app/shared/services';

@Component({
  selector: 'pos-flash-button',
  templateUrl: './flash-button.component.html',
  styleUrls: ['./flash-button.component.scss']
})
export class FlashButtonComponent extends ModalFormComponent implements OnInit {

  @Input() selectedButtonBehaviorType;
  @Input() buttonBehavior: ButtonBehavior = null;
  @Input() buttons = [];
  @Input() screenToggleButtons: Array<OrderNavigationButton>;
  @ViewChild('uncheckButtonForm') uncheckButtonForm: NgForm;

  buttonIds: Array<number> = [];
  seconds: number = null;
  get getForm(): NgForm {
    return this.uncheckButtonForm;
  }
  constructor(protected spinnerService: SpinnerService,
    protected alertsService: AlertsService,
    protected buttonFunctionService: ButtonFunctionService,
    modalRef: BsModalRef, modalService: ModalService, protected formUtilityService: FormUtilityService) {
      super(modalRef, modalService, formUtilityService);
    }

  ngOnInit(): void {
    if (this.buttonBehavior && this.buttonBehavior.Id) {
      try {
        const behavior = JSON.parse(this.buttonBehavior.TextValue);
        this.buttonIds = behavior && behavior.buttonIds ? behavior.buttonIds : null;
        this.seconds = behavior && behavior.seconds ? behavior.seconds : null;
        const notMappedButtonIds = this.buttonFunctionService.getAllSelectedButtonInList(this.screenToggleButtons, this.buttonIds);
        if (notMappedButtonIds)  {
          this.buttonFunctionService.addButtonInBindList(this.screenToggleButtons, this.buttons, notMappedButtonIds);
        }
        this.initialValues();
      } catch (ex) {
        console.error('Invalid behavior value: ' + this.buttonBehavior.TextValue);
      }
    }
   
  }
  

  saveBehavior = (isValid: boolean) => {
    if (isValid && this.buttonIds.length) {
      this.hide({ shouldReload: true, Value: { buttonIds: this.buttonIds, seconds: this.seconds } });
    }
  }

  closeModal = () => {
    this.hide({ shouldReload: false });
  }

}
