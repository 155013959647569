<pos-edit-wrapper (close)="onCancel(false, null)" heading="Product">
    <form #categoryProductForm="ngForm" (ngSubmit)="saveCategoryProduct(categoryProductForm.valid)" autocomplete="off">
        <div class="col-12 pos-modal-body screen-scroll">
            <pos-row>
                <pos-column>
                    <pos-form-field label="Button Text" [validationMessages]="{required: 'Please enter button text.'}">
                        <input posInput class="form-control" name="buttonText" #buttonText="ngModel"
                            [(ngModel)]="buttonDetails.ButtonText" type="text" [required]="true" />
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Link to Button">
                        <ng-select posInput #salesProduct name="salesProduct" [items]="salesProducts" bindLabel="Name"
                            bindValue="id" [(ngModel)]="buttonDetails.SalesProductId"
                            placeholder="Select linked button">
                        </ng-select>
                    </pos-form-field>
                </pos-column>
                <pos-column *ngIf="categorySectionList.length > 0">
                    <pos-form-field label="Section">
                        <ng-select posInput #categorySection name="categorySection" [items]="categorySectionList"
                            bindLabel="Name" bindValue="Id" [(ngModel)]="buttonDetails.SectionId"
                            placeholder="Select section">
                        </ng-select>
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Icon" inputStyle="col-12">
                        <pos-row>
                            <pos-column md="6" sm="7"
                                [ngClass]="{'has-error': buttonDetails.hasImageResolutionError && buttonDetails.Icon}">
                                <input posInput type="file" id="buttonIcon" class="form-control"
                                    accept=".png,.jpg,.jpeg,.gif,.bmp"
                                    (change)="handleFileInput($event.target.files)" />
                            </pos-column>
                            <pos-column lg="3" [hidden]="!isChangeImage">
                                <img class="ps-pix-5 col-40" #buttonImage id="buttonImage"
                                    [hidden]="!buttonDetails.Icon" alt=""/>
                                <pos-icon [icon]="icons.times" class="pointer mt-pix-n15 remove-icon-badge"
                                    (click)="removeIcon()" [hidden]="!buttonDetails.Icon">
                                </pos-icon>
                            </pos-column>
                            <pos-column lg="6" sm="4" class="mt-pix-7"
                                *ngIf="buttonId > 0 && !isChangeImage && buttonIconName">
                                <span class="edit-form__section__label ps-pix-5">{{buttonIconName}}</span>
                                <span class="ps-pix-5 mt-pix-n4" (click)="removeIcon()">
                                    <pos-icon [icon]="icons.times" class="pointer mt-pix-n7 remove-icon-badge"></pos-icon>
                                </span>
                            </pos-column>
                            <pos-column xs="12" class="p-0">
                                <span *ngIf="buttonDetails.hasImageResolutionError && buttonDetails.Icon">
                                    <label class="red-extreme">{{resolutionWarningMessage}}</label>
                                </span>
                            </pos-column>
                        </pos-row>
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Background Color">
                        <pos-color-picker posInput name="backgroundColor" [(ngModel)]="buttonDetails.BackgroundColor">
                        </pos-color-picker>
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Text Color">
                        <pos-color-picker posInput name="textColor" [(ngModel)]="buttonDetails.TextColor" cpPosition="top">
                        </pos-color-picker>
                    </pos-form-field>
                </pos-column>
                <pos-column *ngIf="!openFromCategory">
                    <pos-form-field>
                        <p-checkbox posInput name="chkToggle" #chkToggle="ngModel" [(ngModel)]="buttonDetails.IsToggle"
                            binary="true" [disabled]="buttonDetails.Id > 0 || isToggle != null" label="Toggle">
                        </p-checkbox>
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field>
                        <p-checkbox posInput class="chk-section__input" name="featured" #featured="ngModel"
                            [(ngModel)]="buttonDetails.IsFeatured" binary="true" label="Featured Product">
                        </p-checkbox>
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field>
                        <p-checkbox posInput class="chk-section__input" name="mobile" #mobile="ngModel"
                            [(ngModel)]="buttonDetails.IsMobile" binary="true" label="Mobile only">
                        </p-checkbox>
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field>
                        <p-checkbox posInput class="chk-section__input" name="active" #active="ngModel"
                            [(ngModel)]="buttonDetails.IsActive" binary="true" label="Visible">
                        </p-checkbox>
                    </pos-form-field>
                </pos-column>
            </pos-row>


        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" scrollup>Save </button>
            <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button"
                (click)="onCancel(false, null)">Close</button>
        </div>
    </form>
    <div class="clearfix"></div>
</pos-edit-wrapper>
