import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductLossReason } from 'src/app/information-management';
import { RuntimeConstants } from 'src/app/shared';
import { InventoryLoss } from '../interface/inventory-loss';

@Injectable({
  providedIn: 'root'
})
export class InventoryLossService {

  inventoryLossApiPath = `${RuntimeConstants.API_PATH}inventoryproductloss`;

  constructor(private httpClient: HttpClient) { }

  newInventoryLoss() {
    const inventoryLoss: InventoryLoss = {
      id: 0,
      InventoryProductId: null,
      SalesProductId: null,
      SizeId: null,
      UserId: null,
      QtyLoss: null,
      Note: null,
      LossReasonId: null,
    };
    return inventoryLoss;
  }

  getProductLossReasons(): Observable<Array<ProductLossReason>> {
    return this.httpClient.get<Array<ProductLossReason>>(this.inventoryLossApiPath);
  }

  saveInventoryProductLoss(inventoryLoss: InventoryLoss): Observable<InventoryLoss> {
    return this.httpClient.post<InventoryLoss>(this.inventoryLossApiPath, inventoryLoss);
  }
}
