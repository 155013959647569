<pos-edit-wrapper heading="Information {{productInfo.ButtonText ? ': ' + productInfo.ButtonText : '' }}"
  (close)="closeModal()">
  <div class="col-12 container-fluid row mx-0">
    <div class="col-md-3 col-4 text-align-center" *ngIf="productInfo.Image">
      <img class="pointer" height="100" src="{{imagePath}}/buttons/{{productInfo.Image ||''}}" alt="" />
    </div>
    <div class="col-md-5 col-8 mt-pix-7 bold pt-pix-20" [ngClass]="{'pt-pix-10': !productInfo.Image}">
      <div class="col-12 p-0 font-20">{{productInfo.ButtonText}}</div>
      <div class="col-12 p-0">Price:
        {{productInfo.Price+ manualPriceAdjust | tark_currency}}</div>
    </div>
    <div class="col-md-4 col-12 p-0 pt-pix-10 float-end">
      <div class="btn-group float-end">
        <button class="btn bg-theme-separator me-pix-2 pos-button" (click)="sendHighlightProductRequest('Highlight')">
          Highlight on Menu Display</button>

        <button type="button" class="btn bg-theme-separator dropdown-toggle pos-button" data-bs-toggle="dropdown">
          <span class="caret"></span>
          <span class="sr-only">Highlight</span>
        </button>

        <ul class="dropdown-menu" role="menu">
          <li class="dropdown-item"><a class="pointer" (click)="sendHighlightProductRequest(menuInteractivityActionType.Highlight)">Highlight</a></li>
          <li class="dropdown-item"><a class="pointer" (click)="sendHighlightProductRequest(menuInteractivityActionType.Flash)">Flash</a></li>
          <li class="dropdown-item"><a class="pointer" (click)="sendHighlightProductRequest(menuInteractivityActionType.HideExclusions)">Hide Exclusions</a></li>
        </ul>
      </div>
    </div>
  </div>

  <div class="col-12 container-fluid ">
    <ul class="nav nav-tabs inner-tab pos-nav-tabs">
      <li [ngClass]="{'active': selectedTab == tabList.ProductInformation }">
        <a (click)="selectedTab = tabList.ProductInformation" class="blue">
          <pos-icon [icon]="icons.infoCircle" class="pe-pix-5 theme-separator"></pos-icon>Information
        </a>
      </li>
      <li [ngClass]="{'active': selectedTab == tabList.ProductRecipe }">
        <a (click)="selectedTab = tabList.ProductRecipe" class="blue">
          <pos-icon [icon]="icons.listAlt" class="pe-pix-5 theme-separator"></pos-icon>
          Recipe
        </a>
      </li>
      <li [ngClass]="{'active': selectedTab == tabList.ProductIngredients }">
        <a (click)="selectedTab = tabList.ProductIngredients" class="blue">
          <pos-icon [icon]="icons.boxes" class="pe-pix-5 theme-separator"></pos-icon>
          Ingredients
        </a>
      </li>
      <li [ngClass]="{'active': selectedTab == tabList.ProductDietaryWarnings }">
        <a (click)="selectedTab = tabList.ProductDietaryWarnings" class="blue">
          <pos-icon [icon]="icons.exclamationTriangle" class="pe-pix-5 theme-separator"></pos-icon>
          Dietary Warnings
        </a>
      </li>
      <li [ngClass]="{'active': selectedTab == tabList.Tags }">
        <a (click)="selectedTab = tabList.Tags" class="blue">
          <pos-icon [icon]="icons.tag" class="pe-pix-5 theme-separator"></pos-icon>Tags
        </a>
      </li>
      <li [ngClass]="{'active': selectedTab == tabList.ProductInventory }">
        <a (click)="selectedTab = tabList.ProductInventory" class="blue">
          <pos-icon [icon]="icons.orderInfoInventoryCheckDouble" class="pe-pix-5 theme-separator"></pos-icon>Inventory
        </a>
      </li>
    </ul>
    <div class="tab-content ignore-tabcontent-space pos-tab-body" style="width:100%">

      <div *ngIf="selectedTab == tabList.ProductInformation">
        <div class="col-12">
          <div class=" row mx-0" [ngStyle]="{'height':pageHeight + 'px'}">
            <div class="col-md-9 col-6 ps-0 mt-pix-15">
              <div class="col-12 p-0 bold"> Description</div>
              <p [hidden]="productInfo.Information ==='' ">{{productInfo.Information}}</p>
              <p [hidden]="productInfo.Information !=='' ">No description provided</p>
            </div>
            <div class="col-md-3 col-6 p-0 mt-pix-15" *ngIf="salesSizesWithPrices.length > 0">
              <div class="col-12 p-0 text-align-center bold mb-pix-10">
                Nutrition Facts
              </div>
              <div *ngFor="let size of salesSizesWithPrices" class="col-12 p-0 mb-pix-10">
                <div class="col-12 p-pix-2">
                  <button style="color:white;"
                    class="btn width-full  bg-theme-separator information-modal-size pos-button" data-toggle="modal"
                    (click)="printNutritionFactInfo(size.SizeId)">
                    <span class="float-start text-ellipsis information-modal-size__name">
                      <pos-icon [icon]="icons.nutritionPrint" class="pe-pix-5 information-modal-size__name">
                      </pos-icon>
                      {{size.Name}}
                    </span>
                    <span class="float-end text-ellipsis information-modal-size__price">
                      <div *ngIf="!productInfo.IsDefault">{{size.Price + manualPriceAdjust | tark_currency}}
                      </div>
                      <div *ngIf="productInfo.IsDefault">{{productInfo.large_total_price | tark_currency}}</div>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="selectedTab == tabList.ProductRecipe">
        <div class="col-12">
          <div [ngStyle]="{'height':pageHeight+ 'px'}">
            <pos-order-entry-recipe-info [productId]="productInfo.SalesProductId"></pos-order-entry-recipe-info>
          </div>
        </div>
      </div>

      <div *ngIf="selectedTab == tabList.ProductIngredients">
        <div class="col-12 pt-pix-5">
          <div [ngStyle]="{'height':pageHeight + 'px'}">
            <pos-ingredients-info [productId]="productInfo.SalesProductId"></pos-ingredients-info>
          </div>
        </div>
      </div>

      <div *ngIf="selectedTab == tabList.ProductDietaryWarnings">
        <div class="col-12 pt-pix-5">
          <div [ngStyle]="{'height':pageHeight +'px'}">
            <pos-ingredients-dietary-warnings [productId]="productInfo.SalesProductId">
            </pos-ingredients-dietary-warnings>
          </div>
        </div>
      </div>

      <div *ngIf="selectedTab == tabList.ProductInventory">
        <div class="col-12">
          <div [ngStyle]="{'height':pageHeight+ 'px'}">
            <div class="col-12 p-0 mt-pix-5 table-responsive">
              <pos-order-entry-inventory-info [productId]="productInfo.SalesProductId">
              </pos-order-entry-inventory-info>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="selectedTab == tabList.Tags">
        <div class="col-12 pt-pix-5">
          <div [ngStyle]="{'height': pageHeight + 'px'}">
            <div class="display-flex flex-flow-wrap" *ngIf="tags.length > 0">
              <span *ngFor="let tag of tags">
                <p-chip [label]="tag.Name" class="pe-pix-5" image="{{imagePath}}/tags/{{tag.Icon}}" *ngIf="tag.Icon"
                  [style]="{'background-color': tag.Color, 'color': getContrastColor(tag.Color)}"></p-chip>
                <p-chip [label]="tag.Name" class="pe-pix-5" *ngIf="!tag.Icon"
                  [style]="{'background-color': tag.Color, 'color': getContrastColor(tag.Color)}"></p-chip>
              </span>
            </div>
            <div class="pt-pix-10" *ngIf="!tags.length">
              <p>No tags are linked with this product.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 action-button-container popup-footer">
    <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="closeModal()" type="button">
      Close
    </button>
  </div>
  <div class="clearfix"></div>
</pos-edit-wrapper>
