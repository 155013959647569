<pos-edit-wrapper (close)="onCancel()" [isPopup]="false" [headingIcon]="icons.inventoryRawIngredient"
  heading="Raw Ingredient">
  <form #rawIngredientForm="ngForm" (ngSubmit)="saveRawIngredient(rawIngredientForm.valid)" autocomplete="off">
    <div class="col-12 pos-page-body border-top">
      <div class="col-12 pt-pix-5">
        <pos-row>
          <pos-column>
            <pos-form-field label="Name" [validationMessages]="{required: 'Please enter raw ingredient name.'}"
              inputStyle="col-lg-4 col-md-4 col-sm-4 col-xs-8">
              <input posInput class="form-control" name="name" #name="ngModel" [(ngModel)]=rawIngredientData.Name
                [required]="true">
            </pos-form-field>
          </pos-column>
        </pos-row>
      </div>
    </div>
    <div class="col-12 action-button-container page-footer">
      <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save </button>
      <button type="button" class="btn-secondary  btn-lg btn btn-cancel pos-button" (click)="onCancel()">Cancel</button>
    </div>
  </form>
</pos-edit-wrapper>
