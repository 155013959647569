import {
    reply, squareLightYellowOrangeStack, composePlusStack, markAllCircleStack, markAllCheckStack,
    envelopeStack, squareWhiteGreyStack, envelopeOpenStack, composeEnvelopeStack, composeCircleStack, composeEnvelopeOpenStack
} from '../../../shared/components/icon/icons';
import { MessageTypes } from '../../../shared/services/rabbitmq/constant/RabbitMQMessageConstants';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { InboxMessage } from '../../interface';
import { InboxMessagesEditComponent } from '../inbox-messages-edit';
import { InboxMessageService } from '../../services';
import { ViewMessageComponent } from '../view-message/view-message.component';
import { OrderEventBroadcastingService } from 'src/app/shared/services/order-event-broadcasting.service';
import { ICloseable, ModalService } from 'src/app/shared/components/modal';
import { SpinnerService } from 'src/app/shared/components/spinner';
import { Subscription } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { filter, finalize } from 'rxjs/operators';
import { SlicePipe } from '@angular/common';
import { FilterConfiguration } from 'src/app/shared/interface/filter-configuration';
import { RabbitMQService } from 'src/app/shared/services/rabbitmq/services/rabbitmq.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { findIndex, forEach } from 'lodash';
import { TemplateColumn } from '@tarktech/tark-ng-utils/table/template-column';
import { TextAlign } from '@tarktech/tark-ng-utils/table/text-align';
import { GridColumn } from '@tarktech/tark-ng-utils';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'pos-inbox-messages-list',
    templateUrl: './inbox-messages-list.component.html',
    styleUrls: ['./inbox-messages-list.component.scss']
})
export class InboxMessagesListComponent implements OnInit, ICloseable, OnDestroy {
    messageMarkAsReadSubscription: Subscription;
    @Output() inboxMessage = new EventEmitter<number>();
    @Input() height = 500;
    showUnreadMessages = true;
    inboxMessageColumns: Array<GridColumn>;
    close: EventEmitter<any> = new EventEmitter();
    inboxMessageList: Array<InboxMessage> = [];
    terminalMessageModel: any;
    afterInit = false;
    public icons = {
        reply,
        squareLightYellowOrangeStack,
        envelopeStack,
        envelopeOpenStack,
        squareWhiteGreyStack,
        composeEnvelopeStack,
        composeCircleStack,
        composeEnvelopeOpenStack,
        composePlusStack,
        markAllCircleStack,
        markAllCheckStack
    };
    userId: number;
    filterConfiguration: FilterConfiguration = {
        PageNumber: 1, PageSize: 8,
        SortField: 'DateAdded', SortOrder: -1, FilterValue: null
    };
    totalRecords = 1;
    @ViewChild('sendToTemplate', { static: true }) sendToTemplate: TemplateRef<any>;
    @ViewChild('messageTextTemplate', { static: true }) messageTextTemplate: TemplateRef<any>;
    @ViewChild('dateTemplate', { static: true }) dateTemplate: TemplateRef<any>;
    @ViewChild('actionTemplate', { static: true }) action: TemplateRef<any>;
    constructor(
        private inboxMessageService: InboxMessageService,
        private rabbitMQService: RabbitMQService,
        private spinnerService: SpinnerService,
        private alertService: AlertsService,
        private modalService: ModalService,
        private slicePipe: SlicePipe,
        private applicationStateService: ApplicationStateService,
        private eventBroadcastingService: OrderEventBroadcastingService
    ) {
        const userDetails = this.applicationStateService.userDetails;
        this.userId = userDetails.id;
        this.terminalMessageModel = {
            SenderId: '',
            RecipientTerminalId: '',
            RecipientUserId: '',
            MessageText: '',
            FromEmailId: '',
            RecipientEmailId: ''
        };
        this.terminalMessageModel.FromUserId = this.userId;
        this.terminalMessageModel.FromTerminalId = this.applicationStateService.terminalId;
    }

    ngOnInit() {
        this.loadData();
    }
    ngOnDestroy() {
    }

    loadData() {
        this.terminalMessageModel.RecipientUserId = this.userId;
        this.terminalMessageModel.RecipientTerminalId = this.applicationStateService.terminalId;
        this.terminalMessageModel.IsViewed = !this.showUnreadMessages;
        this.configureGridColumns();
        this.subscribeRabbitMQ();
        this.loadMessageCount();
    }
    subscribeRabbitMQ = () => {
        const self = this;
        this.messageMarkAsReadSubscription = this.rabbitMQService.subscribeToTerminalMessageMarkAsReadMessage$()
            .subscribe({
                next: (message: any) => {
                    if (message.Payload.MessageInfo) {
                        let msgIndex = findIndex(self.inboxMessageList, (msg) => {
                            return msg.Id == message.Payload.MessageInfo.Id
                        });
                        if (msgIndex != -1) {
                            self.inboxMessageList.splice(msgIndex, 1);
                        }
                        if (!self.inboxMessageList.length) {
                            self.eventBroadcastingService.inboxMessage.emit(false);
                        }
                    }
                }, error: () => {
                    console.log("Error occurred while subscribing for terminal messages.");
                }
            });
    }


    getMessageDetails(isSilentCall = false) {
        if (!isSilentCall) {
            this.spinnerService.show();
        }
        this.terminalMessageModel.IsViewed = !this.showUnreadMessages;
        this.inboxMessageService.getMessageDetails(this.terminalMessageModel, this.filterConfiguration).subscribe(
            res => {
                this.inboxMessageList = [];
                forEach(res.MailMessageModel, (message) => {
                    const inboxMessage = message;
                    inboxMessage.RowClass = inboxMessage.IsViewed ? 'read-message' : 'unread-message';
                    this.inboxMessageList.push(inboxMessage);
                });
                if (!this.inboxMessageList.length) {
                    this.eventBroadcastingService.inboxMessage.emit(false);
                }
            },
            this.alertService.showApiError,
            () => {
                if (!isSilentCall) {
                    this.spinnerService.hide();
                }
            }
        );
    }

    sliceMessage(message) {
        if (message.length > 40) {
            const messageArray = [...this.slicePipe.transform(message, 0, 40)];
            const shortMessage = messageArray.splice(0, messageArray.length - 1);
            let finalMessage = '';
            shortMessage.forEach((data) => {
                finalMessage = finalMessage + data;
            });
            finalMessage = finalMessage + '...';
            return finalMessage;
        }
        return message;
    }

    configureGridColumns() {
        this.inboxMessageColumns = [
            new TemplateColumn({
                HeaderText: 'Sender',
                Field: 'Sender',
                Width: '27%',
                IsSortable: true,
                itemTemplate: this.sendToTemplate,
            }),
            new TemplateColumn({
                HeaderText: 'Message Text',
                Field: 'MessageText',
                Width: '40%',
                IsSortable: true,
                itemTemplate: this.messageTextTemplate,
            }),
            new TemplateColumn({
                HeaderText: 'Date & Time',
                Field: 'DateAdded',
                Width: '23%',
                IsSortable: true,
                itemTemplate: this.dateTemplate,
            }),
            new TemplateColumn({
                Width: '82px',
                itemTemplate: this.action,
                TextAlign: TextAlign.Center
            })
        ];
    }

    replyMessage(data) {
        const inboxMessageModalRef = this.modalService.show(InboxMessagesEditComponent, {
            animated: false,
            keyboard: true,
            backdrop: true,
            ignoreBackdropClick: false,
            class: 'vertical-center modal-lg',
            initialState: {
                inboxMessageList: this.inboxMessageList,
                inboxMessage: data,
            }
        });
        inboxMessageModalRef.close.subscribe(res => {
            if (res && res.shouldReload) {
                this.loadMessageCount();
            }
        });

    }
    markAsRead(data: any) {
        this.spinnerService.show();
        this.terminalMessageModel.Id = data.Id;
        this.inboxMessageService.markAsRead(this.terminalMessageModel).subscribe(
            res => {
                this.loadMessageCount(false, true);
            },
            this.alertService.showApiError,
            () => {
                this.spinnerService.hide();
            }
        );
    }

    markAsUnRead(data: any) {
        this.spinnerService.show();
        this.inboxMessageService.markAsUnread(data).pipe(finalize(() => {
            this.spinnerService.hide();
        })).subscribe({
            next: (response) => {
                this.loadMessageCount();
            }
        });
    }

    showMessage(data) {
        const modalRef = this.modalService.getModalWrapper(ViewMessageComponent);
        const modal = modalRef.show({
            animated: false,
            backdrop: 'static',
            keyboard: false,
            class: 'vertical-center modal-lg',
            initialState: {
                terminalMessageModel: data,
                isRequiredMarkAsRead: true
            }
        });

        modal.close.subscribe(res => {
            if (res && res.reload) {
                this.loadMessageCount();
            }
        });
    }

    onChangeReadMessage() {
        this.filterConfiguration = {
            PageNumber: 1, PageSize: 8,
            SortField: 'DateAdded', SortOrder: -1, FilterValue: null
        };
        this.spinnerService.show();
        this.inboxMessageService.getInboxMessageCount(this.terminalMessageModel).pipe(finalize(() => {
            this.spinnerService.hide();
        })).subscribe({
            next: (response) => {
                this.totalRecords = response;
                this.loadMessageCount(!this.showUnreadMessages);
            }
        });
    }

    markAllAsRead() {
        this.spinnerService.show();
        this.inboxMessageService.markAllAsRead(this.terminalMessageModel)
            .subscribe({
                next: res => {
                    this.loadMessageCount();
                }, error: this.alertService.showApiError,
                complete: () => {
                    this.spinnerService.hide();
                }
            });
    }

    lazyLoad(event) {
        if (this.afterInit) {
            this.filterConfiguration.PageSize = 8;
            this.filterConfiguration.PageNumber = Math.ceil((event.first + 1) / 8);
            this.filterConfiguration.SortField = event.sortField;
            this.filterConfiguration.SortOrder = event.sortOrder;
            this.filterConfiguration.FilterValue = event.globalFilter;
            this.getMessageDetails();
        }
        this.afterInit = true;
    }

    loadMessageCount(readMessages = false, isSilentCall: boolean = false) {
        this.terminalMessageModel.IsViewed = readMessages;
        this.spinnerService.show();
        this.inboxMessageService.getInboxMessageCount(this.terminalMessageModel).pipe(finalize(() => {
            this.spinnerService.hide();
        })).subscribe({
            next: (response) => {
                if (this.showUnreadMessages !== readMessages) {
                    this.totalRecords = response;
                }
                if (!this.terminalMessageModel.IsViewed) {
                    this.inboxMessage.emit(response);
                }
                this.getMessageDetails(isSilentCall);
            }, error: this.alertService.showApiError
        });
    }
}
