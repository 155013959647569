import { Messages } from './../../../../shared/constants/ui-messages';
import { AlertsService } from 'src/app/shared/services';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { GridColumn, EditColumn, DeleteColumn, DateColumn, LinkColumn } from '@tarktech/tark-ng-utils';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalComponent, ConfirmDeleteComponent, ModalBackdropService, ModalService, ICloseable } from 'src/app/shared';
import { UserTrainingNotesService } from '../../services';
import * as _ from 'lodash';
import { UserTrainingNoteEditComponent } from '../user-training-note-edit';

@Component({
  selector: 'pos-user-training-notes',
  templateUrl: './user-training-notes.component.html',
  styleUrls: ['./user-training-notes.component.scss']
})
export class UserTrainingNotesComponent extends ModalComponent implements OnInit {
  @ViewChild('confirmTrainingNoteDelete') private confirmTrainingNoteDelete: ConfirmDeleteComponent;
  trainingData: any;
  message: string;
  deleteTrainingNote: any;
  userTrainingNote: string;
  userTrainingNoteId: number;
  userTrainingNoteColumns: Array<GridColumn> = [];
  userTrainingNotes: any = [];
  // @ViewChild('userTrainingNoteModal') private userTrainingNoteModal: any;
  userTrainingNoteModalRef: ICloseable;

  constructor(modalRef: BsModalRef,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private modalService: ModalService,
    private modalBackdropService: ModalBackdropService,
    private userTrainingNotesService: UserTrainingNotesService) {
    super(modalRef);
  }

  ngOnInit() {
    this.getUserTrainingNotes();
    this.columnConfiguration();
    this.message = Messages.ConfirmDeleteUserTrainingNote;
  }

  private columnConfiguration() {
    const editColumn = new EditColumn({
      Clicked: (data) => {
        this.editTrainingTopicLevel(data.Id, data.TrainingLevelId, data.Note);
      },
      Width: '30px',
      AddIcon: new LinkColumn({
        Clicked: () => {
          this.editTrainingTopicLevel(0, this.trainingData.UserTrainingLevelId, '');
        },
        ToolTip: 'New'
      })
    });

    const deleteColumn = new DeleteColumn({
      Clicked: (data) => {
        this.deleteUserTrainingNote(data);
      },
      Width: '30px'
    });

    this.userTrainingNoteColumns = [
      new GridColumn({ HeaderText: 'Note', Field: 'Note', Width: '48%' }),
      new DateColumn({ HeaderText: 'Date Time', Field: 'DateAdded', Width: '20%' }),
      new GridColumn({ HeaderText: 'User', Field: 'Username', Width: '25%' })
    ] as Array<GridColumn>;

    this.userTrainingNoteColumns.push(editColumn);
    this.userTrainingNoteColumns.push(deleteColumn);

  }

  editTrainingTopicLevel(id, trainingLevelId, note) {
    this.userTrainingNoteId = id;
    this.userTrainingNote = note;
    const modelRef = this.modalService.show(UserTrainingNoteEditComponent, {
      backdrop: 'static', // This disable for click outside event
      keyboard: false, // This for keyboard event
      'class': 'vertical-center',
      initialState: {
        userTrainingNoteId: id,
        userTrainingLevelId: this.trainingData.UserTrainingLevelId,
        userTrainingNote: this.userTrainingNote,
        trainingData: this.trainingData
      }
    });
    modelRef.close.subscribe((res) => {
      if (res?.shouldReload) {
        this.getUserTrainingNotes();
      }
    })
  }

  getUserTrainingNotes() {
    this.spinnerService.show();
    this.userTrainingNotesService.getUserTrainingNotes(this.trainingData.UserTrainingLevelId)
      .subscribe({
        next: (response) => {
          this.userTrainingNotes = response;
          _.forEach(this.userTrainingNotes, (note) => {
            note.Username = note.User.LastName + ', ' + note.User.FirstName
          });
        }, error: this.alertService.showApiError,
        complete: () => {
          this.spinnerService.hide();
        }
      });
  }

  deleteUserTrainingNote($event) {
    this.deleteTrainingNote = $event;
    this.confirmTrainingNoteDelete.Show(Messages.ConfirmDeleteUserTrainingNote);
  }

  onConfirmAcknowledge() {
    this.spinnerService.show();
    this.userTrainingNotesService.deleteUserTrainingNote(this.deleteTrainingNote.Id).subscribe(
      () => {
        this.alertService.renderSuccessMessage(Messages.TrainingNoteDeletedSuccess);
        this.getUserTrainingNotes();
      },
      this.alertService.showApiError,
      () => {
        this.spinnerService.hide();
      }
    );
  }

  onCancel(reload: boolean) {
    this.hide({ shouldReload: reload });
  }

}
