import { Component, OnInit, Input } from '@angular/core';
import { ModalComponent, AlertsService, SpinnerService, ApplicationStateService, ProductNavigationDetails } from 'src/app/shared';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OrderService } from '../../shared/services/order.service';
import { finalize } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'pos-set-product-for-button',
  templateUrl: './set-product-for-button.component.html',
  styleUrls: ['./set-product-for-button.component.scss']
})
export class SetProductForButtonComponent extends ModalComponent implements OnInit {

  @Input('buttonId') buttonId: number;
  @Input('productId') productId: number;
  searchFilter: string = '';
  selectedProduct: any = {};
  productList: Array<ProductNavigationDetails> = [];
  orderNavigationDataForAllScreen: any;
  constructor(setProductForButtonModalRef: BsModalRef,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private orderService: OrderService,
    private applicationStateService: ApplicationStateService) {
    super(setProductForButtonModalRef);
    this.orderNavigationDataForAllScreen = applicationStateService.productNavigationDetails;
  }

  ngOnInit() {
    this.getProductList();
  }

  private getProductList(): void {
    this.spinnerService.show();
    this.orderService.getProductList().pipe(finalize(() => {
      this.spinnerService.hide();
    }))
      .subscribe({
        next: (res: any) => {
          this.productList = res && res.SaleProductModel ? res.SaleProductModel : [];
        }, error: this.alertService.showApiError
      });
  }

  setProduct(product: any): void {
    this.productId = 0;
    this.selectedProduct.ProductId = product.ID;
    this.selectedProduct.ChoiceId = this.buttonId;
    const self = this;
    _.forEach(this.orderNavigationDataForAllScreen, function (item) {
      if (item.id === self.buttonId) {
        item.ProductId = product.ID;
      }
    });
  }

  onSave(): void {
    if (this.selectedProduct && this.selectedProduct.ProductId) {
      this.spinnerService.show();
      this.orderService.setProduct(this.selectedProduct).pipe(finalize(() => {
        this.spinnerService.hide();
      }))
        .subscribe({
          next: (res: any) => {
            this.applicationStateService.productNavigationDetails = this.orderNavigationDataForAllScreen;
            this.hide({ reload: true });
          }, error: this.alertService.showApiError
        });
    }
  }

  onCancel() {
    this.hide({ reload: false });
  }

}
