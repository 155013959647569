import { Route } from "@angular/router";
import { WaitListTerminalComponent } from "./components/wait-list-terminal/wait-list-terminal.component";
import { AuthGuard } from "../app-guard";
import { DomainConstants, Levels } from "../shared/constants";

export const WAITLIST_STATES: Array<Route> = [
    {
        path: 'terminal/:terminalId',
        component: WaitListTerminalComponent,
        canActivate: [AuthGuard],
        data: {
            permissions: [{ 'Name': DomainConstants.TerminalTypes.WAITLIST.Permission, 'Level': Levels.Access }]
        }
    }
]
