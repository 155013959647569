import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseCrudService } from 'src/app/shared/services';
import { DashboardDataSource, DashboardDataSourceParameter } from '../interface';

@Injectable({
    providedIn: 'root'
})
export class DashboardDataSourceParamterService extends BaseCrudService<DashboardDataSource> {

    newDataSourceParameter(): DashboardDataSourceParameter {
        return {
            Id: 0,
            DataSourceId: 0,
            ParameterName: '',
            ParameterDefaultValue: '',
            ParameterLabel: '',
            IsUserDefined: false,
            DataType: null,
        };
    }

    setDataSourceParameter(id: number, dataSourceId: number, parameterName: string,
        parameterDefaultValue: string): DashboardDataSourceParameter {
        return {
            Id: id,
            DataSourceId: dataSourceId,
            ParameterName: parameterName,
            ParameterDefaultValue: parameterDefaultValue,
            ParameterLabel: '',
            IsUserDefined: false,
            DataType: null
        };
    }

    constructor(protected httpClient: HttpClient) {
        super('dashboard-data-source-parameter', httpClient);
    }

    getDataSourceParameter(dataSourceId: number) {
        return this.httpClient.get<Array<DashboardDataSourceParameter>>(this.apiEndpoint + '/DataSourceParameter/' + dataSourceId);
    }

}
