import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/internal/operators/finalize';
import { AlertsService, SpinnerService } from 'src/app/shared';
import { SystemLogViewerService } from '../../services/system-log-viewer.service';
import { angleDoubleRight, angleDoubleLeft } from 'src/app/shared/components/icon';
import { LogModel } from '../../interfaces/log-model';
import { Router } from '@angular/router';
declare let $: any;

@Component({
	selector: 'pos-system-log-viewer',
	templateUrl: './system-log-viewer.component.html',
	styleUrls: ['./system-log-viewer.component.scss']
})

export class SystemLogViewerComponent implements OnInit {

	logFileNames: Array<string> = [];
	logs: Array<LogModel> = [];
	selectedFileName = '';
	isFullScreen = false;
	icons = { angleDoubleRight, angleDoubleLeft };

	constructor(private spinnerService: SpinnerService,
		private systemLogViewerService: SystemLogViewerService,
		private alertService: AlertsService,
		private router: Router) { }

	ngOnInit(): void {
		this.getLogFileList();
	}

	getLogFileList(): void {
		this.spinnerService.show();
		this.systemLogViewerService.getLogFileList().pipe(finalize(() => {
			this.spinnerService.hide();
		})).subscribe({
			next: (res: Array<string>) => {
				this.logFileNames = res;
				if (this.logFileNames && this.logFileNames.length > 0) {
					this.displayContent(this.logFileNames[0]);
				}
			}, error: this.alertService.showApiError
		});
	}

	displayContent(fileName: string): void {
		this.selectedFileName = fileName;
		this.systemLogViewerService.getLogFileDetails(fileName).subscribe({
			next: (res: Array<LogModel>) => {
				this.logs = res;
			}, error: this.alertService.showApiError
		});
	}

	toggleFullScreen(): void {
		this.isFullScreen = !this.isFullScreen;
		if (this.isFullScreen) {
			$('.nav-pills').hide();
		} else {
			$('.nav-pills').show();
		}
	}

	close() {
		this.router.navigate(['manage/console']);
	}
}
