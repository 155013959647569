<pos-edit-wrapper (close)="onCancel()" heading="Import Ingredient">
    <div class="col-12 screen-scroll pt-pix-15 px-pix-20">
        <ng-table #gridComponent [search]="false" [sort]="false" [paginator]="false"
            [columns]="inventoryProductColumns" [data]="ingredients">
        </ng-table>
    </div>
    <div class="col-12 action-button-container popup-footer">
        <button type="button" class="btn btn-lg btn-primary btn-save pos-button"
            (click)="importIngredients()">Accept</button>
        <button class="btn btn-lg btn-secondary btn-cancel-modal pos-button" type="button" (click)="onCancel()">Discard</button>
    </div>
</pos-edit-wrapper>

<ng-template let-data="rowData" #chkTemplate>
    <span *ngIf="data.Is2pct" class="badge badge-success param-badge">
        <pos-icon [icon]="icons.check"></pos-icon>
    </span>
  </ng-template>
