import { Route } from '@angular/router';
import { Levels, Permissions } from 'src/app/shared';
import { InventoryBatchMarkAsOrderedComponent } from './inventory-batch-mark-as-ordered/inventory-batch-mark-as-ordered.component';
import { InventoryLogComponent } from './inventory-log/components/inventory-log.component';
import { InventoryLossComponent } from './inventory-loss/components/inventory-loss.component';
import { InventoryPrepComponent } from './inventory-prep/components/inventory-prep/inventory-prep.component';
import { InventoryReceivingComponent } from './inventory-receiving/components';
import { InventoryReceivingEditComponent } from './inventory-receiving/components/inventory-receiving-edit/inventory-receiving-edit.component';
import { InventoryTransferComponent } from './inventory-transfer';


const INVENTORY_LOG: Route = {
    path: 'log-inventory',
    component: InventoryLogComponent,
    data: {
        permissions: [{ 'Name': Permissions.InventoryLogInventory, 'Level': Levels.Access }]
    }
};

const INVENTORY_LOSS: Route = {
    path: 'inventory-loss',
    component: InventoryLossComponent,
    data: {
        permissions: [{ 'Name': Permissions.InventoryInventoryLoss, 'Level': Levels.Access }]
    }
};

const INVENTORY_PREP: Route = {
    path: 'inventory-prep-recording',
    component: InventoryPrepComponent,
    data: {
        permissions: [{ 'Name': Permissions.InventoryInventoryPrep, 'Level': Levels.Access }]
    }
};
const INVENTORY_RECEIVING: Route = {
    path: 'inventory-receiving',
    component: InventoryReceivingComponent,
    data: {
        permissions: [{ 'Name': Permissions.InventoryOrderReceiving, 'Level': Levels.Access }]
    }
};

const INVENTORY_RECEIVING_EDIT: Route = {
    path: 'inventory-receiving-edit',
    component: InventoryReceivingEditComponent,
    data: {
        // permissions: [{ 'Name': Permissions.InventoryOrderReceiving, 'Level': Levels.Access }]
    }
};

const INVENTORY_BATCH_MARK_AS_ORDERED: Route = {
    path: 'inventory-batch-mark-as-ordered',
    component: InventoryBatchMarkAsOrderedComponent,
    data: {
        permissions: [{ 'Name': Permissions.InventoryBatchMarkAsOrdered, 'Level': Levels.Access }]
    }
};

const INVENTORY_TRANSFER: Route = {
    path: 'inventory-transfer',
    component: InventoryTransferComponent,
    data: {
        permissions: [{ 'Name': Permissions.InventoryInventoryTransfer, 'Level': Levels.Access }]
    }
};

export const INVENTORY_STATES = [INVENTORY_LOG, INVENTORY_LOSS, INVENTORY_PREP, INVENTORY_RECEIVING, INVENTORY_RECEIVING_EDIT,
    INVENTORY_BATCH_MARK_AS_ORDERED, INVENTORY_TRANSFER];
