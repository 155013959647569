import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BorderStyleConfig } from 'src/app/shared/interface/widget-style/border-style-config';
import { WidgetHeaderConfig } from '../../interfaces/widget-header-config';
import { WidgetHeaderStyleConfig } from '../../interfaces/widget-header-style-config';
import { MenuWidgetStyleService } from '../../services/menu-widget-style.service';

@Component({
  selector: 'pos-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit, OnChanges {

  @Input() data: WidgetHeaderConfig;
  @Input() styleData: WidgetHeaderStyleConfig;
  @Input() borderStyle: BorderStyleConfig;
  @Input() imagePath: string;
  bannerStyle;
  textStyle;
  priceInfoStyle;
  imageSize;
  bannerSize;
  text: SafeHtml = '';
  constructor(protected menuWidgetStyleService: MenuWidgetStyleService,
    public sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.text = this.data?.Text ? this.sanitizer.bypassSecurityTrustHtml(this.data?.Text) : this.text;
    this.bannerStyle = this.getBannerStyle();
    this.priceInfoStyle = this.menuWidgetStyleService.getFontStyle(this.styleData?.PriceInfoStyle);
    this.textStyle = this.menuWidgetStyleService.getFontStyle(this.styleData?.TextFontStyle);
  }

  getBannerStyle() {
    const height = 60;
    this.bannerSize = parseInt(this.styleData?.TextFontStyle.fontSize.replace('px', ''));
    this.imageSize = (this.styleData?.HeaderHeight ?? height) - ((this.styleData?.HeaderHeight ?? height) * 0.15);
    return {
      'background-color': this.styleData?.BackgroundColor,
      'height': (this.styleData?.HeaderHeight ?? height) + 'px',
      borderBottom: this.styleData?.ShowHeaderBorder ? this.borderStyle?.borderBottom : '',
      borderBottomColor: this.styleData?.ShowHeaderBorder ? this.borderStyle?.borderColor : '',
      borderBottomStyle: this.styleData?.ShowHeaderBorder ? this.borderStyle?.borderStyle : '',
    };
  }

}
