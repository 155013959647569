import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import {  ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'pos-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  providers: [
    {
       provide: NG_VALUE_ACCESSOR,
       useExisting: forwardRef(() => ColorPickerComponent),
       multi: true
    }
  ],
})
export class ColorPickerComponent implements ControlValueAccessor {
  @Input() cpPosition = 'bottom';
  @Input() cpWidth = '180px';
  @Input() cpAlphaChannel = 'disabled';
  @Input() size = 7;
  @Input() colorPicker: any;
  @Input() name: string;
  @Input() styleClass = 'col-200'
  @Input() inputStyleClass = '';

  @Output() ngModelChange = new EventEmitter();
  @Input() required: boolean = false;

  // input tag values
  value: any;
  disabled = false;

  colorChange(color: string) {
    this.value = color;
    this.ngModelChange.emit(this.value);
  }

  remove() {
    if(!this.disabled) {
      this.value = '';
      this.ngModelChange.emit(this.value);
    }
  }

  // life cycle methods
  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
