import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseCrudComponent, SpinnerService } from 'src/app/shared/components';
import { SMSTemplate } from '../../interfaces/sms-template';
import { messageCode, sms } from 'src/app/shared/components/icon/icons';
import { AlertsService, FormUtilityService, Messages } from 'src/app/shared';
import { ActivatedRoute, Router } from '@angular/router';
import { SmsTemplateService } from '../../services/sms-template.service';
import { finalize } from 'rxjs/operators';
import { sortBy } from 'lodash';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'pos-sms-template-edit',
  templateUrl: './sms-template-edit.component.html',
  styleUrls: ['./sms-template-edit.component.scss']
})
export class SmsTemplateEditComponent extends BaseCrudComponent<SMSTemplate> implements OnInit {
  @ViewChild('smsTemplateForm', { static: true }) form: NgForm;
  smsTemplate: SMSTemplate;
  variables = [];
  isOpenVariable: boolean;
  selectedParameter: string;
  codeMirrorOptions = {
    lineNumbers: true,
    name: 'htmlmixed'
  };
  get getForm(): NgForm {
    return this.form;
  }
  icons = {
    messageCode,
    sms
  };
  ngxCodeMirrorDoc;
  constructor(protected spinnerService: SpinnerService,
    protected alertService: AlertsService,
    protected router: Router,
    private route: ActivatedRoute,
    protected smsTemplateService: SmsTemplateService,
    formUtilityService: FormUtilityService) {
    super(smsTemplateService, alertService, spinnerService, formUtilityService);
    this.smsTemplate = smsTemplateService.newSmsTemplate();
    this.smsTemplate.Id = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
  }

  ngOnInit(): void {
    this.saveSuccessMessage = Messages.EmailTemplateSaveSuccess;
    this.getSmsTemplate();
  }

  getSmsTemplate() {
    this.spinnerService.show();
    this.smsTemplateService.get(this.smsTemplate.Id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.smsTemplate = res;
          this.variables = this.smsTemplate.Variables?.split(',');
          this.variables = sortBy(this.variables);
        }, error: this.alertService.showApiError
      });
  }

  saveSmsTemplate(isValid: boolean) {
    if (!isValid || !this.smsTemplate.Value) {
      return;
    }
    this.save(this.smsTemplate);
  }

  cancel() {
    this.router.navigate(['sms-templates'], { relativeTo: this.route.parent });
  }

  onSaveSuccess(_model: SMSTemplate): void {
    this.router.navigate(['sms-templates'], { relativeTo: this.route.parent });
  }
  getCursorPosition(event) {
    this.ngxCodeMirrorDoc = event.curOp.cm.getDoc();
  }

  changeParameter() {
    this.isOpenVariable = false;
    const cursor = this.ngxCodeMirrorDoc?.getCursor();
    if (cursor) {
      this.ngxCodeMirrorDoc.replaceRange('{{' + this.selectedParameter + '}}', cursor);
    } else {
      this.smsTemplate.Value = this.smsTemplate.Value + '{{' + this.selectedParameter + '}}';
    }
    this.selectedParameter = null;
  }

  loadDependencies(): void { }

}
