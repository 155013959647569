<pos-edit-wrapper (close)="cancelEditing()" [isPopup]="false" [headingIcon]="icons.jobCodeBusinessTime"
    heading="{{jobCode.Id ? 'Edit Job Code' : 'Add Job Code'}}">
    <form #jobCodeForm="ngForm" class="edit-form" (ngSubmit)="submit(jobCodeForm.valid)" autocomplete="off">
        <context-provider provide="props">
            <div class="col-12 pos-page-body border-top pt-pix-5">
                <div class="col-12">
                    <pos-row>
                        <pos-column>
                            <pos-form-field label="Name" [validationMessages]="{required: 'Please enter name.'}">
                                <textarea posInput class="form-control" id="name" name="name" [(ngModel)]="jobCode.name" type="text"
                                    rows="5" #name="ngModel" cols="20" [required]="true"></textarea>
                            </pos-form-field>
                        </pos-column>
                    </pos-row>
                </div>
            </div>
            <div class="col-12 action-button-container page-footer">
                <button type="submit" value="Save" class="btn-primary btn-lg btn btn-save pos-button"
                    data-dismiss="modal">Save</button>
                <button type="button" class="btn-secondary btn-lg btn btn-cancel pos-button" (click)="cancelEditing()"
                    value="Cancel"> Cancel
                </button>
            </div>
        </context-provider>
    </form>
</pos-edit-wrapper>
