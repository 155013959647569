<div class="tab-content ps-pix-10 pe-pix-10 report-details" *ngIf="isShowReportDetails">
    <div class="tab-pane active px-3">
        <div class="col-md-12">
            <a class="close" (click)="closeReportDetails();">×</a>
            <h3 class="page-content__header">
                {{ reportParam.ReportName }}
            </h3>
        </div>
        <div class="row">
            <div class="report-detail__export">
                <button class="btn btn-secondary normal me-pix-5 pos-button mb-1" (click)="reloadReport()">
                    <pos-icon [icon]="icons.sync"></pos-icon> Re-run Report
                </button>
                <button class="btn btn-secondary normal me-pix-5 pos-button mb-1" (click)="printDiv();">
                    <pos-icon [icon]="icons.printReportManagement"></pos-icon> Print
                </button>
                <button class="btn btn-secondary normal me-pix-5 pos-button mb-1" *ngIf="!userHasEmail" (click)="exportAction();">
                    <pos-icon [icon]="icons.upload"></pos-icon> Export
                </button>
                <div class="dropdown d-inline-flex me-pix-5 mb-1" *ngIf="userHasEmail">
                    <button class="btn btn-secondary normal dropdown-toggle pos-button" type="button" id="exportMenu"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                        <pos-icon [icon]="icons.upload"></pos-icon> Export
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="exportMenue">
                        <li (click)="exportAction()">
                            <a class="dropdown-item" tabindex="-1">
                                <div class="display-flex">CSV Download</div>
                            </a>
                        </li>
                        <li (click)="sendEmailToUser()">
                            <a class="dropdown-item" tabindex="-1">
                                <div class="display-flex">Email Me</div>
                            </a>
                        </li>
                    </ul>
                </div>
                <button class="btn btn-secondary normal btn-subscribe pos-button mb-1" (click)="openSubscriptionModal()">
                    <pos-icon [icon]="icons.rssSquare"></pos-icon> Subscribe
                </button>
            </div>
        </div>
        <div class="col-md-12 report-detail__sub-header">
            <div class="report-detail__heading-left-param" *ngFor="let param of reportParam.parameters">
                <div class="ps-pix-5 float-start" *ngIf="param.AllowUserInput">
                    <div class="ps-pix-5" *ngIf="param.DataType=='DATE'">
                        <div class="report-detail__circle-icon">
                            <pos-icon [icon]="icons.circle"></pos-icon>
                        </div>
                        <div class="float-start">
                            <b>{{ param.Label }}:</b> {{param.InitialValue | tark_date }}
                        </div>
                    </div>
                    <div class="ps-pix-5" *ngIf="param.DataType=='INT'">
                        <div *ngIf="(param.QueryValues || param.ManualValues)">
                            <div *ngFor="let drpValue of param.ParameterQueryValues">
                                <div *ngIf="drpValue.Id==param.InitialValue">
                                    <div class="report-detail__circle-icon">
                                        <pos-icon [icon]="icons.circle"></pos-icon>
                                    </div>
                                    <div class="float-start">
                                        <b>{{ param.Label }}:</b> {{ drpValue.Name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ps-pix-5" *ngIf="param.DataType=='CSV' || param.DataType=='DROPDOWN'">
                        <div *ngFor="let drpValue of param.ParameterQueryValues">
                            <div *ngIf="drpValue.Id==param.InitialValue">
                                <div class="report-detail__circle-icon">
                                    <pos-icon [icon]="icons.circle"></pos-icon>
                                </div>
                                <div class="float-start">
                                    <b>{{param.Label}}:</b> {{drpValue.Name}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ps-pix-5" *ngIf="param.DataType=='MULTISELECT'">
                        <span *ngFor="let value of param.InitialValue.split(','); let index = index;">
                            <span *ngFor="let drpValue of param.ParameterQueryValues">
                                <span *ngIf="value == drpValue.Id">
                                    <span class="report-detail__circle-icon" *ngIf="index == 0">
                                        <pos-icon [icon]="icons.circle"></pos-icon>
                                    </span>
                                    <div class="float-start bold pe-pix-3" *ngIf="index==0">{{param.Label}}:</div>{{index > 0 ? ', ' + drpValue.Name : drpValue.Name }}</span>
                            </span>
                        </span>
                    </div>
                    <div class="ps-pix-5" *ngIf="param.DataType=='BIT'">
                        <div class="report-detail__circle-icon">
                            <pos-icon [icon]="icons.circle"></pos-icon>
                        </div>
                        <div class="float-start  ">
                            <b>{{param.Label}}:</b>
                            <span *ngIf="param.InitialValue==0">No</span>
                            <span *ngIf="param.InitialValue==1">Yes
                            </span>
                        </div>
                    </div>
                    <div class="ps-pix-5" *ngIf="param.DataType=='TEXT' || param.DataType=='INT'">
                        <div *ngIf="param.InitialValue && !(param.QueryValues || param.ManualValues)">
                            <div class="report-detail__circle-icon">
                                <pos-icon [icon]="icons.circle"></pos-icon>
                            </div>
                            <div class="float-start">
                                <b>{{param.Label}}:</b> {{param.InitialValue}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <span class="float-end"> <b>Generated Date: </b> {{ reportDate | tark_date_time}} </span>
        </div>
        <div class="col-12">
            <div id="exportableReport">
                <span *ngIf="isMobileMode">
                    <ng-table [data]="reportData.Data" [columns]="reportColumns" [groupCategory]="groupCategory"
                        [scrollable]="false" [paginator]="true"></ng-table>

                </span>
                <span *ngIf="!isMobileMode">
                    <ng-table [data]="reportData.Data" [columns]="reportColumns" [groupCategory]="groupCategory"
                        scrollDirection="both" [scrollable]="true" [paginator]="reportParam.IsPaged"
                        [tableStyle]="tableStyle" [virtualScroll]="!reportParam.IsPaged" [search]="true"
                        [scrollHeight]="'calc(100vh - 330px)'" rowBgColor="rowBgColor">
                    </ng-table>
                </span>

            </div>
        </div>
    </div>
</div>
<pos-print-table [hidden]="true"></pos-print-table>
