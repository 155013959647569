<pos-edit-wrapper (close)="cancelEditing()" heading="{{id ? 'Edit Equipment' : 'Add Equipment'}}"
    [headingIcon]="icons.equipmentListGripVertical" [isPopup]="false">
    <form #equipmentForm="ngForm" (submit)="submit(equipmentForm.valid)" autocomplete="off">
        <context-provider provide="props">
            <div class="col-12 pos-page-body border-top pt-pix-5">
                <div class="col-12">
                    <pos-row>
                        <pos-column>
                            <pos-form-field label="Name" [validationMessages]="{required: 'Please enter name.'}">
                                <input posInput type="text" name="Name" class="form-control"
                                    [(ngModel)]="equipment.Name" [required]="true" />
                            </pos-form-field>
                        </pos-column>
                        <pos-column>
                            <pos-form-field label="Equipment Type"
                                [validationMessages]="{required: 'Please select equipment type.'}">
                                <ng-select posInput [items]="equipmentTypes" bindLabel="Name" bindValue="Id"
                                    [required]="true" placeholder="Select equipment type"
                                    [(ngModel)]="equipment.EquipmentTypeId" name="equipmentType" appendTo="body"
                                    [disabled]="id" (change)="setEquipmentProperties(equipment)">
                                </ng-select>
                            </pos-form-field>
                        </pos-column>
                        <pos-column>
                            <pos-form-field label="Description" inputStyle="col-lg-5 col-sm-9">
                                <input posInput type="text" name="description" class="form-control"
                                    [(ngModel)]="equipment.Description" />
                            </pos-form-field>
                        </pos-column>
                        <pos-column>
                            <pos-form-field label="Inventory Zone">
                                <ng-select posInput [items]="inventoryZones" bindLabel="NameWithCode" bindValue="Id"
                                    placeholder="Select inventory zone" [(ngModel)]="equipment.InventoryZoneId"
                                    name="inventoryZone">
                                </ng-select>
                            </pos-form-field>
                        </pos-column>
                    </pos-row>
                    <pos-row class="ban-color bold mt-pix-15 g-0" *ngIf="selectedEquipmentProperties?.length">
                        <pos-column lg="3" md="3" sm="6" xs="12">Property Name
                        </pos-column>
                        <pos-column md="7" sm="10" xs="12"> Property Value</pos-column>
                    </pos-row>
                    <div class="col-xs-12 p-0 overflow-auto" [ngStyle]="{'height': height + 'px'}">
                        <pos-row *ngFor="let property of selectedEquipmentProperties; let index = index" class="g-0">
                            <pos-column lg="3" md="3" sm="6" xs="12" class="pt-pix-10 px-0">
                                <label class="edit-form__section__label required">
                                    {{property.Name}}
                                </label>
                                <field-info *ngIf="property.Description" class="d-inline-block" [info]="property.Description"></field-info>
                            </pos-column>
                            <pos-column lg="4" md="7" sm="10" xs="12" class="px-0">
                                <pos-form-field inputStyle="col-xs-12">
                                    <pos-editor posInput [submit]="equipmentForm.submitted"
                                        [propertyValues]="property.Value" [(propertyValue)]="property.Value"
                                        [required]="required" [propertyName]="property.Name"
                                        [propertyPlaceHolder]="'Select ' + property.Name"
                                        [controlType]="getEditorType.Text" (changeValue)="property.Value = $event"
                                        [requiredMessage]="'Please specify value for ' + property.Name + '.'">
                                    </pos-editor>
                                </pos-form-field>
                            </pos-column>
                        </pos-row>
                    </div>
                </div>
            </div>

            <div class="col-12 action-button-container page-footer">
                <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" value="Save">Save </button>
                <button type="button" data-dismiss="modal" class="btn-secondary btn-lg btn btn-cancel pos-button"
                    (click)="cancelEditing()" value="Cancel">Cancel</button>
            </div>
        </context-provider>
    </form>
</pos-edit-wrapper>
