<pos-edit-wrapper heading="{{buttonName}} is present on" (close)="onConfirm()">
  <div class="pt-pix-15 pos-modal-body">
    <ul class="list-group mb-0">
      <li class="list-group-item" *ngFor="let screenName of screenNames">
        {{screenName}}
      </li>
    </ul>
  </div>
  <div class="col-12 action-button-container popup-footer">
    <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="onConfirm()" type="button">Close</button>
  </div>
  <div class="clearfix"></div>
</pos-edit-wrapper>
