<form #formMenuDisplayBackup="ngForm" (ngSubmit)='createBackup(formMenuDisplayBackup.valid)' class="pb-pix-5">
  <pos-row colSm="6" colXs="12" class="mx-0">
    <pos-column class="p-0">
      <pos-form-field label="Name" [validationMessages]="{required: 'Please enter backup name.'}" inputStyle="col-12">
        <input posInput name="backupNameText" #backupNameText="ngModel" type="text" class="form-control"
          [(ngModel)]="backupName" [required]="true" />
      </pos-form-field>
    </pos-column>
    <pos-column class="create-backup">
      <button type="submit" class="btn-primary btn btn-save pos-button ms-2 mb-1" value="Save">Create Backup</button>
      <button type="button" class="btn-secondary btn btn-cancel-modal pos-button ms-2 mb-1" value="Cancel"
        (click)="onCancel()">Cancel</button>
    </pos-column>
  </pos-row>
</form>
