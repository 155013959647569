
<div class="dashboard-configuration-page page row mx-0">
    <div class="col-12 p-0">
       <pos-adf-dashboard #posAdfDashboard [adfModel]='dashboard' [isCollapsible]="true" [isMaximizable]="true"
            [name]="dashboard.Name" (toggleMode)="toggleEditMode($event)" [(mode)]="mode" [structure]="6-6" [redirectRoute]="redirectRoute">
        </pos-adf-dashboard>

        <div *ngIf="!dashboard.IsInteractive && isShowClock && !salesProductId && !inventoryProductId" class="bottom-time-block">
            <span class="bottom-time-block__time"><b>{{ currentTime | tark_time }}</b></span>
        </div>
    </div>
</div>

