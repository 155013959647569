import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared';
import { CustomerWaitListEntry } from '../interfaces/customer-wait-list-entry';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WaitListService {

  baseUrl = `${RuntimeConstants.API_PATH}customerWaitlist/`
  constructor(private httpClient: HttpClient) { }

  getWaitListEntriesByTerminalId(terminalId: number): Observable<CustomerWaitListEntry[]> {
    return this.httpClient.get<CustomerWaitListEntry[]>(`${this.baseUrl}terminal/${terminalId}`);
  }

  addWaitListEntry(customerWaitListEntry: CustomerWaitListEntry): Observable<CustomerWaitListEntry> {
    return this.httpClient.post<CustomerWaitListEntry>(`${this.baseUrl}`, customerWaitListEntry);
  }

  deleteWaitListEntry(code: number) {
    return this.httpClient.delete(`${this.baseUrl}${code}`);
  }

  notifyWaitListEntry(entry: CustomerWaitListEntry) {
    return this.httpClient.put(`${this.baseUrl}notify/${entry.Code}`, entry);
  }
}
