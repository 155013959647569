import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { FormUtilityService, Messages } from 'src/app/shared';
import { BaseCrudComponent } from 'src/app/shared/components/base-crud.component';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { MacroScript } from '../../interface/macro-script';
import { MacroScriptService } from '../../service/macro-script-service';
import { scroll } from 'src/app/shared/components/icon';
@Component({
  selector: 'pos-macro-script-edit',
  templateUrl: './macro-script-edit.component.html',
  styleUrls: ['./macro-script-edit.component.scss']
})
export class MacroScriptEditComponent extends BaseCrudComponent<MacroScript> implements OnInit {
  id = 0;
  macroScript: MacroScript;
  scriptName: string = '';
  codeMirrorOptions = {
    lineNumbers: true,
    name: 'sql',
    lineWrapping: true
  };

  icons = { scroll };
  @ViewChild('macroScriptForm') macroScriptForm: NgForm;

  get getForm(): NgForm {
    return this.macroScriptForm;
  }
  macroScriptNameChangeWarning = Messages.MacroScriptNameChangeWarning;

  constructor(
    protected macroScriptService: MacroScriptService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    formUtilityService: FormUtilityService) {
    super(macroScriptService, alertService, spinnerService, formUtilityService);
    this.id = parseInt(route.snapshot.params.id, 10) ?? 0;
    this.macroScript = this.macroScriptService.newMacroScript();
    this.dataSubscription();
    this.saveSuccessMessage = Messages.MacroScriptSaveSuccess;
  }

  dataSubscription() {
    if (!this.id) {
      return;
    }
    this.loadData().subscribe({
      next: (response) => {
        this.macroScript = response;
        this.scriptName = this.macroScript.Name;
      }
    });
  }

  loadDependencies(): void {
    return;
  }

  submit(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.spinnerService.show();
    this.macroScriptService.getNameExists(this.macroScript.Id, this.macroScript.Name).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (response) => {
        if (response) {
          this.alertService.renderErrorMessage('Script name already exists.');
        } else {
          this.save(this.macroScript);
        }
      }, error: this.alertService.showApiError
    });

  }


  onSaveSuccess(): void {
    this.onCancel();
  }

  onCancel() {
    this.router.navigate(['macro-scripts'], { relativeTo: this.route.parent });
  }





}
