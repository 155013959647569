import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomainConstants, RuntimeConstants } from 'src/app/shared/constants';
import { BaseCrudService } from 'src/app/shared/services';
import { EventSubscriptionActionAttribute, RepeatActionConfig } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class EventSubscriptionActionAttributeService extends BaseCrudService<EventSubscriptionActionAttribute> {

  eventsApiUrl = `${RuntimeConstants.API_PATH}event-subscription-action-attribute`;

  constructor(httpService: HttpClient) {
    super('event-subscription-action-attribute', httpService);
  }

  newRepeatEventActionConfig(): RepeatActionConfig {
    return {
      Period: {
        Time: null,
        Unit: DomainConstants.EventRepeatActionDurationUnit[0],
      },
      Until: {
        Time: null,
        Unit: DomainConstants.EventRepeatActionDurationUnit[0],
        Type: DomainConstants.EventRepeatUntilDurationType.Times
      }
    };
  }

  saveAttributes(eventSubscriptionActionAttributes: EventSubscriptionActionAttribute[], eventIdentifier: string) {
    return this.httpService.post(`${this.eventsApiUrl}/${eventIdentifier}/attributes`, eventSubscriptionActionAttributes);
  }

}
