import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountCategory } from '../../interface/account-category';
import { BaseListComponent } from 'src/app/shared/components/base-list.component';
import { Permissions, Levels, Messages, DomainConstants } from '../../../../shared/constants/';
import { GridColumn, TableComponent } from '@tarktech/tark-ng-utils';
import { AccountCategoryService } from 'src/app/shared/services/account-category.service';
import { AuthenticationService } from 'src/app/shared/auth/services/authentication.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import * as _ from 'lodash';
import { ApplicationStateService } from 'src/app/shared';
import { ActivatedRoute, Router } from '@angular/router';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { accountCategoriesClipboardList } from 'src/app/shared/components/icon';

@Component({
    selector: 'pos-account-category-list',
    templateUrl: './account-category-list.component.html',
})
export class AccountCategoryListComponent extends BaseListComponent<AccountCategory> implements OnInit {

    permission = {
        name: Permissions.CustomersAccountCategories,
        readOnlyLevel: Levels.ReadOnly,
        editLevel: Levels.Edit
    };
    icons = { accountCategoriesClipboardList };
    accountCategoriesColumns: Array<GridColumn> = [];
    accountCategories: AccountCategory[] = [];
    @ViewChild('accountCategoryGrid', { static: true }) accountCategoryGrid: TableComponent;
    constructor(
        private authenticationService: AuthenticationService,
        protected alertService: AlertsService,
        protected spinnerService: SpinnerService,
        protected accountCategoryService: AccountCategoryService,
        protected modalService: ModalService,
        protected route: ActivatedRoute,
        private router: Router,
        protected printService: PrintTableService,
        protected applicationStateService: ApplicationStateService
    ) {
        super(accountCategoryService, alertService, spinnerService, modalService, applicationStateService, printService, route);
    }

    ngOnInit() {
        this.deleteSuccessMessage = Messages.AccountCategoryDeleted;
        this.accountCategoriesColumns = this.configureGridColumns();
        this.checkPermission();
        this.loadSubscriptions();
        this.scrollToTop();
        this.accountCategoryGrid.context = this.gridContext;
    }
    public getGridColumns(): Array<GridColumn> {
        return [
            new GridColumn({ HeaderText: 'Name', Field: 'Category', IsSortable: true, Width: '95%' }),
        ] as Array<GridColumn>;
    }
    private checkPermission(): void {
        if (!this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
            _.remove(this.accountCategoriesColumns, (column) => {
                return (column.Type === DomainConstants.GridColumnsTypes.EditColumn
                    || column.Type === DomainConstants.GridColumnsTypes.DeleteColumn);
            });
        }
    }
    private loadSubscriptions(): void {
        this.data$.subscribe({
            next: (res) => {
                this.accountCategories = res;
            }
        });
    }
    public editItem(id: number, data?: AccountCategory): void {
        this.router.navigate([id], { relativeTo: this.route });
    }
    public getConfirmDeleteMessage(data: AccountCategory): string {
        return StringUtils.format(Messages.ConfirmDeleteAccountCategory,
            { 'accountCategory': data ? data.Category : '' }
        );
    }
    private scrollToTop(): void {
        window.scrollTo(0, 0);
    }

    close() {
        this.router.navigate(['../'], { relativeTo: this.route });
    }
}
