<div class="p-pix-10 overflow-hidden">
    <div class="row m-pix-10 mt-0">
        <div class="col-12 p-0 display-flex vertical-mid">
            <div class="col-lg-2 col-md-3 col-4 p-0 text-center pe-pix-20" *ngIf="productInfo.Image">
                <img class="product-info-img" height="100" src="{{imagePath}}/buttons/{{productInfo.Image ||''}}" />
            </div>
            <div class="col-lg-10 col-md-9 col-sm-6 col-8 m-auto ms-0 mr-0 p-0">
                <div class="col-12 p-0 font-20 bold">{{productInfo.ButtonText}}</div>
                <div class="col-12 p-0">
                    {{ salesProduct?.Information }}</div>
            </div>
        </div>
    </div>
    <div class="row tabs">
        <div class="col-12">
            <button class="btn bg-white normal me-pix-15 theme-separator theme-separator-border ms-pix-10 pos-button"
                [ngClass]="{'bg-theme-separator white': activeTab === tabList.ProductIngredients}"
                (click)="activeTab = tabList.ProductIngredients">
                <pos-icon class="me-pix-5" [icon]="icons.inventoryProductIngredientStore"></pos-icon> Ingredients
            </button>
            <button class="btn bg-white normal me-pix-15 theme-separator theme-separator-border pos-button"
                [ngClass]="{'bg-theme-separator white': activeTab === tabList.ProductDietaryWarnings}"
                (click)="activeTab = tabList.ProductDietaryWarnings">
                <pos-icon class="me-pix-5" [icon]="icons.exclamationTriangle"></pos-icon> Allergens
            </button>
            <button class="btn bg-white normal me-pix-15 theme-separator theme-separator-border last-tab pos-button"
                [ngClass]="{'bg-theme-separator white': activeTab === tabList.NutritionFacts}"
                (click)="activeTab = tabList.NutritionFacts">
                <pos-icon class="me-pix-5" [icon]="icons.nutritionFacts"></pos-icon> Nutrition Facts
            </button>
        </div>
        <div>
            <div class="col-12 p-pix-10 menu-explorer-tab-height bg-alabaster">
                <pos-ingredients-info-card [ingredientsInfo]="ingredientsInfo"
                    *ngIf="activeTab === tabList.ProductIngredients"></pos-ingredients-info-card>
                <pos-dietary-warnings-info [dietaryWarnings]="dietaryWarnings"
                    *ngIf="activeTab === tabList.ProductDietaryWarnings"></pos-dietary-warnings-info>
                <pos-nutrition-facts [nutritionFacts]="nutritionFacts" *ngIf="activeTab === tabList.NutritionFacts">
                </pos-nutrition-facts>
            </div>
        </div>
    </div>
    <div class="col-12 text-center">
        <div class="form-horizontal">
            <button type="button" class="btn btn-primary menu-explorer-primary-button p-pix-15 pe-pix-30 ps-pix-30 pos-button" value="Cancel"
                (click)="closeModal()"><pos-icon class="me-pix-5" [icon]="icons.times"></pos-icon> CLOSE</button>
        </div>
    </div>
</div>
