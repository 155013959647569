import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridColumn, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { cloneDeep } from 'lodash';
import { finalize } from 'rxjs/operators';
import {
  AlertsService,
  ApplicationStateService,
  BaseListComponent,
  Permissions,
  Levels,
  Messages,
  ModalService,
  PrintTableService,
  SpinnerService,
  AuthenticationService,
  ExportService,
  StringUtils,
} from 'src/app/shared';
import { check, plusWhite, editWhite, ban, checkCircleWhite, cashierReport } from 'src/app/shared/components/icon';
import { RevenueCenter } from '../../interface/revenue-center';
import { RevenueCenterService } from '../../service/revenue-center.service';
import { RevenueCenterEditComponent } from '../revenue-centers-edit';

@Component({
  selector: 'pos-revenue-centers',
  templateUrl: './revenue-center-list.component.html',
  styleUrls: ['./revenue-center-list.component.scss'],
})
export class RevenueCenterListComponent extends BaseListComponent<RevenueCenter> implements OnInit {

  @ViewChild('activeTemplate', { static: true }) activeTemplate: TemplateRef<any>;
  @ViewChild('operationTemplate', { static: true }) private operationTemplate: TemplateRef<any>;
  @ViewChild('headerTemplate', { static: true }) private headerTemplate: TemplateRef<any>;

  revenueCenterColumns: Array<GridColumn> = [];
  printRevenueCenterColumns: Array<GridColumn> = [];
  revenueCenters: Array<RevenueCenter> = [];
  filteredRevenueCenters: Array<RevenueCenter> = [];
  public icons = {
    check, plusWhite, editWhite, ban, checkCircleWhite, cashierReport
  };
  permission = {
    name: Permissions.SystemConfigurationRevenueCenters,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };
  isInactive = false;

  constructor(
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected applicationStateService: ApplicationStateService,
    protected printService: PrintTableService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected modalService: ModalService,
    protected revenueCenterService: RevenueCenterService,
    private authenticationService: AuthenticationService,
    private exportService: ExportService
  ) {
    super(revenueCenterService, alertService, spinnerService, modalService, applicationStateService, printService, route);
  }

  ngOnInit(): void {
    this.revenueCenterColumns = this.configureGridColumns();
  }

  reload(): void {
    this.spinnerService.show();
    this.revenueCenterService.getAll()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          this.revenueCenters = res;
          this.changeRevenueCenterList();
        }, error: this.alertService.showApiError
      });
  }

  changeRevenueCenterList() {
    if (this.isInactive) {
      this.filteredRevenueCenters = this.revenueCenters;
    } else {
      this.filteredRevenueCenters = this.revenueCenters.filter(x => x.IsActive);
    }
  }

  getGridColumns(): GridColumn[] {
    return this.revenueCenterColumns;
  }

  configureGridColumns() {

    const activeColumn = new TemplateColumn({
      HeaderText: 'Active',
      itemTemplate: this.activeTemplate,
      TextAlign: TextAlign.Center,
      Width: '110px',
      Field: 'IsActive',
      IsSortable: true,
    });

    const operationColumn = new TemplateColumn({
      itemTemplate: this.operationTemplate,
      Width: '147px',
      headerTemplate: this.headerTemplate,
      CellClass: 'cell-padding',
      TextAlign: TextAlign.Center
    });

    this.revenueCenterColumns = [
      new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '95%' }),
      activeColumn
    ];

    this.printRevenueCenterColumns = [...this.revenueCenterColumns];

    if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      this.revenueCenterColumns.push(operationColumn);
    }

    return this.revenueCenterColumns;
  }

  changeActiveStatus(revenueCenterId: number, isActive: boolean) {
    this.spinnerService.show();
    this.revenueCenterService.changeActiveStatus(revenueCenterId, isActive)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.alertService.renderSuccessMessage(StringUtils.format(Messages.RevenueCenterStatusChange, { activeMessage: (isActive ? 'activated' : 'deactivated') }));
          this.reload();
        }, error: this.alertService.showApiError
      });
  }

  editItem(id: number, data?: RevenueCenter) {
    const modalRef = this.modalService.getModalWrapper(RevenueCenterEditComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      initialState: {
        revenueCenterId: data ? cloneDeep(data.Id) : 0,
        revenueCenterEditName: data ? cloneDeep(data.Name) : '',
      }
    });
    modal.close.subscribe(res => {
      if (res && res.shouldReload) {
        this.reload();
      }
    });
  }

  getConfirmDeleteMessage(data) {
    return Messages.ConfirmDeleteRevenueCenter;
  }

  protected printData() {
    this.printService.printEmitter.next({ gridColumns: this.printRevenueCenterColumns, gridData: this.filteredRevenueCenters });
  }

  exportCSV() {
    this.exportService.exportCSV('revenue-center', this.filteredRevenueCenters, null, this.printRevenueCenterColumns);
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
