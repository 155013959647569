import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pos-shared-header',
  templateUrl: './shared-header.component.html',
  styleUrls: ['./shared-header.component.scss']
})
export class SharedHeaderComponent implements OnInit {

  headerLinks = {
    tasks: true,
    home: true,
    warning: true,
    admin: true,
    time: true,
    activeOrders: true,
    signIn: true,
    mySubscriptions: true
  };

  constructor() { }

  ngOnInit(): void {
  }

}
