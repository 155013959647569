import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  Levels,
  Permissions,
  BaseListComponent,
  AuthenticationService,
  AlertsService,
  SpinnerService,
  ModalService,
  PrintTableService,
  Messages,
  DomainConstants,
  ApplicationStateService
} from 'src/app/shared';
import { GridColumn, DateColumn, TableComponent, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { TimeClockEntry } from '../../interface';
import { TimeClockEntriesService } from '../../service/time-clock-entries.service';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { infoManageTimeClockEntriesClock } from 'src/app/shared/components/icon';
import { forEach } from 'lodash';

@Component({
  selector: 'pos-time-clock-entries-list',
  templateUrl: './time-clock-entries-list.component.html',
  styleUrls: ['./time-clock-entries-list.component.scss']
})
export class TimeClockEntriesListComponent extends BaseListComponent<TimeClockEntry> implements OnInit {
  permission = {
    name: Permissions.ManageTimeClockEntries,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };
  timeClockEntriesColumns: Array<GridColumn> = [];
  timeClockEntriesList: Array<TimeClockEntry> = [];
  fromDate = new Date();
  toDate = new Date();
  @ViewChild('timeClockEntryGrid', { static: true }) timeClockEntryGrid: TableComponent;
  @ViewChild('hoursTemplate', { static: true }) hoursTemplate: TemplateRef<any>;
  dateFormat = 'mm-dd-yyyy';
  icons = { infoManageTimeClockEntriesClock };
  constructor(private authenticationService: AuthenticationService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected timeClockEntryService: TimeClockEntriesService,
    protected printService: PrintTableService,
    protected applicationStateService: ApplicationStateService,
    private router: Router,
    protected route: ActivatedRoute) {
    super(timeClockEntryService, alertService, spinnerService, modalService, applicationStateService, printService, route);
    this.scrollToTop();
  }

  getOtherProperties() {
    this.othersProperties = { fromDate: this.fromDate, toDate: this.toDate };
  }

  ngOnInit() {
    if (this.othersProperties.fromDate && this.othersProperties.toDate) {
      this.fromDate = this.othersProperties.fromDate;
      this.toDate = this.othersProperties.toDate;
    }
    this.deleteSuccessMessage = Messages.TimeClockEntryDeleted;
    this.timeClockEntriesColumns = this.configureGridColumns();
    this.checkPermission();
    this.fromDate.setDate(this.toDate.getDate() - 15);
    this.timeClockEntryGrid.context = this.gridContext;
    this.dateFormat = this.applicationStateService.settingParam.PCalendarDateFormat;
  }

  private scrollToTop(): void {
    window.scrollTo(0, 0);
  }

  public getGridColumns(): Array<GridColumn> {
    const hoursColumn = new TemplateColumn({
      HeaderText: 'Hours',
      itemTemplate: this.hoursTemplate,
      TextAlign: TextAlign.Right,
      Field: 'TotalMinutes',
      Width: '10%',
      IsSortable: true
    })

    return [
      new GridColumn({ HeaderText: 'User', Field: 'EmpName', IsSortable: true, Width: '25%' }),
      new GridColumn({ HeaderText: 'Job Code', Field: 'Name', IsSortable: true, Width: '21%' }),
      new DateColumn({ HeaderText: 'Clock In', Field: 'ClockIn', IsSortable: true, Width: '20%' }),
      new DateColumn({ HeaderText: 'Clock Out', Field: 'ClockOut', IsSortable: true, Width: '20%' }),
      hoursColumn
    ] as Array<GridColumn>;
  }

  reload() {
    this.spinnerService.show();
    this.timeClockEntryService.getTimeClockEntries(this.fromDate, this.toDate).subscribe(response => {
      this.timeClockEntriesList = response == null ? [] : response;
      this.gridData = response;
      forEach(this.timeClockEntriesList, x => {
        x.Hours = this.calculateHours(x.TotalMinutes);
      });
    },
      (error) => { this.alertService.showApiError(error); },
      () => { this.spinnerService.hide(); });
  }

  calculateHours(totalMinutes: number) {
    if (!totalMinutes) return '';

    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}.${minutes < 10 ? '0' + minutes : minutes}`;
  }

  public getConfirmDeleteMessage(data: TimeClockEntry): string {
    return Messages.ConfirmDeleteTimeClockEntry;
  }

  private checkPermission(): void {
    if (!this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      _.remove(this.timeClockEntriesColumns, (column) => {
        return (column.Type === DomainConstants.GridColumnsTypes.EditColumn
          || column.Type === DomainConstants.GridColumnsTypes.DeleteColumn);
      });
    }
  }

  public editItem(id: number, data?: TimeClockEntry): void {
    this.router.navigate([id], { relativeTo: this.route });
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
