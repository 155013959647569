
import { Component, OnInit, ViewChild, EventEmitter, OnDestroy } from '@angular/core';
import {
  AlertsService, ModalComponent, SpinnerService, ApplicationStateService, SettingParam, NumpadOptions, RabbitMQService,
  ModalService, DomainConstants, Levels, Permissions, InfoModalComponent, ModalBackdropService, CreditCardPaymentStatus
} from 'src/app/shared';
import { NgForm } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { OrderService, PromotionService } from 'src/app/shared/services';
import { GiftCard } from '../../interface';
import { GiftCardService } from '../../services';
import { messageCode, newAudioFile, keyboard, switchCard } from '../../../../shared/components/icon';
import { KeyboardComponent } from 'src/app/keyboard';
import * as _ from 'lodash';
import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { OrderProductPromotion, PromotionSummaryComponent } from 'src/app/manage-console/promotion';
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pos-add-to-balance',
  templateUrl: './add-to-balance.component.html',
  styleUrls: ['./add-to-balance.component.scss']
})
export class AddToBalanceComponent extends ModalComponent implements OnInit, OnDestroy {
  @ViewChild('addBalanceForm')
  addBalanceForm: NgForm;
  public icons = {
    newAudioFile,
    messageCode,
    keyboard,
    switchCard
  };
  amount: 0;
  checkCCPaymentStatusInterval;
  giftCardDetail: GiftCard;
  public onAddBalanceCancel: Function;
  settingParams: SettingParam;
  numpadOptions: NumpadOptions;
  memo: string = '';
  allowGiftCardBalanceByCC: boolean = false;
  isNegativeAmount: boolean = false;
  enableOperationButtons: boolean = false;
  confirmationMessageForApprove: string = Messages.SwipeCardAndSelectResult;
  rabbitMQRequestId: any;
  creditCardPaymentModalDetails: any = {};
  numpadOptionChange = new EventEmitter<any>();
  @ViewChild('numpad') numpad: any;
  memoKeyBoardOptions = { isOpen: false, capsLock: false, title: 'Enter Memo', inputMode: 'multiLine', dynamicMultilineInput: true };
  isOpen = false;
  keyboardId: string;
  msgText = '';
  tenderTypeColors: any = {};
  permissions: any = Permissions;
  accessLevels: any = Levels;
  @ViewChild('confirmApproveModalTemplate') private confirmApproveModalTemplate: any;
  @ViewChild('creditCardPaymentPopup') private creditCardPaymentPopup: any;
  confirmApproveModalRef: BsModalRef;
  creditCardPaymentPopupModalRef: BsModalRef;
  // rabbitMq subscriptions
  rabbitMqOrderEntryTerminalQueueSubscription: Subscription;
  rabbitMqHICResponseSubscription: Subscription;
  constructor(
    addToBalanceModalRef: BsModalRef,
    private alertService: AlertsService,
    private giftCardService: GiftCardService,
    private applicationStateService: ApplicationStateService,
    private spinnerService: SpinnerService,
    private modalService: ModalService,
    private bsModalService: BsModalService,
    private rabbitMQService: RabbitMQService,
    private orderService: OrderService,
    private promotionService: PromotionService,
    private modalBackdropService: ModalBackdropService
  ) {
    super(addToBalanceModalRef);
  }

  ngOnInit() {
    this.onAddBalanceCancel = this.onCancelBalance.bind(this);
    this.settingParams = this.applicationStateService.settingParam;
    this.numpadOptions = {
      allowDecimal: true,
      prefix: this.settingParams.CurrencySymbol ?? '$',
      enterButton: false
    };
    this.allowGiftCardBalanceByCC = this.settingParams.GiftcardAddBalanceByCreditCard;
    this.subscribePaymentSuccessMessages();
    this.subscribeHICResponseToTerminal();
    this.getTenderTypesColor();
  }
  ngOnDestroy() {
    this.clearCCPaymentStatusInterval();
  }
  onCancel(reload: boolean) {
    this.hide({ shouldReload: reload, balance: this.isNegativeAmount ? this.amount * -1 : this.amount });
  }

  onAddGratis() {
    this.amount = this.numpad.value;
    this.addBalanceToGiftCard(this.prepareGiftCardCashBalanceRequest(1, null));
  }

  onAddBalance() {
    this.amount = this.numpad.value;
    if (this.amount && !this.applicationStateService.statusCloseDrawer) {
      const giftCardBalanceRequest = this.prepareGiftCardCashBalanceRequest(1, this.settingParams.CashDrawerPrinter.Id);
      this.addBalanceToGiftCard(giftCardBalanceRequest);
    } else {
      const modalRef = this.modalService.getModalWrapper(InfoModalComponent);
      const infoModal = modalRef.show({
        keyboard: false,
        animated: false,
        class: 'vertical-center',
        initialState: {
          message: Messages.LoadDrawerForGiftCardRecharge
        }
      });
      infoModal.close.subscribe(res => {
        this.numpadOptions.disable = false;
      });
    }
  }
  clearCCPaymentStatusInterval() {
    if (this.checkCCPaymentStatusInterval) {
      clearInterval(this.checkCCPaymentStatusInterval);
    }
  }
  onCCTransactionGetSuccessStatus = () => {
    this.clearCCPaymentStatusInterval();
    this.creditCardPaymentModalDetails = {
      Message: Messages.PaymentApproved,
      HeaderText: Messages.Success,
      ButtonText: 'Ok',
      IsTransactionComplete: true
    };
    this.rabbitMQRequestId = '';
  }

  onCCTransactionGetCanceledStatus = () => {
    this.clearCCPaymentStatusInterval();
    this.creditCardPaymentModalDetails = {
      Message: DomainConstants.TransactionStatus.Canceled,
      HeaderText: Messages.Error,
      ButtonText: 'Ok',
      IsTransactionComplete: true
    };
    this.rabbitMQRequestId = '';
  }

  onCCTransactionGetFailedStatus = () => {
    this.clearCCPaymentStatusInterval();
    this.creditCardPaymentModalDetails = {
      Message: Messages.PaymentRejected,
      HeaderText: Messages.Error,
      ButtonText: 'Ok',
      IsTransactionComplete: true
    };
    this.rabbitMQRequestId = '';
  }

  checkCCPaymentStatus = () => {
    if (this.rabbitMQRequestId) {
      this.orderService.getTransactionStatus(this.rabbitMQRequestId)
        .subscribe({
          next: (response: CreditCardPaymentStatus) => {

            if (response.Status == DomainConstants.TransactionStatus.Success) {
              this.onCCTransactionGetSuccessStatus();
            } else if (response.Status == DomainConstants.TransactionStatus.Failed) {
              this.onCCTransactionGetFailedStatus();
            } else if (response.Status == DomainConstants.TransactionStatus.Canceled) {
              this.onCCTransactionGetCanceledStatus();
            }
            else if (this.creditCardPaymentModalDetails.Message == Messages.ConnectingCreditCardTerminal && response.Status == DomainConstants.TransactionStatus.Pending) {
              this.creditCardPaymentModalDetails.Message = Messages.StillConnectingCreditCardTerminal;
            }
          }, error: this.alertService.showApiError
        });
    } else {
      this.clearCCPaymentStatusInterval();
    }
  }

  addBalanceToGiftCard(giftCardRequestModal, silentCall = false) {
    if (!silentCall) {
      this.spinnerService.show();
    }
    this.giftCardService.addGiftCardBalance(giftCardRequestModal)
      .subscribe(
        (res) => {
          if (giftCardRequestModal?.Type == 1) {
            this.applyGiftCardPromotion(giftCardRequestModal.CardNumber, giftCardRequestModal.Amount, silentCall);
          }
        }, this.alertService.showApiError,
        () => {
          if (!silentCall) {
            this.spinnerService.hide();
          }
        }
      );
  }

  applyGiftCardPromotion(cardNumber: string, rechargeAmount: number, silentCall = false) {
    this.spinnerService.show();
    this.promotionService.applyGiftCardPromotion(cardNumber, rechargeAmount)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: Array<OrderProductPromotion>) => {
          if (res?.length) {
            this.openPromotionSummary(res, silentCall);
          } else {
            if (!silentCall) {
              this.onCancel(true);
            }
          }
        }
      });
  }

  openPromotionSummary(orderProductPromotions: Array<OrderProductPromotion>, silentCall = false) {
    if (orderProductPromotions?.find(x => x.IsRewardedItem && x.Amount > 0) != null) {
      const modalRef = this.modalService.getModalWrapper(PromotionSummaryComponent);
      const modal = modalRef.show({
        animated: false,
        class: 'vertical-center',
        initialState: {
          orderProductPromotions: orderProductPromotions
        }
      });

      modal.close.subscribe(() => {
        if (!silentCall) {
          this.onCancel(true);
        }
      });
    }
    else {
      this.onCancel(true);
    }
  }

  onCancelBalance() {
    this.onCancel(false);
  }



  prepareGiftCardCashBalanceRequest(type, drawerId) {
    return {
      CardNumber: this.giftCardDetail.CardNumber, // this.giftCardNumber,
      Amount: this.isNegativeAmount ? this.amount * -1 : this.amount,
      UserId: this.applicationStateService.userId,
      DrawerID: drawerId,
      Type: type,
      TerminalId: this.applicationStateService.terminalId,
      TerminalName: this.applicationStateService.terminalName,
      Memo: this.memo,
      CreditCardTerminalId: this.settingParams.CreditCardTerminal.Id,
      PaymentMethod: 1
    }
  }

  prepareGiftCardCreditBalanceRequest() {
    return {
      Amount: this.amount,
      CardNumber: this.giftCardDetail.CardNumber,
      PaymentMethod: 2,
      CurrentUser: this.settingParams.CurrentUser,
      UserId: this.applicationStateService.userId,
      TerminalId: this.applicationStateService.terminalId,
      TerminalName: this.applicationStateService.terminalName,
      CreditCardTerminalId: this.settingParams.CreditCardTerminal.Id,
      CreditCardTerminalName: this.settingParams.CreditCardTerminal.Name,
      DrawerId: this.settingParams.CashDrawerPrinter.Id,
      GiftCardId: this.giftCardDetail.Id,
      Memo: this.memo,
      Type: 2,
      SubAccountOrdinals: '',
      RabbitMQRequestId: this.rabbitMQRequestId
    };
  }

  addGiftCardBalanceByCC() {
    this.amount = this.numpad.value;
    if (!this.amount) {
      this.alertService.renderErrorMessage(Messages.InvalidAmountInGiftCard);
      return;
    }
    if (this.isNegativeAmount) {
      this.alertService.renderErrorMessage(Messages.InvalidAmountInGiftCardCC);
      return;
    }

    if (this.settingParams.CreditCardType === 'Peripheral') {
      this.confirmApproveModalRef = this.bsModalService.show(this.confirmApproveModalTemplate, {
        'backdrop': 'static',
        'class': 'vertical-center',
        keyboard: false
      });
      this.modalBackdropService.addBackDrop(3);
      setTimeout(() => {
        this.enableOperationButtons = true;
      }, 5000);

    } else {
      if (this.settingParams.AllowCreditCards && this.settingParams.CreditCardTerminal.Id) {
        this.rabbitMQRequestId = this.rabbitMQService.guidWithTimeStamp();
        this.creditCardPaymentModalDetails = {
          Message: Messages.ConnectingCreditCardTerminal,
          HeaderText: 'Info',
          ButtonText: 'Cancel',
          IsTransactionComplete: false
        };
        this.creditCardPaymentPopupModalRef = this.bsModalService.show(this.creditCardPaymentPopup, {
          'backdrop': 'static',
          'class': 'vertical-center',
          keyboard: false
        });
        this.modalBackdropService.addBackDrop(3);
        this.addBalanceToGiftCard(this.prepareGiftCardCreditBalanceRequest(), true);
        this.checkCCPaymentStatusInterval = setInterval(() => {
          this.checkCCPaymentStatus();
        }, 5000);

      } else {
        this.alertService.renderErrorMessage(StringUtils.format(Messages.RequiredCreditCardTerminalMappedToAddBalance,
          { 'terminalName': this.settingParams.TerminalName }));
      }
    }
  }

  onApprove() {
    if (this.confirmApproveModalRef) {
      this.confirmApproveModalRef.hide();
      this.modalBackdropService.removeBackdrop();
    }
    this.addBalanceToGiftCard(this.prepareGiftCardCashBalanceRequest(2, this.settingParams.CashDrawerPrinter.Id));
    this.applyGiftCardPromotion(this.giftCardDetail.CardNumber, this.amount);
  }

  onDecline() {
    if (this.confirmApproveModalRef) {
      this.confirmApproveModalRef.hide();
      this.modalBackdropService.removeBackdrop();
    }
  }

  onCancelCCPayment() {
    if (this.creditCardPaymentPopupModalRef) {
      this.creditCardPaymentPopupModalRef.hide();
      this.modalBackdropService.removeBackdrop();
    }
    // if (this.msgTimeout) {
    //   clearTimeout(this.msgTimeout);
    // }
    if (!this.creditCardPaymentModalDetails.IsTransactionComplete) {
      const cancelRequestModel = {
        TerminalId: this.applicationStateService.terminalId,
        TerminalName: this.applicationStateService.terminalName,
        CreditCardTerminalId: this.settingParams.CreditCardTerminal.Id,
        RequestId: this.rabbitMQRequestId
      };
      this.giftCardService.cancelCreditCardTransaction(cancelRequestModel, 0)
        .subscribe({
          next: (res) => {

          }, error: this.alertService.showApiError
        });
    }
    if (this.creditCardPaymentModalDetails.HeaderText === Messages.Success && this.creditCardPaymentModalDetails.IsTransactionComplete) {
      // Add balance completed
      this.applyGiftCardPromotion(this.giftCardDetail.CardNumber, this.amount);
    }

  }

  subscribePaymentSuccessMessages() {
    this.rabbitMqOrderEntryTerminalQueueSubscription = this.rabbitMQService.subscribeToOrderPaymentResponseTerminalQueue$(this.applicationStateService.terminalId)
      .subscribe({
        next: (message: any) => {
          this.clearCCPaymentStatusInterval();
          this.paymentResponseCompleted(message.Payload.PaymentResponse);
        }
      });
  }

  subscribeHICResponseToTerminal() {
    this.rabbitMqHICResponseSubscription = this.rabbitMQService.subscribeToHICResponseToTerminal$(this.applicationStateService.terminalId)
      .subscribe({
        next: (message: any) => {
          const responseObj = message.Payload.HICReponse;
          if (responseObj.HardwareType === 'CreditCardTerminal') {
            if (responseObj.Status === 'Connected') {
              this.creditCardPaymentModalDetails.Message = Messages.SwipeCardRequest;
              this.creditCardPaymentModalDetails.ButtonText = 'Cancel';
              this.creditCardPaymentModalDetails.HeaderText = 'Info';
            } else if (responseObj.Status === 'Busy') {
              if (responseObj.ConnectedToTerminalId == this.applicationStateService.terminalId) {
                this.creditCardPaymentModalDetails.Message = Messages.CreditCardTerminalBusyWithPreviousRequest;
                this.clearCCPaymentStatusInterval();
              } else {
                this.creditCardPaymentModalDetails.Message = StringUtils.format(Messages.CreditCardTerminalBusy,
                  { 'terminalName': responseObj.ConnectedToTerminalName, 'creditCardTerminalName': responseObj.DeviceName });
              }
              this.creditCardPaymentModalDetails.ButtonText = 'OK';
              this.creditCardPaymentModalDetails.HeaderText = 'Info';
              this.creditCardPaymentModalDetails.IsTransactionComplete = true;
            } else if (responseObj.Error === 'Error') {
              this.clearCCPaymentStatusInterval();
              this.creditCardPaymentModalDetails.Message = responseObj.Message;
              this.creditCardPaymentModalDetails.ButtonText = 'OK';
              this.creditCardPaymentModalDetails.HeaderText = 'Error';
              this.creditCardPaymentModalDetails.IsTransactionComplete = true;
            }

          }
        }
      });
  }

  paymentResponseCompleted(response) {
    if (response.PaymentCompleted) {
      this.creditCardPaymentModalDetails.IsTransactionComplete = true;
      this.creditCardPaymentModalDetails.Message = response.PaymentResponseFromWeb.Message;
      this.creditCardPaymentModalDetails.ButtonText = 'OK';
      if (response.PaymentSuccessfullyComplete) {
        this.creditCardPaymentModalDetails.HeaderText = Messages.Success;
      } else {
        this.creditCardPaymentModalDetails.HeaderText = Messages.Error;
      }

    }
  }

  positiveNegativeGiftCardBalance() {
    this.isNegativeAmount = !this.isNegativeAmount;
    if (this.isNegativeAmount) {
      this.numpadOptions.prefix = `C  ${this.settingParams.CurrencySymbol}`;
    } else {
      this.numpadOptions.prefix = this.settingParams.CurrencySymbol;
    }
    this.numpadOptionChange.next({ value: this.amount, options: this.numpadOptions });
  }

  // onValueChange(value) {
  //   this.amount = value;
  // }

  onMessageKeyboard() {
    this.memoKeyBoardOptions.isOpen = true;
    this.numpadOptions.disable = true;
    this.numpadOptionChange.next({ value: this.amount, options: this.numpadOptions });
    const keyboardModalRef = this.modalService.show(KeyboardComponent, {
      animated: false,
      class: 'vertical-center modal-max-width-95',
      initialState: {
        keyboardId: 'memoKeyboard',
        isOpen: true,
        options: this.memoKeyBoardOptions,
        value: this.memo
      }
    });
    keyboardModalRef.close.subscribe(res => {
      if (res) {
        this.memo = res.value;
      }
      this.numpadOptions.disable = false;
      this.numpadOptionChange.next({ value: this.amount, options: this.numpadOptions });
    });

    this.memoKeyBoardOptions.isOpen = true;
    this.keyboardId = 'memoKeyboard';
    this.isOpen = true;
    this.msgText = this.memo;
  }

  getTenderTypesColor() {
    this.tenderTypeColors = {}
    let tenderTypesColors = this.settingParams.TenderTypesColors
      && this.settingParams.TenderTypesColors.length > 0 ? this.settingParams.TenderTypesColors.split(',') : [];
    _.forEach(tenderTypesColors, (tenderColor) => {
      let color = tenderColor.split(':');
      if (color && color[0] == DomainConstants.TenderTypes.CASH.Code) {
        this.tenderTypeColors.Cash = color[1]
      }
      else if (color && color[0] == DomainConstants.TenderTypes.CREDIT_CARD.Code) {
        this.tenderTypeColors.CreditCard = color[1]
      }
      else if (color && color[0] == DomainConstants.TenderTypes.GRATIS.Code) {
        this.tenderTypeColors.Gratis = color[1]
      }
    });
  }

}


// <!-- <pos-key-board [keyboardId]="keyboardId" [(isOpen)]="isOpen" [options]="memoKeyBoardOptions"
//   (valueChanged)="memo = $event" [value]="msgText">
// </pos-key-board> -->
