import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize, forkJoin, Observable } from 'rxjs';
import { InventoryProductDetails, InventoryProductService, Messages, StringUtils } from 'src/app/shared';
import { InventoryOffSiteLocation, InventoryOffSiteLocationService, InventoryOffSiteInventoryProduct, InventoryOffsiteInventoryProductService } from 'src/app/information-management/inventory-off-site-locations';
import { BaseFormComponent, InfoModalComponent, ModalService, SpinnerService } from 'src/app/shared/components';
import { dollyFlatbedAlt } from 'src/app/shared/components/icon';
import { AlertsService, FormUtilityService } from 'src/app/shared/services';
import { InventoryTransfer } from './interfaces/inventory-transfer';
import { clone, filter, find } from 'lodash';
import Pluralize from 'pluralize';
import { NgSelectComponent } from '@ng-select/ng-select/lib/ng-select.component';

@Component({
  selector: 'pos-inventory-transfer',
  templateUrl: './inventory-transfer.component.html',
  styleUrls: ['./inventory-transfer.component.scss']
})
export class InventoryTransferComponent extends BaseFormComponent implements OnInit {

  fromLocations: Array<InventoryOffSiteLocation> = [];
  toLocations: Array<InventoryOffSiteLocation> = [];
  inventoryProducts: Array<InventoryProductDetails> = [];
  filteredInventoryProducts: Array<InventoryProductDetails> = [];
  inventoryOffsiteProducts: Array<InventoryOffSiteInventoryProduct> = [];
  selectedProductQty = 0;
  transferInventory: InventoryTransfer = { FromLocation: null, ToLocation: null, InventoryProductId: null, Qty: 0, OverriddenQty: 0 };
  Pluralize = Pluralize;
  icons = {
    dollyFlatbedAlt
  }
  @ViewChild('transferInventoryForm') transferInventoryForm: NgForm;
  @ViewChild('inventoryProductInput') inventoryProductInput: NgSelectComponent;
  selectedInventoryProduct: InventoryProductDetails;
  maxAllowedQty = 0;
  tempTransferInventory: InventoryTransfer = null;

  get getForm(): NgForm {
    return this.transferInventoryForm;
  }

  constructor(private router: Router,
    private alertService: AlertsService,
    private inventoryOffSiteLocationService: InventoryOffSiteLocationService,
    private inventoryOffSiteProductService: InventoryOffsiteInventoryProductService,
    private inventoryProductService: InventoryProductService,
    private modalService: ModalService,
    private spinnerService: SpinnerService,
    formUtilityService: FormUtilityService) {
    super(formUtilityService);
  }

  ngOnInit(): void {
    this.loadDependencies();
  }

  loadDependencies() {
    this.spinnerService.show();
    const observables: Array<Observable<any>> = [];
    observables.push(this.inventoryOffSiteLocationService.getActiveLocations());
    observables.push(this.inventoryOffSiteProductService.getAll());
    observables.push(this.inventoryProductService.getActiveInventoryProducts(true));
    forkJoin(observables)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: ([inventoryLocations, inventoryOffsiteProducts, inventoryProducts]:
          [Array<InventoryOffSiteLocation>, Array<InventoryOffSiteInventoryProduct>, Array<InventoryProductDetails>]) => {
          this.fromLocations = inventoryLocations;
          this.toLocations = [...this.fromLocations];
          this.inventoryOffsiteProducts = inventoryOffsiteProducts;
          this.inventoryProducts = inventoryProducts;
          this.filteredInventoryProducts = [...inventoryProducts];
          if (this.tempTransferInventory) {
            this.transferInventory.FromLocation = this.tempTransferInventory.FromLocation ?? 0;
            this.fromLocationChanged();
            this.transferInventory.ToLocation = this.tempTransferInventory.ToLocation ?? 0;
            this.setInitialValue();
            const inputFocus = setTimeout(() => {
              this.inventoryProductInput.searchInput.nativeElement.focus();
              clearTimeout(inputFocus);
            });
          }
        }, error: this.alertService.showApiError
      });
  }

  fromLocationChanged() {
    this.transferInventory.ToLocation = null;
    this.transferInventory.InventoryProductId = null;
    this.transferInventory.Qty = null;
    this.transferInventory.OverriddenQty = null;
    this.selectedInventoryProduct = null;
    if (this.transferInventory.FromLocation != null) {
      this.toLocations = this.fromLocations.filter(x => x.Id != this.transferInventory.FromLocation);
      const availableProductIds = this.inventoryOffsiteProducts.filter(x => x.InventoryOffsiteLocationId == this.transferInventory.FromLocation && x.CalculatedQty > 0).map(x => x.InventoryProductId);
      this.filteredInventoryProducts = this.transferInventory.FromLocation > 0 ? filter(this.inventoryProducts, x => availableProductIds.includes(x.Id)) : [...(this.inventoryProducts.filter(x => x.CalculatedQty > 0))];
    } else {
      this.toLocations = [...this.fromLocations];
    }
    if (this.toLocations.length == 1) {
      this.transferInventory.ToLocation = this.toLocations[0].Id;
    }
  }

  inventoryProductSelected() {
    this.selectedProductQty = 0;
    this.transferInventory.Qty = null;
    this.transferInventory.OverriddenQty = null;
    this.selectedInventoryProduct = null;
    if (this.transferInventory.InventoryProductId) {
      this.selectedInventoryProduct = find(this.inventoryProducts, x => x.Id == this.transferInventory.InventoryProductId);
      this.transferInventory.UnitQty = this.selectedInventoryProduct.UnitQty;
      this.transferInventory.Qty = 1;
      if (this.transferInventory.FromLocation) {
        this.selectedProductQty = find(this.inventoryOffsiteProducts, x => x.InventoryOffsiteLocationId == this.transferInventory.FromLocation && x.InventoryProductId == this.transferInventory.InventoryProductId)?.CalculatedQty ?? 0;
      } else {
        this.selectedProductQty = this.selectedInventoryProduct.CalculatedQty;
      }
      this.updateAndValidateQtyInput();
    }
  }

  submitTransferInventory(isValid: boolean) {
    if (!isValid) {
      return;
    }
    const qtyToBeTransferred = this.transferInventory.Qty * (this.transferInventory.OverriddenQty ?? this.selectedInventoryProduct.UnitQty);
    if (qtyToBeTransferred > 0) {
      const transferQty = this.getTransferQty(qtyToBeTransferred);
      const confirmDelete = this.modalService.getModalWrapper(InfoModalComponent)
      const modalRef = confirmDelete.show({
        animated: false,
        class: 'vertical-center',
        'backdrop': 'static',
        initialState: {
          modalHeaderText: 'Confirm Transfer',
          confirmButtonText: 'Yes',
          rejectButtonText: 'No',
          message: StringUtils.format(Messages.ConfirmTransferInventory, { qty: transferQty, unit: Pluralize(this.selectedInventoryProduct.UnitName, qtyToBeTransferred), product: this.selectedInventoryProduct.Name, fromLocation: this.getLocationName(this.transferInventory.FromLocation), toLocation: this.getLocationName(this.transferInventory.ToLocation) })
        }
      });
      modalRef.close.subscribe(res => {
        if (res?.shouldConfirm) {
          this.transferInventoryToLocation();
        }
      });
    }
  }

  private getTransferQty(qtyToBeTransferred: number) {
    const splittedQty = qtyToBeTransferred.toString().split('.');
    let decimalPlacesLength = splittedQty && splittedQty[1] ? splittedQty[1].length : 0;
    if (decimalPlacesLength > 4) {
      return qtyToBeTransferred.toFixed(4);
    } else if (decimalPlacesLength === 1) {
      return qtyToBeTransferred.toFixed(2);
    } else {
      return qtyToBeTransferred.toString()
    }
  }

  getLocationName(locationId) {
    return find(this.fromLocations, x => x.Id == locationId)?.Name;
  }

  transferInventoryToLocation() {
    this.spinnerService.show();
    this.tempTransferInventory = clone(this.transferInventory);
    this.inventoryOffSiteLocationService.transferInventory(this.tempTransferInventory)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.selectedProductQty = 0;
          this.selectedInventoryProduct = null;
          this.alertService.renderSuccessMessage(Messages.TransferInventorySaveSuccess);
          this.transferInventoryForm.resetForm();
          this.loadDependencies();
        }, error: this.alertService.showApiError
      });
  }

  cancel() {
    this.router.navigate(['manage/console']);
  }

  updateAndValidateQtyInput() {
    this.maxAllowedQty = this.selectedProductQty / (!this.transferInventory.OverriddenQty ? this.selectedInventoryProduct?.UnitQty : this.transferInventory.OverriddenQty);
  }

}
