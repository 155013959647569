import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { DomainConstants } from 'src/app/shared';
import { MenuWidgetService } from 'src/app/menu-display/services';
import { OutOfStockBehavior } from '../../../interfaces';

@Component({
  selector: 'pos-out-of-stock-behavior',
  templateUrl: './out-of-stock-behavior.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class OutOfStockBehaviorComponent implements OnChanges {
  @Input() outOfStockBehavior: OutOfStockBehavior
  @Output() outOfStockBehaviorChange = new EventEmitter<OutOfStockBehavior>()
  @Input() prefix: string;
  outOfStockBehaviorTypes = DomainConstants.OutOfStockBehaviorTypes;

  constructor(protected menuWidgetService: MenuWidgetService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('outOfStockBehavior') && !this.outOfStockBehavior) {
      this.outOfStockBehavior = this.menuWidgetService.getNewOutOfStockBehavior();
    }
    this.outOfStockBehaviorChange.emit(this.outOfStockBehavior);
  }

}
