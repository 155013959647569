<pos-edit-wrapper heading="External API" (close)="onCancel()" [headingIcon]="icons.externalApiLink" [isPopup]="false">
    <form #formExternalApi="ngForm" (ngSubmit)="saveExternalApi(formExternalApi.valid)" autocomplete="off">
        <div class="col-12 border-top pt-pix-5">
            <div class="col-12 pos-page-body content-scroll">
                <h3 class="setting-title"><span>API</span></h3>
                <pos-row class="pos-title-content">
                    <pos-column>
                        <pos-form-field label="Name" [fieldInfo]="fieldInfoMessages.ExternalApiName"
                            [validationMessages]="{required: 'Please enter api name.'}">
                            <input posInput type="text" name="apiName" #apiName="ngModel" class="form-control"
                                [(ngModel)]="externalApi.Name" [required]="true" />
                        </pos-form-field>
                    </pos-column>
                    <pos-column xs="3" class="col-220 pe-0">
                        <pos-form-field inputStyle="col-12">
                            <ng-select posInput [items]="apiVerbs" #apiVerb="ngModel" name="apiVerb" bindLabel="Name"
                                bindValue="id" [(ngModel)]="externalApi.ApiVerb" [clearable]="false" appendTo="body"
                                [clearOnBackspace]="false" (change)="onAPiVerbChange(externalApi.ApiVerb)"></ng-select>
                        </pos-form-field>
                    </pos-column>
                    <pos-column lg="6" sm="9" xs="12" class="pe-0 mb-pix-5">
                        <pos-form-field [validationMessages]="{required: 'Please enter url.'}"
                            inputStyle="d-flex flex-nowrap" inputStyle="col-12">
                            <span class="col-11 p-0 d-flex flex-nowrap">
                                <p class="input-group flex-grow-1">
                                    <input posInput type="text" name="url"
                                        class="form-control border-tb-right border-tb-left" #url="ngModel"
                                        [(ngModel)]="externalApi.Url" placeholder="URL" [required]="true" />
                                    <span class="input-group-addon pointer" (click)="onParamsBtnClick()"
                                        scrollup>Params</span>
                                </p>
                                <field-info [info]="fieldInfoMessages.ExternalApiVerb" [placement]="'left'"
                                    class="pt-pix-7 ps-pix-25">
                                </field-info>
                            </span>
                        </pos-form-field>
                    </pos-column>
                    <pos-column *ngIf="toggleShowHideParamsGrid" xs="12" class="px-pix-5">
                        <table class="table table-hover mb-0" id="rptParams">
                            <thead>
                                <tr>
                                    <th>Key</th>
                                    <th>Value</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let externalApiQueryParam of externalApi.ExternalApiQueryParams; let last = last;let index = index"
                                    [hidden]="!externalApiQueryParam.ParamTypeId">
                                    <td class="grey pti-0"
                                        [ngClass]="{'has-error': ((paramName+index).invalid && formExternalApi.submitted && externalApiQueryParam.ParamValue)}">
                                        <pos-form-field inputStyle="col-12 ps-pix-10"
                                            [validationMessages]="{required: 'Please enter parameter key.'}">
                                            <input posInput type="text" name="paramName{{index}}" class="form-control"
                                                (keypress)="createExternalApiQueryParamRow(last)"
                                                [required]="toggleShowHideParamsGrid && !last && externalApiQueryParam.ParamValue"
                                                [(ngModel)]="externalApiQueryParam.ParamName" />
                                        </pos-form-field>
                                    </td>
                                    <td class="grey pti-0">
                                        <pos-form-field inputStyle="col-12 ps-pix-10 ">
                                            <input posInput type="text" name="paramValue{{index}}" class="form-control"
                                                (keypress)="createExternalApiQueryParamRow(last)"
                                                [(ngModel)]="externalApiQueryParam.ParamValue" />
                                        </pos-form-field>
                                    </td>
                                    <td class="grey">
                                        <pos-icon [icon]="icons.times" *ngIf="!last" class="d-block mt-pix-10 red"
                                            (click)="clearExternalApiQueryParamRow(index)"></pos-icon>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </pos-column>
                </pos-row>
                <div class="col-12 section-border p-0">
                    <div>
                        <h3 class="setting-title">
                            <span>Authorization</span>
                        </h3>
                    </div>
                    <context-provider provide="props">
                        <pos-row class="pos-title-content">
                            <pos-column>
                                <pos-form-field label="Type"
                                    [validationMessages]="{required: 'Please select authorization type.'}">
                                    <ng-select posInput [items]="authorizationTypes" name="authorizationType"
                                        bindLabel="Name" bindValue="id" #authorizationType="ngModel" [required]="true"
                                        [(ngModel)]="externalApi.AuthorizationType" appendTo="body"
                                        (change)="onAuthTypeChange(externalApi.AuthorizationType)"></ng-select>
                                </pos-form-field>
                            </pos-column>
                            <pos-column *ngIf="toggleShowHideAuthTypeBasic">
                                <pos-form-field label="Username"
                                    [validationMessages]="{required: 'Please enter username.'}">
                                    <input posInput type="text" name="userName" class="form-control" #userName="ngModel"
                                        [(ngModel)]="externalApi.UserName"
                                        [required]="externalApi.AuthorizationType == basicAuthTypeId" />
                                </pos-form-field>
                            </pos-column>
                            <pos-column *ngIf="toggleShowHideAuthTypeBasic">
                                <pos-form-field label="Password"
                                    [validationMessages]="{required: 'Please enter password.'}">
                                    <input posInput type="password" name="password" class="form-control"
                                        #password="ngModel" [(ngModel)]="externalApi.Password"
                                        [required]="externalApi.AuthorizationType == basicAuthTypeId" />
                                </pos-form-field>
                            </pos-column>
                            <pos-column *ngIf="toggleShowHideAuthTypeToken">
                                <pos-form-field label="Token" [validationMessages]="{required: 'Please enter token.'}">
                                    <input posInput type="text" name="token" class="form-control" #token="ngModel"
                                        [(ngModel)]="externalApi.Token"
                                        [required]="externalApi.AuthorizationType == tokenAuthTypeId" />
                                </pos-form-field>
                            </pos-column>
                        </pos-row>
                    </context-provider>
                </div>
                <div class="col-12 section-border p-0" *ngIf="toggleShowHideBodyParamsGrid">
                    <div>
                        <h3 class="setting-title mt-pix-5">
                            <span>Body Params</span>
                        </h3>
                    </div>
                    <pos-column xs="12">
                        <table class="table table-hover" id="rptBodyParams">
                            <thead>
                                <tr>
                                    <th>Key</th>
                                    <th>Value</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let externalApiBodyParam of externalApi.ExternalApiBodyParams; let last = last;let index = index">
                                    <td class="grey pti-0"
                                        [ngClass]="{'has-error': (bodyParamName.invalid && formExternalApi.submitted && externalApiBodyParam.ParamValue)}">
                                        <pos-form-field inputStyle="col-12 ps-pix-10"
                                            [validationMessages]="{required: 'Please enter parameter key.'}">
                                            <input posInput type="text" name="bodyParamName{{index}}"
                                                #bodyParamName="ngModel" class="form-control"
                                                (keypress)="createExternalApiBodyParamRow(last)"
                                                [(ngModel)]="externalApiBodyParam.ParamName"
                                                [required]="toggleShowHideBodyParamsGrid && !last  && externalApiBodyParam.ParamValue" />
                                        </pos-form-field>
                                    </td>
                                    <td class="grey pti-0">
                                        <pos-form-field inputStyle="col-12 ps-pix-10">
                                            <input posInput type="text" class="form-control"
                                                name="bodyParamValue{{index}}"
                                                (keypress)="createExternalApiBodyParamRow(last)"
                                                [(ngModel)]="externalApiBodyParam.ParamValue" />
                                        </pos-form-field>
                                    </td>
                                    <td class="grey">
                                        <pos-icon [icon]="icons.times" *ngIf="!last" class="d-block mt-pix-10 red"
                                            (click)="clearExternalApiBodyParamRow(index)"></pos-icon>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </pos-column>
                </div>
            </div>
        </div>
        <div class="col-12 action-button-container page-footer">
            <button type="submit" value="Save" class="btn btn-primary btn-lg btn-save pos-button" scrollup>Save</button>
            <button type="button" class="btn btn-secondary btn-lg btn-cancel pos-button" value="Cancel"
                (click)="onCancel()" scrollup>Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
