import { NgModule } from '@angular/core';
import { CommonModule, SlicePipe } from '@angular/common';
import { InboxMessageService } from './services';
import { InboxMessagesListComponent, InboxMessagesEditComponent, InboxMessagePopoverComponent, ViewMessageComponent } from './components';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule } from '@angular/forms';

import { ComponentsModule, TableConfiguration } from '../shared';
import { TableModule } from '@tarktech/tark-ng-utils';
import { TableModule as PrimengTableModule } from 'primeng/table';
import { NgSelectModule } from '@ng-select/ng-select';
import { CKEditorModule } from 'ckeditor4-angular';
import { MessageBoxComponent } from './components/message-box/message-box.component';
import { CheckboxModule } from 'primeng/checkbox';
import { SentMessagesComponent } from './components/sent-messages/sent-messages.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ModalModule.forRoot(),
    ComponentsModule,
    TableModule.forRoot(TableConfiguration),
    NgSelectModule,
    CKEditorModule,
    PrimengTableModule,
    CheckboxModule
  ],
  providers: [InboxMessageService, SlicePipe],
  declarations: [InboxMessagesListComponent, InboxMessagesEditComponent, InboxMessagePopoverComponent, ViewMessageComponent, MessageBoxComponent, SentMessagesComponent],
  exports: [InboxMessagesListComponent, InboxMessagesEditComponent, InboxMessagePopoverComponent, ViewMessageComponent]
})
export class InboxMessagesModule { }
