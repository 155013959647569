import { Component, OnInit } from '@angular/core';
import {
  BaseListComponent,
  AuthenticationService,
  AlertsService,
  SpinnerService,
  ModalService,
  PrintTableService,
  Messages,
  Permissions,
  Levels,
  DomainConstants,
  ApplicationStateService
} from 'src/app/shared';
import { SlideShow } from '../../interface/slide-show';
import { SlideShowService } from '../../services/slide-show.service';
import { GridColumn, LinkColumn } from '@tarktech/tark-ng-utils';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { digitalSignageBullHorn } from 'src/app/shared/components/icon';
@Component({
  selector: 'pos-slide-show-list',
  templateUrl: './slide-show-list.component.html',
})
export class SlideShowListComponent extends BaseListComponent<SlideShow> implements OnInit {

  slideShowColumns: Array<GridColumn> = [];
  slideShowList: Array<SlideShow> = [];
  permission = {
    name: Permissions.SystemConfigurationDigitalSignage,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };
  icons = { digitalSignageBullHorn };

  constructor(private authenticationService: AuthenticationService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected slideShowService: SlideShowService,
    protected printService: PrintTableService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected applicationStateService: ApplicationStateService) {
    super(slideShowService, alertService, spinnerService, modalService, applicationStateService, printService, route);
  }

  ngOnInit() {
    this.isPrintIconVisible = false;
    this.deleteSuccessMessage = Messages.SlideshowDeleted;
    this.slideShowColumns = this.configureGridColumns();
    this.checkPermission();
    this.loadSubscriptions();
    console.log(this.slideShowColumns);
  }

  public getGridColumns(): Array<GridColumn> {
    return [
      new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '90%' })
    ] as Array<GridColumn>;
  }

  private loadSubscriptions(): void {
    this.data$.subscribe({
      next: (res) => {
        this.slideShowList = res;
      }
    });
  }

  private checkPermission(): void {
    if (!this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      _.remove(this.slideShowColumns, (column) => {
        return (column.Type === DomainConstants.GridColumnsTypes.EditColumn
          || column.Type === DomainConstants.GridColumnsTypes.DeleteColumn);
      });
    } else {
      const imageColumn = new LinkColumn({
        Clicked: (data) => {
          this.imageItem(data.Id);
        },
        CellClass: 'cell-delete pi-0',
        Width: '100px',
        Icon: 'far fa-image',
        ToolTip: 'Configure Slides'
      });
      this.slideShowColumns.push(imageColumn);
    }
  }

  public getConfirmDeleteMessage(data: SlideShow): string {
    return Messages.ConfirmDeleteSlideshow;
  }

  public editItem(slideShowID: number) {
    this.router.navigate([slideShowID], { relativeTo: this.route });
  }

  imageItem(slideShowID: number) {
    this.router.navigate(['image', slideShowID], { relativeTo: this.route });
  }

  close(){
    this.router.navigate(['../'], { relativeTo: this.route });
  }

}
