<div>
  <button class="close" (click)="onClose()">&times;</button>
  <h4 class="modal-content__heading">Tasks</h4>
</div>
<div class="p-pix-10 pb-0 overflow-hidden shift-duty-modal">
  <div class="row g-0">
      <div class="row g-0 col-12 p-0">
        <div class="g-0 col-12 p-0 d-flex justify-content-end align-items-center">
          <div class="col-lg-2 col-md-2 col-sm-3 col-xs-5 p-0 pt-pix-2">
            <ng-select [items]="statusList" bindLabel="Name" [clearable]="false" bindValue="Value"
              placeholder="Select task status" appendTo="body" (change)="filterData(selectedStatus)"
              [(ngModel)]="selectedStatus" name="taskStatus" id="taskStatus">
              <ng-template ng-label-tmp let-item="item">
                <fa-icon class="{{item.Icon}} pe-pix-5"></fa-icon>
                <span class="{{item.Color}}">{{item.Name}}</span>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <fa-icon class="{{item.Icon}} pe-pix-5"></fa-icon>
                <span class="{{item.Color}}">{{item.Name}}</span>
              </ng-template>
            </ng-select>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 p-0 col-100"
            permissions='[{"Name":"{{permission.name}}", "Level":"{{permission.editLevel}}"}]'>
            <button class="btn btn-orange normal float-end display-flex pos-button" (click)="addNewTask()" type="button"
              value="New">
              <pos-icon class="mt-pix-1 pe-pix-3" [icon]="icons.plus"></pos-icon>
              <div class="mt-pix-3 ps-pix-2"> New</div>
            </button>
          </div>
        </div>
        <div class="row g-0 col-12 p-0">
          <div class="col-12 ps-0 pe-pix-10 screen-scroll min-height mt-pix-10">
            <div class="col-12 p-0"
              *ngFor="let tasks of groupedTask| keyvalue; let i = index">
              <div class="col-12 task-hours" [ngClass]="{'mti-25': i !== 0}"
                (click)="clickOnTimeGroup(tasks)">
                <pos-icon class="" *ngIf="!tasks?.value[0]?.isClosed" [icon]="icons.taskCollapseCaretDown"></pos-icon>
                <pos-icon class="" *ngIf="tasks?.value[0]?.isClosed" [icon]="icons.taskCollapseCaretRight"></pos-icon>
                {{tasks?.value[0]?.date | tark_time }}
              </div>
              <span *ngFor="let item of tasks.value| orderBy:'-due_on'; let taskIndex = index">
                <div class="g-0 row col-lg-12 col-md-12 col-sm-12 ps-pix-5 pe-pix-5" *ngIf="!tasks?.value[0]?.isClosed">
                  <div class="row g-0 col-12 p-0 task-border"
                    [ngClass]="{'border-none-important': taskIndex == tasks.value.length - 1}">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 p-0 col-25 mt-pix-n1 pt-pix-8"
                      (click)="updateShiftDuties(item)">
                      <pos-icon class="light-grey" *ngIf="!item.signed_off_on && !item.skipped && !item.IsStarted"
                        [icon]="icons.taskTodo"></pos-icon>
                      <pos-icon class="green" *ngIf="item.signed_off_on && !item.skipped && !item.IsStarted"
                        [icon]="icons.checkCircleTask">
                      </pos-icon>
                      <pos-icon class="warning-color font-20" *ngIf="item.skipped" [icon]="icons.taskSkip">
                      </pos-icon>
                      <pos-icon class="medium-violet" [icon]="icons.hourglassTask" 
                        *ngIf="item.IsStarted && !item.skipped"></pos-icon>
                    </div>
                    <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10 strip-padding ps-pix-5 pointer"
                      (click)="updateShiftDuties(item)" [ngClass]="{'comment': item.signed_off_on || item.skipped || item.IsStarted}">
                      <p [ngClass]="{'line-through pe-pix-3': item.signed_off_on && !item.skipped && !item.IsStarted}">{{item.taskname}}</p>
                      <p class="font-12 italic" *ngIf="(item.signed_off_on || item.skipped) && !item.IsStarted">({{item.Comment}}
                        {{item.signed_off_on | tark_date_time}})</p>
                      <p class="font-12 italic" *ngIf="item.IsStarted && !item.skipped">({{item.Comment}})</p>
                    </div>
                    <div class="col-lg-1 col-md-2 col-sm-2 col-xs-2 p-0 float-end flex-grow-1 col-90"
                      [ngClass]="{'pe-pix-28 pt-pix-1': item.signed_off_on && !item.skipped && !item.IsStarted}">
                      <span class="green pe-pix-5 task-status" *ngIf="item.signed_off_on && !item.skipped && !item.IsStarted">
                        Completed
                      </span>
                      <span class="warning-color pe-pix-5 task-status" *ngIf="item.skipped">
                        Skipped
                      </span>
                      <span class="pe-pix-5 task-status medium-violet" *ngIf="item.IsStarted && !item.skipped">Started</span>
                      <span [hidden]="item.signed_off_on && !item.skipped && !item.IsStarted" class="float-end pt-pix-6">
                        <a #pop="bs-popover" placement="left" appendTo='body' (click)="onLinkPopOver(item, pop)"
                          [outsideClick]="true" [popover]="operationPopoverTemplate" triggers="click">
                          <pos-action-icon [icon]="icons.cogWhite"></pos-action-icon>
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
<div class="col-12 action-button-container popup-footer pb-pix-10">
  <button class="btn-lg btn-secondary btn btn-cancel-modal pos-button" (click)="onClose()" type="button" value="Close">Close</button>
</div>
<div class="clearfix"></div>
<ng-template #shiftDutyConfirmationModal>
  <div>
    <div>
      <h4 class="modal-content__heading">Confirm</h4>
    </div>
    <div class="modal-body pb-0">
      <span>{{confirmationMessage}}</span>
    </div>
    <div class="col-12 action-button-container popup-footer pb-pix-10">
      <button class="btn btn-primary btn-lg btn-save pos-button" (click)="updateTaskUndone()" type="button">Yes </button>
      <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="closeConfirmationModal()"
        type="button">No</button>
    </div>
    <div class="clearfix"></div>
  </div>
</ng-template>

<ng-template #operationPopoverTemplate>
  <div class="col-90">
    <ul>
      <li class="listbullet pointer">
        <a class="grey" (click)="taskHelpRequest(taskDetails)">
          <pos-icon [icon]="icons.taskHelp" class="pe-pix-3 m-l-5">
          </pos-icon>
          Help
        </a>
      </li>
      <hr />
      <li class="listbullet pointer" *ngIf="!taskDetails.skipped">
        <a class="grey" (click)="skippedShiftDuties(taskDetails, true)">
          <pos-icon [icon]="icons.taskSkip" class="pe-pix-3 m-l-5">
          </pos-icon>
          Skip Task
        </a>
      </li>
      <li class="listbullet pointer" *ngIf="taskDetails.skipped">
        <a class="grey" (click)="skippedShiftDuties(taskDetails, false)">
          <pos-icon [icon]="icons.undo" class="pe-pix-3 m-l-5">
          </pos-icon>
          Undo Skip
        </a>
      </li>
    </ul>
  </div>
</ng-template>
