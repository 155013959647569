import { Component, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { djBars, play, stop, stepBackward, stepForward } from '../../shared/components/icon';
import { DjSchemeConfig } from './interface/dj-scheme-config';
import { DjService } from './services/dj.service';
import * as _ from 'lodash';
import { TerminalProperty } from 'src/app/configurator/terminals/interface/terminal-property';
import { TerminalsService } from 'src/app/configurator/terminals/services/terminals.service';
import { Playback, Scheme } from './interface/scheme';
import { PlaylistTrack } from 'src/app/configurator/playlists/interface/playlist-track';
import { SpinnerService } from 'src/app/shared/components/spinner';
import { AlertsService, ApplicationStateService, LoggerService, RabbitMQMessage, RabbitMQService } from 'src/app/shared/services';
import { DomainConstants, RuntimeConstants } from 'src/app/shared/constants';
import { DjPlaylist, DjTrack } from './interface/dj-playlist';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DJTerminalSpeakTextPayload } from 'src/app/shared/services/rabbitmq/models/djterminal-speak-text-payload';
import { forEach, keys, remove, sortBy } from 'lodash';
declare let $: any;
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pos-dj',
  templateUrl: './dj.component.html',
  styleUrls: ['./dj.component.scss']
})
export class DjComponent implements OnInit, OnDestroy {
  djSpeakTextRequestSubscription: Subscription;
  isPlayDjSpeakText: boolean = false;
  headerLinks = {
    tasks: false,
    admin: false,
    home: false,
    activeOrders: false,
    changeTerminal: false,
    mappedTerminals: false,
    signIn: false,
    time: false
  };
  homeState = 'dj';
  showAlert = false;
  currentTime: Date;
  icons = {
    djBars, play, stop, stepBackward, stepForward
  };
  audio = new Audio();
  isPlaying = false;
  isStopped = true;
  options: Playback;
  trackCount = 0;
  playlist: DjPlaylist;
  index = 0;
  category: string;
  recents: Array<any> = [];
  categorizedTracksCount = {};

  now = new Date();
  counter = '--:-- / --:--';

  djSchemeDetails: DjSchemeConfig;
  terminalVolume = 0;
  terminalProperties: Array<TerminalProperty>;
  schemes: Array<string> = [];
  configuredDjSchemes: Array<number> = [];

  nextSchemePlayed: string;
  volume = 0;
  DjScheme: string;
  scheme: Scheme;
  isLoadPlaylist = true;
  playlistTracks: Array<PlaylistTrack> | any = [];
  categories: Array<string> = [];
  playOrder = DomainConstants.PlayOrder;
  isPlayLiteral = false;

  djTitle = '';
  playBackTitle = '';
  djPlaybackTitle = '';
  duration = 0;
  currentProgress = 0;
  playlistName = '';
  selectedPlaylist = 0;
  djSpeakTextList = [];
  djSpeakText;
  oldAudioVolume = 0;
  djSpeakTextCounter = '--:-- / --:--';
  djSpeakTextDuration = 0;
  djSpeakTextProgress = 0;
  djSpeakTextFileName = '';
  djSpeakTextVolume = 0;
  playNextCount = 0;
  customVolume: number;
  constructor(private spinnerService: SpinnerService,
    private terminalService: TerminalsService,
    private djService: DjService,
    private alertsService: AlertsService,
    private applicationStateService: ApplicationStateService,
    private rabbitMQService: RabbitMQService,
    private loggerService: LoggerService) { }

  ngOnInit() {
    setInterval(() => {
      this.currentTime = new Date();
    }, 1000);
    this.addEventListeners(this.audio, this.playNext);
    this.loadData();
    this.rabbitMQSubscription();
  }

  ngOnDestroy(): void {
    this.pause();
    this.audio = null;
  }

  rabbitMQSubscription = () => {
    this.djSpeakTextRequestSubscription = this.rabbitMQService.subscribeToDJTerminalSpeakTextMessage$(this.applicationStateService.terminalId)
      .subscribe({
        next: (message: RabbitMQMessage<DJTerminalSpeakTextPayload>) => {
          this.loggerService.logWarning(`Dj speak text request received fileName: ${message?.Payload?.FileName}`);
          if (message.Payload.TerminalId === this.applicationStateService.terminalId) {
            this.djSpeakTextList = this.djSpeakTextList ? this.djSpeakTextList : [];
            this.djSpeakTextList.push({
              FileName: message.Payload.FileName, Volume: message.Payload.Volume,
              displayPath: this.removeTimeTicksFromFileName(message.Payload.FileName)
            });
            if (!this.isPlayDjSpeakText) {
              this.oldAudioVolume = this.audio.volume;
              this.isPlayDjSpeakText = true;
              this.playDJSpeakText(this.djSpeakTextList[0]);
            }
          }
        }, error: () => {
          console.log('error while connecting to RabbitMQ.');
        }
      });
  }

  removeTimeTicksFromFileName = (fileName): string => {
    return fileName.substr(fileName.indexOf('-') + 1, fileName.length);
  }

  private playDJSpeakText(djSpeakTextData) {
    this.audio.volume = 0;
    this.djSpeakTextCounter = '--:-- / --:--';
    this.djSpeakText = new Audio();
    this.djSpeakText.volume = djSpeakTextData.Volume ? (djSpeakTextData.Volume / 100) : this.oldAudioVolume;
    this.djSpeakTextVolume = this.djSpeakText.volume * 100;
    this.djSpeakTextFileName = djSpeakTextData.displayPath;
    this.djSpeakText.src = RuntimeConstants.MEDIA_BASE_PATH + DomainConstants.DjDirectories.speakText + '/' + djSpeakTextData.FileName;
    const playPromise = this.djSpeakText.play();
    this.loggerService.logWarning(`Started playing Speak Text ${this.djSpeakText.src}`);
    if (playPromise) {
      playPromise.then(() => {
        // Automatic playback started!
      }).catch((error) => {
        this.loggerService.logError(`Error occurred while playing the speak text: ${this.djSpeakText?.src}, Error: ${error?.toString()}`);
        this.audio.volume = this.oldAudioVolume;
        this.isPlayDjSpeakText = false;
      });
    }
    this.audioEndedEventListener(this.djSpeakText, () => { this.playAudioSchemeAfterSpeakText(djSpeakTextData); });
    this.timeUpdaterEventListener(this.djSpeakText);
    $(this.audio).finish();
    this.audio.volume = 0;
  }

  playAudioSchemeAfterSpeakText = (djSpeakTextData) => {
    remove(this.djSpeakTextList, (item) => {
      return item.FileName === djSpeakTextData.FileName;
    });
    if (this.djSpeakTextList && this.djSpeakTextList.length) {
      this.playDJSpeakText(this.djSpeakTextList[0]);
    } else {
      this.djSpeakTextList = [];
      this.audio.volume = this.oldAudioVolume;
      this.isPlayDjSpeakText = false;
      this.djSpeakTextCounter = '--:-- / --:--';
      this.djSpeakTextDuration = 0;
      this.djSpeakTextProgress = 0;
      this.djSpeakTextFileName = '';
      this.djSpeakTextVolume = 0;
      if (this.isPlaying) {
        const playPromise = this.audio.play();
        this.loggerService.logWarning(`Started playing audio ${this.audio.src}`)
        if (playPromise) {
          playPromise.then(() => {
            // Automatic playback started!
          }).catch((error) => {
            this.loggerService.logError(`Error occurred while playing audio: ${this.audio?.src}, Error: ${error?.toString()}`);
          });
        }
      }
    }
  }

  addEventListeners(audioObject, callBackFunction?) {
    this.audioEndedEventListener(audioObject, callBackFunction ? callBackFunction : this.playNext);
    this.audioErrorEventListener(audioObject, callBackFunction ? callBackFunction : this.playNext);
    // event listener for audio playback
    this.timeUpdaterEventListener(audioObject);
  }

  audioErrorEventListener = (audioObject, callBackFunction?) => {
    audioObject.addEventListener('error', (ev) => {
      // Error code 4 = file not found in the directory
      if (audioObject.error.code === 4) {
        this.showAlert = true;
        const showAlertTimeout = setTimeout(() => {
          this.showAlert = false;
          clearTimeout(showAlertTimeout);
        }, 5000);
        // playNextCount retry play next song for 5 songs if not found then it's stop playing the music
        if (this.playNextCount <= 5) {
          callBackFunction();
          this.playNextCount++;
        }
      }
    }, false);
  }

  audioEndedEventListener = (audioObject, callbackFunction) => {
    audioObject.addEventListener('ended', () => {
      // try to play next track in literal or random order
      callbackFunction();
    });
  }

  timeUpdaterEventListener = (audioObject) => {
    audioObject.addEventListener('timeupdate', () => {
      // getting the duration of the current track
      const duration = audioObject.duration ? audioObject.duration : 0;
      if (this.isPlayDjSpeakText && audioObject.src == this.djSpeakText.src) {
        this.djSpeakTextDuration = duration;
      } else {
        this.duration = duration
      }
      const progress = audioObject.currentTime;
      if (this.isPlayDjSpeakText && audioObject.src == this.djSpeakText.src) {
        this.djSpeakTextProgress = progress;
      } else {
        this.currentProgress = progress;
      }

      // converting secounds to mm:ss
      const dMin = Math.floor(duration % (60 * 60) / 60);
      const dSec = Math.ceil(duration % (60 * 60) % 60);

      const cMin = Math.floor(progress % (60 * 60) / 60);
      const cSec = Math.ceil(progress % (60 * 60) % 60);

      // update interface with values
      const counter = (cMin < 10 ? '0' : '') + cMin + ':' + (cSec < 10 ? '0' : '') + cSec + ' / ' + (dMin < 10 ? '0' : '')
        + dMin + ':' + (dSec < 10 ? '0' : '') + dSec;

      if (this.isPlayDjSpeakText && audioObject.src == this.djSpeakText.src) {
        this.djSpeakTextCounter = counter;
      } else {
        this.counter = counter;
      }
    });
  }

  loadData() {
    const observable: Array<Observable<any>> = [];
    observable.push(this.djService.getDJSchemesDetails(this.applicationStateService.terminalId));
    observable.push(this.terminalService.getTerminalProperties(this.applicationStateService.terminalId));
    this.spinnerService.show();
    forkJoin(observable)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: ([djSchemeDetails, terminalProperties]: [DjSchemeConfig, Array<TerminalProperty>]) => {
          this.djSchemeDetails = djSchemeDetails;
          this.terminalProperties = terminalProperties;
          _.forEach(terminalProperties, (property) => {
            if (DomainConstants.TerminalProperties.VOLUME.Key === property.PropertyKey) {
              this.terminalVolume = parseInt(property.PropertyValue, 10);
            }
            if (DomainConstants.TerminalProperties.DjScheme.Key === property.PropertyKey && property.PropertyValue) {
              this.configuredDjSchemes = property.PropertyValue.split(',').map(x => parseInt(x, 10));
            }
          });
          this.getDJSchemesDetailsCompleted();
        },
        error: this.alertsService.showApiError
      });
  }

  getDJSchemesDetailsCompleted() {
    let autoStart: boolean | number = false;
    if (this.djSchemeDetails && this.djSchemeDetails.schemes) {
      _.forEach(this.djSchemeDetails.schemes, (djSchemeDetail: Scheme, i) => {
        const djScheme = _.find(this.configuredDjSchemes, (schemeId) => {
          return schemeId === djSchemeDetail.Id;
        });
        if (!djScheme) {
          djSchemeDetail.startTime = null;
          djSchemeDetail.endTime = null;
        }
        if (djSchemeDetail.startTime) {
          const time = djSchemeDetail.startTime.split(':').map(x => parseInt(x, 10));
          const start = new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate(), time[0], time[1]);
          if (start <= this.now) { autoStart = i; }
          this.setScheme(time[0], time[1], djSchemeDetail.name, 'start');
        }
        if (djSchemeDetail.endTime) {
          const time = djSchemeDetail.endTime.split(':').map(x => parseInt(x, 10));
          const end = new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate(), time[0], time[1]);
          if (end <= this.now) { autoStart = false; }
          this.setScheme(time[0], time[1], djSchemeDetail.name, 'stop');
        }
        const djSchemeName = djSchemeDetail.name;
        if (djSchemeName) {
          this.schemes.push(djSchemeName);
        }
        if (autoStart || (autoStart == 0 && i === 0)) {
          this.loadScheme(autoStart);
        }
      });
    }
  }

  setScheme(hour: number, min: number, scheme: string, method: string) {
    this.now = new Date();
    if (this.now.getHours() === hour && this.now.getMinutes() === min) {
      if (method === 'start') {
        this.nextScheme(scheme);
      } else {
        this.unloadScheme();
      }
    }
    const now = new Date();
    const delay = 60000 - (now.getTime() % 60000); // exact ms to next minute interval
    setTimeout(() => {
      this.setScheme(hour, min, scheme, method);
    }, delay);
  }

  loadScheme(index) {
    this.playNextCount = 0;
    this.scheme = this.djSchemeDetails.schemes[index];
    if (this.scheme) {
      let playList: DjPlaylist;
      _.forEach(this.djSchemeDetails.playLists, (djPlaylist: any) => {
        if (djPlaylist.name === this.scheme.playList) {
          playList = djPlaylist;
        }
      });

      this.DjScheme = this.scheme.name;

      if (this.scheme.onComplete || this.scheme.onStart) {
        const data: { nextScheme: string, fadeIn: number, volume: number } = { nextScheme: '', fadeIn: 0, volume: 0 };
        if (this.scheme.onComplete) { data.nextScheme = this.scheme.onComplete; }
        if (this.scheme.onStart) {
          data.fadeIn = this.scheme.onStart.seconds;
          data.volume = this.scheme.onStart.volume;
        }
        this.loadPlaylist(playList, this.scheme.playback, data);
      } else {
        this.loadPlaylist(playList, this.scheme.playback, {});
      }
    }
  }

  loadPlaylist(data: DjPlaylist, playback: Playback, config) {
    if (this.playlist) {
      if (this.playlist.name === data.name) {
        // don't load playlist, if it's already running
        this.index = 0;
        this.loadTrack(this.index);
        this.start({ fadein: config.fadeIn, fromVolume: config.volume });
        return;
      }
      this.unloadPlaylist();
    }
    this.playlist = data;
    this.playlistName = this.playlist.name;
    this.options = playback;
    this.trackCount = this.playlist.tracks.length;
    _.forEach(this.playlist.tracks, track => {
      if (!track.category) {
        track.category = 'None';
      }
    });
    this.categorizedTracksCount = _.countBy(this.playlist.tracks, (track) => {
      return this.sanitizeString(track.category);
    });
    this.categories = [];
    this.categories = keys(this.categorizedTracksCount);
    this.index = 0;
    this.category = null;
    this.nextSchemePlayed = config.nextScheme;
    this.isPlayLiteral = this.options?.order !== this.playOrder.Alpha ? false : this.isPlayLiteral;

    // update ui
    this.playBackTitle = this.options.order + ' playback';

    this.currentProgress = 0;
    this.playlistTracks = [];
    _.forEach(this.playlist.tracks, (playlistTracks: any) => {
      playlistTracks.displayPath = playlistTracks.path.substr(playlistTracks.path.indexOf('-') + 1,
        playlistTracks.path.length);
      if (playlistTracks.category) {
        playlistTracks.displayCategory = '(' + playlistTracks.category + ')';
      }
      this.playlistTracks.push(playlistTracks);
    });
    this.isLoadPlaylist = false;
    if (this.options.order === this.playOrder.Random || this.options.order === this.playOrder.Alpha) {
      this.playNext();
    }
    this.loadTrack(this.index);
    this.start({ fadein: config.fadeIn, fromVolume: config.volume });
  }

  selectSong = (index) => {
    this.index = index;
    this.loadTrack(index);
    this.start({ fadein: null, fromVolume: this.volume });
  }

  loadTrack(index) {
    const trackVolume = this.playlist.tracks[index].volume;
    this.volume = trackVolume ? trackVolume : this.getVolume(this.scheme, this.playlist);
    this.volume = this.customVolume ?? this.volume;
    this.audio.volume = (this.volume / 100);
    // assign the track to the player
    this.audio.src = RuntimeConstants.MEDIA_BASE_PATH + DomainConstants.DjDirectories.dj + '/' + this.playlist.tracks[index].path;
    this.djTitle = this.playlist.tracks[index].path.substr(this.playlist.tracks[index].path.indexOf('-') + 1,
      this.playlist.tracks[index].path.length);
    this.selectedPlaylist = index;
  }

  getVolume(scheme: Scheme, playlist: DjPlaylist) {
    let availableVolume = 0;
    if (playlist && playlist.Volume) {
      availableVolume = playlist.Volume;
    } else if (scheme && scheme.volume) {
      availableVolume = scheme.volume;
    } else if (this.terminalVolume) {
      availableVolume = this.terminalVolume;
    }
    return availableVolume;
  }

  setVolume(volume) {
    if (this.isPlayDjSpeakText && this.djSpeakTextVolume) {
      this.customVolume = volume;
      this.djSpeakText.volume = (this.djSpeakTextVolume / 100);
    } else if (volume) {
      $(this.audio).finish();
      this.customVolume = volume;
      this.audio.volume = (volume / 100);
    }
  }

  nextScheme(name: string) {
    _.forEach(this.djSchemeDetails.schemes, (scheme, i) => {
      if (scheme.name === name) {
        this.loadScheme(i);
      }
    });
  }

  unloadScheme() {
    this.unloadPlaylist();
    this.DjScheme = '';
    this.volume = 0;
    this.audio.removeAllListeners();
    this.audio = new Audio();
    if (this.nextSchemePlayed) {
      // we have a scheduled next scheme to be load
      this.nextScheme(this.nextSchemePlayed);
    }
    this.addEventListeners(this.audio);
  }

  unloadPlaylist() {
    this.pause();
    this.playlist = null;
    this.options = null;
    this.counter = '--:-- / --:--';
    this.playBackTitle = '';
    this.djTitle = '';
    this.duration = 100;
    this.currentProgress = 0;
    this.isLoadPlaylist = true;
    this.playlistName = '';
    this.playlistTracks = [];
  }

  start(event) {
    let fadein = null;
    let fromVolume = null;
    if (event) {
      fadein = event.fadein;
      fromVolume = event.fromVolume;
    }
    if (!fromVolume) {
      fromVolume = 20;
    }
    let playPromise;
    if (this.isPlayDjSpeakText) {
      this.djSpeakText?.pause();
      playPromise = this.djSpeakText?.play();
      this.loggerService.logWarning(`Started playing DJ Text ${this.djSpeakText?.src}`)
    } else {
      this.audio?.pause();
      playPromise = this.audio.play();
      this.loggerService.logWarning(`Started playing Speak Text ${this.audio.src}`)
    }

    if (playPromise) {
      playPromise.then(() => {
        // Automatic playback started!
      }).catch((error) => {
        this.loggerService.logError(`Error occurred while playing audio: ${this.isPlayDjSpeakText ? this.djSpeakText?.src : this.audio?.src}, Error: ${error?.toString()}`);
      });
    }
    if (fadein && !this.isPlayDjSpeakText) {
      this.audio.volume = this.customVolume ? (this.customVolume / 100) : (fromVolume / 100);
      $(this.audio).animate({ volume: (this.volume / 100) }, parseInt(fadein + '000', 10));
    }

    // updating ui
    this.isPlaying = true;
    this.isStopped = false;

    this.djPlaybackTitle = this.options.order + ' playback';
    if (this.options.pattern.length > 0 && this.options.pattern[0]) {
      this.djPlaybackTitle += '\n up next: ' + this.options.pattern.toString();
    }
    this.playBackTitle = this.djPlaybackTitle;
  }

  stop() {
    let audioObject: HTMLAudioElement;
    if (this.isPlayDjSpeakText) {
      audioObject = this.djSpeakText;
    } else {
      audioObject = this.audio;
    }
    audioObject.pause();
    audioObject.currentTime = 0;
    this.isPlaying = false;
    this.isStopped = true;
    this.loggerService.logWarning(`stop clicked when playing Speak Text ${this.audio.src}`)
  }

  pause() {
    if (this.isPlayDjSpeakText) {
      this.djSpeakText?.pause();
    } else {
      this.audio?.pause();
    }
    this.isPlaying = false;
  }

  playNext = () => {
    if (this.options.order === this.playOrder.Literal || this.isPlayLiteral) {
      this.literalPlayback(true);
    } else if (this.options.order === this.playOrder.Alpha) {
      this.alphaPlayback();
    } else {
      this.randomPlayback(true);
    }
  }

  playPrevious() {
    if (this.options.order === this.playOrder.Literal || this.isPlayLiteral) {
      // literal playback
      this.literalPlayback(false);
    } else {
      this.randomPlayback(false);
    }
  }

  literalPlayback(isNext: boolean) {
    const checkCondition = isNext ? (this.index + 1) < this.trackCount : (this.index - 1) > -1;
    // literal playback
    if (checkCondition) {
      // we can play next or previous track from playlist
      isNext ? this.index++ : this.index--;
      this.loadTrack(this.index);
      if (this.isPlaying) {
        this.start(null);
      }
    } else {
      // end of the playlist, decide what to do
      this.pause();
      if (this.nextSchemePlayed) {
        // we have a scheduled next or previous scheme to be load
        this.nextScheme(this.nextSchemePlayed);
      } else {
        // unload scheme
        this.unloadScheme();
      }
    }
  }

  alphaPlayback() {
    if (!this.options.pattern[0]) {
      this.playlist.tracks = sortBy(this.playlist.tracks, ['category', 'displayPath']);
      this.playlistTracks = sortBy(this.playlistTracks, ['category', 'displayPath']);
    } else {
      const tracks: DjTrack[] = [];
      const playlistTracks = [];
      forEach(this.options.pattern, category => {
        const categoryTracks = _.filter(this.playlist.tracks, x => x.category === category);
        tracks.push(...sortBy(categoryTracks, 'displayPath'));
        const playlistTrackList = _.filter(this.playlistTracks, x => x.category === category);
        playlistTracks.push(...sortBy(playlistTrackList, 'displayPath'));
      });
      this.playlist.tracks = tracks;
      this.playlistTracks = playlistTracks;
      this.trackCount = this.playlistTracks.length;
    }
    this.index = -1;
    this.isPlayLiteral = true;
    this.playNext();
  }

  randomPlayback(isNext: boolean) {
    if (!this.options.pattern[0]) {
      this.options.pattern = this.categories;
    }
    // we have a pattern, so select the upcoming type in the pattern and push it to the end of the list aswell
    this.category = isNext ? this.options.pattern.shift() : this.options.pattern.pop();
    isNext ? this.options.pattern.push(this.category) : this.options.pattern.unshift(this.category);
    // getting a random track from the list, category is null by default    
    if (this.categorizedTracksCount[this.sanitizeString(this.category)]) {
      this.index = this.getRandomIndex(this.category);
    } else {
      // play next if the chosen category has no tracks
      isNext ? this.playNext() : this.playPrevious();
    }
    this.loadTrack(this.index);
    if (this.isPlaying) {
      this.start(null);
    }
  }

  getRandomIndex(category: string) {    
    // Find the recent category entry
    let recentCategory = this.recents.find(recent => recent.category === category);
    
    // Filter tracks of the given category
    const availableTracks = this.playlist.tracks.filter(track => track.category === category);
    if (!recentCategory) {
      recentCategory = { category: category, tracks: [] };
      this.recents.push(recentCategory);
    }

    // Get the indices of the available tracks
    let availableIndices = availableTracks.map(track => this.playlist.tracks.indexOf(track));

    // Filter out recently played tracks
    let tracksToChooseFrom = availableIndices.filter(index => !recentCategory.tracks.includes(index));
  
    // If all tracks have been played, reset the recently played list for the category
    if (tracksToChooseFrom.length === 0) {
      recentCategory.tracks = [];
      tracksToChooseFrom = availableIndices;
    }

    // Pick a random track index from the available tracks
    let randomIndex = tracksToChooseFrom[Math.floor(Math.random() * tracksToChooseFrom.length)];

    return randomIndex;
  }

  sanitizeString(string: string) {
    return string ? string.trim() : '';
  }

  progressBarClicked(event) {
    if (!this.isLoadPlaylist) {
      const duration = event.offsetX / event.currentTarget.offsetWidth;
      this.audio.currentTime = Number((duration ? duration : 0) * (this.audio.duration ? this.audio.duration : 0));
      this.currentProgress = duration / 100;
    }
  }

}
