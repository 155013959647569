import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DateColumn, GridColumn, TarkTimePipe, TextAlign } from '@tarktech/tark-ng-utils';
import { Format } from '@tarktech/tark-ng-utils/table/format-type';
import { finalize } from 'rxjs/operators';
import { ScheduleOrderActiveHoursOverride } from 'src/app/orders/order-entry/special-functions/interfaces/schedule-order-active-hours-override';
import { BaseListComponent } from 'src/app/shared/components/base-list.component';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { ScheduleOrderActiveHoursOverrideService } from 'src/app/shared/services';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import { ScheduleActiveHourOverridesEditComponent } from '../schedule-active-hour-overrides-edit/schedule-active-hour-overrides-edit.component';

@Component({
  selector: 'pos-schedule-active-hours-override',
  templateUrl: './schedule-active-hours-override.component.html',
})
export class ScheduleActiveHoursOverrideComponent extends BaseListComponent<ScheduleOrderActiveHoursOverride> implements OnInit {

  activeHourOverrideColumns: Array<GridColumn> = [];
  activeHourOverrides: Array<ScheduleOrderActiveHoursOverride> = [];

  constructor(
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected applicationStateService: ApplicationStateService,
    protected printService: PrintTableService,
    protected route: ActivatedRoute,
    protected scheduleOrderActiveHoursOverrideService: ScheduleOrderActiveHoursOverrideService,
    protected tarkTimePipe: TarkTimePipe
  ) {
    super(
      scheduleOrderActiveHoursOverrideService,
      alertService,
      spinnerService,
      modalService,
      applicationStateService,
      printService,
      route
    );
  }

  ngOnInit(): void {
    this.deleteSuccessMessage = Messages.ScheduleOrderActiveHourOverrideDeleted;
    this.isPrintIconVisible = false;
    this.activeHourOverrideColumns = this.configureGridColumns();
  }

  reload() {
    this.spinnerService.show();
    this.scheduleOrderActiveHoursOverrideService.getNextScheduleOverrides()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: ScheduleOrderActiveHoursOverride[]) => {
          this.activeHourOverrides = response;
          this.activeHourOverrides.forEach((data) => {
            data.StartTime = data.StartTime ?
              this.tarkTimePipe.transform(Date.parse('1-1-0001 ' + data.StartTime.toString()), false) : 'Closed for the day';
            data.EndTime = data.EndTime ?
              this.tarkTimePipe.transform(Date.parse('1-1-0001 ' + data.EndTime.toString()), false) : 'Closed for the day';
          });
        }
      });
  }

  editItem(scheduleId) {
    const modelRefScheduleOverride = this.modalService.show(ScheduleActiveHourOverridesEditComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        scheduleOverrideId: scheduleId
      }
    });
    modelRefScheduleOverride.close.subscribe(res => {
      if (res && res.shouldReload) {
        this.reload();
      }
    });
  }

  getGridColumns() {
    return [
      new DateColumn({ HeaderText: 'From', Field: 'FromDate', Format: Format.Date, Width: '23%', TextAlign: TextAlign.Left }),
      new DateColumn({ HeaderText: 'To', Field: 'ToDate', Width: '23%', Format: Format.Date, TextAlign: TextAlign.Left }),
      new GridColumn({ HeaderText: 'Start Time', Field: 'StartTime', Width: '23%' }),
      new GridColumn({ HeaderText: 'End Time', Field: 'EndTime', Width: '23%' }),
    ];
  }

  getConfirmDeleteMessage() {
    return Messages.OnlineOrderScheduleOverrideConfirmDelete;
  }
}
