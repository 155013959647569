<pos-edit-wrapper (close)="onCancel()" [isPopup]="false" [headingIcon]="icons.centralApiConfigurationCogs"
  heading="Central API Configuration">
  <form #centralApiConfigForm="ngForm" (ngSubmit)="saveCentralApiConfig(centralApiConfigForm.valid)" autocomplete="off">
    <context-provider provide="props">
      <div class="col-12 pos-modal-body border-top pt-pix-5">
        <div class="col-12">
          <div>
            <h3 class="setting-title">Settings</h3>
          </div>
          <pos-row class="px-2">
            <pos-column>
              <pos-form-field label="Central API URL"
                [validationMessages]="{required: 'Please enter central api url.'}">
                <input posInput class="form-control" name="CentralApiUrl" #centralApiUrl="ngModel"
                  [(ngModel)]="centralApiConfig.CentralApiUrl" type="text" [required]="true" />
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field label="Auth API URL" [validationMessages]="{required: 'Please enter auth api url.'}">
                <input posInput class="form-control" name="AuthApiUrl" #authApiUrl="ngModel"
                  [(ngModel)]="centralApiConfig.AuthApiUrl" type="text" [required]="true" />
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field label="Scope" [validationMessages]="{required: 'Please enter scope.'}">
                <input posInput class="form-control" name="Scope" #scope="ngModel" [(ngModel)]="centralApiConfig.Scope"
                  type="text" [required]="true" />
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field label="Client Secret" [validationMessages]="{required: 'Please enter client secret.'}">
                <input posInput class="form-control" name="ClientSecret" #clientSecret="ngModel"
                  [(ngModel)]="centralApiConfig.ClientSecret" type="text" [required]="true" />
              </pos-form-field>
            </pos-column>
          </pos-row>
        </div>
      </div>
      <div class="col-12 action-button-container page-footer">
        <button class="btn btn-primary btn-lg btn-save pos-button" scrollup type="submit"> Save </button>
        <button class="btn btn-secondary btn-lg btn-cancel pos-button" scrollup type="button" routerLink="../">
          Cancel </button>
      </div>
    </context-provider>
  </form>
</pos-edit-wrapper>
