<pos-list-wrapper [headingIcon]="icons.screensDesktop" heading="Screens" (close)="close()">
    <div class="row">
        <div class="col-12">
            <ng-table id="screens" [sort]="true" [columns]="screenColumns" [data]="screens"
                [exportFilename]="'screens'">
            </ng-table>
            <ng-template let-data="rowData" #printReceiptIconTemplate>
                <span *ngIf="data.PrintOnOrderReceipt" class="green text-center vertical-mid font-13">
                    <pos-icon [icon]="icons.check"></pos-icon>
                </span>
            </ng-template>

            <ng-template let-data="rowData" #operationHeaderTemplate>
                <div class="text-center">
                    <span (click)="editItem(0)" class="no-wrap" tooltip title="Add">
                        <pos-action-icon [icon]="icons.plusWhite" iconText="Add"></pos-action-icon>
                    </span>
                    <pos-export-dropdown class="inline-block ms-pix-5" (onPrint)="printData()"
                        (onCSV)="exportCSV()">
                    </pos-export-dropdown>
                </div>
            </ng-template>

            <ng-template let-data="rowData" #operationTemplate>
                <div class="text-center">
                    <span class="pe-pix-3" (click)="editItem(data.Id, data)" tooltip title="Edit">
                        <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
                    </span>
                    <span class="pe-pix-3" (click)="confirmDelete(data)" tooltip title="Delete">
                        <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
                    </span>
                    <!-- <span tooltip title="options">
                        <a class="action-btn grey pointer" #pop="bs-popover" placement="left"
                            (click)="onLinkPopOver(data,pop)" [outsideClick]="true"
                            [popover]="ScreenPopOverTemplate" triggers="click">
                            <pos-action-icon [icon]="icons.barsWhite"></pos-action-icon>
                        </a>
                    </span> -->
                </div>
            </ng-template>

            <ng-template #ScreenPopOverTemplate>
                <ul>
                    <li class="listbullet">
                        <a class="grey pointer" (click)="onScreenHistory()" title="History">
                            <pos-icon [icon]="icons.history" class="pe-pix-3 m-l-5">
                            </pos-icon>
                            History
                        </a>
                    </li>
                </ul>
            </ng-template>

        </div>
    </div>
</pos-list-wrapper>
<pos-print-table [hidden]="true"></pos-print-table>
