import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableComponent, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { GridColumn } from '@tarktech/tark-ng-utils/table/grid-column';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { finalize } from 'rxjs/internal/operators/finalize';
import { Messages } from 'src/app/shared';
import { BaseListComponent } from 'src/app/shared/components/base-list.component';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import { MenuDisplay } from '../../interfaces/menu-display';
import { MenuWidget } from '../../interfaces/menu-widget';
import { MenuWidgetService } from '../../services';
import { MenuDisplayService } from '../../services/menu-display.service';
import {
  editWhite,
  deleteWhite,
  cogWhite
} from 'src/app/shared/components/icon';
import { Observable } from 'rxjs';

@Component({
  selector: 'pos-menu-widget-list',
  templateUrl: './menu-widget-list.component.html',
  styleUrls: ['./menu-widget-list.component.scss']
})
export class MenuWidgetListComponent extends BaseListComponent<MenuDisplay> implements OnInit {

  menuWidgetColumns: Array<GridColumn> = [];
  menuWidgets: Array<MenuWidget> = [];
  menuId: number;
  menu: MenuDisplay;
  @ViewChild('operationTemplate', { static: true }) operationTemplate: TemplateRef<any>;
  @ViewChild('menuWidgetList', { static: true }) menuWidgetList: TableComponent;
  icons = {
    editWhite,
    deleteWhite,
    cogWhite
  };

  constructor(protected route: ActivatedRoute,
    protected spinnerService: SpinnerService,
    protected alertService: AlertsService,
    protected applicationStateService: ApplicationStateService,
    protected router: Router,
    protected menuDisplayService: MenuDisplayService,
    protected menuWidgetService: MenuWidgetService,
    protected modalService: ModalService,
    protected printService: PrintTableService) {
    super(menuDisplayService, alertService, spinnerService, modalService, applicationStateService, printService, route);
    this.menuId = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
  }
  ngOnInit(): void {
    this.reload();
    this.menuWidgetColumns = this.getGridColumns();
    this.menuWidgetList.context = this.gridContext;
  }

  reload() {
    this.spinnerService.show();
    const observable: Array<Observable<any>> = [];
    observable.push(this.menuDisplayService.getMenuWidgets(this.menuId));
    observable.push(this.menuDisplayService.get(this.menuId));
    forkJoin(observable).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: ([dashboard, digitalMenu]: [Array<MenuWidget>, MenuDisplay]) => {
        this.menuWidgets = dashboard;
        this.menu = digitalMenu;
      },
      error: this.alertService.showApiError
    });
  }

  getGridColumns(): GridColumn[] {
    const operation = new TemplateColumn({
      itemTemplate: this.operationTemplate,
      Width: '125px',
      TextAlign: TextAlign.Center
    });
    return [
      new GridColumn({ HeaderText: 'Name', Field: 'Name', Width: '50%' }),
      new GridColumn({ HeaderText: 'Type', Field: 'WidgetType', Width: '20%' }),
      new GridColumn({ HeaderText: 'Detail', Field: 'WidgetDetails', Width: '30%' }),
      operation
    ];
  }

  editItem(id: number): void {
    this.router.navigate([id], { relativeTo: this.route });
  }

  delete(id) {
    this.spinnerService.show();
    this.menuWidgetService.delete(id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      })).subscribe({
        next: () => {
          this.alertService.renderSuccessMessage(Messages.WidgetDeleted);
          this.reload();
        }, error: this.alertService.showApiError
      });
  }

  configWidgetGroup(data) {
    this.router.navigate([data.Id, 'groups'], { relativeTo: this.route });
  }

  getConfirmDeleteMessage() {
    return Messages.ConfirmDeleteDigitalMenuWidget;
  }

  cancel() {
    this.router.navigate(['/manage/menu-display']);
  }
}
