<pos-list-wrapper [headingIcon]="icons.infoManageTimeClockEntriesClock" heading="Time Clock Entries" (close)="close()">
  <div class="row">
    <div class="col-12 row p-0 justify-content-end">
      <div class="col-220 me-pix-15">
        <div class="col-12 label-section pe-0">Date From</div>
        <div class="col-12 col-220 pe-0">
          <p-calendar [(ngModel)]="fromDate" showButtonBar="true" appendTo="body" [showIcon]="true" [dateFormat]="dateFormat"
            (onSelect)="reload()" [readonlyInput]="true" name="FromDate" yearNavigator="true" yearRange="2000:2099"
            #FromDate="ngModel" [monthNavigator]=true [required]="true" [clearButtonStyleClass]="'display-none-imp'"
            [inputStyle]="{'width':'unset','display':'unset'}" inputStyleClass="form-control"></p-calendar>
        </div>
      </div>
      <div class="col-220 p-0">
        <div class="col-12 label-section pe-0">Date To</div>
        <div class="col-12  col-220 pe-0">
          <p-calendar [(ngModel)]="toDate" showButtonBar="true" appendTo="body" [showIcon]="true"
            [dateFormat]="dateFormat" (onSelect)="reload()" [readonlyInput]="true" name="ToDate" yearNavigator="true"
            yearRange="2000:2099" #ToDate="ngModel" [monthNavigator]=true [required]="true"
            [clearButtonStyleClass]="'display-none-imp'" [inputStyle]="{'width':'unset','display':'unset'}"
            inputStyleClass="form-control"></p-calendar>
        </div>
      </div>
    </div>
    <div class="col-12">
      <ng-table #timeClockEntryGrid [columns]="timeClockEntriesColumns" [exportFilename]="'time-clock-entries'"
        [data]="timeClockEntriesList">
      </ng-table>
      <ng-template let-data="rowData" #hoursTemplate>
        {{ data.Hours | number: '0.2-2' }}
      </ng-template>
    </div>
  </div>
</pos-list-wrapper>
<pos-print-table #printTableReference [hidden]="true"></pos-print-table>
