import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  GridColumn,
  TableComponent,
  TemplateColumn,
  TextAlign,
} from '@tarktech/tark-ng-utils';
import {
  ApplicationStateService,
  Messages,
  PrintTableService,
  SpinnerService,
  AlertsService,
  ModalService,
  BaseListComponent,
  StringUtils,
  Permissions,
  Levels,
  AuthenticationService,
  DomainConstants,
  RuntimeConstants,
} from 'src/app/shared';
import { Tag } from '../../interface/tags';
import { check, tag, thList } from 'src/app/shared/components/icon/icons';
import { TagService } from 'src/app/shared/services/tag.service';
import { find, forEach, remove } from 'lodash';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'pos-tags-list',
  templateUrl: './tags-list.component.html',
  styleUrls: ['./tags-list.component.scss'],
})
export class TagsListComponent extends BaseListComponent<Tag> implements OnInit {

  @ViewChild('searchableTemplate', { static: true }) searchableTemplate: TemplateRef<any>;
  @ViewChild('tagColor', { static: true }) tagColor: TemplateRef<any>;
  @ViewChild('iconTemplate', { static: true }) iconTemplate: TemplateRef<any>;
  @ViewChild('gridComponent', { static: true }) gridComponent: TableComponent;
  public tagList: Array<Tag> = [];
  public message = Messages.ConfirmDeleteTag;
  tagId: number;
  tagColumns: Array<GridColumn> = [];
  printTagColumns: Array<GridColumn> = [];
  tagCategories: Array<{ Name: string, Value: string }> = [];
  tagCategory: string;
  tagListFilter: Array<any> = [];
  imagePath = RuntimeConstants.IMAGE_BASE_PATH + '/tags';

  public icons = {
    check,
    tag
  };
  permission = {
    name: Permissions.SalesTags,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };

  constructor(
    private authenticationService: AuthenticationService,
    protected applicationStateService: ApplicationStateService,
    protected route: ActivatedRoute,
    protected spinnerService: SpinnerService,
    protected printService: PrintTableService,
    protected tagService: TagService,
    protected alertService: AlertsService,
    protected modalService: ModalService,
    protected router: Router
  ) {
    super(tagService, alertService, spinnerService, modalService, applicationStateService, printService, route);
  }

  ngOnInit(): void {
    this.configureGridColumns();
    this.tagColumns = this.configureGridColumns();
    this.deleteSuccessMessage = Messages.TagDeleted;
    this.tagId = 0;
    this.checkPermission();
    this.gridComponent.context = this.gridContext;
  }

  reload() {
    const observable = [];
    this.spinnerService.show();
    observable.push(this.tagService.getAll());
    observable.push(this.tagService.getTagCategories());
    forkJoin(observable).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (response: any) => {
        if (response) {
          this.tagList = response[0] ? response[0] : [];
          forEach(response[1], (key, value: string) => {
            this.tagCategories.push({ 'Name': key, 'Value': value });
          });
          forEach(this.tagList, (tag) => {
            const category = find(this.tagCategories, (cat) => cat.Value === tag.Category)?.Name ?? tag.Category;
            tag.Category = category;
          });
          this.tagListFilter = this.tagList;
        }
      }, error: this.alertService.showApiError
    });
  }

  private checkPermission(): void {
    if (!this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      remove(this.tagColumns, (column) => {
        return (column.Type === DomainConstants.GridColumnsTypes.EditColumn
          || column.Type === DomainConstants.GridColumnsTypes.DeleteColumn);
      });
    }
  }

  public getConfirmDeleteMessage(data: Tag): string {
    return StringUtils.format(Messages.ConfirmDeleteTag, { 'Name': data.Name });
  }

  public getGridColumns(): Array<GridColumn> {
    const searchable = new TemplateColumn({
      HeaderText: 'Searchable',
      Field: 'IsSearchable',
      Width: '120px',
      IsSortable: false,
      TextAlign: TextAlign.Center,
      itemTemplate: this.searchableTemplate,
    });

    const color = new TemplateColumn({
      Field: 'Color',
      Width: '10px',
      CellClass: 'pi-0 no-right-border',
      itemTemplate: this.tagColor,
    });

    const columns = [
      color,
      new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: false, Width: '40%', CellClass: 'no-left-border' }),
      new GridColumn({ HeaderText: 'Category', Field: 'Category', Width: '35%', IsSortable: false }),
      new TemplateColumn({
        HeaderText: 'Icon', Field: 'Icon', Width: '10%', itemTemplate: this.iconTemplate, TextAlign: TextAlign.Center,
        CellClass: 'pi-5'
      }),
      searchable,
    ] as Array<GridColumn>;

    this.printTagColumns = [...columns];
    return columns;
  }

  public editItem(id: number) {
    this.router.navigate([id], { relativeTo: this.route, state: { tagId: id } });
  }

  getTagsWithFilter() {
    this.tagListFilter = this.tagList.filter((data) => {
      return data.Category != this.tagCategory;
    });
  }
  public printData() {
    this.printService.printEmitter.next({ gridColumns: this.printTagColumns, gridData: this.tagList });
  }

  gridRowReorder(event) {
    this.tagService.updateTagOrdinal(this.tagList)
      .subscribe({
        next: (res) => {
          this.tagList = res ? res : [];
        }, error: this.alertService.showApiError
      });
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}

