import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { cloneDeep, forEach } from 'lodash';
import { finalize } from 'rxjs/operators';
import { HardwareInterface } from 'src/app/configurator/hardware-interfaces/interface/hardware-interface';
import { HardwaresService } from 'src/app/configurator/hardware/service';
import { AlertsService, ApplicationStateService, SpinnerService } from 'src/app/shared';
import { ActivityReportPayload } from '../../interfaces/activity-report-payload';
import { Service } from '../../interfaces/service';
import { HicDashboardService } from '../../services/hic-dashboard.service';

@Component({
  selector: 'pos-hic-dashboard',
  templateUrl: './hic-dashboard.component.html',
  styleUrls: ['./hic-dashboard.component.scss']
})
export class HicDashboardComponent implements OnInit {

  display = 'stream';
  hardwareInterfaces: Array<HardwareInterface> = [];
  services: Array<Service> = [];
  activities: Array<ActivityReportPayload> = [];
  activitiesCopy: Array<ActivityReportPayload> = [];
  tabList = {
    ActivityStream: 'ActivityStream',
    ServiceStatus: 'ServiceStatus'
  };
  selectedTab = this.tabList.ActivityStream;

  constructor(private spinnerService: SpinnerService,
    private hardwareService: HardwaresService,
    private hicDashboardService: HicDashboardService,
    private applicationStateService: ApplicationStateService,
    private router: Router,
    private alertsService: AlertsService) { }

  ngOnInit(): void {
    this.getHardwareInterfaces();
  }

  newService() {
    return {
      Name: '', Identifier: '', Status: 'Down'
    } as Service;
  }

  getHardwareInterfaces() {
    this.spinnerService.show();
    this.hardwareService.getHardwareInterfaceComputers()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          this.hardwareInterfaces = response;
          forEach(this.hardwareInterfaces, (hardwareInterface) => {
            if (hardwareInterface.MonitorUrl) {
              const service = this.newService();
              service.Name = hardwareInterface.Name;
              service.Identifier = hardwareInterface.Guid;
              service.HealthUrl = hardwareInterface.MonitorUrl + '/health';
              this.services.push(service);
              this.getServiceInfo(hardwareInterface);
            }
          });
        }
      });
  }

  getServiceInfo(hardwareInterface: HardwareInterface) {
    this.spinnerService.show();
    this.hicDashboardService.getServiceInfo(hardwareInterface.MonitorUrl)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (serviceInfo: Service) => {
          forEach(this.services, (service) => {
            if (service.Name === serviceInfo.Name) {
              service.HealthUrl = hardwareInterface.MonitorUrl + serviceInfo.HealthUrl;
              service.IpAddress = serviceInfo.IpAddress;
            }
          });
          this.getActivities(hardwareInterface);
        }
      });
  }

  getActivities(hardwareInterface: HardwareInterface) {
    this.spinnerService.show();
    this.hicDashboardService.getActivities(this.applicationStateService.settingParam.HicHistorySize, hardwareInterface.MonitorUrl)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: Array<ActivityReportPayload>) => {
          forEach(response, (activity) => {
            activity.ServiceName = hardwareInterface.Name;
          });
          this.activitiesCopy.push(...response);
          this.activities = cloneDeep(this.activitiesCopy);
        }, error: this.alertsService.showApiError
      });
  }

  cancel() {
    this.router.navigate(['manage/console']);
  }

}
