import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared';
import { OnlineOrderingCategoryProduct } from '../interface/online-ordering';
@Injectable({
  providedIn: 'root'
})
export class OnlineOrderingCategoryProductService {
  constructor(private httpService: HttpClient) { }

  newOnlineOrderingCategoryProduct(){
      const categoryProduct: OnlineOrderingCategoryProduct = {
        Id: 0,
        ButtonText: '',
        Icon: '',
        SalesProductId: null,
        BackgroundColor: '',
        TextColor: '',
        IsToggle: false,
        SectionId: null,
        Ordinal: 0,
        CategoryId: null,
        SalesProductName: '',
        SectionName: '',
        SectionOrdinal: 0,
        hasImageResolutionError: false,
        IsActive: true,
        IsFeatured: false,
        IsMobile: false
      }
      return categoryProduct
  }

  getOnlineOrderingCategoryProduct(buttonId: number): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}onlineorderingcategoryproduct/` + buttonId
    );
  }
  getOnlineOrderingCategoryProducts(): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}onlineorderingcategoryproduct`
    );
  }
  saveCategoryProduct(buttonDetails, icon: File): Observable<any> {
    const formData = this.prepareIconFormData(buttonDetails, icon);
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}onlineorderingcategoryproduct`, formData
    );
  }
  // addCategoryProduct(buttonDetails, icon: File): Observable<any> {
  //   const formData = this.prepareIconFormData(buttonDetails, icon);
  //   return this.httpService.put(
  //     `${RuntimeConstants.API_PATH}onlineorderingcategoryproduct`, formData
  //   );
  // }
  private prepareIconFormData(param: any, iconFile: File): FormData {
    const formData = new FormData();
    if (iconFile) {
        formData.append('file', iconFile);
    }
    formData.append('model', JSON.stringify(param));
    return formData;
}

  updateOnlineOrderingProductOrdinal(buttons: Array<OnlineOrderingCategoryProduct>): Observable<any> {
    return this.httpService.put(
      `${RuntimeConstants.API_PATH}onlineorderingcategoryproduct/ordinal`,
      buttons
    );
  }

  getOnlineOrderingProductConfiguration(buttonId: number): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}onlineorderingcategoryproduct/config/` + buttonId
    );
  }

  addMenuButtonConfiguration(menuButtonConfiguration): Observable<any> {
    return this.httpService.put(
      `${RuntimeConstants.API_PATH}onlineorderingcategoryproduct/config/`, menuButtonConfiguration
    );
  }

  deleteMenuButtonConfiguration(configurationId: number): Observable<any> {
    return this.httpService.delete(
      `${RuntimeConstants.API_PATH}onlineorderingcategoryproduct/config/` + configurationId
    );
  }

  updateOnlineOrderingMenuButtonConfigurationOrdinal(menuButtonConfigurations): Observable<any> {
    return this.httpService.put(
      `${RuntimeConstants.API_PATH}onlineorderingcategoryproduct/config/ordinal/`,
      menuButtonConfigurations
    );
  }

}
