<pos-edit-wrapper heading="{{selectedDeliveryType.description}}" (close)="onClose(false)">
    <form #externalAPIActivityEditForm="ngForm" autocomplete="off"
        (submit)='saveActivity(externalAPIActivityEditForm.valid)'>
        <div class="col-12 pos-modal-body screen-scroll">
            <pos-row>
                <pos-column>
                    <pos-form-field label="API" [validationMessages]="{required: 'Please select API.'}">
                        <ng-select posInput [items]="externalApis" bindLabel="Name" appendTo="body" bindValue="Id"
                            placeholder="Select call API" [(ngModel)]="selectedExternalApi"
                            (change)="changeExternalApis()" name="externalApi" [required]="true">
                        </ng-select>
                    </pos-form-field>
                </pos-column>
            </pos-row>
            <div class="col-12"
                *ngIf="externalApiParameters && externalApiParameters.length">
                <div class="page-content pt-pix-10 external-api-event">
                    <div class="tab-pane fade in active show tab-pane-m-p">
                        <p-table [value]="externalApiParameters">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 45%">Parameter</th>
                                    <th style="width: 40%" class="no-right-border">Value</th>
                                    <th style="width: 60px" class="no-left-border"></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-i="rowIndex">
                                <tr [pReorderableRow]="i">
                                    <td class="vertical-mid-imp">
                                        {{rowData.ParamName}}
                                    </td>
                                    <td class="no-right-border">
                                        <textarea pInputText type="text" class="form-control" name="ParamValue{{i}}"
                                            #ParamValue="ngModel" [(ngModel)]="rowData.ParamValue"
                                            (change)="parameterChange()" (focus)="focusOnParam()" row="0" (click)="getCaretPos(rowData, $event.target)"
                                            (keyup)="getCaretPos(rowData, $event.target)"></textarea>
                                    </td>
                                    <td class="no-left-border vertical-mid-imp">
                                        <div class="col-md-6 ps-pix-10">
                                            <span class="font-20 pointer">
                                                <pos-icon [icon]="icons.code" (click)="openVariables(rowData)">
                                                </pos-icon>
                                            </span>
                                            <div style="position: fixed; z-index: 10;"
                                                *ngIf="rowData.Id == selectedVariableId && !isDisabled" class="col-200">
                                                <ng-select [items]="eventAttributes" dropdownPosition="auto"
                                                    appendTo="body" placeholder="Select event variable"
                                                    name="rowData.Id" [(ngModel)]="selectedEventAttribute"
                                                    (change)="changeEventAttribute()" [isOpen]="true">
                                                </ng-select>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 px-0">
                <pos-repeat-action-configuration [(eventSubscriptionActionAttributes)]="eventSubscriptionActionAttributes"                
                [isFormSubmitted]="externalAPIActivityEditForm.submitted" #RepeatActionConfiguration [isRecoverableEvent]="isRecoverableEvent">

                </pos-repeat-action-configuration>
            </div>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save</button>
            <button type="button" (click)="onClose(false)" class="btn-secondary btn-lg btn btn-cancel-modal pos-button"
                value="Cancel">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
