<div class="kiosk-invoice d-flex flex-column h-100 shadow">
    <h2 class="invoice-header">Order #{{order.SurrogateOrderId}}</h2>
    <div class="invoice-body flex-grow">
        <div class="col-12 px-3">
            <div class="d-flex align-items-center px-4 py-3" *ngIf="order.DiscountPct > 0">
                <div class="bold font-15 flex-fill ps-pix-3"> DISCOUNT {{order.DiscountPct}}% </div>
            </div>
            <hr class="separator m-auto" *ngIf="order.DiscountPct > 0" />
            <div class="d-flex align-items-center px-4 py-3" *ngIf="order.MarkupPct > 0">
                <div class="bold font-15 flex-fill ps-pix-3"> MARKUP {{order.MarkupPct}}% </div>
            </div>
            <hr class="separator m-auto" *ngIf="order.MarkupPct > 0" />
        </div>
        <div class="row mx-0 p-3 pt-0" *ngFor="let product of order?.OrderItems; let i = index;">
            <hr *ngIf="i > 0" class="separator m-auto" />
            <div class="row mx-0 pt-3 g-0">
                <div class="col-12" *ngIf="product.OrderItemType != orderItemTypes.Product">
                    <div class="d-flex align-items-center ps-4">
                        <div class="bold font-15 flex-fill ps-pix-3"> {{ product.ButtonText | uppercase }} </div>
                        <div class="price">{{ (product.Qty * product.Price) | tark_currency }}</div>
                    </div>
                </div>
                <div class="col-12" *ngIf="product.OrderItemType == orderItemTypes.Product">
                    <div class="d-flex align-items-center">
                        <div role="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapseItem'+i"
                            aria-expanded="true" class="col-25">
                            <span *ngIf="product.OrderProductComponents?.length && !(product.OrderProductComponents.length == 1 && product.OrderProductComponents[0]?.IsSize)">
                                <pos-icon class="arrow-right" [icon]="icons.chevronRight"></pos-icon>
                                <pos-icon class="arrow-down" [icon]="icons.chevronDown"></pos-icon>
                            </span>
                        </div>
                        <div class="bold font-15 flex-fill"> {{ (product.DisplayText ?? product.ButtonText) | uppercase }} </div>
                        <div class="d-flex align-items-center">
                            <span class="qty shadow pointer" (click)="openNumpad(product)"> {{ product.Qty }} </span>
                            <pos-icon class="ms-pix-10 font-22 text-danger pointer" [icon]="icons.times"
                                *ngIf="product.OrderItemType != orderItemTypes.SingularFee"
                                (click)="deleteProduct(product)"></pos-icon>
                        </div>
                    </div>
                    <div class="collapse show ms-4" id="collapseItem{{i}}">
                        <div *ngFor="let component of product.OrderProductComponents;">
                            <span *ngIf="!component.IsSize">
                                <span class="mx-2">
                                    <pos-icon [icon]="icons.times" class="red pointer font-18" [hidden]="component.IsFees || (product.SalesProductId?.Value == -5 && component.SalesProductId?.Value == -4)"
                                        (click)="deleteComponent(product, component)"></pos-icon>
                                </span>
                                <span class="vertical-text-bottom" [ngClass]="{'ps-pix-16 lh-base': component.IsFees || (product.SalesProductId?.Value == -5 && component.SalesProductId?.Value == -4)}"> {{ component.ButtonText }} </span>
                            </span>                            
                        </div>
                    </div>
                    <div class="price">{{ (product.Qty * product.Price) | tark_currency }}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="invoice-footer py-3">
        <div class="row mx-0" *ngIf="order?.DiscountAmount">
            <div class="col-9">Discount: </div>
            <div class="col-3 footer-price">{{ order?.DiscountAmount | tark_currency }} </div>
        </div>
        <div class="row mx-0" *ngIf="order?.MarkupAmount">
            <div class="col-9">Markup: </div>
            <div class="col-3 footer-price">{{ order?.MarkupAmount | tark_currency }} </div>
        </div>
        <div class="row mx-0">
            <div class="col-9">Subtotal: </div>
            <div class="col-3 footer-price">{{ order?.SubTotal | tark_currency }} </div>
        </div>
        <div class="row mx-0">
            <div class="col-9">Tax: </div>
            <div class="col-3 footer-price">{{ order?.TaxTotal | tark_currency }} </div>
        </div>
        <div class="row mx-0">
            <div class="col-9">Total: </div>
            <div class="col-3 footer-price">{{ order?.GrandTotal | tark_currency }} </div>
        </div>
    </div>
</div>
