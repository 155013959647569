<pos-edit-wrapper heading="Sign In" (close)="cancel(false)">
  <div class="row col-12 g-0 pt-pix-10">
    <div class="alert condensed inherit-position alert-danger mb-pix-10 mx-pix-15" id="divSuccessmsg" style="display: none;">
    </div>
    <div class="col-lg-4 col-md-4 col-sm-3 col-p">
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 px-3">
      <input type="password" name="signInPassword" id="signInPassword" placeholder="Password" #signInPassword="ngModel"
        class="form-control time-entry-modal__password" [(ngModel)]="password" (keyup.enter)="userLogin()" />
    </div>

    <div class="col-12 mt-pix-5 text-center p-0">

      <button class="btn-keypad clock-in-btn-keypad" (click)="textEnteredForLogin('1')">1</button>

      <button class="btn-keypad clock-in-btn-keypad" (click)="textEnteredForLogin('2')">2</button>

      <button class="btn-keypad clock-in-btn-keypad" (click)="textEnteredForLogin('3')">3</button>

      <button class="btn-keypad clock-in-btn-keypad" (click)="textEnteredForLogin('4')">4</button>

      <button class="btn-keypad clock-in-btn-keypad" (click)="textEnteredForLogin('5')">5</button>

      <button class="btn-keypad clock-in-btn-keypad" (click)="textEnteredForLogin('6')">6</button>

      <button class="btn-keypad clock-in-btn-keypad" (click)="textEnteredForLogin('7')">7</button>

      <button class="btn-keypad clock-in-btn-keypad" (click)="textEnteredForLogin('8')">8</button>

      <button class="btn-keypad clock-in-btn-keypad" (click)="textEnteredForLogin('9')">9</button>

      <button class="btn-keypad clock-in-btn-keypad" (click)="textEnteredForLogin('0')">0</button>
    </div>
  </div>
  <div class="col-12 action-button-container popup-footer">
    <button class="btn btn-secondary btn-lg me-pix-10 pos-button" (click)="userLogin()" type="button">
      <img src="images/icons/icon-circle-check.png" /> Continue
    </button>
    <button class="btn btn-secondary btn-lg pos-button me-pix-10" (click)="cancel(false)" type="button">
      <img src="images/icons/icon-circle-x.png" /> Cancel </button>
  </div>
</pos-edit-wrapper>
<pos-keyboard-wedge [(isEnabled)]="isKeyboardWedgeEnabled" (onSwipeDetected)="onSwipeDetected($event)">
</pos-keyboard-wedge>
