<pos-edit-wrapper heading="Gift Card" (close)="cancel()" [headingIcon]="icons.giftCard" [isPopup]="false">
    <form (ngSubmit)="saveGiftCardWorkflow(giftCardWorkflow.valid)" #giftCardWorkflow="ngForm" class="edit-form">
        <div class="col-12 border-top pt-pix-5">
            <div class="pos-page-body col-12">
                <pos-row>
                    <pos-column xs="3" class="fit-content pt-pix-10 pe-0">
                        <label class="edit-form__section__label">Auto Deduct</label>
                    </pos-column>
                    <pos-column xs="3" class="col-120 pe-0">
                        <pos-form-field inputStyle="col-xs-12"
                            [validationMessages]="{required: 'Amount must be greater than zero.'}">
                            <p class="input-group d-flex flex-nowrap">
                                <span class="input-group-addon">{{ currencySymbol }}</span>
                                <input posInput class="form-control price-input" name="GiftCardAutoDeductionAmount"
                                    min="0" [(ngModel)]="parameters.GiftCardAutoDeductionAmount" posDecimal
                                    type="number" [required]="true">
                            </p>
                        </pos-form-field>
                    </pos-column>
                    <pos-column xs="3" class="fit-content pt-pix-10 pe-0">
                        <label class="edit-form__section__label">every month after</label>
                    </pos-column>
                    <pos-column xs="3" class="col-120 pe-0">
                        <pos-form-field inputStyle="col-xs-12"
                            [validationMessages]="{required: 'Please enter month(s).', range: 'Month must be greater than 0 and less than 12.'}">
                            <input posInput class="form-control " min="1" max="12" step="1" posOnlyNumbers
                                name="GiftCardAutoDeductionAfterMonth" [required]="true" [range]="[1, 12]"
                                [(ngModel)]="parameters.GiftCardAutoDeductionAfterMonth" type="number" />
                        </pos-form-field>
                    </pos-column>
                    <pos-column xs="3" class="fit-content pt-pix-10 pe-0">
                        <label class="edit-form__section__label">month(s)</label>
                    </pos-column>
                </pos-row>
            </div>
        </div>
        <div class="col-12 action-button-container page-footer">
            <button class="btn-primary btn-lg btn btn-save pos-button" scrollup type="submit" value="Save">Save</button>
            <button class="btn-secondary btn-lg btn btn-cancel pos-button" routerLink="../" scrollup type="button"
                value="Cancel">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
