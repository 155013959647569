<pos-list-wrapper [headingIcon]="icons.emailTemplatesEnvelopeOpen" heading="Email Templates" (close)="close()">
  <div class="row">
    <div class="col-md-12 col-sm-12">
      <ng-table id="emailTemplates" [sort]="true" [columns]="emailTemplateColumns" [data]="emailTemplates" #templatesGrid>
      </ng-table>
      <ng-template let-data="rowData" #operationTemplate>
        <span (click)="editItem(data.Id)" class="pe-pix-3">
          <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
        </span>
        <pos-icon class="pointer" (click)="showPreview(data)" [icons]="[icons.squareLightOrangeStack , icons.fileSearch]">
        </pos-icon>
      </ng-template>
    </div>
  </div>
</pos-list-wrapper>
