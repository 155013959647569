import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { GridColumn, EditColumn, LinkColumn, DeleteColumn, TemplateColumn, TableComponent } from '@tarktech/tark-ng-utils';
import {
  AuthenticationService,
  Levels,
  Permissions,
  ConfirmDeleteComponent,
  Messages,
  SpinnerService,
  AlertsService,
  PrintTableService,
  BaseGridComponent,
  ApplicationStateService,
  ModalBackdropService,
  ModalService
} from 'src/app/shared';
import { InventoryZoneService } from 'src/app/shared/services/inventory-zone.service';
import { InventoryZone } from '../../interface/inventory-zone';
import { listAlt, barsWhite, check, inventoryZonesWarehouse } from 'src/app/shared/components/icon';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { LinkTemplateColumn } from '@tarktech/tark-ng-utils/table/link-template-column';
import { InventoryLogDueDateOverrideEditComponent } from 'src/app/information-management/vendors';

@Component({
  selector: 'pos-inventory-zone-list',
  templateUrl: './inventory-zone-list.component.html',
  styleUrls: ['./inventory-zone-list.component.scss']
})
export class InventoryZoneListComponent extends BaseGridComponent implements OnInit {
  @ViewChild('confirmDeleteOption') private confirmDeleteOption: ConfirmDeleteComponent;
  @ViewChild('popOverForInventoryZone', { static: true }) popOverForInventoryZone: TemplateRef<any>;
  @ViewChild('popOverForInventoryZoneName', { static: true }) popOverForInventoryZoneName: TemplateRef<any>;
  @ViewChild('zoneLogDateEditModal') zoneLogDateEditModal: TemplateRef<any>;

  inventoryZoneListColumns: Array<GridColumn> = [];
  printInventoryZoneListColumns: Array<GridColumn> = [];
  editInventoryZoneId: number;
  prinitListColumns: Array<GridColumn> = [];
  selectedInventoryZoneId: number;
  inventoryZoneListData: Array<InventoryZone> = [];
  inventoryZoneData: InventoryZone;
  popup: any;
  selectedId: number;
  inventoryZoneName: string;
  @ViewChild('inventoryZoneGrid', { static: true }) inventoryZoneGrid: TableComponent;
  constructor(private authenticationService: AuthenticationService,
    private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private printService: PrintTableService,
    private inventoryZoneService: InventoryZoneService,
    private modalService: ModalService,
    private modalBackdropService: ModalBackdropService,
    protected applicationStateService: ApplicationStateService,
    protected route: ActivatedRoute,
    private router: Router) {
    super(applicationStateService, route);
  }

  permission = {
    InventoryZones: Permissions.InventoryZones
  };

  accessLevels = {
    ReadOnly: Levels.ReadOnly,
    Edit: Levels.Edit
  };

  icons = {
    barsWhite,
    listAlt,
    check,
    inventoryZonesWarehouse
  };

  ngOnInit() {
    this.configureGridColumns();
    this.reloadInventoryZoneList();
    this.inventoryZoneGrid.context = this.gridContext;
  }

  configureGridColumns() {
    const editColumn = new EditColumn({
      Clicked: (data) => {
        this.editInventoryZone(data.Id);
      },
      Width: '57px',
      AddIcon: new LinkColumn({
        Clicked: () => {
          this.editInventoryZone(0);
        },
        ToolTip: 'New'
      })
    });

    const deleteColumn = new DeleteColumn({
      Clicked: (data) => {
        this.deleteInventoryZone(data.Id);
      },
      Width: '68px',
      PrintIcon: new LinkColumn({
        Clicked: () => {
          this.printData();
        }
      })
    });

    const popoverIcon = new LinkTemplateColumn({
      CellClass: 'cell-link greyicon', itemTemplate: this.popOverForInventoryZone, Width: '5px'
    });

    this.inventoryZoneListColumns = [
      new TemplateColumn({
        HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '33%',
        itemTemplate: this.popOverForInventoryZoneName
      }),
      // new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '40%', IsPopOver: true
      // , PopOverColumnClicked: (event, data) => { this.popOverSelected(event, data); } }),
      new GridColumn({ HeaderText: 'Code', Field: 'Code', IsSortable: true, Width: '20%' }),
      new GridColumn({ HeaderText: 'Color', Field: 'Color', IsSortable: true, Width: '20%' }),
      new GridColumn({ HeaderText: 'Label', Field: 'Label', IsSortable: true, Width: '20%' })
    ] as Array<GridColumn>;
    this.printInventoryZoneListColumns = [...this.inventoryZoneListColumns];
    if (this.authenticationService.userHasPermission([{ Name: this.permission.InventoryZones, Level: this.accessLevels.Edit }],
      'any')) {
      this.inventoryZoneListColumns.push(editColumn);
      this.inventoryZoneListColumns.push(deleteColumn);
      this.inventoryZoneListColumns.push(popoverIcon);
    }
  }

  editInventoryZone(inventoryZoneId) {
    this.editInventoryZoneId = inventoryZoneId;
    this.router.navigate([inventoryZoneId], { relativeTo: this.route });
  }

  deleteInventoryZone(inventoryzoneId) {
    this.selectedInventoryZoneId = inventoryzoneId;
    this.confirmDeleteOption.Show(Messages.ConfirmDeleteInventoryZone);
  }

  printData() {
    this.printService.printEmitter.next({ gridColumns: this.printInventoryZoneListColumns, gridData: this.inventoryZoneListData });
  }

  onConfirmAcknowledge() {
    this.spinnerService.show();
    this.inventoryZoneService.deleteInventoryZone(this.selectedInventoryZoneId).subscribe(response => {
      this.alertsService.renderSuccessMessage(Messages.InventoryZoneDeleted);
      this.reloadInventoryZoneList();
    },
      (error) => { this.alertsService.showApiError(error); },
      () => { this.spinnerService.hide(); }
    );
  }

  reloadInventoryZoneList() {
    this.spinnerService.show();
    this.inventoryZoneService.getInventoryZones().subscribe(response => {
      if (response) {
        this.inventoryZoneListData = response;
      }
    },
      (error) => { this.alertsService.showApiError(error); },
      () => { this.spinnerService.hide(); });
  }


  onLinkPopOver(data: InventoryZone, popup: any) {
    this.inventoryZoneData = data;
    this.popup = popup;
  }

  openNextInventoryLogDateModal(data: InventoryZone) {
    this.selectedInventoryZoneId = data.Id;
    this.inventoryZoneName = data.Name;
    const modalRef = this.modalService.getModalWrapper(InventoryLogDueDateOverrideEditComponent);

    const modal = modalRef.show({
      'backdrop': 'static',
      'class': 'vertical-center',
      keyboard: false,
      initialState: {
        inventoryZoneId: this.selectedInventoryZoneId,
        heading: 'Inventory Zone: ' + this.inventoryZoneName
      }
    });
    modal.close.subscribe((res) => {
      if (res && res.reload) {
        this.alertsService.renderSuccessMessage(Messages.NextInventoryDueDateOverrideEditSuccess);
      }
    });
    this.popup.hide();
  }

  openInventoryProductForZone(data: InventoryZone, popup: any) {
    this.selectedId = data.Id;
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
