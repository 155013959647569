<pos-list-wrapper [headingIcon]="icons.warehouseAlt" heading="Off-Site Locations" (close)="close()">
    <div class="row">
        <div class="col-12">
            <div class="label-section float-end pe-pix-10">
                <p-checkbox name="chkActive" #chkActive="ngModel" (onChange)="changeLocationList()"
                    [(ngModel)]="showActive" binary="true" label="Active">
                </p-checkbox>
            </div>
        </div>
        <div class="col-12">
            <ng-table #offSiteLocationsGrid [columns]="locationsColumns"
                [exportFilename]="'off-site-locations'" [data]="filteredOffSiteLocations"
                id="offSiteLocations" [sort]="true">
            </ng-table>
        </div>
    </div>
</pos-list-wrapper>
<ng-template let-data="rowData" #activeTemplate>
    <span *ngIf="(data.IsActive)" class="green text-center vertical-mid font-13">
        <pos-icon [icon]="icons.check"></pos-icon>
    </span>
</ng-template>
<ng-template let-data="rowData" #operationTemplate>
    <span (click)="editItem(data.Id, data)" class="pe-pix-3" tooltip title="Edit">
        <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
    </span>
    <span *ngIf="data.IsActive" class="pt-pix-3 pe-pix-3" (click)="changeActiveStatus(data, false)" tooltip
        title="Deactivate off site location">
        <pos-action-icon [icon]="icons.ban"></pos-action-icon>
    </span>
    <span *ngIf="!data.IsActive" class="pt-pix-3 pe-pix-3" (click)="changeActiveStatus(data, true)" tooltip
        title="Activate off site location">
        <pos-action-icon [icon]="icons.checkCircleWhite"></pos-action-icon>
    </span>
    <span class="text-center mt-pix-n4" style="overflow-wrap: break-word;">
        <a class="action-btn grey pointer" #pop="bs-popover" placement="left" (click)="onLinkPopOver(data,pop)"
            [outsideClick]="true" [popover]="offsiteLocationPopoverTemplate" triggers="click">
            <pos-action-icon [icon]="icons.barsWhite"></pos-action-icon>
        </a>
    </span>
</ng-template>
<ng-template #headerTemplate>
    <div class="text-center align-content-center">
        <pos-action-icon (click)="editItem(0)" class="inline-block" [icon]="icons.plusWhite" iconText="Add">
        </pos-action-icon>
        <pos-export-dropdown class="inline-block ps-pix-5" (onPrint)="printData()"
            (onCSV)="exportCSV()"></pos-export-dropdown>
    </div>
</ng-template>
<ng-template #offsiteLocationPopoverTemplate>
    <ul>
        <li class="listbullet">
            <a class="grey pointer" (click)="openInventorySettings(offsiteLocation)">
                <pos-icon [icon]="icons.cogs" class="pe-pix-5"></pos-icon>Inventory Settings
            </a>
        </li>
    </ul>
</ng-template>
<pos-print-table [hidden]="true"></pos-print-table>
