import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { VendorAndCategory } from '../../manage-console/inventory/inventory-log/interfaces/vendor-and-category';
import { MathsUtilityService } from './maths-utility.service';
import { InventoryScannerBatchModel } from 'src/app/manage-console/inventory-barcode/interfaces/inventory-scanner-batch-model';

@Injectable({
  providedIn: 'root'
})
export class InventoryLogService {

  inventoryLogApiUrl = '';
  constructor(private httpService: HttpClient, private mathsUtilityService: MathsUtilityService,) { 
    this.inventoryLogApiUrl = RuntimeConstants.API_PATH + 'inventory-log';
  }
  getVendorAndCategory(): Observable<VendorAndCategory> {
    return this.httpService.get<VendorAndCategory>(this.inventoryLogApiUrl+'/vendor-category');
  }

  getInventoryLogging(inventoryData) {
    return this.httpService.post(this.inventoryLogApiUrl, inventoryData);
  };

  updateInventoryLog(inventoryFinalLog): Observable<any> {
    return this.httpService.post(
      `${this.inventoryLogApiUrl}/update`, inventoryFinalLog
    );
  }
  
  printInventory(inventoryModel) {
    return this.httpService.post(
      `${this.inventoryLogApiUrl}/printInventory`, inventoryModel
    );
  }

  qtyChange(itemDetails, inventoryProductForLog) {
    const hasUnitQtyIncrementError = this.hasUnitQtyIncrementError(itemDetails.Qty, itemDetails.UnitQtyIncrement);
    itemDetails.IsUpdated = true;
    itemDetails.IsChanged = inventoryProductForLog.oldQty !== Number(inventoryProductForLog.Qty);
    const diff = itemDetails.Qty - itemDetails.CalculatedQty;
    let originalQty = itemDetails.CalculatedQty;
    if (itemDetails.CalculatedQty === 0) {
      originalQty = 100;
    }
    const qtyPercent = Math.abs(diff * 100 / originalQty);
    const consumptionVarianceLowThresholdPercent = itemDetails.ConsumptionVarianceLowThresholdPct;
    const consumptionVarianceHighThresholdPercent = itemDetails.ConsumptionVarianceHighThresholdPct;
    itemDetails.varianceExceedsLowThreshold = false;
    itemDetails.varianceExceedsHighThreshold = false;
    if (itemDetails.IsWarnUnexpectedInventoryQty) {
      if (Math.abs(qtyPercent) > consumptionVarianceLowThresholdPercent && itemDetails.Qty < originalQty) {
        if ((itemDetails.ConsumptionThresholdLowAmount > 0 || consumptionVarianceLowThresholdPercent > 0)
          && Math.abs(diff) > itemDetails.ConsumptionThresholdLowAmount) {
          itemDetails.varianceExceedsLowThreshold = true;
          if (((itemDetails.ConsumptionVarianceLowThresholdPct * originalQty) / 100) < itemDetails.ConsumptionThresholdLowAmount) {
            itemDetails.DifferedBy = itemDetails.ConsumptionThresholdLowAmount + ' ' + itemDetails.Unit;
          } else {
            itemDetails.DifferedBy = itemDetails.ConsumptionVarianceLowThresholdPct + '%';
          }

        }
      } else if (Math.abs(qtyPercent) > consumptionVarianceHighThresholdPercent && itemDetails.Qty > originalQty) {
        if ((itemDetails.ConsumptionThresholdHighAmount > 0 || consumptionVarianceHighThresholdPercent > 0)
          && Math.abs(diff) > itemDetails.ConsumptionThresholdHighAmount) {
          itemDetails.varianceExceedsHighThreshold = true;
          if (((itemDetails.ConsumptionVarianceHighThresholdPct * originalQty) / 100) < itemDetails.ConsumptionThresholdHighAmount) {
            itemDetails.DifferedBy = itemDetails.ConsumptionThresholdHighAmount + ' ' + itemDetails.Unit;
          } else {
            itemDetails.DifferedBy = itemDetails.ConsumptionVarianceHighThresholdPct + '%';
          }
        }
      }
    }
    itemDetails.TotalPrice = itemDetails.Qty * itemDetails.CurrentValue;
    return hasUnitQtyIncrementError;
  }

  hasUnitQtyIncrementError(qty: number, unitQtyIncrement?: number): boolean {
    return unitQtyIncrement != null && qty != null
      && this.mathsUtilityService.floatSafeModulus(parseFloat(qty.toString()), parseFloat(unitQtyIncrement.toString())) != 0;
  }

  getNewInventoryScannerBatchModel(): InventoryScannerBatchModel {
    return {
      IsUpdated: false,
      InventoryId: 0,
      InventoryName: '',
      Unit: '',
      PrimaryZoneName: '',
      SecondaryZoneName: '',
      PrimaryQty: 0,
      SecondaryQty: 0,
      OtherQty: 0,
      TotalQty: 0,
      Memo: null,
      InventoryFinalLog: {
        CategoryList: [],
        IsChecked: false,
        CategoryName: '',
      },
    }
  }
}
