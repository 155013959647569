import { Component, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseFormComponent, FormUtilityService } from 'src/app/shared';
@Component({
  selector: 'pos-sales-product-edit-wrap',
  templateUrl: './sales-product-edit-wrap.component.html',
  styleUrls: ['./sales-product-edit-wrap.component.scss']
})
export class SalesProductEditWrapComponent extends BaseFormComponent implements OnInit {

  form: NgForm;
  @Input() isScroll = false;
  @Input() salesProductId: number = null;
  @Input() salesProductName = '';
  initialize = false;
  get getForm(): NgForm {
    return this.form;
  }
  constructor(private route: ActivatedRoute, private router: Router, formUtilityService: FormUtilityService) {
    super(formUtilityService);
    const navigation = this.router.getCurrentNavigation();
    this.salesProductId = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
    this.isScroll = (navigation?.extras?.state && navigation?.extras?.state.isScroll) ?? false;
    this.salesProductName = (navigation?.extras?.state && navigation?.extras?.state.salesProductName) ?? '';
    this.initialize = false;
  }

  ngOnInit() {
    this.initialize = true;
  }
  onFormChange($event: NgForm) {
    this.form = $event;
    this.setInitialValue();
  }

  onClose($event: any) {
    this.router.navigate(['sales-products'], { relativeTo: this.route.parent });
  }

}
