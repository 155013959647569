import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HangfireJobs } from '../interface';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';

@Injectable()
export class HangfireJobsService extends BaseCrudService<HangfireJobs> {

  hangfireJobsApiUrl = '';
  constructor(httpService: HttpClient) {
    super('jobs', httpService);
    this.hangfireJobsApiUrl = RuntimeConstants.API_PATH + 'jobs';
  }

  getJobDetails(entityType: string, entityId: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('entityType', entityType);
    params = params.append('entityId', entityId);
    return this.httpService.get(`${this.hangfireJobsApiUrl}`, { params: params });
  }

  getEntityTypes(): Observable<any> {
    return this.httpService.get(`${this.hangfireJobsApiUrl}/getEntityTypes`)
  }
}
