<div class="col-12 mt-pix-5 pb-pix-5 border-top pt-pix-15">
    <div>
      <div class="col-12 p-0">
        <div class="reset-style bold"><span class="bold">Nutrition</span></div>
        <div class="pt-pix-10 pb-pix-10">
          <div>
            <p-table [value]="nutritionInfo">
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 40%">Nutrition Fact Element</th>
                  <th style="width: 10%">Amount</th>
                  <th style="width: 38%">Measure</th>
                  <th style="width: 10%">Percent</th>
                  <th style="width: 45px"></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-i="rowIndex">
                <tr style="height: 37px;">
                  <td>
                    <ng-select 
                    appendTo="body"
                    placeholder="Select parent ingredient" 
                    (change)="createNutritionRow(i+1== nutritionInfo.length);disableSelectedNutritionFact(i)"
                    name="nutritionFactElement{{i}}"
                    id="nutritionFactElement{{i}}"
                    #nutritionFactElement="ngModel"                    
                    [items]="nutritionFactElements" 
                    [style]="{'width':'80%'}"
                    [(ngModel)]="rowData.NutritionFactElementId"                   
                    [multiple]="false" 
                    [selectOnTab]="true" 
                    bindLabel="label"
                    bindValue="value"                                    
                    [clearable]="false"
                    [required]="i+1 != nutritionInfo.length">      
                          
                  </ng-select>
                    <span [ngClass]="{'has-error': nutritionFactElement.errors?.required}"
                      *ngIf="nutritionFactElement.errors?.required">
                      <label class="control-label">Please
                        select nutrition fact element.</label>
                    </span>
                  </td>
                  <td class="text-right">
                    <input pInputText type="number" class="text-right form-control" min="0" step="0.01"
                      name="amount{{i}}" id="amount{{i}}" #amount="ngModel" [(ngModel)]="rowData.Amount"
                      (keypress)="createNutritionRow(i+1== nutritionInfo.length)"
                      invalid-message="'Please enter positive amount.'" required
                      oninput="validity.valid||(value='');">
                    <span [ngClass]="{'has-error': (amount.invalid && amount.touched)}"
                      *ngIf="amount.invalid && amount.touched && !(i+1== nutritionInfo.length)">
                      <label class="control-label" *ngIf="amount.errors?.required">Please
                        enter amount.</label>
                    </span>
                    <span [ngClass]="{'has-error': (rowData.Amount < 0)}" *ngIf="rowData.Amount < 0">
                      <label class="control-label">Please
                        enter positive amount.</label>
                    </span>
                  </td>
                  <td>
                    <ng-select 
                    appendTo="body"
                    placeholder="Select measure"
                    name="nutritionFactMeasure{{i}}"
                    id="nutritionFactMeasure{{i}}"
                    #nutritionFactMeasure="ngModel"                    
                    [items]="nutritionFactMeasures" 
                    [style]="{'width':'80%'}"
                    [(ngModel)]="rowData.MeasureId"                 
                    [multiple]="false" 
                    [selectOnTab]="true" 
                    bindLabel="label"
                    bindValue="value"                                    
                    [clearable]="false">                    
                  </ng-select>

                  </td>
                  <td>
                    <input pInputText type="number" name="percent{{i}}" #percent="ngModel" min="0" step="0.01"
                      id="percent{{i}}" class="form-control" [(ngModel)]="rowData.Percent"
                      oninput="validity.valid||(value='');">

                  </td>
                  <td class="text-center">
                    <a (click)="clearNutritionRow(rowData, i)" title="Delete" *ngIf="!(i+1== nutritionInfo.length)">
                      <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
                    </a>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
</div>
