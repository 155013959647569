import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'pos-kiosk-modal-wrapper',
  templateUrl: './kiosk-modal-wrapper.component.html',
  styleUrls: ['./kiosk-modal-wrapper.component.scss']
})
export class KioskModalWrapperComponent {
  @Input() heading: string;
  @Input() showCloseIcon = true;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  closeModal() {
    this.close.next({ shouldReload: false });
  }

}
