<div class="kiosk-overlay kiosk-background" [style.background-image]="backgroundImage ? 'url(\'' + backgroundImage + '\')' : null">
    <div class="footer-buttons">
        <button class="btn reject-button" (click)="closeConfirmCancel()">
            <img class="mt-pix-n3 me-pix-2" height="18" [src]="imageRootPath+'back-arrow.png'" /> Oops! Take me back
        </button>
        <button class="btn confirm-button" (click)="closeConfirmCancel(true)">
            <p>Yes, I want to cancel</p>
            <p>My entire order</p>
        </button>
    </div>
</div>
