import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Messages } from '../../constants/ui-messages';
import { ApplicationStateService } from '../../services';


@Component({
  selector: 'unsaved-form-data',
  templateUrl: './unsaved-form-data.component.html'  
})
export class UnsavedFormDataComponent implements OnInit {
  public message: string = Messages.UnsavedChanges;
  public close: EventEmitter<any> = new EventEmitter();
  constructor(private modalRef: BsModalRef,
    private applicationStateService: ApplicationStateService) {  }

  ngOnInit() {
    this.applicationStateService.stayWithUnsavedFormChanges = false;
  }

  public leave(): void {
    this.applicationStateService.stayWithUnsavedFormChanges = false;
    this.close.emit({shouldHide: true});
  }

  public stay(): void {
    this.applicationStateService.stayWithUnsavedFormChanges = true;
    this.close.emit({shouldHide: false});
  }

}
