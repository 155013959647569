<pos-edit-wrapper heading="{{inventoryOffSiteLocation.Id ? 'Edit' : 'Add'}} Off-Site Location" (close)="cancel()">
    <form #inventoryOffSiteLocationForm="ngForm" (ngSubmit)='saveOffSiteLocation(inventoryOffSiteLocationForm.valid)'>
        <div class="overflow-hidden col-12 pos-modal-body">
            <pos-row>
                <pos-column>
                    <pos-form-field label="Name" [validationMessages]="{required: 'Please enter name.'}">
                        <input posInput name="Name" #name="ngModel" type="text" class="form-control"
                            [(ngModel)]="inventoryOffSiteLocation.Name" [required]="true" />
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" value="Save">Save</button>
            <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" value="Cancel"
                (click)="cancel()">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
