import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AlertsService, BaseFormComponent, ConfirmDeleteModalComponent, FormUtilityService, Messages, ModalService, SpinnerService, StringUtils } from 'src/app/shared';
import { Dayparts } from '../../interface';
import { DaypartsService } from '../../services/dayparts.service';
import { dayparts, deleteWhite } from 'src/app/shared/components/icon/icons';
import { forEach, orderBy, remove } from 'lodash';
import { NgForm } from '@angular/forms';
import { formatDate } from '@angular/common';

@Component({
  selector: 'pos-dayparts',
  templateUrl: './dayparts.component.html',
  styleUrls: ['./dayparts.component.scss']
})
export class DaypartsComponent extends BaseFormComponent implements OnInit {

  @ViewChild('daypartsForm') daypartsForm: NgForm;
  get getForm(): NgForm {
    return this.daypartsForm
  }

  dayparts: Array<Dayparts> = [];
  remainingTimeBlocks: Array<string> = [];
  isBetweenTime = false;
  icons = {
    deleteWhite,
    dayparts
  };
  isShowTimeBlocks = true;
  props = {
    controlBoxClass: 'col-lg-12 col-md-12 col-sm-12 col-xs-12'
  }

  constructor(private daypartsService: DaypartsService,
    private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private modalService: ModalService,
    private router: Router,
    formUtilityService: FormUtilityService) {
    super(formUtilityService);
  }

  ngOnInit(): void {
    this.loadData();
  }

  customTrackBy(index: number, obj: any): any {
    return index;
  }

  loadData() {
    this.dayparts = [];
    this.spinnerService.show();
    this.daypartsService.getAll()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: Array<Dayparts>) => {
          this.dayparts = orderBy(response, 'StartTime');
          this.getRemainingTimeBlocks();
          this.createDaypartsRow(true);
        }, error: this.alertsService.showApiError
      });
  }

  getRemainingTimeBlocks() {
    this.remainingTimeBlocks = [];
    if (this.dayparts.length) {
      forEach(this.dayparts, (daypart, i) => {
        if (this.dayparts[i + 1]?.StartTime && daypart.EndTime !== this.dayparts[i + 1].StartTime) {
          this.remainingTimeBlocks.push(this.parseTime(daypart.EndTime) + ' - ' + this.parseTime(this.dayparts[i + 1].StartTime));
        }
      });
    }
  }

  parseTime(timeString) {
    const time = timeString.split(':');
    const date = new Date();
    date.setHours(time[0]);
    date.setMinutes(parseInt(time[1], 10) || 0);
    return formatDate(date, 'hh:mm a', 'en');
  }

  createDaypartsRow(lastRow: boolean) {
    if (!lastRow && this.dayparts.length > 1) {
      return;
    }
    const newDaypart = this.daypartsService.newDayparts();
    this.dayparts.push(newDaypart);
  }

  timeToDateTime(timeString) {
    const timeTokens = timeString.split(':');
    if (timeTokens && timeTokens.length > 0) {
      return new Date(1970, 0, 1, timeTokens[0], timeTokens[1]).getTime();
    }
  }

  saveDayparts(isValid: boolean) {
    if (!isValid) {
      return;
    }
    let isValidTime = true;
    this.isBetweenTime = false;
    forEach(this.dayparts, (daypart) => {
      if (daypart.StartTime && daypart.EndTime) {
        if (this.timeToDateTime(daypart.StartTime) > this.timeToDateTime(daypart.EndTime)) {
          isValidTime = false;
        }
      }
    });
    if (!isValidTime) {
      this.alertsService.renderErrorMessage(Messages.ErrorWhileStartTimeIsLaterThanEndTime);
      return;
    }
    forEach(this.dayparts, (daypart) => {
      if (daypart.StartTime && daypart.EndTime) {
        this.checkBetweenTime(daypart);
      }
    });
    if (this.isBetweenTime) {
      return;
    }
    const daypartsCopy = [...this.dayparts];
    forEach(daypartsCopy, (daypart) => {
      if (!daypart.Name) {
        this.removeDaypart(daypart, null, true);
      }
    });
    remove(daypartsCopy, (daypart) => daypart.Invalidated);
    if (daypartsCopy.length) {
      this.spinnerService.show();
      this.daypartsService.save(daypartsCopy)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: () => {
            this.alertsService.renderSuccessMessage(Messages.DaypartsSaveSuccess);
            this.daypartsForm.resetForm();
            this.loadData();
          }, error: this.alertsService.showApiError
        });
    } else {
      this.daypartsForm.resetForm();
      this.dayparts = [];
      this.createDaypartsRow(true);
    }
  }

  checkBetweenTime(daypart: Dayparts) {
    forEach(this.dayparts, (part) => {
      if (part.StartTime && part.EndTime) {
        if (part.StartTime.substr(0, 5) < daypart.StartTime.substr(0, 5) && daypart.StartTime.substr(0, 5) < part.EndTime.substr(0, 5)) {
          this.isBetweenTime = true;
          daypart.HasBetweeenStartTime = true;
        }
        if (part.StartTime.substr(0, 5) < daypart.EndTime.substr(0, 5) && daypart.EndTime.substr(0, 5) < part.EndTime.substr(0, 5)) {
          this.isBetweenTime = true;
          daypart.HasBetweeenEndTime = true;
        }
      }
    });
  }

  removeDaypart(part: Dayparts, index, fromSave = false): void {
    this.resetBetweenTime();
    if (!fromSave) {
      const modal = this.modalService.getModalWrapper(ConfirmDeleteModalComponent);
      const modalRef = modal.show({
        animated: false,
        class: 'vertical-center',
        keyboard: false,
        initialState: {
          message: StringUtils.format(Messages.DaypartConfirmDelete, { daypartName: part.Name })
        }
      });
      modalRef.close.subscribe(res => {
        if (res && res.shouldDelete) {
          this.deleteDaypart(part, index, fromSave);
        }
      });
    } else {
      this.deleteDaypart(part, index, fromSave);
    }
  }

  deleteDaypart(part: Dayparts, index, fromSave) {
    if (part.Id > 0) {
      this.spinnerService.show();
      this.daypartsService.delete(part.Id)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res) => {
            if (!fromSave) {
              this.loadData();
              this.alertsService.renderSuccessMessage(Messages.DaypartDeleteSuccess);
            }
          }, error: this.alertsService.showApiError
        });
    } else if (!fromSave) {
      this.dayparts.splice(index, 1);
    }
    part.Invalidated = true;
  }

  cancel() {
    this.router.navigate(['/manage/system-configuration']);
  }

  resetBetweenTime() {
    forEach(this.dayparts, (part) => {
      part.HasBetweeenStartTime = false;
      part.HasBetweeenEndTime = false;
    });
  }

}
