import { Component, OnInit, ViewChild } from '@angular/core';
import { WorkScheduleService } from 'src/app/shared/services/work-schedule.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { FormUtilityService, Messages, ModalFormComponent, ModalService } from 'src/app/shared';
import { forwardFad, fileImport, fieldInfoCircle } from 'src/app/shared/components/icon';
import { ConfirmDeleteComponent } from 'src/app/shared/components/confirm-delete/confirm-delete.component';
import { finalize } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';
declare let $: any;
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { NgForm } from '@angular/forms';
import { HttpStatusCode } from '@angular/common/http';

@Component({
    selector: 'pos-import-work-schedule',
    templateUrl: './import-work-schedule.component.html',
    styleUrls: ['./import-work-schedule.component.scss']
})
export class ImportWorkScheduleComponent extends ModalFormComponent implements OnInit {
    get getForm(): NgForm {
        return this.form
    }
    excelError = { isError: false, errorMessage: '',  isConflicted: false};
    workScheduleFile: File;
    cachedScheduleFile: File;
    skipConflicts = false;
    overwriteConflicts = false;
    workScheduleMessage = Messages.WorkScheduleImportFormatsMessage;
    @ViewChild('confirmImportWorkSchedule') private confirmImportWorkSchedule: ConfirmDeleteComponent;
    @ViewChild('importWorkScheduleForm', { static: true }) form: NgForm;
    icons = {
        forwardFad, fileImport, fieldInfoCircle
      };
    constructor(
        private workScheduleService: WorkScheduleService,
        private spinnerService: SpinnerService,
        private alertService: AlertsService,
        workScheduleModalRef: BsModalRef,
        modalService: ModalService,
        formUtilityService: FormUtilityService) {
        super(workScheduleModalRef, modalService, formUtilityService);
    }

    ngOnInit() {
    }

    onImportWorkSchedule(event) {
        this.excelError = { isError: false, errorMessage: '', isConflicted: false };
        this.workScheduleFile = this.cachedScheduleFile = event.target.files.item(0);
        this.validateFile();
        if (this.excelError.isError) {
            event.target.value = '';
        }
        this.setInitialValue();
    }
    validateFile() {
        if (this.workScheduleFile) {
            const fileExtension =
                this.workScheduleFile.name.substring(this.workScheduleFile.name.lastIndexOf('.'), this.workScheduleFile.name.length);
            if (fileExtension === '.ods' || fileExtension === '.xls' || fileExtension === '.xlsx' || fileExtension === '.csv') {
            } else {
                this.excelError = { isError: true, errorMessage: 'Invalid file selected. only .ods, .xls, .xlsx, .csv files are allowed.', isConflicted: false};
            }
        } else {
            this.excelError = { isError: true, errorMessage: 'Please choose file.', isConflicted: false };
        }
    }

    import(skipConflicts = false, overwriteConflicts = false) {
        this.skipConflicts = skipConflicts;
        this.overwriteConflicts = overwriteConflicts;
        this.workScheduleFile = this.cachedScheduleFile;
        if (skipConflicts || overwriteConflicts) {
            this.confirmImportWorkSchedule.Show(
                StringUtils.format(Messages.WorkScheduleSkipOverwrite, { 'action': (skipConflicts ? 'skip' : 'overwrite') }));
        } else if (this.workScheduleFile) {
            this.confirmImportWorkSchedule.Show(
                StringUtils.format(Messages.WorkScheduleImportConfirmation, { 'fileName': this.workScheduleFile.name }));
        } else if (!this.workScheduleFile) {
            this.excelError = { isError: true, errorMessage: 'Please choose file.', isConflicted: false };
        }
    }

    onCancelConfirm() {
        this.skipConflicts = false;
        this.overwriteConflicts = false;
    }

    onConfirmAcknowledge() {
        this.spinnerService.show();
        this.workScheduleService.SaveWorkScheduleImport(this.workScheduleFile, this.skipConflicts, this.overwriteConflicts)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (result) => {
                        this.resetFile();
                        this.alertService.renderSuccessMessage(Messages.WorkScheduleImportSuccess);
                        this.onCancel(true);
                }, error: (err) => {
                    this.resetFile();
                    this.excelError = { isError: true, errorMessage: err.error, isConflicted:  err.status == HttpStatusCode.Conflict ? true : false};
                }
            });

    }

    onCancel(isReload: boolean) {
        const timeoutObj = setTimeout(() => {
            this.hide({ shouldReload: isReload });
            clearTimeout(timeoutObj);
        }, 500);
    }

    resetFile() {
        $('#workScheduleFile').val('');
        this.workScheduleFile = undefined;
    }
}
