import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { ICloseable, SpinnerService, ModalService } from 'src/app/shared/components';
import { Messages } from 'src/app/shared/constants';
import { AlertsService, ApplicationStateService } from 'src/app/shared/services';
import { ReportParameter } from '../../interfaces/report-parameter';
import { reportBan, runReport } from 'src/app/shared/components/icon/icons';
import { ReportService } from '../../services/report.service';
import { ReportParameterRules } from '../../interfaces/report-parameter-rules';
import { finalize } from 'rxjs/operators';
import { find, forEach } from 'lodash';
import { ActiveReport } from '../../interfaces/active-report';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';

@Component({
  selector: 'pos-report-parameters',
  templateUrl: './report-parameters.component.html',
  styleUrls: ['./report-parameters.component.scss']
})
export class ReportParametersComponent implements OnInit, ICloseable {

  @Input() parameters: Array<ReportParameter> = [];
  @Input() activeReport: ActiveReport;
  close: EventEmitter<any> = new EventEmitter<any>();
  reportParameterRules: Array<ReportParameterRules> = [];
  hasError = false;
  icons = {
    reportBan, runReport
  };
  dateFormat = 'mm-dd-yyyy';
  constructor(private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private modalService: ModalService,
    private applicationStateService: ApplicationStateService,
    private reportService: ReportService) { }

  ngOnInit(): void {
    this.getReportParameterRules();
    this.dateFormat = this.applicationStateService.settingParam.PCalendarDateFormat;
  }

  getReportParameterRules() {
    this.spinnerService.show();
    this.reportService.getReportParameterRules(this.activeReport.Id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: Array<ReportParameterRules>) => {
              this.reportParameterRules = response;
              forEach(this.parameters, param => {
                  if (param.DataType === 'MULTISELECT') {
                      if (param.InitialValue) {
                          param.InitialValue = param.InitialValue.split(',');
                      }
                  }
              });
        }, error: this.alertsService.showApiError
      });
  }

  runReport() {
    this.hasError = false;
    this.validateReportParameters();
    if (!this.hasError && this.validateReportParameterRules()) {
      this.close.emit({ shouldRunReport: true });
    }
  }

  validateReportParameterRules() {
    let validationErrors = '';
    forEach(this.reportParameterRules, (rule) => {
      const firstParamId = find(this.parameters, { Id: rule.Parameter1Id });
      const secondParamId = find(this.parameters, { Id: rule.Parameter2Id });
      if (!this.compare(firstParamId.InitialValue, secondParamId.InitialValue, rule.Comparator)) {
        validationErrors += (validationErrors.length > 0 ? '<br>' : '') + rule.Message;
      }
    });
    if (validationErrors.length > 0) {
      this.alertsService.renderErrorMessage(validationErrors);
      return false;
    }
    return true;
  }

  validateReportParameters() {
    forEach(this.parameters, (param) => {
      if (param) {
        if (param.DataType === 'TEXT') {
          if (!param.InitialValue && param.IsRequired) {
            this.alertsService.renderErrorMessage(StringUtils.format(Messages.ReportParameterRequired,
              { 'parameterName': param.Label }));
            this.hasError = true;
          }
        } else if (param.DataType === 'DATE') {
          if (param.InitialValue) {
            const reportDate = new Date(param.InitialValue.toString());
            param.InitialValue = reportDate;
          } else {
            this.alertsService.renderErrorMessage(Messages.DateNotSelected);
            this.hasError = true;
          }
        } else if (param.DataType === 'INT') {
          this.validateDropdownWithIntDataType(param);
        } else if (param.DataType === 'DROPDOWN') {
          this.validateDropdown(param);
        } else if (param.DataType === 'MULTISELECT') {
          this.validateMultiselect(param);
        }
      }
    });
  }

  private validateDropdown(param: ReportParameter) {
    if (!param.InitialValue) {
      this.alertsService.renderErrorMessage(StringUtils.format(Messages.ReportParameterRequired,
        { 'parameterName': param.Label }));
      this.hasError = true;
    }
  }

  private validateMultiselect(param: ReportParameter) {
    if (!param.InitialValue && param.IsRequired) {
      this.alertsService.renderErrorMessage(StringUtils.format(Messages.ReportParameterRequired,
        { 'parameterName': param.Label }));
      this.hasError = true;
    } else if (param.InitialValue) {
      param.InitialValue = param.InitialValue.join();
    } else {
      param.InitialValue = '';
    }
  }

  private validateDropdownWithIntDataType(param: ReportParameter) {
    if (param.ManualValues !== 'NULL,All') {
      if (!param.InitialValue) {
        this.alertsService.renderErrorMessage(StringUtils.format(Messages.ReportParameterRequired,
          { 'parameterName': param.Label }));
        this.hasError = true;
      } else if (isNaN(parseInt(param.InitialValue.toString(), 10))) {
        this.alertsService.renderErrorMessage(StringUtils.format(Messages.ReportParameterInvalidInput,
          { 'parameterName': param.Label }));
        this.hasError = true;
      } else if (param.Name === '@Threshold' && this.activeReport.Name === 'Inventory Restock Order') {
        if (parseInt(param.InitialValue.toString(), 10) < 0 || parseInt(param.InitialValue.toString(), 10) > 100) {
          this.alertsService.renderErrorMessage(StringUtils.format(Messages.ReportParameterInvalidRange,
            { 'parameterName': param.Label }));
          this.hasError = true;
        }
      }
    }
  }

  compare(val1, val2, op) {
    switch (op) {
      case '<=':
        return val1 <= val2;
      case '<':
        return val1 < val2;
      case '=':
        return val1 === val2;
      case '>=':
        return val1 >= val2;
      case '>':
        return val1 > val2;
      default:
        return false;
    }
  }

  closeModal() {
    this.close.emit();
  }

}
