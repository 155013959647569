<pos-edit-wrapper (close)="onCancel()" [isPopup]="false" [headingIcon]="icons.history"
  heading="{{this.id? 'Edit': 'Add'}} Inventory Product History{{this.id? ': ' + inventoryProductName: ''}}">
    <form #inventoryProductHistoryForm="ngForm"
        (ngSubmit)="saveProductHistory(inventoryProductHistoryForm.valid)" autocomplete="off">
        <context-provider provide="props">
        <div class="col-12 px-4 border-top ">
            <div class="col-12">

                <pos-row colXs="12" class="p-0">
                    <pos-column>
                        <pos-form-field label="Name" [validationMessages]="{required: 'Please enter name.'}">
                            <input posInput class="form-control" name="name" #name="ngModel"
                                [(ngModel)]="inventoryProductHistory.Name" [required]="true">
                        </pos-form-field>
                    </pos-column>
                    <pos-column>
                        <pos-form-field label="Details">
                            <input posInput class="form-control" name="unitDetails" #unitDetails="ngModel"
                                [(ngModel)]="inventoryProductHistory.UnitDetails">
                        </pos-form-field>
                    </pos-column>
                    <pos-column>
                        <pos-form-field label="Unit" [validationMessages]="{required: 'Please select unit.'}"
                            inputStyle="col-lg-2 col-md-2 col-sm-4 col-xs-6">
                            <ng-select posInput appendTo="body" [items]="unitList" bindLabel="Name" bindValue="Id"
                                placeholder="Select unit" #unit="ngModel"
                                [(ngModel)]="inventoryProductHistory.InventoryUnitId" name="unit" [required]="true">
                            </ng-select>
                        </pos-form-field>
                    </pos-column>
                    <pos-column>
                        <pos-row colLg="2" colSm="3" colXs="12" class="p-0">
                            <pos-column>
                                <pos-form-field label="Unit Qty" [validationMessages]="{required: 'Please enter unit qty.'}"
                                    inputStyle="col-md-2 col-170">
                                    <input posInput class="form-control" name="unitQty" #unitQty="ngModel" type="number"
                                        [(ngModel)]="inventoryProductHistory.UnitQty" [required]="true">
                                </pos-form-field>
                            </pos-column>
                            <pos-column>
                                <pos-form-field label="Price" [validationMessages]="{required: 'Please enter price.'}"
                                    inputStyle="col-md-2 col-170">
                                    <input posInput class="form-control" name="price" #price="ngModel" type="number"
                                        [(ngModel)]="inventoryProductHistory.Price" [required]="true">
                                </pos-form-field>
                            </pos-column>
                        </pos-row>
                    </pos-column>
                    <pos-column>
                        <pos-row colLg="2" colSm="3" colXs="12" class="p-0">
                            <pos-column>
                                <pos-form-field label="Min Level" [validationMessages]="{required: 'Please enter unit min level.'}"
                                    inputStyle="col-md-2 col-170">
                                    <input posInput class="form-control" name="min" #min="ngModel" type="number"
                                    [(ngModel)]="inventoryProductHistory.MinLevel" [required]="true">
                                </pos-form-field>
                            </pos-column>
                            <pos-column>
                                <pos-form-field label="Max Level" [validationMessages]="{required: 'Please enter max level.'}"
                                    inputStyle="col-md-2 col-170">
                                    <input posInput class="form-control" name="max" #max="ngModel" type="number"
                                    [(ngModel)]="inventoryProductHistory.MaxLevel" [required]="true">
                                </pos-form-field>
                            </pos-column>
                        </pos-row>
                    </pos-column>
                    <pos-column>
                        <pos-form-field label="Vendor" [validationMessages]="{required: 'Please select vendor.'}">
                            <ng-select posInput appendTo="body" [items]="vendorList" bindLabel="name" bindValue="id"
                                placeholder="Select vendor" #vendor="ngModel"
                                [(ngModel)]="inventoryProductHistory.InventoryVendorId" name="vendor"
                                [required]="true">
                            </ng-select>
                        </pos-form-field>
                    </pos-column>
                    <pos-column>
                        <pos-form-field label="Vendor Sku">
                            <input posInput class="form-control" name="vendorSku" #vendorSku="ngModel"
                                [(ngModel)]="inventoryProductHistory.VendorSku">
                        </pos-form-field>
                    </pos-column>
                    <pos-column>
                        <pos-form-field *ngIf="!id" label="Date" [validationMessages]="{required: 'Please enter edit date.'}">
                            <p-calendar posInput [(ngModel)]="inventoryProductHistory.DateAdded" [showIcon]="true"
                                [dateFormat]="dateFormat" [readonlyInput]="true" name="dateEdited"
                                yearNavigator="true" [showTime]="true" [hourFormat]="hourFormat"
                                [inputStyle]="{'width':'unset','display':'unset'}"
                                inputStyleClass="form-control" yearRange="2000:2099" #dateEdited="ngModel"
                                [monthNavigator]=true [required]="true" [maxDate]="today">
                            </p-calendar>
                        </pos-form-field>
                    </pos-column>
                </pos-row>
            </div>
            <div class="clearfix"></div>
        </div>
        </context-provider>
        <div class="col-12 action-button-container page-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save </button>
            <button type="button" class="btn-secondary btn-lg btn btn-cancel pos-button"
                (click)="onCancel()">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
