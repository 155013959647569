import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalComponent } from 'src/app/shared';

@Component({
  selector: 'pos-kiosk-keyboard',
  templateUrl: './kiosk-keyboard.component.html',
  styleUrls: ['./kiosk-keyboard.component.scss']
})
export class KioskKeyboardComponent extends ModalComponent {

  @Input() value = null;
  @Input() title;
  keyboardOptions;

  constructor(bsModalRef: BsModalRef) {
    super(bsModalRef);
  }
  
  ngOnInit() {
    this.keyboardOptions = { isOpen: false, capsLock: false, title: this.title, isReadOnly: true };
  }

  submit(res) {
    this.hide({value: res});
  }

  cancel() {
    this.hide({});
  }

}
