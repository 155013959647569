<pos-edit-wrapper heading="Order(s) on Table: {{tableName}}" (close)="closeDialog()">
  <div class="col-12 pt-pix-10 pos-modal-body screen-scroll">
    <div class="row details-box" id="account_{{order.OrderId}}" (click)="selectOrder(order)"
      *ngFor="let order of orders" [ngClass]="{'pointer':isShowOccupiedTable}">
      <div class="col-6 p-0 pt-pix-4">Order: {{order.SurrogateOrderId}}</div>
      <!--SurrogateId -->
      <div class="col-6 p-0">
        <div class="float-end ps-pix-15"><button class="btn btn-primary btn-sm white normal pos-button"
            (click)="openOrder(order.OrderId)">Open</button></div>
        <div class="float-end pt-pix-4">Total: {{order.Amount | tark_currency}}</div><!--Amount -->
      </div>
    </div>
  </div>
  <div class="col-12 action-button-container popup-footer">
    <button *ngIf="!isShowOccupiedTable && !oneActiveOrderPerAccount" class="btn btn-primary btn-lg btn-save pos-button"
      type="button" (click)="selectTable()">Assign Current Order</button>
    <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" type="button"
      (click)="closeDialog()">Close</button>
  </div>
</pos-edit-wrapper>
