import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AlertsService, BaseCrudModelComponent, FormUtilityService, Messages, OrderService, SpinnerService } from 'src/app/shared';
import { OrderMethod } from 'src/app/configurator/order-method/interfaces/order-method';
import { PaymentType } from 'src/app/shared/interface/payment-type';
import { ServeMethod } from 'src/app/shared/interface/serve-method';
import { RevenueCenter } from '../../interface/revenue-center';
import { RevenueCenterService } from '../../service/revenue-center.service';
import { OrderMethodService } from 'src/app/configurator/order-method/services/order-method.service';

@Component({
  selector: 'pos-revenue-centers-edit',
  templateUrl: './revenue-center-edit.component.html',
})
export class RevenueCenterEditComponent extends BaseCrudModelComponent<RevenueCenter> implements OnInit {

  revenueCenterId: number;
  revenueCenterEditName: string;
  revenueCenter: RevenueCenter;
  get getForm(): NgForm {
    return this.revenueCenterForm
  }
  @ViewChild('revenueCenterForm') revenueCenterForm: NgForm;
  serveMethods: Array<ServeMethod> = [];
  orderMethods: Array<OrderMethod> = [];
  paymentModes: Array<PaymentType> = [];
  props = {
    labelClass: '',
    controlBoxClass: 'col-sm-9 col-xs-12'
  }
  constructor(protected revenueCenterService: RevenueCenterService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    private orderService: OrderService,
    private orderMethodService: OrderMethodService,
    formUtilityService: FormUtilityService
  ) {
    super(revenueCenterService, alertService, spinnerService, formUtilityService);
  }

  ngOnInit(): void {
    this.revenueCenter = this.revenueCenterService.newRevenueCenter();
    this.saveSuccessMessage = Messages.RevenueCenterSaveSuccess;
  }

  saveRevenueCenter(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.save(this.revenueCenter);
  }

  onSaveSuccess(model: RevenueCenter): void {
    this.hide({ shouldReload: true });
  }

  cancelEditing() {
    this.hide({ shouldReload: false });
  }

  loadDependencies(): void {
    const revenueCenterObservable: Array<Observable<any>> = [];
    this.spinnerService.show();
    revenueCenterObservable.push(this.orderService.getAllServeMethods());
    revenueCenterObservable.push(this.orderMethodService.getAll());
    revenueCenterObservable.push(this.orderService.getPaymentTypes());
    if (this.revenueCenterId) {
      revenueCenterObservable.push(this.revenueCenterService.get(this.revenueCenterId));
    }
    forkJoin(revenueCenterObservable)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: ([serveMethods, orderMethods, paymentModes, revenueCenter]:
          [Array<ServeMethod>, Array<OrderMethod>, Array<PaymentType>, RevenueCenter]) => {
          this.serveMethods = serveMethods;
          this.orderMethods = orderMethods;
          this.paymentModes = paymentModes;
          if (revenueCenter) {
            this.revenueCenter = revenueCenter;
          }
        },
        error: this.alertService.showApiError
      });
  }

}
