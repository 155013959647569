import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomainConstants } from 'src/app/shared/constants/domain-constants';
import { database, deleteWhite } from 'src/app/shared/components/icon/icons';

import { DashboardDataSourceService } from '../../service/dashboard-data-source.service';
import { DataSourceModel, DashboardDataSourceParameter, DashboardDataSource } from '../../interface';
import { AlertsService, FormUtilityService } from 'src/app/shared/services';
import { BaseCrudComponent } from 'src/app/shared/components/base-crud.component';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { cloneDeep, forEach } from 'lodash';
import { DashboardDataSourceParamterService } from '../../service/dashboard-data-source-parameter.service';
import { ConfirmDeleteModalComponent, Messages, ModalService } from 'src/app/shared';
import { finalize } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'pos-dashboard-data-source-edit',
  templateUrl: './dashboard-data-source-edit.component.html',
  styleUrls: ['./dashboard-data-source-edit.component.scss']
})
export class DashboardDataSourceEditComponent extends BaseCrudComponent<DataSourceModel> implements OnInit {
  id = 0;
  dataSourceName: string;
  dataSource: DataSourceModel;
  get getForm(): NgForm {
    return this.dataSourceForm
  }
  @ViewChild('dataSourceForm') dataSourceForm: NgForm;
  dataSourceParameter: Array<DashboardDataSourceParameter> = [];
  outputTypes = [...DomainConstants.OutputTypes];
  sourceTypes = [...DomainConstants.SourceTypes];
  dataTypes = [...DomainConstants.DashboardDataType];
  icons = {
    deleteWhite,
    database
  };
  parameterName = DomainConstants.DashboardDataSourceParameterName;
  props = {
    labelClass: '',
    controlBoxClass: 'col-lg-3 col-xs-5'
  }

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected dataSourceService: DashboardDataSourceService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected dashboardDataSourceParameterService: DashboardDataSourceParamterService,
    formUtilityService: FormUtilityService
  ) {
    super(dataSourceService, alertService, spinnerService, formUtilityService);
    this.dataSource = this.dataSourceService.newDataSource();
    this.id = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
  }

  ngOnInit(): void {
    if (this.id > 0) {
      this.loadDataSource();
    } else {
      this.dataSource.DataSourceParameter.push(this.dashboardDataSourceParameterService.newDataSourceParameter());
    }
  }

  loadDataSource() {
    const observable: Array<Observable<any>> = [];
    observable.push(this.loadData());
    observable.push(this.dashboardDataSourceParameterService.getDataSourceParameter(this.id));
    this.spinnerService.show();
    forkJoin(observable).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: ([dataSource, parameters]: [DataSourceModel, Array<DashboardDataSourceParameter>]) => {
        this.dataSource = dataSource;
        this.dataSourceName = dataSource.Name;
        this.dataSource.DataSourceParameter = [];
        this.setParameters(parameters);
        this.dataSource.DataSourceParameter.push(this.dashboardDataSourceParameterService.newDataSourceParameter());
      },
      error: this.alertService.showApiError
    });
  }

  setParameters(parameters: Array<DashboardDataSourceParameter>) {
    forEach(parameters, (data) => {
      if (data.ParameterName === this.parameterName.StoredProcedure) {
        this.dataSource.StoredProcedure = data.ParameterDefaultValue;
        this.dataSource.StoredProcedureId = data.Id;
      } else if (data.ParameterName === this.parameterName.Method) {
        this.dataSource.Method = data.ParameterDefaultValue;
        this.dataSource.MethodId = data.Id;
      } else if (data.ParameterName === this.parameterName.Url) {
        this.dataSource.Url = data.ParameterDefaultValue;
        this.dataSource.UrlId = data.Id;
      } else {
        this.dataSource.DataSourceParameter.push(data);
      }
    });
  }

  loadDependencies() {
    // no dependencies to load
  }

  createParameterRow(lastIndex: boolean) {
    if (!lastIndex && this.dataSource.DataSourceParameter.length > 1) {
      return;
    }
    const newParameter = this.dashboardDataSourceParameterService.newDataSourceParameter();
    this.dataSource.DataSourceParameter.push(newParameter);
  }

  deleteParameterRow(index: number) {
    if (this.dataSource.DataSourceParameter[index].Id) {

      this.confirmDeleteParameter(index);
    } else {
      this.dataSource.DataSourceParameter.splice(index, 1);
    }
  }

  private confirmDeleteParameter(index: number): void {
    const confirmDelete = this.modalService.show(ConfirmDeleteModalComponent, {
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      initialState: {
        message: Messages.ConfirmDeleteDashboardDataSourceParameter
      }
    });
    confirmDelete.close.subscribe(res => {
      if (res && res.shouldDelete) {
        this.spinnerService.show();
        this.dashboardDataSourceParameterService.delete(this.dataSource.DataSourceParameter[index].Id).pipe(
          finalize(() => {
            this.spinnerService.hide();
          })
        ).subscribe({
          next: (response) => {
            this.dataSource.DataSourceParameter.splice(index, 1);
            this.alertService.renderSuccessMessage(Messages.DashboardDataSourceParameterDeleted);
          }, error: this.alertService.showApiError
        });
      }
    });
  }

  submit(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.saveDataSource();
  }

  saveDataSource() {
    const dataSource = this.prepareDataSource();
    this.spinnerService.show();
    this.dataSourceService.saveDataSourceWithParameters(dataSource).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (response) => {
        this.onSaveSuccess();
      }, error: this.alertService.showApiError
    });
  }

  prepareDataSource() {
    const dataSource = cloneDeep(this.dataSource);
    dataSource.DataSourceParameter.splice(dataSource.DataSourceParameter.length - 1, 1);
    if (this.dataSource.StoredProcedure) {
      const storedProcedure = this.dashboardDataSourceParameterService.setDataSourceParameter(dataSource.StoredProcedureId, this.id,
        this.parameterName.StoredProcedure, dataSource.StoredProcedure);
      dataSource.DataSourceParameter.push(storedProcedure);
    }
    const Method = this.dashboardDataSourceParameterService.setDataSourceParameter(dataSource.MethodId, this.id,
      this.parameterName.Method, DomainConstants.ApiMethods.POST);
    dataSource.DataSourceParameter.push(Method);

    const Url = this.dashboardDataSourceParameterService.setDataSourceParameter(dataSource.UrlId, this.id, this.parameterName.Url,
      dataSource.OutputType === 'List' ? DomainConstants.DashboardDataSourceOutputType.TabularValue :
        DomainConstants.DashboardDataSourceOutputType.ScalarValue);
    dataSource.DataSourceParameter.push(Url);
    return dataSource;
  }

  onSaveSuccess() {
    this.alertService.renderSuccessMessage(Messages.DashboardDataSourceSaveSuccess);
    this.onCancel();
  }

  onCancel() {
    this.router.navigate(['/manage/system-configuration/data-source']);
  }

}
