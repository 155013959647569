import { Directive } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IDeactivateGuard } from '../guards/deactivate.guard';
import { FormUtilityService } from '../services/form-utility.service';
import { BaseComponent } from './base.component';

@Directive()
export abstract class BaseFormComponent extends BaseComponent implements IDeactivateGuard {
    abstract get getForm(): NgForm;
    protected initialValues;
    formTimeout;
    formValueChangeSubscription;
    isSubmitted: boolean = null;
    eventSubscription: Subscription;
    isDetectUnsavedChanges = true;
    constructor(protected formUtilityService: FormUtilityService) {
        super();
        this.formTimeout = setTimeout(() => {
            this.setInitialValue();
            this.setSubmitted();
        });

    }

    setInitialValue(): void {
        if (this.getForm) {
            if (this.isSubmitted == null) {
                this.setSubmitted();
            }
            const form = this.getForm;
            if (!this.initialValues) {
                this.initialValues = form.value;
            }
            this.formValueChangeSubscription = form.valueChanges.subscribe({
                next: () => {
                    if (!form.dirty) {
                        this.initialValues = form.value;
                    } else {
                        this.unsubscribeFormValueChangeSubscription();
                    }
                }
            });
            this.clearFormTimeout();
        }

    }

    setSubmitted() {
        if (this.getForm) {
            this.isSubmitted = false;
            this.getForm.ngSubmit.subscribe(() => {
                if (!this.getForm.invalid) {
                    this.isSubmitted = true;
                }
            });
        }
    }

    clearFormTimeout() {
        if (this.formTimeout) {
            clearTimeout(this.formTimeout);
        }
    }

    unsubscribeFormValueChangeSubscription() {
        if (this.formValueChangeSubscription) {
            this.formValueChangeSubscription.unsubscribe();
        }
    }

    canDeactivate(): boolean {
        if (this.isDetectUnsavedChanges && this.hasUnsavedChanges() && !(this.getForm.submitted && !this.getForm.invalid)) {
            return false;
        }
        return true;
    }
    hasUnsavedChanges() {
        return this.formUtilityService.hasUnsavedChanges(this.getForm, this.initialValues);
    }


}
