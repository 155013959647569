import { Injectable } from '@angular/core';
import { KioskScreenImage, KioskScreen } from '../../order-kiosk/interfaces/kiosk-screen';
import { HttpClient } from '@angular/common/http';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';
import { SlideShowDetails } from 'src/app/configurator/digital-signage';
import { Observable } from 'rxjs';
import { RuntimeConstants } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class OrderKioskScreenService extends BaseCrudService<KioskScreen> {

  constructor(private httpClient: HttpClient) {
    super('kiosk-screen', httpClient);
  }

  newScreen() {
    return {
      Id: 0,
      Name: null,
      TemplateType: null,
      DefaultNextScreenId: null,
      KioskScreenChoices: [],
      KioskScreenConfigs: [],
      IsFilteredProductsByTags: false
    } as KioskScreen;
  }

  saveScreenDetails(kioskScreen: KioskScreen, images: Array<KioskScreenImage> = null) {
    const formData = new FormData();
    if (images?.length) {
      images.forEach(image => {
        formData.append('images', image.ImageFile, image.ImageName);
      });
    }
    formData.append('kioskScreen', JSON.stringify(kioskScreen));
    return this.httpClient.post(`${this.apiEndpoint}/${kioskScreen.Id}/details`, formData);
  }

  getAllScreenWithNavigation() {
    return this.httpClient.get(`${this.apiEndpoint}/navigation`);
  }

  getTerminalSlideshowDetails(terminalId: number): Observable<Array<SlideShowDetails>> {
    return this.httpService.get<Array<SlideShowDetails>>(
      `${RuntimeConstants.API_PATH}slideshows/` + terminalId + '/slideshowdetails'
    );
  }

  getSalesProductDietaryWarnings() {
    return this.httpClient.get(`${this.apiEndpoint}/dietary-warnings`);
  }
}
