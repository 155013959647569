import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountCategory } from '../../interface/account-category';
import { NgForm } from '@angular/forms';
import { BaseCrudComponent } from 'src/app/shared/components/base-crud.component';
import { AccountCategoryService } from 'src/app/shared/services/account-category.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { Messages } from 'src/app/shared/constants';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { accountCategoriesClipboardList } from 'src/app/shared/components/icon/icons';
import { FormUtilityService } from 'src/app/shared/services/form-utility.service';

@Component({
    selector: 'pos-edit-account-category',
    templateUrl: './edit-account-category.component.html',
})
export class EditAccountCategoryComponent extends BaseCrudComponent<AccountCategory> implements OnInit {

    accountCategory: AccountCategory;
    @ViewChild('accountCategoryForm', { static: true }) accountCategoryForm: NgForm;
    get getForm(): NgForm {
        return this.accountCategoryForm
    }
    icons = { accountCategoriesClipboardList }

    constructor(
        protected alertService: AlertsService,
        protected spinnerService: SpinnerService,
        protected accountCategoryService: AccountCategoryService,
        private router: Router,
        private route: ActivatedRoute,
        formUtilityService: FormUtilityService
    ) {
        super(accountCategoryService, alertService, spinnerService, formUtilityService);
        this.id = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
    }

    ngOnInit() {
        this.accountCategory = this.accountCategoryService.newAccountCategory();
        this.saveSuccessMessage = Messages.AccountCategorySaveSuccess;
        this.dataSubscription();
        this.scrollToTop();
        this.setInitialValue();
    }
    private dataSubscription(): void {
        if (this.id > 0) {
            this.spinnerService.show();
            this.loadData().pipe(finalize(() => {
                this.spinnerService.hide();
            }))
                .subscribe({
                    next: (res) => {
                        this.accountCategory = res;
                    }, error: this.alertService.showApiError
                });
        }
    }
    public loadDependencies() {
    }
    public submit(isValid: boolean) {
        if (!isValid) {
            return;
        }
        this.save(this.accountCategory);
    }
    public onSaveSuccess(model: AccountCategory): void {
        this.cancelEditing();
    }
    public cancelEditing(): void {
        this.router.navigate(['account-categories'], { relativeTo: this.route.parent });
    }
    private scrollToTop(): void {
        window.scrollTo(0, 0);
    }
}
