import { Component, Input, OnInit } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { OutOfStockBehavior } from '../../interfaces/out-of-stock-behaviour';

@Component({
  selector: 'pos-menu-image',
  templateUrl: './menu-image.component.html',
  styleUrls: ['./menu-image.component.scss']
})
export class MenuImageComponent implements OnInit {

  @Input() widgetId: any;
  @Input() image;
  @Input() height;
  @Input() width;
  @Input() imagePosition = 'center';
  @Input() text;
  @Input() imagePath = RuntimeConstants.IMAGE_BASE_PATH;
  @Input() textPosition;
  @Input() textStyle;
  @Input() showImageText = true;
  @Input() interactiveStyleClass: string;
  @Input() outOfStock: OutOfStockBehavior;
  @Input() verticalAlign = {};

  // image shadow properties
  @Input() offsetX = '';
  @Input() offsetY = '';
  @Input() blurRadius = '';
  @Input() shadowColor = '';

  widgetData: any = 'jones-radio.png';
  terminalName = '';

  constructor() { }

  ngOnInit(): void {
    this.textPosition = this.getImageStyle();
  }

  getImageStyle() {
    return {
      'flex-direction': this.textPosition?.toLowerCase() === 'top' ? 'column' : 'column-reverse',
      ...this.verticalAlign
    };
  }

}
