import { Component, EventEmitter } from '@angular/core';
import { OrderInvoice } from '../../orders/interface';
import { ApplicationStateService, checkCircleSolid, DomainConstants, exclamationCircle, ICloseable, RabbitMQService, SettingParam } from '../../shared';
import * as $ from 'jquery';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pos-user-review-order',
  templateUrl: './user-review-order.component.html',
  styleUrls: ['./user-review-order.component.scss']
})
export class UserReviewOrderComponent implements ICloseable {
  
  close: EventEmitter<any> = new EventEmitter();
  orderItemTypes = DomainConstants.OrderItemTypes;
  orderData: OrderInvoice;
  confirmOrderHeight: number;
  seats: any[] = [];
  icons = { exclamationCircle, checkCircleSolid };
  settingParam: SettingParam;
  
  closeReviewModalSubscription: Subscription;

  constructor(private rabbitMQService: RabbitMQService,
    private applicationStateService: ApplicationStateService,
  ) {}

  ngOnInit() {
    this.settingParam = this.applicationStateService.settingParam;
    this.confirmOrderHeight = $(window).height() - 240;
    $(window).resize(() => this.confirmOrderHeight = $(window).height() - 240);
    this.closeReviewModalSubscription = this.rabbitMQService.subscribeToCloseReviewOrderModalMessage$(this.settingParam.OrderEntryTerminalId)
    .subscribe({
      next: (message: any) => {
        this.closeModal(message.Payload.IsConfirmed, false);
      },
      error: () => console.log('error while connecting to RabbitMQ.')
    });
  }

  closeModal(isConfirmed = false, sendRabbitMqMessage = true) {
    this.closeReviewModalSubscription?.unsubscribe();
    this.close.emit({ isConfirmed: isConfirmed, sendRabbitMqMessage: sendRabbitMqMessage });
  }
}
