<ng-table #gridComponent [sort]="false" [paginator]="false" [search]="false" [scrollable]="true" [scrollHeight]="screenHeight +'px'" [columns]="inventoryInfoColumns" [data]="inventoryInfo">
</ng-table>

<ng-template let-data="rowData" #supplyRemainingTemplate>
  <span>
    {{data.SupplyRemaining | tark_number }}
  </span>
</ng-template>

<ng-template let-data="rowData" #onHandTemplate>
  <span>
    {{data.OnHand | tark_number }}
  </span>
</ng-template>

<ng-template let-data="rowData" #lastReceivedAmountTemplate>
  <span>
    {{data.LastReceivedAmount | tark_number }}
  </span>
</ng-template>
