<pos-edit-wrapper heading="End of Day" (close)="closeEndOfDay()">
    <div class="pos-modal-body pt-pix-10">
        <div *ngIf="isEndOfDayAlreadyTriggered">
            The day is already closed at {{time | tark_time }}.
        </div>
        <div *ngIf="!isEndOfDayAlreadyTriggered">
            <div class="alert alert-warning" *ngIf="isShowWarning">{{ endOfDayScheduledDailyCloseMessage }}</div>
            <p-steps [model]="items" [(activeIndex)]="activeIndex"></p-steps>
            <div class="pt-pix-20">
                <span [innerHtml]="messages[activeIndex]" class="unordered-list"></span>
            </div>
        </div>
    </div>
    <div class="col-12 action-button-container popup-footer">
        <button class="btn btn-primary btn-lg btn-save pos-button" (click)="next()" [disabled]="activeIndex < 2"
            *ngIf="!isEndOfDayAlreadyTriggered">{{ activeIndex < 3 ? 'Next' : 'Submit' }}</button>
                <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="closeEndOfDay()">{{
                    !isEndOfDayAlreadyTriggered ?
                    'Cancel' : 'Close'}}</button>
    </div>
</pos-edit-wrapper>
