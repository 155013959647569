import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { TerminalDashboardProperty } from '../../configurator/dashboard-terminal-property/interface/dashboard-terminal-property';

@Injectable()
export class DashboardTerminalPropertiesService {

  terminalDashboardPropertiesApiPath = `${RuntimeConstants.API_PATH}terminal-dashboard-property`;

  constructor(private httpService: HttpClient) { }

  getTerminalDashboardProperties(terminalId: number) {
    return this.httpService.get(`${this.terminalDashboardPropertiesApiPath}/${terminalId}`);
  }

  saveTerminalDashboardProperty(terminalDashboardProperty: TerminalDashboardProperty): Observable<TerminalDashboardProperty> {
    return this.httpService.post<TerminalDashboardProperty>(
      `${this.terminalDashboardPropertiesApiPath}`,
      terminalDashboardProperty
    );
  }

  deleteTerminalDashboardProperty(propertyId: number): Observable<TerminalDashboardProperty> {
    return this.httpService.delete<TerminalDashboardProperty>(
      `${this.terminalDashboardPropertiesApiPath}/${propertyId}`
    );
  }

  updatePropertyOrdinal(terminalDashboardProperties: Array<TerminalDashboardProperty>): Observable<TerminalDashboardProperty> {
    return this.httpService.put<TerminalDashboardProperty>(
      `${this.terminalDashboardPropertiesApiPath}/update-ordinal`,
      terminalDashboardProperties
    );
  }
}
