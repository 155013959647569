import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { SalesProductsService } from 'src/app/information-management/sales-products/services/sales-products.service';

import { finalize } from 'rxjs/operators';
import { ProductInventoryConsumption } from '../../interface/inventory-product-consumption';
import { SalesSize } from 'src/app/information-management/sales-sizes/interface/sales-size';
import { FormUtilityService, Messages, ModalFormComponent, ModalService } from 'src/app/shared';
import { forkJoin } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { InventoryProductService } from 'src/app/shared/services/inventory-product.service';
import { NgForm } from '@angular/forms';
declare let $: any;

@Component({
    selector: 'pos-recipe-editor',
    templateUrl: './recipe-editor.component.html',
    styleUrls: ['./recipe-editor.component.scss']
})
export class RecipeEditorComponent extends ModalFormComponent implements OnInit {
    get getForm(): NgForm {
        return this.recipeEditorForm;
    }

    @Input('inventoryProductId') inventoryProductId: number;
    @Input('productName') inventoryProductName: number;
    @ViewChild('recipeEditorForm') recipeEditorForm: NgForm;

    // @Output() closePopup = new EventEmitter();
    // @Output() saveRecipeEditor = new EventEmitter();

    screenHeight = '300px';
    productInventoryConsumptions: Array<ProductInventoryConsumption> = [];
    salesSize: Array<SalesSize> = [];

    constructor(
        modalRef: BsModalRef,
        private alertService: AlertsService,
        private spinnerService: SpinnerService,
        private salesProductsService: SalesProductsService,
        private inventoryProductService: InventoryProductService,
        modalService: ModalService,
        formUtilityService: FormUtilityService
    ) {
        super(modalRef, modalService, formUtilityService);
    }

    ngOnInit() {
        this.loadData();
        if ($(window).height() <= 700) {
            this.screenHeight = ($(window).height() - 460) < 150 ? '105px' : ($(window).height() - 330) + 'px';
        } else {
            this.screenHeight = ($(window).height() - 460) < 150 ? '105px' : ($(window).height() - 450) + 'px';
        }
    }

    private loadData() {
        const recipeEditorObservables = [];
        recipeEditorObservables.push(this.salesProductsService.getProductsInventoryForConsumption(this.inventoryProductId));
        recipeEditorObservables.push(this.salesProductsService.getSalesSizes(0));
        this.spinnerService.show();
        forkJoin(recipeEditorObservables)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (response: any) => {
                    this.productInventoryConsumptions = response ? response[0] : [];
                    this.salesSize = response ? response[1] : [];

                    this.productInventoryConsumptions.forEach((productInventoryConsumption: ProductInventoryConsumption) => {
                        const size = this.salesSize.find(s => s.Id === productInventoryConsumption.SizeId);
                        if (size) {
                            productInventoryConsumption.Size = size.Name;
                        }
                    });

                }, error: this.alertService.showApiError
            });
    }

    public editRecipes() {
        const validateInventoryProduct = this.productInventoryConsumptions.filter(i => !i.qty);
        if (validateInventoryProduct && validateInventoryProduct.length > 0) {
            this.alertService.renderErrorMessage(Messages.InvalidRecipeQty);
            return;
        }
        this.spinnerService.show();
        this.inventoryProductService.UpdateInventoryProductConsumptionDetails(this.inventoryProductId, this.productInventoryConsumptions)
            .pipe(finalize(() => {
                this.spinnerService.hide();
                this.hide({});
            }))
            .subscribe({
                next: () => {
                    this.alertService.renderSuccessMessage(Messages.RecipesSavedSucess);
                    this.hide({});

                }, error: this.alertService.showApiError
            });
    }

    public cancelRecipeEditor() {
        this.hide({});
    }
}
