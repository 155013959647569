<div class="page-content row">
    <div class="col-12">
        <form #barcodeForm="ngForm" class="edit-form" (ngSubmit)="save(barcodeForm.valid)" autocomplete="off">
            <div class="row pb-pix-1 mb-pix-2">
                <div class="col-xl-3 col-md-4 col-12">
                    <pos-form-field label="Barcode" inputStyle="col-12"
                        [validationMessages]="{required: 'Please enter barcode.'}">
                        <input posInput class="form-control" maxlength="30" name="barcode" [required]="true"
                            #barcodeName="ngModel" id="barcodeName" [(ngModel)]="barcode.Barcode" type="text" (blur)="barcodeEntered(barcode.Barcode)" />
                    </pos-form-field>
                </div>

                <div class="col-xl-2 col-md-3 col-12">
                    <pos-form-field label="Size" inputStyle="col-12"
                        [validationMessages]="{required: 'Please select size.'}">
                        <ng-select posInput [items]="salesProductSizes" bindLabel="Name" bindValue="SizeId" name="salesSize"
                            placeholder="Select size" [(ngModel)]="barcode.SalesSizeId" appendTo="body"
                            [required]="true"></ng-select>
                    </pos-form-field>
                </div>

                <div class="col-xl-2 col-md-2 col-12">
                    <pos-form-field label="Qty" inputStyle="col-12">
                        <input posInput class="form-control" name="textQty" [(ngModel)]="barcode.Qty" #qty="ngModel"
                            type="number" posDecimalRange />
                    </pos-form-field>
                </div>

                <div class="col-xl-2 col-md-3 col-12">
                    <div class="col-12 pt-pix-10 pb-pix-7">
                        <label class=""></label>
                    </div>
                    <div class="col-12 no-wrap pe-0 text-right">
                        <button class="btn btn-primary white normal pos-button" type="submit">
                            {{barcode.Id ? 'Update' : 'Add'}}
                        </button>
                        <button class="btn btn-secondary normal ms-pix-4 pos-button" (click)="cancel()" type="button">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>

        </form>
        <div class="col-12">
            <ng-table id="barcode" [sort]="true" [columns]="barcodeColumns" [data]="barcodes"
                [exportFilename]="'sales-product-barcodes'" [paginator]="false">
            </ng-table>
        </div>
    </div>
</div>
<pos-print-table [hidden]="true"></pos-print-table>
<pos-scan-barcode (barcodeScanned)="scanBarcode($event)"></pos-scan-barcode>
