import { Component, OnInit, Input, ViewChild, TemplateRef, Output, EventEmitter } from '@angular/core';
import {
  plus, squareO, square, barsWhite, deleteWhite, editWhite, exchangeAltAction, ordinalBars,
  arrowsAlt, column, salesProductRecipe
} from 'src/app/shared/components/icon';
import { SalesProductsService } from '../../services';
import { InventoryProductService } from '../../../../shared/services/inventory-product.service';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SalesSize } from 'src/app/information-management/sales-sizes';
import { GridColumn, DateColumn, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import * as _ from 'lodash';
import { SalesProductRecipeEditComponent } from '../sales-product-recipe-edit';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { chain, cloneDeep } from 'lodash';
declare let $: any;
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { Format } from '@tarktech/tark-ng-utils/table/format-type';
import { SalesProductRecipeSwapComponent } from '../sales-product-recipe-swap/sales-product-recipe-swap.component';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SalesSizeService } from 'src/app/shared/services/sales-size.service';
import { ModalComponent } from 'src/app/shared/components/modal/modal-component';
import { ConfirmDeleteModalComponent } from 'src/app/shared/components/confirm-delete-modal/confirm-delete-modal.component';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { CopyProductRecipeModalComponent } from '../copy-product-recipe-modal/copy-product-recipe-modal.component';
import { SalesInventoryProductService } from '../../services/sales-inventory-product.service';

@Component({
  selector: 'pos-sales-product-recipe-list',
  templateUrl: './sales-product-recipe-list.component.html',
  styleUrls: ['./sales-product-recipe-list.component.scss']
})
export class SalesProductRecipeListComponent extends ModalComponent implements OnInit {

  @Input('productId') productId: number = null;
  @Input('productName') productName: string = '';
  @Input('isPopup') isPopup: boolean = false;
  @Input('isDefaultQtyFraction') isDefaultQtyFraction: boolean = false;
  @Output('changeDirectLinkState') changeDirectLinkState = new EventEmitter<any>();
  @Output('recipeUpdated') recipeUpdated = new EventEmitter<any>();
   recipeDetails: any;
  currentSizeName: string = '';
  selectedSizeId: number = null;
  isDirectLinkToInventoryProduct: boolean = false;
  linkedProductId: number = null;
  ingredientId: number = null;
  ingredientList: Array<any> = [];
  isAtLeastOnePrice: boolean = true;
  allSalesSizes: Array<SalesSize> = [];
  salesSizes: Array<SalesSize> = [];
  isShowHistory: boolean = false;
  productIngredientConsumption: Array<any> = [];
  productBaseIngredientConsumption: any;
  selectedDefaultProduct: number;
  selectedProduct: any = null;
  activeSalesProduct: Array<any> = [];
  copyToProduct: boolean = true;
  consumptionColumn: Array<GridColumn> = [];
  inFlightRequest: number = 0;
  tempShowRecipe: boolean = false;
  recipeId: number = null;
  inventoryProductId: number = null;
  popup: any;
  icons = {
    plus,
    squareO,
    square,
    barsWhite,
    deleteWhite,
    editWhite,
    exchangeAltAction,
    ordinalBars,
    arrowsAlt,
    column,
    salesProductRecipe
  };
  groupCategory: string = null;
  @ViewChild('productTemplate', { static: true }) productTemplate: TemplateRef<any>;
  @ViewChild('instructionTemplate', { static: true }) instructionTemplate: TemplateRef<any>;
  @ViewChild('operationTemplate', { static: true }) operationTemplate: TemplateRef<any>;
  @ViewChild('customColorTemplate', { static: true }) private customColorTemplate: any;

  constructor(
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private salesProductService: SalesProductsService,
    private inventoryProductService: InventoryProductService,
    private modalService: ModalService,
    private salesSizesService: SalesSizeService,
    public modalRef: BsModalRef,
    private salesInventoryProductService: SalesInventoryProductService
  ) {
    super(modalRef);
  }

  ngOnInit() {
    this.groupCategory = 'RecipeName';
    this.configureColumn();
    this.getRecipeDetails();
    this.selectedDefaultProduct = this.productId;
  }

  getRecipeDetails() {
    if (this.productId) {
      this.spinnerService.show();
      const recipeDetailObservables = [];
      recipeDetailObservables.push(this.inventoryProductService.getActiveInventoryProducts(true));
      recipeDetailObservables.push(this.salesSizesService.getSalesProductSizes(0));
      recipeDetailObservables.push(this.salesProductService.getProductSizesWithPrice(this.productId));
      forkJoin(recipeDetailObservables)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (responses: any) => {
            if (responses) {
              this.ingredientList = responses[0] ? responses[0] : [];
              this.allSalesSizes = responses[1] ? responses[1] : [];
              if (responses[2]) {
                this.salesSizes = responses[2];
                this.selectedSizeId = this.selectedSizeId ? this.selectedSizeId :
                  (this.salesSizes && this.salesSizes.length ? this.salesSizes[0].SizeId : 0);
                this.selectedSizeId ? this.selectProductSizeForAddingIngredient() : this.isAtLeastOnePrice = false;
              }
              if (this.selectedSizeId) {
                this.setDirectLinkToProduct();
              }
              this.setCurrentSizeName();
            }
          }, error: this.alertService.showApiError
        });
    }
  }

  filterProductBaseIngredients = () => {
    this.productBaseIngredientConsumption =
      chain(this.productIngredientConsumption)
        .groupBy("SizeId")
        .map((value, key) => ({ SizeId: Number(key && !isNaN(Number(key)) ? key : 0), Recipes: value }))
        .orderBy("SizeId", "desc")
        .value();
  }

  openSalesProductPopover(pop: any, data) {
    this.recipeDetails = data;
    this.popup = pop;
    $('popover-container').popover({ container: 'body' });
  }


  productIngredientsGridRowReorder(event, recipes) {
    this.inFlightRequest++;
    this.salesProductService.changeSalesInventoryProductOrdinal(this.productId, this.selectedSizeId, recipes)
      .pipe(finalize(() => {
      }))
      .subscribe({
        next: res => {
          recipes = res ? res : recipes;
        }, error: this.alertService.showApiError
      });
  }

  selectProductSizeForAddingIngredient() {
    this.isAtLeastOnePrice = true;
    this.productIngredientConsumption = [];
    this.productBaseIngredientConsumption = [];
    this.getConsumptionRecord(true, true);
    this.setCurrentSizeName();
  }

  setCurrentSizeName() {
    this.currentSizeName = _.find(this.salesSizes, (size) => {
      return size.SizeId == this.selectedSizeId;
    })?.Name;
  }

  getConsumptionRecord(isHideSpinner, isReloadDirectLink = false) {
    if (!isHideSpinner) {
      this.spinnerService.show();
    }
    let consumption = {
      InventoryProductId: 0,
      SizeId: this.selectedSizeId,
      IsHistory: this.isShowHistory,
      SalesProductId: this.selectedDefaultProduct ? this.productId : -1
    };
    this.salesProductService.getProductsInventoryConsumption(consumption)
      .pipe(finalize(() => {
        if (!isHideSpinner) {
          this.spinnerService.hide();
        }
      }))
      .subscribe({
        next: res => {
          if (res) {
            this.productIngredientConsumption = res;
            this.filterProductBaseIngredients();
            this.recipeId = res.length > 0 ? res[0].ID : null;
            if (isReloadDirectLink && this.selectedSizeId) {
              this.setDirectLinkToProduct();
            }
            this.recipeUpdated?.emit();
          }
        }, error: this.alertService.showApiError
      });
  }

  setDirectLinkToProduct() {
    this.isDirectLinkToInventoryProduct = false;
    this.ingredientId = null;
    this.linkedProductId = null;
    const currentSize = _.find(this.salesSizes, (size) => {
      return size.SizeId == this.selectedSizeId;
    });
    if (currentSize && currentSize.IsDirectLinkToInventoryProduct) {
      this.isDirectLinkToInventoryProduct = true;
      this.setSelectedLinkedProduct();
    }
  }

  setSelectedLinkedProduct() {
    const activeProduct = _.filter(this.productIngredientConsumption, (ingredient) => {
      return !ingredient.DateExpired || ingredient.DateExpired > new Date();
    });
    if (activeProduct && activeProduct.length === 1 && !activeProduct[0].DateExpired && activeProduct[0].qty === 1) {
      this.linkedProductId = activeProduct[0].inventory_product_id;
      this.inventoryProductId = this.linkedProductId;
    }
  }


  onChangeDirectLinkState() {
    const activeProduct = _.filter(this.productIngredientConsumption, (ingredient) => {
      return !ingredient.DateExpired || ingredient.DateExpired > new Date();
    });
    if (this.isDirectLinkToInventoryProduct && (activeProduct.length > 1 || (activeProduct.length === 1 && activeProduct[0].qty !== 1))) {
      const deleteAreaModalRef = this.modalService.show(ConfirmDeleteModalComponent, {
        animated: false,
        keyboard: false,
        class: 'vertical-center',
        initialState: {
          message: Messages.ConfirmDeleteAllRecipeFromSalesProduct,
        }
      });
      deleteAreaModalRef.close.subscribe(res => {
        if (res && res.shouldDelete) {
          this.tempShowRecipe = false;
          this.isDirectLinkToInventoryProduct = true;
          this.updateIsDirectLinkProductStatus();
        } else {
          this.tempShowRecipe = false;
        }
      });
      this.tempShowRecipe = true;
      setTimeout(() => {
        this.isDirectLinkToInventoryProduct = false;
      });
    } else {
      this.updateIsDirectLinkProductStatus();
      if (this.productIngredientConsumption && this.productIngredientConsumption.length === 1) {
        this.setSelectedLinkedProduct();
      }
    }
  }

  updateIsDirectLinkProductStatus() {
    this.spinnerService.show();
    this.salesProductService.updateDirectLinkProductStatus(this.productId, this.selectedSizeId, this.isDirectLinkToInventoryProduct)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          this.linkedProductId = null;
          this.ingredientId = null;
          if (this.isDirectLinkToInventoryProduct) {
            this.setSelectedLinkedProduct();
          } else {
            this.productIngredientConsumption = [];
            this.productBaseIngredientConsumption = [];
          }
          this.getProductSize(this.productId);
          if (!this.isPopup) {
            this.changeDirectLinkState.emit({ Id: this.selectedSizeId, IsDirectLink: this.isDirectLinkToInventoryProduct });
          }
        }, error: this.alertService.showApiError
      });
  }

  getProductSize(productId) {
    this.salesProductService.getProductSizesWithPrice(productId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          if (res) {
            this.salesSizes = res;
            this.selectedSizeId = this.selectedSizeId ? this.selectedSizeId :
              (this.salesSizes && this.salesSizes.length ? this.salesSizes[0].SizeId : 0);
            this.selectedSizeId ? this.selectProductSizeForAddingIngredient() : this.isAtLeastOnePrice = false;
            this.setCurrentSizeName();

          }
        }, error: this.alertService.showApiError
      });
  }

  inventoryProductDirectLink() {
    const consumption = {
      SizeId: this.selectedSizeId,
      InventoryProductId: this.linkedProductId ? this.linkedProductId : null,
      SalesProductId: this.selectedDefaultProduct > 0 ? this.productId : -1
    };

    if (this.linkedProductId && this.linkedProductId > 0) {
      this.spinnerService.show();
      this.salesProductService.directLinkInventoryProduct(consumption)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: res => {
            if (res) {
              this.inventoryProductId = this.linkedProductId;
              this.getConsumptionRecord(true);
            }
          }, error: this.alertService.showApiError
        });
    }
  }

  deleteRecipe(data) {
    this.spinnerService.show();
    this.salesProductService.removeProductIngredient(this.productId, 0, data.ID)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {

          this.productIngredientConsumption = [];
          this.productBaseIngredientConsumption = [];
          this.getConsumptionRecord(false);

        }, error: this.alertService.showApiError
      });
  }

  deleteInventoryProduct() {
    const modal = this.modalService.getModalWrapper(ConfirmDeleteModalComponent);
    const modalRef = modal.show({
      animated: false,
      keyboard: false,
      class: 'vertical-center',
      initialState: {
        message: Messages.ConfirmDeleteRecipeFromSalesProduct,
      }
    });
    modalRef.close.subscribe(res => {
      if (res && res.shouldDelete) {
        this.spinnerService.show();
        this.salesProductService.removeProductIngredient(this.productId, 0, this.recipeId)
          .pipe(finalize(() => {
            this.spinnerService.hide();
          }))
          .subscribe({
            next: () => {
              this.productIngredientConsumption = [];
              this.productBaseIngredientConsumption = [];
              this.getConsumptionRecord(false);
            }, error: this.alertService.showApiError
          });
      } else {
        this.linkedProductId = this.inventoryProductId;
      }
    });
  }

  showHistoricalIngredientsData() {
    this.getConsumptionRecord(false);
  }

  addIngredientToProduct(isBaseRecipe = false) {    
    if (this.ingredientId) {
      const self = this;
      const selectedProduct = this.ingredientList.find(x => x.Id == this.ingredientId);
      const availableProduct = _.find(this.productIngredientConsumption, (product) => {
        return product.inventory_product_id === self.ingredientId;
      });
      if (availableProduct) {
        this.alertService.renderErrorMessage(Messages.DuplicateProductError);
        return;
      }
      let recipe =  this.salesInventoryProductService.newSalesInventoryProduct();
      recipe.SalesProductId = this.productId;
      recipe.InventoryProductId = this.ingredientId;
      recipe.SizeId = isBaseRecipe ? null: this.selectedSizeId;
      recipe.Product = selectedProduct?.Name??'';
      recipe.Unit = selectedProduct.UnitName;
      recipe.UnitQty = selectedProduct.UnitQty;
      this.editRecipe(recipe);
    }
  }

  openCopyModal() {
    const selectedProduct = this.selectedProduct;
    const modal = this.modalService.getModalWrapper(CopyProductRecipeModalComponent)
      .show({
        'backdrop': 'static',
        'class': 'vertical-center',
        'keyboard': false,
        initialState: {
          activeSalesProduct: this.activeSalesProduct,
          selectedProduct: selectedProduct
        }
      });
    modal.close.subscribe(res => {
      if (res && res.shouldReload) {
        this.selectedProduct = res.Value.selectedProduct;
        this.selectActiveSalesProduct();
      } else {
        this.closeCopyModal();
      }
    });
  }

  closeCopyModal() {
    this.selectedProduct = null;
  }

  copyProductToIngredient() {
    this.copyToProduct = true;
    this.getActiveSalesProductWithSizes();
  }

  copyIngredientFromProduct() {
    this.copyToProduct = false;
    this.getActiveSalesProductWithSizes();
  }

  getActiveSalesProductWithSizes() {
    this.spinnerService.show();
    this.salesProductService.getActiveSalesProductWithSizes()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          if (res) {
            this.getActiveSalesProductWithSizesCompleted(res);
          }
        }, error: this.alertService.showApiError
      });
  }

  selectActiveSalesProduct() {
    let confirmationMessage = '';
    if (this.selectedProduct && !this.selectedProduct.idSize) {
      this.selectedProduct.idSize = this.selectedProduct.id + '-' + this.selectedProduct.Size;
    }
    if (this.selectedProduct && this.selectedProduct.idSize) {
      const selectedProduct = _.filter(this.activeSalesProduct, (product) => {
        return (product.id === parseInt(this.selectedProduct.idSize.split('-')[0], null) &&
          product.Size === this.selectedProduct.idSize.split('-')[1]);
      });
      if (selectedProduct && selectedProduct.length > 0) {
        if (this.copyToProduct) {
          confirmationMessage = StringUtils.format(Messages.ConfirmCopyToProduct, {
            'FromProduct': this.productName, 'TargetProduct': selectedProduct[0].Name
          });
        } else {
          confirmationMessage = StringUtils.format(Messages.ConfirmCopyToProduct, {
            'FromProduct': selectedProduct[0].Name, 'TargetProduct': this.productName
          });
        }

        if (this.selectedProduct.idSize) {
          const currentSize = _.find(this.allSalesSizes, (size) => {
            return size.Name === this.selectedProduct.idSize.split('-')[1];
          });
          let consumption = {
            InventoryProductId: this.copyToProduct ? this.productId : this.selectedProduct.idSize.split('-')[0],
            SalesProductId: this.copyToProduct ? this.selectedProduct.idSize.split('-')[0] : this.productId,
            SizeId: this.copyToProduct ? this.selectedSizeId : currentSize ? currentSize.Id : null,
            DestinationSizeId: !this.copyToProduct ? this.selectedSizeId : currentSize ? currentSize.Id : null,
          };
          this.spinnerService.show();
          this.salesProductService.copyProductToIngredient(this.selectedDefaultProduct, consumption.SizeId, 0, 0, consumption)
            .pipe(finalize(() => {
              this.spinnerService.hide();
            }))
            .subscribe({
              next: res => {
                if (res) {
                  if (this.copyToProduct) {
                    this.alertService.renderSuccessMessage(Messages.CopiedRecipeSaveSussess);
                    this.closeCopyModal();
                    this.getRecipeDetails();
                  } else {
                    this.alertService.renderSuccessMessage(Messages.CopiedRecipeSaveSussess);
                    this.closeCopyModal();
                    this.getRecipeDetails();
                  }
                }
              }, error: this.alertService.showApiError
            });
        }
      }
    }
    this.selectedProduct = null;
  }

  loadDestinationProduct() {
    if (this.selectedDefaultProduct > 0) {
      this.spinnerService.show();
      this.salesProductService.getSalesProduct(this.selectedProduct.idSize.split('-')[0])
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: res => {
            if (res) {
              this.productId = res.id;
              this.productName = res.Name;
              const consumption = {
                SalesProductId: this.productId,
                IsHistory: this.isShowHistory,
                SizeId: this.selectedSizeId,
                InventoryProductId: null
              };
              this.getInventoryConsumption(consumption);
            }
          }, error: this.alertService.showApiError
        });
    }
  }

  getInventoryConsumption(consumption: any) {
    this.spinnerService.show();
    this.salesProductService.getProductsInventoryConsumption(consumption)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          if (res) {
            this.productIngredientConsumption = res;
            this.filterProductBaseIngredients();
          }
        }, error: this.alertService.showApiError
      });
  }



  getActiveSalesProductWithSizesCompleted(response) {
    let delIndex = 0;
    let size = '';
    const currentSize = _.find(this.salesSizes, (salesSize) => {
      return salesSize.SizeId == this.selectedSizeId;
    });
    size = currentSize.Name;
    // loop Separately to avoid case where after splice it will reduce index of other items 
    // so it will skip next id from which row spliced, so it will not set idSize of one item.
    _.forEach(response, (product) => {
      if (product) {
        product.idSize = product.id + '-' + product.Size;
        if (this.productId === product.id && product.Size === size) {
          response.splice(delIndex, 1);
        }
        delIndex++;
      }
    });
    this.activeSalesProduct = response;
    this.openCopyModal();
  }

  configureColumn() {

    const productColumn = new TemplateColumn({
      HeaderText: 'Product',
      itemTemplate: this.productTemplate,
      Field: 'Product'
    });

    const instructionColumn = new TemplateColumn({
      HeaderText: 'Instructions',
      itemTemplate: this.instructionTemplate,
      Field: 'instructions'
    });

    const customColorColumn = new TemplateColumn({
      HeaderText: 'Custom Color',
      itemTemplate: this.customColorTemplate,
      TextAlign: TextAlign.Center,
      Field: 'CustomColor'
    });

    const operationTemplate = new TemplateColumn({
      itemTemplate: this.operationTemplate,
      Width: '126px',
      TextAlign: TextAlign.Center
    });

    this.consumptionColumn = [
      productColumn,
      new GridColumn({ HeaderText: 'Quantity', Field: 'qty', IsSortable: false }),
      new GridColumn({ HeaderText: 'Unit', Field: 'Unit', IsSortable: false }),
      new GridColumn({ HeaderText: 'Notes', Field: 'Notes', IsSortable: false }),
      instructionColumn,
      customColorColumn,
      new DateColumn({ HeaderText: 'Effective Date', Field: 'DateEffective', Format: Format.Date, IsSortable: false }),
      new DateColumn({ HeaderText: 'Expired Date', Field: 'DateExpired', Format: Format.Date, IsSortable: false }),
      operationTemplate
    ] as Array<GridColumn>;
  }

  editRecipe(recipe) {
    const recipeModalRef = this.modalService.show(SalesProductRecipeEditComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        recipe: cloneDeep(recipe),
        productId: this.productId
      }
    });
    recipeModalRef.close.subscribe(res => {
      if (res && res.shouldReload) {    
        this.ingredientId = null;
        this.getConsumptionRecord(false);
      }
    });
  }

  onSwapRecipeItem() {
    const recipeModalRef = this.modalService.show(SalesProductRecipeSwapComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        ingredientList: this.ingredientList.filter((data) => {
          return !this.productIngredientConsumption.find(x => x.inventory_product_id === data.Id);
        }),
        recipeItem: this.recipeDetails,
        salesProductId: this.productId
      }
    });
    recipeModalRef.close.subscribe(res => {
      if (res && res.shouldReload) {
        this.getRecipeDetails();
      }
    });
    this.popup.hide();
  }

  onMoveRecipe() {
    this.salesProductService.moveToBaseRecipe(this.productId, this.recipeDetails.inventory_product_id,
      this.recipeDetails.SizeId).pipe(
        finalize(() => {
          this.spinnerService.hide();
        })).subscribe({
          next: (response) => {
            this.getRecipeDetails();
          }, error: this.alertService.showApiError
        });
    this.popup.hide();
  }

  onCancel() {
    this.hide({});
  }
}
