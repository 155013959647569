import { Component, OnInit } from '@angular/core';
import { cloneDeep } from 'lodash';
import { finalize } from 'rxjs/internal/operators/finalize';
import { ApplicationStateService, ConfirmDeleteModalComponent, Messages, ModalService, SettingParam } from 'src/app/shared';
import {
  angleDoubleLeft, angleDoubleRight, caretDown, receiptDesignerArrowCircleLeft, receiptDesignerArrowCircleRight, receiptDesignerCheckCircle,
  receiptDesignerLock, receiptDesignerPlusSquare, receiptDesignerTimesCircle
} from 'src/app/shared/components/icon';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { PrinterScript } from '../../interface/printer-script';
import { ReceiptDesignerService } from '../../services/receipt-designer.service';
import { NewTemplateComponent } from '../new-template/new-template.component';
declare let $: any;
import { StringUtils } from 'src/app/shared/string-utils/string-utils';

@Component({
  selector: 'pos-receipt-designer',
  templateUrl: './receipt-designer.component.html',
  styleUrls: ['./receipt-designer.component.scss']
})
export class ReceiptDesignerComponent implements OnInit {

  isFullScreen = false;
  icons = {
    angleDoubleLeft, angleDoubleRight, caretDown, receiptDesignerArrowCircleLeft, receiptDesignerArrowCircleRight,
    receiptDesignerCheckCircle, receiptDesignerLock, receiptDesignerPlusSquare, receiptDesignerTimesCircle
  };
  activeTabIndex = -1;
  receiptScriptList: Array<PrinterScript> = [];
  onlyReceiptPrint: Array<PrinterScript> = [];
  notReceiptPrint: Array<PrinterScript> = [];
  selectedReceipt: PrinterScript = {
    Id: 0,
    Name: '',
    Script: '',
    IsSystem: false,
    IsReceiptPrint: false,
    UserName: '',
    TerminalId: 0,
    TerminalName: ''
  };
  isReceiptPrint = true;
  isShowButtons = false;
  codeMirrorOptions = {
    indentWithTabs: true,
    mode: 'turtle'
  };
  oldScript = '';
  settingParam: SettingParam;

  constructor(private receiptDesignerService: ReceiptDesignerService,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private applicationStateService: ApplicationStateService,
    private modalService: ModalService) { }

  ngOnInit(): void {
    this.getAllPrinterReceipts();
    this.setDefaults();
  }

  setDefaults() {
    this.settingParam = this.applicationStateService.settingParam;
  }

  getHeight(isOuterContainer: boolean): number {
    $('.CodeMirror').css('height', $(window).height() - 210 + 'px');
    if (isOuterContainer) {
      return $(window).height() - 160;
    } else {
      return $(window).height() - 180;
    }
  }

  getAllPrinterReceipts(): void {
    this.spinnerService.show();
    this.receiptDesignerService.getAllPrinterScripts()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.receiptScriptList = res;
          this.onlyReceiptPrint = this.receiptScriptList.filter(x => x.IsReceiptPrint);
          this.notReceiptPrint = this.receiptScriptList.filter(x => !x.IsReceiptPrint);
          this.isShowButtons = true;
        }, error: this.alertService.showApiError
      });
  }

  toggleFullScreen(): void {
    this.isFullScreen = !this.isFullScreen;
    if (this.isFullScreen) {
      $('.nav-pills').hide();
      $('.tab-content').css('width', '100%');
    } else {
      $('.nav-pills').show();
      $('.tab-content').css('width', '80%');
    }
  }

  hideActiveTabContent(): void {
    this.activeTabIndex = -1;
    document.getElementById('sampleReceiptDiv').innerHTML = '';
  }

  selectedVerticalTab(receiptScript: PrinterScript) {
    this.selectedReceipt = receiptScript;
    this.activeTabIndex = receiptScript.Id;
    this.isShowButtons = true;
    this.oldScript = cloneDeep(receiptScript.Script);
    this.isReceiptPrint = receiptScript.IsReceiptPrint;
    document.getElementById('sampleReceiptDiv').innerHTML = '';
  }

  deletePrinterScript(): void {
    if (this.selectedReceipt && !this.selectedReceipt.IsSystem) {

      const modalRef = this.modalService.getModalWrapper(ConfirmDeleteModalComponent);
      const modal = modalRef.show({
        keyboard: false,
        animated: false,
        class: 'vertical-center',
        initialState: {
          message: StringUtils.format(Messages.ConfirmDeletePrintScript, { 'script': this.selectedReceipt.Name })
        }
      });
      modal.close.subscribe(res => {
        if (res && res.shouldDelete) {
          this.spinnerService.show();
          this.receiptDesignerService.deletePrinterScript(this.selectedReceipt.Id)
            .pipe(finalize(() => {
              this.spinnerService.hide();
            }))
            .subscribe({
              next: () => {
                this.alertService.renderSuccessMessage(Messages.ReceiptPrintDeleteSuccess);
                this.isShowButtons = false;
                this.activeTabIndex = -1;
                this.getAllPrinterReceipts();
              }, error: this.alertService.showApiError
            });
        }
      });
    }
  }

  showReceiptSample(): void {
    this.spinnerService.show();
    if (this.selectedReceipt) {
      const script = cloneDeep(this.selectedReceipt);
      script.Script = cloneDeep(this.oldScript);
      script.TerminalId = this.settingParam.TerminalId;
      script.TerminalName = this.settingParam.TerminalName;
      script.UserName = this.applicationStateService.userDetails.username;
      this.receiptDesignerService.showReceiptSample(script)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res: { Content: string }) => {
            document.getElementById('sampleReceiptDiv').innerHTML = res.Content;
          }, error: this.alertService.showApiError
        });
    }
  }

  saveReceiptScript(): void {
    if (this.oldScript !== this.selectedReceipt.Script) {
      this.selectedReceipt.Script = this.oldScript;
      this.savePrintScript(this.selectedReceipt, true);
    } else {
      this.alertService.renderSuccessMessage(Messages.ReceiptPrintHaveNoChanges);
    }
  }

  revertDataReceiptScript(): void {
    this.oldScript = cloneDeep(this.selectedReceipt.Script);
  }

  loadCreateTemplateModal(scriptType: string) {
    const modalRef = this.modalService.getModalWrapper(NewTemplateComponent);
    const modal = modalRef.show({
      keyboard: false,
      animated: false,
      class: 'vertical-center',
      initialState: {
        scriptType: scriptType
      }
    });
    modal.close.subscribe(res => {
      if (res && res.Name) {
        const newPrinterScript: PrinterScript = {
          Name: res.Name,
          IsReceiptPrint: scriptType === 'Print Script',
          Script: '',
          IsSystem: false,
        } as PrinterScript;
        this.savePrintScript(newPrinterScript);
      }
    });
  }

  savePrintScript = (printScript, isShowSuccessMessage?: boolean) => {
    this.spinnerService.show();
    this.receiptDesignerService.savePrinterScript(printScript)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: PrinterScript) => {
          if (isShowSuccessMessage) {
            this.alertService.renderSuccessMessage(Messages.ReceiptPrintSaveSuccess);
          } else {
            this.getAllPrinterReceipts();
          }
          this.selectedVerticalTab(printScript && printScript.Id ? printScript : res);
        }, error: this.alertService.showApiError
      });
  }

}
