import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RuntimeConstants } from 'src/app/shared';
import { PickScreenOrder } from '../interfaces/pick-screen-product';

@Injectable({
  providedIn: 'root'
})
export class PickScreenService {

  constructor(private httpService: HttpClient) { }

  getAllPickScreenDetails(terminalId): Observable<Array<PickScreenOrder>> {
    return this.httpService.get<Array<PickScreenOrder>>(
      `${RuntimeConstants.API_PATH}pick-screen/${terminalId}`
    );
  }

  getPickScreenDetails(orderProductId): Observable<PickScreenOrder> {
    return this.httpService.get<PickScreenOrder>(
      `${RuntimeConstants.API_PATH}pick-screen/product/${orderProductId}`
    );
  }

}
