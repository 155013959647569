import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Unit } from 'src/app/information-management/units/interface/unit';
import { UnitService } from 'src/app/information-management/units/services/unit.service';
import { Vendor } from 'src/app/information-management/vendors/interface/vendor';
import { VendorService } from 'src/app/information-management/vendors/service/vendor.service';
import { BaseCrudComponent } from 'src/app/shared/components/base-crud.component';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { InvProduct } from 'src/app/shared/interface/inventory-product';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { FormUtilityService } from 'src/app/shared/services/form-utility.service';
import { InventoryProductService } from 'src/app/shared/services/inventory-product.service';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import { InventoryProductHistory } from '../../interface/inventory-product-history';
import { InventoryProductHistoryService } from '../../services/inventory-product-history.service';
import { history } from 'src/app/shared/components/icon';
import { GridContextInfo } from 'src/app/shared';

@Component({
  selector: 'pos-inventory-product-history-edit',
  templateUrl: './inventory-product-history-edit.component.html',
  styleUrls: ['./inventory-product-history-edit.component.scss']
})
export class InventoryProductHistoryEditComponent extends BaseCrudComponent<InventoryProductHistory> implements OnInit {

  inventoryProductHistory: InventoryProductHistory;
  inventoryProductId: number;
  id: number;
  vendorList: Array<Vendor> = [];
  inventoryProductName: string;
  inventoryProduct: InvProduct;
  dateFormat = 'mm-dd-yy';
  hourFormat = '12';
  unitList = [];
  today = new Date();
  @ViewChild('inventoryProductHistoryForm') inventoryProductHistoryForm: NgForm;
  get getForm(): NgForm {
    return this.inventoryProductHistoryForm
  }
  props = {
    labelClass: '',
    controlBoxClass: 'col-lg-4 col-md-4 col-sm-6 col-xs-12'
  }
  icons = {
    history
  }
  inventoryProductsGridContext: GridContextInfo = null;
  inventoryProductCategory: number = null;
  inventoryProductVendor: number = null;
  inventoryProductZone: number = null;
  inventoryProductActiveFilter: boolean = true;

  constructor(protected inventoryProductHistoryService: InventoryProductHistoryService,
    protected inventoryProductService: InventoryProductService,
    private vendorService: VendorService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected applicationStateService: ApplicationStateService,
    protected route: ActivatedRoute,
    private router: Router,
    protected unitService: UnitService,
    protected printService: PrintTableService,
    formUtilityService: FormUtilityService) {
    super(inventoryProductHistoryService, alertService, spinnerService, formUtilityService);
    this.inventoryProductId = parseInt(route.snapshot.params.inventoryProductId, 10) ?? 0;
    this.id = parseInt(route.snapshot.params.productHistoryId, 10) ?? 0;
    this.inventoryProductHistory = this.inventoryProductHistoryService.getNewProductHistory();
    this.dateFormat = this.applicationStateService.settingParam.PCalendarDateFormat;
    this.saveSuccessMessage = Messages.InventoryProductHistorySavedSuccess;

    const navigation = router.getCurrentNavigation();
    this.inventoryProductsGridContext = navigation?.extras?.state?.GridContext ?? null;
    this.inventoryProductCategory = navigation?.extras?.state?.Category ?? null;
    this.inventoryProductVendor = navigation?.extras?.state?.Vendor ?? null;
    this.inventoryProductZone = navigation?.extras?.state?.Zone ?? null;
    this.inventoryProductActiveFilter = navigation?.extras?.state?.Active ?? null;
  }

  ngOnInit(): void {
    this.loadProductHistory();
  }

  loadProductHistory() {
    if (this.id) {
      this.spinnerService.show();
      this.loadData().pipe(finalize(() => {
        this.spinnerService.hide();
      })).subscribe({
        next: (response) => {
          this.inventoryProductHistory = response;
        }, error: this.alertService.showApiError
      });
    }
    else {
      this.spinnerService.show();
      this.inventoryProductService.getInventoryProduct(this.inventoryProductId).pipe(finalize(() => {
        this.spinnerService.hide();
      })).subscribe({
        next: (response) => {
          this.inventoryProduct = response;
        }, error: this.alertService.showApiError
      });
    }
  }

  loadDependencies() {
    const observable: Array<Observable<any>> = [];
    this.spinnerService.show();
    observable.push(this.vendorService.getVendorList());
    observable.push(this.unitService.getUnits());
    observable.push(this.inventoryProductService.getInventoryProduct(this.inventoryProductId));
    forkJoin(observable).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (response: [Array<Vendor>, Array<Unit>, InvProduct]) => {
        this.vendorList = [...response[0]];
        this.unitList = [...response[1]];
        this.inventoryProductName = response[2].name;
      }
    });
  }

  saveProductHistory(isValid: boolean) {
    if (!isValid) {
      return;
    }
    if (!this.id) {
      this.inventoryProductHistory.InventoryProductId = this.inventoryProductId;
    }
    this.spinnerService.show();
    this.inventoryProductHistoryService.insert(this.inventoryProductHistory).pipe(finalize(() => {
      this.spinnerService.hide();
    })).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (response) => {
        this.onSaveSuccess();
      }, error: this.alertService.showApiError
    });
  }

  onSaveSuccess() {
    this.alertService.renderSuccessMessage(this.saveSuccessMessage);
    this.onCancel();
  }


  onCancel() {
    this.router.navigate([`manage/app-configuration/inventory-products/${this.inventoryProductId}/product-history`], { state: { GridContext: this.inventoryProductsGridContext, Category: this.inventoryProductCategory, Vendor: this.inventoryProductVendor, Zone: this.inventoryProductZone, Active: this.inventoryProductActiveFilter } });
  }

}
