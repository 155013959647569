import { Component, OnInit } from '@angular/core';
import { AlertsService, ApplicationStateService, ColorUtilityService, CreditCardPaymentStatus, DomainConstants, OrderService, RabbitMQService, SettingParam, SpinnerService, UserIdleService } from 'src/app/shared';
import { RuntimeConstants } from 'src/app/shared/constants';
import { KioskNavigationService } from '../../services';
import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs/internal/Subscription';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderInvoice } from 'src/app/orders';
import { KioskUserIdleComponent } from '../kiosk-user-idle-component';
import { KioskPaymentStyle } from '../../interfaces/styling/kiosk-payment-style';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pos-credit-card-payment-processing',
  templateUrl: './credit-card-payment-processing.component.html',
  styleUrls: ['./credit-card-payment-processing.component.scss']
})
export class CreditCardPaymentProcessingComponent implements OnInit {

  backgroundImage;
  baseImagePath = `${RuntimeConstants.IMAGE_BASE_PATH}/order-kiosk/default-screens/`;
  footerBackgroundColor = '#EEEEEE';
  settingParam: SettingParam;
  orderId: number = null;
  checkCCPaymentStatusInterval;
  rabbitMQRequestId;
  rabbitMqOrderEntryTerminalQueueSubscription: Subscription;
  rabbitMqHICResponseSubscription: Subscription;
  isPaymentRetry: boolean = false;
  order: OrderInvoice;
  swipeCardImage: string;
  paymentFailedImage: string;
  paymentTerminalBusyImage: string;
  allowPayAtCounter: boolean = true;
  userIdleSubscription: Subscription;
  kioskPaymentStyle: KioskPaymentStyle;
  failedStatusTimeout: NodeJS.Timeout;
  constructor(public applicationStateService: ApplicationStateService,
    public kioskNavigationService: KioskNavigationService,
    public rabbitMQService: RabbitMQService,
    public orderService: OrderService,
    public router: Router,
    public route: ActivatedRoute,
    public alertService: AlertsService,
    private colorUtilityService: ColorUtilityService,
    private userIdleService: UserIdleService,
    private appStateService: ApplicationStateService,
    private spinnerService: SpinnerService
  ) {
    this.footerBackgroundColor = this.colorUtilityService.convertColorToRgba(this.appStateService.settingParam.KioskButtonsBackgroundColor ?? '#ffa800', this.appStateService.settingParam.KioskButtonsBackgroundOpacity ?? 0.4);
    this.kioskPaymentStyle = this.appStateService.settingParam.OrderKioskPaymentStyle ? JSON.parse(this.appStateService.settingParam.OrderKioskPaymentStyle) : this.newKioskPaymentStyle();
  }

  private newKioskPaymentStyle = (): KioskPaymentStyle => {
    return {
      OrderDetailFontColor: null,
      OrderDetailBackgroundColor: null,
      OrderDetailVerticalAlignment: null,
      OrderDetailVerticalPosition: null
    };
  }

  ngOnInit() {
    const settingParam = this.applicationStateService.settingParam;
    if (settingParam) {
      this.swipeCardImage = settingParam.OrderKioskPleaseSwipeCardImage;
      this.paymentFailedImage = settingParam.OrderKioskPaymentFailedImage;
      this.paymentTerminalBusyImage = settingParam.OrderKioskPaymentTerminalBusyImage;
      this.allowPayAtCounter = settingParam.OrderKioskAllowPayAtCounter;
      this.backgroundImage = this.swipeCardImage;
    }
    this.order = this.kioskNavigationService.orderDetails;
    this.orderId = this.kioskNavigationService.orderDetails?.Id?.Value;
    if (this.orderId) {
      this.settingParam = this.applicationStateService.settingParam;
      this.subscribeToHICResponseToTerminal();
      this.subscribeToOrderPaymentResponseTerminalQueue();
      this.makePayment();
    } else {
      this.router.navigate(['..'], { relativeTo: this.route });
    }
  }

  makePayment = () => {
    if (this.isPaymentRetry) {
      this.isPaymentRetry = false;
      this.backgroundImage = this.swipeCardImage;
      this.stopUserIdleService();
      clearTimeout(this.failedStatusTimeout);
      this.clearCCPaymentStatusInterval();
    }
    if (this.orderId) {
      const payment = this.preparePaymentDetails();
      this.orderService.integratedCreditCardPayment(this.orderId, payment)
        .pipe(finalize(() => {
        }))
        .subscribe({
          next: (response: any) => {
            this.checkCCPaymentStatusInterval = setInterval(() => {
              this.checkCCPaymentStatus();
            }, 5000);
            this.failedStatusTimeout = setTimeout(() => {
              if (this.checkCCPaymentStatusInterval) {
                this.showPaymentFailed();
              }
              clearTimeout(this.failedStatusTimeout);
            }, 60 * 1000);
          }, error: (error) => {
            this.isPaymentRetry = true;
          }
        });
    }
  }

  subscribeToOrderPaymentResponseTerminalQueue() {
    this.rabbitMqOrderEntryTerminalQueueSubscription = this.rabbitMQService.subscribeToOrderPaymentResponseTerminalQueue$(this.applicationStateService.terminalId)
      .subscribe((message: any) => {
        this.creditCardPaymentCompleted(message.Payload.PaymentResponse);
      });
  }

  subscribeToHICResponseToTerminal() {
    this.rabbitMqHICResponseSubscription = this.rabbitMQService.subscribeToHICResponseToTerminal$(this.applicationStateService.terminalId)
      .subscribe((message: any) => {
        const response = message.Payload.HICReponse;
        if (response.HardwareType == 'CreditCardTerminal') {
          if (response.Status == DomainConstants.HICResponseStatus.Busy) {
            this.rabbitMQRequestId = '';
            this.isPaymentRetry = true;
            this.backgroundImage = this.paymentTerminalBusyImage;
            this.clearCCPaymentStatusInterval();
          } else if (response.Status == DomainConstants.HICResponseStatus.Error) {
            this.rabbitMQRequestId = '';
            this.isPaymentRetry = true;
            this.clearCCPaymentStatusInterval();
          }
        }
      });
  }

  checkCCPaymentStatus = () => {
    if (this.rabbitMQRequestId && this.orderId) {
      this.orderService.getTransactionStatus(this.rabbitMQRequestId)
        .subscribe({
          next: (response: CreditCardPaymentStatus) => {
            if (response.Status == DomainConstants.TransactionStatus.Success) {
              this.onCCTransactionGetSuccessStatus();
            } else if (response.Status == DomainConstants.TransactionStatus.Failed) {
              this.onCCTransactionGetFailedStatus();
            } else if (response.Status == DomainConstants.TransactionStatus.Canceled) {
              this.onCCTransactionGetCanceledStatus();
            }
          }, error: this.alertService.showApiError
        });
    } else {
      this.clearCCPaymentStatusInterval();
    }
  }

  clearCCPaymentStatusInterval() {
    if (this.checkCCPaymentStatusInterval) {
      clearInterval(this.checkCCPaymentStatusInterval);
    }
  }


  preparePaymentDetails() {
    this.rabbitMQRequestId = this.rabbitMQService.guidWithTimeStamp();
    const paymentDetails: any = {
      Id: 0,
      OrderId: this.orderId,
      Amount: this.order.GrandTotal,
      PaymentTypeId: DomainConstants.PaymentTypes.CREDITCARD,
      IsCompleted: false,
      UserId: this.applicationStateService.userDetails.id,
      IsCaptured: false,
      TerminalId: this.applicationStateService.terminalId,
      TerminalName: this.applicationStateService.terminalName,
      SubAccountOrdinals: [],
      SurrogateOrderId: this.order.SurrogateOrderId,
      RabbitMQRequestId: this.rabbitMQRequestId,
      IsPartialPayment: false,
      CreditCardTerminalId: this.settingParam?.CreditCardTerminal?.Id
    };
    return paymentDetails;
  }

  onCCTransactionGetSuccessStatus = () => {
    this.clearCCPaymentStatusInterval();
    this.rabbitMQRequestId = '';
  }


  onCCTransactionGetFailedStatus = () => {
    this.clearCCPaymentStatusInterval();
    this.rabbitMQRequestId = '';
    this.showPaymentFailed();
  }

  onCCTransactionGetCanceledStatus = () => {
    this.clearCCPaymentStatusInterval();
    this.rabbitMQRequestId = '';
    this.showPaymentFailed();
  }

  creditCardPaymentCompleted(response) {
    if (response?.PaymentCompleted) {
      this.clearCCPaymentStatusInterval();
      this.rabbitMQRequestId = '';
      if (!response.PaymentSuccessfullyComplete) {
        this.showPaymentFailed();
      } else {
        this.payAtCounter();
      }
    }
  }

  payAtCounter = () => {
    this.router.navigate(['../confirmed'], { relativeTo: this.route });
  }

  showPaymentFailed() {
    this.isPaymentRetry = true;
    this.backgroundImage = this.paymentFailedImage;
    this.subscribeToUserIdleSubscription();
  }

  subscribeToUserIdleSubscription() {
    if (this.userIdleSubscription) {
      this.userIdleSubscription.unsubscribe();
    }
    this.userIdleSubscription = this.userIdleService.initialize(this.kioskNavigationService.userIdleTimeout, () => this.userGoneIdle());
  }

  userGoneIdle = () => {
    this.stopUserIdleService();
    const callBack = (res) => {
      if (res?.continueOrder) {
        this.subscribeToUserIdleSubscription();
      } else {
        this.cancelOrder();
      }
    };
    this.kioskNavigationService.openOverlayModal(KioskUserIdleComponent, callBack)
  }

  stopUserIdleService() {
    this.userIdleService.stop();
    this.userIdleSubscription?.unsubscribe();
  }

  cancelOrder = () => {
    if (this.kioskNavigationService.orderDetails?.Id?.Value) {
      this.spinnerService.show();
      this.orderService.deleteOrder(this.kioskNavigationService.orderDetails.Id.Value)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: () => {
            this.stopUserIdleService();
            this.kioskNavigationService.resetProps();
            this.router.navigate(['home'], { relativeTo: this.route.parent, state: { startOrderingDirectly: true } });
          }, error: this.alertService.showApiError
        });
    }
  }
  ngOnDestroy() {
    clearInterval(this.failedStatusTimeout);
    this.stopUserIdleService();
    this.clearCCPaymentStatusInterval();
  }

}
