import { Component, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { TemplateColumn } from '@tarktech/tark-ng-utils';
import { cloneDeep, filter, find, findIndex, forEach, orderBy } from 'lodash';
import { finalize } from 'rxjs/operators';
import {
  AlertsService, ConfirmDeleteModalComponent, DomainConstants, Messages, ModalService,
  SpinnerService, HardwareModel, AudioNotificationService, DeviceMappingService, ExternalApiService, UserService, ButtonService, CronEditorService, ApplicationStateService
} from 'src/app/shared';
import {
  eventbars, editWhite, deleteWhite, ban, checkCircleWhite, eventActionPlus, envelopeSolid, terminalTv, audioNotification, bell, editSolid,
  clock, externalApiPlug, eventToggleOn, eventToggleOff, eventTasksCheckDouble, eventOrderEntryTerminalWarning, sms, music, lightBulb
} from 'src/app/shared/components/icon';
import { EventService } from '../../services';
import { SalesProductsService } from '../../../../information-management/sales-products/services/sales-products.service';
import { SalesProduct } from '../../../../information-management/sales-products/interface/sales-product';
import { EventSubscriptionActionAttribute, EventSubscriptionAction, EventSubscription, EventSubscriptionAttribute } from '../../interfaces';
import { NgForm } from '@angular/forms';
import { User } from '../../../../information-management/users';
import { Terminal, TerminalsService } from '../../../../configurator/terminals/';
import { ExternalApi } from '../../../../configurator/external-api';
import { TaskService } from '../../../../shared/services/task.service';
import { HardwaresService } from '../../../../configurator/hardware';
import { Task } from '../../../../information-management/tasks/interface/task';
import { AudioNotificationFile } from '../../../../information-management/audio-notification-files/interfaces/audio-notification-file';
import { forkJoin } from 'rxjs';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { Features } from 'src/app/shared/constants/features';
import { EventConfiguration } from '../../interfaces/event-configuration';
import { Equipment, EquipmentService } from 'src/app/information-management';
import { EventSubscriptionActionService } from '../../services/event-subscription-action.service';
import { EventSubscriptionService } from '../../services/event-subscription.service';
import { ButtonScreenModel } from 'src/app/information-management/buttons/interface/button-screen-model';
import { UserClockedInPeriod } from '../../interfaces/user-clocked-in-period';
import { CronOptions } from '@tarktech/ngx-cron-editor';
@Component({
  selector: 'pos-event-configuration-list',
  templateUrl: './event-configuration-list.component.html',
  styleUrls: ['./event-configuration-list.component.scss']
})
export class EventConfigurationListComponent implements OnInit, OnChanges {
  icons = {
    eventbars, editWhite, deleteWhite, ban, checkCircleWhite, eventActionPlus, envelopeSolid, terminalTv, audioNotification, bell, editSolid,
    clock, externalApiPlug, eventToggleOn, eventToggleOff, eventTasksCheckDouble, eventOrderEntryTerminalWarning, sms, music, lightBulb
  };
  @Input() isFullScreen = false;
  @Input() eventConfiguration: EventConfiguration;
  @Input() availableDeliveryChannels: Array<any> = [];
  @Input() eventId = 0;
  @Output()
  reload = new EventEmitter<EventConfiguration>();
  @Output()
  closeTab = new EventEmitter<string>();
  cols: any[];
  deliveryTypes = [];
  @ViewChild('actionName', { static: true }) actionName: TemplateRef<any>;
  @ViewChild('descriptions', { static: true }) descriptions: TemplateRef<any>;
  @ViewChild('operationTemplate', { static: true }) operationTemplate: TemplateRef<any>;
  @ViewChild('formEventConfigurationList', { static: true }) formEventConfigurationList: NgForm;
  activeOrders = 0;
  clockInHours = 0;
  unservedOrderMinutes = 0;
  selectedSalesProduct: Array<number> = [];
  selectedButtons: Array<number> = [];
  salesProducts: Array<SalesProduct> = [];
  buttons: Array<ButtonScreenModel> = [];
  creditCardConnectedTerminalMinutes = 0;
  isNotifyDNIProduct = false;
  eventDeliveryChannels = DomainConstants.EventDeliveryChannels;
  loadEdit = false;
  actionId = 0;
  selectedPeriodList: Array<UserClockedInPeriod> = [];
  selectedDeliveryType;
  selectedAction;
  selectedSubscription: EventSubscription = null;
  eventTypes = DomainConstants.EventTypes;
  features = Features;
  lightingControllers: Array<HardwareModel> = [];
  hardwareDevices: Array<HardwareModel> = [];
  printerDevices: Array<HardwareModel> = [];
  temperatureExceptions = orderBy(DomainConstants.TemperatureExceptions, 'Key');
  eventSubscriptionAttributeKeys = DomainConstants.EventSubscriptionAttributeKeys;
  eventType = DomainConstants.EventType;
  subscriptionEventType: string = null;
  period = DomainConstants.Period;
  weeks = DomainConstants.Weeks;
  hoursOverPeriodMonthOptions = DomainConstants.HoursOverPeriodMonth;

  users: Array<User> = [];
  terminals: Array<Terminal> = [];
  externalApis: Array<ExternalApi> = [];
  audioDevices: Array<HardwareModel> = [];
  audioFiles: Array<AudioNotificationFile> = [];
  audioVoices: Array<string> = [];
  tasks: Array<Task> = [];
  equipments: Array<Equipment> = [];
  cronOptions: CronOptions = null;
  inventoryPrepNeededQtyStatus = DomainConstants.InventoryPrepNeededQtyStatus;
  isHideScheduleExpressionEdit = true;
  isCronValid = true;

  constructor(private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private modalService: ModalService,
    private eventService: EventService,
    private eventSubscriptionService: EventSubscriptionService,
    private eventSubscriptionActionService: EventSubscriptionActionService,
    private salesProductsService: SalesProductsService,
    private userService: UserService,
    private terminalService: TerminalsService,
    private externalApiService: ExternalApiService,
    private deviceMappingService: DeviceMappingService,
    private hardwareService: HardwaresService,
    private audioNotificationService: AudioNotificationService,
    private buttonService: ButtonService,
    private equipmentService: EquipmentService,
    private cronEditorService: CronEditorService,
    private taskService: TaskService,
    private applicationStateService: ApplicationStateService,
  ) { }

  ngOnInit(): void {
    this.loadDependencies();
    this.prepareGridColumn();
    this.getSalesProducts();
    this.cronOptions = this.cronEditorService.newCronSettings();
    this.prepareWeekDays();
  }

  ngOnChanges(event) {
    if (this.eventConfiguration && this.eventConfiguration.EventIdentifier) {
      this.setAttributeValues();
      this.generateDeliveryType();
      this.prepareDescriptionForActions();
      this.prepareRecoveryActions();
      if (this.eventConfiguration.IsScheduled) {
        this.eventConfiguration.Schedule = this.eventConfiguration.Schedule ?? CronEditorService.defaultStandard;
      }
    }
  }

  prepareWeekDays() {
    const startWeekDay = this.applicationStateService.settingParam.WeekStart;
    const startWeekDayIndex = findIndex(DomainConstants.Weeks, (day) => {
      return day.Value === startWeekDay;
    });
    if (startWeekDayIndex) {
      const weeksCopy = [...this.weeks];
      this.weeks = [];
      this.weeks.push(...weeksCopy.slice(startWeekDayIndex, weeksCopy.length));
      this.weeks.push(...weeksCopy.slice(0, startWeekDayIndex));
    }
  }

  prepareRecoveryActions() {
    forEach(this.eventConfiguration.EventSubscriptions, (subscription) => {
      subscription.EventSubscriptionRecoveryActions =
        cloneDeep(subscription.EventSubscriptionActions.filter(x => x.EventType === this.eventType.Recovery));
      subscription.EventSubscriptionActions =
        cloneDeep(subscription.EventSubscriptionActions.filter(x => x.EventType === this.eventType.Event));
    });
  }
  prepareGridColumn = () => {
    const activityColumn = new TemplateColumn({
      itemTemplate: this.actionName,
      IsSortable: true,
      Width: '257px',
    });

    const descriptionColumn = new TemplateColumn({
      HeaderText: '',
      itemTemplate: this.descriptions,
      CellClass: 'break-word'
    });

    const operationColumn = new TemplateColumn({
      itemTemplate: this.operationTemplate,
      Width: '105px'
    });

    this.cols = [
      activityColumn,
      descriptionColumn,
      operationColumn
    ];
  }

  close = () => {
    this.closeTab.emit();
  }

  prepareDescriptionForActions = () => {
    forEach(this.eventConfiguration.EventSubscriptions, (subscription) => {
      forEach(subscription.EventSubscriptionActions, (action) => {
        action.EventType = action.EventType ?? this.eventType.Event;
        this.setEventSubscriptionAction(action);
      });
      forEach(subscription.EventSubscriptionRecoveryActions, (action) => {
        action.EventType = action.EventType ?? this.eventType.Event;
        this.setEventSubscriptionAction(action);
      });
    });
  }

  setEventSubscriptionAction(action: EventSubscriptionAction) {
    const eventDeliveryAttributes = DomainConstants.EventDeliveryAttributes;
    let selectedTerminalNames = null, selectedUserNames = null, recipientPhoneNumbers = '', smsText = '';
    action.Description = '';
    forEach(action.EventSubscriptionActionAttributes, attribute => {
      switch (attribute.AttributeKey) {
        case eventDeliveryAttributes.Email.Recipients:
          action.Description = 'To: ' + attribute.AttributeValue;
          break;
        case eventDeliveryAttributes.TerminalMessage.Terminals:
          selectedTerminalNames = this.getSelectedTerminalsName(attribute);
          action.Description += (selectedUserNames ? '; ' : '') + (selectedTerminalNames ? 'Terminals: ' + selectedTerminalNames : '');
          break;
        case eventDeliveryAttributes.TerminalMessage.Users:
          selectedUserNames = this.getSelectedUsersName(attribute);
          action.Description += (selectedTerminalNames ? '; ' : '') + (selectedUserNames ? 'Users: ' + selectedUserNames : '');
          break;
        case eventDeliveryAttributes.ExternalAPIDelivery.ExternalApi:
          action.Description = 'API: ' + this.getSelectedExternalApiName(attribute);
          break;
        case eventDeliveryAttributes.AudioNotification.AudioDevices:
          action.Description = this.getSelectedAudioNotifierName(attribute);
          return false;
        case eventDeliveryAttributes.Task.TaskId:
          action.Description = this.getSelectedTaskName(attribute);
          break;
        case eventDeliveryAttributes.Task.TaskNameTemplate:
          action.Description = attribute.AttributeValue;
          break;
        case eventDeliveryAttributes.Delay.Seconds:
          action.Description = attribute.AttributeValue + ' Seconds';
          break;
        case eventDeliveryAttributes.SMS.RecipientPhoneNumbers:
          recipientPhoneNumbers = attribute.AttributeValue;
          action.Description += (smsText ? ', ' : '') + 'To: ' + recipientPhoneNumbers;
          break;
        case eventDeliveryAttributes.SMS.SMSText:
          smsText = attribute.AttributeValue;
          action.Description += (recipientPhoneNumbers ? ', ' : '') + 'Message: ' + smsText;
          break;
        case eventDeliveryAttributes.DJ.Terminals:
          selectedTerminalNames = this.getSelectedTerminalsName(attribute);
          action.Description = 'Terminals: ' + selectedTerminalNames;
          break;
        case eventDeliveryAttributes.PushNotification.AudioFile:
          action.Description = DomainConstants.PushNotificationModes.Audio;
          break;
        case eventDeliveryAttributes.PushNotification.NotificationText:
          action.Description = DomainConstants.PushNotificationModes.Text;
          break;
        case eventDeliveryAttributes.PushNotification.NotificationSpeakText:
          action.Description = DomainConstants.PushNotificationModes.SpeakText;
          break;
        case eventDeliveryAttributes.Light.LightingControllerId:
          action.Description = 'Lighting Controller: ' + this.getSelectedLightingControllerName(attribute);
          break;
        // case eventDeliveryAttributes.Light.IndicationType:
        //   action.Description += ', Action: ' + attribute.AttributeValue;
        //   break;
      }
    });
  }

  getSelectedTaskName = (attribute: EventSubscriptionActionAttribute): string => {
    let selectedTaskName = '';
    if (attribute.AttributeValue) {
      const task = find(this.tasks, (t) => {
        return t.id === parseInt(attribute.AttributeValue, 10);
      });
      if (task) {
        selectedTaskName = task.taskname;
      }
    }
    return selectedTaskName;
  }

  getSelectedAudioNotifierName = (attribute: EventSubscriptionActionAttribute): string => {
    let selectedAudioNotifierName = '';
    if (attribute.AttributeValue) {
      forEach(this.audioDevices, (api) => {
        if (api.Id === parseInt(attribute.AttributeValue, 10)) {
          selectedAudioNotifierName = api.Name;
        }
      });
    }
    return selectedAudioNotifierName;
  }

  getSelectedExternalApiName = (attribute: EventSubscriptionActionAttribute): string => {
    let selectedExternalApiName = '';
    if (attribute.AttributeValue) {
      forEach(this.externalApis, (api) => {
        if (api.Id === parseInt(attribute.AttributeValue, 10)) {
          selectedExternalApiName = api.Name;
        }
      });
    }
    return selectedExternalApiName;
  }

  getSelectedLightingControllerName = (attribute: EventSubscriptionActionAttribute): string => {
    let lightingController = '';
    if (attribute.AttributeValue) {
      lightingController = find(this.lightingControllers, x => x.Id === parseInt(attribute.AttributeValue, 10))?.Name;
    }
    return lightingController;
  }

  getSelectedTerminalsName = (attribute: EventSubscriptionActionAttribute): string => {
    let selectedTerminalNames = '';
    if (attribute.AttributeValue) {
      if (attribute.AttributeValue === '0') {
        selectedTerminalNames = 'All Terminals';
      } else {
        const selectedTerminals = attribute.AttributeValue.includes(',') ?
          attribute.AttributeValue.split(',') : [attribute.AttributeValue];
        forEach(selectedTerminals, (selectedTerminal) => {
          if (selectedTerminal) {
            forEach(this.terminals, (terminal) => {
              if (parseInt(selectedTerminal, 10) === terminal.TerminalId) {
                selectedTerminalNames += ', ' + terminal.TerminalName;
              }
            });
          }
        });
      }
    }
    return selectedTerminalNames.trim().replace(/(^,)|(,$)/g, '');
  }

  getSelectedUsersName = (attribute: EventSubscriptionActionAttribute): string => {
    let selectedUserName = '';
    if (attribute.AttributeValue) {
      if (attribute.AttributeValue === '0') {
        selectedUserName = 'All Users';
      } else {
        const selectedUsers = attribute.AttributeValue.includes(',') ?
          attribute.AttributeValue.split(',') : [attribute.AttributeValue];
        forEach(selectedUsers, (selectedUser) => {
          if (selectedUser) {
            forEach(this.users, (user) => {
              if (parseInt(selectedUser, 10) === user.id) {
                selectedUserName += ', ' + user.name;
              }
            });
          }
        });
      }
    }
    return selectedUserName.trim().replace(/(^,)|(,$)/g, '');
  }

  setAttributeValues = () => {
    forEach(this.eventConfiguration.EventSubscriptions, (subscription, index) => {
      if (subscription.EventSubscriptionAttributes?.length) {
        switch (this.eventConfiguration.EventIdentifier) {
          case this.eventTypes.EquipmentTemperatureExceptionEvent:
            this.setAttributesForEquipmentTemperatureEvent(subscription.EventSubscriptionAttributes);
            subscription.EventSubscriptionAttributes = orderBy(subscription.EventSubscriptionAttributes, 'Ordinal');
            break;
          case this.eventTypes.HardwareConnectedEvent:
            this.setAttributesForHardwareConnectedEvent(subscription.EventSubscriptionAttributes);
            subscription.EventSubscriptionAttributes = orderBy(subscription.EventSubscriptionAttributes, 'Ordinal');
            break;
          case this.eventTypes.UserClockedInOverXHourFromXPeriodEvent:
            this.setAttributeForHoursOverPeriodXEvent(subscription.EventSubscriptionAttributes, index)
            subscription.EventSubscriptionAttributes = orderBy(subscription.EventSubscriptionAttributes, 'Ordinal');
            break;
          default:
            const attribute = subscription.EventSubscriptionAttributes[0];
            if (this.eventConfiguration.EventIdentifier === this.eventTypes.ProductOrderedEvent ||
              this.eventConfiguration.EventIdentifier === this.eventTypes.ButtonPressedEvent ||
              this.eventConfiguration.EventIdentifier === this.eventTypes.PrinterErrorEvent ||
              this.eventConfiguration.EventIdentifier === this.eventTypes.TerminalConnectedEvent) {
              attribute.AttributeValue = attribute.AttributeValue ?
                String(attribute.AttributeValue).split(',').map(x => parseInt(x, 10)) : null;
            } else if (this.eventConfiguration.EventIdentifier === this.eventTypes.InventoryQtyMinimumReachedEvent ||
              this.eventConfiguration.EventIdentifier === this.eventTypes.UnexpectedInventoryLoggedEvent
              || this.eventConfiguration.EventIdentifier === this.eventTypes.InventoryQtyCriticalLevelReachedEvent) {
              attribute.AttributeValue = JSON.parse(attribute.AttributeValue?.toString() ?? 'false');
            }
            break;
        }
      } else {
        subscription.EventSubscriptionAttributes = this.createNewSubscriptionAttributes(subscription.Id);
      }
    });
  }

  setAttributeForHoursOverPeriodXEvent(attributes: Array<EventSubscriptionAttribute>, index: number) {
    const preparedModel = this.eventSubscriptionActionService.newUserClockedInPeriod();
    this.getOrCreateAttribute(attributes, this.eventSubscriptionAttributeKeys.Hours, 0);
    preparedModel.Period = this.getOrCreateAttribute(attributes, this.eventSubscriptionAttributeKeys.Period, 1)?.AttributeValue?.toString();
    switch (preparedModel.Period) {
      case DomainConstants.Period.Day:
        preparedModel.Day = this.getOrCreateAttribute(attributes, this.eventSubscriptionAttributeKeys.Day)?.AttributeValue?.toString();
        break;
      case DomainConstants.Period.Week:
        preparedModel.WeekDay = this.getOrCreateAttribute(attributes, this.eventSubscriptionAttributeKeys.Day)?.AttributeValue?.toString();
        preparedModel.Week = this.getOrCreateAttribute(attributes, this.eventSubscriptionAttributeKeys.Week)?.AttributeValue?.toString();
        break;
      case DomainConstants.Period.Month:
        preparedModel.DayOnMonth = this.getOrCreateAttribute(attributes, this.eventSubscriptionAttributeKeys.Day)?.AttributeValue?.toString();
        preparedModel.Month = this.getOrCreateAttribute(attributes, this.eventSubscriptionAttributeKeys.Month)?.AttributeValue?.toString();
        break;
    }
    this.selectedPeriodList[index] = preparedModel;
  }

  getOrCreateAttribute(attributes: Array<EventSubscriptionAttribute>, attributeKey: string, ordinal: number = 0): EventSubscriptionAttribute {
    let attribute = attributes.find(x => x.AttributeKey == attributeKey);
    if (attribute) {
      attribute.Ordinal = ordinal;
    } else {
      attribute = this.getNewAttribute(attributeKey, ordinal);
      attributes.push(attribute);
    }
    return attribute;
  }

  setAttributeValue(attributes: Array<EventSubscriptionAttribute>, attributeKey: string, value: string, ordinal: number = 0) {
    const attribute = this.getOrCreateAttribute(attributes, attributeKey, ordinal);
    attribute.AttributeValue = value;
  }

  setAttributesForEquipmentTemperatureEvent(attributes: Array<EventSubscriptionAttribute>) {
    const equipmentsAttribute = find(attributes,
      x => x.AttributeKey === this.eventSubscriptionAttributeKeys.Equipments);
    if (equipmentsAttribute) {
      equipmentsAttribute.Ordinal = 1;
      equipmentsAttribute.AttributeValue = equipmentsAttribute.AttributeValue ?
        String(equipmentsAttribute.AttributeValue).split(',').map(x => parseInt(x, 10)) : null;
    } else {
      attributes.push(this.getNewAttribute(this.eventSubscriptionAttributeKeys.Equipments, 1));
    }
    const temperatureExceptionAttribute = find(attributes,
      x => x.AttributeKey === this.eventSubscriptionAttributeKeys.TemperatureExceptionState);
    if (temperatureExceptionAttribute) {
      temperatureExceptionAttribute.Ordinal = 2;
      temperatureExceptionAttribute.AttributeValue = temperatureExceptionAttribute.AttributeValue ?
        String(temperatureExceptionAttribute.AttributeValue).split(',') : null;
    } else {
      attributes.push(this.getNewAttribute(this.eventSubscriptionAttributeKeys.TemperatureExceptionState, 2));
    }
    const temperatureExceptionMinutesAttribute = find(attributes,
      x => x.AttributeKey === this.eventSubscriptionAttributeKeys.TemperatureExceptionMinutes);
    if (temperatureExceptionMinutesAttribute) {
      temperatureExceptionMinutesAttribute.Ordinal = 3;
      temperatureExceptionMinutesAttribute.AttributeValue = temperatureExceptionMinutesAttribute.AttributeValue ?
        parseInt(temperatureExceptionMinutesAttribute.AttributeValue.toString(), 10) : null;
    } else {
      attributes.push(this.getNewAttribute(this.eventSubscriptionAttributeKeys.TemperatureExceptionMinutes, 3));
    }
  }

  setAttributesForHardwareConnectedEvent(attributes: Array<EventSubscriptionAttribute>) {
    const hardwareDevicesAttribute = find(attributes,
      x => x.AttributeKey === this.eventSubscriptionAttributeKeys.HardwareDevices);
    if (hardwareDevicesAttribute) {
      hardwareDevicesAttribute.Ordinal = 1;
      hardwareDevicesAttribute.AttributeValue = hardwareDevicesAttribute.AttributeValue ?
        String(hardwareDevicesAttribute.AttributeValue).split(',').map(x => parseInt(x, 10)) : null;
    } else {
      attributes.push(this.getNewAttribute(this.eventSubscriptionAttributeKeys.HardwareDevices, 1));
    }
    const hardwareStateMinutes = find(attributes,
      x => x.AttributeKey === this.eventSubscriptionAttributeKeys.HardwareStateMinutes);
    if (hardwareStateMinutes) {
      hardwareStateMinutes.Ordinal = 2;
      hardwareStateMinutes.AttributeValue = hardwareStateMinutes.AttributeValue ?
        parseInt(hardwareStateMinutes.AttributeValue.toString(), 10) : null;
    } else {
      attributes.push(this.getNewAttribute(this.eventSubscriptionAttributeKeys.HardwareStateMinutes, 2));
    }
  }

  getSalesProducts = () => {
    this.spinnerService.show();
    this.salesProductsService.getSalesProducts()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: Array<any>) => {
          this.salesProducts = res ? res : [];
        }, error: this.alertService.showApiError
      });
  }

  gridRowReorder(subscriptionActions) {
    const eventActionIds = [];
    forEach(subscriptionActions, (action) => {
      eventActionIds.push(action.Id);
    });
    this.eventSubscriptionActionService.updateActionsOrdinal(this.eventConfiguration.EventIdentifier, eventActionIds)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: EventConfiguration) => {
          this.reload.emit(this.eventConfiguration);
        }, error: this.alertService.showApiError
      });
  }

  editConfiguration = (prop) => {
    const subscription = find(this.eventConfiguration.EventSubscriptions, x => x.Id === prop.EventSubscriptionId);
    if (prop) {
      const deliveryType = this.findDeliveryType(prop.DeliveryChannel);
      this.openAddEditConfigurationModal(prop, deliveryType, subscription);
      this.subscriptionEventType = prop.EventType;
    }
  }

  generateDeliveryType = () => {
    this.deliveryTypes = [];
    forEach(this.eventDeliveryChannels, (channels) => {
      const isAvailableDeliveryChannel = find(this.availableDeliveryChannels, x => x.Type === channels.type);
      if (isAvailableDeliveryChannel || channels.type === this.eventDeliveryChannels.Delay.type) {
        this.deliveryTypes.push(channels);
      }
    });
    this.deliveryTypes = orderBy(this.deliveryTypes, 'description');
  }

  findDeliveryType = (type) => {
    return find(this.deliveryTypes, (deliveryType) => {
      return deliveryType.type === type;
    });
  }

  openAddEditConfigurationModal = (action, deliveryType, subscription: EventSubscription) => {
    this.selectedDeliveryType = deliveryType;
    this.selectedAction = action;
    this.selectedSubscription = subscription;
    this.loadEdit = true;
  }

  closeEventConfigurationEdit = (res) => {
    if (res && res.shouldReload) {
      this.reload.emit(this.eventConfiguration);
    }
    this.actionId = 0;
    this.selectedDeliveryType = null;
    this.selectedAction = null;
    this.loadEdit = false;
    if (res?.audioFiles?.length) {
      this.audioFiles = res.audioFiles;
    }
  }

  addConfiguration = (deliveryType, subscription: EventSubscription, eventType = this.eventType.Event) => {
    this.actionId = subscription.Id;
    this.subscriptionEventType = eventType;
    if (this.eventConfiguration.EventIdentifier === this.eventTypes.UserClockedInOverXHourFromXPeriodEvent) {
      this.prepareUserOverXPeriodAttributeValues();
    }
    this.openAddEditConfigurationModal(null, deliveryType, subscription);
  }

  confirmDelete = (action) => {
    const modalRef = this.modalService.getModalWrapper(ConfirmDeleteModalComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      initialState: {
        message: StringUtils.format(Messages.ConfirmDeleteEventConfiguration,
          { 'configurationName': action.DeliveryChannel })
      }
    });

    modal.close.subscribe(res => {
      if (res && res.shouldDelete) {
        this.deleteConfiguration(action);
      }
    });
  }

  confirmDeleteEventSubscription(subscription: EventSubscription, index: number) {
    if (subscription.Id) {
      const modalRef = this.modalService.getModalWrapper(ConfirmDeleteModalComponent);
      const modal = modalRef.show({
        animated: false,
        class: 'vertical-center',
        initialState: {
          message: Messages.ConfirmDeleteEventSubscription
        }
      });
      modal.close.subscribe(res => {
        if (res && res.shouldDelete) {
          this.deleteEventSubscription(subscription);
        }
      });
    } else {
      this.eventConfiguration.EventSubscriptions.splice(index, 1);
    }
  }

  deleteEventSubscription(subscription: EventSubscription) {
    this.spinnerService.show();
    this.eventSubscriptionService.deleteEventSubscription(this.eventConfiguration.EventIdentifier, subscription.Id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: Array<any>) => {
          this.alertService.renderSuccessMessage(Messages.EventSubscriptionDeleted);
          this.reload.emit(this.eventConfiguration);
        }, error: this.alertService.showApiError
      });
  }

  getDeliveryChannelDescription(deliveryChannel) {
    const channel = find(this.eventDeliveryChannels, x => x.type === deliveryChannel.Type);
    return channel.description;
  }

  deleteConfiguration = (action) => {
    this.spinnerService.show();
    this.eventSubscriptionActionService.deleteAction(this.eventConfiguration.EventIdentifier, action.Id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: Array<any>) => {
          this.alertService.renderSuccessMessage(Messages.EventActionDeleted);
          this.reload.emit(this.eventConfiguration);
        }, error: this.alertService.showApiError
      });
  }

  changeActivityStatus = (action: EventSubscriptionAction) => {
    this.spinnerService.show();
    this.eventSubscriptionActionService.changeActionStatus(this.eventConfiguration.EventIdentifier, action.Id, !action.IsActive)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          action.IsActive = !action.IsActive;
          this.reload.emit(this.eventConfiguration);
        }, error: this.alertService.showApiError
      });
  }

  saveEventConfiguration = (isValid: boolean) => {
    if (this.eventConfiguration.EventIdentifier === this.eventTypes.UserClockedInOverXHourFromXPeriodEvent) {
      this.prepareUserOverXPeriodAttributeValues();
    }
    if (this.eventConfiguration.IsScheduled) {
      this.isCronValid = CronEditorService.cronIsValid(this.cronOptions.cronFlavor, this.eventConfiguration.Schedule);
      if (!this.isCronValid) {
        return;
      }
    }
    if (this.eventConfiguration.EventSubscriptions.some(x => !x.EventSubscriptionActions.length)) {
      this.alertService.renderWarningMessage(Messages.WarningWhileAddSubscriptionWithoutAction);
      return;
    }
    if (isValid) {
      this.prepareAttribute();
      this.spinnerService.show();
      this.eventService.saveEvent(this.eventConfiguration)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res) => {
            this.isHideScheduleExpressionEdit = true;
            this.alertService.renderSuccessMessage(Messages.EventConfigurationSaved);
            this.reload.emit(this.eventConfiguration);
          }, error: this.alertService.showApiError
        });
    }
  }

  prepareUserOverXPeriodAttributeValues() {
    this.selectedPeriodList.forEach((data, index) => {
      const eventAttributes = this.eventConfiguration.EventSubscriptions[index]?.EventSubscriptionAttributes;
      if (eventAttributes) {
        this.getOrCreateAttribute(eventAttributes, this.eventSubscriptionAttributeKeys.Hours, 0);
        this.setAttributeValue(eventAttributes, this.eventSubscriptionAttributeKeys.Period, data.Period, 1);
        this.setAttributeValue(eventAttributes, this.eventSubscriptionAttributeKeys.Day, null, 2);
        this.setAttributeValue(eventAttributes, this.eventSubscriptionAttributeKeys.Week, null, 3);
        this.setAttributeValue(eventAttributes, this.eventSubscriptionAttributeKeys.Month, null, 3);
        switch (data.Period) {
          case DomainConstants.Period.Day:
            this.setAttributeValue(eventAttributes, this.eventSubscriptionAttributeKeys.Day, data.Day, 2);
            break;
          case DomainConstants.Period.Week:
            this.setAttributeValue(eventAttributes, this.eventSubscriptionAttributeKeys.Day, data.WeekDay, 2);
            this.setAttributeValue(eventAttributes, this.eventSubscriptionAttributeKeys.Week, data.Week, 3);
            break;
          case DomainConstants.Period.Month:
            this.setAttributeValue(eventAttributes, this.eventSubscriptionAttributeKeys.Day, data.DayOnMonth, 2);
            this.setAttributeValue(eventAttributes, this.eventSubscriptionAttributeKeys.Month, data.Month, 3);
            break;
        }
      }
    });
  }

  prepareAttribute = () => {
    forEach(this.eventConfiguration.EventSubscriptions, subscription => {
      forEach(subscription.EventSubscriptionAttributes, attribute => {
        if (attribute) {
          if (this.eventConfiguration.EventIdentifier === this.eventTypes.ProductOrderedEvent ||
            this.eventConfiguration.EventIdentifier === this.eventTypes.ButtonPressedEvent ||
            this.eventConfiguration.EventIdentifier === this.eventTypes.PrinterErrorEvent ||
            this.eventConfiguration.EventIdentifier === this.eventTypes.TerminalConnectedEvent) {
            attribute.AttributeValue = Array(attribute.AttributeValue).join(',');
          } else if (attribute.AttributeKey) {
            attribute.AttributeValue = attribute.AttributeValue || attribute.AttributeValue === 0
              ? attribute.AttributeValue.toString() : null;
          }
        } else {
          subscription.EventSubscriptionAttributes = this.createNewSubscriptionAttributes(subscription.Id);
        }
      });
    });
  }

  changeWithinPercentOfMinimum(subscription: EventSubscription) {
    const withinPercentOfMinimumAttribute = find(subscription.EventSubscriptionAttributes, x => x.AttributeKey == this.eventSubscriptionAttributeKeys.WithinPercentOfMinimum);
    withinPercentOfMinimumAttribute.AttributeValue = 0;
  }

  changeQtyStatus(subscription: EventSubscription, value: number) {
    if (!value) {
      this.changeWithinPercentOfMinimum(subscription);
    }
    const qtyStatus = find(subscription.EventSubscriptionAttributes, x => x.AttributeKey == this.eventSubscriptionAttributeKeys.QtyStatus);
    qtyStatus.AttributeValue = value > 0 ? this.inventoryPrepNeededQtyStatus.WithinPercentOfMinimum : this.inventoryPrepNeededQtyStatus.BelowPar;
  }

  loadDependencies = () => {
    this.spinnerService.show();
    const eventObservable = [];
    eventObservable.push(this.userService.getAllActiveUser());
    eventObservable.push(this.terminalService.getTerminals());
    eventObservable.push(this.externalApiService.getExternalApis());
    eventObservable.push(this.taskService.getTaskList());
    eventObservable.push(this.deviceMappingService.getAudioDevices());
    eventObservable.push(this.audioNotificationService.getAudioNotificationFiles());
    eventObservable.push(this.audioNotificationService.getAudioVoiceId());
    eventObservable.push(this.hardwareService.getAll());
    eventObservable.push(this.buttonService.getButtonListWithScreenName());
    eventObservable.push(this.equipmentService.getEquipmentList());
    forkJoin(eventObservable)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (responses: any) => {
          if (responses?.length) {
            this.getUsersCompleted(responses[0]);
            this.getTerminalsCompleted(responses[1]);
            this.getExternalApisCompleted(responses[2]);
            this.getTasksCompleted(responses[3]);
            this.getAudioDeviceCompleted(responses[4]);
            this.getAudioNotificationFilesCompleted(responses[5]);
            this.getAudioVoiceCompleted(responses[6]);
            // filter POS DMX Lighting Controller
            this.lightingControllers = filter(responses[7], x => x.Type === DomainConstants.HardwareTypes.LightingController && x.IsActive && x.TypeIdentifier != "00950D7F-CF93-4645-B86A-792A60307D39");
            this.hardwareDevices = filter(responses[7], x => (x.Type === DomainConstants.HardwareTypes.CreditCardTerminal ||
              x.Type === DomainConstants.HardwareTypes.KitchenPrinter ||
              x.Type === DomainConstants.HardwareTypes.ReceiptPrinter ||
              x.Type === DomainConstants.HardwareTypes.LabelPrinter ||
              x.Type === DomainConstants.HardwareTypes.NetworkedDevice ||
              x.Type === DomainConstants.HardwareTypes.AudioRecorder) && x.IsActive);
            this.printerDevices = filter(responses[7], x => (x.Type === DomainConstants.HardwareTypes.KitchenPrinter ||
              x.Type === DomainConstants.HardwareTypes.ReceiptPrinter ||
              x.Type === DomainConstants.HardwareTypes.LabelPrinter) && x.IsActive);
            this.buttons = responses[8] ? responses[8].filter(x => x.SpecialFunction != DomainConstants.ButtonFunctionTypes.PLACEHOLDER.Name
              && x.SpecialFunction != DomainConstants.ButtonFunctionTypes.NON_FUNCTIONAL.Name) : [];
            this.buttons.forEach((data) => {
              data.ButtonScreen = data.ScreenName && data.ScreenName != '' ? data.ButtonText + ' - (' + data.ScreenName + ')' : data.ButtonText;
            });
            this.prepareEquipmentList(responses[9]);
          }
          this.prepareDescriptionForActions();
        }, error: this.alertService.showApiError
      });
  }

  prepareEquipmentList(equipments: Array<Equipment>) {
    if (equipments.length) {
      this.equipments = filter(equipments, x => x.EquipmentType.EquipmentTypeProperties
        .some(prop => prop.EquipmentProperty.Name.toLowerCase() === DomainConstants.EquipmentProperties.MinTemperature.toLowerCase() ||
          prop.EquipmentProperty.Name.toLowerCase() === DomainConstants.EquipmentProperties.MaxTemperature.toLowerCase()));
    }
  }

  getUsersCompleted = (res: Array<User>) => {
    this.users = res ? res : [];
    const userDetails = this.userService.newUser();
    userDetails.name = 'All Users';
    this.users.splice(0, 0, userDetails);
  }

  getTerminalsCompleted = (res: Array<Terminal>) => {
    this.terminals = res ? res : [];
    this.terminals = orderBy(this.terminals, 'TerminalName');
    const terminalDetails = this.terminalService.newTerminal();
    terminalDetails.TerminalName = 'All Terminals';
    this.terminals.splice(0, 0, terminalDetails);
  }

  getExternalApisCompleted = (res: Array<ExternalApi>) => {
    this.externalApis = res ? res : [];
  }

  getTasksCompleted = (res: Array<Task>) => {
    this.tasks = res;
  }

  getAudioDeviceCompleted = (res: Array<HardwareModel>) => {
    this.audioDevices = res ? res : [];
    const audioDeviceDetails = this.hardwareService.newHardware();
    audioDeviceDetails.Name = 'All Devices';
    this.audioDevices.splice(0, 0, audioDeviceDetails);
  }

  getAudioNotificationFilesCompleted = (res: Array<AudioNotificationFile>) => {
    this.audioFiles = res ? res : [];
  }

  getAudioVoiceCompleted = (res: Array<string>) => {
    this.audioVoices = res ? res : [];
  }

  newEventSubscription() {
    if (this.eventConfiguration.EventIdentifier === this.eventTypes.CreditCardTerminalDisconnectedEvent
      && this.eventConfiguration.EventSubscriptions.length > 0) {
      return;
    }
    if (this.eventConfiguration.EventSubscriptions.some(x => !x.EventSubscriptionActions.length)) {
      this.alertService.renderWarningMessage(Messages.WarningWhileAddSubscriptionWithoutAction);
      return;
    }
    const newEventSubscription = this.eventSubscriptionActionService.newEventSubscription();
    newEventSubscription.EventId = this.eventId;
    newEventSubscription.EventSubscriptionAttributes = this.createNewSubscriptionAttributes();
    this.setDefaultAttributes();
    if (this.eventConfiguration.EventIdentifier === this.eventTypes.InventoryPrepNeededEvent) {
      this.changeQtyStatus(newEventSubscription, 0);
      this.changeWithinPercentOfMinimum(newEventSubscription);
    }
    this.eventConfiguration.EventSubscriptions.push(newEventSubscription);
  }

  setDefaultAttributes() {
    if (this.eventConfiguration.EventIdentifier === this.eventTypes.UserClockedInOverXHourFromXPeriodEvent) {
      this.selectedPeriodList.push(this.eventSubscriptionActionService.newUserClockedInPeriod());
    }
  }

  createNewSubscriptionAttributes(subscriptionId: number = 0) {
    const subscriptionAttributes: Array<EventSubscriptionAttribute> = [];
    const eventAttributes = this.getSubscriptionAttributesForEvent();
    forEach(eventAttributes, attribute => {
      subscriptionAttributes.push(this.getNewAttribute(attribute.AttributeName, 0, subscriptionId));
    });
    return subscriptionAttributes.length ? subscriptionAttributes : [];
  }

  getNewAttribute(name: string, ordinal: number = null, subscriptionId = 0) {
    const newEventSubscriptionAttribute: EventSubscriptionAttribute = this.eventSubscriptionActionService.newEventSubscriptionAttribute();
    newEventSubscriptionAttribute.AttributeKey = name;
    newEventSubscriptionAttribute.EventSubscriptionId = subscriptionId;
    newEventSubscriptionAttribute.Ordinal = ordinal;
    return newEventSubscriptionAttribute;
  }

  getSubscriptionAttributesForEvent() {
    const subscriptionAttribute = filter(DomainConstants.EventSubscriptionAttributes, (attribute) =>
      attribute.EventName === this.eventConfiguration.EventIdentifier);
    return subscriptionAttribute.length ? subscriptionAttribute : [];
  }

  changeEventStatus() {
    this.spinnerService.show();
    this.eventService.changeEventStatus(this.eventId, !this.eventConfiguration.IsActive)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.reload.emit(this.eventConfiguration);
        }, error: this.alertService.showApiError
      });
  }

  changeSubscriptionStatus(subscription: EventSubscription) {
    this.spinnerService.show();
    this.eventSubscriptionService.changeSubscriptionStatus(this.eventConfiguration.EventIdentifier, subscription.Id, !subscription.IsActive)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          if (subscription.Id) {
            this.reload.emit(this.eventConfiguration);
          }
        }, error: this.alertService.showApiError
      });
  }

  onPeriodChange(index: number) {
    switch (this.selectedPeriodList[index].Period) {
      case DomainConstants.Period.Day:
        this.selectedPeriodList[index] = this.eventSubscriptionActionService.newUserClockedInPeriod(DomainConstants.Period.Day);
        break;
      case DomainConstants.Period.Week:
        this.selectedPeriodList[index] = this.eventSubscriptionActionService.newUserClockedInPeriod(DomainConstants.Period.Week);
        break;
      case DomainConstants.Period.Month:
        this.selectedPeriodList[index] = this.eventSubscriptionActionService.newUserClockedInPeriod(DomainConstants.Period.Month);
        break;
    }
  }
  cronExpressionChanged(event) {
    this.eventConfiguration.Schedule = event;
  }

  cronChangeByKey() {
    this.isCronValid = CronEditorService.cronIsValid(this.cronOptions.cronFlavor, this.eventConfiguration.Schedule);
    const t = setTimeout(() => {
      this.isHideScheduleExpressionEdit = !this.isHideScheduleExpressionEdit;
      clearTimeout(t);
    });
  }

}
