<pos-edit-wrapper (close)="onCancel()" heading="Schedule Override">
    <form #scheduleForm="ngForm" (ngSubmit)='saveSchedule(scheduleForm.valid)'>
        <div class="col-12 px-4">
            <pos-row>
                <pos-column>
                    <pos-form-field label="From" [validationMessages]="{required: 'Please enter from date.'}">
                        <p-calendar posInput inputStyleClass="form-control" [showIcon]="true" appendTo="body" name="fromDate"
                        [dateFormat]="dateFormat" [(ngModel)]="currentSchedule.StartDateTime" [required]="true"
                        [monthNavigator]="true" (onSelect)="onDateChange()" (onInput)="onDateChange()" [showTime]="true" [minDate]="currentDate" [hourFormat]="12"
                        yearNavigator="true" yearRange="2000:2099"></p-calendar>
                    </pos-form-field>
                </pos-column>

                <pos-column>
                    <pos-form-field label="To" [validationMessages]="{required: 'Please enter to date.'}" [inputStyle]="{'col-xs-12': true,'has-error': scheduleForm.submitted && (inValidFromDate || inValidToDate)}">
                        <p-calendar posInput inputStyleClass="form-control" [showIcon]="true" appendTo="body" name="toDate"
                            [dateFormat]="dateFormat" [(ngModel)]="currentSchedule.EndDateTime" [monthNavigator]="true" [showTime]="true" [hourFormat]="12"
                            [required]="true" [minDate]="currentSchedule.StartDateTime" (onSelect)="onDateChange()"
                            (onInput)="onDateChange()" yearNavigator="true" yearRange="2000:2099">
                        </p-calendar>
                        <span *ngIf="(inValidFromDate || inValidToDate) && scheduleForm.submitted">
                            <label class="control-label">Period overlaps with another override ({{conflictingSchedule?.StartDateTime | tark_date_time}}
                                to {{conflictingSchedule?.EndDateTime | tark_date_time}}) </label>
                        </span>
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" scrollup value="Save">Save</button>
            <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" value="Cancel"
                (click)="onCancel()">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
