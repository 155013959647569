import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { BaseFormComponent } from 'src/app/shared/components/base-form.component';
import { confirmationScreenSolid } from 'src/app/shared/components/icon/icons';
import { SpinnerService } from 'src/app/shared/components/spinner';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { FontStyle } from 'src/app/shared/interface/font-style';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { FormUtilityService } from 'src/app/shared/services/form-utility.service';
import { ConfirmationScreenWorkflowParameter } from '../../interfaces/ConfirmationScreenWorkflowParameter';
import { ConfirmationScreenWorkflowService } from '../../services/confirmation-screen.service';
import { PostSavedConfigurationService } from 'src/app/configurator/post-saved-configuration.service';

@Component({
  selector: 'pos-confirmation-screen',
  templateUrl: './confirmation-screen.component.html',
})
export class ConfirmationScreenComponent extends BaseFormComponent implements OnInit {
  @ViewChild('confirmationScreenWorkflowForm') confirmationScreenWorkflowForm: NgForm;
  get getForm(): NgForm {
    return this.confirmationScreenWorkflowForm
  }
  confirmationScreenWorkflowParameter: ConfirmationScreenWorkflowParameter;
  confirmationScreenDisplayImageHeaderStyle: FontStyle;
  confirmationScreenDisplayImageProductStyle: FontStyle;
  icons = {
    confirmationScreenSolid
  };
  constructor(
    private confirmationScreenWorkflowService: ConfirmationScreenWorkflowService,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private router: Router,
    private route: ActivatedRoute,
    formUtilityService: FormUtilityService,
    private postSavedConfigurationService: PostSavedConfigurationService
  ) {
    super(formUtilityService);
  }

  ngOnInit(): void {
    this.confirmationScreenWorkflowParameter = this.confirmationScreenWorkflowService.newConfirmationScreenWorkflowConfigurationParameter();
    this.confirmationScreenDisplayImageHeaderStyle = this.confirmationScreenWorkflowService.newFontStyle();
    this.confirmationScreenDisplayImageProductStyle = this.confirmationScreenWorkflowService.newFontStyle();
    this.loadData();
  }

  private loadData() {
    this.spinnerService.show();
    this.confirmationScreenWorkflowService.getConfirmationScreenWorkflowParameters()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (params: ConfirmationScreenWorkflowParameter) => {
          this.confirmationScreenWorkflowParameter = params;
          this.confirmationScreenDisplayImageHeaderStyle =
            JSON.parse(
              this.confirmationScreenWorkflowParameter
                .ConfirmationScreenDisplayImageHeaderStyle
            ) ?? this.confirmationScreenWorkflowService.newFontStyle();
          this.confirmationScreenDisplayImageProductStyle =
            JSON.parse(
              this.confirmationScreenWorkflowParameter
                .ConfirmationScreenDisplayImageProductStyle
            ) ?? this.confirmationScreenWorkflowService.newFontStyle();
        },
        error: this.alertService.showApiError
      });
  }

  saveConfirmationScreenWorkflow(isValid: boolean) {

    this.confirmationScreenWorkflowParameter.ConfirmationScreenDisplayImageSeconds =
      this.confirmationScreenWorkflowParameter
        .ConfirmationScreenDisplayImageSeconds ?? 0;
    this.confirmationScreenWorkflowParameter.ConfirmationScreenDisplayImageTransitionSeconds =
      this.confirmationScreenWorkflowParameter
        .ConfirmationScreenDisplayImageTransitionSeconds ?? 0;

    const imageHeaderFontSizes = Number(this.confirmationScreenDisplayImageHeaderStyle.fontSize) > 0
      ? this.confirmationScreenDisplayImageHeaderStyle.fontSize + 'px'
      : null;
    this.confirmationScreenDisplayImageHeaderStyle.fontSize = isNaN(Number(this.confirmationScreenDisplayImageHeaderStyle.fontSize))
      ? this.confirmationScreenDisplayImageHeaderStyle.fontSize
      : imageHeaderFontSizes;

    const imageProductFontSizes = Number(this.confirmationScreenDisplayImageProductStyle.fontSize) > 0
      ? this.confirmationScreenDisplayImageProductStyle.fontSize + 'px'
      : null;
    this.confirmationScreenDisplayImageProductStyle.fontSize = isNaN(Number(this.confirmationScreenDisplayImageProductStyle.fontSize))
      ? this.confirmationScreenDisplayImageProductStyle.fontSize
      : imageProductFontSizes

    this.confirmationScreenWorkflowParameter.ConfirmationScreenDisplayImageHeaderStyle =
      JSON.stringify(this.confirmationScreenDisplayImageHeaderStyle);
    this.confirmationScreenWorkflowParameter.ConfirmationScreenDisplayImageProductStyle =
      JSON.stringify(this.confirmationScreenDisplayImageProductStyle);

    this.spinnerService.show();
    this.confirmationScreenWorkflowService.updateConfirmationScreenWorkflowParameters(this.confirmationScreenWorkflowParameter)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      })).subscribe({
        next: () => {
          this.alertService.renderSuccessMessage(Messages.ConfirmationScreenWorkflowSaveSuccess);
          this.postSavedConfigurationService.showMessage();
        }, error: this.alertService.showApiError
      });
  }

  cancel() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
