<pos-row colXs="6" class="pos-title-content">
    <pos-column>
        <pos-form-field label="Border Top">
            <input posInput type="text" name="{{prefix}}_borderTop" class="form-control col-120" [(ngModel)]="borderStyle.borderTop"/>
        </pos-form-field>
    </pos-column>
    <pos-column>
        <pos-form-field label="Border Bottom">
            <input posInput type="text" name="{{prefix}}_borderBottom" class="form-control col-120" [(ngModel)]="borderStyle.borderBottom"/>
        </pos-form-field>
    </pos-column>
    <pos-column>
        <pos-form-field label="Border Left">
            <input posInput type="text" name="{{prefix}}_borderLeft" class="form-control col-120" [(ngModel)]="borderStyle.borderLeft"/>
        </pos-form-field>
    </pos-column>
    <pos-column>
        <pos-form-field label="Border Right">
            <input posInput type="text" name="{{prefix}}_borderRight" class="form-control col-120" [(ngModel)]="borderStyle.borderRight"/>
        </pos-form-field>
    </pos-column>
    <pos-column>
        <pos-form-field label="Border Style">
            <ng-select posInput name="{{prefix}}_borderStyle" bindLabel="Key" bindValue="Value"
                [(ngModel)]="borderStyle.borderStyle" [items]="BorderStyleList" [clearOnBackspace]="false"
                appendTo="body"></ng-select>
        </pos-form-field>
    </pos-column>
    <pos-column>
        <pos-form-field label="Border Color">
            <pos-color-picker posInput name="{{prefix}}_borderColor" [(ngModel)]="borderStyle.borderColor"></pos-color-picker>
        </pos-form-field>
    </pos-column>
    <pos-column>
        <pos-form-field label="Border Radius">
            <input posInput type="text" name="{{prefix}}_borderRadius" class="form-control" [(ngModel)]="borderStyle.borderRadius"/>
        </pos-form-field>
    </pos-column>
</pos-row>
