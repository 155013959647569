<pos-edit-wrapper heading="{{id > 0 ? 'Edit' : 'Add'}} Dashboard Data Source{{id ? ': '+dataSourceName : ''}}"
    [headingIcon]="icons.database" (close)="onCancel()" [isPopup]="false">
    <form #dataSourceForm="ngForm" (ngSubmit)="submit(dataSourceForm.valid)">
        <div class="col-xs-12 border-top content-scroll">
            <div class="col-xs-12 pos-page-body mb-pix-2">
                <h3 class="setting-title mt-pix-5">Data Source</h3>
                <context-provider provide="props">
                    <pos-row class="pos-title-content">
                        <pos-column>
                            <pos-form-field label="Name" [validationMessages]="{required: 'Please enter name.'}">
                                <input posInput name="name" #name="ngModel" type="text" class="form-control"
                                    [(ngModel)]="dataSource.Name" [required]="true" />
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Output Type"
                                [validationMessages]="{required: 'Please select output type.'}">
                                <ng-select posInput name="outputType" #outputType="ngModel" appendTo="body"
                                    placeholder="Select output type" [(ngModel)]="dataSource.OutputType"
                                    [required]="true" [items]="outputTypes" bindLabel="Name"
                                    bindValue="Value"></ng-select>
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Source Type"
                                [validationMessages]="{required: 'Please select source type.'}">
                                <ng-select posInput name="sourceType" #sourceType="ngModel" appendTo="body" disabled
                                    placeholder="Select source type" [(ngModel)]="dataSource.SourceType"
                                    [required]="true" [items]="sourceTypes" bindLabel="Name"
                                    bindValue="Value"></ng-select>
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Stored Procedure"
                                [validationMessages]="{required: 'Please enter stored procedure.'}">
                                <input posInput name="storedProcedure" #storedProcedure="ngModel" type="text"
                                    class="form-control" [(ngModel)]="dataSource.StoredProcedure" [required]="true" />
                            </pos-form-field>
                        </pos-column>

                        <pos-column class="overflow-auto pb-2">
                            <pos-row class="pt-pix-10 flex-nowrap" colXs="2" colStyleClass="theme-separator">
                                <pos-column class="col-170 theme-separator">
                                    <strong>Parameter</strong>
                                </pos-column>

                                <pos-column class="col-170 theme-separator">
                                    <strong>Parameter Label</strong>
                                </pos-column>

                                <pos-column class="col-170 theme-separator">
                                    <strong>Value</strong>
                                </pos-column>

                                <pos-column class="col-170 theme-separator">
                                    <strong>Data Type</strong>
                                </pos-column>

                                <pos-column class="col-121 theme-separator">
                                    <strong>User Defined</strong>
                                </pos-column>
                            </pos-row>
                            <pos-row colXs="2" class="flex-nowrap"
                                *ngFor="let parameter of dataSource.DataSourceParameter; let last = last; let index = index;">
                                <pos-column class="col-170">
                                    <pos-form-field [validationMessages]="{required: 'Please enter name.'}"
                                        inputStyle="col-xs-12">
                                        <input posInput type="text" class="form-control" #parameterName="ngModel"
                                            (keypress)="createParameterRow(last)" name="parameterName{{index}}"
                                            [(ngModel)]="parameter.ParameterName"
                                            [required]="index !== (dataSource.DataSourceParameter.length - 1)" />
                                    </pos-form-field>
                                </pos-column>

                                <pos-column class="col-170">
                                    <pos-form-field [validationMessages]="{required: 'Please enter label.'}"
                                        inputStyle="col-xs-12">
                                        <input posInput type="text" class="form-control" #ParameterLabel="ngModel"
                                            name="ParameterLabel{{index}}" (keypress)="createParameterRow(last)"
                                            [required]="index !== (dataSource.DataSourceParameter.length - 1)"
                                            [(ngModel)]="parameter.ParameterLabel" />
                                    </pos-form-field>
                                </pos-column>

                                <pos-column class="col-170">
                                    <pos-form-field inputStyle="col-xs-12">
                                        <input posInput type="text" class="form-control" #parameterValue="ngModel"
                                            name="parameterValue{{index}}" (keypress)="createParameterRow(last)"
                                            [(ngModel)]="parameter.ParameterDefaultValue" />
                                    </pos-form-field>
                                </pos-column>

                                <pos-column class="col-170">
                                    <pos-form-field [validationMessages]="{required: 'Please enter data type.'}"
                                        inputStyle="col-xs-12">
                                        <ng-select posInput name="dataType{{index}}" #dataType="ngModel" appendTo="body"
                                            (change)="createParameterRow(last)" placeholder="Select data type"
                                            [(ngModel)]="parameter.DataType" [items]="dataTypes" bindLabel="Text"
                                            [required]="index !== (dataSource.DataSourceParameter.length - 1)"
                                            bindValue="Value"></ng-select>
                                    </pos-form-field>
                                </pos-column>

                                <pos-column class="col-121">
                                    <pos-form-field inputStyle="col-xs-12">
                                        <p-checkbox posInput name="chkIsUserDefined{{index}}"
                                            #chkIsUserDefined="ngModel" (change)="createParameterRow(last)"
                                            binary="true" class="pt-pix-8 ps-pix-33"
                                            [(ngModel)]="parameter.IsUserDefined">
                                        </p-checkbox>
                                    </pos-form-field>
                                </pos-column>

                                <pos-column class="pt-pix-12 col-50" *ngIf="index !== (dataSource.DataSourceParameter.length - 1)">
                                    <span (click)="deleteParameterRow(index)"
                                        class="pr-2"
                                        tooltip title="Delete">
                                        <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
                                    </span>
                                </pos-column>
                            </pos-row>
                        </pos-column>
                    </pos-row>
                </context-provider>
            </div>

            <div class="clearfix"></div>
        </div>
        <div class="col-xs-12 action-button-container page-footer">
            <div class="form-horizontal">
                <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" value="Save">
                    Save
                </button>
                <button type="button" class="btn-secondary btn-lg btn btn-cancel pos-button" value="Cancel"
                    (click)="onCancel()">
                    Cancel
                </button>
            </div>
        </div>
    </form>
</pos-edit-wrapper>
