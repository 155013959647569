import { Injectable, Type } from '@angular/core';
import { ModalService, Permissions, Levels, DomainConstants, AlertsService, SpinnerService, ConfirmDeleteModalComponent, Messages } from 'src/app/shared';
import { Observable, Subject } from 'rxjs';
import { MenuOptions } from '../interface/button-menu';
import { ButtonEditComponent } from 'src/app/information-management';
import { editScreenSetup, clone, menuSetupSearch, times, desktop, book, trashScreenSetup, paste, unlinkEmbeddedScreen } from 'src/app/shared/components/icon';
import { MenuActions } from '../constants/menuAction';
import { ChooseNextScreenComponent } from '../choose-next-screens';
import { OrderService } from '../../shared/services/order.service';
import { finalize } from 'rxjs/operators';
import { ScreenContainingListComponent } from '../screen-containing-list/screen-containing-list.component';
import { InitStateParam } from '../interface/init-state-param';
import { SetProductForButtonComponent } from '../set-product-for-button/set-product-for-button.component';
import { OrderNavigationButton } from '../interface/order-navigation-button';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { EmbedScreenService } from '../order-entry';
import { ScreenButtons } from '../interface';

@Injectable({
  providedIn: 'root',
})

export class MenuResolveFactoryService {
  private dataSubject = new Subject<any>();
  constructor(private modalService: ModalService,
    private orderService: OrderService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private embedScreenService: EmbedScreenService
  ) { }


  public get modalObserver$(): Observable<any> {
    return this.dataSubject.asObservable();
  }

  menuActionExecutor(menuOption: MenuOptions, button: OrderNavigationButton, screenButtons: Array<OrderNavigationButton>, allButtons: Array<ScreenButtons>) {
    if (menuOption.Action === MenuActions.Modal) {
      const initStateParams = this.getComponentConfig(menuOption, button, screenButtons, allButtons);
      this.openModal(menuOption.Component, initStateParams);
    } else if (menuOption.Action === MenuActions.Api) {
      this.apiExecutor(menuOption, button);
    }
  }

  private openModal<T>(component: Type<T>, initStateParams?: InitStateParam): void {

    const modalRef = this.modalService.getModalWrapper(component);
    const modal = modalRef.show({
      animated: false,
      keyboard: false,
      class: 'vertical-center ' + (initStateParams && initStateParams.AdditionalClasses ? initStateParams.AdditionalClasses : ''),
      initialState: initStateParams.Data
    });
    modal.close.subscribe(res => {
      this.dataSubject.next(res ? res : {});
    });

  }

  private apiExecutor(menuOption: MenuOptions, button: OrderNavigationButton): void {
    if (menuOption.Menu === DomainConstants.ButtonMenu.DuplicateButtonOnScreen) {
      this.duplicateButtonOnScreen(button);
    } else if (menuOption.Menu === DomainConstants.ButtonMenu.RemoveButtonFromScreen) {
      this.removeButtonFromScreen(button);
    } else if (menuOption.Menu === DomainConstants.ButtonMenu.RemoveProductFromButton) {
      this.removeProductFromButton(button);
    } else if (menuOption.Menu === DomainConstants.ButtonMenu.RemoveEmbeddedScreen) {
      this.removeEmbeddedScreen(button);
    }
  }

  private getComponentConfig(menuOption: MenuOptions, data: OrderNavigationButton, screenButtons: Array<OrderNavigationButton>, allButtons: Array<ScreenButtons>): InitStateParam {
    let initStateParams: InitStateParam;
    if (menuOption.Menu === DomainConstants.ButtonMenu.ButtonEdit) {
      initStateParams = {
        Data: {
          id: data.Id,
          screenId: data.ScreenId,
          buttonName: data.ButtonText,
          screenButtons: screenButtons
        },
        AdditionalClasses: 'modal-lg modal-max-width-65',
        RequiredWrapper: false
      };
    } else if (menuOption.Menu === DomainConstants.ButtonMenu.DuplicateButtonOnScreen) {
      initStateParams = null;
    } else if (menuOption.Menu === DomainConstants.ButtonMenu.ChooseExistingNextScreen) {
      initStateParams = {
        Data: {
          buttonInfo: data,
          allButtons: allButtons
        },
        AdditionalClasses: 'modal-lg'
      };
    } else if (menuOption.Menu === DomainConstants.ButtonMenu.FindScreenContainingThisButton) {
      initStateParams = {
        Data: {
          buttonId: data.Id,
          buttonName: data.ButtonText
        }
      };
    } else if (menuOption.Menu === DomainConstants.ButtonMenu.SetProduct) {
      initStateParams = {
        Data: {
          buttonId: data.Id,
          productId: data.SalesProductId
        }
      };
    } else if (menuOption.Menu === DomainConstants.ButtonMenu.CopyToNewButton) {
      initStateParams = {
        Data: {
          id: data.Id,
          screenId: data.EmbeddedScreenId ? data.EmbeddedScreenId : data.ScreenId,
          buttonName: data.ButtonText,
          isCopyButton: true,
          selectedScreen: data.NextScreenId
        },
        AdditionalClasses: 'modal-lg modal-max-width-65',
        RequiredWrapper: false
      };
    }
    return initStateParams;
  }

  getButtonMenu(): Array<MenuOptions> {
    return [
      {
        Menu: DomainConstants.ButtonMenu.EmbeddedScreenHeading,
        Name: 'Embedded Screen',
        Permission: "[]",
        Icon: null,
        Action: MenuActions.Heading
      },
      {
        Menu: DomainConstants.ButtonMenu.ButtonEdit,
        Name: 'Edit Button',
        Permission: `[{ "Name": "${Permissions.UIButtons}", "Level": "${Levels.Edit}" }]`,
        Icon: editScreenSetup,
        Component: ButtonEditComponent,
        Action: MenuActions.Modal
      },
      {
        Menu: DomainConstants.ButtonMenu.DuplicateButtonOnScreen,
        Name: 'Replicate Button',
        Permission: "[]",
        Icon: paste,
        Action: MenuActions.Api
      },
      {
        Menu: DomainConstants.ButtonMenu.FindScreenContainingThisButton,
        Name: 'List Screens Using this Button',
        Permission: "[]",
        Icon: menuSetupSearch,
        Component: ScreenContainingListComponent,
        Action: MenuActions.Modal
      },
      {
        Menu: DomainConstants.ButtonMenu.RemoveButtonFromScreen,
        Name: 'Remove Button',
        Permission: "[]",
        Icon: times,
        Action: MenuActions.Api
      },
      {
        Menu: DomainConstants.ButtonMenu.ChooseExistingNextScreen,
        Name: 'Choose Next Screen',
        Permission: "[]",
        Icon: desktop,
        Component: ChooseNextScreenComponent,
        Action: MenuActions.Modal
      },
      {
        Menu: DomainConstants.ButtonMenu.SetProduct,
        Name: 'Set Product',
        Permission: `[{ "Name": "${Permissions.UIButtons}", "Level": "${Levels.Edit}" }]`,
        Icon: book,
        Component: SetProductForButtonComponent,
        Action: MenuActions.Modal
      },
      {
        Menu: DomainConstants.ButtonMenu.RemoveProductFromButton,
        Name: 'Remove Product From Button',
        Permission: `[{ "Name": "${Permissions.UIButtons}", "Level": "${Levels.Edit}" }]`,
        Icon: trashScreenSetup,
        Action: MenuActions.Api
      },
      {
        Menu: DomainConstants.ButtonMenu.CopyToNewButton,
        Name: 'Copy To New Button',
        Permission: `[{ "Name": "${Permissions.UIButtons}", "Level": "${Levels.Edit}" }]`,
        Icon: clone,
        Component: ButtonEditComponent,
        Action: MenuActions.Modal
      },
      {
        Menu: DomainConstants.ButtonMenu.RemoveEmbeddedScreen,
        Name: 'Remove Embedded Screen',
        Permission: "[]",
        Icon: unlinkEmbeddedScreen,
        Action: MenuActions.Api
      }
    ];
  }

  private duplicateButtonOnScreen(button: OrderNavigationButton): void {
    this.spinnerService.show();
    this.orderService.duplicateButtonOnScreen(button.ScreenChoiceId).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (res: any) => {
        res.reload = true;
        this.dataSubject.next(res ? res : {});
      }, error: this.alertService.showApiError
    });
  }

  private removeButtonFromScreen(button: OrderNavigationButton): void {
    this.spinnerService.show();
    this.orderService.removeButtonFromScreen(button.ScreenId, button.ScreenChoiceId).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (res: any) => {
        res.reload = true;
        this.dataSubject.next(res ? res : {});
      }, error: this.alertService.showApiError
    });
  }

  private removeProductFromButton(button: OrderNavigationButton): void {
    const confirmRemoveProductFromButton = this.modalService.show(ConfirmDeleteModalComponent, {
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      initialState: {
        message: StringUtils.format(Messages.ConfirmRemoveProductFromButton, { 'buttonName': button.ButtonText })
      }
    });
    confirmRemoveProductFromButton.close.subscribe(res => {
      if (res && res.shouldDelete) {
        this.orderService.removeProductFromButton(button.Id).pipe(finalize(() => {
          this.spinnerService.hide();
        })).subscribe({
          next: (res: any) => {
            res.reload = true;
            this.dataSubject.next(res ? res : {});
            this.alertService.renderSuccessMessage(Messages.RemoveProductFromButtonSuccess);
          }, error: this.alertService.showApiError
        });
      }
    });
  }

  removeEmbeddedScreen(button: OrderNavigationButton) {
    this.embedScreenService.deleteEmbeddedScreen(button.EmbeddedScreenChoiceId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      })).subscribe({
        next: (res: any) => {
          res = res ?? {};
          res.reload = true;
          this.dataSubject.next(res ? res : {});
          this.alertService.renderSuccessMessage(Messages.EmbeddedScreenDeleted);
        }, error: this.alertService.showApiError
      });
  }
}
