import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Tag } from 'src/app/information-management/tags/interface/tags';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { BaseCrudService } from './base-crud.service';

@Injectable()
export class TagService extends BaseCrudService<Tag> {

    tagsApiBasePath = `${RuntimeConstants.API_PATH}tags`;

    constructor(httpService: HttpClient) {
        super('tags', httpService);
    }

    newTag() {
        const tagObj: Tag = {
            Id: 0,
            Name: '',
            Color: '',
            Icon: '',
            Category: null,
            IsSearchable: false,
            Ordinal: 0,
        };
        return tagObj;
    }

    addTag(tag, file: File) {
        const formData = this.prepareIconFormDataForTag(tag, file);
        return this.httpService.post(
            `${this.tagsApiBasePath}`,
            formData
        );
    }

    updateTag(tag, file: File) {
        const formData = this.prepareIconFormDataForTag(tag, file);
        return this.httpService.put(
            `${this.tagsApiBasePath}/${tag.Id}`,
            formData
        );
    }

    updateTagOrdinal(tags: Array<Tag>): Observable<any> {
        return this.httpService.put(`${this.tagsApiBasePath}/ordinal`, tags);
    }

    private prepareIconFormDataForTag(param, iconFile: File): FormData {
        const formData = new FormData();
        if (iconFile) {
            formData.append('file', iconFile);
        }
        formData.append('tag', JSON.stringify(param));
        return formData;
    }

    getTagCategories(): Observable<Array<string>> {
        return this.httpService.get<Array<string>>(`${this.tagsApiBasePath}/tag-categories`);
    }
}
