<button aria-label="Close" (click)="closeDialog();" class="close" type="button">&times;</button>
<h4 class="modal-content__heading">Message</h4>

<div class="modal-body pt-pix-10 pb-0">

  <div class="p-0 screen-scroll">
    <div class="col-12 p-1 row mx-0">
      <div class="col-sm-2 col-3 p-0">
        {{isSentMessage ? 'Receiver:':'Sender:'}}
      </div>
      <div class="col-sm-10 col-9 p-0">
        {{isSentMessage ? terminalMessageModel.Receiver : terminalMessageModel.Sender}}
      </div>
    </div>
    <div class="col-12 p-1 row mx-0">
      <div class="col-sm-2 col-3 p-0">
        Date & Time:
      </div>
      <div class="col-sm-10 col-9 p-0">
        {{terminalMessageModel.DateAdded | tark_date_time}}
      </div>
    </div>
    <div class="col-12 p-1 row mx-0">
      <div class="col-sm-2 col-3 p-0">
        Message:
      </div>
      <div class="col-sm-10 col-9 p-0">
        <span [innerHTML]="sanitizedHtml"></span>
      </div>
    </div>
  </div>
</div>
<div class="col-12 action-button-container popup-footer pb-pix-10">
  <button class="btn btn-primary btn-lg btn-save pos-button" *ngIf="isRequiredMarkAsRead && !isSentMessage"
    (click)="markAsReadOrUnread(terminalMessageModel)" type="button">
    {{terminalMessageModel.IsViewed? 'Mark as Unread' :'Mark as Read'}}</button>
  <button class="btn btn-primary btn-lg btn-save pos-button" (click)="replyMessage(terminalMessageModel);" type="button"
    *ngIf="isOrderEntryTypeTerminal && !terminalMessageModel.IsSystemMessage &&  !isSentMessage">Reply</button>
  <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="closeDialog();" type="button">Close</button>
</div>
<div class="clearfix"></div>
