import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { RuntimeConstants } from '../../constants/runtime-constants';
import { ApplicationStateService } from '../../services/application-state.service';
import { saveAs } from 'file-saver';
import { AlertsService } from '../../services/alerts.service';
import { AudioRecording } from 'src/app/manage-console';

@Injectable({
  providedIn: 'root'
})
export class AudioRecorderService {
  audioRecorderApiUrl = RuntimeConstants.API_PATH + 'audio-recorder';
  constructor(
    private applicationStateService: ApplicationStateService,
    private alertService: AlertsService,
    private httpService: HttpClient) {
  }

  tagAudioRecord(tagName: string) {
    return this.httpService.post(`${this.audioRecorderApiUrl}/tag/${tagName}`, this.applicationStateService.terminalId).subscribe();
  }

  untagAudioRecord() {
    return this.httpService.post(`${this.audioRecorderApiUrl}/untag`, this.applicationStateService.terminalId).subscribe();
  }

  getAllRecordings(): Observable<any> {
    return this.httpService.get(`${this.audioRecorderApiUrl}/${this.applicationStateService.terminalId}/recordings`);
  }

  getAllRecordingsByHardware(hardwareId: number): Observable<any> {
    return this.httpService.get(`${this.audioRecorderApiUrl}/${this.applicationStateService.terminalId}/recordings/${hardwareId}`);
  }

  getRecordingsForOrder(orderId: number, rabbitMQRequestId: string): Observable<number> {
    return this.httpService.post<number>(`${this.audioRecorderApiUrl}/recordings/order/${orderId}`, { OrderId: orderId, RequestId: rabbitMQRequestId, TerminalId: this.applicationStateService.terminalId });
  }

  getRecording(data: AudioRecording, rabbitMQRequestId: string): Observable<any> {
    return this.httpService.post(`${this.audioRecorderApiUrl}/${this.applicationStateService.terminalId}/recording`, { TagName: data.Name.replace('.wav', ""), RequestId: rabbitMQRequestId, HardwareId: data.HardwareId ?? null });
  }

  deleteCapturedAudio(fileName: string) {
    return this.httpService.delete(`${this.audioRecorderApiUrl}/captured-audio/${fileName}`);
  }

  download(url: string, fileName: string) {
    this.httpService.get(url, { responseType: 'blob' })
      .subscribe({
        next: (blob: Blob) => {
          saveAs(blob, fileName);
        },
        error: this.alertService.showApiError,
        complete: () => {
          this.deleteCapturedAudio(fileName).subscribe();
        }
      });
  }
}
