<pos-edit-wrapper (close)="onCancel()" [isPopup]="false"
  heading="Work Schedule Tags">
    <div class="col-12 border-top">
        <div class="col-12 px-3">
            <h3 class="setting-title mt-pix-5">Tags</h3>
            <div class="pt-pix-10">
                <div class="table-responsive">
                    <ng-table [sort]="true" [columns]="workScheduleTagColumns" [data]="tags"
                        [paginator]="false" [scrollable]="true" [scrollHeight]="screenHeight.toString()"
                        [search]="false" [exportFilename]="'work-schedule-tags'">
                    </ng-table>
                    <ng-template let-data="rowData" #colorTemplate>
                        <div class="table-color-column" [ngStyle]="{'background-color': data.Color}">
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 action-button-container page-footer">
        <div class="form-horizontal">
            <button class="btn-secondary btn-lg btn btn-cancel pos-button" (click)="onCancel()" type="button">
                Cancel
            </button>
        </div>
    </div>
</pos-edit-wrapper>
<pos-print-table [hidden]="true"></pos-print-table>
