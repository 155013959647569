<pos-row colSm="6" colXs="12" class="pos-title-content">
    <pos-column>
        <pos-form-field label="Line Height">
            <input posInput name="{{prefix}}_lineHeight" type="text" class="form-control"
                [(ngModel)]="generalStyle.lineHeight" />
        </pos-form-field>
    </pos-column>

    <pos-column>
        <pos-form-field label="Padding">
            <input posInput name="{{prefix}}_padding" type="text" class="form-control"
                [(ngModel)]="generalStyle.padding" />
        </pos-form-field>
    </pos-column>

    <pos-column>
        <pos-form-field label="Height">
            <input posInput name="{{prefix}}_height" type="text" class="form-control"
                [(ngModel)]="generalStyle.height" />
        </pos-form-field>
    </pos-column>

    <pos-column>
        <pos-form-field label="Margin">
            <input posInput name="{{prefix}}_margin" type="text" class="form-control"
                [(ngModel)]="generalStyle.margin" />
        </pos-form-field>
    </pos-column>

    <pos-column>
        <pos-form-field label="Display">
            <input posInput name="{{prefix}}_display" type="text" class="form-control"
                [(ngModel)]="generalStyle.display" />
        </pos-form-field>
    </pos-column>

    <pos-column>
        <pos-form-field label="Background Color">
            <pos-color-picker posInput name="{{prefix}}_backgroundColor" [(ngModel)]="generalStyle.backgroundColor">
            </pos-color-picker>
        </pos-form-field>
    </pos-column>

    <pos-column>
        <pos-form-field label="Color">
            <pos-color-picker posInput name="{{prefix}}_color" [(ngModel)]="generalStyle.color">
            </pos-color-picker>
        </pos-form-field>
    </pos-column>

    <pos-column>
        <pos-form-field label="Border">
            <input posInput name="{{prefix}}_border" type="text" class="form-control"
                [(ngModel)]="generalStyle.border" />
        </pos-form-field>
    </pos-column>

</pos-row>
