import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { cloneDeep, Dictionary, filter, find, forEach, groupBy, isEqual, orderBy, remove } from 'lodash';
import { finalize } from 'rxjs/internal/operators/finalize';
import { OrderDietaryRestriction, OrderInvoice, OrderInvoiceOrderProduct } from 'src/app/orders';
import { SettleSeatService } from 'src/app/orders/order-entry/settle/services/settle-seat.service';
import { AlertsService, ApplicationStateService, DomainConstants, OrderService, RuntimeConstants, SettingParam, SpinnerService } from 'src/app/shared';
import { check } from 'src/app/shared/components/icon';

@Component({
  selector: 'pos-order-summary-products',
  templateUrl: './order-summary-products.component.html',
  styleUrls: ['./order-summary-products.component.scss', '../../../styles/pages/design/settle.scss']
})
export class OrderSummaryProductsComponent implements OnInit, OnChanges {

  @Input() orderData: OrderInvoice;
  @Input() isShowIcon = false;
  @Input() isShowSeatTotal = false;
  @Input() isSelectableProduct?: boolean;
  @Output() changeProductSelection: EventEmitter<OrderInvoiceOrderProduct> = new EventEmitter<OrderInvoiceOrderProduct>();
  @Input() seats: any = [];
  settingParam: SettingParam;
  subAccountTerm: string;
  orderProductStatus = RuntimeConstants;
  orderItemTypes = DomainConstants.OrderItemTypes;
  dietaryRestrictions: Dictionary<Array<OrderDietaryRestriction>>;
  isDietaryRestrictionsAdded = false;
  icons = {
    check
  };

  constructor(private applicationStateService: ApplicationStateService,
    private settleMarkupAndDiscountService: SettleSeatService,
    private orderService: OrderService,
    private spinnerService: SpinnerService,
    private alertService: AlertsService
  ) {
  }

  ngOnInit(): void {
    this.settingParam = this.applicationStateService.settingParam;
    this.subAccountTerm = this.settingParam.SubaccountTerm ? this.settingParam.SubaccountTerm : 'Seat';
  }

  originalOrder = (firstKey: KeyValue<number, string>, secondKey: KeyValue<number, string>): number => {
    return 0;
  }

  ngOnChanges() {
    this.isDietaryRestrictionsAdded = false;
    if (this.orderData) {
      // this.seats = groupBy(this.orderData.OrderItems, 'SubAccountOrdinal');
      if (this.orderData.DietaryRestrictions && this.orderData.DietaryRestrictions.length) {
        this.isDietaryRestrictionsAdded = true;
      }
      this.dietaryRestrictions = groupBy(this.orderData.DietaryRestrictions, 'SubaccountOrdinal');
      if (!this.seats || !this.seats.length) {
        this.prepareSeatObj();
      }
    }
  }

  prepareSeatObj() {
    if (this.orderData.Id.Value != 0) {
      this.spinnerService.show();
      this.orderService
        .getSeatWiseOrderDetails(this.orderData.Id.Value)
        .pipe(
          finalize(() => {
            this.spinnerService.hide();
          })
        )
        .subscribe({
          next: (res: Array<OrderInvoice>) => {
            this.seats = res ? res : [];

          }, error: this.alertService.showApiError
        });
    }
  }

  selectProduct = (product: OrderInvoiceOrderProduct) => {
    if (this.isSelectableProduct) {
      product.IsSelected = !product.IsSelected;
      forEach(this.orderData.OrderItems, (item) => {
        if (item.Id.Value !== product.Id.Value) {
          item.IsSelected = false;
        }
      });
      forEach(this.seats, (seat) => {
        forEach(seat.OrderItems, (orderItem) => {
          if (orderItem.Id.Value !== product.Id.Value) {
            orderItem.IsSelected = false;
          }
        });
      });
      this.changeProductSelection.emit(product);
    }
  }
}
