import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ColorUtilityService {

  constructor() { }

  convertHexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    var rgb = result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;

    return rgb;
  };

  convertEnglishColorNameToRgba(colorName) {
    // Returns the color as an array of [r, g, b, a] -- all range from 0 - 255
    // color must be a valid canvas fillStyle. This will cover most anything
    // you'd want to use.
    // Examples:
    // colorToRGBA('red')  # [255, 0, 0, 255]
    // colorToRGBA('#f00') # [255, 0, 0, 255]
    var cvs, ctx;
    cvs = document.createElement('canvas');
    cvs.height = 1;
    cvs.width = 1;
    ctx = cvs.getContext('2d');
    ctx.fillStyle = colorName;
    ctx.fillRect(0, 0, 1, 1);
    return ctx.getImageData(0, 0, 1, 1).data;
  };

  getContrastColor = function (color) {
    if (color == null || !color)
      return '#000000'; //black;

    //If color isn't in rgb format already
    if (!Array.isArray(color)) {
      if (color[0] == '#') {
        //Color is in hex format
        color = this.convertHexToRgb(color);
      }
      else {
        //English color name
        color = this.convertEnglishColorNameToRgba(color);
      }
    }

    var o = Math.round(((parseInt(color[0]) * 299) + (parseInt(color[1]) * 587) + (parseInt(color[2]) * 114)) / 1000);

    if (o > 125) {
      //$('#bg').css('color', 'black');
      return 'black';
    } else {
      // $('#bg').css('color', 'white');
      return 'white';
    }
  };

  byteToHex(num) {
    // Turns a number (0-255) into a 2-character hex number (00-ff)
    return ('0' + num.toString(16)).slice(-2);
  }

  lightenDarkenColor(col, amt) {
    if (!col) {
      return col;
    }
    if (col[0] == "#") {
      col = col.slice(1);
    } else {
      col = this.colorToHex(col);
      col = col.slice(1);
    }
    var num = parseInt(col, 16);
    var r = (num >> 16) + amt;
    if (r > 255) r = 255;
    else if (r < 0) r = 0;
    var b = ((num >> 8) & 0x00FF) + amt;
    if (b > 255) b = 255;
    else if (b < 0) b = 0;
    var g = (num & 0x0000FF) + amt;
    if (g > 255) g = 255;
    else if (g < 0) g = 0;
    return "#" + (g | (b << 8) | (r << 16)).toString(16);

  }

  colorToHex(color) {
    // Convert any CSS color to a hex representation
    // Examples:
    // colorToHex('red')            # '#ff0000'
    // colorToHex('rgb(255, 0, 0)') # '#ff0000'
    var rgba, hex;
    rgba = this.convertEnglishColorNameToRgba(color);
    hex = [0, 1, 2].map(
      (idx) => { return this.byteToHex(rgba[idx]); }
    ).join('');
    return "#" + hex;
  }

  convertColorToRgba(color, opacity = 1) {
    if (color) {
      if (color.startsWith('#')) {
        const rgbColor = this.convertHexToRgb(color);
        return rgbColor ? `rgba(${rgbColor[0]}, ${rgbColor[1]}, ${rgbColor[2]}, ${opacity ?? 1})` : color;
      } else {
        const rgbColor = this.convertEnglishColorNameToRgba(color);
        return rgbColor ? `rgba(${rgbColor[0]}, ${rgbColor[1]}, ${rgbColor[2]}, ${opacity ?? 1})` : color;
      }
    }
  }
}
