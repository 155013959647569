import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pos-display-time',
  templateUrl: './display-time.component.html',
  styleUrls: ['./display-time.component.scss']
})
export class DisplayTimeComponent implements OnInit {
  currentTime = new Date().getTime();
  constructor() { }

  ngOnInit(): void {
    this.setCurrentTimeInterval();
  }

  setCurrentTimeInterval() {
    setInterval(() => {
      this.currentTime = new Date().getTime();
    }, 60000);
  }

}
