<div class="m-0-auto list-width-70">
  <pos-edit-wrapper
    heading="{{ dataSourceSubscribe.Id ? 'Edit' : 'Add' }} Subscription{{ dataSourceSubscribe.Id ? ': ' + dataSourceName : '' }}"
    (close)="onCancel()" [headingIcon]="icons.rssSquare" [isPopup]="false">
    <form #dataSourceSubscribeForm="ngForm" class="edit-form" (ngSubmit)="submit(dataSourceSubscribeForm.valid)">
      <context-provider provide="props">
        <div class="col-12 border-top pt-pix-5">
          <div class="pos-page-body content-scroll">
            <pos-row>
              <pos-column>
                <pos-form-field label="Data Source" [validationMessages]="{required: 'Please select data source.'}"
                  inputStyle="col-lg-4 col-md-6 col-sm-10 col-12">
                  <ng-select posInput appendTo="body" [items]="dataSource" bindLabel="Name" bindValue="Id"
                    placeholder="Select data source" (change)="onDataSourceChange()"
                    [(ngModel)]="dataSourceSubscribe.DataSourceId" id="dataSource" name="dataSource" [required]="true">
                  </ng-select>
                </pos-form-field>
              </pos-column>
              <pos-column>
                <pos-form-field label="Export Type">
                  <div class="btn-group">
                    <label class="btn btn-default btn-radio col-75 text-shadow-none"
                      [ngClass]="dataSourceSubscribe.ExportType === exportTypes.Auto ? 'active btn-primary normal': 'btn-secondary normal'">
                      <input [(ngModel)]="dataSourceSubscribe.ExportType" [value]="exportTypes.Auto" type="radio"
                        name="autoExport">
                      Auto
                    </label>
                    <label class="btn btn-default btn-radio col-75 text-shadow-none"
                      [ngClass]="dataSourceSubscribe.ExportType === exportTypes.Manual ? 'active btn-primary normal': 'btn-secondary normal'">
                      <input [(ngModel)]="dataSourceSubscribe.ExportType" [value]="exportTypes.Manual" type="radio"
                        name="manualExport">
                      Manual
                    </label>
                  </div>
                </pos-form-field>
              </pos-column>
              <pos-column>
                <pos-form-field label="Schedule" inputStyle="col-12">
                  <app-cron-editor [cronStartingValue]="dataSourceSubscribe.Schedule" [options]="cronOptions"
                    (cronChange)="seeCron($event)">
                  </app-cron-editor>
                  <span *ngIf="!isValidCron && dataSourceSubscribeForm.submitted">
                    <label class="red-extreme">Please enter valid cron expression.</label>
                  </span>
                </pos-form-field>
              </pos-column>

              <pos-column>
                <pos-form-field label="Export Format" inputStyle="row col-12"
                  [validationMessages]="{required: 'Please select export format.'}">
                  <pos-column  class="col-200">
                    <ng-select posInput [items]="exportFormats" placeholder="Select export format"
                      #exportFormat="ngModel" bindLabel="Name" bindValue="Name" appendTo="body" [clearable]="false"
                      
                      [(ngModel)]="dataSourceSubscribe.ExportFormat" name="format" [required]="true"></ng-select>
                  </pos-column>
                  <pos-column sm="6" xs="12" class="pt-pix-7" *ngIf="dataSourceSubscribe.ExportFormat == 'JSON'">
                    <p-checkbox name="chkIsSourceReturnsJson" #chkIsSourceReturnsJson="ngModel"
                      [(ngModel)]="dataSourceSubscribe.IsSourceReturnsJson" binary="false" label="Source Returns JSON">
                    </p-checkbox>
                  </pos-column>
                </pos-form-field>
              </pos-column>

              <pos-column>
                <pos-form-field label="Filename" inputStyle="row">
                  <pos-column lg="4" md="6" sm="12" xs="12">
                    <input posInput type="text" name="exportScriptFileName" #exportScriptFileName="ngModel"
                      class="form-control" [(ngModel)]="dataSourceSubscribe.FileName" />
                  </pos-column>
                  <pos-column lg="4" md="6" sm="6" xs="12" class="pt-pix-7">
                    <p-checkbox name="chkIsAppendTimeStampToFileName" #chkIsAppendTimeStampToFileName="ngModel"
                      [(ngModel)]="dataSourceSubscribe.IsAppendTimeStampToFileName" binary="true"
                      label="Append timestamp">
                    </p-checkbox>
                  </pos-column>
                </pos-form-field>
                <div class="light-grey italic" *ngIf="dataSourceSubscribe.IsAppendTimeStampToFileName">
                  (ex: {{dataSourceSubscribe.FileName ? dataSourceSubscribe.FileName :
                  dataSourceName}}-{{currentTimeStamp}}{{this.dataSourceSubscribe?.ExportFormat ? "." +
                  this.dataSourceSubscribe?.ExportFormat : "" }})
                </div>
              </pos-column>

              <pos-column>
                <pos-form-field inputStyle="row">
                  <p-checkbox posInput class="col-100 mb-1" name="chkActive" #chkActive="ngModel" [(ngModel)]="dataSourceSubscribe.IsActive"
                    binary="true" label="Active">
                  </p-checkbox>
                  <p-checkbox posInput class="col-200" name="chkSendEmptyResults" #chkSendEmptyResults="ngModel"
                    [(ngModel)]="dataSourceSubscribe.SendEmptyResults" binary="true" label="Send Empty Results">
                  </p-checkbox>
                </pos-form-field>
              </pos-column>
            </pos-row>

            <div class="col-12 p-0 pt-pix-10">
              <ul class="nav nav-tabs inner-tab pos-nav-tabs">
                <li [ngClass]="{ active: selectedTab == tabList.ParametersSetting }">
                  <a (click)="selectedTab = tabList.ParametersSetting" class="blue"
                    [ngClass]="{'required': parametersSetting.invalid}">
                    <span>
                      <pos-icon class="theme-separator pe-pix-4" [icon]="icons.list"></pos-icon>
                      <span>Parameters</span>
                    </span>
                  </a>
                </li>
                <li [ngClass]="{ active: selectedTab == tabList.SubscriptionDestination }">
                  <a (click)="selectedTab = tabList.SubscriptionDestination" class="blue"
                    [ngClass]="{'required': subscriptionDestination.invalid}">
                    <pos-icon [icon]="icons.mapPin" class="theme-separator pe-pix-4">
                    </pos-icon>
                    <span>Destination</span>
                  </a>
                </li>
              </ul>
              <div class="tab-content">
                <div [hidden]="selectedTab != tabList.ParametersSetting" ngModelGroup="parametersSetting"
                  #parametersSetting="ngModelGroup">
                  <div class="col-12 px-pix-5">
                    <div class="alert condensed inherit-position alert-warning mb-pix-10 mt-pix-15" id="divDatasourceParameter"
                      [hidden]="dataSourceParameter.length > 0">
                      {{dataSourceParameterError}}</div>
                  </div>
                  <div class="col-12 p-0 pt-pix-5 mb-pix-2">
                    <pos-row *ngFor="let data of dataSourceParameter; let index = index;">
                      <pos-column>
                        <pos-form-field [label]="data.DataType == 'BIT' ? '' : data.Label"
                          [validationMessages]="{required: 'Please enter ' + (data.Label | lowercase)}">
                          <input posInput type="text" name="parameterValue{{index}}" #parameter="ngModel"
                            [required]="data.IsRequired" class="form-control" *ngIf="data.DataType != 'BIT'"
                            [(ngModel)]="data.ParameterValue" />
                          <span [hidden]="data.DataType != 'BIT'">
                            <p-triStateCheckbox posInput name="paramValue{{index}}" [(ngModel)]="data.ParameterValue"
                              label="{{ data.Label }}"></p-triStateCheckbox>
                          </span>
                        </pos-form-field>
                      </pos-column>
                    </pos-row>
                  </div>
                </div>
                <div [hidden]="selectedTab != tabList.SubscriptionDestination" ngModelGroup="subscriptionDestination"
                  #subscriptionDestination="ngModelGroup" class="pos-tab-body">
                  <pos-row >
                    <pos-column>
                      <pos-form-field label="Destination"
                        [validationMessages]="{required: 'Please select destination.'}">
                        <ng-select posInput [items]="exportConnection" bindLabel="Name" bindValue="Id"
                          placeholder="Select destination" [(ngModel)]="dataSourceSubscribe.ExportConnectionId"
                          id="exportConnectionId" name="exportConnectionId" [required]="true">
                        </ng-select>
                      </pos-form-field>
                    </pos-column>
                  </pos-row>

                  <div class="row col-12">
                    <div class="col-lg-3 col-md-3 col-sm-4 col-5 pt-pix-10 theme-separator">
                      <strong>Parameter</strong>
                    </div>
                    <div class="col-lg-4 col-md-5 col-sm-5 col-5 pt-pix-10 theme-separator">
                      <strong>
                        <span class="display-flex">
                          Value
                        </span>
                      </strong>
                    </div>
                  </div>
                  <div *ngFor="let config of destinationConfigValues;  let last = last; let index = index"
                    class="row col-12 pt-pix-10">
                    <div class="col-lg-3 col-md-3 col-sm-4 col-5"
                      [ngClass]="{'has-error': (parameter.invalid && dataSourceSubscribeForm.submitted && (config.Value || config.Value == 0))}">
                      <input type="text" name="parameter{{index+updatedIndex}}" #parameter="ngModel"
                        class="form-control" (ngModelChange)="createParameterRow(last)" [(ngModel)]="config.Parameter"
                        [required]="!last && (config.Value || config.Value == 0)" (keyup)="createParameterRow(last)" />
                      <span
                        *ngIf="parameter.invalid && dataSourceSubscribeForm.submitted && (config.Value || config.Value == 0)">
                        <label class="control-label text-left text-nowrap" *ngIf="parameter.errors?.required">
                          Please enter parameter.</label>
                      </span>
                    </div>
                    <div class="col-lg-4 col-md-5 col-sm-5 col-5"
                      [ngClass]="{'has-error': (value.invalid && dataSourceSubscribeForm.submitted && config.Parameter)}">
                      <input type="text" class="form-control" #value="ngModel" name="value{{index+updatedIndex}}"
                        step="0.1" (ngModelChange)="createParameterRow(last)" (keyup)="createParameterRow(last)"
                        [(ngModel)]="config.Value" [required]="!last && config.Parameter !== ''" />
                      <span *ngIf="value.invalid && dataSourceSubscribeForm.submitted && config.Parameter">
                        <label class="control-label text-left text-nowrap" *ngIf="value.errors?.required">
                          Please enter value.</label>
                      </span>
                    </div>
                    <div class="col-1 pt-pix-2" *ngIf="!last">
                      <pos-action-icon [icon]="icons.deleteWhite" (click)="deleteParameterRow(index)">
                      </pos-action-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 action-button-container page-footer">
          <button type="submit" value="Save" class="btn btn-primary btn-lg btn-save pos-button" scrollup>
            Save
          </button>
          <button type="button" class="btn btn-secondary btn-lg btn-cancel pos-button" value="Cancel" (click)="onCancel()" scrollup>
            Cancel
          </button>
        </div>
      </context-provider>
    </form>
  </pos-edit-wrapper>
</div>
