import { Component, OnInit, Input } from '@angular/core';
import { ModalComponent } from 'src/app/shared/components/modal/modal-component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { InvProduct } from '../../../../shared/interface/inventory-product';
import { InventoryProductService } from '../../../../shared/services/inventory-product.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { finalize } from 'rxjs/operators';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { InventoryProductLabelPrintRequest } from '../../../../shared/interface/inventory-product-label-print-request';
import { ApplicationStateService } from 'src/app/shared';
import Pluralize from 'pluralize';

@Component({
  selector: 'pos-label-print-intermediary',
  templateUrl: './label-print-intermediary.component.html',
  styleUrls: ['./label-print-intermediary.component.scss']
})
export class LabelPrintIntermediaryComponent extends ModalComponent implements OnInit {

  @Input() inventoryProduct: InvProduct;
  inventoryProductLabelPrint: InventoryProductLabelPrintRequest;
  Pluralize = Pluralize;
  measuringInstructionsWarning = Messages.InventoryProductMeasuringInstructionsWarning;

  constructor(modalRef: BsModalRef,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private inventoryProductService: InventoryProductService,
    private applicationStateService: ApplicationStateService) {
    super(modalRef);
  }

  ngOnInit() {
    this.inventoryProductLabelPrint = {
      ProductName: this.inventoryProduct.name,
      UnitQty: this.inventoryProduct.unit_qty,
      UnitName: this.inventoryProduct.UnitName,
      MaxPortionsText: this.inventoryProduct.MaxPortionsText,
      MeasureByInstructions: this.inventoryProduct.MeasureByInstructions,
      NumberOfLabels: 1,
      Parameters: this.applicationStateService.settingParam,
      SubcategoryId: this.inventoryProduct.subcategory_id,
      PrimaryZoneCode: this.inventoryProduct.PrimaryZoneCode,
      PrimaryBinName: this.inventoryProduct.PrimaryBinName,
      SecondaryZoneCode: this.inventoryProduct.SecondaryZoneCode,
      SecondaryBinName: this.inventoryProduct.SecondaryBinName
    }
  }

  cancel() {
    this.hide({});
  }

  printLabel(isValid: boolean) {
    if (!isValid) {
      return;
    }
    else {
      this.spinnerService.show();
      this.inventoryProductLabelPrint.UnitQty = this.inventoryProductLabelPrint.UnitQty ? this.inventoryProductLabelPrint.UnitQty : 0;
      this.inventoryProductService.labelPrint(this.inventoryProductLabelPrint)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: res => {
            this.alertService.renderSuccessMessage(Messages.LabelPrintRequestSendSuccess);
            this.hide({});
          }, error: this.alertService.showApiError
        });
    }
  }
}
