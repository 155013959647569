import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { HttpClient } from '@angular/common/http';
import { InfoLinkModel } from '../interface/info-link-model';
import { Observable } from 'rxjs';

@Injectable()
export class InfoLinkService {
    infoLinkApiBasePath = `${RuntimeConstants.API_PATH}infolinks`;

    constructor(private httpService: HttpClient) { }

    getInfoLinks(): Observable<InfoLinkModel[]> {
        return this.httpService.get<InfoLinkModel[]>(this.infoLinkApiBasePath);
    }

    deleteInfoLink(infoLinkId: number): Observable<any> {
        return this.httpService.delete(this.infoLinkApiBasePath + '/' + infoLinkId );
    }

    getInfoLink(infoLinkId: number): Observable<InfoLinkModel> {
        return this.httpService.get<InfoLinkModel>(this.infoLinkApiBasePath + '/' + infoLinkId);
    }

    newInfoLink() {
        const infoLinkModel: InfoLinkModel = {
            Id: 0,
            Name: '',
            Link: '',
			SectionName: '',
			UserId: 0,
            SectionId: null
        };

        return infoLinkModel;
    }

    addInfoLink(infoLink: InfoLinkModel): Observable<any> {
        return this.httpService.post(this.infoLinkApiBasePath, infoLink);
    }

    updateInfoLink(infoLinkId: number, infoLink: InfoLinkModel): Observable<any> {
        return this.httpService.put(this.infoLinkApiBasePath + '/' + infoLinkId, infoLink);
    }

    saveCustomLinkClicks(customLinkClicks: InfoLinkModel): Observable<any> {
        return this.httpService.post(this.infoLinkApiBasePath + '/SaveCustomLinkClicks', customLinkClicks);
    }

}
