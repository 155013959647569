<pos-list-wrapper [headingIcon]="icons.digitalSignageBullHorn" heading="Digital Signage" (close)="close()">
  <div class="col-12">
    <ng-table #slideShow [columns]="slideShowColumns" [data]="slideShowList" [paginator]="false"
      [search]="false">
    </ng-table>
  </div>
</pos-list-wrapper>

<ng-template let-data="rowData" #operationHeaderTemplate>
  <pos-action-icon (click)="edit(null)" class="inline-block" [icon]="icons.plusWhite" iconText="Add">
  </pos-action-icon>
</ng-template>
<ng-template let-data="rowData" #operationTemplate>
  <span class="pe-pix-3" (click)="editItem(data.Id)" tooltip title="Edit" scrollup>
    <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
  </span>
  <span class="pe-pix-3" (click)="deleteHardware(data)" tooltip title="Delete">
    <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
  </span>
  <span *ngIf="(data.IsActive)" class="pt-pix-3" (click)="updateHardwareDeviceStatus(data, false)" tooltip
    title="Click here to deactivate this hardware">
    <pos-action-icon [icon]="icons.ban"></pos-action-icon>
  </span>
  <span *ngIf="!(data.IsActive)" class="pt-pix-3" (click)="updateHardwareDeviceStatus(data, true)" tooltip
    title="Click here to activate this hardware">
    <pos-action-icon [icon]="icons.checkCircleWhite"></pos-action-icon>
  </span>
</ng-template>
