import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-heros',
    templateUrl: './heros.component.tpl.html'
})
export class HerosComponent implements OnInit {
    public heros: string[];
    myVar = true;

    ngOnInit(): void {
        this.heros = [
            'Iron Man',
            'Bat Man',
            'Spider Man'
        ];
        console.dir(this.heros);
    }
}
