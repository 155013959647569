import { Component, OnInit, ViewChild } from '@angular/core';
import { SalesUnit } from '../../../../shared/interface/sales-units';
import { SpinnerService } from 'src/app/shared/components';
import { SalesUnitService } from '../../services/sales-unit.service';
import { AlertsService, Messages } from 'src/app/shared';
import { NgForm } from '@angular/forms';
import { BaseCrudModelComponent } from 'src/app/shared/components/base-crud-model.component';
import { FormUtilityService } from 'src/app/shared/services/form-utility.service';

@Component({
  selector: 'pos-sales-unit-edit',
  templateUrl: './sales-unit-edit.component.html',
  styleUrls: ['./sales-unit-edit.component.scss']
})
export class SalesUnitEditComponent extends BaseCrudModelComponent<SalesUnit> implements OnInit {

  salesUnit: SalesUnit;
  @ViewChild('salesUnitForm') form: NgForm;
  get getForm(): NgForm {
    return this.form;
  }
  props = {
    labelClass: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
    controlBoxClass: 'col-lg-5 col-md-5 col-sm-5 col-xs-12'
  }
  constructor(spinnerService: SpinnerService,
    private salesUnitService: SalesUnitService,
    alertService: AlertsService,
    formUtilityService: FormUtilityService) {
    super(salesUnitService, alertService, spinnerService, formUtilityService);
  }

  ngOnInit() {
    this.salesUnit = this.salesUnit ? this.salesUnit : this.salesUnitService.newSalesUnit();
    this.saveSuccessMessage = Messages.SalesUnitSaveSuccess;
  }

  saveSalesUnit(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.save(this.salesUnit);
  }

  cancelEditing() {
    this.hide({ shouldReload: false });
  }

  onSaveSuccess(model: SalesUnit): void {
    this.hide({ shouldReload: true });
  }

  loadDependencies(): void { }
}
