import { SystemLogViewerComponent } from './system-log-viewer/components/system-log-viewer/system-log-viewer.component';
import { Permissions, Levels, DomainConstants } from '../shared/constants';
import { CustomLinksComponent } from './customlinks/components/custom-links/custom-links.component';
import { ManageConsoleComponent } from './manage-console/component/manage-console/manage-console.component';
import { ReceiptDesignerComponent } from './printout-designer/component/receipt-designer/receipt-designer.component';
import { Route } from '@angular/router';
import { AuthGuard } from '../app-guard';
import { SharedHeaderComponent } from './shared-header/shared-header.component';
import { INVENTORY_STATES } from './inventory/inventory.routes';
import { DashboardListComponent } from '../management-dashboard/components';
import { UserWorkScheduleComponent } from '../information-management/work-schedule/components/user-work-schedule/user-work-schedule.component';
import { OnlineOrderComponent } from './online-order/components/online-order/online-order.component';
import { AudioRecordingListComponent } from './audio-recordings/component/audio-recording-list/audio-recording-list.component';
import { InventoryBarcodeScannerComponent } from './inventory-barcode/components/inventory-barcode-scanner';
import { InventoryBarcodeLogComponent } from './inventory-barcode/components/inventory-barcode-log';
import { InventoryBarcodeBatchListComponent } from './inventory-barcode/components/inventory-barcode-batch-list/inventory-barcode-batch-list.component';

export const MANAGE_CONSOLE_STATES: Array<Route> = [{
    path: '',
    component: SharedHeaderComponent,
    canActivateChild: [AuthGuard],
    children: [
        {
            path: '',
            pathMatch:'full',
            redirectTo: 'console'
        },
        {
            path: 'console',
            component: ManageConsoleComponent,
            data: {
                permissions: [{ 'Name': Permissions.ManageConsole, 'Level': Levels.Access }]
            }
        },
        {
            path: 'log-viewer',
            component: SystemLogViewerComponent,
            data: {
                permissions: [{ 'Name': Permissions.ManageLogs, 'Level': Levels.Access },
                { 'Name': Permissions.ManageConsole, 'Level': Levels.Access }]
            }
        },
        {
            path: 'custom-links',
            component: CustomLinksComponent,
            data: {
                permissions: [{ 'Name': Permissions.LinkCustomLinks, 'Level': Levels.Access }]
            }
        },
        {
            path: 'receipt-script',
            component: ReceiptDesignerComponent,
            data: {
                permissions: [{ 'Name': Permissions.ManagePrintout, 'Level': Levels.Access },
                { 'Name': Permissions.ManageConsole, 'Level': Levels.Access }]
            }
        },
        {
            path: 'online-order',
            component: OnlineOrderComponent,
            data: {
                permissions: [{ 'Name': Permissions.ManageOrderFulfillment, 'Level': Levels.Access }]
            }
        },
        {
            path: 'app-configuration',
            // canDeactivate: [DeactivateGuard],
            loadChildren: () => import('../information-management/information-management.module').then(m => m.InformationManagementModule),
        },
        {
            path: 'report',
            loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
        },
        {
            path: 'events',
            loadChildren: () => import('./event-manager/event.module').then(m => m.EventModule)
        },
        {
            path: 'dashboard/hic',
            loadChildren: () => import('./hic-dashboard/hic-dashboard.module').then(m => m.HicDashboardModule)
        },
        {
            path: 'system-configuration',
            loadChildren: () => import('../configurator/configurator.module').then(m => m.ConfiguratorModule)
        },
        {
            path: 'layouts',
            loadChildren: () => import('../layout-designer/layout-designer.module').then(m => m.LayoutDesignerModule)
        },
        {
            path: 'dashboards/list',
            component: DashboardListComponent,
            data: {
                permissions: [{ 'Name': DomainConstants.TerminalTypes.MANAGEMENT_DASHBOARD.Permission, 'Level': Levels.Access }]
            }
        },
        {
            path: 'menu-display',
            loadChildren: () => import('../menu-display/menu-display.module').then(m => m.MenuDisplayModule)
        },
        {
            path: 'background-jobs',
            data: {
                permissions: [{ 'Name': Permissions.ManageBackgroundJobs, 'Level': Levels.Access }]
            },
            loadChildren: () => import('../hangfire-jobs/hangfire-jobs.module').then(m => m.HangfireJobsModule)
        },
        {
            path: 'menu-setup',
            loadChildren: () => import('../menu-setup/menu-setup.module').then(m => m.MenuSetupModule)
        },
        {
            path: 'work-schedule',
            component: UserWorkScheduleComponent,
            data: {
                permissions: [{ 'Name': Permissions.LinkWorkschedule, 'Level': Levels.Access }]
            }
        },
        {
            path: 'automated-exports',
            loadChildren: () => import('../manage-console/automated-exports/automated-exports.module').then(m => m.AutomatedExportsModule)
        },
        {
            path: 'promotion',
            loadChildren: () => import('../manage-console/promotion').then(m => m.PromotionModule),
        },
        {
            path: 'captured-audio',
            component: AudioRecordingListComponent,
            data: {
                permissions: [{ 'Name': Permissions.CapturedAudio, 'Level': Levels.Access }]
            }
        },
        {
            path: 'inventory-barcode',
            component: InventoryBarcodeScannerComponent,
            data: {
                permissions: [{ 'Name': Permissions.InventoryLogInventory, 'Level': Levels.Access }]
            }
        },
        {
            path: 'inventory-barcode/:id',
            component: InventoryBarcodeLogComponent,
            data: {
                permissions: [{ 'Name': Permissions.InventoryLogInventory, 'Level': Levels.Access }]
            }
        },
        {
            path: 'inventory-log-batch',
            component: InventoryBarcodeBatchListComponent,
            data: {
                permissions: [{ 'Name': Permissions.InventoryLogInventory, 'Level': Levels.Access }]
            }
        },
        {
            path: 'order-kiosk',
            loadChildren: () => import('../order-kiosk/order-kiosk.module').then(m => m.OrderKioskModule)
        },
        ...INVENTORY_STATES
    ]
}];
