<pos-list-wrapper [headingIcon]="icons.cashPayoutsSearchDollar" heading="Cash Payouts" (close)="close()">
    <div class="row">
        <div class="col-12">
            <ng-table id="cashPayout" #cashPayoutGrid [sort]="true" [exportFilename]="'cash-payouts'"
                [columns]="cashPayoutColumns" [data]="cashPayouts">
            </ng-table>
        </div>
    </div>
</pos-list-wrapper>
<pos-print-table [hidden]="true"></pos-print-table>
