<pos-kiosk-header *ngIf="screenConfig.Header.IsShow" [header]="screenConfig.Header" />
<div class="row mx-0 overflow-auto designer-area" [style.background-color]="screenConfig.BackgroundColor"
    [style.height]="invoiceHeight + 'px'"
    [style.background-image]="screenConfig.BackgroundImage ? 'url(\''+ imageRootPath + screenConfig.BackgroundImage + '\')' : null">
    <div class="p-0 relative" [ngClass]="screenConfig.ShowInvoice ? 'col-xl-9 col-8' : 'col-12'"
        [style.height]="invoiceHeight - 3 + 'px'">
        <pos-action-icon [icon]="icons.plusWhite" iconText="Add Button" *ngIf="isDesignMode"
            class="text-nowrap add-button m-2" (click)="addKioskScreenChoice()"></pos-action-icon>
        <div class="d-flex h-100 relative w-100" *ngIf="buttonConfig" [style.height]="buttonDivHeight + 'px'"
            [ngStyle]="{'justify-content': buttonConfig.ContainerWidth ? 'center' : ''}"
            [style.align-items]="buttonConfig.Alignment?.Vertical">
            <div class="d-flex" [style.justify-content]="buttonConfig.Alignment?.Horizontal" [ngStyle]="{'top': buttonConfig.VerticalPosition + 'px', 'position': 'absolute',
                    'width': buttonConfig.ContainerWidth ? buttonConfig.ContainerWidth : '100%'
                 }">
                <div class="grid-column pointer" (click)="buttonClick(screenButton)"
                    *ngFor="let screenButton of screenButtons" [ngStyle]="{'height': buttonConfig.Height ? buttonConfig.Height + 'px' : '50px',
                'width': buttonConfig.Width ? buttonConfig.Width + 'px' : '', 'min-width': buttonConfig.Width ? buttonConfig.Width + 'px' : '100px',
                'border-color': screenButton?.Button?.BorderColor, 'background-color': screenButton?.Button?.BackgroundColor,
                'border-radius': buttonConfig.Radius + 'px', '--rotation-degree': buttonConfig.RotationDegree + 'deg',
                '--bg-opacity': buttonConfig.Opacity ?? 1 }"
                    [class.kiosk-bg-light-grey]="isDesignMode || (screenButton.Button && !screenButton.Button.BackgroundColor)"
                    [ngClass]="{'active': screenButton?.Button?.IsSelected, 'searched': screenButton?.Button?.IsSearched, 'filtered-product': screenButton?.Button?.IsFilteredByAllergens, 'out-of-stock': screenButton?.Button?.SalesProduct && !screenButton.Button.SalesProduct.IsInStock && !screenButton?.Button?.IsFilteredByAllergens, 'inactive-product': screenButton?.Button?.SalesProduct && !screenButton.Button.SalesProduct.IsActive }">
                    <pos-icon class="plus-icon" *ngIf="!screenButton?.Button && isDesignMode"
                        [icon]="icons.plusMessage"></pos-icon>
                    <div class="config-button">
                        <pos-kiosk-screen-setup [screenButton]="screenButton" [screenButtons]="screenButtons"
                            (screenButtonsChange)="emitScreenButtonsChange($event)" [isDesignMode]="isDesignMode"
                            [isAllowDelete]="true"></pos-kiosk-screen-setup>
                    </div>
                    <div class="button-text" *ngIf="screenButton?.Button"
                        [innerHTML]="screenButton.Button.ButtonText | uppercase"
                        [ngStyle]="{'color': screenButton.Button.TextColor, 'font-size': screenButton.Button.FontSize }">
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="screenConfig.Note" class="note w-75 m-auto" [innerHTML]="screenConfig.Note"
            [ngClass]="{'mb-pix-20': !screenConfig.Footer.IsShow }">
        </div>
    </div>
    <div class="col-xl-3 col-4 p-3" *ngIf="screenConfig.ShowInvoice" [ngStyle]="{'height': invoiceHeight - 3 + 'px'}">
        <pos-kiosk-invoice></pos-kiosk-invoice>
    </div>
</div>
<pos-kiosk-footer *ngIf="screenConfig.Footer.IsShow" [buttons]="screenButtons"
    [footer]="screenConfig.Footer"></pos-kiosk-footer>
