import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PromotionType } from 'src/app/manage-console/promotion/interfaces/promotion-type';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';

@Injectable()
export class MarketingPromotionTypesService {
  constructor(private httpService: HttpClient) { }
  getPromotionTypes(): Observable<Array<PromotionType>> {
    return this.httpService.get<Array<PromotionType>>(
      `${RuntimeConstants.API_PATH}marketing-promotion-types/`
    );
  }
}
