import { MessagePayload } from "./message-payload";

export class ReviewOrderPayload extends MessagePayload {
    public OrderData: any;
    public Seats: any;

    constructor(orderData, seats) {
        super();
        this.OrderData = orderData;
        this.Seats = seats;
    }
}
