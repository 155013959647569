import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { MessageStripConfig } from 'src/app/order-kiosk/interfaces';
import { GeneralConfigService } from 'src/app/order-kiosk/services';
import { AlertsService, ApplicationStateService, BaseFormComponent, ExtensionValidationService, FormUtilityService, Messages, RuntimeConstants, SettingParam, SpinnerService, cogs, times } from 'src/app/shared';


declare let $: any;

@Component({
  selector: 'pos-general-config',
  templateUrl: './general-config.component.html',
  styleUrls: ['./general-config.component.scss']
})
export class GeneralConfigComponent extends BaseFormComponent implements OnInit {

  get getForm(): NgForm {
    return this.form
  }
  @ViewChild('configForm') form: NgForm;

  parameters: SettingParam;
  messageStripConfig: MessageStripConfig;
  icons = {
    cogs,
    times
  };
  imageFile: File;
  ImagePath = RuntimeConstants.IMAGE_BASE_PATH + '/order-kiosk';


  constructor(
    private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    applicationStateService: ApplicationStateService,
    private router: Router,
    formUtilityService: FormUtilityService,
    private extensionValidationService: ExtensionValidationService,
    private generalConfigService: GeneralConfigService) {
    super(formUtilityService);
    this.parameters = applicationStateService.newSettingParams();
    this.messageStripConfig = {
      FontColor: null,
      BackgroundColor: null,
      FontSize: null,
      Text: null
    }
  }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.spinnerService.show();
    this.generalConfigService.getConfig()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          this.parameters = res;
          if (this.parameters.OrderKioskTitleMessageConfig)
            this.messageStripConfig = JSON.parse(this.parameters.OrderKioskTitleMessageConfig);
        }, error: this.alertsService.showApiError
      });
  }

  handleFileInput(files: FileList) {
    let fileToUpload = '';
    if (files && files[0]) {
      this.imageFile = files.item(0);
      fileToUpload = files.item(0).name;
    }

    if (this.extensionValidationService.isInvalidExtension(fileToUpload)) {
      if (fileToUpload) {
        this.alertsService.renderErrorMessage(Messages.InvalidSalesProductImageFileType);
      }
      this.removeIcon();
      return;
    }

    // Read file from input
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        $('#imagePreview').attr('src', reader.result);
      };
      reader.readAsDataURL(files[0]);
      this.parameters.OrderKioskIsDisabledImage = this.imageFile.name;
    }
  }

  removeIcon() {
    this.parameters.OrderKioskIsDisabledImage = null;
    this.imageFile = null;  
    $('#image').val(null);
  }

  saveConfig(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.spinnerService.show();
    this.parameters.OrderKioskTitleMessageConfig = JSON.stringify(this.messageStripConfig);
    this.generalConfigService.save(this.parameters, this.imageFile)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          this.alertsService.renderSuccessMessage(Messages.KioskGeneralConfigSave);
        }, error: this.alertsService.showApiError
      });
  }

  cancelEditing() {
    this.router.navigate(['manage/order-kiosk']);
  }
}
