import { Component, OnInit, SimpleChanges, OnChanges, Input, ViewChild, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { SettingParam, ApplicationStateService, DomainConstants, ModalService, InventoryLogService } from 'src/app/shared';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { inventoryLogInfoCircle, inventoryLogClock, inventoryLogWarning } from 'src/app/shared/components/icon';
import { ControlContainer, NgForm } from '@angular/forms';
import { InventoryProductMemoComponent } from '../inventory-product-memo/inventory-product-memo.component';
declare let $: any;

@Component({
  selector: 'pos-inventory-product-log',
  templateUrl: './inventory-product-log.component.html',
  styleUrls: ['./inventory-product-log.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class InventoryProductLogComponent implements OnInit, OnChanges {
  @Input() isPopup = false;
  @Input() index: string;
  @Input() isFromInventoryExhaustion = false;
  @Input() inventoryProduct: any = {};
  @Input() focus = false;
  @Input() checkAllProducts: boolean = false;
  @Output() stateChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  settingParam: SettingParam;
  modalRef: BsModalRef;
  public icons = {
    inventoryLogInfoCircle,
    inventoryLogClock,
    inventoryLogWarning
  };
  isToggleMemo = false;
  @ViewChild('inputLog') private inputLog: any;
  constructor(private inventoryLogService: InventoryLogService,
    private applicationStateService: ApplicationStateService,
    private modalService: ModalService) {
    this.settingParam = this.applicationStateService.settingParam;
  }

  ngOnInit() {
    if (this.inventoryProduct) {
      this.inventoryProduct.oldQty = this.inventoryProduct.Qty;

    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.settingParam.InventoryLogPopulateQuantityUsing === DomainConstants.InventoryLogPopulateQuantityUsing[1].Name) {
      this.inventoryProduct.Qty = this.inventoryProduct.CalculatedQty;
    }
    if (this.isPopup) {
      setTimeout(() => {
        const txtQtyId = '#txtQty' + this.inventoryProduct?.id;
        this.showToolTip(txtQtyId, false);
      });
      this.isToggleMemo = false;
    }
  }

  onChangeQty(itemDetails) {
    const hasUnitQtyIncrementError = this.inventoryLogService.qtyChange(itemDetails, this.inventoryProduct);
    const txtQtyId = '#txtQty' + itemDetails?.id;
    this.showToolTip(txtQtyId, hasUnitQtyIncrementError);
  }

  showToolTip(controlId, hasError) {
    if (hasError) {
      $(controlId).parent().addClass('has-error');
      this.inputLog.show();
      $(controlId).focus();
    } else {
      $(controlId).parent().removeClass('has-error');
      this.inputLog.hide();
    }
  }

  showMemoPopup(inventory) {
    if (!this.isPopup) {
      const productMemoModal = this.modalService.getModalWrapper(InventoryProductMemoComponent);
      const modalRef = productMemoModal.show({
        'backdrop': 'static',
        'class': 'vertical-center',
        initialState: {
          productMemo: ''
        }
      });
      modalRef.close.subscribe((res) => {
        if (res && res.reload) {
          this.inventoryProduct.IsUpdated = true;
          this.inventoryProduct.Memo = res.productMemo;
        }
      });
    } else {
      this.isToggleMemo = !this.isToggleMemo;
    }
    this.inventoryProduct.Memo = null;
  }

  productSelectionStateChange = () => {
    this.stateChange?.emit(this.inventoryProduct.IsUpdated);
  }

}
