import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { UserTraining } from '../interface';

@Injectable()
export class TrainingApproveService {
    constructor(private httpService: HttpClient) { }

    GetTrainingInfoByRoleId(userId: number): Observable<any> {
        return this.httpService.get(
            `${RuntimeConstants.API_PATH}trainingapprove/` + userId
        );
    }
    addUserTraining(userTraining: UserTraining): Observable<any> {
        return this.httpService.post(
            `${RuntimeConstants.API_PATH}usertraining`,
            userTraining
        );
    }

}
