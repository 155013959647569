import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { ModalService } from '../shared/components/modal/modal.service';
import { Messages } from '../shared/constants/ui-messages';
import { Router } from '@angular/router';
import { InfoModalComponent } from '../shared/components/info-modal/info-modal.component';

@Injectable({
  providedIn: 'root'
})
export class PostSavedConfigurationService {

  constructor(private modalService: ModalService,
    private router: Router) { }
  private messageSource = new Subject<string>();
  message$ = this.messageSource.asObservable();

  showMessage(requiredToNavigate: boolean = true): void {
    // this.messageSource.next(message);
    const modalRef = this.modalService.getModalWrapper(InfoModalComponent);
    const modal = modalRef.show({
      animated: false,
      class: "vertical-center",
      initialState: {
        message: Messages.ColorsEffectWarning
      },
    });
    modal.close.subscribe((res) => {
      if (requiredToNavigate) {
        this.router.navigate(["/manage/system-configuration"]);
      }
    });
  }
}
