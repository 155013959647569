<div>
  <button (click)="cancelRefund()" class="close" type="button">&times;</button>
  <h4 class="modal-content__heading">Refund</h4>
</div>
<div class="overflow-hidden refund-order">
  <div class="col-12 px-pix-20 pt-pix-15" *ngIf="orderData">
    <table class="col-12">
      <th></th>
      <tbody>
        <tr>
          <td class="col-lg-5 col-md-5 co-sm-5 col-xs-5 p-0">
            <pos-read-only-order-summary #readOnlyOrderSummary [orderId]="orderId" [(orderData)]="orderData"
              (changeProductSelection)="changeOrderProductSelection($event)"
              [isSelectableProduct]="true"></pos-read-only-order-summary>
          </td>
          <td class="col-lg-2 col-md-2 col-sm-2 col-xs-2 text-center">
            <div class="row g-0 px-2">
              <div class="col-12">
                <button class="btn btn-secondary normal mb-pix-5 w-pix-115 pos-button" (click)="addAllItemToRefund()">
                  Add All <pos-icon [icon]="icons.angleDoubleRight"></pos-icon>
                </button>
              </div>
              <div class="col-12 pb-3">
                <button class="btn btn-secondary normal w-pix-115 pos-button" (click)="addToRefund()">
                  Add <pos-icon [icon]="icons.angleRight"></pos-icon>
                </button>
              </div>
              <hr class="m-0">
              <div class="col-12 pt-3">
                <button class="btn btn-secondary normal mb-pix-5 w-pix-115 pos-button" (click)="removeItemFromRefund()">
                  <pos-icon [icon]="icons.angleLeft"></pos-icon> Remove
                </button>
              </div>
              <div class="col-12">
                <button class="btn btn-secondary normal mb-pix-5 w-pix-115 pos-button"
                  (click)="removeAllItemsFromRefund()">
                  <pos-icon [icon]="icons.angleDoubleLeft"></pos-icon> Remove All
                </button>
              </div>
            </div>
          </td>
          <td class="col-lg-5 col-md-5 co-sm-5 col-xs-5 p-0">
            <pos-read-only-order-summary [orderId]="0" [(seats)]="refundOrderSeats"
              (changeProductSelection)="changeOrderProductSelectionForRefundOrder($event)"
              [(orderData)]="refundOrderData" [isSelectableProduct]="true"></pos-read-only-order-summary>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="clearfix"></div>
  <div class="col-12 action-button-container popup-footer pb-pix-10">
    <button class="btn btn-primary btn-lg btn-save pos-button" type="submit" (click)="refundByCreditCard()"
      [disabled]="!refundOrderData || !refundOrderData.GrandTotal"> Credit Card
    </button>
    <button class="btn btn-primary btn-lg btn-save pos-button" type="submit" (click)="openPeripheralModel(true)"
      [disabled]="!refundOrderData || !refundOrderData.GrandTotal"> EBT
    </button>
    <button class="btn btn-primary btn-lg btn-save pos-button" type="submit" (click)="applyRefundViaCash()"
      [disabled]="!refundOrderData || !refundOrderData.GrandTotal"> Cash
    </button>
    <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="cancelRefund()" type="button">
      Cancel
    </button>
  </div>
</div>

