import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { Observable } from 'rxjs';
import { TerminalSummary } from 'src/app/application-login/interface/terminal-summary';
import { map } from 'rxjs/operators';
import { ApplicationStateService, EventBroadcastingService, BaseCrudService, UserDetails, SettingParam } from 'src/app/shared';
import { Terminal } from '../interface/terminal';
import { TerminalSlideshowMap, TerminalProperty } from '../interface';
import { TerminalDetail } from '../interface/terminal-detail';
import { MenuDisplaySetting } from '../interface/menu-display-setting';
import { KioskTerminalStatusConfig } from '../interface/kiosk-terminal-status-config';

@Injectable({
  providedIn: 'root'
})
export class TerminalsService extends BaseCrudService<Terminal> {

  terminalApiPath = `${RuntimeConstants.API_PATH}terminals/`;
  parameterNames = {
    DEFAULT_KITCHEN_PRINTER: 'DefaultKitchenPrinterId'
  };
  constructor(protected httpService: HttpClient,
    private applicationStateService: ApplicationStateService,
    private eventBroadcastingService: EventBroadcastingService) {
    super('terminals', httpService);
  }

  newTerminal() {
    const terminal: Terminal = {
      Id: 0,
      TerminalId: 0,
      TerminalName: '',
      TerminalType: '',
      TerminalTypeId: null,
      TerminalSlideshowMapModel: null,
      MakeTableTerminalIds: '',
      TerminalPropertiesModel: [],
      OrderEntryTerminalId: null,
      OrderEntryTerminalIds: '',
      MenuDisplaySettings: null,
      HasMappedHardwareTypes: false,
      TerminalStatus: null,
    };
    return terminal;
  }

  newSlideshowMapModel(): TerminalSlideshowMap {
    return {
      Id: 0,
      TerminalId: 0,
      SlideshowId: null
    };
  }

  newMenuDisplaySetting(): MenuDisplaySetting {
    return {
      Id: 0,
      TerminalId: 0,
      MenuDisplayId: null,
      Days: '',
      DaysIds: [],
      StartTime: null,
      EndTime: null
    };
  }

  getTerminalProperties(terminalId): Observable<Array<TerminalProperty>> {
    return this.httpService.get<Array<TerminalProperty>>(
      `${this.terminalApiPath}` + terminalId + `/TerminalProperties`
    );
  }

  getMappedTerminals(terminalId, terminalType): Observable<Array<TerminalDetail>> {
    return this.httpService.get<Array<TerminalDetail>>(
      `${this.terminalApiPath}` + terminalId + `/MappedTerminals/` + terminalType
    );
  }

  getParameter(terminalId: number, parameterName: string) {
    return this.httpService.get(
      `${this.terminalApiPath}` + terminalId + '/parameters/' + parameterName);
  }

  getTerminalSummary(): Observable<Array<TerminalSummary>> {
    return this.httpService.get<Array<TerminalSummary>>(`${RuntimeConstants.API_PATH}terminals`);
  }

  saveTerminalEvents(terminalEvent) {
    return this.httpService.post(`${this.terminalApiPath}SaveTerminalEvents`, terminalEvent);
  }

  logTerminalUserLogin(terminalEvent) {
    return this.httpService.post(`${this.terminalApiPath}LogTerminalUserLogin`, terminalEvent);
  }

  reloadSettings(terminalId: number): Observable<SettingParam> {
    return this.httpService.get(`${this.terminalApiPath}` + terminalId + `/ReloadSettings`)
      .pipe((map((response: SettingParam) => {
        if (response) {
          this.applicationStateService.settingParam = response;
          this.eventBroadcastingService.reloadSettingsEvent();
        }
        return response;
      })));
  }

  getTerminalTypes(): Observable<Array<TerminalSummary>> {
    return this.httpService.get<Array<TerminalSummary>>(`${this.terminalApiPath}terminalTypes`);
  }

  globalLogoff(message: string, userDetails: UserDetails) {
    return this.httpService.post(`${this.terminalApiPath}${message}`, userDetails);
  }

  getTerminalSlideshow(terminalId: number): Observable<TerminalSlideshowMap[]> {
    return this.httpService.get<TerminalSlideshowMap[]>(`${this.terminalApiPath}${terminalId}/TerminalSlideshow`);
  }

  saveTerminalDetails(terminal: Terminal) {
    return this.httpService.post(`${this.terminalApiPath}`, terminal);
  }

  getTerminals() {
    return this.httpService.get(this.terminalApiPath);
  }

  getScreenList() {
    return this.httpService.get(`${this.terminalApiPath}screen-list`);
  }

  linkMakeTableToAllOrderEntry(terminalId: number) {
    return this.httpService.post(`${this.terminalApiPath}link/make-table/${terminalId}/order-entry`, null);
  }

  getTimerOptions() {
    return this.httpService.get(`${this.terminalApiPath}timerOptions`);
  }

  getTimerSounds(): Observable<Array<string>> {
    return this.httpService.get<Array<string>>(`${this.terminalApiPath}timerSounds`);
  }

  getPrepGroupName() {
    return this.httpService.get(`${this.terminalApiPath}prepGroupName`);
  }

  getDashboards() {
    return this.httpService.get(`${RuntimeConstants.API_PATH}DashboardConfiguration/GetDashboards`);
  }

  updateTerminalDashboardProperty(terminalDashboardProperty) {
    return this.httpService.put(`${this.terminalApiPath}dashboard-property`, terminalDashboardProperty);
  }

  saveMakeTableTerminalProperty(makeTableProperty: TerminalProperty) {
    return this.httpService.post(`${this.terminalApiPath}${makeTableProperty.TerminalId}/makeTableTerminalProperty`,
    makeTableProperty);
  }

  getMenuDisplaySettings(terminalId: number): Observable<Array<MenuDisplaySetting>> {
    return this.httpService.get<Array<MenuDisplaySetting>>(`${this.terminalApiPath}${terminalId}/menu-display-settings`);
  }

  deleteMenuDisplaySetting(menuDisplaySettingId: number) {
    return this.httpService.delete(`${this.terminalApiPath}menu-display-setting/${menuDisplaySettingId}`);
  }

  getKioskTerminalStatusConfig(): Observable<KioskTerminalStatusConfig[]> {
    return this.httpService.get<KioskTerminalStatusConfig[]>(`${this.terminalApiPath}kiosk-status-details`);
  }

  updateKioskTerminalStatusConfig(kioskTerminalStatusConfig :KioskTerminalStatusConfig[]): Observable<KioskTerminalStatusConfig[]> {
    return this.httpService.post<KioskTerminalStatusConfig[]>(`${this.terminalApiPath}kiosk-status-details`, kioskTerminalStatusConfig);
  }
}
