<div class="m-0-auto">
    <div class="page-content">
        <div class="tab-content">
            <div class="tab-pane fade px-3 in active show">
                <div class="col-12 ">
                    <a class="close" (click)="onCancel()">×</a>
                    <div class="row">
                        <div class="col-10 ps-0 display-flex">
                            <pos-icon [icon]="icons.history" class="pe-pix-10 vertical-center-align page-content__header-icon"></pos-icon>
                            <h3 class="page-content__header">
                                Sales Product History: {{salesProductName}}
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="col-12 pb-pix-5">
                    <div class="table-responsive">
                        <ng-table [columns]="salesProductHistoryColumns" [exportFilename]="'sales-product-history'" #salesProductHistoryTable
                            [data]="salesProductHistory" (lazyLoad)="lazyLoad($event)" [lazy]="true" [totalRecords]="totalRecords">
                        </ng-table>

                        <ng-template let-data="rowData" #taxableTemplate>
                            <span *ngIf="(data.IsTaxable)" class="green text-center vertical-mid font-13">
                                <pos-icon [icon]="icons.check"></pos-icon>
                            </span>
                        </ng-template>

                        <ng-template let-data="rowData" #activeTemplate>
                            <span *ngIf="(data.IsActive)" class="green text-center vertical-mid font-13">
                                <pos-icon [icon]="icons.check"></pos-icon>
                            </span>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
