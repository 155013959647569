import { Component, OnInit, Input } from '@angular/core';
import { SpinnerService, AlertsService, ModalComponent } from 'src/app/shared';
import { SalesProductsService } from '../../services/sales-products.service';
import { finalize } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { GridColumn } from '@tarktech/tark-ng-utils';

@Component({
  selector: 'pos-sales-product-list-all-buttons',
  templateUrl: './sales-product-list-all-buttons.component.html',
  styleUrls: ['./sales-product-list-all-buttons.component.scss']
})
export class SalesProductListAllButtonsComponent extends ModalComponent implements OnInit {

  @Input('productId') productId: number;
  @Input('productName') productName: string = '';
  mappedButtons: Array<any> = [];
  mappedButtonColumns: Array<GridColumn> = [];
  constructor(private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private salesProductService: SalesProductsService,
    public modalRef: BsModalRef) {
    super(modalRef);
  }

  ngOnInit() {
    this.configureGridColumn();
    this.getDetails();
  }

  configureGridColumn() {
    this.mappedButtonColumns = [
      new GridColumn({ HeaderText: 'Screen', Field: 'ScreenName', IsSortable: true }),
      new GridColumn({ HeaderText: 'Button', Field: 'ButtonName', IsSortable: true })
    ]
  }

  getDetails() {
    this.spinnerService.show();
    this.salesProductService.getSalesProductMappedButtonDetails(this.productId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({ next: (res) => {
        if (res) {
          this.mappedButtons = res;
        }
      }, error: this.alertService.showApiError });

  }

  onCancel() {
    this.hide({});
  }

}
