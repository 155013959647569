<pos-edit-wrapper heading="Cash Drawer" (close)="cancel()" [headingIcon]="icons.cashDrawerMoneyBillWave"
  [isPopup]="false">
  <form #cashDrawerForm="ngForm" class="edit-form" (ngSubmit)="save(cashDrawerForm.valid)">
    <div class="col-12 pos-page-body border-top">
      <div class="col-12">
        <div>
          <h3 class="setting-title">Drawer Open Events</h3>
        </div>
        <pos-row class="pos-title-content">
          <pos-column *ngFor="let openDrawerCondition of parameters.OpenDrawerConditions; let i = index">
            <pos-form-field inputStyle="d-flex">
                <p-checkbox posInput name="shouldOpenCashDrawer{{i}}" #shouldOpenCashDrawer{{i}}="ngModel"
                  [(ngModel)]="openDrawerCondition.ShouldOpenCashDrawer"
                  [disabled]="!openDrawerCondition.IsSelected || openDrawerCondition.Code=='HouseAccount'" binary="true"
                  [label]="openDrawerCondition.Name">
                </p-checkbox>
                <field-info *ngIf="openDrawerCondition.TenderFieldInfo" [info]="openDrawerCondition.TenderFieldInfo"
                  class="ps-3 pt-0">
                </field-info>
            </pos-form-field>
          </pos-column>
        </pos-row>
      </div>

      <div class="col-12 section-border">
        <div>
          <h3 class="setting-title">Behavior</h3>
        </div>
        <pos-row class="pos-title-content">
          <pos-column>
            <pos-form-field inputStyle="d-flex">
              <p-checkbox posInput name="assignUserToDrawer" #assignUserToDrawer="ngModel"
                [(ngModel)]="parameters.AssignUserToDrawer" binary="true" label="Assign drawer to single user">
              </p-checkbox>
              <field-info class="ps-3" [info]="fieldInfoMessages.CashDrawerAssignDrawerToSingleUser"></field-info>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field inputStyle="d-flex">
              <p-checkbox posInput name="preventLogoutUntilDrawerClosed" #preventLogoutUntilDrawerClosed="ngModel"
                [(ngModel)]="parameters.PreventLogoutUntilDrawerClosed" binary="true"
                label="Prevent sign out until drawer is closed">
              </p-checkbox>
              <field-info class="ps-3" [info]="fieldInfoMessages.CashDrawerPreventSignOut">
              </field-info>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field inputStyle="d-flex">
              <p-checkbox posInput name="printEndOfShiftReport" #printEndOfShiftReport="ngModel"
                [(ngModel)]="parameters.PrintEndOfShiftReport" binary="true" label="Print end of Shift Report on Close">
              </p-checkbox>
              <field-info class="ps-3" [info]="fieldInfoMessages.CashDrawerPrintShiftReport">
              </field-info>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field inputStyle="d-flex">
              <p-checkbox posInput name="deductCreditTipsFromDrawer" #deductCreditTipsFromDrawer="ngModel"
                [(ngModel)]="parameters.DeductCreditTipsFromDrawer" binary="true"
                label="Deduct Credit Tips and open Drawer">
              </p-checkbox>
              <field-info class="ps-3" [info]="fieldInfoMessages.CashDrawerDeductCreditTips" placement="left">
              </field-info>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Variance Threshold" inputStyle="col-lg-2 col-md-3 col-xs-8 col-150 d-flex"
              [validationMessages]="{required: 'Invalid percentage.'}">
              <p class="input-group drawer-variance d-flex flex-nowrap">
                <input posInput type="number" class="form-control col-150 price-input" name="CashDrawerVarianceThreshold"
                  [(ngModel)]="parameters.CashDrawerVarianceThreshold" [range]="[0, 100]" step="0.01"
                  #CashDrawerVarianceThreshold="ngModel" posDecimal />
                <span class="input-group-addon">%</span>
              </p>
            </pos-form-field>
          </pos-column>
        </pos-row>
      </div>
    </div>
    <div class="col-12 action-button-container page-footer">
      <button class="btn btn-primary btn-lg normal btn-save pos-button" scrollup type="submit"
        value="Save">Save</button>
      <button class="btn btn-secondary btn-lg normal btn-cancel pos-button" routerLink="../" scrollup type="button"
        value="Cancel">Cancel</button>
    </div>
  </form>
</pos-edit-wrapper>
