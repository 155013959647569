import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GridColumn, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import cronstrue from 'cronstrue';
import { forEach } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { User } from 'src/app/information-management/users/interface/user';
import { AlertsService, ApplicationStateService, AuthenticationService, Levels, Messages, ModalComponent } from 'src/app/shared';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ReportSubscription } from '../../interfaces';
import { ReportService } from '../../services';

@Component({
  selector: 'pos-copy-report-subscription',
  templateUrl: './copy-report-subscription.component.html',
  styleUrls: ['./copy-report-subscription.component.scss']
})
export class CopyReportSubscriptionComponent extends ModalComponent implements OnInit {

  users: Array<User> = [];
  selectedUser: User;
  userId: number;
  userSubscriptions: Array<ReportSubscription> = [];
  reportsSubscriptionColumns: Array<GridColumn> = [];
  tableHeight = 250;
  selectAll = false;

  @ViewChild('subscriptionChkTemplate', { static: true }) subscriptionChkTemplate: TemplateRef<any>;
  @ViewChild('scheduleTemplate', { static: true }) scheduleTemplate: TemplateRef<any>;
  @ViewChild('subscriptionChkHeaderTemplate', { static: true }) subscriptionChkHeaderTemplate: TemplateRef<any>;
  constructor(private userService: UserService,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private reportService: ReportService,
    private authenticationService: AuthenticationService,
    private applicationStateService: ApplicationStateService,
    modalRef: BsModalRef) {
    super(modalRef);
    this.userId = applicationStateService.userId;
  }

  ngOnInit(): void {
    this.loadData();
    this.reportsSubscriptionColumns = this.configureColumns();
  }

  configureColumns(): Array<GridColumn> {
    return [
      new TemplateColumn({
        headerTemplate: this.subscriptionChkHeaderTemplate, itemTemplate: this.subscriptionChkTemplate,
        Width: '35px', TextAlign: TextAlign.Center
      }),
      new GridColumn({ HeaderText: 'Report', Field: 'ReportName', Width: '30%' }),
      new TemplateColumn({
        HeaderText: 'Subscription Schedule', itemTemplate: this.scheduleTemplate, Width: '65%'
      })
    ];
  }

  loadData() {
    this.spinnerService.show();
    this.userService.getAllActiveUser().pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (response) => {
        if (response) {
          this.users = response.filter(x => (x.id !== this.userId) && x.EmailAddress);
        }
      }, error: this.alertService.showApiError
    });
  }

  loadUserSubscription() {
    if (this.selectedUser?.id) {
      this.spinnerService.show();
      this.reportService.getReportSubscriptionSchedules(this.selectedUser.id).pipe(finalize(() => {
        this.spinnerService.hide();
      })).subscribe({
        next: (response) => {
          if (response) {
            this.userSubscriptions = response.filter((data) => {
              return this.authenticationService.userHasPermission([{
                Name: data.PermissionId,
                Level: Levels.Access
              }], 'all');
            });
            this.userSubscriptions.forEach((data) => {
              if (data.Schedule) {
                try {
                  data.Schedule = cronstrue.toString(data.Schedule);
                } catch (ex) {
                  throw new Error('Invalid schedule expression found in data source subscription.');
                }
              }
              if (data.TimeString) {
                data.Time = this.parseTime(data.TimeString);
              }
            });
          }
        }, error: this.alertService.showApiError
      });
    } else {
      this.userSubscriptions = [];
    }
  }

  selectAllSubscriptions() {
    if (this.selectAll) {
      forEach(this.userSubscriptions, (data) => {
        data.Subscribe = true;
      });
    } else {
      forEach(this.userSubscriptions, (data) => {
        data.Subscribe = false;
      });
    }
  }

  removeSelectAll() {
    this.selectAll = false;
  }

  parseTime(timeString) {
    if (timeString === '') { return null; }

    const time = timeString.match(/(\d+)(:(\d\d))?\s*(p?)/i);
    if (time == null) { return null; }

    let hours = parseInt(time[1], 10);
    if (hours === 12 && !time[4]) {
      hours = 0;
    } else {
      hours += (hours < 12 && time[4]) ? 12 : 0;
    }
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(parseInt(time[3], 10) || 0);
    date.setSeconds(0, 0);
    return date;
  }

  onSave(isValid: Boolean) {
    if (!isValid) {
      return;
    }
    const copyReportSubscription = this.userSubscriptions.filter(x => x.Subscribe);
    if (copyReportSubscription.length) {
      this.spinnerService.show();
      this.reportService.copyReportSubscriptions(copyReportSubscription, this.userId).pipe(finalize(() => {
        this.spinnerService.hide();
      })).subscribe({
        next: (response) => {
          this.onSaveSuccess();
        }, error: this.alertService.showApiError
      });
    } else {
      this.alertService.renderErrorMessage(Messages.SubscriptionCopySaveWarning);
    }
  }

  onSaveSuccess() {
    this.alertService.renderSuccessMessage(Messages.SubscriptionCopySuccess);
    this.onCancel(true);
  }

  onCancel(reloadOnClose: Boolean = false) {
    this.close.emit({ reloadOnClose });
  }

}
