import { Injectable } from "@angular/core";
import * as moment from "moment";
import { MakeTableColorConfig, MakeTableOrderItem } from "src/app/make-table";
import { DomainConstants } from "../constants/domain-constants";
import { SettingParam } from "../interface/setting-param";

@Injectable({
  providedIn: 'root'
})
export class OrderCardService {
  constructor() { }

  getBackGroundColor(quantity, makeTableQuantityColors) {
    if (!makeTableQuantityColors || makeTableQuantityColors.length === 0) {
      return 'Black';
    } else {
      const makeTableQuantityColorArray = makeTableQuantityColors;
      if (quantity <= makeTableQuantityColorArray.length) {
        for (let i = 0; i <= makeTableQuantityColorArray.length; i++) {
          if (i === (parseInt(quantity) - 1)) {
            if (makeTableQuantityColorArray[i] !== ',') {
              return (makeTableQuantityColorArray[i]);
            }
          }
        }
      } else {
        return 'Black';
      }
    }
  }

  checkIsFilteredApplied(filteredProductData) : boolean {
      const salesCategoriesFilter = filteredProductData.SalesCategories;
      const salesGroupsFilter = filteredProductData.SalesGroups;
      if (salesGroupsFilter?.length || salesCategoriesFilter?.length) {
        return true;
      }
      return false;
  }

  calculateOrderTime(makeTableDateTime) {
    const currentDate = new Date();
    let formattedTime = '';
    const timeDifference = moment.duration(moment(currentDate).diff(moment(new Date(makeTableDateTime))));
    const hours = timeDifference.asHours();
    formattedTime = hours && Math.floor(hours) > 0 ?
      Math.floor(hours).toLocaleString('en-US', { minimumIntegerDigits: 2 }) + ':' : '';
    formattedTime += hours < 0 ? '00:00' :
    moment.utc(moment(currentDate, 'DD/MM/YYYY HH:mm:ss').diff(moment(new Date(makeTableDateTime), 'DD/MM/YYYY HH:mm:ss'))).format('mm:ss');
    return formattedTime;
  }

  getItemMakeTableColor(product: MakeTableOrderItem, makeTableColorConfig: MakeTableColorConfig) {
    let MakeTableItemStyleColor = '';
    if (product.MakeTableState === DomainConstants.MakeTableStates.RETRIEVING_INGREDIENTS ||
      product.MakeTableState === DomainConstants.MakeTableStates.COOKING ||
      product.MakeTableState === DomainConstants.MakeTableStates.CRAFTING) {
      MakeTableItemStyleColor = makeTableColorConfig.MakeTableProcessColor;
    } else if (product.MakeTableState === DomainConstants.MakeTableStates.READY) {
      MakeTableItemStyleColor = makeTableColorConfig.MakeTableReadyColor;
    } else if (product.MakeTableState === DomainConstants.MakeTableStates.DONE) {
      MakeTableItemStyleColor = makeTableColorConfig.MakeTableCompleteColor;
    } else if (product.MakeTableState === DomainConstants.MakeTableStates.PENDING) {
      MakeTableItemStyleColor = product.MakeTableColor;
    } else if (product.MakeTableState === DomainConstants.MakeTableStates.PARTIALLY_IN_PROGRESS) {
      MakeTableItemStyleColor = makeTableColorConfig.MakeTablePartiallyInProgressColor;
    }
    return MakeTableItemStyleColor;
  }

  getProductStatusUpdateModel(product, orderId, previousState, terminalId) {
    return {
      TerminalId: terminalId,
      ProductItemId: product.Id,
      OrderId: orderId,
      MakeTableState: product.MakeTableState,
      UseParallelPickScreen: product.UseParallelPickScreen,
      IsUpdated: false,
      PreviousMakeTableState: previousState
    };
  }
}
