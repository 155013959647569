<div class="kiosk-overlay kiosk-background"
    [style.background-image]="backgroundImage && !isVideo ? 'url(\'' + baseImagePath + backgroundImage + '\')' : null">
    <video class="full-screen" *ngIf="isVideo" id="videoPlayer" autoplay="autoplay">
        <source [src]="baseImagePath + backgroundImage" type="video/mp4">
    </video>
    <div class="footer-buttons" *ngIf="isShowButtons" [style.--footer-bg]="footerBackgroundColor"
        [ngStyle]="{'justify-content': rejectButtonText ? 'space-between' : 'center'}">
        <button type="button" class="btn btn-lg" (click)="emitUserResponse()" *ngIf="rejectButtonText">
            {{rejectButtonText}}
        </button>
        <button type="button" class="btn btn-lg" (click)="continueOperation()">
            {{confirmButtonText}}
        </button>
    </div>
</div>
