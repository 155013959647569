import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SpinnerService {
  public _requestCount = 0;
  public _spinnerVisibility: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  /**
   * show
   */
  public show() {
    this._requestCount++;
    this._spinnerVisibility.next(true);
  }

  /**
   * hide
   */
  public hide() {
    this._requestCount--;
    if (this._requestCount <= 0) {
      this._requestCount = 0;
      this._spinnerVisibility.next(false);
    }
  }
}
