import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseCrudService } from 'src/app/shared';
import { OrderMethod } from '../interfaces/order-method';

@Injectable({
  providedIn: 'root'
})
export class OrderMethodService extends BaseCrudService<OrderMethod> {

  constructor(private httpClient: HttpClient) {
    super('order-methods', httpClient);
  }

}
