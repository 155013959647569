import { keyboard } from '../../../shared/components/icon/icons';
import { ApplicationStateService } from '../../../shared/services/application-state.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AlertsService, SpinnerService, ModalComponent, ApplicationInitializationConfigurations, ModalService, DomainConstants } from 'src/app/shared';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { InboxMessageService } from '../../services';
declare let $: any;
import { finalize } from 'rxjs/operators';
import { KeyboardComponent } from 'src/app/keyboard';

@Component({
    selector: 'pos-inbox-messages-edit',
    templateUrl: './inbox-messages-edit.component.html',
    styleUrls: ['./inbox-messages-edit.component.scss']
})
export class InboxMessagesEditComponent extends ModalComponent implements OnInit {
    @ViewChild('sendMessagesForm') sendMessagesForm: NgForm;
    @ViewChild('ckeditor',{static: true}) ckeditor;
    inboxMessage: any;
    messageText = '';
    sender = { SelectedEmail: null, SelectedTerminal: null };
    message = {
        senderType: 'User',
        recipientType: 'User'
    };
    userEmails: Array<any> = [];
    terminalMessageModel: any;
    terminals: Array<any> = [];
    userId: number;
    appConfig: ApplicationInitializationConfigurations;
    messagekeyBoardOptions = { isOpen: false, capsLock: false, title: 'Enter Message Text', inputMode: 'multiLine' };
    isOpen = false;
    keyboardId: string;
    msgText = '';
    inboxMessageLength: number;
    public icons = {
        keyboard
    }
    constructor(
        commentModalRef: BsModalRef,
        private inboxMessageService: InboxMessageService,
        private alertService: AlertsService,
        private spinnerService: SpinnerService,
        private modalService: ModalService,
        private applicationStateService: ApplicationStateService) {
        super(commentModalRef);
        const userDetails = this.applicationStateService.userDetails;
        this.userId = userDetails.id;
        this.getUserNameForSendingMessages();
        this.getTerminals();
        this.terminalMessageModel = {
            SenderId: '',
            RecipientTerminalId: '',
            RecipientUserId: '',
            MessageText: '',
            FromEmailId: '',
            RecipientEmailId: ''
        };
        this.appConfig = this.applicationStateService.applicationInitializationConfigurations;
        this.terminalMessageModel.FromUserId = this.userId;
        this.terminalMessageModel.FromTerminalId = this.applicationStateService.terminalId;
    }
    ngOnInit() {
        this.messageText = '';
        this.sender.SelectedTerminal = null;
        if (this.inboxMessage !== 0) {
            this.terminalMessageModel.FromUserId = this.inboxMessage.RecipientUserId > 0 ? this.inboxMessage.RecipientUserId : 0;
            this.terminalMessageModel.FromTerminalId = this.inboxMessage.RecipientTerminalId > 0 ?
                this.inboxMessage.RecipientTerminalId : 0;
            this.terminalMessageModel.RecipientUserId = this.inboxMessage.FromUserId > 0 ? this.inboxMessage.FromUserId : 0;
            this.terminalMessageModel.RecipientTerminalId = this.inboxMessage.FromTerminalId > 0 ? this.inboxMessage.FromTerminalId : 0;

            this.inboxMessage.senderType = this.terminalMessageModel.FromUserId ? 'User' : 'Terminal';
            this.inboxMessage.recipientType = this.terminalMessageModel.RecipientUserId ? 'User' : 'Terminal';

            this.message.senderType = this.terminalMessageModel.FromUserId ? 'User' : 'Terminal';
            this.message.recipientType = this.terminalMessageModel.RecipientUserId ? 'User' : 'Terminal';

            this.sender.SelectedEmail = this.terminalMessageModel.RecipientUserId;
            this.sender.SelectedTerminal = this.terminalMessageModel.RecipientTerminalId;
            this.markAsRead(this.inboxMessage);
        } else {
            this.message.senderType = 'User';
            this.message.recipientType = 'Terminal';
            this.resetTerminalMessage();
        }
        $(document).off('focusin.modal');
    }

    getUserNameForSendingMessages() {
        this.spinnerService.show();
        this.inboxMessageService.getUserNameForSendingMessages()
            .pipe(finalize(() => {
                this.spinnerService.hide();
            })).subscribe(
                res => {
                    this.userEmails = res.UserEmailAddress;
                    _.forEach(this.userEmails, (user) => {
                        user.fullName = user.lastname + ', ' + user.firstname;
                    });
                },
                this.alertService.showApiError,
                () => {

                }
            );
    }
    resetTerminalMessage() {
        // Reset control
        this.sender.SelectedEmail = null;
        this.sender.SelectedTerminal = null;
        this.messageText = '';
        this.terminalMessageModel = new Object();
    }

    markAsRead(msg) {
        this.spinnerService.show();
        this.terminalMessageModel.FromUserId = this.userId;
        this.terminalMessageModel.FromTerminalId = this.applicationStateService.terminalId;
        this.terminalMessageModel.Id = msg.Id;
        this.inboxMessageService.markAsRead(this.terminalMessageModel).subscribe(
            res => {
            },
            this.alertService.showApiError,
            () => {
                this.spinnerService.hide();
            }
        );
    }
    getTerminals() {
        this.spinnerService.show();
        this.inboxMessageService.getTerminals()
            .pipe(finalize(() => {
                this.spinnerService.hide();
            })).subscribe(
                res => {
                    res = _.orderBy(res, 'TerminalName');

                    this.terminals = $.grep(res, function (terminal) {
                        return terminal.TerminalType === DomainConstants.TerminalTypes.ORDER_ENTRY.Name || terminal.TerminalType === DomainConstants.TerminalTypes.MAKE_TABLE.Name;
                    });
                    _.forEach(this.terminals, (terminal) => {
                        terminal.id = terminal.TerminalId;
                    });
                    this.terminals = _.orderBy(this.terminals, 'TerminalName');
                    this.terminals.splice(0, 0, { TerminalName: '[All Terminals]', id: 0 });
                },
                this.alertService.showApiError,
                () => {
                }
            );

    }

    editorEvent(){
        this.ckeditor.instance.document.on('keydown',(e)=>{
            if(e.data.$.ctrlKey && e.data.$.keyCode == 13){
                        this.sendMessages(this.sendMessagesForm.valid);
            }
        } );
    }

    sendMessages(isValid: boolean) {
        const snapshot =  this.ckeditor.instance.getSnapshot();
        this.ckeditor.instance.loadSnapshot(snapshot);
        if (isValid) {
            if (this.message.recipientType === 'Terminal') {
                if (!this.sender.SelectedTerminal && this.sender.SelectedTerminal !== 0) {
                    return;
                }
            }
            if (this.message.recipientType === 'User') {
                if (!this.sender.SelectedEmail) {
                    return;
                }
            }
            if (!this.messageText) {
                return;
            }
            this.spinnerService.show();
            this.terminalMessageModel.FromUserId = this.message.senderType === 'User' ? this.userId : 0; // From User
            this.terminalMessageModel.FromTerminalId = this.message.senderType === 'Terminal' ?
                this.applicationStateService.terminalId : 0; // From Terminal
            this.terminalMessageModel.MessageText = this.messageText;
            this.terminalMessageModel.RecipientUserId = this.message.recipientType === 'Terminal' ? null : this.sender.SelectedEmail;
            this.terminalMessageModel.RecipientTerminalId = this.message.recipientType === 'User' ? null : this.sender.SelectedTerminal;
            this.terminalMessageModel.TerminalId = this.applicationStateService.terminalId;
            this.terminalMessageModel.IsSystemMessage = false;
            this.setSenderAndRecipientEmailId();
            this.inboxMessageService.sendMessage(this.terminalMessageModel).subscribe(
                res => {
                    this.messageText = '';
                    this.sender.SelectedEmail = null;
                    this.sender.SelectedTerminal = null;
                    this.terminalMessageModel = new Object();
                    this.sendMessagesForm.reset();
                },
                this.alertService.showApiError,
                () => {
                    this.spinnerService.hide();
                    this.hide({ shouldReload: true });
                    $('#InboxMessage').modal('hide');
                }
            );
        }
    }
    setSenderAndRecipientEmailId() {
        if (this.terminalMessageModel.RecipientUserId || this.terminalMessageModel.FromUserId) {
            _.forEach(this.userEmails, (item) => {
                if (item.id === this.terminalMessageModel.RecipientUserId) {
                    this.terminalMessageModel.RecipientEmailId = item.text_email_address;
                }
                if (item.id === this.userId) {
                    this.terminalMessageModel.FromEmailId = item.text_email_address;
                    this.terminalMessageModel.Sender = item.lastname + ', ' + item.firstname;
                }
            });
        }

        if (this.terminalMessageModel.FromTerminalId) {
            _.forEach(this.terminals, (item) => {
                if (item.id === this.terminalMessageModel.FromTerminalId) {
                    this.terminalMessageModel.Sender = 'Terminal: ' + item.TerminalName;
                }
            });
        }
    }
    onMessageKeyboard() {
        this.messagekeyBoardOptions.isOpen = true;
        this.keyboardId = 'messageKeyboard';
        this.isOpen = true;
        this.msgText = this.messageText;

        const keyboardModalRef = this.modalService.show(KeyboardComponent, {
            animated: false,
            class: 'vertical-center modal-max-width-95',
            initialState: {
                keyboardId: 'messageKeyboard',
                isOpen: true,
                options: this.messagekeyBoardOptions,
                value: this.messageText
            }
        });
        keyboardModalRef.close.subscribe(res => {
            if (res?.value) {
                this.messageText = res.value;
            }
        });
    }

    onCancel(reload: boolean) {
        this.sendMessagesForm.reset();
        this.hide({ shouldReload: reload });
    }

}

// <pos-key-board [keyboardId]="keyboardId" [(isOpen)]="isOpen" [options]="messagekeyBoardOptions"
//     (valueChanged)="messageText = $event" [value]="msgText">
// </pos-key-board>
