<pos-edit-wrapper heading="{{selectedDeliveryType.description}}" (close)="closeModal(false)">
    <form #pushNotificationEditForm="ngForm" (submit)='saveActivity(pushNotificationEditForm.valid)'>
        <context-provider provide="props">
            <div class="col-12 pos-modal-body screen-scroll form-min-height">
                <pos-row>
                    <pos-column>
                        <pos-form-field label="Notification Mode" inputStyle="col-12">
                            <div class="btn-group">
                                <label class="btn btn-radio"
                                    [ngClass]="notificationMode == pushNotificationModes.Audio ? 'active btn-primary normal' : 'btn-secondary'">
                                    <input type="radio" name="notificationMode" class="form-check-input"
                                        (change)="changeAudioMode(pushNotificationModes.Audio)"
                                        [(ngModel)]="notificationMode">
                                    Audio File </label>
                                <label class="btn btn-radio"
                                    [ngClass]="notificationMode == pushNotificationModes.SpeakText ? 'active btn-primary normal' : 'btn-secondary'">
                                    <input type="radio" name="notificationMode" class="form-check-input"
                                        (change)="changeAudioMode(pushNotificationModes.SpeakText)"
                                        [(ngModel)]="notificationMode"> Speak Text </label>
                                <label class="btn btn-radio"
                                    [ngClass]="notificationMode == pushNotificationModes.Text ? 'active btn-primary normal' : 'btn-secondary'">
                                    <input type="radio" name="notificationMode" class="form-check-input"
                                        (change)="changeAudioMode(pushNotificationModes.Text)"
                                        [(ngModel)]="notificationMode">
                                    Text </label>
                            </div>
                        </pos-form-field>
                    </pos-column>
                    <pos-column *ngIf="notificationMode == pushNotificationModes.Audio">
                        <pos-form-field label="Audio File"
                            [validationMessages]="{required: 'Please select audio files.'}">
                            <pos-row class="mx-0">
                                <pos-column xs="11" class="p-0">
                                    <ng-select posInput [items]="audioFiles" bindLabel="Name" appendTo="body"
                                        bindValue="Id" placeholder="Select audio file" [(ngModel)]="selectedAudioFile"
                                        name="audioFile" [required]="notificationMode == pushNotificationModes.Audio">
                                    </ng-select>
                                </pos-column>
                                <pos-column xs="1" class="pe-0 pt-pix-5" *ngIf="userHasPermission"
                                    (click)="addNewAudioFile();">
                                    <pos-icon [icon]="icons.newAudioFile" class="pointer"></pos-icon>
                                </pos-column>
                            </pos-row>
                        </pos-form-field>
                    </pos-column>
                    <pos-column *ngIf="notificationMode != pushNotificationModes.Audio">
                        <pos-form-field
                            [label]="notificationMode == pushNotificationModes.SpeakText ? 'Speak Text' : 'Text'"
                            [validationMessages]="{required: 'Please add ' + (notificationMode == pushNotificationModes.SpeakText ? 'speak' : 'notification' ) + ' text.'}">
                            <textarea posInput class="form-control" name="audioText" rows="5" #audioText
                                [(ngModel)]="audioTextString" maxlength="2000" (click)="getCaretPos(audioText)"
                                (keyup)="getCaretPos(audioText)"
                                [required]="notificationMode != pushNotificationModes.Audio"></textarea>
                            <span class="me-pix-20 mt-pix-n30 relative float-right">
                                <span class="font-20 pointer grey">
                                    <pos-icon [icon]="icons.messageCode" class="pointer"
                                        (click)="isOpenVariable = !isOpenVariable">
                                    </pos-icon>
                                </span>
                                <div *ngIf="isOpenVariable" class="col-200 event-variable-button">
                                    <ng-select [items]="eventAttributes" appendTo="body" class="push-notification"
                                        placeholder="Select event variable" name="eventVariable"
                                        [(ngModel)]="selectedEventAttribute" (change)="changeEventAttribute()"
                                        [isOpen]="true">
                                    </ng-select>
                                </div>
                            </span>
                        </pos-form-field>
                    </pos-column>
                    <pos-column *ngIf="notificationMode == pushNotificationModes.SpeakText">
                        <pos-form-field label="Audio Voice"
                            [validationMessages]="{required: 'Please select audio voice.'}">
                            <ng-select posInput [items]="audioVoices" appendTo="body" placeholder="Select audio voice"
                                name="audioVoice" [(ngModel)]="selectedAudioVoice"
                                [required]="notificationMode == pushNotificationModes.SpeakText">
                            </ng-select>
                        </pos-form-field>
                    </pos-column>
                    <pos-column>
                        <pos-repeat-action-configuration
                            [(eventSubscriptionActionAttributes)]="eventSubscriptionActionAttributes"
                            [isFormSubmitted]="pushNotificationEditForm.submitted" #RepeatActionConfiguration
                            [isRecoverableEvent]="isRecoverableEvent">
                        </pos-repeat-action-configuration>
                    </pos-column>
                </pos-row>
            </div>
            <div class="col-12 action-button-container popup-footer">
                <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save</button>
                <button type="button" (click)="closeModal(false)"
                    class="btn-secondary btn-lg btn btn-cancel-modal pos-button" value="Cancel">Cancel</button>
            </div>
        </context-provider>
    </form>
</pos-edit-wrapper>
