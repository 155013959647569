import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Permissions, Levels, Messages } from '../../../../shared/constants/';
import { ConfirmDeleteComponent } from 'src/app/shared/components/confirm-delete/confirm-delete.component';
import { GridColumn, EditColumn, LinkColumn, DeleteColumn, TemplateColumn } from '@tarktech/tark-ng-utils';
import { InventorySubcategory } from '../../interface/inventory-subcategories';
import { AuthenticationService } from 'src/app/shared/auth/services/authentication.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { InventorySubCategoryService } from '../../service/inventory-subcategory.service';
import { finalize } from 'rxjs/operators';
import { listAlt, barsWhite, inventorySubCategoriesTh } from 'src/app/shared/components/icon';
import { InventorySubcategoryEditComponent } from '../inventory-subcategory-edit';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import { BaseGridComponent, ApplicationStateService, ModalBackdropService } from 'src/app/shared';
import { ActivatedRoute, Router } from '@angular/router';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { LinkTemplateColumn } from '@tarktech/tark-ng-utils/table/link-template-column';
import { InventoryLogDueDateOverrideEditComponent } from 'src/app/information-management/vendors';

@Component({
    selector: 'pos-inventory-subcategory-list',
    templateUrl: './inventory-subcategory-list.component.html',
    styleUrls: ['./inventory-subcategory-list.component.scss']
})
export class InventorySubcategoryListComponent extends BaseGridComponent implements OnInit {

    permission = {
        name: Permissions.InventorySubcategories,
        readOnlyLevel: Levels.ReadOnly,
        editLevel: Levels.Edit
    };
    icons = {
        barsWhite,
        listAlt,
        inventorySubCategoriesTh
    };
    popup: any;
    inventorySubCategories: InventorySubcategory[] = [];
    inventorySubCategory: InventorySubcategory;
    inventorySubCategoryId: number;
    inventorySubCategoryName: string;
    inventorySubCategoriesColumns: Array<GridColumn> = [];
    printInventorySubCategoriesColumns: Array<GridColumn> = [];
    @ViewChild('confirmInventorySubCategory') private confirmInventorySubCategory: ConfirmDeleteComponent;
    @ViewChild('popOverForInventorySubCategory', { static: true }) popOverForInventorySubCategory: TemplateRef<any>;
    modalRef: BsModalRef;
    constructor(private authenticationService: AuthenticationService,
        private alertService: AlertsService,
        private spinnerService: SpinnerService,
        private modalService: ModalService,
        private inventorySubCategoryService: InventorySubCategoryService,
        private modalBackdropService: ModalBackdropService,
        private printService: PrintTableService,
        protected applicationStateService: ApplicationStateService,
        protected route: ActivatedRoute,
        protected router: Router,
    ) {
        super(applicationStateService, route);
    }

    ngOnInit() {
        this.configureGridColumns();
        this.reloadInventorySubCategories();
    }
    private configureGridColumns() {

        const editColumn = new EditColumn({
            Clicked: (inventorySubCategory) => {
                this.editInventorySubCategory(inventorySubCategory.id);
            },
            Width: '57px',
            AddIcon: new LinkColumn({
                Clicked: () => {
                    this.editInventorySubCategory(0);
                },
                ToolTip: 'New'
            })
        });

        const deleteColumn = new DeleteColumn({
            Clicked: (inventorySubCategory) => {
                this.deleteInventorySubCategory(inventorySubCategory);
            },
            Width: '68px',
            PrintIcon: new LinkColumn({
                Clicked: () => {
                    this.printDiv();
                },
                ToolTip: 'Print'
            })
        });

        const popoverIcon = new LinkTemplateColumn({
            CellClass: 'cell-link greyicon', itemTemplate: this.popOverForInventorySubCategory, Width: '5px'
        });

        this.inventorySubCategoriesColumns = [
            new GridColumn({ HeaderText: 'Subcategory', Field: 'name', IsSortable: true, Width: '48%' }),
            new GridColumn({ HeaderText: 'Category', Field: 'categoryname', IsSortable: true, Width: '45%' }),
        ] as Array<GridColumn>;

        this.printInventorySubCategoriesColumns = [...this.inventorySubCategoriesColumns];

        if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
            this.inventorySubCategoriesColumns.push(editColumn);
            this.inventorySubCategoriesColumns.push(deleteColumn);
            this.inventorySubCategoriesColumns.push(popoverIcon);
        }
    }
    private reloadInventorySubCategories() {
        this.spinnerService.show();
        this.inventorySubCategoryService.getInventorySubcategoryList()
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (inventorySubCategories: InventorySubcategory[]) => {
                    this.inventorySubCategories = (inventorySubCategories === null ? [] : inventorySubCategories);
                }, error: this.alertService.showApiError
            });
    }
    private printDiv() {
        this.printService.printEmitter.next({
            gridColumns: this.printInventorySubCategoriesColumns,
            gridData: this.inventorySubCategories
        });
    }
    private editInventorySubCategory(id: number) {
        this.inventorySubCategoryId = id;

        const modalRef = this.modalService.show(InventorySubcategoryEditComponent, {
            animated: false,
            class: 'vertical-center',
            initialState: {
                inventorySubCategoryId: id
            }
        });
        modalRef.close.subscribe(res => {
            if (res && res.shouldReload) {
                this.inventorySubCategoryId = 0;
                this.reloadInventorySubCategories();
            } else {
                this.inventorySubCategoryId = 0;
            }
        });
    }
    private deleteInventorySubCategory(inventorySubCategory: InventorySubcategory) {
        this.inventorySubCategoryId = inventorySubCategory.id;
        this.confirmInventorySubCategory.Show(
            StringUtils.format(Messages.ConfirmDeleteInventorySubCategory, { 'inventorySubCategory': inventorySubCategory.name }));
    }
    onConfirmAcknowledge() {
        this.spinnerService.show();
        this.inventorySubCategoryService.deleteInventorySubcategory(this.inventorySubCategoryId)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: () => {
                    this.alertService.renderSuccessMessage(Messages.InventorySubCategoryDeleted);
                    this.reloadInventorySubCategories();
                    this.inventorySubCategoryId = 0;
                }, error: this.alertService.showApiError
            });
    }
    openNextInventorySubCategoryLogDateModal(inventorySubCategory: InventorySubcategory) {
        this.inventorySubCategoryName = inventorySubCategory.name;
        this.inventorySubCategoryId = inventorySubCategory.id;
        const modalRef = this.modalService.getModalWrapper(InventoryLogDueDateOverrideEditComponent);
        const modal = modalRef.show({
            'backdrop': 'static',
            'class': 'vertical-center',
            keyboard: false,
            initialState: {
                subcategoryId: this.inventorySubCategoryId,
                heading: 'Inventory Subcategory: ' + this.inventorySubCategoryName
            }
        });
        modal.close.subscribe((res) => {
            if (res && res.reload) {
                this.alertService.renderSuccessMessage(Messages.NextInventoryDueDateOverrideEditSuccess);
            }
            this.resetInventorySubCategoryData();
        });
        this.popup.hide();
    }
    onLinkPopOver(inventorySubCategory: InventorySubcategory, popup: any) {
        this.inventorySubCategory = inventorySubCategory;
        this.popup = popup;
    }
    private resetInventorySubCategoryData() {
        this.inventorySubCategoryName = '';
        this.inventorySubCategoryId = 0;
    }

    close() {
        this.router.navigate(['../'], { relativeTo: this.route });
    }
}
