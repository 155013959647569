import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportComponent } from './components/report/report.component';
import { ComponentsModule } from 'src/app/shared';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { ReportParametersComponent } from './components/report-parameters/report-parameters.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ReportDetailsComponent } from './components/report-details/report-details.component';
import { TableModule } from '@tarktech/tark-ng-utils';
import { ReportSubscriptionEditComponent } from './components/report-subscription-edit/report-subscription-edit.component';
import { ReportSubscriptionComponent } from './components/report-subscription/report-subscription.component';
import { NgPipesModule } from 'ngx-pipes';
import { RouterModule } from '@angular/router';
import { REPORT_ROUTES } from './reports.routes';
import { CopyReportSubscriptionComponent } from './components/copy-report-subscription/copy-report-subscription.component';
import { CronEditorModule } from '@tarktech/ngx-cron-editor';
import { MatSliderModule } from '@angular/material/slider';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
@NgModule({
  declarations: [ReportComponent, ReportParametersComponent, ReportDetailsComponent, ReportSubscriptionEditComponent,
    ReportSubscriptionComponent,
    CopyReportSubscriptionComponent],
  imports: [
    CommonModule,
    FormsModule,
    ComponentsModule,
    AccordionModule,
    NgSelectModule,
    CalendarModule,
    CheckboxModule,
    TableModule,
    NgPipesModule,
    MatCardModule,
    MatSelectModule,
    MatSliderModule,
    CronEditorModule,
    RouterModule.forChild(REPORT_ROUTES),
  ],
  providers:[
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' }
    }
  ],
  exports:[ReactiveFormsModule,MatCardModule,MatSelectModule,MatSliderModule]
})
export class ReportsModule { }
