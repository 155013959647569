import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GridColumn, TableComponent, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { ReportGroup } from '../../interface/report-group';
import { plusWhite, printWhite, editWhite, deleteWhite, layerGroup } from 'src/app/shared/components/icon';
import {
  AlertsService,
  ApplicationStateService,
  AuthenticationService,
  BaseListComponent,
  Levels,
  Permissions,
  Messages,
  ModalService,
  PrintTableService,
  SpinnerService,
  StringUtils,
  DomainConstants
} from 'src/app/shared';
import { Router, ActivatedRoute } from '@angular/router';
import { ReportGroupService } from '../../service/report-group.service';
import { remove } from 'lodash';
import { finalize } from 'rxjs/operators';
import { PermissionTypesService } from 'src/app/information-management/permissions';

@Component({
  selector: 'pos-report-group',
  templateUrl: './report-group.component.html',
})
export class ReportGroupComponent extends BaseListComponent<ReportGroup> implements OnInit {

  icons = {
    plusWhite,
    printWhite,
    editWhite,
    deleteWhite,
    layerGroup
  };
  permission = {
    name: Permissions.SystemConfigurationReportGroups,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };

  reportGroup: Array<ReportGroup> = [];
  reportGroupColumns: Array<GridColumn> = [];
  printReportGroupColumns: Array<GridColumn> = [];
  @ViewChild('IconTemplate', { static: true }) iconTemplate: TemplateRef<any>;
  @ViewChild('reportGroups', { static: true }) reportGroupTable: TableComponent;

  constructor(
    private authenticationService: AuthenticationService,
    protected reportGroupService: ReportGroupService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    private router: Router,
    protected applicationStateService: ApplicationStateService,
    protected printService: PrintTableService,
    private permissionTypeService: PermissionTypesService,
    protected route: ActivatedRoute
  ) {
    super(
      reportGroupService,
      alertService,
      spinnerService,
      modalService,
      applicationStateService,
      printService,
      route
    );
  }

  ngOnInit(): void {
    this.reportGroupColumns = this.configureGridColumns();
    this.deleteSuccessMessage = Messages.ReportGroupDeleted;
    this.load();
    this.checkPermission();
    this.reportGroupTable.context = this.gridContext;
  }

  private checkPermission(): void {
    if (!this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      remove(this.reportGroupColumns, (column) => {
        return (column.Type === DomainConstants.GridColumnsTypes.EditColumn
          || column.Type === DomainConstants.GridColumnsTypes.DeleteColumn);
      });
    }
  }

  load() {
    this.data$.pipe().subscribe({
      next: (response) => {
        response.forEach(x => x.CategoryName = x.Category.Name);
        this.reportGroup = response;
      }
    });
  }

  getGridColumns(): GridColumn[] {
    const ReportIcon = new TemplateColumn({
      Width: '27px',
      CellClass: 'no-right-border pri-0',
      itemTemplate: this.iconTemplate,
      TextAlign: TextAlign.Center
    });
    const columns = [
      new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '67%', CellClass: 'no-left-border', }),
      new GridColumn({ HeaderText: 'Category', Field: 'CategoryName', IsSortable: true, Width: '20%' }),
    ];
    this.printReportGroupColumns = [...columns];
    columns.unshift(ReportIcon);
    return columns;
  }

  getConfirmDeleteMessage(data: ReportGroup): string {
    return StringUtils.format(Messages.ConfirmDeleteReportGroup, { 'reportGroup': data.Name });
  }

  printData() {
    this.printService.printEmitter.next({
      gridColumns: this.printReportGroupColumns,
      gridData: this.reportGroup,
    });
  }

  editItem(id: number, data?: ReportGroup): void {
    this.router.navigate([id], { relativeTo: this.route });
  }

  close() {
    this.router.navigate(['manage/system-configuration']);
  }

  protected override afterDelete(): void {
    this.spinnerService.show();
    this.permissionTypeService.updatePermissionGraph()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe((res) => {});
  }

}
