import { Component, ViewChild, OnInit } from '@angular/core';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { AlertsService, Messages, ConfirmDeleteComponent, AuthenticationService } from 'src/app/shared';
import { GridColumn, TemplateColumn, TextAlign, DateColumn } from '@tarktech/tark-ng-utils';
import { Permissions, Levels } from '../../../shared/constants';
import * as _ from 'lodash';
import { blackListedAddressBook, unlock } from 'src/app/shared/components/icon';
import { IpFailedLoginAttemptService } from '../service/ip-failed-login-attempt.service';
import { IpFailedLoginAttempt } from '../interface/ip-failed-login-attempt';
import { finalize } from 'rxjs/operators';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'pos-blacklisted-ip-addresses',
    templateUrl: './blacklisted-ip-addresses.component.html',
})
export class BlacklistedIpAddressesComponent implements OnInit {

    @ViewChild('confirmBlacklistedIpAddress') private confirmBlacklistedIpAddress: ConfirmDeleteComponent;
    @ViewChild('iconTemplate') private iconTemplate: any;
    @ViewChild('operationTemplate', { static: true }) private operationTemplate: any;
    @ViewChild('headerTemplate') private headerTemplate: any;

    ipFailedLoginAttempts: IpFailedLoginAttempt[] = [];
    public icons = {
        unlock,
        blackListedAddressBook
    };
    private selectedIpFailedLoginAttempts: IpFailedLoginAttempt;
    blackListedIpColumns: Array<GridColumn> = [];
    confirmUnblockBlacklistedIpAddress: string;
    permission = {
        name: Permissions.SecurityBlacklistedAddresses,
        readOnlyLevel: Levels.ReadOnly,
        editLevel: Levels.Edit
    };

    constructor(private alertService: AlertsService,
        private spinnerService: SpinnerService,
        private authenticationService: AuthenticationService,
        private ipFailedLoginAttemptService: IpFailedLoginAttemptService,
        protected router: Router,
        protected route: ActivatedRoute) { }

    ngOnInit() {
        this.configureGridColumns();
        this.loadIpFailedLoginAttempts();
    }

    private loadIpFailedLoginAttempts() {
        this.spinnerService.show();
        this.ipFailedLoginAttemptService.getIpFailedLoginAttempt()
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (ipFailedLoginAttempt: IpFailedLoginAttempt[]) => {
                    if (ipFailedLoginAttempt) {
                        this.ipFailedLoginAttempts = ipFailedLoginAttempt;
                    } else {
                        this.ipFailedLoginAttempts = [];
                    }
                }, error: this.alertService.showApiError
            });
    }

    private configureGridColumns() {
        const operationColumn = new TemplateColumn({
            itemTemplate: this.operationTemplate,
            Width: '90px',
            headerTemplate: this.headerTemplate,
            TextAlign: TextAlign.Center
        });

        this.blackListedIpColumns = [
            new GridColumn({ HeaderText: 'IP Address', Field: 'IpAddress', IsSortable: true, Width: '68%' }),
            new GridColumn({
                HeaderText: 'Failed Attempts', Field: 'FailedLoginAttempts', IsSortable: true,
                Width: '10%', TextAlign: TextAlign.Right
            }),
            new DateColumn({
                HeaderText: 'Last Failed Attempts', Field: 'LastFailedAttempt',
                IsSortable: true, Width: '15%'
            })
        ] as Array<GridColumn>;

        if (this.authenticationService.userHasPermission([{ 'Name': this.permission.name, 'Level': this.permission.editLevel }], 'any')) {
            this.blackListedIpColumns.push(operationColumn);
        }

    }

    deleteIpFailedLoginAttempt(ipFailedLoginAttempt: IpFailedLoginAttempt) {
        this.selectedIpFailedLoginAttempts = ipFailedLoginAttempt;
        this.confirmUnblockBlacklistedIpAddress = StringUtils.format(Messages.ConfirmUnblockBlacklistedIpAddress,
            { 'ipAddress': ipFailedLoginAttempt.IpAddress });
        this.confirmBlacklistedIpAddress.Show(this.confirmUnblockBlacklistedIpAddress);
    }

    onConfirmAcknowledge() {
        this.spinnerService.show();
        this.selectedIpFailedLoginAttempts.FailedLoginAttempts = 0;
        this.ipFailedLoginAttemptService.updateIpFailedLoginAttempt(this.selectedIpFailedLoginAttempts)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: () => {
                    this.loadIpFailedLoginAttempts();
                    this.alertService.renderSuccessMessage(Messages.IPUnblockSuccess);
                }, error: this.alertService.showApiError
            });
    }

    close() {
        this.router.navigate(['../'], { relativeTo: this.route });
    }

}
