import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RuntimeConstants } from 'src/app/shared/constants';
import { Observable } from 'rxjs';
import { CustomerCredential, TerminalSummary } from '../../application-login/interface';

@Injectable()
export class ApplicationLoginService {
  applicationLoginApiUrl = '';
  constructor(private httpService: HttpClient) {
    this.applicationLoginApiUrl = RuntimeConstants.API_PATH + 'logins';
  }

  getCustomer(customerCredential: CustomerCredential): Observable<any> {
    return this.httpService.post<any>(`${this.applicationLoginApiUrl}/GetCustomer`, customerCredential);
  }

  checkTerminalName(terminalDetails): Observable<any> {
    return this.httpService.post<any>(`${this.applicationLoginApiUrl}/CheckTerminalName`, terminalDetails);
  }

  getUserPreference(userId: number): Observable<any> {
    return this.httpService.get(`${RuntimeConstants.API_PATH}Manages/GetUserPreference/` + userId);
  }

  logoutTheUsedTerminal(terminalId: number): Observable<any> {
    return this.httpService.post(`${this.applicationLoginApiUrl}/LogoutTheUsedTerminal/` + terminalId, null);
  }

  getUserTrainingBadges(userId: number): Observable<any> {
    return this.httpService.get(`${RuntimeConstants.API_PATH}usertrainingbadge/` + userId);
  }

  getUserTrainingDetails(userId: number): Observable<any> {
    return this.httpService.get(`${RuntimeConstants.API_PATH}usertraining/details/` + userId);
  }

  getUserByCode(code: string): Observable<any> {
    return this.httpService.post(`${this.applicationLoginApiUrl}/GetUserByCode/` + code, code);
  }

  clockLoginCheck(password: string): Observable<any> {
    return this.httpService.post(`${this.applicationLoginApiUrl}/ClockLoginCheck/` + password, password);
  }

  clockIntoPos(clockInOutDetails: any): Observable<any> {
    return this.httpService.post(`${this.applicationLoginApiUrl}/ClockIntoPos`, clockInOutDetails);
  }

  clockOutOfPos(clockInOutDetails: any): Observable<any> {
    return this.httpService.post(`${this.applicationLoginApiUrl}/ClockOutOfPos`, clockInOutDetails);
  }

  clockedOk(userId: number): Observable<any> {
    return this.httpService.post(`${this.applicationLoginApiUrl}/ClockedOK/` + userId, userId);
  }

  getLoginById(userId: number): Observable<any> {
    return this.httpService.post(`${this.applicationLoginApiUrl}/GetLoginById/` + userId, userId);
  }
}

