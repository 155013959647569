import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { GridColumn, DateColumn, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { OrderEntryInventoryInfo } from 'src/app/orders/interface/order-entry-inventory-info';
import { SalesProductsService } from 'src/app/information-management';
import { AlertsService, SpinnerService } from 'src/app/shared';
import { finalize } from 'rxjs/operators';
import { Format } from '@tarktech/tark-ng-utils/table/format-type';
declare let $: any;
@Component({
  selector: 'pos-order-entry-inventory-info',
  templateUrl: './order-entry-inventory-info.component.html',
  styleUrls: ['./order-entry-inventory-info.component.scss']
})
export class OrderEntryInventoryInfoComponent implements OnInit {
  @Input("productId") productId: number;
  inventoryInfo: Array<OrderEntryInventoryInfo> = [];
  @ViewChild('supplyRemainingTemplate', { static: true }) private supplyRemainingTemplate: any;
  @ViewChild('onHandTemplate', { static: true }) private onHandTemplate: any;
  @ViewChild('lastReceivedAmountTemplate', { static: true }) private lastReceivedAmountTemplate: any;
  screenHeight: number;
  inventoryInfoColumns: Array<GridColumn> = [];
  constructor(
    private salesProductService: SalesProductsService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService
  ) { }

  ngOnInit() {
    this.configureGridColumns();
    this.getInventoryInfo();
    this.screenHeight = $(window).height() - 500 < 150 ? 150 : 340;
    this.resizeWindow();
  }

  resizeWindow = () => {
    $(window).resize(() => {
      this.screenHeight = $(window).height() - 500 < 150 ? 150 : 340;
    });
  }

  configureGridColumns() {
    const supplyRemainingColumn = new TemplateColumn({
      HeaderText: 'Supply Remaining',
      itemTemplate: this.supplyRemainingTemplate,
      Field: "SupplyRemaining",
      Width: '20%',
      TextAlign: TextAlign.Right
    });

    const onHandColumn = new TemplateColumn({
      HeaderText: 'On Hand',
      itemTemplate: this.onHandTemplate,
      Field: "LastReceivedAmount",
      Width: '15%',
      TextAlign: TextAlign.Right
    });
    const lastReceivedAmountColumn = new TemplateColumn({
      HeaderText: 'Last Received',
      itemTemplate: this.lastReceivedAmountTemplate,
      Field: "OnHand",
      Width: '15%',
      TextAlign: TextAlign.Right
    });
    this.inventoryInfoColumns = [
      new GridColumn({ HeaderText: 'Recipe', Field: 'InventoryProductName', Width: '30%' }),
      new GridColumn({ HeaderText: 'Size', Field: 'SizeName', Width: '10%' }),
      // new GridColumn({ HeaderText: 'Supply Remaining', Field: 'SupplyRemaining', Width: '20%' }),
      supplyRemainingColumn,
      onHandColumn,
      new GridColumn({ HeaderText: 'Unit', Field: 'UnitName', Width: '20%' }),
      lastReceivedAmountColumn,
      new DateColumn({ HeaderText: 'Last Received Date', Field: 'LastReceivedDate', Width: '25%', Format: Format.Date, }),
    ] as Array<GridColumn>;

  }

  getInventoryInfo() {
    this.salesProductService.getProductInventory(this.productId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (salesProductInventory) => {
          this.inventoryInfo = salesProductInventory;
        }, error: this.alertService.showApiError
      });
  }
}
