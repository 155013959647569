import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { cloneDeep, forEach } from 'lodash';
import { Subscription } from 'rxjs/internal/Subscription';
import { AlertsService, ApplicationStateService, DomainConstants, ModalService, RabbitMQService, RuntimeConstants, SpinnerService } from 'src/app/shared';
import { MenuDisplayItem, WidgetComponentProperties } from '../../interfaces';
import { ItemWidgetConfig } from '../../interfaces/item-widget-config';
import { ItemWidgetStyleConfig } from '../../interfaces/item-widget-style-config';
import { MenuWidgetService } from '../../services';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
declare let $: any;

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pos-menu-item-widget',
  templateUrl: './menu-item-widget.component.html',
  styleUrls: ['./menu-item-widget.component.scss']
})
export class MenuItemWidgetComponent extends BaseWidgetComponent implements OnInit, WidgetComponentProperties {
  widgetStyleConfig: ItemWidgetStyleConfig = null;
  interactiveStyleClass = '';
  salesProduct: MenuDisplayItem;
  tagIconSize = 14;
  productImageSize = 30;
  imagePath = RuntimeConstants.IMAGE_BASE_PATH + '/menu-display';
  tagIconPath = RuntimeConstants.IMAGE_BASE_PATH + '/tags';
  outOfStockBehaviorTypes = DomainConstants.OutOfStockBehaviorTypes;
  productWidgetConfig: ItemWidgetConfig;
  bannerHeight = '60px';
  rabbitMqSalesProductOutOfStockEventSubscription: Subscription;
  rabbitMqSalesProductReplenishedEventSubscription: Subscription;

  constructor(protected menuWidgetService: MenuWidgetService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected applicationStateService: ApplicationStateService,
    protected modalService: ModalService,
    protected rabbitMQService: RabbitMQService) {
    super(menuWidgetService, alertService, spinnerService, applicationStateService, modalService, rabbitMQService);
    this.salesProduct = menuWidgetService.getNewMenuDisplayItem();
    this.salesProduct.Text = 'Sample sales product';
    this.widgetStyleConfig = menuWidgetService.getNewProductWidgetStyle();
  }

  ngOnInit(): void {
    this.loadSubscriptions();
    this.loadWidget(this.widgetId);
    this.subscribeToSalesProductOutOfStockEventExchange();
    this.subscribeToSalesProductReplenishedEventExchange();
  }

  loadSubscriptions() {
    this.widgetData$.subscribe({
      next: (res) => {
        this.salesProduct = res.MenuDisplayItems && res.MenuDisplayItems[0] ? res.MenuDisplayItems[0] : this.menuWidgetService.getNewMenuDisplayItem();
        if (!this.salesProduct.Id) {
            this.salesProduct.IsInStock = true;
            this.salesProduct.Text = res.Name;
        }
        this.productWidgetConfig = res.WidgetConfig ? this.parseJson<ItemWidgetConfig>(res.WidgetConfig) : null;
        this.widgetStyleConfig = res.WidgetStyleConfig ? this.parseJson<ItemWidgetStyleConfig>(res.WidgetStyleConfig) : null;
        if (this.widgetStyleConfig?.BorderStyle) {
          $('.widget-border-' + this.widgetId)?.css(this.widgetStyleConfig.BorderStyle);
        }
        $('.widget-background-' + this.widgetId)?.css({ 'background': this.widgetStyleConfig?.WidgetBackgroundColor });

        this.interactiveStyleClass = this.salesProduct?.InteractiveStyleClass;
        this.prepareStyleConfig();
      }
    });
  }

  prepareStyleConfig = () => {
    let fontSize = 0;
    if (this.widgetStyleConfig?.ItemFontStyle?.fontSize) {
      fontSize = parseInt((this.widgetStyleConfig?.ItemFontStyle?.fontSize)?.replace('px', ''), 10);
    }
    this.tagIconSize = fontSize ? fontSize - 1 : this.tagIconSize;
    this.productImageSize = fontSize ? fontSize * 1.5 : this.productImageSize;

    if (this.productWidgetConfig?.OutOfStockBehavior?.TextStyleConfig) {
      this.productWidgetConfig.OutOfStockBehavior.TextStyleConfig.lineHeight =
        (this.productWidgetConfig?.OutOfStockBehavior?.TextStyleConfig?.fontSize ?
          this.productWidgetConfig?.OutOfStockBehavior?.TextStyleConfig?.fontSize : '');
    }
    if (this.widgetStyleConfig?.DescriptionStyle) {
      this.widgetStyleConfig.DescriptionStyle.lineHeight =
        (this.widgetStyleConfig?.DescriptionStyle?.fontSize ? this.widgetStyleConfig?.DescriptionStyle?.fontSize : '');
    }
    this.bannerHeight = (this.widgetStyleConfig?.WidgetHeaderStyleConfig?.HeaderHeight ?? 60) + 'px';
  }

  subscribeToSalesProductOutOfStockEventExchange = () => {
    this.rabbitMqSalesProductOutOfStockEventSubscription = this.rabbitMQService.subscribeToSalesProductOutOfStockEventExchange$()
      .subscribe({
        next: (message) => {
          if (message.Payload.POSEvent && message.Payload.POSEvent.SalesProductId == this.salesProduct.Id) {
            this.setSalesProductStockStatus(message.Payload.POSEvent.SizeIds, false);
          }
        }
      });
  }

  subscribeToSalesProductReplenishedEventExchange = () => {
    this.rabbitMqSalesProductReplenishedEventSubscription = this.rabbitMQService.subscribeToSalesProductReplenishedEventExchange$()
      .subscribe({
        next: (message) => {
          if (message.Payload.POSEvent && message.Payload.POSEvent.SalesProductId == this.salesProduct.Id) {
            this.setSalesProductStockStatus(message.Payload.POSEvent.SizeIds, true);
          }
        }
      });
  }

  setSalesProductStockStatus(sizeIds: number[], status: boolean) {
    forEach(this.salesProduct.Prices, (price) => {
      if (sizeIds?.some((size) => size == price?.SizeId )) {
        price.IsInStock = status;
      }
    });
    this.salesProduct.IsInStock = this.salesProduct.Prices.some(x => x.IsInStock);
  }

  editWidget(id: number, data?: any): void {
    throw new Error('Method not implemented.');
  }
}
