import { Component, OnInit, ViewChild } from '@angular/core';
import { EquipmentType } from '../../interface/equipment-type';
import { Permissions, Levels, Messages, DomainConstants } from '../../../../shared/constants/';
import { BaseListComponent } from 'src/app/shared/components/base-list.component';
import { GridColumn, TableComponent } from '@tarktech/tark-ng-utils';
import { EquipmentTypeService } from '../../service/equipment-type.service';
import { AuthenticationService } from 'src/app/shared/auth/services/authentication.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import * as _ from 'lodash';
import { ApplicationStateService } from 'src/app/shared';
import { ActivatedRoute, Router } from '@angular/router';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { equipmentPropertiesDolly } from 'src/app/shared/components/icon';
@Component({
    selector: 'pos-equipment-type-list',
    templateUrl: './equipment-type-list.component.html',
})
export class EquipmentTypeListComponent extends BaseListComponent<EquipmentType> implements OnInit {

    permission = {
        name: Permissions.EquipmentsTypes,
        readOnlyLevel: Levels.ReadOnly,
        editLevel: Levels.Edit
    };
    equipmentTypeColumns: Array<GridColumn> = [];
    equipmentTypes: Array<EquipmentType> = [];
    id: number;
    icons = { equipmentPropertiesDolly };
    @ViewChild('equipmentTypeGrid', { static: true }) equipmentTypeGrid: TableComponent;

    constructor(
        private authenticationService: AuthenticationService,
        protected alertService: AlertsService,
        protected spinnerService: SpinnerService,
        protected equipmentTypeService: EquipmentTypeService,
        protected modalService: ModalService,
        protected route: ActivatedRoute,
        protected printService: PrintTableService,
        protected applicationStateService: ApplicationStateService,
        private router: Router
    ) {
        super(equipmentTypeService, alertService, spinnerService, modalService, applicationStateService, printService, route);
    }
    ngOnInit() {
        this.deleteSuccessMessage = Messages.EquipmentTypeDeleted;
        this.equipmentTypeColumns = this.configureGridColumns();
        this.checkPermission();
        this.loadSubscriptions();
        this.equipmentTypeGrid.context = this.gridContext;
    }
    public getGridColumns(): Array<GridColumn> {
        return [
            new GridColumn({ HeaderText: 'Equipment Type', Field: 'Name', IsSortable: true, Width: '95%' })
        ] as Array<GridColumn>;
    }
    private checkPermission(): void {
        if (!this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
            _.remove(this.equipmentTypeColumns, (column) => {
                return (column.Type === DomainConstants.GridColumnsTypes.EditColumn
                    || column.Type === DomainConstants.GridColumnsTypes.DeleteColumn);
            });
        }
    }
    private loadSubscriptions(): void {
        this.data$.subscribe({
            next: (res) => {
                this.equipmentTypes = res;
            }
        });
    }
    public editItem(id: number, data?: EquipmentType): void {
        this.router.navigate([id], { relativeTo: this.route });
    }
    public getConfirmDeleteMessage(data: EquipmentType): string {
        return StringUtils.format(Messages.ConfirmDeleteEquipmentType,
            { 'equipmentType': data ? data.Name : '' }
        );
    }

    close() {
        this.router.navigate(['../'], { relativeTo: this.route });
    }
}
