import { Component, Input, OnInit } from '@angular/core';
import { IconSpecification } from '../icon/icon-specification';
import { squareSolid } from 'src/app/shared/components/icon';
import { cloneDeep, find, forEach, remove } from 'lodash';

@Component({
  selector: 'pos-action-icon',
  templateUrl: './action-icon.component.html',
  styleUrls: ['./action-icon.component.scss']
})
export class ActionIconComponent implements OnInit {
  icons = { squareSolid };
  @Input() icon: IconSpecification;
  @Input() iconText: string;
  @Input() additionalClasses?: Array<string>;
  @Input() spin?: boolean;
  @Input() pulse?: boolean;
  @Input() fixedWidth?: boolean;
  @Input() backgroundClasses?: Array<string> | string;
  @Input() additionalClassesAppend?: boolean;

  parentIcon: IconSpecification;
  displayIcon: IconSpecification;
  constructor() { }

  ngOnInit(): void {
    this.parentIcon = cloneDeep(squareSolid);
    this.displayIcon = cloneDeep(this.icon);
      if (this.additionalClassesAppend) {
        forEach(this.additionalClasses, (c) => {
          this.displayIcon.additionalClasses.push(c);
        });
      } else if (this.additionalClasses) {
        this.displayIcon.additionalClasses = this.additionalClasses;
      }
      this.displayIcon.spin = this.displayIcon.spin !== this.spin ? this.spin : this.displayIcon.spin;
      this.displayIcon.pulse = this.displayIcon.pulse !== this.pulse ? this.pulse : this.displayIcon.pulse;
      this.displayIcon.fixedWidth = this.displayIcon.fixedWidth !== this.fixedWidth ? this.fixedWidth : this.displayIcon.fixedWidth;
      this.displayIcon.backgroundClasses = this.backgroundClasses && this.displayIcon.backgroundClasses !== this.backgroundClasses ?
        this.backgroundClasses : this.displayIcon.backgroundClasses;
    if (!this.iconText) {
      const isStackClassApplied = find(this.displayIcon.additionalClasses, x => x === 'fa-stack-1x');
      if (!isStackClassApplied) {
        this.displayIcon.additionalClasses.push('fa-stack-1x');
      }
    }
    if (this.displayIcon && this.displayIcon.backgroundClasses) {
      if (!this.iconText) {
        if (typeof (this.displayIcon.backgroundClasses) === 'string') {
          this.parentIcon.additionalClasses.push(this.displayIcon.backgroundClasses);
        } else {
          forEach(this.displayIcon.backgroundClasses, (additionalClass) => {
            this.parentIcon.additionalClasses.push(additionalClass);
          });
        }
      } else {
        this.parentIcon.additionalClasses.push('icon-bg-default');
      }
    } else {
      this.parentIcon.additionalClasses.push('icon-bg-default');
    }
  }
}
