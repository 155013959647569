import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GridColumn, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { DomainConstants, Levels } from 'src/app/shared/constants';
import { WaitTimeAdjustmentRule } from '../../interfaces';
import {
  ban, check, checkCircleWhite, editWhite, plusWhite, deleteWhite
} from 'src/app/shared/components/icon';
import { BaseListComponent, ModalService, SpinnerService } from 'src/app/shared/components';
import { AlertsService, ApplicationStateService, ExportService, PrintTableService } from 'src/app/shared/services';
import { AuthenticationService, Messages, StringUtils } from 'src/app/shared';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { WaitTimeRuleService } from '../../services/wait-time-rule.service';

@Component({
  selector: 'pos-wait-time-rules',
  templateUrl: './wait-time-rules.component.html'
})
export class WaitTimeRulesComponent extends BaseListComponent<WaitTimeAdjustmentRule> implements OnInit {

  waitTimeRuleType = DomainConstants.WaitTimeRuleTypes.Global;
  ruleColumns: Array<GridColumn> = [];
  printRuleColumns: Array<GridColumn> = [];
  filteredRules: Array<WaitTimeAdjustmentRule> = [];
  rules: Array<WaitTimeAdjustmentRule> = [];
  showInactiveRules = false;
  permission = {
    name: null,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };
  icons = {
    plusWhite,
    editWhite,
    check,
    ban,
    checkCircleWhite,
    deleteWhite
  };
  @ViewChild('activeTemplate', { static: true }) activeTemplate: TemplateRef<any>;
  @ViewChild('operationTemplate', { static: true }) operationTemplate: TemplateRef<any>;
  @ViewChild('headerTemplate', { static: true }) headerTemplate: TemplateRef<any>;
  constructor(protected waitTimeRuleService: WaitTimeRuleService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected applicationStateService: ApplicationStateService,
    protected printService: PrintTableService,
    protected exportService: ExportService,
    protected authenticationService: AuthenticationService,
    private router: Router,
    protected route: ActivatedRoute) {
    super(waitTimeRuleService, alertService, spinnerService, modalService, applicationStateService, printService, route);
    this.waitTimeRuleType = this.waitTimeRuleService.getWaitTimeRuleTypeByRoute(route.snapshot?.url[0]?.path);
    this.permission.name = this.waitTimeRuleService.getWaitTimeRuleTypeByRoute(route.snapshot?.url[0]?.path).Permission;
  }

  ngOnInit(): void {
    this.ruleColumns = this.configureGridColumns();
    this.deleteSuccessMessage = Messages.WaitTimeRuleDeleteSuccess;
    this.loadSubscriptions();
  }

  private loadSubscriptions(): void {
    this.data$.subscribe({
      next: (res) => {
        this.rules = res.filter(x => x.Type === this.waitTimeRuleType.Value);
        this.filteredRules = this.rules.filter(x => x.IsActive);
      }
    });
  }

  changeRulesList() {
    if (this.showInactiveRules) {
      this.filteredRules = this.rules;
    } else {
      this.filteredRules = this.rules.filter(x => x.IsActive);
    }
  }

  configureGridColumns(): GridColumn[] {
    const activeColumn = new TemplateColumn({
      HeaderText: 'Active',
      Field: 'IsActive',
      itemTemplate: this.activeTemplate,
      TextAlign: TextAlign.Center,
      Width: '8%',
      IsSortable: false
    });
    const operationColumn = new TemplateColumn({
      itemTemplate: this.operationTemplate,
      Width: '147px',
      headerTemplate: this.headerTemplate,
      TextAlign: TextAlign.Center,
      CellClass: 'cell-padding'
    });
    const ruleColumns = [
      new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: false, Width: '80%' }),
      activeColumn
    ];
    this.printRuleColumns = [...ruleColumns];
    if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      ruleColumns.push(operationColumn);
    }
    return ruleColumns;
  }

  getGridColumns(): GridColumn[] {
    return this.ruleColumns;
  }

  editItem(id: number, data?: WaitTimeAdjustmentRule): void {
    this.router.navigate([id], { relativeTo: this.route });
  }

  getConfirmDeleteMessage(data: WaitTimeAdjustmentRule): string {
    return Messages.WaitTimeRuleConfirmDelete;
  }
  protected printData() {
    this.printService.printEmitter.next({ gridColumns: this.printRuleColumns, gridData: this.filteredRules });
  }

  exportCSV() {
    this.exportService.exportCSV('wait-time-rules', this.filteredRules, null, this.printRuleColumns);
  }

  updatePromotionActiveStatus(data: WaitTimeAdjustmentRule, isActive: boolean) {
    data.IsActive = isActive;
    this.spinnerService.show();
    this.waitTimeRuleService.update(data)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          this.changeRulesList();
          this.alertService.renderSuccessMessage(StringUtils.format(Messages.WaitTimeRuleActiveStatusChange, { activeMessage: isActive ? 'activated' : 'deactivated' }));
        }, error: this.alertService.showApiError
      });
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
