import { AfterContentInit, Component, Input, ViewChild } from '@angular/core';
import { filter, find } from 'lodash';
import { AlertsService, FieldInfoMessages, Messages, SpinnerService, Permissions, Levels, ModalFormComponent, ModalService, FormUtilityService, ApplicationStateService } from 'src/app/shared';
import { BaselineExpense } from '../../interface';
import { BaselineExpenseService } from '../../services/baseline-expense.service';
import { finalize } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'pos-baseline-expenses-edit',
  templateUrl: './baseline-expenses-edit.component.html',
})
export class BaselineExpensesEditComponent extends ModalFormComponent implements AfterContentInit {
  @ViewChild('baselineExpensesForm') baselineExpensesForm: NgForm;
  get getForm(): NgForm {
    return this.baselineExpensesForm
  }
  @Input() editIndex: number;
  @Input() baselineExpenses: Array<BaselineExpense>;
  baselineExpense: BaselineExpense;
  fieldInfoMessages = FieldInfoMessages;
  currencySymbol = null;
  permission = {
    name: Permissions.SystemConfigurationBaselineExpenses,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };
  constructor(
    protected spinnerService: SpinnerService,
    protected alertService: AlertsService,
    protected baselineExpenseService: BaselineExpenseService,
    protected applicationStateService: ApplicationStateService,
    public modalRef: BsModalRef,
    public modalService: ModalService,
    protected formUtilityService: FormUtilityService
  ) {
    super(modalRef, modalService, formUtilityService);
    this.baselineExpense = baselineExpenseService.newBaselineExpense();
    this.currencySymbol = applicationStateService.settingParam.CurrencySymbol;
  }

  ngAfterContentInit() {
    this.loadDependencies();
  }

  loadDependencies(): void {
    if (this.editIndex > 0) {
      this.baselineExpense = find(this.baselineExpenses, baselineExpense => this.editIndex === baselineExpense.Id);
    }
  }

  saveBaselineExpense(isValid: boolean) {
    if (!isValid) {
      return;
    }
    const enteredExpense = this.baselineExpense.Expense.toUpperCase();
    if (this.editIndex) {
      const checkBaselineExpensesArray = [...this.baselineExpenses];
      checkBaselineExpensesArray.splice(this.editIndex - 1, 1);
      const baselineExpenseAvailable = filter(checkBaselineExpensesArray, (baselineExpense) => {
        return baselineExpense.Expense.toUpperCase() === enteredExpense;
      });
      if (baselineExpenseAvailable.length <= 0) {
        this.baselineExpenses[this.editIndex - 1] = this.baselineExpense;
        this.saveBaselineExpensesDetails();
      } else {
        this.alertService.renderErrorMessage(Messages.ErrorWhileSameExpenseAvailable);
      }
    } else {
      const baselineExpenseAvailable = filter(this.baselineExpenses, (baselineExpense) => {
        return baselineExpense.Expense.toUpperCase() === enteredExpense;
      });
      if (baselineExpenseAvailable.length <= 0) {
        this.baselineExpenses.push(this.baselineExpense);
        this.saveBaselineExpensesDetails();
      } else {
        this.alertService.renderErrorMessage(Messages.ErrorWhileSameExpenseAvailable);
      }
    }
  }

  saveBaselineExpensesDetails() {
    this.baselineExpenses = filter(this.baselineExpenses, (baselineExpense) => {
      return baselineExpense.Expense !== 'Total';
    });
    this.spinnerService.show();
    this.baselineExpenseService.saveBaselineExpensesDetail(this.baselineExpenses)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.alertService.renderSuccessMessage(Messages.BaselineExpenseSaveSuccess);
          this.hide({ shouldReload: true });
        }, error: this.alertService.showApiError
      });
  }

  cancelBaselineExpense() {
    this.hide({});
  }
}
