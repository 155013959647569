<div class="h-80">
    <pos-header-entry-main [links]="headerLinks" [homeState]="homeState" [isShowBarIcon]="false"></pos-header-entry-main>
</div>
<div class="page">
    <h3 class="page__title"></h3>
    <div class="col-12 g-0 px-2 justify-content-center row">
        <div *ngFor="let instruction of instructionsBuffer" [ngStyle]="SetInstructionItemStyle()">
             <pos-instruction [instruction]="instruction" 
             [countInstructions]="instructionsBuffer?.length" 
             (removeProduct)="removeProduct($event)"></pos-instruction>
        </div>
    </div>
</div>

