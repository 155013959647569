<div class="m-0-auto px-pix-10 list-width-70">
    <pos-list-wrapper heading="Widget Group" (close)="cancel()">
        <h4><b><span class="header-lightgrey">Menu Widget{{widget?.Name ? ': ' :
                    ''}}{{widget?.Name}}</span></b></h4>
        <h4 class="pi-0"><b><span class="header-lightgrey">Menu Display: {{menu?.Name}} ({{menu?.Width}} X
                    {{menu?.Height}})</span></b></h4>
        <div class="col-12 pb-pix-5">
            <div>
                <ng-table [columns]="menuWidgetColumns" [data]="widgetGroupList" [isReorderableTable]="true"
                    [ReorderableColumnRow]="'row'" (onRowReorder)="gridRowReorder($event)">
                </ng-table>
            </div>
        </div>
    </pos-list-wrapper>
</div>
<pos-print-table [hidden]=" true"></pos-print-table>
