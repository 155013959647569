<div class="h-80 mb-4">
    <pos-header-entry-main [links]="headerLinks"></pos-header-entry-main>
</div>
<div class="container card shadow">
    <div class="card-body">
        <div class="d-flex justify-content-between align-items-center mb-4">
            <div class="d-flex align-items-center">
                <pos-icon [icon]="icons.watch" class="pe-pix-10 vertical-center-align page-content__header-icon"></pos-icon>
                <h3 class="page-content__header font-1-7em">Waitlist</h3>
            </div>
            <div class="font-1-7em fw-bold"></div>
            <button class="btn btn-success white btn-lg shadow-sm" (click)="OpenAddNewEntryModal()">
                <i class="fas fa-plus-circle"></i> Add
            </button>
        </div>
        <div class="table-container" [style.maxHeight]="scrollHeight + 'px'" [style.height]="scrollHeight + 'px'">
            <table class="table table-striped text-align-center">
                <thead class="table-light header-color">
                    <tr>
                        <th scope="col" class="fs-5">Code</th>
                        <th scope="col" class="fs-5">Phone Number</th>
                        <th scope="col" class="fs-5">Time in Waitlist</th>
                        <th scope="col" class="fs-5"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let entry of waitListEntries; let i = index;" class="align-middle">
                        <!-- a code to hide and show code -->
                        <!-- <td class="fs-5 text-primary pointer" style="min-width: 150px;">
                            <div class="d-flex justify-content-center align-items-center" (click)="showCode[i] = !showCode[i]">
                                <span *ngIf="!showCode[i]">{{ getMaskedCode(entry.Code) }}</span>
                                <i *ngIf="!showCode[i]" class="fas fa-eye ps-2"></i>
                                <span *ngIf="showCode[i]">{{ entry.Code }}</span>
                                <i *ngIf="showCode[i]" class="fas fa-eye-slash ps-2"></i>
                            </div>
                        </td> -->
                        <td class="fs-5 text-primary" style="min-width: 150px;">{{ entry.Code }}</td>
                        <td class="fs-5 text-muted">{{ entry.PhoneNumber | phoneMaskPipe }}</td>
                        <td class="fs-5 text-info">{{ entry.waitingTime$ | async }}</td>
                        <td>
                            <button class="btn btn-primary btn-lg shadow-sm me-1 position-relative action-button-width" (click)="notify(entry)">
                                <i class="fas fa-bell"></i>
                                <span class="translate-middle badge rounded-pill" *ngIf="entry.NotifyCount">
                                    {{ entry.NotifyCount }}
                                </span>
                            </button>
                            <button class="btn btn-danger btn-lg shadow-sm ms-3 action-button-width" (click)="openDeleteEntryModal(entry)">
                                <i class="fas fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

