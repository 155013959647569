export class MessageEndpoint {

    public Id: number;
    public Name: string;

    constructor() {
        this.Id = null;
        this.Name = '';
    }
}
