import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormUtilityService, ModalFormComponent, ModalService } from 'src/app/shared';
declare let $: any;
@Component({
  selector: 'pos-inventory-product-memo',
  templateUrl: './inventory-product-memo.component.html',
  styleUrls: []
})
export class InventoryProductMemoComponent extends ModalFormComponent implements OnInit {

  get getForm(): NgForm {
    return this.productMemoForm;
  }
  @Input() productMemo = '';
  @ViewChild('productMemoForm') productMemoForm: NgForm;
  constructor(
    modalRef: BsModalRef,
    modalService: ModalService,
    formUtilityService: FormUtilityService
  ) {
    super(modalRef, modalService, formUtilityService);
  }

  ngOnInit(): void {
    $('input[name="inventoryMemo"]').focus();
  }

  saveMemo() {
    this.hide({ reload: true, productMemo: this.productMemo});
  }

  cancelMemo() {
    this.hide({ reload: false });
  }
}
