<pos-edit-wrapper [isPopup]="false" [headingIcon]="icons.dollarSign"
    heading="{{this.id? 'Edit': 'Add'}} Sales Product Price History{{this.id? ': ' + salesProductName: ''}}"
    (close)="onCancel()">
    <form #salesProductSizeHistoryForm="ngForm"
        (ngSubmit)="saveSalesProductSizeHistory(salesProductSizeHistoryForm.valid)" autocomplete="off">
        <context-provider provide="props">
            <div class="col-12 pos-page-body border-top pt-pix-5">
                <div class="col-12">
                    <pos-row>
                        <pos-column>
                            <pos-form-field label="Size" [validationMessages]="{ required: 'Please select size.'}">
                                <ng-select posInput name="productSize" [items]="salesSizeList" bindLabel="Name"
                                    bindValue="Id" #productSize="ngModel" [(ngModel)]="salesProductSizeHistory.SizeId"
                                    placeholder="Select size" [disabled]="id" [required]="true">
                                </ng-select>
                            </pos-form-field>
                        </pos-column>
    
                        <pos-column>
                            <pos-form-field label="Price" [validationMessages]="{ required: 'Please enter price.'}">
                                <p class="input-group">
                                    <span class="input-group-addon">{{currencySymbol}}</span>
                                    <input posInput class="form-control price-input" name="price" #price="ngModel"
                                        type="number" [(ngModel)]="salesProductSizeHistory.Price" [required]="true">
                                </p>
                            </pos-form-field>
                        </pos-column>
    
                        <pos-column>
                            <pos-form-field label="Date" [validationMessages]="{required: 'Please enter edit date.'}"
                                *ngIf="!id" inputStyle="col-xl-4 col-12">
                                <p-calendar posInput [(ngModel)]="salesProductSizeHistory.DateEffective" [showIcon]="true"
                                    [dateFormat]="dateFormat" [readonlyInput]="true" name="dateEffective"
                                    yearNavigator="true" [showTime]="true" [hourFormat]="hourFormat"
                                    [inputStyle]="{'width':'unset','display':'unset'}" inputStyleClass="form-control"
                                    yearRange="2000:2099" #dateEffective="ngModel" [monthNavigator]="true" [required]="true"
                                    [maxDate]="today"
                                    [ngClass]="{'has-error': (dateEffective.invalid && salesProductSizeHistoryForm.submitted)}">
                                </p-calendar>
                            </pos-form-field>
                        </pos-column>
                    </pos-row>
                </div>
            </div>
            <div class="col-12 action-button-container page-footer">
                <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save </button>
                <button type="button" class="btn-secondary btn-lg btn btn-cancel pos-button" (click)="onCancel()">Cancel</button>
            </div>
        </context-provider>
    </form>
</pos-edit-wrapper>
