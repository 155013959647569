import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { find } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DomainConstants, FormUtilityService, ICloseable, ModalFormComponent, ModalService } from 'src/app/shared';
import { EventSubscriptionActionAttribute } from '../../interfaces/event-subscription-action-attribute';

@Component({
  selector: 'pos-delay-configuration',
  templateUrl: './delay-configuration.component.html',
  styleUrls: ['./delay-configuration.component.scss']
})
export class DelayConfigurationComponent extends ModalFormComponent implements OnInit, ICloseable {

  @ViewChild('delayActivityEditForm', { static: true }) delayActivityEditForm: NgForm;
  delaySeconds: number = null;
  @Input() actionId = 0;
  @Input() eventSubscriptionActionAttributes: Array<EventSubscriptionActionAttribute> = [];
  @Input() eventAttributes;
  @Input() deliveryTypes = [];
  selectedDeliveryType;
  @Input() eventIdentifier: string;
  deliveryAttributes = DomainConstants.EventDeliveryAttributes;

  get getForm(): NgForm {
    return this.delayActivityEditForm;
  }

  constructor(modalRef: BsModalRef, modalService: ModalService, protected formUtilityService: FormUtilityService) {
    super(modalRef, modalService, formUtilityService);
  }

  ngOnInit(): void {
    const seconds = find(this.eventSubscriptionActionAttributes,
      x => x.AttributeKey === this.deliveryAttributes.Delay.Seconds)?.AttributeValue;
    this.delaySeconds = seconds ? parseInt(seconds, 10) : null;
    this.setInitialValue();
  }

  saveActivity = (isValid: boolean) => {
    if (isValid) {
      const actionAttributes = this.prepareEventActionAttributes();
      this.hide({ shouldReload: true, actionAttributes: actionAttributes });
    }
  }

  onClose = (shouldReload: boolean) => {
    this.hide({ shouldReload: shouldReload });
  }

  prepareEventActionAttributes() {
    const actionDeliveryAttributes: Array<EventSubscriptionActionAttribute> = [];
    this.addAttribute(this.deliveryAttributes.Delay.Seconds, this.delaySeconds.toString(),
      actionDeliveryAttributes);
    return actionDeliveryAttributes;
  }

  addAttribute = (key, value, array) => {
    const actionDeliveryAttribute: EventSubscriptionActionAttribute = {
      Id: this.getActionAttributeId(key),
      AttributeKey: key,
      AttributeValue: value,
      EventSubscriptionActionId: this.actionId
    };
    array.push(actionDeliveryAttribute);
  }

  getActionAttributeId(key) {
    const attribute = find(this.eventSubscriptionActionAttributes, x => x.AttributeKey === key);
    return attribute ? attribute.Id : 0;
  }

}
