<div class="col-12 row mx-0 p-1">
  <div class="col-12 ps-0 bold"
    *ngIf="orderData.CustomerAccount && orderData.CustomerAccount.Name">
    <span>{{loyaltyAccountTerm}}:</span>
    {{orderData.CustomerAccount.Name}}
  </div>
  <div class="col-md-8 col-12 ps-0 bold">
    <span *ngIf="orderData.OrderAttributes.PickupTime">
      Online Order - Pickup Time: {{ today === pickupDate ? (orderData.OrderAttributes.PickupTime | tark_time) : (orderData.OrderAttributes.PickupTime | tark_date_time )}}
    </span>
  </div>
  <div class="col-6 ps-0 bold">
    <span *ngIf="orderData.Account">
      <span *ngIf="orderData.Account.Type">{{orderData.Account.Type == accountTypes.Tab ? accountTerm : tableTerm}}:</span>
      {{orderData.Account.Name}}
    </span>
  </div>
  <div class="col-6 ps-0 pe-pix-5 bold text-end">Order #{{orderData.SurrogateOrderId}}</div>
</div>
<div class="col-12 p-0 panel-overflow order-invoice-detail"
  [ngStyle]="{'max-height': height + 'px', 'min-height': height + 'px'}">
  <pos-order-summary-products [orderData]="orderData" [isShowIcon]="true" [isShowSeatTotal]="true"></pos-order-summary-products>
</div>
<div class="clearfix"></div>
<pos-settle-total [orderData]="orderData"></pos-settle-total>
