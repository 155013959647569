import { Component, OnInit, ViewChild } from '@angular/core';
import { MultiProductGroup } from '../multi-product-group/multi-product-group';
import { BaseListComponent } from 'src/app/shared/components/base-list.component';
import { AlertsService, ApplicationStateService, ModalService, MultiProductGroupService, PrintTableService, SpinnerService } from 'src/app';
import { ActivatedRoute, Router } from '@angular/router';
import { GridColumn, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { deleteWhite, editWhite, layerGroup, plusWhite } from 'src/app/shared/components/icon/icons';
import { AuthenticationService } from 'src/app/shared/auth';
import { Permissions, Levels, Messages } from '../../../../shared/constants/';
import { MultiProductGroupComponent } from '../multi-product-group/multi-product-group.component';
import { ExportService } from 'src/app/shared/services/export.service';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
@Component({
  selector: 'pos-multi-product-group-list',
  templateUrl: './multi-product-group-list.component.html',
  styleUrls: ['./multi-product-group-list.component.scss']
})
export class MultiProductGroupListComponent extends BaseListComponent<MultiProductGroup> implements OnInit {

  groups: Array<MultiProductGroup> = [];
  id: number;
  permission = {
    name: Permissions.MultipleProductGroups,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };
  icons = {
    layerGroup,
    plusWhite,
    editWhite,
    deleteWhite

  }
  @ViewChild('operationTemplate', { static: true }) public operationTemplate: any;
  @ViewChild('operationHeaderTemplate', { static: true }) public operationHeaderTemplate: any;
  groupColumns: Array<GridColumn> = [];
  groupPrintColumns: Array<GridColumn> = [];
  constructor(private exportService: ExportService,
    multiProductGroupService: MultiProductGroupService,
    private authenticationService: AuthenticationService,
    alertService: AlertsService,
    spinnerService: SpinnerService,
    modalService: ModalService,
    applicationStateService: ApplicationStateService,
    printService: PrintTableService,
    private router: Router,
    route: ActivatedRoute) {
    super(multiProductGroupService, alertService, spinnerService, modalService, applicationStateService, printService, route);
  }
  ngOnInit() {
    this.groupColumns = this.getGridColumns();
    this.checkPermission();
    this.loadSubscriptions();
    this.deleteSuccessMessage = Messages.MultiProductGroupDeleted;
  }
  getGridColumns(): GridColumn[] {
    const columns = [
      new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true }),
    ];
    this.groupPrintColumns = [...columns];
    return columns;
  }
  editItem(id: number, data?: MultiProductGroup): void {
    this.id = id;
    const modelRef = this.modalService.show(MultiProductGroupComponent, {
      animated: false,
      class: 'vertical-center modal-md',
      keyboard: false,
      initialState: {
        id: id,
        name: data?.Name
      }
    });

    modelRef.close.subscribe((response) => {
      if (response.shouldReload) {
        this.reload();
      }
    });
  }
  private loadSubscriptions(): void {
    this.data$.subscribe({
      next: (res) => {
        this.groups = res;
      }
    });
  }

  getConfirmDeleteMessage(data: MultiProductGroup): string {
    return StringUtils.format(Messages.ConfirmDeleteMultipleProductGroupMap,
      { 'name': data.Name }
    );
  }

  exportCSV() {
    this.exportService.exportCSV('screens', this.groups, null, this.groupPrintColumns);
  }

  printData() {
    this.printService.printEmitter.next({
      gridColumns: this.groupPrintColumns,
      gridData: this.groups
    });
  }


  private checkPermission(): void {
    const operation = new TemplateColumn({
      itemTemplate: this.operationTemplate,
      headerTemplate: this.operationHeaderTemplate,
      TextAlign: TextAlign.Center,
      Width: '156px'
    });
    if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      this.groupColumns.push(operation);
    }
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

}
