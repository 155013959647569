import { ConfirmDeleteComponent } from 'src/app/shared/components/confirm-delete/confirm-delete.component';
import { AuthenticationService } from './../../../../shared/auth/services/authentication.service';
import { SpinnerService } from './../../../../shared/components/spinner/spinner.service';
import { AlertsService } from './../../../../shared/services/alerts.service';
import { AudioNotificationService } from '../../../../shared/services/audio-notification-file.service';
import { EditColumn, LinkColumn, DeleteColumn, GridColumn } from '@tarktech/tark-ng-utils';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ModalService, Messages, PrintTableService, BaseGridComponent, ApplicationStateService } from 'src/app/shared';
import { AudioNotificationEditComponent } from '../audio-notification-edit';
import { Permissions, Levels } from '../../../../shared/constants/';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { faPlay, infoManageBullHorn, pauseGreen, playGreenNormalFont } from 'src/app/shared/components/icon';
import { LinkTemplateColumn } from '@tarktech/tark-ng-utils/table/link-template-column';
@Component({
  selector: 'pos-audio-notification',
  templateUrl: './audio-notification.component.html',
})
export class AudioNotificationComponent extends BaseGridComponent implements OnInit, OnDestroy {

  constructor(private audioNotificationService: AudioNotificationService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private modalService: ModalService,
    private authenticationService: AuthenticationService,
    protected applicationStateService: ApplicationStateService,
    protected router: Router,
    protected route: ActivatedRoute,
    private printService: PrintTableService) { super(applicationStateService, route); }
  audioNotificationFileId = 0;
  audioNotificationFiles: any = [];
  audio = new Audio();
  @ViewChild('confirmAudioNotificationDelete') private confirmAudioNotificationDelete: ConfirmDeleteComponent;
  @ViewChild('playAudioTemplate', { static: true }) playAudioTemplate: TemplateRef<any>;
  audioNotificationColumns: Array<GridColumn> = [];
  printAudioNotificationColumns: Array<GridColumn> = [];
  public message = Messages.ConfirmDeleteAudioNotificationFile;
  icons = { infoManageBullHorn, playGreenNormalFont, pauseGreen };
  permission = {
    name: Permissions.AudioClips,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };

  ngOnInit() {
    this.configureGridColumns();
    this.reloadAudioNotifierDetails();
    this.audio.onended = () => {
      this.resetIsPlayingProperty();
    }
    this.audio.addEventListener('error', (ev) => {
      this.alertService.renderErrorMessage(this.audio.error.code === 4 ? Messages.ErrorWhenAudioFileNotFound : Messages.ErrorWhilePlayAudio);
      this.resetIsPlayingProperty();
    });

  }

  ngOnDestroy(): void {
    this.audio.pause();
    this.audio = null;
  }

  reloadAudioNotifierDetails() {
    this.spinnerService.show();
    this.audioNotificationService.getAudioNotificationFiles()
      .subscribe(
        response => {
          if (response) {
            this.audioNotificationFiles = response;
          } else {
            this.audioNotificationFiles = [];
          }
        },
        this.alertService.showApiError,
        () => {
          this.spinnerService.hide();
        }
      );
  }

  private configureGridColumns() {

    const editColumn = new EditColumn({
      Clicked: (notifier) => {
        this.resetAudioObject();
        this.editAudioNotifier(notifier);
      },
      Width: '57px',
      AddIcon: new LinkColumn({
        Clicked: () => {
          this.editAudioNotifier(0);
        },
        ToolTip: 'New'
      })
    });

    const deleteColumn = new DeleteColumn({
      Clicked: (data) => {
        this.resetAudioObject();
        this.deleteAudioNotifier(data);
      },
      Width: '83px',
      PrintIcon: new LinkColumn({
        Clicked: () => {
          this.printData();
        }
      })
    });


    const playAudioColumn = new LinkTemplateColumn({
      CellClass: 'cell-link', itemTemplate: this.playAudioTemplate, Width: '0px'
    });

    this.audioNotificationColumns = [
      new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '15%' }),
      new GridColumn({ HeaderText: 'File', Field: 'FileName', IsSortable: true, Width: '80%' }),
    ] as Array<GridColumn>;

    this.printAudioNotificationColumns = [...this.audioNotificationColumns];

    if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      this.audioNotificationColumns.push(editColumn);
      this.audioNotificationColumns.push(deleteColumn);
      this.audioNotificationColumns.push(playAudioColumn);
    }
  }
  private resetAudioObject() {
    this.audio.pause();
    this.resetIsPlayingProperty();
  }

  deleteAudioNotifier(data) {
    this.audioNotificationFileId = data.Id;
    this.confirmAudioNotificationDelete.Show(this.message);
  }

  onConfirmAcknowledge() {
    this.spinnerService.show();
    this.audioNotificationService.deleteAudioNotificationFile(this.audioNotificationFileId)
      .subscribe(
        () => {
          this.alertService.renderSuccessMessage(
            Messages.AudioNotificationFileDeleteSuccess
          );
          this.reloadAudioNotifierDetails();
        },
        this.alertService.showApiError,
        () => {
          this.spinnerService.hide();
        }
      );
  }

  editAudioNotifier(data) {
    const audioNotificationFileModalRef = this.modalService.show(AudioNotificationEditComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        audioNotifierId: data.Id,
        audioNotifierName: data.Name
      }
    });

    audioNotificationFileModalRef.close.subscribe(res => {
      if (res && res.shouldReload) {
        this.reloadAudioNotifierDetails();
      }
    });
  }

  playAudio(data) {
    if (!data.IsPlaying) {
      try {
        this.audio.src = '/media/audio-notification/' + data.FileName;
        this.audio.play();
        this.resetIsPlayingProperty();
      }
      catch (ex) {
        this.alertService.renderErrorMessage("Error occurred while play audio");
      }
    } else {
      this.audio.pause();
    }
    data.IsPlaying = !data.IsPlaying;
  }

  private resetIsPlayingProperty() {
    _.forEach(this.audioNotificationFiles, (file) => {
      file.IsPlaying = false;
    });
  }

  printData() {
    this.printService.printEmitter.next({ gridColumns: this.printAudioNotificationColumns, gridData: this.audioNotificationFiles });
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
