import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DateColumn } from '@tarktech/tark-ng-utils/table/date-column';
import { GridColumn } from '@tarktech/tark-ng-utils/table/grid-column';
import { TextAlign } from '@tarktech/tark-ng-utils/table/text-align';
import { BaseListComponent } from 'src/app/shared/components/base-list.component';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import { SalesProductHistory } from '../../interface/sales-product-history';
import { SalesProductHistoryService } from '../../services/sales-product-history.service';
import { check, history } from 'src/app/shared/components/icon';
import { TemplateColumn } from '@tarktech/tark-ng-utils/table/template-column';
import { SalesProductsService } from '../../services/sales-products.service';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { finalize } from 'rxjs/internal/operators/finalize';
import { SalesProduct } from '../../interface/sales-product';
import { SalesCategory } from 'src/app/information-management/sales-categories/interface/sales-category';
import { SalesCategoryService } from 'src/app/shared/services/sales-category.service';
import { FilterConfiguration, GridContextInfo } from 'src/app/shared/interface';
import { TableConfiguration } from 'src/app/shared/table';
import { TableComponent } from '@tarktech/tark-ng-utils';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'pos-sales-product-history',
  templateUrl: './sales-product-history.component.html',
  styleUrls: ['./sales-product-history.component.scss']
})
export class SalesProductHistoryComponent extends BaseListComponent<SalesProductHistory> implements OnInit {

  salesProductHistoryColumns = [];
  salesProductHistory: Array<SalesProductHistory> = [];
  salesProductName;
  id: number;
  @ViewChild('taxableTemplate', { static: true }) taxableTemplate: TemplateRef<any>;
  @ViewChild('activeTemplate', { static: true }) activeTemplate: TemplateRef<any>;
  public icons = {
    check,
    history
  };

  totalRecords = 0;
  filterConfiguration: FilterConfiguration = {
    PageNumber: 1, PageSize: TableConfiguration.pageSize,
    SortField: 'DateAdded', SortOrder: -1, FilterValue: null
  };
  categories: SalesCategory[] = [];
  isCalledFromOnInit = true;
  @ViewChild('salesProductHistoryTable', { static: true }) salesProductHistoryTable: TableComponent;
  salesProductGridContext: GridContextInfo = null;
  salesProductCategory: number = 0;
  salesProductActive: boolean = false;

  constructor(
    protected salesProductHistoryService: SalesProductHistoryService,
    protected salesCategoryService: SalesCategoryService,
    private salesProductService: SalesProductsService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected applicationStateService: ApplicationStateService,
    protected route: ActivatedRoute,
    private router: Router,
    protected printService: PrintTableService) {
    super(salesProductHistoryService, alertService, spinnerService, modalService, applicationStateService, printService, route);
    this.id = parseInt(route.snapshot.params.id, 10) ?? 0;
    this.deleteSuccessMessage = Messages.SalesProductHistoryDeletedSuccess;
    const navigation = this.router.getCurrentNavigation();
    this.salesProductGridContext = navigation?.extras?.state?.GridContext ?? null;
    this.salesProductCategory = navigation?.extras?.state?.Category ?? null;
    this.salesProductActive = navigation?.extras?.state?.Active ?? null;
  }

  ngOnInit(): void {
    this.isPrintIconVisible = false;
    this.salesProductHistoryColumns = this.configureGridColumns();
    this.salesProductHistoryTable.context = this.gridContext;
  }

  reload() {
    if (this.id) {
      const observable: Array<Observable<any>> = [];
      observable.push(this.salesProductService.getSalesProduct(this.id));
      observable.push(this.salesCategoryService.getSalesCategories(true));
      observable.push(this.salesProductHistoryService.getTotalRecordsCount(this.id));
      this.spinnerService.show();
      forkJoin(observable).pipe(finalize(() => {
        this.spinnerService.hide();
      })).subscribe({
        next: (response: [SalesProduct, Array<SalesCategory>, number]) => {
          this.salesProductName = response[0].Name;
          this.categories = response[1];
          this.totalRecords = response[2];
          if (!this.isCalledFromOnInit) {
            this.getProductHistories();
          }
          this.isCalledFromOnInit = false;
        },
        error: this.alertService.showApiError
      });
    }
  }

  editItem(id: number) {
    this.router.navigate([id], { relativeTo: this.route, state: { GridContext: this.salesProductGridContext, Category: this.salesProductCategory, Active: this.salesProductActive }});
  }

  lazyLoad(event) {
    this.filterConfiguration.PageSize = event.rows;
    this.filterConfiguration.PageNumber = Math.ceil((event.first + 1) / event.rows);
    this.filterConfiguration.SortField = event.sortField;
    this.filterConfiguration.SortOrder = event.sortOrder;
    this.filterConfiguration.FilterValue = event.globalFilter;
    this.getProductHistories();
  }

  getProductHistories() {
    this.spinnerService.show();
    this.salesProductHistoryService.getProductHistories(this.id, this.filterConfiguration)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          this.salesProductHistory = response;
          this.salesProductHistory.forEach((data) => {
            data.SalesCategory = this.categories?.find(x => x.id === data.SalesCategoryId)?.name;
          });
        }
      });
  }

  getConfirmDeleteMessage() {
    return Messages.SalesProductHistoryDeleteConfirm;
  }

  getGridColumns() {

    const taxableColumn = new TemplateColumn({
      HeaderText: 'Taxable',
      itemTemplate: this.taxableTemplate,
      TextAlign: TextAlign.Center,
      Width: '15%',
      Field: 'IsTaxable',
      IsSortable: true,
    });

    const activeColumn = new TemplateColumn({
      HeaderText: 'Active',
      itemTemplate: this.activeTemplate,
      TextAlign: TextAlign.Center,
      Width: '15%',
      Field: 'IsActive',
      IsSortable: true,
    });
    return [
      new GridColumn({ Field: 'Name', HeaderText: 'Name', Width: '25%', IsSortable: true }),
      new GridColumn({ Field: 'SalesCategory', HeaderText: 'Category', Width: '20%' }),
      taxableColumn,
      activeColumn,
      new DateColumn({ Field: 'DateAdded', HeaderText: 'Created On', Width: '18%', TextAlign: TextAlign.Left, IsSortable: true }),
    ];
  }

  onCancel() {
    this.router.navigate(['manage/app-configuration/sales-products'], { state: { GridContext: this.salesProductGridContext, Category: this.salesProductCategory, Active: this.salesProductActive } });
  }

}
