import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { CommentWarning } from "../../information-management/comment-warnings/interface/comment-warning";

@Injectable()
export class CommentWarningService {
  constructor(private httpService: HttpClient) { }

  newCommentWarning() {
    const commentWarningObj: CommentWarning = {
      Id: 0,
      WarningMessage: '',
      CommentTextMatch: '',
      IsBlocked: false
    };
    return commentWarningObj;
  }

  getCommentWarnings(): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}commentEntry`
    );
  }

  get(commentWarningId: number): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}commentEntry/` + commentWarningId
    );
  }
  save(commentWarning: CommentWarning): Observable<any> {
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}commentEntry`,
      commentWarning
    );
  }
  update(commentWarning: CommentWarning): Observable<any> {
    return this.httpService.put(
        `${RuntimeConstants.API_PATH}commentEntry`,
        commentWarning
    );
  }
  delete(commentWarningId: number): Observable<any> {
    return this.httpService.delete(
      `${RuntimeConstants.API_PATH}commentEntry/` + commentWarningId
    );
  }
}

