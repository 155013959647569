<pos-edit-wrapper heading="{{headerName}}" (close)="onCancel(false)">
  <div class="pos-modal-body row mx-0">
    <div class="col-12 pe-pix-10">
      <div class="page-content__search float-end ">
        <input class="form-control" name="textToMatch" placeholder="Filter..." #textToMatch="ngModel"
          [(ngModel)]="searchFilter">
      </div>
    </div>
    <div class="row col-12 pe-0 screen-scroll height-60">
      <div class="col-md-2 col-sm-3 col-xs-6 ps-0 pe-pix-10"
        *ngFor="let img of availableImages | customFilter:searchFilter">
        <div class="col-12 p-pix-5 border-1 image-gallery-modal__image-list__img-wrap"
          [ngClass]="{'border-primary': selectedImage == img.name}" (click)="onSelectImage(img)">
          <!-- ngClass="selectedGalleryImage.url==img.url?'bg-grey':''" -->
          <img class="image-gallery-modal__image-list__img-wrap__img" src="{{imageRootPath}}/{{img.name}}" />
        </div>
        <div class="col-md-12 text-ellipsis instructions-image-name text-center">
          {{img.actualName}}
        </div>
      </div>
    </div>
  </div>
  <div class="clearfix"></div>
  <div class="col-12 action-button-container popup-footer">
    <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" scrollup (click)="onCancel(false)">Close</button>
  </div>
</pos-edit-wrapper>
