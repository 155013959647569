import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-villains',
  templateUrl: './villains.component.html',
  styleUrls: ['./villains.component.scss']
})
export class VillainsComponent implements OnInit {

  public villains: string[];

  constructor() { }

  ngOnInit() {
    this.villains = [
      'Joker',
      'Ben'
    ];
  }
}
