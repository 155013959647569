<pos-edit-wrapper heading="User Settings" (close)="cancel()" [headingIcon]="icons.usersCogs" [isPopup]="false">
  <form (ngSubmit)="saveUserSetting()" #userSettingsForm="ngForm" class="edit-form">
    <div class="col-12 border-top pt-pix-5">
      <div class="col-12 pos-page-body">
        <h3 class="setting-title">Settings</h3>
        <pos-row class="pos-title-content">
          <pos-column>
            <pos-form-field inputStyle="d-flex">
              <p-checkbox posInput name="useClockIns" #useClockIns="ngModel" [(ngModel)]="parameters.UseClockIns"
                binary="true" label="Use Time Management">
              </p-checkbox>
              <field-info [info]="fieldInfoMessages.UserSettingTimeManagement" styleClass="pt-0">
              </field-info>
            </pos-form-field>
          </pos-column>
          <pos-column>
            <pos-form-field inputStyle="d-flex">
              <p-checkbox posInput name="printClockouts" #printClockouts="ngModel"
                [(ngModel)]="parameters.PrintClockouts" binary="true" label="Print Clockout Time">
              </p-checkbox>
              <field-info [info]="fieldInfoMessages.UserSettingPrintClockoutTime" styleClass="pt-0">
              </field-info>
            </pos-form-field>
          </pos-column>
          <pos-column>
            <pos-form-field>
              <p-checkbox posInput name="serveUponSettle" #serveUponSettle="ngModel"
                [(ngModel)]="parameters.ServeUponSettle" binary="true" label="Mark order Served upon Settle">
              </p-checkbox>
            </pos-form-field>
          </pos-column>
          <pos-column>
            <pos-form-field>
              <pos-row>
                <pos-column xs="4" class="pt-pix-7 col-200 mb-1">
                  <p-checkbox posInput name="chkAutoGratuity" #chkAutoGratuity="ngModel" (onChange)="changeGratuity()"
                    [(ngModel)]="parameters.AutoGratuity" binary="true" label="Auto Gratuity Percent">
                  </p-checkbox>
                </pos-column>
                <pos-column xs="4">
                  <input posInput maxlength="2" [disabled]="!parameters.AutoGratuity"
                    [(ngModel)]="parameters.AutoGratuityPct" posOnlyNumbers class="form-control col-120" type="number"
                    name="autoGratuityPct">
                </pos-column>
              </pos-row>
            </pos-form-field>
          </pos-column>
          <pos-column class="ps-5">
            <pos-form-field>
              <p-checkbox posInput name="managerOnlyOverrideGratuity" #managerOnlyOverrideGratuity="ngModel"
                [(ngModel)]="parameters.ManagerOnlyOverrideGratuity" binary="true" [disabled]="!parameters.AutoGratuity"
                label="Manager-only override">
              </p-checkbox>
            </pos-form-field>
          </pos-column>
          <pos-column>
            <pos-form-field>
              <p-checkbox posInput name="gratuitySelfOnly" #gratuitySelfOnly="ngModel"
                [(ngModel)]="parameters.GratuitySelfOnly" binary="true" label="Gratuity on own orders only">
              </p-checkbox>
            </pos-form-field>
          </pos-column>
          <pos-column class="ps-5">
            <pos-form-field>
              <p-checkbox posInput name="gratuityAdministrator" #gratuityAdministrator="ngModel"
                [(ngModel)]="parameters.GratuityAdministrator" binary="true" [disabled]="!parameters.GratuitySelfOnly"
                label="Unless Administrator">
              </p-checkbox>
            </pos-form-field>
          </pos-column>
          <pos-column>
            <pos-form-field>
              <p-checkbox posInput name="signInEachOrder" #signInEachOrder="ngModel"
                [(ngModel)]="parameters.SignInEachOrder" binary="true" label="Sign in for each order">
              </p-checkbox>
            </pos-form-field>
          </pos-column>
          <pos-column>
            <pos-form-field>
              <p-checkbox posInput name="signOutAfterEachOrder" #signOutAfterEachOrder="ngModel"
                [(ngModel)]="parameters.SignOutAfterEachOrder" binary="true" label="Auto sign off after each order">
              </p-checkbox>
            </pos-form-field>
          </pos-column>
          <pos-column>
            <button class="btn btn-primary white normal mt-pix-10 pos-button" (click)="createManagerLogin()" type="button">
              Create Manager Login
            </button>
          </pos-column>
        </pos-row>
      </div>
    </div>
    <div class="col-12 action-button-container page-footer">
      <button class="btn btn-primary btn-lg btn-save pos-button" scrollup type="submit">Save</button>
      <button class="btn btn-secondary btn-lg btn-cancel pos-button" routerLink="../" scrollup type="button">Cancel</button>
    </div>
  </form>
</pos-edit-wrapper>
