import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { GridColumn, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { find, forEach, orderBy } from 'lodash';
import { QueryValuesComponent } from 'src/app/manage-console/automated-exports/components/query-values';
import { DataSourceParameter } from 'src/app/manage-console/automated-exports/interface/data-source-parameter';
import { DataSourceParameterService } from 'src/app/manage-console/automated-exports/service';
import { DomainConstants, Messages } from 'src/app/shared/constants';
import { StringUtils } from 'src/app/shared/string-utils';
import { ConfirmDeleteModalComponent } from '../confirm-delete-modal';
import { ModalService } from '../modal/modal.service';
import { deleteWhite, listWhite, queryValuesDatabase } from 'src/app/shared/components/icon';
import { AlertsService } from '../../services';
import { SpinnerService } from '../spinner';
import { finalize } from 'rxjs/operators';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'pos-report-configuration-parameters',
  templateUrl: './report-configuration-parameters.component.html',
  styleUrls: ['./report-configuration-parameters.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ReportConfigurationParametersComponent implements OnInit {

  @Input() parameters: Array<DataSourceParameter> = [];
  @Input() dataSourceId = 0;
  @Input() isFormSubmitted = false;
  @Output() reloadParameters: EventEmitter<any> = new EventEmitter();
  updateIndex = 0;
  columns: Array<GridColumn> = [];
  dataTypes = orderBy(DomainConstants.DataTypes, 'Text');
  queryType = DomainConstants.queryType;
  icons = {
    deleteWhite, listWhite, queryValuesDatabase
  };

  @ViewChild('nameTemplate', { static: true }) private nameTemplate: TemplateRef<any>;
  @ViewChild('labelTemplate', { static: true }) private labelTemplate: TemplateRef<any>;
  @ViewChild('dataTypeTemplate', { static: true }) private dataTypeTemplate: TemplateRef<any>;
  @ViewChild('dataLengthTemplate', { static: true }) private dataLengthTemplate: TemplateRef<any>;
  @ViewChild('initialValueTemplate', { static: true }) private initialValueTemplate: TemplateRef<any>;
  @ViewChild('isRequiredTemplate', { static: true }) private isRequiredTemplate: TemplateRef<any>;
  @ViewChild('allowUserInputTemplate', { static: true }) private allowUserInputTemplate: TemplateRef<any>;
  @ViewChild('operationTemplate', { static: true }) private operationTemplate: TemplateRef<any>;

  constructor(protected dataSourceParameterService: DataSourceParameterService,
    private modalService: ModalService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.configureGridColumns();
    forEach(this.parameters, param => {
      this.dataTypeChanged(param, false);
    });
  }

  configureGridColumns() {
    const operationColumn = new TemplateColumn({
      itemTemplate: this.operationTemplate,
      Width: '107px'
    });

    this.columns = [
      new TemplateColumn({ HeaderText: 'Parameter', Field: 'Label', itemTemplate: this.labelTemplate, Width: '20%' }),
      new TemplateColumn({ HeaderText: 'Field Name', Field: 'Name', itemTemplate: this.nameTemplate, Width: '20%' }),
      new TemplateColumn({ HeaderText: 'Data Type', Field: 'DataType', itemTemplate: this.dataTypeTemplate, Width: '14%' }),
      new TemplateColumn({ HeaderText: 'Field Length', Field: 'DataLength', itemTemplate: this.dataLengthTemplate, Width: '9%' }),
      new TemplateColumn({
        HeaderText: 'Initial Value', Field: 'InitialValue', itemTemplate: this.initialValueTemplate, Width: '15%',
        TextAlign: TextAlign.Center
      }),
      new TemplateColumn({
        HeaderText: 'Editable', Field: 'AllowUserInput', itemTemplate: this.allowUserInputTemplate,
        Width: '8%', TextAlign: TextAlign.Center
      }),
      new TemplateColumn({
        HeaderText: 'Required', Field: 'IsRequired', itemTemplate: this.isRequiredTemplate, Width: '8%',
        TextAlign: TextAlign.Center
      }),
      operationColumn
    ] as Array<GridColumn>;
  }

  createParameterRow(index: number, firstRow = false) {
    if (this.parameters.length !== (index + 1) && !firstRow) {
      return;
    }
    const newParameter = this.dataSourceParameterService.newDataSourceParameter();
    newParameter.DataSourceId = this.dataSourceId;
    newParameter.Ordinal = index + 1;
    this.parameters.push(newParameter);
  }

  dataTypeChanged(parameter: DataSourceParameter, valid: boolean) {
    if (valid) {
      parameter.DataLength = null;
    }
    const datatype = find(this.dataTypes, x => x.Value === parameter.DataType);
    if (datatype) {
      parameter.HasDropdown = datatype.HasDropdown;
    }
  }

  openValuesModal(data: DataSourceParameter, type: string) {
    const modalRef = this.modalService.show(QueryValuesComponent, {
      animated: false,
      class: 'vertical-center',
      keyboard: false,
      initialState: {
        type: type,
        dataSourceParamName: data.Label,
        queryValues: type === this.queryType.Manual ? data.ManualValues : data.QueryValues
      }
    });
    modalRef.close.subscribe(res => {
      if (res && res.shouldReload) {
        if (type === this.queryType.Manual) {
          data.ManualValues = res.values;
        } else if (type === this.queryType.Query) {
          data.QueryValues = res.values;
        }
      }
    });
  }

  removeParameter(dataSourceParameter: DataSourceParameter, index) {
    if (dataSourceParameter.Id) {
      const modalRef = this.modalService.show(ConfirmDeleteModalComponent , {
        animated: false,
        class: 'vertical-center',
        keyboard: false,
        initialState: {
          message: StringUtils.format(Messages.DataSourceParameterConfirmDelete, { paramName: dataSourceParameter.Label })
        }
      });
      modalRef.close.subscribe(res => {
        if (res && res.shouldDelete) {
          this.deleteParameter(dataSourceParameter, index);
        }
      });
    } else {
      this.deleteParameter(dataSourceParameter, index);
    }
  }

  deleteParameter(dataSourceParameter: DataSourceParameter, index) {
    if (dataSourceParameter.Id > 0) {
      this.spinnerService.show();
      this.dataSourceParameterService.delete(dataSourceParameter.Id)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res) => {
            this.reloadParameters.emit();
            this.alertService.renderSuccessMessage(Messages.DataSourceParameterDelete);
          }, error: this.alertService.showApiError
        });
    } else {
      this.parameters.splice(index, 1);
      this.updateIndex++;
    }
  }

}
