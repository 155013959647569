import { Route } from '@angular/router';
import { AuthGuard } from '../app-guard';
import { Levels, DomainConstants } from '../shared/constants';
import { TimeEntryTerminalComponent } from './time-entry-terminal';

const TIME_ENTRY: Route = {
    path: '',
    canActivate: [AuthGuard],
    component: TimeEntryTerminalComponent,
    data: {
        permissions: [{ 'Name': DomainConstants.TerminalTypes.TIME_ENTRY.Permission, 'Level': Levels.Access }]
    }
};

export const TIME_ENTRY_STATES = [TIME_ENTRY];
