<pos-list-wrapper [headingIcon]="icons.equipmentPropertiesDolly" heading="Equipment Types" (close)="close()">
    <div class="row">
        <div class="col-12">
            <ng-table id="equipmentType" [sort]="true" [columns]="equipmentTypeColumns"
                [data]="equipmentTypes" [exportFilename]="'equipment-types'" #equipmentTypeGrid>
            </ng-table>
        </div>
    </div>
</pos-list-wrapper>
<pos-print-table [hidden]="true"></pos-print-table>
