import { UserTrainingNotesComponent } from 'src/app/information-management/training-topic-levels/components/user-training-notes/user-training-notes.component';
import { AlertsService, SpinnerService, ApplicationStateService, Messages, thList, trainingApproveNote, ICloseable } from 'src/app/shared';
import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { TrainingApproveService } from '../services/training-approve.service';
import { GridColumn, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { TrainingApprove, UserTraining } from '../interface';
import * as _ from 'lodash';
import { Observable, interval as observableInterval } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TrainingLevelContentComponent } from 'src/app/training/training-approve/components/training-level-content/training-level-content.component';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { TrainingTopicLevelService } from 'src/app/shared/services/training-topic-level.service';

import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { TrainingApprovalModalComponent } from './training-approval-modal/training-approval-modal.component';

@Component({
    selector: 'pos-training-approve',
    templateUrl: './training-approve.component.html'
})
export class TrainingApproveComponent implements OnInit {
    public icons = {
        thList,
        trainingApproveNote
    };
    trainingInfo: Array<TrainingApprove> = [];
    trainingInfoColumns: Array<GridColumn> = [];
    levelInfoColumns: Array<GridColumn> = [];
    levelInfo: Array<any> = [];

    userTraining: UserTraining;
    topicName: string;
    timerList: any = [];
    @ViewChild('approveTemplate', { static: true }) private approveTemplate: any;
    timer: Observable<number>;
    confirmApproveMessage = Messages.ConfirmApproveTraining;
    modalRef: ICloseable;
    levelInfoModalRef: BsModalRef;
    @ViewChild('confirmModalTemplate') public confirmModalTemplate: any;
    public confirmMessage;
    close: EventEmitter<any> = new EventEmitter();
    constructor(private alertService: AlertsService,
        private spinnerService: SpinnerService,
        private trainingApproveService: TrainingApproveService,
        private trainingTopicLevelService: TrainingTopicLevelService,
        private applicationStateService: ApplicationStateService,
        private modalService: ModalService
    ) {
        this.getTrainingInfo();
        this.userTraining = {
            Id: 0,
            UserId: 0,
            TrainingLevelId: 0,
            CompletionDate: new Date(),
            ApprovedByUserId: 0,
            DateAdded: new Date(),
            DateEdited: null
        };

    }

    ngOnInit() {
        this.columnConfiguration();
        this.levelInfo = [];
    }

    ngOnDestroy() {
        _.forEach(this.timerList, (timer) => {
            if (timer) {
                timer.unsubscribe();
            }
        })
    }

    private columnConfiguration() {
        const approveColumn = new TemplateColumn({
            itemTemplate: this.approveTemplate,
            TextAlign: TextAlign.Center,
            Width: '165px'
        });


        this.trainingInfoColumns = [
            new GridColumn({ HeaderText: 'User', Field: 'UserName', Width: '25%' }),
            new GridColumn({ HeaderText: 'Area', Field: 'AreaName', Width: '24%' }),
            new GridColumn({ HeaderText: 'Topic', Field: 'TopicName', Width: '30%' }),
            new GridColumn({ HeaderText: 'Level', Field: 'LevelName', Width: '36%' }),
            approveColumn
        ] as Array<GridColumn>;
    }

    private levelColumnConfiguration() {
        this.levelInfoColumns = [
            new GridColumn({ HeaderText: 'Level', Field: 'LevelNumber', Width: '20px' }),
            new GridColumn({ HeaderText: 'Description', Field: 'Description', Width: '20%' })
        ] as Array<GridColumn>;
    }

    addNotes(data) {
        const modalRef = this.modalService.show(UserTrainingNotesComponent, {
            animated: false,
            keyboard: false,
            class: 'modal-lg vertical-center',
            initialState: {
                trainingData: data
            }
        });
        modalRef.close.subscribe(res => {
            if (res && res.shouldReload) {
                this.getTrainingInfo();
            }
        });
    }

    getTrainingInfo() {
        this.spinnerService.show();
        this.trainingApproveService.GetTrainingInfoByRoleId(this.applicationStateService.userDetails.id).subscribe(
            res => {
                _.forEach(res, (trainingData) => {
                    let currentDateTime = new Date();
                    trainingData.ShowApproveButton = false;
                    const dateForTopicCompleteTime = new Date(trainingData.StartedOn);
                    trainingData.ShowTimeForApprove = '';
                    let successionLimitDelayTime = new Date(trainingData.StartedOn).getTime() + (trainingData.SuccessionLimitDelayMinutes * 60000);
                    if (new Date().getTime() > successionLimitDelayTime) {
                        trainingData.ShowApproveButton = true;
                    }
                    else {
                        let diffMs = dateForTopicCompleteTime.getTime() - currentDateTime.getTime();
                        let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
                        let diffMins = Math.floor(((diffMs % 86400000) % 3600000) / 60000); // minutes
                        let diffSeconds = Math.floor(diffMs / (1000) % 60); // seconds
                        trainingData.ShowApproveButton = false;
                        this.timer = observableInterval(1000);
                        let timer = this.timer.subscribe({
                            next: (t) => {
                                if (successionLimitDelayTime > new Date().getTime()) {
                                    currentDateTime = new Date();
                                    diffMs = (dateForTopicCompleteTime.getTime() + (trainingData.SuccessionLimitDelayMinutes * 60000)) - currentDateTime.getTime();
                                    diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
                                    diffMins = Math.floor(((diffMs % 86400000) % 3600000) / 60000); // minutes
                                    diffSeconds = Math.floor(diffMs / (1000) % 60); // seconds
                                    trainingData.ShowTimeForApprove = this.setNumberFormat(diffHrs, 2) + ':' + this.setNumberFormat(diffMins, 2) + ':' + this.setNumberFormat(diffSeconds, 2);
                                } else {
                                    trainingData.ShowApproveButton = true;
                                }
                            }
                        });
                        this.timerList.push(timer);
                    }
                });
                this.trainingInfo = (res === null ? [] : res);

            },
            this.alertService.showApiError,
            () => {
                this.spinnerService.hide();
            }
        );
    }

    setNumberFormat(number, length) {
        number = number.toString();
        while (number.length < length) {
            number = '0' + number;
        }
        return number;
    }

    onApprove(data: TrainingApprove) {
        this.userTraining.UserId = data.UserId;
        this.userTraining.TrainingLevelId = data.TrainingLevelId;
        this.userTraining.ApprovedByUserId = this.applicationStateService.userDetails.id;
        const ConfirmApprovedMessage = StringUtils.format(this.confirmApproveMessage,
            {
                'LastName': data.LastName.toString(), 'FirstName': data.FirstName.toString(),
                'Topic': data.TopicName.toString(), 'Area': data.AreaName.toString(),
                'Level': data.LevelName.toString()
            });
        this.confirmMessage = ConfirmApprovedMessage;
        this.modalRef = this.modalService.show(TrainingApprovalModalComponent, {
            'backdrop': 'static',
            'class': 'vertical-center',
            keyboard: false,
            initialState: {
                confirmMessage: ConfirmApprovedMessage
            }
        });
        this.modalRef.close.subscribe((res) => {
            if (res.approve) {
                this.spinnerService.show();
                this.trainingApproveService.addUserTraining(this.userTraining).subscribe(
                    res => {
                        this.alertService.renderSuccessMessage(Messages.TrainingTopicApprovedSuccess);
                        this.getTrainingInfo();
                    }, this.alertService.showApiError,
                    () => {
                        this.spinnerService.hide();
                    }
                );
            }
        })
    }

    onReview(data) {
        this.trainingTopicLevelService.getTrainingTopicLevel(data.TrainingLevelId).subscribe(
            res => {
                this.modalService.show(TrainingLevelContentComponent, {
                    animated: false,
                    class: 'vertical-center modal-max-width-65',
                    initialState: {
                        trainingLevel: res,
                        topicName: data.TopicName,
                        trainingAreaName: data.AreaName
                    }
                });
            });
    }
    onCancel() {
        this.close.emit();
    }
}
