import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Levels, Permissions, buttonsDiceOne, checkDouble, cogs, infoManageAngleDoubleLeft, infoManageAngleDoubleRight, paymentsMoneyBillAlt, screensDesktop, search, shoppingBag, timeUserClock, times, userRobot, windowCancel } from 'src/app/shared';
import { ApplicationStateService, BaseMenuSearchService } from 'src/app/shared/services';
declare let $: any;

@Component({
  selector: 'pos-kiosk-management',
  templateUrl: './kiosk-management.component.html',
  styleUrls: ['./kiosk-management.component.scss']
})
export class KioskManagementComponent {
  isFullScreen = false;
  permissions: any = Permissions;
  accessLevels: any = Levels;
  kioskFilter = '';
  icons = {
    infoManageAngleDoubleRight,
    infoManageAngleDoubleLeft,
    search,
    times
  };

  menuList = [
    {
      GroupName: 'Order Kiosk',
      Permission: this.permissions.OrderKioskSection,
      PermissionLevel: this.accessLevels.Access,
      List: [
        {
          Name: 'Buttons', Icon: buttonsDiceOne, Permission: this.permissions.OrderKioskButtons,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'buttons'
        },
        {
          Name: 'Screens', Icon: screensDesktop, Permission: this.permissions.OrderKioskScreens,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'screens'
        }
      ]
    },
    {
      GroupName: 'Configurations',
      Permission: this.permissions.OrderKioskConfigurationSection,
      PermissionLevel: this.accessLevels.Access,
      List: [
        {
          Name: 'General Settings', Icon: cogs, Permission: this.permissions.OrderKioskGeneralConfig,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'kiosk-general'
        },
        {
          Name: 'Cancel Order', Icon: windowCancel, Permission: this.permissions.OrderKioskCancelOrderConfig, 
          PermissionLevel: this.accessLevels.Access, RoutLink: 'cancel-order' 
        },
        {
          Name: 'Idle Timeout', Icon: timeUserClock, Permission: this.permissions.OrderKioskIdleTimeoutConfig, 
          PermissionLevel: this.accessLevels.Access, RoutLink: 'idle-timeout' 
        },
        {
          Name: 'Order Confirm', Icon: checkDouble, Permission: this.permissions.OrderKioskOrderConfirmConfig, 
          PermissionLevel: this.accessLevels.Access, RoutLink: 'kiosk-order-confirm' 
        },
        {
          Name: 'Payment', Icon: paymentsMoneyBillAlt, Permission: this.permissions.OrderKioskPaymentConfig, 
          PermissionLevel: this.accessLevels.Access, RoutLink: 'kiosk-payment' 
        },
        {
          Name: 'Checkout', Icon: shoppingBag, Permission: this.permissions.OrderKioskCheckoutConfig, 
          PermissionLevel: this.accessLevels.Access, RoutLink: 'kiosk-checkout' 
        },
        {
          Name: 'AI Shake', Icon: userRobot, Permission: this.permissions.OrderKioskAIShakeConfig, 
          PermissionLevel: this.accessLevels.Access, RoutLink: 'ai-shake' 
        }
      ]
    }
  ];
  filterMenuList = [...this.menuList];
  
  constructor(private applicationStateService: ApplicationStateService,
    private baseMenuSearchService: BaseMenuSearchService,
    private router: Router) { }

    ngOnInit() {
      document.documentElement.style.setProperty('--kiosk-management-width', '80%');
      if (this.router.url === 'manage/order-kiosk') {
        this.applicationStateService.gridRouteContext = null;
      }
    }

    toggleFullScreen() {
      this.isFullScreen = !this.isFullScreen;
      if (this.isFullScreen) {
        document.documentElement.style.setProperty('--kiosk-management-width', '100%');
      } else {
        document.documentElement.style.setProperty('--kiosk-management-width', '80%');
      }
    }
  
    search() {
      this.filterMenuList = this.baseMenuSearchService.search(this.menuList, this.kioskFilter);
    }
}
