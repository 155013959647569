<pos-list-wrapper [headingIcon]="icons.jobCodeBusinessTime" heading="Job Codes" (close)="close()">
    <div class="row">
        <div class="col-12">
            <ng-table id="jobCodes" #jobCodeGrid [sort]="true" [exportFilename]="'job-codes'"
                [columns]="jobCodeColumns" [data]="jobCodes">
            </ng-table>
        </div>
    </div>
</pos-list-wrapper>
<pos-print-table [hidden]="true"></pos-print-table>
