import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { Observable } from 'rxjs';
import { ConfigurationParameter } from 'src/app/shared/interface/configuration-parameter';

@Injectable()
export class ParameterService {

    parameterApiUrl = '';
    constructor(private httpService: HttpClient) {
        this.parameterApiUrl = RuntimeConstants.API_PATH + 'parameters';
    }
    getParameterForTerminal(terminalId: number) {
        return this.httpService.get(`${this.parameterApiUrl}/terminals/` + terminalId);
    }
    getParameterServiceForName(parameterName: string): Observable<ConfigurationParameter> {
        return this.httpService.get<ConfigurationParameter>(`${this.parameterApiUrl}/` + parameterName);
    }
    getParameterForName(parameterName: string): Observable<any> {
        return this.httpService.get(`${this.parameterApiUrl}` + '/' + parameterName);
    }

    saveParameter(parameter): Observable<any> {
        return this.httpService.post(`${this.parameterApiUrl}/parameter`, parameter);
    }

    saveColors(parameters) {
        return this.httpService.post(`${this.parameterApiUrl}/colors`, parameters);
    }

    saveLayouts(layoutModel) {
        return this.httpService.post(`${this.parameterApiUrl}/layout`, layoutModel);
    }

    saveFormats(FormatModel) {
        return this.httpService.post(`${this.parameterApiUrl}/formats`, FormatModel);
    }

    saveNotification(parameters) {
        return this.httpService.post(`${this.parameterApiUrl}/notification`, parameters);
    }
}
