import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { ApplicationStateService } from '../../services/application-state.service';
import { SettingParam } from '../../interface/setting-param';
import { ICloseable } from '../modal/ICloseable';

@Component({
  selector: 'pos-swipe-card',
  templateUrl: './swipe-card.component.html',
  styleUrls: ['./swipe-card.component.scss']
})
export class SwipeCardComponent implements OnInit, ICloseable {

  settingParam: SettingParam;
  loyaltyCardInfoModelRef: any;
  isKeyboardWedgeEnabled: boolean = true;
  @Input() public message: string = '';
  @Input() public modalHeaderText: string = 'Info';
  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(
    private applicationStateService: ApplicationStateService
  ) { 
  }

  ngOnInit() {
    this.settingParam = this.applicationStateService.settingParam;
    this.applicationStateService.enableAttendanceCardSwipe = false;
  }

  onSwipeDetected(loyaltyCardNumber) {
    if (!this.applicationStateService.enableAttendanceCardSwipe) {
      this.applicationStateService.enableAttendanceCardSwipe = true;
      this.close.emit({cardNumber: loyaltyCardNumber});  
    }
  }

  public onClose(): void {
    this.applicationStateService.enableAttendanceCardSwipe = true;
    this.close.emit();
  }
}
