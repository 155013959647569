import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GridColumn, TemplateColumn } from '@tarktech/tark-ng-utils';
import { finalize } from 'rxjs/operators';
import { AlertsService, AuthenticationService, Dashboard, DashboardConfigurationService, Levels, ModalService, Permissions, SpinnerService } from 'src/app/shared';
import { DashboardEditComponent } from '../dashboard-edit';
import { plusWhite, editWhite, search } from 'src/app/shared/components/icon';
import { Router } from '@angular/router';

@Component({
  selector: 'pos-dashboard-list',
  templateUrl: './dashboard-list.component.html',
  styleUrls: ['./dashboard-list.component.scss']
})
export class DashboardListComponent implements OnInit {

  dashboardList: Array<Dashboard> = [];
  dashboardColumns: Array<GridColumn> = [];
  icons = {
    plusWhite, editWhite, search
  };

  permission = {
    Name: Permissions.DashboardTemplates,
    EditLevel: Levels.Edit
  }

  @ViewChild('operationTemplate', { static: true }) operationTemplate: TemplateRef<any>;
  @ViewChild('operationHeaderTemplate', { static: true }) operationHeaderTemplate: TemplateRef<any>;

  constructor(protected dashboardConfigurationService: DashboardConfigurationService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    private authenticationService: AuthenticationService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.dashboardColumns = this.configureGridColumns();
    this.reload();
  }

  reload() {
    this.spinnerService.show();
    this.dashboardConfigurationService.getDashboards()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          this.dashboardList = (res === null ? [] : res);
        }, error: this.alertService.showApiError
      });
  }

  configureGridColumns(): GridColumn[] {
    const operationColumn = new TemplateColumn({
      itemTemplate: this.operationTemplate,
      Width: '70px',
      headerTemplate: this.operationHeaderTemplate,
      CellClass: 'cell-padding text-center'
    });

    const columns = [
      new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '56%' }),
      new GridColumn({ HeaderText: 'Layout', Field: 'LayoutName', IsSortable: true, Width: '40%' }),
    ];

    if (this.authenticationService.userHasPermission([{ Name: this.permission.Name, Level: this.permission.EditLevel }], 'any')) {
      columns.push(operationColumn);
    }

    return columns;
  }

  editItem(id: number, data?: Dashboard): void {
    const modalRef = this.modalService.getModalWrapper(DashboardEditComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      initialState: {
        id: data ? data.Id : 0
      }
    });

    modal.close.subscribe(res => {
      if (res && res.shouldReload) {
        this.reload();
      }
    });
  }

  loadDashboard(id: number) {
    this.router.navigate(['dashboards/template', 'interactive', id], { state: { redirectRoute: 'manage/dashboards/list' } });
  }

  cancel() {
    this.router.navigate(['manage/console']);
  }
}
