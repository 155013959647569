import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { Observable } from 'rxjs';
import { Button, ButtonFunctionParametersModel } from '../../information-management/buttons/interface/button';
import { BaseCrudService } from './base-crud.service';
import { DomainConstants } from '../constants/domain-constants';

@Injectable()
export class ButtonService extends BaseCrudService<Button> {

  buttonsApiUrl = '';
  constructor(httpService: HttpClient) {
    super('buttons', httpService);
    this.buttonsApiUrl = RuntimeConstants.API_PATH + 'buttons';
  }

  newButton(): Button {
    return {
      Id: 0,
      Selection: '',
      ProductName: '',
      Color: '',
      ManagerOnly: false,
      Toggle: false,
      RowNumber: 0,
      PageSize: 0,
      CurrentPage: 1,
      Image: '',
      ScheduleStartTime: null,
      ScheduleEndTime: null,
      ScheduleStartDate: null,
      ScheduleEndDate: null,
      ScreenList: '',
      ProductID: -1,
      TextColor: '',
      OldImage: '',
      next_screen_id: null,
      screen_id: 0,
      StartForm: false,
      SpecialFunction: '',
      InternalText: '',
      ReceiptText: '',
      GroupName: '',
      ButtonGroupMin: 0,
      ButtonGroupMax: 0,
      PrintToKitchen: 1,
      ButtonType: DomainConstants.ButtonTypes.DetailEntry,
      ButtonTypes: DomainConstants.ButtonTypes,
      ButtonFunctionParameters: null,
      DisplayMessageText: '',
      DocumentInventoryText: 0,
      InventoryReturnValue: 0,
      ContextualPrice: '',
      MakeTableFgColor: '',
      MakeTableBgColor: '',
      PickScreenText: '',
      PickScreenTextBgColor: '',
      IsAllowOutOfStockOrder: false,
      IsContext: false,
      displayMessageCheck: false,
      documentInventoryCheckBox: false,
      returnInventoryCheckBox: false,
      IsDefaultChecked: false,
      BackgroundColor: '',
      BehaviorName: '',
      IntValue: 0,
      TextValue: '',
      CodeNames: '',
      DateRange: '',
      BorderColor: '',
      RevenueCenterId: null,
      SalesProductSizes: null,
      ToggleColor: null
    };
  }

  getButton(buttonId, screenId): Observable<any> {
    return this.httpService.get<any[]>(this.buttonsApiUrl + '/' + buttonId + '/screen/' + screenId);
  }

  getButtonList() {
    return this.httpService.get(this.buttonsApiUrl + '/list');
  }

  getButtonListWithScreenName() {
    return this.httpService.get(this.buttonsApiUrl + '/list/include/screen');
  }


  getButtonFunctionParameters(buttonId): Observable<Array<ButtonFunctionParametersModel>> {
    return this.httpService.get<any[]>(this.buttonsApiUrl + '/parameter/' + buttonId);
  }

  triggerMenuChange() {
    return this.httpService.post(`${this.buttonsApiUrl}/menu-change`, null);
  }

}

