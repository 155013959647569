import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { forEach } from 'lodash';
import { EventBroadcastingService } from 'src/app/shared';
import { angleRight, angleDown, tagSm } from 'src/app/shared/components/icon';
import { DomainConstants } from 'src/app/shared/constants';
import { PermissionGrant } from 'src/app/shared/interface/permission-grant';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pos-permission-node',
  templateUrl: './permission-node.component.html',
  styleUrls: ['./permission-node.component.scss']
})
export class PermissionNodeComponent implements OnInit, OnChanges {
  @Input() dataNodes: PermissionGrant[];
  @Input() accessLevels: any = [];
  @Input() permissionLevels: any = [];
  @Input() hasParent = false;
  icons = {
    angleDown,
    angleRight,
    tagSm
  }
  permissionTypes = DomainConstants.PermissionTypes;
  constructor(private eventBroadCastingService: EventBroadcastingService) { }
  ngOnChanges(changes: SimpleChanges): void {
    this.setTagsList();
  }

  ngOnInit(): void {
    this.eventBroadCastingService.permissionLevelChanged.subscribe(val => {
      this.updatePermissionsById(this.dataNodes, val.PermissionId, val.PermissionLevel);
    });
  }

  updatePermissionsById(permissions: Array<PermissionGrant>, permissionId: string, permissionLevel) {
    let matchedPermissions = permissions.filter(x => x.Permission.Id == permissionId);
    matchedPermissions.forEach(x => {
      x.PermissionLevel = permissionLevel;
      if (x.Children?.some(x => x.Permission.Id == permissionId)) {
        this.updatePermissionsById(x.Children, permissionId, permissionLevel);
      }
    });
  }

  closeExpanded(permissionGrant: PermissionGrant) {
    permissionGrant.Permission.IsExpanded = !permissionGrant.Permission.IsExpanded;
    if (!permissionGrant.Permission.IsExpanded) {
      if (permissionGrant.Children?.length) {
        this.closeChildren(permissionGrant.Children);
      }
    }
  }

  closeChildren(children: PermissionGrant[]) {
    children.forEach(x => {
      x.Permission.IsExpanded = false;
      if (x.Children?.length) {
        this.closeChildren(x.Children);
      }
    });
  }

  setTagsList() {
    forEach(this.dataNodes, node => {
      if (node.Permission.Tags?.length) {
        let temp = '<ul class="tags-list">';
        for (let tag of node.Permission.Tags) {
          temp += `<li>${tag}</li>`;
        }
        temp += '</ul>';
        node.Permission.TagsList = temp;
      }
    });
  }

  updatePermissions(data: PermissionGrant) {
    this.eventBroadCastingService.permissionLevelChanged.emit({ PermissionId: data.Permission.Id, PermissionLevel: data.PermissionLevel });
  }

}
