<pos-edit-wrapper (close)="onCancel()"
    heading="{{inventorySubCategoryId > 0 ? 'Edit Subcategory' : 'Add Subcategory'}}">
    <form #inventorySubCategoryForm="ngForm" (submit)='submit(inventorySubCategoryForm.valid)' autocomplete="off">
        <div class="col-12 pos-modal-body form-horizontal screen-scroll">
            <pos-row>
                <pos-column>
                    <pos-form-field label="Subcategory Name"
                        [validationMessages]="{required: 'Please enter subcategory name.'}">
                        <input posInput type="text" name="subCategoryName" class="form-control"
                            [(ngModel)]="inventorySubCategory.name" #subCategoryName="ngModel" [required]="true" />
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Category Name" [validationMessages]="{required: 'Please select category.'}">
                        <ng-select posInput [items]="inventoryCategories" bindLabel="name" bindValue="id"
                            placeholder="Select category" appendTo="body" [(ngModel)]="inventorySubCategory.categoryid"
                            name="categoryId" [required]="true">
                        </ng-select>
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Maximum Days between Inventory"
                        [validationMessages]="{range: 'Days must be in range of 0-99.'}">
                        <input posInput type="number" id="invCatMaxDay" name="invCatMaxDay" class="form-control col-120"
                            [(ngModel)]="inventorySubCategory.days_between_inventory" min="0" max="99"
                            #inventorySubCategoryDaysBetweenInventory="ngModel" posOnlyNumbers [range]="[0, 99]" />
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Label Printer">
                        <ng-select posInput [items]="labelPrinters" bindLabel="Name" bindValue="Id"
                            placeholder="Select label printer" appendTo="body"
                            [(ngModel)]="inventorySubCategory.LabelPrinterId" name="laberPrinterId">
                        </ng-select>
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" scrollup value="Save">Save</button>
            <button type="button" class="btn-secondary  btn-lg btn btn-cancel-modal pos-button" (click)="onCancel()"
                value="Cancel">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
