import { Component, ViewChild } from '@angular/core';
import { AlertsService, ApplicationStateService, OrderService, UserIdleService } from 'src/app/shared/services';
import { KioskConfirmCancelComponent } from '../kiosk-confirm-cancel';
import { Subscription, finalize } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ICloseable, SpinnerService } from 'src/app/shared/components';
import { RuntimeConstants } from 'src/app/shared/constants';
import { KioskUserIdleComponent } from '../kiosk-user-idle-component';
import { KioskNavigationService, OrderKioskStorageService } from '../../services';
import { KioskPaymentOptionsScreenComponent } from '../kiosk-payment-options-screen/kiosk-payment-options-screen.component';
import { ModalService } from 'src/app/shared/components';
import { UntilDestroy } from '@ngneat/until-destroy';
import { KioskCancelOrderScreenComponent } from '../kiosk-cancel-order-screen';
import { KioskInvoiceComponent } from '../kiosk-invoice';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pos-kiosk-checkout-screen',
  templateUrl: './kiosk-checkout-screen.component.html',
  styleUrls: ['./kiosk-checkout-screen.component.scss']
})
export class KioskCheckoutScreenComponent {
  @ViewChild('kioskInvoiceComponent') kioskInvoiceComponent: KioskInvoiceComponent;
  invoiceHeight: number;
  backgroundColor = '#95918e';
  terminalId: number = 0;
  userIdleSubscription: Subscription;
  imagePath = `${RuntimeConstants.IMAGE_BASE_PATH}/order-kiosk/default-screens/`;
  backgroundImage = 'payment-methods.png';
  allowPayAtCounter: boolean = true;
  paymentOptionsModalRef: ICloseable;

  constructor(private userIdleService: UserIdleService,
    private applicationStateService: ApplicationStateService,
    private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private orderService: OrderService,
    private router: Router,
    private route: ActivatedRoute,
    private kioskNavigationService: KioskNavigationService,
    private modelService: ModalService,
    private orderKioskStorageService: OrderKioskStorageService
  ) { }

  ngOnInit() {
    if (!this.kioskNavigationService.orderDetails) {
      this.router.navigate(['..'], { relativeTo: this.route });
    } else {
      const settingParam = this.applicationStateService.settingParam;
      if (settingParam) {
        this.backgroundImage = settingParam.OrderKioskCheckoutImage;
        this.allowPayAtCounter = settingParam.OrderKioskAllowPayAtCounter;
      }
      this.terminalId = this.applicationStateService.terminalId;
      if (this.terminalId) {
        this.subscribeToUserIdleSubscription();
      }
    }
  }

  userGoneIdle = () => {
    this.stopUserIdleService();
    const callBack = (res) => {
      if (res?.continueOrder) {
        this.subscribeToUserIdleSubscription();
      } else {
        this.closeModalsIfOpen();
        this.cancelOrder(true);
      }
    };
    this.kioskNavigationService.openOverlayModal(KioskUserIdleComponent, callBack)
  }

  private closeModalsIfOpen() {
    if (this.paymentOptionsModalRef)
      this.paymentOptionsModalRef.close.emit();
    if (this.kioskInvoiceComponent?.numpadRef)
      this.kioskInvoiceComponent.numpadRef.close.emit();
  }

  confirmCancelOrder() {
    this.stopUserIdleService();
    const callBack = (res) => {
      if (res?.continueOrder) {
        this.subscribeToUserIdleSubscription();
      } else {
        this.cancelOrder();
      }
    };
    this.kioskNavigationService.openOverlayModal(KioskConfirmCancelComponent, callBack, { intervalSeconds: this.kioskNavigationService.userIdleTimeout })
  }

  subscribeToUserIdleSubscription() {
    if (this.userIdleSubscription) {
      this.userIdleSubscription.unsubscribe();
    }
    this.userIdleSubscription = this.userIdleService.initialize(this.kioskNavigationService.userIdleTimeout, () => this.userGoneIdle());
  }

  stopUserIdleService() {
    this.userIdleService.stop();
    this.userIdleSubscription?.unsubscribe();
  }

  cancelOrder(isCancelFromIdle = false) {
    this.spinnerService.show();
    this.orderService.deleteOrder(this.kioskNavigationService.orderDetails.Id.Value)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe({
        next: () => {
          this.kioskNavigationService.resetProps();
          if (isCancelFromIdle) {
            this.navigateToKioskHome();
          } else {
            const callBack = (res) => this.navigateToKioskHome({ startOrderingDirectly: res?.newOrder });
            this.kioskNavigationService.openOverlayModal(KioskCancelOrderScreenComponent, callBack, { intervalSeconds: this.kioskNavigationService.userIdleTimeout });
          }
        }, error: this.alertsService.showApiError
      });
  }

  addNewItem() {
    this.navigateToKioskHome({ startOrderingDirectly: true, continueOrderScreenId: this.orderKioskStorageService.continueOrderScreenId ?? null });
  }

  navigateToKioskHome(data = {}) {
    this.router.navigate(['home'], { relativeTo: this.route.parent, state: data });
  }

  onOrderConfirmed() {
    if (this.allowPayAtCounter) {
      this.paymentOptionsModalRef = this.modelService.show(KioskPaymentOptionsScreenComponent, {
        backdrop: 'static',
        class: 'vertical-center payment-model',
        animated: false,
        keyboard: true,
        initialState: {
          router: this.router,
          route: this.route
        }
      });
    } else {
      this.router.navigate(['cc-payment'], { relativeTo: this.route.parent });
    }
  }

  ngDestroy() {
    this.stopUserIdleService();
  }
}
