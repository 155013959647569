import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RuntimeConstants, SettingParam } from 'src/app/shared';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  paymentsApiBasePath = `${RuntimeConstants.API_PATH}payments`;

  constructor(private httpClient: HttpClient) { }

  getPaymentsDetail(terminalId: number): Observable<SettingParam> {
    return this.httpClient.get<SettingParam>(`${this.paymentsApiBasePath}/${terminalId}`);
  }

  savePayments(params: SettingParam): Observable<SettingParam> {
    return this.httpClient.post<SettingParam>(`${this.paymentsApiBasePath}`, params);
  }

}
