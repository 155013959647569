import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertsService, ApplicationStateService, BaseFormComponent, DomainConstants, FieldInfoMessages, FormUtilityService, Messages, SettingParam, SpinnerService } from 'src/app/shared';
import { PaymentsService } from '../../services/payments.service';
import { finalize } from 'rxjs/operators';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { paymentsMoneyBillAlt } from 'src/app/shared/components/icon';
import { NgForm } from '@angular/forms';
import { PostSavedConfigurationService } from 'src/app/configurator/post-saved-configuration.service';
@Component({
  selector: 'pos-payments',
  templateUrl: './payments.component.html',
})
export class PaymentsComponent extends BaseFormComponent implements OnInit {
  @ViewChild('paymentsForm') paymentsForm: NgForm;
  get getForm(): NgForm {
    return this.paymentsForm
  }
  parameters: SettingParam;
  fieldInfoMessages = FieldInfoMessages;
  autoDefer = false;
  allTenderTypes = DomainConstants.TenderTypes;
  ringUp = false;
  creditCardTypes = DomainConstants.CreditCardTypes;
  ebtTypes = DomainConstants.CreditCardTypes;
  icons = {
    paymentsMoneyBillAlt
  }
  props = {
    labelClass: '',
    controlBoxClass: 'col-xs-12'
  }
  constructor(private spinnerService: SpinnerService,
    private paymentsService: PaymentsService,
    private alertsService: AlertsService,
    private applicationStateService: ApplicationStateService,
    private postSavedConfigurationService: PostSavedConfigurationService,
    private router: Router,
    formUtilityService: FormUtilityService) {
    super(formUtilityService);
    this.parameters = applicationStateService.newSettingParams();
  }

  ngOnInit() {
    this.loadData();
    this.ebtTypes = _.filter(DomainConstants.CreditCardTypes, (type) => {
      return type.Name === DomainConstants.EBTTypes.Peripheral;
    });
  }

  loadData() {
    this.spinnerService.show();
    this.paymentsService.getPaymentsDetail(this.applicationStateService.terminalId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: SettingParam) => {
          this.parameters = res;
          if (this.parameters.AutoDefer) {
            this.autoDefer = true;
          } else {
            this.ringUp = true;
          }
          const tenderTypes = [];
          _.forEach(this.parameters.AvailableTenderTypes, (availableTenderType) => {
            const tenderType = _.find(this.parameters.TenderTypes, (type) => type.Code === availableTenderType.Code);
            if (tenderType) {
              availableTenderType.IsSelected = true;
            } else {
              availableTenderType.IsSelected = false;
            }
            tenderTypes.push(availableTenderType);
          });
          this.parameters.TenderTypes = tenderTypes;
        }, error: this.alertsService.showApiError
      });
  }

  savePayments(isValid: boolean) {
    if (!isValid) {
      return;
    }
    const params = _.cloneDeep(this.parameters);
    this.parameters.AutoDefer = this.autoDefer;
    this.parameters.AllowCreditCardTip = false;
    this.parameters.AllowEBTPaymentType = false;
    this.parameters.GiftcardAddBalanceByCreditCard = false;
    this.parameters.AllowAddingTipTillDays = 0;
    this.parameters.GiftcardTransactionFee = 0;
    _.forEach(this.parameters.TenderTypes, (tenderType) => {
      if (tenderType.Code === 'GiftCard' && !tenderType.IsSelected) {
        this.parameters.GiftCardMask = '';
      }
      if (tenderType.Code === 'CreditCard') {
        this.setParamsForCreditCardTenderType(tenderType, params);
      }
      if (tenderType.Code === DomainConstants.TenderTypes.EBT.Code && tenderType.IsSelected) {
        this.parameters.AllowEBTPaymentType = true;
      }
    });
    const tenderTypes = [];
    _.forEach(this.parameters.TenderTypes, (tenderType) => {
      if (tenderType.IsSelected) {
        tenderTypes.push(tenderType);
      }
    });
    this.parameters.TenderTypes = tenderTypes;
    this.spinnerService.show();
    this.paymentsService.savePayments(this.parameters)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.alertsService.renderSuccessMessage(Messages.PaymentsSaveSuccess);
          this.postSavedConfigurationService.showMessage();
        }, error: this.alertsService.showApiError
      });
  }

  private setParamsForCreditCardTenderType(tenderType: any, params: SettingParam) {
    if (tenderType.IsSelected) {
      this.parameters.AllowCreditCards = true;
      this.parameters.GiftcardAddBalanceByCreditCard = params.GiftcardAddBalanceByCreditCard;

      if (this.parameters.GiftcardAddBalanceByCreditCard) {
        this.parameters.GiftcardTransactionFee = params.GiftcardTransactionFee ?? 0;
      }
      if (this.parameters.CreditCardType === DomainConstants.CreditCardType.Integrated) {
        this.parameters.AllowCreditCardTip = params.AllowCreditCardTip;
        this.parameters.AllowAddingTipTillDays = params.AllowCreditCardTip &&
          params.AllowAddingTipTillDays ? params.AllowAddingTipTillDays : 0;
      }
    } else {
      this.parameters.AllowCreditCards = false;
    }
  }

  closeEdit() {
    this.router.navigate(['/manage/system-configuration']);
  }

}
