import { Component } from '@angular/core';
import { forEach, intersectionBy } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import {
  AlertsService, ApplicationLoginService, ApplicationStateService, AuthenticationService,
  EventBroadcastingService, HttpStatusCodes, Messages, ModalComponent, PermissionService, SpinnerService, UserDetails
} from 'src/app/shared';
import { UserSignInByButtonModel } from 'src/app/shared/interface/user-sign-in-by-button-model';
declare let $: any;
@Component({
  selector: 'pos-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent extends ModalComponent {

  password: string = '';
  userDetails: UserDetails;
  userName: string;
  isKeyboardWedgeEnabled: boolean = true;
  constructor(private applicationStateService: ApplicationStateService,
    private spinnerService: SpinnerService,
    private applicationLoginService: ApplicationLoginService,
    private alertService: AlertsService,
    private permissionService: PermissionService,
    private authenticationService: AuthenticationService,
    private eventBroadcastingService: EventBroadcastingService,
    protected deleteModalRef: BsModalRef) {
    super(deleteModalRef)
  }

  ngOnInit(): void {
    this.userDetails = this.applicationStateService.userDetails;
    this.applicationStateService.enableAttendanceCardSwipe = false;
    const focusTimeout = setTimeout(() => {
      $('#signInPassword').focus();
      if (focusTimeout) {
        clearTimeout(focusTimeout);
      }
    });
  }

  cancel = (isSignIn) => {
    this.applicationStateService.enableAttendanceCardSwipe = true;
    this.close.emit({ shouldReload: isSignIn });
  }

  cardBasedLogin = (cardBasedPass) => {
    this.spinnerService.show();
    this.authenticationService.GetAuthenticationTokenForCardBasedLogin(cardBasedPass, '', '', true)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: any) => {
          if (res) {
            const customerCredential = {
              Password: res.Password,
              ExternalPassword: '',
              Username: ''
            };
            this.getUserDetails(customerCredential);
          }
        }, error: this.alertService.showApiError
      });
  }

  onSwipeDetected(badgeNumber) {
    if (!this.applicationStateService.enableAttendanceCardSwipe) {
      this.cardBasedLogin(badgeNumber);
    } else {
      this.applicationStateService.enableAttendanceCardSwipe = true;
    }
  }

  userLogin(): void {
    this.spinnerService.show();
    this.authenticationService.getAuthenticationToken
      (this.password, '', '').pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: any) => {
          const customerCredential = {
            ExternalPassword: '',
            Password: this.password,
            Username: ''
          };
          if (this.password) {
            this.applicationStateService.userCode = this.password;
          }
          this.getUserDetails(customerCredential);
        }, error: (error) => { this.handelLoginError(error); }
      });
  }

  private handelLoginError = (response) => {
    let message = '';
    this.spinnerService.hide();
    if (response?.data || response?.error) {
      if (response.status === HttpStatusCodes.BadRequest) {
        if (response && response.data && !response.data.ReturnStatus && response.data.ReturnMessage.length > 0) {
          message = response.data.ReturnMessage[0];
        } else {
          message = response.data ? response.data : response.error;
        }
      } else {
        if (response.data && response.data.StatusMessage && response.data.StatusMessage !== '') {
          message = response.data.StatusMessage;
        } else if (response.status === HttpStatusCodes.Unauthorized) {
          message = 'Access denied';
        } else {
          if (response.ReturnMessage) {
            message = this.formatMessage(response.ReturnMessage);
          } else if (response.data) {
            message = this.formatMessage(response.data.ReturnMessage);
          }
          message = (message && message !== '') ? message : Messages.UnexpectedErrorOccurred;
        }
      }
      this.alertService.renderErrorMessage(message);
    }
    $('#appPassword').focus();
  }

  private getUserDetails(customerCredential) {
    this.spinnerService.show();
    this.applicationLoginService.getCustomer(customerCredential).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (response) => {
        if (response) {
          const userDetails = response?.UserTerminalDetails?.UserDetails;
          if (this.applicationStateService.userDetails.id !== userDetails.id) {
            const details: UserSignInByButtonModel = {
              userDetails: userDetails,
              callBack: () => {
                this.signInCompleted(userDetails);
              }
            };
            this.eventBroadcastingService.userSignInByButtonEvent(details);
          } else {
            this.cancel(true);
          }
        }
      }, error: (error) => {
        this.handelLoginError(error);
      }
    });
  }

  private signInCompleted(userDetails: any) {
    const commonRoles = intersectionBy(this.userDetails.Roles, userDetails.Roles, 'RoleId');
    if ((this.userDetails?.Roles?.length !== userDetails?.Roles?.length ||
      userDetails?.Roles?.length !== commonRoles?.length)) {
      const loadedPermissions = this.applicationStateService.loadedPermissions;
      forEach(loadedPermissions, (x) => {
        this.permissionService.reflectPermission(x);
      });
    }
    this.cancel(true);
  }

  private formatMessage(message): string {
    let messageBox = '';
    Array.isArray(message);
    if (JSON.parse(message)) {
      for (let i = 0; i < message.length; i++) {
        messageBox = messageBox + message[i];
      }
    } else {
      messageBox = message;
    }
    return messageBox;
  }

  textEnteredForLogin(value: string) {
    this.password += value;
  }
}
