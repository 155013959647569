import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GridColumn } from '@tarktech/tark-ng-utils';
import { cloneDeep, filter, find, forEach, orderBy } from 'lodash';
import { DomainConstants } from 'src/app/shared/constants';
import { EventSubscriptionActionAttribute } from '../../interfaces/event-subscription-action-attribute';
import { deleteWhite } from 'src/app/shared/components/icon';
import { AlertsService, FormUtilityService, HardwareModel, Messages, ModalFormComponent, ModalService, SpinnerService } from 'src/app/shared';
import { HardwaresService } from 'src/app/configurator';
import { finalize } from 'rxjs/operators';
import { LightsInfo } from 'src/app/configurator/hardware/interface';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgForm } from '@angular/forms';
import { RepeatActionConfigurationComponent } from '../repeat-action-configuration';

@Component({
  selector: 'pos-light-configuration',
  templateUrl: './light-configuration.component.html',
  styleUrls: ['./light-configuration.component.scss']
})
export class LightConfigurationComponent extends ModalFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.lightActivityEditForm;
  }
  OFF_LIGHT_COLOR = '#000000';
  @ViewChild('RepeatActionConfiguration') repeatActionConfiguration: RepeatActionConfigurationComponent;
  @Input() actionId = 0;
  @Input() eventSubscriptionActionAttributes: Array<EventSubscriptionActionAttribute> = [];
  @Input() deliveryTypes = [];
  @Input() eventAttributes: Array<string> = [];
  @Input() eventIdentifier: string;
  @Input() selectedDeliveryType;
  @Input() lightingControllers: Array<HardwareModel> = [];
  @Input() isRecoverableEvent;
  @ViewChild('lightActivityEditForm') lightActivityEditForm: NgForm;
  // close: EventEmitter<any> = new EventEmitter();
  deliveryAttributes = DomainConstants.EventDeliveryAttributes;
  configColumns: Array<GridColumn> = [];

  indicationType = 'On';
  lightsConfiguration: Array<{ PanelId: string, Color: string, disabled?: boolean }> = [];
  duration: string = null;
  lightingControllerId: number = null;
  panelIds: Array<LightsInfo> = [];
  panelsUnavailableMessage = Messages.PanelsUnavailableMessage;

  icons = {
    deleteWhite
  };

  constructor(protected hardwareService: HardwaresService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    modalRef: BsModalRef, modalService: ModalService, protected formUtilityService: FormUtilityService) {
    super(modalRef, modalService, formUtilityService);
  }

  ngOnInit(): void {
    const indicationType = find(this.eventSubscriptionActionAttributes,
      x => x.AttributeKey === this.deliveryAttributes.Light.IndicationType)?.AttributeValue;
    this.indicationType = indicationType ? indicationType : this.indicationType;
    const lightConfiguration = find(this.eventSubscriptionActionAttributes,
      x => x.AttributeKey === this.deliveryAttributes.Light.LightsConfiguration)?.AttributeValue;
    this.lightsConfiguration = lightConfiguration ? JSON.parse(lightConfiguration) : [];
    const lightingController = parseInt(find(this.eventSubscriptionActionAttributes,
      x => x.AttributeKey === this.deliveryAttributes.Light.LightingControllerId)?.AttributeValue, 10);
    this.lightingControllerId = lightingController ? lightingController : null;
    this.duration = find(this.eventSubscriptionActionAttributes,
      x => x.AttributeKey === this.deliveryAttributes.Light.Duration)?.AttributeValue;
    if (this.indicationType === 'Off') {
      forEach(this.lightsConfiguration, x => x.Color = null);
    }
    this.createLightsConfigRow(this.lightsConfiguration.length - 1);
    this.lightingControllerSelected();
  }

  deleteLightConfiguration(panelId) {
    this.lightsConfiguration = filter(this.lightsConfiguration, (x) => x.PanelId != panelId);
    const timeout = setTimeout(() => {
      this.changeLightSelection();
      clearInterval(timeout);      
    });
  }

  changeLightSelection = () => {
    const panels = cloneDeep(this.panelIds);
    this.panelIds = [];
    panels.forEach((x) => {
      const panel = this.lightsConfiguration.find((lc) => { return lc.PanelId == x.PanelId.toString(); });
      x.disabled = !!panel;
    });
    this.panelIds = panels;
  }

  createLightsConfigRow(index: number, firstRow = false) {
    this.changeLightSelection();
    if (this.lightsConfiguration.length !== (index + 1) && !firstRow) {
      return;
    }
    const newLight = { PanelId: null, Color: null };
    this.lightsConfiguration.push(newLight);
  }

  onClose = (shouldReload: boolean) => {
    this.hide({ shouldReload: shouldReload });
  }

  saveActivity = (isValid: boolean) => {
    if (isValid) {
      const actionAttributes = this.prepareEventActionAttributes();
      this.hide({ shouldReload: true, actionAttributes: actionAttributes });
    }
  }

  prepareEventActionAttributes() {
    const actionDeliveryAttributes: Array<EventSubscriptionActionAttribute> = [];
    this.addAttribute(this.deliveryAttributes.Light.IndicationType, this.indicationType, actionDeliveryAttributes);
    if (this.indicationType === 'Off') {
      forEach(this.lightsConfiguration, x => x.Color = this.OFF_LIGHT_COLOR);
    }
    this.lightsConfiguration = filter(this.lightsConfiguration, x => x.PanelId != null);
    this.addAttribute(this.deliveryAttributes.Light.LightsConfiguration, JSON.stringify(this.lightsConfiguration),
      actionDeliveryAttributes);
    this.addAttribute(this.deliveryAttributes.Light.Duration, this.duration,
      actionDeliveryAttributes);
    this.addAttribute(this.deliveryAttributes.Light.LightingControllerId, this.lightingControllerId,
      actionDeliveryAttributes);
    if (this?.repeatActionConfiguration?.repeatConfig) {
      this.addAttribute(this.deliveryAttributes.Light.Repeat, this?.repeatActionConfiguration?.isRepeat ? JSON.stringify(this?.repeatActionConfiguration?.repeatConfig) : "", actionDeliveryAttributes)
    }
    return actionDeliveryAttributes;
  }

  addAttribute = (key, value, array) => {
    const actionDeliveryAttribute: EventSubscriptionActionAttribute = {
      Id: this.getActionAttributeId(key),
      AttributeKey: key,
      AttributeValue: value,
      EventSubscriptionActionId: this.actionId
    };
    array.push(actionDeliveryAttribute);
  }

  getActionAttributeId(key) {
    const attribute = find(this.eventSubscriptionActionAttributes, x => x.AttributeKey === key);
    return attribute ? attribute.Id : 0;
  }

  lightingControllerSelected() {
    if (this.lightingControllerId) {
      this.spinnerService.show();
      this.hardwareService.get(this.lightingControllerId)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (hardware: HardwareModel) => {
            const lightInfo = hardware.Properties.find(prop => prop.Name === DomainConstants.LightHardwareProperty.LightsInfo);
            this.panelIds = lightInfo ? JSON.parse(lightInfo.Value) : [];
            forEach(this.panelIds, panel => {
              panel.Name = panel.Name ? `${panel.Name} (${panel.PanelId})` : panel.PanelId.toString();
            });
            this.panelIds = orderBy(this.panelIds, 'Name');
            if (!this.panelIds.length) {
              this.lightsConfiguration = [];
              this.createLightsConfigRow(0, true);
            } else {
              this.changeLightSelection();
            }
          }, error: this.alertService.showApiError
        });
    } else {
      this.panelIds = [];
      this.lightsConfiguration = [];
      this.createLightsConfigRow(0, true);
    }
  }

  actionChanged() {
    if (this.indicationType === 'Off') {
      forEach(this.lightsConfiguration, x => x.Color = null);
    }
  }

}
