<div class="footer"
    [ngStyle]="{'background-color': footer.BackgroundColor, 'background-image': footer.BackgroundImage ? 'url(\''+ imageRootPath + footer.BackgroundImage + '\')' : '', 'border-top': footer.BackgroundImage ? '' : (footer.BorderWidth ? footer.BorderWidth + 'px' : '0') + ' solid ' + footer.BorderColor }">
    <div class="buttons">
        <button class="btn btn-sm normal text-nowrap" *ngIf="footer.IsShowBackButton" (click)="back()">
            <img class="mt-pix-n3 me-pix-2" height="16" [src]="imageRootPath+'back-arrow.png'" />
            Back</button>
    </div>
    <div class="footer-allergen-notes" *ngIf="showAllergenNotes" [innerHTML]="allergenNotes">
    </div>
    <div class="buttons">
        <button class="btn btn-sm normal text-nowrap me-pix-10" (click)="addItemToOrder()" *ngIf="isNotAllergenTemplate && !isAICreatedTemplate"
            [disabled]="!isShowAddProduct">
            Continue <img class="mt-pix-n3" height="16" [src]="imageRootPath+'continue-arrow.png'" />
        </button>
        <div class="cart pointer" (click)="checkout()">
            <span class="cart-img">
                <img [src]="imageRootPath+'bag.png'" />
                <span class="item-total">{{itemsCount}}</span>
            </span>
            <span class="price-total">{{ orderTotal | number: '2.2-2' }}</span>
        </div>
    </div>
</div>
