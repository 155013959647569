<div class="col-md-3 pt-pix-10">
  <div class="left-nav-toggle" id="menu-toggle-div" (click)="toggleFullScreen()">
    <a class="left-nav-toggle__icon">
      <pos-icon class="black"
        [icon]="isFullScreen ? icons.infoManageAngleDoubleRight : icons.infoManageAngleDoubleLeft"></pos-icon>
    </a>
  </div>
</div>
<div class="col-12 tab-section px-3" id="informationMgtDiv">
  <div class="vertical-tabs">
    <ul class="nav nav-pills nav-stacked flex-column" [hidden]="isFullScreen">
      <div>
        <div class="relative">
          <span class="search-icons">
            <pos-icon [icon]="icons.times" *ngIf="informationManagementFilter"
              (click)="informationManagementFilter = ''; search()">
            </pos-icon>
            <pos-icon [icon]="icons.search" *ngIf="!informationManagementFilter"></pos-icon>
          </span>
          <input class="form-control" [(ngModel)]="informationManagementFilter" (input)="search()"
            placeholder="App Configuration Filter...">
        </div>
      </div>
      <ng-container *ngFor="let menuGroup of filterMenuList">
        <li class="tab-head" *ngIf="menuGroup.Permission && menuGroup.PermissionLevel"
          permissions='[{"Name":"{{menuGroup.Permission}}", "Level":"{{menuGroup.PermissionLevel}}"}]'>
          {{menuGroup.GroupName}}
        </li>
        <li class="tab-head" *ngIf="!menuGroup.Permission && !menuGroup.PermissionLevel">
          {{menuGroup.GroupName}}
        </li>
        <ng-container *ngFor="let menulist of menuGroup.List">
          <li *ngIf="menulist.Permission && menulist.PermissionLevel" class="pointer" role="presentation"
            grantif="all" [routerLink]="menulist.RoutLink" routerLinkActive="active" permissions='[{"Name":"{{menulist.Permission}}", "Level":"{{menulist.PermissionLevel}}"},
                    {"Name":"{{menuGroup.Permission}}", "Level":"{{menuGroup.PermissionLevel}}"}]' scrollup>
            <a>
              <div class="display-contents">
                <section class="float-start text-center col-33">
                  <pos-icon class="black font-x-normal" [icon]="menulist.Icon"></pos-icon>
                </section>
              </div>
              <div class="display-flex">
                {{menulist.Name}}
              </div>
            </a>
          </li>
          <li *ngIf="!menulist.Permission && !menulist.PermissionLevel" class="pointer" role="presentation"
            grantif="all" [routerLink]="menulist.RoutLink" routerLinkActive="active" scrollup>
            <a>
              <div>
                <section class="float-start text-center col-33">
                  <pos-icon class="black font-x-normal" [icon]="menulist.Icon"></pos-icon>
                </section>
              </div>
              <div class="display-flex">
                {{menulist.Name}}
              </div>
            </a>
          </li>
        </ng-container>
      </ng-container>
    </ul>
    <div class="p-0">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
