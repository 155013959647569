<div>
  <button class="close" (click)="onCancel(true)">&times;</button>
  <h4 class="modal-content__heading">Training Notes</h4>
</div>
<div class="pos-modal-body py-pix-5 overflow-hidden pb-0">
  <div class="col-12 screen-scroll">
    <div class="pt-pix-10">
      <div class="col-md-6 col-12 ps-0">
        <label>Area: {{trainingData.AreaName}}</label>
      </div>
      <div class="col-md-6 col-12 ps-0">
        <label>Topic: {{trainingData.TopicName}}</label>
      </div>
      <div class="col-md-6 col-12 ps-0">
        <label>Level: {{trainingData.LevelName}}</label>
      </div>
      <div class="col-md-6 col-12 ps-0">
        <label>User: {{trainingData.UserName}}</label>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="col-12 pt-pix-10 p-0">
      <ng-table #gridComponent [sort]="false" [search]="false" [paginator]="false" [columns]="userTrainingNoteColumns"
        [data]="userTrainingNotes">
      </ng-table>
    </div>
  </div>
</div>
<div class="col-12 action-button-container popup-footer pb-pix-10">
  <button class="btn-secondary btn-lg btn btn-cancel-modal pos-button" type="button" (click)="onCancel(true)">Close</button>
</div>
<div class="clearfix"></div>
<pos-confirm-delete [message]="message" (onConfirmAcknowledge)="onConfirmAcknowledge()" #confirmTrainingNoteDelete>
</pos-confirm-delete>

<ng-template class="modal" #userTrainingNoteModal tabindex="-1" role="dialog">
  <pos-user-training-note-edit [userTrainingNoteId]="userTrainingNoteId"
    [userTrainingLevelId]="trainingData.UserTrainingLevelId" [userTrainingNote]="userTrainingNote"
    (onCancelTrainingNotes)="onCancelUserTrainingNote()" (onSaveTrainingNotes)="onSaveUserTrainingNote()"
    [trainingData]="trainingData"></pos-user-training-note-edit>
</ng-template>
