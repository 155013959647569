import { Injectable } from "@angular/core";
import { RabbitMQService } from "./rabbitmq";
import { ApplicationStateService } from "./application-state.service";

@Injectable({ providedIn: 'root' })

export class TerminalStatusCheckService {

    constructor(private rabbitMqService: RabbitMQService, private applicationStateService: ApplicationStateService){}

    subscribeTerminalStatusCheck(terminalId: number) {
        return this.rabbitMqService.subscribeToTerminalStatusCheck$(terminalId)
    }

    publishTerminalStatusResponse(request: any, body: any = {}) {
        console.log("RPC Server request: " + request.body);
        const replyTo = request.headers['reply-to'];
        const correlationId = request.headers['correlation-id'];
        const defaultBody = {
            UserId : this.applicationStateService.userId,
            UserName: this.applicationStateService.userDetails.username,
            ...body,
        }
        const responseBody = JSON.stringify(defaultBody);
        console.log("RPC Server response: " + responseBody + " for " + request.body);
        this.rabbitMqService.sendRpcResponse(replyTo, responseBody, correlationId);
    }
}
