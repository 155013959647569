<pos-list-wrapper [headingIcon]="icons.accountCategoriesClipboardList" heading="Account Categories" (close)="close()">
    <div class="row">
        <div class="col-12">
            <ng-table id="accountCategory" #accountCategoryGrid [sort]="true"
                [exportFilename]="'account-categories'" [columns]="accountCategoriesColumns"
                [data]="accountCategories">
            </ng-table>
        </div>
    </div>
</pos-list-wrapper>
<pos-print-table [hidden]="true"></pos-print-table>
