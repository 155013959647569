<!-- start: clock and scheme selector -->
<div class="row mt-pix-10 text-center vertical-center-align row-cols-3" style="font-size: 17px;">
    <div class="text-start">
        <ul id="clock" class="list-inline text-nowrap">
            {{ currentTime | tark_time: true }}
        </ul>
    </div>
    <div class="text-center">
        <h3>{{ djScheme }}</h3>
    </div>
    <div class="text-end" ngbDropdown>
        <div class="btn-group">
            <button ngbDropdownToggle type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
                <pos-icon [icon]="icons.djBars"></pos-icon>
            </button>
            <div ngbDropdownMenu class="dropdown-menu">
                <a ngbDropdownItem *ngFor="let scheme of schemes; let i = index;" class="pointer" (click)="nextScheme(scheme)">
                    {{ scheme }}
                </a>
            </div>
        </div>
    </div>
</div>
<!-- end: clock and scheme selector -->
<hr />
<!-- start: main display for track and playback details -->
<div class="row justify-content-center word-break">
    <h1><span class="font-2_2em">{{djTitle}}</span></h1>
    <span class="text-center font-1.2em">{{playBackTitle}}</span>
</div>
<!-- end: main display for track and playback details -->
<div class="col-md-12 p-0">
    <hr />
</div>

<div class="row col-md-10 col-md-offset-1 p-0 align-items-center">
    <div class="col-sm-6 col-12 text-xs-left ps-0 left-padding">
        <div class="btn-group" role="group" aria-label="Player controls">
            <button [disabled]="isLoadPlaylist" [ngClass]="{'btn-success btn-color-white': !isStopped, 'btn-secondary': isStopped }"
                type="button" class="btn" (click)="start(null, null)" *ngIf="!isPlaying">
                <pos-icon [icon]="icons.play"></pos-icon>
            </button>
            <button [disabled]="isLoadPlaylist" [ngClass]="{'btn-success btn-color-white': !isStopped, 'btn-secondary': isStopped }"
                type="button" class="btn" (click)="pause()" *ngIf="isPlaying">
                <pos-icon [icon]="icons.pause"></pos-icon>
            </button>
            <button [disabled]="isLoadPlaylist" [ngClass]="{'btn-success btn-color-white': isStopped, 'btn-secondary': !isStopped }"
                type="button" class="btn" (click)="stop()">
                <pos-icon [icon]="icons.stop"></pos-icon>
            </button>
            <button [disabled]="isLoadPlaylist" type="button" class="btn btn-secondary" (click)="playPrevious()">
                <pos-icon [icon]="icons.stepBackward"></pos-icon>
            </button>
            <button [disabled]="isLoadPlaylist" type="button" class="btn btn-secondary" (click)="playNext()">
                <pos-icon [icon]="icons.stepForward"></pos-icon>
            </button>
        </div>
    </div>
    <div class="hidden-xs-down col-sm-6 col-12 text-xs-right">
        <pos-row class="align-items-center">
            <pos-column xs="11">
                <ngx-slider [value]="volume" [options]="sliderOptions" (userChange)="setVolume($event)"
                    [ngClass]="{'pointers-none disable-volume-slider': isLoadPlaylist}">
                </ngx-slider>
            </pos-column>
            <pos-column xs="1" class="p-0">
                <span class="badge volume-circle">
                    {{volume}}
                </span>
            </pos-column>
        </pos-row>
    </div>
</div>
<!-- end: playback and volume controls -->
<div class="col-md-12 p-0">
    <hr />
</div>
<!-- start: progress bar and playback timer -->
<div class="row vertical-center-align">
    <div class="col-md-10 offset-md-1 p-0 display-flex align-items-baseline">
        <div class="col-8 col-sm-9" [ngClass]="{'pointers-none': isLoadPlaylist}">
            <progress class="progress pointer" [value]="currentProgress" (click)="progressBarClicked($event)"
                [max]="duration"></progress>
        </div>
        <div id="counter" class="col-4 col-sm-3 text-xs-right">
            {{counter}}
        </div>
    </div>
    <div class="col-md-12">
        <hr />
    </div>
</div>
<!-- end: progress bar and playback timer -->
<!-- start: playlist for tracks -->
<div class="row vertical-center-align">
    <h1 id="playlistName">{{ playlistName }}</h1>
    <ul id="playlist" class="list-unstyled playlist col-md-10 col-md-offset-1 p-0">
        <li *ngFor="let track of playlistTracks; let i = index;" class="font-1_2em" (click)="loadTrack(i)"
            [ngClass]="{'selected': i === selectedPlaylist}">
            <a class="pointer" scrollup>{{track.displayPath}} {{track.displayCategory}}</a></li>
    </ul>
</div>
<!-- end: playlist for tacks -->
