<div id="order" class="order-screen-page">
  <div class="h-80" *ngIf="homeState" [hidden]="isSlideShow || isNewProductAdded">
    <pos-header-entry-main [links]="headerLinks" [isShowBarIcon]="false"></pos-header-entry-main>
  </div>
  <section class="order-screen-section" *ngIf="!isSlideShow">
    <div class="col-12 pt-pix-5" *ngIf="!isNewProductAdded">
      <div class="order-screen-section__summary">
        <div class="row mx-0">
          <div class="col-12">
            <h2 class="order-screen-section__summary__title">Order Summary</h2>
            <div class="font-14">
              <div class="order-screen-section__summary__title-cap col-12 p-0"
                [hidden]="!orderDetails || !orderDetails.Id || !orderDetails.Id.Value">
                Order #{{orderDetails.SurrogateOrderId}}
              </div>
              <span class="order-screen-section__summary__title-cap col-12 p-0"
                *ngIf="orderDetails && orderDetails.ScheduleDate">
                <pos-icon [icon]="icons.clock"></pos-icon>
                {{ orderDetails.ScheduleDate | tark_date_time }}
              </span>
              <span class="order-screen-section__summary__title-cap col-12 p-0"
                *ngIf="orderDetails && orderDetails.CustomerAccount && orderDetails.CustomerAccount.Name">{{loyaltyAccountTerm}}:
                {{orderDetails.CustomerAccount.Name}}
              </span>
              <span class="order-screen-section__summary__title-cap col-12 p-0"
                *ngIf="orderDetails && orderDetails.Account && orderDetails.Account.Name">{{orderDetails.Account.Type ==
                accountTypes.Tab ? accountTerm : tableTerm}}:
                {{orderDetails.Account.Name}}
              </span>
              <span class="order-screen-section__summary__title-cap col-12 p-0" *ngIf="orderName">{{orderName}}</span>
              <span class="order-screen-section__summary__title-cap col-12 p-0" *ngIf="orderPhoneNumber">Phone:
                {{orderPhoneNumber | phoneMaskPipe }}</span>
              <span class="order-screen-section__summary__title-cap col-12 p-0"
                *ngIf="orderDetails?.Id?.Value && orderDetails.OrderAttributes.IsCallIn">
                Call In: {{orderDetails.OrderAttributes.CallInOrderTime}}
              </span>
              <div class="order-screen-section__summary__title-cap"
                [hidden]="orderDetails && orderDetails.Id && orderDetails.Id.Value">
                No Current Order
              </div>
            </div>
          </div>
        </div>

        <div class="order-screen-section__summary__items bottom-invoice px-pix-10"
          *ngIf="orderDetails && orderDetails.Id && orderDetails.Id.Value"
          [ngStyle]="{'padding-bottom': orderDetails.MarkupAmount && orderDetails.DiscountAmount ? '245px' : (orderDetails.MarkupAmount || orderDetails.DiscountAmount ? '200px' : 'inherit')}">

          <div class="order-screen-section__summary__items__block" *ngIf="orderDetails.MarkupPct">
            <div class="row mx-0 order-screen-section__summary__items__block__list">
              <div class="col-md-7 col-sm-7 col-8 ps-pix-5">
                <strong>
                  <strong>Markup {{orderDetails.MarkupPct}}%</strong>
                </strong>
              </div>
              <div class="col-md-5 col-sm-5 col-4 p-0 pe-pix-15">
                <span class="product-price" style="padding-top: 0px">
                  <span class="product-price">{{((orderDetails.ProductsTotal * orderDetails.MarkupPct) / 100) |
                    tark_currency}}</span>
                </span>
              </div>
            </div>
          </div>

          <div class="order-screen-section__summary__items__block" *ngIf="orderDetails.DiscountPct">
            <div class="row mx-0 order-screen-section__summary__items__block__list">
              <div class="col-md-7 col-sm-7 col-8 ps-pix-5">
                <strong>
                  <strong>Discount {{orderDetails.DiscountPct}}%</strong>
                </strong>
              </div>
              <div class="col-md-5 col-sm-5 col-4 p-0 pe-pix-15">
                <span class="product-price" style="padding-top: 0px">
                  <span class="product-price">{{((orderDetails.ProductsTotal * orderDetails.DiscountPct) / 100) |
                    tark_currency}}</span>
                </span>
              </div>
            </div>
          </div>

          <div class="order-screen-section__summary__items__block">
            <div class="col-sm-12 order-screen-section__summary__items__block__list"
              *ngFor="let product of orderDetails.OrderItems; index as i"
              [ngClass]="{'order-screen-section__summary__items__block__selected-odd-row': i % 2 != 0,
                          'order-screen-section__summary__items__block__selected-even-row': i % 2 == 0, 'combo-product': product.GroupId, 'pti-0': product.GroupId && !product.IsComboProduct }">
              <div class="col-12 combo-header mb-pix-7 px-pix-5" *ngIf="product.GroupId && !product.IsComboProduct"> {{
                product.OrderProductGroup?.Name }} </div>
              <div class="row mx-0">
                <div class="col-md-7 col-sm-7 col-8 ps-pix-5">
                  <div>
                    <strong>
                      <pos-icon [icon]="icons.clock" *ngIf="product.IsScheduled"></pos-icon> {{product.ReceiptText}}
                      <span *ngIf="product.Qty">
                        ({{ product.Qty | productQty}})</span>
                    </strong>
                    <br />
                    <div class="width-full ps-pix-10" [hidden]="product.OrderItemType != orderItemTypes.Product"
                      *ngFor="let item of product.OrderProductComponents">

                      <div class="order-screen-section__summary__items__block__list__comment"
                        *ngIf="item.ReceiptText.indexOf('-') == 0">
                        No
                        {{item.ReceiptText | noMinusSign }}
                      </div>
                      <div class="order-screen-section__summary__items__block__list__comment"
                        *ngIf="item.ReceiptText.indexOf('-') != 0">
                        {{item.ReceiptText}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-5 col-sm-5 col-4 p-0 pe-pix-10">
                  <span class="product-price" style="padding-top: 0px">
                    {{product.Price*product.Qty | tark_currency}}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="order-screen-section__summary__totals right-0"
          [hidden]="!orderDetails || !orderDetails.Id || !orderDetails.Id.Value">
          <div class="col-lg-12 order-screen-section__summary__totals__bottom-border"></div>
          <div class="row mx-0">
            <div class="col-lg-9 col-md-8 col-sm-7 col-xs-12 col-12 text-center font-50 bold blink-verify-order pe-0"
              [ngStyle]="{'height': (isMobileMode || !height) ? 'unset' : height + 'px', 'font-size': isMobileMode?'30px':'50px'}">
              <span class="text-ellipsis" *ngIf="isReviewOrder"> Verified by {{ orderCustomerName ? orderCustomerName : 'customer' }}</span>
            </div>
            <div class="order-screen-section__summary__totals__sub-totals p-0 col-lg-3 col-md-4 col-sm-5 col-xs-12 col-5"
              id="total-div" #totalDiv>
              <div class="order-screen-section__summary__totals__sub-totals__block"
                [hidden]="!orderDetails.MarkupAmount">
                <span class="float-right pe-pix-15 ps-pix-10">{{orderDetails.MarkupAmount | tark_currency}}</span>
                <span>Markup &nbsp;</span>
              </div>
              <div class="order-screen-section__summary__totals__sub-totals__block pe-pix-15"
                [hidden]="!orderDetails.DiscountAmount">
                <span class="float-right ps-pix-10">{{orderDetails.DiscountAmount | tark_currency}}</span>
                <span>Discount &nbsp;</span>
              </div>
              <div class="order-screen-section__summary__totals__sub-totals__block pe-pix-15">
                <span class="float-right ps-pix-10">{{orderDetails.SubTotal | tark_currency}}</span>
                <span>Subtotal &nbsp;</span>
              </div>

              <div class="order-screen-section__summary__totals__sub-totals__block pe-pix-15">
                <span class="float-right ps-pix-10">{{0+orderDetails.TaxTotal | tark_currency}}</span>
                <span>Tax &nbsp;</span>
              </div>
              <div class="order-screen-section__summary__totals__grant-total">
                <span
                  class="order-screen-section__summary__totals__grant-total__amount ps-pix-5 float-end">{{0+orderDetails.GrandTotal
                  | tark_currency }}</span>
                <span class="order-screen-section__summary__totals__grant-total__amount float-start">Total &nbsp;</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container full-screen-display" *ngIf="isNewProductAdded"
      [ngStyle]="{'background-color': settingParam.ConfirmationScreenDisplayImageBackground, height: screenHeight + 'px'}">
      <div class="row height-100vh display-flex flex-flow-column">
        <div class="col-12 title-font text-center my-pix-20 text-shadow"
          [ngStyle]="settingParam.ConfirmationScreenDisplayImageHeaderStyle">Added to order:</div>
        <div class="row mx-0 flex-grow-1 align-items-center">
          <div class="col-lg-6 col-md-12 col-12 image-div">
            <img id="productImage" src="{{productImagePath}}/{{itemAddedToOrder.ProductImage}}" alt=""
              [ngStyle]="{'animation-duration': transitionSeconds + 's'}" class="product-image" />
          </div>
          <div class="col-lg-6 col-md-12 col-12 product-name"
            [ngStyle]="settingParam.ConfirmationScreenDisplayImageProductStyle">
            <span *ngIf="showProductText">{{ productName ? productName : itemAddedToOrder.ReceiptText }}</span>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div id="mediaRotator" *ngIf="isSlideShow && slideShowData && slideShowData.length">
    <pos-media-rotator [media]="slideShowData"></pos-media-rotator>
  </div>
</div>
