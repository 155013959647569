import { Component, OnInit, Input, OnDestroy, OnChanges, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { times, deleteWhite, ordinalBars, check, ingredientImport } from './../../../../shared/components/icon/icons';
import { InventoryProductRawIngredientModel } from 'src/app/orders';
import { InventoryProductIngredientsService } from '../../service';
import { SpinnerService, AlertsService, Messages, ApplicationStateService, EventBroadcastingService, ModalService } from 'src/app/shared';
import { RawIngredientsService } from 'src/app/information-management/raw-ingredients';
import { finalize } from 'rxjs/operators';
import * as _ from 'lodash';
import { ControlContainer, NgForm, NgModelGroup } from '@angular/forms';
import { cloneDeep, findLast, forEach } from 'lodash';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { ImportRawIngredientsComponent } from '../import-raw-ingredients/import-raw-ingredients.component';

@Component({
  selector: 'pos-inventory-product-raw-ingredients',
  templateUrl: './inventory-product-raw-ingredients.component.html',
  styleUrls: ['./inventory-product-raw-ingredients.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class InventoryProductRawIngredientsComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('ingredientsFormGroup')
  ingredientsFormGroup: NgModelGroup;
  @ViewChild('btn') btn: ElementRef;
  @Input() inventoryProductId: number = 0;
  @Input() isSubmit: boolean;
  public icons = {
    times,
    deleteWhite,
    ordinalBars,
    check,
    ingredientImport
  };
  ingredients: Array<InventoryProductRawIngredientModel> = [];
  availableRawIngredients: Array<string> = [];
  suggestedRawIngredients: Array<any> = [];
  parentIngredients: Array<InventoryProductRawIngredientModel> = [];
  isDebugModeOn = false;
  eventSubscriptions: Array<any> = [];
  removedIngredients: Array<InventoryProductRawIngredientModel> = [];
  loadTable = true;
  @Output() saveIngredients = new EventEmitter<any>();
  constructor(private rawIngredientService: InventoryProductIngredientsService,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private rawIngredientsService: RawIngredientsService,
    private applicationStateService: ApplicationStateService,
    private eventBroadcastingService: EventBroadcastingService,
    private modalService: ModalService) { }

  ngOnInit() {
    this.createIngredientRow();
    this.getRawIngredients();
    if (this.inventoryProductId) {
      this.getIngredientsByInventoryProductId();
    }
    this.isDebugModeOn = this.applicationStateService.isDebug;
    this.eventSubscriptions.push(this.eventBroadcastingService.reloadSettings.subscribe(
      () => {
        this.isDebugModeOn = this.applicationStateService.isDebug;
      }
    ));
  }

  ngOnDestroy(): void {
    if (this.eventSubscriptions) {
      _.forEach(this.eventSubscriptions, (subscription) => {
        subscription.unsubscribe();
      });
    }
  }

  ngOnChanges() {
    if (this.isSubmit) {
      this.resetAllIngredients();
      const currentIngredients: Array<InventoryProductRawIngredientModel> = cloneDeep(this.ingredients);
      // Return only that ingredients which have RawIngredient
      const ingredients: Array<InventoryProductRawIngredientModel> = _.filter(currentIngredients, (ing) => ing.RawIngredient) as Array<InventoryProductRawIngredientModel>;
      _.forEach(ingredients, (ing) => {
        ing.RawIngredient = ing.RawIngredient.trim();
      });
      const isValidParentIngredient = this.validateAllIngredients();
      this.saveIngredients.emit({
        ingredient: ingredients,
        deletedIngredients: this.removedIngredients,
        valid: this.ingredientsFormGroup.valid && isValidParentIngredient
      });
    }
  }

  validateAllIngredients = () => {
    let isValidParentIngredient = true;
    _.forEach(this.ingredients, (ing) => {
      if (isValidParentIngredient) {
        let ordinalString = cloneDeep(ing.OrdinalString);
        const ordinalStringLevelLength = ordinalString.split('.').length;
        for (let i = 0; i < ordinalStringLevelLength; i++) {
          const parentIngredient = this.getParentIngredientByOrdinalString(ordinalString);
          if (parentIngredient && ing.OrdinalString !== parentIngredient.OrdinalString
            && parentIngredient.RawIngredient === ing.RawIngredient) {
            isValidParentIngredient = false;
            const invalidIngredient = StringUtils.format(Messages.InvalidParentOrChildIngredient,
              { 'childIngredient': ing.RawIngredient, 'parentIngredient': parentIngredient.RawIngredient });
            this.alertService.renderErrorMessage(invalidIngredient);
            break;
          }
          ordinalString = ordinalString.substr(0, ordinalString.lastIndexOf('.'));
        }
      }
    });
    return isValidParentIngredient;
  }

  getRawIngredients = () => {
    this.spinnerService.show();
    this.rawIngredientsService.getRawIngredientsList()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          if (res) {
            _.forEach(res, (rawIngredient) => {
              this.availableRawIngredients.push(rawIngredient.Name);
            });
          }
        }, error: this.alertService.showApiError
      });
  }

  getIngredientsByInventoryProductId = () => {
    this.spinnerService.show();
    this.rawIngredientService.getIngredientsByInventoryProductId(this.inventoryProductId).subscribe(
      (response: any) => {
        this.getIngredientsByInventoryProductIdCompleted(response);
      },
      this.alertService.showApiError,
      () => {
        this.spinnerService.hide();
      }
    );
  }

  getIngredientsByInventoryProductIdCompleted = (ingredients) => {
    this.ingredients = ingredients ? ingredients : [];
    if (this.ingredients && this.ingredients.length) {
      _.forEach(this.ingredients, (ing) => {
        this.setParentIngredientById(ing);
        this.setIngredientOrdinal(ing);
        this.setIngredientLevel(ing);
        ing.OldOrdinalString = ing.OrdinalString;
      });
    }
    // Create new blank raw for new ingredient entry
    this.createIngredientRow();
  }

  // it's return last ordinal of child by given parentId
  getLastChildOrdinalByParentIngredientId = (parentIngredientId, ignoreOrdinalString: string = ''): number => {
    // ignoreOrdinalString is used to ignore same ingredient in list
    const ingredients = _.filter(this.ingredients, (ingredient) =>
      ingredient.ParentIngredientId === parentIngredientId && ingredient.OrdinalString &&
      (!ignoreOrdinalString || ingredient.OrdinalString !== ignoreOrdinalString)
    );
    if (ingredients && ingredients.length) {
      return ingredients[ingredients.length - 1].Ordinal;
    } else {
      return 0;
    }
  }

  // it's return last ordinal of child by given parentOrdinalString and parentName
  getLastChildOrdinalByParentIngredientOrdinalString = (ordinalString, parentIngredientName, ignoreOrdinalString: string = '') => {
    // ignoreOrdinalString is used to ignore same ingredient in list
    const ingredients = _.filter(this.ingredients, (ingredient) => {
      return ingredient.OrdinalString.includes(ordinalString + '.') &&
        (ingredient.ParentIngredient && ingredient.ParentIngredient.RawIngredient === parentIngredientName) &&
        (!ignoreOrdinalString || ingredient.OrdinalString !== ignoreOrdinalString);
    });
    if (ingredients && ingredients.length) {
      return ingredients[ingredients.length - 1].Ordinal;
    } else {
      return 0;
    }
  }

  // it's used to set parent ingredient data into child ingredient by parent ingredient id
  setParentIngredientById = (ingredient: InventoryProductRawIngredientModel) => {
    if (ingredient && ingredient.ParentIngredientId && !ingredient.ParentIngredient.RawIngredient) {
      const parentIngredient = _.find(this.ingredients, (ing) => {
        return ing.Id == ingredient.ParentIngredientId;
      });
      if (parentIngredient) {
        ingredient.ParentIngredient = parentIngredient;
      }
    }
  }

  // it's used to set ingredient's ordinal and ordinal based on parent
  setIngredientOrdinal = (ingredient: InventoryProductRawIngredientModel) => {
    if (ingredient && ingredient.ParentIngredient) {
      if (ingredient.ParentIngredient.OrdinalString) {
        const parentIngredient = this.getParentIngredientByOrdinalString(ingredient.ParentIngredient.OrdinalString);
        if (parentIngredient) {
          ingredient.ParentIngredient = parentIngredient;
        }
        const ordinal = ingredient.Id > 0 ? this.getLastChildOrdinalByParentIngredientId(ingredient.ParentIngredientId) :
          this.getLastChildOrdinalByParentIngredientOrdinalString(ingredient.ParentIngredient.OrdinalString,
            ingredient.ParentIngredient.RawIngredient);
        ingredient.Ordinal = ordinal + 1;
        ingredient.OrdinalString = `${ingredient.ParentIngredient.OrdinalString}.${this.getOrdinalStringWithZero(ordinal + 1)}`;
      } else {
        this.setIngredientOrdinal(ingredient.ParentIngredient);
      }
    } else {
      ingredient.OrdinalString = this.getOrdinalStringWithZero(ingredient.Ordinal);
    }
  }

  createIngredientRow = (isLastRow: boolean = true) => {
    if (!isLastRow) {
      return;
    }
    let rawIngredient: InventoryProductRawIngredientModel = this.rawIngredientService.newIngredient();
    rawIngredient.InventoryProductId = this.inventoryProductId ? this.inventoryProductId : 0;
    const ordinal = this.getLastChildOrdinalByParentIngredientId(null);
    rawIngredient.Ordinal = ordinal + 1;
    rawIngredient.OrdinalString = this.getOrdinalStringWithZero(rawIngredient.Ordinal);
    this.ingredients.push(rawIngredient);
  }

  // it's used to display suggestion for raw ingredient when user enter string in auto complete text box
  prepareRawIngredientsForSuggestions = (event) => {
    this.suggestedRawIngredients = [];
    this.suggestedRawIngredients = this.availableRawIngredients
      .filter(data => data.toString().trim().toLowerCase()
        .indexOf(event.query.toString().trim().toLowerCase()) !== -1);
  }

  // it's used to filter and prepare list for parent
  onParentIngredientFocus = (ingredientIndex, ingredient) => {
    // ingredientIndex is used to ignore current ingredient in a parent list
    const rawIngredients = cloneDeep(this.ingredients);
    this.parentIngredients = _.filter(rawIngredients, (ingredient, index) => {
      return (index != ingredientIndex &&
        index != this.ingredients.length - 1 && ingredient.RawIngredient != null);
    });
    this.onChangeParentIngredient(ingredient);
  }

  setOldOrdinalString = (ingredient: InventoryProductRawIngredientModel) => {
    _.forEach(this.ingredients, (ing) => {
      if (ingredient.OrdinalString === ing.OrdinalString) {
        ing.OldOrdinalString = ing.OrdinalString;
      }
    });
    ingredient.OldOrdinalString = ingredient.OrdinalString;
  }

  resetOldOrdinalStringInAllChild = (ordinalString: string, parentIngredientName: string, parentIngredientLevel: number) => {
    _.forEach(this.ingredients, (ingredient) => {
      if (ingredient.OrdinalString.includes(ordinalString + '.') &&
        (ingredient.ParentIngredient && ingredient.ParentIngredient.RawIngredient === parentIngredientName) &&
        ingredient.Level - 1 === parentIngredientLevel) {
        ingredient.ParentIngredient.OldOrdinalString = ordinalString;
      }
    });
  }

  removeOldOrdinalStringFromIngredients = () => {
    _.forEach(this.ingredients, (ing) => {
      if (ing.OldOrdinalString) {
        ing.OldOrdinalString = '';
      }
    });
  }

  // it's used to get parent and child ingredient of given ingredient
  getChildAndParentIngredientByCurentIngredient = (ingredient: InventoryProductRawIngredientModel, currentOrdinalString: string):
    Array<InventoryProductRawIngredientModel> => {
    const ingredientGroup = [];
    ingredientGroup.push(ingredient);

    // get parent ingredient
    let ordinalString = currentOrdinalString;
    const ordinalStringLevelLength = ordinalString.split('.').length;
    for (let i = 0; i < ordinalStringLevelLength; i++) {
      const parent = this.getParentIngredientByOrdinalString(ordinalString);
      if (parent) {
        ingredientGroup.push(parent);
      }
      ordinalString = ordinalString.substr(0, ordinalString.lastIndexOf('.'));
    }

    // get child ingredients
    _.forEach(this.ingredients, (ing) => {
      const childOrdinalString = ing.OrdinalString.substr(0, ingredient.OrdinalString.length);
      if (childOrdinalString.trim() == ingredient.OrdinalString && ing.OrdinalString.includes(ingredient.OrdinalString + '.')) {
        ingredientGroup.push(ing);
      }
    });
    return ingredientGroup;
  }

  //  it's used to validate child and parent ingredient when try to set parent ingredient in any ingredient
  validateChildAndParentIngredient = (ingredient: InventoryProductRawIngredientModel, newOrdinalString: string) => {
    // validate parent ingredient for ingredient
    let parentIngredient = '';
    let childIngredient = '';
    const isValidParent = this.validateParentIngredient(ingredient.OrdinalString, ingredient.RawIngredient, newOrdinalString);
    let isValidChild = true;
    if (isValidParent) {
      // validate child ingredient for ingredient
      const ingredientGroup = this.getChildAndParentIngredientByCurentIngredient(ingredient, newOrdinalString);
      _.forEach(ingredientGroup, (ing) => {
        if (ing.RawIngredient) {
          ing.RawIngredient = ing.RawIngredient.trim();
        }
      });
      const duplicateIngredients = _.groupBy(ingredientGroup, 'RawIngredient');
      _.forEach(duplicateIngredients, (duplicateIng) => {
        if (isValidChild && duplicateIng.length > 1) {
          childIngredient = duplicateIng[0].RawIngredient;
          isValidChild = false;
        }
      });
    }
    if (!isValidParent) {
      const invalidIngredient = StringUtils.format(Messages.InvalidParentOrChildIngredient,
        { 'childIngredient': ingredient.ParentIngredient.RawIngredient, 'parentIngredient': ingredient.RawIngredient });
      this.alertService.renderErrorMessage(invalidIngredient);
    } else if (!isValidChild) {
      const invalidIngredient = StringUtils.format(Messages.InvalidParentOrChildIngredient,
        { 'childIngredient': childIngredient, 'parentIngredient': ingredient.RawIngredient });
      this.alertService.renderErrorMessage(invalidIngredient);
    }
    return isValidParent && isValidChild;
  }

  // it's used to validate parent ingredient
  validateParentIngredient = (currentOrdinalString: string, rawIngredient: string, newOrdinalString): boolean => {
    let parentIngredient = '';
    let childIngredient = '';
    const ordinalString = newOrdinalString.substr(0, currentOrdinalString.length);
    let isValidParentIngredient = true;
    if (ordinalString == currentOrdinalString && newOrdinalString.includes(currentOrdinalString + '.')) {
      isValidParentIngredient = false;
    } else {
      let ordinalString = newOrdinalString;
      const ordinalStringLevelLength = ordinalString.split('.').length;
      for (let i = 0; i < ordinalStringLevelLength; i++) {
        const parentIngredient = this.getParentIngredientByOrdinalString(ordinalString);
        if (parentIngredient && parentIngredient.RawIngredient === rawIngredient) {
          isValidParentIngredient = false;
          break;
        }
        ordinalString = ordinalString.substr(0, ordinalString.lastIndexOf('.'));
      }
    }
    return isValidParentIngredient;
  }

  // it's called when change parent ingredient
  onChangeParentIngredient = (ingredient: InventoryProductRawIngredientModel) => {
    if (ingredient && ingredient.ParentIngredient) {
      const parentIngredient = ingredient.OrdinalString.substr(0, ingredient.OrdinalString.lastIndexOf('.'));
      if (parentIngredient === ingredient.ParentIngredient.OrdinalString || !ingredient.RawIngredient) {
        if (!ingredient.RawIngredient) {
          ingredient.ParentIngredient = null;
        }
        return;
      }
      let ordinal = 1;
      // get last ordinal of parent
      if (ingredient.ParentIngredient.Id) {
        ordinal = this.getLastChildOrdinalByParentIngredientId(ingredient.ParentIngredient.Id, ingredient.OrdinalString);
      } else {
        ordinal = this.getLastChildOrdinalByParentIngredientOrdinalString(ingredient.ParentIngredient.OrdinalString,
          ingredient.ParentIngredient.RawIngredient, ingredient.OrdinalString);
      }
      // prepare new ordinal string for current ingredient
      const ordinalString = `${ingredient.ParentIngredient.OrdinalString}.${this.getOrdinalStringWithZero(ordinal + 1)}`;
      // validate child and parent ingredients
      if (this.validateChildAndParentIngredient(ingredient, ordinalString)) {
        // if valid parent and child ingredients then change ordinal string of all child of current ingredient
        this.changeChildOrdinalStringAfterParentOrdinalChange(ingredient.OrdinalString, ordinalString);
        if (!ingredient.Id) {
          this.setOldOrdinalString(ingredient.ParentIngredient);
        }
        ingredient.OrdinalString = ordinalString;
        ingredient.Ordinal = ordinal + 1;
        ingredient.ParentIngredientId = ingredient.ParentIngredient.Id ? ingredient.ParentIngredient.Id : null;
        this.setIngredientLevel(ingredient);
      } else {
        // if parent or child ingredient is not valid then reset ingredient to previous state (reset parent if applicable)
        if (ingredient.OrdinalString.length > 4) {
          const parentOrdinalString = ingredient.OrdinalString.substr(0, ingredient.OrdinalString.lastIndexOf('.'));
          ingredient.ParentIngredient = this.getParentIngredientByOrdinalString(parentOrdinalString);
        } else {
          ingredient.ParentIngredient = null;
        }
      }
      // reorder all ingredients
      this.setOrdering();
      this.resetIngredientsOldOrdinalString();
    }
  }

  // it's used to change ordinal string of all childs and sub childs of given ingredient (ordinal string)
  changeChildOrdinalStringAfterParentOrdinalChange = (oldOrdinalString, newOrdinalString) => {
    _.forEach(this.ingredients, (ingredient) => {
      const ordinalString = ingredient.OrdinalString.substr(0, oldOrdinalString.length);
      if (ordinalString === oldOrdinalString && ingredient.OrdinalString.includes(oldOrdinalString + '.')) {
        ingredient.OrdinalString = ingredient.OrdinalString
          .replace(oldOrdinalString, newOrdinalString).trim();
        this.setIngredientLevel(ingredient);
      }
    });
  }

  // it's used to set level of ingredient based on ordinal string
  setIngredientLevel = (ingredient: InventoryProductRawIngredientModel) => {
    ingredient.Level = ingredient.OrdinalString.split('.').length ? ingredient.OrdinalString.split('.').length - 1 : 0;
  }

  // it's called when remove parent from ingredient (onclick of X)
  removeParentIngredient = (ingredient: InventoryProductRawIngredientModel, index: number) => {
    if (ingredient.RawIngredient) {
      let ordinal = 1;
      if (index > 0) {
        // set ordinal for ingredient where parent is removed
        const previousIngredient = this.ingredients[index - 1];
        const superParentBeforeThisIngredient = this.getSuperParentByOrdinalString(previousIngredient.OrdinalString);
        if (superParentBeforeThisIngredient) {
          ordinal = superParentBeforeThisIngredient.Ordinal + 1;
        }
      }
      // set ordinal string for ingredient
      const ordinalString = this.getOrdinalStringWithZero(ordinal);
      ingredient.ParentIngredient = null;
      ingredient.ParentIngredientId = null;
      // change ordinal string of all child of current ingredient
      this.changeChildOrdinalStringAfterParentOrdinalChange(ingredient.OrdinalString, ordinalString);
      ingredient.OrdinalString = ordinalString;
      ingredient.Ordinal = ordinal;
      this.setIngredientLevel(ingredient);
      // this.resetSuperParentOrdinals();
      // reset all ingredients after remove parent
      this.resetAllIngredients();
      this.setOrdering();
    }
  }

  removeParentIngredientOrdinalString = (ingredient: InventoryProductRawIngredientModel) => {
    if (ingredient.ParentIngredient && ingredient.ParentIngredient.OrdinalString) {
      if (!ingredient.ParentIngredient.Id) {
        ingredient.ParentIngredient.OldOrdinalString = ingredient.ParentIngredient.OrdinalString;
      }
      ingredient.ParentIngredient.OrdinalString = '';
      if (ingredient.ParentIngredient.ParentIngredient) {
        this.removeParentIngredientOrdinalString(ingredient.ParentIngredient);
      }
    }
  }

  // it's return ingredient which is the parent of given ingredient by ordinal string
  getParentIngredientByOrdinalString = (ordinalString: string): InventoryProductRawIngredientModel => {
    const parentIngredient = _.find(this.ingredients, (ing) => {
      return ing.OrdinalString === ordinalString;
    });
    if (parentIngredient && parentIngredient.ParentIngredient) {
      const superParent = this.getParentIngredientByOrdinalString(parentIngredient.ParentIngredient.OrdinalString);
      if (superParent) {
        parentIngredient.ParentIngredient = superParent;
      }
    }
    if (parentIngredient) {
      return parentIngredient;
    }
  }

  setParentIngredientOrdinalStringByOldOrdinalString = (ingredient: InventoryProductRawIngredientModel, index) => {
    if (ingredient.ParentIngredient) {
      const parentIngredient = _.find(this.ingredients, (ing) => {
        return ing.OldOrdinalString === ingredient.ParentIngredient.OldOrdinalString;
      });
      if (parentIngredient) {
        ingredient.ParentIngredient.OrdinalString = parentIngredient.OrdinalString;
      } else if (index) {
        const assumedParent = this.ingredients[index - 1];
        if (assumedParent.RawIngredient == ingredient.ParentIngredient.RawIngredient) {
          ingredient.ParentIngredient.OrdinalString = assumedParent.OrdinalString;
        }
      }
    }
  }

  // it's used to get maximum available from all ingredients
  getMaxLevelFromIngredients = (): number => {
    let maxLevel = 0;
    _.forEach(this.ingredients, (ingredient) => {
      const level = ingredient.OrdinalString.split('.').length ? ingredient.OrdinalString.split('.').length - 1 : 0;
      if (level > maxLevel) {
        maxLevel = level;
      }
    });
    return maxLevel;
  }

  // it's used to reset all ingredients
  resetAllIngredients = () => {
    // get maximum available from all ingredients
    const maxAvailableLevel = this.getMaxLevelFromIngredients();

    // reset ordinal for all super parent
    this.resetSuperParentOrdinals();

    // remove ordinal string from all ingredients
    _.forEach(this.ingredients, (ingredient) => {
      ingredient.OrdinalString = '';
      this.removeParentIngredientOrdinalString(ingredient);
    });

    // set ordinal, level, parent ingredient, ordinal string by ingredients level
    for (let level = 0; level <= maxAvailableLevel; level++) {
      _.forEach(this.ingredients, (ingredient, index) => {
        if (ingredient.Level == level) {
          // set parent ingredient ordinal string by old ordinal string
          if (ingredient.ParentIngredient) {
            this.setParentIngredientOrdinalStringByOldOrdinalString(ingredient, index);
          }

          // set ordinal and ordinal string for ingredient
          this.setIngredientOrdinal(ingredient);

          // set level for ingredient based on ordinal string
          this.setIngredientLevel(ingredient);
        }
      });
    }
    // reset ordering by ordinal string
    this.setOrdering();

    // reset old ordinal string for all parent ingredient
    this.removeOldOrdinalStringFromIngredients();
    this.resetIngredientsOldOrdinalString();
  }

  resetIngredientsOldOrdinalString = () => {
    _.forEach(this.ingredients, (ing) => {
      ing.OldOrdinalString = ing.OrdinalString;
      if (!ing.Id && ing.ParentIngredient && ing.ParentIngredient.OldOrdinalString !== ing.ParentIngredient.OrdinalString) {
        ing.ParentIngredient.OldOrdinalString = ing.ParentIngredient.OrdinalString;
      }
    });
  }

  resetSuperParentOrdinals = () => {
    let ordinal = 1;
    _.forEach(this.ingredients, (ingredient) => {
      if (ingredient.Level === 0) {
        ingredient.Ordinal = ordinal;
        ordinal++;
      }
    });
  }

  getSuperParentByOrdinalString = (ordinalString): InventoryProductRawIngredientModel => {
    const ordinals = ordinalString.split('.');
    if (ordinals && ordinals.length) {
      const ingredient = _.find(this.ingredients, (ing) => {
        return ing.OrdinalString === ordinals[0];
      });
      if (ingredient) {
        return ingredient;
      }
    }
  }

  setOrdering = () => {
    this.ingredients = _.orderBy(this.ingredients, ['OrdinalString']);
  }

  getOrdinalStringWithZero = (ordinal: number): string => ordinal.toString().padStart(3, '0');


  // it's called when try to delete ingredient but it remove all child and sub child ingredients
  deleteIngredient = (ingredient: InventoryProductRawIngredientModel, ingredientIndex, element: ElementRef) => {
    if (ingredient.RawIngredient) {
      const deleteIngredient: any = cloneDeep(ingredient);
      const ingredientsForDelete = [];
      deleteIngredient.ParentIngredient = deleteIngredient.ParentIngredient ? deleteIngredient.ParentIngredient.RawIngredient : null;
      ingredientsForDelete.push(deleteIngredient);
      _.forEach(this.ingredients, (ing) => {
        const childOrdinalString = ing.OrdinalString.substr(0, ingredient.OrdinalString.length);
        if (ing.RawIngredient && childOrdinalString.trim() == ingredient.OrdinalString &&
          ing.OrdinalString.includes(ingredient.OrdinalString + '.')) {
          let removedIngredient: any = cloneDeep(ing);
          removedIngredient.ParentIngredient = removedIngredient.ParentIngredient ? removedIngredient.ParentIngredient.RawIngredient : null;
          ingredientsForDelete.push(removedIngredient);
        }
      });
      if (ingredientsForDelete && ingredientsForDelete.length) {
        _.forEach(ingredientsForDelete, (ing, index) => {
          _.remove(this.ingredients, (deletedIng) => {
            return deletedIng.OrdinalString === ing.OrdinalString;
          });
          this.removedIngredients.push(ing);
        });
        this.resetIngredientsOldOrdinalString();
        this.setOrdering();
        this.resetAllIngredients();
      }
    } else {
      const tempIngredients = cloneDeep(this.ingredients);
      this.ingredients = [];
      tempIngredients.splice(ingredientIndex, 1);
      setTimeout(() => {
        this.ingredients = tempIngredients;
      });
    }
    if (!ingredient.RawIngredient) {
      this.loadTable = false;
      setTimeout(() => {
        this.loadTable = true;
        this.ingredientsFormGroup.control.markAsPristine();
        this.ingredientsFormGroup.control.markAsUntouched();
      });
    }
  }

  // it's used to change ordering of ingredients
  gridRowReorder = (event) => {
    const dragElementIndex = event.dragIndex;
    const dropElementIndex = event.dropIndex;
    let dropIndex;
    if (dropElementIndex != null) {
      if (dragElementIndex > dropElementIndex) {
        dropIndex = dropElementIndex;
      } else {
        dropIndex = dropElementIndex === 0 ? 0 : dropElementIndex - 1;
      }
    }
    let allowToMove = false;
    const currentIngredient = this.ingredients[dropIndex] ? this.ingredients[dropIndex] : null;
    if (currentIngredient && currentIngredient.RawIngredient) {
      const parentIngredientOrdinalOfCurrentIngredient =
        currentIngredient.OrdinalString.substr(0, currentIngredient.OrdinalString.lastIndexOf('.'));
      const parentIngredientLevelOfCurrentIngredient = parentIngredientOrdinalOfCurrentIngredient.split('.').length - 1;

      if (dropIndex > 0 && currentIngredient.Level) {
        const firstIngredientAfterCurrent = this.ingredients[dropIndex + 1];
        const parentIngredientOfFirstIngredientAfterCurrent =
          firstIngredientAfterCurrent.OrdinalString.substr(0, firstIngredientAfterCurrent.OrdinalString.lastIndexOf('.'));

        const firstIngredientBeforeCurrent = this.ingredients[dropIndex - 1];
        const parentIngredientOffirstIngredientBeforeCurrent =
          firstIngredientBeforeCurrent.OrdinalString.substr(0, firstIngredientBeforeCurrent.OrdinalString.lastIndexOf('.'));

        if ((parentIngredientOrdinalOfCurrentIngredient === parentIngredientOfFirstIngredientAfterCurrent &&
          currentIngredient.Level === firstIngredientAfterCurrent.Level) ||
          (parentIngredientOrdinalOfCurrentIngredient === parentIngredientOffirstIngredientBeforeCurrent &&
            currentIngredient.Level === firstIngredientBeforeCurrent.Level)) {
          allowToMove = true;
        }

        if (!allowToMove) {
          let lastSubChildOfParent = 0;
          _.forEach(this.ingredients, (ing, index) => {
            const ingredientOrdinalString = ing.OrdinalString.substr(0, parentIngredientOrdinalOfCurrentIngredient.length);
            if (currentIngredient.OrdinalString !== ing.OrdinalString &&
              ingredientOrdinalString === parentIngredientOrdinalOfCurrentIngredient &&
              ing.OrdinalString.includes(ingredientOrdinalString)) {
              lastSubChildOfParent = index;
            }
          });
          if (dropIndex === lastSubChildOfParent + 1) {
            allowToMove = true;
          }
        }

        if (allowToMove) {
          let ordinal = 1;
          _.forEach(this.ingredients, (ing) => {
            const ordinalString = ing.OrdinalString.substr(0, ing.OrdinalString.lastIndexOf('.'));
            if (ordinalString == parentIngredientOrdinalOfCurrentIngredient && currentIngredient.Level === ing.Level) {
              ing.Ordinal = ordinal;
              ordinal++;
            }
          });
          this.resetIngredientsOldOrdinalString();
          this.resetAllIngredients();
        } else {
          this.alertService.renderErrorMessage(Messages.InvalidIngredientMove);
          this.setOrdering();
        }
      } else if (!parentIngredientLevelOfCurrentIngredient) {
        this.resetIngredientsOldOrdinalString();
        this.resetAllIngredients();
      } else {
        this.alertService.renderErrorMessage(Messages.InvalidIngredientMove);
        this.setOrdering();
      }
    } else {
      this.setOrdering();
    }
    this.loadTable = false;
    const loadTableTimeout = setTimeout(() => {
      this.loadTable = true;
      clearTimeout(loadTableTimeout);
    });
  }

  // import inventory raw ingredient
  importRawIngredient = () => {
    const modal = this.modalService.getModalWrapper(ImportRawIngredientsComponent);
    const modalRef = modal.show({
      animated: false,
      class: 'vertical-center modal-max-width-40',
      initialState: {}
    });
    modalRef.close.subscribe((res) => {
      if (res && res.shouldImport && res.ingredients) {
        this.addIngredients(res.ingredients);
      }
    });
  }

  addIngredients = (importedIngredients) => {
    const parentIngredients = [];
    forEach(importedIngredients, (ingredient, i) => {
      const rowData = this.ingredients.length ? this.ingredients[this.ingredients.length - 1] : this.ingredients[0];
      rowData.RawIngredient = ingredient.Name;
      rowData.LessThan2Percent = ingredient.Is2pct;
      this.createIngredientRow();
      const actualParent = findLast(parentIngredients, (x) => x.RawIngredient === ingredient.Parent);
      rowData.ParentIngredient = actualParent && actualParent.RawIngredient === ingredient.Parent ? actualParent : null;
      this.onChangeParentIngredient(rowData);
      if (ingredient.IsParent) {
        parentIngredients.push(rowData);
      }
    });
  }

}
