<div class="col-12 item-container" [ngStyle]="{'height': containerHeight + 'px'}">
    <div class="">
        <div *ngFor="let row of rows" class="col-12 p-0 display-flex flex-grow-1">
            <div *ngFor="let column of columns" class="m-pix-5"
                [ngStyle]="{'width': cardWidth + 'px', 'height': cardHeight + 'px'}">
                <ng-container *ngIf="{productQueue:getProductQueueData(column, row)}; let state">
                    <div class="menu-option-button m-pix-10" *ngIf="terminalDesignMode">
                        <div class="menu-option" id="button_{{column}}{{row}}"
                        [ngStyle]="{'margin-left': (cardWidth - 45) + 'px'}"
                        #pop="bs-popover" placement="left" appendTo='body'
                        (click)="onConfigClick(state?.productQueue, pop)" [outsideClick]="true" [popover]="operationPopoverTemplate"
                        triggers="click"
                        >
                            <pos-icon [icon]="icons.cog"></pos-icon>
                        </div>
                    </div>
                    <div class="product-card p-0 bold"
                        *ngIf="(state?.productQueue?.ColumnId -1 == column && state?.productQueue?.RowId -1 == row)"
                        [ngStyle]="{'backgroundColor': state.productQueue.BackgroundColor, 
            'color': state.productQueue.ForegroundColor,
            'width': cardWidth + 'px', 
            'height': cardHeight + 'px'}">
                        <div class="count">
                          <div class="item-count" [ngStyle]="{'font-size': ((state.productQueue.ItemCount % 1) < 1 && (state.productQueue.ItemCount % 1) != 0 ? (counterFontSize - (counterFontSize / 2.8)) : counterFontSize) + 'px'}">
                            {{state.productQueue.ItemCount}}</div>
                            <div class="unit" [ngStyle]="{'padding-top': unitTextPadding + 'px', 'font-size': (counterFontSize - (counterFontSize / 1.1)) + 'px'}">{{ state.productQueue.ItemCount ? Pluralize(state.productQueue.Unit, state.productQueue.ItemCount) : state.productQueue.Unit}}</div>
                        </div>
                        <div class="card-label-div ps-pix-10 pe-pix-10 pt-pix-7" [ngStyle]="{'font-size': labelFontSize + 'px'}">
                          <span class="card-label">{{state.productQueue.Label ? state.productQueue.Label : state.productQueue.InventoryProduct}}</span>
                        </div> 
                    </div>
                </ng-container>
            </div>
        </div>
    </div>    
</div>

<ng-template #operationPopoverTemplate>
    <div class="col-150">
      <ul>
        <li class="listbullet pointer">
          <a class="grey" (click)="editConfig(currentConfigQueue)">
            <pos-icon [icon]="icons.editSolid" class="pe-pix-3 m-l-5">
            </pos-icon>
            Configure Product
          </a>
        </li>
        <hr/>
        <li class="listbullet pointer">
          <a class="grey" (click)="removeConfiguration(currentConfigQueue)">
            <pos-icon [icon]="icons.trashScreenSetup" class="pe-pix-3 m-l-5">
            </pos-icon>
            Remove Product
          </a>
        </li>
      </ul>
    </div>
  </ng-template>
