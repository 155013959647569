<pos-edit-wrapper (close)="cancelEditing()" heading="{{editInventoryProducts.Id ? 'Edit Recipe Product' : 'Add Recipe Product'}}">
  <form #prepRecipeForm="ngForm" (submit)="submit(prepRecipeForm.valid)" autocomplete="off">
    <context-provider provide="props">
    <div class="col-md-12 px-4 form-horizontal screen-scroll mb-2">
        <pos-row>
            <pos-column>
                <pos-form-field label="Product" [validationMessages]="{required: 'Please select product.'}">
                    <ng-select posInput [required]="true" [items]="inventoryProducts" bindLabel="Name" bindValue="Id"
                        placeholder="Select product" [(ngModel)]="inventoryProductRecipe.InventoryProductId" appendTo="body"
                        name="selectedUserRole" (ngModelChange)="productChangeHandler($event)">
                    </ng-select>
                </pos-form-field>
            </pos-column>
            <pos-column>
                <pos-form-field label="Qty" [validationMessages]="{required: 'Please enter qty.', pattern: 'Only 4 decimal places allow.'}"
                    inputStyle="col-12 row mx-0">
                    <input posInput class="form-control col-4 col-120" name="textUnitQty" [(ngModel)]="inventoryProductRecipe.Qty"
                        pattern="[0-9]+(.[0-9]{0,4})?$" #Qty="ngModel" type="number" (keypress)="preventMinusSign($event)"
                        [required]="true" />
                    <span *ngIf="inventoryProductRecipe.UnitName" class="col-6 pt-pix-7">{{inventoryProductRecipe.UnitName}}(s)</span>
                </pos-form-field>
            </pos-column>
            <pos-column class="mb-1">
                <pos-form-field label="Instructions">
                    <textarea class="form-control" cols="50" id="txtInstructions" name="txtInstructions"
                        [(ngModel)]="inventoryProductRecipe.Instructions" rows="5"></textarea>
                </pos-form-field>
            </pos-column>
        </pos-row>
    </div>
      <div class="clearfix"></div>
      <div class="col-12 action-button-container popup-footer">
          <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" value="Save">Save </button>
          <button type="button" data-dismiss="modal" class="btn-secondary btn-lg btn btn-cancel-modal pos-button"
              (click)="cancelEditing()" value="Cancel">Cancel</button>
      </div>
    </context-provider>
  </form>
</pos-edit-wrapper>
