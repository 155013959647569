import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { find, forEach } from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { InventoryProductRecipe, InventoryProductSummary } from 'src/app/information-management/inventory-products/interface';
import { InventoryProductRecipeService } from 'src/app/shared/services/inventory-product-recipe.service';
import {
  AlertsService, ApplicationStateService, InfoModalComponent, InvProduct, InventoryProductService, Messages,
  ModalService,
  SettingParam,
  SpinnerService
} from 'src/app/shared';
import { exclamationTriangle } from 'src/app/shared/components/icon/icons';
import { RecordInventoryPreparation } from '../../interfaces/record-inventory-preparation';
import Pluralize from 'pluralize';
declare let $: any;
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { Router } from '@angular/router';

@Component({
  selector: 'pos-fixed-ingredient-recipe',
  templateUrl: './fixed-ingredient-recipe.component.html',
  styleUrls: ['./fixed-ingredient-recipe.component.scss']
})
export class FixedIngredientRecipeComponent implements OnInit {

  height: string;
  ingredientsHeight: string;
  recipeHeight: string;
  inventoryPrepRecordingProducts: Array<InventoryProductSummary> = [];
  inventoryPrepProduct: RecordInventoryPreparation;
  unitName = '';
  recipeList: Array<InventoryProductRecipe> = [];
  recipeInformation: string;
  isRecipeLoaded = false;
  icons = {
    exclamationTriangle
  };
  isAvailableQty = true;
  settingParam: SettingParam;
  prepQuantity = 0;
  showCancelButton = false;
  @ViewChild('invetoryPrepForm') invetoryPrepForm: NgForm;

  constructor(private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private applicationStateService: ApplicationStateService,
    private inventoryProductService: InventoryProductService,
    private modalService: ModalService,
    private inventoryProductRecipeService: InventoryProductRecipeService,
    private router: Router) {
    this.inventoryPrepProduct = inventoryProductRecipeService.newRecordInventoryPreparation();
  }

  ngOnInit(): void {
    this.height = $(window).height() - 300 + 'px';
    this.ingredientsHeight = ($(window).height() >= 969 ? $(window).height() - 650 : 235) + 'px';
    this.recipeHeight = ($(window).height() >= 969 ? $(window).height() - 730 : 130) + 'px';
    this.settingParam = this.applicationStateService.settingParam;
    this.loadData();
  }

  loadData() {
    this.spinnerService.show();
    this.inventoryProductService.getInventoryPrepRecordingProducts()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          this.inventoryPrepRecordingProducts = response;
        }, error: this.alertsService.showApiError
      });
  }

  inventoryProductChanged() {
    this.recipeInformation = '';
    this.recipeList = [];
    this.unitName = '';
    this.isRecipeLoaded = false;
    if (this.inventoryPrepProduct.InventoryProductId) {
      const selectedProduct = find(this.inventoryPrepRecordingProducts, x => x.Id === this.inventoryPrepProduct.InventoryProductId);
      if (selectedProduct) {
        this.unitName = this.inventoryPrepProduct.Qty ? Pluralize(selectedProduct.UnitName, this.inventoryPrepProduct.Qty) :
          selectedProduct.UnitName;
        this.inventoryPrepProduct.Name = selectedProduct.Name;
        this.inventoryPrepProduct.IsPrintPrepLabel = selectedProduct.IsPrintPrepLabel;
      }
    }
    this.setShowCancelButton();
  }

  qtyChanged() {
    const selectedProduct = find(this.inventoryPrepRecordingProducts, x => x.Id === this.inventoryPrepProduct.InventoryProductId);
    this.unitName = this.inventoryPrepProduct.Qty ? Pluralize(selectedProduct.UnitName, this.inventoryPrepProduct.Qty) :
      selectedProduct.UnitName;
  }

  getInventoryProductRecipe(isValid: boolean) {
    if (!isValid || !this.inventoryPrepProduct.InventoryProductId) {
      return;
    }
    this.isRecipeLoaded = true;
    this.isAvailableQty = true;
    this.prepQuantity = this.inventoryPrepProduct.Qty;
    if (this.inventoryPrepProduct.InventoryProductId) {
      this.spinnerService.show();
      const observable: Array<Observable<any>> = [];
      observable.push(this.inventoryProductService.getInventoryProduct(this.inventoryPrepProduct.InventoryProductId));
      observable.push(this.inventoryProductRecipeService.getInventoryProductRecipe(this.inventoryPrepProduct.InventoryProductId));
      forkJoin(observable)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: ([inventoryProduct, recipes]: [InvProduct, Array<InventoryProductRecipe>]) => {
            this.recipeInformation = inventoryProduct.Recipe;
            this.recipeList = recipes;
            forEach(this.recipeList, (recipe) => {
              if (!recipe.PrepareQty) {
                recipe.PrepareQty = 1;
              }
              if ((this.inventoryPrepProduct.Qty * (recipe.Qty / recipe.PrepareQty)) > recipe.CalculatedQty) {
                recipe.IsAvailableQty = false;
                this.isAvailableQty = false;
              } else {
                recipe.IsAvailableQty = true;
              }
            });
          },
          error: this.alertsService.showApiError
        });
    }
  }

  updatePrepQty() {
    if (this.isRecipeLoaded) {
      if (!this.isAvailableQty) {
        const modalRef = this.modalService.getModalWrapper(InfoModalComponent);
        const modal = modalRef.show({
          animated: false,
          class: 'vertical-center',
          initialState: {
            message: StringUtils.format(Messages.ConfirmSaveRecipeIngredients, { 'Name': this.inventoryPrepProduct.Name }),
            confirmButtonText: 'Yes',
            rejectButtonText: 'No',
            modalHeaderText: 'Confirm'
          }
        });
        modal.close.subscribe((response) => {
          if (response && response.shouldConfirm) {
            this.confirmPrepLabelPrint();
          }
        });
      } else {
        this.confirmPrepLabelPrint();
      }
    }
  }

  confirmPrepLabelPrint() {
    if (this.inventoryPrepProduct && this.inventoryPrepProduct.IsPrintPrepLabel && this.inventoryPrepProduct.Qty > 0) {
      if (!this.settingParam.MaxPrepLabels) {
        this.settingParam.MaxPrepLabels = 15;
      }
      const prepLabelPrintCount = this.inventoryPrepProduct.Qty > this.settingParam.MaxPrepLabels ? this.settingParam.MaxPrepLabels
        : this.inventoryPrepProduct.Qty;
      const modalRef = this.modalService.getModalWrapper(InfoModalComponent);
      const modal = modalRef.show({
        animated: false,
        class: 'vertical-center',
        initialState: {
          message: StringUtils.format(Messages.ConfirmPrepLabelPrint, { 'printCount': prepLabelPrintCount }),
          confirmButtonText: 'Yes',
          rejectButtonText: 'No',
          modalHeaderText: 'Confirm'
        }
      });
      modal.close.subscribe((response) => {
        if (response) {
          this.saveProductRecipe(response.shouldConfirm);
        }
      });
    } else {
      this.saveProductRecipe(false);
    }
  }

  saveProductRecipe(isPrintPrepLabels: boolean) {
    if (isPrintPrepLabels) {
      this.inventoryPrepProduct.IsPrintPrepLabels = isPrintPrepLabels;
    }
    this.spinnerService.show();
    this.inventoryPrepProduct.TerminalId = this.applicationStateService.terminalId;
    this.inventoryProductService.recordInventoryPreparation(this.inventoryPrepProduct)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.alertsService.renderSuccessMessage(Messages.PrepRecordAdded);
          this.resetRecipe();
        }, error: this.alertsService.showApiError
      });
  }

  printInventoryPrep() {
    if (this.inventoryPrepProduct.InventoryProductId && this.inventoryPrepProduct.Qty) {
      this.spinnerService.show();
      this.inventoryPrepProduct.TerminalId = this.applicationStateService.terminalId;
      this.inventoryPrepProduct.TerminalName = this.applicationStateService.terminalName;
      this.inventoryProductService.printInventoryPrep(this.inventoryPrepProduct.InventoryProductId, this.inventoryPrepProduct)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res) => {
            this.alertsService.renderSuccessMessage(Messages.PrintRequestSendSuccess);
          }, error: this.alertsService.showApiError
        });
    } else {
      const modalRef = this.modalService.getModalWrapper(InfoModalComponent);
      const modal = modalRef.show({
        animated: false,
        class: 'vertical-center',
        initialState: {
          message: Messages.RequiredProductAndQtyForFixedPrepRecipe
        }
      });
    }
  }

  setShowCancelButton() {
    this.showCancelButton = (this.inventoryPrepProduct.InventoryProductId || this.inventoryPrepProduct.Qty) ? true : false;
  }

  close() {
    this.router.navigate(['manage/console']);
  }

  resetRecipe() {
    this.inventoryPrepProduct.InventoryProductId = null;
    this.inventoryPrepProduct.Qty = null;
    this.unitName = '';
    this.recipeInformation = '';
    this.recipeList = [];
    this.isRecipeLoaded = false;
    this.showCancelButton = false;
    this.invetoryPrepForm.resetForm();
  }

}
