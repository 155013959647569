import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridColumn, TableComponent, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { finalize } from 'rxjs/operators';
import { AlertsService, ApplicationStateService, AuthenticationService, DomainConstants, ExportService, Levels, Messages, PrintTableService, StringUtils } from 'src/app/shared';
import { BaseListComponent, ModalService, SpinnerService } from 'src/app/shared/components';
import {
  ban, check, checkCircleWhite, editWhite, plusWhite
} from 'src/app/shared/components/icon';
import { Promotion } from '../../../../shared/interface/rules/promotion';
import { PromotionService } from 'src/app/shared/services';


@Component({
  selector: 'pos-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.scss']
})
export class PromotionsComponent extends BaseListComponent<Promotion> implements OnInit {

  promotionType = DomainConstants.PromotionTypes.GiftCards;
  promotionColumns: Array<GridColumn> = [];
  printPromotionColumns: Array<GridColumn> = [];
  filteredPromotions: Array<Promotion> = [];
  promotions: Array<Promotion> = [];
  showInactivePromotions = false;
  permission = {
    name: null,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };
  icons = {
    plusWhite,
    editWhite,
    check,
    ban,
    checkCircleWhite
  };
  @ViewChild('activeTemplate', { static: true }) activeTemplate: TemplateRef<any>;
  @ViewChild('operationTemplate', { static: true }) operationTemplate: TemplateRef<any>;
  @ViewChild('headerTemplate', { static: true }) headerTemplate: TemplateRef<any>;
  @ViewChild('promotionGrid', { static: true }) promotionGrid: TableComponent;

  constructor(protected promotionService: PromotionService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected applicationStateService: ApplicationStateService,
    protected printService: PrintTableService,
    protected exportService: ExportService,
    protected authenticationService: AuthenticationService,
    private router: Router,
    protected route: ActivatedRoute) {
    super(promotionService, alertService, spinnerService, modalService, applicationStateService, printService, route);
    this.promotionType = this.promotionService.getPromotionTypeByRoute(route.snapshot?.url[0]?.path);
    this.permission.name = this.promotionService.getPromotionTypeByRoute(route.snapshot?.url[0]?.path).Permission;
  }
  ngOnInit(): void {
    this.promotionColumns = this.configureGridColumns();
    this.loadSubscriptions();
    this.promotionGrid.context = this.gridContext;
  }

  private loadSubscriptions(): void {
    this.data$.subscribe({
      next: (res) => {
        this.promotions = res.filter(x => x.Type === this.promotionType.DisplayName);
        this.filteredPromotions = this.promotions.filter(x => x.IsActive);
      }
    });
  }

  configureGridColumns(): GridColumn[] {
    const activeColumn = new TemplateColumn({
      HeaderText: 'Active',
      Field: 'IsActive',
      itemTemplate: this.activeTemplate,
      TextAlign: TextAlign.Center,
      Width: '10%',
      IsSortable: false
    });
    const operationColumn = new TemplateColumn({
      itemTemplate: this.operationTemplate,
      Width: '150px',
      headerTemplate: this.headerTemplate,
      TextAlign: TextAlign.Center,
      CellClass: 'cell-padding'
    });
    const promotionColumns = [
      new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: false, Width: '80%' }),
      activeColumn
    ];
    this.printPromotionColumns = [...promotionColumns];
    if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      promotionColumns.push(operationColumn);
    }
    return promotionColumns;
  }

  getGridColumns(): GridColumn[] {
    return this.promotionColumns;
  }

  editItem(id: number, data?: Promotion): void {
    this.router.navigate([id], { relativeTo: this.route });
  }
  getConfirmDeleteMessage(data: Promotion): string {
    return;
  }
  protected printData() {
    this.printService.printEmitter.next({ gridColumns: this.printPromotionColumns, gridData: this.filteredPromotions });
  }

  exportCSV() {
    this.exportService.exportCSV('promotions', this.filteredPromotions, null, this.printPromotionColumns);
  }

  updatePromotionActiveStatus(data: Promotion, isActive: boolean) {
    data.IsActive = isActive;
    this.spinnerService.show();
    this.promotionService.update(data)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          this.changePromotionList();
          this.alertService.renderSuccessMessage(StringUtils.format(Messages.PromotionActiveStatusChange, { activeMessage: isActive ? 'activated' : 'deactivated' }));
        }, error: this.alertService.showApiError
      });
  }

  changePromotionList() {
    if (this.showInactivePromotions) {
      this.filteredPromotions = this.promotions;
    } else {
      this.filteredPromotions = this.promotions.filter(x => x.IsActive);
    }
  }

  promotionGridRowReorder(event, promotions) {
    this.promotionService.updatePromotionOrdinal(promotions)
      .pipe(finalize(() => {
      }))
      .subscribe({
        next: res => {
          this.filteredPromotions = res ?? promotions;
        }, error: this.alertService.showApiError
      });
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

}
