import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseListComponent } from 'src/app/shared/components/base-list.component';
import { CashPayOut } from '../../interface/cash-payout';
import { Permissions, Levels, Messages, DomainConstants } from '../../../../shared/constants/';
import { GridColumn, TableComponent } from '@tarktech/tark-ng-utils';
import { AuthenticationService } from 'src/app/shared/auth/services/authentication.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { CashPayoutService } from '../../service/cash-payout.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import * as _ from 'lodash';
import { ApplicationStateService } from 'src/app/shared';
import { ActivatedRoute, Router } from '@angular/router';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { cashPayoutsSearchDollar } from 'src/app/shared/components/icon';
import { CurrencyColumn } from '@tarktech/tark-ng-utils/table/currency-column';

@Component({
    selector: 'pos-cash-payout-list',
    templateUrl: './cash-payout-list.component.html',
})
export class CashPayoutListComponent extends BaseListComponent<CashPayOut> implements OnInit {

    permission = {
        name: Permissions.OperationsCashPayouts,
        readOnlyLevel: Levels.ReadOnly,
        editLevel: Levels.Edit
    };
    icons = { cashPayoutsSearchDollar };
    cashPayoutColumns: Array<GridColumn> = [];
    cashPayouts: CashPayOut[] = [];
    @ViewChild('cashPayoutGrid', { static: true }) cashPayoutGrid: TableComponent;
    constructor(
        private authenticationService: AuthenticationService,
        protected alertService: AlertsService,
        protected spinnerService: SpinnerService,
        protected infractionService: CashPayoutService,
        protected modalService: ModalService,
        protected route: ActivatedRoute,
        private router: Router,
        protected printService: PrintTableService,
        protected applicationStateService: ApplicationStateService
    ) {
        super(infractionService, alertService, spinnerService, modalService, applicationStateService, printService, route);
    }

    ngOnInit() {
        this.deleteSuccessMessage = Messages.CashPayoutDeleted;
        this.cashPayoutColumns = this.configureGridColumns();
        this.checkPermission();
        this.loadSubscriptions();
        this.scrollToTop();
        this.cashPayoutGrid.context = this.gridContext;
    }

    public getGridColumns(): Array<GridColumn> {
        return [
            new GridColumn({ HeaderText: 'Drawer', Field: 'CashDrawerName', IsSortable: true, Width: '25%' }),
            new GridColumn({ HeaderText: 'User', Field: 'Name', IsSortable: true, Width: '20%' }),
            new CurrencyColumn({ HeaderText: 'Amount', Field: 'Amount', IsSortable: true, Width: '20%' }),
            new GridColumn({ HeaderText: 'Memo', Field: 'Memo', IsSortable: true, Width: '30%' }),
        ] as Array<GridColumn>;
    }
    private checkPermission(): void {
        if (!this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
            _.remove(this.cashPayoutColumns, (column) => {
                return (column.Type === DomainConstants.GridColumnsTypes.EditColumn
                    || column.Type === DomainConstants.GridColumnsTypes.DeleteColumn);
            });
        }
    }
    private loadSubscriptions(): void {
        this.data$.subscribe({
            next: (res) => {
                this.cashPayouts = res;
            }
        });
    }
    public editItem(id: number, data?: CashPayOut): void {
        this.router.navigate([id], { relativeTo: this.route });
    }
    public getConfirmDeleteMessage(data: CashPayOut): string {
        return StringUtils.format(Messages.ConfirmDeleteCashPayout,
            { 'cashPayout': data ? data.CashDrawerName : '' }
        );
    }
    private scrollToTop(): void {
        window.scrollTo(0, 0);
    }

    close() {
        this.router.navigate(['../'], { relativeTo: this.route });
    }
}
