<pos-edit-wrapper heading="Set Next Screen" (close)="cancel()">
    <form #screenButtonForm="ngForm" (ngSubmit)="save(screenButtonForm.valid)" autocomplete="off">
        <div class="col-12 pos-modal-body">
            <pos-row>
                <pos-column>
                    <pos-form-field label="Next Screen" inputStyle="col-12">
                        <div class="row row-cols-sm-2 row-cols-1 mx-0">
                            <div class="ps-0">
                                <ng-select posInput [items]="kioskScreens" placeholder="Select next screen"
                                    appendTo="body" [(ngModel)]="kioskScreenButton.NextScreenId" name="nextScreen"
                                    #nextScreen="ngModel" bindLabel="Name" bindValue="Id">
                                </ng-select>
                            </div>
                            <div class="pt-pix-6 ps-0">
                                <span (click)="openScreenModal(0)">
                                    <pos-action-icon [icon]="icons.plusWhite" iconText="Add">
                                    </pos-action-icon>
                                </span>
                                <span (click)="openScreenModal(kioskScreenButton.NextScreenId)">
                                    <pos-action-icon class="text-nowrap ms-pix-5" [icon]="icons.editWhiteWithText"
                                        iconText="Edit">
                                    </pos-action-icon>
                                </span>
                            </div>
                        </div>
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save</button>
            <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button"
                (click)="cancel()">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
