import { GridColumn } from './grid-column';
import { GridColumnTypes } from './grid-column-types';



export class BooleanColumn extends GridColumn {

    get Type(): string {
        return GridColumnTypes.BooleanColumn;
    }

     constructor(init?: Partial<BooleanColumn>) {
        super();
        this.CellClass = "cell-boolean";

        Object.assign(this, init);
    }
}
