import { Injectable, Inject } from '@angular/core';
import { JL } from 'jsnlog';
import { RuntimeConstants } from '../constants';
import { ApplicationStateService } from './application-state.service';

@Injectable()
export class LoggerService {
  JsnLog: JL.JSNLog;
  constructor(@Inject('JSNLOG') logger: JL.JSNLog, private applicationStateService: ApplicationStateService) {
    const hostname = window.location.hostname + ':' + window.location.port;
    if (hostname != 'localhost') {
      JL.setOptions({
        'defaultAjaxUrl': RuntimeConstants.API_PATH + '../../jsnlog.logger'
    });
    }
    this.JsnLog = logger;
  }

  logError(errorMessage) {
    this.JsnLog().error(this.appendDetails(errorMessage));
  }

  logWarning(warningMessage) {
    this.JsnLog().warn(this.appendDetails(warningMessage));
  }

  logInformation(infoMessage) {
    this.JsnLog().info(this.appendDetails(infoMessage));
  }

  logDebug(debugMessage) {
    this.JsnLog().debug(this.appendDetails(debugMessage));
  }

  private appendDetails = (message): string => {
    return `TerminalId: ${this.applicationStateService.terminalId}, UserId: ${this.applicationStateService.userId}, Message: ${message}`;
  }
}
