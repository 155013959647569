<div class="m-0-auto width-98">
    <div class="page-content">
        <div class="tab-content">
            <div class="tab-pane fade in active show">
                <div class="col-12 p-0 relative">
                    <a class="absolute close pe-pix-20" (click)="cancel()">×</a>
                    <pos-row xs="12" class="p-0 ps-pix-20">
                        <pos-column lg="2" md="4" sm="5" xs="12" class="p-0">
                            <h3 class="page-content__header">
                                <img class="me-pix-10 mt-pix-n4" alt="" src='images/admin/manage-loginventory.png' height='22px' />
                                Inventory Log
                            </h3>
                        </pos-column>
                        <pos-column lg="9" md="8" sm="12" xs="12" class="row p-0 pt-pix-5 mb-pix-5 ps-pix-15 ps-pix-md-0">
                            <div class="col-4 col-75 pe-0"><label class="edit-form__section__label">Location</label>
                            </div>
                            <div class="col-xl-4 col-sm-6 col-8 mb-pix-10 mb-pix-xl-0">
                                <pos-form-field inputStyle="col-12" [appendPaddingTop]="false">
                                    <ng-select posInput [items]="inventoryLocations" bindLabel="Name" bindValue="Id"
                                        placeholder="Select Location" [(ngModel)]="selectedLocation" appendTo="body"
                                        name="selectedLocation" [clearable]="false" [clearOnBackspace]="false"
                                        (change)="showInventories(idForDropDown, typeForDropDown)">
                                    </ng-select>
                                </pos-form-field>
                            </div>
                            <div class="col-xl-6 col-lg-8 col-sm-10 col-12 pe-0 ps-pix-10 ps-pix-xl-0">
                                <label class="edit-form__section__label alert alert-warning py-pix-6 mb-0"
                                    *ngIf="selectedLocation != 0">
                                    You are logging quantities for <b> {{selectedLocationName}} </b> location
                                </label>
                            </div>
                        </pos-column>
                    </pos-row>
                    <div class="inventory-log-form">
                        <div #filterSection class="col-12 filter-section border-top pt-pix-5 px-4">
                            <pos-row colMd="3" colSm="6" colStyleClass="ps-0 pe-pix-0 pe-pix-sm-15" class="g-0">
                                <pos-column>
                                    <pos-form-field label="Vendor" labelStyle="col-12" inputStyle="col-12">
                                        <ng-select posInput [items]="vendors" bindLabel="Name" bindValue="Id"
                                            name="vendor" (change)="showInventories(selectedVendor,'Vendor')"
                                            [(ngModel)]="selectedVendor" placeholder="Select vendor">
                                        </ng-select>
                                    </pos-form-field>
                                </pos-column>
                                <pos-column>
                                    <pos-form-field label="Category" labelStyle="col-12" inputStyle="col-12">
                                        <ng-select posInput [items]="inventoryCategories" bindLabel="Name"
                                            bindValue="Id" placeholder="Select category" [(ngModel)]="selectedCategory"
                                            (change)="showInventories(selectedCategory,'Category')">
                                        </ng-select>
                                    </pos-form-field>
                                </pos-column>
                                <pos-column>
                                    <pos-form-field label="Inventory Zone" labelStyle="col-12" inputStyle="col-12">
                                        <ng-select posInput [items]="inventoryZones" bindLabel="NameWithCode"
                                            bindValue="Id" placeholder="Select inventory zone"
                                            [(ngModel)]="selectedZone"
                                            (change)="showInventories(selectedZone,'InventoryZone')">
                                        </ng-select>
                                    </pos-form-field>
                                </pos-column>
                                <pos-column>
                                    <pos-form-field label="Status" labelStyle="col-12" inputStyle="col-12">
                                        <ng-select posInput [items]="inventoryStatus" bindLabel="Name" bindValue="Id"
                                            (change)="showInventories(selectedStatus,'Status')"
                                            [(ngModel)]="selectedStatus" placeholder="Select status">
                                        </ng-select>
                                    </pos-form-field>
                                </pos-column>
                            </pos-row>
                            <div class="row col-12 p-0 pt-pix-5 display-flex wrap-reverse mx-0">
                                <div class="col-lg-9 col-md-9 col-12 check-section p-0">
                                    <label class="pe-pix-15 check">
                                        <span class="font-16 font-weight-600">Check</span>
                                    </label>
                                    <div class="pe-pix-15">
                                        <p-checkbox posInput name="allSections" #allSections="ngModel" class="font-16"
                                            (onChange)="checkChange('section',checkAllSections)"
                                            [(ngModel)]="checkAllSections" binary="true" label="All Sections" />
                                    </div>
                                    <div class="pe-pix-15">
                                        <p-checkbox posInput name="allProducts" #allProducts="ngModel" class="font-16"
                                            (onChange)="checkChange('product', checkAllProducts)"
                                            [(ngModel)]="checkAllProducts" binary="true" label="All Products" />
                                    </div>
                                    <div class="pe-pix-15">
                                        <p-checkbox posInput name="allOverdueProducts" #allOverdueProducts="ngModel"
                                            class="font-16" (onChange)="checkChange('overdue', checkOverdueProducts)"
                                            [(ngModel)]="checkOverdueProducts" binary="true" label="Overdue" />
                                    </div>
                                </div>
                                <div
                                    class="page-content__search col-lg-3 col-md-3 col-sm-6 col-12 ps-pix-0 ps-pix-md-15 pe-pix-0 pe-pix-sm-15">
                                    <pos-icon [icon]="icons.times" *ngIf="filterProduct"
                                        (click)="clearLogSearchFilter()">
                                    </pos-icon>
                                    <input class="form-control h-36" id="searchFilter" (input)="searchProduct()"
                                        [(ngModel)]="filterProduct" #searchFilter="ngModel" placeholder="Search here">
                                </div>
                            </div>
                        </div>
                        <!-- inventory-block-->
                        <form #inventoryLogForm="ngForm" autocomplete="off">
                            <div class="col-12 inner_remaining" [class.align-vertical-center]="noRecordMessage">
                                <div class="col-12 inventory-block__items p-0 display-flex flex-wrap"
                                    *ngFor="let item of inventoryFinalLog | customDeepFilter: filterProduct: 'CategoryList':'Product'; index as i">
                                    <div class="col-md-12 col-sm-12 col-12 inventory-block__items__title">
                                        <div class="display-flex mb-0">
                                            <p-checkbox [(ngModel)]="item.IsChecked" binary="true"
                                                [name]="item.CategoryName"
                                                (onChange)="!item.IsChecked?checkAllSections=false:null" [label]="item.CategoryName" labelStyleClass="ps-pix-5 font-weight-600" />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12 p-pix-10 display-flex"
                                        *ngFor="let product of item.CategoryList | customFilter: filterProduct; index as j">
                                        <div class="inventory-product-card">
                                            <pos-inventory-product-log [inventoryProduct]="product"
                                                (stateChange)="productStateChange($event)" [index]="i + '_' + j">
                                            </pos-inventory-product-log>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 text-center red">
                                    <span>{{noRecordMessage}}</span>
                                </div>
                            </div>
                            <div class="col-12 pt-pix-10 pe-0 border-top">
                                <!-- inventory-footer -->
                                <button
                                    class="btn btn-lg bg-theme-separator ms-pix-20 pos-button"
                                    (click)="printInventory();" type="button">
                                    <pos-icon [icon]="icons.inventorylogprint" class="pe-pix-5"></pos-icon>Print
                                </button>
                                <button class="btn btn-lg btn-primary btn-cancel white float-end pos-button"
                                    (click)="checkUpdatedProducts();" type="button">
                                    <pos-icon [icon]="icons.inventorylogupdate" class="pe-pix-5"></pos-icon>Update
                                    Inventory
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #salesProductStockWarning>
    <div>
        <button (click)="closeWarningModel()" aria-label="Close" class="close" type="button">&times;</button>
        <h4 class="modal-content__heading">Confirm</h4>
    </div>
    <div class="modal-body px-pix-20 pb-0">
        <pos-inventory-log-stock-sales-product-warning [salesInventoryProducts]="salesInventoryProducts">
        </pos-inventory-log-stock-sales-product-warning>
    </div>
    <div class="col-12 action-button-container popup-footer pb-pix-10">
        <button type="submit" class="btn btn-primary btn-lg btn-save pos-button"
            (click)="updateInventoryQty()">Continue</button>
        <button type="button" class="btn btn-secondary btn-lg btn-cancel-modal pos-button"
            (click)="closeWarningModel()">Cancel</button>
    </div>
    <div class="clearfix"></div>
</ng-template>
