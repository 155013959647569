<pos-edit-wrapper (close)="onCancel()" heading="Merge Into Product: {{inventoryProductName}} ({{dateAdded| tark_date}})">
    <form #mergeProductForm="ngForm" (submit)='submit(mergeProductForm.valid)' autocomplete="off">
        <div class="col-12 px-4 form-horizontal">
            <pos-row>
                <pos-column>
                    <pos-form-field label="Inventory Product" [validationMessages]="{required: 'Please select inventory product.'}"
                        inputStyle="col-sm-8 col-xs-12">
                        <ng-select posInput [items]="inventoryProducts" bindLabel="Name" placeholder="Select product" appendTo="body"
                            [(ngModel)]="selectedProduct" name="product" [required]="true">
                        </ng-select>
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="clearfix"></div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" value="Save">Save</button>
            <button type="button" data-dismiss="modal" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" (click)="onCancel()"
                value="Cancel">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
