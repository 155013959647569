import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseCrudService } from 'src/app/shared/services';
import { ManualDataExport, ManualDataExportPreview } from '../interface/manual-data-export';

@Injectable({
  providedIn: 'root'
})
export class ManualDataExportService extends BaseCrudService<ManualDataExport> {

  constructor(private httpClient: HttpClient) {
    super('data-subscription/manual', httpClient);
  }

  newManualSubscription(): ManualDataExportPreview {
    return {
      Id: 0,
      DataSubscriptionId: 0,
      Name: '',
      RawData: '',
      Status: '',
      ScheduledOn: null,
      DateEdited: null,
      SendVia: '',
      Data: [],
      Columns: [],
    };
  }
  getPendingManualExport(): Observable<Array<ManualDataExportPreview>> {
    return this.httpClient.get<Array<ManualDataExportPreview>>(this.apiEndpoint + '/pending');
  }

  getManualExportPreview(id: number): Observable<ManualDataExportPreview> {
    return this.httpClient.get<ManualDataExportPreview>(this.apiEndpoint + `/${id}/preview`);
  }

  releaseManualExport(manualSubscriptionId: number) {
    return this.httpClient.post(this.apiEndpoint + `/${manualSubscriptionId}/release`, {});
  }

  ignoreManualExport(manualSubscriptionId: number) {
    return this.httpClient.post(this.apiEndpoint + `/${manualSubscriptionId}/reject`, {});
  }
}
