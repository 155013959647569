import { check, infoManageCheckSquare } from 'src/app/shared/components/icon';
import { ConfirmDeleteComponent } from 'src/app/shared/components/confirm-delete/confirm-delete.component';
import { OnlineOrderingCategoriesService } from './../../../service/online-ordering-categories.service';
import { Component, OnInit, ViewChild } from '@angular/core';

import {
  SpinnerService, AlertsService, Permissions, Levels, AuthenticationService, Messages, PrintTableService,
  BaseGridComponent, ApplicationStateService
} from 'src/app/shared';
import { GridColumn, EditColumn, LinkColumn, DeleteColumn, TextAlign, TemplateColumn, TableComponent } from '@tarktech/tark-ng-utils';
import * as _ from 'lodash';
import { OnlineOrderingCategory } from '../../../interface';
import { ActivatedRoute, Router } from '@angular/router';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
@Component({
  selector: 'pos-online-ordering-option-list',
  templateUrl: './online-ordering-option-list.component.html',
  styleUrls: ['./online-ordering-option-list.component.scss']
})
export class OnlineOrderingOptionListComponent extends BaseGridComponent implements OnInit {

  orderingOptionListColumns: Array<GridColumn> = [];
  printOrderingOptionListColumns: Array<GridColumn> = [];
  permission = {
    name: Permissions.OnlineOrderingOptionLists,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };
  public icons = {
    check,
    infoManageCheckSquare
  };
  optionList: Array<OnlineOrderingCategory> = [];
  @ViewChild('confirmDeleteOption') private confirmDeleteOption: ConfirmDeleteComponent;
  @ViewChild('iconTemplate', { static: true }) private iconTemplate: any;
  @ViewChild('orderingOptionGrid', { static: true }) orderingOptionGrid: TableComponent;
  deleteOption: any;
  constructor(private alertService: AlertsService,
    private spinnerService: SpinnerService,
    protected route: ActivatedRoute,
    private router: Router,
    protected applicationStateService: ApplicationStateService,
    private authenticationService: AuthenticationService,
    private onlineOrderingCategoriesService: OnlineOrderingCategoriesService,
    private printService: PrintTableService) { super(applicationStateService, route); }

  ngOnInit() {
    this.configureGridColumns();
    this.getOnlineOrderingOptions();
    this.orderingOptionGrid.context = this.gridContext;
  }

  getOnlineOrderingOptions() {
    this.spinnerService.show();
    this.onlineOrderingCategoriesService.getOnlineOrderingCategories()
      .subscribe({
        next: (response) => {
          this.optionList = _.filter(response, (orderingCategorie) => {
            return orderingCategorie.IsOption === true;
          });
          this.optionList = _.orderBy(this.optionList, ['Name']);
          this.optionList.forEach(option => {
            option.HasIcon = option.Icon ? true : false;
          });
        },
        error: this.alertService.showApiError,
        complete: () => {
          this.spinnerService.hide();
        }
      });
  }


  configureGridColumns() {

    const iconColumn = new TemplateColumn({
      HeaderText: 'Has Icon',
      itemTemplate: this.iconTemplate,
      TextAlign: TextAlign.Center,
      Width: '100px'
    });

    const editColumn = new EditColumn({
      Clicked: (data) => {
        this.editOrderingOption(data.Id);
      },
      // CellClass: 'cell-edit',
      Width: '57px',
      AddIcon: new LinkColumn({
        Clicked: () => {
          this.editOrderingOption(0);
        },
        // CellClass: 'cell-edit',
        ToolTip: 'New'
      })
    });

    const deleteColumn = new DeleteColumn({
      Clicked: (data) => {
        this.deleteOrderingOption(data);
      },
      Width: '83px',
      PrintIcon: new LinkColumn({
        Clicked: () => {
          this.printData();
        }
      })
    });

    this.orderingOptionListColumns = [
      new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '27%' }),
      new GridColumn({ HeaderText: 'Description', Field: 'Description', IsSortable: true, Width: '30%' }),
      new GridColumn({ HeaderText: 'Min Selection', Field: 'MinSelections', IsSortable: true, Width: '15%' }),
      new GridColumn({ HeaderText: 'Max Selection', Field: 'MaxSelections', IsSortable: true, Width: '15%' })
    ] as Array<GridColumn>;

    this.printOrderingOptionListColumns = [...this.orderingOptionListColumns];
    this.printOrderingOptionListColumns.push(new GridColumn({ HeaderText: 'Has Icon', Field: 'HasIcon', IsSortable: true, Width: '20%' }));

    this.orderingOptionListColumns.push(iconColumn);
    if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      this.orderingOptionListColumns.push(editColumn);
      this.orderingOptionListColumns.push(deleteColumn);
    }
  }

  editOrderingOption(optionId) {
    this.router.navigate([optionId], { relativeTo: this.route });
  }

  deleteOrderingOption(data) {
    this.deleteOption = data;
    const message = StringUtils.format(Messages.ConfirmDeleteOption, { 'optionName': data.Name });
    this.confirmDeleteOption.Show(message);

  }

  onConfirmAcknowledge() {
    this.spinnerService.show();
    this.onlineOrderingCategoriesService.deleteCategory(this.deleteOption.Id)
      .subscribe({
        next: (response) => {
          this.getOnlineOrderingOptions();
          const message = StringUtils.format(Messages.OnlineOrderingOptionDeleteSuccess, { 'optionName': this.deleteOption.Name });
          this.alertService.renderSuccessMessage(message);
        },
        error: this.alertService.showApiError,
        complete: () => {
          this.spinnerService.hide();
        }
      });
  }

  printData() {
    this.printService.printEmitter.next({ gridColumns: this.printOrderingOptionListColumns, gridData: this.optionList });
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
