import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AlertsService, SpinnerService, Messages, ICloseable, OrderService, ModalFormComponent, ModalService, FormUtilityService } from 'src/app/shared';
import { finalize } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'pos-choose-color-screen',
  templateUrl: './choose-color-screen.component.html',
  styleUrls: ['./choose-color-screen.component.scss']
})
export class ChooseColorScreenComponent extends ModalFormComponent implements OnInit, ICloseable {
  get getForm(): NgForm {
    return this.formColor;
  }

  @Input() screenId: number;
  @ViewChild('formColor') formColor: NgForm;
  color: string = '';
  constructor(private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private orderService: OrderService,
    modalRef: BsModalRef, modalService: ModalService, formUtilityService: FormUtilityService) {
    super(modalRef, modalService, formUtilityService);
  }

  ngOnInit() {
  }

  updateButtonColor(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.spinnerService.show();
    this.orderService.updateButtonColor(this.screenId, this.color)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (data: any) => {
          this.alertService.renderSuccessMessage(Messages.ChooseColorScreenSaveSuccess);
          this.hide({ shouldReload: true });
        }, error: this.alertService.showApiError
      });
  }

  onCancel() {
    this.hide({ shouldReload: false });
  }

}
