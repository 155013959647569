import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalService, SpinnerService, book, cogWhite, deleteWhite, desktop, paintBrush, times } from 'src/app/shared/components';
import { SetKioskButtonComponent, SetKioskNextScreenComponent } from 'src/app/order-kiosk';
import { finalize } from 'rxjs';
import { ButtonDesign, KioskScreenChoice, KioskScreenConfig } from 'src/app/order-kiosk/interfaces';
import { KioskNavigationService, OrderKioskConfigService, OrderKioskScreenChoiceService } from 'src/app/order-kiosk/services';
import { AlertsService, Messages } from 'src/app/shared';
import { KioskConfigSetupItem } from 'src/app/order-kiosk/interfaces/kiosk-config-setup-items';
import { DesignButtonComponent } from '../../button-actions/design-button/design-button.component';
declare let $: any;

@Component({
  selector: 'pos-kiosk-screen-setup',
  templateUrl: './kiosk-screen-setup.component.html',
  styleUrls: ['./kiosk-screen-setup.component.scss']
})
export class KioskScreenSetupComponent {

  @Input() screenButton: KioskScreenChoice;
  @Input() screenButtons: Array<KioskScreenChoice> = [];
  @Output() screenButtonsChange = new EventEmitter<Array<KioskScreenChoice>>();
  @Output() setGridRowsCols = new EventEmitter<any>();
  @Input() isAllowDelete = false;
  @Input() isDesignMode = false;
  @Input() setupItems: KioskConfigSetupItem;
  @Input() buttonsDesignConfig: KioskScreenConfig;
  @Input() buttonIndex: number;
  defaultItems: KioskConfigSetupItem;
  popup: any;
  oldMenuIndex: number;
  icons = {
    book, cogWhite, deleteWhite, desktop, times, paintBrush
  }

  constructor(private modalService: ModalService,
    private orderKioskScreenChoiceService: OrderKioskScreenChoiceService,
    private orderKioskConfigService: OrderKioskConfigService,
    private kioskNavigationService: KioskNavigationService,
    private spinnerService: SpinnerService,
    private alertsService: AlertsService) {

  }

  ngOnInit() {
    this.defaultItems = {
      SetButton: true,
      SetNextScreen: true,
      RemoveButton: true,
      IsEditable: false,
      DesignButton: false
    };
    this.setupItems = { ...this.defaultItems, ...this.setupItems }
    $(document).click(() => {
      if (this.isDesignMode) {
        $('.custom-order').css('display', 'none');
        this.oldMenuIndex = null;
      }
    });
  }

  configure($event, isConfigureButton = true) {
    this.preventDefaultButtonEvent($event);
    this.popup.hide();
    const component = isConfigureButton ? SetKioskButtonComponent : SetKioskNextScreenComponent;
    const modalRef = this.modalService.show(component, {
      backdrop: 'static',
      class: 'vertical-center',
      animated: false,
      keyboard: false,
      initialState: {
        kioskScreenButton: this.screenButton
      }
    });
    modalRef.close.subscribe((res) => {
      if (res?.isReloadButtons) {
        this.kioskNavigationService.reloadNavigationButtons.emit();
      }
    });
  }

  private preventDefaultButtonEvent($event: any) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  removeButton($event) {
    this.preventDefaultButtonEvent($event);
    this.popup.hide();
    this.screenButton.ButtonId = null;
    this.screenButton.Button = null;
    this.screenButton.NextScreenId = null;
    this.spinnerService.show();
    this.orderKioskScreenChoiceService.insertOrUpdate(this.screenButton)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.alertsService.renderSuccessMessage(Messages.KioskScreenChoiceRemoved);
          this.screenButtonsChange.emit(this.screenButtons);
        }, error: this.alertsService.showApiError
      });
  }

  deleteButton(event) {
    this.preventDefaultButtonEvent(event);
    this.spinnerService.show();
    this.orderKioskScreenChoiceService.delete(this.screenButton.Id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.screenButtons = this.screenButtons.filter(x => x.Id != this.screenButton.Id);
          this.screenButtonsChange.emit(this.screenButtons);
          this.setGridRowsCols.emit();
          this.alertsService.renderSuccessMessage(Messages.KioskScreenChoiceDeleted);
          if (this.buttonsDesignConfig?.Config)
            this.removeButtonConfig();
        }, error: this.alertsService.showApiError
      });
  }
  removeButtonConfig() {
    let buttonsDesign: Array<ButtonDesign> = JSON.parse(this.buttonsDesignConfig.Config);
    buttonsDesign.splice(this.buttonIndex, 1);
    this.buttonsDesignConfig.Config = JSON.stringify(buttonsDesign);
    this.spinnerService.show();
    this.orderKioskConfigService.update(this.buttonsDesignConfig)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.kioskNavigationService.reloadNavigationButtons.emit();
        }, error: this.alertsService.showApiError
      });
  }

  openOptionsMenu(event: Event) {
    let buttonIndex = this.screenButton.Ordinal;
    this.preventDefaultButtonEvent(event);
    $('.custom-order').css('display', 'none');
    $('popover-container').css('display', 'none');

    /* Note: We user oldMenuIndex variable for handel exact menu open or close request */
    if (this.oldMenuIndex !== buttonIndex || this.oldMenuIndex == null) {
      this.oldMenuIndex = buttonIndex;
      $('#menu_' + buttonIndex).css('display', 'block');
    } else {
      $('#menu_' + buttonIndex).css('display', 'none');
      this.oldMenuIndex = null;
    }
  }

  designButton(event) {
    this.preventDefaultButtonEvent(event);
    this.popup.hide();
    const modalRef = this.modalService.show(DesignButtonComponent, {
      backdrop: 'static',
      class: 'vertical-center',
      animated: false,
      keyboard: false,
      initialState: {
        kioskScreenConfig: this.buttonsDesignConfig,
        buttonIndex: this.buttonIndex,
        buttonsLength: this.screenButtons.length
      }
    });
    modalRef.close.subscribe((res) => {
      if (res?.isReload)
        this.kioskNavigationService.reloadNavigationButtons.emit();
    });
  }
}
