import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { AdfColumn, AdfModel, DashboardOptions } from '../../interfaces';
import Sortable from "sortablejs";
import { find, forEach, remove } from 'lodash';
declare let $: any;
@Component({
  selector: 'pos-adf-dashboard-column',
  templateUrl: './adf-dashboard-column.component.html',
})
export class AdfDashboardColumnComponent implements OnChanges {

  @Input() column: AdfColumn;
  @Input() isEditMode: boolean;
  @Input() adfModel: AdfModel;
  @Input() options: DashboardOptions;
  @Input() isInteractive: boolean;

  ngOnChanges(change: SimpleChanges) {
    if (this.adfModel && this.isInteractive) {
      this.applySortable();
    }
  }

  ngAfterViewInit() {
    if (this.adfModel && this.adfModel.rows && this.isInteractive && Array.isArray(this.adfModel.rows)) {
      this.applySortable();
    }
  }
  applySortable = () => {
    const element = $('#c_' + this.column.rowIndex + '_' + this.column.columnIndex)[0];
    try {
      Sortable.create(element, {
        group: 'widgets',
        handle: '.move-handle',
        ghostClass: 'placeholder',
        animation: 150,
        invertSwap: true,
        dragoverBubble: true,
        onAdd: (evt) => {
          this.changeWidget(evt);
        },
        onUpdate: (evt) => {
          this.changeWidget(evt);
        }
      })
    }
    catch (ex) {
    }
  }

  changeWidget = (dragEvent) => {
    if (dragEvent) {
      const sourceElement = dragEvent.from;
      const destinationElement = dragEvent.to;
      const sourceElementAttributeValue = sourceElement ? sourceElement.getAttribute('id') : '-1';
      const destinationElementAttributeValue = destinationElement ? destinationElement.getAttribute('id') : '-1';
      const sourceRowIndex = this.getRowIndex(sourceElementAttributeValue);
      let destinationRowIndex = this.getRowIndex(destinationElementAttributeValue);
      const sourceColumnIndex = this.getColumnIndex(sourceElementAttributeValue);
      const destinationColumnIndex = this.getColumnIndex(destinationElementAttributeValue);
      let widgetIndex = dragEvent.item.getAttribute('id');
      widgetIndex = widgetIndex.replace('w_', '');
      const widget = this.findAndRemoveWidget(sourceRowIndex, sourceColumnIndex, widgetIndex);

      if (widget && destinationRowIndex && destinationColumnIndex) {
        this.setWidgetIndex(widget, destinationRowIndex, destinationColumnIndex);
      }

      forEach(this.adfModel.rows, (row, rowIndex) => {
        forEach(row.columns, (column, columnIndex) => {
          forEach(column.widgets, (widget, widgetIndex) => {
            widget.rowIndex = rowIndex + 1;
            widget.columnIndex = columnIndex + 1;
            widget.widgetIndex = widget.rowIndex.toString() + widget.columnIndex.toString() + widgetIndex.toString();
          })
        });
      });

    }
  }

  findAndRemoveWidget = (sourceRowIndex, sourceColumnIndex, dragedWidgetIndex) => {

    const row = find(this.adfModel.rows, (row, rowIndex) => { return rowIndex == sourceRowIndex - 1 });
    if (row) {
      const column = find(row.columns, (column, columnIndex) => { return columnIndex == sourceColumnIndex - 1 });
      if (column) {
        const draggedWidget = find(column.widgets, (widget, widgetIndex) => {
          return widget.columnIndex == sourceColumnIndex && widget.rowIndex == sourceRowIndex
            && (!widget.widgetIndex || widget.widgetIndex == sourceRowIndex.toString() + sourceColumnIndex.toString() + dragedWidgetIndex.toString())
        });
        if (draggedWidget) {
          remove(column.widgets, (widget) => {
            return widget.columnIndex == sourceColumnIndex && widget.rowIndex == sourceRowIndex
              && (!widget.widgetIndex || widget.widgetIndex == sourceRowIndex.toString() + sourceColumnIndex.toString() + dragedWidgetIndex.toString())
          });
        }
        return draggedWidget;
      }
      return null;
    }
    return null;
  }

  getModifiedAttributeValue = (attributeValue) => {
    return attributeValue.replace('c_', '');
  }

  getColumnIndex = (attributeValue) => {
    const modifiedAttributeValue = this.getModifiedAttributeValue(attributeValue);
    const indexes = modifiedAttributeValue.split('_');
    if (indexes && indexes.length) {
      return indexes[1];
    }
    return '-1';
  }

  getRowIndex = (attributeValue) => {
    const modifiedAttributeValue = this.getModifiedAttributeValue(attributeValue);
    const indexes = modifiedAttributeValue.split('_');
    if (indexes && indexes.length) {
      return indexes[0];
    }
    return '-1';
  }


  private setWidgetIndex(widget: any, destinationRowIndex: any, destinationColumnIndex: any) {
    widget.rowIndex = destinationRowIndex;
    widget.columnIndex = destinationColumnIndex;
    if (this.adfModel?.rows[destinationRowIndex - 1] &&
      this.adfModel?.rows[destinationRowIndex - 1]?.columns[destinationColumnIndex - 1]) {
      if (!this.adfModel?.rows[destinationRowIndex - 1]?.columns[destinationColumnIndex - 1]?.widgets?.length) {
        this.adfModel.rows[destinationRowIndex - 1].columns[destinationColumnIndex - 1].widgets = [];
      }
      this.adfModel?.rows[destinationRowIndex - 1]?.columns[destinationColumnIndex - 1]?.widgets?.push(widget);
    }
  }
}
