import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forEach } from 'lodash';
import { DietaryWarning } from 'src/app/information-management';
import { KioskScreenConfig, KioskScreenChoice } from 'src/app/order-kiosk/interfaces';
import { AllergenScreenConfig } from 'src/app/order-kiosk/interfaces/config/allergen-screen-config';
import { KioskConfigSetupItem } from 'src/app/order-kiosk/interfaces/kiosk-config-setup-items';
import { KioskNavigationService, OrderKioskChoiceService, OrderKioskConfigService } from 'src/app/order-kiosk/services';
import { checkCircle, cogWhite, deleteWhite, plusWhite } from 'src/app/shared/components/icon';
import { DomainConstants, RuntimeConstants } from 'src/app/shared/constants';
declare let $: any;

@Component({
  selector: 'pos-allergens-screen-two',
  templateUrl: './allergens-screen-two.component.html',
  styleUrls: ['./allergens-screen-two.component.scss']
})
export class AllergensScreenTwoComponent {
  @Input() screenConfigs: Array<KioskScreenConfig> = [];
  @Input() screenId: number;
  @Input() screenButtons: Array<KioskScreenChoice> = [];
  screenConfig: AllergenScreenConfig;
  imageRootPath = `${RuntimeConstants.IMAGE_BASE_PATH}/order-kiosk/`;
  allergenRootPath = `${RuntimeConstants.IMAGE_BASE_PATH}/allergens/`;
  sectionHeight: number;
  warningsHeight: number;
  isDesignMode = false;
  dietaryWarnings: Array<DietaryWarning> = [];
  icons = {
    cogWhite,
    deleteWhite,
    plusWhite,
    checkCircle
  }
  setupItems: KioskConfigSetupItem;

  constructor(private orderKioskConfigService: OrderKioskConfigService,
    private kioskNavigationService: KioskNavigationService,
    private kioskChoiceService: OrderKioskChoiceService,
    private route: ActivatedRoute
  ) {
    this.isDesignMode = route.snapshot.params.id;
    this.screenConfig = orderKioskConfigService.newScreenConfig();
  }

  ngOnInit() {
    this.setupItems = { IsEditable: true, SetButton: false, RemoveButton: false };
    this.dietaryWarnings = this.kioskNavigationService.dietaryWarnings;
    this.resizeWindow();
    this.prepareConfigs();
    this.calculateSectionHeight();
    this.setButtonsInScreenButtons();
  }

  setButtonsInScreenButtons() {
    forEach(this.screenButtons, screenButton => {
      screenButton.Button = this.kioskChoiceService.newChoice();
    });
  }

  prepareConfigs() {
    const screenConfig = this.orderKioskConfigService.parseConfigProperties(DomainConstants.KioskScreenConfigProperties.ScreenConfig, this.screenConfigs);
    if (screenConfig) this.screenConfig = screenConfig;
  }

  ngAfterViewInit(): void {
    this.calculateSectionHeight();
  }

  calculateSectionHeight() {
    this.sectionHeight = this.orderKioskConfigService.calculateInvoiceHeight(this.screenConfig);
    this.warningsHeight = this.sectionHeight - ($('.allergens-title').outerHeight() ?? 0) - $('.action-buttons').outerHeight() - ($('.allergen-notes').outerHeight() ?? 0) - 55;
  }

  resizeWindow() {
    $(window).resize(() => {
      this.calculateSectionHeight();
    });
  }

  buttonClick = (kioskScreenChoice: KioskScreenChoice, isApplyDietaryWarning = false) => {
    if (!this.isDesignMode) {
      kioskScreenChoice.Button.NextScreenId = kioskScreenChoice.NextScreenId;
      if (isApplyDietaryWarning) {
        this.kioskNavigationService.applyDietaryWarnings();
      }
      this.kioskNavigationService.buttonClick(kioskScreenChoice.Button, this.screenId, kioskScreenChoice.ConfigId);
    }
  }
}
