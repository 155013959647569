import { Route } from '@angular/router';
import { AutomatedExportBaseComponent } from './components/automated-export-base/automated-export-base.component';
import { DataSourceEditComponent } from './components/data-source-edit/data-source-edit.component';
import { DataSourceSubscribeComponent } from './components/data-source-subscribe/data-source-subscribe.component';
import { DataSourceComponent } from './components/data-source/data-source.component';
import { Levels, Permissions } from 'src/app/shared/constants';
import { DataSourceSubscribeEditComponent } from './components/data-source-subscribe-edit/data-source-subscribe-edit.component';
import { ExportConnectionComponent } from './components/export-connection';
import { ExportConnectionEditComponent } from './components/export-connection-edit/export-connection-edit.component';
import { ManualDataExportListComponent } from './components/manual-data-export-list';
import { ManualDataExportPreviewComponent } from './components/manual-data-export-preview';
import { MacroScriptsComponent } from './components/macro-scripts/macro-scripts.component';
import { MacroScriptEditComponent } from './components/macro-script-edit/macro-script-edit.component';
import { DeactivateGuard } from 'src/app/shared';

export const AUTOMATED_EXPORTS: Array<Route> = [
    {
        path: 'subscriptions',
        component: DataSourceSubscribeComponent,
        data: {
            group: 'subscriptions',
            permissions: [{ 'Name': Permissions.AutomatedExportsSubscriptions, 'Level': Levels.Access }]
        }
    },
    {
        path: 'subscriptions/manual',
        component: ManualDataExportListComponent,
        data: {
            group: 'manual-export',
            permissions: [{ 'Name': Permissions.ReleaseQueue, 'Level': Levels.Access }]
        }
    },
    {
        path: 'subscriptions/manual/review/:id',
        component: ManualDataExportPreviewComponent,
        data: {
            permissions: [{ 'Name': Permissions.ReleaseQueue, 'Level': Levels.Access }]
        }
    },
    {
        path : 'subscriptions/:id',
        component: DataSourceSubscribeEditComponent,
        data: {
            permissions: [{ 'Name': Permissions.AutomatedExportsSubscriptions, 'Level': Levels.Access }]
        }
    },
    {
        path: 'configuration',
        component: AutomatedExportBaseComponent,
        data: {
            group: 'configuration',
            permissions: [{ 'Name': Permissions.AutomatedExportsConfiguration, 'Level': Levels.Access }]
        },
        children: [
            {
                path: 'macro-scripts',
                component: MacroScriptsComponent,
                data: {
                    group: 'macro-script',
                    permissions: [{ 'Name': Permissions.AutomatedExportsConfiguration, 'Level': Levels.Access }]
                }
            },
            {
                path: 'macro-scripts/:id',
                component: MacroScriptEditComponent,
                data: {
                    group: 'macro-script',
                    permissions: [{ 'Name': Permissions.AutomatedExportsConfiguration, 'Level': Levels.Access }]
                }
            },
            {
                path: 'data-source',
                component: DataSourceComponent,
                data: {
                    group: 'data-source',
                    permissions: [{ 'Name': Permissions.AutomatedExportsConfiguration, 'Level': Levels.Access }]
                }
            },
            {
                path: 'data-source/:id',
                component: DataSourceEditComponent,
                canDeactivate: [DeactivateGuard],
                data: {
                    permissions: [{ 'Name': Permissions.AutomatedExportsConfiguration, 'Level': Levels.Access }]
                }
            },
            {
                path: 'export-connection',
                component: ExportConnectionComponent,
                data: {
                    group: 'export-connection',
                    permissions: [{ 'Name': Permissions.AutomatedExportsConfiguration, 'Level': Levels.Access }]
                }
            },
            {
                path: 'export-connection/:id',
                component: ExportConnectionEditComponent,
                canDeactivate: [DeactivateGuard],
                data: {
                    permissions: [{ 'Name': Permissions.AutomatedExportsConfiguration, 'Level': Levels.Access }]
                }
            }
        ]
    }
];
