import { check, infoManageShoppingCart } from 'src/app/shared/components/icon';
import { Messages } from './../../../../shared/constants/ui-messages';
import { OnlineOrderingCategory } from './../../interface/online-ordering';
import { Component, OnInit, ViewChild } from '@angular/core';

import {
  SpinnerService, AlertsService, ConfirmDeleteComponent, Permissions, Levels, AuthenticationService, PrintTableService,
  ApplicationStateService, BaseGridComponent
} from 'src/app/shared';
import { OnlineOrderingCategoriesService } from '../../service/online-ordering-categories.service';
import { GridColumn, EditColumn, LinkColumn, DeleteColumn, TemplateColumn, TextAlign, TableComponent } from '@tarktech/tark-ng-utils';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';

@Component({
  selector: 'pos-online-ordering-categories',
  templateUrl: './online-ordering-categories.component.html',
  styleUrls: ['./online-ordering-categories.component.scss']
})
export class OnlineOrderingCategoriesComponent extends BaseGridComponent implements OnInit {

  orderingCategories: Array<OnlineOrderingCategory> = [];
  deleteCategory: OnlineOrderingCategory;
  orderingCategoriesColumns: Array<GridColumn> = [];
  @ViewChild('orderingCategoryGrid', { static: true }) orderingCategoryGrid: TableComponent;
  printOrderingCategoriesColumns: Array<GridColumn> = [];
  permission = {
    name: Permissions.OnlineOrderingCategories,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };
  public icons = {
    check,
    infoManageShoppingCart
  };
  @ViewChild('categoryIconTemplate', { static: true }) private categoryIconTemplate: any;
  @ViewChild('categoryActiveTemplate', { static: true }) private categoryActiveTemplate: any;
  @ViewChild('confirmDeleteCategory') private confirmDeleteCategory: ConfirmDeleteComponent;
  constructor(private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private onlineOrderingCategoriesService: OnlineOrderingCategoriesService,
    private authenticationService: AuthenticationService,
    protected applicationStateService: ApplicationStateService,
    protected route: ActivatedRoute,
    private router: Router,
    private printService: PrintTableService) {
    super(applicationStateService, route);
  }

  ngOnInit() {
    this.configureGridColumns();
    this.getOnlineOrderingCategories();
    this.orderingCategoryGrid.context = this.gridContext;
  }

  getOnlineOrderingCategories() {
    this.spinnerService.show();
    this.onlineOrderingCategoriesService.getOnlineOrderingCategories()
      .subscribe({
        next: (response) => {
          response = (response === null ? [] : response);
          this.orderingCategories = _.filter(response, (orderingCategorie) => {
            return orderingCategorie.IsOption === false;
          });

          this.orderingCategories.forEach(category => {
            category.HasIcon = category.Icon ? true : false;
          });
        }, error: this.alertService.showApiError,
        complete: () => {
          this.spinnerService.hide();
        }
      });
  }

  configureGridColumns() {

    const categoryIconColumn = new TemplateColumn({
      HeaderText: 'Has Icon',
      Field: 'Icon',
      itemTemplate: this.categoryIconTemplate,
      TextAlign: TextAlign.Center,
      Width: '100px',
    });

    const categoryActiveColumn = new TemplateColumn({
      HeaderText: 'Visible',
      Field: 'IsActive',
      itemTemplate: this.categoryActiveTemplate,
      TextAlign: TextAlign.Center,
      Width: '100px',
    });

    const editColumn = new EditColumn({
      Clicked: (data) => {
        this.editOrderingCategory(data.Id);
      },
      AddIcon: new LinkColumn({
        Clicked: () => {
          this.editOrderingCategory(0);
        },
        ToolTip: 'New'
      }),
      Width: '57px'
    });

    const deleteColumn = new DeleteColumn({
      Clicked: (data) => {
        this.deleteOrderingCategory(data);
      },
      Width: '83px',
      PrintIcon: new LinkColumn({
        Clicked: () => {
          this.printData();
        }
      })
    });

    this.orderingCategoriesColumns = [
      new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '30%' }),
      new GridColumn({ HeaderText: 'Description', Field: 'Description', IsSortable: true, Width: '45%' }),
    ] as Array<GridColumn>;

    this.printOrderingCategoriesColumns = [...this.orderingCategoriesColumns];
    this.printOrderingCategoriesColumns.push(new GridColumn({ HeaderText: 'Has Icon', Field: 'HasIcon', IsSortable: true, Width: '20%' }));
    this.printOrderingCategoriesColumns.push(new GridColumn({ HeaderText: 'Visible', Field: 'IsActive', IsSortable: true, Width: '20%' }));

    this.orderingCategoriesColumns.push(categoryIconColumn);
    this.orderingCategoriesColumns.push(categoryActiveColumn);
    if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      this.orderingCategoriesColumns.push(editColumn);
      this.orderingCategoriesColumns.push(deleteColumn);
    }
  }

  gridRowReorder(event) {
    const dragElementIndex = event.dragIndex;
    const dropElementIndex = event.dropIndex;
    dragElementIndex < dropElementIndex ?
      (this.orderingCategories[dropElementIndex - 1].Ordinal = dropElementIndex) :
      (this.orderingCategories[dropElementIndex].Ordinal = dropElementIndex + 1);
    this.onlineOrderingCategoriesService.updateOnlineOrderingCategoryOrdinal(dragElementIndex < dropElementIndex ?
      this.orderingCategories[dropElementIndex - 1] : this.orderingCategories[dropElementIndex]).subscribe(
        () => { },
        this.alertService.showApiError,
        () => { }
      );
  }

  deleteOrderingCategory(data) {
    this.deleteCategory = data;
    const message = StringUtils.format(Messages.ConfirmDeleteCategory, { 'categoryName': data.Name });
    this.confirmDeleteCategory.Show(message);

  }

  onConfirmAcknowledge() {
    this.spinnerService.show();
    this.onlineOrderingCategoriesService.deleteCategory(this.deleteCategory.Id)
      .subscribe({
        next: (response) => {
          this.getOnlineOrderingCategories();
          const message = StringUtils.format(Messages.OnlineOrderingCategoryDeleteSuccess, { 'categoryName': this.deleteCategory.Name });
          this.alertService.renderSuccessMessage(message);
        }, error: this.alertService.showApiError,
        complete: () => {
          this.spinnerService.hide();
        }
      });
  }

  editOrderingCategory(categoryId) {
    this.router.navigate([categoryId], { relativeTo: this.route });
  }

  printData() {
    this.printService.printEmitter.next({ gridColumns: this.printOrderingCategoriesColumns, gridData: this.orderingCategories });
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
