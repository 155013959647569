import { Pipe, PipeTransform } from '@angular/core';
import { filter } from 'lodash';
import { MakeTableOrderModel } from 'src/app/make-table/interfaces/make-table-order';
import { MakeTableUIState } from 'src/app/make-table/interfaces/make-table-ui-state';
@Pipe({
    name: 'readyOrderFilter',
    pure: false
})
export class ReadyOrderFilter implements PipeTransform {
    transform(orders: Array<MakeTableOrderModel>, uiStates: Array<MakeTableUIState>): Array<MakeTableOrderModel> {
        return filter(orders, (order) => {
            return order ? (uiStates[order.Id]?.IsReadyProductAvailable) : false;
        });
    }
}
