import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { FilterConfiguration } from 'src/app/shared/interface/filter-configuration';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';
import { InventoryProductHistory } from '../interface/inventory-product-history';

@Injectable({
    providedIn: 'root'
})
export class InventoryProductHistoryService extends BaseCrudService<InventoryProductHistory> {

    constructor(httpService: HttpClient) {
        super('inventory-product-history', httpService);
    }

    getNewProductHistory(): InventoryProductHistory {
        return {
            Id: 0,
            InventoryProductId: 0,
            Name: null,
            SubCategory: null,
            InventorySubcategoryId: 0,
            Vendor: null,
            InventoryVendorId: null,
            VendorSku: null,
            VendorUrl: null,
            InventoryUnitId: null,
            UnitQty: null,
            UnitDetails: null,
            UnitName: null,
            Price: null,
            MinLevel: null,
            MaxLevel: null,
            IsActive: false,
            CurrentQtyOnHand: 0,
            MaxDaysBetweenLogging: null,
            IsCommon: false,
            PrimaryZoneId: null,
            PrimaryBinId: null,
            SecondaryZoneId: null,
            SecondaryBinId: null,
            Aisle: null,
            CalculatedQty: null,
            IsWarnConsumption: false,
            ConsumptionVarianceLowThresholdPct: null,
            MinTaskId: null,
            MinTaskTiming: null,
            MinTaskTimeString: null,
            ConsumptionThresholdLowAmount: null,
            IsWarnUnexpectedInventoryQty: false,
            AutoDecrementBy: '',
            AutoDecrementQty: null,
            ReceivingInstructions: null,
            Recipe: null,
            PricePerUnit: null,
            PriceUnit: null,
            IngredientImage: null,
            IngredientInstruction: null,
            IsSkipInventory: false,
            AverageConsumption: null,
            LogDueDateOverride: new Date(Date.now()),
            LogAfterReceivedDays: 0,
            IsLogAfterReceived: false,
            ConsumptionThresholdHighAmount: null,
            ConsumptionVarianceHighThresholdPct: null,
            MaxPortionsText: null,
            MeasureByInstructions: null,
            IsFood: false,
            IsOnOrder: false,
            IsTriggerInventoryLogOnMinimumReached: false,
            Description: null,
            ExhaustionPromptToTerminalId: null,
            IsPrintPrepLabel: false,
            IsNotified: true,
            DateAdded: new Date(Date.now()),
            InventoryDateAdded: new Date(Date.now()),
            InventoryDateEdited: new Date(Date.now()),
            DateEdited: new Date(Date.now()),
            UserId: 0
        };
    }

    getProductHistoryByProductId(productId: number): Observable<Array<InventoryProductHistory>> {
        return this.httpService.get<Array<InventoryProductHistory>>(`${this.apiEndpoint}/product/${productId}`);
    }

    getProductHistories(productId: number, filterConfiguration: FilterConfiguration): Observable<Array<InventoryProductHistory>> {
        return this.httpService.post<Array<InventoryProductHistory>>(`${this.apiEndpoint}/all/${productId}`,
            filterConfiguration);
    }

    getTotalRecordsCount(productId: number): Observable<number> {
        return this.httpService.get<number>(`${this.apiEndpoint}/count/${productId}`);
    }

}
