import { Component, EventEmitter, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { deleteWhite, fileSearch, undoWhite, plusWhite, screenBackupPlus, squareGreenStack, squareLightOrangeStack } from 'src/app/shared/components/icon/icons';
import { ICloseable } from 'src/app/shared/components/modal/ICloseable';
import { MenuBackup } from '../../interfaces/menu-backup';
import { ConfirmDeleteModalComponent, SpinnerService } from 'src/app/shared/components';
import { AlertsService } from 'src/app/shared/services';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { DateColumn, GridColumn, TarkDateTimePipe, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { MenuBackupService } from '../../services/menu-backup.service';
import { finalize } from 'rxjs/operators';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { MenuDisplayBackupEditComponent } from '../menu-display-backup-edit/menu-display-backup-edit.component';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';

@Component({
  selector: 'pos-menu-display-backup',
  templateUrl: './menu-display-backup.component.html',
  styleUrls: ['./menu-display-backup.component.scss']
})
export class MenuDisplayBackupComponent implements OnInit, ICloseable {
  close: EventEmitter<any> = new EventEmitter();
  @Input('menuId') menuId: number;
  @Input('menuDisplayName') menuDisplayName: string;
  backupColumns: Array<GridColumn> = [];
  backups: Array<MenuBackup> = [];
  @ViewChild('operationTemplate', { static: true }) operationTemplate: TemplateRef<any>;
  @ViewChild('headerTemplate', { static: true }) headerTemplate: TemplateRef<any>;
  public icons = {
    deleteWhite,
    undoWhite,
    screenBackupPlus,
    squareGreenStack,
    squareLightOrangeStack,
    fileSearch,
    plusWhite
  };

  constructor(private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private dateTimePipe: TarkDateTimePipe,
    private modalService: ModalService,
    private menuBackupService: MenuBackupService) { }

  ngOnInit(): void {
    this.configureGridColumn();
    this.getBackups();
  }

  configureGridColumn() {

    const operationColumn = new TemplateColumn({
      itemTemplate: this.operationTemplate,
      Width: '80px',
      headerTemplate: this.headerTemplate,
      TextAlign: TextAlign.Center
    });

    this.backupColumns = [
      new GridColumn({ HeaderText: 'Backup Name', Field: 'Name', IsSortable: false }),
      new DateColumn({ HeaderText: 'Date', Field: 'DateAdded', IsSortable: true, TextAlign: TextAlign.Left }),
      operationColumn
    ];
  }

  getBackups() {
    this.spinnerService.show();
    this.menuBackupService.getMenuBackups(this.menuId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.backups = res ? res : [];
        }, error: this.alertService.showApiError
      });
  }

  confirmDelete(backup) {
    const confirmCommonNextScreen = this.modalService.show(ConfirmDeleteModalComponent, {
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      initialState: {
        message: StringUtils.format(Messages.ConfirmDeleteBackup,
          {
            'Name': backup.Name,
            'Time': this.dateTimePipe.transform(backup.DateAdded)
          })
      }
    });
    confirmCommonNextScreen.close.subscribe(res => {
      if (res && res.shouldDelete) {
        this.deleteBackup(backup.Id);
      }
    });
  }

  deleteBackup(backupId: number) {
    this.spinnerService.show();
    this.menuBackupService.delete(backupId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: any) => {
          this.alertService.renderSuccessMessage(Messages.BackupDeletedSuccess);
          this.getBackups();
        }, error: this.alertService.showApiError
      });
  }

  confirmRestoreBackup(backup) {
    const confirmCommonNextScreen = this.modalService.show(ConfirmDeleteModalComponent, {
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      initialState: {
        message: StringUtils.format(Messages.ConfirmRestoreBackup,
          {
            'Name': backup.Name,
            'Time': this.dateTimePipe.transform(backup.DateAdded)
          })
      }
    });
    confirmCommonNextScreen.close.subscribe(res => {
      if (res && res.shouldDelete) {
        this.restoreBackup(backup.Id);
      }
    });
  }

  restoreBackup(backupId: number) {
    this.spinnerService.show();
    this.menuBackupService.restoreBackup(backupId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: any) => {
          this.alertService.renderSuccessMessage(Messages.BackupRestoredSuccess);
          this.close.emit({ shouldReload: true });
        }, error: this.alertService.showApiError
      });
  }


  onCancel() {
    this.close.emit({ shouldReload: false });
  }

}
