import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { Observable } from 'rxjs';
import { RawIngredient } from '../interface';

@Injectable()
export class RawIngredientsService {

  constructor(private httpService: HttpClient) {

  }

  getRawIngredientsList(): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}rawingredient`
    );
  }

  getRawIngredientByID(id: number): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}rawingredient/` + id
    );
  }

  addRawIngredient(ingredient: RawIngredient): Observable<any> {
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}rawingredient`, ingredient
    );
  }

  updateRawIngredient(ingredient: RawIngredient): Observable<any> {
    return this.httpService.put(
      `${RuntimeConstants.API_PATH}rawingredient`, ingredient
    );
  }

  deleteRawIngredient(id: number): Observable<any> {
    return this.httpService.delete(
      `${RuntimeConstants.API_PATH}rawingredient/` + id
    );
  }

  newrawIngredients() {
    const newrawIngredientsObj: RawIngredient = {
      Id: 0,
      Name: '',
      DateAdded: new Date(),
      DateEdited: null,
    };
    return newrawIngredientsObj;
  }

  getInventoryProduct(rawIngredientId: number) {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}rawingredient/` + rawIngredientId + `/inventoryproducts`
    );
  }

  getSalesProduct(rawIngredientId: number) {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}rawingredient/` + rawIngredientId + `/salesproducts`
    );
  }

  mergeRawIngredient(sourceIngredient: number, descIngredient: number) {
    return this.httpService.put(
      `${RuntimeConstants.API_PATH}rawingredient/` + sourceIngredient + `/merge/` + descIngredient, null
    );
  }
}
