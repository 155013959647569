import { IHaveId } from '../models';
import { BaseCrudService, AlertsService, FormUtilityService } from '../services';
import { OnInit, AfterContentInit, Directive } from '@angular/core';
import { catchError, finalize } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { SpinnerService } from './spinner/spinner.service';
import { BaseFormComponent } from './base-form.component';
@Directive()
export abstract class BaseCrudComponent<TModel extends IHaveId> extends BaseFormComponent implements OnInit, AfterContentInit {
    protected saveSuccessMessage: string;
    protected id: number;
    protected model: TModel;
    constructor(protected crudService: BaseCrudService<TModel>,
        protected alertService: AlertsService,
        protected spinnerService: SpinnerService,
        protected formUtilityService: FormUtilityService) {
        super(formUtilityService);
    }

    ngOnInit(): void { }

    ngAfterContentInit() {
        this.loadDependencies();
    }

    protected loadData(): Observable<TModel> {
        return this.crudService.get(this.id)
            .pipe(
                catchError(error => {
                    this.handleError(error);
                    return throwError(error);
                })
            );
    }

    protected save(model: TModel, file?: File, isMultipartRequest: boolean = false): void {
        this.spinnerService.show();
        this.crudService.insertOrUpdate(model, file, isMultipartRequest)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: x => {
                    this.onSaveSuccess(x);
                    this.showSuccessMessage();
                }, error: this.alertService.showApiError
            });
    }

    private showSuccessMessage(): void {
        this.alertService.renderSuccessMessage(this.saveSuccessMessage);
    }

    abstract onSaveSuccess(model: TModel): void;
    abstract loadDependencies(): void;
}
