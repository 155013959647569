<pos-list-wrapper [headingIcon]="icons.inventorySubCategoriesTh" heading="Inventory Subcategories" (close)="close()">
    <ng-table id="inventorySubCategories" [sort]="true" [exportFilename]="'inventory-subcategories'"
        [columns]="inventorySubCategoriesColumns" [data]="inventorySubCategories">
    </ng-table>
    <pos-confirm-delete (onConfirmAcknowledge)="onConfirmAcknowledge()" #confirmInventorySubCategory>
    </pos-confirm-delete>
</pos-list-wrapper>
<ng-template #inventorySubCategoryPopoverTemplate>
    <div class="">
        <ul>
            <li class="listbullet">
                <a class="grey" (click)="openNextInventorySubCategoryLogDateModal(inventorySubCategory)">
                    <pos-icon [icon]="icons.listAlt" class="pe-pix-3 m-l-5">
                    </pos-icon>
                    Next Inventory Log Date
                </a>
            </li>
        </ul>
    </div>
</ng-template>
<ng-template #popOverForInventorySubCategory let-data="rowData">
    <span class="mt-pix-n4 overflow-wrap">
        <span #pop="bs-popover" placement="left" (click)="onLinkPopOver(data,pop)" [outsideClick]="true"
            [popover]="inventorySubCategoryPopoverTemplate" triggers="click">
            <pos-action-icon [icon]="icons.barsWhite"></pos-action-icon>
        </span>
    </span>
</ng-template>
<pos-print-table [hidden]="true"></pos-print-table>
