import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ComponentsModule, TableConfiguration } from '../shared';
import { TableModule } from '@tarktech/tark-ng-utils';
import { TableModule as PrimengTableModule } from 'primeng/table';
import { NgSelectModule } from '@ng-select/ng-select';
import { DispatchComponent } from './components/dispatch/dispatch.component';
import { TerminalReportsComponent } from './components/terminal-reports/terminal-reports.component';
import { NgPipesModule } from 'ngx-pipes';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ModalModule.forRoot(),
    ComponentsModule,
    TableModule.forRoot(TableConfiguration),
    NgSelectModule,
    PrimengTableModule,
    NgPipesModule
  ],
  providers: [],
  declarations: [DispatchComponent, TerminalReportsComponent],
  exports: [DispatchComponent, TerminalReportsComponent]
})
export class DispatchModule { }
