<div class="m-0-auto list-width-80">
  <pos-list-wrapper [headingIcon]="icons.backgroundJobs" heading="Background Jobs" (close)="close()">
    <div class="row">
      <div class="col-md-12 text-end pb-pix-5 display-flex justify-content-end-flex align-items-center">
        <div class="row d-inline-block">
          <div class="inline-block ps-pix-10 pe-0 col-200  text-start">
            <ng-select [items]="entityTypes" bindLabel="name" bindValue="id" placeholder="Entity"
              [(ngModel)]="entityType" name="EntityType">
            </ng-select>
          </div>
          <div class="inline-block ps-pix-10 col-200">
            <input class="form-control" name="entityId" [(ngModel)]="entityId" placeholder="Entity Id">
          </div>
        </div>
        <div class="inline-block ps-pix-10">
          <button class="btn btn-primary normal text-nowrap white pos-button" (click)="getEntityJobDetails()">
            <pos-icon [icon]="icons.backgroundJobsSearch"></pos-icon> Search
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-sm-12">
        <div class="table-responsive">
          <ng-table id="HangfireJobs" [sort]="true" [columns]="hangfireJobsColumns" [data]="hangfireJobs"
            [scrollable]="true" [scrollHeight]="screenHeight.toString()">
          </ng-table>
          <ng-template let-data="rowData" #jobIdColumn>
            <div class="a-link">
              <a href="{{apiUrl}}/background-jobs/jobs/details/{{data.JobId}}" target="_blank"> {{ data.JobId }}
              </a>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </pos-list-wrapper>
</div>
<pos-print-table [hidden]="true"></pos-print-table>
