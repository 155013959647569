import { Component, Input } from '@angular/core';
import { KioskScreen, KioskScreenChoice, KioskScreenConfig, KioskScreenImage } from '../../interfaces';
import { DomainConstants } from 'src/app/shared/constants';
import { ScreenConfig } from '../../interfaces/config/screen-config';
import { GridConfig } from '../../interfaces/config/grid-config';
import { OrderKioskConfigService } from '../../services/order-kiosk-config.service';
import { AlertsService, ConfirmDeleteModalComponent, ExtensionValidationService, Messages, ModalComponent, ModalService, OrderKioskScreenService, RuntimeConstants, SpinnerService, StringUtils, } from 'src/app/shared';
import { allergiesLight, buttonsDiceOne, column, desktop, th, times } from 'src/app/shared/components/icon';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { cloneDeep, find } from 'lodash';
import moment from 'moment';
import { ButtonConfig } from '../../interfaces/config/button-config';
import { LeftPanelConfig, RightPanelConfig } from '../../interfaces/config/split-screen-config';
import { finalize } from 'rxjs/operators';
import { Options } from '@angular-slider/ngx-slider/options';
import { ShakeButtonConfig } from '../../interfaces/config/shake-button-config';
import { CreatedShakeScreenConfig } from '../../interfaces/config/created-shake-screen-config';
declare let $: any;

@Component({
  selector: 'pos-kiosk-screen-config-edit',
  templateUrl: './kiosk-screen-config-edit.component.html',
  styleUrls: ['./kiosk-screen-config-edit.component.scss']
})
export class KioskScreenConfigEditComponent extends ModalComponent {
  @Input() kioskScreen: KioskScreen;
  screenConfig: ScreenConfig = null;
  grid: GridConfig = null;
  gridCopy: GridConfig = null;
  buttonConfig: ButtonConfig;
  shakeButtonConfig: ShakeButtonConfig;
  secondaryButtonConfig: ButtonConfig;
  createdShakeScreenConfig: CreatedShakeScreenConfig;
  leftPanelConfig: LeftPanelConfig;
  rightPanelConfig: RightPanelConfig;
  containerHeight = window.innerHeight - 250 + 'px';
  horizontalAlignments = DomainConstants.AlignItemsHorizontal;
  horizontalAlignmentsForFlex = DomainConstants.HorizontalAlignmentsForFlex;
  alignItemsVertical = DomainConstants.VerticalAlignments;
  templateTypes = DomainConstants.KioskScreenTemplateTypes;
  imageRootPath = RuntimeConstants.IMAGE_BASE_PATH + "/order-kiosk/";
  kioskImages: Array<KioskScreenImage> = [null, null, null, null];
  options: Options = {
    floor: 0,
    ceil: 1,
    step: 0.01,
    translate: (value: number): string => {
      return '';
    }
  };

  codeMirrorOptions = {
    lineNumbers: true,
    name: 'htmlmixed',
    lineWrapping: true
  };
  toolbarConfig;

  screenButtonsConfiguration: KioskScreenConfig;
  icons = {
    allergiesLight, buttonsDiceOne, column, desktop, th, times
  }
  tabList = {
    Screen: 'Screen',
    Grid: 'Grid',
    Button: 'Button',
    Panel: 'Panel',
    Allergy: 'Allergy',
    AllergenButtons: 'AllergenButtons',
    Layout: 'Layout'
  }
  selectedTab = this.tabList.Screen;

  constructor(private orderKioskConfigService: OrderKioskConfigService,
    public modalRef: BsModalRef,
    private modalService: ModalService,
    private alertsService: AlertsService,
    private spinnerService: SpinnerService,
    private kioskScreenService: OrderKioskScreenService,
    private extensionValidation: ExtensionValidationService
  ) {
    super(modalRef);
  }

  ngOnInit() {
    this.toolbarConfig = {
      toolbar: [{ name: 'clipboard', items: ['Cut', 'Copy', 'Paste', '-', 'Undo', 'Redo'] },
      { name: 'basicstyles', items: ['Bold', 'Italic', 'Strike']},
      { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote'] },
      { name: 'styles', items: ['Format', 'Font', 'FontSize'] }
    ]};
    this.setConfigObjects();
  }

  setConfigObjects() {
    const screenConfig = this.orderKioskConfigService.parseConfigProperties(DomainConstants.KioskScreenConfigProperties.ScreenConfig, this.kioskScreen.KioskScreenConfigs);
    this.screenConfig = screenConfig ?? (this.kioskScreen.TemplateType == this.templateTypes.AllergensInquiryLayout ? this.orderKioskConfigService.newAllergenInquiry() : this.orderKioskConfigService.newScreenConfig());

    if (this.kioskScreen.TemplateType == this.templateTypes.FixedGridLayout) {
      this.prepareConfigObjectForFixedGridLayout();
    } else if (this.kioskScreen.TemplateType == this.templateTypes.FluidGridLayout) {
      this.prepareConfigObjectForFluidLayout();
    } else if (this.kioskScreen.TemplateType == this.templateTypes.SplitScreenLayout
      || this.kioskScreen.TemplateType == this.templateTypes.WelcomeScreenLayout
    ) {
      this.prepareConfigObjectForSplitScreenLayout();
    } else if (this.kioskScreen.TemplateType == this.templateTypes.BannerLayout) {
      this.prepareConfigObjectForBannerLayout();
    } else if (this.kioskScreen.TemplateType == this.templateTypes.AIShakeGroupSelectionTemplate) {
      this.prepareConfigObjectForAIShakeGroupSelectionLayout();
    } else if (this.kioskScreen.TemplateType == this.templateTypes.AIShakeCreatedTemplate) {
      this.prepareConfigObjectForAIShakeCreatedLayout();
    }
  }
  
  private prepareConfigObjectForFixedGridLayout() {
    const grid = this.orderKioskConfigService.parseConfigProperties(DomainConstants.KioskScreenConfigProperties.Grid, this.kioskScreen.KioskScreenConfigs);
    this.grid = grid ?? this.orderKioskConfigService.newGridConfig();
    this.gridCopy = cloneDeep(this.grid);

    const buttonConfig = this.orderKioskConfigService.parseConfigProperties(DomainConstants.KioskScreenConfigProperties.ButtonConfig, this.kioskScreen.KioskScreenConfigs);
    this.buttonConfig = buttonConfig ?? this.orderKioskConfigService.newButtonConfig(null, 0);
  }

  private prepareConfigObjectForSplitScreenLayout() {
    const leftPanelConfig = this.orderKioskConfigService.parseConfigProperties(DomainConstants.KioskScreenConfigProperties.LeftPanelConfig, this.kioskScreen.KioskScreenConfigs);
    this.leftPanelConfig = leftPanelConfig ?? this.orderKioskConfigService.newLeftPanelConfig();

    const rightPanelConfig = this.orderKioskConfigService.parseConfigProperties(DomainConstants.KioskScreenConfigProperties.RightPanelConfig, this.kioskScreen.KioskScreenConfigs);
    this.rightPanelConfig = rightPanelConfig ?? this.orderKioskConfigService.newRightPanelConfig();
  }

  private prepareConfigObjectForFluidLayout() {
    const grid = this.orderKioskConfigService.parseConfigProperties(DomainConstants.KioskScreenConfigProperties.Grid, this.kioskScreen.KioskScreenConfigs);
    this.grid = grid ?? this.orderKioskConfigService.newGridConfig();
    this.gridCopy = cloneDeep(this.grid);

    const buttonConfig = this.orderKioskConfigService.parseConfigProperties(DomainConstants.KioskScreenConfigProperties.ButtonConfig, this.kioskScreen.KioskScreenConfigs);
    this.buttonConfig = buttonConfig ?? this.orderKioskConfigService.newButtonConfig(200, 200);

    const secondaryButtonConfig = this.orderKioskConfigService.parseConfigProperties(DomainConstants.KioskScreenConfigProperties.SecondaryButtonConfig, this.kioskScreen.KioskScreenConfigs);
    this.secondaryButtonConfig = secondaryButtonConfig ?? this.orderKioskConfigService.newButtonConfig(50, 300);
  }

  private prepareConfigObjectForBannerLayout() {
    const buttonConfig = this.orderKioskConfigService.parseConfigProperties(DomainConstants.KioskScreenConfigProperties.ButtonConfig, this.kioskScreen.KioskScreenConfigs);
    this.buttonConfig = buttonConfig ?? this.orderKioskConfigService.newButtonConfig();
  }

  private prepareConfigObjectForAIShakeGroupSelectionLayout() {
    const buttonConfig = this.orderKioskConfigService.parseConfigProperties(DomainConstants.KioskScreenConfigProperties.ButtonConfig, this.kioskScreen.KioskScreenConfigs);
    this.shakeButtonConfig = buttonConfig ?? this.orderKioskConfigService.newShakeButtonConfig();
  }

  private prepareConfigObjectForAIShakeCreatedLayout() {
    const createdShakeScreenConfig = this.orderKioskConfigService.parseConfigProperties(DomainConstants.KioskScreenConfigProperties.CreatedShakeScreenConfig, this.kioskScreen.KioskScreenConfigs);
    this.createdShakeScreenConfig = createdShakeScreenConfig ?? this.orderKioskConfigService.newCreatedShakeScreenConfig();
  }

  save(isValid) {
    if (!isValid) {
      return;
    }
    const buttonsDiffCount = this.kioskScreen.TemplateType == this.templateTypes.FluidGridLayout ?this.calculateButtonDifference() : 0;
    if (this.grid && buttonsDiffCount > 0) {
      this.openWarningModal(buttonsDiffCount);
    } else
      this.saveScreenConfiguration(buttonsDiffCount);
  }

  calculateButtonDifference() {
    const oldButtonCount = (this.gridCopy?.NoOfRows ?? 1) * this.gridCopy.NoOfCols;
    const newButtonCount = (this.grid.NoOfRows ?? 1) * this.grid.NoOfCols;
    return oldButtonCount - newButtonCount;
  }

  private saveScreenConfiguration(buttonsDiffCount: number) {
    this.prepareConfigArray(buttonsDiffCount);
    this.kioskScreen.ButtonToDeleteCount = buttonsDiffCount;
    this.spinnerService.show();
    this.kioskScreenService.saveScreenDetails(this.kioskScreen, this.kioskImages.filter(x => x != null))
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.alertsService.renderSuccessMessage(Messages.KioskScreenConfigSaveSuccess);
        }, error: this.alertsService.showApiError,
        complete: () => {
          this.hide({ shouldReload: true });
        }
      });
  }

  prepareConfigArray(buttonsDiffCount: number) {
    if (this.screenConfig) {
      const configName = DomainConstants.KioskScreenConfigProperties.ScreenConfig;
      this.setConfig(configName, this.screenConfig);
    }
    if (this.grid) {
      const configName = DomainConstants.KioskScreenConfigProperties.Grid;
      this.setConfig(configName, this.grid);
    }

    if (this.buttonConfig) {
      const configName = DomainConstants.KioskScreenConfigProperties.ButtonConfig;
      this.setConfig(configName, this.buttonConfig);
    }

    if (this.secondaryButtonConfig) {
      const configName = DomainConstants.KioskScreenConfigProperties.SecondaryButtonConfig;
      this.setConfig(configName, this.secondaryButtonConfig);
    }

    if (this.leftPanelConfig) {
      const configName = DomainConstants.KioskScreenConfigProperties.LeftPanelConfig;
      this.setConfig(configName, this.leftPanelConfig);
    }

    if (this.rightPanelConfig) {
      const configName = DomainConstants.KioskScreenConfigProperties.RightPanelConfig;
      this.setConfig(configName, this.rightPanelConfig);
    }

    if (this.shakeButtonConfig) {
      const configName = DomainConstants.KioskScreenConfigProperties.ButtonConfig;
      this.setConfig(configName, this.shakeButtonConfig);
    }

    if (this.createdShakeScreenConfig) {
      const configName = DomainConstants.KioskScreenConfigProperties.CreatedShakeScreenConfig;
      this.setConfig(configName, this.createdShakeScreenConfig);
    }

    const templatesHavingButtons = [this.templateTypes.FixedGridLayout, this.templateTypes.FluidGridLayout];
    if (templatesHavingButtons.includes(this.kioskScreen.TemplateType)) {
      const configName = DomainConstants.KioskScreenConfigProperties.Buttons;
      this.setConfig(configName, null, DomainConstants.KioskScreenConfigTypes.Buttons);

      this.setKioskButtons(buttonsDiffCount);
    }
  }

  private setConfig(configName: string, configuration, configType: string = null) {
    let config = this.getConfigElement(configName, configType);
    config.ScreenId = this.kioskScreen.Id;
    config.Config = configuration ? JSON.stringify(configuration) : null;
  }

  setKioskButtons(buttonsDiffCount: number) {
    buttonsDiffCount = Math.abs(buttonsDiffCount);
    const noOfElements = this.grid.NoOfRows ? this.grid.NoOfRows * this.grid.NoOfCols : 1; 
    const emptyScreenChoices = new Array<KioskScreenChoice>(buttonsDiffCount > 0 ? buttonsDiffCount : noOfElements);
    this.kioskScreen.KioskScreenChoices = buttonsDiffCount > 0 ? [...this.kioskScreen.KioskScreenChoices, ...emptyScreenChoices] : emptyScreenChoices;
  }

  getConfigElement(configName: string, configType: string = null) {
    if (!this.kioskScreen.KioskScreenConfigs) this.kioskScreen.KioskScreenConfigs = [];
    let config = find(this.kioskScreen.KioskScreenConfigs, x => x.Name == configName);
    if (!config) {
      config = this.orderKioskConfigService.newKioskScreenConfig(configName, configType);
      this.kioskScreen.KioskScreenConfigs.push(config);
    } return config;
  }

  openWarningModal(buttonCount: number) {
    const modalRef = this.modalService.show(ConfirmDeleteModalComponent, {
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      initialState: {
        message: StringUtils.format(Messages.KioskScreenConfigChangeWarning, { type: 'grid' })
      }
    });
    modalRef.close.subscribe((res) => {
      if (res.shouldDelete) {
        this.saveScreenConfiguration(buttonCount);
      }
    });
  }

  handleFileInput(files: FileList, previewName: string, inputName: string) {
    const image = files[0];
    const timestamp = moment(Date.now()).format('yyyyMMDDHHmmssSSSS');
    const imageName = `${timestamp}-${image.name}`;

    const fileToUpload = image ? imageName : '';
    if (this.extensionValidation.isInvalidExtension(fileToUpload)) {
      if (fileToUpload) {
        this.alertsService.renderErrorMessage(Messages.InvalidReceiptLogoFileType);
      }
      this.removeIcon(inputName);
      return;
    }
    if (files?.[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        $('#' + previewName).attr('src', reader.result);
      };
      reader.readAsDataURL(files[0]);
      if (previewName == 'backgroundImage') {
        this.screenConfig.BackgroundImage = image ? imageName : '';
        this.addImageToImageArray(0, imageName, image);
      } else if (previewName == 'headerImage') {
        this.rightPanelConfig.Header.Image = image ? imageName : '';
        this.addImageToImageArray(1, imageName, image);
      } else if (previewName == 'screenHeaderImagePreview') {
        this.screenConfig.Header.BackgroundImage = image ? imageName : '';
        this.addImageToImageArray(2, imageName, image);
      } else if (previewName == 'screenFooterImagePreview') {
        this.screenConfig.Footer.BackgroundImage = image ? imageName : '';
        this.addImageToImageArray(3, imageName, image);
      }
    }
  }

  addImageToImageArray(index: number, imageName: string, imageFile: File) {
    this.kioskImages[index] = { ImageName: imageName, ImageFile: imageFile };
  }

  removeIcon(imageInputName: string) {
    $('#' + imageInputName).val('');
  }

  headerConfigChanged() {
    if (!this.screenConfig.Header.IsShow) {
      this.screenConfig.Header.BackgroundColor = null;
      this.screenConfig.Header.BackgroundImage = null;
      this.screenConfig.Header.Title = null;
    }
  }

  onCancel() {
    this.hide({});
  }
}
