import { Component, OnInit, ViewChild } from '@angular/core';
import { InventoryCategoryService } from '../../service/inventory-category.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { NgForm } from '@angular/forms';
import { InventoryCategory } from '../../interface/inventory-category';
import { finalize } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormUtilityService, Messages, ModalService } from 'src/app/shared';
import { ModalFormComponent } from 'src/app/shared/components/modal-form/modal-form.component';

@Component({
    selector: 'pos-inventory-category-edit',
    templateUrl: './inventory-category-edit.component.html',
})
export class InventoryCategoryEditComponent extends ModalFormComponent implements OnInit {

    @ViewChild('form', { static: true }) form: NgForm;
    get getForm(): NgForm {
        return this.form
    }
    inventoryCategoryId: number;
    inventoryCategory: InventoryCategory;
    constructor(private alertService: AlertsService,
        private spinnerService: SpinnerService,
        private inventoryCategoryService: InventoryCategoryService,
        public modalService: ModalService,
        private inventoryCategoryModalRef: BsModalRef,
        formUtilityService: FormUtilityService) {
        super(inventoryCategoryModalRef, modalService, formUtilityService);
    }

    ngOnInit() {
        this.inventoryCategory = this.inventoryCategoryService.newInventoryCategory();
        this.getInventoryCategoryDetails();
        this.setInitialValue();
    }

    private getInventoryCategoryDetails() {
        if (this.inventoryCategoryId > 0) {
            this.spinnerService.show();
            this.inventoryCategoryService.getInventoryCategoryDetails(this.inventoryCategoryId)
                .pipe(finalize(() => {
                    this.spinnerService.hide();
                }))
                .subscribe({
                    next: (inventoryCategory: InventoryCategory) => {
                        this.inventoryCategory = inventoryCategory;
                    }, error: this.alertService.showApiError
                });
        }
    }
    cancelEditing() {
        this.hide({ shouldReload: false });
    }
    saveInventoryCategory(isValid: boolean) {
        if (!isValid || (this.inventoryCategory.days_between_inventory < 0 || this.inventoryCategory.days_between_inventory > 99)) {
            return;
        }
        this.spinnerService.show();
        this.inventoryCategoryService.saveInventoryCategory(this.inventoryCategory)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (inventoryCategory: InventoryCategory) => {
                    this.alertService.renderSuccessMessage(Messages.InventoryCategorySaveSuccess);
                    this.inventoryCategoryId = 0;
                    this.inventoryCategory = inventoryCategory;
                    this.hide({ shouldReload: true });
                }, error: this.alertService.showApiError
            });
    }
}
