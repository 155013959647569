<div class="m-0-auto width-80">
    <pos-edit-wrapper heading="Inventory Transfer" [headingIcon]="icons.dollyFlatbedAlt" (close)="cancel()"
        [isPopup]="false">
        <form #transferInventoryForm="ngForm" (ngSubmit)="submitTransferInventory(transferInventoryForm.valid)">
            <div class="col-12 pos-page-body border-top pt-pix-5">
                <pos-row class="mx-0">
                    <pos-column xl="3" md="4" sm="9" xs="12" class="p-0">
                        <pos-form-field label="From Location" inputStyle="col-12"
                            [validationMessages]="{ required: 'Please select from location.' }">
                            <ng-select posInput [items]="fromLocations" bindLabel="Name" bindValue="Id"
                                placeholder="Select From Location" [(ngModel)]="transferInventory.FromLocation"
                                appendTo="body" name="fromLocation" [required]="true" (change)="fromLocationChanged()">
                            </ng-select>
                        </pos-form-field>
                    </pos-column>
                    <pos-column xl="3" md="4" sm="9" xs="12" class="pe-0 ps-pix-0 ps-pix-md-15">
                        <pos-form-field label="To Location" inputStyle="col-12"
                            [validationMessages]="{ required: 'Please select to location.' }">
                            <ng-select posInput [items]="toLocations" bindLabel="Name" bindValue="Id"
                                placeholder="Select To Location" [(ngModel)]="transferInventory.ToLocation"
                                appendTo="body" name="toLocation" [required]="true"
                                [disabled]="transferInventory.FromLocation == null">
                            </ng-select>
                        </pos-form-field>
                    </pos-column>
                </pos-row>
                <pos-row class="mx-0">
                    <pos-column xl="3" md="4" sm="9" xs="12" class="p-0">
                        <pos-form-field label="Inventory Product" inputStyle="col-12"
                            [validationMessages]="{ required: 'Please select inventory product.' }">
                            <ng-select posInput [items]="filteredInventoryProducts" bindLabel="Name" bindValue="Id"
                                placeholder="Select Product" [(ngModel)]="transferInventory.InventoryProductId"
                                appendTo="body" name="inventoryProductInput" [required]="true" #inventoryProductInput
                                [disabled]="transferInventory.FromLocation == null"
                                (change)="inventoryProductSelected()">
                            </ng-select>
                        </pos-form-field>
                    </pos-column>
                    <pos-column xl="4" md="4" sm="9" xs="12" class="pe-0 ps-pix-0 ps-pix-md-15 pt-pix-5 pt-pix-md-35"
                        *ngIf="transferInventory.InventoryProductId">
                        <label class="edit-form__section__label bold">
                            {{ (selectedProductQty | number: '0.2-4') + ' ' + (selectedInventoryProduct?.UnitName ?
                            Pluralize(selectedInventoryProduct?.UnitName,
                            selectedProductQty) : '') }} </label>
                        <label class="edit-form__section__label ps-pix-4"> {{ selectedProductQty ? ' available' : ''
                            }}</label>
                    </pos-column>
                </pos-row>
                <pos-row class="mx-0">
                    <pos-column xs="4" class="col-135 p-0">
                        <pos-form-field label="Qty" inputStyle="col-12"
                            [validationMessages]="{ required: 'Please enter qty.', min: 'Qty to transfer should be a positive number.', max: selectedInventoryProduct ? ('Insufficient available qty (' + (selectedProductQty | number: '0.2-4') + (selectedInventoryProduct?.UnitName ? ' ' + Pluralize(selectedInventoryProduct?.UnitName, selectedProductQty) : '') + ').') : '' }">
                            <input posInput class="form-control col-120" name="qty" #qty="ngModel" type="number"
                                [(ngModel)]="transferInventory.Qty" step="1" [min]="0.0001" [required]="true"
                                [max]="maxAllowedQty" [disabled]="transferInventory.FromLocation == null"
                                (ngModelChange)="maxAllowedQty = selectedProductQty/(!transferInventory.OverriddenQty ? selectedInventoryProduct?.UnitQty : transferInventory.OverriddenQty)" />
                        </pos-form-field>
                    </pos-column>
                    <pos-column sm="6" xs="12" class="p-0 ps-pix-0 ps-pix-sm-15 pt-pix-5 pt-pix-sm-35"
                        *ngIf="selectedInventoryProduct">
                        <label class="edit-form__section__label">
                            Purchase Unit(s)
                        </label>
                        <span class="badge badge-info ms-pix-5 mt-pix-n2"> {{ ((transferInventory.OverriddenQty ??
                            selectedInventoryProduct.UnitQty)
                            * transferInventory.Qty) | number: '0.2-4' }}
                            {{ selectedInventoryProduct?.UnitName ? Pluralize(selectedInventoryProduct?.UnitName,
                            selectedProductQty) : '' }}
                        </span>
                    </pos-column>
                </pos-row>
                <pos-row class="mx-0" *ngIf="selectedInventoryProduct">
                    <pos-column class="p-0">
                        <p class="mb-0 pt-pix-10 ps-pix-5">
                            <span class="bold">Unit Qty:</span> {{ selectedInventoryProduct.UnitQty }}
                        </p>
                        <p class="mb-0 pt-pix-3 ps-pix-5" *ngIf="selectedInventoryProduct.UnitDetails"> {{
                            selectedInventoryProduct.UnitDetails }}</p>
                    </pos-column>
                </pos-row>
                <pos-row>
                    <pos-column>
                        <fieldset class="fieldset ps-pix-10">
                            <legend class="reset-style"><span class="px-pix-5">Optional</span></legend>
                            <pos-form-field label="Override Qty to"
                                labelStyle="col-2 edit-form__section__label col-120 pe-0" inputStyle="col-sm-8 col-12 pe-0"
                                [validationMessages]="{ min: 'Overridden qty should be a positive number.'}"
                                [appendPaddingTop]="false">
                                <pos-row colXs="12" class="p-0">
                                    <pos-column class="col-150">
                                        <input posInput class="form-control col-120" name="productQty"
                                            #productQty="ngModel" type="number"
                                            [(ngModel)]="transferInventory.OverriddenQty" step="1" [min]="0.01"
                                            [disabled]="transferInventory.FromLocation == null"
                                            (ngModelChange)="updateAndValidateQtyInput()" />
                                    </pos-column>
                                    <pos-column class="col-75" *ngIf="selectedInventoryProduct">
                                        <label class="edit-form__section__label col-75">{{
                                            selectedInventoryProduct?.UnitName ?
                                            Pluralize(selectedInventoryProduct?.UnitName,
                                            transferInventory.OverriddenQty) : selectedInventoryProduct?.UnitName }}
                                        </label>
                                    </pos-column>
                                </pos-row>
                            </pos-form-field>
                        </fieldset>
                    </pos-column>
                </pos-row>
            </div>
            <div class="col-12 action-button-container page-footer">
                <button class="btn btn-lg btn-primary btn-save pos-button" type="submit">Transfer
                </button>
                <button class="btn btn-lg btn-secondary btn-cancel pos-button" (click)="cancel()" type="button">Close
                </button>
            </div>
        </form>
    </pos-edit-wrapper>
</div>
