<pos-edit-wrapper heading="Ordering" (close)="cancel()" [headingIcon]="icons.orderBuilding" [isPopup]="false">
	<form (ngSubmit)="saveOrderWorkflow(orderWorkflowForm.valid)" #orderWorkflowForm="ngForm" class="edit-form">
		<div class="border-top content-scroll pt-pix-5">
			<div class="col-12 pos-page-body">
				<pos-row>
					<pos-column>
						<pos-form-field inputStyle="d-flex">
							<p-checkbox posInput name="chkAllowOverridesOnDietaryRestrictions"
								#chkAllowOverridesOnDietaryRestrictions="ngModel"
								[(ngModel)]="orderWorkflowParameters.AllowOverridesOnDietaryRestrictions" binary="true"
								label="Allow Overrides on Dietary Restrictions">
							</p-checkbox>
							<field-info [info]="fieldInfoMessages.WorkflowAllowOverrideDietaryRestrictions"
								styleClass="pt-0"></field-info>
						</pos-form-field>
					</pos-column>
					<pos-column>
						<pos-form-field inputStyle="d-flex">
							<p-checkbox posInput name="chkIsSendOrderToKitchenImmediately"
								#chkIsSendOrderToKitchenImmediately="ngModel"
								[(ngModel)]="orderWorkflowParameters.IsSendOrderToKitchenImmediately" binary="true"
								label="Send order to kitchen immediately">
							</p-checkbox>
							<field-info [info]="fieldInfoMessages.WorkflowSendOrderToKitchenImmediately"
								styleClass="pt-0"></field-info>
						</pos-form-field>
					</pos-column>
					<pos-column>
						<pos-form-field>
							<p-checkbox posInput name="chkIsAutoServeNonPrintingItems"
								#chkIsAutoServeNonPrintingItems="ngModel"
								[(ngModel)]="orderWorkflowParameters.IsAutoServeNonPrintingItems" binary="true"
								label="Auto serve non-printing items">s
							</p-checkbox>
						</pos-form-field>
					</pos-column>
					<pos-column>
						<pos-form-field>
							<p-checkbox posInput name="chkIsShowReviewOrder" #chkIsShowReviewOrder="ngModel"
								[(ngModel)]="orderWorkflowParameters.IsShowReviewOrder" binary="true"
								label="Show review order">
							</p-checkbox>
						</pos-form-field>
					</pos-column>
					<pos-column>
					<pos-form-field>
						<p-checkbox posInput name="chkVerifyOrderBeforeSettle"
							#chkVerifyOrderBeforeSettle="ngModel"
							[(ngModel)]="orderWorkflowParameters.VerifyOrderBeforeSettle" binary="true"
							label="Verify order before settlement">
						</p-checkbox>
					</pos-form-field>
					</pos-column>
					<pos-column>
						<pos-form-field>
							<p-checkbox posInput name="chkAutoServeOrder" #chkAutoServeOrder="ngModel"
								[(ngModel)]="orderWorkflowParameters.AutoServeOrder" binary="true"
								label="Auto Serve Order">
							</p-checkbox>
						</pos-form-field>
					</pos-column>
					<pos-column>
						<pos-form-field>
							<p-checkbox posInput name="chkRecallEntireOrder" #chkRecallEntireOrder="ngModel"
								[(ngModel)]="orderWorkflowParameters.RecallEntireOrder" binary="true"
								label="Recall entire order">
							</p-checkbox>
						</pos-form-field>
					</pos-column>
					<pos-column>
						<pos-form-field>
							<!-- ShowAllOrders flag filter active orders based on cash drawer -->
							<p-checkbox posInput name="chkShowAllOrders" #chkShowAllOrders="ngModel"
								[(ngModel)]="orderWorkflowParameters.ShowAllOrders" binary="true"
								label="Show all orders on all terminals">
							</p-checkbox>
						</pos-form-field>
					</pos-column>
					<pos-column>
						<pos-form-field>
							<!-- ShowOwnOrdersOnly flag filter active orders based on user id -->
							<p-checkbox posInput name="chkShowOwnOrdersOnly" #chkShowOwnOrdersOnly="ngModel"
								[(ngModel)]="orderWorkflowParameters.ShowOwnOrdersOnly" binary="true"
								label="Show own orders only">
							</p-checkbox>
						</pos-form-field>
					</pos-column>
					<pos-column class="ps-4">
						<pos-form-field>
							<p-checkbox posInput name="chkSettlePersonalOrdersOnly"
								#chkSettlePersonalOrdersOnly="ngModel"
								[(ngModel)]="orderWorkflowParameters.SettlePersonalOrdersOnly" binary="true"
								[disabled]="!orderWorkflowParameters.ShowOwnOrdersOnly" label="Settle own orders only">
							</p-checkbox>
						</pos-form-field>
					</pos-column>
					<pos-column>
						<pos-form-field [validationMessages]="{ required: 'Please enter value.'}"
							inputStyle="d-flex flex-nowrap">
							<label class="edit-form__section__label pull-left pe-pix-15">
								Allow scheduling an order till next
							</label>
							<span class="col-xs-2 col-120 pe-pix-15"
								[ngClass]="{'has-error': maxScheduledOrderHours.errors?.required && orderWorkflowForm.submitted}">
								<input posInput class="form-control" min="1" step="1" name="maxScheduledOrderHours"
									posOnlyNumbers [(ngModel)]="orderWorkflowParameters.MaxScheduledOrderHours"
									type="number" required #maxScheduledOrderHours="ngModel" />
							</span>
							<label class="edit-form__section__label"> hours</label>
						</pos-form-field>
					</pos-column>
					<pos-column>
						<pos-form-field>
							<p-checkbox posInput name="chkCloseSettleAfterFullPayment"
								#chkCloseSettleAfterFullPayment="ngModel" label="Close Settle screen after full payment"
								[(ngModel)]="orderWorkflowParameters.CloseSettleAfterFullPayment" binary="true">
							</p-checkbox>
						</pos-form-field>
					</pos-column>
					<pos-column>
						<pos-form-field>
							<p-checkbox posInput name="showCheckmarkOnToggleButton"
								#showCheckmarkOnToggleButton="ngModel" label="Show check mark on toggle button"
								[(ngModel)]="orderWorkflowParameters.IsShowCheckmarkOnToggleButton" binary="true">
							</p-checkbox>
						</pos-form-field>
					</pos-column>
					<pos-column>
						<pos-form-field>
							<p-checkbox posInput name="changeBackgroundColorOnToggleButton"
								#changeBackgroundColorOnToggleButton="ngModel"
								[(ngModel)]="orderWorkflowParameters.ChangeBackgroundColorOnToggleButton" binary="true"
								label="Change background color on toggle button">
							</p-checkbox>
						</pos-form-field>
					</pos-column>
					<pos-column>
						<pos-form-field>
							<p-checkbox posInput name="chkAllowModifyingSubaccountNameAtOrderInvoice"
								#chkAllowModifyingSubaccountNameAtOrderInvoice="ngModel"
								[(ngModel)]="orderWorkflowParameters.AllowModifyingSubaccountNameAtOrderInvoice"
								binary="true" label="Allow setting name for {{subAccountTerm}}">
							</p-checkbox>
						</pos-form-field>
					</pos-column>
					<pos-column>
						<pos-form-field>
							<p-checkbox posInput name="chkShowTipsInTerminalInfo" #chkShowTipsInTerminalInfo="ngModel"
								label="Show tips in terminal information"
								[(ngModel)]="orderWorkflowParameters.ShowTipsInTerminalInfo" binary="true">
							</p-checkbox>
						</pos-form-field>
					</pos-column>
					<pos-column>
						<pos-form-field>
							<p-checkbox posInput name="chkPager" #chkPager="ngModel" (onChange)="changeUsePager()"
								[(ngModel)]="orderWorkflowParameters.IsUsePager" binary="true" label="Use Pager">
							</p-checkbox>
						</pos-form-field>
					</pos-column>
					<pos-column>
						<pos-row colXs="4" colStyleClass="col-120">
							<pos-column class="col-170 ps-4">
								<pos-form-field label="Pager Range" inputStyle="col-120"
									[validationMessages]="{min: 'Please enter value greater than 0.'}">
									<input class="form-control col-120" type="number" placeholder="Min" min="1"
										[(ngModel)]="pagerStartNumber" name="pagerMinNumber" posOnlyNumbers
										[disabled]="!orderWorkflowParameters.IsUsePager" />
								</pos-form-field>
							</pos-column>
							<pos-column class="pt-pix-19">
								<pos-form-field label=" "
									[validationMessages]="{min: 'Please enter value greater than minimum pager range.', max: 'Please enter proper range. Maximum range 50.'}">
									<input class="form-control col-120" placeholder="Max" type="number" posOnlyNumbers
										min="{{pagerStartNumber ? pagerStartNumber: 1}}" max="{{pagerStartNumber + 49}}"
										#endNumber="ngModel" [(ngModel)]="pagerEndNumber" name="pagerMaxNumber"
										[disabled]="!orderWorkflowParameters.IsUsePager" />
								</pos-form-field>
							</pos-column>
						</pos-row>
					</pos-column>
					<pos-column>
						<pos-form-field label="Maximum allowed discount" labelStyle="col-xs-12"
							[fieldInfo]="fieldInfoMessages.WorkflowMaximumAllowedDiscount"
							[validationMessages]="{required: 'Please enter percentage.', range: 'Invalid percentage.'}">
							<p class="input-group workflow-variance flex-nowrap col-120">
								<input posInput class="form-control text-right price-input" [required]="true"
									name="MaximumAllowedDiscount" [range]="[0, 100]"
									[(ngModel)]="orderWorkflowParameters.MaximumAllowedDiscount" type="number" min="0"
									max="100" step="1" #maximumAllowedDiscount="ngModel" posOnlyNumbers />
								<span class="input-group-addon">%</span>
							</p>
						</pos-form-field>
					</pos-column>
					<pos-column>
						<pos-form-field>
							<p-checkbox posInput name="chkAllowOrderingFractionalQty"
								#chkAllowOrderingFractionalQty="ngModel" label="Allow Ordering Fractional Qty"
								[(ngModel)]="orderWorkflowParameters.AllowOrderingFractionalQty" binary="true">
							</p-checkbox>
						</pos-form-field>
					</pos-column>
					<pos-column>
						<pos-form-field label="Quantity change">
							<ng-select posInput [items]="quantityChangeOptions" bindLabel="Name" bindValue="Name"
								[(ngModel)]="orderWorkflowParameters.QuantityChange" name="quantityChange"
								class="col-120" #quantityChange="ngModel" [clearable]="false"
								[clearOnBackspace]="false">
							</ng-select>
						</pos-form-field>
					</pos-column>
					<pos-column>
						<pos-form-field label="Associate Online orders to Terminal">
							<ng-select posInput [items]="orderEntryTerminals" bindLabel="TerminalName" class="col-300"
								bindValue="TerminalId" [(ngModel)]="orderWorkflowParameters.OnlineOrderTerminalId"
								name="orderEntryTerminal" #orderEntryTerminal="ngModel" [clearable]="false"
								[clearOnBackspace]="false">
							</ng-select>
						</pos-form-field>
					</pos-column>
					<pos-column>
						<pos-row colXs="4" colStyleClass="col-120">
							<pos-column class="col-275 pe-5">
								<pos-form-field label="Barcode Initiator Sequence"
									[validationMessages]="{required: 'Please enter barcode initiator sequence.'}">
									<input posInput class="form-control col-150" name="txtBarcodeInitiatorSequence"
										#initiatorSequence="ngModel" [(ngModel)]="orderWorkflowParameters.BarcodeScanningInitiatorSequence"
										[required]="true" type="text" />
								</pos-form-field>
							</pos-column>
							<pos-column class="col-275">
								<pos-form-field label="Barcode Ending Sequence"
									[validationMessages]="{required: 'Please enter barcode ending sequence.'}">
									<input posInput class="form-control col-150" name="txtBarcodeEndingSequence"
										#endingSequence="ngModel" [(ngModel)]="orderWorkflowParameters.BarcodeScanningEndingSequence"
										[required]="true" type="text" />
								</pos-form-field>
							</pos-column>
						</pos-row>
					</pos-column>
				</pos-row>
				<div class="col-12 section-border p-0">
					<div>
						<h3 class="setting-title">Prompt</h3>
					</div>
					<pos-row class="pos-title-content">
						<pos-column class="pb-2">
							<pos-form-field>
								<p-checkbox posInput name="chkPromptOrder" #chkPromptOrder="ngModel"
									(onChange)="promptOrders()" [(ngModel)]="isOrderPrompt" binary="true"
									label="Prompt on New Order" [required]="isOrderPrompt">
								</p-checkbox>
							</pos-form-field>
						</pos-column>
						<pos-column class="ps-4 pb-2" *ngFor="let orderPrompt of promptServeMethods; let i=index;">
							<pos-form-field [appendPaddingTop]="0">
								<p-checkbox posInput name="promptOrdertype_{{i}}" #promptOrdertype_{{i}}="ngModel"
									(onChange)="checkOrderPromptsValidation()" [(ngModel)]="orderPrompt.isSelected"
									binary="true" [disabled]="!isOrderPrompt" [label]="orderPrompt.Name">
								</p-checkbox>
							</pos-form-field>
						</pos-column>
						<span class="red-extreme ps-pix-20" *ngIf="isOrderPrompt && !isValidOrderPrompts">Please
							select at least one option</span>
						<pos-column class="pb-2">
							<pos-form-field label="Require Name on Order">
								<ng-select posInput [items]="requireNameOnOrderOptions" bindLabel="Name"
									bindValue="Name" [(ngModel)]="orderWorkflowParameters.RequireNameOnOrder"
									name="requireNameOnOrder" [clearable]="false" [clearOnBackspace]="false"
									class="col-140">
								</ng-select>
							</pos-form-field>
						</pos-column>
					</pos-row>
				</div>
				<div class="col-12 section-border p-0">
					<div>
						<h3 class="setting-title">Virtual Order Number Settings</h3>
					</div>
					<pos-row class="pos-title-content">
						<pos-column>
							<pos-form-field label="Order Number Increment"
								[validationMessages]="{ required: 'Please enter value.'}">
								<input posInput class="form-control col-120" name="OrderNumberIncrement"
									[(ngModel)]="orderWorkflowParameters.OrderNumberIncrement" type="number"
									[required]="true" posOnlyNumbers />
							</pos-form-field>
						</pos-column>
						<pos-column>
							<pos-form-field>
								<p-checkbox posInput name="showOrderNumberAfterPayment"
									#showOrderNumberAfterPayment="ngModel" label="Show Order Number After Payment"
									[(ngModel)]="orderWorkflowParameters.ShowOrderNumberAfterPayment" binary="true">
								</p-checkbox>
							</pos-form-field>
						</pos-column>
					</pos-row>
				</div>
				<div class="col-xs-12 section-border">
					<div>
						<h3 class="setting-title">Auto Sign-out when</h3>
					</div>
					<pos-row class="pos-title-content">
						<pos-column>
							<pos-form-field>
								<p-checkbox posInput name="AutoSignOutOnOrderSubmitted" label="Order is Submitted"
									[(ngModel)]="orderWorkflowParameters.AutoSignOutOnOrderSubmitted" binary="true">
								</p-checkbox>
							</pos-form-field>
						</pos-column>
						<pos-column>
							<pos-form-field>
								<p-checkbox posInput name="AutoSignOutOnPaymentCompleted" label="Payment is Completed"
									[(ngModel)]="orderWorkflowParameters.AutoSignOutOnPaymentCompleted" binary="true">
								</p-checkbox>
							</pos-form-field>
						</pos-column>
						<pos-column>
							<pos-form-field inputStyle="col-xs-12"
								[validationMessages]="{min: 'Idle seconds should be greater than zero.'}">
								<pos-row>
									<pos-column xs="3" class="pt-pix-7 col-140 pe-0 mb-1">
										<p-checkbox name="isAutoSignOutOnUserIdle" label="User is Idle for"
											[(ngModel)]="isAutoSignOutOnUserIdle" binary="true"
											(onChange)="orderWorkflowParameters.AutoSignOutOnUserIdleSeconds = 0;">
										</p-checkbox>
									</pos-column>
									<pos-column xs="3" class="col-120">
										<input posInput type="number" min="1" name="AutoSignOutOnUserIdleSeconds"
											class="form-control"
											[(ngModel)]="orderWorkflowParameters.AutoSignOutOnUserIdleSeconds"
											[disabled]="!isAutoSignOutOnUserIdle" />
									</pos-column>
									<pos-column xs="3" class="ps-2 col-60">
										<label class="label-section pt-pix-7">seconds</label>
									</pos-column>
								</pos-row>
							</pos-form-field>
						</pos-column>
					</pos-row>
				</div>
				<div class="form-group mb-0">
					<div class="col-12 section-border p-0">
						<div>
							<h3 class="setting-title">Daily Schedule</h3>
						</div>
						<div class="pos-title-content overflow-auto">
							<pos-row class="mx-0 overflow-auto">
								<pos-column>
									<pos-row colXs="2" colStyleClass="p-0 text-center" class="flex-nowrap">
										<pos-column xs="2" class="p-0 col-180"></pos-column>
										<pos-column class="col-180">
											<label class="label-section bold">Start Time</label>
										</pos-column>
										<pos-column xs="2" class="col-50 p-0"></pos-column>
										<pos-column class="col-180">
											<label class="label-section bold">End Time</label>
										</pos-column>
									</pos-row>
								</pos-column>

								<pos-column *ngFor="let scheduleDay of scheduleDaysSettings; let i = index">
									<pos-row colXs="2" class="flex-nowrap align-items-center pt-pix-10">
										<pos-column xs="2" class="col-180 px-pix-5">
											{{ scheduleDay.day }}
										</pos-column>
										<pos-column class="col-180">
											<input posInput type="time" class="form-control" name="startTime_{{i}}"
												[(ngModel)]="scheduleDay.startTime" />
										</pos-column>
										<pos-column xs="2" class="col-50 pt-pix-3 text-center">
											<span (click)="removeTime(true, i)">
												<pos-icon [icon]="icons.times"></pos-icon>
											</span>
										</pos-column>
										<pos-column class="col-180">
											<input posInput type="time" class="form-control" name="endTime_{{i}}"
												[(ngModel)]="scheduleDay.endTime" [min]="scheduleDay.startTime" />
										</pos-column>

										<pos-column xs="2" class="col-50 pt-pix-3 pe-0 text-center">
											<span (click)="removeTime(false, i)">
												<pos-icon [icon]="icons.times"></pos-icon>
											</span>
										</pos-column>
									</pos-row>
								</pos-column>
							</pos-row>
						</div>

					</div>
					<div class="col-12 section-border p-0 row mx-0">
						<h3 class="setting-title">Schedule Overrides</h3>
						<pos-column class="pos-title-content">
							<pos-schedule-active-hours-override></pos-schedule-active-hours-override>
						</pos-column>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 action-button-container page-footer">
			<button class="btn-primary btn-lg btn btn-save pos-button" scrollup type="submit" value="Save">Save</button>
			<button class="btn-secondary btn-lg btn btn-cancel pos-button" routerLink="../" scrollup type="button"
				value="Cancel">Cancel</button>
		</div>
	</form>
</pos-edit-wrapper>
