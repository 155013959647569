import { NgForm } from "@angular/forms";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { FormUtilityService } from "../../services/form-utility.service";
import { ModalComponent } from "../modal";
import { ModalService } from "../modal/modal.service";

export abstract class ModalFormComponent extends ModalComponent {
    abstract get getForm(): NgForm;
    formValueChangeSubscription;
    protected initialValues;
    unloadEvent;
    formTimeout;
    isSubmitted: boolean = null;
    isDetectUnsavedChanges = true;
    constructor(public modalRef: BsModalRef, public modalService: ModalService, protected formUtilityService: FormUtilityService) {
        super(modalRef);
        this.formTimeout = setTimeout(() => {
            this.setInitialValue();
            this.setSubmitted();
        });
    }

    setSubmitted() {
        if (this.getForm) {
            this.isSubmitted = false;
            this.getForm.ngSubmit.subscribe(() => {
                if (!this.getForm.invalid) {
                    this.isSubmitted = true;
                }
            });
        }
    }

    setInitialValue(): void {
        if (this.getForm) {
            if (this.isSubmitted == null) {
                this.setSubmitted();
            }
            const form = this.getForm;
            if (!this.initialValues) {
                this.initialValues = form.value;
            }
            this.formValueChangeSubscription = form.valueChanges.subscribe({
                next: () => {
                    if (!form.dirty) {
                        this.initialValues = form.value;
                    } else {
                        this.unsubscribeFormValueChangeSubscription();
                    }
                }
            });
            this.clearFormTimeout();
        }
    }

    unsubscribeFormValueChangeSubscription() {
        if (this.formValueChangeSubscription) {
            this.formValueChangeSubscription.unsubscribe();
        }
    }

    clearFormTimeout() {
        if (this.formTimeout) {
            clearTimeout(this.formTimeout);
        }
    }

    openConfirmModal(reason): Promise<boolean> {
        return new Promise<boolean>((resolve, _reject) => {
            const modal = this.formUtilityService.openConfirmModal();
            modal.close.subscribe(res => {
                if (res.shouldHide) {
                    super.hide(reason);
                }
                return resolve(res.shouldHide);
            });

        });
    }

    public hide(reason: any) {
        this.unsubscribeFormValueChangeSubscription();
        if (this.isDetectUnsavedChanges && this.getForm && this.hasUnsavedChanges() && !(this.getForm.submitted && !this.getForm.invalid)) {
            return this.openConfirmModal(reason);
        }
        super.hide(reason);
    }
    hasUnsavedChanges() {
        return this.formUtilityService.hasUnsavedChanges(this.getForm, this.initialValues);
    }
}
