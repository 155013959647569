import { Component, OnInit, ViewChild } from '@angular/core';
import { SalesSizeService } from 'src/app/shared/services/sales-size.service';
import { SalesSize } from '../../interface';
import { GridColumn, EditColumn, DeleteColumn, LinkColumn, TableComponent } from '@tarktech/tark-ng-utils';
import { AlertsService, Messages, SpinnerService, ConfirmDeleteComponent, ModalService, AuthenticationService,
   BaseGridComponent, ApplicationStateService, PrintTableService, salesSizesFunnelDollar } from 'src/app/shared';
import { SalesSizeEditComponent } from '../sales-size-edit';
import { Permissions } from '../../../../shared/constants/permissions';
import { Levels } from '../../../../shared/constants/accessLevel';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'pos-sales-size-list',
  templateUrl: './sales-size-list.component.html',
  styleUrls: ['./sales-size-list.component.scss']
})

export class SalesSizeListComponent extends BaseGridComponent implements OnInit {
  @ViewChild('confirmSalesSizeDelete') private confirmSalesSizeDelete: ConfirmDeleteComponent;
  salesSizes: Array<SalesSize> = [];
  salesSizeColumn: Array<GridColumn> = [];
  printSalesSizeColumns: Array<GridColumn> = [];
  deleteSalesSize: SalesSize;
  @ViewChild('salesSizeGrid', {static: true}) salesSizeGrid: TableComponent;
  public message = Messages.ConfirmDeleteSalesSize;
  public icons = {
    salesSizesFunnelDollar
  };
  permission = {
    name: Permissions.SalesSizes,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };

  constructor(
    private alertService: AlertsService,
    private salesSizeService: SalesSizeService,
    private spinnerService: SpinnerService,
    private modalService: ModalService,
    private authenticationService: AuthenticationService,
    protected applicationStateService: ApplicationStateService,
    protected route: ActivatedRoute,
    private printerService: PrintTableService,
    private router: Router,
  ) {
    super(applicationStateService, route);
    this.reloadSalesSizeList();
  }

  reloadSalesSizeList() {
    this.spinnerService.show();
    this.salesSizeService.getSalesSizes().subscribe(
      response => {
        this.salesSizes = (response === null ? [] : response);
      },
      this.alertService.showApiError,
      () => {
        this.spinnerService.hide();
      }
    );
  }

  editSalesSize(data) {
    const modalRef = this.modalService.show(SalesSizeEditComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        salesSizeId: data
      }
    });

    modalRef.close.subscribe(res => {
      if (res && res.shouldReload) {
        this.reloadSalesSizeList();
      }
    });
  }
  private configureGridColumns() {

    const editColumn = new EditColumn({
      Clicked: (data) => {
        this.editSalesSize(data.Id);
      },
      Width: '57px',
      AddIcon: new LinkColumn({
        Clicked: () => {
          this.editSalesSize(0);
        },
        ToolTip: 'New'
      })
    });

    const deleteColumn = new DeleteColumn({
      Clicked: (data) => {
        this.deleteButton(data);
      },
      Width: '84px',
      PrintIcon: new LinkColumn({
        Clicked: () => {
          this.printData();
        }
      })
    });

    this.salesSizeColumn = [
      new GridColumn({ HeaderText: 'Size', Field: 'Name', IsSortable: true, Width: '30%' }),
      new GridColumn({ HeaderText: 'CLI Codes', Field: 'CliCodes', IsSortable: true, Width: '40%' }),
      new GridColumn({ HeaderText: 'Make Table Size', Field: 'MakeTableSize', IsSortable: true, Width: '23%' })
    ] as Array<GridColumn>;

    this.printSalesSizeColumns = [...this.salesSizeColumn];

    if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      this.salesSizeColumn.push(editColumn);
      this.salesSizeColumn.push(deleteColumn);
    }
  }
  public ngOnInit() {
    this.configureGridColumns();
    this.salesSizeGrid.context = this.gridContext;
  }
  gridRowReorder(event) {
    const dragElementIndex = event.dragIndex;
    const dropElementIndex = event.dropIndex;
    dragElementIndex < dropElementIndex ?
      (this.salesSizes[dropElementIndex - 1].Ordinal = dropElementIndex) :
      (this.salesSizes[dropElementIndex].Ordinal = dropElementIndex + 1);
    this.salesSizeService.updateSalesSizeOrdinal(dragElementIndex < dropElementIndex ?
      this.salesSizes[dropElementIndex - 1] : this.salesSizes[dropElementIndex]).subscribe(
        () => { },
        this.alertService.showApiError,
        () => { }
      );
  }
  deleteButton($event: SalesSize) {
    this.deleteSalesSize = $event;
    this.confirmSalesSizeDelete.Show(this.message);
  }
  onConfirmAcknowledge() {
    this.spinnerService.show();
    this.salesSizeService.deleteSalesSize(this.deleteSalesSize.Id).subscribe(
      () => {
        this.alertService.renderSuccessMessage(
          Messages.SalesSizesDeleted
        );
        this.reloadSalesSizeList();
      },
      this.alertService.showApiError,
      () => {
        this.spinnerService.hide();
      }
    );
  }

  public printData() {
    this.printerService.printEmitter.next({gridColumns : this.printSalesSizeColumns, gridData: this.salesSizes });
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
