
import { Observable, of } from 'rxjs';
import { HttpParams, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared';

@Injectable({
  providedIn: 'root'
})
export class OrderConfirmationService {

  constructor(private httpService: HttpClient) { }
  
  getTerminalSlideshowDetails(terminalId: number): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}slideshows/` + terminalId + '/slideshowdetails'
    );
  }
}
