import { Route } from '@angular/router';
import { DeactivateGuard } from '../shared/guards/deactivate.guard';
import { IngredientsInfoComponent, IngredientsDietaryWarningsComponent, OrderComponent } from './order-entry';

const INGREDIENTS_INFO: Route = {
    path: 'ingredients-info',
    component: IngredientsInfoComponent
};

const INGREDIENT_DIETARY_WARNING: Route = {
    path: 'dietary-warning',
    component: IngredientsDietaryWarningsComponent
};

const ORDER: Route = {
    path: 'order-entry',
    component: OrderComponent,
    canDeactivate: [DeactivateGuard],
    data: {}
};

export const ORDER_STATES = [INGREDIENTS_INFO, INGREDIENT_DIETARY_WARNING, ORDER];
