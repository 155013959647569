import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { SettingParam } from '../../interface';
import { ApplicationStateService } from '../../services';

@Component({
  selector: 'pos-scan-barcode',
  templateUrl: './scan-barcode.component.html'
})
export class ScanBarcodeComponent implements OnInit {

  isEnabled = true;
  chars = [];
  settingParam: SettingParam;
  isScanningInProgress = false;
  @Output() barcodeScanned: EventEmitter<string> = new EventEmitter();

  @HostListener('document:keypress', ['$event']) onKeypress(event) {
    if (this.isEnabled) {
      this.subscribeToKeypressEvent(event);
    }
  }

  constructor(private applicationStateService: ApplicationStateService) {

  }
  ngOnInit() {
    this.settingParam = this.applicationStateService.settingParam;
  }

  subscribeToKeypressEvent(event) {
    if (!(event.target instanceof HTMLInputElement)) {
      if (event.key == this.settingParam.BarcodeScanningInitiatorSequence) {
        this.isScanningInProgress = true;
        this.chars = [];
      } else if (event.key != this.settingParam.BarcodeScanningEndingSequence && this.isScanningInProgress) {
        this.chars.push(event.key);
      }

      if (event.key == this.settingParam.BarcodeScanningEndingSequence && this.chars.length > 0) {
        let barcode = this.chars.join('');
        this.chars = [];
        this.isScanningInProgress = false;
        this.barcodeScanned.emit(barcode);
      }
    }
  }

}
