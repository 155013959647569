<div>
  <button type="button" class="close" (click)="closeDialog()">&times;</button>
  <h4 class="modal-content__heading">Print Check</h4>

  <div class="pos-modal-body pt-pix-15" [ngStyle]="{'min-height': screenHeight}">
    <div class="mb-pix-15 text-end">
      <div class="btn-group">
        <label class="btn  btn-radio col-75" [ngClass]="{'active btn-primary white font-nobold': status == 'All', 'btn-secondary': status != 'All'}">
          <input type="radio" name="status" class="form-check-input" (change)="getUnservedOrders()" [(ngModel)]="status"
            value="All"> All</label>
        <label class="btn btn-radio col-75" [ngClass]="{'active btn-primary white font-nobold': status == 'Paid', 'btn-secondary': status != 'Paid'}">
          <input type="radio" name="status" class="form-check-input" (change)="getUnservedOrders()" [(ngModel)]="status"
            value="Paid"> Paid</label>
        <label class="btn btn-radio col-75"
          [ngClass]="{'active btn-primary white font-nobold': status == 'Unpaid', 'btn-secondary': status != 'Unpaid'}">
          <input type="radio" name="status" class="form-check-input" [(ngModel)]="status" (change)="getUnservedOrders()"
            value="Unpaid"> Unpaid</label>

      </div>
    </div>

    <ng-table [columns]="printCheckColumns" [sort]="false" [paginator]="false" [search]="false" [data]="orders"
      [scrollable]="true" [scrollHeight]="screenHeight + 'px'" [rowClass]="'color'">
    </ng-table>
    <ng-template let-data="rowData" #amountTemplate>
      <span>
        {{data.Total | tark_currency}}
      </span>
    </ng-template>
    <ng-template let-data="rowData" #iconTemplate>
      <button class="btn btn-sm bg-theme-separator normal white text-nowrap pos-button" type="button" (click)="print(data)">
        <pos-icon [icon]="icons.printOrderManagement" class="white" [hidden]="data.showSpinner"></pos-icon>
        <pos-icon [icon]="icons.spinner" class="white" [hidden]="!data.showSpinner"></pos-icon>
        Print
      </button>
    </ng-template>
  </div>
  <div class="col-xs-12 action-button-container modal-footer popup-footer">
    <button class="btn btn-primary btn-lg btn-save pos-button" type="button" *ngIf="hasPermission"
      (click)="openOrderManagement()">Order Maintenance</button>
    <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" type="button" (click)="closeDialog()">Close</button>
  </div>
</div>
