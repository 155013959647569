import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { GiftCardWorkflowParameter } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class GiftCardWorkflowService {

  giftCardWorkflowApiBasePath = `${RuntimeConstants.API_PATH}gift-card-workflow-configuration`;
  constructor(private httpService: HttpClient) { }

  newGiftCardWorkflowConfigurationParameter(): GiftCardWorkflowParameter {
    return {
      GiftCardAutoDeductionAmount: null,
      GiftCardAutoDeductionAfterMonth: null
    }
  }

  getGiftCardWorkflowParameters(): Observable<GiftCardWorkflowParameter> {
    return this.httpService.get<GiftCardWorkflowParameter>(`${this.giftCardWorkflowApiBasePath}`);
  }

  updateGiftCardWorkflowParameters(giftCardWorkflowParameter: GiftCardWorkflowParameter) {
    return this.httpService.post<GiftCardWorkflowParameter>(`${this.giftCardWorkflowApiBasePath}`, giftCardWorkflowParameter);
  }
}
