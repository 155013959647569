import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { DomainConstants, Messages, FormUtilityService, BaseFormComponent } from 'src/app/shared';
import { NgForm } from '@angular/forms';
import { SecurityRoleService } from 'src/app/information-management/security-roles/service/security-role.service';
import { SecurityRoleEdit, PermissionRoles } from 'src/app/information-management/security-roles/interface/permission-roles';
import { GridColumn, DeleteColumn } from '@tarktech/tark-ng-utils';
import { debounce, filter, forEach, orderBy, uniqBy } from 'lodash';
import { PermissionGrant } from 'src/app/shared/interface/permission-grant';
import { ActivatedRoute, Router } from '@angular/router';
import { lockOpen, timesCircle, securityRolesUserLock, minusSquare } from 'src/app/shared/components/icon';
import { ButtonExclusionDataModel, SavePermissionRolesModel } from '../../interface/save-permission-roles-model';
import { PermissionCategory, PermissionLevel } from '../../interface/permission-category';
import { OrderNavigationButton } from 'src/app/orders';
import { ButtonService } from 'src/app/shared/services';

@Component({
    selector: 'pos-security-role-edit',
    templateUrl: './security-role-edit.component.html',
    styleUrls: ['./security-role-edit.component.scss']
})
export class SecurityRoleEditComponent extends BaseFormComponent implements OnInit {
    searchString: string = '';
    get getForm(): NgForm {
        return this.form
    }
    @ViewChild('securityRoleForm', { static: true }) form: NgForm;
    isDuplicateRole = false;
    securityRoleId: number;
    securityRoleDetails: PermissionRoles = null;
    permissionAssignments: Array<PermissionGrant> = [];
    permissionLevels: Array<PermissionLevel> = [];
    selectedRoleId: number;
    selectedRoleName = '';
    excludedButtonList: Array<ButtonExclusionDataModel> = [];
    excludedButtons: Array<OrderNavigationButton> = [];
    buttonExclusionList: Array<OrderNavigationButton> = [];
    selectedCategory: PermissionCategory = null;
    selectedButton: number;
    excludedButtonsColumns: Array<GridColumn> = [];
    permissionLevel = DomainConstants.PermissionLevels;
    accessLevels = [];
    public icons = {
        lockOpen, timesCircle, securityRolesUserLock, minusSquare
    };
    tabList = {
        Permissions: 'Permissions',
        ButtonExclusions: 'Button Exclusions',
    };
    selectedTab = this.tabList.Permissions;
    managerRole = DomainConstants.SystemPermissionRoles.Manager;
    constructor(
        private securityRoleService: SecurityRoleService,
        private alertService: AlertsService,
        private spinnerService: SpinnerService,
        private route: ActivatedRoute,
        private router: Router,
        formUtilityService: FormUtilityService,
        private buttonService: ButtonService
    ) {
        super(formUtilityService);
        this.securityRoleId = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
    }

    ngOnInit() {
        this.onLoadSecurityRoleDetails();
        this.configureGridColumns();
    }

    private configureGridColumns() {

        const deleteColumn = new DeleteColumn({
            Clicked: (excludedButton) => {
                this.removeButtonFromExclusion(excludedButton);
            },
            Width: '40px'
        });

        this.excludedButtonsColumns = [
            new GridColumn({ HeaderText: 'Excluded Buttons', Field: 'Selection', IsSortable: true, Width: '89%' }),
        ] as Array<GridColumn>;

        this.excludedButtonsColumns.push(deleteColumn);
    }

    private onLoadSecurityRoleDetails() {
        const securityRoleObservable: Array<Observable<any>> = [];
        securityRoleObservable.push(this.securityRoleService.getSecurityRole(this.securityRoleId));
        securityRoleObservable.push(this.securityRoleService.getButtonExclusion(this.securityRoleId));
        this.spinnerService.show();
        forkJoin(securityRoleObservable)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: responses => {
                    if (responses[0]) {
                        this.loadSecurityRoleData(responses[0]);
                    }
                    if (responses[1]) {
                        this.excludedButtonList = responses[1];
                    }
                },
                error: this.alertService.showApiError
            });
    }

    getAllButtons = () => {
        if (!this.buttonExclusionList.length) {
            this.spinnerService.show();
            this.buttonService.getButtonList()
                .pipe(finalize(() => {
                    this.spinnerService.hide();
                }))
                .subscribe({
                    next: (res: Array<OrderNavigationButton>) => {
                        if (res) {
                            this.buttonExclusionList = res;
                            this.loadButtonExclusionData();
                        }
                    }, error: this.alertService.showApiError
                });
        }
    }

    private loadSecurityRoleData(securityRolesList) {
        this.securityRoleDetails = securityRolesList.Role;
        this.permissionAssignments = securityRolesList.PermissionAssignments as Array<PermissionGrant>;
        this.permissionLevels = securityRolesList.PermissionLevels;
        this.preparePermissionLevels(securityRolesList.PermissionLevels);
        this.selectedRoleId = this.securityRoleId;

        this.selectedRoleName = '';
        if (!this.securityRoleId) {
            const enterOrderPermission = this.permissionAssignments.find((permissionAssignment) => permissionAssignment.Permission.Name === SecurityRoleEdit.EnterOrder);
            if (enterOrderPermission) {
                enterOrderPermission.IsUpdated = true;
                enterOrderPermission.PermissionLevel = this.permissionLevel.ACCESS.value;
            }
        } else {
            this.selectedRoleName = this.securityRoleDetails.Name;
        }
    }

    preparePermissionLevels(permissionLevels) {
        this.permissionLevels = permissionLevels.filter(x => x.Name !== this.permissionLevel.ACCESS.displayName);
        this.accessLevels = permissionLevels.filter(x => x.Name !== this.permissionLevel.EDIT.key
            && x.Name !== this.permissionLevel.READONLY.displayName);
        this.accessLevels = orderBy(this.accessLevels, "Name");
        this.accessLevels.unshift({ Id: -1, Name: 'Not Set' });
        this.permissionLevels.unshift({ Id: -1, Name: 'Not Set' });
    }

    private loadButtonExclusionData() {
        if (this.buttonExclusionList.length) {
            this.buttonExclusionList.forEach((product) => {
                product.IsExcluded = true;
                this.excludedButtonList.forEach((btnExclusion: ButtonExclusionDataModel) => {
                    if (product.Id === btnExclusion.ChoiceId) {
                        btnExclusion.Selection = product.ButtonText;
                        product.IsExcluded = false;
                        this.excludedButtons.push(product);
                    }
                });
            });
            this.buttonExclusionList = this.buttonExclusionList.filter(b => b.IsExcluded).sort((a, b) => a.ButtonText.localeCompare(b.ButtonText));
            this.excludedButtonList.sort((a, b) => a.Selection.localeCompare(b.Selection));
        }
    }

    selectCategoryTab(category: PermissionCategory) {
        this.selectedCategory = category;
    }

    addButtonToExclusion() {
        const item = this.buttonExclusionList.find(x => x.Id == this.selectedButton);
        const buttonInList = this.excludedButtonList.find(e => e.ChoiceId === this.selectedButton);
        if (!buttonInList) {
            const buttonExclusion: ButtonExclusionDataModel = {
                Id: 0,
                RoleId: this.securityRoleId,
                Selection: item.ButtonText,
                ChoiceId: item.Id
            };
            this.excludedButtonList.push(buttonExclusion);
            this.excludedButtons.push(item);
            this.buttonExclusionList = this.buttonExclusionList.filter(b => b.Id !== this.selectedButton);
            this.excludedButtonList.sort((a, b) => a.Selection.localeCompare(b.Selection));

            this.selectedButton = null;
        }
    }

    removeButtonFromExclusion(currentButton: ButtonExclusionDataModel) {
        const index = this.excludedButtonList.indexOf(currentButton);
        this.excludedButtonList.splice(index, 1);
        const excludedButton = this.excludedButtons.find(b => b.Id === currentButton.ChoiceId);
        this.buttonExclusionList = [...this.buttonExclusionList, excludedButton];
        this.buttonExclusionList.sort((a, b) => a.ButtonText.localeCompare(b.ButtonText));
        this.selectedButton = null;
    }

    cancelEditing() {
        this.router.navigate(['security-roles'], { relativeTo: this.route.parent });
    }

    submit(isValid: boolean) {
        if (!isValid) {
            return;
        }
        const permissionModel: SavePermissionRolesModel = {
            Name: this.selectedRoleName,
            RoleId: this.selectedRoleId,
            AssignmentList: uniqBy(this.flattenArray(this.permissionAssignments).filter(x => x.IsUpdated), 'PermissionTypeId'),
            ButtonExclusions: this.excludedButtonList
        };

        forEach(permissionModel.AssignmentList, x => x.PermissionLevel = x.PermissionLevel === -1 ? null : x.PermissionLevel);
        this.saveSecurityRole(permissionModel);
    }
    private saveSecurityRole(permissionModel: SavePermissionRolesModel) {
        this.spinnerService.show();
        this.securityRoleService.savePermissionRole(permissionModel)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: () => {
                    this.alertService.renderSuccessMessage(Messages.SecurityRoleSaveSuccess);
                    this.router.navigate(['security-roles'], { relativeTo: this.route.parent });
                }, error: this.alertService.showApiError
            });
    }

    flattenArray(arr: PermissionGrant[]): PermissionGrant[] {
        return arr.reduce((flat, toFlatten) => {
            if (toFlatten.IsUpdated) {
                flat.push(toFlatten);
            }
            if (toFlatten.Children.length) {
                flat.push(...this.flattenArray(toFlatten.Children));
            }
            return flat;
        }, []);
    }

    filterSearch = debounce(() => {
        this.filterNodes(this.permissionAssignments);
        this.getFilteredPermissionsOnly(this.permissionAssignments);
    }, 200);

    filterNodes(nodes: PermissionGrant[], shouldIncludeChild = false, isExpanded = true) {
        forEach(nodes, node => {
            node.Permission.ShouldInclude = shouldIncludeChild;
            node.Permission.IsExpanded = isExpanded;
            node.IsLastFilteredElement = false;
            if (node.Permission.Name?.toLowerCase().includes(this.searchString.toLowerCase()) ||
                node.Permission.Description?.toLowerCase().includes(this.searchString.toLowerCase()) ||
                node.Permission.Id?.toLowerCase().includes(this.searchString.toLowerCase()) ||
                node.Permission.Tags?.some(x => x.toLowerCase().includes(this.searchString.toLowerCase()))) {
                node.Permission.IsExpanded = false;
                node.Permission.ShouldInclude = true;
            }

            if (node.Children?.length) {
                this.filterNodes(node.Children, node.Permission.ShouldInclude, node.Permission.IsExpanded);
            }
            node.Permission.ShouldInclude = node.Children?.some(n => n.Permission.ShouldInclude) ? true : node.Permission.ShouldInclude;
        });
    }

    getFilteredPermissionsOnly(permissions: PermissionGrant[]) {
        permissions = filter(permissions, p => p.Permission.ShouldInclude);
        const lastPermission = permissions[permissions.length - 1];
        if (lastPermission) lastPermission.IsLastFilteredElement = true;
        forEach(permissions, p => {
            if (p.Children?.length) {
                this.getFilteredPermissionsOnly(p.Children);
            }
        });
    }

    closeAllNodes(nodes: PermissionGrant[]) {
        nodes?.forEach(node => {
            node.Permission.IsExpanded = false;
            if (node.Children?.length) {
                this.closeAllNodes(node.Children)
            }
        })
    }
}

