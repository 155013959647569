import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseCrudService } from 'src/app/shared/services';
import { Rule } from '../interface/report-rules';

@Injectable({
    providedIn: 'root'
})
export class ReportParameterRuleService extends BaseCrudService<Rule> {
    constructor(private httpClient: HttpClient) {
        super('report-parameter-rule', httpClient);
    }

    getParameterRule(dataSourceId: number) {
        return this.httpClient.get<Array<Rule>>(`${this.apiEndpoint}/${dataSourceId}`);
    }
}
