<span [ngClass]="interactiveStyleClass">
    <span [ngClass]="{'display-flex' : text}" [ngStyle]="textPosition">
        <div *ngIf="text && showImageText" class="text-center" [ngStyle]="textStyle">
            <span innerHTML="{{text}}"></span>
        </div>
        <span [ngClass]="{'position-relative': outOfStock}" style="display: inline-flex">
            <div class="display-flex outOfStockStyle" *ngIf="outOfStock?.Text" [ngStyle]="outOfStock?.TextStyleConfig">
                <span innerHTML="{{outOfStock.Text}}"></span>
            </div>
            <img [ngStyle]="{'height' : (height ? height+ 'px' : '100%') , 'width': (width ? width + 'px' : '100%'),
                'filter': 'drop-shadow(' + this.offsetX + ' ' + this.offsetY + ' ' + this.blurRadius + ' ' + (this.shadowColor ? this.shadowColor : 'transparent') + ')'}"
                [ngClass]="{'opacity-0_5': outOfStock}" class="widget-image" src="{{imagePath}}/{{image}}" alt="image"/>
        </span>
    </span>
</span>
