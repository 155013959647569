import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ModalDirective, ModalOptions } from 'ngx-bootstrap/modal';
import { GridColumn, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { printOrderManagement, spinner } from './../../../shared/components/icon';
import { ApplicationStateService, SpinnerService, AlertsService, AuthenticationService, Permissions, Levels, ICloseable, SettingParam, UserDetails, Messages, InfoModalComponent, ModalService, OrderService } from 'src/app/shared';
import { finalize } from 'rxjs/operators';
declare let $: any;
import { OrderMaintenanceComponent } from 'src/app/order-maintenance/components/order-maintenance';
@Component({
  selector: 'pos-print-check',
  templateUrl: './print-check.component.html',
  styleUrls: ['./print-check.component.scss']
})
export class PrintCheckComponent implements OnInit, OnChanges, ICloseable {
  @Input() openPopup: boolean;
  @Input() orderId: number = 0;
  close: EventEmitter<any> = new EventEmitter();
  // @Input() printCheckOfOrder: Function;
  settingParam: SettingParam;
  userDetails: UserDetails;
  @Output() closePopup = new EventEmitter();
  @Input() resolvedOrderId: number;
  @ViewChild('iconTemplate', { static: true }) private iconTemplate: any;
  @ViewChild('amountTemplate', { static: true }) private amountTemplate: any;
  public icons = {
    printOrderManagement,
    spinner
  }
  status: string;
  screenHeight = $(window).height() - 500 < 150 ? 200 : 370;
  permission = {
    name: Permissions.ManageOrderMaintenance,
    accessLevel: Levels.Access
  }
  config: ModalOptions = {
    ignoreBackdropClick: true,
    keyboard: false
  }
  orders: any = [];
  printCheckColumns: Array<GridColumn> = [];
  hasPermission = false;
  constructor(
    private orderService: OrderService,
    private applicationStateService: ApplicationStateService,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private authenticationService: AuthenticationService,
    private modalService: ModalService) {
    this.status = this.applicationStateService.settingParam.PrintCheckDefaultOrderStatus ? this.applicationStateService.settingParam.PrintCheckDefaultOrderStatus : "All";
    this.orderId = 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const resolvedId = changes.resolvedOrderId;
    if (resolvedId && resolvedId.currentValue > 0) {
      this.orders.map(c => { if (c.id == resolvedId.currentValue) { c.showSpinner = false; } return c; });
    }
  }

  ngOnInit() {
    this.hasPermission = this.authenticationService.userHasPermission([{ "Name": this.permission.name, "Level": this.permission.accessLevel }], 'any');
    this.configureColumns();
    this.setSetting();
    this.getUnservedOrders();
    this.resizeWindow();
  }

  resizeWindow = () => {
    $(window).resize(() => {
      this.screenHeight = $(window).height() - 500 < 150 ? 200 : 370;
    });
  }

  setSetting() {
    this.settingParam = this.applicationStateService.settingParam;
    this.userDetails = this.applicationStateService.userDetails;
  }

  configureColumns() {
    const iconColumn = new TemplateColumn({
      HeaderText: '',
      Width: '15%',
      itemTemplate: this.iconTemplate,
      TextAlign: TextAlign.Center,
      CellClass: 'black'
    });
    const amountColumn = new TemplateColumn({
      HeaderText: 'Amount',
      itemTemplate: this.amountTemplate,
      Field: 'Total',
      Width: '15%',
      TextAlign: TextAlign.Right,
      IsSortable: true,
      CellClass: 'black'
    });
    this.printCheckColumns = [
      new GridColumn({ HeaderText: 'Order', Width: '15%', Field: 'SurrogateOrderId', IsSortable: true, CellClass: 'black' }),
      new GridColumn({ HeaderText: 'Name', Field: 'FirstMainProduct', IsSortable: true, CellClass: 'black' }),
    ] as Array<GridColumn>;
    this.printCheckColumns.push(amountColumn);
    this.printCheckColumns.push(iconColumn);
  }

  getUnservedOrders() {

    this.orders = [];
    const drawerId = this.applicationStateService.settingParam.CashDrawerPrinter.Id;
    const userId = this.applicationStateService.userDetails.id;

    this.spinnerService.show();

    this.orderService.getUnservedOrdersForPrintCheck(drawerId, userId, this.status, this.orderId ? this.orderId : 0).subscribe({
      next: response => {
        if (response && response.length > 0) {
          const ordersData: any[] = response.sort(o => o.id);
          if (ordersData.slice(0, 10).some(o => o.id == this.orderId || !this.orderId)) {
            this.orders = ordersData.slice(0, 10).map(o => { o.showSpinner = false; o.color = o.id == this.orderId ? 'bg-theme-primary' : ''; return o; });
          }
          else {
            const currentOrder = ordersData.find(o => o.id == this.orderId && this.orderId > 0);
            if (currentOrder) {
              currentOrder.color = 'bg-theme-primary';
              currentOrder.showSpinner = false;
              this.orders = ordersData.slice(0, 9);
              this.orders.push(currentOrder);
            }
          }
        }
      }, error: this.alertService.showApiError,
      complete: () => {
        this.spinnerService.hide();
      }
    });
  }

  print(obj) {
    this.printCheckOfOrder(obj, obj.SurrogateOrderId);
  }

  closeDialog() {
    this.close.emit();
    this.openPopup = false;
  }


  printCheckOfOrder(order, surrogateOrderId) {
    if (!this.settingParam) {
      this.settingParam = this.applicationStateService.settingParam;
    }
    this.settingParam.UserID = this.applicationStateService.userId;
    this.settingParam.CurrentUser = this.applicationStateService.username;
    this.settingParam.TerminalId = this.applicationStateService.terminalId;

    if (!this.settingParam?.ReceiptPrinter?.Id) {
      this.modalService.show(InfoModalComponent, {
        animated: false,
        class: 'vertical-center',
        initialState: {
          message: Messages.ReceiptPrinterNotMapped
        }
      });
    } else {
      order.showSpinner = true;
      const printOrderModel = {
        OrderId: order.id,
        TerminalId: this.applicationStateService.terminalId,
        Parameter: this.settingParam,
        PaidAmount: 0,
        SurrogateOrderId: surrogateOrderId,
        ReceiptPrinterId: this.settingParam.ReceiptPrinter.Id,
        PrintSeatDetails: []
      };
      // this.spinnerService.show();
      this.orderService.printCheck(printOrderModel)
        .pipe(finalize(() => {
          // this.spinnerService.hide();
          this.orders.map(c => { if (c.id === order.id) { c.showSpinner = false; } return c; });
        }))
        .subscribe({
          next: (res) => {
          }, error: this.alertService.showApiError
        });
    }
  }

  openOrderManagement() {
    const modalRef = this.modalService.getModalWrapper(OrderMaintenanceComponent);
    modalRef.show({
      animated: false,
      class: 'vertical-center modal-lg modal-max-width-95',
      'backdrop': 'static'
    });
  }
}
