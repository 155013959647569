<div>
  <button (click)="cancel()" class="close" type="button">×</button>
  <h4 class="modal-content__heading">Order Transactions</h4>
</div>
<div class="pos-modal-body overflow-hidden p-0">
  <div class="col-12 pt-pix-20">
    <ng-table #gridComponent [paginator]="false" [search]="false" [scrollable]="true" [selectionMode]="'single'"
      (rowSelect)="selectTransaction($event)" [scrollHeight]="'400px'" [columns]="transactionColumns"
      [styleClass]="'no-outline'" [data]="transactions">
    </ng-table>
    <ng-template let-data="rowData" #amountTemplate>
      <div class="ps-pix-10"> {{data.Amount}} </div>
    </ng-template>
    <ng-template let-data="rowData" #lastFourDigitsTemplate>
      <div class="ps-pix-10"> {{data.LastFourDigits}} </div>
    </ng-template>
  </div>
</div>
<div class="col-12 action-button-container popup-footer pb-pix-10">
  <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="cancel()" scrollup type="button">Cancel</button>
</div>
<div class="clearfix"></div>
