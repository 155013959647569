import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Messages } from '../../constants';
import { ConfigurationParameter, SettingParam } from '../../interface';
import { AlertsService, ApplicationStateService, ParameterService, EventBroadcastingService } from '../../services';
import { InfoModalComponent } from '../info-modal';
import { ModalService } from '../modal';
import { SpinnerService } from '../spinner/spinner.service';
declare let $: any;
@Component({
  selector: 'pos-keyboard-wedge',
  template: ''
})
export class KeyboardWedgeComponent implements OnInit {

  @Input() isEnabled = false;
  @Output() onSwipeDetected = new EventEmitter();
  settingParams: SettingParam;
  enableUserSwipe: boolean = true;
  isRFIDInitiatorSequence: boolean = false;
  chars = [];
  charBetweenRFIDSequence: boolean = true;
  isStartStringForBadgeNo: boolean = false;
  keyboardWedgeValue: string = '';
  cardBasedSignInSubscription: Subscription;
  constructor(private applicationStateService: ApplicationStateService,
    private parameterService: ParameterService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private modalService: ModalService,
    private EventBroadcastingService: EventBroadcastingService) { }

  ngOnInit() {
    this.getCardBasedLoginConfig();
    this.cardBasedSignInChangedSubscription();
    this.subscribeWindowKeypress();
  }

  ngOnDestroy() {
    // $(window).off('keypress', '');
    this.cardBasedSignInSubscription?.unsubscribe();
  }

  private subscribeWindowKeypress(): void {
    if (this.isEnabled) {
      $(window).keypress((event) => {
        if (this.settingParams) {
          if (event.key === this.settingParams.RFIDInitiatorSequence) {
            this.isRFIDInitiatorSequence = true;
            this.charBetweenRFIDSequence = !this.charBetweenRFIDSequence;
            this.isStartStringForBadgeNo = true;
            if (this.chars.length > 0) {
              this.chars = [];
              this.charBetweenRFIDSequence = false;
            }
          } else {
            if (event.key !== this.settingParams.RFIDEndingSequence) {
              this.charBetweenRFIDSequence = true;
            } else {
              this.charBetweenRFIDSequence = false;
            }
          }
          if (this.isRFIDInitiatorSequence && this.charBetweenRFIDSequence) {
            this.chars.push(event.key);
          }

          if (event.key === this.settingParams.RFIDEndingSequence && this.chars.length > 0 && this.isStartStringForBadgeNo) {
            this.isRFIDInitiatorSequence = false;
            this.charBetweenRFIDSequence = true;
            this.keyboardWedgeValue = this.chars.join('');
            this.chars = [];
            if (this.keyboardWedgeValue === this.settingParams.RFIDErrorCode) {
              const modalRef = this.modalService.show(InfoModalComponent, {
                animated: false,
                class: 'vertical-center',
                initialState: {
                  message: Messages.CardbasedLoginBadgeNumberErrorCode
                }
              });

              return;
            } else {
              if (this.enableUserSwipe) {
                this.onSwipeDetected.emit(this.keyboardWedgeValue);
              }
            }
          }
        }
      });
    }
  }

  private cardBasedSignInChangedSubscription(): void {
    /** NOTE: We need to add timeout for handle $digest already in progress error.
     * CASE: Goto order entry screen then after come to Admin it's give an error
    */
    setTimeout(() => {
      this.cardBasedSignInSubscription = this.EventBroadcastingService.cardBasedSignInChanged.subscribe(
        (enabled: boolean) => {
          this.enableUserSwipe = enabled;
        }
      );
    });
  }

  private getCardBasedLoginConfig(): void {
    this.settingParams = this.applicationStateService.settingParam;
    if (!this.settingParams) {
      this.spinnerService.show();
      const settingObservable = [];
      settingObservable.push(this.parameterService.getParameterServiceForName('RFIDInitiatorSequence'));
      settingObservable.push(this.parameterService.getParameterServiceForName('RFIDEndingSequence'));
      settingObservable.push(this.parameterService.getParameterServiceForName('RFIDErrorCode'));
      forkJoin(settingObservable)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res: Array<ConfigurationParameter>) => {
            if (res) {
              this.settingParams = this.applicationStateService.newSettingParams();
              this.settingParams.RFIDInitiatorSequence = (res[0] && res[0].value) ? (res[0].value) : null;
              this.settingParams.RFIDEndingSequence = res[1] && res[1].value ? res[1].value : null;
              this.settingParams.RFIDErrorCode = res[2] && res[2].value ? res[2].value : null;
            }
          }, error: this.alertService.showApiError
        });
    }
  }

}
