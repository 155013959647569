<pos-list-wrapper [headingIcon]="icons.securityRolesUserLock" heading="Security Roles" (close)="close()">
    <div class="row">
        <div class="col-12">
            <ng-table id="securityRoles" [sort]="true" [columns]="securityRolesColumns"
                [data]="securityRoles" #securityRolesGrid>
            </ng-table>
            <ng-template let-data="rowData" #operationTemplate>
                <div class="display-flex justify-content-center">
                    <div  class="pe-pix-3"
                        [ngClass]="{' col-30': data.IsSystemDefined}">
                        <pos-action-icon [icon]="icons.editWhite" (click)="editSecurityRole(data.Id)"
                            *ngIf="data.Name != adminRole"></pos-action-icon>
                    </div>
                    <div  class="pe-pix-3"
                        [ngClass]="{'col-30': data.IsSystemDefined}">
                        <pos-action-icon [icon]="icons.deleteWhite" (click)="deleteSecurityRole(data)" 
                        *ngIf="!data.IsSystemDefined">
                        </pos-action-icon>
                    </div>
                    <div class="greyicon" style="overflow-wrap: break-word;">
                        <a class="action-btn grey pointer" #pop="bs-popover" placement="left"
                            (click)="onLinkPopOver(data,pop)" [outsideClick]="true"
                            [popover]="securityRolePopoverTemplate" triggers="click">
                            <pos-action-icon [icon]="icons.barsWhite"></pos-action-icon>
                        </a>
                    </div>
                </div>
    
            </ng-template>
    
            <ng-template #headerTemplate>
                <div class="display-flex">
                    <pos-action-icon (click)="editSecurityRole(0)" backgroundClasses="bg-add d-inline-block" class="no-wrap" [icon]="icons.plusWhite"
                        iconText="Add">
                    </pos-action-icon>
                    <pos-export-dropdown class="inline-block ps-pix-5" (onPrint)="printDiv()"
                        (onCSV)="exportCSV()"></pos-export-dropdown>
                </div>
            </ng-template>
        </div>
    </div>
</pos-list-wrapper>
<pos-confirm-delete (onConfirmAcknowledge)="onConfirmAcknowledge()" #confirmSecurityRole>
</pos-confirm-delete>
<ng-template #securityRolePopoverTemplate>
    <div class="">
        <ul>
            <li class="listbullet">
                <a class="grey" (click)="openSecurityRoleCopyDateModal(securityRole)">
                    <pos-icon [icon]="icons.clone" class="pe-pix-3" style="margin-left: -5px;">
                    </pos-icon>
                    Copy to new Role
                </a>
            </li>
        </ul>
    </div>
</ng-template>
<pos-print-table [hidden]="true"></pos-print-table>
