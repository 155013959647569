import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DateColumn, GridColumn, TarkTimePipe, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { Format } from '@tarktech/tark-ng-utils/table/format-type';
import { AuthenticationService } from 'src/app/shared/auth/services/authentication.service';
import { BaseListComponent } from 'src/app/shared/components/base-list.component';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import { OnlineOrderingScheduleOverrides } from '../../interface/online-ordering-schedule-override';
import { OnlineOrderingScheduleOverridingService } from '../../service/online-ordering-schedule-overriding.service';
import { ScheduleOverrideEditComponent } from '../schedule-override-edit/schedule-override-edit.component';

@Component({
  selector: 'pos-schedule-overrides',
  templateUrl: './schedule-overrides.component.html',
  styleUrls: ['./schedule-overrides.component.scss']
})
export class ScheduleOverridesComponent extends BaseListComponent<OnlineOrderingScheduleOverrides> implements OnInit {
  @Input() fromListPage = true;
  scheduleOverrideColumns: Array<GridColumn> = [];
  scheduleOverrides: Array<OnlineOrderingScheduleOverrides> = [];
  constructor(protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected printService: PrintTableService,
    protected applicationStateService: ApplicationStateService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected onlineOrderingScheduleOverrideService: OnlineOrderingScheduleOverridingService,
    protected tarkTimePipe: TarkTimePipe) {
    super(onlineOrderingScheduleOverrideService
      , alertService, spinnerService, modalService, applicationStateService, printService, route);
    this.deleteSuccessMessage = Messages.OnlineOrderScheduleOverrideDeleteSuccess;
    this.isPrintIconVisible = false;
  }

  ngOnInit(): void {
    this.loadData();
    this.scheduleOverrideColumns = this.configureGridColumns();
  }

  loadData() {
    this.data$.subscribe({
      next: (response) => {
        this.scheduleOverrides = response;
        this.scheduleOverrides.map((data) => {
          data.StartTime = data.StartTime ?
            this.tarkTimePipe.transform(Date.parse('1-1-0001 ' + data.StartTime.toString()), false).toString() : 'Closed for the day';
          data.EndTime = data.EndTime ?
            this.tarkTimePipe.transform(Date.parse('1-1-0001 ' + data.EndTime.toString()), false).toString() : 'Closed for the day';
        });
      }
    });
  }

  editItem(scheduleId) {
    const modalRef = this.modalService.getModalWrapper(ScheduleOverrideEditComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      initialState: {
        scheduleOverrideId: scheduleId
      }
    });
    modal.close.subscribe(res => {
      if (res && res.shouldReload) {
        this.reload();
      }
    });
  }

  getGridColumns() {
    return [
      new DateColumn({ HeaderText: 'From', Field: 'FromDate', Format: Format.Date, Width: '23%', TextAlign: TextAlign.Left }),
      new DateColumn({ HeaderText: 'To', Field: 'ToDate', Width: '23%', Format: Format.Date, TextAlign: TextAlign.Left }),
      new GridColumn({ HeaderText: 'Start Time', Field: 'StartTime', Width: '23%' }),
      new GridColumn({ HeaderText: 'End Time', Field: 'EndTime', Width: '23%' }),
    ];
  }

  getConfirmDeleteMessage() {
    return Messages.OnlineOrderScheduleOverrideConfirmDelete;
  }

}
