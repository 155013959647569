<pos-list-wrapper [headingIcon]="icons.baselineExpenseMoneyBill" heading="Baseline Expenses" (close)="close()">
    <div class="col-xs-12 mt-10">
        <ng-table [columns]="baselineExpenseColumns" [data]="baselineExpenses" [paginator]="false"
            [search]="false"></ng-table>
        <ng-template let-data="rowData" #expenseTemplate>
            <span [ngClass]="{'text-expense': (data.Expense === 'Total')}">{{ data.Expense }}</span>
        </ng-template>
        <ng-template let-data="rowData" #amountTemplate>
            <span [ngClass]="{'bold': (data.Expense === 'Total')}">{{ data.Amount | tark_currency }}</span>
        </ng-template>
        <ng-template let-data="rowData" #operationHeaderTemplate>
            <div class="text-center">
                <pos-action-icon (click)="editItem(0)" [icon]="icons.plusWhite"
                    iconText="Add"></pos-action-icon>
            </div>
        </ng-template>
        <ng-template let-data="rowData" #operationTemplate>
            <div permissions='[{"Name":"{{permission.name}}", "Level":"{{permission.editLevel}}"}]'
                *ngIf="data.Expense !== 'Total'">
                <span (click)="editItem(null, data)" tooltip title="Edit" scrollup>
                    <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
                </span>
                <span (click)="deleteBaselineExpense(data)" tooltip title="Delete">
                    <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
                </span>
            </div>
        </ng-template>
    </div>
</pos-list-wrapper>
<pos-confirm-delete [message]="message" (onConfirmAcknowledge)="onConfirmAcknowledge()" #confirmDeleteBaselineExpense>
</pos-confirm-delete>
