import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MenuWidgetDirective } from '../../directives/menu-widget.directive';
import { cogWhite, editWhite } from 'src/app/shared/components/icon';
import { DigitalMenuComponentResolverFactoryService } from '../../services/digital-menu-component-resolver-factory.service';
import { MenuWidgetService } from '../../services/menu-widget.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { RabbitMQService } from 'src/app/shared/services/rabbitmq/services/rabbitmq.service';
import { DomainConstants } from 'src/app/shared/constants';

@Component({
  selector: 'pos-menu-widget',
  templateUrl: './menu-widget.component.html',
  styleUrls: ['./menu-widget.component.scss']
})
export class MenuWidgetComponent extends BaseWidgetComponent implements OnInit {

  @ViewChild(MenuWidgetDirective, { static: true }) posMenuWidget: MenuWidgetDirective;
  @Input() widgetType: string = '';
  @Input() widgetId: number;
  @Input() widgetIndex: number;
  @Input() layerIndex: number;
  @Input() gridsterItem;
  @Input() previewMode = false;
  @Output() widgetDelete = new EventEmitter<number>();
  @Output() copyWidget = new EventEmitter<number>();
  @Output() edit = new EventEmitter<boolean>
  @Output() reload = new EventEmitter<boolean>();
  groupWidgetName = DomainConstants.DigitalMenuWidgetTypes.Group.Name;
  isShowEditOption = true;

  icons = {
    editWhite,
    cogWhite
  };

  constructor(
    protected menuWidgetService: MenuWidgetService,
    protected spinnerService: SpinnerService,
    protected alertService: AlertsService,
    protected applicationStateService: ApplicationStateService,
    protected modalService: ModalService,
    private digitalMenuWidgetResolverFactory: DigitalMenuComponentResolverFactoryService,
    protected rabbitMQService: RabbitMQService) {
    super(menuWidgetService, alertService, spinnerService, applicationStateService, modalService, rabbitMQService);
    this.deleteSuccessMessage = Messages.WidgetDeleted;
  }

  ngOnInit(): void {
    const viewContainerRef = this.posMenuWidget.viewContainerRef;
    this.digitalMenuWidgetResolverFactory.loadComponent(viewContainerRef, this.widgetType, this.widgetId, this.previewMode);
    this.subscribeToShowGroupsChange();
  }

  deleteWidget() {
    if (this.widgetId) {
      this.confirmDelete({ Id: this.widgetId });
    } else {
      this.widgetDelete.emit(this.widgetIndex);
    }
  }

  showDeletedSuccessfullyMessage(): void {
    this.alertService.renderSuccessMessage(this.deleteSuccessMessage);
    this.widgetDelete.emit(this.widgetIndex);
  }

  editWidget() {

  }

  subscribeToShowGroupsChange() {
    this.baseWidgetCrudService.showGroupWidgetsChange$.subscribe({
      next: (response) => {
        this.isShowEditOption = (response?.showGroupWidgets && this.widgetType == DomainConstants.DigitalMenuWidgetTypes.Group.Name) || !response?.showGroupWidgets;
      }
    });
  }

  copy() {
    this.copyWidget.emit();
  }

  emitEditWidget() {
    this.edit.emit();
  }

}
