import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertsService, ApplicationStateService, BaseFormComponent, FieldInfoMessages, FormUtilityService, Messages, SettingParam, SpinnerService } from 'src/app/shared';
import { finalize } from 'rxjs/operators';
import { CardBasedLoginService } from '../../services/card-based-login.service';
import { Router } from '@angular/router';
import { cardBasedLoginsIdCard } from 'src/app/shared/components/icon';
import { NgForm } from '@angular/forms';
import { PostSavedConfigurationService } from 'src/app/configurator/post-saved-configuration.service';
@Component({
  selector: 'pos-card-based-login',
  templateUrl: './card-based-login.component.html',
})
export class CardBasedLoginComponent extends BaseFormComponent implements OnInit {
  @ViewChild('cardBasedLoginForm') cardBasedLoginForm: NgForm;
  get getForm(): NgForm {
    return this.cardBasedLoginForm
  }
  parameters: SettingParam;
  settingParam: SettingParam;
  fieldInfoMessages = FieldInfoMessages;
  icons = {
    cardBasedLoginsIdCard
  }
  constructor(private spinnerService: SpinnerService,
    private cardBasedLoginService: CardBasedLoginService,
    private alertsService: AlertsService,
    private applicationStateService: ApplicationStateService,
    private postSavedConfigurationService: PostSavedConfigurationService,
    private router: Router,
    formUtilityService: FormUtilityService) {
    super(formUtilityService);
    this.parameters = applicationStateService.newSettingParams();
  }

  ngOnInit() {
    this.settingParam = this.applicationStateService.settingParam;
    this.loadData();
  }

  loadData() {
    this.spinnerService.show();
    this.cardBasedLoginService.getCardbasedLoginInfo(this.applicationStateService.terminalId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: SettingParam) => {
          this.parameters = res;
        }, error: this.alertsService.showApiError
      });
  }

  saveCardBaseLogin(isValid: boolean) {
    if (!isValid) {
      return;
    }
    if (this.settingParam.BarcodeScanningInitiatorSequence == this.parameters.RFIDInitiatorSequence
      && this.settingParam.BarcodeScanningEndingSequence == this.parameters.RFIDEndingSequence) {
        this.alertsService.renderErrorMessage(Messages.ErrorWhileRFIDSequenceSameAsBarcode);
        return;
    }
    this.spinnerService.show();
    this.cardBasedLoginService.saveCardbasedLogin(this.parameters)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.alertsService.renderSuccessMessage(Messages.CardbasedLoginSaveSuccess);
          this.postSavedConfigurationService.showMessage();
        }, error: this.alertsService.showApiError
      });
  }

  cancelEditing() {
    this.router.navigate(['/manage/system-configuration']);
  }
}
