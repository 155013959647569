import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";
import { versionInfo } from "src/version";
import { ApplicationStateService } from "src/app/shared/services";

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

    constructor(private applicationStateService: ApplicationStateService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const modifiedReq = req.clone({
            headers: req.headers.set('X-Client-Version', versionInfo.versionOnly)
                .set('Terminal-Id', this.applicationStateService.terminalId?.toString() ?? "0")
        });

        return next.handle(modifiedReq);
    }

}
