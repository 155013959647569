<div id="container" class="row mx-0">
    <ul class="timer-ul m-t-20 col-12 col-sm-6 row flex-direction-column" [ngClass]="{'backColor': isReadyProduct}">
        <li class="btns-li col-lg-3 col-md-4 col-4 col-sm-5 pt-pix-10 pb-pix-10 action-buttons">
            <div class="col-12 p-0">
                <button class="start col-12" [disabled]="isActiveTimer"
                    (click)="startTimer(timerData)">Start</button>
            </div>
            <div class="col-12 p-0">
                <button class="stop col-12" [disabled]="isTimerStop" (click)="stopTimer(timerData)">Stop</button>
            </div>
            <div class="col-12 p-0" *ngIf="!option.IsManual">
                <button class="product col-12" [disabled]="isResetProduct" [hidden]="option.IsManual"
                    (click)="productClick()"
                    [ngClass]="{'highlight-productButton': timerProducts.length && !option.OrderId && option.TimerOptionsProperty != timerTerminalOptions.PRECONFIGURED_TIMERS}">Product</button>
            </div>
            <div class=" col-12 p-0">
                <button class="reset col-12" (click)="resetTimer(timerData)">Reset</button>
            </div>
        </li>
        <div class="col-lg-7 col-md-6 col-8 col-sm-7 p-0 counter-div">
            <div class="counter" [ngClass]="{'running': isActiveTimer}">{{counter}}</div>
            <div class="nm" *ngIf="productName">{{productName}}</div>
        </div>
        <div class="col-lg-2 col-8 col-sm-6 p-0 tmr-li" *ngIf="option.HasManualAdjustment">
            <button class="up" [disabled]="isActiveUpDown" (click)="timerUp()"></button>
            <button class="down" [disabled]="isActiveUpDown" (click)="timerDown()"></button>
            <label *ngIf="option && option.TimerData && option.TimerData.IsUpdate"
                class="btn-warning btn-Updated p-pix-5">Updated</label>
        </div>
    </ul>

</div>
