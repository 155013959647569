import { DeleteColumn, EditColumn, LinkColumn, GridColumn } from '@tarktech/tark-ng-utils';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AlertsService, ModalService, SpinnerService, ConfirmDeleteComponent, AuthenticationService, PrintTableService,
  BaseGridComponent, ApplicationStateService
} from 'src/app/shared';
import { ProductLossReasonService } from '../../service/product-loss-reason.service';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { ProductLossReason } from '../../interface';
import { ProductLossReasonEditComponent } from '../product-loss-reason-edit';
import { Permissions, Levels } from '../../../../shared/constants/';
import { ActivatedRoute, Router } from '@angular/router';
import { commentWarningsComments } from 'src/app/shared/components/icon';


@Component({
  selector: 'pos-product-loss-reasons',
  templateUrl: './product-loss-reasons.component.html',
  styleUrls: ['./product-loss-reasons.component.scss']
})
export class ProductLossReasonsComponent extends BaseGridComponent implements OnInit {

  productLossReasons: ProductLossReason[] = [];
  lossReasonColumns: Array<GridColumn> = [];
  printLossReasonColumns: Array<GridColumn> = [];
  productId = 0;
  permission = {
    name: Permissions.InventoryLossReason,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };
  icons =  { commentWarningsComments };
  @ViewChild('confirmButtonDelete') confirmButtonDelete: ConfirmDeleteComponent;
  sortName = 'Name';
  reverse = false;
  message: string = Messages.ConfirmDeleteProductLossReason;
  constructor(
    private alertService: AlertsService,
    private inventoryService: ProductLossReasonService,
    private modalService: ModalService,
    private spinnerService: SpinnerService,
    private authenticationService: AuthenticationService,
    protected applicationStateService: ApplicationStateService,
    protected route: ActivatedRoute,
    private printService: PrintTableService,
    private router: Router,
  ) {
    super(applicationStateService, route);
    this.loadProductLossReason();
  }

  ngOnInit() {
    this.configureGridColumns();
  }

  loadProductLossReason() {
    this.spinnerService.show();
    this.inventoryService.getProductLossResasons().subscribe(
      response => {
        if (response) {
          this.productLossReasons = response;
        } else {
          this.productLossReasons = [];
        }
      },
      this.alertService.showApiError,
      () => {
        this.spinnerService.hide();
      }
    );
  }

  editProductLoss(data) {

    const modalRef = this.modalService.show(ProductLossReasonEditComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        productId: data
      }
    });

    modalRef.close.subscribe(res => {
      if (res && res.shouldReload) {
        this.loadProductLossReason();
      }
    });

  }

  onConfirmAcknowledge() {
    this.spinnerService.show();
    this.inventoryService.deleteProductLossResason(this.productId).subscribe(
      () => {
        this.alertService.renderSuccessMessage(
          Messages.ProductLossReasonDelete
        );
        this.loadProductLossReason();
      },
      this.alertService.showApiError,
      () => {
        this.spinnerService.hide();
      }
    );
  }

  deleteProductLoss(productId: number): void {
    this.productId = productId;
    this.confirmButtonDelete.Show(this.message);
  }

  sortList(value: string) {
    if (this.sortName === value) {
      this.reverse = !this.reverse;
    }
    this.sortName = value;
  }

  private configureGridColumns() {
    const deleteColumn = new DeleteColumn({
      Clicked: (data) => {
        this.deleteProductLoss(data.id);
      },
      Width: '83px',
      PrintIcon: new LinkColumn({
        Clicked: () => {
          this.printData();
        }
      })
    });

    const editColumn = new EditColumn({
      Clicked: (data) => {
        this.editProductLoss(data.id);
      },
      Width: '57px',
      AddIcon: new LinkColumn({
        Clicked: () => {
          this.editProductLoss(0);
        },
        ToolTip: 'New'
      })
    });

    this.lossReasonColumns = [
      new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '95%' }),
    ] as Array<GridColumn>;

    this.printLossReasonColumns = [...this.lossReasonColumns];

    if (this.authenticationService.userHasPermission([{ 'Name': this.permission.name, 'Level': this.permission.editLevel }], 'any')) {
      this.lossReasonColumns.push(editColumn);
      this.lossReasonColumns.push(deleteColumn);
    }

  }

  printData() {
    this.printService.printEmitter.next({ gridColumns: this.printLossReasonColumns, gridData: this.productLossReasons });
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}

