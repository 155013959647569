import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { ModalService } from 'src/app/shared/components/modal';
import { ModalFormComponent } from 'src/app/shared/components/modal-form';
import { ModalComponent } from 'src/app/shared/components/modal/modal-component';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { FormUtilityService } from 'src/app/shared/services/form-utility.service';
import { OnlineOrderingScheduleOverrides } from '../../interface/online-ordering-schedule-override';
import { OnlineOrderingScheduleOverridingService } from '../../service/online-ordering-schedule-overriding.service';

@Component({
  selector: 'pos-schedule-override-edit',
  templateUrl: './schedule-override-edit.component.html',
  styleUrls: ['./schedule-override-edit.component.scss']
})
export class ScheduleOverrideEditComponent extends ModalFormComponent implements OnInit {

  @ViewChild('scheduleOverrideForm') scheduleOverrideForm: NgForm;
  get getForm(): NgForm {
    return this.scheduleOverrideForm
  }
  scheduleOverrides: OnlineOrderingScheduleOverrides;
  schedules: Array<OnlineOrderingScheduleOverrides> = [];
  scheduleOverrideId: number;
  scheduleStatus: boolean = false;
  inValidToDate = false;
  inValidFromDate = false;
  dateFormat = 'mm-dd-yy';
  conflictingSchedule: OnlineOrderingScheduleOverrides;

  constructor(protected spinnerService: SpinnerService,
    private onlineOrderScheduleOverrideService: OnlineOrderingScheduleOverridingService,
    private alertService: AlertsService,
    private applicationStateService: ApplicationStateService,
    onlineOrderScheduleOverrideModalRef: BsModalRef,
    public modalService: ModalService,
    protected formUtilityService: FormUtilityService) {
    super(onlineOrderScheduleOverrideModalRef, modalService, formUtilityService);
    this.scheduleOverrides = this.onlineOrderScheduleOverrideService.getNewOverrideSchedule();
    this.conflictingSchedule = this.onlineOrderScheduleOverrideService.getNewOverrideSchedule();
    this.dateFormat = this.applicationStateService.settingParam.PCalendarDateFormat;
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.spinnerService.show();
    this.onlineOrderScheduleOverrideService.getAll().pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (response) => {
        this.schedules = response;
        if (this.scheduleOverrideId) {
          const scheduleOverride = { ...this.schedules.find(x => x.Id === this.scheduleOverrideId) };
          this.schedules = this.schedules.filter(x => x.Id !== this.scheduleOverrideId);
          this.scheduleOverrides = this.onlineOrderScheduleOverrideService.setOverrideSchedule(scheduleOverride);
          if (this.scheduleOverrides.StartTime || this.scheduleOverrides.EndTime) {
            this.scheduleStatus = true;
          }
        }

      }, error: this.alertService.showApiError
    });
  }

  onCancel(reload: boolean = false) {
    this.hide({ shouldReload: reload });
  }

  saveScheduleOverride(isValid: boolean) {
    this.checkForDateConflict();
    this.scheduleStatusChange();
    if (!isValid || this.inValidFromDate || this.inValidToDate) {
      return;
    }
    this.spinnerService.show();

    this.onlineOrderScheduleOverrideService.insert(this.scheduleOverrides).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (response) => {
        this.onSaveSuccess();
      }, error: this.alertService.showApiError
    });
  }

  onSaveSuccess() {
    this.onCancel(true);
    this.alertService.renderSuccessMessage(Messages.OnlineOrderScheduleOverrideSaveSuccess);
  }

  onDateChange() {
    if (Date.parse(this.scheduleOverrides.FromDate.toString()) > Date.parse(this.scheduleOverrides.ToDate.toString())) {
      this.scheduleOverrides.ToDate = this.scheduleOverrides.FromDate;
    }
    this.inValidToDate = false;
    this.inValidFromDate = false;
    this.checkForDateConflict();
  }

  scheduleStatusChange() {
    if (!this.scheduleStatus) {
      this.scheduleOverrides.StartTime = null;
      this.scheduleOverrides.EndTime = null;
    }
  }

  checkForDateConflict() {
    this.schedules.forEach((data) => {
      if (Date.parse(data.FromDate.toString()) <= Date.parse(this.scheduleOverrides?.FromDate.toString()) &&
        Date.parse(data.ToDate.toString()) >= Date.parse(this.scheduleOverrides?.FromDate.toString())) {
        this.inValidFromDate = true;
        this.conflictingSchedule.FromDate = new Date(data.FromDate);
        this.conflictingSchedule.ToDate = new Date(data.ToDate);
      }
      if (Date.parse(data.FromDate.toString()) <= Date.parse(this.scheduleOverrides?.ToDate.toString()) &&
        Date.parse(data.ToDate.toString()) >= Date.parse(this.scheduleOverrides?.ToDate.toString())) {
        this.inValidToDate = true;
        this.conflictingSchedule.FromDate = new Date(data.FromDate);
        this.conflictingSchedule.ToDate = new Date(data.ToDate);
      }
      if (Date.parse(data.FromDate.toString()) >= Date.parse(this.scheduleOverrides?.FromDate.toString()) &&
        Date.parse(data.ToDate.toString()) <= Date.parse(this.scheduleOverrides?.ToDate.toString())) {
        this.inValidFromDate = true;
        this.inValidToDate = true;
        this.conflictingSchedule.FromDate = new Date(data.FromDate);
        this.conflictingSchedule.ToDate = new Date(data.ToDate);
      }
    });
  }


  validateTime = (control: AbstractControl) => {
    let startTime = control?.get('startTime');
    let endTime = control?.get('endTime');
    if (startTime?.value && endTime?.value) {
      const start = startTime.value.split(':');
      const end = endTime.value.split(':');
      if (parseInt(start[0]) > parseInt(end[0])) {
        endTime.setErrors({ inValidScheduleStatusTime: true });
      }
      else if (parseInt(start[0]) === parseInt(end[0]) && parseInt(start[1]) > parseInt(end[2])) {
        endTime.setErrors({ inValidScheduleStatusTime: true });
      } else {
        endTime.setErrors(null);
      }
    }
    return control.errors;
  }

}
