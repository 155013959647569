import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { InventoryZone } from '../../../interface';
declare let $: any;

@Component({
  selector: 'pos-inventory-zone-edit-details',
  templateUrl: './inventory-zone-edit-details.component.html',
  styleUrls: ['./inventory-zone-edit-details.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm}]
})
export class InventoryZoneEditDetailsComponent implements OnInit, OnChanges {

  @Input() inventoryZone: InventoryZone;
  @Input() parentForm: NgForm;
  @Output() isInventoryZoneDetailValid = new EventEmitter<any>();
  makeInventoryDetailColor = { backgroundColor: '' };
  props = {
    labelClass: '',
    controlBoxClass: 'col-lg-4 col-md-5 col-sm-6 col-xs-12'
  }

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (this.inventoryZone) {
      if (this.inventoryZone.Id > 0) {
        this.isInventoryZoneDetailValid.emit({IsDataValid: true, changedDataInventoryDetail: this.inventoryZone});
      } else {
        this.isInventoryZoneDetailValid.emit({IsDataValid: false, changedDataInventoryDetail: this.inventoryZone});
      }
    }
  }

  ngOnInit() {
  }

  getHeight(isOuterContainer: boolean) {
    if (isOuterContainer === true) {
      return $(window).height() - 290 + 'px';
    } else {
      return $(window).height() - 310 + 'px';
    }
  }

}
