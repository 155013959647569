import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { SalesGroup } from '../../information-management/sales-groups/interface/sales-groups';
import { Observable } from 'rxjs';
import { BaseCrudService } from './base-crud.service';

@Injectable()
export class SalesGroupService extends BaseCrudService<SalesGroup> {

    salesGroupApiUrl = '';
    constructor(httpService: HttpClient) {
        super('salesgroup', httpService);
        this.salesGroupApiUrl = RuntimeConstants.API_PATH + 'salesgroup';
    }

    newSalesGroup() {
        return {
            Id: 0,
            name: '',
            make_table_color: '',
            IsTimedPrep: false,
            IsUseWorkflowTimer: false,
            PrepGroupName: '',
            PrepTime: null,
            PrepButtonColor: '',
            CurrentPage: 0,
            DateEdited: null,
            KitchenPrinterList: null,
            PageSize: 0,
            DateAdded: new Date()
        };
    }

    getSalesGroups(): Observable<any[]> {
        return this.httpService.get<any[]>(this.salesGroupApiUrl);
    }
    getMappedKitchenPrinters(salesGroupId: number): Observable<any[]> {
        return this.httpService.get<any[]>(`${this.salesGroupApiUrl}/` + salesGroupId + '/kitchenPrinters');
    }
}
