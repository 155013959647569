<div>
  <button class="close" data-dismiss="modal" type="button" (click)="onRejectConfirm('close')">
    <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
  </button>
  <h4 class="modal-content__heading">{{modalHeaderText}}</h4>
</div>
<div class="modal-body pb-0" [ngClass]="{'text-center': !rejectButtonText}">
  <span innerHTML="{{message}}"></span>
  <div class="col-12 p-0 mt-pix-10">
    <p-checkbox name="active" #active="ngModel" [(ngModel)]="sendEmail" binary="true"
      label="Notify me via email when backup is completed">
    </p-checkbox>
  </div>
</div>
<div class="col-12 action-button-container popup-footer pb-pix-10">
  <button class="btn btn-primary btn-lg btn-save pos-button" id="confirmButton" (click)="onConfirm()"
    type="button">{{confirmButtonText}}</button>
  <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="onRejectConfirm('reject')" type="button"
    *ngIf="rejectButtonText">{{rejectButtonText}}</button>
</div>
<div class="clearfix"></div>
