import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { cloneDeep, find, findLast, forEach, unionBy, uniqBy } from 'lodash';
import { finalize } from 'rxjs/operators';
import { ParsedIngredient } from 'src/app/information-management/raw-ingredients/interface/parsed-ingredient';
import { RawIngredientDescriptor } from 'src/app/orders';
import { AlertsService, ICloseable, ModalService, SpinnerService } from 'src/app/shared';
import { RawIngredientDescriptorService } from '../../service/raw-ingredient-descriptor.service';
import { ImportIngredientPreviewComponent } from '../import-ingredient-preview/import-ingredient-preview.component';
import { ingredientImport } from './../../../../shared/components/icon/icons';
@Component({
  selector: 'pos-import-raw-ingredients',
  templateUrl: './import-raw-ingredients.component.html',
})
export class ImportRawIngredientsComponent implements OnInit, ICloseable {
  close: EventEmitter<any> = new EventEmitter();
  @ViewChild('formImportIngredients') formImportIngredients: NgForm;
  addInExisting: boolean = false;
  subIngredient: string = '';
  ingredients: string = '';
  parsedIngredients: Array<ParsedIngredient> = [];
  contains2OrLessPctStrings = ['contains 2% or less of:', 'contains 2% or less of', 'contains 2% or less of the following:', 'contains 2% or less of the following', 'contains less than 2% of:', 'contains less than 2% of'];
  ingredientString = '';
  public icons = {
    ingredientImport
  };
  rawIngredientDescriptors: Array<RawIngredientDescriptor> = [];
  constructor(private modalService: ModalService, private rawIngredientDescriptorService: RawIngredientDescriptorService,
    private spinnerService: SpinnerService, private alertService: AlertsService) { }

  ngOnInit(): void {
    this.getRawIngredientDescriptors();
  }

  getRawIngredientDescriptors = () => {
    this.spinnerService.show();
    this.rawIngredientDescriptorService.getAll()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.rawIngredientDescriptors = res ? res : [];
        }, error: this.alertService.showApiError
      });
  }


  onCancel() {
    this.close.emit();
  }

  convertToLowerCase = () => {
    this.ingredientString = cloneDeep(this.ingredients.toLowerCase());
  }

  trimWhiteSpaces = () => {
    this.ingredientString = this.ingredientString.trim();
  }

  removeTrailingPeriod = () => {
    if (this.ingredientString.endsWith('.')) {
      this.ingredientString = this.ingredientString.slice(0, -1);
    }
  }

  removeSpaceAfterComma = () => {
    const r = new RegExp(', ', 'g');
    this.ingredientString = this.ingredientString.replace(r, ',');
  }

  replaceSquareBrackets = () => {
    this.ingredientString = this.ingredientString.replace(/\n/g, '');
    this.ingredientString = this.ingredientString.replace(/\[/g, '(');
    this.ingredientString = this.ingredientString.replace(/\]/g, ')');
    this.ingredientString = this.ingredientString.replace(/ \(/g, ',(');
    this.ingredientString = this.ingredientString.replace(/\(/g, ',(');
  }

  removePrefix = () => {
    this.ingredientString = this.ingredientString.replace('ingredients: ', '');
    this.ingredientString = this.ingredientString.replace('ingredient: ', '');
  }

  countString(str, letter) {
    let count = 0;

    // looping through the items
    for (let i = 0; i < str.length; i++) {

      // check if the character is at that position
      if (str.charAt(i) == letter) {
        count += 1;
      }
    }
    return count;
  }

  prepareIngredients = () => {
    this.parsedIngredients = [];
    let rawIngredientList;
    const parentIngredient = [];
    let lastIngredient;
    let ingredientValue = cloneDeep(this.ingredientString);
    if (this.addInExisting) {
      ingredientValue = `${this.subIngredient.toLowerCase()},(${this.ingredientString}`;
    }
    rawIngredientList = ingredientValue.split(',');
    forEach(rawIngredientList, (value) => {
      if (value) {
        let isPop = false;
        const comment = find(this.rawIngredientDescriptors, (item) => {
          return value.includes(`(${item.Name})`) || value.includes(`[${item.Name}]`)
        });
        if (value.startsWith('(')) {
          parentIngredient.push(lastIngredient);
          this.parsedIngredients[this.parsedIngredients.length - 1].IsParent = true;
          value = value.substr(1);
        }
        const bracketCount = this.countString(value, ')');
        for (let count = 0; count < bracketCount; count++) {
          if (value.endsWith(')')) {
            isPop = true;
            value = value.slice(0, -1);
          }
        }
        if (comment && this.parsedIngredients && this.parsedIngredients.length) {
          this.parsedIngredients[this.parsedIngredients.length - 1].Name += ' ' + `(${value})`;
        } else {
          const item = this.addIngredientToList(value, parentIngredient);
          lastIngredient = item;
        }
        const is2pct = false;
        this.popParentIngredients(isPop, bracketCount, parentIngredient);
      }
    });
    if (this.addInExisting) {
      this.removeDuplicate();
    }
    if (this.parsedIngredients && this.parsedIngredients.length) {
      this.openPreviewModal();
    }
  }



  removeDuplicate = () => {
    this.parsedIngredients = uniqBy(this.parsedIngredients, v => [v.Name, v.Parent].join());
  }

  openPreviewModal = () => {
    const modal = this.modalService.getModalWrapper(ImportIngredientPreviewComponent);
    const modalRef = modal.show({
      animated: false,
      class: 'vertical-center modal-max-width-40',
      initialState: {
        ingredients: this.parsedIngredients
      }
    });
    modalRef.close.subscribe((res) => {
      if (res && res.shouldImport) {
        this.close.emit({ shouldImport: true, ingredients: this.parsedIngredients });
      }
    });
  }

  parseIngredients = (isValid) => {
    if (!isValid) {
      return;
    }
    // step 1 - lower case
    this.convertToLowerCase();
    // step 2 - trim
    this.trimWhiteSpaces();
    // step 3 - remove trailing period
    this.removeTrailingPeriod();
    // step 4 - remove spaces after commas
    this.removeSpaceAfterComma();
    // step 5 - replace square brackets with parentheses; parens with commas
    this.replaceSquareBrackets();
    // step 6 - remove prefix
    this.removePrefix();
    // step 7 - prepare ingredient
    this.prepareIngredients();
  }


  private popParentIngredients(isPop: boolean, bracketCount: number, parentIngredient: any[]) {
    if (isPop) {
      for (let count = 0; count < bracketCount; count++) {
        parentIngredient.pop();
      }
    }
  }

  private isLessThen2Pct(value: any, is2pct: boolean, parentIngredient: any[]) {
    value = value.trim();
    if (parentIngredient && parentIngredient.length && parentIngredient[parentIngredient.length - 1].Is2pct) {
      is2pct = true;
    } else {
      forEach(this.contains2OrLessPctStrings, (item) => {
        if (value.startsWith(item)) {
          is2pct = true;
          value = value.replace(item, '').trim();
        }
      });
    }
    const lastParentWithSameLevel = findLast(this.parsedIngredients, (ing) => {
      return ing.Level == parentIngredient.length && ing.Parent == (parentIngredient.length === 0 ? '' : parentIngredient[parentIngredient.length - 1].Name); // && !ing.IsParent;
    });
    is2pct = !is2pct ? (this.parsedIngredients.length ?
      (parentIngredient.length === this.parsedIngredients[this.parsedIngredients.length - 1].Level ?
        this.parsedIngredients[this.parsedIngredients.length - 1].Is2pct : lastParentWithSameLevel ?
          lastParentWithSameLevel.Is2pct : this.parsedIngredients[this.parsedIngredients.length - 1].Is2pct)
      : is2pct) : is2pct;
    return { value, is2pct };
  }

  private addIngredientToList(value: any, parentIngredient: any[]) {
    let is2pct = false;
    ({ value, is2pct } = this.isLessThen2Pct(value, is2pct, parentIngredient));
    const item = {
      Name: value,
      Parent: (parentIngredient.length === 0 ? '' : parentIngredient[parentIngredient.length - 1].Name),
      Is2pct: is2pct,
      Level: parentIngredient.length
    };
    this.parsedIngredients.push(item);
    return item;
  }
}
