import { Injectable } from '@angular/core';
import { RuntimeConstants } from '../constants';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { InventoryBinLightModel } from '../interface/inventory-bin-light-model';
import { InventoryBinLightDeviceModel } from '../interface/inventory-bin-light-device-model';

@Injectable()
export class InventoryBinLightService {

    inventoryBinLightApiBasePath = `${RuntimeConstants.API_PATH}inventorybinlights`;

    constructor(private httpService: HttpClient) { }

    getinventoryBinLights(inventoryBinId: number): Observable<InventoryBinLightModel[]> {
        return this.httpService.get<InventoryBinLightModel[]>(this.inventoryBinLightApiBasePath + '/' + inventoryBinId);
    }

    getinventoryBinLightDevices(): Observable<InventoryBinLightDeviceModel[]> {
        return this.httpService.get<InventoryBinLightDeviceModel[]>(this.inventoryBinLightApiBasePath + '/getLightingDevice');
    }

    addinventoryBinLight(inventoryBinLight: InventoryBinLightModel): Observable<any> {
        return this.httpService.post(this.inventoryBinLightApiBasePath, inventoryBinLight);
    }

    updateinventoryBinLight(inventoryBinLight: InventoryBinLightModel): Observable<any> {
        return this.httpService.put(this.inventoryBinLightApiBasePath + '/' + inventoryBinLight.Id, inventoryBinLight);
    }

    deleteinventoryBinLight(inventoryBinLightId: number): Observable<any> {
        return this.httpService.delete(this.inventoryBinLightApiBasePath + '/' +  inventoryBinLightId);
    }

}
