<pos-text-widget [widgetConfiguration]="model" [adfModel]="adfModel" [config]="model.config"
    [isInteractive]="isInteractive" *ngIf="model.type === 'text_widget'" [column]="column"></pos-text-widget>
 <pos-chart-widget [widgetConfiguration]="model" [adfModel]="adfModel" [config]="model.config" [column]="column"
    [isInteractive]="isInteractive" *ngIf="model.type === 'chart_widget'"></pos-chart-widget>
<pos-gauge-widget [widgetConfiguration]="model" [adfModel]="adfModel" [config]="model.config" [column]="column"
    [isInteractive]="isInteractive" *ngIf="model.type === 'gauge_widget'" [isFullScreenMode]="isFullScreenMode"></pos-gauge-widget>
<pos-iframe-widget [widgetConfiguration]="model" [config]="model.config" [adfModel]="adfModel" [column]="column"
    [isInteractive]="isInteractive" *ngIf="model.type === 'iframe'">
</pos-iframe-widget>
<pos-table-widget [widgetConfiguration]="model" [adfModel]="adfModel" [config]="model.config" [column]="column"
    [isInteractive]="isInteractive" *ngIf="model.type === 'table_widget'">
</pos-table-widget>
