import { Component, OnInit, Input } from '@angular/core';
import { SpinnerService } from 'src/app/shared/components';
import { AlertsService, Messages, ApplicationStateService } from 'src/app/shared';
import { finalize } from 'rxjs/operators';
import { LightingService } from '../../service/lighting.service';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'pos-light-test',
  templateUrl: './light-test.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class LightTestComponent implements OnInit {

  @Input() hardwareId: number;
  lightAddress: string;
  terminalDetails: { TerminalId: number, TerminalName: string };
  isLightFlash = false;

  constructor(private lightingService: LightingService,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private applicationStateService: ApplicationStateService) { }

  ngOnInit() {
    this.terminalDetails = {
      TerminalId: this.applicationStateService.terminalId,
      TerminalName: this.applicationStateService.terminalName
    };
  }

  triggerLightAll() {
    this.spinnerService.show();
    this.lightingService.triggerLight(this.hardwareId, 'all', this.terminalDetails)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.alertService.renderSuccessMessage(Messages.RequestSendSuccess);
        }, error: this.alertService.showApiError
      });
  }

  triggerResetLightAll() {
    this.spinnerService.show();
    this.lightingService.triggerUnlight(this.hardwareId, 'all', this.terminalDetails)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.alertService.renderSuccessMessage(Messages.RequestSendSuccess);
        }, error: this.alertService.showApiError
      });
  }

  triggerFlash() {
    this.isLightFlash = false;
    if (!this.lightAddress) {
      this.isLightFlash = true;
    } else {
      this.spinnerService.show();
      this.lightingService.triggerFlash(this.hardwareId, this.lightAddress, this.terminalDetails)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res) => {
            this.lightAddress = '';
            this.alertService.renderSuccessMessage(Messages.RequestSendSuccess);
          }, error: this.alertService.showApiError
        });
    }
  }

}
