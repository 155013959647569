<pos-list-wrapper [headingIcon]="icons.salesSizesFunnelDollar" heading="Sales Sizes" (close)="close()"
  permissions='[{"Name":"{{permission.name}}", "Level":"{{permission.readOnlyLevel}}"}]'>
  <ng-table #salesSizeGrid [sort]="false" [paginator]="false" [columns]="salesSizeColumn" [data]="salesSizes"
    [isReorderableTable]="true" [ReorderableColumnRow]="'row'" [exportFilename]="'sales-sizes'"
    (onRowReorder)="gridRowReorder($event)">
  </ng-table>
  <pos-confirm-delete [message]="message" (onConfirmAcknowledge)="onConfirmAcknowledge()" #confirmSalesSizeDelete>
  </pos-confirm-delete>
  <pos-print-table [hidden]="true"></pos-print-table>
</pos-list-wrapper>
