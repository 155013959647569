<div class="order-section__products__block row mx-0" id="screenPreview">
  <span class="px-pix-5" *ngIf="!buttons || !buttons.length">No button(s) found.</span>
  <div *ngFor="let od of buttons" class="product-group p-0 col-lg-3 col-md-3 col-sm-4 col-4"
    [ngStyle]="{'width':buttonWidth ,'height' : buttonHeight + 'px'}">
    <div class="ps-pix-5 pe-pix-5 pt-pix-4 pb-pix-4" [ngStyle]="{'background-color':od.BackgroundColor}">
      <div class="product-block">
        <div class="text-align-center product-block__detail" [ngStyle]="{ 'border': od.BorderColor?'4px solid' + od.BorderColor:'default' }">
          <div class="product-block__detail__block" [ngStyle]="{'background-color':od.Color}">
            <div class="product-block__detail__info" [ngStyle]="{'height': ((od.BorderColor && od.BorderColor != '') ? buttonHeight - 4 : buttonHeight) + 'px'}"
            [ngClass]="{'stripe-even': od.EmbeddedScreenId && (od.EmbeddedScreenNumber % 2 == 0), 
              'stripe-odd': od.EmbeddedScreenId && (od.EmbeddedScreenNumber % 2 != 0)}">
              <div *ngIf="od.Image" class="product-block__detail__info__img-section">
                <img alt="" class="product-block__detail__info__img-section__img" src="{{imageBaseFile}}/buttons/{{od.Image}}"
                [ngStyle]="{'height': imageSize }" />
              </div>
              <div class="product-block__detail__info__name"
                [ngClass]="{'text-ellipsis product-block__detail__info__name--img': od.Image,'product-block__detail__info__name--no-img': !od.Image}"
                [ngStyle]="{'color':od.TextColor}">
                {{od.ButtonText}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
