<pos-edit-wrapper heading="Delivery / Dispatch" (close)="onCancel()">
  <div class="col-12 pos-modal-body row mx-0 screen-scroll pt-pix-5">
    <div class="col-md-4 col-12 p-0 pr-custom-pix-sm-0">
      <fieldset class="fieldset" id="AccountFieldSet">
        <legend class="reset-style bold">
          <span class="bold"> Drivers In</span>
        </legend>
        <div class="col-12 panel-overflow dispatch-panel px-pix-5">
          <div class="dispatch-drivers row mx-0" (click)="driverSelection(item)"
            [ngClass]="{'selected-driver': item.IsSelected}" *ngFor="let item of driverDetails"
            [hidden]="item.DeliveryCount > 0 || !item.IsActiveDelivery">
            <div class="col-65 pt-pix-2 ps-pix-5">
              <img alt="" class="col-37" src="images/icons/active-driver.svg" />
            </div>
            <div class="col-xl-8 col-sm-6 col-8 vertical-center-align p-0">
              <div class="col-12 p-0">
                <b>{{item.Name}}</b>
              </div>
              <div class="col-12 p-0" *ngIf="item.DeliveryCount">
                {{item.DeliveryCount}} Deliveries
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
    <div class="col-md-4 col-12 p-0 pr-custom-pix-sm-0">
      <fieldset class="fieldset" id="AccountFieldSet">
        <legend class="reset-style bold">
          <span class="bold">Delivery Orders</span>
        </legend>
        <div class="col-12 panel-overflow dispatch-panel px-pix-5">
          <div class="dispatch-orders col-12 row mx-0"
            [ngClass]="{'selected-orders': item.IsSelected, 'pointers-none': !selectedDriver || item.OutForDelivery }"
            (click)="selectOrder(item)" *ngFor="let item of deliveryTypeOrders">
            <div class="col-37 p-0" *ngIf="item.OutForDelivery">
              <img alt="" class="" src="images/icons/order-assigned.svg" [ngStyle]="{'max-height': 37, 'min-width': 35}" />
            </div>
            <div class="col-37 p-0" *ngIf="!item.OutForDelivery">
              <img alt="" class="" src="images/icons/order-notassigned.svg"
                [ngStyle]="{'max-height': 37, 'min-width': 35}" />
            </div>
  
            <div class="col-xl-10 col-sm-9 col-10 p-0">
              <div class="col-12 p-0">
                <div class="">
                  <b>{{item.CustomerAccountName ? 'Account: ' + item.CustomerAccountName + ' | ' : ''}}{{(item.AccountTypeId > 0 ? item.AccountType + ':' : '')}}
                    {{item.AccountName}}</b>
                </div>
              </div>
              <div class="col-12 p-0 row mx-0">
                <div class="col-3 p-0">#{{item.SurrogateOrderId}}</div>
                <div class="col-4 text-align-right px-pix-5">{{item.Total | tark_currency}}</div>
                <div class="float-end col-5 p-0 text-align-right">
                  {{item.EnteredDate | tark_time }}</div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
    <div class="col-md-4 col-12 p-0 pe-0">
      <fieldset class="fieldset" id="AccountFieldSet">
        <legend class="reset-style bold">
          <span class="bold"> Drivers Out on Delivery</span>
        </legend>
        <div class="col-12 panel-overflow dispatch-panel px-pix-5">
          <div class="dispatch-drivers col-12 row mx-0"
            [ngClass]="{'selected-driver': item.IsSelected}" (click)="driverSelection(item)"
            [hidden]="!item.DeliveryCount > 0 || !item.IsActiveDelivery" *ngFor="let item of driverDetails">
            <div class=" col-65 pt-pix-2 ps-pix-5">
              <img alt="" class="col-37" src="images/icons/drivers.svg" />
            </div>
            <div class="col-xl-8 col-sm-6 col-8 vertical-center-align row mx-0 p-0">
              <div class="col-12 p-0">
                <b>{{item.Name}}</b>
              </div>
              <div class="col-12 p-0">
                {{item.DeliveryCount}} Deliveries
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
  <div class="clearfix"></div>
  <div class="col-12 action-button-container popup-footer">
    <div class="pe-pix-5">
      <button type="button" class="btn-secondary btn-lg btn normal me-pix-5 pos-button" value="Cancel"
        (click)="onCancel()">Close</button>

      <button type="button" class="btn btn-primary btn-lg white normal me-pix-5 pos-button"
        *ngIf="selectedOrders && selectedOrders.length > 0 && selectedDriver && !selectedDriver.DeliveryCount"
        (click)="driverOut(false)" value="Driver Out">Driver Out</button>

      <button type="button" class="btn btn-primary btn-lg white normal me-pix-5 pos-button"
        *ngIf="selectedDriver && selectedOrders && selectedOrders.length == 0 && selectedDriver.DeliveryCount >0"
        (click)="driverIn()" value="Driver In">Driver In</button>

      <button type="button" class="btn btn-primary btn-lg white normal me-pix-5 pos-button"
        *ngIf="selectedDriver && selectedOrders && selectedOrders.length == 0 && selectedDriver.DeliveryCount > 0"
        (click)="terminalReport()" value="Info"> Info </button>

      <button type="button" class="btn btn-primary btn-lg white normal me-pix-5 pos-button"
        *ngIf="selectedDriver && selectedOrders && selectedOrders.length > 0 && selectedDriver.DeliveryCount > 0"
        (click)="driverOut(true)" value="Add Item">Add Item</button>

      <button type="button" class="btn btn-primary btn-lg white normal me-pix-5 pos-button"
        *ngIf="selectedDriver && selectedDriver.DeliveryCount > 0" (click)="printDelivery()"
        value="Add Item">Print</button>

    </div>
  </div>
</pos-edit-wrapper>
