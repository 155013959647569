<div class="col-12 row mx-0 mt-pix-5 relative" [ngClass]="styleClass">
    <div class="row py-pix-10 pe-0" *ngIf="newRuleModel.Condition">
        <div class="col-12 p-0 display-flex justify-content-between">
            <p-selectButton class="p-selectButton" [options]="operators" [(ngModel)]="newRuleModel.Condition" optionLabel="DisplayName"
                optionValue="DisplayName"></p-selectButton>
            <div class="float-right">
                <div class="btn-group">
                    <button (click)="addNewRule(newRuleModel)" type="button" value="Save"
                        class="btn btn-secondary normal pos-button">
                        <pos-icon [icon]="icons.newProduct" class="pe-pix-5"></pos-icon> Rule
                    </button>
                    <button (click)="addNewRuleSet(newRuleModel)" type="button" value="Save"
                        class="btn btn-secondary normal pos-button">
                        <pos-icon [icon]="icons.newProduct" class="pe-pix-5"></pos-icon> Ruleset
                    </button>
                </div>
                <button (click)="onDeleteRuleGroup()" type="button" value="Save" class="btn btn-secondary normal pos-button ms-pix-5"
                    *ngIf="level!= 1">
                    <pos-icon [icon]="icons.trashAltWhite" class="red"></pos-icon>
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="!newRuleModel.Rules.length" class="p-pix-10 well text-center">
        A ruleset cannot be empty. Please add a rule or remove it.
    </div>
    <div *ngFor="let rule of newRuleModel.Rules; let index = index; let last = last;">
        <span *ngIf="!rule.Condition">
            <div class="col-12 row mx-0 mt-pix-5 ms-pix-5 py-pix-15 rule-shadow criteria-config-parent relative"
                [ngClass]="!last ? 'rule' : 'last-rule'">
                <div class="col-12 row justify-content-between">
                    <div class="col-lg-5 col-md-7 col-sm-9 col-10"
                        [ngClass]="{'has-error': criteriaModel.invalid && form.submitted}">
                        <ng-select [items]="criteries" bindLabel="DisplayName" bindValue="Name" appendTo="body"
                            name="criteria{{index}}" #criteriaModel="ngModel" placeholder="Select {{criteriaCaption | lowercase}}"
                            [(ngModel)]="rule.Name" (change)="criteriaDropdownChange($event, rule)" required>
                        </ng-select>
                        <span *ngIf="criteriaModel.invalid && form.submitted">
                            <label class="control-label text-nowrap">Please select {{criteriaCaption | lowercase}}.</label>
                        </span>
                    </div>
                    <div class="col-1 col-35 float-right">
                        <button (click)="onDelete(index)" type="button" value="Save" class="btn btn-secondary normal pos-button">
                            <pos-icon [icon]="icons.trashAltWhite" class="red"></pos-icon>
                        </button>
                    </div>
                </div>
                <div class="col-12">
                    <pos-config *ngIf="rule.Config" [configs]="rule.Config" [inputName]="'criteria'+ level + index" [description]="rule.Description">
                    </pos-config>
                </div>
            </div>
        </span>
        <div *ngIf="rule.Condition" class="col-12 ps-pix-5 p-0">
            <pos-criteria [(newRuleModel)]="newRuleModel.Rules[index]" (delete)="onDelete($event)"
                [operators]="operators" [ruleIndex]="index" [criteries]="criteries" [level]="level+1"
                [styleClass]="!last ? 'rule' : 'last-rule'" parentElement="body"></pos-criteria>
        </div>
    </div>
</div>
