import { Component, OnInit } from "@angular/core";
import { ApplicationStateService } from "src/app/shared/services/application-state.service";


@Component({
  selector: 'pos-product-build-queue-terminal',
  templateUrl: './product-build-queue-terminal.component.html',
  styleUrls: ['./product-build-queue-terminal.component.scss']
})
export class ProductBuildQueueTerminalComponent implements OnInit {

  terminalId: number;
  headerLinks = {
    changeTerminal: false,
    mappedTerminals: false,
    time: false,
    signIn: false,
    tasks: false,
    activeOrders: false,
    admin: false,
    home: false,
    terminalDesignMode: true
  };

  constructor(private applicationStateService: ApplicationStateService) { }
  ngOnInit(): void {
    this.terminalId = this.applicationStateService.terminalId
  }
}
