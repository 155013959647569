import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalFormComponent, ModalService } from 'src/app/shared/components';
import { DomainConstants } from 'src/app/shared/constants';
import { ButtonBehavior } from 'src/app/shared/interface';
import { ApplicationStateService, FormUtilityService } from 'src/app/shared/services';

@Component({
  selector: 'pos-auto-discount',
  templateUrl: './auto-discount.component.html',
  styleUrls: ['./auto-discount.component.scss']
})
export class AutoDiscountComponent extends ModalFormComponent implements OnInit {
  @Input() selectedButtonBehaviorType;
  @Input() buttonBehavior: ButtonBehavior = null;
  @ViewChild('autoDiscountForm') autoDiscountForm: NgForm;

  get getForm(): NgForm {
    return this.autoDiscountForm;
  }

  discountPercent: number = 0;
  taxTypes = DomainConstants.TaxType;
  taxType = null;

  displayMessage: string = null;

  constructor(modalRef: BsModalRef, modalService: ModalService, protected formUtilityService: FormUtilityService
  ) {
    super(modalRef, modalService, formUtilityService);
  }

  ngOnInit(): void {
    if (this.buttonBehavior && this.buttonBehavior.Id) {
      try {
        const behavior = JSON.parse(this.buttonBehavior.TextValue);
        this.discountPercent = behavior ? behavior.discountPercent : 0;
        this.taxType = behavior ? behavior.taxType : DomainConstants.TaxType[0];
        this.setInitialValue();
      } catch (ex) {
        console.error('Invalid behavior value: ' + this.buttonBehavior.TextValue);
      }
    }
  }

  saveBehavior = (isValid: boolean) => {
    if (isValid) {
      this.hide({ shouldReload: true, Value: { discountPercent: this.discountPercent, taxType: this.taxType } });
    }
  }

  closeModal = () => {
    this.hide({ shouldReload: false });
  }
}
