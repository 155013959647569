<pos-edit-wrapper (close)="onCancel()" [isPopup]="false" [headingIcon]="icons.trainingBadge"
  heading="Training Badge">
    <form #formTrainingBadge="ngForm" (ngSubmit)="saveTrainingBadge(formTrainingBadge.valid)" autocomplete="off">
        <div class="col-12 border-top pt-pix-5 pos-page-body">
            <div class="col-12">
                <pos-row>
                    <pos-column>
                        <pos-form-field label="Name" [validationMessages]="{required: 'Please select badge name.'}" inputStyle="col-md-4 col-sm-5 col-xs-8">
                            <input posInput type="text" id="badgeName" name="badgeName" #badgeName="ngModel"
                                class="form-control" [(ngModel)]="badge.Name" [required]="true" />
                        </pos-form-field>
                    </pos-column>

                    <pos-column>
                        <pos-form-field label="Icon" inputStyle="col-sm-7 col-xs-12">
                            <pos-row class="p-0">
                                <pos-column sm="5" xs="8" [styleClass]="{'has-error': !showBadgeIcon && formTrainingBadge.submitted}">
                                    <input type="file" class="form-control" accept=".png,.jpg,.jpeg,.gif,.bmp"
                                        #badgeIcon name="badgeIcon" id="badgeIcon" (change)="handleFileInput($event.target.files)">
                                    <span *ngIf="!showBadgeIcon && formTrainingBadge.submitted">
                                        <label class="control-label">Please choose badge icon. </label>
                                    </span>
                                </pos-column>

                                <pos-column md="3" xs="4" class="p-0" [hidden]="!showBadgeIcon">
                                  <pos-row class="p-0">
                                    <pos-column md="7" sm="5" xs="8" class="col-50">
                                        <img src="{{imageRootPath}}/{{badge.Icon}}" width="50px" #badgeImage id="badgeImage" />
                                    </pos-column>

                                    <pos-column sm="4" xs="2" (click)="removeIcon()" class="mt-pix-n5">
                                      <pos-icon [icon]="icons.times" class="pointer remove-icon-badge"></pos-icon>
                                    </pos-column>
                                  </pos-row>
                                </pos-column>

                                <pos-column xs="1">
                                  <button type="button" (click)="selectIcon()" class="btn btn-secondary normal me-pix-5 pos-button">Choose</button>
                                </pos-column>
                              </pos-row>
                        </pos-form-field>
                    </pos-column>

                    <pos-column>
                        <pos-form-field label="Score Threshold"
                            [validationMessages]="{
                                required: 'Please enter score threshold.',
                                condition: 'Please enter valid score threshold.',
                                min: 'Please enter positive score threshold.'}">
                            <input posInput type="number" id="scoreThreshold" name="scoreThreshold" #scoreThreshold="ngModel" posOnlyNumbers
                                class="form-control mcol-100" [(ngModel)]="badge.ScoreThreshold" min="1" [required]="true" [conditionallyValidate]="scoreThreshold?.value % 10 == 0"/>
                        </pos-form-field>
                    </pos-column>
                </pos-row>
            </div>
        </div>
        <div class="col-12 action-button-container page-footer">
            <button type="submit" value="Save" class="btn btn-primary btn-lg btn-save pos-button" scrollup>Save</button>
            <button type="button" class="btn btn-secondary btn-lg btn-cancel pos-button" value="Cancel" (click)="onCancel()" scrollup>Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>

