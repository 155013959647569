<div class="row">
    <div class="col-md-10 col-12">
        <ng-table [columns]="locationAddressColumns" [paginator]="false" [exportFilename]="'location-address'"
            [data]="locationAddresses" [scrollHeight]="scrollHeight">
        </ng-table>
        <ng-template let-data="rowData" #operationHeaderTemplate>
            <div class="text-center">
                <pos-action-icon (click)="editItem(0)" [icon]="icons.plusWhite"
                    iconText="Add"></pos-action-icon>
            </div>
        </ng-template>
        <ng-template let-data="rowData" #operationTemplate>
            <div>
                <span (click)="editItem(data.id, data)">
                    <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
                </span>
                <span (click)="deleteItem(data.id)">
                    <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
                </span>
            </div>
        </ng-template>
    </div>
</div>
