import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ORDER_KIOSK_STATE } from './order-kiosk.routes';
import { FormsModule } from '@angular/forms';
import { KioskScreenListComponent } from './components/kiosk-screen-list/kiosk-screen-list.component';
import { KioskInvoiceComponent } from './components/kiosk-invoice/kiosk-invoice.component';
import { KioskFixedGridLayoutTemplateComponent } from './components/templates/kiosk-fixed-grid-layout-template/kiosk-fixed-grid-layout-template.component';
import { KioskDesignerGridComponent } from './components/kiosk-designer-grid/kiosk-designer-grid.component';
import { TableModule } from '@tarktech/tark-ng-utils';
import { KioskFooterComponent } from './components/kiosk-footer/kiosk-footer.component';
import { KioskHeaderComponent } from './components/kiosk-header/kiosk-header.component';
import { KioskNumpadComponent } from './components/kiosk-numpad/kiosk-numpad.component';
import { KioskKeyboardComponent } from './components/kiosk-keyboard/kiosk-keyboard.component';
import { OrderModule } from '../orders/order.module';
import { KioskScreenEditComponent } from './components/kiosk-screen-edit/kiosk-screen-edit.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { KioskScreenConfigComponent } from './components/kiosk-screen-config/kiosk-screen-config.component';
import { KioskScreenConfigEditComponent } from './components/kiosk-screen-config-edit/kiosk-screen-config-edit.component';
import { CheckboxModule } from 'primeng/checkbox';
import { KioskFluidGridLayoutTemplateComponent } from './components/templates/kiosk-fluid-grid-layout-template/kiosk-fluid-grid-layout-template.component';
import { KioskChoiceEditComponent } from './components/kiosk-choice-edit/kiosk-choice-edit.component';
import { NgxContextModule } from '@tarktech/ngx-context';
import { KioskSplitScreenLayoutTemplateComponent } from './components/templates/kiosk-split-screen-layout-template/kiosk-split-screen-layout-template.component';
import { KioskScreenTemplateComponent } from './components/templates/kiosk-screen-template/kiosk-screen-template.component';
import { KioskScreenTemplateDirective } from './directives/kiosk-screen-template.directive';
import { SetKioskButtonComponent } from './components/button-actions/set-kiosk-button/set-kiosk-button.component';
import { OrderKioskTerminalComponent } from './components/order-kiosk-terminal/order-kiosk-terminal.component';
import { OrderKioskPreviewComponent } from './components/order-kiosk-preview/order-kiosk-preview.component';
import { KioskOverlayComponent } from './components/kiosk-overlay/kiosk-overlay.component';
import { KioskUserIdleComponent } from './components/kiosk-user-idle-component/kiosk-user-idle.component';
import { KioskCancelOrderScreenComponent } from './components/kiosk-cancel-order-screen/kiosk-cancel-order-screen.component';
import { KioskOrderConfirmedComponent } from './components/kiosk-order-confirmed/kiosk-order-confirmed.component';
import { KioskConfirmCancelComponent } from './components/kiosk-confirm-cancel/kiosk-confirm-cancel.component';
import { KioskCheckoutScreenComponent } from './components/kiosk-checkout-screen/kiosk-checkout-screen.component';
import { NgIdleModule } from '@ng-idle/core';
import { WelcomeScreenTwoComponent } from './components/templates/welcome-screen-two/welcome-screen-two.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { BannerLayoutComponent } from './components/templates/banner-layout/banner-layout.component';
import { AllergensScreenOneComponent } from './components/templates/allergens-screen-one/allergens-screen-one.component';
import { AllergensScreenTwoComponent } from './components/templates/allergens-screen-two/allergens-screen-two.component';
import { KioskScreenSetupComponent } from './components/templates/kiosk-screen-setup/kiosk-screen-setup.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { KioskPaymentOptionsScreenComponent } from './components/kiosk-payment-options-screen/kiosk-payment-options-screen.component';
import { KioskManagementComponent } from './components/kiosk-management';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CKEditorModule } from 'ckeditor4-angular';
import { CreditCardPaymentProcessingComponent } from './components/credit-card-payment-processing/credit-card-payment-processing.component';
import { ButtonBehaviorEditComponent } from '../information-management/buttons/component/button-behavior-edit/button-behavior-edit.component';
import { OrderKioskNutritionFactsComponent } from './components/order-kiosk-nutrition-facts/order-kiosk-nutrition-facts.component';
import { SetKioskNextScreenComponent } from './components/button-actions';
import { KioskChoicesComponent } from './components/kiosk-choices';
import { KioskModalWrapperComponent } from './components/kiosk-modal-wrapper';
import { ComponentsModule } from '../shared/components/components.module';
import { RabbitMQService } from '../shared/services/rabbitmq';
import { KioskMessageStripComponent } from './components/kiosk-message-strip/kiosk-message-strip.component';
import { DesignButtonComponent } from './components/button-actions/design-button/design-button.component';
import { AllergensInquiryComponent } from './components/templates/allergens-inquiry/allergens-inquiry.component';
import { ImageInterceptorService } from './services/image-interceptor.service';
import { CancelOrderComponent, CheckoutConfigComponent, ConfirmOrderConfigComponent, GeneralConfigComponent, IdleTimeoutComponent, PaymentConfigComponent } from './components/configurations';
import { OrderKioskInitialScreenComponent } from './components/order-kiosk-initial-screen/order-kiosk-initial-screen.component';
import { AIShakeGroupSelectionComponent } from './components/templates/ai-shake-group-selection/ai-shake-group-selection.component';
import { AIShakeCreatedComponent } from './components/templates/ai-shake-created/ai-shake-created.component';
import { AiShakeConfigComponent } from './components/configurations/ai-shake-config/ai-shake-config.component';
import { KioskListComponent } from './components/kiosk-list/kiosk-list.component';
import { InputSwitchModule } from 'primeng/inputswitch';

@NgModule({
  declarations: [
    KioskScreenListComponent,
    KioskInvoiceComponent,
    KioskFixedGridLayoutTemplateComponent,
    KioskDesignerGridComponent,
    KioskFooterComponent,
    KioskHeaderComponent,
    KioskNumpadComponent,
    KioskModalWrapperComponent,
    KioskKeyboardComponent,
    KioskScreenEditComponent,
    KioskScreenConfigComponent,
    KioskScreenConfigEditComponent,
    KioskFluidGridLayoutTemplateComponent,
    KioskSplitScreenLayoutTemplateComponent,
    KioskScreenTemplateComponent,
    KioskScreenTemplateDirective,
    KioskManagementComponent,
    KioskChoicesComponent,
    KioskChoiceEditComponent,
    SetKioskButtonComponent,
    OrderKioskTerminalComponent,
    OrderKioskPreviewComponent,
    KioskOverlayComponent,
    KioskUserIdleComponent,
    KioskCancelOrderScreenComponent,
    KioskOrderConfirmedComponent,
    KioskConfirmCancelComponent,
    KioskCheckoutScreenComponent,
    WelcomeScreenTwoComponent,
    BannerLayoutComponent,
    AllergensScreenOneComponent,
    AllergensScreenTwoComponent,
    SetKioskNextScreenComponent,
    KioskScreenSetupComponent,
    KioskPaymentOptionsScreenComponent,
    CreditCardPaymentProcessingComponent,
    OrderKioskNutritionFactsComponent,
    KioskMessageStripComponent,
    DesignButtonComponent,
    CancelOrderComponent,
    IdleTimeoutComponent,
    ConfirmOrderConfigComponent,
    PaymentConfigComponent,
    CheckoutConfigComponent,
    GeneralConfigComponent,
    OrderKioskInitialScreenComponent,
    AllergensInquiryComponent,
    AIShakeGroupSelectionComponent,
    AIShakeCreatedComponent,
    AiShakeConfigComponent,
    KioskListComponent
  ],
  providers: [{ provide: BsModalRef, useClass: BsModalRef },
    RabbitMQService,
    ImageInterceptorService
  ],
  imports: [
    CommonModule,
    FormsModule,
    ComponentsModule,
    RouterModule.forChild(ORDER_KIOSK_STATE),
    TableModule,
    OrderModule,
    NgSelectModule,
    CheckboxModule,
    NgxContextModule,
    ButtonBehaviorEditComponent,
    NgIdleModule.forRoot(),
    NgxSliderModule,
    PopoverModule,
    CKEditorModule,
    InputSwitchModule,
  ]
})
export class OrderKioskModule { }
