<pos-kiosk-header *ngIf="screenConfig.Header.IsShow" [header]="screenConfig.Header" />
<div class="row mx-0 designer-area" [style.background-color]="screenConfig.BackgroundColor"
    [style.height]="sectionHeight + 'px'"
    [style.background-image]="screenConfig.BackgroundImage ? 'url(\''+ imageRootPath + screenConfig.BackgroundImage + '\')' : null">
    <div class="p-0 relative" [ngClass]="screenConfig.ShowInvoice ? 'col-xl-9 col-8' : 'col-12'">
        <div class="flavor-group" [style.height]="sectionHeight - (screenConfig.GridStartPosition ?? 0) - 10 + 'px'"
        [ngStyle]="{'position': 'absolute', 'top': (screenConfig.GridStartPosition ?? 0) + 'px'}">
            <div *ngFor="let flavorGroup of shakeFlavorGroups" class="flavor-button" [ngStyle]="{'width': itemWidth, 'border-color': buttonConfig.BackgroundColor}"
                (click)="buttonClick(flavorGroup.Id)">
                <div class="button-text" [ngStyle]="{'background-color': buttonConfig.BackgroundColor, 'color': buttonConfig.FontColor}"> {{ flavorGroup.Name }} </div>
                <img class="button-image" alt="" src="{{shakeGroupImageRootPath}}{{flavorGroup.Image}}" />
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-4 p-3" *ngIf="screenConfig.ShowInvoice" [ngStyle]="{'height': invoiceHeight - 3 + 'px'}">
        <pos-kiosk-invoice></pos-kiosk-invoice>
    </div>
</div>
<pos-kiosk-footer *ngIf="screenConfig.Footer.IsShow" [buttons]="screenButtons"
    [footer]="screenConfig.Footer"></pos-kiosk-footer>
