import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RuntimeConstants } from 'src/app/shared/constants';
import { Observable } from 'rxjs';

@Injectable()
export class LogService {
  logApiUrl = '';
  constructor(private httpService: HttpClient) {
    this.logApiUrl = RuntimeConstants.API_PATH + 'applicatonlog';
  }

  uploadLog(logModel): Observable<any> {
    return this.httpService.post(`${this.logApiUrl}/upload`, logModel);
  }
}
