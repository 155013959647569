import { Component, EventEmitter, Input, OnInit, ViewChild} from '@angular/core';
import { GridColumn } from '@tarktech/tark-ng-utils';
import { filter, forEach, remove } from 'lodash';
import { deleteWhite } from 'src/app/shared/components/icon';
import { DomainConstants } from 'src/app/shared/constants/domain-constants';
import { Messages } from 'src/app/shared/constants';
import { AlertsService, FormUtilityService } from 'src/app/shared/services';
import { ManualValuesModel } from '../../interface/parameter-model';
import { ModalFormComponent } from 'src/app/shared/components/modal-form';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalService } from 'src/app/shared/components/modal';

@Component({
  selector: 'pos-query-values',
  templateUrl: './query-values.component.html',
  styleUrls: ['./query-values.component.scss'],
})
export class QueryValuesComponent extends ModalFormComponent implements OnInit {
  @ViewChild('queryValuesForm') queryValuesForm: NgForm;			
  get getForm(): NgForm {
    return this.queryValuesForm
  }
  @Input() type: string;
  @Input() dataSourceParamName: string;
  queryValues: string = null;
  close: EventEmitter<any> = new EventEmitter();
  icons = {
    deleteWhite,
  };
  codeMirrorOptions = {
    lineNumbers: true,
    name: 'sql',
    lineWrapping: true,
  };
  manualValuesColumns: Array<GridColumn> = [];
  manualValues: Array<ManualValuesModel> = [];

  queryType = DomainConstants.queryType;

  constructor(private alertsService: AlertsService,
    public modalRef: BsModalRef,
    public modalService: ModalService,
    formUtilityService: FormUtilityService) {
    super(modalRef,modalService,formUtilityService);
  }

  ngOnInit(): void {
    if (this.type === this.queryType.Manual) {
      this.prepareManualValues();
    }
  }

  prepareManualValues() {
    if (this.queryValues) {
      let queryValues = [];
      queryValues = this.queryValues.split('|');
      forEach(queryValues, (x) => {
        this.manualValues.push({ Name: x.split(',')[1], Value: x.split(',')[0] });
      });
    }
    this.createParameterRow(true);
  }

  saveQueryValues(isValid: boolean) {
    let invalidNames = false;
    if (!isValid) {
      return;
    }
    forEach(this.manualValues, x => {
      const existingName = filter(this.manualValues, value => x.Name === value.Name);
      if (existingName.length > 1) {
        invalidNames = true;
      }
    });
    if (invalidNames) {
      this.alertsService.renderErrorMessage(Messages.DataSourceQueryDuplicateName);
      return;
    }
    remove(this.manualValues, x => !x.Name || !x.Value);
    if (this.type === this.queryType.Manual) {
      const queryValues = [];
      forEach(this.manualValues, (x) => {
        queryValues.push(x.Value + ',' + x.Name);
      });
      this.queryValues = queryValues.join('|');
    }
    this.close.emit({ shouldReload: true, values: this.queryValues });
  }

  createParameterRow(lastIndex: boolean) {
    if (!lastIndex && this.manualValues?.length > 1) {
      return;
    }
    this.manualValues.push({ Name: null, Value: null });
  }

  deleteValue(index: number) {
    this.manualValues.splice(index, 1);
  }

  cancel() {
    this.hide({ shouldReload: false });
  }
}
