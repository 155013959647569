import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { cloneDeep, find } from 'lodash';
import { DomainConstants } from 'src/app/shared';

import { EventSubscriptionActionAttribute } from '../../interfaces/event-subscription-action-attribute';
import { RepeatActionConfig } from '../../interfaces/repeat-action-config';
import { EventSubscriptionActionAttributeService } from '../../services';

@Component({
  selector: 'pos-repeat-action-configuration',
  templateUrl: './repeat-action-configuration.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class RepeatActionConfigurationComponent implements OnInit {

  @Input() eventSubscriptionActionAttributes: Array<EventSubscriptionActionAttribute> = [];
  @Output() eventSubscriptionActionAttributesChange = new EventEmitter<EventSubscriptionActionAttribute>();
  @Output() formChange = new EventEmitter<NgForm>();
  @Input() isRecoverableEvent;
  @Input() isFormSubmitted: boolean = false;
  maxDurationVal; maxTimeValue;
  untilXTime: RepeatActionConfig;
  untilXDuration: RepeatActionConfig;
  repeatUntilUnit = 'Times';
  durationUnits = DomainConstants.EventRepeatActionDurationUnit;
  untilDurationType = DomainConstants.EventRepeatUntilDurationType;
  repeatConfig: RepeatActionConfig;
  isRepeat: boolean = false;
  deliveryAttributes = DomainConstants.EventDeliveryAttributes;
  constructor(public eventSubscriptionActionAttributeService: EventSubscriptionActionAttributeService) {
    this.repeatConfig = eventSubscriptionActionAttributeService.newRepeatEventActionConfig();
    this.untilXTime = eventSubscriptionActionAttributeService.newRepeatEventActionConfig();
    this.untilXDuration = eventSubscriptionActionAttributeService.newRepeatEventActionConfig();
  }

  ngOnInit(): void {
    const config = find(this.eventSubscriptionActionAttributes,
      x => x.AttributeKey === this.deliveryAttributes.Email.Repeat)?.AttributeValue;
    if (config) {
      this.repeatConfig = JSON.parse(config);
      this.isRepeat = true;
      this.setRepeatConfig();
    }
  }

  resetRepeatConfig = () => {
    this.repeatConfig = this.eventSubscriptionActionAttributeService.newRepeatEventActionConfig();
  }


  private setRepeatConfig() {
    if (this.repeatConfig.Until.Type == this.untilDurationType.Times) {
      this.untilXTime = cloneDeep(this.repeatConfig);
    } else if (this.repeatConfig.Until.Type == this.untilDurationType.Duration) {
      this.untilXDuration = cloneDeep(this.repeatConfig);
    }
  }
}
