<div class="col-12 p-0 pt-pix-5">
    <div class="float-end mb-pix-10">
        <button type="button" class="btn btn-secondary normal pos-button" (click)="addCombo()">
            <pos-icon [icon]="icons.plus" class="pe-pix-5 font-12"></pos-icon> Combo
        </button>
    </div>
</div>
<div class="col-12 overflow-auto-tabs p-0" [ngStyle]="{'height': containerHeight}" cdkDropList
    (cdkDropListDropped)="drop($event)">
    <div class="col-12 item-box" cdkDrag *ngFor="let combo of comboItems; let i = index">
        <pos-row colXs="12" class="mx-0">
            <pos-column>
                <div class="row mx-0">
                    <pos-form-field class="col-6 p-0" label="Description" inputStyle="col-12">
                        <div>
                            <input posInput type="text" name="combo_description_{{i}}" [(ngModel)]="combo.Description"
                                class="form-control" />
                        </div>
                    </pos-form-field>
                    <div class="col-6">
                        <div class="col-1 p-0 col-35 float-end pt-pix-10">
                            <button cdkDragHandle type="button" class="btn btn-secondary normal widget-combo-move">
                                <pos-action-icon [icon]="icons.arrowsAlt" [additionalClasses]="['font-16', 'white']"
                                    [backgroundClasses]="['green']"></pos-action-icon>
                            </button>
                        </div>
                        <div class="col-1 p-0 col-35 float-end pt-pix-10 me-pix-10">
                            <button (click)="onDeleteItem(i)" type="button"
                                class="btn btn-secondary normal widget-combo-delete">
                                <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
                            </button>
                        </div>
                        <div class="col-1 col-35 float-end me-pix-10 pt-pix-10">
                            <button (click)="configureCombo(i)" type="button"
                                class="btn btn-secondary normal widget-combo-edit">
                                <pos-action-icon [icon]="icons.cogWhite"></pos-action-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </pos-column>
            <pos-column>
                <pos-form-field label="Sales Product">
                    <ng-select posInput name="itemSalesProduct_{{i}}" [items]="salesProductList" bindLabel="Name"
                        bindValue="id" placeholder="Select sales product" [hideSelected]="true"
                        [(ngModel)]="combo.SalesProductId" appendTo="body"></ng-select>
                </pos-form-field>
            </pos-column>
        </pos-row>
    </div>
</div>
