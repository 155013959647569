<pos-edit-wrapper heading="Account" (close)="onCancel()">
  <div class="col-12 container-fluid screen-scroll">
    <ul class="nav nav-tabs inner-tab pos-nav-tabs">
      <li [ngClass]="{'active': selectedTab == tabList.Account }">
        <a (click)="selectedTab = tabList.Account" class="blue">
          <pos-icon class="theme-separator pe-pix-5" [icon]="icons.tablesSolarPanel"></pos-icon>
          Account
        </a>
      </li>
      <li [ngClass]="{'active': selectedTab == tabList.Orders }">
        <a (click)="selectedTab = tabList.Orders" class="blue">
          <pos-icon class="theme-separator pe-pix-5" [icon]="icons.accountOrdersShoppingCart">
          </pos-icon>
          Orders
        </a>
      </li>
    </ul>
    <div class="pos-tab-body tab-content ignore-tabcontent-space w-100" [ngStyle]="{'height':getHeight(true)}">
      <div *ngIf="selectedTab == tabList.Account">
        <div class="overflow-auto-tabs" [ngStyle]="{'height':getHeight(false)}">
          <div class="row pt-pix-10">
            <div class="col-6 col-12 p-0 row mx-0">
              <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                <label class="label-section bold">Name:</label>
              </div>
              <div class="col-lg-9 col-md-9 col-sm-9 col-9">
                <label class="label-section">{{accountDetails.Name ? accountDetails.Name : 'N/A'}}</label>
              </div>
            </div>
            <div class="col-6 col-12 p-0 row mx-0">
              <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                <label class="label-section bold">Address:</label>
              </div>
              <div class="col-lg-9 col-md-9 col-sm-9 col-9">
                <label class="label-section">{{accountDetails.Address ? accountDetails.Address: 'N/A'}}</label>
              </div>
            </div>
            <div class="col-6 col-12 p-0 row mx-0">
              <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                <label class="label-section bold">Phone:</label>
              </div>
              <div class="col-lg-9 col-md-9 col-sm-9 col-9">
                <label class="label-section">{{accountDetails.Phone ? (accountDetails.Phone | phoneMaskPipe):
                  'N/A'}}</label>
              </div>
            </div>
            <div class="col-6 col-12 p-0 row mx-0">
              <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                <label class="label-section bold">City:</label>
              </div>
              <div class="col-lg-9 col-md-9 col-sm-9 col-9">
                <label class="label-section">{{accountDetails.City ? accountDetails.City : 'N/A'}}</label>
              </div>
            </div>
            <div class="col-6 col-12 p-0 row mx-0">
              <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                <label class="label-section bold">Category:</label>
              </div>
              <div class="col-lg-9 col-md-9 col-sm-9 col-9">
                <label class="label-section">{{accountDetails.AccountCategoryName ?
                  accountDetails.AccountCategoryName : 'N/A'}}</label>
              </div>
            </div>
            <div class="col-6 col-12 p-0 row mx-0">
              <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                <label class="label-section bold">State:</label>
              </div>
              <div class="col-lg-9 col-md-9 col-sm-9 col-9">
                <label class="label-section">{{accountDetails.State ? accountDetails.State : 'N/A'}}</label>
              </div>
            </div>
            <div class="col-6 col-12 p-0 row mx-0">
              <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                <label class="label-section bold">Notes:</label>
              </div>
              <div class="col-lg-9 col-md-9 col-sm-9 col-9">
                <label class="label-section">{{accountDetails.Notes ? accountDetails.Notes : 'N/A'}}</label>
              </div>
            </div>
            <div class="col-6 col-12 p-0 row mx-0">
              <div class="col-lg-3 col-md-3 col-sm-3 col-3 text-nowrap">
                <label class="label-section bold">ZIP Code:</label>
              </div>
              <div class="col-lg-9 col-md-9 col-sm-9 col-9">
                <label class="label-section">{{accountDetails.PostalCode ? accountDetails.PostalCode :
                  'N/A'}}</label>
              </div>
            </div>
            <div class="clearfix"></div>
            <div class="col-6 col-12 p-0 row mx-0">
              <div class="col-lg-3 col-md-3 col-sm-3 col-3 text-nowrap">
                <label class="label-section bold">Loyalty Card:</label>
              </div>
              <div class="col-lg-9 col-md-9 col-sm-9 col-9">
                <label class="label-section">{{accountDetails.LoyaltyCardNumber ? accountDetails.LoyaltyCardNumber :
                  'N/A'}}</label>
              </div>
            </div>
            <div class="col-12"
              *ngIf="accountDetails.AccountAttributes && accountDetails.AccountAttributes.length > 0">
              <pos-account-attributes-view [accountAttributes]="accountDetails.AccountAttributes">
              </pos-account-attributes-view>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="selectedTab == tabList.Orders">
        <div class="col-12" [ngStyle]="{'height':getHeight(false)}">
          <pos-account-orders [ordersData]="accountOrderDetails">
          </pos-account-orders>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 action-button-container popup-footer">
    <button type="button" class="btn btn-primary btn-lg btn-save pos-button" (click)="onPrintAccountDetails()">Print</button>
    <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" value="Cancel"
      (click)="onCancel()">Cancel</button>
  </div>
</pos-edit-wrapper>
