import { Directive, OnInit, Input, ElementRef, Renderer2 } from '@angular/core';
import { find, isEqual } from 'lodash';
import { AuthenticationService } from '../../auth/services/authentication.service';
import { PermissionArguments } from '../../interface/permission-arguments';
import { ApplicationStateService, PermissionService } from '../../services';

@Directive({
  selector: '[permissions]'
})
export class PermissionsDirective implements OnInit {

  @Input('grantif') grantif: string = '';
  @Input() permissions: any;
  @Input('disableIfNotGranted') disableIfNotGranted: boolean = false;
  @Input() checkPast: boolean = true;

  currentElement: ElementRef;
  elementRenderer: Renderer2;

  constructor(private authenticationService: AuthenticationService, private el: ElementRef, private renderer: Renderer2,
    private applicationStateService: ApplicationStateService,
    private permissionService: PermissionService) {
    this.elementRenderer = renderer;
  }

  ngOnInit() {
  }

  ngAfterContentInit() {
    if(this.checkPast) {
    const permissionDetails = this.addPermissionsInLoadedList();
    this.permissionService.reflectPermission(permissionDetails);
    } else {
      this.permissionService.reflectPermission(this.preparePermissionArgs(null));
    }
  }

  private addPermissionsInLoadedList() {
    const loadedPermissions = this.applicationStateService.loadedPermissions;
    const isAvailablePermission = this.getAlreadyExistPermission(loadedPermissions);
    const permissionDetails = this.preparePermissionArgs(isAvailablePermission);
    loadedPermissions.push(permissionDetails);
    this.applicationStateService.loadedPermissions = loadedPermissions;
    return permissionDetails;
  }

  private preparePermissionArgs(isAvailablePermission: PermissionArguments) {
    if (!isAvailablePermission) {
      const loadedPermission: PermissionArguments = {
        permissions: this.permissions,
        elementRenderer: this.elementRenderer,
        currentElement: this.el,
        grantif: this.grantif,
        disableIfNotGranted: this.disableIfNotGranted
      };
      return loadedPermission;
    } else {
      isAvailablePermission.currentElement = this.el;
      isAvailablePermission.elementRenderer = this.elementRenderer;
      return isAvailablePermission;
    }
  }

  private getAlreadyExistPermission(loadedPermissions: PermissionArguments[]) {
    return find(loadedPermissions, (x: PermissionArguments) => {
      return x.permissions === this.permissions &&
        isEqual(x.currentElement, this.el) &&
        x.grantif === this.grantif &&
        x.disableIfNotGranted == this.disableIfNotGranted;
    });
  }
}
