import { Observable } from 'rxjs';
import { BaseCrudService } from './base-crud.service';
import { Diagram } from '../../layout-designer/interfaces/layout-designer';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from '../constants/runtime-constants';


@Injectable()
export class LayoutDesignersService extends BaseCrudService<Diagram> {

  layoutApiUrl = '';
  constructor(httpService: HttpClient) {
    super('layouts', httpService);
    this.layoutApiUrl = RuntimeConstants.API_PATH + 'layouts';
  }

  newLayoutDesigner(): Diagram {
    const layout: Diagram = {
      Id: 0,
      Name: '',
      IsActive: true,
      LayoutDefinition: '',
      // AccountVisualizations: []
    };
    return layout;
  }

  saveLayouts(layoutModel): Observable<any> {
    return this.httpService.post(
      `${this.layoutApiUrl}`,
      layoutModel
    );

  }
  updateLayouts(layoutModel): Observable<any> {
    return this.httpService.put(
      `${this.layoutApiUrl}/${layoutModel.Id}`,
      layoutModel
    );
  }

  getLayoutWithAccountVisualization(): Observable<any> {
    return this.httpService.get(`${this.layoutApiUrl}/getLayoutsWithAccountVisualization`);
  }

  getAccountVisualizations(): Observable<any> {
    return this.httpService.get(`${RuntimeConstants.API_PATH}layouts/account-visualizations`);
  }
}
