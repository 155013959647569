<pos-edit-wrapper heading="Audio" (close)="cancel()" [isPopup]="true">
    <div class="pos-modal-body">
        <div class="pt-pix-10">
            <p class="mb-pix-10"> File: {{fileName}} </p>
        </div>
        <div class="d-flex">
            <div id="audio-div">
            </div>
            <span class="ps-2 pt-pix-12 pointer" (click)="downloadAudio()" tooltip title="Download">
                <pos-action-icon [icon]="icons.downloadWhite"></pos-action-icon>
            </span>
        </div>
    </div>
</pos-edit-wrapper>
