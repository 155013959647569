import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LightingService {

  inventoryBinLightApiBasePath = RuntimeConstants.API_PATH + 'lights';

  constructor(private httpClient: HttpClient) { }

  triggerLight(hardwareDeviceId, lightAddress, terminalDetails) {
    return this.httpClient.post(`${this.inventoryBinLightApiBasePath}/${hardwareDeviceId}/${lightAddress}/light`,
      terminalDetails);
  }

  triggerUnlight(hardwareDeviceId, lightAddress, terminalDetails) {
    return this.httpClient.post(`${this.inventoryBinLightApiBasePath}/${hardwareDeviceId}/${lightAddress}/unlight`,
      terminalDetails);
  }

  triggerFlash(hardwareDeviceId, lightAddress, terminalDetails) {
    return this.httpClient.post(`${this.inventoryBinLightApiBasePath}/${hardwareDeviceId}/${lightAddress}/flash`,
      terminalDetails);
  }
}
