import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { RuntimeConstants, SettingParam } from 'src/app/shared';

@Injectable({
  providedIn: 'root'
})
export class GeneralConfigService {
  GeneralConfigApiBasePath = RuntimeConstants.API_PATH + 'general-config';

  constructor(private httpClient: HttpClient) { }
  getConfig(): Observable<SettingParam> {
    return this.httpClient.get<SettingParam>(`${this.GeneralConfigApiBasePath}`);
  }

  save(params, imageFile: File): Observable<SettingParam> {
    const formData = this.prepareImageForm(params, imageFile);
    return this.httpClient.post<SettingParam>(this.GeneralConfigApiBasePath, formData);
  }

  prepareImageForm(params, imageFile: File): FormData {
    const formData = new FormData();
    if (imageFile) {
        formData.append('image', imageFile);
    }
    formData.append('model', JSON.stringify(params));

    return formData;
}
}
