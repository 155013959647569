<button type="button" class="close" (click)="onCancel()">&times;</button>
<h4 class="modal-content__heading">
  {{trainingLevel.LevelNumber}}. {{trainingLevel.Description}} </h4>
<div class="overflow-hidden">
  <div class="col-xs-12 pt-pix-10 pos-modal-body screen-scroll" style="min-height:250px">
    <h3 class="ps-pix-5 pt-pix-5"><b><span class="">Area: {{trainingAreaName}}</span></b></h3>
    <h3 class="ps-pix-5 pt-pix-1 pb-pix-5"><b><span class="">Topic: {{topicName}}</span></b></h3>
    <h4 class="ps-pix-5 pt-pix-1 pb-pix-5" *ngIf="prerequisiteText">
      <span class="text-justify reach-textbox" [innerHTML]="prerequisiteText"></span>
    </h4>
    <div class="embed-responsive embed-responsive-16by9" *ngIf="showVideo">
      <iframe id="player" class="embed-responsive-item" [src]="urlSafe" allowfullscreen></iframe>
    </div>
  </div>
  <div class="col-12 action-button-container popup-footer pb-pix-10">
    <button class="btn-secondary btn-lg btn btn-cancel-modal pos-button" type="button" (click)="onCancel()">Close</button>
  </div>
  <div class="clearfix"></div>
</div>
