import { Component, OnInit } from '@angular/core';
import { Dictionary, cloneDeep, forEach, groupBy, orderBy } from 'lodash';
import { finalize } from 'rxjs/operators';
import { Levels, AlertsService, SpinnerService, ModalService } from 'src/app/shared';
import { angleDoubleRight, angleDoubleLeft, times, search } from 'src/app/shared/components/icon';
import { ActiveReport } from '../../interfaces/active-report';
import { ParameterForReport } from '../../interfaces/parameter-for-report';
import { Report } from '../../interfaces/report';
import { ReportDetails } from '../../interfaces/report-details';
import { ReportParameter } from '../../interfaces/report-parameter';
import { ReportService } from '../../services/report.service';
import { ReportParametersComponent } from '../report-parameters/report-parameters.component';
declare let $: any;

@Component({
  selector: 'pos-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  isFullScreen = false;
  accessLevels = Levels;
  icons = {
    angleDoubleRight, angleDoubleLeft, times, search
  };
  searchReports = '';
  reports: Dictionary<Array<Report>>;
  reportsByGroup: Array<Report> = [];
  reportParameters: Array<ReportParameter> = [];
  activeReport: ActiveReport;
  isReportParameter = true;
  reportDataSource = '';
  reportsByGroupCopy: Array<Report> = [];
  reportData: ReportDetails = null;
  reportParam: ParameterForReport;

  constructor(private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private modalService: ModalService,
    private reportService: ReportService) {
    this.reportParam = reportService.newParameterForReport();
    this.activeReport = reportService.newActiveReport();
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.spinnerService.show();
    this.reportService.getReports()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (reports: Array<Report>) => {
          const reportsGroupList = groupBy(reports, 'Category');
          let newGroup = {
            ReportsGroupList: null, Category: '', ReportCategoryIcon: '', ReportCategoryOrdinal: null, PermissionId: null
            , ReportGroup: '', ReportGroupIcon: '', ReportsList: null, ReportCategoryPermissionId: null
          };
          forEach(reportsGroupList, (group) => {
            newGroup = {
              ReportsGroupList: [], Category: '', ReportCategoryIcon: '', ReportCategoryOrdinal: null, PermissionId: null,
              ReportGroup: '', ReportGroupIcon: '', ReportsList: null, ReportCategoryPermissionId: null
            };
            newGroup.ReportsGroupList = [];
            forEach(group, (groupItem) => {
              newGroup.Category = groupItem.Category;
              newGroup.ReportCategoryIcon = groupItem.ReportCategoryIcon;
              newGroup.ReportCategoryOrdinal = groupItem.ReportCategoryOrdinal;
              newGroup.ReportCategoryPermissionId = groupItem.ReportCategoryPermissionId;
              if (groupItem.Category === newGroup.Category) {
                newGroup.ReportsGroupList.push(groupItem);
              }
            });
            this.reportsByGroup.push(newGroup as Report);
          });
          forEach(this.reportsByGroup, (group) => {
            const groupReports = [];
            newGroup = {
              ReportsGroupList: null, Category: '', ReportCategoryIcon: '', ReportCategoryOrdinal: null, PermissionId: null,
              ReportGroup: '', ReportGroupIcon: '', ReportsList: null, ReportCategoryPermissionId: null
            };
            newGroup.ReportsGroupList = groupBy(group.ReportsGroupList, 'ReportGroup');
            forEach(newGroup.ReportsGroupList, (report) => {
              newGroup = {
                ReportsGroupList: null, Category: '', ReportCategoryIcon: '', ReportCategoryOrdinal: null, PermissionId: null,
                ReportGroup: '', ReportGroupIcon: '', ReportsList: null, ReportCategoryPermissionId: null
              };
              newGroup.ReportGroup = report[0].ReportGroup;
              newGroup.ReportGroupIcon = report[0].ReportGroupIcon;
              newGroup.PermissionId = report[0].PermissionId;
              newGroup.ReportsList = report;
              groupReports.push(newGroup);
            });
            group.ReportsGroupList = groupReports;
          });
          this.reportsByGroup = orderBy(this.reportsByGroup, 'ReportCategoryOrdinal');
          this.reportsByGroupCopy = this.reportsByGroup;
        }, error: this.alertsService.showApiError
      });
  }

  getReportParameterDetails(item: Report) {
    this.activeReport = {
      Name: item.ReportName,
      Id: item.Id,
      IsPaged: item.IsPaged,
      QueryType: item.QueryType
    };
    this.reportDataSource = item.ReportDataSource;
    this.spinnerService.show();
    this.reportService.getReportParameterDetails(item.Id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: Array<ReportParameter>) => {
          this.reportParameters = response;
          this.setControls();
          this.runReport();
        }, error: this.alertsService.showApiError
      });
  }

  runReport() {
    if (this.reportParameters.length) {
      this.isReportParameter = true;
      this.openReportParameterModal();
    } else {
      this.isReportParameter = false;
      this.getReportData();
    }
  }

  openReportParameterModal() {
    const modalRef = this.modalService.getModalWrapper(ReportParametersComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center modal-md',
      'backdrop': 'static',
      initialState: {
        parameters: this.reportParameters,
        activeReport: this.activeReport
      }
    });
    modal.close.subscribe((response) => {
      if (response && response.shouldRunReport) {
        this.getReportData();
      }
    });
  }

  setControls() {
    forEach(this.reportParameters, (param) => {
      if (param.DataType === 'DATE') {
        param.InitialValue = new Date(param.InitialValue.toString());
      } else if (param.DataType === 'BIT') {
        if (param.InitialValue === '0') {
          param.InitialValue = false;
        } else {
          param.InitialValue = true;
        }
      }
    });
  }

  getReportData() {
    this.resetData();
    this.reportParam = {
      ReportName: this.activeReport.Name,
      parameters: this.reportParameters,
      spName: this.reportDataSource,
      IsPaged: this.activeReport.IsPaged,
      QueryType: this.activeReport.QueryType,
      ReportId: this.activeReport.Id
    };
    this.getReportDetails(this.activeReport.Id);
  }

  resetData() {
    this.isReportParameter = true;
    this.reportData = this.reportService.newReportDetails();
  }

  getReportDetails = (reportId) => {
    this.spinnerService.show();
    this.reportService.getReportData(this.activeReport.Id, this.reportParam)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: ReportDetails) => {
          this.reportData = response;
          this.isReportParameter = false;
          if ($(window).width() < 641) {
            this.isFullScreen = true;
          }
        }
      });
  }

  toggleFullScreen() {
    this.isFullScreen = !this.isFullScreen;
    if ($(window).width() > 641) {
      if (this.isFullScreen) {
        $('.tab-content').css('width', '100%');
      } else {
        $('.tab-content').css('width', '80%');
      }
    }
  }

  search() {
    this.reportsByGroupCopy = [];
    let groups = [];
    let reportCategories = [];
    let reports = [];
    this.reportsByGroupCopy = cloneDeep(this.reportsByGroup);
    forEach(this.reportsByGroupCopy, (categoryReport) => {
      groups = [];
      if (String(categoryReport.Category).toLowerCase().includes(this.searchReports.toLowerCase())) {
        groups.push(categoryReport);
      } else {
        reportCategories = [];
        forEach(categoryReport.ReportsGroupList, (groupReport) => {
          if (String(groupReport.ReportGroup).toLowerCase().includes(this.searchReports.toLowerCase())) {
            reportCategories.push(groupReport);
          } else {
            reports = [];
            forEach(groupReport.ReportsList, (report) => {
              if (String(report.ReportName).toLowerCase().includes(this.searchReports.toLowerCase())) {
                reports.push(report);
              }
            });
            groupReport.ReportsList = reports;
            if (groupReport && groupReport.ReportsList.length) {
              reportCategories.push(groupReport);
            }
          }
        });
        categoryReport.ReportsGroupList = reportCategories;
        if (categoryReport && categoryReport.ReportsGroupList && categoryReport.ReportsGroupList.length) {
          groups.push(categoryReport);
        }
      }
    });
  }

  reportDetailsClosed() {
    this.activeReport.Name = null;
    this.resetData();
  }
}
