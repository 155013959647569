import { Pipe, PipeTransform } from '@angular/core';
import { Dictionary, groupBy } from 'lodash';

@Pipe({
  name: 'groupBy'
})
export class GroupByPipe implements PipeTransform {
  transform(collection: any[], property: string): Dictionary<any[]> {
    if(!collection) {
        return null;
    }

    return groupBy(collection, property);
  }
}
