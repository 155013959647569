import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseCrudService } from 'src/app/shared/services';
import { DataSourceModel } from '../interface/data-source-model';
import { DashboardDataSource, DashboardDataSourceParameter } from '../interface';

@Injectable({
  providedIn: 'root'
})
export class DashboardDataSourceService extends BaseCrudService<DataSourceModel> {

  constructor(protected httpClient: HttpClient) {
    super('dashboard-data-source', httpClient);
  }

  newDataSource(): DataSourceModel {
    return {
      Id: 0,
      Name: '',
      OutputType: null,
      SourceType: 'SQL',
      StoredProcedure: '',
      StoredProcedureId: 0,
      DataSourceParameter: [],
      Method: '',
      MethodId: 0,
      Url: '',
      UrlId: 0,
    };
  }

  saveDataSourceWithParameters(dashboardDataSource) {
    return this.httpClient.post(this.apiEndpoint, dashboardDataSource);
  }

}
