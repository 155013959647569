import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { Observable } from 'rxjs';
import { InventorySchedule } from '../interface/inventory-schedule';

@Injectable({
  providedIn: 'root'
})
export class InventoryScheduleEditorService {

  constructor(private httpService: HttpClient) { }

  getInventorySubcategory(): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}inventorysubcategories/`
    );
  }

  getInventoryVendors(): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}vendors/active/`
    );
  }

  getInventoryZones(): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}inventoryzone/`
    );
  }

  getInventoryProduct(data: any): Observable<any> {
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}inventorySchedule`,
      data
    );
  }

  updateProduct(products: InventorySchedule): Observable<any> {
    return this.httpService.put(
      `${RuntimeConstants.API_PATH}inventorySchedule`,
      products
    );
  }
}
