<pos-list-wrapper [headingIcon]="icons.sortNumericUp" heading="Inventory Vendor Profiles" (close)="cancel()">
  <div>
    <h4 class="page-content__header" *ngIf="vendorName">
      Vendor: <span class="normal black">{{vendorName}}</span>
    </h4>
  </div>
  <div class="row">
    <div class="col-12">
      <ng-table #profileGrid [columns]="vendorProfileColumns" [exportFilename]="'vendor-profiles'"
        [data]="inventoryVendorProfiles">
      </ng-table>
      <ng-template let-data="rowData" #nameTemplate>
        {{ data.Name }}
        <span *ngIf="data.IsLastAppliedProfile" class="label label-success font-11">Active</span>
      </ng-template>
      <ng-template let-data="rowData" #operationHeaderTemplate>
        <div class="d-flex justify-content-center align-items-center">
          <span permissions='[{"Name":"{{permission.name}}", "Level":"{{permission.editLevel}}"}]' class="mt-pix-1">
            <span (click)="editItem(0)" tooltip title="Add" class="pe-1">
              <pos-action-icon [icon]="icons.plusWhite" iconText="Add"></pos-action-icon>
            </span>
          </span>
          <span tooltip title="Print">
            <pos-export-dropdown class="inline-block ps-pix-5" (onPrint)="printDiv()"
              (onCSV)="exportCSV()"></pos-export-dropdown>
          </span>
        </div>

      </ng-template>
      <ng-template let-data="rowData" #operationTemplate>
        <div permissions='[{"Name":"{{permission.name}}", "Level":"{{permission.editLevel}}"}]'
          class="d-flex justify-content-center">
          <span class="pe-pix-3" (click)="editItem(data.Id, data)" tooltip title="Edit">
            <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
          </span>
          <span class="pe-pix-3" (click)="deleteInventoryVendorProfile(data)" tooltip title="Delete">
            <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
          </span>
          <span (click)="confirmApplyProfile(data)" tooltip title="Apply Profile">
            <pos-action-icon [icon]="icons.clipboardCheck"></pos-action-icon>
          </span>
        </div>
      </ng-template>
    </div>
  </div>
</pos-list-wrapper>
<pos-print-table [hidden]="true"></pos-print-table>
