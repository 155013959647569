import { Pipe, PipeTransform } from '@angular/core';
import { NgxMaskPipe } from 'ngx-mask';
import { DomainConstants } from '../constants/domain-constants';
import { ApplicationStateService } from '../services';

@Pipe({ name: 'phoneMaskPipe' })

export class PhoneMaskPipe implements PipeTransform {
    constructor(private applicationStateService: ApplicationStateService, private maskPipe: NgxMaskPipe) {}

    transform(phoneNumber: string) {
        const phoneMask = this.applicationStateService.settingParam?.PhoneNumberMask.trim()
          ? this.applicationStateService.settingParam.PhoneNumberMask
          : DomainConstants.DefaultPhoneMask;
        return this.maskPipe.transform(phoneNumber, phoneMask);
    }
}
