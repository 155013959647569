<div *ngIf="isPopup">
    <button class="close" (click)="closeBarcodeModal()">&times;</button>
    <h4 class="modal-content__heading">Barcodes: {{productName}}</h4>
</div>
<div class="page-content row" [ngClass]="{'m-0':isPopup}">
    <div class="col-12" [ngClass]="{'pos-modal-body':isPopup}">
        <form #barCodeForm="ngForm" class="edit-form" (ngSubmit)="submit(barCodeForm.valid)" autocomplete="off">

            <div class="row pb-pix-1 mb-pix-2">
                <div class="col-sm-4 col-12">
                    <pos-form-field label="Barcode" inputStyle="col-12"
                        [validationMessages]="{required: 'Please enter barcode.'}">
                        <input posInput class="form-control" maxlength="30" name="barCode" [required]="true"
                            #barcodeName="ngModel" id="barcodeName" (keyup.enter)="saveBarcodeToAvoidFormSubmit()"
                            [(ngModel)]="barcode.Barcode" type="text" />
                    </pos-form-field>
                </div>

                <div class="col-lg-4 col-sm-3 col-9">
                    <pos-form-field label="Unit Qty" inputStyle="col-12"
                        [validationMessages]="{pattern: 'Only 4 decimal places allowed.'}">
                        <input posInput class="form-control" name="textUnitQty" [(ngModel)]="barcode.UnitQtyOverride"
                            pattern="[0-9]+(.[0-9]{0,4})?$" #UnitQtyOverride="ngModel" type="number"
                            (keypress)="preventMinusSign($event)" />
                    </pos-form-field>
                </div>

                <div class="col-lg-1 col-sm-2 col-3">
                    <div class="col-12 pt-pix-10">
                        <label class="">Unit</label>
                    </div>
                    <div class="col-12 p-0">
                        <label class="edit-form__section__label">{{unitName}}</label>
                    </div>
                </div>

                <div class="col-sm-3 col-12">
                    <div class="col-12 pt-pix-10 pb-pix-7">
                        <label class=""></label>
                    </div>
                    <div class="col-12 no-wrap pe-0 text-right">
                        <button class="btn btn-primary white normal pos-button" type="submit">
                            {{barcode.Id ? 'Update' : 'Add'}}
                        </button>
                        <button class="btn btn-secondary normal ms-pix-4 pos-button" (click)="CancelEdit()"
                            type="button">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>

        </form>
        <div class="col-12">
            <ng-table id="barcode" [sort]="true" [columns]="barcodeColumns" [data]="barcodes"
                [exportFilename]="'barcodes'" [paginator]="false">
            </ng-table>
            <ng-template let-data="rowData" #unitTemplate>
                <span>{{unitName}}</span>
            </ng-template>
        </div>
    </div>
    <div class="col-12 p-0 action-button-container popup-footer" *ngIf="isPopup">
        <button type="button" (click)="closeBarcodeModal()" data-dismiss="modal"
            class="btn-secondary btn-lg btn btn-cancel-modal mb-pix-10 pos-button" value="Close">Close</button>
    </div>
</div>
<pos-print-table *ngIf="!isPopup" [hidden]="true"></pos-print-table>
