import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalComponent } from 'src/app/shared/components/modal/modal-component';

@Component({
  selector: 'pos-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss']
})
export class ImageGalleryComponent extends ModalComponent implements OnInit {

  imageList: Array<any> = [];
  imageRootPath: string = '';
  headerName: string = '';
  selectedImage: string = ''
  availableImages: Array<any> = [];
  searchFilter: string = '';
  constructor(imageGalleryModalRef: BsModalRef) {
    super(imageGalleryModalRef);
  }

  ngOnInit() {
    if (!this.headerName) {
      this.headerName = 'Image Gallery';
    }
    this.imageList.forEach(element => {
      const name = element.substring(element.lastIndexOf("\\") + 1, element.length);
      this.availableImages.push({
        name: name,
        actualName: name && name.lastIndexOf('-') !== -1 &&
          name.substring(name.lastIndexOf('-') + 1, name.lastIndexOf('.')).length === 18 &&
          !isNaN(name.substring(name.lastIndexOf('-') + 1, name.lastIndexOf('.'))) ?
          name.substring(0, name.lastIndexOf('-')) :
          name.substring(0, name.lastIndexOf('.'))
      });
    });
  }

  onSelectImage(img: any) {
    this.selectedImage = img.name;
    this.onCancel(true);
  }

  onCancel(isImageSelected: boolean) {
    isImageSelected ?
      this.hide({ shouldReload: false, selectedImage: this.selectedImage })
      : this.hide({ shouldReload: false });
  }

}
