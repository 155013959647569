import { Component, OnInit, Input, ViewChild, OnDestroy, ElementRef, Output, EventEmitter } from '@angular/core';
import { RuntimeConstants, DomainConstants, Permissions, Levels, Messages } from 'src/app/shared/constants';
import { Router } from '@angular/router';
import {
  ApplicationStateService, EventBroadcastingService, RabbitMQService,
  AlertsService, UserDetails, ApplicationInitializationConfigurations, SettingParam, Configurations,
  WarningTypes, OrderService, AuthenticationService, OrderIdRabbitMQPayload, UserIdleService, Features,
  ForceLogoutModalComponent,
  InfoModalComponent
} from 'src/app/shared';
import {
  home, taskClipboardList, adminWrench, timeUserClock, signInUserTag, listAlt,
  reloadRedo, userNameCircle, subscriptionBookmark, userSignOut, turnDebugMode, infolinksExternalLinkAlt, userImpersonate,
  calendar, userTimes, functionButtonFilter, exchangeAlt, cogs, th, exclamationCircle, check, contactSupport,
  tabletAlt
} from 'src/app/shared/components/icon';
import * as _ from 'lodash';
import { finalize } from 'rxjs/operators';
import { TerminalsService } from 'src/app/configurator';
import { ApplicationLoginService, ApplicationService, AudioOperationsService, CameraAnnotationService, PermissionService, RabbitMQMessage } from 'src/app/shared/services';
import { forkJoin, Observable, interval as observableInterval, Subscription } from 'rxjs';
import { MainService } from '../../services/main.service';
import { ClockInComponent } from '../clock-in/clock-in.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ShiftDutiesComponent } from '../shift-duties/shift-duties.component';
import { SpinnerService } from 'src/app/shared/components/spinner';
import { ICloseable, ModalBackdropService, ModalService } from 'src/app/shared/components/modal';
import { OrderEventBroadcastingService } from 'src/app/shared/services/order-event-broadcasting.service';
import { versionInfo } from 'src/version';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ManagesService } from 'src/app/shared/services/manages.service';
import { ReloadSettingsService } from 'src/app/shared/services/reload-settings.service';
import { UserSignInByButtonModel } from 'src/app/shared/interface/user-sign-in-by-button-model';
import { FilteredProductsComponent } from 'src/app/configurator/terminals';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ApplicationFeaturesService } from 'src/app/shared/services/application-features.service';
import { debounce, filter as _filter, forEach, remove } from 'lodash';
import { StringUtilityService } from 'src/app/shared/services/string-utility.service';
import { TerminalStatusCheckService } from 'src/app/shared/services/terminal-status-check.service';
import { environment } from 'src/environments/environment.dev';
declare let Tawk_API: any;
declare let $: any;
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pos-header-entry-main',
  templateUrl: './header-entry-main.component.html',
  styleUrls: ['./header-entry-main.component.scss']
})
export class HeaderEntryMainComponent implements OnInit, OnDestroy {
  terminalLogoutRequestSubscription: Subscription;
  userClockOutSubscription: Subscription;
  refreshActiveOrderSubscription: Subscription;
  globalLogoffSubscription: Subscription;
  terminalInUseSubscription: Subscription;
  userTrainingBadgeSubscription: Subscription;
  rabbitMqActiveOrderServedSubscription: Subscription;
  refreshTerminalRequestSubscription: Subscription;
  imagePath = RuntimeConstants.IMAGE_BASE_PATH;
  trainingMode: boolean = false;
  clockInModalRef: ICloseable;
  icons = {
    home,
    taskClipboardList,
    adminWrench,
    timeUserClock,
    signInUserTag,
    listAlt,
    reloadRedo,
    userNameCircle,
    subscriptionBookmark,
    userSignOut: userSignOut,
    turnDebugMode: turnDebugMode,
    infolinksExternalLinkAlt,
    userImpersonate: userImpersonate,
    calendar,
    userTimes: userTimes,
    functionButtonFilter,
    exchangeAlt,
    cogs,
    th,
    exclamationCircle,
    check,
    contactSupport,
    tabletAlt
  };

  @Input() links: any = {};
  @Input() homeState: string = '/order/order-entry';
  @Input() isShowBarIcon = true;
  @Output() filterProductsClick: EventEmitter<any> = new EventEmitter();
  linksVisibility: any;
  warnings: Array<any> = [];
  shiftDutiesDue: boolean = false;
  mappedOrderEntryTerminals: Array<any> = [];
  unservedOrdersData: Array<any> = [];
  activeOrdersVisible: boolean = false;
  userId: number = 0;
  forceLogout: boolean = false;
  isImpersonateUser: boolean = false;
  userName: string = '';
  badgeIcon: string = '';
  timeKeeping: boolean = false;
  userDetails: UserDetails;
  isDebug: boolean = false;
  customLink: any;
  applicationConfig: ApplicationInitializationConfigurations;
  settingParams: SettingParam;
  configuration: Configurations;
  isPopoutRequired: boolean = false;
  userTrainingDetails: any;
  badgeName: string = '';
  isTimeEntryTerminal: boolean = false;
  forceLogoutInterval: any;
  forceLogoutModalRef: ICloseable;
  terminalWarningModalRef: BsModalRef;
  @ViewChild('terminalWarningModal') public terminalWarningModal: any;
  @ViewChild('cardBasedSignIn') public cardBasedSignIn: any;
  cardBasedSignInModalRef: BsModalRef;
  forceLogOutMessage: string = '';
  warningForcedDisplayInterval: number;
  warningTypes: any;
  serveMethods: object;
  timer: Observable<number>;
  features = Features;
  timerSubscription: any;
  headerEntryRabbitMQSubscriptions: Array<any> = [];
  clockStatus: string = 'Clock In';
  signInMessage: string = '';
  permissionLevel = Levels.Access;
  permissions = {
    linkReportSubscriptions: Permissions.LinkReportSubscriptions,
    linkCustomLinks: Permissions.LinkCustomLinks,
    linkWorkSchedule: Permissions.LinkWorkschedule,
    manageConsole: Permissions.ManageConsole,
    linkDesignMode: Permissions.LinkDesignMode,
    linkContactSupport: Permissions.LinkContactSupport
  };
  isKeyboardWedgeEnabled: boolean = true;
  mappedTerminalVisible: boolean = false;
  eventSubscriptions: Array<any> = [];
  isCardBasedLoginSameUser: boolean = false;
  isUserSwipeDisabled: boolean = false;
  terminalType: string;
  versionInfo = versionInfo;
  terminalTypes = DomainConstants.TerminalTypes;
  orderMethods = DomainConstants.OrderMethods;
  @ViewChild('clockInComponent') clockInComponent: ClockInComponent;
  subAccountTerm: string = '';
  accountTypes = DomainConstants.AccountTypes;
  tableTerm: string = '';
  accountTerm: string = '';
  terminalDesignMode: boolean = false;
  autoSignOutSubscription: Subscription;
  rabbitMQTerminalStatusCheckSubscription: Subscription;
  constructor(private router: Router,
    private applicationStateService: ApplicationStateService,
    private eventBroadcastingService: EventBroadcastingService,
    private orderEventBroadcastingService: OrderEventBroadcastingService,
    private rabbitMQService: RabbitMQService,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private orderService: OrderService,
    private managesService: ManagesService,
    private terminalService: TerminalsService,
    private reloadSettingsService: ReloadSettingsService,
    private applicationLoginService: ApplicationLoginService,
    private mainService: MainService,
    private modalService: ModalService,
    private bsModalService: BsModalService,
    private modalBackdropService: ModalBackdropService,
    private authenticationService: AuthenticationService,
    private permissionService: PermissionService,
    private elRef: ElementRef,
    private deviceDetectorService: DeviceDetectorService,
    private applicationFeatureService: ApplicationFeaturesService,
    private stringUtilityService: StringUtilityService,
    private cameraAnnotationService: CameraAnnotationService,
    public userIdleService: UserIdleService,
    private terminalStatusChecker: TerminalStatusCheckService,
    private applicationService: ApplicationService,
    public audioOperationsService: AudioOperationsService) {
    this.terminalType = this.applicationStateService.terminalType;
  }

  ngOnInit() {
    this.setDefaults();
    this.setLinkVisibility();
    this.initializeComponent();
    this.eventBroadcastingSubscriptions();
    this.settingParams.BackofficeOnlyMode = this.settingParams.TerminalType === DomainConstants.TerminalTypes.BACK_OFFICE.Name;
    if (this.settingParams.BackofficeOnlyMode) {
      this.linksVisibility = {
        home: false,
        warning: false,
        tasks: this.applicationStateService.applicationInitializationConfigurations.HasTaskSetup,
        changeTerminal: false,
        mappedTerminals: false,
        admin: true,
        time: false,
        signIn: true,
        activeOrders: false,
        userMenu: true,
        mySubscriptions: true,
        reload: true,
        customLinks: true,
        filterProducts: false,
        terminalDesignMode: false
      };
    }
    // isFirstTimeAfterLogin flag assign with false from make table if terminal type is make table
    if (this.applicationStateService.isFirstTimeAfterLogin && this.userDetails?.time_keeping && this.settingParams.TerminalType !== DomainConstants.TerminalTypes.MAKE_TABLE.Name) {
      this.applicationStateService.isFirstTimeAfterLogin = false;
      this.clockLoginCheck(this.applicationStateService.userCode, this.applicationStateService.userDetails, null, !this.isTimeEntryTerminal);
    }

    if (this.applicationStateService.settingParam.TerminalType == DomainConstants.TerminalTypes.ORDER_ENTRY.Name) {
      this.userIdleService.initialize(this.applicationStateService.settingParam.AutoSignOutOnUserIdleSeconds, () => {
        this.rabbitMQService.sendAutoSignOutUserMessage(
          this.applicationStateService.terminalId,
          this.applicationStateService.terminalName,
          this.applicationStateService.userId,
          "UserIdleEvent"
        );
      });
    }
    this.subscribeToAutoSignOutMessage();
    this.subscribeToTerminalStatusCheck();
    //this.loadContactSupportModule();
  }

  loadContactSupportModule() {
    const scripttagElement = document.createElement('script');
    scripttagElement.type = 'text/javascript';
    scripttagElement.text = `var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    Tawk_API.visitor = {
      name : '${this.userName}'
    };
    (function(){
    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    s1.async=true;
    s1.src='https://embed.tawk.to/${this.applicationStateService.contactSupportKey}';
    s1.charset='UTF-8';
    s1.setAttribute('crossorigin','*');
    s0.parentNode.insertBefore(s1,s0);
    })();`;
    document.body.appendChild(scripttagElement);
  }

  subscribeToAutoSignOutMessage() {
    this.autoSignOutSubscription = this.rabbitMQService.subscribeToAutoSignOutUserMessage$()
      .subscribe({
        next: (message: any) => {
          if (
            (message?.Payload?.EventName == "UserIdleEvent" || message?.Payload?.EventName == "OrderSubmittedEvent") &&
            message?.Payload?.TerminalId == this.applicationStateService.terminalId &&
            message?.Payload?.UserId == this.applicationStateService.userId
          ) {
            this.logoutClick(message?.Payload?.EventName == "UserIdleEvent");
          }
        },
        error: () => {
          console.log("error while connecting to RabbitMQ.");
        },
      });

    this.orderEventBroadcastingService.autoSignOutUser.subscribe({
      next: () => this.logoutClick(true),
    })
  }

  subscribeToTerminalStatusCheck() {
    this.rabbitMQTerminalStatusCheckSubscription = this.terminalStatusChecker.subscribeTerminalStatusCheck(this.applicationStateService.terminalId)
      .subscribe({
        next: (res) => this.terminalStatusChecker.publishTerminalStatusResponse(res)
      })
  }

  ngOnDestroy(): void {
    this.userIdleService.stop();
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
    this.unsubscribeEventBroadcastingSubscriptions();
  }

  unsubscribeEventBroadcastingSubscriptions() {
    if (this.eventSubscriptions) {
      _.forEach(this.eventSubscriptions, (subscription) => {
        subscription.unsubscribe();
      });
    }
  }

  setDefaults() {
    this.settingParams = this.applicationStateService.settingParam;
    this.trainingMode = JSON.parse(this.applicationStateService.sessionTrainingMode);
    this.userId = this.applicationStateService.userId;
    this.isImpersonateUser = this.applicationStateService.isImpersonateUser;
    this.userDetails = this.applicationStateService.userDetails;
    this.userName = this.userDetails && this.userDetails.username ?
      this.userDetails.lastname + ', ' + this.userDetails.firstname : '';
    this.applicationStateService.username = this.userDetails?.username;
    this.timeKeeping = this.userDetails.time_keeping;
    this.isDebug = this.applicationStateService.isDebug;
    this.applicationConfig = this.applicationStateService.applicationInitializationConfigurations;
    this.customLink = this.applicationConfig && this.applicationConfig.CustomLink ? this.applicationConfig.CustomLink : null;
    this.configuration = this.applicationStateService.configurations;
    this.warningForcedDisplayInterval = this.applicationConfig.WarningForcedDisplayInterval * 1000;
    this.warningTypes = WarningTypes;
    this.serveMethods = DomainConstants.ServeMethods;
    this.unservedOrdersData = this.applicationStateService.unservedOrdersData ? this.applicationStateService.unservedOrdersData : [];
    this.eventSubscriptions = [];
    this.subAccountTerm = this.settingParams.SubaccountTerm ? this.settingParams.SubaccountTerm : 'Seat';
    this.tableTerm = this.settingParams.TableTerm ? this.settingParams.TableTerm : 'Table';
    this.accountTerm = this.settingParams.AccountTerm ? this.settingParams.AccountTerm : 'Tab';
  }

  setLinkVisibility() {
    const defaultLinksVisibility = {
      home: true,
      warning: true,
      tasks: true,
      changeTerminal: false,
      mappedTerminals: false,
      admin: true,
      time: true,
      signIn: true,
      activeOrders: true,
      userMenu: true,
      mySubscriptions: false,
      reload: true,
      customLinks: true
    };
    this.linksVisibility = { ...defaultLinksVisibility, ...this.links };
  }

  initializeComponent() {

    if (!this.applicationStateService.terminalType || this.applicationStateService.terminalName === '') {
      this.reloadSettingsService.reloadSettings(this.applicationStateService.terminalId).pipe(finalize(() => {
      }))
        .subscribe({
          next: (res) => {
            this.setTerminalTypeConfiguration();
            this.getUnservedOrder();
          }, error: this.alertService.showApiError
        });
    } else {
      this.setTerminalTypeConfiguration();
      this.getUnservedOrder();
    }
    this.getUserTrainingBadges(this.userId);
    this.getTaskDetails();
    this.rabbitMQSubscriptions();
    this.loadCashDrawerDetails();
    if (this.applicationStateService.terminalType === DomainConstants.TerminalTypes.MAKE_TABLE.Name) {
      this.getMappedTerminalForMakeTable();
    }
  }

  getMappedTerminalForMakeTable() {
    this.managesService.getMappedTerminalsByType(this.applicationStateService.terminalId, DomainConstants.TerminalTypes.ORDER_ENTRY.Name)
      .subscribe({
        next: (res) => {
          if (res) {
            this.mappedOrderEntryTerminals = res;
          }
        }, error: this.alertService.showApiError
      });
  }

  loadCashDrawerDetails() {
    this.mainService.getCashDrawerDetails(this.applicationStateService.terminalId)
      .subscribe({
        next: (res) => {
          if (res && res.PreventLogoutUntilDrawerClosed) {
            this.settingParams.PreventLogoutUntilDrawerClosed = res.PreventLogoutUntilDrawerClosed;
          }
        }, error: this.alertService.showApiError
      });
  }

  closeForceLogOutModal() {
    this.forceLogoutModalRef.close.emit();
    clearInterval(this.forceLogoutInterval);
    this.logout(true);
  }

  openForceLogoutModal() {
    this.forceLogoutModalRef = this.modalService.show(ForceLogoutModalComponent, {
      'backdrop': 'static',
      'class': 'vertical-center',
      'keyboard': false,
      initialState: {
        isForceLogout: this.forceLogout,
        forceLogOutMessage: this.forceLogOutMessage
      }
    });
    this.forceLogoutInterval = setTimeout(() => {
      this.closeForceLogOutModal();
    }, 3000);
  }

  onSwipeDetected = (badgeNumber) => {
    if (this.applicationStateService.enableAttendanceCardSwipe) {
      if (!this.isUserSwipeDisabled) {
        const currentURL = this.router.url
        this.router.navigateByUrl('/', { skipLocationChange: true }).then((shouldRedirect) => {
          if (shouldRedirect) {
            this.router.navigate([currentURL]);
            if (currentURL == '/time-entry' && this.checkIsClockedInComponentOpen()) {
              this.clockInModalRef.close.emit();
            }
            if (!this.applicationStateService.stayWithUnsavedFormChanges) {
              this.cardBasedLogin(badgeNumber);
            }
          }
        });
      }
    } else {
      this.applicationStateService.enableAttendanceCardSwipe = false;
    }
  }

  clockInCompleted(event) {
    this.cardBasedLoginCompleted(event);
  }

  clockInCompletedSubmit = (event) => {
    this.signIn(event)
  }

  cardBasedLogin = (cardBasedPass) => {
    this.spinnerService.show();
    this.authenticationService.GetAuthenticationTokenForCardBasedLogin(cardBasedPass, '', '', !this.checkIsClockedInComponentOpen())
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.spinnerService.show();
            const customerCredential = {
              Password: res.Password,
              ExternalPassword: '',
              Username: ''
            }

            this.applicationLoginService.getCustomer(customerCredential)
              .pipe(finalize(() => {
                this.spinnerService.hide();
              }))
              .subscribe({
                next: (res: any) => {
                  this.cardBasedLoginCompleted(res);
                }, error: this.alertService.showApiError
              });

          }
        }, error: this.alertService.showApiError
      });
  }

  checkIsClockedInComponentOpen(): boolean {
    return _.some(this.modalService.modalRefArr, (x: any) => x?.component?.name == ClockInComponent.name);
  }

  cardBasedLoginCompleted(res) {
    if (res && res.UserTerminalDetails && res.UserTerminalDetails.UserDetails) {
      const logInUserDetails = res.UserTerminalDetails.UserDetails;
      const currentUserDetails = this.applicationStateService.userDetails;
      if (logInUserDetails.id == currentUserDetails.id) {
        if (logInUserDetails.time_keeping) {
          this.isCardBasedLoginSameUser = true;
          this.clockLoginCheck(logInUserDetails.code, logInUserDetails);
        } else if (this.checkIsClockedInComponentOpen()) {
          this.alertService.renderErrorMessage(Messages.UserTimeKeepingError);
        }
      } else {
        if (logInUserDetails.time_keeping) {
          this.clockLoginCheck(logInUserDetails.code, logInUserDetails, this.signIn);
        } else if (this.checkIsClockedInComponentOpen()) {
          this.alertService.renderErrorMessage(Messages.UserTimeKeepingError);
        } else {
          this.signIn(logInUserDetails);
        }

      }
    }
  }

  signIn = (logInUserDetails, callBack?: Function) => {
    if ((!this.checkIsClockedInComponentOpen()
      && (this.clockStatus == DomainConstants.ClockInOut.CLOCK_IN || !logInUserDetails.time_keeping) && this.applicationStateService.userDetails.id !== logInUserDetails.id)
      || this.applicationStateService.userSignInByButton) {
      const logoutDetails = {
        EventMessage: 'Terminal Auto Logout',
        EventDetails: 'Auto Logout',
        UserId: this.applicationStateService.userDetails.id,
        TerminalId: this.applicationStateService.terminalId
      };
      const commonRoles = _.intersectionBy(this.userDetails.Roles, logInUserDetails.Roles, 'RoleId');
      const isReloadHomeState = ((this.userDetails?.Roles?.length !== logInUserDetails?.Roles?.length ||
        logInUserDetails?.Roles?.length !== commonRoles?.length) &&
        !this.applicationStateService.userSignInByButton);
      this.applicationStateService.userDetails = logInUserDetails;
      this.applicationStateService.userId = logInUserDetails.id;
      this.applicationStateService.username = logInUserDetails.username;
      this.userDetails = this.applicationStateService.userDetails;
      this.userName = logInUserDetails && logInUserDetails.username ? logInUserDetails.lastname + ', ' + logInUserDetails.firstname : '';
      this.applicationStateService.impersonateUserDetails = null;
      this.reloadSettings();
      this.removeSettings();
      this.setSettingsToLocalStorage(logInUserDetails ? logInUserDetails : null);
      this.saveTerminalEvents(logInUserDetails, isReloadHomeState, callBack);

      this.getUnservedOrder();
      this.spinnerService.show();
      this.orderService.logoutTheSession(logoutDetails).pipe(finalize(() => {
        this.spinnerService.hide();
      }))
        .subscribe({
          next: (res) => {

          }, error: this.alertService.showApiError
        });
    }
    if (!this.isTimeEntryTerminal) {
      this.hideClockInComponent();
    }

  }

  hideClockInComponent() {
    if (this.clockInModalRef) {
      this.clockInModalRef.close.emit();
    }
  }
  private setSettingsToLocalStorage(userDetails): void {
    if (userDetails) {
      this.applicationStateService.userId = userDetails.id;
      this.applicationStateService.cookieUserId = userDetails.id;
      this.applicationStateService.userDetails = userDetails;
      this.applicationStateService.sessionApplicationMode = 'application';
      this.applicationStateService.userCode = userDetails.code;
      this.applicationStateService.externalPassword = userDetails.Password;
    }
  }
  clockLoginCheck = (password, logInUserDetails, callback = null, checkForClockOut: boolean = true) => {
    this.spinnerService.show();
    this.applicationLoginService.clockLoginCheck(password)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          if (res) {
            const username = res.UserClockStatus.LastName + ' ' + res.UserClockStatus.FirstName;
            if (res.UserClockStatus == null) {
              this.clockStatus = DomainConstants.ClockInOut.CLOCK_IN;
              this.clockInComponent.openClockInStatusModal(logInUserDetails.id, username, Messages.UserCurrentlyClockOut, this.clockStatus, logInUserDetails, callback);
            } else if (res.UserClockStatus.id !== -1) {
              if (res.UserClockStatus.ClockOutDateTime) {
                this.clockStatus = DomainConstants.ClockInOut.CLOCK_IN;
                this.clockInComponent.openClockInStatusModal(logInUserDetails.id, username, Messages.UserCurrentlyClockOut, this.clockStatus, logInUserDetails, callback);
              } else if (checkForClockOut && (this.checkIsClockedInComponentOpen() || this.terminalType === this.terminalTypes.TIME_ENTRY.Name)) {
                this.clockStatus = DomainConstants.ClockInOut.CLOCK_OUT;
                this.clockInComponent.openClockInStatusModal(logInUserDetails.id, username, Messages.UserCurrentlyClockIn, this.clockStatus, callback);
              } else if (callback) {
                callback(logInUserDetails);
              }
            } else {
              this.alertService.renderErrorMessage(Messages.PasswordIncorrect);
            }
          }
        }, error: this.alertService.showApiError
      });
  }

  saveTerminalEvents(userDetails, isReloadHomeState, callBack?: Function) {
    const terminalDetails = {
      TerminalId: this.applicationStateService.terminalId,
      TerminalName: this.applicationStateService.terminalName,
      TerminalType: this.applicationStateService.terminalType
    };
    this.spinnerService.show();
    this.applicationLoginService.checkTerminalName(terminalDetails).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (response) => {
        if (response) {
          this.saveTerminalEventLog(userDetails, isReloadHomeState, callBack);
        }
      }, error: this.alertService.showApiError
    });
  }

  private saveTerminalEventLog(userDetails, isReloadHomeState, callBack?: Function) {
    this.applicationStateService.gotoLoginScreen = false;
    const terminalEvent = {
      userId: this.applicationStateService.userId,
      terminalid: this.applicationStateService.terminalId,
      eventMessage: 'Terminal Sign In',
      eventDetails: 'Sign In',
      terminalname: this.applicationStateService.terminalName,
      ipAddress: ''
    };
    this.spinnerService.show();
    const terminalSelectionObservables = [];
    terminalSelectionObservables.push(this.terminalService.saveTerminalEvents(terminalEvent));
    terminalSelectionObservables.push(this.terminalService.logTerminalUserLogin(terminalEvent));
    terminalSelectionObservables.push(this.reloadSettingsService.reloadSettings(this.applicationStateService.terminalId));
    terminalSelectionObservables.push(this.applicationFeatureService.getApplicationFeatures());
    terminalSelectionObservables.push(this.permissionService.getUserPermission());
    forkJoin(terminalSelectionObservables).pipe(finalize(() => {
      this.spinnerService.hide();
    }))
      .subscribe({
        next: (responses: any) => {
          if (!this.applicationStateService.userSignInByButton) {
            this.signInMessage = Messages.CardbasedLoginOtherUserSignedin +
              userDetails.firstname + ' ' + userDetails.lastname;
            this.cardBasedSignInModalRef = this.bsModalService.show(this.cardBasedSignIn, {
              'backdrop': 'static',
              'class': 'vertical-center',
              'keyboard': false
            });
            this.modalBackdropService.addBackDrop();
            setTimeout(() => {
              this.cardBasedSignInModalRef.hide();
              this.modalBackdropService.removeBackdrop();
              this.homeClick();
              window.location.reload();
            }, 2000);
          }
          if (callBack) {
            callBack();
          }
        }, error: this.alertService.showApiError
      });
  }

  rabbitMQSubscriptions() {
    this.refreshTerminalRequestSubscription = this.rabbitMQService.subscribeToRefreshTerminal$(this.applicationStateService.terminalId)
      .subscribe({
        next: () => {
          window.location.reload();
        }
      })
    this.terminalLogoutRequestSubscription = this.rabbitMQService.subscribeToTerminalLogoutMessage$(this.applicationStateService.terminalId)
      .subscribe({
        next: (message) => {
          if (message.Payload.TerminalId === this.applicationStateService.terminalId) {
            this.forceLogOutMessage = ' Another user has signed into the ' + message.Source.Name + ' terminal.  Logging out...';
            this.forceLogout = true;
            this.openForceLogoutModal();
          }
        }, error: () => {
          console.log('error while connecting to RabbitMQ.');
        }
      });

    this.userClockOutSubscription = this.rabbitMQService.subscribeToUserClockOutMessage$()
      .subscribe({
        next: (message: any) => {
          if (message.Payload.UserId === this.applicationStateService.userId) {
            if (parseInt(message.Source.Id) !== this.applicationStateService.terminalId || this.isTimeEntryTerminal) {
              this.forceLogOutMessage = 'This user has clocked out.  Signing out...';
              this.forceLogout = true;
              this.openForceLogoutModal();
            }
          }
        }, error: () => {
          console.log('error while connecting to RabbitMQ.');
        }
      });

    this.refreshActiveOrderSubscription = this.rabbitMQService.subscribeToRefreshActiveOrderMessage$()
      .subscribe({
        next: (message) => {
          this.refreshActiveOrder();
        }, error: () => {
          console.log('error while connecting to RabbitMQ.');
        }
      });

    this.globalLogoffSubscription = this.rabbitMQService.subscribeToGlobalLogoffMessage$()
      .subscribe({
        next: (message: any) => {
          this.forceLogOutMessage = message.Payload.CommandArguments.Message;
          this.forceLogout = true;
          this.openForceLogoutModal();
        }, error: () => {
          console.log('error while connecting to RabbitMQ.');
        }
      });

    this.terminalInUseSubscription = this.rabbitMQService.subscribeToTerminalUseMessage$()
      .subscribe({
        next: (message: any) => {
          this.rabbitMQService.sendConfirmTerminalUseMessage(message.Source.Id, this.applicationStateService.terminalId,
            this.applicationStateService.terminalName, this.settingParams.TerminalType);
        }, error: () => {
          console.log('error while connecting to RabbitMQ.');
        }
      });

    // persistentRabbitMqSubscriptions
    this.userTrainingBadgeSubscription = this.rabbitMQService.subscribeToUserTrainingBadgeExchange$()
      .subscribe({
        next: (message: any) => {
          if (message.Payload) {
            if (message.Payload.Message === this.userId) {
              this.getUserTrainingBadges(message.Payload.Message);
            }
          }
        }, error: () => {
          console.log('error while connecting to RabbitMQ.');
        }
      });

    this.rabbitMqActiveOrderServedSubscription = this.rabbitMQService.subscribeToOrderServedMessage$()
      .subscribe({
        next: (message: RabbitMQMessage<OrderIdRabbitMQPayload>) => {
          remove(this.unservedOrdersData, (order) => {
            return order.id == message.Payload.OrderId
          })
        }
      });
  }

  refreshActiveOrder = debounce(() => {
    this.getUnservedOrder();
  }, 1500, {
    'maxWait': 1500,
    'trailing': true,
    'leading': true
  })

  getTaskDetails() {
    this.managesService.getTaskDetails()
      .subscribe({
        next: (res) => {
          if (res && res.TaskDetailModel) {
            _.forEach(res.TaskDetailModel, (task) => {
              if (task.signed_off_on == null) {
                this.eventBroadcastingService.onShiftDutiesDue(true);
                return;
              }
            });
          }
        }, error: this.alertService.showApiError
      });
  }

  getUserTrainingBadges(userId: number) {
    this.badgeIcon = null;
    this.userTrainingDetails = {};
    const trainingObservables = [];
    trainingObservables.push(this.applicationLoginService.getUserTrainingBadges(userId));
    trainingObservables.push(this.applicationLoginService.getUserTrainingDetails(userId));
    forkJoin(trainingObservables)
      .subscribe({
        next: (responses: Array<any>) => {
          if (responses) {
            if (responses[0]) {
              const trainingBadge = _.maxBy(responses[0],
                (badge: any) => { return badge.TrainingBadge.ScoreThreshold; });
              if (trainingBadge) {
                this.badgeIcon = trainingBadge.TrainingBadge.Icon;
                this.badgeName = trainingBadge.TrainingBadge.Name;
              }
            }
            if (responses[1]) {
              this.userTrainingDetails = responses[1];
            }
          }
        }, error: this.alertService.showApiError
      });
  }

  setTerminalTypeConfiguration() {
    if (this.applicationStateService.terminalType === DomainConstants.TerminalTypes.ORDER_ENTRY.Name
      || this.applicationStateService.terminalType === DomainConstants.TerminalTypes.MAKE_TABLE.Name) {
      this.isPopoutRequired = true;
    } else {
      this.isPopoutRequired = false;
    }
    if (this.applicationStateService.terminalType === DomainConstants.TerminalTypes.TIME_ENTRY.Name) {
      this.isTimeEntryTerminal = true;
      this.timeEntryClick();
    }
  }

  getUnservedOrder() {
    if (this.linksVisibility.activeOrders) {
      if (this.applicationStateService.terminalType === DomainConstants.TerminalTypes.MAKE_TABLE.Name) {
        this.orderService.getUnservedOrdersForMakeTable(this.applicationStateService.terminalId)
          .subscribe({
            next: (res) => {
              this.setOrderCompleted(res);
            }, error: this.alertService.showApiError
          });
      } else {
        this.orderService.getUnservedOrdersData(this.settingParams.CashDrawerPrinter.Id, this.userDetails.id)
          .subscribe({
            next: (res) => {
              this.setOrderCompleted(res);
            }, error: this.alertService.showApiError
          });
      }
    }

  }
  identify = (index: number, item: any) => item;
  setOrderCompleted(response) {
    if (response) {
      _.forEach(response, (orderItem) => {
        orderItem.Minutes = parseInt(orderItem.Minutes, null) + 1;
        orderItem.Min = parseInt((orderItem.Minutes / 60).toString(), null);
        orderItem.Seconds = parseInt((orderItem.Minutes % 60).toString(), null);
      });
      this.unservedOrdersData = response;
      const phoneItems = _filter(this.unservedOrdersData, (unservedOrder) =>
        unservedOrder.FirstMainProduct.includes(DomainConstants.PhoneText)
      );

      forEach(phoneItems, (item) => {
        item.FirstMainProduct = this.stringUtilityService.maskPhoneString(item.FirstMainProduct);
      });
      this.eventBroadcastingService.changeNoOfUnservedOrder(response.length);
      this.applicationStateService.unservedOrdersData = this.unservedOrdersData;
      if (!this.timerSubscription) {
        this.subscribeTimerForActiveOrder();
      }

    }
  }

  subscribeTimerForActiveOrder() {

    this.timer = observableInterval(1000);
    this.timerSubscription = this.timer.subscribe((t) => {
      if (this.unservedOrdersData) {
        _.forEach(this.unservedOrdersData, (orderItem) => {
          orderItem.Minutes = parseInt(orderItem.Minutes, null) + 1;
          orderItem.Min = parseInt((orderItem.Minutes / 60).toString(), null);
          orderItem.Seconds = parseInt((orderItem.Minutes % 60).toString(), null);
        });
      }
    });
  }

  gotoTerminal() {
    this.router.navigate(['terminals']);
  }

  homeClick() {
    switch (this.applicationStateService.terminalType) {
      case DomainConstants.TerminalTypes.MAKE_TABLE.Name:
        this.homeState = 'make-table';
        break;
      case DomainConstants.TerminalTypes.BACK_OFFICE.Name:
        this.homeState = 'back-office/console';
        break;
      default:
        break;
    }

    if (this.router.url === this.homeState) {
      this.orderEventBroadcastingService.changeHomeState();
    }
    this.router.navigate([this.homeState]);
    this.closeMenu();
  }

  warningClick() {

    this.openWarningDialog();
  }
  adminClick() {
    this.router.navigate(['/manage/console']);
    this.closeMenu();
  }

  timeEntryClick = () => {
    this.clockInModalRef = this.modalService.show(ClockInComponent, {
      animated: false,
      class: 'vertical-center modal-max-width-65',
      keyboard: false,
      initialState: {
        isTimeEntryTerminal: this.isTimeEntryTerminal
      }
    });

    this.clockInModalRef.close.subscribe(res => {
      this.clockInModalRef = null;
    });
    this.closeMenu();
  }

  logoutClick(isRequireRefresh = false) {
    if (this.settingParams.PreventLogoutUntilDrawerClosed) {
      this.checkDrawerStatus();
    } else {
      this.addCameraAnnotationForSignOut();
      this.removeSettings();
      this.logout(isRequireRefresh);
    }
  }

  checkDrawerStatus() {
    this.spinnerService.show();
    const cashDrawerId = this.settingParams && this.settingParams.CashDrawerPrinter && this.settingParams.CashDrawerPrinter.Id ?
      this.settingParams.CashDrawerPrinter.Id : 0;
    this.managesService.getDrawerStatus(cashDrawerId).pipe(finalize(() => {
      this.spinnerService.hide();
    }))
      .subscribe({
        next: (res) => {
          if (res.action === 'Open') {
            this.showInfoMessage(Messages.CloseDrawerBeforeSignOut);
          } else {
            this.addCameraAnnotationForSignOut();
            this.removeSettings();
            this.logout();
          }
        }, error: this.alertService.showApiError
      });
  }

  addCameraAnnotationForSignOut() {
    if (this.applicationStateService?.userDetails) {
      this.cameraAnnotationService.addAnnotationToCamera(this.applicationStateService.cameraAnnotations.SignOut, {});
    }
  }

  linksClick() {
    this.router.navigate(['manage/custom-links']);
  }

  turnDebugClick() {
    this.isDebug = !this.isDebug;
    this.applicationStateService.isDebug = this.isDebug;
  }

  turnTerminalDesignModeClick = () => {
    this.terminalDesignMode = !this.terminalDesignMode;
    this.eventBroadcastingService.onTerminalDesignModeChanged(this.terminalDesignMode);
  }

  workScheduleClick() {
    this.router.navigate(['manage/work-schedule']);
  }

  endImpersonateClick() {
    this.applicationStateService.isImpersonateUser = false;
    this.applicationStateService.impersonateUserDetails = null;
    this.applicationStateService.gotoLoginScreen = true;
    window.location.reload();
  }

  signInClick() {
    this.removeSettings();
    const signInAsDiffUserTerminalDetail = {
      TerminalId: this.applicationStateService.terminalId,
      TerminalName: this.applicationStateService.terminalName,
      TerminalType: this.applicationStateService.terminalType
    };
    this.applicationStateService.gotoLoginScreen = true;
    this.applicationStateService.signInAsDiffUserTerminalDetail = signInAsDiffUserTerminalDetail;
    this.logout();
  }


  taskClick() {
    const shiftModalRef = this.modalService.show(ShiftDutiesComponent, {
      animated: false,
      class: 'vertical-center modal-max-width-65',
      initialState: {
        shiftDutiesDue: this.shiftDutiesDue
        // commentWarningId: data
      }
    });

    shiftModalRef.close.subscribe(res => {
      if (res && res.shouldReload) {
        // this.reloadCommentWarningList();
      }
    });
    this.closeMenu();
  }

  logout(isRequireRefresh = false) {
    if (this.forceLogoutModalRef) {
      this.forceLogoutModalRef.close.emit();
      this.modalBackdropService.removeBackdrop();
      clearInterval(this.forceLogoutInterval);
    }
    this.applicationService.logout(this.forceLogout, isRequireRefresh);
    if (this.forceLogout) this.forceLogout = false;
  }

  removeSettings() {
    this.applicationStateService.gotoLoginScreen = false;
    this.applicationStateService.userDetails = null;
    this.applicationStateService.username = null;
    this.applicationStateService.gotoTerminal = false;
    this.applicationStateService.cartOrder = null;
    this.applicationStateService.parentId = 0;
    this.applicationStateService.unservedOrdersData = [];
    this.applicationStateService.productNavigationDetails = [];
    this.applicationStateService.defaultProductSelected = [];
    this.applicationStateService.screenBehaviorData = [];
    this.applicationStateService.inventoryOrderReceivingProductList = [];
    this.applicationStateService.inventoryOrderReceivingNewBarcodeList = [];
    this.applicationStateService.signInAsDiffUserTerminalDetail = null;
    this.applicationStateService.isImpersonateUser = false;
    this.applicationStateService.impersonateUserDetails = null;
    this.applicationStateService.sessionDesignMode = false;
    this.applicationStateService.userCode = null;
  }

  toggleActiveOrders(show) {
    if (this.applicationStateService.terminalType === DomainConstants.TerminalTypes.ORDER_ENTRY.Name) {
      this.activeOrdersVisible = show;
    }
  }

  toggleMappedTerminal(show) {
    this.mappedTerminalVisible = show;
  }

  getActiveOrder(orderData: any) {
    this.router.navigate([this.homeState], { state: { orderId: orderData.id } });
    const self = this;
    setTimeout(() => {
      this.audioOperationsService.tagAudioRecord(DomainConstants.AudioInteractions.AccessExistingOrder, orderData.id.toString());
      // Need to set flag false for close active order menu after select order
      self.activeOrdersVisible = false;
      self.orderEventBroadcastingService.activeOrderClicked.emit(orderData.id);
    });
  }

  reloadSettings() {
    this.spinnerService.show();
    const reloadStatesObservables = [];
    reloadStatesObservables.push(this.reloadSettingsService.reloadSettings(this.applicationStateService.terminalId));
    reloadStatesObservables.push(this.applicationFeatureService.getApplicationFeatures());
    forkJoin(reloadStatesObservables).pipe(finalize(() => {
      this.spinnerService.hide();
    }))
      .subscribe({
        next: (responses: any) => {
        }, error: this.alertService.showApiError
      });
  }
  subscriptionClick() {
    this.router.navigate(['manage/report/subscriptions']);
  }

  addWarning = (warning: any) => {
    if (warning.WarningType === this.warningTypes.MENU_CHANGED && (JSON.parse(this.applicationStateService.sessionDesignMode) === true
      || this.router.url !== '/order/order-entry')) {
      return;
    }

    const existingWarning = _.find(this.warnings, (warn) => {
      return warn.WarningType === warning.WarningType && warn.WarningId === warning.WarningId && warn.Message === warning.Message;
    });

    if (!existingWarning || (existingWarning && existingWarning.length === 0)) {
      let warningObj = { ...{}, ...warning };
      warningObj.ReceivedOn = new Date();
      warningObj.Viewed = false;
      this.warnings.push(warningObj);
      this.scheduleForceOpenWarning(warningObj);
    }
  }

  scheduleForceOpenWarning(warning) {
    const self = this;
    setTimeout((warn) => {
      const unreadWarnings = _.filter(self.warnings, (w) => {
        return w.WarningType === warn.WarningType && w.WarningId === warn.WarningId && !w.Viewed;
      });
      if (self.warnings && self.warnings.length > 0 && unreadWarnings && unreadWarnings.length > 0) {
        if (!self.terminalWarningModalRef) {
          self.openWarningDialog();
        }
      }
    }, this.warningForcedDisplayInterval, { ...{}, ...warning });
  }

  openWarningDialog() {
    if (this.terminalWarningModalRef) {
      this.closeWarningDialog();
    }
    this.terminalWarningModalRef = this.bsModalService.show(this.terminalWarningModal, {
      'backdrop': 'static',
      'class': 'vertical-center',
      'keyboard': false
    });
    this.modalBackdropService.addBackDrop();
  }

  removeWarning(warning: any) {
    const warns = _.find(this.warnings, (warn) => {
      return warn.WarningType === warning.WarningType && warn.WarningId === warning.WarningId;
    });
    this.warnings = warns ? warns : [];
    if (!this.warnings || !this.warnings.length) {
      this.warnings = [];
      this.terminalWarningModalRef.hide();
      this.modalBackdropService.removeBackdrop();
      this.terminalWarningModalRef = null;
    }
  }

  menuChangedWarningClick(warning: any) {
    this.orderEventBroadcastingService.onMenuChangeClicked();
    this.removeWarning(warning);
  }

  warningClicked(warning: any) {
    if (warning.IsActionable) {
      if (warning.WarningType === this.warningTypes.MENU_CHANGED) {
        warning.Viewed = true;
        this.menuChangedWarningClick(warning);
      }
    }
  }

  closeWarningDialog() {
    this.warnings = _.filter(this.warnings, (warn) => {
      return warn.IsActionable;
    });

    if (this.terminalWarningModalRef) {
      this.terminalWarningModalRef.hide();
      this.modalBackdropService.removeBackdrop();
      this.terminalWarningModalRef = null;
    }
  }

  closeMenu() {
    let menu = this.elRef.nativeElement.querySelector('#bs-example-navbar-collapse-1');
    if (menu) {
      menu?.classList.remove('in');
    }
  }

  eventBroadcastingSubscriptions() {
    this.eventSubscriptions.push(this.eventBroadcastingService.reloadSettings.subscribe(
      () => {
        this.setDefaults();
      }
    ));

    this.eventSubscriptions.push(this.eventBroadcastingService.userSignInByButton.subscribe(
      (details: UserSignInByButtonModel) => {
        this.applicationStateService.userSignInByButton = true;
        this.signIn(details.userDetails, () => {
          if (details.callBack) {
            this.applicationStateService.userSignInByButton = false;
            details.callBack();
          }
        });
      }
    ));


    this.eventSubscriptions.push(this.eventBroadcastingService.noOfUnservedOrder.subscribe(
      (orderCount: number) => {
        if (this.applicationStateService.terminalType === DomainConstants.TerminalTypes.ORDER_ENTRY.Name
          || this.applicationStateService.terminalType === DomainConstants.TerminalTypes.MAKE_TABLE.Name) {
          document.title = orderCount > 0 ? ('(' + orderCount + ') ' + this.settingParams.CustomerName) :
            this.settingParams.CustomerName;

        } else {
          document.title = this.settingParams.CustomerName;
        }
      }
    ));

    this.eventSubscriptions.push(this.orderEventBroadcastingService.terminalWarning.subscribe(
      (warning: any) => {
        this.addWarning(warning);
      }
    ));

    this.eventSubscriptions.push(this.eventBroadcastingService.terminalWarningClosure.subscribe(
      (warning: any) => {
        this.removeWarning(warning);
      }
    ));

    this.eventSubscriptions.push(this.eventBroadcastingService.shiftDutiesDue.subscribe(
      (shiftDutiesDue: any) => {
        this.shiftDutiesDue = shiftDutiesDue;
      }
    ));

    this.eventSubscriptions.push(this.eventBroadcastingService.cardBasedSignInChanged.subscribe(
      (isUserSwipeDisabled: any) => {
        this.isUserSwipeDisabled = isUserSwipeDisabled;
      }
    ));

    this.eventSubscriptions.push(this.orderEventBroadcastingService.deleteOrder.subscribe({
      next: (orderId: number) => {
        _.remove(this.unservedOrdersData, (order) => {
          return orderId == order.id;
        });
        this.applicationStateService.unservedOrdersData = this.unservedOrdersData;
      }
    }));
  }
  async delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  contactSupport() {
    if (typeof Tawk_API !== 'undefined') {
      Tawk_API.toggle();
    }
  }

  filterProducts() {
    if (this.deviceDetectorService.isMobile()) {
      this.filterProductsClick.emit();
    } else {
      const modalRef = this.modalService.getModalWrapper(FilteredProductsComponent);
      modalRef.show({
        animated: false,
        class: 'vertical-center',
        initialState: {
          terminalId: this.settingParams.TerminalId
        }
      });
    }
    this.closeMenu();
  }

  showInfoMessage(message) {
    const modalRef = this.modalService.show(InfoModalComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        message: message
      }
    });
  }
}
