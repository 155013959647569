import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { RuntimeConstants } from 'src/app/shared/constants';
import { SettingParam } from 'src/app/shared/interface';

@Injectable({
  providedIn: 'root'
})
export class CheckoutConfigService {

  checkoutConfigApiBasePath = RuntimeConstants.API_PATH + 'checkout-config';
  constructor(private httpClient: HttpClient) { }
  getCheckoutConfig(): Observable<SettingParam> {
    return this.httpClient.get<SettingParam>(`${this.checkoutConfigApiBasePath}`);
  }

  save(params, image: File): Observable<SettingParam> {
    const formData = this.prepareImageForm(params, image);
    return this.httpClient.post<SettingParam>(this.checkoutConfigApiBasePath, formData);
  }

  prepareImageForm(params, imageFile: File): FormData {
    const formData = new FormData();
    if (imageFile) {
      formData.append('file', imageFile);
    }
    formData.append('model', JSON.stringify(params));
    return formData;
  }
}

