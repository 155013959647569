import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';
import { CashPayOut } from '../interface/cash-payout';
import { Observable } from 'rxjs';

@Injectable()
export class CashPayoutService extends BaseCrudService<CashPayOut> {

    cashPayoutApiUrl = '';
    constructor(httpService: HttpClient) {
        super('cashpayout', httpService);
        this.cashPayoutApiUrl = RuntimeConstants.API_PATH + 'cashpayout';
    }
    newCashPayout() {
        return {
            Id: 0,
            UserId: null,
            CashDrawerId: null,
            Amount: null,
            Name: '',
            CashDrawerName: '',
            Memo: ''
        };
    }
    getCashDrawers(): Observable<any> {
        return this.httpService.get(this.cashPayoutApiUrl + '/CashDrawers');
    }
}
