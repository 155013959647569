import { Route } from '@angular/router';
import { AuthGuard } from '../app-guard';
import { Levels, DomainConstants } from '../shared/constants';
import { TimerComponent } from './components/timer/timer.component';

const TIMER: Route = {
    path: '',
    canActivate: [AuthGuard],
    component: TimerComponent,
    data: {
        permissions: [{ 'Name': DomainConstants.TerminalTypes.TIMER.Permission, 'Level': Levels.Access }]
    }
};

export const TIMER_ROUTES = [TIMER];
