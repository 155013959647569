import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuExplorerComponent } from './components/menu-explorer/menu-explorer.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MENU_EXPLORER_STATES } from './menu-explorer.routes';
import { ComponentsModule } from '../shared/components';
import { HeaderEntryModule } from '../header-entry';
import { MenuExplorerButtonComponent } from './components/menu-explorer-button/menu-explorer-button.component';
import { MenuExplorerProductInfoComponent } from './components/menu-explorer-product-info/menu-explorer-product-info.component';
import { TableModule } from '@tarktech/tark-ng-utils';
import { IngredientsDietaryWarningService, IngredientsInfoService, ScreenSetupComponent } from '../orders/order-entry';
import { IngredientsInfoCardComponent } from './components/ingredients-info-card/ingredients-info-card.component';
import { DietaryWarningsInfoComponent } from './components/dietary-warnings-info/dietary-warnings-info.component';
import { CheckboxModule } from 'primeng/checkbox';
import { InventoryProductNutritionService } from '../information-management/nutrition-facts';
import { OrderModule } from '../orders/order.module';
import { AutoCompleteModule } from 'primeng/autocomplete';

@NgModule({
  declarations: [
    MenuExplorerComponent,
    MenuExplorerButtonComponent,
    MenuExplorerProductInfoComponent,
    IngredientsInfoCardComponent,
    DietaryWarningsInfoComponent  ],
  imports: [
    CommonModule,
    FormsModule,
    ComponentsModule,
    OrderModule,
    RouterModule.forChild(MENU_EXPLORER_STATES),
    HeaderEntryModule,
    TableModule,
    CheckboxModule,
    AutoCompleteModule,
  ],
  providers: [
    IngredientsInfoService, IngredientsDietaryWarningService, InventoryProductNutritionService
  ]
})
export class MenuExplorerModule { }
