export module StringUtils {
    export function format(str, col) {
        col = typeof col === 'object' ? col : Array.prototype.slice.call(arguments, 1);

        return str.replace(/\{\{|\}\}|\{(\w+)\}/g,
            function (match, number) {
                if (match == "{{") {
                    return "{";
                }
                if (match == "}}") {
                    return "}";
                }
                return (col[number] != null || col[number] != undefined) ? (col[number]) : '';
            });
    }
} 
