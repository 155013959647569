import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { LogModel } from '../interfaces/log-model';

@Injectable()
export class SystemLogViewerService {

	private applicationLogApiUrl = '';
	
	constructor(private httpService: HttpClient) {
		this.applicationLogApiUrl = RuntimeConstants.API_PATH + 'applicatonlog';
	}

	public getLogFileList(): Observable<Array<string>> {
		return this.httpService.get<Array<string>>(this.applicationLogApiUrl + '/files');
	}

	public getLogFileDetails(fileName: string): Observable<Array<LogModel>> {
		return this.httpService.get<Array<LogModel>>(this.applicationLogApiUrl + '/files/' + fileName);
	}

}
