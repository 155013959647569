import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RuntimeConstants } from 'src/app/shared';
import { DjSchemeConfig } from '../interface/dj-scheme-config';

@Injectable({
  providedIn: 'root'
})
export class DjService {

  djSchemeApiBasePath = `${RuntimeConstants.API_PATH}djschemes`;

  constructor(private httpClient: HttpClient) { }

  getDJSchemesDetails(terminalId: number): Observable<DjSchemeConfig> {
    return this.httpClient.get<DjSchemeConfig>(`${this.djSchemeApiBasePath}/DJSchemes/${terminalId}`);
  }
}
