import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalComponent } from 'src/app/shared/components/modal';
declare let $: any;

@Component({
  selector: 'pos-kiosk-payment-options-screen',
  templateUrl: './kiosk-payment-options-screen.component.html',
  styleUrls: ['./kiosk-payment-options-screen.component.scss']
})
export class KioskPaymentOptionsScreenComponent extends ModalComponent {

  @Input() router;
  @Input() route;

  constructor(public modalRef: BsModalRef
  ) {
    super(modalRef);
  }

  ngOnInit() {
    $(".modal-backdrop").addClass("kiosk-payment-modal");
  }

  payAtCounter = () => {
    this.close.emit();
    this.router.navigate(['confirmed'], { relativeTo: this.route.parent });
  }

  payViaCC = () => {
    this.close.emit();
    this.router.navigate(['cc-payment'], { relativeTo: this.route.parent });
  }

}
