<pos-edit-wrapper [isPopup]="true" heading="Product Dashboard: {{salesProductName}}" (close)="onCancel()">
    <div class="col-12 pos-modal-body">
        <div class="col-md-12 form-horizontal overflow-auto-tabs sales-dashboard" [ngStyle]="{'height': height + 'px'}">
            <pos-dashboard-configuration [dashboardId]="dashboardId" [salesProductId]="salesProductId">
            </pos-dashboard-configuration>
        </div>
        <div class="clearfix"></div>
        <div class="col-12 action-button-container popup-footer">
            <button type="button" data-dismiss="modal" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" (click)="onCancel()"
                value="Cancel">Close</button>
        </div>
    </div>
</pos-edit-wrapper>
