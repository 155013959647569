<a class="grey pointer" #pop="bs-popover" placement="left" [outsideClick]="true"
    *ngIf="isDesignMode && (screenButton?.Button || setupItems.IsEditable)"
    (click)="openOptionsMenu($event); popup = pop" container="body" [popover]="salesProductPopoverTemplate"
    triggers="click">
    <pos-action-icon [icon]="icons.cogWhite" class="pointer"></pos-action-icon>
</a>
<ng-template let-data="rowData" #salesProductPopoverTemplate>
    <ul class="kiosk-screen-setup" id="menu_{{screenButton.Ordinal}}">
        <li class="dropdown-item pointer" *ngIf="setupItems.SetButton" (click)="configure($event)">
            <span><pos-icon [icon]="icons.book" class="me-pix-2"></pos-icon></span> Set Button
        </li>
        <li class="dropdown-item pointer" *ngIf="setupItems.SetNextScreen" (click)="configure($event, false)">
            <span><pos-icon [icon]="icons.desktop" class="me-pix-2"></pos-icon></span> Set Next Screen
        </li>
        <li class="dropdown-item pointer" *ngIf="setupItems.DesignButton" (click)="designButton($event)">
            <span><pos-icon [icon]="icons.paintBrush" class="me-pix-2"></pos-icon></span> Design Button
        </li>
        <li class="dropdown-item pointer" *ngIf="setupItems.RemoveButton" (click)="removeButton($event)">
            <span><pos-icon [icon]="icons.times" class="me-pix-2"></pos-icon></span> Remove Button
        </li>
    </ul>
</ng-template>
<pos-action-icon *ngIf="isDesignMode && isAllowDelete && screenButton.Ordinal != 1" [icon]="icons.deleteWhite"
    class="pointer" [additionalClasses]="['font-14', 'white', 'mt-pix-1']"
    (click)="deleteButton($event)"></pos-action-icon>
