import { Component, OnInit, ViewChild } from '@angular/core';
import { cloneDeep, find, forEach } from 'lodash';
import { finalize } from 'rxjs/operators';
import { AlertsService, ApplicationStateService, BaseFormComponent, ConfirmDeleteModalComponent, DomainConstants, FormUtilityService, InventoryLogService, InventoryProductService, Messages, ModalService, SpinnerService } from 'src/app/shared';
import { InventoryProductDetails } from 'src/app/shared/interface/inventory-product-deail';
import { InventoryReceived } from 'src/app/shared/interface/inventory-received';
import { InventoryProductReceived } from '../../interfaces/inventory-product-received';
import { InventoryReceivingService } from '../../services/inventory-receiving.service';
import { deleteWhite, backward, forward } from 'src/app/shared/components/icon';
import Pluralize from 'pluralize';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { InventoryOffSiteLocation, InventoryOffSiteLocationService } from 'src/app/information-management';
import { forkJoin } from 'rxjs';
declare let $: any;
@Component({
  selector: 'pos-inventory-receiving-edit',
  templateUrl: './inventory-receiving-edit.component.html',
  styleUrls: ['./inventory-receiving-edit.component.scss']
})
export class InventoryReceivingEditComponent extends BaseFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.form;
  }

  startDate: Date;
  endDate: Date;
  inventoryProductId: number;
  inventoryReceivedFinalList: Array<InventoryProductReceived> = [];
  inventoryProducts: Array<InventoryProductDetails> = [];
  isUpdateAnyProduct: boolean = false;
  selectedReceivingLocationId: number = null;
  @ViewChild('inventoryReceivingEditForm', { static: true }) form: NgForm;
  public icons = {
    deleteWhite,
    backward,
    forward
  };
  Pluralize = Pluralize;
  dateFormat = 'mm-dd-yyyy';
  inventoryLocations: Array<InventoryOffSiteLocation> = [];
  selectedLocation = 0;
  selectedLocationName = DomainConstants.OnSiteInventoryText;
  inventoryProductsReceived: Array<InventoryProductReceived> = [];

  constructor(private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private inventoryProductService: InventoryProductService,
    private modalService: ModalService,
    private inventoryReceivingService: InventoryReceivingService,
    private applicationStateService: ApplicationStateService,
    private router: Router,
    private inventoryOffSiteLocationService: InventoryOffSiteLocationService,
    private inventoryLogService: InventoryLogService,
    formUtilityService: FormUtilityService) {
    super(formUtilityService);
    const navigation = router.getCurrentNavigation();
    this.selectedReceivingLocationId = navigation?.extras?.state?.SelectedLocationId ?? null;
    this.selectedLocationName = this.applicationStateService.settingParam.DefaultLocationTerm;
  }

  ngOnInit(): void {
    this.setDefaults();
    this.searchInventoryReceived();
    this.getInventoryProductsAndLocations();
    this.resetProductAreaHeight();
    this.resizeWindow();
    this.dateFormat = this.applicationStateService.settingParam.PCalendarDateFormat;
  }

  resetProductAreaHeight = () => {
    const scrolableHeight = $(window).height() - 335;
    $('.inner_remaining').height(scrolableHeight);
  }

  resizeWindow() {
    $(window).resize(() => {
      this.resetProductAreaHeight();
    });
  }

  setDefaults = () => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 2);
    this.startDate = currentDate;
    this.endDate = new Date();
  }

  searchInventoryReceived = () => {
    this.spinnerService.show();
    let inventoryReceivedEdit = {
      ProductId: this.inventoryProductId,
      StartDateTime: this.startDate,
      EndDateTime: this.endDate
    }

    this.inventoryProductService.getInventoryReceivedByDate(inventoryReceivedEdit)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: Array<InventoryReceived>) => {
          this.setInitialValue();
          this.getInventoryReceivedByDateCompleted(response);
        }, error: this.alertService.showApiError
      });
  }

  orderReceivedQuantityChanged = (itemDetails, tooltip) => {
    this.isUpdateAnyProduct = true;
    itemDetails.IsUpdated = true;
    if (this.inventoryLogService.hasUnitQtyIncrementError(itemDetails.NewQty, itemDetails.UnitQtyIncrement))
      tooltip.show();
    else
      tooltip.hide();
  }

  getInventoryProductsAndLocations = () => {
    this.spinnerService.show();
    forkJoin([this.inventoryProductService.getActiveInventoryProducts(true), this.inventoryOffSiteLocationService.getActiveLocations()])
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: ([inventoryProducts, locations]: [Array<InventoryProductDetails>, Array<InventoryOffSiteLocation>]) => {
          this.inventoryProducts = inventoryProducts?.length ? inventoryProducts : [];
          this.inventoryLocations = locations;
        }, error: this.alertService.showApiError
      });
  }

  getInventoryReceivedByDateCompleted = (res: Array<InventoryReceived>) => {
    this.inventoryProductsReceived = [];
    this.inventoryReceivedFinalList = [];
    let receivedDate = '';
    let receivedDateList: Array<InventoryReceived> = [];
    let inventoryReceivedList = {
      ReceivedDate: new Date(),
      ReceivedDateList: []
    } as InventoryProductReceived;
    if (res?.length) {
      forEach(res, (item) => {
        if (receivedDate.substring(0, 10).startsWith(item.ReceivedDate.toString().substring(0, 10)) || item.Id === res[0].Id) {
          receivedDateList.push(item);
          item.IsUpdated = false;
          item.NewQty = null;
          receivedDate = item.ReceivedDate.toString();
          inventoryReceivedList.ReceivedDate = item.ReceivedDate;
        } else {
          inventoryReceivedList.ReceivedDateList = cloneDeep(receivedDateList);
          this.inventoryProductsReceived.push(inventoryReceivedList);
          inventoryReceivedList = {
            ReceivedDate: new Date(),
            ReceivedDateList: []
          } as InventoryProductReceived;
          receivedDateList = [];
          receivedDate = item.ReceivedDate.toString();
          receivedDateList.push(item);
          item.IsUpdated = false;
          item.NewQty = null;
          inventoryReceivedList.ReceivedDate = item.ReceivedDate;
        }
        item.InventoryOffsiteLocation = find(this.inventoryLocations, il => (il.Id == (item.InventoryOffsiteLocationId ?? 0)))?.Name;
      });
      inventoryReceivedList.ReceivedDateList = receivedDateList;
      this.inventoryProductsReceived.push(inventoryReceivedList);
      this.getInventoryReceivedByLocation();
    }
  }

  addDays = (theDate, days) => {
    return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
  }

  nextDay = () => {
    this.startDate = this.addDays(this.startDate, 1);
    this.endDate = this.addDays(this.endDate, 1);
    this.searchInventoryReceived();
  }

  previousDay = () => {
    this.startDate = this.addDays(this.startDate, -1);
    this.endDate = this.addDays(this.endDate, -1);
    this.searchInventoryReceived();
  }

  confirmDeleteInventory = (receivedId: number) => {
    const confirmDelete = this.modalService.show(ConfirmDeleteModalComponent, {
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      initialState: {
        message: Messages.ConfirmDeleteInventoryReceived
      }
    });

    confirmDelete.close.subscribe(res => {
      if (res?.shouldDelete) {
        this.deleteInventoryReceived(receivedId);
      }
    });
  }

  deleteInventoryReceived = (receivedId: number) => {
    this.spinnerService.show();
    this.inventoryReceivingService.deleteInventoryReceived(receivedId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.alertService.renderSuccessMessage(Messages.InventoryReceivedDeleted);
          this.searchInventoryReceived();
        }, error: this.alertService.showApiError
      });
  }

  updateInventoryReceived = () => {
    const inventoryProducts: Array<InventoryReceived> = [];
    forEach(this.inventoryReceivedFinalList, (inventoryItem) => {
      forEach(inventoryItem.ReceivedDateList, (item) => {
        if (item.IsUpdated && item.Qty !== item.NewQty && item.NewQty > 0) {
          const receivedProduct = {
            Id: item.Id,
            InventoryProductId: item.InventoryProductId,
            Qty: this.precisionRound(item.NewQty - (item.Qty), 2),
            ReceivedDate: item.ReceivedDate,
            UserId: this.applicationStateService.userId,
            Barcode: '',
            TerminalName: this.applicationStateService.terminalName,
            InventoryOffsiteLocationId: item.InventoryOffsiteLocationId,
          } as InventoryReceived;
          inventoryProducts.push(receivedProduct);
        }
      });
    });
    if (this.isUpdateAnyProduct) {
      this.recordReceiving(inventoryProducts);
    } else {
      this.isUpdateAnyProduct = false;
      this.inventoryProductId = null;
      this.onCancel();
    }
  }

  recordReceiving = (inventoryProducts: Array<InventoryReceived>) => {
    this.spinnerService.show();
    this.inventoryReceivingService.recordInventoryReceiving(this.applicationStateService.terminalId, inventoryProducts)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          this.isUpdateAnyProduct = false;
          this.inventoryProductId = null;
          this.alertService.renderSuccessMessage(Messages.ProductRecevingUpdatedSuccess);
          this.searchInventoryReceived()
        }, error: this.alertService.showApiError
      });
  }

  precisionRound = function (number, precision) {
    const factor = Math.pow(10, precision);
    return Math.round(number * factor) / factor;
  };

  onCancel = () => {
    this.router.navigate(['manage/inventory-receiving'], { state: { SelectedLocationId: this.selectedReceivingLocationId }});
  }

  filterInventoryReceived() {
    this.selectedLocationName = this.inventoryLocations.find(x => x.Id == this.selectedLocation)?.Name;
    this.getInventoryReceivedByLocation();
  }


  private getInventoryReceivedByLocation() {
    const locationId = this.selectedLocation > 0 ? this.selectedLocation : null;
    this.inventoryReceivedFinalList = cloneDeep(this.inventoryProductsReceived);
    this.inventoryReceivedFinalList.forEach(x => x.ReceivedDateList = x.ReceivedDateList.filter(x => x.InventoryOffsiteLocationId == locationId));
  }
}
