<pos-edit-wrapper (close)="onCancel(false)" heading="Comment Warning">
    <form #commentWarningForm="ngForm" (ngSubmit)="saveCommentWarning(commentWarningForm.valid)" autocomplete="off">
        <context-provider provide="props">
            <div class="col-12 pos-page-body form-horizontal screen-scroll">
                <pos-row>
                    <pos-column>
                        <pos-form-field label="Text to Match" [validationMessages]="{required: 'Please enter text to match.'}">
                            <input posInput class="form-control" name="textToMatch" #textToMatch="ngModel"
                                [(ngModel)]="commentWarningData.CommentTextMatch" [required]="true">
                        </pos-form-field>
                    </pos-column>

                    <pos-column>
                        <pos-form-field label="Warning Message" inputStyle="col-12"
                            [validationMessages]="{required: 'Please enter warning message.'}">
                            <input posInput class="form-control" name="warningMessage" #warningMessage="ngModel"
                                [(ngModel)]="commentWarningData.WarningMessage" [required]="true">
                        </pos-form-field>
                    </pos-column>

                    <pos-column>
                        <pos-form-field [validationMessages]="{required: 'Please enter .'}">
                            <p-checkbox posInput name="chkPreventEntry" #chkPreventEntry="ngModel"
                                [(ngModel)]="commentWarningData.IsBlocked" binary="true" label="Prevent Entry">
                            </p-checkbox>
                        </pos-form-field>
                    </pos-column>
                </pos-row>
            </div>
            <div class="col-12 action-button-container popup-footer">
                <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" scrollup>Save </button>
                <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button"
                    (click)="onCancel(false)">Close</button>
            </div>
        </context-provider>
    </form>
</pos-edit-wrapper>
