import { Component, OnInit } from '@angular/core';
import { NumpadOptions, Messages, ModalComponent, ProductNavigationDetails } from 'src/app/shared';

import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'pos-manual-amount',
  templateUrl: './manual-amount.component.html',
  styleUrls: ['./manual-amount.component.scss']
})
export class ManualAmountComponent extends ModalComponent implements OnInit {
  numpadOptions: NumpadOptions;
  public onAmountSubmit: Function;
  public onAmountCancel: Function;
  button: ProductNavigationDetails;
  isShowTaxableSelection: boolean;
  taxableItem: string;
  typeSpecialAmount: number;
  selectedItemIsTaxable: boolean = true;
  constructor(modalRef: BsModalRef) {
    super(modalRef);
  }

  ngOnInit() {
    this.onAmountSubmit = this.makeSpecialEntry.bind(this);
    this.onAmountCancel = this.cancelSpecialPaymentPrice.bind(this);
    this.numpadOptions = {
      allowAlphabets: false,
      allowLeadingZero: false,
      allowDecimal: true
    };
    this.taxableItem = Messages.TaxableItem;
  }

  taxableSelection() {
    if (!this.selectedItemIsTaxable) {
      this.taxableItem = Messages.NonTaxable;
      this.selectedItemIsTaxable = true;
    }
    else {
      this.taxableItem = Messages.TaxableItem;
      this.selectedItemIsTaxable = false;
    }
  }

  makeSpecialEntry(amountObj) {
    this.hide({ shouldReload: true, buttonDetails: this.button, amount: amountObj.Value, selectedItemIsTaxable: this.selectedItemIsTaxable });
  }

  cancelSpecialPaymentPrice() {
    this.hide({ shouldReload: false });
  }
}
