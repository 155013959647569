<pos-list-wrapper [headingIcon]="icons.playListThList" [heading]="'Playlists'" (close)="close()">
  <div class="row">
    <div class="col-xs-12">
      <ng-table [columns]="playlistColumns" [data]="playlists" [paginator]="false" [search]="false"></ng-table>
      <ng-template let-data="rowData" #operationHeaderTemplate>
        <div class="text-center" permissions='[{"Name":"{{permission.name}}", "Level":"{{permission.editLevel}}"}]' tooltip title="Add">
          <pos-action-icon (click)="editItem(0)" [icon]="icons.plusWhite" iconText="Add" scrollup></pos-action-icon>
        </div>
      </ng-template>
      <ng-template let-data="rowData" #operationTemplate>
        <div permissions='[{"Name":"{{permission.name}}", "Level":"{{permission.editLevel}}"}]'>
          <span (click)="editItem(data.Id, data)" tooltip title="Edit" scrollup>
            <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
          </span>
          <span class="ps-pix-3" (click)="deletePlaylist(data)" tooltip title="Delete">
            <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
          </span>
          <span class="vertical-mid" (click)="openPlaylistTracksAddEdit(data.Id, data.Name)" tooltip scrollup
            title="Add/Edit Track">
            <pos-action-icon [icon]="icons.cogWhite"></pos-action-icon>
          </span>
        </div>
      </ng-template>
    </div>
  </div>
</pos-list-wrapper>
