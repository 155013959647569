import { Route } from '@angular/router';
import { AuthGuard } from '../app-guard';
import { PreventBackGuardService } from '../shared/guards/prevent-back-guard.service';
import { MenuExplorerComponent } from './components/menu-explorer/menu-explorer.component';

const MENU_EXPLORER: Route = {
  path: '',
  canActivate: [AuthGuard],
  children: [
    {
      path: '',
      component: MenuExplorerComponent,
      canActivate: [PreventBackGuardService]
    },
    {
      path: ':id',
      component: MenuExplorerComponent
    }
  ]
};
export const MENU_EXPLORER_STATES = [MENU_EXPLORER];

