import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RuntimeConstants } from 'src/app/shared/constants';
import { Observable } from 'rxjs';
import { Driver } from '../../dispatch/interfaces/driver';
import { DeliveryPayment } from '../../dispatch/interfaces/delivery-payment';
import { DriverBank } from '../../dispatch/interfaces/driver-bank';
import { DriverDeliveryDetail } from '../../dispatch/interfaces/driver-delivery-detail';

@Injectable()
export class DriverService {

  driverApiUrl = '';
  constructor(private httpService: HttpClient) {
    this.driverApiUrl = RuntimeConstants.API_PATH + 'driver';
  }

  getDrivers(): Observable<Array<Driver>> {
    return this.httpService.get<Driver[]>(`${this.driverApiUrl}`);
  }

  makeDriverDeliveryPaid(id: number, driver): Observable<any> {
    return this.httpService.put(`${this.driverApiUrl}/` + id, driver);
  }

  applyPaymentForDriverDelivery(paymentModel: Array<DeliveryPayment>): Observable<any> {
    return this.httpService.put(`${this.driverApiUrl}/apply-payment-for-driver-delivery`, paymentModel);
  }

  updateDriverAsDeliveryOut(driverOutModel): Observable<any> {
    return this.httpService.put(`${this.driverApiUrl}/update-driver-as-delivery-out`, driverOutModel);
  }

  getDriverBank(id: number): Observable<DriverBank> {
    return this.httpService.get<DriverBank>(`${this.driverApiUrl}/` + id);
  }

  getOpenDeliveryDetails(id: number): Observable<Array<DriverDeliveryDetail>> {
    return this.httpService.get<Array<DriverDeliveryDetail>>(`${this.driverApiUrl}/open-delivery-details/` + id);
  }

  getDriverBankAmount(id: number): Observable<Driver> {
    return this.httpService.get<Driver>(`${this.driverApiUrl}/driver-bank-amount/` + id);
  }

  updateDriverBankAmount(driverBank: DriverBank): Observable<any> {
    return this.httpService.post(`${this.driverApiUrl}`, driverBank);
  }

  getUnservedOrderForDelivery(): Observable<Array<DriverDeliveryDetail>> {
    return this.httpService.get<Array<DriverDeliveryDetail>>(`${this.driverApiUrl}/unserved-order-for-delivery`);
  }

  getSalesStatisticsReport(drawerId: number): Observable<any> {
    return this.httpService.get(`${this.driverApiUrl}/sales-statistics-report/` + drawerId);
  }

  getCashDrawerReport(drawerId: number): Observable<any> {
    return this.httpService.get(`${this.driverApiUrl}/cash-drawer-report/` + drawerId);
  }

  getSalesReport(drawerId: number): Observable<any> {
    return this.httpService.get(`${this.driverApiUrl}/sales-report/` + drawerId);
  }

  getDriverPaidAmount(): Observable<Array<Driver>> {
    return this.httpService.get<Array<Driver>>(`${this.driverApiUrl}/driver-paid-amount`);
  }

  saveDriverPayment(paymentModel): Observable<any> {
    return this.httpService.put(`${this.driverApiUrl}/save-driver-payment`, paymentModel);
  }

  printDriverReceipt(driverOrderReceiptPrint): Observable<any> {
    return this.httpService.put(`${this.driverApiUrl}/print-driver-receipt`, driverOrderReceiptPrint);
  }

  printTerminalReport(reportData): Observable<any> {
    return this.httpService.post(`${RuntimeConstants.API_PATH}printTerminalReports/printTerminalReport`, reportData);
  }
}
