import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { find, forEach } from 'lodash';
import { RabbitMQService } from 'src/app/shared';
import { search, sortasc, sortdesc } from 'src/app/shared/components/icon';
import { Service } from '../../interfaces';
import { ActivityReportPayload } from '../../interfaces/activity-report-payload';
declare let $: any;

@Component({
  selector: 'pos-activity-stream',
  templateUrl: './activity-stream.component.html',
  styleUrls: ['./activity-stream.component.scss']
})
export class ActivityStreamComponent implements OnInit, OnChanges {

  @Input() activities: Array<ActivityReportPayload> = [];
  @Input() services: Array<Service> = [];

  icons = {
    search, sortasc, sortdesc
  };
  searchActivity = '';
  sortBy = 'ActivityTime';
  reverseSort = true;
  activityTypeColorMaps: Array<{ Type: string, Color: string }> = [];
  height = $(window).height() - 350;

  constructor(private rabbitMqService: RabbitMQService) { }

  ngOnInit(): void {
    this.setColor();
  }

  ngOnChanges() {
    this.setColor();
    this.subscribeToActivityExchange();
  }

  subscribeToActivityExchange() {
    forEach(this.services, (service) => {
      this.rabbitMqService.subscribeToActivityExchange$(service.Name, '#')
        .subscribe({
          next: (message) => {
            const activity: ActivityReportPayload = message.Payload;
            activity.Color = this.getActivityTypeColorCode(activity.Type);
            activity.ServiceName = service.Name;
            this.activities.push(activity);
            this.activities = [...this.activities];
          }
        });
    });
  }
  sortActivities(field) {
    if (this.sortBy === field) {
      this.reverseSort = !this.reverseSort;
    } else {
      this.sortBy = field;
      this.reverseSort = false;
    }
  }

  setColor() {
    forEach(this.activities, (activity) => {
      if (!activity.Color) {
        activity.Color = this.getActivityTypeColorCode(activity.Type);
      }
    });
  }

  getActivityTypeColorCode(activityType) {
    const activityTypeColorMap = find(this.activityTypeColorMaps, (map) => {
      return map.Type === activityType;
    });

    if (activityTypeColorMap) {
      return activityTypeColorMap.Color;
    } else {
      const color = this.getRandomColor();

      this.activityTypeColorMaps.push({
        'Type': activityType,
        'Color': color
      });

      return color;
    }
  }

  getRandomColor() {
    let letters = '012345'.split('');
    let color = '#';
    color += letters[Math.round(Math.random() * 5)];
    letters = '0123456789ABCDEF'.split('');
    for (let letterCount = 0; letterCount < 5; letterCount++) {
      color += letters[Math.round(Math.random() * 15)];
    }

    // Don't select previously selected color
    const isTaken = find(this.activityTypeColorMaps, (map) => {
      return map.Color === color;
    });

    if (isTaken) {
      color = this.getRandomColor();
    }
    return color;
  }

}
