import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderMaintenanceComponent } from './components/order-maintenance/order-maintenance.component';
import { TableModule } from '@tarktech/tark-ng-utils';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ComponentsModule } from '../shared';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { RefundPaymentComponent } from './components/refund-payment/refund-payment.component';
import { RefundQuantityComponent } from './components/refund-quantity/refund-quantity.component';
import { RefundPaymentModalComponent } from './components/refund-payment-modal/refund-payment-modal.component';
import { ReadOnlyOrderSummaryModule } from './components/read-only-order-summary/read-only-order-summary.module';
import { SetAccountModule } from '../set-account/set-account.module';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { CreditCardTransactionsComponent } from './components/credit-card-transactions/credit-card-transactions.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { OrderRecordingsComponent } from './components/order-recordings/order-recordings.component';
import { OrderWiseRefundComponent } from './components/order-wise-refund/order-wise-refund.component';
import { SetOrderMethodComponent } from './components/set-order-method/set-order-method.component';

@NgModule({
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    ComponentsModule,
    TableModule,
    CalendarModule,
    FormsModule,
    NgSelectModule,
    ReadOnlyOrderSummaryModule,
    SetAccountModule,
    PopoverModule.forRoot(),
    TooltipModule.forRoot()
  ],
  declarations: [OrderMaintenanceComponent, RefundPaymentComponent, RefundQuantityComponent,
    RefundPaymentModalComponent,
    CreditCardTransactionsComponent,
    OrderRecordingsComponent,
    OrderWiseRefundComponent,
    SetOrderMethodComponent]
})
export class OrderMaintenanceModule { }
