<div class="col-12 p-0 mt-pix-10">
  <ng-table #gridComponent [search]="true" [sort]="true" [paginator]="false" [columns]="accountOrdersColumns"
    [data]="ordersData">
  </ng-table>
  <ng-template let-data="rowData" #amountTemplate>
      <span>
        {{data.GrandTotal | tark_currency}}
      </span>
    </ng-template>
</div>
