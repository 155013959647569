import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { SalesCategory } from '../../information-management/sales-categories/interface/sales-category';
import { Observable } from 'rxjs';
import { SalesKitchenPrinter } from 'src/app/information-management/sales-products/interface';
import { ProductWithSizes } from 'src/app/information-management';

@Injectable()
export class SalesCategoryService {

    salesCategoryApiUrl = '';
    constructor(private httpService: HttpClient) {
        this.salesCategoryApiUrl = RuntimeConstants.API_PATH + 'salescategory';
    }
    state = { isReadyState: 'If checked, this item will skip Ready state in maketable and transition to Served directly' };
    newSalesCategory() {
        return {
            id: 0,
            name: '',
            make_table_color: '',
            IsTimedPrep: false,
            IsUseWorkflowTimer: false,
            PrepGroupName: '',
            PrepTime: null,
            PrepButtonColor: '',
            IsSkipReadyState: false,
            DefaultSizeId: null,
            IsActive: true,
            CurrentPage: null,
            DateAdded: new Date(),
            DateEdited: null,
            DefaultSize: null,
            IsPrintToLabels: false,
            KitchenPrinterList: null,
            LabelPrinter: null,
            PageSize: null,
            Sizes: null,
            sales_group_id: null
        };
    }

    getSalesCategories(isActive: boolean): Observable<any[]> {
        return this.httpService.get<any[]>(`${this.salesCategoryApiUrl}/` + isActive);
    }
    getSalesCategory(salesCategoryId: number): Observable<any> {
        return this.httpService.get(`${this.salesCategoryApiUrl}/salesCategory/` + salesCategoryId);
    }
    getKitchenPrinter(salesCategoryId: number): Observable<any[]> {
        return this.httpService.get<any[]>(`${this.salesCategoryApiUrl}/` + salesCategoryId + '/kitchenPrinters');
    }
    deleteSalesCategory(salesCategoryId: number): Observable<any> {
        return this.httpService.delete(`${this.salesCategoryApiUrl}/` + salesCategoryId);
    }
    addSalesCategory(salesCategory: SalesCategory): Observable<any> {
        return this.httpService.post(this.salesCategoryApiUrl, salesCategory);
    }
    updateSalesCategory(salesCategory: SalesCategory): Observable<any> {
        return this.httpService.put(`${this.salesCategoryApiUrl}/` + salesCategory.id, salesCategory);
    }
    getSalesProductWithSizes(salesCategory: SalesCategory): Observable<Array<ProductWithSizes>> {
        return this.httpService.post<Array<ProductWithSizes>>(`${this.salesCategoryApiUrl}/getProductWithSizes`, salesCategory);
    }
    getKitchenPrintersInfo(salesGroupId: number): Observable<SalesKitchenPrinter> {
        return this.httpService.get<SalesKitchenPrinter>(`${this.salesCategoryApiUrl}/kitchen-printers-info/` + salesGroupId);
    }
}
