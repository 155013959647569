<pos-edit-wrapper [isPopup]="false" (close)="cancel()" heading="{{ id ? 'Edit' : 'Add' }} Inventory Vendor Profile" [headingIcon]="icons.sortNumericUp">
    <form #vendorProfileForm="ngForm" class="edit-form" (ngSubmit)="submit(vendorProfileForm.valid)">
        <div class="col-12 px-4 border-top pt-pix-15">
            <div class="col-12">
                <pos-row>
                    <pos-column>
                        <label >Vendor: {{vendorName}}</label>
                    </pos-column>
                    <pos-column>
                        <pos-form-field label="Name" [validationMessages]="{required: 'Please enter profile name.'}" >
                            <input posInput type="text" name="name" #profileName="ngModel" class="form-control col-250"
                                [(ngModel)]="inventoryVendorProfile.Name" [required]="true" />
                        </pos-form-field>
                    </pos-column>
                </pos-row>
                <div class="row">
                    <pos-inventory-vendor-profile-products [vendorName]="vendorName"
                        (profileProducts)="setProfileProducts($event)" [profileName]="inventoryVendorProfile.Name">
                    </pos-inventory-vendor-profile-products>
                </div>
            </div>
        </div>
        <div class="col-12 action-button-container page-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save</button>
            <button type="button" class="btn-secondary  btn-lg btn btn-cancel pos-button" (click)="cancel()">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
