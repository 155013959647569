<pos-list-wrapper [headingIcon]="icons.buttonsDiceOne" heading="Buttons" (close)="close()">
  <div class="row mx-0">
    <div class="col-12 px-0">
      <ng-table id="Button" [sort]="true" [columns]="buttonsColumns" [data]="buttons"
        [exportFilename]="'buttons'">
      </ng-table>
      <ng-template let-data="rowData" #toggleTemplate>
        <span *ngIf="(data.Toggle)" class="green text-center vertical-mid font-13">
          <pos-icon [icon]="icons.check"></pos-icon>
        </span>
      </ng-template>
      <ng-template let-data="rowData" #managerOnlyTemplate>
        <span *ngIf="(data.ManagerOnly)" class="green text-center vertical-mid font-13">
          <pos-icon [icon]="icons.check"></pos-icon>
        </span>
      </ng-template>
      <ng-template let-data="rowData" #inUseTemplate>
        <span *ngIf="(data.InUse)" class="green text-center vertical-mid font-13">
          <pos-icon [icon]="icons.check"></pos-icon>
        </span>
      </ng-template>
      <ng-template let-data="rowData" #colorTemplate>
        <div *ngIf="(data.Color)" class="hg-20 col-20 div-center"
          [ngStyle]="{'background-color': data && data.Color ? data.Color : ''}">
        </div>
      </ng-template>


      <ng-template let-data="rowData" #operationHeaderTemplate>
        <div class="text-center">
          <span (click)="editItem(0)" class="no-wrap" tooltip title="Add">
            <pos-action-icon [icon]="icons.plusWhite" iconText="Add"></pos-action-icon>
          </span>
          <pos-export-dropdown class="inline-block ms-pix-5" (onPrint)="printData()" (onCSV)="exportCSV()">
          </pos-export-dropdown>
        </div>

      </ng-template>

      <ng-template let-data="rowData" #operationTemplate>
        <div class="text-center">
          <span class="pe-pix-3" (click)="editItem(data.Id, data)" tooltip title="Edit">
            <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
          </span>
          <span class="pe-pix-3" (click)="confirmDelete(data)" tooltip title="Delete">
            <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
          </span>
        </div>
      </ng-template>

      <ng-template #buttonPopOverTemplate>
        <ul>
          <li class="listbullet">
            <a class="grey pointer" (click)="onButtonHistory()" title="History">
              <pos-icon [icon]="icons.history" class="pe-pix-3 m-l-5">
              </pos-icon>
              History
            </a>
          </li>
        </ul>
      </ng-template>


    </div>
  </div>
</pos-list-wrapper>
<pos-print-table #printTableReference [hidden]="true"></pos-print-table>
