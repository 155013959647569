import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { PermissionRole, TrainingTopic } from '../../information-management/training-topics/interface/training-topic';
import { TrainingAreaService } from './training-area.service';
@Injectable()
export class TrainingTopicService {

  constructor(private httpService: HttpClient, private trainingAreaService: TrainingAreaService) { }

  newTrainingTopic() {
    const trainingTopicObj: TrainingTopic = {
      Id: 0,
      TrainingAreaId: null,
      Name: '',
      Description: '',
      Ordinal: null,
      TopicsRoles: [],
      TrainingArea: this.trainingAreaService.newTrainingArea(),
      TrainingTopicRoles: [],
      TrainingTopicLevels: [],
      DateAdded: new Date(),
      DateEdited: null,
      TrainingLevels: {},
      TotalScore: 0
    };
    return trainingTopicObj;
  }

  getTrainingTopic(trainingTopicId: number): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}trainingtopic/` + trainingTopicId
    );
  }

  saveTrainingTopic(trainingTopic: TrainingTopic): Observable<any> {
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}trainingtopic`,
      trainingTopic
    );
  }

  updateTrainingTopic(trainingTopic: TrainingTopic): Observable<any> {
    return this.httpService.put(
      `${RuntimeConstants.API_PATH}trainingtopic`,
      trainingTopic
    );
  }

  deleteTrainingTopic(trainingTopicId: number): Observable<any> {
    return this.httpService.delete(
      `${RuntimeConstants.API_PATH}trainingtopic/` + trainingTopicId
    );
  }

  updateOrdinal(trainingTopic: TrainingTopic): Observable<any> {
    return this.httpService.put(
      `${RuntimeConstants.API_PATH}trainingtopic/updateordinal`,
      trainingTopic
    );
  }
  getRoles(includeSystemDefined: boolean): Observable<PermissionRole[]> {
    return this.httpService.get<PermissionRole[]>(
      `${RuntimeConstants.API_PATH}securityrole/` + includeSystemDefined
    );
  }

  getTrainingTopicsByAreaId(areaId, userId): Observable<any[]> {
    return this.httpService.get<any[]>(
      `${RuntimeConstants.API_PATH}trainingtopic/` + areaId + `/area/` + userId
    );
  }

  GetTrainingAreaWithTopics(): Observable<any[]> {
    return this.httpService.get<any[]>(
      `${RuntimeConstants.API_PATH}trainingtopic/area`
    );
  }

  moveTrainingTopic(trainingAreaId: number, trainingTopicId: number): Observable<any> {
    return this.httpService.put(
      `${RuntimeConstants.API_PATH}trainingtopic/movetopic/` + trainingAreaId + `/` + trainingTopicId, null
    );
  }
}
