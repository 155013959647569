import { Component, OnInit, ViewChild } from '@angular/core';
import { RabbitMQStompConfig } from 'src/app/shared/interface';
import { RabbitMqConfigurationService } from '../../services/rabbit-mq-configuration.service';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { NgForm } from '@angular/forms';
import { ModalFormComponent } from 'src/app/shared/components/modal-form';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalService } from 'src/app/shared/components/modal';
import { FormUtilityService } from 'src/app/shared/services/form-utility.service';

@Component({
  selector: 'pos-rabbit-mq-stomp-host-edit',
  templateUrl: './rabbit-mq-stomp-host-edit.component.html',
})
export class RabbitMqStompHostEditComponent extends ModalFormComponent implements OnInit {

  rabbitMQStompConfig: RabbitMQStompConfig;
  mode = '';
  useSSLConfigMessage: string = Messages.ConfirmIsUseSSLConfig;
  @ViewChild('formRMQStompConfig') formRMQStompConfig: NgForm;
  get getForm(): NgForm {
    return this.formRMQStompConfig
  }
  showSSLWarning = false;

  constructor(private rabbitMqService: RabbitMqConfigurationService,
    public modalRef: BsModalRef,
    public modalService: ModalService,
    formUtilityService: FormUtilityService) {
    super(modalRef,modalService,formUtilityService);
  }

  ngOnInit() {
    this.rabbitMQStompConfig = this.rabbitMQStompConfig ? this.rabbitMQStompConfig : this.rabbitMqService.newRabbitMqStompConfig();
    this.mode = this.rabbitMQStompConfig.ApplicationHost ? 'Edit' : 'Add';
    if (location.protocol.toLowerCase() === 'https:' && !this.rabbitMQStompConfig.IsUseSSL) {
      this.showSSLWarning = true;
    } else {
      this.showSSLWarning = false;
    }
  }

  saveRabbitMQStompConfig() {
    if (!this.formRMQStompConfig.valid) {
      return;
    }
    this.isSubmitted = true;
    this.hide({ data: this.rabbitMQStompConfig, mode: this.mode });
  }

  cancelEditingStompHost() {
    this.hide({ shouldReload: false });
  }

  changeUseSSLStompStatus() {
    if (location.protocol.toLowerCase() === 'https:' && !this.rabbitMQStompConfig.IsUseSSL) {
      this.showSSLWarning = true;
    } else {
      this.showSSLWarning = false;
    }
  }

}
