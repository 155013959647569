import { Messages } from 'src/app/shared';
import { GridColumn } from '@tarktech/tark-ng-utils';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'pos-inventory-log-stock-sales-product-warning',
  templateUrl: './inventory-log-stock-sales-product-warning.component.html'
})
export class InventoryLogStockSalesProductWarningComponent implements OnInit {

  @Input('salesInventoryProducts') salesInventoryProducts: any = [];
  productColumns: Array<GridColumn> = [];
  constructor() { }

  confirmLogMessage: string;

  ngOnInit() {
    this.configureColumns();  
    this.confirmLogMessage = Messages.ConfirmLogInventory;
  }

  configureColumns() {
    this.productColumns = [
      new GridColumn({ HeaderText: 'Inventory Products', Field: 'InventoryProductName', IsSortable: true, Width: '40%' }),
      new GridColumn({ HeaderText: 'Sales Products', Field: 'SalesProductName', IsSortable: true, Width: '40%' })
    ] as Array<GridColumn>;
  }
}
