import { Injectable } from '@angular/core';
import { DashboardConfigurationService, AlertsService, RabbitMQService, RabbitMQExchangeFactoryService } from 'src/app/shared/services';
import { filter, find } from 'lodash';
import { DashboardWidgetsService } from './dashboard-widgets.service';
declare let $: any;
@Injectable({
  providedIn: 'root'
})
export class BaseWidgetService {

  dashboardStateParams: {};
  isInteractive: boolean;
  constructor(private dashboardConfigurationService: DashboardConfigurationService,
    private dashboardWidgetService: DashboardWidgetsService,
    private alertService: AlertsService,
    private rabbitMqService: RabbitMQService,
    private rabbitMQExchangeFactoryService: RabbitMQExchangeFactoryService) { }

  loadBaseWidget = (widget, config, completedFunction) => {
    this.dashboardStateParams = this.dashboardConfigurationService.getDashboardStatesParams();
    this.isInteractive = this.dashboardConfigurationService ? this.dashboardConfigurationService['mode'] === 'interactive' : false;

    if (!widget.widgetData || widget.isReloadWidget) {
      widget.isReloadWidget = false;
      this.initializeWidgetDetails(widget, config, completedFunction, !widget.isSilentReload);
    } else {
      completedFunction(widget.widgetData);
    }

  }

  initializeWidgetDetails = (widget, config, completedFunction, isLoading) => {
    if (config && config.params && config.params.length > 0) {
      this.initializeWidget(widget, config, completedFunction, isLoading);
    } else {
      this.populateDataSourceParams(widget, config, completedFunction, isLoading);
    }
  }

  initializeWidget = (widget, config, completedFunction, isLoading) => {
    if (widget.config.sourceType === 'RabbitMQ') {
      this.initializeWidgetRabbitMq(widget, config, completedFunction, isLoading);
    } else {
      isLoading = !widget.isSilentReload;
      this.initializeWidgetRest(widget, config, completedFunction, isLoading);
    }
  }

  initializeWidgetRabbitMq = (widget, config, completedFunction, isLoading) => {
    const paramExchangeName = find(config.params, { IsUserDefined: false, ParameterName: 'ExchangeName' });
    const paramExchangeType = find(config.params, { IsUserDefined: true, ParameterName: 'ExchangeType' });
    const paramRoutingKey = find(config.params, { IsUserDefined: true, ParameterName: 'RoutingKey' });
    if (paramExchangeName && paramExchangeType && paramRoutingKey) {
      widget.widgetData = 'Awaiting for update';
      const exchangeInfo = this.rabbitMQExchangeFactoryService.exchangeInfo(paramExchangeName.ParameterValue,
        paramExchangeType.ParameterValue,
        paramRoutingKey.ParameterValue);
      this.rabbitMqService.subscribe<any>(exchangeInfo)
        .subscribe((message) => {
          if (message.Type === paramExchangeName.ParameterValue) {
            widget.widgetData = message.state;
            completedFunction(message.state);
          }
        });
    }
    isLoading = false;
  }

  resizeText = (id) => {
    const minFontSize = 10;
    const container = $('#' + id);
    const fontSize = container.css('font-size');
    const containerInnerWidth = Math.ceil(container.innerWidth() ?? 0);
    // decrease font size
    if (container && container[0] && container[0].scrollWidth && (container[0].scrollWidth >
      containerInnerWidth) && parseFloat(fontSize) > minFontSize) {
      container.css('fontSize', parseFloat(fontSize) - 1);
      this.resizeText(id);
    }
    // trim text after min font size
    else if (container[0] && container[0].scrollWidth && container[0].scrollWidth > containerInnerWidth &&
      parseFloat(fontSize) <= minFontSize) {
      let temp = container.text();

      while (container[0].scrollWidth < containerInnerWidth) {
        temp = temp.substr(0, temp.length - 1)
        container.text(temp);
      }
      container.text(temp.substr(0, temp.length - 3));
      container.append('...');
    }
  }

  initializeWidgetRest = (widget, config, completedFunction, isLoading) => {
    const paramUrl = filter(config.params, { IsUserDefined: false, ParameterName: 'Url' });
    const paramMethod = filter(config.params, { IsUserDefined: false, ParameterName: 'Method' });
    if (paramUrl && paramUrl.length > 0 && paramMethod && paramMethod.length > 0) {
      const params = filter(config.params, (item) => {
        return item.IsUserDefined || item.ParameterName === 'sproc' || !item.ParameterValue || item.ParameterValue === '-1';
      });

      // get parameter value by stateParam
      filter(params, (param) => {
        if (this.dashboardStateParams[param.ParameterName] !== undefined) {
          param.ParameterValue = this.dashboardConfigurationService.parseByDataType
            (this.dashboardStateParams[param.ParameterName], param.DataType);
        }
      });
      const filteredParams = filter(params, (param) => {
        return !param.ParameterValue ||
          param.ParameterValue === '-1';
      })
      if (this.dashboardStateParams['mode'] === 'interactive' && filteredParams.length > 0) {
        completedFunction([]);
      } else {
        this.getWidget(paramUrl, paramMethod, params, completedFunction);
      }
    } else {
      isLoading = false;
    }
  }

  getWidget = (paramUrl, paramMethod, params, completedFunction) => {
    this.dashboardWidgetService.getWidget(paramUrl[0].ParameterValue, paramMethod[0].ParameterValue, params)
      .subscribe({
        next: (res) => {
          completedFunction(res);
        }, error: this.alertService.showApiError
      });
  }

  populateDataSourceParams(widget, config, completedFunction, isLoading) {
    if (widget.DatasourceId) {
      isLoading = isLoading;
      widget.widgetAssignmentId = widget.widgetAssignmentId ? widget.widgetAssignmentId : 0;
      this.dashboardWidgetService.getDashboardWidgetDatasourceParameters(widget.widgetAssignmentId, widget.DatasourceId)
        .subscribe({
          next: (res) => {
            config.params = res;
            this.initializeWidget(widget, config, completedFunction, isLoading);
          }, error: this.alertService.showApiError
        });
    }
  }

  setWidgetHeights = (adfModel) => {
    for (let i = 0; i < adfModel.rows.length; i++) {
      let row = adfModel.rows[i];
      let column = { columnIndex: undefined, height: 0 };

      for (let j = 0; j < row.columns.length; j++) {
        let col = row.columns[j];

        //reset widget height as undefined 
        for (let w = 0; col.widgets && w < col.widgets.length; w++) {
          col.widgets[w].height = undefined;
        }

        //find the highest column height in a row
        if ($("#c_" + col.rowIndex + '_' + col.columnIndex).height() > column.height) {
          column = {
            columnIndex: col.columnIndex,
            height: $("#c_" + col.rowIndex + '_' + col.columnIndex).height()
          };
        }
      }

      for (let j = 0; j < row.columns.length; j++) {
        let col = row.columns[j];
        let sumWidgetsHeight = 0;

        if (col.widgets) {
          //find sum of widgets height in column
          for (let w = 0; w < col.widgets.length; w++) {
            sumWidgetsHeight += $("#w_" + col.widgets[w].widgetAssignmentId).height();
          }
          let wHeight = (column.height - sumWidgetsHeight - col.widgets.length * 10) / col.widgets.length;

          //set new height to the widgets based on the extra space in a cloumn
          for (let w = 0; w < col.widgets.length; w++) {
            col.widgets[w].height = ($("#w_" + col.widgets[w].widgetAssignmentId).height() + wHeight) + 'px';
          }
        }
      }
    }
  }

}
