import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { Observable } from 'rxjs';
import { InventorySubcategory } from '../interface/inventory-subcategories';

@Injectable({
    providedIn: 'root'
})
export class InventorySubCategoryService {

    inventorySubCategoryApiUrl = '';
    constructor(private httpService: HttpClient) {
        this.inventorySubCategoryApiUrl = RuntimeConstants.API_PATH + 'inventorysubcategories';
    }
    newInventorySubCategory() {
        return {
            id: 0,
            name: '',
            categoryname: '',
            categoryid: null,
            days_between_inventory: null,
            InventoryCategoryName: '',
            LabelPrinterId: null
        };
    }

    getInventorySubcategoryList(): Observable<any[]> {
        return this.httpService.get<any[]>(`${this.inventorySubCategoryApiUrl}`);
    }
    getInventorySubCategoryDetails(inventorySubCategoryId: number): Observable<any> {
        return this.httpService.get(`${this.inventorySubCategoryApiUrl}/` + inventorySubCategoryId);
    }
    saveInventorySubcategory(inventorySubCategory: InventorySubcategory): Observable<any> {
        return this.httpService.post(this.inventorySubCategoryApiUrl, inventorySubCategory);
    }
    deleteInventorySubcategory(inventorySubCategoryId: number): Observable<any> {
        return this.httpService.delete(`${this.inventorySubCategoryApiUrl}/` + inventorySubCategoryId);
    }
}
