<pos-edit-wrapper heading="{{selectedDeliveryType.description}}" (close)="onClose(false)">
    <form #delayActivityEditForm="ngForm" autocomplete="off" (submit)='saveActivity(delayActivityEditForm.valid)'>
        <div class="col-12 pos-modal-body screen-scroll">
            <pos-row>
                <pos-column>
                    <pos-form-field label="Delay" inputStyle="col-12 row mx-0"
                        [validationMessages]="{required: 'Please enter delay.', min: 'Please enter delay greater than zero.'}">
                        <input posInput class="form-control col-120 col-3" id="delayTime" name="delayTime"
                            [(ngModel)]="delaySeconds" [required]="true" type="number" #delay="ngModel" min="0" />
                        <label class="edit-form__section__label ps-pix-10 col-70">seconds</label>
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save</button>
            <button type="button" (click)="onClose(false)" class="btn-secondary btn-lg btn btn-cancel-modal pos-button"
                value="Cancel">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
