import { Component, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormUtilityService } from 'src/app/shared';
import { ModalFormComponent, ModalService } from 'src/app/shared/components';
import { GooglePayConfiguration } from '../../interface/google-pay-configuration';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'pos-google-pay-configuration',
  templateUrl: './google-pay-configuration.component.html',
})
export class GooglePayConfigurationComponent extends ModalFormComponent {
  
  @Input() configuration: GooglePayConfiguration;
  @ViewChild('googlePayConfigurationForm') googlePayConfigurationForm: NgForm;

  items: Array<MenuItem> = [{ label: 'Sign Up' }, { label: 'Merchant Details' }, { label: 'Gateway Details' } ];
  activeIndex = 0;
  lastIndex = this.items.length - 1;

  get getForm(): NgForm {
    return this.googlePayConfigurationForm;
  }

  constructor(bsModalRef: BsModalRef, modalService: ModalService, formUtilityService: FormUtilityService) {
    super(bsModalRef, modalService, formUtilityService);
  }

  onClose(res: any = null) {
    this.hide(res);
  }

  next() {
    if (this.activeIndex < this.lastIndex) {
      this.configuration.IsValid = true;
      this.activeIndex++;
    } else {
      this.configuration.IsValid = true;
      this.onClose(this.configuration);
    }
  }

}
