<div class="m-0-auto width-70">
    <pos-edit-wrapper heading="{{ id ? 'Edit' : 'Add' }} Menu Display{{ id ? ': '+menuName : '' }}" (close)="cancel()"
        [headingIcon]="icons.displayMenu" [isPopup]="false">
        <form #digitalMenuForm="ngForm" class="edit-form" (ngSubmit)="submit(digitalMenuForm.valid)">
            <div class="col-12 pos-page-body border-top pt-pix-5">
                <pos-row>
                    <pos-column>
                        <pos-form-field label="Name" [validationMessages]="{required: 'Please enter name.'}">
                            <input posInput type="text" name="name" #name="ngModel" class="form-control"
                                [(ngModel)]="digitalMenu.Name" [required]="true" />
                        </pos-form-field>
                    </pos-column>
                    <pos-column>
                        <pos-form-field label="Resolution">
                            <div class="row mx-0">
                                <div class="col-5 col-100 p-0"
                                    [ngClass]="{'has-error': width.invalid && digitalMenuForm.submitted}">
                                    <input type="text" name="width" #width="ngModel" class="form-control"
                                        [(ngModel)]="digitalMenu.Width" required />
                                </div>
                                <div class="col-1 text-center col-25 p-0 h-36">
                                    <label class="pt-pix-6 font-20">×</label>
                                </div>
                                <div class="col-5 col-100 p-0"
                                    [ngClass]="{'has-error': height.invalid && digitalMenuForm.submitted}">
                                    <input type="text" name="height" #height="ngModel" class="form-control"
                                        [(ngModel)]="digitalMenu.Height" required />
                                </div>
                                <div class="label-section col-1 pe-0">
                                    px
                                </div>
                            </div>
                        </pos-form-field>
                        <div *ngIf="(width.invalid || height.invalid) && digitalMenuForm.submitted">
                            <label class="ps-pix-5 red-extreme"
                                *ngIf="height.errors?.required || width.errors?.required">Please
                                enter resolution.</label>
                        </div>
                    </pos-column>
                    <pos-column>
                        <pos-form-field label="Orientation">
                            <div class="btn-group">
                                <label class="btn btn-radio col-120"
                                    [ngClass]="!digitalMenu.Orientation ? 'active btn-primary normal' : 'btn-secondary'">
                                    <input [(ngModel)]="digitalMenu.Orientation" [value]="false" type="radio"
                                        name="landscape">
                                    <span class="fad fa-rectangle-landscape"></span>
                                    Landscape
                                </label>
                                <label class="btn btn-radio col-110"
                                    [ngClass]="digitalMenu.Orientation ? 'active btn-primary normal' : 'btn-secondary'">
                                    <input [(ngModel)]="digitalMenu.Orientation" [value]="true" type="radio"
                                        name="portrait">
                                    <span class="fad fa-rectangle-portrait"></span>
                                    Portrait
                                </label>
                            </div>
                        </pos-form-field>
                    </pos-column>
                    <pos-column>
                        <pos-form-field label="Background Color">
                            <pos-color-picker posInput name="backgroundColor"
                                [(ngModel)]="menuConfig.BackgroundColor"></pos-color-picker>
                        </pos-form-field>
                    </pos-column>
                </pos-row>
            </div>
            <div class="col-12 action-button-container page-footer">
                <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save</button>
                <button type="button" class="btn-secondary btn-lg btn btn-cancel pos-button"
                    (click)="cancel()">Cancel</button>
            </div>
        </form>
    </pos-edit-wrapper>
</div>
