<pos-list-wrapper [headingIcon]="icons.hardwareInterfacePlug" heading="HIC List" (close)="close()">
  <div class="row">
    <div class="col-12">
      <ng-table #hardwareInterface [columns]="hardwareInterfaceColumns" [data]="hardwareInterfaceList"
        [search]="false" [paginator]="false">
      </ng-table>
      <ng-template let-data="rowData" #operationHeaderTemplate>
        <div class="text-center">
          <span (click)="editItem(null)" tooltip title="Add">
            <pos-action-icon [icon]="icons.plusWhite" class="inline-block" iconText="Add"></pos-action-icon>
          </span>
          <pos-export-dropdown class="inline-block ps-pix-5" (onPrint)="printData()"
            (onCSV)="exportCSV()"></pos-export-dropdown>
        </div>
      </ng-template>
      <ng-template let-data="rowData" #operationTemplate>
        <div class="text-center">
          <span class="pe-pix-3" (click)="editItem(data.Id, data)" tooltip title="Edit" scrollup>
            <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
          </span>
          <span (click)="deleteHardwareInterface(data)" tooltip title="Delete">
            <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
          </span>
          <span class="ps-pix-3" (click)="viewHICDashboard(data)" tooltip title="Open Dashboard">
            <pos-action-icon [icon]="icons.externalLink"></pos-action-icon>
          </span>
        </div>
      </ng-template>
    </div>
  </div>
</pos-list-wrapper>
<pos-print-table [hidden]="true"></pos-print-table>
