import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BaseCrudComponent, SpinnerService } from 'src/app/shared/components';
import { AlertsService, ApplicationStateService, FormUtilityService } from 'src/app/shared/services';
import { PosHardwareType } from 'src/app/configurator/device-mapping';
import { HardwaresService } from '../../service/hardware.service';
import { finalize } from 'rxjs/operators';
import { hardwareLabelPrint, hardwareToolbox } from 'src/app/shared/components/icon';
import { FieldInfoMessages, Messages, DomainConstants } from 'src/app/shared/constants';
import { forkJoin, Observable } from 'rxjs';
import * as _ from 'lodash';
import { PrintingService } from 'src/app/information-management/inventory-subcategories';
import { HardwareModel, SettingParam } from 'src/app/shared/interface';
import { HardwareInterface, LightsInfo } from 'src/app/configurator';
import { HardwareType } from '../../interface/hardware-type';
import { find } from 'lodash';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
declare let $: any;

@Component({
  selector: 'pos-hardware-edit',
  templateUrl: './hardware-edit.component.html',
  styleUrls: ['./hardware-edit.component.scss']
})
export class HardwareEditComponent extends BaseCrudComponent<HardwareModel> implements OnInit {
  @ViewChild('hardwareForm') hardwareForm: NgForm;
  get getForm(): NgForm {
    return this.hardwareForm
  }
  @Input('id') id: number;
  hardware: HardwareModel;
  posHardwareTypes: Array<PosHardwareType> = [];
  hardwareTypes: Array<HardwareType> = [];
  filteredHardwareTypes: Array<HardwareType> = [];
  hardwareInterfaceComputers: Array<HardwareInterface> = [];
  hardwareProperties = [];
  splitByConfig = 'SplitByConfig';
  splitConfigValue = '';
  lightHardwareProperties = DomainConstants.LightHardwareProperty;
  fieldInfoMessages = FieldInfoMessages;
  settingParam: SettingParam;
  icons = {
    hardwareLabelPrint,
    hardwareToolbox
  };
  showLoader = false;
  height = $('#edit-block').height();
  props = {
    labelClass: '',
    controlBoxClass: 'col-md-4 col-xs-7'
  }
  constructor(protected hardwareService: HardwaresService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    private printingService: PrintingService,
    private applicationStateService: ApplicationStateService,
    private route: ActivatedRoute,
    private router: Router,

    formUtilityService: FormUtilityService) {
    super(hardwareService, alertService, spinnerService, formUtilityService);
    this.id = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
    this.hardware = hardwareService.newHardware();
  }

  ngOnInit() {
    this.settingParam = this.applicationStateService.settingParam;
    this.splitConfigValue = DomainConstants.KitchenPrinterSplitByConfig.PrintWholeOrder;
    this.saveSuccessMessage = Messages.HardwareSaveSuccess;
  }

  setHeight() {
    this.height = $('#edit-block').height();
  }

  loadDependencies(): void {
    this.spinnerService.show();
    const hardwareObservables: Array<Observable<any>> = [];
    hardwareObservables.push(this.hardwareService.getAvailablePosHardwareTypes());
    hardwareObservables.push(this.hardwareService.getAvailableHardwareTypes());
    hardwareObservables.push(this.hardwareService.getHardwareInterfaceComputers());
    if (this.id > 0) {
      hardwareObservables.push(this.hardwareService.get(this.id));
    }

    forkJoin(hardwareObservables)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: ([posHardwareTypes, hardwareTypes, hardwareInterfaceComputers, hardware]:
          [Array<PosHardwareType>, Array<HardwareType>, Array<HardwareInterface>, HardwareModel]) => {
          this.setHardwareType(posHardwareTypes);
          this.hardwareTypes = hardwareTypes ? hardwareTypes : [];
          this.hardwareInterfaceComputers = hardwareInterfaceComputers ? hardwareInterfaceComputers : [];
          if (hardware) {
            this.hardware = hardware;
            this.hardwareCategoryChanged(this.hardware);
            this.setHardwareProperties(this.hardware);
          }
        }
      });
  }

  setHardwareType(posHardwareTypes: Array<PosHardwareType>) {
    const posFilteredHardwareTypes = posHardwareTypes.filter((data) => {
      const feature = this.applicationStateService.applicationFeatures.find(x => x.Name == data.Description);
      return feature ? feature.IsEnabled : data;
    });
    this.posHardwareTypes = posFilteredHardwareTypes ? posFilteredHardwareTypes : [];
    this.posHardwareTypes.sort((a, b) => a.HardwareType.localeCompare(b.HardwareType));
  }

  hardwareCategoryChanged(hardware: HardwareModel) {
    if (this.hardware.TypeIdentifier == null) {
      this.hardwareProperties = [];
    }
    const selectedHardware = _.find(this.posHardwareTypes, (hardwareType) => {
      return hardware.Type === hardwareType.Type;
    });
    this.filteredHardwareTypes = [];
    if (selectedHardware) {
      this.filteredHardwareTypes = this.hardwareTypes.filter((hardwareType) => {
        return hardwareType.Type === selectedHardware.HardwareType;
      });
    }
    if (this.filteredHardwareTypes.length === 1) {
      this.hardware.TypeIdentifier = this.filteredHardwareTypes[0].Identifier;
      this.setHardwareProperties(this.hardware);
    }
  }

  setHardwareProperties(hardware: HardwareModel) {
    if (this.hardware.TypeIdentifier == null) {
      this.hardwareProperties = [];
    }
    this.spinnerService.show();
    if (this.hardware.TypeIdentifier != null) {
      const hardwareProp = this.hardwareTypes.filter((hardwareType) => {
        return hardwareType.Identifier === hardware.TypeIdentifier;
      });
      if (hardwareProp.length > 0) {
        this.hardwareProperties = hardwareProp[0].Properties;
        _.forEach(this.hardwareProperties, (property) => {
          property.AvailableValueType = property.AvailableValueType === 'Any' ? 'Text' : property.AvailableValueType;
          const propertyValues = this.hardware.Properties.filter((propertyMetadata) => {
            if (propertyMetadata.Name === this.splitByConfig) {
              this.splitConfigValue = propertyMetadata.Value;
            }
            return propertyMetadata.Name === property.Name;
          });
          if (propertyValues && propertyValues.length > 0) {
            property.ValueObject = propertyValues[0];
          } else {
            property.ValueObject = {};
          }
        });
      }
    }
    this.spinnerService.hide();
    this.setHeight();
  }

  saveHardware(isValid: boolean) {
    if (!isValid) {
      return;
    }
    let isPropertyMissing = false;
    _.forEach(this.hardwareProperties, (property) => {
      if (!property.ValueObject.Value) {
        isPropertyMissing = true;
      }
      const propertyModel = this.hardware.Properties.filter((prop) => {
        return prop.Name === property.Name;
      });

      if (propertyModel && propertyModel.length > 0) {
        propertyModel[0].Value = property.ValueObject.Value;
      } else {
        this.hardware.Properties.push(this.hardwareService.newProperty(property.Name, property.ValueObject.Value));
      }
      if (this.hardware.Type === 'KitchenPrinter' && !(this.hardware.Properties.some(prop => prop.Name === this.splitByConfig))) {
        this.hardware.Properties.push(this.hardwareService.newProperty(this.splitByConfig,
          DomainConstants.KitchenPrinterSplitByConfig.PrintWholeOrder));
      }
    });

    if (this.hardware.TypeIdentifier === '00950C7F-CF93-4645-B86A-792A60317D49') {
      if (!(find(this.hardwareProperties, x => x.Name === DomainConstants.LightHardwareProperty.AuthToken)?.ValueObject.Value)) {
        this.alertService.renderErrorMessage(Messages.ConfigureAuthForLights);
        return;
      } else if (!(find(this.hardwareProperties,
        x => x.Name === DomainConstants.LightHardwareProperty.LightsInfo)?.ValueObject.Value)) {
        this.alertService.renderErrorMessage(Messages.ReadLights);
        return;
      }
    }
    if (this.hardware.TypeIdentifier === '00950D7F-CF93-4645-B86A-792A60307D39') {
      this.prepareLightsInfo();
    }
    if (isPropertyMissing) {
      return;
    }
    this.save(this.hardware);
  }

  prepareLightsInfo() {
    const property = find(this.hardwareProperties, x => x.Name === DomainConstants.LightHardwareProperty.LightsInfo);
    const lightCount = find(this.hardwareProperties, x => x.Name === DomainConstants.LightHardwareProperty.LightCount)?.ValueObject?.Value;
    if (lightCount > 0) {
      let lightsInfo: Array<LightsInfo> = [];
      for (let i = 1; i <= lightCount; i++) {
        lightsInfo.push({ PanelId: i, Name: '' });
      }
      property.ValueObject.Value = JSON.stringify(lightsInfo);
    }

  }

  setLightDetails(event) {
    if (event.authToken) {
      const property = find(this.hardwareProperties, x => x.Name === DomainConstants.LightHardwareProperty.AuthToken);
      property.ValueObject.Value = event.authToken;
    } else if (event.lightsInfo) {
      const property = find(this.hardwareProperties, x => x.Name === DomainConstants.LightHardwareProperty.LightsInfo);
      property.ValueObject.Value = event.lightsInfo;
    }
  }

  splitConfig(event) {
    const propExist = this.hardware.Properties.some(prop => prop.Name === 'SplitByConfig');
    if (!propExist) {
      this.hardware.Properties.push(this.hardwareService.newProperty(this.splitByConfig, event.splitConfig));
    } else {
      this.hardware.Properties = _.forEach(this.hardware.Properties, function (property) {
        if (property.Name === 'SplitByConfig') {
          property.Value = event.splitConfig;
        }
      });
    }
  }

  testLabelPrint() {
    this.spinnerService.show();
    this.printingService.testLabelPrint(this.hardware.Id, this.settingParam.TerminalId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.alertService.renderSuccessMessage(Messages.LabelPrintRequestSendSuccess);
        }, error: (error) => this.alertService.showApiError(error)
      });
  }

  onSaveSuccess(model: HardwareModel): void {
    this.cancelHardware();
  }

  cancelHardware() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

}
