import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { InventoryTransfer } from 'src/app/manage-console/inventory/inventory-transfer/interfaces/inventory-transfer';
import { DomainConstants } from 'src/app/shared/constants';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';
import { InventoryOffSiteLocation } from '../interfaces/inventory-offsite-location';
import { ApplicationStateService } from 'src/app';

@Injectable({
  providedIn: 'root'
})
export class InventoryOffSiteLocationService extends BaseCrudService<InventoryOffSiteLocation> {

  constructor(private applicationStateService: ApplicationStateService,protected httpService: HttpClient) {
    super('inventory-off-site-location', httpService);
  }

  newOffSiteLocation() {
    return {
      Id: 0,
      Name: null,
      IsActive: true
    } as InventoryOffSiteLocation;
  }

  changeActiveStatus(inventoryOffsiteLocation: InventoryOffSiteLocation) {
    return this.httpService.put(`${this.apiEndpoint}/status/${inventoryOffsiteLocation.Id}`, inventoryOffsiteLocation);
  }

  transferInventory(transferInventory: InventoryTransfer) {
    transferInventory.FromLocation = transferInventory.FromLocation > 0 ? transferInventory.FromLocation : null;
    transferInventory.ToLocation = transferInventory.ToLocation > 0 ? transferInventory.ToLocation : null;
    transferInventory.Qty = !transferInventory.OverriddenQty ? (transferInventory.Qty * transferInventory.UnitQty) : (transferInventory.Qty * transferInventory.OverriddenQty);
    return this.httpService.put(`${this.apiEndpoint}/transfer`, transferInventory);
  }

  getActiveLocations() {
    const defaultLocation = this.applicationStateService.settingParam.DefaultLocationTerm ? this.applicationStateService.settingParam.DefaultLocationTerm :  DomainConstants.OnSiteInventoryText;
    return this.httpService.get<Array<InventoryOffSiteLocation>>(this.apiEndpoint).pipe(map(res => [{ Id: 0, Name: defaultLocation, IsActive: true }, ...(res.filter(x => x.IsActive))]));
  }
}
