export class GridColumnTypes {
    public static GridColumn = 'GridColumn';
    public static LinkColumn = 'LinkColumn';

    public static PrimaryColumn = 'PrimaryColumn';

    public static EditColumn = 'EditColumn';

    public static DeleteColumn = 'DeleteColumn';

    public static TemplateColumn = 'TemplateColumn';
    public static LinkTemplateColumn = 'LinkTemplateColumn';

    public static BooleanColumn = 'BooleanColumn';

    public static DateColumn = 'DateColumn';
    public static DecimalColumn = 'DecimalColumn';
    public static CurrencyColumn = 'CurrencyColumn';
}
