<pos-list-wrapper [headingIcon]="icons.buttonsDiceOne" heading="Comment Warnings" (close)="close()">
  <div class="row">
    <div class="col-12">
        <ng-table #gridComponent [search]="true" [sort]="true" [exportFilename]="'comment-warnings'" [paginator]="true" [columns]="commentWarningsColumns" [data]="commentWarnings">
        </ng-table>
    </div>
  </div>
</pos-list-wrapper>
<pos-confirm-delete [message]="message" (onConfirmAcknowledge)="onConfirmAcknowledge()" #confirmButtonDelete>
</pos-confirm-delete>
<pos-print-table #printTableReference [hidden]="true"></pos-print-table>
