import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalComponent } from '../modal/modal-component';
declare let $: any;
@Component({
  selector: 'pos-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss']
})
export class InfoModalComponent extends ModalComponent implements OnInit {

  public message: string = '';
  public confirmButtonText: string = 'OK';
  public rejectButtonText: string = '';
  public modalHeaderText: string = 'Info';
  constructor(deleteModalRef: BsModalRef) { super(deleteModalRef); }

  ngOnInit() {
    setTimeout(()=>{
      $('#confirmButton')?.focus();
    });
  }

  public onConfirm(): void {
    this.hide({ shouldConfirm: true });
  }

  public onRejectConfirm(event): void {
    this.hide({ shouldConfirm: false, event: event });
  }

}
