<pos-edit-wrapper (close)="cancelEditing()"
  heading="{{ infolinksectionId ? 'Edit Custom Link Section' : 'Add Custom Link Section' }}">
  <form #formInfoLinkSectionEdit="ngForm" (ngSubmit)='saveInfoLinkSections(formInfoLinkSectionEdit.valid)'>
    <div class="col-12 pos-modal-body overflow-hidden">
      <pos-row>
        <pos-column>
          <pos-form-field label="Name" [validationMessages]="{required: 'Please enter name.'}"
            inputStyle="col-sm-9 col-xs-12">
           <input posInput class="form-control" name="InfoLinkName" #InfoLinkName="ngModel"
                [(ngModel)]="infoLinkSectionDetail.Name" [required]="true" type="text" />
          </pos-form-field>
        </pos-column>
      </pos-row>

    </div>
    <div class="col-12 action-button-container popup-footer">
      <button class="btn-lg btn-primary btn btn-save pos-button" type="submit" value="Save">Save</button>
      <button class="btn-secondary  btn-lg btn btn-cancel-modal pos-button" data-dismiss="modal" (click)="cancelEditing()" type="button"
        value="Cancel">Cancel</button>
    </div>
  </form>
</pos-edit-wrapper>
