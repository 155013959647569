<pos-edit-wrapper heading="Inventory" (close)="cancel()" [headingIcon]="icons.rawIngredientInventoryProductStore"
  [isPopup]="false">
  <form (ngSubmit)="saveInventoryWorkflow(inventoryWorkflow.valid)" #inventoryWorkflow="ngForm" class="edit-form">
    <div class="col-12 border-top pt-pix-5">
      <div class="col-12 pos-page-body">
        <pos-row>
          <pos-column>
            <pos-form-field label="Inventory log of same quantity">
              <ng-select posInput class="col-300" [items]="newInventoryRecordOnDuplicateAmount" appendTo="body"
                bindLabel="Name" bindValue="Value"
                [(ngModel)]="inventoryWorkflowParameter.NewInventoryRecordOnDuplicateAmount" name="inventoryRecord"
                [clearable]="false" [clearOnBackspace]="false">
              </ng-select>
            </pos-form-field>
          </pos-column>
          <pos-column>
            <pos-form-field label="Populate Quantities with">
              <ng-select posInput class="col-300" [items]="inventoryLogPopulateQuantityUsing" appendTo="body"
                bindLabel="Name" bindValue="Name" name="populateQuantity"
                [(ngModel)]="inventoryWorkflowParameter.InventoryLogPopulateQuantityUsing" [clearable]="false"
                [clearOnBackspace]="false">
              </ng-select>
            </pos-form-field>
          </pos-column>
          <pos-column>
            <pos-form-field inputStyle="row" [validationMessages]="{required: 'Please enter product quantity.'}">
              <pos-column xs="3" class="fit-content">
                <label class="edit-form__section__label">Warn Inventory Log that affects more than</label>
              </pos-column>
              <pos-column xs="3" class="fit-content">
                <input posInput type="number" min="0" posOnlyNumbers [required]="true" class="form-control col-120"
                  [(ngModel)]="inventoryWorkflowParameter.InventoryLogStockWarningMinimum" name="chkStockWarning"
                  #inventoryLogStockWarningMinimum="ngModel" />
              </pos-column>
              <pos-column xs="3" class="no-wrap">
                <label class="edit-form__section__label">products</label>
              </pos-column>
            </pos-form-field>
          </pos-column>
          <pos-column>
            <pos-form-field inputStyle="row"
              [validationMessages]="{required: 'Please enter percentage.', range: 'Invalid percentage.'}">
              <pos-column xs="3" class="fit-content mb-1">
                <p-checkbox name="chkInventoryLogUnchangedQtyWarning" #chkInventoryLogUnchangedQtyWarning="ngModel"
                  (onChange)="onInventoryLogUnchangedQtyWarningChanged()" label="Warn if user logs more than"
                  [(ngModel)]="isCheckedInventoryLogUnchangedQtyWarning" binary="true"
                  class="edit-form__section__label">
                </p-checkbox>
              </pos-column>
              <pos-column xs="3" class="fit-content">
                <p class="input-group workflow-variance col-120 d-flex flex-nowrap">
                  <input posInput class="form-control price-input" name="inventoryLogUnchangedQtyWarningMinimumPercent"
                    [(ngModel)]="inventoryWorkflowParameter.InventoryLogUnchangedQtyWarningMinimumPercent" type="number"
                    min="0" max="100" step="0.01" required [disabled]="!isCheckedInventoryLogUnchangedQtyWarning"
                    #unchangedQtyPercent="ngModel" [range]="[0, 100]" />
                  <span class="input-group-addon">%</span>
                </p>
              </pos-column>
              <pos-column xs="3" class="no-wrap">
                <label class="edit-form__section__label">quantities unchanged</label>
              </pos-column>
            </pos-form-field>
          </pos-column>
          <pos-column>
            <pos-form-field label="Don't warn on logging when fewer than"
              [validationMessages]="{required: 'Invalid value'}">
              <input posInput type="number" min="0" name="InventoryLogUnchangedQtyWarningMinimumThreshold"
                class="text-right chk-section__input config-printing-page__num-input form-control col-100"
                [(ngModel)]="inventoryWorkflowParameter.InventoryLogUnchangedQtyWarningMinimumThreshold"
                [required]="true" [disabled]="!isCheckedInventoryLogUnchangedQtyWarning"
                #unchangedQtyThreshold="ngModel" posOnlyNumbers
                [ngClass]="{'disabled':!isCheckedInventoryLogUnchangedQtyWarning}" />
            </pos-form-field>
          </pos-column>
          <pos-column>
            <pos-form-field label="Inventory Receiving Confirmation Wait Time" inputStyle="d-flex flex-nowrap">
              <input posInput type="number" name="inventoryReceivingConfirmationWaitTime"
                class="form-control col-120 col-xs-3" min="0"
                [(ngModel)]="inventoryWorkflowParameter.InventoryReceivingConfirmationWaitTime"
                #inventoryReceivingConfirmationWaitTime="ngModel" posOnlyNumbers />
              <label class="edit-form__section__label ms-pix-10">seconds</label>
            </pos-form-field>
          </pos-column>
          <pos-column md="7" xs="12">
            <pos-form-field label="Inventory Receiving Confirmation Warning Text">
              <textarea posInput rows="3" name="inventoryReceivingConfirmationWarningText" type="textarea"
                class="form-control" [disabled]="!inventoryWorkflowParameter.InventoryReceivingConfirmationWaitTime"
                [(ngModel)]="inventoryWorkflowParameter.InventoryReceivingConfirmationWarningText"
                #inventoryReceivingConfirmationWarningText="ngModel"></textarea>
            </pos-form-field>
          </pos-column>
        </pos-row>
        <pos-row>
          <pos-column md="5" xs="12">
            <pos-form-field label="Default location term">
              <input posInput class="form-control" name="defaultLocationTerm"
                [(ngModel)]="inventoryWorkflowParameter.DefaultLocationTerm" type="text" />
            </pos-form-field>
          </pos-column>
        </pos-row>
      </div>
    </div>
    <div class="col-12 action-button-container page-footer">
      <button class="btn-primary btn-lg btn btn-save pos-button" scrollup type="submit" value="Save">Save</button>
      <button class="btn-secondary btn-lg btn btn-cancel pos-button" routerLink="../" scrollup type="button"
        value="Cancel">Cancel</button>
    </div>
  </form>
</pos-edit-wrapper>
