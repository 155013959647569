import { Component, OnInit, EventEmitter, Input, TemplateRef, ViewChild } from '@angular/core';
import { ICloseable, AlertsService, SpinnerService, OrderService } from 'src/app/shared';
import { finalize } from 'rxjs/operators';
import { GridColumn, EditColumn, LinkColumn, DeleteColumn, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import * as _ from 'lodash';

@Component({
  selector: 'pos-add-existing-button',
  templateUrl: './add-existing-button.component.html',
  styleUrls: ['./add-existing-button.component.scss']
})
export class AddExistingButtonComponent implements OnInit, ICloseable {
  close: EventEmitter<any> = new EventEmitter();
  buttons: Array<any> = [];
  buttonColumns: Array<GridColumn> = [];
  @ViewChild('chkTemplate', { static: true }) chkTemplate: TemplateRef<any>;
  @Input() screenId: number;
  constructor(private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private orderService: OrderService) { }

  ngOnInit() {
    this.configureGridColumn();
    this.getExistingButtons();
  }

  getExistingButtons() {
    this.spinnerService.show();
    this.orderService.getExistingButtons()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: any) => {
          this.buttons = res && res.ButtonData ? res.ButtonData : [];
        }, error: this.alertService.showApiError
      });
  }

  addButtons() {
    const self = this;
    const selectedButtons = _.filter(this.buttons, (button) => {
      button.ScreenId = self.screenId;
      return button.IsCheckedButton === true;
    });
    if (selectedButtons && selectedButtons.length > 0) {
      this.spinnerService.show();
      this.orderService.saveButtonDetailsForScreen(selectedButtons)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res: any) => {
            this.close.emit({ shouldReload: true });
          }, error: this.alertService.showApiError
        });
    }
  }

  configureGridColumn() {
    const operationColumn = new TemplateColumn({
      itemTemplate: this.chkTemplate,
      Width: '45px',
      HeaderText: '',
      TextAlign: TextAlign.Center
    });

    this.buttonColumns = [
      operationColumn,
      new GridColumn({ HeaderText: 'Button Text', Field: 'ButtonText', IsSortable: false }),
      new GridColumn({ HeaderText: 'Product', Field: 'Product', IsSortable: false }),
      new GridColumn({ HeaderText: 'Type', Field: 'Type', IsSortable: false })
    ];
  }

  onCancel() {
    this.close.emit({ shouldReload: false });
  }

}
