import { BsModalRef } from 'ngx-bootstrap/modal';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { ModalComponent } from 'src/app/shared';

@Component({
  selector: 'pos-training-level-content',
  templateUrl: './training-level-content.component.html',
  styleUrls: ['./training-level-content.component.scss']
})
export class TrainingLevelContentComponent extends ModalComponent implements OnInit {
  trainingLevel: any;
  topicName: string;
  prerequisiteText: string;
  showVideo: boolean;
  trainingAreaName: string
  public urlSafe: SafeResourceUrl;
  constructor(
    public sanitizer: DomSanitizer,
    modalRef: BsModalRef) {
    super(modalRef);
    this.showVideo = false;
   }

  ngOnInit() {
    this.prerequisiteText = this.trainingLevel.PrerequisiteText;
    const sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.trainingLevel.VideoUrl ? this.trainingLevel.VideoUrl : '');
    this.urlSafe = sanitizedUrl;
    if (this.trainingLevel.VideoUrl) {
      this.showVideo = true;
    } else {
      this.showVideo = false;
    }
   
  }

  onCancel() {
    this.hide({ shouldReload: true });
  }

  
}
