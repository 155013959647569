<div id="table" class="order-status-page">
  <div class="h-80" [hidden]="isSlideShow">
    <pos-header-entry-main [links]="headerLinks" [isShowBarIcon]="false"></pos-header-entry-main>
  </div>
  <div class="col-md-12 col-sm-12 px-3 pt-pix-15" [hidden]="isSlideShow">
    <div class="table-responsive">
      <table class="table table-bordered table-striped">
        <tr class="order-status-page__col-heading" [ngStyle]="{'font-size': fontSize + 'px'}">
          <th class="col-120">Order</th>
          <th class="col-320">Name</th>
          <th>Product </th>
          <th class="col-220">Status </th>
        </tr>

        <tr id="{{'orderStatusTable' + data.OrderId}}" class="order-status-page__col-text"
          [ngClass]="{'order-status-page__col-text--even': index % 2 === 0, 'order-status-page__col-text--odd': index % 2 !== 0}"
          [ngStyle]="{'font-size': fontSize + 'px'}" *ngFor="let data of orderStatusList; let index = index">
          <td>
            {{data.SurrogateOrderId}}
          </td>
          <td class="text-ellipsis">
            {{data.Name}}
          </td>
          <td>
            {{data.ScreenName}}
          </td>
          <td>
            {{data.Status}}
          </td>
        </tr>
        <tr [hidden]="orderStatusList && orderStatusList.length > 0">
          <td colspan="4">
            No Records
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div id="mediaRotator" class="height-100vh" *ngIf="isSlideShow && slideShowData && slideShowData.length > 0">
    <pos-media-rotator [media]="slideShowData"></pos-media-rotator>
  </div>
</div>
