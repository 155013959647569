import { Component, OnInit, ViewChild } from '@angular/core';
import {
  SettingParam, FieldInfoMessages, AlertsService, SpinnerService, ApplicationStateService, Messages,
  Levels, Permissions, FormUtilityService, BaseFormComponent,
} from 'src/app/shared';
import { finalize } from 'rxjs/operators';
import { EndOfDayService } from '../../services/end-of-day.service';
import { ActivatedRoute, Router } from '@angular/router';
import { endOfDayMoon } from 'src/app/shared/components/icon';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'pos-end-of-day',
  templateUrl: './end-of-day.component.html',
})
export class EndOfDayComponent extends BaseFormComponent implements OnInit {
  @ViewChild('endOfDayForm') endOfDayForm: NgForm;
  get getForm(): NgForm {
    return this.endOfDayForm
  }
  parameters: SettingParam;
  fieldInfoMessages = FieldInfoMessages;
  permission = {
    name: Permissions.SystemConfigurationEndOfDay,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };
  endOfDayTime = new Date();
  hourFormat = '12';
  icons = {
    endOfDayMoon
  }

  constructor(private alertsService: AlertsService,
    private endOfDayService: EndOfDayService,
    private spinnerService: SpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private applicationStateService: ApplicationStateService,
    formUtilityService: FormUtilityService) {
    super(formUtilityService);
    this.parameters = applicationStateService.newSettingParams();
  }

  ngOnInit() {
    this.hourFormat = this.applicationStateService.settingParam.PCalendarHourFormat;
    this.loadParameters();
  }

  loadParameters() {
    this.spinnerService.show();
    this.endOfDayService.getEndOfDayDetails(this.applicationStateService.terminalId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: SettingParam) => {
          this.parameters = response;
          this.endOfDayTime = this.parseTime(this.parameters.EndOfDay);
        }, error: this.alertsService.showApiError
      });
  }

  saveEndOfDay() {
    this.spinnerService.show();
    this.parameters.EndOfDay = this.endOfDayTime.getHours() + ':' + this.endOfDayTime.getMinutes();
    this.endOfDayService.saveEndOfDayDetails(this.parameters)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.alertsService.renderSuccessMessage(Messages.EndOfDaySaveSuccess);
          this.router.navigate(['/manage/system-configuration']);
        }, error: this.alertsService.showApiError
      });
  }

  parseTime(timeString) {
    if (timeString === '') { return null; }

    const time = timeString.match(/(\d+)(:(\d\d))?\s*(p?)/i);
    if (time == null) { return null; }

    let hours = parseInt(time[1], 10);
    if (hours === 12 && !time[4]) {
      hours = 0;
    } else {
      hours += (hours < 12 && time[4]) ? 12 : 0;
    }
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(parseInt(time[3], 10) || 0);
    date.setSeconds(0, 0);
    return date;
  }

  cancel() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
