<pos-list-wrapper [headingIcon]="icons.blackListedAddressBook" heading="Blacklisted IP Addresses" (close)="close()">
    <div>
        <ng-table #gridComponent [columns]="blackListedIpColumns" [data]="ipFailedLoginAttempts">
        </ng-table>

        <ng-template let-data="rowData" #operationTemplate>
            <button type="button" (click)="deleteIpFailedLoginAttempt(data)"
                class="btn btn-primary btn-sm normal pos-button white text-nowrap">
                <pos-icon [icon]="icons.unlock"></pos-icon> Unblock
            </button>
        </ng-template>
        <pos-confirm-delete [message]="confirmUnblockBlacklistedIpAddress"
            (onConfirmAcknowledge)="onConfirmAcknowledge()" #confirmBlacklistedIpAddress>
        </pos-confirm-delete>
    </div>
</pos-list-wrapper>
