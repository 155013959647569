import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { MenuDisplayItem } from '../../interfaces/menu-display-item';
import { TilesWidgetStyleConfig } from '../../interfaces/tiles-widget-style-config';
import { WidgetBaseConfig } from '../../interfaces/widget-base-config';
import { MenuWidgetStyleService } from '../../services/menu-widget-style.service';
import { MenuWidgetService } from '../../services/menu-widget.service';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import * as $ from 'jquery';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { MenuItemsSortService } from '../../services/menu-items-sort.service';
import { RabbitMQService } from 'src/app/shared';
@Component({
  selector: 'pos-menu-tile-list-widget',
  templateUrl: './menu-tile-list-widget.component.html',
  styleUrls: ['./menu-tile-list-widget.component.scss']
})
export class MenuTileListWidgetComponent extends BaseWidgetComponent implements OnInit {

  tileItems: Array<MenuDisplayItem>;
  widgetTilesStyle: TilesWidgetStyleConfig;
  widgetConfig: WidgetBaseConfig;
  widgetContentStyle = {};
  tileBorder = {};
  textStyle = {};
  priceText = {};
  descriptionTextStyle = {};
  tileHeight;
  tileBackgroundColor: string;
  imagePath = RuntimeConstants.IMAGE_BASE_PATH + '/menu-display';
  tileWidth: string = null;
  constructor(protected menuWidgetService: MenuWidgetService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected applicationStateService: ApplicationStateService,
    protected modalService: ModalService,
    protected menuWidgetStyleService: MenuWidgetStyleService,
    private menuItemSortService: MenuItemsSortService,
    protected rabbitMQService: RabbitMQService) {
    super(menuWidgetService, alertService, spinnerService, applicationStateService, modalService, rabbitMQService);
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.widgetData$.subscribe({
      next: (response) => {
        this.widgetTilesStyle = this.parseJson<TilesWidgetStyleConfig>(response.WidgetStyleConfig);
        this.widgetConfig = this.parseJson<WidgetBaseConfig>(response.WidgetConfig);
        this.widgetTilesStyle?.BorderStyle ?
          $('.widget-border-' + this.widgetId)?.css(this.menuWidgetStyleService.getBorderStyle(this.widgetTilesStyle?.BorderStyle)) : null;
        $('.widget-background-' + this.widgetId)?.css({ 'background': this.widgetTilesStyle?.WidgetBackgroundColor });
        this.setTileStyle();
        this.tileItems = response?.MenuDisplayItems;
        this.widgetContentStyle = this.menuWidgetStyleService.getWidgetContentStyle(this.widgetTilesStyle);
        this.tileItems = this.menuItemSortService.sortItems(this.tileItems, this.widgetConfig?.ProductDisplayOrdinal?.find(pdo => pdo.GroupId == null)?.Ordinal ?? []);
      }
    });
    this.loadWidget(this.widgetId);
  }

  setTileStyle() {
    this.tileBorder = this.menuWidgetStyleService.getBorderStyle(this.widgetTilesStyle?.TileBorder);
    this.textStyle = this.menuWidgetStyleService.getFontStyle(this.widgetTilesStyle?.Title);
    this.priceText = this.menuWidgetStyleService.getFontStyle(this.widgetTilesStyle?.Price);
    this.descriptionTextStyle = this.menuWidgetStyleService.getFontStyle(this.widgetTilesStyle?.Description);
    this.tileBackgroundColor = this.widgetTilesStyle?.TileBackgroundColor;
    this.tileHeight = this.widgetTilesStyle?.TileHeight;
    this.tileWidth = this.widgetTilesStyle?.TilesPerRow ? (97 / this.widgetTilesStyle.TilesPerRow) + '%' : null;
  }

  editWidget() {

  }

}
