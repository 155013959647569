<pos-list-wrapper [headingIcon]="icons.commentWarningsComments" heading="Product Loss Reasons" (close)="close()" 
  permissions='[{"Name":"{{permission.name}}", "Level":"{{permission.readOnlyLevel}}"}]'>
  <div class="row">
    <div class="col-12">
      <ng-table #gridComponent [search]="true" [sort]="true" [paginator]="true"
        [exportFilename]="'product-loss-reasons'" [columns]="lossReasonColumns" [data]="productLossReasons">
      </ng-table>
    </div>
  </div>
</pos-list-wrapper>
<pos-confirm-delete [message]="message" (onConfirmAcknowledge)="onConfirmAcknowledge()" #confirmButtonDelete>
</pos-confirm-delete>
<pos-print-table #printTableReference [hidden]="true"></pos-print-table>
