import { Component, OnInit, ViewChild } from '@angular/core';
import { Permissions, Levels, Messages, DomainConstants } from '../../../../shared/constants/';
import { WorkSchedule, FilterBy } from '../../interface';
import { BaseListComponent } from 'src/app/shared/components/base-list.component';
import { AuthenticationService } from 'src/app/shared/auth/services/authentication.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { GridColumn, DateColumn, TextAlign, TableComponent } from '@tarktech/tark-ng-utils';
import * as _ from 'lodash';
import { DatePipe } from '@angular/common';
import { ImportWorkScheduleComponent } from '../import-work-schedule';
import { WorkScheduleBatchEntryComponent } from '../work-schedule-batch-entry';
import { UserWorkScheduleComponent } from '../user-work-schedule';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import { ApplicationStateService, WorkScheduleService } from 'src/app/shared';
import { ActivatedRoute, Router } from '@angular/router';

import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { workScheduleCalendarAlt } from 'src/app/shared/components/icon';

@Component({
    selector: 'pos-work-schedule-list',
    templateUrl: './work-schedule-list.component.html',
    styleUrls: ['./work-schedule-list.component.scss']
})
export class WorkScheduleListComponent extends BaseListComponent<WorkSchedule> implements OnInit {

    permission = {
        name: Permissions.ManageWorkSchedule,
        readOnlyLevel: Levels.ReadOnly,
        editLevel: Levels.Edit,
    };
    workScheduleColumns: Array<GridColumn> = [];
    printWorkScheduleColumns: Array<GridColumn> = [];
    workSchedules: WorkSchedule[] = [];
    workSchedulesSearchResult: WorkSchedule[] = [];
    @ViewChild('workScheduleGrid', { static: true }) workScheduleGrid: TableComponent;
    currentFilter = '';
    icons = { workScheduleCalendarAlt };
    constructor(
        protected workScheduleService: WorkScheduleService,
        private authenticationService: AuthenticationService,
        protected alertService: AlertsService,
        protected spinnerService: SpinnerService,
        protected modalService: ModalService,
        protected route: ActivatedRoute,
        private router: Router,
        private datePipe: DatePipe,
        protected applicationStateService: ApplicationStateService,
        protected printService: PrintTableService,
    ) {
        super(workScheduleService, alertService, spinnerService, modalService, applicationStateService, printService, route);
    }

    ngOnInit() {
        this.deleteSuccessMessage = Messages.WorkScheduleDeleted;
        this.workScheduleColumns = this.configureGridColumns();
        this.printWorkScheduleColumns = [...this.getGridColumns()];
        this.checkPermission();
        this.loadSubscriptions();
        this.scrollToTop();
        this.workScheduleGrid.context = this.gridContext;
    }

    public getGridColumns(): Array<GridColumn> {
        return [
            new GridColumn({ HeaderText: 'Username', Field: 'user_name', IsSortable: true, Width: '35%' }),
            new DateColumn({
                HeaderText: 'Start Date',
                Field: 'start_date',
                TextAlign: TextAlign.Left,
                IsSortable: true,
                Width: '30%'
            }),
            new DateColumn({
                HeaderText: 'End Date',
                Field: 'end_date',
                TextAlign: TextAlign.Left,
                IsSortable: true,
                Width: '30%'
            })
        ] as Array<GridColumn>;
    }

    private loadSubscriptions(): void {
        this.data$.subscribe({
            next: (res) => {
                this.workSchedulesSearchResult = [...res];
                this.workSchedules = res;
            }
        });
    }

    public editItem(id: number, data?: WorkSchedule): void {
        this.router.navigate([id], { relativeTo: this.route });
    }

    public getConfirmDeleteMessage(data: WorkSchedule): string {
        return StringUtils.format(Messages.ConfirmDeleteWorkSchedule,
            { 'user_name': data ? data.user_name : '' }
        );
    }

    private checkPermission(): void {
        if (!this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
            _.remove(this.workScheduleColumns, (column) => {
                return (column.Type === DomainConstants.GridColumnsTypes.EditColumn
                    || column.Type === DomainConstants.GridColumnsTypes.DeleteColumn);
            });
        }
    }

    public onImportWorkSchedule() {
        const modalRef = this.modalService.show(ImportWorkScheduleComponent, {
            animated: false,
            class: 'vertical-center',
            initialState: {}
        });
        modalRef.close.subscribe(res => {
            if (res && res.shouldReload) {
                this.reload();
            }
        });
    }

    public loadUserWorkSchedule() {
        const modalRef = this.modalService.show(UserWorkScheduleComponent, {
            animated: false,
            class: 'vertical-center modal-max-width-95',
            initialState: {}
        });
        modalRef.close.subscribe(res => {
            if (res?.shouldReload) {
                this.reload();
            }
        });
    }

    public loadBathEntry() {
        const modalRef = this.modalService.show(WorkScheduleBatchEntryComponent, {
            animated: false,
            class: 'vertical-center modal-max-width-95',
            initialState: {}
        });
        modalRef.close.subscribe(res => {
            if (res?.shouldReload) {
                this.reload();
            }
        });
    }

    public showTags() {
        this.router.navigate(['work-schedule-tags'], { relativeTo: this.route.parent });
    }
    public filterWorkScheduleList(filterBy: string) {
        this.currentFilter = filterBy;
        if (filterBy === this.filterBy.Today) {
            this.workScheduleFilteredList(null, this.getDate(new Date()));
        } else if (filterBy === this.filterBy.Next7Days) {
            this.workScheduleFilteredList(this.getDate(new Date()), this.getDate(new Date(new Date().setDate(new Date().getDate() + 7))));
        } else if (filterBy === this.filterBy.Last7Days) {
            this.workScheduleFilteredList(this.getDate(new Date()), this.getDate(new Date(new Date().setDate(new Date().getDate() - 7))));
        } else if (filterBy === this.filterBy.TwoWeeks) {
            this.workScheduleFilteredList(
                this.getDate(new Date(new Date().setDate(new Date().getDate() + 7))),
                this.getDate(new Date(new Date().setDate(new Date().getDate() - 7))));
        }
    }

    public clearFilter() {
        this.currentFilter = '';
        this.workSchedules = this.workSchedulesSearchResult;
    }

    private workScheduleFilteredList(fromDate: string, toDate: string) {
        if (!fromDate) {
            this.workSchedules = this.workSchedulesSearchResult.filter(w => this.getDate(w.start_date) === toDate);
        } else {
            if (fromDate > toDate) {
                this.workSchedules = this.workSchedulesSearchResult.filter(
                    w => this.getDate(w.start_date) >= toDate && this.getDate(w.start_date) <= fromDate);
            } else {
                this.workSchedules = this.workSchedulesSearchResult.filter(
                    w => this.getDate(w.start_date) >= fromDate && this.getDate(w.start_date) <= toDate);
            }
        }
    }

    private getDate(date: Date): string {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    get filterBy() {
        return FilterBy;
    }
    private scrollToTop(): void {
        window.scrollTo(0, 0);
    }
    public printData() {
        this.printService.printEmitter.next({ gridColumns: this.printWorkScheduleColumns, gridData: this.workSchedules });
    }

    close() {
        this.router.navigate(['../'], { relativeTo: this.route });
    }
}
