<pos-edit-wrapper (close)="cancelEditing()" [isPopup]="false" [headingIcon]="icons.accountCategoriesClipboardList"
  heading="{{accountCategory.Id ? 'Edit Account Category' : 'Add Account Category'}}">
    <form #accountCategoryForm="ngForm" class="edit-form" (ngSubmit)="submit(accountCategoryForm.valid)"
        autocomplete="off">
        <div class="col-12 px-4 border-top pt-pix-5">
            <div class="col-12">
                <pos-row>
                    <pos-column>
                        <pos-form-field label="Name" [validationMessages]="{required: 'Please enter name.'}">
                            <input posInput type="text" name="category" #Category="ngModel" class="form-control"
                                [(ngModel)]="accountCategory.Category" [required]="true" />
                        </pos-form-field>
                    </pos-column>
                </pos-row>
            </div>
        </div>
        <div class="clearfix"></div>
        <div class="col-12 action-button-container page-footer">
            <button type="submit" value="Save" class="btn-primary btn-lg white btn btn-save pos-button"
                data-dismiss="modal">Save</button>
            <button type="button" class="btn-secondary btn-lg btn btn-cancel pos-button"
                (click)="cancelEditing()" value="Cancel"> Cancel
            </button>
        </div>
    </form>
</pos-edit-wrapper>
