<pos-edit-wrapper (close)="cancelEditing()"
    heading="{{id ? 'Edit Permission: ' + permissionType.Name : 'Add Permission'}}" [headingIcon]="icons.lockOpen"
    [isPopup]="false">
    <form #permissionTypeForm="ngForm" (submit)="submit(permissionTypeForm.valid)" autocomplete="off">
        <context-provider provide="props">
            <div class="col-12 pos-page-body border-top pt-pix-10">
                <div class="col-xs-12">
                    <pos-row>
                        <pos-column>
                            <label class="edit-form__section__label mb-0">Permission ID: {{
                                permissionType.PermissionId
                                }}</label>
                        </pos-column>
                        <pos-column>
                            <pos-form-field label="Tags" [fieldInfo]="fieldInfoMessages.PermissionTags">
                                <ng-select class="tags-theme" [items]="[]" placeholder="Add tag(s)" [addTag]="true" name="tagsInput"
                                    [(ngModel)]="tags" #tagsInput="ngModel" [multiple]="true" [hideSelected]="true"
                                    [isOpen]="false">
                                </ng-select>
                            </pos-form-field>
                        </pos-column>
                        <pos-column>
                            <pos-form-field label="Description">
                                <textarea posInput rows="3" name="description" class="form-control"
                                    [(ngModel)]="permissionType.Description"></textarea>
                            </pos-form-field>
                        </pos-column>
                    </pos-row>
                </div>
            </div>

            <div class="col-12 action-button-container page-footer">
                <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" value="Save">Save </button>
                <button type="button" data-dismiss="modal" class="btn-secondary  btn-lg btn btn-cancel pos-button"
                    (click)="cancelEditing()" value="Cancel">Cancel</button>
            </div>
        </context-provider>
    </form>
</pos-edit-wrapper>
