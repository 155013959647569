<div bsModal [config]="{backdrop: 'static'}" #lgModal="bs-modal" id="{{keyboardId}}" tabindex="-1" role="dialog"
    aria-labelledby="CommentForItemLabel" style="z-index: 10005" aria-hidden="true"
    (keyup.enter)="enterEventForKeyboard()">
    <div class="modal-content pb-pix-10">
        <div>
            <button type="button" class="close" (click)="textEnteredForKeyboard('Cancel',0)"
                data-dismiss="modal">&times;</button>
            <h4 class="modal-content__heading" id="keyboardTitle">{{options.title}}</h4>
        </div>
        <div>
            <div class="col-md-12 pt-pix-15 px-pix-20" [ngClass]="{'has-error': ((isSubmitted && !value ) && required)}">
                <input id="txtKeyboard_{{keyboardId}}" #txtKeyboard [disabled]="options.isReadOnly"
                    class="keyboard-modal__keyboard__comment form-control" type="text" [(ngModel)]="value"
                    role="textbox" *ngIf="options.inputMode==='singleLine'"
                    maxlength="{{options.maxLength && options.maxLength > 0 ? options.maxLength : defaultMaxLength}}">
                <textarea id="multiTxtKeyboard" #multiLineInput type="text" *ngIf="options.inputMode==='multiLine'"
                    rows="options.dynamicMultilineInput ? 1 : 4" [(ngModel)]="value" role="textbox" class=""
                    [ngClass]="{'keyboard-modal__keyboard__dynamic-input': options.dynamicMultilineInput, 'keyboard-modal__keyboard__comment--area form-control': !options.dynamicMultilineInput}"></textarea>
                <label class="control-label" *ngIf="(isSubmitted && !value ) && required">Please enter value.</label>
            </div>
            <div class="col-12 pt-pix-10" *ngIf="isDeviceMobile">
                <div class="col-4">
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 col-2 p-pix-5">
                    <div class="btn-cal web-only" (click)="submit()" data-dismiss="modal">
                        <div class="keyboard-modal__keyboard__btn-img">
                            <pos-icon [icon]="icons.checkCircleSolid"></pos-icon>
                        </div>
                        <div class="text-left mt-pix-3">
                            <span>OK</span>
                        </div>
                    </div>
                    <div class="btn-cal mobile-only" (click)="submit()" data-dismiss="modal">
                        <div>
                            <img class="btn-cal__img ps-pix-5" src="images/icons/icon-circle-ok.png" />
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 col-2 p-pix-5">
                    <div class="btn-cal web-only" (click)="textEnteredForKeyboard('Cancel',0)" data-dismiss="modal">
                        <div class="keyboard-modal__keyboard__btn-img">
                            <pos-icon [icon]="icons.timesCircle"
                                class="grey p-pix-5 keyboard-modal__keyboard__btn-img__icon "></pos-icon>
                        </div>
                        <div class="text-left mt-pix-3">
                            <span>cancel</span>
                        </div>
                    </div>
                    <div class="btn-cal mobile-only" (click)="textEnteredForKeyboard('Cancel',0)" data-dismiss="modal">
                        <div>
                            <img class="btn-cal__img ps-pix-5" src="images/icons/icon-no-square.png" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row g-0 px-pix-17 col-12 pt-pix-10" *ngIf="!isDeviceMobile">
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard('1',0)">1</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard('2',0)">2</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard('3',0)">3</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard('4',0)">4</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard('5',0)">5</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard('6',0)">6</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard('7',0)">7</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard('8',0)">8</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard('9',0)">9</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard('0',0)">0</div>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 col-2 p-pix-5">
                    <div class="btn-cal web-only text-ellipsis" (click)="textEnteredForKeyboard('Back',0)">
                        <div class="keyboard-modal__keyboard__btn-img mt-pix-n4">
                            <pos-icon class="grey p-pix-2 ps-pix-4ix-4ix-4ix-4 keyboard-modal__keyboard__btn-img__icon"
                                [icon]="icons.arrowAltCircleLeft"></pos-icon>
                        </div>
                        <div class="text-left">
                            <span>Backspace</span>
                        </div>
                    </div>
                    <div class="btn-cal mobile-only" (click)="textEnteredForKeyboard('Back',0)">
                        <div>
                            <pos-icon class="grey p-pix-2" [icon]="icons.arrowLeft"></pos-icon>
                        </div>
                    </div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard(capsLock=='On'?'Q':'q',0)">
                        {{capsLock=='On'? 'Q' :'q'}}</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard(capsLock=='On'?'W':'w',0)">
                        {{capsLock=='On'? 'W' :'w'}}</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard(capsLock=='On'?'E':'e',0)">
                        {{capsLock=='On'? 'E' :'e'}}</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard(capsLock=='On'?'R':'r',0)">
                        {{capsLock=='On'? 'R' :'r'}}</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard(capsLock=='On'?'T':'t',0)">
                        {{capsLock=='On'? 'T' :'t'}}</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard(capsLock=='On'?'Y':'y',0)">
                        {{capsLock=='On'? 'Y' :'y'}}</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard(capsLock=='On'?'U':'u',0)">
                        {{capsLock=='On'? 'U' :'u'}}</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard(capsLock=='On'?'I':'i',0)">
                        {{capsLock=='On'? 'I' :'i'}}</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard(capsLock=='On'?'O':'o',0)">
                        {{capsLock=='On'? 'O' :'o'}}</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard(capsLock=='On'?'P':'p',0)">
                        {{capsLock=='On'? 'P' :'p'}}</div>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 col-2 p-pix-5">
                    <div class="btn-cal web-only text-ellipsis" (click)="textEnteredForKeyboard('Cancel',0)"
                        data-dismiss="modal">
                        <div class="keyboard-modal__keyboard__btn-img mt-pix-n2">
                            <pos-icon [icon]="icons.timesCircle"
                                class="grey p-pix-5 keyboard-modal__keyboard__btn-img__icon "></pos-icon>
                        </div>
                        <div class="text-left">
                            <span>Cancel</span>
                        </div>
                    </div>
                    <div class="btn-cal mobile-only" (click)="textEnteredForKeyboard('Cancel',0)" data-dismiss="modal">
                        <div>
                            <img class="btn-cal__img ps-pix-5" src="images/icons/icon-no-square.png" />
                        </div>
                    </div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard(capsLock=='On'?'A':'a',0)">
                        {{capsLock=='On'? 'A' :'a'}}</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard(capsLock=='On'?'S':'s',0)">
                        {{capsLock=='On'? 'S' :'s'}}</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard(capsLock=='On'?'D':'d',0)">
                        {{capsLock=='On'? 'D' :'d'}}</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard(capsLock=='On'?'F':'f',0)">
                        {{capsLock=='On'? 'F' :'f'}}</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard(capsLock=='On'?'G':'g',0)">
                        {{capsLock=='On'? 'G' :'g'}}</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard(capsLock=='On'?'H':'h',0)">
                        {{capsLock=='On'? 'H' :'h'}}</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard(capsLock=='On'?'J':'j',0)">
                        {{capsLock=='On'? 'J' :'j'}}</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard(capsLock=='On'?'K':'k',0)">
                        {{capsLock=='On'? 'K' :'k'}}</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard(capsLock=='On'?'L':'l',0)">
                        {{capsLock=='On'? 'L' :'l'}}</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard('#',0)">#</div>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 col-2 p-pix-5">
                    <div class="btn-cal web-only" (click)="textEnteredForKeyboard('Clear',0)">
                        <div class="keyboard-modal__keyboard__btn-img">
                            <span class="fa-stack grey keyboard-modal__keyboard__btn-img__block">
                                <pos-icon [icon]="icons.circleRegular"
                                    class="fa-stack-1x keyboard-modal__keyboard__btn-img__icon"></pos-icon>
                                <pos-icon [icon]="icons.reply" class="font-15"></pos-icon>
                            </span>
                        </div>
                        <div class="text-left">
                            <span>Clear</span>
                        </div>
                    </div>
                    <div class="btn-cal mobile-only" (click)="textEnteredForKeyboard('Clear',0)">
                        <div>
                            <img class="btn-cal__img ps-pix-5" src="images/icons/icon-revert-arrow.png" />
                        </div>
                    </div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard(capsLock=='On'?'Z':'z',0)">
                        {{capsLock=='On'? 'Z' :'z'}}</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard(capsLock=='On'?'X':'x',0)">
                        {{capsLock=='On'? 'X' :'x'}}</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard(capsLock=='On'?'C':'c',0)">
                        {{capsLock=='On'? 'C' :'c'}}</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard(capsLock=='On'?'V':'v',0)">
                        {{capsLock=='On'? 'V' :'v'}}</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard(capsLock=='On'?'B':'b',0)">
                        {{capsLock=='On'? 'B' :'b'}}</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard(capsLock=='On'?'N':'n',0)">
                        {{capsLock=='On'? 'N' :'n'}}</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard(capsLock=='On'?'M':'m',0)">
                        {{capsLock=='On'? 'M' :'m'}}</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard(',',0)">,</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard('.',0)">.</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard(';',0)">;</div>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 col-2 p-pix-5">
                    <div class="btn-cal web-only" (click)="submit()" data-dismiss="modal">
                        <div class="keyboard-modal__keyboard__btn-img mt-pix-n4">
                            <pos-icon [icon]="icons.checkCircleSolid"
                                class="p-pix-2 ps-pix-4 grey keyboard-modal__keyboard__btn-img__icon "></pos-icon>
                        </div>
                        <div class="text-left">
                            <span>OK</span>
                        </div>
                    </div>
                    <div class="btn-cal mobile-only" (click)="submit()" data-dismiss="modal">
                        <div>
                            <img class="btn-cal__img ps-pix-5" src="images/icons/icon-circle-ok.png" />
                        </div>
                    </div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard('-',0)">-</div>
                </div>
                <div class="offset-1  col-lg-6 col-md-6 col-sm-6 col-6 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard(' ',0)">Space</div>
                </div>
                <div class="col-1 p-pix-5">
                    <div class="btn-cal" style="transform: rotate(90deg)" *ngIf="newLine"
                        (click)="textEnteredForKeyboard('\n',0)">
                        <pos-icon [icon]=icons.levelDown></pos-icon>
                    </div>
                </div>

                <div class="col-1 p-pix-5">
                    <div class="btn-cal" (click)="textEnteredForKeyboard('@',0)">@</div>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 col-2 p-pix-5">
                    <div class="btn-cal" (click)="capsLockOnOff()">Caps {{capsLock}}</div>
                </div>
            </div>
        </div>
        <div class="clearfix"></div>
    </div>
</div>
