import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AlertsService, Dashboard, DashboardConfigurationService, DashboardLayout, FormUtilityService, Messages, ModalFormComponent, ModalService, RabbitMQService, SpinnerService } from 'src/app/shared';


@Component({
  selector: 'pos-dashboard-edit',
  templateUrl: './dashboard-edit.component.html',
  styleUrls: ['./dashboard-edit.component.scss']
})
export class DashboardEditComponent extends ModalFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.dashboardForm;
  }

  @Input() id = 0;
  dashboard: Dashboard;
  layouts: Array<DashboardLayout> = [];

  @ViewChild('dashboardForm', { static: true}) dashboardForm: NgForm;

  constructor(protected dashboardConfigurationService: DashboardConfigurationService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    modalRef: BsModalRef,
    modalService: ModalService,
    protected rabbitMQService: RabbitMQService,
    formUtilityService: FormUtilityService) {
      super(modalRef, modalService, formUtilityService);
      this.dashboard = dashboardConfigurationService.newDashboard();
  }

  ngOnInit(): void {
    this.loadDependencies();
  }

  loadDependencies(): void {
    const observable: Array<Observable<any>> = [];
    observable.push(this.dashboardConfigurationService.getDashboardLayouts());
    if (this.id > 0) {
      observable.push(this.dashboardConfigurationService.getDashboard(this.id));
    }
    this.spinnerService.show();
    forkJoin(observable)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: ([layouts, dashboard]: [Array<DashboardLayout>, Dashboard]) => {
          this.layouts = layouts;
          if (this.id > 0) {
            this.dashboard = dashboard;
          }
        },
        error: this.alertService.showApiError
      });
  }

  saveDashboard(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.spinnerService.show();
    const dashboard = {
      dashboardId: this.dashboard.Id,
      name: this.dashboard.Name,
      layoutId: this.dashboard.LayoutId,
      AutoRefreshInterval: this.dashboard.AutoRefreshInterval,
      IsShowClock: this.dashboard.IsShowClock,
    };
    this.dashboardConfigurationService.saveDashboard(dashboard)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({ next: () => {
        this.rabbitMQService.sendRefreshDashBoardMessage();
        this.alertService.renderSuccessMessage(Messages.DashboardSaveSuccess);
        this.hide({shouldReload: true});
      }, error: this.alertService.showApiError });
  }

  cancelDashboard() {
    this.hide({shouldReload: false});
  }

}
