import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { forEach, sortBy } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ComboSalesProductModel } from 'src/app/menu-display/interfaces/widget-combo-item-model';
import { MenuWidgetService } from 'src/app/menu-display/services';
import { ConfirmDeleteModalComponent, FormUtilityService, Messages, ModalFormComponent, ModalService } from 'src/app/shared';
import { deleteWhite, plus, arrowsAlt } from 'src/app/shared/components/icon';
declare let $: any;
@Component({
  selector: 'pos-combo-products-config',
  templateUrl: './combo-products-config.component.html',
  styleUrls: ['./combo-products-config.component.scss']
})
export class ComboProductsConfigComponent extends ModalFormComponent implements OnInit {

  @Input() comboSalesProducts: Array<ComboSalesProductModel> = [];
  get getForm(): NgForm {
    return this.comboProductConfigForm;
  }
  @ViewChild('comboProductConfigForm') comboProductConfigForm: NgForm;
  icons = {
    plus,
    deleteWhite,
    arrowsAlt
  }
  containerHeight: string;
  trackByIdx(index: number, obj: any): any {
    return index;
  }
  constructor(public menuWidgetService: MenuWidgetService,
    modalRef: BsModalRef,
    modalService: ModalService,
    formUtilityService: FormUtilityService,) {
    super(modalRef, modalService, formUtilityService)
  }

  ngOnInit(): void {
    this.getHeight();
    forEach(this.comboSalesProducts, (item) => {
      item.BorderStyleConfig = item.BorderStyle ? JSON.parse(item.BorderStyle) : this.menuWidgetService.getNewBorderStyleConfig();
    });
    this.comboSalesProducts = sortBy(this.comboSalesProducts, ['Ordinal']);
  }

  getHeight() {
    this.containerHeight = $(window).height() - (320) + 'px';
  }
  saveWidget(isValid: boolean) {
    if(!isValid) return;
    forEach(this.comboSalesProducts, (item) => {
      item.BorderStyle = JSON.stringify(item.BorderStyleConfig);
    });
    this.hide({ shouldReload: true, comboSalesProducts: this.comboSalesProducts })
  }
  onCancel() {
    this.hide({});
  }

  addProduct() {
    const newComboProduct = this.menuWidgetService.newWidgetComboSalesProduct();
    newComboProduct.Ordinal = this.comboSalesProducts.length + 1;
    this.comboSalesProducts.push(newComboProduct);
  }

  onDeleteItem(index: number) {
    const confirmDelete = this.modalService.show(ConfirmDeleteModalComponent, {
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      initialState: {
        message: Messages.ConfirmDeleteWidgetComboProduct
      }
    });

    confirmDelete.close.subscribe(res => {
      if (res && res.shouldDelete) {
        this.comboSalesProducts.splice(index, 1);
        this.reloadOrdinal();
      }
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.comboSalesProducts, event.previousIndex, event.currentIndex);
    this.reloadOrdinal();
  }

  reloadOrdinal() {
    forEach(this.comboSalesProducts, (product, index) => {
      product.Ordinal = index + 1;
    })
  }
}
