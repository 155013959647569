<pos-edit-wrapper heading="Drivers" (close)="closeDriver()">
    <div class="col-12 pos-modal-body mt-pix-5 screen-scroll">
        <div class="row mx-0" *ngIf="drivers.length > 0">
            <div *ngFor="let item of drivers" (click)="updateDriver(item)" class="col-12 row mx-0 drivers p-0"
                tooltipPosition="top" tooltipZIndex="10000"
                pTooltip="{{item.IsActiveDelivery ? activeDriverTooltip : inactiveDriverTooltip }}" appendTo="body"
                tooltipStyleClass="custom-tooltip">
                <div class="col-sm-2 col-3 pe-0 pt-pix-15">
                    <img alt="" *ngIf="item.IsActiveDelivery" class="image-size" src="images/icons/active-driver.svg" />
                    <img alt="" *ngIf="!item.IsActiveDelivery" class="image-size"
                        src="images/icons/inactive-driver.svg" />
                </div>
                <div class="col-sm-10 col-9 pt-pix-15 pe-0">
                    <div class="row mx-0">
                        <div class="col-6 p-0">
                            <b>{{item.Name}}</b>
                        </div>
                        <div class="col-6 text-align-right">Bank: {{item.Bank | tark_currency}}</div>
                    </div>
                    <div class="row mx-0">
                        <div class="col-6 p-0">Current Deliveries: {{item.DeliveryCount}}</div>
                        <div class="col-6 text-align-right">Orders: {{item.Total | tark_currency}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!drivers.length" class="col-12 drivers p-pix-10 text-align-center">
            <h2>Driver Not Available</h2>
        </div>
    </div>

    <div class="col-12 action-button-container popup-footer">
        <button type="button" class="btn btn-secondary btn-lg btn-cancel-modal pos-button" value="Cancel"
            (click)="closeDriver()">Close</button>
    </div>
</pos-edit-wrapper>
