import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertsService, AudioNotificationService, BaseFormComponent, DeviceMappingService, DomainConstants, Features, FormUtilityService, HardwareModel, Messages, SpinnerService } from 'src/app/shared';
import { ServeScreenWorkflowParameter } from '../../interfaces/serve-screen-workflow-parameter';
import { ServeScreenWorkflowService } from '../../services/serve-screen-workflow.service';
import { Options } from '@angular-slider/ngx-slider';
import { Terminal, TerminalsService } from 'src/app/configurator/terminals';
import { code, rocket } from 'src/app/shared/components/icon';
import { finalize, forkJoin, Observable } from 'rxjs';
import { filter, isEmpty } from 'lodash';
import { PostSavedConfigurationService } from 'src/app/configurator/post-saved-configuration.service';
import { OrderItemVariable } from '../../interfaces/order-item-variable';

@Component({
  selector: 'pos-serve-screen-workflow',
  templateUrl: './serve-screen-workflow.component.html',
  styleUrls: ['./serve-screen-workflow.component.scss']
})
export class ServeScreenWorkflowComponent extends BaseFormComponent {
  
  @ViewChild('serveScreenWorkflowForm') serveScreenWorkflowForm: NgForm;
  get getForm(): NgForm {
    return this.serveScreenWorkflowForm;
  }
  props = {
    controlBoxClass: 'col-md-6 col-sm-8 col-xs-12'
  }

  serveScreenWorkflowParameters: ServeScreenWorkflowParameter;
  features = Features;
  options: Options = {
    floor: 0,
    ceil: 100,
    translate: (value: number): string => {
      return '';
    }
  };
  icons = { code, rocket }
  audioVoices: Array<string> = [];
  audioDevices: Array<HardwareModel> = [];
  chkSendViaSMS = false;
  chkSendViaDJ = false;
  chkSendViaAudioNotifier = false;
  cursorPosition = 0;
  djTerminals: Array<Terminal> = [];
  isOpenVariableForAudioNotifier = false;
  isOpenVariableForSMS = false;
  isOpenVariableForDJTerminal = false;
  orderItemVariables: Array<OrderItemVariable> = [];
  selectedOrderVariable = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private terminalsService: TerminalsService,
    private audioNotificationService: AudioNotificationService,
    private deviceMappingService: DeviceMappingService,
    private serveScreenWorkflowService: ServeScreenWorkflowService,
    private postSavedConfigurationService: PostSavedConfigurationService,
    protected formUtilityService: FormUtilityService
  ) {
    super(formUtilityService);
  }

  ngOnInit(): void {
    this.serveScreenWorkflowParameters = this.serveScreenWorkflowService.newServeScreenWorkflowConfigurationParameter();
    this.loadData();
  }

  loadData() {
    const observables: Array<Observable<any>> = [];
    observables.push(this.terminalsService.getTerminals());
    observables.push(this.audioNotificationService.getAudioVoiceId());
    observables.push(this.deviceMappingService.getAudioDevices());
    observables.push(this.serveScreenWorkflowService.getServeScreenWorkflowParameters());
    observables.push(this.serveScreenWorkflowService.getOrderItemVariables());
    this.spinnerService.show();
    forkJoin(observables)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe({
        next: ([terminals, audioVoices, audioDevices, parameter, orderItemVariables]: [Terminal[], Array<string>, Array<HardwareModel>, ServeScreenWorkflowParameter, Array<OrderItemVariable>]) => {
          this.djTerminals = filter(terminals, (terminal) => terminal.TerminalType === DomainConstants.TerminalTypes.DJ.Name);
          this.audioVoices = audioVoices;
          this.audioDevices = audioDevices;
          this.serveScreenWorkflowParameters = parameter;
          this.orderItemVariables = orderItemVariables;      

          this.chkSendViaSMS = this.serveScreenWorkflowParameters.ItemReadyNotifyViaSMSText !== '' ? true : false;
            this.chkSendViaDJ =
              this.serveScreenWorkflowParameters.ItemReadyNotifyViaDJTerminal &&
              this.serveScreenWorkflowParameters.ItemReadyNotifyViaDJText &&
              this.serveScreenWorkflowParameters.ItemReadyNotifyViaDJVoice
                ? true
                : false;
          this.chkSendViaAudioNotifier =
            this.serveScreenWorkflowParameters.ItemReadyNotifyViaAudioNotifier &&
            this.serveScreenWorkflowParameters
              .ItemReadyNotifyViaAudioNotifierText &&
            this.serveScreenWorkflowParameters
              .ItemReadyNotifyViaAudioNotifierVoice
              ? true
              : false;
          this.serveScreenWorkflowParameters.ItemReadyNotifyViaDJTerminal = this.serveScreenWorkflowParameters.ItemReadyNotifyViaDJTerminal > 0 ? this.serveScreenWorkflowParameters.ItemReadyNotifyViaDJTerminal : null;
          this.serveScreenWorkflowParameters.ItemReadyNotifyViaDJVoice = isEmpty(this.serveScreenWorkflowParameters.ItemReadyNotifyViaDJVoice) ? null : this.serveScreenWorkflowParameters.ItemReadyNotifyViaDJVoice;
          this.serveScreenWorkflowParameters.ItemReadyNotifyViaAudioNotifier = this.serveScreenWorkflowParameters.ItemReadyNotifyViaAudioNotifier > 0 ? this.serveScreenWorkflowParameters.ItemReadyNotifyViaAudioNotifier : null;
          this.serveScreenWorkflowParameters.ItemReadyNotifyViaAudioNotifierVoice = isEmpty(this.serveScreenWorkflowParameters.ItemReadyNotifyViaAudioNotifierVoice) ? null : this.serveScreenWorkflowParameters.ItemReadyNotifyViaAudioNotifierVoice;
        },
        error: this.alertService.showApiError
      })
  }

  saveServeScreenWorkflow(isValid: boolean) {
    if (!isValid) return;
    if (this.serveScreenWorkflowParameters.ItemReadyShowNotifyButtonOnServeScreen && !(this.chkSendViaSMS || this.chkSendViaDJ ||
      this.serveScreenWorkflowParameters)) {
      this.alertService.renderErrorMessage(Messages.InvalidNotifyConfiguration);
      return;
    }
    if (!this.checkValidationForNotify()) {
      return;
    }
    this.serveScreenWorkflowParameters.ItemReadyNotifyViaDJVoice = this.serveScreenWorkflowParameters.ItemReadyNotifyViaDJVoice ?? '';
    this.serveScreenWorkflowParameters.ItemReadyNotifyViaAudioNotifierVoice = this.serveScreenWorkflowParameters.ItemReadyNotifyViaAudioNotifierVoice ?? '';
    this.setMakeTableNotifications();
    this.spinnerService.show();
    this.serveScreenWorkflowService.updateServeScreenWorkflowParameters(this.serveScreenWorkflowParameters)
    .pipe(finalize(() => this.spinnerService.hide()))
    .subscribe({
      next: (response) => {
        this.alertService.renderSuccessMessage(Messages.ServeScreenConfigurationSaveSuccess);
        this.postSavedConfigurationService.showMessage();
      }, error: this.alertService.showApiError
    });
  }

  checkValidationForNotify = () => {
    if (this.serveScreenWorkflowParameters.ItemReadyShowNotifyButtonOnServeScreen) {
      if (this.chkSendViaDJ && (!this.serveScreenWorkflowParameters.ItemReadyNotifyViaDJTerminal || !this.serveScreenWorkflowParameters.ItemReadyNotifyViaDJVoice)) {
        return;
      }
      if (this.chkSendViaAudioNotifier && (!this.serveScreenWorkflowParameters.ItemReadyNotifyViaAudioNotifier ||
        !this.serveScreenWorkflowParameters.ItemReadyNotifyViaAudioNotifierVoice)) {
        return;
      }
    }
    return true;
  }

  setMakeTableNotifications() {
    if (!this.chkSendViaSMS || !this.serveScreenWorkflowParameters.ItemReadyShowNotifyButtonOnServeScreen) {
      this.serveScreenWorkflowParameters.ItemReadyNotifyViaSMSText = '';
    }
    if (!this.chkSendViaDJ || !this.serveScreenWorkflowParameters.ItemReadyShowNotifyButtonOnServeScreen) {
      this.serveScreenWorkflowParameters.ItemReadyNotifyViaDJTerminal = 0;
      this.serveScreenWorkflowParameters.ItemReadyNotifyViaDJText = '';
      this.serveScreenWorkflowParameters.ItemReadyNotifyViaDJVoice = '';
      this.serveScreenWorkflowParameters.ItemReadyNotifyViaDJVolume = 0;
    }
    if (!this.chkSendViaAudioNotifier || !this.serveScreenWorkflowParameters.ItemReadyShowNotifyButtonOnServeScreen) {
      this.serveScreenWorkflowParameters.ItemReadyNotifyViaAudioNotifier = 0;
      this.serveScreenWorkflowParameters.ItemReadyNotifyViaAudioNotifierText = '';
      this.serveScreenWorkflowParameters.ItemReadyNotifyViaAudioNotifierVoice = '';
      this.serveScreenWorkflowParameters.ItemReadyNotifyViaAudioNotifierVolume = 0;
    }
  }

  setCaretPosition(inputId) {
    const input: HTMLInputElement = <HTMLInputElement>document.getElementById(inputId);
    this.cursorPosition = input ? input.selectionStart : 0;
  }

  changeOrderVariable(selectedVariable, messageText, inputId) {
    this.isOpenVariableForSMS = false;
    this.isOpenVariableForDJTerminal = false;
    this.isOpenVariableForAudioNotifier = false;
    messageText = messageText ?? '';
    messageText = messageText.substring(0, this.cursorPosition) + ' {{' + selectedVariable + '}} '
      + messageText.substring(this.cursorPosition);
    if (inputId === 'smsText') {
      this.serveScreenWorkflowParameters.ItemReadyNotifyViaSMSText = messageText;
    } else if (inputId === 'djTerminalText') {
      this.serveScreenWorkflowParameters.ItemReadyNotifyViaDJText = messageText;
    } else if (inputId === 'audioText') {
      this.serveScreenWorkflowParameters.ItemReadyNotifyViaAudioNotifierText = messageText;
    }
    this.setCaretPosition(inputId);
    this.selectedOrderVariable = null;
  }

  cancel() {
    this.router.navigate(['..'], { relativeTo: this.route })
  }

}
