<div>
    <button (click)="cancelDialog()" class="close" type="button">&times;</button>
    <h4 class="modal-content__heading">Add new widget</h4>
</div>
<div class="px-pix-10 overflow-hidden">
    <form class="edit-form">
        <div class="widget-add-modal row mx-2 pt-3">
            <div class="col-sm-4 col-xs-2 mt-2 px-0" *ngFor="let w of widgets | keyvalue">
                <div class="mx-1 nav-link full-height py-2 thumbnail" (click)="addWidget(w.value, w.key)">
                    <div class="col-md-12">
                        <div class="widget-add-modal__block__widget__icon-block">
                            <pos-icon [icon]="w.value.Icon"></pos-icon>
                        </div>
                    </div>
                    <div class="col-md-12 p-0">
                        <div class="widget-add-modal__block__widget__title">{{w.value.Name}}</div>
                        <h6 class="text-center">{{w.value.Description}} </h6>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="col-12 action-button-container popup-footer pb-pix-10">
    <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="cancelDialog()" scrollup type="button"
        value="Cancel">Close</button>
</div>
<div class="clearfix"></div>
