<div style="width:100%">
  <form (ngSubmit)="addAlias(inventoryProductAlias)" autocomplete="off">
    <div class="col-12 pb-pix-3">
      <pos-row>
        <pos-column>
          <pos-form-field label="Alias">
            <div class="row">
              <div class="col-md-7 col-6">
                <input posInput type="text" id="inventoryProductAliasName" name="inventoryProductAliasName"
                  class="form-control" [(ngModel)]="inventoryProductAlias.AliasName" />
              </div>
              <div class="col-lg-4 col-md-2 col-6">
                <button class="btn btn-secondary normal pos-button vertical-center-align" type="submit"
                  [disabled]="!inventoryProductAlias.AliasName"><pos-icon [icon]="icons.normalPlus" class="font-12"></pos-icon> <span
                    class="ps-1">Add</span></button>
              </div>
            </div>
          </pos-form-field>
        </pos-column>
      </pos-row>
    </div>
  </form>
  <div class="col-md-7 col-lg-5 col-12 p-0 pt-pix-15">
    <ng-table #gridComponent [search]="false" [sort]="false" [paginator]="false"
      [columns]="inventoryProductAliasesColumn" [data]="inventoryProductAliases">
    </ng-table>
  </div>
</div>
