<div>
    <button class="close" data-dismiss="modal" type="button" (click)="hideModal()">
        <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
    </button>
    <h4 class="modal-content__heading">Promotion Summary</h4>
</div>
<div class="modal-body pos-modal-body pt-0 pb-0">
    <div class="col-12 p-pix-10 promotion mt-pix-5" *ngFor="let promotion of promotionGroup">
        <h3 class="bold">{{ promotion.OrderProducts[0].PromotionName }} <span class="float-end">{{
                promotion.TotalRewardAmount | tark_currency }}</span></h3>
        <div class="col-12 pe-0"
            *ngFor="let promotionDetail of promotion.OrderProducts">
            <div class="row g-0" *ngIf="promotionDetail.ProductName">
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 p-0"
                    [ngClass]="{'green': promotionDetail.IsRewardedItem}">
                    {{ promotionDetail.ProductName }}
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-end p-0"
                    [ngClass]="{'green': promotionDetail.IsRewardedItem}">
                    {{ promotionDetail.Amount | tark_currency }}
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 mt-pix-10 pe-pix-10 ps-pix-10">
        <h2 class="bold">Saving <span class="float-end">{{ totalSaved | tark_currency }}</span></h2>
    </div>
</div>
<div class="col-12 action-button-container popup-footer pb-pix-10">
    <button class="btn btn-primary btn-lg btn-cancel-modal white pos-button" (click)="hideModal()" type="button">OK</button>
</div>
<div class="clearfix"></div>
