import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';
import { AccountType } from '../../information-management/account/interface/account-type';
import { Observable } from 'rxjs';

@Injectable()
export class AccountTypeService extends BaseCrudService<AccountType> {

    accountTypeApiUrl = '';
    constructor(httpService: HttpClient) {
        super('accounttypes', httpService);
        this.accountTypeApiUrl = RuntimeConstants.API_PATH + 'accounttypes';
    }
    newAccountType() {
        return {
            Id: 0,
            AccountType: ''
        };
    }
    getAccountTypes(): Observable<AccountType[]> {
        return this.httpService.get<AccountType[]>(this.accountTypeApiUrl);
    }
}
