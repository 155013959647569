
import { InventoryProductService } from '../../../../shared/services/inventory-product.service';
import { GridColumn, TextAlign, TemplateColumn } from '@tarktech/tark-ng-utils';
import { Messages } from '../../../../shared/constants/ui-messages';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { AlertsService } from 'src/app/shared/services';
import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { SalesProductsService } from '../../services';
import { ModalComponent } from 'src/app/shared';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { StringUtils } from 'src/app/shared/string-utils/string-utils';
@Component({
  selector: 'pos-sales-inventory-activation',
  templateUrl: './sales-inventory-activation.component.html',
  styleUrls: ['./sales-inventory-activation.component.scss']
})
export class SalesInventoryActivationComponent extends ModalComponent implements OnInit {

  @Input()
  inventoryProducts: any = [];

  @Input()
  salesProductId: number = 0;

  @Input()
  salesProductName: string;

  @Input('headerText') headerText: '';

  @Input()
  activeProduct: boolean;

  @ViewChild('chkTemplate', {static: true}) private chkTemplate: any;

  inventoryProductsColumns: Array<GridColumn> = [];

  message: string;
  constructor(private activeModalRef: BsModalRef,
    private salesProductsService: SalesProductsService,
    private inventoryProductService: InventoryProductService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService) {
    super(activeModalRef);
  }

  ngOnInit() {
    if (this.activeProduct) {
      this.message = StringUtils.format(Messages.SalesInventoryProductDeactivate, { 'salesProduct': this.salesProductName });
    } else {
      _.forEach(this.inventoryProducts, (product) => {
        product.IsActive = true;
      });
      this.message = StringUtils.format(Messages.SalesInventoryProductActivate, { 'salesProduct': this.salesProductName });
    }

    this.columnConfiguration();
  }

  private columnConfiguration() {

    const chkColumn = new TemplateColumn({
      HeaderText: 'Select',
      itemTemplate: this.chkTemplate,
      Width: '70px',
      TextAlign: TextAlign.Center
    });

    this.inventoryProductsColumns = [
      chkColumn,
      new GridColumn({ HeaderText: 'Inventory Product', Field: 'name', IsSortable: true, Width: '92%' })
    ] as Array<GridColumn>;
  }

  // closeInventoryProducts() {
  //   this.onCancel.emit();
  // }

  activateDeactivateInventoryProducts(inventoryProducts) {
    this.spinnerService.show();
    this.inventoryProductService.activateInventoryProducts(!(this.activeProduct), inventoryProducts)
      .subscribe(res => {
        this.hide({ shouldSave: true });
      },
        this.alertService.showApiError,
        () => {
          this.spinnerService.hide();
        });
  }

  updateInventoryProducts() {
    let inventoryProducts = _.filter(this.inventoryProducts, (inventoryProduct) => {
      return inventoryProduct.IsActive;
    });
    if (inventoryProducts && inventoryProducts.length > 0) {
      if (!(this.activeProduct)) {
        this.spinnerService.show();
        this.salesProductsService.activateSalesProduct(this.salesProductId)
          .subscribe(res => {
            this.activateDeactivateInventoryProducts(inventoryProducts);
          },
            this.alertService.showApiError,
            () => {
              this.spinnerService.hide();
            });
      } else {
        this.activateDeactivateInventoryProducts(inventoryProducts);
      }
    }
    else {
      this.hide({ shouldSave: true });
    }
  }

  cancel() {
    this.hide({ shouldSave: false });
  }
}
