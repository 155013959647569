import { Route } from '@angular/router';
import { AuthGuard } from '../app-guard';
import { Levels, DomainConstants } from '../shared/constants';
import { OrderStatusComponent } from './order-status/order-status.component';

const ORDER_STATUS: Route = {
    path: '',
    canActivate: [AuthGuard],
    component: OrderStatusComponent,
    data: {
        permissions: [{ 'Name': DomainConstants.TerminalTypes.ORDER_STATUS.Permission, 'Level': Levels.Access }]
    }
};

export const ORDER_STATUS_STATES = [ORDER_STATUS];
