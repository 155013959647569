<pos-edit-wrapper [isPopup]="false"
    heading="{{id ? 'Edit ' : 'Add' }} {{waitTimeRuleType.DisplayName}}{{id ? ' Rule: ' + waitTimeRule.Name : ' Rule'}}"
    [headingIcon]="icon" (close)="cancel()">
    <form #formWaitTimeRule="ngForm" (ngSubmit)="validateRule()">
        <context-provider provide="props">
            <div class="col-12 container-fluid border-top">
                <ul class="nav nav-tabs inner-tab pos-nav-tabs pt-pix-5">
                    <li [ngClass]="{'active': selectedTab == tabList.Info }">
                        <a (click)="selectedTab = tabList.Info" class="blue" [ngClass]="{'required': info.invalid }">
                            <span>
                                <pos-icon class="theme-separator pe-pix-5" [icon]="icons.infoCircle"></pos-icon>
                            </span>
                            <span>Info</span>
                        </a>
                    </li>
                    <li [ngClass]="{'active': selectedTab == tabList.Rule }">
                        <a (click)="selectedTab = tabList.Rule" class="blue"
                            [ngClass]="{'required': ruleModelGroup.invalid || isAnyRuleEmpty}">
                            <span class="pe-pix-5">
                                <pos-icon class="theme-separator" [icon]="icons.clipboardList"></pos-icon>
                            </span> <span>Rule</span> </a>
                    </li>
                    <li [ngClass]="{'active': selectedTab == tabList.Adjustment }">
                        <a (click)="selectedTab = tabList.Adjustment" class="blue"
                            [ngClass]="{'required': adjustmentsModelGroup.invalid || isAnyAdjustmentEmpty}">
                            <span class="pe-pix-5">
                                <pos-icon class="theme-separator" [icon]="icons.clock"></pos-icon>
                            </span> <span>Adjustment</span> </a>
                    </li>
                </ul>
                <div class="overflow-auto-tabs screen-scroll min-height pt-pix-5 pos-tab-body">
                    <div [hidden]="selectedTab != tabList.Info" ngModelGroup="info" #info="ngModelGroup">
                        <pos-row>
                            <pos-column>
                                <pos-form-field label="Name" [validationMessages]="{required: 'Please enter name.'}">
                                    <input posInput name="productName" id="productName" #productName="ngModel"
                                        type="text" class="form-control" [(ngModel)]="waitTimeRule.Name"
                                        [required]="true" />
                                </pos-form-field>
                            </pos-column>
                            <pos-column>
                                <pos-form-field>
                                    <p-checkbox posInput name="active" #active="ngModel"
                                        [(ngModel)]="waitTimeRule.IsActive" binary="true" label="Active">
                                    </p-checkbox>
                                </pos-form-field>
                            </pos-column>
                        </pos-row>
                    </div>
                    <div [hidden]="selectedTab != tabList.Rule" class="pb-pix-10" ngModelGroup="ruleModelGroup"
                        #ruleModelGroup="ngModelGroup">
                        <pos-criteria [operators]="operators" [criteries]="rules" [(newRuleModel)]="ruleModel" [criteriaCaption]="'Rule'">
                        </pos-criteria>
                    </div>
                    <div [hidden]="selectedTab != tabList.Adjustment" class="pt-pix-5" ngModelGroup="adjustmentsModelGroup"
                        #adjustmentsModelGroup="ngModelGroup">
                        <pos-reward [rewards]="adjustments" [configuredRewards]="configuredAdjustments" [rewardCaption]="'Adjustment'" [isMultipleReward]="false"></pos-reward>
                    </div>
                </div>
            </div>
            <div class="col-12 action-button-container page-footer">
                <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" value="Save">Save</button>
                <button type="button" class="btn-secondary btn-lg btn btn-cancel pos-button" value="Cancel"
                    (click)="cancel()">Cancel</button>
            </div>
        </context-provider>
    </form>
</pos-edit-wrapper>
