<div *ngIf="!previewMode && isShowEditOption">
    <pos-action-icon class="edit-button mat-icon-button" style="z-index: 1000;" [icon]="icons.editWhite" (click)="emitEditWidget()"></pos-action-icon>
    <button type="button" mat-icon-button [matMenuTriggerFor]="menu" style="z-index: 1000;">
      <pos-action-icon [icon]="icons.cogWhite"></pos-action-icon>
    </button >

    <mat-menu #menu="matMenu" yPosition="below" overlapTrigger="false">
        <label class="ps-pix-10 font-small grey">Layer Index: {{ layerIndex ?? 1 }}</label>
        <button mat-menu-item (click)="copy()">
            <span>Copy</span>
        </button>
        <button mat-menu-item [disabled]="widgetType == groupWidgetName" (click)="gridsterItem.bringToFront(1)">
            <span>Bring To Front</span>
        </button>
        <button mat-menu-item [disabled]="widgetType == groupWidgetName" (click)="gridsterItem.sendToBack(1)">
            <span>Send To Back</span>
        </button>
        <button mat-menu-item (click)="deleteWidget()">
            <span>Delete</span>
        </button>
    </mat-menu>
</div>
<ng-template posMenuWidget></ng-template>
