import { Injectable } from '@angular/core';
import { RuntimeConstants, SettingParam } from 'src/app/shared';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class BrandingService {

  brandingApiBasePath = RuntimeConstants.API_PATH + 'branding';

  constructor(private httpClient: HttpClient) { }


  getBrandingDetail(terminalId: number): Observable<SettingParam> {
    return this.httpClient.get<SettingParam>(`${this.brandingApiBasePath}/${terminalId}`);
  }

  uploadLogo(logo: File): Observable<File> {
    const formData = this.prepareBrandingLogoImageForm(logo);
    return this.httpClient.post<File>(this.brandingApiBasePath + '/UploadLogo', formData);
  }

  prepareBrandingLogoImageForm(imageFile: File): FormData {
    const formData = new FormData();
    if (imageFile) {
      formData.append('file', imageFile);
    }
    return formData;
  }

  saveBranding(params, brandingFile: File): Observable<SettingParam> {
    const formData = this.prepareBrandingImageForm(params, brandingFile);
    return this.httpClient.post<SettingParam>(this.brandingApiBasePath, formData);
  }

  prepareBrandingImageForm(params, imageFile: File): FormData {
    const formData = new FormData();
    if (imageFile) {
      formData.append('file', imageFile);
    }
    formData.append('model', JSON.stringify(params));
    return formData;
  }
}
