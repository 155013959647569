<pos-list-wrapper [headingIcon]="icons.infolinksExternalLinkAlt" heading="Info Links" (close)="close()">
  <div class="row">
    <div class="col-12">
      <ng-table #infoLinkList [columns]="infoLinksListColumns" [exportFilename]="'info-links'" [data]="infoLinkDetailsList" [paginator]="false">
      </ng-table>
    </div>
  </div>
</pos-list-wrapper>
<pos-confirm-delete (onConfirmAcknowledge)="onConfirmAcknowledge()" #confirmDeleteOption>
</pos-confirm-delete>
<pos-print-table #printTableReference [hidden]="true"></pos-print-table>
