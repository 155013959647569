import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SalesCategory } from 'src/app/information-management/sales-categories/interface/sales-category';
import { BaseCrudComponent } from 'src/app/shared/components/base-crud.component';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { FormUtilityService } from 'src/app/shared/services/form-utility.service';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import { SalesCategoryService } from 'src/app/shared/services/sales-category.service';
import { SalesProduct } from '../../interface/sales-product';
import { SalesProductHistory } from '../../interface/sales-product-history';
import { SalesProductHistoryService } from '../../services/sales-product-history.service';
import { SalesProductsService } from '../../services/sales-products.service';
import { history } from 'src/app/shared/components/icon';
import { GridContextInfo } from 'src/app/shared/interface';

@Component({
  selector: 'pos-sales-product-history-edit',
  templateUrl: './sales-product-history-edit.component.html',
  styleUrls: ['./sales-product-history-edit.component.scss']
})
export class SalesProductHistoryEditComponent extends BaseCrudComponent<SalesProductHistory> implements OnInit {
  dateFormat = 'mm-dd-yy';
  hourFormat = '12';
  id: number;
  salesProductId: number;
  salesProductHistory: SalesProductHistory;
  salesProductName: string;
  salesCategoryList;
  today = new Date();
  @ViewChild('salesProductHistoryForm') salesProductHistoryForm: NgForm;
  props = {
    controlBoxClass: 'col-lg-3 col-md-4 col-sm-6 col-xs-12'
  }
  get getForm(): NgForm {
    return this.salesProductHistoryForm
  }
  icons = {
    history
  }
  salesProductGridContext: GridContextInfo = null;
  salesProductCategory: number = 0;
  salesProductActive: boolean = false;
  constructor(protected salesProductHistoryService: SalesProductHistoryService,
    protected salesCategoryService: SalesCategoryService,
    protected salesProductService: SalesProductsService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected applicationStateService: ApplicationStateService,
    protected route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    protected printService: PrintTableService,
    formUtilityService: FormUtilityService) {
    super(salesProductHistoryService, alertService, spinnerService, formUtilityService);
    this.salesProductId = parseInt(route.snapshot.params.salesProductId, 10) ?? 0;
    this.id = parseInt(route.snapshot.params.id, 10) ?? 0;
    this.dateFormat = this.applicationStateService.settingParam.PCalendarDateFormat;
    this.saveSuccessMessage = Messages.InventoryProductHistorySavedSuccess;
    this.salesProductHistory = this.salesProductHistoryService.getNewSalesProduct();
    const navigation = this.router.getCurrentNavigation();
    this.salesProductGridContext = navigation?.extras?.state?.GridContext ?? null;
    this.salesProductCategory = navigation?.extras?.state?.Category ?? null;
    this.salesProductActive = navigation?.extras?.state?.Active ?? null;
  }

  ngOnInit(): void {
    this.loadSalesProduct();
  }

  onCancel() {
    this.router.navigate([`manage/app-configuration/sales-products/${this.salesProductId}/sales-product-history`], { state: { GridContext: this.salesProductGridContext, Category: this.salesProductCategory, Active: this.salesProductActive } });
  }

  loadSalesProduct() {
    if (this.id) {
      this.spinnerService.show();
      this.loadData().pipe(finalize(() => {
        this.spinnerService.hide();
      })).subscribe({ next: (response) => {
        this.salesProductHistory = response;
        this.salesProductHistory.DateAdded = new Date(response.DateAdded);
        this.salesProductHistory.IsTimedPrep = this.salesProductHistory.IsTimedPrep ?? false;
        this.salesProductHistory.IsSkipReadyState = this.salesProductHistory.IsSkipReadyState ?? false;
      }, error: this.alertService.showApiError });
    }

  }

  loadDependencies() {
    this.spinnerService.show();
    const observable: Array<Observable<any>> = [];
    observable.push(this.salesCategoryService.getSalesCategories(true));
    observable.push(this.salesProductService.getSalesProduct(this.salesProductId));
    observable.push(this.salesCategoryService.getSalesCategories(false));
    forkJoin(observable).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (response: [Array<SalesCategory>, SalesProduct, Array<SalesCategory>]) => {
        this.salesCategoryList = response[0];
        this.salesProductName = response[1].Name;
        this.salesCategoryList = [...this.salesCategoryList, ...response[2]];
      },
      error: this.alertService.showApiError
    });
  }

  saveSalesProductHistory(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.spinnerService.show();
    if (!this.id) {
      this.salesProductHistory.SalesProductId = this.salesProductId;
    }

    this.salesProductHistoryService.insert(this.salesProductHistory).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({ next: (response) => {
      this.onSaveSuccess();
    }, error: this.alertService.showApiError });
  }

  onSaveSuccess() {
    this.onCancel();
    this.alertService.renderSuccessMessage(this.saveSuccessMessage);
  }


}
