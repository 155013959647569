import { Component, ViewChild } from '@angular/core';
import { KioskScreen } from '../../interfaces';
import { AlertsService, BaseCrudModelComponent, ConfirmDeleteModalComponent, DomainConstants, FormUtilityService, Messages, ModalService, OrderKioskScreenService, SpinnerService, StringUtils, tabletAlt } from 'src/app/shared';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { forEach, orderBy } from 'lodash';
import { Observable, forkJoin } from 'rxjs';

@Component({
  selector: 'pos-kiosk-screen-edit',
  templateUrl: './kiosk-screen-edit.component.html'
})
export class KioskScreenEditComponent extends BaseCrudModelComponent<KioskScreen> {

  @ViewChild('kioskScreenForm') kioskScreenForm: NgForm;
  id = 0;
  isPopup = false;
  screenName = '';
  templateTypes: Array<string> = [];
  kioskScreen: KioskScreen;
  screensList: Array<KioskScreen>;
  selectedTemplateType: string = null;
  icons = {
    tabletAlt
  }
  constructor(private kioskScreenService: OrderKioskScreenService,
    protected alertsService: AlertsService,
    protected spinnerService: SpinnerService,
    protected formUtilityService: FormUtilityService,
    private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super(kioskScreenService, alertsService, spinnerService, formUtilityService);
    this.id = !this.id && route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : this.id;
    this.kioskScreen = kioskScreenService.newScreen();
  }

  ngOnInit(): void {
    this.saveSuccessMessage = Messages.KioskScreenSaveSuccess;
    forEach(DomainConstants.KioskScreenTemplateTypes, type => {
      this.templateTypes.push(type);
    });
    this.templateTypes = orderBy(this.templateTypes);
  }

  onSaveSuccess(model: KioskScreen): void {
    this.cancel(model.Id);
  }

  loadDependencies(): void {
    this.spinnerService.show();
    const observables: Observable<any>[] = [];
    observables.push(this.kioskScreenService.getAll());
    if (this.id) {
      observables.push(this.loadData());
    }
    forkJoin(observables).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: ([kioskScreens, kioskScreen]: [Array<KioskScreen>, KioskScreen]) => {
        this.screensList = kioskScreens.filter(x => x.Id != this.id);
        if (this.id) {
          this.kioskScreen = kioskScreen;
          this.screenName = this.kioskScreen.Name;
          this.selectedTemplateType = this.kioskScreen.TemplateType;
        }
      }, error: this.alertService.showApiError
    });
  }

  submit(isValid: boolean) {
    if (!isValid) {
      return;
    }
    if (this.id > 0 && this.kioskScreen.TemplateType != this.selectedTemplateType) {
      this.openWarningModal();
    } else
      this.save(this.kioskScreen);
  }

  openWarningModal() {
    const modalRef = this.modalService.show(ConfirmDeleteModalComponent, {
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      initialState: {
        message: StringUtils.format(Messages.KioskScreenConfigChangeWarning, { type: 'template type' })
      }
    });
    modalRef.close.subscribe((res) => {
      if (res.shouldDelete) {
        this.kioskScreen.IsDeleteConfiguration = true;
        this.save(this.kioskScreen);
      }
    });
  }

  get getForm(): NgForm {
    return this.kioskScreenForm;
  }

  cancel(screenId: number = null) {
    if (this.isPopup) {
      this.hide({ screenId: screenId ?? this.id, screen: this.kioskScreen });
    } else {
      this.router.navigate(['screens'], { relativeTo: this.route.parent });
    }
  }

}
