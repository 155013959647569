import { Observable, interval as observableInterval } from 'rxjs';
import { UserTrainingLevel } from './../../../training-topic-levels/interface/user-training-level';
import { lock, checkCircle, trainingApproveCheckCircle, levelDown } from './../../../../shared/components/icon/icons';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalComponent } from 'src/app/shared/components/modal/modal-component';
import { ApplicationStateService } from './../../../../shared/services/application-state.service';
import { AlertsService, SpinnerService } from 'src/app/shared';
import { Component, OnInit } from '@angular/core';
import { TrainingTopicService } from 'src/app/shared/services/training-topic.service';
import { TrainingTopic } from '../../interface';
import * as _ from 'lodash';
import { TrainingTopicLevel } from 'src/app/information-management/training-topic-levels';
import { UserTrainingLevelService } from 'src/app/information-management/training-topic-levels/services/user-training-level.service';
import { TrainingLevelContentComponent } from 'src/app/training/training-approve/components/training-level-content/training-level-content.component';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { find } from 'lodash';

@Component({
  selector: 'pos-training-topics',
  templateUrl: './training-topic.component.html',
  styleUrls: ['./training-topic.component.scss']
})
export class TrainingTopicComponent extends ModalComponent implements OnInit {

  trainingTopics: Array<TrainingTopic>;

  trainingAreaId: number;
  trainingAreaName: string;
  topicName: string;
  enableLevelIndexFound = false;
  timerSubscribed: any;
  timer: Observable<number>;
  public icons = {
    lock,
    checkCircle,
    trainingApproveCheckCircle
  };
  trainingLevel: TrainingTopicLevel;
  constructor(private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private trainingTopicService: TrainingTopicService,
    private userTrainingLevelService: UserTrainingLevelService,
    private applicationStateService: ApplicationStateService,
    private levelModelService: ModalService,
    modalRef: BsModalRef) {
    super(modalRef);

  }

  ngOnInit() {
    this.getTrainingTopicList();
    this.trainingLevel = {
      Id: 0,
      LevelNumber: null,
      TrainingTopicId: null,
      Description: '',
      PrerequisiteText: '',
      VideoUrl: '',
      LevelScore: null,
      TrainingTopic: this.trainingTopicService.newTrainingTopic(),
      DateAdded: null,
      DateEdited: null,
      SuccessionLimitDelayMinutes: null
    };

  }

  getTrainingTopicList() {
    this.spinnerService.show();
    this.trainingTopicService.getTrainingTopicsByAreaId(this.trainingAreaId, this.applicationStateService.userDetails.id).subscribe(
      response => {
        this.trainingTopics = [];
        this.getTrainingListCompleted(response);
      }, this.alertService.showApiError,
      () => {
        this.spinnerService.hide();
      }
    );
  }

  getTrainingListCompleted(response) {
    this.enableLevelIndexFound = false;
    _.forEach(response, (topic, topicIndex: number) => {
      if (topic.TrainingArea.Id === this.trainingAreaId) {
        _.forEach(topic.TrainingTopicRoles, (role) => {
          const isRoleAvailable = find(this.applicationStateService.userDetails.Roles, (item) => {
            return item.RoleId === role.RoleId;
          });
          const isTopicAvailable = find(this.trainingTopics, (item) => item.Id === topic.Id &&
            item.TrainingAreaId === topic.TrainingAreaId);
          if (isRoleAvailable && !isTopicAvailable) {
            this.setTrainingTopics(response, topic, topicIndex);
          }
        });
      }
    });
    this.setNextLevelEnableTimer(true);
  }

  setTrainingTopics(response, topic, topicIndex) {
    let isNextLevelEnable = false;
    if (topicIndex > 0) {
      let i = 1;
      while (!response[topicIndex - i].TrainingTopicLevels.length && i < response.length && topicIndex > i) {
        i++;
      }
      const completedTrainingTopicLevels = _.filter(response[topicIndex - i].TrainingTopicLevels, (level) => {
        return level.IsEnabled;
      });

      if (completedTrainingTopicLevels?.length && response[topicIndex - i]?.TrainingTopicLevels.length == completedTrainingTopicLevels.length) {
        const level = response[topicIndex - i].TrainingTopicLevels[response[topicIndex - i].TrainingTopicLevels.length - 1];
        isNextLevelEnable = (level.UserTrainingLevels && level.UserTrainingLevels.length > 0 && (new Date() > new Date(new Date(level.UserTrainingLevels[0].StartedOn).getTime() + (level.SuccessionLimitDelayMinutes * 60000)))) ? true : false;
      }
    }
    this.setTrainingTopicLevels(topic, isNextLevelEnable);
    let approvedLevels = _.filter(topic.TrainingTopicLevels, (level) => {
      return level.isCompleted;
    });
    topic.ApprovedAllLevels = approvedLevels && approvedLevels.length == topic.TrainingTopicLevels.length ? true : false;
    this.trainingTopics.push(topic);
  }

  setTrainingTopicLevels(topic, isNextLevelEnable) {
    topic.TrainingTopicLevels = _.orderBy(topic.TrainingTopicLevels, 'LevelNumber');
    _.forEach(topic.TrainingTopicLevels, (level) => {
      level.isCompleted = false;
      if (level.UserTraining && level.UserTraining.length > 0) {
        level.isCompleted = true;
      }
      if ((level.UserTrainingLevels && level.UserTrainingLevels.length > 0) || isNextLevelEnable || (!this.enableLevelIndexFound)) {
        isNextLevelEnable = (level.UserTrainingLevels && level.UserTrainingLevels.length > 0 && (new Date() > new Date(new Date(level.UserTrainingLevels[0].StartedOn).getTime() + (level.SuccessionLimitDelayMinutes * 60000)))) ? true : false;
        level.IsEnabled = true;
        this.enableLevelIndexFound = true;
      } else {
        level.IsEnabled = false;
        isNextLevelEnable = false;
      }
    });
  }

  setTimerForEnableLevel(level, nextLevel, isRefresh) {
    if (level && level.UserTrainingLevels && level.UserTrainingLevels.length > 0 && level.SuccessionLimitDelayMinutes > 0) {
      let startOn = new Date(level.UserTrainingLevels[0].StartedOn);
      const openInterval = startOn.setMinutes(startOn.getMinutes() + level.SuccessionLimitDelayMinutes);
      let timerInterval = openInterval - (new Date().getTime());
      if (timerInterval > 0) {

        this.unsubscribeTimer();
        this.timer = observableInterval(timerInterval);
        this.timerSubscribed = this.timer.subscribe((t) => {
          nextLevel.IsEnabled = true;
          this.unsubscribeTimer();
        });
      }
    }
    else if (!level.SuccessionLimitDelayMinutes && !isRefresh) {
      nextLevel.IsEnabled = true;
    }
  }

  unsubscribeTimer() {
    if (this.timerSubscribed) {
      this.timerSubscribed.unsubscribe();
    }
  }

  setNextLevelEnableTimer(isRefresh) {
    let lastLevel: any;
    let isDisabledLevelFound = false;
    _.forEach(this.trainingTopics, (topic) => {
      _.forEach(topic.TrainingTopicLevels, (level: any) => {
        if (!level.IsEnabled && !isDisabledLevelFound) {
          this.setTimerForEnableLevel(lastLevel, level, isRefresh);
          isDisabledLevelFound = true;
          return false;
        }
        lastLevel = level;
      });
    });
  }

  checkOpenedLevelIsLastLevel(trainingLevel): boolean {
    let isDisabledLevelFound = false;
    let lastLevel = null;
    this.trainingTopics.forEach(topic => {
      if (isDisabledLevelFound) {
        return false;
      }
      topic.TrainingTopicLevels.forEach(level => {
        if (!level.IsEnabled) {
          isDisabledLevelFound = true;
          return false;
        }
        lastLevel = level
      });
    });
    return trainingLevel.Id === lastLevel?.Id;
  }

  trainingTopicLevelOpen(trainingLevel, topic) {
    if (trainingLevel.IsEnabled) {
      const modalRef = this.levelModelService.show(TrainingLevelContentComponent, {
        animated: false,
        class: 'vertical-center modal-max-width-65',
        initialState: {
          trainingLevel: trainingLevel,
          topicName: topic.Name,
          trainingAreaName: this.trainingAreaName
        }
      });
      modalRef.close.subscribe(res => {
        if (res?.shouldReload && this.checkOpenedLevelIsLastLevel(trainingLevel)) {
          this.spinnerService.show();
          this.trainingTopicService.getTrainingTopicsByAreaId(this.trainingAreaId, this.applicationStateService.userDetails.id)
            .subscribe({
              next: response => {
                _.forEach(response, (responseTopic) => {
                  _.forEach(this.trainingTopics, (topic) => {
                    _.forEach(responseTopic.TrainingTopicLevels, (responseTopicLevel) => {
                      _.forEach(topic.TrainingTopicLevels, (topicLevel: any) => {
                        if (topicLevel.Id == responseTopicLevel.Id) {
                          topicLevel.UserTrainingLevels = responseTopicLevel.UserTrainingLevels;
                        }
                      });
                    });
                  });
                });
                this.setNextLevelEnableTimer(false);
              }, error: this.alertService.showApiError,
              complete: () => {
                this.spinnerService.hide();
              }
            });
        }
      });
      let userTrainingLevel: UserTrainingLevel = {
        Id: 0,
        UserId: this.applicationStateService.userDetails.id,
        LevelId: trainingLevel.Id,
        StartedOn: new Date(),
        DateAdded: new Date(),
        DateEdited: null
      }
      this.userTrainingLevelService.saveUserTrainingLevel(userTrainingLevel)
        .subscribe({
          next: () => {
            // This is intentional
          }, error: this.alertService.showApiError
        });
    }
  }

  onCancel(reload: boolean) {
    this.unsubscribeTimer();
    this.hide({ shouldReload: reload });
  }
}
