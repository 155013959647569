<pos-list-wrapper [headingIcon]="icons.salesGroupCommentsDollar" heading="Sales Groups" (close)="close()">
  <div class="row">
    <div class="col-12">
      <ng-table id="SalesGroup" [sort]="true" #salesGroupGrid [columns]="salesGroupsColumns" [data]="salesGroups"
        [exportFilename]="'sales-groups'">
      </ng-table>
      <ng-template let-data="rowData" #makeTableColorColumn>
        <div *ngIf="(data.make_table_color)" class="hg-20 col-20 div-center"
          [ngStyle]="{'background-color': data && data.make_table_color ? data.make_table_color : ''}">
        </div>
      </ng-template>
    </div>
  </div>
</pos-list-wrapper>
<pos-print-table [hidden]="true"></pos-print-table>
