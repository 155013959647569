<pos-edit-wrapper heading='Google Pay' (close)="onClose()">
    <form #googlePayConfigurationForm="ngForm" (ngSubmit)="next()">
        <div class="col-12 pos-modal-body screen-scroll pt-pix-15" style="height: 260px;">
            <pos-row>
                <pos-column>
                    <p-toast></p-toast>
                    <p-steps [model]="items" [(activeIndex)]="activeIndex"></p-steps>
                </pos-column>

                <pos-column class="pe-0 pt-pix-10" [hidden]="activeIndex != 0" #signupFormGroup="ngModelGroup" ngModelGroup="signup">
                    <pos-row class="ps-4">
                        <pos-column>
                            <ul>
                                <li class="list-style-type-disc"><span>Sign up for <em><a rel="noopener" target="_blank" href="https://pay.google.com/business/console/">Google Pay for Business</a></em>.</span></li>
                                <li class="pt-1"><span>Use the Google Account you'd like to use to accept payments for your business. If you don't have a Google Account, create one.</span></li>
                            </ul> 
                        </pos-column>
                    </pos-row>
                </pos-column>
                
                <pos-column class="pt-pix-10 pe-0" [hidden]="activeIndex != 1" #merchantInfoFormGroup="ngModelGroup" ngModelGroup="merchantInfo">
                    <pos-row class="ps-4">
                        <pos-column>
                            <ul>
                                <li class="list-style-type-disc">
                                    <span> After setting up the business profile on <em><a rel="noopener" target="_blank" href="https://pay.google.com/business/console/home">Google Pay Business home</a></em> you can find merchant Id on top right corner.</span>
                                </li>
                                <li class="list-style-type-disc pt-1"><span>Enter Merchant Id and Merchant Name here:</span></li>
                            </ul>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Merchant Id">
                                <input type="text" posInput class="form-control" name="MerchantId"
                                    [(ngModel)]="configuration.MerchantId" [required]="true" #merchantIdElement="ngModel">
                                <span class="has-error" *ngIf="merchantIdElement.invalid && merchantIdElement.touched">
                                    <label class="control-label" *ngIf="merchantIdElement.errors?.required">Please enter merchant Id.</label>
                                </span>
                            </pos-form-field>
                        </pos-column>
        
                        <pos-column>
                            <pos-form-field label="Merchant Name">
                                <input type="text" posInput class="form-control" name="MerchantName"
                                    [(ngModel)]="configuration.MerchantName" [required]="true"  #merchantNameElement="ngModel">
                                <span class="has-error" *ngIf="merchantNameElement.invalid && merchantNameElement.touched">
                                    <label class="control-label" *ngIf="merchantNameElement.errors?.required">Please enter merchant Id.</label>
                                </span>
                            </pos-form-field>
                        </pos-column>
                    </pos-row>
                </pos-column>

                <pos-column class="pt-pix-10 pe-0" [hidden]="activeIndex != 2" #gatewayInfoFormGroup="ngModelGroup" ngModelGroup="gatewayInfo">
                    <pos-row class="ps-4">

                        <pos-column>
                            <ul>
                                <li class="list-style-type-disc">
                                    <span>Log in to <em><a rel="noopener" target="_blank" href="https://login.authorize.net/">Authorize.net Merchant Interface</a></em></span>
                                </li>
                                <li class="list-style-type-disc pt-1">
                                    <span>Navigate to <em>Accounts > User Administration</em>.</span>
                                </li>
                                <li class="list-style-type-disc pt-1">
                                    <span>You can find <em><b>Payment Gateway Id</b></em> just below the user name.</span>
                                </li>
                            </ul>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Payment Gateway Id">
                                <input type="text" posInput class="form-control" name="PaymentGatewayId"
                                    [(ngModel)]="configuration.PaymentGatewayId" [required]="true" #gatewayMerchantElement="ngModel">
                                <span class="has-error" *ngIf="gatewayMerchantElement.invalid && gatewayMerchantElement.touched">
                                    <label class="control-label" *ngIf="gatewayMerchantElement.errors?.required">Please enter merchant Id.</label>
                                </span>
                            </pos-form-field>
                        </pos-column>

                    </pos-row>
                </pos-column>


            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="button" class="btn btn-lg btn-secondary pos-buttton btn-cancel-modal ms-pix-10 float-start" *ngIf="activeIndex > 0" 
                (click)="activeIndex = activeIndex - 1">Back</button>

            <button type="button" class="btn btn-lg btn-primary btn-save pos-button" (click)="next()" *ngIf="this.activeIndex != lastIndex"
                [disabled]="this.activeIndex == 1 && merchantInfoFormGroup.invalid">
                Next
            </button>
            <button type="submit" class="btn btn-lg btn-primary btn-save pos-button" *ngIf="this.activeIndex == lastIndex"
                [disabled]="this.activeIndex == lastIndex && gatewayInfoFormGroup.invalid">
                Submit
            </button>
            <button class="btn btn-lg btn-secondary  btn-cancel-modal pos-button" type="button" (click)="onClose()">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
