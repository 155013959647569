import { code } from '../../../../shared/components/icon/icons';
import { SpinnerService, AlertsService, DomainConstants, ModalFormComponent, ModalService, FormUtilityService } from 'src/app/shared';
import { ExternalApiService } from '../../../../shared/services/external-api.service';
import { ExternalApiParameter } from '../../../../configurator/external-api/interface/external-api';
import { EventEmitter, OnChanges, ViewChild } from '@angular/core';
import { Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ExternalApi } from '../../../../configurator/external-api/interface';
import * as _ from 'lodash';
import { NgForm } from '@angular/forms';
import { EventSubscriptionActionAttribute } from '../../interfaces/event-subscription-action-attribute';
import { find } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RepeatActionConfigurationComponent } from '../repeat-action-configuration';

@Component({
  selector: 'pos-select-external-api',
  templateUrl: './select-external-api.component.html',
  styleUrls: ['./select-external-api.component.scss']
})
export class SelectExternalApiComponent extends ModalFormComponent implements OnInit, OnChanges {
  get getForm(): NgForm {
    return this.externalAPIActivityEditForm;
  }
  @Input()
  externalApis: Array<ExternalApi> = [];
  @Input()
  isDisabled = false;
  @Input()
  eventAttributes: Array<string> = [];
  @Input()
  selectedExternalApi: number = null;

  apiDelay = 0;
  selectedExternalApiParams = [];
  selectedEventAttribute: string;
  selectedVariableId = 0;
  @ViewChild('RepeatActionConfiguration') repeatActionConfiguration: RepeatActionConfigurationComponent;
  @Output()
  externalApi = new EventEmitter<number>();
  @Output()
  externalApiParams = new EventEmitter<string>();
  @Output()
  externalApiDelay = new EventEmitter<number>();

  externalApiParameters: Array<ExternalApiParameter> = [];
  apiParameters: any = [];
  apiParamsString: string;

  @ViewChild('externalAPIActivityEditForm', { static: true }) externalAPIActivityEditForm: NgForm;
  @Input() actionId = 0;
  @Input() eventSubscriptionActionAttributes: Array<EventSubscriptionActionAttribute> = [];
  @Input() deliveryTypes = [];
  selectedDeliveryType;
  @Input() eventIdentifier: string;
  @Input() isRecoverableEvent;
  selectedTask: number;
  deliveryAttributes = DomainConstants.EventDeliveryAttributes;

  public icons = {
    code
  };
  ngOnChanges(event) {
    if (this.selectedExternalApi) {
      this.getParameters(this.selectedExternalApi);
    } else {
      this.selectedExternalApi = null;
    }
    if (this.eventAttributes) {
      this.eventAttributes = _.sortBy(this.eventAttributes);
    }
  }

  constructor(private externalApiService: ExternalApiService,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    modalRef: BsModalRef, modalService: ModalService, protected formUtilityService: FormUtilityService) {
    super(modalRef, modalService, formUtilityService);
  }

  ngOnInit() {
    const selectedExternalApi = parseInt(find(this.eventSubscriptionActionAttributes,
      x => x.AttributeKey === this.deliveryAttributes.ExternalAPIDelivery.ExternalApi)?.AttributeValue, 10);
    this.selectedExternalApi = isNaN(selectedExternalApi) ? null : selectedExternalApi;
    if (this.eventSubscriptionActionAttributes) {
      const externalApiParams = find(this.eventSubscriptionActionAttributes,
        x => x.AttributeKey === this.deliveryAttributes.ExternalAPIDelivery.ApiParameters);

      if (externalApiParams) {
        this.selectedExternalApiParams = JSON.parse(externalApiParams.AttributeValue);
      }
    }
    const apiDelay = parseInt(find(this.eventSubscriptionActionAttributes,
      x => x.AttributeKey === this.deliveryAttributes.ExternalAPIDelivery.ExternalApiDelay)?.AttributeValue, 10);
    this.apiDelay = isNaN(apiDelay) ? null : apiDelay;
    if (this.selectedExternalApi) {
      this.getParameters(this.selectedExternalApi);
    }
  }

  changeEventAttribute() {
    if (this.selectedEventAttribute) {
      _.forEach(this.externalApiParameters, (param) => {
        if (param.Id === this.selectedVariableId) {
          param.ParamValue = param.ParamValue.substring(0, param.CursorPosition) + ' {{' + this.selectedEventAttribute + '}} '
            + param.ParamValue.substring(param.CursorPosition);
          param.CursorPosition += this.selectedEventAttribute.length + 5;
        }
      });
    }
    this.selectedVariableId = null;
    this.selectedEventAttribute = undefined;
    this.selectedVariableId = null;
    this.parameterChange();
  }

  getCaretPos(externalApiParam: ExternalApiParameter, textareaRef: any) {
    if (textareaRef.selectionStart || textareaRef.selectionStart === '0') {
      externalApiParam.CursorPosition = textareaRef.selectionStart;
    }
  }

  changeDelay() {
    this.externalApiDelay.emit(this.apiDelay);
  }

  parameterChange() {
    this.apiParameters = [];
    _.forEach(this.externalApiParameters, (param) => {
      this.apiParameters.push({ ParameterId: param.Id, Value: param.ParamValue });
    });
    this.apiParamsString = JSON.stringify(this.apiParameters);
    this.externalApiParams.emit(this.apiParamsString);
  }

  getParameters(id) {
    if (id > 0) {
      this.spinnerService.show();
      this.externalApiService.getExternalApi(id).subscribe(
        (response: ExternalApi) => {
          if (response.Parameters && response.Parameters.length) {
            this.externalApiParameters = _.filter(response.Parameters, (param) => {
              return !param.ParamValue;
            });
            _.forEach(this.externalApiParameters, (param) => {
              _.forEach(this.selectedExternalApiParams, (eventParam) => {
                if (eventParam.ParameterId === param.Id) {
                  param.ParamValue = eventParam.Value;
                }
                param.CursorPosition = param.ParamValue?.length ?? 0;
              });
            });
          } else {
            this.externalApiParameters = [];
          }
        },
        this.alertService.showApiError,
        () => {
          this.spinnerService.hide();
        });
    } else {
      this.externalApiParameters = [];
    }
  }

  openVariables(rowData) {
    this.selectedVariableId = this.selectedVariableId === rowData.Id ? null : rowData.Id;
    this.selectedEventAttribute = undefined;
  }

  changeExternalApis() {
    this.selectedVariableId = null;
    this.externalApi.emit(this.selectedExternalApi);
    this.getParameters(this.selectedExternalApi);
  }

  focusOnParam() {
    this.selectedVariableId = null;
  }

  saveActivity = (isValid: boolean) => {
    if (isValid) {
      const actionAttributes = this.prepareEventActionAttributes();
      this.hide({ shouldReload: true, actionAttributes: actionAttributes });
    }
  }

  onClose = (shouldReload: boolean) => {
    this.selectedVariableId = null;
    this.hide({ shouldReload: shouldReload });
  }

  prepareEventActionAttributes() {
    const actionDeliveryAttributes: Array<EventSubscriptionActionAttribute> = [];
    this.addAttribute(this.deliveryAttributes.ExternalAPIDelivery.ExternalApiDelay, this.apiDelay?.toString(),
      actionDeliveryAttributes);
    this.addAttribute(this.deliveryAttributes.ExternalAPIDelivery.ExternalApi, this.selectedExternalApi?.toString(),
      actionDeliveryAttributes);
    this.addAttribute(this.deliveryAttributes.ExternalAPIDelivery.ApiParameters, this.apiParamsString, actionDeliveryAttributes);
    if(this?.repeatActionConfiguration?.repeatConfig) { 
      this.addAttribute(this.deliveryAttributes.ExternalAPIDelivery.Repeat, this?.repeatActionConfiguration?.isRepeat ? JSON.stringify(this?.repeatActionConfiguration?.repeatConfig) : "", actionDeliveryAttributes)
    }
    return actionDeliveryAttributes;
  }

  addAttribute = (key, value, array) => {
    const actionDeliveryAttribute: EventSubscriptionActionAttribute = {
      Id: this.getActionAttributeId(key),
      AttributeKey: key,
      AttributeValue: value,
      EventSubscriptionActionId: this.actionId
    };
    if (actionDeliveryAttribute.Id > 0 ||
      (!actionDeliveryAttribute.Id && actionDeliveryAttribute.AttributeValue)) {
      array.push(actionDeliveryAttribute);
    }
  }

  getActionAttributeId(key) {
    const attribute = find(this.eventSubscriptionActionAttributes, x => x.AttributeKey === key);
    return attribute ? attribute.Id : 0;
  }
}
