import { Injectable } from '@angular/core';
import { SalesUnit } from '../../../shared/interface/sales-units';
import { HttpClient } from '@angular/common/http';
import { RuntimeConstants } from 'src/app/shared/constants';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';

@Injectable({
  providedIn: 'root'
})
export class SalesUnitService extends BaseCrudService<SalesUnit> {

  salesUnitApiBasePath = `${RuntimeConstants.API_PATH}salesUnits`;

  constructor(httpService: HttpClient) {
    super('salesUnits', httpService);
  }

  newSalesUnit() {
    const salesUnit: SalesUnit = {
        Id: 0,
        Name: null,
        AbbreviationName: null,
        IsShowInOnlineOrdering: true
    };
    return salesUnit;
  }
}
