import { Component, OnInit, EventEmitter } from '@angular/core';
import { OrderInvoice } from '../../interface';
import { ApplicationStateService, DomainConstants, ICloseable, RabbitMQService } from 'src/app/shared';
import { exclamationCircle, checkCircleSolid } from 'src/app/shared/components/icon';
import { Subscription } from 'rxjs';
declare let $: any;
@Component({
  selector: 'pos-review-order',
  templateUrl: './review-order.component.html',
  styleUrls: ['./review-order.component.scss']
})
export class ReviewOrderComponent implements OnInit, ICloseable {

  close: EventEmitter<any> = new EventEmitter();
  constructor(private rabbitMQService: RabbitMQService,
    private applicationStateService: ApplicationStateService,
  ) { }
  orderItemTypes = DomainConstants.OrderItemTypes;
  orderData: OrderInvoice;
  confirmOrderHeight: number;
  seats: any = [];
  icons = {
    exclamationCircle, checkCircleSolid
  };

  closeReviewModalSubscription: Subscription;

  ngOnInit() {
    this.confirmOrderHeight = $(window).height() - 150;
    $(window).resize(() => {
      this.confirmOrderHeight = $(window).height() - 150;
    });
    this.closeReviewModalSubscription = this.rabbitMQService.subscribeToCloseReviewOrderModalMessage$(this.applicationStateService.terminalId)
    .subscribe({
      next: (message: any) => {
        this.closeReviewModalSubscription?.unsubscribe();
        this.closeModal(message.Payload.IsConfirmed);
      },
      error: () => console.log('error while connecting to RabbitMQ.')
    });
  }

  invoiceConfirmation(isConfirmed = false) {
    this.closeReviewModalSubscription?.unsubscribe();
    this.rabbitMQService.sendCloseReviewOrderMessage(this.applicationStateService.terminalId, isConfirmed);
    this.closeModal(isConfirmed);
  }

  closeModal(isConfirmed = false) {
    this.close.emit({isConfirmed: isConfirmed});
  }
}
