import { Injectable } from '@angular/core';
import { GroupWidgetStyleConfig, ImageWidgetStyleConfig } from '../interfaces';
import { BorderStyleConfig, FontStyleConfig } from 'src/app/shared';

@Injectable({
  providedIn: 'root'
})
export class MenuWidgetStyleService {

  constructor() { }

  getFontStyle(fontStyleConfig: FontStyleConfig, defaultSize: string = null, defaultColor: string = null): any {
    if (fontStyleConfig) {
      return fontStyleConfig;
    }
    return '';
  }

  getBorderStyle(borderStyle: BorderStyleConfig): any {
    if (borderStyle) {
      // border-style should be last other wise it will inherit properties of other properties like border-left
      return {
        "borderTop": borderStyle.borderTop,
        "borderBottom": borderStyle.borderBottom,
        "borderLeft": borderStyle.borderLeft,
        "borderRight": borderStyle.borderRight,
        "borderColor": borderStyle.borderColor,
        "borderRadius": borderStyle.borderRadius,
        "borderStyle": borderStyle.borderStyle,
      };
    }
    return '';
  }

  getImageStyle(imageStyle: ImageWidgetStyleConfig) {
    return {
      'height': imageStyle.Width,
      'width': imageStyle.Height,
    };
  }

  getBulletStyle(bulletType: string, fontSize = null): any {
    if (bulletType) {
      return {
        'display': 'list-item',
        'listStyleType': bulletType,
        'marginLeft': fontSize ?? '12px',
        'fontSize': fontSize,
        'line-height': fontSize,
      };
    }
    return null;
  }

  getLineHeight(fontSize = null) {
    return {
      'fontSize': fontSize,
      'lineHeight': fontSize
    };
  }

  getWidgetContentStyle(widgetStyle): any {
    return {
      'background-color': widgetStyle?.WidgetBackgroundColor,
      'align-items': widgetStyle?.Vertical === 'top' ? 'start' : (widgetStyle?.Vertical === 'bottom' ? 'end' : 'center'),
      'justify-content': widgetStyle?.Horizontal,
      'width': widgetStyle?.Width,
      // 'display': widgetStyle?.Display
    };
  }

  getGroupWidgetStyle(widgetStyle: GroupWidgetStyleConfig, isPreviewMode = false) {
    const borderConfig = this.getBorderStyle(widgetStyle.BorderStyle);
    return {
      'background-color': isPreviewMode ? 'transparent' : '#78ea30aa',
      ...borderConfig
    }
  }
}
