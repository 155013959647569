import { Component, OnInit, EventEmitter } from '@angular/core';
import { ICloseable, ApplicationStateService, DomainConstants, SpinnerService, AlertsService, ModalService } from 'src/app/shared';
import { InboxMessageService } from '../../services';
import { InboxMessagesEditComponent } from '../inbox-messages-edit';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
@Component({
  selector: 'pos-view-message',
  templateUrl: './view-message.component.html',
})
export class ViewMessageComponent implements OnInit, ICloseable {

  terminalMessageModel: any = {}
  isOrderEntryTypeTerminal: boolean = false;
  isRequiredMarkAsRead: boolean;
  isSentMessage = false;
  close: EventEmitter<any> = new EventEmitter();
  sanitizedHtml: SafeHtml;
  constructor(private applicationStateService: ApplicationStateService,
    private spinnerService: SpinnerService,
    private inboxMessageService: InboxMessageService,
    private modalService: ModalService,
    private sanitizer: DomSanitizer,
    private alertService: AlertsService) { }
  ngOnInit() {
    if (this.applicationStateService.terminalType == DomainConstants.TerminalTypes.ORDER_ENTRY.Name) {
      this.isOrderEntryTypeTerminal = true;
    }
    this.sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(this.terminalMessageModel.MessageText);
  }

  closeDialog() {
    this.close.emit({ reload: false });
  }

  markAsReadOrUnread(data) {
    this.spinnerService.show();
    let observable = null;
    this.terminalMessageModel.FromUserId = this.applicationStateService.userDetails.id;
    this.terminalMessageModel.FromTerminalId = this.applicationStateService.terminalId;
    this.terminalMessageModel.Id = data.Id;
    if (this.terminalMessageModel.IsViewed) {
      observable = this.inboxMessageService.markAsUnread(this.terminalMessageModel);
    } else {
      observable = this.inboxMessageService.markAsRead(this.terminalMessageModel);
    }
    observable.subscribe(
      res => {
        this.close.emit({ reload: true });
      },
      this.alertService.showApiError,
      () => {
        this.spinnerService.hide();
      }
    );

  }

  replyMessage(data: any) {
    const inboxMessageModalRef = this.modalService.show(InboxMessagesEditComponent, {
      animated: false,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: false,
      class: 'vertical-center modal-lg',
      initialState: {
        inboxMessage: data,
      }
    });
    inboxMessageModalRef.close.subscribe(res => {
      this.close.emit({ reload: true });
    });

  }
}
