<pos-edit-wrapper heading="{{isTypeAccount ? settingParam.LoyalAccountTerm : accountTerm}} Selection"
  (close)="closeModal()">
  <div class="row mx-0">
    <div class="col-12 overflow-auto-tabs">
      <div class="col-12 p-0 row mx-0 pt-pix-10 p-pix-5 align-items-center">
        <div class="col-md-8 col-sm-7 col-9 ps-0">
          <input class="form-control" name="AccountName" [(ngModel)]="searchAccount" type="text"
            placeholder="Search {{isTypeAccount ? settingParam.LoyalAccountTerm : accountTerm}}..." />
        </div>
        <div class="col-md-1 col-sm-2 col-3 pe-0">
          <pos-action-icon (click)="addNewAccount()" [icon]="icons.plusWhite" iconText="Add"
            permissions='[{"Name":"{{permission.Name}}", "Level":"{{permission.EditLevel}}"}]'></pos-action-icon>
        </div>
        <div *ngIf="settingParam.IsEnableLoyaltyCardProgram" class="col-md-3 col-12 p-md-0 me-xs-3 float-end">
          <button type="button" class="my-1 btn bg-theme-separator float-end white normal text-nowrap pos-button"
            (click)="addAccountForLoyaltyCard()" *ngIf="isTypeAccount">
            <pos-icon [icon]="icons.loyaltyCreditCard"></pos-icon> Loyalty Card
          </button>
        </div>
      </div>
      <div class="col-12 order-management__order-list mt-pix-10 p-pix-5 min-height">
        <div class="row mx-0">
          <div class="col-md-4 col-sm-6 col-12 ps-0 pe-pix-10 cust-account" (click)="accountSelect(account)"
            *ngFor="let account of accounts| customDeepFilter: searchAccount: 'SearchableAccountAttributes': 'AttributeValue'; let j = index">
            <div class="media account-list row mx-0 relative"
              [ngStyle]="{'min-height': isTypeAccount ? accountMaxHeight + 'px' : null }"
              [ngClass]="{'account-border-primary': account.Id == (selectedAccountId?selectedAccountId.Value:0)}">
              <div class="col-12 p-0" [class.mb-pix-30]="!isTypeAccount || !account.SearchableAccountAttributes?.length">
                <h4 class="text-ellipsis"><b> {{account.Name}}</b></h4>
                <hr class="mb-pix-5 mt-pix-2">
                <div class="text-ellipsis">
                  <pos-icon class="d-inline-block w-pix-15 text-center" [icon]="icons.accountTelephone"></pos-icon>
                  {{account.Phone && account.Phone != '' ? (account.Phone | phoneMaskPipe) : 'N/A'}}
                </div>
                <div class="text-ellipsis">
                  <pos-icon class="d-inline-block w-pix-15 text-center" [icon]="icons.mapMarkerAlt"></pos-icon>
                  {{account.Address && account.Address != '' ? account.Address : 'N/A'}}
                </div>
                <div *ngIf="account.SearchableAccountAttributes">
                  <div *ngFor="let attribute of account.SearchableAccountAttributes; let i=index">
                    <div class="text-ellipsis"><b>{{attribute.AttributeName}}:</b> {{attribute.AttributeValue}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 row mx-0 row-cols-2 px-0 align-items-end">
                <div class="col-6 px-0" (click)="onPrintAccountDetails(account.Id, $event)">
                  <button type="button" class="btn btn-secondary btn-sm normal pos-button float-start">
                    <pos-icon [icon]="icons.accountPrint"></pos-icon> Print
                  </button>
                </div>
                <div class="col-6 px-0" (click)="openAccountDetails(account, $event)">
                  <button type="button" class="btn btn-secondary btn-sm normal pos-button float-end">
                    <pos-icon [icon]="icons.memberMoreDetails"></pos-icon> More
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 action-button-container popup-footer">
    <button type="button" class="btn btn-secondary btn-lg btn-cancel-modal pos-button pos-button"
      (click)="closeModal()">Cancel</button>
  </div>
</pos-edit-wrapper>
