import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthenticationService } from './authentication.service';
import * as moment from 'moment';
import { ApplicationStateService } from '../../services/application-state.service';
import { tap, map } from 'rxjs/operators';

const RENEW_TOKEN_BEFORE_SECONDS = 30;

@Injectable()
export class TokenService {
  private isTokenRequested = false;
  private authTokenPromise: Promise<string>;

  constructor(
    private authenticationService: AuthenticationService,
    private applicationStateService: ApplicationStateService,
  ) {
  }

  async getAsyncToken() {
    if (!this.applicationStateService.isAuthenticated) {
      return null;
    }

    const token = this.applicationStateService.authTokenInfo ? this.applicationStateService.authTokenInfo.Token : null;
    const jwtHelper = new JwtHelperService();

    if (!token ||
      jwtHelper.isTokenExpired(token) ||
      moment(jwtHelper.getTokenExpirationDate(token)).diff(
        moment(), 'seconds'
      ) <= RENEW_TOKEN_BEFORE_SECONDS
    ) {
      if (!this.isTokenRequested) {
        const password = this.applicationStateService.userCode;
        const userName = this.applicationStateService.username;
        const externalPassword = this.applicationStateService.externalPassword;
        this.isTokenRequested = true;

        this.authTokenPromise = this.authenticationService.authenticate(password, userName, externalPassword)
          .pipe(tap(res => {
            this.applicationStateService.authTokenInfo = res;
            this.isTokenRequested = null;
          }))
          .pipe(map(res => res.Token))
          .toPromise();
      }
      
      return this.authTokenPromise;
    }

    return token;
  }
}
