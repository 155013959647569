<div class="grey">
    <span *ngIf="isLoad">Loading...</span>

    <h3 class="edit-form__section__heading popover-header mb-pix-10" *ngIf="!isLoad">Inventory Product(s)</h3>
    <div class="screen-scroll" *ngIf="!isLoad">
        <ul>
            <li *ngFor="let detail of inventoryInfoModelList">{{detail.Name}}</li>
            <li *ngIf="inventoryInfoModelList.length == 0" style="list-style-type: none"> No Product</li>
        </ul>
    </div>
</div>
