import { MessagePayload } from './message-payload';

export class HighlightMenuItemsPayload extends MessagePayload {

    public ProductId?: number;
    public ActionType: string;

    constructor(productId?: number, actionType: string = null) {
        super();
        this.ProductId = productId;
        this.ActionType = actionType;
    }
}
