import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivityReportPayload } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class HicDashboardService {

  constructor(private httpService: HttpClient) { }

  getServiceInfo(serviceApiUrl: string) {
    return this.httpService.get(serviceApiUrl + '/serviceinfo');
  }

  getActivities(activityCount, serviceApiUrl: string): Observable<Array<ActivityReportPayload>> {
    return this.httpService.get<Array<ActivityReportPayload>>(`${serviceApiUrl}/activities`, { params: {'count': activityCount} });
  }

  checkServiceStatus(healthUrl) {
    return this.httpService.get(`${healthUrl}/ping`);
  }
}
