import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pos-terminal-login-icon',
  templateUrl: './terminal-login-icon.component.html',
  styleUrls: ['./terminal-login-icon.component.scss']
})
export class TerminalLoginIconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
