<pos-edit-wrapper heading="Configure Product Build Queue" (close)="onCancel()">
    <form #productQueueForm="ngForm" (ngSubmit)="saveProductBuildQueue(productQueueForm.valid)" autocomplete="off">
        <div class="form-horizontal screen-scroll">
            <div class="col-12 pos-modal-body">
                <pos-row>
                    <pos-column>
                        <pos-form-field label="Product" >
                            <ng-select posInput #product="ngModel" name="product" [items]="inventoryProducts" appendTo="body"
                                bindLabel="Name" bindValue="Id" [(ngModel)]="productQueue.InventoryProductId"
                                placeholder="Select product">
                            </ng-select>
                        </pos-form-field>
                    </pos-column>

                    <pos-column>
                        <pos-form-field label="Label" >
                            <input posInput class="form-control" name="productLabel" #productLabel="ngModel"
                            [(ngModel)]="productQueue.Label">
                        </pos-form-field>
                    </pos-column>

                    <pos-column>
                        <pos-form-field label="Background Color" >
                            <pos-color-picker posInput [(ngModel)]="productQueue.BackgroundColor" name="foregroundColor" ></pos-color-picker>
                        </pos-form-field>
                    </pos-column>

                    <pos-column>
                        <pos-form-field label="Font Color" >
                            <pos-color-picker posInput [(ngModel)]="productQueue.ForegroundColor" name="backgroundColor" ></pos-color-picker>
                        </pos-form-field>
                    </pos-column>
                </pos-row>
            </div>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button class="btn-primary btn-lg btn btn-save pos-button" type="submit" value="Save">Save</button>
            <button class="btn-secondary btn-lg btn btn-cancel-modal pos-button" (click)="onCancel()" type="button"
                value="Cancel">Cancel</button>
        </div>
        <div class="clearfix"></div>
    </form>
</pos-edit-wrapper>
