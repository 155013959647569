<span>
    <input type="text" name="Name{{attributeType.Id}}{{attributeType.Ordinal}}" #sectionName="ngModel"
        (input)="changeCurrentAttribute()" class="form-control" [(ngModel)]="attributeType.Value"
        *ngIf="attributeType.ValueType == accountAttributeValueType.Text" />

    <input type="number" name="Name{{attributeType.Id}}{{attributeType.Ordinal}}" #sectionName="ngModel"
        (input)="changeCurrentAttribute()" class="form-control" [(ngModel)]="attributeType.Value"
        *ngIf="attributeType.ValueType == accountAttributeValueType.Number" />

    <textarea name="Name{{attributeType.Id}}{{attributeType.Ordinal}}" rows="3" #sectionName="ngModel"
        (input)="changeCurrentAttribute()" class="form-control" [(ngModel)]="attributeType.Value"
        *ngIf="attributeType.ValueType == accountAttributeValueType.Paragraph"></textarea>

    <ng-select id="Name{{attributeType.Id}}{{attributeType.Ordinal}}" appendTo="body"
        name="Name{{attributeType.Id}}{{attributeType.Ordinal}}" (change)="changeCurrentAttribute()"
        [(ngModel)]="attributeType.Value" [items]="attributeType.DropdownValues" style="height: 34px;"
        *ngIf="attributeType.ValueType == accountAttributeValueType.Dropdown">
    </ng-select>


    <p-calendar #sectionName="ngModel" inputStyleClass="form-control"
        [showIcon]="true" appendTo="body" [dateFormat]="dateFormat"
        name="Name{{attributeType.Id}}{{attributeType.Ordinal}}" [(ngModel)]="attributeType.Value"
        *ngIf="attributeType.ValueType == accountAttributeValueType.Date" (onSelect)="changeCurrentAttribute()"
        (onBlur)="changeCurrentAttribute()"></p-calendar>

    <span *ngIf="attributeType.ValueType == accountAttributeValueType.Radio">
        <label class="pe-pix-10 pt-pix-5" *ngFor="let option of attributeType.DropdownValues">
            <input type="radio" class="btn-chk-box" (change)="changeCurrentAttribute()"
                name="Name{{attributeType.Id}}{{attributeType.Ordinal}}"
                [(ngModel)]="attributeType.Value" value="{{option}}"> <span class="vertical-mid"> {{option}} </span>
        </label>
    </span>
</span>
