<pos-edit-wrapper heading="{{ id > 0 ? 'Edit' : 'Add' }} IP Address" (close)="cancelWhitelistedIpAddress()">
  <form (ngSubmit)="saveWhitelistedIps(whitelistedIpForm.valid)" #whitelistedIpForm="ngForm" class="edit-form">
    <div class="col-xs-12 pos-page-body">
      <pos-row>
        <pos-column>
          <pos-form-field label="Type" [validationMessages]="{required: 'Please select type.'}">
            <ng-select posInput [items]="addressTypes" appendTo="body" bindLabel="Name" bindValue="Value" [clearable]="false"
            [(ngModel)]="addressType" [clearOnBackspace]="false" name="addType" #addType="ngModel" [required]="true"></ng-select>
          </pos-form-field>
        </pos-column>

        <pos-column>
          <pos-form-field label="IP Address {{addressType === '1' ? 'Begin' : ''}}" [fieldInfo]="fieldInfoMessages.WhitelistedIPAddresses"
          [validationMessages]="{required: 'Please enter a valid IP address.', pattern: 'Invalid ip address.'}">
            <input posInput class="form-control" id="whitelistedIpFrom" name="whitelistedIpFrom" type="text"
            [(ngModel)]="ipRangeFrom" #whitelistedIpFrom="ngModel" [required]="true"
            pattern="^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$" />
          </pos-form-field>
        </pos-column>

        <pos-column *ngIf="addressType==='1'">
          <pos-form-field label="IP Address End" [fieldInfo]="fieldInfoMessages.WhitelistedIPAddresses" [validationMessages]="{required: 'Please enter a valid IP address.', pattern: 'Invalid ip address.'}">
            <input posInput class="form-control" name="whitelistedIpTo" [(ngModel)]="ipRangeTo" #isRangeUpto="ngModel"
            pattern="^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
            [required]="addressType==='1'" type="text" />
          </pos-form-field>
        </pos-column>
      </pos-row>
    </div>
    <div class="clearfix"></div>
    <div class="col-xs-12 action-button-container page-footer">
      <button class="btn btn-primary btn-lg btn-save pos-button" scrollup type="submit" value="Save">Save</button>
      <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="cancelWhitelistedIpAddress()" type="button"
        value="Cancel">Cancel</button>
    </div>
  </form>
</pos-edit-wrapper>
