<pos-edit-wrapper (close)="onCancel()" [isPopup]="false" [headingIcon]="icons.inventoryProductsStore"
    heading="Update Inventory Products">
    <form #bulkUpdateForm="ngForm" (ngSubmit)="updateInventoryProducts(bulkUpdateForm.valid)" autocomplete="off">
        <div class="col-12 border-top pt-pix-5">
            <div class="col-12 px-pix-20">
                <div class="pb-pix-5">
                    <ng-table #gridComponent [paginator]="false" [columns]="columns"
                        [data]="inventoryProductsForBulkUpdate" [scrollable]="true">
                    </ng-table>
                </div>
                <ng-template let-rowData="rowData" let-i="rowIndex" #priceTemplate>
                    <input name="price{{i}}" id="price{{i}}" #price="ngModel" type="number"
                        class="form-control text-right" [(ngModel)]="rowData.Price" required min="0" posDecimal />
                    <span [ngClass]="{'has-error': (price.invalid)}" *ngIf="!rowData.Price && rowData.Price != 0">
                        <label class="control-label">Please enter price.</label>
                    </span>
                </ng-template>
                <ng-template let-rowData="rowData" let-i="rowIndex" #unitQtyTemplate>
                    <input name="productUnitQty{{i}}" #productUnitQty="ngModel" type="number"
                        class="form-control text-right" max="9999" min="0.01" [(ngModel)]="rowData.UnitQty" required />
                    <span *ngIf="!rowData.UnitQty" [ngClass]="{'has-error': (!rowData.UnitQty)}">
                        <label class="control-label">Please enter unit
                            quantity.</label>
                    </span>
                    <span [ngClass]="{'has-error': ((rowData.UnitQty < 0.01 || rowData.UnitQty > 9999))}"
                        *ngIf="(rowData.UnitQty && (rowData.UnitQty < 0.01 || rowData.UnitQty > 9999))">
                        <label class="control-label">Unit quantity must be
                            between 0 to 9999.</label>
                    </span>
                </ng-template>
                <ng-template let-rowData="rowData" let-i="rowIndex" #unitTemplate>
                    <ng-select [items]="units" name="inventoryUnitId{{i}}" [selectOnTab]="true" bindLabel="Name"
                        bindValue="Id" placeholder="Select unit" [(ngModel)]="rowData.InventoryUnitId"
                        [clearable]="false" appendTo="body" (focus)="open">
                    </ng-select>
                </ng-template>
                <ng-template let-rowData="rowData" let-i="rowIndex" #primaryZoneTemplate>
                    <ng-select [items]="inventoryZoneList" bindLabel="NameWithCode" placeholder="Select zone" bindValue="Id"
                        [selectOnTab]="true" [(ngModel)]="rowData.PrimaryZoneId" name="primaryZone{{i}}"
                        (change)="rowData.PrimaryBinId = null; setPrimaryBins(rowData)" appendTo="body">
                    </ng-select>
                </ng-template>
                <ng-template let-rowData="rowData" let-i="rowIndex" #primaryBinTemplate>
                    <ng-select bindValue="Id" [items]="rowData.PrimaryBinList" bindLabel="Bin" placeholder="Select bin"
                        appendTo="body" [selectOnTab]="true" [(ngModel)]="rowData.PrimaryBinId" name="primaryBin{{i}}">
                    </ng-select>
                </ng-template>
                <ng-template let-rowData="rowData" let-i="rowIndex" #secondaryZoneTemplate>
                    <ng-select [items]="inventoryZoneList" bindLabel="NameWithCode" bindValue="Id" placeholder="Select zone"
                        appendTo="body" [(ngModel)]="rowData.SecondaryZoneId" name="secondaryZone{{i}}"
                        [selectOnTab]="true" (change)="rowData.SecondaryBinId = null; setSecondaryBins(rowData)">
                    </ng-select>
                </ng-template>
                <ng-template let-rowData="rowData" let-i="rowIndex" #secondaryBinTemplate>
                    <ng-select [items]="rowData.SecondaryBinList" bindLabel="Bin" bindValue="Id"
                        placeholder="Select bin" appendTo="body" [(ngModel)]="rowData.SecondaryBinId"
                        [selectOnTab]="true" name="secondaryBin{{i}}">
                    </ng-select>
                </ng-template>
            </div>
        </div>
        <div class="col-12 action-button-container page-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button"
                *ngIf="inventoryProductsForBulkUpdate.length">Save </button>
            <button type="button" class="btn-secondary btn-lg btn btn-cancel pos-button" (click)="onCancel()">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
