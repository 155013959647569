import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertsService, ApplicationStateService, FormUtilityService, Messages, ModalFormComponent, ModalService, SpinnerService } from 'src/app/shared';
import { OnlineOrderingAnnouncementSchedule } from '../../interface/online-ordering-announcement-schedule';
import { filter, find, forEach } from 'lodash';
import { OnlineOrderingAnnouncementScheduleService } from '../../service/online-ordering-announcement-schedule.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'pos-announcement-schedule-edit',
  templateUrl: './announcement-schedule-edit.component.html',
  styleUrls: ['./announcement-schedule-edit.component.scss']
})
export class AnnouncementScheduleEditComponent extends ModalFormComponent implements OnInit {

  @Input() scheduleId: number;
  existingSchedules: Array<OnlineOrderingAnnouncementSchedule> = [];
  @ViewChild('scheduleForm') scheduleForm: NgForm;
  get getForm(): NgForm {
    return this.scheduleForm
  }
  dateFormat = 'mm-dd-yy';
  inValidToDate = false;
  inValidFromDate = false;
  currentSchedule: OnlineOrderingAnnouncementSchedule;
  conflictingSchedule: OnlineOrderingAnnouncementSchedule;
  currentDate = new Date();

  constructor(
    bsModalRef: BsModalRef,
    modalService: ModalService,
    formUtilityService: FormUtilityService,
    private applicationStateService: ApplicationStateService,
    private onlineOrderingAnnouncementScheduleService: OnlineOrderingAnnouncementScheduleService,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
  ) {
    super(bsModalRef, modalService, formUtilityService);
    this.dateFormat = this.applicationStateService.settingParam.PCalendarDateFormat;
    this.currentSchedule = onlineOrderingAnnouncementScheduleService.getNewAnnouncementSchedule();
    this.conflictingSchedule = onlineOrderingAnnouncementScheduleService.getNewAnnouncementSchedule();
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.spinnerService.show();
    this.onlineOrderingAnnouncementScheduleService.getAll()
    .pipe(finalize(() => this.spinnerService.hide()))
    .subscribe({
      next: (response) => {
        this.existingSchedules = response;
        if (this.scheduleId) {
          this.currentSchedule = { ...find(this.existingSchedules, x => x.Id === this.scheduleId) }
          this.currentSchedule.StartDateTime = new Date(this.currentSchedule.StartDateTime.toString());
          this.currentSchedule.EndDateTime = new Date(this.currentSchedule.EndDateTime.toString());
          this.existingSchedules = filter(this.existingSchedules, x => x.Id !== this.scheduleId);
        }
      },
      error: this.alertService.showApiError
    })
  }

  onDateChange() {
    if (Date.parse(this.currentSchedule.StartDateTime.toString()) > Date.parse(this.currentSchedule.EndDateTime.toString())) {
      this.currentSchedule.EndDateTime = this.currentSchedule.StartDateTime;
    }
    this.inValidFromDate = false;
    this.inValidToDate = false;
    this.checkForDateConflict();
  }

  checkForDateConflict() {
    forEach(this.existingSchedules, (data) => {
      if (Date.parse(data.StartDateTime.toString()) <= Date.parse(this.currentSchedule?.StartDateTime.toString()) &&
        Date.parse(data.EndDateTime.toString()) >= Date.parse(this.currentSchedule?.StartDateTime.toString()))
      {
          this.inValidFromDate = true;
          this.conflictingSchedule.StartDateTime = new Date(data.StartDateTime);
          this.conflictingSchedule.EndDateTime = new Date(data.EndDateTime);
      }
      if (Date.parse(data.StartDateTime.toString()) <= Date.parse(this.currentSchedule?.EndDateTime.toString()) &&
        Date.parse(data.EndDateTime.toString()) >= Date.parse(this.currentSchedule?.EndDateTime.toString()))
      {
          this.inValidToDate = true;
          this.conflictingSchedule.StartDateTime = new Date(data.StartDateTime);
          this.conflictingSchedule.EndDateTime = new Date(data.EndDateTime);
      }
    });
  }

  onCancel(reload: boolean = false) {
    this.hide({ shouldReload: reload });
  }

  saveSchedule(isValid: boolean) {
    this.checkForDateConflict();
    if (!isValid || this.inValidFromDate || this.inValidToDate) {
      return;
    }
    this.spinnerService.show();
    this.onlineOrderingAnnouncementScheduleService.insert(this.currentSchedule)
    .pipe(finalize(() => this.spinnerService.hide()))
    .subscribe({
      next: (response) => {
        this.onSaveSuccess()
      },
      error: this.alertService.showApiError
    })
  }

  onSaveSuccess() {
    this.onCancel(true);
    this.alertService.renderSuccessMessage(Messages.OnlineOrderAnnouncementScheduleSaveSuccess);
  }

}
