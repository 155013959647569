import { Component, OnInit, Input, KeyValueDiffer, KeyValueDiffers } from '@angular/core';

@Component({
  selector: 'rotate-media',
  templateUrl: './rotate-media.component.html',
  styleUrls: ['./rotate-media.component.scss']
})
export class RotateMediaComponent implements OnInit {
  @Input() media: any;
  private customerDiffer: KeyValueDiffer<string, any>;


  constructor(private differs: KeyValueDiffers) {
  }

  ngOnInit(): void {
    if (this.media) {
      this.customerDiffer = this.differs.find(this.media).create();
    }
  }

  ngDoCheck(): void {
    const changes = this.customerDiffer.diff(this.media);
    if (changes) {
      this.mediaChanged();
    }
  }

  mediaChanged(): any {
    if (this.media.type === 'video') {      
        setTimeout(() => {
          let activeVideo = document.getElementById("videoPlayer");
          (<HTMLVideoElement>activeVideo)?.load();
        });
    }
  }
}
