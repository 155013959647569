import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AdfColumn, AdfModel } from '../../interfaces';
import { SpinnerService, AlertsService, DashboardConfigurationService } from 'src/app/shared';
import { DashboardBroadcastingService } from '../../services/dashboard-broadcasting.service';
import { Subscription } from 'rxjs';
import { forEach, filter } from 'lodash';
import { finalize } from 'rxjs/operators';
import { DashboardWidgetsService } from '../../services/dashboard-widgets.service';

@Component({
  selector: 'pos-adf-widget-content',
  templateUrl: './adf-widget-content.component.html',
})
export class AdfWidgetContentComponent implements OnInit, OnDestroy {

  @Input() adfModel: AdfModel;
  @Input() model: any;
  @Input() content: any;
  @Input() column: AdfColumn;
  @Input() isInteractive: boolean;
  @Input() isFullScreenMode?: boolean = false;
  eventsSubscription: Array<Subscription> = [];
  constructor(private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private dashboardBroadcastingService: DashboardBroadcastingService,
    private dashboardConfigurationService: DashboardConfigurationService,
    private dashboardWidgetService: DashboardWidgetsService) { }

  ngOnInit(): void {
    this.subscribeBroadcastEvents();

  }

  ngOnDestroy() {
    forEach(this.eventsSubscription, (subscribe) => {
      if (subscribe) {
        subscribe.unsubscribe();
      }
    });
  }

  subscribeBroadcastEvents = () => {
    this.eventsSubscription.push(this.dashboardBroadcastingService.adfDashboardSaved.subscribe((dashboardId) => {
      this.saveWidget(dashboardId);
    }));
  }

  saveWidget = (dashboardId) => {
    this.spinnerService.show();
    const params = filter(this.model.config.params, 'IsUserDefined');
    let wt = JSON.parse(JSON.stringify(this.model));

    if (wt.config.chart) {
      wt.config.chart = {};
    }

    if (wt.DatasourceId > 0 && wt.config['DatasourceId'] && wt.config['DatasourceId'] != wt.DatasourceId) {
      wt.DatasourceId = wt.config['DatasourceId']
      delete wt.config['DatasourceId'];
    }
    delete wt.config['params'];
    this.saveDashboardWidgetAssignment(dashboardId, wt, params);
  }

  private saveDashboardWidgetAssignment(dashboardId: any, wt: any, params: any[]) {
    this.dashboardConfigurationService.saveDashboardWidgetAssignment(dashboardId, wt)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (dashboardWidgetAssignmentId) => {
          const model = [];
          forEach(params, (param) => {
            let item = {
              id: param.Id > 0 ? param.Id : null,
              dashboardWidgetAssignmentId: dashboardWidgetAssignmentId,
              parameterId: param.ParameterId,
              parameterValue: Object.prototype.toString.call(param.ParameterValue) === '[object Object]' ? JSON.stringify(param.ParameterValue) : param.ParameterValue
            };
            model.push(item);
          });
          if (model?.length) {
            this.saveDashboardWidgetDataSourceParameters(model);
          } else if (this.spinnerService._requestCount <= 1) {
            this.dashboardBroadcastingService.widgetSaved();
          }
        }, error: this.alertsService.showApiError
      });
  }

  private saveDashboardWidgetDataSourceParameters(model: any[]) {
    this.spinnerService.show();
    this.dashboardWidgetService.saveDashboardWidgetDataSourceParameters(model)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          if (this.spinnerService._requestCount <= 1) {
            this.dashboardBroadcastingService.widgetSaved();
          }
        }, error: this.alertsService.showApiError
      });
  }
}
