import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared';
import { OnlineOrderingCategory, OnlineOrderingCategorySection } from '..';

@Injectable({
  providedIn: 'root'
})

export class OnlineOrderingCategoriesService {

  constructor(private httpService: HttpClient) { }

  newOrderingCategory() {
    const category: OnlineOrderingCategory = {
      Id: 0,
      Description: '',
      Name: '',
      Icon: '',
      MinSelection: null,
      MaxSelection: null,
      Ordinal: 0,
      IsOption: false,
      hasImageResolutionError: false,
      HasIcon: false,
      IsActive: true,
      IsMobile: false,
      IsFeatured: false
    };
    return category;
  }

  newOrderingCategorySection() {
    const section: OnlineOrderingCategorySection = {
      Id: 0,
      Description: '',
      Name: '',
      Ordinal: 0,
      Icon: '',
      CategoryId: 0,
      IsChanged: false,
      IsDefault: false,
      IsChangedImage: false,
      iconName: ''
    }
    return section;
  }

  getOnlineOrderingCategories(): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}onlineorderingcategories`
    );
  }

  getOnlineOrderingCategory(categoryId: number, isToggle: boolean): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}onlineorderingcategories/` + categoryId + '/' + isToggle
    );
  }

  saveOnlineOrderingCategory(categoryDetails: any, images): Observable<any> {
    const formData = this.prepareIconFormDataForCategory(categoryDetails, images);
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}onlineorderingcategories`,
      formData
    );
  }

  saveOnlineOrderingCategorySection(sectionDetails: any, images): Observable<any> {
    const formData = this.prepareIconFormDataForSection(sectionDetails, images);
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}onlineorderingcategories/section/`,
      formData
    );
  }

  updateOnlineOrderingCategoryOrdinal(categories: OnlineOrderingCategory): Observable<any> {
    return this.httpService.put(
      `${RuntimeConstants.API_PATH}onlineorderingcategories/ordinal`,
      categories
    );
  }

  updateOnlineOrderingCategorySection(categories: OnlineOrderingCategorySection): Observable<any> {
    return this.httpService.put(
      `${RuntimeConstants.API_PATH}onlineorderingcategories/sectionordinal`,
      categories
    );
  }

  private prepareIconFormDataForSection(param, images: File[]): FormData {
    const formData = new FormData();
    if (images) {
      images.forEach(image => {
        formData.append('file', image);
      });
    }
    formData.append('model', JSON.stringify(param));
    return formData;
  }

  private prepareIconFormDataForCategory(param, iconFile: File): FormData {
    const formData = new FormData();
    if (iconFile) {
      formData.append('file', iconFile);
    }
    formData.append('model', JSON.stringify(param));
    return formData;
  }

  getOnlineOrderingCategorySection(categoryId: number): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}onlineorderingcategorysections/` + categoryId
    );
  }

  getOnlineOrderingOptions(): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}onlineorderingcategories`
    );
  }

  addButtonToOption(button: any): Observable<any> {
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}onlineorderingcategories/option/button`,
      button
    );
  }

  deleteCategory(id: number): Observable<any> {
    return this.httpService.delete(
      `${RuntimeConstants.API_PATH}onlineorderingcategories/` + id);
  }


  deleteSection(id: number): Observable<any> {
    return this.httpService.delete(
      `${RuntimeConstants.API_PATH}onlineorderingcategories/section/` + id);
  }

  deleteButtonToOption(optionButtonId: number): Observable<any> {
    return this.httpService.delete(
      `${RuntimeConstants.API_PATH}onlineorderingcategories/option/button/` + optionButtonId);
  }

  updateButtonToOption(button: any): Observable<any> {
    return this.httpService.put(
      `${RuntimeConstants.API_PATH}onlineorderingcategories/option/button`,
      button
    );
  }

  getOnlineOrderingOption(categoryId: number): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}onlineorderingcategories/` + categoryId
    );
  }
}
