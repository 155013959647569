import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SpinnerService } from 'src/app/shared/components';
import { ModalService } from 'src/app/shared/components/modal';
import { AlertsService, ApplicationStateService, MessageTypes, RabbitMQService } from 'src/app/shared/services';
import { NanoleafAuthTokenConfigComponent } from './nanoleaf-auth-token-config/nanoleaf-auth-token-config.component';
import { filter as rxJsFilter, finalize } from 'rxjs/operators';
import { LightsInfoModel } from '../../interface/lights-info-model';
import { HardwaresService } from '../../service/hardware.service';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { find, forEach } from 'lodash';
import { lightBulb, check } from 'src/app/shared/components/icon';
import { DomainConstants } from 'src/app/shared/constants';
import { LightsInfo } from '../../interface';

@Component({
  selector: 'pos-nanoleaf-hardware-config',
  templateUrl: './nanoleaf-hardware-config.component.html',
  styleUrls: ['./nanoleaf-hardware-config.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class NanoleafHardwareConfigComponent implements OnInit {

  FLASH_LIGHT_COLOR = '#ffffff';
  constructor(protected modalService: ModalService,
    private hardwareService: HardwaresService,
    private applicationStateService: ApplicationStateService,
    private rabbitMQService: RabbitMQService,
    protected alertService: AlertsService,
    private spinnerService: SpinnerService) { }
  @Input() hardwareProperties = [];
  @Input() typeIdentifier: string;
  @Input() HICId: number;
  @Input() isValidForm = false;
  @Input() isSubmitted = false;
  @Input() hardwareId = 0;
  @Output() close: EventEmitter<any> = new EventEmitter();

  rabbitMQSubscriptionTimer: ReturnType<typeof setTimeout>;
  authToken: string = null;
  lightsInfo: Array<LightsInfo> = [];
  baseUrl: string = null;
  rabbitMqHICResponseSubscription: Subscription;
  icons = {
    lightBulb,
    check
  };

  ngOnInit(): void {
    this.loadLightDetails();
  }

  loadLightDetails() {
    if (this.hardwareId) {
      this.baseUrl = find(this.hardwareProperties, x => x.Name === DomainConstants.LightHardwareProperty.BaseUrl)?.ValueObject.Value;
      this.authToken = find(this.hardwareProperties, x => x.Name === DomainConstants.LightHardwareProperty.AuthToken)?.ValueObject.Value;
      const lightsInfo = find(this.hardwareProperties, x => x.Name === DomainConstants.LightHardwareProperty.LightsInfo);
      if (lightsInfo?.ValueObject?.Value) {
        this.lightsInfo = JSON.parse(lightsInfo?.ValueObject?.Value);
      }
    }
  }

  propertyValueChanged() {
    this.baseUrl = find(this.hardwareProperties, x => x.Name === DomainConstants.LightHardwareProperty.BaseUrl)?.ValueObject.Value;
  }

  subscribeToHICResponseToTerminal(errorMessage: string) {
    this.spinnerService.show();
    this.rabbitMqHICResponseSubscription =
      this.rabbitMQService.subscribeToHICResponseToTerminal$(this.applicationStateService.settingParam.TerminalId)
        .pipe(rxJsFilter(message => message.Payload.RequestType === MessageTypes.LIGHT_INFO || message.Payload.RequestType === MessageTypes.LIGHT_AUTH_TOKEN))
        .subscribe({
          next: (message) => {
            this.clearAndUnsubscribeRabbitMQTimeOut();
            if (message.Payload.RequestType === MessageTypes.LIGHT_INFO) {
              this.lightsInfo = [];
              forEach(message.Payload.LightsInfo, lightId => {
                this.lightsInfo.push({ PanelId: lightId, Name: '' });
              });
              this.close.emit({ lightsInfo: JSON.stringify(this.lightsInfo) });
            } else if (message.Payload.RequestType === MessageTypes.LIGHT_AUTH_TOKEN) {
              this.authToken = message.Payload.AuthToken;
              this.close.emit({ authToken: this.authToken });
            }
            
          },
          error: (error) => {
            this.clearAndUnsubscribeRabbitMQTimeOut();
            this.alertService.showApiError(Messages.ConfigureAuthForLightsFailed);
          }
        });
    this.rabbitMQSubscriptionTimeOut(errorMessage);
  }

  rabbitMQSubscriptionTimeOut(errorMessage: string) {
    if (this.rabbitMQSubscriptionTimer) {
      clearTimeout(this.rabbitMQSubscriptionTimer);
    }
    this.rabbitMQSubscriptionTimer = setTimeout(() => {
      this.clearAndUnsubscribeRabbitMQTimeOut();
      this.alertService.renderErrorMessage(errorMessage);
    }, 60 * 1000)
  }

  clearAndUnsubscribeRabbitMQTimeOut() {
    this.rabbitMqHICResponseSubscription?.unsubscribe();
    if (this.rabbitMQSubscriptionTimer) {
      clearTimeout(this.rabbitMQSubscriptionTimer);
    }
    this.spinnerService.hide();
  }

  configureAuth() {
    const modalRef = this.modalService.getModalWrapper(NanoleafAuthTokenConfigComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      initialState: {
        HICId: this.HICId,
        typeIdentifier: this.typeIdentifier,
        baseUrl: this.baseUrl
      }
    });

    modal.close.subscribe(res => {
      if (res?.shouldShowLoader) {
        this.subscribeToHICResponseToTerminal(Messages.ConfigureAuthForLightsFailed);
      }
    });
  }

  prepareLightAuthTokenModel(panelId = null) {
    const authTokenModel: LightsInfoModel = {
      TerminalName: this.applicationStateService.settingParam.TerminalName,
      TerminalId: this.applicationStateService.settingParam.TerminalId,
      TypeIdentifier: this.typeIdentifier,
      HICId: this.HICId,
      AuthToken: this.authToken,
      PanelId: `[{ PanelId: ${panelId}, Color: "${this.FLASH_LIGHT_COLOR}"}]`,
      BaseUrl: this.baseUrl
    };
    return authTokenModel;
  }

  flashLight(panelId) {
    const authTokenModel: LightsInfoModel = this.prepareLightAuthTokenModel(panelId);
    this.spinnerService.show();
    this.hardwareService.triggerFlash(authTokenModel)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe({
        next: (res) => this.alertService.renderSuccessMessage(Messages.RequestSendSuccess),
        error: this.alertService.showApiError
      });
  }

  readLights() {
    const authTokenModel: LightsInfoModel = this.prepareLightAuthTokenModel();
    this.hardwareService.getLightsInfo(authTokenModel)
      .subscribe({
        next: () => this.subscribeToHICResponseToTerminal(Messages.GetNanoLeafLightInfoError),
        error: this.alertService.showApiError
      });
  }

  lightInfoChanged() {
    this.close.emit({ lightsInfo: JSON.stringify(this.lightsInfo) });
  }
}
