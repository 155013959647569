import { Component, OnInit, ViewChild } from '@angular/core';
import { Permissions, Levels, Messages, DomainConstants } from '../../../../shared/constants/';
import {
  BaseListComponent, AuthenticationService, AlertsService, SpinnerService, ModalService, PrintTableService,
  ApplicationStateService,
  ExportService
} from 'src/app/shared';
import { Button } from '../../interface/button';
import { GridColumn, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { ButtonService } from 'src/app/shared/services/button.service';
import { check, barsWhite, deleteWhite, editWhite, history, plusWhite, printWhite, buttonsDiceOne } from 'src/app/shared/components/icon';
import * as _ from 'lodash';
import { ButtonEditComponent } from '../button-edit';
import { ActivatedRoute, Router } from '@angular/router';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';

@Component({
  selector: 'pos-button-list',
  templateUrl: './button-list.component.html',
})
export class ButtonListComponent extends BaseListComponent<Button> implements OnInit {

  permission = {
    name: Permissions.UIButtons,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit,
    parent: Permissions.UserInterface,
    parentAccessLevel: Levels.Access
  };
  public icons = {
    check,
    barsWhite,
    deleteWhite,
    editWhite,
    history,
    plusWhite,
    printWhite,
    buttonsDiceOne
  };
  buttonsColumns: Array<GridColumn> = [];
  buttonsPrintColumns: Array<GridColumn> = [];
  buttons: Button[] = [];
  buttonId: number = 0;
  buttonName: string = '';
  popover: any;
  button: Button;
  @ViewChild('toggleTemplate', { static: true }) private toggleTemplate: any;
  @ViewChild('managerOnlyTemplate', { static: true }) private managerOnlyTemplate: any;
  @ViewChild('popoverTemplate', { static: true }) private popoverTemplate: any;
  @ViewChild('colorTemplate', { static: true }) private colorTemplate: any;
  @ViewChild('inUseTemplate', { static: true }) private inUseTemplate: any;
  @ViewChild('operationHeaderTemplate', { static: true }) private operationHeaderTemplate: any;
  @ViewChild('operationTemplate', { static: true }) private operationTemplate: any;
  constructor(protected buttonService: ButtonService,
    private authenticationService: AuthenticationService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected applicationStateService: ApplicationStateService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected exportService: ExportService,
    protected printService: PrintTableService) {
    super(buttonService, alertService, spinnerService, modalService, applicationStateService, printService, route);
    this.scrollToTop();
  }

  ngOnInit() {
    this.deleteSuccessMessage = Messages.ButtonDeleted;
    this.buttonsColumns = this.getGridColumns();
    this.checkPermission();
    this.loadSubscriptions();
    this.buttonId = 0;
  }

  private loadSubscriptions(): void {
    this.data$.subscribe({
      next: (res) => {
        this.buttons = res;
      }
    });
  }

  public getGridColumns(): Array<GridColumn> {

    const toggleColumn = new TemplateColumn({
      HeaderText: 'Toggle',
      itemTemplate: this.toggleTemplate,
      TextAlign: TextAlign.Center,
      Width: '70px',
      Field: 'Toggle',
      IsSortable: true
    });

    const managerOnlyColumn = new TemplateColumn({
      HeaderText: 'Manager Only',
      itemTemplate: this.managerOnlyTemplate,
      TextAlign: TextAlign.Center,
      Width: '110px',
      Field: 'ManagerOnly',
      IsSortable: true
    });

    const colorColumn = new TemplateColumn({
      HeaderText: 'Color',
      itemTemplate: this.colorTemplate,
      TextAlign: TextAlign.Center,
      Width: '70px',
      Field: 'Color',
      IsSortable: true
    });

    const inUseColumn = new TemplateColumn({
      HeaderText: 'In Use',
      itemTemplate: this.inUseTemplate,
      TextAlign: TextAlign.Center,
      Width: '110px',
      Field: 'InUse',
      IsSortable: true,
    });



    const columns = [
      new GridColumn({ HeaderText: 'Selection', Field: 'Selection', IsSortable: true, Width: '13%' }),
      new GridColumn({ HeaderText: 'Kitchen Text', Field: 'InternalText', IsSortable: true, Width: '12%' }),
      new GridColumn({ HeaderText: 'Receipt Text', Field: 'ReceiptText', IsSortable: true, Width: '12%' }),
      toggleColumn,
      new GridColumn({ HeaderText: 'Product Name', Field: 'ProductName', IsSortable: true, Width: '12%' }),
      colorColumn,
      managerOnlyColumn,
      inUseColumn,
    ];
    this.buttonsPrintColumns = [...columns];
    return columns;
  }

  private checkPermission(): void {

    const operation = new TemplateColumn({
      itemTemplate: this.operationTemplate,
      headerTemplate: this.operationHeaderTemplate,
      TextAlign: TextAlign.Center,
      Width: '150px',
      CellClass: 'cell-padding'
    });

    if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      this.buttonsColumns.push(operation);
    }
  }

  exportCSV() {
    this.exportService.exportCSV('buttons', this.buttons, null, this.buttonsPrintColumns);
  }

  printData() {
    this.printService.printEmitter.next({
      gridColumns: this.buttonsPrintColumns,
      gridData: this.buttons
    });
  }

  public editItem(id: number, data?: Button): void {
    this.buttonId = id;
    this.buttonName = data ? data.Selection : '';
    const modalRef = this.modalService.getModalWrapper(ButtonEditComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center modal-lg modal-max-width-65',
      initialState: {
        id: this.buttonId,
        buttonName: this.buttonName
      }
    });
    modal.close.subscribe(res => {
      this.buttonId = 0;
      this.buttonName = '';
      if (res && res.reload) {
        this.reload();
      }
    });

  }

  onLinkPopOver(data, popover) {
    this.popover = popover;
    this.button = data;
  }

  onButtonHistory() {
    this.router.navigate([this.button.Id, 'button-history'], { relativeTo: this.route });
  }

  public getConfirmDeleteMessage(data: Button): string {
    return StringUtils.format(Messages.ConfirmDeleteButton, { 'buttonName': data.Selection });
  }

  private scrollToTop(): void {
    window.scrollTo(0, 0);
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

}
