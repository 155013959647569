import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RuntimeConstants, BaseCrudService } from 'src/app/shared';
import { Playlist } from '../interface/playlist';

@Injectable({
  providedIn: 'root'
})
export class PlaylistsService extends BaseCrudService<Playlist> {

  playlistApiPath = `${RuntimeConstants.API_PATH}playlists`;

  constructor(private httpClient: HttpClient) {
    super('playlists', httpClient);
  }

  newPlaylist() {
    const playlist: Playlist = {
      Id: 0,
      Name: '',
      Volume: 1
    };
    return playlist;
  }

  getPlaylistTracks(playListId) {
    return this.httpClient.get(`${this.playlistApiPath}/${playListId}/tracks`);
  }

  deletePlaylistTrack(playlistId: number, trackId: number) {
    return this.httpClient.delete(`${this.playlistApiPath}/${playlistId}/tracks/${trackId}`);
  }

  addTracksToPlaylist(playListId, playlistTracks) {
    return this.httpClient.post(`${this.playlistApiPath}/${playListId}/tracks`, playlistTracks);
  }

  getPlaylistTrackCategory(playListId: number) {
    return this.httpClient.get(`${this.playlistApiPath}/${playListId}/track_Category`);
  }
}
