<pos-list-wrapper [headingIcon]="icons.database" heading="Dashboard Data Source" (close)="close()">
    <div class="row pt-pix-5">
        <div class="col-12 ">
            <ng-table #dashboardDataSourceTable [columns]="dataSourceColumns" [data]="dataSource"
                [exportFilename]="'dashboard-data-source'">
            </ng-table>
        </div>
    </div>
</pos-list-wrapper>
<pos-print-table [hidden]="true"></pos-print-table>
