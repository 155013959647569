<pos-edit-wrapper heading="{{revenueCenterId ? 'Edit' : 'Add'}} Revenue Center{{revenueCenterId ?': ' + revenueCenterEditName : ''}}" (close)="cancelEditing()">
  <form #revenueCenterForm="ngForm" (ngSubmit)='saveRevenueCenter(revenueCenterForm.valid)'>
    <div class="col-xs-12 pos-modal-body">
      <context-provider provide="props">
      <pos-row>
        <pos-column>
          <pos-form-field label="Name" [validationMessages]="{required: 'Please enter name.'}">
            <input posInput name="revenueCenterName" #revenueCenterName="ngModel" type="text" class="form-control"
              [(ngModel)]="revenueCenter.Name" [required]="true" />
          </pos-form-field>
        </pos-column>

        <pos-column>
          <pos-form-field label="Order Method" [validationMessages]="{required: 'Please select order method(s).'}">
            <ng-select posInput appendTo="body" [items]="orderMethods" bindLabel="Name" bindValue="Id" [multiple]="true" [hideSelected]="true"
              placeholder="Select order method(s)" [(ngModel)]="revenueCenter.OrderMethodIds" id="orderMethodIds"
              name="orderMethodIds" [required]="true">
            </ng-select>
          </pos-form-field>
        </pos-column>

        <pos-column>
          <pos-form-field label="Serve Method" [validationMessages]="{required: 'Please select serve method(s).'}">
            <ng-select posInput appendTo="body" [items]="serveMethods" bindLabel="Name" [multiple]="true" bindValue="Id" [hideSelected]="true"
              placeholder="Select serve method(s)" [(ngModel)]="revenueCenter.ServeMethodIds" id="serveMethodIds"
              name="serveMethodIds" [required]="true">
            </ng-select>
          </pos-form-field>
        </pos-column>

        <pos-column>
          <pos-form-field label="Payment Type">
            <ng-select posInput appendTo="body" [items]="paymentModes" bindLabel="Name" bindValue="Id" [multiple]="true" [hideSelected]="true"
              placeholder="Select payment type(s)" [(ngModel)]="revenueCenter.PaymentTypeIds" id="paymentTypeIds"
              name="paymentTypeIds">
            </ng-select>
          </pos-form-field>
        </pos-column>

        <pos-column>
          <pos-form-field>
            <p-checkbox posInput name="active" #featured="ngModel" [(ngModel)]="revenueCenter.IsActive" binary="true" label="Active">
            </p-checkbox>
          </pos-form-field>
        </pos-column>
      </pos-row>
      </context-provider>
    </div>
    <div class="col-xs-12 action-button-container popup-footer">
      <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" value="Save">Save</button>
      <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" value="Cancel"
        (click)="cancelEditing()">Cancel</button>
    </div>
  </form>
</pos-edit-wrapper>
