import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AlertsService, SpinnerService, ModalService, ModalFormComponent, DomainConstants, FormUtilityService } from 'src/app/shared';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CopyInventoryIngredientsInfoService } from '../service/copy-inventory-ingredients-info.service';
import * as _ from 'lodash';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';

@Component({
  selector: 'pos-copy-inventory-ingredients-info',
  templateUrl: './copy-inventory-ingredients-info.component.html',
})
export class CopyInventoryIngredientsInfoComponent extends ModalFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.copyProductIngredientsInfo;
  }

  @ViewChild('copyProductIngredientsInfo') copyProductIngredientsInfo: NgForm;
  @Input('productId') productId: number;
  @Input('productName') productName: string;
  inventoryProducts: Array<any> = [];
  destinationProduct: any = null;
  ingredients: boolean = true;
  servings: boolean = true;
  nutrition: boolean = true;
  dietaryWarnings: boolean = true;
  itemToCopy: string;
  constructor(
    modalRef: BsModalRef,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    modalService: ModalService,
    private copyInventoryIngredientsInfoService: CopyInventoryIngredientsInfoService,
    formUtilityService: FormUtilityService
  ) { super(modalRef, modalService, formUtilityService); }

  ngOnInit() {
    if (this.productId) {
      this.onChangeSelection();
      this.getInventoryProducts();
    }
  }


  getInventoryProducts() {
    this.spinnerService.show();
    this.copyInventoryIngredientsInfoService.getInventoryProduct(0, 0, true, 0)
      .subscribe(
        response => {

          this.inventoryProducts = _.filter(response, (product) => {
            return product.IsFood;
          });
        },
        this.alertService.showApiError,
        () => {
          this.spinnerService.hide();
        }
      );
  }

  copyProductIngredients(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.spinnerService.show();

    let copyObj = {
      SourceId: this.productId,
      DestinationId: this.destinationProduct.Id,
      ItemsToCopy: this.itemToCopy
    };
    this.copyInventoryIngredientsInfoService.copyProductIngredientsInfo(copyObj)
      .subscribe(
        response => {
          this.alertService.renderSuccessMessage(StringUtils.format(Messages.InventoryRawInfoCopiedSuccess,
            { 'sourceProductName': this.productName, 'destinationProductName': this.destinationProduct.Name }));
          this.cancelCopy();
        },
        this.alertService.showApiError,
        () => {
          this.spinnerService.hide();
        }
      );
  }

  cancelCopy() {
    this.hide({ shouldReload: true });
  }

  onChangeSelection() {
    this.itemToCopy = '';
    if (this.ingredients) {
      this.itemToCopy += DomainConstants.InventoryProductRawInfo.Ingredients + ',';
    }
    if (this.servings) {
      this.itemToCopy += DomainConstants.InventoryProductRawInfo.Servings + ',';
    }
    if (this.nutrition) {
      this.itemToCopy += DomainConstants.InventoryProductRawInfo.Nutrition + ',';
    }
    if (this.dietaryWarnings) {
      this.itemToCopy += DomainConstants.InventoryProductRawInfo.DietaryWarnings + ',';
    }
  }
}
