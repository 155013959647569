import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cloneDeep, find, forEach, isArray } from 'lodash';
import { Observable } from 'rxjs';
import { BaseCrudService, DomainConstants, RuntimeConstants } from 'src/app/shared';
import { PromotionRule } from '../../interface';
import { Promotion } from '../../interface/rules/promotion';
import { PromotionConfig, PromotionConfigParam } from '../../interface/rules/promotion-config';

@Injectable({
  providedIn: 'root'
})
export class PromotionService extends BaseCrudService<Promotion> {

  apiUrl = `${RuntimeConstants.API_PATH}promotion/`;
  criterias: Array<PromotionConfig> = [];
  constructor(private httpClient: HttpClient) {
    super('promotion', httpClient);
  }

  newPromotion(): Promotion {
    return {
      Id: 0,
      Name: '',
      IsActive: true,
      RewardAfterEligibility: DomainConstants.RewardAfterEligibility.Immediately,
      StartDate: null,
      EndDate: null,
      StartTime: null,
      EndTime: null,
      Days: null,
      Type: DomainConstants.PromotionTypes.GiftCards.DisplayName,
      Criteria: null,
      Reward: null,
      PromotionTypeId: null
    };
  }

  getPromotionTypeByRoute(route: string) {
    return find(DomainConstants.PromotionTypes, x => x.Route == route) ?? DomainConstants.PromotionTypes.GiftCards;
  }

  getCriteria(): Observable<Array<PromotionConfig>> {
    return this.httpClient.get<Array<PromotionConfig>>(`${this.apiUrl}criteria`);
  }

  getRewards(): Observable<Array<PromotionConfig>> {
    return this.httpClient.get<Array<PromotionConfig>>(`${this.apiUrl}rewards`);
  }

  applyGiftCardPromotion(cardNumber: string, rechargeAmount: number) {
    return this.httpClient.post(
      `${this.apiUrl}apply-gift-card-promotion`, { CardNumber: cardNumber, RechargeAmount: rechargeAmount }
    );
  }

  preparePromotionRule(criteria: PromotionRule, isSetValue = false) {
    if (criteria.Config) {
      criteria.Config = isSetValue ? this.setConfig(criteria) : this.convertConfigToJson(criteria);
    }
    if (criteria.Rules.length) {
      forEach(criteria.Rules, x => this.preparePromotionRule(x, isSetValue));
    }
    return criteria;
  }

  setPromotionRule(criteria: string, criterias: Array<PromotionConfig>) {
    this.criterias = criterias;
    const ruleModel = JSON.parse(criteria);
    return this.preparePromotionRule(ruleModel, true);
  }

  updatePromotionOrdinal(promotions): Observable<Array<any>> {
    return this.httpClient.put<Array<any>>(`${this.apiUrl}sort`, promotions);
  }

  convertConfigToJson(criteria): any {
    let promoConfigParam = {};
    if (criteria.Config) {
      forEach(criteria.Config, param => {
        const value = isArray(param.Value) && param.Value?.length ? param.Value : null;
        promoConfigParam[param.Name] = isArray(param.Value) ? value : param.Value;
      });
    }
    return promoConfigParam;
  }

  setConfig(criteria): Array<PromotionConfigParam> {
    let promotionConfig = cloneDeep(find(this.criterias, x => x.Name == criteria.Name).Config);
    if (criteria.Config) {
      forEach(promotionConfig, config => {
        config.Value = criteria.Config[config.Name];
      })
    }
    return promotionConfig;
  }
}
