import { Component, OnInit, Input, OnChanges } from '@angular/core';
import * as _ from 'lodash';
import { OrderInvoice } from 'src/app/orders';
import { check } from 'src/app/shared/components/icon';
import { DomainConstants } from 'src/app/shared/constants/domain-constants';
import { SettingParam } from 'src/app/shared/interface/setting-param';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';

@Component({
  selector: 'pos-settle-order-invoice',
  templateUrl: './settle-order-invoice.component.html',
  styleUrls: ['./settle-order-invoice.component.scss', '../../../../../../styles/pages/design/settle.scss']
})
export class SettleOrderInvoiceComponent implements OnInit, OnChanges {

  @Input() orderData: OrderInvoice;
  @Input() seats?: Array<any> = null;
  @Input() boxHeight: number;
  height: number;
  public icons = {
    check
  };
  today = new Date().toLocaleDateString();
  pickupDate: string = null;
  settingParam: SettingParam;
  loyaltyAccountTerm: string;
  accountTypes = DomainConstants.AccountTypes;
  tableTerm: string = '';
  accountTerm: string = '';
  constructor(private applicationStateService:  ApplicationStateService) { }

  ngOnInit() {
    this.setDefault();
  }

  setDefault() {
    this.settingParam = this.applicationStateService.settingParam;
    this.loyaltyAccountTerm = this.settingParam.LoyalAccountTerm ? this.settingParam.LoyalAccountTerm : 'Account';
    this.tableTerm = this.settingParam.TableTerm ? this.settingParam.TableTerm : 'Table';
    this.accountTerm = this.settingParam.AccountTerm ? this.settingParam.AccountTerm : 'Tab';
  }

  ngOnChanges() {
    this.pickupDate = new Date(this.orderData.OrderAttributes.PickupTime).toLocaleDateString();
    this.height = (this.boxHeight > 0 ? this.boxHeight - (this.orderData.CustomerAccount && this.orderData.CustomerAccount.Name ? 170 : 150)
      : 275);
    this.height = this.orderData.OrderAttributes.PickupTime ? this.height - 20 : this.height;
  }
}
