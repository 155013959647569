<pos-edit-wrapper (close)="cancel()" heading="Recipes Included: {{inventoryProductName}}">
    <div>
        <div class="page-content" id="inventoryProductBarCodeContainer">
            <div class="col-md-12 text-right pt-pix-10 px-4">
                <button class="btn btn-secondary btn-lg normal pos-button" (click)="exportAction('exportRecipeIncluded');">
                    <pos-icon [icon]="icons.upload"></pos-icon>
                    Export
                </button>
            </div>
            <div class="col-md-12 px-4" id="exportRecipeIncluded">
                <ng-table [sort]="true" [columns]="recipeIncludedColumns" [data]="recipeIncluded" [paginator]="false"
                    [scrollable]="true" [scrollHeight]="isMobileMode ? 'calc(100vh - 260px)' : 'calc(100vh - 460px)'">
                </ng-table>
            </div>
            <div class="col-12 action-button-container popup-footer mti-15">
                <button type="button" data-dismiss="modal" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" value="Close"
                    (click)="cancel()">Close</button>
            </div>
        </div>
    </div>
</pos-edit-wrapper>
