import { Injectable } from '@angular/core';
import { TarkLocalProvider } from '@tarktech/tark-ng-utils/table/service/tark-local-provider.service';
import { ApplicationStateService } from './application-state.service';
import { FormatWidth, getLocaleDateFormat, getLocaleTimeFormat } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LocalProvider implements TarkLocalProvider {

  constructor(private applicationStateService: ApplicationStateService) { }

  getCurrencyCode(): string {
    return this.applicationStateService.settingParam?.Currency ?? 'USD';
  }

  getLocale(): string {
    return this.applicationStateService.settingParam?.Locale ? this.applicationStateService.settingParam?.Locale : 'en';
  }

  getDateFormat(): string {
    return !this.applicationStateService.settingParam?.DateFormat
      ? getLocaleDateFormat(this.getLocale(), FormatWidth.Short)
      : this.applicationStateService.settingParam?.DateFormat;
  }

  getTimeFormat(): string {
    return !this.applicationStateService.settingParam?.TimeFormat
      ? getLocaleTimeFormat(this.getLocale(), FormatWidth.Short)
      : this.applicationStateService.settingParam?.TimeFormat;
  }
}
