<pos-edit-wrapper (close)="cancelEditing()" [isPopup]="false" [headingIcon]="icons.infoManageInfractionThumbsDown"
    heading="{{infraction.Id ? 'Edit Infraction' : 'Add Infraction'}}">
    <form #infractionForm="ngForm" class="edit-form" (ngSubmit)="submit(infractionForm.valid)" autocomplete="off">
        <context-provider provide="props">
            <div class="col-12 pos-page-body border-top">
                <div class="col-12 pt-pix-5">
                    <pos-row>
                        <pos-column>
                            <pos-form-field label="User" [validationMessages]="{required: 'Please select user.'}">
                                <ng-select posInput [items]="users" bindLabel="name" bindValue="id" [required]="true"
                                    placeholder="Select user" [(ngModel)]="infraction.user_id" name="user">
                                </ng-select>
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Infraction Details">
                                <textarea posInput class="form-control" id="infraction" name="infraction"
                                    [(ngModel)]="infraction.infraction" type="text" rows="5" cols="20"></textarea>
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Infraction Date"
                                [validationMessages]="{required: 'Please enter infraction date.'}">
                                <p-calendar posInput [readonlyInput]="true" [required]="true" [dateFormat]="dateFormat"
                                    [inputStyle]="{'width':'unset','display':'unset'}" inputStyleClass="form-control"
                                    [monthNavigator]="true" [showIcon]="true" name="effectiveDate"
                                    [(ngModel)]="infraction.InfractionDate" appendTo="body" #infractionDate="ngModel">
                                </p-calendar>
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Points Assessed"
                                [validationMessages]="{required: 'Please enter points assessed.', max: 'Points assessed must be less than or equal to 99.'}">
                                <input posInput type="number" name="points" #points="ngModel" class="form-control col-120"
                                    posOnlyNumbers [(ngModel)]="infraction.points" [required]="true" max="99" min="0" />
                            </pos-form-field>
                        </pos-column>
                    </pos-row>
                </div>
            </div>
        </context-provider>
        <div class="col-12 action-button-container page-footer">
            <button type="submit" value="Save" class="btn-primary btn-lg white btn btn-save pos-button"
                data-dismiss="modal">Save</button>
            <button type="button" class="btn-secondary btn-lg btn btn-cancel pos-button" (click)="cancelEditing()"
                value="Cancel"> Cancel
            </button>
        </div>
        <div class="clearfix"></div>
    </form>
</pos-edit-wrapper>
