import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DateColumn } from '@tarktech/tark-ng-utils/table/date-column';
import { GridColumn } from '@tarktech/tark-ng-utils/table/grid-column';
import { TemplateColumn } from '@tarktech/tark-ng-utils/table/template-column';
import { TextAlign } from '@tarktech/tark-ng-utils/table/text-align';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { finalize } from 'rxjs/internal/operators/finalize';
import { SalesProduct } from 'src/app/information-management/sales-products/interface/sales-product';
import { SalesProductsService } from 'src/app/information-management/sales-products/services/sales-products.service';
import { BaseListComponent } from 'src/app/shared/components/base-list.component';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { ButtonService } from 'src/app/shared/services/button.service';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import { Button } from '../../interface/button';
import { ButtonHistory } from '../../interface/button-history';
import { ButtonHistoryService } from '../../service/button-history-service';
import { check } from 'src/app/shared/components/icon';
import { TableConfiguration } from 'src/app/shared/table/table-configuration';
import { FilterConfiguration } from 'src/app/shared/interface';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'pos-button-history',
  templateUrl: './button-history.component.html',
})
export class ButtonHistoryComponent extends BaseListComponent<ButtonHistory> implements OnInit {

  buttonHistory: Array<ButtonHistory> = [];
  buttonHistoryColumns = [];
  buttonName = '';
  id: number;
  @ViewChild('toggleTemplate', { static: true }) private toggleTemplate: any;
  icons = {
    check
  };
  salesProducts: Array<SalesProduct> = [];

  totalRecords = 0;
  filterConfiguration: FilterConfiguration = {
    PageNumber: 1, PageSize: TableConfiguration.pageSize,
    SortField: 'DateAdded', SortOrder: -1, FilterValue: null
  };
  isCalledFromOnInit = true;

  constructor(protected buttonHistoryService: ButtonHistoryService,
    protected salesProductService: SalesProductsService,
    protected buttonService: ButtonService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected applicationStateService: ApplicationStateService,
    protected route: ActivatedRoute,
    private router: Router,
    protected printService: PrintTableService) {
    super(buttonHistoryService, alertService, spinnerService, modalService, applicationStateService, printService, route);
    this.id = parseInt(route.snapshot.params.id, 10) ?? 0;
    this.deleteSuccessMessage = Messages.ButtonHistoryDeletedSuccess;
  }

  ngOnInit(): void {
    this.isPrintIconVisible = false;
    this.buttonHistoryColumns = this.configureGridColumns();
  }

  reload() {
    if (this.id) {
      this.buttonHistoryService.getButtonHistoryByButtonId(this.id).subscribe({
        next: (response) => {
          this.buttonHistory = response;
        }
      });

      const observable: Array<Observable<any>> = [];
      observable.push(this.buttonService.get(this.id));
      observable.push(this.salesProductService.getSalesProducts());
      observable.push(this.buttonHistoryService.getTotalRecordsCount(this.id));
      this.spinnerService.show();
      forkJoin(observable).pipe(finalize(() => {
        this.spinnerService.hide();
      })).subscribe({
        next: (response: [Button, Array<SalesProduct>, number]) => {
          this.buttonName = response[0].Selection;
          this.salesProducts = response[1];
          this.totalRecords = response[2];
          if (!this.isCalledFromOnInit) {
            this.getButtonHistories();
          }
          this.isCalledFromOnInit = false;
        },
        error: this.alertService.showApiError
      });
    }
  }

  editItem(id: number) {
    this.router.navigate([id], { relativeTo: this.route });
  }

  lazyLoad(event) {
    this.filterConfiguration.PageSize = event.rows;
    this.filterConfiguration.PageNumber = Math.ceil((event.first + 1) / event.rows);
    this.filterConfiguration.SortField = event.sortField;
    this.filterConfiguration.SortOrder = event.sortOrder;
    this.filterConfiguration.FilterValue = event.globalFilter;
    this.getButtonHistories();
  }

  getButtonHistories() {
    this.spinnerService.show();
    this.buttonHistoryService.getButtonHistories(this.id, this.filterConfiguration)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          this.buttonHistory = response;
        }
      });
  }

  getConfirmDeleteMessage() {
    return Messages.ButtonHistoryDeleteConfirm;
  }

  getGridColumns() {

    const toggleColumn = new TemplateColumn({
      HeaderText: 'Toggle',
      itemTemplate: this.toggleTemplate,
      TextAlign: TextAlign.Center,
      Width: '10%',
      Field: 'Toggle',
      IsSortable: true
    });

    return [
      new GridColumn({ Field: 'ButtonText', HeaderText: 'Button Text', Width: '28%', IsSortable: true }),
      new GridColumn({ Field: 'SalesProduct.Name', HeaderText: 'Product', Width: '26%' }),
      toggleColumn,
      new GridColumn({ Field: 'ButtonType', HeaderText: 'Button Type', Width: '13%', IsSortable: true }),
      new DateColumn({ Field: 'DateAdded', HeaderText: 'Created On', Width: '16%', TextAlign: TextAlign.Left, IsSortable: true }),
    ];
  }

  onCancel() {
    this.router.navigate(['manage/app-configuration/buttons']);
  }
}
