<pos-edit-wrapper heading="Serve Screen" (close)="cancel()" [headingIcon]="icons.rocket" [isPopup]="false">
    <form (ngSubmit)="saveServeScreenWorkflow(serveScreenWorkflowForm.valid)" #serveScreenWorkflowForm="ngForm">
        <context-provider provide="props">
            <div class="col-12 border-top pt-pix-5">
                <div class="col-12 pos-modal-body content-scroll">
                    <pos-row>
                        <pos-column>
                            <pos-form-field inputStyle="col-12">
                              <p-checkbox name="showNotifyButton"
                                [(ngModel)]="serveScreenWorkflowParameters.ItemReadyShowNotifyButtonOnServeScreen" binary="true"
                                #showNotifyButton="ngModel" label="Show Notify button on Products"></p-checkbox>
                            </pos-form-field>
                          </pos-column>
              
                          <pos-column class="pos-sub-content row g-0">
                            <!-- Notify via SMS -->
                            <pos-column>
                              <pos-row [feature]="features.SMSNotification">
                                <pos-column>
                                  <pos-form-field>
                                    <p-checkbox name="notifyViaSMS" [(ngModel)]="chkSendViaSMS" binary="true" #notifyViaSMS="ngModel"
                                      [disabled]="!serveScreenWorkflowParameters.ItemReadyShowNotifyButtonOnServeScreen"
                                      label="Notify via SMS">
                                    </p-checkbox>
                                  </pos-form-field>
                                </pos-column>
                                <pos-column class="pos-sub-content">
                                  <pos-form-field label="Text to send" [validationMessages]="{required: 'Please enter text for SMS.'}"
                                    class="position-relative">
                                    <textarea posInput class="form-control" name="sms" rows="5" #smsText="ngModel"
                                      (click)="setCaretPosition('smsText')" (keyup)="setCaretPosition('smsText')"
                                      [(ngModel)]="serveScreenWorkflowParameters.ItemReadyNotifyViaSMSText"
                                      [disabled]="!chkSendViaSMS || !serveScreenWorkflowParameters.ItemReadyShowNotifyButtonOnServeScreen"
                                      maxlength="2000" id="smsText"
                                      [required]="chkSendViaSMS && serveScreenWorkflowParameters.ItemReadyShowNotifyButtonOnServeScreen"></textarea>
                                    <span class="me-pix-20 mt-pix-n30 position-relative float-end">
                                      <span class="font-20 pointer grey"
                                        [ngClass]="{'disabled': !chkSendViaSMS || !serveScreenWorkflowParameters.ItemReadyShowNotifyButtonOnServeScreen}">
                                        <pos-icon [icon]="icons.code" (click)="isOpenVariableForSMS = !isOpenVariableForSMS">
                                        </pos-icon>
                                      </span>
                                      <div *ngIf="isOpenVariableForSMS" class="col-200 order-variable-select">
                                        <ng-select [items]="orderItemVariables" placeholder="Select order variable" #orderVariableForSMS
                                          id="orderVariableForSMS" name="orderVariableForSMS" appendTo="body"
                                          (change)="changeOrderVariable(selectedOrderVariable, serveScreenWorkflowParameters.ItemReadyNotifyViaSMSText, 'smsText')"
                                          [isOpen]="true" [(ngModel)]="selectedOrderVariable">
                                        </ng-select>
                                      </div>
                                    </span>
                                  </pos-form-field>
                                </pos-column>
                              </pos-row>
                            </pos-column>
              
                            <!-- Notify via DJ terminal -->
                            <pos-column>
                              <pos-row>
                                <pos-column>
                                  <pos-form-field>
                                    <p-checkbox posInput name="notifyViaDJ" [(ngModel)]="chkSendViaDJ" binary="true"
                                      #notifyViaDJ="ngModel"
                                      [disabled]="!serveScreenWorkflowParameters.ItemReadyShowNotifyButtonOnServeScreen"
                                      label="Notify via DJ Terminal">
                                    </p-checkbox>
                                  </pos-form-field>
                                </pos-column>
                                <pos-column class="pos-sub-content">
                                  <pos-form-field [validationMessages]="{ required: 'Please select dj terminal.' }">
                                    <ng-select posInput name="djTerminal" appendTo="body" class="col-300"
                                      [ngClass]="{'select-disabled': !chkSendViaDJ || !serveScreenWorkflowParameters.ItemReadyShowNotifyButtonOnServeScreen}"
                                      placeholder="Select DJ terminal" [items]="djTerminals" bindLabel="TerminalName"
                                      [required]="chkSendViaDJ && serveScreenWorkflowParameters.ItemReadyShowNotifyButtonOnServeScreen"
                                      [(ngModel)]="serveScreenWorkflowParameters.ItemReadyNotifyViaDJTerminal" bindValue="TerminalId">
                                    </ng-select>
                                  </pos-form-field>
                                </pos-column>
              
                                <pos-column class="pos-sub-content">
                                  <pos-form-field label="Text to speak"
                                    [validationMessages]="{required: 'Please enter text for DJ.'}">
                                    <textarea posInput class="form-control" name="djText" rows="5" id="djTerminalText"
                                      (click)="setCaretPosition('djTerminalText')" (keyup)="setCaretPosition('djTerminalText')"
                                      [(ngModel)]="serveScreenWorkflowParameters.ItemReadyNotifyViaDJText" #djTerminalText="ngModel"
                                      [required]="chkSendViaDJ && serveScreenWorkflowParameters.ItemReadyShowNotifyButtonOnServeScreen"
                                      [disabled]="!chkSendViaDJ || !serveScreenWorkflowParameters.ItemReadyShowNotifyButtonOnServeScreen"
                                      maxlength="2000"></textarea>
                                    <span class="me-pix-20 mt-pix-n30 position-relative float-end">
                                      <span class="font-20 pointer grey"
                                        [ngClass]="{'disabled': !chkSendViaDJ || !serveScreenWorkflowParameters.ItemReadyShowNotifyButtonOnServeScreen}">
                                        <pos-icon [icon]="icons.code"
                                          (click)="isOpenVariableForDJTerminal = !isOpenVariableForDJTerminal"></pos-icon>
                                      </span>
                                      <div *ngIf="isOpenVariableForDJTerminal" class="col-200 order-variable-select">
                                        <ng-select [items]="orderItemVariables" placeholder="Select order variable"
                                          #orderVariableForDJTerminal id="orderVariableForDJTerminal"
                                          name="orderVariableForDJTerminal" [isOpen]="true" [(ngModel)]="selectedOrderVariable"
                                          (change)="changeOrderVariable(selectedOrderVariable, serveScreenWorkflowParameters.ItemReadyNotifyViaDJText, 'djTerminalText')">
                                        </ng-select>
                                      </div>
                                    </span>
                                  </pos-form-field>
                                </pos-column>
              
                                <pos-column class="pos-sub-content">
                                  <pos-form-field label="Voice" [validationMessages]="{required: 'Please select voice.'}">
                                    <ng-select posInput name="djAudioVoices" class="col-300"
                                      [ngClass]="{'select-disabled': !chkSendViaDJ || !serveScreenWorkflowParameters.ItemReadyShowNotifyButtonOnServeScreen}"
                                      placeholder="Select voice" [items]="audioVoices" bindLabel="Name"
                                      [required]="chkSendViaDJ && serveScreenWorkflowParameters.ItemReadyShowNotifyButtonOnServeScreen"
                                      [(ngModel)]="serveScreenWorkflowParameters.ItemReadyNotifyViaDJVoice">
                                    </ng-select>
                                  </pos-form-field>
                                </pos-column>
              
                                <pos-column md="6" xs="12" class="pos-sub-content">
                                  <pos-form-field label="Volume">
                                    <div class="row">
                                      <pos-column xs="11">
                                        <ngx-slider [(value)]="serveScreenWorkflowParameters.ItemReadyNotifyViaDJVolume"
                                          [options]="options"
                                          [ngClass]="{'pointers-none disable-volume-slider': !chkSendViaDJ || !serveScreenWorkflowParameters.ItemReadyShowNotifyButtonOnServeScreen}">
                                        </ngx-slider>
                                      </pos-column>
                                      <pos-column xs="1" class="p-0">
                                        <span
                                          class="badge mt-pix-n2 volume-circle">{{serveScreenWorkflowParameters.ItemReadyNotifyViaDJVolume}}</span>
                                      </pos-column>
                                    </div>
                                  </pos-form-field>
                                </pos-column>
                              </pos-row>
                            </pos-column>
              
                            <!-- Notify via Audio Notifier -->
                            <pos-column>
                              <pos-row>
                                <pos-column>
                                  <pos-form-field>
                                    <p-checkbox posInput name="notifyViaAudioNotifier" [(ngModel)]="chkSendViaAudioNotifier"
                                      binary="true" #notifyViaAudioNotifier="ngModel" label="Notify via Audio Notifier"
                                      [disabled]="!serveScreenWorkflowParameters.ItemReadyShowNotifyButtonOnServeScreen">
                                    </p-checkbox>
                                  </pos-form-field>
                                </pos-column>
              
                                <pos-column class="pos-sub-content">
                                  <pos-form-field [validationMessages]="{ required: 'Please select audio notifier.' }">
                                    <ng-select posInput name="audioNotifier" class="col-300"
                                      [ngClass]="{'select-disabled': !chkSendViaAudioNotifier || !serveScreenWorkflowParameters.ItemReadyShowNotifyButtonOnServeScreen}"
                                      appendTo="body" placeholder="Select audio notifier" [items]="audioDevices" bindLabel="Name"
                                      [required]="chkSendViaAudioNotifier && serveScreenWorkflowParameters.ItemReadyShowNotifyButtonOnServeScreen"
                                      [(ngModel)]="serveScreenWorkflowParameters.ItemReadyNotifyViaAudioNotifier" bindValue="Id">
                                    </ng-select>
                                  </pos-form-field>
                                </pos-column>
              
                                <pos-column class="pos-sub-content">
                                  <pos-form-field label="Text to speak"
                                    [validationMessages]="{required: 'Please enter text for audio.'}">
                                    <textarea posInput class="form-control" name="audioTextarea" rows="5" id="audioText"
                                      (click)="setCaretPosition('audioText')" (keyup)="setCaretPosition('audioText')"
                                      [(ngModel)]="serveScreenWorkflowParameters.ItemReadyNotifyViaAudioNotifierText"
                                      #audioText="ngModel"
                                      [required]="chkSendViaAudioNotifier && serveScreenWorkflowParameters.ItemReadyShowNotifyButtonOnServeScreen"
                                      [disabled]="!chkSendViaAudioNotifier || !serveScreenWorkflowParameters.ItemReadyShowNotifyButtonOnServeScreen"
                                      maxlength="2000"></textarea>
                                    <span class="me-pix-20 mt-pix-n30 position-relative float-end">
                                      <span class="font-20 pointer grey"
                                        [ngClass]="{'disabled': !chkSendViaAudioNotifier || !serveScreenWorkflowParameters.ItemReadyShowNotifyButtonOnServeScreen}">
                                        <pos-icon [icon]="icons.code"
                                          (click)="isOpenVariableForAudioNotifier = !isOpenVariableForAudioNotifier"></pos-icon>
                                      </span>
                                      <div *ngIf="isOpenVariableForAudioNotifier" class="col-200 order-variable-select">
                                        <ng-select [items]="orderItemVariables" placeholder="Select order variable"
                                          name="orderVariableForAudioNotifier" [isOpen]="true"
                                          (change)="changeOrderVariable(selectedOrderVariable, serveScreenWorkflowParameters.ItemReadyNotifyViaAudioNotifierText, 'audioText')"
                                          [(ngModel)]="selectedOrderVariable">
                                        </ng-select>
                                      </div>
                                    </span>
                                  </pos-form-field>
                                </pos-column>
              
                                <pos-column class="pos-sub-content">
                                  <pos-form-field label="Voice" [validationMessages]="{required: 'Please select voice.'}">
                                    <ng-select posInput name="audioVoices" class="col-300"
                                      [ngClass]="{'select-disabled': !chkSendViaAudioNotifier || !serveScreenWorkflowParameters.ItemReadyShowNotifyButtonOnServeScreen}"
                                      placeholder="Select voice" [items]="audioVoices" bindLabel="Name"
                                      [required]="chkSendViaAudioNotifier && serveScreenWorkflowParameters.ItemReadyShowNotifyButtonOnServeScreen"
                                      [(ngModel)]="serveScreenWorkflowParameters.ItemReadyNotifyViaAudioNotifierVoice">
                                    </ng-select>
                                  </pos-form-field>
                                </pos-column>
              
                                <pos-column md="6" xs="12" class="pos-sub-content">
                                  <pos-form-field label="Volume">
                                    <div class="row">
                                      <pos-column xs="11">
                                        <ngx-slider [(value)]="serveScreenWorkflowParameters.ItemReadyNotifyViaAudioNotifierVolume"
                                          [options]="options"
                                          [ngClass]="{'pointers-none disable-volume-slider': !chkSendViaAudioNotifier || !serveScreenWorkflowParameters.ItemReadyShowNotifyButtonOnServeScreen}">
                                        </ngx-slider>
                                      </pos-column>
                                      <pos-column xs="1" class="p-0">
                                        <span
                                          class="badge mt-pix-n2 volume-circle">{{serveScreenWorkflowParameters.ItemReadyNotifyViaAudioNotifierVolume}}</span>
                                      </pos-column>
                                    </div>
                                  </pos-form-field>
                                </pos-column>
                              </pos-row>
                            </pos-column>
                          </pos-column>
                    </pos-row>
                </div>
            </div>
            <div class="col-12 action-button-container page-footer">
                <button class="btn-primary btn-lg btn btn-save pos-button" scrollup type="submit" value="Save">Save</button>
                <button class="btn-secondary btn-lg btn btn-cancel pos-button" routerLink="../" scrollup type="button"
                    value="Cancel">Cancel</button>
            </div>
        </context-provider>
    </form>
</pos-edit-wrapper>
