<div>
  <div>
    <button class="close" (click)="hide()">&times;</button>
    <h4 class="modal-content__heading">Clock In/Clock Out</h4>
  </div>
  <div class="modal-body">
    <div class="font-20">
      {{userClockInStatusMessage}}
    </div>
  </div>
  <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 action-button-container popup-footer pb-pix-10">
    <button class="btn btn-primary btn-lg btn-save pos-button" (click)="clockedInOutToSystem()" type="button">
      {{clockInButtonText}}
    </button>
    <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="hide()" type="button">
      Close
    </button>
  </div>
  <div class="clearfix"></div>
</div>
