import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertsService, SpinnerService, Messages, FormUtilityService, BaseFormComponent } from 'src/app/shared';
import { NgForm } from '@angular/forms';
import { ExternalApiObj, ExternalApi, ExternalApiParameter } from '../../interface/external-api';
import { ExternalApiService } from '../../../../shared/services/external-api.service';
import { DomainConstants } from 'src/app/shared/constants/domain-constants';
import { FieldInfoMessages } from 'src/app/shared/constants/field-info-messages';
import * as _ from 'lodash';
import { ActivatedRoute, Navigation, Router } from '@angular/router';
import { forEach } from 'lodash';
import { externalApiLink, times } from 'src/app/shared/components/icon/icons';

@Component({
    selector: 'pos-external-api-edit',
    templateUrl: './external-api-edit.component.html'
})
export class ExternalApiEditComponent extends BaseFormComponent implements OnInit {
    @ViewChild('formExternalApi') formExternalApi: NgForm;
    get getForm(): NgForm {
        return this.formExternalApi
    }
    externalApi: ExternalApiObj;
    saveExternalApiObj: ExternalApi;
    externalApiHeaderParam: ExternalApiParameter;
    navigation: Navigation;
    apiVerbs = DomainConstants.ApiVerbs;
    authorizationTypes = DomainConstants.AuthorizationTypes;
    parameterTypes = DomainConstants.ParameterTypes;
    queryStringId: number;
    bodyId: number;
    headerId: number;
    isQueryParam = false;
    isCopy = false;
    basicAuthTypeId: number;
    basicAuthTypeName: string;
    tokenAuthTypeId: number;
    tokenAuthTypeName: string;
    noAuthTypeId: number;
    noAuthTypeName: string;
    postVerbId: number;
    toggleShowHideParamsGrid = false;
    toggleShowHideAuthTypeBasic = false;
    toggleShowHideAuthTypeToken = false;
    toggleShowHideBodyParamsGrid = false;
    fieldInfoMessages = FieldInfoMessages;
    httpResponse = {};
    externalApiId: number;
    icons = { externalApiLink, times }
    props = {
        controlBoxClass: 'col-lg-4 col-md-6 col-sm-10 col-xs-12'
    }
    constructor(
        private externalApiService: ExternalApiService,
        private alertService: AlertsService,
        private spinnerService: SpinnerService,
        private route: ActivatedRoute,
        private router: Router,
        formUtilityService: FormUtilityService
    ) {
        super(formUtilityService);
        this.externalApi = this.externalApiService.newExternalApi();
        this.saveExternalApiObj = {
            Id: 0, Name: '', ApiVerb: null, Url: '', DateAdded: new Date(), DateEdited: null,
            Parameters: []
        };
        this.externalApiHeaderParam = { Id: 0, ApiId: 0, ParamName: '', ParamValue: '', ParamTypeId: 0, DateAdded: new Date(), DateEdited: null };
        this.navigation = router.getCurrentNavigation();
        this.externalApi.Id = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
        this.isCopy = (this.navigation?.extras?.state && this.navigation?.extras?.state.isCopy) ? this.navigation?.extras?.state.isCopy : false;

        const queryString = _.find(this.parameterTypes, (item) => item.Name === 'QueryString');
        this.queryStringId = queryString.id;
        const body = _.find(this.parameterTypes, (item) => item.Name === 'Body');
        this.bodyId = body.id;
        const header = _.find(this.parameterTypes, (item) => item.Name === 'Header');
        this.headerId = header.id;

        const basic = _.find(this.authorizationTypes, (item) => item.Name === 'Username/Password');
        this.basicAuthTypeId = basic.id;
        this.basicAuthTypeName = basic.Name;
        const token = _.find(this.authorizationTypes, (item) => item.Name === 'Token');
        this.tokenAuthTypeId = token.id;
        this.tokenAuthTypeName = token.Name;
        const noAuth = _.find(this.authorizationTypes, (item) => item.Name === 'No Authentication');
        this.noAuthTypeId = noAuth.id;
        this.noAuthTypeName = noAuth.Name;

        const postVerb = _.find(this.apiVerbs, (item) => item.Name === 'POST');
        this.postVerbId = postVerb.id;
    }

    ngOnInit(): void {
        this.getExternalApi(this.externalApi.Id);
    }
    createExternalApiQueryParamRow(lastRow: boolean) {
        if (!lastRow) {
            return;
        }
        const paramsObj = {
            Id: 0,
            ApiId: this.externalApi.Id,
            ParamName: '',
            ParamValue: '',
            ParamTypeId: this.queryStringId,
            DateAdded: new Date(),
            DateEdited: null
        };
        this.externalApi.ExternalApiQueryParams.push(paramsObj);
    }
    createExternalApiBodyParamRow(lastRow: boolean) {
        if (!lastRow) {
            return;
        }
        const paramsObj = {
            Id: 0,
            ApiId: this.externalApi.Id,
            ParamName: '',
            ParamValue: '',
            ParamTypeId: this.bodyId,
            DateAdded: new Date(),
            DateEdited: null
        };
        this.externalApi.ExternalApiBodyParams.push(paramsObj);
    }
    getExternalApi(externalApiId: number) {
        if (externalApiId > 0) {
            this.spinnerService.show();
            this.externalApiService.getExternalApi(externalApiId).subscribe(
                (response: ExternalApi) => {
                    this.externalApi.Id = response.Id;
                    this.externalApi.Name = this.isCopy ? response.Name + ' (copy)' : response.Name;
                    this.externalApi.ApiVerb = response.ApiVerb;
                    this.externalApi.Url = response.Url;
                    this.isQueryParam = false;
                    if (response.Parameters && response.Parameters.length) {
                        for (const externalApiParam of response.Parameters) {
                            if (externalApiParam.ParamTypeId === this.queryStringId) {
                                this.isQueryParam = true;
                                this.toggleShowHideParamsGrid = true;
                                this.externalApi.ExternalApiQueryParams.push(externalApiParam);
                            }
                            if (externalApiParam.ParamTypeId === this.bodyId) {
                                this.toggleShowHideBodyParamsGrid = true;
                                this.externalApi.ExternalApiBodyParams.push(externalApiParam);
                            }
                            if (externalApiParam.ParamTypeId === this.headerId) {
                                this.externalApiHeaderParam.Id = this.isCopy ? 0 : externalApiParam.Id;
                                if (externalApiParam.ParamName === this.basicAuthTypeName) {
                                    this.toggleShowHideAuthTypeBasic = true;
                                    this.externalApi.AuthorizationType = this.basicAuthTypeId;
                                    const basicAuthTypeValue = externalApiParam.ParamValue.split(':');
                                    this.externalApi.UserName = basicAuthTypeValue[0];
                                    this.externalApi.Password = basicAuthTypeValue[1];
                                }
                                if (externalApiParam.ParamName === this.tokenAuthTypeName) {
                                    this.toggleShowHideAuthTypeToken = true;
                                    this.externalApi.AuthorizationType = this.tokenAuthTypeId;
                                    this.externalApi.Token = externalApiParam.ParamValue;
                                }
                                if (externalApiParam.ParamName === this.noAuthTypeName) {
                                    this.externalApi.AuthorizationType = this.noAuthTypeId;
                                }
                            }
                        }
                    }
                    if (this.isQueryParam) {
                        this.createExternalApiQueryParamRow(true);
                    }
                    if (parseInt(this.externalApi.ApiVerb) === this.postVerbId) {
                        this.createExternalApiBodyParamRow(true);
                        this.toggleShowHideBodyParamsGrid = true;
                    }
                    if (this.isCopy) {
                        this.externalApi.Id = 0;
                        forEach(this.externalApi.ExternalApiQueryParams, (param) => { param.Id = 0; });
                        forEach(this.externalApi.ExternalApiBodyParams, (param) => { param.Id = 0; });
                    }
                },
                this.alertService.showApiError,
                () => {
                    this.spinnerService.hide();
                }
            );
        } else {
            this.externalApi = this.externalApiService.newExternalApi();
        }
    }
    public saveExternalApi(isValid: boolean): void {
        const isNullExternalApiQueryParam = _.find(this.externalApi.ExternalApiQueryParams,
            (item) => (item.ParamName === null
                || item.ParamName === '') && (item.ParamValue !== null && item.ParamValue !== ''));
        const isNullExternalApiBodyParam = _.find(this.externalApi.ExternalApiBodyParams,
            (item) => (item.ParamName === null
                || item.ParamName === '') && (item.ParamValue !== null && item.ParamValue !== ''));
        if (!isValid || isNullExternalApiQueryParam || isNullExternalApiBodyParam) {
            return;
        }
        this.spinnerService.show();
        this.saveExternalApiObj.Id = this.externalApi.Id;
        this.saveExternalApiObj.Name = this.externalApi.Name;
        this.saveExternalApiObj.ApiVerb = this.externalApi.ApiVerb;
        this.saveExternalApiObj.Url = this.externalApi.Url;
        for (const externalApiQueryParam of this.externalApi.ExternalApiQueryParams) {
            if (externalApiQueryParam.ParamName === null || externalApiQueryParam.ParamName.trim() === '') {
                externalApiQueryParam.ParamTypeId = 0;
            }
            if (externalApiQueryParam.Id || (externalApiQueryParam.ParamName.trim() !== '' && externalApiQueryParam.ParamName !== null)) {
                this.saveExternalApiObj.Parameters.push(externalApiQueryParam);
            }
        }
        if (this.externalApi.AuthorizationType && this.externalApi.AuthorizationType !== null) {
            this.externalApiHeaderParam.ApiId = this.externalApi.Id;
            // tslint:disable-next-line:radix
            if (parseInt(this.externalApi.AuthorizationType.toString()) === this.basicAuthTypeId) {
                this.externalApiHeaderParam.ParamName = this.basicAuthTypeName;
                this.externalApiHeaderParam.ParamValue = this.externalApi.UserName + ':' + this.externalApi.Password;
                // tslint:disable-next-line: radix
            } else if (parseInt(this.externalApi.AuthorizationType.toString()) === this.tokenAuthTypeId) {
                this.externalApiHeaderParam.ParamName = this.tokenAuthTypeName;
                this.externalApiHeaderParam.ParamValue = this.externalApi.Token;
            }
            this.externalApiHeaderParam.ParamTypeId = this.headerId;
            this.saveExternalApiObj.Parameters.push(this.externalApiHeaderParam);
        }
        for (const externalApiBodyParam of this.externalApi.ExternalApiBodyParams) {
            if (externalApiBodyParam.ParamName === null || externalApiBodyParam.ParamName.trim() === '') {
                externalApiBodyParam.ParamTypeId = 0;
            }
            if (externalApiBodyParam.Id || (externalApiBodyParam.ParamName.trim() !== '' && externalApiBodyParam.ParamName !== null)) {
                this.saveExternalApiObj.Parameters.push(externalApiBodyParam);
            }
        }
        if (this.saveExternalApiObj.Id === 0) {
            this.externalApiService.addExternalApi(this.saveExternalApiObj)
                .subscribe({
                    next: (res) => {
                        this.alertService.renderSuccessMessage(Messages.ExternalApiSaveSuccess);
                        this.onCancel();
                    }, error: (errorResponse) => {
                        this.alertService.showApiError(errorResponse);
                    }, complete: () => {
                        this.spinnerService.hide();
                    }
                });
        } else {
            this.externalApiService.updateExternalApi(this.saveExternalApiObj)
                .subscribe({
                    next: (res) => {
                        this.alertService.renderSuccessMessage(Messages.ExternalApiUpdatedSuccess);
                        this.onCancel();
                    }, error: (errorResponse) => {
                        this.alertService.showApiError(errorResponse);
                    }, complete: () => {
                        this.spinnerService.hide();
                    }
                });
        }
    }
    clearExternalApiQueryParamRow(index): void {
        const parameter = this.externalApi.ExternalApiQueryParams[index];
        parameter.ParamTypeId = 0;
        this.externalApi.ExternalApiQueryParams[index] = parameter;
    }
    clearExternalApiBodyParamRow(index): void {
        const parameter = this.externalApi.ExternalApiBodyParams[index];
        parameter.ParamTypeId = null;
        this.externalApi.ExternalApiBodyParams[index] = parameter;
    }
    onParamsBtnClick() {
        if (!this.toggleShowHideParamsGrid) {
            this.toggleShowHideParamsGrid = true;
            if (this.externalApi.ExternalApiQueryParams.length === 0) {
                this.createExternalApiQueryParamRow(true);
            }
        } else {
            this.toggleShowHideParamsGrid = false;
            this.externalApi.ExternalApiQueryParams = [];
        }
    }
    onAuthTypeChange(authType) {
        // tslint:disable-next-line:radix
        if (this.basicAuthTypeId === parseInt(authType)) {
            this.toggleShowHideAuthTypeBasic = true;
            this.toggleShowHideAuthTypeToken = false;
            this.externalApi.Token = '';
        }
        // tslint:disable-next-line:radix
        if (this.tokenAuthTypeId === parseInt(authType)) {
            this.toggleShowHideAuthTypeBasic = false;
            this.toggleShowHideAuthTypeToken = true;
            this.externalApi.UserName = '';
            this.externalApi.Password = '';
        }
        // tslint:disable-next-line: radix
        if (this.noAuthTypeId === parseInt(authType)) {
            this.toggleShowHideAuthTypeBasic = false;
            this.toggleShowHideAuthTypeToken = false;
        }
        // tslint:disable-next-line:radix
        if (!parseInt(authType) || authType === '0') {
            this.toggleShowHideAuthTypeBasic = false;
            this.toggleShowHideAuthTypeToken = false;
            this.externalApi.UserName = '';
            this.externalApi.Password = '';
            this.externalApi.Token = '';
        }
    }
    onAPiVerbChange(apiVerb) {
        if (this.postVerbId === parseInt(apiVerb)) {
            this.toggleShowHideBodyParamsGrid = true;
            if (!this.externalApi.ExternalApiBodyParams.length) {
                this.createExternalApiBodyParamRow(true);
            }
        } else {
            this.toggleShowHideBodyParamsGrid = false;
            _.forEach(this.externalApi.ExternalApiBodyParams, (param) => {
                param.ParamTypeId = null;
            });
        }
    }
    onCancel() {
        this.router.navigate(['external-api'], { relativeTo: this.route.parent });
    }
}
