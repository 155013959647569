import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { BaseCrudComponent } from 'src/app/shared/components/base-crud.component';
import { SpinnerService } from 'src/app/shared/components/spinner';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { MenuDisplay, MenuDisplayConfig } from '../../interfaces/menu-display';
import { MenuDisplayService } from '../../services/menu-display.service';
import { NgForm } from '@angular/forms';
import { FormUtilityService } from 'src/app/shared/services/form-utility.service';
import { displayMenu } from 'src/app/shared/components/icon';

@Component({
  selector: 'pos-menu-display-edit',
  templateUrl: './menu-display-edit.component.html',
  styleUrls: ['./menu-display-edit.component.scss']
})
export class MenuDisplayEditComponent extends BaseCrudComponent<MenuDisplay> implements OnInit {
  @ViewChild('digitalMenuForm') digitalMenuForm: NgForm;
  id: number;
  digitalMenu: MenuDisplay;
  menuConfig: MenuDisplayConfig;
  menuName: string;
  get getForm(): NgForm {
    return this.digitalMenuForm
  }
  icons = {
    displayMenu
  }
  constructor(
    protected menuDisplayService: MenuDisplayService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    formUtilityService: FormUtilityService) {
    super(menuDisplayService, alertService, spinnerService, formUtilityService);
    this.saveSuccessMessage = Messages.DigitalMenuSaveSuccess;
    this.digitalMenu = this.menuDisplayService.newMenuDisplay();
    this.menuConfig = this.menuDisplayService.newMenuDisplayConfig();
    this.id = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
  }

  ngOnInit(): void {
    this.loadDataDigitalMenu();
  }

  loadDependencies() {
  }

  loadDataDigitalMenu() {
    if (this.id) {
      this.spinnerService.show();
      this.loadData().pipe(finalize(() => {
        this.spinnerService.hide();
      })).subscribe({
        next: (response: MenuDisplay) => {
          this.digitalMenu = response;
          this.menuConfig = response.Config ? JSON.parse(response.Config) : this.menuConfig;
          this.menuName = response.Name;
        }, error: this.alertService.showApiError
      });
    }
  }

  submit(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.digitalMenu.Config = JSON.stringify(this.menuConfig);
    this.spinnerService.show();
    this.menuDisplayService.insertOrUpdate(this.digitalMenu).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: () => {
        this.onSaveSuccess();
      }, error: this.alertService.showApiError
    });
  }


  onSaveSuccess() {
    this.cancel();
    this.alertService.renderSuccessMessage(this.saveSuccessMessage);
  }

  cancel() {
    this.router.navigate(['/manage/menu-display']);
  }

}
