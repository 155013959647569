import { ElementRef, ViewChild } from '@angular/core';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'pos-export-dropdown',
  templateUrl: './export-dropdown.component.html',
  styleUrls: ['./export-dropdown.component.scss']
})
export class ExportDropdownComponent implements OnInit {
  btnExport = false;
  @Output() onPrint = new EventEmitter<boolean>();
  @Output() onCSV = new EventEmitter<boolean>();
  public printIcon = 'fa-print';
  public exportIcon = 'fa-file-export';
  public actionIcon = 'fa-clipboard-list';
  public offsetRight = '0xp';
  @ViewChild('posDropdown', { read: ElementRef, static: true }) posDropdown: ElementRef;
  @HostListener('document:click', ['$event'])
  onClickEvent(event: MouseEvent) {
    if (event.target['id'] !== 'tarkDropdown' && event.target['id'] !== 'dropdownAction' && event.target['id'] !== 'dropdownAction') {
      this.btnExport = false;
    }
    this.updateXPosition();
  }

  constructor() { }

  ngOnInit(): void {
  }

  updateXPosition() {
    const body = document.querySelector('body').getBoundingClientRect().right;
    const parent = body - this.posDropdown.nativeElement?.offsetParent?.getBoundingClientRect().right;
    const dropdown = body - this.posDropdown.nativeElement.getBoundingClientRect().right;
    this.offsetRight = (dropdown - (parent ? parent : 0)).toString() + 'px';
  }

  dataPrint() {
    this.onPrint.emit(true);
  }
  exportCSV() {
    this.onCSV.emit(true);
  }

}
