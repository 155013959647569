<pos-edit-wrapper (close)="closeModal()" heading="{{selectedButtonBehaviorType.Text}}">
    <form #displayMessageForm="ngForm" autocomplete="off" (submit)='saveBehavior(displayMessageForm.valid)'>
        <div class="col-12 screen-scroll pos-modal-body">
            <pos-row class="g-0">
                <pos-column>
                    <pos-form-field label="Markup"
                        [validationMessages]="{required: 'Please enter markup percentage.', range: 'Please enter value between 0 to 100.'}"
                        inputStyle="col-lg-7 col-md-6 col-9">
                        <p class="input-group col-150">
                            <input posInput type="number" min="0" max="100" name="percent" #Percent="ngModel"
                                class="form-control" [(ngModel)]="markupPercent" [required]="true"
                                [range]="[0, 100]" />
                            <span class="input-group-addon">%</span>
                        </p>
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save</button>
            <button type="button" (click)="closeModal()"
                class="btn-secondary btn-lg btn btn-cancel-modal pos-button">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
