import { Injectable } from '@angular/core';
import { Route } from '@angular/router';
import { forEach } from 'lodash';
import { DeactivateGuard } from '../guards/deactivate.guard';

@Injectable({
  providedIn: 'root'
})
export class RouteConfigService {

  static addGuardsToRoutes(routes: Array<Route>) {
    forEach(routes, (route) => {
      route.canDeactivate = [DeactivateGuard];
    });
  }
}
