import { Component, OnInit, Input, HostListener } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { SpinnerService, AlertsService, OrderService, ApplicationStateService, Messages } from 'src/app/shared';
import * as _ from 'lodash';
import { KeyValue } from '@angular/common';
import { nutritionPrint } from 'src/app/shared/components/icon';
import { orderBy } from 'lodash';
declare var $;
@Component({
  selector: 'pos-order-entry-recipe-info',
  templateUrl: './order-entry-recipe-info.component.html',
  styleUrls: ['./order-entry-recipe-info.component.scss']
})
export class OrderEntryRecipeInfoComponent implements OnInit {
  @Input("productId") productId: number;
  height: number;
  loadIngredientsData: any;
  recipes: any = [];
  public icons = {
    nutritionPrint
  };

  constructor(private orderService: OrderService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private applicationStateService: ApplicationStateService) { }

  ngOnInit() {
    this.getIngredientData();
    this.height = $(window).height() - 500 < 150 ? 150 : 340;
    document.documentElement.style.setProperty('--p-table-wrapper-height', this.height + 'px');
  }

  @HostListener('window:resize', ['$event'])
  resizeWindow = () => {
    this.height = $(window).height() - 500 < 150 ? 150 : 340;
    document.documentElement.style.setProperty('--p-table-wrapper-height', this.height + 'px');
  }

  getIngredientData() {
    this.orderService.getIngredientsInfo(this.productId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: any) => {
          const details = orderBy(response.ProductIngredientModel, i => i.Ordinal);
          this.loadIngredientsData = {};
          if (details) {
            for (let i = 0; i < details.length; i++) {
              let rowData = details[i];
              let sizeName = rowData.SizeName;
              if (i == 0) {
                this.loadIngredientsData[sizeName] = { index: 0, size: 1 };
              } else {
                let previousRowData = details[i - 1];
                let previousRowGroup = previousRowData.SizeName;
                if (sizeName === previousRowGroup) {
                  this.loadIngredientsData[sizeName].size++;
                }
                else {
                  this.loadIngredientsData[sizeName] = { index: i, size: 1 };
                }
              }
            }
          }
          this.recipes = orderBy(response.ProductIngredientModel, i => i.Ordinal);
        }, error: this.alertService.showApiError
      });
  }

  originalOrder = (firstKey: KeyValue<number, string>, secondKey: KeyValue<number, string>): number => {
    return 0;
  }

  printNutritionFactInfo(sizeId, inventoryProductId) {
    var printObj = {
      SalesProductId: this.productId,
      SizeId: sizeId,
      TerminalId: this.applicationStateService.terminalId,
      TerminalName: this.applicationStateService.terminalName,
      InventoryProductId: inventoryProductId
    }
    if (!this.applicationStateService.settingParam.ReceiptPrinter?.Id) {
      this.alertService.renderErrorMessage(Messages.ReceiptPrinterNotMapped);
    } else {
      this.spinnerService.show();
      this.orderService.printSalesRecipeNutritionFacts(printObj)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (response) => {

          }, error: this.alertService.showApiError
        });
    }
  }

}
