<pos-edit-wrapper heading="Review Order" (close)="invoiceConfirmation()">
  <div class="col-12 pos-modal-body row mx-0 screen-scroll" frameborder="0" [ngStyle]="{'height':confirmOrderHeight + 'px'}">
    <div id="divConfirmOrder" class="row flex-column g-0">
      <div class="order-item-border col-12 ps-0 pe-0" *ngIf="orderData.OrderAttributes.PagerNumber">
        <strong>Pager Number: {{orderData.OrderAttributes.PagerNumber}}</strong>
      </div>
      <div class="order-item-border col-12 ps-0 pe-0" *ngIf="orderData.DiscountPct">
        <strong>Discount {{orderData.DiscountPct * -1}}%</strong>
      </div>
      <div class="order-item-border col-12 ps-0 pe-0" *ngIf="orderData.MarkupPct">
        <strong>Markup {{orderData.MarkupPct}}%</strong>
      </div>

      <div class="order-item-border col-12 ps-0 pe-0" *ngIf="orderData.OrderAttributes.IsCallIn">
        <strong>Call In: {{ orderData.OrderAttributes.CallInOrderTime }}</strong>
      </div>

      <div class="order-item-border col-12 ps-0 pe-0" *ngIf="orderData.OrderAttributes.Name">
        <strong>Name: {{ orderData.OrderAttributes.Name }}</strong>
      </div>

      <div class="order-item-border col-12 ps-0 pe-0" *ngIf="orderData.OrderAttributes.PhoneNumber">
        <strong>Phone: {{ orderData.OrderAttributes.PhoneNumber | phoneMaskPipe }}</strong>
      </div>

      <div class="order-item-border col-12 ps-0 pe-0" *ngIf="orderData.DeliveryFee">
        <strong>Delivery Fee</strong>
      </div>

      <div *ngFor="let item of seats | keyvalue">
        <div class="order-item-border col-12 ps-0 pe-0" *ngFor="let product of item.value">
          <div>
            <strong ng-if="!product.IsDeliveryFee">
              {{product.MakeTableText}} <span
                *ngIf="product.Qty && product.OrderItemType ==  orderItemTypes.Product">({{product.Qty}})</span>
            </strong>

            <div class="order-page__comment" *ngFor="let component of product.OrderProductComponents"
              [hidden]="product.OrderItemType != orderItemTypes.Product">
              <div class="order-page__comment__text ps-pix-5">{{component.ButtonText}}</div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="col-12 action-button-container popup-footer">
    <button class="btn btn-lg btn-danger btn-save pos-button" (click)="invoiceConfirmation()" type="button">
      <pos-icon [icon]="icons.exclamationCircle" class="font-17"></pos-icon> Issue Found
    </button>
    <button class="btn btn-lg btn-success white btn-cancel-modal pos-button" (click)="invoiceConfirmation(true)" type="button">
      <pos-icon [icon]="icons.checkCircleSolid" class="font-17"></pos-icon> Confirmed
    </button>
  </div>
</pos-edit-wrapper>
