import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { finalize } from 'rxjs/internal/operators/finalize';
import { SalesSize } from 'src/app/information-management/sales-sizes/interface/sales-size';
import { BaseCrudComponent } from 'src/app/shared/components/base-crud.component';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { FormUtilityService } from 'src/app/shared/services/form-utility.service';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import { SalesCategoryService } from 'src/app/shared/services/sales-category.service';
import { SalesSizeService } from 'src/app/shared/services/sales-size.service';
import { SalesProduct } from '../../interface/sales-product';
import { SalesProductSizeHistory } from '../../interface/sales-product-sizes-history';
import { SalesProductSizeService } from '../../services/sales-product-size.service';
import { SalesProductsService } from '../../services/sales-products.service';
import { dollarSign } from 'src/app/shared/components/icon';
import { GridContextInfo } from 'src/app/shared';

@Component({
  selector: 'pos-sales-product-size-history-edit',
  templateUrl: './sales-product-size-history-edit.component.html',
  styleUrls: ['./sales-product-size-history-edit.component.scss']
})
export class SalesProductSizeHistoryEditComponent extends BaseCrudComponent<SalesProductSizeHistory> implements OnInit {
  dateFormat = 'mm-dd-yy';
  hourFormat = '12';
  id: number;
  salesProductId: number;
  salesProductHistoryName = '';
  salesProductName = '';
  salesProductSizeHistory: SalesProductSizeHistory;
  salesSizeList: Array<SalesSize> = [];
  today = new Date();
  @ViewChild('salesProductSizeHistoryForm') salesProductSizeHistoryForm: NgForm;
  currencySymbol = null;
  props = {
    controlBoxClass: 'col-xl-3 col-md-4 col-sm-6 col-12'
  }
  get getForm(): NgForm {
    return this.salesProductSizeHistoryForm
  }
  icons = {
    dollarSign
  }

  salesProductGridContext: GridContextInfo = null;
  salesProductCategory: number = 0;
  salesProductActive: boolean = false;

  constructor(protected salesProductSizeService: SalesProductSizeService,
    protected salesCategoryService: SalesCategoryService,
    protected salesProductService: SalesProductsService,
    protected salesSizeService: SalesSizeService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected applicationStateService: ApplicationStateService,
    protected route: ActivatedRoute,
    private router: Router,
    protected printService: PrintTableService,
    formUtilityService: FormUtilityService) {
    super(salesProductSizeService, alertService, spinnerService, formUtilityService);
    this.salesProductId = parseInt(route.snapshot.params.salesProductId, 10) ?? 0;
    this.id = parseInt(route.snapshot.params.id, 10) ?? 0;
    this.dateFormat = this.applicationStateService.settingParam.PCalendarDateFormat;
    this.saveSuccessMessage = Messages.SalesProductSizeSaveSuccess;
    this.salesProductSizeHistory = this.salesProductSizeService.getNewSalesSize();
    this.currencySymbol = applicationStateService.settingParam.CurrencySymbol;
    const navigation = this.router.getCurrentNavigation();
    this.salesProductGridContext = navigation?.extras?.state?.GridContext ?? null;
    this.salesProductCategory = navigation?.extras?.state?.Category ?? null;
    this.salesProductActive = navigation?.extras?.state?.Active ?? null;
  }

  ngOnInit(): void {
    this.loadSalesProduct();
  }

  loadSalesProduct() {
    if (this.id) {
      this.spinnerService.show();
      this.loadData().pipe(finalize(() => {
        this.spinnerService.hide();
      })).subscribe({
        next: (response) => {
          this.salesProductSizeHistory = response;
          this.salesProductSizeHistory.DateAdded = new Date(response.DateAdded);
        }, error: this.alertService.showApiError
      });
    }

  }

  loadDependencies() {
    this.spinnerService.show();
    const observable: Array<Observable<any>> = [];
    observable.push(this.salesSizeService.getSalesSizes());
    observable.push(this.salesProductService.getSalesProduct(this.salesProductId));
    forkJoin(observable).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (response: [Array<SalesSize>, SalesProduct]) => {
        this.salesSizeList = response[0];
        this.salesProductHistoryName = response[1].Name;
        this.salesProductName = response[1].Name;
      },
      error: this.alertService.showApiError
    });
  }

  saveSalesProductSizeHistory(isValid: boolean) {
    if (!isValid) {
      return;
    }
    if (!this.id) {
      this.salesProductSizeHistory.SalesProductId = this.salesProductId;
    }
    this.spinnerService.show();
    this.salesProductSizeService.insert(this.salesProductSizeHistory).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (response) => {
        this.onSaveSuccess();
      }, error: this.alertService.showApiError
    });
  }

  onSaveSuccess() {
    this.onCancel();
    this.alertService.renderSuccessMessage(this.saveSuccessMessage);
  }

  onCancel() {
    this.router.navigate([`manage/app-configuration/sales-products/${this.salesProductId}/sales-product-size-history`], { state: { GridContext: this.salesProductGridContext, Category: this.salesProductCategory, Active: this.salesProductActive } });
  }

}
