import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseCrudComponent, SpinnerService } from 'src/app/shared/components';
import { IconSpecification, clipboardList, clock, infoCircle } from 'src/app/shared/components/icon';
import { Messages } from 'src/app/shared/constants';
import { AlertsService, FormUtilityService, PromotionDataService } from 'src/app/shared/services';
import { WaitTimeAdjustmentRule } from '../../interfaces';
import { WaitTimeRuleService } from '../../services';
import { Observable, finalize, forkJoin } from 'rxjs';
import { cloneDeep, filter, find, forEach, isArray } from 'lodash';
import { PromotionConfig, PromotionConfigParam, PromotionJsonModel, PromotionRule } from 'src/app/shared/interface';

@Component({
  selector: 'pos-wait-time-rule-edit',
  templateUrl: './wait-time-rule-edit.component.html',
  styleUrls: ['./wait-time-rule-edit.component.scss']
})
export class WaitTimeRuleEditComponent extends BaseCrudComponent<WaitTimeAdjustmentRule> implements OnInit {

  waitTimeRule: WaitTimeAdjustmentRule;
  operators: Array<PromotionConfig>;
  @ViewChild('formWaitTimeRule') formWaitTimeRule: NgForm;
  waitTimeRuleType;
  icon: IconSpecification;
  tabList = {
    Info: 'Info',
    Rule: 'Rule',
    Adjustment: 'Adjustment'
  };
  selectedTab = this.tabList.Info;
  rules: Array<PromotionConfig> = [];
  adjustments: Array<PromotionConfig> = [];
  isAnyAdjustmentEmpty = false;
  isAnyRuleEmpty = false;
  ruleModel: PromotionRule;
  configuredAdjustments: Array<PromotionConfig> = [];
  icons = {
    infoCircle, clock, clipboardList
  };
  props = {
    controlBoxClass: 'col-lg-4 col-md-6 col-sm-8 col-xs-12'
  }
  get getForm(): NgForm {
    return this.formWaitTimeRule;
  }
  constructor(protected spinnerService: SpinnerService,
    protected alertService: AlertsService,
    protected router: Router,
    private route: ActivatedRoute,
    protected waiTimeRuleService: WaitTimeRuleService,
    protected promotionData: PromotionDataService,
    protected formUtilityService: FormUtilityService) {
    super(waiTimeRuleService, alertService, spinnerService, formUtilityService);
    const waitTimeRuleType = this.waiTimeRuleService.getWaitTimeRuleTypeByRoute(route.snapshot?.url[0]?.path);
    this.waitTimeRuleType = waitTimeRuleType;
    this.icon = waitTimeRuleType.Icon;
    this.waitTimeRule = waiTimeRuleService.newWaitTimeRule();
    this.id = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
  }

  ngOnInit(): void {
    this.saveSuccessMessage = Messages.WaitTimeRuleSaveSuccess;
  }

  loadDependencies(): void {
    this.promotionData.loadData();
    const observables: Array<Observable<any>> = [];
    observables.push(this.waiTimeRuleService.getRules());
    observables.push(this.waiTimeRuleService.getAdjustments());
    if (this.id) {
      observables.push(this.loadData());
    }
    this.spinnerService.show();
    forkJoin(observables)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: ([rules, adjustments, waitTimeRule]:
          [Array<PromotionConfig>, Array<PromotionConfig>, WaitTimeAdjustmentRule]) => {
          this.rules = filter(rules, x => x.Categories.length ? x.Categories.includes(this.waitTimeRuleType.Value) : !x.IsOperator);
          this.operators = filter(rules, x => x.IsOperator);
          this.adjustments = filter(adjustments, x => x.Categories.length ? x.Categories.includes(this.waitTimeRuleType.Value) : true);
          if (waitTimeRule) {
            this.waitTimeRule = waitTimeRule;
            this.prepareAdjustments(waitTimeRule.AdjustmentConfig);
            this.ruleModel = this.setRule(waitTimeRule.RuleConfig);
          } else {
            this.addAdjustment();
          }
        }, error: this.alertService.showApiError
      });
  }

  addAdjustment() {
    this.configuredAdjustments.push(this.promotionData.newPromotionConfig());
  }

  prepareAdjustments(config: string) {
    let promoConfig: Array<PromotionJsonModel> = JSON.parse(config);
    forEach(promoConfig, config => {
      const adjustments = cloneDeep(this.adjustments);
      let ruleConfig = find(adjustments, x => x.Name == config.Name);
      if (ruleConfig) {
        forEach(ruleConfig.Config, param => {
          param.Value = config.Config[param.Name];
        });
        this.configuredAdjustments.push(ruleConfig);
      } else {
        this.addAdjustment();
      }
    });
  }

  setRule(rule: string) {
    const ruleModel = JSON.parse(rule);
    return this.prepareRule(ruleModel, true);
  }

  validateRule() {
    this.promotionData.validateRuleAndReward(this.ruleModel.Rules, this.configuredAdjustments);
    this.isAnyAdjustmentEmpty = this.configuredAdjustments.some(x => !x.Name || x.Config.some(c => (isArray(c.Value) ? !c.Value.length : !c.Value) && (c.IsRequired || c.IsInvalid)));
    this.isAnyRuleEmpty = this.isEligibilityValid(this.ruleModel);
    setTimeout(() => {
      this.saveWaitTimeRule();
    });
  }

  saveWaitTimeRule() {
    if (!this.formWaitTimeRule.valid || this.isAnyRuleEmpty || this.isAnyAdjustmentEmpty) {
      return;
    }
    this.waitTimeRule.AdjustmentConfig = this.convertConfigToJson(this.configuredAdjustments ?? []);
    const config = this.prepareRule(this.ruleModel);
    this.waitTimeRule.RuleConfig = JSON.stringify(config);
    this.waitTimeRule.Type = this.waitTimeRuleType.Value;
    this.save(this.waitTimeRule);
  }

  convertConfigToJson(ruleConfig: Array<PromotionConfig>): string {
    let ruleJsonConfig: Array<PromotionJsonModel> = [];
    forEach(ruleConfig, config => {
      if (config) {
        let promoConfigParam = {};
        forEach(config.Config, param => {
          promoConfigParam[param.Name] = param.Value;
        });
        ruleJsonConfig.push({ Name: config.Name, Config: promoConfigParam });
      }
    });
    return JSON.stringify(ruleJsonConfig);
  }

  convertAdjustmentConfigToJson(rule): any {
    let promoConfigParam = {};
    if (rule.Config) {
      forEach(rule.Config, param => {
        promoConfigParam[param.Name] = param.Value;
      });
    }
    return promoConfigParam;
  }

  isEligibilityValid(rule: PromotionRule): boolean {
    return rule.Condition
      && (!rule.Rules.length
        || rule.Rules.some(ruleChild => !ruleChild.Condition
          && (!ruleChild.Name || ruleChild.Config.some(c => (isArray(c.Value) ? !c.Value.length : (typeof (c.Value) == 'object' ? (!c.Value?.Name || !c.Value?.Value) : !c.Value)) && (c.IsRequired || c.IsInvalid)))
          || this.isEligibilityValid(ruleChild))
      );
  }

  prepareRule(rule: PromotionRule, isSetValue = false) {
    if (rule.Config) {
      rule.Config = isSetValue ? this.setConfig(rule) : this.convertAdjustmentConfigToJson(rule);
    }
    if (rule.Rules.length) {
      forEach(rule.Rules, x => this.prepareRule(x, isSetValue));
    }
    return rule;
  }

  setConfig(rule): Array<PromotionConfigParam> {
    let ruleConfig = cloneDeep(find(this.rules, x => x.Name == rule.Name).Config);
    if (rule.Config) {
      forEach(ruleConfig, config => {
        config.Value = rule.Config[config.Name];
      })
    }
    return ruleConfig;
  }

  onSaveSuccess(model: WaitTimeAdjustmentRule): void {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  cancel() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  flattenArray(arr: PromotionRule[]): PromotionRule[] {
    return arr.reduce((flat, toFlatten) => {
      flat.push(toFlatten);
      if (toFlatten.Rules.length) {
        flat.push(...this.flattenArray(toFlatten.Rules));
      }
      return flat;
    }, []);
  }

}
