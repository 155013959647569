import { Directive, ElementRef, HostListener } from '@angular/core';
declare let $: any;
@Directive({
  selector: 'p-calendar'
})
export class PCalendarDirective {

  currentElement: ElementRef;
  constructor(private el: ElementRef) {
    this.currentElement = el;
  }

  @HostListener('onSelect')
  onSelect() {
    if (this.currentElement) {
      $(this.currentElement.nativeElement).find('input').focus();
    }
  }

}
