import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { AlertsService, BaseCrudComponent, FieldInfoMessages, FormUtilityService, lockOpen, Messages, SpinnerService } from 'src/app/shared';
import { PermissionType } from '../../interfaces/permission-type';
import { PermissionTypesService } from '../../services/permission-types.service';

@Component({
  selector: 'pos-permissions-edit',
  templateUrl: './permissions-edit.component.html'
})
export class PermissionsEditComponent extends BaseCrudComponent<PermissionType> implements OnInit {

  id: number;
  permissionType: PermissionType;
  tags: Array<string> = [];
  icons = {
    lockOpen
  }
  fieldInfoMessages = FieldInfoMessages;
  @ViewChild('permissionTypeForm') permissionTypeForm: NgForm;
  constructor(protected spinnerService: SpinnerService,
    protected alertService: AlertsService,
    protected permissionTypesService: PermissionTypesService,
    protected formUtilityService: FormUtilityService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super(permissionTypesService, alertService, spinnerService, formUtilityService);
    this.id = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
    this.permissionType = permissionTypesService.newPermission();
  }

  ngOnInit(): void {
    this.saveSuccessMessage = Messages.PermissionTypeSaveSuccess;
  }

  submit(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.permissionType.Tags = this.tags?.length ? this.tags.join() : null;
    this.save(this.permissionType);
  }
  onSaveSuccess(model: PermissionType): void {
    this.router.navigate(['permissions'], { relativeTo: this.route.parent });
  }
  loadDependencies(): void {
    this.spinnerService.show();
    this.loadData()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe(res => {
        this.permissionType = res;
        this.tags = this.permissionType.Tags?.split(',');
      });
  }
  get getForm(): NgForm {
    return this.permissionTypeForm;
  }

  cancelEditing(): void {
    this.router.navigate(['permissions'], { relativeTo: this.route.parent });
  }

}
