import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forEach } from 'lodash';
import { KioskNavigationService } from 'src/app/order-kiosk';
import { KioskScreenConfig, KioskScreenChoice, KioskConfigSetupItem } from 'src/app/order-kiosk/interfaces';
import { AllergenInquiryScreenConfig } from 'src/app/order-kiosk/interfaces/config/allergen-inquiry-screen-config';
import { OrderKioskConfigService, OrderKioskChoiceService } from 'src/app/order-kiosk/services';
import { ColorUtilityService, DomainConstants, RuntimeConstants } from 'src/app/shared';
declare let $: any;

@Component({
  selector: 'pos-allergens-inquiry',
  templateUrl: './allergens-inquiry.component.html',
  styleUrls: ['./allergens-inquiry.component.scss']
})
export class AllergensInquiryComponent {
  @Input() screenConfigs: Array<KioskScreenConfig> = [];
  @Input() screenId: number;
  @Input() screenButtons: Array<KioskScreenChoice> = [];
  screenConfig: AllergenInquiryScreenConfig;
  imageRootPath = RuntimeConstants.IMAGE_BASE_PATH + "/order-kiosk/";
  sectionHeight: number;
  isDesignMode = false;
  setupItems: KioskConfigSetupItem;

  constructor(private orderKioskConfigService: OrderKioskConfigService,
    private kioskChoiceService: OrderKioskChoiceService,
    private route: ActivatedRoute,
    private kioskNavigationService: KioskNavigationService,
    private colorUtilityService: ColorUtilityService
  ) {
    this.isDesignMode = route.snapshot.params.id;
    this.screenConfig = orderKioskConfigService.newAllergenInquiry();
  }

  ngOnInit() {
    this.resizeWindow();
    this.prepareConfigs();
    this.calculateSectionHeight();
    this.setButtonsInScreenButtons();
    this.setupItems = { IsEditable: true, SetButton: false, RemoveButton: false };
  }

  setButtonsInScreenButtons() {
    forEach(this.screenButtons, screenButton => {
      screenButton.Button = this.kioskChoiceService.newChoice();
    });
  }

  prepareConfigs() {
    const screenConfig = this.orderKioskConfigService.parseConfigProperties(DomainConstants.KioskScreenConfigProperties.ScreenConfig, this.screenConfigs);
    if (screenConfig) this.screenConfig = screenConfig;

    this.screenConfig.ConfirmButtonBackgroundColor = this.colorUtilityService.convertColorToRgba(this.screenConfig.ConfirmButtonBackgroundColor, this.screenConfig.ConfirmButtonOpacity);
    this.screenConfig.RejectButtonBackgroundColor = this.colorUtilityService.convertColorToRgba(this.screenConfig.RejectButtonBackgroundColor, this.screenConfig.RejectButtonOpacity);
  }

  ngAfterViewInit(): void {
    this.calculateSectionHeight();
  }

  calculateSectionHeight() {
    this.sectionHeight = this.orderKioskConfigService.calculateInvoiceHeight(this.screenConfig);
  }

  resizeWindow() {
    $(window).resize(() => {
      this.calculateSectionHeight();
    });
  }

  buttonClick = (kioskScreenChoice: KioskScreenChoice, isResetWarnings = true) => {
    if (!this.isDesignMode) {
      if (isResetWarnings && this.kioskNavigationService.dietaryWarnings?.length) {
        this.kioskNavigationService.dietaryWarnings = [];
        this.kioskNavigationService.applyDietaryWarnings();
      }
      kioskScreenChoice.Button.NextScreenId = kioskScreenChoice.NextScreenId;
      this.kioskNavigationService.buttonClick(kioskScreenChoice.Button, this.screenId, kioskScreenChoice.ConfigId);
    }
  }
}
