import { Component, OnInit, Input } from '@angular/core';
import { ModalFormComponent, ModalService } from 'src/app/shared/components';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgForm } from '@angular/forms';
import { FormUtilityService } from 'src/app/shared/services/form-utility.service';

@Component({
  selector: 'pos-inventory-product-edit-model',
  templateUrl: './inventory-product-edit-model.component.html',
  styleUrls: ['./inventory-product-edit-model.component.scss']
})
export class InventoryProductEditModelComponent extends ModalFormComponent implements OnInit {
  form: NgForm;
  get getForm(): NgForm {
    return this.form
  }
  @Input('productid') productid: number = null;
  @Input('parameters') parameters: any;
  initialize = false;
  constructor(inventoryProductModalRef: BsModalRef, modalService: ModalService, formUtilityService: FormUtilityService) {
    super(inventoryProductModalRef, modalService, formUtilityService);
    this.initialize = true;
  }

  ngOnInit() {
    this.initialize = true;
  }

  onClose(event: any) {
    if (event && event.shouldReload) {
      this.hide(event);
    } else {
      this.hide({ event });
    }
  }

  onFormChange($event: NgForm) {
    this.form = $event;
    this.setInitialValue();
  }
}
