<div>
  <button class="close" data-dismiss="modal" type="button" (click)="closeModal()">
    <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
  </button>
  <h4 class="modal-content__heading" id="myModalLabel">Serve Methods</h4>
</div>
<div class="overflow-hidden">
  <div class="row px-4 mx-0">
    <div class="col-12 pt-pix-15 p-0">
      Select a serve method:
    </div>
    <div class="px-4 col-12 display-flex-row flex-wrap">
      <div class="col-100 col-4 pt-pix-10 p-0" *ngFor="let serveMethod of serveMethods | keyvalue">
        <div class="order-type-tile" (click)="setServeMethod(serveMethod.key)">
          <i [ngClass]="getServeMethodClass(serveMethod.key)"></i>
          <h6>{{serveMethod.value}}</h6>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 action-button-container popup-footer pb-pix-10">
    <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" type="button" (click)="closeModal()">
      Close
    </button>
  </div>
  <div class="clearfix"></div>
</div>
