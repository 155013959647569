<pos-edit-wrapper (close)="onCancel(false)" [isPopup]="!fromListPage" [headingIcon]="icons.iconsVectorSquare"
heading="{{ fromListPage ? areaId > 0 ? 'Edit Training Area': 'Add Training Area' : 'Training Area'}}">
  <form #formTrainingArea="ngForm" (ngSubmit)="saveTrainingArea(formTrainingArea.valid)" autocomplete="off">
    <div class="col-12 border-top" [ngClass]="{'pos-page-body': !fromListPage, 'pos-modal-body': fromListPage}">
      <div [ngClass]="{'col-12': fromListPage}" *ngIf="fromListPage">
        <pos-row>
          <pos-column>
            <pos-form-field label="Name" [validationMessages]="{required: 'Please enter area name.'}" inputStyle="col-md-4 col-sm-5 col-xs-12">
              <input posInput type="text" id="areaName" name="areaName" #areaName="ngModel" class="form-control"
                [(ngModel)]="area.Name" [required]="true" />
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Description" [validationMessages]="{required: 'Please enter area description.'}" inputStyle="col-sm-7 col-xs-12">
              <input posInput type="text" id="areaDescription" name="areaDescription" #areaDescription="ngModel"
                  class="form-control" [(ngModel)]="area.Description" [required]="true" />
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Icon" [validationMessages]="{required: 'Please choose area icon.'}" inputStyle="col-sm-7 col-xs-12">
              <pos-row class="p-0">
                <pos-column sm="5" xs="8" [styleClass]="{'has-error': !showAreaIcon && formTrainingArea.submitted}">
                  <input posInput type="file" class="form-control" accept=".png,.jpg,.jpeg,.gif,.bmp" #areaIcon
                    name="areaIcon" id="areaIcon" (change)="handleFileInput($event.target.files)" [required]="true">
                  <span *ngIf="!showAreaIcon && formTrainingArea.submitted">
                    <label class="control-label">Please choose area icon.</label>
                  </span>
                </pos-column>

                <pos-column md="3" xs="4" class="p-0" [hidden]="!showAreaIcon">
                  <pos-row class="p-0">
                    <pos-column md="7" sm="5" xs="8" class="col-50">
                      <img src="{{imageRootPath}}/{{area.Icon}}" #areaImage id="areaImage" width="50px" />
                    </pos-column>

                    <pos-column sm="4" xs="2" (click)="removeIcon()" class="mt-pix-n5">
                      <pos-icon [icon]="icons.times" class="pointer remove-icon-badge"></pos-icon>
                    </pos-column>
                  </pos-row>
                </pos-column>

                <pos-column xs="1">
                  <button type="button" (click)="selectIcon()" class="btn btn-secondary normal me-pix-5 pos-button">Choose</button>
                </pos-column>
              </pos-row>
            </pos-form-field>
          </pos-column>
        </pos-row>
      </div>
      <div *ngIf="fromListPage" class="clearfix"></div>
    </div>
    <div class="col-xs-12 action-button-container"  [ngClass]="{'page-footer': fromListPage, 'popup-footer': !fromListPage}">
      <div class="form-horizontal">
        <button type="submit" value="Save" class="btn btn-primary btn-lg btn-save pos-button" scrollup>Save</button>
        <button type="button" class="btn btn-secondary btn-lg pos-button" [ngClass]="{'btn-cancel': fromListPage, 'btn-cancel-modal': !fromListPage}" value="Cancel" (click)="onCancel(false)"
          scrollup>{{buttonText}}</button>
      </div>
    </div>
  </form>
</pos-edit-wrapper>
