<pos-edit-wrapper (close)="cancelEditing()" heading="Task">
    <form #taskForm="ngForm" (ngSubmit)="saveTask(taskForm.valid)" autocomplete="off">
        <div class="col-12 pos-modal-body screen-scroll">
            <pos-row>
                <pos-column>
                    <pos-form-field label="Name" [validationMessages]="{required: 'Please enter name.'}"  inputStyle="col-12">
                        <textarea posInput class="form-control" name="taskname" #taskname="ngModel" maxlength="100" rows="5"
                            [(ngModel)]="taskData.taskname" [required]="true"></textarea>
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <h3 class="task-state">Task States</h3>
                    <pos-row class="px-pix-20">
                        <pos-column>
                            <pos-form-field inputStyle="col-12">
                                <p-checkbox posInput name="Complete" [(ngModel)]="toDo" disabled label="To Do" binary="true">
                                </p-checkbox>
                            </pos-form-field>
                        </pos-column>
                        <pos-column>
                            <pos-form-field  inputStyle="col-12">
                                <p-checkbox posInput name="Started" label="Started"
                                    #param.ParameterName="ngModel" [(ngModel)]="taskData.IsStart" binary="true">
                                </p-checkbox>
                            </pos-form-field>
                        </pos-column>
                        <pos-column>
                            <pos-form-field inputStyle="col-12">
                                <p-checkbox posInput name="Skip" [(ngModel)]="completed" disabled label="Completed" binary="true">
                                </p-checkbox>
                            </pos-form-field>
                        </pos-column>
                    </pos-row>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button class="btn-primary btn-lg btn btn-save pos-button" type="submit" value="Save" scrollup>Save</button>
            <button class="btn-info btn-lg white btn btn-save pos-button" (click)="showScheduleModel(taskData.id)" type="button"
                value="Edit Schedule">Edit Schedule
            </button>
            <button class="btn-secondary btn-lg btn btn-cancel-modal pos-button" (click)="cancelEditing()" type="button"
                value="Cancel">Cancel
            </button>
        </div>
    </form>
</pos-edit-wrapper>
