import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ModalComponent } from 'src/app/shared/components';
import { RuntimeConstants } from 'src/app/shared/constants';
import { KioskNavigationService } from '../../services';
import { UserIdleService } from 'src/app/shared';
import { KioskUserIdleComponent } from '../kiosk-user-idle-component';

@Component({
  selector: 'pos-kiosk-confirm-cancel',
  templateUrl: './kiosk-confirm-cancel.component.html',
  styleUrls: ['./kiosk-confirm-cancel.component.scss']
})
export class KioskConfirmCancelComponent extends ModalComponent {
  @Input() intervalSeconds: number = 0;
  confirmButtonText = 'YES';
  rejectButtonText = 'NO';
  imageRootPath = RuntimeConstants.IMAGE_BASE_PATH + "/order-kiosk/";
  backgroundImage = `${this.imageRootPath}cancel-order.png`;
  userIdleSubscription: Subscription;

  constructor(public bsModalRef: BsModalRef,
    private idleService: UserIdleService,
    private kioskNavigationService: KioskNavigationService,
  ) {
    super(bsModalRef);
  }

  ngOnInit() {
    this.subscribeToUserIdleSubscription();
  }

  onUserResponse(res: any) {
    this.hide({
      continueOrder: res?.continueOperation
    })
  }

  closeConfirmCancel(isCancelOrder = false) {
    this.hide({ continueOrder: !isCancelOrder });
  }

  subscribeToUserIdleSubscription() {
    if (this.userIdleSubscription) {
      this.userIdleSubscription.unsubscribe();
    }
    this.userIdleSubscription = this.idleService.initialize(this.kioskNavigationService.userIdleTimeout < 30 ? this.kioskNavigationService.userIdleTimeout : 30, () => this.userGoneIdle());
  }

  userGoneIdle = () => {
    this.stopUserIdleService();
    const callBack = (res) => {
      if (res?.continueOrder) {
        this.subscribeToUserIdleSubscription();
      } else {
        this.closeConfirmCancel(true);
      }
    };
    this.kioskNavigationService.openOverlayModal(KioskUserIdleComponent, callBack)
  }

  stopUserIdleService() {
    this.idleService.stop();
    this.userIdleSubscription?.unsubscribe();
  }
  ngOnDestroy() {
    this.stopUserIdleService();
  }
}
