import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import {
  ICloseable,
  AlertsService,
  Messages,
  ApplicationStateService,
  SpinnerService,
  StringUtils,
} from 'src/app/shared';
import * as moment from 'moment';
import { ScheduleOrderActiveHoursOverride } from '../interfaces/schedule-order-active-hours-override';
import { find } from 'lodash';
import { ScheduleOrderActiveHoursOverrideService } from 'src/app/shared/services/schedule-order-active-hours-override.service';
import { finalize } from 'rxjs/operators';
import { TarkDatePipe, TarkTimePipe } from '@tarktech/tark-ng-utils';

@Component({
  selector: 'pos-time-entry',
  templateUrl: './time-entry.component.html',
  styleUrls: ['./time-entry.component.scss'],
})
export class TimeEntryComponent implements OnInit, ICloseable {
  title: string;
  selectedTime: Date;
  close: EventEmitter<any> = new EventEmitter();
  instruction: string;
  hourFormat = '12';
  scheduleDate: Date;
  maxDateValue: Date = new Date();
  minDateValue: Date = new Date();
  maxScheduledOrderHours;
  waitOfTime: { hour: number; minute: number } = { hour: 0, minute: 0 };
  isRescheduleOrder = false;
  isShowCalender = true;
  @Input() timeSlots: Array<{ text: string; value: number }> = [];

  isValidSelectedTime = true;
  scheduleOrderActiveHours = '';
  scheduleOrderActiveHoursOverrides: Array<ScheduleOrderActiveHoursOverride>;
  scheduleOrderActiveHoursInvalidMessage = '';
  today = new Date();
  constructor(
    private alertService: AlertsService,
    private applicationStateService: ApplicationStateService,
    private spinnerService: SpinnerService,
    private tarkDatePipe: TarkDatePipe,
    private tarkTimePipe: TarkTimePipe,
    private scheduleOrderActiveHoursOverrideService: ScheduleOrderActiveHoursOverrideService
  ) { }

  ngOnInit() {
    this.getScheduleOrderActiveHoursOverrides();
    const scheduleDate = new Date(this.scheduleDate);
    this.selectedTime = scheduleDate && scheduleDate > this.today ? scheduleDate : this.today;
    this.scheduleDate = scheduleDate && scheduleDate > this.today ? new Date(this.scheduleDate) : this.today;
    this.changeTime();
    this.hourFormat =
      this.applicationStateService.settingParam.PCalendarHourFormat;
    this.maxScheduledOrderHours = this.applicationStateService?.settingParam?.MaxScheduledOrderHours;
    this.scheduleOrderActiveHours = this.applicationStateService?.settingParam?.ScheduleOrderActiveHours;
    this.maxDateValue.setHours(
      this.minDateValue.getHours() + this.maxScheduledOrderHours
    );
    this.configureTimeSlots();
  }

  configureTimeSlots() {
    const defaultTimeSlots = [
      { text: '10 min', value: 10 },
      { text: '20 min', value: 20 },
      { text: '30 min', value: 30 },
      { text: '1 hr', value: 60 },
      { text: '2 hr', value: 120 },
      { text: '4 hr', value: 240 },
      { text: '24 hr', value: 1440 },
      { text: '48 hr', value: 2880 },
    ];
    this.timeSlots = this.timeSlots.length ? this.timeSlots : defaultTimeSlots;
  }

  getScheduleOrderActiveHoursOverrides = () => {
    this.spinnerService.show();
    this.scheduleOrderActiveHoursOverrideService.getNextScheduleOverrides()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: Array<ScheduleOrderActiveHoursOverride>) => {
          this.scheduleOrderActiveHoursOverrides = response?.length ? response : [];
        }, error: this.alertService.showApiError
      });
  }

  onChangeScheduleTime() {
    if (this.selectedTime) {
      const currentTime = new Date();
      currentTime.setSeconds(0);
      this.selectedTime.setSeconds(0);

      const difference = this.selectedTime.getTime() - currentTime.getTime();
      let hours = Math.floor(difference / (60 * 60 * 1000));
      let minutes = Math.ceil((difference % (60 * 60 * 1000)) / (60 * 1000));
      if (minutes === 60) {
        hours += 1;
        minutes = 0;
      }
      this.waitOfTime.hour = hours >= 0 ? hours : 0;
      this.waitOfTime.minute = minutes >= 0 ? minutes : 0;
      this.validateScheduleTime();
      if (!this.isShowCalender) {
        this.waitOfTime.hour = this.waitOfTime.hour % 24;
      }
    }
  }

  setAfterXMinute(minute) {
    const currentDate = new Date();
    currentDate.setSeconds(0);
    currentDate.setMinutes(currentDate.getMinutes() + parseInt(minute, 10));
    this.selectedTime = currentDate;
    this.changeTime();
  }

  addHour(hour) {
    this.selectedTime.setSeconds(0);
    const currentDate = new Date(
      this.selectedTime.getTime() + parseInt(hour, 10) * (60 * 60 * 1000)
    );
    this.selectedTime = currentDate;
    this.changeTime();
  }

  changeTime() {
    this.isValidSelectedTime = true;
    const currentDate = new Date();
    currentDate.setSeconds(0, 0);
    this.selectedTime.setSeconds(0, 0);
    this.selectedTime = this.selectedTime.getTime() <= currentDate.getTime() ? currentDate : this.selectedTime;

    this.scheduleDate = this.selectedTime;
    this.onChangeScheduleTime();
  }

  private getMaxScheduleTime() {
    const maxAllowedTime = new Date();
    if (!this.isShowCalender) {
      maxAllowedTime.setHours(
        maxAllowedTime.getHours() + (this.maxScheduledOrderHours > 24 ? 24 : this.maxScheduledOrderHours)
      );
    } else {
      maxAllowedTime.setHours(
        maxAllowedTime.getHours() + this.maxScheduledOrderHours
      );
    }
    return maxAllowedTime;
  }

  changeDate() {
    const currentDate = new Date();
    currentDate.setSeconds(0, 0);
    this.scheduleDate.setSeconds(0, 0);
    this.scheduleDate =
      currentDate < this.scheduleDate ? this.scheduleDate : currentDate;
    this.scheduleDate.setHours(this.selectedTime.getHours());
    this.scheduleDate.setMinutes(this.selectedTime.getMinutes());
    this.selectedTime = this.scheduleDate;
    this.changeTime();
  }

  addMinutes(minute) {
    this.selectedTime.setSeconds(0);
    const currentDate = new Date(
      this.selectedTime.getTime() + parseInt(minute, 10) * 60 * 1000
    );
    this.selectedTime = currentDate;
    this.changeTime();
  }

  closeTimeEntry() {
    this.close.emit();
  }

  enterCallInTime(isActivateNow = false) {
    this.selectedTime = isActivateNow ? new Date() : this.selectedTime;
    this.validateScheduleTime();
    if (this.isValidSelectedTime) {
      this.close.emit({
        enteredTime: this.selectedTime,
      });
    }
  }

  validateScheduleTime = () => {
    const maxAllowedTime = this.getMaxScheduleTime();
    if (this.selectedTime.getTime() >= maxAllowedTime.getTime()) {
      this.scheduleOrderActiveHoursInvalidMessage = StringUtils.format(Messages.OrderScheduleFarInAdvance,
        { 'maxHours': this.maxScheduledOrderHours });
      this.isValidSelectedTime = false;
    } else {
      let startTime, endTime;
      const currentDate = this.selectedTime ? new Date(this.selectedTime) : new Date();
      const dayOfWeek = currentDate.getDay();
      const timeSchedules = this.scheduleOrderActiveHours ? JSON.parse(this.scheduleOrderActiveHours) : null;
      const currentOverride = find(this.scheduleOrderActiveHoursOverrides, (override) => {
        const startDate = new Date(override?.FromDate);
        const endDate = new Date(override?.ToDate);
        endDate.setHours(23, 59);
        return (startDate ? startDate <= currentDate : !!endDate) && (endDate ? endDate >= currentDate : !!startDate);
      });
      if (currentOverride) {
        if (!currentOverride.StartTime && !currentOverride.EndTime) {
          this.isValidSelectedTime = false;
          this.scheduleOrderActiveHoursInvalidMessage = StringUtils.format(Messages.ScheduleOnClosedDay,
            { 'date': this.tarkDatePipe.transform(this.selectedTime.getTime()) });
          return;
        }
        startTime = this.convertTimeToDateTime((currentOverride.StartTime ? currentOverride.StartTime : currentDate.setHours(0, 0, 0, 0)),
          currentOverride.FromDate);
        endTime = this.convertTimeToDateTime((currentOverride.EndTime ? currentOverride.EndTime : currentDate.setHours(24, 0, 0, 0)),
          currentOverride.ToDate);
        this.validateSelectedTime(startTime, endTime, this.selectedTime);
      } else if (timeSchedules?.length) {
        startTime = this.getStartTime(timeSchedules, currentDate, dayOfWeek);
        endTime = this.getEndTime(timeSchedules, currentDate, dayOfWeek);
        this.validateSelectedTime(startTime, endTime, this.selectedTime);
      }
    }
  }


  getStartTime = (timeSchedules, currentDate, dayOfWeek) => {
    return timeSchedules[dayOfWeek].startTime ? this.convertTimeToDateTime(moment(timeSchedules[dayOfWeek].startTime, 'HH:mm'),
      currentDate) : currentDate.setHours(0, 0, 0, 0);
  }

  getEndTime = (timeSchedules, currentDate, dayOfWeek) => {
    return timeSchedules[dayOfWeek].endTime ? this.convertTimeToDateTime(moment(timeSchedules[dayOfWeek].endTime, 'HH:mm'),
      currentDate) : currentDate.setHours(24, 0, 0, 0);
  }

  validateSelectedTime = (startTime, endTime, selectedTime) => {
    const time = moment(selectedTime);
    this.isValidSelectedTime = time.isBetween(startTime, endTime, null, '[]');
    this.scheduleOrderActiveHoursInvalidMessage = StringUtils.format(Messages.OrderOutsideOperatingHours,
      {
        'startTime': this.tarkTimePipe.transform(startTime),
        'endTime': this.tarkTimePipe.transform(endTime)
      });
  }

  convertTimeToDateTime = (timeSpan, date?) => {
    date = date ? new Date(date) : new Date();
    const time = moment(timeSpan, 'HH:mm');
    date.setHours(time.hours());
    date.setMinutes(time.minutes());
    return moment(date, 'HH:mm');
  }
}
