import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AlertsService, SpinnerService, Messages, RuntimeConstants, ModalComponent, ModalService, ConfirmDeleteModalComponent, ImageGalleryComponent } from 'src/app/shared';
import { InstructionsService } from '../../services/instructions.service';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import * as _ from 'lodash';
import {
  salesProductInfo, salesProductPricing, salesProductWorkflow, salesProductButtonsDiceOne,
  salesProductRecipe, salesProductQuickReportFileAlt, salesProductClock,
  productSimplyBuilt, plusWhite, deleteWhite, times
} from 'src/app/shared/components/icon';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SalesSize } from 'src/app/information-management/sales-sizes';
import { InstructionComponent } from '../instruction/instruction.component';
import { ImportRecipeComponent } from '../import-recipe/import-recipe.component';
import { CopyInstructionsComponent } from '../copy-instructions/copy-instructions.component';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { NgForm } from '@angular/forms';
import { InventoryProductDetail } from 'src/app/information-management/inventory-products/interface/inventory-product-detail';
import { InventorySubCategoryService } from 'src/app/information-management/inventory-subcategories/service';
import { InventoryProductService } from 'src/app/shared/services/inventory-product.service';
import { SalesProductsService } from '../../services/sales-products.service';
declare let $: any;
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { ExtensionValidationService } from 'src/app/shared/services/extension-validation.service';
@Component({
  selector: 'pos-sales-product-instruction-configuration',
  templateUrl: './sales-product-instruction-configuration.component.html',
  styleUrls: ['./sales-product-instruction-configuration.component.scss',
    '../../../../../styles/pages/instructions/instructions-configuration.scss']
})
export class SalesProductInstructionConfigurationComponent extends ModalComponent implements OnInit {

  @Input('salesProductId') salesProductId: number;
  @Input('salesProductName') salesProductName: number;

  inventoryCategories: Array<any> = [];
  salesSizes: Array<SalesSize> = [];
  instructionSizeId: number = 0;
  allSizes: Array<SalesSize> = [];
  inventoryProductList: Array<InventoryProductDetail> = [];
  inventoryProducts: Array<InventoryProductDetail> = [];
  warningMessage: string = '';
  isDisplayInstructionWarning: boolean = false;
  areaList: any = [];
  areaInstruction: any = {};
  instructionArea: any = {};
  instructionAreas: any = [];
  areaInstructions: any = [];
  instructionImg: string = '';
  editAreaLabel = 'Add Area';
  editInstructionLabel = 'Add Instruction';
  selectedGalleryImage: any = '';
  icon: File;
  previewInstruction: any = {};
  galleryImages: Array<any> = [];
  imagePath = RuntimeConstants.IMAGE_BASE_PATH;
  icons = {
    salesProductInfo,
    salesProductPricing,
    salesProductWorkflow,
    salesProductButtonsDiceOne,
    salesProductRecipe,
    salesProductQuickReportFileAlt,
    salesProductClock,
    productSimplyBuilt,
    plusWhite,
    deleteWhite,
    times
  };

  props = {
    controlBoxClass: 'col-lg-8 col-md-8 col-sm-8 col-xs-12'
  }

  @ViewChild('formArea') formArea: NgForm;
  @ViewChild('formInstructions') formInstructions: NgForm;
  constructor(private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private instructionService: InstructionsService,
    private inventorySubCategoryService: InventorySubCategoryService,
    private inventoryProductService: InventoryProductService,
    private salesProductService: SalesProductsService,
    modalRef: BsModalRef,
    private modalService: ModalService,
    private extensionValidation: ExtensionValidationService,
  ) {
    super(modalRef);
  }

  ngOnInit() {
    this.loadInstructions();
  }

  onSortArea(event: CdkDragDrop<string[]>) {
    const filteredArea = _.filter(this.areaList, (area) => {
      return area.InstructionSizeId === this.instructionSizeId;
    });
    moveItemInArray(filteredArea, event.previousIndex, event.currentIndex);
    _.forEach(filteredArea, (area, index) => {
      const instructionArea = {
        Id: area.InstructionAreaId,
        SalesProductId: area.SalesProductId,
        SizeId: area.InstructionSizeId,
        Area: area.InstructionAreaName,
        InventorySubcategoryId: area.InventorySubCategoryId,
        Ordinal: index + 1
      };
      area.InstructionAreaOrdinal = index + 1;
      this.saveInstructionArea(instructionArea, false, index === filteredArea.length - 1);
    });
  }

  onSortInstruction(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.areaInstructions, event.previousIndex, event.currentIndex);
    _.forEach(this.areaInstructions, (inst, index: number) => {
      const instruction = {
        Id: inst.IngredientId,
        InstructionAreaId: inst.InstructionAreaId,
        InventoryProductId: inst.InventoryProductId,
        IngredientImage: inst.IngredientImage,
        IngredientInstruction: inst.IngredientInstruction,
        Ordinal: index + 1
      };
      inst.IngredientOrdinal = index + 1;
      this.saveInstruction(instruction, null, false, index === this.areaInstructions.length - 1, false);
    });
  }


  loadInstructions() {
    this.spinnerService.show();
    const instructionObservables = [];
    instructionObservables.push(this.inventorySubCategoryService.getInventorySubcategoryList());
    instructionObservables.push(this.salesProductService.getProductSizesWithPrice(this.salesProductId));
    instructionObservables.push(this.salesProductService.getSalesSizes(0));
    instructionObservables.push(this.inventoryProductService.getActiveInventoryProducts(true));
    instructionObservables.push(this.instructionService.getSalesProductIngredients(this.salesProductId)); // false, false
    forkJoin(instructionObservables)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (responses: any) => {
          if (responses) {
            this.inventoryCategories = responses[0] ? responses[0] : [];
            this.salesSizes = responses[1] ? responses[1] : [];
            this.instructionSizeId = this.salesSizes.length > 0 ? this.salesSizes[0].SizeId : 0;
            this.allSizes = responses[2] ? responses[2] : [];
            if (responses[3]) {
              this.inventoryProductList = responses[3];
              this.inventoryProducts = responses[3];
              this.onChangeInventoryCategory(0);
            }
            if (responses[4]) {
              this.getSalesProductIngredientCompleted(responses[4], false, false, false);
            }
          }
        }, error: this.alertService.showApiError
      });
  }

  getSalesProductIngredientCompleted(res, isLoading, isResetArea, isResetInstruction) {
    if (res.length > 0) {
      this.salesProductName = res[0].SalesProductName;
      if (!res[0].IsShowInstructions) {
        this.warningMessage = Messages.DoNotInstruction;
        this.isDisplayInstructionWarning = true;
      }
      this.areaList = [];
      this.areaInstruction = {
        InstructionAreaId: undefined,
        InventoryProductId: undefined,
        IngredientImage: '',
        IngredientInstruction: '',
        InventoryProduct: null
      };
      this.instructionArea.SalesProductId = this.salesProductId;
      this.instructionAreas = _.groupBy(res, 'InstructionAreaId');


      if (this.instructionAreas && Object.keys(this.instructionAreas)[0]) { // !== 'null'
        if (this.instructionArea.Id > 0) {
          this.areaInstructions = _.filter(this.instructionAreas[this.instructionArea.Id], (instruction) => {
            return instruction.IngredientId > 0;
          });
          this.areaInstruction.InstructionAreaId = this.instructionArea.Id;
        } else {
          this.areaInstructions = _.filter(Object.values<any>(this.instructionAreas)[0], (instruction: any) => {
            return instruction.IngredientId > 0;
          });
          if (this.areaInstructions.length) {
            this.areaInstruction.InstructionAreaId = this.areaInstructions[0].InstructionAreaId;
          }
        }

        // unique area list
        _.forEach(Object.values(this.instructionAreas), (areas: any) => {
          if (areas && areas.length > 0) {
            this.areaList.push(areas[0]);
          }
        });
        this.areaList = _.sortBy(this.areaList, ['InstructionSizeId', 'InstructionAreaOrdinal']);
        const sizeId = _.cloneDeep(this.instructionSizeId);
        this.instructionSizeId = this.areaList && this.areaList.length && this.areaList[0].InstructionSizeId && !sizeId ? this.areaList[0].InstructionSizeId : this.instructionSizeId;
        const areaId = this.instructionArea && this.instructionArea.Id ? this.instructionArea.Id : 0;
        _.forEach(this.instructionAreas, (instructions, index) => {
          if (instructions && instructions.length) {
            const currentSelectedArea = _.find(instructions, (instruction) => {
              return (areaId ? instruction.InstructionAreaId == areaId :
                instruction.InstructionAreaOrdinal === this.areaList[0].InstructionAreaOrdinal)
                && (!sizeId ? instruction.InstructionAreaId === this.areaList[0].InstructionAreaId :
                  instruction.InstructionSizeId == sizeId);
            });

            if (currentSelectedArea) {
              if (isResetArea) {
                this.editInstructionArea(0);
                this.editInstruction(0);
                this.areaInstructions = [];
              } else if (isResetInstruction) {
                this.editInstruction(0);
              } else {
                this.editInstructionArea(this.instructionArea.Id && this.instructionArea.SizeId ? this.instructionArea.Id : currentSelectedArea.InstructionAreaId);
                this.editInstruction(currentSelectedArea.IngredientId);
              }
            }
          }
        });

        if (!(this.instructionArea.Id > 0) && this.areaList.length > 0) {
          if (!isLoading) {
            const firstArea = _.find(this.areaList, (area) => {
              return area.InstructionSizeId === this.instructionSizeId;
            })
            if (firstArea) {
              this.instructionArea.Id = firstArea.InstructionAreaId;
              this.editInstructionArea(this.instructionArea.Id, true);
            } else {
              this.instructionArea.Id = this.areaList[0].InstructionAreaId;
            }
          }
        }
      }
    } else {
      this.alertService.renderErrorMessage(Messages.SalesProductNotExists);
    }
  }

  editInstructionArea(instructionAreaId, isResetInstruction = false) {
    this.instructionImg = '';
    $('#areaName').focus();
    if (instructionAreaId > 0) {

      const instructionArea = this.instructionAreas[instructionAreaId][0];
      this.areaInstructions = _.filter(this.instructionAreas[instructionAreaId], (instruction) => {
        return instruction.IngredientId > 0;
      });
      this.instructionArea = {
        Id: instructionArea.InstructionAreaId,
        SalesProductId: instructionArea.SalesProductId,
        SizeId: instructionArea.InstructionSizeId,
        Area: instructionArea.InstructionAreaName,
        InventorySubcategoryId: instructionArea.InventorySubCategoryId,
        Ordinal: instructionArea.InstructionAreaOrdinal
      };

      this.areaInstruction = {
        InstructionAreaId: instructionArea.InstructionAreaId,
        InventoryProduct: {}
      };

      if (this.inventoryProductList) {
        this.onChangeInventoryCategory(this.instructionArea.InventorySubcategoryId);
      }
      this.editInstruction(instructionArea.IngredientId);
      this.editAreaLabel = 'Edit Area: ' + instructionArea.InstructionAreaName;
    } else {
      // add new area
      this.instructionArea = {
        Id: undefined,
        SalesProductId: this.salesProductId,
        SizeId: undefined,
        Area: '',
        InventorySubcategoryId: undefined,
        Ordinal: undefined
      };
      this.editAreaLabel = 'Add Area';
      if (isResetInstruction) {
        this.areaInstructions = [];
        this.editInstruction(0);
      }
    }
  }

  editInstruction(ingredientId) {
    $('#fileIngredient').val('');
    if (ingredientId > 0) {
      const instruction = _.filter(this.areaInstructions, { 'IngredientId': ingredientId })[0];
      if (instruction) {
        this.areaInstruction.InstructionAreaId = instruction.InstructionAreaId;
        this.areaInstruction.Id = instruction.IngredientId;
        this.areaInstruction.InventoryProductId = instruction.InventoryProductId;
        this.areaInstruction.InventoryProduct = instruction.InventoryProductId;
        this.areaInstruction.IngredientImage = instruction.IngredientImage;
        this.areaInstruction.IngredientImageOld = instruction.IngredientImage;
        this.areaInstruction.IngredientInstruction = instruction.IngredientInstruction;
        this.areaInstruction.Ordinal = instruction.IngredientOrdinal;
        this.areaInstruction.InstructionSizeId = instruction.InstructionSizeId;
        this.instructionImg = instruction.IngredientImage ? this.imagePath + '/instructions/'
          + instruction.IngredientImage : '';
        this.editInstructionLabel = 'Edit Ingredient: ' + this.areaInstruction.IngredientInstruction;
      }
    } else {
      // add new instruction
      this.areaInstruction.InstructionAreaId = this.instructionArea.Id;
      this.areaInstruction.Id = undefined;
      this.areaInstruction.InventoryProduct = null;
      this.areaInstruction.InventoryProductId = undefined;
      this.areaInstruction.IngredientImage = '';
      this.areaInstruction.IngredientInstruction = '';
      this.areaInstruction.Ordinal = undefined;
      this.instructionImg = '';
      this.areaInstruction.InstructionSizeId = this.instructionSizeId;
      this.editInstructionLabel = 'Add Instruction';
    }
  }

  getSalesProductIngredients(isLoading, isResetArea, isResetInstruction = false) {
    if (isLoading) {
      this.spinnerService.show();
    }
    this.instructionService.getSalesProductIngredients(this.salesProductId)
      .pipe(finalize(() => {
        if (isLoading) {
          this.spinnerService.hide();
        }
      }))
      .subscribe({
        next: (res) => {
          if (res) {
            this.getSalesProductIngredientCompleted(res, isLoading, isResetArea, isResetInstruction);
          }
        }, error: this.alertService.showApiError
      });
  }

  saveInstructionArea(instructionArea, isLoading, isLoadInstructions, isResetInstruction = false) {
    if (isLoading) {
      this.spinnerService.show();
    }
    this.instructionService.saveSalesProductInstructionArea(instructionArea)
      .pipe(finalize(() => {
        if (isLoading) {
          this.spinnerService.hide();
        }
      }))
      .subscribe({
        next: (res) => {
          if (isLoadInstructions) {
            this.getSalesProductIngredients(isLoading, isResetInstruction);
          }
          if (isResetInstruction) {
            this.formArea.resetForm();
            this.formArea.reset();
            this.instructionArea = {
              Id: res ? res : undefined,
              SalesProductId: this.salesProductId,
              SizeId: undefined,
              Area: '',
              InventorySubcategoryId: undefined,
              Ordinal: undefined
            };
            this.alertService.renderSuccessMessage(Messages.SalesProductAreaSaved);
          }
        }, error: this.alertService.showApiError
      });
  }

  saveInstruction(instruction, file, isLoading, isLoadInstructions, isResetInstruction) {
    if (isLoading) {
      this.spinnerService.show();
    }
    this.instructionService.saveSalesProductInstruction(instruction, file)
      .pipe(finalize(() => {
        if (isLoading) {
          this.spinnerService.hide();
        }
      }))
      .subscribe({
        next: (res) => {
          $('#fileIngredient').val('');
          this.formInstructions.resetForm();
          this.formInstructions.reset();
          this.icon = null;
          this.instructionImg = '';
          if (isLoadInstructions) {
            this.getSalesProductIngredients(false, false, isResetInstruction);
          }
          if (isResetInstruction) {
            this.alertService.renderSuccessMessage(Messages.SalesProductInstructionSaved);
          }
        }, error: this.alertService.showApiError
      });
  }

  getSizeName(sizeId) {
    const defaultSize = _.find(this.allSizes, function (size) {
      return size.Id === sizeId;
    });
    return defaultSize ? defaultSize.Name : '';
  }

  setInstructionSize(sizeId) {
    if (sizeId) {
      this.instructionSizeId = sizeId;
      const currentSelectedArea = _.find(this.areaList, { 'InstructionSizeId': sizeId });
      if (currentSelectedArea) {
        this.editInstructionArea(currentSelectedArea.InstructionAreaId);
      } else {
        this.areaInstructions = [];
        this.editInstructionArea(0);
        this.editInstruction(0);
      }
    }
  }

  updateInstructionArea(isValid: boolean) {
    if (!isValid) {
      return;
    }
    if (!this.instructionArea.Id) {
      const ordinal = Math.max.apply(Math, Object.values(this.instructionAreas).map(function (area: any) {
        return Math.max.apply(Math, area.map(function (item) {
          return item.InstructionAreaOrdinal;
        }));
      }));
      this.instructionArea.Ordinal = ordinal > 0 ? ordinal + 1 : 1;
    }

    this.instructionArea.SizeId = this.instructionSizeId;
    this.saveInstructionArea(this.instructionArea, true, true);
  }

  updateInstruction(isValid: boolean) {
    if (!isValid) {
      return;
    }
    if (!this.areaInstruction.Id) {
      const ordinal = Math.max.apply(Math, this.areaInstructions.map(function (item) { return item.IngredientOrdinal; }));
      this.areaInstruction.Ordinal = ordinal > 0 ? ordinal + 1 : 1;
    }
    if (this.selectedGalleryImage) {
      this.areaInstruction.IngredientImageUrl = null;
      this.areaInstruction.IngredientImage = this.selectedGalleryImage;
      this.selectedGalleryImage = ''
    }
    this.areaInstruction.InventoryProductId = this.areaInstruction.InventoryProduct;
    this.saveInstruction(this.areaInstruction, this.icon, true, true, true);
  }

  deleteInstructionArea(id, name) {
    const deleteAreaModalRef = this.modalService.show(ConfirmDeleteModalComponent, {
      animated: false,
      keyboard: false,
      class: 'vertical-center',
      initialState: {
        message: StringUtils.format(Messages.ConfirmDeleteInstructionArea, { 'areaName': name }),
      }
    });
    deleteAreaModalRef.close.subscribe(res => {
      if (res && res.shouldDelete) {
        this.confirmDeleteArea(id);
      }
    });
  }

  confirmDeleteArea(id: number) {
    this.spinnerService.show();
    this.instructionService.deleteSalesProductInstructionArea(id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.instructionArea = {};
          this.getSalesProductIngredients(false, true);
        }, error: this.alertService.showApiError
      });
  }

  deleteInstruction(id: number) {
    this.instructionService.deleteSalesProductInstruction(id)
      .pipe(finalize(() => {
      }))
      .subscribe({
        next: (res) => {
          const currentSelectedInstructionArea = _.cloneDeep(this.instructionAreas[this.instructionArea.Id]);
          const currentSelectedAreaInstructionAreaId = currentSelectedInstructionArea[0].InstructionAreaId;
          _.forEach(currentSelectedInstructionArea, (item, $index) => {
            if (item && item.IngredientId === id) {
              if (currentSelectedInstructionArea.length > 1) {
                currentSelectedInstructionArea.splice($index, 1);
              } else {
                item.IngredientId = null;
                item.IngredientImage = null;
                item.IngredientInstruction = null;
              }
            }
          });
          this.instructionAreas[this.instructionArea.Id] = currentSelectedInstructionArea;
          this.editInstructionArea(currentSelectedAreaInstructionAreaId);
          this.editInstruction(0);
        }, error: this.alertService.showApiError
      });
  }

  openImageGallery() {
    this.selectedGalleryImage = '';
    this.galleryImages = [];
    this.spinnerService.show();
    this.instructionService.getInstructionsImages()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          if (response) {
            this.galleryImages = response;
            const imageGalleryModalRef = this.modalService.show(ImageGalleryComponent, {
              animated: false,
              keyboard: false,
              class: 'vertical-center modal-lg',
              initialState: {
                imageList: this.galleryImages,
                imageRootPath: this.imagePath + '/instructions',
                headerName: 'Select instruction icon'
              }
            });
            imageGalleryModalRef.close.subscribe(res => {
              if (res && res.selectedImage) {
                this.selectedGalleryImage = res.selectedImage;
                this.instructionImg = this.imagePath + '/instructions/' + this.selectedGalleryImage;
                this.areaInstruction.IngredientImageUrl = _.find(this.galleryImages, (image) => {
                  return image.substring(image.lastIndexOf('\\') + 1, image.length) === this.selectedGalleryImage;
                });
              }
            });
          }
        }, error: this.alertService.showApiError
      });
  }

  preview() {
    this.previewInstruction = {
      SalesProductId: this.salesProductId,
      SalesProductName: this.salesProductName,
      Size: this.getSizeName(this.instructionSizeId),
      OrderId: 0,
      InstructionAreas: [],
      ExceptionalinstructionArea: { SalesProductIngredients: [] }
    };

    _.forEach(this.areaList, (area) => {
      const ingredients = _.filter(this.instructionAreas[area.InstructionAreaId], (i) => {
        return i.IngredientId > 0 && i.InstructionSizeId === this.instructionSizeId;
      });
      const salesProductIngredients = [];
      _.forEach(ingredients, (ins) => {
        salesProductIngredients.push({
          Image: ins.IngredientImage,
          Text: ins.IngredientInstruction,
          Ordinal: ins.IngredientOrdinal
        });
      });
      const instructionArea = {
        Name: area.InstructionAreaName,
        AreaId: area.InstructionAreaId,
        Ordinal: area.InstructionAreaOrdinal,
        SalesProductIngredients: salesProductIngredients
      };
      this.previewInstruction.InstructionAreas.push(instructionArea);
    });

    const previewModalRef = this.modalService.show(InstructionComponent, {
      animated: false,
      keyboard: false,
      class: 'vertical-center modal-max-width-80',
      initialState: {
        instruction: this.previewInstruction,
        isPopup: true
      }
    });
  }


  handleFileInput(files: FileList) {
    this.icon = files.item(0);
    const fileToUpload = this.icon ? this.icon.name : '';
    if (this.extensionValidation.isInvalidExtension(fileToUpload, ['.jpg', '.bmp', '.png', '.gif'])) {
      if (fileToUpload) {
        this.alertService.renderErrorMessage(Messages.InvalidReceiptLogoFileType);
      }
      this.removeIcon();
      return;
    }
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        $('#imageIngredient').attr('src', reader.result);
      };
      reader.readAsDataURL(files[0]);
      this.instructionImg = this.icon ? this.icon.name : '';
      // this.showIcon = true;
    }
  }

  removeIcon(): void {
    this.areaInstruction.IngredientImage = '';
    this.icon = undefined;
    this.instructionImg = '';
    $('#fileIngredient').val('');
  }

  onChangeInventoryCategory(inventoryCategoryId: number) {
    if (inventoryCategoryId) {
      this.inventoryProducts = _.filter(this.inventoryProductList, (product) => {
        return product.SubcategoryId === inventoryCategoryId;
      });
    } else {
      this.inventoryProducts = this.inventoryProductList;
    }
  }

  openRecipeModal(isImport: boolean) {
    const maxAreaOrdinal = Math.max.apply(Math, Object.values(this.instructionAreas).map(function (area: any) {
      return Math.max.apply(Math, area.map(function (item) {
        return item.InstructionAreaOrdinal;
      }));
    }));

    const importRecipeModalRef = this.modalService.show(ImportRecipeComponent, {
      animated: false,
      class: 'vertical-center modal-lg',
      keyboard: false,
      initialState: {
        isImport: isImport,
        salesProductId: this.salesProductId,
        salesProductName: this.salesProductName,
        sizeName: this.getSizeName(this.instructionSizeId),
        areaId: this.instructionArea.Id,
        sizeId: this.instructionSizeId,
        maxOrdinal: maxAreaOrdinal
      }
    });
    importRecipeModalRef.close.subscribe(res => {
      if (res && res.shouldReload && isImport) {
        this.loadInstructions();
      }
    });
  }

  openImportRecipes() {
    if (!this.areaList || this.areaList.length === 0 || !this.areaInstruction.InstructionAreaId) {
      this.alertService.renderErrorMessage(Messages.RecipeAreaMissing);
      return;
    }
    this.openRecipeModal(true);
  }

  openExclusions() {
    if (!this.areaList || this.areaList.length === 0) {
      this.alertService.renderErrorMessage(Messages.RecipeAreaMissing);
      return;
    }
    this.openRecipeModal(false);
  }

  replaceLineBreak(str) {
    if (str != null) {
      return str.replace('\n', '<br>');
    }
  }

  openCopyInstructions(isCopyTo) {
    const copy = {
      salesProductId: this.salesProductId,
      isCopyTo: isCopyTo,
      SelectedSizeId: this.instructionSizeId,
      Sizes: this.allSizes
    };

    const copyModalRef = this.modalService.show(CopyInstructionsComponent, {
      animated: false,
      keyboard: false,
      class: 'vertical-center modal-max-width-95',
      initialState: {
        copy: copy
      }
    });
    copyModalRef.close.subscribe(res => {
      if (res?.shouldReload) {
        this.loadInstructions();
      }
    });
  }

  onCancel() {
    this.hide({});
  }

}
