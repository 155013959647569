import { Injectable } from "@angular/core";
import { IKioskNavigation } from "../interfaces/ikiosk-navigation";
import { ChoiceBehavior } from "./choices-behavior";

@Injectable({
    providedIn: 'root'
})
export class MultipleProductsBehavior extends ChoiceBehavior {

    constructor() {
        super();
    }

    execute(navObj: IKioskNavigation, orderNavigation: any): Promise<void> {
        
        return Promise.resolve();
    }
}

