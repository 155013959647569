import { Injectable } from '@angular/core';
import { RuntimeConstants, SettingParam } from 'src/app/shared';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CashDrawerHistory } from 'src/app/manage-console/end-of-day/interfaces/cash-drawer-history';

@Injectable({
  providedIn: 'root'
})
export class EndOfDayService {

  endOfDayApiBasePath = `${RuntimeConstants.API_PATH}endOfDay`;
  endOfDayTriggerApiBasePath = `${RuntimeConstants.API_PATH}endOfDay/trigger/`;

  constructor(private httpClient: HttpClient) { }

  getEndOfDayDetails(terminalId: number): Observable<SettingParam> {
    return this.httpClient.get<SettingParam>(`${this.endOfDayApiBasePath}/${terminalId}`);
  }

  saveEndOfDayDetails(params): Observable<SettingParam> {
    return this.httpClient.post<SettingParam>(this.endOfDayApiBasePath, params);
  }

  checkCashDrawerStatus(): Observable<Array<CashDrawerHistory>> {
    return this.httpClient.get<Array<CashDrawerHistory>>(`${this.endOfDayTriggerApiBasePath}check-cash-drawer-status`);
  }

  getLastDailyClose(): Observable<Date> {
    return this.httpClient.get<Date>(`${this.endOfDayTriggerApiBasePath}get-daily-close`);
  }

  triggerEndOfDay() {
    return this.httpClient.post(`${this.endOfDayTriggerApiBasePath}`, null);
  }

}
