import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AlertsService, FormUtilityService, ModalFormComponent, ModalService, SpinnerService, times } from 'src/app/shared';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { OnlineOrderingCategoryProductService } from '../../../service/online-ordering-category-product.service';
import { OnlineOrderingCategoryProduct } from '../../../interface/online-ordering';
import { NgForm } from '@angular/forms';
import { SalesProductsService } from 'src/app/information-management/sales-products';
declare let $: any;
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { ExtensionValidationService } from 'src/app/shared/services/extension-validation.service';
@Component({
  selector: 'pos-online-ordering-category-product',
  templateUrl: './online-ordering-category-product.component.html',
  styleUrls: ['./online-ordering-category-product.component.scss']
})
export class OnlineOrderingCategoryProductComponent extends ModalFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.form
  }
  buttonId: number;
  categorySectionList: Array<any> = [];
  image: File;
  resolutionWarningMessage: string = '';
  isToggle: boolean = false;
  openFromCategory: boolean = false;
  isChangeImage: boolean = false;
  buttonIconName: string = '';
  @ViewChild('categoryProductForm', { static: true }) form: NgForm;
  @ViewChild('buttonImage') buttonImage: ElementRef;
  salesProducts: Array<any> = [];
  selectedSection: any;
  buttonDetails: OnlineOrderingCategoryProduct;
  icons = {
    times
  }
  constructor(
    productModalRef: BsModalRef,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private onlineOrderingCategoryProductService: OnlineOrderingCategoryProductService,
    private salesProductsService: SalesProductsService,
    private extensionValidation: ExtensionValidationService,
    modalService: ModalService,
    formUtilityService: FormUtilityService) {
    super(productModalRef, modalService, formUtilityService);
  }

  ngOnInit() {
    this.getSalesProducts();
    this.buttonDetails = this.onlineOrderingCategoryProductService.newOnlineOrderingCategoryProduct();
    this.buttonDetails.IsToggle = this.isToggle ? this.isToggle : false;
    this.buttonDetails.SectionId = this.selectedSection ? this.selectedSection : 0;
    if (this.buttonId > 0) {
      this.getButtonDetails(this.buttonId);
    }
    this.setInitialValue();
  }

  getSalesProducts() {
    this.spinnerService.show();
    this.salesProductsService.getSalesProducts()
      .subscribe({
        next: (res) => {
          this.salesProducts = res;
        }, error: this.alertService.showApiError,
        complete: () => {
          this.spinnerService.hide();
        }
      });
  }

  removeTimeStamp() {
    if (this.buttonDetails.Icon) {
      let index = this.buttonDetails.Icon.lastIndexOf(".");
      let suffix = this.buttonDetails.Icon.substr(index);
      let timeIndex = this.buttonDetails.Icon.lastIndexOf("-");
      let iconName = this.buttonDetails.Icon.substr(0, index);
      if (this.buttonDetails.Icon.substr(timeIndex).length >= (suffix.length + 19)) {
        iconName = this.buttonDetails.Icon.substr(0, timeIndex);
      }
      this.buttonIconName = iconName + suffix;
    }
  }

  getButtonDetails(buttonId: number) {
    if (buttonId > 0) {
      this.spinnerService.show();
      this.onlineOrderingCategoryProductService.getOnlineOrderingCategoryProduct(buttonId).subscribe(
        response => {
          this.buttonDetails = response;
          this.buttonDetails.SectionId = this.selectedSection ? this.selectedSection : 0;
          this.removeTimeStamp();
        },
        this.alertService.showApiError,
        () => {
          this.spinnerService.hide();
        }
      );
    }
  }

  onCancel(reload: boolean, buttonId: number) {
    this.hide({ shouldReload: reload, buttonId: buttonId, SectionId: this.buttonDetails.SectionId, IsToggle: this.buttonDetails.IsToggle });
  }

  handleFileInput(files: FileList) {
    this.image = files.item(0);
    const fileToUpload = this.image ? this.image.name : '';
    if (this.extensionValidation.isInvalidExtension(fileToUpload)) {
      if (fileToUpload) {
        this.alertService.renderErrorMessage(Messages.InvalidReceiptLogoFileType);
      }
      this.removeIcon();
      return;
    }
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        $('#buttonImage').attr('src', reader.result);
        setTimeout(() => { this.checkImageResolution() });
        this.isChangeImage = true;
      };
      reader.readAsDataURL(files[0]);
      this.buttonDetails.Icon = this.image ? this.image.name : '';
    }
  }

  checkImageResolution() {
    var width = this.openFromCategory ? 270 : 95;
    var height = this.openFromCategory ? 180 : 73;
    if (this.buttonImage && (this.buttonImage.nativeElement.naturalWidth < width || this.buttonImage.nativeElement.naturalHeight < height)) {
      this.resolutionWarningMessage = StringUtils.format(Messages.OnlineOrderingImageResolutionWarning, { 'resolution': width + 'X' + height });
      this.buttonDetails.hasImageResolutionError = true;
    }
    else {
      this.resolutionWarningMessage = '';
      this.buttonDetails.hasImageResolutionError = false;
    }
  }

  removeIcon() {
    this.isChangeImage = false;
    this.buttonDetails.Icon = '';
    this.buttonIconName = '';
    $('#buttonIcon').val('');
  }

  saveCategoryProduct(isValid) {
    if (!isValid) {
      return;
    }
    this.spinnerService.show();
    if (this.openFromCategory) {
      this.buttonDetails.IsToggle = false;
    }
    this.buttonDetails.SectionId = this.buttonDetails.SectionId != 0 ? this.buttonDetails.SectionId : null;
    this.onlineOrderingCategoryProductService.saveCategoryProduct(this.buttonDetails, this.image)
      .subscribe({
        next: (res) => {
          if (this.buttonId > 0) {
            this.alertService.renderSuccessMessage(Messages.OnlineOrderingButtonSaveSuccess);
            this.onCancel(true, null);
          } else {
            this.onCancel(true, res.Id);
          }
        }, error: this.alertService.showApiError,
        complete: () => {
          this.spinnerService.hide();
        }
      });
  }
}

