<div class="kiosk-background"
    [style.background-image]="backgroundImage ? 'url(\'' + baseImagePath + backgroundImage + '\')' : null">
    <div class="footer-buttons">
        <button type="button" class="btn btn-lg white confirm-button" (click)="hideModal()">
            {{confirmButtonText}}
        </button>
        <button type="button" class="btn btn-lg white reject-button" (click)="hideModal(false)">
            {{rejectButtonText}}
        </button>
    </div>
</div>
