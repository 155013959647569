<pos-list-wrapper [headingIcon]="icons.unitsThLarge" heading="Units" (close)="close()">
  <div class="row">
    <div class="col-12">
      <ng-table #unitGrid [columns]="unitListColumns" [exportFilename]="'units'" [data]="unitDetailsList">
      </ng-table>
    </div>
  </div>
</pos-list-wrapper>
<pos-confirm-delete (onConfirmAcknowledge)="onConfirmAcknowledge()" #confirmDeleteOption>
</pos-confirm-delete>
<pos-print-table #printTableReference [hidden]="true"></pos-print-table>




