<pos-edit-wrapper (close)="closeModal()" heading="{{selectedButtonBehaviorType.Text}}">
    <form #tagSelectionForm="ngForm" autocomplete="off" (submit)='saveBehavior(tagSelectionForm.valid)'>
        <div class="col-12 pos-modal-body screen-scroll">
            <pos-row class="col-12">
                <pos-column class="col-12">
                    <pos-form-field label="Tag(s)" inputStyle="col-12"
                        [validationMessages]="{required: 'Please select tag.'}">
                        <ng-select posInput [items]="tags" placeholder="Select tag" [multiple]="true"
                            name="products" bindLabel="Name" bindValue="Id" [(ngModel)]="tagIds"
                            #sizeList="ngModel" appendTo="body" [required]="true">
                        </ng-select>
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save</button>
            <button type="button" (click)="closeModal()" class="btn-secondary btn-lg btn btn-cancel-modal pos-button">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
