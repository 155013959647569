import { Component, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormUtilityService, ModalFormComponent, ModalService } from 'src/app/shared';
import { InventoryProductRecipe } from '../../../interface';

@Component({
  selector: 'pos-prep-recipe-edit-modal',
  templateUrl: './prep-recipe-edit-modal.component.html',
})
export class PrepRecipeEditModalComponent extends ModalFormComponent {
  get getForm(): NgForm {
    return this.prepRecipeEditForm;
  }

  @Input() inventoryProductId: number;
  @Input() editInventoryProducts: InventoryProductRecipe;
  prepRecipeEditForm: NgForm;
  constructor(
    modalRef: BsModalRef,
    modalService: ModalService,
    formUtilityService: FormUtilityService
  ) {
    super(modalRef, modalService, formUtilityService);
  }

  onClose($event) {
    this.hide($event);
  }

  onFormChange($event: NgForm) {
    this.prepRecipeEditForm = $event;
    this.setInitialValue();
  }
}
