<div class="row mx-0 pt-pix-10 right-side-bar">
  <div class="right-side-bar__info">
    <div class="p-0 text-center">
      <h6 class="right-side-bar__info__heading">Terminal</h6>
    </div>

    <div class="right-side-bar__info__matrix text-left">
      <span class="right-side-bar__info__matrix__name bold">
        Name: {{drawerInfo.TerminalName}}
      </span>
    </div>
    <div class="no-border right-side-bar__info__matrix text-left">
      <span class="right-side-bar__info__matrix__name bold">
        Version: {{currentVersion}}
      </span>
    </div>
    <div class="no-border right-side-bar__info__matrix text-left" *ngIf="currentWaitTimeFeatureEnable">
      <span class="right-side-bar__info__matrix__name bold">
        Current Wait Time: {{ currentWaitTime | date: 'HH:mm:ss'}}
      </span>
    </div>
  </div>

  <div class="right-side-bar__info" *ngIf="drawerInfo.DrawerAmountModel && drawerInfo.DrawerAmountModel.length">
    <h6 class="right-side-bar__info__heading"> Current Totals</h6>
    <div *ngFor="let drawerAmountType of drawerInfo.DrawerAmountModel">
      <div class="right-side-bar__info__matrix text-left"
        *ngIf="settingParam.ShowTipsInTerminalInfo && drawerAmountType.PaymentType === 'Tips'">
        <span class="right-side-bar__info__matrix__name bold">
          {{drawerAmountType.PaymentType}}:
          <div class="float-end"> {{drawerAmountType.Amount | tark_currency}}</div>
        </span>
      </div>
      <div class="right-side-bar__info__matrix text-left" *ngIf="drawerAmountType.PaymentType !== 'Tips'">
        <span class="right-side-bar__info__matrix__name bold">
          {{drawerAmountType.PaymentType}}:
          <div class="float-end"> {{drawerAmountType.Amount | tark_currency}}</div>
        </span>
      </div>
    </div>
  </div>
  <div class="right-side-bar__info">
    <div class="col-12 p-0 text-center">
      <h6 class="right-side-bar__info__heading"> Connected Devices</h6>
    </div>
    <div class="ps-pix-5 right-side-bar__info__matrix text-left">
      <span class="right-side-bar__info__matrix__name bold">
        Cash Drawer: {{drawerInfo.CashDrawerPrinterName}}
      </span>
    </div>
    <div class="no-border ps-pix-5 right-side-bar__info__matrix text-left">
      <span class="right-side-bar__info__matrix__name bold">
        Credit Card Terminal: {{drawerInfo.CardTerminalName}}
      </span>
    </div>
  </div>
</div>
