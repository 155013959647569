<div class="col-12 pe-0 pt-pix-15 pe-0 ps-0">
  <p-table [value]="recipes" sortField="sizeName" sortMode="single" [scrollable]="true" [scrollHeight]="height +'px'">
    <ng-template pTemplate="header">
      <tr>
        <th [style]="{'width': '85%','border-left': 'none' ,'border-right': 'none'}">Recipe</th>
        <th [style]="{'border-left': 'none' ,'border-right': 'none' , 'text-align':'center'}">Nutrition Facts</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
      <tr class="ui-widget-header" *ngIf="loadIngredientsData[rowData.SizeName].index === rowIndex"
        [style]="{'border-left': 'none' ,'border-right': 'none','background-color': '#ffffff'}">
        <td colspan="2" [style]="{'border-left': 'none' ,'border-right': 'none'}">
          <span style="font-weight:bold">{{rowData.SizeName}}</span>
        </td>
      </tr>
      <tr [style]="{'background-color': '#ffffff'}">
        <td [style]="{'width': '85%','padding-left': '25px' ,'padding-top': '5px', 'padding-bottom': '5px'
          , 'vertical-align': 'middle','border-left': 'none' ,'border-right': 'none'}">
          {{rowData.name}}
          {{rowData.info}}</td>
        <td class="text-nowrap"
          [style]="{'border-left': 'none' ,'border-right': 'none','padding-top': '5px', 'padding-bottom': '5px', 'text-align':'center'}">
          <button style="color:white;" class="btn bg-theme-separator information-modal-size pos-button" *ngIf="rowData.IsAvailableNutritionFact"
            data-toggle="modal" (click)="printNutritionFactInfo(rowData.SizeId, rowData.InventoryProductId)">
            <span class="text-ellipsis">
              <pos-icon [icon]="icons.nutritionPrint" class="pe-pix-5"></pos-icon>
            </span> Print
          </button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
