import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { HttpClient } from '@angular/common/http';
import { SettingParam } from 'src/app/shared';
import { Observable } from 'rxjs/internal/Observable';

@Injectable()
export class ExternalLoginsService {

  externalLoginsApiBasePath = `${RuntimeConstants.API_PATH}externallogins`;

  constructor(private httpService: HttpClient) { }

  getExternalLogins(): Observable<SettingParam> {
    return this.httpService.get<SettingParam>(this.externalLoginsApiBasePath);
  }

  saveExternalLogins(params: SettingParam): Observable<SettingParam> {
    return this.httpService.post<SettingParam>(this.externalLoginsApiBasePath, params);
  }
}
