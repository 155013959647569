import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PROMOTION_STATES } from './promotion.routes';
import { PromotionBaseComponent } from './components/promotion-base/promotion-base.component';
import { ComponentsModule } from 'src/app/shared';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { PromotionsComponent } from './components/promotions/promotions.component';
import { PromotionConfigComponent } from './components/promotion-config/promotion-config.component';
import { TableModule } from '@tarktech/tark-ng-utils';
import { CheckboxModule } from 'primeng/checkbox';
import { CalendarModule } from 'primeng/calendar';
import { PromotionSummaryComponent } from './components/promotion-summary';
import { NgxContextModule } from '@tarktech/ngx-context';

@NgModule({
  declarations: [
    PromotionBaseComponent,
    PromotionsComponent,
    PromotionConfigComponent,
    PromotionSummaryComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    NgSelectModule,
    FormsModule,
    RouterModule.forChild(PROMOTION_STATES),
    TableModule,
    CheckboxModule,
    CalendarModule,
    NgxContextModule
  ]
})
export class PromotionModule { }
