import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe, DatePipe, CurrencyPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AdfDashboardComponent } from './components/adf-dashboard/adf-dashboard.component';
import { AdfDashboardRowComponent } from './components/adf-dashboard-row/adf-dashboard-row.component';
import { ComponentsModule } from '../shared';
import { AdfStructurePreviewComponent } from './components/adf-structure-preview/adf-structure-preview.component';
import { AdfWidgetComponent } from './components/adf-widget/adf-widget.component';
import { AdfDashboardEditComponent } from './components/adf-dashboard-edit/adf-dashboard-edit.component';
import { AddWidgetComponent } from './components/add-widget/add-widget.component';
import { TextWidgetComponent } from './components/widgets/text-widget/text-widget.component';
import { ChartWidgetComponent } from './components/widgets/chart-widget/chart-widget.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { GaugeWidgetComponent } from './components/widgets/gauge-widget/gauge-widget.component';
import { TableWidgetComponent } from './components/widgets/table-widget/table-widget.component';
import { TableModule, TarkCurrencyPipe, TarkDatePipe, TarkDateTimePipe, TarkTimePipe } from '@tarktech/tark-ng-utils';
import { IframeWidgetComponent } from './components/widgets/iframe-widget/iframe-widget.component';
import { TextWidgetEditComponent } from './components/widgets/text-widget-edit/text-widget-edit.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { WidgetEditComponent } from './components/widgets/widget-edit/widget-edit.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ChartWidgetEditComponent } from './components/widgets/chart-widget-edit/chart-widget-edit.component';
import { GaugeWidgetEditComponent } from './components/widgets/gauge-widget-edit/gauge-widget-edit.component';
import { TableWidgetEditComponent } from './components/widgets/table-widget-edit/table-widget-edit.component';
import { IframeWidgetEditComponent } from './components/widgets/iframe-widget-edit/iframe-widget-edit.component';
import { AdfDashboardColumnComponent } from './components/adf-dashboard-column/adf-dashboard-column.component';
import { AdfWidgetContentComponent } from './components/adf-widget-content/adf-widget-content.component';
import { AdfWidgetFullscreenComponent } from './components/adf-widget-fullscreen/adf-widget-fullscreen.component';
import { WidgetLoadingComponent } from './components/widgets/widget-loading/widget-loading.component';
import { CheckboxModule } from 'primeng/checkbox';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ModalModule.forRoot(),
        ComponentsModule,
        HighchartsChartModule,
        TableModule,
        ColorPickerModule,
        NgSelectModule,
        CheckboxModule
    ],
    declarations: [AdfDashboardComponent, AdfDashboardRowComponent, AdfStructurePreviewComponent, AdfWidgetComponent,
        AdfDashboardEditComponent, AddWidgetComponent, TextWidgetComponent, ChartWidgetComponent,
        GaugeWidgetComponent, TableWidgetComponent, IframeWidgetComponent, TextWidgetEditComponent, WidgetEditComponent,
        AdfDashboardColumnComponent, AdfWidgetContentComponent,
        ChartWidgetEditComponent, GaugeWidgetEditComponent, TableWidgetEditComponent, IframeWidgetEditComponent, AdfWidgetFullscreenComponent,
        WidgetLoadingComponent,
    ],
    providers: [DatePipe, CurrencyPipe, DecimalPipe, TarkDatePipe, TarkDateTimePipe, TarkTimePipe, TarkCurrencyPipe],
    exports: [AdfDashboardComponent, TextWidgetComponent, ChartWidgetComponent, GaugeWidgetComponent, TableWidgetComponent, IframeWidgetComponent,
        TextWidgetEditComponent, ChartWidgetEditComponent, GaugeWidgetEditComponent, TableWidgetEditComponent, IframeWidgetEditComponent]
})
export class AdfModule { }
