import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { CopySalesProduct } from '../../interface/copy-sales-product';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { SalesProductsService } from '../../services/sales-products.service';
import { finalize } from 'rxjs/operators';
import { FormUtilityService, Messages, ModalFormComponent, ModalService } from 'src/app/shared';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'pos-copy-sales-product',
    templateUrl: './copy-sales-product.component.html',
    styleUrls: ['./copy-sales-product.component.scss']
})
export class CopySalesProductComponent extends ModalFormComponent implements OnInit {
    get getForm(): NgForm {
        return this.copyProductForm;
    }

    @Input('salesProductName') salesProductName: string;
    @Input('salesProductId') salesProductId: number;
    @ViewChild('copyProductForm') copyProductForm: NgForm;
    salesProduct: CopySalesProduct;

    constructor(
        private alertService: AlertsService,
        private spinnerService: SpinnerService,
        private salesProductsService: SalesProductsService,
        public modalRef: BsModalRef,
        modalService: ModalService,
        formUtilityService: FormUtilityService
    ) {
        super(modalRef, modalService, formUtilityService);
    }

    ngOnInit() {
        this.salesProduct = {
            Id: 0,
            ProductName: '',
            IsCopyInstruction: true,
            IsCopyRecipe: true,
            Prices: true
        };
    }

    public submit(isValid: boolean) {
        if (!isValid) {
            return;
        }
        this.salesProduct.Id = this.salesProductId;
        this.spinnerService.show();
        this.salesProductsService.CopySalesProduct(this.salesProduct)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({ next: () => {
                this.alertService.renderSuccessMessage(Messages.CopySalesProductSavedSuccess);
                this.onCancel(true);
            }, error: this.alertService.showApiError });
    }

    public onCancel(reload: boolean) {
        this.hide({ shouldReload: reload });
    }

}
