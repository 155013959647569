import { Injectable } from '@angular/core';
import { forEach } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AdfUtilsService {

  stringToBoolean = (string) => {
    switch (typeof string === 'string' ? string.toLowerCase() : null) {
      case 'true': case 'yes': case '1': return true;
      case 'false': case 'no': case '0': case null: return false;
      default: return Boolean(string);
    }
  }

  split = (object, size) => {
    const arr = [];
    let i = 0;
    forEach(object, (value, key) => {
      const index = i++ % size;
      if (!arr[index]) {
        arr[index] = {};
      }
      arr[index][key] = value;
    });
    return arr;
  }

}
