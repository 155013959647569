import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AIShakeConfigService } from 'src/app/order-kiosk/services';
import { AlertsService, ApplicationStateService, BaseFormComponent, ExtensionValidationService, FormUtilityService, Messages, RuntimeConstants, SettingParam, SpinnerService, times, userRobot } from 'src/app/shared';
declare let $: any;

@Component({
  selector: 'pos-ai-shake-config',
  templateUrl: './ai-shake-config.component.html',
  styleUrls: ['./ai-shake-config.component.scss']
})
export class AiShakeConfigComponent extends BaseFormComponent {
  @ViewChild('configForm') form: NgForm;

  parameters: SettingParam;
  imageBasePath = RuntimeConstants.IMAGE_BASE_PATH;
  showIcon = false;
  aiProcessingImage = null;

  get getForm(): NgForm {
    return this.form
  }

  icons = {
    userRobot, times
  }

  constructor(private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private applicationStateService: ApplicationStateService,
    private router: Router,
    formUtilityService: FormUtilityService,
    private aiShakeConfigService: AIShakeConfigService,
    private extensionValidation: ExtensionValidationService,) {
    super(formUtilityService);
    this.parameters = applicationStateService.newSettingParams();
  }

  ngOnInit() {
    this.load();
  }

  load() {
    this.spinnerService.show();
    this.aiShakeConfigService.getAIShakeConfig()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          this.parameters = res;
          this.aiProcessingImage = `${this.imageBasePath}/order-kiosk/default-screens/${this.parameters.OrderKioskAIShakeProcessingImage}`;
          this.showIcon = !(this.parameters.OrderKioskAIShakeProcessingImage === '' || this.parameters.OrderKioskAIShakeProcessingImage == null);
        }, error: this.alertsService.showApiError
      });
  }

  saveConfig(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.spinnerService.show();
    this.aiShakeConfigService.save(this.parameters, this.aiProcessingImage)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          this.alertsService.renderSuccessMessage(Messages.KioskAIShakeConfigSave);
        }, error: this.alertsService.showApiError
      });
  }

  public handleFileInput(files: FileList) {
    this.aiProcessingImage = files.item(0);
    const fileToUpload = this.aiProcessingImage ? this.aiProcessingImage.name : '';
    if (this.extensionValidation.isInvalidExtension(fileToUpload, ['.jpg', '.bmp', '.png', '.gif', '.mp4'])) {
      if (fileToUpload) {
        this.alertsService.renderErrorMessage(Messages.InvalidReceiptLogoFileType);
      }
      this.removeIcon();
      return;
    }
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
         $('#aiProcessingImage').attr('src', reader.result);
      };
      reader.readAsDataURL(files[0]);
      this.parameters.OrderKioskAIShakeProcessingImage = this.aiProcessingImage ? this.aiProcessingImage.name : '';
      this.showIcon = true;
    }
  }

  public removeIcon(): void {
    this.parameters.OrderKioskAIShakeProcessingImage = '';
    $('#aiProcessingImage').val('');
    $('#image').val(null);
    this.aiProcessingImage = null;
    this.showIcon = false;
  }

  cancelEditing() {
    this.router.navigate(['manage/order-kiosk']);
  }
}
