<pos-list-wrapper [headingIcon]="icons.salesCategoriesCommentDollar" heading="Sales Categories" (close)="close()">
    <div class="row">
        <div class="col-md-12 text-right">
            <div class="inline-block ps-pix-10 float-right">
                <p-checkbox name="isActiveSalesCategories" [(ngModel)]="isActiveSalesCategories"
                    binary="true" (onChange)="reload()" label="Active">
                </p-checkbox>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-1 col-sm-1"></div>
        <div class="col-md-12 col-sm-12">
                <ng-table id="SalesCategory" #salesCategoryGrid [sort]="true"
                    [columns]="salesCategoriesColumns" [data]="salesCategories"
                    [exportFilename]="'sales-categories'">
                </ng-table>
                <ng-template let-data="rowData" #makeTableColorColumn>
                    <div *ngIf="(data.make_table_color)" class="hg-20 col-20 div-center"
                        [ngStyle]="{'background-color': data && data.make_table_color ? data.make_table_color : ''}">
                    </div>
                </ng-template>
        </div>
    </div>
</pos-list-wrapper>
<pos-confirm-delete (onConfirmAcknowledge)="onConfirmAcknowledge()" #confirmSalesCategoryDelete>
</pos-confirm-delete>
<pos-print-table [hidden]="true"></pos-print-table>
