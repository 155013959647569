import { Component, OnInit, Input, OnChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { SpinnerService, AlertsService, ModalService, FormUtilityService } from 'src/app/shared';
import { ModalFormComponent } from 'src/app/shared/components';
import { UnitService } from '../../services/unit.service';
import { Unit } from '../../interface/unit';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'pos-unit-edit',
  templateUrl: './unit-edit.component.html',
  styleUrls: ['./unit-edit.component.scss']
})
export class UnitEditComponent extends ModalFormComponent implements OnInit, OnChanges {
  get getForm(): NgForm {
    return this.form
  }
  @ViewChild('formUnit', { static: true }) form: NgForm;
  unitId: number;
  unit: Unit;
  isUnitQtyIncrement: boolean = false;
  focusControl: boolean;

  constructor(private spinnerService: SpinnerService,
    private unitService: UnitService,
    private alertService: AlertsService,
    unitListModalRef: BsModalRef,
    modalService: ModalService,
    formUtilityService: FormUtilityService) {
      super(unitListModalRef, modalService, formUtilityService);
      this.unit = this.unitService.newUnit();
     }

  ngOnChanges() {
  }

  ngOnInit() {
    if (this.unitId) {
      this.getUnitDetails(this.unitId);
    }
    this.setInitialValue();
  }

  getUnitDetails(unitId) {
    if (unitId > 0) {
      this.spinnerService.show();
      this.unitService.getUnit(unitId).subscribe(res => {
        this.unit = res;
        this.isUnitQtyIncrement = res.Increment != null ? true : false;
      },
        (error) => {
          this.alertService.showApiError(error);
        },
        () => {
          this.spinnerService.hide();
          this.focusControl = true;
        });
    } else {
      this.form.resetForm();
      this.unit = this.unitService.newUnit();
      this.focusControl = true;
    }
  }

  saveUnit(isValid: boolean) {
    if (!isValid || (this.unit.Increment && this.unit.Increment > 9)) {
      return;
    }

    this.spinnerService.show();
    if (!this.isUnitQtyIncrement) {
      this.unit.Increment = null;
    }
    if (this.unitId > 0) {
      this.unit.Id = this.unitId;
      this.unitService.updateUnit(this.unit).subscribe(res => {
        this.hide({ shouldReload: true });
        this.resetForm();
      },
        (error) => {
          this.alertService.showApiError(error);
        },
        () => {
          this.spinnerService.hide();
        });
    } else {
      this.unitService.addUnit(this.unit).subscribe(res => {
        this.hide({ shouldReload: true });
        this.resetForm();
      },
        (error) => { this.alertService.showApiError(error); }
        ,
        () => { this.spinnerService.hide(); });
    }
  }

  resetForm() {
    this.form.resetForm();
    this.unitId = 0;
  }

  cancelEditing() {
    this.hide({ shouldReload: false });
  }

}
