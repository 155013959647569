import { Route } from '@angular/router';
import { Levels, Permissions } from 'src/app/shared/constants';
import { ReportComponent } from './components/report';
import { ReportSubscriptionComponent } from './components/report-subscription';

export const REPORT_ROUTES: Array<Route> = [
    {
        path: '',
        component: ReportComponent,
        data: {
            permissions: [{ 'Name': Permissions.ManageManagementReports, 'Level': Levels.Access },
                { 'Name': Permissions.ManageConsole, 'Level': Levels.Access }]
        }
    },
    {
        path: 'subscriptions',
        component: ReportSubscriptionComponent,
        data: {
            permissions: [{ 'Name': Permissions.LinkReportSubscriptions, 'Level': Levels.Access }]
        }
    },
];
