<pos-edit-wrapper (close)="onCancel(false)" heading="Account Attribute Type">
    <form #accountAttributeTypeForm="ngForm" (ngSubmit)="saveAccountAttributeType(accountAttributeTypeForm.valid)"
        autocomplete="off">
        <div class="col-md-12 pos-modal-body form-horizontal screen-scroll">
            <context-provider provide="props">
                <pos-row>
                    <pos-column>
                        <pos-form-field label="Name" [validationMessages]="{required: 'Please enter account attribute name.'}">
                            <input posInput class="form-control" name="name" #nameWarningMessage="ngModel"
                                [(ngModel)]="accountAttributeType.Name" [required]="true">
                        </pos-form-field>
                    </pos-column>

                    <pos-column>
                        <pos-form-field label="Value Type" [validationMessages]="{required: 'Please enter account attribute value type.'}">
                            <ng-select posInput [items]="accountAttributeValueTypes" bindLabel="value" bindValue="type"
                                name="accountAttributeValueType" placeholder="Select value type"
                                #accountAttributeValueType="ngModel" [(ngModel)]="accountAttributeType.ValueType"
                                appendTo="body" [required]="true">
                            </ng-select>
                        </pos-form-field>
                    </pos-column>

                    <pos-column>
                        <pos-form-field label="Values" [validationMessages]="{required: 'Please enter account attribute value type.'}">
                            <ng-select class="custom-theme" [items]="[]" [addTag]="true" name="tags" appendTo="body"
                                [(ngModel)]="attributeValues" #valuesWarningMessage="ngModel" [multiple]="true" [hideSelected]="true" [isOpen]="false"
                                [required]="false">
                            </ng-select>
                        </pos-form-field>
                    </pos-column>

                    <pos-column>
                        <pos-form-field>
                          <p-checkbox posInput name="searchable" #featured="ngModel" [(ngModel)]="accountAttributeType.IsSearchable" binary="true" label="Searchable">
                          </p-checkbox>
                        </pos-form-field>
                      </pos-column>
                </pos-row>
            </context-provider>

        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" scrollup>Save </button>
            <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" (click)="onCancel(false)">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
