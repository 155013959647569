import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { find, forEach, orderBy, remove } from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SalesSize } from 'src/app/information-management/sales-sizes';
import { BaseFormComponent, SpinnerService } from 'src/app/shared/components';
import { Messages } from 'src/app/shared/constants';
import { AlertsService, FormUtilityService, SalesSizeService } from 'src/app/shared/services';
import { SalesProductBulkUpdate, SalesProductPrice } from '../../interface';
import { SalesProductSizeService, SalesProductsService } from '../../services';
import { salesProductsFileInvoiceDollar } from 'src/app/shared/components/icon';
import { NgForm } from '@angular/forms';
declare let $: any;
@Component({
  selector: 'pos-sales-products-bulk-update',
  templateUrl: './sales-products-bulk-update.component.html',
  styleUrls: ['./sales-products-bulk-update.component.scss']
})
export class SalesProductsBulkUpdateComponent extends BaseFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.bulkUpdateForm;
  }
  @ViewChild('bulkUpdateForm') bulkUpdateForm: NgForm;
  salesProductIds: Array<number> = [];
  salesProductsForBulkUpdate: Array<SalesProductBulkUpdate> = [];
  salesSizes: Array<SalesSize> = [];
  salesProductSizes: Array<SalesProductPrice> = [];
  notApplicableValue = 'N/A';
  globalFilterColumns: Array<string> = [];
  icons = { salesProductsFileInvoiceDollar }

  constructor(private router: Router,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private salesSizeService: SalesSizeService,
    private salesProductsService: SalesProductsService,
    private salesProductSizeService: SalesProductSizeService,
    formUtilityService: FormUtilityService) {
    super(formUtilityService);
    const navigation = router.getCurrentNavigation();
    this.salesProductIds = (navigation?.extras?.state && navigation?.extras?.state.salesProductIds) ?
      navigation?.extras?.state.salesProductIds : [];
  }

  ngOnInit(): void {
    this.loadDependencies();
  }

  loadDependencies() {
    this.spinnerService.show();
    const observables: Array<Observable<any>> = [];
    observables.push(this.salesSizeService.getSalesSizes());
    observables.push(this.salesProductsService.getProductsForBulkUpdate(this.salesProductIds));
    forkJoin(observables)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: ([salesSizes, salesProductsForBulkUpdate]:
          [Array<SalesSize>, Array<SalesProductBulkUpdate>]) => {
          this.salesSizes = salesSizes;
          this.salesProductsForBulkUpdate = salesProductsForBulkUpdate;
          this.globalFilterColumns.push('Name');
          this.prepareSalesProductPrices();
        },
        error: this.alertService.showApiError
      });
  }

  prepareSalesProductPrices() {
    forEach(this.salesProductsForBulkUpdate, salesProduct => {
      forEach(this.salesSizes, size => {
        const existingSize = find(salesProduct.SalesProductSizes, productSize => productSize.SizeId === size.Id);
        if (existingSize) {
          existingSize.Ordinal = size.Ordinal;
        } else {
          const productSize = find(salesProduct.SalesCategorySizes, categorySize => categorySize.SizeId === size.Id);
          const newSalesProductSize = this.salesProductSizeService.getNewSalesProductPrice();
          if (productSize) {
            newSalesProductSize.SizeId = size.Id;
            newSalesProductSize.SalesProductId = salesProduct.Id;
            newSalesProductSize.IsInStock = salesProduct.IsInStock;
            newSalesProductSize.IsDirectLinkToInventoryProduct = salesProduct.IsDirectLinkToInventoryProduct;
          }
          newSalesProductSize.Ordinal = size.Ordinal;
          salesProduct.SalesProductSizes.push(newSalesProductSize);
        }
        const sizeName = size.Name.replace(/ /g, '');
        salesProduct[sizeName] = existingSize ? existingSize.Price.toString() : null;
        this.globalFilterColumns.push(sizeName);
        salesProduct.SalesProductSizes = orderBy(salesProduct.SalesProductSizes, 'Ordinal');
      });
    });
    const timeout = setTimeout(() => {
      this.focusOnFirstElement(1, 1);
      this.convertPricesToDecimal();
      if (timeout) {
        clearTimeout(timeout);
      }
    });
  }

  updateSalesProducts(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.spinnerService.show();
    const products: Array<SalesProductPrice> = [];
    forEach(this.salesProductsForBulkUpdate, sp => {
      const salesProductSizes = sp.SalesProductSizes;
      remove(salesProductSizes, x => !x.SizeId);
      products.push(...salesProductSizes);
    });
    this.salesProductSizeService.saveProductsForBulkUpdate(products)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.alertService.renderSuccessMessage(Messages.SalesProductUpdateSuccess);
          this.router.navigate([`manage/app-configuration/sales-products`]);
        }, error: this.alertService.showApiError
      });
  }

  onCancel() {
    this.router.navigate([`manage/app-configuration/sales-products`]);
  }

  convertPricesToDecimal() {
    const inputs = $('input');
    forEach(inputs, (input) => {
      if (input.value) {
        if (input.value || input.value == 0) {
          input.value = parseFloat(input.value).toFixed(2);
        }
      }
      if (isNaN(input.value)) {
        input.value = '0.00';
      }
    });
  }

  focusOnFirstElement = (rowIndex, columnIndex) => {
    if (rowIndex <= this.salesProductsForBulkUpdate.length) {
      let nextElement = $('tr').eq(rowIndex).find('td').eq(columnIndex).find('input');
      let nextToNextElement = $('tr').eq(rowIndex).find('td').eq(columnIndex + 1).find('input');
      nextToNextElement = nextToNextElement.length ? nextToNextElement : $('tr').eq(rowIndex).find('td').eq(columnIndex + 1).find('span');
      nextElement = nextElement.length ? nextElement : $('tr').eq(rowIndex).find('td').eq(columnIndex).find('span');
      const value = nextElement[0].localName !== 'span' ? null : this.notApplicableValue;
      if (value !== this.notApplicableValue && nextElement?.length) {
        nextElement[0].focus();
      } else {
        if (!nextToNextElement?.length) {
          rowIndex += 1;
          columnIndex = 0;
        }
        this.focusOnFirstElement(rowIndex, columnIndex + 1);
      }
    }
  }

}
