import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { RuntimeConstants } from 'src/app/shared';
import { ProductBuildQueue, ProductBuildQueueGridInfo } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ProductBuildQueueService {

  terminalApiPath = `${RuntimeConstants.API_PATH}product-queue/`;
  constructor(protected httpService: HttpClient) { }

  newProductBuildQueue = (columnId?: number, rowId?: number) => {
    const queue: ProductBuildQueue = {
      BackgroundColor: '',
      ColumnId: columnId,
      ForegroundColor: null,
      InventoryProduct: '',
      InventoryProductId: null,
      RowId: rowId,
      ItemCount: null,
      Label: '',
      Unit: ''
    };
    return queue;
  }

  saveTerminalProperty(terminalProperty) {
    return this.httpService.post(`${this.terminalApiPath}${terminalProperty.TerminalId}/save-terminal-property`,
    terminalProperty);
  }

  getProductBuildQueue(terminalId): Observable<Array<ProductBuildQueue>> {
    return this.httpService.get<Array<ProductBuildQueue>>(
      `${this.terminalApiPath}` + terminalId
    );
  }

}
