<div>
  <button class="close" (click)="cancel()">&times;</button>
  <h4 class="modal-content__heading">{{headerText}}</h4>
</div>
<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 p-pix-10">
  <span innerHTML="{{message}}"></span>
  <div>
    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 p-0 pb-pix-10">
      <ng-table #gridComponent [columns]="inventoryProductsColumns" [scrollable]="true" [scrollHeight]="'51vh'"
        [paginator]="false" [data]="inventoryProducts">
      </ng-table>
      <ng-template let-data="rowData" #chkTemplate>
        <span>
          <p-checkbox name="productIngredients" #productIngredients="ngModel" [(ngModel)]="data.IsActive" binary="true">
          </p-checkbox>
        </span>
      </ng-template>
    </div>
  </div>
</div>
<div class="col-md-12 col-sm-12 col-xs-12 col-lg-12 action-button-container popup-footer mb-pix-10">
  <button type="button" class="btn btn-lg btn-primary btn-cancel-modal normal pos-button" (click)="updateInventoryProducts();">Continue</button>
</div>
<div class="clearfix"></div>
