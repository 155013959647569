<div class="width-98 m-auto">
    <pos-edit-wrapper
        [heading]="inventoryProductDetails ? (inventoryProductDetails.name + ' (' +  barCode + ')'): 'No Product'"
        (close)="cancel()" [headingIcon]="icons.barcode" [isPopup]="false">
        <form (ngSubmit)="save()" #inventoryBarcodeLogForm="ngForm">
            <div class="col-12 action-button-container pt-pix-10">
                <button class="btn-primary btn-lg btn btn-save pos-button" scrollup type="submit"
                    value="Save">Save</button>
                <button class="btn-secondary btn-lg btn btn-cancel pos-button" routerLink="../" scrollup type="button"
                    value="Cancel">Cancel</button>
            </div>
            <div class="col-12">
                <div class="section-border"></div>
            </div>
            <div class="col-12 pos-page-body pt-pix-5 barcode-log-scroll"
                *ngIf="inventoryProductDetails && inventoryProductForLog">
                <pos-row colXs="12" colStyleClass="row mx-0 pt-pix-10" class="g-0">
                    <!--Primary Zone Start-->
                    <pos-column xs="12" class="p-0 pt-pix-10" *ngIf="inventoryProductDetails.PrimaryZoneName">
                        <div class="col-12 p-0 display-flex"
                            [ngClass]="{'zone-highlight': inventoryProductDetails.PrimaryZoneId == inventoryZoneId}">
                            <div class="bin-color"
                                [ngStyle]="{'background-color': inventoryProductDetails.PrimaryZoneColor}">
                            </div>
                            <div class="mb-pix-7 ms-pix-10 flex-grow">
                                <pos-row colStyleClass="pt-pix-5" colXs="12">
                                    <pos-column class="display-flex">
                                        <div class="flex-grow">
                                            <label
                                                class="bold-700 mb-0 font-16">{{inventoryProductDetails.PrimaryZoneName}}&nbsp;</label>
                                            <em>({{inventoryProductDetails.PrimaryZoneLabel}})</em>
                                            <span class="bin-circle" *ngIf="inventoryProductDetails.PrimaryBinName"
                                                [ngStyle]="{'background-color': inventoryProductDetails.PrimaryBinColor,
                                                        'color': getContrastColor(inventoryProductDetails.PrimaryBinColor)}">
                                                {{inventoryProductDetails.PrimaryBinName}}
                                            </span>
                                        </div>
                                    </pos-column>
                                    <pos-column class="pt-pix-5 display-flex">
                                        <label class="bold-700 col-4 col-85 p-0 pt-pix-9">Qty</label>
                                        <div class="inventory-input col-8 p-0" *ngIf="inventoryProductForLog">
                                            <div class="max-col-120">
                                                <div class="inventory-product-input">
                                                    <input posInput class="form-control h-38" name="primaryZoneQty"
                                                        (input)="calculateQty()"
                                                        [autoFocus]="inventoryProductDetails.PrimaryZoneId == inventoryZoneId || inventoryZoneId == 0"
                                                        [selectOnFocus] [(ngModel)]="currentInventoryScanner.PrimaryQty"
                                                        type="text" type="number" />
                                                </div>
                                            </div>
                                            <div class="ms-pix-5 col-85 opacity-09 align-self-center">
                                                {{inventoryProductForLog.Unit}}(s)
                                            </div>
                                        </div>
                                    </pos-column>
                                </pos-row>
                            </div>
                        </div>
                    </pos-column>
                    <!--Primary Zone End-->

                    <!--Secondary Zone Start-->
                    <pos-column xs="12" class="p-0 section-border" *ngIf="inventoryProductDetails.SecondaryZoneName">
                        <div class="col-12 p-0 display-flex"
                            [ngClass]="{'zone-highlight': inventoryProductDetails.SecondaryZoneId == inventoryZoneId}">
                            <div class="bin-color"
                                [ngStyle]="{'background-color': inventoryProductDetails.SecondaryZoneColor}">
                            </div>
                            <div class="mb-pix-7 ms-pix-10 flex-grow">
                                <pos-row colStyleClass="pt-pix-5" colXs="12">
                                    <pos-column class="display-flex">
                                        <div class="flex-grow">
                                            <label
                                                class="bold-700 mb-0 font-16">{{inventoryProductDetails.SecondaryZoneName}}&nbsp;</label>
                                            <em>({{inventoryProductDetails.SecondaryZoneLabel}})</em>
                                            <span class="bin-circle" *ngIf="inventoryProductDetails.SecondaryBinName"
                                                [ngStyle]="{'background-color': inventoryProductDetails.SecondaryBinColor,
                                                        'color': getContrastColor(inventoryProductDetails.SecondaryBinColor)}">
                                                {{inventoryProductDetails.SecondaryBinName}}
                                            </span>
                                        </div>
                                    </pos-column>
                                    <pos-column class="pt-pix-5 display-flex">
                                        <label class="bold-700 col-4 col-85 p-0 pt-pix-9">Qty</label>
                                        <div class="inventory-input col-8 p-0" *ngIf="inventoryProductForLog">
                                            <div class="max-col-120">
                                                <div class="inventory-product-input">
                                                    <input posInput class="form-control h-38" name="secondaryZoneQty"
                                                        (input)="calculateQty()"
                                                        [autoFocus]="inventoryProductDetails.SecondaryZoneId == inventoryZoneId"
                                                        [selectOnFocus]
                                                        [(ngModel)]="currentInventoryScanner.SecondaryQty" type="text"
                                                        type="number" />
                                                </div>
                                            </div>
                                            <div class="ms-pix-5 col-85 opacity-09 align-self-center">
                                                {{inventoryProductForLog.Unit}}(s)
                                            </div>
                                        </div>
                                    </pos-column>
                                </pos-row>
                            </div>
                        </div>
                    </pos-column>
                    <!--Secondary Zone End-->

                    <!-- other start -->
                    <pos-column class="p-0 pti-0">
                        <div class="col-12 p-0 section-border display-flex">
                            <div class="flex-grow">
                                <pos-row class="pt-pix-5" colXs="12">
                                    <pos-column class="display-flex">
                                        <label class="bold-700 col-4 col-95 p-0 pt-pix-9">Other</label>
                                        <div class="inventory-input col-8 p-0" *ngIf="inventoryProductForLog">
                                            <div class="max-col-120">
                                                <div class="inventory-product-input">
                                                    <input posInput class="form-control h-38" name="otherQty"
                                                        (input)="calculateQty()" [selectOnFocus]
                                                        [(ngModel)]="currentInventoryScanner.OtherQty" type="text"
                                                        type="number" />
                                                </div>
                                            </div>
                                            <div class="ms-pix-5 col-85 opacity-09 align-self-center">
                                                {{inventoryProductForLog.Unit}}(s)
                                            </div>
                                        </div>
                                    </pos-column>
                                </pos-row>
                            </div>
                        </div>
                    </pos-column>
                    <!-- other end -->

                    <pos-column class="p-0 pti-0">
                        <div class="col-12 p-0 display-flex section-border">
                            <div class="flex-grow">
                                <pos-row class="pt-pix-5" colXs="12">
                                    <pos-column class="display-flex">
                                        <label class="bold-700 col-4 p-0 pt-pix-7 col-95 font-16">Total Qty</label>
                                        <div class="inventory-input col-8 p-0" *ngIf="inventoryProductForLog">
                                            <div class="max-col-120" [ngClass]="{
                                            'checked':inventoryProductForLog.IsUpdated,
                                            'inventory-alert': (
                                                (inventoryProductForLog.varianceExceedsLowThreshold || inventoryProductForLog.varianceExceedsHighThreshold)
                                                && inventoryProductForLog.IsWarnUnexpectedInventoryQty)}">
                                                <div class="inventory-product-input">
                                                    <input posInput class="form-control h-38" disabled
                                                        id="{{'txtQty'+inventoryProductForLog.Id}}" name="inventoryLog"
                                                        (input)="onChangeQty(inventoryProductForLog);"
                                                        [(ngModel)]="this.currentInventoryScanner.TotalQty" type="text"
                                                        type="number"
                                                        tooltip="{{'Value should be in multiple of '+inventoryProductForLog.UnitQtyIncrement}}"
                                                        triggers="" min="0" placement="top" #inputLog="bs-tooltip" />
                                                    <pos-icon [icon]="icons.inventoryLogWarning"
                                                        [hidden]="!((inventoryProductForLog.varianceExceedsHighThreshold ||inventoryProductForLog.varianceExceedsLowThreshold)
                                                                && inventoryProductForLog.IsWarnUnexpectedInventoryQty)"
                                                        tooltip="Calculated quantity differs by more than {{inventoryProductForLog.DifferedBy}}"
                                                        placement="top">
                                                    </pos-icon>
                                                </div>
                                            </div>
                                            <div class="ms-pix-5 col-85 opacity-09 align-self-center">
                                                {{inventoryProductForLog.Unit}}(s)
                                            </div>
                                            <div class="p-0 mb-pix-1 align-left display-flex">
                                                <div class="width-30-px me-pix-5 text-align-center"
                                                    [hidden]="inventoryProductForLog.DaysUntilDue>0">
                                                    <span class="font-16 pointer">
                                                        <pos-icon [icon]="icons.inventoryLogClock"
                                                            tooltip="Inventory has not been logged since {{inventoryProductForLog.LastLog | tark_date_time}}"
                                                            placement="top">
                                                        </pos-icon>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </pos-column>
                                </pos-row>
                            </div>
                        </div>
                    </pos-column>

                    <pos-column xs="12" class="p-0 pt-pix-10 row mx-0"
                        *ngIf="inventoryProductForLog && inventoryProductForLog.TotalPrice >300">
                        <label class="bold-700 col-4 p-0 pt-pix-7 col-95 font-16"></label>
                        <div class="col-8 p-0">
                            <span class="inventory-block__items__price italic p-pix-3">Value:
                                {{inventoryProductForLog.TotalPrice | tark_currency}}</span>
                        </div>
                    </pos-column>

                    <pos-column xs="12" class="p-0 pt-pix-10" *ngIf="inventoryProductForLog?.LastLog">
                        <label class="bold-700 col-95 col-4 p-0 mb-0">Last Logged</label>
                        <div class="col-8 p-0">
                            <div>{{this.inventoryProductForLog.oldQty | tark_number}} {{inventoryProductForLog.Unit}}(s)
                            </div>
                            <div><em>({{inventoryProductForLog?.LastLog | tark_date_time}})</em></div>
                        </div>
                        <div></div>
                    </pos-column>

                    <pos-column class="p-0">
                        <label class="bold-700 col-95 col-4 p-0 pt-pix-7">Memo</label>
                        <div class="col-8 col-md-4 p-0">
                            <input posInput posInput type="text" name="inventoryMemo" class="form-control"
                                [(ngModel)]="inventoryProductForLog.Memo" />
                        </div>
                    </pos-column>

                    <pos-column class="p-0">
                        <label class="bold-700 col-95 col-4 p-0 mb-0">Vendor</label>
                        <div class="col-8 p-0">{{inventoryProductDetails.VendorName}}</div>
                    </pos-column>
                    <pos-column class="p-0">
                        <label class="bold-700 col-95 col-4 p-0 mb-0">SKU</label>
                        <div class="col-8 p-0">{{inventoryProductDetails.vendor_sku ?? 'Not Set'}}</div>
                    </pos-column>
                    <pos-column class="p-0">
                        <label class="bold-700 col-95 col-4 p-0 mb-0">Unit Details</label>
                        <div class="col-8 p-0">{{inventoryProductForLog?.UnitDetails ?
                            inventoryProductForLog?.UnitDetails : 'Not Set'}}</div>
                    </pos-column>

                </pos-row>
            </div>
            <div class="col-12 mt-pix-10 action-button-container page-footer text-align-center"
                *ngIf="inventoryScannerBatch?.length">
                <button class="btn-lg btn pos-button submit-batch" type="button" (click)="redirectToBatchList()">Submit
                    Batch <span class="count-circle">{{inventoryScannerBatch?.length ?? 0}}</span></button>
            </div>
        </form>
    </pos-edit-wrapper>
</div>

<ng-template #salesProductStockWarning>
    <div>
        <button (click)="closeWarningModel()" aria-label="Close" class="close" type="button">&times;</button>
        <h4 class="modal-content__heading">Confirm</h4>
    </div>
    <div class="modal-body pos-modal-body">
        <pos-inventory-log-stock-sales-product-warning [salesInventoryProducts]="salesInventoryProducts">
        </pos-inventory-log-stock-sales-product-warning>
    </div>
    <div class="col-12 action-button-container popup-footer pb-pix-10">
        <button type="submit" class="btn btn-primary btn-lg btn-save pos-button"
            (click)="saveInventoryQty()">Continue</button>
        <button type="button" class="btn btn-secondary btn-lg btn-cancel-modal pos-button"
            (click)="closeWarningModel()">Cancel</button>
    </div>
    <div class="clearfix"></div>
</ng-template>
