import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { BaseCrudService } from 'src/app/shared/services';
import { ReportModel } from '../interface/report-model';
import { Rule } from '../interface/report-rules';
import { ReportSaveModel } from '../interface/report-save-model';

@Injectable({
    providedIn: 'root'
})
export class ReportConfigurationService extends BaseCrudService<ReportModel> {
    constructor(private httpClient: HttpClient) {
        super('report', httpClient);
    }

    newReport(): ReportModel {
        return {
            Id: 0,
            Name: null,
            IsPaged: false,
            ReportGroupId: null,
            ReportCategoryId: null,
            ReportIcon: null,
            DataSourceId: null
        };
    }

    newRule() {
        const rule: Rule = {
            Id: 0,
            Parameter1Id: null,
            Parameter2Id: null,
            Comparator: null,
            Message: null,
        };
        return rule;
    }

    public getAll(): Observable<Array<ReportModel>> {
        return this.httpClient.get<Array<ReportModel>>(this.apiEndpoint + '/reports');
    }

    updateReportOrdinal(reportCategory: Array<ReportModel>) {
        return this.httpService.put<Array<ReportModel>>(`${this.apiEndpoint}/ordinal`, reportCategory);
    }

    saveReport(reportSaveModel: ReportSaveModel) {
        return this.httpClient.post<ReportSaveModel>(`${this.apiEndpoint}`, reportSaveModel);
    }

    delete(reportId) {
        return this.httpService.delete(`${this.apiEndpoint}/delete-report/` + reportId);
    }
}
