import { Component, OnInit, ViewChild } from '@angular/core';
import { times, check } from 'src/app/shared/components/icon';
import { BaseListComponent } from 'src/app/shared/components/base-list.component';
import { Diagram } from '../../interfaces/layout-designer';
import { AlertsService, SpinnerService, ModalService, PrintTableService, Messages, ApplicationStateService } from 'src/app/shared';
import { LayoutDesignersService } from '../../../shared/services/layout-designers.service';
import { GridColumn, TableComponent, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'pos-layout-designer-list',
  templateUrl: './layout-designer-list.component.html',
  styleUrls: ['./layout-designer-list.component.scss']
})
export class LayoutDesignerListComponent extends BaseListComponent<Diagram> implements OnInit {


  icons = {
    times,
    check
  };
  layoutColumns: Array<GridColumn> = [];
  layouts: Diagram[] = [];
  @ViewChild('activeTemplate', { static: true }) private activeTemplate: any;
  @ViewChild('layoutsGrid', { static: true }) layoutsGrid: TableComponent;

  constructor(protected layoutDesignerService: LayoutDesignersService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected applicationStateService: ApplicationStateService,
    protected printService: PrintTableService) {
    super(layoutDesignerService, alertService, spinnerService, modalService, applicationStateService, printService, route);
  }

  ngOnInit() {
    this.deleteSuccessMessage = Messages.LayoutDesignDeleteSuccess;
    this.layoutColumns = this.configureGridColumns();
    this.loadSubscriptions();
    this.layoutsGrid.context = this.gridContext;
  }

  getGridColumns(): GridColumn[] {
    const activeColumn = new TemplateColumn({
      HeaderText: 'Active',
      itemTemplate: this.activeTemplate,
      TextAlign: TextAlign.Center,
      Width: '85px',
      Field: 'IsActive',
      IsSortable: true
    });


    return [
      new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '88%' }),
      activeColumn
    ] as Array<GridColumn>;
  }

  editItem(id: number, data?: Diagram): void {
    if (id > 0) {
      this.router.navigate(['manage/layouts/edit', id]);
    } else {
      this.router.navigate(['manage/layouts/edit', 0]);
    }
  }

  getConfirmDeleteMessage(data: Diagram): string {
    return Messages.ConfirmDeleteLayout;
  }

  private loadSubscriptions(): void {
    this.data$.subscribe({
      next: (res) => {
        this.layouts = res;
      }
    });
  }

  cancel() {
    this.router.navigate(['/manage/console']);
  }

}
