<pos-edit-wrapper heading="Select user" (close)="cancel()">
    <div class="col-12 px-pix-10 screen-scroll pti-5">
        <div class="screen-scroll">
            <div *ngFor="let user of users" class="select-user-tile pointer font-16 display-flex" (click)="selectUser(user.Id)">
                <div class="min-col-50">
                    <span
                        class="font-14 white select-user-badge py-pix-5 mt-pix-1 radius-15 bold order-section__bottom__block__buttons__btn text-ellipsis bg-black">
                        {{user.FirstName?.charAt(0)?.toUpperCase()}}{{user.LastName?.charAt(0)?.toUpperCase()}}
                    </span>
                </div>
                {{user.LastName}}, {{user.FirstName}}

            </div>
        </div>

    </div>

    <div class="col-12 action-button-container pt-pix-10">
        <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button"
            (click)="cancel()">Close</button>
    </div>
</pos-edit-wrapper>
