<pos-list-wrapper [headingIcon]="icons.database" heading="Data Sources" (close)="close()">
  <div class="row">
    <div class="col-md-12 col-sm-12">
      <ng-table #dataSourceTable [search]="true" [sort]="true" [paginator]="true" [columns]="dataSourceColumns"
        [data]="dataSourceList" [exportFilename]="'data-source'">
      </ng-table>

    </div>
  </div>
  <ng-template let-data="rowData" #operationHeaderTemplate>
    <span>
      <span (click)="editItem(0)" tooltip title="Add">
        <pos-action-icon [icon]="icons.plusWhite" iconText="Add"></pos-action-icon>
      </span>
    </span>
    <span (click)="printData()" tooltip title="Print">
      <pos-action-icon [icon]="icons.printWhite" iconText="Print" class="ms-pix-5"></pos-action-icon>
    </span>
  </ng-template>

  <ng-template let-data="rowData" #operationTemplate>
    <div>
      <span class="pe-pix-3" (click)="editItem(data.Id)" tooltip title="Edit">
        <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
      </span>
      <span class="pe-pix-3" (click)="confirmDelete(data)" tooltip title="Delete">
        <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
      </span>
      <span class="pointer pe-pix-3" (click)="preview(data)" tooltip title="Preview">
        <pos-icon [icons]="[icons.squareLightOrangeStack, icons.folderMagnifyingGlass]"></pos-icon>
      </span>
    </div>
  </ng-template>
</pos-list-wrapper>
<pos-print-table [hidden]="true"></pos-print-table>
