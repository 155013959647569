import { ApplicationStateService } from './../../../../shared/services/application-state.service';
import { AlertsService, SpinnerService, RuntimeConstants, ModalService } from 'src/app/shared';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { TrainingAreaService } from 'src/app/shared/services/training-area.service';
import { TrainingArea } from '../../interface';
import { TrainingTopicComponent } from 'src/app/information-management/training-topics/components/training-topic/training-topic.component';
import { TrainingTopicService } from 'src/app/shared/services/training-topic.service';
import * as _ from 'lodash';
import { UserTraining } from 'src/app/training';
@Component({
  selector: 'training-areas',
  templateUrl: './training-area.component.html'
})
export class TrainingAreaComponent implements OnInit {
  trainingAreas: Array<TrainingArea>;
  close: EventEmitter<any> = new EventEmitter();
  public imageRootPath = RuntimeConstants.IMAGE_BASE_PATH + '/training-area-icon';
  constructor(private alertService: AlertsService,
    private applicationStateService: ApplicationStateService,
    private spinnerService: SpinnerService,
    private trainingAreaService: TrainingAreaService,
    private modalService: ModalService,
    private trainingTopicService: TrainingTopicService
  ) { }

  ngOnInit() {
    this.getTrainingAreaList();
  }

  getTrainingAreaList() {
    this.spinnerService.show();
    this.trainingAreaService.getTrainingAreas().subscribe(
      res => {
        this.trainingTopicService.GetTrainingAreaWithTopics().subscribe(
          trainingTopics => {
            this.trainingAreaService.getUserTrainings(this.applicationStateService.userDetails.id).subscribe(
              userTrainings => {
                this.setTrainingArea(res, trainingTopics, userTrainings);
                this.trainingAreas = _.filter(res, (area) => {
                  return area.TotalLevels > 0;
                });
              }, this.alertService.showApiError,
              () => {
                this.spinnerService.hide();
              });
          },
          this.alertService.showApiError,
          () => {
            this.spinnerService.hide();
          });
      },
      this.alertService.showApiError
    );
  }

  setTrainingArea(trainingArea, trainingTopics, userTrainings: Array<UserTraining>) {
    trainingArea.forEach(area => {
      area.TrainingTopics = [];
      area.TotalLevels = 0;
      area.CompletedLevels = 0;
      trainingTopics.forEach(topic => {
        if (topic.TrainingArea && topic.TrainingArea.Id == area.Id && topic.TrainingTopicRoles.length) {
          const assignedRoles = _.intersectionBy(this.applicationStateService.userDetails.Roles, topic.TrainingTopicRoles, 'RoleId');
          if (assignedRoles?.length) {
            area.TrainingTopics.push(topic);
            area.TotalLevels += topic.TrainingTopicLevels ? topic.TrainingTopicLevels.length : 0;
            _.forEach(topic.TrainingTopicLevels, (level) => {
              let userTraining = _.find(userTrainings, (ut: UserTraining) => {
                return ut.TrainingLevelId == level.Id;
              });
              if (userTraining) {
                area.CompletedLevels += 1;
              }
            });
          }
        }
      });

    });
  }

  onCancel() {
    this.close.emit();
  }
  selectArea(trainingArea) {
    const modal = this.modalService.getModalWrapper(TrainingTopicComponent);
    modal.show({
      animated: false,
      class: 'vertical-center',
      initialState: {
        trainingAreaId: trainingArea.Id,
        trainingAreaName: trainingArea.Name
      }
    });
  }
}
