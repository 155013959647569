<div class="px-2">
  <pos-list-wrapper [headingIcon]="icons.upload" heading="Release Queue" (close)="close()">
    <div class="row">
      <div class="col-12">
        <ng-table #manualExportGrid [search]="true" [sort]="true" [paginator]="true" [columns]="manualDataExportColumns"
          [data]="manualDataExportList" exportFilename="manual-data-export">
        </ng-table>
  
        <ng-template #headerTemplate>
          <div class="text-center">
            <pos-export-dropdown class="inline-block" (onPrint)="printData()" (onCSV)="exportCSV()">
            </pos-export-dropdown>
          </div>
        </ng-template>
  
        <ng-template let-data="rowData" #operationTemplate>
          <div class="text-center">
            <pos-action-icon (click)="review(data)" [icon]="icons.fileSearchWhite" iconText="Review"></pos-action-icon>
          </div>
        </ng-template>
      </div>
    </div>
  </pos-list-wrapper>
</div>

<pos-print-table [hidden]="true"></pos-print-table>
