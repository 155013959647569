import { MessageEndpoint } from './message-endpoint';

export class TerminalEndpoint extends MessageEndpoint {

    public Id: number;
    public Name: string;

    constructor(id: number, name: string) {
        super();
        this.Id = id;
        this.Name = name;
    }
}
