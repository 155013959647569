import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PermissionRoles } from '../interface/permission-roles';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { map } from 'rxjs/operators';
import { SavePermissionRolesModel } from '../interface/save-permission-roles-model';


@Injectable()
export class SecurityRoleService {

   securityRoleApiUrl = '';
   constructor(private httpService: HttpClient) {
      this.securityRoleApiUrl = RuntimeConstants.API_PATH + 'securityrole';
   }

   getSecurityRoles(includeSystemDefined: boolean): Observable<any> {
      return this.httpService.get(`${this.securityRoleApiUrl}/` + includeSystemDefined)
         .pipe(
            map((value: PermissionRoles[]): PermissionRoles[] => {
               return value;
            })
         );
   }
   getSecurityRole(roleId: number): Observable<any> {
      return this.httpService.get(`${this.securityRoleApiUrl}/` + roleId);
   }

   copySecurityRole(permissionRole: PermissionRoles): Observable<any> {
      return this.httpService.post(`${this.securityRoleApiUrl}/copy`, permissionRole);
   }

   deleteSecurityRole(roleId: number) {
      return this.httpService.delete(`${this.securityRoleApiUrl}/` + roleId);
   }

   savePermissionRole(permissionModel: SavePermissionRolesModel) {
      return this.httpService.post(`${this.securityRoleApiUrl}/${permissionModel.RoleId}`, permissionModel);
   }

   getButtonExclusion(roleId: number) {
      return this.httpService.get(`${this.securityRoleApiUrl}/` + roleId + `/buttonExclusion`);
   }

}
