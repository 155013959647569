<pos-edit-wrapper heading="Features" [headingIcon]="icons.star" (close)="cancel()" [isPopup]="false">
    <form (ngSubmit)="saveFeatureConfiguration(featureConfiguration.valid)" #featureConfiguration="ngForm"
        class="edit-form">
        <div class="col-xs-12 border-top pt-pix-5 content-scroll">
            <div class="col-xs-12 pos-page-body">
                <pos-row>
                    <pos-column *ngFor="let feature of featuresConfigurations;let index = index;">
                        <pos-form-field>
                            <p-checkbox posInput name="chk{{index}}" [label]="feature.Name"
                                #chk{{index}}="ngModel"
                                [(ngModel)]="feature.IsActive" binary="true">
                            </p-checkbox>
                        </pos-form-field>
                    </pos-column>
                </pos-row>
            </div>
        </div>
        <div class="col-xs-12 action-button-container page-footer">
            <button class="btn-primary btn-lg btn btn-save pos-button" scrollup type="submit"
                value="Save">Save</button>
            <button class="btn-secondary btn-lg btn btn-cancel pos-button" routerLink="../" scrollup type="button"
                value="Cancel">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
