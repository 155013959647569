import { Injectable } from '@angular/core';
import { BaseCrudService } from './base-crud.service';
import { HttpClient } from '@angular/common/http';
import { RuntimeConstants } from '../constants/runtime-constants';

@Injectable({
    providedIn: 'root'
})
export class MultiProductNextScreenService extends BaseCrudService<MultiProductNextScreen> {

    multiProductGroupApiUrl: string = '';
    constructor(httpService: HttpClient) {
        super('MultiProductNextScreen', httpService);
        this.multiProductGroupApiUrl = `${RuntimeConstants.API_PATH}MultiProductNextScreen`;
    }

    getMultiProductNextScreenById(screenId: number) {
        return this.httpService.get(
          `${this.multiProductGroupApiUrl}/screen/${screenId}`
        );
      }

}

export class MultiProductNextScreen {
    Id: number;
    ScreenId: number;
    MultiProductGroupId: number;
    MultiProductNextScreenId: number;
    Group: string;
    NextScreen: string;
}
