import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule } from '@angular/forms';
import { ButtonWithInactiveProductsComponent } from './Components/button-with-inactive-products/button-with-inactive-products.component';
import {UnlinkedScreenComponent } from './Components/unlinked-screen/unlinked-screen.component';
import { MenuSetupService } from './Services/menu-setup.service';
import { ComponentsModule, TableConfiguration } from '../shared';
import { NgPipesModule } from 'ngx-pipes';
import { TableModule } from '@tarktech/tark-ng-utils';
import { RouterModule } from '@angular/router';
import { MENU_SETUP_ROUTES } from './menu-setup.routes';
import { UnusedImagesComponent } from './Components/unused-images/unused-images.component';
import { CheckboxModule } from 'primeng/checkbox';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ComponentsModule,
    NgPipesModule,
    CheckboxModule,
    ModalModule.forRoot(),
    TableModule.forRoot(TableConfiguration),
    RouterModule.forChild(MENU_SETUP_ROUTES)
  ],
  providers: [MenuSetupService],
  declarations: [ButtonWithInactiveProductsComponent, UnlinkedScreenComponent, UnusedImagesComponent]
})
export class MenuSetupModule { }
