import { GridColumn } from './grid-column';
import { GridColumnTypes } from './grid-column-types';
import { TextAlign } from './text-align';

export class LinkColumn extends GridColumn {
    Clicked: (data: any) => void;

    Icon?: string;

    ToolTip?: string;

    get Type(): string {
        return GridColumnTypes.LinkColumn;
    }
    constructor(init?: Partial<LinkColumn>) {
        super();
        this.TextAlign = TextAlign.Center;
        this.Width = '60px';
        this.CellClass = 'cell-link cell-padding';
        this.BackgroundColor = 'orangered';

        Object.assign(this, init);
    }
}
