import { TrainingApproveService } from './services/training-approve.service';
import { TrainingApproveComponent } from './components/training-approve.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TableModule } from '@tarktech/tark-ng-utils';
import { ComponentsModule } from '../../shared/components/components.module';
import { UserTrainingLevelService } from 'src/app/information-management/training-topic-levels/services/user-training-level.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TrainingLevelContentComponent } from './components/training-level-content/training-level-content.component';
import { UserTrainingNotesComponent } from 'src/app/information-management/training-topic-levels/components/user-training-notes/user-training-notes.component';
import { UserTrainingNotesService } from 'src/app/information-management';
import { UserTrainingNoteEditComponent } from 'src/app/information-management/training-topic-levels/components/user-training-note-edit/user-training-note-edit.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TableModule,
    ComponentsModule
  ],
  declarations:[TrainingApproveComponent, TrainingLevelContentComponent, UserTrainingNotesComponent, UserTrainingNoteEditComponent],
  providers: [TrainingApproveService, UserTrainingLevelService, UserTrainingNotesService,{ provide: BsModalRef, useClass: BsModalRef }]
})
export class TrainingModule { }
