import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { GridColumn, DeleteColumn, EditColumn } from '@tarktech/tark-ng-utils';
import { SpinnerService, AlertsService, Messages, ModalService, ConfirmDeleteModalComponent } from 'src/app/shared';
import { InventoryBinLightService } from 'src/app/shared/services/inventory-bin-light.service';
import { InventoryBinLightDeviceModel } from 'src/app/shared/interface/inventory-bin-light-device-model';
import { zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { InventoryBinLightModel } from 'src/app/shared/interface/inventory-bin-light-model';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'pos-inventory-zone-bin-light',
  templateUrl: './inventory-zone-bin-light.component.html',
  styleUrls: ['./inventory-zone-bin-light.component.scss']
})
export class InventoryZoneBinLightComponent implements OnInit {

  @Input() inventoryBinId: number;
  inventoryZoneBinsLightColumns: Array<GridColumn> = [];
  selectedLigthId: number;
  lightingDevicesList: Array<InventoryBinLightDeviceModel> = [];
  inventoryBinLightsList: Array<InventoryBinLightModel> = [];
  currentBinLight: any = {};
  isDirtyForm: boolean;
  @ViewChild('binLightForm') binLightForm: NgForm;

  constructor(private spinnerService: SpinnerService,
    private modalService: ModalService,
    private alertService: AlertsService,
    private inventoryBinLightService: InventoryBinLightService) { }

  ngOnInit() {
    this.configureInventoryBinLightColumns();
    this.getInventoryBinLight();
  }

  configureInventoryBinLightColumns() {
    const editColumn = new EditColumn({
      Clicked: (data) => {
        this.editInventoryBinLight(data);
      },
      Width: '30px'
    });

    const deleteColumn = new DeleteColumn({
      Clicked: (data) => {
        this.deleteInventoryBinLight(data.Id);
      },
      Width: '30px'
    });

    this.inventoryZoneBinsLightColumns = [
      new GridColumn({ HeaderText: 'Address', Field: 'LightAddress', IsSortable: true, Width: '40%' }),
      new GridColumn({ HeaderText: 'Controller', Field: 'DeviceName', IsSortable: true, Width: '44%' }),
    ];

    this.inventoryZoneBinsLightColumns.push(editColumn);
    this.inventoryZoneBinsLightColumns.push(deleteColumn);
  }

  deleteInventoryBinLight(lightId: number) {
    this.selectedLigthId = lightId;
    const modalRef = this.modalService.show(ConfirmDeleteModalComponent, {
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      initialState: {
        message: Messages.ConfirmDeleteInventoryBinLight
      }
    });

    modalRef.close.subscribe(res => {
      if (res?.shouldDelete) {
        this.onConfirmAcknowledgeLightDelete();
      }
    });
  }

  onConfirmAcknowledgeLightDelete() {
    this.spinnerService.show();
    this.inventoryBinLightService.deleteinventoryBinLight(this.selectedLigthId).subscribe(response => {
      this.getInventoryBinLight();
      this.alertService.renderSuccessMessage(Messages.InventoryBinLightDeleted);
    },
      (error) => { this.alertService.showApiError(error); },
      () => { this.spinnerService.hide(); });
  }

  getInventoryBinLight() {
    this.resetBinLight();
    this.lightingDevicesList = [];

    zip(
      this.inventoryBinLightService.getinventoryBinLightDevices(),
      this.inventoryBinLightService.getinventoryBinLights(this.inventoryBinId)
    ).pipe(
      map(([lightDeviceResponse, lightResponse]) => {
        this.lightingDevicesList = lightDeviceResponse == null ? [] : lightDeviceResponse;
        this.inventoryBinLightsList = lightResponse == null ? [] : lightResponse;
      })
    ).subscribe({
      next: (res) => {
        this.inventoryBinLightsList.forEach(element => {
          this.lightingDevicesList.forEach(el => {
            if (el.DeviceId === element.DeviceId) {
              element.DeviceName = el.DeviceName;
            }
          });
        });
      }, error: this.alertService.showApiError,
      complete: () => {
        this.spinnerService.hide();
      }
    });
  }

  resetBinLight() {
    this.currentBinLight = {};
    this.isDirtyForm = true;
    this.binLightForm?.resetForm();
  }

  editInventoryBinLight(binLight) {
    this.currentBinLight = { ...binLight };
  }

  saveInventoryBinLight() {
    this.isDirtyForm = false;
    const isBinLightAlreadyExist = this.checkBinLightAlreadyExist();
    if (this.currentBinLight.LightAddress != null && this.currentBinLight !== '' && this.currentBinLight.DeviceId !== null
      && this.currentBinLight.DeviceId !== 0) {
      this.isDirtyForm = true;
    }
    if (this.isDirtyForm) {
      if (isBinLightAlreadyExist) {
        if (this.currentBinLight.Id > 0) {
          this.updateInventoryBinLight();
        } else {
          this.addInventoryBinLight();
        }
        this.binLightForm.resetForm();
      } else {
        this.alertService.renderErrorMessage(Messages.InventoryBinLightAlreadyExist);
      }
    }
  }

  checkBinLightAlreadyExist() {
    const availableLight = this.inventoryBinLightsList.filter((binlight) => {
      return binlight.LightAddress === this.currentBinLight.LightAddress && binlight.DeviceId === this.currentBinLight.DeviceId
        && binlight.Id !== this.currentBinLight.Id;
    });
    if (availableLight.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  updateInventoryBinLight() {
    this.spinnerService.show();
    const binLightObject: InventoryBinLightModel = {
      Id: this.currentBinLight.Id,
      BinId: this.inventoryBinId,
      DeviceId: this.currentBinLight.DeviceId,
      LightAddress: this.currentBinLight.LightAddress,
      DeviceName: ''
    };
    this.inventoryBinLightService.updateinventoryBinLight(binLightObject).subscribe(response => {
      this.getInventoryBinLight();
      this.alertService.renderSuccessMessage(Messages.InventoryBinLightSaveSuccess);
    },
      (error) => { this.alertService.showApiError(error); },
      () => {
        this.spinnerService.hide();
      });
  }

  addInventoryBinLight() {
    this.spinnerService.show();
    const binLightObject: InventoryBinLightModel = {
      Id: 0,
      BinId: this.inventoryBinId,
      DeviceId: this.currentBinLight.DeviceId,
      LightAddress: this.currentBinLight.LightAddress,
      DeviceName: ''
    };
    this.inventoryBinLightService.addinventoryBinLight(binLightObject).subscribe(response => {
      this.getInventoryBinLight();
      this.alertService.renderSuccessMessage(Messages.InventoryBinLightSaveSuccess);
    },
      (error) => { this.alertService.showApiError(error); },
      () => {
        this.spinnerService.hide();
      });

  }

}
