import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'pos-iframe-widget-edit',
  templateUrl: './iframe-widget-edit.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class IframeWidgetEditComponent implements OnInit {

  @Input() widgetConfig: any;
  ngOnInit(): void {
    this.widgetConfig.config = this.widgetConfig.config ? this.widgetConfig.config : {};
  }

}
