import { Component, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { first } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs';
import { SalesProduct, SalesProductsService } from 'src/app/information-management/sales-products';
import { AlertsService, ButtonBehavior, FormUtilityService, ModalFormComponent, ModalService, SpinnerService } from 'src/app/shared';

@Component({
  selector: 'pos-main-product',
  templateUrl: './main-product.component.html'
})
export class MainProductComponent extends ModalFormComponent {
  @Input() buttonBehavior = null;
  @ViewChild('detailEntryForm') detailEntryForm: NgForm;
  selectedSalesProductId: number;
  salesProducts: SalesProduct[];

  constructor(protected spinnerService: SpinnerService,
    protected alertsService: AlertsService,
    private salesProductService: SalesProductsService,
    modalRef: BsModalRef,
    modalService: ModalService,
    protected formUtilityService: FormUtilityService) {
    super(modalRef, modalService, formUtilityService);
  }

  get getForm(): NgForm {
    return this.detailEntryForm;
  }

  ngOnInit() {
    if (this.buttonBehavior && this.buttonBehavior.Id) {
      try {
        const behavior = first(this.buttonBehavior.BehaviorValue) as any;
        this.selectedSalesProductId = behavior.SalesProductId??null;
      } catch (ex) {
        console.error('Invalid behavior value: ' + this.buttonBehavior.TextValue);
      }
    }
    this.loadData();
  }

  loadData() {
    this.spinnerService.show();
    this.salesProductService.getSalesProducts()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.salesProducts = res;
        }, error: this.alertsService.showApiError
      });
  }

  saveBehavior = (isValid: boolean) => {
    if (isValid && this.selectedSalesProductId) {
      this.hide({ shouldReload: true, Value: { SalesProductId: this.selectedSalesProductId } });
    }
  }

  closeModal = () => {
    this.hide({ shouldReload: false });
  }
}
