import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[kioskScreenTemplate]'
})
export class KioskScreenTemplateDirective {

  constructor(public viewContainerRef: ViewContainerRef) {}

}
