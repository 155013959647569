import { Component, OnInit, ViewChild, TemplateRef, EventEmitter } from '@angular/core';
import { GridColumn, TemplateColumn, TextAlign, DateColumn } from '@tarktech/tark-ng-utils';
import {
  deleteWhite, database, screenBackupPlus
} from 'src/app/shared/components/icon';
import { SpinnerService, AlertsService, ModalService, ConfirmDeleteModalComponent, Messages, ICloseable, Permissions, Levels, AuthenticationService, StringUtils } from 'src/app/shared';
import { BackupService } from '../../services/backup.service';
import { finalize } from 'rxjs/operators';
import { ScreenBackupEditComponent } from './screen-backup-edit/screen-backup-edit.component';

@Component({
  selector: 'pos-screen-backup',
  templateUrl: './screen-backup.component.html',
  styleUrls: ['./screen-backup.component.scss']
})
export class ScreenBackupComponent implements OnInit, ICloseable {
  close: EventEmitter<any> = new EventEmitter();

  backupColumns: Array<GridColumn> = [];
  backups: Array<any> = [];
  @ViewChild('operationTemplate', { static: true }) operationTemplate: TemplateRef<any>;
  @ViewChild('headerTemplate', { static: true }) headerTemplate: TemplateRef<any>;
  public icons = {
    deleteWhite,
    database,
    screenBackupPlus
  };

  permission = {
    Name: Permissions.ManageScreenBackups,
    EditLevel: Levels.Edit
  }
  constructor(private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private backupService: BackupService,
    private authenticationService: AuthenticationService,
    private modalService: ModalService) { }

  ngOnInit() {
    this.configureGridColumn();
    this.getBackups();
  }

  getBackups() {
    this.spinnerService.show();
    this.backupService.getScreenBackupsList()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: any) => {
          this.backups = res?.BackupModel ?? [];
        }, error: this.alertService.showApiError
      });
  }

  confirmDelete(backup) {
    const confirmCommonNextScreen = this.modalService.show(ConfirmDeleteModalComponent, {
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      initialState: {
        message: StringUtils.format(Messages.ConfirmDeleteBackup, { Name: backup.Name, Time: backup.DateAdded1 })
      }
    });
    confirmCommonNextScreen.close.subscribe(res => {
      if (res?.shouldDelete) {
        this.deleteBackup(backup.Id);
      }
    });
  }

  deleteBackup(backupId: number) {
    this.spinnerService.show();
    this.backupService.deleteBackup(backupId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: any) => {
          this.alertService.renderSuccessMessage(Messages.BackupDeletedSuccess);
          this.getBackups();
        }, error: this.alertService.showApiError
      });
  }

  confirmRestoreBackup(backup) {
    const confirmCommonNextScreen = this.modalService.show(ConfirmDeleteModalComponent, {
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      initialState: {
        message: StringUtils.format(Messages.ConfirmRestoreBackup, { Name: backup.Name, Time: backup.DateAdded1 })
      }
    });
    confirmCommonNextScreen.close.subscribe(res => {
      if (res?.shouldDelete) {
        this.restoreBackup(backup.Id);
      }
    });
  }

  restoreBackup(backupId: number) {
    this.spinnerService.show();
    this.backupService.restoreScreenFromBackup(backupId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: any) => {
          this.alertService.renderSuccessMessage(Messages.BackupRestoredSuccess);
          this.close.emit({ shouldReload: true });
        }, error: this.alertService.showApiError
      });
  }

  configureGridColumn() {
    const operationColumn = new TemplateColumn({
      itemTemplate: this.operationTemplate,
      Width: '140px',
      headerTemplate: this.headerTemplate,
      TextAlign: TextAlign.Center
    });

    this.backupColumns = [
      new GridColumn({ HeaderText: 'Backup Set Name', Field: 'Name', IsSortable: false }),
      new DateColumn({ HeaderText: 'Date', Field: 'DateAdded', IsSortable: true, TextAlign: TextAlign.Left }),
    ];

    if (this.authenticationService.userHasPermission([{ Name: this.permission.Name, Level: this.permission.EditLevel }], 'any')) {
      this.backupColumns.push(operationColumn);
    }
  }

  createBackup() {
    const modalRef = this.modalService.getModalWrapper(ScreenBackupEditComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      initialState: {
      }
    });
    modal.close.subscribe(res => {
      if (res?.shouldReload) {
        this.getBackups();
      }
    });
  }

  onCancel() {
    this.close.emit({ shouldReload: false });
  }
}
