<div class="order-section__products__block__menu__options btn btn-primary pos-button white relative" (click)="screenSetup($event)">
  <span>
    <pos-icon [icon]="icons.tv"></pos-icon>
    {{screenWidth < 1030 && menuExplorer? '' : 'Screen setup' }} </span>
      <ul class="custom-order order-section__products__block__menu__options--mobile" id="screenSetting"
        [ngClass]="{'custom-orderUserPref': appStateConfigurations && appStateConfigurations.UserPreferenceInvoiceLocation == 'left'}">
        <li (click)="editScreen(true)"
          permissions='[{"Name":"{{permission.UIScreens}}", "Level":"{{permission.editLevel}}"}]'>
          <a class="bold-normal">
            <div class="order-menu-action-icon">
              <pos-icon class="black" [icon]="icons.screenSetupPlus"></pos-icon>
            </div>
            New Screen
          </a>
        </li>
        <li (click)="editScreen()"
          permissions='[{"Name":"{{permission.UIScreens}}", "Level":"{{permission.editLevel}}"}]'>
          <a class="bold-normal">
            <div class="order-menu-action-icon">
              <pos-icon class="black" [icon]="icons.editScreenSetup"></pos-icon>
            </div>
            Edit Screen
          </a>
        </li>
        <li (click)="applyColorToButton()">
          <a class="bold-normal">
            <div class="order-menu-action-icon">
              <pos-icon class="black" [icon]="icons.paintBrush"></pos-icon>
            </div>
            Apply Colors to buttons
          </a>
        </li>
        <li (click)="addNewButton()"
          permissions='[{"Name":"{{permission.UIButtons}}", "Level":"{{permission.editLevel}}"}]'>
          <a class="bold-normal">
            <div class="order-menu-action-icon">
              <pos-icon class="black" [icon]="icons.screenSetupPlus"></pos-icon>
            </div>
            Add New Button
          </a>
        </li>
        <li (click)="addExistingButton()">
          <a class="bold-normal">
            <div class="order-menu-action-icon">
              <pos-icon class="black" [icon]="icons.plusSquare"></pos-icon>
            </div>
            Add Existing Button
          </a>
        </li>
        <li (click)="copyButton()">
          <a class="bold-normal">
            <div class="order-menu-action-icon">
              <pos-icon class="black" [icon]="icons.copy"></pos-icon>
            </div>
            Copy Buttons From Other Screen
          </a>
        </li>
        <li (click)="deleteScreen()"
          permissions='[{"Name":"{{permission.UIScreens}}", "Level":"{{permission.editLevel}}"}]'>
          <a class="bold-normal">
            <div class="order-menu-action-icon">
              <pos-icon class="black" [icon]="icons.times"></pos-icon>
            </div>
            Delete Screen
          </a>
        </li>
        <li>
          <a class="bold-normal">
            <div class="order-menu-action-icon">
              <pos-icon class="black" [icon]="icons.objectUngroup"></pos-icon>
            </div>
            Select All Buttons
          </a>
        </li>
        <li (click)="reArrangeButton()">
          <a class="bold-normal">
            <div class="order-menu-action-icon">
              <pos-icon class="black" [icon]="icons.arrowsAlt"></pos-icon>
            </div>
            Rearrange Buttons
          </a>
        </li>
        <li (click)="screenBackups()"
          permissions='[{"Name":"{{permission.ManageScreenBackups}}", "Level":"{{permission.readOnlyLevel}}"}]'>
          <a class="bold-normal">
            <div class="order-menu-action-icon">
              <pos-icon class="black" [icon]="icons.save"></pos-icon>
            </div>
            Screen Backups
          </a>
        </li>
        <li (click)="embedScreen()">
          <a class="bold-normal">
            <div class="order-menu-action-icon">
              <pos-icon class="black" [icon]="icons.embedScreen"></pos-icon>
            </div>
            Embed Screen
          </a>
        </li>
      </ul>
</div>
