import { Component, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseFormComponent } from 'src/app/shared/components';
import { FormUtilityService } from 'src/app/shared/services/form-utility.service';

@Component({
  selector: 'pos-inventory-product-edit-wrap',
  templateUrl: './inventory-product-edit-wrap.component.html',
})
export class InventoryProductEditWrapComponent extends BaseFormComponent implements OnInit {
  form: NgForm;
  get getForm(): NgForm {
    return this.form;
  }
  inventoryTransParam: any;
  @Input() isScroll = false;
  @Input() inventoryProductName = '';
  @Input() inventoryProductId: number = null;
  initialize = false;
  constructor(private router: Router, private route: ActivatedRoute,formUtilityService: FormUtilityService) {
    super(formUtilityService);
    const navigation = this.router.getCurrentNavigation();
    this.inventoryProductId = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
    this.isScroll = (navigation?.extras?.state && navigation?.extras?.state.isScroll) ?? false;
    this.inventoryProductName = (navigation?.extras?.state && navigation?.extras?.state.inventoryProductName) ?? '';
    this.initialize = false;
  }

  ngOnInit() {
    this.initialize = true;
  }

  onClose($event: any) {
    this.router.navigate(['inventory-products'], { relativeTo: this.route.parent});
  }

  onFormChange($event: NgForm) {
    this.form = $event;
    this.setInitialValue();
  }
}
