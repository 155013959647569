import { Component, Input } from '@angular/core';
import { KioskFooter, KioskScreenChoice } from '../../interfaces';
import { DomainConstants, RuntimeConstants } from 'src/app/shared';
import { KioskNavigationService } from '../../services';
import { debounce } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'pos-kiosk-footer',
  templateUrl: './kiosk-footer.component.html',
  styleUrls: ['./kiosk-footer.component.scss']
})
export class KioskFooterComponent {
  @Input() footer: KioskFooter;
  @Input() buttons: Array<KioskScreenChoice> = [];
  imageRootPath = `${RuntimeConstants.IMAGE_BASE_PATH}/order-kiosk/`;
  searchText = '';
  isShowAddProduct = false;
  itemsCount = 0;
  orderTotal = 0;
  ignoreChoiceBehaviors: Array<string> = [];
  isDesignMode = false;
  allergenNotes: string = "We'll carefully attempt to exclude products that you want to avoid. With your well-being in mind, please be aware that while we strive for the highest standards of accuracy, safety, and cleanliness, our kitchen prepares a wide variety of products. <b> This means there's a possibility of cross-contamination, as they are present in other parts of our establishment. </b>";
  showAllergenNotes: boolean = false;
  isNotAllergenTemplate: boolean;
  isAICreatedTemplate: boolean;

  constructor(private kioskNavigationService: KioskNavigationService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.searchText = this.kioskNavigationService.searchText;
    this.itemsCount = kioskNavigationService.orderDetails?.OrderItems.filter(x => x.OrderItemType == DomainConstants.OrderItemTypes.Product)?.length ?? 0;
    this.orderTotal = kioskNavigationService.orderDetails?.GrandTotal ?? 0;
    this.isDesignMode = route.snapshot.params.id;
  }

  ngOnInit() {
    this.ignoreChoiceBehaviors = [DomainConstants.KioskButtonBehaviors.UncheckAllButtons.Value, DomainConstants.KioskButtonBehaviors.AddComment.Value, DomainConstants.KioskButtonBehaviors.AddCommentToAllProducts.Value]
    this.setShowAddProduct();
    this.isAICreatedTemplate = this.kioskNavigationService?.orderNavigation?.TemplateType == DomainConstants.KioskScreenTemplateTypes.AIShakeCreatedTemplate;
    this.isNotAllergenTemplate = this.checkIfTemplateIsNotOfAllergenType();
    this.showAllergenNotes = !!this.kioskNavigationService.dietaryWarnings?.length && this.isNotAllergenTemplate;
  }

  checkIfTemplateIsNotOfAllergenType() {
    return this.kioskNavigationService?.orderNavigation?.TemplateType != DomainConstants.KioskScreenTemplateTypes.AllergensVerifyScreenLayout
      && this.kioskNavigationService?.orderNavigation?.TemplateType != DomainConstants.KioskScreenTemplateTypes.AllergensSelectionScreenLayout
  }

  // TODO: remove code
  searchButton = debounce(() => {
    if (this.searchText.length > 2) {
      this.kioskNavigationService.searchText = this.searchText;
      this.kioskNavigationService.setIsSearched();
    } else if (!this.searchText && this.kioskNavigationService.searchText) {
      this.kioskNavigationService.searchText = null;
      this.kioskNavigationService.setIsSearched();
    }
  }, 500);

  private setShowAddProduct() {
    const screenButtons = this.buttons?.length > 0 ? this.buttons : this.kioskNavigationService?.versionedVal?.Obj?.ScreenButtons?.Buttons;
    this.isShowAddProduct = screenButtons.length && screenButtons.every(x => x.Button == null || x.Button.ButtonType == DomainConstants.KioskChoiceType.Toggle
      || x.Button.ButtonType == DomainConstants.KioskChoiceType.Placeholder
      || x.Button.ChoiceBehaviors?.some(x => this.ignoreChoiceBehaviors.includes(x.Name)
      ));
  }

  back() {
    if (!this.isDesignMode)
      this.kioskNavigationService.navigateBackward();
  }

  addItemToOrder() {
    this.kioskNavigationService.onContinuePress();
  }

  checkout() {
    if (this.kioskNavigationService.orderDetails)
      this.router.navigate(["checkout"], { relativeTo: this.route.parent });
  }
}
