<pos-edit-wrapper heading='Apple Pay' (close)="onClose()">
    <form #applePayConfigurationForm="ngForm" (ngSubmit)="next()">
        <div class="col-12 pos-modal-body screen-scroll pt-pix-15" style="height: 390px;">
            <pos-row>
                <pos-column>
                    <p-toast></p-toast>
                    <p-steps [model]="items" [(activeIndex)]="activeIndex"></p-steps>
                </pos-column>

                <pos-column class="pt-pix-10 pe-0" [hidden]="activeIndex != 0" #merchantDetailsFormGroup="ngModelGroup" ngModelGroup="merchantDetails">
                    <pos-row class="ps-4">
                        <pos-column>
                            <ul>
                                <li class="list-style-type-disc pt-1">
                                    <span>Sign up for <em><a target="_blank" href="https://developer.apple.com/">Apple Developer</a></em></span></li>
                                <li class="list-style-type-disc pt-1">
                                    <span>In <em><a rel="noopener" target="_blank" href="https://developer.apple.com/account/resources">Certificates, Identifiers & Profiles</a></em>, click Identifiers in the sidebar, then click the add button (+) on the top left.</span>
                                </li>
                                <li class="list-style-type-disc pt-1">
                                    <span>Select Merchant IDs, then click Continue.</span>
                                </li>
                                <li class="list-style-type-disc pt-1">
                                    <span>Enter the merchant description and identifier name, then click Continue.</span>
                                </li>
                                <li class="list-style-type-disc pt-1">
                                    <span>Review the settings, then click Register.</span>
                                </li>
                                <li class="list-style-type-disc pt-1">
                                    <span>Enter Merchant Id and name here, then click Next.</span>
                                </li>
                            </ul>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Merchant Id">
                                <input posInput type="text" class="form-control" name="merchantId" [(ngModel)]="configuration.MerchantId" [required]="true">
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Merchant Name">
                                <input posInput type="text" class="form-control" name="MerchantName" [(ngModel)]="configuration.MerchantName" [required]="true">
                            </pos-form-field>
                        </pos-column>
                    </pos-row>
                </pos-column>
                
                <pos-column class="pe-0 pt-pix-10" [hidden]="activeIndex != 1">
                    <pos-row class="ps-4">
                        <pos-column>
                            <ul>
                                <li class="list-style-type-disc pt-1">
                                    <span>Log in to the <em><a rel="noopener" target="_blank" href="https://account.authorize.net/">Authorize.net Merchant Interface</a></em>.</span>
                                </li>

                                <li class="list-style-type-disc pt-1">
                                    <span>Navigate to <em>Account > Digital Payments Solutions > Apple Pay</em>.</span>
                                </li>

                                <li class="list-style-type-disc pt-1">
                                    <span>Enter your Apple Merchant ID in the Apple Merchant ID field.</span>
                                </li>

                                <li class="list-style-type-disc pt-1">
                                    <span>Click Generate Apple CSR.</span>
                                </li>
                            </ul>
                        </pos-column>
                    </pos-row>
                </pos-column>

                <pos-column class="pt-pix-10 pe-0" [hidden]="activeIndex != 2">
                    <pos-row class="ps-4">
                        <pos-column>
                            <ul>
                                <li class="list-style-type-disc pt-1">
                                    <span>A payment processing certificate is associated with your merchant identifier and used to encrypt payment information.
                                        The payment processing certificate expires every 25 months. If the certificate is revoked, you can recreate it.
                                    </span>
                                </li>

                                <li class="list-style-type-disc pt-1">
                                    <span>On <em><a rel="noopener" target="_blank" href="https://developer.apple.com/account/resources">Certificates, Identifiers & Profiles</a></em>, click Identifiers in the sidebar.</span>
                                </li>

                                <li class="list-style-type-disc pt-1">
                                    <span>Under Identifiers, select Merchant IDs using the filter on the top right.</span>
                                </li>

                                <li class="list-style-type-disc pt-1">
                                    <span>On the right, select your merchant identifier.</span>
                                </li>

                                <li class="pt-1">
                                    <span><b>Note:</b> <em> If a banner appears at the top of the page saying that you need to accept an agreement, click the Review Agreement button and follow the instructions before continuing.</em></span>
                                </li>

                                <li class="list-style-type-disc pt-1">
                                    <span>Under Apple Pay Payment Processing Certificate, click Create Certificate.</span>
                                </li>
                                <li class="list-style-type-disc pt-1">
                                    <span>Choose CSR file that was generated in previous step, and click Continue</span>
                                </li>
                                <li class="list-style-type-disc pt-1">
                                    <span>After Completing this step click next here.</span>
                                </li>
                            </ul>
                        </pos-column>
                    </pos-row>
                </pos-column>

                <pos-column class="pt-pix-10" [hidden]="activeIndex != 3" #verifyDomainFormGroup="ngModelGroup" ngModelGroup="verifyDomain">
                    <pos-row class="ps-4">
                        <pos-column>
                            <ul>
                                <li class="list-style-type-disc pt-1">
                                    <span>Go to <em><a rel="noopener" target="_blank" href="https://developer.apple.com/account/resources">Certificates, Identifiers & Profiles</a></em>and click on Identifiers in the sidebar. Then select Merchant IDs using the filter on the top right. </span>
                                </li>
                                <li class="list-style-type-disc pt-1">
                                    <span>Select your merchant identifier from the Identifiers list. </span>
                                </li>
                                <li class="list-style-type-disc pt-1">
                                    <span>Under <b>Merchant Domains</b>, click Add Domain. Enter the fully qualified domain name (online order URL without https://), then click Save. Click Download.</span>
                                </li>
                                <li class="list-style-type-disc pt-1">
                                    <span>Upload the downloaded file here along with the domain name you entered in the Apple Developer page. </span>
                                </li>
                            </ul>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Domain Name">
                                <input posInput type="text" class="form-control" name="websiteDomain" [(ngModel)]="configuration.WebsiteDomain" [required]="true">
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-row>
                                <pos-column>
                                    <pos-form-field label="Merchant Domain Association File" inputStyle="col-12">
                                        <pos-row colXs="6">
                                            <pos-column>
                                                <input type="file" class="form-control" ngModel accept=".txt" name="domainVerificationFile" (change)="handleFileInput($event.target.files, true)">
                                            </pos-column>

                                            <pos-column>
                                                <button type="button" class="btn btn-primary btn-save pos-button" (click)="uploadDomainVerificationCertificate()">Upload</button>
                                            </pos-column>
                                        </pos-row>
                                    </pos-form-field>
                                </pos-column>
                            </pos-row>
                        </pos-column>

                        <pos-column>
                            <ul>
                                <li class="pt-1">
                                    <span><b>Note:</b> file name must be <b>apple-developer-merchantid-domain-association.txt</b></span>
                                </li>
                                <li class="list-style-type-disc pt-1">
                                    <span>Click the <b>Upload</b> button. This will upload the file to the place where Apple expects it. </span>
                                </li>
                                <li class="list-style-type-disc pt-1">
                                    <span>After a successful response, click  <b>Next</b>.</span>
                                </li>
                            </ul>
                        </pos-column>
                    </pos-row>
                </pos-column>

                <pos-column class="pt-pix-10 " [hidden]="activeIndex != 4">
                    <pos-row class="ps-4">
                        <pos-column>
                            <ul>
                                <li class="list-style-type-disc pt-1">
                                    <span>Now to complete verification, click verify beside domain you just added on apple developer page and then Done.</span>
                                </li>
                            </ul>
                        </pos-column>
                    </pos-row>
                </pos-column>

                <pos-column class="pt-pix-10" [hidden]="activeIndex != 5">
                    <pos-row class="ps-4">
                        <pos-column>
                            <ul>
                                <li class="list-style-type-disc pt-1">
                                    <span>In <em><a rel="noopener" target="_blank" href="https://developer.apple.com/account/resources">Certificates, Identifiers & Profiles</a></em>, click Identifiers in the sidebar, then select Merchant IDs from the pop-up menu on the top right.</span>
                                </li>
                                <li class="list-style-type-disc pt-1">
                                    <span>On the right, select your merchant identifier.</span>
                                </li>
                                <li class="list-style-type-disc pt-1">
                                    <span>Under Apple Pay Merchant Identity Certificate, click Create Certificate.</span>
                                </li>
                                <li class="list-style-type-disc pt-1">
                                    <span><a rel="noopener" target="_blank" href="https://developer.apple.com/help/account/create-certificates/create-a-certificate-signing-request"><em>Create a certificate signing request</em></a> on your Mac, then click Continue.</span>
                                </li>
                                <li class="list-style-type-disc pt-1">
                                    <span>Click Choose File. In the dialog that appears, select the certificate request file (a file with a .certSigningRequest file extension), then click Choose.</span>
                                </li>
                                <li class="list-style-type-disc pt-1">
                                    <span>Click Continue, then Click Download.</span>
                                </li>
                            </ul>
                        </pos-column>
                    </pos-row>
                </pos-column>
                
                <pos-column class="pt-pix-10" [hidden]="activeIndex != 6" #merchantIdentityCertificateFormGroup="ngModelGroup" ngModelGroup="merchantIdentityCertificate">
                    <pos-row class="ps-4">
                        
                        <pos-column>
                            <ul>
                                <li class="list-style-type-disc pt-1">
                                    <span>Double-click the downloaded CER file to add it to Keychain Access.</span>
                                </li>
                                <li class="list-style-type-disc pt-1">
                                    <span>Select both the CER file and the private key and choose Export 2 Items.</span>
                                </li>
                                <li class="list-style-type-disc pt-1">
                                    <span>Save the exported file as a .p12 file.</span>
                                </li>
                                <li class="list-style-type-disc pt-1">
                                    <span>Add generated .p12 and password here.</span>
                                </li>
                            </ul>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Merchant Identity Certificate">
                                <input type="file" class="form-control" ngModel accept=".pfx,.p12" name="merchantIdentityCertificate" (change)="handleFileInput($event.target.files)">
                                <span *ngIf="configuration.MerchantIdentityCertificateName">{{configuration.MerchantIdentityCertificateName}}</span>
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Certificate Password">
                                <input posInput type="password" class="form-control" name="certificateFile" [(ngModel)]="configuration.CertificatePassword" [required]="true">
                            </pos-form-field>
                        </pos-column>
                    </pos-row>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="button" class="btn btn-lg btn-secondary pos-buttton btn-cancel-modal ms-pix-10 float-start" *ngIf="activeIndex > 0" 
                (click)="activeIndex = activeIndex - 1">Back</button>

            <button type="button" class="btn btn-lg btn-primary btn-save pos-button" (click)="next()" *ngIf="this.activeIndex != lastIndex"
                [disabled]="(this.activeIndex == 0 && merchantDetailsFormGroup.invalid)
                    || (this.activeIndex == 3 && verifyDomainFormGroup.invalid)"    
            >
                Next
            </button>
            <button type="submit" class="btn btn-lg btn-primary btn-save pos-button" *ngIf="this.activeIndex == lastIndex"
                [disabled]="this.activeIndex == lastIndex && merchantIdentityCertificateFormGroup.invalid"
            >
                Submit
            </button>
            <button class="btn btn-lg btn-secondary  btn-cancel-modal pos-button" type="button" (click)="onClose()">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
