<pos-list-wrapper [headingIcon]="icons.equipmentProperties" heading="Equipment Properties" (close)="close()">
    <div class="row">
        <div class="col-12">
            <ng-table id="equipmentProperty" [sort]="true" [columns]="equipmentPropertyColumns"
                [data]="equipmentProperties" [exportFilename]="'equipment-properties'">
            </ng-table>
        </div>
    </div>
</pos-list-wrapper>
<pos-print-table [hidden]="true"></pos-print-table>
