<div class="row mx-0">
<div class="col-md-4 col-sm-6 col-12 ps-0 pe-pix-10" [hidden]="!seat.SubAccountOrdinal || seat.SubAccountOrdinal == '0'"
  *ngFor="let seat of seatsDetails">
  <div class="col-12 seat-details" (click)="selectSeat(seat, true)"
    tooltip="{{isPartialPayment ? seatSelectionPrevent : seat.Status ? seatPaymentCompleted : ''}}"
    placement="bottom" data-container="body" [ngClass]="{'selected-item': seat.IsSelected, 'div-disabled': isPartialPayment, 'bg-green-light-x': seat.PaidAmount >= seat.SeatTotal}">
    <div class="bold text-ellipsis" *ngIf="seat.SubAccountOrdinal != 99">  {{seat.SubaccountName ? seat.SubaccountName : (subAccountTerm + " " + seat.SubAccountOrdinal)}} </div>
    <div class="bold" *ngIf="seat.SubAccountOrdinal == 99"> To Go</div>
  </div>
</div>
<!-- title="{{isPartialPayment ? seatSelectionPrevent : seat.value.Status ? seatPaymentCompleted : ''}}" -->
<div class="col-12 seat-details" [ngClass]="{'div-disabled': isPartialPayment}"
  *ngIf="seatsDetails && seatsDetails.length > 1" (click)="separateAll()">
  <!-- [disabled]="isPartialPayment" -->
  <div class="bold"> Separate All</div>
</div>
</div>
