import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RuntimeConstants } from 'src/app/shared/constants';
import { ActiveInventory } from '../interfaces/active-inventory';

@Injectable({
  providedIn: 'root'
})
export class QuickSearchService {

  constructor(private httpService: HttpClient) { }

  getAllActiveInventoryProducts(filter): Observable<Array<ActiveInventory>> {
    return this.httpService.get<Array<ActiveInventory>>(`${RuntimeConstants.API_PATH}salesproducts/` + filter + `/GetAllActiveInventoryProducts`);
  }
}
