import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { DomainConstants } from '../constants/domain-constants';
import { PhoneMaskPipe } from '../pipes';

@Injectable({
  providedIn: 'root'
})
export class StringUtilityService {

  constructor(private phoneMaskPipe: PhoneMaskPipe) { }

  maskPhoneString(phoneString: string) {
    const splitPhoneString = phoneString.split(DomainConstants.PhoneText);
    if (splitPhoneString[1]) {
      const phoneNumber = splitPhoneString[1].trim();
      return DomainConstants.PhoneText + this.phoneMaskPipe.transform(phoneNumber);
    } else {
      return phoneString;
    }
  }

  displayName(str): string {
    const copyStr = cloneDeep(str);
    return copyStr?.replace(/([a-z])([A-Z])/g, '$1 $2');
  }
}
