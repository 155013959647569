<pos-edit-wrapper heading="Products" (close)="onCancel()">
    <form #productOrdinalForm="ngForm" (ngSubmit)="onSave(productOrdinalForm.valid)" autocomplete="off">
    
    <div class="col-12 pos-modal-body content-scroll">
        <div class="sort-button">
            <button type="button" class="btn btn-secondary" (click)="sortAlphabetically()">
                <pos-icon [icon]="icons.sortAlphaDown"></pos-icon>
            </button>
        </div>
        <ng-table [isReorderableTable]="true" [ReorderableColumnRow]="'row'" [data]="productTableItems"
        [columns]="columns" (onRowReorder)="gridRowReorder($event)" [paginator]="false"></ng-table>
        <ng-template let-data="rowData" let-index="rowIndex" #ShowProductPriceTemp>
            <p-checkbox posInput name="ShowProductPrice_{{data.Id}}" [(ngModel)]="data.ShowProductPrice" binary="true">
            </p-checkbox>
        </ng-template>
    </div>
    <div class="col-12 action-button-container popup-footer">
        <button type="submit" value="Save" class="btn btn-primary btn-lg white btn-save" scrollup>Save</button>
        <button type="button" class="btn btn-secondary btn-lg btn-cancel-modal" value="Cancel" (click)="onCancel()"
            scrollup>Cancel</button>
    </div>
</form>
</pos-edit-wrapper>
