<pos-edit-wrapper (close)="closeModal()" heading="{{selectedButtonBehaviorType.Text}}">
    <form #commentForm="ngForm" autocomplete="off" (submit)='saveBehavior(commentForm.valid)'>
        <div class="col-12 pos-modal-body screen-scroll">
            <pos-row>
                <pos-column xs="12">
                    <pos-form-field inputStyle="col-12" label="Comment" [validationMessages]="{required: 'Please enter comment.'}">
                        <textarea posInput type="text" name="commentInput" #commentInput="ngModel" class="form-control"
                            [(ngModel)]="comment" [required]="!isAddCommentToOrder"></textarea>
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save</button>
            <button type="button" (click)="closeModal()"
                class="btn-secondary btn-lg btn btn-cancel-modal pos-button">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
