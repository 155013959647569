import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BaseFormComponent, SpinnerService, timeUserClock, times } from 'src/app/shared/components';
import { AlertsService, ApplicationStateService, ExtensionValidationService, FormUtilityService } from 'src/app/shared/services';
import { finalize } from 'rxjs/operators';
import { FieldInfoMessages, Messages, RuntimeConstants } from 'src/app/shared/constants';
import { NgForm } from '@angular/forms';
import { SettingParam } from 'src/app/shared/interface';
import { IdleTimeoutService } from 'src/app/order-kiosk/services';
declare let $: any;

@Component({
  selector: 'pos-idle-timeout',
  templateUrl: './idle-timeout.component.html',
  styleUrls: ['./idle-timeout.component.scss']
})
export class IdleTimeoutComponent extends BaseFormComponent implements OnInit {
  

  get getForm(): NgForm {
    return this.form
  }
  @ViewChild('configForm') form: NgForm;

  parameters: SettingParam;
  fieldInfoMessages = FieldInfoMessages;
  imageBasePath = RuntimeConstants.IMAGE_BASE_PATH;
  updatedLogoFileName = '';
  updatedFileName = '';
  idleTimeoutImage = null;
  selectedFiles: File;
  idleTimeoutImagePath = '';
  showIcon = false;
  receiptLogoPath = '';
  icons = {
    timeUserClock,
    times
  };

  
  constructor(
    private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private applicationStateService: ApplicationStateService,
    private router: Router,
    formUtilityService: FormUtilityService,
    private extensionValidation: ExtensionValidationService,
    private idleTimeoutService: IdleTimeoutService) {
    super(formUtilityService);
    this.parameters = applicationStateService.newSettingParams();
  }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.spinnerService.show();
    this.idleTimeoutService.getIdleTimeoutConfig()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          this.parameters = res;
          this.idleTimeoutImage = `${this.imageBasePath}/order-kiosk/default-screens/${this.parameters.OrderKioskIdleTimeoutImage}`;
          this.showIcon = !(this.parameters.OrderKioskIdleTimeoutImage === '' || this.parameters.OrderKioskIdleTimeoutImage == null);
        }, error: this.alertsService.showApiError
      });
  }

  saveConfig(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.spinnerService.show();
    this.idleTimeoutService.save(this.parameters, this.idleTimeoutImage)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          this.alertsService.renderSuccessMessage(Messages.KioskIdleTimeoutConfigSave);
        }, error: this.alertsService.showApiError
      });
  }

  public handleFileInput(files: FileList) {
    this.idleTimeoutImage = files.item(0);
    const fileToUpload = this.idleTimeoutImage ? this.idleTimeoutImage.name : '';
    if (this.extensionValidation.isInvalidExtension(fileToUpload, ['.jpg', '.bmp', '.png', '.gif'])) {
      if (fileToUpload) {
        this.alertsService.renderErrorMessage(Messages.InvalidReceiptLogoFileType);
      }
      this.removeIcon();
      return;
    }
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
         $('#cancelOrderImage').attr('src', reader.result);
      };
      reader.readAsDataURL(files[0]);
      this.parameters.OrderKioskIdleTimeoutImage = this.idleTimeoutImage ? this.idleTimeoutImage.name : '';
      this.showIcon = true;
    }
  }

  public removeIcon(): void {
    this.parameters.OrderKioskIdleTimeoutImage = '';
    $('#cancelOrderImage').val('');
    $('#image').val(null);
    this.idleTimeoutImage = null;
    this.showIcon = false;
  }

  cancelEditing() {
    this.router.navigate(['manage/order-kiosk']);
  }
}
