import { Component, OnInit, EventEmitter } from "@angular/core";
import { NumpadOptions } from "src/app/shared/interface";
import { ICloseable } from "../../modal";

@Component({
    selector: 'pos-numpad-wrapper',
    templateUrl: './numpad-wrapper.component.html'
})
export class NumpadWrapperComponent implements OnInit, ICloseable {

    numpadOption: NumpadOptions;
    close: EventEmitter<any> = new EventEmitter();
    value: any;
    numpadId: string;
    numpadTitle: string;
    ngOnInit() {

    }

    onSubmit = (res) => {
        this.close.emit(res);
    }

    onCancel = (res) => {
        this.close.emit({});
    }
}
