<div class="grid-height" *ngIf="digitalMenu" [ngStyle]="{'height': digitalMenu.Orientation ? digitalMenu?.Width + 'px' :digitalMenu?.Height + 'px' ,
 'width': digitalMenu.Orientation ? digitalMenu?.Height + 'px' : digitalMenu?.Width + 'px'}">
    <gridster [options]="options" [ngStyle]="{'--menu-background-color': menuDisplayConfig?.BackgroundColor}">
        <div class="display-contents" *ngFor="let item of menuWidgets; let index = index">
            <gridster-item [item]="item.GridItem" class="widget-border-{{item.Id}} widget-background-{{item.Id}}"
                [ngClass]="{'display-none-imp': item.WidgetType == groupWidgetName}">
                <pos-menu-widget [widgetType]="item.WidgetType" [widgetId]="item.Id" [previewMode]="true"
                    [widgetIndex]="index" (widgetDelete)="deleteWidget($event)" (reload)="reload($event)">
                </pos-menu-widget>
            </gridster-item>
        </div>
    </gridster>
</div>
