import { Component, OnInit, ViewChild } from '@angular/core';
import { SpinnerService, AlertsService, Messages, HardwareModel, DeviceMappingService, DomainConstants } from 'src/app/shared';
import { finalize } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import * as _ from 'lodash';
import { PosHardwareType, TerminalHardwareDevice } from 'src/app/configurator/device-mapping/interface';
import { ActivatedRoute, Router } from '@angular/router';
import { find, forEach, remove } from 'lodash';
import { terminalTv } from 'src/app/shared/components/icon/icons';
import { BaseFormComponent } from 'src/app/shared/components/base-form.component';
import { NgForm } from '@angular/forms';
import { FormUtilityService } from 'src/app/shared/services/form-utility.service';
import { PostSavedConfigurationService } from 'src/app/configurator/post-saved-configuration.service';

@Component({
  selector: 'pos-terminal-device-mapping',
  templateUrl: './terminal-device-mapping.component.html',
  styleUrls: ['./terminal-device-mapping.component.scss']
})
export class TerminalDeviceMappingComponent extends BaseFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.deviceMappingForm;
  }

  @ViewChild('deviceMappingForm') deviceMappingForm: NgForm;
  id: number;
  terminalName: string;
  templatesTransParam: any;
  posHardwareTypes: Array<PosHardwareType> = [];
  hardwareTypes: Array<Array<PosHardwareType>> = [];
  hardwareDevices: Array<HardwareModel> = [];
  hardwareDeviceDetails: Array<TerminalHardwareDevice> = [];
  deviceId = null;
  selectedField = null;
  cameraDevices: Array<TerminalHardwareDevice> = [];
  cameraDeviceIds: Array<number> = [];
  cameraHardWareType: string;
  icons = {
    terminalTv
  }
  terminalTypeId: number;

  constructor(protected spinnerService: SpinnerService,
    protected alertService: AlertsService,
    protected router: Router,
    private route: ActivatedRoute,
    protected deviceMappingService: DeviceMappingService,
    private postSavedConfigurationService: PostSavedConfigurationService,
    formUtilityService: FormUtilityService) {
    super(formUtilityService);
    const navigation = router.getCurrentNavigation();
    this.id = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
    this.terminalName = (navigation?.extras?.state && navigation?.extras?.state.name) ? navigation?.extras?.state.name : '';
    this.terminalTypeId = (navigation?.extras?.state && navigation?.extras?.state.terminalTypeId) ? navigation?.extras?.state.terminalTypeId : null;
    this.cameraHardWareType = DomainConstants.HardwareTypes.Camera;
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    const observable: Array<Observable<any>> = [];
    this.spinnerService.show();
    observable.push(this.deviceMappingService.getTerminalHardwareDevices(this.id));
    observable.push(this.deviceMappingService.getHardwares());
    if (this.terminalTypeId) {
      observable.push(this.deviceMappingService.getAvailablePosHardwareTypesForTerminalType(this.terminalTypeId));
    }
    forkJoin(observable)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: ([hardwareDeviceDetails, hardwareDevices, posHardwareTypes]:
          [Array<TerminalHardwareDevice>, Array<HardwareModel>, Array<PosHardwareType>]) => {
          this.posHardwareTypes = posHardwareTypes;
          this.hardwareDeviceDetails = hardwareDeviceDetails ? hardwareDeviceDetails : [];
          this.hardwareDevices = hardwareDevices;
          this.cameraDevices = this.hardwareDeviceDetails.filter(x => x.DeviceType === this.cameraHardWareType);
          this.cameraDeviceIds = this.cameraDevices.map(x => x.DeviceId);
          if (this.posHardwareTypes?.length) {
            this.setHardware();
            forEach(DomainConstants.HardwareGroup, (group) => {
              const hardwareGroupArray = this.posHardwareTypes.filter(x => x.HardwareGroup === group);
              if (hardwareGroupArray.length) {
                this.hardwareTypes.push(hardwareGroupArray);
              }
            });
          }
        },
        error: this.alertService.showApiError
      });
  }

  setHardware() {
    this.posHardwareTypes.forEach((data) => {
      data.ImagePath = DomainConstants.HardwareTypeImages.find(x => x.Type === data.Type)?.ImagePath;
      data.HardwareGroup = DomainConstants.HardwareTypeImages.find(x => x.Type === data.Type)?.Group;
      const deviceDetail = find(this.hardwareDeviceDetails, (detail) => detail.DeviceType === data.Type);
      data.DeviceId = deviceDetail ? deviceDetail.DeviceId : null;
      data.hardwareDevices = this.hardwareDevices.filter(x => x.Type === data.Type);
    });
  }

  saveDeviceMapping(isValid: boolean) {
    if (!isValid) {
      return;
    }
    const hardwareDevices = [];
    _.forEach(this.posHardwareTypes, (hardwareType: PosHardwareType) => {
      if (hardwareType.HardwareType === this.cameraHardWareType) {
        return;
      }
      const deviceDetail = _.find(this.hardwareDeviceDetails, (detail) => detail.DeviceType === hardwareType.Type);
      const hardwareDevice = {
        Id: deviceDetail ? deviceDetail.Id : 0,
        DeviceId: hardwareType.DeviceId ? hardwareType.DeviceId : 0,
        TerminalId: this.id,
        DeviceType: hardwareType.HardwareType
      };
      if (hardwareDevice.Id || hardwareDevice.DeviceId) {
        hardwareDevices.push(hardwareDevice);
      }
    });
    if (this.cameraDevices || this.cameraDeviceIds) {
      this.handleCameraDevices(hardwareDevices);
    }
    if (hardwareDevices.length > 0) {
      this.spinnerService.show();
      this.deviceMappingService.saveHardwareDevicesMapping(hardwareDevices)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (response) => {
            this.alertService.renderSuccessMessage(Messages.DeviceMappingSaveSuccess);
            this.postSavedConfigurationService.showMessage();
            this.router.navigate(['manage/system-configuration/terminal']);
          }, error: this.alertService.showApiError
        });
    } else {
      this.router.navigate(['manage/system-configuration/terminal']);
    }
  }

  handleCameraDevices(hardwareDevices: any[]) {
    this.cameraDevices.forEach(cameraDevice => {
      const unavailableDevice = find(this.cameraDeviceIds, (id) => {
        return id !== cameraDevice.Id;
      });
      if (unavailableDevice || !this.cameraDeviceIds?.length) {
        hardwareDevices.push({
          Id: cameraDevice.Id,
          DeviceId: 0,
          TerminalId: this.id
        });
      } else {
        remove(this.cameraDeviceIds, (id) => {
          return id === cameraDevice.Id;
        });
      }
    });

    if (this.cameraDeviceIds) {
      this.cameraDeviceIds.forEach(id => {
        hardwareDevices.push({
          Id: 0,
          DeviceId: id,
          TerminalId: this.id
        });
      });
    }
  }
}
