import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertsService, BaseCrudComponent, DomainConstants, FormUtilityService, Messages, SpinnerService } from 'src/app/shared';
import { DataSourceSubscribe } from '../../interface/data-source-subscribe';
import { DataSourceSubscribeService } from '../../service/data-source-subscribe.service';
import { editSolid, list, mapPin, deleteWhite, rssSquare } from './../../../../shared/components/icon/icons';
import { CronOptions } from '@tarktech/ngx-cron-editor';
import { FieldInfoMessages } from 'src/app/shared/constants/field-info-messages';
import { DataSourceParameterService } from '../../service/data-source-parameter.service';
import { DataSourceService } from '../../service/data-source.service';
import { forkJoin } from 'rxjs';
import { filter, find, first, forEach, remove } from 'lodash';
import { GridColumn } from '@tarktech/tark-ng-utils';
import { ExportConnectionService } from '../../service/export-connection.service';
import { DataSource, DataSourceParameter, ExportConnection } from '../../interface';
import { DataSubscriptionParameter } from '../../interface/data-subscription-parameter';
import { finalize } from 'rxjs/operators';
import { ParameterModel } from '../../interface/parameter-model';
import { CronEditorService } from 'src/app/shared/services/cron-editor.service';
import cronstrue from 'cronstrue';
import * as moment from 'moment';

import { NgForm } from '@angular/forms';
@Component({
  selector: 'pos-data-source-subscribe-edit',
  templateUrl: './data-source-subscribe-edit.component.html',
  styleUrls: ['./data-source-subscribe-edit.component.scss'],
})
export class DataSourceSubscribeEditComponent extends BaseCrudComponent<DataSourceSubscribe> implements OnInit {

  @ViewChild('dataSourceSubscribeForm', { static: true }) dataSourceSubscribeForm: NgForm;
  dataSource: Array<DataSource> = [];
  selectedDataSource: DataSource = null;
  dataSourceSubscribe: DataSourceSubscribe;
  subscriptionDestinationColumns: Array<GridColumn> = [];
  exportConnection: Array<ExportConnection> = [];
  dataSourceParameter: DataSourceParameter[] = [];
  dataSubscriptionParameter: DataSubscriptionParameter[] = [];
  dataSourceName = '';
  dataSourceParameterError = Messages.DataSourceEmptyParameter;
  fieldInfoMessages = FieldInfoMessages;
  updatedIndex = 0;
  exportFormats = DomainConstants.ExportFormats;
  exportTypes = DomainConstants.ExportTypes;
  public icons = {
    editSolid,
    list,
    deleteWhite,
    mapPin,
    rssSquare
  };
  id = 0;
  tabList = {
    ParametersSetting: 'Parameters',
    SubscriptionDestination: 'SubscriptionDestination',
  };
  selectedTab = this.tabList.ParametersSetting;
  height = window.innerHeight - 830;

  cronOptions: CronOptions = null;
  isValidCron: boolean = true;
  destinationConfigValues: Array<ParameterModel> = [];
  isInActive: boolean;
  currentTimeStamp = moment(Date.now()).format('yyyyMMDDHHmmssSSSS');
  get getForm(): NgForm {
    return this.dataSourceSubscribeForm
  }

  props = {
    controlBoxClass: 'col-lg-3 col-md-4 col-sm-10 col-xs-12'
  }

  constructor(
    protected cronEditorService: CronEditorService,
    protected dataSourceSubscribeService: DataSourceSubscribeService,
    protected exportConnectionService: ExportConnectionService,
    protected dataSourceService: DataSourceService,
    private dataSourceParameterService: DataSourceParameterService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    formUtilityService: FormUtilityService
  ) {
    super(dataSourceSubscribeService, alertService, spinnerService, formUtilityService);
    this.id = parseInt(route.snapshot.params.id, 10) ?? 0;
    const navigation = router.getCurrentNavigation();
    this.isInActive = (navigation?.extras?.state && navigation?.extras?.state.isInActive) ?? false;
  }

  ngOnInit(): void {
    this.saveSuccessMessage = Messages.DataSubscriptionSaveSuccess;
    this.cronOptions = this.cronEditorService.newCronSettings(false);
    this.dataSourceSubscribe = this.dataSourceSubscribeService.newDataSourceSubscribe();
    this.dataSourceSubscribe.Schedule = CronEditorService.defaultQuartz;
  }

  loadDependencies() {
    const subscriptionObservable = [];
    subscriptionObservable.push(this.dataSourceService.getAll());
    subscriptionObservable.push(this.exportConnectionService.getAll());
    if (this.id) {
      subscriptionObservable.push(this.dataSourceSubscribeService.get(this.id));
    }
    this.spinnerService.show();
    forkJoin(subscriptionObservable)
      .pipe(
        finalize(() => {
          this.spinnerService.hide();
        })
      )
      .subscribe({
        next: (responses: Array<any>) => {
          if (responses && responses.length) {
            this.dataSource = filter(responses[0], (dataSource) => !dataSource.IsPrivate);
            this.exportConnection = responses[1];
            if (this.id) {
              this.dataSourceSubscribe = responses[2].DataSubscription;
              this.setDataSourceName();
              this.dataSourceSubscribe.ExportType = this.dataSourceSubscribe.ExportType ?? this.exportTypes.Auto;
              this.dataSubscriptionParameter = responses[2].DataSubscriptionParameters;
              const destinationJson = JSON.parse(this.dataSourceSubscribe.DestinationParameter);
              forEach(destinationJson, (value, key) => {
                this.destinationConfigValues.push({ Parameter: key, Value: value });
              });
              this.onDataSourceChange();
            }
          }
          this.createParameterRow(true);
        }, error: this.alertService.showApiError
      });
  }

  private setDataSourceName() {
    this.dataSourceName = this.dataSource?.find(
      (x) => x.Id === this.dataSourceSubscribe?.DataSourceId
    )?.Name;
  }

  onDataSourceChange() {
    this.selectedDataSource = null;
    if (this.dataSourceSubscribe.DataSourceId) {
      this.spinnerService.show();
      this.dataSourceParameterService
        .getDataSourceParameters(this.dataSourceSubscribe.DataSourceId)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (response) => {
            this.dataSourceParameter = response;
            forEach(this.dataSourceParameter, ((data) => {
              const subscriptionParam = find(this.dataSubscriptionParameter, x => x.DataSourceParameterId === data.Id);
              if (subscriptionParam) {
                data.DataSubscriptionParameterId = subscriptionParam.Id;
              }
              data.ParameterValue = subscriptionParam?.Id ? subscriptionParam.ParameterValue : data.InitialValue;
              data.ParameterValue = data.DataType === 'BIT' && data.ParameterValue ? JSON.parse(data.ParameterValue.toString())
                : data.ParameterValue;
              /* Will implement later if date picker is required for date type parameters
               if (data.DataType === 'DATE') {
                const date: Date = new Date();
                if (data.DefaultValue.toLowerCase().startsWith('now+')) {
                  const newDefaultValue = data.DefaultValue.toLowerCase().replace('now+', '');
                  date.setDate(date.getDate() + parseInt(newDefaultValue, 10));
                } else if (data.DefaultValue.toLowerCase().startsWith('now-')) {
                  const newDefaultValue = data.DefaultValue.toLowerCase().replace('now-', '');
                  date.setDate(date.getDate() - parseInt(newDefaultValue, 10));
                }
                data.ParameterValue = data.ParameterValue ?? date;
              } */
            }));
          }
        });
      this.selectedDataSource = find(this.dataSource, (source) => {
        return source.Id === this.dataSourceSubscribe.DataSourceId;
      });
      // if (this.selectedDataSource && this.selectedDataSource.QueryType === 'POS API') {
      //   this.dataSourceSubscribe.ExportFormat = find(this.exportFormats, (format) => {
      //     return format.Name === 'JSON';
      //   })?.Name;
      // }
      this.setDataSourceName();
    } else {
      this.dataSourceParameter = [];
    }
    this.dataSourceSubscribe.FileName = this.dataSourceSubscribe.FileName ? this.dataSourceSubscribe.FileName : this.dataSourceName;

  }

  createParameterRow(lastIndex) {
    if (!lastIndex && this.destinationConfigValues.length > 1) {
      return;
    }
    const newParameter = {
      Parameter: null,
      Value: null,
    };
    this.destinationConfigValues.push(newParameter);
  }

  submit(isValid: boolean) {
    this.isValidCron = CronEditorService.cronIsValid(this.cronOptions.cronFlavor, this.dataSourceSubscribe.Schedule)
    if (!isValid || !this.dataSourceSubscribe.Schedule || !this.isValidCron) {
      return;
    }
    this.spinnerService.show();
    this.prepareDataSourceParameters();
    this.save();
  }

  prepareDataSourceParameters() {
    remove(this.destinationConfigValues, (x) => !x.Parameter);
    let connectionJson = {};
    forEach(this.destinationConfigValues, (data) => {
      connectionJson = { ...connectionJson, [data['Parameter']]: data['Value'] };
    });
    this.dataSourceSubscribe.DestinationParameter = JSON.stringify(connectionJson);
    this.dataSubscriptionParameter = [];
    forEach(this.dataSourceParameter, (data) => {
      this.dataSubscriptionParameter.push({
        DataSourceParameterId: data.Id,
        ParameterValue: data.ParameterValue,
        //  ParameterValue: data.DataType === 'DATE' ?  formatDate(data.ParameterValue, 'MM-dd-yyyy', 'en').toString()
        //  : data.ParameterValue,
        DataSubscriptionId: this.dataSourceSubscribe.Id,
        Id: data.DataSubscriptionParameterId
      });
    });
  }

  save() {
    this.dataSourceSubscribeService
      .saveDataSubscriptionWithParameters({
        DataSubscription: this.dataSourceSubscribe,
        DataSubscriptionParameters: this.dataSubscriptionParameter,
      })
      .pipe(
        finalize(() => {
          this.spinnerService.hide();
        })
      )
      .subscribe({
        next: (response) => {
          this.onSaveSuccess();
        }, error: this.alertService.showApiError
      });
  }

  onSaveSuccess() {
    this.alertService.renderSuccessMessage(this.saveSuccessMessage);
    this.onCancel();
  }

  deleteParameterRow(index) {
    this.destinationConfigValues.splice(index, 1);
    this.updatedIndex++;
  }

  onCancel() {
    this.router.navigate(['subscriptions'], { relativeTo: this.route.parent, state: { isInActive: this.isInActive } });
  }

  getCronStrue = (schedule) => schedule ? cronstrue.toString(schedule) : '';

  seeCron(event) {
    this.dataSourceSubscribe.Schedule = event;
    this.isValidCron = CronEditorService.cronIsValid(this.cronOptions.cronFlavor, this.dataSourceSubscribe.Schedule);
  }

}
