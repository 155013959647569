import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { WidgetComponentProperties } from '../../interfaces/widget-component-properties';
import { MenuWidgetService } from '../../services/menu-widget.service';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { RabbitMQService } from 'src/app/shared/services/rabbitmq/services/rabbitmq.service';
import { Subscription } from 'rxjs';
import { cloneDeep, find, forEach, some } from 'lodash';
import { MenuWidgetStyleService } from '../../services/menu-widget-style.service';
import { MenuListComponent } from '../menu-list/menu-list.component';
import { MenuDisplayItem } from '../../interfaces/menu-display-item';
import { WidgetHeaderConfig } from '../../interfaces/widget-header-config';
import { WidgetHeaderStyleConfig } from '../../interfaces/widget-header-style-config';
import { ListWidgetStyleConfig } from '../../interfaces/list-widget-style-config';
import { ListWidgetConfig } from '../../interfaces/list-widget-config';
import { WidgetProductGroups } from '../../interfaces/widget-product-group';
import { MenuItemsSortService } from '../../services/menu-items-sort.service';
declare var $;

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pos-menu-list-widget',
  templateUrl: './menu-list-widget.component.html',
  styleUrls: ['./menu-list-widget.component.scss']
})
export class MenuListWidgetComponent extends BaseWidgetComponent implements OnInit, WidgetComponentProperties {


  @Input() widgetId: number;
  @ViewChild('menuList') menuList: MenuListComponent;
  listWidgetConfig: ListWidgetConfig = null;
  listWidgetStyleConfig: ListWidgetStyleConfig = null;
  headerStyleConfig: WidgetHeaderStyleConfig;
  headerWidgetConfig: WidgetHeaderConfig = null;
  menuListItems: Array<MenuDisplayItem> = [];
  widgetContentStyle = {};
  isDataLoaded = false;

  rabbitMqSalesProductOutOfStockEventSubscription: Subscription;
  rabbitMqSalesProductReplenishedEventSubscription: Subscription;

  constructor(
    protected menuWidgetService: MenuWidgetService,
    protected spinnerService: SpinnerService,
    protected alertService: AlertsService,
    protected rabbitMQService: RabbitMQService,
    protected applicationStateService: ApplicationStateService,
    protected modalService: ModalService,
    protected menuWidgetStyleService: MenuWidgetStyleService,
    private menuItemSortService: MenuItemsSortService) {
    super(menuWidgetService, alertService, spinnerService, applicationStateService, modalService, rabbitMQService);
    this.deleteSuccessMessage = Messages.WidgetDeleted;
  }

  imagePath = RuntimeConstants.IMAGE_BASE_PATH + '/menu-display';

  ngOnInit(): void {
    this.loadSubscriptions();
    this.loadWidget(this.widgetId);
    this.subscribeToSalesProductOutOfStockEventExchange();
    this.subscribeToSalesProductReplenishedEventExchange();
  }

  loadSubscriptions() {
    this.widgetData$.subscribe({
      next: (res) => {
        this.listWidgetConfig = res.WidgetConfig ? this.parseJson<ListWidgetConfig>(res.WidgetConfig) : null;
        if (this.listWidgetConfig.ShowIndentedText == null) {
          this.listWidgetConfig.ShowIndentedText = true;
        }
        this.listWidgetStyleConfig = res.WidgetStyleConfig ? this.parseJson<ListWidgetStyleConfig>(res.WidgetStyleConfig) : null;
        this.isDataLoaded = true;
        this.headerStyleConfig = this.listWidgetStyleConfig?.WidgetHeaderStyleConfig;
        this.headerWidgetConfig = this.listWidgetConfig?.WidgetHeaderConfig;
        this.listWidgetStyleConfig?.BorderStyle ? $('.widget-border-' + this.widgetId)?.css(this.menuWidgetStyleService.getBorderStyle(this.listWidgetStyleConfig.BorderStyle)) : null;
        $('.widget-background-' + this.widgetId)?.css({ 'background': this.listWidgetStyleConfig?.WidgetBackgroundColor });
        this.widgetContentStyle = this.menuWidgetStyleService.getWidgetContentStyle(this.listWidgetStyleConfig);
        this.menuListItems = [];
        if (res.WidgetProductGroups) {
          forEach(res.WidgetProductGroups, (data) => {
            this.menuListItems.push(this.setMenuList(data));
          });
          forEach(res.MenuDisplayItems, (data) => {
            if (data.WidgetProductGroupId) {
              this.setItemIsShowPrice(data);
              const menuItem = this.menuListItems.find((x) => {
                return x.WidgetProductGroupId === data.WidgetProductGroupId;
              });
              menuItem.SubItems.push({
                Id: data.Id,
                Text: data.Text,
                IsInStock: data?.IsInStock,
                Prices: data.Prices.sort(x => x?.Price),
                IsShowPrice: data.IsShowPrice,
                IsInteractive: data.IsInteractive,
                InteractiveStyleClass: data.InteractiveStyleClass
              });
            } else {
              this.setItemIsShowPrice(data);
              this.menuListItems.push(data);
            }
          });
          this.sortMenuSubItem();
        }
      }
    });
  }

  sortMenuSubItem() {
    forEach(this.menuListItems, (data) => {
      if (data.SubItems?.length) {
        const priorityList = this.listWidgetConfig?.ProductDisplayOrdinal?.find(pdo => pdo.GroupId != null && pdo.GroupId == data.WidgetProductGroupId.toString());
        data.SubItems = this.menuItemSortService.sortItems(data.SubItems, priorityList?.Ordinal ?? []);
      }
    });
  }

  setItemIsShowPrice(data) {
    if (data.Prices?.length > 0) {
      if (this.listWidgetConfig?.ShowInlinePrices?.length) {
        data.IsShowPrice = this.listWidgetConfig?.ShowInlinePrices?.includes(data.Id) ? true : false;
      }
    }
  }

  subscribeToSalesProductOutOfStockEventExchange = () => {
    this.rabbitMqSalesProductOutOfStockEventSubscription = this.rabbitMQService.subscribeToSalesProductOutOfStockEventExchange$()
      .subscribe({
        next: (message) => {
          const menuListItems = cloneDeep(this.menuListItems);
          if (message.Payload.POSEvent) {
            this.menuListItems = this.setSalesProductStockStatus(message.Payload.POSEvent.SalesProductId, message.Payload.POSEvent.SizeIds, false, menuListItems);
          }
        }
      });
  }

  subscribeToSalesProductReplenishedEventExchange = () => {
    this.rabbitMqSalesProductReplenishedEventSubscription = this.rabbitMQService.subscribeToSalesProductReplenishedEventExchange$()
      .subscribe({
        next: (message) => {
          if (message.Payload.POSEvent) {
            const menuListItems = cloneDeep(this.menuListItems);
            this.menuListItems = this.setSalesProductStockStatus(message.Payload.POSEvent.SalesProductId, message.Payload.POSEvent.SizeIds, true, menuListItems);           
          }
        }
      });
  }

  setSalesProductStockStatus = (productId: number, sizeIds: number[], status: boolean, menuItems: any[]) => {
    forEach(menuItems, (item) => {
      if (item.SubItems) {
        item.SubItems = this.setSalesProductStockStatus(productId, sizeIds, status, item.SubItems);
      }
      else if (item.Id === productId && item?.Prices) {
        forEach(item.Prices, (price) => {
          if (sizeIds?.some((size) => size == price?.SizeId )) {
            price.IsInStock = status;
          }
        });
      }
      item.IsInStock = some(item.Prices, (price) => price?.IsInStock);
    });
    return menuItems;
  }

  editWidget(id: number, data?: any): void {
    throw new Error('Method not implemented.');
  }

  setMenuList(groupProduct: WidgetProductGroups): MenuDisplayItem {
    const group = this.menuWidgetService.getNewMenuDisplayItem();
    const config = groupProduct?.GroupConfig ? JSON.parse(groupProduct?.GroupConfig) : {};
    group.WidgetProductGroupId = groupProduct.Id;
    group.Text = groupProduct?.Name;
    group.Price = groupProduct.Price;
    group.Description = config?.Description;
    group.SubItems = [];
    group.IsInStock = true;
    group.Ordinal = groupProduct.Ordinal;
    return group;
  }

}
