import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomainConstants, RuntimeConstants } from 'src/app/shared/constants';
import { BaseCrudService } from 'src/app/shared/services';
import { EventSubscriptionAction } from '../interfaces';
import { EventSubscription } from '../interfaces/event-subscription';
import { EventSubscriptionAttribute } from '../interfaces/event-subscription-attribute';
import { UserClockedInPeriod } from '../interfaces/user-clocked-in-period';

@Injectable({
  providedIn: 'root'
})
export class EventSubscriptionActionService extends BaseCrudService<EventSubscriptionAction> {

  eventsSubscriptionActionApiUrl = `${RuntimeConstants.API_PATH}event-subscription-action`;

  constructor(httpService: HttpClient) {
    super('event-subscription-action', httpService);
  }

  newEventSubscription(): EventSubscription {
    return {
      Id: 0,
      IsActive: true,
      EventId: 0,
      EventSubscriptionActions: [],
      EventSubscriptionRecoveryActions: [],
      EventSubscriptionAttributes: []
    };
  }

  newEventSubscriptionAttribute(): EventSubscriptionAttribute {
    return {
      Id: 0,
      AttributeKey: '',
      AttributeValue: null,
      EventSubscriptionId: 0
    };
  }

  newUserClockedInPeriod(period = DomainConstants.Period.Day): UserClockedInPeriod{
    return {
      Period: period,
      Day: null,
      DayOnMonth: null,
      WeekDay: null,
      Week: null,
      Month: DomainConstants.HoursOverPeriodMonth[0].Value
    }
  }

  updateActionsOrdinal(eventIdentifier: string, actions: Array<number>) {
    return this.httpService.put(`${this.eventsSubscriptionActionApiUrl}/${eventIdentifier}/action/update-ordinal`, actions);
  }

  changeActionStatus = (eventIdentifier: string, actionId: number, isActive: boolean) => {
    return this.httpService.post(`${this.eventsSubscriptionActionApiUrl}/${eventIdentifier}/action/${actionId}/state/${isActive}`, null);
  }

  deleteAction(eventIdentifier: string, actionId: number) {
    return this.httpService.delete(`${this.eventsSubscriptionActionApiUrl}/${eventIdentifier}/action/${actionId}`);
  }
}
