import { Component, OnInit, ViewChild } from '@angular/core';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { MenuSetupService } from '../../Services/menu-setup.service';
import { AlertsService } from 'src/app/shared/services';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { ConfirmDeleteComponent } from 'src/app/shared/components/confirm-delete/confirm-delete.component';
import { sync, times, unlinkedScreensDesktop } from 'src/app/shared/components/icon';
import { UnlinkedScreens } from '../../interface/UnlinkedScreens';
import { GridColumn, DeleteColumn } from '@tarktech/tark-ng-utils';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { Router } from '@angular/router';

@Component({
  selector: 'pos-unlinked-screen',
  templateUrl: './unlinked-screen.component.html',
  styleUrls: ['./unlinked-screen.component.scss']
})
export class UnlinkedScreenComponent implements OnInit {
  @ViewChild('confirmScreenDelete') private confirmScreenDelete: ConfirmDeleteComponent;
  public icons = {
    sync,
    times,
    unlinkedScreensDesktop
  };

  unlinkedScreenColumns: Array<GridColumn> = [];
  unlinkedScreenList: Array<UnlinkedScreens> = [];
  deleteScreen: UnlinkedScreens;
  public message;
  screenHeight = (window.innerHeight - 245) + 'px';
  constructor(
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private menuSetupService: MenuSetupService,
    private router: Router
  ) {
    this.reloadUnlinkedScreens();
  }

  ngOnInit() {
    this.columnConfiguration();
  }
  private columnConfiguration() {
    const deleteColumn = new DeleteColumn({
      Clicked: (data) => {
        this.deleteButton(data);
      },
      Width: '50px'
    });

    this.unlinkedScreenColumns = [
      new GridColumn({ HeaderText: 'Screen Name', Field: 'Name', IsSortable: true, Width: '71%' }),
      new GridColumn({ HeaderText: 'Buttons', Field: 'ButtonCount', IsSortable: true, Width: '25%' }),
      deleteColumn
    ] as Array<GridColumn>;

  }
  reloadUnlinkedScreens() {
    this.spinnerService.show();
    this.menuSetupService.getUnlinkedScreenList().subscribe(response => {
      if (response) {
        this.unlinkedScreenList = response;
      } else {
        this.unlinkedScreenList = [];
      }
    },
      this.alertService.showApiError,
      () => {
        this.spinnerService.hide();
      });
  }
  deleteButton($event: UnlinkedScreens) {
    this.deleteScreen = $event;
    this.message = StringUtils.format(Messages.ConfirmDeleteUnlinkedScreen, { 'screen': this.deleteScreen.Name });

    this.confirmScreenDelete.Show(this.message);
  }
  onConfirmAcknowledge() {
    this.spinnerService.show();
    this.menuSetupService.deleteUnlinkedScreen(this.deleteScreen.ScreenId).subscribe(
      () => {
        this.alertService.renderSuccessMessage(
          Messages.UnlinkedScreenDeleted
        );
        this.reloadUnlinkedScreens();
      },
      this.alertService.showApiError,
      () => {
        this.spinnerService.hide();
      });
  }
  cancel() {
    this.router.navigate(['manage/console']);
  }
}
