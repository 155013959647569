import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { GridColumn, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { ModalService } from 'src/app/shared';
import { plusWhite, editWhite, deleteWhite } from 'src/app/shared/components/icon';
import { LocationAddressEditComponent } from './location-address-edit/location-address-edit.component';
import { LocationAddress } from '../../interface/location-address';

@Component({
  selector: 'pos-location-address',
  templateUrl: './location-address.component.html',
  styleUrls: []
})
export class LocationAddressComponent implements OnInit {
  @Input() locationAddresses: LocationAddress[] = [];
  @Output() locationAddressesChange: EventEmitter<LocationAddress[]> = new EventEmitter<LocationAddress[]>();
  @Input() scrollHeight = '200px';
  @ViewChild('operationTemplate', { static: true }) operationTemplate: TemplateRef<any>;
  @ViewChild('operationHeaderTemplate', { static: true }) operationHeaderTemplate: TemplateRef<any>;

  icons = { editWhite, deleteWhite, plusWhite };

  locationAddressColumns: Array<GridColumn> = [];

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {
    this.configureColumns();
    
  }
  
  configureColumns() {
    const operationColumn = new TemplateColumn({
      headerTemplate: this.operationHeaderTemplate,
      itemTemplate: this.operationTemplate,
      Width: '67px',
      TextAlign: TextAlign.Center,
      CellClass: 'cell-padding'
    });
    this.locationAddressColumns = [
      new GridColumn({ HeaderText: 'Customer Id', Field: 'customerId'}),
      new GridColumn({ HeaderText: 'Name', Field: 'name'}),
      new GridColumn({ HeaderText: 'Location', Field: 'address', Width: '30%'}),
      new GridColumn({ HeaderText: 'URL Slug', Field: 'urlSlug'}),
      operationColumn
    ]
  }

  editItem(id: number, data: any): void {
    const modalRef = this.modalService.getModalWrapper(LocationAddressEditComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      initialState: {
        id: id,
        data: {...data} ?? { name: '', location: '', link: '', default: false }
      }
    });

    modal.close.subscribe(res => {
      if(res?.shouldReload) {
        if (res.data.id > 0)
          this.locationAddresses[res.data.id - 1] = res.data;
        else {
          res.data.id = this.locationAddresses.length + 1;
          this.locationAddresses.push(res.data);
        }
      }
    })
  }

  deleteItem(id: number) {
    this.locationAddresses = this.locationAddresses
      .filter(x => x.id != id)
      .map((x, index) => ({...x, id: index + 1}))
    this.locationAddressesChange.emit(this.locationAddresses);
  }
}
