import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { finalize } from 'rxjs/operators';
import { BaseFormComponent } from 'src/app/shared/components/base-form.component';
import { rawIngredientInventoryProductStore } from 'src/app/shared/components/icon/icons';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { DomainConstants } from 'src/app/shared/constants/domain-constants';
import { FieldInfoMessages } from 'src/app/shared/constants/field-info-messages';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { FormUtilityService } from 'src/app/shared/services/form-utility.service';
import { InventoryWorkflowParameter } from '../../interfaces';
import { InventoryWorkflowService } from '../../services/inventory-workflow.service';
import { PostSavedConfigurationService } from 'src/app/configurator/post-saved-configuration.service';

@Component({
  selector: 'pos-inventory-workflow',
  templateUrl: './inventory-workflow.component.html',
})
export class InventoryWorkflowComponent extends BaseFormComponent implements OnInit {
  @ViewChild('inventoryWorkflow') inventoryWorkflow: NgForm;
  get getForm(): NgForm {
    return this.inventoryWorkflow
  }
  inventoryWorkflowParameter: InventoryWorkflowParameter;
  isCheckedInventoryLogUnchangedQtyWarning = false;
  fieldInfoMessages = FieldInfoMessages;
  newInventoryRecordOnDuplicateAmount = DomainConstants.NewInventoryRecordOnDuplicateAmount;
  inventoryLogPopulateQuantityUsing = DomainConstants.InventoryLogPopulateQuantityUsing;
  promptServeMethods = cloneDeep(DomainConstants.PromptServeMethods);
  icons = {
    rawIngredientInventoryProductStore
  };
  constructor(private inventoryWorkflowService: InventoryWorkflowService,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private route: ActivatedRoute,
    private postSavedConfigurationService: PostSavedConfigurationService,
    private router: Router,
    formUtilityService: FormUtilityService) {
    super(formUtilityService);
  }

  ngOnInit(): void {
    this.inventoryWorkflowParameter = this.inventoryWorkflowService.newInventoryWorkflowConfigurationParameter();
    this.loadData();
  }

  loadData() {
    this.spinnerService.show();
    this.inventoryWorkflowService.getInventoryWorkflowParameters().pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (response) => {
        this.inventoryWorkflowParameter = response;
        this.isCheckedInventoryLogUnchangedQtyWarning = this.inventoryWorkflowParameter.InventoryLogUnchangedQtyWarningMinimumPercent > 0;
      }, error: this.alertService.showApiError
    });
  }

  onInventoryLogUnchangedQtyWarningChanged() {
    if (!this.isCheckedInventoryLogUnchangedQtyWarning) {
      this.inventoryWorkflowParameter.InventoryLogUnchangedQtyWarningMinimumPercent = 0;
      this.inventoryWorkflowParameter.InventoryLogUnchangedQtyWarningMinimumThreshold = 0;
    }
  }

  saveInventoryWorkflow(isValid: boolean) {
    if (!isValid) {
      return;
    }
    if (!this.inventoryWorkflowParameter.InventoryReceivingConfirmationWaitTime) {
      this.inventoryWorkflowParameter.InventoryReceivingConfirmationWaitTime = 0;
      this.inventoryWorkflowParameter.InventoryReceivingConfirmationWarningText = '';
    }
    this.spinnerService.show();
    this.inventoryWorkflowService.updateInventoryWorkflowParameters(this.inventoryWorkflowParameter).pipe(finalize(() => {
      this.spinnerService.hide()
    })).subscribe({
      next: (response) => {
        this.alertService.renderSuccessMessage(Messages.InventoryConfigurationSaveSuccess);
        this.postSavedConfigurationService.showMessage();
      }, error: this.alertService.showApiError
    });
  }

  cancel() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

}
