import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { RuntimeConstants } from "src/app/shared";
import { ServeScreenWorkflowParameter } from "../interfaces/serve-screen-workflow-parameter";
import { Observable } from "rxjs";
import { OrderItemVariable } from "../interfaces/order-item-variable";

@Injectable({
    providedIn: 'root'
})
export class ServeScreenWorkflowService {

    serveScreenWorkflowApiBasePath = `${RuntimeConstants.API_PATH}serve-screen-workflow-configuration`;
    constructor(private httpService: HttpClient) { }

    newServeScreenWorkflowConfigurationParameter(): ServeScreenWorkflowParameter {
        return {
            ItemReadyShowNotifyButtonOnServeScreen: false,
            ItemReadyNotifyViaSMSText: '',
            ItemReadyNotifyViaDJTerminal: null,
            ItemReadyNotifyViaDJVolume: null,
            ItemReadyNotifyViaAudioNotifier: null,
            ItemReadyNotifyViaAudioNotifierText: '',
            ItemReadyNotifyViaAudioNotifierVoice: '',
            ItemReadyNotifyViaAudioNotifierVolume: null,
            ItemReadyNotifyViaDJText: '',
            ItemReadyNotifyViaDJVoice: '',
        };
    }

    getServeScreenWorkflowParameters(): Observable<ServeScreenWorkflowParameter> {
        return this.httpService.get<ServeScreenWorkflowParameter>(`${this.serveScreenWorkflowApiBasePath}`);
    }

    updateServeScreenWorkflowParameters(serveScreenWorkflowParameters: ServeScreenWorkflowParameter) {
        return this.httpService.post<ServeScreenWorkflowParameter>(`${this.serveScreenWorkflowApiBasePath}`, serveScreenWorkflowParameters);
    }

    getOrderItemVariables(): Observable<Array<OrderItemVariable>> {
        return this.httpService.get<Array<any>>(`${this.serveScreenWorkflowApiBasePath}/order-item-variables`);
    }
}
