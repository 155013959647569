import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { MenuWidgetService } from '../../services/menu-widget.service';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { MenuWidgetStyleService } from '../../services';
import { WidgetStyleConfig } from '../../interfaces';
import { DomainConstants, RabbitMQService } from 'src/app/shared';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
declare var $;

@Component({
  selector: 'pos-menu-text-widget',
  templateUrl: './menu-text-widget.component.html',
  styleUrls: ['./menu-text-widget.component.scss']
})
export class MenuTextWidgetComponent extends BaseWidgetComponent implements OnInit {

  @Input() widgetId: number;
  text: SafeHtml = 'Sample text';
  widgetStyleConfig: WidgetStyleConfig = null;
  itemFontStyle: string = null;
  isVerticalText = false;
  widgetContentStyle = {};
  interactiveStyleClass = '';
  constructor(protected baseWidgetCrudService: MenuWidgetService,
    protected menuWidgetService: MenuWidgetService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected applicationStateService: ApplicationStateService,
    protected menuWidgetStyleService: MenuWidgetStyleService,
    public sanitizer: DomSanitizer,
    protected modalService: ModalService,
    protected rabbitMQService: RabbitMQService) {
    super(baseWidgetCrudService, alertService, spinnerService, applicationStateService, modalService, rabbitMQService);
  }

  ngOnInit(): void {
    this.loadSubscriptions();
    this.loadWidget(this.widgetId);
  }

  loadSubscriptions() {
    this.widgetData$.subscribe({
      next: (res) => {
        const menuDisplayItem = res.MenuDisplayItems ? res.MenuDisplayItems : null;
        this.text = menuDisplayItem[0]?.Text ? this.sanitizer.bypassSecurityTrustHtml(menuDisplayItem[0]?.Text) : res.Name;
        this.widgetStyleConfig = res.WidgetStyleConfig ? this.parseJson<WidgetStyleConfig>(res.WidgetStyleConfig) : null;
        this.isVerticalText = menuDisplayItem[0]?.Orientation === DomainConstants.Orientation.Vertical;
        $('.widget-background-' + this.widgetId)?.css({ 'background': this.widgetStyleConfig?.WidgetBackgroundColor });
        if (this.widgetStyleConfig?.BorderStyle) {
            $('.widget-border-' + this.widgetId)?.css(this.menuWidgetStyleService.getBorderStyle(this.widgetStyleConfig?.BorderStyle));
        }
        this.itemFontStyle = this.menuWidgetStyleService.getFontStyle(this.widgetStyleConfig?.ItemFontStyle);
        this.widgetContentStyle = this.menuWidgetStyleService.getWidgetContentStyle(this.widgetStyleConfig);
        this.interactiveStyleClass = menuDisplayItem[0]?.InteractiveStyleClass;
      }
    });
  }

  editWidget() {
  }

}
