<div class="small-device clearfix"></div>
<div class="col-lg-4 col-md-4 col-sm-4 ps-0 order-section__summary" id="divOrderSummary"
    [ngClass]="{'float-start ':configurations.UserPreferenceInvoiceLocation=='left'}">
    <a class="order-section__summary__cart-toggle" href="javascript:;">
        <pos-icon [icon]="icons.editToggleInvoiceArea"></pos-icon>
    </a>
    <div class="order-section__summary--fixed">
        <div class="row g-0" id="OrderInvoiceHeader" [ngStyle]="{'min-height': '90px'}">
            <div class="col-sm-6 col-8" [ngClass]="{'mb-pix-10':!orderData.Account && !orderData.CustomerAccount}">
                <div class="order-section__summary__title-cap">
                    <h2 class="order-section__summary__title">Order Summary</h2>
                </div>
                <span class="order-section__summary__title-cap"
                    *ngIf="(orderData.Id && orderData.Id.Value) && orderData.SurrogateOrderId">
                    Order #{{orderData.SurrogateOrderId}} &nbsp;
                    <span *ngIf="orderData.ScheduleDate">
                        <pos-icon [icon]="icons.clock"></pos-icon>
                        &nbsp;{{orderData.ScheduleDate | tark_date_time}}
                    </span>
                </span>
                <div class="order-section__summary__title-cap">
                    <div *ngIf="orderData.CustomerAccount && orderData.CustomerAccount.Name">
                        <span>{{loyaltyAccountTerm ? loyaltyAccountTerm : 'Account'}}:</span>
                        {{orderData.CustomerAccount.Name}}
                    </div>
                    <div *ngIf="orderData.Account && orderData.Account.Name">
                        <span *ngIf="orderData.Account.Type">{{orderData.Account.Type == accountTypes.Tab ? accountTerm
                            : tableTerm}}:</span>
                        {{orderData.Account.Name}}
                    </div>
                </div>
                <div class="order-section__summary__title-cap" [hidden]="orderData.Id && orderData.Id.Value > 0 ">
                    No Current Order
                </div>
            </div>
            <div class="absolute row mx-0 p-0">
                <div class="col-4 flex-grow">
                    <button class="btn bg-theme-primary float-right p-pix-10 pos-button" (click)="startANewOrder(true)"
                        [callCameraApi]="newOrderCameraAnnotation">
                        NEW ORDER
                    </button>
                </div>
                <div class="col-2 p-0 web-only">
                    <button class="btn bg-theme-separator p-pix-10 width-full pos-button"
                        (click)="saveUserPreference()">
                        <pos-icon [icon]="icons.arrowsAlth"></pos-icon>
                    </button>
                </div>

                <div class="col-12 pt-pix-2 pb-pix-2 px-md-0" *ngIf="orderData.Id && orderData.Id.Value > 0">
                    <button type="button" (click)="addNewSeat()" title="New"
                        class="btn btn-primary order-seat seat-btn pos-button float-right">
                        <pos-icon [icon]="icons.orderSeatPlus" class="font-small"></pos-icon>
                        {{ subaccountTerm }}
                    </button>
                </div>
            </div>
        </div>
        <div id="orderSummaryInvoice">
            <div style="position:relative;" class="order-section__summary__invoice thick-scrollbar overflow-auto-tabs"
                id="divOrderContent"
                [ngClass]="{'l-0 ':configurations.UserPreferenceInvoiceLocation=='left' && windowHeight >= 765,'r-0':configurations.UserPreferenceInvoiceLocation=='right' && windowHeight >= 765}">
                <div id="loaderInvoiceDiv" class="order-loading-block"
                    [ngClass]="orderInvoiceLoader?'show':'display-none-imp'">
                    <pos-spinner-icon></pos-spinner-icon>
                </div>

                <div class="order-item-border col-12 px-0 row mx-0" *ngIf="orderData.OrderAttributes.PagerNumber">
                    <div class="col-md-2 col-sm-3 col-2 p-0">
                    </div>
                    <div class="col-sm-9 col-5 pt-pix-7 pe-0 ps-0 col-lg-6 col-md-5">
                        <strong>Pager Number: {{orderData.OrderAttributes.PagerNumber}}</strong>
                    </div>
                </div>


                <div class="order-item-border col-12 px-0 row mx-0" *ngIf="orderData.DiscountPct > 0">
                    <div class="col-md-2 col-sm-3 col-2 p-0">
                        <span
                            permissions='[{"Name":"{{permissions.OrderEntryRemoveNotSentItem}}", "Level":"{{accessLevels.Access}}"}]'
                            [hidden]="(orderData.PaidAmount > 0)? !orderEntryRemoveItemPermission : false">
                            <a class="btn-cross pe-pix-9 ps-pix-9 pointer" (click)="deleteDiscountPct()">
                                <pos-icon [icon]="icons.times"></pos-icon>
                            </a>
                        </span>
                    </div>
                    <div class="col-sm-9 col-5 pt-pix-7 pe-0 ps-0 col-lg-6 col-md-5">
                        <strong>Discount {{orderData.DiscountPct}}%</strong>
                    </div>
                    <div class="col-sm-4 float-right p-0">
                        <span class="product-price pe-pix-5">{{-1 *(((orderData.ProductsTotal * orderData.DiscountPct /
                            100)
                            * 100) | ceil) / 100 | tark_currency}}</span>
                    </div>
                </div>
                <div class="order-item-border col-12 px-0 row mx-0" *ngIf="orderData.MarkupPct">
                    <div class="col-md-2 col-sm-3 col-2 p-0">
                        <span
                            permissions='[{"Name":"{{permissions.OrderEntryRemoveNotSentItem}}", "Level":"{{accessLevels.Access}}"}]'
                            [hidden]="(orderData.PaidAmount > 0) ? !orderEntryRemoveItemPermission : false">
                            <a class="btn-cross pe-pix-9 ps-pix-9 pointer" (click)="deleteMarkupPct()">
                                <pos-icon [icon]="icons.times"></pos-icon>
                            </a>
                        </span>
                    </div>
                    <div class="col-sm-9 col-5 pt-pix-7 pe-0 ps-0 col-lg-6 col-md-5">
                        <strong>Markup {{orderData.MarkupPct}}%</strong>
                    </div>
                    <div class="col-sm-4 float-right p-0">
                        <span class="product-price pe-pix-5">{{orderData.ProductsTotal * orderData.MarkupPct / 100
                            | tark_currency}}</span>
                    </div>
                </div>
                <div class="order-item-border col-12 px-0 row mx-0" *ngIf="orderData.DeliveryFee">
                    <div class="col-md-2 col-sm-3 col-2 p-0">
                        <span
                            permissions='[{"Name":"{{permissions.OrderEntryRemoveNotSentItem}}", "Level":"{{accessLevels.Access}}"}]'
                            [hidden]="(orderData.PaidAmount > 0) ? !orderEntryRemoveItemPermission : false">
                            <a class="btn-cross pe-pix-9 ps-pix-9 pointer" (click)="deleteDeliveryFee()">
                                <pos-icon [icon]="icons.times"></pos-icon>
                            </a>
                        </span>
                    </div>
                    <div class="col-sm-9 col-5 pt-pix-7 pe-0 ps-0 col-lg-6 col-md-5">
                        <strong>Delivery Fee</strong>
                    </div>
                    <div class="col-sm-4 float-right p-0">
                        <span class="product-price pe-pix-5">{{orderData.DeliveryFee | tark_currency}}</span>
                    </div>
                </div>
                <div class="order-item-border col-12 px-0 row mx-0" *ngIf="orderData.OrderAttributes.PickupTime">
                    <div class="col-md-2 col-sm-3 col-2 p-0">
                    </div>
                    <div class="col-sm-9 col-5 pt-pix-7 pe-0 ps-0 col-lg-8 col-md-8">
                        <strong>Online Order - Pickup Time: {{ today === pickupDate ?
                            (orderData.OrderAttributes.PickupTime | tark_time)
                            : (orderData.OrderAttributes.PickupTime | tark_date_time )}}</strong>
                    </div>
                </div>
                <div class="order-item-border col-12 px-0 row mx-0" *ngIf="orderData.OrderAttributes.IsCallIn">
                    <div class="col-md-2 col-sm-3 col-2 p-0">
                        <span
                            permissions='[{"Name":"{{permissions.OrderEntryRemoveNotSentItem}}", "Level":"{{accessLevels.Access}}"}]'
                            [hidden]="(orderData.PaidAmount > 0) ? !orderEntryRemoveItemPermission : false">
                            <a class="btn-cross pe-pix-9 ps-pix-9 pointer" (click)="deleteCallIn()">
                                <pos-icon [icon]="icons.times"></pos-icon>
                            </a>
                        </span>
                    </div>
                    <div class="col-sm-9 col-5 pt-pix-7 pe-0 ps-0 col-lg-8 col-md-8">
                        <strong>Call In: {{ orderData.OrderAttributes.CallInOrderTime }}</strong>
                    </div>
                </div>
                <div class="order-item-border col-12 px-0 row g-0 ps-pix-1" *ngIf="orderData.OrderAttributes.Name">
                    <div class="col-md-2 col-sm-3 col-2 p-0">
                        <span
                            permissions='[{"Name":"{{permissions.OrderEntryRemoveNotSentItem}}", "Level":"{{accessLevels.Access}}"}]'
                            [hidden]="(orderData.PaidAmount > 0) ? !orderEntryRemoveItemPermission : false">
                            <a class="btn-cross pe-pix-9 ps-pix-9 pointer" (click)="deleteName()">
                                <pos-icon [icon]="icons.times"></pos-icon>
                            </a>
                        </span>
                    </div>
                    <div class="col-sm-9 col-5 pt-pix-7 pe-0 ps-0 col-lg-8 col-md-8 text-ellipsis">
                        <strong>Name: {{ orderData.OrderAttributes.Name }}</strong>
                    </div>
                </div>
                <div class="order-item-border col-12 px-0 row mx-0" *ngIf="orderData.OrderAttributes.PhoneNumber">
                    <div class="col-md-2 col-sm-3 col-2 p-0">
                        <span
                            permissions='[{"Name":"{{permissions.OrderEntryRemoveNotSentItem}}", "Level":"{{accessLevels.Access}}"}]'
                            [hidden]="(orderData.PaidAmount > 0) ? !orderEntryRemoveItemPermission : false">
                            <a class="btn-cross pe-pix-9 ps-pix-9 pointer" (click)="deletePhoneNumber()">
                                <pos-icon [icon]="icons.times"></pos-icon>
                            </a>
                        </span>
                    </div>
                    <div class="col-sm-9 col-5 pt-pix-7 pe-0 ps-0 col-lg-8 col-md-8">
                        <strong>Phone: {{ orderData.OrderAttributes.PhoneNumber | phoneMaskPipe }}</strong>
                    </div>
                </div>
                <div *ngFor="let item of seats | keyvalue: originalOrder" class="clear-both">
                    <div *ngIf="((item.key)) && item.key > 0"
                        class="text-center col-lg-12 col-md-12 co-sm-12 col-12 seats-div p-0 bg-theme-primary"
                        [ngClass]="{'bg-theme-separator':item.key == currentSeat,'bg-theme-primary': item.key != currentSeat, 'mb-pix-5': !item.value.length}"
                        (click)="selectSeat(item.key)">
                        <label class="mbi-2 vertical-mid text-ellipsis" *ngIf="item.key != 99"> {{orderSubaccount &&
                            orderSubaccount[item.key] && orderSubaccount[item.key].SubaccountName
                            ? orderSubaccount[item.key].SubaccountName : subaccountTerm +' '+item.key}}
                        </label>
                        <label class="white pe-pix-5 ps-pix-5 pointer mb-0"
                            *ngIf="isSubaccountModificationAllow && item.key != 99"
                            (click)="openSubaccountNameModel(item.key)"><pos-icon
                                [icon]="icons.pencil"></pos-icon></label>
                        <label class="white mbi-2" *ngIf="item.key == 99"> To Go </label>
                    </div>



                    <div class="col-12 px-0 order-item-border row g-0 ps-pix-1" style="background: #ffe8e8"
                        *ngIf="dietaryRestrictions && dietaryRestrictions[item.key]">
                        <div class="col-md-1 col-sm-2 col-1">
                            <a class="btn-cross p-pix-6">
                                <pos-icon [icon]="icons.exclamationTriangle"></pos-icon>
                            </a>
                        </div>
                        <div class="col-md-1 col-sm-2 p-0"></div>
                        <div class="col-md-10 col-sm-2 p-0">
                            <strong>
                                Dietary Warnings
                            </strong>
                            <div *ngFor="let dietaryRestriction of dietaryRestrictions | keyvalue">
                                <div *ngIf="dietaryRestriction.key == item.key">
                                    <div class="row p-0" *ngFor="let warning of dietaryRestriction.value">
                                        <span class="col-lg-3 col-md-4 col-sm-6 pointer">
                                            <pos-icon [icon]="icons.trashRegularOrderInvoice"
                                                (click)="deleteDietaryWarning(warning)"></pos-icon>
                                            {{warning.Name}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 g-0 row"
                        [ngClass]="{'order-item-border': !product.GroupId, 'mx-0': product.GroupId}"
                        *ngFor="let product of item.value; let i = index">
                        <div class="col-12 combo-header p-pix-2 row g-0" *ngIf="product.GroupId && !product.IsComboProduct">
                            <div class="col-3 p-0">
                                <button class="btn-times font-16 pointer"
                                    *ngIf="getGroupProductsStatus(product.GroupId, item.value)"
                                    permissions='[{"Name":"{{permissions.OrderEntryRemoveNotSentItem}}", "Level":"{{accessLevels.Access}}"}]'
                                    [hidden]="checkGroupItemsRemovePermission(product.GroupId, item.value) ? !orderEntryRemoveItemPermission : false"
                                    (click)="deleteComboItemFromOrder(product.GroupId, product)"
                                    [ngStyle]="{'background-color': (inflightItemDeleteRequests || inflightItemComponentDeleteRequests) ?'gray':'none'}">
                                    <pos-icon [icon]="icons.times"></pos-icon>
                                </button>
                            </div>
                            <div class="col-6 p-0 vertical-center-align">
                                <div class="combo-label" ngbDropdown>{{ product.OrderProductGroup.Name
                                    }}
                                    <span class="float-end ps-pix-20" *ngIf="seatsCount > 1 && item.key > 0"
                                        aria-expanded="true" ngbDropdownToggle data-toggle="dropdown"
                                        id="dropdownMenu1">
                                    </span>
                                    <ul ngbDropdownMenu aria-labelledby="dropdownMenu1" *ngIf="seatsCount > 1"
                                        class="dropdown-menu order-options-dropdown pointer" role="menu">
                                        <li ngbDropdownItem class="dropdown-header">Move To</li>
                                        <ng-container *ngFor="let seat of seats | keyvalue">
                                            <li ngbDropdownItem *ngIf="seat.key && item.key != seat.key && seat.key != '0'"
                                                (click)="moveProductGroupToOtherSeat(product.GroupId, seat.key, product.SubAccountOrdinal)"
                                                [hidden]="" role="presentation"
                                                class="dropdown-submenu order-options-dropdown__order-option"
                                                aria-expanded="true" data-toggle="dropdown" id="dropdownMenu2">
                                                <span *ngIf="seat.key != 99 && seat.key > 0">
                                                    {{orderSubaccount && orderSubaccount[seat.key] &&
                                                    orderSubaccount[seat.key].SubaccountName
                                                    ? orderSubaccount[seat.key].SubaccountName : subaccountTerm +'
                                                    '+seat.key}}
                                                </span>
                                                <span *ngIf="seat.key == 99">To Go</span>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-3 p-0">
                                <pos-quantity-change [isSmallScreen]="true" [(orderProduct)]="item.value[i]"
                                    [paidAmount]="orderData.PaidAmount"
                                    (changeComboItemQty)="changeComboItemQty($event, product.SubAccountOrdinal)"
                                    [groupId]="product.GroupId">
                                </pos-quantity-change>
                            </div>
                        </div>
                        <div class="col-12 row g-0 ps-pix-1 mx-0"
                            [ngClass]="{'combo-product ps-0': product.GroupId, 'pe-0': !product.GroupId }">
                            <div class="col-lg-2 col-3 p-0">
                                <span
                                    *ngIf="product.StatusId==orderProductStatus.ORDER_STATUS_NONE && product.OrderItemType != orderItemTypes.SingularFee && !product.GroupId"
                                    permissions='[{"Name":"{{permissions.OrderEntryRemoveNotSentItem}}", "Level":"{{accessLevels.Access}}"}]'>
                                    <a class="btn-cross pe-pix-9 ps-pix-9 pointer me-pix-5"
                                        (click)="deleteItemFromOrder(product)"
                                        [hidden]="(product.StatusId != 0 || product.IsSentToKitchen || product.IsDirty)? !orderEntryRemoveItemPermission : false"
                                        [ngStyle]="{'background-color': (inflightItemDeleteRequests || inflightItemComponentDeleteRequests) ?'gray':'none'}">
                                        <pos-icon [icon]="icons.times"></pos-icon>
                                    </a>
                                </span>
                                <span *ngIf="product.StatusId==orderProductStatus.ORDER_STATUS_SENT">
                                    <a class="btn-cross pe-pix-6 ps-pix-6 me-pix-5">
                                        <pos-icon [icon]="icons.check"></pos-icon>
                                    </a>
                                </span>
                                <span *ngIf="product.StatusId==orderProductStatus.ORDER_STATUS_PAID">
                                    <a class="btn-cross bg-theme-primary pe-pix-6 ps-pix-6 me-pix-5">
                                        <pos-icon [icon]="icons.check"></pos-icon>
                                    </a>
                                </span>
                                <span *ngIf="product.StatusId==orderProductStatus.ORDER_STATUS_TIMED">
                                    <a class="btn-cross bg-theme-primary pe-pix-6 ps-pix-6 me-pix-5 pointer"
                                        (click)="timedProduct(product)">
                                        <pos-icon [icon]="icons.clock"></pos-icon>
                                    </a>
                                </span>

                                <span *ngIf="product.StatusId==orderProductStatus.ORDER_STATUS_REFUNDED">
                                    <a class="btn-cross bg-theme-primary pe-pix-6 ps-pix-6 me-pix-5">
                                        <pos-icon [icon]="icons.undo"></pos-icon>
                                    </a>
                                </span>

                                <span *ngIf="product.StatusId==orderProductStatus.ORDER_STATUS_DELETED">
                                    <a class="btn-cross">
                                        <pos-icon [icon]="icons.times"></pos-icon>
                                    </a>
                                </span>

                                <a class="btn-sent-to-kitchen ps-pix-7 pe-pix-7"
                                    *ngIf="(product.SalesProductId?.Value > 0 || product.SalesProductId?.Value == -4 || product.SalesProductId?.Value == -5) && product.IsSentToKitchen && !product.IsDirty"
                                    [ngClass]="{'ms-pix-31' : product.StatusId == 0 && (((product.IsSentToKitchen || product.IsDirty)? !orderEntryRemoveItemPermission : false))}">
                                    <pos-icon [icon]="icons.check" class="white"></pos-icon>
                                </a>
                            </div>
                            <div class="col-md-9 col-5 pt-pix-7 pe-0 ps-0 col-lg-5">
                                <strong>
                                    <div ngbDropdown>
                                        <span>{{product.MakeTableText}}
                                            <span
                                                *ngIf="product.Qty && product.OrderItemType ==  orderItemTypes.Product && product.Qty - (product.RefundedQty ? product.RefundedQty : 0) > 0">
                                                ({{(product.RefundedQty ? product.Qty - product.RefundedQty :
                                                product.Qty) +
                                                (product.SalesProductBusinessEntity &&
                                                product.SalesProductBusinessEntity.SalesUnit &&
                                                product.SalesProductBusinessEntity.SalesUnit.AbbreviationName ?
                                                (' ' +
                                                product.SalesProductBusinessEntity.SalesUnit.AbbreviationName):'')}})
                                                <span class="refunded-qty" *ngIf="product.RefundedQty">
                                                    (ordered {{product.Qty}})
                                                </span>
                                            </span>
                                        </span>

                                        <span class="float-right me-pix-3" ngbDropdownToggle
                                            *ngIf="seatsCount > 1 && item.key > 0 && !product.GroupId"
                                            aria-expanded="true" data-toggle="dropdown" id="dropdownMenu1">
                                        </span>
                                        <div class="dropdown">
                                            <ul ngbDropdownMenu aria-labelledby="dropdownMenu1"
                                                *ngIf="seatsCount > 1 && !product.GroupId"
                                                class="dropdown-menu order-options-dropdown pointer" role="menu">
                                                <li ngbDropdownItem class="dropdown-header">Move To</li>
                                                <li ngbDropdownItem *ngFor="let seat of seats | keyvalue"
                                                    (click)="moveItemToOtherSeat(item, product, seat.key)"
                                                    [hidden]="(item.key == seat.key || seat.key == '0')" role="presentation"
                                                    class="dropdown-submenu order-options-dropdown__order-option"
                                                    aria-expanded="true" data-toggle="dropdown" id="dropdownMenu2">
                                                    <span *ngIf="seat.key != 99 && seat.key > 0">
                                                        {{orderSubaccount && orderSubaccount[seat.key] &&
                                                        orderSubaccount[seat.key].SubaccountName
                                                        ? orderSubaccount[seat.key].SubaccountName : subaccountTerm +'
                                                        '+seat.key}}
                                                    </span>
                                                    <span *ngIf="seat.key == 99">To Go</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </strong>
                                <div class="order-page__comment"
                                    *ngFor="let component of product.OrderProductComponents"
                                    [hidden]="product.OrderItemType != orderItemTypes.Product">
                                    <div class="order-page__comment__icon"
                                        (click)="deleteOrderProductComponent(product, component)"
                                        *ngIf="product.StatusId!=orderProductStatus.ORDER_STATUS_SENT && product.StatusId!=orderProductStatus.ORDER_STATUS_PAID && !component.IsFees"
                                        [hidden]="(product.StatusId != 0 || product.IsSentToKitchen || product.IsDirty)? !orderEntryRemoveItemPermission : false"
                                        permissions='[{"Name":"{{permissions.OrderEntryRemoveNotSentItem}}", "Level":"{{accessLevels.Access}}"}]'>
                                        <pos-icon [icon]="icons.trashRegularOrderInvoice"
                                            *ngIf="!inflightItemComponentDeleteRequests"></pos-icon>
                                        <pos-icon [icon]="icons.trashRegularOrderInvoiceDisable"
                                            *ngIf="inflightItemComponentDeleteRequests"></pos-icon>
                                    </div>
                                    <div class="order-page__comment__text">{{component.MakeTableText}}</div>

                                </div>
                            </div>
                            <!-- only visible in md -->
                            <div class="col-sm-12 p-0 d-none d-md-block d-lg-none">
                                <div class="col-sm-12 float-start px-0 pt-2">
                                    <div class="order-section__summary__buttons"
                                        [hidden]="product.OrderItemType != orderItemTypes.Product">
                                        <button class="btn-cross bg-theme-primary order-section__summary__buttons__btn"
                                            #pop="bs-popover" placement="left" (click)="toggleInvoicePopover(product)"
                                            [outsideClick]="true" [popover]="barsPopoverTemplate" triggers="click">
                                            <pos-icon [icon]="icons.orderInvoiceBars"></pos-icon>
                                        </button>
                                        <pos-quantity-change *ngIf="!product.GroupId" [isSmallScreen]="true"
                                            [(orderProduct)]="item.value[i]" [paidAmount]="orderData.PaidAmount"
                                            [(changeItemQty)]="changeItemQty" [addQuantityToItem]="addQuantityToItem">
                                        </pos-quantity-change>
                                        <button class="btn-cross bg-theme-primary order-section__summary__buttons__btn"
                                            data-toggle="modal"
                                            (click)="enterCommentForItem(product,product.MakeTableText)">
                                            <pos-icon [icon]="icons.comments"></pos-icon>
                                        </button>

                                        <button class="btn-cross bg-theme-primary order-section__summary__buttons__btn"
                                            *ngIf="(product.StatusId != 0)? false : orderEntryOverridePricePermission"
                                            data-toggle="modal" (click)="overridePrice(product)">
                                            <span>{{currencySymbol}}</span>
                                        </button>
                                    </div>
                                </div>
                                <div class="col-sm-4 float-right">
                                    <span class="product-price"
                                        [hidden]="product.OrderItemType == orderItemTypes.Name || product.OrderItemType == orderItemTypes.Comment">
                                        {{((product.Price*
                                        (product.SalesProductId && product.SalesProductId.Value ? product.Qty : 1))) |
                                        tark_currency}}
                                    </span>
                                </div>
                            </div>
                            <!-- hide in md -->
                            <div class="col-4 p-0 col-lg-5 pe-pix-1 d-md-none d-lg-block">
                                <div class="order-section__summary__buttons"
                                    [hidden]="product.OrderItemType != orderItemTypes.Product">
                                    <button
                                        class="btn-invoice py-pix-6 bg-theme-primary order-section__summary__buttons__btn px-0"
                                        #pop="bs-popover" placement="left" (click)="toggleInvoicePopover(product)"
                                        [outsideClick]="true" [popover]="barsPopoverTemplate" triggers="click">
                                        <pos-icon [icon]="icons.orderInvoiceBars"></pos-icon>
                                    </button>
                                    <pos-quantity-change *ngIf="!product.GroupId" [isSmallScreen]="false"
                                        [(orderProduct)]="item.value[i]" [paidAmount]="orderData.PaidAmount"
                                        [(changeItemQty)]="changeItemQty" [addQuantityToItem]="addQuantityToItem">
                                    </pos-quantity-change>

                                    <button
                                        class="py-pix-6 bg-theme-primary order-section__summary__buttons__btn px-0 btn-invoice"
                                        data-toggle="modal"
                                        (click)="enterCommentForItem(product,product.MakeTableText)">
                                        <pos-icon [icon]="icons.comments"></pos-icon>
                                    </button>
                                    <button *ngIf="(product.StatusId != 0)? false : orderEntryOverridePricePermission"
                                        class="btn-invoice py-pix-6 bg-theme-primary order-section__summary__buttons__btn px-0"
                                        data-toggle="modal" (click)="overridePrice(product)">
                                        <span>{{currencySymbol}}</span>
                                    </button>
                                </div>
                                <span class="product-price"
                                    [hidden]="product.OrderItemType == orderItemTypes.Name || product.OrderItemType == orderItemTypes.Comment">
                                    {{((product.Price*
                                    (product.SalesProductId && product.SalesProductId.Value ? product.Qty : 1))) |
                                    tark_currency}}
                                </span>
                            </div>
                        </div>

                    </div>
                    <div class="order-item-border col-12 px-0 row mx-0" *ngFor="let comment of orderData.Comments">
                        <div class="col-md-2 col-sm-3 col-2 p-0">
                            <span
                                [hidden]="(product.StatusId != 0 || product.IsSentToKitchen || product.IsDirty)? !orderEntryRemoveItemPermission : false">
                                <a class="btn-cross pe-pix-9 ps-pix-9" (click)="deleteItemFromOrder(product)"
                                    [ngStyle]="{'background-color': (inflightItemDeleteRequests || inflightItemComponentDeleteRequests) ?'gray':'none'}">
                                    <pos-icon [icon]="icons.times"></pos-icon>
                                </a>
                            </span>
                        </div>
                        <div class="col-sm-9 col-5 pt-pix-7 pe-0 ps-0 col-lg-6 col-md-5">
                            <strong>{{comment.Text}}</strong>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-4 col-12 p-0 order-section__summary__totals" id="OrderSummaryTotal"
                    [ngClass]="{'l-0 ':configurations.UserPreferenceInvoiceLocation=='left','r-0':configurations.UserPreferenceInvoiceLocation=='right'}">
                    <div class="pe-pix-20">
                        <pos-order-summary-total [orderData]="orderData"></pos-order-summary-total>
                    </div>
                    <div class="row mx-0 col-12 px-pix-10 pb-pix-10">
                        <div class="col-6 p-pix-2" *ngIf="!settingParam.IsSendOrderToKitchenImmediately"
                            [ngClass]="{'col-lg-3':settingParam.IsShowReviewOrder,'col-lg-4':!settingParam.IsShowReviewOrder}">
                            <button class="btn bg-theme-primary width-full text-ellipsis pos-button" data-toggle="modal"
                                [disabled]="inflightItemQtyChangeRequests || inflightPriceModifier || inflightItemComponentDeleteRequests || inflightItemDeleteRequests"
                                (click)="sentToKitchen()">
                                <pos-icon [icon]="icons.spinner"
                                    *ngIf="inflightItemQtyChangeRequests || inflightPriceModifier || inflightItemComponentDeleteRequests || inflightItemDeleteRequests">
                                </pos-icon>
                                <pos-icon [icon]="icons.sendOrderPaperPlane" class="pe-pix-5"></pos-icon>
                                {{invoiceAreaButtonText?.SendOrder ? invoiceAreaButtonText.SendOrder : 'SEND ORDER'}}
                            </button>
                        </div>
                        <div class="col-6 p-pix-2"
                            [ngClass]="{'col-lg-3':!settingParam.IsSendOrderToKitchenImmediately && settingParam.IsShowReviewOrder,'col-lg-4':settingParam.IsSendOrderToKitchenImmediately || !settingParam.IsShowReviewOrder}">
                            <button class="btn bg-theme-separator width-full text-ellipsis pos-button"
                                permissions='[{"Name":"{{permissions.CancelOrder}}", "Level":"{{accessLevels.Access}}"}]'
                                data-toggle="modal" (click)="cancelOrder()">
                                <pos-icon [icon]="icons.cancelOrderUndo" class="pe-pix-5"></pos-icon>
                                {{invoiceAreaButtonText?.CancelOrder ? invoiceAreaButtonText.CancelOrder : 'CANCEL ORDER'}}
                            </button>
                        </div>
                        <div class="col-6 p-pix-2" *ngIf="settingParam.IsShowReviewOrder"
                            [ngClass]="{'col-lg-3':!settingParam.IsSendOrderToKitchenImmediately,'col-lg-4':settingParam.IsSendOrderToKitchenImmediately}">
                            <button class="btn btn-warning white width-full text-ellipsis pos-button"
                                [disabled]="isReviewOrder" (click)="reviewOrder()">
                                <pos-icon [icon]="icons.reviewOrderSearchDollar" class="pe-pix-5"></pos-icon>
                                {{invoiceAreaButtonText?.ReviewOrder ? invoiceAreaButtonText.ReviewOrder : 'REVIEW ORDER'}}
                            </button>
                        </div>
                        <div class="p-pix-2"
                            [ngClass]="{'col-lg-3 col-6 ':!settingParam.IsSendOrderToKitchenImmediately && settingParam.IsShowReviewOrder,'col-lg-4 col-12':settingParam.IsSendOrderToKitchenImmediately || !settingParam.IsShowReviewOrder}">
                            <button class="btn bg-theme-primary width-full text-ellipsis pos-button"
                                (click)="settleOrderPayment()"
                                [disabled]="inflightItemQtyChangeRequests || inflightPriceModifier || inflightItemComponentDeleteRequests || inflightItemDeleteRequests">
                                <pos-icon [icon]="icons.spinner" class="spinner-icon pe-pix-3"
                                    *ngIf="inflightItemQtyChangeRequests || inflightPriceModifier || inflightItemComponentDeleteRequests || inflightItemDeleteRequests">
                                </pos-icon>
                                <pos-icon [icon]="icons.ringupMoneyBill" class="pe-pix-5"></pos-icon>
                                {{invoiceAreaButtonText?.SettleOrder ? invoiceAreaButtonText.SettleOrder : 'RING UP'}}
                            </button>
                        </div>

                        <div class="col-12 p-pix-2">
                            <button class="btn btn-secondary width-full mobile-only text-ellipsis pos-button">
                                <pos-icon [icon]="icons.checkSquare" class="pe-pix-5"></pos-icon>
                                Serve Order
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <ng-template #barsPopoverTemplate>
        <div *ngIf="showInvoicePopover" class="col-185">
            <ul class="invoice-operations">
                <li class="pointer"
                    permissions='[{"Name":"{{permissions.OrderEntryScheduleOrder}}", "Level":"{{accessLevels.Access}}"}]'>
                    <span class="grey" (click)="timedProduct(orderProduct)">
                        <pos-icon [icon]="icons.clock" class="col-lg-1 text-center p-0 col-16 me-pix-5"></pos-icon>
                        Schedule
                        Product
                    </span>
                </li>
                <hr
                    permissions='[{"Name":"{{permissions.OrderEntryScheduleOrder}}", "Level":"{{accessLevels.Access}}"}]' />
                <li class="pointer">
                    <span class="grey" (click)="productLabelPrint(orderProduct)">
                        <pos-icon [icon]="icons.printOrderInvoice"
                            class="col-lg-1 text-center p-0 col-16 me-pix-5"></pos-icon>
                        Label Print
                    </span>
                </li>
                <hr />
                <li class="pointer">
                    <span class="grey" (click)="printNutritionFacts(orderProduct)">
                        <pos-icon [icon]="icons.nutitionFactPrint"
                            class="col-lg-1 text-center p-0 col-16 me-pix-5"></pos-icon>
                        Nutrition Facts Print
                    </span>
                </li>
            </ul>
        </div>
    </ng-template>
<pos-scan-qr-code [orderId]="orderData.Id.Value"></pos-scan-qr-code>
