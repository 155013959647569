import { Injectable, EventEmitter } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ModalBackdropService, ModalComponent, ModalWrapperComponent } from './modal-component';
import { ICloseable } from './ICloseable';
import { forEach } from 'lodash';
declare let $: any;

@Injectable()
export class ModalService {
  constructor(private bsModalService: BsModalService, private backDropService: ModalBackdropService) {
  }
  // public hidemodal: EventEmitter<any> = new EventEmitter();
  public modalRefArr : Array<ModalWrapperComponent<any>> = [];
  public close: EventEmitter<any> = new EventEmitter();
  modalRef;
  /**
   * show
   */
  public show(content: any, config?: ModalOptions): ICloseable {
    this.modalRef = this.getModalWrapper(content);
    this.modalRefArr.push(this.modalRef);

    const modalContent = this.modalRef.show(config);
    modalContent?.close?.subscribe((x) => {
      this.modalRefArr.pop();
    });
    return modalContent;
  }
  public hideAllModal() {
    forEach(this.modalRefArr, (modalRef) => modalRef.hide({}))
    this.modalRefArr = [];
  }
  getModalWrapper<TComponent>(component: TComponent): ModalWrapperComponent<TComponent> {
    return new ModalWrapperComponent<TComponent>(component, this.bsModalService, this.backDropService);
  }
}

