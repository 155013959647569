import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { RuntimeConstants } from '../constants/runtime-constants';
import { SettingParam } from '../interface/setting-param';
import { ApplicationStateService } from './application-state.service';
import { EventBroadcastingService } from './event-broadcasting.service';

@Injectable({
  providedIn: 'root'
})
export class ReloadSettingsService {

  terminalApiPath = `${RuntimeConstants.API_PATH}terminals/`;
  constructor(private httpService: HttpClient,
    private applicationStateService: ApplicationStateService,
    private eventBroadcastingService: EventBroadcastingService) { }

  reloadSettings(terminalId: number): Observable<SettingParam> {
    return this.httpService.get(`${this.terminalApiPath}` + terminalId + `/ReloadSettings`)
      .pipe((map((response: SettingParam) => {
        if (response) {
          this.applicationStateService.settingParam = response;
          this.applicationStateService.setLocalizationSettings(this.applicationStateService.settingParam);
          this.eventBroadcastingService.reloadSettingsEvent();
        }
        return response;
      })));
  }
}
