import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  AlertsService,
  SpinnerService,
  Messages,
  ConfirmDeleteComponent,
  FieldInfoMessages,
  ModalService,
  NumpadWrapperComponent
} from 'src/app/shared';
import { SlideShowService } from '../../services';
import { SlideShow, SlideShowDetails } from '../../interface';
import { NgForm } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import * as _ from 'lodash';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ActivatedRoute, Router } from '@angular/router';
import { deleteWhite , solidVideo } from 'src/app/shared/components/icon';
import { digitalSignageBullHorn } from 'src/app/shared/components/icon/icons';

@Component({
  selector: 'pos-slide-show-image',
  templateUrl: './slide-show-image.component.html',
  styleUrls: ['./slide-show-image.component.scss', '../../../../../styles/pages/manage/slide-show-image.scss']
})
export class SlideShowImageComponent implements OnInit {

  @ViewChild('formImage') formImage: NgForm;
  @ViewChild('confirmDeleteOptionSlide') private confirmDeleteOptionSlide: ConfirmDeleteComponent;
  @ViewChild('imageFiles', { static: true }) imageFiles: ElementRef;
  id = 0;
  slideshowDetail: SlideShow;
  selectedFiles: FileList;
  slideshowImageToAdd: SlideShowDetails;
  slideshowImages: Array<SlideShowDetails> = [];
  selectedSlideshowImage: SlideShowDetails;
  slideshowImageId: number;
  slideshowImageBaseUrl: string;
  mediaTypes = {
    image: ['.png', '.jpg', '.jpeg', '.gif', '.bmp'],
    video: ['.mp4', '.mpg', '.avi']
  };
  sortableOpts = {
    containment: '.sortable-container'
  };
  fieldInfoMessages = FieldInfoMessages;
  icons = {
    deleteWhite,
    digitalSignageBullHorn,
    solidVideo
  };
  dragIndex = null;
  dropIndex = null;

  constructor(private route: ActivatedRoute,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected slideShowService: SlideShowService,
    private router: Router,
    private modalService: ModalService,) {
    this.id = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
    this.scrollToTop();
  }

  ngOnInit() {
    this.slideshowImageToAdd = this.slideShowService.newSlideShowImage();
    this.slideshowDetail = this.slideShowService.newSlideShow();
    this.loadImagesData();
  }

  loadImagesData() {
    this.slideshowImageBaseUrl = this.slideShowService.slideshowImageBaseUrl;
    if (this.id > 0) {
      this.spinnerService.show();
      this.slideShowService.getSlideshow(this.id)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: response => {
            this.slideshowDetail = response;
          }, error: this.alertService.showApiError
        });
      this.loadImages();
    }
    this.resetFileControl();
  }

  loadImages() {
    this.spinnerService.show();
    return this.slideShowService.getSlideshowImages(this.id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: response => {
          response.forEach(element => {
            element.MediaType = this.getMediaType(element);
            const FileName = element.ImagePath.substring(element.ImagePath.indexOf('-') + 1, element.ImagePath.lastIndexOf('.'));
            element.FileNameWithText = FileName.substring(0, 15) + (FileName.length > 15 ? '...' : '') +
              element.ImagePath.substring(element.ImagePath.lastIndexOf('.'));
          });
          this.slideshowImages = response;
        }, error: this.alertService.showApiError
      });
  }

  getMediaType(slideshowImage) {
    const extension = slideshowImage.ImagePath.substring(slideshowImage.ImagePath.lastIndexOf('.'));
    for (const mediaType in this.mediaTypes) {
      if (this.mediaTypes[mediaType].indexOf(extension) >= 0) {
        return mediaType;
      }
    }
    return null;
  }

  getAll() {
    const mediaTypes = [];
    for (const mediaType in this.mediaTypes) {
      if (typeof (mediaType) !== 'function') {
        mediaTypes.push.apply(mediaTypes, this.mediaTypes[mediaType]);
      }
    }
    return mediaTypes.join(',');
  }

  resetFileControl() {
    this.selectedFiles = null;
    this.imageFiles.nativeElement.value = null;
  }

  filesSelected(event) {
    this.selectedFiles = event.target.files;
  }

  private scrollToTop(): void {
    window.scrollTo(0, 0);
  }

  addImage(slideshowImage: SlideShowDetails, files: FileList) {
    if (!slideshowImage.HoldSeconds || slideshowImage.HoldSeconds < 0) {
      return;
    }
    if (!this.selectedFiles) {
      this.alertService.renderErrorMessage(Messages.SelectAtLeastOneFile);
      return;
    }
    if (files && files.length > 0) {
      this.spinnerService.show();
      const newSlideShowImages = [];
      Array.from(files).forEach(file => {
        newSlideShowImages.push(this.slideShowService.addSlideshowImage(this.id, slideshowImage, file));
      });

      forkJoin(newSlideShowImages).pipe(finalize(() => {
        this.spinnerService.hide();
      }))
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (response) => {
            this.alertService.renderSuccessMessage(Messages.MediaAddedToSlideshow);
            this.slideshowImageToAdd = this.slideShowService.newSlideShowImage();
            this.loadImages();
            this.resetFileControl();
          }, error: this.alertService.showApiError
        });
    }
  }

  deleteImage(slideshowImage) {
    this.slideshowImageId = slideshowImage.Id;
    this.confirmDeleteOptionSlide.Show(Messages.ConfirmDeleteSlideshowImage);
  }

  onConfirmAcknowledgeSlide() {
    this.spinnerService.show();
    this.slideShowService.deleteSlideshowImage(this.id, this.slideshowImageId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          this.alertService.renderSuccessMessage(Messages.SlideshowImageDeletedSuccessMessage);
          this.loadImages();
        }, error: this.alertService.showApiError
      });
  }

  updateImage(slideshowImage) {
    this.spinnerService.show();
    this.slideShowService.updateSlideshowImage(this.id, slideshowImage)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: response => {
          this.alertService.renderSuccessMessage(Messages.SlideshowImageParameterUpdateSuccessMessage);
        }, error: this.alertService.showApiError
      });
  }

  changeHoldSecond(slideshowImage: SlideShowDetails) {
    this.selectedSlideshowImage = slideshowImage;
    const modalRef = this.modalService.getModalWrapper(NumpadWrapperComponent);
    modalRef.show({
      animated: false,
      class: 'vertical-center',
      initialState: {
        value: slideshowImage.HoldSeconds,
        numpadId: 'holdSecondsNumpad',
        numpadOption: {
          allowDecimal: false,
          doubleZero: false
        },
        numpadTitle: 'Hold time in seconds',
        onSubmit: (res) => {
          this.onNumpadSubmit(res);
          modalRef.hide();
        },
        onCancel: () => {
          modalRef.hide();
        }
      }
    });
  }

  onNumpadSubmit(value) {
    this.spinnerService.show();
    const originalValue = this.selectedSlideshowImage.HoldSeconds;
    if (originalValue !== value) {
      this.selectedSlideshowImage.HoldSeconds = value.value;

      this.slideShowService.updateSlideshowImage(this.id, this.selectedSlideshowImage)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: response => { },
          error: (error) => {
            // Revert to original
            this.selectedSlideshowImage.HoldSeconds = originalValue;
            this.alertService.showApiError(error);
          }
        });
    }
  }

  dragEntered(event) {
    const drag = event.item;
    const dropList = event.container;
    this.dragIndex = drag.data;
    this.dropIndex = dropList.data;

    const phContainer = dropList.element.nativeElement;
    const phElement = phContainer.querySelector('.cdk-drag-placeholder');
    phContainer.removeChild(phElement);
    phContainer.parentElement.insertBefore(phElement, phContainer);

    moveItemInArray(this.slideshowImages, this.dragIndex, this.dropIndex);
  }

  ordinalChanged(event) {
    this.slideShowService.changeSlideshowImageOrdinal(this.id, this.slideshowImages[this.dropIndex].Id, this.dropIndex + 1)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe(response => { },
        (error) => {
          moveItemInArray(this.slideshowImages, this.dropIndex, this.dragIndex);
          this.alertService.showApiError(error);
        });
  }

  cancelEditing() {
    this.router.navigate(['digital-signage'], { relativeTo: this.route.parent })
  }
}
