import { Component, Input } from '@angular/core';
import { KioskScreen } from '../../interfaces';
import { AlertsService, DomainConstants, InventoryProductDietaryWarningService, ModalComponent, OrderKioskScreenService, SpinnerService } from 'src/app/shared';
import { Terminal, TerminalsService } from 'src/app/configurator';
import { Observable, finalize, forkJoin } from 'rxjs';
import { cloneDeep, find, forEach } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { KioskNavigationService } from '../../services/kiosk-navigation.service';
import { DietaryWarning } from 'src/app/information-management';

@Component({
  selector: "pos-order-kiosk-preview",
  templateUrl: "./order-kiosk-preview.component.html",
  styleUrls: ["./order-kiosk-preview.component.scss"],
})
export class OrderKioskPreviewComponent extends ModalComponent {
  @Input() kioskScreenId: number;
  kioskScreen: KioskScreen;
  kioskScreens: Array<KioskScreen> = [];
  terminals: Terminal[] = [];
  terminalId = null;
  constructor(
    private terminalService: TerminalsService,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private kioskScreenService: OrderKioskScreenService,
    private kioskNavigationService: KioskNavigationService,
    private inventoryProductDietaryWarningService: InventoryProductDietaryWarningService,
    modalRef: BsModalRef
  ) {
    super(modalRef);
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    const screenObservables: Observable<any>[] = [];
    screenObservables.push(this.terminalService.getAll());
    screenObservables.push(this.kioskScreenService.getAllScreenWithNavigation());
    screenObservables.push(this.inventoryProductDietaryWarningService.getDietaryWarnings());
    this.spinnerService.show();
    forkJoin(screenObservables)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe({
        next: (res: [Terminal[], Array<KioskScreen>, Array<DietaryWarning>]) => {
          this.terminals = res[0]
            .filter((x) => x.TerminalType == DomainConstants.TerminalTypes.ORDER_KIOSK.Name)
            .sort((a, b) => a.TerminalName.localeCompare(b.TerminalName));
          this.kioskScreens = cloneDeep(res[1] ?? []);
          const currentScreen = find(res[1], (screen: KioskScreen) => screen.Id == this.kioskScreenId);
          if (currentScreen) {
            this.kioskScreen = cloneDeep(currentScreen);
          }
          this.kioskNavigationService.initializeNavigationService(res[1] ?? [], this.kioskScreenId);
          if (res[2]?.length) {
            this.kioskNavigationService.allDietaryWarnings = res[2];
          }
        },
        error: this.alertService.showApiError,
      });
  }

  setResolutionByTerminal(terminalId: number) {
    if (!terminalId) {
      document.documentElement.style.setProperty('--kiosk-screen-preview-height', null);
      document.documentElement.style.setProperty('--kiosk-screen-preview-width', null);
      window.dispatchEvent(new Event('resize'));
      return;
    }
    this.spinnerService.show();
    this.terminalService
      .getTerminalProperties(terminalId)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe({
        next: (response) => {
          const terminalProperties = response ?? [];
          let terminalHeight: string = null;
          let terminalWidth: string = null;
          forEach(terminalProperties, (property) => {
            if (property.PropertyKey === DomainConstants.TerminalProperties.SCREEN_WIDTH.Key) {
              terminalWidth = parseInt(property.PropertyValue, 10) + 'px';
            } else if (property.PropertyKey === DomainConstants.TerminalProperties.SCREEN_HEIGHT.Key) {
              terminalHeight = parseInt(property.PropertyValue, 10) + 'px';
            }
          });

          document.documentElement.style.setProperty('--kiosk-screen-preview-height', terminalHeight);
          document.documentElement.style.setProperty('--kiosk-screen-preview-width', terminalWidth);
          window.dispatchEvent(new Event('resize'));
        },
        error: this.alertService.showApiError,
      });
  }

  onClose() {
    const currentScreen = this.kioskScreens.find(x => x.Id == this.kioskScreenId);
    this.hide(currentScreen);
  }
}
