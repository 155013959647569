import { DatePipe } from '@angular/common';
import { Inject, Pipe, PipeTransform } from '@angular/core';
import { TarkLocalProvider, LOCAL_PROVIDER } from '../service/tark-local-provider.service';

@Pipe({
  name: 'tark_date_time'
})
export class TarkDateTimePipe implements PipeTransform {

  dateFormat: string = null;
  timeFormat: string = null;
  dateTimeFormat: string = null;

  constructor(@Inject(LOCAL_PROVIDER) private tarkLocalProvider: TarkLocalProvider,
    private datePipe: DatePipe) {
      this.dateFormat = this.tarkLocalProvider.getDateFormat();
      this.timeFormat = this.tarkLocalProvider.getTimeFormat();
  }

  transform(value: number, showSeconds = false): string | null {
    this.dateTimeFormat = (this.dateFormat ?? 'MM-dd-yyyy') + ' ' + (this.timeFormat ? this.timeFormat : 'hh:mm a');
    this.dateTimeFormat = showSeconds ? this.addSeconds() : this.dateTimeFormat;
    return this.datePipe.transform(value, this.dateTimeFormat, null, this.tarkLocalProvider.getLocale());
  }

  addSeconds() {
    const format = this.dateTimeFormat.includes('mm') ? (this.dateTimeFormat.substring(0, this.dateTimeFormat.indexOf('mm') + 2) + ':ss' +
      this.dateTimeFormat.substring(this.dateTimeFormat.indexOf('mm') + 2)) : this.dateTimeFormat;
    return format;
  }

}
