import { Component, ContentChild, Input } from '@angular/core';
import { forEach } from 'lodash';
import { InputControlDirective } from '../directives';

@Component({
  selector: 'pos-form-field',
  templateUrl: './form-field.component.html',
})
export class FormFieldComponent {

  @Input() labelStyle: string | string[] | Set<string> | { [klass: string]: any; } = '';
  @Input() inputStyle: string | string[] | Set<string> | { [klass: string]: any; } = '';
  @Input() label = '';
  @Input() fieldInfo = '';
  @Input() fieldInfoObject: {info: string, image: string} = null;
  @Input() validationMessages: {[key: string]: string} = {};
  @Input() appendPaddingTop = true;
  @Input() hasWarning: boolean = false;
  @Input() warning: string  = '';

  @Input() fieldInfoPlacement: string = 'top';

  // default label and input control class
  props = {
    labelClass: 'col-12',
    controlBoxClass: 'col-lg-6 col-md-6 col-sm-6 col-12'
  }
  @ContentChild(InputControlDirective, {descendants: true}) _formFieldControl: InputControlDirective;

  /** Gets the current form field control */
  get control(): InputControlDirective {
    return this._formFieldControl;
  }

  getValidationMessage() {
      const keys = Object.keys(this.validationMessages);
      let validationMessage = '';
      forEach(keys, key => {
        if(this.control?.errors?.[key]) {
          validationMessage = this.validationMessages[key];
          return false;
        }
      })
      return validationMessage;
  }
}
