import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { RuntimeConstants } from 'src/app/shared';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';
import { MenuDisplay, MenuDisplayConfig } from '../interfaces/menu-display';
import { MenuWidget } from '../interfaces/menu-widget';

@Injectable({
    providedIn: 'root'
})
export class MenuDisplayService extends BaseCrudService<MenuDisplay> {
    constructor(private httpClient: HttpClient) {
        super('menu-display', httpClient);
    }

    newMenuDisplay(): MenuDisplay {
        return {
            Id: 0,
            Name: '',
            Height: null,
            Width: null,
            Orientation: false,
            Config: ''
        };
    }

    newMenuDisplayConfig(): MenuDisplayConfig {
        return {
            BackgroundColor: '',
        }
    }

    getMenuWidgets(menuId: number): Observable<Array<MenuWidget>> {
        return this.httpService.get<Array<MenuWidget>>(
            `${this.apiEndpoint}/${menuId}/widgets`
        );
    }

    getMenuWidgetsOfType(widgetType: string): Observable<Array<MenuWidget>> {
        return this.httpService.get<Array<MenuWidget>>(`${this.apiEndpoint}/widgets/type/${widgetType}`);
    }

    getMenuWidget(widgetId: number): Observable<MenuWidget> {
        return this.httpService.get<MenuWidget>(
            `${this.apiEndpoint}/${widgetId}/widget`
        );
    }

    saveUpdateMenuWidgets(menuId: number, menuWidgets: Array<MenuWidget>): Observable<Array<any>> {
        return this.httpService.post<Array<any>>(
            `${this.apiEndpoint}/${menuId}/widgets`,
            menuWidgets
        );
    }

    saveMenuWidget(menuWidget: MenuWidget): Observable<MenuWidget> {
        return this.httpService.post<MenuWidget>(`${RuntimeConstants.API_PATH}menu-widget/add`, menuWidget);
    }
}
