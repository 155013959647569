import { Component, OnInit, ViewChild } from '@angular/core';
import { EquipmentType } from '../../interface/equipment-type';
import { NgForm } from '@angular/forms';
import { BaseCrudComponent, equipmentPropertiesDolly } from 'src/app/shared/components';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { EquipmentTypeService } from '../../service/equipment-type.service';
import { finalize } from 'rxjs/operators';
import { EquipmentPropertyService } from 'src/app/information-management/equipment-properties/service/equipment-property.service';
import { EquipmentProperty } from 'src/app/information-management/equipment-properties/interface';
import { FormUtilityService } from 'src/app/shared/services/form-utility.service';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { ActivatedRoute, Router } from '@angular/router';
declare let $: any;

@Component({
    selector: 'pos-equipment-type-edit',
    templateUrl: './equipment-type-edit.component.html',
})
export class EquipmentTypeEditComponent extends BaseCrudComponent<EquipmentType> implements OnInit {

    equipmentType: EquipmentType;
    id = 0;
    @ViewChild('equipmentTypeForm') equipmentTypeForm: NgForm;
    equipmentPropertyList: Array<EquipmentProperty>;
    selectedEqipmentProperties: Array<EquipmentProperty> = [];
    height = 300;
    get getForm(): NgForm {
        return this.equipmentTypeForm
    }

    icons = {
        equipmentPropertiesDolly
    }
    constructor(
        protected alertService: AlertsService,
        protected spinnerService: SpinnerService,
        private route: ActivatedRoute,
        private router: Router,
        protected equipmentTypeService: EquipmentTypeService,
        protected equipmentPropertyService: EquipmentPropertyService,
        formUtilityService: FormUtilityService
    ) {
        super(equipmentTypeService, alertService, spinnerService, formUtilityService);
        this.id = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
    }

    ngOnInit() {
        this.equipmentType = this.equipmentTypeService.newEquipmentType();
        this.saveSuccessMessage = Messages.EquipmentTypeSaveSuccess;
        this.dataSubscription();
        this.setHeight();
        $(window).resize(() => {
            this.setHeight();
        });
    }

    setHeight() {
        this.height = ($(window).height() - 450) < 150 ? 100 : ($(window).height() - 450);
    }
    submit(isValid: boolean): void {

        if (!isValid) {
            return;
        }
        this.equipmentTypeService.getNameExists(this.equipmentType.Id, this.equipmentType.Name)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            })).subscribe({
                next: (response) => {
                    if (response) {
                        this.alertService.renderErrorMessage('Equipment type name already exists.');
                    } else {
                        this.save(this.equipmentType);
                    }
                }
            });
    }

    private dataSubscription(): void {
        if (this.id > 0) {
            this.spinnerService.show();
            this.loadData().pipe(finalize(() => {
                this.spinnerService.hide();
            }))
                .subscribe({
                    next: (res) => {
                        this.equipmentType = res;
                        this.setEquipmentType(res);
                    }, error: this.alertService.showApiError
                });
        }
    }

    setEquipmentType(equipmentType: EquipmentType) {
        const eqipmentProperties = [];
        equipmentType.EquipmentTypeProperties.forEach((data) => {
            eqipmentProperties.push(data.EquipmentProperty)
            data.EquipmentPropertyName = data.EquipmentProperty.Name;
        });
        this.selectedEqipmentProperties = [...eqipmentProperties];
    }

    onChangeProperties() {
        const selectedProperty = []
        this.selectedEqipmentProperties.forEach((data) => {
            const equipmentProperty = this.equipmentType?.EquipmentTypeProperties?.find(x => x.EquipmentPropertyId == data.Id);
            if (equipmentProperty) {
                selectedProperty.push(equipmentProperty);
            } else {
                selectedProperty.push(this.equipmentPropertyService.newEquipmentTypeProperty(this.equipmentType.Id, data.Id, data.Name));
            }
        });
        this.equipmentType.EquipmentTypeProperties = [...selectedProperty];
    }

    public loadDependencies(): void {
        this.spinnerService.show();
        this.equipmentPropertyService.getAll().pipe(finalize(() => {
            this.spinnerService.hide()
        })).subscribe({
            next: (response) => {
                this.equipmentPropertyList = response;
            }
        });
    }

    public onSaveSuccess(model: EquipmentType): void {
        this.cancelEditing();
    }

    cancelEditing(): void {
        this.router.navigate(['equipment-types'], { relativeTo: this.route.parent });
    }
}
