import { Component, OnInit, Input } from '@angular/core';
import { InventoryZoneService } from 'src/app/shared/services/inventory-zone.service';
import { AlertsService } from 'src/app/shared';
import { InventoryInfoModel } from '../../../interface';

@Component({
  selector: 'pos-inventory-products-for-bin',
  templateUrl: './inventory-products-for-bin.component.html',
  styleUrls: ['./inventory-products-for-bin.component.scss']
})
export class InventoryProductsForBinComponent implements OnInit {

  @Input() inventoryZoneBinId: number;
  inventoryInfoModelList: Array<InventoryInfoModel> = [];
  isLoad = false;

  constructor(private inventoryZoneService: InventoryZoneService,
    private alertService: AlertsService) { }

  ngOnInit() {
    this.getDetails();
  }

  getDetails() {
    this.isLoad = true;
    this.inventoryZoneService.getInventoryProductsForBin(this.inventoryZoneBinId)
      .subscribe(response => {
        this.inventoryInfoModelList = response  == null ? [] : response;
      },
        (error) => { this.alertService.showApiError(error); },
        () => { this.isLoad = false; });
  }
}
