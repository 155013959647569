import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AlertsService, BaseFormComponent, CameraAnnotationService, FormUtilityService, Messages, SpinnerService } from 'src/app/shared';
import { CameraAnnotations } from 'src/app/shared/interface/camera-annotations';
import { finalize } from 'rxjs/operators';
import { camera, messageCode } from 'src/app/shared/components/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { PostSavedConfigurationService } from 'src/app/configurator/post-saved-configuration.service';

@Component({
  selector: 'pos-camera-annotation-edit',
  templateUrl: './camera-annotation-edit.component.html',
  styleUrls: ['./camera-annotation-edit.component.scss'],
})
export class CameraAnnotationEditComponent extends BaseFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.form
  }
  @ViewChild('cameraAnnotationsForm', { static: true }) form: NgForm;
  id: number;
  cameraAnnotation: CameraAnnotations;
  cursorPosition = 0;
  isOpenVariable = false;
  selectedTokenAttribute: string;
  tokens = [];
  icons = {
    messageCode,
    camera
  };
  codeMirrorOptions = {
    mode: 'htmlmixed',
    lineNumbers: true,
    lineWrapping: true
  };
  constructor(
    private cameraAnnotationService: CameraAnnotationService,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private router: Router,
    private postSavedConfigurationService: PostSavedConfigurationService,
    private route: ActivatedRoute,
    formUtilityService: FormUtilityService
  ) {
    super(formUtilityService);
    this.id = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
    if (!this.id) {
      this.cancelEditing();
    }
  }

  ngOnInit(): void {
    this.cameraAnnotation = this.cameraAnnotationService.getNewCameraAnnotation();
    this.loadData();
  }

  loadData() {
    this.spinnerService.show();
    this.cameraAnnotationService.getCameraAnnotationById(this.id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: CameraAnnotations) => {
          this.cameraAnnotation = res;
          this.cameraAnnotation.Annotation = this.cameraAnnotation.Annotation.replace(/\\n/g, "\n");
          this.tokens = this.cameraAnnotation.Tokens.split(',').sort((a, b) => a.localeCompare(b));
          this.cursorPosition = this.cameraAnnotation.Annotation.length;
          this.onFormChange(this.form);
        }, error: this.alertService.showApiError
      });
  }

  cancelEditing() {
    this.router.navigate(['camera-annotation'], { relativeTo: this.route.parent });
  }

  saveCameraAnnotation(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.spinnerService.show();
    this.cameraAnnotationService.updateCameraAnnotation(this.cameraAnnotation)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: _x => {
          this.cancelEditing();
          this.alertService.renderSuccessMessage(Messages.CameraAnnotationSaveSuccess);
          this.postSavedConfigurationService.showMessage();
        }, error: this.alertService.showApiError
      });
  }

  changeTokenAttribute() {
    this.isOpenVariable = false;
    this.cameraAnnotation.Annotation =
      this.cameraAnnotation.Annotation.substring(0, this.cursorPosition) + ' {' + this.selectedTokenAttribute + '} '
      + this.cameraAnnotation.Annotation.substring(this.cursorPosition);
    this.cursorPosition += this.selectedTokenAttribute.length + 3;
    this.selectedTokenAttribute = null;
  }

  getCaretPos(text) {
    if (text.selectionStart || text.selectionStart === '0') {
      this.cursorPosition = text.selectionStart;
    }
  }

  onFormChange($event: NgForm) {
    this.form = $event;
    this.setInitialValue();
  }

}
