import { Injectable } from '@angular/core';
import { BaseCrudService, RuntimeConstants } from 'src/app/shared';
import { LoginMessageModel } from '../interface/login-message-model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class LoginMessagesService  extends BaseCrudService<LoginMessageModel>  {
    loginmessageApiBasePath = '';

    constructor(httpService: HttpClient) {
        super('loginmessage', httpService);
        this.loginmessageApiBasePath = RuntimeConstants.API_PATH + 'loginmessage';
    }

    newLoginMessage() {
        const loginMessageModel: LoginMessageModel = {
            Id: 0,
            message: ''
        };
        return loginMessageModel;
    }
}
