<div class="row mx-0">
<div class="col-3 row mx-0 flex-direction-column justify-content-end-flex">
    <div class="col-12 px-0 pad-top-currency-btn">
        <div class="btn-cal" [ngClass]="{'disablePaymentButton btn-disabled':isDisablePaymentButton}"
            (click)="numpad.addValueFn(5)">{{settingParam.CurrencySymbol}}5
        </div>
    </div>
    <div class="col-12 px-0 pt-pix-15">
        <div class="btn-cal" [ngClass]="{'disablePaymentButton btn-disabled':isDisablePaymentButton}"
            (click)="numpad.addValueFn(10)">{{settingParam.CurrencySymbol}}10
        </div>
    </div>
    <div class="col-12 px-0 pt-pix-15">
        <div class="btn-cal" [ngClass]="{'disablePaymentButton btn-disabled':isDisablePaymentButton}"
            (click)="numpad.addValueFn(20)">{{settingParam.CurrencySymbol}}20
        </div>
    </div>
</div>
<div class="col-9">
    <label class="bold font-16 float-right mt-pix-n10 mb-pix-n15" [hidden]="!isShowCashTender">{{cashTendered | tark_currency}}
    </label>
    <pos-numpad #numpad [numpadId]="'settlePayment'" [onCancel]="cancelPaymentPrice" [onSubmit]="submitCashPayment"
        [onOptionChange]="numpadOptionChange" [options]="numpadOptions" [value]="enteredAmount">
    </pos-numpad>
</div>
<div class="col-3 p-0">
</div>
<div class="col-12 p-0 row mx-0">
    <div class="col-12 row mx-0" [ngClass]="{'ps-0': isAllowCreditCard}">
        <div class="col-4 pt-pix-15" *ngIf="isAllowCreditCard">
            <div class="btn-cal btn-cal-green btn-gift vertical-center-align" [ngStyle]="{'background-color':tenderTypeColors.CreditCard}"
                [ngClass]="{'btn-disabled disablePaymentButton':isDisablePaymentButton}" (click)="makeCreditCardPayment()">
                Credit Card</div>
        </div>
        <div class="col-8 px-0 pt-pix-15 row mx-0">
            <div class="ps-0"
                [ngClass]="{'col-4': settingParam.AllowEBTPaymentType, 'col-6':!settingParam.AllowEBTPaymentType}">
                <div class="btn-cal btn-cal-green btn-gift vertical-center-align" [ngStyle]="{'background-color':tenderTypeColors.GiftCard}"
                    [ngClass]="{'disablePaymentButton btn-disabled':isDisablePaymentButton}" (click)="makeGiftCardPayment()"
                    *ngIf="settingParam.CashDrawerPrinter.Id">Gift Card</div>
            </div>
            <div [ngClass]="{'col-4 ps-0':settingParam.AllowEBTPaymentType}" *ngIf="settingParam.AllowEBTPaymentType">
                <pos-ebt-button [canModifyAmount]="!isDisablePaymentButton" [ebtTenderTypeColor]="tenderTypeColors.EBT"
                    (makePaymentEBT)="makeEBTPayment()">
                </pos-ebt-button>
            </div>
            <div class="p-0" *ngIf="!(paymentButtonText==='Close' || paymentButtonText==='Submit')"
                [ngClass]="{'col-4':settingParam.AllowEBTPaymentType,'col-6':!settingParam.AllowEBTPaymentType}">
                <div class="btn-cal btn-cal-green btn-gift vertical-center-align" (click)="submitCashPayment(numpadValue)" [ngStyle]="{'background-color':tenderTypeColors.Cash}"
                    [ngClass]="{'disablePaymentButton btn-disabled': isSubmitPayment, 'disablePaymentButton btn-disabled':isDisablePaymentButton}"
                    *ngIf="settingParam.CashDrawerPrinter.Id"
                    [hidden]="(paymentButtonText==='Close' || paymentButtonText==='Submit')">Cash
                </div>
            </div>
            <div class="p-0"
                [ngClass]="{'col-4':settingParam.AllowEBTPaymentType,'col-6':!settingParam.AllowEBTPaymentType}">
                <div class="btn-cal btn-cal-green btn-gift vertical-center-align" (click)="makeCashPayment()"
                    [ngClass]="{'disablePaymentButton btn-disabled': isSubmitPayment}"
                    *ngIf="settingParam.CashDrawerPrinter.Id && isShowSubmitButton"
                    [hidden]="!(paymentButtonText==='Close' || paymentButtonText==='Submit')">{{paymentButtonText}}
                </div>
                </div>
            </div>
        </div>
    </div>
</div>
