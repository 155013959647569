import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LicenseInfo } from 'src/app/shared';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { Observable } from 'rxjs';

@Injectable()
export class LicenseService {

    licenseApiBasePath = `${RuntimeConstants.API_PATH}license`;

    constructor(private httpService: HttpClient) {}

    getLicenseInfo(TerminalId: number): Observable<LicenseInfo> {
        return this.httpService.get<LicenseInfo>(this.licenseApiBasePath + '/' + TerminalId);
    }

    initializeApplication(): Observable<LicenseInfo> {
        return this.httpService.get<LicenseInfo>(this.licenseApiBasePath + '/InitializeApplication');
    }

    refreshLicense() {
        return this.httpService.post(this.licenseApiBasePath + '/refresh', null);
    }

    saveLicense(params): Observable<LicenseInfo> {
        return this.httpService.post<LicenseInfo>(this.licenseApiBasePath + '/', params);
    }

}
