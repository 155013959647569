import { Component, Input, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import {
  AlertsService, ChartWidgetConfiguration, DashboardConfigurationService, SpinnerService,
  DomainConstants,
  ApplicationStateService
} from 'src/app/shared';
import { filter } from 'lodash';
import { WidgetService } from 'src/app/adf/services';
import { DashboardDataSourceModel } from 'src/app/adf/interfaces';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'pos-chart-widget-edit',
  templateUrl: './chart-widget-edit.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ChartWidgetEditComponent implements OnInit {

  @Input() widgetConfig: any;
  datasources: Array<DashboardDataSourceModel> = [];
  config: ChartWidgetConfiguration;
  chartTypes = DomainConstants.DashboardWidgetSelectOptions.ChartTypes;
  dateFormat = 'mm-dd-yyyy';

  constructor(private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private widgetsService: WidgetService,
    protected applicationStateService: ApplicationStateService,
    private configurationService: DashboardConfigurationService) { }

  ngOnInit(): void {
    this.initializeDatasources();
    this.dateFormat = this.applicationStateService.settingParam.PCalendarDateFormat;
    this.config = this.widgetConfig.config;
    const firstChartTypeValue = this.chartTypes.length ? this.chartTypes[0].value : null;
    this.config.chartType = this.config.chartType ? this.config.chartType : firstChartTypeValue;
  }

  initializeDatasources() {
    this.spinnerService.show();
    this.widgetsService.getDashboardDatasources()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.getDatasourcesCompleted(res);
        }, error: this.alertsService.showApiError
      });
  }

  getDatasourcesCompleted(response) {
    this.datasources = this.configurationService.filterDatasources(response, this.widgetConfig.AllowedInputTypes);
    if (this.datasources.length > 0) {
      if (!this.widgetConfig.DatasourceId) {
        this.widgetConfig.DatasourceId = this.datasources[0].Id;
      }

      // no need to load datasource param until there are already params into the config.
      if (!this.widgetConfig.params || this.widgetConfig.params.length === 0) {
        this.populateDatasourceParams();
      }
    }
  }

  populateDatasourceParams() {
    if (this.widgetConfig.DatasourceId) {
      this.spinnerService.show();
      this.widgetConfig.params = [];
      const filteredDatasources = filter(this.datasources, { Id: this.widgetConfig.DatasourceId });
      this.config.sourceType = filteredDatasources.length > 0 ? filteredDatasources[0].SourceType : this.datasources[0].SourceType;
      this.config.outputType = filteredDatasources.length > 0 ? filteredDatasources[0].OutputType : this.datasources[0].OutputType;
      this.widgetConfig.WidgetAssignmentId = this.widgetConfig.WidgetAssignmentId ? this.widgetConfig.WidgetAssignmentId : 0;
      this.widgetsService.getDashboardWidgetDatasourceParameters(this.widgetConfig.WidgetAssignmentId, this.widgetConfig.DatasourceId)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (response) => {
            this.widgetConfig.params = response;
          }, error: this.alertsService.showApiError
        });
    }
  }

}
