<div class="row mx-0">
    <div class="col-sm-6 col-xs-12">
        <pos-form-field label="Metrics Name" inputStyle="col-md-8 col-12">
            <input posInput class="form-control" name="metricsName" [(ngModel)]="config.metricsName" type="text" />
        </pos-form-field>
    </div>

    <div class="col-sm-6 col-xs-12">
        <pos-form-field label="Datasource" inputStyle="col-md-9 col-12">
            <ng-select posInput [items]="datasources" appendTo="body" bindLabel="Name" bindValue="Id"
                (change)="populateDatasourceParams()" [(ngModel)]="widgetConfig.DatasourceId" name="datasource"
                [clearable]="false" [clearOnBackspace]="false">
            </ng-select>
        </pos-form-field>
    </div>

    <div class="col-sm-6 col-xs-12">
        <pos-form-field label="Chart Type">
            <ng-select posInput [items]="chartTypes" appendTo="body" bindLabel="label" bindValue="value"
                [(ngModel)]="config.chartType" name="chartType" [clearable]="false" [clearOnBackspace]="false">
            </ng-select>
        </pos-form-field>
    </div>

    <div *ngFor="let param of config.params | dataFilter:{'IsUserDefined': true}" class="col-sm-6 col-xs-12">
        <pos-form-field [label]="param.ParameterLabel">
            <input posInput
                *ngIf="param.DataType === 'String'|| param.DataType === 'DateTime' || param.DataType === 'Int' || !param.DataType"
                class="form-control" name="param.ParameterName" [(ngModel)]="param.ParameterValue"
                placeholder="{{param.DataType === 'DateTime' ? dateFormat : ''}}" type="text" />
            <ng-select posInput [items]="options" appendTo="body" bindLabel="label" bindValue="value"
                [(ngModel)]="param.ParameterValue" name="options"
                *ngIf="param.DataType === 'DayRange' || param.DataType === 'PeriodRange' || param.DataType === 'ExchangeNames' || param.DataType === 'ExchangeTypes' || param.DataType === 'ExchangeRoutes'">
            </ng-select>
            <p-checkbox posInput *ngIf="param.DataType === 'Bool'" name="param.ParameterName"
                #param.ParameterName="ngModel" [(ngModel)]="param.ParameterValue" binary="true">
            </p-checkbox>
        </pos-form-field>
    </div>

    <div class="col-sm-6 col-xs-12">
        <pos-form-field label="Color">
            <pos-color-picker posInput cpPosition="top" name="color" [(ngModel)]="config.color">
            </pos-color-picker>
        </pos-form-field>
    </div>

    <div class="col-sm-6 col-xs-12">
        <pos-form-field label="Background">
            <pos-color-picker posInput cpPosition="top" name="background" [(ngModel)]="config.bgColor">
            </pos-color-picker>
        </pos-form-field>
    </div>

    <div class="col-sm-6 col-xs-12">
        <pos-form-field label="Border">
            <pos-color-picker posInput cpPosition="top" name="borderColor" [(ngModel)]="config.borderColor">
            </pos-color-picker>
        </pos-form-field>
    </div>
</div>
