import { ExternalApi, ExternalApiParameter } from './../../interface/external-api';
import { AlertsService } from './../../../../shared/services/alerts.service';
import { ExternalApiService } from '../../../../shared/services/external-api.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SpinnerService } from 'src/app/shared';
import * as _ from 'lodash';
import { ButtonFunctionParametersModel } from 'src/app/information-management/buttons/interface/button';
import { ControlContainer, NgForm } from '@angular/forms';
@Component({
  selector: 'pos-external-api-view',
  templateUrl: './external-api-view.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ExternalApiViewComponent implements OnInit {

  @Input('selectedExternalApi')
  selectedExternalApi: number;

  @Input('selectedParameters')
  selectedParameters: Array<ButtonFunctionParametersModel>;

  externalApiParameters: Array<ExternalApiParameter> = [];
  externalApis: Array<ExternalApi> = [];

  @Output('externalApiChange')
  externalApiChange = new EventEmitter<number>();

  @Output('externalApiParamChange')
  externalApiParamChange = new EventEmitter<Array<ExternalApiParameter>>();

  constructor(private externalApiService: ExternalApiService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService) { }

  ngOnInit() {
    this.externalApiService.getExternalApis()
      .subscribe({
        next: (response) => {
          this.externalApis = response;
          this.getParameters(this.selectedExternalApi);
        }, error: this.alertService.showApiError
      });
  }


  changeExternalApis() {
    this.externalApiChange.emit(this.selectedExternalApi);
    this.getParameters(this.selectedExternalApi);
  }

  changeParameter() {
    this.externalApiParamChange.emit(this.externalApiParameters);
  }

  getParameters(id) {
    if (id > 0) {
      this.spinnerService.show();
      this.externalApiService.getExternalApi(id).subscribe(
        (response: ExternalApi) => {

          if (response.Parameters && response.Parameters.length) {

            _.forEach(response.Parameters, (param) => {
              let findedParam = _.find(this.selectedParameters, (selectedParam: any) => {
                return selectedParam.ParameterId == param.Id;
              });
              if (findedParam) {
                param.ParamValue = "";
              }
            });

            this.externalApiParameters = _.filter(response.Parameters, (param) => {
              return !param.ParamValue;
            });

            _.forEach(this.externalApiParameters, (param) => {
              let findedParam = _.find(this.selectedParameters, (selectedParam: any) => {
                return selectedParam.ParameterId == param.Id;
              });
              if (findedParam) {
                param.ParamValue = findedParam.Value;
              }
            });
          }
          else {
            this.externalApiParameters = [];
          }
          this.externalApiParamChange.emit(this.externalApiParameters);
        },
        this.alertService.showApiError,
        () => {
          this.spinnerService.hide();
        });
    }
    else {
      this.externalApiParameters = [];
    }
  }
}
