import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GridColumn, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';

@Component({
  selector: 'pos-order-transactions',
  templateUrl: './order-transactions.component.html',
  styleUrls: ['./order-transactions.component.scss']
})
export class OrderTransactionsComponent implements OnInit {

  @Input() transactions = [];
  transactionColumns: Array<GridColumn> = [];
  @ViewChild('amountTemplate', {static: true}) private amountTemplate: any;
  @ViewChild('lastFourDigitsTemplate', {static: true}) private lastFourDigitsTemplate: any;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() close = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
    const amountTemplate = new TemplateColumn({
      HeaderText: 'Amount',
      itemTemplate: this.amountTemplate,
      Field: 'Amount',
      TextAlign: TextAlign.Right,
      IsSortable: true
    });
    const lastFourDigitsTemplate = new TemplateColumn({
      HeaderText: 'Last Four Digits',
      itemTemplate: this.lastFourDigitsTemplate,
      Field: 'LastFourDigits',
      IsSortable: true
    });
    this.transactionColumns = [
      amountTemplate,
      lastFourDigitsTemplate
    ] as Array<GridColumn>;
  }

  selectTransaction(event) {
    this.close.emit(event.data);
  }

  cancel() {
    this.close.emit();
  }

}
