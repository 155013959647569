import { Component, OnInit, ViewChild } from "@angular/core";
import { BaseFormComponent, SpinnerService } from "src/app/shared/components";
import {
  ParameterService,
  AlertsService,
  SettingParam,
  ApplicationStateService,
  FieldInfoMessages,
  Messages,
  ModalService,
  InfoModalComponent,
  FormUtilityService,
} from "src/app/shared";
import { finalize } from "rxjs/operators";
import { colorsPaletter, plusWhite, timesCircle } from "src/app/shared/components/icon";
import * as _ from "lodash";
import { Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { PostSavedConfigurationService } from "src/app/configurator/post-saved-configuration.service";

@Component({
  selector: "pos-colors",
  templateUrl: "./colors.component.html",
  styleUrls: ["./colors.component.scss"],
})
export class ColorsComponent extends BaseFormComponent implements OnInit {
  @ViewChild('colorsForm') form: NgForm;
  parameters: SettingParam;
  fieldInfoMessages = FieldInfoMessages;
  isAllowEditSeconds = true;
  isEditedMakeTableThresholdColor = false;
  isDeletedThresholdColor = false;
  editedIndex = -1;
  makeTableQuantityColor = null;
  tenderTypesColors = [];
  makeTableColorsArray = [];
  makeTableThresholdColors = [];
  makeTableThresholdColor = { Color: null, Second: null };
  icons = {
    plusWhite,
    timesCircle,
    colorsPaletter
  };
  get getForm(): NgForm {
    return this.form
  }
  constructor(
    private modalService: ModalService,
    private spinnerService: SpinnerService,
    private parameterService: ParameterService,
    private alertsService: AlertsService,
    private applicationStateService: ApplicationStateService,
    private router: Router,
    formUtilityService: FormUtilityService,
    private postSavedConfigurationService: PostSavedConfigurationService
  ) {
    super(formUtilityService);
    this.parameters = applicationStateService.newSettingParams();
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.spinnerService.show();
    this.parameterService
      .getParameterForTerminal(this.applicationStateService.terminalId)
      .pipe(
        finalize(() => {
          this.spinnerService.hide();
        })
      )
      .subscribe({
        next: (res: SettingParam) => {
          this.parameters = res;
          this.makeTableColorsArray = this.parameters.MakeTableQuantityColors;
          const makeTableThresholdColors =
            this.parameters.MakeTableThresholdColors?.length > 0
              ? this.parameters.MakeTableThresholdColors.split(",")
              : [];
          makeTableThresholdColors.forEach((element) => {
            if (element) {
              const color = element.split(":");
              const colorObj = {
                // tslint:disable-next-line: radix
                Second: parseInt(color[0]),
                Color: color[1],
              };
              this.makeTableThresholdColors.push(colorObj);
            }
          });
          const tenderTypesColors =
            this.parameters.TenderTypesColors && this.parameters.TenderTypesColors?.length > 0
              ? this.parameters.TenderTypesColors.split(",")
              : [];
          _.forEach(res.AvailableTenderTypes, (availableTenderType) => {
            const availableTenderTypeColors = { Name: "", Code: "" };
            availableTenderTypeColors.Name = availableTenderType.Name;
            availableTenderTypeColors.Code = availableTenderType.Code;
            this.tenderTypesColors.push(availableTenderTypeColors);
          });
          _.forEach(tenderTypesColors, (tenderColor) => {
            const color = tenderColor.split(":");
            if (color && color[1]) {
              _.forEach(this.tenderTypesColors, (tender) => {
                if (tender.Code === color[0]) {
                  tender.Color = color[1];
                }
              });
            }
          });
          this.onFormChange(this.form);
        }, error: this.alertsService.showApiError
      });
  }

  updateMakeTableQuantityColor(makeTableQuantityColor) {
    this.makeTableQuantityColor = null;
    if (makeTableQuantityColor) {
      this.makeTableColorsArray.push(makeTableQuantityColor);
    }
  }

  removeColorQuantity(colorQty) {
    let delIndex = 0;
    let isDeleted = false;
    let makeTableQuantityColor = "";
    _.forEach(this.makeTableColorsArray, (color) => {
      if (!isDeleted) {
        if (color === colorQty) {
          this.makeTableColorsArray.splice(delIndex, 1);
          isDeleted = true;
        } else {
          makeTableQuantityColor = color + "," + makeTableQuantityColor;
        }
        delIndex++;
      } else {
        makeTableQuantityColor = color + "," + makeTableQuantityColor;
      }
    });
  }

  updateMakeTableThresholdColor(makeTableThresholdColor) {
    const availableColorForSecond = _.findIndex(this.makeTableThresholdColors, {
      Second: makeTableThresholdColor.Second,
    });
    if (!this.isEditedMakeTableThresholdColor) {
      if (makeTableThresholdColor.Color && makeTableThresholdColor.Second) {
        if (availableColorForSecond < 0) {
          const thresholdColor = {
            // tslint:disable-next-line: radix
            Second: parseInt(makeTableThresholdColor.Second),
            Color: makeTableThresholdColor.Color,
          };
          this.makeTableThresholdColors.push(thresholdColor);
          this.sortMakeTableThreshold();
          this.isAllowEditSeconds = true;
        } else {
          this.alertsService.renderErrorMessage(
            Messages.ErrorWhileAddingMaketableThresholdColorForSameSecond
          );
        }
      }
    } else {
      if (availableColorForSecond === -1 || this.editedIndex === availableColorForSecond) {
        this.isEditedMakeTableThresholdColor = false;
        // tslint:disable-next-line: radix
        makeTableThresholdColor.Second = parseInt(makeTableThresholdColor.Second);
        this.makeTableThresholdColors.splice(this.editedIndex, 1, makeTableThresholdColor);
        this.sortMakeTableThreshold();
        this.isAllowEditSeconds = true;
      } else {
        this.alertsService.renderErrorMessage(
          Messages.ErrorWhileAddingMaketableThresholdColorForSameSecond
        );
      }
    }
    this.makeTableThresholdColor = { Color: null, Second: null };
  }

  sortMakeTableThreshold() {
    this.makeTableThresholdColors = _.sortBy(this.makeTableThresholdColors, ["Second", "Color"]);
  }

  editMakeTableThresholdColor(makeTableThresholdColor) {
    if (!this.isDeletedThresholdColor) {
      this.isEditedMakeTableThresholdColor = true;
      this.editedIndex = _.findIndex(this.makeTableThresholdColors, {
        Second: makeTableThresholdColor.Second,
        Color: makeTableThresholdColor.Color,
      });
      this.isAllowEditSeconds = this.editedIndex === 0 ? false : true;
      this.makeTableThresholdColor = _.cloneDeep(makeTableThresholdColor);
    }
    this.isDeletedThresholdColor = false;
  }

  removeMakeTableThresholdColor(makeTableThresholdColor) {
    let delIndex = 0;
    this.isDeletedThresholdColor = false;
    _.forEach(this.makeTableThresholdColors, (color) => {
      if (this.isDeletedThresholdColor === false) {
        if (color === makeTableThresholdColor) {
          this.makeTableThresholdColors.splice(delIndex, 1);
          this.isDeletedThresholdColor = true;
        }
        delIndex++;
      }
    });
    this.sortMakeTableThreshold();
  }

  removeTenderTypeColor(tenderType) {
    tenderType.Color = "";
    document.getElementById(tenderType.Code).style.backgroundColor = "";
  }

  saveColors(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.spinnerService.show();
    this.parameters.MakeTableQuantityColors = this.makeTableColorsArray;
    let makeTableThresholdColors = "";
    this.makeTableThresholdColors.forEach((element) => {
      makeTableThresholdColors += "," + element.Second + ":" + element.Color;
    });
    let tenderTypesColors = "";
    this.tenderTypesColors.forEach((element) => {
      if (element.Color) {
        tenderTypesColors += "," + element.Code + ":" + element.Color;
      }
    });
    this.parameters.MakeTableThresholdColors = makeTableThresholdColors;
    this.parameters.TenderTypesColors = tenderTypesColors;
    this.parameterService
      .saveColors(this.parameters)
      .pipe(
        finalize(() => {
          this.spinnerService.hide();
        })
      )
      .subscribe({
        next: () => {
          this.alertsService.renderSuccessMessage(Messages.ConfigureColorSaveSuccess);
   
          this.postSavedConfigurationService.showMessage();
        }, error: this.alertsService.showApiError
      });
  }

  onFormChange($event: NgForm) {
    this.form = $event;
    this.setInitialValue();
  }

  cancelEditing() {
    this.router.navigate(["/manage/system-configuration"]);
  }
}
