import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertsService, FormUtilityService, SalesSizeService } from 'src/app/shared';
import { ModalFormComponent, ModalService, SpinnerService } from 'src/app/shared/components';
import { finalize } from 'rxjs/operators';
import { first } from 'lodash';

@Component({
  selector: 'pos-set-size',
  templateUrl: './set-size.component.html',
  styleUrls: ['./set-size.component.scss']
})
export class SetSizeComponent extends ModalFormComponent implements OnInit {
  @ViewChild('sizeSelectionForm') sizeSelectionForm: NgForm;
  @Input() selectedButtonBehaviorType;
  @Input() buttonBehavior = null;

  sizeId: number = null;
  sizes: Array<any> = [];

  get getForm(): NgForm {
    return this.sizeSelectionForm;
  }

  constructor(private salesSizesService: SalesSizeService,
    protected spinnerService: SpinnerService,
    protected alertsService: AlertsService,
    modalRef: BsModalRef, modalService: ModalService, protected formUtilityService: FormUtilityService) {
    super(modalRef, modalService, formUtilityService);
  }

  ngOnInit(): void {
    if (this.buttonBehavior && this.buttonBehavior.Id) {
      try {
        const behavior = first(this.buttonBehavior.BehaviorValue) as any;
        this.sizeId = behavior ? behavior.SizeId : null;
      } catch (ex) {
        console.error('Invalid behavior value: ' + this.buttonBehavior.TextValue);
      }
    }
    this.loadData();
    this.setInitialValue();
  }

  loadData() {
    this.spinnerService.show();
    this.salesSizesService.getSalesProductSizes(0)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.sizes = res;
        }, error: this.alertsService.showApiError
      });
  }

  saveBehavior = (isValid: boolean) => {
    if (isValid && this.sizeId) {
      this.hide({ shouldReload: true, Value: { sizeId: this.sizeId } });
    }
  }

  closeModal = () => {
    this.hide({ shouldReload: false });
  }
}
