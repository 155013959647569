<div>
  <div>
    <h4 class="modal-content__heading">Confirm</h4>
  </div>
  <div class="">
    <div class="col-md-12 col-sm-12 pt-pix-15 text-center">
      {{peripheralCreditCardMessage}}
    </div>
  </div>
  <div class="col-xs-12 action-button-container popup-footer pb-pix-10">
    <button type="button" [disabled]="isDisableApprovedButton" [ngClass]="{'btn-success': !isDisableApprovedButton, 'btn-secondary black': isDisableApprovedButton}"
      (click)="onPeripheralApproved()" class="btn btn-lg btn-save pos-button">Approved
    </button>
    <button type="button" [disabled]="isDisableApprovedButton" [ngClass]="{'btn-danger': !isDisableApprovedButton, 'btn-secondary': isDisableApprovedButton}"
      (click)="onPeripheralCreditCardCancel()" class="btn btn-lg btn-cancel-modal pos-button">Declined
    </button>
  </div>
  <div class="clearfix"></div>
</div>
