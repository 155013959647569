<div class="page-content pt-pix-10">
    <div class="tab-pane fade show active tab-pane-m-p">
            <p-table [value]="dietaryWarningInfo">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 49%">Dietary Warning</th>
                        <th style="width: 49%">Dietary Warning Type</th>
                        <th style="width: 45px"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-i="rowIndex">
                    <tr style="height: 37px;">
                        <td>
                        <pos-form-field [validationMessages]="{required: 'Please select dietary warning.'}" inputStyle="col-xs-12 px-pix-10" [appendPaddingTop]="false">
                            
                            <ng-select  posInput
                    appendTo="body"
                    placeholder="Select dietary warning"
                    (change)="createDietaryWarningRow(i+1== dietaryWarningInfo.length); disableSelectedDietaryWarning()"
                    name="dietaryWarnings{{i}}"
                    id="dietaryWarnings{{i}}"
                    #dietaryWarning="ngModel"                    
                    [items]="dietaryWarnings" 
                    [style]="{'width':'80%'}"
                    [(ngModel)]="rowData.DietaryWarningId"                   
                    [multiple]="false" 
                    [selectOnTab]="true" 
                    bindLabel="label"
                    bindValue="value"                                    
                    [clearable]="false"
                    [required]="i+1 != dietaryWarningInfo.length">      
                          
                  </ng-select>      

                        </pos-form-field>
                        </td>
                        <td>
                        <pos-form-field [validationMessages]="{required: 'Please select dietary warning type.'}" inputStyle="col-xs-12 px-pix-10" [appendPaddingTop]="false">

                            <ng-select  posInput
                    appendTo="body"
                    placeholder="Select dietary warning type"
                    (change)="createDietaryWarningRow(i+1== dietaryWarningInfo.length)"
                    name="dietaryWarningType{{i}}"
                    id="dietaryWarningType{{i}}"
                    #dietaryWarningType="ngModel"                    
                    [items]="dietaryWarningTypes" 
                    [style]="{'width':'80%'}"
                    [(ngModel)]="rowData.DietaryWarningTypeId"                   
                    [multiple]="false" 
                    [selectOnTab]="true" 
                    bindLabel="label"
                    bindValue="value"                                    
                    [clearable]="false"
                    [required]="i+1 != dietaryWarningInfo.length">    </ng-select>
                        </pos-form-field>
                        </td>

                        <td class="text-center">
                            <a (click)="clearDietaryWarningRow(rowData, i)" title="Delete"
                                *ngIf="!(i+1== dietaryWarningInfo.length)">
                                <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
                            </a>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
    </div>
</div>
