import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalFormComponent, SpinnerService, AlertsService, ApplicationStateService, ModalService, FormUtilityService } from 'src/app/shared';
import { SalesProductsService } from '../../services';
import { finalize } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { SalesInventoryProductService } from '../../services/sales-inventory-product.service';

@Component({
  selector: 'pos-sales-product-recipe-edit',
  templateUrl: './sales-product-recipe-edit.component.html',
  styleUrls: ['./sales-product-recipe-edit.component.scss']
})
export class SalesProductRecipeEditComponent extends ModalFormComponent implements OnInit {

  @Input('recipe') recipe: any;
  @Input('productId') productId: number;
  @ViewChild('recipeEditForm', { static: true }) form: NgForm;
  minDate: Date;
  dateFormat = 'mm-dd-yyyy';
  get getForm(): NgForm {
    return this.form
  }

  constructor(recipeModalRef: BsModalRef,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private applicationStateService: ApplicationStateService,
    private salesProductService: SalesProductsService,
    modalService: ModalService,
    formUtilityService: FormUtilityService,
    private salesInventoryProductService: SalesInventoryProductService) {
    super(recipeModalRef, modalService, formUtilityService);
  }

  ngOnInit() {
    if(this.recipe.Id == 0) {
      this.recipe.qty = 1;
    }
    if (this.recipe.DateEffective) {
      this.recipe.DateEffective = new Date(this.recipe.DateEffective);
    }
    if (this.recipe.DateExpired) {
      this.recipe.DateExpired = new Date(this.recipe.DateExpired);
    }
    this.minDate = new Date();
    this.dateFormat = this.applicationStateService.settingParam.PCalendarDateFormat;
    this.setInitialValue();
  }

  saveIngredient(isValid: boolean) {
    if (!isValid) {
      return;
    }
    if (this.recipe.Id == 0) {
      this.saveNewConsumption();
    } else {
      this.spinnerService.show();
      if (this.recipe.qty && this.recipe.qty !== '') {
        if (this.recipe.qty.toString().indexOf('/') >= 0) {
          const qty = this.recipe.qty.toString().indexOf('/');
          if (qty !== -1) {
            this.recipe.qty = ((this.recipe.qty.substring(0, qty) / this.recipe.qty.substring(qty + 1))).toFixed(4);
          }
        }
      }
      this.recipe.QtyEditing = false;
      this.recipe.EditAction = false;
      this.recipe.DateEditing = false;
      this.recipe.InstructionEdit = false;
      this.recipe.CustomColorEdit = false;
      let consumption = this.recipe;
      consumption.sales_product_id = this.productId;
      consumption.Qty = this.recipe.qty;
      consumption.inventory_product_id = this.recipe.inventory_product_id;
      this.salesProductService.updateInventoryConsumption(this.productId, 0, 0, consumption)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: res => {
            if (res) {
              this.onCancel(true);
            }
          }, error: this.alertService.showApiError
        });
    }

   
  }

  saveNewConsumption = () => {
    this.spinnerService.show();
    this.salesInventoryProductService.saveSalesInventoryProduct(this.recipe)
      .pipe(
        finalize(() => {
          this.spinnerService.hide();
        }))
      .subscribe({
        next: res => {
          this.onCancel(true);
        },
        error: this.alertService.showApiError
      });
  }

  onCancel(reload: boolean) {
    this.hide({ shouldReload: reload });
  }
}
