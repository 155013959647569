import { Component, OnInit, Input, TemplateRef, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { SpinnerService, AlertsService, Messages, ModalService, ConfirmDeleteModalComponent, ModalFormComponent, FormUtilityService } from 'src/app/shared';
import { SalesProductsService, InstructionsService } from '../../services';
import { finalize } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { GridColumn, DeleteColumn, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import {
  salesProductRecipe,
  inventoryProductsStore
} from 'src/app/shared/components/icon';
import { InvProduct } from 'src/app/shared/interface/inventory-product';
declare let $: any;
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'pos-import-recipe',
  templateUrl: './import-recipe.component.html',
  styleUrls: ['./import-recipe.component.scss']
})
export class ImportRecipeComponent extends ModalFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.importRecipeForm;
  }

  @Input('isImport') isImport: boolean = false;
  @Input('salesProductId') salesProductId: number;
  @Input('areaId') areaId: number;
  @Input('salesProductName') salesProductName: string = '';
  @Input('sizeName') sizeName: string = '';
  @Input('sizeId') sizeId: number;
  @Input('maxOrdinal') maxOrdinal: any;
  @ViewChild('importRecipeForm') importRecipeForm: NgForm;
  recipes: Array<any> = [];
  inventoryProducts: Array<InvProduct> = [];
  height: any;
  isCheckedAll: boolean = false;
  selectedInventoryProductId: number = null;
  exclusionInventoryProducts: Array<any> = [];
  isActiveRecipeTab = true;
  @ViewChild('chkTemplate', { static: true }) chkTemplate: TemplateRef<any>;
  @ViewChild('headerTemplate', { static: true }) headerTemplate: TemplateRef<any>;
  @ViewChild('qtyTemplate', { static: true }) qtyTemplate: TemplateRef<any>;
  recipeColumns: Array<GridColumn> = [];
  productsColumn: Array<GridColumn> = [];
  icons = {
    salesProductRecipe,
    inventoryProductsStore
  };
  tabList = {
    Recipes: 'Recipes',
    InventoryProducts: 'InventoryProducts'
  };
  selectedTab = this.tabList.Recipes;
  constructor(private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private salesProductService: SalesProductsService,
    private instructionsService: InstructionsService,
    modalService: ModalService,
    modalRef: BsModalRef,
    formUtilityService: FormUtilityService
  ) {
    super(modalRef, modalService, formUtilityService);
  }

  ngOnInit() {
    this.height = this.isImport ? $(window).height() - 210 : $(window).height() - 300;
    this.isActiveRecipeTab = true;
    this.getSalesProductRecipes();
    this.configureGridColumnForRecipe();
    if (!this.isImport) {
      this.configureGridColumnForProduct();
    }
  }

  configureGridColumnForRecipe() {
    const operationColumn = new TemplateColumn({
      itemTemplate: this.chkTemplate,
      Width: '70px',
      TextAlign: TextAlign.Center,
      headerTemplate: this.headerTemplate
    });

    const qtyColumn = new TemplateColumn({
      HeaderText: 'Qty',
      itemTemplate: this.qtyTemplate,
      TextAlign: TextAlign.Center,
      Field: 'qty',
      IsSortable: true,
    });

    this.recipeColumns = [
      operationColumn,
      new GridColumn({ HeaderText: 'Name', Field: 'Product', IsSortable: true }),
      qtyColumn,
      new GridColumn({ HeaderText: 'Notes', Field: 'Notes', IsSortable: true, Width: '45%' })
    ];
  }

  configureGridColumnForProduct() {
    const deleteColumn = new DeleteColumn({
      Clicked: (data) => {
        this.confirmDeleteSalesProductInstructionExclusion(data);
      },
      Width: '30px',
    });
    this.productsColumn = [
      new GridColumn({ HeaderText: 'Name', Field: 'ProductName', IsSortable: true, Width: '95%' }),
      deleteColumn
    ] as Array<GridColumn>;
  }

  getSalesProductRecipes() {
    this.spinnerService.show();
    const consumption = {
      SalesProductId: this.salesProductId,
      InventoryProductId: 0,
      SizeId: this.sizeId,
      IsHistory: false
    };
    this.salesProductService.getProductsInventoryConsumption(consumption)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          if (res) {
            this.recipes = res;
            _.forEach(this.recipes, (recipe) => {
              recipe.isChecked = false;
            });
            if (!this.isImport) {
              this.getInventoryProducts();
            }
          }
        }, error: this.alertService.showApiError
      });
  }

  getInventoryProducts() {
    this.spinnerService.show();
    this.inventoryProducts = [];
    const inventoryProductObservables = [];
    inventoryProductObservables.push(this.salesProductService.getAllActiveInventoryProducts('undefined'));
    inventoryProductObservables.push(this.instructionsService.getSalesProductInstructionExclusions(this.salesProductId));
    forkJoin(inventoryProductObservables)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (responses: any) => {
          if (responses) {
            this.exclusionInventoryProducts = responses[1] ? responses[1].sort((a, b) => a.ProductName.localeCompare(b.ProductName)) : [];

            _.forEach(this.recipes, (recipe) => {
              recipe.isChecked = false;
              recipe.ExclusionId = 0;
              _.forEach(this.exclusionInventoryProducts, (exclusionProduct) => {
                if (recipe.inventory_product_id === exclusionProduct.InventoryProductId) {
                  recipe.isChecked = true;
                  recipe.ExclusionId = exclusionProduct.Id;
                }
              });
            });
            if (responses[0]) {
              let isProductInstructionExclusion = false;
              this.inventoryProducts = _.filter(responses[0], (product) => {
                isProductInstructionExclusion = false;
                _.forEach(this.exclusionInventoryProducts, (exclusionProduct) => {
                  if (exclusionProduct.InventoryProductId === product.id) {
                    isProductInstructionExclusion = true;
                  }
                });
                return product.id > 0 && !isProductInstructionExclusion;
              });
            }
          }
        }, error: this.alertService.showApiError
      });
  }

  addSalesProductInstructionExclusion(selectedInventoryProductId) {
    if (selectedInventoryProductId) {
      const exclusion = {
        Id: undefined,
        SalesProductId: this.salesProductId,
        InventoryProductId: selectedInventoryProductId
      };

      this.spinnerService.show();
      this.instructionsService.saveSalesProductInstructionExclusion(exclusion)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: res => {
            this.alertService.renderSuccessMessage(Messages.InstructionsExcludedSaveSuccess);
            this.getInventoryProducts();
            this.selectedInventoryProductId = null;
          }, error: this.alertService.showApiError
        });
    }
  }

  deleteSalesProductInstructionExclusion(exclusionInventoryProduct) {
    this.spinnerService.show();
    this.instructionsService.deleteSalesProductInstructionExclusion(exclusionInventoryProduct.Id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          this.alertService.renderSuccessMessage(StringUtils.format(Messages.InstructionsExcludedDeleteSuccess,
            { 'productName': exclusionInventoryProduct.ProductName }));
          this.getInventoryProducts();
        }, error: this.alertService.showApiError
      });
  }

  saveInstructionExclusions(recipes) {
    const importedProducts = _.filter(this.recipes, { 'isChecked': false });
    _.forEach(importedProducts, (recipe) => {
      if (recipe.ExclusionId > 0) {
        this.spinnerService.show();
        this.instructionsService.deleteSalesProductInstructionExclusion(recipe.ExclusionId)
          .pipe(finalize(() => {
            this.spinnerService.hide();
          }))
          .subscribe({
            next: res => {
              this.getInventoryProducts();
            }, error: this.alertService.showApiError
          });
      }
    });
    _.forEach(recipes, (recipe) => {
      if (!recipe.ExclusionId) {
        const exclusion = {
          Id: undefined,
          SalesProductId: this.salesProductId,
          InventoryProductId: recipe.inventory_product_id,
        };
        this.saveInstructionExclusion(exclusion);
      }
    });
  }

  saveInstructionExclusion(model) {
    this.spinnerService.show();
    this.instructionsService.saveSalesProductInstructionExclusion(model)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          if (this.spinnerService._requestCount - 1 === 0) {
            const msg = this.isImport ? Messages.ImportRecipeSuccess : Messages.InstructionsExcludedSaveSuccess;
            this.alertService.renderSuccessMessage(msg);
            this.getInventoryProducts();
          }
        }, error: this.alertService.showApiError
      });
  }

  saveImportRecipes(recipes) {
    _.forEach(recipes, (recipe) => {
      this.maxOrdinal++;
      const inst = {
        InstructionAreaId: this.areaId,
        InventoryProductId: recipe.inventory_product_id,
        IngredientImage: undefined,
        IngredientInstruction: recipe.Product + ' (' + recipe.qty + ' ' + recipe.Unit + ').\n' + recipe.Notes,
        Ordinal: this.maxOrdinal
      };
      this.saveInstruction(inst);
    });
  }

  saveInstruction(instruction) {
    this.spinnerService.show();
    this.instructionsService.saveSalesProductInstruction(instruction, null)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          if (this.spinnerService._requestCount - 1 === 0) {
            const msg = this.isImport ? Messages.ImportRecipeSuccess : Messages.InstructionsExcludedSaveSuccess;
            this.alertService.renderSuccessMessage(msg);
            this.onClose(true);
          }
        }, error: this.alertService.showApiError
      });
  }

  selectAll(isCheckedAll) {
    _.forEach(this.recipes, (recipe) => {
      recipe.isChecked = isCheckedAll;
    });
  }

  onRecipeChecked() {
    this.isCheckedAll = _.every(this.recipes, { 'isChecked': true });
  }

  onClose(reload: boolean) {
    this.hide({ shouldReload: reload });
  }

  activeRecipeTab(active: boolean) {
    this.isActiveRecipeTab = active;
  }

  save() {
    const recipes = _.filter(this.recipes, { 'isChecked': true });
    if (this.isImport) {
      this.saveImportRecipes(recipes);
    } else {
      this.saveInstructionExclusions(recipes);
    }
  }

  confirmDeleteSalesProductInstructionExclusion(exclusionInventoryProduct) {
    const deleteExclusionModalRef = this.modalService.show(ConfirmDeleteModalComponent, {
      animated: false,
      keyboard: false,
      class: 'vertical-center',
      initialState: {
        message: StringUtils.format(Messages.ConfirmDeleteExcludedProduct,
          { 'productName': exclusionInventoryProduct.ProductName }),
      }
    });
    deleteExclusionModalRef.close.subscribe(res => {
      if (res && res.shouldDelete) {
        this.deleteSalesProductInstructionExclusion(exclusionInventoryProduct);
      }
    });
  }
}
