import { TemplateRef, ContentChild } from '@angular/core';
import { GridColumn } from './grid-column';
import { GridColumnTypes } from './grid-column-types';

export class LinkTemplateColumn extends GridColumn {

    public itemTemplate: TemplateRef<any>;
    public headerTemplate: TemplateRef<any>;

    get Type(): string {
        return GridColumnTypes.LinkTemplateColumn;
    }
    constructor(init?: Partial<LinkTemplateColumn>) {
        super();
        this.CellClass = 'cell-link cell-padding';
        this.BackgroundColor = 'orangered';
        Object.assign(this, init);
    }

}
