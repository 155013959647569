import { Injectable } from '@angular/core';
import { cloneDeep, forEach } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AdfDashboardService {

  changeStructure = (model, structure) => {
    const columns = this.readColumns(model);
    let counter = 0;

    model.rows = cloneDeep(structure.value.rows);

    while (counter < columns.length) {
      counter = this.fillStructure(model, columns, counter);
    }
  }

  readColumns = (root, columns?) => {
    columns = columns || [];
    if (root.rows) {
      forEach(root.rows, (row) => {
        forEach(row.columns, (col) => {
          if (!col.hasOwnProperty('rows')) {
            columns.push(col);
          }
          // keep reading columns until we can't any more
          this.readColumns(col, columns);
        });
      });
    }
    return columns;
  }

  fillStructure = (root, columns, counter) => {
    counter = counter || 0;
    if (root.rows) {
      forEach(root.rows, (row) => {
        forEach(row.columns, (column) => {
          // if the widgets prop doesn't exist, create a new array for it.
          // this allows ui.sortable to do it's thing without error
          if (!column.widgets) {
            column.widgets = [];
          }

          // if a column exist at the counter index, copy over the column
          if (columns[counter]) {
            // do not add widgets to a column, which uses nested rows
            if (!column.rows) {
              this.copyWidgets(columns[counter], column);
              counter++;
            }
          }
          // run fillStructure again for any sub rows/columns
          counter = this.fillStructure(column, columns, counter);
        });
      });
    }
    return counter;
  }

  copyWidgets = (source, target) => {
    if (source.widgets && source.widgets.length > 0) {
      let w = source.widgets.shift();
      // Customized: set widget's rowIndex & columnIndex properties with targeted rowIndex & columnIndex while drag n drop
      w.rowIndex = target.rowIndex;
      w.columnIndex = target.columnIndex;
      while (w) {
        target.widgets.push(w);
        w = source.widgets.shift();
      }
    }
  }

  createConfiguration = (type, dashboard) => {
    let cfg = {};

    // Customized: using dashboard.widgetsList instead of dashboard.widgets as we are holding db widgets into widgetsList now
    const config = dashboard.widgetsList[type].config;
    if (config) {
      cfg = cloneDeep(config);
    }
    return cfg;
  }

  findLastWidgetColumn = (model) => {
    let column = null;
    if (!Array.isArray(model.rows)) {
      return null;
    }

    for (let i = 0; i < model.rows.length; i++) {
      const row = model.rows[i];
      if (Array.isArray(row.columns)) {
        for (let j = 0; j < row.columns.length; j++) {
          const col = row.columns[j];
          // Customized: add new widget at the end of the widgets
          if (!col.rows && (!col.widgets || col.widgets.length === 0)) {
            column = col;
            break;
          }
        }
      }
      if (column) {
        break;
      }
    }
    if (!column && model.rows.length > 0 && model.rows[0].columns.length > 0) {
      column = model.rows[0].columns[0];
    }
    return column;
  }

  addNewWidgetToModel = (model, widget, name) => {
    if (model) {
      const column = this.findLastWidgetColumn(model);
      if (column) {
        if (!column.widgets) {
          column.widgets = [];
        }
        column.widgets.unshift(this.prepareWidget(widget, column));

      } else {
        console.error('could not find first widget column');
      }
    } else {
      console.error('model is undefined');
    }
  }


  prepareWidget = (widget, column) => {
    const model = {
      allowedInputTypes: widget.AllowedInputTypes,
      frameless: widget.frameless,
      reload: widget.reload,
      title: widget.Name,
      type: widget.WidgetKey,
      columnIndex: column.columnIndex,
      rowIndex: column.rowIndex,
      widgetId: widget.widgetId,
      config: {
        bgColor: '',
        color: '',
        borderColor: '',
        colorConditions: []
      },
      isNewWidget: true
    }
    return model;
  }

  isEditModeImmediate = (type, dashboard) => {
    // Customized: using dashboard.widgetsList instead of dashboard.widgets as we are holding db widgets into widgetsList now
    const widget = dashboard.widgetsList[type];
    return widget && widget.edit && widget.edit.immediate;
  }

  createCategories(widgets) {
    const categories = {};
    forEach(widgets, (widget, key) => {
      let category = widget.category;
      // if the widget has no category use a default one
      if (!category) {
        category = 'Miscellaneous';
      }
      // push widget to category array
      if (!categories[category]) {
        categories[category] = { widgets: {} };
      }
      categories[category].widgets[key] = widget;
    });
    return categories;
  }

}
