<pos-edit-wrapper heading="Print Stock Labels: {{productName}}" (close)="cancel()">
  <form #prepLabelPrintForm="ngForm" (ngSubmit)="prepLabelPrint(prepLabelPrintForm.valid)" autocomplete="off">
    <div class="col-12 pos-modal-body">
      <pos-row>
        <pos-column>
          <pos-form-field inputStyle="col-12"
          [validationMessages]="{
            required: 'Please enter print count.',
            min: 'Please enter print count greater than zero.',
            max: 'Max ' + maxAllowedPrintLabelCount + ' print(s) allowed.'}">
            <pos-row colXs="2" colStyleClass="col-70">
              <pos-column>
                <label class="edit-form__section__label">Print</label>
              </pos-column>
              <pos-column xs="4" class="ps-0 col-120">
                <input posInput type="number" min="1" (keypress)="onKeyPress($event)" class="form-control text-right" [required]="true"
                  name="printLabelCount" [max]="maxAllowedPrintLabelCount" #printLabelCount="ngModel" [(ngModel)]="labelCount">
              </pos-column>
              <pos-column>
                <label class="edit-form__section__label">Label(s)</label>
              </pos-column>
            </pos-row>
          </pos-form-field>
        </pos-column>
      </pos-row>
    </div>
    <div class="col-12 action-button-container popup-footer">
      <button class="btn-primary btn-lg btn normal btn-save pos-button" type="submit" value="Save">Print</button>
      <button class="btn-secondary btn-lg btn normal btn-cancel-modal pos-button" (click)="cancel()" type="button" value="Cancel">Cancel
      </button>
    </div>
  </form>
</pos-edit-wrapper>
