<div class="row">
    <div class="col-md-12 col-sm-12 zone-content">
        <div class="table-responsive order-list pb-pix-5">
            <ng-table [data]="transactions" [columns]="creditCardTransactionColumns" class="transaction-details"
                [paginator]="false" [search]="false">
            </ng-table>

            <ng-template let-data="rowData" #transactionDetailsTemplate>
                <div *ngIf="data.PaymentType">
                    <div><b>Payment Type: </b>{{data.PaymentType}}</div>
                </div>
                <div *ngIf="data.CCTxnRefNumber">
                    <div><b>Reference Number: </b>{{data.CCTxnRefNumber}}</div>
                </div>
                <div *ngIf="data.LastFourDigits">
                    <div><b>Last Four Digits: </b>{{data.LastFourDigits}}</div>
                </div>
                <div *ngIf="data.AuthorizationCode">
                    <div><b>Authorization Code: </b>{{data.AuthorizationCode}}</div>
                    <div class="ps-pix-20"> </div>
                </div>
                <div *ngIf="data.RegisterId">
                    <div><b>Register Id: </b>{{data.RegisterId}}</div>
                </div>
                <div *ngIf="data.GiftCardNumber">
                    <div><b>Gift Card Number: </b>{{data.GiftCardNumber}}</div>
                </div>
            </ng-template>

            <ng-template let-data="rowData" let-index="rowIndex" #voidButtonTemplate>
                <button *ngIf="(index + 1) != transactions.length && data.TransactionType != transactionTypes.Void && data.Amount > 0 && orderTypeId != orderTypes.Online" class="btn btn-sm btn-secondary pos-button" (click)="confirmVoidTransaction(data);close()">Void</button>
                <span *ngIf="data.TransactionType == transactionTypes.Void"> Voided </span>
            </ng-template>
        </div>
        <div class="col-md-12 pt-pix-10 action-button-container border-top-0-important">
            <button type="button" class="btn-secondary btn normal pos-button" (click)="close()"
                value="Cancel"> Close
            </button>
        </div>
    </div>
</div>
