import { Component, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormUtilityService, ModalFormComponent, ModalService } from 'src/app/shared';

@Component({
  selector: 'pos-inventory-product-log-modal',
  templateUrl: './inventory-product-log-modal.component.html',
  styleUrls: []
})
export class InventoryProductLogModalComponent extends ModalFormComponent {
  get getForm(): NgForm {
    return this.inventoryProductModalForm;
  }

  @Input() logInventoryPopupHeader: string;
  @Input() inventoryProductForLog: any;
  @ViewChild('inventoryProductModalForm') inventoryProductModalForm: NgForm;
  constructor(modalRef: BsModalRef, modalService: ModalService, formUtilityService: FormUtilityService) {
    super(modalRef, modalService, formUtilityService);
  }

  updateInventoryLog() {
    this.hide({ reload: true, inventoryProductForLog: this.inventoryProductForLog});
  }

  closeInventoryLogModel() {
    this.hide({ reload: false });
  }

}
