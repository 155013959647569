import { DecimalPipe } from '@angular/common';
import { Directive, ElementRef, Renderer2, Input, OnInit, HostListener } from '@angular/core';

@Directive({
  selector: '[posDecimalRange]'
})
export class PosDecimalRangeDirective implements OnInit {

  @Input('minFractionDigits') minFractionDigits: number = 2;
  @Input('maxFractionDigits') maxFractionDigits: number = 4;
  @Input('isAllowNegative') isAllowNegative?: boolean = false;
  @Input() ngModel: any;

  currentElement: ElementRef;
  elementRenderer: Renderer2;

  constructor(private el: ElementRef, private renderer: Renderer2,
    private decimalPipe: DecimalPipe) {
    this.currentElement = el;
    this.elementRenderer = renderer;
  }

  ngOnInit(): void {
    if (!this.ngModel) {
      return;
    }
  }

  @HostListener('blur', ['$event.target.value']) onBlur(value) {
    if(value) {
      this.currentElement.nativeElement.value = this.decimalPipe.transform(parseFloat(value), `0.${this.minFractionDigits}-${this.maxFractionDigits}`)?.replace(',','');
    }
  }

  @HostListener('keypress', ['$event']) onKeypress(event) {
    const keyCode = event.keyCode;
    const enterKeyCode = 13;

    // Allow Enter key
    if (keyCode === enterKeyCode) {
      return;
    }
    
    if (keyCode === 32 || (keyCode === 45 && !this.isAllowNegative)) {
      event.preventDefault();
    }
  }

}
