import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { find } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DomainConstants, FormUtilityService, ModalFormComponent, ModalService } from 'src/app/shared';
import { Task } from '../../../../information-management/tasks/interface/task';
import { EventSubscriptionActionAttribute } from '../../interfaces/event-subscription-action-attribute';
import { messageCode } from 'src/app/shared/components/icon';
import { RepeatActionConfigurationComponent } from '../repeat-action-configuration';
@Component({
  selector: 'pos-task-configuration',
  templateUrl: './task-configuration.component.html',
  styleUrls: ['./task-configuration.component.scss']
})
export class TaskConfigurationComponent extends ModalFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.taskActivityEditForm;
  }
  @ViewChild('RepeatActionConfiguration') repeatActionConfiguration: RepeatActionConfigurationComponent;
  @ViewChild('taskActivityEditForm', { static: true }) taskActivityEditForm: NgForm;
  @Input() actionId = 0;
  @Input() eventSubscriptionActionAttributes: Array<EventSubscriptionActionAttribute> = [];
  @Input() eventAttributes;
  @Input() deliveryTypes = [];
  selectedDeliveryType;
  @Input() eventIdentifier: string;
  selectedTask: number;
  @Input() tasks: Array<Task> = [];
  @Input() isRecoverableEvent;
  deliveryAttributes = DomainConstants.EventDeliveryAttributes;
  taskTypes = DomainConstants.TaskActionTypes;
  taskType = this.taskTypes.Existing;
  taskName: string = null;
  cursorPosition = 0;
  isOpenVariable = false;
  selectedEventAttribute;
  public icons = {
    messageCode
  };
  props = {
    controlBoxClass: 'col-xs-12'
  }
  constructor(modalRef: BsModalRef, modalService: ModalService, protected formUtilityService: FormUtilityService) {
    super(modalRef, modalService, formUtilityService);
  }

  ngOnInit(): void {
    const selectedTask = parseInt(find(this.eventSubscriptionActionAttributes,
      x => x.AttributeKey === this.deliveryAttributes.Task.TaskId)?.AttributeValue, 10);
    this.selectedTask = isNaN(selectedTask) ? null : selectedTask;
    this.taskName = find(this.eventSubscriptionActionAttributes,
      x => x.AttributeKey === this.deliveryAttributes.Task.TaskNameTemplate)?.AttributeValue;
    this.taskType = this.selectedTask ? this.taskTypes.Existing : this.taskTypes.New;
  }

  saveActivity = (isValid: boolean) => {
    if (isValid) {
      const actionAttributes = this.prepareEventActionAttributes();
      this.hide({ shouldReload: true, actionAttributes: actionAttributes });
    }
  }

  changeTaskType() {
    if (this.taskType == this.taskTypes.Existing) {
      this.taskName = null;
    } else {
      this.selectedTask = null;
    }
  }

  onClose = (shouldReload: boolean) => {
    this.hide({ shouldReload: shouldReload });
  }

  prepareEventActionAttributes() {
    const actionDeliveryAttributes: Array<EventSubscriptionActionAttribute> = [];
    this.addAttribute(this.deliveryAttributes.Task.TaskId, this.selectedTask?.toString(),
      actionDeliveryAttributes);
    this.addAttribute(this.deliveryAttributes.Task.TaskNameTemplate, this.taskName,
      actionDeliveryAttributes);
    if (this?.repeatActionConfiguration?.repeatConfig) {
      this.addAttribute(this.deliveryAttributes.Task.Repeat, this?.repeatActionConfiguration?.isRepeat ? JSON.stringify(this?.repeatActionConfiguration?.repeatConfig) : "", actionDeliveryAttributes)
    }
    return actionDeliveryAttributes;
  }

  addAttribute = (key, value, array) => {
    const actionDeliveryAttribute: EventSubscriptionActionAttribute = {
      Id: this.getActionAttributeId(key),
      AttributeKey: key,
      AttributeValue: value,
      EventSubscriptionActionId: this.actionId
    };
    if (actionDeliveryAttribute.Id > 0 ||
      (!actionDeliveryAttribute.Id && actionDeliveryAttribute.AttributeValue)) {
      array.push(actionDeliveryAttribute);
    }
  }

  getActionAttributeId(key) {
    const attribute = find(this.eventSubscriptionActionAttributes, x => x.AttributeKey === key);
    return attribute ? attribute.Id : 0;
  }

  changeEventAttribute() {
    this.isOpenVariable = false;
    this.taskName = this.taskName ? this.taskName.substring(0, this.cursorPosition) + `{{${this.selectedEventAttribute}}}`
      + this.taskName.substring(this.cursorPosition) : `{{${this.selectedEventAttribute}}}`;
    this.selectedEventAttribute = null;
  }

  getCaretPos(text) {
    if (text.selectionStart || text.selectionStart === '0') {
      this.cursorPosition = text.selectionStart;
    }
  }

}
