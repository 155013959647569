import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AppState } from '../interface/app-state';
import { LicenseInfo } from '../interface/license-info';
import { UserDetails } from '../interface/user-details';
import { SettingParam } from '../interface/setting-param';
import { Configurations } from '../interface/configurations';
import { UnservedOrdersData } from '../interface/unserved-orders-data';
import { ProductNavigationDetails } from '../interface/product-navigation-details';
import { ScreenBehaviorData } from '../interface/screen-behavior-data';
import { DefaultProductSelected } from '../interface/default-product-selected';
import { ButtonBehavior } from '../interface/button-behavior';
import { ApplicationInitializationConfigurations } from '../interface/application-initialization-configurations';
import { InventoryOrderReceivingProductList } from '../interface/inventory-order-receiving-product-list';
import { InventoryOrderReceivingNewBarcodeList } from '../interface/inventory-order-receiving-new-barcode-list';
import { SignInAsDiffUserTerminalDetail } from '../interface/sign-in-as-diff-user-terminal-detail';
import { TerminalSummary } from 'src/app/application-login';
import { ApplicationFeatures, UserPermissionModel } from '../interface';
import { GridRouteContextInfo } from '../interface/grid-route-context-info';
import { PermissionArguments } from '../interface/permission-arguments';
import { getCurrencySymbol, registerLocaleData } from '@angular/common';
import { DomainConstants } from '../constants/domain-constants';
import { CameraAnnotationObject } from '../interface/camera-annotations';
import { TerminalProperty } from 'src/app/configurator/terminals';

@Injectable()
export class ApplicationStateService {
  private appState: AppState;

  // alerts: any[];
  // Alert: {
  //   type: any;
  //   msg: any;
  //   htmlMessage: any;
  // };
  // MessageBox: string;
  constructor(private _cookieService: CookieService) {

    this.appState = {
      cookie: {
        terminalName: null,
        userId: null,
        terminalType: null,
        currentUser: null
      },
      terminalName: '',
      terminalProperties: [],
      licenseInfo: null,
      applicationType: null,
      userId: null,
      userDetails: null,
      settingParam: null,
      isDebug: null,
      configurations: null,
      userPermissions: null,
      cartOrder: null,
      unservedOrdersData: null,
      productNavigationDetails: null,
      screenBehaviorData: null,
      defaultProductSelected: null,
      buttonBehavior: null,
      parentId: 0,
      applicationInitializationConfigurations: null,
      selectedActiveOrderId: 0,
      gotoTerminal: false,
      updateFlag: null,
      invType: null,
      invId: 0,
      inventoryOrderReceivingProductList: null,
      inventoryOrderReceivingNewBarcodeList: null,
      terminalType: null,
      currentUser: null,
      loadHomePage: null,
      applicationMode: null,
      currentUserId: 0,
      signInAsDiffUserTerminalDetail: null,
      application: null,
      statusCloseDrawer: null,
      externalPassword: null,
      username: null,
      enableAttendanceCardSwipe: true,
      previousState: null,
      isAuthenticated: false,
      isImpersonateUser: false,
      impersonateUserDetails: null,
      recentTerminal: null,
      gridRouteContext: null,
      isFirstTimeAfterLogin: null,
      userSignInByButton: false,
      loadedPermissions: [],
      applicationFeatures: [],
      cameraAnnotations: {
        TaskComplete: '',
        TaskSkipped: '',
        SignIn: '',
        SignOut: '',
        ClockIn: '',
        ClockOut: '',
        PriceOverride: '',
        ChangeQuantity: '',
        ScheduleProduct: '',
        LabelPrint: '',
        NutritionFactsPrint: '',
        CommentAdd: '',
        DeleteComponent: '',
        NewOrder: '',
        DeleteProduct: '',
        SendOrder: '',
        CancelOrder: '',
        ReviewOrder: '',
        RingUp: '',
        AddSubaccount: '',
        MoveSubaccount: '',
        RenameSubaccount: '',
        RingupPaymentType: '',
        RingupSubaccountSelect: '',
        RingupPaymentCompleted: '',
        OrderNavigationButtonPressed: '',
        ItemAddedToInvoice: '',
        MakeTableStateChange: '',
        MakeTableOrderServe: '',
        MakeTableNotify: '',
        ChangeProductGroupQuantity: '',
        DeleteProductGroup: '',
        MoveProductGroup: ''
      },
      stayWithUnsavedFormChanges: false,
      contactSupportKey: null,
      isDisableCurrentKioskTerminal: false
    };

  }

  public get terminalId(): number {
    const currentTerminalId = parseInt(sessionStorage.getItem('TerminalId'));

    if (currentTerminalId && !isNaN(currentTerminalId)) {
      return currentTerminalId;
    } else {
      return null;
    }
  }

  public set terminalId(currentTerminalId: number) {
    sessionStorage.setItem('TerminalId', currentTerminalId.toString());
  }

  public get previousState(): any {
    return JSON.parse(sessionStorage.getItem('PreviousState'));
  }

  public set previousState(state: any) {
    sessionStorage.setItem('PreviousState', JSON.stringify(state));
  }

  public get terminalName(): string {
    return this.appState.terminalName;
  }

  public get terminalProperties(): TerminalProperty[] {
    return this.appState.settingParam.TerminalProperties ?? [];
  }

  public set terminalName(terminalName: string) {
    this.appState.terminalName = terminalName;
  }

  public get kioskTerminalState(): boolean {
    return this.appState.isDisableCurrentKioskTerminal;
  }

  public set kioskTerminalState(state) {
    this.appState.isDisableCurrentKioskTerminal = state;
  }

  public set terminalProperties(terminalProperties: TerminalProperty[]) {
    this.appState.settingParam.TerminalProperties = terminalProperties;
  }

  public get isFirstTimeAfterLogin(): boolean {
    return this.appState.isFirstTimeAfterLogin;
  }

  public set isFirstTimeAfterLogin(isFirstTime: boolean) {
    this.appState.isFirstTimeAfterLogin = isFirstTime;
  }

  public set authTokenInfo(currentAuthTokenInfo: any) {
    sessionStorage.setItem('AuthTokenInfo', JSON.stringify(currentAuthTokenInfo));
  }

  public get authTokenInfo(): any {
    return JSON.parse(sessionStorage.getItem('AuthTokenInfo'));
  }

  public get userCode(): string {
    if (this.isImpersonateUser && this.impersonateUserDetails) {
      return this.impersonateUserDetails.code;
    } else {
      return sessionStorage.getItem('UserCode');
    }
  }

  public set userCode(currentUserCode: string) {
    sessionStorage.setItem('UserCode', currentUserCode);
  }

  public get licenseInfo(): LicenseInfo {
    return this.appState.licenseInfo;
  }

  public set licenseInfo(currentLicenseInfo: LicenseInfo) {
    this.appState.licenseInfo = currentLicenseInfo;
  }

  public get applicationType(): any {
    return this.appState.applicationType;
  }

  public set applicationType(currentApplicationType: any) {
    this.appState.applicationType = currentApplicationType;
  }

  public get userId(): number {
    if (this.isImpersonateUser && this.impersonateUserDetails) {
      return this.impersonateUserDetails.id;
    } else {
      return this.appState.userId;
    }
  }

  public set userId(currentUserId: number) {
    this.appState.userId = currentUserId;
  }

  public get userDetails(): UserDetails {
    return this.appState.userDetails;
  }

  public set userDetails(currentUserDetails: UserDetails) {
    this.appState.userDetails = currentUserDetails;
  }


  public get settingParam(): SettingParam {
    return this.appState.settingParam;
  }

  public set settingParam(currentSettingParam: SettingParam) {
    this.appState.settingParam = currentSettingParam;
  }

  public get applicationFeatures(): Array<ApplicationFeatures> {
    return this.appState.applicationFeatures;
  }

  public set applicationFeatures(features: Array<ApplicationFeatures>) {
    this.appState.applicationFeatures = features;
  }

  public get isDebug(): boolean {
    return this.appState.isDebug;
  }

  public set isDebug(isDebug: boolean) {
    this.appState.isDebug = isDebug;
  }

  public get configurations(): Configurations {
    return this.appState.configurations;
  }

  public set configurations(currentConfigurations: Configurations) {
    this.appState.configurations = currentConfigurations;
  }

  public get userPermissions(): UserPermissionModel {
    return this.appState.userPermissions;
  }

  public set userPermissions(currentUserPermission: UserPermissionModel) {
    this.appState.userPermissions = currentUserPermission;
  }

  public get cartOrder(): number {
    return this.appState.cartOrder;
  }

  public set cartOrder(currentCartOrder: number) {
    this.appState.cartOrder = currentCartOrder;
  }

  public get unservedOrdersData(): Array<UnservedOrdersData> {
    return this.appState.unservedOrdersData;
  }

  public set unservedOrdersData(currentUnservedOrdersData: Array<UnservedOrdersData>) {
    this.appState.unservedOrdersData = currentUnservedOrdersData;
  }

  public get productNavigationDetails(): Array<ProductNavigationDetails> {
    return this.appState.productNavigationDetails;
  }

  public set productNavigationDetails(currentProductNavigationDetails: Array<ProductNavigationDetails>) {
    this.appState.productNavigationDetails = currentProductNavigationDetails;
  }

  public get screenBehaviorData(): Array<ScreenBehaviorData> {
    return this.appState.screenBehaviorData;
  }

  public set screenBehaviorData(currentScreenBehaviorData: Array<ScreenBehaviorData>) {
    this.appState.screenBehaviorData = currentScreenBehaviorData;
  }

  public get defaultProductSelected(): Array<DefaultProductSelected> {
    return this.appState.defaultProductSelected;
  }

  public set defaultProductSelected(currentDefaultProductSelected: Array<DefaultProductSelected>) {
    this.appState.defaultProductSelected = currentDefaultProductSelected;
  }

  public get buttonBehavior(): Array<ButtonBehavior> {
    return this.appState.buttonBehavior;
  }

  public set buttonBehavior(currentButtonBehavior: Array<ButtonBehavior>) {
    this.appState.buttonBehavior = currentButtonBehavior;
  }

  public get parentId(): number {
    return this.appState.parentId;
  }

  public set parentId(currentParentId: number) {
    this.appState.parentId = currentParentId;
  }

  public get applicationInitializationConfigurations(): ApplicationInitializationConfigurations {
    return this.appState.applicationInitializationConfigurations;
  }

  public set applicationInitializationConfigurations(currentConfigurations: ApplicationInitializationConfigurations) {
    this.appState.applicationInitializationConfigurations = currentConfigurations;
  }

  public get selectedActiveOrderId(): number {
    return this.appState.selectedActiveOrderId;
  }

  public set selectedActiveOrderId(currentSelectedActiveOrderId: number) {
    this.appState.selectedActiveOrderId = currentSelectedActiveOrderId;
  }

  public get gotoTerminal(): boolean {
    return this.appState.gotoTerminal;
  }

  public set gotoTerminal(currentGoToTerminal: boolean) {
    this.appState.gotoTerminal = currentGoToTerminal;
  }

  public get updateFlag(): boolean {
    return this.appState.updateFlag;
  }

  public set updateFlag(currentUpdateFlag: boolean) {
    this.appState.updateFlag = currentUpdateFlag;
  }

  public get invType(): string {
    return this.appState.invType;
  }

  public set invType(currentInvType: string) {
    this.appState.invType = currentInvType;
  }

  public get invId(): number {
    return this.appState.invId;
  }

  public set invId(currentInvId: number) {
    this.appState.invId = currentInvId;
  }

  public get inventoryOrderReceivingProductList(): Array<InventoryOrderReceivingProductList> {
    return this.appState.inventoryOrderReceivingProductList;
  }

  public set inventoryOrderReceivingProductList(currentInventoryOrderReceivingProductList: Array<InventoryOrderReceivingProductList>) {
    this.appState.inventoryOrderReceivingProductList = currentInventoryOrderReceivingProductList;
  }

  public get inventoryOrderReceivingNewBarcodeList(): Array<InventoryOrderReceivingNewBarcodeList> {
    return this.appState.inventoryOrderReceivingNewBarcodeList;
  }

  public set inventoryOrderReceivingNewBarcodeList(currentInventoryOrderReceivingNewBarcodeList: Array<InventoryOrderReceivingNewBarcodeList>) {
    this.appState.inventoryOrderReceivingNewBarcodeList = currentInventoryOrderReceivingNewBarcodeList;
  }

  public get gotoLoginScreen(): boolean {
    return JSON.parse(sessionStorage.getItem('GotoLoginScreen'));
  }

  public set gotoLoginScreen(currentGoToLoginScreen: boolean) {
    sessionStorage.setItem('GotoLoginScreen', currentGoToLoginScreen.toString());
  }

  public get terminalType(): string {
    return this.appState.terminalType;
  }

  public set terminalType(currentTerminalType: string) {
    this.appState.terminalType = currentTerminalType;
  }

  public get cookieId(): number {
    return parseInt(this._cookieService.get('id'));
  }

  public set cookieId(currentId: number) {
    this._cookieService.set('id', currentId.toString());
  }

  public get currentUser(): any {
    return this.appState.currentUser;
  }

  public set currentUser(user: any) {
    this.appState.currentUser = user;
  }

  // sessionStorage Start
  public get sessionDesignMode(): any {
    return sessionStorage.getItem('DesignMode');
  }

  public set sessionDesignMode(currentDesignMode: any) {
    sessionStorage.setItem('DesignMode', currentDesignMode);
  }

  public get sessionTrainingMode(): any {
    return sessionStorage.getItem('TrainingMode');
  }

  public set sessionTrainingMode(currentTrainingMode: any) {
    sessionStorage.setItem('TrainingMode', currentTrainingMode);
  }

  public get recentTerminalDetails(): TerminalSummary {
    return this.appState.recentTerminal;
  }

  public set recentTerminalDetails(recentTerminalDetails: TerminalSummary) {
    this.appState.recentTerminal = recentTerminalDetails;
  }

  public get loadHomePage(): boolean {
    return this.appState.loadHomePage;
  }

  public set loadHomePage(currentLoadHomePage: boolean) {
    this.appState.loadHomePage = currentLoadHomePage;
  }

  public get enableAttendanceCardSwipe(): boolean {
    return this.appState.enableAttendanceCardSwipe;
  }

  public set enableAttendanceCardSwipe(currentEnableAttendanceCardSwipe: boolean) {
    this.appState.enableAttendanceCardSwipe = currentEnableAttendanceCardSwipe;
  }

  public get sessionApplicationMode(): string {
    return this.appState.applicationMode;
  }

  public set sessionApplicationMode(currentApplicationMode: string) {
    this.appState.applicationMode = currentApplicationMode;
  }

  public get currentUserId(): number {
    return this.appState.currentUserId;
  }

  public set currentUserId(userId: number) {
    this.appState.currentUserId = userId;
  }

  public get externalPassword(): string {
    return sessionStorage.getItem("ExternalPassword");
  }

  public set externalPassword(password: string) {
    sessionStorage.setItem("ExternalPassword", password);
  }

  public get username(): string {
    return sessionStorage.getItem("Username");
  }

  public set username(uname: string) {
    sessionStorage.setItem("Username", uname);
  }

  public get signInAsDiffUserTerminalDetail(): SignInAsDiffUserTerminalDetail {
    return this.appState.signInAsDiffUserTerminalDetail;
  }

  public set signInAsDiffUserTerminalDetail(currentSignInAsDiffUserTerminalDetail: SignInAsDiffUserTerminalDetail) {
    this.appState.signInAsDiffUserTerminalDetail = currentSignInAsDiffUserTerminalDetail;
  }

  public get application(): any {
    return this.appState.application;
  }

  public set application(currentApplication: any) {
    this.appState.application = currentApplication;
  }

  public get statusCloseDrawer(): boolean {
    return this.appState.statusCloseDrawer;
  }

  public set statusCloseDrawer(currentStatusCloseDrawer: boolean) {
    this.appState.statusCloseDrawer = currentStatusCloseDrawer;
  }

  public get cookieTerminalName(): string {
    return this.appState.cookie.terminalName;
  }

  public set cookieTerminalName(currentTerminalName: string) {
    this._cookieService.set('TerminalName', currentTerminalName);
    this.appState.cookie.terminalName = currentTerminalName;
  }

  public get cookieUserId(): number {
    return this.appState.cookie.userId;
  }

  public set cookieUserId(currentUserId: number) {
    this._cookieService.set('UserId', currentUserId.toString());
    this.appState.cookie.userId = currentUserId;
  }

  public get cookieTerminalType(): string {
    return this._cookieService.get('TerminalType');
  }

  public set cookieTerminalType(terminalType: string) {
    this._cookieService.set('TerminalType', terminalType);
    this.appState.cookie.terminalType = terminalType;
  }

  public get cookieCurrentUser(): string {
    return this.appState.cookie.currentUser;
  }

  public set cookieCurrentUser(currentUser: string) {
    this._cookieService.set('CurrentUser', currentUser);
    this.appState.cookie.currentUser = currentUser;
  }

  public get isAuthenticated(): boolean {
    return this.appState.isAuthenticated;
  }

  public set gridRouteContext(gridRouteContext: GridRouteContextInfo) {
    this.appState.gridRouteContext = gridRouteContext;
  }

  public get gridRouteContext(): GridRouteContextInfo {
    return this.appState.gridRouteContext;
  }

  public set isImpersonateUser(value: boolean) {
    sessionStorage.setItem('isImpersonateUser', value.toString());
  }

  public get isImpersonateUser(): boolean {
    return JSON.parse(sessionStorage.getItem('isImpersonateUser'));
  }

  public set impersonateUserDetails(value: any) {
    sessionStorage.setItem('impersonateUserDetails', JSON.stringify(value));
  }

  public get impersonateUserDetails(): any {
    return JSON.parse(sessionStorage.getItem('impersonateUserDetails'));
  }

  public set isAuthenticated(value: boolean) {
    this.appState.isAuthenticated = value;
  }

  // sessionStorage Start
  public removeGotoLoginScreen() {
    return sessionStorage.remove('GotoLoginScreen');
  }

  public get userSignInByButton(): boolean {
    return this.appState.userSignInByButton;
  }

  public set loadedPermissions(permissions: Array<PermissionArguments>) {
    this.appState.loadedPermissions = permissions;
  }

  public get loadedPermissions(): Array<PermissionArguments> {
    return this.appState.loadedPermissions;
  }

  public set userSignInByButton(isUserSignInByButton: boolean) {
    this.appState.userSignInByButton = isUserSignInByButton;
  }

  public get cameraAnnotations(): CameraAnnotationObject {
    return this.appState.cameraAnnotations;
  }

  public set cameraAnnotations(cameraAnnotations: CameraAnnotationObject) {
    this.appState.cameraAnnotations = cameraAnnotations;
  }

  public get stayWithUnsavedFormChanges(): boolean {
    return this.appState.stayWithUnsavedFormChanges;
  }

  public set stayWithUnsavedFormChanges(stayWithUnsavedFormChanges: boolean) {
    this.appState.stayWithUnsavedFormChanges = stayWithUnsavedFormChanges;
  }

  get contactSupportKey() {
    return this.appState.contactSupportKey;
  }

  set contactSupportKey(value) {
    this.appState.contactSupportKey = value;
  }

  public newSettingParams(): SettingParam {
    const settingParams: SettingParam = {
      CurrentTerminalName: '',
      TerminalType: '',
      AccountID: null,
      CameraIP: '',
      SubaccountFontSize: null,
      DebugMode: false,
      TrainingMode: false,
      SubaccountMainColor: '',
      SubaccountHighlightColor: '',
      ProductFontSize: null,
      ShowActiveOrderPanel: false,
      ButtonBarContents: '',
      PromptCustomerReceipt: false,
      PromptMerchantReceipt: false,
      PrintCreditCustomerReceipt: false,
      PrintCreditMerchantReceipt: false,
      ShowButtonBar: false,
      ServerIP: '',
      HeadingButtonAccounts: false,
      HeadingButtonTasks: false,
      FunctionButtons: '',
      UseClockIns: false,
      AutoGratuity: false,
      AutoGratuityManagerOnly: false,
      AutoGratuityPercent: '',
      PublicInternetConnection: false,
      RegisterUSB: false,
      LastDatabaseVersion: '',
      RegisterThroughPrinter: false,
      RegisterCOMPort: '',
      CreditCardType: '',
      ActiveCreditCardIntegrated: false,
      RegisterRepeatCycles: null,
      MinutesBetweenBackups: null,
      EngageCallerID: false,
      RegisterPrinterName: '',
      ShowBreadCrumbs: false,
      BreadCrumbs: false,
      AccountTerm: '',
      SharedAccountIDs: '',
      ServeUponSettle: false,
      CashDrawerConnected: false,
      PaymentTypes: '',
      ParentAccountID: null,
      CustomerID: '',
      ReportDatesEditable: false,
      PrintAddressOnReceipt: false,
      PrintAddressOnOrderReceipt: false,
      PrintEndOfShiftReport: false,
      AutoCloseAccount: false,
      AutoCloseTab: false,
      AutoCloseDaily: false,
      AutoCloseHour: '',
      AutoCloseTerminal: '',
      PrintAccountAddressOnReceipt: false,
      ForceEndOfDay: false,
      PrintAccountAddressOnOrderReceipt: false,
      SortProductComponentsAlphabetically: false,
      TerminalIP: '',
      TerminalPort: '',
      PromptOrderTypes: '',
      CurrentAccountName: '',
      OneActiveOrderPerAccount: false,
      OneActiveOrderPerTab: false,
      CurrentAccountAddressAndNotes: '',
      CurrentAccountTaxExempt: false,
      AcceptMultipleDiscountsPerAccount: false,
      CurrentAccountNotes: '',
      SessionTerm: '',
      ServerTerm: '',
      QuickBooksCashCustomerName: '',
      QuickBooksCreditCustomerName: '',
      QuickBooksSalesTaxAccount: '',
      QuickBooksSalesTaxVendor: '',
      QuickBooksToBePrinted: '',
      SalesTaxPercentage: '',
      CurrentPassword: '',
      SettlePersonalOrdersOnly: false,
      ShowAdvanceOrderButton: false,
      QuitAdministrator: false,
      TaxToGo: false,
      UseNoTaxSeat: false,
      CurrentUser: '',
      GlobalPrinterList: '',
      MultiSelectProducts: false,
      CurrentUserName: '',
      CustomerName: '',
      TimeEntryOnly: false,
      MustClockIn: false,
      PrintClockouts: false,
      ReceiptLogoPath: '',
      AllowNonpaymentNonAccountOrders: false,
      AllowNonpaymentNonAccount: false,
      ReceiptFooter1: '',
      ReceiptFooter2: '',
      DeliveryReceiptCopies: null,
      DeliveryFee: null,
      DeliveryFeeCountsInDrawer: false,
      IgnoreComponentTextOnReceipt: null,
      OrderPrinterFontSize: null,
      ChooseAccountBeforeOrder: false,
      ChooseAccountOptional: '',
      SortComponentsAlphabetically: false,
      ChooseAccountLocationBeforeOrder: false,
      IsMainClientPC: false,
      CurrentVersion: '',
      QuantityKeypad: false,
      GratuitySelfOnly: false,
      GratuityAdministrator: false,
      BackofficeOnlyMode: false,
      DatabaseName: '',
      DisplayAccountLocationOnOrderReceipt: false,
      ChooseAccountBeforeOrdering: false,
      PrintEachOrder: false,
      PrintOnlySelectedItems: false,
      PaygistixVendorName: '',
      ClientPCName: '',
      PrintDrawerClosing: false,
      PromptPrintReceipt: false,
      ErrorOccurred: false,
      ReceiptPrinterFontSize: null,
      ReceiptFontSize: null,
      OrderReceiptFontSize: null,
      ManagerSignedIn: false,
      AdministratorSignedIn: false,
      SubaccountTerm: '',
      StreetAddressForMapping: '',
      CurrentAccountID: null,
      DiscountTaxes: false,
      AssignUserToDrawer: false,
      PreventLogoutUntilDrawerClosed: false,
      DrawerUserID: null,
      CurrentSubaccountID: null,
      CurrentSubaccountName: '',
      CurrentSessionID: null,
      AutoDefer: false,
      TwoMonitors: false,
      ShowDisabledButtons: false,
      AllowCreditCards: false,
      DeductCreditTipsFromDrawer: false,
      ShowDispatchButton: false,
      SignInEachOrder: false,
      LastBackup: null,
      UserID: null,
      JobCodeID: null,
      TerminalId: null,
      TerminalName: '',
      TerminalProperties: [],
      OrderScreenTypeId: null,
      ShowAllServersOrders: false,
      SignInOnEachOrder: false,
      SignOutAfterEachOrder: false,
      ReturnToHomeAfterEachProduct: false,
      ActiveIntegratedCreditCard: false,
      AccountSwitching: '',
      DeferPaymentsAllowed: false,
      AccountTypes: '',
      AccountTypeDefault: '',
      PrintDeliveryReceiptToKitchen: false,
      PrintOrderRecalls: false,
      SoftButtons: false,
      ReceiptAutoHideSec: null,
      ReceiptAutoHide: false,
      IgnorePrintReceipt: false,
      PrintZoneBinOnReceiving: false,
      GiftCardMask: '',
      NO_OVERRIDE_PRICE: null,
      TABLE_TO_GO: '',
      TABLE_TO_GO_NAME: '',
      TABLE_NO_TAX: null,
      TABLE_NO_TAX_NAME: '',
      TABLE_MANUAL_ADJUSTMENT: null,
      BACKSPACE_CHAR: '',
      CLEAR_CHAR: '',
      TAB_CHAR: '',
      ALL_CHAR: '',
      IncomingEmailDomain: '',
      DefaultButtonActiveStateBackgroundColor: '',
      ToggleButtonActiveStateBackgroundColor: '',
      HICGuid: '',
      TotalBaselineExpense: '',
      BaselineExpenses: [],
      MakeTableWorkflowStates: null,
      OrderStatusStates: null,
      HardwareDeviceModel: {
        Id: null,
        DeviceTypeId: null,
        DeviceType: '',
        HICId: null,
        HICName: '',
        Name: '',
        IsActive: false
      },
      ReceiptPrinter: {
        Id: null,
        Name: ''
      },
      CashDrawerPrinter: {
        Id: null,
        Name: ''
      },
      CreditCardTerminal: {
        Id: null,
        Name: '',
        RequestType: ''
      },
      MappedCameraDevices: null,
      DigitalSignage: {
        Id: null,
        Name: ''
      },
      RabbitMQConfig: null,
      OrderPrompts: '',
      UseDeliveryModule: false,
      DBServer: '',
      DBInstanceName: '',
      DBName: '',
      DBUserName: '',
      DBPassword: '',
      CardSignatureReqAmount: null,
      CardSignaturePrintReceipts: false,
      ManagerOnlyOverrideGratuity: false,
      AutoGratuityPct: null,
      ShowOwnOrdersOnly: false,
      // make_table_color= '',
      // make_table_process_color: '',
      // make_table_complete_color= '',
      MakeTableColor: '',
      MakeTableProcessColor: '',
      MakeTableCompleteColor: '',
      MakeTableAccountColor: '',
      MakeTableReadyColor: '',
      MakeTableCallInColor: '',
      MakeTablePagerColor: '',
      MakeTablePartiallyInProgressColor: '',
      FunctionButtonBackgroundColor: '',
      OrderFunctionButtonBackgroundColor: '',
      AccountSelectionBackgroundColor: '',
      DriverSelectionBackgroundColor: '',
      PrintPerOrder: false,
      PrintReceiptOnClose: false,
      MakeTable3Step: false,
      ColorPreference: {
        TrimColor: '',
        HeadingColor: '',
        TrimFontColor: '',
        HeadingFontColor: ''
      },
      AutoNextButtonColor: '',
      AutoNextButtonIcon: '',
      OrderStatusTermPick: '',
      OrderStatusTermPreparing: '',
      OrderEntryTerminalId: null,
      DefaultKitchenPrinterId: null,
      OrderConfirmationScrollSpeed: null,
      SearchButtonNavigationBorderColor: '',
      ShowMenuSearchBox: false,
      MakeTableQuantityColors: null,
      MakeTableThresholdColors: '',
      NewInventoryRecordOnDuplicateAmount: false,
      UseParallelPickScreenAndTimer: false,
      CameraId: null,
      AvailableTenderTypes: null,
      TenderTypes: null,
      OpenDrawerConditions: null,
      RFIDInitiatorSequence: '',
      RFIDEndingSequence: '',
      RFIDErrorCode: '',
      RequireNameOnOrder: '',
      EndOfDay: null,
      WeekStart: '',
      ToasterPosition: '',
      ToasterTimeout: null,
      ToasterCloseButton: false,
      IsGlobalShutoff: false,
      ShowOrderNumberAfterPayment: false,
      OrderNumberIncrement: null,
      LatestSurrogateOrderId: null,
      GiftCardAutoDeductionAmount: null,
      GiftCardAutoDeductionAfterMonth: null,
      GiftcardAddBalanceByCreditCard: false,
      CashDrawerVarianceThreshold: null,
      MakeTableStateChangeDelayMsec: null,
      AutoHidePopoutMessageInterval: null,
      CartTerm: '',
      WebsiteAddress: '',
      IsShowNutritionFactInOnlineOrder: '',
      TenderTypesColors: '',
      PrintCheckDefaultOrderStatus: '',
      MaxUnreadMessagePopoutCount: null,
      InventoryLogPopulateQuantityUsing: null,
      MaxPrepLabels: null,
      InventoryLogStockWarningMinimum: null,
      IsEnableLoyaltyCardProgram: false,
      LoyaltyProgramRequiresMemberInformation: false,
      IsUseMakeTable: false,
      MaximumAllowedDiscount: null,
      IsUsePager: false,
      PagerRange: '',
      LoyalAccountTerm: '',
      RequireNameOnOrderTemporaryDisable: false,
      ShowAllOrders: false,
      ShowTipsInTerminalInfo: false,
      ProductLabelPrinting: 0,
      MessageForcedDisplayInterval: 0,
      OrderClosurePrintAction: '',
      AllowOverridesOnDietaryRestrictions: true,
      IsShowMakeTablePendingState: false,
      AutoCollapseOnlineOrders: false,
      AutoExpandMinutesOnlineOrder: 0,
      AutoServeOrder: false,
      InventoryLogUnchangedQtyWarningMinimumPercent: 0,
      InventoryLogUnchangedQtyWarningMinimumThreshold: 0,
      UserFailedLoginAttemptsLimit: 0,
      IpFailedLoginAttemptsLimit: 0,
      AuthorizeAmountFactor: 0,
      AuthorizeNetApiLoginId: '',
      AuthorizeNetClientKey: '',
      AuthorizeNetTransactionKey: '',
      AllowOrderingFractionalQty: false,
      QuantityChange: '',
      PrintToKitchenOrderTypes: '',
      DefaultProductLabelPrinterId: 0,
      Logo: '',
      DefaultInventoryLabelPrinterId: 0,
      HicHistorySize: 0,
      RecallEntireOrder: false,
      OnlineOrderTerminalId: 0,
      GiftcardTransactionFee: 0,
      AllowCreditCardTip: false,
      AllowAddingTipTillDays: 0,
      EBTType: '',
      AllowEBTPaymentType: false,
      MakeTableNotifyColor: '',
      MakeTableServeColor: '',
      MakeTableNotifyFontColor: '',
      MakeTableServeFontColor: '',
      OrderReadyShowNotifyButtonOnMaketable: '',
      OrderReadyNotifyViaSMSText: '',
      OrderReadyNotifyViaDJTerminal: 0,
      OrderReadyNotifyViaDJText: '',
      OrderReadyNotifyViaDJVoice: '',
      OrderReadyNotifyViaDJVolume: 0,
      OrderReadyNotifyViaAudioNotifier: 0,
      OrderReadyNotifyViaAudioNotifierText: '',
      OrderReadyNotifyViaAudioNotifierVoice: '',
      OrderReadyNotifyViaAudioNotifierVolume: 0,
      FeatureNotificationSMS: false,
      InvoiceAreaButtonText: '',
      TableTerm: '',
      CloseSettleAfterFullPayment: false,
      ShowSubaccountInMakeTable: false,
      IsShowReviewOrder: true,
      IsSendOrderToKitchenImmediately: true,
      Currency: null,
      Locale: null,
      DateFormat: '',
      TimeFormat: 'hh:mm a',
      CurrencySymbol: '',
      PCalendarDateFormat: '',
      PCalendarHourFormat: '12',
      ConfirmationScreenDisplayImageOnAddProduct: false,
      ConfirmationScreenDisplayImageSeconds: null,
      ConfirmationScreenDisplayImageTransitionSeconds: null,
      ConfirmationScreenDisplayImageBackground: null,
      ConfirmationScreenDisplayImageHeaderStyle: null,
      ConfirmationScreenDisplayImageProductStyle: null,
      PhoneNumberMask: DomainConstants.DefaultPhoneMask,
      ShowNonDefaultQuantityOnPickScreen: false,
      IsShowCheckmarkOnToggleButton: false,
      ChangeBackgroundColorOnToggleButton: true,
      ToggleButtonDefaultActiveStateCheckmarkColor: '',
      ToggleButtonActiveStateCheckmarkColor: '',
      IsPrintSizeOnReceipt: false,
      AllowModifyingSubaccountNameAtOrderInvoice: false,
      MaxScheduledOrderHours: 0,
      ScheduleOrderActiveHours: '',
      ResetMenuDisplayFilterSeconds: 0,
      MenuItemHighlightColor: '',
      InventoryReceivingConfirmationWaitTime: 0,
      InventoryReceivingConfirmationWarningText: '',
      IsShowOrderUpdatesNotificationOption: false,
      EnableFormDirtyBitsWarning: false,
      IsShowLoyaltyCardInOnlineOrder: false,
      EnableAudioRecording: false,
      EventEmailDisplayName: '',
      EventEmailFromEmail: '',
      ReportEmailDisplayName: '',
      ReportEmailFromEmail: '',
      OnlineOrderConfirmationEmailDisplayName: '',
      OnlineOrderConfirmationFromEmail: '',
      IsUsePromotion: false,
      MenuDisplayAutoRefreshIntervalMinutes: 10,
      PickScreenRefreshInterval: 3,
      AutoSignOutOnOrderSubmitted: false,
      AutoSignOutOnPaymentCompleted: false,
      AutoSignOutOnUserIdleSeconds: 0,
      BarcodeScanningInitiatorSequence: '',
      BarcodeScanningEndingSequence: '',
      IsShowTipOnMakeTable: false,
      DefaultLocationTerm: '',
      IsShowCookingPanel: false,
      KioskButtonsBackgroundColor: '',
      KioskButtonsBackgroundOpacity: 1,
      OrderKioskOrderCancelledImage: '',
      OrderKioskStartOverButtonText: '',
      OrderKioskIdleTimeoutImage: '',
      OrderKioskNeedMoreTimeButtonText: '',
      OrderKioskCancelOrderButtonText: '',
      OrderKioskOrderConfirmImage: '',
      OrderKioskOrderConfirmAppreciationText: '',
      OrderKioskPleaseSwipeCardImage: '',
      OrderKioskPaymentFailedImage: '',
      OrderKioskPaymentTerminalBusyImage: '',
      OrderKioskCheckoutImage: '',
      OrderKioskAllowPayAtCounter: true,
      OrderKioskRemovePendingOrderIntervalMinutes: 0,
      OrderKioskPaymentStyle: '',
      OrderKioskTitleMessageConfig: '',
      OrderKioskOrderConfirmStyle: '',
      OrderKioskIdleScreenIdleTimeout: 30,
      OrderKioskOrderConfirmScreenIdleTimeout: 30,
      OrderKioskAIShakeProcessingImage: '',
      OrderKioskAIShakeProcessingImageSeconds: null,
      VerifyOrderBeforeSettle: false,
      ItemReadyShowNotifyButtonOnServeScreen: false,
      PromptToChooseUsernameOnMakeTable: false,
      OrderKioskIsDisabledImage: '',
      PickScreenHighlightItemColor: '',
      PickScreenHighlightItemDuration: 0,
    };
    return settingParams;
  }

  public setLocalizationSettings(settingParam: SettingParam) {
    if (settingParam.Locale) {
      import(/* webpackExclude: /\.d\.ts$/ */
        /* webpackMode: "eager" */
        /* webpackChunkName: "i18n-base" */
        `node_modules/@angular/common/locales/${settingParam.Locale}.mjs`)
        .then(lang => registerLocaleData(lang.default));
    }
    settingParam.CurrencySymbol = settingParam.Currency ?
      getCurrencySymbol(settingParam.Currency, 'narrow') : '$';
    settingParam.PCalendarDateFormat = this.setPCalendarDateFormat();
    settingParam.PCalendarHourFormat = this.setPCalendarTimeFormat();
  }

  setPCalendarDateFormat(): string {
    let dateFormat = !this.appState.settingParam.DateFormat ? 'MM-dd-yyyy' : this.appState.settingParam.DateFormat;
    if (dateFormat.includes('MMMM')) {
      dateFormat = dateFormat.replace('MMMM', 'MM');
    } else if (dateFormat.includes('MMM')) {
      dateFormat = dateFormat.replace('MMM', 'M');
    } else if (dateFormat.includes('MM')) {
      dateFormat = dateFormat.replace('MM', 'mm');
    } else if (dateFormat.includes('M')) {
      dateFormat = dateFormat.replace('M', 'm');
    }

    if (dateFormat.includes('yyyy')) {
      dateFormat = dateFormat.replace('yyyy', 'yy');
    } else if (dateFormat.includes('yy')) {
      dateFormat = dateFormat.replace('yy', 'y');
    }
    return dateFormat;
  }

  setPCalendarTimeFormat(): string {
    let timeFormat = !this.appState.settingParam.TimeFormat ? 'hh:mm a' : this.appState.settingParam.TimeFormat;

    if (timeFormat.includes('HH') || timeFormat.includes('H')) {
      timeFormat = '24';
    } else if (timeFormat.includes('hh') || timeFormat.includes('h')) {
      timeFormat = '12';
    } else {
      timeFormat = '12';
    }
    return timeFormat;
  }
}
