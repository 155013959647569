<pos-edit-wrapper heading="Edit Camera Annotation" (close)="cancelEditing()" [headingIcon]="icons.camera"
    [isPopup]="false">
    <form #cameraAnnotationsForm="ngForm" (ngSubmit)='saveCameraAnnotation(cameraAnnotationsForm.valid)'>
        <div class="col-xs-12 pos-page-body border-top">
            <pos-row colXs="12">
                <pos-column class="pt-pix-15">
                    <div class="col-xs-12">
                        <label class="mb-0">Event: {{ cameraAnnotation?.Description }}</label>
                    </div>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Annotation" [validationMessages]="{required: 'Please enter annotation.'}"
                        inputStyle="col-md-9 col-sm-8 col-xs-12">
                        <ngx-codemirror posInput [(ngModel)]="cameraAnnotation.Annotation" name="annotationText"
                            [options]="codeMirrorOptions" [required]="true"></ngx-codemirror>
                        <span class="me-pix-20 mt-pix-n30 relative" style="float: right">
                            <span class="font-20 pointer grey">
                                <pos-icon [icon]="icons.messageCode" class="pointer"
                                    (click)="isOpenVariable = !isOpenVariable">
                                </pos-icon>
                            </span>
                            <div style="position: absolute; z-index: 10;" *ngIf="isOpenVariable" class="col-200 code-variables">
                                <ng-select [items]="tokens" appendTo="body" placeholder="Select token variable"
                                    name="tokens" [(ngModel)]="selectedTokenAttribute" (change)="changeTokenAttribute()"
                                    [isOpen]="true">
                                </ng-select>
                            </div>
                        </span>
                    </pos-form-field>
                </pos-column>

                <pos-column>
                    <pos-form-field>
                        <p-checkbox posInput name="IsActive" #isActive="ngModel" [(ngModel)]="cameraAnnotation.IsActive"
                            binary="true" label="Active">
                        </p-checkbox>
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="form-horizontal col-xs-12 action-button-container page-footer">
            <button class="btn btn-primary btn-lg normal normal btn-save pos-button" type="submit">Save</button>
            <button class="btn btn-secondary btn-lg normal normal btn-cancel pos-button" (click)="cancelEditing()"
                type="button">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
