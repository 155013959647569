import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { TrainingBadge } from '../interface/training-badge';
import { Observable } from 'rxjs';

@Injectable()
export class TrainingBadgeService {

    constructor(private httpService: HttpClient) { }

    newTrainingBadge() {
        const trainingBadgeObj: TrainingBadge = {
            Id: 0,
            Name: '',
            Icon: '',
            ScoreThreshold: null
        };

        return trainingBadgeObj;
    }

    getTrainingBadges(): Observable<TrainingBadge[]> {
        return this.httpService.get<TrainingBadge[]>(`${RuntimeConstants.API_PATH}trainingbadge`);
    }

    getTrainingBadge(trainingBadgeId: number): Observable<TrainingBadge> {
        return this.httpService.get<TrainingBadge>(`${RuntimeConstants.API_PATH}trainingbadge/` + trainingBadgeId);
    }

    getTrainingBadgeImages(): Observable<any> {
        return this.httpService.get(
            `${RuntimeConstants.API_PATH}trainingbadge/images`
        );
    }

    saveTrainingBadge(trainingBadge: TrainingBadge, badgeIcon: File, userId: number): Observable<any> {
        const formData = this.prepareIconFormData(trainingBadge, badgeIcon);
        return this.httpService.post(
            `${RuntimeConstants.API_PATH}trainingbadge/` + userId,
            formData
        );
    }

    deleteTrainingBadge(trainingBadgeId: number): Observable<any> {
        return this.httpService.delete(
            `${RuntimeConstants.API_PATH}trainingbadge/` + trainingBadgeId
        );
    }
    private prepareIconFormData(param: TrainingBadge, iconFile: File, isAvailableIcon: boolean = false): FormData {
        const formData = new FormData();
        if (iconFile) {
            formData.append('file', iconFile);
        }
        formData.append('isAvailableIcon', JSON.stringify({ isUseUploadedIcon: isAvailableIcon }));
        formData.append('model', JSON.stringify(param));
        return formData;
    }
}
