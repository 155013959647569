import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import {
  AlertsService, ApplicationStateService, DomainConstants, RabbitMQService,
  ActiveOrder, OrderItem, OrderItemComponent, ColorUtilityService, SpinnerService
} from 'src/app/shared';
import { TimerService } from '../../services/timer.service';
import { TerminalsService, TerminalProperty } from 'src/app/configurator/terminals';
import * as _ from 'lodash';
import { TimerTerminalProperties } from '../../interfaces/timer-terminal-properties';
import { QueuedProduct } from '../../interfaces/queued-product';
import { UpdateTimerStatusModel } from '../../interfaces/update-timer-status-model';
import { TimerOption } from '../../interfaces/timer-option';
import { finalize } from 'rxjs/operators';
import {
  sync,
  clock,
  stepForward
} from 'src/app/shared/components/icon';
import { TimerBroadcastingService } from '../../services/timer-broadcasting.service';
import { Subscription } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pos-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit, OnDestroy {
  terminalProperties: TimerTerminalProperties = {
    NumberOfSlot: 2,
    PrepGroup: [],
    TimerOptions: '',
    SlotTimes: [],
    ManualAdjustment: true,
    TimerSounds: [],
  };
  isShowRefreshButton: boolean;
  timerTerminalRabbitMQSubscriptions: Array<any> = [];
  queuedProducts: Array<Array<QueuedProduct>> = [];
  timerOptions: Array<TimerOption> = [];
  timerSlot: any;
  isConfiguredAllGroup: boolean = false;
  isShowProductList: boolean;
  headerLinks = {
    tasks: false,
    home: false,
    warning: false,
    admin: false,
    time: false,
    activeOrders: false,
    signIn: false
  };
  icons = {
    sync,
    clock,
    stepForward
  };
  terminalOption = DomainConstants.TimerTerminalOptions;
  timerStates = DomainConstants.TimerStates;
  @ViewChild('timerControl') timerControl: ElementRef;

  // rabbitMq Subscriptions
  rabbitMqOrderServedSubscription: Subscription;
  rabbitMqOrderItemDeleteSubscription: Subscription;
  rabbitMqTimerTerminalBroadcastSubscription: Subscription;
  rabbitMqOrderDeleteSubscription: Subscription;
  rabbitMqOrderUpdateSubscription: Subscription;
  constructor(private alertService: AlertsService,
    private timerService: TimerService,
    private terminalService: TerminalsService,
    private applicationStateService: ApplicationStateService,
    private rabbitMQService: RabbitMQService,
    private colorUtilityService: ColorUtilityService,
    private spinnerService: SpinnerService,
    private timerBroadcastingService: TimerBroadcastingService) { }

  ngOnInit(): void {
    this.getTerminalProperties();
  }

  ngOnDestroy() {
  }

  getTerminalProperties = () => {
    this.terminalService.getTerminalProperties(this.applicationStateService.terminalId)
      .subscribe({
        next: (res: Array<TerminalProperty>) => {
          if (res?.length) {
            _.forEach(res, (terminalProperty) => {
              if (terminalProperty.PropertyKey === DomainConstants.TerminalProperties.TIMER_NUMBER_OF_SLOT.Key) {
                this.terminalProperties.NumberOfSlot = parseInt(terminalProperty.PropertyValue, 0);
              } else if (terminalProperty.PropertyKey == DomainConstants.TerminalProperties.TIMER_PREPGROUP.Key) {
                this.terminalProperties.PrepGroup = (terminalProperty.PropertyValue).split(',');
                this.isConfiguredAllGroup = this.terminalProperties.PrepGroup.includes("All");
              } else if (terminalProperty.PropertyKey == DomainConstants.TerminalProperties.TIMER_OPTIONS.Key) {
                this.terminalProperties.TimerOptions = terminalProperty.PropertyValue;
              } else if (terminalProperty.PropertyKey === DomainConstants.TerminalProperties.TIMER_SLOT_TIMES.Key) {
                this.terminalProperties.SlotTimes = JSON.parse(terminalProperty.PropertyValue);
              } else if (terminalProperty.PropertyKey === DomainConstants.TerminalProperties.MANUAL_ADJUSTMENT.Key) {
                this.terminalProperties.ManualAdjustment = JSON.parse(terminalProperty.PropertyValue);
              } else if (terminalProperty.PropertyKey === DomainConstants.TerminalProperties.TIMER_SOUND.Key) {
                  this.terminalProperties.TimerSounds = JSON.parse(terminalProperty.PropertyValue);
              }
            });
          }
          this.prepareTimerSlots();
          if (this.terminalProperties && this.terminalProperties.TimerOptions === DomainConstants.TimerTerminalOptions.PRECONFIGURED_TIMERS) {
            this.getPreConfiguratedTimerProducts();
          } else if (this.terminalProperties && this.terminalProperties.TimerOptions ===
            DomainConstants.TimerTerminalOptions.WORKFLOW_TIMERS) {
            this.isShowRefreshButton = true;
            this.getWorkFlowTimerProducts(false);
            this.subscribeToOrderUpdatesFromAllMakeTablesAndTimers();
            this.subscribeToTerminalBroadcastQueue();
            this.subscribeToMakeTableExchangeForAllOrderEntryTerminals();
          }
        }, error: this.alertService.showApiError
      });
  }

  prepareTimerSlots = () => {
    this.timerOptions = [];
    if (this.terminalProperties) {
      for (let i = 1; i <= this.terminalProperties.NumberOfSlot; i++) {
        const timerOption: TimerOption = {
          Id: i,
          TimerOptionsProperty: this.terminalProperties.TimerOptions,
          IsManual: this.terminalProperties.TimerOptions !== DomainConstants.TimerTerminalOptions.TIMER_ONLY ? false : true,
          TimerData: {},
          Counter: this.terminalProperties.SlotTimes.length ? parseInt(this.terminalProperties.SlotTimes[i - 1], 10) : 0,
          HasManualAdjustment: this.terminalProperties.ManualAdjustment,
          Sound: this.terminalProperties.TimerSounds.length ? this.terminalProperties.TimerSounds[i-1] :  null,
          start: (timerData) => {
            this.isShowProductList = false;
          },
          stop: (timerData) => { },
          reset: (timerData) => { },
          onComplete: (option) => {
            this.isShowProductList = true;
            // $('#ProductList').css('display', 'block');
            // $('#SlotList').css('display', 'none');
            this.timerSlot = option;
          },
          productRequest: (timerData: QueuedProduct) => {
            this.removeCookingProductsFromList(timerData.OrderId, timerData.ComponentId);
          }
        }
        this.timerOptions.push(timerOption);
      }
    }
  }

  getPreConfiguratedTimerProducts = () => {
    this.spinnerService.show();
    this.timerService.getSalesProductTimerDetails(this.applicationStateService.terminalId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: Array<QueuedProduct>) => {
          if (res?.length && this.terminalProperties?.PrepGroup?.length) {
            if (this.terminalProperties.PrepGroup.length === 1
              && this.terminalProperties.PrepGroup[0] === 'All') {
              this.addPrepGroup(res);
              this.isConfiguredAllGroup = true;
            }

            _.forEach(this.terminalProperties.PrepGroup, (groupName) => {
              const productGroup: Array<QueuedProduct> = [];
              _.forEach(res, (product: QueuedProduct) => {
                if (groupName === product.PrepGroupName && !product.IsUseWorkflowTimer) {
                  product.Color = this.colorUtilityService.getContrastColor(product.PrepButtonColor);
                  productGroup.push(product);
                }
              });
              if (productGroup.length) {
                this.queuedProducts.push(productGroup);
              }
            });
            this.queuedProducts = this.orderByQueuedProducts();
            
          }
        }, error: this.alertService.showApiError
      });
      
  }

  addPrepGroup = (products: Array<QueuedProduct>) => {
    this.terminalProperties.PrepGroup = [];
    _.forEach(products, (product: QueuedProduct) => {
      if (this.terminalProperties.PrepGroup.indexOf(product.PrepGroupName) === -1) {
        this.terminalProperties.PrepGroup.push(product.PrepGroupName);
      }
    });
  }

  getWorkFlowTimerProducts = (isManualRefresh: boolean) => {
    this.spinnerService.show();
    this.timerService.getActiveProductForTimer()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: Array<QueuedProduct>) => {
          if (res?.length && this.terminalProperties?.PrepGroup?.length) {
            let prepGroups: Array<string> = [];
            if (this.terminalProperties.PrepGroup.length === 1
              && this.terminalProperties.PrepGroup[0] === 'All') {
                this.isConfiguredAllGroup = true;
              this.addPrepGroup(res);
            } else {
              prepGroups = this.terminalProperties.PrepGroup;
            }
            this.queuedProducts = [];
            _.forEach(this.terminalProperties.PrepGroup, (groupName) => {
              const productGroup: Array<QueuedProduct> = [];
              _.forEach(res, (product: QueuedProduct) => {
                if (groupName === product.PrepGroupName &&
                  product.IsUseWorkflowTimer &&
                  product.IsShowOnMakeTable &&
                  product.MakeTableText.indexOf('-') !== 0 &&
                  (product.TimerState === DomainConstants.TimerStates.QUEUED
                    || (product.TimerState === DomainConstants.TimerStates.COOKING &&
                      product.TimerId === this.applicationStateService.terminalId))) {
                  let ignoreIfProductInTimerSlot = false;
                  product.Color = this.colorUtilityService.getContrastColor(product.PrepButtonColor);
                  product.MakeTableText = product.MakeTableText + ' (' + product.SurrogateOrderId + ')';
                  if (isManualRefresh) {
                    _.forEach(this.timerOptions, (timerOption: TimerOption) => {
                      if (timerOption.TimerData?.ComponentId === product?.ComponentId &&
                        timerOption.TimerData?.OrderId == product?.OrderId) {
                        ignoreIfProductInTimerSlot = true;
                      }
                    });
                  }
                  if (!ignoreIfProductInTimerSlot) {
                    productGroup.push(product);
                  }
                }
              });
              if (productGroup.length) {
                this.queuedProducts.push(productGroup);
              }
            });
            this.queuedProducts = this.orderByQueuedProducts();
          }
        }, error: this.alertService.showApiError
      });
  }

  removeCookingProductsFromList = (orderId: number, componentId: number) => {
    _.forEach(this.queuedProducts, (queuedItems: Array<QueuedProduct>) => {
      _.remove(queuedItems, (queuedProduct: QueuedProduct) => {
        return queuedProduct.OrderId === orderId &&
          queuedProduct.ComponentId === componentId;
      });
    });
    _.remove(this.queuedProducts, (queuedItems) => {
      return queuedItems.length === 0;
    });
    this.queuedProducts = this.orderByQueuedProducts();
  }

  updateProductList = (orderDetails: ActiveOrder, product: OrderItem, orderItemComponent: OrderItemComponent) => {
    if (orderItemComponent.IsTimedPrep &&
      orderItemComponent.IsUseWorkflowTimer &&
      orderItemComponent && orderItemComponent.TimerState === DomainConstants.TimerStates.QUEUED) {
      const prepGroupName = _.find(this.terminalProperties.PrepGroup, (groupName) => {
        return groupName === orderItemComponent.PrepGroupName;
      });
      const queuedProduct: QueuedProduct = this.getTimerProductObject(product, orderItemComponent, orderDetails);
      let isProductQueued = false;

      // Check in Timer Slots for product is already there or not
      _.forEach(this.timerOptions, (timerOption: TimerOption) => {
        if (timerOption.TimerData?.ComponentId === orderItemComponent.Id &&
          timerOption.TimerData?.OrderId === orderDetails.Id) {
          isProductQueued = true;
        }
      });

      if (!isProductQueued) {
        // Check in Queued Products list
        _.forEach(this.queuedProducts, (items: Array<QueuedProduct>) => {
          _.forEach(items, (item: QueuedProduct) => {
            if (queuedProduct.OrderId === item.OrderId &&
              queuedProduct.ComponentId === item.ComponentId) {
              isProductQueued = true;
            }
          });
        });
      }
      if (!isProductQueued) {
        let productPrepGroupFound = false;
        _.forEach(this.queuedProducts, (productGroup: Array<QueuedProduct>) => {
          if (productGroup[0]?.PrepGroupName === queuedProduct.PrepGroupName && !queuedProduct.MakeTableText.startsWith('-')) {
            productPrepGroupFound = true;
            productGroup.push(queuedProduct);
          }
        });
        if (!productPrepGroupFound 
          && !queuedProduct.MakeTableText.startsWith('-')
          && (this.terminalProperties.PrepGroup.includes(queuedProduct.PrepGroupName) || this.isConfiguredAllGroup)) { // else add product under new PrepGroupName
          const queuedItems = [];
          queuedItems.push(queuedProduct);
          this.queuedProducts = [...this.queuedProducts, queuedItems];
        }
      }
      this.queuedProducts = this.orderByQueuedProducts();
    } else {
      this.removeCookingProductsFromList(orderDetails.Id, orderItemComponent.Id);
    }
    
  }

  getTimerProductObject = (product: OrderItem, orderItemComponent: OrderItemComponent, orderDetails?: ActiveOrder): QueuedProduct => {
    const queuedProduct: QueuedProduct = {
      MakeTableText: orderItemComponent.MakeTableText + ' (' + orderDetails.SurrogateOrderId + ')',
      OrderId: orderDetails.Id,
      SalesProductId: orderItemComponent.SalesProductId,
      ProductId: product.Id,
      ComponentId: orderItemComponent ? orderItemComponent.Id : 0,
      IsTimedPrep: orderItemComponent.IsTimedPrep,
      PrepGroupName: orderItemComponent.PrepGroupName,
      PrepTime: orderItemComponent.PrepTime,
      PrepButtonColor: orderItemComponent.PrepButtonColor,
      IsUseWorkflowTimer: orderItemComponent.IsUseWorkflowTimer,
      TimerState: orderItemComponent ? orderItemComponent.TimerState : '',
      Qty: product.Qty,
      Color: this.colorUtilityService.getContrastColor(orderItemComponent.PrepButtonColor),
      SurrogateOrderId: orderDetails.SurrogateOrderId
    };
    return queuedProduct;
  }

  subscribeToOrderUpdatesFromAllMakeTablesAndTimers = () => {
    this.rabbitMqTimerTerminalBroadcastSubscription = this.rabbitMQService.subscribeToTimerTerminalBroadcastExchange$()
      .subscribe({
        next: (message: any) => {
          const orderDetails: ActiveOrder = message.Payload.OrderDetails;
          _.forEach(orderDetails.OrderItems, (orderItem: OrderItem) => {
            _.forEach(orderItem.OrderProductComponents, (orderItemComponent: OrderItemComponent) => {
              _.forEach(this.timerOptions, (timerOption: TimerOption) => {
                if (timerOption.TimerData && timerOption.TimerData.OrderId === orderDetails.Id &&
                  timerOption.TimerData.ComponentId === orderItemComponent.Id) {
                  timerOption.TimerData.TimerState = orderItemComponent.TimerState;
                }
              });
              this.updateProductList(orderDetails, orderItem, orderItemComponent);
            });
            this.queuedProducts = this.orderByQueuedProducts();
            
          });
        }, error: () => {
          console.log('error while connecting to RabbitMQ.');
        }
      });
  }

  subscribeToTerminalBroadcastQueue = () => {

    this.rabbitMqOrderServedSubscription = this.rabbitMQService.subscribeToOrderServedMessage$()
      .subscribe({
        next: (message: any) => {
          const deletedOrderId = parseInt(message.Payload.OrderId, 0);
          _.forEach(this.queuedProducts, (products: Array<QueuedProduct>, parentIndex) => {
            _.forEach(products, (product: QueuedProduct, childIndex) => {
              if (product && deletedOrderId === product.OrderId) {
                const updatedProduct = this.prepareProductForUpdateState(DomainConstants.TimerStates.DONE,
                  product.OrderId, product.ComponentId);
                this.updateProductState(
                  updatedProduct,
                  product.ProductId, product.ComponentId);
                products.splice(childIndex, 1);
              }
            });
          });
          _.remove(this.queuedProducts, (products) => {
            return products.length === 0;
          });
          this.queuedProducts = this.orderByQueuedProducts();
          _.forEach(this.timerOptions, (timerSlot: TimerOption) => {
            if (timerSlot.OrderId === deletedOrderId) {
              const updatedProduct = this.prepareProductForUpdateState(DomainConstants.TimerStates.DONE, timerSlot.TimerData.OrderId,
                timerSlot.TimerData.ComponentId);
              this.updateProductState(
                updatedProduct,
                timerSlot.TimerData.ProductId, timerSlot.TimerData.ComponentId);
              timerSlot.reset(timerSlot); 
            }
          });
        }, error: () => {
          console.log('error while connecting to RabbitMQ.');
        }
      });
    this.rabbitMqOrderItemDeleteSubscription = this.rabbitMQService.subscribeToOrderItemDeletedMessage$()
      .subscribe({
        next: (message: any) => {
          const orderItemId = parseInt(message.Payload.OrderId, 0);
          if (orderItemId) {
            _.forEach(this.timerOptions, (timerSlot: TimerOption) => {
              if (timerSlot.TimerData && timerSlot.TimerData.ProductId === orderItemId) {
                timerSlot.reset(timerSlot);
              }
            });
            _.forEach(this.queuedProducts, (products: Array<QueuedProduct>) => {
              _.remove(products, (product: QueuedProduct) => {
                return orderItemId === product.ProductId;
              });
            });
            this.queuedProducts = this.orderByQueuedProducts();
          }
        }, error: () => {
          console.log('error while connecting to RabbitMQ.');
        }
      });
  }

  subscribeToMakeTableExchangeForAllOrderEntryTerminals = () => {
    this.rabbitMqOrderUpdateSubscription = this.rabbitMQService.subscribeToOrderUpdatedMessageForAllOrderEntry$()
      .subscribe({
        next: (message) => {
          const orderDetails: ActiveOrder = message.Payload.OrderDetails;
          if (orderDetails && orderDetails.OrderItems && orderDetails.OrderItems.length) {
            _.forEach(orderDetails.OrderItems, (product: OrderItem) => {
              _.forEach(product.OrderProductComponents, (component: OrderItemComponent) => {
                _.forEach(this.timerOptions, (timerSlot: TimerOption) => {
                  if (timerSlot.OrderId === orderDetails.Id &&
                    timerSlot.TimerData.ComponentId === component.Id &&
                    product.OrderUpdate) {
                    timerSlot.TimerData.IsUpdate = true;
                  }
                });
                _.forEach(this.queuedProducts, (queuedProducts: Array<QueuedProduct>) => {
                  _.forEach(queuedProducts, (queuedProduct: QueuedProduct) => {
                    if (queuedProduct.ComponentId === component.Id) {
                      queuedProduct.Qty = product.Qty;
                    }
                  });
                });
              });
            });
          }
        }, error: () => {
          console.log('error while connecting to RabbitMQ.');
        }
      });
    this.rabbitMqOrderDeleteSubscription = this.rabbitMQService.subscribeToOrderDeletedMessageForAllOrderEntry$()
      .subscribe({
        next: (message) => {
          const deletedOrderId = message.Payload.OrderId;
          _.forEach(this.queuedProducts, (queuedProducts: Array<QueuedProduct>) => {
            _.remove(queuedProducts, (queuedProduct: QueuedProduct) => {
              return deletedOrderId === queuedProduct.OrderId;
            });
          });
          _.remove(this.queuedProducts, (queuedProducts) => {
            return queuedProducts.length === 0;
          });
          this.queuedProducts = this.orderByQueuedProducts();
          _.forEach(this.timerOptions, (timerSlot: TimerOption) => {
            if (timerSlot.OrderId === deletedOrderId) {
              timerSlot.reset(timerSlot);
            }
          });
        }, error: () => {
          console.log('error while connecting to RabbitMQ.');
        }
      });
  }

  prepareProductForUpdateState = (timerState: string,
    orderId: number, componentId: number, makeTableState?: string): UpdateTimerStatusModel => {
    const updatedProduct: UpdateTimerStatusModel = {
      TerminalId: this.applicationStateService.terminalId,
      TimerTerminalId: this.applicationStateService.terminalId,
      OrderId: orderId,
      OrderProductComponentId: componentId,
      // ToSelect: false,
      MakeTableState: makeTableState ? makeTableState : null,
      TimerState: timerState
    };
    return updatedProduct;
  }

  updateProductState = (product: UpdateTimerStatusModel, orderProductId: number, componentId: number) => {
    this.timerService.updateProductState(product.OrderId, orderProductId, componentId, product)
      .subscribe({
        next: (res: void) => {
        }, error: this.alertService.showApiError
      });
  }

  skipProduct = (timerData: QueuedProduct) => {
    const updatedProduct = this.prepareProductForUpdateState(DomainConstants.TimerStates.DONE,
      timerData.OrderId, timerData.ComponentId);
    this.updateProductState(updatedProduct, timerData.ProductId, timerData.ComponentId);
    this.removeCookingProductsFromList(timerData.OrderId, timerData.ComponentId);
  }

  cancelClick = () => {
    this.isShowProductList = false;
    // $('#ProductList').css('display', 'none');
    // $('#SlotList').css('display', 'block');
  }
  requestProduct = (timerData: QueuedProduct) => {
    this.isShowProductList = false;
    const data = {
      product: timerData,
      option: this.timerSlot
    }
    this.timerBroadcastingService.startTimerRequestForProduct(data);
  }

  private orderByQueuedProducts() {
    let products = [];
    this.queuedProducts.forEach(x => products.push(_.orderBy(x, ['OrderId', 'MakeTableText'])));
    return products;
  }
}
