import { Injectable } from "@angular/core";
import { IKioskNavigation } from "../interfaces/ikiosk-navigation";
import { ChoiceBehavior } from "./choices-behavior";
import { KioskNavigationService } from "../services/kiosk-navigation.service";
import { KioskScreen } from "../interfaces/kiosk-screen";
import { DomainConstants } from "src/app/shared";

@Injectable({
    providedIn: 'root'
})
export class SelectButtonsBehavior extends ChoiceBehavior {

    constructor(private kioskNavigationService: KioskNavigationService) {
        super();
    }

    execute(navObj: IKioskNavigation, orderNavigation: KioskScreen): Promise<void> {
        if (navObj.button.IsSelected) {
            const selectButtonsBehavior = navObj.button.ChoiceBehaviors.find(x => x.Name == DomainConstants.KioskButtonBehaviors.SelectButtons.Value);
            const buttonIds = selectButtonsBehavior.ButtonBehaviors?.map(x => x.ChoiceId);
            this.kioskNavigationService.changeButtonSelectionState(buttonIds, true);
        }
        return Promise.resolve();
    }
}
