<pos-edit-wrapper (close)="onCancel(true)"
  heading="Choose ingredient to merge into: {{ingredient.Name}} ({{ ingredient.DateAdded | tark_date }})">
  <form #mergeRawIngredient="ngForm" autocomplete="off">
    <div class="col-12 px-4">

      <pos-form-field label="Raw Ingredient">
        <ng-select posInput [items]="rawIngredients" bindLabel="Name" bindValue="Id" placeholder="Select ingredient" appendTo="body"
          [(ngModel)]="sourceIngredientId" name="sourceIngredient">
        </ng-select>
      </pos-form-field>
    </div>
    <div class="col-12 action-button-container popup-footer">
      <button type="submit" class="btn btn-lg btn-primary btn-save pos-button" (click)="mergeIngredient()">Merge</button>
      <button class="btn btn-lg btn-secondary btn-cancel-modal pos-button" type="button" (click)="onCancel(false)">Cancel</button>
    </div>
  </form>
</pos-edit-wrapper>
