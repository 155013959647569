import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderConfirmationComponent } from './order-confirmation/order-confirmation.component';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ComponentsModule, TableConfiguration } from '../shared';
import { ORDER_CONFIRMATION_STATES } from './order-confirmation-routes';
import { HeaderEntryModule } from '../header-entry';
import { OrderConfirmationService } from './order-confirmation/services/order-confirmation.service';
import { RouterModule } from '@angular/router';
import { TableModule } from '@tarktech/tark-ng-utils';
import { UserReviewOrderComponent } from './user-review-order/user-review-order.component';
@NgModule({
  imports: [CommonModule,
    FormsModule,
    ModalModule.forRoot(),
    ComponentsModule,
    TableModule.forRoot(TableConfiguration),
    RouterModule.forChild(ORDER_CONFIRMATION_STATES),
    HeaderEntryModule
  ],
  providers: [OrderConfirmationService],
  declarations: [OrderConfirmationComponent, UserReviewOrderComponent]
})
export class OrderConfirmationModule { }
