import { Component, OnInit, ViewChild } from '@angular/core';
import { ICloseable, SpinnerService, AlertsService, Messages, ModalFormComponent, ModalService, FormUtilityService } from 'src/app/shared';
import { BackupService } from '../../../services';
import { finalize } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'pos-screen-backup-edit',
  templateUrl: './screen-backup-edit.component.html',
  styleUrls: ['./screen-backup-edit.component.scss']
})
export class ScreenBackupEditComponent extends ModalFormComponent implements OnInit, ICloseable {
  get getForm(): NgForm {
    return this.formScreenBackup;
  }
  backupName: string = '';
  @ViewChild('formScreenBackup') formScreenBackup: NgForm;

  constructor(private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private backupService: BackupService,
    modalRef: BsModalRef, modalService: ModalService, formUtilityService: FormUtilityService) {
    super(modalRef, modalService, formUtilityService);
  }

  ngOnInit() {
  }

  saveScreenBackup(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.spinnerService.show();
    this.backupService.createBackup(this.backupName)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: any) => {
          this.alertService.renderSuccessMessage(Messages.BackupCreatedSuccess);
          this.hide({ shouldReload: true });
        }, error: this.alertService.showApiError
      });
  }

  onCancel() {
    this.hide({ shouldReload: false });
  }

}
