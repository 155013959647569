<pos-edit-wrapper heading="{{ type }} Values{{ dataSourceParamName ? ': '+ dataSourceParamName : null }}" (close)="cancel()">
  <form (ngSubmit)="saveQueryValues(queryValuesForm.valid)" #queryValuesForm="ngForm" class="edit-form">
    <div *ngIf="type === queryType.Manual && manualValues.length> 0">
      <div class="form-horizontal div-height overflow-auto-tabs pos-modal-body">
        <div class="row pb-pix-10 pt-pix-10">
          <div class="col-5 theme-separator">
            <strong>Display Name</strong>
          </div>
          <div class="col-6 theme-separator">
            <strong>Value</strong>
          </div>
        </div>
        <div *ngFor="let manualValue of manualValues; let last = last; let index = index" class="row pb-2" [ngClass]="{'mb-pix-10': !last}">
          <div class="col-5 pe-0"
            [ngClass]="{'has-error': (displayName.invalid && queryValuesForm.submitted && (manualValue.Value || manualValue.Value == 0))}">
            <input type="text" name="DisplayName{{index}}" #displayName="ngModel" class="form-control"
              (ngModelChange)="createParameterRow(last)" (keyup)="createParameterRow(last)"
              [(ngModel)]="manualValue.Name" [required]="!last && (manualValue.Value || manualValue.Value == 0)" />
            <span
              *ngIf="displayName.invalid && queryValuesForm.submitted && (manualValue.Value || manualValue.Value == 0)">
              <label class="control-label text-left text-nowrap" *ngIf="displayName.errors?.required">
                Please enter name.</label>
            </span>
          </div>
          <div class="col-6 pe-pix-10"
            [ngClass]="{'has-error': (value.invalid && queryValuesForm.submitted && manualValue.Name)}">
            <input type="text" class="form-control" #value="ngModel" name="value{{index}}" step="0.1"
              (ngModelChange)="createParameterRow(last)" (keyup)="createParameterRow(last)"
              [(ngModel)]="manualValue.Value" [required]="!last && manualValue.Name !== ''" />
            <span *ngIf="value.invalid && queryValuesForm.submitted && manualValue.Name">
              <label class="control-label text-left text-nowrap" *ngIf="value.errors?.required">
                Please enter value.</label>
            </span>
          </div>
          <div class="col-1 mt-pix-4 ps-sm-2 p-0" *ngIf="!last">
            <pos-action-icon [icon]="icons.deleteWhite" (click)="deleteValue(index)"></pos-action-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="pos-modal-body pt-pix-10 pb-0" [ngClass]="{ 'has-error': values.invalid && queryValuesForm.submitted }"
      *ngIf="type === queryType.Query">
      <ngx-codemirror id="dataSourceQuery" name="values" #values="ngModel" class="sql-values" [(ngModel)]="queryValues"
        [options]="codeMirrorOptions" required>
      </ngx-codemirror>
      <span *ngIf="values.invalid && queryValuesForm.submitted">
        <label class="control-label" *ngIf="values.errors?.required">Please enter values.</label>
      </span>
    </div>
    <div class="col-12 action-button-container popup-footer">
      <button class="btn btn-primary btn-lg btn-save pos-button" scrollup type="submit">OK</button>
      <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="cancel()" type="button" value="Cancel">
        Cancel
      </button>
    </div>
  </form>
</pos-edit-wrapper>
