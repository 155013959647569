import { Component, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forEach } from 'lodash';
import { finalize } from 'rxjs/operators';
import { PostSavedConfigurationService } from 'src/app/configurator/post-saved-configuration.service';
import { AlertsService, BaseFormComponent, DomainConstants, FormUtilityService, Messages, ModalFormComponent, ModalService, SpinnerService } from 'src/app/shared';
import { QuickBooksConfigurationParams } from '../../shared/interface/QuickBooksConfigurationParams';
import { QuickBooksConfigurationService } from '../../shared/services/quick-books-configuration.service';
import { cogs } from 'src/app/shared/components/icon/icons';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'pos-quick-books',
  templateUrl: './quick-books.component.html',
  styleUrls: ['./quick-books.component.scss']
})
export class QuickBooksComponent extends ModalFormComponent implements OnInit, OnChanges {  
    parameters: QuickBooksConfigurationParams;
    daysOfWeek = [];
    icons = {
      cogs
    };
    props = {
      controlBoxClass: 'col-lg-3 col-md-5 col-sm-8 col-xs-12'
    }
    @ViewChild('generalWorkflowForm') generalWorkflowForm: NgForm;
    get getForm(): NgForm {
      return this.generalWorkflowForm
    }
    constructor(
      private quickBooksConfigurationService: QuickBooksConfigurationService,
      private spinnerService: SpinnerService,
      private alertService: AlertsService,
      private router: Router,
      private route: ActivatedRoute,
      private postSavedConfigurationService: PostSavedConfigurationService,
      formUtilityService: FormUtilityService,
      cashSalesModalRef: BsModalRef,
      modalService: ModalService,
    ) {
      super(cashSalesModalRef, modalService, formUtilityService);
    }
  ngOnChanges(changes: SimpleChanges): void {
  }
  
    ngOnInit(): void {
      this.parameters = this.quickBooksConfigurationService.newQuickBooksConfigurationParameter();
      this.loadData();
      forEach(DomainConstants.WeekDay, (key, value) => {
        this.daysOfWeek.push({ Name: value });
      });
    }
  
    private loadData() {
      this.spinnerService.show();
      this.quickBooksConfigurationService.getQuickBooksParameters()
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (params: QuickBooksConfigurationParams) => {
            this.parameters = params;
          }, error: this.alertService.showApiError
        });
    }
  
    saveQuickBooksCashSalesConfig(isValid: boolean) {
      if (isValid) {
        this.spinnerService.show();
        this.quickBooksConfigurationService.updateQuickBooksParameters(this.parameters)
          .pipe(finalize(() => {
            this.spinnerService.hide();
          }))
          .subscribe({
            next: () => {
              this.alertService.renderSuccessMessage(Messages.QuickBookConfigurationSaveSuccess);
              this.hide({ shouldReload: true });
            }, error: this.alertService.showApiError
          });
      }
    }
  
    cancel() {
      this.hide({ shouldReload: false });
    }
  
  


}
