import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[selectOnFocus]'
})
export class SelectOnFocusDirective {
    @Input() selectOnFocus = true;
    constructor (private _elRef: ElementRef) {}

    @HostListener('focus') onFocus() {
        if (this.selectOnFocus) {
            this._elRef.nativeElement.select();
        }
    }
}
