<pos-edit-wrapper heading="Sales Tax" (close)="cancel()" [headingIcon]="icons.taxesMoneyBillAlt" [isPopup]="false">
  <form (ngSubmit)="saveTaxes(formTaxes.valid)" #formTaxes="ngForm" name="formTaxes">
    <div class="col-12 pos-page-body border-top pt-pix-5">
      <div class="col-12">
        <div>
          <h3 class="setting-title">Settings</h3>
        </div>
        <div class="col-12 px-pix-5 pt-pix-10">
          <pos-row class="responsive-tax-group">
            <pos-column>
              <pos-row class="mb-pix-10 flex-nowrap">
                <pos-column xs="4" class="col-220">
                  <strong>Tax Group</strong>
                </pos-column>
                <pos-column xs="4" class="col-120">
                  <strong>
                    <span class="display-flex">
                      Percent (0.xx)
                    </span>
                  </strong>
                </pos-column>
                <pos-column xs="2" class="ps-pix-5 col-90 d-flex">
                  <strong>Default</strong>
                  <field-info [info]="fieldInfoMessages.TaxesName" class="pt-pix-1">
                  </field-info>
                </pos-column>
              </pos-row>
              <pos-row *ngFor="let tax of taxes; trackBy: customTrackBy; let last = last; let index = index"
                class="mb-pix-10 flex-nowrap">
                <pos-column xs="4" class="col-220"
                  [ngClass]="{'has-error': (name.invalid && formTaxes.submitted && (tax.Percent || tax.Percent == 0))}">
                  <input type="text" name="name{{index}}" #name="ngModel" class="form-control"
                    (keypress)="createSalesTaxRow(last)" [(ngModel)]="tax.Name"
                    [required]="!last && (tax.Percent || tax.Percent == 0)" />
                  <span *ngIf="name.invalid && formTaxes.submitted && (tax.Percent || tax.Percent == 0)">
                    <label class="control-label text-left text-nowrap" *ngIf="name.errors?.required">
                      Please enter tax name.</label>
                  </span>
                </pos-column>
                <pos-column xs="4" class="col-120"
                  [ngClass]="{'has-error': (percent.invalid && formTaxes.submitted && tax.Name)}">
                  <input type="number" class="form-control" #percent="ngModel" name="percent{{index}}" step="0.1"
                    (keypress)="createSalesTaxRow(last)" [(ngModel)]="tax.Percent"
                    [required]="!last && tax.Name !== ''" />
                  <span *ngIf="percent.invalid && formTaxes.submitted && tax.Name">
                    <label class="control-label text-left text-nowrap" *ngIf="percent.errors?.required">
                      Please enter percent.</label>
                  </span>
                </pos-column>
                <pos-column class="col-90 text-center" xs="2">
                  <div class="label-section" *ngIf="!last">
                    <p-checkbox name="chkDefault_{{index}}" #chkDefault_{{index}}="ngModel"
                      (click)="defaultChanged(index, tax.IsDefault)" [(ngModel)]="tax.IsDefault" binary="true" />
                  </div>
                </pos-column>
                <pos-column xs="1" class="p-0 pt-pix-7"
                  *ngIf="!last && tax.Name && (tax.Percent || tax.Percent == 0) && !tax.IsDefault">
                  <pos-icon [icon]="icons.times" class="red pointer" (click)="removeSalesTax(tax, index)">
                  </pos-icon>
                </pos-column>
              </pos-row>
            </pos-column>
          </pos-row>
          <pos-row>
            <pos-column>
              <pos-form-field [appendPaddingTop]="false">
                <span class="col-xs-3 col-300 d-flex">
                  <p-checkbox posInput name="chkTaxToGo" #chkTaxToGo="ngModel" [(ngModel)]="taxToGo" binary="true"
                    label="Tax To-Go Orders">
                  </p-checkbox>
                  <field-info [info]="fieldInfoMessages.TaxesToGoOrders" class="pt-0 pull-right"></field-info>
                </span>
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field>
                <span class="col-xs-3 col-300 d-flex">
                  <p-checkbox posInput name="chkDiscountTaxes" #chkDiscountTaxes="ngModel" [(ngModel)]="discountTaxes"
                    binary="true" label="Discount Taxes">
                  </p-checkbox>
                  <field-info [info]="fieldInfoMessages.TaxesDiscountTax" class="pt-0 pull-right"></field-info>
                </span>
              </pos-form-field>
            </pos-column>
          </pos-row>
        </div>
      </div>
    </div>
    <div class="col-12 action-button-container page-footer">
      <button class="btn btn-primary btn-lg btn-save pos-button" scrollup type="submit" value="Save">Save</button>
      <button class="btn btn-secondary btn-lg btn-cancel pos-button" (click)="cancel()" scrollup type="button"
        value="Cancel">Cancel</button>
    </div>
  </form>
</pos-edit-wrapper>
