<pos-edit-wrapper heading="End of day" (close)="cancel()" [headingIcon]="icons.endOfDayMoon" [isPopup]="false">
  <form (ngSubmit)="saveEndOfDay()" #endOfDayForm="ngForm" class="edit-form">
    <div class="col-12 pos-page-body border-top pt-pix-5">
      <div class="col-12">
        <h3 class="setting-title">Settings</h3>
        <pos-row class="px-2">
          <pos-column>
            <pos-form-field label="Time" [fieldInfo]="fieldInfoMessages.EndOfDayTime" [fieldInfoPlacement]="'right'">
              <p-calendar posInput [(ngModel)]="endOfDayTime" [timeOnly]="true" [inline]="true"
                [hourFormat]="hourFormat" name="timeEntry">
              </p-calendar>
            </pos-form-field>
          </pos-column>
        </pos-row>
      </div>
    </div>
    <div class="col-12 action-button-container page-footer">
      <button class="btn btn-primary btn-lg btn-save pos-button" scrollup type="submit"
        permissions='[{"Name":"{{permission.name}}", "Level":"{{permission.editLevel}}"}]'>Save</button>
      <button class="btn btn-secondary btn-lg btn-cancel pos-button" routerLink="../" scrollup type="button">Cancel</button>
    </div>
  </form>
</pos-edit-wrapper>
