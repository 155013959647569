import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { WidgetDetails } from '../../interfaces/widget-details';
import { MenuWidgetStyleService } from '../../services/menu-widget-style.service';
import { MenuWidgetService } from '../../services/menu-widget.service';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import * as $ from 'jquery';
import { WidgetComponentProperties } from '../../interfaces/widget-component-properties';
import { MenuDisplayItem } from '../../interfaces/menu-display-item';
import { ImageWidgetStyleConfig } from '../../interfaces/image-widget-style-config';
import { RabbitMQService } from 'src/app/shared/services/rabbitmq/services/rabbitmq.service';

@Component({
  selector: 'pos-menu-image-widget',
  templateUrl: './menu-image-widget.component.html',
  styleUrls: ['./menu-image-widget.component.scss']
})
export class MenuImageWidgetComponent extends BaseWidgetComponent implements OnInit, WidgetComponentProperties {

  @Input() widgetId: any;
  menuDisplayItem: Array<MenuDisplayItem> = [];
  widgetStyle: ImageWidgetStyleConfig;
  widgetData: WidgetDetails;
  imageStyle = {};
  imageFontStyle = {};
  image: string;
  widgetContentStyle = {};

  imagePath = RuntimeConstants.IMAGE_BASE_PATH + '/menu-display';
  terminalName = '';
  constructor(protected menuWidgetStyleService: MenuWidgetStyleService,
    protected menuWidgetService: MenuWidgetService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected applicationStateService: ApplicationStateService,
    protected modalService: ModalService,
    protected rabbitMQService: RabbitMQService) {
    super(menuWidgetService, alertService, spinnerService, applicationStateService, modalService, rabbitMQService);
    this.widgetStyle = this.menuWidgetService.getNewImageWidgetStyle();
    this.widgetData = this.menuWidgetService.getNewWidgetDetails();
    const menuItem = this.menuWidgetService.getNewMenuDisplayItem();
    this.widgetData.MenuDisplayItems.push(menuItem);
  }

  ngOnInit(): void {
    this.loadSubscriptions();
    this.loadWidget(this.widgetId);
  }

  public editWidget(id: number, data?: any): void {
  }

  private loadSubscriptions(): void {
    this.widgetData$.subscribe({
      next: (res: WidgetDetails) => {
        this.menuDisplayItem = res.MenuDisplayItems ? res.MenuDisplayItems : null;
        this.widgetStyle = this.parseJson<ImageWidgetStyleConfig>(res.WidgetStyleConfig);
        this.image = this.menuDisplayItem[0]?.Image;
        if (this.widgetStyle?.BorderStyle) {
            $('.widget-border-' + this.widgetId)?.css(this.menuWidgetStyleService.getBorderStyle(this.widgetStyle?.BorderStyle));
        }
        $('.widget-background-' + this.widgetId)?.css({ 'background': this.widgetStyle?.WidgetBackgroundColor });
        this.imageFontStyle = this.menuWidgetStyleService.getFontStyle(this.widgetStyle?.ItemFontStyle);
        this.widgetContentStyle = this.menuWidgetStyleService.getWidgetContentStyle(this.widgetStyle);
      }
    });
  }



}
