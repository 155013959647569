<pos-list-wrapper [headingIcon]="icons.inventoryProductsStore" heading="Inventory Products" (close)="close()">
    <div class="row">
        <div class="col-12 text-right pt-pix-5">
            <div class="inline-block ps-pix-10 float-right label-section">
                <p-checkbox (onChange)="reloadInventoryProductsWithFilter()" name="Active"
                    #Active="ngModel" [(ngModel)]="isActiveInventory" binary="true" label="Active">
                </p-checkbox>
            </div>
            <div class="inline-block ps-pix-10 float-right col-300">
                <ng-select [items]="inventoryZones" bindLabel="NameWithCode" bindValue="Id"
                    placeholder="Zone" [(ngModel)]="inventoryZoneId" appendTo="body" name="zone"
                    (change)="reloadInventoryProductsWithFilter()">
                </ng-select>
            </div>
            <div class="inline-block ps-pix-10 float-right col-220">
                <ng-select [items]="vendors" bindLabel="name" bindValue="id" placeholder="Vendor"
                    [(ngModel)]="vendorId" appendTo="body" name="Vendor"
                    (change)="reloadInventoryProductsWithFilter()">
                </ng-select>
            </div>
            <div class="inline-block ps-pix-10 float-right col-300">
                <ng-select [items]="inventorySubCategory" bindLabel="InventoryCategoryName" bindValue="id"
                    placeholder="Category" [(ngModel)]="subCategoryId" appendTo="body" name="category"
                    (change)="reloadInventoryProductsWithFilter()">
                </ng-select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 col-sm-12">
            <div class="bulk-update-button">
                <button class="btn btn-primary normal pos-button me-pix-7 mt-pix-10 white" (click)="updateBulkProducts()"
                    [disabled]="!inventoryProductsForBulkUpdateIds.length">
                    Bulk update {{inventoryProductsForBulkUpdateIds.length}} product(s)
                </button>
                <button class="btn btn-info normal pos-button white mt-pix-10" (click)="printProductBarCodes()"
                    [disabled]="!inventoryProductsForBulkUpdateIds.length">
                    Print {{inventoryProductsForBulkUpdateIds.length}} barcode(s)
                </button>
            </div>
            <div class="">
                <ng-template let-data="rowData" #priceTemplate>
                    <div class="text-right" *ngIf="data.Price">
                        {{data.Price | tark_currency}}
                    </div>
                </ng-template>
                <ng-template let-data="rowData" #priceUnitTemplate>
                    <div class="text-right" *ngIf="data.PriceUnit">
                        {{data.PriceUnit | tark_currency}}
                    </div>
                </ng-template>
                <ng-template let-data="rowData" #iconTemplate>
                    <div *ngIf="data.Active" class="green text-center vertical-mid font-13">
                        <pos-icon [icon]="icons.check"></pos-icon>
                    </div>
                </ng-template>
                <ng-template let-data="rowData" #VendorSkuUnitTemplate>
                    <div style="width: 150px;word-wrap: break-word;">
                        {{data.VendorSku}}
                    </div>
                </ng-template>
                <ng-template let-data="rowData" #unitDetailsTemplate>
                    <div style="width: 150px;word-wrap: break-word;">
                        {{data.UnitDetails}}
                    </div>
                </ng-template>
                <ng-template #iconHeaderTemplate>
                </ng-template>
                <p-table #ptable [value]="inventoryProducts" [columns]="inventoryProductColumnsNew"
                    [paginator]="true" [rows]="gridContext.PageSize?gridContext.PageSize:20"
                    [rowsPerPageOptions]="[10, 20, 50, 100]" [paginatorPosition]="'top'"
                    [ngStyle]="{'table-layout':'auto'}" (onFilter)="onTableFiltered($event)" [first]="first"
                    (onPage)="onPageChange($event)" (onSort)="onSort($event)" responsiveLayout="scroll"
                    [sortOrder]="(gridContext.SortDirection === 'Desc' ? -1 : 1)"
                    [sortField]="gridContext.SortProperty" [styleClass]="'data-list'"
                    [exportFilename]="'inventory-products'" [globalFilterFields]="globalfilterColumns">
                    <ng-template pTemplate="caption">
                        <div class="float-right">
                            <pos-icon [icons]='[icons.squareSolidPaginationLightBlue,icons.filterListStack]'
                                class="filter-list font-18">
                            </pos-icon>
                            <div class="p-inputgroup align-items-center">
                                <p-multiSelect [options]="dynamicInventoryProductColumns" tooltipPosition="
                                left" (onChange)="onColumnSelection()" class="product-dynamic-columns"
                                    [(ngModel)]="selectedDynamicColumns" optionLabel="HeaderText"
                                    [style]="{width: '33px' , top:'11px' , left: '-11px'}" defaultLabel="Select columns">
                                </p-multiSelect>
                                <input class="p-inputtext p-widget p-state-default p-corner-all p-component"
                                    pInputText placeholder="Filter..." [(ngModel)]="gridContext.Filter"
                                    (input)="ptable.filterGlobal($event.target.value, 'contains')"
                                    #searchBox>
                            </div>
                        </div>
    
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr class="table-header">
                            <th style="width: 37px" class="text-center">
                                <p-checkbox name="bulkUpdateAll"
                                    (onChange)="addAllProductsForBulkUpdate()"
                                    [(ngModel)]="isBulkUpdateAllProducts" binary="true">
                                </p-checkbox>
                            </th>
                            <th *ngFor="let col of columns" class="font-weight-bold"
                                [pSortableColumn]="col.Field" [pSortableColumnDisabled]="!col.IsSortable"
                                [ngStyle]="{'width': col.Width, 'word-wrap':'break-word'}">
                                {{col.HeaderText}}
                                <p-sortIcon *ngIf="col.IsSortable" [field]="col.Field"></p-sortIcon>
                            </th>
                            <th style="width: 60px !important;" class="cell-edit" *ngIf="editPermission"
                                colspan="2">
                                <div title="New" (click)="editInventoryProduct(0, null)">
                                    <pos-action-icon [icon]="icons.plusWhite" iconText="Add"
                                        class="no-wrap" backgroundClasses="bg-add d-inline-block">
                                    </pos-action-icon>
                                </div>
                            </th>
                            <th style="width: 89px !important;" class="cell-delete pli-5"
                                *ngIf="editPermission || readOnlyPermission" colspan="2">
                                <pos-export-dropdown class="d-inline-block" (onPrint)="printDiv()"
                                    (onCSV)="exportCSV()"></pos-export-dropdown>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-record let-columns="columns">
                        <tr>
                            <td style="width: 37px" class="text-center">
                                <p-checkbox name="bulkUpdate" #bulkUpdate="ngModel"
                                    (onChange)="addProductForBulkUpdate(record)"
                                    [(ngModel)]="record.IsAddToBulkUpdate" binary="true">
                                </p-checkbox>
                            </td>
                            <ng-container *ngFor="let col of columns">
                                <td class="font-weight-bold" *ngIf="col.Type === gridColumnTypes.GridColumn"
                                    [ngStyle]="{'width': col.Width, 'word-wrap':'break-word'}">
                                    {{record[col.Field]}}
                                </td>
                                <td *ngIf="col.Type === gridColumnTypes.DateColumn"
                                    [ngStyle]="{'width': col.Width, 'word-wrap':'break-word'}">
                                    {{record[col.Field] | tark_date}}
                                </td>
                                <td *ngIf="col.Field === 'CalculatedQty'" style="width: auto"
                                    class="grey text-right" 
                                    [ngClass]="record.IsOnOrder && record.Active && !record.IsSkipInventory ? 'bg-light-green' : (!record.IsSkipInventory && record.Active) ? 
                            ((record.CalculatedQty < (record.Min + ((record.Max-record.Min) * 0.2))) && record.OffsiteQty > 0) ? 'bg-orange-light_1-important' :
                            (record.CalculatedQty <= 0 ? (record.Min ? 'bg-light-red-important black' : ''):
                            (record.CalculatedQty > 0 && record.CalculatedQty <= record.Min) ? 'bg-light-orange-important black' :
                            (record.CalculatedQty < (record.Min + ((record.Max-record.Min) * 0.2)))? 'bg-light-blue-important black' : '') 
                            : ''">
                                    <span *ngIf="!record.IsSkipInventory">
                                            {{record.CalculatedQty}}{{record.OffsiteQty > 0 ? (' / '+ record.OffsiteQty) : ''}}
                                    </span>
                                </td>
                                <td style="width: auto" *ngIf="col.Type === gridColumnTypes.TemplateColumn">
                                    <ng-container
                                        *ngTemplateOutlet="col.itemTemplate; context:{rowData:record}">
                                    </ng-container>
                                </td>
                            </ng-container>
    
                            <td style="width: 0.2%;" colspan="4"
                                class="cell-edit text-center pointer cell-padding vertical-mid"
                                *ngIf="editPermission">
                                <a title="Edit" (click)="editInventoryProduct(record.Id, record)" scrollup>
                                    <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
                                </a>
                                <a class="pointer" title="Dashboard" (click)="openDashboard(record)">
                                    <pos-action-icon [icon]="icons.dashboardList"></pos-action-icon>
                                </a>
                                <span class="inline-block">
                                    <ng-container
                                        *ngTemplateOutlet="popOverForInventoryProduct;context:{rowData:record}">
                                    </ng-container>
                                </span>
                            </td>
                            <td style="width: 89px !important;" colspan="2"
                                class="cell-edit text-center pointer cell-padding vertical-mid"
                                *ngIf="!editPermission && readOnlyPermission">
                                <a class="pointer" title="Dashboard" (click)="openDashboard(record)">
                                    <pos-action-icon [icon]="icons.dashboardList"></pos-action-icon>
                                </a>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td [attr.colSpan]="columnsLength">
                                No records found
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</pos-list-wrapper>

<ng-template #popoverForInventoryProductName let-data="rowData">
    <span #pop="bs-popover" class="grey popover-zone pointerStyle pointer" placement="right"
        (click)="openInventoryProductConsumption(data)  " [outsideClick]="true"
        [popover]="inventoryProductNamePopoverTemplate" triggers="click" #popoverTooltip><span
            tooltip="Click to see products" placement="top">{{data.Name}}</span></span>
</ng-template>
<ng-template #popOverForDashboard let-data="rowData">
    <div class="text-center mt-pix-n4 overflow-wrap">
        <a class="action-btn grey pointer">
            <pos-action-icon [icon]="icons.dashboardList"></pos-action-icon>
        </a>
    </div>
</ng-template>
<ng-template #popOverForInventoryProduct let-data="rowData">
    <div class="text-center mt-pix-n4 overflow-wrap">
        <a class="pointer" #pop="bs-popover" placement="left" (click)="onLinkPopOver(data,pop)" [outsideClick]="true"
            [popover]="vendorPopoverTemplate" triggers="click">
            <pos-action-icon [icon]="icons.barsWhite"></pos-action-icon>
        </a>
    </div>
</ng-template>
<ng-template #vendorPopoverTemplate>
    <div class="">
        <ul>
            <li class="listbullet pointer">
                <a class="grey" (click)="openBarcodeAddEdit()">
                    <pos-icon [icon]="icons.barcode" class="pe-pix-7 m-l-5">
                    </pos-icon>
                    Barcodes
                </a>
            </li>

            <li class="listbullet pointer">
                <a class="grey" (click)="openInventoryReceived()">
                    <pos-icon [icon]="icons.inventoryReceivedTruck" class="pe-pix-3 m-l-5">
                    </pos-icon>
                    Inventory Received
                </a>
            </li>

            <li class="listbullet pointer">
                <a class="grey" (click)="openPrepRecipe()">
                    <pos-icon [icon]="icons.inventoryPrepUtensils" class="pe-pix-10 m-l-5">
                    </pos-icon>
                    Prep Recipe
                </a>
            </li>

            <li class="listbullet pointer">
                <a class="grey" (click)="openRecipesIncluded()">
                    <pos-icon [icon]="icons.listAlt" class="pe-pix-7 m-l-5">
                    </pos-icon>
                    Recipes Included
                </a>
            </li>

            <li class="listbullet pointer">
                <a class="grey" (click)="openRecipeEditor()">
                    <pos-icon [icon]="icons.shoppingCart" class="pe-pix-5 m-l-5">
                    </pos-icon>
                    Recipe Editor
                </a>
            </li>
            <li class="listbullet pointer">
                <a class="grey ps-pix-4" (click)="openMergeProduct()">
                    <pos-icon [icon]="icons.codeBranch" class="pe-pix-7 m-l-5">
                    </pos-icon>
                    Merge Product
                </a>
            </li>
            <li class="listbullet pointer" *ngIf="!this.inventoryProductData.IsPrepRecipe">
                <a class="grey" (click)="markAsOrdered()"
                    [ngStyle]="{'cursor': inventoryProductData && inventoryProductData.IsOnOrder?'not-allowed':'pointer'}">
                    <pos-icon [icon]="icons.checkCircleSolid" class="pe-pix-7 m-l-5">
                    </pos-icon>
                    Mark as Ordered
                </a>
            </li>

            <li class="listbullet pointer">
                <a class="grey" (click)="openCopyNutritionAndIngredients()">
                    <pos-icon [icon]="icons.clone" class="pe-pix-7 m-l-5">
                    </pos-icon>
                    Copy Nutrition and Ingredients
                </a>
            </li>

            <li class="listbullet pointer" [hidden]="!inventoryProductData.IsPrintPrepLabel">
                <a class="grey" (click)="showPrintStockLabel()" title="Print Stock Label">
                    <pos-icon [icon]="icons.prepLabelPriting" class="pe-pix-7 m-l-5">
                    </pos-icon>
                    Print Stock Label
                </a>
            </li>

            <li class="listbullet pointer">
                <a class="grey" (click)="flashLights()">
                    <pos-icon [icon]="icons.lightBulb" class="pe-pix-10 ps-pix-2">
                    </pos-icon>
                    Flash Lights
                </a>
            </li>

            <li class="listbullet pointer">
                <a class="grey pointer" (click)="productHistory()">
                    <pos-icon [icon]="icons.history" class="pe-pix-10 ps-pix-2">
                    </pos-icon>History
                </a>
            </li>

        </ul>
    </div>
</ng-template>
<pos-print-table [hidden]="true"></pos-print-table>

<ng-template #inventoryProductNamePopoverTemplate>
    <pos-show-consumption [inventoryProductId]="inventoryProductConsumptionId"></pos-show-consumption>
</ng-template>
