import { Injectable } from '@angular/core';
import { BaseCrudService } from 'src/app/shared';
import { KioskChoiceBehavior } from '../interfaces';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { KioskBehaviorExecution } from '../kiosk-behaviors/kiosk-behavior-execution';

@Injectable({
  providedIn: 'root'
})
export class OrderKioskChoiceBehaviorService extends BaseCrudService<KioskChoiceBehavior> {

  constructor(httpClient: HttpClient) {
    super('kiosk-choice-behavior', httpClient);
  }

  getAllBehavior(choiceId: number): Observable<Array<KioskChoiceBehavior>> {
    return this.httpService.get<Array<KioskChoiceBehavior>>(`${this.apiEndpoint}/behavior/${choiceId}`);
  }

  newBehavior() {
    return {
      Id: 0,
      ButtonId: 0,
      Name: '',
      Ordinal: 0,
      Value: '',
      Choice: null,
      DisplayName: '',
      DisplayValue: ''
    } as KioskChoiceBehavior;
  }

  executeBehavior(kioskBehavior: KioskBehaviorExecution) {
    return this.httpService.post(`${this.apiEndpoint}/execute`, kioskBehavior);
  }
}
