import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { salesGroupThLarge, salesGroupClock, infoCircle, salesGroupCommentsDollar } from 'src/app/shared/components/icon';
import { SalesGroup } from '../../interface';
import { Messages, DomainConstants } from 'src/app/shared/constants';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { NgForm } from '@angular/forms';
import { SalesGroupService } from 'src/app/shared/services/sales-group.service';
import { ParameterService } from '../../../../shared/services/parameter.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { finalize } from 'rxjs/operators';
import { SettingParam } from 'src/app/shared/interface/setting-param';
import { forkJoin } from 'rxjs';
import { TerminalsService } from 'src/app/configurator/terminals/services/terminals.service';
import { BaseCrudComponent, FormUtilityService, HardwareService } from 'src/app/shared';

@Component({
    selector: 'pos-sales-group-edit',
    templateUrl: './sales-group-edit.component.html'
})
export class SalesGroupEditComponent extends BaseCrudComponent<SalesGroup> implements OnInit {

    @Input() id = 0;
    @Input() fromListPage: number = null;
    @ViewChild('salesGroupForm', { static: true }) salesGroupForm: NgForm;
    @Output() Close = new EventEmitter<any>();
    @Output() formChange = new EventEmitter<NgForm>();
    salesGroup: SalesGroup;
    props = {
        labelClass: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
        controlBoxClass: 'col-lg-8 col-md-8 col-sm-8 col-xs-12'
    }
    public icons = {
        salesGroupThLarge: salesGroupThLarge,
        salesGroupClock,
        infoCircle: infoCircle,
        salesGroupCommentsDollar
    };
    get getForm(): NgForm {
        return this.salesGroupForm
    }
    isMakeTableCookingState = false;
    selectedSalesGroupKitchenPrinters: any = [];
    infoMakeTableCookingState = Messages.InfoMakeTableCookingState;
    settingParam = this.applicationStateService.settingParam;
    kitchenPrinters: any = [];
    prepSecondsConvertToTime: Date;
    kitchenPrinterInfo = 'If not specified, will not print';

    constructor(protected applicationStateService: ApplicationStateService,
        protected salesGroupService: SalesGroupService,
        private parameterService: ParameterService,
        protected alertService: AlertsService,
        protected spinnerService: SpinnerService,
        private hardwareService: HardwareService,
        private terminalService: TerminalsService,
        formUtilityService: FormUtilityService) {
        super(salesGroupService, alertService, spinnerService, formUtilityService);
        this.scrollToTop();
    }

    ngOnInit() {
        this.salesGroup = this.salesGroupService.newSalesGroup();
        this.saveSuccessMessage = Messages.SalesGroupSaveSuccess;
        this.dataSubscription();
    }

    private dataSubscription(): void {
        if (this.id > 0) {
            this.spinnerService.show();
            this.loadData().pipe(finalize(() => {
                this.spinnerService.hide();
            }))
                .subscribe({ next: (res) => {
                    this.salesGroup = res;
                    this.secondsConvertToTime(this.salesGroup.PrepTime);
                    this.formChange.emit(this.salesGroupForm);
                }, error: this.alertService.showApiError });
        } else {
            this.salesGroupForm.reset();
            this.salesGroup = this.salesGroupService.newSalesGroup();
        }
    }

    public loadDependencies(): void {
        if (this.id >= 0) {
            this.selectedSalesGroupKitchenPrinters = [];
            if (!this.settingParam.MakeTableWorkflowStates) {
                this.spinnerService.show();
                this.parameterService.getParameterForTerminal(this.applicationStateService.terminalId)
                    .pipe(finalize(() => {
                        this.spinnerService.hide();
                    }))
                    .subscribe({ next: (response: SettingParam) => {
                        this.applicationStateService.settingParam = response;
                        this.settingParam = this.applicationStateService.settingParam;
                        this.setPrepSettingVisibility();
                    }, error: this.alertService.showApiError });
            } else {
                this.setPrepSettingVisibility();
            }

            this.spinnerService.show();
            const salesGroupObservable = [];
            salesGroupObservable.push(this.getKitchenPrinters());
            if (this.id > 0) {
                salesGroupObservable.push(this.salesGroupService.getMappedKitchenPrinters(this.id));
            }

            forkJoin(salesGroupObservable)
                .pipe(finalize(() => {
                    this.spinnerService.hide();
                }))
                .subscribe({ next: (responses: any) => {
                    this.kitchenPrinters = responses && responses[0] ? responses[0].filter(k => k.IsActive) : [];
                    if (this.id === 0 && responses && responses[0]) {
                        this.getDefaultKitchenPrinter(responses[0]);
                    }
                    this.getSalesCategoryKitchenPrintersForInfo(responses[0]);
                    if (this.id > 0 && responses && responses[1]) {
                        const selectedKitchenPrinters = [];
                        responses[1].forEach((kitchenPrinter: any) => {
                            selectedKitchenPrinters.push(kitchenPrinter);
                        });
                        this.selectedSalesGroupKitchenPrinters = selectedKitchenPrinters;
                    } else {
                        this.selectedSalesGroupKitchenPrinters = [];
                    }
                    this.formChange.emit(this.salesGroupForm);
                }, error: this.alertService.showApiError });
        }
    }

    private getKitchenPrinters(): any {
        this.kitchenPrinters = [];
        return this.hardwareService.getHardwaresOfType(DomainConstants.HardwareTypes.KitchenPrinter);
    }

    private setPrepSettingVisibility(): void {
        this.settingParam.MakeTableWorkflowStates.forEach((makeTableWorkflowState: any) => {
            if (makeTableWorkflowState.Code === DomainConstants.MakeTableStates.COOKING
                && makeTableWorkflowState.IsSelected) {
                this.isMakeTableCookingState = true;
            }
        });
    }

    private getDefaultKitchenPrinter(kitchenPrinterDevices: any): any {
        this.spinnerService.show();
        this.getDefaultKitchenPrinterParameter()
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({ next: (parameter: any) => {
                if (parameter) {
                    const defaultKitchenPrinter = kitchenPrinterDevices.find(k => k.Id === Number(parameter.Value));
                    if (defaultKitchenPrinter) {
                        const selectedKitchenPrinters = [];
                        selectedKitchenPrinters.push(defaultKitchenPrinter);
                        this.selectedSalesGroupKitchenPrinters = selectedKitchenPrinters;
                    }
                }
            }, error: this.alertService.showApiError });
    }

    getSalesCategoryKitchenPrintersForInfo = (kitchenPrinterDevices) => {
        this.getDefaultKitchenPrinterParameter()
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({ next: (parameter: any) => {
                if (parameter) {
                    const defaultKitchenPrinter = kitchenPrinterDevices.find(k => k.Id === Number(parameter.Value));
                    if (defaultKitchenPrinter) {
                        this.kitchenPrinterInfo = `If not specified, will print to: ${defaultKitchenPrinter.Name} <i> (Inherited from default configuration) </i>`;
                    }
                }
            }, error: this.alertService.showApiError });
    }

    private getDefaultKitchenPrinterParameter(): any {
        return this.terminalService.getParameter(
            this.applicationStateService.terminalId, this.terminalService.parameterNames.DEFAULT_KITCHEN_PRINTER);
    }

    public secondsConvertToTime(seconds: number): void {
        if (seconds > 0) {
            const lowestDate = new Date(1970, 0, 1);
            lowestDate.setHours(0, 0, seconds, 0);
            this.prepSecondsConvertToTime = lowestDate;
        } else {
            this.prepSecondsConvertToTime = null;
        }
    }

    public submit(isSalesGroupFormValid: boolean): void {
        if (!isSalesGroupFormValid) {
            return;
        }
        this.salesGroup.KitchenPrinterList = this.selectedSalesGroupKitchenPrinters;
        if (this.salesGroup.IsTimedPrep) {
            this.salesGroup.IsUseWorkflowTimer = this.salesGroup.IsUseWorkflowTimer;
            this.salesGroup.PrepTime = this.salesGroup.PrepTime;
            this.salesGroup.PrepButtonColor = this.salesGroup.PrepButtonColor;
        } else {
            this.salesGroup.IsUseWorkflowTimer = false;
            this.salesGroup.PrepTime = null;
            this.salesGroup.PrepButtonColor = null;
        }
        this.save(this.salesGroup);
    }

    public onSaveSuccess(model: SalesGroup): void {
        this.Close.next({ shouldReload: true, salesGroup: model });
    }

    public cancelEditing(): void {
        this.scrollToTop();
        this.Close.next({ shouldReload: false });
    }

    private scrollToTop(): void {
        window.scrollTo(0, 0);
    }
}
