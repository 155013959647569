<pos-list-wrapper [headingIcon]="icons.scroll" heading="Macro Scripts" (close)="close()">
  <div class="row">
    <div class="col-md-12 col-sm-12">
      <ng-table #macroScripts [search]="true" [sort]="true" [paginator]="true" [columns]="macroScriptColumns"
        [data]="macroScriptList" [exportFilename]="'data-source'">
      </ng-table>
    </div>
  </div>
</pos-list-wrapper>
  <pos-print-table [hidden]="true"></pos-print-table>
