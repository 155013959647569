<div class="kiosk-background"
    [style.background-image]="backgroundImage ? 'url(\'' + baseImagePath + backgroundImage + '\')' : null">
    <div class="order-details-div" *ngIf="!isPaymentRetry"
        [ngStyle]="{'align-items': kioskPaymentStyle.OrderDetailVerticalAlignment && !kioskPaymentStyle.OrderDetailVerticalPosition ? kioskPaymentStyle.OrderDetailVerticalAlignment : 'center'}">
        <div class="order-detail"
            [ngStyle]="{'background': kioskPaymentStyle.OrderDetailBackgroundColor ? kioskPaymentStyle.OrderDetailBackgroundColor : '#47a1ab', 'color': kioskPaymentStyle.OrderDetailFontColor ? kioskPaymentStyle.OrderDetailFontColor : '#fff', 'top': kioskPaymentStyle.OrderDetailVerticalPosition}">
            <p>Order #{{order?.SurrogateOrderId}}</p>
            <p>Your total is {{ order?.GrandTotal | tark_currency }}</p>
        </div>
    </div>
    <div class="footer-buttons" [style.--footer-bg]="footerBackgroundColor" *ngIf="isPaymentRetry">
        <button type="button" class="btn btn-lg white kiosk-button" (click)="makePayment()">
            Try Card Again
        </button>
        <button type="button" class="btn btn-lg white kiosk-button" (click)="payAtCounter()">
            Print Receipt & Pay At Counter
        </button>
        <button type="button" class="btn btn-lg white kiosk-button" (click)="cancelOrder()">
            Cancel Order
        </button>
    </div>
</div>
