import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconSpecification } from '../icon';

@Component({
  selector: 'pos-edit-wrapper',
  templateUrl: './edit-wrapper.component.html',
  styleUrls: ['./edit-wrapper.component.scss']
})
export class EditWrapperComponent {
  @Input() heading: string;
  @Input() isPopup = true;
  @Input() headingIcon: IconSpecification;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  closeEditing() {
    this.close.next({shouldReload: false});
  }

}
