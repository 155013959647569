import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';
import { EquipmentProperty } from '../interface/equipment-property';
import { Observable } from 'rxjs';
import { EquipmentTypeProperty } from '../interface/equipment-type-property';

@Injectable()
export class EquipmentPropertyService extends BaseCrudService<EquipmentProperty> {

    equipmentPropertiesApiUrl = '';
    constructor(httpService: HttpClient) {
        super('equipmentproperties', httpService);
        this.equipmentPropertiesApiUrl = RuntimeConstants.API_PATH + 'equipmentproperties';
    }
    newEquipmentProperty(Id: number= 0, name : string = '' , value: string = '', description = '') {
        return {
            Id: Id,
            Name: name,
            Value: value,
            Description: description
        };
    }

    newEquipmentTypeProperty(equipmentTypeId: number = 0 , equipmentPropertyId:number = 0, equipmentPropertyName : string =''): EquipmentTypeProperty{
        return {
            Id: 0,
            EquipmentProperty: null,
            EquipmentTypeId: equipmentTypeId,
            EquipmentPropertyId: equipmentPropertyId,
            Description: '',
            EquipmentPropertyName: equipmentPropertyName,
        }
    }

    deleteEquipmentProperty(equipmentPropertyId: number): Observable<any> {
        return this.httpService.delete(this.equipmentPropertiesApiUrl + '/' + equipmentPropertyId);
    }

    getEquipmentProperties(): Observable<any> {
        return this.httpService.get(this.equipmentPropertiesApiUrl);
    }
}
