import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RuntimeConstants } from 'src/app/shared/constants';
import { Observable } from 'rxjs';

@Injectable()
export class MainService {
  mainApiUrl = '';
  constructor(private httpService: HttpClient) {
    this.mainApiUrl = RuntimeConstants.API_PATH + 'main';
  }

  getConfigurationsForWebApp(): Observable<any> {
    return this.httpService.get(`${this.mainApiUrl}/GetConfigurationsForWebApp`);
  }

  getApplicationInitalizationConfiguration(): Observable<any> {
    return this.httpService.get(`${this.mainApiUrl}/GetApplicationInitalizationConfiguration`);
  }

  getApplicationVersion(): Observable<any> {
    return this.httpService.get(`${this.mainApiUrl}/GetApplicationVersion`);
  }

  initializeApplication(): Observable<any> {
    return this.httpService.get(`${this.mainApiUrl}/InitializeApplication`);
  }

  getCashDrawerDetails(terminalId: number): Observable<any> {
    return this.httpService.get(`${RuntimeConstants.API_PATH}cash-drawer/` + terminalId);
  }

  getContactSupportKey(): Observable<any> {
    return this.httpService.get(`${this.mainApiUrl}/tawk-to-contact-support-key`);
  }

}
