<pos-edit-wrapper (close)="onCancel()" heading="Import ingredient">
    <form #formImportIngredients="ngForm" (ngSubmit)="parseIngredients(formImportIngredients.valid)">
        <div class="col-12 pos-modal-body screen-scroll">
            <pos-row class="mx-0">
                <pos-column class="p-0">
                    <pos-form-field>
                        <p-checkbox posInput name="Common" #Common="ngModel" [(ngModel)]="addInExisting" binary="true"
                            label="Add to existing ingredients">
                        </p-checkbox>
                    </pos-form-field>
                </pos-column>
            </pos-row>
            <pos-row *ngIf="addInExisting">
                <pos-column>
                    <pos-form-field label="Sub Ingredient"
                        [validationMessages]="{required: 'Please enter sub ingredient.'}">
                        <input posInput class="form-control" name="txtSubIngredient" #txtSubIngredient="ngModel"
                            [disabled]="!addInExisting" [(ngModel)]="subIngredient" type="text"
                            [required]="addInExisting" />
                    </pos-form-field>
                </pos-column>
            </pos-row>
            <pos-row class="mx-0">
                <pos-column class="p-0">
                    <pos-form-field label="Ingredients" [validationMessages]="{required: 'Please enter ingredients.'}"
                        inputStyle="col-12">
                        <textarea posInput class="form-control overflow-auto h-300" name="txtIngredients"
                            [(ngModel)]="ingredients" [required]="true" #txtIngredients="ngModel" rows="15"></textarea>
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="clearfix"></div>
        <div class="col-12 action-button-container popup-footer">
            <div class="form-horizontal">
                <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" value="Save"><pos-icon
                        [icon]="icons.ingredientImport"></pos-icon> Import</button>
                <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" value="Cancel"
                    (click)="onCancel()">Cancel</button>
            </div>
        </div>
    </form>
</pos-edit-wrapper>
