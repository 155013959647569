import { Component, OnInit, ViewChild } from '@angular/core';
import { finalize } from 'rxjs/internal/operators/finalize';
import { AlertsService, ApplicationStateService, ConfirmDeleteModalComponent, Messages, StringUtils, } from 'src/app/shared';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { DomainConstants } from 'src/app/shared/constants/domain-constants';
import { ReportSubscription } from '../../interfaces/report-subscription';
import { ReportService } from '../../services/report.service';
import { editWhite, ban, copy, checkCircleWhite, deleteWhite, subscriptionBookmark } from './../../../../shared/components/icon/icons';
import { Permissions, Levels } from 'src/app/shared/constants';
import { filter, find, forEach, groupBy, mapValues, sortBy } from 'lodash';
import { ReportSubscriptionEditComponent } from '../report-subscription-edit/report-subscription-edit.component';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { GridColumn, TemplateColumn } from '@tarktech/tark-ng-utils';
import cronstrue from 'cronstrue';
import { CopyReportSubscriptionComponent } from '../copy-report-subscription/copy-report-subscription.component';
declare let $: any;
@Component({
    selector: 'pos-report-subscription',
    templateUrl: './report-subscription.component.html',
    styleUrls: ['./report-subscription.component.scss']
})
export class ReportSubscriptionComponent implements OnInit {

    daysOfTheWeek = DomainConstants.ReportDaysOfTheWeek;
    reportSubscription: Array<ReportSubscription> = [];
    userId: number;
    icons = {
        editWhite,
        ban,
        copy,
        checkCircleWhite,
        deleteWhite,
        subscriptionBookmark
    };
    permissions = Permissions;
    accessLevels = Levels;
    subscriptions: any = [];
    reportSubscriptions: Array<any> = [];
    noSubscriptionMessage = 'You have not subscribed for any reports yet.';
    height = $(window).height() - 140;
    hasSubscriptions = true;
    inventoryProductColumns: Array<GridColumn> = [];
    screenHeight = (window.innerHeight - 250) + 'px';
    @ViewChild('infoTemplate', { static: true }) private infoTemplate: any;
    @ViewChild('operationTemplate', { static: true }) private operationTemplate: any;
    constructor(private spinnerService: SpinnerService,
        private applicationStateService: ApplicationStateService,
        private reportService: ReportService,
        private alertService: AlertsService,
        private modalService: ModalService) { }

    ngOnInit(): void {
        this.columnConfiguration();
        this.setDefaults();
        this.loadReportSubscriptions();
        $(window).resize(() => {
            this.height = $(window).height() - 140;
        });
    }

    private columnConfiguration() {
        const operationColumn = new TemplateColumn({
            HeaderText: '',
            itemTemplate: this.operationTemplate,
            Width: '100px'
        });
        const dataColumn = new TemplateColumn({
            itemTemplate: this.infoTemplate,
            HeaderText: 'Subscriptions'
        });
        this.inventoryProductColumns = [
            dataColumn,
            operationColumn
        ] as Array<GridColumn>;
    }

    setDefaults(): void {
        this.userId = this.applicationStateService.userId;
    }

    loadReportSubscriptions(): void {
        this.spinnerService.show();
        this.reportService.getReportSubscription(this.userId)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (res: Array<ReportSubscription>) => {
                    this.reportSubscription = res;
                    if (this.reportSubscription.length > 0) {
                        const sortedSubscriptions = filter(sortBy(this.reportSubscription, 'ReportName'), report => {
                            if (report.TimeString) {
                                report.Time = this.parseTime(report.TimeString);
                            }
                            return report.ScheduleId;
                        });
                        this.hasSubscriptions = sortedSubscriptions.length > 0;
                        this.subscriptions = mapValues(groupBy(sortedSubscriptions, 'ReportName'), (values) => {
                            return groupBy(values, 'ScheduleId');
                        });
                        this.reportSubscriptions = [];
                        forEach(this.subscriptions, (subscription) => {
                            forEach(subscription, (report) => {
                                const day = find(this.daysOfTheWeek, x => x.Value === report[0].DayOfWeek);
                                report[0].DayOfWeek = day?.Text;
                            });
                            this.reportSubscriptions.push(subscription);
                        });
                    } else {
                        this.hasSubscriptions = false;
                    }
                }, error: this.alertService.showApiError
            });
    }

    parseTime(timeString: string): Date {
        if (timeString === '') { return null; }

        const time = timeString.match(/(\d+)(:(\d\d))?\s*(p?)/i);
        if (time == null) { return null; }

        let hours = parseInt(time[1], 10);
        if (hours === 12 && !time[4]) {
            hours = 0;
        } else {
            hours += (hours < 12 && time[4]) ? 12 : 0;
        }
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(parseInt(time[3], 10) || 0);
        date.setSeconds(0, 0);
        return date;
    }

    updateActivation(reportSubscriptionId: number, isActive: boolean): void {
        this.reportService.updateReportSubscriptionActivation(reportSubscriptionId, isActive)
            .subscribe({ next: res => { this.loadReportSubscriptions(); }, error: this.alertService.showApiError });
    }

    showSubscribeToReportModal(reportId: number, reportName: string): void {
        const modalRef = this.modalService.getModalWrapper(ReportSubscriptionEditComponent);
        const modal = modalRef.show({
            keyboard: false,
            animated: false,
            class: 'vertical-center modal-lg modal-max-width-70',
            initialState: {
                reportId: reportId,
                reportName: reportName
            }
        });
        modal.close.subscribe((res) => {
            if (res && res.success) {
                this.loadReportSubscriptions();
            }
        });
    }

    showCopySubscriptionModel() {
        const modalRef = this.modalService.getModalWrapper(CopyReportSubscriptionComponent);
        const modal = modalRef.show({
            keyboard: false,
            animated: false,
            class: 'vertical-center modal-lg',
            initialState: {
            }
        });
        modal.close.subscribe((res) => {
            if (res && res.reloadOnClose) {
                this.loadReportSubscriptions();
            }
        });
    }

    confirmDeleteReportSubscription(subscriptionId, reportName) {
        const confirmDelete = this.modalService.show(ConfirmDeleteModalComponent, {
            animated: false,
            class: 'vertical-center',
            'backdrop': 'static',
            initialState: {
                message: StringUtils.format(Messages.ConfirmDeleteReportSubscriptions,
                    { 'reportName': reportName })
            }
        });
        confirmDelete.close.subscribe(res => {
            if (res && res.shouldDelete) {
                this.deleteReportSubscription(subscriptionId);
            }
        });
    }

    deleteReportSubscription = (subscriptionId) => {
        this.spinnerService.show();
        this.reportService.deleteReportSubscription(subscriptionId)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (res) => {
                    this.loadReportSubscriptions();
                }, error: this.alertService.showApiError
            });
    }

    convertParamValue(dataType: string, val: string, query: string): string {
        if (val) {
            let paramValue = '';
            if (dataType != null) {
                switch (dataType.toLowerCase()) {
                    case 'date':
                        {
                            if ((val.toLowerCase().indexOf('now') !== -1 || val.toLowerCase().indexOf('today') !== -1) &&
                                (val.indexOf('+') !== -1 || val.indexOf('-') !== -1)) {
                                let periodType = 'day';
                                if (val[val.length - 1] === 'h') {
                                    periodType = 'hour';
                                } else if (val[val.length - 1] === 'm') {
                                    periodType = 'minute';
                                }
                                let period;
                                if (val.indexOf('+') !== -1) {
                                    period = parseInt(val.substring(val.indexOf('+') + 1, val.length - 1), 10);
                                    paramValue = period + ' ' + periodType + (period > 1 ? 's' : '') + ' after';
                                } else {
                                    period = parseInt(val.substring(val.indexOf('-') + 1, val.length - 1), 10);
                                    paramValue = period + ' ' + periodType + (period > 1 ? 's' : '') + ' before';
                                }

                                paramValue += val.toLowerCase().indexOf('now') !== -1 ? ' now' : ' today';
                            }

                            if (paramValue.length === 0) {
                                paramValue = val;
                            }
                        }
                        break;
                    case 'bit':
                        paramValue = val === '1' ? 'Yes' : 'No';
                        break;
                    case 'int':
                        paramValue = ((val === '-1') && query != null && query !== '') ? 'All' : val;
                        break;
                    case 'csv':
                        paramValue = ((val === '-1' || val === '0') && query != null && query !== '') ? 'All' : val;
                        break;
                    default:
                        paramValue = val;
                        break;
                }
            }
            // remove NULL from a Parameter value
            if (paramValue.indexOf('NULL,') !== -1) {
                paramValue = paramValue.substring(paramValue.indexOf('NULL,') + 5, paramValue.length);
            }

            if (paramValue === 'NULL') {
                paramValue = '';
            }
            return paramValue;
        } else {
            return val;
        }
    }

    getCronStrue = (schedule) => schedule ? cronstrue.toString(schedule) : '';
}
