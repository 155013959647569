import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ParameterForReport } from '../../interfaces';
import { ReportColumn, ReportDetails } from '../../interfaces/report-details';
import { ReportService } from '../../services';
import { printReportManagement, sync, upload, rssSquare, circle, caretDown } from 'src/app/shared/components/icon';
import { cloneDeep, find, forEach, forOwn, groupBy, isNumber, remove, sum } from 'lodash';
import { AlertsService, DomainConstants, ModalService, PrintTableService, SpinnerService, ApplicationStateService } from 'src/app/shared';
import { ReportSubscriptionEditComponent } from '../report-subscription-edit/report-subscription-edit.component';
import { DateColumn, GridColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { CurrencyColumn } from '@tarktech/tark-ng-utils/table/currency-column';
import { DecimalColumn } from '@tarktech/tark-ng-utils/table/decimal-column';
import { ExportService } from 'src/app/shared/services/export.service';
import { finalize } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { Format } from '@tarktech/tark-ng-utils/table/format-type';
declare let $: any;
@Component({
  selector: 'pos-report-details',
  templateUrl: './report-details.component.html',
  styleUrls: ['./report-details.component.scss']
})
export class ReportDetailsComponent implements OnInit, OnChanges {

  @Input() reportId: number;
  @Input() reportData: ReportDetails;
  @Input() reportParam: ParameterForReport;
  @Output() reRunReport: EventEmitter<any> = new EventEmitter<any>();
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  reportDate = new Date();
  isShowReportDetails = true;
  icons = {
    printReportManagement, sync, upload, rssSquare, circle, caretDown
  };
  tableStyle;
  reportColumns: Array<GridColumn> = [];
  reportDataTypes = DomainConstants.ReportDataTypes;
  reportWithHorizontalScroll = DomainConstants.ReportsWithHorizontalScroll;
  groupCategory: string = null;
  sellerReportColumns: Array<ReportColumn> = [];
  reportGridColumns: Array<GridColumn> = [];
  isMobileMode = false;
  userHasEmail = false;
  constructor(private modalService: ModalService,
    private reportService: ReportService,
    private printTableService: PrintTableService,
    private exportService: ExportService,
    private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private datePipe: DatePipe,
    private applicationStateService: ApplicationStateService) {
    this.reportParam = reportService.newParameterForReport();
    this.reportData = reportService.newReportDetails();
    this.userHasEmail = applicationStateService.userDetails.EmailAddress ? true : false;
  }

  ngOnInit(): void {
    if (this.reportParam.ReportName === 'Top Sellers') {
      this.groupCategory = 'ReportType';
      this.sellerReportColumns = cloneDeep(this.reportData.ReportConfiguration.ReportColumns);
      remove(this.reportData.ReportConfiguration.ReportColumns, column => column.FieldName === 'ReportType');
    }
    this.prepareReportSummary();
    this.reportColumns = this.prepareColumns(this.reportData.ReportConfiguration.ReportColumns);
    this.checkIsMobileMode();
    $(window).resize(() => {
      this.checkIsMobileMode();
    });
    if (this.reportWithHorizontalScroll.includes(this.reportParam.ReportName)) {
      this.tableStyle = { width: "max-content" };
    }
  }

  checkIsMobileMode() {
    this.isMobileMode = $(window).width() < 991 ? true : false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isShowReportDetails = true;
  }


  prepareReportSummary() {
    if (this.reportData.ReportSummary.length && this.reportData.Data.length) {
      let newReport: any = {};
      forEach(this.reportData.ReportSummary, (item) => {
        const column = find(this.reportData.ReportConfiguration.ReportColumns, col => col.Name === item.ColumnName);
        if (item.SettingName === DomainConstants.ReportColumnSettingName.Total) {
          this.prepareTotalColumn(this.reportData.Data, column, item.SettingName, newReport);
        } else if (column && item.SettingName === DomainConstants.ReportColumnSettingName.Alignment) {
          column.Align = item.SettingValue;
        } else if (column && item.SettingName === DomainConstants.ReportColumnSettingName.Format) {
          column.Format = item.SettingValue;
        }
      });
      this.generateGroupSummary();
      if (Object.keys(newReport).length) {
        this.reportData.Data.push(newReport);
      }
    }
    setTimeout(() => {
      if ($('.p-datatable-tbody') && $('.p-datatable-tbody')[1] && $('.tab-pane')[0] && $('.p-datatable-tbody')[1].clientHeight >= ($('.tab-pane')[0].clientHeight - 189)) {
        $('.p-datatable-scrollable-header-box').addClass('p-datatable-scrollable-header-box-pad');
      }
    }, 500);
  }

  prepareTotalColumn(reportData: any[], column: ReportColumn, settingName: string, reportRow: any) {
    const titleColumn = find(this.reportData.ReportConfiguration.ReportColumns, col => col.Index === 0);
    reportRow[titleColumn.FieldName] = settingName;
    if (column) {
      const total = sum(reportData.map(x => isNumber(x[column.FieldName]) ? parseFloat(x[column.FieldName]) : 0));
      reportRow[column.FieldName] = total;
    }
  }

  generateGroupSummary() {
    const columns = find(this.reportData.ReportSummary, item => item.SettingName === DomainConstants.ReportColumnSettingName.GroupField);
    const groupByColumns = columns ? JSON.parse(columns.SettingValue) : null;
    if (groupByColumns) {
      this.reportData.Data = this.groupSummaryHelper(this.reportData.Data, 0, groupByColumns);
    }
  }

  groupSummaryHelper(data: any[], index: number, groupByColumns: any[]) {
    if (index >= groupByColumns.length)
      return data;
    const groupedData = groupBy(data, groupByColumns[index].GroupField)
    const reportData = []
    forOwn(groupedData, (value: any[], key) => {
      const newGroupSummary = {}
      forEach(this.reportData.ReportSummary, (item) => {
        const column = find(this.reportData.ReportConfiguration.ReportColumns, col => col.Name === item.ColumnName);
        if (item.SettingName === DomainConstants.ReportColumnSettingName.Total) {
          this.prepareTotalColumn(value, column, groupByColumns[index]?.GroupField + " Subtotal", newGroupSummary);
        }
      });
      const nestedReport = (index == groupByColumns.length - 1) ? value : this.groupSummaryHelper(value, index+1, groupByColumns);
      reportData.push(...nestedReport);
      newGroupSummary['rowBgColor'] = groupByColumns[index].Color;
      reportData.push(newGroupSummary);
    })
    return reportData;
  }

  prepareColumns(reportColunms: Array<ReportColumn>) {
    const columns: Array<GridColumn> = [];

    forEach(reportColunms, (column) => {
      if ((column.DataType === this.reportDataTypes.Money || column.DataType === this.reportDataTypes.Int
        || column.DataType === this.reportDataTypes.Decimal) && column.Format === DomainConstants.ReportColumnFormat.Currency) {
        columns.push(new CurrencyColumn({
          HeaderText: column.Name, Field: column.FieldName,
          TextAlign: column.Align ? TextAlign[column.Align] : TextAlign.Right
        }));
      } else if ((column.DataType === this.reportDataTypes.Money || column.DataType === this.reportDataTypes.Int
        || column.DataType === this.reportDataTypes.Decimal) && column.Format === DomainConstants.ReportColumnFormat.Numeric) {
        columns.push(new DecimalColumn({
          HeaderText: column.Name, Field: column.FieldName,
          TextAlign: column.Align ? TextAlign[column.Align] : TextAlign.Right
        }));
      } else if ((column.DataType === this.reportDataTypes.Varchar || column.DataType === this.reportDataTypes.NVarchar)
        && column.Format === DomainConstants.ReportColumnFormat.Date) {
        columns.push(new DateColumn({
          HeaderText: column.Name, Field: column.FieldName, Format: Format.Date,
          TextAlign: column.Align ? TextAlign[column.Align] : TextAlign.Left
        }));
      } else if (column.DataType === this.reportDataTypes.Varchar || column.DataType === this.reportDataTypes.NVarchar ||
        column.DataType === this.reportDataTypes.Bit || column.DataType === this.reportDataTypes.Real) {
        columns.push(new GridColumn({
          HeaderText: column.Name, Field: column.FieldName,
          TextAlign: column.Align ? TextAlign[column.Align] : TextAlign.Left
        }));
      } else if (column.DataType === this.reportDataTypes.Int && column.Name !== 'RowsCount') {
        columns.push(new GridColumn({
          HeaderText: column.Name, Field: column.FieldName,
          TextAlign: column.Align ? TextAlign[column.Align] : TextAlign.Right
        }));
      } else if (column.DataType === this.reportDataTypes.Decimal) {
        columns.push(new DecimalColumn({
          HeaderText: column.Name, Field: column.FieldName,
          TextAlign: column.Align ? TextAlign[column.Align] : TextAlign.Right
        }));
      } else if (column.DataType === this.reportDataTypes.Money) {
        columns.push(new CurrencyColumn({
          HeaderText: column.Name, Field: column.FieldName,
          TextAlign: column.Align ? TextAlign[column.Align] : TextAlign.Right
        }));
      } else if (column.DataType === this.reportDataTypes.Date) {
        columns.push(new DateColumn({
          HeaderText: column.Name, Field: column.FieldName, Format: Format.Date,
          TextAlign: column.Align ? TextAlign[column.Align] : TextAlign.Left
        }));
      } else if (column.DataType === this.reportDataTypes.DateTime) {
        columns.push(new DateColumn({
          HeaderText: column.Name, Field: column.FieldName,
          TextAlign: column.Align ? TextAlign[column.Align] : TextAlign.Left
        }));
      }
    });
    return columns;
  }

  reloadReport() {
    this.reRunReport.emit();
  }

  exportAction() {
    const fileName = this.reportData.ReportConfiguration.Name.replace(/\s/g, '-');
    forEach(this.reportData.Data, (v) => {
      if (v && v.RowsCount) {
        delete v.RowsCount;
      }
    });

    this.exportService.exportCSV(fileName, this.reportData.Data, null, this.reportColumns);
  }

  prepareParamsForExport() {
    const reportParams = cloneDeep(this.reportParam.parameters);
    forEach(reportParams, x => {
      switch (x.DataType) {
        case 'DATE':
          x.InitialValue = this.datePipe.transform(x.InitialValue, this.applicationStateService.settingParam.DateFormat);
          break;
        case 'INT':
          if (x.ManualValues || x.QueryValues) {
            x.InitialValue = find(x.ParameterQueryValues, drp => drp.Id === x.InitialValue)?.Name;
          }
          break;
        case 'CSV':
          x.InitialValue = find(x.ParameterQueryValues, drp => drp.Id === x.InitialValue)?.Name;
          break;
        case 'DROPDOWN':
          x.InitialValue = find(x.ParameterQueryValues, drp => drp.Id === x.InitialValue)?.Name;
          break;
        case 'BIT':
          x.InitialValue = x.InitialValue ? 'Yes' : 'No';
          break;
        case 'MULTISELECT':
          let initialValue = '';
          forEach(x.InitialValue.split(','), value => {
            initialValue += find(x.ParameterQueryValues, drp => drp.Id === value)?.Name + ';';
          });
          x.InitialValue = initialValue;
          break;
        default:
          return;
      }
    });
    return reportParams;
  }

  sendEmailToUser = () => {
    this.spinnerService.show();
    this.reportService.sendEmailToUser(this.reportId, this.reportParam.parameters)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
        }, error: this.alertsService.showApiError
      });
  }

  printDiv() {
    let gridColumnsForPrint = this.reportColumns;
    if (this.reportParam.ReportName === 'Top Sellers') {
      gridColumnsForPrint = this.prepareColumns(this.sellerReportColumns);
    }
    const params = '<h3>' + this.reportParam.ReportName + '</h3>\n' +
      this.prepareParamsForExport().map(x => x.InitialValue ? '<li>' + x.Label + ': ' + x.InitialValue + '</li>' : '').join('\n');
    this.printTableService.printEmitter.next({ gridColumns: gridColumnsForPrint, gridData: this.reportData.Data, headerContent: params, isMentionReportCreatedDate: true });
  }

  openSubscriptionModal() {
    const modalRef = this.modalService.getModalWrapper(ReportSubscriptionEditComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center modal-lg modal-max-width-70',
      'backdrop': 'static',
      initialState: {
        reportId: this.reportId,
        reportName: this.reportParam.ReportName
      }
    });
    modal.close.subscribe(() => { });
  }

  closeReportDetails() {
    this.close.emit();
  }

}
