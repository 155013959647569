import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { RuntimeConstants } from 'src/app/shared/constants';
import { SettingParam } from 'src/app/shared/interface';
import { ConfirmOrderStyle } from '../interfaces/config/confirm-order-style';

@Injectable({
  providedIn: 'root'
})
export class ConfirmOrderService {

  idleTimeoutConfigApiBasePath = RuntimeConstants.API_PATH + 'order-confirm-config';
  constructor(private httpClient: HttpClient) { }

  newConfirmOrderStyle = (): ConfirmOrderStyle => {
    return {
      BackgroundColor: '#b3dbdb',
      FontColor: 'white',
      VerticalPossition: null,
      VerticalAlignment: 'center'
    } as ConfirmOrderStyle;
  }

  getConfirmOrderConfig(): Observable<SettingParam> {
    return this.httpClient.get<SettingParam>(`${this.idleTimeoutConfigApiBasePath}`);
  }

  save(params, image: File): Observable<SettingParam> {
    const formData = this.prepareImageForm(params, image);
    return this.httpClient.post<SettingParam>(this.idleTimeoutConfigApiBasePath, formData);
  }

  prepareImageForm(params, imageFile: File): FormData {
    const formData = new FormData();
    if (imageFile) {
      formData.append('file', imageFile);
    }
    formData.append('model', JSON.stringify(params));
    return formData;
  }
}
