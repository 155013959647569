import { externalApiTest, externalApiCopy, externalApiLink } from './../../../../shared/components/icon/icons';
import { Component, ViewChild, OnInit, TemplateRef } from '@angular/core';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { ExternalApiService } from '../../../../shared/services/external-api.service';
import { AlertsService, Messages, DomainConstants, ConfirmDeleteComponent, AuthenticationService, ModalBackdropService, BaseGridComponent, ApplicationStateService } from 'src/app/shared';
import { ExternalApi } from '../../interface/external-api';
import * as _ from 'lodash';
import { GridColumn, EditColumn, DeleteColumn, LinkColumn, TableComponent } from '@tarktech/tark-ng-utils';
import { Permissions, Levels } from '../../../../shared/constants';
import { finalize } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
declare let $: any;

@Component({
    selector: 'pos-external-api-list',
    templateUrl: './external-api-list.component.html'
})
export class ExternalApiListComponent extends BaseGridComponent implements OnInit {
    @ViewChild('confirmExternalApiDelete') private confirmExternalApiDelete: ConfirmDeleteComponent;
    externalApis: Array<ExternalApi> = [];
    externalApiColumn: Array<GridColumn> = [];
    apiVerbs = DomainConstants.ApiVerbs;
    externalAPIResponse: any = {};
    deleteExternalApi: ExternalApi;
    @ViewChild('responseModal') responseModal: TemplateRef<any>;
    @ViewChild('gridComponent', {static: true}) gridComponent: TableComponent;
    responseModalRef: BsModalRef;
    permission = {
        name: Permissions.SystemConfigurationApiManager,
        readOnlyLevel: Levels.ReadOnly,
        editLevel: Levels.Edit
    };
    icons = { externalApiLink };
    public message = Messages.ConfirmDeleteExternalApi;
    constructor(
        private externalApiService: ExternalApiService,
        private alertService: AlertsService,
        private spinnerService: SpinnerService,
        private router: Router,
        protected route: ActivatedRoute,
        protected applicationStateService: ApplicationStateService,
        private authenticationService: AuthenticationService,
        public bsModalService: BsModalService,
        private modalBackdropService: ModalBackdropService
    ) {
        super(applicationStateService, route);
        this.reloadExternalApiList();
    }
    ngOnInit(): void {
        this.configureGridColumns();
        this.gridComponent.context = this.gridContext;
    }
    private configureGridColumns() {

        const editColumn = new EditColumn({
            Clicked: (data) => {
                this.onEditExternalApi(data);
                $('html, body').animate({ scrollTop: 0 }, 'medium');
            },
            Width: '132px',
            AddIcon: new LinkColumn({
                Clicked: () => {
                    this.onAddExternalApi();
                },
                ToolTip: 'New'
            })
        });

        const deleteColumn = new DeleteColumn({
            Clicked: (data) => {
                this.deleteButton(data);
            },
            Width: '0'
        });

        const copyColumn = new LinkColumn({
            Clicked: (data) => {
                $('html, body').animate({ scrollTop: 0 }, 'medium');
                this.onCopy(data);
            },
            Icon: externalApiCopy.iconClasses + '',
            ToolTip: 'Copy',
            Width: '0'
        });

        const testColumn = new LinkColumn({
            Clicked: (data) => {
                this.onTest(data);
            },
            Icon: externalApiTest.iconClasses + '',
            ToolTip: 'Test',
            Width: '0',
            BackgroundColor: 'green'
        });


        this.externalApiColumn = [
            new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '21%' }),
            new GridColumn({ HeaderText: 'API Verb', Field: 'ApiVerb', IsSortable: true, Width: '10%' }),
            new GridColumn({ HeaderText: 'URL', Field: 'Url', IsSortable: true, Width: '60%' })
        ] as Array<GridColumn>;

        if (this.authenticationService.userHasPermission([{ "Name": this.permission.name, "Level": this.permission.editLevel }], 'any')) {
            this.externalApiColumn.push(editColumn);
            this.externalApiColumn.push(deleteColumn);
            this.externalApiColumn.push(copyColumn);
            this.externalApiColumn.push(testColumn);
        }
    }
    reloadExternalApiList() {
        this.spinnerService.show();
        this.externalApiService.getExternalApis().subscribe(
            (response: ExternalApi[]) => {
                this.externalApis = (response === null ? [] : response);
                for (const externalApi of this.externalApis) {
                    const apiVerb = _.find(this.apiVerbs, (item) => item.id === parseInt(externalApi.ApiVerb));
                    externalApi.ApiVerb = apiVerb.Name;
                }
            },
            this.alertService.showApiError,
            () => {
                this.spinnerService.hide();
            }
        );
    }
    deleteButton($event: ExternalApi) {
        this.deleteExternalApi = $event;
        this.confirmExternalApiDelete.Show(this.message);
    }
    onConfirmAcknowledge() {
        this.spinnerService.show();
        this.externalApiService.deleteExternalApi(this.deleteExternalApi.Id).subscribe(
            () => {
                this.alertService.renderSuccessMessage(
                    Messages.ExternalApiDeleted
                );
                this.reloadExternalApiList();
            },
            this.alertService.showApiError,
            () => {
                this.spinnerService.hide();
            }
        );
    }
    onEditExternalApi(data: ExternalApi) {
        this.router.navigate([data.Id], { relativeTo: this.route });
    }
    onCopy(data: ExternalApi) {
        this.router.navigate([data.Id], { state: { isCopy: true }, relativeTo: this.route });
    }
    onAddExternalApi() {
        this.router.navigate([0], { relativeTo: this.route });
    }

    onTest(data) {
        this.spinnerService.show();
        this.externalApiService.testExternalApi(data.Id)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (response) => {
                    this.externalAPIResponse = response;
                    this.openResponseModal();
                }, error:
                    (error) => {
                        this.externalAPIResponse = error;
                        this.externalAPIResponse.Response = error.message;
                        this.externalAPIResponse.StatusCode = error.status;
                        this.openResponseModal();
                    }
            });
    }
    openResponseModal() {
        this.responseModalRef = this.bsModalService.show(this.responseModal, {
            'backdrop': 'static',
            'class': 'vertical-center',
            'keyboard': false
        });
        this.modalBackdropService.addBackDrop();
    }
    closeForcefullyOpenDialog() {
        this.responseModalRef.hide();
        this.modalBackdropService.removeBackdrop();
        $('#ExternalApiResponse').modal('hide');
    }

    close() {
        this.router.navigate(['../'], { relativeTo: this.route });
    }
}
