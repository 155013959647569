import { Injectable } from '@angular/core';
import { BaseCrudService, RuntimeConstants } from 'src/app/shared';
import { SlideShow } from '../interface/slide-show';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SlideShowDetails } from '../interface/slide-show-details';

@Injectable()
export class SlideShowService extends BaseCrudService<SlideShow> {

    slideshowApiBasePath = `${RuntimeConstants.API_PATH}slideshows`;
    slideshowImageBaseUrl = `${RuntimeConstants.IMAGE_BASE_PATH}/SlideImages/`;

    constructor(httpService: HttpClient) {
        super('slideshows', httpService);
    }

    newSlideShow() {
        const slideShowModel: SlideShow = {
            Id: 0,
            Name: ''
        };

        return slideShowModel;
    }

    newSlideShowImage() {
        const slideImages: SlideShowDetails = {
            Id: 0,
            ImagePath: null,
            Ordinal: 0,
            HoldSeconds: 10,
            SlideShowImageDetails: null,
            MediaType: null,
            FileNameWithText: null
        };

        return slideImages;
    }

    getSlideshow(slideshowId): Observable<SlideShow> {
        return this.httpService.get<SlideShow>(`${this.slideshowApiBasePath}/${slideshowId}`);
    }

    getSlideshowImages(slideshowId): Observable<SlideShowDetails[]> {
        return this.httpService.get<SlideShowDetails[]>(`${this.slideshowApiBasePath}/${slideshowId}/images`);
    }

    getSlideshowImage(slideshowId, slideshowImageId): Observable<SlideShowDetails> {
        return this.httpService.get<SlideShowDetails>(`${this.slideshowApiBasePath}/${slideshowId}/images/${slideshowImageId}`);
    }

    addSlideshowImage(slideshowId, slideshowImage, slideshowImageFile: File): Observable<SlideShowDetails> {
        const formData = this.prepareSlideshowImageForm(slideshowImage, slideshowImageFile);
        return this.httpService.post<SlideShowDetails>(`${this.slideshowApiBasePath}/${slideshowId}/images`, formData);
    }

    updateSlideshowImage(slideshowId, slideshowImage: SlideShowDetails): Observable<SlideShowDetails> {
        return this.httpService.put<SlideShowDetails>(`${this.slideshowApiBasePath}/${slideshowId}/images/${slideshowImage.Id}`,
        slideshowImage);
    }

    deleteSlideshowImage(slideshowId, slideshowImageId): Observable<SlideShowDetails> {
        return this.httpService.delete<SlideShowDetails>(`${this.slideshowApiBasePath}/${slideshowId}/images/${slideshowImageId}`);
    }

    changeSlideshowImageOrdinal(slideshowId, slideshowImageId, ordinal): Observable<SlideShowDetails> {
        return this.httpService.put<SlideShowDetails>(`${this.slideshowApiBasePath}/${slideshowId}/images/${slideshowImageId}/ordinal/
        ${ordinal}`,
            null);
    }

    getTerminalSlideshowDetails(terminalId): Observable<SlideShowDetails[]> {
        return this.httpService.get<SlideShowDetails[]>(`${this.slideshowApiBasePath}/${terminalId}/slideshowdetails`);
    }

    prepareSlideshowImageForm(slideshowImage, imageFile: File) {
        const formData = new FormData();
        if (imageFile) {
            formData.append('file', imageFile);
        }
        formData.append('model', JSON.stringify(slideshowImage));

        return formData;
    }
}
