import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ComponentsModule } from '../shared';
import { HeaderEntryModule } from '../header-entry';
import { TIME_ENTRY_STATES } from './time-entry-terminal-routes';
import { TimeEntryTerminalComponent } from './time-entry-terminal/time-entry-terminal.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ModalModule.forRoot(),
    ComponentsModule,
    RouterModule.forChild(TIME_ENTRY_STATES),
    HeaderEntryModule,
  ],
  declarations: [TimeEntryTerminalComponent],
})
export class TimeEntryModule { }
