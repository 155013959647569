import { ApplicationStateService } from './../../../../shared/services/application-state.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Features, OrderService, SettingParam } from 'src/app/shared';
import { MainService } from 'src/app/header-entry/services/main.service';
import { CashDrawerDetail } from 'src/app/manage-console/load-drawer/cash-drawer-detail';



@Component({
  selector: 'pos-terminal-info',
  templateUrl: './terminal-info.component.html'
})
export class TerminalInfoComponent implements OnInit, OnDestroy {

  @Input('drawerInfo')
  drawerInfo: CashDrawerDetail;
  currentWaitTimeFeatureEnable = false;
  settingParam: SettingParam;

  currentVersion: string = '';
  currentWaitTime;
  waitTimeInterval;
  constructor(private applicationStateService: ApplicationStateService,
    private mainService: MainService,
    private orderService: OrderService) {
    this.settingParam = this.applicationStateService.settingParam;
  }

  ngOnInit() {
    this.currentVersion = this.applicationStateService.applicationInitializationConfigurations.CurrentVersion;
    if (!this.currentVersion) {
      this.mainService.getApplicationVersion()
        .subscribe({
          next: (response) => {
            this.currentVersion = response.CurrentVersion;
            this.applicationStateService.applicationInitializationConfigurations.CurrentVersion = response.CurrentVersion;
          }
        });
    }
    const currentWaitTimeFeature = this.applicationStateService.applicationFeatures.find(x => x.Name == Features.TrackCarryOutOrdersWaitTime)
    if (currentWaitTimeFeature?.IsEnabled) {
      this.getCurrentWaitTime();
      this.currentWaitTimeFeatureEnable = true;
      this.waitTimeInterval = setInterval(() => this.getCurrentWaitTime(), 60 * 1000);
    }

  }

  ngOnDestroy(): void {
    if (this.waitTimeInterval) {
      clearInterval(this.waitTimeInterval);
    }
  }

  getCurrentWaitTime() {
    this.orderService.getCurrentWaitTime()
      .subscribe({
        next: (waitTime: number) => {
          this.secondsConvertToTime(waitTime ? waitTime * 60 : 0);
        }
      });
  }
  public secondsConvertToTime(seconds: number): void {
    if (seconds > 0) {
      const lowestDate = new Date(1970, 0, 1);
      lowestDate.setHours(0, 0, seconds, 0);
      this.currentWaitTime = lowestDate;
    } else {
      this.currentWaitTime = null;
    }
  }

}
