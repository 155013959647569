<pos-edit-wrapper (close)="onCancel()" [isPopup]="false" [headingIcon]="icons.infoManageCogs"
  heading="Online Ordering - Settings">
  <form #onlineOrderingSettingForm="ngForm" autocomplete="off"
    (ngSubmit)="saveOnlineOrderingSettings(onlineOrderingSettingForm.valid)">
    <div class="col-12 container-fluid border-top">
      <ul class="nav nav-tabs inner-tab pos-nav-tabs">
        <li [ngClass]="{'active': selectedTab == tabList.TextSetting }">
          <a (click)="selectedTab = tabList.TextSetting" class="blue">
            <span>
              <pos-icon [icon]="icons.onlineOrderingCommentAlt" class="theme-separator"></pos-icon>
            </span><span> Text </span></a>
        </li>
        <li [ngClass]="{'active': selectedTab == tabList.ImageSetting }">
          <a (click)="selectedTab = tabList.ImageSetting" class="blue">
            <span>
              <pos-icon [icon]="icons.onlineOrderingImages" class="theme-separator"></pos-icon>
            </span> <span> Images</span> </a>
        </li>
        <li [ngClass]="{'active': selectedTab == tabList.SocialMediaSetting }">
          <a (click)="selectedTab = tabList.SocialMediaSetting" class="blue">
            <span>
              <i class="{{socialMediaClass}} theme-separator"></i>
            </span> <span> Social Media</span></a>
        </li>
        <li [ngClass]="{'active': selectedTab == tabList.ScheduleSetting }">
          <a (click)="selectedTab = tabList.ScheduleSetting" class="blue">
            <span>
              <pos-icon [icon]="icons.onlineOrderingSchedule" class="theme-separator"></pos-icon>
            </span> <span> Schedule</span></a>
        </li>
        <li [ngClass]="{'active': selectedTab == tabList.DiscountsSetting }">
          <a (click)="selectedTab = tabList.DiscountsSetting" class="blue">
            <span>
              <pos-icon [icon]="icons.dicountMoneyBill" class="theme-separator"></pos-icon>
            </span> <span> Discounts</span></a>
        </li>
        <li [ngClass]="{'active': selectedTab == tabList.ColorSetting }">
          <a (click)="selectedTab = tabList.ColorSetting" class="blue">
            <span>
              <pos-icon [icon]="icons.onlineOrderingColor" class="theme-separator"></pos-icon>
            </span> <span> Color</span></a>
        </li>
        <li [ngClass]="{'active': selectedTab == tabList.AnnouncementSetting}">
          <a (click)="selectedTab = tabList.AnnouncementSetting" class="blue">
            <span>
              <pos-icon [icon]="icons.megaphone" class="theme-separator"></pos-icon>
            </span> <span> Announcement</span>
          </a>
        </li>
      </ul>
      <div *ngIf="selectedTab == tabList.TextSetting" class="col-12 pos-tab-body overflow-tab pt-pix-5"
        [ngStyle]="{'height': getHeight()}">
        <pos-row>
          <pos-column class="online-ordering-setting" *ngFor="let textSetting of textSettings" [ngClass]="{'ms-pix-15': textSetting.KeyName == 'acknowledgmentMessage'}">
            <pos-form-field inputStyle="col-12" [validationMessages]="{required: 'Acknowledgment message is required.'}">
              <pos-row>
                <pos-column class="setting-title">{{textSetting.SettingName}}</pos-column>

                <pos-column class="description">{{textSetting.Description}}</pos-column>

                <pos-column class="pt-pix-10" *ngIf="textSetting.KeyName !== 'locationAddress' && textSetting.KeyName !== 'isShowAcknowledgmentPage'">
                  <textarea posInput class="form-control" name="{{textSetting.SettingName}}" [required]="textSetting.KeyName == 'acknowledgmentMessage' && isShowAcknowledgmentPageParam" [disabled]="textSetting.KeyName == 'acknowledgmentMessage' && !isShowAcknowledgmentPageParam"
                    [(ngModel)]="textSetting.ParamValue" (change)="textSetting.IsChanged = true"></textarea>
                </pos-column>
                <pos-column class="pt-pix-10" *ngIf="textSetting.KeyName === 'locationAddress'">
                  <pos-location-address [(locationAddresses)]="locationAddresses"></pos-location-address>
                </pos-column>
                <pos-column *ngIf="textSetting.KeyName == 'isShowAcknowledgmentPage'">
                  <div class="btn-group pt-pix-10">
                    <label class="btn btn-radio"
                      [ngClass]="textSetting.ParamValue === 'true' ?'active btn-primary normal':'btn-secondary'">
                      <input type="radio" name="isShowAcknowledgmentPage" class="form-check-input"
                        [(ngModel)]="textSetting.ParamValue" value="true"
                        (ngModelChange)="textSetting.IsChanged = true; isShowAcknowledgmentPageParam = true;">
                      Yes
                    </label>
                    <label class="btn btn-default btn-radio"
                      [ngClass]="textSetting.ParamValue === 'false' ?'active btn-primary normal': 'btn-secondary'">
                      <input type="radio" name="isShowAcknowledgmentPage" class="form-check-input"
                        [(ngModel)]="textSetting.ParamValue" value="false"
                        (ngModelChange)="textSetting.IsChanged = true; isShowAcknowledgmentPageParam = false;">
                      No
                    </label>
                  </div>
                </pos-column>
              </pos-row>
            </pos-form-field>
          </pos-column>
        </pos-row>
      </div>

      <div *ngIf="selectedTab == tabList.ImageSetting" class="col-12 pos-tab-body overflow-tab pt-pix-5"
        [ngStyle]="{'height': getHeight()}">
        <pos-row>
          <pos-column class="online-ordering-setting" *ngFor="let imageSetting of imageSettings; let i = index">
            <pos-form-field inputStyle="col-12">
              <pos-row>
                <pos-column class="setting-title">{{imageSetting.SettingName}}</pos-column>

                <pos-column class="description">{{imageSetting.Description}}</pos-column>

                <pos-column class="pt-pix-5">
                  <pos-row colMd="4" colSm="6" colXs="12">
                    <pos-column>
                      <input posInput type="file" id="imageUpload{{i}}" class="form-control"
                        accept=".png,.jpg,.ico,.bmp" (change)="handleFileInput($event.target.files, i, imageSetting)" />
                    </pos-column>

                    <pos-column sm="6" xs="12" *ngIf="imageSetting.IsChanged && imageSetting.ParamValue">
                      <pos-row>
                        <pos-column xs="2" class="col-50 pe-0">
                          <img id="image{{i}}" width="50px" />
                        </pos-column>
                        <pos-column sm="6" xs="12" class="mt-pix-n5">
                          <a (click)="removeIcon(i, imageSetting)" class="black">
                            <pos-icon [icon]="icons.times" class="pointer remove-icon-badge"></pos-icon>
                          </a>
                        </pos-column>
                      </pos-row>
                    </pos-column>
                    <pos-column sm="6" xs="12" *ngIf="!imageSetting.IsChanged && imageSetting.iconName != ''"
                      class="pt-pix-7">
                      <span>{{imageSetting.iconName}}</span>
                      <span class="ps-pix-5 mt-pix-n5" (click)="removeIcon(i, imageSetting)">
                        <pos-icon [icon]="icons.times" class="pointer remove-icon-badge"></pos-icon>
                      </span>
                    </pos-column>

                  </pos-row>
                </pos-column>
              </pos-row>
            </pos-form-field>
          </pos-column>
        </pos-row>
      </div>

      <div *ngIf="selectedTab == tabList.SocialMediaSetting" class="col-12 pos-tab-body overflow-tab pt-pix-5"
        [ngStyle]="{'height': getHeight()}">
        <pos-row>
          <pos-column class="online-ordering-setting" *ngFor="let socialMediaSetting of socialMediaSettings">
            <pos-form-field inputStyle="col-12">
              <pos-row>
                <pos-column class="setting-title">{{socialMediaSetting.SettingName}}</pos-column>

                <pos-column class="description">{{socialMediaSetting.Description}}</pos-column>

                <pos-column lg="8" md="9" xs="12" class="pt-pix-5">
                  <input posInput type="text" class="form-control" name="{{socialMediaSetting.SettingName}}"
                    (change)="socialMediaSetting.IsChanged = true" [(ngModel)]="socialMediaSetting.ParamValue" />
                </pos-column>
              </pos-row>
            </pos-form-field>
          </pos-column>
        </pos-row>
      </div>

      <div *ngIf="selectedTab == tabList.ScheduleSetting" class="col-12 pos-tab-body overflow-tab pt-pix-5"
        [ngStyle]="{'height': getHeight()}">
        <pos-row>
          <pos-column xs="12" class="online-ordering-setting pt-pix-10"
            *ngFor="let scheduleSetting of scheduleSettings; index as i">
            <pos-row>

              <pos-column class="setting-title">{{scheduleSetting.SettingName}}</pos-column>
              <pos-column class="description"
                *ngIf="!(scheduleSetting.KeyName == 'schedules' || scheduleSetting.KeyName == 'pickupTimeSchedule' || scheduleSetting.KeyName == 'dayparts')">
                {{scheduleSetting.Description}}
              </pos-column>
              <pos-column *ngIf="scheduleSetting.KeyName == 'isActiveOnlineOrdering'">
                <div class="btn-group pt-pix-10">
                  <label class="btn btn-radio"
                    [ngClass]="scheduleSetting.ParamValue === 'true' ?'active btn-primary normal':'btn-secondary'">
                    <input type="radio" name="onlineOrdering" class="form-check-input"
                      [(ngModel)]="scheduleSetting.ParamValue" value="true" (click)="scheduleSetting.IsChanged = true">
                    On
                  </label>
                  <label class="btn btn-default btn-radio"
                    [ngClass]="scheduleSetting.ParamValue === 'false' ?'active btn-primary normal': 'btn-secondary'">
                    <input type="radio" name="onlineOrdering" class="form-check-input"
                      [(ngModel)]="scheduleSetting.ParamValue" value="false" (click)="scheduleSetting.IsChanged = true">
                    Off
                  </label>
                </div>
              </pos-column>

              <pos-column sytleClass="mx-pix-n5" *ngIf="scheduleSetting.KeyName == 'advanceOrderMaxDays'">
                <pos-form-field inputStyle="ps-pix-10">
                  <p-checkbox posInput name="chkAdvanceOrder" #chkAdvanceOrder="ngModel"
                    [(ngModel)]="isAllowAdvanceOrder" binary="true"
                    (onChange)="changeAllowAdvanceOrder(scheduleSetting);" label="Allow Advance Day(s) Ordering">
                  </p-checkbox>
                </pos-form-field>
                <pos-form-field inputStyle="col-md-2 col-sm-3 col-6 ps-pix-10" *ngIf="isAllowAdvanceOrder">
                  <input posInput type="number" class="form-control" (input)="scheduleSetting.IsChanged = true"
                    [(ngModel)]="scheduleSetting.ParamValue" name="advanceOrderMaxDays" id="advanceOrderMaxDays" />
                </pos-form-field>
              </pos-column>

              <pos-column *ngIf="(scheduleSetting.KeyName == 'onlineOrderingTimeZone')">
                <pos-form-field inputStyle="col-lg-3 col-md-4 col-sm-6 col-8 ps-pix-10"
                  [validationMessages]="{required: 'Time zone is required.'}">
                  <ng-select posInput [items]="timezoneList" bindLabel="DisplayName" bindValue="ZoneId"
                    placeholder="Select timezone" (change)="scheduleSetting.IsChanged = true"
                    [(ngModel)]="scheduleSetting.ParamValue" dropdownPosition="top" name="timezone" [required]="true">
                  </ng-select>
                </pos-form-field>
              </pos-column>

              <pos-column
                *ngIf="!(scheduleSetting.KeyName == 'schedules' || scheduleSetting.KeyName == 'pickupTimeSchedule' || scheduleSetting.KeyName == 'advanceOrderMaxDays' || scheduleSetting.KeyName == 'onlineOrderingTimeZone' || scheduleSetting.KeyName == 'dayparts'  || scheduleSetting.KeyName == 'isActiveOnlineOrdering' || scheduleSetting.KeyName == 'syncOrderOn')">
                <pos-form-field inputStyle="col-md-2 col-sm-3 col-6">
                  <input posInput type="number" class="form-control" (input)="scheduleSetting.IsChanged = true"
                    [(ngModel)]="scheduleSetting.ParamValue" name="scheduleSetting_{{i}}" />
                </pos-form-field>
              </pos-column>

              <pos-column *ngIf="scheduleSetting.KeyName == 'dayparts'">
                <pos-row>
                  <pos-column>
                    <pos-row colLg="2" colSm="3" colXs="5" colStyleClass="pe-0">
                      <pos-column md="3" sm="5" xs="12" class="pe-0"></pos-column>
                      <pos-column class="text-center">
                        <label class="label-section bold">Max Orders</label>
                      </pos-column>
                      <pos-column class="text-center">
                        <label class="label-section bold">Override Min Wait Time (minutes)</label>
                      </pos-column>
                      <pos-column sm="1" xs="2" class="p-0 pt-pix-7 pointer">
                        <field-info [info]="fieldInfoMessages.DaypartMinWaitTimeOverride"
                          styleClass="p-0 mt-pix-n8 inline-block"></field-info>
                      </pos-column>
                    </pos-row>
                  </pos-column>

                  <pos-column *ngFor="let partConfig of daypartsConfig; let j=index" class="pt-pix-10">
                    <pos-row colLg="2" colSm="3" colXs="5" colStyleClass="pe-0">
                      <pos-column md="3" sm="5" xs="12" class="pe-0">
                        <label class="pt-pix-7">{{ partConfig.Name }}
                          <i class="font-12"> ({{ partConfig.StartTime }} - {{partConfig.EndTime}})</i>
                        </label>
                      </pos-column>
                      <pos-column>
                        <input type="number" class="form-control" name="maxOrderOfPart_{{i}}_{{j}}"
                          [(ngModel)]="partConfig.MaxOrder" />
                      </pos-column>
                      <pos-column>
                        <input type="number" class="form-control" name="minWaitTimeOfPart_{{i}}_{{j}}"
                          [(ngModel)]="partConfig.MinWaitTime" />
                      </pos-column>
                      <pos-column sm="1" xs="2" class="pe-0 pt-pix-8 pointer grey font-12">
                        <span (click)="removeDaypartOverrides(partConfig)">
                          <pos-icon [icon]="icons.undo"></pos-icon>
                        </span>
                      </pos-column>
                    </pos-row>
                  </pos-column>
                </pos-row>
              </pos-column>

              <pos-column
                *ngIf="scheduleSetting.KeyName == 'schedules' || scheduleSetting.KeyName == 'pickupTimeSchedule'">
                <pos-row>
                  <pos-column>
                    <pos-row colLg="2" colSm="3" colXs="5">
                      <pos-column sm="2" xs="12"></pos-column>
                      <pos-column class="text-center col-150 p-0">
                        <label class="label-section bold">Start Time</label>
                      </pos-column>
                      <pos-column xs="1" class="p-0"></pos-column>
                      <pos-column class="text-center col-150 p-0">
                        <label class="label-section bold">End Time</label>
                      </pos-column>
                    </pos-row>
                  </pos-column>

                  <pos-column
                    *ngFor="let scheduleDay of (scheduleSetting.KeyName == 'schedules' ? scheduleDaysSettings : pickupTimeSettings) ; let j = index">
                    <pos-row colLg="2" colSm="3" colXs="5" class="pt-pix-10">
                      <pos-column sm="2" xs="12" class="pt-pix-7">
                        {{ scheduleDay.day }}
                      </pos-column>

                      <pos-column class="text-center col-150 p-0">
                        <input type="time" class="form-control" name="startTime_{{i}}_{{j}}"
                          [(ngModel)]="scheduleDay.startTime" />
                      </pos-column>

                      <pos-column xs="1" class="pt-pix-7">
                        <span (click)="removeTime(true,j,scheduleSetting.KeyName == 'schedules')">
                          <pos-icon [icon]="icons.times"></pos-icon>
                        </span>
                      </pos-column>

                      <pos-column class="text-center col-150 p-0">
                        <input type="time" class="form-control" name="endTime_{{i}}_{{j}}"
                          [(ngModel)]="scheduleDay.endTime" />
                      </pos-column>

                      <pos-column xs="1" class="pt-pix-7">
                        <span (click)="removeTime(false,j,scheduleSetting.KeyName == 'schedules')">
                          <pos-icon [icon]="icons.times"></pos-icon>
                        </span>
                      </pos-column>
                    </pos-row>
                  </pos-column>

                  <pos-column *ngIf="scheduleSetting.KeyName == 'pickupTimeSchedule'">
                    <div class="position-relative pt-pix-10">
                      <div class="setting-title z-index-100 position-absolute">Pickup Time
                        Overrides</div>
                      <div>
                        <pos-schedule-overrides [fromListPage]="false">
                        </pos-schedule-overrides>
                      </div>
                    </div>
                  </pos-column>
                </pos-row>
              </pos-column>

              <pos-column *ngIf="scheduleSetting.KeyName == 'syncOrderOn'">
                <pos-row class="mx-pix-n5">
                  <pos-column>
                    <pos-form-field>
                      <input posInput name="syncOrder" type="radio" name="activateFutureOrdersDay"
                        [value]="syncOrderOn.Immediately.Value" [(ngModel)]="activateFutureOrders.day" />
                      <label>{{ syncOrderOn.Immediately.Text }}</label>
                    </pos-form-field>
                  </pos-column>

                  <pos-column>
                    <pos-form-field>
                      <input posInput name="syncOrder" type="radio" [value]="syncOrderOn.OnPickupDate.Value"
                        name="activateFutureOrdersDay" [(ngModel)]="activateFutureOrders.day" />
                      <label>{{ syncOrderOn.OnPickupDate.Text }}</label>
                    </pos-form-field>
                  </pos-column>

                  <pos-column class="ps-pix-20">
                    <pos-row [disabled]="activateFutureOrders.day === syncOrderOn.OnPickupDate.Value">
                      <pos-column>
                        <pos-form-field>
                          <input posInput name="withinHours" type="radio" [value]="false" [(ngModel)]="radioWithinHours"
                            [disabled]="activateFutureOrders.day !== syncOrderOn.OnPickupDate.Value" />
                          <label>Immediately</label>
                        </pos-form-field>
                      </pos-column>

                      <pos-column class="row">
                        <pos-form-field class="col-1 col-85" inputStyle="col-12">
                          <input posInput name="withinHours" type="radio" [value]="true" [(ngModel)]="radioWithinHours"
                            [disabled]="activateFutureOrders.day !== syncOrderOn.OnPickupDate.Value" />
                          <label class="edit-form__section__label">Within</label>
                        </pos-form-field>
                        <pos-form-field class="col-120 col-1 p-0" inputStyle="col-12 col-120"
                          [validationMessage]="{required: 'Hours must be between 0 and 24.'}">
                          <input posInput type="number" class="form-control" [range]="[0, 24]"
                            name="activateFutureOrdersWithinHours"
                            [disabled]="!radioWithinHours || activateFutureOrders.day !== syncOrderOn.OnPickupDate.Value"
                            [(ngModel)]="activateFutureOrders.withinHours" />
                        </pos-form-field>
                        <div class="col-6 pt-pix-10 p-0">
                          <label class="edit-form__section__label"> hours of schedule</label>
                        </div>
                      </pos-column>
                    </pos-row>
                  </pos-column>

                </pos-row>
              </pos-column>
            </pos-row>
          </pos-column>

        </pos-row>
      </div>

      <div *ngIf="selectedTab == tabList.DiscountsSetting" class="col-12 pos-tab-body overflow-tab pt-pix-5"
        [ngStyle]="{'height': getHeight()}">
        <div class="col-12">
          <div>
            <h3 class="setting-title">Percent</h3>
          </div>
          <pos-row class="pos-title-content">
            <pos-column *ngFor="let discountSetting of discountPercentSettings; let sectionIndex = index">
              <pos-row>
                <pos-column sm="4" xs="12">
                  <pos-form-field inputStyle="col-12">
                    <div class="col-12 p-0 setting-title" *ngIf="sectionIndex == 0 || screenWidth < 768">
                      <label [ngClass]="{'extreme-orange bold': screenWidth > 768}">Code</label>
                    </div>
                    <input posInput type="text" class="form-control" (change)="discountSetting.IsChanged = true"
                      [(ngModel)]="discountSetting.Code" name="discountSettingCode_{{sectionIndex}}" />
                  </pos-form-field>
                </pos-column>

                <pos-column sm="4" xs="12" class="col-170">
                  <pos-form-field inputStyle="col-sm-4 col-12 col-170"
                    [validationMessages]="{range: 'Please enter valid value.'}">
                    <div class="col-12 p-0 setting-title" *ngIf="sectionIndex == 0 || screenWidth < 768">
                      <label [ngClass]="{'extreme-orange bold': screenWidth > 768}">Percentage</label>
                    </div>
                    <p class="input-group">
                      <input posInput type="number" #percentDiscount="ngModel" class="form-control text-right"
                        [range]="[0.01, 100]" step="0.01" name="percent{{sectionIndex}}"
                        (input)="discountSetting.IsChanged = true" [(ngModel)]="discountSetting.Value" />
                      <span class="input-group-addon">%</span>
                    </p>
                  </pos-form-field>
                </pos-column>

                <pos-column sm="4" xs="12" [styleClass]="{'ps-0': true, '': screenWidth < 768}">
                  <pos-form-field inputStyle="col-12">
                    <div class="col-12 p-0 setting-title" *ngIf="sectionIndex == 0 || screenWidth < 768">
                      <label [ngClass]="{'extreme-orange bold': screenWidth > 768}">Description</label>
                    </div>
                    <textarea posInput class="form-control" name="discountPercentSettingsDescription_{{sectionIndex}}"
                      [(ngModel)]="discountSetting.Description" (change)="discountSetting.IsChanged = true"></textarea>
                  </pos-form-field>
                </pos-column>
              </pos-row>
            </pos-column>
          </pos-row>
        </div>

        <div class="col-12 p-0 section-border">
          <div>
            <h3 class="setting-title">Manual</h3>
          </div>
          <pos-row class="pos-title-content">
            <pos-column *ngFor="let discountSetting of discountManualSettings; let sectionIndex = index">
              <pos-row>
                <pos-column sm="4" xs="12">
                  <pos-form-field inputStyle="col-12">
                    <div class="col-12 p-0 setting-title" *ngIf="sectionIndex == 0 || screenWidth < 768">
                      <label [ngClass]="{'extreme-orange bold': screenWidth > 768}">Code</label>
                    </div>
                    <input posInput type="text" class="form-control" (change)="discountSetting.IsChanged = true"
                      [(ngModel)]="discountSetting.Code" name="discountManualSettingsCode_{{sectionIndex}}" />
                  </pos-form-field>

                </pos-column>

                <pos-column sm="4" xs="12" class="col-170">
                  <pos-form-field inputStyle="col-sm-4 col-12 col-170">
                    <div class="col-12 p-0 setting-title" *ngIf="sectionIndex == 0 || screenWidth < 768">
                      <label [ngClass]="{'extreme-orange bold': screenWidth > 768}">Amount</label>
                    </div>
                    <p class="input-group">
                      <span class="input-group-addon">{{currencySymbol}}</span>
                      <input posInput type="number" class="form-control text-right"
                        (input)="discountSetting.IsChanged = true" name="discountManualSettingsValue_{{sectionIndex}}"
                        [(ngModel)]="discountSetting.Value" />
                    </p>
                  </pos-form-field>
                </pos-column>

                <pos-column sm="4" xs="12" [styleClass]="{'ps-0': true, '': screenWidth < 768}">
                  <pos-form-field inputStyle="col-12">
                    <div class="col-12 p-0 setting-title" *ngIf="sectionIndex == 0 || screenWidth < 768">
                      <label [ngClass]="{'extreme-orange bold': screenWidth > 768}">Description</label>
                    </div>
                    <textarea posInput class="form-control" name="discountManualSettingsDescription_{{sectionIndex}}"
                      [(ngModel)]="discountSetting.Description" (change)="discountSetting.IsChanged = true"></textarea>
                  </pos-form-field>
                </pos-column>
              </pos-row>
            </pos-column>

          </pos-row>
        </div>
      </div>

      <div *ngIf="selectedTab == tabList.ColorSetting" class="col-12 pos-tab-body overflow-tab pt-pix-5"
        [ngStyle]="{'height': getHeight()}">
        <pos-row>
          <pos-column *ngFor="let colorSetting of colorSettings">
            <pos-form-field [label]="colorSetting.SettingName"
              [validationMessages]="{required: colorSetting.SettingName + ' is required.'}">
              <pos-color-picker posInput [(ngModel)]="colorSetting.ParamValue" name="{{colorSetting.SettingName}}"
                [required]="true"></pos-color-picker>
            </pos-form-field>
          </pos-column>
        </pos-row>
      </div>

      <div *ngIf="selectedTab == tabList.AnnouncementSetting" class="col-12 pos-tab-body overflow-tab pt-pix-5"
        [ngStyle]="{'height': getHeight()}">
        <pos-row>
          <pos-column xs="12" class="online-ordering-setting pt-pix-10 mx-pix-5"
            *ngFor="let announcementSetting of announcementSettings; index as i">
            <pos-row>
              <pos-column class="setting-title"
                *ngIf="announcementSetting.KeyName != 'announcementSchedule'">{{announcementSetting.SettingName}}</pos-column>
              <pos-column class="description"
                *ngIf="announcementSetting.KeyName == 'announcementSummary' || announcementSetting.KeyName == 'announcementDetails'">
                {{announcementSetting.Description}}
              </pos-column>

              <pos-column class=" pt-pix-10 col-md-6 col-12"
                *ngIf="announcementSetting.KeyName == 'announcementSummary' || announcementSetting.KeyName == 'announcementDetails'">
                <textarea posInput class="form-control" name="{{announcementSetting.SettingName}}" row="4"
                  [(ngModel)]="announcementSetting.ParamValue"
                  (change)="announcementSetting.IsChanged = true"></textarea>
              </pos-column>

              <pos-column>
                <div class="position-relative">
                  <div class="setting-title position-absolute z-index-100"
                    *ngIf="announcementSetting.KeyName == 'announcementSchedule'">
                    Schedule
                  </div>
                  <div class="pt-pix-10" *ngIf="announcementSetting.KeyName == 'announcementSchedule'">
                    <pos-announcement-schedule></pos-announcement-schedule>
                  </div>
                </div>
              </pos-column>
            </pos-row>
          </pos-column>
        </pos-row>
      </div>
    </div>
    <div class="col-12 action-button-container page-footer">
      <button type="submit" *ngIf="permissionDenied" value="Save" class="btn-primary btn-lg btn btn-save pos-button"
        scrollup>Save</button>
      <button type="button" class="btn-secondary btn-lg btn btn-cancel pos-button" (click)="onCancel()" value="Cancel"
        scrollup>Cancel</button>
    </div>
  </form>
</pos-edit-wrapper>
