import { Injectable } from '@angular/core';
import { DomainConstants } from 'src/app/shared/constants/domain-constants';

@Injectable()
export class ButtonFunctionService {

  constructor() { }

  getButtonFunctions = function () {

    return [
      {
        'name': DomainConstants.ButtonFunctionTypes.ADD_COMMENT.Name,
        'code': DomainConstants.ButtonFunctionTypes.ADD_COMMENT.Code,
        'allowedButtonType': [
          'MainProduct',
          'DetailEntry'
        ]
      },
      {
        'name': DomainConstants.ButtonFunctionTypes.ADD_COMMENT_TO_ALL.Name,
        'code': DomainConstants.ButtonFunctionTypes.ADD_COMMENT_TO_ALL.Code,
        'allowedButtonType': [
          'Functional'
        ]
      },
      {
        'name': DomainConstants.ButtonFunctionTypes.ADD_COMMENT_UNRESTRICTED.Name,
        'code': DomainConstants.ButtonFunctionTypes.ADD_COMMENT_UNRESTRICTED.Code,
        'allowedButtonType': [
          'MainProduct',
          'DetailEntry'
        ]
      },
      {
        'name': DomainConstants.ButtonFunctionTypes.ASSIGN_PAGER.Name,
        'code': DomainConstants.ButtonFunctionTypes.ASSIGN_PAGER.Code,
        'allowedButtonType': [
          'Functional'
        ]
      },
      {
        'name': DomainConstants.ButtonFunctionTypes.CALL_API.Name,
        'code': DomainConstants.ButtonFunctionTypes.CALL_API.Code,
        'allowedButtonType': [
          'Functional'
        ]
      },
      {
        'name': DomainConstants.ButtonFunctionTypes.COMMENT_ENTRY.Name,
        'code': DomainConstants.ButtonFunctionTypes.COMMENT_ENTRY.Code,
        'allowedButtonType': [
          'Functional'
        ]
      },
      {
        'name': DomainConstants.ButtonFunctionTypes.CUSTOMER_NAME.Name,
        'code': DomainConstants.ButtonFunctionTypes.CUSTOMER_NAME.Code,
        'allowedButtonType': [
          'Functional'
        ]
      },
      {
        'name': DomainConstants.ButtonFunctionTypes.END_MULTIPLE_PRODUCTS.Name,
        'code': DomainConstants.ButtonFunctionTypes.END_MULTIPLE_PRODUCTS.Code,
        'allowedButtonType': [
          'Functional'
        ]
      },
      {
        'name': DomainConstants.ButtonFunctionTypes.GRATUITY_MARKUP_AUTO.Name,
        'code': DomainConstants.ButtonFunctionTypes.GRATUITY_MARKUP_AUTO.Code,
        'allowedButtonType': [
          'Functional'
        ]
      },
      {
        'name': DomainConstants.ButtonFunctionTypes.MULTIPLE_PRODUCTS.Name,
        'code': DomainConstants.ButtonFunctionTypes.MULTIPLE_PRODUCTS.Code,
        'allowedButtonType': [
          'Functional'
        ]
      },
      {
        'name': DomainConstants.ButtonFunctionTypes.NON_FUNCTIONAL.Name,
        'code': DomainConstants.ButtonFunctionTypes.NON_FUNCTIONAL.Code,
        'allowedButtonType': [
          'Functional'
        ]
      },
      {
        'name': DomainConstants.ButtonFunctionTypes.NUMERIC_DISCOUNT_MANUAL.Name,
        'code': DomainConstants.ButtonFunctionTypes.NUMERIC_DISCOUNT_MANUAL.Code,
        'allowedButtonType': [
          'Functional'
        ]
      },
      {
        'name': DomainConstants.ButtonFunctionTypes.NUMERIC_MARKUP.Name,
        'code': DomainConstants.ButtonFunctionTypes.NUMERIC_MARKUP.Code,
        'allowedButtonType': [
          'Functional'
        ]
      },
      {
        'name': DomainConstants.ButtonFunctionTypes.PERCENT_DISCOUNT.Name,
        'code': DomainConstants.ButtonFunctionTypes.PERCENT_DISCOUNT.Code,
        'allowedButtonType': [
          'Functional'
        ]
      },
      {
        'name': DomainConstants.ButtonFunctionTypes.PERCENT_MARKUP.Name,
        'code': DomainConstants.ButtonFunctionTypes.PERCENT_MARKUP.Code,
        'allowedButtonType': [
          'Functional'
        ]
      },
      {
        'name': DomainConstants.ButtonFunctionTypes.PLACEHOLDER.Name,
        'code': DomainConstants.ButtonFunctionTypes.PLACEHOLDER.Code,
        'allowedButtonType': [
          'Functional'
        ]
      },
      {
        'name': DomainConstants.ButtonFunctionTypes.PRICE_SET.Name,
        'code': DomainConstants.ButtonFunctionTypes.PRICE_SET.Code,
        'allowedButtonType': [
          'Functional'
        ]
      },
      {
        'name': DomainConstants.ButtonFunctionTypes.REMOVE_TAX.Name,
        'code': DomainConstants.ButtonFunctionTypes.REMOVE_TAX.Code,
        'allowedButtonType': [
          'Functional'
        ]
      },
      {
        'name': DomainConstants.ButtonFunctionTypes.DEDUCT_INVENTORY_PRODUCT.Name,
        'code': DomainConstants.ButtonFunctionTypes.DEDUCT_INVENTORY_PRODUCT.Code,
        'allowedButtonType': [
          'Functional'
        ]
      },
      {
        'name': DomainConstants.ButtonFunctionTypes.SET_DIETARY_RESTRICTION.Name,
        'code': DomainConstants.ButtonFunctionTypes.SET_DIETARY_RESTRICTION.Code,
        'allowedButtonType': [
          'Functional'
        ]
      },
      {
        'name': DomainConstants.ButtonFunctionTypes.SET_PHONE_NUMBER.Name,
        'code': DomainConstants.ButtonFunctionTypes.SET_PHONE_NUMBER.Code,
        'allowedButtonType': [
          'Functional'
        ]
      },
      {
        'name': DomainConstants.ButtonFunctionTypes.SET_SIZE.Name,
        'code': DomainConstants.ButtonFunctionTypes.SET_SIZE.Code,
        'allowedButtonType': [
          'Functional'
        ]
      },
      {
        'name': DomainConstants.ButtonFunctionTypes.TIME_ENTRY.Name,
        'code': DomainConstants.ButtonFunctionTypes.TIME_ENTRY.Code,
        'allowedButtonType': [
          'Functional'
        ]
      }
    ];
  };

  getAllSelectedButtonInList(screenToggleButtons, buttonIds) {
    return buttonIds.filter((x) => !screenToggleButtons.find((y) => x == y.Id));
  }

  addButtonInBindList(selectedButtons, allButtons, buttonIds) {
    buttonIds.forEach(id => {
      const button = allButtons.find(x => x.Id == id);
      if (button) {
        button.ButtonText = button.ButtonScreen;
        selectedButtons.push(button);
      }
    });
  }
}
