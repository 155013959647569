import { Component, Input } from '@angular/core';
import { AdfModel, AdfModelRow } from '../../interfaces/adf-model';
import { DashboardOptions } from '../../interfaces/dashboard-options';

@Component({
  selector: 'pos-adf-dashboard-row',
  templateUrl: './adf-dashboard-row.component.html',
})
export class AdfDashboardRowComponent {

  @Input() row: AdfModelRow;
  @Input() adfModel: AdfModel;
  @Input() isEditMode: boolean;
  @Input() isContinuousEditMode: boolean;
  @Input() options: DashboardOptions;
  @Input() isInteractive: boolean;
  
}
