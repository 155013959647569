import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { cloneDeep, find, forEach, remove } from 'lodash';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { finalize } from 'rxjs/operators';
import { TerminalProperty } from 'src/app/configurator/terminals';
import { TerminalsService } from 'src/app/configurator/terminals/services/terminals.service';
import { AlertsService, DomainConstants, EventBroadcastingService, InventoryProductService, ModalService, RabbitMQService, SpinnerService } from 'src/app/shared';
import { ProductBuildQueue } from '../../interfaces/product-build-queue';
import { ProductBuildQueueGridInfo } from '../../interfaces/product-build-queue-grid-info';
import { cog, editSolid, trashScreenSetup } from 'src/app/shared/components/icon';
import { OnDestroy } from '@angular/core';
import { ProductBuildQueueService } from '../../services/product-build-queue.service';
import { ProductBuildQueueEditComponent } from '../product-build-queue-edit';
import { Subscription } from 'rxjs/internal/Subscription';
import { InventoryProduct } from 'src/app/manage-console';
import { UntilDestroy } from '@ngneat/until-destroy';
import Pluralize from 'pluralize';
declare let $: any;
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pos-product-build-queue',
  templateUrl: './product-build-queue.component.html',
  styleUrls: ['./product-build-queue.component.scss']
})
export class ProductBuildQueueComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() terminalDesignMode: boolean = true;
  @Input() terminalId: number;
  @Input() utilizeWidthPct: number = 100;
  @Input() isRequiredSyncData: boolean = true;
  gridInfo: ProductBuildQueueGridInfo;
  containerHeight: number;
  Pluralize = Pluralize;
  columns: Array<number> = [];
  rows: Array<number> = [];
  productBuildQueue: Array<ProductBuildQueue> = [];
  counterFontSize: number;
  labelFontSize: number;
  unitTextPadding: number;

  cardWidth: number = 0;
  cardHeight: number = 0;
  inventoryProducts: Array<InventoryProduct> = [];
  public icons = {
    cog,
    editSolid,
    trashScreenSetup
  };

  eventSubscriptions: Array<any> = [];
  terminalProperty: TerminalProperty;
  rabbitMqOrderUpdateSubscription: Subscription;
  rabbitMqOrderServedSubscription: Subscription;
  rabbitMqOrderProductStateChangeSubscription: Subscription;
  popup: any;
  currentConfigQueue: ProductBuildQueue;
  constructor(private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private terminalService: TerminalsService,
    private inventoryProductService: InventoryProductService,
    private eventBroadcastingService: EventBroadcastingService,
    private productBuildQueueService: ProductBuildQueueService,
    private modalService: ModalService,
    private rabbitMQService: RabbitMQService) {

  }

  ngAfterViewInit(): void {
    this.setFontSize();
  }

  ngOnInit(): void {
    this.getData();
    this.eventBroadcastingSubscriptions();
    if (this.isRequiredSyncData) {
      this.subscribeRabbitMQMessages();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeEventBroadcastingSubscriptions();
  }

  unsubscribeEventBroadcastingSubscriptions() {
    if (this.eventSubscriptions) {
      forEach(this.eventSubscriptions, (subscription) => {
        subscription.unsubscribe();
      });
    }
  }

  resizeWindow() {
    $(window).resize(() => {
      this.setCardProperties();
    });
  }

  subscribeRabbitMQMessages = () => {
    this.rabbitMqOrderUpdateSubscription = this.rabbitMQService.subscribeToOrderDeletedMessageForAllOrderEntry$()
      .subscribe((message) => {
        this.getProductBuildQueue(true);
      });

    this.rabbitMqOrderUpdateSubscription = this.rabbitMQService.subscribeToOrderUpdatedMessageForAllOrderEntry$()
      .subscribe((message) => {
        this.getProductBuildQueue(true);
      });

    this.rabbitMqOrderServedSubscription = this.rabbitMQService.subscribeToOrderServedMessage$()
      .subscribe((message: any) => {
        this.getProductBuildQueue(true);
      });
    this.rabbitMqOrderProductStateChangeSubscription = this.rabbitMQService.subscribeToOrderItemStateChangedForAllOrderEntryMessage$()
      .subscribe((message) => {
        this.getProductBuildQueue(true);
      });
  }

  setCardProperties = () => {
    const marginWidthTotal = 12 * this.gridInfo.Columns;
    const marginHeightTotal = 12 * this.gridInfo.Rows;
    const screenWidth = ($(window).width() * this.utilizeWidthPct) / 100;
    const screenHeight = $(window).height() - 100;
    const width = (screenWidth - marginWidthTotal) / this.gridInfo.Columns;
    const height = (screenHeight - marginHeightTotal) / this.gridInfo.Rows;
    this.cardWidth = width // > 300 ? 300 : width;
    this.cardHeight = height // > 203 ? 203 : height;
    this.containerHeight = (window).innerHeight - 85;
    this.setFontSize();
  }

  setFontSize = () => {
    const timeout = setTimeout(() => {
      const counterElements = $('.count');
      const labelElements = $('.card-label-div');
      if (counterElements?.length) {
        this.counterFontSize = $('.count').height();
      }
      if (labelElements?.length) {
        this.labelFontSize = $('.card-label-div').height() * 0.4;
      }
      this.unitTextPadding = (this.counterFontSize - 4);
      if (timeout) {
        clearTimeout(timeout);
      }
    });
  }

  getProductBuildQueue = (isSilentReload: boolean = false) => {
    if (!isSilentReload) {
      this.spinnerService.show();
    }
    this.productBuildQueueService.getProductBuildQueue(this.terminalId)
      .pipe(finalize(() => {
        if (!isSilentReload) {
          this.spinnerService.hide();
        }
      }))
      .subscribe({
        next: (res: Array<ProductBuildQueue>) => {
          this.GetProductBuildQueueCompleted(res);
        }, error: this.alertsService.showApiError
      });
  }

  getData = () => {
    this.spinnerService.show();
    const recipeDetailObservables = [];
    recipeDetailObservables.push(this.terminalService.getTerminalProperties(this.terminalId));
    recipeDetailObservables.push(this.inventoryProductService.getActiveInventoryProducts(true));
    recipeDetailObservables.push(this.productBuildQueueService.getProductBuildQueue(this.terminalId))
    forkJoin(recipeDetailObservables)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (responses: Array<any>) => {
          if (responses && responses.length > 0) {
            this.getTerminalPropertiesCompleted(responses[0]);
            this.getInventoryProductsCompleted(responses[1]);
            this.GetProductBuildQueueCompleted(responses[2]);
            this.setCardProperties();
            this.resizeWindow();
          } else {
            this.setDefaultTerminalProperty();
          }
        }, error: this.alertsService.showApiError
      });
  }

  private GetProductBuildQueueCompleted(res: ProductBuildQueue[]) {
    this.productBuildQueue = res ? res : [];
    this.setInventoryNameAndUnitName();
  }

  private setInventoryNameAndUnitName() {
    forEach(this.productBuildQueue, (item) => {
      if (item.InventoryProductId) {
        const inventoryProduct = find(this.inventoryProducts, (inventory: any) => inventory.Id === item.InventoryProductId);
        item.InventoryProduct = inventoryProduct.Name;
        item.Unit = inventoryProduct.UnitName;
        item.ItemCount = Number(item.ItemCount.toFixed(2));
      }
    });
  }

  private setDefaultTerminalProperty() {
    this.terminalProperty = {
      Id: 0,
      TerminalId: this.terminalId,
      PropertyKey: DomainConstants.TerminalProperties.GRID_CONFIG.Key,
      PropertyValue: ''
    };
  }

  getTerminalPropertiesCompleted(response: Array<TerminalProperty>) {
    this.setDefaultTerminalProperty();
    forEach(response, (property: TerminalProperty) => {
      if (property.PropertyKey === DomainConstants.TerminalProperties.GRID_CONFIG.Key) {
        this.terminalProperty = property;
      } else if (property.PropertyKey === DomainConstants.TerminalProperties.PRODUCT_BUILD_QUEUE_GRID.Key) {
        this.gridInfo = JSON.parse(property.PropertyValue);
        this.columns = Array(this.gridInfo.Columns).fill(1).map((x, i) => i);
        this.rows = Array(this.gridInfo.Rows).fill(1).map((x, i) => i);
      }
    });
  }


  getInventoryProductsCompleted = (response) => {
    this.inventoryProducts = response ? response : [];
  }

  getProductQueueData = (columnId, rowId) => {
    const currentProductCard = find(this.productBuildQueue, (queue) => {
      return queue.ColumnId - 1 === columnId && queue.RowId - 1 === rowId;
    });
    return this.terminalDesignMode && !currentProductCard ? this.productBuildQueueService.newProductBuildQueue(columnId + 1, rowId + 1)
      : currentProductCard;
  }

  eventBroadcastingSubscriptions() {
    this.eventSubscriptions.push(this.eventBroadcastingService.terminalDesignModeChanged.subscribe(
      (mode) => {
        this.terminalDesignMode = mode;
      }
    ));

    this.eventSubscriptions.push(this.eventBroadcastingService.configurationFullScreenChanged.subscribe(
      (isFullScreen) => {
        this.utilizeWidthPct = isFullScreen ? 98 : 78;
        this.setCardProperties();
      }
    ));
  }

  onConfigClick = (data, popup) => {
    this.currentConfigQueue = data;
    this.popup = popup;
  }

  editConfig = (configData) => {
    this.popup?.hide();
    const modalRef = this.modalService.show(ProductBuildQueueEditComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        productQueue: cloneDeep(configData),
        inventoryProducts: this.inventoryProducts,
        terminalProperty: this.terminalProperty
      }
    });
    modalRef.close.subscribe(res => {
      if (res && res.shouldReload) {
        const queue: Array<ProductBuildQueue> = this.terminalProperty.PropertyValue ? JSON.parse(this.terminalProperty.PropertyValue) : [];
        forEach(queue, (item) => {
          item.ItemCount = 0;
        });
        remove(queue, (item: ProductBuildQueue) => {
          return (item.ColumnId === res?.queue?.ColumnId &&
            item.RowId === res?.queue?.RowId);
        });
        if (res?.queue?.InventoryProductId) {
          queue.push(res?.queue);
        }
        this.terminalProperty.PropertyValue = JSON.stringify(queue);
        this.saveTerminalProperties();
      }
    });
  }

  removeConfiguration = (config) => {
    this.popup?.hide();
    const queue: Array<ProductBuildQueue> = this.terminalProperty.PropertyValue ? JSON.parse(this.terminalProperty.PropertyValue) : [];
    forEach(queue, (item) => {
      item.ItemCount = 0;
    });
    remove(queue, (item: ProductBuildQueue) => {
      return (item.ColumnId === config?.ColumnId &&
        item.RowId === config?.RowId);
    });
    this.terminalProperty.PropertyValue = JSON.stringify(queue);
    this.saveTerminalProperties();
  }



  saveTerminalProperties = () => {
    this.spinnerService.show();
    this.productBuildQueueService.saveTerminalProperty(this.terminalProperty)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: TerminalProperty) => {
          this.getProductBuildQueue(true);
          this.terminalProperty = res ? res : this.terminalProperty;
          this.setCardProperties();
        }, error: this.alertsService.showApiError
      });
  }

}
