import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalComponent } from '../modal/modal-component';
import { userTimes } from '../icon';
import { ApplicationService } from '../../services/application.service';

@Component({
  selector: 'pos-force-logout-modal',
  templateUrl: './force-logout-modal.component.html',
  styleUrls: ['./force-logout-modal.component.scss']
})
export class ForceLogoutModalComponent extends ModalComponent {

  @Input() isForceLogout = false;
  @Input() forceLogOutMessage: string = '';
  constructor(private bsModalRef: BsModalRef,
    private applicationService: ApplicationService) {
    super(bsModalRef);
  }

  icons = {
    userTimes
  }

  closeForceLogOutModal() {
    this.hide({});
    this.applicationService.logout(this.isForceLogout, true);
  }

}
