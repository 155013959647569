import { Injectable } from '@angular/core';
import { RuntimeConstants, SettingParam } from 'src/app/shared';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeliveryService {

  deliveryApiBasePath = `${RuntimeConstants.API_PATH}delivery`;

  constructor(private httpClient: HttpClient) { }

  getDeliveryInfo(terminalId: number): Observable<SettingParam> {
    return this.httpClient.get<SettingParam>(`${this.deliveryApiBasePath}/${terminalId}`);
  }

  saveDelivery(params: SettingParam): Observable<SettingParam> {
    return this.httpClient.post<SettingParam>(this.deliveryApiBasePath, params);
  }
}
