import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { plusMessage } from 'src/app/shared/components/icon';
import { ButtonConfig, GridConfig, KioskScreenChoice } from '../../interfaces';
import { ColorUtilityService, DomainConstants, ModalService, RuntimeConstants } from 'src/app/shared';
import { SetKioskButtonComponent } from '../button-actions/set-kiosk-button/set-kiosk-button.component';
import { OrderKioskConfigService, OrderKioskStorageService } from '../../services';
import { KioskNavigationService } from '../../services/kiosk-navigation.service';
import { find, forEach } from 'lodash';
declare let $: any;

@Component({
  selector: 'pos-kiosk-designer-grid',
  templateUrl: './kiosk-designer-grid.component.html',
  styleUrls: ['./kiosk-designer-grid.component.scss']
})
export class KioskDesignerGridComponent implements OnChanges {
  @Input() grid: GridConfig;
  @Input() buttonConfig: ButtonConfig;
  @Input() screenId: number;
  @Input() screenButtons: Array<KioskScreenChoice> = [];
  @Input() templateType: string;
  @Output() screenButtonsChange = new EventEmitter<Array<KioskScreenChoice>>();
  @Input() isAllowDelete = false;
  @Input() isDesignMode = false;
  @Input() designerGridId = null;
  rowsArray: Array<KioskScreenChoice> = [];
  templateTypes = DomainConstants.KioskScreenTemplateTypes;
  itemWidth = 0;
  itemMaxWidth = 0;
  buttonRootPath = `${RuntimeConstants.IMAGE_BASE_PATH}/order-kiosk/buttons/`;
  tagRootPath = `${RuntimeConstants.IMAGE_BASE_PATH}/tags/`;
  icons = {
    plusMessage
  }
  constructor(
    private modalService: ModalService,
    private orderKioskConfigService: OrderKioskConfigService,
    private colorUtilityService: ColorUtilityService,
    private kioskNavigationService: KioskNavigationService,
    private kioskStorageService: OrderKioskStorageService
  ) {
  }

  ngOnInit() {
    this.calculateItemWidth();
    const width = this.buttonConfig.Width ?? this.itemWidth;
    document.documentElement.style.setProperty("--max-overlay-height", this.buttonConfig.Height && this.buttonConfig.Height < width ? this.buttonConfig.Height + 'px' : (this.itemWidth + 'px'))
    this.setRowAndColumnConfiguration();
    this.orderKioskConfigService.setScreenButtonsBackgroundColor(this.screenButtons, this.buttonConfig.Opacity);
  }

  private setRowAndColumnConfiguration() {
    let rowCount = this.grid.NoOfRows;
    if (!this.grid.NoOfRows) {
      rowCount = this.screenButtons?.length > 1 ? Math.ceil(this.screenButtons.length / this.grid.NoOfCols) : 1;
    }
    this.rowsArray = new Array(rowCount);
    this.screenButtons = this.screenButtons.length ? this.screenButtons : new Array(rowCount * this.grid.NoOfCols);
    this.resizeWindow();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['screenButtons']) {
      this.screenButtons = changes['screenButtons'].currentValue;
      if (this.templateType == DomainConstants.KioskScreenTemplateTypes.FluidGridLayout && this.screenButtons?.[0]?.ScreenConfig.Name == 'SecondaryButtons') {
        this.grid.NoOfCols = this.screenButtons.length;
      } else if (this.templateType == DomainConstants.KioskScreenTemplateTypes.SplitScreenLayout) {
        this.setGridRowsCols();
      } else if (this.templateType == DomainConstants.KioskScreenTemplateTypes.FixedGridLayout) {
        this.setRowAndColumnConfiguration();
      }
      this.setColorsWithOpacity();
    }
  }

  private updateButtonWidthConfig(width) {
    if (this.kioskStorageService.kioskScreens) {
      forEach(this.kioskStorageService.kioskScreens, (screen) => {
        if (screen.Id == this.screenId) {
          forEach(screen.KioskScreenConfigs, (config) => {
            if (config.Name == DomainConstants.KioskScreenConfigProperties.ButtonConfig) {
              config.Config = JSON.stringify(this.buttonConfig);
            }
          });
        }
      });
    }
  }

  private setColorsWithOpacity() {
    this.orderKioskConfigService.setScreenButtonsBackgroundColor(this.screenButtons, this.buttonConfig.Opacity);
    this.buttonConfig.TextBackground = this.colorUtilityService.convertColorToRgba(this.buttonConfig.TextBackground, this.buttonConfig.Opacity);
  }

  private calculateItemWidth() {
    if (!this.buttonConfig.Width) {
      const designerGridWidth = $(`.kiosk-designer-grid-${this.screenId}-${this.designerGridId}`).width();
      if (designerGridWidth) {
        const width = (designerGridWidth / this.grid.NoOfCols) - 19;
        // this.updateButtonWidthConfig(width);
        this.itemWidth = width;
        this.itemMaxWidth = width;
      }
    } else {
      this.itemWidth = this.buttonConfig.Width;
      this.itemMaxWidth = this.buttonConfig.Width;
    }

  }

  resizeWindow() {
    $(window).resize(() => {
      this.calculateItemWidth();
    });
  }

  buttonClick = (kioskScreenChoice: KioskScreenChoice) => {
    if (kioskScreenChoice.Button && !this.isDesignMode) {
      kioskScreenChoice.Button.NextScreenId = kioskScreenChoice.NextScreenId;
      this.kioskNavigationService.buttonClick(kioskScreenChoice.Button, this.screenId, kioskScreenChoice.ConfigId);
    } else if (!kioskScreenChoice.Button && this.isDesignMode) {
      this.configure(kioskScreenChoice);
    }
  }

  configure(button: KioskScreenChoice) {
    const modalRef = this.modalService.show(SetKioskButtonComponent, {
      backdrop: 'static',
      class: 'vertical-center',
      animated: false,
      keyboard: false,
      initialState: {
        kioskScreenButton: button
      }
    });
    modalRef.close.subscribe((res) => {
      if (res?.isReloadButtons) {
        this.kioskNavigationService.reloadNavigationButtons.emit();
      }
    });
  }

  emitScreenButtonsChange(screenButtons) {
    this.screenButtonsChange.emit(screenButtons);
  }

  private setGridRowsCols() {
    if (this.screenButtons?.[0]?.ScreenConfig.Name == 'RightPanelButtons') {
      this.grid.NoOfRows = this.screenButtons.length;
      this.rowsArray = new Array(this.grid.NoOfRows);
    }
    else if (this.grid.NoOfRows) this.grid.NoOfCols = this.screenButtons.length;
  }
}
