import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DomainConstants } from "src/app/shared/constants/domain-constants";
import {
    cog
} from 'src/app/shared/components/icon';
@Component({
    selector: 'pos-order-bottom-clock-and-layout',
    templateUrl: './order-bottom-clock-and-layout.component.html',
})
export class MakeTableBottomClockComponent implements OnInit {
    views = DomainConstants.MaketableView;

    @Input() currentLayout: string;
    @Output() changeLayout: EventEmitter<string> = new EventEmitter<string>();
    icons = { cog };
    constructor() { }
    ngOnInit() {
    }

    changeLayoutClicked(layout) {
        this.changeLayout.emit(layout);
    }

}
