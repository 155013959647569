import { MessagePayload } from './message-payload';

export class OrderProductPayload extends MessagePayload {

    public OrderId: number;
    public OrderProductId: number;

    constructor(orderId: number, orderProductId: number) {
        super();
        this.OrderId = orderId;
        this.OrderProductId = orderProductId;
    }
}
