<pos-edit-wrapper heading="Choose Screen" (close)="onCancel()">
    <div class="col-12 pos-modal-body">
      <pos-row>
        <pos-column>
          <pos-form-field label="Search">
            <input posInput class="form-control" name="searchProduct" placeholder="Filter..." #searchProduct="ngModel"
              [(ngModel)]="searchFilter">
          </pos-form-field>
        </pos-column>
      </pos-row>
      <div class="col-12 modal-content__list mt-pix-10">
        <ul class="list-group">
          <div *ngFor="let screen of screens | customFilter:searchFilter">
            <li class="list-group-item" (click)="setScreenId(screen)" *ngIf="screenId != screen.id"
              [ngClass]="{'bg-theme-primary': screen.id === selectedScreenId}">
              {{screen.Screen}}</li>
          </div>
        </ul>
      </div>
    </div>
    <div class="col-12 action-button-container popup-footer">
      <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" (click)="onSave()">Ok</button>
      <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" (click)="onCancel()">Cancel</button>
    </div>
</pos-edit-wrapper>
