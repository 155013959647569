import { MessagePayload } from './message-payload';

export class OrderDetailsPayload extends MessagePayload {

    public OrderDetails: any;

    constructor(orderDetails: any) {
        super();
        this.OrderDetails = orderDetails;
    }
}
