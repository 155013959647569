import { Component, OnInit } from '@angular/core';
import { DateColumn, GridColumn, TarkTimePipe, TextAlign } from '@tarktech/tark-ng-utils';
import { OnlineOrderingAnnouncementSchedule } from '../../interface/online-ordering-announcement-schedule';
import { AlertsService, ApplicationStateService, BaseListComponent, Messages, ModalService, PrintTableService, SpinnerService } from 'src/app/shared';
import { AnnouncementScheduleEditComponent } from '../announcement-schedule-edit/announcement-schedule-edit.component';
import { Format } from '@tarktech/tark-ng-utils/table/format-type';
import { Router, ActivatedRoute } from '@angular/router';
import { OnlineOrderingAnnouncementScheduleService } from '../../service/online-ordering-announcement-schedule.service';

@Component({
  selector: 'pos-announcement-schedule',
  templateUrl: './announcement-schedule.component.html',
  styleUrls: ['./announcement-schedule.component.scss']
})
export class AnnouncementScheduleComponent extends BaseListComponent<OnlineOrderingAnnouncementSchedule> implements OnInit {

  announcementScheduleColumns: Array<GridColumn> = [];
  announcementSchedules: Array<OnlineOrderingAnnouncementSchedule> = [];
  deleteSuccessMessage = Messages.OnlineOrderAnnouncementScheduleDeleteSuccess;
 
  constructor(
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected printService: PrintTableService,
    protected applicationStateService: ApplicationStateService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected onlineOrderingAnnouncementScheduleService: OnlineOrderingAnnouncementScheduleService,
    protected tarkTimePipe: TarkTimePipe,
    protected alertService: AlertsService) {
    super(onlineOrderingAnnouncementScheduleService
      , alertService, spinnerService, modalService, applicationStateService, printService, route);
     }

  ngOnInit(): void {
    this.loadData();
    this.announcementScheduleColumns = this.configureGridColumns();
    this.reload();
  }

  loadData() {
    this.data$.subscribe({
      next: (response) => {
        this.announcementSchedules = response;
      }
    })
  }

  getGridColumns(): GridColumn[] {
    return [
      new DateColumn({ HeaderText: 'Start', Field: 'StartDateTime', Format: Format.DateTime, Width: '45%', TextAlign: TextAlign.Left }),
      new DateColumn({ HeaderText: 'End', Field: 'EndDateTime', Width: '45%', Format: Format.DateTime, TextAlign: TextAlign.Left }),
    ]
  }

  getConfirmDeleteMessage(data: OnlineOrderingAnnouncementSchedule): string {
    return Messages.OnlineOrderAnnouncementScheduleConfirmDelete;
  }

  editItem(scheduleId: number, data = null) {
    const modalRef = this.modalService.getModalWrapper(AnnouncementScheduleEditComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      initialState: {
        scheduleId: scheduleId,
      }
    });
    modal.close.subscribe(res => {
      if (res?.shouldReload) {
        this.reload();
      }
    });
  }
}
