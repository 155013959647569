<pos-edit-wrapper (close)="onCancel(false)" [isPopup]="true" heading="Product Ingredient: {{recipe.Product}}">
  <form #recipeEditForm="ngForm" (ngSubmit)="saveIngredient(recipeEditForm.valid)" autocomplete="off">
    <div class="overflow-hidden pos-modal-body">
      <div class="row screen-scroll">
        <pos-row>
          <pos-column>
            <pos-form-field label="Effective Date">
              <p-calendar posInput #effectiveDate="ngModel" [readonlyInput]="true" [dateFormat]="dateFormat"
                [ngClass]="{'has-error': (effectiveDate.invalid && recipeEditForm.submitted)}"
                [inputStyle]="{'width':'unset','display':'unset'}" inputStyleClass="form-control" [monthNavigator]=true
                [showIcon]="true" name="effectiveDate" appendTo="body" [(ngModel)]="recipe.DateEffective">
              </p-calendar>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Expired Date">
              <p-calendar posInput #expiredDate="ngModel" [readonlyInput]="true" [minDate]="minDate"
                [dateFormat]="dateFormat" [ngClass]="{'has-error': (expiredDate.invalid && recipeEditForm.submitted)}"
                [inputStyle]="{'width':'unset','display':'unset'}" inputStyleClass="form-control" [monthNavigator]=true
                [showIcon]="true" name="expiredDate" appendTo="body" [(ngModel)]="recipe.DateExpired">
              </p-calendar>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Instruction">
              <textarea posInput class="form-control" name="instructions" #instructions="ngModel"
                [(ngModel)]="recipe.instructions"></textarea>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Custom Color">
              <pos-color-picker posInput name="customColor" [(ngModel)]="recipe.CustomColor">
              </pos-color-picker>
            </pos-form-field>
          </pos-column>

          <pos-column>
            <pos-form-field label="Qty" [validationMessages]="{required: 'Please enter qty.'}">
              <input posInput type="number" name="qty" #qty="ngModel" class="form-control col-100 display-initial"
                [(ngModel)]="recipe.qty" [required]="true" />
              <label class="edit-form__section__label col-100 ps-pix-10">{{recipe.Unit}}</label>
            </pos-form-field>
          </pos-column>
          <pos-column>
            <div class="pt-pix-10">Unit Qty: {{recipe.UnitQty}} {{recipe.Unit}}</div>
          </pos-column>
        </pos-row>
      </div>
      <div class="clearfix"></div>
    </div>
    <div class="col-12 action-button-container popup-footer">
      <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save </button>
      <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" (click)="onCancel(false)">Close</button>
    </div>
  </form>
</pos-edit-wrapper>
