<pos-edit-wrapper (close)="cancelEditing()" heading="{{id > 0 ? 'Edit Equipment Type' : 'Add Equipment Type'}}"
    [headingIcon]="icons.equipmentPropertiesDolly" [isPopup]="false">
    <form #equipmentTypeForm="ngForm" (submit)="submit(equipmentTypeForm.valid)" autocomplete="off">
        <div class="col-12 pos-page-body border-top pt-pix-5">
            <div class="col-12">
                <pos-row>
                    <pos-column>
                        <pos-form-field label="Name" [validationMessages]="{required: 'Please enter equipment type.'}"
                            inputStyle="col-md-3 col-sm-6 col-xs-12">
                            <input posInput type="text" name="Name" class="form-control"
                                [(ngModel)]="equipmentType.Name" [required]="true" />
                        </pos-form-field>
                    </pos-column>
                    <pos-column>
                        <pos-form-field label="Equipment Properties">
                            <ng-select posInput [items]="equipmentPropertyList" bindLabel="Name" [multiple]="true"
                                placeholder="Select equipment properties" [(ngModel)]="selectedEqipmentProperties"
                                [hideSelected]="true" name="equipmentProperties" appendTo="body"
                                (change)="onChangeProperties()">
                            </ng-select>
                        </pos-form-field>
                    </pos-column>
                </pos-row>

                <pos-row class="ban-color bold mt-pix-15 g-0">
                    <pos-column lg="2" md="3" sm="4" xs="6" class="ps-pix-5">Property Name
                    </pos-column>
                    <pos-column lg="3" md="4" sm="8" xs="6" >Description</pos-column>
                </pos-row>
                <div class="col-xs-12 p-0 overflow-auto" [ngStyle]="{'height': height + 'px'}">
                    <pos-row *ngFor="let equipmentProperty of equipmentType.EquipmentTypeProperties; let index = index" class="g-0" >
                        <pos-column lg="2" md="3" sm="4" xs="12" class="pt-pix-10 ps-pix-5 pe-0">
                            <label class="edit-form__section__label">{{equipmentProperty.EquipmentPropertyName}}</label>
                        </pos-column>
                        <pos-column lg="5" md="8" sm="8" xs="12">
                            <pos-form-field inputStyle="col-xs-12">
                                <input posInput type="text" name="{{equipmentProperty.EquipmentPropertyName}}{{index}}"
                                    class="form-control" [(ngModel)]="equipmentProperty.Description"
                                    #{{equipmentProperty.EquipmentPropertyName}}{{index}}="ngModel" />
                            </pos-form-field>
                        </pos-column>
                    </pos-row>
                </div>
            </div>
        </div>
        <div class="col-12 action-button-container page-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" value="Save">Save </button>
            <button type="button" class="btn-secondary btn-lg btn btn-cancel pos-button" (click)="cancelEditing()"
                value="Cancel">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
