import { Component, OnInit, ViewChild } from '@angular/core';
import { Playlist } from '../../interface';
import { PlaylistsService } from '../../services';
import { FieldInfoMessages, SpinnerService, AlertsService, BaseCrudComponent, Messages, FormUtilityService } from 'src/app/shared';
import { finalize } from 'rxjs/operators';
import { Options } from '@angular-slider/ngx-slider/options';
import { ActivatedRoute, Router } from '@angular/router';
import { playListThList } from 'src/app/shared/components/icon/icons';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'pos-playlist-edit',
  templateUrl: './playlist-edit.component.html',
  styleUrls: ['./playlist-edit.component.scss']
})
export class PlaylistEditComponent extends BaseCrudComponent<Playlist> implements OnInit {
  @ViewChild('playlistForm') playlistForm: NgForm;
  get getForm(): NgForm {
    return this.playlistForm
  }
  playlist: Playlist;
  playlistId = 0;
  fieldInfoMessages = FieldInfoMessages;
  templatesTransParam: any;
  isUserSchemeVolume = false;
  options: Options = {
    floor: 1,
    ceil: 100,
    translate: (value: number): string => {
      return '';
    }
  };
  icons = {
    playListThList
  }
  props = {
    controlBoxClass: 'col-lg-3 col-md-5 col-sm-8 col-xs-12'
  }

  constructor(
    protected spinnerService: SpinnerService,
    protected alertService: AlertsService,
    protected router: Router,
    private route: ActivatedRoute,
    private playlistService: PlaylistsService,
    formUtilityService: FormUtilityService) {
    super(playlistService, alertService, spinnerService, formUtilityService);
    this.playlistId = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
    this.playlist = playlistService.newPlaylist();
  }

  ngOnInit() {
    this.saveSuccessMessage = Messages.PlaylistSaveSuccess;
  }

  loadDependencies(): void {
    if (this.playlistId > 0) {
      this.spinnerService.show();
      this.playlistService.get(this.playlistId)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res) => {
            this.playlist = res;
            this.isUserSchemeVolume = !(this.playlist && this.playlist.Volume);
          }
        });
    } else {
      this.playlist = this.playlistService.newPlaylist();
      this.isUserSchemeVolume = !(this.playlist && this.playlist.Volume);
    }
  }

  onChangeIsUserSchemeVolume() {
    if (this.isUserSchemeVolume) {
      this.playlist.Volume = 0;
    }
  }

  savePlaylist(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.save(this.playlist);
  }

  onSaveSuccess(model: Playlist): void {
    this.router.navigate(['playlists'], { relativeTo: this.route.parent });
  }

  cancel() {
    this.router.navigate(['playlists'], { relativeTo: this.route.parent });
  }

}
