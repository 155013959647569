import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { find } from 'lodash';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { RuntimeConstants } from '../constants/runtime-constants';
import { ApplicationFeatures } from '../interface/application-features';
import { ApplicationStateService } from './application-state.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationFeaturesService {

  applicationFeaturesApiPath = `${RuntimeConstants.API_PATH}application-features/`;
  constructor(private httpService: HttpClient,
    private applicationStateService: ApplicationStateService) { }

  getApplicationFeatures(): Observable<Array<ApplicationFeatures>> {
    return this.httpService.get(`${this.applicationFeaturesApiPath}`)
      .pipe((map((response: Array<ApplicationFeatures>) => {
        if (response) {
          this.applicationStateService.applicationFeatures = response;
        }
        return response;
      })));
  }

  isEnableFeature(featureName: string): boolean {
    const features = this.applicationStateService.applicationFeatures;
    if (features?.length && featureName) {
      const currentFeature: ApplicationFeatures = find<ApplicationFeatures>(features, (feature: ApplicationFeatures) => {
        return feature.Name === featureName;
      });
      return currentFeature != null ? currentFeature.IsEnabled : true;
    }
  }
}
