import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { UserTrainingLevel } from '../interface/user-training-level';


@Injectable()
export class UserTrainingLevelService {
    constructor(private httpService: HttpClient) { }
    getUserTrainingLevels(): Observable<UserTrainingLevel[]> {
        return this.httpService.get<UserTrainingLevel[]>(
            `${RuntimeConstants.API_PATH}usertraininglevel`
        );
    }

    saveUserTrainingLevel(userTrainingLevel: UserTrainingLevel): Observable<any> {
        return this.httpService.post(
            `${RuntimeConstants.API_PATH}usertraininglevel`, userTrainingLevel
        );
    }
}
