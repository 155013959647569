import { ApplicationStateService, DomainConstants } from 'src/app/shared';
import { EventEmitter, Component, OnInit, ViewChild, Input, Output } from '@angular/core';
import { AlertsService } from './../../../../shared/services/alerts.service';
import { AccountAttributeTypeService } from './../../account-attribute-types/services/account-attribute-type.service';
import { GridColumn, TemplateColumn } from '@tarktech/tark-ng-utils';
import { AccountAttribute } from '../interface/account-attribute';
import * as _ from 'lodash';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'pos-account-attributes',
  templateUrl: './account-attributes.component.html',
  styleUrls: ['./account-attributes.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class AccountAttributesComponent implements OnInit {

  accountAttributeColumns: Array<GridColumn> = [];
  @Input('accountAttributes') accountAttributes: Array<AccountAttribute> = [];
  accountAttributeTypes: any = [];
  dateFormat = 'mm-dd-yyyy';

  accountAttributeValueType = DomainConstants.AccountAttributeValueTypes;
  @Output() accountAttributeType = new EventEmitter<any>();
  @ViewChild('nameTemplate') private nameTemplate: any;
  constructor(
    private accountAttributeTypeService: AccountAttributeTypeService,
    private applicationStateService: ApplicationStateService,
    private alertService: AlertsService
  ) { }

  ngOnInit() {
    this.configureAccountAttributes();
    this.dateFormat = this.applicationStateService.settingParam.PCalendarDateFormat;
  }

  ngOnChanges() {
    this.configureAccountAttributes();
  }
  changeAttribute() {
    this.accountAttributeType.emit(this.accountAttributeTypes);
  }

  getOptions(value) {
    if (value) {
      return value.split(',');
    }

  }
  configureAccountAttributes() {
    this.accountAttributeTypeService.getAccountAttributeTypes()
      .subscribe({
        next: (response) => {
          this.accountAttributeTypes = response;
          _.forEach(this.accountAttributeTypes, (attributeType) => {
            let attribute = _.find(this.accountAttributes, (attribute) => {
              return attribute.AccountAttributeType?.Name == attributeType.Name;
            });
            if (attribute) {
              if (attributeType.ValueType == this.accountAttributeValueType.Date) {
                attributeType.Value = attribute.AttributeValue ? new Date(attribute.AttributeValue) : null;
              } else {
                attributeType.Value = attribute.AttributeValue;
              }

            }
            if (attributeType.ValueType === this.accountAttributeValueType.Dropdown
              || attributeType.ValueType === this.accountAttributeValueType.Radio) {
              attributeType.DropdownValues = attributeType.QueryValue?.split(',');
            }

          });

        }, error: this.alertService.showApiError
      });

    const nameColumn = new TemplateColumn({
      itemTemplate: this.nameTemplate,
      Width: '20%',
      CellClass: 'account-attributes'
    });
    this.accountAttributeColumns = [
      new GridColumn({ Field: 'Name', IsSortable: true, Width: '20%', CellClass: 'account-attributes' }),
      nameColumn
    ] as Array<GridColumn>;
  }

}
