<pos-edit-wrapper (close)="cancelEditing()" [isPopup]="false" [headingIcon]="icons.loginMessagesCommentDots"
  heading="{{id > 0 ? 'Edit Login Memo': 'Add Login Memo'}}">
  <form #formloginmessage="ngForm" class="edit-form" (ngSubmit)="submit(formloginmessage.valid)">
    <div class="col-12 pos-page-body border-top pt-pix-5">
      <div class="col-12">
        <pos-row>
          <pos-column>
            <pos-form-field label="Message" [validationMessages]="{required: 'Please enter message.'}">
              <textarea posInput class="form-control" maxlength="500" name="LoginMessage"
                #LoginMessage="ngModel" [(ngModel)]="loginMessageDetail.message" placeholder="" rows="6"
                style="height: 111px" [required]="true"></textarea>
            </pos-form-field>
          </pos-column>
        </pos-row>
      </div>
    </div>
    <div class="col-12 action-button-container page-footer">
      <button class="btn-primary btn-lg btn btn-save pos-button" scrollup type="submit" value="Save">Save</button>
      <button class="btn-secondary btn-lg btn btn-cancel pos-button" (click)="cancelEditing()" scrollup type="button"
        value="Cancel">Cancel</button>
    </div>
  </form>
</pos-edit-wrapper>
