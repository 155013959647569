import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridColumn, TableComponent, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { AlertsService, ApplicationStateService, Permissions, Levels, Messages, PrintTableService, StringUtils, AuthenticationService, ExportService } from 'src/app/shared';
import { BaseListComponent, editWhite, lockOpen, ModalService, SpinnerService } from 'src/app/shared/components';
import { PermissionType } from '../../interfaces/permission-type';
import { PermissionTypesService } from '../../services/permission-types.service';

@Component({
  selector: 'pos-permissions-list',
  templateUrl: './permissions-list.component.html'
})
export class PermissionsListComponent extends BaseListComponent<PermissionType> implements OnInit {

  permissions: Array<PermissionType> = [];
  permissionsColumns: Array<GridColumn> = [];
  printPermissionsColumns: Array<GridColumn> = [];
  public icons = {
    lockOpen, editWhite
  };

  permission = {
    name: Permissions.Permissions,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };

  @ViewChild('permissionsGrid', { static: true }) permissionsGrid: TableComponent;
  @ViewChild('operationTemplate', { static: true }) operationTemplate: TemplateRef<any>;
  @ViewChild('operationHeaderTemplate', { static: true }) operationHeaderTemplate: TemplateRef<any>;

  constructor(protected permissionTypesService: PermissionTypesService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected applicationStateService: ApplicationStateService,
    protected printService: PrintTableService,
    private authenticationService: AuthenticationService,
    private exportService: ExportService,
    private router: Router,
    protected route: ActivatedRoute) {
    super(permissionTypesService, alertService, spinnerService, modalService, applicationStateService, printService, route);
  }

  ngOnInit(): void {
    this.isAddIconVisible = false;
    this.deleteSuccessMessage = Messages.PermissionDelete;
    this.permissionsColumns = this.configureGridColumns();
    this.permissionsGrid.context = this.gridContext;
    this.loadSubscriptions();
  }

  loadSubscriptions() {
    this.data$.subscribe({ next: (res) => {
      this.permissions = res;
    }});
  }

  protected configureGridColumns(): GridColumn[] {
    const operationColumn = new TemplateColumn({
      itemTemplate: this.operationTemplate,
      Width: '90px',
      headerTemplate: this.operationHeaderTemplate,
      CellClass: 'cell-padding',
      TextAlign: TextAlign.Center
    });

    this.printPermissionsColumns = [
      new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '97%' })
    ];

    const operationColumns = [];
    if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      operationColumns.push(operationColumn);
    }

    return [...this.printPermissionsColumns, ...operationColumns];
  }

  getGridColumns(): GridColumn[] {
    return this.permissionsColumns;
  }

  editItem(id: number, data?: PermissionType): void {
    this.router.navigate([id], { relativeTo: this.route });
  }

  getConfirmDeleteMessage(data: PermissionType): string {
    return StringUtils.format(Messages.PermissionDeleteConfirm,
      { 'name': data ? data.Name : '' }
    );
  }

  printDiv() {
    this.printService.printEmitter.next({ gridColumns: this.printPermissionsColumns, gridData: this.permissions });
  }

  exportCSV() {
    this.exportService.exportCSV('permissions', this.permissions, null, this.printPermissionsColumns);
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

}
