<pos-edit-wrapper heading="{{id > 0 ? 'Edit Kiosk Button: ' + buttonName : 'Add Kiosk Button'}}"
  (close)="cancelEditing()" [isPopup]="isPopup" [headingIcon]="icons.buttonsDiceOne">
  <form #formButton="ngForm" (ngSubmit)="submit(formButton.valid)" class="edit-form" autocomplete="off">
    <context-provider provide="props">
      <div class="col-12 container-fluid" [ngClass]="{'border-top pt-pix-10': !isPopup}">
        <div class="vertical-tabs">
          <ul class="nav nav-tabs inner-tab pos-nav-tabs">
            <li [ngClass]="{'active': selectedTab == tabList.Details }">
              <a (click)="selectedTab = tabList.Details" class="blue" [ngClass]="{'required': details.invalid }">
                <pos-icon [icon]="icons.infoCircle" class="pe-pix-5 theme-separator"></pos-icon> Details
              </a>
            </li>
            <li *ngIf="button.ButtonType != placeholderButtonType"
              [ngClass]="{'active': selectedTab == tabList.Behaviors }">
              <a (click)="selectedTab = tabList.Behaviors" class="blue">
                <pos-icon [icon]="icons.buttonBehaviorPlus" class="pe-pix-5 theme-separator"></pos-icon>Behaviors
              </a>
            </li>
          </ul>
        </div>
        <div>
          <div [hidden]="selectedTab != tabList.Details" ngModelGroup="details" #details="ngModelGroup"
            class="col-12 pos-tab-body pt-pix-5">
            <pos-row>
              <pos-column>
                <pos-form-field label="Button Text"
                  [validationMessages]="{required: 'Please enter button text.'}">
                  <input posInput name="buttonText" id="buttonText" #buttonText="ngModel" class="form-control"
                    [(ngModel)]="button.ButtonText" [required]="true" />
                </pos-form-field>
              </pos-column>
              <pos-column>
                <pos-form-field label="Button Type" [validationMessages]="{required:'Please select button type.'}">
                  <div class="row row-cols-sm-2 row-cols-1 mx-0">
                    <div class="ps-0">
                      <ng-select posInput [items]="buttonTypes" placeholder="Select button type" appendTo="body"
                        [(ngModel)]="button.ButtonType" name="buttonType" id="buttonType" #buttonType="ngModel"
                        [required]="true">
                      </ng-select>
                    </div>
                  </div>
                </pos-form-field>
              </pos-column>
              <pos-column>
                <pos-form-field label="Button Image">
                  <div class="row">
                    <div class="col-9">
                      <input posInput type="file" class="form-control" accept=".png,.jpg,.jpeg,.bmp" name="buttonImage"
                        id="buttonImage" (change)="handleFileInput($event.target.files)" ngModel #buttonImage />
                    </div>
                    <div class="col-md-1 col-3 p-0 col-70 mt-pix-n1 text-center" [hidden]="!button.Image">
                      <img class="icon" src="{{buttonImagePath}}/{{button.Image}}" #buttonImagePreview
                        id="buttonImagePreview" alt="" height="40" />
                      <div class="relative float-right mt-pix-n5" (click)="removeIcon()">
                        <pos-icon [icon]="icons.times" class="pointer ms-pix-n7 remove-icon-badge"></pos-icon>
                      </div>
                    </div>
                  </div>
                </pos-form-field>
              </pos-column>

              <pos-column>
                <pos-form-field label="Text Color">
                  <pos-color-picker posInput [(ngModel)]="button.TextColor" name="buttonTextColor" ></pos-color-picker>
                </pos-form-field>
              </pos-column>
              <pos-column>
                <pos-form-field label="Border Color">
                  <pos-color-picker posInput [(ngModel)]="button.BorderColor"
                    name="buttonBorderColor"></pos-color-picker>
                </pos-form-field>
              </pos-column>
              <pos-column>
                <pos-form-field label="Background Color">
                  <pos-color-picker posInput [(ngModel)]="button.BackgroundColor"
                    name="buttonBackgroundColor"></pos-color-picker>
                </pos-form-field>
              </pos-column>

              <pos-column>
                <pos-form-field label="Font size">
                  <input posInput name="fontSize" #fontSize="ngModel" class="form-control col-120"
                    [(ngModel)]="button.FontSize" />
                </pos-form-field>
              </pos-column>

              <!-- <pos-column>
                    <pos-form-field label="Sales Group" [validationMessages]="{required:'Please select sales group.'}">
                      <div class="row row-cols-sm-2 row-cols-1 mx-0">
                        <div class="ps-0">
                          <ng-select posInput [items]="salesGroupList" bindLabel="name" bindValue="Id"
                            placeholder="Select sales group" appendTo="body" [(ngModel)]="salesCategory.sales_group_id"
                            name="salesGroup" id="salesGroup" #salesGroup="ngModel"
                            (change)="getSalesCategoryKitchenPrintersForInfo(salesCategory.sales_group_id)"
                            [required]="true">
                          </ng-select>
                        </div>
                      </div>
                    </pos-form-field>
                  </pos-column> -->
            </pos-row>
          </div>
          <div *ngIf="selectedTab == tabList.Behaviors">
            <div class="overflow-auto-tabs width-full row pos-tab-body" [ngStyle]="{'height': getHeight()}">
              <div class="col-12 pt-pix-10">
                <div *ngIf="!id" class="alert condensed inherit-position alert-warning">
                  {{pressApplyToProceed}}</div>
                <div *ngIf="id" class="pt-pix-10">
                  <ng-table [sort]="false" [paginator]="false" [columns]="behaviorColumns"
                    [data]="button.ChoiceBehaviors??[]" [isReorderableTable]="true" [ReorderableColumnRow]="'row'"
                    [search]="false" (onRowReorder)="gridRowReorder($event)" [scrollable]="true"
                    [scrollHeight]="getHeight() - 20 + 'px'">
                  </ng-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 action-button-container" [ngClass]="{'popup-footer': isPopup, 'page-footer': !isPopup}">
        <button class="btn-lg btn-success btn btn-save pos-button" type="submit" value="Apply"
          (click)="isSaveAndContinue = true" *ngIf="!id"> Apply </button>
        <button class="btn-primary btn-lg btn btn-save pos-button" type="submit" value="Save">Save</button>
        <button class="btn-secondary btn-lg btn pos-button"
          [ngClass]="{'btn-cancel-modal': isPopup, 'btn-cancel':!isPopup}" (click)="cancel()" type="button"
          value="Cancel">Cancel</button>
      </div>
    </context-provider>
  </form>
</pos-edit-wrapper>
<ng-template let-data="rowData" #operationTemplate>
  <span class="col-30 d-inline-block col-lg-1 pr-2">
    <pos-action-icon [icon]="icons.editWhite" (click)="editBehavior(data)"></pos-action-icon>
  </span>
  <span class="d-inline-block" (click)="confirmDelete(data)">
    <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
  </span>
</ng-template>

<ng-template #headerTemplate>
  <div class="dropdown text-center">
    <button id="behaviorMenu" class="btn btn-xs text-white normal pos-button btn-primary dropdown-toggle py-0 px-pix-2"
      type="button" data-bs-toggle="dropdown" aria-expanded="false">
      <pos-icon [icon]="icons.buttonBehaviorPlus" class="plus-icon text-white"></pos-icon>
      <span class="pt-pix-5 font-13 text-white "> Behavior </span>
    </button>
    <ul aria-labelledby="behaviorMenu" class="dropdown-menu dropdown-menu-start" role="menu">
      <li role="presentation" *ngFor="let behaviorType of behaviorTypes; last as lastIndex"
        (click)="addBehavior(behaviorType)">
        <a class="pointer dropdown-item py-2" role="menuitem" tabindex="-1">
          <fa-icon class="{{behaviorType.Icon}} me-pix-3 width-10 text-center"></fa-icon>
          {{ behaviorType.Text }}
        </a>
        <div *ngIf="!lastIndex" class="line-separator"></div>
      </li>
    </ul>
  </div>
</ng-template>
<ng-template let-data="rowData" #behaviorTemplate>
  <fa-icon class="{{data.Icon}} me-pix-3 width-10 text-center"></fa-icon>
  {{ data.DisplayName }}
</ng-template>
<pos-button-behavior-edit *ngIf="loadEdit" [selectedButtonBehaviorType]="selectedButtonBehaviorType" [buttonId]="id"
  [buttonType]="button.ButtonType" [buttonBehavior]="buttonBehavior" [screens]="screenList" [buttonList]="buttonList"
  (close)="closeButtonBehaviorEdit($event)" (onSave)="onSaveBehavior($event)">
</pos-button-behavior-edit>
