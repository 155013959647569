import { Component, OnInit, ViewChild } from '@angular/core';
import { Permissions, Levels, Messages, DomainConstants } from '../../../../shared/constants/';
import { GridColumn, TableComponent, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { ConfirmDeleteComponent } from 'src/app/shared/components/confirm-delete/confirm-delete.component';
import { AuthenticationService } from 'src/app/shared/auth/services/authentication.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { finalize } from 'rxjs/operators';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { printWhite, editWhite, deleteWhite, plusWhite, clone, barsWhite, securityRolesUserLock } from './../../../../shared/components/icon';
import { PermissionRoles } from '../../interface/permission-roles';
import { SecurityRoleService } from '../../service/security-role.service';
import { CopySecurityRoleComponent } from '../copy-security-role/copy-security-role.component';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import { BaseGridComponent, ApplicationStateService, ExportService } from 'src/app/shared';
import { ActivatedRoute, Router } from '@angular/router';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';

@Component({
    selector: 'pos-security-roles-list',
    templateUrl: './security-roles-list.component.html',
    styleUrls: ['./security-roles-list.component.scss']
})
export class SecurityRolesListComponent extends BaseGridComponent implements OnInit {
    permission = {
        name: Permissions.ManageSecurityRoles,
        readOnlyLevel: Levels.ReadOnly,
        editLevel: Levels.Edit
    };
    public icons = {
        printWhite,
        editWhite,
        deleteWhite,
        plusWhite,
        clone,
        barsWhite,
        securityRolesUserLock
    };
    adminRole = DomainConstants.SystemPermissionRoles.Administrator;
    securityRoles: PermissionRoles[] = [];
    securityRole: PermissionRoles;
    securityRoleId: number;
    roleName: string;
    securityRolesColumns: Array<GridColumn> = [];
    printSecurityRolesColumns: Array<GridColumn> = [];
    @ViewChild('confirmSecurityRole') private confirmSecurityRole: ConfirmDeleteComponent;
    @ViewChild('operationTemplate', { static: true }) private operationTemplate: any;
    @ViewChild('headerTemplate', { static: true }) private headerTemplate: any;
    @ViewChild('securityRolesGrid', { static: true }) securityRolesGrid: TableComponent;
    popup: any;
    constructor(
        private authenticationService: AuthenticationService,
        private alertService: AlertsService,
        private spinnerService: SpinnerService,
        private securityRoleService: SecurityRoleService,
        private modelService: ModalService,
        protected applicationStateService: ApplicationStateService,
        protected route: ActivatedRoute,
        protected router: Router,
        private printService: PrintTableService,
        private exportService: ExportService
    ) {
        super(applicationStateService, route);
    }

    ngOnInit() {
        this.configureGridColumns();
        this.reloadSecurityRoles();
        this.securityRolesGrid.context = this.gridContext;
    }
    private configureGridColumns() {
        const operationColumn = new TemplateColumn({
            itemTemplate: this.operationTemplate,
            Width: '155px',
            headerTemplate: this.headerTemplate,
            CellClass: 'cell-padding',
            TextAlign: TextAlign.Center
        });

        this.securityRolesColumns = [
            new GridColumn({ HeaderText: 'Role', Field: 'Name', IsSortable: true, Width: '95%' }),
        ] as Array<GridColumn>;

        this.printSecurityRolesColumns = [...this.securityRolesColumns];

        if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
            this.securityRolesColumns.push(operationColumn);
        }
    }
    private reloadSecurityRoles() {
        this.spinnerService.show();
        this.securityRoleService.getSecurityRoles(true)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (permissionRoles: PermissionRoles[]) => {
                    this.securityRoles = permissionRoles;
                }, error: this.alertService.showApiError
            });
    }
    editSecurityRole(securityRoleId: number) {
        this.router.navigate([securityRoleId], { relativeTo: this.route });
    }
    deleteSecurityRole(securityRole: PermissionRoles) {
        this.securityRoleId = securityRole.Id;
        this.confirmSecurityRole.Show(
            StringUtils.format(Messages.ConfirmDeleteSecurityRole, { 'securityRole': securityRole.Name }));
    }
    onConfirmAcknowledge() {
        this.spinnerService.show();
        this.securityRoleService.deleteSecurityRole(this.securityRoleId)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: () => {
                    this.alertService.renderSuccessMessage(Messages.SecurityRoleDeleted);
                    this.reloadSecurityRoles();
                    this.securityRoleId = 0;
                }, error: this.alertService.showApiError
            });
    }
    printDiv() {
        this.printService.printEmitter.next({ gridColumns: this.printSecurityRolesColumns, gridData: this.securityRoles });
    }

    exportCSV() {
        this.exportService.exportCSV('security-roles', this.securityRoles, null, this.printSecurityRolesColumns);
    }

    onLinkPopOver(securityRole: PermissionRoles, popup: any) {
        this.securityRole = securityRole;
        this.popup = popup;
    }
    openSecurityRoleCopyDateModal(securityRole: PermissionRoles) {
        const modalRef = this.modelService.show(CopySecurityRoleComponent, {
            animated: false,
            keyboard: false,
            class: 'vertical-center',
            initialState: {
                roleId: securityRole.Id,
                roleName: securityRole.Name
            }
        });
        modalRef.close.subscribe(res => {
            if (res && res.shouldReload) {
                this.reloadSecurityRoles();
            }
        });
        this.popup.hide();
    }

    close() {
        this.router.navigate(['../'], { relativeTo: this.route });
    }
}
