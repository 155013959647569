import { Component, OnInit, ViewChild, EventEmitter, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalBackdropService } from '../modal/modal-component';

@Component({
  selector: 'pos-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss'],
  outputs: ['onConfirmAcknowledge']
})
export class ConfirmDeleteComponent implements OnInit {
  @ViewChild('confirmModalTemplate') public confirmModalTemplate: any;
  
  @Input('message') message:string='';
  @Input('confirmButtonText') confirmButtonText: string = 'Yes';
  @Input('cancelButtonText') cancelButtonText: string = 'No';
  public confirmMessage;

  private modalRef: BsModalRef;
  
  public onConfirmAcknowledge = new EventEmitter();
  public onCancelAcknowledge = new EventEmitter();

  constructor(    
    private modalService: BsModalService,
    private modalBackdropService: ModalBackdropService
    ) { }

  ngOnInit() {
    this.confirmMessage = this.message;
  }

  public onConfirm(): void {
    this.onConfirmAcknowledge.emit(true);
    this.modalRef.hide();
    this.modalBackdropService.removeBackdrop();
  }

  public hideChildModal(): void {
    this.onCancelAcknowledge.emit(false);
    this.modalRef.hide();
    this.modalBackdropService.removeBackdrop();
  }
  public Show(message) {
    this.confirmMessage = message;
    this.modalRef = this.modalService.show(this.confirmModalTemplate, {
      'backdrop': 'static',
      'class': 'vertical-center',
      keyboard: false
    });
    this.modalBackdropService.addBackDrop();
  }
}
