import { Component, TemplateRef, ViewChild } from '@angular/core';
import { GridColumn, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs';
import { AlertsService, RabbitMQService } from 'src/app/shared/services';
import { KioskTerminalStatusConfig } from 'src/app/configurator/terminals/interface/kiosk-terminal-status-config';
import { TerminalsService } from 'src/app/configurator/terminals/services';
import { ModalComponent, SpinnerService } from 'src/app/shared/components';
import { ban, checkCircleWhite } from 'src/app/shared/components/icon';
import * as $ from 'jquery'

@Component({
  selector: 'pos-kiosk-list',
  templateUrl: './kiosk-list.component.html',
  styleUrls: ['./kiosk-list.component.scss']
})
export class KioskListComponent extends ModalComponent {

  kioskTerminalStatusConfig: KioskTerminalStatusConfig[] = [];
  kioskTerminalStatusConfigColumns: GridColumn[] = [];
  screenHeight = $(window).height() - 500 < 150 ? 200 : 370;
  @ViewChild('operationTemplate', { static: true }) operationTemplate: TemplateRef<any>;
  @ViewChild('headerTemplate', { static: true }) headerTemplate: TemplateRef<any>;

  icons = {
    ban,
    checkCircleWhite,
  };

  constructor(modalRef: BsModalRef,
    private terminalService: TerminalsService,
    private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private rabbitMQService: RabbitMQService
  ){
    super(modalRef);
  }

  ngOnInit() {
    this.resizeWindow();
    this.configureGridColumns();
    this.loadData();
  }

  resizeWindow = () => {
    $(window).resize(() => {
      this.screenHeight = $(window).height() - 500 < 150 ? 200 : 370;
    });
  }

  loadData() {
    this.spinnerService.show();
    this.terminalService.getKioskTerminalStatusConfig()
    .pipe(finalize(() => this.spinnerService.hide()))
    .subscribe({
      next: (res: KioskTerminalStatusConfig[]) => {
        this.kioskTerminalStatusConfig = res;
      },
      error: this.alertsService.showApiError,
    })
  }

  configureGridColumns() {
    const operationColumn = new TemplateColumn({
      itemTemplate: this.operationTemplate,
      Width: '120px',
      headerTemplate: this.headerTemplate,
      TextAlign: TextAlign.Center
    });
    this.kioskTerminalStatusConfigColumns = [
      new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: false, Width: '90%' }),
      operationColumn
    ] as Array<GridColumn>;
  }

  changeKioskStatus(data: KioskTerminalStatusConfig) {

    this.spinnerService.show();

    const dataToUpdate = {...data}

    this.terminalService.updateKioskTerminalStatusConfig([dataToUpdate])
    .pipe(finalize(() => this.spinnerService.hide()))
    .subscribe({
      next: () => {
        this.loadData();
        this.rabbitMQService.sendOrderKioskStateUpdatedMessage(data.TerminalId, data.IsEnabled);
      },
      error: this.alertsService.showApiError,
    })
  }

  disableAllKiosk() {
    const dataToUpdate = this.kioskTerminalStatusConfig.map((config) =>{ return {...config, isEnabled: false} });
    this.terminalService.updateKioskTerminalStatusConfig(dataToUpdate)
    .pipe(finalize(() => this.spinnerService.hide()))
    .subscribe({
      next: () => {
        this.loadData();
        this.rabbitMQService.sendOrderKioskStateUpdatedMessage(0, false);
      },
      error: this.alertsService.showApiError,
    })
  }

  closeModal() {
    this.hide({});
  }
}
