<div class="login-page">
  <form>
    <header class="pull-left width-full bg-theme-primary">
      <div class="col-12 login-page__logo text-center">
        <a href="">
          <img class="login-page__logo-image" src="{{imagePath}}/logo/logo.png" />
        </a>
      </div>
    </header>
    <div class="login-page__section">
      <div class="float-end m-pix-10" *ngIf="isImpersonateUser">
        <button type="submit" value="Save" class="btn-danger white btn normal pos-button" (click)="cancelImpersonateUser()"
          data-dismiss="modal">
          End Impersonation
        </button>
      </div>

      <div class="col-12" *ngIf="!userHasPermission">
        <div class="col-12 col-p">
          <div class="mt-pix-10 col-lg-10 col-md-10 col-sm-10 offset-sm-1 col-12">
            <div class="alert condensed inherit-position alert-danger">{{terminalAccessDenied}}</div>
          </div>
        </div>
      </div>

      <div class="col-12 login-page__section__body" [hidden]="!userHasPermission">
        <h2 class="login-page__section__body__heading">Select Terminal</h2>
        <div class="col-12 terminal-form">
          <fieldset class="width-full" *ngIf="mostRecentTerminalDetails && !isRecentTerminalInLinkedTerminals">
            <legend class="terminal-form__title">Most recent terminal</legend>
            <div class="row mx-0">
              <div class="col-lg-2 col-md-3 col-sm-6 col-12 terminal-list">
                <div class="terminal-list__terminal terminal-list__terminal--most-recent {{styleClasses}} mx-0"
                  (click)="saveTerminalEvents(mostRecentTerminalDetails)">
                  <div class="row mx-0">
                    <div class="col-md-2 col-sm-1 col-2 terminal-list__terminal__icon">
                      <span *ngIf="mostRecentTerminalDetails.Image && !mostRecentTerminalDetails.Icon">
                        <img class="terminal-list__terminal__icon__img"
                          src="images/terminal/{{mostRecentTerminalDetails.Image}}" />
                      </span>
                      <span class="terminal-list__terminal__icon--i"
                        *ngIf="mostRecentTerminalDetails.Icon && !mostRecentTerminalDetails.Image">
                        <pos-icon [icons]="mostRecentTerminalDetails.Icon"></pos-icon>
                      </span>
  
                    </div>
                    <div class="col-md-10 col-sm-11 col-10 terminal-list__terminal__name" [ngClass]="{'mt-pix-6':mostRecentTerminalDetails.Icon}">
                      {{mostRecentTerminalDetails.TerminalName}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset class="width-full" *ngIf="mappedTerminalsWithIp?.length">
            <legend class="terminal-form__title">Linked Terminals</legend>
            <div class="row mx-0">
              <div class="col-lg-2 col-md-3 col-sm-6 col-12 terminal-list" *ngFor="let mappedTerminalWithIp of mappedTerminalsWithIp| orderBy: 'TerminalName'">
                <div class="terminal-list__terminal terminal-list__terminal--most-recent {{styleClasses}}"
                  (click)="saveTerminalEvents(mappedTerminalWithIp)">
                  <div class="row mx-0">
                    <div class="col-md-2 col-sm-1 col-2 terminal-list__terminal__icon">
                      <span *ngIf="mappedTerminalWithIp.Image && !mappedTerminalWithIp.Icon">
                        <img class="terminal-list__terminal__icon__img"
                          src="images/terminal/{{mappedTerminalWithIp.Image}}" />
                      </span>
                      <span class="terminal-list__terminal__icon--i"
                        *ngIf="mappedTerminalWithIp.Icon && !mappedTerminalWithIp.Image">
                        <pos-icon [icons]="mappedTerminalWithIp.Icon"></pos-icon>
                      </span>
  
                    </div>
                    <div class="col-md-10 col-sm-11 col-10 terminal-list__terminal__name" [ngClass]="{'mt-pix-6':mappedTerminalWithIp.Icon}">
                      {{mappedTerminalWithIp.TerminalName}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <div *ngFor="let item of terminalSelectionList | keyvalue: originalOrder">
            <fieldset class="width-full">
              <legend class="terminal-form__title">{{item.key}}</legend>
              <div class="row mx-0">
                <div class="col-lg-2 col-md-3 col-sm-6 col-12 terminal-list"
                  [hidden]="mostRecentTerminalDetails && (mostRecentTerminalDetails.TerminalId == terminal.TerminalId)"
                  *ngFor="let terminal of item.value| orderBy: 'TerminalName'">
                  <div class="terminal-list__terminal {{styleClasses}}" (click)="saveTerminalEvents(terminal)">
                    <div class="row mx-0">
                      <div class="col-md-2 col-sm-1 col-2 terminal-list__terminal__icon">
                        <span *ngIf="terminal.Image && !terminal.Icon">
                          <img class="terminal-list__terminal__icon__img" src="images/terminal/{{terminal.Image}}" />
                        </span>
                        <span class="terminal-list__terminal__icon--i" *ngIf="terminal.Icon && !terminal.Image">
                          <span class="terminal-list__terminal__icon--i__round">
                            <pos-icon [icons]="terminal.Icon"></pos-icon>
                          </span>
                        </span>
                      </div>
                      <div class="col-md-10 col-sm-11 col-10 terminal-list__terminal__name" [ngClass]="{'mt-pix-6':terminal.Icon}">
                        {{terminal.TerminalName}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>

    <ng-template #loaderModal>
      <div>
        <div class="modal-body">
          <div class="col-md-12 col-sm-12 text-center">
            <div class="login-loader">
              <pos-terminal-login-icon></pos-terminal-login-icon>
              <div class="terminal-progress__message mb-pix-10 mt-pix-10" id="message">{{terminalProcessingMessage}}</div>
            </div>
          </div>
        </div>
        <div class="modal-footer p-0" style="border: unset">
          <div class="col-md-12 text-center">
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #terminalUsedModal>
      <div>
        <div>
          <h4 class="modal-content__heading">&nbsp;</h4>
        </div>
        <div class="modal-body pbi-0">
          <div class="">
            <div class="col-md-12 col-sm-12">
              <div class="col-12 modal-content__process p-pix-1">
                This terminal is in use by another active user. Continue?
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer p-0 pb-pix-10" style="border: unset">
          <div class="col-lg-12 col-12 action-button-container popup-footer mti-15">
            <button class="btn btn-secondary btn-lg btn-save black pos-button" data-dismiss="modal" (click)="logoutTheUsedTerminal()"
              type="button">
              <img src="images/icons/icon-circle-check.png" />
              Yes
            </button>
            <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" data-dismiss="modal" (click)="terminalModalClose();" type="button">
              <img src="images/icons/icon-circle-x.png" />
              No
            </button>
          </div>
        </div>
      </div>
    </ng-template>
  </form>
</div>
