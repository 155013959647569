<pos-edit-wrapper heading="Card-based Login" [headingIcon]="icons.cardBasedLoginsIdCard" (close)="cancelEditing()" [isPopup]="false">
    <form (ngSubmit)="saveCardBaseLogin(cardBasedLoginForm.valid)" #cardBasedLoginForm="ngForm"
        class="edit-form">
        <div class="col-xs-12 border-top">
            <div class="col-xs-12 pos-page-body">
                <div>
                    <h3 class="setting-title mt-pix-5">Settings</h3>
                </div>
                <pos-row class="pos-title-content">
                    <pos-column>
                        <pos-form-field label="RFID Initiator Sequence" [fieldInfo]="fieldInfoMessages.CardbasedLoginsRFIDInitiatorSequence"
                            [validationMessages]="{required: 'Please enter RFID Initiator Sequence.'}">
                            <input posInput class="form-control col-150" name="txtRFIDInitiatorSequence"
                                #sequence="ngModel" [(ngModel)]="parameters.RFIDInitiatorSequence" [required]="true" type="text" />
                        </pos-form-field>
                    </pos-column>
                    <pos-column>
                        <pos-form-field label="RFID Ending Sequence" [fieldInfo]="fieldInfoMessages.CardbasedLoginsRFIDEndingSequence"
                            [validationMessages]="{required: 'Please enter RFID Ending Sequence.'}">
                            <input posInput class="form-control col-150" name="txtRFIDEndingSequence" #endingSequence="ngModel"
                                [(ngModel)]="parameters.RFIDEndingSequence" [required]="true" type="text" />
                        </pos-form-field>
                    </pos-column>
                    <pos-column>
                        <pos-form-field label="RFID Error Code" [fieldInfo]="fieldInfoMessages.CardbasedLoginsRFIDErrorCode"
                            [validationMessages]="{required: 'Please enter RFID Error Code.'}">
                            <input posInput class="form-control col-150" name="txtRFIDErrorCode" [required]="true"
                                #errorCode="ngModel" [(ngModel)]="parameters.RFIDErrorCode"
                                required type="text" />
                        </pos-form-field>
                    </pos-column>
                </pos-row>
            </div>
        </div>
        <div class="col-xs-12 action-button-container page-footer">
            <button class="btn btn-primary btn-lg btn-save pos-button" scrollup type="submit"
                value="Save">Save</button>
            <button class="btn btn-secondary btn-lg btn-cancel pos-button" routerLink="../" scrollup
                type="button" value="Cancel">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
