import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { first } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/internal/operators/finalize';
import { AlertsService, FormUtilityService, MultiProductGroupService, Promotion, PromotionService } from 'src/app/shared';
import { ModalFormComponent, ModalService, SpinnerService } from 'src/app/shared/components';
import { DomainConstants } from 'src/app/shared/constants';
import { ButtonBehavior } from 'src/app/shared/interface/button-behavior';
@Component({
  selector: 'pos-multiple-product',
  templateUrl: './multiple-product.component.html'
})
export class MultipleProductComponent extends ModalFormComponent implements OnInit {

  @ViewChild('multiProductForm') multiProductForm: NgForm;
  @Input() selectedButtonBehaviorType;
  @Input() buttonBehavior = null;
  @Input() buttonType: string;
  promotions: Array<Promotion> = [];
  @Input() selectedFunction: string;
  groupId = null;
  promotionId?: number = null;
  multiProductGroups = [];
  get getForm(): NgForm {
    return this.multiProductForm;
  }

  constructor(
    private promotionService: PromotionService,
    private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private multiProductGroupService: MultiProductGroupService,
    modalRef: BsModalRef, modalService: ModalService, protected formUtilityService: FormUtilityService) {
    super(modalRef, modalService, formUtilityService);
  }

  ngOnInit(): void {
    if (this.buttonBehavior?.Id) {
      try {
        if (this.buttonBehavior.BehaviorValue) {
          const behavior = first(this.buttonBehavior.BehaviorValue) as any;
          this.promotionId = behavior.PromotionId ?? null;
          this.groupId = behavior.GroupId ?? null;
        } else {
          const behavior = JSON.parse(this.buttonBehavior.TextValue);
          this.promotionId = behavior?.promotionId ? behavior.promotionId : null;
          this.groupId = behavior?.groupId ? behavior.groupId : null;
        }
        this.setInitialValue();
      } catch (ex) {
        console.error('Invalid behavior value: ' + this.buttonBehavior.TextValue);
      }
    }
    this.loadData();
    this.setInitialValue();
  }

  loadData() {
    this.spinnerService.show();
    const multiProductGroupObservables: Observable<any>[] = [];
    multiProductGroupObservables.push(this.promotionService.getAll());
    multiProductGroupObservables.push(this.multiProductGroupService.getAll());
    forkJoin(multiProductGroupObservables)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.promotions = res[0].filter(x => x.IsActive && x.Type == DomainConstants.PromotionTypes.Promotions.DisplayName);
          this.multiProductGroups = res[1];

        }, error: this.alertsService.showApiError
      });
  }

  saveBehavior = (isValid: boolean) => {
    if (isValid) {
      this.hide({ shouldReload: true, Value: { groupId: this.groupId, promotionId: this.promotionId } });
    }
  }

  closeModal = () => {
    this.hide({ shouldReload: false });
  }

}
