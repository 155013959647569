import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationStart, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PreventBackGuardService  {
  lastTrigger: 'imperative' | 'popstate' | 'hashchange';
  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.lastTrigger = event.navigationTrigger;
      }
    });
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.lastTrigger === 'popstate' && this.router.url === '/menu-explorer') {
      this.router.navigate([this.router.url]);
    }
    return true;
  }
}
