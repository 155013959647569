import { Component, OnInit, ViewChild } from '@angular/core';
import { Equipment, EquipmentLog } from '../../interface/equipment';
import { BaseCrudComponent, equipmentListGripVertical } from 'src/app/shared/components';
import { EquipmentType } from 'src/app/information-management/equipment-types/interface/equipment-type';
import { InventoryZone } from 'src/app/information-management/inventory-zone';
import { NgForm } from '@angular/forms';
import { EquipmentService } from '../../service/equipment.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { EquipmentTypeService } from 'src/app/information-management/equipment-types/service/equipment-type.service';
import { FormUtilityService, Messages } from 'src/app/shared';
import { finalize } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { EquipmentPropertyService, EquipmentProperty } from 'src/app/information-management/equipment-properties';
import { EditorType } from 'src/app/shared/interface/editor-type';
import { InventoryZoneService } from 'src/app/shared/services/inventory-zone.service';
import { ActivatedRoute, Router } from '@angular/router';
declare let $: any;

@Component({
    selector: 'pos-equipment-edit',
    templateUrl: './equipment-edit.component.html',
    styleUrls: ['./equipment-edit.component.scss']
})
export class EquipmentEditComponent extends BaseCrudComponent<Equipment> implements OnInit {
    get getForm(): NgForm {
        return this.equipmentForm
    }
    equipment: Equipment;
    equipmentTypes: Array<EquipmentType>;
    inventoryZones: Array<InventoryZone>;
    equipmentProperties: Array<EquipmentProperty>;
    selectedEquipmentProperties: Array<EquipmentProperty> = [];
    equipmentObservables = [];
    icons = {
        equipmentListGripVertical
    }
    id = 0;
    @ViewChild('equipmentForm') equipmentForm: NgForm;
    height = 300;
    props = {
        controlBoxClass: 'col-lg-4 col-md-6 col-sm-10 col-xs-12'
    }
    constructor(
        protected alertService: AlertsService,
        protected spinnerService: SpinnerService,
        protected equipmentService: EquipmentService,
        private equipmentTypeService: EquipmentTypeService,
        private inventoryZoneService: InventoryZoneService,
        private equipmentPropertyService: EquipmentPropertyService,
        private route: ActivatedRoute,
        private router: Router,
        formUtilityService: FormUtilityService
    ) {
        super(equipmentService, alertService, spinnerService, formUtilityService);
        this.id = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
    }

    ngOnInit() {
        this.equipment = this.equipmentService.newEquipment();
        this.saveSuccessMessage = Messages.EquipmentSaveSuccess;
        this.dataSubscription();
        this.setHeight();
        $(window).resize(() => {
            this.setHeight();
        });
    }

    setHeight() {
        this.height = ($(window).height() - 600) < 150 ? 100 : ($(window).height() - 600);
    }

    submit(isValid: boolean): void {
        if (!isValid || !this.equipment.EquipmentTypeId || !this.validateProperty()) {
            return;
        }
        this.equipment.Logs = [];
        this.selectedEquipmentProperties.forEach((equipmentProperty: EquipmentProperty) => {
            this.equipment.Logs.push(this.newEquipmentLog(equipmentProperty.Id, equipmentProperty.Value));
        });
        this.setDeviceAddress();
        this.save(this.equipment);
    }

    private setDeviceAddress() {
        const selectedEquipmentType = this.equipmentTypes.find(x => x.Id == this.equipment.EquipmentTypeId);
        const nodeIdProperty = this.equipmentProperties.find(x => x.Name == "Node Id");
        const nodeEquipmentTypeProperty = selectedEquipmentType.EquipmentTypeProperties.find(x => x.EquipmentPropertyId == nodeIdProperty?.Id);
        const nodeLogEntry = this.equipment.Logs.find(x => x.PropertyId == nodeEquipmentTypeProperty?.Id);
        this.equipment.DeviceAddress = nodeLogEntry?.Value;
    }

    private validateProperty() {
        if (this.selectedEquipmentProperties) {
            const blankProperties = this.selectedEquipmentProperties.filter(ep => !ep.Value);
            if (blankProperties && blankProperties.length > 0) {
                return false;
            }
        }
        return true;
    }

    private newEquipmentLog(id: number, value: string): EquipmentLog {
        return {
            PropertyId: id,
            Value: value,
        };
    }

    private dataSubscription(): void {
        if (this.id > 0) {
            this.equipmentObservables.push(this.loadData());
        }
    }

    public loadDependencies(): void {
        this.equipmentObservables.push(this.equipmentTypeService.getEquipmentTypes());
        this.equipmentObservables.push(this.inventoryZoneService.getInventoryZones());
        this.equipmentObservables.push(this.equipmentPropertyService.getEquipmentProperties());

        this.spinnerService.show();
        forkJoin(this.equipmentObservables)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (responses: any) => {
                    if (this.id > 0) {
                        this.equipment = responses && responses[0] ? responses[0] : [];
                        this.equipmentTypes = responses && responses[1] ? responses[1] : [];
                        this.inventoryZones = responses && responses[2] ? responses[2] : [];
                        this.equipmentProperties = responses && responses[3] ? responses[3] : [];
                        this.setEquipmentProperties(this.equipment);
                    } else {
                        this.equipmentTypes = responses && responses[0] ? responses[0] : [];
                        this.inventoryZones = responses && responses[1] ? responses[1] : [];
                        this.equipmentProperties = responses && responses[2] ? responses[2] : [];
                    }
                }, error: this.alertService.showApiError
            });
    }

    public onSaveSuccess(model: Equipment): void {
        this.cancelEditing();
    }

    cancelEditing(): void {
        this.router.navigate(['equipments'], { relativeTo: this.route.parent });
    }

    public setEquipmentProperties(equipment: Equipment) {
        this.selectedEquipmentProperties = [];
        if (equipment.EquipmentTypeId) {
            this.equipmentTypes.find(x => x.Id == equipment.EquipmentTypeId)?.EquipmentTypeProperties.forEach((data) => {
                const value = equipment.Logs.find(x => x.PropertyId == data.Id)?.Value;
                const equipmentProperty = this.equipmentPropertyService.newEquipmentProperty(data.Id, data.EquipmentProperty.Name, value, data.Description);
                this.selectedEquipmentProperties.push(equipmentProperty);
            });
        }
    }
    get getEditorType() {
        return EditorType;
    }
}
