import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { find } from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize, forkJoin, of } from 'rxjs';
import { DomainConstants, HttpStatusCodes } from 'src/app/shared';
import { BaseFormComponent, ModalBackdropService, SpinnerService } from 'src/app/shared/components';
import { barcode, inventoryLogClock, inventoryLogInfoCircle, inventoryLogWarning } from 'src/app/shared/components/icon';
import { InventoryBinLightModel, InventoryLogStockSalesProduct, SettingParam } from 'src/app/shared/interface';
import { InvProduct } from 'src/app/shared/interface/inventory-product';
import { InventoryBinLightService, AlertsService, InventoryProductService, FormUtilityService, InventoryLogService, MathsUtilityService, ColorUtilityService, ApplicationStateService } from 'src/app/shared/services';
import { InventoryScannerBatchModel } from '../../interfaces/inventory-scanner-batch-model';
declare let $: any;

@Component({
  selector: 'pos-inventory-barcode-log',
  templateUrl: './inventory-barcode-log.component.html',
  styleUrls: ['./inventory-barcode-log.component.scss']
})
export class InventoryBarcodeLogComponent extends BaseFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.inventoryBarcodeLogForm;
  }

  settingParam: SettingParam;
  barCode: string;
  inventoryProductId: number;
  inventoryZoneId: number;
  icons = {
    barcode,
    inventoryLogWarning,
    inventoryLogClock,
    inventoryLogInfoCircle
  }
  @ViewChild('inventoryBarcodeLogForm') inventoryBarcodeLogForm: NgForm;
  inventoryProductDetails: InvProduct;
  inventoryPrimaryBinLights: Array<InventoryBinLightModel> = [];
  inventorySecondaryBinLights: Array<InventoryBinLightModel> = [];
  inventoryProductForLog: any;
  isLoading = true;
  salesProductStockWarningModelRef: BsModalRef;
  @ViewChild('salesProductStockWarning') private salesProductStockWarning: any;
  salesInventoryProducts: Array<InventoryLogStockSalesProduct> = [];
  inventoryScannerBatch: Array<InventoryScannerBatchModel> = [];
  currentInventoryScanner: InventoryScannerBatchModel;
  sessionKey = DomainConstants.SessionStorageKeys.InventoryScannerBatch;

  constructor(
    protected route: ActivatedRoute,
    private router: Router,
    public spinnerService: SpinnerService,
    public alertService: AlertsService,
    public inventoryProductService: InventoryProductService,
    private inventoryBinLightService: InventoryBinLightService,
    formUtilityService: FormUtilityService,
    private inventoryLogService: InventoryLogService,
    private mathsUtilityService: MathsUtilityService,
    private modalBackdropService: ModalBackdropService,
    private bsModalService: BsModalService,
    private colorUtilityService: ColorUtilityService,
    private applicationStateService: ApplicationStateService,
  ) {
    super(formUtilityService);
    this.barCode = route.snapshot.params.id ? route.snapshot.params.id : 0;
    this.validateBarcode();
  }

  validateBarcode() {
    if (!this.barCode || this.barCode.length != 12 || isNaN(parseInt(this.barCode, 10))) {
      this.showBarcodeDoesNotExists(true);
      return;
    }
    this.inventoryProductId = parseInt(this.barCode.slice(0, 5), 10);
    this.inventoryZoneId = parseInt(this.barCode.slice(5, 8), 10);
  }

  ngOnInit(): void {
    this.setDefaults();
    if(this.inventoryProductId) {
      this.getInventoryDetails();
    }
  }

  setDefaults() {
    this.settingParam = this.applicationStateService.settingParam;
    const batchData = sessionStorage.getItem(this.sessionKey);
    if (batchData) {
      this.inventoryScannerBatch = JSON.parse(batchData) as Array<InventoryScannerBatchModel>
    }
    const findCurrentData = find(this.inventoryScannerBatch, x => x.InventoryId == this.inventoryProductId);
    this.currentInventoryScanner = findCurrentData ? findCurrentData : this.inventoryLogService.getNewInventoryScannerBatchModel();
  }

  getInventoryDetails() {
    this.isLoading = true;
    this.spinnerService.show();
    this.inventoryProductService.getInventoryProduct(this.inventoryProductId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: InvProduct) => {
          this.inventoryProductDetails = res;
          if (!this.inventoryProductDetails.id || !this.inventoryProductDetails.active) {
            this.showBarcodeDoesNotExists();
            return;
          }
          this.getProductData();
        }, error: (err) => {
          if (err.status == HttpStatusCodes.BadRequest) {
            this.showBarcodeDoesNotExists();
          } else {
            this.alertService.showApiError(err);
          }
        }
      });
  }

  getProductData() {
    const productVendor = {
      Id: this.inventoryProductDetails.vendor_id,
      Type: 'Vendor'
    };
    this.spinnerService.show();
    forkJoin({
      primaryBin: this.inventoryProductDetails.PrimaryBinId ? this.inventoryBinLightService.getinventoryBinLights(this.inventoryProductDetails.PrimaryBinId) : of(null),
      secondaryBin: this.inventoryProductDetails.SecondaryBinId ? this.inventoryBinLightService.getinventoryBinLights(this.inventoryProductDetails.SecondaryBinId) : of(null),
      InvForLog: this.inventoryLogService.getInventoryLogging(productVendor)
    })
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe({
        next: ({ primaryBin, secondaryBin, InvForLog }) => {
          this.inventoryPrimaryBinLights = primaryBin ? primaryBin : [];
          this.inventorySecondaryBinLights = secondaryBin ? secondaryBin : [];
          this.findInventoryLog(InvForLog);
          this.isLoading = false;
        },
        error: this.alertService.showApiError
      })
  }

  findInventoryLog(res) {
    if (res) {
      this.inventoryProductForLog = find(res, (item) => item.Id == this.inventoryProductDetails.id);
      if (this.inventoryProductForLog) {
        this.inventoryProductForLog.Memo = this.currentInventoryScanner.Memo;
        this.inventoryProductForLog.oldQty = this.inventoryProductForLog.Qty;
        if (this.currentInventoryScanner.TotalQty) {
          this.inventoryProductForLog.Qty = this.currentInventoryScanner.TotalQty;
        }
      }
    }
  }

  getContrastColor(color) {
    return this.colorUtilityService.getContrastColor(color);
  }

  showToolTip = (controlId: string, hasError: boolean) => {
    if (hasError) {
      $(controlId).parent().addClass('has-error');
      $(controlId).focus();
    }
  }

  save() {
    if (this.inventoryProductForLog &&
      (this.inventoryProductForLog.UnitQtyIncrement &&
        (this.mathsUtilityService.floatSafeModulus(parseFloat(this.inventoryProductForLog.Qty),
          parseFloat(this.inventoryProductForLog.UnitQtyIncrement)) !== 0) || this.inventoryProductForLog.Qty < 0)) {
      const txtQtyId = '#txtQty' + this.inventoryProductForLog?.Id;
      this.showToolTip(txtQtyId, true);
    } else if (this.inventoryProductForLog) {
      this.getInventoryLogStockSalesProducts();
    }
  }

  getInventoryLogStockSalesProducts = () => {
    const products = [{
      InventoryProductId: this.inventoryProductForLog.Id,
      Qty: this.inventoryProductForLog.Qty
    }];
    this.spinnerService.show();
    this.inventoryProductService.getInventoryLogStockSalesProducts(products)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe({
        next: (res: Array<InventoryLogStockSalesProduct>) => {
          this.salesInventoryProducts = res ?? [];
          if (res?.length && res.length >= this.settingParam.InventoryLogStockWarningMinimum) {
            this.openWarningModel();
          } else {
            this.saveInventoryQty();
          }
        }, error: this.alertService.showApiError
      });
  }

  openWarningModel = () => {
    this.salesProductStockWarningModelRef = this.bsModalService.show(this.salesProductStockWarning, {
      'backdrop': 'static',
      'class': 'vertical-center'
    });
    this.modalBackdropService.addBackDrop();
  }

  closeWarningModel = () => {
    if (this.salesProductStockWarningModelRef) {
      this.salesProductStockWarningModelRef.hide();
      this.modalBackdropService.removeBackdrop();
    }
  }

  saveInventoryQty(redirect = true) {
    this.closeWarningModel();
    if (!this.currentInventoryScanner.InventoryId) {
      this.inventoryScannerBatch.push(this.currentInventoryScanner);
    }
    this.currentInventoryScanner.InventoryId = this.inventoryProductId;
    this.currentInventoryScanner.PrimaryZoneName = this.inventoryProductDetails.PrimaryZoneName;
    this.currentInventoryScanner.SecondaryZoneName = this.inventoryProductDetails.SecondaryZoneName;
    this.currentInventoryScanner.InventoryName = this.inventoryProductDetails.name;
    this.currentInventoryScanner.Unit = this.inventoryProductForLog.Unit;
    this.currentInventoryScanner.Memo = this.inventoryProductForLog.Memo;
    this.inventoryProductForLog.Qty = this.currentInventoryScanner.TotalQty;
    this.onChangeQty(this.inventoryProductForLog);
    this.currentInventoryScanner.InventoryFinalLog = this.prepareInventoryLogModel();
    sessionStorage.setItem(this.sessionKey, JSON.stringify(this.inventoryScannerBatch));
    if (redirect) {
      this.alertService.renderSuccessMessage(`${this.inventoryProductDetails.name} saved in batch.`);
      this.cancel();
    }
  }

  redirectToBatchList() {
    this.saveInventoryQty(false);
    this.router.navigate(['inventory-log-batch'], { relativeTo: this.route.parent })
  }

  prepareInventoryLogModel() {
    return {
      CategoryName: this.inventoryProductForLog.Category + ' - ' + this.inventoryProductForLog.SubCategory,
      CategoryList: [this.inventoryProductForLog],
      IsChecked: true
    };
  }

  cancel(isSetTimeout = false) {
    if(isSetTimeout) {
      const time = setTimeout(() => {
        this.router.navigate(['inventory-barcode'], { relativeTo: this.route.parent });
        clearTimeout(time);
      });
    } else {
      this.router.navigate(['inventory-barcode'], { relativeTo: this.route.parent });
    }
  }

  calculateQty() {
    this.inventoryProductForLog.Qty = this.currentInventoryScanner.TotalQty = this.convertToInt(this.currentInventoryScanner.PrimaryQty)
      + this.convertToInt(this.currentInventoryScanner.SecondaryQty)
      + this.convertToInt(this.currentInventoryScanner.OtherQty);
    this.onChangeQty(this.inventoryProductForLog)
  }

  convertToInt(n): number {
    return parseInt(n ? n : 0)
  }

  onChangeQty(itemDetails) {
    const hasUnitQtyIncrementError = this.inventoryLogService.qtyChange(itemDetails, this.inventoryProductForLog);
    const txtQtyId = '#txtQty' + itemDetails?.id;
    this.showToolTip(txtQtyId, hasUnitQtyIncrementError);
  }

  showBarcodeDoesNotExists(isSetTimeout = false) {
    this.alertService.renderErrorMessage(`Invalid Code: Couldn't identify a product with code ${this.barCode}.`);
    this.cancel(isSetTimeout);
  }
}
