import { NgPipesModule } from 'ngx-pipes';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAKE_TABLE_STATES } from './make-table.route';
import { MakeTableComponent } from './components/make-table.component';
import { ComponentsModule,  FormsModule} from '../shared';
import { OrderCardSharedModule } from '../order-shared/order-card-shared.module';
import { HeaderEntryModule } from '../header-entry/header-entry.module';
import { RouterModule } from '@angular/router';
import { SelectUserComponent } from './components/select-user';
import { FormsModule as AngularFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(MAKE_TABLE_STATES),
        NgPipesModule,
        HeaderEntryModule,
        ComponentsModule,
        OrderCardSharedModule,
        NgSelectModule,
        FormsModule,
        AngularFormsModule
    ],
    declarations: [MakeTableComponent, SelectUserComponent],
    providers: [],
    bootstrap: [MakeTableComponent]
})
export class MakeTableModule { }
