<pos-edit-wrapper heading="Set Product" (close)="onCancel()">
  <div class="pt-pix-10 overflow-hidden">
    <div class="col-12 pos-modal-body">
      <div class="col-12 pb-pix-10">
        <input class="form-control" name="searchProduct" placeholder="Search product" #searchProduct="ngModel"
          [(ngModel)]="searchFilter">
      </div>
      <div class="col-12 modal-content__list" [ngStyle]="{'max-height': 'calc(100vh - 280px)'}">
        <ul class="list-group">
          <div *ngFor="let product of productList | customFilter:searchFilter">
            <li class="list-group-item" (click)="setProduct(product)"
              [ngClass]="{ 'bg-theme-primary' : product.ID == selectedProduct.ProductId || productId == product.ID }">
              {{product.Name}}</li>
          </div>
        </ul>
      </div>
    </div>
    <div class="col-12 action-button-container popup-footer">
      <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" (click)="onSave()">Ok</button>
      <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" (click)="onCancel()">Cancel</button>
    </div>
  </div>
</pos-edit-wrapper>
