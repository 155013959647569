import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { BaseFormComponent, FormUtilityService } from 'src/app/shared';
import { star } from 'src/app/shared/components/icon/icons';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { FeatureConfiguration } from '../../interface/feature-configuration';
import { FeatureConfigurationService } from '../../services/feature-configuration.service';
import { PostSavedConfigurationService } from 'src/app/configurator/post-saved-configuration.service';

@Component({
  selector: 'pos-features',
  templateUrl: './features.component.html',
  styleUrls: []
})
export class FeaturesComponent extends BaseFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.featureConfiguration;
  }

  @ViewChild('featureConfiguration') featureConfiguration: NgForm;
  featuresConfigurations: Array<FeatureConfiguration>;
  icons = {
    star
  };

  constructor(private featureConfigurationService: FeatureConfigurationService,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private router: Router,
    private postSavedConfigurationService: PostSavedConfigurationService,
    protected formUtilityService: FormUtilityService) {
    super(formUtilityService);
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.spinnerService.show();
    this.featureConfigurationService.getAll().pipe(finalize(() => {
      this.spinnerService.hide()
    })).subscribe({
      next: (response) => {
        this.featuresConfigurations = response;
      }, error: this.alertService.showApiError
    });
  }

  saveFeatureConfiguration(isValid) {
    if (!isValid) {
      return;
    }
    this.spinnerService.show();
    this.featureConfigurationService.updateFeatures(this.featuresConfigurations).pipe(finalize(() => {
      this.spinnerService.hide()
    })).subscribe({
      next: () => {
        this.onSaveSuccess();
      }, error: this.alertService.showApiError
    });
  }

  onSaveSuccess() {
    this.alertService.renderSuccessMessage(Messages.FeatureConfigurationSaveSuccess);
    this.postSavedConfigurationService.showMessage();
  }

  cancel() {
    this.router.navigate(['/manage/system-configuration']);
  }

}
