<pos-list-wrapper [headingIcon]="icons.layerGroup" heading="Multiple Product Groups" (close)="close()">
    <div class="row">
        <div class="col-12">
            <ng-table id="screens" [sort]="true" [columns]="groupColumns" [data]="groups"
                [exportFilename]="'MultiProductGroups'">
            </ng-table>

            <ng-template let-data="rowData" #operationHeaderTemplate>
                <div class="text-center">
                    <span (click)="editItem(0)" class="no-wrap" tooltip title="Add">
                        <pos-action-icon [icon]="icons.plusWhite" iconText="Add"></pos-action-icon>
                    </span>
                    <pos-export-dropdown class="inline-block ms-pix-5" (onPrint)="printData()" (onCSV)="exportCSV()">
                    </pos-export-dropdown>
                </div>
            </ng-template>

            <ng-template let-data="rowData" #operationTemplate>
                <div class="text-center">
                    <span class="pe-pix-3" (click)="editItem(data.Id, data)" tooltip title="Edit">
                        <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
                    </span>
                    <span class="pe-pix-3" (click)="confirmDelete(data)" tooltip title="Delete">
                        <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
                    </span>

                </div>
            </ng-template>
        </div>
    </div>
</pos-list-wrapper>
<pos-print-table [hidden]="true"></pos-print-table>
