<span class="col-1 col-w-10" (click)="onClickIcon($event)" [ngClass]="styleClass">
  <div class="ps-2 mt-pix-n1">
    <!-- <pos-icon [icon]="icons.fieldInfoCircle" 
        pTooltip="{{info}}" 
        tooltipEvent="hover"
        tooltipPosition="{{placement}}" 
        (mouseenter)="handleMouseOver($event)" 
        (mouseleave)="handleMouseOut($event)"
        appendTo="body"
        tooltipZIndex="10000" >
    </pos-icon> -->
    <a href="" class="black" #popoverTooltip id="popoverTooltip" (click)="onClickFieldInfoIcon($event)"
      [(pTooltip)]="image ? imageTemplate : info"
      tooltipEvent="focus"
      tooltipPosition="{{placement}}"
      (mouseenter)="handleMouseOver($event)"
      (mouseleave)="handleMouseOut($event)"
      (blur)="handleMouseOut($event)"
      [escape]="false"
      appendTo="body"
      tooltipZIndex="10000"
      [tooltipStyleClass]="image ? 'image-popover' : ''">
      <pos-icon [icon]="icons.fieldInfoCircle"></pos-icon></a>
  </div>
</span>



