import { DatePipe } from '@angular/common';
import { Inject, Pipe, PipeTransform } from '@angular/core';
import { TarkLocalProvider, LOCAL_PROVIDER } from '../service/tark-local-provider.service';

@Pipe({
  name: 'tark_time'
})
export class TarkTimePipe implements PipeTransform {

  constructor(@Inject(LOCAL_PROVIDER) private tarkLocalProvider: TarkLocalProvider,
    private datePipe: DatePipe) {  }

  transform(value: number, showSeconds = false): string | null {
    const timeFormat = showSeconds ? 'mediumTime' : this.tarkLocalProvider.getTimeFormat();
    return this.datePipe.transform(value, timeFormat, null, this.tarkLocalProvider.getLocale());
  }

}
