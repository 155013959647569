<div class="h-80">
    <pos-header-entry-main [links]="headerLinks" [isShowBarIcon]="false"></pos-header-entry-main>
</div>
<div id="table">
    <div id="container">
        <div [hidden]="isShowProductList" id="SlotList" class="row mx-0">
            <div *ngFor="let timerControl of timerOptions" class="col-md-6 col-12 no-pad"
                [ngClass]="{'col-sm-6 two-rows': timerOptions.length > 2, 'col-sm-12 single-row': timerOptions.length <= 2}">
                <pos-timer-control #timerControl [option]="timerControl"
                    [(timerProducts)]="queuedProducts"></pos-timer-control>
            </div>
        </div>
        <ul [hidden]="!isShowProductList" class="products-ul mt-pix-10 row" id="ProductList">
            <li class="float-right mt-pix-10 mb-pix-10" [hidden]="!isShowRefreshButton">
                <button class="btn btn-secondary pos-button" (click)="getWorkFlowTimerProducts(true)">
                    <pos-icon class="black font-x-normal" [icon]="icons.sync"></pos-icon>
                    Refresh
                </button>
            </li>
            <li class="btns-li" *ngFor="let timerDetails of queuedProducts| orderBy:'-PrepGroupName'">
                <!-- index as i; trackBy: i -->
                <div class="row mx-0">
                    <fieldset>
                        <legend class="mb-pix-15 pb-pix-3 border-bottom">{{timerDetails[0]?.PrepGroupName}}</legend>
                        <div class="row mx-0">
                            <div class="col-xl-3 col-lg-4 col-12 col-sm-6 ps-0" style="display:table"
                                *ngFor="let timerData of timerDetails">
                                <!-- ng-repeat="timerdata in timerDetails | orderBy:['OrderId','name']"> -->
                                <button class="text-nowrap col-sm-9 col-9" (click)="requestProduct(timerData);"
                                    data-next="Check"
                                    [ngStyle]="{'background-color': timerData.PrepButtonColor, 'color': timerData.Color}">
                                    <span [hidden]="timerData.Qty == 1" style="background-color:white"
                                        class="badge black qty-circle make-table-page__content__block__qty">
                                        {{timerData.Qty}}
                                    </span>
                                    {{timerData.MakeTableText}}
                                    <pos-icon style="color: white;" *ngIf="timerData.TimerState === timerStates.COOKING"
                                        class="black font-x-normal" [icon]="icons.clock"></pos-icon>
                                </button>
                                <button class="skip col-sm-3 col-3" (click)="skipProduct(timerData)" type="button"
                                    *ngIf="terminalProperties.TimerOptions != terminalOption.PRECONFIGURED_TIMERS">
                                    <pos-icon class="black" [icon]="icons.stepForward"></pos-icon>
                                    Skip
                                </button>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </li>
            <li class="fixed-product-bottom px-0">
                <div class="col-12 action-button-container pt-pix-10">
                    <button class="btn-secondary btn-lg btn btn-cancel pos-button"
                        (click)="cancelClick()">Cancel</button>
                </div>
            </li>
        </ul>
    </div>
</div>
