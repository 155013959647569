<pos-edit-wrapper heading="Confirm Receiving" (close)="onCancel()">



    <div class="modal-body pos-modal-body pti-0">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12 edit-form__section__label alert alert-warning py-pix-6 mb-0">
            You are receiving products at <b>{{location}}</b> location
        </div>
        <div class="col-12 p-0 pt-pix-5">
            <ng-table #confirmReceiving [scrollable]="true" [search]="false" [columns]="confirmReceivingColumns"
                [paginator]="false" [scrollHeight]="'200px'" [data]="productList">
            </ng-table>
        </div>
    </div>
    <div class="col-12 action-button-container popup-footer">
        <button class="btn btn-primary btn-lg btn-save pos-button" (click)="onConfirm()" type="button"
            [disabled]="isConfirmDisable">Confirm</button>
        <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="onCancel()"
            type="button">Cancel</button>
    </div>
</pos-edit-wrapper>

<ng-template #qtyReceivedTemplate let-data="rowData">
    <div class="col-12 pointer p-0">
        {{ (((data.Qty % 1) > 0) ? data.Qty.toFixed(2) : data.Qty.toString())}}</div>
</ng-template>
