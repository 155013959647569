import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { BaseCrudService } from 'src/app/shared/services';
import { ExportConnection } from '../interface';

@Injectable({
  providedIn: 'root'
})
export class ExportConnectionService extends BaseCrudService<ExportConnection> {

  constructor(private httpClient: HttpClient) {
    super('export-connection', httpClient);
  }

  newExportConnection(): ExportConnection {
    return {
      Id: 0,
      Name: '',
      Configuration: '',
      ExportFormat: null,
      ConnectionType: null,
    };
  }

  getExportTypes(): Observable<Array<string>> {
    return this.httpClient.get<Array<string>>(this.apiEndpoint + '/data-sender-types');
  }
}
