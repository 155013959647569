import { IKioskNavigation } from "../interfaces/ikiosk-navigation";
import { ChoiceBehavior } from "./choices-behavior";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class SingularFeeBehavior extends ChoiceBehavior {

    constructor() { 
        super();
    }

    execute(navObj: IKioskNavigation, orderNavigation: any): Promise<any> {
        return Promise.resolve();
    }
}

