import { InfoModalComponent, ModalService } from "src/app/shared/components";
import { IKioskNavigation } from "../interfaces/ikiosk-navigation";
import { ChoiceBehavior } from "./choices-behavior";
import { Injectable } from "@angular/core";
import { BehaviorPromiseProviderService } from "../services";

@Injectable({
    providedIn: 'root'
})
export class DisplayMessageBehavior extends ChoiceBehavior {

    constructor(private modalService: ModalService,
        private behaviorPromiseProviderService: BehaviorPromiseProviderService
    ) {
        super()
    }

    execute(navObj: IKioskNavigation, orderNavigation: any): Promise<any> {
        const message = this.behaviorValues.message;
        return this.displayMessage(message);
    }

    private displayMessage(message: string): Promise<any> {
        const modalRef = this.modalService.show(InfoModalComponent, {
            animated: false,
            class: 'vertical-center',
            initialState: {
                message: message
            }
        });
        modalRef.close.subscribe(res => this.behaviorPromiseProviderService.resolvePromise(res),
            (e) => this.behaviorPromiseProviderService.rejectPromise(e));
        return this.behaviorPromiseProviderService.executionPromise;
    }
}

