import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { InventoryProductService } from '../../../../shared/services/inventory-product.service';
import { finalize } from 'rxjs/operators';
import { InventoryProductDetails } from 'src/app/shared/interface/inventory-product-deail';
import { DatePipe } from '@angular/common';
import { ApplicationStateService, FormUtilityService, Messages, ModalFormComponent, ModalService } from 'src/app/shared';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'pos-merge-product',
    templateUrl: './merge-product.component.html',
    styleUrls: ['./merge-product.component.scss']
})
export class MergeProductComponent extends ModalFormComponent implements OnInit {
    get getForm(): NgForm {
        return this.mergeProductForm;
    }

    @Input('inventoryProductId') inventoryProductId: number;
    @Input('productName') inventoryProductName: string;
    @Input('dateAdded') dateAdded: Date;
    @ViewChild('mergeProductForm') mergeProductForm: NgForm;

    inventoryProducts: Array<InventoryProductDetails>;
    selectedProduct: InventoryProductDetails = null;

    constructor(
        modalRef: BsModalRef,
        private alertService: AlertsService,
        private spinnerService: SpinnerService,
        private inventoryProductService: InventoryProductService,
        private applicationStateService: ApplicationStateService,
        private datePipe: DatePipe,
        modalService: ModalService,
        formUtilityService: FormUtilityService
    ) {
        super(modalRef, modalService, formUtilityService);
    }

    ngOnInit() {
        this.loadData();
    }

    public loadData() {
        this.spinnerService.show();
        this.inventoryProductService.getInventoryProductsForMerge()
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (response) => {
                    this.inventoryProducts = response ? response : [];
                    this.inventoryProducts = this.inventoryProducts.filter(p => p.Id !== this.inventoryProductId);
                    this.inventoryProducts.forEach((inventoryProduct: InventoryProductDetails) => {
                        inventoryProduct.Name += ' (' + this.datePipe.transform(inventoryProduct.DateAdded, this.applicationStateService.settingParam.DateFormat) + ')';
                    });
                }, error: this.alertService.showApiError
            });
    }

    submit(isValid: boolean) {
        if (!isValid) {
            return;
        }
        this.spinnerService.show();
        this.inventoryProductService.mergeProduct(this.inventoryProductId, this.selectedProduct.Id)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: () => {
                    this.alertService.renderSuccessMessage(StringUtils.format(Messages.MergeInventoryProduct,
                        { 'oldProductName': this.selectedProduct.Name, 'masterProductName': this.inventoryProductName }));
                    this.hide({ shouldReload: true });
                }, error: this.alertService.showApiError
            });
    }

    public onCancel() {
        this.hide({});
    }

}
