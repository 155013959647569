import { Observable } from 'rxjs';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UserTrainingNotesService {

  constructor(private httpService: HttpClient) { }
  getUserTrainingNotes(userTrainingLevelId): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}user-training-notes/` + userTrainingLevelId
    );
  }

  //   getAudioNotificationFile(audioNotifierId: number): Observable<any> {
  //     return this.httpService.get(
  //       `${RuntimeConstants.API_PATH}audionotifier/` + audioNotifierId
  //     );
  //   }
  addUserTrainingNote(userTrainingNote): Observable<any> {
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}user-training-notes/`,
      userTrainingNote
    );
  }

  updateUserTrainingNote(userTrainingNoteId, userTrainingNote): Observable<any> {
    return this.httpService.put(
      `${RuntimeConstants.API_PATH}user-training-notes/` + userTrainingNoteId,
      userTrainingNote
    );
  }

  //     // return this.httpService.put(
  //     //     `${RuntimeConstants.API_PATH}audionotifier`,
  //     //     audioNotificationData
  //     // );
  //   }
  deleteUserTrainingNote(userTrainingNoteId: number): Observable<any> {
      return this.httpService.delete(
        `${RuntimeConstants.API_PATH}user-training-notes/` + userTrainingNoteId
      );
    }

  //   getAudioVoiceId(): Observable<any> {
  //     return this.httpService.get(
  //       `${RuntimeConstants.API_PATH}audionotifier/voiceId`
  //     );
  //   }

}
