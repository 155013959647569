import { Component, OnInit, ViewChild } from '@angular/core';
import {
  BaseListComponent,
  AuthenticationService,
  AlertsService,
  SpinnerService,
  ModalService,
  Messages,
  Permissions,
  Levels,
  DomainConstants,
  PrintTableService,
  ApplicationStateService
} from 'src/app/shared';
import { LoginMessageModel } from '../../interface/login-message-model';
import { LoginMessagesService } from '../../services/login-messages.service';
import { GridColumn, TableComponent } from '@tarktech/tark-ng-utils';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { loginMessagesCommentDots } from 'src/app/shared/components/icon';


@Component({
  selector: 'pos-login-messages',
  templateUrl: './login-messages.component.html',
  styleUrls: ['./login-messages.component.scss']
})
export class LoginMessagesComponent extends BaseListComponent<LoginMessageModel> implements OnInit {

  permission = {
    name: Permissions.ManageLoginMessages,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };
  loginMessageColumns: Array<GridColumn> = [];
  loginMessageList: LoginMessageModel[] = [];
  @ViewChild('loginMessageGrid', { static: true }) loginMessageGrid: TableComponent;
  icons = { loginMessagesCommentDots };
  constructor(private authenticationService: AuthenticationService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected loginMessageService: LoginMessagesService,
    protected route: ActivatedRoute,
    private router: Router,
    protected applicationStateService: ApplicationStateService,
    protected printService: PrintTableService) {
    super(loginMessageService, alertService, spinnerService, modalService, applicationStateService, printService, route);
    this.scrollToTop();
  }

  ngOnInit() {
    this.deleteSuccessMessage = Messages.LoginMessageDeleted;
    this.loginMessageColumns = this.configureGridColumns();
    this.checkPermission();
    this.loadSubscriptions();
    this.loginMessageGrid.context = this.gridContext;
  }

  private scrollToTop(): void {
    window.scrollTo(0, 0);
  }

  public getGridColumns(): Array<GridColumn> {
    return [
      new GridColumn({ HeaderText: 'Message', Field: 'message', IsSortable: true, Width: '95%' }),
    ] as Array<GridColumn>;
  }

  private loadSubscriptions(): void {
    this.data$.subscribe({
      next: (res) => {
        this.loginMessageList = res;
      }
    });
  }

  public getConfirmDeleteMessage(data: LoginMessageModel): string {
    return Messages.ConfirmDeleteLoginMessage;
  }

  private checkPermission(): void {
    if (!this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      _.remove(this.loginMessageColumns, (column) => {
        return (column.Type === DomainConstants.GridColumnsTypes.EditColumn
          || column.Type === DomainConstants.GridColumnsTypes.DeleteColumn);
      });
    }
  }

  public editItem(id: number, data?: LoginMessageModel): void {
    this.router.navigate([id], { relativeTo: this.route });
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
