import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { InventoryProductRecipe } from '../../interface/inventory-product-recipe';
import { BaseCrudComponent } from 'src/app/shared/components/base-crud.component';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { InventoryProductRecipeService } from 'src/app/shared/services/inventory-product-recipe.service';
import { FormUtilityService, Messages } from 'src/app/shared';
import { finalize } from 'rxjs/operators';
import { InventoryProductService } from '../../../../shared/services/inventory-product.service';
import { InventoryProductSummary } from '../../interface/inventory-product-summary';

@Component({
    selector: 'pos-prep-recipe-edit',
    templateUrl: './prep-recipe-edit.component.html',
    styleUrls: []
})
export class PrepRecipeEditComponent extends BaseCrudComponent<InventoryProductRecipe> implements OnInit {

    inventoryProductRecipe: InventoryProductRecipe;
    inventoryProducts: Array<InventoryProductSummary> = [];
    @Input() inventoryProductId: number;
    @Input() editInventoryProducts: InventoryProductRecipe;
    @ViewChild('prepRecipeForm') prepRecipeForm: NgForm;
    @Output() close = new EventEmitter();
    @Output() formChange = new EventEmitter<NgForm>();
    props = {
        labelClass: '',
        controlBoxClass: 'col-lg-10 col-md-6 col-sm-6 col-xs-12'
    }

    get getForm(): NgForm {
        return this.prepRecipeForm;
    }

    constructor(
        protected alertService: AlertsService,
        protected spinnerService: SpinnerService,
        protected inventoryProductRecipeService: InventoryProductRecipeService,
        private inventoryProductService: InventoryProductService,
        formUtilityService: FormUtilityService
    ) {
        super(inventoryProductRecipeService, alertService, spinnerService, formUtilityService);
    }

    ngOnInit() {
        this.saveSuccessMessage = Messages.PrepRecipeAdded;
        this.dataSubscription();
    }

    submit(isValid: boolean): void {
        if (!isValid || !this.inventoryProductRecipe.InventoryProductId) {
            return;
        }
        this.inventoryProductRecipe.UsedPrepQty = 0;
        this.inventoryProductRecipe.CalculatedQty = 0;
        this.save(this.inventoryProductRecipe);
    }

    private dataSubscription(): void {
        this.inventoryProductRecipe = { ...this.editInventoryProducts };
        this.inventoryProductRecipe.MainInventoryProductId = this.inventoryProductId;
    }

    public loadDependencies(): void {
        this.spinnerService.show();
        this.inventoryProductService.getEligibleRecipeInventoryProduct(this.inventoryProductId)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({next:(res) => {
                this.inventoryProducts = res;
                this.formChange.emit(this.prepRecipeForm);
            }, error: this.alertService.showApiError });
    }

    productChangeHandler(productId: number) {
        const inventoryProduct = productId && this.inventoryProducts.find(x => x.Id == productId);
        this.inventoryProductRecipe.UnitName = inventoryProduct?.UnitName ? inventoryProduct.UnitName : null;
    }

    public onSaveSuccess(model: InventoryProductRecipe): void {
        this.close.emit({reload: true});
    }

    public cancelEditing(): void {
        this.close.emit();
    }

    public preventMinusSign(event) {
        if (event.key === '-') {
            return false;
        }
        return true;
    }

}
