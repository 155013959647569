<pos-row colSm="6" colXs="12" class="pos-title-content">
    <pos-column>
        <pos-form-field label="Font Size">
            <input posInput type="text" class="form-control" name="{{prefix}}_fontSize"
                [(ngModel)]="fontStyleConfig.fontSize">
        </pos-form-field>
    </pos-column>

    <pos-column>
        <pos-form-field label="Font Color">
            <pos-color-picker posInput [(ngModel)]="fontStyleConfig.color"
                name="{{prefix}}_fontColor"></pos-color-picker>
        </pos-form-field>
    </pos-column>

    <pos-column>
        <pos-form-field label="Font Style">
            <input posInput type="text" class="form-control" name="{{prefix}}_fontStyle"
                [(ngModel)]="fontStyleConfig.fontStyle">
        </pos-form-field>
    </pos-column>

    <pos-column>
        <pos-form-field label="Font Family">
            <input posInput type="text" class="form-control" name="{{prefix}}_fontFamily"
                [(ngModel)]="fontStyleConfig.fontFamily">
        </pos-form-field>
    </pos-column>

    <pos-column>
        <pos-form-field label="Font Weight">
            <input posInput type="text" class="form-control" name="{{prefix}}_fontWeight"
                [(ngModel)]="fontStyleConfig.fontWeight">
        </pos-form-field>
    </pos-column>

    <pos-column>
        <pos-form-field label="Line Height">
            <input posInput type="text" class="form-control" name="{{prefix}}_lineHeight"
                [(ngModel)]="fontStyleConfig.lineHeight">
        </pos-form-field>
    </pos-column>
</pos-row>
