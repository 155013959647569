import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SalesProduct } from 'src/app/information-management/sales-products/interface/sales-product';
import { SalesProductsService } from 'src/app/information-management/sales-products/services/sales-products.service';
import { BaseCrudComponent } from 'src/app/shared/components/base-crud.component';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { ButtonService } from 'src/app/shared/services/button.service';
import { FormUtilityService } from 'src/app/shared/services/form-utility.service';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import { Button } from '../../interface/button';
import { ButtonHistory } from '../../interface/button-history';
import { ButtonHistoryService } from '../../service/button-history-service';

@Component({
  selector: 'pos-button-history-edit',
  templateUrl: './button-history-edit.component.html',
})
export class ButtonHistoryEditComponent extends BaseCrudComponent<ButtonHistory> implements OnInit {

  buttonHistory: ButtonHistory;
  buttonHistoryTime: string;
  buttonName: string;
  dateFormat = 'mm-dd-yy';
  hourFormat = '12';
  salesProductList: Array<SalesProduct> = [];
  id: number;
  buttonId: number;
  today = new Date();
  @ViewChild('buttonHistoryForm') buttonHistoryForm: NgForm;
  get getForm(): NgForm {
    return this.buttonHistoryForm
  }

  constructor(protected buttonHistoryService: ButtonHistoryService,
    protected buttonService: ButtonService,
    protected salesProductService: SalesProductsService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected applicationStateService: ApplicationStateService,
    protected route: ActivatedRoute,
    private router: Router,
    protected printService: PrintTableService,
    formUtilityService: FormUtilityService) {
    super(buttonHistoryService, alertService, spinnerService, formUtilityService);
    this.buttonId = parseInt(route.snapshot.params.buttonId, 10) ?? 0;
    this.id = parseInt(route.snapshot.params.id, 10) ?? 0;
    this.dateFormat = this.applicationStateService.settingParam.PCalendarDateFormat;
    this.saveSuccessMessage = Messages.ButtonHistorySavedSuccess;
    this.buttonHistory = this.buttonHistoryService.getNewButtonHistory();
  }

  ngOnInit(): void {
    this.loadButtonHistory();
  }

  loadButtonHistory() {
    if (this.id) {
      this.spinnerService.show();
      this.loadData().pipe(finalize(() => {
        this.spinnerService.hide();
      })).subscribe({
        next: (response) => {
          this.buttonHistory = response;
          this.buttonHistory.DateAdded = new Date(response.DateAdded);
        }, error: this.alertService.showApiError
      });
    }
  }

  onCancel() {
    this.router.navigate([`manage/app-configuration/buttons/${this.buttonId}/button-history`]);
  }

  loadDependencies() {
    this.spinnerService.show();
    const observable: Array<Observable<any>> = [];
    observable.push(this.salesProductService.getSalesProducts());
    observable.push(this.buttonService.get(this.buttonId));
    forkJoin(observable).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (response: [Array<SalesProduct>, Button]) => {
        this.salesProductList = [...response[0]];
        this.buttonName = response[1].Selection;
      },
      error: this.alertService.showApiError
    });
  }

  saveButtonHistory(isValid: boolean) {
    if (!isValid) {
      return;
    }
    if (!this.id) {
      this.buttonHistory.ButtonId = this.buttonId;
    }
    this.spinnerService.show();
    this.buttonHistoryService.insert(this.buttonHistory).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (response) => {
        this.onSaveSuccess();
      }, error: this.alertService.showApiError
    });
  }

  onSaveSuccess() {
    this.onCancel();
    this.alertService.renderSuccessMessage(this.saveSuccessMessage);
  }

}
