import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';

@Injectable()
export class AudioNotificationService {
  constructor(private httpService: HttpClient) { }

  newAudioNotifier() {
    const audioNotifierObj = {
      Id: 0,
      Name: '',
      FileName: '',
    };
    return audioNotifierObj;
  }

  getAudioNotificationFiles(): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}audio-notifier`
    );
  }

  getAudioNotificationFile(audioNotifierId: number): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}audio-notifier/` + audioNotifierId
    );
  }
  saveAudioNotificationFile(audioNotificationData, audioNotificationFile): Observable<any> {
    const formData = this.prepareIconFormDataForSection(audioNotificationData, audioNotificationFile);
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}audio-notifier/`,
      formData
    );
  }

  private prepareIconFormDataForSection(param, file: File): FormData {
    const formData = new FormData();
    if (file) {
      formData.append('file', file);
    }
    formData.append('model', JSON.stringify(param));
    return formData;
  }

  updateAudioNotificationFile(audioNotificationData, audioNotificationFile): Observable<any> {
    const formData = this.prepareIconFormDataForSection(audioNotificationData, audioNotificationFile);
    return this.httpService.put(
      `${RuntimeConstants.API_PATH}audio-notifier/`,
      formData
    );

    // return this.httpService.put(
    //     `${RuntimeConstants.API_PATH}audio-notifier`,
    //     audioNotificationData
    // );
  }
  deleteAudioNotificationFile(audioNotifierId: number): Observable<any> {
    return this.httpService.delete(
      `${RuntimeConstants.API_PATH}audio-notifier/` + audioNotifierId
    );
  }

  getAudioVoiceId(): Observable<Array<string>> {
    return this.httpService.get<Array<string>>(
      `${RuntimeConstants.API_PATH}audio-notifier/voiceId`
    );
  }
}

