<pos-edit-wrapper (close)="closeModal()" heading="{{selectedButtonBehaviorType.Text}}">
    <form #singularFeeForm="ngForm" autocomplete="off" (submit)='saveBehavior(singularFeeForm.valid)'>
        <div class="col-12 pos-modal-body screen-scroll">
            <pos-row class="g-0">
                <pos-column>
                    <pos-form-field label="Name"
                        [validationMessages]="{required: 'Please enter name.'}">
                        <input posInput type="text" class="form-control" [(ngModel)]="name" name="seconds"
                            [required]="true" #nameInput="ngModel">
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Amount"
                        [validationMessages]="{required: 'Please enter amount.', min: 'Amount should be greater than 0.'}">
                        <p class="input-group">
                            <span class="input-group-addon">{{currencySymbol}}</span>
                            <input posInput type="number" min="0.01" name="amount" #amountInput="ngModel"
                                class="form-control price-input" [(ngModel)]="amount" [required]="true" posDecimal/>
                        </p>
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Taxable">
                        <div class="btn-group">
                            <label class="btn  btn-radio"
                                [ngClass]=" isTaxable ? 'active btn-primary normal': 'btn-secondary'">
                                <input posInput type="radio" name="taxable" class="form-check-input"
                                    [(ngModel)]="isTaxable" [value]="true">Taxable</label>
                            <label class="btn  btn-radio"
                                [ngClass]=" !isTaxable ?'active btn-primary normal': 'btn-secondary'">
                                <input posInput type="radio" name="taxable" class="form-check-input"
                                    [(ngModel)]="isTaxable" [value]="false">Non Taxable</label>
                        </div>
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Fee Type" *ngIf="isShowFeeType">
                        <div class="btn-group">
                            <label class="btn btn-radio "
                                [ngClass]="feeType == singularFeeTypes.PerProduct ?'active btn-primary normal': 'btn-secondary'">
                                <input type="radio" name="feeType" class="form-check-input" [(ngModel)]="feeType"
                                    value="PerProduct">Per Product</label>
                            <label class="btn btn-radio"
                                [ngClass]="feeType == singularFeeTypes.PerOrder ? 'active btn-primary normal': 'btn-secondary'">
                                <input type="radio" name="feeType" class="form-check-input" [(ngModel)]="feeType"
                                    value="PerOrder">Per Order</label>
                        </div>
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save</button>
            <button type="button" (click)="closeModal()" class="btn-secondary btn-lg btn btn-cancel-modal pos-button">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
