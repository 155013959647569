import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { ICloseable } from 'src/app/shared/components/modal/ICloseable';

@Component({
  selector: 'pos-new-template',
  templateUrl: './new-template.component.html',
  styleUrls: ['./new-template.component.scss']
})
export class NewTemplateComponent implements ICloseable, OnInit {

  @Input() scriptType = '';
  close: EventEmitter<any> = new EventEmitter();
  templateName = '';
  constructor() { }

  ngOnInit(): void { }

  cancel() {
    this.close.emit();
    this.templateName = '';
  }

  createNewScript(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.close.emit({ Name: this.templateName });
  }
}
